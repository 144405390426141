import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import {
  Api,
  FetchNotificationsAsReviewerParams,
  GetLimitedTimeNotificationNoticeAsOwnerParams,
  NotificationsListParams,
} from "../../lib/Api";

const api = new Api();

export const fetchNotificationsAsStudent = createAsyncThunk<
  any,
  NotificationsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/fetchNotifications", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.notificationsList(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchNotificationsAsTeacher = createAsyncThunk<
  any,
  NotificationsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/fetchNotifications", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.notificationsList(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchNotificationsAsOwner = createAsyncThunk<
  any,
  NotificationsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/fetchNotifications", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.notificationsList(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchNotificationsAsSuperOwner = createAsyncThunk<
  any,
  NotificationsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/fetchNotifications", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.notificationsList(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchNotificationsAsReviewer = createAsyncThunk<
  any,
  FetchNotificationsAsReviewerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("reviewer/fetchNotifications", async (params, { rejectWithValue }) => {
  try {
    const response = await api.reviewer.fetchNotificationsAsReviewer(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchNotificationsAsAdmin = createAsyncThunk<
  any,
  NotificationsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/fetchNotifications", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.notificationsList(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchNotificationsAsContentProvider = createAsyncThunk<
  any,
  NotificationsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("contentProvider/fetchNotifications", async (params, { rejectWithValue }) => {
  try {
    const response = await api.contentProvider.notificationsList(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getLimitedTimeNotificationNoticeAsOwner = createAsyncThunk<
  any,
  GetLimitedTimeNotificationNoticeAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/fetchLimitedTimeNotifications",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.getLimitedTimeNotificationNoticeAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getLimitedTimeNotificationNoticeAsTeacher = createAsyncThunk<
  any,
  GetLimitedTimeNotificationNoticeAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/fetchLimitedTimeNotifications",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.getLimitedTimeNotificationNoticeAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getLimitedTimeNotificationNoticeAsStudent = createAsyncThunk<
  any,
  GetLimitedTimeNotificationNoticeAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/fetchLimitedTimeNotifications",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.student.getLimitedTimeNotificationNoticeAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const putLimitedTimeNotificationNoticeCheckAsOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/fetchLimitedTimeNotifications/put",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.postLimitedTimeNotificationNoticeCheckAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const putLimitedTimeNotificationNoticeCheckAsTeacher = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/fetchLimitedTimeNotifications/put",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.postLimitedTimeNotificationNoticeCheckAsTeacher(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const putLimitedTimeNotificationNoticeCheckAsStudent = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/fetchLimitedTimeNotifications/put",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.student.postLimitedTimeNotificationNoticeCheckAsStudent(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetFetchNotificationState = createAction(
  "notification/resetFetchState",
);
