/* eslint-disable camelcase */
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { AccountUserInfo, AffiliateItem, BasicUserInfo } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import {
  bulkDeleteAffiliatesAsAdmin,
  createAdminUsersContentProvider,
  createAdminUsersOwners,
  createSuperOwners,
  deleteUsersAsAdmin,
  fetchAdminSearchedUsers,
  fetchAdminUserDetail,
  fetchAdminUsersContentProviders,
  fetchAdminUsersOwners,
  fetchAdminUsersStudents,
  fetchAdminUsersSuperOwners,
  fetchAdminUsersTeachers,
  resetAdminUserState,
  resetPasswordAsAdmin,
} from "../../actions/adminApp/user";
import { RootState } from "../../store";

export type OwnerInfo = BasicUserInfo;
export type StudentInfo = BasicUserInfo;
export type UsersInfo = { total_count: number; users: BasicUserInfo };
export type StudentDetailInfo = {
  affiliates: AffiliateItem[];
  current_sign_in_at?: string | null;
  email: string;
  first_name: string;
  id: string;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  last_name: string;
  last_sign_in_at?: string | null;
};
export type TeacherInfo = BasicUserInfo;
export type SuperOwnerInfo = BasicUserInfo;
export type ContentProviderInfo = BasicUserInfo;
export interface AdminAppUserState {
  fetching: boolean;
  posting: boolean;
  posted: boolean;
  deleting: boolean;
  deleted: boolean;
  fetchError: any;
  postError: any;
  deleteError: any;
  owners: OwnerInfo[];
  superOwners: SuperOwnerInfo[];
  teachers: TeacherInfo[];
  student: StudentDetailInfo | undefined;
  students: StudentInfo[];
  message: string;
  contentProviders: ContentProviderInfo[];
  totalCount: number;
  passwordUpdate: ReduxStatusType;
  passwordUpdateError: string | null;
  resetPasswordUser: AccountUserInfo | null;
}

export const initialCompanyDetailState: BasicUserInfo = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  invitation_accepted: false,
  invitation_expired: false,
  nickname: "",
};

export const initialState: AdminAppUserState = {
  fetching: false,
  posting: false,
  posted: false,
  deleting: false,
  deleted: false,
  fetchError: null,
  postError: null,
  deleteError: null,
  owners: [],
  superOwners: [],
  teachers: [],
  student: undefined,
  students: [],
  message: "",
  contentProviders: [],
  totalCount: 0,
  passwordUpdate: ReduxStatus.idle,
  passwordUpdateError: null,
  resetPasswordUser: null,
};

const createPendingState = (state: AdminAppUserState) => {
  return {
    ...state,
    posting: true,
    posted: false,
  };
};

export const userSlice = createSlice({
  name: "Admin/User",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminUsersOwners.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchAdminUsersOwners.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          owners: action.payload,
          fetchError: null,
          totalCount: action.payload,
        };
      })
      .addCase(fetchAdminUsersOwners.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          owners: [],
          fetchError: getErrorMessage(action.payload),
        };
      })
      .addCase(fetchAdminUsersSuperOwners.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchAdminUsersSuperOwners.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          superOwners: action.payload,
          fetchError: null,
          totalCount: action.payload,
        };
      })
      .addCase(fetchAdminUsersSuperOwners.rejected, (state, action) => {
        return {
          ...state,
          superOwners: [],
          fetching: false,
          fetchError: getErrorMessage(action.payload),
        };
      })
      .addCase(fetchAdminUsersContentProviders.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchAdminUsersContentProviders.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          contentProviders: action.payload,
          fetchError: null,
          totalCount: action.payload,
        };
      })
      .addCase(fetchAdminUsersContentProviders.rejected, (state, action) => {
        return {
          ...state,
          contentProviders: [],
          fetching: false,
          fetchError: getErrorMessage(action.payload),
        };
      })
      .addCase(fetchAdminUsersTeachers.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchAdminUsersTeachers.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          teachers: action.payload,
          fetchError: null,
          totalCount: action.payload,
        };
      })
      .addCase(fetchAdminUsersTeachers.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          teachers: [],
          fetchError: getErrorMessage(action.payload),
        };
      })
      .addCase(fetchAdminUsersStudents.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchAdminUsersStudents.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          students: action.payload,
          fetchError: null,
          totalCount: action.payload,
        };
      })
      .addCase(fetchAdminUsersStudents.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          students: [],
          fetchError: getErrorMessage(action.payload),
        };
      })
      .addCase(fetchAdminSearchedUsers.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchAdminSearchedUsers.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          students: action.payload.users,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchAdminSearchedUsers.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          students: [],
          fetchError: getErrorMessage(action.payload),
        };
      })
      .addCase(fetchAdminUserDetail.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchAdminUserDetail.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          student: action.payload,
        };
      })
      .addCase(fetchAdminUserDetail.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          student: undefined,
          fetchError: getErrorMessage(action.payload),
        };
      })
      .addCase(bulkDeleteAffiliatesAsAdmin.pending, (state, action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          fetching: true,
        };
      })
      .addCase(bulkDeleteAffiliatesAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          deleting: false,
          deleted: true,
        };
      })
      .addCase(bulkDeleteAffiliatesAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          deleting: false,
          deleted: false,
          deleteError: getErrorMessage(action.payload.error),
        };
      })
      .addCase(createAdminUsersOwners.pending, (state, action) => {
        return createPendingState(state);
      })
      .addCase(createAdminUsersOwners.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          postError: null,
        };
      })
      .addCase(createAdminUsersOwners.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          owner: initialCompanyDetailState,
          postError: action.payload
            ? action.payload.errors
            : "エラーが発生しました。",
        };
      })
      .addCase(createAdminUsersContentProvider.pending, (state, action) => {
        return createPendingState(state);
      })
      .addCase(createAdminUsersContentProvider.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          postError: null,
        };
      })
      .addCase(createAdminUsersContentProvider.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          contentProvider: initialCompanyDetailState,
          postError: action.payload
            ? action.payload.errors
            : "エラーが発生しました。",
        };
      })
      .addCase(createSuperOwners.pending, (state, action) => {
        return createPendingState(state);
      })
      .addCase(createSuperOwners.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          postError: null,
        };
      })
      .addCase(createSuperOwners.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          superOwner: null,
          postError: action.payload
            ? action.payload.errors
            : "エラーが発生しました。",
        };
      })
      .addCase(deleteUsersAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
        };
      })
      .addCase(deleteUsersAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          deleteError: action.payload,
          deleting: false,
          deleted: false,
        };
      })
      .addCase(deleteUsersAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: true,
        };
      })
      .addCase(resetPasswordAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          resetPasswordUser: null,
          passwordUpdate: ReduxStatus.pending,
        };
      })
      .addCase(resetPasswordAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          resetPasswordUser: payload,
          passwordUpdate: ReduxStatus.fulfilled,
          passwordUpdateError: null,
        };
      })
      .addCase(resetPasswordAsAdmin.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          resetPasswordUser: null,
          passwordUpdate: ReduxStatus.rejected,
          passwordUpdateError: getErrorMessage(payload.errors),
        };
      })
      .addCase(resetAdminUserState, (state) => {
        return {
          ...state,
          posted: false,
          postError: null,
          deleting: false,
          deleted: false,
          deleteError: null,
          passwordUpdate: ReduxStatus.idle,
          passwordUpdateError: null,
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const userState = (state: RootState): AdminAppUserState =>
  state.adminApp.user;

export default userSlice.reducer;
