import { combineReducers } from "redux";

import categoryRenameMapReducer from "./categoryRenameMap";
import companyReducer from "./company";
import feedbackCommentReducer from "./feedbackComment";
import ipWhiteListReducer from "./ipWhiteList";
import notificationReducer from "./notification";
import pblSponsorInfoReducer from "./pbl/sponsors/info";
import pblSponsorInfoCategoryReducer from "./pbl/sponsors/infoCategory";
import pblSponsorLectureReducer from "./pbl/sponsors/lecture";
import pblSponsorLectureFileReducer from "./pbl/sponsors/lectureFile";
import pblSponsorProjectReducer from "./pbl/sponsors/project";
import pblSponsorProjectCategoryReducer from "./pbl/sponsors/projectCategory";
import pblSponsorSchoolLinkReducer from "./pbl/sponsors/schoolLink";
import steamContentReducer from "./steamContent";
import userReducer from "./user";

const adminAppReducer = combineReducers({
  company: companyReducer,
  user: userReducer,
  notification: notificationReducer,
  steamContent: steamContentReducer,
  feedbackComment: feedbackCommentReducer,
  categoryRenameMap: categoryRenameMapReducer,
  pblSponsorInfo: pblSponsorInfoReducer,
  pblSponsorProject: pblSponsorProjectReducer,
  pblSponsorLecture: pblSponsorLectureReducer,
  pblSponsorLectureFile: pblSponsorLectureFileReducer,
  pblSponsorInfoCategory: pblSponsorInfoCategoryReducer,
  pblSponsorProjectCategory: pblSponsorProjectCategoryReducer,
  ipWhiteList: ipWhiteListReducer,
  pblSponsorSchoolLink: pblSponsorSchoolLinkReducer,
});

export default adminAppReducer;
