import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";

import {
  deleteOwnerTemplateFrameworksId,
  deleteOwnerTemplateItemFrameworksId,
  fetchTemplateFrameworkAfterQuestionDelete,
  getOneOwnerTemplateFramework,
  getOwnerTemplateFrameworks,
  postOwnerTemplateFrameworks,
  postOwnerTemplateItemFrameworks,
  resetState,
  updateOwnerTemplateFrameworks,
  updateOwnerTemplateItemFrameworks,
} from "../../actions/ownerApp/frameWorkReports";
import { RootState } from "../../store";

export interface OwnerAppHomeworkState {
  fetchingReports: boolean;
  fetchedReports: boolean;
  fetchingOneReport: boolean;
  fetchedOneReport: boolean;
  posting: boolean;
  posted: boolean;
  postingItem: boolean;
  postedItem: boolean;
  updating: boolean;
  updated: boolean;
  updatingItem: boolean;
  updatedItem: boolean;
  deletedFramWork: boolean;
  deletedFramWorkItem: boolean;
  deletingFramWork: boolean;
  deleteFrameWorkItemError: string;
  frameWorkReports: any[];
  oneFrameWorkReport: any;
  postError: string;
  postItemError: string;
  updateFrameWorkError: string;
  updateItemError: string;
  deleteFrameWorkError: string;
  reportsError: string;
  oneReportError: string;
  totalCount: number;
}

export const initialState: OwnerAppHomeworkState = {
  fetchingReports: false,
  fetchedReports: false,
  fetchingOneReport: false,
  fetchedOneReport: false,
  posting: false,
  posted: false,
  postingItem: false,
  postedItem: false,
  updatingItem: false,
  updatedItem: false,
  updating: false,
  updated: false,
  deletingFramWork: false,
  deletedFramWork: false,
  deletedFramWorkItem: false,
  deleteFrameWorkItemError: "",
  updateFrameWorkError: "",
  postError: "",
  postItemError: "",
  reportsError: "",
  updateItemError: "",
  deleteFrameWorkError: "",
  oneReportError: "",
  frameWorkReports: [],
  oneFrameWorkReport: null,
  totalCount: 0,
};

const ownerAppFrameWorkReportsSlice = createSlice({
  name: "OwnerApp/Homework",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postOwnerTemplateFrameworks.pending, (state, _action) => {
        return {
          ...state,
          posting: true,
          posted: false,
          postError: "",
        };
      })
      .addCase(postOwnerTemplateFrameworks.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postError: errorWithMessage(action.payload),
        };
      })
      .addCase(postOwnerTemplateFrameworks.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          postError: "",
        };
      })
      .addCase(postOwnerTemplateItemFrameworks.pending, (state, _action) => {
        return {
          ...state,
          postingItem: true,
          postedItem: false,
          postItemError: "",
        };
      })
      .addCase(postOwnerTemplateItemFrameworks.rejected, (state, action) => {
        return {
          ...state,
          postingItem: false,
          postedItem: false,
          postItemError: errorWithMessage(action.payload),
        };
      })
      .addCase(postOwnerTemplateItemFrameworks.fulfilled, (state, action) => {
        return {
          ...state,
          postingItem: false,
          postedItem: true,
          postItemError: "",
        };
      })
      .addCase(updateOwnerTemplateFrameworks.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          updateFrameWorkError: "",
        };
      })
      .addCase(updateOwnerTemplateFrameworks.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          updateFrameWorkError: errorWithMessage(action.payload),
        };
      })
      .addCase(updateOwnerTemplateFrameworks.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
          updateFrameWorkError: "",
        };
      })
      .addCase(updateOwnerTemplateItemFrameworks.pending, (state, _action) => {
        return {
          ...state,
          updatingItem: true,
          updatedItem: false,
          updateItemError: "",
        };
      })
      .addCase(updateOwnerTemplateItemFrameworks.rejected, (state, action) => {
        return {
          ...state,
          updatingItem: false,
          updatedItem: false,
          updateItemError: errorWithMessage(action.payload),
        };
      })
      .addCase(updateOwnerTemplateItemFrameworks.fulfilled, (state, action) => {
        return {
          ...state,
          updatingItem: false,
          updatedItem: true,
          updateItemError: "",
        };
      })
      .addCase(deleteOwnerTemplateFrameworksId.pending, (state, _action) => {
        return {
          ...state,
          deletedFramWork: false,
          deletingFramWork: true,
        };
      })
      .addCase(deleteOwnerTemplateFrameworksId.rejected, (state, action) => {
        return {
          ...state,
          deletedFramWork: false,
          deletingFramWork: false,
        };
      })
      .addCase(deleteOwnerTemplateFrameworksId.fulfilled, (state, action) => {
        return {
          ...state,
          deletedFramWork: true,
          deletingFramWork: false,
        };
      })
      .addCase(
        deleteOwnerTemplateItemFrameworksId.pending,
        (state, _action) => {
          return {
            ...state,
            deletedFramWorkItem: false,
            deleteFrameWorkItemError: "",
          };
        },
      )
      .addCase(
        deleteOwnerTemplateItemFrameworksId.rejected,
        (state, action) => {
          return {
            ...state,
            deletedFramWorkItem: false,
            deleteFrameWorkItemError: errorWithMessage(action.payload),
          };
        },
      )
      .addCase(
        deleteOwnerTemplateItemFrameworksId.fulfilled,
        (state, action) => {
          return {
            ...state,
            deletedFramWorkItem: true,
            deleteFrameWorkItemError: "",
          };
        },
      )
      .addCase(getOwnerTemplateFrameworks.pending, (state, _action) => {
        return {
          ...state,
          fetchingReports: true,
          fetchedReports: false,
          reportsError: "",
        };
      })
      .addCase(getOwnerTemplateFrameworks.rejected, (state, action) => {
        return {
          ...state,
          fetchingReports: false,
          fetchedReports: false,
          reportsError: errorWithMessage(action.payload),
          frameWorkReports: [],
        };
      })
      .addCase(getOwnerTemplateFrameworks.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingReports: false,
          fetchedReports: true,
          reportsError: "",
          frameWorkReports: payload.report_template_frameworks,
          totalCount: payload.total_count,
        };
      })
      .addCase(getOneOwnerTemplateFramework.pending, (state, _action) => {
        return {
          ...state,
          fetchingOneReport: true,
          fetchedOneReport: false,
          oneReportError: "",
        };
      })
      .addCase(getOneOwnerTemplateFramework.rejected, (state, action) => {
        return {
          ...state,
          fetchingOneReport: false,
          fetchedOneReport: false,
          oneReportError: errorWithMessage(action.payload),
          oneFrameWorkReport: [],
        };
      })
      .addCase(getOneOwnerTemplateFramework.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOneReport: false,
          fetchedOneReport: true,
          oneReportError: "",
          oneFrameWorkReport: payload,
        };
      })
      .addCase(
        fetchTemplateFrameworkAfterQuestionDelete.pending,
        (state, _action) => {
          return {
            ...state,
            oneReportError: "",
          };
        },
      )
      .addCase(
        fetchTemplateFrameworkAfterQuestionDelete.rejected,
        (state, action) => {
          return {
            ...state,
            oneReportError: errorWithMessage(action.payload),
            oneFrameWorkReport: [],
          };
        },
      )
      .addCase(
        fetchTemplateFrameworkAfterQuestionDelete.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            oneReportError: "",
            oneFrameWorkReport: payload,
          };
        },
      )
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postingItem: false,
          postedItem: false,
          updatingItem: false,
          updatedItem: false,
          updating: false,
          updated: false,
          deletedFramWork: false,
          deletingFramWork: false,
          deletedFramWorkItem: false,
          postError: "",
          postItemError: "",
          deleteFrameWorkError: "",
        };
      });
  },
});

export const ownerAppFrameWorkReportsState = (
  state: RootState,
): OwnerAppHomeworkState => state.ownerApp.frameWorkReports;

export default ownerAppFrameWorkReportsSlice.reducer;
