import { FormCategoryTitle } from "@components/UI/atoms/Forms/FormComponents";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  title: string;
  annotation?: string;
  dataCy?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    required: {
      fontSize: "0.8rem",
      marginLeft: 6,
      color: theme.palette.grey[600],
    },
  }),
);

const CategoryTitleAnnotation: React.FC<Props> = ({
  title,
  annotation,
  dataCy,
}) => {
  const classes = useStyles();

  return (
    <FormCategoryTitle data-cy={dataCy ?? "category_title"}>
      {title}
      {annotation && <span className={classes.required}>*{annotation}</span>}
    </FormCategoryTitle>
  );
};

export default CategoryTitleAnnotation;
CategoryTitleAnnotation.defaultProps = {
  annotation: undefined,
};
