import { enqueueToast } from "@actions/toast";
import { DistributionEnum } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList";
import TableBodyLoading from "@components/UI/organisms/TableBodyLoading/TableBodyLoading";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import {
  LearningTemplatePackageBase,
  PackagingAsLearningTemplatePackageBase,
} from "@lib/Api";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import NO_DATA_MESSAGE from "@root/constants/Messages/Table";
import { useAppDispatch } from "@root/hooks";
import React from "react";

import TableItem from "./TableItems";

interface Props {
  fetched: boolean;
  fetching: boolean;
  fetchError: string | null;
  packages: LearningTemplatePackageBase[];
  handleModalOpen: (
    modalStatus: ModalStatus,
    learningTemplateInfos?: LearningTemplatePackageBase,
    packageInfos?: PackagingAsLearningTemplatePackageBase,
  ) => void;
  handleDistributionModal: (
    type: DistributionEnum,
    learningTemplateInfos: LearningTemplatePackageBase,
    packagesArrayInfos: PackagingAsLearningTemplatePackageBase[],
  ) => void;
}

const LearningTemplateTableBody: React.FC<Props> = ({
  fetched,
  fetching,
  fetchError,
  packages,
  handleModalOpen,
  handleDistributionModal,
}) => {
  const dispatch = useAppDispatch();
  if (!fetching && (!packages.length || fetchError)) {
    if (fetchError) {
      dispatch(
        enqueueToast({
          message: fetchError,
          id: nanoid(),
          options: {
            variant: "error",
          },
        }),
      );
    }
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={4}>{NO_DATA_MESSAGE}</TableCell>
        </TableRow>
      </TableBody>
    );
  }
  if (fetching) {
    return <TableBodyLoading colCount={4} />;
  }
  if (!fetching && !fetchError && fetched && packages.length) {
    return (
      <TableBody>
        {packages &&
          packages.map((template) => {
            return (
              <TableItem
                key={template.id}
                learningTemplate={template}
                handleModalOpen={handleModalOpen}
                handleDistributionModal={handleDistributionModal}
              />
            );
          })}
      </TableBody>
    );
  }

  return null;
};

export default LearningTemplateTableBody;
