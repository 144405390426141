import { CommonNotification } from "@actions/types/notification";
import { ReduxStatus,ReduxStatusType } from "@constants/redux";
import { LimitedTimeNotificationNoticeBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import {
  fetchNotificationsAsAdmin,
  fetchNotificationsAsContentProvider,
  fetchNotificationsAsOwner,
  fetchNotificationsAsReviewer,
  fetchNotificationsAsStudent,
  fetchNotificationsAsSuperOwner,
  fetchNotificationsAsTeacher,
  getLimitedTimeNotificationNoticeAsOwner,
  getLimitedTimeNotificationNoticeAsStudent,
  getLimitedTimeNotificationNoticeAsTeacher,
  resetFetchNotificationState,
} from "../actions/notification";
import { RootState } from "../store";

export interface NotificationState {
  fetching: boolean;
  fetched: boolean;
  notifications: CommonNotification[];
  error: string | null;
  totalCount: number;
  limitedTimeNotifications: LimitedTimeNotificationNoticeBase[];
  fetchLimitedTimeNotifications: ReduxStatusType;
  limitedTimeError: string | null;
  limitedTimeNotificationsCount: number;
}

export const initialState: NotificationState = {
  fetching: false,
  fetched: false,
  notifications: [],
  error: null,
  totalCount: 0,
  limitedTimeNotifications: [],
  fetchLimitedTimeNotifications: ReduxStatus.idle,
  limitedTimeError: null,
  limitedTimeNotificationsCount: 0,
};

export const notificationSlice = createSlice({
  name: "Notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          notifications: [],
          error: null,
        };
      })
      .addCase(fetchNotificationsAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          notifications: action.payload.notifications,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchNotificationsAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          notifications: [],
          error: "データが取得できませんでした。",
        };
      })
      .addCase(fetchNotificationsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          notifications: [],
          error: null,
        };
      })
      .addCase(fetchNotificationsAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          notifications: action.payload.notifications,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchNotificationsAsTeacher.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          notifications: [],
          error: "データが取得できませんでした。",
        };
      })
      .addCase(fetchNotificationsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          notifications: [],
          error: null,
        };
      })
      .addCase(fetchNotificationsAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          notifications: action.payload.notifications,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchNotificationsAsOwner.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          notifications: [],
          error: "データが取得できませんでした。",
        };
      })
      .addCase(fetchNotificationsAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          notifications: [],
          error: null,
        };
      })
      .addCase(fetchNotificationsAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          notifications: action.payload.notifications,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchNotificationsAsSuperOwner.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          notifications: [],
          error: "データが取得できませんでした。",
        };
      })
      .addCase(fetchNotificationsAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          notifications: [],
          error: null,
        };
      })
      .addCase(fetchNotificationsAsReviewer.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          notifications: action.payload.notifications,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchNotificationsAsReviewer.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          notifications: [],
          error: "データが取得できませんでした。",
        };
      })
      .addCase(fetchNotificationsAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          notifications: [],
          error: null,
        };
      })
      .addCase(fetchNotificationsAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          notifications: action.payload.notifications,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchNotificationsAsAdmin.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          notifications: [],
          error: "データが取得できませんでした。",
        };
      })
      .addCase(
        getLimitedTimeNotificationNoticeAsOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetchLimitedTimeNotifications: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getLimitedTimeNotificationNoticeAsOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            limitedTimeNotifications: action.payload.limited_time_notifications,
            fetchLimitedTimeNotifications: ReduxStatus.fulfilled,
            limitedTimeNotificationsCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        getLimitedTimeNotificationNoticeAsOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetchLimitedTimeNotifications: ReduxStatus.rejected,
            limitedTimeError: getErrorMessage(action.payload.error),
          };
        },
      )
      .addCase(
        getLimitedTimeNotificationNoticeAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetchLimitedTimeNotifications: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getLimitedTimeNotificationNoticeAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            limitedTimeNotifications: action.payload.limited_time_notifications,
            fetchLimitedTimeNotifications: ReduxStatus.fulfilled,
            limitedTimeNotificationsCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        getLimitedTimeNotificationNoticeAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetchLimitedTimeNotifications: ReduxStatus.rejected,
            limitedTimeError: getErrorMessage(action.payload.error),
          };
        },
      )
      .addCase(
        getLimitedTimeNotificationNoticeAsStudent.pending,
        (state, _action) => {
          return {
            ...state,
            fetchLimitedTimeNotifications: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getLimitedTimeNotificationNoticeAsStudent.fulfilled,
        (state, action) => {
          return {
            ...state,
            limitedTimeNotifications: action.payload.limited_time_notifications,
            fetchLimitedTimeNotifications: ReduxStatus.fulfilled,
            limitedTimeNotificationsCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        getLimitedTimeNotificationNoticeAsStudent.rejected,
        (state, action) => {
          return {
            ...state,
            fetchLimitedTimeNotifications: ReduxStatus.rejected,
            limitedTimeError: getErrorMessage(action.payload.error),
          };
        },
      )
      .addCase(
        fetchNotificationsAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            notifications: [],
            error: null,
          };
        },
      )
      .addCase(
        fetchNotificationsAsContentProvider.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            notifications: action.payload.notifications,
            totalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        fetchNotificationsAsContentProvider.rejected,
        (state, _action) => {
          return {
            ...state,
            fetching: false,
            notifications: [],
            error: "データが取得できませんでした。",
          };
        },
      )
      .addCase(resetFetchNotificationState, (state, _action) => {
        return {
          ...state,
          fetched: false,
          error: null,
          fetchLimitedTimeNotifications: ReduxStatus.idle,
          limitedTimeError: null,
        };
      });
  },
});

export const notificationState = (state: RootState): NotificationState =>
  state.notification;

export default notificationSlice.reducer;
