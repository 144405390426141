import { resetMaterialHomeworkTemplatePackagesState } from "@actions/superOwnerApp/materialHomeworkPackage/materialHomeworkPackage";
import { resetTemplateDistributionState } from "@actions/superOwnerApp/templatePackages/learningTemplateDistribution";
import {
  fetchLearningTemplatePackagesAsSuperOwner,
  resetTemplatePackageState,
} from "@actions/superOwnerApp/templatePackages/learningTemplatePackage";
import { QueryParams } from "@components/Common/Material/MaterialLayout/UseSearchUtils/GlobalStates";
import LearningTemplateTable from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList";
import {
  LearningTemplatePackageBase,
  PackagingAsLearningTemplatePackageBase,
} from "@lib/Api";
import { useQuery, useSetQuery } from "@lib/hooks/query";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import { errorWithMessage } from "@lib/rtk/error-utils";
import { getValidPageNumber } from "@lib/url-param-utils";
import { categoryState } from "@reducers/category";
import { superOwnerAppLearningTemplateDistributionState } from "@reducers/superOwnerApp/templatePackages/learningTemplateDistribution";
import { superOwnerAppLearningTemplatePackagesState } from "@reducers/superOwnerApp/templatePackages/learningTemplatePackage";
import { ReduxStatus } from "@root/constants/redux";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import React, { useCallback, useEffect, useState } from "react";

import { ContentType } from "../index";

interface Props {
  detailView: ContentType;
  tabClickHandler: (value: string) => void;
}

export enum ModalStatus {
  CREATE_TEMPLATE = "create",
  EDIT_TEMPLATE = "edit",
  DELETE_TEMPLATE = "delete_template",
  ADD_PACKAGE = "add",
  EDIT_ADDED_PACKAGE = "edit_added_package",
  DELETE_PACKAGE = "delete_package",
  DELETE_CO_PACKAGE = "delete_co_package",
  DISTRIBUTION = "distribution",
  IDLE = "idle",
}
export interface ModalStateParams {
  isOpen: boolean;
  learningTemplate: LearningTemplatePackageBase | null;
  package: PackagingAsLearningTemplatePackageBase | null;
  packagesArray: PackagingAsLearningTemplatePackageBase[] | null;
  status: ModalStatus;
  coPackage?: PackagingAsLearningTemplatePackageBase | null;
}

const LearningTemplateListContainer: React.FC<Props> = ({
  detailView,
  tabClickHandler,
}) => {
  const dispatch = useAppDispatch();
  const learningTemplateDistributionCache = useAppSelector(
    superOwnerAppLearningTemplateDistributionState,
  );
  const {
    posting: distributing,
    posted: distributed,
    postSuccessMessage: distributionPostSuccessMessage,
    postError: distributionError,
  } = learningTemplateDistributionCache;
  const learningTemplateCache = useAppSelector(
    superOwnerAppLearningTemplatePackagesState,
  );
  const {
    fetching,
    fetched,
    fetchError,
    packages,
    postError,
    posting,
    posted,
    updating,
    updated,
    updateError,
    postingPackage,
    success: postedPackage,
    postPackageError,
    deletedPackage,
    deletingPackage,
    deleteLeaningTemplate,
    deletePackageError,
    totalCount,
    packageStatus,
    deleteSuccess,
  } = learningTemplateCache;

  const categoryCache = useAppSelector(categoryState);
  const { text, grade, subject, major, middle, minor } = categoryCache;
  const { actions } = useEnqueueToast();
  const perPage = 10;

  const { page: pageParam, title: titleParam } = useQuery();
  const setQuery = useSetQuery<QueryParams>();
  const [currentPage, setCurrentPage] = useState(getValidPageNumber(pageParam));
  const [searchText, setSearchText] = useState<string>(titleParam ?? "");

  const [modalState, setModalState] = useState<ModalStateParams>({
    isOpen: false,
    learningTemplate: null,
    package: null,
    packagesArray: null,
    status: ModalStatus.IDLE,
  });

  const fetchLearningTemplatePackages = useCallback(
    (page: number, searchText: string) => {
      dispatch(
        fetchLearningTemplatePackagesAsSuperOwner({
          page,
          per_page: perPage,
          title: searchText,
        }),
      );
    },
    [dispatch, perPage],
  );

  const handleUpdatePage = useCallback(
    (newPage: number) => {
      const validPageNumber = getValidPageNumber(newPage);
      setCurrentPage(newPage);
      setQuery({
        page: validPageNumber.toString(),
        searchWord: searchText,
      });
      fetchLearningTemplatePackages(newPage, searchText);
    },
    [searchText],
  );

  const handleModalOpen = (
    modalStatus: ModalStatus,
    learningTemplateInfos: LearningTemplatePackageBase | null = null,
    packageInfos: PackagingAsLearningTemplatePackageBase | null = null,
    packagesArrayInfos: PackagingAsLearningTemplatePackageBase[] | null = null,
  ) => {
    setModalState({
      isOpen: true,
      learningTemplate: learningTemplateInfos,
      package: packageInfos,
      packagesArray: packagesArrayInfos,
      status: modalStatus,
    });
  };

  const resetModalState = () => {
    setModalState({
      isOpen: false,
      learningTemplate: null,
      package: null,
      packagesArray: null,
      status: ModalStatus.IDLE,
    });
  };

  const onSearch = useCallback(() => {
    setCurrentPage(1);
    setQuery({ page: "1", searchWord: searchText });
    fetchLearningTemplatePackages(1, searchText);
  }, [fetchLearningTemplatePackages, searchText, setCurrentPage, setQuery]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (detailView === ContentType.PACKAGE) {
      fetchLearningTemplatePackages(1, searchText);
    }
  }, [detailView]);

  useEffect(() => {
    if (posted) {
      actions.showSuccess("プログラムを作成しました。");
      fetchLearningTemplatePackages(currentPage, searchText);
      resetModalState();
      dispatch(resetTemplatePackageState());
    } else if (updated) {
      actions.showSuccess("プログラムを更新しました。");
      fetchLearningTemplatePackages(currentPage, searchText);
      resetModalState();
      dispatch(resetTemplatePackageState());
    } else if (postedPackage) {
      actions.showSuccess(
        modalState.status === ModalStatus.EDIT_ADDED_PACKAGE
          ? "コンテンツを更新しました。"
          : "コンテンツを追加しました。",
      );
      fetchLearningTemplatePackages(currentPage, searchText);
      resetModalState();
      dispatch(resetTemplatePackageState());
    } else if (deleteSuccess && deletedPackage) {
      actions.showSuccess("コンテンツを削除しました。");
      fetchLearningTemplatePackages(currentPage, searchText);
      resetModalState();
      dispatch(resetTemplatePackageState());
    } else if (deleteLeaningTemplate === ReduxStatus.fulfilled) {
      actions.showSuccess("プログラムを削除しました。");
      fetchLearningTemplatePackages(currentPage, searchText);
      resetModalState();
      dispatch(resetTemplatePackageState());
    } else if (distributionPostSuccessMessage) {
      actions.showSuccess(distributionPostSuccessMessage);
      fetchLearningTemplatePackages(currentPage, searchText);
      resetModalState();
      dispatch(resetTemplateDistributionState());
      dispatch(resetTemplatePackageState());
    } else if (postPackageError) {
      actions.showError("コンテンツはすでに存在します。");
      dispatch(resetTemplatePackageState());
    } else if (distributionError) {
      actions.showError(errorWithMessage(distributionError));
      dispatch(resetTemplateDistributionState());
    } else if (postError) {
      actions.showError("プログラムの作成はできませんでした。");
      dispatch(resetTemplatePackageState());
    } else if (updateError) {
      actions.showError("プログラムの更新はできませんでした。");
      dispatch(resetTemplatePackageState());
    } else if (deletePackageError) {
      actions.showError("コンテンツの削除はできませんでした。");
      dispatch(resetTemplatePackageState());
    } else if (packageStatus.status === ReduxStatus.fulfilled) {
      actions.showSuccess(packageStatus.message);
      dispatch(resetTemplatePackageState());
    } else if (packageStatus.status === ReduxStatus.rejected) {
      actions.showError(packageStatus.message);
      dispatch(resetTemplatePackageState());
    }
    dispatch(resetMaterialHomeworkTemplatePackagesState());
  }, [
    posted,
    postError,
    postedPackage,
    updated,
    updateError,
    postPackageError,
    deletedPackage,
    deletePackageError,
    distributionPostSuccessMessage,
    distributionError,
    deleteLeaningTemplate,
    packageStatus.status,
    deleteSuccess,
  ]);

  return (
    <LearningTemplateTable
      fetched={fetched}
      fetching={fetching}
      posting={posting}
      posted={posted || updated}
      distributing={distributing}
      postingPackage={postingPackage}
      postedPackage={postedPackage}
      updating={updating}
      deletingPackage={
        deletingPackage || deleteLeaningTemplate === ReduxStatus.pending
      }
      distributed={distributed}
      fetchError={fetchError}
      packages={packages}
      resetModalState={resetModalState}
      handleModalOpen={handleModalOpen}
      modalState={modalState}
      totalCount={totalCount}
      currentPage={currentPage}
      perPage={perPage}
      handlePageTransition={handleUpdatePage}
      text={text}
      grade={grade}
      subject={subject}
      major={major}
      middle={middle}
      minor={minor}
      tabClickHandler={tabClickHandler}
      onSearch={onSearch}
      onInputChange={onInputChange}
      searchText={searchText}
    />
  );
};

export default LearningTemplateListContainer;
