import { useAuth } from "@contexts/Auth";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const useLogoutIfNotLoggedIn = (): void => {
  const { isAuthenticated: isLoggedIn } = useAuth();
  const history = useHistory();
  useEffect(() => {
    if (!isLoggedIn) {
      history.push({ pathname: "/login", search: "?logout" });
    }
  }, [isLoggedIn]);
};

export default useLogoutIfNotLoggedIn;
