import {
  Api,
  FetchPblReportAnswersAsStudentParams,
  PblReportAnswerStudentEventEnum,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblReportAnswersAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/report/answers/fetch",
  async (params: FetchPblReportAnswersAsStudentParams, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchPblReportAnswersAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblReportAnswerAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/report/answers/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchPblReportAnswerAsStudent(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdatePblReportAnswerAsStudentArg {
  id: string;
  data: {
    event?: PblReportAnswerStudentEventEnum;
  };
}

export const updatePblReportAnswerAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/report/answers/update",
  async (params: UpdatePblReportAnswerAsStudentArg, { rejectWithValue }) => {
    const { id, data } = params;
    try {
      const response = await api.student.updatePblReportAnswerAsStudent(
        id,
        data,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetPblReportAnswerRelatedData = createAction(
  "student/pbl/report/answer_related/reset",
);
