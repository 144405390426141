import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import { objectHas } from "@lib/object-utils";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Menu, TextField } from "@mui/material";
import theme from "@root/styles/theme";
import { getMarkAttributes } from "@tiptap/core";
import React, { memo, useContext,useEffect, useState } from "react";

import useDOMParse from "../useDOMParse";
import { BubbleMenuContext } from "./BubbleMenuContext";
import { useStyles } from "./styles";

export const LinkGroup = (): JSX.Element => {
  const { canDisplayLink } = useDOMParse();
  const { editor, subMenuOpen, setSubMenuOpen, isOtherSubMenuOpen } =
    useContext(BubbleMenuContext);
  const classes = useStyles();
  const { hasLink } = useDOMParse();
  const [linkValue, setLinkValue] = useState<string>("");
  const [linkInputAnchorEl, setLinkInputAnchorEl] =
    useState<null | HTMLElement>(null);

  const [linkSaveButtonClicked, setLinkSaveButtonClicked] =
    useState<boolean>(false);

  const disabled = isOtherSubMenuOpen("linkGroup") || !canDisplayLink(editor);

  useEffect(() => {
    if (linkSaveButtonClicked) {
      editor?.commands.setLink({ href: linkValue });
      setLinkSaveButtonClicked(false);
    }
  }, [linkSaveButtonClicked]);

  const handleClose = () => {
    setSubMenuOpen({
      ...subMenuOpen,
      linkGroup: false,
    });
    setLinkInputAnchorEl(null);
  };

  return (
    <>
      <button
        type="button"
        onClick={(event) => {
          const attributes = getMarkAttributes(editor.state, "link");
          if (objectHas(attributes, "href")) {
            setLinkValue(attributes.href);
          } else {
            setLinkValue("");
          }

          if (!linkInputAnchorEl) {
            setSubMenuOpen({
              ...subMenuOpen,
              linkGroup: true,
            });

            setLinkInputAnchorEl(event.currentTarget);
          } else {
            setSubMenuOpen({
              ...subMenuOpen,
              linkGroup: false,
            });

            setLinkInputAnchorEl(null);
          }
        }}
        className={hasLink(editor) ? classes.isActive : classes.default}
        style={{
          ...(disabled && { opacity: 0.1 }),
        }}
        disabled={disabled}
      >
        <StyledTooltip title="リンクを追加" placement="top">
          <LinkOutlinedIcon
            fontSize="small"
            htmlColor={
              hasLink(editor)
                ? theme.palette.tertiary.mainDark
                : theme.palette.common.black
            }
          />
        </StyledTooltip>
      </button>
      <Menu
        keepMounted
        anchorEl={linkInputAnchorEl}
        open={Boolean(linkInputAnchorEl)}
        onClose={() => {
          setLinkValue("");
          handleClose();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={classes.linkMenuStyle}
      >
        <div
          style={{
            margin: "5px",
          }}
        >
          <TextField
            maxRows={4}
            variant="outlined"
            onChange={(event) => setLinkValue(event.target.value)}
            className={classes.customTextField}
            value={linkValue}
          />
          <button
            type="button"
            onClick={() => {
              setLinkSaveButtonClicked(true);
              editor.chain().focus().setLink({ href: linkValue }).run();
              handleClose();
            }}
            className={classes.saveButton}
          >
            保存
          </button>
          {hasLink(editor) && (
            <button
              type="button"
              onClick={() => {
                editor.chain().focus().unsetLink().run();
                setLinkValue("");
                handleClose();
              }}
              className={classes.deleteButton}
            >
              削除
            </button>
          )}
        </div>
      </Menu>
    </>
  );
};

export default memo(LinkGroup);
