/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */

import { STUDENT_ARTICLES_PATH } from "@constants/api";
import {
  AllowedArticleCategorizableType,
  Api,
  Article,
  FetchArticlesAsStudentParams,
  FetchArticleWordCloudAsStudentParams,
  WordCloudBase,
} from "@lib/Api";
import { http } from "@lib/http";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RoleApiFunctions } from "@root/types/wordCloud";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticlesAsStudentArg {
  params: FetchArticlesAsStudentParams;
}

export const fetchArticlesAsStudent = createAsyncThunk<
  any,
  FetchArticlesAsStudentArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/article/fetch", async (arg, { rejectWithValue }) => {
  try {
    const response = await api.student.fetchArticlesAsStudent(arg.params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchArticleAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/article/fetchOne",
  async (articleId: string, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchArticleAsStudent(articleId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdateArticleAsStudentParams {
  title?: string;
  body: string;
  updated_at_with_second: string;
  cover?: {
    file: string | File;
    type: string;
    name: string;
  };
  coverDeleted?: boolean;
  add_group_affiliate_ids?: string[];
  remove_group_affiliate_ids?: string[];
  add_or_update_categorizable_id?: string;
  add_or_update_categorizable_type?: AllowedArticleCategorizableType;
  remove_categorizable_id?: string;
  remove_categorizable_type?: AllowedArticleCategorizableType;
}

type StudentArticleAffiliateParams = {
  article_id: string;
  affiliate_id: string;
};
export interface UpdateArticleAsStudentArg {
  articleId: string;
  params: UpdateArticleAsStudentParams;
}

export const updateArticleAsStudent = createAsyncThunk<
  Article,
  UpdateArticleAsStudentArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/article/update", async (arg, { rejectWithValue }) => {
  const { articleId, params } = arg;
  const {
    title,
    body,
    updated_at_with_second,
    cover,
    coverDeleted,
    remove_group_affiliate_ids,
    add_group_affiliate_ids,
    add_or_update_categorizable_id,
    add_or_update_categorizable_type,
    remove_categorizable_id,
    remove_categorizable_type,
  } = params;
  const fd = new FormData();
  // @ts-expect-error
  fd.set("title", title);
  if (typeof body !== "undefined") {
    fd.set("body", body);
  }
  fd.set("updated_at_with_second", updated_at_with_second);
  if (typeof cover !== "undefined") {
    // @ts-expect-error
    fd.append("cover", cover.file, cover.name);
  }
  if (coverDeleted) {
    fd.append("cover_deleted", "deleted"); // Cannot send boolean with form data.
  }
  if (typeof add_group_affiliate_ids !== "undefined") {
    add_group_affiliate_ids.forEach((groupId) =>
      fd.append("add_group_affiliate_ids[]", groupId),
    );
  }
  if (add_or_update_categorizable_id && add_or_update_categorizable_type) {
    fd.set("add_or_update_categorizable_id", add_or_update_categorizable_id);
    fd.set(
      "add_or_update_categorizable_type",
      add_or_update_categorizable_type,
    );
  }
  if (remove_categorizable_id && remove_categorizable_type) {
    fd.set("remove_categorizable_id", remove_categorizable_id);
    fd.set("remove_categorizable_type", remove_categorizable_type);
  }

  if (typeof remove_group_affiliate_ids !== "undefined") {
    remove_group_affiliate_ids.forEach((groupId) =>
      fd.append("remove_group_affiliate_ids[]", groupId),
    );
  }
  try {
    const response = await http.put(
      `${STUDENT_ARTICLES_PATH}/${articleId}`,
      fd,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const submitArticleToTeacherAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/article/submit_to_teacher",
  async (articleId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.student.submitArticleToTeacherAsStudent(articleId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postArticleAffiliatesAsStudent = createAsyncThunk<
  any,
  StudentArticleAffiliateParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/article/affiliate_student_to_article",
  async (params, { rejectWithValue }) => {
    try {
      const response: any =
        await api.student.postArticleAffiliatesAsStudent(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteArticleAffiliatesAsStudent = createAsyncThunk<
  any,
  StudentArticleAffiliateParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/article/delete_affiliated_student",
  async (params, { rejectWithValue }) => {
    try {
      const response: any =
        await api.student.deleteArticleAffiliatesAsStudent(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteArticleAsStudent = createAsyncThunk<
  { data: any; changeState: boolean },
  { articleId: string; changeState?: boolean },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/article/delete",
  async ({ articleId, changeState = true }, { rejectWithValue }) => {
    try {
      const response = await api.student.deleteArticleAsStudent(articleId);
      return { data: response.data, changeState };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchArticleStatsAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/articles/stats", async (arg: any, { rejectWithValue }) => {
  try {
    const response = await api.student.fetchArticleStatsAsStudent(arg);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchWordCloudDataAsStudent = createAsyncThunk<
  WordCloudBase,
  FetchArticleWordCloudAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/word_cloud/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.fetchArticleWordCloudAsStudent(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetModalState = createAction("student/article/resetModalState");
export const resetUpdateStatus = createAction(
  "student/article/resetUpdateStatus",
);
export const resetDeleteStatus = createAction(
  "student/article/resetDeleteStatus",
);
export const resetFetchOneStatus = createAction(
  "student/article/resetFetchOneStatus",
);
export const resetStatusUpdate = createAction(
  "student/article/resetStatusUpdate",
);

export const resetArticleRelatedData = createAction("student/article/resetOne");

type StudentActionsType = {
  student: RoleApiFunctions;
};
export const studentWordCloudActions: StudentActionsType = {
  student: {
    fetchWordCloudDataByRole: fetchWordCloudDataAsStudent,
  },
};
