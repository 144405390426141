import V2StyledSelect, {
  SelectItem,
} from "@components/UIv2/atoms/V2StyledSelect";
import { Box, SelectChangeEvent,Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  selectItems: string[] | number[] | SelectItem[];
  selectorValue: string | number;
  handleSelectorChange: (event: SelectChangeEvent) => void;
  disabled?: boolean | undefined;
  position?: "start" | "center" | "end";
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      columnGap: theme.spacing(1),
    },
    pageSelectorTitle: {
      fontWeight: 700,
      fontSize: 16,
      color: theme.palette.tertiary.mainDark,
    },
  }),
);

const PerPageSelector: React.FC<Props> = ({
  selectItems,
  selectorValue,
  handleSelectorChange,
  disabled,
  position,
}) => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      justifyContent={position}
      data-cy="per-page-selector-wrapper"
    >
      <Typography className={classes.pageSelectorTitle}>
        {t("display_number")}
      </Typography>
      <V2StyledSelect
        selectItems={selectItems}
        selectorValue={selectorValue}
        handleSelectorChange={handleSelectorChange}
        disabled={disabled}
      />
    </Box>
  );
};

PerPageSelector.defaultProps = {
  disabled: undefined,
  position: "end",
};
export default PerPageSelector;
