import {
  createPblCardBookmarkAsStudent,
  deletePblCardBookmarkAsStudent,
  deletePblCardImageAsStudent,
  fetchPblCardBookmarkAsStudent,
  fetchPblCardBookmarksAsStudent,
  resetModalState,
  resetOne,
  updatePblCardBookmarkAsStudent,
} from "@actions/studentApp/pbl/cards/information_literacy/bookmark";
import { deletePblCardBookmarkImageAsStudent } from "@actions/studentApp/pbl/cards/information_literacy/bookmarkImage";
import {
  createPblCardBookmarkCommentAsStudent,
  deletePblCardBookmarkCommentAsStudent,
} from "@actions/studentApp/pbl/cards/information_literacy/comment";
import { PblCardBookmarkBase } from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface StudentAppPblCardBookmarkState {
  fetching: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchingInModal: boolean;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  changeError: any;
  fetchError: string | null;
  fetchOneError: string | null;
  bookmarks: PblCardBookmarkBase[];
  bookmark: PblCardBookmarkBase | null;
  bookmarkInModal: PblCardBookmarkBase | null;
  totalCount: number;
  deletingImage: boolean;
  deletedImage: boolean;
  deletingImageModal: boolean;
  deletedImageModal: boolean;
}

export const initialState: StudentAppPblCardBookmarkState = {
  fetching: false,
  fetchingOne: false,
  fetchedOne: false,
  fetchError: null,
  fetchOneError: null,
  fetchingInModal: false,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  changeError: null,
  bookmarks: [],
  bookmark: null,
  bookmarkInModal: null,
  totalCount: 0,
  deletingImage: false,
  deletedImage: false,
  deletingImageModal: false,
  deletedImageModal: false,
};

const studentAppPblCardBookmarkSlice = createSlice({
  name: "StudentApp/Pbl/Card/Bookmark",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblCardBookmarksAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetchError: null,
        };
      })
      .addCase(fetchPblCardBookmarksAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: action.error.message as string,
          bookmarks: [],
        };
      })
      .addCase(fetchPblCardBookmarksAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetchError: null,
          bookmarks: payload.bookmarks,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchPblCardBookmarkAsStudent.pending, (state, action) => {
        const { meta } = action;
        const { inModal } = meta.arg;
        return {
          ...state,
          fetchingOne: !inModal,
          fetchOneError: null,
          fetchingInModal: inModal,
        };
      })
      .addCase(fetchPblCardBookmarkAsStudent.rejected, (state, action) => {
        const { meta } = action;
        const { inModal } = meta.arg;
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: inModal ? null : (action.error.message as string),
          fetchingInModal: false,
        };
      })
      .addCase(fetchPblCardBookmarkAsStudent.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const { inModal } = meta.arg;
        return {
          ...state,
          fetchingOne: false,
          fetchingInModal: false,
          fetchOneError: null,
          bookmark: inModal ? state.bookmark : payload,
          bookmarkInModal: inModal ? payload : state.bookmarkInModal,
        };
      })
      .addCase(createPblCardBookmarkAsStudent.pending, (state, action) => {
        return {
          ...state,
          creating: true,
          created: false,
        };
      })
      .addCase(createPblCardBookmarkAsStudent.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
        };
      })
      .addCase(createPblCardBookmarkAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          creating: false,
          created: true,
          bookmarks: [payload, ...state.bookmarks],
        };
      })
      .addCase(updatePblCardBookmarkAsStudent.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(updatePblCardBookmarkAsStudent.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
        };
      })
      .addCase(updatePblCardBookmarkAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          bookmarks: update(currentState.bookmarks, payload),
          bookmark:
            currentState.bookmark && currentState.bookmark.id === payload.id
              ? payload
              : state.bookmark,
          bookmarkInModal: payload,
        };
      })
      .addCase(deletePblCardBookmarkAsStudent.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(deletePblCardBookmarkAsStudent.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
        };
      })
      .addCase(deletePblCardBookmarkAsStudent.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          bookmarks: remove(currentState.bookmarks, meta.arg.id),
          bookmark:
            currentState.bookmark && currentState.bookmark.id === payload.id
              ? null
              : state.bookmark,
          bookmarkInModal: null,
        };
      })
      .addCase(resetModalState, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          deletingImage: false,
          deletedImage: false,
          changeError: null,
          fetchingInModal: false,
          bookmarkInModal: null,
        };
      })
      .addCase(resetOne, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          deletingImage: false,
          deletedImage: false,
          changeError: null,
          fetchingInModal: false,
          bookmarkInModal: null,
          bookmark: null,
          deletingImageModal: false,
          deletedImageModal: false,
        };
      })
      // Comment
      .addCase(
        createPblCardBookmarkCommentAsStudent.fulfilled,
        (state, action) => {
          const currentState = current(state);
          let newBookmark = currentState.bookmark;
          if (
            currentState.bookmark &&
            typeof currentState.bookmark !== "undefined"
          ) {
            newBookmark = {
              ...currentState.bookmark,
              num_comments: currentState.bookmark
                ? currentState.bookmark.num_comments + 1
                : 0,
            };
          }
          return {
            ...state,
            bookmark: newBookmark,
          };
        },
      )
      .addCase(
        deletePblCardBookmarkCommentAsStudent.fulfilled,
        (state, action) => {
          const currentState = current(state);
          let newBookmark = currentState.bookmark;
          if (
            currentState.bookmark &&
            typeof currentState.bookmark !== "undefined"
          ) {
            newBookmark = {
              ...currentState.bookmark,
              num_comments: currentState.bookmark
                ? currentState.bookmark.num_comments - 1
                : 0,
            };
          }
          return {
            ...state,
            bookmark: newBookmark,
          };
        },
      )
      // Image
      .addCase(deletePblCardBookmarkImageAsStudent.pending, (state, action) => {
        return {
          ...state,
          deletingImage: true,
          deletedImage: false,
        };
      })
      .addCase(
        deletePblCardBookmarkImageAsStudent.rejected,
        (state, action) => {
          return {
            ...state,
            deletingImage: false,
            deletedImage: false,
          };
        },
      )
      .addCase(
        deletePblCardBookmarkImageAsStudent.fulfilled,
        (state, action) => {
          const { meta } = action;
          const currentState = current(state);
          let newBookmark = currentState.bookmark;
          if (
            currentState.bookmark &&
            typeof currentState.bookmark !== "undefined"
          ) {
            newBookmark = {
              ...currentState.bookmark,
              images: remove(currentState.bookmark.images, meta.arg),
            };
          }
          return {
            ...state,
            deletingImage: false,
            deletedImage: true,
            bookmark: newBookmark,
          };
        },
      )
      // Image
      .addCase(deletePblCardImageAsStudent.pending, (state, action) => {
        return {
          ...state,
          deletingImageModal: true,
          deletedImageModal: false,
        };
      })
      .addCase(deletePblCardImageAsStudent.rejected, (state, action) => {
        return {
          ...state,
          deletingImageModal: false,
          deletedImageModal: false,
        };
      })
      .addCase(deletePblCardImageAsStudent.fulfilled, (state, action) => {
        const { meta } = action;
        const currentState = current(state);
        let newBookmark = currentState.bookmark;
        if (
          currentState.bookmark &&
          typeof currentState.bookmark !== "undefined"
        ) {
          newBookmark = {
            ...currentState.bookmark,
            images: remove(currentState.bookmark.images, meta.arg),
          };
        }
        return {
          ...state,
          deletingImageModal: false,
          deletedImageModal: true,
          bookmark: newBookmark,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppPblCardBookmarkState = (
  state: RootState,
): StudentAppPblCardBookmarkState => state.studentApp.pblCardBookmark;

export default studentAppPblCardBookmarkSlice.reducer;
