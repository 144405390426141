/* eslint-disable @typescript-eslint/ban-types */
import { FormSectionWrap } from "@components/UI/atoms/Forms/FormComponents";
import V2StyledFormTitle from "@components/UIv2/atoms/V2StyledFormTitle";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValuesTypes } from "..";

type Props = {
  control: Control<FormValuesTypes, object>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formHelperText: {
      color: theme.palette.error.main,
    },
  }),
);

const IsOnlineBox: React.FC<Props> = ({ control }) => {
  const classes = useStyles();
  const { t } = useTranslation("university");
  return (
    <div>
      <FormSectionWrap>
        <Controller
          control={control}
          name="isOnline"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl>
              <V2StyledFormTitle isRequired title={t("isOnline")} />
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="オンライン"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="オフライン※直接大学に訪問"
                />
              </RadioGroup>
              <FormHelperText className={classes.formHelperText}>
                {error ? error.message : null}
              </FormHelperText>
            </FormControl>
          )}
          rules={{ required: "面談方法を選択してください。" }}
        />
      </FormSectionWrap>
    </div>
  );
};

export default IsOnlineBox;
