import MessageItem from "@components/Common/UniversityInterviewRequestList/Modal/MailBoxContainer/Chat/ChatMessages/MessageItems";
import { useAuth } from "@contexts/Auth";
import { Box,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  InterviewFeedbackBase,
  InterviewRequestListBase2,
} from "@root/endpoints/TimeTactApi/studentApi";
import React, { useEffect, useRef } from "react";

import FeedBacksAnswerTemplate from "../FeedBacksAnswerTemplate";

type Props = {
  messages: InterviewRequestListBase2[];
  feedBacks: InterviewFeedbackBase[];
  currentUserId: string;
  requestId: string;
  isElementsDisabled: boolean;
  refetchMessageList: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      flexGrow: "inherit",
      overflow: "auto",
      "scrollbar-width": "none" /* Firefox対応のスクロールバー非表示コード*/,
      "-ms-overflow-style":
        "none" /* Internet Explore対応のスクロールバー非表示コード*/,
      "&::-webkit-scrollbar": {
        display:
          "none" /* Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/,
      },
      height: 0,
      padding: theme.spacing(2, 0),
      gap: theme.spacing(4),
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
  }),
);

const ChatMessages: React.FC<Props> = ({
  requestId,
  messages,
  currentUserId,
  feedBacks,
  isElementsDisabled,
  refetchMessageList,
}) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const messagesEndRef = useRef<HTMLDivElement>(null); // this ref will scroll on the bottom of the msg List
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages.length]);
  return (
    <Box className={classes.main}>
      <Box className={classes.wrapper}>
        {messages.map((message) => (
          <MessageItem
            isOwnMessage={
              message.sender_affiliate.affiliate_id ===
              currentUser?.affiliate_id
            }
            message={message}
            key={message.id}
            requestId={requestId}
            isElementsDisabled={isElementsDisabled}
            refetchMessageList={refetchMessageList}
          />
        ))}
        <FeedBacksAnswerTemplate
          feedBacks={feedBacks}
          currentUserId={currentUserId}
        />
        <div ref={messagesEndRef} />
      </Box>
    </Box>
  );
};

export default ChatMessages;
