import loader from "@assets/images/svg/PleaseWait.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

const VideoUnderConstructionLabel = () => {
  return (
    <Box position="relative" display="inline-flex">
      <img src={loader} alt="動画準備中です" height={77} />
    </Box>
  );
};

const VideoUnderConstruction: React.FC = () => {
  return (
    <Box
      height="80vh"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box mb="2rem">
        <VideoUnderConstructionLabel />
      </Box>
      <Box mb="2rem">
        <Typography variant="h6">
          動画登録処理中です。 しばらくたってから再度ご確認ください。
        </Typography>
      </Box>
    </Box>
  );
};

export default VideoUnderConstruction;
