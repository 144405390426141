import { StyledTagColor } from "@components/UI/atoms/StyledTag";
import {
  ArticleStatus,
  HomeworkStatus,
  HomeworkTemplateDistributionStatus,
  IdealBoardStatus,
  LearningPackageStatus,
  MaterialWorkStatus,
  PblReportAnswerStatusEnum,
} from "@lib/Api";

export type CommunizedStatusCode =
  | HomeworkStatus
  | HomeworkTemplateDistributionStatus
  | IdealBoardStatus
  | ArticleStatus
  | PblReportAnswerStatusEnum
  | MaterialWorkStatus
  | LearningPackageStatus;

export const getTagColorByStatusCode = (
  status: CommunizedStatusCode,
): StyledTagColor => {
  switch (status) {
    case IdealBoardStatus.Todo:
    case HomeworkStatus.Todo:
    case HomeworkTemplateDistributionStatus.Todo:
    case MaterialWorkStatus.Todo:
    case PblReportAnswerStatusEnum.Todo:
      return StyledTagColor.LightRed;
    case HomeworkStatus.AssignedReviewer:
    case ArticleStatus.TeacherChecking:
    case IdealBoardStatus.InProgress:
    case MaterialWorkStatus.InProgress:
    case LearningPackageStatus.Completed:
      return StyledTagColor.BrightGreen;
    case HomeworkStatus.ChangeRequested:
    case ArticleStatus.Correction:
      return StyledTagColor.Blue;
    case ArticleStatus.Draft:
    case LearningPackageStatus.Incompleted:
      return StyledTagColor.Orange;
    case ArticleStatus.Published:
    case ArticleStatus.Reviewing:
      return StyledTagColor.Grey;
    case PblReportAnswerStatusEnum.Drafting:
      return StyledTagColor.Azure;
    case HomeworkStatus.Checked:
    default:
      return StyledTagColor.Grey;
  }
};
