import { HomeworkTemplateDistribution } from "@lib/Api";
import { removeMany, updateMany } from "@lib/collection";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";

import {
  batchCreate,
  batchDelete,
  batchUpdate,
  fetchHomeworkTemplateDistributionAsSuperOwner,
  fetchHomeworkTemplateDistributionsAsSuperOwner,
  resetDeleteActionState,
  resetModalState,
  resetState,
} from "../../actions/superOwnerApp/homeworkTemplateDistribution";
import { RootState } from "../../store";

export interface SuperOwnerAppHomeworkTemplateDistributionState {
  fetching: boolean;
  error: string | null;
  distributions: HomeworkTemplateDistribution[];
  distribution: HomeworkTemplateDistribution | null;
  totalCount: number;
  batchChangeErrors: any[];
  batchCreateError: string | null;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
}

export const initialState: SuperOwnerAppHomeworkTemplateDistributionState = {
  fetching: false,
  error: null,
  distributions: [],
  distribution: null,
  totalCount: 0,
  batchChangeErrors: [],
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  batchCreateError: null,
};

const superOwnerAppHomeworkTemplateDistributionSlice = createSlice({
  name: "SuperOwnerApp/HomeworkTemplateDistribution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchHomeworkTemplateDistributionsAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            error: null,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionsAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            error: errorWithMessage(action.payload),
            distributions: [],
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionsAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            error: null,
            distributions: payload.homework_template_distributions,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            error: null,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            error: errorWithMessage(action.payload),
            distribution: null,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            error: null,
            distribution: payload,
          };
        },
      )
      .addCase(batchCreate.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          batchChangeErrors: [],
        };
      })
      .addCase(batchCreate.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          batchCreateError: errorWithMessage(action.payload),
        };
      })
      .addCase(batchCreate.fulfilled, (state, action) => {
        const { payload } = action;
        const newDistributions = [
          ...payload.distributions,
          ...state.distributions,
        ];
        return {
          ...state,
          creating: false,
          created: true,
          batchChangeErrors: payload.errors,
          error: null,
          distributions: newDistributions,
          totalCount: state.totalCount + payload.distributions.length,
        };
      })
      .addCase(batchUpdate.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          batchChangeErrors: [],
        };
      })
      .addCase(batchUpdate.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(batchUpdate.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updating: false,
          updated: true,
          batchChangeErrors: payload.errors,
          error: null,
          distributions: updateMany(state.distributions, payload.distributions),
        };
      })
      .addCase(batchDelete.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          batchChangeErrors: [],
        };
      })
      .addCase(batchDelete.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(batchDelete.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          deleting: false,
          deleted: payload.deleted_ids.length > 0,
          batchChangeErrors: payload.errors,
          error: null,
          distributions: removeMany(state.distributions, payload.deleted_ids),
          totalCount: state.totalCount - payload.deleted_ids.length,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          error: null,
          batchCreateError: null,
        };
      })
      .addCase(resetDeleteActionState, (state, _action) => {
        return {
          ...state,
          batchChangeErrors: [],
          deleted: false,
          error: null,
        };
      })
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          fetching: false,
          error: null,
          distributions: [],
          distribution: null,
          totalCount: 0,
          batchChangeErrors: [],
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const superOwnerAppHomeworkTemplateDistributionState = (
  state: RootState,
): SuperOwnerAppHomeworkTemplateDistributionState =>
  state.superOwnerApp.homeworkTemplateDistribution;

export default superOwnerAppHomeworkTemplateDistributionSlice.reducer;
