/* eslint-disable camelcase */
import {
  ImageData,
  SponsorImageData,
  VideoData,
} from "@components/Admin/Sponsors/modals/types";
import { Api, GetAdminSponsorInfosParams, SponsorSalesChannel } from "@lib/Api";
import { http } from "@lib/http";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { createPblSponsorInfoCategory } from "./infoCategory";

const api = new Api();

export const fetchOnePblSponsorInfoAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/infos/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.admin.getAdminSponsorInfosId(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const fetchPblSponsorInfoAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/infos/fetch",
  async (params: GetAdminSponsorInfosParams, { rejectWithValue }) => {
    try {
      const response = await api.admin.getAdminSponsorInfos(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export interface CreateSponsorInfoAsAdminParams {
  company_id: string;
  description: string;
  is_public: boolean;
  sales_channel: SponsorSalesChannel;
  tags: string;
  categories?: {
    name: string;
    description: string;
    file: ImageData | null;
  }[];
  sponsor_logo: File | null;
  image: SponsorImageData | null;
  video_file: VideoData | null;
  external_link: string | null;
}

export const createPblSponsorInfoAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/infos/create",
  async (params: CreateSponsorInfoAsAdminParams, { rejectWithValue }) => {
    try {
      const {
        company_id,
        description,
        is_public,
        sales_channel,
        tags,
        categories,
        sponsor_logo,
        image,
        video_file,
        external_link,
      } = params;
      const fd = new FormData();
      fd.set("company_id", company_id);
      fd.set("description", description);
      fd.set("is_public", is_public ? "true" : "false");
      fd.set("sales_channel", sales_channel);
      if (typeof tags !== "undefined" && tags.length) {
        tags.split(",").forEach((tag) => {
          fd.append(`tags[]`, tag);
        });
      }
      if (sponsor_logo) {
        fd.set("sponsor_logo", sponsor_logo);
      }
      if (typeof image?.file !== "undefined" && image.file) {
        fd.set("image_contents", image.file);
      }
      if (video_file && video_file.file) {
        fd.set("video_file", video_file.file as File);
      }
      if (external_link) {
        fd.set("external_link", external_link as string);
      }
      const response = await http.post(`/admin/sponsor/infos`, fd);
      const sponsorData = response.data;
      let catResults: { success: boolean; data: any }[] | null = null;
      if (typeof categories !== "undefined") {
        catResults = await Promise.all(
          categories.map((catData) => {
            return createPblSponsorInfoCategory({
              sponsor_id: sponsorData.id,
              name: catData.name,
              description: catData.description,
              file: catData.file,
            });
          }),
        );
      }
      return {
        sponsorInfo: sponsorData,
        categoriesResult: catResults,
      };
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export interface UpdateSponsorInfoAsAdminParams {
  company_id: string;
  description: string;
  is_public: boolean;
  sales_channel: SponsorSalesChannel;
  tags: string;
  sponsor_logo: File | null;
  image: SponsorImageData | null;
  video_file: VideoData | null;
  external_link: string | null;
  isLogoChanged: boolean;
  isChanged: boolean;
  isImageChanged: boolean;
}

export interface UpdateSponsorInfoAsAdminArg {
  id: string;
  params: UpdateSponsorInfoAsAdminParams;
}

export const updatePblSponsorInfoAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/infos/update",
  async ({ id, params }: UpdateSponsorInfoAsAdminArg, { rejectWithValue }) => {
    try {
      const {
        company_id,
        description,
        is_public,
        sales_channel,
        tags,
        sponsor_logo,
        image,
        video_file,
        external_link,
        isLogoChanged,
        isChanged,
        isImageChanged,
      } = params;
      const fd = new FormData();
      fd.set("company_id", company_id);
      fd.set("description", description);
      fd.set("is_public", is_public ? "true" : "false");
      fd.set("sales_channel", sales_channel);
      if (typeof tags !== "undefined" && tags.length) {
        tags.split(",").forEach((tag) => {
          fd.append(`tags[]`, tag);
        });
      }
      if (isLogoChanged) {
        fd.set("sponsor_logo", sponsor_logo ?? "deleted");
      }
      if (isImageChanged) {
        if (typeof image?.file !== "undefined" && image.file) {
          fd.set("image_contents", image.file);
        } else {
          fd.set("image_contents", "delete");
        }
      }
      if (isChanged) {
        if (video_file && video_file.file) {
          fd.set("video_file", video_file.file as File);
        } else {
          fd.set("video_file", "delete");
        }
      }
      if (external_link) {
        fd.set("external_link", external_link as string);
      } else {
        fd.set("external_link", "");
      }
      const response = await http.put(`/admin/sponsor/infos/${id}`, fd);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const deletePblSponsorInfoAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/pbl/sponsor/infos/delete", async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.admin.deleteSponsorInfoAsAdmin(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const resetAction = createAction("admin/pbl/sponsor/infos/resetAction");
export const reset = createAction("admin/pbl/sponsor/infos/reset");
export const resetOne = createAction("admin/pbl/sponsor/infos/resetOne");
