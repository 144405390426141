/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Only three kinds of errors come from the timetact-server
export type MessageString = {
  message: string;
};

export type ErrorString = {
  error: string;
};
export type ErrorWithErrors = {
  errors: string[];
};

export type ErrorsWithKeyMessage = {
  errors: { key: string; message: string }[];
};

export const isObject = (value: unknown): value is Record<string, unknown> => {
  if (typeof value !== "object" || value === null) return false;
  return true;
};
export const isMessageString = (value: unknown): value is MessageString => {
  return (
    isObject(value) &&
    "message" in value &&
    typeof (value as any).message === "string"
  );
};
export const isErrorString = (value: unknown): value is ErrorString => {
  return (
    isObject(value) &&
    "error" in value &&
    typeof (value as any).error === "string"
  );
};
export const isErrorArray = (value: any): value is ErrorWithErrors => {
  return (
    value &&
    Array.isArray(value.errors) &&
    value.errors.every((error: unknown) => typeof error === "string")
  );
};
export const isErrorsWithKeyMessage = (
  value: unknown,
): value is ErrorsWithKeyMessage => {
  if (!isObject(value)) return false;
  if (!("errors" in value)) return false;
  if (!(typeof (value as any).errors === "object")) return false;
  if (!Array.isArray(value.errors)) return false;
  return value.errors.every(
    (e) =>
      isMessageString(e) && "key" in e && typeof (e as any).key === "string",
  );
};

type Data = {
  data: unknown;
};

const hasData = (value: unknown): value is Data => {
  if (!isObject(value)) return false;
  return "data" in value;
};
export const errorWithMessages = (error: unknown): string[] => {
  let messages: string[] = [];
  if (hasData(error)) {
    const { data } = error;
    if (typeof data === "string") {
      messages = [data];
    } else if (isMessageString(data)) {
      messages = [data.message];
    } else if (isErrorString(data)) {
      messages = [data.error];
    } else if (isErrorsWithKeyMessage(data)) {
      messages = data.errors.map((keyMessage) => keyMessage.message);
    } else {
      messages = ["エラーが発生しました。"];
    }
  } else {
    messages = ["エラーが発生しました。"];
  }
  return messages;
};

export const errorWithMessage = (error: unknown): string => {
  let message = "";
  if (hasData(error)) {
    const { data } = error;
    if (typeof data === "string") {
      message = data;
    } else if (isMessageString(data)) {
      message = data.message;
    } else if (isErrorString(data)) {
      message = data.error;
    } else if (isErrorArray(data)) {
      message = data.errors.join("\n");
    } else if (isErrorsWithKeyMessage(data)) {
      message = data.errors[0].message;
    } else {
      message = "エラーが発生しました。";
    }
  } else if (typeof error === "string") {
    message = error;
  } else {
    message = "エラーが発生しました。";
  }
  return message;
};

export default errorWithMessage;
