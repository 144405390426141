// eslint-disable-next-line import/prefer-default-export
export const fileSize = {
  file: {
    size: 52428800, // 50MB
    message: "50MB以上のファイルは添付されません。",
  },
  image: {
    size: 52428800, // 50MB
    message: "画像ファイルは50MB以下のサイズにしてください。",
  },
  materialImage: {
    size: 104857600, // 100MB
    message: "画像ファイルは100MB以下のサイズにしてください。",
  },
  video: {
    size: 1073741824, // 1GB
    message: "動画ファイルは1GB以下のサイズにしてください。",
  },
  materialVideo: {
    size: 3221225472, // 3GB
    message: "動画ファイルは3GB以下のサイズにしてください。",
  },
};
