/* eslint-disable camelcase */
import { OWNER_CATEGORIES_PATH } from "@constants/api";
import { Api, CategoryInfo, CategoryType } from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RoleApiFunctions } from "@root/types/category";
import { RootState } from "@store/store";

import { fetchMaterialsAsOwner } from "./material";

const api = new Api();

export const fetchCategoriesAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/category/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.fetchCategoriesAsOwner(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type OwnerActionsType = {
  owner: RoleApiFunctions;
};

export const ownerCategoryActions: OwnerActionsType = {
  owner: {
    getCategories: fetchCategoriesAsOwner,
  },
};

export interface CreateCategoryParams {
  name: string;
  parent_id: string | null;
  student_visible?: boolean;
  teacher_visible?: boolean;
  is_help?: boolean | undefined;
  image?: File;
}

export const createCategoryAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/category/create",
  async (params: CreateCategoryParams, { rejectWithValue }) => {
    try {
      const { parent_id, name, student_visible, teacher_visible, image } =
        params;
      const fd = new FormData();
      if (parent_id) {
        fd.set("parent_id", parent_id as string);
      }
      fd.set("name", name);
      if (image) {
        fd.set("image", image as File, image?.name);
      }
      const response = await http.post(`${OWNER_CATEGORIES_PATH}`, fd, {
        params: {
          student_visible,
          teacher_visible,
        },
      });
      return response.data as CategoryInfo;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface UpdateCategoryParams {
  id: string | undefined;
  name: string;
  student_visible?: boolean;
  teacher_visible?: boolean;
  image?: File;
}

export const updateCategoryAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/category/update",
  async (params: UpdateCategoryParams, { rejectWithValue }) => {
    try {
      const { id, name, student_visible, teacher_visible, image } = params;
      const fd = new FormData();

      fd.set("id", id as string);
      fd.set("name", name);
      if (image) {
        fd.set("image", image as File, image?.name);
      }
      const response = await http.put(
        `${OWNER_CATEGORIES_PATH}/${params.id}`,
        fd,
        {
          params: {
            student_visible,
            teacher_visible,
          },
        },
      );
      return response.data as CategoryInfo;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export type DeleteCategoryParams = {
  id: string;
  type: CategoryType;
  textId: string;
};

export const deleteCategoryAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "category/delete",
  async (params: DeleteCategoryParams, { rejectWithValue, dispatch }) => {
    const { id, type, textId } = params;
    try {
      await http.delete(`${OWNER_CATEGORIES_PATH}/${id}`);
      if (type !== CategoryType.Text) {
        dispatch(fetchMaterialsAsOwner({ category_id: textId }));
      }
      return {
        id,
        categoryType: type,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface SaveSequenceArg {
  type: CategoryType;
  updatedItems: CategoryInfo[];
  sequences: Array<{
    category_id: string;
    sequence: number;
  }>;
}

export const saveSequenceAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/category/saveSequence",
  async (arg: SaveSequenceArg, { rejectWithValue }) => {
    const { updatedItems, type, sequences } = arg;
    const filteredItems = updatedItems.filter((item) => item.id !== "0");
    try {
      const response = await api.owner.putOwnerCategoriesSequence({
        sequences,
      });
      return {
        updatedItems: filteredItems,
        data: response.data,
        type,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const resetSortData = createAction("category/resetSortData");
export const resetCopyModalState = createAction("category/resetCopyModalState");
export const resetCategoryFormRelatedData = createAction(
  "category/resetFormRelatedData",
);
