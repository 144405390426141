/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-case-declarations */
import Fuda from "@assets/images/svg/fuda_icon.svg?react";
import { DistributionEnum } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import {
  StyledTag,
  StyledTagColor,
  StyledTextColor,
} from "@components/UI/atoms/StyledTag";
import { getGradeName } from "@lib/grade-utils";
import { Box, Typography } from "@mui/material";
import { superOwnerAppLearningTemplateDistributionState } from "@reducers/superOwnerApp/templatePackages/learningTemplateDistribution";
import { useAppSelector } from "@root/hooks";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { UseFormGetValues, UseFormWatch } from "react-hook-form";

import {
  FormType,
  SchoolDistributionEnum,
} from "../usePackageDistributionModal";

interface Props {
  getValues: UseFormGetValues<FormType>;
  watch: UseFormWatch<FormType>;
  distributionType: DistributionEnum;
}

const StepFour: React.FC<Props> = ({ getValues, watch, distributionType }) => {
  const {
    startDate,
    endDate,
    grade,
    tagIds,
    schoolTags,
    schoolDistributionType,
  } = getValues();
  const studentIds = watch("studentIds");
  const learningTemplateDistributionCache = useAppSelector(
    superOwnerAppLearningTemplateDistributionState,
  );

  const { studentsAffiliate } = learningTemplateDistributionCache;
  const isTagShow =
    schoolDistributionType === SchoolDistributionEnum.BY_SCHOOL_TAG ||
    distributionType === DistributionEnum.BY_LIMITED_USER_TAG ||
    distributionType === DistributionEnum.BY_USER_TAG;
  const selectedStudents = _.compact(
    studentsAffiliate.map((student) => {
      if (studentIds.includes(student.affiliate_id)) {
        return student;
      }
      return null;
    }),
  );

  const grepJpDistributionMethod = (type: DistributionEnum) => {
    switch (type) {
      case DistributionEnum.BY_SCHOOL:
        return "学校で配信する";
      case DistributionEnum.BY_LIMITED_USER_TAG:
        return "限定ユーザータグで配信する";
      case DistributionEnum.BY_USER_TAG:
        return "ユーザータグで配信する";
      default:
        return null;
    }
  };

  const grepTags = (type: DistributionEnum) => {
    switch (type) {
      case DistributionEnum.BY_SCHOOL:
        return schoolTags;
      case DistributionEnum.BY_USER_TAG:
      case DistributionEnum.BY_LIMITED_USER_TAG:
        return tagIds;
      default:
        return null;
    }
  };

  const distributionMethodJp = grepJpDistributionMethod(distributionType);
  const tags = grepTags(distributionType);

  const schoolName = watch("schools")
    .map((school) => school.name)
    .toString();

  return (
    <>
      <FormSectionWrap>
        <FormCategoryTitle>配信開始日</FormCategoryTitle>
        <Typography>
          {startDate ? dayjs(startDate).format("YYYY年M月DD日") : "なし"}
        </Typography>
      </FormSectionWrap>

      <FormSectionWrap>
        <FormCategoryTitle>配信終了日</FormCategoryTitle>
        <Typography>
          {endDate ? dayjs(endDate).format("YYYY年M月DD日") : "なし"}
        </Typography>
      </FormSectionWrap>

      <FormSectionWrap>
        <FormCategoryTitle>配信方法</FormCategoryTitle>
        <Typography>{distributionMethodJp}</Typography>
      </FormSectionWrap>

      {isTagShow ? (
        <FormSectionWrap>
          <FormCategoryTitle>タグの選択</FormCategoryTitle>
          <Box>
            {tags?.map((tag) => (
              <StyledTag
                key={`tag-${tag.name}`}
                tagColor={StyledTagColor.LightGrey}
                label={tag.name}
                avatar={<Fuda />}
                textColor={StyledTextColor.Green}
              />
            ))}
          </Box>
        </FormSectionWrap>
      ) : null}
      <FormSectionWrap>
        <FormCategoryTitle>学校名</FormCategoryTitle>
        <Typography>
          {schoolName || (
            <span style={{ color: "red" }}>対象学校がありません。</span>
          )}
        </Typography>
      </FormSectionWrap>

      <FormSectionWrap>
        <FormCategoryTitle>学年</FormCategoryTitle>
        <Typography>
          {grade ? getGradeName(grade) : "学年の設定がありません。"}
        </Typography>
      </FormSectionWrap>
      <FormSectionWrap>
        <FormCategoryTitle>表示名</FormCategoryTitle>
        <Typography>
          {selectedStudents.map((student) => (
            <StyledTag
              key={`student-${student?.affiliate_id}`}
              tagColor={StyledTagColor.LightGrey}
              label={`${student?.last_name} ${student?.first_name}`}
              textColor={StyledTextColor.Green}
            />
          ))}
        </Typography>
      </FormSectionWrap>
    </>
  );
};

export default StepFour;
