import {
  fetchOneStudentMaterialWorkAsTeacher,
  fetchStudentMaterialWorksAsTeacher,
} from "@actions/teacherApp/studentMaterialWork";
import { MaterialWork, TeacherMaterialWork } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";

export interface TeacherAppStudentMaterialWorkState {
  fetching: boolean;
  fetchingOne: boolean;
  totalCount: number;
  fetchError: any;
  materialWork: TeacherMaterialWork | null;
  materialWorks: MaterialWork[];
}

export const initialState: TeacherAppStudentMaterialWorkState = {
  fetching: false,
  fetchingOne: false,
  totalCount: 0,
  fetchError: null,
  materialWork: null,
  materialWorks: [],
};

const teacherAppStudentMaterialWorkSlice = createSlice({
  name: "TeacherApp/StudentMaterialWork",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudentMaterialWorksAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          serverError: null,
        };
      })
      .addCase(fetchStudentMaterialWorksAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          serverError: action.error.message as string,
        };
      })
      .addCase(
        fetchStudentMaterialWorksAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            materialWorks: payload,
          };
        },
      )

      .addCase(
        fetchOneStudentMaterialWorkAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingOne: true,
            materialWork: null,
            fetchError: null,
          };
        },
      )
      .addCase(
        fetchOneStudentMaterialWorkAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingOne: false,
            fetchError: action.error.message as string,
          };
        },
      )
      .addCase(
        fetchOneStudentMaterialWorkAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingOne: false,
            materialWork: payload,
          };
        },
      )
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppStudentMaterialWorkState = (
  state: RootState,
): TeacherAppStudentMaterialWorkState => state.teacherApp.studentMaterialWork;

export default teacherAppStudentMaterialWorkSlice.reducer;
