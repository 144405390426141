import DownloadIcon from "@assets/images/svg/download_icon.svg";
import EditIcon from "@assets/images/svg/editIcon.svg";
import DeleteIcon from "@assets/images/svg/GreenTrash.svg";
import LazyImageModal from "@components/UI/organisms/Modal/LazyImageModal";
import V2IconButton from "@components/UIv2/atoms/buttons/V2IconButton";
import V2FileName from "@components/UIv2/atoms/V2FileName";
import { isImage, isPdf } from "@lib/file-utils";
import { isTouchDevice } from "@lib/responsive-utils";
import { Box, Grow, Menu,Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { V2_COLORS } from "@root/styles/v2/v2Colors";
import React, { lazy, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AttachmentFileModalStateType,
  AttachmentInfo,
  defaultFileModalState,
} from "./types";
import MenuItems from "./V2AttachmentDownloadMenuItems";

const V2AttachmentThumbnail = lazy(() => import("./V2AttachmentThumbnail"));

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  imgBox: {
    display: "flex",
    justifyContent: "center",
    border: `2px dashed ${V2_COLORS.black}4D`, // 4D: opacity 30%
    borderRadius: "8px",
    overflow: "hidden",
  },
  infoBox: {
    display: "flex",
    gap: theme.spacing(0.5),
    minHeight: 25,
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionBtnBox: {
    gap: theme.spacing(0.5),
  },
  actionBtn: {
    padding: 0,
  },
}));

type Props = {
  attachment: AttachmentInfo;
  handleDownload?: (attachment: AttachmentInfo) => void;
  handleQRDownload?: (attachment: AttachmentInfo) => void;
  handleEdit?: (attachment: AttachmentInfo) => void;
  handleDelete?: (attachment: AttachmentInfo) => void;
  onClick?: (attachment: AttachmentInfo) => void;
};

const V2AttachmentItem: React.FC<Props> = ({
  attachment,
  handleDownload,
  handleQRDownload,
  handleEdit,
  handleDelete,
  onClick,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const isImageFile = isImage(attachment.url);
  const isPdfFile = isPdf(attachment.url);
  const showActionButtons = isHover || isTouchDevice();
  const isQRDownload = Boolean(handleQRDownload);
  const isDownload = Boolean(handleDownload);
  const isDelete = Boolean(handleDelete);
  const isEdit = Boolean(handleEdit) && (isImageFile || isPdfFile);
  const hasActionButton = isDownload || isDelete || isEdit || isQRDownload;
  const { t } = useTranslation("files");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [fileModalState, setFileModalState] =
    useState<AttachmentFileModalStateType>(defaultFileModalState);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onClick(attachment);
    } else if (isImageFile || isPdfFile) {
      if (anchorEl) {
        setFileModalState({
          isOpen: false,
          modalUrl: "",
          modalTitle: "",
        });
      } else {
        setFileModalState({
          isOpen: true,
          modalUrl: attachment.url || "",
          modalTitle: attachment.title || "",
        });
      }
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (handleDownload) {
      handleDownload(attachment);
    } else if (handleQRDownload) {
      handleQRDownload(attachment);
    }
  };

  const handleDownloadMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleFileModalClose = () => {
    setFileModalState(defaultFileModalState);
  };

  const handleDeleteBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (handleDelete) {
      handleDelete(attachment);
    }
  };

  const handleEditBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (handleEdit) {
      handleEdit(attachment);
    }
  };

  return (
    <>
      <LazyImageModal
        url={fileModalState.modalUrl}
        title={fileModalState.modalTitle}
        open={fileModalState.isOpen}
        handleClose={handleFileModalClose}
        canRotate
      />
      <Box
        data-cy="attachment-item"
        className={classes.container}
        onMouseLeave={() => setIsHover(false)}
        onMouseOver={() => setIsHover(true)}
        onClick={(e) => handleClick(e)}
      >
        <Box className={classes.imgBox}>
          <Suspense fallback={<></>}>
            <V2AttachmentThumbnail attachment={attachment} />
          </Suspense>
        </Box>
        <Box className={classes.infoBox}>
          <V2FileName fileName={attachment.title} />
          <Grow in={showActionButtons}>
            <Box
              className={classes.actionBtnBox}
              style={{
                display: showActionButtons && hasActionButton ? "flex" : "none",
              }}
            >
              {(isDownload || isQRDownload) && (
                <V2IconButton
                  data-cy="download-button"
                  title={t("download")}
                  placement="top"
                  aria-label="Download"
                  className={classes.actionBtn}
                  onClick={
                    isDownload && isQRDownload
                      ? handleDownloadMenuClick
                      : handleDownloadBtnClick
                  }
                >
                  <img src={DownloadIcon} alt="download" />
                </V2IconButton>
              )}
              {isDownload && isQRDownload && (
                <Menu
                  id="download-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  style={{ padding: 15 }}
                  keepMounted
                >
                  <MenuItems
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    handleDownload={handleDownload!}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    handleQRDownload={handleQRDownload!}
                    attachment={attachment}
                    handleMenuClose={handleMenuClose}
                  />
                </Menu>
              )}
              {isEdit && (
                <V2IconButton
                  title={t("update")}
                  placement="top"
                  aria-label="Edit"
                  className={classes.actionBtn}
                  onClick={handleEditBtnClick}
                >
                  <img src={EditIcon} alt="edit" />
                </V2IconButton>
              )}
              {isDelete && (
                <V2IconButton
                  title={t("delete")}
                  placement="top"
                  aria-label="Delete"
                  className={classes.actionBtn}
                  onClick={handleDeleteBtnClick}
                >
                  <img src={DeleteIcon} alt="delete" />
                </V2IconButton>
              )}
            </Box>
          </Grow>
        </Box>
      </Box>
    </>
  );
};

V2AttachmentItem.defaultProps = {
  handleDownload: undefined,
  handleEdit: undefined,
  handleDelete: undefined,
  onClick: undefined,
  handleQRDownload: undefined,
};

export default V2AttachmentItem;
