import styled from "styled-components";

export const FormSectionWrap = styled.div`
  margin: 1rem 0 1.5rem;
`;

export const FormCategoryTitle = styled.h4`
  margin: 1rem 0 0.5rem;
  font-size: "16px";
`;

export const FormCategoryTitleSecond = styled.h4`
  margin: 0 0 0.5rem;
  font-size: "16px";
`;

export const FormAnnotation = styled.p`
  margin: 1rem 0 0.5rem;
  color: dimgrey;
`;
export const FormIconAnnotation = styled.div`
  display: flex;
`;
