/* eslint-disable camelcase */
import { Api, GetContentProviderSponsorInfosParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchOnePblSponsorInfoAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/sponsor/infos/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getContentProviderSponsorInfosId(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblSponsorInfoAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/sponsor/infos/fetch",
  async (params: GetContentProviderSponsorInfosParams, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getContentProviderSponsorInfos(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const reset = createAction("contentProvider/pbl/sponsor/infos/reset");
export const resetOne = createAction(
  "contentProvider/pbl/sponsor/infos/resetOne",
);
