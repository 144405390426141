/* eslint-disable react/no-array-index-key */
import { Box, ListItem, ListItemIcon,Theme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemContentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      width: "100%",
    },
    listItem: {
      wordBreak: "break-word",
      margin: "20px, 0",
    },
    avatarContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
    tagSkeleton: {
      borderRadius: "20px",
      width: 75,
      height: 32,
    },
    text: {
      width: "100%",
      height: 40,
    },
  }),
);

const ListItemsSkeleton: React.FC = () => {
  const classes = useStyles();
  const skeletonArray = Array.from({ length: 5 }, (_, idx) => idx + 1);
  return (
    <>
      {skeletonArray.map((value) => {
        return (
          <ListItem classes={{ root: classes.listItem }} button key={value}>
            <ListItemIcon>
              <Box className={classes.avatarContainer}>
                <Skeleton variant="circular" width={50} height={50} />
              </Box>
            </ListItemIcon>
            <Box className={classes.listItemContentWrapper}>
              <span>
                <Skeleton
                  variant="rectangular"
                  className={classes.tagSkeleton}
                />
              </span>
              <Skeleton variant="text" className={classes.text} />
              <Skeleton variant="text" className={classes.text} />
            </Box>
          </ListItem>
        );
      })}
    </>
  );
};

export default ListItemsSkeleton;
