/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useAuth } from "@contexts/Auth";

const useOptionFeature = () => {
  const { currentUser } = useAuth();
  const options = currentUser?.company_authorization;

  return {
    isUseVideo: options?.use_video,
    isHomeworkFeature: options?.homework_feature,
    isBlogFeature: options?.blog_feature,
    isTextSellerFeature: options?.text_seller,
    isUseImage: options?.use_image,
    isMaterialFeature: options?.material,
    isTeacherMaterialFeature: options?.teacher_material,
    isReportFeature: options?.report,
    isPaymentFeature: options?.payment,
    isCalendarFeature: options?.calender,
    isReviewFeature: options?.review_feature,
    isReviewerCompanyFeature: options?.reviewer_company,
    isTagFeature: options?.tag,
    isGoogleClassroomFeature: options?.google_classroom,
    isSteamLibraryFeature: options?.steam_library,
    isContentCompanyFeature: options?.content_company,
    isContentCompanyReviewFeature: options?.content_company_review_feature,
    isDashboardFeature: options?.dashboard_feature,
    isStudyLogFeature: options?.study_log_feature,
    isIdealFeature: options?.ideal_feature,
    isPblBoardFeature: options?.pbl_board_feature,
    isExamFeature: options?.exam_feature,
    isRubricFeature: options?.rubric_feature,
    isWordCloudFeature: options?.word_cloud_feature,
    isRubricEvaluateFeature: options?.rubric_evaluate_feature,
    isGoogleDriveFeature: options?.google_drive_feature,
    isOneDriveFeature: options?.one_drive_feature,
    isUniversityCooperationFeature: options?.university_cooperation_feature,
    isArticleStudentCommentFeature: options?.article_student_comment_feature,
    isAutomaticScoring: options?.homework_automatic_scoring_feature,
    isClassNavFeature: options?.class_nav_feature,
    isPblProjectsFeature: options?.pbl_projects_feature,
    isGoGoFeature: options?.gogo_feature,
  };
};

export default useOptionFeature;
