/* eslint-disable camelcase */
import { AUTH } from "@constants/localStorageKeys";
import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import Cookies from "js-cookie";

const api = new Api();

export interface UpdateCurrentUserAsTeacherParams {
  first_name?: string;
  last_name?: string;
  teacher_info_attributes?: {
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    bank_name?: string;
    bank_branch_name?: string;
    bank_account_type?: string;
    bank_account_code?: string;
  };
}

export interface UpdateCurrentUserAsTeacherCombinedParams {
  userId: string;
  params: UpdateCurrentUserAsTeacherParams;
}

export const updateCurrentUserAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/updateCurrentUser",
  async (
    { userId, params }: UpdateCurrentUserAsTeacherCombinedParams,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.teacher.updateCurrentUserAsTeacher(
        userId,
        params,
      );
      const { headers } = response;
      const accessToken = headers["access-token"];
      const { client, uid } = headers;
      Cookies.set(AUTH.ACCESS_TOKEN, accessToken as string, { expires: 1 });
      Cookies.set(AUTH.CLIENT, client as string);
      Cookies.set(AUTH.UID, uid as string);
      return {
        user: response.data,
        accessToken,
        client,
        uid,
      } as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
