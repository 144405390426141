const prod = {
  url: {
    API_URL: "https://timetact-api.studyvalley.jp/v1",
    FRONT_URL: "https://timetact.studyvalley.jp",
    OR_KEY: "eInqluRBoa0Ob3sXhjJR",
    WS_URL: "wss://timetact-api.studyvalley.jp/cable",
    PUBLIC_PBL_URL: "https://pbl.studyvalley.jp",
  },
  sitekey: {
    recaptchaV2: "6LcyyFIbAAAAAONGs1CCDPEkkInI7d6B3F68GW7q",
    recaptchaV3: "6LeC2rgkAAAAAIT9kgVHAIIi8Hv_s_wea22-WzD-",
    recaptchaV3Action: "login",
  },
};
const staging = {
  url: {
    API_URL: "https://timetact-beta-api.studyvalley.jp/v1",
    FRONT_URL: "https://timetact-beta.studyvalley.jp",
    OR_KEY: "SRDRnS6wcMec5CbQVrrj",
    WS_URL: "wss://timetact-beta-api.studyvalley.jp/cable",
    PUBLIC_PBL_URL: "https://pbl-beta.studyvalley.jp",
  },
  sitekey: {
    recaptchaV2: "6LcyyFIbAAAAAONGs1CCDPEkkInI7d6B3F68GW7q",
    recaptchaV3: "6LeF3LgkAAAAAEBL8-EAqvtVobO_AsVISy22IXnY",
    recaptchaV3Action: "login",
  },
};
const development = {
  url: {
    API_URL: "http://localhost:3000/v1",
    FRONT_URL: "http://localhost:3002",
    OR_KEY: "",
    WS_URL: "ws://localhost:3000/cable",
    PUBLIC_PBL_URL: "http://localhost:3003",
  },
  sitekey: {
    recaptchaV2: "dummySiteKeyV2",
    recaptchaV3: "dummySiteKeyV3",
    recaptchaV3Action: "login",
  },
};

let tempConfig = null;
if (import.meta.env.VITE_SETTING === "production") {
  tempConfig = prod;
} else if (import.meta.env.VITE_SETTING === "staging") {
  tempConfig = staging;
} else {
  tempConfig = development;
}

// eslint-disable-next-line import/prefer-default-export
export const config = tempConfig;
