/* eslint-disable camelcase */
import { PostNotificationAsOwnerParams } from "@actions/types/notification";
import {
  Api,
  GetLimitedTimeNotificationsAsOwnerParams,
  NotificationRequest,
  NotificationsListParams,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchNotificationsSentAsOwner = createAsyncThunk<
  any,
  NotificationsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/notificationsSent/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.notificationsList({
      ...params,
      sent: "sent",
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const fetchLimitedTimeNotificationsAsOwner = createAsyncThunk<
  any,
  GetLimitedTimeNotificationsAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/limitedTimeNotifications/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.getLimitedTimeNotificationsAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const postNotificationAsOwner = createAsyncThunk<
  any,
  PostNotificationAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/notifications/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.createNotificationAsOwner({
      ...params,
      with_email: true,
    });
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const updateNotificationAsOwner = createAsyncThunk<
  any,
  {
    notifParams: NotificationRequest;
    notifId: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/notifications/put", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.putOwnerNotificationId(
      params.notifId,
      params.notifParams,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type PostLimitedTimeNotificationParams = {
  title: string;
  message: string;
  start_time: string;
  end_time: string;
  add_roles: string[];
  remove_role_ids?: string[];
  add_company_ids?: string[];
  add_user_tag_ids?: string[];
  remove_user_tag_ids?: string[];
};

export const postLimitedTimeNotificationAsOwner = createAsyncThunk<
  any,
  PostLimitedTimeNotificationParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/limitedTimeNotifications/post", async (data, { rejectWithValue }) => {
  try {
    const fd = new FormData();
    fd.set("title", data.title);
    fd.set("message", data.message);
    fd.set("start_time", data.start_time);
    fd.set("end_time", data.end_time);

    data.add_roles.forEach((role) => {
      fd.append("add_roles[]", role);
    });
    if (typeof data.add_company_ids !== "undefined") {
      data.add_company_ids.forEach((role) => {
        fd.append("add_company_ids[]", role);
      });
    }
    if (typeof data.add_user_tag_ids !== "undefined") {
      data.add_user_tag_ids.forEach((addUserTagIds) => {
        fd.append("add_user_tag_ids[]", addUserTagIds);
      });
    }
    if (typeof data.remove_user_tag_ids !== "undefined") {
      data.remove_user_tag_ids.forEach((removeUserTagIds) => {
        fd.append("remove_user_tag_ids[]", removeUserTagIds);
      });
    }
    const response = await http.post("/owner/limited_time_notifications", fd);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type PutLimitedTimeNotificationParams = {
  id: string;
  data: PostLimitedTimeNotificationParams;
};

export const putLimitedTimeNotificationAsOwner = createAsyncThunk<
  any,
  PutLimitedTimeNotificationParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/limitedTimeNotifications/put", async (params, { rejectWithValue }) => {
  const { id, data } = params;
  try {
    const fd = new FormData();
    fd.set("title", data.title);
    fd.set("message", data.message);
    fd.set("start_time", data.start_time);
    fd.set("end_time", data.end_time);

    data.add_roles.forEach((role) => {
      fd.append("add_roles[]", role);
    });

    if (typeof data.remove_role_ids !== "undefined") {
      data.remove_role_ids.forEach((roleId) => {
        fd.append("remove_role_ids[]", roleId);
      });
    }
    if (typeof data.add_user_tag_ids !== "undefined") {
      data.add_user_tag_ids.forEach((addUserTagIds) => {
        fd.append("add_user_tag_ids[]", addUserTagIds);
      });
    }
    if (typeof data.remove_user_tag_ids !== "undefined") {
      data.remove_user_tag_ids.forEach((userId) => {
        fd.append("remove_user_tag_ids[]", userId);
      });
    }

    const response = await http.put(
      `/owner/limited_time_notifications/${id}`,
      fd,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deleteNotificationAsOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/notifications/delete", async (notificationId, { rejectWithValue }) => {
  try {
    const response = await api.owner.deleteOwnerNotificationId(notificationId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deleteLimitedTimeNotificationAsOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/limitedNotification/delete",
  async (notificationId, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.deleteLimitedTimeNotificationAsOwner(notificationId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);
export const resetFormState = createAction("owner/notifications/resetState");
