import { Report } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  fetchReportAsStudent,
  fetchReportsAsStudent,
} from "../../actions/studentApp/report";
import { RootState } from "../../store";

// eslint-disable-next-line camelcase
export const defaultReport: Report & { message_to_student: string } = {
  teacher_name: "",
  title: "",
  detail: undefined,
  reply: undefined,
  status: undefined,
  created_at: undefined,
  student_name: undefined,
  next_goal: undefined,
  monthly_teaching_times: undefined,
  monthly_student_schedule_change: undefined,
  monthly_teacher_schedule_change: undefined,
  homework_frequency: undefined,
  is_schedule_change_required: undefined,
  schedule_detail_reason: undefined,
  test_misc: undefined,
  id: "",
  message_to_student: "",
};

export interface StudentAppReportState {
  fetching: boolean;
  fetched: boolean;
  updating: boolean;
  updated: boolean;
  error: any;
  reports: Report[];
  // eslint-disable-next-line camelcase
  report: Report & { message_to_student: string };
  totalCount: number;
}

export const initialState: StudentAppReportState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  error: null,
  reports: [],
  report: defaultReport,
  totalCount: 0,
};

const studentAppReportSlice = createSlice({
  name: "StudentApp/Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          reports: [],
        };
      })
      .addCase(fetchReportsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: action.error.message as string,
          reports: [],
        };
      })
      .addCase(fetchReportsAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          reports: payload.reports,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchReportAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchReportAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: action.error.message as string,
        };
      })
      .addCase(fetchReportAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          report: payload,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppReportState = (
  state: RootState,
): StudentAppReportState => state.studentApp.report;

export default studentAppReportSlice.reducer;
