/* eslint-disable @typescript-eslint/ban-types */
import { DistributionEnum } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList";
import { FormSectionWrap } from "@components/UI/atoms/Forms/FormComponents";
import { AffiliateStudentName } from "@lib/Api";
import { Checkbox } from "@mui/material";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";

import { FormType } from "../../usePackageDistributionModal";
import Body from "./Body";

interface Props {
  setValue: UseFormSetValue<FormType>;
  selectedStudentIds: string[];
  students: AffiliateStudentName[];
  totalCount: number;
  control: Control<FormType, object>;
  reset: UseFormReset<FormType>;
  getValues: UseFormGetValues<FormType>;
  distributionType: DistributionEnum;
}

const useStyles = makeStyles({
  table: {
    width: "100%",
    borderRadius: 0,
    outline: "none",
    boxShadow: "none",
    border: "none",
  },
  checkboxContainer: {
    marginLeft: 3,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    textAlicgn: "right",
  },
  headerLeftSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  checkBoxText: {
    marginLeft: 6,
  },
});

const StudentList: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const {
    setValue,
    students,
    selectedStudentIds,
    totalCount,
    control,
    reset,
    getValues,
    distributionType,
  } = props;

  const filterCompaniesByStudentId = (studentIds: string[]) => {
    if (
      distributionType === DistributionEnum.BY_LIMITED_USER_TAG ||
      distributionType === DistributionEnum.BY_USER_TAG
    ) {
      const filterStudents = students.filter((student) =>
        studentIds.includes(student.affiliate_id),
      );
      const filteredCompanies = filterStudents.map(
        (student) => student.company,
      );
      const removeDuplications = filteredCompanies.filter(
        (obj, index, self) =>
          index ===
          self.findIndex((idx) => idx.id === obj.id && idx.name === obj.name),
      );

      setValue("schools", removeDuplications);
    }
  };

  const handleStudentIds = (checkedId: string): string[] => {
    const newIds = selectedStudentIds?.includes(checkedId)
      ? selectedStudentIds?.filter((id: string) => id !== checkedId)
      : [...(selectedStudentIds ?? []), checkedId];
    filterCompaniesByStudentId(newIds);
    return newIds;
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ids: string[] = students.map((student) => student.affiliate_id);
    filterCompaniesByStudentId(ids);
    if (event.target.checked) {
      reset({
        ...getValues(),
        studentIds: ids,
      });
    } else {
      reset({
        ...getValues(),
        studentIds: [],
      });
    }
  };

  const handleCheckedByGrade = (
    event: React.ChangeEvent<HTMLInputElement>,
    studentsInfos: AffiliateStudentName[],
  ) => {
    const filterStudentIds = studentsInfos.map(
      (student) => student.affiliate_id,
    );
    const newIds: string[] = selectedStudentIds;
    if (event.target.checked) {
      filterStudentIds.forEach((studentId: string) => {
        newIds.push(studentId);
      });
    } else {
      filterStudentIds.forEach((studentId: string) => {
        const indexToRemove = newIds.indexOf(studentId);
        if (indexToRemove > -1) {
          newIds.splice(indexToRemove, 1);
        }
      });
    }
    const newIdsFiltered = newIds.filter((id: string, index: number) => {
      return newIds.indexOf(id) === index;
    });
    filterCompaniesByStudentId(newIdsFiltered);
    setValue("studentIds", newIdsFiltered);
  };

  const isGradeCheckBoxChecked = (studentPerGrade: AffiliateStudentName[]) => {
    const filterStudentIds = studentPerGrade.map(
      (student) => student.affiliate_id,
    );
    let count = 0;
    selectedStudentIds.forEach((studentId: string) => {
      if (filterStudentIds.includes(studentId)) {
        count += 1;
      }
    });
    return filterStudentIds.length === count;
  };

  const gradeGroupingKeys = new Map<string, AffiliateStudentName[]>();
  students.forEach((student) => {
    const key = student.grades?.[0]?.grade ?? "学年なし";
    const list = gradeGroupingKeys.get(key as string) ?? [];
    list.push(student);
    gradeGroupingKeys.set(key as string, list);
  });

  return (
    <FormSectionWrap style={{ minHeight: 300 }}>
      <div className={classes.checkboxContainer}>
        <div className={classes.headerLeftSide}>
          <Checkbox
            edge="end"
            color="primary"
            onChange={handleSelectAllClick}
            data-cy="select-all"
          />
          <p className={classes.checkBoxText}>まとめて選択</p>
          <p
            style={{ textAlign: "right" }}
          >{`： ${selectedStudentIds.length}人選択中`}</p>
        </div>
      </div>
      <Controller
        control={control}
        name="studentIds"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              {error && !value.length && (
                <Alert style={{ margin: "10px 0" }} severity="error">
                  {error.message}
                </Alert>
              )}
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    {Array.from(gradeGroupingKeys.keys()).map((key) => {
                      const studentsList = gradeGroupingKeys.get(key);
                      return (
                        <Body
                          key={`grade-${key}`}
                          gradeName={key}
                          handleStudentIds={handleStudentIds}
                          handleCheckedByGrade={handleCheckedByGrade}
                          value={value}
                          onChange={onChange}
                          students={studentsList as AffiliateStudentName[]}
                          checkIfGradeIsChecked={isGradeCheckBoxChecked}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {totalCount === 0 && (
                <p>現在の検索条件で、未配信の生徒はいません。</p>
              )}
            </>
          );
        }}
        rules={{ required: "生徒の設定は必須です。" }}
      />
    </FormSectionWrap>
  );
};

export default StudentList;
