import { Box,Theme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: theme.spacing(1),
    },
    sectionTitleContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.customColor.text}`,
    },
    skeleton: {
      backgroundColor: theme.palette.action.hover,
    },
    test: {
      clipPath:
        "polygon(50% 0%, 58% 0, 59% 66%, 86% 66%, 69% 83%, 50% 100%, 29% 83%, 10% 67%, 41% 67%, 41% 0)",
    },
  }),
);

const SectionDataSkeleton: React.FC = () => {
  const classes = useStyles();
  const skeletonData = Array.from({ length: 5 }, (_, idx) => idx + 1);
  return (
    <Box className={classes.container}>
      {skeletonData.map((value) => (
        <Box key={value} className={classes.sectionTitleContainer}>
          <Box>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              className={classes.skeleton}
            />
          </Box>
          <Box style={{ width: "120px" }}>
            <Skeleton
              variant="text"
              width="100%"
              className={classes.skeleton}
            />
          </Box>
          <Box>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              className={`${classes.test}`}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SectionDataSkeleton;
