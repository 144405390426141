/* eslint-disable camelcase */
import { batchUpdateBoardsAffiliateParams } from "@actions/types/ideal";
import { Api } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import _ from "lodash";

const api = new Api();

export const batchUpdateBoardsAffiliateAsStudent = createAsyncThunk<
  any,
  batchUpdateBoardsAffiliateParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/idealBoardsAffiliate/batchUpdate",
  async (params, { rejectWithValue }) => {
    try {
      const { idealBoardId, oldStudents, newStudents } = params;
      const responses: any[] = [];
      const oldStudentsIDs: string[] = oldStudents.map(
        (oldStudent) => oldStudent?.affiliate_id as string,
      );
      const newStudentsIDs: string[] = newStudents.map(
        (newStudent) => newStudent?.affiliate_id as string,
      );

      const removeIds = _.difference(oldStudentsIDs, newStudentsIDs);
      const postIds = _.difference(newStudentsIDs, oldStudentsIDs);

      if (removeIds) {
        responses.push(
          await Promise.all(
            removeIds.map((removeId) => {
              return api.student.deleteIdealBoardsAffiliatesAsStudent({
                ideal_board_id: idealBoardId,
                affiliate_id: removeId,
              });
            }),
          ),
        );
      }

      if (postIds) {
        responses.push(
          await Promise.all(
            postIds.map((postId) => {
              return api.student.postIdealBoardsAffiliatesAsStudent({
                ideal_board_id: idealBoardId,
                affiliate_id: postId,
              });
            }),
          ),
        );
      }
      return responses;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetStudentAppIdealBoardsAffiliates = createAction(
  "studentApp/ideal/boardsAffiliates/resetState",
);
