/* eslint-disable camelcase */
import { Api, FetchPblCardBookmarkCommentsAsTeacherParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblCardBookmarkCommentsAsTeacher = createAsyncThunk<
  any,
  FetchPblCardBookmarkCommentsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/cards/bookmarks/comments/fetch",
  async (
    params: FetchPblCardBookmarkCommentsAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.fetchPblCardBookmarkCommentsAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreatePblCardBookmarkCommentAsTeacherParams {
  bookmark_id: string;
  body: string;
}

export const createPblCardBookmarkCommentAsTeacher = createAsyncThunk<
  any,
  CreatePblCardBookmarkCommentAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/cards/bookmarks/comments/create",
  async (
    params: CreatePblCardBookmarkCommentAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.createPblCardBookmarkCommentAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdatePblCardBookmarkCommentAsTeacherParams {
  body: string;
}

export interface UpdatePblCardBookmarkAsTeacherArg {
  id: string;
  params: UpdatePblCardBookmarkCommentAsTeacherParams;
}

export const updatePblCardBookmarkCommentAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/cards/bookmarks/comments/update",
  async (arg: UpdatePblCardBookmarkAsTeacherArg, { rejectWithValue }) => {
    try {
      const response = await api.teacher.updatePblCardBookmarkCommentAsTeacher(
        arg.id,
        arg.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblCardBookmarkCommentAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/cards/bookmarks/comments/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.deletePblCardBookmarkCommentAsTeacher(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "teacher/pbl/cards/bookmarks/comments/resetState",
);
export const resetActionState = createAction(
  "teacher/pbl/cards/bookmarks/comments/resetActionState",
);
