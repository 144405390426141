import {
  getSteamLibraryContentDetail,
  getSteamLibraryContentDetailMoreLectures,
  getSteamLibraryContentImages,
  getSteamLibraryLectureDetail,
  getSteamLibraryLecturesNumber,
} from "@actions/steamLibrary";
import {
  SteamLibraryContentDetail,
  SteamLibraryContentImage,
  SteamLibraryLectureCard,
  SteamLibraryLectureDetail,
} from "@actions/types/steamLibrary";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

interface SteamLibraryState {
  fetchSteamContent: ReduxStatusType;
  fetchSteamLecture: ReduxStatusType;
  contentDetail: SteamLibraryContentDetail | null;
  lectureDetail: SteamLibraryLectureDetail | null;
  fetchSteamContentError: string | null;
  fetchSteamLectureError: string | null;
  contentImages: SteamLibraryContentImage[];
  fetchSteamLecturesWithNumber: ReduxStatusType;
  steamLecturesWithNumber: any;
}

const initialState: SteamLibraryState = {
  fetchSteamContent: ReduxStatus.idle,
  fetchSteamLecture: ReduxStatus.idle,
  contentDetail: null,
  lectureDetail: null,
  fetchSteamContentError: null,
  fetchSteamLectureError: null,
  contentImages: [],
  fetchSteamLecturesWithNumber: ReduxStatus.idle,
  steamLecturesWithNumber: null,
};

const steamLibrarySlice = createSlice({
  name: "SteamLibrary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSteamLibraryContentDetail.pending, (state, _action) => {
        return {
          ...state,
          fetchSteamContent: ReduxStatus.pending,
          fetchSteamContentError: null,
        };
      })
      .addCase(getSteamLibraryContentDetail.fulfilled, (state, action) => {
        return {
          ...state,
          fetchSteamContent: ReduxStatus.fulfilled,
          contentDetail: action.payload,
        };
      })
      .addCase(getSteamLibraryContentDetail.rejected, (state, _action) => {
        return {
          ...state,
          fetchSteamContent: ReduxStatus.rejected,
          fetchSteamContentError: "データが見つかりませんでした。",
        };
      })
      .addCase(getSteamLibraryLectureDetail.pending, (state, _action) => {
        return {
          ...state,
          fetchSteamLecture: ReduxStatus.pending,
          fetchSteamLectureError: null,
        };
      })
      .addCase(getSteamLibraryLectureDetail.fulfilled, (state, action) => {
        return {
          ...state,
          fetchSteamLecture: ReduxStatus.fulfilled,
          lectureDetail: action.payload,
        };
      })
      .addCase(getSteamLibraryLectureDetail.rejected, (state, _action) => {
        return {
          ...state,
          fetchSteamLecture: ReduxStatus.rejected,
          fetchSteamLectureError: "データが見つかりませんでした。",
        };
      })
      .addCase(
        getSteamLibraryContentDetailMoreLectures.fulfilled,
        (state, action) => {
          const newLectures: SteamLibraryLectureCard[] = action.payload.map(
            (lecture: SteamLibraryLectureCard) => ({
              id: lecture.id,
              title: lecture.title,
              sequence: lecture.sequence,
              tags: lecture.tags,
              thumbnail: lecture.thumbnail,
            }),
          );
          return {
            ...state,
            contentDetail: {
              ...(state.contentDetail as SteamLibraryContentDetail),
              content_items: newLectures,
            },
          };
        },
      )
      .addCase(getSteamLibraryContentImages.fulfilled, (state, action) => {
        return {
          ...state,
          contentImages: action.payload.contents,
        };
      })
      .addCase(getSteamLibraryLecturesNumber.fulfilled, (state, action) => {
        return {
          ...state,
          fetchSteamLecturesWithNumber: ReduxStatus.fulfilled,
          steamLecturesWithNumber: action.payload.contents,
        };
      });
  },
});

export const steamLibraryState = (state: RootState): SteamLibraryState =>
  state.steamLibrary;

export default steamLibrarySlice.reducer;
