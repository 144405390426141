/* eslint-disable camelcase */
import { Api, GetTeacherPblReportTemplatesIdAnswersParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblReportAnswerAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/answers/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherPblReportAnswerId(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblReportTemplateAnswersAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/template/answers/fetch",
  async (
    params: GetTeacherPblReportTemplatesIdAnswersParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.getTeacherPblReportTemplatesIdAnswers(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface CreatePblReportAnswerAsTeacherParams {
  student_id: string;
  template_id: string;
}

export const createPblReportAnswerAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/answers/create",
  async (params: CreatePblReportAnswerAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.postTeacherPblReportAnswer(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetOneAnswer = createAction(
  "teacher/pbl/report/templates/answers/resetOne",
);

export const resetAnswers = createAction(
  "teacher/pbl/report/templates/answers/reset",
);
