import {
  ActivityParams,
  fetchStudyLogsActivityAsTeacher,
  fetchStudyLogsAsTeacher,
  fetchStudyLogsByStudentsAsTeacher,
  fetchStudyLogsStatsAsTeacher,
  resetOne,
  StatsParams,
} from "@actions/teacherApp/studentLogs";
import { StudyByStudentBase,StudyLogBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface TeacherAppStudentLogsState {
  fetchingLogs: boolean;
  fetchingLogsByStudents: boolean;
  fetchedLogs: boolean;
  fetchedLogsByStudents: boolean;
  fetchLogsErrors: string | null;
  fetchLogsError: string | null;
  fetchingLogsActivity: boolean;
  fetchedLogsActivity: boolean;
  fetchLogsActivityError: string | null;
  fetchLogsByStudentError: string | null;
  fetchingLogsStats: boolean;
  fetchedLogsStats: boolean;
  fetchLogsStatsError: string | null;
  stats: StatsParams;
  activity: ActivityParams;
  studyLogs: StudyLogBase[];
  studyLogsByStudents: StudyByStudentBase[];
  totalLogsCount: number;
  totalStudentsCount: number;
}

export const initialState: TeacherAppStudentLogsState = {
  fetchingLogs: false,
  fetchingLogsByStudents: false,
  fetchedLogs: false,
  fetchedLogsByStudents: false,
  fetchLogsErrors: null,
  fetchLogsError: null,
  fetchLogsByStudentError: null,
  fetchingLogsActivity: false,
  fetchedLogsActivity: false,
  fetchLogsActivityError: null,
  fetchingLogsStats: false,
  fetchedLogsStats: false,
  fetchLogsStatsError: null,
  stats: {
    study_logs: [],
    daily_logs: [],
    this_week_daily_logs: [],
    total_count: 0,
  },
  activity: {
    this_week_daily_logs: [],
    last_week_daily_logs: [],
  },
  studyLogs: [],
  studyLogsByStudents: [],
  totalLogsCount: 0,
  totalStudentsCount: 0,
};

const teacherAppPblSponsorInfoSlice = createSlice({
  name: "teacherApp/student_logs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudyLogsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingLogs: true,
          fetchedLogs: false,
        };
      })
      .addCase(fetchStudyLogsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingLogs: false,
          fetchedLogs: false,
          fetchLogsError: action.error.message as string,
        };
      })
      .addCase(fetchStudyLogsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingLogs: false,
          fetchedLogs: true,
          fetchLogsError: null,
          studyLogs: payload.study_logs,
          totalLogsCount: payload.total_count,
        };
      })
      .addCase(fetchStudyLogsByStudentsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingLogsByStudents: true,
          fetchedLogsByStudents: false,
        };
      })
      .addCase(fetchStudyLogsByStudentsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingLogsByStudents: false,
          fetchedLogsByStudents: false,
          fetchLogsByStudentError: action.error.message as string,
        };
      })
      .addCase(fetchStudyLogsByStudentsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingLogsByStudents: false,
          fetchedLogsByStudents: true,
          fetchLogsByStudentError: null,
          studyLogsByStudents: payload.study_logs,
          totalStudentsCount: payload.total_count,
        };
      })
      .addCase(fetchStudyLogsActivityAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingLogsActivity: true,
          fetchedLogsActivity: false,
        };
      })
      .addCase(fetchStudyLogsActivityAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingLogsActivity: false,
          fetchedLogsActivity: false,
          fetchLogsActivityError: action.error.message as string,
        };
      })
      .addCase(fetchStudyLogsActivityAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingLogsActivity: false,
          fetchedLogsActivity: true,
          fetchLogsActivityError: null,
          activity: payload,
        };
      })
      .addCase(fetchStudyLogsStatsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingLogsStats: true,
          fetchedLogsStats: false,
        };
      })
      .addCase(fetchStudyLogsStatsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingLogsStats: false,
          fetchedLogsStats: false,
          fetchLogsStatsError: action.error.message as string,
        };
      })
      .addCase(fetchStudyLogsStatsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingLogsStats: false,
          fetchedLogsStats: true,
          fetchLogsStatsError: null,
          stats: payload,
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          infoDetails: null,
          infoList: [],
          fetched: false,
          fetchedOne: false,
          fetchError: null,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppStudentLogsState = (
  state: RootState,
): TeacherAppStudentLogsState => state.teacherApp.study;

export default teacherAppPblSponsorInfoSlice.reducer;
