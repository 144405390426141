import {
  addProjectBookmarkAsTeacher,
  fetchProjectBookmarkByProjectAsTeacher,
  fetchProjectBookmarksAsTeacher,
  removeProjectBookmarkAsTeacher,
  resetAction,
  resetAddAction,
  resetOne,
  resetRemoveAction,
} from "@actions/teacherApp/pbl/sponsor/bookmark";
import { SponsorProjectBookmarkBase } from "@lib/Api";
import { remove } from "@lib/collection";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface TeacherAppSponsorProjectBookmarkState {
  fetching: boolean;
  fetchingOne: boolean;
  fetched: boolean;
  fetchedOne: boolean;
  fetchErrors: string | null;
  fetchOneErrors: string | null;
  bookmarks: SponsorProjectBookmarkBase[];
  totalCount: number;
  adding: boolean;
  added: boolean;
  removing: boolean;
  removed: boolean;
}

export const initialState: TeacherAppSponsorProjectBookmarkState = {
  fetching: false,
  fetchingOne: false,
  fetched: false,
  fetchedOne: false,
  fetchErrors: null,
  fetchOneErrors: null,
  bookmarks: [],
  totalCount: 0,
  adding: false,
  added: false,
  removing: false,
  removed: false,
};

const teacherAppSponsorProjectBookmarkSlice = createSlice({
  name: "teacherApp/pbl/sponsors/projects/bookmarks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectBookmarksAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchProjectBookmarksAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchProjectBookmarksAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: null,
          bookmarks: payload.bookmarks,
          totalCount: payload.total_count,
        };
      })
      .addCase(
        fetchProjectBookmarkByProjectAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingOne: true,
            fetchedOne: false,
          };
        },
      )
      .addCase(
        fetchProjectBookmarkByProjectAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: false,
            fetchOneError: action.error.message as string,
          };
        },
      )
      .addCase(
        fetchProjectBookmarkByProjectAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: true,
            fetchOneError: null,
            bookmarks: payload.id
              ? [...state.bookmarks, payload]
              : state.bookmarks,
          };
        },
      )
      .addCase(addProjectBookmarkAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          adding: true,
          added: false,
        };
      })
      .addCase(addProjectBookmarkAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          adding: false,
          added: false,
        };
      })
      .addCase(addProjectBookmarkAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          adding: false,
          added: true,
          bookmarks: [...state.bookmarks, payload],
        };
      })
      .addCase(removeProjectBookmarkAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          removing: true,
          removed: false,
        };
      })
      .addCase(removeProjectBookmarkAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          removing: false,
          removed: false,
        };
      })
      .addCase(removeProjectBookmarkAsTeacher.fulfilled, (state, action) => {
        const { meta } = action;
        return {
          ...state,
          removing: false,
          removed: true,
          bookmarks: remove(state.bookmarks, meta.arg),
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          bookmarks: [],
          fetched: false,
          fetchedOne: false,
          fetchError: null,
          fetchOneError: null,
        };
      })
      .addCase(resetAddAction, (state, _action) => {
        return {
          ...state,
          added: false,
          adding: false,
        };
      })
      .addCase(resetRemoveAction, (state, _action) => {
        return {
          ...state,
          removed: false,
          removing: false,
        };
      })
      .addCase(resetAction, (state, _action) => {
        return {
          ...state,
          added: false,
          adding: false,
          removed: false,
          removing: false,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppSponsorProjectBookmarkState = (
  state: RootState,
): TeacherAppSponsorProjectBookmarkState =>
  state.teacherApp.sponsorProjectBookmark;

export default teacherAppSponsorProjectBookmarkSlice.reducer;
