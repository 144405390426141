/* eslint-disable camelcase */
import {
  fetchContentNumberOfLecturesAsStudent,
  getNumberOfHistoriesAndLecturesAsStudent,
  postSteamLectureHistoriesAsStudent,
} from "@actions/studentApp/steam/steamLecture";
import { LecturesAndHistoriesCountResponse } from "@actions/types/steamLibrary";
import {
  LectureHistoriesStats,
  LectureHistoriesStatsAndLecturesCount,
} from "@components/Student/Dashboard/StudentSteamLibrary/types";
import { ReduxStatus,ReduxStatusType } from "@constants/redux";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface StudentAppSteamLectureHistoriesState {
  lectureHistoriesStats?: LectureHistoriesStats;
  fetchError: string | null;
  fetchAllStats: ReduxStatusType;
  updating: boolean;
  updated: boolean;
  statsContent?: LectureHistoriesStatsAndLecturesCount;
  fetchLecturesCount: ReduxStatusType;
  lecturesCountError: string | null;
  lecturesCount: LecturesAndHistoriesCountResponse[];
}

const initialState: StudentAppSteamLectureHistoriesState = {
  lectureHistoriesStats: undefined,
  fetchError: null,
  updating: false,
  fetchAllStats: ReduxStatus.idle,
  updated: true,
  statsContent: undefined,
  fetchLecturesCount: ReduxStatus.idle,
  lecturesCountError: null,
  lecturesCount: [],
};

const studentAppSteamLectureSlice = createSlice({
  name: "StudentApp/SteamLectureHistories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSteamLectureHistoriesAsStudent.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(
        postSteamLectureHistoriesAsStudent.fulfilled,
        (state, _action) => {
          return {
            ...state,
            updating: false,
            updated: true,
          };
        },
      )
      .addCase(postSteamLectureHistoriesAsStudent.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          updateError: action.payload.errors,
        };
      })
      .addCase(
        fetchContentNumberOfLecturesAsStudent.pending,
        (state, _action) => {
          return {
            ...state,
            fetchAllStats: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetchContentNumberOfLecturesAsStudent.fulfilled,
        (state, action) => {
          return {
            ...state,
            statsContent: action.payload,
            fetchAllStats: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        fetchContentNumberOfLecturesAsStudent.rejected,
        (state, _action) => {
          return {
            ...state,
            fetchAllStats: ReduxStatus.rejected,
            fetchError: "データが取得できませんでした。",
          };
        },
      )
      .addCase(
        getNumberOfHistoriesAndLecturesAsStudent.pending,
        (state, _action) => {
          return {
            ...state,
            fetchLecturesCount: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getNumberOfHistoriesAndLecturesAsStudent.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetchLecturesCount: ReduxStatus.fulfilled,
            lecturesCount: action.payload,
          };
        },
      )
      .addCase(
        getNumberOfHistoriesAndLecturesAsStudent.rejected,
        (state, _action) => {
          return {
            ...state,
            fetchLecturesCount: ReduxStatus.rejected,
            lecturesCountError: "エラーが発生しました。 ",
          };
        },
      );
  },
});

export const StudentAppSteamLectureHistoriesState = (
  state: RootState,
): StudentAppSteamLectureHistoriesState =>
  state.studentApp.steamLectureHistories;

export default studentAppSteamLectureSlice.reducer;
