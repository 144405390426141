import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      padding: 0,
      margin: "0 10px 0 10px",
      fontSize: "16px",
      lineHeight: "24px",
      color: theme.palette.customColor.text,
    },
    selectedPosition: {
      backgroundColor: "lightgrey",
    },
    adjustBar: {
      width: "5px",
      height: "100%",
      position: "absolute",
      borderRadius: 5,
      border: `1px solid ${theme.palette.common.black}`,
      backgroundColor: "white",
      opacity: 0.5,
      top: 0,
    },
    adjustPosition: {
      textAlign: "left",
      position: "absolute",
      top: 0,
      right: 0,
      backgroundColor: "white",
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: 5,
      zIndex: 2000,
    },
    imageDivStyle: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
    },
    ModalContainer: {
      position: "fixed",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 4000,
    },
    ListItemIconStyle: {
      minWidth: "32px !important",
    },
    AddButtonWrapper: {
      display: "flex",
      width: "52px",
      margin: "0",
      padding: "0",
    },
    DragIndicatorIcon: {
      margin: "0",
      padding: "0",
      "&:hover": {
        borderRadius: 0,
      },
    },
    DragIndicatorMenu: {
      border: "0px !important",
      padding: "10px 5px 10px 5px",
      zIndex: 100,
      "&:hover": {
        opacity: 0.5,
      },
    },
    MenuItem: {
      margin: "0 10px 0 10px",
      "&:hover": {
        margin: "0 10px 0 10px",
        borderRadius: 10,
        border: `1px solid ${theme.palette.tertiary.mainDark}`,
        backgroundColor: `${theme.palette.tertiary.mainDark}1A !important`,
      },
    },
    MenuItemButton: {
      display: "block",
      padding: 0,
      "&:hover": {
        backgroundColor: "initial",
      },
    },
    rightPointingTriangle: {
      width: 0,
      height: 0,
      borderLeft: "30px solid black",
      borderTop: "30px solid transparent",
      borderBottom: "30px solid transparent",
    },
    downPointingTriangle: {
      width: 0,
      height: 0,
      borderLeft: "30px solid transparent",
      borderTop: "30px solid transparent",
      borderBottom: "30px solid black",
    },
    divider: {
      margin: theme.spacing(1),
      height: 2,
    },
  }),
);

export default useStyles;
