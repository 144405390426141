/* eslint-disable camelcase */
import { CompanyAsSuperOwner } from "@lib/Api";
import { removeMany } from "@lib/collection";
import { MaterialCapacity } from "@reducers/adminApp/company";
import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import { batchCreate } from "../../actions/superOwnerApp/homeworkTemplateDistribution";
import {
  createCompanyAsSuperOwner,
  deleteCompanyAsSuperOwner,
  fetchCompaniesAsSuperOwner,
  fetchSuperOwnerCompany,
  fetchSuperOwnerMaterialCapacity,
  resetBatchCreateState,
  resetModalState,
  resetState,
  updateCompanyAsSuperOwner,
} from "../../actions/superOwnerApp/school";
import { RootState } from "../../store";

export const initialSchoolState: CompanyAsSuperOwner = {
  id: "",
  name: "",
  postcode: "",
  prefecture: "",
  address1: "",
  address2: "",
  phone: "",
  email: "",
  created_at: "",
  owner_number: 0,
  teacher_number: 0,
  student_number: 0,
  company_tags: [],
  logo: {},
};

export interface SuperOwnerSchoolsState {
  fetching: boolean;
  fetchingInModal: boolean;
  fetched: boolean;
  fetchedInModal: boolean;
  posting: boolean;
  posted: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  schoolsInModal: CompanyAsSuperOwner[];
  schools: CompanyAsSuperOwner[];
  school: CompanyAsSuperOwner;
  totalCount: number;
  totalCountInModal: number;
  error: any;
  postError: any;
  updateError: string | null;
  deleteError: any;
  fetchErrorInModal: string | null;
  batchCount: number;
  batchPostErrors: { index: number; message: string }[];
  materialCapacityDetail: MaterialCapacity;
  fetchingMaterialCapacity: boolean;
}

export const initialState: SuperOwnerSchoolsState = {
  fetching: false,
  fetchingInModal: false,
  fetched: false,
  fetchedInModal: false,
  fetchErrorInModal: null,
  posting: false,
  posted: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  schools: [],
  schoolsInModal: [],
  school: initialSchoolState,
  totalCount: 0,
  totalCountInModal: 0,
  error: null,
  postError: null,
  updateError: null,
  deleteError: null,
  batchCount: 0,
  batchPostErrors: [],
  materialCapacityDetail: null,
  fetchingMaterialCapacity: false,
};

export const superOwnerAppSchoolsSlice = createSlice({
  name: "SuperOwnerApp/Schools",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompaniesAsSuperOwner.pending, (state, action) => {
        const { meta } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: true,
            fetchedInModal: false,
          };
        }
        return {
          ...state,
          fetched: false,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchCompaniesAsSuperOwner.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: false,
            fetchedInModal: true,
            schoolsInModal: payload.companies,
            totalCountInModal: payload.total_count,
          };
        }
        return {
          ...state,
          fetched: true,
          fetching: false,
          error: null,
          schools: payload.companies,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchCompaniesAsSuperOwner.rejected, (state, action) => {
        const { meta } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: false,
            fetchedInModal: true,
            fetchErrorInModal: action.error.message as string,
          };
        }
        return {
          ...state,
          fetched: false,
          fetching: false,
          error: getErrorMessage(action.payload.error),
        };
      })
      .addCase(fetchSuperOwnerCompany.pending, (state, action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchSuperOwnerCompany.fulfilled, (state, action) => {
        return {
          ...state,
          fetched: true,
          fetching: false,
          error: null,
          school: action.payload,
        };
      })
      .addCase(fetchSuperOwnerCompany.rejected, (state, action) => {
        return {
          ...state,
          fetched: false,
          fetching: false,
          error: action.error,
        };
      })
      .addCase(fetchSuperOwnerMaterialCapacity.pending, (state, action) => {
        return {
          ...state,
          fetchingMaterialCapacity: true,
          error: null,
        };
      })
      .addCase(fetchSuperOwnerMaterialCapacity.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingMaterialCapacity: false,
          error: null,
          materialCapacityDetail: action.payload,
        };
      })
      .addCase(fetchSuperOwnerMaterialCapacity.rejected, (state, action) => {
        return {
          ...state,
          fetchingMaterialCapacity: false,
          error: getErrorMessage(action.payload.error),
        };
      })
      .addCase(createCompanyAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          posted: false,
          posting: true,
          postError: null,
        };
      })
      .addCase(createCompanyAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          posted: true,
          posting: false,
          postError: null,
          school: action.payload,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(createCompanyAsSuperOwner.rejected, (state, { payload }) => {
        const errorMessages = payload.error.errors?.map(
          (error: { index: number; message: string }) => error.message,
        );
        const errorString = errorMessages?.join("。");

        return {
          ...state,
          posted: false,
          posting: false,
          postError: payload?.error.errors,
          batchCount: state.batchCount + 1,
          batchPostErrors: payload?.requestIndex
            ? [
                ...state.batchPostErrors,
                {
                  index: payload.requestIndex - 1,
                  message: `${errorString}。`,
                },
              ]
            : [],
        };
      })
      .addCase(updateCompanyAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          updated: false,
          updating: true,
          updateError: null,
        };
      })
      .addCase(updateCompanyAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updated: true,
          updating: false,
          updateError: null,
          school: action.payload,
        };
      })
      .addCase(updateCompanyAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          updated: false,
          updating: false,
          updateError: action.payload,
        };
      })
      .addCase(deleteCompanyAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          deleted: false,
          deleting: true,
          deleteError: null,
        };
      })
      .addCase(deleteCompanyAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          deleted: true,
          deleting: false,
          deleteError: null,
        };
      })
      .addCase(deleteCompanyAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          deleted: false,
          deleting: false,
          deleteError: action.payload?.errors || ["エラーが発生しました。"],
        };
      })
      .addCase(batchCreate.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          schoolsInModal: removeMany(state.schoolsInModal, payload.company_ids),
          totalCountInModal:
            state.totalCountInModal - payload.company_ids.length,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: null,
          posting: false,
          posted: false,
          postError: null,
          updating: false,
          updated: false,
          updateError: null,
          deleting: false,
          deleted: false,
          deleteError: null,
        };
      })
      .addCase(resetModalState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchErrorInModal: null,
          schools: [],
          totalCountInModal: 0,
        };
      })
      .addCase(resetBatchCreateState, (state) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postError: null,
          batchCount: 0,
          batchPostErrors: [],
        };
      });
  },
});

export const superOwnerAppSchoolsState = (
  state: RootState,
): SuperOwnerSchoolsState => state.superOwnerApp.schools;

export default superOwnerAppSchoolsSlice.reducer;
