import {
  assignHomeworkTemplateChunkDistributionToReviewerAsReviewer,
  getHomeworkTemplateChunkDistributionAsReviewer,
  getHomeworkTemplateChunkDistributionsAsReviewer,
  putHomeworkTemplateChunkDistributionAsReviewer,
  resetState,
} from "@actions/reviewerApp/homeworkTemplateChunkDistribution";
import {
  HomeworkTemplateChunkDistributionDetail,
  HomeworkTemplateChunkDistributionList,
  HomeworkTemplateChunkDistributionStatuses,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface ReviewerAppHomeworkTemplateChunkDistributionState {
  getChunkDistributionsStatus: ReduxStatusType;
  getChunkDistributionStatus: ReduxStatusType;
  putChunkDistributionStatus: ReduxStatusType;
  assignChunkDistributionStatus: ReduxStatusType;
  chunkDistributions: HomeworkTemplateChunkDistributionList[];
  chunkDistribution: HomeworkTemplateChunkDistributionDetail | null;
  chunkDistributionStatuses: HomeworkTemplateChunkDistributionStatuses;
  totalCount: number;
  putChunkDistributionError: string | null;
  assignChunkDistributionError: string | null;
}

export const initialState: ReviewerAppHomeworkTemplateChunkDistributionState = {
  getChunkDistributionsStatus: ReduxStatus.idle,
  getChunkDistributionStatus: ReduxStatus.idle,
  putChunkDistributionStatus: ReduxStatus.idle,
  assignChunkDistributionStatus: ReduxStatus.idle,
  chunkDistributions: [],
  chunkDistribution: null,
  chunkDistributionStatuses: null,
  totalCount: 0,
  putChunkDistributionError: null,
  assignChunkDistributionError: null,
};

export const reviewerAppHomeworkTemplateChunkDistributionSlice = createSlice({
  name: "ReviewerApp/HomeworkTemplateChunkDistribution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getHomeworkTemplateChunkDistributionsAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            getChunkDistributionsStatus: ReduxStatus.pending,
            chunkDistributionStatuses: null,
            chunkDistributions: [],
          };
        },
      )
      .addCase(
        getHomeworkTemplateChunkDistributionsAsReviewer.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            getChunkDistributionsStatus: ReduxStatus.fulfilled,
            chunkDistributions: payload.homework_template_chunk_distributions,
            chunkDistributionStatuses:
              payload.template_chunk_distribution_statuses,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        getHomeworkTemplateChunkDistributionsAsReviewer.rejected,
        (state, _action) => {
          return {
            ...state,
            getChunkDistributionsStatus: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        getHomeworkTemplateChunkDistributionAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            getChunkDistributionStatus: ReduxStatus.pending,
            chunkDistribution: null,
          };
        },
      )
      .addCase(
        getHomeworkTemplateChunkDistributionAsReviewer.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            getChunkDistributionStatus: ReduxStatus.fulfilled,
            chunkDistribution: payload,
          };
        },
      )
      .addCase(
        getHomeworkTemplateChunkDistributionAsReviewer.rejected,
        (state, _action) => {
          return {
            ...state,
            getChunkDistributionStatus: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        putHomeworkTemplateChunkDistributionAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            putChunkDistributionStatus: ReduxStatus.pending,
            putChunkDistributionError: null,
          };
        },
      )
      .addCase(
        putHomeworkTemplateChunkDistributionAsReviewer.fulfilled,
        (state, action) => {
          return {
            ...state,
            putChunkDistributionStatus: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        putHomeworkTemplateChunkDistributionAsReviewer.rejected,
        (state, action) => {
          return {
            ...state,
            putChunkDistributionStatus: ReduxStatus.rejected,
            putChunkDistributionError: errorWithMessage(action.payload),
          };
        },
      )
      .addCase(
        assignHomeworkTemplateChunkDistributionToReviewerAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            assignChunkDistributionStatus: ReduxStatus.pending,
            assignChunkDistributionError: null,
          };
        },
      )
      .addCase(
        assignHomeworkTemplateChunkDistributionToReviewerAsReviewer.fulfilled,
        (state, _action) => {
          return {
            ...state,
            assignChunkDistributionStatus: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        assignHomeworkTemplateChunkDistributionToReviewerAsReviewer.rejected,
        (state, action) => {
          return {
            ...state,
            assignChunkDistributionStatus: ReduxStatus.rejected,
            assignChunkDistributionError: errorWithMessage(action.payload),
          };
        },
      )
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          getChunkDistributionsStatus: ReduxStatus.idle,
          getChunkDistributionStatus: ReduxStatus.idle,
          putChunkDistributionStatus: ReduxStatus.idle,
          assignChunkDistributionStatus: ReduxStatus.idle,
          putChunkDistributionError: null,
          assignChunkDistributionError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const reviewerAppHomeworkTemplateChunkDistributionState = (
  state: RootState,
): ReviewerAppHomeworkTemplateChunkDistributionState =>
  state.reviewerApp.homeworkTemplateChunkDistribution;

export default reviewerAppHomeworkTemplateChunkDistributionSlice.reducer;
