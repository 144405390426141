/* eslint-disable camelcase */
import { TagHashType } from "@constants/Tag/tags";
import { CreatedUserTagType, UserTagWithCompaniesNum } from "@lib/Api";
import { SuperOwnerCompanyTagTypesState } from "@reducers/superOwnerApp/companyTagType";

export type UserTagType = CreatedUserTagType;

export type UserTag = UserTagWithCompaniesNum & UserTagType;

export type CompanyTagType = NonNullable<
  SuperOwnerCompanyTagTypesState["companyTagTypes"][number]
>;

export type CompanyTag = NonNullable<CompanyTagType["company_tags"]>[number];

export type TagBasic = Pick<UserTag, "id" | "name"> & {
  hashType?: TagHashType;
};
export type TagType = {
  id: string;
  name: string;
  teacher_visible?: boolean;
  role_type: "教職員" | "生徒" | null;
};

// type guard to check whether tag type is user tag type or company tag type
export function isUserTagType(
  tagType: UserTagType | CompanyTagType,
): tagType is UserTagType {
  return (tagType as UserTagType).user_tags !== undefined;
}

export function isCompanyTag(tag: UserTag | CompanyTag): tag is CompanyTag {
  return (tag as CompanyTag).number_of_linking_companies !== undefined;
}

export type PostTagTypeParams = {
  name: string;
  teacher_visible?: boolean;
  role_type: "student" | "teacher";
};

export type UpdateTagTypeParams = {
  id: string;
  name: string;
  teacher_visible?: boolean;
  role_type?: "student" | "teacher" | undefined;
};

export type DeleteTagTypeParams = {
  id: string;
};

export type PostTagParams = {
  tagTypeId: string;
  name: string;
  limit?: number;
  role_type: string;
};

export type UpdateTagParams = {
  id: string;
  name: string;
  limit?: number;
};

export type DeleteTagParams = {
  id: string;
};

export type UserTaggingParams = {
  user_id: string;
  user_tag_id: string;
  tag_name?: string;
};

export type FetchStudentTaggingByUserTag = {
  user_tag_id: string;
  page?: number;
  per_page?: number;
};
