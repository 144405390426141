/* eslint-disable camelcase */

import { CONTENT_PROVIDER_ROUTES } from "@constants/routes";
import {
  Api,
  ArticleComment,
  FeedbackTemplateBase,
  GetArticleCommentsAsContentProviderParams,
  GetFeedbackTemplatesAsContentProviderParams,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticleCommentsAsContentProviderArg {
  params: GetArticleCommentsAsContentProviderParams;
}

export const fetchArticleCommentsAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/articleComment/fetch",
  async (
    arg: FetchArticleCommentsAsContentProviderArg,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.contentProvider.getArticleCommentsAsContentProvider(
          arg.params,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const getFeedbackTemplatesAsContentProvider = createAsyncThunk<
  { feedback_templates: FeedbackTemplateBase[]; total_count: number },
  GetFeedbackTemplatesAsContentProviderParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/feedback_templates/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getFeedbackTemplatesAsContentProvider(args);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreateArticleCommentAsContentProviderArg {
  articleId: string;

  params: { body: string; comment_images?: string[] };
}

export const createArticleCommentAsContentProvider = createAsyncThunk<
  ArticleComment,
  CreateArticleCommentAsContentProviderArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("contentProvider/articleComment/create", async (arg, { rejectWithValue }) => {
  const { articleId, params } = arg;
  const fd = new FormData();
  fd.set("body", params.body);
  params.comment_images?.forEach((img) => {
    fd.append("comment_images[]", img);
  });

  try {
    const response = await http.post(
      `${CONTENT_PROVIDER_ROUTES.ARTICLES}/${articleId}/comments`,
      fd,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export interface DeleteArticleCommentAsContentProviderArg {
  articleId: string;
  commentId: string;
}

export const deleteArticleCommentAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/articleComment/delete",
  async (
    arg: DeleteArticleCommentAsContentProviderArg,
    { rejectWithValue },
  ) => {
    const { articleId, commentId } = arg;
    try {
      await api.contentProvider.deleteArticleCommentAsContentProvider(
        articleId,
        commentId,
      );
      return {
        articleId,
        commentId,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetCommentRelatedFormState = createAction(
  "contentProvider/article/comment/resetFormState",
);

export const resetDeleteCommentRelatedState = createAction(
  "contentProvider/article/comment/resetDeleteRelatedState",
);
