/* eslint-disable camelcase */
import {
  Api,
  DeleteSuperOwnerInquiryCommentsIdParams,
  GetSuperOwnerInquiriesParams,
  InquiryComment,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getNumberOfCommentInquiriesAsSuperOwner = createAsyncThunk<
  any,
  void,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/inquiries/getNewNumber", async (_, { rejectWithValue }) => {
  try {
    const response =
      await api.superOwner.getTheNumberOfNewCommentInquiriesAsSuperOwner();
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchInquiriesAsSuperOwner = createAsyncThunk<
  any,
  GetSuperOwnerInquiriesParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/inquiries/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.getSuperOwnerInquiries(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchInquiryDetailsAsSuperOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/inquiryDetails/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.getSuperOwnerInquiriesId(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateInquiryDetailsAsSuperOwner = createAsyncThunk<
  any,
  { id: string; data: { person_in_charge_id?: string; status?: string } },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/inquiryDetails/update", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    const response = await api.common.putCommonInquiries(id, data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const postInquiryCommentAsSuperOwner = createAsyncThunk<
  InquiryComment,
  { id: string; body: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/inquiryComment/post", async (params, { rejectWithValue }) => {
  try {
    const { id, body } = params;
    const response = await api.superOwner.postSuperOwnerInquiryComments(
      { inquiry_id: id },
      { body },
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateInquiryCommentStatusAsSuperOwner = createAsyncThunk<
  InquiryComment,
  {
    id: string;
    inquiry_id: string;
    body: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/inquiryCommentStatus/update",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { id, body, inquiry_id } = params;
      const response = await api.superOwner.putSuperOwnerInquiryCommentsId(
        { inquiry_id, id },
        { body, status: "viewed" },
      );
      await dispatch(getNumberOfCommentInquiriesAsSuperOwner());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateInquiryCommentBodyAsSuperOwner = createAsyncThunk<
  InquiryComment,
  {
    id: string;
    inquiry_id: string;
    body: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/inquiryCommentBody/update",
  async (params, { rejectWithValue }) => {
    try {
      const { id, body, inquiry_id } = params;
      const response = await api.superOwner.putSuperOwnerInquiryCommentsId(
        { inquiry_id, id },
        { body, status: "not_viewed" },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteInquiryCommentAsSuperOwner = createAsyncThunk<
  any,
  DeleteSuperOwnerInquiryCommentsIdParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/inquiryComment/delete", async (params, { rejectWithValue }) => {
  const { inquiry_id, id } = params;
  try {
    const response = await api.superOwner.deleteSuperOwnerInquiryCommentsId({
      inquiry_id,
      id,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetUpdateInquiryDetailsState = createAction(
  "superOwner/inquiryDetails/resetState",
);

export const resetCommentFormState = createAction(
  "superOwner/inquiryComment/resetState",
);
