import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import { useAuth } from "@contexts/Auth";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import { errorWithMessage } from "@lib/rtk/error-utils";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Container, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useDeleteInterviewMessageAsStudentMutation } from "@root/endpoints/TimeTactApi/studentApi";
import { useDeleteInterviewMessageAsTeacherMutation } from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.error.main,
    },
  }),
);

type Props = {
  messageId: string;
  requestId: string;
  handleClose: () => void;
  refetchMessageList: () => void;
  isOpen: boolean;
};

const DeleteMessageModal: React.FC<Props> = ({
  messageId,
  requestId,
  isOpen,
  handleClose,
  refetchMessageList,
}) => {
  const classes = useStyles();
  const { isCurrentRoles } = useAuth();
  const {
    actions: { showError },
  } = useEnqueueToast();
  const { t } = useTranslation(["common", "university"]);

  const deleteMessageMutationAsStudent =
    useDeleteInterviewMessageAsStudentMutation();
  const deleteMessageMutationAsTeacher =
    useDeleteInterviewMessageAsTeacherMutation();

  const getDeleteMessageMutation = () => {
    if (isCurrentRoles.isStudent) {
      return deleteMessageMutationAsStudent;
    }

    return deleteMessageMutationAsTeacher;
  };
  const [deleteMessage, { isLoading: isDeleting }] = getDeleteMessageMutation();
  const onSubmit = () => {
    deleteMessage({
      requestId,
      id: messageId,
    })
      .unwrap()
      .then(() => {
        handleClose();
        refetchMessageList();
      })
      .catch((error: unknown) => showError(errorWithMessage(error)));
  };

  return (
    <GenericModal
      open={isOpen}
      handleClose={handleClose}
      title={t("university:deleteMessage")}
      useActionButtons
      primaryButtonAction={onSubmit}
      primaryButtonText={t("delete")}
      useSecondaryButton
      secondaryButtonText={t("cancel")}
      posting={isDeleting}
      secondaryButtonAction={handleClose}
    >
      <Container>
        <Box display="flex" marginTop="1rem" marginBottom="1.5rem">
          <ErrorIcon fontSize="large" classes={{ root: classes.warningIcon }} />
          <Typography color="error">
            一度削除するとデータの復元はできません。本当に削除しますか？
          </Typography>
        </Box>
      </Container>
    </GenericModal>
  );
};

export default DeleteMessageModal;
