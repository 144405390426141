import { Box,Grid, Theme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.quaternary.grey}`,
      boxShadow:
        "2px 2px 4px 0px rgba(71, 81, 73, 0.05),-2px -2px 4px 0px rgba(71, 81, 73, 0.05)",
    },
    itemContainer: {
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    timeContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
    },
  }),
);

const StatsSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.mainContainer}>
      <Grid
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.itemContainer}
        item
      >
        <Box className={classes.timeContainer}>
          <Skeleton variant="text" width={210} height={40} />
        </Box>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        className={classes.itemContainer}
        item
      >
        <Skeleton variant="circular" width={180} height={180} />
      </Grid>
    </Grid>
  );
};

export default StatsSkeleton;
