import { FILE_TYPE } from "@constants/file";
import { fileSize } from "@constants/fileSize";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import React, {
  ChangeEvent,
  Dispatch,
  forwardRef,
  SetStateAction,
} from "react";

import { SelectedFile } from "./types";

type Props = {
  setIsResizerOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedFile: Dispatch<SetStateAction<SelectedFile>>;
};

const ImageInput = forwardRef<HTMLInputElement, Props>(
  ({ setIsResizerOpen, setSelectedFile }, ref) => {
    const { actions } = useEnqueueToast();

    const handleChangePhotoFileInput = async (
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      const target = event.currentTarget;
      if (!target) {
        return;
      }
      const changedFile = event.currentTarget.files?.item(0) as File;
      target.value = "";
      if (!changedFile) {
        return;
      }
      if (!FILE_TYPE.IMAGE.MIME_TYPES.includes(changedFile.type)) {
        setIsResizerOpen(false);
        actions.showError(FILE_TYPE.IMAGE.MESSAGE);
        return;
      }
      if (changedFile.size > fileSize.image.size) {
        setIsResizerOpen(false);
        actions.showError(fileSize.image.message);
        return;
      }

      // store reference to the File object and a base64 representation of it
      const readDataUrl = async (file: File) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          // async event handlers
          reader.onload = () => resolve(reader.result);
          reader.onerror = () => reject(reader.error);
          reader.readAsDataURL(file);
        });
      };
      const dataUrl = await readDataUrl(changedFile);

      if (changedFile) {
        const objectUrl = URL.createObjectURL(changedFile);
        setSelectedFile((prev) => ({
          ...prev,
          changedFile,
          base64: dataUrl,
          objectUrl,
        }));
        setIsResizerOpen(true);
      }
    };

    return (
      <input
        ref={ref}
        accept={FILE_TYPE.IMAGE.MIME_TYPES.join(",")}
        type="file"
        onChange={(event) => {
          handleChangePhotoFileInput(event);
        }}
        hidden
      />
    );
  },
);

export default ImageInput;
