import V2StyledSelect from "@components/UIv2/atoms/V2StyledSelect";
import { SelectChangeEvent } from "@mui/material";
import { InterviewRequestStatusEnum } from "@root/types/interviewRequest";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  currentStatus: InterviewRequestStatusEnum;
  handleStatusFilter: (event: SelectChangeEvent) => void;
};

const SelectStatusDropDown: React.FC<Props> = ({
  currentStatus,
  handleStatusFilter,
}) => {
  const { t } = useTranslation("university");
  return (
    <V2StyledSelect
      selectItems={[
        {
          value: InterviewRequestStatusEnum.NULL,
          label: t("all"),
        },
        {
          value: InterviewRequestStatusEnum.COMPLETED,
          label: t("studentCompleted"),
        },
        {
          value: InterviewRequestStatusEnum.IN_PROGRESS,
          label: t("studentApproved"),
        },
        {
          value: InterviewRequestStatusEnum.APPROVAL_PENDING,
          label: t("approvalPendingTeacher"),
        },
        {
          value: InterviewRequestStatusEnum.NON_APPROVED,
          label: t("nonCompleted"),
        },
        {
          value: InterviewRequestStatusEnum.INVALIDED,
          label: t("invalidStatus"),
        },
      ]}
      selectorValue={currentStatus}
      handleSelectorChange={(e) => handleStatusFilter(e)}
      isCustomLabelDisabled
      margin="normal"
      customStyles={{
        width: "120px",
        marginBottom: "8px",
      }}
    />
  );
};

export default SelectStatusDropDown;
