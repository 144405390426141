import EditDistributionsModal from "@components/SuperOwner/SuperOwnerMaterialDistribution/Modals/EditDistributions/EditDistributions";
import SelectButton from "@components/UI/molecules/DownloadCsvFileListItem/SelectButton";
import { StyledTableContainer } from "@components/UI/organisms/Tables/TableComponents";
import PerPageSelector from "@components/UIv2/molecules/PerPageSelector";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import {
  LearningTemplatePackageDistributionHistoryBase,
  TtCsvExportType,
} from "@lib/Api";
import useUrlPagination from "@lib/hooks/useUrlPagination";
import { Box, Theme } from "@mui/material";
import Table from "@mui/material/Table";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import {
  LearningTemplateHistoryModalKind,
  LearningTemplateHistoryModalType,
} from "../types";
import Body from "./Body";
import Header from "./Header";
import DeleteDistributionModal from "./Modals/DeleteDistribution";
import DistributionInfosModal from "./Modals/DistributionInfos";

interface TableProps {
  fetched: boolean;
  fetching: boolean;
  fetchError: string | null;
  learningTemplatePackage: LearningTemplatePackageDistributionHistoryBase[];
  totalCount: number;
  fetchDistributionHistories: (newPage?: number, newPerPage?: number) => void;
  handleClose: () => void;
  handleModalState: (
    data: LearningTemplatePackageDistributionHistoryBase,
    kind: LearningTemplateHistoryModalKind,
  ) => void;
  modalState: LearningTemplateHistoryModalType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    csvButtonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      margin: theme.spacing(2, 0),
    },
  }),
);

const LearningTemplateHistoryTable: React.FC<TableProps> = ({
  fetched,
  fetching,
  fetchError,
  learningTemplatePackage,
  totalCount,
  fetchDistributionHistories,
  handleModalState,
  handleClose,
  modalState,
}) => {
  const classes = useStyles();
  const selectorValues: number[] = [10, 50, 100];

  const {
    currentPage,
    currentPerPage,
    handlePageTransition,
    handlePerPageChange,
  } = useUrlPagination(fetchDistributionHistories);

  return (
    <>
      <PerPageSelector
        selectItems={selectorValues}
        selectorValue={currentPerPage}
        handleSelectorChange={(event) =>
          handlePerPageChange(Number(event.target.value))
        }
      />
      <EditDistributionsModal
        open={
          modalState.isOpen &&
          modalState.kind === LearningTemplateHistoryModalKind.EDIT
        }
        editableInformation={modalState.learningTemplatePackage}
        handleClose={handleClose}
        handleUpdatePage={(newPage) => fetchDistributionHistories(newPage)}
      />
      <DistributionInfosModal
        handleClose={handleClose}
        modalState={modalState}
      />
      {modalState.isOpen &&
        modalState.kind === LearningTemplateHistoryModalKind.DELETE &&
        modalState.learningTemplatePackage && (
          <DeleteDistributionModal
            handleClose={handleClose}
            learningTemplatePackage={modalState.learningTemplatePackage}
            fetchDistributionHistories={fetchDistributionHistories}
          />
        )}
      <Box className={classes.csvButtonContainer}>
        <SelectButton
          type={
            TtCsvExportType.SuperOwnerLearningPackageDistributionHistoryList
          }
        />
      </Box>
      <StyledTableContainer>
        <Table aria-label="simple table" style={{ width: "100%" }}>
          <Header />
          <Body
            fetched={fetched}
            fetching={fetching}
            fetchError={fetchError}
            learningTemplatePackage={learningTemplatePackage}
            handleModalState={handleModalState}
          />
        </Table>
      </StyledTableContainer>
      <V2Pagination
        totalCount={totalCount}
        currentPage={currentPage}
        perPage={currentPerPage}
        handlePageTransition={handlePageTransition}
      />
    </>
  );
};

export default LearningTemplateHistoryTable;
