/* eslint-disable camelcase */
import {
  CreateMaterialParams,
  UpdateMaterialParams,
  UpdateVideoInMaterialWithVideoParams,
} from "@actions/types/material";
import {
  NO_CHANGE,
  THUMBNAIL_DELETED,
} from "@components/Common/Categories/Modals/Material/Edit/Main";
import { NETWORK_ERROR } from "@constants/Messages/responseMessage";
import { OWNER_ROUTES } from "@constants/routes";
import {
  Api,
  FetchMaterialsAsOwnerParams,
  FetchMaterialsFromSuperOwnerAsOwnerParams,
  MaterialsAllContainedCategoryListParams,
} from "@lib/Api";
import { http } from "@lib/http";
import { prepareCommonFormForVideoUploading } from "@lib/material/vieo-upload-utils";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RoleApiFunctions } from "@root/types/materials";
import { RootState } from "@store/store";

const api = new Api();

export const fetchMaterialsAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["fetchMaterialsAsOwner"]>,
  FetchMaterialsAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/materials/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.fetchMaterialsAsOwner(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const fetchMaterialsFromSuperOwnerAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["fetchMaterialsFromSuperOwnerAsOwner"]>,
  FetchMaterialsFromSuperOwnerAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/materials/from_super_owner/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.fetchMaterialsFromSuperOwnerAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

type OwnerActionsType = {
  owner: RoleApiFunctions;
};

export const ownerMaterialsActions: OwnerActionsType = {
  owner: {
    getMaterials: fetchMaterialsFromSuperOwnerAsOwner,
  },
};

export const fetchMaterialAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["fetchMaterialAsOwner"]>,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/materials/fetchOne", async (materialId, { rejectWithValue }) => {
  try {
    const response = await api.owner.fetchMaterialAsOwner(materialId);
    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const createMaterialAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["createMaterialAsOwner"]>,
  CreateMaterialParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/materials/create", async (params, { rejectWithValue }) => {
  try {
    const { title, categoryId, downloadAllowed, isPublic } = params;
    const response = await api.owner.createMaterialAsOwner({
      title,
      ...(categoryId && { category_id: categoryId }),
      download_allowed: downloadAllowed,
      is_public: isPublic,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const updateMaterialAsOwner = createAsyncThunk<
  any,
  UpdateMaterialParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/materials/update", async (params, { rejectWithValue }) => {
  try {
    const fd = prepareCommonFormForVideoUploading(params);
    const {
      pdfFilesData,
      removedImages,
      thumbnailData,
      removedVideos,
      external_link,
      materialVideoId,
      videoChanged,
      videoFileData,
    } = params;

    if (pdfFilesData) {
      pdfFilesData.forEach((fileData) => {
        if (fileData.file) {
          fd.append("image_content_titles[]", fileData.name);
          fd.append(`image_contents[]`, fileData.file, fileData.name);
        } else {
          fd.append("image_content_titles[]", fileData.name);
          fd.append("image_contents[]", "");
        }
      });
    }
    if (removedImages) {
      removedImages.forEach((id) => {
        fd.append("removed_image_ids[]", id);
      });
    }

    if (
      thumbnailData !== NO_CHANGE &&
      typeof thumbnailData !== "string" &&
      typeof thumbnailData !== "undefined"
    ) {
      fd.set("thumbnail_file", thumbnailData.file);
    } else if (thumbnailData === THUMBNAIL_DELETED) {
      fd.set("thumbnail_file", "remove");
    }

    fd.set("video_content_external_link", external_link || "");

    if (external_link && materialVideoId) {
      fd.set("video_id", materialVideoId);
    }
    if (videoChanged && videoFileData) {
      fd.set("is_video_updating", "true");
    }

    if (removedVideos.length) {
      removedVideos.forEach((id) => {
        fd.append("removed_video_ids[]", id);
      });
    }

    const response = await http.put(
      `${OWNER_ROUTES.MATERIALS}/${params.id}`,
      fd,
    );
    const resData = response.data;
    return {
      material: resData,
    };
  } catch (err) {
    const status = err?.status || err?.response?.status;
    let errorMessage = "";
    if (status === undefined) {
      errorMessage = NETWORK_ERROR;
    } else {
      errorMessage = errorWithMessage(err.response);
    }
    return rejectWithValue(errorMessage);
  }
});

export const updateVideoInMaterialAsOwner = createAsyncThunk<
  any,
  UpdateVideoInMaterialWithVideoParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/materials/update_video", async (args, { rejectWithValue }) => {
  const { params, setProgress } = args;
  try {
    const fd = prepareCommonFormForVideoUploading(params);
    const { materialVideoId, videoChanged, videoFileData } = params;

    if (materialVideoId) {
      fd.set("video_id", materialVideoId);
    }

    if (videoChanged && videoFileData) {
      fd.set("video_content_title", videoFileData.name);
      fd.set("video_content_thumbnail", videoFileData.thumbnail);
      fd.set("video_content_file", videoFileData.file);
      fd.set("is_video_updating", "false");
    }

    const config = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percent = Math.round((loaded * 100) / total);
        setProgress(percent);
      },
    };
    const response = await http.put(
      `${OWNER_ROUTES.MATERIALS}/${params.id}`,
      fd,
      config,
    );
    const resData = response.data;
    return {
      material: resData,
    };
  } catch (err) {
    const status = err?.status || err?.response?.status;
    let errorMessage = "";
    if (status === undefined) {
      errorMessage = NETWORK_ERROR;
    } else {
      errorMessage = errorWithMessage(err.response);
    }
    return rejectWithValue(errorMessage);
  }
});

export const deleteMaterialAsOwner = createAsyncThunk<
  { materialId: string },
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/materials/delete", async (materialId, { rejectWithValue }) => {
  try {
    await api.owner.deleteMaterialAsOwner(materialId, {
      id: materialId,
    });
    return {
      materialId,
    };
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const resetFormRelatedData = createAction(
  "ownerApp/materials/resetFormRelatedData",
);

export const resetOwnerMaterialSequenceState = createAction(
  "ownerApp/materials/sequence",
);

export interface SaveMaterialSequenceParams {
  sequences: Array<{
    material_id: string;
    sequence: number;
  }>;
}

export const saveMaterialSequenceAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["putOwnerMaterialsSequence"]>,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "ownerApp/materials/saveSequence",
  async (data: SaveMaterialSequenceParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.putOwnerMaterialsSequence(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchMaterialsAllCategoriesAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["materialsAllContainedCategoryList"]>,
  MaterialsAllContainedCategoryListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  `owner/materials/all_contained_categories`,
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.materialsAllContainedCategoryList(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction("owner/materia/resetState");
