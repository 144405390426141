/* eslint-disable camelcase */
import {
  Api,
  FetchAdminSteamContentsParams,
  GetAdminSteamContentsParams,
  PblSteamContentBase,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import _ from "lodash";

const api = new Api();

export const fetchSteamLibraryContentsAsAdmin = createAsyncThunk<
  { steam_contents?: PblSteamContentBase[]; total_count?: number },
  GetAdminSteamContentsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/steamLibraryContents/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.getAdminSteamContents(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getSteamLibraryContentTitle = createAsyncThunk<
  { steam_library_content_id: number; steam_library_content_title: string },
  FetchAdminSteamContentsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/steamLibraryContents/getTitle",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.admin.fetchAdminSteamContents(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export type PostSteamLibraryContentsAsAdminParams = {
  difficulty?: string;
  title: string;
  is_scientific?: boolean;
  sl_content_id: number;
  aspect?: string;
  has_carousel?: boolean;
};

export const postSteamLibraryContentsAsAdmin = createAsyncThunk<
  any,
  PostSteamLibraryContentsAsAdminParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/steamLibraryContents/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.postAdminSteamContents(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export type UpdateSteamLibraryContentsAsAdminParams =
  PostSteamLibraryContentsAsAdminParams & { id: string };

export const updateSteamLibraryContentsAsAdmin = createAsyncThunk<
  any,
  UpdateSteamLibraryContentsAsAdminParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/steamLibraryContents/update", async (params, { rejectWithValue }) => {
  const data = _.omit(params, "id");
  try {
    const response = await api.admin.putAdminSteamContentsId(params.id, data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteSteamLibraryContentsAsAdmin = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/steamLibraryContents/delete", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.deleteAdminSteamContentsId(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetContentTitleState = createAction(
  "admin/steamLibraryContentTitle/reset",
);

export const resetContentFormState = createAction(
  "admin/steamLibraryContentForm/reset",
);
