/* eslint-disable camelcase */
import {
  deleteArticleAffiliatesAsStudent,
  deleteArticleAsStudent,
  fetchArticleAsStudent,
  fetchArticlesAsStudent,
  fetchArticleStatsAsStudent,
  fetchWordCloudDataAsStudent,
  postArticleAffiliatesAsStudent,
  resetArticleRelatedData,
  resetDeleteStatus,
  resetFetchOneStatus,
  resetModalState,
  resetStatusUpdate,
  resetUpdateStatus,
  submitArticleToTeacherAsStudent,
  updateArticleAsStudent,
} from "@actions/studentApp/article";
import { fetchArticleCommentsAsStudent } from "@actions/studentApp/article_comment";
import { fetchArticleFeedbacksAsStudent } from "@actions/studentApp/article_feedback";
import {
  deleteArticleImagesAsStudent,
  postArticleImagesAsStudent,
} from "@actions/studentApp/article_image";
import { fetchReviewerCompaniesAsStudent } from "@actions/studentApp/reviewer_company";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import {
  Article,
  ArticleBase,
  ArticleComment,
  ArticleFeedback,
  ArticleImageBase,
  ReviewerCompany,
  WordCloudWord,
} from "@lib/Api";
import { update } from "@lib/collection";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface StudentAppArticleState {
  statusUpdate: ReduxStatusType;
  statusUpdateError: string | null;
  updateStatus: ReduxStatusType;
  deletingFile: boolean;
  deletedFile: boolean;
  creatingFile: boolean;
  createdFile: boolean;
  deleteArticleStatus: ReduxStatusType;
  changeError: string | null; // updateError, createError or deleteError
  fetching: boolean;
  fetched: boolean;
  fetchOneStatus: ReduxStatusType;
  fetchOneError: any;
  error: string | null;
  serverError: string | null;
  totalCount: number;
  articles: ArticleBase[];
  studentOwnArticles: ArticleBase[];
  article: Article | null;
  reviewerCompanies: ReviewerCompany[];
  studentAffiliateStatus: ReduxStatusType;
  studentAffiliateError: any;
  deleteingStudent: boolean;
  deletedStudent: boolean;
  deleteStudentError: any;
  fetchingReviewerCompanies: boolean;
  fetchedReviewerCompanies: boolean;
  fetchReviewerCompaniesError: any;
  articleComments: ArticleComment[];
  fetchCommentsStatus: ReduxStatusType;
  fetchCommentsError: any;
  articleFeedbacks: ArticleFeedback[];
  fetchFeedbacksStatus: ReduxStatusType;
  fetchFeedbackError: any;
  fetchingStats: boolean;
  fetchedStats: boolean;
  fetchStatsError: any;
  articleImageBase: ArticleImageBase[];
  wordCloudData: WordCloudWord[];
  fetchWordCloudData: ReduxStatusType;
}

export const initialState: StudentAppArticleState = {
  statusUpdate: ReduxStatus.idle,
  statusUpdateError: null,
  updateStatus: ReduxStatus.idle,
  deletingFile: false,
  deletedFile: false,
  creatingFile: false,
  createdFile: false,
  deleteArticleStatus: ReduxStatus.idle,
  changeError: null,
  fetching: false,
  fetched: false,
  fetchOneStatus: ReduxStatus.idle,
  fetchOneError: null,
  studentAffiliateStatus: ReduxStatus.idle,
  studentAffiliateError: null,
  error: null,
  serverError: null,
  articles: [],
  studentOwnArticles: [],
  totalCount: 0,
  article: null,
  reviewerCompanies: [],
  fetchingReviewerCompanies: false,
  fetchedReviewerCompanies: false,
  fetchReviewerCompaniesError: null,
  deleteingStudent: false,
  deletedStudent: false,
  deleteStudentError: null,
  articleComments: [],
  fetchCommentsStatus: ReduxStatus.idle,
  fetchCommentsError: null,
  articleFeedbacks: [],
  fetchFeedbacksStatus: ReduxStatus.idle,
  fetchFeedbackError: null,
  fetchingStats: false,
  fetchedStats: false,
  fetchStatsError: null,
  articleImageBase: [],
  fetchWordCloudData: ReduxStatus.idle,
  wordCloudData: [],
};

export const studentAppArticleSlice = createSlice({
  name: "StudentApp/Article",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticlesAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          serverError: null,
        };
      })
      .addCase(fetchArticlesAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          articles: payload.articles,
          totalCount: payload.total_count,
          error: "",
        };
      })
      .addCase(fetchArticlesAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          articles: [],
          error: action.error.message as string,
        };
      })
      .addCase(fetchArticleAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchOneStatus: ReduxStatus.pending,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchOneStatus: ReduxStatus.fulfilled,
          article: payload,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchOneStatus: ReduxStatus.rejected,
          fetchOneError: action.error,
          article: null,
        };
      })
      .addCase(postArticleAffiliatesAsStudent.pending, (state, _action) => {
        return {
          ...state,
          studentAffiliateStatus: ReduxStatus.pending,
          studentAffiliateError: null,
        };
      })
      .addCase(postArticleAffiliatesAsStudent.fulfilled, (state) => {
        return {
          ...state,
          studentAffiliateStatus: ReduxStatus.fulfilled,
          studentAffiliateError: null,
        };
      })
      .addCase(postArticleAffiliatesAsStudent.rejected, (state, action) => {
        return {
          ...state,
          studentAffiliateStatus: ReduxStatus.rejected,
          studentAffiliateError: action.error,
        };
      })
      .addCase(updateArticleAsStudent.pending, (state, _action) => {
        return {
          ...state,
          updateStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(updateArticleAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updateStatus: ReduxStatus.fulfilled,
          articles: update(currentState.articles, payload),
          article: payload,
          changeError: null,
        };
      })
      .addCase(updateArticleAsStudent.rejected, (state, action) => {
        return {
          ...state,
          updateStatus: ReduxStatus.rejected,
          changeError: action.payload,
        };
      })
      .addCase(deleteArticleAffiliatesAsStudent.pending, (state, _action) => {
        return {
          ...state,
          deleteingStudent: true,
          deletedStudent: false,
          deleteStudentError: null,
        };
      })
      .addCase(deleteArticleAffiliatesAsStudent.fulfilled, (state) => {
        return {
          ...state,
          deleteingStudent: false,
          deletedStudent: true,
          deleteStudentError: null,
        };
      })
      .addCase(deleteArticleAffiliatesAsStudent.rejected, (state, action) => {
        return {
          ...state,
          deleteingStudent: false,
          deletedStudent: false,
          deleteStudentError: action.payload.errors,
        };
      })
      .addCase(submitArticleToTeacherAsStudent.pending, (state, _action) => {
        return {
          ...state,
          statusUpdate: ReduxStatus.pending,
          statusUpdateError: null,
        };
      })
      .addCase(submitArticleToTeacherAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          articles: update(currentState.articles, payload),
          article: payload,
          statusUpdate: ReduxStatus.fulfilled,
          statusUpdateError: null,
        };
      })
      .addCase(submitArticleToTeacherAsStudent.rejected, (state, action) => {
        return {
          ...state,
          statusUpdate: ReduxStatus.rejected,
          statusUpdateError: getErrorMessage(action.payload),
        };
      })
      .addCase(deleteArticleAsStudent.pending, (state, _action) => {
        return {
          ...state,
          deleteArticleStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(deleteArticleAsStudent.fulfilled, (state, action) => {
        const { changeState } = action.payload;
        if (!changeState) return state;
        return {
          ...state,
          deleteArticleStatus: ReduxStatus.fulfilled,
          article: null,
          changeError: null,
        };
      })
      .addCase(deleteArticleAsStudent.rejected, (state, action) => {
        return {
          ...state,
          deleteArticleStatus: ReduxStatus.rejected,
          changeError: errorWithMessage(action.payload.data),
        };
      })
      .addCase(postArticleImagesAsStudent.pending, (state) => {
        return {
          ...state,
          creatingFile: true,
          createdFile: false,
          changeError: null,
        };
      })
      .addCase(postArticleImagesAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          creatingFile: false,
          createdFile: true,
          changeError: null,
        };
      })
      .addCase(postArticleImagesAsStudent.rejected, (state, action) => {
        return {
          ...state,
          creatingFile: false,
          createdFile: false,
          changeError: action.payload,
        };
      })
      .addCase(deleteArticleImagesAsStudent.pending, (state) => {
        return {
          ...state,
          deletingFile: true,
          deletedFile: false,
          changeError: null,
        };
      })
      .addCase(deleteArticleImagesAsStudent.fulfilled, (state) => {
        return {
          ...state,
          deletingFile: false,
          deletedFile: true,
          changeError: null,
        };
      })
      .addCase(deleteArticleImagesAsStudent.rejected, (state) => {
        return {
          ...state,
          deletingFile: false,
          deletedFile: false,
          changeError: null,
        };
      })
      .addCase(fetchReviewerCompaniesAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchingReviewerCompanies: true,
          fetchedReviewerCompanies: false,
          fetchReviewerCompaniesError: null,
        };
      })
      .addCase(fetchReviewerCompaniesAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingReviewerCompanies: false,
          fetchedReviewerCompanies: true,
          reviewerCompanies: payload.companies,
          fetchReviewerCompaniesError: null,
        };
      })
      .addCase(fetchReviewerCompaniesAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingReviewerCompanies: false,
          fetchedReviewerCompanies: false,
          fetchReviewerCompaniesError: action.payload,
        };
      })
      .addCase(fetchArticleStatsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchingStats: true,
          fetchedStats: false,
          fetchStatsError: null,
        };
      })
      .addCase(fetchArticleStatsAsStudent.fulfilled, (state, action) => {
        const { total_count, articles } = action.payload;
        return {
          ...state,
          fetchingStats: false,
          fetchedStats: true,
          studentOwnArticles: articles,
          fetchStatsError: null,
          totalCount: total_count,
        };
      })
      .addCase(fetchArticleStatsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingStats: false,
          fetchedStats: false,
          fetchStatsError: action.error,
        };
      })
      .addCase(fetchArticleCommentsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchCommentsStatus: ReduxStatus.pending,
          fetchCommentsError: null,
        };
      })
      .addCase(fetchArticleCommentsAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchCommentsStatus: ReduxStatus.fulfilled,
          articleComments: payload.article_comments,
          fetchCommentsError: null,
        };
      })
      .addCase(fetchArticleCommentsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchCommentsStatus: ReduxStatus.rejected,
          fetchCommentsError: action.error,
        };
      })
      .addCase(fetchArticleFeedbacksAsStudent.pending, (state) => {
        return {
          ...state,
          fetchFeedbacksStatus: ReduxStatus.pending,
          fetchFeedbacksError: null,
        };
      })
      .addCase(fetchArticleFeedbacksAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchFeedbacksStatus: ReduxStatus.fulfilled,
          articleFeedbacks: payload.article_feedbacks,
          fetchFeedbacksError: null,
        };
      })
      .addCase(fetchArticleFeedbacksAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchFeedbacksStatus: ReduxStatus.rejected,
          fetchFeedbacksError: action.error,
        };
      })
      .addCase(fetchWordCloudDataAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchWordCloudData: ReduxStatus.pending,
        };
      })
      .addCase(fetchWordCloudDataAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchWordCloudData: ReduxStatus.fulfilled,
          wordCloudData: payload.word_cloud_list,
        };
      })
      .addCase(fetchWordCloudDataAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchWordCloudData: ReduxStatus.rejected,
          fetchWordCloudError: action.error,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          statusUpdate: ReduxStatus.idle,
          statusUpdateError: null,
          updateStatus: ReduxStatus.idle,
          deleteArticleStatus: ReduxStatus.idle,
          changeError: null,
          fetchOneStatus: ReduxStatus.idle,
          fetchOneError: null,
        };
      })
      .addCase(resetUpdateStatus, (state) => {
        return {
          ...state,
          updateStatus: ReduxStatus.idle,
        };
      })
      .addCase(resetFetchOneStatus, (state) => {
        return {
          ...state,
          fetchOneStatus: ReduxStatus.idle,
        };
      })
      .addCase(resetDeleteStatus, (state) => {
        return {
          ...state,
          deleteArticleStatus: ReduxStatus.idle,
        };
      })
      .addCase(resetStatusUpdate, (state) => {
        return {
          ...state,
          statusUpdate: ReduxStatus.idle,
          statusUpdateError: null,
        };
      })
      .addCase(resetArticleRelatedData, (state, _action) => {
        return {
          ...state,
          statusUpdate: ReduxStatus.idle,
          statusUpdateError: null,
          updateStatus: ReduxStatus.idle,
          deletedFile: false,
          changeError: null,
          fetchOneStatus: ReduxStatus.idle,
          fetchOneError: null,
          fetchCommentsStatus: ReduxStatus.idle,
          fetchFeedbacksStatus: ReduxStatus.idle,
          fetchCommentsError: null,
          fetchFeedbackError: null,
          articleFeedbacks: [],
          articleComments: [],
          article: null,
        };
      })
      .addDefaultCase((state) => {
        return state;
      });
  },
});

export const studentAppArticleState = (
  state: RootState,
): StudentAppArticleState => state.studentApp.article;

export default studentAppArticleSlice.reducer;
