/* eslint-disable camelcase */
import { Api, InquirySimpleBase } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { roles } from "@root/constants/roles";
import { RootState } from "@store/store";

const api = new Api();

export const getNumberOfCommentInquiries = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/inquiries/getNewNumber", async (role, { rejectWithValue }) => {
  try {
    let response: any;
    if (role === roles.OWNER) {
      response = await api.owner.getTheNumberOfNewCommentInquiriesAsOwner();
    }
    if (role === roles.TEACHER) {
      response = await api.teacher.getTheNumberOfNewCommentInquiriesAsTeacher();
    }
    if (role === roles.STUDENT) {
      response = await api.student.getTheNumberOfNewCommentInquiriesAsStudent();
    }
    if (role === roles.REVIEWER) {
      response =
        await api.reviewer.getTheNumberOfNewCommentInquiriesAsReviewer();
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchCommonInquiries = createAsyncThunk<
  any,
  { page?: number; per_page?: number; role: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/inquiries/fetch", async (params, { rejectWithValue }) => {
  try {
    const { role, ...data } = params;
    let response: any;
    if (role === roles.OWNER) {
      response = await api.owner.getOwnerInquiries(data);
    }
    if (role === roles.TEACHER) {
      response = await api.teacher.getTeacherInquiries(data);
    }
    if (role === roles.STUDENT) {
      response = await api.student.getStudentInquiries(data);
    }
    if (role === roles.REVIEWER) {
      response = await api.reviewer.getReviewerInquiries(data);
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchCommonInquiryDetails = createAsyncThunk<
  InquirySimpleBase,
  { id: string; role: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/inquiryDetails/fetch", async (params, { rejectWithValue }) => {
  try {
    const { id, role } = params;
    let call;
    switch (role) {
      case roles.OWNER: {
        call = () => api.owner.getOwnerInquiriesId(id);
        break;
      }
      case roles.TEACHER: {
        call = () => api.teacher.getTeacherInquiriesId(id);
        break;
      }
      case roles.STUDENT: {
        call = () => api.student.getStudentInquiriesId(id);
        break;
      }
      case roles.REVIEWER: {
        call = () => api.reviewer.getReviewerInquiriesId(id);
        break;
      }
      default: {
        call = () => api.reviewer.getReviewerInquiriesId(id);
        break;
      }
    }
    const response = await call();
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const postCommonInquiryComment = createAsyncThunk<
  any,
  { id: string; body: string; role: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/inquiryComment/post", async (params, { rejectWithValue }) => {
  try {
    const { id, body, role } = params;
    let response: any;
    if (role === roles.OWNER) {
      response = await api.owner.postOwnerInquiryComments(
        { inquiry_id: id },
        { body },
      );
    }
    if (role === roles.TEACHER) {
      response = await api.teacher.postTeacherInquiryComments(
        { inquiry_id: id },
        { body },
      );
    }
    if (role === roles.STUDENT) {
      response = await api.student.postStudentInquiryComments(
        { inquiry_id: id },
        { body },
      );
    }
    if (role === roles.REVIEWER) {
      response = await api.reviewer.postReviewerInquiryComments(
        { inquiry_id: id },
        { body },
      );
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateCommonInquiryCommentStatus = createAsyncThunk<
  any,
  {
    inquiry_id: string;
    id: string;
    body: string;
    role: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/inquiryCommentStatus/update",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { inquiry_id, id, body, role } = params;
      let response: any;
      if (role === roles.OWNER) {
        response = await api.owner.putOwnerInquiryCommentsId(
          { inquiry_id, id },
          { body, status: "viewed" },
        );
      }
      if (role === roles.TEACHER) {
        response = await api.teacher.putTeacherInquiryCommentsId(
          { inquiry_id, id },
          { body, status: "viewed" },
        );
      }
      if (role === roles.STUDENT) {
        response = await api.student.putStudentInquiryCommentsId(
          { inquiry_id, id },
          { body, status: "viewed" },
        );
      }
      if (role === roles.REVIEWER) {
        response = await api.reviewer.putReviewerInquiryCommentsId(
          { inquiry_id, id },
          { body, status: "viewed" },
        );
      }
      await dispatch(getNumberOfCommentInquiries(role));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCommonInquiryCommentBody = createAsyncThunk<
  any,
  {
    inquiry_id: string;
    id: string;
    body: string;
    role: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/inquiryCommentBody/update", async (params, { rejectWithValue }) => {
  try {
    const { inquiry_id, id, body, role } = params;
    let response: any;
    if (role === roles.OWNER) {
      response = await api.owner.putOwnerInquiryCommentsId(
        { inquiry_id, id },
        { body, status: "not_viewed" },
      );
    }
    if (role === roles.TEACHER) {
      response = await api.teacher.putTeacherInquiryCommentsId(
        { inquiry_id, id },
        { body, status: "not_viewed" },
      );
    }
    if (role === roles.STUDENT) {
      response = await api.student.putStudentInquiryCommentsId(
        { inquiry_id, id },
        { body, status: "not_viewed" },
      );
    }
    if (role === roles.REVIEWER) {
      response = await api.reviewer.putReviewerInquiryCommentsId(
        { inquiry_id, id },
        { body, status: "not_viewed" },
      );
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteCommonInquiryComment = createAsyncThunk<
  any,
  { inquiry_id: string; id: string; role: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/inquiryComment/delete", async (params, { rejectWithValue }) => {
  try {
    const { inquiry_id, id, role } = params;
    let response: any;
    if (role === roles.OWNER) {
      response = await api.owner.deleteOwnerInquiryCommentsId({
        inquiry_id,
        id,
      });
    }
    if (role === roles.TEACHER) {
      response = await api.teacher.deleteTeacherInquiryCommentsId({
        inquiry_id,
        id,
      });
    }
    if (role === roles.STUDENT) {
      response = await api.student.deleteStudentInquiryCommentsId({
        inquiry_id,
        id,
      });
    }
    if (role === roles.REVIEWER) {
      response = await api.reviewer.deleteReviewerInquiryCommentsId({
        inquiry_id,
        id,
      });
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export type PostInquiryParams = {
  title: string;
  body: string;
  os_name?: string;
  os_version?: string;
  browser_name?: string;
  browser_version?: string;
  kind: string;
};

export const postCommonInquiry = createAsyncThunk<
  any,
  PostInquiryParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/inquiry/post", async (inquiry, { rejectWithValue }) => {
  try {
    const response = await api.common.postCommonInquiries(inquiry);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const resetState = createAction("inquiry/resetState");

export const resetCommentFormState = createAction(
  "common/inquiryComment/resetState",
);
