import { StyledTag, StyledTagColor } from "@components/UI/atoms/StyledTag";
import { BodyItemsTypes } from "@components/UIv2/organisms/CommonTable/tableTypes";
import { CurrentUserInfo } from "@lib/Api";
import { formatLong } from "@lib/time/dateUtils";
import { IsRoles } from "@lib/user/get-role-utils";
import { fullName } from "@lib/user/name-utils";
import { Box } from "@mui/material";
import { InterviewFeedbackBase } from "@root/endpoints/TimeTactApi/studentApi";
import {
  InterviewRequestListBase,
  InterviewRequestUnreadCountBase,
} from "@root/endpoints/TimeTactApi/teacherApi";
import {
  InterviewRequestStatusEnum,
  InterviewRequestStatusEnumJa,
} from "@root/types/interviewRequest";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import React from "react";

import { SearchTypes } from "../hook/handleInterviewRequestTypes";

type TagInfosType = {
  label: InterviewRequestStatusEnumJa;
  tagColor: StyledTagColor;
};

export const handleTagInfos = (
  requestStatusCode: InterviewRequestStatusEnum,
  isCurrentRoles: IsRoles,
  currentUser: CurrentUserInfo | null,
): TagInfosType => {
  let label: InterviewRequestStatusEnumJa;
  let tagColor: StyledTagColor;
  const isNotUniversityTeacher =
    !currentUser?.is_university && isCurrentRoles.isTeacher;
  const isStudentOrTeacher = isCurrentRoles.isStudent || isNotUniversityTeacher;
  switch (requestStatusCode) {
    case InterviewRequestStatusEnum.IN_PROGRESS:
      label = isStudentOrTeacher
        ? InterviewRequestStatusEnumJa.IN_PROGRESS_TEACHER
        : InterviewRequestStatusEnumJa.IN_PROGRESS;
      tagColor = StyledTagColor.Green;
      break;
    case InterviewRequestStatusEnum.APPROVAL_PENDING:
      label = isStudentOrTeacher
        ? InterviewRequestStatusEnumJa.APPROVAL_PENDING_TEACHER
        : InterviewRequestStatusEnumJa.APPROVAL_PENDING;
      tagColor = StyledTagColor.LightRed;
      break;
    case InterviewRequestStatusEnum.COMPLETED:
      label = InterviewRequestStatusEnumJa.COMPLETED;
      tagColor = StyledTagColor.Grey;
      break;
    case InterviewRequestStatusEnum.NON_APPROVED:
      label = InterviewRequestStatusEnumJa.NON_APPROVED;
      tagColor = StyledTagColor.Grey;
      break;
    case InterviewRequestStatusEnum.INVALIDED:
      label = InterviewRequestStatusEnumJa.INVALIDED;
      tagColor = StyledTagColor.Grey;
      break;
    default:
      label = InterviewRequestStatusEnumJa.NON_APPROVED;
      tagColor = StyledTagColor.Grey;
  }

  return { label, tagColor };
};

export const handleIsChatShown = (
  requestStatusCode: InterviewRequestStatusEnum,
): boolean => {
  const chatShownStatuses = [
    InterviewRequestStatusEnum.IN_PROGRESS,
    InterviewRequestStatusEnum.COMPLETED,
    InterviewRequestStatusEnum.INVALIDED,
  ];

  return chatShownStatuses.includes(requestStatusCode);
};

export const handleUnreadMessagesCount = (
  unreadMessagesCounts: InterviewRequestUnreadCountBase[],
  request: InterviewRequestListBase,
): { isUnread: boolean; count: number } => {
  if (!unreadMessagesCounts.length || !request)
    return { isUnread: false, count: 0 };
  const matchedItem = unreadMessagesCounts.find(
    (undeadMsg) => request.id === undeadMsg.id,
  );
  if (matchedItem) {
    return { isUnread: true, count: matchedItem.count };
  }
  return { isUnread: false, count: 0 };
};

type TableHeaderType = {
  title: string;
  id: string;
};

export const handleTableInfos = (
  unreadMessagesCounts: InterviewRequestUnreadCountBase[],
  interviewRequests: InterviewRequestListBase[],
  isUniversityTeacher: boolean,
  isTeacher: boolean,
  isOwner: boolean,
  t: TFunction,
  isCurrentRoles: IsRoles,
  currentUser: CurrentUserInfo,
): {
  tableBody: BodyItemsTypes<InterviewRequestListBase>[];
  tableHeaders: TableHeaderType[];
} => {
  const handleFirstColumn = (
    request: InterviewRequestListBase | null = null,
  ) => {
    const requestStatusCode =
      request?.status_code as InterviewRequestStatusEnum;
    const { label, tagColor } = handleTagInfos(
      requestStatusCode,
      isCurrentRoles,
      currentUser,
    );

    return {
      body: (
        <Box style={{ width: "81px" }}>
          <StyledTag borderRadius={15} label={label} tagColor={tagColor} />
        </Box>
      ),
      columnTitle: t("status"),
    };
  };

  const handleSecondColumn = (
    request: InterviewRequestListBase | null = null,
  ) => {
    const commonColumnTitle = `${
      request?.professor_affiliate ? fullName(request?.professor_affiliate) : ""
    } / ${request?.professor_affiliate.professor_info.department} / ${
      request?.professor_affiliate.professor_info.position
    }`;

    if (isUniversityTeacher) {
      return {
        body: request?.article.title,
        columnTitle: t("articleItem"),
      };
    }
    if (isTeacher || isOwner) {
      return {
        body: commonColumnTitle,
        columnTitle: `${t("universityTeacherName")}/${t("affiliation")}/${t(
          "position",
        )}`,
      };
    }
    throw new Error("Invalid Column");
  };

  const handleThirdColumn = (
    request: InterviewRequestListBase | null = null,
  ) => {
    if (isUniversityTeacher) {
      return {
        body: `${request?.article.company.name || ""} (${
          request?.article.assign_user_names
        })`,
        columnTitle: `${t("companyItem")}/${t("studentItem")}`,
      };
    }
    if (isTeacher || isOwner) {
      return {
        body: request?.article.title,
        columnTitle: t("articleItem"),
      };
    }
    throw new Error("Invalid Column");
  };

  const handleFourthColumn = (
    request: InterviewRequestListBase | null = null,
  ) => {
    if (isUniversityTeacher) {
      return {
        body: request?.confirm_date ? formatLong(request?.confirm_date) : "",
        columnTitle: t("interviewDataTime"),
      };
    }
    if (isTeacher) {
      return {
        body: request?.article.assign_user_names,
        columnTitle: t("user_list:displayedName"),
      };
    }
    if (isOwner) {
      return {
        body: `${request?.article.company.name} (${request?.article.assign_user_names})`,
        columnTitle: `${t("companyItem")}/${t("studentItem")}`,
      };
    }
    throw new Error("Invalid Column");
  };

  const tableBody = interviewRequests.map((request) => ({
    id: request.id,
    fullBody: request,
    cells: [
      { id: "1", body: handleFirstColumn(request).body },
      { id: "2", body: handleSecondColumn(request).body },
      { id: "3", body: handleThirdColumn(request).body },
      { id: "4", body: handleFourthColumn(request).body },
      ...(isUniversityTeacher || isTeacher
        ? [
            {
              id: "5",
              body: handleUnreadMessagesCount(unreadMessagesCounts, request)
                .count,
            },
          ]
        : []),
    ],
  }));

  const tableHeaders = [
    { title: handleFirstColumn().columnTitle, id: "1" },
    { title: handleSecondColumn().columnTitle, id: "2" },
    { title: handleThirdColumn().columnTitle, id: "3" },
    { title: handleFourthColumn().columnTitle, id: "4" },
    ...(isUniversityTeacher || isTeacher
      ? [{ id: "5", title: t("unReadMessagesCount") }]
      : []),
  ];

  return {
    tableHeaders,
    tableBody,
  };
};

export const handleSearchBarMenuItems = (
  isUniversityTeacher: boolean,
  isTeacher: boolean,
  isOwner: boolean,
  t: TFunction,
): { value: string; label: string }[] => {
  let menuItems: { value: string; label: string }[] = [];

  if (isUniversityTeacher) {
    menuItems = [
      {
        label: t("companyItem"),
        value: SearchTypes.COMPANY_NAME,
      },
      {
        label: t("studentItem"),
        value: SearchTypes.STUDENT_NAME,
      },
      {
        label: t("articleItem"),
        value: SearchTypes.ARTICLE_TITLE,
      },
    ];
  } else if (isTeacher) {
    menuItems = [
      {
        label: t("studentItem"),
        value: SearchTypes.STUDENT_NAME,
      },
      {
        label: t("articleItem"),
        value: SearchTypes.ARTICLE_TITLE,
      },
    ];
  } else if (isOwner) {
    menuItems = [
      {
        label: t("companyItem"),
        value: SearchTypes.COMPANY_NAME,
      },
      {
        label: t("teacherItem"),
        value: SearchTypes.PROFESSOR_NAME,
      },
    ];
  }

  return menuItems;
};

export const handleSearchInputPlaceHolder = (
  searchType: SearchTypes,
  t: TFunction,
): string => {
  const base = t("placeHolderBase");
  let placeHolder;

  switch (searchType) {
    case SearchTypes.COMPANY_NAME:
      placeHolder = `${t("companyItem")}${base}`;
      break;
    case SearchTypes.PROFESSOR_NAME:
      placeHolder = `${t("teacherItem")}${base}`;
      break;
    case SearchTypes.ARTICLE_TITLE:
      placeHolder = `${t("articleItem")}${base}`;
      break;
    case SearchTypes.STUDENT_NAME:
      placeHolder = `${t("studentItem")}${base}`;
      break;
    default:
      placeHolder = "入力";
  }
  return placeHolder;
};

export const handleDefaultType = (
  isUniversityTeacher: boolean,
  isTeacher: boolean,
  isOwner: boolean,
): SearchTypes => {
  if (isUniversityTeacher || isOwner) {
    return SearchTypes.COMPANY_NAME;
  }
  if (isTeacher) {
    return SearchTypes.STUDENT_NAME;
  }
  throw new Error("Invalid type");
};

export const handleStudentQuestionary = (
  requestTime: string | null,
): boolean => {
  if (!requestTime) return false;
  const currentTime = dayjs();
  const parsedRequestTime = dayjs(requestTime, "YYYY/MM/DD HH:mm");
  const isExpired = currentTime.isAfter(parsedRequestTime);
  return isExpired;
};

export const handleFeedBackButtonAsStudent = (
  currentUser: CurrentUserInfo,
  feedBacks: InterviewFeedbackBase[],
): boolean => {
  if (!feedBacks.length) return true;
  const feedBackAffiliatesIds = feedBacks.map(
    (feedback) => feedback.student_affiliate.affiliate_id,
  );
  const isCurrentUserAsfeedBack = feedBackAffiliatesIds.includes(
    currentUser.affiliate_id as string,
  );
  return !isCurrentUserAsfeedBack;
};
