import React, { lazy,Suspense } from "react";

import { ImageModalProps } from "./ImageModal";

const ImageModal = lazy(() => import("./ImageModal"));

const LazyImageModal = (props: ImageModalProps): JSX.Element => {
  return (
    <Suspense fallback={<></>}>
      <ImageModal {...props} />
    </Suspense>
  );
};

export default LazyImageModal;
