import { getLearningPackagesAsStudent } from "@actions/studentApp/learning_packages";
import { LearningPackageBaseDetails } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface StudentAppLearningPackageState {
  fetchLearningPages: ReduxStatusType;
  fetchError: string | null;
  learningPackages: LearningPackageBaseDetails[];
  totalCount: number;
}

const initialState: StudentAppLearningPackageState = {
  fetchLearningPages: ReduxStatus.idle,
  fetchError: "",
  learningPackages: [],
  totalCount: 0,
};

const learningPackageSlice = createSlice({
  name: "student/Learning_package",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLearningPackagesAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchLearningPages: ReduxStatus.pending,
        };
      })
      .addCase(getLearningPackagesAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchLearningPages: ReduxStatus.fulfilled,
          learningPackages: payload.learning_packages,
          totalCount: payload.total_count,
        };
      })
      .addCase(getLearningPackagesAsStudent.rejected, (state, action) => {
        const { payload }: any = action;
        return {
          ...state,
          fetchLearningPages: ReduxStatus.rejected,
          fetchError: payload.errors.message,
        };
      });
  },
});

export const studentAppLearningPackageState = (
  state: RootState,
): StudentAppLearningPackageState => state.studentApp.learningPackage;

export default learningPackageSlice.reducer;
