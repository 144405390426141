/* eslint-disable camelcase */
import { getSteamLibraryContentDetailMoreLectures } from "@actions/steamLibrary";
import { Api, GetSteamLecturesAsTeacherParams } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { PostLectureParams } from "@root/types/Steam/lecture";
import { RootState } from "@store/store";

const api = new Api();

export const getSteamLectureDetailsAsTeacher = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibraryLecture/fetch",
  async (lectureId, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherSteamLecturesId(lectureId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSteamLectureAsTeacher = createAsyncThunk<
  any,
  PostLectureParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/steamLibraryLecture/post", async (params, { rejectWithValue }) => {
  try {
    const { contentId, title, sl_lecture_id, sequence } = params;
    const response = await api.teacher.postTeacherSteamLectures(
      { sl_content_id: contentId },
      { title, sl_lecture_id, sequence },
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getSteamLibraryLecturesAsTeacher = createAsyncThunk<
  any,
  GetSteamLecturesAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "steamLibraryContent/getLectures",
  async (params: GetSteamLecturesAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getSteamLecturesAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAllSteamLibraryLecturesAsTeacher = createAsyncThunk<
  any,
  GetSteamLecturesAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "steamLibraryContent/getAllLectures",
  async (
    params: GetSteamLecturesAsTeacherParams,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const allLecturesWithImages = await dispatch(
        getSteamLibraryContentDetailMoreLectures(
          params?.sl_content_id as number,
        ),
      );

      if (allLecturesWithImages) {
        await Promise.all(
          allLecturesWithImages?.payload.map((lecture: any) => {
            return dispatch(
              postSteamLectureAsTeacher({
                sl_lecture_id: lecture.id,
                title: lecture.title,
                contentId: lecture.content_id,
                sequence: lecture.sequence,
              }),
            );
          }),
        );
      }

      const response = await api.teacher.getSteamLecturesAsTeacher(params);

      const steamLecturesWithId = response.data.steam_lectures.map(
        (item: any) => {
          const steamLecturesWithImage = allLecturesWithImages?.payload.find(
            (obj: any) => obj.id === item.sl_lecture_id,
          );

          return {
            ...item,
            image: steamLecturesWithImage.thumbnail,
            contentId: steamLecturesWithImage.content_id,
          };
        },
      );

      return {
        lectures: steamLecturesWithId,
        totalCount: response.data.total_count,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetSteamLectureDetails = createAction(
  "teacher/steamLibraryLecture/resetState",
);
