/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-array-index-key */
import {
  NewNotifications,
  NotificationType,
} from "@actions/types/notification";
import ModalTransition from "@components/UI/atoms/ModalTransition";
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import LinkButton from "@components/UIv2/atoms/buttons/LinkButton/LinkButton";
import { useAuth } from "@contexts/Auth";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import errorWithMessage from "@lib/rtk/error-utils";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { IconButton, Theme, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  useGetNewNotificationCountAsCommonQuery,
  usePatchNewNotificationCountAsCommonMutation,
} from "@root/endpoints/TimeTactApi/remainingApi";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import NotificationCard from "./Card/Card";

type Props = {
  hasNewMessage: boolean;
  changeNewNotificationState: () => void;
  postNotifications: ReturnType<
    typeof usePatchNewNotificationCountAsCommonMutation
  >[0];
  newLimitedTimeNotificationCount: number;
  newNotificationCount: number;
  setOpenNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  postNotificationsIsLoading: boolean;
  notifications: NewNotifications[];
  newNotificationCountResult: ReturnType<
    typeof useGetNewNotificationCountAsCommonQuery
  >;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: 0,
      padding: theme.spacing(2),
      background: theme.palette.grey[200],
      color: theme.palette.common.black,
    },
    dialogContent: {
      paddingTop: theme.spacing(2),
      minWidth: 600,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 0),
        minWidth: 300,
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.common.black,
    },
    button: {
      margin: theme.spacing(1),
    },
  }),
);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    background: theme.palette.grey[200],
    flex: "display",
    justifyContent: "center",
  },
}))(MuiDialogActions);

const NewNotificationsModal: React.FC<Props> = ({
  hasNewMessage,
  changeNewNotificationState,
  postNotifications,
  newNotificationCount,
  newLimitedTimeNotificationCount,
  setOpenNotifications,
  postNotificationsIsLoading,
  notifications,
  newNotificationCountResult,
}) => {
  const { currentUser, proxy } = useAuth();
  const classes = useStyles();
  const { t } = useTranslation(["modal"]);
  const {
    actions: { showError },
  } = useEnqueueToast();

  const [open, setOpen] = useState<boolean>(true);
  const unreadNotifications = notifications?.filter(
    (notification: NewNotifications) =>
      notification?.type === NotificationType.NEW,
  );
  const unreadLimitedTimeNotifications = notifications?.filter(
    (notification: NewNotifications) =>
      notification?.type === NotificationType.LIMITED,
  );

  const notDisplayedNotificationsCount =
    newNotificationCount +
    newLimitedTimeNotificationCount -
    (notifications?.length ?? 0);

  const handleClose = async () => {
    await postNotifications({})
      .unwrap()
      .then(() => {
        newNotificationCountResult.refetch();
      })
      .catch((err) => showError(errorWithMessage(err)))
      .finally(() => setOpen(false));
  };

  return (
    <Dialog
      open={
        open &&
        hasNewMessage &&
        !!notifications?.length &&
        !!currentUser?.terms_accepted &&
        !proxy
      }
      onClose={(_event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return false;
        }
        return handleClose();
      }}
      TransitionComponent={ModalTransition}
      TransitionProps={{ onExited: changeNewNotificationState }}
      id="newNotificationsModal"
    >
      <DialogTitle className={classes.title}>
        <Box display="flex" alignItems="center">
          <NotificationsOutlinedIcon />
          <Box pl={1} data-cy="notification-title">
            {t("new_notification")}
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
          disabled={postNotificationsIsLoading}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Container maxWidth="md" sx={{ p: 2 }}>
          <Box mb={1}>
            <Typography>
              {t("new_notification_message", {
                number: newNotificationCount + newLimitedTimeNotificationCount,
              })}
            </Typography>
          </Box>
          {!!notDisplayedNotificationsCount && (
            <Box display="flex" justifyContent="end" mb={1}>
              <LinkButton
                text={t("notification_message", {
                  number: notDisplayedNotificationsCount,
                })}
                handleClick={() => {
                  setOpenNotifications(true);
                  handleClose();
                }}
              />
            </Box>
          )}
          {unreadNotifications?.map(
            (notification: NewNotifications, index: number) => (
              <NotificationCard
                key={`notification-${index}`}
                notification={notification}
                handleClose={handleClose}
                changeNewNotificationState={changeNewNotificationState}
              />
            ),
          )}
          {unreadLimitedTimeNotifications?.map(
            (limitedNotification: NewNotifications, index: number) => (
              <NotificationCard
                key={`limitedNotification-${index}`}
                limitedNotification={limitedNotification}
                handleClose={handleClose}
                changeNewNotificationState={changeNewNotificationState}
              />
            ),
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <SingleButton
          variant="contained"
          startIcon={<CancelIcon />}
          onClick={handleClose}
          className={classes.button}
          data-cy="notification-close-button"
          disabled={postNotificationsIsLoading}
        >
          {postNotificationsIsLoading ? (
            <CircularProgress size="22px" />
          ) : (
            t("close")
          )}
        </SingleButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewNotificationsModal;
