/* eslint-disable camelcase */
import {
  Api,
  Error as SvError,
  FetchPblSponsorLinkableCompaniesAsAdminParams,
  GetSponsorLinksAsAdminParams,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblSponsorSchoolLinksAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/schoolLinks/fetch",
  async (params: GetSponsorLinksAsAdminParams, { rejectWithValue }) => {
    try {
      const response = await api.admin.getSponsorLinksAsAdmin(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchLinkableSchools = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/schoolLinks/fetchLinkableSchools",
  async (
    params: FetchPblSponsorLinkableCompaniesAsAdminParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.admin.fetchPblSponsorLinkableCompaniesAsAdmin(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface CreatePblSponsorSchoolLinksAsAdminArg {
  sponsorId: string;
  schoolIds: string[];
}

const createPblSponsorLink = async (sponsorId: string, schoolId: string) => {
  try {
    const response = await api.admin.postSponsorLinksAsAdmin(sponsorId, {
      school_id: schoolId,
    });
    return { success: true, data: response.data as any };
  } catch (err) {
    return { success: false, err: err as SvError };
  }
};

export interface CreateSponsorSchoolLinkAsAdminParams {
  info_id: string;
  school_id: string;
}

export const createSponsorSchoolLinkAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/schoolLinks/create",
  async (params: CreateSponsorSchoolLinkAsAdminParams, { rejectWithValue }) => {
    const { info_id, school_id } = params;
    try {
      const response = await api.admin.postSponsorLinksAsAdmin(info_id, {
        school_id,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// This method is not used currently. Instead use createSponsorSchoolLinkAsAdmin to call api everytime user toggle the checkbox.
// Leave this method for now, delete this once current implementation is accepted.
export const createPblSponsorSchoolLinksAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/schoolLinks/createMany",
  async (arg: CreatePblSponsorSchoolLinksAsAdminArg, { rejectWithValue }) => {
    const { sponsorId, schoolIds } = arg;
    try {
      const results = await Promise.all(
        schoolIds.map((schoolId) => createPblSponsorLink(sponsorId, schoolId)),
      );
      let createdItems: any[] = [];
      let errorList: SvError[] = [];
      results.forEach((result) => {
        if (result.success) {
          createdItems = [...createdItems, result.data];
        } else {
          errorList = [...errorList, result.err as SvError];
        }
      });
      return {
        createdItems,
        errorList,
      };
    } catch (err) {
      const typedErr = err as any;
      return rejectWithValue(typedErr);
    }
  },
);

interface DeleteSponsorSchoolLinkParams {
  info_id: string;
  id: string;
}

export const deleteSponsorSchoolLinkAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/schoolLinks/delete",
  async (params: DeleteSponsorSchoolLinkParams, { rejectWithValue }) => {
    const { info_id, id } = params;
    try {
      const response = await api.admin.deleteSponsorLinkAsAdmin(info_id, id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetAction = createAction(
  "admin/pbl/sponsor/schoolLinks/resetAction",
);
export const reset = createAction("admin/pbl/sponsor/schoolLinks/reset");
export const resetOne = createAction("admin/pbl/sponsor/schoolLinks/resetOne");
