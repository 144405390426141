import React, { memo } from "react";
import { Path,useFormState } from "react-hook-form";

import { saveColumnName,SVEditorFormValue } from "../types";
import useSVEditorStyles from "../useSVEditorStyles";

type Props<T extends SVEditorFormValue> = {
  FormValueName: Path<T>;
  children: JSX.Element;
};

const SVEditorWrapper = <T extends SVEditorFormValue>({
  FormValueName,
  children,
}: Props<T>): JSX.Element => {
  const { errors } = useFormState<T>();
  const { SVEditorClasses: classes } = useSVEditorStyles();
  return (
    <div
      className={classes.SVEditorWrapper}
      style={{
        ...(errors[FormValueName as saveColumnName] && {
          borderColor: "red",
        }),
      }}
    >
      {children}
    </div>
  );
};

export default memo(SVEditorWrapper) as typeof SVEditorWrapper;
