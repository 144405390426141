import { AffiliateInvitation } from "@lib/Api";
import { update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";

import {
  activate,
  fetchAffiliateInvitationsAsOwner,
  inactivate,
  reactivate,
  resetAffiliateInvitationData,
  resetAffiliateInvitationModalData,
} from "../../actions/ownerApp/affiliateInvitation";
import { RootState } from "../../store";

export interface OwnerAppAffiliateInvitationState {
  fetching: boolean;
  fetched: boolean;
  fetchError: any;
  invitations: {
    student: AffiliateInvitation[];
    teacher: AffiliateInvitation[];
  };
  changing: boolean;
  changed: boolean;
  changeError: any;
  totalCount: number;
}

export const initialState: OwnerAppAffiliateInvitationState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  invitations: {
    student: [],
    teacher: [],
  },
  changing: false,
  changed: false,
  changeError: null,
  totalCount: 0,
};

export const ownerAppAffiliateInvitationSlice = createSlice({
  name: "OwnerApp/AffiliateInvitation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAffiliateInvitationsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          fetchError: null,
        };
      })
      .addCase(fetchAffiliateInvitationsAsOwner.fulfilled, (state, action) => {
        const payload = action.payload as any;
        const { role } = action.meta.arg;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: null,
          invitations: {
            ...state.invitations,
            [role as string]: payload.invitations,
          },
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchAffiliateInvitationsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error as any,
        };
      })
      .addCase(activate.pending, (state, _action) => {
        return {
          ...state,
          changed: false,
          changing: true,
          changeError: null,
        };
      })
      .addCase(activate.fulfilled, (state, action) => {
        const payload = action.payload as any;
        const { invitation, role } = payload;
        const currentInvitations =
          state.invitations[role as "student" | "teacher"];
        return {
          ...state,
          changing: false,
          changed: true,
          changeError: null,
          invitations: {
            ...state.invitations,
            [role]: [...currentInvitations, invitation],
          },
        };
      })
      .addCase(activate.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: action.error as any,
        };
      })
      .addCase(inactivate.pending, (state, _action) => {
        return {
          ...state,
          changed: false,
          changing: true,
          changeError: null,
        };
      })
      .addCase(inactivate.fulfilled, (state, action) => {
        const payload = action.payload as any;
        const currentState = current(state);
        return {
          ...state,
          changing: false,
          changed: true,
          changeError: null,
          invitations: {
            student: update(currentState.invitations.student, payload),
            teacher: update(currentState.invitations.teacher, payload),
          },
        };
      })
      .addCase(inactivate.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: action.error as any,
        };
      })
      .addCase(reactivate.pending, (state, _action) => {
        return {
          ...state,
          changed: false,
          changing: true,
          changeError: null,
        };
      })
      .addCase(reactivate.fulfilled, (state, action) => {
        const payload = action.payload as any;
        const currentState = current(state);
        return {
          ...state,
          changing: false,
          changed: true,
          changeError: null,
          invitations: {
            student: update(currentState.invitations.student, payload),
            teacher: update(currentState.invitations.teacher, payload),
          },
        };
      })
      .addCase(reactivate.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: action.error,
        };
      })
      .addCase(resetAffiliateInvitationData, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: null,
          changing: false,
          changed: false,
          changeError: null,
          invitations: {
            student: [],
            teacher: [],
          },
        };
      })
      .addCase(resetAffiliateInvitationModalData, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: null,
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const ownerAppAffiliateInvitationState = (
  state: RootState,
): OwnerAppAffiliateInvitationState => state.ownerApp.affiliateInvitation;

export default ownerAppAffiliateInvitationSlice.reducer;
