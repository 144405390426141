/* eslint-disable @typescript-eslint/ban-types */
import { fetchSuperOwnerCompanyTagTypes } from "@actions/superOwnerApp/companyTagTypes";
import { fetchCompaniesAsSuperOwner } from "@actions/superOwnerApp/school";
import {
  fetchLimitedUserTagsAsSuperOwner,
  fetchUserTagsAsSuperOwner,
  RoleType,
} from "@actions/superOwnerApp/userTag";
import { DistributionEnum } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList";
import DatePicker from "@components/UI/molecules/StartEndDatePicker";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import V2StyledFormTitle from "@components/UIv2/atoms/V2StyledFormTitle";
import { UserTag } from "@lib/Api";
import { Theme } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { superOwnerAppCompanyTagTypesState } from "@reducers/superOwnerApp/companyTagType";
import { superOwnerAppSchoolsState } from "@reducers/superOwnerApp/school";
import { superOwnerAppUserTagsState } from "@reducers/superOwnerApp/userTag";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { FormType } from "../usePackageDistributionModal";
import GradeSelector from "./CommonGradeSelector";
import CommonTagAccordion from "./CommonTagAccordion";
import DistributeByShool from "./DistributeBySchool";

export const StyledFormHelperText = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "red",
      fontSize: 12,
      marginBottom: 10,
      textAlign: "center",
    },
  }),
)(FormHelperText);

interface Props {
  distributionType: DistributionEnum | undefined;
  control: Control<FormType, object>;
  setValue: UseFormSetValue<FormType>;
  getValues: UseFormGetValues<FormType>;
  watch: UseFormWatch<FormType>;
  errors: any;
  reset: UseFormReset<FormType>;
}

const StepOne: React.FC<Props> = ({
  control,
  setValue,
  watch,
  distributionType,
  getValues,
  errors,
  reset,
}) => {
  const dispatch = useAppDispatch();
  const userTagsCache = useAppSelector(superOwnerAppUserTagsState);
  const {
    limitedUserTags,
    userTags,
    fetching: fetchingUserTags,
  } = userTagsCache;
  const companyTagCache = useAppSelector(superOwnerAppCompanyTagTypesState);
  const { companyTagTypes, fetching: fetchingCompanyTags } = companyTagCache;
  const schoolCache = useAppSelector(superOwnerAppSchoolsState);
  const { schools, fetching: fetchingSchools } = schoolCache;
  const loading = fetchingCompanyTags || fetchingSchools || fetchingUserTags;

  const handleTagIds = (value: UserTag) => {
    const { tagIds } = getValues();
    const compareTo = tagIds.map((item) => item.id);
    const allTags = tagIds;
    const newIds = compareTo?.includes(value.id)
      ? allTags?.filter((tag: UserTag) => tag.id !== value.id)
      : [...(allTags ?? []), value];
    return newIds;
  };

  const handleDistributionTypesComponent = (type: DistributionEnum) => {
    switch (type) {
      case DistributionEnum.BY_SCHOOL:
        return (
          <DistributeByShool
            companyTagTypes={companyTagTypes}
            schools={schools}
            control={control}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
            errors={errors}
            reset={reset}
          />
        );
      case DistributionEnum.BY_USER_TAG:
        return (
          <CommonTagAccordion
            handleIds={handleTagIds}
            tags={userTags}
            control={control}
            errors={errors}
          />
        );
      case DistributionEnum.BY_LIMITED_USER_TAG:
        return (
          <CommonTagAccordion
            handleIds={handleTagIds}
            tags={limitedUserTags}
            control={control}
            errors={errors}
          />
        );
      default:
        return null;
    }
  };

  const distributionTypeComponent = handleDistributionTypesComponent(
    distributionType as DistributionEnum,
  );

  const fetchData = () => {
    if (distributionType === DistributionEnum.BY_SCHOOL) {
      dispatch(fetchCompaniesAsSuperOwner({ params: { simple_info: true } }));
      dispatch(fetchSuperOwnerCompanyTagTypes({}));
    } else if (distributionType === DistributionEnum.BY_USER_TAG) {
      dispatch(
        fetchUserTagsAsSuperOwner({ params: { role_type: RoleType.Student } }),
      );
    } else if (distributionType === DistributionEnum.BY_LIMITED_USER_TAG) {
      dispatch(fetchLimitedUserTagsAsSuperOwner({}));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <DatePicker
            control={control}
            setValue={setValue}
            getValues={getValues}
            time
            disablePast
            startInputTitle={<V2StyledFormTitle isRequired title="開始日時" />}
            endInputTitle={<V2StyledFormTitle isRequired title="終了日時" />}
            startInputName="startDate"
            endInputName="endDate"
            minEndDate={watch("startDate") || dayjs()}
            isRequired
            startHelperMsg="開始日時を設定してください"
            endHelperMsg="終了日時を設定してください"
          />
          {distributionTypeComponent}
          <GradeSelector control={control} />
        </>
      )}
    </div>
  );
};

export default StepOne;
