import { fetchLearningTemplatePackagesAsSuperOwner } from "@actions/superOwnerApp/templatePackages/learningTemplatePackage";
import BookIcon from "@assets/images/svg/book_icon.svg?react";
import CaseIcon from "@assets/images/svg/case_icon.svg?react";
import CoPackageIcon from "@assets/images/svg/document_package_icon.svg?react";
import PenIcon from "@assets/images/svg/edition_pen_icon.svg?react";
import SortIcon from "@assets/images/svg/sort_icon.svg?react";
import TrashIcon from "@assets/images/svg/trash_icon.svg?react";
import MainButton from "@components/UI/atoms/MainButton/MainButton";
import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import { ReduxStatus } from "@constants/redux";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import {
  LearningTemplatePackageBase,
  PackagingAsLearningTemplatePackageBase,
} from "@lib/Api";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, IconButton, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { usePutMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwnerMutation } from "@root/endpoints/TimeTactApi/superOwnerApi";
import { useAppDispatch } from "@root/hooks";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";

import { useCoPackage } from "./useCoPackage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    package: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: theme.spacing(1),
      padding: theme.spacing(0, 1),
    },
    packageTitle: {
      display: "flex",
      alignItems: "center",
      height: "60px",
    },
    packageInner: {
      width: "100%",
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.quaternary.lightGrey,
      padding: "1rem",
      borderRadius: "8px",
    },
    icons: {
      color: theme.palette.common.white,
    },
    iconBox: {
      display: "flex",
    },
    sortIconBox: {
      display: "flex",
      justifyContent: "flex-end",
    },
    coPackageIconBox: {
      display: "flex",
      alignItems: "center",
    },
  }),
);

interface Props {
  handleModalOpen: (
    modalStatus: ModalStatus,
    learningTemplateInfos?: LearningTemplatePackageBase,
    packageInfos?: PackagingAsLearningTemplatePackageBase,
    packagesArrayInfos?: PackagingAsLearningTemplatePackageBase[],
  ) => void;
  templatePackage: PackagingAsLearningTemplatePackageBase;
  learningTemplate: LearningTemplatePackageBase;
  sortDisabled: boolean;
  currentPage: number;
}

const CoPackage: React.FC<Props> = ({
  handleModalOpen,
  learningTemplate,
  templatePackage,
  sortDisabled,
  currentPage,
}) => {
  const classes = useStyles();
  const { actions } = useEnqueueToast();
  const dispatch = useDispatch();
  const packageContents =
    templatePackage?.material_homework_template_package?.packagings ?? [];
  const packageId = templatePackage?.material_homework_template_package?.id;
  const [loading, setLoading] = useState(false);

  const {
    handleSortState,
    onDragEnd,
    sortActivated,
    reorderCoPackageList,
    setIsSaved,
    setSortActivated,
  } = useCoPackage(packageContents);

  const [putMaterialHomeworkTemplatePackagePackagingSequence] =
    usePutMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwnerMutation();

  const handleSaveSortedBoardList = async () => {
    if (reorderCoPackageList && packageId) {
      setLoading(true);
      const promiseAll: Promise<ReturnType<typeof useAppDispatch>>[] = [];
      reorderCoPackageList.forEach(async (coPackage, index) => {
        const param = {
          id: packageId,
          packagingId: coPackage.id,
          sequenceRequiredRequestBody: {
            sequence: index,
          },
        };
        promiseAll.push(
          putMaterialHomeworkTemplatePackagePackagingSequence(param),
        );
      });

      const results = await Promise.allSettled(promiseAll);

      const errors = results.filter(
        (result) => result.status === ReduxStatus.rejected,
      );
      if (errors.length > 0) {
        actions.showErrors(["順番が更新されませんでした。"]);
      } else {
        dispatch(
          fetchLearningTemplatePackagesAsSuperOwner({
            page: currentPage,
            perPage: 10,
          }),
        );
        actions.showSuccess("順番が更新されました。");
      }
      setLoading(false);
    }

    setIsSaved(true);
    setSortActivated(!sortActivated);
  };

  return (
    <Box>
      <Box className={classes.package}>
        <Box className={classes.packageTitle}>
          <span style={{ marginRight: "16px" }}>
            <CoPackageIcon />
          </span>
          <Typography>
            {templatePackage?.material_homework_template_package?.title}
          </Typography>
        </Box>
        {!learningTemplate.is_distributed && sortDisabled && (
          <Box className={classes.iconBox}>
            <Box className={classes.sortIconBox}>
              {sortDisabled && (
                <StyledTooltip
                  title={sortActivated ? "キャンセル" : "コンテンツの並び替え"}
                >
                  <IconButton
                    aria-label="閉じる"
                    color="inherit"
                    size="medium"
                    onClick={handleSortState}
                    className={classes.icons}
                  >
                    <SortIcon />
                  </IconButton>
                </StyledTooltip>
              )}
              {sortActivated && (
                <MainButton
                  disabled={loading}
                  variant="contained"
                  onClick={handleSaveSortedBoardList}
                >
                  保存する
                </MainButton>
              )}
            </Box>
            <StyledTooltip title="コンテンツの更新">
              <IconButton
                onClick={() =>
                  handleModalOpen(
                    ModalStatus.EDIT_ADDED_PACKAGE,
                    learningTemplate,
                    templatePackage,
                  )
                }
                className={classes.icons}
                size="large"
              >
                <PenIcon />
              </IconButton>
            </StyledTooltip>
            <StyledTooltip title="コンテンツの削除">
              <IconButton
                className={classes.icons}
                onClick={() =>
                  handleModalOpen(
                    ModalStatus.DELETE_CO_PACKAGE,
                    learningTemplate,
                    templatePackage,
                  )
                }
                size="large"
              >
                <TrashIcon />
              </IconButton>
            </StyledTooltip>
          </Box>
        )}
      </Box>
      <Box className={classes.packageInner}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="packageList">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {reorderCoPackageList &&
                  reorderCoPackageList.length > 0 &&
                  reorderCoPackageList.map((coPackage, index) => {
                    const IconComponent =
                      coPackage.packageable_type === "HomeworkTemplate"
                        ? CaseIcon
                        : BookIcon;

                    return (
                      <Draggable
                        key={coPackage.id}
                        draggableId={coPackage.id}
                        index={index}
                        isDragDisabled={!sortActivated}
                      >
                        {(dragProvided) => (
                          <div
                            className={classes.package}
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...(sortActivated
                              ? dragProvided.dragHandleProps
                              : {})}
                          >
                            <Box display="flex" alignItems="center">
                              <span style={{ marginRight: "16px" }}>
                                <Box className={classes.coPackageIconBox}>
                                  {sortDisabled && sortActivated && (
                                    <DragIndicatorIcon />
                                  )}
                                  <IconComponent />
                                </Box>
                              </span>
                              <Typography>
                                {coPackage.packageable_title}
                              </Typography>
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default CoPackage;
