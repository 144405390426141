/* eslint-disable camelcase */
import {
  Api,
  GetPblBoardCampaignsAsOwnerParams,
  PblBoardCampaignBase,
  PblBoardCampaignList,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  PostPblBoardCampaignParams,
  PutPblBoardCampaignParams,
} from "@root/types/PblBoard/Campaign";
import { RootState } from "@store/store";

const api = new Api();

export const getPblBoardCampaignsAsOwner = createAsyncThunk<
  { board_campaigns: PblBoardCampaignList[]; total_count: number },
  GetPblBoardCampaignsAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/pbl/board/campaigns/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.getPblBoardCampaignsAsOwner(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getPblBoardCampaignAsOwner = createAsyncThunk<
  PblBoardCampaignBase,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/pbl/board/campaigns/fetchOne", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.getPblBoardCampaignAsOwner(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const postPblBoardCampaignAsOwner = createAsyncThunk<
  any,
  PostPblBoardCampaignParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/pbl/board/campaigns/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.postPblBoardCampaignAsOwner(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const putPblBoardCampaignAsOwner = createAsyncThunk<
  any,
  PutPblBoardCampaignParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/pbl/board/campaigns/put", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    const response = await api.owner.putPblBoardCampaignAsOwner(id, data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deletePblBoardCampaignAsOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/pbl/board/campaigns/delete", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.deletePblBoardCampaignAsOwner(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetOwnerAppPblBoardCampaignState = createAction(
  "ownerApp/pbl/board/resetPblBoardCampaignState",
);

export const resetOwnerAppPblBoardCampaignModalState = createAction(
  "ownerApp/pbl/board/resetPblBoardCampaignModalState",
);
