import { BaseCategoryInfo, GradeCategoryInfo } from "@lib/Api";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { StyledAccordion, StyledAccordionSummary } from "..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    minortext: {
      fontSize: 16,
      marginLeft: theme.spacing(6),
      cursor: "pointer",
    },
    card: {
      borderRadius: 0,
    },
    summary: {
      backgroundColor: theme.palette.common.white,
    },
    activeSummary: {
      backgroundColor: theme.palette.grey[100],
    },
  }),
);

interface Props {
  categoryItem: GradeCategoryInfo;
  selectedCategoryId: string[];
  handleSelectedIds: (item: BaseCategoryInfo) => void;
}

const MinorLayer: React.FC<Props> = (props: Props) => {
  const { categoryItem, selectedCategoryId, handleSelectedIds } = props;
  const classes = useStyles();
  return (
    <StyledAccordion square className={classes.card}>
      <StyledAccordionSummary
        className={
          selectedCategoryId.includes(categoryItem.id)
            ? classes.activeSummary
            : classes.summary
        }
        onClick={() => handleSelectedIds(categoryItem)}
      >
        <Typography className={classes.minortext}>
          {categoryItem.name}
        </Typography>
      </StyledAccordionSummary>
    </StyledAccordion>
  );
};

export default MinorLayer;
