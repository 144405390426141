import {
  createSponsorLectureFileAsAdmin,
  deleteSponsorLectureFileAsAdmin,
  resetAction,
} from "@actions/adminApp/pbl/sponsor/lectureFile";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface AdminAppPblSponsorLectureFileState {
  creating: boolean;
  created: boolean;
  deleting: boolean;
  deleted: boolean;
  changeErrors: any[] | null;
}

export const initialState: AdminAppPblSponsorLectureFileState = {
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  changeErrors: null,
};

const adminAppPblSponsorLectureFileSlice = createSlice({
  name: "adminApp/pbl/sponsors/projects/category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSponsorLectureFileAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          changeErrors: null,
        };
      })
      .addCase(createSponsorLectureFileAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeErrors: action.payload,
        };
      })
      .addCase(createSponsorLectureFileAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          creating: false,
          created: true,
          changeErrors: null,
        };
      })
      .addCase(deleteSponsorLectureFileAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          changeErrors: null,
        };
      })
      .addCase(deleteSponsorLectureFileAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          changeErrors: action.payload,
        };
      })
      .addCase(deleteSponsorLectureFileAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: true,
          changeErrors: null,
        };
      })
      .addCase(resetAction, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          changeErrors: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const adminAppPblSponsorLectureFileState = (
  state: RootState,
): AdminAppPblSponsorLectureFileState => state.adminApp.pblSponsorLectureFile;

export default adminAppPblSponsorLectureFileSlice.reducer;
