/* eslint-disable @typescript-eslint/ban-types */
import {
  postDailyLogAsStudent,
  putDailyLogAsStudent,
} from "@actions/studentApp/student_logs";
import CheckedIcon from "@assets/images/svg/check-one.svg?react";
import ChronoIcon from "@assets/images/svg/chono_icon.svg?react";
import CrossIcon from "@assets/images/svg/close-one.svg?react";
import {
  StudyLogsModalState,
  StudyLogsModalTypes,
} from "@components/Teacher/StudentLogs";
import { DailyLogCategory, DailyLogCategoryJa } from "@lib/Api";
import { formatedDate } from "@lib/time-utils";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button,Theme, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { useAppDispatch } from "@root/hooks";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation, withTranslation } from "react-i18next";

import LogForm from "./Form";

interface Props {
  handleClose: () => void;
  modalState: StudyLogsModalTypes;
  posting: boolean;
  posted: boolean;
  setModalState?: React.Dispatch<React.SetStateAction<StudyLogsModalTypes>>;
}

const useStyles: any = makeStyles<Theme, { posting: boolean }>((theme: Theme) =>
  createStyles({
    modalHeader: {
      position: "relative",
      margin: 0,
      padding: theme.spacing(2),
      background: theme.palette.action.hover,
      color: theme.palette.common.black,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: "-7px",
      color: theme.palette.tertiary.main,
    },
    modalTitle: {
      fontWeight: 900,
      color: theme.palette.common.white,
      marginRight: 10,
    },
    modalBody: {
      background: ({ posting }) =>
        posting ? theme.palette.common.white : theme.palette.tertiary.main,
      padding: 10,
    },
    contentWrapper: {
      display: "block",
      background: theme.palette.common.white,
      borderRadius: "3px",
      padding: 10,
    },
    primaryButton: {
      color: theme.palette.tertiary.mainDark,
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${theme.palette.tertiary.mainDark}`,
      fontWeight: 600,
      marginLeft: theme.spacing(1),
      "&:hover": {
        border: `2px solid ${theme.palette.tertiary.mainDark}`,
      },
    },
    secondaryButton: {
      color: theme.palette.tertiary.main,
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${theme.palette.tertiary.main}`,
      fontWeight: 600,
      "&:hover": {
        border: `2px solid ${theme.palette.tertiary.main}`,
      },
    },
    buttonWapper: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  }),
);

const StyledDialog = withStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    paperFullWidth: {
      width: "100%",
      minHeight: 670,
      [theme.breakpoints.down("sm")]: {
        margin: 11,
      },
    },
    paperWidthFalse: {
      maxWidth: "initial",
    },
  }),
)(Dialog);

export type FormType = {
  date: string;
  category: string;
  logTime: number | string;
  memo: string;
};

const CreateOrUpdateLogModal: React.FC<Props> = ({
  handleClose,
  modalState,
  posting,
  posted,
  setModalState,
}) => {
  const classes = useStyles(posting);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("study_logs");
  const { open, modalState: status, dailyInfos, step } = modalState;
  const defaultValues = {
    date: dayjs().format("YYYY-MM-DD"),
    category: "",
    logTime: "",
    memo: "",
  };
  const { control, handleSubmit, reset } = useForm<FormType>({
    defaultValues,
  });

  const handleCategory = () => {
    if (dailyInfos) {
      const { category } = dailyInfos;
      if (DailyLogCategoryJa.analysis === category) {
        return DailyLogCategory.Analysis;
      }
      if (DailyLogCategoryJa.research === category) {
        return DailyLogCategory.Research;
      }
      if (DailyLogCategoryJa.summary === category) {
        return DailyLogCategory.Summary;
      }
    }
    return DailyLogCategory.ThemeSettings;
  };

  const modalCloser = () => {
    if (status === StudyLogsModalState.Edit && setModalState) {
      reset(defaultValues);
      setModalState({
        open: true,
        step: 2,
        modalState: StudyLogsModalState.Details,
        dailyInfos,
      });
    } else {
      reset(defaultValues);
      handleClose();
    }
  };

  const onSubmit = (values: FormType) => {
    const params = {
      date: values.date,
      study_time: values.logTime as number,
      category: values.category as DailyLogCategory,
      memo: values.memo || undefined,
    };
    if (dailyInfos && status === StudyLogsModalState.Edit) {
      const isDataChanged =
        values.category === handleCategory() &&
        values.date === formatedDate(dailyInfos.date) &&
        values.logTime === dailyInfos.study_time &&
        values.memo === dailyInfos.memo;
      if (isDataChanged) {
        return modalCloser();
      }
      return dispatch(
        putDailyLogAsStudent({
          id: dailyInfos.id,
          data: params,
        }),
      );
    }
    return dispatch(postDailyLogAsStudent(params));
  };

  useEffect(() => {
    if (open && dailyInfos && status === StudyLogsModalState.Edit) {
      reset({
        date: formatedDate(dailyInfos.date),
        category: handleCategory(),
        logTime: dailyInfos.study_time,
        memo: dailyInfos.memo,
      });
    }
  }, [open, status]);

  let modalTitle = t("recordStudyTime");
  let primaryButtonTitle = t("save");

  if (status === StudyLogsModalState.Edit) {
    modalTitle = t("updateStudyTime");
    primaryButtonTitle = t("update");
  }

  useEffect(() => {
    if (posted) {
      reset(defaultValues);
    }
  }, [posted]);

  return (
    <StyledDialog
      open={open && step === 3}
      onClose={modalCloser}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.modalHeader}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={modalCloser}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.modalBody}>
        <>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            my={1}
          >
            <Typography className={classes.modalTitle}>{modalTitle}</Typography>
            <span>
              <ChronoIcon />
            </span>
          </Box>
          <Box className={classes.contentWrapper}>
            <LogForm control={control} />

            <Box className={classes.buttonWapper}>
              <Button
                classes={{
                  outlined: classes.secondaryButton,
                }}
                startIcon={<CrossIcon />}
                variant="outlined"
                onClick={modalCloser}
              >
                {t("cancel")}
              </Button>
              <Button
                classes={{
                  outlined: classes.primaryButton,
                }}
                startIcon={<CheckedIcon />}
                variant="outlined"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={posting}
              >
                {posting ? (
                  <CircularProgress size="22px" />
                ) : (
                  primaryButtonTitle
                )}
              </Button>
            </Box>
          </Box>
        </>
      </DialogContent>
    </StyledDialog>
  );
};
CreateOrUpdateLogModal.defaultProps = {
  setModalState: undefined,
};
export default withTranslation(["study_logs"])(CreateOrUpdateLogModal);
