/* eslint-disable camelcase */
import { VideoInfo } from "@components/UIv2/organisms/V2VideoPlayer";
import {
  SponsorLectureVideoContentBase,
  SponsorProjectVideoContentBase,
} from "@lib/Api";
import Cookies from "js-cookie";

const getStreamingUrl = (video: VideoInfo): string => {
  return (video.external_link ||
    video.encoded_path ||
    video.file_url) as string;
};
export default getStreamingUrl;

export const getSponsorStreamingUrl = (
  video:
    | SponsorProjectVideoContentBase
    | SponsorLectureVideoContentBase
    | null
    | undefined,
): string | null | undefined => {
  return video?.external_link || video?.encoded_path || video?.url;
};

export const isValidUrl = new RegExp(
  "^(https?:\\/\\/)?" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?$",
  "i",
);

type StreamingInfo = {
  url: string;
  needsCookie: boolean;
};

const isTestVideo =
  /.*\/uploads\/video_content\/file\/.*hlstest-B9318734-BD8B-42FF-98C5-F35A36AC20B4.*/;

export const getStreamingInfo = (video: VideoInfo): StreamingInfo => {
  const { external_link, cookie } = video;
  let url = getStreamingUrl(video);
  let needsCookie = false;
  if (!external_link && cookie && isTestVideo.test(url)) {
    Cookies.set("CloudFront-Key-Pair-Id", cookie.cloudfront_key_pair_id);
    Cookies.set("CloudFront-Policy", cookie.cloudfront_policy);
    Cookies.set("CloudFront-Signature", cookie.cloudfront_signature);
    url = cookie.unsigned_url;
    needsCookie = true;
  }
  return {
    url,
    needsCookie,
  };
};
