/* eslint-disable camelcase */
import { Api, FetchHomeworksAsReviewerParams, HomeworkStatus } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

export const fetchHomeworkAsReviewer = createAsyncThunk<
  GetResponseType<Api["reviewer"]["fetchHomeworksAsReviewer"]>,
  FetchHomeworksAsReviewerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("reviewerApp/homework/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.reviewer.fetchHomeworksAsReviewer(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchOneHomeworkAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewerApp/homework/fetchOne",
  async (homeworkId: string, { rejectWithValue }) => {
    try {
      const response = await api.reviewer.fetchHomeworkAsReviewer(homeworkId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export type UpdateHomeworkAsReviewerParams = {
  status?: HomeworkStatus;
  reviewer_comment?: string;
  reviewer_comment_for_teacher?: string;
  score?: number;
};

export interface UpdateHomeworkAsReviewerArg {
  homeworkId: string;
  params: UpdateHomeworkAsReviewerParams;
}
export interface AssignHomeworkAsReviewerArg {
  isBatched?: boolean;
  homework_id: string;
  data: {
    add_reviewer_affiliate_ids?: string[];
    remove_reviewer_affiliate_ids?: string[];
  };
}

export const updateOneHomeworkAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewerApp/homework/update",
  async (
    { homeworkId, params }: UpdateHomeworkAsReviewerArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.reviewer.updateHomeworkAsReviewer(
        homeworkId,
        params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const assignReviewerToHomework = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewerApp/homework/assign/update",
  async (
    { homework_id, isBatched, data }: AssignHomeworkAsReviewerArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.reviewer.homeworksAssignUpdate(
        homework_id,
        data,
      );
      if (!isBatched) {
        dispatch(fetchOneHomeworkAsReviewer(homework_id));
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetModalState = createAction(
  "reviewer/homework/resetModalState",
);

export const updatedHomework = createAction(
  "reviewerApp/homework/updatedHomework",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const fileUploaded = createAction(
  "reviewerApp/homework/fileUploaded",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const fileDeleted = createAction(
  "reviewerApp/homework/fileDeleted",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const resetState = createAction("reviewerApp/homework/resetState");

export const changeAllStatusSuccess = createAction(
  "reviewerApp/homework/statusChanged",
);
export const changeAllStatusError = createAction(
  "reviewerApp/homework/statusChangedError",
);

export const updatePersonInChargeSuccess = createAction(
  "reviewerApp/homework/updatePersonInChargeSuccess",
);
export const updatePersonInChargeError = createAction(
  "reviewerApp/homework/updatePersonInChargeError",
);

export const resetOne = createAction("reviewerApp/homework/resetOne");

export const resetReviewerAppHomeworkFormData = createAction(
  "reviewerApp/homework/resetFormRelatedData",
);
