import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ListItemIconStyle: {
      minWidth: "32px !important",
    },
    default: {
      cursor: "pointer",
      backgroundColor: "white",
      borderRadius: 10,
      border: 0,
      flexShrink: 0,
      color: theme.palette.customColor.text,
      "& .MuiSvgIcon-fontSizeSmall": {
        verticalAlign: "middle",
      },
      verticalAlign: "middle",
      "&:hover": {
        backgroundColor: "rgba(71, 81, 73, 0.04)",
      },
    },
    isActive: {
      cursor: "pointer",
      color: theme.palette.tertiary.mainDark,
      fontWeight: "bold",
      backgroundColor: "white",
      border: "0px",
      verticalAlign: "middle",
      height: "2em",
    },
    highlightStyle: {
      color: "white",
      backgroundColor: theme.palette.common.black,
    },
    hightlightColorReset: {
      border: "1px solid black",
      backgroundColor: "white",
      margin: 5,
      fontSize: "small",
      "&:hover": {
        opacity: 0.5,
      },
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: 8,
    },

    colorReset: {
      border: `1px solid ${theme.palette.common.black}`,
      backgroundColor: theme.palette.common.black,
      margin: 5,
      fontSize: "small",
      "&:hover": {
        opacity: 0.5,
      },
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: 8,
    },
    colorDefault: {
      margin: 5,
      fontSize: "small",
      "&:hover": {
        opacity: 0.5,
      },
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: 8,
    },
    hoverStyle: {
      "&:hover": {
        opacity: 0.5,
      },
    },
    colorColumn: {
      display: "flex",
      justifyContent: "start",
      flexWrap: "wrap",
    },
    customTextField: {
      width: "380px",
      verticalAlign: "middle",
      "& .MuiOutlinedInput-input": {
        margin: "5px",
        padding: "5px",
        height: "1em",
      },
    },
    linkMenuStyle: {
      "& .MuiList-padding": {
        padding: 0,
      },
    },
    MateriallinkMenuStyle: {
      width: "80%",
    },

    saveButton: {
      width: "65px",
      verticalAlign: "middle",
      margin: "0 2.5px 0 2.5px",
      borderRadius: 10,
      backgroundColor: theme.palette.tertiary.mainDark,
      borderColor: theme.palette.tertiary.mainDark,
      color: "white",
      height: "2.5em",
      "&:hover": {
        opacity: 0.5,
      },
    },
    deleteButton: {
      width: "65px",
      verticalAlign: "middle",
      margin: "0 2.5px 0 2.5px",
      borderRadius: 10,
      height: "2.5em",
      "&:hover": {
        opacity: 0.5,
      },
    },
    ModalContainer: {
      position: "fixed",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 4000,
    },
    MenuItem: {
      "&:hover": {
        margin: "0 10px 0 10px",
        borderRadius: 10,
        border: `1px solid ${theme.palette.tertiary.mainDark}`,
        backgroundColor: `${theme.palette.tertiary.mainDark}1A !important`,
      },
    },
    MenuItemSelected: {
      margin: "0 10px 0 10px",
      borderRadius: 10,
      border: `1px solid ${theme.palette.tertiary.mainDark}`,
      backgroundColor: `${theme.palette.tertiary.mainDark}1A !important`,
    },
  }),
);

export const Colors: { [key: string]: string } = {
  pink: "rgb(255, 141, 226)",
  purple: "rgb(139, 75, 251)",
  lightblue: "rgb(80, 207, 255)",
  blue: "rgb(14, 38, 255)",
  green: "rgb(4, 141, 31)",
  yellow: "rgb(255, 197, 48)",
  orange: "rgb(255, 125, 65)",
  red: "rgb(255, 48, 48)",
  lightgreen: "rgb(81, 218, 108)",
  brown: "rgb(139, 104, 78)",
  grey: "rgb(115, 115, 115)",
  reset: "",
} as { [key: string]: string };

export const HightlightColors: { [key: string]: string } = {
  pink: "rgba(255, 141, 226, 0.4)",
  purple: "rgba(139, 75, 251, 0.4)",
  lightblue: "rgba(80, 207, 255, 0.4)",
  blue: "rgba(14, 38, 255, 0.4)",
  green: "rgba(4, 141, 31, 0.4)",
  yellow: "rgba(255, 197, 48, 0.4)",
  orange: "rgba(255, 125, 65, 0.4)",
  red: "rgba(255, 48, 48, 0.4)",
  lightgreen: "rgba(81, 218, 108, 0.4)",
  brown: "rgba(139, 104, 78, 0.4)",
  grey: "rgba(115, 115, 115, 0.4)",
  reset: "",
} as { [key: string]: string };
