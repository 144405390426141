/* eslint-disable camelcase */
import { Api, FetchPaymentsAsStudentParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { UpdatePaymentAsStudentArg } from "./types/payment";

const api = new Api();

export const fetchPaymentsAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/payments/fetch",
  async (params: FetchPaymentsAsStudentParams, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchPaymentsAsStudent(params, {});
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePaymentAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/payments/update",
  async (
    { paymentId, status }: UpdatePaymentAsStudentArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.student.updatePaymentAsStudent(paymentId, {
        status,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetStudentAppUpdatePaymentFormData = createAction(
  "studentApp/payment/resetUpdateFormRelatedData",
);
