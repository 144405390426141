/* eslint-disable react/no-array-index-key */
import { Skeleton } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import React from "react";

import { StyledTableCell, StyledTableRow } from "../Tables/TableComponents";

interface Props {
  colCount?: number;
}

const TableBodyLoading: React.FC<Props> = ({ colCount = 10 }: Props) => {
  const emptyArr = Array(10).fill("");
  const rowArr = Array(colCount).fill("");

  return (
    <TableBody>
      {emptyArr.map((_item, i) => (
        <StyledTableRow key={i}>
          {rowArr.map((_cell, j) => (
            <StyledTableCell key={j}>
              <Skeleton />
            </StyledTableCell>
          ))}
        </StyledTableRow>
      ))}
    </TableBody>
  );
};

export default TableBodyLoading;
TableBodyLoading.defaultProps = {
  colCount: 10,
};
