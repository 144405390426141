import {
  batchDelete,
  batchUpdate,
  createOneHomeworkAsTeacher,
  fetchAllHomeworkAsTeacher,
  fetchHomeworkAsTeacher,
  fetchOneHomeworkAsTeacher,
  fileDeleted as fileDeletedAction,
  fileUploaded,
  resetDeleteActionState,
  resetModalState,
  resetState,
  resetTeacherAppHomeworkFormData,
  submitAnswerFiles,
  updatedHomework,
  updateOneHomeworkAsTeacher,
} from "@actions/teacherApp/homework";
import { HomeworkBase, HomeworkDetails } from "@lib/Api";
import { removeMany, update,updateMany } from "@lib/collection";
import errorWithMessage, { errorWithMessages } from "@lib/rtk/error-utils";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface TeacherAppHomeworkState {
  fetching: boolean;
  putting: boolean;
  posting: boolean;
  posted: boolean;
  error: string;
  postError: string;
  homeworks: HomeworkBase[];
  homework: HomeworkDetails | null;
  totalCount: number;
  batchChangeErrors: string[];
  deleting: boolean;
  deleted: boolean;
  updating: boolean;
  updated: boolean;
  fetchingAll: boolean;
  errorAll: string;
  homeworkAll: HomeworkDetails | null;
  totalCountAll: number;
  homeworksAll: HomeworkBase[];
}

export const initialState: TeacherAppHomeworkState = {
  fetching: false,
  putting: false,
  posting: false,
  posted: false,
  error: "",
  postError: "",
  homeworks: [],
  homework: null,
  totalCount: 0,
  batchChangeErrors: [],
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  fetchingAll: false,
  errorAll: "",
  homeworkAll: null,
  totalCountAll: 0,
  homeworksAll: [],
};

const teacherAppHomeworkSlice = createSlice({
  name: "teacherApp/Homework",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeworkAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: "",
          homework: null,
        };
      })
      .addCase(fetchHomeworkAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: errorWithMessage(action.payload),
          homeworks: [],
        };
      })
      .addCase(fetchHomeworkAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          error: "",
          homeworks: payload.homeworks,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchAllHomeworkAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingAll: true,
          errorAll: "",
          homeworkAll: null,
        };
      })
      .addCase(fetchAllHomeworkAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingAll: false,
          errorAll: errorWithMessage(action.payload),
          homeworksAll: [],
        };
      })
      .addCase(fetchAllHomeworkAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingAll: false,
          errorAll: "",
          homeworksAll: payload.homeworks,
          totalCountAll: payload.total_count,
        };
      })
      .addCase(fetchOneHomeworkAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: "",
        };
      })
      .addCase(fetchOneHomeworkAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: errorWithMessage(action.payload),
          homework: null,
        };
      })
      .addCase(fetchOneHomeworkAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          error: "",
          homework: payload,
        };
      })
      .addCase(createOneHomeworkAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          posting: true,
          posted: false,
          postError: "",
        };
      })
      .addCase(createOneHomeworkAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          postError: errorWithMessage(action.payload),
          posted: false,
        };
      })
      .addCase(createOneHomeworkAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          posting: false,
          homework: payload,
          postError: "",
          posted: true,
        };
      })
      .addCase(updateOneHomeworkAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          putting: true,
          updated: false,
        };
      })
      .addCase(updateOneHomeworkAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          putting: false,
          updated: false,
          error: action.payload,
        };
      })
      .addCase(updateOneHomeworkAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          putting: false,
          updated: true,
          error: "",
          homework: payload,
        };
      })
      .addCase(batchUpdate.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          batchChangeErrors: [],
        };
      })
      .addCase(batchUpdate.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(batchUpdate.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updating: false,
          updated: true,
          batchChangeErrors: errorWithMessages(payload),
          error: "",
          distributions: updateMany(state.homeworks, payload.homeworks),
        };
      })
      .addCase(submitAnswerFiles.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          batchChangeErrors: [],
        };
      })
      .addCase(submitAnswerFiles.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(submitAnswerFiles.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updating: false,
          updated: true,
          batchChangeErrors: errorWithMessages(payload),
          error: "",
          distributions: updateMany(state.homeworks, payload.homeworks),
        };
      })
      .addCase(batchDelete.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          batchChangeErrors: [],
        };
      })
      .addCase(batchDelete.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(batchDelete.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          deleting: false,
          deleted: true,
          batchChangeErrors: errorWithMessages(payload),
          error: "",
          homeworks: removeMany(state.homeworks, payload.deleted_ids),
          totalCount: state.totalCount - payload.deleted_ids.length,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          batchChangeErrors: [],
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          error: "",
        };
      })
      .addCase(resetDeleteActionState, (state, _action) => {
        return {
          ...state,
          batchChangeErrors: [],
          deleted: false,
          error: "",
        };
      })
      .addCase(updatedHomework, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updated: true,
          updating: false,
          uploaded: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addCase(fileUploaded, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          uploaded: true,
          updating: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addCase(fileDeletedAction, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          fileDeleted: true,
          updating: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          fetching: false,
          error: "",
          homeworks: [],
          fetchingAll: false,
          errorAll: "",
          homeworkAll: null,
          homeworksAll: [],
          homework: null,
          totalCount: 0,
          batchChangeErrors: [],
          deleting: false,
          deleted: false,
          updating: false,
          updated: false,
        };
      })
      .addCase(resetTeacherAppHomeworkFormData, (state, _action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postError: "",
          updating: false,
          updated: false,
          error: "",
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppHomeworkState = (
  state: RootState,
): TeacherAppHomeworkState => state.teacherApp.homework;

export default teacherAppHomeworkSlice.reducer;
