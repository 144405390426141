/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RoleApiFunctions } from "@root/types/category";
import { RootState } from "@store/store";

const api = new Api();

// eslint-disable-next-line import/prefer-default-export
export const fetchCategoriesAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/category/fetch", async (_: void, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getTeacherCategories();
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type TeacherActionsType = {
  teacher: RoleApiFunctions;
};

export const teacherCategoryActions: TeacherActionsType = {
  teacher: {
    getCategories: fetchCategoriesAsTeacher,
  },
};
