/* eslint-disable camelcase */
import {
  Api,
  FetchHomeworkTemplateDistributionsAsSuperOwnerParams,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchHomeworkTemplateDistributionsAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateDistribution/fetch",
  async (
    params: FetchHomeworkTemplateDistributionsAsSuperOwnerParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.fetchHomeworkTemplateDistributionsAsSuperOwner(
          params,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface FetchHomeworkTemplateDistributionAsSuperOwnerArg {
  templateId: string;
  distributionId: string;
}

export const fetchHomeworkTemplateDistributionAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateDistribution/fetchOne",
  async (
    {
      templateId,
      distributionId,
    }: FetchHomeworkTemplateDistributionAsSuperOwnerArg,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.fetchHomeworkTemplateDistributionAsSuperOwner(
          templateId,
          distributionId,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface CreateHomeworkTemplateDistributionAsSuperOwnerParams {
  company_id: string;
  max_distribution_end_users: number | null;
  memo: string | null;
}

export interface BatchCreateHomeworkTemplateDistributionAsSuperOwnerArg {
  templateId: string;
  start_at?: string;
  end_at?: string;
  reviewer_end_at?: string;
  create_params_list: CreateHomeworkTemplateDistributionAsSuperOwnerParams[];
}

export const batchCreate = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateDistribution/batchCreate",
  async (
    params: BatchCreateHomeworkTemplateDistributionAsSuperOwnerArg,
    { rejectWithValue },
  ) => {
    const {
      templateId,
      start_at,
      end_at,
      create_params_list,
      reviewer_end_at,
    } = params;
    try {
      const response =
        await api.superOwner.batchCreateHomeworkTemplateDistributionsAsSuperOwner(
          templateId,
          { start_at, end_at, reviewer_end_at, create_params_list },
        );
      // Refetch Distributions
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface BatchUpdateHomeworkTemplateDistributionAsSuperOwnerParams {
  distribution_ids: string[];
  max_distribution_end_users: number;
  start_at?: string;
  end_at?: string;
  reviewer_end_at?: string;
}
export interface BatchUpdateHomeworkTemplateDistributionAsSuperOwnerArg {
  templateId: string;
  params: BatchUpdateHomeworkTemplateDistributionAsSuperOwnerParams;
}

export const batchUpdate = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateDistribution/batchUpdate",
  async (
    {
      templateId,
      params,
    }: BatchUpdateHomeworkTemplateDistributionAsSuperOwnerArg,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.batchUpdateHomeworkTemplateDistributionsAsSuperOwner(
          templateId,
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface BatchDeleteHomeworkTemplateDistributionAsSuperOwnerParams {
  distribution_ids: string[];
}
export interface BatchDeleteHomeworkTemplateDistributionAsSuperOwnerArg {
  templateId: string;
  params: BatchDeleteHomeworkTemplateDistributionAsSuperOwnerParams;
}

export const batchDelete = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateDistribution/batchDelete",
  async (
    {
      templateId,
      params,
    }: BatchDeleteHomeworkTemplateDistributionAsSuperOwnerArg,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.batchDeleteHomeworkTemplateDistributionsAsSuperOwner(
          templateId,
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const resetModalState = createAction(
  "superOwner/homeworkTemplateDistribution/resetModalState",
);

export const resetDeleteActionState = createAction(
  "superOwner/homeworkTemplateDistribution/resetDeleteActionState",
);

export const resetState = createAction(
  "superOwner/homeworkTemplateDistribution/resetState",
);
