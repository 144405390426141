/* eslint-disable camelcase */
import { UserListAsSuperOwner } from "@components/SuperOwner/SchoolDetails/Users/types";
import {
  AccountUserInfo,
  BasicUserInfo,
  OwnerListAsSuperOwner,
  ReviewerDetail,
  StudentInputSetting,
  StudentListAsSuperOwner,
  TeacherListAsSuperOwner,
} from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import {
  createAccountUserAsSuperOwner,
  createReviewerAsSuperOwner,
  createUserAsSuperOwner,
  deleteReviewerAsSuperOwner,
  deleteUserAsSuperOwner,
  fetchOwnersAsSuperOwner,
  fetchReviewersAsSuperOwner,
  fetchStudentsAsSuperOwner,
  fetchSuperOwnersAsSuperOwner,
  fetchTeachersAsSuperOwner,
  resetBatchCreateState,
  resetBatchDeleteState,
  resetModalState,
  resetPasswordAsSuperOwner,
  resetReviewersState,
  resetState,
  updateReviewerAsSuperOwner,
  updateUserAsSuperOwner,
} from "../../actions/superOwnerApp/user";
import { RootState } from "../../store";

export type SuperOwnerAffiliateInfo = {
  affiliate_id?: string;
  user?: BasicUserInfo;
  company?: {
    id: string;
    name: string;
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    email?: string;
    logo?: { url?: string; thumb?: { url?: string }; icon?: { url?: string } };
  };
  role?: { id: string; name: string; jp_name: string };
};

export interface SuperOwnerUsersState {
  fetching: boolean;
  fetched: boolean;
  fetchingAll: boolean;
  fetchedAll: boolean;
  posting: boolean;
  posted: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  owners: OwnerListAsSuperOwner[];
  teachers: TeacherListAsSuperOwner[];
  students: StudentListAsSuperOwner[];
  reviewers: ReviewerDetail[];
  superOwners: SuperOwnerAffiliateInfo[];
  allUsers: UserListAsSuperOwner[];
  studentInputSettings: StudentInputSetting | null;
  totalOwnerCount: number;
  totalTeacherCount: number;
  totalStudentCount: number;
  totalReviewerCount: number;
  totalSuperOwnerCount: number;
  error: any;
  postError: any;
  updateError: any;
  deleteError: any;
  batchCount: number;
  batchDeleteCount: number;
  batchErrors: string[];
  batchPostErrors: { index: number; message: string }[];
  passwordUpdate: ReduxStatusType;
  passwordUpdateError: string | null;
  resetPasswordUser: AccountUserInfo | null;
}

export const initialState: SuperOwnerUsersState = {
  fetching: false,
  fetched: false,
  fetchingAll: false,
  fetchedAll: false,
  posting: false,
  posted: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  owners: [],
  teachers: [],
  students: [],
  reviewers: [],
  superOwners: [],
  allUsers: [],
  studentInputSettings: null,
  totalOwnerCount: 0,
  totalTeacherCount: 0,
  totalStudentCount: 0,
  totalReviewerCount: 0,
  totalSuperOwnerCount: 0,
  error: null,
  postError: null,
  updateError: null,
  deleteError: null,
  batchCount: 0,
  batchDeleteCount: 0,
  batchErrors: [],
  batchPostErrors: [],
  passwordUpdate: ReduxStatus.idle,
  passwordUpdateError: null,
  resetPasswordUser: null,
};

const fetchUserPendingState = (state: SuperOwnerUsersState, action: any) => {
  const { fetchAll } = action.meta.arg;
  if (fetchAll) {
    return {
      ...state,
      fetchingAll: true,
      fetchedAll: false,
    };
  }
  return {
    ...state,
    fetching: true,
    fetched: false,
    error: null,
  };
};

const fetchUserRejectedState = (state: SuperOwnerUsersState, action: any) => {
  return {
    ...state,
    fetching: false,
    fetched: false,
    error: getErrorMessage(action.error) as any,
  };
};

const createUserPendingState = (state: SuperOwnerUsersState) => {
  return {
    ...state,
    posting: true,
    posted: false,
    postError: null,
  };
};

const createUserRejectedState = (state: SuperOwnerUsersState, action: any) => {
  const errorMessages = action.payload?.error.errors?.map(
    (error: { index: number; message: string }) => error.message,
  );
  const errorString = errorMessages?.join("。");
  return {
    ...state,
    posted: false,
    posting: false,
    postError: action.payload?.error.errors,
    batchCount: state.batchCount + 1,
    batchPostErrors: action.payload?.requestIndex
      ? [
          ...state.batchPostErrors,
          {
            index: action.payload?.requestIndex ?? 0 - 1,
            message: `${errorString}`,
          },
        ]
      : [],
  };
};

const updateUserPendingState = (state: SuperOwnerUsersState) => {
  return {
    ...state,
    updating: true,
    updated: false,
    updateError: null,
  };
};

const deleteUserPendingState = (state: SuperOwnerUsersState) => {
  return {
    ...state,
    deleting: true,
    deleted: false,
    deleteError: null,
  };
};

export const superOwnerAppUsersSlice = createSlice({
  name: "SuperOwnerApp/Users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOwnersAsSuperOwner.pending, (state, action) => {
        return fetchUserPendingState(state, action);
      })
      .addCase(fetchOwnersAsSuperOwner.fulfilled, (state, action) => {
        const { fetchAll } = action.meta.arg;
        if (fetchAll) {
          return {
            ...state,
            fetchingAll: false,
            fetchedAll: true,
            allUsers: action.payload.owners,
            studentInputSettings: null,
          };
        }
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          owners: action.payload.owners,
          totalOwnerCount: action.payload.total_count,
          studentInputSettings: null,
        };
      })
      .addCase(fetchOwnersAsSuperOwner.rejected, (state, action) => {
        return fetchUserRejectedState(state, action);
      })
      .addCase(fetchSuperOwnersAsSuperOwner.pending, (state, action) => {
        return fetchUserPendingState(state, action);
      })
      .addCase(fetchSuperOwnersAsSuperOwner.fulfilled, (state, action) => {
        const { fetchAll } = action.meta.arg;
        if (fetchAll) {
          return {
            ...state,
            fetchingAll: false,
            fetchedAll: true,
            superOwners: action.payload.super_owners,
            studentInputSettings: null,
          };
        }
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          superOwners: action.payload.super_owners,
          totalSuperOwnerCount: action.payload.total_count,
          studentInputSettings: null,
        };
      })
      .addCase(fetchSuperOwnersAsSuperOwner.rejected, (state, action) => {
        return fetchUserRejectedState(state, action);
      })
      .addCase(fetchTeachersAsSuperOwner.pending, (state, action) => {
        return fetchUserPendingState(state, action);
      })
      .addCase(fetchTeachersAsSuperOwner.fulfilled, (state, action) => {
        const { fetchAll } = action.meta.arg;
        if (fetchAll) {
          return {
            ...state,
            fetchingAll: false,
            fetchedAll: true,
            allUsers: action.payload.teachers,
            studentInputSettings: null,
          };
        }
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          teachers: action.payload.teachers,
          totalTeacherCount: action.payload.total_count,
          studentInputSettings: null,
        };
      })
      .addCase(fetchTeachersAsSuperOwner.rejected, (state, action) => {
        return fetchUserRejectedState(state, action);
      })
      .addCase(fetchStudentsAsSuperOwner.pending, (state, action) => {
        return fetchUserPendingState(state, action);
      })
      .addCase(fetchStudentsAsSuperOwner.fulfilled, (state, action) => {
        const { fetchAll } = action.meta.arg;
        if (fetchAll) {
          return {
            ...state,
            fetchingAll: false,
            fetchedAll: true,
            allUsers: action.payload.students,
            studentInputSettings: action.payload.student_input_settings,
          };
        }
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          students: action.payload.students,
          totalStudentCount: action.payload.total_count,
          studentInputSettings: action.payload.student_input_settings,
        };
      })
      .addCase(fetchStudentsAsSuperOwner.rejected, (state, action) => {
        return fetchUserRejectedState(state, action);
      })
      .addCase(fetchReviewersAsSuperOwner.pending, (state, action) => {
        return fetchUserPendingState(state, action);
      })
      .addCase(fetchReviewersAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          reviewers: payload.reviewers,
          totalReviewerCount: payload.total_count,
          studentInputSettings: null,
        };
      })
      .addCase(fetchReviewersAsSuperOwner.rejected, (state, action) => {
        return fetchUserRejectedState(state, action);
      })
      .addCase(createUserAsSuperOwner.pending, (state, _action) => {
        return createUserPendingState(state);
      })
      .addCase(createUserAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          postError: null,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(createAccountUserAsSuperOwner.rejected, (state, action) => {
        return createUserRejectedState(state, action);
      })
      .addCase(createAccountUserAsSuperOwner.pending, (state, _action) => {
        return createUserPendingState(state);
      })
      .addCase(createAccountUserAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          postError: null,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(resetPasswordAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          passwordUpdate: ReduxStatus.pending,
        };
      })
      .addCase(resetPasswordAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          resetPasswordUser: payload,
          passwordUpdate: ReduxStatus.fulfilled,
          passwordUpdateError: null,
        };
      })
      .addCase(resetPasswordAsSuperOwner.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          resetPasswordUser: null,
          passwordUpdate: ReduxStatus.rejected,
          passwordUpdateError: payload.error.errors,
        };
      })
      .addCase(createUserAsSuperOwner.rejected, (state, action) => {
        return createUserRejectedState(state, action);
      })
      .addCase(createReviewerAsSuperOwner.pending, (state, _action) => {
        return createUserPendingState(state);
      })
      .addCase(createReviewerAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          postError: null,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(createReviewerAsSuperOwner.rejected, (state, action) => {
        return createUserRejectedState(state, action);
      })
      .addCase(updateUserAsSuperOwner.pending, (state, _action) => {
        return updateUserPendingState(state);
      })
      .addCase(updateUserAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
          updateError: null,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(updateUserAsSuperOwner.rejected, (state, action) => {
        const errorMessages = action.payload?.error.errors?.map(
          (error: { index: number; message: string }) => error.message,
        );
        errorMessages.push(action.payload?.error.error);
        const errorString = errorMessages?.join("。");
        return {
          ...state,
          updated: false,
          updating: false,
          updateError:
            action.payload?.error.errors ??
            action.payload?.error.error ??
            "error happened.",
          batchCount: state.batchCount + 1,
          batchPostErrors: action.payload?.requestIndex
            ? [
                ...state.batchPostErrors,
                {
                  index: action.payload?.requestIndex ?? 0 - 1,
                  message: `${errorString}`,
                },
              ]
            : [],
        };
      })
      .addCase(updateReviewerAsSuperOwner.pending, (state, _action) => {
        return updateUserPendingState(state);
      })
      .addCase(updateReviewerAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
          updateError: null,
        };
      })
      .addCase(updateReviewerAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          updateError: action.payload as any,
        };
      })
      .addCase(deleteUserAsSuperOwner.pending, (state, _action) => {
        return deleteUserPendingState(state);
      })
      .addCase(deleteUserAsSuperOwner.fulfilled, (state, action) => {
        if (action.meta.arg.batch) {
          return {
            ...state,
            deleting: false,
            deleted: true,
            batchDeleteCount: state.batchDeleteCount + 1,
          };
        }
        return {
          ...state,
          deleting: false,
          deleted: true,
          deleteError: null,
        };
      })
      .addCase(deleteUserAsSuperOwner.rejected, (state, action) => {
        if (action.meta.arg.batch) {
          return {
            ...state,
            batchDeleteCount: state.batchDeleteCount + 1,
            batchErrors: [...state.batchErrors, String(action.meta.arg.id)],
          };
        }
        return {
          ...state,
          deleting: false,
          deleted: false,
          deleteError: action.payload.errors,
        };
      })
      .addCase(deleteReviewerAsSuperOwner.pending, (state, _action) => {
        return deleteUserPendingState(state);
      })
      .addCase(deleteReviewerAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: true,
          deleteError: null,
        };
      })
      .addCase(deleteReviewerAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          deleteError: action.payload as any,
        };
      })
      .addCase(resetReviewersState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: null,
          posting: false,
          posted: false,
          postError: null,
          updating: false,
          updated: false,
          updateError: null,
          deleting: false,
          deleted: false,
          deleteError: null,
          reviewers: [],
          totalReviewerCount: 0,
        };
      })
      .addCase(resetModalState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: null,
          posting: false,
          posted: false,
          postError: null,
          updating: false,
          updated: false,
          updateError: null,
          deleting: false,
          deleted: false,
          deleteError: null,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          error: null,
          posting: false,
          posted: false,
          postError: null,
          updating: false,
          updated: false,
          updateError: null,
          deleting: false,
          deleted: false,
          deleteError: null,
          passwordUpdate: ReduxStatus.idle,
          passwordUpdateError: null,
        };
      })
      .addCase(resetBatchDeleteState, (state) => {
        return {
          ...state,
          batchDeleteCount: 0,
          batchErrors: [],
          fetchedAll: false,
          error: null,
          allOwners: [],
        };
      })
      .addCase(resetBatchCreateState, (state) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postError: null,
          updating: false,
          updated: false,
          updateError: null,
          batchCount: 0,
          batchPostErrors: [],
        };
      });
  },
});

export const superOwnerAppUsersState = (
  state: RootState,
): SuperOwnerUsersState => state.superOwnerApp.users;

export default superOwnerAppUsersSlice.reducer;
