/* eslint-disable react/require-default-props */
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import {
  byteLengthOf,
  getParsedJSON,
  safeString,
  TextColumnMaxByte,
} from "@lib/string-utils";
import { reorder } from "@root/utils/reorder/reorder";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import {
  Controller,
  Path,
  PathValue,
  UnpackNestedValue,
  useFormContext,
} from "react-hook-form";

import EditorError from "./Components/EditorError";
import SVEditorWrapper from "./Components/SVEditorWrapper";
import { SVEditorContext } from "./SVEditorContext";
import {
  EditorBlocksValue,
  EditorOneBlockJSON,
  EditorTwoBlockJSON,
  OptionalExtensions,
  SVEditorFormValue,
} from "./types";
import useRestoreImg from "./useDOMParse";
import useSVEditor from "./useSVEditor";
import useSVEditorStyles, { COMPUTER_WIDTH } from "./useSVEditorStyles";
import { editorBlocksToHTML, HTMLToEditorBlocksJSONArray } from "./utils";

type Props<T extends SVEditorFormValue> = {
  // SVEditorの値を格納するためのカラム名。
  // 1ページ内に複数SVEditorを配置する箇所があり、動作するためにはデータの保持先を変数により分ける必要がある
  FormValueName: Path<T>;
  optionalExtensions?: OptionalExtensions;
};

// useFormContextを利用しているため、
// このコンポーネントの利用側でuseFormとFormProviderが使用されることを前提としている
const SVEditor = <T extends SVEditorFormValue>({
  FormValueName,
  optionalExtensions = {
    toggleButton: true,
  },
}: Props<T>): JSX.Element => {
  const { setValue, getValues, watch, control, trigger } = useFormContext<T>();
  const {
    restoreSVEditorData,
    setSomeValues,
    getDefaultInitialEditorBlocksJSON,
    EditorBlocksKey,
  } = useSVEditor<T>(FormValueName);
  const { SVEditorClasses: classes } = useSVEditorStyles();
  const { refetchResizeImageSrc, setMaterialLinkHref } = useRestoreImg();

  const description = safeString(getValues(FormValueName));

  // descriptionが一度もSVEditorを利用していない場合のデータであるを考慮
  // SVEditorのオブジェクトデータを含まない形で初期値を用意する
  // descriptionがSVEditor用のデータであった場合、この値は必要ない。
  // しかしフックのルールを満たすためにこの処理が必要
  // (フックをループや条件分岐、あるいはネストされた関数内で呼び出してはいけません。https://ja.reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level)
  const defaultInitialEditorBlocksJSON =
    getDefaultInitialEditorBlocksJSON(description);

  // descriptionの値からSVEditorを復元する。
  // descriptionの値が空白や通常のHTML(SVEditor使用前)の場合も考慮している
  const parsedDescription: (EditorOneBlockJSON | EditorTwoBlockJSON)[] =
    getParsedJSON(description) !== false
      ? getParsedJSON(description)
      : getParsedJSON(defaultInitialEditorBlocksJSON);

  const editorBlocks = watch(EditorBlocksKey);

  // imgタグのsrc要素に記載されている画像を再取得する
  // (awsに置かれている画像はpresignedされており有効期限があるので、都度再取得して有効期限を回避している)
  const refetch = async () => {
    // 処理しやすいようにhtmlに戻す
    let html = editorBlocksToHTML(parsedDescription, classes);
    html = setMaterialLinkHref(html);
    // htmlに戻した後,画像を再取得してsrcの中身を再取得後のデータに置き換える
    const result = await refetchResizeImageSrc(html, COMPUTER_WIDTH);

    // htmlをSVEditorで利用できるように前処理する
    // ここで変換された値はtiptapのオブジェクトを含まないので、restoreSVEditorDataでtiptapのオブジェクトデータを含んだ形に変換する必要がある
    const editorBlocksJSONArray = HTMLToEditorBlocksJSONArray(result);

    // 再取得後のデータを再度セットする
    setValue(
      EditorBlocksKey,
      restoreSVEditorData(editorBlocksJSONArray) as UnpackNestedValue<
        PathValue<T, Path<T>>
      >,
    );
  };

  useEffect(() => {
    refetch();
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult, _: ResponderProvided) => {
      if (!result.destination) return;
      if (result.destination.index === result.source.index) return;

      const newEditorBlocks = reorder(
        editorBlocks,
        result.source.index,
        result.destination.index,
      ) as EditorBlocksValue;
      setSomeValues(newEditorBlocks);
    },
    [editorBlocks],
  );

  const EditorContent = useMemo(
    () => (
      <DragDropContext onDragEnd={onDragEnd}>
        {editorBlocks?.map((item: EditorBlocksValue[number], index: number) => {
          let element = <></>;
          if (Array.isArray(item.content)) {
            element = (
              <div className={classes.DivWrapper}>
                {item.content.map((data) => {
                  return React.cloneElement(data.content, { key: data.id });
                })}
              </div>
            );
          } else {
            element = item.content;
          }

          return React.cloneElement(
            <Droppable droppableId={`droppable-${item.id}`}>
              {(droppableProvided: DroppableProvided) => (
                <div
                  className={classes.draggableItemWrapper}
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  <Draggable draggableId={item.id} index={index}>
                    {(draggableProvided: DraggableProvided) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        {element}
                      </div>
                    )}
                  </Draggable>
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>,
            { key: item.id },
          );
        })}
      </DragDropContext>
    ),
    [editorBlocks],
  );

  // 利用率が100%を超えないように入力のたびに
  // triggerを起動してチェックしている
  useEffect(() => {
    trigger(FormValueName);
  }, [watch(FormValueName)]);

  return (
    <SVEditorContext.Provider value={{ optionalExtensions }}>
      {editorBlocks?.length ? (
        <Controller
          control={control}
          name={FormValueName}
          rules={{
            validate: {
              validateMaxByte: (value) => {
                if (value !== null && value !== undefined) {
                  return (
                    byteLengthOf(value) <= TextColumnMaxByte ||
                    "利用率が100%を超えています"
                  );
                }
                return true;
              },
            },
          }}
          render={() => (
            <SVEditorWrapper<T> FormValueName={FormValueName}>
              {EditorContent}
            </SVEditorWrapper>
          )}
        />
      ) : (
        <CircularLoading />
      )}

      <EditorError<T> FormValueName={FormValueName} />
    </SVEditorContext.Provider>
  );
};

export default memo(SVEditor) as typeof SVEditor;
