import {
  deleteSteamLibraryCommentAsStudent,
  getSteamLibraryCommentAsStudent,
  postSteamLibraryCommentAsStudent,
  resetSteamLibraryCommentState,
  updateSteamLibraryCommentAsStudent,
} from "@actions/studentApp/steam/steamComment";
import { PblSteamCommentBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

interface StudentAppSteamCommentState {
  fetching: boolean;
  fetched: boolean;
  fetchError: string | null;
  comment: PblSteamCommentBase | null;
  changing: boolean;
  changed: boolean;
  changeError: any;
  changeMessage: string;
}

const initialState: StudentAppSteamCommentState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  comment: null,
  changing: false,
  changed: false,
  changeError: null,
  changeMessage: "",
};

const studentAppSteamCommentSlice = createSlice({
  name: "StudentApp/SteamComment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSteamLibraryCommentAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: null,
        };
      })
      .addCase(getSteamLibraryCommentAsStudent.fulfilled, (state, action) => {
        const ownComment = action.payload.steam_content_comments.find(
          (comment: PblSteamCommentBase) =>
            comment.affiliate.user?.id === action.payload.userId,
        );
        return {
          ...state,
          fetching: false,
          fetched: true,
          comment: ownComment,
        };
      })
      .addCase(getSteamLibraryCommentAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(postSteamLibraryCommentAsStudent.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(postSteamLibraryCommentAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          comment: action.payload,
          changeMessage: "コメントを追加しました。",
        };
      })
      .addCase(postSteamLibraryCommentAsStudent.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changeError: action.payload.errors,
        };
      })
      .addCase(updateSteamLibraryCommentAsStudent.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(
        updateSteamLibraryCommentAsStudent.fulfilled,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            comment: action.payload,
            changeMessage: "コメントを編集しました。",
          };
        },
      )
      .addCase(updateSteamLibraryCommentAsStudent.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changeError: action.payload.errors,
        };
      })
      .addCase(deleteSteamLibraryCommentAsStudent.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(
        deleteSteamLibraryCommentAsStudent.fulfilled,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            comment: null,
            changeMessage: "コメントを削除しました。",
          };
        },
      )
      .addCase(deleteSteamLibraryCommentAsStudent.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changeError: action.payload.errors,
        };
      })
      .addCase(resetSteamLibraryCommentState, (state, _action) => {
        return {
          ...state,
          comment: null,
          changeMessage: "",
          changed: false,
          changeError: null,
        };
      });
  },
});

export const studentAppSteamCommentState = (
  state: RootState,
): StudentAppSteamCommentState => state.studentApp.steamComment;

export default studentAppSteamCommentSlice.reducer;
