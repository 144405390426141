import {
  deletePblBoardCampaignAsContentProvider,
  getPblBoardCampaignAsContentProvider,
  getPblBoardCampaignsAsContentProvider,
  postPblBoardCampaignAsContentProvider,
  putPblBoardCampaignAsContentProvider,
  resetContentProviderAppPblBoardCampaignModalState,
  resetContentProviderAppPblBoardCampaignState,
} from "@actions/contentProviderApp/pblBoard/campaign";
import { PblBoardCampaignBase,PblBoardCampaignList } from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface ContentProviderAppPblBoardCampaignState {
  fetchPblBoardCampaigns: ReduxStatusType;
  fetchPblBoardCampaign: ReduxStatusType;
  postPblBoardCampaign: ReduxStatusType;
  putPblBoardCampaign: ReduxStatusType;
  deletePblBoardCampaign: ReduxStatusType;
  postPblBoardCampaignError: string | null;
  putPblBoardCampaignError: string | null;
  deletePblBoardCampaignError: string | null;
  pblBoardCampaigns: PblBoardCampaignList[];
  pblBoardCampaign: PblBoardCampaignBase | null;
  totalCount: number;
}

export const initialState: ContentProviderAppPblBoardCampaignState = {
  fetchPblBoardCampaigns: ReduxStatus.idle,
  fetchPblBoardCampaign: ReduxStatus.idle,
  postPblBoardCampaign: ReduxStatus.idle,
  putPblBoardCampaign: ReduxStatus.idle,
  deletePblBoardCampaign: ReduxStatus.idle,
  postPblBoardCampaignError: null,
  putPblBoardCampaignError: null,
  deletePblBoardCampaignError: null,
  pblBoardCampaigns: [],
  pblBoardCampaign: null,
  totalCount: 0,
};

const contentProviderAppPblBoardCampaignSlice = createSlice({
  name: "contentProviderApp/pblBoard/campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getPblBoardCampaignsAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchPblBoardCampaigns: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getPblBoardCampaignsAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchPblBoardCampaigns: ReduxStatus.rejected,
            pblBoardCampaigns: [],
          };
        },
      )
      .addCase(
        getPblBoardCampaignsAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchPblBoardCampaigns: ReduxStatus.fulfilled,
            pblBoardCampaigns: payload.board_campaigns,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        getPblBoardCampaignAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchPblBoardCampaign: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getPblBoardCampaignAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchPblBoardCampaign: ReduxStatus.rejected,
            pblBoardCampaign: null,
          };
        },
      )
      .addCase(
        getPblBoardCampaignAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchPblBoardCampaign: ReduxStatus.fulfilled,
            pblBoardCampaign: payload,
          };
        },
      )
      .addCase(
        postPblBoardCampaignAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            postPblBoardCampaign: ReduxStatus.pending,
            postPblBoardCampaignError: null,
          };
        },
      )
      .addCase(
        postPblBoardCampaignAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            postPblBoardCampaign: ReduxStatus.rejected,
            pblBoardCampaign: null,
            postPblBoardCampaignError: errorWithMessage(action.payload),
          };
        },
      )
      .addCase(
        postPblBoardCampaignAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            postPblBoardCampaign: ReduxStatus.fulfilled,
            pblBoardCampaign: payload,
            postPblBoardCampaignError: null,
          };
        },
      )
      .addCase(
        putPblBoardCampaignAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            putPblBoardCampaign: ReduxStatus.pending,
            putPblBoardCampaignError: null,
          };
        },
      )
      .addCase(
        putPblBoardCampaignAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            putPblBoardCampaign: ReduxStatus.rejected,
            putPblBoardCampaignError: "更新に失敗しました。",
          };
        },
      )
      .addCase(
        putPblBoardCampaignAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            putPblBoardCampaign: ReduxStatus.fulfilled,
            pblBoardCampaign: payload,
            putPblBoardCampaignError: null,
          };
        },
      )
      .addCase(
        deletePblBoardCampaignAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            deletePblBoardCampaign: ReduxStatus.pending,
            deletePblBoardCampaignError: null,
          };
        },
      )
      .addCase(
        deletePblBoardCampaignAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            deletePblBoardCampaign: ReduxStatus.rejected,
            deletePblBoardCampaignError: errorWithMessage(action.payload),
          };
        },
      )
      .addCase(
        deletePblBoardCampaignAsContentProvider.fulfilled,
        (state, action) => {
          return {
            ...state,
            deletePblBoardCampaign: ReduxStatus.fulfilled,
            deletePblBoardCampaignError: null,
          };
        },
      )
      .addCase(
        resetContentProviderAppPblBoardCampaignState,
        (state, _action) => {
          return {
            ...state,
            fetchPblBoardCampaigns: ReduxStatus.idle,
            fetchMorePblBoardCampaigns: ReduxStatus.idle,
            pblBoardCampaigns: [],
            pblBoardCampaign: null,
          };
        },
      )
      .addCase(
        resetContentProviderAppPblBoardCampaignModalState,
        (state, _action) => {
          return {
            ...state,
            postPblBoardCampaign: ReduxStatus.idle,
            postPblBoardCampaignError: null,
            putPblBoardCampaign: ReduxStatus.idle,
            putPblBoardCampaignError: null,
            deletePblBoardCampaign: ReduxStatus.idle,
            deletePblBoardCampaignError: null,
          };
        },
      )
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const contentProviderAppPblBoardCampaignBoardState = (
  state: RootState,
): ContentProviderAppPblBoardCampaignState =>
  state.contentProviderApp.pblBoardCampaign;

export default contentProviderAppPblBoardCampaignSlice.reducer;
