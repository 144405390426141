import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, Menu, MenuItem } from "@mui/material";
import theme from "@root/styles/theme";
import React, { memo, useContext, useState } from "react";
import {
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
  AiOutlineAlignRight,
} from "react-icons/ai";

import { BubbleMenuContext } from "../BubbleMenuContext";

type Alignment = "left" | "center" | "right";
type TextAlignItem = {
  icon: JSX.Element;
  alignment: Alignment;
};

const TextAlignGroup: React.FC = () => {
  const { editor, subMenuOpen, setSubMenuOpen, isOtherSubMenuOpen } =
    useContext(BubbleMenuContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setSubMenuOpen({
      ...subMenuOpen,
      colorGroup: false,
    });
    setAnchorEl(null);
  };

  const handleTextAlignButtonClick = (alignment: Alignment) => {
    if (editor) {
      editor.chain().focus().setTextAlign(alignment).run();
      handleClose();
    }
  };

  const textAlignItems: TextAlignItem[] = [
    { icon: <AiOutlineAlignLeft />, alignment: "left" },
    { icon: <AiOutlineAlignCenter />, alignment: "center" },
    { icon: <AiOutlineAlignRight />, alignment: "right" },
  ];

  const getCurrentIcon = (): JSX.Element => {
    if (editor.isActive({ textAlign: "right" })) {
      return <AiOutlineAlignRight />;
    }
    if (editor.isActive({ textAlign: "center" })) {
      return <AiOutlineAlignCenter />;
    }
    return <AiOutlineAlignLeft />;
  };

  return (
    <>
      <IconButton
        size="small"
        style={{ color: theme.palette.customColor.text }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={isOtherSubMenuOpen("textAlignGroup")}
      >
        {getCurrentIcon()}
        <KeyboardArrowDownIcon fontSize="small" />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {textAlignItems.map((item) => (
          <MenuItem
            key={item.alignment}
            onClick={() => handleTextAlignButtonClick(item.alignment)}
          >
            {item.icon}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default memo(TextAlignGroup);
