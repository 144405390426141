import { mergeAttributes } from "@tiptap/core";
import Link from "@tiptap/extension-link";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    materialLink: {
      setMaterialLink: (attributes: {
        href?: string | undefined;
        target?: string;
        "data-material-id": string;
      }) => ReturnType;
      unsetMaterialLink: () => ReturnType;
    };
  }
}

export const MaterialLink = Link.extend({
  name: "link",

  addOptions() {
    return {
      openOnClick: true,
      linkOnPaste: true,
      autolink: true,
      protocols: [],
      HTMLAttributes: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
        class: null,
        href: null,
        "data-material-id": null,
      },
      validate: (url: string) => true,
      defaultProtocol: "",
    };
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      "data-material-id": {
        default: null,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "a",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setMaterialLink:
        (attributes) =>
        ({ chain }) => {
          return chain()
            .focus()
            .setMark(this.name, attributes)
            .setMeta("preventAutolink", true)
            .run();
        },
      unsetMaterialLink:
        () =>
        ({ chain }) => {
          return chain()
            .unsetMark(this.name, { extendEmptyMarkRange: true })
            .setMeta("preventAutolink", true)
            .run();
        },
    };
  },
});

export default MaterialLink;
