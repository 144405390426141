import { Company, CompanyBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import {
  fetchCompanyInfoAsOwner,
  fetchCompanyInfoAsSuperOwner,
  fetchCompanyInfoAsTeacher,
  fetchCompanyProviderAsTeacher,
  fetchMaterialCapacityAsOwner,
  updateOwnCompanyInfoAsOwner,
  updateOwnCompanyInfoAsSuperOwner,
  updateUserAccountLockAsOwner,
  updateUserAccountLockAsTeacher,
} from "../actions/company";
import { RootState } from "../store";
import { MaterialCapacity } from "./adminApp/company";

export interface CompanyInfoState {
  updating: boolean;
  updated: boolean;
  fetching: boolean;
  fetchingContentCompanies: boolean;
  fetched: boolean;
  fetchError: string | null;
  updateError: string | null;
  info: Company | null;
  contentCompanies: CompanyBase[];
  materialCapacityDetail: MaterialCapacity;
  fetchingMaterialCapacity: boolean;
}

export const initialState: CompanyInfoState = {
  updating: false,
  updated: false,
  fetching: false,
  fetchingContentCompanies: false,
  fetched: false,
  fetchError: null,
  updateError: null,
  contentCompanies: [],
  info: null,
  materialCapacityDetail: null,
  fetchingMaterialCapacity: false,
};

export const companySlice = createSlice({
  name: "Company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyInfoAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          fetchError: null,
          updated: false,
          updateError: null,
        };
      })
      .addCase(fetchCompanyInfoAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          info: action.payload,
        };
      })
      .addCase(fetchCompanyInfoAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          info: null,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(fetchCompanyInfoAsOwner.pending, (state, action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          fetchError: null,
          updated: false,
          updateError: null,
        };
      })
      .addCase(fetchCompanyInfoAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          info: action.payload,
        };
      })
      .addCase(fetchCompanyInfoAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          info: null,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(fetchMaterialCapacityAsOwner.pending, (state, action) => {
        return {
          ...state,
          fetchingMaterialCapacity: true,
        };
      })
      .addCase(fetchMaterialCapacityAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingMaterialCapacity: false,
          materialCapacityDetail: action.payload,
        };
      })
      .addCase(fetchMaterialCapacityAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchingMaterialCapacity: false,
          materialCapacityDetail: null,
          fetchError: getErrorMessage(action.payload),
        };
      })
      .addCase(fetchCompanyInfoAsTeacher.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchCompanyInfoAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          info: action.payload,
        };
      })
      .addCase(fetchCompanyInfoAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          info: null,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(fetchCompanyProviderAsTeacher.pending, (state, action) => {
        return {
          ...state,
          fetchingContentCompanies: true,
        };
      })
      .addCase(fetchCompanyProviderAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingContentCompanies: false,
          contentCompanies: action.payload.content_companies,
        };
      })
      .addCase(fetchCompanyProviderAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingContentCompanies: false,
          contentCompanies: [],
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(updateOwnCompanyInfoAsOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          updateError: null,
        };
      })
      .addCase(updateOwnCompanyInfoAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
        };
      })
      .addCase(updateOwnCompanyInfoAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updateError: action.payload,
        };
      })
      .addCase(updateUserAccountLockAsTeacher.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          updateError: null,
        };
      })
      .addCase(updateUserAccountLockAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
        };
      })
      .addCase(updateUserAccountLockAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updateError: action.payload,
        };
      })
      .addCase(updateUserAccountLockAsOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          updateError: null,
        };
      })
      .addCase(updateUserAccountLockAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
        };
      })
      .addCase(updateUserAccountLockAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updateError: action.payload,
        };
      })
      .addCase(updateOwnCompanyInfoAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          updateError: null,
        };
      })
      .addCase(updateOwnCompanyInfoAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
        };
      })
      .addCase(updateOwnCompanyInfoAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updateError: action.payload,
        };
      });
  },
});

export const companyState = (state: RootState): CompanyInfoState =>
  state.company;

export default companySlice.reducer;
