import { getSteamLibraryContentImages } from "@actions/steamLibrary";
import { SteamLibraryContentImage } from "@actions/types/steamLibrary";
import {
  Api,
  GetStudentSteamContentWorksParams,
  SearchStudentSteamContentsParams,
  StudentPblSteamContentDetail,
  StudentPblSteamContentWork,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const searchSteamContentsAsStudent = createAsyncThunk<
  any,
  SearchStudentSteamContentsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibraryContent/search",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await api.student.searchStudentSteamContents(params);
      const contentIds = response.data.steam_contents.map(
        (content) => content.sl_content_id,
      );
      await dispatch(getSteamLibraryContentImages(contentIds));
      const contentsWithImages = response.data.steam_contents.map(
        (content) => ({
          ...content,
          top_image: getState().steamLibrary.contentImages.find(
            (image: SteamLibraryContentImage) =>
              image.id === content.sl_content_id,
          ),
        }),
      );
      return {
        ...response.data,
        steam_contents: contentsWithImages,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSteamContentWorksAsStudent = createAsyncThunk<
  any,
  GetStudentSteamContentWorksParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibraryContentWorks/fetch",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await api.student.getStudentSteamContentWorks(params);
      const contentIds = response.data.steam_contents?.map(
        (content) => content.sl_content_id,
      );
      await dispatch(getSteamLibraryContentImages(contentIds as number[]));
      const contentsWithImages = response.data?.steam_contents?.map(
        (content) => ({
          ...content,
          top_image: getState().steamLibrary.contentImages.find(
            (image: SteamLibraryContentImage) =>
              image.id === content.sl_content_id,
          ),
        }),
      );
      return {
        ...response.data,
        steam_contents: contentsWithImages,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSteamContentWorksAsStudent = createAsyncThunk<
  StudentPblSteamContentWork,
  { contentId: number; progress: StudentPblSteamContentDetail["progress"] },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibraryContentWorks/post",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.student.postStudentSteamContentWorks(
        { content_id: params.contentId },
        { progress: params.progress },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSteamContentWorksAsStudent = createAsyncThunk<
  StudentPblSteamContentWork,
  { id: string; progress: StudentPblSteamContentDetail["progress"] },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibraryContentWorks/put",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.student.putStudentSteamContentWorksId(
        params.id,
        { progress: params.progress },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetSteamContentWorksState = createAction(
  "student/steamLibraryContent/resetContentWorksState",
);
export const resetSearchSteamContentsState = createAction(
  "student/steamLibraryContent/resetSearchState",
);
