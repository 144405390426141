/* eslint-disable camelcase */
import {
  Api,
  GetHomeworkImageDownloadUrlsAsReviewerParams,
  ModelBase,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export type HomeworkForDownloadUrls = ModelBase & {
  download_urls: string[];
  student_name: string;
};

export interface GetImageUrlsArg {
  withQr: boolean;
  params: GetHomeworkImageDownloadUrlsAsReviewerParams;
}

export const getDownloadUrls = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkImage/fetchDownloadUrls",
  async (arg: GetImageUrlsArg, { rejectWithValue }) => {
    try {
      const response =
        await api.reviewer.getHomeworkImageDownloadUrlsAsReviewer(arg.params);
      return response.data.homeworks as HomeworkForDownloadUrls[];
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction("reviewer/homeworkImage/resetState");
