/* eslint-disable @typescript-eslint/ban-types */
import { CompanyTagType } from "@actions/types/tag";
import { StyledFormHelperText } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList/Modals/LearningTemplateDistributionModal/StepOne";
import { FormType } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList/Modals/LearningTemplateDistributionModal/usePackageDistributionModal";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import { Company } from "@lib/Api";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Control, Controller } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    studentListWrapper: {
      maxHeight: 500,
      width: "100%",
      padding: theme.spacing(1, 2),
      display: "flex",
      justifyContenßt: "flex-start",
      alignItems: "flex-start",
      border: `2px solid rgba(71, 81, 73, 0.3)`,
      borderRadius: 8,
      overflow: "scroll",
    },
    items: {
      root: {
        "&.MuiFormControlLabel-root": {
          marginLeft: "0px",
        },
      },
      width: "100%",
      backgroundColor: theme.palette.learningTemplatePackage.main,
    },
  }),
);
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "0px",
      width: "100%",
      backgroundColor: "rgba(0, 130, 103, 0.05)",
      "&:not(:last-child)": {
        marginBottom: "5px",
      },
    },
  }),
);

interface Props {
  schools: Company[];
  toggleAllSchool: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSchools: (value: Company | CompanyTagType) => {
    id: string;
    name: string;
  }[];
  control: Control<FormType, object>;
  errors: any;
}

const SchoolList: React.FC<Props> = ({
  schools,
  toggleAllSchool,
  handleSchools,
  control,
  errors,
}: Props) => {
  const classes = useStyles();
  const label = useStyle();

  return (
    <FormSectionWrap>
      <FormCategoryTitle>学校名 *</FormCategoryTitle>
      {schools && schools.length > 0 ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  toggleAllSchool(e);
                }}
              />
            }
            label={<span style={{ fontSize: 14 }}>全て選択</span>}
            labelPlacement="start"
            style={{ fontSize: 14 }}
            data-cy="select-allSchool"
          />
          <>
            {errors.schools ? (
              <StyledFormHelperText>
                ** 学校の設定は必須です **
              </StyledFormHelperText>
            ) : null}
          </>
          <div className={classes.studentListWrapper}>
            <Controller
              control={control}
              name="schools"
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                const compareTo = value.map((item) => item.id);
                return (
                  <>
                    <FormControl style={{ width: "100%" }} error={!!error}>
                      <FormGroup style={{ width: "100%" }}>
                        {schools &&
                          schools.map((school: any) => (
                            <FormControlLabel
                              className={label.root}
                              key={school.id}
                              control={
                                <Checkbox
                                  onChange={() =>
                                    onChange(handleSchools(school))
                                  }
                                  checked={compareTo?.includes(school.id)}
                                  inputRef={ref}
                                />
                              }
                              label={school.name}
                            />
                          ))}
                      </FormGroup>
                    </FormControl>
                  </>
                );
              }}
              rules={{ required: "学校の設定は必須です" }}
            />
          </div>
        </>
      ) : (
        <Box m={2}>
          <Typography>学校が見つかりませんでした。</Typography>
        </Box>
      )}
    </FormSectionWrap>
  );
};

export default SchoolList;
