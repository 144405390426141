/* eslint-disable camelcase */
import { ImageData } from "@components/Admin/Sponsors/modals/types";
import { Api } from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

interface CreateSponsorProjectCategoryAsAdminParams {
  project_id: string;
  name: string;
  description: string;
  file: ImageData | null;
}

export const createSponsorProjectCategoryAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/categories/create",
  async (
    params: CreateSponsorProjectCategoryAsAdminParams,
    { rejectWithValue },
  ) => {
    try {
      const { project_id, name, description, file } = params;
      const fd = new FormData();
      fd.set("project_id", project_id);
      fd.set("name", name);
      if (typeof description !== "undefined") {
        fd.set("description", description);
      }
      if (file && file.file) {
        fd.set("file", file.file);
      }
      const response = await http.post(`/admin/sponsor/project_categories`, fd);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface UpdateSponsorProjectCategoryAsAdminParams {
  name: string;
  description: string;
  file: ImageData | null;
}

type UpdateSponsorProjectCategoryAsAdminArg = {
  id: string;
  params: UpdateSponsorProjectCategoryAsAdminParams;
};

export const updateSponsorProjectCategoryAsAdmin = createAsyncThunk<
  any,
  UpdateSponsorProjectCategoryAsAdminArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/categories/update",
  async (
    { id, params }: UpdateSponsorProjectCategoryAsAdminArg,
    { rejectWithValue },
  ) => {
    try {
      const { name, description, file } = params;
      const fd = new FormData();
      fd.set("name", name);
      if (typeof description !== "undefined") {
        fd.set("description", description);
      }
      if (file && file.file) {
        fd.set("file", file.file);
      }
      const response = await http.put(
        `/admin/sponsor/project_categories/${id}`,
        fd,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSponsorProjectCategoryAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/categories/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.admin.deleteSponsorProjectCategoryAsAdmin(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetAction = createAction(
  "admin/pbl/sponsor/projects/categories/resetAction",
);
