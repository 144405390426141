import { combineReducers } from "redux";

import ArticleReducer from "./article";
import HomeworkReducer from "./homework";
import HomeworkChunkReducer from "./homeworkChunk";
import HomeworkImage from "./homeworkImage";
import HomeworkTemplateReducer from "./homeworkTemplate";
import HomeworkTemplateChunkDistributionReducer from "./homeworkTemplateChunkDistribution";
import HomeworkTemplateDistributionReducer from "./homeworkTemplateDistribution";
import submitHomeworksReviewerRequestReducer from "./submitHomeworksReviewerRequest";
import UserReducer from "./user";

const reviewerAppReducer = combineReducers({
  user: UserReducer,
  article: ArticleReducer,
  homework: HomeworkReducer,
  homeworkChunk: HomeworkChunkReducer,
  homeworkTemplateChunkDistribution: HomeworkTemplateChunkDistributionReducer,
  homeworkTemplateDistribution: HomeworkTemplateDistributionReducer,
  homeworkTemplate: HomeworkTemplateReducer,
  submitHomeworksFilesRequest: submitHomeworksReviewerRequestReducer,
  homeworkImage: HomeworkImage,
});

export default reviewerAppReducer;
