import {
  BasicUserInfo,
  MaterialDetail,
  MaterialList,
  ScheduleBase,
} from "@lib/Api";
import errorWithMessage, { errorWithMessages } from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import {
  acceptTermsOfUse,
  CommonAffiliate,
  connectedToClassRoom,
  deleteCommonSchedules,
  getCommonAffiliates,
  getCommonSchedules,
  getCommonSchedulesDetail,
  getHelpCategories,
  getHelpMaterialDetail,
  getHelpMaterials,
  postCommonAffiliates,
  postCommonSchedules,
  resetClassStatusValue,
  resetState,
  resetStatusValue,
  scheduleEventEnded,
  scheduleEventStarted,
  sentInvitationsToUsers,
  sentInvitationToUser,
  syncGoogleCalendar,
  syncGoogleClassRoom,
  synchedGoogleCalendar,
  synchedGoogleClassRoom,
  synchedGoogleDrives,
  synchedMicrosoftDrives,
  toggleHelpPageDrawer,
  toggleJoyride,
  updateCommonSchedules,
} from "../actions/common";
import { RootState } from "../store";

const googleSync = Cookies.get("googleAuth");
export interface CommonState {
  companies: CommonAffiliate[];
  affiliateUser: BasicUserInfo | null;
  fetching: boolean;
  fetched: boolean;
  changing: boolean;
  changed: boolean;
  updating: boolean;
  updated: boolean;
  sending: boolean;
  sent: boolean;
  deletingCommonSchedule: boolean;
  deletedCommonSchedule: boolean;
  isSyncToGoogle: boolean;
  error: string | null;
  affiliateError: string | null;
  schedulesError: string | null;
  classRoomErrors: string[];
  message: string | null;
  schedules: ScheduleBase[];
  schedule: ScheduleBase | Record<string, never>;
  schedulesFetching: boolean;
  schedulesFetched: boolean;
  helpCategories: any[];
  helpCategoryDetail: any;
  helpMaterials: MaterialList[];
  helpMaterialDetail: MaterialDetail | null;
  googleOauthUrl: any;
  helpFetching: boolean;
  helpFetchingDetail: boolean;
  helpFetched: boolean;
  joyrideOpen: boolean;
  drawerOpen: boolean;
  accepting: boolean;
  accepted: boolean;
  synched: boolean;
  isSynched: boolean;
  status: string | null;
  classStatus: string | null;
  deletedErrorMessage: string | null;
  errorBatch: string | null;
  sendingBatch: boolean;
  sentBatch: boolean;
  scheduleEventStarting: boolean;
  scheduleEventIsEnded: boolean;
}

export const initialState: CommonState = {
  companies: [],
  affiliateUser: null,
  fetching: false,
  fetched: false,
  changing: false,
  changed: false,
  updating: false,
  updated: false,
  sending: false,
  sent: false,
  accepting: false,
  accepted: false,
  deletingCommonSchedule: false,
  deletedCommonSchedule: false,
  synched: false,
  isSynched: false,
  isSyncToGoogle: !!googleSync,
  error: null,
  affiliateError: null,
  schedulesError: null,
  classRoomErrors: [],
  message: null,
  schedules: [],
  schedule: {},
  schedulesFetching: false,
  schedulesFetched: false,
  scheduleEventStarting: false,
  scheduleEventIsEnded: false,
  helpCategories: [],
  helpCategoryDetail: null,
  helpMaterials: [],
  helpMaterialDetail: null,
  helpFetching: false,
  helpFetchingDetail: false,
  helpFetched: false,
  joyrideOpen: false,
  drawerOpen: false,
  googleOauthUrl: null,
  status: null,
  classStatus: null,
  deletedErrorMessage: null,
  errorBatch: null,
  sendingBatch: false,
  sentBatch: false,
};

export const commonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommonAffiliates.pending, (state, action) => {
        return {
          ...state,
          changed: false,
          fetching: true,
          companies: [],
          affiliateUser: null,
          affiliateError: null,
        };
      })
      .addCase(getCommonAffiliates.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          companies: action.payload.affiliates as any,
          affiliateUser: action.payload.user,
          error: null,
          fetched: true,
        };
      })
      .addCase(getCommonAffiliates.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          affiliateError: errorWithMessage(action.payload),
          companies: [],
          affiliateUser: null,
        };
      })
      .addCase(postCommonAffiliates.pending, (state, action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          message: null,
          affiliateError: null,
        };
      })
      .addCase(postCommonAffiliates.fulfilled, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          message: action.payload.message as string,
          affiliateError: null,
        };
      })
      .addCase(postCommonAffiliates.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          message: null,
          affiliateError: errorWithMessage(action.payload),
        };
      })
      .addCase(getCommonSchedules.pending, (state, action) => {
        return {
          ...state,
          schedulesFetching: true,
          schedules: [],
          schedulesError: null,
        };
      })
      .addCase(getCommonSchedules.fulfilled, (state, action) => {
        return {
          ...state,
          schedulesFetching: false,
          schedules: action.payload as any,
          schedulesError: null,
          schedulesFetched: true,
        };
      })
      .addCase(getCommonSchedules.rejected, (state, action) => {
        return {
          ...state,
          schedulesFetching: false,
          schedulesFetched: false,
          schedulesError: errorWithMessage(action.payload),
          schedules: [],
        };
      })
      .addCase(postCommonSchedules.pending, (state, action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          schedulesError: null,
          message: null,
        };
      })
      .addCase(postCommonSchedules.fulfilled, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          schedulesError: null,
          message: action.payload.message,
        };
      })
      .addCase(postCommonSchedules.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          schedulesError: errorWithMessage(action.payload),
          message: null,
        };
      })
      .addCase(updateCommonSchedules.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          schedulesError: null,
          message: null,
        };
      })
      .addCase(updateCommonSchedules.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
          schedulesError: null,
          message: action.payload.message,
        };
      })
      .addCase(updateCommonSchedules.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          schedulesError: errorWithMessage(action.payload),
          message: null,
        };
      })
      .addCase(deleteCommonSchedules.pending, (state, _action) => {
        return {
          ...state,
          deletingCommonSchedule: true,
          deletedCommonSchedule: false,
          error: null,
        };
      })
      .addCase(deleteCommonSchedules.fulfilled, (state, action) => {
        return {
          ...state,
          deletingCommonSchedule: false,
          deletedCommonSchedule: true,
          deletedErrorMessage: action.payload.message,
          error: null,
        };
      })
      .addCase(deleteCommonSchedules.rejected, (state, action) => {
        return {
          ...state,
          deletingCommonSchedule: false,
          deletedCommonSchedule: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(getCommonSchedulesDetail.pending, (state, action) => {
        return {
          ...state,
          schedulesFetching: true,
          schedule: {},
          schedulesError: null,
        };
      })
      .addCase(getCommonSchedulesDetail.fulfilled, (state, action) => {
        return {
          ...state,
          schedulesFetching: false,
          schedule: action.payload,
          schedulesError: null,
          schdulesFetched: true,
        };
      })
      .addCase(getCommonSchedulesDetail.rejected, (state, action) => {
        return {
          ...state,
          schedulesFetching: false,
          schdulesFetched: false,
          schedulesError: errorWithMessage(action.payload),
          schedule: {},
        };
      })
      .addCase(getHelpCategories.pending, (state, action) => {
        return {
          ...state,
          helpFetching: true,
          helpFetched: false,
          error: null,
        };
      })
      .addCase(getHelpCategories.fulfilled, (state, action) => {
        return {
          ...state,
          helpFetching: false,
          helpCategories: action.payload,
          error: null,
          helpFetched: true,
        };
      })
      .addCase(getHelpCategories.rejected, (state, action) => {
        return {
          ...state,
          helpFetching: false,
          helpFetched: false,
          error: errorWithMessage(action.payload),
          helpCategories: [],
        };
      })
      .addCase(getHelpMaterials.pending, (state, action) => {
        return {
          ...state,
          helpFetching: true,
          helpFetched: false,
          error: null,
        };
      })
      .addCase(getHelpMaterials.fulfilled, (state, action) => {
        return {
          ...state,
          helpFetching: false,
          helpMaterials: action.payload.materials,
          error: null,
          helpFetched: true,
        };
      })
      .addCase(getHelpMaterials.rejected, (state, action) => {
        return {
          ...state,
          helpFetching: false,
          helpFetched: false,
          error: errorWithMessage(action.payload),
          helpMaterials: [],
        };
      })
      .addCase(getHelpMaterialDetail.pending, (state, action) => {
        return {
          ...state,
          helpFetchingDetail: true,
          helpFetched: false,
          error: null,
        };
      })
      .addCase(getHelpMaterialDetail.fulfilled, (state, action) => {
        return {
          ...state,
          helpFetchingDetail: false,
          helpMaterialDetail: action.payload,
          error: null,
          helpFetched: true,
        };
      })
      .addCase(getHelpMaterialDetail.rejected, (state, action) => {
        return {
          ...state,
          helpFetchingDetail: false,
          helpFetched: false,
          error: errorWithMessage(action.payload),
          helpMaterialDetail: null,
        };
      })
      .addCase(syncGoogleCalendar.pending, (state, action) => {
        return {
          ...state,
          message: null,
          status: null,
        };
      })
      .addCase(syncGoogleCalendar.fulfilled, (state, action) => {
        return {
          ...state,
          googleOauthUrl: action.payload,
          message: action.payload.message as string,
          status: action.payload.status as string,
          affiliateError: null,
        };
      })
      .addCase(syncGoogleCalendar.rejected, (state, action) => {
        return {
          ...state,
          googleOauthUrl: null,
          message: null,
          status: null,
          affiliateError: errorWithMessage(action.payload),
        };
      })
      .addCase(sentInvitationToUser.pending, (state, action) => {
        return {
          ...state,
          error: null,
          sending: true,
          sent: false,
        };
      })
      .addCase(sentInvitationToUser.fulfilled, (state, action) => {
        return {
          ...state,
          error: null,
          sending: false,
          sent: true,
        };
      })
      .addCase(sentInvitationToUser.rejected, (state, action) => {
        return {
          ...state,
          error: errorWithMessage(action.payload),
          sending: false,
          sent: false,
        };
      })
      .addCase(sentInvitationsToUsers.pending, (state, action) => {
        return {
          ...state,
          errorBatch: null,
          sendingBatch: true,
          sentBatch: false,
        };
      })
      .addCase(sentInvitationsToUsers.fulfilled, (state, action) => {
        return {
          ...state,
          errorBatch: null,
          sendingBatch: false,
          sentBatch: true,
        };
      })
      .addCase(sentInvitationsToUsers.rejected, (state, action) => {
        return {
          ...state,
          errorBatch: errorWithMessage(action.payload),
          sendingBatch: false,
          sentBatch: false,
        };
      })
      .addCase(acceptTermsOfUse.pending, (state, action) => {
        return {
          ...state,
          error: null,
          accepting: true,
          accepted: false,
        };
      })
      .addCase(acceptTermsOfUse.fulfilled, (state, action) => {
        return {
          ...state,
          error: null,
          accepting: false,
          accepted: true,
        };
      })
      .addCase(acceptTermsOfUse.rejected, (state, action) => {
        return {
          ...state,
          error: errorWithMessage(action.payload),
          accepting: false,
          accepted: false,
        };
      })
      .addCase(synchedGoogleCalendar, (state) => {
        return {
          ...state,
          synched: true,
          googleOauthUrl: null,
        };
      })
      .addCase(syncGoogleClassRoom.pending, (state, action) => {
        return {
          ...state,
          message: null,
          fetching: true,
          classStatus: null,
        };
      })
      .addCase(syncGoogleClassRoom.fulfilled, (state, action) => {
        return {
          ...state,
          googleOauthUrl: action.payload,
          fetching: false,
          message: action.payload.message as string,
          classStatus: action.payload.status as string,
          classRoomErrors: [],
        };
      })
      .addCase(syncGoogleClassRoom.rejected, (state, action) => {
        return {
          ...state,
          googleOauthUrl: null,
          fetching: false,
          message: null,
          classStatus: null,
          classRoomErrors: errorWithMessages(action.payload),
        };
      })
      .addCase(synchedGoogleClassRoom, (state) => {
        return {
          ...state,
          isSynched: true,
          googleOauthUrl: null,
        };
      })
      .addCase(toggleJoyride, (state) => {
        return {
          ...state,
          joyrideOpen: !state.joyrideOpen,
        };
      })
      .addCase(toggleHelpPageDrawer, (state) => {
        return {
          ...state,
          drawerOpen: !state.drawerOpen,
        };
      })
      .addCase(resetStatusValue, (state) => {
        return {
          ...state,
          status: "synched",
        };
      })
      .addCase(resetClassStatusValue, (state) => {
        return {
          ...state,
          classStatus: "conected",
        };
      })
      .addCase(connectedToClassRoom, (state) => {
        return {
          ...state,
          isSyncToGoogle: true,
        };
      })
      .addCase(scheduleEventStarted, (state) => {
        return {
          ...state,
          scheduleEventStarting: true,
        };
      })
      .addCase(scheduleEventEnded, (state) => {
        return {
          ...state,
          scheduleEventStarting: false,
          scheduleEventIsEnded: true,
        };
      })
      .addCase(synchedMicrosoftDrives, (state) => {
        return {
          ...state,
        };
      })
      .addCase(synchedGoogleDrives, (state) => {
        return {
          ...state,
          googleDrivesOauthUrl: null,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          accepting: false,
          accepted: false,
          updating: false,
          updated: false,
          sending: false,
          sent: false,
          error: null,
          affiliateError: null,
          schedulesError: null,
          classRoomErrors: [],
          changing: false,
          changed: false,
          deletingCommonSchedule: false,
          deletedCommonSchedule: false,
          schedule: {},
          errorBatch: null,
          sendingBatch: false,
          sentBatch: false,
          scheduleEventStarting: false,
          scheduleEventIsEnded: false,
        };
      });
  },
});

export const commonState = (state: RootState): CommonState => state.common;

export default commonSlice.reducer;
