/* eslint-disable camelcase */
import {
  ChildCustomMasterMapRelationship,
  RelationshipChildItem,
} from "@actions/types/customList";
import {
  Api,
  CustomMasterBase,
  CustomMasterMapRelationshipBase,
  CustomMasterRequestBody,
  GetCustomMasterMapRelationshipChildCustomMastersAsSuperOwnerParams,
  GetSuperOwnerCustomMasterMapsCustomMasterMapIdParams,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchCustomMasterListDetailAsSuperOwner = createAsyncThunk<
  any,
  GetSuperOwnerCustomMasterMapsCustomMasterMapIdParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterListDetail/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.getSuperOwnerCustomMasterMapsCustomMasterMapId(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

const getRelationChildren = async (
  relation: CustomMasterMapRelationshipBase,
  params: GetCustomMasterMapRelationshipChildCustomMastersAsSuperOwnerParams,
) => {
  try {
    const response =
      await api.superOwner.getCustomMasterMapRelationshipChildCustomMastersAsSuperOwner(
        params,
      );
    const childList = response.data.child_custom_master_columns.map((resp) => {
      return {
        ...resp,
        relationId: params.id,
      };
    });
    const renderChildLIstObj: RelationshipChildItem = {
      relationName: relation.name,
      listData: childList,
    };
    return { data: renderChildLIstObj };
  } catch (error) {
    return { err: errorWithMessage(error.response) };
  }
};

export const getCustomMasterMapRelationshipChildAsSuperOwner = createAsyncThunk<
  ChildCustomMasterMapRelationship,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/getCustomMasterMapRelationshipChildA/get",
  async (id, { rejectWithValue }) => {
    try {
      const relationListResponse =
        await api.superOwner.getCustomMasterMapRelationshipsAsSuperOwner({
          custom_master_map_id: id,
        });
      const results = await Promise.all(
        relationListResponse.data.relationships.map((relation) =>
          getRelationChildren(relation, {
            custom_master_map_id: id,
            id: relation.id,
          }),
        ),
      );
      const createdItems: RelationshipChildItem[] = [];
      const errorList: string[] = [];
      results.forEach((result) => {
        if (result.data) {
          createdItems.push(result.data);
        } else {
          errorList.push(result.err);
        }
      });
      const childItems = createdItems.flat(1);
      return {
        childItems,
        customMasterRelation: relationListResponse.data.relationships,
        errorList,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

type CustomMasterListRelationDetailParams = {
  relationship_types_id: string;
  child_relation_id: string;
};

type PostCustomMasterListDetailParams = CustomMasterRequestBody & {
  custom_master_map_id: string;
  relationships?: CustomMasterListRelationDetailParams[];
  index?: number;
};

export const postCustomMasterListDetailAsSuperOwner = createAsyncThunk<
  any,
  PostCustomMasterListDetailParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterListDetail/post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.postSuperOwnerCustomMasters(data);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        error: err.response.data,
        requestIndex: data.index,
      });
    }
  },
);

export type UpdateCustomMasterListDetailParams = {
  values: CustomMasterBase & {
    relationships?: {
      relationship_types_id: string;
      child_relation_id: string;
    }[];
  };
  custom_master_id: string;
};

export const updateCustomMasterListDetailAsSuperOwner = createAsyncThunk<
  any,
  UpdateCustomMasterListDetailParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterListDetail/update",
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.putSuperOwnerCustomMastersCustomMasterId(
          data.custom_master_id,
          data.values,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteCustomMasterListDetailAsSuperOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterListDetail/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.deleteSuperOwnerCustomMastersCustomMasterId(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCustomMasterRecordCompanyLink = createAsyncThunk<
  { message?: string },
  { companyId: string; customMasterId: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterListDetail/link",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.customMastersLinkingCompanyUpdate(
        data.customMasterId,
        { company_id: data.companyId },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "superOwner/customMasterListDetail/resetState",
);

export const resetBatchCreateState = createAction(
  "superOwner/customMasterListDetail/resetBatchState",
);
