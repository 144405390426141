/* eslint-disable camelcase */
import { fetchCategoriesAsSuperOwner } from "@actions/superOwnerApp/category";
import { fetchHomeworkTemplatesAsSuperOwner } from "@actions/superOwnerApp/homeworkTemplate";
import {
  addPackagesSuccess,
  postPackagingAsSuperOwner,
  updatePackagingAsSuperOwner,
} from "@actions/superOwnerApp/templatePackages/learningTemplatePackage";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import { SelectedButton } from "@components/UIv2/molecules/V2Tabs";
import { ITEMS_PER_PAGE } from "@constants/page";
import {
  ModalStateParams,
  ModalStatus,
} from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import { GradeCategoryState } from "@reducers/category/grade";
import { MajorCategoryState } from "@reducers/category/major";
import { MiddleCategoryState } from "@reducers/category/middle";
import { MinorCategoryState } from "@reducers/category/minor";
import { SubjectCategoryState } from "@reducers/category/subject";
import { TextCategoryState } from "@reducers/category/text";
import { superOwnerAppHomeworkTemplateState } from "@reducers/superOwnerApp/homeworkTemplate";
import { useGetSuperOwnerMaterialsAllContainedCategoryQuery } from "@root/endpoints/TimeTactApi/superOwnerApi";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import {
  BaseCategoryInfo,
  PackagingAsLearningTemplatePackageBase,
  PackagingPackageableType,
} from "@root/lib/Api";
import _ from "lodash";
import React, { useEffect, useMemo,useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import AddCoPackage from "./AddCoPackage";
import ChildPackage from "./ChildPackage";
import EditCoPackage from "./EditCoPackage";
import HomeWorkTemplateList from "./HomeWorkTemplateList";
import MaterialList from "./MaterialsList";
import PanelHeader from "./PanelHeader";
import PblProjectList from "./PblProjectList";
import SteamContentList from "./SteamContentList";

interface Props {
  modalState: ModalStateParams;
  posting: boolean;
  postedPackage: boolean;
  handleClose: () => void;
  text: TextCategoryState;
  grade: GradeCategoryState;
  subject: SubjectCategoryState;
  major: MajorCategoryState;
  middle: MiddleCategoryState;
  minor: MinorCategoryState;
  fetching: boolean;
}
export interface coPackageType {
  type: SelectedButton;
  id: string;
}

enum REDUCER_ACTIONS {
  PACKAGE_TYPE = "package_type",
  CHOSEN_PACKAGE = "chosen_package",
}
type INITIAL_STATES = {
  chosenPackage: string[] | coPackageType[];
  packageType: PackagingPackageableType;
};

type ACTIONS = {
  type: REDUCER_ACTIONS;
  payload: any;
};

const PackageContentsEnum = {
  homeworkTemplate: "homeworkTemplate",
  material: "material",
} as const;
interface FormType {
  packageTitle: string;
}

const AddPackageModal: React.FC<Props> = ({
  postedPackage,
  handleClose,
  modalState,
  posting,
  text,
  grade,
  subject,
  major,
  middle,
  minor,
  fetching,
}) => {
  const dispatch = useAppDispatch();
  const { actions } = useEnqueueToast();
  const {
    isOpen,
    status,
    learningTemplate,
    package: templatePackage,
    packagesArray,
  } = modalState;

  const packageTypeValues = Object.values(PackagingPackageableType).map(
    (value) => String(value),
  );
  const [currentMaterialsPage, setCurrentMaterialsPage] = useState<number>(1);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string[]>([]);
  const typeOfPackage = useMemo(
    () =>
      templatePackage?.packageable_type &&
      packageTypeValues.includes(templatePackage.packageable_type)
        ? (templatePackage?.packageable_type as PackagingPackageableType)
        : PackagingPackageableType.Package,
    [templatePackage?.packageable_type],
  );

  const isModalOpen =
    isOpen &&
    (status === ModalStatus.ADD_PACKAGE ||
      status === ModalStatus.EDIT_ADDED_PACKAGE);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const homeworkTemplateCache = useAppSelector(
    superOwnerAppHomeworkTemplateState,
  );
  const { templates, fetching: fetchingHomeWorkTemplate } =
    homeworkTemplateCache;

  const { state } = useLocation();

  const [buttonState, setButtonState] = useState<SelectedButton>(
    state && state.from === PackageContentsEnum.material
      ? SelectedButton.Secondary
      : SelectedButton.Primary,
  );

  const initialState: INITIAL_STATES = {
    chosenPackage: [],
    packageType:
      status === ModalStatus.EDIT_ADDED_PACKAGE &&
      typeOfPackage === PackagingPackageableType.Package
        ? typeOfPackage
        : PackagingPackageableType.Material,
  };

  // eslint-disable-next-line default-param-last
  const reducer = (storeState = initialState, action: ACTIONS) => {
    switch (action.type) {
      case REDUCER_ACTIONS.CHOSEN_PACKAGE:
        return {
          ...storeState,
          chosenPackage: action.payload,
        };
      case REDUCER_ACTIONS.PACKAGE_TYPE:
        return {
          ...storeState,
          packageType: action.payload,
        };
      default:
        throw new Error();
    }
  };

  const [{ chosenPackage, packageType }, dispatchIntern] = useReducer(
    reducer,
    initialState,
  );
  const editCoPackage =
    packageType === PackagingPackageableType.Package &&
    status === ModalStatus.EDIT_ADDED_PACKAGE;

  const editPackage =
    packageType !== PackagingPackageableType.Package &&
    status === ModalStatus.EDIT_ADDED_PACKAGE;

  const materialHomeworkPackage =
    packageType === PackagingPackageableType.Package &&
    chosenPackage.length < 2;

  const defaultValues = {
    packageTitle: "",
  };

  const { reset, control, handleSubmit, getValues } = useForm<FormType>({
    defaultValues,
  });

  const handlePackageType = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatchIntern({
      type: REDUCER_ACTIONS.CHOSEN_PACKAGE,
      payload: [],
    });
    dispatchIntern({
      type: REDUCER_ACTIONS.PACKAGE_TYPE,
      payload: event.target.value as PackagingPackageableType,
    });
  };

  const handleChosePackage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatchIntern({
        type: REDUCER_ACTIONS.CHOSEN_PACKAGE,
        payload: [...chosenPackage, event.target.value],
      });
    } else {
      dispatchIntern({
        type: REDUCER_ACTIONS.CHOSEN_PACKAGE,
        payload: chosenPackage.filter(
          (item: string) => item !== event.target.value,
        ),
      });
    }
  };

  const handleCoPackage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatchIntern({
        type: REDUCER_ACTIONS.CHOSEN_PACKAGE,
        payload: [
          ...chosenPackage,
          { type: buttonState, id: event.target.value },
        ],
      });
    } else {
      dispatchIntern({
        type: REDUCER_ACTIONS.CHOSEN_PACKAGE,
        payload: chosenPackage.filter(
          (item: coPackageType) => item.id !== event.target.value,
        ),
      });
    }
  };

  const getPackagedIds = () => {
    const coPackage = packagesArray?.flatMap((item) => {
      return item.material_homework_template_package
        ? item.material_homework_template_package.packagings
        : item;
    });
    const homeworksAndMaterials = coPackage?.flat();
    return homeworksAndMaterials?.map((pack) => pack?.packageable_id);
  };

  const packagedIds = getPackagedIds();

  const handleSelectedIds = (itemValue: BaseCategoryInfo) => {
    setSelectedCategoryId((prevCategory) => {
      return selectedCategoryId.includes(itemValue.id)
        ? selectedCategoryId.filter((item) => item !== itemValue.id)
        : _.uniq([...prevCategory, itemValue.id]);
    });
    setCurrentMaterialsPage(1);
  };

  const { data, isLoading } =
    useGetSuperOwnerMaterialsAllContainedCategoryQuery(
      {
        categoryIds: selectedCategoryId,
        perPage: ITEMS_PER_PAGE,
        page: currentMaterialsPage,
      },
      { refetchOnMountOrArgChange: true },
    );

  const handleMaterialsPagination = (newPage: number) => {
    setCurrentMaterialsPage(newPage);
  };

  const handleModalBody = () => {
    switch (packageType) {
      case PackagingPackageableType.Material:
        return (
          <MaterialList
            materials={data?.materials || []}
            handleChosePackage={handleChosePackage}
            chosenPackage={chosenPackage}
            status={status}
            selectedCategoryId={selectedCategoryId}
            text={text}
            grade={grade}
            subject={subject}
            major={major}
            middle={middle}
            minor={minor}
            fetchingMaterialsByCategories={isLoading}
            handleSelectedIds={handleSelectedIds}
            currentMaterialsPage={currentMaterialsPage}
            handleMaterialsPagination={handleMaterialsPagination}
            totalMaterials={data?.total_count || 0}
          />
        );
      case PackagingPackageableType.Package:
        return (
          <ChildPackage
            chosenPackage={chosenPackage}
            templates={templates}
            status={status}
            control={control}
            buttonState={buttonState}
            setButtonState={setButtonState}
            handleCoPackage={handleCoPackage}
            materials={data?.materials || []}
            selectedCategoryId={selectedCategoryId}
            text={text}
            grade={grade}
            subject={subject}
            major={major}
            middle={middle}
            minor={minor}
            fetchingMaterialsByCategories={isLoading}
            handleSelectedIds={handleSelectedIds}
            currentMaterialsPage={currentMaterialsPage}
            handleMaterialsPagination={handleMaterialsPagination}
            totalMaterials={data?.total_count || 0}
          />
        );
      case PackagingPackageableType.PblSteamLecture:
        return (
          <SteamContentList
            status={status}
            chosenPackage={chosenPackage}
            handleChosePackage={handleChosePackage}
          />
        );
      case PackagingPackageableType.PblSponsorLecture:
        return (
          <PblProjectList
            status={status}
            chosenPackage={chosenPackage}
            handleChosePackage={handleChosePackage}
          />
        );

      default:
        return (
          <HomeWorkTemplateList
            chosenPackage={chosenPackage}
            handleChosePackage={handleChosePackage}
            templates={templates}
            status={status}
          />
        );
    }
  };
  const modalBody = handleModalBody();

  const modalCloser = () => {
    handleClose();
    dispatchIntern({ type: REDUCER_ACTIONS.CHOSEN_PACKAGE, payload: [] });
    dispatchIntern({
      type: REDUCER_ACTIONS.PACKAGE_TYPE,
      payload: PackagingPackageableType.Material,
    });
    setButtonState(SelectedButton.Primary);
  };

  const handleEditAddedPackage = async () => {
    // ... edit Material or Homework Template
    if (!packagedIds?.includes(chosenPackage.toString())) {
      const updateParams = {
        data: {
          package_id: learningTemplate ? learningTemplate.id : "",
          packageable_type: packageType,
          packageable_id: chosenPackage.toString(),
        },
        currentPackage:
          templatePackage as PackagingAsLearningTemplatePackageBase,
      };
      await dispatch(updatePackagingAsSuperOwner(updateParams));
      await dispatch(addPackagesSuccess());
    } else {
      setErrorMessage("コンテンツはすでに存在します。");
    }
  };

  const handleAddPackage = async () => {
    // ... add Material or Homework Template
    if (packagedIds?.some((id: string) => chosenPackage.includes(id))) {
      setErrorMessage("コンテンツはすでに存在します。");
    } else {
      chosenPackage.forEach(async (packageId: string) => {
        await dispatch(
          postPackagingAsSuperOwner({
            package_id: learningTemplate ? learningTemplate.id : "",
            packageable_type: packageType,
            packageable_id: packageId,
          }),
        );
      });
      await dispatch(addPackagesSuccess());
    }
  };

  const handleAddCoPackage = async () => {
    await AddCoPackage({
      values: getValues(),
      learningTemplate,
      packageType,
      chosenPackage,
      setErrorMessage,
      packagedIds,
      dispatch,
    });
  };

  const handleEditCoPackage = async () => {
    await EditCoPackage({
      values: getValues(),
      templatePackage,
      chosenPackage,
      setErrorMessage,
      packagedIds,
      dispatch,
    });
  };

  const onSubmit = async () => {
    if (
      status === ModalStatus.EDIT_ADDED_PACKAGE &&
      learningTemplate &&
      chosenPackage.length > 0 &&
      packageType !== PackagingPackageableType.Package
    ) {
      // edit Material or Homework Template
      await handleEditAddedPackage();
    } else if (
      status === ModalStatus.ADD_PACKAGE &&
      packageType !== PackagingPackageableType.Package &&
      learningTemplate &&
      chosenPackage.length > 0
    ) {
      // add Material or Homework Template
      await handleAddPackage();
    } else if (
      status === ModalStatus.ADD_PACKAGE &&
      learningTemplate &&
      packageType === PackagingPackageableType.Package
    ) {
      // add Co-Package
      await handleAddCoPackage();
    } else if (
      status === ModalStatus.EDIT_ADDED_PACKAGE &&
      learningTemplate &&
      chosenPackage.length > 0 &&
      packageType === PackagingPackageableType.Package
    ) {
      // edit Co-Package
      await handleEditCoPackage();
    } else {
      setErrorMessage("コンテンツはすでに存在します。");
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      dispatch(fetchCategoriesAsSuperOwner({}));
      dispatch(fetchHomeworkTemplatesAsSuperOwner({ undelivery: false }));
      if (status === ModalStatus.EDIT_ADDED_PACKAGE && templatePackage) {
        dispatchIntern({
          type: REDUCER_ACTIONS.PACKAGE_TYPE,
          payload: typeOfPackage,
        });
        if (typeOfPackage !== PackagingPackageableType.Package) {
          dispatchIntern({
            type: REDUCER_ACTIONS.CHOSEN_PACKAGE,
            payload: [templatePackage.packageable_id],
          });
        } else {
          const selectedMaterial =
            templatePackage?.material_homework_template_package?.packagings.filter(
              (item) =>
                item.packageable_type === PackagingPackageableType.Material,
            );

          const selectedHomeWork =
            templatePackage?.material_homework_template_package?.packagings.filter(
              (item) =>
                item.packageable_type ===
                PackagingPackageableType.HomeworkTemplate,
            );

          const materialParam =
            selectedMaterial?.map((material) => ({
              type: SelectedButton.Primary,
              id: material.packageable_id,
            })) || [];

          const homeworkParam =
            selectedHomeWork?.map((homework) => ({
              type: SelectedButton.Secondary,
              id: homework.packageable_id,
            })) || [];

          dispatchIntern({
            type: REDUCER_ACTIONS.CHOSEN_PACKAGE,
            payload: [...materialParam, ...homeworkParam],
          });
        }
      }
    } else {
      setSelectedCategoryId([]);
      handleMaterialsPagination(1);
    }
    if (
      status === ModalStatus.EDIT_ADDED_PACKAGE &&
      typeOfPackage === PackagingPackageableType.Package
    ) {
      reset({
        ...getValues(),
        packageTitle:
          templatePackage?.material_homework_template_package?.title,
      });
    } else if (status === ModalStatus.ADD_PACKAGE) {
      reset({
        ...getValues(),
        packageTitle: "",
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (errorMessage) {
      actions.showWarning(errorMessage);
      setErrorMessage("");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (postedPackage) {
      modalCloser();
    }
  }, [postedPackage]);

  useEffect(() => {
    setCurrentMaterialsPage(1);
    setSelectedCategoryId([]);
  }, [packageType]);

  const modalTitle =
    status === ModalStatus.EDIT_ADDED_PACKAGE
      ? "コンテンツの変更"
      : "コンテンツを追加する";
  const primaryButtonText =
    status === ModalStatus.EDIT_ADDED_PACKAGE ? "更新する" : "追加する";

  return (
    <GenericModal
      useSecondaryButton
      title={modalTitle}
      useActionButtons
      fullScreen
      handleClose={posting ? () => null : modalCloser}
      secondaryButtonAction={modalCloser}
      primaryButtonAction={handleSubmit(onSubmit)}
      primaryButtonText={primaryButtonText}
      secondaryButtonText="キャンセル"
      open={isModalOpen}
      posting={posting}
      secondaryButtonActionPosting={posting}
      primaryLock={materialHomeworkPackage}
    >
      {isLoading || fetchingHomeWorkTemplate || fetching ? (
        <CircularLoading />
      ) : (
        <>
          <PanelHeader
            handlePackageType={handlePackageType}
            packageType={packageType}
            disabled={editCoPackage}
            disablePackage={editPackage}
          />
          {modalBody}
        </>
      )}
    </GenericModal>
  );
};

export default AddPackageModal;
