import Fuda from "@assets/images/svg/fuda_icon.svg?react";
import { Box, ChipProps } from "@mui/material";
import React from "react";

import { StyledTag, StyledTagColor, StyledTextColor } from ".";

export interface Props extends ChipProps {
  label: string;
  avatar?: React.ReactElement;
  fontSize?: "default" | "small";
}

const StyledUserTag: React.FC<Props> = ({
  label,
  avatar = (
    <Box sx={{ minWidth: "24px", minHeight: "24px" }}>
      <Fuda
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  ),
  fontSize = "default",
  ...args
}) => {
  return (
    <StyledTag
      tagColor={StyledTagColor.LightOpacityGrey}
      label={label}
      avatar={avatar}
      textColor={StyledTextColor.Green}
      fontSize={fontSize}
      {...args}
    />
  );
};

export default StyledUserTag;
