import {
  deleteSteamLibraryCommentAsTeacher,
  getSteamLibraryCommentAsTeacher,
  postSteamLibraryCommentAsTeacher,
  resetChangeCommentState,
  resetSteamLibraryCommentState,
  updateSteamLibraryCommentAsTeacher,
} from "@actions/teacherApp/steam/steamComment";
import { PblSteamCommentBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

interface TeacherAppSteamCommentState {
  fetching: boolean;
  fetched: boolean;
  fetchError: string | null;
  ownComment: PblSteamCommentBase | null;
  teacherComments: PblSteamCommentBase[];
  studentComments: PblSteamCommentBase[];
  changing: boolean;
  changed: boolean;
  changeError: any;
  changeMessage: string;
}

const initialState: TeacherAppSteamCommentState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  ownComment: null,
  teacherComments: [],
  studentComments: [],
  changing: false,
  changed: false,
  changeError: null,
  changeMessage: "",
};

const teacherAppSteamCommentSlice = createSlice({
  name: "TeacherApp/SteamComment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSteamLibraryCommentAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: null,
        };
      })
      .addCase(getSteamLibraryCommentAsTeacher.fulfilled, (state, action) => {
        const ownComment = action.payload.teacher_comments?.find(
          (comment: PblSteamCommentBase) =>
            comment.affiliate.user?.id === action.payload.userId,
        );
        return {
          ...state,
          fetching: false,
          fetched: true,
          ownComment,
          teacherComments: action.payload.teacher_comments,
          studentComments: action.payload.student_comments,
        };
      })
      .addCase(getSteamLibraryCommentAsTeacher.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(postSteamLibraryCommentAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(postSteamLibraryCommentAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          ownComment: action.payload,
          changeMessage: "コメントを追加しました。",
        };
      })
      .addCase(postSteamLibraryCommentAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changeError: action.payload.errors,
        };
      })
      .addCase(updateSteamLibraryCommentAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(
        updateSteamLibraryCommentAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            ownComment: action.payload,
            changeMessage: "コメントを編集しました。",
          };
        },
      )
      .addCase(updateSteamLibraryCommentAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changeError: action.payload.errors,
        };
      })
      .addCase(deleteSteamLibraryCommentAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(
        deleteSteamLibraryCommentAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            ownComment: null,
            changeMessage: "コメントを削除しました。",
          };
        },
      )
      .addCase(deleteSteamLibraryCommentAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changeError: action.payload.errors,
        };
      })
      .addCase(resetChangeCommentState, (state, _action) => {
        return {
          ...state,
          changeMessage: "",
          changed: false,
          changeError: null,
        };
      })
      .addCase(resetSteamLibraryCommentState, (state, _action) => {
        return {
          ...state,
          ownComment: null,
          teacherComments: [],
          studentComments: [],
          changeMessage: "",
          changed: false,
          changeError: null,
        };
      });
  },
});

export const teacherAppSteamCommentState = (
  state: RootState,
): TeacherAppSteamCommentState => state.teacherApp.steamComment;

export default teacherAppSteamCommentSlice.reducer;
