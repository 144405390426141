import ButtonDown from "@assets/images/svg/buttonDown.svg?react";
import ButtonUp from "@assets/images/svg/buttonUp.svg?react";
import OneBlock from "@assets/images/svg/oneBlock.svg?react";
import Trash from "@assets/images/svg/trash-icon-green.svg?react";
import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import theme from "@root/styles/theme";
import { Editor } from "@tiptap/react";
import React, { memo, useMemo,useState } from "react";
import { useTranslation } from "react-i18next";

import { featureTitle, TextGroupPlusMenu } from "../SVBubble/TextGroup";
import { EditorBlocksValue } from "../types";
import ImageMenuItem from "./ImageMenuItem";
import { useStyles } from "./styles";
import TableMenuItem from "./TableMenuItem";

type Props = {
  id: string;
  editor: Editor | null;
  editorBlocks: EditorBlocksValue;
  selected: boolean;
  setSelected: React.Dispatch<React.SetStateAction<boolean>>;
  clickOneBlockAddAction: (oneBlockValue?: string) => void;
  clickTwoBlockAddAction: () => void;
  clickUpAction: () => void;
  clickDownAction: () => void;
  clickDeleteAction: () => void;
};

const PlusButtonMenu = ({
  id,
  editor,
  editorBlocks,
  selected,
  setSelected,
  clickOneBlockAddAction,
  clickTwoBlockAddAction,
  clickUpAction,
  clickDownAction,
  clickDeleteAction,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation("articles");
  const [, setFeature] = useState<string>(featureTitle[0]);
  const [dragInidicatorAnchorEl, setDragIndicatorAnchorEl] =
    useState<null | HTMLElement>(null);

  const plusButtonMenuStyles = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDragInidicator = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setDragIndicatorAnchorEl(event.currentTarget);
  };
  const handleCloseDragInidicator = () => {
    setDragIndicatorAnchorEl(null);
  };

  const blockSize = useMemo(
    () =>
      editorBlocks
        .map((item) => {
          if (Array.isArray(item.content)) {
            return item.content.map((data) => data.id);
          }
          return item.id;
        })
        .flat().length,
    [editorBlocks],
  );

  return (
    <div
      className={plusButtonMenuStyles.AddButtonWrapper}
      onMouseLeave={() => {
        setSelected(false);
      }}
    >
      <IconButton
        aria-label="before-add-block"
        onClick={handleClick}
        className={plusButtonMenuStyles.DragIndicatorIcon}
        size="large"
      >
        <StyledTooltip title={t("addBlock")} placement="top">
          <AddCircleIcon htmlColor={theme.palette.tertiary.mainDark} />
        </StyledTooltip>
      </IconButton>
      {blockSize > 1 && (
        <>
          <Menu
            anchorEl={dragInidicatorAnchorEl}
            keepMounted
            open={Boolean(dragInidicatorAnchorEl)}
            onClose={handleCloseDragInidicator}
            classes={{
              list: plusButtonMenuStyles.DragIndicatorIcon,
            }}
          >
            <ButtonGroup color="primary" aria-label="text primary button group">
              <Button
                onClick={clickUpAction}
                className={plusButtonMenuStyles.DragIndicatorMenu}
              >
                <ButtonUp />
              </Button>
              <Button
                onClick={clickDownAction}
                className={plusButtonMenuStyles.DragIndicatorMenu}
              >
                <ButtonDown />
              </Button>

              <Button
                onClick={clickDeleteAction}
                className={plusButtonMenuStyles.DragIndicatorMenu}
              >
                <Tooltip title={t("delete")} placement="bottom">
                  <Trash />
                </Tooltip>
              </Button>
            </ButtonGroup>
          </Menu>
          <IconButton
            aria-label="indicator-block"
            onClick={(e) => {
              setSelected(!selected);
              handleClickDragInidicator(e);
            }}
            className={plusButtonMenuStyles.DragIndicatorIcon}
            size="large"
          >
            <DragIndicatorIcon htmlColor={theme.palette.tertiary.mainDark} />
          </IconButton>
        </>
      )}
      {editor && (
        <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              clickOneBlockAddAction();
              handleClose();
            }}
            classes={{ root: plusButtonMenuStyles.MenuItem }}
          >
            <ListItemIcon className={plusButtonMenuStyles.ListItemIconStyle}>
              <OneBlock />
            </ListItemIcon>

            <ListItemText
              primary={t("firstRowBlock")}
              style={{ fontSize: 16 }}
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              clickTwoBlockAddAction();
              handleClose();
            }}
            classes={{ root: plusButtonMenuStyles.MenuItem }}
          >
            <ListItemIcon className={plusButtonMenuStyles.ListItemIconStyle}>
              <OneBlock width={10} />
              <OneBlock width={10} />
            </ListItemIcon>

            <ListItemText
              primary={t("secondRowBlock")}
              style={{ fontSize: 16 }}
            />
          </MenuItem>

          <Divider classes={{ root: plusButtonMenuStyles.divider }} />

          <TextGroupPlusMenu
            editor={editor}
            handleClose={handleClose}
            setFeature={setFeature}
            clickOneBlockAddAction={clickOneBlockAddAction}
          />

          <Divider classes={{ root: plusButtonMenuStyles.divider }} />

          <ImageMenuItem editor={editor} handleClose={handleClose} />
          <TableMenuItem editor={editor} handleClose={handleClose} />
        </Menu>
      )}
    </div>
  );
};

export default memo(PlusButtonMenu);
