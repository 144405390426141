import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import isTouchDevice from "@lib/responsive-utils";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import React from "react";

export type Placement =
  | "bottom"
  | "left"
  | "right"
  | "top"
  | "bottom-end"
  | "bottom-start"
  | "left-end"
  | "left-start"
  | "right-end"
  | "right-start"
  | "top-end"
  | "top-start";

export type Props = {
  title: string;
  placement?: Placement;
  children: React.ReactNode;
} & IconButtonProps;

const V2IconButton: React.FunctionComponent<Props> = ({
  title,
  placement,
  children,
  ...iconProps
}: Props) => {
  return (
    <StyledTooltip title={isTouchDevice() ? "" : title} placement={placement}>
      <IconButton data-cy="icon-button" size="large" {...iconProps}>
        {children}
      </IconButton>
    </StyledTooltip>
  );
};

export default V2IconButton;

V2IconButton.defaultProps = {
  placement: undefined,
};
