import Chip, { ChipProps } from "@mui/material/Chip";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import theme from "@root/styles/theme";
import React, { memo } from "react";

import { getTagColor, getTextColor } from "./utils";

const useStyles = makeStyles<Theme, ThemeProps>(() =>
  createStyles({
    main: (props) => ({
      borderRadius: props.borderRadius,
      color: props.color,
      fontWeight: 600,
      fontSize: "0.9rem",
      padding: "3.4px",
      margin: theme.spacing(0.5, 0.4, 0.5, 0.4),
      border:
        props.variant && props.variant === "outlined"
          ? `2px solid ${props.color}`
          : "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.caption.fontSize,
        margin: theme.spacing(0.3, 0.3, 0.3, 0.3),
      },
      backgroundColor: props.backgroundColor,
      "&:hover, &:focus": {
        backgroundColor: props.backgroundColor,
      },
    }),
    fontSmall: (props) => ({
      fontSize: "11px",
      padding: theme.spacing(0.4),
      marginRight: props.avatar ? theme.spacing(0.5) : "auto",
      marginLeft: props.avatar ? theme.spacing(0.5) : "auto",
    }),
  }),
);

type ThemeProps = {
  borderRadius: number;
  backgroundColor: string;
  color: string;
  avatar?: React.ReactElement;
  variant?: string;
};

export enum StyledTagColor {
  Green = "green",
  BrightGreen = "brightGreen",
  Blue = "blue",
  Orange = "orange",
  LightBlue = "lightBlue",
  Grey = "grey",
  Yellow = "yellow",
  Pink = "pink",
  LightPink = "lightPink",
  LightGrey = "lightGrey",
  LightOpacityGrey = "lightOpacityGrey",
  Azure = "azure",
  LightRed = "lightRed",
  White = "white",
  Black = "black",
}

export enum StyledTextColor {
  LightGreen = "lightGreen",
  Blue = "blue",
  Green = "green",
  White = "white",
  Black = "black",
  Orange = "orange",
  Grey = "grey",
  LightRed = "lightRed",
  Azure = "azure",
}
export interface StyledTagProps extends ChipProps {
  tagColor?: StyledTagColor;
  textColor?: StyledTextColor;
  fontSize?: "default" | "small";
  avatar?: React.ReactElement;
  borderRadius?: number;
  clickable?: boolean;
  onClick?: React.MouseEventHandler<any>;
}

export const StyledTag: React.FC<StyledTagProps> = memo(
  ({
    fontSize,
    tagColor,
    textColor,
    avatar,
    borderRadius = 8,
    variant,
    ...args
  }) => {
    const classes = useStyles({
      borderRadius,
      backgroundColor: getTagColor(tagColor, variant),
      color: getTextColor(textColor),
      avatar,
      variant,
    });

    return (
      <Chip
        data-cy="styled-tag"
        classes={{
          root: classes.main,
          label: `${fontSize === "small" && classes.fontSmall}`,
        }}
        avatar={avatar}
        {...args}
      />
    );
  },
);
