import { Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

export const StyledTableContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 6,
      boxShadow: "0px 2px 4px 0px rgba(71,81,73,0.05)",
      marginBottom: theme.spacing(2),
      whiteSpace: "nowrap",
    },
  }),
)(TableContainer);

export const StyledTableThCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: theme.typography.body1.fontSize,
      [theme.breakpoints.down("sm")]: {
        fontWeight: "bold",
        fontSize: theme.typography.caption.fontSize,
        minWidth: 40,
        padding: theme.spacing(2, 1),
      },
    },
  }),
)(TableCell);

export const CommonStyledTableThCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.learningTemplatePackage.main,
      color: theme.palette.customColor.text,
      fontSize: "16px",
      fontWeight: 700,
      whiteSpace: "pre-line",
      [theme.breakpoints.down("sm")]: {
        fontWeight: "bold",
        fontSize: theme.typography.caption.fontSize,
        minWidth: 40,
        padding: theme.spacing(2, 1),
      },
    },
  }),
)(TableCell);

export const AdminTableThCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.action.hover,
      fontWeight: "bold",
      color: theme.palette.customColor.text,
      fontSize: theme.typography.body1.fontSize,
      [theme.breakpoints.down("sm")]: {
        fontWeight: "bold",
        fontSize: theme.typography.caption.fontSize,
        minWidth: 40,
        padding: theme.spacing(2, 1),
      },
    },
  }),
)(TableCell);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "max-content",
      fontSize: theme.typography.body1.fontSize,
      wordWrap: "break-word",
      wordBreak: "break-all",
      whiteSpace: "pre-line",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.caption.fontSize,
        minWidth: 40,
        padding: theme.spacing(2, 1),
      },
    },
  }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

export const DistributedTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.body1.fontSize,
      display: "inlineBlock",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.caption.fontSize,
        minWidth: 40,
        padding: theme.spacing(2, 1),
      },
    },
  }),
)(TableCell);

export const DistributedTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

export const AdminTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
    },
  }),
)(TableRow);

const useStylesAdminTableRow = () => ({
  root: (theme: Theme) => ({
    cursor: "pointer",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  }),
  child: (theme: Theme) => ({
    backgroundColor: theme.palette.common.white, // Set background for child elements
  }),
});

export const HoverClickableAdminTableRow = ({ ...otherProps }) => {
  const classes = useStylesAdminTableRow();
  return <TableRow sx={[classes.root, classes.child]} {...otherProps} />;
};

export const DisabledAdminTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[300],
    },
  }),
)(TableRow);

export const DisplayAdminTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
    },
  }),
)(TableRow);
