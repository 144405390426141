import useUrlState from "@ahooksjs/use-url-state";
import { ITEMS_PER_PAGE } from "@constants/page";
import {
  getValidPageNumber,
  getValidPerPageNumber,
} from "@lib/url-param-utils";
import { useEffect } from "react";

type FetchDataFunction = (newPage?: number, newPerPage?: number) => void;

const useUrlPagination = (fetchData: FetchDataFunction) => {
  const [paramsQuery, setParamsQuery] = useUrlState({
    page: 1,
    perPage: ITEMS_PER_PAGE,
  });

  useEffect(() => {
    const perPage = parseInt(paramsQuery.perPage, 10) || ITEMS_PER_PAGE;
    const page = parseInt(paramsQuery.page, 10) || 1;
    fetchData(page, perPage);
  }, [paramsQuery.page, paramsQuery.perPage]);

  const handlePageTransition = (newPage: number) => {
    setParamsQuery((prev) => ({ ...prev, page: newPage }));
  };

  const handlePerPageChange = (newPerPage: number) => {
    setParamsQuery((prev) => ({ ...prev, page: 1, perPage: newPerPage }));
  };

  return {
    currentPage: getValidPageNumber(paramsQuery.page),
    currentPerPage: getValidPerPageNumber(paramsQuery.perPage, ITEMS_PER_PAGE),
    handlePageTransition,
    handlePerPageChange,
  };
};

export default useUrlPagination;
