/* eslint-disable camelcase */
import {
  Api,
  Company,
  CompanyAsSuperOwner,
  FetchCompaniesAsSuperOwnerParams,
  Option,
  StudentInputSetting,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchCompaniesAsSuperOwnerArg {
  params?: FetchCompaniesAsSuperOwnerParams;
  inModal?: boolean;
}

// Fetch copanies connted to the super owner company
// Can filter with company_tags
export const fetchCompaniesAsSuperOwner = createAsyncThunk<
  {
    companies: CompanyAsSuperOwner[];
    total_count: number;
  },
  FetchCompaniesAsSuperOwnerArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/fetch", async ({ params }, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.fetchCompaniesAsSuperOwner({
      ...params,
    });
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchSuperOwnerCompany = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/fetchOne", async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.fetchCompanyAsSuperOwner(id);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchSuperOwnerMaterialCapacity = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/material_capacity", async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.getMaterialCapacityAsSuperOwner(id);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export type CreateCompanyParams = {
  // index used in batch create when uploading CSV
  index?: number;
  name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  logo?: string;
  report_recipient_email?: string;
  student_input_settings?: StudentInputSetting;
  unique_id?: string;
};

export const createCompanyAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/create", async (data, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.createCompanyAsSuperOwner(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      error: err.response.data,
      requestIndex: data.index,
    });
  }
});

export type UpdateCompanyParams = {
  id: string;
  data: {
    name?: string;
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    email?: string;
    logo?: string;
    report_recipient_email?: string;
    report_the_number_of_users_email?: string;
    report_homework_template_distribution_histories_email?: string;
    inquiry_email?: string;
    student_input_settings?: StudentInputSetting;
    unique_id?: string;
    limited_user_tag_monthly_report_email?: string;
    reviewer_company_group_id?: string;
    options?: Option;
  };
};

export const updateCompanyAsSuperOwner = createAsyncThunk<
  Company,
  UpdateCompanyParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/update", async (args, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.updateCompanyAsSuperOwner(
      args.id,
      args.data,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const deleteCompanyAsSuperOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/delete", async (id, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.deleteCompanyAsSuperOwner(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetState = createAction("super_owner/companies/resetState");

export const resetModalState = createAction(
  "super_owner/companies/resetModalState",
);

export const resetBatchCreateState = createAction(
  "super_owner/companies/resetBatchCreateState",
);
