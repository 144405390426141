import { enqueueToast } from "@actions/toast";
import FolderIcon from "@assets/images/svg/folderPen.svg?react";
import Section from "@components/UI/organisms/Layout/SectionWrapper";
import { StudyLogBase } from "@lib/Api";
import { Box, Theme,Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { teacherAppStudentLogsState } from "@reducers/teacherApp/studentLogs";
import { nanoid } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  handlePageRedirection: (log: StudyLogBase) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainWrap: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1, 0),
      gap: theme.spacing(2),
    },
    body: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      width: "100%",
      height: "100%",
      margin: "2px 0",
    },
    icon: {
      width: "19.5px",
      height: "19.5px",
    },
    date: {
      marginLeft: "10px",
      whiteSpace: "nowrap",
    },
    modalLink: {
      cursor: "pointer",
      marginLeft: theme.spacing(2),
      fontSize: "1rem",
      textDecoration: "underline",
      textUnderlineOffset: "3px",
      color: theme.palette.tertiary.darkGreen,
      textDecorationColor: theme.palette.tertiary.darkGreen,
      "&:hover": {
        backgroundColor: theme.palette.quaternary.lightGrey,
      },
    },
  }),
);

const LogsByStudent: React.FC<Props> = ({ handlePageRedirection }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["study_logs", "common"]);
  const { studyLogs, fetchingLogs, fetchedLogs, fetchLogsError } =
    useAppSelector(teacherAppStudentLogsState);

  let content: NonNullable<React.ReactNode> & React.ReactNode = <></>;
  if (!fetchingLogs && !fetchLogsError && fetchedLogs && studyLogs.length > 0) {
    content = (
      <>
        <Box className={classes.mainWrap}>
          {studyLogs.map((item: StudyLogBase) => {
            if (item) {
              const { id, created_at: createdAt } = item;
              return (
                <Box key={id} className={classes.body}>
                  <span>
                    <FolderIcon className={classes.icon} />
                  </span>
                  <Box className={classes.date}>
                    <Typography>
                      {`${dayjs(createdAt).format("YYYY年MM月DD日 HH:mm")}`}
                    </Typography>
                  </Box>
                  <Typography
                    onClick={() => handlePageRedirection(item)}
                    className={classes.modalLink}
                    data-cy="logs-by-student-log"
                  >
                    {item.log}
                  </Typography>
                </Box>
              );
            }
            return null;
          })}
        </Box>
      </>
    );
  } else if (!fetchingLogs && fetchedLogs && studyLogs.length === 0) {
    if (fetchLogsError) {
      dispatch(
        enqueueToast({
          message: fetchLogsError,
          id: nanoid(),
          options: {
            variant: "error",
          },
        }),
      );
    }
    content = (
      <Box m={2}>
        <Typography>{t("common:noData")}</Typography>
      </Box>
    );
  }
  return <Section title={t("allLogs")}>{content}</Section>;
};

export default LogsByStudent;
