/* eslint-disable camelcase */
import {
  CommonNotification,
  NewNotifications,
} from "@actions/types/notification";
import HomeworkIcon from "@assets/images/svg/homework_notification.svg?react";
import MaterialIcon from "@assets/images/svg/material-icon-grey.svg?react";
import NoticeIcon from "@assets/images/svg/notification.svg?react";
import PblIcon from "@assets/images/svg/pbl_notification.svg?react";
import ProgramIcon from "@assets/images/svg/program_icon-grey.svg?react";
import ArticleIcon from "@assets/images/svg/tentBanner.svg?react";
import { roles } from "@constants/roles";
import { useAuth } from "@contexts/Auth";
import {
  CurrentUserInfo,
  NotificationResourceInfo,
  NotificationResourceInfoType,
} from "@lib/Api";
import { safeString } from "@lib/string-utils";
import ForumIcon from "@mui/icons-material/Forum";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  COMMON_ROUTES,
  CONTENT_PROVIDER_ROUTES,
  OWNER_ROUTES,
  REVIEWER_ROUTES,
  STUDENT_ROUTES,
  SUPER_OWNER_ROUTES,
  TEACHER_ROUTES,
} from "@root/constants/routes";
import React, { useCallback } from "react";
import { FaHandshake as Collaboration,FaLock } from "react-icons/fa";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    articleIcon: {
      fill: theme.palette.customColor.text,
      "& > path": {
        fill: theme.palette.customColor.text,
      },
    },
  }),
);

type displayObjType = {
  title: string;
  sender_name: string | undefined;
  notified_time: string;
  message: string;
};

type Props = {
  notification?: NewNotifications | CommonNotification;
  limitedNotification?: NewNotifications | CommonNotification;
};

type CustomProps = {
  states: {
    displayObj: displayObjType;
    link: string | null;
    text: string | null;
  };
  components: { icon?: JSX.Element };
};

type NotificationIconText = {
  icon: JSX.Element;
  text: string | null;
};

const useNotificationAsset = ({
  notification,
  limitedNotification,
}: Props): CustomProps => {
  const classes = useStyles();
  const currentUser = useAuth().currentUser as CurrentUserInfo;
  const linkTitle = {
    DETAIL: "詳細へ",
    PROGRAM_LIST: "プログラム一覧へ",
    DISTRIBUTION_HISTORY: "配信履歴へ",
    USERS: "利用者一覧へ",
  } as const;

  const defaultValues = {
    icon: <NoticeIcon />,
    text: null,
    link: null,
  };

  const getNotificationAsset = useCallback(
    (
      resource_info: string | null,
    ): NotificationIconText & {
      link: string | null;
    } => {
      if (!resource_info) return defaultValues;
      const info: NotificationResourceInfo = JSON.parse(resource_info);
      if (!info) return defaultValues;

      let defaultInquiryLink = {
        icon: <NoticeIcon />,
        text: linkTitle.DETAIL,
        roleToLink: {
          [`${roles.STUDENT}`]: `${COMMON_ROUTES.INQUIRIES}/${info.id}`,
        },
      };
      Object.values(roles).forEach((role) => {
        defaultInquiryLink = {
          ...defaultInquiryLink,
          roleToLink: {
            ...defaultInquiryLink.roleToLink,
            [`${role}`]: `${COMMON_ROUTES.INQUIRIES}/${info.id}`,
          },
        };
      });

      const notificationAssetMapper: Record<
        NotificationResourceInfoType,
        NotificationIconText & { roleToLink: Record<string, string> | null }
      > = {
        User: {
          icon: <FaLock style={{ fontSize: 24 }} />,
          text: linkTitle.USERS,
          roleToLink: {
            [`${roles.TEACHER}`]: `${TEACHER_ROUTES.USERS}?type=student`,
          },
        },
        Article: {
          icon: <ArticleIcon className={classes.articleIcon} />,
          text: linkTitle.DETAIL,
          roleToLink: {
            [`${roles.CONTENT_PROVIDER}`]: `${CONTENT_PROVIDER_ROUTES.ARTICLES}/${info.id}`,
            [`${roles.TEACHER}`]: `${TEACHER_ROUTES.ARTICLES}/${info.id}`,
          },
        },
        "Common::Inquiry": {
          ...defaultInquiryLink,
          icon: <ForumIcon />,
          text: linkTitle.DETAIL,
          roleToLink: {
            [`${roles.SUPER_OWNER}`]: `${SUPER_OWNER_ROUTES.INQUIRIES}/${info.id}`,
          },
        },
        Homework: {
          icon: <HomeworkIcon />,
          text: linkTitle.DETAIL,
          roleToLink: {
            [`${roles.STUDENT}`]: `${STUDENT_ROUTES.HOMEWORK}/${info.id}`,
            [`${roles.TEACHER}`]: `${TEACHER_ROUTES.HOMEWORK_TEMPLATES}/${info.template_id}`,
            [`${roles.OWNER}`]: `${OWNER_ROUTES.HOMEWORK_TEMPLATES}/${info.template_id}`,
            [`${roles.SUPER_OWNER}`]: `${SUPER_OWNER_ROUTES.HOMEWORK_TEMPLATES}/${info.template_id}`,
            [`${roles.REVIEWER}`]: `${REVIEWER_ROUTES.HOMEWORK_TEMPLATES}/${info.distribution_id}/homeworks/${info.id}`,
          },
        },
        HomeworkTemplateDistribution: {
          icon: <HomeworkIcon />,
          text: linkTitle.DETAIL,
          roleToLink: {
            [`${roles.STUDENT}`]: `${STUDENT_ROUTES.HOMEWORK}/${info.id}`,
            [`${roles.TEACHER}`]: `${TEACHER_ROUTES.HOMEWORK_TEMPLATES}/${info.template_id}`,
            [`${roles.OWNER}`]: `${OWNER_ROUTES.HOMEWORK_TEMPLATES}/${info.template_id}`,
            [`${roles.SUPER_OWNER}`]: `${SUPER_OWNER_ROUTES.HOMEWORK_TEMPLATES}/${info.template_id}`,
            [`${roles.REVIEWER}`]: `${REVIEWER_ROUTES.HOMEWORK_TEMPLATES}/${info.id}`,
          },
        },
        LearningTemplatePackage: {
          icon: <ProgramIcon />,
          text: linkTitle.PROGRAM_LIST,
          roleToLink: {
            [`${roles.STUDENT}`]: `${STUDENT_ROUTES.LEARNING_PACKAGES}`,
            [`${roles.TEACHER}`]: `${TEACHER_ROUTES.LEARNING_PACKAGES}`,
            [`${roles.OWNER}`]: `${OWNER_ROUTES.LEARNING_PACKAGES}`,
            [`${roles.SUPER_OWNER}`]: `${SUPER_OWNER_ROUTES.LEARNING_PACKAGES}`,
          },
        },
        Material: {
          icon: <MaterialIcon />,
          text: linkTitle.DETAIL,
          roleToLink: {
            [`${roles.OWNER}`]: `${OWNER_ROUTES.MATERIAL_DETAILS}/${info.id}`,
            [`${roles.TEACHER}`]: `${TEACHER_ROUTES.MATERIAL_DISTRIBUTIONS}/${info.id}`,
          },
        },
        MaterialWork: {
          icon: <MaterialIcon />,
          text: linkTitle.DETAIL,
          roleToLink: {
            [`${roles.STUDENT}`]: `${STUDENT_ROUTES.MATERIAL_WORKS}/${info.id}`,
            [`${roles.SUPER_OWNER}`]: `${SUPER_OWNER_ROUTES.MATERIALS}/${info.id}`,
          },
        },
        MaterialDistributionHistoryGroup: {
          icon: <MaterialIcon />,
          text: linkTitle.DISTRIBUTION_HISTORY,
          roleToLink: {
            [`${roles.OWNER}`]: `${OWNER_ROUTES.MATERIAL_DISTRIBUTIONS}#History`,
            [`${roles.TEACHER}`]: `${TEACHER_ROUTES.MATERIAL_DISTRIBUTIONS}`,
            [`${roles.SUPER_OWNER}`]: `${SUPER_OWNER_ROUTES.MATERIALS}#distributions`,
          },
        },
        "Pbl::Report::Answer": {
          icon: <PblIcon />,
          text: linkTitle.DETAIL,
          roleToLink: {
            [`${roles.STUDENT}`]: `${STUDENT_ROUTES.PBL_REPORTS}/${info.id}`,
          },
        },
        "Gogo::Request": {
          icon: <Collaboration style={{ fontSize: "25px" }} />,
          text: linkTitle.DETAIL,
          roleToLink: {
            [`${roles.TEACHER}`]: TEACHER_ROUTES.CORPORATE_COLLABORATION,
            [`${roles.CONTENT_PROVIDER}`]:
              CONTENT_PROVIDER_ROUTES.CORPORATE_COLLABORATION,
          },
        },
      };

      const result = notificationAssetMapper[info.type];

      return {
        ...result,
        link: result?.roleToLink?.[currentUser.current_role] ?? null,
      };
    },
    [currentUser],
  );

  const { icon, text, link } = getNotificationAsset(
    notification?.resource_info ?? null,
  );

  let displayObj: displayObjType;
  if (notification) {
    displayObj = {
      title: safeString(notification.title),
      sender_name: safeString(notification?.sender?.display_name),
      notified_time: safeString(notification.notified_time),
      message: safeString(notification.message),
    };
  } else if (limitedNotification) {
    displayObj = {
      title: safeString(limitedNotification.title),
      sender_name: undefined,
      notified_time: `${limitedNotification.start_time}〜${limitedNotification.end_time}`,
      message: safeString(limitedNotification.message),
    };
  } else {
    displayObj = {
      title: "",
      sender_name: undefined,
      notified_time: "",
      message: "",
    };
  }

  return {
    states: {
      displayObj,
      text,
      link,
    },
    components: { icon },
  };
};

export default useNotificationAsset;
