import {
  deleteLimitedTimeNotification,
  deleteNotificationAsSuperOwner,
  fetchLimitedTimeNotificationsAsSuperOwner,
  fetchNotificationsSentAsSuperOwner,
  postLimitedTimeNotificationAsSuperOwner,
  postNotificationAsSuperOwner,
  putLimitedTimeNotificationAsSuperOwner,
  putNotificationAsSuperOwner,
  resetFormState,
} from "@actions/superOwnerApp/notification";
import { NotificationSent } from "@actions/types/notification";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { LimitedTimeNotificationList } from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

interface NotificationState {
  fetching: boolean;
  fetched: boolean;
  posting: boolean;
  posted: boolean;
  deleting: boolean;
  deleted: boolean;
  updating: boolean;
  updated: boolean;
  limitedNotificationList: LimitedTimeNotificationList[];
  notificationsSent: NotificationSent[];
  fetchError: string | null;
  postError: string | null;
  deleteError: string | null;
  updateError: string | null;
  totalCountSent: number;
  totalCountLimited: number;
  LimitedTimeNotificationError: string | null;
  fetchLimitedTimeNotifications: ReduxStatusType;
  createLimitedTimeNotification: ReduxStatusType;
  createLimitedTimeNotificationError: string | null;
  deleteLimitedTimeNotification: ReduxStatusType;
  deleteLimitedTimeNotificationError: string | null;
  putLimitedTimeNotification: ReduxStatusType;
  putLimitedTimeNotificationError: string | null;
}

const initialState: NotificationState = {
  fetching: false,
  fetched: false,
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  posting: false,
  posted: false,
  limitedNotificationList: [],
  notificationsSent: [],
  fetchError: null,
  postError: null,
  updateError: null,
  deleteError: null,
  totalCountSent: 0,
  totalCountLimited: 0,
  LimitedTimeNotificationError: null,
  fetchLimitedTimeNotifications: ReduxStatus.idle,
  createLimitedTimeNotification: ReduxStatus.idle,
  createLimitedTimeNotificationError: null,
  deleteLimitedTimeNotification: ReduxStatus.idle,
  deleteLimitedTimeNotificationError: null,
  putLimitedTimeNotification: ReduxStatus.idle,
  putLimitedTimeNotificationError: null,
};

const notificationSlice = createSlice({
  name: "SuperOwner/NotificationSent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsSentAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          notificationsSent: [],
          fetchError: null,
        };
      })
      .addCase(
        fetchNotificationsSentAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            notificationsSent: action.payload.notifications,
            totalCountSent: action.payload.total_count,
          };
        },
      )
      .addCase(fetchNotificationsSentAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(
        fetchLimitedTimeNotificationsAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetchLimitedTimeNotifications: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetchLimitedTimeNotificationsAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchLimitedTimeNotifications: ReduxStatus.fulfilled,
            limitedNotificationList: payload.limited_time_notifications,
            totalCountLimited: payload.total_count,
          };
        },
      )
      .addCase(
        fetchLimitedTimeNotificationsAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetchLimitedTimeNotifications: ReduxStatus.rejected,
            LimitedTimeNotificationError: errorWithMessage(action.payload),
          };
        },
      )
      .addCase(
        postLimitedTimeNotificationAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            createLimitedTimeNotification: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        postLimitedTimeNotificationAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            createLimitedTimeNotification: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        postLimitedTimeNotificationAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            createLimitedTimeNotification: ReduxStatus.rejected,
            createLimitedTimeNotificationError: errorWithMessage(
              action.payload,
            ),
          };
        },
      )
      .addCase(
        putLimitedTimeNotificationAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            putLimitedTimeNotification: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        putLimitedTimeNotificationAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            putLimitedTimeNotification: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        putLimitedTimeNotificationAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            putLimitedTimeNotification: ReduxStatus.rejected,
            putLimitedTimeNotificationError: errorWithMessage(action.payload),
          };
        },
      )
      .addCase(postNotificationAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          posting: true,
          posted: false,
          postError: null,
        };
      })
      .addCase(postNotificationAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
        };
      })
      .addCase(postNotificationAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          postError: errorWithMessage(action.payload),
        };
      })
      .addCase(putNotificationAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          updateError: null,
        };
      })
      .addCase(putNotificationAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
        };
      })
      .addCase(putNotificationAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updateError: errorWithMessage(action.payload),
        };
      })
      .addCase(deleteNotificationAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          deleteError: null,
        };
      })
      .addCase(deleteNotificationAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: true,
        };
      })
      .addCase(deleteNotificationAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleteError: errorWithMessage(action.payload),
        };
      })
      .addCase(deleteLimitedTimeNotification.pending, (state, action) => {
        return {
          ...state,
          deleteLimitedTimeNotification: ReduxStatus.pending,
        };
      })
      .addCase(deleteLimitedTimeNotification.fulfilled, (state, action) => {
        return {
          ...state,
          deleteLimitedTimeNotification: ReduxStatus.fulfilled,
        };
      })
      .addCase(deleteLimitedTimeNotification.rejected, (state, action) => {
        return {
          ...state,
          deleteLimitedTimeNotification: ReduxStatus.rejected,
          deleteLimitedTimeNotificationError: errorWithMessage(action.payload),
        };
      })
      .addCase(resetFormState, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postError: null,
          deleting: false,
          deleted: false,
          deleteError: null,
          updating: false,
          updated: false,
          updateError: null,
          createLimitedTimeNotification: ReduxStatus.idle,
          createLimitedTimeNotificationError: null,
          deleteLimitedTimeNotification: ReduxStatus.idle,
          deleteLimitedTimeNotificationError: null,
          putLimitedTimeNotification: ReduxStatus.idle,
          putLimitedTimeNotificationError: null,
        };
      });
  },
});

export const superOwnerAppNotificationState = (
  state: RootState,
): NotificationState => state.superOwnerApp.notification;

export default notificationSlice.reducer;
