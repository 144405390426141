import TableCell from "@tiptap/extension-table-cell";
import { Plugin, PluginKey } from "prosemirror-state";

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
      border: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-border"),
        renderHTML: (attributes) => {
          return {
            "data-border": attributes.border,
            style: `border: ${attributes.border}`,
          };
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("table-cell-paste-plugin"),
        props: {
          transformPastedHTML(html) {
            // Remove ul and li tags from clipboard data
            const htmlToPaste = html.replace(
              /<ul([^>]*)>|<\/ul>|<li([^>]*)>|<\/li>/g,
              "",
            );
            // Prevent pasting images into table cell
            return htmlToPaste.match(/<img.*?>/g) ? "" : htmlToPaste;
          },
        },
      }),
    ];
  },
});

export default CustomTableCell;
