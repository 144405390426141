import {
  deleteIdealBoardAsStudent,
  getIdealBoardAsStudent,
  getIdealBoardForAffiliateAvatarsAsStudent,
  getIdealBoardsAsStudent,
  getIdealBoardsMoreAsStudent,
  postIdealBoardsAsStudent,
  putIdealBoardAsStudent,
  putIdealBoardDataAsStudent,
  resetStudentAppIdealModalState,
  resetStudentAppIdealState,
  updateIdealBoardHeaderAsStudent,
  updateIdealBoardOnlineAffiliateIdsAsStudent,
} from "@actions/studentApp/ideal/board";
import { Board, BoardList } from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice, current } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface StudentAppIdealBoardState {
  fetchIdeals: ReduxStatusType;
  fetchMoreIdeals: ReduxStatusType;
  fetchIdeal: ReduxStatusType;
  fetchIdealForAffiliateAvatars: ReduxStatusType;
  postIdeal: ReduxStatusType;
  putIdeal: ReduxStatusType;
  putIdealData: ReduxStatusType;
  deleteIdeal: ReduxStatusType;
  postIdealError: string | null;
  putIdealError: string | null;
  putIdealDataError: string | null;
  deleteIdealError: string | null;
  boards: BoardList[];
  board: Board | null;
  totalCount: number;
  onlineAffiliateIds: string[];
}

export const initialState: StudentAppIdealBoardState = {
  fetchIdeals: ReduxStatus.idle,
  fetchMoreIdeals: ReduxStatus.idle,
  fetchIdeal: ReduxStatus.idle,
  fetchIdealForAffiliateAvatars: ReduxStatus.idle,
  postIdeal: ReduxStatus.idle,
  putIdeal: ReduxStatus.idle,
  putIdealData: ReduxStatus.idle,
  deleteIdeal: ReduxStatus.idle,
  postIdealError: null,
  putIdealError: null,
  putIdealDataError: null,
  deleteIdealError: null,
  boards: [],
  board: null,
  totalCount: 0,
  onlineAffiliateIds: [],
};

const StudentAppIdealBoardSlice = createSlice({
  name: "studentApp/IdealBoard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIdealBoardsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchIdeals: ReduxStatus.pending,
        };
      })
      .addCase(getIdealBoardsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchIdeals: ReduxStatus.rejected,
          boards: [],
        };
      })
      .addCase(getIdealBoardsAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchIdeals: ReduxStatus.fulfilled,
          boards: payload.boards,
          totalCount: payload.total_count,
        };
      })
      .addCase(getIdealBoardsMoreAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchMoreIdeals: ReduxStatus.pending,
        };
      })
      .addCase(getIdealBoardsMoreAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchMoreIdeals: ReduxStatus.rejected,
          boards: [],
        };
      })
      .addCase(getIdealBoardsMoreAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          fetchMoreIdeals: ReduxStatus.fulfilled,
          boards: [...currentState.boards, ...payload.boards],
          totalCount: payload.total_count,
        };
      })
      .addCase(getIdealBoardAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchIdeal: ReduxStatus.pending,
        };
      })
      .addCase(getIdealBoardAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchIdeal: ReduxStatus.rejected,
          board: null,
        };
      })
      .addCase(getIdealBoardAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchIdeal: ReduxStatus.fulfilled,
          board: payload,
        };
      })
      .addCase(
        getIdealBoardForAffiliateAvatarsAsStudent.pending,
        (state, _action) => {
          return {
            ...state,
            fetchIdealForAffiliateAvatars: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getIdealBoardForAffiliateAvatarsAsStudent.rejected,
        (state, action) => {
          return {
            ...state,
            fetchIdealForAffiliateAvatars: ReduxStatus.rejected,
            board: null,
          };
        },
      )
      .addCase(
        getIdealBoardForAffiliateAvatarsAsStudent.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          if (currentState.board) {
            return {
              ...state,
              fetchIdealForAffiliateAvatars: ReduxStatus.fulfilled,
              board: {
                ...currentState.board,
                invited_affiliates: payload.invited_affiliates,
              },
            };
          }
          return {
            ...state,
            fetchIdealForAffiliateAvatars: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(postIdealBoardsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          postIdeal: ReduxStatus.pending,
          postIdealError: null,
        };
      })
      .addCase(postIdealBoardsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          postIdeal: ReduxStatus.rejected,
          board: null,
          postIdealError: errorWithMessage(action.payload),
        };
      })
      .addCase(postIdealBoardsAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          postIdeal: ReduxStatus.fulfilled,
          board: payload,
          postIdealError: null,
        };
      })
      .addCase(putIdealBoardAsStudent.pending, (state, _action) => {
        return {
          ...state,
          putIdeal: ReduxStatus.pending,
          putIdealError: null,
        };
      })
      .addCase(putIdealBoardAsStudent.rejected, (state, action) => {
        return {
          ...state,
          putIdeal: ReduxStatus.rejected,
          putIdealError: errorWithMessage(action.payload),
        };
      })
      .addCase(putIdealBoardAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          putIdeal: ReduxStatus.fulfilled,
          putIdealError: null,
        };
      })
      .addCase(putIdealBoardDataAsStudent.pending, (state, _action) => {
        return {
          ...state,
          putIdealData: ReduxStatus.pending,
          putIdealDataError: null,
        };
      })
      .addCase(putIdealBoardDataAsStudent.rejected, (state, action) => {
        return {
          ...state,
          putIdealData: ReduxStatus.rejected,
          putIdealDataError: errorWithMessage(action.payload),
        };
      })
      .addCase(putIdealBoardDataAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          putIdealData: ReduxStatus.fulfilled,
          putIdealDataError: null,
        };
      })
      .addCase(deleteIdealBoardAsStudent.pending, (state, _action) => {
        return {
          ...state,
          deleteIdeal: ReduxStatus.pending,
          deleteIdealError: null,
        };
      })
      .addCase(deleteIdealBoardAsStudent.rejected, (state, action) => {
        return {
          ...state,
          deleteIdeal: ReduxStatus.rejected,
          deleteIdealError: errorWithMessage(action.payload),
        };
      })
      .addCase(deleteIdealBoardAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          deleteIdeal: ReduxStatus.fulfilled,
          deleteIdealError: null,
        };
      })
      .addCase(resetStudentAppIdealState, (state, _action) => {
        return {
          ...state,
          fetchIdeals: ReduxStatus.idle,
          fetchMoreIdeals: ReduxStatus.idle,
          boards: [],
          board: null,
        };
      })
      .addCase(resetStudentAppIdealModalState, (state, _action) => {
        return {
          ...state,
          postIdeal: ReduxStatus.idle,
          postIdealError: null,
          putIdeal: ReduxStatus.idle,
          putIdealError: null,
          deleteIdeal: ReduxStatus.idle,
          deleteIdealError: null,
        };
      })
      .addCase(updateIdealBoardOnlineAffiliateIdsAsStudent, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          onlineAffiliateIds: payload,
        };
      })
      .addCase(updateIdealBoardHeaderAsStudent, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        if (currentState.board) {
          return {
            ...state,
            board: {
              ...currentState.board,
              name: payload.name,
            },
          };
        }
        return {
          ...state,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppIdealBoardState = (
  state: RootState,
): StudentAppIdealBoardState => state.studentApp.idealBoard;

export default StudentAppIdealBoardSlice.reducer;
