/* eslint-disable camelcase */
import {
  Api,
  GetPblBoardCampaignRequestAsContentProviderParams,
  PblBoardCampaignRequestList,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { PutPblBoardCampaignRequestParams } from "@root/types/PblBoard/Campaign";
import { RootState } from "@store/store";

const api = new Api();

export const getPblBoardCampaignRequestAsContentProvider = createAsyncThunk<
  {
    board_campaign_requests: PblBoardCampaignRequestList[];
    total_count: number;
  },
  GetPblBoardCampaignRequestAsContentProviderParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/board/campaigns/requests/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getPblBoardCampaignRequestAsContentProvider(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const putPblBoardCampaignRequestAsContentProvider = createAsyncThunk<
  any,
  PutPblBoardCampaignRequestParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/board/campaigns/requests/put",
  async (params, { rejectWithValue }) => {
    const { campaign_id, id, data } = params;
    try {
      const response =
        await api.contentProvider.putPblBoardCampaignRequestAsContentProvider(
          campaign_id,
          id,
          data,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export type CampaignRequestSequenceParams = {
  campaign_id: string;
  id: string;
  data: { sequence: number };
};

export const putPblBoardCampaignRequestSequenceAsContentProvider =
  createAsyncThunk<
    any,
    CampaignRequestSequenceParams,
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    "contentProvider/pbl/board/campaigns/requests/put/sequence",
    async (params, { rejectWithValue }) => {
      const { campaign_id, id, data } = params;
      try {
        const response =
          await api.contentProvider.putPblBoardCampaignRequestSequenceAsContentProvider(
            campaign_id,
            id,
            data,
          );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    },
  );

export const resetContentProviderAppPblBoardCampaignRequestState = createAction(
  "contentProviderApp/pbl/board/resetPblBoardCampaignRequestState",
);

export const resetContentProviderAppPblBoardCampaignRequestModalState =
  createAction(
    "contentProviderApp/pbl/board/resetPblBoardCampaignRequestModalState",
  );

export const pblBoardCampaignRequestSequenceSuccess = createAction(
  "ownerApp/pbl/board/campaignRequestSequenceSuccess",
);

export const pblBoardCampaignRequestSequenceRejected = createAction(
  "ownerApp/pbl/board/campaignRequestSequenceRejected",
);
