/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
import {
  Api,
  ListMaterialDistributionHistoryAsSuperOwnerParams,
  ListShowMaterialDistributionHistoryAsSuperOwnerParams,
  MaterialDistributionHistory,
  SearchByUserIdMaterialDistributionHistoryAsSuperOwnerParams,
} from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

export const fetchAllDistributedMaterialWorks = createAsyncThunk<
  {
    material_distribution_histories: MaterialDistributionHistory[];
    total_count: number;
  },
  ListMaterialDistributionHistoryAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/fetchAllDistributedMaterialWork/",
  async (
    params,

    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.listMaterialDistributionHistoryAsSuperOwner(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchDistributedMaterialWorksForCsv = createAsyncThunk<
  GetResponseType<
    Api["superOwner"]["listMaterialDistributionHistoryAsSuperOwner"]
  >,
  ListMaterialDistributionHistoryAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/fetchDistributedMaterialWorksForCsv",
  async (
    params: ListMaterialDistributionHistoryAsSuperOwnerParams,

    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.listMaterialDistributionHistoryAsSuperOwner(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const searchDistributedHistoryByUserId = createAsyncThunk<
  GetResponseType<
    Api["superOwner"]["searchByUserIdMaterialDistributionHistoryAsSuperOwner"]
  >,
  SearchByUserIdMaterialDistributionHistoryAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/fetchDistributedMaterialWorkDetail",
  async (
    params,

    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.searchByUserIdMaterialDistributionHistoryAsSuperOwner(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchDistributedSchoolsByMaterial = createAsyncThunk<
  GetResponseType<
    Api["superOwner"]["listMaterialDistributionHistoryAsSuperOwner"]
  >,
  ListMaterialDistributionHistoryAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/schoolsDistributedByMaterial/fetch",
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.listMaterialDistributionHistoryAsSuperOwner(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchDistributedMaterialHistoryByMaterial = createAsyncThunk<
  GetResponseType<
    Api["superOwner"]["listShowMaterialDistributionHistoryAsSuperOwner"]
  >,
  ListShowMaterialDistributionHistoryAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/materialDistributionHistory/fetchByMaterial",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.listShowMaterialDistributionHistoryAsSuperOwner(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
