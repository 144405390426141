import SuperOwnerLearningPackageTemplate from "@components/UI/templates/SuperOwnerAppTemplate/SuperOwnerLearningPackageTemplate";
import { SUPER_OWNER_ROUTES } from "@constants/routes";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";

import LearningTemplateHistoryContainer from "./LearningTemplateHistoryContainer";
import LearningTemplateListContainer from "./LearningTemplateListContainer";

export enum ContentType {
  PACKAGE = "package",
  PACKAGE_HISTORY = "package_history",
}

const LearningTemplatePackageContainer: React.FC = () => {
  const location = useLocation();
  const { replace } = useHistory();

  const initialTab =
    location.hash === "#history"
      ? ContentType.PACKAGE_HISTORY
      : ContentType.PACKAGE;
  const [detailView, setDetailView] = useState<ContentType>(initialTab);

  const tabClickHandler = (value: string) => {
    if (value === ContentType.PACKAGE) {
      setDetailView(ContentType.PACKAGE);
      replace(SUPER_OWNER_ROUTES.LEARNING_PACKAGES);
    } else {
      setDetailView(ContentType.PACKAGE_HISTORY);
      replace(`${SUPER_OWNER_ROUTES.LEARNING_PACKAGES}#history`);
    }
  };

  let content: React.ReactNode = null;
  if (detailView === ContentType.PACKAGE) {
    content = (
      <LearningTemplateListContainer
        detailView={detailView}
        tabClickHandler={tabClickHandler}
      />
    );
  } else {
    content = <LearningTemplateHistoryContainer detailView={detailView} />;
  }

  return (
    <SuperOwnerLearningPackageTemplate
      detailView={detailView}
      tabClickHandler={tabClickHandler}
    >
      {content}
    </SuperOwnerLearningPackageTemplate>
  );
};

export default LearningTemplatePackageContainer;
