import { initialIsRoles,IsRoles } from "@lib/user/get-role-utils";
import Cookies from "js-cookie";
import React, { createContext,useContext, useEffect } from "react";

import { AUTH, AUTH_PROXY } from "../constants/localStorageKeys";
import { useAppDispatch, useAppSelector } from "../hooks";
import { CurrentUserInfo } from "../lib/Api";
import * as AuthActions from "../store/actions/auth";
import { authState } from "../store/reducers/auth";

interface Props {
  children: React.ReactNode;
}

export type Auth = {
  currentUser: CurrentUserInfo | null;
  isAuthenticated: boolean;
  role: string | null;
  showErrorPage: boolean;
  loading: boolean;
  proxy: string | null;
  isCurrentRoles: IsRoles;
};

export const AuthContext = createContext<Auth>({
  currentUser: null,
  isAuthenticated: !!Cookies.get(AUTH.ACCESS_TOKEN),
  role: "",
  showErrorPage: false,
  loading: false,
  proxy: sessionStorage.getItem(AUTH_PROXY.ACCESS_TOKEN) || null,
  isCurrentRoles: initialIsRoles,
});

const AuthProvider: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const authCache = useAppSelector(authState);
  const {
    accessToken,
    role,
    showErrorPage,
    currentUser,
    loadingCurrentUser: loading,
    proxy,
    isCurrentRoles,
  } = authCache;
  const isAuthenticated = !!accessToken;

  useEffect(() => {
    dispatch(AuthActions.authCheckState({}));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAuthenticated,
        role,
        showErrorPage,
        loading,
        proxy,
        isCurrentRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = (): Auth => {
  return useContext(AuthContext);
};
