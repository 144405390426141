/* eslint-disable camelcase */
import {
  Api,
  GetLearningTemplatePackageDistributionHistoriesAsSuperOwnerParams,
  GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwnerParams,
} from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchLearningTemplatePackageDistributionHistories =
  createAsyncThunk<
    any,
    GetLearningTemplatePackageDistributionHistoriesAsSuperOwnerParams,
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    "super_owner/package_distribution_histories/fetch",
    async (args, { rejectWithValue }) => {
      try {
        const response =
          await api.superOwner.getLearningTemplatePackageDistributionHistoriesAsSuperOwner(
            args,
          );
        return response.data as any;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    },
  );

export const fetchLearningTemplatePackageDistributedStudent = createAsyncThunk<
  any,
  GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/package_distributed_user/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.getLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwner(
          args,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
