import { ListItemIcon, ListItemText, MenuItem, Tooltip } from "@mui/material";
import { Editor } from "@tiptap/react";
import React, { memo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiImage } from "react-icons/bi";

import ImageInput from "../Extension/Image/ImageInput";
import ImageResizer from "../Extension/Image/ImageResizer";
import { defaultSelectedFile,SelectedFile } from "../Extension/Image/types";
import useDOMParse from "../useDOMParse";
import useStyles from "./styles";

type Props = {
  editor: Editor;
  handleClose: () => void;
};

const ImageMenuItem: React.FC<Props> = ({ editor, handleClose }) => {
  const plusButtonMenuStyles = useStyles();
  const { canInsertImage } = useDOMParse();
  const { getValues } = useFormContext();
  const categorizableId = getValues("categorizableId");
  const isDisabled = !canInsertImage(editor) || !categorizableId;

  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const [isResizerOpen, setIsResizerOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] =
    useState<SelectedFile>(defaultSelectedFile);

  const onImageHtmlCreated = (imageHTML: string) => {
    const insertPos = editor.state.selection.$head.pos;
    editor
      .chain()
      .focus(insertPos ?? false)
      .insertContent(imageHTML)
      .run();

    // For fixing the missing cursor
    setTimeout(() => {
      editor
        .chain()
        .insertContentAt(editor.state.selection.$to.pos, "<p></p>")
        .focus("end")
        .run();
    }, 0);
  };

  const handleItemClick = () => {
    if (isDisabled) return;
    imageInputRef.current?.click();
    handleClose();
  };

  const getToolTipTitle = () => {
    if (!canInsertImage(editor)) return "画像の挿入は１ブロック１つまでです。";
    if (!categorizableId) return "画像の挿入は更新時のみ利用可能です";
    return "";
  };

  return (
    <>
      <Tooltip title={getToolTipTitle()} placement="top">
        <MenuItem
          onClick={handleItemClick}
          classes={{ root: plusButtonMenuStyles.MenuItem }}
        >
          <ListItemIcon className={plusButtonMenuStyles.ListItemIconStyle}>
            <BiImage size={16} />
          </ListItemIcon>
          <ListItemText primary="画像" style={{ fontSize: 16 }} />
        </MenuItem>
      </Tooltip>
      <ImageInput
        ref={imageInputRef}
        setIsResizerOpen={setIsResizerOpen}
        setSelectedFile={setSelectedFile}
      />
      <ImageResizer
        editor={editor}
        isResizerOpen={isResizerOpen}
        setIsResizerOpen={setIsResizerOpen}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        onImageHtmlCreated={onImageHtmlCreated}
      />
    </>
  );
};

export default memo(ImageMenuItem);
