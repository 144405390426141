import {
  deleteHomeworkTemplateDistributionImageAsReviewer,
  fetchHomeworkTemplateDistributionAsReviewer,
  fetchHomeworkTemplateDistributionsAsReviewer,
  getUserGroupAffiliatesPackagesAsReviewer,
  getUserTagsAsReviewer,
  mergeChunksInHomeworkTemplateDistributionAsReviewer,
  resetModalState,
  returnToSchoolAsReviewer,
  revertToSchoolAsReviewer,
  updateAsReviewer,
} from "@actions/reviewerApp/homeworkTemplateDistribution";
import {
  GroupAffiliatePackageMinimalBase,
  HomeworkTemplateDistributionBaseForReviewer,
  UserTagBasic,
} from "@lib/Api";
import { update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { ReduxStatus,ReduxStatusType } from "@root/constants/redux";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import { RootState } from "../../store";

export interface ReviewerAppHomeworkTemplateDistributionState {
  fetching: boolean;
  updating: boolean;
  revertToSchoolUpdating: boolean;
  updated: boolean;
  groupAffiliatePackages: GroupAffiliatePackageMinimalBase[];
  userTags: UserTagBasic[];
  revertToSchoolUpdated: boolean;
  changeError: string | null;
  revertToSchoolChangeError: string | null;
  totalCount: number;
  distributions: HomeworkTemplateDistributionBaseForReviewer[];
  distribution: HomeworkTemplateDistributionBaseForReviewer | null;
  mergeChunksStatus: ReduxStatusType;
  deleteHomeworkImage: ReduxStatusType;
  mergeError: string | null;
}

export const initialState: ReviewerAppHomeworkTemplateDistributionState = {
  fetching: false,
  updating: false,
  revertToSchoolUpdating: false,
  updated: false,
  groupAffiliatePackages: [],
  userTags: [],
  revertToSchoolUpdated: false,
  distributions: [],
  distribution: null,
  totalCount: 0,
  changeError: null,
  deleteHomeworkImage: ReduxStatus.idle,
  revertToSchoolChangeError: null,
  mergeChunksStatus: ReduxStatus.idle,
  mergeError: null,
};

export const reviewerAppHomeworkTemplateDistributionSlice = createSlice({
  name: "ReviewerApp/HomeworkTemplateDistribution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchHomeworkTemplateDistributionsAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            distributions: [],
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionsAsReviewer.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            distributions: payload.distributions,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionsAsReviewer.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            distributions: [],
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            distribution: null,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionAsReviewer.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            distribution: payload,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateDistributionAsReviewer.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            distribution: null,
          };
        },
      )
      .addCase(
        getUserGroupAffiliatesPackagesAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
          };
        },
      )
      .addCase(
        getUserGroupAffiliatesPackagesAsReviewer.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            fetchError: action.error.message as string,
          };
        },
      )
      .addCase(
        getUserGroupAffiliatesPackagesAsReviewer.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            groupAffiliatePackages: action.payload.group_affiliate_packages,
          };
        },
      )
      .addCase(getUserTagsAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(getUserTagsAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(getUserTagsAsReviewer.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          userTags: action.payload.user_tags,
        };
      })
      .addCase(returnToSchoolAsReviewer.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeError: null,
        };
      })
      .addCase(returnToSchoolAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: action.payload.error,
        };
      })
      .addCase(returnToSchoolAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          distribution: payload,
          distributions: update(currentState.distributions, payload),
        };
      })
      .addCase(revertToSchoolAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          revertToSchoolUpdating: true,
          revertToSchoolUpdated: false,
          revertToSchoolChangeError: null,
        };
      })
      .addCase(revertToSchoolAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          revertToSchoolUpdating: false,
          revertToSchoolUpdated: false,
          revertToSchoolChangeError: action.payload.error,
        };
      })
      .addCase(revertToSchoolAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          revertToSchoolUpdating: false,
          revertToSchoolUpdated: true,
          distribution: payload,
          distributions: update(currentState.distributions, payload),
        };
      })
      .addCase(updateAsReviewer.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeError: null,
        };
      })
      .addCase(updateAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: getErrorMessage(action.payload),
        };
      })
      .addCase(updateAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          distribution: payload,
          distributions: update(currentState.distributions, payload),
        };
      })
      .addCase(
        mergeChunksInHomeworkTemplateDistributionAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            mergeChunksStatus: ReduxStatus.pending,
            mergeError: null,
          };
        },
      )
      .addCase(
        mergeChunksInHomeworkTemplateDistributionAsReviewer.rejected,
        (state, action) => {
          return {
            ...state,
            mergeChunksStatus: ReduxStatus.rejected,
            mergeError: action.payload.error,
          };
        },
      )
      .addCase(
        mergeChunksInHomeworkTemplateDistributionAsReviewer.fulfilled,
        (state, _action) => {
          return {
            ...state,
            mergeChunksStatus: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        deleteHomeworkTemplateDistributionImageAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            deleteHomeworkImage: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        deleteHomeworkTemplateDistributionImageAsReviewer.rejected,
        (state, _action) => {
          return {
            ...state,
            deleteHomeworkImage: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        deleteHomeworkTemplateDistributionImageAsReviewer.fulfilled,
        (state, _action) => {
          return {
            ...state,
            deleteHomeworkImage: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: null,
          mergeChunksStatus: ReduxStatus.idle,
          mergeError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const reviewerAppHomeworkTemplateDistributionState = (
  state: RootState,
): ReviewerAppHomeworkTemplateDistributionState =>
  state.reviewerApp.homeworkTemplateDistribution;

export default reviewerAppHomeworkTemplateDistributionSlice.reducer;
