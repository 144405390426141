/* eslint-disable camelcase */
import {
  batchCreateUserAccountId,
  batchCreateUsersAsTeacher,
  changeCsvImportState,
  fetchAffiliatedStudent,
  fetchStudentAsTeacher,
  fetchStudentsAsTeacher,
  resetCsvImportState,
  updateUserAsTeacher,
} from "@actions/teacherApp/student";
import { AffiliateName, StudentInputSetting, UserDetail } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import ApiResponseType from "@root/types/ApiResponse";
import { RootState } from "@store/store";

export interface TeacherAppUserState {
  // item
  students: ApiResponseType["teacher"]["fetchStudentsAsTeacher"]["students"];
  userDetail: UserDetail | null;
  affiliatedStudents: AffiliateName[];
  studentInputSettings: StudentInputSetting | null;
  // csv
  csvImporting: boolean;
  csvImported: boolean;
  csvImportErrors: string[];
  // update
  batchUpdateCount: number;
  updateError: string | null;
  // total count
  studentsTotalCount: number;
  allStudentsTotalCount: number;
  // api status
  fetchStudent: ReduxStatusType;
  fetchAllStudents: ReduxStatusType;
  fetchStudents: ReduxStatusType;
  fetchedAffiliatedStudents: boolean;
  fetchingAffiliatedStudents: boolean;
}

export const initialState: TeacherAppUserState = {
  // item
  students: [],
  userDetail: null,
  studentInputSettings: null,
  affiliatedStudents: [],
  // csv
  csvImporting: false,
  csvImported: false,
  csvImportErrors: [],
  // update
  batchUpdateCount: 0,
  updateError: null,
  // total count
  studentsTotalCount: 0,
  allStudentsTotalCount: 0,
  // api status
  fetchStudent: ReduxStatus.idle,
  fetchAllStudents: ReduxStatus.idle,
  fetchStudents: ReduxStatus.idle,
  fetchedAffiliatedStudents: false,
  fetchingAffiliatedStudents: false,
};

export const teacherAppUserSlice = createSlice({
  name: "TeacherApp/Users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudentsAsTeacher.pending, (state) => {
        return {
          ...state,
          fetchStudents: ReduxStatus.pending,
        };
      })
      .addCase(fetchStudentsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          students: payload.students,
          studentsTotalCount: payload.total_count,
          fetchStudents: ReduxStatus.fulfilled,
        };
      })
      .addCase(fetchStudentsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchStudents: ReduxStatus.rejected,
          errors: action.error.message as string,
        };
      })
      .addCase(fetchAffiliatedStudent.pending, (state) => {
        return {
          ...state,
          fetchingAffiliatedStudents: true,
          fetchedAffiliatedStudents: false,
        };
      })
      .addCase(fetchAffiliatedStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          affiliatedStudents: payload.students,
          fetchingAffiliatedStudents: false,
          fetchedAffiliatedStudents: true,
        };
      })
      .addCase(fetchAffiliatedStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchedAffiliatedStudents: false,
          fetchingAffiliatedStudents: false,
          errors: action.error.message as string,
        };
      })
      .addCase(fetchStudentAsTeacher.pending, (state) => {
        return {
          ...state,
          fetchStudent: ReduxStatus.pending,
        };
      })
      .addCase(fetchStudentAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          userDetail: payload,
          studentInputSettings: payload.student_input_settings,
          fetchStudent: ReduxStatus.fulfilled,
        };
      })
      .addCase(fetchStudentAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchStudent: ReduxStatus.rejected,
          errors: action.error.message as string,
        };
      })
      .addCase(updateUserAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          updateError: null,
        };
      })
      .addCase(updateUserAsTeacher.fulfilled, (state, _action) => {
        return {
          ...state,
          updateError: null,
          batchUpdateCount: state.batchUpdateCount + 1,
        };
      })
      .addCase(updateUserAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updateError: action.payload as string,
        };
      })
      .addCase(batchCreateUsersAsTeacher.pending, (state, action) => {
        const { meta } = action;
        const { slice_start } = meta.arg;
        const starting = slice_start === 0;
        return {
          ...state,
          csvImporting: true,
          csvImported: false,
          csvImportErrors: starting ? [] : state.csvImportErrors,
        };
      })
      .addCase(batchCreateUsersAsTeacher.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          csvImporting: false,
          csvImported: false,
          csvImportErrors: [],
          error: payload as string,
        };
      })
      .addCase(batchCreateUsersAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const errors = payload.errors as string[];
        return {
          ...state,
          csvImporting: false,
          csvImported: true,
          csvImportErrors: [...state.csvImportErrors, ...errors],
        };
      })
      .addCase(batchCreateUserAccountId.pending, (state, _action) => {
        return {
          ...state,
        };
      })
      .addCase(batchCreateUserAccountId.fulfilled, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(batchCreateUserAccountId.rejected, (state, action) => {
        const { payload } = action;

        const errorMessage = payload
          ? `${payload.requestIndex}行目${payload.error}`
          : "エラーが発生しました。";

        return {
          ...state,
          csvImportErrors: [...state.csvImportErrors, errorMessage],
        };
      })
      .addCase(changeCsvImportState, (state, _action) => {
        return {
          ...state,
          csvImporting: true,
        };
      })
      .addCase(resetCsvImportState, (state, _action) => {
        return {
          ...state,
          csvImporting: false,
          csvImported: false,
          csvImportErrors: [],
          batchUpdateCount: 0,
          updateError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppUserState = (state: RootState): TeacherAppUserState =>
  state.teacherApp.user;

export default teacherAppUserSlice.reducer;
