import { Grid,Theme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    gridItemWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    tagItemWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingRight: theme.spacing(3),
    },
    customSkeleton: {
      height: "24px",
      borderRadius: "10px",
    },
    tagSkeleton: {
      height: "52px",
      borderRadius: "8px",
    },
  }),
);

const LogsSectionDataSkeleton: React.FC = () => {
  const classes = useStyles();
  const skeletonArray = Array.from({ length: 10 }, (_, idx) => idx + 1);
  return (
    <>
      {skeletonArray.map((value) => (
        <Grid key={value} className={classes.container}>
          <Grid item xs={2} className={classes.tagItemWrapper}>
            <Skeleton
              variant="text"
              width={74.44}
              className={classes.tagSkeleton}
            />
          </Grid>
          <Grid item className={classes.gridItemWrapper} xs={3}>
            <Skeleton
              variant="text"
              width={130}
              className={classes.customSkeleton}
            />
          </Grid>

          <Grid item className={classes.gridItemWrapper} xs={6}>
            <Skeleton variant="text" width={300} />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default LogsSectionDataSkeleton;
