import { ReduxStatus,ReduxStatusType } from "@constants/redux";
import { Homework, HomeworkDetails } from "@lib/Api";
import { update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { getErrorsInArray } from "@utils/errorMessage/errorMessage";

import {
  answerCustomFieldAsStudent,
  deleteCustomFieldAsStudent,
  fetchHomeworkAsStudent,
  fetchOneHomeworkAnswersAsStudent,
  fetchOneHomeworkAsStudent,
  fileDeleted as fileDeletedAction,
  fileUploaded,
  resetStudentAppHomeworkFormData,
  resetStudentAppHomeworkFormError,
  updateAllCustomFieldAsStudent,
  updateCustomFieldAsStudent,
  updatedHomeworkFile,
  updateOneHomeworkAsStudent,
} from "../../actions/studentApp/homework";
import { RootState } from "../../store";

export interface StudentAppHomeworkState {
  fetching: boolean;
  fetched: boolean;
  updating: boolean;
  updated: boolean;
  updateFile: ReduxStatusType;
  answerCustomField: ReduxStatusType;
  updateCustomField: ReduxStatusType;
  deleteCustomField: ReduxStatusType;
  updatedAllCustomFields: ReduxStatusType;
  uploaded: boolean;
  fileDeleted: boolean;
  error: string[];
  answerCustomFieldError: string[];
  updateCustomFieldError: string[];
  deleteCustomFieldError: string[];
  homeworks: Homework[];
  homework: HomeworkDetails | null;
  totalCount: number;
  fetchingAnswers: boolean;
  fetchedAnswers: boolean;
  errorAnswers: string[];
  errorCustomFieldId: string | null;
  errorStatusCode: number;
  duplicationData: { message?: string; hashid?: string; body?: string };
}

export const initialState: StudentAppHomeworkState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  updateFile: ReduxStatus.idle,
  answerCustomField: ReduxStatus.idle,
  updateCustomField: ReduxStatus.idle,
  deleteCustomField: ReduxStatus.idle,
  updatedAllCustomFields: ReduxStatus.idle,
  uploaded: false,
  fileDeleted: false,
  error: [],
  answerCustomFieldError: [],
  updateCustomFieldError: [],
  deleteCustomFieldError: [],
  homeworks: [],
  homework: null,
  totalCount: 0,
  fetchingAnswers: false,
  fetchedAnswers: false,
  errorAnswers: [],
  errorCustomFieldId: null,
  errorStatusCode: 0,
  duplicationData: {},
};

const studentAppHomeworkSlice = createSlice({
  name: "studentApp/Homework",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeworkAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: [],
        };
      })
      .addCase(fetchHomeworkAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: getErrorsInArray(action.payload as unknown),
          homeworks: [],
        };
      })
      .addCase(fetchHomeworkAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: [],
          homeworks: payload.homeworks,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchOneHomeworkAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: [],
          errorCustomFieldId: null,
          errorStatusCode: 0,
          duplicationData: {},
        };
      })
      .addCase(fetchOneHomeworkAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: getErrorsInArray(action.payload as unknown),
          homework: null,
        };
      })
      .addCase(fetchOneHomeworkAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: [],
          errorCustomFieldId: null,
          errorStatusCode: 0,
          duplicationData: {},
          homework: payload,
        };
      })
      .addCase(fetchOneHomeworkAnswersAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchingAnswers: true,
          fetchedAnswers: false,
          errorAnswers: [],
          errorCustomFieldId: null,
          errorStatusCode: 0,
          duplicationData: {},
        };
      })
      .addCase(fetchOneHomeworkAnswersAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingAnswers: false,
          fetchedAnswers: false,
          errorAnswers: getErrorsInArray(action.payload as unknown),
          homework: null,
        };
      })
      .addCase(fetchOneHomeworkAnswersAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingAnswers: false,
          fetchedAnswers: true,
          errorAnswers: [],
          errorCustomFieldId: null,
          errorStatusCode: 0,
          duplicationData: {},
          homework: payload,
        };
      })
      .addCase(updateOneHomeworkAsStudent.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          uploaded: false,
          error: [],
        };
      })
      .addCase(updateOneHomeworkAsStudent.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          error: getErrorsInArray(action.payload as unknown),
        };
      })
      .addCase(updateOneHomeworkAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updating: false,
          updated: true,
          error: [],
          homework: payload,
        };
      })
      .addCase(resetStudentAppHomeworkFormData, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          uploaded: false,
          fileDeleted: false,
          updateFile: ReduxStatus.idle,
          error: [],
          answerCustomField: ReduxStatus.idle,
          updateCustomField: ReduxStatus.idle,
          deleteCustomField: ReduxStatus.idle,
          updatedAllCustomFields: ReduxStatus.idle,
          answerCustomFieldError: [],
          updateCustomFieldError: [],
          deleteCustomFieldError: [],
          fetchingAnswers: false,
          fetchedAnswers: false,
          errorAnswers: [],
          errorCustomFieldId: null,
          errorStatusCode: 0,
          duplicationData: {},
        };
      })
      .addCase(resetStudentAppHomeworkFormError, (state, _action) => {
        return {
          ...state,
          error: [],
          answerCustomFieldError: [],
          updateCustomFieldError: [],
          deleteCustomFieldError: [],
          errorAnswers: [],
          errorCustomFieldId: null,
          errorStatusCode: 0,
          duplicationData: {},
        };
      })
      .addCase(updateAllCustomFieldAsStudent, (state, _action) => {
        return {
          ...state,
          updatedAllCustomFields: ReduxStatus.fulfilled,
        };
      })
      .addCase(updatedHomeworkFile, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updateFile: ReduxStatus.fulfilled,
          uploaded: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload as Homework),
        };
      })
      .addCase(fileUploaded, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          uploaded: true,
          updating: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload as Homework),
        };
      })
      .addCase(fileDeletedAction, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          fileDeleted: true,
          updating: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload as Homework),
        };
      })
      .addCase(answerCustomFieldAsStudent.pending, (state, _action) => {
        return {
          ...state,
          answerCustomField: ReduxStatus.pending,
          answerCustomFieldError: [],
        };
      })
      .addCase(answerCustomFieldAsStudent.rejected, (state, action) => {
        const { data, status, errCustomFieldId } = action.payload as {
          data: { message?: string; hashid?: string; body?: string };
          status: number;
          errCustomFieldId: string;
        };
        return {
          ...state,
          answerCustomField: ReduxStatus.rejected,
          answerCustomFieldError: getErrorsInArray(data),
          errorCustomFieldId: errCustomFieldId,
          errorStatusCode: status,
          duplicationData: data,
        };
      })
      .addCase(answerCustomFieldAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          answerCustomField: ReduxStatus.fulfilled,
          answerCustomFieldError: [],
          errorCustomFieldId: null,
          errorStatusCode: 0,
          duplicationData: {},
          homework: {
            ...(state.homework as HomeworkDetails),
            homework_template_custom_fields:
              payload.homework_template_custom_fields,
          },
        };
      })
      .addCase(updateCustomFieldAsStudent.pending, (state, _action) => {
        return {
          ...state,
          updateCustomField: ReduxStatus.pending,
          updateCustomFieldError: [],
          errorCustomFieldId: null,
          errorStatusCode: 0,
          duplicationData: {},
        };
      })
      .addCase(updateCustomFieldAsStudent.rejected, (state, action) => {
        return {
          ...state,
          updateCustomField: ReduxStatus.rejected,
          updateCustomFieldError: getErrorsInArray(action.payload as unknown),
        };
      })
      .addCase(updateCustomFieldAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updateCustomField: ReduxStatus.fulfilled,
          updateCustomFieldError: [],
          errorCustomFieldId: null,
          errorStatusCode: 0,
          duplicationData: {},
          homework: {
            ...(state.homework as HomeworkDetails),
            homework_template_custom_fields:
              payload.homework_template_custom_fields,
          },
        };
      })
      .addCase(deleteCustomFieldAsStudent.pending, (state, _action) => {
        return {
          ...state,
          deleteCustomField: ReduxStatus.pending,
          deleteCustomFieldError: [],
        };
      })
      .addCase(deleteCustomFieldAsStudent.rejected, (state, action) => {
        return {
          ...state,
          deleteCustomField: ReduxStatus.rejected,
          deleteCustomFieldError: getErrorsInArray(action.payload as unknown),
        };
      })
      .addCase(deleteCustomFieldAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          deleteCustomField: ReduxStatus.fulfilled,
          deleteCustomFieldError: [],
          homework: {
            ...(state.homework as HomeworkDetails),
            homework_template_custom_fields:
              payload.homework_template_custom_fields,
          },
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppHomeworkState = (
  state: RootState,
): StudentAppHomeworkState => state.studentApp.homework;

export default studentAppHomeworkSlice.reducer;
