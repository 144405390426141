/* eslint-disable camelcase */

import {
  Api,
  ArticleDetailBase,
  FetchArticlesAsOwnerParams,
  FetchArticleWordCloudAsOwnerParams,
  WordCloudBase,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RoleApiFunctions } from "@root/types/wordCloud";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticlesAsOwnerArg {
  params: FetchArticlesAsOwnerParams;
}

export const fetchArticlesAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/article/fetch",
  async (arg: FetchArticlesAsOwnerArg, { rejectWithValue }) => {
    try {
      const response = await api.owner.fetchArticlesAsOwner(arg.params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchArticleAsOwner = createAsyncThunk<
  ArticleDetailBase,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/article/fetchOne", async (articleId: string, { rejectWithValue }) => {
  try {
    const response = await api.owner.fetchArticleAsOwner(articleId);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchWordCloudDataAsOwner = createAsyncThunk<
  WordCloudBase,
  FetchArticleWordCloudAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/word_cloud/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.fetchArticleWordCloudAsOwner(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetLikeRelatedState = createAction(
  "owner/article/resetLikeRelatedState",
);

export const resetArticleRelatedData = createAction("owner/article/resetOne");

type OwnerActionsType = {
  owner: RoleApiFunctions;
};
export const ownerWordCloudActions: OwnerActionsType = {
  owner: {
    fetchWordCloudDataByRole: fetchWordCloudDataAsOwner,
  },
};
