/* eslint-disable camelcase */
import { AvatarImgType } from "@components/UI/molecules/Avatar/types";
import {
  AffiliateName,
  Api,
  GetUserStudentsAsStudentParams,
  StudentProfile,
} from "@lib/Api";
import { http } from "@lib/http";
import errorWithMessage from "@lib/rtk/error-utils";
import { safeString } from "@lib/string-utils";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { STUDENT_PROFILE_PATH } from "@root/constants/api";
import { RootState } from "@store/store";

const api = new Api();

export type StudentTeacher = {
  id: string;
  last_name: string;
  first_name: string;
};

export const getUserStudentsAsStudent = createAsyncThunk<
  { students: AffiliateName[]; total_count: number },
  GetUserStudentsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "studentApp/getUserStudentsAsStudent",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.student.getUserStudentsAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const getAffiliateTeachersAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "studentApp/getAffiliateTeachersAsStudent",
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.student.getUserStudentAffiliatesAsStudent({});
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const getStudentProfile = createAsyncThunk<
  StudentProfile,
  string,
  { state: RootState; rejectWithValue: any }
>("studentApp/getStudentProfile", async (id, { rejectWithValue }) => {
  try {
    const response = await api.student.getStudentUsersUserId(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export type UpdateStudentProfileParams = {
  user_id: string;
  data: {
    last_name?: string;
    first_name?: string;
    avatar?: AvatarImgType;
    nickname?: string;
    student_info_attributes?: {
      birth_date?: string;
      parent_last_name?: string;
      parent_first_name?: string;
      postcode?: string;
      prefecture?: string;
      address1?: string;
      address2?: string;
      phone?: string;
    };
  };
};

export const updateStudentProfile = createAsyncThunk<
  any,
  UpdateStudentProfileParams,
  { state: RootState; rejectWithValue: any }
>("studentApp/updateStudentProfile", async (params, { rejectWithValue }) => {
  try {
    const fd = new FormData();
    const {
      user_id,
      data: {
        last_name,
        first_name,
        avatar,
        student_info_attributes,
        nickname,
      },
    } = params;

    fd.set("last_name", safeString(last_name));
    fd.set("first_name", safeString(first_name));
    fd.set("nickname", safeString(nickname));
    if (avatar) {
      fd.set("avatar", avatar);
    }

    if (student_info_attributes) {
      const {
        birth_date,
        parent_last_name,
        parent_first_name,
        postcode,
        prefecture,
        address1,
        address2,
        phone,
      } = student_info_attributes;

      fd.set("student_info_attributes[birth_date]", safeString(birth_date));
      fd.set(
        "student_info_attributes[parent_last_name]",
        safeString(parent_last_name),
      );
      fd.set(
        "student_info_attributes[parent_first_name]",
        safeString(parent_first_name),
      );
      fd.set("student_info_attributes[postcode]", safeString(postcode));
      fd.set("student_info_attributes[prefecture]", safeString(prefecture));
      fd.set("student_info_attributes[address1]", safeString(address1));
      fd.set("student_info_attributes[address2]", safeString(address2));
      fd.set("student_info_attributes[phone]", safeString(phone));
    }

    const response = await http.put(`${STUDENT_PROFILE_PATH}/${user_id}`, fd);
    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const resetStudentProfileFormState = createAction(
  "studentApp/resetStudentProfileFormState",
);
