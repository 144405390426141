import {
  createSponsorLectureAsAdmin,
  deleteSponsorLectureAsAdmin,
  fetchPblSponsorLectureAsAdmin,
  fetchPblSponsorLecturesAsAdmin,
  resetAction,
  resetOne,
  saveLectureListSequence,
  updateSponsorLectureAsAdmin,
} from "@actions/adminApp/pbl/sponsor/lecture";
import {
  createSponsorLectureFileAsAdmin,
  deleteSponsorLectureFileAsAdmin,
} from "@actions/adminApp/pbl/sponsor/lectureFile";
import { SponsorLectureDetailsBase } from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface AdminAppPblSponsorLectureState {
  fetching: boolean;
  fetched: boolean;
  fetchError: string | null;
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneErrors: string | null;
  lectures: SponsorLectureDetailsBase[];
  totalCount: number;
  lectureDetails: SponsorLectureDetailsBase | null;
  createStatus: ReduxStatusType;
  updateStatus: ReduxStatusType;
  deleting: boolean;
  deleted: boolean;
  changeErrors: any[] | null;
  putLectureSequence: ReduxStatusType;
  putLectureSequenceError: string | null;
}

export const initialState: AdminAppPblSponsorLectureState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  fetchingOne: false,
  fetchedOne: false,
  fetchOneErrors: null,
  lectures: [],
  totalCount: 0,
  lectureDetails: null,
  createStatus: ReduxStatus.idle,
  updateStatus: ReduxStatus.idle,
  deleting: false,
  deleted: false,
  changeErrors: null,
  putLectureSequence: ReduxStatus.idle,
  putLectureSequenceError: null,
};

export const adminAppPblSponsorLectureSlice = createSlice({
  name: "adminApp/pbl/sponsors/lecture",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblSponsorLecturesAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchPblSponsorLecturesAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.payload,
        };
      })
      .addCase(fetchPblSponsorLecturesAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: null,
          lectures: payload.project_lectures,
          totalCount: payload.total_count,
        };
      })
      .addCase(saveLectureListSequence.pending, (state, _action) => {
        return {
          ...state,
          putLectureSequence: ReduxStatus.pending,
        };
      })
      .addCase(saveLectureListSequence.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          putLectureSequence: ReduxStatus.rejected,
          putLectureSequenceError: getErrorMessage(payload.errors),
        };
      })
      .addCase(saveLectureListSequence.fulfilled, (state, _action) => {
        return {
          ...state,
          putLectureSequence: ReduxStatus.fulfilled,
        };
      })
      .addCase(fetchPblSponsorLectureAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
        };
      })
      .addCase(fetchPblSponsorLectureAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error.message as string,
        };
      })
      .addCase(fetchPblSponsorLectureAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          fetchOneError: null,
          lectureDetails: payload,
        };
      })
      .addCase(createSponsorLectureAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          createStatus: ReduxStatus.pending,
          changeErrors: null,
        };
      })
      .addCase(createSponsorLectureAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          createStatus: ReduxStatus.rejected,
          changeErrors: action.payload,
        };
      })
      .addCase(createSponsorLectureAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          createStatus: ReduxStatus.fulfilled,
          changeErrors: null,
          lectureDetails: payload,
          lectures: [...state.lectures, payload],
        };
      })
      .addCase(updateSponsorLectureAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          updateStatus: ReduxStatus.pending,
          changeErrors: null,
        };
      })
      .addCase(updateSponsorLectureAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          updateStatus: ReduxStatus.rejected,
          changeErrors: action.payload,
        };
      })
      .addCase(updateSponsorLectureAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updateStatus: ReduxStatus.fulfilled,
          changeErrors: null,
          lectureDetails: payload,
          lectures: update(state.lectures, payload),
        };
      })
      .addCase(deleteSponsorLectureAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          changeErrors: null,
        };
      })
      .addCase(deleteSponsorLectureAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          changeErrors: action.payload,
        };
      })
      .addCase(deleteSponsorLectureAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: true,
          changeErrors: null,
          lectureDetails: null,
          lectures: remove(state.lectures, action.meta.arg),
        };
      })
      .addCase(createSponsorLectureFileAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        let updatedLectureDetails = state.lectureDetails;
        if (state.lectureDetails) {
          const { lectureDetails } = state;
          const files = lectureDetails.files ? lectureDetails.files : [];
          updatedLectureDetails = {
            ...lectureDetails,
            files: [...files, payload],
          };
        }
        return {
          ...state,
          lectureDetails: updatedLectureDetails,
        };
      })
      .addCase(deleteSponsorLectureFileAsAdmin.fulfilled, (state, action) => {
        let updatedLectureDetails = state.lectureDetails;
        if (state.lectureDetails) {
          const { lectureDetails } = state;
          const files = lectureDetails.files ? lectureDetails.files : [];
          updatedLectureDetails = {
            ...lectureDetails,
            files: remove(files, action.meta.arg),
          };
        }
        return {
          ...state,
          lectureDetails: updatedLectureDetails,
        };
      })
      .addCase(resetAction, (state, _action) => {
        return {
          ...state,
          createStatus: ReduxStatus.idle,
          updateStatus: ReduxStatus.idle,
          deleting: false,
          deleted: false,
          changeErrors: null,
          putLectureSequence: ReduxStatus.idle,
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          lectureDetails: null,
          fetchedOne: false,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const adminAppPblSponsorLectureState = (
  state: RootState,
): AdminAppPblSponsorLectureState => state.adminApp.pblSponsorLecture;

export default adminAppPblSponsorLectureSlice.reducer;
