import { createContext } from "react";

import { OptionalExtensions } from "./types";

export type SVEditorContextType = {
  optionalExtensions: OptionalExtensions;
};

export const SVEditorContext = createContext<SVEditorContextType>(
  undefined as never,
);
