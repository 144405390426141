/* eslint-disable camelcase */

import { Api, FetchArticleFeedbacksAsTeacherParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticleFeedbacksAsTeacherArg {
  params: FetchArticleFeedbacksAsTeacherParams;
}

export const fetchArticleFeedbacksAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/articleFeedback/fetch",
  async (arg: FetchArticleFeedbacksAsTeacherArg, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchArticleFeedbacksAsTeacher(
        arg.params,
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreateArticleFeedbackAsTeacherArg {
  articleId: string;
  params: {
    body: string;
  };
}

export const createArticleFeedbackAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/articleFeedback/create",
  async (arg: CreateArticleFeedbackAsTeacherArg, { rejectWithValue }) => {
    const { articleId, params } = arg;
    try {
      const response = await api.teacher.createArticleFeedbackAsTeacher(
        articleId,
        { body: params.body },
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface DeleteArticleFeedbackAsTeacherArg {
  articleId: string;
  feedbackId: string;
}

export const deleteArticleFeedbackAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/articleFeedback/delete",
  async (arg: DeleteArticleFeedbackAsTeacherArg, { rejectWithValue }) => {
    const { articleId, feedbackId } = arg;
    try {
      await api.teacher.deleteArticleFeedbackAsTeacher(articleId, feedbackId);
      return {
        feedbackId,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetFeedbackRelatedFormState = createAction(
  "teacher/article/feedback/resetFormState",
);

export const resetDeleteFeedbackRelatedState = createAction(
  "teacher/article/feedback/resetDeleteRelatedState",
);
