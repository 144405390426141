import {
  fetchArticleAsContentProvider,
  fetchArticleLikeAsContentProvider,
  fetchArticlesAsContentProvider,
  fetchWordCloudDataAsContentProvider,
  likeArticleAsContentProvider,
  resetArticleRelatedData,
  resetLikeRelatedState,
  unlikeArticleAsContentProvider,
} from "@actions/contentProviderApp/article";
import {
  createArticleCommentAsContentProvider,
  deleteArticleCommentAsContentProvider,
  fetchArticleCommentsAsContentProvider,
  getFeedbackTemplatesAsContentProvider,
  resetCommentRelatedFormState,
  resetDeleteCommentRelatedState,
} from "@actions/contentProviderApp/articleComment";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import {
  ArticleBase,
  ArticleComment,
  ArticleDetailBase,
  ArticleFeedback,
  ArticleLike,
  FeedbackTemplateBase,
  WordCloudWord,
} from "@lib/Api";
import { remove,update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@root/store/store";

export interface ContentProviderAppArticleState {
  fetching: boolean;
  fetched: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneError: any;
  serverError: any;
  totalCount: number;
  articles: ArticleBase[];
  article: ArticleDetailBase | ArticleBase | null;
  articleComments: ArticleComment[];
  fetchingComments: boolean;
  fetchedComments: boolean;
  articleFeedbacks: ArticleFeedback[];
  fetchingFeedbacks: boolean;
  fetchedFeedbacks: boolean;
  creatingComment: boolean;
  createdComment: boolean;
  createCommentError: any;
  deletingComment: boolean;
  deletedComment: boolean;
  deleteCommentError: any;
  fetchingLike: boolean;
  fetchedLike: boolean;
  fetchLikeError: any;
  fetchFeedBackTemplate: ReduxStatusType;
  feedbackTemplates: FeedbackTemplateBase[];
  likes: ArticleLike[];
  liking: boolean;
  liked: boolean;
  likeError: any;
  unliking: boolean;
  unliked: boolean;
  unlikeError: any;
  wordCloudData: WordCloudWord[];
  fetchWordCloudData: ReduxStatusType;
}

export const initialState: ContentProviderAppArticleState = {
  fetching: false,
  fetched: false,
  fetchingOne: false,
  fetchedOne: false,
  fetchOneError: null,
  serverError: null,
  articles: [],
  totalCount: 0,
  article: null,
  articleComments: [],
  fetchingComments: false,
  fetchedComments: false,
  articleFeedbacks: [],
  fetchingFeedbacks: false,
  fetchedFeedbacks: false,
  creatingComment: false,
  createdComment: false,
  createCommentError: null,
  deletingComment: false,
  deletedComment: false,
  deleteCommentError: null,
  fetchingLike: false,
  fetchedLike: false,
  fetchLikeError: null,
  fetchFeedBackTemplate: ReduxStatus.idle,
  feedbackTemplates: [],
  likes: [],
  liking: false,
  liked: false,
  likeError: null,
  unliking: false,
  unliked: false,
  unlikeError: null,
  fetchWordCloudData: ReduxStatus.idle,
  wordCloudData: [],
};

export const contentProviderAppArticleSlice = createSlice({
  name: "ContentProviderApp/Article",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticlesAsContentProvider.pending, (state, _action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          serverError: null,
        };
      })
      .addCase(fetchArticlesAsContentProvider.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          articles: payload.articles,
          totalCount: payload.total_count,
          serverError: "",
        };
      })
      .addCase(fetchArticlesAsContentProvider.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          articles: [],
          serverError: action.error,
        };
      })
      .addCase(fetchArticleAsContentProvider.pending, (state, _action) => {
        return {
          ...state,
          fetchedOne: false,
          fetchingOne: true,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsContentProvider.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          article: payload,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsContentProvider.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error,
          article: null,
        };
      })
      .addCase(
        fetchArticleCommentsAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchedComments: false,
            fetchingComments: true,
            serverError: null,
          };
        },
      )
      .addCase(
        fetchArticleCommentsAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingComments: false,
            fetchedComments: true,
            articleComments: payload.article_comments,
            serverError: "",
          };
        },
      )
      .addCase(
        fetchArticleCommentsAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            articles: [],
            serverError: action.error,
          };
        },
      )
      .addCase(
        getFeedbackTemplatesAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchFeedBackTemplate: ReduxStatus.pending,
            fetchFeedBackTemplateError: null,
          };
        },
      )
      .addCase(
        getFeedbackTemplatesAsContentProvider.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetchFeedBackTemplate: ReduxStatus.fulfilled,
            feedbackTemplates: action.payload.feedback_templates,
            fetchFeedBackTemplateError: "",
          };
        },
      )
      .addCase(
        getFeedbackTemplatesAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchFeedBackTemplate: ReduxStatus.rejected,
            fetchFeedBackTemplateError: action.error,
          };
        },
      )
      .addCase(
        createArticleCommentAsContentProvider.pending,
        (state, action) => {
          return {
            ...state,
            creatingComment: true,
            createdComment: false,
            createCommentError: null,
          };
        },
      )
      .addCase(fetchArticleLikeAsContentProvider.pending, (state, action) => {
        return {
          ...state,
          fetchingLike: true,
          fetchedLike: false,
          fetchLikeError: null,
        };
      })
      .addCase(fetchArticleLikeAsContentProvider.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingLike: false,
          fetchedLike: true,
          likes: payload.article_likes,
          fetchLikeError: null,
        };
      })
      .addCase(fetchArticleLikeAsContentProvider.rejected, (state, action) => {
        return {
          ...state,
          fetchingLike: false,
          fetchedLike: false,
          fetchLikeError: action.payload,
        };
      })
      .addCase(
        fetchWordCloudDataAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchWordCloudData: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetchWordCloudDataAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchWordCloudData: ReduxStatus.fulfilled,
            wordCloudData: payload.word_cloud_list,
          };
        },
      )
      .addCase(
        fetchWordCloudDataAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchWordCloudData: ReduxStatus.rejected,
            fetchWordCloudError: action.error,
          };
        },
      )
      .addCase(
        createArticleCommentAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          let updatedArtcile = null;
          if (currentState.article) {
            updatedArtcile = {
              ...currentState.article,
              num_comments: currentState.article.num_comments + 1,
            };
          }
          let updatedArtciles = currentState.articles;
          if (updatedArtcile) {
            updatedArtciles = update(
              currentState.articles,
              updatedArtcile as ArticleBase,
            );
          }
          return {
            ...state,
            creatingComment: false,
            createdComment: true,
            article: updatedArtcile,
            articles: updatedArtciles,
            articleComments: [...currentState.articleComments, payload],
            createCommentError: null,
          };
        },
      )
      .addCase(
        createArticleCommentAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            creatingComment: false,
            createdComment: false,
            createCommentError: action.error,
          };
        },
      )
      .addCase(
        deleteArticleCommentAsContentProvider.pending,
        (state, action) => {
          return {
            ...state,
            deletingComment: true,
            deletedComment: false,
            deleteCommentError: null,
          };
        },
      )
      .addCase(
        deleteArticleCommentAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          let updatedArtcile = null;
          const currentState = current(state);
          if (currentState.article) {
            updatedArtcile = {
              ...currentState.article,
              num_comments: currentState.article.num_comments - 1,
            };
          }
          let updatedArtciles = currentState.articles;
          if (updatedArtcile) {
            updatedArtciles = update(
              currentState.articles,
              updatedArtcile as ArticleBase,
            );
          }
          return {
            ...state,
            deletingComment: false,
            deletedComment: true,
            article: updatedArtcile,
            articles: updatedArtciles,
            articleComments: remove(
              currentState.articleComments,
              payload.commentId,
            ),
            deleteCommentError: null,
          };
        },
      )
      .addCase(
        deleteArticleCommentAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            deletingComment: false,
            deletedComment: false,
            deleteCommentError: action.error,
          };
        },
      )
      .addCase(likeArticleAsContentProvider.pending, (state, action) => {
        return {
          ...state,
          liking: true,
          liked: false,
          likeError: null,
        };
      })
      .addCase(likeArticleAsContentProvider.fulfilled, (state, action) => {
        return {
          ...state,
          liking: false,
          liked: true,
          likeError: null,
        };
      })
      .addCase(likeArticleAsContentProvider.rejected, (state, action) => {
        return {
          ...state,
          liking: false,
          liked: false,
          likeError: action.payload,
        };
      })
      .addCase(unlikeArticleAsContentProvider.pending, (state, action) => {
        return {
          ...state,
          unliking: true,
          unliked: false,
          unlikeError: null,
        };
      })
      .addCase(unlikeArticleAsContentProvider.fulfilled, (state, action) => {
        return {
          ...state,
          unliking: false,
          unliked: true,
          unlikeError: null,
        };
      })
      .addCase(unlikeArticleAsContentProvider.rejected, (state, action) => {
        return {
          ...state,
          unliking: false,
          unliked: false,
          unlikeError: action.payload,
        };
      })
      .addCase(resetCommentRelatedFormState, (state, _action) => {
        return {
          ...state,
          creatingComment: false,
          createdComment: false,
          createCommentError: null,
          deletingComment: false,
          deletedComment: false,
          deleteCommentError: null,
        };
      })
      .addCase(resetDeleteCommentRelatedState, (state, _action) => {
        return {
          ...state,
          deletingComment: false,
          deletedComment: false,
          deleteCommentError: null,
        };
      })
      .addCase(resetLikeRelatedState, (state, _action) => {
        return {
          ...state,
          likeing: false,
          liked: false,
          likeError: false,
          unliking: false,
          unliked: false,
          unlikeError: null,
        };
      })
      .addCase(resetArticleRelatedData, (state, _action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: null,
          fetchingFeedbacks: false,
          fetchedFeedbacks: false,
          fetchedComments: false,
          fetchingComments: false,
          fetchCommentsError: null,
          fetchFeedbackError: null,
          fetchFeedBackTemplate: ReduxStatus.idle,
          articleFeedbacks: [],
          articleComments: [],
          article: null,
          deletingComment: false,
          deletedComment: false,
          deleteCommentError: null,
          likeing: false,
          liked: false,
          likeError: false,
          unliking: false,
          unliked: false,
          unlikeError: null,
          creatingComment: false,
          createdComment: false,
          createCommentError: null,
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const contentProviderAppArticleState = (
  state: RootState,
): ContentProviderAppArticleState => state.contentProviderApp.article;

export default contentProviderAppArticleSlice.reducer;
