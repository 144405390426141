/* eslint-disable @typescript-eslint/ban-types */
import { CompanyTagType } from "@actions/types/tag";
import { StyledFormHelperText } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList/Modals/LearningTemplateDistributionModal/StepOne";
import { FormType } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList/Modals/LearningTemplateDistributionModal/usePackageDistributionModal";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import { Company } from "@lib/Api";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";

import TagAccordion from "./TagItem";

interface TagAccordionProps {
  companyTagTypes: CompanyTagType[];
  control: Control<FormType, object>;
  handleSchoolTagAssign: (value: Company | CompanyTagType) => {
    id: string;
    name: string;
  }[];
  errors: any;
}

const SchoolTags: React.FC<TagAccordionProps> = ({
  companyTagTypes,
  control,
  handleSchoolTagAssign,
  errors,
}) => {
  return (
    <>
      <FormSectionWrap>
        <FormCategoryTitle>タグの設定 *</FormCategoryTitle>
        {companyTagTypes && companyTagTypes.length > 0 ? (
          <>
            {errors.schoolTags && (
              <StyledFormHelperText>
                ** タグの設定は必須です **
              </StyledFormHelperText>
            )}
            <Controller
              control={control}
              name="schoolTags"
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    {companyTagTypes.map((tagType: CompanyTagType) => (
                      <TagAccordion
                        key={tagType.id}
                        tagType={tagType}
                        handleSchoolTagAssign={handleSchoolTagAssign}
                        value={value}
                        onChange={onChange}
                      />
                    ))}
                  </>
                );
              }}
              rules={{ required: "タグの設定は必須です。" }}
            />
          </>
        ) : (
          <Box m={2}>
            <Typography>タグが見つかりませんでした。</Typography>
          </Box>
        )}
      </FormSectionWrap>
    </>
  );
};

export default SchoolTags;
