import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { MaterialDetail, MaterialList, StudentMaterialWork } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  fetchMaterialAsStudent,
  fetchMaterialsAsStudent,
} from "../../actions/studentApp/material";
import { RootState } from "../../store";

export interface StudentAppMaterialState {
  fetching: boolean;
  fetchingMaterial: ReduxStatusType;
  error: any;
  materialWorks: StudentMaterialWork[];
  worksTotalCount: number;
  totalCount: number;
  materials: MaterialList[];
  material: MaterialDetail | undefined;
}

export const initialState: StudentAppMaterialState = {
  fetching: false,
  fetchingMaterial: ReduxStatus.idle,
  materialWorks: [],
  error: null,
  materials: [],
  material: undefined,
  worksTotalCount: 0,
  totalCount: 0,
};

const studentAppMaterialSlice = createSlice({
  name: "StudentApp/Material",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterialsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchMaterialsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: action.error.message as string,
        };
      })
      .addCase(fetchMaterialsAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          materials: payload.materials,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchMaterialAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchingMaterial: ReduxStatus.pending,
          error: null,
          material: undefined,
        };
      })
      .addCase(fetchMaterialAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingMaterial: ReduxStatus.rejected,
          material: undefined,
          error: action.error.message as string,
        };
      })
      .addCase(fetchMaterialAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingMaterial: ReduxStatus.fulfilled,
          material: payload,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppMaterialState = (
  state: RootState,
): StudentAppMaterialState => state.studentApp.material;

export default studentAppMaterialSlice.reducer;
