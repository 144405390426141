import { LearningTemplatePackageDistributionHistoryBase } from "@lib/Api";

export enum LearningTemplateHistoryModalKind {
  IDLE = "idle",
  SHOW = "show",
  EDIT = "edit",
  DELETE = "delete",
}

export type LearningTemplateHistoryModalType = {
  isOpen: boolean;
  kind: LearningTemplateHistoryModalKind;
  learningTemplatePackage: LearningTemplatePackageDistributionHistoryBase | null;
};
