import animationData from "@assets/lotties/DeleteBox.json";
import React, { lazy,Suspense } from "react";

const LazyLottie = lazy(() => import("./LazyLottie"));

type DeleteBoxProps = {
  width?: number;
  height?: number;
};

const defaultProps: DeleteBoxProps = {
  width: 100,
  height: 100,
};

const DeleteBox = (props: DeleteBoxProps): JSX.Element => {
  const { width, height } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Suspense fallback={<></>}>
      <LazyLottie options={defaultOptions} height={height} width={width} />
    </Suspense>
  );
};
DeleteBox.defaultProps = defaultProps;

export default DeleteBox;
