/* eslint-disable camelcase */
import DeleteIcon from "@assets/images/svg/GreenTrash.svg?react";
import { StyledTag } from "@components/UI/atoms/StyledTag";
import StyledUserTag from "@components/UI/atoms/StyledTag/StyledUserTag";
import TableBodyLoading from "@components/UI/organisms/TableBodyLoading/TableBodyLoading";
import { DisabledAdminTableRow } from "@components/UI/organisms/Tables/TableComponents";
import V2IconButton from "@components/UIv2/atoms/buttons/V2IconButton";
import {
  DeletionProgress,
  DistributionStatusStatus,
  HasIdAndName,
  LearningTemplatePackageDistributionHistoryBase,
} from "@lib/Api";
import { getDistributionStatusColor } from "@lib/distribution/distribution-utils";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import { getStartAndEndDate } from "@lib/time/dateUtils";
import { isAfterTime } from "@lib/time-utils";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import NO_DATA_MESSAGE from "@root/constants/Messages/Table";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { LearningTemplateHistoryModalKind } from "../../types";

interface Props {
  fetched: boolean;
  fetching: boolean;
  fetchError: string | null;
  learningTemplatePackage: LearningTemplatePackageDistributionHistoryBase[];
  handleModalState: (
    data: LearningTemplatePackageDistributionHistoryBase,
    kind: LearningTemplateHistoryModalKind,
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
    distributedCell: {
      "&:hover": {
        backgroundColor: "inherit",
      },
    },
  }),
);

const Body: React.FC<Props> = ({
  fetched,
  fetching,
  fetchError,
  learningTemplatePackage,
  handleModalState,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("distribution_status");
  const { actions } = useEnqueueToast();

  if (!fetching && (!learningTemplatePackage.length || fetchError)) {
    if (fetchError) {
      actions.showError(fetchError);
    }
    return (
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row" colSpan={4}>
            {NO_DATA_MESSAGE}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }
  if (fetching) {
    return <TableBodyLoading colCount={7} />;
  }
  if (!fetching && !fetchError && fetched && learningTemplatePackage.length) {
    return (
      <TableBody>
        {learningTemplatePackage.map(
          (templatePackage: LearningTemplatePackageDistributionHistoryBase) => {
            const { kind, companies, user_tags } = templatePackage;
            const isDeleting =
              templatePackage?.deletion_progress === DeletionProgress.Running;
            const isDeletable =
              !isDeleting &&
              isAfterTime(templatePackage.start_at, 10) &&
              templatePackage?.status ===
                DistributionStatusStatus.DistributionIdle;
            const distributionStatus = isDeleting
              ? DeletionProgress.Running
              : templatePackage?.status || DistributionStatusStatus.Completed;
            const companySetting = () => {
              let settingToReturn: string[] | React.ReactNode = [];
              if (kind === "company") {
                settingToReturn = companies
                  .map((company: HasIdAndName) => company.name)
                  .toString();
              } else {
                settingToReturn = (
                  <Box display="flex" flexDirection="column">
                    {user_tags.map((tag: HasIdAndName) => (
                      <Box key={tag.id}>
                        <StyledUserTag label={tag.name} />
                      </Box>
                    ))}
                  </Box>
                );
              }
              return settingToReturn;
            };
            const TableRowComponent = isDeleting
              ? DisabledAdminTableRow
              : TableRow;
            return (
              <TableRowComponent
                key={`template-${templatePackage.id}`}
                data-cy="distribution-table-row"
                className={!isDeleting ? classes.root : undefined}
              >
                <TableCell align="left" data-cy="distribution-table-cell">
                  <StyledTag
                    label={t(distributionStatus)}
                    tagColor={getDistributionStatusColor(distributionStatus)}
                  />
                </TableCell>
                <TableCell align="left" data-cy="distribution-table-cell">
                  {templatePackage.learning_template_package.title}
                </TableCell>
                <TableCell align="left">
                  {dayjs(templatePackage.distribution_at).format("YYYY/MM/DD")}
                </TableCell>
                <TableCell align="left">
                  {getStartAndEndDate(
                    templatePackage.start_at,
                    templatePackage.end_at,
                  )}
                  {!isDeleting && (
                    <IconButton
                      onClick={() =>
                        handleModalState(
                          templatePackage,
                          LearningTemplateHistoryModalKind.EDIT,
                        )
                      }
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="left">{companySetting()}</TableCell>
                <TableCell align="left">
                  <Button
                    className={
                      !isDeleting ? classes.distributedCell : undefined
                    }
                    onClick={() =>
                      !isDeleting &&
                      handleModalState(
                        templatePackage,
                        LearningTemplateHistoryModalKind.SHOW,
                      )
                    }
                  >
                    <Box display="flex" flexDirection="column">
                      <span>
                        {templatePackage.number_of_school_distributed}校
                      </span>
                      <span>
                        {templatePackage.number_of_students_distributed}人
                      </span>
                    </Box>
                  </Button>
                </TableCell>
                <TableCell align="center">
                  {isDeletable && (
                    <V2IconButton
                      title="削除"
                      aria-label="Delete"
                      onClick={() =>
                        handleModalState(
                          templatePackage,
                          LearningTemplateHistoryModalKind.DELETE,
                        )
                      }
                    >
                      <DeleteIcon />
                    </V2IconButton>
                  )}
                </TableCell>
              </TableRowComponent>
            );
          },
        )}
      </TableBody>
    );
  }
  return null;
};

export default Body;
