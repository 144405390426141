/* eslint-disable camelcase */
import {
  Api,
  FetchHomeworkTemplateDistributionsAsReviewerParams,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchHomeworkTemplateDistributionsAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateDistribution/fetch",
  async (
    params: FetchHomeworkTemplateDistributionsAsReviewerParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.reviewer.fetchHomeworkTemplateDistributionsAsReviewer(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchHomeworkTemplateDistributionAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateDistribution/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.reviewer.fetchHomeworkTemplateDistributionAsReviewer(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserGroupAffiliatesPackagesAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateDistribution/userGroupAffiliatesPackages",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.reviewer.getUserGroupAffiliatesPackagesAsReviewer(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserTagsAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateDistribution/userTags",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.reviewer.getUserTagsAsReviewer(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface ReturnHomeworkTemplateDistributionToSchoolAsReviewerArg {
  id: string;
  params: {
    comment: string;
    returnAt: string;
    files: File[];
  };
}

export const returnToSchoolAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateDistribution/returnToSchool",
  async (
    arg: ReturnHomeworkTemplateDistributionToSchoolAsReviewerArg,
    { rejectWithValue },
  ) => {
    const {
      id,
      params: { comment, returnAt, files },
    } = arg;
    const fd = new FormData();

    if (comment) {
      fd.set("comment", comment);
    }
    if (returnAt) {
      fd.set("return_at", returnAt);
    }
    if (files.length) {
      files.forEach((file) => {
        fd.append("image_contents[]", file);
      });
    }

    try {
      const response = await http.put(
        `/reviewer/homework_template_distributions/${id}/return_to_school`,
        fd,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface UpdateHomeworkTemplateDistributionAsReviewerArg {
  id: string;
  params: {
    comment?: string;
    returnAt?: string;
    files?: File[];
  };
}

export const updateAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateDistribution/update",
  async (
    arg: UpdateHomeworkTemplateDistributionAsReviewerArg,
    { rejectWithValue },
  ) => {
    const {
      id,
      params: { comment, returnAt, files },
    } = arg;
    const fd = new FormData();

    if (comment) {
      fd.set("comment", comment);
    }
    if (returnAt) {
      fd.set("return_at", returnAt);
    }
    if (files && files.length) {
      files.forEach((file) => {
        fd.append("image_contents[]", file);
      });
    }
    try {
      const response = await http.put(
        `/reviewer/homework_template_distributions/${id}`,
        fd,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface RevertHomeworkTemplateDistributionToSchoolAsReviewerArg {
  id: string;
  params: {
    comment: string | null;
  };
}

export const revertToSchoolAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateDistribution/revertToSchool",
  async (
    arg: RevertHomeworkTemplateDistributionToSchoolAsReviewerArg,
    { rejectWithValue },
  ) => {
    const { id, params } = arg;
    try {
      const response =
        await api.reviewer.revertHomeworkTemplateDistributionToSchoolAsReviewer(
          id,
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteHomeworkTemplateDistributionImageAsReviewer =
  createAsyncThunk<
    any,
    { id: string; data: { image_content_id: string } },
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    `/reviewer/homework_template_distributions/id/delete_distribution_image`,
    async (params, { rejectWithValue }) => {
      try {
        const response =
          await api.reviewer.deleteHomeworkTemplateDistributionImageAsReviewer(
            params.id,
            params.data,
          );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    },
  );

export const mergeChunksInHomeworkTemplateDistributionAsReviewer =
  createAsyncThunk<
    any,
    { id: string },
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    `/reviewer/homeworkTemplateDistribution/mergeChunks`,
    async (params, { rejectWithValue }) => {
      try {
        const response =
          await api.reviewer.mergeChunksInHomeworkTemplateDistributionAsReviewer(
            params.id,
          );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    },
  );

export const resetModalState = createAction(
  "reviewer/homeworkTemplateDistribution/resetModalState",
);
