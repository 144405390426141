import MaterialList from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList/Modals/AddOrUpdatePackageModal/MaterialsList";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import { ITEMS_PER_PAGE } from "@constants/page";
import { ReduxStatus } from "@constants/redux";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import { BaseCategoryInfo } from "@lib/Api";
import { objectHas } from "@lib/object-utils";
import { Menu, TextField, Tooltip } from "@mui/material";
import { categoryState } from "@reducers/category";
import { useAppSelector } from "@root/hooks";
import { getMarkAttributes } from "@tiptap/core";
import React, { memo, useContext,useEffect, useState } from "react";

import useDOMParse from "../useDOMParse";
import { BubbleMenuContext } from "./BubbleMenuContext";
import { useStyles } from "./styles";

export const LinkGroup = (): JSX.Element => {
  const { editor, subMenuOpen, setSubMenuOpen, isOtherSubMenuOpen } =
    useContext(BubbleMenuContext);
  const classes = useStyles();
  const {
    getMaterialLink,
    fetchMaterialsAllCategories,
    fetchMaterialsAllCategoriesStatus,
    getMaterialsAllCategories,
    materialCount,
    canDisplayMaterialLink,
  } = useDOMParse();

  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [currentMaterialsPage, setCurrentMaterialsPage] = useState<number>(1);
  const { text, grade, subject, major, middle, minor } =
    useAppSelector(categoryState);

  const [linkValue, setLinkValue] = useState<string>("");
  const [linkInputAnchorEl, setLinkInputAnchorEl] =
    useState<null | HTMLElement>(null);

  const { hasMaterialLink } = useDOMParse();
  const [linkSaveButtonClicked, setLinkSaveButtonClicked] =
    useState<boolean>(false);

  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false);

  const disabled =
    isOtherSubMenuOpen("materialLinkGroup") || !canDisplayMaterialLink(editor);

  useEffect(() => {
    if (linkSaveButtonClicked) {
      editor?.commands.setMaterialLink({
        href: getMaterialLink(linkValue),
        "data-material-id": linkValue,
      });
      setLinkSaveButtonClicked(false);
    }
  }, [linkSaveButtonClicked]);

  const handleClose = () => {
    setSubMenuOpen({
      ...subMenuOpen,
      materialLinkGroup: false,
    });
    setLinkInputAnchorEl(null);
  };

  const handleSelectedIds = (itemValue: BaseCategoryInfo) => {
    setSelectedCategoryIds((prevCategory) => {
      return selectedCategoryIds.includes(itemValue.id)
        ? selectedCategoryIds.filter((item) => item !== itemValue.id)
        : [...prevCategory, itemValue.id];
    });
    fetchMaterialsAllCategories({
      category_ids: selectedCategoryIds,
      per_page: ITEMS_PER_PAGE,
      page: currentMaterialsPage,
    });
  };

  const handleMaterialsPagination = (newPage: number) => {
    fetchMaterialsAllCategories({
      category_ids: selectedCategoryIds,
      per_page: ITEMS_PER_PAGE,
      page: newPage,
    });
    setCurrentMaterialsPage(newPage);
  };

  return (
    <>
      <button
        type="button"
        onClick={(event) => {
          const attributes = getMarkAttributes(editor.state, "link");
          if (objectHas(attributes, "data-material-id")) {
            setLinkValue(attributes["data-material-id"]);
          } else {
            setLinkValue("");
          }

          if (!linkInputAnchorEl) {
            setSubMenuOpen({
              ...subMenuOpen,
              materialLinkGroup: true,
            });

            setLinkInputAnchorEl(event.currentTarget);
          } else {
            setSubMenuOpen({
              ...subMenuOpen,
              materialLinkGroup: true,
            });

            setLinkInputAnchorEl(null);
          }
        }}
        className={hasMaterialLink(editor) ? classes.isActive : classes.default}
        disabled={disabled}
      >
        <Tooltip title="教材用リンクを追加" placement="top">
          <>教材用リンク</>
        </Tooltip>
      </button>
      <GenericModal
        useSecondaryButton
        title="教材用リンクを追加"
        useActionButtons
        modalSize="md"
        handleClose={() => {
          setOpenSearchModal(false);
        }}
        secondaryButtonAction={() => {
          setOpenSearchModal(false);
        }}
        primaryButtonAction={() => {
          setOpenSearchModal(false);
        }}
        primaryButtonText="追加する"
        secondaryButtonText="キャンセル"
        open={openSearchModal}
      >
        <MaterialList
          materials={getMaterialsAllCategories()}
          handleChosePackage={(event) => setLinkValue(event.target.value)}
          chosenPackage={[linkValue]}
          selectedCategoryId={selectedCategoryIds}
          status={ModalStatus.ADD_PACKAGE}
          text={text}
          grade={grade}
          subject={subject}
          major={major}
          middle={middle}
          minor={minor}
          fetchingMaterialsByCategories={
            fetchMaterialsAllCategoriesStatus() === ReduxStatus.pending
          }
          handleSelectedIds={handleSelectedIds}
          currentMaterialsPage={currentMaterialsPage}
          handleMaterialsPagination={handleMaterialsPagination}
          totalMaterials={materialCount}
        />
      </GenericModal>

      <Menu
        keepMounted
        anchorEl={linkInputAnchorEl}
        open={Boolean(linkInputAnchorEl)}
        onClose={() => {
          setLinkValue("");
          handleClose();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={classes.linkMenuStyle}
      >
        <div
          style={{
            margin: "5px",
            display: "flex",
          }}
          className={classes.customTextField}
        >
          <TextField
            maxRows={4}
            variant="outlined"
            onChange={(event) => setLinkValue(event.target.value)}
            className={classes.customTextField}
            value={linkValue}
            placeholder="教材ID"
          />

          <button
            type="button"
            onClick={() => {
              setLinkSaveButtonClicked(true);
              editor
                .chain()
                .focus()
                .setMaterialLink({
                  href: getMaterialLink(linkValue),
                  "data-material-id": linkValue,
                })
                .run();
              handleClose();
            }}
            className={classes.saveButton}
          >
            挿入
          </button>
          <button
            type="button"
            onClick={() => {
              setOpenSearchModal(true);
            }}
            className={classes.saveButton}
          >
            検索
          </button>

          {hasMaterialLink(editor) && (
            <button
              type="button"
              onClick={() => {
                editor.chain().focus().unsetMaterialLink().run();
                setLinkValue("");
                handleClose();
              }}
              className={classes.deleteButton}
            >
              削除
            </button>
          )}
        </div>
      </Menu>
    </>
  );
};

export default memo(LinkGroup);
