/* eslint-disable camelcase */
import {
  createCategoryRenameMapAsAdmin,
  fetchCategoryRenameMapAsAdmin,
  resetOne,
  updateCategoryRenameMapAsAdmin,
} from "@actions/adminApp/categoryRenameMap";
import { CategoryRenameMap } from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface AdminAppCategoryRenameMapState {
  fetching: boolean;
  error: any;
  changing: boolean;
  changed: boolean;
  changeError: any;
  renameMap: CategoryRenameMap | null;
}

const initialState: AdminAppCategoryRenameMapState = {
  fetching: false,
  error: null,
  changing: false,
  changed: false,
  changeError: null,
  renameMap: null,
};

export const adminAppCategoryRenameMapSlice = createSlice({
  name: "Admin/CategoryRenameMap",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryRenameMapAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: null,
          renameMap: null,
        };
      })
      .addCase(fetchCategoryRenameMapAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          renameMap: typeof payload.id !== "undefined" ? payload : null,
        };
      })
      .addCase(fetchCategoryRenameMapAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: action.payload,
        };
      })
      .addCase(createCategoryRenameMapAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(createCategoryRenameMapAsAdmin.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
        };
      })
      .addCase(createCategoryRenameMapAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: errorWithMessage(action.payload),
        };
      })
      .addCase(updateCategoryRenameMapAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(updateCategoryRenameMapAsAdmin.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
        };
      })
      .addCase(updateCategoryRenameMapAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: errorWithMessage(action.payload),
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          fetching: false,
          changing: false,
          changed: false,
          changeError: null,
        };
      });
  },
});

export const adminAppCategoryRenameMapState = (
  state: RootState,
): AdminAppCategoryRenameMapState => state.adminApp.categoryRenameMap;

export default adminAppCategoryRenameMapSlice.reducer;
