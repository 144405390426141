/* eslint-disable react/no-array-index-key */
import {
  CommonNotification,
  NewNotifications,
} from "@actions/types/notification";
import ModalTransition from "@components/UI/atoms/ModalTransition";
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import { SelectedButton } from "@components/UIv2/molecules/V2Tabs";
import V2NotificationTabs from "@components/UIv2/molecules/V2Tabs/notification";
import { ITEMS_PER_PAGE } from "@constants/page";
import { useAuth } from "@contexts/Auth";
import useOptionFeature from "@lib/hooks/useOptionFeature";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { IconButton, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { usePatchNewNotificationCountAsCommonMutation } from "@root/endpoints/TimeTactApi/remainingApi";
import React from "react";
import { useTranslation } from "react-i18next";

import NotificationCard from "./Card/Card";
import LimitedTimeNotifications from "./LimitedTimeNotifications";
import StudentMessages from "./StudentMessages";
import useNewNotificationCount from "./useNewNotificationCount";

type Props = {
  currentPage: number;
  totalCount: number;
  handleUpdatePage: (newPage: number) => void;
  open: boolean;
  fetched: boolean;
  fetching: boolean;
  fetchError: string | null;
  handleClose: () => void;
  notifications: NewNotifications[] | CommonNotification[];
  changeNewNotificationState: () => void;
  setButtonState: React.Dispatch<React.SetStateAction<SelectedButton>>;
  buttonState: SelectedButton;
  allowedToDisplayLimitedTimeNotification?: boolean;
  postNotifications: ReturnType<
    typeof usePatchNewNotificationCountAsCommonMutation
  >[0];
  limitedTimeNotificationsCount: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: 0,
      padding: theme.spacing(2),
      background: theme.palette.grey[200],
      color: theme.palette.common.black,
    },
    dialogContent: {
      padding: theme.spacing(0),
      minWidth: 600,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 0),
        minWidth: 300,
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.common.black,
    },
    button: {
      margin: theme.spacing(1),
    },
    notificationContainer: {
      padding: theme.spacing(2, 0),
    },
  }),
);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    background: theme.palette.grey[200],
    flex: "display",
    justifyContent: "center",
  },
}))(MuiDialogActions);

const NotificationsModal: React.FC<Props> = ({
  currentPage,
  totalCount,
  handleUpdatePage,
  open,
  fetching,
  fetched,
  fetchError,
  handleClose,
  notifications,
  changeNewNotificationState,
  setButtonState,
  buttonState,
  allowedToDisplayLimitedTimeNotification,
  postNotifications,
  limitedTimeNotificationsCount,
}) => {
  const classes = useStyles();
  const { isCurrentRoles } = useAuth();
  const { t } = useTranslation("common");
  const { isUniversityCooperationFeature } = useOptionFeature();
  const { newLimitedTimeNotificationCount, newNotificationCount } =
    useNewNotificationCount();

  let notificationsMain: React.ReactNode;
  if (fetching) {
    notificationsMain = <CircularLoading />;
  }
  if (fetchError) {
    notificationsMain = <p>{fetchError}</p>;
  }
  if (fetched && notifications.length === 0) {
    notificationsMain = (
      <Box data-cy="notifications-empty">お知らせはありません。</Box>
    );
  }

  if (notifications && notifications.length) {
    notificationsMain = notifications.map((notification, index) => (
      <NotificationCard
        key={`notification-${index}`}
        notification={notification}
        handleClose={handleClose}
        changeNewNotificationState={changeNewNotificationState}
        postNotifications={postNotifications}
      />
    ));
  }

  const handleModalClose = () => {
    if (allowedToDisplayLimitedTimeNotification) {
      setButtonState(SelectedButton.Primary);
    }
    handleClose();
  };

  const renderPagination = (count: number) => (
    <V2Pagination
      totalCount={count}
      perPage={ITEMS_PER_PAGE}
      currentPage={currentPage}
      handlePageTransition={handleUpdatePage}
    />
  );

  let modalContent;
  switch (buttonState) {
    case SelectedButton.Secondary:
      modalContent = (
        <Box className={classes.notificationContainer}>
          <LimitedTimeNotifications
            postNotifications={postNotifications}
            handleClose={handleClose}
            changeNewNotificationState={changeNewNotificationState}
          />
          {renderPagination(limitedTimeNotificationsCount)}
        </Box>
      );
      break;
    case SelectedButton.Primary:
      modalContent = (
        <Box className={classes.notificationContainer}>
          {notificationsMain}
          {renderPagination(totalCount)}
        </Box>
      );
      break;
    case SelectedButton.Tertiary:
      modalContent = (
        <StudentMessages handleNotificationModalClose={handleClose} />
      );
      break;
    default:
      throw new Error("Unsupported button state");
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      fullScreen
      onClose={handleModalClose}
      TransitionComponent={ModalTransition}
      TransitionProps={{ onExited: changeNewNotificationState }}
    >
      <DialogTitle className={classes.title}>
        <Box display="flex" alignItems="center">
          <NotificationsOutlinedIcon />
          <Box pl={1} data-cy="notification-title">
            お知らせ
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleModalClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {allowedToDisplayLimitedTimeNotification && (
        <Box mx={2} my={2}>
          <V2NotificationTabs
            buttonState={buttonState}
            setButtonState={setButtonState}
            primary={t("notificationTitle")}
            secondary={t("currentNotifications")}
            tertiary={
              isUniversityCooperationFeature && isCurrentRoles.isStudent
                ? t("messageTab")
                : ""
            }
            tertiaryBadgeContent={0}
            primaryBadgeContent={newNotificationCount}
            secondaryBadgeContent={newLimitedTimeNotificationCount}
          />
        </Box>
      )}
      <DialogContent className={classes.dialogContent}>
        <Container
          style={{ height: "100%", width: "100" }}
          maxWidth={buttonState === SelectedButton.Tertiary ? "xl" : "md"}
        >
          {modalContent}
        </Container>
      </DialogContent>
      <DialogActions>
        <SingleButton
          variant="contained"
          startIcon={<CancelIcon />}
          onClick={handleModalClose}
          className={classes.button}
          data-cy="notification-close-button"
        >
          閉じる
        </SingleButton>
      </DialogActions>
    </Dialog>
  );
};

NotificationsModal.defaultProps = {
  allowedToDisplayLimitedTimeNotification: undefined,
};

export default NotificationsModal;
