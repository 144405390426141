import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  SponsorProjectAndTagsBase,
  superOwnerApi,
} from "@root/endpoints/TimeTactApi/superOwnerApi";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionRoot: {
      boxShadow: "none",
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    accordion: {
      margin: theme.spacing(2, 0),
    },
    accordionSummary: {
      backgroundColor: "rgba(0, 130, 103, 0.05)",
    },
    image: {
      width: "100px",
      minWidth: "100px",
      objectFit: "cover",
      overflow: "hidden",
    },
    projectName: {
      color: theme.palette.customColor.text,
      fontWeight: 400,
      fontSize: 16,
    },
    formGroup: {
      display: "flex",
      flexWrap: "wrap",
    },
  }),
);

type Props = {
  status: ModalStatus;
  project: SponsorProjectAndTagsBase;
  chosenPackage: string[];
  handleChosePackage: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PblProjectAccordion: React.FC<Props> = ({
  status,
  project,
  chosenPackage,
  handleChosePackage,
}) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [lectureLazyQuery, { data, isUninitialized, isFetching }] =
    superOwnerApi.endpoints.fetchSponsorLecturesAsSuperOwner.useLazyQuery();
  const lectures = data?.project_lectures || [];

  useEffect(() => {
    if (isExpanded && isUninitialized) {
      lectureLazyQuery({ projectId: project.id });
    }
  }, [isExpanded, isUninitialized]);

  return (
    <Accordion
      square
      key={project.id}
      classes={{
        root: classes.accordionRoot,
      }}
      className={classes.accordion}
      expanded={isExpanded}
      onChange={(_, expanded) => setIsExpanded(expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <Typography className={classes.projectName}>{project.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <FormGroup className={classes.formGroup}>
            {lectures.length ? (
              <>
                {lectures.map((lecture) => (
                  <FormControlLabel
                    key={lecture.id}
                    control={
                      <Checkbox
                        value={lecture.id}
                        checked={chosenPackage.includes(lecture.id)}
                        disabled={
                          status === ModalStatus.EDIT_ADDED_PACKAGE &&
                          !!chosenPackage.length &&
                          !chosenPackage.includes(lecture.id)
                        }
                        onChange={handleChosePackage}
                      />
                    }
                    label={lecture.name}
                  />
                ))}
              </>
            ) : (
              <Box m={2}>
                <Typography>データが見つかりませんでした。</Typography>
              </Box>
            )}
          </FormGroup>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default PblProjectAccordion;
