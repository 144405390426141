/* eslint-disable camelcase */
import {
  deleteSteamContentBookmarksAsTeacher,
  getAllSteamContentBookmarksAsTeacher,
  getContentsAndBookmarksAsTeacher,
  getContentsAndBookmarksWithLectureNumberAsTeacher,
  postSteamContentBookmarksAsTeacher,
  resetSteamContentBookmarksState,
} from "@actions/teacherApp/steam/contentBookmark";
import { SteamLibraryContentCard } from "@actions/types/steamLibrary";
import { ContentBookmarkBase } from "@lib/Api";
import { add,remove } from "@lib/collection";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export type BookmarkedContentWithLectureNumber = SteamLibraryContentCard & {
  lecture_number: number;
};
export interface TeacherAppSteamContentBookmarkState {
  getBookmarks: ReduxStatusType;
  getBookmarksError: string | null;
  getAllBookmarks: ReduxStatusType;
  getAllBookmarksError: string | null;
  getBookmarkedContentsWithLectureNumber: ReduxStatusType;
  bookmarks: ContentBookmarkBase[];
  bookmarkedContents: SteamLibraryContentCard[];
  bookmarkedContentsWithLectureNumber: BookmarkedContentWithLectureNumber[];
  allBookmarks: ContentBookmarkBase[];
  currentPage: number;
  totalCount: number;
  postBookmark: ReduxStatusType;
  postBookmarkError: any;
  deleteBookmark: ReduxStatusType;
  deleteBookmarkError: any;
}

const initialState: TeacherAppSteamContentBookmarkState = {
  getBookmarks: ReduxStatus.idle,
  getBookmarksError: null,
  getAllBookmarks: ReduxStatus.idle,
  getAllBookmarksError: null,
  getBookmarkedContentsWithLectureNumber: ReduxStatus.idle,
  bookmarks: [],
  bookmarkedContents: [],
  bookmarkedContentsWithLectureNumber: [],
  allBookmarks: [],
  currentPage: 1,
  totalCount: 0,
  postBookmark: ReduxStatus.idle,
  postBookmarkError: null,
  deleteBookmark: ReduxStatus.idle,
  deleteBookmarkError: null,
};

const teacherAppSteamContentBookmarkSlice = createSlice({
  name: "TeacherApp/SteamBookmark",
  initialState,
  reducers: {
    setBookmarkCurrentPage: (state, action: PayloadAction<number>) => {
      return { ...state, currentPage: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContentsAndBookmarksAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          getBookmarks: ReduxStatus.pending,
          getBookmarksError: null,
        };
      })
      .addCase(getContentsAndBookmarksAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          bookmarks: action.payload.bookmarks,
          bookmarkedContents: action.payload.bookmarkedContents,
          totalCount: action.payload.total_count,
          getBookmarks: ReduxStatus.fulfilled,
          getBookmarksError: null,
        };
      })
      .addCase(getContentsAndBookmarksAsTeacher.rejected, (state, _action) => {
        return {
          ...state,
          getBookmarks: ReduxStatus.rejected,
          getBookmarksError: "データが取得できませんでした。",
        };
      })
      .addCase(
        getContentsAndBookmarksWithLectureNumberAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            getBookmarkedContentsWithLectureNumber: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getContentsAndBookmarksWithLectureNumberAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            bookmarkedContentsWithLectureNumber:
              action.payload.bookmarkedContentsWithLectureNumber,
            getBookmarkedContentsWithLectureNumber: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        getContentsAndBookmarksWithLectureNumberAsTeacher.rejected,
        (state, _action) => {
          return {
            ...state,
            getBookmarkedContentsWithLectureNumber: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        getAllSteamContentBookmarksAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            getAllBookmarks: ReduxStatus.pending,
            getAllBookmarksError: null,
          };
        },
      )
      .addCase(
        getAllSteamContentBookmarksAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            allBookmarks: action.payload.bookmarks,
            getAllBookmarks: ReduxStatus.fulfilled,
            getAllBookmarksError: null,
          };
        },
      )
      .addCase(
        getAllSteamContentBookmarksAsTeacher.rejected,
        (state, _action) => {
          return {
            ...state,
            getAllBookmarks: ReduxStatus.rejected,
            getAllBookmarksError: "データが取得できませんでした。",
          };
        },
      )
      .addCase(postSteamContentBookmarksAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          postBookmark: ReduxStatus.pending,
          postBookmarkError: null,
        };
      })
      .addCase(
        postSteamContentBookmarksAsTeacher.fulfilled,
        (state, action) => {
          const { bookmarks, allBookmarks } = state;
          return {
            ...state,
            bookmarks: [...add([...bookmarks], action.payload)],
            allBookmarks: [...add([...allBookmarks], action.payload)],
            postBookmark: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(postSteamContentBookmarksAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          postBookmark: ReduxStatus.rejected,
          postBookmarkError: action.payload.errors
            .map((error: any) => {
              return error.message;
            })
            .join(),
        };
      })
      .addCase(
        deleteSteamContentBookmarksAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            deleteBookmark: ReduxStatus.pending,
            deleteBookmarkError: null,
          };
        },
      )
      .addCase(
        deleteSteamContentBookmarksAsTeacher.fulfilled,
        (state, action) => {
          const bookmarkId = action.meta.arg;
          const { bookmarks, allBookmarks } = state;
          return {
            ...state,
            bookmarks: remove(bookmarks, bookmarkId),
            allBookmarks: remove(allBookmarks, bookmarkId),
            deleteBookmark: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        deleteSteamContentBookmarksAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            deleteBookmark: ReduxStatus.rejected,
            deleteBookmarkError: action.payload.errors,
          };
        },
      )
      .addCase(resetSteamContentBookmarksState, (state, _action) => {
        return {
          ...state,
          getBookmarks: ReduxStatus.idle,
          getBookmarksError: null,
          getAllBookmarks: ReduxStatus.idle,
          getAllBookmarksError: null,
          postBookmark: ReduxStatus.idle,
          postBookmarkError: null,
          deleteBookmark: ReduxStatus.idle,
          deleteBookmarkError: null,
        };
      });
  },
});

export const teacherAppSteamContentBookmarkState = (
  state: RootState,
): TeacherAppSteamContentBookmarkState => state.teacherApp.steamContentBookmark;

export const { setBookmarkCurrentPage } =
  teacherAppSteamContentBookmarkSlice.actions;
export default teacherAppSteamContentBookmarkSlice.reducer;
