import { mobileTabs } from "@components/UI/organisms/Navigation/Categories/student";
import {
  MobileTab,
  STUDENT_TAB_TYPES,
  Tab,
} from "@components/UI/organisms/Navigation/Categories/types/navigationTypes";
import { STUDENT_ROUTES } from "@constants/routes";
import { StudyLogCategorizableType } from "@lib/Api";

import useOptionFeature from "./useOptionFeature";

type useStudentHeaderType = {
  getCurrentTabValue: (pathname: string) => STUDENT_TAB_TYPES;
  getTabValue: (state: string) => string | undefined;
  getRoutePath: (newValue: string) => string | undefined | false;
  getNewTabs: (tabs: Tab[]) => Tab[];
  getMobileTabs: (tabs: MobileTab[]) => MobileTab[];
  getMobileMenu: (displayItems: string[]) => MobileTab[];
};

enum VIEW {
  Mobile = "mobile",
  Desktop = "Desktop",
  Both = "Both",
}

const useStudentHeader = (): useStudentHeaderType => {
  const {
    isBlogFeature,
    isCalendarFeature,
    isGoogleClassroomFeature,
    isSteamLibraryFeature,
    isStudyLogFeature,
    isDashboardFeature,
    isIdealFeature,
    isHomeworkFeature,
    isMaterialFeature,
    isPblProjectsFeature,
  } = useOptionFeature();

  const commonMappings = [
    {
      view: VIEW.Both,
      value: STUDENT_TAB_TYPES.student_logs,
      hasFeature: isStudyLogFeature,
    },
    {
      view: VIEW.Desktop,
      value: STUDENT_TAB_TYPES.ideal,
      hasFeature: isIdealFeature,
    },
    {
      view: VIEW.Both,
      value: STUDENT_TAB_TYPES.dashboard,
      hasFeature: isDashboardFeature,
    },
    {
      view: VIEW.Both,
      value: STUDENT_TAB_TYPES.pbl_menu,
      hasFeature: isBlogFeature,
    },
    {
      view: VIEW.Both,
      value: STUDENT_TAB_TYPES.calendar,
      hasFeature: isCalendarFeature,
    },
    {
      view: VIEW.Both,
      value: STUDENT_TAB_TYPES.classroom,
      hasFeature: isGoogleClassroomFeature,
    },
    {
      view: VIEW.Both,
      value: STUDENT_TAB_TYPES.steam_library,
      hasFeature: isSteamLibraryFeature,
    },
    {
      view: VIEW.Desktop,
      value: STUDENT_TAB_TYPES.study_contents_menu,
      hasFeature: true,
    },
    {
      view: VIEW.Mobile,
      value: STUDENT_TAB_TYPES.materials,
      hasFeature: isMaterialFeature,
    },
    {
      view: VIEW.Mobile,
      value: STUDENT_TAB_TYPES.homework,
      hasFeature: isHomeworkFeature,
    },
    {
      view: VIEW.Both,
      value: STUDENT_TAB_TYPES.pbl_projects,
      hasFeature: isPblProjectsFeature,
    },
  ];

  const getCurrentTabValue = (pathname: string): STUDENT_TAB_TYPES => {
    const mappings = [
      { path: STUDENT_ROUTES.DASHBOARD, tabType: STUDENT_TAB_TYPES.dashboard },
      {
        path: STUDENT_ROUTES.STUDENT_LOGS,
        tabType: STUDENT_TAB_TYPES.student_logs,
      },
      {
        path: STUDENT_ROUTES.STUDY_CONTENTS_MENU,
        tabType: STUDENT_TAB_TYPES.study_contents_menu,
      },
      { path: STUDENT_ROUTES.IDEAL, tabType: STUDENT_TAB_TYPES.ideal },
      {
        path: [
          STUDENT_ROUTES.PBL_PROJECTS,
          STUDENT_ROUTES.PBL_PROJECT_PROJECT_DETAIL,
          STUDENT_ROUTES.PBL_PROJECTS_LECTURE,
        ],
        tabType: STUDENT_TAB_TYPES.pbl_projects,
      },
      {
        path: [
          STUDENT_ROUTES.PBL_MENU,
          STUDENT_ROUTES.ARTICLES,
          STUDENT_ROUTES.PBL_REPORTS,
          STUDENT_ROUTES.PBL_BOOKMARKS,
        ],
        tabType: STUDENT_TAB_TYPES.pbl_menu,
      },
      { path: STUDENT_ROUTES.STEAM, tabType: STUDENT_TAB_TYPES.steam_library },
      { path: STUDENT_ROUTES.CALENDAR, tabType: STUDENT_TAB_TYPES.calendar },
      { path: STUDENT_ROUTES.CLASSROOM, tabType: STUDENT_TAB_TYPES.classroom },
      {
        path: [
          STUDENT_ROUTES.MATERIALS,
          STUDENT_ROUTES.MATERIAL_WORKS,
          STUDENT_ROUTES.HOMEWORK,
          STUDENT_ROUTES.LEARNING_PACKAGES,
        ],
        tabType: STUDENT_TAB_TYPES.study_contents_menu,
      },
    ];

    const mapping = mappings.find((currentTabValueMapping) => {
      const paths = Array.isArray(currentTabValueMapping.path)
        ? currentTabValueMapping.path
        : [currentTabValueMapping.path];
      return paths.some((path) => pathname.includes(path));
    });

    return mapping?.tabType ?? STUDENT_TAB_TYPES.dashboard;
  };

  const getTabValue = (state: string): string | undefined => {
    const mappings = [
      {
        state: STUDENT_TAB_TYPES.pbl_projects,
        tabType: STUDENT_TAB_TYPES.pbl_projects,
      },
      {
        state: STUDENT_TAB_TYPES.student_logs,
        tabType: STUDENT_TAB_TYPES.student_logs,
      },
      {
        state: STUDENT_TAB_TYPES.dashboard,
        tabType: STUDENT_TAB_TYPES.dashboard,
      },
      {
        state: StudyLogCategorizableType.Homework,
        tabType: STUDENT_TAB_TYPES.study_contents_menu,
      },
      {
        state: StudyLogCategorizableType.Article,
        tabType: STUDENT_TAB_TYPES.pbl_menu,
      },
      {
        state: StudyLogCategorizableType.Schedule,
        tabType: STUDENT_TAB_TYPES.calendar,
      },
      {
        state: StudyLogCategorizableType.MaterialWork,
        tabType: STUDENT_TAB_TYPES.study_contents_menu,
      },
      {
        state: StudyLogCategorizableType.PblCardsInformationLiteracyBookmark,
        tabType: STUDENT_TAB_TYPES.pbl_menu,
      },
      {
        state: StudyLogCategorizableType.PblReportAnswer,
        tabType: STUDENT_TAB_TYPES.pbl_menu,
      },
      {
        state: StudyLogCategorizableType.PblSteamComment,
        tabType: STUDENT_TAB_TYPES.steam_library,
      },
      {
        state: StudyLogCategorizableType.PblSteamContentWork,
        tabType: STUDENT_TAB_TYPES.steam_library,
      },
    ];

    const mapping = mappings.find(
      (tabValueMapping) => tabValueMapping.state === state,
    );
    return mapping?.tabType;
  };

  const getRoutePath = (newValue: string): string | undefined | false => {
    const mappings = [
      {
        value: STUDENT_TAB_TYPES.dashboard,
        hasFeature: isDashboardFeature,
        route: STUDENT_ROUTES.DASHBOARD,
      },
      {
        value: STUDENT_TAB_TYPES.student_logs,
        hasFeature: isStudyLogFeature,
        route: STUDENT_ROUTES.STUDENT_LOGS,
      },
      {
        value: STUDENT_TAB_TYPES.study_contents_menu,
        hasFeature: true,
        route: STUDENT_ROUTES.STUDY_CONTENTS_MENU,
      },
      {
        value: STUDENT_TAB_TYPES.ideal,
        hasFeature: isIdealFeature,
        route: STUDENT_ROUTES.IDEAL,
      },
      {
        value: STUDENT_TAB_TYPES.pbl_menu,
        hasFeature: isBlogFeature,
        route: STUDENT_ROUTES.PBL_MENU,
      },
      {
        value: STUDENT_TAB_TYPES.pbl_projects,
        hasFeature: isPblProjectsFeature,
        route: STUDENT_ROUTES.PBL_PROJECTS,
      },
      {
        value: STUDENT_TAB_TYPES.calendar,
        hasFeature: isCalendarFeature,
        route: STUDENT_ROUTES.CALENDAR,
      },
      {
        value: STUDENT_TAB_TYPES.classroom,
        hasFeature: isGoogleClassroomFeature,
        route: STUDENT_ROUTES.CLASSROOM,
      },
      {
        value: STUDENT_TAB_TYPES.steam_library,
        hasFeature: isSteamLibraryFeature,
        route: STUDENT_ROUTES.STEAM,
      },
    ];

    const mapping = mappings.find(
      (routeMapping) =>
        routeMapping.value === newValue && routeMapping.hasFeature,
    );
    return mapping?.route;
  };

  const getNewTabs = (tabs: Tab[]): Tab[] => {
    const desktopMappings = commonMappings.filter(
      (mapping) => mapping.view === VIEW.Both || mapping.view === VIEW.Desktop,
    );

    const newTabs = tabs.filter((tab) => {
      const mapping = desktopMappings.find((m) => m.value === tab.value);
      const hasFeature = mapping?.hasFeature ?? false;
      return hasFeature;
    });
    return newTabs;
  };

  const getMobileTabs = (tabs: MobileTab[]): MobileTab[] => {
    const mobileMappings = commonMappings.filter(
      (mapping) => mapping.view === VIEW.Both || mapping.view === VIEW.Mobile,
    );
    const newMobileTabs = tabs.filter((tab) => {
      const mapping = mobileMappings.find((m) => m.value === tab.value);
      const newTab = mapping ? mapping.hasFeature : tab;
      return newTab;
    });
    return newMobileTabs;
  };

  const getMobileMenu = (displayItems: string[]): MobileTab[] => {
    return getMobileTabs(mobileTabs).filter((tab) => {
      return displayItems.includes(tab.value);
    });
  };

  return {
    getTabValue,
    getCurrentTabValue,
    getRoutePath,
    getNewTabs,
    getMobileTabs,
    getMobileMenu,
  };
};

export default useStudentHeader;
