import {
  fetchPblSponsorLectureAsContentProvider,
  resetOne,
} from "@actions/contentProviderApp/pbl/sponsor/lecture";
import { SponsorLectureDetailsBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface ContentProviderAppPblSponsorLectureState {
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneErrors: string | null;
  lectureDetails: SponsorLectureDetailsBase | null;
}

export const initialState: ContentProviderAppPblSponsorLectureState = {
  fetchingOne: false,
  fetchedOne: false,
  fetchOneErrors: null,
  lectureDetails: null,
};

const contentProviderAppPblSponsorLectureSlice = createSlice({
  name: "contentProviderApp/pbl/sponsors/lecture",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPblSponsorLectureAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingOne: true,
            fetchedOne: false,
          };
        },
      )
      .addCase(
        fetchPblSponsorLectureAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: false,
            fetchOneError: action.error.message as string,
          };
        },
      )
      .addCase(
        fetchPblSponsorLectureAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: true,
            fetchOneError: null,
            lectureDetails: payload,
          };
        },
      )
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          lectureDetails: null,
          fetchedOne: false,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const contentProviderAppPblSponsorLectureState = (
  state: RootState,
): ContentProviderAppPblSponsorLectureState =>
  state.contentProviderApp.pblSponsorLecture;

export default contentProviderAppPblSponsorLectureSlice.reducer;
