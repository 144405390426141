import { PostNotificationAsAdminParams } from "@actions/types/notification";
import { Api, NotificationsListParams } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchNotificationsSentAsAdmin = createAsyncThunk<
  any,
  NotificationsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/notificationsSent/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.notificationsList({
      ...params,
      sent: "sent",
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const postNotificationAsAdmin = createAsyncThunk<
  any,
  PostNotificationAsAdminParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/notifications/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.notificationsCreate(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetFormState = createAction("admin/notifications/resetState");
