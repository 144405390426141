import { Payment } from "@lib/Api";
import { update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";

import {
  addPaymentAsTeacher,
  fetchPaymentsAsTeacher,
  resetTeacherAppPaymentCreateState,
  resetTeacherAppPaymentUpdateState,
  updatePaymentAsTeacher,
} from "../../actions/teacherApp/payment";
import { RootState } from "../../store";

export interface TeacherAppPaymentState {
  fetching: boolean;
  fetched: boolean;
  updating: boolean;
  updated: boolean;
  adding: boolean;
  added: boolean;
  error: any;
  payments: Payment[];
  totalCount: number;
}

export const initialState: TeacherAppPaymentState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  adding: false,
  added: false,
  error: null,
  payments: [],
  totalCount: 0,
};

const teacherAppPaymentSlice = createSlice({
  name: "TeacherApp/Payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchPaymentsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: action.error.message,
        };
      })
      .addCase(fetchPaymentsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          payments: payload.payments,
          totalCount: payload.total_count,
        };
      })

      .addCase(addPaymentAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          adding: true,
          added: false,
        };
      })
      .addCase(addPaymentAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          adding: false,
          added: false,
          error: action.error.message,
        };
      })
      .addCase(addPaymentAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          adding: false,
          added: true,
          payments: [payload, ...currentState.payments],
          totalCount: state.totalCount + 1,
        };
      })
      .addCase(updatePaymentAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(updatePaymentAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          error: action.error.message,
        };
      })
      .addCase(updatePaymentAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          payments: update(currentState.payments, payload),
        };
      })
      .addCase(resetTeacherAppPaymentCreateState, (state, _action) => {
        return {
          ...state,
          adding: false,
          added: false,
          error: null,
        };
      })
      .addCase(resetTeacherAppPaymentUpdateState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          error: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppPaymentState = (
  state: RootState,
): TeacherAppPaymentState => state.teacherApp.payment;

export default teacherAppPaymentSlice.reducer;
