import { grades } from "@root/constants/grades";

export function getGradeValue(grade: string): string | undefined {
  if (!grade) return;
  let gradeValue = "";

  grades.forEach((gradeBase) => {
    if (grade.includes(gradeBase.name)) {
      gradeValue = gradeBase.value;
    }
  });
  // eslint-disable-next-line consistent-return
  return gradeValue;
}

export function getGradeName(grade: string): string | undefined {
  if (!grade) return;
  let gradeName = "";

  grades.forEach((gradeBase) => {
    if (grade.includes(gradeBase.value)) {
      gradeName = gradeBase.name;
    }
  });
  // eslint-disable-next-line consistent-return
  return gradeName;
}
