/* eslint-disable camelcase */
import { Payment } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import { fetchPaymentsAsOwner } from "../../actions/ownerApp/payment";
import { RootState } from "../../store";

export interface OwnerAppPaymentState {
  isFetching: boolean;
  payments: Payment[];
  error?: string;
  totalCount: number;
}

export const initialState: OwnerAppPaymentState = {
  isFetching: false,
  payments: [],
  error: "",
  totalCount: 0,
};

const ownerAppPaymentSlice = createSlice({
  name: "OwnerApp/Payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          isFetching: true,
          payments: [],
        };
      })
      .addCase(fetchPaymentsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          error: action.error.message,
          payments: [],
          totalCount: 0,
        };
      })
      .addCase(fetchPaymentsAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          isFetching: false,
          payments: payload.payments,
          totalCount: payload.total_count,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppPaymentState = (state: RootState): OwnerAppPaymentState =>
  state.ownerApp.payment;

export default ownerAppPaymentSlice.reducer;
