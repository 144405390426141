import ColumnHeadline from "@assets/images/svg/columnHeadline.svg?react";
import DragIndicatorGreenIcon from "@assets/images/svg/DragIndicatorGreenIcon.svg?react";
import RowHeadline from "@assets/images/svg/rowHeadline.svg?react";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Switch,
  SwitchClassKey,
  SwitchProps,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import originalTheme from "@root/styles/theme";
import {
  findCell,
  isInTable,
  selectedRect,
  selectionCell,
  TableMap,
} from "@tiptap/pm/tables";
import { Editor, NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";

import { getCheckBoxImage } from "../../useSVEditorStyles";
import {
  initialBorderWidth,
  initialCellHeight,
  initialColSize,
  initialRowSize,
} from "./SVTable";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface HeadlineSwitchProps extends SwitchProps {
  classes: Styles;
}
// 列見出し、行見出しの隣にあるスイッチ
const HeadlineSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 3,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 5,
    "&$checked": {
      transform: "translate(15px, -1px)",
      color: theme.palette.common.white,
      "&::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
        backgroundImage: getCheckBoxImage(theme.palette.tertiary.mainDark),
        left: 6,
      },
      "& + $track": {
        backgroundColor: theme.palette.tertiary.mainDark,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.tertiary.mainDark,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "rgba(71, 81, 73, 0.3)",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: HeadlineSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const TableAddHight = 28;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = ({
  index = 0,
  colposition = 0,
  cellHeight = initialCellHeight,
  colWidths = [0],
}) =>
  makeStyles(() =>
    createStyles({
      tableAddTop: {
        position: "absolute",
        top: `${index * cellHeight + cellHeight / 4}px`,
        left: 0,
        width: 5,
        backgroundColor: "none",
        border: 0,
      },
      tableAddLeft: {
        position: "absolute",
        top: -10,
        left: `${colposition + colWidths[index] / 2}px`,
        height: 5,
        backgroundColor: "none",
        border: 0,
        transform: "rotate(90deg)",
      },
      tableAddRight: {
        position: "absolute",
        bottom: 0,
        width: TableAddHight,
        height: "100%",
        backgroundColor: "rgba(71, 81, 73, 0.1);",
        zIndex: 100,
      },
      tableAddButtom: {
        position: "absolute",
        bottom: -27,
        left: 20,
        width: "100%",
        height: TableAddHight,
        backgroundColor: "rgba(71, 81, 73, 0.1);",
      },
      tableAddRightButtom: {
        position: "absolute",
        bottom: -27,
        width: TableAddHight,
        height: TableAddHight,
        backgroundColor: "rgba(71, 81, 73, 0.1);",
      },
      clickButton: {
        border: "2px solid grey",
        borderRadius: "6px",
      },
      clickAddButton: {
        backgroundColor: "rgba(71, 81, 73, 0.1);",
        width: "100%",
        height: "100%",
        borderRadius: 0,
      },
      ListItemIconStyle: {
        minWidth: "32px !important",
      },
    }),
  );

// 選択されたセルの枠のスタイル
const borderStyle = `3px solid ${originalTheme.palette.tertiary.mainDark}`;

type Props = {
  editor: Editor;
  index: number;
};

// 選択されたセルの右側に出るボタンの位置決めの際に必要な値
const getCellHight = (editor: Editor) => {
  const { state } = editor.view;
  if (!isInTable(editor.view.state)) {
    return initialCellHeight;
  }
  const tableRect = selectedRect(state);

  if (tableRect.map.map && tableRect.map.map.length > 0) {
    const dom = editor.view.nodeDOM(
      tableRect.tableStart + tableRect.map.map[0],
    );
    return dom?.pmViewDesc?.contentDOM?.offsetHeight || initialCellHeight;
  }
  return initialCellHeight;
};

// テーブルのスタイルを初期化する
// (選択されていたセルの枠などのスタイルを初期化する)
const initTable = (editor: Editor): void => {
  editor.view.state.doc.descendants((node, pos) => {
    if (node.type.name === "tableCell" && node.attrs.border) {
      const transaction = editor.view.state.tr.setNodeMarkup(pos, null, {
        border: null,
      });
      editor.view.dispatch(transaction);
    }

    if (node.type.name === "tableRow") {
      const transaction = editor.view.state.tr.setNodeMarkup(pos, null, {
        border: "none",
      });
      editor.view.dispatch(transaction);
    }
  });
  const cols = editor.view.dom.querySelectorAll("div.table-wrapper table col");
  Array.from(cols).forEach((col, i) => {
    (cols[i] as HTMLElement).style.border = "none";
  });
};

type selectedCellPosition = {
  col: number; // 選択されたセルの行
  row: number; // 選択されたセルの列
  pos: number; // セルのテーブル内部での位置情報
  tableStart: number; // テーブルの位置情報
};

// 選択されたセルの情報を返す(カーソルがあるセル)
const getCellPositionWithCursor = (
  editor: Editor,
): selectedCellPosition | null => {
  if (isInTable(editor.view.state)) {
    const resolvedPos = selectionCell(editor.view.state);
    const cell = findCell(resolvedPos);
    const tableRect = selectedRect(editor.view.state);
    const { tableStart } = tableRect;
    const posInTable = resolvedPos.pos - tableStart;
    return {
      col: cell.left,
      row: cell.top,
      pos: posInTable,
      tableStart,
    };
  }
  return null;
};

const cellFocus = (editor: Editor) => {
  editor.off("update");
  editor.chain().focus().setCellAttribute("border", borderStyle).run();
  editor.on("update", () => cellFocus(editor));
};

// セルを選択したときに、左側に出るボタンをクリックした際に出るメニュー
const RowMenu = (props: Props): JSX.Element => {
  const { editor, index } = props;
  const [topAnchorEl, setTopAnchorEl] = useState<null | HTMLElement>(null);

  const classes = useStyles({})();
  const cellHeight = getCellHight(editor);
  const [checked, setChecked] = useState(false);

  return (
    <>
      <div className={useStyles({ index, cellHeight })().tableAddTop}>
        <IconButton
          aria-label="add-column"
          onClick={(event) => {
            // ボタンが押下された際に列全体の枠の色を変える処理
            editor.off("update");
            setTopAnchorEl(event.currentTarget);
            initTable(editor);
            const tableRect = selectedRect(editor.view.state);
            tableRect.table.forEach((node, offset, innerRowIndex) => {
              if (innerRowIndex === index) {
                const transaction = editor.view.state.tr.setNodeMarkup(
                  tableRect.tableStart + offset,
                  null,
                  {
                    border: borderStyle,
                  },
                );
                editor.view.dispatch(transaction);
              }
            });
            editor.on("update", () => cellFocus(editor));
          }}
          className={useStyles({ index })().clickButton}
          style={{
            ...(!topAnchorEl && { width: "50%" }),
            ...(topAnchorEl && { border: "none" }),
            height: "28px",
            ...(topAnchorEl && { padding: 0 }),
          }}
          size="large"
        >
          {topAnchorEl ? (
            <DragIndicatorGreenIcon width={25} height={25} />
          ) : (
            <DragIndicatorIcon />
          )}
        </IconButton>
      </div>
      <Menu
        anchorEl={topAnchorEl}
        open={Boolean(topAnchorEl)}
        onClose={() => setTopAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          <MenuItem>
            <ListItemIcon className={classes.ListItemIconStyle}>
              <RowHeadline />
            </ListItemIcon>
            <FormControlLabel
              control={
                <HeadlineSwitch
                  checked={checked}
                  onChange={() => {
                    editor.commands.toggleHeaderRow();
                    setChecked(!checked);
                    setTopAnchorEl(null);
                  }}
                />
              }
              labelPlacement="start"
              label="行見出し"
              style={{
                margin: 0,
              }}
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              editor.chain().addRowBefore().run();
              setTopAnchorEl(null);
            }}
          >
            <ListItemIcon className={classes.ListItemIconStyle}>
              <ArrowUpwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="上に挿入" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              editor.chain().addRowAfter().run();
              setTopAnchorEl(null);
            }}
          >
            <ListItemIcon className={classes.ListItemIconStyle}>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="下に挿入" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              editor.chain().focus().deleteRow().run();
              setTopAnchorEl(null);
            }}
          >
            <ListItemIcon className={classes.ListItemIconStyle}>
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="削除" />
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

// セルを選択したときに、上側に出るボタンをクリックした際に出るメニュー
const ColMenu = (
  props: Props & {
    colWidths: number[];
  },
): JSX.Element => {
  const { editor, index, colWidths } = props;
  const classes = useStyles({})();
  const [leftAnchorEl, setLeftAnchorEl] = useState<null | HTMLElement>(null);
  const [checked, setChecked] = useState(false);

  const leftPosition = useMemo(() => {
    if (index === 0 && colWidths.length === 0) {
      return 0;
    }
    const arr = _.slice(colWidths, 0, index);
    if (arr.length === 0) {
      return 0;
    }
    return arr.reduce((a, b) => {
      return a + b;
    }, 0);
  }, [colWidths, index]);

  return (
    <>
      <div
        className={
          useStyles({
            index,
            colposition: leftPosition,
            colWidths,
          })().tableAddLeft
        }
      >
        <IconButton
          aria-label="add-column"
          onClick={(event) => {
            // ボタンが押下された際に行全体の枠の色を変える処理
            editor.off("update");
            initTable(editor);
            const cols = editor.view.dom.getElementsByTagName("col");
            cols[index].style.border = borderStyle;
            setLeftAnchorEl(event.currentTarget);
            editor.on("update", () => cellFocus(editor));
          }}
          className={classes.clickButton}
          style={{
            padding: 0,
            width: "20px",
            ...(leftAnchorEl && { border: "none" }),
          }}
          size="large"
        >
          {leftAnchorEl ? (
            <DragIndicatorGreenIcon width={36} height={25} />
          ) : (
            <DragIndicatorIcon />
          )}
        </IconButton>
      </div>
      <Menu
        anchorEl={leftAnchorEl}
        open={Boolean(leftAnchorEl)}
        onClose={() => setLeftAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          <MenuItem>
            <ListItemIcon className={classes.ListItemIconStyle}>
              <ColumnHeadline />
            </ListItemIcon>
            <FormControlLabel
              control={
                <HeadlineSwitch
                  checked={checked}
                  onChange={() => {
                    editor.commands.toggleHeaderColumn();
                    setLeftAnchorEl(null);
                    setChecked(!checked);
                  }}
                />
              }
              labelPlacement="start"
              label="列見出し"
              style={{
                margin: 0,
              }}
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              editor.chain().addColumnBefore().run();
              setLeftAnchorEl(null);
            }}
          >
            <ListItemIcon className={classes.ListItemIconStyle}>
              <ArrowBackIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="左に挿入" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              editor.chain().addColumnAfter().run();
              setLeftAnchorEl(null);
            }}
          >
            <ListItemIcon className={classes.ListItemIconStyle}>
              <ArrowForwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="右に挿入" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              editor.chain().focus().deleteColumn().run();
              setLeftAnchorEl(null);
            }}
          >
            <ListItemIcon className={classes.ListItemIconStyle}>
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="削除" />
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export const TableOperationComponent = ({
  editor,
}: {
  editor: Editor;
}): JSX.Element => {
  const { state } = editor.view;
  const classes = useStyles({})();
  const [display, setDisplay] = useState<{ display: string }>({
    display: "none",
  });
  const initialColWidths = [0, 0];
  const initialSelectedCellPosition = {
    col: -1,
    row: -1,
    pos: -1,
    tableStart: -1,
  };
  const [selectedCellPosition, setSelectedCellPosition] =
    useState<selectedCellPosition>(initialSelectedCellPosition);

  const { colSize } = useMemo(() => {
    if (isInTable(state)) {
      const tableRect = selectedRect(state);
      const tableInfo = TableMap.get(tableRect.table);
      return { rowSize: tableInfo.height, colSize: tableInfo.width };
    }
    return { rowSize: initialRowSize, colSize: initialColSize };
  }, [state]);

  // セルの横幅が可変なので、各列の横幅を取得する
  const colWidths = useMemo(() => {
    if (!isInTable(state)) {
      return initialColWidths;
    }
    const tableRect = selectedRect(state);

    if (!(tableRect.map.map && tableRect.map.map.length > 0)) {
      return initialColWidths;
    }
    return _.slice(tableRect.map.map, 0, colSize).map((pos) => {
      const dom = editor.view.nodeDOM(tableRect.tableStart + pos);
      return dom?.pmViewDesc?.contentDOM?.clientWidth || 400;
    });
  }, [state, colSize]);

  // テーブル全体の幅を各列の横幅が変わるたびに再計算する
  const tableWidth = useMemo(() => {
    return colWidths.reduce((a, b) => {
      return a + b;
    }, 0);
  }, [colWidths]);

  // 選択されたセルを取得する。また選択されたセルの枠の色を変える
  const setColorForSelectedCell = () => {
    const cell = getCellPositionWithCursor(editor);
    if (
      cell &&
      cell.row > -1 &&
      cell.col > -1 &&
      JSON.stringify(cell) !== JSON.stringify(selectedCellPosition)
    ) {
      const cellDom = editor.view.dom.querySelector(
        `div.table-wrapper table tr:nth-child(${cell.row + 1}) td:nth-child(${
          cell.col + 1
        })`,
      );
      if (cellDom && !(cellDom as HTMLElement).style.border) {
        initTable(editor);
        (cellDom as HTMLElement).style.border = borderStyle;
      }
      setSelectedCellPosition({ ...cell });
    }
  };

  // セルの選択が変わるたびに、テーブルの色を初期状態に戻して、選択されたセルの枠を変える
  // またカーソルがテーブルから外れた場合にも、テーブルを初期状態にする
  const selectionUpdateFunction = () => {
    editor.off("selectionUpdate");
    if (isInTable(editor.view.state)) {
      setDisplay({ display: "block" });
      setColorForSelectedCell();
    } else {
      setDisplay({ display: "none" });
      initTable(editor);
    }
    editor.on("selectionUpdate", selectionUpdateFunction);
  };

  // イベントを設定する
  useEffect(() => {
    editor.on("update", () => cellFocus(editor));

    editor.on("selectionUpdate", selectionUpdateFunction);
  }, []);

  return (
    <NodeViewWrapper className="table-wrapper">
      <div
        style={{
          position: "relative",
          width: "80%",
        }}
        onMouseLeave={() => {
          setDisplay({ display: "none" });
          editor.off("update");
          initTable(editor);
          editor.on("update", () => cellFocus(editor));
        }}
      >
        {/* 選択したセルの上部と左部に出るボタン*/}
        {selectedCellPosition.row > -1 && selectedCellPosition.col > -1 && (
          <div style={display}>
            <ColMenu
              editor={editor}
              index={selectedCellPosition.col}
              colWidths={colWidths}
            />
            <RowMenu editor={editor} index={selectedCellPosition.row} />
          </div>
        )}

        {/* テーブルの行を追加するボタン */}
        <div
          className={classes.tableAddRight}
          style={{
            ...display,
            left:
              tableWidth + TableAddHight + (colSize - 4) * initialBorderWidth, // テーブルの幅には枠の幅が含まれないので枠の幅も含めた値を計算する
          }}
        >
          <IconButton
            aria-label="add-column"
            onClick={() => {
              editor.chain().focus().addColumnAfter().run();
            }}
            className={classes.clickAddButton}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </div>

        {/* テーブルの列を追加するボタン */}
        <div
          className={classes.tableAddButtom}
          style={{
            ...display,
            width: tableWidth + colSize * initialBorderWidth,
          }}
        >
          <IconButton
            aria-label="add-row"
            onClick={() => {
              editor.chain().focus().addRowAfter().run();
            }}
            className={classes.clickAddButton}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </div>

        {/* テーブルの右下角に出るプラスボタン */}
        <div
          className={classes.tableAddRightButtom}
          style={{
            ...display,
            left:
              tableWidth + TableAddHight + (colSize - 4) * initialBorderWidth, // テーブルの幅には枠の幅が含まれないので枠の幅も含めた値を計算する
          }}
        >
          <IconButton
            aria-label="add-row-column"
            onClick={() => {
              editor
                .chain()
                .focus()
                .addColumnAfter()
                .focus()
                .addRowAfter()
                .run();
            }}
            className={classes.clickAddButton}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </div>

        {/* テーブルの描画 */}
        <NodeViewContent as="table" style={{ margin: "24px" }} />
      </div>
    </NodeViewWrapper>
  );
};

export default TableOperationComponent;
