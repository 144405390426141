import { createAction } from "@reduxjs/toolkit";
import {
  SnackbarAction,
  SnackbarKey,
  SnackbarMessage,
  VariantType,
} from "notistack";

export interface EnqueueToastPayload {
  message: SnackbarMessage;
  id: SnackbarKey;
  options: {
    variant: VariantType;
    action?: SnackbarAction;
  };
}

export interface CloseToastPayload {
  id: SnackbarKey;
  dismissAll: boolean;
}

export interface RemoveToastPayload {
  id: SnackbarKey;
}

export const enqueueToast = createAction<EnqueueToastPayload, "toast/enqueue">(
  "toast/enqueue",
);

export const closeToast = createAction<CloseToastPayload, "toast/close">(
  "toast/close",
);

export const removeToast = createAction<RemoveToastPayload, "toast/remove">(
  "toast/remove",
);
