import { materialsTargetUsersDetailType } from "@actions/ownerApp/types/materialWork";
import { OwnerMaterialWork } from "@lib/Api";
import { removeMany } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import {
  createMaterialWorksAsOwner,
  deletedChangedMaterialWorksAsOwnerByUser,
  deleteMaterialWorksAsOwner,
  fetchMaterialsTargetUsersDetail,
  fetchMaterialWorksAsOwner,
  resetDistributeActionData,
} from "../../actions/ownerApp/materialWork";
import { RootState } from "../../store";

export interface OwnerAppMaterialWorkState {
  fetching: boolean;
  fetchedOnce: boolean;
  fetchingInModal: boolean;
  fetchedInModal: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchError: any;
  fetchErrorInModal: any;
  fetchOneError: any;
  fetchingMaterialsTargetUsersDetail: boolean;
  fetchedMaterialsTargetUsersDetail: boolean;
  fetchMaterialsTargetUsersDetailError: any;
  materialsTargetUsersDetail: materialsTargetUsersDetailType;
  changing: boolean; // Create or Update one or multiple material works
  createMaterialWorksAsOwnerChanged: boolean;
  createMaterialWorksAsOwnerChanging: boolean;
  createMaterialWorksAsOwnerChangeError: any;
  deleting: boolean;
  deleted: boolean;
  deleteError: any;
  materialWorks: OwnerMaterialWork[];
  totalCount: number;
  materialWorksInModal: OwnerMaterialWork[];
  deletedMaterialWorks: boolean;
}

const initialState: OwnerAppMaterialWorkState = {
  fetching: false,
  fetchingInModal: false,
  fetchedOnce: false,
  fetchedInModal: false,
  fetchingOne: false,
  fetchedOne: false,
  fetchError: null,
  fetchErrorInModal: null,
  fetchOneError: null,
  fetchingMaterialsTargetUsersDetail: false,
  fetchedMaterialsTargetUsersDetail: false,
  fetchMaterialsTargetUsersDetailError: null,
  materialsTargetUsersDetail: { students: {} },
  changing: false, // creating or updating
  createMaterialWorksAsOwnerChanged: false,
  createMaterialWorksAsOwnerChanging: false,
  createMaterialWorksAsOwnerChangeError: null,
  deleting: false,
  deleted: false,
  deleteError: null,
  materialWorks: [],
  totalCount: 0,
  materialWorksInModal: [],
  deletedMaterialWorks: false,
};

const ownerAppMaterialWorkSlice = createSlice({
  name: "OwnerApp/MaterialWork",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterialWorksAsOwner.pending, (state, action) => {
        const { meta } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: true,
            fetchedInModal: false,
          };
        }
        return {
          ...state,
          fetching: true,
          fetchedOnce: false,
        };
      })
      .addCase(fetchMaterialWorksAsOwner.rejected, (state, action) => {
        const { meta } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: false,
            fetchedInModal: true,
            fetchErrorInModal: getErrorMessage(action.payload.message),
          };
        }
        return {
          ...state,
          fetching: false,
          fetchedOnce: true,
          fetchError: getErrorMessage(action.payload.message),
        };
      })
      .addCase(fetchMaterialWorksAsOwner.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: false,
            fetchedInModal: true,
            materialWorksInModal: payload.material_works,
          };
        }
        return {
          ...state,
          fetching: false,
          fetchedOnce: true,
          totalCount: payload.total_count,
          materialWorks: payload.material_works,
        };
      })
      .addCase(fetchMaterialsTargetUsersDetail.pending, (state, _action) => {
        return {
          ...state,
          fetchingMaterialsTargetUsersDetail: true,
          fetchedMaterialsTargetUsersDetail: false,
          fetchMaterialsTargetUsersDetailError: undefined,
        };
      })
      .addCase(fetchMaterialsTargetUsersDetail.rejected, (state, action) => {
        return {
          ...state,
          fetchingMaterialsTargetUsersDetail: false,
          fetchedMaterialsTargetUsersDetail: false,
          fetchMaterialsTargetUsersDetailError: getErrorMessage(
            action.payload.message,
          ),
        };
      })
      .addCase(fetchMaterialsTargetUsersDetail.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingMaterialsTargetUsersDetail: false,
          fetchedMaterialsTargetUsersDetail: true,
          materialsTargetUsersDetail: payload as materialsTargetUsersDetailType,
          fetchMaterialsTargetUsersDetailError: undefined,
        };
      })
      .addCase(createMaterialWorksAsOwner.pending, (state, _action) => {
        return {
          ...state,
          createMaterialWorksAsOwnerChanged: false,
          createMaterialWorksAsOwnerChanging: true,
          createMaterialWorksAsOwnerChangeError: null,
        };
      })
      .addCase(createMaterialWorksAsOwner.rejected, (state, action) => {
        return {
          ...state,
          createMaterialWorksAsOwnerChanged: false,
          createMaterialWorksAsOwnerChanging: false,
          createMaterialWorksAsOwnerChangeError: getErrorMessage(
            action.payload.message,
          ),
        };
      })
      .addCase(createMaterialWorksAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          createMaterialWorksAsOwnerChanged: true,
          createMaterialWorksAsOwnerChanging: false,
          materialWorks: [
            ...currentState.materialWorks,
            ...(payload.materialWorks || []),
          ],
          materialWorksInModal: [
            ...currentState.materialWorksInModal,
            ...(payload.materialWorks || []),
          ],
        };
      })
      .addCase(deleteMaterialWorksAsOwner.pending, (state, _action) => {
        return {
          ...state,
          deleted: false,
          deleting: true,
          deleteError: null,
        };
      })
      .addCase(deleteMaterialWorksAsOwner.rejected, (state, action) => {
        return {
          ...state,
          deleted: false,
          deleting: false,
          deleteError: getErrorMessage(action.payload.message),
        };
      })
      .addCase(deleteMaterialWorksAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          deleted: true,
          deleting: false,
          materialWorks: removeMany(
            currentState.materialWorks,
            payload.deletedMaterialWorkIds,
          ),
          materialWorksInModal: removeMany(
            currentState.materialWorksInModal,
            payload.deletedMaterialWorkIds,
          ),
        };
      })
      .addCase(resetDistributeActionData, (state, _action) => {
        return {
          ...state,
          changing: false,
          deleted: false,
          deleteError: null,
          deleting: false,
          createMaterialWorksAsOwnerChanged: false,
          createMaterialWorksAsOwnerChangeError: null,
          createMaterialWorksAsOwnerChanging: false,
          deletedMaterialWorks: false,
        };
      })
      .addCase(deletedChangedMaterialWorksAsOwnerByUser, (state, _action) => {
        return {
          ...state,
          deletedMaterialWorks: true,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppMaterialWorkState = (
  state: RootState,
): OwnerAppMaterialWorkState => state.ownerApp.materialWork;

export default ownerAppMaterialWorkSlice.reducer;
