/* eslint-disable camelcase */
import { Api, FetchReportsAsTeacherParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { CreateReportAsTeacherParams } from "./types/report";

const api = new Api();

export const fetchReportsAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/reports/fetch",
  async (params: FetchReportsAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchReportsAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchReportAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/reports/fetchOne", async (reportId: string, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getTeacherReportsReportId(reportId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createReportAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/reports/create",
  async (params: CreateReportAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.postTeacherReports(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
