import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import theme from "@root/styles/theme";
import React, { memo, useContext } from "react";

import { BubbleMenuContext } from "../BubbleMenuContext";
import { useStyles } from "../styles";

const TextDecorationGroup: React.FC = () => {
  const { editor, isSubMenuOpen } = useContext(BubbleMenuContext);
  const classes = useStyles();
  return (
    <>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? classes.isActive : classes.default}
        disabled={isSubMenuOpen()}
      >
        <StyledTooltip title="太字" placement="top">
          <FormatBoldIcon
            fontSize="small"
            htmlColor={
              editor.isActive("bold")
                ? theme.palette.tertiary.mainDark
                : theme.palette.common.black
            }
          />
        </StyledTooltip>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={
          editor.isActive("italic") ? classes.isActive : classes.default
        }
        disabled={isSubMenuOpen()}
      >
        <StyledTooltip title="イタリック" placement="top">
          <FormatItalicIcon
            fontSize="small"
            htmlColor={
              editor.isActive("italic")
                ? theme.palette.tertiary.mainDark
                : theme.palette.common.black
            }
          />
        </StyledTooltip>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={
          editor.isActive("underline") ? classes.isActive : classes.default
        }
        disabled={isSubMenuOpen()}
      >
        <StyledTooltip title="下線" placement="top">
          <FormatUnderlinedIcon
            fontSize="small"
            htmlColor={
              editor.isActive("underline")
                ? theme.palette.tertiary.mainDark
                : theme.palette.common.black
            }
          />
        </StyledTooltip>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={
          editor.isActive("strike") ? classes.isActive : classes.default
        }
        disabled={isSubMenuOpen()}
      >
        <StyledTooltip title="取り消し線" placement="top">
          <StrikethroughSIcon
            fontSize="small"
            htmlColor={
              editor.isActive("strike")
                ? theme.palette.tertiary.mainDark
                : theme.palette.common.black
            }
          />
        </StyledTooltip>
      </button>
    </>
  );
};

export default memo(TextDecorationGroup);
