/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const deletePblCardBookmarkImageAsStudent = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/images/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.student.deletePblCardBookmarkImageAsStudent(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
