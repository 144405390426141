import { TEACHER_ROUTES } from "@constants/routes";
import useOptionFeature from "@lib/hooks/useOptionFeature";
import { Divider, MenuItem, useTheme } from "@mui/material";
import { TFunction } from "react-i18next";

export const useTeacherSettingsMenuItems = (
  t: TFunction<["common", "class_nav", "modal", "header_menu"], undefined>,
) => {
  const { isTagFeature } = useOptionFeature();

  const handleRedirection = (route: string) => {
    globalThis.open(route);
  };

  const menuItems = [
    {
      key: "users-list",
      dataCy: "",
      title: t("accountManagement"),
      action: () => handleRedirection(TEACHER_ROUTES.USERS),
    },
    isTagFeature && {
      key: "tag-list",
      dataCy: "",
      title: t("tagManagement"),
      action: () => handleRedirection(TEACHER_ROUTES.TAGS),
    },
    {
      key: "company-details",
      dataCy: "",
      title: t("schoolInformation"),
      action: () => handleRedirection(TEACHER_ROUTES.COMPANY),
    },
    {
      key: "notification-list",
      dataCy: "",
      title: "お知らせ/メール配信",
      action: () => handleRedirection(TEACHER_ROUTES.NOTIFICATIONS),
    },
  ].filter(Boolean);
  return menuItems;
};

type CalendarTabProps = {
  t: TFunction<["common", "class_nav", "modal", "header_menu"], undefined>;
};
export const CalendarTab: React.FC<CalendarTabProps> = ({ t }) => {
  const { isCalendarFeature } = useOptionFeature();
  const theme = useTheme();
  return (
    <>
      {isCalendarFeature && (
        <div key="calendar">
          <Divider sx={{ opacity: "0.6" }} />
          <MenuItem
            key="calendar"
            sx={{
              width: "100%",
              minWidth: 0,
              "&:hover": {
                backgroundColor: theme.palette.quaternary.lightGreen,
                borderRadius: 1,
                boxShadow: `inset 0 0 0 1px ${theme.palette.tertiary.mainDark}`,
              },
            }}
            onClick={() => globalThis.open(TEACHER_ROUTES.CALENDAR)}
          >
            {t("calendar")}
          </MenuItem>
        </div>
      )}
    </>
  );
};
