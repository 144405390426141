import ProjectIcon from "@assets/images/svg/building_icon.svg?react";
import CalendarMobileIcon from "@assets/images/svg/calender_icon.svg?react";
import CalenderIcon from "@assets/images/svg/calender_icon_desk.svg?react";
import GoogleIcon from "@assets/images/svg/google_icon.svg?react";
import HomeIcon from "@assets/images/svg/home_icon.svg?react";
import MaterialIcon from "@assets/images/svg/material_icon.svg?react";
import MaterialWorkIcon from "@assets/images/svg/materialWork_icon.svg?react";
import MemoIcon from "@assets/images/svg/memo_icon.svg?react";
import BookmarkIcon from "@assets/images/svg/pbl_icon.svg?react";
import PblReportIcon from "@assets/images/svg/pblReport_icon.svg?react";
import ProgramIcon from "@assets/images/svg/program_icon.svg?react";
import ReportIcon from "@assets/images/svg/report_icon.svg?react";
import ReportAnswerIcon from "@assets/images/svg/reportAnswer_icon.svg?react";
import SmilingBubble from "@assets/images/svg/SmilingBubble.svg?react";
import SteamLibraryMobileIcon from "@assets/images/svg/steamLibrary_icon.svg?react";
import SteamLibraryIcon from "@assets/images/svg/steamLibrary_icon_desk.svg?react";
import LayersIcon from "@assets/images/svg/studentLog_icon.svg?react";
import BallotIcon from "@assets/images/svg/studyContents_icon.svg?react";
import {
  MobileTab,
  STUDENT_TAB_TYPES,
  Tab,
} from "@components/UI/organisms/Navigation/Categories/types/navigationTypes";
import { STUDENT_ROUTES } from "@constants/routes";
import React from "react";

export const tabs: Tab[] = [
  {
    value: STUDENT_TAB_TYPES.dashboard,
    icon: <HomeIcon />,
    label: "ホーム",
  },
  {
    value: STUDENT_TAB_TYPES.student_logs,
    icon: <LayersIcon />,
    label: "学習ログ",
  },
  {
    value: STUDENT_TAB_TYPES.study_contents_menu,
    icon: <BallotIcon />,
    label: "学習コンテンツ",
  },
  {
    value: STUDENT_TAB_TYPES.ideal,
    icon: <SmilingBubble />,
    label: "あいである",
  },
  {
    value: STUDENT_TAB_TYPES.pbl_menu,
    icon: <BookmarkIcon />,
    label: "探究学習",
  },
  {
    value: STUDENT_TAB_TYPES.pbl_projects,
    icon: <ProjectIcon />,
    label: "企業プロジェクト",
  },
  {
    value: STUDENT_TAB_TYPES.steam_library,
    icon: <SteamLibraryIcon />,
    label: "STEAM Library",
  },
  {
    value: STUDENT_TAB_TYPES.calendar,
    icon: <CalenderIcon />,
    label: "カレンダー",
  },
  {
    value: STUDENT_TAB_TYPES.classroom,
    icon: <GoogleIcon />,
    label: "Googleドキュメント",
  },
];

export const mobileTabs: MobileTab[] = [
  {
    id: 1,
    icon: <LayersIcon style={{ margin: "auto" }} />,
    title: "学習ログ",
    url: STUDENT_ROUTES.STUDENT_LOGS,
    value: STUDENT_TAB_TYPES.student_logs,
  },
  {
    id: 2,
    icon: <SteamLibraryMobileIcon style={{ margin: "auto" }} />,
    title: "STEAM\nライブラリー",
    url: STUDENT_ROUTES.STEAM,
    value: STUDENT_TAB_TYPES.steam_library,
  },
  {
    id: 3,
    icon: <CalendarMobileIcon style={{ margin: "auto" }} />,
    title: "カレンダー",
    url: STUDENT_ROUTES.CALENDAR,
    value: STUDENT_TAB_TYPES.calendar,
  },
  {
    id: 4,
    icon: <GoogleIcon style={{ margin: "auto" }} />,
    title: "Google\nドキュメント",
    url: STUDENT_ROUTES.CLASSROOM,
    value: STUDENT_TAB_TYPES.classroom,
  },
  {
    id: 5,
    icon: <ProgramIcon style={{ margin: "auto" }} />,
    title: "プログラム",
    url: STUDENT_ROUTES.LEARNING_PACKAGES,
    value: STUDENT_TAB_TYPES.learning_packages,
  },
  {
    id: 6,
    icon: <MaterialIcon style={{ margin: "auto" }} />,
    title: "教材",
    url: STUDENT_ROUTES.MATERIALS,
    value: STUDENT_TAB_TYPES.materials,
  },
  {
    id: 7,
    icon: <MaterialWorkIcon style={{ margin: "auto" }} />,
    title: "受講中の教材",
    url: STUDENT_ROUTES.MATERIAL_WORKS,
    value: STUDENT_TAB_TYPES.material_works,
  },
  {
    id: 8,
    icon: <ReportIcon style={{ margin: "auto" }} />,
    title: "提出物",
    url: STUDENT_ROUTES.HOMEWORK,
    value: STUDENT_TAB_TYPES.homework,
  },
  {
    id: 9,
    icon: <ProjectIcon style={{ margin: "auto" }} />,
    title: "企業\nプロジェクト",
    url: STUDENT_ROUTES.PBL_PROJECTS,
    value: STUDENT_TAB_TYPES.pbl_projects,
  },
  {
    id: 10,
    icon: <PblReportIcon style={{ margin: "auto" }} />,
    title: "探究成果",
    url: STUDENT_ROUTES.ARTICLES,
    value: STUDENT_TAB_TYPES.articles,
  },
  {
    id: 11,
    icon: <ReportAnswerIcon style={{ margin: "auto" }} />,
    title: "探究レポート",
    url: STUDENT_ROUTES.PBL_REPORTS,
    value: STUDENT_TAB_TYPES.report_answers,
  },
  {
    id: 12,
    icon: <MemoIcon style={{ margin: "auto" }} />,
    title: "調査メモ",
    url: STUDENT_ROUTES.PBL_BOOKMARKS,
    value: STUDENT_TAB_TYPES.memo,
  },
  {
    id: 13,
    icon: <HomeIcon style={{ margin: "auto" }} />,
    title: "ホーム",
    url: STUDENT_ROUTES.DASHBOARD,
    value: STUDENT_TAB_TYPES.dashboard,
  },
];
