import {
  Api,
  GetStudentSteamCommentsParams,
  PblSteamCommentBase,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { PostLectureParams } from "@root/types/Steam/lecture";
import { RootState } from "@store/store";

import { postSteamLectureAsStudent } from "./steamLecture";

const api = new Api();

export const getSteamLibraryCommentAsStudent = createAsyncThunk<
  any,
  GetStudentSteamCommentsParams & { userId: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibrary/lecture/getComment",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.student.getStudentSteamComments(params);
      return { ...response.data, userId: params.userId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSteamLibraryCommentAsStudent = createAsyncThunk<
  PblSteamCommentBase,
  {
    lectureId: string;
    body: string;
    newLecture?: PostLectureParams;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibrary/lecture/postComment",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const { lectureId, body, newLecture } = params;
      if (newLecture && !lectureId) {
        await dispatch(postSteamLectureAsStudent(newLecture));
      }
      const response = await api.student.postStudentSteamComments({
        lecture_id: lectureId || getState().studentApp.steamLecture.lecture.id,
        is_public: true,
        body,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSteamLibraryCommentAsStudent = createAsyncThunk<
  PblSteamCommentBase,
  { commentId: string; body: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibrary/lecture/updateComment",
  async (params, { rejectWithValue }) => {
    const { commentId, body } = params;
    try {
      const response = await api.student.putStudentSteamContentsId(commentId, {
        is_public: true,
        body,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSteamLibraryCommentAsStudent = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibrary/lecture/deleteComment",
  async (commentId, { rejectWithValue }) => {
    try {
      const response =
        await api.student.deleteStudentSteamContentsId(commentId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetSteamLibraryCommentState = createAction(
  "student/steamLibrary/lecture/resetCommentState",
);
