/* eslint-disable camelcase */
import { updateCategoryRenameMapAsOwner } from "@actions/ownerApp/categoryRenameMap";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface OwnerAppCategoryRenameMapState {
  changing: boolean;
  changed: boolean;
  changeError: any;
}

const initialState: OwnerAppCategoryRenameMapState = {
  changing: false,
  changed: false,
  changeError: null,
};

export const ownerAppCategoryRenameMapSlice = createSlice({
  name: "Admin/CategoryRenameMap",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCategoryRenameMapAsOwner.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(updateCategoryRenameMapAsOwner.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
        };
      })
      .addCase(updateCategoryRenameMapAsOwner.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: errorWithMessage(action.payload),
        };
      });
  },
});

export const ownerAppCategoryRenameMapState = (
  state: RootState,
): OwnerAppCategoryRenameMapState => state.ownerApp.categoryRenameMap;

export default ownerAppCategoryRenameMapSlice.reducer;
