import { combineReducers } from "redux";

import articleReducer from "./article";
import homeworkReducer from "./homework";
import homeworkImageReducer from "./homeworkImage";
import homeworkTemplateReducer from "./homeworkTemplate";
import idealBoardReducer from "./ideal/board";
import idealBoardsAffiliateReducer from "./ideal/boardsAffiliate";
import learningTemplatePackageDistributionsMapReducer from "./learningTemplatePackageDistributionsReducer";
import materialReducer from "./material";
import materialDistributionReducer from "./materialDistribution";
import paymentReducer from "./payment";
import pblCardBookmarkReducer from "./pbl/cards/information_literacy/bookmark";
import pblCardBookmarkCommentReducer from "./pbl/cards/information_literacy/comment";
import pblReportTemplateReducer from "./pbl/report/template";
import sponsorProjectBookmarkReducer from "./pbl/sponsors/bookmark";
import bookmarkedPblSponsorProjectReducer from "./pbl/sponsors/bookmarkedProject";
import pblSponsorInfoReducer from "./pbl/sponsors/info";
import pblSponsorLectureReducer from "./pbl/sponsors/lecture";
import pblSponsorProjectReducer from "./pbl/sponsors/project";
import pblBoardCampaignReducer from "./pblBoard/campaign";
import pblBoardCampaignRequestReducer from "./pblBoard/campaignRequest";
import pblPlanReducer from "./pblplan";
import reportReducer from "./report";
import rubricsReducer from "./rubric";
import schedulesReducer from "./schedules";
import steamContentReducer from "./steam/content";
import steamContentBookmarkReducer from "./steam/contentBookmark";
import steamContentRecommendationReducer from "./steam/contentRecommendations";
import steamCommentReducer from "./steam/steamComment";
import steamCommentListReducer from "./steam/steamCommentList";
import steamLectureReducer from "./steam/steamLecture";
import steamLibraryTabReducer from "./steam/tab";
import studentLogsReducer from "./studentLogs";
import studentMaterialWorkReducer from "./studentMaterialWork";
import submitHomeworkFilesRequestReducer from "./submitHomeworkFilesRequest";
import userReducer from "./user";
import userTagReducer from "./userTag";

const teacherAppReducer = combineReducers({
  payment: paymentReducer,
  homeworkTemplate: homeworkTemplateReducer,
  homework: homeworkReducer,
  homeworkImage: homeworkImageReducer,
  submitHomeworkFilesRequest: submitHomeworkFilesRequestReducer,
  material: materialReducer,
  materialDistribution: materialDistributionReducer,
  studentMaterialWork: studentMaterialWorkReducer,
  report: reportReducer,
  user: userReducer,
  userTag: userTagReducer,
  article: articleReducer,
  pblPlan: pblPlanReducer,
  pblReportTemplate: pblReportTemplateReducer,
  pblCardBookmark: pblCardBookmarkReducer,
  pblCardBookmarkComment: pblCardBookmarkCommentReducer,
  pblSponsorInfo: pblSponsorInfoReducer,
  pblSponsorProject: pblSponsorProjectReducer,
  bookmarkedPblSponsorProject: bookmarkedPblSponsorProjectReducer,
  sponsorProjectBookmark: sponsorProjectBookmarkReducer,
  pblSponsorLecture: pblSponsorLectureReducer,
  steamCommentList: steamCommentListReducer,
  steamLecture: steamLectureReducer,
  steamComment: steamCommentReducer,
  steamContent: steamContentReducer,
  steamContentRecommendation: steamContentRecommendationReducer,
  steamContentBookmark: steamContentBookmarkReducer,
  schedules: schedulesReducer,
  study: studentLogsReducer,
  learningTemplatePackageDistributions:
    learningTemplatePackageDistributionsMapReducer,
  steamLibraryTab: steamLibraryTabReducer,
  rubrics: rubricsReducer,
  pblBoardCampaign: pblBoardCampaignReducer,
  pblBoardCampaignRequest: pblBoardCampaignRequestReducer,
  idealBoard: idealBoardReducer,
  idealBoardsAffiliate: idealBoardsAffiliateReducer,
});

export default teacherAppReducer;
