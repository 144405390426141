import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import { Menu,MenuItem } from "@mui/material";
import theme from "@root/styles/theme";
import { Editor } from "@tiptap/react";
import _ from "lodash";
import React, { memo, useContext, useState } from "react";

import { getCheckBoxImage } from "../useSVEditorStyles";
import { BubbleMenuContext } from "./BubbleMenuContext";
import { Colors, HightlightColors, useStyles } from "./styles";

export const HighlightGroup = (): JSX.Element => {
  const { editor, subMenuOpen, setSubMenuOpen, isOtherSubMenuOpen } =
    useContext(BubbleMenuContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setSubMenuOpen({
      ...subMenuOpen,
      highlightGroup: false,
    });

    setAnchorEl(null);
  };

  const getColor = (editorVal: Editor): string => {
    const color = Object.keys(Colors).find((item) => {
      return editorVal.isActive("highlight", { color: HightlightColors[item] });
    });
    return color ?? "white";
  };

  return (
    <>
      <button
        type="button"
        onClick={(event) => {
          if (!anchorEl) {
            setSubMenuOpen({
              ...subMenuOpen,
              highlightGroup: true,
            });
            setAnchorEl(event.currentTarget);
          } else {
            handleClose();
          }
        }}
        className={
          editor.isActive("highlight") ? classes.isActive : classes.default
        }
        disabled={isOtherSubMenuOpen("highlightGroup")}
      >
        <StyledTooltip title="ハイライト" placement="top">
          <FontDownloadIcon
            fontSize="small"
            htmlColor={
              editor.isActive("highlight")
                ? getColor(editor)
                : theme.palette.common.black
            }
          />
        </StyledTooltip>
      </button>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {_.chunk(Object.keys(Colors), 4).map((columns) => {
          const key = columns.map((column) => column).join("+");
          return (
            <div className={classes.colorColumn} key={key}>
              {columns.map((color) => {
                return color === "reset" ? (
                  <MenuItem
                    onClick={() => {
                      editor.chain().focus().unsetHighlight().run();
                      handleClose();
                    }}
                    className={classes.hightlightColorReset}
                    key={color}
                    style={{
                      ...(!editor.isActive("highlight") && {
                        backgroundImage: getCheckBoxImage(
                          "rgb(71, 81, 73)",
                          32,
                          32,
                        ),
                      }),
                    }}
                  />
                ) : (
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setHighlight({ color: HightlightColors[color] })
                        .run();
                      handleClose();
                    }}
                    className={classes.colorDefault}
                    style={{
                      color: Colors[color],
                      backgroundColor: Colors[color],
                      ...(editor.isActive("highlight", {
                        color: HightlightColors[color],
                      }) && {
                        backgroundImage: getCheckBoxImage(
                          "rgb(71, 81, 73)",
                          32,
                          32,
                        ),
                      }),
                    }}
                    key={color}
                  />
                );
              })}
            </div>
          );
        })}
      </Menu>
    </>
  );
};

export default memo(HighlightGroup);
