import { UserGroup } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  createUserGroupAsOwner,
  fetchUserGroupsAsOwner,
  resetCreateUserGroupModalState,
} from "../../actions/ownerApp/userGroup";
import { RootState } from "../../store";

export interface OwnerAppUserGroupState {
  fetching: boolean;
  fetchError: any;
  userGroups: UserGroup[];
  totalCount: number;
  creating: boolean;
  created: boolean;
  createError: any;
}

export const initialState: OwnerAppUserGroupState = {
  fetching: false,
  fetchError: null,
  userGroups: [],
  totalCount: 0,
  creating: false,
  created: false,
  createError: null,
};

const ownerAppUserGroupSlice = createSlice({
  name: "OwnerApp/UserGroup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserGroupsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchUserGroupsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: action.error.message as string,
          reports: [],
        };
      })
      .addCase(fetchUserGroupsAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          userGroups: payload.userGroups,
          totalCount: payload.total_count,
        };
      })
      .addCase(createUserGroupAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(createUserGroupAsOwner.rejected, (state, action) => {
        return {
          ...state,
          creating: true,
          created: false,
          createError: null,
        };
      })
      .addCase(createUserGroupAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          creating: false,
          created: true,
          createError: null,
          userGroups: [...state.userGroups, payload],
          totalCount: state.totalCount + 1,
        };
      })
      .addCase(resetCreateUserGroupModalState, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          createError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppUserGroupState = (
  state: RootState,
): OwnerAppUserGroupState => state.ownerApp.userGroup;

export default ownerAppUserGroupSlice.reducer;
