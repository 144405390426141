import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import { ITEMS_PER_PAGE } from "@constants/page";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import { safeString } from "@lib/string-utils";
import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  PblSteamContent,
  superOwnerApi,
} from "@root/endpoints/TimeTactApi/superOwnerApi";
import { useGetSteamLibraryContentImagesQuery } from "@store/services/api/steamLibraryApp/steamLibraryApi";
import React, { useMemo, useState } from "react";

import SteamContentAccordion from "./SteamContentAccordion";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      borderTop: `2px solid ${theme.palette.learningTemplatePackage.lightGrey}`,
      padding: theme.spacing(2, 0),
    },
    descriptionBox: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderImage: theme.palette.dialogBorder.gradient,
      borderImageSlice: 1,
      fontWeight: 500,
      fontSize: 16,
      padding: theme.spacing(1, 3),
      marginBottom: theme.spacing(1),
    },
    accordionRoot: {
      boxShadow: "none",
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    accordionExpanded: {},
    accordion: {
      margin: theme.spacing(1, 0),
    },
    accordionSummary: {
      backgroundColor: "rgba(0, 130, 103, 0.05)",
    },
    contentTitle: {
      color: theme.palette.customColor.text,
      fontWeight: 700,
      fontSize: 16,
    },
  }),
);

export type ContentWithImage = PblSteamContent & { imgSrc: string };

type Props = {
  status: ModalStatus;
  chosenPackage: string[];
  handleChosePackage: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SteamContentList: React.FC<Props> = ({
  status,
  chosenPackage,
  handleChosePackage,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState<number>(1);

  // contents
  const steamContentsResult =
    superOwnerApi.endpoints.getSteamContentsAsSuperOwner.useQuery({
      page,
      perPage: ITEMS_PER_PAGE,
    });
  const contents = steamContentsResult.data?.steam_contents || [];
  const totalCount = steamContentsResult.data?.total_count ?? 0;

  const contentIds = useMemo(
    () => contents.map((content) => content.sl_content_id),
    [contents],
  );

  // content images
  const imagesResult = useGetSteamLibraryContentImagesQuery(contentIds, {
    skip: !steamContentsResult.isSuccess,
  });
  const images = imagesResult.data?.contents || [];

  const contentsWithImage: ContentWithImage[] = useMemo(
    () =>
      contents.map((content) => ({
        ...content,
        imgSrc: safeString(
          images.find((image) => image.id === content.sl_content_id)?.top_image,
        ),
      })),
    [contents, images],
  );

  return steamContentsResult.isFetching || imagesResult.isFetching ? (
    <CircularLoading />
  ) : (
    <Box className={classes.main}>
      <Box className={classes.descriptionBox}>テーマ・レクチャー一覧</Box>
      {contentsWithImage.length ? (
        <>
          {contentsWithImage.map((content) => (
            <SteamContentAccordion
              key={content.id}
              status={status}
              content={content}
              chosenPackage={chosenPackage}
              handleChosePackage={handleChosePackage}
            />
          ))}
          <V2Pagination
            totalCount={totalCount}
            perPage={ITEMS_PER_PAGE}
            currentPage={page}
            handlePageTransition={setPage}
          />
        </>
      ) : (
        <Box m={2}>
          <Typography>データが見つかりませんでした。</Typography>
        </Box>
      )}
    </Box>
  );
};

export default SteamContentList;
