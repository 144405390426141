/* eslint-disable camelcase */
import { UpdateTaggingBatchParams } from "@actions/ownerApp/userTag";
import {
  Api,
  BasicUserInfo,
  FetchUserTagsAsTeacherParams,
  UserTagTypeKind,
  UserTagTypesListParams,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { OwnerTeacherApiFunctions } from "@root/types/tags";
import { RootState } from "@store/store";

import { FetchStudentTaggingByUserTag,UserTaggingParams } from "../types/tag";

const api = new Api();

export const fetchUserTagTypesAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/userTagTypes/fetch",
  async (data: UserTagTypesListParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.userTagTypesList(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchUserTagsAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/userTag/fetch",
  async (data: FetchUserTagsAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchUserTagsAsTeacher({
        ...data,
        kind: UserTagTypeKind.Nationwide,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchLimitedUserTagsAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/limitedUserTag/fetch",
  async (data: FetchUserTagsAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchUserTagsAsTeacher({
        ...data,
        kind: UserTagTypeKind.Limited,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchUserTagsAsTeacherForTagging = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/allUserTag/fetch",
  async (data: UserTagTypesListParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.userTagTypesList(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchStudentTaggingByUserTag = createAsyncThunk<
  { users: BasicUserInfo[]; total_count: number },
  FetchStudentTaggingByUserTag,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/fetchStudentsByUserTag", async (data, { rejectWithValue }) => {
  try {
    const response = await api.teacher.userTagsUsersDetail(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createUserTagging = createAsyncThunk<
  {
    response: {
      number_of_tagging_users?: number;
      remaining_number_of_tagging_users?: number | null;
    };
  },
  UserTaggingParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/userTagging/create", async (data, { rejectWithValue }) => {
  try {
    const response = await api.teacher.userTaggingsCreate({
      user_id: data.user_id,
      user_tag_id: data.user_tag_id,
    });
    return {
      response: response.data,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const userTaggingsBatchCreatePartialUpdateAsTeacher = createAsyncThunk<
  any,
  UpdateTaggingBatchParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/updateTaggingsAsTeacher", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.teacherUserTaggingBatchCreate(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteUserTagging = createAsyncThunk<
  { response: { messaege?: string } },
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/userTagging/delete", async (data, { rejectWithValue }) => {
  try {
    const response = await api.teacher.userTaggingsDelete({
      user_id: data.user_id,
      user_tag_id: data.user_tag_id,
    });
    return {
      response: response.data,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// ___USER_TAG -- Create/Edit/Delete/Success Actions___

interface CreateUserTagParamsType {
  user_tag_type_id: string;
  name: string;
  limit_number?: number;
}

export const createUserTag = createAsyncThunk<
  any,
  CreateUserTagParamsType,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/user_tag/create", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.createUserTagAsTeacher(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface EditUserTagParamsType {
  id: string;
  commonParams: {
    name?: string;
    limit_number?: number;
  };
}

export const editUserTag = createAsyncThunk<
  any,
  EditUserTagParamsType,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/user_tag/edit", async (params, { rejectWithValue }) => {
  const { id, commonParams } = params;
  try {
    const response = await api.teacher.updateUserTagAsTeacher(id, commonParams);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteUserTag = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/user_tag/delete", async (id, { rejectWithValue }) => {
  try {
    const response = await api.teacher.deleteUserTagAsTeacher(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const userTagSuccess = createAction("teacher/userTag/success");
export const userTagRejected = createAction("teacher/userTag/rejected");

// ___User_tag_type___

interface CreateUserTagTypeTypes {
  name?: string;
  kind?: UserTagTypeKind;
  teacher_visible?: boolean;
}

export const createUserTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/user_tag_type/create",
  async (params: CreateUserTagTypeTypes, { rejectWithValue }) => {
    try {
      const response = await api.teacher.userTagTypesCreate(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface EditUserTagTypeTypes {
  id: string;
  data: {
    name?: string;
    teacher_visible?: boolean;
  };
}

export const editUserTagType = createAsyncThunk<
  any,
  EditUserTagTypeTypes,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/user_tag_type/edit", async (params, { rejectWithValue }) => {
  const { id, data } = params;
  try {
    const response = await api.teacher.userTagTypesUpdate(id, data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteUserTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/user_tag_type/delete", async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.teacher.userTagTypesDelete(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

type SequenceType = {
  id: string;
  sequence_number?: number;
};
export type UpdateSequenceParams = {
  sequences: SequenceType[];
};

export const updateTeacherUserTagsSequence = createAsyncThunk<
  GetResponseType<Api["teacher"]["userTagsSequenceUpdate"]>,
  UpdateSequenceParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/user_tag_sequence/update", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.userTagsSequenceUpdate(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetBatchCountState = createAction(
  "teacher/userTag/resetBatchCountState",
);
export const resetState = createAction("teacher/userTag/resetState");
export const isRequestsRunning = createAction(
  "teacher/userTag/isRequestsRunning",
);

type TeacherActionsType = {
  teacher: OwnerTeacherApiFunctions;
};
export const teacherActions: TeacherActionsType = {
  teacher: {
    post: createUserTag,
    edit: editUserTag,
    delete: deleteUserTag,
    createTagType: createUserTagType,
    editTagType: editUserTagType,
    deleteTagType: deleteUserTagType,
    success: userTagSuccess,
    rejected: userTagRejected,
    isRequestsRunning,
  },
};
