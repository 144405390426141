/* eslint-disable @typescript-eslint/ban-types */
import { ITEMS_PER_PAGE } from "@constants/page";
import {
  DeliverableListBase,
  useGetStudentLogsDeliverableListAsTeacherQuery,
} from "@root/endpoints/TimeTactApi/teacherApi";
import { useEffect, useState } from "react";
import { Control, useForm, UseFormHandleSubmit } from "react-hook-form";

type UserHandleStudentsLogsParams = {
  groupAffiliateId: string | undefined;
};

export type FormValues = {
  searchText: "";
};

type UseHandleStudentsLogsReturnType = {
  states: {
    fetchingDeliverableList: boolean;
    fetchedDeliverableList: boolean;
    studentsLogsError: unknown;
    currentPage: number;
    studentLogsDeliverableList: DeliverableListBase[];
    totalCount: number;
    control: Control<FormValues, object>;
  };
  actions: {
    handlePagination: (newPage: number) => void;
    onSubmit: (values: FormValues) => void;
    handleSubmit: UseFormHandleSubmit<FormValues>;
  };
};

const useHandleStudentsLogs = ({
  groupAffiliateId,
}: UserHandleStudentsLogsParams): UseHandleStudentsLogsReturnType => {
  const { control, handleSubmit } = useForm<FormValues>();
  const defaultState = { currentPage: 1, searchText: "" };
  const [{ currentPage, searchText }, setSearchStudentLogsStates] =
    useState(defaultState);

  const {
    data,
    error: studentsLogsError,
    isFetching: fetchingDeliverableList,
    isSuccess: fetchedDeliverableList,
  } = useGetStudentLogsDeliverableListAsTeacherQuery(
    {
      page: currentPage,
      perPage: ITEMS_PER_PAGE,
      groupAffiliateId,
      search: searchText,
    },
    { refetchOnMountOrArgChange: true },
  );

  const studentLogsDeliverableList = data?.study_logs || [];
  const totalCount = data?.total_count || 0;

  const handlePagination = (newPage: number) =>
    setSearchStudentLogsStates((prev) => {
      return {
        ...prev,
        currentPage: newPage,
      };
    });

  const onSubmit = (values: FormValues) => {
    setSearchStudentLogsStates({
      currentPage: 1,
      searchText: values.searchText,
    });
  };

  useEffect(() => {
    setSearchStudentLogsStates(defaultState);
  }, [groupAffiliateId]);

  return {
    states: {
      studentsLogsError,
      fetchingDeliverableList,
      fetchedDeliverableList,
      currentPage,
      studentLogsDeliverableList,
      totalCount,
      control,
    },
    actions: {
      handlePagination,
      handleSubmit,
      onSubmit,
    },
  };
};

export default useHandleStudentsLogs;
