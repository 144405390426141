import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import { isTouchDevice } from "@lib/responsive-utils";
import { Box } from "@mui/material";
import React from "react";

type Props = {
  fileName?: string | null;
  showToolTip?: boolean;
};

/**
 * Add ellipsis with width dynamically
 * Example:
 * | LongFileName.pdf |  >  | LongFi... .pdf|
 */
const V2FileName: React.FC<Props> = ({ fileName, showToolTip }) => {
  const splitFileName = fileName?.split(".") || [];
  const hasExtensionName = splitFileName.length > 1;
  const extensionName = hasExtensionName ? splitFileName.pop() : "";
  const fileNameWithoutExtension = splitFileName.join();

  const tooltipTitle = !isTouchDevice() && fileName ? fileName : "";

  return (
    <StyledTooltip title={showToolTip ? tooltipTitle : ""} placement="top">
      <Box display="flex" minWidth={0} flexGrow={1}>
        <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {fileNameWithoutExtension}
        </Box>
        {hasExtensionName && <Box flexShrink={0}>{`.${extensionName}`}</Box>}
      </Box>
    </StyledTooltip>
  );
};

V2FileName.defaultProps = {
  fileName: "",
  showToolTip: true,
};

export default V2FileName;
