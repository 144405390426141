/* eslint-disable camelcase */
import {
  Api,
  GetPblBoardCampaignsAsContentProviderParams,
  PblBoardCampaignBase,
  PblBoardCampaignEvent,
  PblBoardCampaignList,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { PostPblBoardCampaignParams } from "@root/types/PblBoard/Campaign";
import { RootState } from "@store/store";

const api = new Api();

export const getPblBoardCampaignsAsContentProvider = createAsyncThunk<
  { board_campaigns: PblBoardCampaignList[]; total_count: number },
  GetPblBoardCampaignsAsContentProviderParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/board/campaigns/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getPblBoardCampaignsAsContentProvider(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPblBoardCampaignAsContentProvider = createAsyncThunk<
  PblBoardCampaignBase,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/board/campaigns/fetchOne",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getPblBoardCampaignAsContentProvider(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postPblBoardCampaignAsContentProvider = createAsyncThunk<
  any,
  PostPblBoardCampaignParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/board/campaigns/post",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.postPblBoardCampaignAsContentProvider(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export type PutPblBoardCampaignParams = {
  id: string;
  data: {
    title: string;
    description?: string;
    start_date: string;
    end_date: string;
    event: PblBoardCampaignEvent;
  };
};

export const putPblBoardCampaignAsContentProvider = createAsyncThunk<
  any,
  PutPblBoardCampaignParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/board/campaigns/put",
  async (params, { rejectWithValue }) => {
    try {
      const { id, data } = params;
      const response =
        await api.contentProvider.putPblBoardCampaignAsContentProvider(
          id,
          data,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblBoardCampaignAsContentProvider = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/board/campaigns/delete",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.deletePblBoardCampaignAsContentProvider(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetContentProviderAppPblBoardCampaignState = createAction(
  "contentProviderApp/pbl/board/resetPblBoardCampaignState",
);

export const resetContentProviderAppPblBoardCampaignModalState = createAction(
  "contentProviderApp/pbl/board/resetPblBoardCampaignModalState",
);
