/* eslint-disable camelcase */
import {
  AffiliateStudentName,
  Api,
  GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerParams,
  GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerParams,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { PostPackageDistributionParams } from "./types";

const api = new Api();

export const postLearningTemplatePackageDistributions = createAsyncThunk<
  any,
  PostPackageDistributionParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/package_distribution/post",
  async (args, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.patchLearningTemplatePackageDistributionsBatchCreateAsSuperOwner(
          args.learning_template_package_id,
          args.data,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getStudentsAffiliatesFromCompanies = createAsyncThunk<
  { student_affiliates: AffiliateStudentName[]; total_count: number },
  GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/student_affiliates_from_companies/get",
  async (args, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.getLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwner(
          args,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getStudentsAffiliatesFromUserTags = createAsyncThunk<
  { student_affiliates: AffiliateStudentName[]; total_count: number },
  GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/student_affiliates_from_user_tags/get",
  async (args, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.getLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwner(
          args,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetTemplateDistributionState = createAction(
  "super_owner/package_distribution/resetState",
);
