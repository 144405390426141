import HelpIcon from "@assets/images/svg/help-icon.svg?react";
import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import { Box, IconButton, Popover,Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      gap: theme.spacing(1),
      padding: theme.spacing(2, 3),
      maxWidth: "560px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "none",
      },
    },
    headerWrapper: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    iconButton: {
      padding: 4,
      margin: theme.spacing(0, 1),
      color: theme.palette.grey[400],
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0.5),
      },
    },
    Icon: {
      width: "28px",
      height: "28px",
    },
    smallIcon: {
      width: "24px",
      height: "24px",
    },
    titleStyle: {
      fontWeight: 700,
      color: theme.palette.tertiary.mainDark,
    },
    commonTextStyle: {
      color: theme.palette.customColor.text,
    },
  }),
);

const StudentHelpMessage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "university"]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box className={classes.main}>
      <StyledTooltip title={t("help")}>
        <IconButton
          id="user-support"
          aria-controls="user-support"
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={handleClick}
          size="large"
        >
          <HelpIcon className={classes.Icon} />
        </IconButton>
      </StyledTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className={classes.wrapper}>
          <Box className={classes.headerWrapper}>
            <span>
              <HelpIcon className={classes.smallIcon} />
            </span>
            <Typography
              className={classes.titleStyle}
              data-cy="request-user-support-title"
            >
              {t("university:whatsInterviewRequest")}
            </Typography>
          </Box>
          <Typography className={classes.commonTextStyle}>
            {t("university:youCanAskToTheProfessor")}
          </Typography>
          <Typography className={classes.commonTextStyle}>
            {t("university:thirdAnnotation")}
          </Typography>
          <Typography className={classes.titleStyle}>
            {t("university:flowOfTheInterview")}
          </Typography>
          <Typography>
            {t("university:first")}
            <br />
            {t("university:second")}
            <br />
            {t("university:firstAnnotation")}
            <br />
            {t("university:third")}
            <br />
            {t("university:fourth")}
            <br />
            {t("university:secondAnnotation")}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default StudentHelpMessage;
