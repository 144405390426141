import { Box, Theme, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import {
  Control,
  useFieldArray,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { FormType } from "../../usePackageDistributionModal";
import Accordion from "./Accordion";

type Props = {
  control: Control<FormType, object>;
  setValue: UseFormSetValue<FormType>;
  watch: UseFormWatch<FormType>;
};

const useStyles = () => ({
  main: (theme: Theme) => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    gap: theme.spacing(2),
  }),
  headingContainer: (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  }),
  heading: (theme: Theme) => ({
    fontWeight: 700,
    color: theme.palette.customColor.text,
  }),
});

const SchoolsAccordion: React.FC<Props> = ({ control, setValue, watch }) => {
  const classes = useStyles();
  const { fields } = useFieldArray({
    name: "schoolsWithAttachedTemplates",
    control,
  });
  const modalStepOneStartDate = watch("startDate");
  const modalStepOneEndDate = watch("endDate");
  return (
    <Box sx={classes.main}>
      <Box sx={classes.headingContainer}>
        <Box sx={classes.headingContainer}>
          <Typography sx={classes.heading}>配信開始日時</Typography>
          <Typography>
            {modalStepOneStartDate
              ? dayjs(modalStepOneStartDate).format("YYYY年M月DD日 HH時mm分")
              : "なし"}
          </Typography>
        </Box>
        <Box sx={classes.headingContainer}>
          <Typography sx={classes.heading}>配信終了日時</Typography>
          <Typography>
            {modalStepOneEndDate
              ? dayjs(modalStepOneEndDate).format("YYYY年M月DD日 HH時mm分")
              : "なし"}
          </Typography>
        </Box>
      </Box>
      {fields.map(({ schoolTitle, id }, index) => (
        <Accordion
          key={id}
          nestedIndex={index}
          control={control}
          setValue={setValue}
          schoolTitle={schoolTitle}
          watch={watch}
        />
      ))}
    </Box>
  );
};

export default SchoolsAccordion;
