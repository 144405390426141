import { createSlice } from "@reduxjs/toolkit";

import { AffiliateInvitation } from "../../lib/Api";
import { fetchAffiliateInvitation } from "../actions/affiliateInvitation";
import { RootState } from "../store";

export interface affiliateInvitationState {
  fetching: boolean;
  fetched: boolean;
  fetchError: any;
  invitation: AffiliateInvitation | null;
}

export const initialState: affiliateInvitationState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  invitation: null,
};

export const affiliateInvitationSlice = createSlice({
  name: "AffiliateInvitation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAffiliateInvitation.pending, (state, _action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          fetchError: null,
        };
      })
      .addCase(fetchAffiliateInvitation.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: null,
          invitation: payload,
        };
      })
      .addCase(fetchAffiliateInvitation.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error,
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const affiliateInvitationState = (
  state: RootState,
): affiliateInvitationState => state.affiliateInvitation;

export default affiliateInvitationSlice.reducer;
