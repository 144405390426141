import { Api, GetTeacherSteamCommentsParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

// eslint-disable-next-line import/prefer-default-export
export const getSteamLibraryCommentsAsTeacher = createAsyncThunk<
  any,
  GetTeacherSteamCommentsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/steamLibraryComments/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getTeacherSteamComments(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
