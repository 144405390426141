/* eslint-disable camelcase */

import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

interface AssignManagerToHomeworkTemplateDistributionAsOwnerArg {
  teacherName: string;
  id: string;
  params: {
    teacher_id: string;
  };
}

export const assignSchoolManagerAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplateDistribution/assignSchoolManager",
  async (
    arg: AssignManagerToHomeworkTemplateDistributionAsOwnerArg,
    { rejectWithValue },
  ) => {
    const { teacherName, id, params } = arg;
    try {
      await api.owner.assignManagerToHomeworkTemplateDistributionAsOwner(
        id,
        params,
      );
      return {
        distributionId: id,
        teacherId: params.teacher_id,
        teacherName,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface SubmitHomeworkTemplateDistributionToReviewerAsOwnerArg {
  id: string;
  params: {
    comment: string | null;
  };
}

export const submitToReviewerAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplateDistribution/submitToReviewer",
  async (
    arg: SubmitHomeworkTemplateDistributionToReviewerAsOwnerArg,
    { rejectWithValue },
  ) => {
    const { id, params } = arg;
    try {
      const response =
        await api.owner.submitHomeworkTemplateDistributionToReviewerAsOwner(
          id,
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface RevertHomeworkTemplateDistributionSubmittedToReviewerAsOwnerArg {
  id: string;
}

export const revertSubmissionToReviewerAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplateDistribution/revertSubmissionToReviewer",
  async (
    arg: RevertHomeworkTemplateDistributionSubmittedToReviewerAsOwnerArg,
    { rejectWithValue },
  ) => {
    const { id } = arg;
    try {
      const response =
        await api.owner.revertHomeworkTemplateDistributionSubmittedToReviewerAsOwner(
          id,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface ReturnHomeworkTemplateDistributionToStudentsAsOwnerArg {
  id: string;
}

export const returnToStudentsAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplateDistribution/returnToStudents",
  async (
    arg: ReturnHomeworkTemplateDistributionToStudentsAsOwnerArg,
    { rejectWithValue },
  ) => {
    const { id } = arg;
    try {
      const response =
        await api.owner.returnHomeworkTemplateDistributionToStudentsAsOwner(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface UpdateHomeworkTemplateDistributionAsOwnerArg {
  id: string;
  params: {
    comment: string;
  };
}

export const updateAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplateDistribution/update",
  async (
    arg: UpdateHomeworkTemplateDistributionAsOwnerArg,
    { rejectWithValue },
  ) => {
    const { id, params } = arg;
    try {
      const response =
        await api.owner.updateHomeworkTemplateDistributionAsOwner(id, params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
