/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import { FormType } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList/Modals/LearningTemplateDistributionModal/usePackageDistributionModal";
import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import { getBeforeEndDateValidateMessage } from "@components/UI/molecules/StartEndDatePicker";
import V2DateTimePicker from "@components/UIv2/atoms/V2DateTimePicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse, TextField,Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { superOwnerApi } from "@root/endpoints/TimeTactApi/superOwnerApi";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  useFieldArray,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import schoolAccordionStyles from "./style";

type Props = {
  nestedIndex: number;
  schoolTitle: string;
  control: Control<FormType, object>;
  setValue: UseFormSetValue<FormType>;
  watch: UseFormWatch<FormType>;
};

type InputTitleProps = {
  title: string;
  isRequired: boolean;
};

const Accordion: React.FC<Props> = ({
  nestedIndex,
  schoolTitle,
  control,
  setValue,
  watch,
}) => {
  const {
    labelTitle,
    tagStyle,
    required,
    optional,
    mainContainer,
    schoolTitleContainer,
    commonFont,
    templatesContainer,
    templateTitle,
    gridItem,
  } = schoolAccordionStyles;
  const { fields: templates } = useFieldArray({
    name: `schoolsWithAttachedTemplates.${nestedIndex}.templates`,
    control,
  });
  const stepOneStartDate = watch("startDate");
  const stepOneEndDate = watch("endDate");
  const [openAccordion, setOpenAccordion] = useState<boolean>(true);
  const isTemplatesPresent = !!templates.length;

  const isSameOrAfter = (end: string, start: string) => {
    return dayjs(end).isSameOrAfter(start);
  };
  const isAfter = (end: string, start: string) => {
    return dayjs(end).isAfter(start);
  };

  const setInputTitle = ({ title, isRequired }: InputTitleProps) => {
    return (
      <Typography sx={labelTitle}>
        {title}
        <Typography
          component="span"
          sx={{ ...tagStyle, ...(isRequired ? required : optional) }}
        >
          {isRequired ? "必須" : "任意"}
        </Typography>
      </Typography>
    );
  };

  const inputLabelProps = { shrink: true };

  useEffect(() => {
    templates.forEach((template, index) => {
      setValue(
        `schoolsWithAttachedTemplates.${nestedIndex}.templates.${index}.start_at`,
        stepOneStartDate,
      );
    });
  }, [setValue, nestedIndex, templates]);

  return (
    <Box sx={mainContainer}>
      <Box
        onClick={() =>
          isTemplatesPresent ? setOpenAccordion((prev) => !prev) : null
        }
        sx={schoolTitleContainer}
      >
        <Typography sx={commonFont}>{schoolTitle}</Typography>
        {isTemplatesPresent && (
          <>
            {openAccordion ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </>
        )}
      </Box>
      <Collapse in={openAccordion} timeout="auto" unmountOnExit>
        <Box sx={templatesContainer}>
          {templates.map((template, index) => {
            const { data } =
              superOwnerApi.endpoints.getSuperOwnerHomeworkTemplatesByHomeworkTemplateId.useQuery(
                { homeworkTemplateId: template.homework_template_id },
                { skip: !template.id },
              );
            const hasReviewer = data?.needs_review ?? false;
            const startAt = watch(
              `schoolsWithAttachedTemplates.${nestedIndex}.templates.${index}.start_at`,
            );
            const endAt = watch(
              `schoolsWithAttachedTemplates.${nestedIndex}.templates.${index}.end_at`,
            );
            return (
              <ImageList
                key={template.homework_template_id}
                gap={16}
                cols={5}
                sx={{ width: "100%" }}
              >
                <ImageListItem sx={gridItem}>
                  <StyledTooltip title={template.templateTitle}>
                    <Typography sx={templateTitle}>
                      {template.templateTitle}
                    </Typography>
                  </StyledTooltip>
                </ImageListItem>
                <ImageListItem sx={gridItem}>
                  <Controller
                    control={control}
                    name={`schoolsWithAttachedTemplates.${nestedIndex}.templates.${index}.start_at`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <V2DateTimePicker
                        disablePast
                        label={setInputTitle({
                          title: "開始日時",
                          isRequired: true,
                        })}
                        value={value}
                        onChange={(newValue) => onChange(newValue)}
                        slotProps={{
                          textField: {
                            helperText: error ? error.message : null,
                            error: !!error,
                            InputLabelProps: inputLabelProps,
                          },
                        }}
                      />
                    )}
                    rules={{
                      required: "開始日時は必須です",
                      validate: {
                        beforeStepOneStartDate: (value) =>
                          !value ||
                          isSameOrAfter(value, stepOneStartDate) ||
                          getBeforeEndDateValidateMessage({
                            endInputTitle: "開始日時",
                            startInputTitle: "プログラムの配信開始日時",
                          }),
                        afterStepOneEndDate: (value) => {
                          if (isAfter(value, stepOneEndDate)) {
                            return "開始日時はプログラムの配信終了日時よりも前に設定してください。";
                          }
                          return true;
                        },
                      },
                    }}
                  />
                </ImageListItem>
                <ImageListItem sx={gridItem}>
                  <Controller
                    control={control}
                    name={`schoolsWithAttachedTemplates.${nestedIndex}.templates.${index}.end_at`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <V2DateTimePicker
                        disablePast
                        label={setInputTitle({
                          title: "生徒の提出期限",
                          isRequired: false,
                        })}
                        value={value}
                        onChange={(newValue) => onChange(newValue)}
                        slotProps={{
                          textField: {
                            helperText: error ? error.message : null,
                            error: !!error,
                            InputLabelProps: inputLabelProps,
                          },
                        }}
                      />
                    )}
                    rules={{
                      validate: {
                        beforeStartDate: (value) =>
                          !startAt ||
                          !value ||
                          isAfter(value, startAt) ||
                          getBeforeEndDateValidateMessage({
                            endInputTitle: "生徒の提出期限",
                            startInputTitle: "開始日時",
                          }),
                        afterStepOneEndDate: (value) => {
                          if (isAfter(value, stepOneEndDate)) {
                            return "生徒の提出期限はプログラムの配信終了日時よりも前に設定してください。";
                          }
                          return true;
                        },
                      },
                    }}
                  />
                </ImageListItem>
                {hasReviewer && (
                  <ImageListItem sx={gridItem}>
                    <Controller
                      control={control}
                      name={`schoolsWithAttachedTemplates.${nestedIndex}.templates.${index}.reviewer_end_at`}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <V2DateTimePicker
                          disablePast
                          label={setInputTitle({
                            title: "添削会社への提出期限",
                            isRequired: true,
                          })}
                          value={value}
                          onChange={(newValue) => onChange(newValue)}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error ? error.message : null,
                              InputLabelProps: {
                                shrink: true,
                              },
                            },
                          }}
                        />
                      )}
                      rules={{
                        required: "添削会社への提出期限は必須です",
                        validate: {
                          beforeStartDate: (value) => {
                            // If there's a start_at, reviewer_end_at must be set after.
                            if (startAt && !isAfter(value, startAt)) {
                              return getBeforeEndDateValidateMessage({
                                endInputTitle: "添削会社への提出期限",
                                startInputTitle: "開始日時",
                              });
                            }
                            return true;
                          },
                          beforeEndDate: (value) => {
                            // If there's an end_at, the value must be after the endAt.
                            if (endAt && !isSameOrAfter(value, endAt)) {
                              return getBeforeEndDateValidateMessage({
                                endInputTitle: "添削会社への提出期限",
                                startInputTitle: "生徒の提出期限",
                              });
                            }
                            return true;
                          },
                          afterStepOneEndDate: (value) => {
                            // If reviewer_end_at date is set after program endDate(modal step 1).
                            if (isAfter(value, stepOneEndDate)) {
                              return "添削会社への提出期限はプログラムの配信終了日時よりも前に設定してください。";
                            }
                            return true;
                          },
                        },
                      }}
                    />
                  </ImageListItem>
                )}
                <ImageListItem sx={gridItem}>
                  <Controller
                    control={control}
                    name={`schoolsWithAttachedTemplates.${nestedIndex}.templates.${index}.memo`}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        type="text"
                        variant="outlined"
                        onChange={onChange}
                        value={value}
                        label={setInputTitle({
                          title: "メモ",
                          isRequired: false,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </ImageListItem>
              </ImageList>
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};

export default Accordion;
