/* eslint-disable camelcase */
import {
  deleteSuperOwnerUserTag,
  fetchAllUserTagsAsSuperOwner,
  fetchLimitedUserTagsAsSuperOwner,
  fetchUserTagsAsSuperOwner,
  postSuperOwnerUserTag,
  resetState,
  saveUserTagSequence,
  updateSuperOwnerUserTag,
} from "@actions/superOwnerApp/userTag";
import { UserTag } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface SuperOwnerUserTagsState {
  fetchedAll: boolean;
  fetching: boolean;
  fetched: boolean;
  userTags: UserTag[];
  limitedUserTags: UserTag[];
  allUserTags: UserTag[];
  totalCount: number;
  error: any;
  changeStatus: ReduxStatusType;
  changeError: any; // for post, update, delete, and sort
}

export const initialState: SuperOwnerUserTagsState = {
  fetchedAll: false,
  fetching: false,
  fetched: false,
  userTags: [],
  limitedUserTags: [],
  allUserTags: [],
  totalCount: 0,
  error: null,
  changeStatus: ReduxStatus.idle,
  changeError: null,
};

export const superOwnerAppUserTagsSlice = createSlice({
  name: "SuperOwnerApp/UserTags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUserTagsAsSuperOwner.pending, (state) => {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchAllUserTagsAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchedAll: true,
          error: null,
          allUserTags: action.payload.user_tags,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchAllUserTagsAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchedAll: false,
          error: getErrorMessage(action.payload as any),
        };
      })
      .addCase(fetchUserTagsAsSuperOwner.pending, (state) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null,
        };
      })
      .addCase(fetchUserTagsAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          userTags: action.payload.user_tags,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchUserTagsAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: getErrorMessage(action.payload as any),
        };
      })
      .addCase(fetchLimitedUserTagsAsSuperOwner.pending, (state) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null,
        };
      })
      .addCase(fetchLimitedUserTagsAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          limitedUserTags: action.payload.user_tags,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchLimitedUserTagsAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: getErrorMessage(action.payload as any),
        };
      })
      .addCase(postSuperOwnerUserTag.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(postSuperOwnerUserTag.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
          fetchedAll: false,
        };
      })
      .addCase(postSuperOwnerUserTag.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload.message,
        };
      })
      .addCase(deleteSuperOwnerUserTag.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(deleteSuperOwnerUserTag.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
          fetchedAll: false,
        };
      })
      .addCase(deleteSuperOwnerUserTag.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(updateSuperOwnerUserTag.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(updateSuperOwnerUserTag.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
          fetchedAll: false,
        };
      })
      .addCase(updateSuperOwnerUserTag.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload.message,
        };
      })
      .addCase(saveUserTagSequence.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(saveUserTagSequence.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
        };
      })
      .addCase(saveUserTagSequence.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: null,
          changeStatus: ReduxStatus.idle,
          changeError: null,
        };
      });
  },
});

export const superOwnerAppUserTagsState = (
  state: RootState,
): SuperOwnerUserTagsState => state.superOwnerApp.userTags;

export default superOwnerAppUserTagsSlice.reducer;
