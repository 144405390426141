import { SubmitHomeworkFilesRequest } from "@lib/Api";
import { createSlice, current } from "@reduxjs/toolkit";

import {
  createSubmitHomeworkFilesRequest,
  fetchCompletedSubmitHomeworkFilesRequest,
  fetchSubmitHomeworkFilesRequest,
  resetCompletedState,
  resetModalState,
} from "../../actions/teacherApp/submitHomeworkFilesRequest";
import { RootState } from "../../store";

export interface TeacherAppSubmitHomeworkFilesRequestState {
  fetching: boolean;
  creating: boolean;
  created: boolean;
  createError: string | null;
  requests: SubmitHomeworkFilesRequest[];
  completedReqestsFetching: boolean;
  completedReqestsFetched: boolean;
  completedFetchError: string | null;
  completedRequests: SubmitHomeworkFilesRequest[];
}

export const initialState: TeacherAppSubmitHomeworkFilesRequestState = {
  fetching: false,
  creating: false,
  created: false,
  createError: null,
  requests: [],
  completedReqestsFetching: false,
  completedReqestsFetched: false,
  completedFetchError: null,
  completedRequests: [],
};

const teacherAppSubmitHomeworkFilesRequestSlice = createSlice({
  name: "teacherApp/SubmitHomeworkFilesRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubmitHomeworkFilesRequest.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetchError: null,
        };
      })
      .addCase(fetchSubmitHomeworkFilesRequest.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchSubmitHomeworkFilesRequest.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          requests: payload.requests,
          totalCount: payload.total_count,
        };
      })
      .addCase(
        fetchCompletedSubmitHomeworkFilesRequest.pending,
        (state, _action) => {
          return {
            ...state,
            completedReqestsFetching: true,
            completedReqestsFetched: false,
            completedFetchError: null,
          };
        },
      )
      .addCase(
        fetchCompletedSubmitHomeworkFilesRequest.rejected,
        (state, action) => {
          return {
            ...state,
            completedReqestsFetching: false,
            completedReqestsFetched: false,
            completedFetchError: action.error.message as string,
          };
        },
      )
      .addCase(
        fetchCompletedSubmitHomeworkFilesRequest.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            completedReqestsFetching: false,
            completedReqestsFetched: true,
            completedRequests: payload.requests,
            completedTotalCount: payload.total_count,
          };
        },
      )
      .addCase(createSubmitHomeworkFilesRequest.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          createError: null,
        };
      })
      .addCase(createSubmitHomeworkFilesRequest.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          createError: action.error.message as string,
        };
      })
      .addCase(createSubmitHomeworkFilesRequest.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          creating: false,
          created: true,
          requests: [payload, ...currentState.requests],
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          createError: null,
        };
      })
      .addCase(resetCompletedState, (state, _action) => {
        return {
          ...state,
          completedReqestsFetching: false,
          completedReqestsFetched: false,
          completedFetchError: null,
          completedRequests: [],
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppSubmitHomeworkFilesRequestState = (
  state: RootState,
): TeacherAppSubmitHomeworkFilesRequestState =>
  state.teacherApp.submitHomeworkFilesRequest;

export default teacherAppSubmitHomeworkFilesRequestSlice.reducer;
