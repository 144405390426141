import { Api, FetchPaymentsAsOwnerParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

// eslint-disable-next-line import/prefer-default-export
export const fetchPaymentsAsOwner = createAsyncThunk<
  any,
  FetchPaymentsAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/payments/fetch",
  async (params: FetchPaymentsAsOwnerParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.fetchPaymentsAsOwner(params, {});
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
