/* eslint-disable camelcase */

import {
  Api,
  FetchAffiliateInvitationsAsOwnerParams,
  StudentGrade,
  UserType,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { AxiosError } from "axios";

const api = new Api();

export const fetchAffiliateInvitationsAsOwner = createAsyncThunk<
  any,
  FetchAffiliateInvitationsAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/affiliateInvitation/fetch",
  async (
    params: FetchAffiliateInvitationsAsOwnerParams,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.owner.fetchAffiliateInvitationsAsOwner(params);
      return response.data;
    } catch (err) {
      const typedErr = err as AxiosError;
      return rejectWithValue(typedErr.response?.data);
    }
  },
);

export interface ActivateAffiliateInvitationArg {
  params: {
    grade?: StudentGrade;
    user_tag_ids?: string[]; // ID of user tags
    role: UserType;
  };
}

export const activate = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/affiliateInvitation/activate",
  async (arg: ActivateAffiliateInvitationArg, { rejectWithValue }) => {
    try {
      const response = await api.owner.activateAffiliateInvitationAsOwner(
        arg.params,
      );
      return {
        invitation: response.data as any,
        role: arg.params.role,
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const inactivate = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/affiliateInvitation/inactivate",
  async (invitationId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.inactivateAffiliateInvitationAsOwner(invitationId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetAffiliateInvitationData = createAction(
  "owner/affiliateInvitation/resetData",
);

export const resetAffiliateInvitationModalData = createAction(
  "owner/affiliateInvitation/resetModalData",
);

export const reactivate = createAsyncThunk(
  "owner/affiliateInvitation/reactivate",
  async (invitationId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.reactivateAffiliateInvitationAsOwner(invitationId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
