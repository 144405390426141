import { BaseCategoryInfo, GradeCategoryInfo } from "@lib/Api";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { MajorCategoryState } from "@reducers/category/major";
import { MiddleCategoryState } from "@reducers/category/middle";
import { MinorCategoryState } from "@reducers/category/minor";
import { SubjectCategoryState } from "@reducers/category/subject";
import React from "react";

import { StyledAccordion, StyledAccordionSummary } from "..";
import SubjectLayer from "./Subject";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gradeText: {
      fontSize: 16,
      marginLeft: 4,
      cursor: "pointer",
    },
    card: {
      borderRadius: 0,
    },
    summary: {
      backgroundColor: theme.palette.common.white,
    },
    activeSummary: {
      backgroundColor: theme.palette.grey[100],
    },
  }),
);

interface Props {
  categoryItem: GradeCategoryInfo;
  subject: SubjectCategoryState;
  major: MajorCategoryState;
  middle: MiddleCategoryState;
  minor: MinorCategoryState;
  selectedCategoryId: string[];
  handleSelectedIds: (item: BaseCategoryInfo) => void;
}

const GradeLayer: React.FC<Props> = (props: Props) => {
  const {
    categoryItem,
    subject,
    major,
    middle,
    minor,
    selectedCategoryId,
    handleSelectedIds,
  } = props;

  const classes = useStyles();

  return (
    <StyledAccordion expanded square className={classes.card}>
      <StyledAccordionSummary
        className={
          selectedCategoryId.includes(categoryItem.id)
            ? classes.activeSummary
            : classes.summary
        }
        onClick={() => handleSelectedIds(categoryItem)}
      >
        <Typography className={classes.gradeText}>
          {categoryItem.name}
        </Typography>
      </StyledAccordionSummary>
      {subject.items
        .filter((s) => categoryItem.id === s.grade_id)
        .map((subjectItem) => (
          <SubjectLayer
            key={subjectItem.id}
            categoryItem={subjectItem}
            major={major}
            middle={middle}
            minor={minor}
            selectedCategoryId={selectedCategoryId}
            handleSelectedIds={handleSelectedIds}
          />
        ))}
    </StyledAccordion>
  );
};

export default GradeLayer;
