import {
  createHomeworkTemplateAsOwner,
  deleteHomeworkTemplateAsOwner,
  fetchHomeworkTemplateAsOwner,
  fetchHomeworkTemplatesAsOwner,
  resetModalState,
  resetTemplateRelatedData,
  successUpdateTemplateAsOwner,
  updateHomeworkTemplateAsOwner,
} from "@actions/ownerApp/homeworkTemplate";
import {
  assignSchoolManagerAsOwner,
  returnToStudentsAsOwner,
  revertSubmissionToReviewerAsOwner,
  submitToReviewerAsOwner,
  updateAsOwner as updateDistributionAsOwner,
} from "@actions/ownerApp/homeworkTemplateDistribution";
import {
  HomeworkTemplateDistribution,
  HomeworkTemplateForOwnerAndTeacher,
} from "@lib/Api";
import { remove,update } from "@lib/collection";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice, current } from "@reduxjs/toolkit";

import { RootState } from "../../store";

export interface OwnerAppHomeworkTemplateState {
  fetching: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  changeError: string | null;
  fetchError: string | null;
  fetchOneError: string | null;
  creating: boolean;
  created: boolean;
  templates: HomeworkTemplateForOwnerAndTeacher[];
  template: HomeworkTemplateForOwnerAndTeacher | null;
  totalCount: number;
  successUpdate: boolean;
}

export const initialState: OwnerAppHomeworkTemplateState = {
  fetching: false,
  fetchingOne: false,
  fetchedOne: false,
  updating: false,
  updated: false,
  fetchError: null,
  fetchOneError: null,
  creating: false,
  created: false,
  changeError: null,
  deleting: false,
  deleted: false,
  templates: [],
  template: null,
  totalCount: 0,
  successUpdate: false,
};

const ownerAppHomeworkTemplateSlice = createSlice({
  name: "OwnerApp/HomeworkTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeworkTemplatesAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetchError: null,
        };
      })
      .addCase(fetchHomeworkTemplatesAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: errorWithMessage(action.payload),
          templates: [],
        };
      })
      .addCase(fetchHomeworkTemplatesAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetchError: null,
          templates: payload.homework_templates,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchHomeworkTemplateAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchOneerror: null,
        };
      })
      .addCase(fetchHomeworkTemplateAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: errorWithMessage(action.payload),
          template: null,
        };
      })
      .addCase(fetchHomeworkTemplateAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: null,
          template: payload,
        };
      })
      .addCase(createHomeworkTemplateAsOwner.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          changeError: null,
        };
      })
      .addCase(createHomeworkTemplateAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          creating: false,
          created: true,
          templates: [...currentState.templates, payload],
          template: payload,
          totalCount: currentState.totalCount + 1,
          changeError: null,
        };
      })
      .addCase(createHomeworkTemplateAsOwner.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeError: errorWithMessage(action.payload),
        };
      })
      .addCase(updateHomeworkTemplateAsOwner.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeError: null,
        };
      })
      .addCase(updateHomeworkTemplateAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          templates: update(currentState.templates, payload),
          template: payload,
          changeError: null,
        };
      })
      .addCase(updateHomeworkTemplateAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: errorWithMessage(action.payload),
        };
      })
      .addCase(deleteHomeworkTemplateAsOwner.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          changeError: null,
        };
      })
      .addCase(deleteHomeworkTemplateAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          deleting: false,
          deleted: true,
          templates: remove(currentState.templates, payload.id),
          template: null,
          changeError: null,
          totalCount: currentState.totalCount - 1,
        };
      })
      .addCase(deleteHomeworkTemplateAsOwner.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          changeError: errorWithMessage(action.payload),
        };
      })
      .addCase(assignSchoolManagerAsOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(assignSchoolManagerAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          changeError: errorWithMessage(action.payload),
        };
      })
      .addCase(assignSchoolManagerAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        const oldTemplate =
          state.template as HomeworkTemplateForOwnerAndTeacher;
        const newTemplate = {
          ...oldTemplate,
          homework_template_distribution: {
            ...(oldTemplate.homework_template_distribution as HomeworkTemplateDistribution),
            school_manager: {
              id: payload.teacherId,
              name: payload.teacherName,
            },
          },
        };
        return {
          ...state,
          updating: false,
          updated: true,
          template: newTemplate,
          templates: update(currentState.templates, newTemplate),
        };
      })
      .addCase(submitToReviewerAsOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(submitToReviewerAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: errorWithMessage(action.payload),
        };
      })
      .addCase(submitToReviewerAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          template: payload,
          templates: update(currentState.templates, payload),
        };
      })
      .addCase(revertSubmissionToReviewerAsOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeError: null,
        };
      })
      .addCase(revertSubmissionToReviewerAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: errorWithMessage(action.payload),
        };
      })
      .addCase(revertSubmissionToReviewerAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          template: payload,
          templates: update(currentState.templates, payload),
        };
      })
      .addCase(returnToStudentsAsOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(returnToStudentsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
        };
      })
      .addCase(returnToStudentsAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          template: payload,
          templates: update(currentState.templates, payload),
        };
      })
      .addCase(updateDistributionAsOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(updateDistributionAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
        };
      })
      .addCase(updateDistributionAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          template: payload,
          templates: update(currentState.templates, payload),
        };
      })
      .addCase(resetTemplateRelatedData, (state, _action) => {
        return {
          ...state,
          changeError: null,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: null,
          updating: false,
          updated: false,
          creating: false,
          created: false,
          template: null,
          successUpdate: false,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          creating: false,
          created: false,
          deleting: false,
          deleted: false,
          changeError: null,
          fetchingOne: false,
          fetchOneError: null,
          successUpdate: false,
        };
      })
      .addCase(successUpdateTemplateAsOwner, (state, _action) => {
        return {
          ...state,
          successUpdate: true,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppHomeworkTemplateState = (
  state: RootState,
): OwnerAppHomeworkTemplateState => state.ownerApp.homeworkTemplate;

export default ownerAppHomeworkTemplateSlice.reducer;
