/* eslint-disable camelcase */
import {
  PostIdealBoardsParams,
  PutIdealBoardsParams,
  UpdateIdealBoardHeaderParams,
} from "@actions/types/ideal";
import { Api, Board, BoardList, GetIdealBoardsAsStudentParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getIdealBoardsAsStudent = createAsyncThunk<
  { boards: BoardList[]; total_count: number },
  GetIdealBoardsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/ideal/boards/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.getIdealBoardsAsStudent(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getIdealBoardsMoreAsStudent = createAsyncThunk<
  { boards: BoardList[]; total_count: number },
  GetIdealBoardsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/ideal/boards/fetchMore", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.getIdealBoardsAsStudent(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getIdealBoardAsStudent = createAsyncThunk<
  Board,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/ideal/boards/fetchOne", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.getIdealBoardAsStudent(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getIdealBoardForAffiliateAvatarsAsStudent = createAsyncThunk<
  Board,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/ideal/boards/fetchOneForAffiliateAvatars",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.student.getIdealBoardAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const postIdealBoardsAsStudent = createAsyncThunk<
  any,
  PostIdealBoardsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/ideal/boards/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.postIdealBoardsAsStudent(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const putIdealBoardAsStudent = createAsyncThunk<
  any,
  PutIdealBoardsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/ideal/boards/put", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    const response = await api.student.putIdealBoardAsStudent(id, data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const putIdealBoardDataAsStudent = createAsyncThunk<
  any,
  PutIdealBoardsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/ideal/boards/putData", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    const response = await api.student.putIdealBoardAsStudent(id, data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deleteIdealBoardAsStudent = createAsyncThunk<
  any,
  { id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/ideal/boards/delete", async (params, { rejectWithValue }) => {
  try {
    const { id } = params;
    const response = await api.student.deleteIdealBoardAsStudent(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const resetStudentAppIdealState = createAction(
  "studentApp/ideal/resetIdealState",
);

export const resetStudentAppIdealModalState = createAction(
  "studentApp/ideal/resetIdealModalState",
);

// params array of affiliate_id
export const updateIdealBoardOnlineAffiliateIdsAsStudent = createAction<
  string[]
>("studentApp/ideal/updateOnlineAffiliateIds");

export const updateIdealBoardHeaderAsStudent =
  createAction<UpdateIdealBoardHeaderParams>(
    "studentApp/ideal/updateIdealBoardHeader",
  );
