/* eslint-disable camelcase */
import {
  Api,
  GetHomeworkTemplateChunkDistributionsAsReviewerParams,
  HomeworkTemplateChunkDistributionDetail,
  HomeworkTemplateChunkDistributionEvent,
  HomeworkTemplateChunkDistributionList,
  HomeworkTemplateChunkDistributionStatuses,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getHomeworkTemplateChunkDistributionsAsReviewer = createAsyncThunk<
  {
    homework_template_chunk_distributions: HomeworkTemplateChunkDistributionList[];
    template_chunk_distribution_statuses: HomeworkTemplateChunkDistributionStatuses;
    total_count: number;
  },
  GetHomeworkTemplateChunkDistributionsAsReviewerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateChunkDistribution/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.reviewer.getHomeworkTemplateChunkDistributionsAsReviewer(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getHomeworkTemplateChunkDistributionAsReviewer = createAsyncThunk<
  HomeworkTemplateChunkDistributionDetail,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateChunkDistribution/fetchOne",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.reviewer.getHomeworkTemplateChunkDistributionAsReviewer(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const putHomeworkTemplateChunkDistributionAsReviewer = createAsyncThunk<
  any,
  {
    id: string;
    data: { event: HomeworkTemplateChunkDistributionEvent };
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homeworkTemplateChunkDistribution/put",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.reviewer.putHomeworkTemplateChunkDistributionAsReviewer(
          params.id,
          params.data,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const assignHomeworkTemplateChunkDistributionToReviewerAsReviewer =
  createAsyncThunk<
    any,
    {
      id: string;
      data: { reviewer_affiliate_id: string | null };
    },
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    "reviewer/homeworkTemplateChunkDistribution/assign",
    async (params, { rejectWithValue }) => {
      try {
        const response =
          await api.reviewer.assignHomeworkTemplateChunkDistributionToReviewerAsReviewer(
            params.id,
            params.data,
          );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    },
  );

export const resetState = createAction(
  "reviewer/homeworkTemplateChunkDistribution/resetState",
);
