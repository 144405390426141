import { handleFeedBackButtonAsStudent } from "@components/Common/UniversityInterviewRequestList/util/interviewRequestUtil";
import { useAuth } from "@contexts/Auth";
import { CurrentUserInfo } from "@lib/Api";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import errorWithMessage from "@lib/rtk/error-utils";
import { Grid,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  InterviewFeedbackBase,
  InterviewRequestDetailBase,
  InterviewRequestListBase2,
  usePostInterviewMessageAsStudentMutation,
} from "@root/endpoints/TimeTactApi/studentApi";
import { InterviewRequestStatusEnum } from "@root/types/interviewRequest";
import React from "react";
import { useForm } from "react-hook-form";

import { ModalToOpen } from "../hook/useInterviewRequests";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import ChatMessages from "./ChatMessages";

type Props = {
  currentInterviewRequest: InterviewRequestDetailBase;
  messagesList: InterviewRequestListBase2[];
  feedBacks: InterviewFeedbackBase[];
  handleNotificationModalClose: () => void;
  refetchMessageList: () => void;
  handleModalState: (modalName: ModalToOpen) => void;
};

export type FormValues = {
  message: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      heigh: "100%",
      padding: theme.spacing(2, 3),
      backgroundColor: "white",
      overflow: "scroll",
      "scrollbar-width": "none" /* Firefox対応のスクロールバー非表示コード*/,
      "-ms-overflow-style":
        "none" /* Internet Explore対応のスクロールバー非表示コード*/,
      "&::-webkit-scrollbar": {
        display:
          "none" /* Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/,
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 1),
      },
    },
  }),
);

const ChatBox: React.FC<Props> = ({
  messagesList,
  currentInterviewRequest,
  feedBacks,
  refetchMessageList,
  handleModalState,
  handleNotificationModalClose,
}) => {
  const classes = useStyles();
  const requestId = currentInterviewRequest?.id || "";
  const { currentUser } = useAuth();
  const [postInterviewMessageAsStudent, { isLoading: isSendingAMessage }] =
    usePostInterviewMessageAsStudentMutation();
  const isFeeBackDisplay = handleFeedBackButtonAsStudent(
    currentUser as CurrentUserInfo,
    feedBacks,
  );
  const requestStatusCode = currentInterviewRequest?.status_code || "";
  const isCalendarIconDisplay =
    !currentInterviewRequest?.preferred_dates?.length;
  const isElementsDisabled =
    !isFeeBackDisplay ||
    requestStatusCode === InterviewRequestStatusEnum.COMPLETED ||
    requestStatusCode === InterviewRequestStatusEnum.APPROVAL_PENDING ||
    requestStatusCode === InterviewRequestStatusEnum.INVALIDED ||
    requestStatusCode === InterviewRequestStatusEnum.NON_APPROVED ||
    !Object.keys(currentInterviewRequest).length ||
    isSendingAMessage;
  const { actions } = useEnqueueToast();
  const { showError } = actions;
  const defaultValues = { message: "" };
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues,
  });

  const resetChatInput = () => {
    reset(defaultValues);
  };

  const onSubmit = (formValues: FormValues) => {
    postInterviewMessageAsStudent({
      requestId,
      body: {
        message: formValues.message,
      },
    })
      .unwrap()
      .then(() => {
        refetchMessageList();
        resetChatInput();
      })
      .catch((error) => showError(errorWithMessage(error)));
  };

  return (
    <Grid item md={8} xs={12} className={classes.main}>
      <ChatHeader
        currentInterviewRequest={currentInterviewRequest}
        isFeeBackDisplay={isFeeBackDisplay}
        handleModalState={handleModalState}
        handleNotificationModalClose={handleNotificationModalClose}
      />
      <ChatMessages
        requestId={requestId}
        messages={messagesList}
        feedBacks={feedBacks}
        currentUserId={currentUser?.affiliate_id as string}
        isElementsDisabled={isElementsDisabled}
        refetchMessageList={refetchMessageList}
      />
      <ChatInput
        control={control}
        requestId={requestId}
        currentInterviewRequest={currentInterviewRequest}
        isElementsDisabled={isElementsDisabled}
        isCalendarIconDisplay={isCalendarIconDisplay}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        handleModalState={handleModalState}
        refetchMessageList={refetchMessageList}
        resetChatInput={resetChatInput}
      />
    </Grid>
  );
};

export default ChatBox;
