import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import {
  ArticleBase,
  ArticleComment,
  ArticleDetailBase,
  ArticleFeedback,
  WordCloudWord,
} from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  fetchArticleAsOwner,
  fetchArticlesAsOwner,
  fetchWordCloudDataAsOwner,
  resetArticleRelatedData,
} from "../../actions/ownerApp/article";
import { fetchArticleCommentsAsOwner } from "../../actions/ownerApp/articleComment";
import { fetchArticleFeedbacksAsOwner } from "../../actions/ownerApp/articleFeedback";
import { RootState } from "../../store";

export interface OwnerAppArticleState {
  fetching: boolean;
  fetched: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchWordCloudData: ReduxStatusType;
  fetchOneError: any;
  fetchingFeedbacks: boolean;
  fetchedFeedbacks: boolean;
  serverError: any;
  fetchWordCloudError: any;
  totalCount: number;
  articles: ArticleBase[];
  article: ArticleDetailBase | null;
  articleComments: ArticleComment[];
  fetchingComments: boolean;
  fetchedComments: boolean;
  articleFeedbacks: ArticleFeedback[];
  wordCloudData: WordCloudWord[];
}

export const initialState: OwnerAppArticleState = {
  fetching: false,
  fetched: false,
  fetchingOne: false,
  fetchedOne: false,
  fetchWordCloudData: ReduxStatus.idle,
  fetchOneError: null,
  serverError: null,
  fetchWordCloudError: null,
  articles: [],
  totalCount: 0,
  article: null,
  articleComments: [],
  fetchingComments: false,
  fetchedComments: false,
  articleFeedbacks: [],
  wordCloudData: [],
  fetchingFeedbacks: false,
  fetchedFeedbacks: false,
};

export const ownerAppArticleSlice = createSlice({
  name: "OwnerApp/Article",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticlesAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          serverError: null,
        };
      })
      .addCase(fetchArticlesAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          articles: payload.articles,
          totalCount: payload.total_count,
          serverError: "",
        };
      })
      .addCase(fetchArticlesAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          articles: [],
          serverError: action.error,
        };
      })
      .addCase(fetchArticleAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchedOne: false,
          fetchingOne: true,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          article: payload,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error,
          article: null,
        };
      })
      .addCase(fetchWordCloudDataAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchWordCloudData: ReduxStatus.pending,
        };
      })
      .addCase(fetchWordCloudDataAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchWordCloudData: ReduxStatus.fulfilled,
          wordCloudData: payload.word_cloud_list,
        };
      })
      .addCase(fetchWordCloudDataAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchWordCloudData: ReduxStatus.rejected,
          fetchWordCloudError: action.error,
        };
      })
      .addCase(fetchArticleCommentsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchedComments: false,
          fetchingComments: true,
          serverError: null,
        };
      })
      .addCase(fetchArticleCommentsAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingComments: false,
          fetchedComments: true,
          articleComments: payload.article_comments,
          serverError: "",
        };
      })
      .addCase(fetchArticleCommentsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          articles: [],
          serverError: action.error,
        };
      })
      .addCase(fetchArticleFeedbacksAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchedFeedbacks: false,
          fetchingFeedbacks: true,
          serverError: null,
        };
      })
      .addCase(fetchArticleFeedbacksAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingFeedbacks: false,
          fetchedFeedbacks: true,
          articleFeedbacks: payload.article_feedbacks,
          serverError: "",
        };
      })
      .addCase(fetchArticleFeedbacksAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          articles: [],
          serverError: action.error,
        };
      })
      .addCase(resetArticleRelatedData, (state, _action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: null,
          fetchingFeedbacks: false,
          fetchedFeedbacks: false,
          fetchedComments: false,
          fetchingComments: false,
          fetchCommentsError: null,
          fetchFeedbackError: null,
          articleFeedbacks: [],
          articleComments: [],
          article: null,
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const ownerAppArticleState = (state: RootState): OwnerAppArticleState =>
  state.ownerApp.article;

export default ownerAppArticleSlice.reducer;
