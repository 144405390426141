import { MultilineText } from "@components/UI/atoms/MultilineText/MultilineText";
import Section from "@components/UI/organisms/Layout/SectionWrapper";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import CommonTable from "@components/UIv2/organisms/CommonTable";
import { StudyLogCategorizableType } from "@lib/Api";
import { StudyLogBase as StudyLogsType } from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";
import { useTranslation } from "react-i18next";

import { handleModalLogTableInfos } from "../../utils";

interface Props {
  studyLogs: StudyLogsType[];
  perPage: number;
  currentPage: number;
  totalCount: number;
  isFetchingStudyLog: boolean;
  handlePageTransition: (page: number) => void;
}

const WeekLogs: React.FC<Props> = ({
  studyLogs,
  handlePageTransition,
  perPage,
  currentPage,
  totalCount,
  isFetchingStudyLog,
}) => {
  const { t } = useTranslation(["study_logs", "common"]);
  const tableBody = studyLogs.map((log) => {
    const { name, icon } = handleModalLogTableInfos(
      log.categorizable_type as StudyLogCategorizableType,
    );
    return {
      id: log.id,
      fullBody: log,
      cells: [
        { id: "1", body: <span>{icon}</span> },
        { id: "2", body: name },
        { id: "3", body: log.behavior },
        { id: "4", body: <MultilineText>{log.log}</MultilineText> },
        { id: "4", body: log.created_at },
      ],
    };
  });

  const tableHeaders = [
    { title: "", id: "1" },
    { title: t("menu"), id: "2" },
    { title: t("content"), id: "3" },
    { title: t("title"), id: "4" },
    { title: t("editedDate"), id: "5" },
  ];

  return (
    <Section title={t("learningLogs")}>
      <CommonTable
        headers={tableHeaders}
        bodyItems={tableBody}
        isLoading={isFetchingStudyLog}
        noDataMessage={t("common:noData")}
      />
      <V2Pagination
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        handlePageTransition={handlePageTransition}
      />
    </Section>
  );
};

export default WeekLogs;
