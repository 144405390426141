import { STUDENT_ROUTES, TEACHER_ROUTES } from "@constants/routes";
import { useAuth } from "@contexts/Auth";
import makeAppInfo from "@lib/app/app-info-utils";
import useCloudRoleAndFeature from "@lib/hooks/useCloudRoleAndFeature";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import useOptionFeature from "@lib/hooks/useOptionFeature";
import errorWithMessage from "@lib/rtk/error-utils";
import {
  usePostTtGoogleDrivesRevokeAsCommonMutation,
  usePostTtMicrosoftDrivesRevokeAsCommonMutation,
} from "@root/endpoints/TimeTactApi/remainingApi";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import * as AuthActions from "@store/actions/auth";
import { commonState } from "@store/reducers/common";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

type Params = {
  googleDriveIsConnected: boolean;
  microsoftDriveIsConnected: boolean;
  setOpenProfile?: React.Dispatch<React.SetStateAction<boolean>>;
  checkAndSetGoogleConnected: () => Promise<void>;
  setAnchorProfile: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  checkAndSetMicrosoftConnected: () => Promise<void>;
};
export const useDefaultMenuItems = ({
  googleDriveIsConnected,
  microsoftDriveIsConnected,
  setOpenProfile,
  checkAndSetGoogleConnected,
  setAnchorProfile,
  checkAndSetMicrosoftConnected,
}: Params) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "class_nav", "modal"]);
  const { companies } = useAppSelector(commonState);
  const { canUseOneDrive, canUseGoogleDrive } = useCloudRoleAndFeature();
  const {
    currentUser,
    isCurrentRoles: { isStudent, isReviewer, isTeacher },
  } = useAuth();
  const { isMylsil, loginPath } = makeAppInfo(
    currentUser ? currentUser.app_name : "",
  );
  const isMatchStudentClassNav = !!useRouteMatch(STUDENT_ROUTES.CLASS_NAV);
  const isMatchTeacherClassNav = !!useRouteMatch(TEACHER_ROUTES.CLASS_NAV);
  const isMatchClassNav = isMatchStudentClassNav || isMatchTeacherClassNav;
  const isMatchAffiliate = useRouteMatch("/affiliates");
  const { isClassNavFeature } = useOptionFeature();
  const { actions } = useEnqueueToast();
  const hasProfileMenu = isStudent || isReviewer;
  const deleteTokenAndLogout = () => {
    dispatch(AuthActions.deleteTokenAndLogout({}));
    history.push(loginPath);
  };

  const [googleDriveDisconnect] = usePostTtGoogleDrivesRevokeAsCommonMutation();
  const googleDriveDisconnectAndRefetch = () => {
    googleDriveDisconnect({})
      .unwrap()
      .then(() => {
        actions.showSuccess(t("googleDriveDisconnectionSuccess"));
        setAnchorProfile(null);
        checkAndSetGoogleConnected();
      })
      .catch((error) => actions.showError(errorWithMessage(error)));
  };

  const [microsoftDriveDisconnect] =
    usePostTtMicrosoftDrivesRevokeAsCommonMutation();
  const microsoftDriveDisconnectAndRefetch = () => {
    microsoftDriveDisconnect({})
      .unwrap()
      .then(() => {
        actions.showSuccess(t("oneDriveDisconnectionSuccess"));
        checkAndSetMicrosoftConnected();
      })
      .catch((error) => actions.showError(errorWithMessage(error)));
  };

  const handleLogout = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault();
    localStorage.clear();
    deleteTokenAndLogout();
  };

  const switchAffiliatePage = () => {
    localStorage.clear();
    history.push("/affiliates");
  };

  const menuItems = [
    companies.length === 1 &&
      companies[0].roles.length > 1 && {
        key: "company",
        title: t("useAnotherRole"),
        dataCy: "company",
        action: () => switchAffiliatePage(),
      },
    companies.length > 1 && {
      key: "another_profile",
      dataCy: "another_profile",
      title: t("useAnotherRole"),
      action: () => switchAffiliatePage(),
    },
    hasProfileMenu &&
      setOpenProfile && {
        key: "student_profile",
        dataCy: "student_profile",
        title: t("myProfile"),
        action: () => setOpenProfile(true),
      },
    isTeacher &&
      !isMatchAffiliate && {
        key: "teacher_profile",
        title: t("myProfile"),
        dataCy: "teacher_profile",
        action: () => history.push("/teacher/profile"),
      },

    isClassNavFeature &&
      isStudent &&
      !isMatchClassNav && {
        key: "class_nav",
        title: t("class_nav:class_nav"),
        dataCy: "class_nav",
        action: () => history.push(STUDENT_ROUTES.CLASS_NAV),
      },
    {
      key: "password_update",
      title: t("passwordUpdate"),
      dataCy: "password_update",
      action: () =>
        window.open(
          `/auth/change_password${isMylsil ? "?app_name=mylsil" : ""}`,
          `_blank`,
        ),
    },
    canUseOneDrive &&
      microsoftDriveIsConnected && {
        key: "oneDriveDisconnection",
        title: t("oneDriveDisconnection"),
        dataCy: "oneDriveDisconnection",
        action: microsoftDriveDisconnectAndRefetch,
      },
    canUseGoogleDrive &&
      googleDriveIsConnected && {
        key: "googleDriveDisconnection",
        title: t("googleDriveDisconnection"),
        dataCy: "googleDriveDisconnection",
        action: googleDriveDisconnectAndRefetch,
      },
    {
      key: "logout",
      title: t("logout"),
      dataCy: "logout",
      action: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
        handleLogout(e),
    },
  ].filter(Boolean);
  return menuItems;
};
