import {
  batchUpdateBoardsAffiliateAsTeacher,
  resetTeacherAppIdealBoardsAffiliates,
} from "@actions/teacherApp/ideal/boardsAffiliate";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface TeacherAppIdealBoardsAffiliateState {
  batchUpdateIdealAffiliates: ReduxStatusType;
  batchUpdateIdealAffiliatesError: string | null;
}

export const initialState: TeacherAppIdealBoardsAffiliateState = {
  batchUpdateIdealAffiliates: ReduxStatus.idle,
  batchUpdateIdealAffiliatesError: null,
};

const TeacherAppIdealBoardsAffiliateSlice = createSlice({
  name: "teacherApp/IdealBoard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        batchUpdateBoardsAffiliateAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            batchUpdateIdealAffiliates: ReduxStatus.pending,
            batchUpdateIdealAffiliatesError: null,
          };
        },
      )
      .addCase(
        batchUpdateBoardsAffiliateAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            batchUpdateIdealAffiliates: ReduxStatus.rejected,
            batchUpdateIdealAffiliatesError:
              "他の人が同時に編集したため、更新に失敗しました。",
          };
        },
      )
      .addCase(
        batchUpdateBoardsAffiliateAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            batchUpdateIdealAffiliates: ReduxStatus.fulfilled,
            batchUpdateIdealAffiliatesError: null,
          };
        },
      )
      .addCase(resetTeacherAppIdealBoardsAffiliates, (state, _action) => {
        return {
          ...state,
          batchUpdateIdealAffiliates: ReduxStatus.idle,
          batchUpdateIdealAffiliatesError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppIdealBoardsAffiliateState = (
  state: RootState,
): TeacherAppIdealBoardsAffiliateState => state.teacherApp.idealBoardsAffiliate;

export default TeacherAppIdealBoardsAffiliateSlice.reducer;
