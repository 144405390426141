import {
  createSponsorProjectCategoryAsAdmin,
  deleteSponsorProjectCategoryAsAdmin,
  resetAction,
  updateSponsorProjectCategoryAsAdmin,
} from "@actions/adminApp/pbl/sponsor/projectCategory";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface AdminAppPblSponsorProjectCategoryState {
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  changeErrors: any[] | null;
}

export const initialState: AdminAppPblSponsorProjectCategoryState = {
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  changeErrors: null,
};

const adminAppPblSponsorProjectCategorySlice = createSlice({
  name: "adminApp/pbl/sponsors/projects/category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        createSponsorProjectCategoryAsAdmin.pending,
        (state, _action) => {
          return {
            ...state,
            creating: true,
            created: false,
            changeErrors: null,
          };
        },
      )
      .addCase(
        createSponsorProjectCategoryAsAdmin.rejected,
        (state, action) => {
          return {
            ...state,
            creating: false,
            created: false,
            changeErrors: action.payload,
          };
        },
      )
      .addCase(
        createSponsorProjectCategoryAsAdmin.fulfilled,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: true,
            changeErrors: null,
          };
        },
      )
      .addCase(
        updateSponsorProjectCategoryAsAdmin.pending,
        (state, _action) => {
          return {
            ...state,
            updating: true,
            updated: false,
            changeErrors: null,
          };
        },
      )
      .addCase(
        updateSponsorProjectCategoryAsAdmin.rejected,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: false,
            changeErrors: action.payload,
          };
        },
      )
      .addCase(
        updateSponsorProjectCategoryAsAdmin.fulfilled,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: true,
            changeErrors: null,
          };
        },
      )
      .addCase(
        deleteSponsorProjectCategoryAsAdmin.pending,
        (state, _action) => {
          return {
            ...state,
            deleting: true,
            deleted: false,
            changeErrors: null,
          };
        },
      )
      .addCase(
        deleteSponsorProjectCategoryAsAdmin.rejected,
        (state, action) => {
          return {
            ...state,
            deleting: false,
            deleted: false,
            changeErrors: action.payload,
          };
        },
      )
      .addCase(
        deleteSponsorProjectCategoryAsAdmin.fulfilled,
        (state, action) => {
          return {
            ...state,
            deleting: false,
            deleted: true,
            changeErrors: null,
          };
        },
      )
      .addCase(resetAction, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          changeErrors: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const adminAppPblSponsorProjectCategoryState = (
  state: RootState,
): AdminAppPblSponsorProjectCategoryState =>
  state.adminApp.pblSponsorProjectCategory;

export default adminAppPblSponsorProjectCategorySlice.reducer;
