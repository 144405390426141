/* eslint-disable camelcase */
import {
  Api,
  GetUserTagsAlreadyBeenDistributedAsOwnerParams,
  ListMaterialDistributionHistoryAsOwnerParams,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

export interface DistributeMaterialWorkAsOwnerParams {
  material_id: string | undefined;
  user_tag_ids: string[] | undefined;
  publication_time: string | undefined;
  expiration_time: string | undefined;
  forbid_download?: boolean;
}

export const fetchAllDistributedMaterialWorksAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["listMaterialDistributionHistoryAsOwner"]>,
  ListMaterialDistributionHistoryAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/fetchAllDistributedMaterialWork/",
  async (
    params,

    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.owner.listMaterialDistributionHistoryAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const distributeMaterialWorkAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["materialWorksBatchCreateUsingTagsCreate"]>,
  DistributeMaterialWorkAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/materialWork/distributeAsOwner", async (data, { rejectWithValue }) => {
  try {
    const response =
      await api.owner.materialWorksBatchCreateUsingTagsCreate(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchUserTagsForDistributionAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["getUserTagsAlreadyBeenDistributedAsOwner"]>,
  GetUserTagsAlreadyBeenDistributedAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/userTagsDistributedByMaterialAll/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.getUserTagsAlreadyBeenDistributedAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchUserTagsDistributedByMaterialAsOwner = createAsyncThunk<
  GetResponseType<Api["owner"]["getUserTagsAlreadyBeenDistributedAsOwner"]>,
  GetUserTagsAlreadyBeenDistributedAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/userTagsDistributedByMaterial/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.getUserTagsAlreadyBeenDistributedAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "owner/materialWorkDistribution/resetState",
);
