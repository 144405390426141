/* eslint-disable react/default-props-match-prop-types */
import MainButton from "@components/UI/atoms/MainButton/MainButton";
import Transition from "@components/UI/atoms/ModalTransition";
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import useResponsive from "@lib/hooks/useResponsive";
import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, Box,DialogActions, Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent, { DialogContentProps } from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";

export interface DataCyTypes {
  primaryBtn?: string;
  secondaryBtn?: string;
  leftBtn?: string;
  modalTitle?: string;
  modalCloseBtn?: string;
}
interface Props {
  /**
   * Change the Modal to fullScreen Modal
   * it will also add the smooth animation
   */
  fullScreen?: boolean;
  /**
   * Disable primary action button
   */
  primaryLock?: boolean;
  /**
   * Disable secondary action button
   */
  secondaryLock?: boolean;
  /**
   * Disable left action button
   */
  leftLock?: boolean;
  /**
   * Display the primary action button
   */
  useActionButtons?: boolean;
  /**
   * Hide the primary action button
   */
  disusePrimaryButton?: boolean;
  /**
   * Display the secondary action button
   */
  useSecondaryButton?: boolean;
  /**
   * if used and "true" display the left button
   */
  useLeftButton?: boolean;
  /**
   * Modal title
   */
  title?: string | JSX.Element;
  /**
   * Modal sizes:
   * "xs", "sm", "md", "lg", "xl"
   */
  modalSize?: "xs" | "sm" | "md" | "lg" | "xl";
  /**
   * Open state of modal
   */
  open: boolean;
  /**
   * Close state of modal
   */
  handleClose: () => void;
  /**
   * Handle posting state - disable button with progress
   */
  posting?: boolean;
  /**
   * Handle posting state for secondary button action - disable button
   */
  secondaryButtonActionPosting?: boolean;
  /**
   * Text of primary button
   */
  primaryButtonText?: string;
  /**
   * Text of secondary button
   */
  secondaryButtonText?: string;
  /**
   * Text of left button
   */
  leftButtonText?: string;
  /**
   * Action of primary button
   */
  primaryButtonAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Action of secondary button
   */
  secondaryButtonAction?: () => void;
  /**
   * Left button Action
   */
  leftButtonAction?: () => void;
  /**
   * If html dialog remove padding
   */
  html?: boolean;
  /**
   * If there is no X to close the modal
   */
  noClose?: boolean;
  /**
   * Padding 0 for the form
   */
  noPadding?: boolean;
  primaryButtonIcon?: React.ReactNode;
  secondaryButtonIcon?: React.ReactNode;
  /**
   * data-cy setting
   */
  dataCy?: DataCyTypes;
  /**
   * For changing the Dialog Content args
   */
  contentArgs?: DialogContentProps;
  /**
   * For adding backdrop to the modal
   */
  backdrop?: boolean;
}

const useStyles = () => ({
  modalTitle: (theme: Theme) => ({
    position: "relative",
    margin: 0,
    padding: theme.spacing(1.5, 6, 1.5, 3),
    background: theme.palette.action.hover,
    color: theme.palette.common.black,
    minHeight: 50,
  }),
  closeButton: (theme: Theme) => ({
    position: "absolute",
    right: "10px",
    top: "2px",
    color: theme.palette.common.black,
  }),
  actions: (theme: Theme) => ({
    background: theme.palette.action.hover,
    padding: theme.spacing(1.5, 3),
  }),
  actionsWithLeft: (theme: Theme) => ({
    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.action.hover,
    padding: theme.spacing(1.5, 3),
  }),
  secondaryButton: (theme: Theme) => ({
    color: theme.palette.customColor.text,
    border: `2px solid ${theme.palette.customColor.text}`,
  }),
  icon: (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
  }),
  backdrop: (theme: Theme) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  }),
  rightButtons: (theme: Theme) => ({
    display: "flex",
    gap: theme.spacing(2),
  }),
});

const StyledDialog = withStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    paperFullWidth: {
      width: "100%",
      borderRadius: "8px",
      border: `2px solid ${theme.palette.tertiary.mainDark}`,
    },
    paperWidthFalse: {
      maxWidth: "initial",
    },
  }),
)(Dialog);

export type ModalProps = Props & Omit<DialogProps, "title">;

const GenericModal: React.FC<ModalProps> = ({
  title,
  open,
  handleClose,
  children,
  modalSize,
  useActionButtons,
  primaryButtonText,
  secondaryButtonText,
  leftButtonText,
  primaryButtonAction,
  secondaryButtonAction,
  primaryButtonIcon,
  secondaryButtonIcon,
  leftButtonAction,
  disusePrimaryButton = false,
  useSecondaryButton,
  useLeftButton,
  posting,
  secondaryButtonActionPosting,
  primaryLock,
  secondaryLock,
  leftLock,
  html,
  noClose,
  noPadding,
  fullScreen,
  dataCy,
  contentArgs,
  backdrop,
  ...args
}) => {
  const classes = useStyles();
  const { isMobileView } = useResponsive();
  const isFullScreen = isMobileView || !!fullScreen;

  return (
    <StyledDialog
      open={open}
      onClose={(_: any, reason: string) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return false;
        }
        return handleClose();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      data-cy="generic-modal-container"
      fullWidth
      fullScreen={isFullScreen}
      maxWidth={modalSize}
      TransitionComponent={isFullScreen ? Transition : undefined}
      {...args}
    >
      {backdrop && (
        <Backdrop sx={classes.backdrop} open={!!posting}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <DialogTitle
        sx={classes.modalTitle}
        data-cy={dataCy?.modalTitle ?? "generic-modal-title"}
      >
        {!noClose && (
          <IconButton
            aria-label="close"
            sx={classes.closeButton}
            onClick={handleClose}
            disabled={posting}
            data-cy={dataCy?.modalCloseBtn ?? "generic-modal-close"}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
        {title}
      </DialogTitle>
      <DialogContent
        {...contentArgs}
        style={{
          padding: noPadding ? 0 : "8px 24px",
        }}
      >
        {children}
      </DialogContent>
      {useActionButtons && (
        <DialogActions
          sx={useLeftButton ? classes.actionsWithLeft : classes.actions}
        >
          {useLeftButton && (
            <MainButton
              data-cy={dataCy?.leftBtn ?? "modal-left-button"}
              variant="contained"
              onClick={leftButtonAction}
              disabled={leftLock}
            >
              {leftButtonText}
            </MainButton>
          )}
          <Box sx={classes.rightButtons}>
            {useSecondaryButton && (
              <SingleButton
                data-cy={dataCy?.secondaryBtn ?? "modal-secondary-button"}
                variant="outlined"
                onClick={secondaryButtonAction || handleClose}
                sx={classes.secondaryButton}
                disabled={
                  posting || secondaryButtonActionPosting || secondaryLock
                }
                startIcon={secondaryButtonIcon}
              >
                {secondaryButtonText}
              </SingleButton>
            )}
            {!disusePrimaryButton && (
              <MainButton
                data-cy={dataCy?.primaryBtn ?? "modal-primary-button"}
                variant="contained"
                onClick={primaryButtonAction}
                type="submit"
                disabled={posting || primaryLock}
                startIcon={primaryButtonIcon}
              >
                {posting ? <CircularProgress size="22px" /> : primaryButtonText}
              </MainButton>
            )}
          </Box>
        </DialogActions>
      )}
    </StyledDialog>
  );
};

export default GenericModal;

GenericModal.defaultProps = {
  modalSize: undefined,
  primaryLock: false,
  secondaryLock: false,
  leftLock: false,
  useActionButtons: false,
  title: "",
  useSecondaryButton: false,
  useLeftButton: false,
  posting: false,
  secondaryButtonActionPosting: false,
  primaryButtonText: "",
  secondaryButtonText: "",
  leftButtonText: "",
  primaryButtonAction: () => null,
  secondaryButtonAction: () => null,
  leftButtonAction: () => null,
  html: false,
  noClose: false,
  noPadding: false,
  fullScreen: false,
};
