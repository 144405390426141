/* eslint-disable camelcase */
import { Api, LimitOptionBase } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getCompanyLimitOption = createAsyncThunk<
  { limit_option: LimitOptionBase; total_count?: number },
  { id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/company_limit_option/get",
  async (params: { id: string }, { rejectWithValue }) => {
    try {
      const response = await api.owner.getCompanyLimitOptionAsOwner(params.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  },
);

export default getCompanyLimitOption;
