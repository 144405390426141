import { StyledTagColor } from "@components/UI/atoms/StyledTag";
import {
  DeletionProgress,
  DistributionStatusStatus,
  DistributionStatusStatusJa,
} from "@lib/Api";

export const getDistributionStatusKeyByJa = (
  status: DistributionStatusStatusJa,
): DistributionStatusStatus => {
  switch (status) {
    case DistributionStatusStatusJa.idle:
      return DistributionStatusStatus.Idle;
    case DistributionStatusStatusJa.save_running:
      return DistributionStatusStatus.SaveRunning;
    case DistributionStatusStatusJa.save_failed:
      return DistributionStatusStatus.SaveFailed;
    case DistributionStatusStatusJa.distribution_idle:
      return DistributionStatusStatus.DistributionIdle;
    case DistributionStatusStatusJa.distribution_running:
      return DistributionStatusStatus.DistributionRunning;
    case DistributionStatusStatusJa.distribution_failed:
      return DistributionStatusStatus.DistributionFailed;
    case DistributionStatusStatusJa.completed:
      return DistributionStatusStatus.Completed;
    default:
      throw new Error("Invalid distribution status");
  }
};

export const getDistributionStatusColor = (
  status: DistributionStatusStatus | DeletionProgress.Running,
): StyledTagColor => {
  switch (status) {
    case DistributionStatusStatus.Idle:
    case DistributionStatusStatus.DistributionIdle:
      return StyledTagColor.LightGrey;
    case DistributionStatusStatus.SaveRunning:
    case DistributionStatusStatus.DistributionRunning:
      return StyledTagColor.Orange;
    case DistributionStatusStatus.SaveFailed:
    case DistributionStatusStatus.DistributionFailed:
      return StyledTagColor.Pink;
    case DistributionStatusStatus.Completed:
      return StyledTagColor.Green;
    case DeletionProgress.Running:
      return StyledTagColor.Blue;
    default:
      throw new Error("Invalid status");
  }
};

export const getDistributionStatusColorByJa = (
  statusJa: DistributionStatusStatusJa,
): StyledTagColor =>
  getDistributionStatusColor(getDistributionStatusKeyByJa(statusJa));
