import { createSponsorLectureAsAdmin } from "@actions/adminApp/pbl/sponsor/lecture";
import {
  createSponsorProjectAsAdmin,
  deleteSponsorProjectAsAdmin,
  fetchLecturesByProjectAsAdmin,
  fetchPblSponsorProjectAsAdmin,
  fetchPblSponsorProjectsAsAdmin,
  reset,
  resetAction,
  resetOne,
  toggleDisplayOfProjectOnCarouselAsAdmin,
  updateSponsorProjectAsAdmin,
} from "@actions/adminApp/pbl/sponsor/project";
import {
  createSponsorProjectCategoryAsAdmin,
  deleteSponsorProjectCategoryAsAdmin,
  updateSponsorProjectCategoryAsAdmin,
} from "@actions/adminApp/pbl/sponsor/projectCategory";
import { SponsorProjectAndTagsBase, SponsorProjectDetailsBase } from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface AdminAppPblSponsorProjectState {
  fetching: boolean;
  fetchingOne: boolean;
  fetched: boolean;
  fetchedOne: boolean;
  fetchErrors: string | null;
  fetchOneErrors: string | null;
  projects: SponsorProjectAndTagsBase[];
  projectDetails: SponsorProjectDetailsBase | null;
  totalCount: number;
  fetchingLectures: boolean;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  changeErrors: any[] | null;
}

export const initialState: AdminAppPblSponsorProjectState = {
  fetching: false,
  fetchingOne: false,
  fetched: false,
  fetchedOne: false,
  fetchErrors: null,
  fetchOneErrors: null,
  projects: [],
  projectDetails: null,
  totalCount: 0,
  fetchingLectures: false,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  changeErrors: null,
};

const adminAppPblSponsorProjectSlice = createSlice({
  name: "adminApp/pbl/sponsors/project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblSponsorProjectsAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchPblSponsorProjectsAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchPblSponsorProjectsAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: null,
          projects: payload.projects,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchPblSponsorProjectAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
        };
      })
      .addCase(fetchPblSponsorProjectAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error.message as string,
        };
      })
      .addCase(fetchPblSponsorProjectAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          fetchOneError: null,
          projectDetails: payload,
        };
      })
      .addCase(fetchLecturesByProjectAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetchingLectures: true,
        };
      })
      .addCase(fetchLecturesByProjectAsAdmin.rejected, (state, _action) => {
        return {
          ...state,
          fetchingLectures: false,
        };
      })
      .addCase(fetchLecturesByProjectAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        const currentLectures =
          currentState.projectDetails && currentState.projectDetails.lectures
            ? currentState.projectDetails.lectures
            : [];
        const newProjectDetails = currentState.projectDetails
          ? {
              ...currentState.projectDetails,
              lectures: [...currentLectures, ...payload.project_lectures],
            }
          : null;
        return {
          ...state,
          fetchingLectures: false,
          projectDetails: newProjectDetails,
        };
      })
      .addCase(createSponsorProjectAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          changeErrors: null,
        };
      })
      .addCase(createSponsorProjectAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeErrors: action.payload,
        };
      })
      .addCase(createSponsorProjectAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          creating: false,
          created: true,
          changeErrors: null,
          projects: [...state.projects, payload],
          totalCount: state.totalCount + 1,
        };
      })
      .addCase(updateSponsorProjectAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeErrors: null,
        };
      })
      .addCase(updateSponsorProjectAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeErrors: action.payload,
        };
      })
      .addCase(updateSponsorProjectAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updating: false,
          updated: true,
          changeErrors: null,
          projectDetails: payload,
          projects: update(state.projects, payload),
        };
      })
      .addCase(
        toggleDisplayOfProjectOnCarouselAsAdmin.pending,
        (state, _action) => {
          return {
            ...state,
            updating: true,
            updated: false,
            changeErrors: null,
          };
        },
      )
      .addCase(
        toggleDisplayOfProjectOnCarouselAsAdmin.rejected,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: false,
            changeErrors: action.payload,
          };
        },
      )
      .addCase(
        toggleDisplayOfProjectOnCarouselAsAdmin.fulfilled,
        (state, _action) => {
          return {
            ...state,
            updating: false,
            updated: true,
            changeErrors: null,
          };
        },
      )
      .addCase(deleteSponsorProjectAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          changeErrors: null,
        };
      })
      .addCase(deleteSponsorProjectAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          changeErrors: action.payload,
        };
      })
      .addCase(deleteSponsorProjectAsAdmin.fulfilled, (state, action) => {
        const deletedId = action.meta.arg;
        return {
          ...state,
          deleting: false,
          deleted: true,
          changeErrors: null,
          projectDetails: null,
          projects: remove(state.projects, deletedId),
          totalCount: state.totalCount - 1,
        };
      })
      .addCase(createSponsorLectureAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(
        createSponsorProjectCategoryAsAdmin.fulfilled,
        (state, action) => {
          const { payload } = action;
          let updatedProjectDetails = state.projectDetails;
          if (state.projectDetails) {
            const { projectDetails } = state;
            const cats = projectDetails.categories
              ? projectDetails.categories
              : [];
            updatedProjectDetails = {
              ...projectDetails,
              categories: [...cats, payload],
            };
          }
          return {
            ...state,
            projectDetails: updatedProjectDetails,
          };
        },
      )
      .addCase(
        updateSponsorProjectCategoryAsAdmin.fulfilled,
        (state, action) => {
          const { payload } = action;
          let updatedProjectDetails = state.projectDetails;
          if (state.projectDetails) {
            const { projectDetails } = state;
            const cats = projectDetails.categories
              ? projectDetails.categories
              : [];
            updatedProjectDetails = {
              ...projectDetails,
              categories: update(cats, payload),
            };
          }
          return {
            ...state,
            projectDetails: updatedProjectDetails,
          };
        },
      )
      .addCase(
        deleteSponsorProjectCategoryAsAdmin.fulfilled,
        (state, action) => {
          const deletedId = action.meta.arg;
          let updatedProjectDetails = state.projectDetails;
          if (state.projectDetails) {
            const { projectDetails } = state;
            const cats = projectDetails.categories
              ? projectDetails.categories
              : [];
            updatedProjectDetails = {
              ...projectDetails,
              categories: remove(cats, deletedId),
            };
          }
          return {
            ...state,
            projectDetails: updatedProjectDetails,
          };
        },
      )
      .addCase(resetAction, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          changeErrors: null,
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          projectDetails: null,
          fetchedOne: false,
          fetchOneError: null,
        };
      })
      .addCase(reset, (state, _action) => {
        return {
          ...state,
          projects: [],
          fetched: false,
          fetchError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const adminAppPblSponsorProjectState = (
  state: RootState,
): AdminAppPblSponsorProjectState => state.adminApp.pblSponsorProject;

export default adminAppPblSponsorProjectSlice.reducer;
