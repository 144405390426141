/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchProjectBookmarksAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/projects/bookmarks/fetch",
  async (projectId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.fetchSponsorProjectBookmarkByProjectAsTeacher({
          project_id: projectId,
        });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchProjectBookmarkByProjectAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/projects/bookmarks/fetchOneByProject",
  async (projectId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.fetchSponsorProjectBookmarkByProjectAsTeacher({
          project_id: projectId,
        });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addProjectBookmarkAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/projects/bookmarks/add",
  async (projectId: string, { rejectWithValue }) => {
    try {
      const response = await api.teacher.addSponsorProjectBookmarkAsTeacher({
        project_id: projectId,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const removeProjectBookmarkAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/projects/bookmarks/remove",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.removeSponsorProjectBookmarkAsTeacher(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const reset = createAction(
  "teacher/pbl/sponsor/projects/bookmarks/reset",
);
export const resetOne = createAction(
  "teacher/pbl/sponsor/projects/bookmarks/resetOne",
);
export const resetAction = createAction(
  "teacher/pbl/sponsor/projects/bookmarks/resetAction",
);
export const resetAddAction = createAction(
  "teacher/pbl/sponsor/projects/bookmarks/resetAddAction",
);

export const resetRemoveAction = createAction(
  "teacher/pbl/sponsor/projects/bookmarks/resetRemoveAction",
);
