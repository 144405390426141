/* eslint-disable camelcase */
type valueOf<T> = T[keyof T];

const constRoles = {
  ADMIN: "admin",
  OWNER: "owner",
  TEACHER: "teacher",
  STUDENT: "student",
  REVIEWER: "reviewer",
  SUPER_OWNER: "super_owner",
  CONTENT_PROVIDER: "content_provider",
} as const;

export type Role = valueOf<typeof constRoles>;

export const roles: Record<keyof typeof constRoles, string> = {
  ...constRoles,
};

export const rolesHash = {
  USER: "users",
  SUPER_OWNER: "superOwners",
  OWNER: "owners",
  TEACHER: "teachers",
  STUDENT: "students",
  CONTENT_PROVIDER: "contentProviders",
};

export enum JpRoleNames {
  admin = "SV管理者",
  super_owner = "親管理者",
  reviewer = "添削利用者",
  content_provider = "コンテンツ事業者",
  owner = "管理者",
  teacher = "教職員",
  student = "生徒",
}
