import { baseApi as api } from "../baseApi";
export const addTagTypes = [
  "AffiliateInvitation",
  "SSO",
  "Auth",
  "Roles",
  "CurrentUser",
  "Common/Addresses",
  "Common/Inquiries",
  "Common/Affiliates",
  "Schedules",
  "Common/GradeMaster",
  "Common/Category",
  "Common/Material",
  "Common/InviteUsers",
  "Common/TermsAccepts",
  "Common/Users",
  "Common/AttachedImages",
  "Common/NewNotificationCount",
  "Common/TtGoogleDrives",
  "Common/TtMicrosoftDrives",
  "Common/Icalendar",
  "Common/ConnectivityCheckStatus",
  "GoogleApi/Calendar",
  "GoogleApi/Classroom",
  "Public/PblBoardInfos",
  "Public/PblBoardArticles",
  "Public/AttachedImages",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAffiliateInvitationsWithTokenByToken: build.query<
        GetAffiliateInvitationsWithTokenByTokenApiResponse,
        GetAffiliateInvitationsWithTokenByTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/affiliate_invitations/with_token/${queryArg.token}`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["AffiliateInvitation"],
      }),
      getAuthorizations: build.query<
        GetAuthorizationsApiResponse,
        GetAuthorizationsApiArg
      >({
        query: (queryArg) => ({
          url: `/authorizations`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            response_type: queryArg.responseType,
            client_id: queryArg.clientId,
            redirect_uri: queryArg.redirectUri,
            scope: queryArg.scope,
          },
        }),
        providesTags: ["SSO"],
      }),
      postAuthSignIn: build.mutation<
        PostAuthSignInApiResponse,
        PostAuthSignInApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/sign_in`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Auth"],
      }),
      deleteAuthSignOut: build.mutation<
        DeleteAuthSignOutApiResponse,
        DeleteAuthSignOutApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/sign_out`,
          method: "DELETE",
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
        }),
        invalidatesTags: ["Auth"],
      }),
      postAuthSignupWithInvitation: build.mutation<
        PostAuthSignupWithInvitationApiResponse,
        PostAuthSignupWithInvitationApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/signup_with_invitation`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Auth"],
      }),
      postAuthPassword: build.mutation<
        PostAuthPasswordApiResponse,
        PostAuthPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Auth"],
      }),
      putAuthPassword: build.mutation<
        PutAuthPasswordApiResponse,
        PutAuthPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
            expiry: queryArg.expiry,
            "token-type": queryArg["token-type"],
          },
        }),
        invalidatesTags: ["Auth"],
      }),
      getAuthInvitationTokenStatus: build.query<
        GetAuthInvitationTokenStatusApiResponse,
        GetAuthInvitationTokenStatusApiArg
      >({
        query: () => ({ url: `/auth/invitation/token_status` }),
        providesTags: ["Auth"],
      }),
      putResendInvitationAsAuth: build.mutation<
        PutResendInvitationAsAuthApiResponse,
        PutResendInvitationAsAuthApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/invitation/resend_invitation`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Auth"],
      }),
      getAuthConfirmation: build.query<
        GetAuthConfirmationApiResponse,
        GetAuthConfirmationApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/confirmation`,
          params: { confirmation_token: queryArg.confirmationToken },
        }),
        providesTags: ["Auth"],
      }),
      putAuthInvitation: build.mutation<
        PutAuthInvitationApiResponse,
        PutAuthInvitationApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/invitation`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Auth"],
      }),
      postAuthRequest: build.mutation<
        PostAuthRequestApiResponse,
        PostAuthRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/request`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Auth"],
      }),
      getAuthUnlock: build.query<GetAuthUnlockApiResponse, GetAuthUnlockApiArg>(
        {
          query: (queryArg) => ({
            url: `/auth/unlock`,
            params: { unlock_token: queryArg.unlockToken },
          }),
          providesTags: ["Auth"],
        },
      ),
      getAuthMicrosoftOauth2Callback: build.query<
        GetAuthMicrosoftOauth2CallbackApiResponse,
        GetAuthMicrosoftOauth2CallbackApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/microsoft_oauth2callback`,
          params: { code: queryArg.code },
        }),
        providesTags: ["Auth"],
      }),
      getRoles: build.query<GetRolesApiResponse, GetRolesApiArg>({
        query: (queryArg) => ({
          url: `/roles`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Roles"],
      }),
      getCommonCurrentUserMe: build.query<
        GetCommonCurrentUserMeApiResponse,
        GetCommonCurrentUserMeApiArg
      >({
        query: () => ({ url: `/common/current_user/me` }),
        providesTags: ["CurrentUser"],
      }),
      getAddressAsCommon: build.query<
        GetAddressAsCommonApiResponse,
        GetAddressAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/addresses/search/${queryArg.zipcode}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Common/Addresses"],
      }),
      postCommonInquiries: build.mutation<
        PostCommonInquiriesApiResponse,
        PostCommonInquiriesApiArg
      >({
        query: (queryArg) => ({
          url: `/common/inquiries`,
          method: "POST",
          body: queryArg.postInquiryRequestBodyAsCommon,
        }),
        invalidatesTags: ["Common/Inquiries"],
      }),
      putCommonInquiriesById: build.mutation<
        PutCommonInquiriesByIdApiResponse,
        PutCommonInquiriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/common/inquiries/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putInquiryRequestBodyAsCommon,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Common/Inquiries"],
      }),
      getCommonAffiliates: build.query<
        GetCommonAffiliatesApiResponse,
        GetCommonAffiliatesApiArg
      >({
        query: () => ({ url: `/common/affiliates` }),
        providesTags: ["Common/Affiliates"],
      }),
      postCommonAffiliatesSwitch: build.mutation<
        PostCommonAffiliatesSwitchApiResponse,
        PostCommonAffiliatesSwitchApiArg
      >({
        query: (queryArg) => ({
          url: `/common/affiliates/switch`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Common/Affiliates"],
      }),
      getCommonSchedules: build.query<
        GetCommonSchedulesApiResponse,
        GetCommonSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/common/schedules`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ["Schedules"],
      }),
      postCommonSchedules: build.mutation<
        PostCommonSchedulesApiResponse,
        PostCommonSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/common/schedules`,
          method: "POST",
          body: queryArg.postScheduleRequestBodyAsCommon,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Schedules"],
      }),
      getCommonSchedulesById: build.query<
        GetCommonSchedulesByIdApiResponse,
        GetCommonSchedulesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/common/schedules/${queryArg.id}`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Schedules"],
      }),
      putCommonSchedulesById: build.mutation<
        PutCommonSchedulesByIdApiResponse,
        PutCommonSchedulesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/common/schedules/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putScheduleRequestBodyAsCommon,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Schedules"],
      }),
      deleteCommonSchedulesById: build.mutation<
        DeleteCommonSchedulesByIdApiResponse,
        DeleteCommonSchedulesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/common/schedules/${queryArg.id}`,
          method: "DELETE",
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Schedules"],
      }),
      getCommonSchedulesSearch: build.query<
        GetCommonSchedulesSearchApiResponse,
        GetCommonSchedulesSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/common/schedules/search`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            affiliate_id: queryArg.affiliateId,
            not_include_google_calendar: queryArg.notIncludeGoogleCalendar,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ["Schedules"],
      }),
      patchSchedulesBatchCreate: build.mutation<
        PatchSchedulesBatchCreateApiResponse,
        PatchSchedulesBatchCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/common/schedules/batch_create`,
          method: "PATCH",
          body: queryArg.batchCreateScheduleRequestBodyAsCommon,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Schedules"],
      }),
      patchSchedulesBatchUpdate: build.mutation<
        PatchSchedulesBatchUpdateApiResponse,
        PatchSchedulesBatchUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/common/schedules/batch_update`,
          method: "PATCH",
          body: queryArg.batchUpdateScheduleRequestBodyAsCommon,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Schedules"],
      }),
      getCommonGradeMasters: build.query<
        GetCommonGradeMastersApiResponse,
        GetCommonGradeMastersApiArg
      >({
        query: () => ({ url: `/common/grade_masters` }),
        providesTags: ["Common/GradeMaster"],
      }),
      getCommonCategories: build.query<
        GetCommonCategoriesApiResponse,
        GetCommonCategoriesApiArg
      >({
        query: () => ({ url: `/common/categories` }),
        providesTags: ["Common/Category"],
      }),
      getCommonMaterials: build.query<
        GetCommonMaterialsApiResponse,
        GetCommonMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/common/materials`,
          params: { category_id: queryArg.categoryId },
        }),
        providesTags: ["Common/Material"],
      }),
      getCommonMaterialsById: build.query<
        GetCommonMaterialsByIdApiResponse,
        GetCommonMaterialsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/common/materials/${queryArg.id}` }),
        providesTags: ["Common/Material"],
      }),
      postCommonInviteUserMailers: build.mutation<
        PostCommonInviteUserMailersApiResponse,
        PostCommonInviteUserMailersApiArg
      >({
        query: (queryArg) => ({
          url: `/common/invite_user_mailers`,
          method: "POST",
          body: queryArg.userInviteUsersRequestBodyAsCommon,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Common/InviteUsers"],
      }),
      putCommonTermsAcceptsById: build.mutation<
        PutCommonTermsAcceptsByIdApiResponse,
        PutCommonTermsAcceptsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/common/terms_accepts/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Common/TermsAccepts"],
      }),
      postCommonKpisSponsorPageViews: build.mutation<
        PostCommonKpisSponsorPageViewsApiResponse,
        PostCommonKpisSponsorPageViewsApiArg
      >({
        query: (queryArg) => ({
          url: `/common/kpis/sponsor_page_views`,
          method: "POST",
          body: queryArg.sponsorPageViewsRequestBody,
        }),
      }),
      putControllerAsRole: build.mutation<
        PutControllerAsRoleApiResponse,
        PutControllerAsRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/common/users`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Common/Users"],
      }),
      postAttachedImageAsCommon: build.mutation<
        PostAttachedImageAsCommonApiResponse,
        PostAttachedImageAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/attached_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Common/AttachedImages"],
      }),
      getAttachedImageAsCommon: build.query<
        GetAttachedImageAsCommonApiResponse,
        GetAttachedImageAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/attached_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["Common/AttachedImages"],
      }),
      putAttachedImageAsCommon: build.mutation<
        PutAttachedImageAsCommonApiResponse,
        PutAttachedImageAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/attached_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Common/AttachedImages"],
      }),
      deleteAttachedImageAsCommon: build.mutation<
        DeleteAttachedImageAsCommonApiResponse,
        DeleteAttachedImageAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/attached_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Common/AttachedImages"],
      }),
      getNewNotificationCountAsCommon: build.query<
        GetNewNotificationCountAsCommonApiResponse,
        GetNewNotificationCountAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/new_notifications`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Common/NewNotificationCount"],
      }),
      patchNewNotificationCountAsCommon: build.mutation<
        PatchNewNotificationCountAsCommonApiResponse,
        PatchNewNotificationCountAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/new_notifications/update_read_and_check`,
          method: "PATCH",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Common/NewNotificationCount"],
      }),
      getTtGoogleDrivesAsCommon: build.query<
        GetTtGoogleDrivesAsCommonApiResponse,
        GetTtGoogleDrivesAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_google/drives`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page_size: queryArg.pageSize,
            page_token: queryArg.pageToken,
            order_by: queryArg.orderBy,
            folder_id: queryArg.folderId,
          },
        }),
        providesTags: ["Common/TtGoogleDrives"],
      }),
      getTtGoogleDrivesFormatAsCommon: build.query<
        GetTtGoogleDrivesFormatAsCommonApiResponse,
        GetTtGoogleDrivesFormatAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_google/drives/format`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { file_id: queryArg.fileId },
        }),
        providesTags: ["Common/TtGoogleDrives"],
      }),
      getTtGoogleDrivesDownloadAsCommon: build.query<
        GetTtGoogleDrivesDownloadAsCommonApiResponse,
        GetTtGoogleDrivesDownloadAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_google/drives/download`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { file_id: queryArg.fileId },
        }),
        providesTags: ["Common/TtGoogleDrives"],
      }),
      getTtGoogleDrivesOauth2CallbackAsCommon: build.query<
        GetTtGoogleDrivesOauth2CallbackAsCommonApiResponse,
        GetTtGoogleDrivesOauth2CallbackAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_google/drives/oauth2callback`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            code: queryArg.code,
            scope: queryArg.scope,
            error: queryArg.error,
          },
        }),
        providesTags: ["Common/TtGoogleDrives"],
      }),
      postTtGoogleDrivesRevokeAsCommon: build.mutation<
        PostTtGoogleDrivesRevokeAsCommonApiResponse,
        PostTtGoogleDrivesRevokeAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_google/drives/revoke`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Common/TtGoogleDrives"],
      }),
      getTtGoogleDrivesConnectionAsCommon: build.query<
        GetTtGoogleDrivesConnectionAsCommonApiResponse,
        GetTtGoogleDrivesConnectionAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_google/drives/connection`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Common/TtGoogleDrives"],
      }),
      getTtMicrosoftDrivesAsCommon: build.query<
        GetTtMicrosoftDrivesAsCommonApiResponse,
        GetTtMicrosoftDrivesAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_microsoft/drives`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            folder_id: queryArg.folderId,
            top: queryArg.top,
            next_link: queryArg.nextLink,
            search: queryArg.search,
            filter: queryArg.filter,
            orderby: queryArg.orderby,
          },
        }),
        providesTags: ["Common/TtMicrosoftDrives"],
      }),
      getTtMicrosoftDrivesFormatAsCommon: build.query<
        GetTtMicrosoftDrivesFormatAsCommonApiResponse,
        GetTtMicrosoftDrivesFormatAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_microsoft/drives/format`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { item_id: queryArg.itemId },
        }),
        providesTags: ["Common/TtMicrosoftDrives"],
      }),
      getTtMicrosoftDrivesOauth2CallbackAsCommon: build.query<
        GetTtMicrosoftDrivesOauth2CallbackAsCommonApiResponse,
        GetTtMicrosoftDrivesOauth2CallbackAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_microsoft/drives/oauth2callback`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { code: queryArg.code },
        }),
        providesTags: ["Common/TtMicrosoftDrives"],
      }),
      postTtMicrosoftDrivesRevokeAsCommon: build.mutation<
        PostTtMicrosoftDrivesRevokeAsCommonApiResponse,
        PostTtMicrosoftDrivesRevokeAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_microsoft/drives/revoke`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Common/TtMicrosoftDrives"],
      }),
      getTtMicrosoftDrivesConnectionAsCommon: build.query<
        GetTtMicrosoftDrivesConnectionAsCommonApiResponse,
        GetTtMicrosoftDrivesConnectionAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/tt_microsoft/drives/connection`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Common/TtMicrosoftDrives"],
      }),
      getIcalendarsAsCommon: build.query<
        GetIcalendarsAsCommonApiResponse,
        GetIcalendarsAsCommonApiArg
      >({
        query: (queryArg) => ({
          url: `/common/icalendars`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            start_time: queryArg.startTime,
            end_time: queryArg.endTime,
            title: queryArg.title,
            description: queryArg.description,
            location: queryArg.location,
            url: queryArg.url,
          },
        }),
        providesTags: ["Common/Icalendar"],
      }),
      getConnectivityCheckStatus: build.query<
        GetConnectivityCheckStatusApiResponse,
        GetConnectivityCheckStatusApiArg
      >({
        query: () => ({ url: `/common/connectivity/check_status` }),
        providesTags: ["Common/ConnectivityCheckStatus"],
      }),
      getGoogleApiCalendarOauth2Callback: build.query<
        GetGoogleApiCalendarOauth2CallbackApiResponse,
        GetGoogleApiCalendarOauth2CallbackApiArg
      >({
        query: (queryArg) => ({
          url: `/google_api/calendar/oauth2callback`,
          params: { code: queryArg.code, scope: queryArg.scope },
        }),
        providesTags: ["GoogleApi/Calendar"],
      }),
      getGoogleApiClassroomOauth2Callback: build.query<
        GetGoogleApiClassroomOauth2CallbackApiResponse,
        GetGoogleApiClassroomOauth2CallbackApiArg
      >({
        query: (queryArg) => ({
          url: `/google_api/classroom/oauth2callback`,
          params: { code: queryArg.code, scope: queryArg.scope },
        }),
        providesTags: ["GoogleApi/Classroom"],
      }),
      postWebhooksVideosEncoded: build.mutation<
        PostWebhooksVideosEncodedApiResponse,
        PostWebhooksVideosEncodedApiArg
      >({
        query: (queryArg) => ({
          url: `/webhooks/videos/encoded`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      getPblBoardInfosAsPublic: build.query<
        GetPblBoardInfosAsPublicApiResponse,
        GetPblBoardInfosAsPublicApiArg
      >({
        query: (queryArg) => ({
          url: `/public/pbl/board/infos/index`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            company_type: queryArg.companyType,
            is_random: queryArg.isRandom,
          },
        }),
        providesTags: ["Public/PblBoardInfos"],
      }),
      getPblBoardInfoAsPublic: build.query<
        GetPblBoardInfoAsPublicApiResponse,
        GetPblBoardInfoAsPublicApiArg
      >({
        query: (queryArg) => ({
          url: `/public/pbl/board/infos`,
          params: {
            company_id: queryArg.companyId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Public/PblBoardInfos"],
      }),
      getPblBoardArticleAsPublic: build.query<
        GetPblBoardArticleAsPublicApiResponse,
        GetPblBoardArticleAsPublicApiArg
      >({
        query: (queryArg) => ({
          url: `/public/pbl/board/articles/${queryArg.id}`,
          params: { company_id: queryArg.companyId },
        }),
        providesTags: ["Public/PblBoardArticles"],
      }),
      getPblBoardArticlesWordCloudAsPublic: build.query<
        GetPblBoardArticlesWordCloudAsPublicApiResponse,
        GetPblBoardArticlesWordCloudAsPublicApiArg
      >({
        query: (queryArg) => ({
          url: `/public/pbl/board/articles/word_cloud`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            list_len: queryArg.listLen,
            offset: queryArg.offset,
            min_num_of_char: queryArg.minNumOfChar,
            company_id: queryArg.companyId,
          },
        }),
        providesTags: ["Public/PblBoardArticles"],
      }),
      postAttachedImageAsPublic: build.mutation<
        PostAttachedImageAsPublicApiResponse,
        PostAttachedImageAsPublicApiArg
      >({
        query: (queryArg) => ({
          url: `/public/attached_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Public/AttachedImages"],
      }),
      getAttachedImageAsPublic: build.query<
        GetAttachedImageAsPublicApiResponse,
        GetAttachedImageAsPublicApiArg
      >({
        query: (queryArg) => ({
          url: `/public/attached_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["Public/AttachedImages"],
      }),
      putAttachedImageAsPublic: build.mutation<
        PutAttachedImageAsPublicApiResponse,
        PutAttachedImageAsPublicApiArg
      >({
        query: (queryArg) => ({
          url: `/public/attached_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Public/AttachedImages"],
      }),
      deleteAttachedImageAsPublic: build.mutation<
        DeleteAttachedImageAsPublicApiResponse,
        DeleteAttachedImageAsPublicApiArg
      >({
        query: (queryArg) => ({
          url: `/public/attached_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Public/AttachedImages"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as remainingApi };
export type GetAffiliateInvitationsWithTokenByTokenApiResponse =
  /** status 200 OK */ AffiliateInvitation;
export type GetAffiliateInvitationsWithTokenByTokenApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  token: string;
};
export type GetAuthorizationsApiResponse = unknown;
export type GetAuthorizationsApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  responseType?: string;
  clientId?: string;
  redirectUri?: string;
  scope?: string;
};
export type PostAuthSignInApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type PostAuthSignInApiArg = {
  /** メールアドレスとパスワードの発行 */
  body: {
    email: string;
    password: string;
    recaptcha_token: string;
    is_recaptcha_v2?: boolean;
    terms_accepted: boolean;
    app_name?: AppName;
    remember_me?: boolean;
  };
};
export type DeleteAuthSignOutApiResponse = unknown;
export type DeleteAuthSignOutApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
};
export type PostAuthSignupWithInvitationApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PostAuthSignupWithInvitationApiArg = {
  body: {
    invitation_id: string;
    email: string;
    first_name: string;
    last_name: string;
    nickname?: string;
    parent_first_name?: string;
    parent_last_name?: string;
    birth_date?: string;
    phone?: string;
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    password: string;
    recaptcha_token: string;
    is_recaptcha_v2?: boolean;
    terms_accepted: boolean;
    app_name?: AppName;
  };
};
export type PostAuthPasswordApiResponse = /** status 200 OK */ {
  success?: boolean;
  message?: string;
};
export type PostAuthPasswordApiArg = {
  /** フロントからEmailアドレスとリダイレクトURLを受け取り、データベースからEmailアドレスを持つユーザを探します。いれば、トークンを発行してそのトークン情報を載せたメールを受けっとったEmailアドレス宛に送信します。 */
  body: {
    email?: string;
    redirect_url?: string;
    app_name?: AppName;
  };
};
export type PutAuthPasswordApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutAuthPasswordApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  expiry?: string;
  "token-type"?: string;
  /** passwordとpassword_confirmationは同じ値であること */
  body: {
    password?: string;
    password_confirmation?: string;
  };
};
export type GetAuthInvitationTokenStatusApiResponse = /** status 200 OK */ {
  invitation_token: string;
  status?: string;
};
export type GetAuthInvitationTokenStatusApiArg = void;
export type PutResendInvitationAsAuthApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutResendInvitationAsAuthApiArg = {
  body: {
    invitation_token: string;
    app_name?: AppName;
  };
};
export type GetAuthConfirmationApiResponse = unknown;
export type GetAuthConfirmationApiArg = {
  confirmationToken?: string;
};
export type PutAuthInvitationApiResponse = /** status 200 OK */
  | undefined
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type PutAuthInvitationApiArg = {
  /** invitation_tokenはURLに付与されているものを設定。passwordおよびpassword_confirmationは同じ値を設定 */
  body: {
    invitation_token: string;
    password: string;
    password_confirmation: string;
    app_name?: AppName;
  };
};
export type PostAuthRequestApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type PostAuthRequestApiArg = {
  body: {
    id_token?: string;
    access_token?: string;
    remember_me?: boolean;
  };
};
export type GetAuthUnlockApiResponse = unknown;
export type GetAuthUnlockApiArg = {
  unlockToken?: string;
};
export type GetAuthMicrosoftOauth2CallbackApiResponse =
  /** status 200 OK */ MicrosoftLoginBase;
export type GetAuthMicrosoftOauth2CallbackApiArg = {
  /** microsoft_oauth2_url後に返却されるcodeを指定 */
  code?: string;
};
export type GetRolesApiResponse = /** status 200 OK */ {
  roles: string[];
  company_id: string;
};
export type GetRolesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
};
export type GetCommonCurrentUserMeApiResponse =
  /** status 200 OK */ CurrentUserInfo;
export type GetCommonCurrentUserMeApiArg = void;
export type GetAddressAsCommonApiResponse =
  /** status 200 200 OK */ AddressBaseCommon;
export type GetAddressAsCommonApiArg = {
  zipcode: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostCommonInquiriesApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostCommonInquiriesApiArg = {
  /** Set os and browser version so tech support can be eased. */
  postInquiryRequestBodyAsCommon: PostInquiryRequestBodyAsCommon;
};
export type PutCommonInquiriesByIdApiResponse = /** status 202 Accepted */ {
  message?: string;
};
export type PutCommonInquiriesByIdApiArg = {
  /** inquiries ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putInquiryRequestBodyAsCommon: PutInquiryRequestBodyAsCommon;
};
export type GetCommonAffiliatesApiResponse =
  /** status 200 OK */ AffiliateBaseAsCommon;
export type GetCommonAffiliatesApiArg = void;
export type PostCommonAffiliatesSwitchApiResponse = /** status 200 OK */ {
  message?: string;
};
export type PostCommonAffiliatesSwitchApiArg = {
  body: {
    company_id?: string;
    role_id?: string;
  };
};
export type GetCommonSchedulesApiResponse =
  /** status 200 privateフラグがtrueのときには、startとendのみ返します。 */ ScheduleBase[];
export type GetCommonSchedulesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  startDate: string;
  endDate: string;
};
export type PostCommonSchedulesApiResponse = /** status 201 created */ {
  message?: string;
};
export type PostCommonSchedulesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  postScheduleRequestBodyAsCommon: PostScheduleRequestBodyAsCommon;
};
export type GetCommonSchedulesByIdApiResponse =
  /** status 200 OK */ ScheduleBase;
export type GetCommonSchedulesByIdApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  id: string;
};
export type PutCommonSchedulesByIdApiResponse = /** status 202 202 Accepted */ {
  message?: string;
};
export type PutCommonSchedulesByIdApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  id: string;
  putScheduleRequestBodyAsCommon: PutScheduleRequestBodyAsCommon;
};
export type DeleteCommonSchedulesByIdApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteCommonSchedulesByIdApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  id: string;
};
export type GetCommonSchedulesSearchApiResponse =
  /** status 200 privateフラグがtrueのときには、startとendのみ返します。 */ ScheduleBase[];
export type GetCommonSchedulesSearchApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  /** Affiliate ID を指定してください。 */
  affiliateId: string;
  /** このパラメータが存在するとGoogle Calendarは表示されない。 */
  notIncludeGoogleCalendar?: string;
  startDate: string;
  endDate: string;
};
export type PatchSchedulesBatchCreateApiResponse =
  /** status 201 created */ ScheduleBatchBase[];
export type PatchSchedulesBatchCreateApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  batchCreateScheduleRequestBodyAsCommon: BatchCreateScheduleRequestBodyAsCommon;
};
export type PatchSchedulesBatchUpdateApiResponse =
  /** status 202 accepted */ ScheduleBatchBase[];
export type PatchSchedulesBatchUpdateApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  batchUpdateScheduleRequestBodyAsCommon: BatchUpdateScheduleRequestBodyAsCommon;
};
export type GetCommonGradeMastersApiResponse = /** status 200 OK */ {
  id?: string;
  grade?: string;
  grade_name?: string;
  graduation_year?: string;
}[];
export type GetCommonGradeMastersApiArg = void;
export type GetCommonCategoriesApiResponse = /** status 200 OK */ {
  texts: TextCategoryInfo[];
  grades: GradeCategoryInfo[];
  subjects: SubjectCategoryInfo[];
  major_categories: MajorCategoryInfo[];
  middle_categories: MiddleCategoryInfo[];
  minor_categories: MinorCategoryInfo[];
};
export type GetCommonCategoriesApiArg = void;
export type GetCommonMaterialsApiResponse = /** status 200 OK */ {
  materials: MaterialList[];
  total_count: number;
};
export type GetCommonMaterialsApiArg = {
  /** Category ID that the help page belongs to */
  categoryId: string;
};
export type GetCommonMaterialsByIdApiResponse =
  /** status 200 OK */ MaterialDetail;
export type GetCommonMaterialsByIdApiArg = {
  /** 対象の教材ID */
  id: string;
};
export type PostCommonInviteUserMailersApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostCommonInviteUserMailersApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  userInviteUsersRequestBodyAsCommon: UserInviteUsersRequestBodyAsCommon;
};
export type PutCommonTermsAcceptsByIdApiResponse = /** status 202 Accepted */ {
  message?: string;
};
export type PutCommonTermsAcceptsByIdApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  id: string;
  body: {
    terms_accepted?: boolean;
  };
};
export type PostCommonKpisSponsorPageViewsApiResponse =
  /** status 200 OK(but not recorded) */
    | undefined
    | /** status 201 Created */ {
        message?: string;
      };
export type PostCommonKpisSponsorPageViewsApiArg = {
  /** 対象となるObjectのID(Hash処理済み)およびそのLayerを記載 */
  sponsorPageViewsRequestBody: SponsorPageViewsRequestBody;
};
export type PutControllerAsRoleApiResponse = /** status 202 Accepted */ {
  user?: {
    first_time_login?: boolean;
  };
};
export type PutControllerAsRoleApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    first_time_login: boolean;
  };
};
export type PostAttachedImageAsCommonApiResponse =
  /** status 201 Created */ AttachedImageBase;
export type PostAttachedImageAsCommonApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetAttachedImageAsCommonApiResponse =
  /** status 200 OK */ AttachedImageBase;
export type GetAttachedImageAsCommonApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PutAttachedImageAsCommonApiResponse =
  /** status 202 Accepted */ AttachedImageBase;
export type PutAttachedImageAsCommonApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteAttachedImageAsCommonApiResponse =
  /** status 200 Accepted */ {
    message?: string;
  };
export type DeleteAttachedImageAsCommonApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type GetNewNotificationCountAsCommonApiResponse =
  /** status 200 OK */ NotificationCounts;
export type GetNewNotificationCountAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PatchNewNotificationCountAsCommonApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PatchNewNotificationCountAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTtGoogleDrivesAsCommonApiResponse =
  /** status 200 OK */ GoogleDriveFileList;
export type GetTtGoogleDrivesAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  pageSize?: string;
  pageToken?: string;
  orderBy?: TtGoogleDriveSort;
  folderId?: string;
};
export type GetTtGoogleDrivesFormatAsCommonApiResponse =
  /** status 200 OK */ GoogleDriveFormat;
export type GetTtGoogleDrivesFormatAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  fileId?: string;
};
export type GetTtGoogleDrivesDownloadAsCommonApiResponse =
  /** status 200 OK */ GoogleDriveFormat;
export type GetTtGoogleDrivesDownloadAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  fileId?: string;
};
export type GetTtGoogleDrivesOauth2CallbackAsCommonApiResponse =
  /** status 200 OK */ GoogleDriveOauth2Url;
export type GetTtGoogleDrivesOauth2CallbackAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** Google認証後に返ってくる値 */
  code?: string;
  /** Google認証後に返ってくる値 */
  scope?: string;
  /** Google認証後に返ってくる値 */
  error?: string;
};
export type PostTtGoogleDrivesRevokeAsCommonApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PostTtGoogleDrivesRevokeAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTtGoogleDrivesConnectionAsCommonApiResponse =
  /** status 200 OK */ GoogleDriveConnection;
export type GetTtGoogleDrivesConnectionAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTtMicrosoftDrivesAsCommonApiResponse =
  /** status 200 OK */ OneDriveFileList;
export type GetTtMicrosoftDrivesAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  folderId?: string;
  top?: number;
  nextLink?: string;
  search?: string;
  filter?: string;
  orderby?: string;
};
export type GetTtMicrosoftDrivesFormatAsCommonApiResponse =
  /** status 200 OK */ OneDriveFormat;
export type GetTtMicrosoftDrivesFormatAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  itemId?: string;
};
export type GetTtMicrosoftDrivesOauth2CallbackAsCommonApiResponse =
  /** status 200 OK */ MicrosoftOauth2Url;
export type GetTtMicrosoftDrivesOauth2CallbackAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** Microsoft認証後に返ってくる値 */
  code?: string;
};
export type PostTtMicrosoftDrivesRevokeAsCommonApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PostTtMicrosoftDrivesRevokeAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTtMicrosoftDrivesConnectionAsCommonApiResponse =
  /** status 200 OK */ OneDriveConnection;
export type GetTtMicrosoftDrivesConnectionAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetIcalendarsAsCommonApiResponse =
  /** status 200 OK */ IcalendarBase;
export type GetIcalendarsAsCommonApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  startTime: string;
  endTime: string;
  title: string;
  description?: string;
  location?: string;
  url?: string;
};
export type GetConnectivityCheckStatusApiResponse = /** status 200 OK */ {
  status_list?: {
    id?: number;
    name?: string;
    url?: string;
    status?: boolean;
  }[];
};
export type GetConnectivityCheckStatusApiArg = void;
export type GetGoogleApiCalendarOauth2CallbackApiResponse =
  /** status 200 OK */ Blob;
export type GetGoogleApiCalendarOauth2CallbackApiArg = {
  /** Google認証後に返ってくる値 */
  code?: string;
  /** Google認証後に返ってくる値 */
  scope?: string;
};
export type GetGoogleApiClassroomOauth2CallbackApiResponse =
  /** status 200 OK */ Blob;
export type GetGoogleApiClassroomOauth2CallbackApiArg = {
  /** Google認証後に返ってくる値 */
  code?: string;
  /** Google認証後に返ってくる値 */
  scope?: string;
};
export type PostWebhooksVideosEncodedApiResponse = unknown;
export type PostWebhooksVideosEncodedApiArg = {
  body: {
    id: number;
    encoded_path: string;
    model_name: string;
  };
};
export type GetPblBoardInfosAsPublicApiResponse = /** status 200 OK */ {
  board_infos: PblBoardInfoIndexBase[];
  total_count: number;
};
export type GetPblBoardInfosAsPublicApiArg = {
  /** 探究ボード一覧のページング */
  page?: number;
  /** 探究ボード一覧のページング */
  perPage?: number;
  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  searchType?: PblBoardInfoSearchType;
  /** 検索単語、search_typeとsearch_wordは同時に指定する必要有り */
  searchWord?: string;
  /** 会社の種類で絞り込み */
  companyType: PblBoardInfoCompanyType;
  /** carousel表示で利用 */
  isRandom?: boolean;
};
export type GetPblBoardInfoAsPublicApiResponse =
  /** status 200 OK */ PblBoardInfoBase;
export type GetPblBoardInfoAsPublicApiArg = {
  companyId: number;
  /** 探究成果一覧のページング */
  page?: number;
  /** 探究成果一覧のページング */
  perPage?: number;
};
export type GetPblBoardArticleAsPublicApiResponse =
  /** status 200 OK */ 一般公開用;
export type GetPblBoardArticleAsPublicApiArg = {
  /** Article ID */
  id: string;
  /** Company ID */
  companyId: string;
};
export type GetPblBoardArticlesWordCloudAsPublicApiResponse =
  /** status 200 OK */ WordCloudBase;
export type GetPblBoardArticlesWordCloudAsPublicApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  listLen?: number;
  offset?: number;
  minNumOfChar?: number;
  /** Company ID */
  companyId: string;
};
export type PostAttachedImageAsPublicApiResponse =
  /** status 201 Created */ AttachedImageBase;
export type PostAttachedImageAsPublicApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetAttachedImageAsPublicApiResponse =
  /** status 200 OK */ AttachedImageBase;
export type GetAttachedImageAsPublicApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PutAttachedImageAsPublicApiResponse =
  /** status 202 Accepted */ AttachedImageBase;
export type PutAttachedImageAsPublicApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteAttachedImageAsPublicApiResponse =
  /** status 200 Accepted */ {
    message?: string;
  };
export type DeleteAttachedImageAsPublicApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type ModelBase = {
  id: string;
};
export type UserInputSetting = "required" | "optional" | "hidden";
export type StudentInputSetting = {
  avatar: UserInputSetting;
  postcode: UserInputSetting;
  prefecture: UserInputSetting;
  address1: UserInputSetting;
  address2: UserInputSetting;
  birth_date: UserInputSetting;
  grade: UserInputSetting;
  phone: UserInputSetting;
  parent_first_name: UserInputSetting;
  parent_last_name: UserInputSetting;
  number: UserInputSetting;
};
export type UserTagTypeBasic = ModelBase & {
  name: string;
  kind?: string;
  teacher_visible: boolean;
  role_type?: string;
};
export type UserTag = ModelBase & {
  name: string;
  number_of_linking_users?: number;
  limit_number?: number | null;
  is_editable?: boolean | null;
  user_tag_type?: UserTagTypeBasic;
  user_tag_company?: {
    start_at?: string | null;
  };
};
export type AffiliateInvitation = ModelBase & {
  expires_at: string | null;
  is_expired: boolean;
  role: string;
  token: string;
  grade: string;
  company: string;
  student_input_settings: StudentInputSetting;
  user_tags: UserTag[];
  signup_count: number;
  description: string;
};
export type ErrorsWithKeyMessage = {
  errors: {
    key?: string;
    message?: string;
  }[];
};
export type AppName = "MYLSIL" | "TimeTact";
export type MicrosoftLoginBase = {
  access_token?: string;
  microsoft_oauth2_url?: string;
};
export type Avatar = {
  url: string | null;
};
export type AffiliateStatusJa =
  | "\u5229\u7528\u4E2D"
  | "\u671F\u9650\u5207\u308C"
  | "\u767B\u9332\u5F85\u3061"
  | "\u30ED\u30C3\u30AF\u4E2D"
  | "\u6E96\u5099\u4E2D"
  | "\u672A\u627F\u8A8D"
  | "\u5229\u7528\u505C\u6B62";
export type Option = {
  use_video?: boolean;
  custom_student_ui?: boolean;
  homework_feature?: boolean;
  blog_feature?: boolean;
  text_seller?: boolean;
  use_image?: boolean;
  material?: boolean;
  teacher_material?: boolean;
  report?: boolean;
  payment?: boolean;
  calender?: boolean;
  review_feature?: boolean;
  reviewer_company?: boolean;
  tag?: boolean;
  google_classroom?: boolean;
  steam_library?: boolean;
  content_company?: boolean;
  content_company_review_feature?: boolean;
  dashboard_feature?: boolean;
  study_log_feature?: boolean;
  ideal_feature?: boolean;
  pbl_board_feature?: boolean;
  exam_feature?: boolean;
  rubric_feature?: boolean;
  word_cloud_feature?: boolean;
  rubric_evaluate_feature?: boolean;
  google_drive_feature?: boolean;
  one_drive_feature?: boolean;
  university_cooperation_feature?: boolean;
  article_student_comment_feature?: boolean;
  homework_automatic_scoring_feature?: boolean;
  class_nav_feature?: boolean;
  use_account_lock_mail?: boolean;
  gogo_feature?: boolean;
  pbl_projects_feature?: boolean;
  use_article_and_homework_mail?: boolean;
};
export type UserAuthorization = {
  material_distribution?: boolean;
  nationwide_user_tag?: boolean;
  limited_user_tag?: boolean;
  homework_review?: boolean;
  user_edit?: boolean;
  notification_delivery?: boolean;
  send_inquiry_email?: boolean;
};
export type AuthorizationInfo = UserAuthorization & {
  create_video?: boolean;
  modify_video?: boolean;
  delete_video?: boolean;
};
export type CategoryRenameMap = {
  id?: string | null;
  for_text: string | null;
  for_subject: string | null;
  for_grade: string | null;
  for_major: string | null;
  for_middle: string | null;
  for_minor: string | null;
} | null;
export type ReviewerInfo = {
  is_manager: boolean;
};
export type CurrentUserInfo = {
  id: string;
  affiliate_id?: string;
  email?: string;
  first_name: string;
  last_name: string;
  full_name: string;
  nickname: string | null;
  current_sign_in_at: string | null;
  last_sign_in_at: string | null;
  avatar: Avatar;
  current_role: string;
  current_role_name?: string;
  current_status?: AffiliateStatusJa;
  company_id: string;
  company_name: string;
  company_unique_id: string | null;
  company_is_child_company?: boolean;
  company_is_super_company?: boolean;
  is_university: boolean;
  is_admin_company?: boolean;
  app_name: AppName;
  terms_accepted: boolean;
  company_authorization?: Option | null;
  authorization?: AuthorizationInfo;
  company_logo_url?: string | null;
  ui_color_code: string;
  ui_second_color_code?: string;
  teacher_info?: {
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    has_register_student?: boolean;
  } | null;
  new_notification: boolean;
  category_rename_map: CategoryRenameMap;
  first_time_login: boolean;
  last_accessed_at: string | null;
  reviewer_info?: ReviewerInfo | null;
  admin_info?: {
    only_pbl_projects_feature: boolean;
  } | null;
};
export type AddressBaseCommon = {
  address: {
    address1?: string;
    address2?: string;
    address3?: string;
    kana1?: string;
    kana2?: string;
    kana3?: string;
    prefcode?: string;
    zipcode?: string;
  };
};
export type PostInquiryRequestBodyAsCommon = {
  title: string;
  category?: string;
  body: string;
  os_name?: string;
  os_version?: string;
  browser_name?: string;
  browser_version?: string;
  kind: string;
  add_affiliate_ids?: string[];
};
export type PutInquiryRequestBodyAsCommon = {
  person_in_charge_id?: string;
  status?: string;
  add_affiliate_ids?: string[];
  remove_affiliate_ids?: string[];
};
export type BasicUserInfo = ModelBase & {
  first_name: string;
  last_name: string;
  email: string;
  optional_email?: string | null;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  expired_date?: string | null;
  current_sign_in_at?: string | null;
  last_sign_in_at?: string | null;
  linked_project_count?: number | null;
  affiliate_id?: string | null;
  nickname: string | null;
};
export type AffiliateBaseAsCommon = {
  affiliates?: {
    id?: string;
    company_id?: string;
    company_name?: string;
    is_enterprise?: boolean;
    status?:
      | "\u5229\u7528\u4E2D"
      | "\u671F\u9650\u5207\u308C"
      | "\u767B\u9332\u5F85\u3061"
      | "\u30ED\u30C3\u30AF\u4E2D"
      | "\u6E96\u5099\u4E2D"
      | "\u672A\u627F\u8A8D"
      | "\u5229\u7528\u505C\u6B62";
    role_id?: string;
    role?: string;
  }[];
  user?: BasicUserInfo;
};
export type PlanContentsCategorizableType =
  | "Article"
  | "HomeworkTemplateDistribution"
  | "Homework"
  | "Material"
  | "Pbl::Report::Template"
  | "Pbl::Sponsor::Lecture"
  | "Pbl::Steam::Content"
  | "Pbl::Steam::Lecture"
  | "CustomContent";
export type PblPlanContentKind = "learn" | "make";
export type TeacherPblPlanContent = ModelBase & {
  pbl_plan_class_id: string;
  categorizable_type: PlanContentsCategorizableType;
  categorizable_id: string;
  categorizable_ja_model_name: string;
  categorizable_title: string;
  categorizable_related_ids: string[];
  kind: PblPlanContentKind;
  sl_lecture_id?: number | null;
  is_project_list?: boolean;
};
export type TeacherPblPlanClass = ModelBase & {
  pbl_plan_item_id: string;
  year?: number;
  number?: number;
  date?: string;
  description?: string;
  is_skip?: boolean;
  article_creation_time?: boolean;
  created_at: string;
  updated_at: string;
  plan_contents?: TeacherPblPlanContent[];
  schedule_id?: string | null;
  class_minutes?: number | null;
  title?: string | null;
};
export type ScheduleBase = ModelBase & {
  start_time: string;
  end_time: string;
  all_day: boolean;
  title: string;
  place?: string;
  private_flag: boolean;
  memo?: string;
  host?: {
    id?: string | null;
    name?: string | null;
    nickname?: string | null;
  };
  attendees: {
    id?: string;
    user_id?: string;
    name?: string;
    nickname?: string | null;
    role?: string;
  }[];
  plan_class?: TeacherPblPlanClass;
};
export type PutScheduleRequestBodyAsCommon = {
  title?: string;
  start_time?: string;
  end_time?: string;
  place?: string;
  private_flag?: boolean;
  all_day?: boolean;
  memo?: string;
  attendee_ids?: string[];
};
export type PostScheduleRequestBodyAsCommon = PutScheduleRequestBodyAsCommon &
  object;
export type ScheduleBatchBase = ModelBase;
export type BatchCreateScheduleRequestBodyAsCommon = {
  host_affiliate_id: string;
  attendee_affiliate_id: string;
  schedule_params_list: {
    start_time: string;
    end_time: string;
    title: string;
    place?: string;
    private_flag: boolean;
    all_day: boolean;
    memo?: string;
    plan_class_id?: string;
  }[];
};
export type BatchUpdateScheduleRequestBodyAsCommon = {
  host_affiliate_id: string;
  attendee_affiliate_id: string;
  delete_attendee_flag: boolean;
  schedule_params_list: {
    id: string;
    start_time?: string;
    end_time?: string;
    title?: string;
    place?: string;
    private_flag?: boolean;
    all_day?: boolean;
    memo?: string;
    plan_class_id?: string;
  }[];
};
export type CategoryType =
  | "text"
  | "grade"
  | "subject"
  | "major"
  | "middle"
  | "minor";
export type BaseCategoryInfo = {
  id: string;
  name: string;
  depth: number;
  type: CategoryType;
  student_visible?: boolean;
  teacher_visible?: boolean;
  owner_visible?: boolean;
  is_help?: boolean;
  student_help_page?: boolean;
  teacher_help_page?: boolean;
  owner_help_page?: boolean;
  reviewer_help_page?: boolean;
  image?: string | null;
};
export type HasIdAndName = {
  id: string;
  name: string;
};
export type UserTagBasic = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_linking_users?: number;
  kind?: string;
};
export type TextCategoryInfo = BaseCategoryInfo & {
  linking_company_tags?: HasIdAndName[];
  linking_user_tags?: UserTagBasic[];
};
export type GradeCategoryInfo = BaseCategoryInfo & {
  text_id: string;
};
export type SubjectCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  text_id: string;
};
export type MajorCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type MiddleCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  major_category_id: string;
  text_id: string;
};
export type MinorCategoryInfo = BaseCategoryInfo & {
  major_category_id: string;
  middle_category_id: string;
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type CategoryPart = {
  id: string;
  name: string;
  depth: number;
  type: string;
  text_id?: string;
  grade_id?: string;
  subject_id?: string;
  major_category_id?: string;
  middle_category_id?: string;
};
export type CategoryBase = {
  category: CategoryPart;
};
export type MaterialList = ModelBase &
  CategoryBase & {
    title: string;
    thumbnail_file?: string | null;
    is_public: boolean;
    is_video_updating: boolean;
    tags: HasIdAndName[];
  };
export type ImageInfo = {
  id: string;
  title: string;
  material_id: string;
  file: {
    url: string;
    thumb?: {
      url: string | null;
    };
    icon?: {
      url: string | null;
    };
  };
  edited_image_url?: string | null;
};
export type CookieInfo = {
  cloudfront_policy: string;
  cloudfront_signature: string;
  cloudfront_key_pair_id: string;
  unsigned_url: string;
};
export type VideoInfo = {
  id: string;
  title: string;
  material_id: string;
  file_url: string | null;
  file: {
    url?: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string;
  thumbnail: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
};
export type MaterialDetail = MaterialList & {
  description: string;
  memo?: string | null;
  download_allowed: boolean;
  is_owned: boolean;
  images: ImageInfo[];
  videos: VideoInfo[];
  html_file: {
    title?: string | null;
    url?: string | null;
  };
};
export type UserInviteUsersRequestBodyAsCommon = {
  email: string;
  company_id?: string;
};
export type SponsorPageLayer = "corporate" | "project" | "lecture";
export type SponsorPageViewsRequestBody = {
  object_id?: string;
  layer?: SponsorPageLayer;
};
export type AttachedImageCategorizableType =
  | "Material"
  | "Ideal::Board"
  | "Article"
  | "Pbl::Board::Content"
  | "Pbl::Sponsor::Lecture"
  | "Ideal::BoardTemplate"
  | "Interview::Message"
  | "HomeworkTemplateCustomFieldFeedback"
  | "Gogo::Message";
export type AttachedImageBase = ModelBase & {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
  file_name: string;
  file_url: string;
};
export type FileRequiredRequestBody = {
  file: Blob;
};
export type LimitedTimeNotificationStatus =
  | "\u516C\u958B\u524D"
  | "\u516C\u958B\u4E2D"
  | "\u63B2\u8F09\u7D42\u4E86";
export type Role =
  | "admin"
  | "super_owner"
  | "owner"
  | "teacher"
  | "student"
  | "reviewer"
  | "content_provider";
export type RoleJa =
  | "SV\u7BA1\u7406\u8005"
  | "\u89AA\u7BA1\u7406\u8005"
  | "\u7BA1\u7406\u8005"
  | "\u6559\u8077\u54E1"
  | "\u751F\u5F92"
  | "\u6DFB\u524A\u5229\u7528\u8005"
  | "\u30B3\u30F3\u30C6\u30F3\u30C4\u4E8B\u696D\u8005";
export type RoleBase = ModelBase & {
  name: Role;
  jp_name: RoleJa;
};
export type NotificationCounts = {
  new_notifications:
    | {
        title?: string;
        message?: string;
        status?: LimitedTimeNotificationStatus;
        start_time?: string;
        end_time?: string;
        created_at?: string;
        updated_at?: string;
        roles?: RoleBase[];
        companies?: HasIdAndName[];
        user_tags?: HasIdAndName[];
        resource_info?: string | null;
        sender?: {
          id?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          display_name?: string | null;
        };
        read?: boolean;
        notified_time?: string;
        type?: "Notification" | "LimitedTimeNotification";
      }[]
    | null;
  new_notification_count: number;
  new_limited_time_notification_count: number;
};
export type GoogleDriveFile = ModelBase & {
  kind: string;
  name: string;
  parents?: string[];
  icon_link?: string;
  thumbnail_link?: string;
  web_content_link?: string;
  mime_type?: string;
  size?: string;
};
export type GoogleDriveFileList = {
  kind: string;
  next_page_token?: string;
  incomplete_search: boolean;
  files: GoogleDriveFile[];
};
export type TtGoogleDriveSort =
  | "createdTime"
  | "folder"
  | "modifiedByMeTime"
  | "modifiedTime"
  | "name"
  | "recency";
export type GoogleDriveFormat = {
  content: string;
};
export type GoogleDriveOauth2Url = {
  google_oauth2_url: string;
};
export type GoogleDriveConnection = {
  has_token: boolean;
};
export type OneDriveFolderDetail = {
  childCount: number;
  view: {
    viewType: string;
    sortBy: string;
    sortOrder: string;
  };
};
export type OneDriveFileDetail = {
  mimeType: string;
  hashes: {
    quickXorHash: string;
    sha1Hash: string;
    sha256Hash: string;
  };
};
export type ParentReference = ModelBase & {
  driveId: string;
  driveType: string;
  path: string;
};
export type OneDriveFile = ModelBase & {
  "@microsoft.graph.downloadUrl"?: string;
  name: string;
  size: number;
  lastModifiedDateTime: string;
  webUrl: string;
  folder?: OneDriveFolderDetail;
  file?: OneDriveFileDetail;
  ParentReference?: ParentReference;
};
export type OneDriveFileList = {
  "@odata.context": string;
  "@odata.count": number;
  "@odata.nextLink"?: string;
  value: OneDriveFile[];
};
export type OneDriveFormat = {
  content: string;
};
export type MicrosoftOauth2Url = {
  microsoft_oauth2_url: string;
};
export type OneDriveConnection = {
  has_token: boolean;
};
export type IcalendarBase = {
  icalendar_data: string;
};
export type PblBoardInfoIndexBase = ModelBase & {
  first_content: {
    catchphrase_image?: {
      url?: string | null;
      thumb?: {
        url?: string | null;
      };
      icon?: {
        url?: string | null;
      };
    } | null;
  };
  company: {
    id: string;
    name: string;
    is_content_company: boolean;
  };
};
export type PblBoardInfoSearchType = "company_name";
export type PblBoardInfoCompanyType = "any" | "school" | "content";
export type PblBoardInfoStatusJa =
  | "\u516C\u958B\u505C\u6B62\u4E2D"
  | "\u516C\u958B\u4E2D";
export type PblBoardItemContentTypeJa =
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8"
  | "\u63A2\u7A76\u6210\u679C\u4E00\u89A7"
  | "\u30D5\u30C3\u30BF\u30FC"
  | "\u30EF\u30FC\u30C9\u30AF\u30E9\u30A6\u30C9";
export type PblBoardContentDesignTypeJa =
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C81"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C82"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C83"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C84";
export type PblBoardContentBase = ModelBase & {
  design_type: PblBoardContentDesignTypeJa;
  catchphrase?: string;
  catchphrase_description?: string;
  catchphrase_image?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
  cta_name?: string;
  cta_url?: string;
  external_link?: string;
};
export type PblBoardFooterItemBase = ModelBase & {
  title?: string;
  url?: string;
};
export type PblBoardFooterBase = ModelBase & {
  category_name?: string;
  sequence?: number;
  board_footers?: PblBoardFooterItemBase[];
};
export type PblBoardItemBase = ModelBase & {
  content_type: PblBoardItemContentTypeJa;
  sequence: number;
  board_contents?: PblBoardContentBase[];
  board_footers?: PblBoardFooterBase[];
};
export type ArticleKind = "default" | "poster";
export type ArticleImageBase = ModelBase & {
  file?: {
    title?: string;
    thumb_url?: string;
    file_url?: string;
  };
};
export type PblBoardArticleBase = ModelBase & {
  title: string;
  kind?: ArticleKind;
  body: string;
  cover: string | null;
  company: HasIdAndName;
  images: ArticleImageBase[];
};
export type PblBoardInfoBase = ModelBase & {
  status: PblBoardInfoStatusJa;
  created_at: string;
  updated_at: string;
  board_items: PblBoardItemBase[];
  articles: PblBoardArticleBase[];
  article_total_count: number;
  company?: {
    id: string;
    name: string;
    is_content_company: boolean;
  };
};
export type ArticleStatus =
  | "draft"
  | "correction"
  | "reviewing"
  | "published"
  | "teacher_checking";
export type BoardCompany = {
  id: string;
  name: string;
  is_content_company: boolean;
};
export type AllowedArticleCategorizableType =
  | "Sponsor::Info"
  | "Pbl::Sponsor::Project";
export type ArticleCategorizable = ModelBase & {
  type: AllowedArticleCategorizableType;
  name: string;
  sponsor_logo: string | null;
  company_name?: string | null;
};
export type ArticleContentType =
  | "theme"
  | "background"
  | "hypothesis"
  | "survey_method"
  | "result"
  | "summary"
  | "future_issues";
export type ArticleContent = ModelBase & {
  content_type: ArticleContentType;
  body: string | null;
};
export type ArticleSponsorContent = ModelBase & {
  pbl_sponsor_article_content_template_field_id: string;
  body: string | null;
};
export type SponsorArticleContentTemplateFieldBase = ModelBase & {
  title: string;
};
export type SponsorArticleContentTemplateBase = ModelBase & {
  title: string;
  description?: string | null;
  pbl_sponsor_lecture_id?: string;
  fields?: SponsorArticleContentTemplateFieldBase[];
};
export type ArticlePoster = {
  sponsor_logo: string | null;
  categorizable?: ArticleCategorizable;
  article_contents: ArticleContent[];
  article_sponsor_contents?: ArticleSponsorContent[];
  pbl_sponsor_article_content_template?: SponsorArticleContentTemplateBase | null;
};
export type TeacherSponsorProjectListBase = ModelBase & {
  sponsor_info_id: string;
  name: string;
  company_name: string;
};
export type 一般公開用 = ModelBase & {
  student_id: string | null;
  student_name: string | null;
  title: string;
  kind?: ArticleKind;
  status: string;
  status_code: ArticleStatus;
  created_at: string;
  updated_at: string;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  article_company: HasIdAndName;
  board_company: BoardCompany;
  images?: ArticleImageBase[];
  body: string | null;
  poster?: ArticlePoster;
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type WordCloudWord = {
  text: string;
  value: number;
};
export type WordCloudBase = {
  word_cloud_list: WordCloudWord[];
  total_count: number;
};
export const {
  useGetAffiliateInvitationsWithTokenByTokenQuery,
  useGetAuthorizationsQuery,
  usePostAuthSignInMutation,
  useDeleteAuthSignOutMutation,
  usePostAuthSignupWithInvitationMutation,
  usePostAuthPasswordMutation,
  usePutAuthPasswordMutation,
  useGetAuthInvitationTokenStatusQuery,
  usePutResendInvitationAsAuthMutation,
  useGetAuthConfirmationQuery,
  usePutAuthInvitationMutation,
  usePostAuthRequestMutation,
  useGetAuthUnlockQuery,
  useGetAuthMicrosoftOauth2CallbackQuery,
  useGetRolesQuery,
  useGetCommonCurrentUserMeQuery,
  useGetAddressAsCommonQuery,
  usePostCommonInquiriesMutation,
  usePutCommonInquiriesByIdMutation,
  useGetCommonAffiliatesQuery,
  usePostCommonAffiliatesSwitchMutation,
  useGetCommonSchedulesQuery,
  usePostCommonSchedulesMutation,
  useGetCommonSchedulesByIdQuery,
  usePutCommonSchedulesByIdMutation,
  useDeleteCommonSchedulesByIdMutation,
  useGetCommonSchedulesSearchQuery,
  usePatchSchedulesBatchCreateMutation,
  usePatchSchedulesBatchUpdateMutation,
  useGetCommonGradeMastersQuery,
  useGetCommonCategoriesQuery,
  useGetCommonMaterialsQuery,
  useGetCommonMaterialsByIdQuery,
  usePostCommonInviteUserMailersMutation,
  usePutCommonTermsAcceptsByIdMutation,
  usePostCommonKpisSponsorPageViewsMutation,
  usePutControllerAsRoleMutation,
  usePostAttachedImageAsCommonMutation,
  useGetAttachedImageAsCommonQuery,
  usePutAttachedImageAsCommonMutation,
  useDeleteAttachedImageAsCommonMutation,
  useGetNewNotificationCountAsCommonQuery,
  usePatchNewNotificationCountAsCommonMutation,
  useGetTtGoogleDrivesAsCommonQuery,
  useGetTtGoogleDrivesFormatAsCommonQuery,
  useGetTtGoogleDrivesDownloadAsCommonQuery,
  useGetTtGoogleDrivesOauth2CallbackAsCommonQuery,
  usePostTtGoogleDrivesRevokeAsCommonMutation,
  useGetTtGoogleDrivesConnectionAsCommonQuery,
  useGetTtMicrosoftDrivesAsCommonQuery,
  useGetTtMicrosoftDrivesFormatAsCommonQuery,
  useGetTtMicrosoftDrivesOauth2CallbackAsCommonQuery,
  usePostTtMicrosoftDrivesRevokeAsCommonMutation,
  useGetTtMicrosoftDrivesConnectionAsCommonQuery,
  useGetIcalendarsAsCommonQuery,
  useGetConnectivityCheckStatusQuery,
  useGetGoogleApiCalendarOauth2CallbackQuery,
  useGetGoogleApiClassroomOauth2CallbackQuery,
  usePostWebhooksVideosEncodedMutation,
  useGetPblBoardInfosAsPublicQuery,
  useGetPblBoardInfoAsPublicQuery,
  useGetPblBoardArticleAsPublicQuery,
  useGetPblBoardArticlesWordCloudAsPublicQuery,
  usePostAttachedImageAsPublicMutation,
  useGetAttachedImageAsPublicQuery,
  usePutAttachedImageAsPublicMutation,
  useDeleteAttachedImageAsPublicMutation,
} = injectedRtkApi;
