/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { enqueueToast } from "@actions/toast";
import {
  Company,
  CompanyTagType,
  PackagingAsLearningTemplatePackageBase,
  UserTag,
} from "@lib/Api";
import { nanoid } from "@reduxjs/toolkit";
import { useGetPackagingsAsSuperOwnerQuery } from "@root/endpoints/TimeTactApi/superOwnerApi";
import { useAppDispatch } from "@root/hooks";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export type StepType = 1 | 2 | 3 | 4;

export enum SchoolDistributionEnum {
  BY_SCHOOL = "School",
  BY_SCHOOL_TAG = "SchoolTags",
}

export type TemplatesSettings = {
  homework_template_id: string;
  company_id: string;
  templateTitle: string;
  start_at: string;
  end_at: string;
  reviewer_end_at: string;
  memo: string;
};
export type AttachedTemplatesWith = {
  schoolTitle: string;
  id: string;
  templates: TemplatesSettings[];
};

export interface FormType {
  startDate: string;
  endDate: string;
  schoolDistributionType: string;
  grade: string;
  schools: Company[];
  schoolTags: CompanyTagType[];
  tagIds: UserTag[];
  studentIds: string[];
  schoolsWithAttachedTemplates: AttachedTemplatesWith[];
}

const defaultValues = {
  startDate: "",
  endDate: "",
  grade: "",
  schoolDistributionType: SchoolDistributionEnum.BY_SCHOOL,
  schools: [],
  schoolTags: [],
  tagIds: [],
  studentIds: [],
  schoolsWithAttachedTemplates: [],
};

const usePackageDistributionModal = (distributed: boolean) => {
  const methods = useForm<FormType>({ defaultValues });
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [step, setStep] = useState<StepType>(1);

  const { reset: reset1 } = methods;

  // fetch package to check if template can be send
  const useFetchPackages = (templateId: string, isModalOpen: boolean) => {
    const packagingsQuery = useGetPackagingsAsSuperOwnerQuery(
      {
        packageId: templateId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !isModalOpen,
      },
    );
    const { data, isLoading: isPackagingsFetching } = packagingsQuery;
    return {
      packagingsList: data?.packagings ?? [],
      isPackagingsFetching,
    };
  };

  const generateTemplateInputs = (
    template: PackagingAsLearningTemplatePackageBase,
    schools: Company,
  ) => ({
    homework_template_id: template.packageable_id,
    company_id: schools.id,
    templateTitle: template.packageable_title,
    start_at: "",
    end_at: "",
    reviewer_end_at: "",
    memo: "",
  });

  const handleSchoolWithAttachedTemplates = (
    schools: Company[],
    attachedHomeWorkTemplate: PackagingAsLearningTemplatePackageBase[],
  ) => {
    return schools.map((school) => ({
      id: school.id,
      schoolTitle: school.name,
      templates: attachedHomeWorkTemplate.map((template) =>
        generateTemplateInputs(template, school),
      ),
    }));
  };

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        enqueueToast({
          message: errorMessage,
          id: nanoid(),
          options: {
            variant: "warning",
          },
        }),
      );
    }
  }, [errorMessage]);

  useEffect(() => {
    if (distributed) {
      reset1(defaultValues);
      setStep(1);
    }
  }, [distributed]);

  return {
    useFetchPackages,
    defaultValues,
    handleSchoolWithAttachedTemplates,
    methods,
    step,
    setStep,
    errorMessage,
    setErrorMessage,
  };
};

export default usePackageDistributionModal;
