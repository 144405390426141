import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import errorWithMessage from "@lib/rtk/error-utils";
import { formatedDateTime } from "@lib/time-utils";
import { usePutInterviewRequestAsStudentMutation } from "@root/endpoints/TimeTactApi/studentApi";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InterviewMessageInput from "./FormInputs/InterviewMessageInput";
import InterviewTimeInputs from "./FormInputs/InterviewTimeInputs";
import IsOnlineBox from "./FormInputs/IsOnlineBox";

type Props = {
  handleModalClose: () => void;
  refetchMessageList: () => void;
  refetchInterviewRequestDetail: () => void;
  isScheduleProposalModalOpen: boolean;
  requestId: string;
};

export type FormValuesTypes = {
  isOnline: string;
  interviewTime1: string;
  interviewTime2: string;
  interviewTime3: string;
  interviewTime4: string;
  interviewMessage: string;
};

const SendScheduleProposalModal: React.FC<Props> = ({
  isScheduleProposalModalOpen,
  requestId,
  handleModalClose,
  refetchMessageList,
  refetchInterviewRequestDetail,
}) => {
  const { t } = useTranslation("university");
  const { actions } = useEnqueueToast();
  const defaultValues = {
    isOnline: "",
    interviewTime: "",
    interviewPlace: "",
    interviewMessage: "",
  };

  const { control, handleSubmit, reset } = useForm<FormValuesTypes>({
    defaultValues,
  });

  const [putInterviewRequestAsStudent, { isLoading: editingInterviewRequest }] =
    usePutInterviewRequestAsStudentMutation();

  const onSubmit = (formValues: FormValuesTypes) => {
    const {
      isOnline,
      interviewTime1,
      interviewTime2,
      interviewTime3,
      interviewTime4,
      interviewMessage,
    } = formValues;
    const isOnlineValue = isOnline === "true";

    putInterviewRequestAsStudent({
      id: requestId,
      body: {
        student_message: interviewMessage,
        is_online: isOnlineValue,
        preferred_dates: [
          formatedDateTime(interviewTime1),
          formatedDateTime(interviewTime2),
          formatedDateTime(interviewTime3),
          formatedDateTime(interviewTime4),
        ],
      },
    })
      .unwrap()
      .then(() => {
        refetchInterviewRequestDetail();
        refetchMessageList();
        reset(defaultValues);
        handleModalClose();
        actions.showSuccess(t("scheduleProposalSuccess"));
      })
      .catch((error: unknown) => actions.showError(errorWithMessage(error)));
  };
  return (
    <GenericModal
      title={t("scheduleProposal")}
      modalSize="md"
      open={isScheduleProposalModalOpen}
      handleClose={handleModalClose}
      secondaryButtonText={t("cancel")}
      primaryButtonText={t("send")}
      primaryButtonAction={handleSubmit(onSubmit)}
      secondaryButtonAction={handleModalClose}
      useActionButtons
      useSecondaryButton
      posting={editingInterviewRequest}
    >
      <IsOnlineBox control={control} />
      <InterviewTimeInputs control={control} />
      <InterviewMessageInput control={control} />
    </GenericModal>
  );
};

export default SendScheduleProposalModal;
