/* eslint-disable camelcase */
import { updateHomeworkChunkAsReviewer } from "@actions/reviewerApp/homeworkChunk";
import { baseUrl, setRTKQueryHeaders } from "@lib/http";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { SaveImageArg } from "../type";

export const reviewerAppHomeworkChunkApi = createApi({
  reducerPath: "reviewerApp/homeworkChunkApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/reviewer/`,
    prepareHeaders: (headers) => setRTKQueryHeaders(headers),
  }),
  endpoints: (build) => ({
    saveChunkFile: build.mutation<any, SaveImageArg>({
      query: (arg: SaveImageArg) => {
        const { id, params } = arg;
        const fd = new FormData();
        fd.append("reviewer_file", params.file);
        return {
          url: `homework_chunks/${id}`,
          method: "PUT",
          body: fd,
        };
      },
      transformResponse: (response: any) => response,
      async onQueryStarted(arg: any, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateHomeworkChunkAsReviewer(data));
      },
    }),
  }),
});

export const { useSaveChunkFileMutation } = reviewerAppHomeworkChunkApi;
