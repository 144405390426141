/* eslint-disable camelcase */

import { Api, FetchArticleFeedbacksAsOwnerParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticleFeedbacksAsOwnerArg {
  params: FetchArticleFeedbacksAsOwnerParams;
}

export const fetchArticleFeedbacksAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/articleFeedback/fetch",
  async (arg: FetchArticleFeedbacksAsOwnerArg, { rejectWithValue }) => {
    try {
      const response = await api.owner.fetchArticleFeedbacksAsOwner(arg.params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetFeedbackRelatedFormState = createAction(
  "owner/article/feedback/resetFormState",
);
