import { ownerActions } from "@actions/ownerApp/userTag";
import {
  deleteSuperOwnerCompanyTag,
  postSuperOwnerCompanyTag,
  resetState as resetCompanyTagState,
  updateSuperOwnerCompanyTag,
} from "@actions/superOwnerApp/companyTag";
import {
  deleteSuperOwnerUserTag,
  postSuperOwnerUserTag,
  resetState as resetUserTagState,
  updateSuperOwnerUserTag,
} from "@actions/superOwnerApp/userTag";
import { teacherActions } from "@actions/teacherApp/userTag";
import { OwnerTeacherTagFunctions, TagFunction } from "@root/types/tags";

export const TAGS = {
  COMPANY: "schoolTags",
  USER: "userTags",
  LIMITED: "limitedTags",
};
export const TAGS_HASH = {
  SCHOOL: "school_tags",
  USER: "user_tags",
  LIMITED: "limited_user_tags",
} as const;

export type TagHashType = (typeof TAGS_HASH)[keyof typeof TAGS_HASH];

export const TAGS_MAX_SIZE = 10;

export const tagFunctions: TagFunction = {
  school_tags: {
    post: postSuperOwnerCompanyTag,
    update: updateSuperOwnerCompanyTag,
    delete: deleteSuperOwnerCompanyTag,
    reset: resetCompanyTagState,
  },
  user_tags: {
    post: postSuperOwnerUserTag,
    update: updateSuperOwnerUserTag,
    delete: deleteSuperOwnerUserTag,
    reset: resetUserTagState,
  },
  limited_user_tags: {
    post: postSuperOwnerUserTag,
    update: updateSuperOwnerUserTag,
    delete: deleteSuperOwnerUserTag,
    reset: resetUserTagState,
  },
};

export const teacherOwnerTagFunctions: OwnerTeacherTagFunctions = {
  ...ownerActions,
  ...teacherActions,
};
