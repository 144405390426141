import { HomeworkDetails } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import { fetchOneHomeworkAsSuperOwner } from "../../actions/superOwnerApp/homework";
import { RootState } from "../../store";

export interface SuperOwnerAppHomeworkState {
  fetching: boolean;
  error: string | null;
  homework: HomeworkDetails | null;
}

export const initialState: SuperOwnerAppHomeworkState = {
  fetching: false,
  error: null,
  homework: null,
};

const superOwnerAppHomeworkSlice = createSlice({
  name: "SuperOwnerApp/Homework",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneHomeworkAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchOneHomeworkAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: action.error.message as string,
          homework: null,
        };
      })
      .addCase(fetchOneHomeworkAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          error: null,
          homework: payload,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const superOwnerAppHomeworkState = (
  state: RootState,
): SuperOwnerAppHomeworkState => state.superOwnerApp.homework;

export default superOwnerAppHomeworkSlice.reducer;
