/* eslint-disable camelcase */
import { Api, FetchPblCardBookmarkStatsAsTeacherParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchPblCardBookmarkAsTeacherArg {
  id: string;
  inModal: boolean;
}
export const fetchPblCardBookmarkAsTeacher = createAsyncThunk<
  any,
  FetchPblCardBookmarkAsTeacherArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/pbl/cards/bookmarks/fetchOne", async (arg, { rejectWithValue }) => {
  try {
    const response = await api.teacher.fetchPblCardBookmarkAsTeacher(arg.id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchPblCardBookmarkStatsAsTeacher = createAsyncThunk<
  any,
  FetchPblCardBookmarkStatsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/cards/information_literacy/bookmarks/stats",
  async (
    params: FetchPblCardBookmarkStatsAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.fetchPblCardBookmarkStatsAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblCardBookmarkImageAsTeacher = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/cards/bookmarks/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.deletePblCardBookmarkImageAsTeacher(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetOne = createAction("teacher/pbl/cards/bookmarks/resetOne");
