import EditIcon from "@assets/images/svg/editIcon.svg?react";
import DeleteIcon from "@assets/images/svg/trash-icon-green.svg?react";
import V2IconButton from "@components/UIv2/atoms/buttons/V2IconButton";
import { Box,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    mainContainer: {
      padding: theme.spacing(1, 2),
      gap: theme.spacing(2),
      background: theme.palette.common.white,
      borderRadius: "4px",
      position: "absolute",
      bottom: "-40px",
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      boxShadow: `-1px -1px 4px ${theme.palette.customColor.boardGrey},-10px -10px 9px ${theme.palette.common.white}`,
      border: `1px solid ${theme.palette.customColor.boardGrey}`,
      zIndex: 100,
    },
    buttonStyle: {
      padding: 0,
    },
  }),
);

type Props = {
  handleEdit: () => void;
  handleDelete: () => void;
  isNotAttachedImagesMsg: boolean;
};

const ActionButtons: React.FC<Props> = ({
  handleEdit,
  handleDelete,
  isNotAttachedImagesMsg,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <Box className={classes.mainContainer}>
      {isNotAttachedImagesMsg && (
        <V2IconButton
          className={classes.buttonStyle}
          size="small"
          onClick={handleEdit}
          title={t("edit")}
          data-cy="edit-interview-message-button"
        >
          <EditIcon />
        </V2IconButton>
      )}
      <V2IconButton
        className={classes.buttonStyle}
        size="small"
        onClick={handleDelete}
        title={t("delete")}
        data-cy="delete-interview-message-button"
      >
        <DeleteIcon />
      </V2IconButton>
    </Box>
  );
};

export default ActionButtons;
