import { resetCategoryData, toggleJoyride } from "@actions/common";
import { InquiryKind } from "@actions/types/inquiry";
import HelpIcon from "@assets/images/svg/help-icon.svg?react";
import { inquiryMenuButtons } from "@components/Common/Inquiry/constants/menuButtons";
import { useAuth } from "@contexts/Auth";
import ForumIcon from "@mui/icons-material/Forum";
import Help from "@mui/icons-material/Help";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { roles } from "@root/constants/roles";
import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import SupportInquiryModal from "../../organisms/Navigation/Common/SupportInquiry/SupportInquiry";
import { StyledTooltip } from "../StyledComponents/Tooltip";

const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {
      padding: 4,
      margin: theme.spacing(0, 1),
      color: theme.palette.grey[400],
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0.5),
      },
    },
    titleText: {
      padding: theme.spacing(0, 2),
      fontWeight: 700,
    },
    divider: {
      margin: theme.spacing(2, 1),
    },
    smallIcon: {
      width: "28px",
      height: "28px",
    },
    iconFullSize: {
      width: "38px",
      height: "38px",
    },
  }),
);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.tertiary.mainDark}`,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.quaternary.lightGreen,
      transition: "0s",
      borderRadius: 8,
    },
  },
}))(MenuItem);

interface SupportButtonProps {
  inquiryCount?: number;
}

const SupportButton: React.FC<SupportButtonProps> = ({ inquiryCount }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const muiTheme = useTheme();
  const { t } = useTranslation("common");
  const { currentUser } = useAuth();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const [openSupportMenu, setSupportMenu] = useState<null | HTMLElement>(null);
  const [supportModalStatus, setSupportModalStatus] = useState<{
    type: InquiryKind | null;
    open: boolean;
  }>({ type: null, open: false });

  const hideInquiryHistory =
    currentUser?.current_role === roles.ADMIN ||
    currentUser?.current_role === roles.SUPER_OWNER ||
    !currentUser;

  const handleSupport = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.id === "user-support") {
      setSupportMenu(e.currentTarget);
    }
  };

  const handleSupportClose = () => {
    setSupportModalStatus({ type: null, open: false });
    setSupportMenu(null);
  };

  const handleMenuClose = () => {
    setSupportMenu(null);
  };

  const resetCategory = () => {
    dispatch(resetCategoryData());
  };
  const mobileView = () => {
    if (isMobileView) {
      return classes.smallIcon;
    }
    return classes.iconFullSize;
  };
  return (
    <>
      <SupportInquiryModal
        open={supportModalStatus.open}
        type={supportModalStatus.type}
        handleClose={handleSupportClose}
      />
      <StyledTooltip title={t("help")}>
        <IconButton
          id="user-support"
          data-cy="user-support"
          aria-controls="user-support"
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={handleSupport}
          size="large"
        >
          <Badge
            color="error"
            overlap="circular"
            badgeContent={inquiryCount}
            variant={isMobileView ? "dot" : "standard"}
          >
            <HelpIcon className={mobileView()} />
          </Badge>
        </IconButton>
      </StyledTooltip>
      <Menu
        id="user-support"
        keepMounted
        anchorEl={openSupportMenu}
        open={Boolean(openSupportMenu)}
        onClose={handleMenuClose}
      >
        <StyledMenuItem
          onClick={() => {
            handleMenuClose();
            resetCategory();
            window.open("/help", "_blank");
          }}
          data-cy="user-support-button-helpPage"
        >
          <ListItemIcon>
            <Help />
          </ListItemIcon>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="inherit">{t("helpPage")}</Typography>
            <NavigateNextIcon />
          </Box>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            dispatch(toggleJoyride());
            handleMenuClose();
          }}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="inherit">{t("tutorial")}</Typography>
          </Box>
        </StyledMenuItem>
        {!hideInquiryHistory && (
          <div>
            <StyledMenuItem
              onClick={() => history.push("/inquiries")}
              style={{ overflow: "visible" }}
              data-cy="user-support-button-inquiries"
            >
              <ListItemIcon>
                <Badge color="error" badgeContent={inquiryCount}>
                  <ForumIcon />
                </Badge>
              </ListItemIcon>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography variant="inherit">{t("inquiryHistory")}</Typography>
                <NavigateNextIcon />
              </Box>
            </StyledMenuItem>
            <Divider
              sx={{ opacity: "0.6" }}
              variant="middle"
              className={classes.divider}
            />
          </div>
        )}
        <Typography className={classes.titleText}>{t("newInquiry")}</Typography>
        {inquiryMenuButtons.map((item) => (
          <StyledMenuItem
            key={item.id}
            onClick={() => setSupportModalStatus({ type: item.id, open: true })}
            data-cy={item.dataCy}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <Box display="flex" flexDirection="column">
              <Typography variant="inherit">{t(item.id)}</Typography>
              <Typography variant="caption">{t(item.caption)}</Typography>
            </Box>
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
};

SupportButton.defaultProps = {
  inquiryCount: 0,
};

export default withTranslation("common")(SupportButton);
