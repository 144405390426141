export function reorder<ListType>(
  list: ListType[],
  startIndex: number,
  endIndex: number,
): ListType[] {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
