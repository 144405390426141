import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import { ITEMS_PER_PAGE } from "@constants/page";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { GradeCategoryState } from "@reducers/category/grade";
import { MajorCategoryState } from "@reducers/category/major";
import { MiddleCategoryState } from "@reducers/category/middle";
import { MinorCategoryState } from "@reducers/category/minor";
import { SubjectCategoryState } from "@reducers/category/subject";
import { TextCategoryState } from "@reducers/category/text";
import { BaseCategoryInfo, HasIdAndTitle } from "@root/lib/Api";
import clsx from "clsx";
import React, { useState } from "react";

import { coPackageType } from "..";
import HandleChecked from "../HomeWorkTemplateList/HandleChecked";
import HandleDisable from "../HomeWorkTemplateList/HandleDisable";
import GradeLayer from "./CategoryAccordion/GradeLayer";

type StyleProps = {
  expand: string | null;
};
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
    },
    drawer: {
      height: "100%",
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        flexShrink: 0,
      },
    },
    card: {
      borderRadius: 0,
      borderBottom: "1px solid #ccc !important",
    },
    divider: {
      backgroundColor: theme.palette.grey[400],
    },
    description: {
      width: "100%",
      zIndex: 3,
      display: "flex",
      alignItems: "center",
    },
    smallSpacer: {
      width: 4,
    },
    summary: {
      backgroundColor: theme.palette.common.white,
    },
    activeSummary: {
      backgroundColor: theme.palette.grey[100],
    },
    main: {
      height: 400,
      width: "100%",
      borderTop: `2px solid ${theme.palette.learningTemplatePackage.lightGrey}`,
    },
    items: {
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll",
      height: "100%",
    },
    leftItems: {
      borderRight: `2px solid ${theme.palette.learningTemplatePackage.lightGrey}`,
    },
    titleContainer: {
      position: "relative",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.learningTemplatePackage.main,
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      gap: theme.spacing(1),
    },
    title: {
      backgroundColor: theme.palette.learningTemplatePackage.main,
      color: theme.palette.common.black,
      fontWeight: 700,
    },
    helpIcon: {
      position: "absolute",
      top: "2px",
      right: "16px",
      width: "38px",
      height: "38px",
    },
  }),
);

export const StyledAccordion = withStyles((theme: Theme) => ({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

export const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    border: "none",
    width: "transparent",
    marginBottom: -1,
    minHeight: 42,
    "&$expanded": {
      minHeight: 42,
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    "&.Mui-expanded": {
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
    "&.MuiButtonBase-root": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

interface Props {
  materials: HasIdAndTitle[];
  handleChosePackage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  chosenPackage: string[] | coPackageType[];
  status: ModalStatus;
  selectedCategoryId: string[];
  text: TextCategoryState;
  grade: GradeCategoryState;
  subject: SubjectCategoryState;
  major: MajorCategoryState;
  middle: MiddleCategoryState;
  minor: MinorCategoryState;
  currentMaterialsPage: number;
  totalMaterials: number;
  fetchingMaterialsByCategories: boolean;
  coPackage?: boolean;
  handleSelectedIds: (item: BaseCategoryInfo) => void;
  handleMaterialsPagination: (newPage: number) => void;
}

const MaterialList: React.FC<Props> = ({
  materials,
  handleChosePackage,
  chosenPackage,
  status,
  selectedCategoryId,
  text,
  grade,
  subject,
  major,
  middle,
  minor,
  fetchingMaterialsByCategories,
  coPackage,
  currentMaterialsPage,
  totalMaterials,
  handleSelectedIds,
  handleMaterialsPagination,
}) => {
  const [expand, setExpand] = useState<string | null>(null);
  const toggleAccordion = (id: string) => {
    setExpand((isExpanded) => (isExpanded === id ? null : id));
  };
  const classes = useStyles({ expand });

  const drawer = (
    <div>
      {text.items &&
        text.items.map((item) => (
          <Box key={item.id}>
            <StyledAccordion
              expanded={expand === item.id}
              square
              className={classes.card}
            >
              <StyledAccordionSummary
                className={
                  selectedCategoryId.includes(item.id)
                    ? classes.activeSummary
                    : classes.summary
                }
              >
                <Box
                  className={classes.description}
                  onClick={() => handleSelectedIds(item)}
                >
                  <MenuBookIcon />
                  <span className={classes.smallSpacer} />
                  <Typography>
                    <ListItemText primary={item.name} />
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => toggleAccordion(item.id)}
                  size="large"
                >
                  {expand === item.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </StyledAccordionSummary>
              {grade.items
                .filter((g) => item.id === g.text_id)
                .map((gradeItem) => (
                  <GradeLayer
                    key={gradeItem.id}
                    categoryItem={gradeItem}
                    subject={subject}
                    major={major}
                    middle={middle}
                    minor={minor}
                    selectedCategoryId={selectedCategoryId}
                    handleSelectedIds={handleSelectedIds}
                  />
                ))}
            </StyledAccordion>
            <Divider className={classes.divider} />
          </Box>
        ))}
    </div>
  );

  let content: React.ReactNode;
  if (fetchingMaterialsByCategories) {
    content = <CircularProgress />;
  } else if (materials.length > 0) {
    content = (
      <>
        {materials.map((material) => {
          return (
            <FormControlLabel
              key={material.id}
              control={
                <Checkbox
                  disabled={HandleDisable(
                    material,
                    chosenPackage,
                    status,
                    coPackage,
                  )}
                  value={material.id}
                  checked={HandleChecked(material, chosenPackage, coPackage)}
                  onChange={handleChosePackage}
                  data-cy={`material-${material.title}`}
                />
              }
              label={material.title}
            />
          );
        })}
        <V2Pagination
          totalCount={totalMaterials}
          currentPage={currentMaterialsPage}
          perPage={ITEMS_PER_PAGE}
          handlePageTransition={handleMaterialsPagination}
        />
      </>
    );
  } else {
    content = (
      <Box m={2}>
        <Typography>教材がありません。</Typography>
      </Box>
    );
  }

  return (
    <Grid container className={classes.main}>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        className={clsx(classes.leftItems, classes.items)}
      >
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>
            カテゴリーで絞り込む
          </Typography>
        </Box>
        <div className={classes.root}>
          <div className={classes.drawer} aria-label="mailbox folders">
            {drawer}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} className={classes.items}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>教材一覧</Typography>
        </Box>
        {content}
      </Grid>
    </Grid>
  );
};

MaterialList.defaultProps = {
  coPackage: undefined,
};

export default MaterialList;
