import ToggleIcon from "@assets/images/svg/toggleIcon.svg?react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListIcon from "@mui/icons-material/List";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { Editor } from "@tiptap/react";
import React, { memo, useContext,useState } from "react";

import { ToggleListHTML } from "../Extension/ToggleButton";
import { useStyles as useSVBubbleStyles } from "../PlusButtonMenu/styles";
import { SVEditorContext } from "../SVEditorContext";
import { BubbleMenuContext } from "./BubbleMenuContext";
import { useStyles } from "./styles";

export const featureTitle = [
  "テキスト",
  "見出し1",
  "見出し2",
  "見出し3",
  "箇条書きリスト",
  "トグルリスト",
];

export const TextGroupPlusMenu = ({
  editor,
  handleClose,
  setFeature,
  clickOneBlockAddAction,
}: {
  editor: Editor | null;
  handleClose?: () => void;
  setFeature: React.Dispatch<React.SetStateAction<string>>;
  clickOneBlockAddAction: (oneBlockValue?: string) => void;
}): JSX.Element => {
  const { optionalExtensions } = useContext(SVEditorContext);

  const classes = useSVBubbleStyles();

  return (
    <>
      <MenuItem
        onClick={() => {
          if (editor) {
            clickOneBlockAddAction("<p></p>");
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[0]);
        }}
        classes={{ root: classes.MenuItem }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>
          <TextFieldsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={featureTitle[0]} />
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (editor) {
            clickOneBlockAddAction("<H1></H1>");
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[1]);
        }}
        classes={{ root: classes.MenuItem }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>H1</ListItemIcon>
        <ListItemText primary={featureTitle[1]} />
      </MenuItem>

      <MenuItem
        onClick={() => {
          if (editor) {
            clickOneBlockAddAction("<H2></H2>");
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[2]);
        }}
        classes={{ root: classes.MenuItem }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>H2</ListItemIcon>
        <ListItemText primary={featureTitle[2]} />
      </MenuItem>

      <MenuItem
        onClick={() => {
          if (editor) {
            clickOneBlockAddAction("<H3></H3>");
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[3]);
        }}
        classes={{ root: classes.MenuItem }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>H3</ListItemIcon>
        <ListItemText primary={featureTitle[3]} />
      </MenuItem>

      <MenuItem
        onClick={() => {
          if (editor) {
            clickOneBlockAddAction("<ul><li><p></p></li></ul>");
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[4]);
        }}
        classes={{ root: classes.MenuItem }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>
          <ListIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={featureTitle[4]} />
      </MenuItem>

      {optionalExtensions.toggleButton && (
        <MenuItem
          onClick={() => {
            if (editor) {
              clickOneBlockAddAction(ToggleListHTML);
            }
            if (handleClose) {
              handleClose();
            }
            setFeature(featureTitle[5]);
          }}
          classes={{ root: classes.MenuItem }}
        >
          <ListItemIcon className={classes.ListItemIconStyle}>
            <ToggleIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText primary={featureTitle[5]} />
        </MenuItem>
      )}
    </>
  );
};

TextGroupPlusMenu.defaultProps = {
  handleClose: null,
};

export const TextGroupMenu = ({
  editor,
  handleClose,
  setFeature,
}: {
  editor: Editor | null;
  handleClose?: () => void;
  setFeature: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const { optionalExtensions } = useContext(SVEditorContext);
  const classes = useStyles();
  const resetTextGroup = (editorVal: Editor): void => {
    if (editorVal.isActive("heading")) {
      editorVal.chain().focus().setParagraph().run();
    }
    if (editorVal.isActive("bulletList")) {
      editorVal.chain().focus().toggleBulletList().run();
    }
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          if (editor) {
            resetTextGroup(editor);
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[0]);
        }}
        selected={editor?.isActive("paragraph")}
        classes={{ root: classes.MenuItem, selected: classes.MenuItemSelected }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>
          <TextFieldsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={featureTitle[0]} />
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (editor) {
            resetTextGroup(editor);
            editor.chain().focus().setHeading({ level: 1 }).run();
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[1]);
        }}
        selected={editor?.isActive("heading", { level: 1 })}
        classes={{ root: classes.MenuItem, selected: classes.MenuItemSelected }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>H1</ListItemIcon>
        <ListItemText primary={featureTitle[1]} />
      </MenuItem>

      <MenuItem
        onClick={() => {
          if (editor) {
            resetTextGroup(editor);
            editor.chain().focus().setHeading({ level: 2 }).run();
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[2]);
        }}
        selected={editor?.isActive("heading", { level: 2 })}
        classes={{ root: classes.MenuItem, selected: classes.MenuItemSelected }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>H2</ListItemIcon>
        <ListItemText primary={featureTitle[2]} />
      </MenuItem>

      <MenuItem
        onClick={() => {
          if (editor) {
            resetTextGroup(editor);
            editor.chain().focus().setHeading({ level: 3 }).run();
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[3]);
        }}
        selected={editor?.isActive("heading", { level: 3 })}
        classes={{ root: classes.MenuItem, selected: classes.MenuItemSelected }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>H3</ListItemIcon>
        <ListItemText primary={featureTitle[3]} />
      </MenuItem>

      <MenuItem
        onClick={() => {
          if (editor) {
            resetTextGroup(editor);
            editor.chain().focus().toggleBulletList().run();
          }
          if (handleClose) {
            handleClose();
          }
          setFeature(featureTitle[4]);
        }}
        selected={editor?.isActive("bulletList")}
        classes={{ root: classes.MenuItem, selected: classes.MenuItemSelected }}
      >
        <ListItemIcon className={classes.ListItemIconStyle}>
          <ListIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={featureTitle[4]} />
      </MenuItem>

      {optionalExtensions.toggleButton && (
        <MenuItem
          onClick={() => {
            if (editor) {
              resetTextGroup(editor);
              editor.chain().focus().setToggleList().run();
            }
            if (handleClose) {
              handleClose();
            }
            setFeature(featureTitle[5]);
          }}
          classes={{ root: classes.MenuItem }}
        >
          <ListItemIcon className={classes.ListItemIconStyle}>
            <ToggleIcon width={20} height={20} />
          </ListItemIcon>
          <ListItemText primary={featureTitle[5]} />
        </MenuItem>
      )}
    </>
  );
};

TextGroupMenu.defaultProps = {
  handleClose: null,
};

export const TextGroup = (): JSX.Element => {
  const { editor, subMenuOpen, setSubMenuOpen, isOtherSubMenuOpen } =
    useContext(BubbleMenuContext);
  const classes = useStyles();
  const [textAnchorEl, setTextAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    if (setSubMenuOpen) {
      setSubMenuOpen({
        ...subMenuOpen,
        textGroup: false,
      });
    }

    setTextAnchorEl(null);
  };
  const [feature, setFeature] = useState<string>(featureTitle[0]);

  return (
    <>
      <button
        type="button"
        onClick={(event) => {
          if (!textAnchorEl) {
            if (setSubMenuOpen) {
              setSubMenuOpen({
                ...subMenuOpen,
                textGroup: true,
              });
            }

            setTextAnchorEl(event.currentTarget);
          } else {
            if (setSubMenuOpen) {
              setSubMenuOpen({
                ...subMenuOpen,
                textGroup: false,
              });
            }

            setTextAnchorEl(null);
          }
        }}
        className={classes.default}
        style={{
          minWidth: 100,
          flexShrink: 0,
          border: "2px solid white",
        }}
        disabled={isOtherSubMenuOpen("textGroup")}
      >
        {feature === featureTitle[0] ? (
          <Box display="flex" alignItems="center">
            <span style={{ verticalAlign: "super" }}>{feature}</span>
            <span style={{ verticalAlign: "bottom" }}>
              <KeyboardArrowDownIcon fontSize="small" />
            </span>
          </Box>
        ) : (
          feature
        )}
      </button>

      <Menu
        anchorEl={textAnchorEl}
        open={Boolean(textAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          <TextGroupMenu
            editor={editor}
            handleClose={handleClose}
            setFeature={setFeature}
          />
        </MenuList>
      </Menu>
    </>
  );
};

export default memo(TextGroup);
