/* eslint-disable camelcase */
import {
  Api,
  GetPblBoardCampaignRequestAsOwnerParams,
  PblBoardCampaignRequestList,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { PutPblBoardCampaignRequestParams } from "@root/types/PblBoard/Campaign";
import { RootState } from "@store/store";

const api = new Api();

export const getPblBoardCampaignRequestAsOwner = createAsyncThunk<
  {
    board_campaign_requests: PblBoardCampaignRequestList[];
    total_count: number;
  },
  GetPblBoardCampaignRequestAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/board/campaigns/requests/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.getPblBoardCampaignRequestAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const putPblBoardCampaignRequestAsOwner = createAsyncThunk<
  any,
  PutPblBoardCampaignRequestParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/board/campaigns/requests/put",
  async (params, { rejectWithValue }) => {
    const { campaign_id, id, data } = params;
    try {
      const response = await api.owner.putPblBoardCampaignRequestAsOwner(
        campaign_id,
        id,
        data,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export type CampaignRequestSequenceParams = {
  campaign_id: string;
  id: string;
  data: { sequence: number };
};

export const putPblBoardCampaignRequestSequenceAsOwner = createAsyncThunk<
  any,
  CampaignRequestSequenceParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/board/campaigns/requests/put/sequence",
  async (params, { rejectWithValue }) => {
    const { campaign_id, id, data } = params;
    try {
      const response =
        await api.owner.putPblBoardCampaignRequestSequenceAsOwner(
          campaign_id,
          id,
          data,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const resetOwnerAppPblBoardCampaignRequestState = createAction(
  "ownerApp/pbl/board/resetPblBoardCampaignRequestState",
);

export const resetOwnerAppPblBoardCampaignRequestModalState = createAction(
  "ownerApp/pbl/board/resetPblBoardCampaignRequestModalState",
);

export const pblBoardCampaignRequestSequenceSuccess = createAction(
  "ownerApp/pbl/board/campaignRequestSequenceSuccess",
);

export const pblBoardCampaignRequestSequenceRejected = createAction(
  "ownerApp/pbl/board/campaignRequestSequenceRejected",
);
