/* eslint-disable camelcase */
import {
  Api,
  DailyLogBase,
  DailyLogCategory,
  GetDailyLogsAsStudentParams,
  GetStudyLogsAsStudentParams,
  StudyLogBase,
  StudyLogBehavior,
  StudyLogCategorizableType,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchDailyLogsAsStudent = createAsyncThunk<
  { daily_logs: DailyLogBase[]; total_count: number },
  GetDailyLogsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/student_logs/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.getDailyLogsAsStudent(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

type DailyLogsParams = {
  date: string;
  study_time: number;
  category: DailyLogCategory;
  memo?: string;
};

export const postDailyLogAsStudent = createAsyncThunk<
  any,
  DailyLogsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/student_dailyLogs/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.postDailyLogAsStudent(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

type EditDailyLogAsStudentParams = {
  id: string;
  data: DailyLogsParams;
};

export const putDailyLogAsStudent = createAsyncThunk<
  any,
  EditDailyLogAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/student_dailyLogs/edit", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    const response = await api.student.putDailyLogAsStudent(id, data);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchStudyLogsAsStudent = createAsyncThunk<
  { study_logs: StudyLogBase[]; total_count: number },
  GetStudyLogsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/student_logs/fetch_study_logs",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.student.getStudyLogsAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

type PostStudyLogsParams = {
  categorizable_type: StudyLogCategorizableType;
  categorizable_id: string;
  behavior: StudyLogBehavior;
  group_affiliate_package_id?: string;
};

export const postStudyLogsAsStudent = createAsyncThunk<
  any,
  PostStudyLogsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/student_studyLogs/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.postStudyLogAsStudent(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetState = createAction("student/student_logs/reset");
