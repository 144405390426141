import { BaseQueryFn, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "@root/constants/env";
import jsonFormData from "json-form-data";
import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import axios, { AxiosRequestConfig, AxiosError } from "axios";

// https://qiita.com/hibohiboo/items/544c641c9c0a981eb31b
type AxiosParams = {
  url: string;
  headers?: AxiosRequestConfig["headers"];
  method?: AxiosRequestConfig["method"];
  body?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
};
const axiosBaseQuery =
  ({
    baseUrl,
    prepareHeaders,
  }: FetchBaseQueryArgs): BaseQueryFn<AxiosParams, unknown, unknown> =>
  async ({ url, headers, method, body, params }, api) => {
    try {
      const data =
        api.type === "mutation"
          ? jsonFormData(body, { showLeafArrayIndexes: false })
          : body;

      const result = await axios.request({
        url: baseUrl + url,
        method: method ?? "GET",
        headers,
        params,
        data,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

// initialize an empty api service that we'll inject endpoints into later as needed
const baseApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: config.url.API_URL,
  }),
  endpoints: () => ({}),
});

// eslint-disable-next-line import/prefer-default-export
export { baseApi };
