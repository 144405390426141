import { MultilineText } from "@components/UI/atoms/MultilineText/MultilineText";
import { StyledTag,StyledTagColor } from "@components/UI/atoms/StyledTag";
import { TEACHER_ROUTES } from "@constants/routes";
import {
  StudentLogCategorizableType,
  StudyLogCategorizableType,
} from "@lib/Api";
import { Grid,Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { StudentLogDeliverableArticleBase } from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

type Props = {
  log: StudentLogDeliverableArticleBase;
  studentUserId: string;
  tagColor: StyledTagColor;
  tagName: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    modalLink: {
      color: theme.palette.tertiary.mainDark,
      fontSize: "1rem",
      textDecoration: "underline",
      textUnderlineOffset: "3px",
      textDecorationColor: theme.palette.tertiary.mainDark,
      cursor: "pointer",
      marginLeft: theme.spacing(3),
    },
    gridItemWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    tagItemWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingRight: theme.spacing(3),
    },
  }),
);

const LogsSectionData: React.FC<Props> = ({
  log,
  studentUserId,
  tagColor,
  tagName,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();

  const handlePageRedirection = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    const from = TEACHER_ROUTES.STUDENTS_LOGS;
    const pathMap: Record<string, string> = {
      [StudyLogCategorizableType.Homework]: `${
        TEACHER_ROUTES.HOMEWORK_TEMPLATES
      }/${(log as any).parent_id}/homeworks/${log.id}`,
      [StudyLogCategorizableType.MaterialWork]: `${TEACHER_ROUTES.USERS}/${studentUserId}/material_works/${log.id}`,
      [StudyLogCategorizableType.PblCardsInformationLiteracyBookmark]: `${TEACHER_ROUTES.PBL_BOOKMARKS}/${log.id}`,
      [StudyLogCategorizableType.PblReportAnswer]: `${
        TEACHER_ROUTES.PBL_REPORTS
      }/${(log as any).parent_id}/answers/${log.id}`,
      [StudyLogCategorizableType.PblSteamComment]: `${TEACHER_ROUTES.STEAM}/lecture/${log.id}`,
      [StudyLogCategorizableType.PblSteamContentWork]: `${TEACHER_ROUTES.STEAM}/content/${log.id}`,
      [StudyLogCategorizableType.Article]: `${TEACHER_ROUTES.ARTICLES}/${log.id}`,
      [StudyLogCategorizableType.Schedule]: `${TEACHER_ROUTES.CALENDAR}`,
      [StudentLogCategorizableType.LearningPackage]: `${TEACHER_ROUTES.LEARNING_PACKAGES}`,
    };
    const path = pathMap[log.categorizable_type];

    if (path) {
      history.push({
        pathname: path,
        state: { detail: log.categorizable_type, from, search },
      });
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={2} className={classes.tagItemWrapper}>
        <StyledTag label={tagName} tagColor={tagColor} />
      </Grid>
      <Grid item className={classes.gridItemWrapper} xs={3}>
        <Typography>{log.updated_at}</Typography>
      </Grid>
      <Grid item className={classes.gridItemWrapper} xs={6}>
        <MultilineText
          className={classes.modalLink}
          onClick={handlePageRedirection}
        >
          {log.title}
        </MultilineText>
      </Grid>
    </Grid>
  );
};

export default LogsSectionData;
