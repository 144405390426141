/* eslint-disable @typescript-eslint/ban-types */
import { fetchCompaniesAsSuperOwner } from "@actions/superOwnerApp/school";
import {
  getStudentsAffiliatesFromCompanies,
  getStudentsAffiliatesFromUserTags,
} from "@actions/superOwnerApp/templatePackages/learningTemplateDistribution";
import { DistributionEnum } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import { ReduxStatus } from "@constants/redux";
import {
  Company,
  GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerParams as GetStudentParamsFromCompanies,
  GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerParams as GetStudentParamsFromTags,
  LearningTemplatePackageBase,
} from "@lib/Api";
import { superOwnerAppSchoolsState } from "@reducers/superOwnerApp/school";
import { superOwnerAppLearningTemplateDistributionState } from "@reducers/superOwnerApp/templatePackages/learningTemplateDistribution";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import React, { useEffect } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import {
  FormType,
  SchoolDistributionEnum,
} from "../usePackageDistributionModal";
import StudentList from "./StudentList";

interface Props {
  getValues: UseFormGetValues<FormType>;
  watch: UseFormWatch<FormType>;
  reset: UseFormReset<FormType>;
  control: Control<FormType, object>;
  setValue: UseFormSetValue<FormType>;
  distributionType: DistributionEnum;
  learningTemplate: LearningTemplatePackageBase;
}

const StepTwo: React.FC<Props> = ({
  getValues,
  watch,
  reset,
  control,
  setValue,
  distributionType,
  learningTemplate,
}) => {
  const { grade, tagIds, schools, schoolTags, schoolDistributionType } =
    getValues();
  const studentIds = watch("studentIds");
  const dispatch = useAppDispatch();
  const schoolCache = useAppSelector(superOwnerAppSchoolsState);
  const learningTemplateDistributionCache = useAppSelector(
    superOwnerAppLearningTemplateDistributionState,
  );
  const { fetchStudentList, studentsAffiliate, totalCount } =
    learningTemplateDistributionCache;
  const { fetching } = schoolCache;

  const getStudentsList = async (
    args: GetStudentParamsFromCompanies | GetStudentParamsFromTags,
  ) => {
    if (
      distributionType === DistributionEnum.BY_LIMITED_USER_TAG ||
      distributionType === DistributionEnum.BY_USER_TAG
    ) {
      await dispatch(
        getStudentsAffiliatesFromUserTags(args as GetStudentParamsFromTags),
      );
    } else {
      await dispatch(
        getStudentsAffiliatesFromCompanies(
          args as GetStudentParamsFromCompanies,
        ),
      );
    }
  };

  const fetchCompaniesAndStudentsList = async () => {
    const schoolTagIds = schoolTags.map((tag) => tag.id);
    const { companies }: { companies: Company[] } = await dispatch(
      fetchCompaniesAsSuperOwner({
        params: {
          company_tag_ids: schoolTagIds,
        },
      }),
    ).unwrap();

    if (companies && companies.length) {
      reset({
        ...getValues(),
        schools: companies,
      });
      await getStudentsList({
        company_ids: companies.map((company: Company) => company.id),
        learning_template_package_id: learningTemplate.id,
        grade: grade || undefined,
      });
    }
  };

  useEffect(() => {
    if (
      distributionType === DistributionEnum.BY_SCHOOL &&
      schoolDistributionType === SchoolDistributionEnum.BY_SCHOOL_TAG
    ) {
      fetchCompaniesAndStudentsList();
    } else if (
      distributionType === DistributionEnum.BY_SCHOOL &&
      schoolDistributionType === SchoolDistributionEnum.BY_SCHOOL
    ) {
      getStudentsList({
        company_ids: schools.map((company: Company) => company.id),
        learning_template_package_id: learningTemplate.id,
        grade: grade || undefined,
      });
    } else if (
      distributionType === DistributionEnum.BY_LIMITED_USER_TAG ||
      distributionType === DistributionEnum.BY_USER_TAG
    ) {
      getStudentsList({
        user_tag_ids: tagIds.map((tag) => tag.id),
        learning_template_package_id: learningTemplate.id,
        grade: grade || undefined,
      });
    }
    reset({
      ...getValues(),
      studentIds: [],
    });
  }, []);
  return (
    <>
      {fetching || fetchStudentList === ReduxStatus.pending ? (
        <CircularLoading />
      ) : (
        <StudentList
          distributionType={distributionType}
          setValue={setValue}
          selectedStudentIds={studentIds}
          students={studentsAffiliate}
          totalCount={totalCount}
          control={control}
          reset={reset}
          getValues={getValues}
        />
      )}
    </>
  );
};

export default StepTwo;
