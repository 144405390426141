/* eslint-disable camelcase */
import {
  CreateMaterialTicketParams,
  CreateMaterialTicketResponse,
  PutMaterialTicketParams,
  PutMaterialTicketResponse,
} from "@actions/types/material";
import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const createMaterialTicketAsSuperOwner = createAsyncThunk<
  CreateMaterialTicketResponse,
  CreateMaterialTicketParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/materialTickets/create", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.materialsTicketsCreate(params);
    const resData = response.data;
    return {
      materialTicket: resData.hash_ticket_id,
      completed: true,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const putMaterialTicketAsSuperOwner = createAsyncThunk<
  PutMaterialTicketResponse,
  PutMaterialTicketParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/materialTickets/update", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.materialsTicketsAcceptUpdate(params);
    const resData = response.data;
    return {
      materialTicket: resData.message,
      completed: true,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
