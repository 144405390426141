/* eslint-disable no-param-reassign */
import axios from "axios";
import Cookies from "js-cookie";

import { AUTH } from "../constants/localStorageKeys";
import { logout } from "../store/actions/auth";
import { store } from "../store/store";

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const instance = axios.create({
  baseURL: "https://prod-api.steam-library.go.jp",
});

instance.interceptors.request.use(
  (reqConfig) => {
    const accessToken = Cookies.get(AUTH.ACCESS_TOKEN);
    const client = Cookies.get(AUTH.CLIENT);
    const uid = Cookies.get(AUTH.UID);
    const newConfig = {
      ...reqConfig,
      headers: {
        ...reqConfig.headers,
        "access-token": accessToken,
        Authorization: accessToken,
        uid,
        client,
      },
    };
    return newConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.status || error.response.status;
    if ([UNAUTHORIZED, FORBIDDEN].includes(status)) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  },
);

export const http = instance;
