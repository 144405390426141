import {
  createUserTagging,
  createUserTagType,
  deleteUserTagging,
  deleteUserTagType,
  fetchLimitedUserTagsAsOwner,
  fetchStudentTaggingByUserTag,
  fetchUserTagsAsOwner,
  fetchUserTagsAsOwnerForTagging,
  fetchUserTagTypesAsOwner,
  isRequestsRunning,
  resetBatchCountState,
  resetState,
  updateOwnerUserTagsSequence,
  userTaggingsBatchCreatePartialUpdate,
  userTagRejected,
  userTagSuccess,
} from "@actions/ownerApp/userTag";
import { BasicUserInfo, UserTag, UserTagType } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface OwnerAppUserTagState {
  fetched: boolean;
  fetching: boolean;
  fetchError: any;
  fetchingTagTypes: boolean;
  fetchingAll: boolean;
  fetchedAll: boolean;
  createUserTagType: ReduxStatusType;
  deleteUserTagType: ReduxStatusType;
  updateTagSequence: ReduxStatusType;
  isRequestsRunning: ReduxStatusType;
  userTags: UserTag[];
  limitedUserTags: UserTag[];
  userTagTypes: UserTagType[];
  limitedUserTagTypes: UserTagType[];
  allTagTypes: UserTagType[];
  batchWarnings: string[];
  totalCount: number;
  changing: boolean;
  changed: boolean;
  success: boolean;
  rejected: boolean;
  batchErrors: any[];
  batchCount: number;
  fetchingStudents: boolean;
  fetchedStudents: boolean;
  students: BasicUserInfo[];
}

export const initialState: OwnerAppUserTagState = {
  fetched: false,
  fetching: false,
  fetchError: null,
  fetchingTagTypes: false,
  fetchingAll: false,
  fetchedAll: false,
  success: false,
  rejected: false,
  createUserTagType: ReduxStatus.idle,
  deleteUserTagType: ReduxStatus.idle,
  updateTagSequence: ReduxStatus.idle,
  isRequestsRunning: ReduxStatus.idle,
  userTags: [],
  limitedUserTags: [],
  userTagTypes: [],
  limitedUserTagTypes: [],
  allTagTypes: [],
  totalCount: 0,
  changing: false,
  changed: false,
  batchErrors: [],
  batchCount: 0,
  batchWarnings: [],
  fetchingStudents: false,
  fetchedStudents: false,
  students: [],
};

const ownerAppUserTagSlice = createSlice({
  name: "OwnerApp/UserTag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserTagTypesAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchingTagTypes: true,
          fetched: false,
        };
      })
      .addCase(fetchUserTagTypesAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchingTagTypes: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchUserTagTypesAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingTagTypes: false,
          fetched: true,
          userTagTypes: action.payload.user_tag_types,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchUserTagsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchUserTagsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchUserTagsAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          userTags: action.payload.user_tags,
        };
      })
      .addCase(fetchLimitedUserTagsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchLimitedUserTagsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchLimitedUserTagsAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          limitedUserTags: action.payload.user_tags,
        };
      })
      .addCase(fetchUserTagsAsOwnerForTagging.pending, (state, _action) => {
        return {
          ...state,
          fetchingAll: true,
          fetched: false,
        };
      })
      .addCase(fetchUserTagsAsOwnerForTagging.rejected, (state, action) => {
        return {
          ...state,
          fetchingAll: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchUserTagsAsOwnerForTagging.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingAll: false,
          fetchedAll: true,
          allTagTypes: action.payload.user_tag_types,
        };
      })
      .addCase(fetchStudentTaggingByUserTag.pending, (state, _action) => {
        return {
          ...state,
          fetchingStudents: true,
          fetchedStudents: false,
          students: [],
        };
      })
      .addCase(fetchStudentTaggingByUserTag.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingStudents: false,
          fetchedStudents: true,
          students: action.payload.users,
        };
      })
      .addCase(fetchStudentTaggingByUserTag.rejected, (state, action) => {
        return {
          ...state,
          fetchingStudents: false,
          fetchError: action.payload.error,
        };
      })
      .addCase(createUserTagging.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(createUserTagging.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(createUserTagging.rejected, (state, action) => {
        const tagName = action.meta.arg.tag_name;
        return {
          ...state,
          changing: false,
          changed: false,
          batchErrors: [
            ...state.batchErrors,
            { tagName, errors: action.payload.errors },
          ],
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(
        userTaggingsBatchCreatePartialUpdate.pending,
        (state, _action) => {
          return {
            ...state,
            changing: true,
            changed: false,
          };
        },
      )
      .addCase(
        userTaggingsBatchCreatePartialUpdate.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            changing: false,
            changed: true,
            batchCount: state.batchCount + 1,
            batchWarnings: [...state.batchWarnings, ...payload.errors],
          };
        },
      )
      .addCase(
        userTaggingsBatchCreatePartialUpdate.rejected,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            changing: false,
            changed: false,
            batchErrors: [...state.batchErrors, payload.errors],
            batchCount: state.batchCount + 1,
          };
        },
      )
      .addCase(deleteUserTagging.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(deleteUserTagging.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(deleteUserTagging.rejected, (state, action) => {
        const tagName = action.meta.arg.tag_name;
        return {
          ...state,
          changing: false,
          changed: false,
          batchErrors: [
            ...state.batchErrors,
            { tagName, errors: action.payload.errors },
          ],
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(createUserTagType.pending, (state, _action) => {
        return {
          ...state,
          createUserTagType: ReduxStatus.pending,
        };
      })
      .addCase(createUserTagType.fulfilled, (state, _action) => {
        return {
          ...state,
          createUserTagType: ReduxStatus.fulfilled,
        };
      })
      .addCase(createUserTagType.rejected, (state, action) => {
        return {
          ...state,
          createUserTagType: ReduxStatus.rejected,
        };
      })
      .addCase(deleteUserTagType.pending, (state, _action) => {
        return {
          ...state,
          deleteUserTagType: ReduxStatus.pending,
        };
      })
      .addCase(deleteUserTagType.fulfilled, (state, _action) => {
        return {
          ...state,
          deleteUserTagType: ReduxStatus.fulfilled,
        };
      })
      .addCase(deleteUserTagType.rejected, (state, action) => {
        return {
          ...state,
          deleteUserTagType: ReduxStatus.rejected,
        };
      })
      .addCase(updateOwnerUserTagsSequence.pending, (state, _action) => {
        return {
          ...state,
          updateTagSequence: ReduxStatus.pending,
        };
      })
      .addCase(updateOwnerUserTagsSequence.fulfilled, (state, _action) => {
        return {
          ...state,
          updateTagSequence: ReduxStatus.fulfilled,
        };
      })
      .addCase(updateOwnerUserTagsSequence.rejected, (state, action) => {
        return {
          ...state,
          updateTagSequence: ReduxStatus.rejected,
        };
      })
      .addCase(resetBatchCountState, (state) => {
        return {
          ...state,
          batchCount: 0,
        };
      })
      .addCase(userTagSuccess, (state) => {
        return {
          ...state,
          success: true,
        };
      })
      .addCase(userTagRejected, (state) => {
        return {
          ...state,
          rejected: true,
        };
      })
      .addCase(isRequestsRunning, (state) => {
        return {
          ...state,
          isRequestsRunning: ReduxStatus.pending,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: null,
          changing: false,
          changed: false,
          createUserTagType: ReduxStatus.idle,
          deleteUserTagType: ReduxStatus.idle,
          updateTagSequence: ReduxStatus.idle,
          isRequestsRunning: ReduxStatus.idle,
          success: false,
          rejected: false,
          batchErrors: [],
          batchWarnings: [],
          batchCount: 0,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppUserTagState = (state: RootState): OwnerAppUserTagState =>
  state.ownerApp.userTag;

export default ownerAppUserTagSlice.reducer;
