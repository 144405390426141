import V2Button from "@components/UIv2/atoms/buttons/V2Button";
import GroupIcon from "@mui/icons-material/Group";
import { Box, Skeleton,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { GroupAffiliatePackageGroupOnlyList } from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  groups: GroupAffiliatePackageGroupOnlyList[];
  isGroupFetching?: boolean;
  selectedGroupId: string | undefined;
  handleChange: (selectedGroup?: string) => void;
};

type ThemeProps = {
  isGroupFetching?: boolean;
};

const useStyles = makeStyles<Theme, ThemeProps>((theme: Theme) =>
  createStyles({
    mainContainer: ({ isGroupFetching }) => ({
      width: isGroupFetching ? "100%" : "auto",
      display: "inline-block",
      margin: theme.spacing(3, 0),
    }),
    mainWrapper: {
      border: `2px solid ${theme.palette.tertiary.mainDark}`,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(0.5),
      display: "flex",
      alignItems: "center",
      justifyContent: "stretch",
      flexWrap: "wrap",
    },
    skeleton: {
      flex: 1,
      margin: theme.spacing(0, 1),
    },
  }),
);

const V2GroupTabs: React.FC<Props> = ({
  groups,
  isGroupFetching,
  selectedGroupId,
  handleChange,
}) => {
  const { t } = useTranslation(["dashboard"]);

  const classes = useStyles({ isGroupFetching });
  return (
    <Box className={classes.mainContainer} data-cy="filter-buttons-container">
      <Box className={classes.mainWrapper}>
        {isGroupFetching ? (
          <Skeleton height={28} className={classes.skeleton} />
        ) : (
          <>
            <Box m={0.5}>
              <V2Button
                data-cy="filter-all-button"
                variant={!selectedGroupId ? "contained" : "text"}
                onClick={() => handleChange()}
              >
                <Box fontWeight="bold"> {t("all_groups")}</Box>
              </V2Button>
            </Box>
            <>
              {groups.length > 0
                ? groups.map(
                    (group) => (
                      <Box key={group.id} m={0.5}>
                        <V2Button
                          variant={
                            group.id === selectedGroupId ? "contained" : "text"
                          }
                          startIcon={<GroupIcon />}
                          onClick={() => handleChange(group.id)}
                        >
                          <Box fontWeight="bold">{group.title}</Box>
                        </V2Button>
                      </Box>
                    ),
                    [],
                  )
                : null}
            </>
          </>
        )}
      </Box>
    </Box>
  );
};

V2GroupTabs.defaultProps = {
  isGroupFetching: false,
};

export default V2GroupTabs;
