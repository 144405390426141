import EditAndDeleteHoverButtons from "@components/Common/UniversityInterviewRequestList/Modal/MailBoxContainer/Chat/ChatMessages/MessageItems/ActionsButtons";
import LinkifyMultipleText from "@components/UI/atoms/LinkifyMultipleText";
import V2AttachmentItem from "@components/UIv2/molecules/V2AttachmentItem";
import { JpRoleNames } from "@constants/roles";
import { useAuth } from "@contexts/Auth";
import { InterviewMessageKind } from "@lib/Api";
import useResponsive from "@lib/hooks/useResponsive";
import { checkNonEmptyString } from "@lib/string-utils";
import { formatLong } from "@lib/time/dateUtils";
import { fullName } from "@lib/user/name-utils";
import { Box, Grid,Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import DefaultPic from "@root/assets/images/png/defaultThumbnail_without_dash.png";
import { InterviewRequestListBase2 as InterviewRequestListAsOwner } from "@root/endpoints/TimeTactApi/ownerApi";
import { InterviewRequestListBase2 as InterviewRequestListAsTeacher } from "@root/endpoints/TimeTactApi/teacherApi";
import axios from "axios";
import fileDownload from "js-file-download";
import React from "react";
import { FaUser, FaUserGraduate,FaUserTie } from "react-icons/fa";

type Props = {
  isOwnMessage: boolean;
  isHover: boolean;
  message: InterviewRequestListAsOwner | InterviewRequestListAsTeacher;
  isElementsDisabled: boolean;
  handleLeave: () => void;
  handleHover: () => void;
  handleIsEditing: () => void;
  handleDelete: () => void;
};

type StyleProps = {
  isOwnMessage: boolean;
  isMobileView: boolean;
  isOwner: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    msgWrapper: ({ isMobileView, isOwner }) => ({
      position: "relative",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
      maxWidth: isMobileView || isOwner ? "none" : "611px",
      width: isMobileView || isOwner ? "100%" : "none",
      minWidth: "311px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "none",
      },
    }),
    wrapper: ({ isMobileView, isOwner }) => ({
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
      maxWidth: isMobileView || isOwner ? "none" : "611px",
      width: isMobileView || isOwner ? "100%" : "none",
      minWidth: "311px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "none",
      },
    }),
    messageContainer: ({ isMobileView, isOwnMessage, isOwner }) => ({
      padding: theme.spacing(2),
      maxWidth: isMobileView || isOwner ? "none" : "511px",
      width: isMobileView || isOwner ? "100%" : "none",
      minWidth: "311px",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flexDirection: "column",
      gap: theme.spacing(1),
      backgroundColor: isOwnMessage
        ? theme.palette.common.white
        : theme.palette.quaternary.opacityGrey,
      borderRadius: theme.spacing(1),
      border: isOwnMessage ? `1px solid ${theme.palette.grey[300]}` : "none",
      [theme.breakpoints.down("sm")]: {
        minWidth: "none",
      },
    }),
    messageHeaderWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    avatarWrapper: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    avatarContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
    },
    avatar: {
      borderRadius: "50%",
      width: "auto",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "cover",
    },
    imgBox: {
      margin: theme.spacing(4, 0),
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(5),
    },
  }),
);

const Message: React.FC<Props> = ({
  message,
  isOwnMessage,
  isHover,
  isElementsDisabled,
  handleHover,
  handleLeave,
  handleIsEditing,
  handleDelete,
}) => {
  const { isMobileView } = useResponsive();
  const { currentUser, isCurrentRoles } = useAuth();
  const isTeacher = message.sender_affiliate.role_name === JpRoleNames.teacher;
  const isStudent = message.sender_affiliate.role_name === JpRoleNames.student;
  const isUniversity = Boolean(currentUser?.is_university);
  const isNotAttachedImagesMsg = (message?.attached_images || []).length <= 0;
  const isAbleToHoverMsg =
    isOwnMessage &&
    !isElementsDisabled &&
    message.kind === InterviewMessageKind.Person;
  const classes = useStyles({
    isMobileView,
    isOwnMessage,
    isOwner: isCurrentRoles.isOwner,
  });
  const handleFileDownload = async (fileUrl: string, title: string) => {
    const { data } = await axios.get(fileUrl, {
      responseType: "blob",
    });
    fileDownload(data, title);
  };

  let userIcon;
  if (isTeacher && !isUniversity) {
    userIcon = <FaUserTie />;
  } else if (isTeacher && isUniversity) {
    userIcon = <FaUserGraduate />;
  } else if (isStudent) {
    userIcon = <FaUser />;
  } else {
    userIcon = <FaUser />;
  }

  return (
    <Box
      data-cy="interview-message-item"
      className={classes.msgWrapper}
      onMouseLeave={() => (isAbleToHoverMsg ? handleLeave() : null)}
      onMouseOver={() => (isAbleToHoverMsg ? handleHover() : null)}
    >
      {!isMobileView && (
        <Box className={classes.avatarWrapper}>
          <Box className={classes.avatarContainer}>
            <img className={classes.avatar} src={DefaultPic} alt="Avatar" />
          </Box>
        </Box>
      )}
      <Box className={classes.messageContainer}>
        <Box className={classes.messageHeaderWrapper}>
          <Box className={classes.wrapper}>
            <span>{userIcon}</span>
            <Typography>{fullName(message.sender_affiliate)}</Typography>
          </Box>

          <Box style={{ display: "flex", flexShrink: 0 }}>
            {formatLong(message.created_at)}
          </Box>
        </Box>
        {message?.attached_images && message?.attached_images?.length > 0 && (
          <Grid container className={classes.fileContainer}>
            {message.attached_images.map((img) => {
              return (
                <Grid key={img.id} item xs={12} sm={6}>
                  <V2AttachmentItem
                    attachment={{
                      id: checkNonEmptyString(img.id),
                      title: checkNonEmptyString(img?.file_name),
                      url: checkNonEmptyString(img?.file_url),
                    }}
                    handleDownload={() =>
                      handleFileDownload(
                        img?.file_url as string,
                        img?.file_name as string,
                      )
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        )}

        <Box>
          <LinkifyMultipleText text={message.message} />
        </Box>
      </Box>
      {isHover && isOwnMessage && (
        <EditAndDeleteHoverButtons
          isNotAttachedImagesMsg={isNotAttachedImagesMsg}
          handleEdit={handleIsEditing}
          handleDelete={handleDelete}
        />
      )}
    </Box>
  );
};

export default Message;
