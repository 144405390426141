import { roles } from "@constants/roles";
import { CurrentUserInfo } from "@lib/Api";

const isTeacher = (currentUser: CurrentUserInfo | null): boolean => {
  return Boolean(currentUser?.current_role === roles.TEACHER);
};

const isStudent = (currentUser: CurrentUserInfo | null): boolean => {
  return Boolean(currentUser?.current_role === roles.STUDENT);
};

const isOwner = (currentUser: CurrentUserInfo | null): boolean => {
  return Boolean(currentUser?.current_role === roles.OWNER);
};

const isSuperOwner = (currentUser: CurrentUserInfo | null): boolean => {
  return Boolean(currentUser?.current_role === roles.SUPER_OWNER);
};

const isReviewer = (currentUser: CurrentUserInfo | null): boolean => {
  return Boolean(currentUser?.current_role === roles.REVIEWER);
};

const isContentProvider = (currentUser: CurrentUserInfo | null): boolean => {
  return Boolean(currentUser?.current_role === roles.CONTENT_PROVIDER);
};

const isAdmin = (currentUser: CurrentUserInfo | null): boolean => {
  return Boolean(currentUser?.current_role === roles.ADMIN);
};

export type IsRoles = {
  isStudent: boolean;
  isTeacher: boolean;
  isOwner: boolean;
  isSuperOwner: boolean;
  isReviewer: boolean;
  isContentProvider: boolean;
  isAdmin: boolean;
};

export const initialIsRoles: IsRoles = {
  isStudent: false,
  isTeacher: false,
  isOwner: false,
  isSuperOwner: false,
  isReviewer: false,
  isContentProvider: false,
  isAdmin: false,
};

const getRoleInfo = (currentUser: CurrentUserInfo | null): IsRoles => {
  return {
    isStudent: isStudent(currentUser),
    isTeacher: isTeacher(currentUser),
    isOwner: isOwner(currentUser),
    isSuperOwner: isSuperOwner(currentUser),
    isReviewer: isReviewer(currentUser),
    isContentProvider: isContentProvider(currentUser),
    isAdmin: isAdmin(currentUser),
  };
};

export default getRoleInfo;
