export const BREAKPOINT_WIDTH = {
  SMALL: 600,
  MEDIUM: 960,
  LARGE: 1280,
  XLARGE: 1920,
};

// eslint-disable-next-line import/prefer-default-export
export const breakpoints = {
  small: `@media (max-width: ${BREAKPOINT_WIDTH.SMALL}px)`,
  medium: `@media (max-width: ${BREAKPOINT_WIDTH.MEDIUM}px)`,
  large: `@media (max-width: ${BREAKPOINT_WIDTH.LARGE}px)`,
  xlarge: `@media (max-width: ${BREAKPOINT_WIDTH.XLARGE}px)`,
};

export type MediaQueryMatch = "small" | "medium" | "large" | "xlarge" | null;
export type ViewWidthType = number | null;
export type ScrollHeightType = number | null;
