import ClockIcon from "@assets/images/svg/chono_icon.svg?react";
import DailyLogsDetailModal from "@components/Common/StudyLogs/DailyLogsDetailModal";
import StudyLogTable from "@components/Common/StudyLogs/Table";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import { ITEMS_PER_PAGE } from "@constants/page";
import { Box,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { StudyLogsModalState, StudyLogsModalTypes } from "..";
import useLogsMain from "./useLogsMain";

type Props = {
  modalState: StudyLogsModalTypes;
  handleClose: () => void;
  setModalState: React.Dispatch<React.SetStateAction<StudyLogsModalTypes>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    clockIcon: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      height: 20,
      width: 20,
      fill: theme.palette.customColor.text,
      "& > path": {
        fill: theme.palette.customColor.text,
      },
    },
    titleSection: {
      display: "flex",
      alignItems: "center",
    },
  }),
);

const LogsMainModal: React.FC<Props> = ({
  modalState,
  handleClose,
  setModalState,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("study_logs");
  const { open, modalState: status, lastWeek } = modalState;
  const { dailyStudyLogsData, isFetching, page, setPage } = useLogsMain({
    lastWeek,
  });

  let main: ReactNode;

  if (isFetching) {
    main = <CircularLoading />;
  } else {
    main = (
      <StudyLogTable
        dailyStudyLogs={dailyStudyLogsData?.daily_logs}
        setModalState={setModalState}
        modalState={modalState}
      />
    );
  }
  const handleModalClose = () => {
    handleClose();
    setPage(1);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <GenericModal
      open={
        open &&
        [StudyLogsModalState.Main, StudyLogsModalState.Details].includes(status)
      }
      handleClose={handleModalClose}
      title={
        <Box className={classes.titleSection}>
          {t("studyLogMain")}
          <ClockIcon className={classes.clockIcon} />
        </Box>
      }
      fullScreen
    >
      <DailyLogsDetailModal
        modalState={modalState}
        setModalState={setModalState}
      />
      <Box className={classes.main}>
        {main}
        <V2Pagination
          currentPage={page}
          handlePageTransition={handleChangePage}
          totalCount={dailyStudyLogsData?.total_count || 0}
          perPage={ITEMS_PER_PAGE}
        />
      </Box>
    </GenericModal>
  );
};

export default LogsMainModal;
