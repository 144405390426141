/* eslint-disable camelcase */
import { Api, GetIpWhitelistsAsAdminParams,IpWhitelistBase } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { batchUpdateIpWhiteListsAsAdminParams } from "./types/ipWhiteList";

const api = new Api();

export const fetchIpWhiteListsAsAdmin = createAsyncThunk<
  { ip_whitelists: IpWhitelistBase[]; total_count: number },
  GetIpWhitelistsAsAdminParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/ipWhiteLists/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.getIpWhitelistsAsAdmin(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const batchUpdateIpWhiteListsAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/ipWhiteLists/batchUpdate",
  async (params: batchUpdateIpWhiteListsAsAdminParams, { rejectWithValue }) => {
    try {
      const { removeIds, postItems, putItems } = params;
      const responses: any[] = [];
      if (removeIds) {
        responses.push(
          await Promise.all(
            removeIds.map((removeId) => {
              return api.admin.deleteIpWhitelistAsAdmin(removeId);
            }),
          ),
        );
      }

      if (putItems) {
        responses.push(
          await Promise.all(
            putItems.map((putItem) => {
              const putParams = {
                role: putItem.roleName,
                ip_address: putItem.ipAddress,
              };
              return api.admin.putIpWhitelistAsAdmin(putItem.dbId, putParams);
            }),
          ),
        );
      }

      if (postItems) {
        responses.push(
          await Promise.all(
            postItems.map((postItem) => {
              return api.admin.postIpWhitelistsAsAdmin({
                role: postItem.roleName,
                ip_address: postItem.ipAddress,
                company_id: postItem.companyId,
              });
            }),
          ),
        );
      }
      return responses;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetIpWhiteListFormState = createAction(
  "admin/ipWhiteListsForm/reset",
);
