import { getSteamLibraryCommentsAsTeacher } from "@actions/teacherApp/steam/steamCommentList";
import { PblSteamCommentList } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

interface TeacherAppSteamCommmentListState {
  fetching: boolean;
  fetched: boolean;
  comments: PblSteamCommentList[];
  totalCount: number;
  fetchError: string | null;
}

const initialState: TeacherAppSteamCommmentListState = {
  fetching: false,
  fetched: false,
  comments: [],
  totalCount: 0,
  fetchError: null,
};

const teacherAppSteamCommentListSlice = createSlice({
  name: "TeacherApp/SteamCommentList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSteamLibraryCommentsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: null,
        };
      })
      .addCase(getSteamLibraryCommentsAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          comments: action.payload.steam_content_comments,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(getSteamLibraryCommentsAsTeacher.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      });
  },
});

export const teacherAppSteamCommentListState = (
  state: RootState,
): TeacherAppSteamCommmentListState => state.teacherApp.steamCommentList;

export default teacherAppSteamCommentListSlice.reducer;
