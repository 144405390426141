/* eslint-disable camelcase */
import {
  AffiliateName,
  Api,
  FetchReviewerAffiliatesAsReviewerParams,
} from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchAllReviewers = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("reviewer/users/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.reviewer.reviewersList(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchReviewerAffiliatesAsReviewer = createAsyncThunk<
  { total_count: number; reviewer_affiliates: AffiliateName[] },
  FetchReviewerAffiliatesAsReviewerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/users/fetchReviewerAffiliates",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.reviewer.fetchReviewerAffiliatesAsReviewer(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
