/* eslint-disable react/require-default-props */
import { objectHas } from "@lib/object-utils";
import { safeString } from "@lib/string-utils";
import { Color } from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Paragraph from "@tiptap/extension-paragraph";
import TableHeader from "@tiptap/extension-table-header";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorContent, Extensions, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Path, useFormContext } from "react-hook-form";

import Div from "./Extension/Div";
import ImageWrapper from "./Extension/Image/ImageWrapper";
import { SVImage } from "./Extension/Image/SVImage";
import { MaterialLink } from "./Extension/MaterialLink";
import CustomTableCell from "./Extension/Table/CustomTableCell";
import CustomTableRow from "./Extension/Table/CustomTableRow";
import SVTable from "./Extension/Table/SVTable";
import TableWrapper from "./Extension/Table/TableWrapper";
import ToggleButton from "./Extension/ToggleButton";
import PlusButtonMenu from "./PlusButtonMenu";
import SVBubble from "./SVBubble";
import { SVEditorContext } from "./SVEditorContext";
import {
  EditorBlocksValue,
  EditorTwoBlockWithSVEditorBase,
  SVEditorFormValue,
} from "./types";
import useSVEditor from "./useSVEditor";
import useSVEditorStyles from "./useSVEditorStyles";
import { findIndexForEditorBlocks } from "./utils";

type SVEditorProps<T> = {
  id: string; // 複数あるSVEditorのうち、どれを指しているかを識別するためのID
  editable: boolean;
  value?: string; // SVEditorで表示したいHTMLの内容
  clickOneBlockAddAction: (oneBlockValue?: string) => void;
  clickTwoBlockAddAction: () => void;
  clickDeleteAction: () => void;
  clickUpAction: () => void;
  clickDownAction: () => void;
  style?: Record<string, string>;
  FormValueName: Path<T>;
};
// tiptapの設定を行うクラス
const SVEditorBase = <T extends SVEditorFormValue>(
  props: SVEditorProps<T>,
): JSX.Element => {
  const {
    id,
    editable,
    value = "",
    clickOneBlockAddAction,
    clickTwoBlockAddAction,
    clickDeleteAction,
    clickUpAction,
    clickDownAction,
    style = {},
    FormValueName,
  } = props;
  const { optionalExtensions } = useContext(SVEditorContext);
  const { watch } = useFormContext<T>();
  const [selected, setSelected] = useState<boolean>(false);

  const CustomParagraph = Paragraph.extend({
    addAttributes() {
      return {
        fontSize: {
          default: "16px",
          renderHTML: (attributes) => {
            return {
              style: `font-size: ${attributes.fontSize}`,
            };
          },
          parseHTML: (element) => {
            return {
              fontSize: element.style.fontSize || "16px",
            };
          },
        },
      };
    },
  });

  const extensions: Extensions = [
    StarterKit,
    Underline,
    SVImage, // 画像を挿入するために必要な拡張
    ImageWrapper, // 画像を挿入した時に右揃え、中央揃え、左揃えを変更できるメニューを表示する拡張機能
    Color,
    Highlight.configure({ multicolor: true }),
    FontFamily,
    TextStyle,
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    Link,
    MaterialLink, // 教材用リンクを挿入するために必要な拡張
    SVTable.configure({
      resizable: true,
    }), // テーブルを挿入するために必要な拡張
    TableHeader,
    CustomTableRow,
    CustomTableCell,
    TableWrapper, // テーブルを挿入した際に出てくる操作メニューを実現するために必要な拡張
    Div, // tiptapではデフォルトで一部のHTMLタグしか挿入可能でないため、divタグを挿入できるようにするために必要な拡張
    CustomParagraph,
  ];

  if (optionalExtensions.toggleButton) {
    extensions.push(ToggleButton); // トグル機能を実現するために必要な拡張
  }

  // tiptapの初期設定を行なっている
  const editor = useEditor({
    extensions,
    content: value, // ここで設定した値がtiptapエディタ上に表示される値
    editable,
    editorProps: {
      handleDOMEvents: {
        // ドラッグアンドドロップでHTML要素を移動されないようにしている
        // これがないと画像やテーブルをドラッグアンドドロップで他のブロックに移動できてしまう
        // (本来想定していない動作を行えないようにしている)
        drop(this, view, event) {
          event.preventDefault();
          return false;
        },
      },
    },
  });
  const { SVEditorClasses: classes } = useSVEditorStyles();
  const { setSomeValues, EditorBlocksKey } = useSVEditor<T>(FormValueName);

  // テキスト入力やHTMLの変更があるたびにsetValueで値を書き換える
  useEffect(() => {
    const editorBlocks = watch(EditorBlocksKey);
    if (!editorBlocks?.length) {
      return;
    }
    // 変更があったSVEditorがeditorBlocks変数上のどのindexにあるのかを調べる
    const { parentIndex, childIndex } = findIndexForEditorBlocks(
      id,
      editorBlocks,
    );

    // 1列ブロックの場合
    // editorBlocks[parentIndex]に該当するSVEditorのオブジェクトがある
    if (parentIndex !== -1 && childIndex === -1) {
      editorBlocks[parentIndex].value = safeString(editor?.getHTML());
    }

    // 2列ブロックの場合
    // editorBlocks[parentIndex].content[childIndex]に該当するSVEditorのオブジェクトがある
    if (parentIndex !== -1 && childIndex !== -1) {
      (editorBlocks[parentIndex] as EditorTwoBlockWithSVEditorBase).content[
        childIndex
      ].value = safeString(editor?.getHTML());
    }

    setSomeValues(editorBlocks);
  }, [editor?.getText(), editor?.getHTML()]);

  const editorBlocks = (watch(EditorBlocksKey) ?? []) as EditorBlocksValue;

  const insertToggleList = useCallback(
    (e: KeyboardEvent) => {
      if (editor) {
        // IME入力中は何もしない
        if (e.isComposing) {
          return;
        }
        if (e.key !== "Enter") {
          return;
        }
        // shift + Enterは通常の改行処理に割り当てたいので何もしない
        if (e.shiftKey) {
          return;
        }
        // https://github.com/ueberdosis/tiptap/issues/1451
        if (!(editor.view as any)?.docView) return;
        const { state } = editor.view;
        const { selection } = state;
        const { $from } = selection;
        if ($from.depth > 3 && $from.before(-3)) {
          const toggleList = state.doc.nodeAt($from.before(-3));
          if (
            toggleList?.type.name === "div" &&
            toggleList.attrs.class === "toggleList"
          ) {
            // 改行動作を無効
            e.preventDefault();
            e.stopImmediatePropagation();

            editor.commands.setToggleList(
              $from.before(-3) + toggleList.nodeSize,
            );
          }
        }
      }
    },
    [editor],
  );

  // トグル機能で、「Enterで新しいトグルを追加、Shit+Enterで改行する」機能を実現している
  useEffect(() => {
    if (editor && objectHas(editor.commands, "setToggleList")) {
      window.addEventListener("keydown", insertToggleList, { capture: true });
    }
    return () => {
      window.removeEventListener("keydown", insertToggleList);
    };
  }, [editor]);

  return (
    <div key={id} className={classes.SVEditorBaseWrapper} style={style}>
      {/*
        プラスボタンの動作を設定
        ブロックが複数あるときに出てくるドラッグメニューも含まれる(6つの丸が並んだメニュー)
      */}
      <PlusButtonMenu
        id={id}
        editor={editor}
        editorBlocks={editorBlocks}
        selected={selected}
        setSelected={setSelected}
        clickOneBlockAddAction={clickOneBlockAddAction}
        clickTwoBlockAddAction={clickTwoBlockAddAction}
        clickUpAction={clickUpAction}
        clickDownAction={clickDownAction}
        clickDeleteAction={clickDeleteAction}
      />

      <div
        className={`${
          selected ? classes.SelectedSVEditor : classes.SVEditorDefault
        } ${classes.ProseMirror}`}
      >
        {/* バブルメニューの設定*/}
        <SVBubble editor={editor} key={`SVBubble+${id}`} />
        {/* 初期設定を行なったtiptap*/}
        <EditorContent data-cy="editor-content" editor={editor} />
      </div>
    </div>
  );
};

export default memo(SVEditorBase) as typeof SVEditorBase;
