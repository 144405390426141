import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Api,
  GetLearningTemplatePackageDistributionHistoriesAsTeacherParams,
} from "@root/lib/Api";
import { RootState } from "@store/store";

const api = new Api();

export const getLearningTemplatePackageDistributionHistoriesAsTeacher =
  createAsyncThunk<
    any,
    GetLearningTemplatePackageDistributionHistoriesAsTeacherParams,
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    "/teacher/learning_template_package_distribution_histories",
    async (params, { rejectWithValue }) => {
      try {
        const response =
          await api.teacher.getLearningTemplatePackageDistributionHistoriesAsTeacher(
            params,
          );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    },
  );
