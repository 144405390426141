/* eslint-disable camelcase */
import { Api, GetOwnerTemplateFrameworksParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface OwnerTemplateFrameworksParams {
  title: string;
}
export const postOwnerTemplateFrameworks = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/report/create_template_frameworks/",
  async (params: OwnerTemplateFrameworksParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.postOwnerTemplateFrameworks(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const updateOwnerTemplateFrameworks = createAsyncThunk<
  any,
  {
    id: string;
    params: OwnerTemplateFrameworksParams;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/report/update_template_frameworks/",
  async (args, { rejectWithValue }) => {
    try {
      const response = await api.owner.putOwnerTemplateFrameworksId(
        args.id,
        args.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getOwnerTemplateFrameworks = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/report/get_all_template_frameworks/",
  async (params: GetOwnerTemplateFrameworksParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.getOwnerTemplateFrameworks(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getOneOwnerTemplateFramework = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/report/get_one_template_framework/",
  async (frameWorkId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.getOwnerTemplateFrameworksId(frameWorkId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const fetchTemplateFrameworkAfterQuestionDelete = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/report/get_one_template_after_question_delete",
  async (frameWorkId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.owner.getOwnerTemplateFrameworksId(frameWorkId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface TemplateItemFrameworksParams {
  type: "text";
  sequence?: number;
  title: string;
  description?: string;
  text_example?: string;
  text_min_char_size?: number;
}
export const postOwnerTemplateItemFrameworks = createAsyncThunk<
  any,
  {
    itemId: string;
    param: TemplateItemFrameworksParams;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/report/create_template_frameworks_item/",
  async (args, { rejectWithValue }) => {
    try {
      const response = await api.owner.postOwnerTemplateItemFrameworks(
        args.itemId,
        args.param,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const updateOwnerTemplateItemFrameworks = createAsyncThunk<
  any,
  {
    itemId: string;
    frameWorkId: string;
    params: TemplateItemFrameworksParams;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/report/update_template_frameworks_item/",
  async (args, { rejectWithValue }) => {
    try {
      const response = await api.owner.putOwnerTemplateItemFrameworksId(
        args.itemId,
        args.frameWorkId,
        args.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

interface DeleteTemplateItemFrameworkArg {
  itemId: string;
  templateFrameworkId: string;
}

export const deleteOwnerTemplateItemFrameworksId = createAsyncThunk<
  any,
  DeleteTemplateItemFrameworkArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/report/delete_template_framework_item/",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await api.owner.deleteOwnerTemplateItemFrameworksId(
        arg.itemId,
        arg.templateFrameworkId,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const deleteOwnerTemplateFrameworksId = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/pbl/report/delete_template_framework",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.owner.deleteOwnerTemplateFrameworksId(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const resetState = createAction("owner/pbl/report/resetState");
