import { BREAKPOINT_WIDTH } from "@constants/breakpoints";
import { createTheme,Theme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const defaultTheme = createTheme();

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: BREAKPOINT_WIDTH.SMALL,
      md: BREAKPOINT_WIDTH.MEDIUM,
      lg: BREAKPOINT_WIDTH.LARGE,
      xl: BREAKPOINT_WIDTH.XLARGE,
    },
  },
  palette: {
    primary: {
      light: "rgba(75, 181, 104, 0.1)",
      main: "#005745",
      dark: "#084100",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff4569",
      main: "#ff1744",
      dark: "#b2102f",
      contrastText: "#475149",
    },
    customColor: {
      mainLight: "rgba(0, 87, 69, 0.2)",
      text: "#475149",
      backgroundColor: "#fbfffa",
      grey: "#EEEEEE",
      lightGreen: "#EAFFE4",
      white: "#ecf1f5",
      commonGrey: "#c4c4c4",
      boardGrey: "#E4E5E4",
      paleGray: "#F0F0F0",
      cyan: "#00A3FF",
      brightGreen: "#67CC59",
      darkGray: "#cccccc",
      mintGreen: "#D9EAD3",
      opacityGrey: "#0000001F",
    },
    tertiary: {
      main: "#4BB568",
      secondary: "#E5FFEC",
      light: "#F4FFF1",
      extraLight: "#FBFFFA",
      mainDark: "#008267",
      mainLight: "rgba(0, 130, 103, 0.2)",
      darkGreen: "#005745",
      orange: "#FF703B",
      azure: "#48C6FC",
      lightRed: "#FF8080",
      lightGreen: "#BFFFCA",
      darkGrey: "#47514999",
    },
    quaternary: {
      lightGrey: "#FAFAFA",
      grey: "#DFDFDF",
      commonGrey: "#E5E5E5",
      lightGreen: "#0082670D",
      lightPink: "#FFE0E0",
      opacityGrey: "#4751490D",
    },
    opacityColors: {
      mainGreenTenPercentage: "#0082671A",
      commentBlue: "rgba(49, 102, 224, 0.1)",
      commentPink: "rgba(255, 112, 59, 0.1)",
      commentGreen: "#67CC591A",
    },
    homeworkStatusColor: {
      student: "#29BF3A",
      teacher: "#046536",
      officer: "#2D7FF9",
    },
    homeworkTemplateStatusColor: {
      new: "#FF8080",
      school: "#29BF3A",
      office: "#2D7FF9",
    },
    progressChip: {
      viewing: "#6B79FF",
      viewed: "#67CC59",
    },
    optionChip: {
      checked: "#67CC59",
      unchecked: "#FF8080",
      disabled: "#E0E0E0",
    },
    arrow: {
      grey: "#C4C4C4",
    },
    dialogBorder: {
      gradient:
        "linear-gradient(111.17deg, #005745 0%, #005745 0%, #035A46 7.34%, #086149 15.59%, #0E694C 23.76%, #16734F 31.77%, #1D7B52 41.05%, #248456 49.73%, #298A58 58.38%, #31945C 66.93%, #369B5E 75.53%, #3DA462 83.53%, #43AB64 91.69%, #4BB568 100%)",
    },
    search: {
      areaBackground: "#EDEEED",
      dateSpace: "#BBBEBB",
      selectedItemBackground: "#FFFFFF",
      lightGreenBackground: "#E5F2F0",
    },
    dashboard: {
      blueTag: "#6B79FF",
      orangeTag: "#FD9667",
      lightBlueTag: "#48C6FC",
      lightGrey: "rgba(71, 81, 73, 0.1)",
    },
    learningTemplatePackage: {
      main: "#D9ECE8",
      lightGrey: "#C8CBC8",
      grey: "#D4D9D7",
      tooltipGrey: "#47514966",
    },
    header: {
      lightGreen: "#E5F2F0",
    },
    fileUpload: {
      pdf: "#B30C01",
      word: "#1F64C2",
      excel: "#0D6E38",
      powerPoint: "#BD3819",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h3: {
      fontSize: 26,
      lineHeight: 1.2,
      fontWeight: 400,
      marginTop: "1rem",
      marginBottom: "1rem",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: "inherit",
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

const styledTheme: Partial<Theme> = {
  components: {
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "14px",
        },
        outlinedInherit: {
          border: "1px solid rgba(0, 0, 0, 0.23)",
          color: "rgba(0, 0, 0, 0.87)",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium as any,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          marginRight: 0,
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          [theme.breakpoints.down("sm")]: {
            margin: 0,
          },
        },
        paperFullWidth: {
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        },
      },
    },
  },
};

export default deepmerge(theme, styledTheme);
