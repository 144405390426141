import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import { MultilineText } from "@components/UI/atoms/MultilineText/MultilineText";
import ErrorMessage from "@components/UI/molecules/ErrorMessage";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { UseFormGetValues } from "react-hook-form";

import { InquiryFormValues } from "./Form";

interface Props {
  getValues: UseFormGetValues<InquiryFormValues>;
  onSubmit: () => void;
  error: any;
}

const Form: React.FC<Props> = ({ getValues, onSubmit, error }) => {
  return (
    <Container>
      <form onSubmit={onSubmit}>
        {error && <ErrorMessage message={error} />}
        <FormSectionWrap>
          <FormCategoryTitle>タイトル</FormCategoryTitle>
          <Typography>{getValues("title")}</Typography>
        </FormSectionWrap>
        <FormSectionWrap>
          <FormCategoryTitle>内容</FormCategoryTitle>
          <MultilineText>{getValues("body")}</MultilineText>
        </FormSectionWrap>
      </form>
    </Container>
  );
};

export default Form;
