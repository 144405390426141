import { Box, Theme, Typography } from "@mui/material";
import Container, { ContainerProps } from "@mui/material/Container";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  title: string;
}

type SectionWrapperProps = Props & ContainerProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: theme.spacing(6),
    },
    header: {
      width: "100%",
      borderRadius: "4px 4px 0 0",
      padding: theme.spacing(2, 1),
    },
    headerTitle: {
      fontSize: "20px",
      fontWeight: 700,
      color: theme.palette.customColor.text,
    },
    body: {
      width: "100%",
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(1),
      background: theme.palette.common.white,
    },
  }),
);

const SectionWrapper: React.FC<SectionWrapperProps> = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Container className={classes.main}>
      <Box className={classes.header}>
        <Typography className={classes.headerTitle}>{title}</Typography>
      </Box>
      <Box className={classes.body}>{children}</Box>
    </Container>
  );
};

export default SectionWrapper;
