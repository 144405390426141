import { RubricBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import {
  batchUpdateRubricAsTeacher,
  getRubricAsTeacher,
  getRubricsAsTeacher,
  resetState,
} from "@store/actions/teacherApp/rubric";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface RubricsState {
  fetch: string;
  fetchError: string | null;
  rubrics: RubricBase[];
  rubric: RubricBase | null;
  totalCount: number;
  create: ReduxStatusType;
  createError: string | string[];
  fetchOne: ReduxStatusType;
  fetchOneError: string;
}

const initialState: RubricsState = {
  fetch: ReduxStatus.idle,
  fetchError: "",
  rubrics: [],
  rubric: null,
  totalCount: 0,
  create: ReduxStatus.idle,
  createError: "",
  fetchOne: ReduxStatus.idle,
  fetchOneError: "",
};

const rubricsDistributionSlice = createSlice({
  name: "teacher/rubrics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRubricsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetch: ReduxStatus.pending,
        };
      })
      .addCase(getRubricsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetch: ReduxStatus.fulfilled,
          rubrics: payload.rubrics,
          totalCount: payload.total_count,
        };
      })
      .addCase(getRubricsAsTeacher.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetch: ReduxStatus.rejected,
          fetchError: payload.errors.message as string,
        };
      })
      .addCase(getRubricAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchOne: ReduxStatus.pending,
        };
      })
      .addCase(getRubricAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchOne: ReduxStatus.fulfilled,
          rubric: payload,
        };
      })
      .addCase(getRubricAsTeacher.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchOne: ReduxStatus.rejected,
          fetchError: getErrorMessage(payload.messages.error),
        };
      })
      .addCase(batchUpdateRubricAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          create: ReduxStatus.pending,
        };
      })
      .addCase(batchUpdateRubricAsTeacher.fulfilled, (state, _action) => {
        return {
          ...state,
          create: ReduxStatus.fulfilled,
        };
      })
      .addCase(batchUpdateRubricAsTeacher.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          create: ReduxStatus.rejected,
          createError: getErrorMessage(payload.messages.error),
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          create: ReduxStatus.idle,
          createError: "",
        };
      });
  },
});

export const rubricsDistributionState = (state: RootState): RubricsState =>
  state.teacherApp.rubrics;

export default rubricsDistributionSlice.reducer;
