import { baseApi as api } from "../baseApi";
export const addTagTypes = [
  "ContentProvider/Articles",
  "ContentProvider/Article",
  "ContentProvider/ArticleComments",
  "ContentProvider/ArticleCommentImages",
  "ContentProvider/ArticleLikes",
  "ContentProvider/Sponsor",
  "ContentProvider/Sponsor/Infos",
  "ContentProvider/Sponsor/Projects",
  "ContentProvider/Sponsor/Lectures",
  "ContentProvider/Notification",
  "ContentProvider/PblBoardInfos",
  "ContentProvider/PblBoardItems",
  "ContentProvider/PblBoardCampaigns",
  "ContentProvider/PblBoardCampaignRequests",
  "ContentProvider/PblBoardArticles",
  "ContentProvider/PblBoardContents",
  "ContentProvider/PblBoardFooters",
  "ContentProvider/PblBoardFooterItems",
  "ContentProvider/FeedbackTemplates",
  "ContentProvider/AttachedImages",
  "ContentProvider/Gogo/Request",
  "ContentProvider/Gogo/Message",
  "ContentProvider/Gogo/Program",
  "ContentProvider/Gogo/ProgramRequest",
  "ContentProvider/Gogo/ProgramRequestMessage",
  "ContentProvider/Gogo/CompanyInformation",
  "ContentProvider/Gogo/ProgramPreferredDate",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getArticlesAsContentProvider: build.query<
        GetArticlesAsContentProviderApiResponse,
        GetArticlesAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["ContentProvider/Articles"],
      }),
      fetchArticleWordCloudAsContentProvider: build.query<
        FetchArticleWordCloudAsContentProviderApiResponse,
        FetchArticleWordCloudAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/word_cloud`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            list_len: queryArg.listLen,
            offset: queryArg.offset,
            min_num_of_char: queryArg.minNumOfChar,
          },
        }),
        providesTags: ["ContentProvider/Article"],
      }),
      getArticleAsContentProvider: build.query<
        GetArticleAsContentProviderApiResponse,
        GetArticleAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["ContentProvider/Articles"],
      }),
      getArticleCommentsAsContentProvider: build.query<
        GetArticleCommentsAsContentProviderApiResponse,
        GetArticleCommentsAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/comments`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["ContentProvider/ArticleComments"],
      }),
      postArticleCommentsAsContentProvider: build.mutation<
        PostArticleCommentsAsContentProviderApiResponse,
        PostArticleCommentsAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/ArticleComments"],
      }),
      getArticleCommentAsContentProvider: build.query<
        GetArticleCommentAsContentProviderApiResponse,
        GetArticleCommentAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/comments/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["ContentProvider/ArticleComments"],
      }),
      putArticleCommentAsContentProvider: build.mutation<
        PutArticleCommentAsContentProviderApiResponse,
        PutArticleCommentAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/ArticleComments"],
      }),
      deleteArticleCommentAsContentProvider: build.mutation<
        DeleteArticleCommentAsContentProviderApiResponse,
        DeleteArticleCommentAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/ArticleComments"],
      }),
      postArticleCommentImageAsContentProvider: build.mutation<
        PostArticleCommentImageAsContentProviderApiResponse,
        PostArticleCommentImageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/comments/${queryArg.commentId}/article_comment_images`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/ArticleCommentImages"],
      }),
      deleteArticleCommentImageAsContentProvider: build.mutation<
        DeleteArticleCommentImageAsContentProviderApiResponse,
        DeleteArticleCommentImageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/comments/${queryArg.commentId}/article_comment_images/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/ArticleCommentImages"],
      }),
      getArticleLikesAsContentProvider: build.query<
        GetArticleLikesAsContentProviderApiResponse,
        GetArticleLikesAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/likes`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["ContentProvider/ArticleLikes"],
      }),
      postArticleLikesAsContentProvider: build.mutation<
        PostArticleLikesAsContentProviderApiResponse,
        PostArticleLikesAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/likes`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/ArticleLikes"],
      }),
      deleteArticleLikeAsContentProvider: build.mutation<
        DeleteArticleLikeAsContentProviderApiResponse,
        DeleteArticleLikeAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/articles/${queryArg.articleId}/likes/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/ArticleLikes"],
      }),
      getSponsorDeliverySchoolsAsContentProvider: build.query<
        GetSponsorDeliverySchoolsAsContentProviderApiResponse,
        GetSponsorDeliverySchoolsAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/sponsor/delivery_schools`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["ContentProvider/Sponsor"],
      }),
      getContentProviderSponsorInfos: build.query<
        GetContentProviderSponsorInfosApiResponse,
        GetContentProviderSponsorInfosApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/sponsor/infos`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["ContentProvider/Sponsor/Infos"],
      }),
      getContentProviderSponsorInfosId: build.query<
        GetContentProviderSponsorInfosIdApiResponse,
        GetContentProviderSponsorInfosIdApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/sponsor/infos/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/Sponsor/Infos"],
      }),
      fetchOneSponsorProjectAsContentProvider: build.query<
        FetchOneSponsorProjectAsContentProviderApiResponse,
        FetchOneSponsorProjectAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/sponsor/projects/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/Sponsor/Projects"],
      }),
      fetchSponsorLecturesAsContentProvider: build.query<
        FetchSponsorLecturesAsContentProviderApiResponse,
        FetchSponsorLecturesAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/sponsor/projects/lectures`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            project_id: queryArg.projectId,
          },
        }),
        providesTags: ["ContentProvider/Sponsor/Lectures"],
      }),
      fetchSponsorLectureAsContentProvider: build.query<
        FetchSponsorLectureAsContentProviderApiResponse,
        FetchSponsorLectureAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/sponsor/projects/lectures/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/Sponsor/Lectures"],
      }),
      getContentProviderNotifications: build.query<
        GetContentProviderNotificationsApiResponse,
        GetContentProviderNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/notifications`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            sent: queryArg.sent,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["ContentProvider/Notification"],
      }),
      getPblBoardInfoAsContentProvider: build.query<
        GetPblBoardInfoAsContentProviderApiResponse,
        GetPblBoardInfoAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/infos`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            preview: queryArg.preview,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["ContentProvider/PblBoardInfos"],
      }),
      postPblBoardInfoAsContentProvider: build.mutation<
        PostPblBoardInfoAsContentProviderApiResponse,
        PostPblBoardInfoAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/infos`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardInfos"],
      }),
      putPblBoardInfoAsContentProvider: build.mutation<
        PutPblBoardInfoAsContentProviderApiResponse,
        PutPblBoardInfoAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/infos`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardInfos"],
      }),
      postPblBoardItemAsContentProvider: build.mutation<
        PostPblBoardItemAsContentProviderApiResponse,
        PostPblBoardItemAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardItems"],
      }),
      putPblBoardItemAsContentProvider: build.mutation<
        PutPblBoardItemAsContentProviderApiResponse,
        PutPblBoardItemAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardItems"],
      }),
      deletePblBoardItemAsContentProvider: build.mutation<
        DeletePblBoardItemAsContentProviderApiResponse,
        DeletePblBoardItemAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardItems"],
      }),
      getPblBoardCampaignsAsContentProvider: build.query<
        GetPblBoardCampaignsAsContentProviderApiResponse,
        GetPblBoardCampaignsAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/campaigns`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["ContentProvider/PblBoardCampaigns"],
      }),
      postPblBoardCampaignAsContentProvider: build.mutation<
        PostPblBoardCampaignAsContentProviderApiResponse,
        PostPblBoardCampaignAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/campaigns`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardCampaigns"],
      }),
      getPblBoardCampaignAsContentProvider: build.query<
        GetPblBoardCampaignAsContentProviderApiResponse,
        GetPblBoardCampaignAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/campaigns/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/PblBoardCampaigns"],
      }),
      putPblBoardCampaignAsContentProvider: build.mutation<
        PutPblBoardCampaignAsContentProviderApiResponse,
        PutPblBoardCampaignAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/campaigns/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardCampaigns"],
      }),
      deletePblBoardCampaignAsContentProvider: build.mutation<
        DeletePblBoardCampaignAsContentProviderApiResponse,
        DeletePblBoardCampaignAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/campaigns/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardCampaigns"],
      }),
      getPblBoardCampaignRequestAsContentProvider: build.query<
        GetPblBoardCampaignRequestAsContentProviderApiResponse,
        GetPblBoardCampaignRequestAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/campaigns/${queryArg.campaignId}/campaign_requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_status: queryArg.searchStatus,
          },
        }),
        providesTags: ["ContentProvider/PblBoardCampaignRequests"],
      }),
      putPblBoardCampaignRequestAsContentProvider: build.mutation<
        PutPblBoardCampaignRequestAsContentProviderApiResponse,
        PutPblBoardCampaignRequestAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/campaigns/${queryArg.campaignId}/campaign_requests/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardCampaignRequests"],
      }),
      putPblBoardCampaignRequestSequenceAsContentProvider: build.mutation<
        PutPblBoardCampaignRequestSequenceAsContentProviderApiResponse,
        PutPblBoardCampaignRequestSequenceAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/campaigns/${queryArg.campaignId}/campaign_requests/${queryArg.id}/sequence`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardCampaignRequests"],
      }),
      getPblBoardArticlesAsContentProvider: build.query<
        GetPblBoardArticlesAsContentProviderApiResponse,
        GetPblBoardArticlesAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/articles`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["ContentProvider/PblBoardArticles"],
      }),
      getPblBoardArticlesWordCloudAsContentProvider: build.query<
        GetPblBoardArticlesWordCloudAsContentProviderApiResponse,
        GetPblBoardArticlesWordCloudAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/articles/word_cloud`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            list_len: queryArg.listLen,
            offset: queryArg.offset,
            min_num_of_char: queryArg.minNumOfChar,
          },
        }),
        providesTags: ["ContentProvider/PblBoardArticles"],
      }),
      getPblBoardArticleAsContentProvider: build.query<
        GetPblBoardArticleAsContentProviderApiResponse,
        GetPblBoardArticleAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/articles/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/PblBoardArticles"],
      }),
      postPblBoardContentAsContentProvider: build.mutation<
        PostPblBoardContentAsContentProviderApiResponse,
        PostPblBoardContentAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/contents`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardContents"],
      }),
      getPblBoardContentAsContentProvider: build.query<
        GetPblBoardContentAsContentProviderApiResponse,
        GetPblBoardContentAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/contents/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/PblBoardContents"],
      }),
      putPblBoardContentAsContentProvider: build.mutation<
        PutPblBoardContentAsContentProviderApiResponse,
        PutPblBoardContentAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/contents/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardContents"],
      }),
      deletePblBoardContentAsContentProvider: build.mutation<
        DeletePblBoardContentAsContentProviderApiResponse,
        DeletePblBoardContentAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/contents/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardContents"],
      }),
      getPblBoardFootersAsContentProvider: build.query<
        GetPblBoardFootersAsContentProviderApiResponse,
        GetPblBoardFootersAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/footers`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["ContentProvider/PblBoardFooters"],
      }),
      postPblBoardFooterAsContentProvider: build.mutation<
        PostPblBoardFooterAsContentProviderApiResponse,
        PostPblBoardFooterAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/footers`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardFooters"],
      }),
      putPblBoardFooterAsContentProvider: build.mutation<
        PutPblBoardFooterAsContentProviderApiResponse,
        PutPblBoardFooterAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/footers/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardFooters"],
      }),
      deletePblBoardFooterAsContentProvider: build.mutation<
        DeletePblBoardFooterAsContentProviderApiResponse,
        DeletePblBoardFooterAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/footers/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardFooters"],
      }),
      putPblBoardFooterSequenceAsContentProvider: build.mutation<
        PutPblBoardFooterSequenceAsContentProviderApiResponse,
        PutPblBoardFooterSequenceAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/footers/${queryArg.id}/sequence`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardFooters"],
      }),
      postPblBoardFooterItemAsContentProvider: build.mutation<
        PostPblBoardFooterItemAsContentProviderApiResponse,
        PostPblBoardFooterItemAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/footers/${queryArg.footerId}/footer_items`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardFooterItems"],
      }),
      putPblBoardFooterItemAsContentProvider: build.mutation<
        PutPblBoardFooterItemAsContentProviderApiResponse,
        PutPblBoardFooterItemAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/footers/${queryArg.footerId}/footer_items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardFooterItems"],
      }),
      deletePblBoardFooterItemAsContentProvider: build.mutation<
        DeletePblBoardFooterItemAsContentProviderApiResponse,
        DeletePblBoardFooterItemAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/pbl/board/items/${queryArg.itemId}/footers/${queryArg.footerId}/footer_items/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/PblBoardFooterItems"],
      }),
      getFeedbackTemplatesAsContentProvider: build.query<
        GetFeedbackTemplatesAsContentProviderApiResponse,
        GetFeedbackTemplatesAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/feedback_templates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["ContentProvider/FeedbackTemplates"],
      }),
      postAttachedImageAsContentProvider: build.mutation<
        PostAttachedImageAsContentProviderApiResponse,
        PostAttachedImageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/attached_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["ContentProvider/AttachedImages"],
      }),
      getAttachedImageAsContentProvider: build.query<
        GetAttachedImageAsContentProviderApiResponse,
        GetAttachedImageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/attached_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["ContentProvider/AttachedImages"],
      }),
      putAttachedImageAsContentProvider: build.mutation<
        PutAttachedImageAsContentProviderApiResponse,
        PutAttachedImageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/attached_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["ContentProvider/AttachedImages"],
      }),
      deleteAttachedImageAsContentProvider: build.mutation<
        DeleteAttachedImageAsContentProviderApiResponse,
        DeleteAttachedImageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/attached_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["ContentProvider/AttachedImages"],
      }),
      getGogoRequestsAsContentProvider: build.query<
        GetGogoRequestsAsContentProviderApiResponse,
        GetGogoRequestsAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            request_type: queryArg.requestType,
            status: queryArg.status,
            company_id: queryArg.companyId,
          },
        }),
        providesTags: ["ContentProvider/Gogo/Request"],
      }),
      getGogoRequestAsContentProvider: build.query<
        GetGogoRequestAsContentProviderApiResponse,
        GetGogoRequestAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/requests/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/Gogo/Request"],
      }),
      putGogoRequestAsContentProvider: build.mutation<
        PutGogoRequestAsContentProviderApiResponse,
        PutGogoRequestAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/requests/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putGogoRequestRequestBodyAsContentProvider,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/Request"],
      }),
      getGogoMessagesAsContentProvider: build.query<
        GetGogoMessagesAsContentProviderApiResponse,
        GetGogoMessagesAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/requests/${queryArg.requestId}/messages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["ContentProvider/Gogo/Message"],
      }),
      postGogoMessageAsContentProvider: build.mutation<
        PostGogoMessageAsContentProviderApiResponse,
        PostGogoMessageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/requests/${queryArg.requestId}/messages`,
          method: "POST",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { categorizable_type: queryArg.categorizableType },
        }),
        invalidatesTags: ["ContentProvider/Gogo/Message"],
      }),
      putGogoMessageAsContentProvider: build.mutation<
        PutGogoMessageAsContentProviderApiResponse,
        PutGogoMessageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/requests/${queryArg.requestId}/messages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { categorizable_type: queryArg.categorizableType },
        }),
        invalidatesTags: ["ContentProvider/Gogo/Message"],
      }),
      deleteGogoMessageAsContentProvider: build.mutation<
        DeleteGogoMessageAsContentProviderApiResponse,
        DeleteGogoMessageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/requests/${queryArg.requestId}/messages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { categorizable_type: queryArg.categorizableType },
        }),
        invalidatesTags: ["ContentProvider/Gogo/Message"],
      }),
      getGogoRequestUnreadCountsAsContentProvider: build.query<
        GetGogoRequestUnreadCountsAsContentProviderApiResponse,
        GetGogoRequestUnreadCountsAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/requests/unread_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { request_ids: queryArg.requestIds },
        }),
        providesTags: ["ContentProvider/Gogo/Request"],
      }),
      getGogoMessageReadAsContentProvider: build.mutation<
        GetGogoMessageReadAsContentProviderApiResponse,
        GetGogoMessageReadAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/requests/${queryArg.requestId}/messages/read`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { categorizable_type: queryArg.categorizableType },
        }),
        invalidatesTags: ["ContentProvider/Gogo/Message"],
      }),
      getGogoProgramsAsContentProvider: build.query<
        GetGogoProgramsAsContentProviderApiResponse,
        GetGogoProgramsAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            is_managed: queryArg.isManaged,
            is_requested: queryArg.isRequested,
          },
        }),
        providesTags: ["ContentProvider/Gogo/Program"],
      }),
      postGogoProgramAsContentProvider: build.mutation<
        PostGogoProgramAsContentProviderApiResponse,
        PostGogoProgramAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs`,
          method: "POST",
          body: queryArg.schema,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/Program"],
      }),
      getGogoProgramAsContentProvider: build.query<
        GetGogoProgramAsContentProviderApiResponse,
        GetGogoProgramAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/Gogo/Program"],
      }),
      putGogoProgramAsContentProvider: build.mutation<
        PutGogoProgramAsContentProviderApiResponse,
        PutGogoProgramAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.id}`,
          method: "PUT",
          body: queryArg.schema,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/Program"],
      }),
      deleteGogoProgramAsContentProvider: build.mutation<
        DeleteGogoProgramAsContentProviderApiResponse,
        DeleteGogoProgramAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/Program"],
      }),
      getGogoProgramRequestAsContentProvider: build.query<
        GetGogoProgramRequestAsContentProviderApiResponse,
        GetGogoProgramRequestAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.programId}/program_requests/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/Gogo/ProgramRequest"],
      }),
      putGogoProgramRequestAsContentProvider: build.mutation<
        PutGogoProgramRequestAsContentProviderApiResponse,
        PutGogoProgramRequestAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.programId}/program_requests/${queryArg.id}`,
          method: "PUT",
          body: queryArg.schema,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/ProgramRequest"],
      }),
      deleteGogoProgramRequestAsContentProvider: build.mutation<
        DeleteGogoProgramRequestAsContentProviderApiResponse,
        DeleteGogoProgramRequestAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.programId}/program_requests/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/ProgramRequest"],
      }),
      getGogoProgramRequestMessagesAsContentProvider: build.query<
        GetGogoProgramRequestMessagesAsContentProviderApiResponse,
        GetGogoProgramRequestMessagesAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["ContentProvider/Gogo/ProgramRequestMessage"],
      }),
      postGogoProgramRequestMessageAsContentProvider: build.mutation<
        PostGogoProgramRequestMessageAsContentProviderApiResponse,
        PostGogoProgramRequestMessageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages`,
          method: "POST",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/ProgramRequestMessage"],
      }),
      putGogoProgramRequestMessageAsContentProvider: build.mutation<
        PutGogoProgramRequestMessageAsContentProviderApiResponse,
        PutGogoProgramRequestMessageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/ProgramRequestMessage"],
      }),
      deleteGogoProgramRequestMessageAsContentProvider: build.mutation<
        DeleteGogoProgramRequestMessageAsContentProviderApiResponse,
        DeleteGogoProgramRequestMessageAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/ProgramRequestMessage"],
      }),
      getGogoProgramRequestMessageReadAsContentProvider: build.mutation<
        GetGogoProgramRequestMessageReadAsContentProviderApiResponse,
        GetGogoProgramRequestMessageReadAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages/read`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/ProgramRequestMessage"],
      }),
      postGogoCompanyInformationAsContentProvider: build.mutation<
        PostGogoCompanyInformationAsContentProviderApiResponse,
        PostGogoCompanyInformationAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/company_informations`,
          method: "POST",
          body: queryArg.gogoCompanyInformationRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/CompanyInformation"],
      }),
      getGogoCompanyInformationAsContentProvider: build.query<
        GetGogoCompanyInformationAsContentProviderApiResponse,
        GetGogoCompanyInformationAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/company_informations`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["ContentProvider/Gogo/CompanyInformation"],
      }),
      putGogoCompanyInformationAsContentProvider: build.mutation<
        PutGogoCompanyInformationAsContentProviderApiResponse,
        PutGogoCompanyInformationAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/company_informations`,
          method: "PUT",
          body: queryArg.gogoCompanyInformationRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/CompanyInformation"],
      }),
      deleteGogoCompanyInformationAsContentProvider: build.mutation<
        DeleteGogoCompanyInformationAsContentProviderApiResponse,
        DeleteGogoCompanyInformationAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/company_informations`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["ContentProvider/Gogo/CompanyInformation"],
      }),
      getGogoProgramPreferredDatesAsContentProvider: build.query<
        GetGogoProgramPreferredDatesAsContentProviderApiResponse,
        GetGogoProgramPreferredDatesAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/program_preferred_dates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            company_id: queryArg.companyId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["ContentProvider/Gogo/ProgramPreferredDate"],
      }),
      getGogoProgramRequestsAsContentProvider: build.query<
        GetGogoProgramRequestsAsContentProviderApiResponse,
        GetGogoProgramRequestsAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/program_preferred_dates/${queryArg.programPreferredDateId}/program_requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            company_id: queryArg.companyId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["ContentProvider/Gogo/ProgramRequest"],
      }),
      postGogoProgramRequestAsContentProvider: build.mutation<
        PostGogoProgramRequestAsContentProviderApiResponse,
        PostGogoProgramRequestAsContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/content_provider/gogo/program_preferred_dates/${queryArg.programPreferredDateId}/program_requests`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { company_id: queryArg.companyId },
        }),
        invalidatesTags: ["ContentProvider/Gogo/ProgramRequest"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as contentProviderApi };
export type GetArticlesAsContentProviderApiResponse = /** status 200 OK */ {
  articles: ArticleBase[];
  total_count: number;
};
export type GetArticlesAsContentProviderApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
};
export type FetchArticleWordCloudAsContentProviderApiResponse =
  /** status 200 OK */ WordCloudBase;
export type FetchArticleWordCloudAsContentProviderApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  listLen?: number;
  offset?: number;
  minNumOfChar?: number;
};
export type GetArticleAsContentProviderApiResponse =
  /** status 200 OK */ ArticleDetailBase;
export type GetArticleAsContentProviderApiArg = {
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type GetArticleCommentsAsContentProviderApiResponse =
  /** status 200 OK */ {
    article_comments: ArticleComment[];
    total_count: number;
  };
export type GetArticleCommentsAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
};
export type PostArticleCommentsAsContentProviderApiResponse =
  /** status 201 Created */ ArticleComment;
export type PostArticleCommentsAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
    comment_images?: Blob[];
  };
};
export type GetArticleCommentAsContentProviderApiResponse =
  /** status 200 OK */ ArticleComment;
export type GetArticleCommentAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  /** 探究成果物のコメントID */
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type PutArticleCommentAsContentProviderApiResponse =
  /** status 202 Accepted */ ArticleComment;
export type PutArticleCommentAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  /** 探究成果物のコメントID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
  };
};
export type DeleteArticleCommentAsContentProviderApiResponse =
  /** status 200 OK */ {
    message?: string;
  };
export type DeleteArticleCommentAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  /** 探究成果物のコメントID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostArticleCommentImageAsContentProviderApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostArticleCommentImageAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  /** 探究成果物のコメントID */
  commentId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    file: Blob;
  };
};
export type DeleteArticleCommentImageAsContentProviderApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type DeleteArticleCommentImageAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  /** 探究成果物のコメントID */
  commentId: string;
  /** 探究成果物のコメント画像 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetArticleLikesAsContentProviderApiResponse = /** status 200 OK */ {
  article_likes: ArticleLike[];
  total_count: number;
};
export type GetArticleLikesAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
};
export type PostArticleLikesAsContentProviderApiResponse =
  /** status 201 Created */ ArticleLike;
export type PostArticleLikesAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type DeleteArticleLikeAsContentProviderApiResponse =
  /** status 200 OK */ {
    message?: string;
  };
export type DeleteArticleLikeAsContentProviderApiArg = {
  /** 探究成果物のID */
  articleId: string;
  /** 探究成果物に対するいいねのID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSponsorDeliverySchoolsAsContentProviderApiResponse =
  /** status 200 OK */ {
    delivery_schools: SponsorSchoolBase[];
    total_count: number;
  };
export type GetSponsorDeliverySchoolsAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetContentProviderSponsorInfosApiResponse = /** status 200 OK */ {
  sponsors: SponsorInfoListBase[];
  total_count: number;
};
export type GetContentProviderSponsorInfosApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: SponsorInfosSearchType;
  searchWord?: string;
};
export type GetContentProviderSponsorInfosIdApiResponse =
  /** status 200 OK */ SponsorInfoDetailsBase;
export type GetContentProviderSponsorInfosIdApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchOneSponsorProjectAsContentProviderApiResponse =
  /** status 200 OK */ SponsorProjectDetailsBase;
export type FetchOneSponsorProjectAsContentProviderApiArg = {
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorLecturesAsContentProviderApiResponse =
  /** status 200 OK */ {
    project_lectures: SponsorLectureDetailsBase[];
    total_count: number;
  };
export type FetchSponsorLecturesAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  projectId: string;
};
export type FetchSponsorLectureAsContentProviderApiResponse =
  /** status 200 OK */ SponsorLectureDetailsBase;
export type FetchSponsorLectureAsContentProviderApiArg = {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetContentProviderNotificationsApiResponse = /** status 200 OK */ {
  notifications?: (NotificationBase & {
    read?: boolean;
  })[];
  total_count?: number;
};
export type GetContentProviderNotificationsApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  sent?: string;
  perPage?: number;
  page?: number;
};
export type GetPblBoardInfoAsContentProviderApiResponse =
  /** status 200 OK */ PblBoardInfoBase;
export type GetPblBoardInfoAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** 文字列のtrueを設定をすると探究成果一覧を取得する */
  preview?: string;
  /** 探究成果一覧のページング */
  page?: number;
  /** 探究成果一覧のページング */
  perPage?: number;
};
export type PostPblBoardInfoAsContentProviderApiResponse =
  /** status 201 Created */ PblBoardInfoBase;
export type PostPblBoardInfoAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardInfoAsContentProviderApiResponse =
  /** status 202 Accepted */ PblBoardInfoBase;
export type PutPblBoardInfoAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    event: PblBoardInfoEvent;
  };
};
export type PostPblBoardItemAsContentProviderApiResponse =
  /** status 201 Created */ PblBoardItemBaseAsShow;
export type PostPblBoardItemAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    content_type: PblBoardItemContentType;
    sequence?: number;
  };
};
export type PutPblBoardItemAsContentProviderApiResponse =
  /** status 202 Accepted */ PblBoardItemBaseAsShow;
export type PutPblBoardItemAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    content_type: PblBoardItemContentType;
    sequence?: number;
  };
};
export type DeletePblBoardItemAsContentProviderApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardItemAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblBoardCampaignsAsContentProviderApiResponse =
  /** status 200 OK */ {
    board_campaigns: PblBoardCampaignList[];
    total_count: number;
  };
export type GetPblBoardCampaignsAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostPblBoardCampaignAsContentProviderApiResponse =
  /** status 201 Created */ PblBoardCampaignBase;
export type PostPblBoardCampaignAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    description?: string;
    start_date: string;
    end_date: string;
    status: PblBoardCampaignStatus;
  };
};
export type GetPblBoardCampaignAsContentProviderApiResponse =
  /** status 200 OK */ PblBoardCampaignBase;
export type GetPblBoardCampaignAsContentProviderApiArg = {
  /** 探究ボードの探究成果募集 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardCampaignAsContentProviderApiResponse =
  /** status 202 Accepted */ PblBoardCampaignBase;
export type PutPblBoardCampaignAsContentProviderApiArg = {
  /** 探究ボードの探究成果募集 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    description?: string;
    start_date: string;
    end_date: string;
    event: PblBoardCampaignEvent;
  };
};
export type DeletePblBoardCampaignAsContentProviderApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardCampaignAsContentProviderApiArg = {
  /** 探究ボードの探究成果募集 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblBoardCampaignRequestAsContentProviderApiResponse =
  /** status 200 OK */ {
    board_campaign_requests: PblBoardCampaignRequestList[];
    total_count: number;
  };
export type GetPblBoardCampaignRequestAsContentProviderApiArg = {
  /** 探究ボードの探究成果募集 ID */
  campaignId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchStatus?: PblBoardCampaignRequestStatus;
};
export type PutPblBoardCampaignRequestAsContentProviderApiResponse =
  /** status 202 Accepted */ PblBoardCampaignRequestList;
export type PutPblBoardCampaignRequestAsContentProviderApiArg = {
  /** 探究ボードの探究成果募集 ID */
  campaignId: string;
  /** 探究ボードの探究成果募集リクエスト ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    event: PblBoardCampaignRequestEvent;
  };
};
export type PutPblBoardCampaignRequestSequenceAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardCampaignRequestSequenceAsContentProviderApiArg = {
  /** 探究ボードの探究成果募集 ID */
  campaignId: string;
  /** 探究ボードの探究成果募集リクエスト ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sequence: number;
  };
};
export type GetPblBoardArticlesAsContentProviderApiResponse =
  /** status 200 OK */ {
    articles: PblBoardArticleBase[];
    total_count: number;
  };
export type GetPblBoardArticlesAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetPblBoardArticlesWordCloudAsContentProviderApiResponse =
  /** status 200 OK */ WordCloudBase;
export type GetPblBoardArticlesWordCloudAsContentProviderApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  listLen?: number;
  offset?: number;
  minNumOfChar?: number;
};
export type GetPblBoardArticleAsContentProviderApiResponse =
  /** status 200 OK */ ArticleDetailAsPblBoard;
export type GetPblBoardArticleAsContentProviderApiArg = {
  /** Article ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostPblBoardContentAsContentProviderApiResponse =
  /** status 201 Created */ PblBoardContentBase;
export type PostPblBoardContentAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    design_type?: PblBoardContentDesignType;
    catchphrase?: string;
    catchphrase_description?: string;
    catchphrase_image?: Blob;
    cta_name?: string;
    cta_url?: string;
    external_link?: string;
  };
};
export type GetPblBoardContentAsContentProviderApiResponse =
  /** status 200 OK */ PblBoardContentBase;
export type GetPblBoardContentAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのコンテンツ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardContentAsContentProviderApiResponse =
  /** status 202 Accepted */ PblBoardContentBase;
export type PutPblBoardContentAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのコンテンツ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    design_type?: PblBoardContentDesignType;
    catchphrase?: string;
    catchphrase_description?: string;
    catchphrase_image?: Blob;
    cta_name?: string;
    cta_url?: string;
  };
};
export type DeletePblBoardContentAsContentProviderApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardContentAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのコンテンツ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblBoardFootersAsContentProviderApiResponse =
  /** status 200 OK */ {
    board_footers: PblBoardFooterBase[];
    total_count: number;
  };
export type GetPblBoardFootersAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostPblBoardFooterAsContentProviderApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostPblBoardFooterAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    category_name: string;
    footer_items?: {
      title: string;
      url: string;
    }[];
  };
};
export type PutPblBoardFooterAsContentProviderApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardFooterAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    category_name: string;
  };
};
export type DeletePblBoardFooterAsContentProviderApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardFooterAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardFooterSequenceAsContentProviderApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardFooterSequenceAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sequence: number;
  };
};
export type PostPblBoardFooterItemAsContentProviderApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostPblBoardFooterItemAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  footerId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    url: string;
  };
};
export type PutPblBoardFooterItemAsContentProviderApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardFooterItemAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  footerId: string;
  /** 探究ボードのフッターリンク ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    url: string;
  };
};
export type DeletePblBoardFooterItemAsContentProviderApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardFooterItemAsContentProviderApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  footerId: string;
  /** 探究ボードのフッターリンク ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetFeedbackTemplatesAsContentProviderApiResponse =
  /** status 200 OK */ {
    feedback_templates: FeedbackTemplateBase[];
    total_count: number;
  };
export type GetFeedbackTemplatesAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostAttachedImageAsContentProviderApiResponse =
  /** status 201 Created */ AttachedImageBase;
export type PostAttachedImageAsContentProviderApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetAttachedImageAsContentProviderApiResponse =
  /** status 200 OK */ AttachedImageBase;
export type GetAttachedImageAsContentProviderApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PutAttachedImageAsContentProviderApiResponse =
  /** status 202 Accepted */ AttachedImageBase;
export type PutAttachedImageAsContentProviderApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteAttachedImageAsContentProviderApiResponse =
  /** status 200 Accepted */ {
    message?: string;
  };
export type DeleteAttachedImageAsContentProviderApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type GetGogoRequestsAsContentProviderApiResponse = /** status 200 OK */ {
  gogo_requests: GogoRequestListBase[];
  total_count: number;
};
export type GetGogoRequestsAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  requestType?: RequestType;
  status?: Schema;
  companyId?: string;
};
export type GetGogoRequestAsContentProviderApiResponse =
  /** status 200 OK */ GogoRequestDetailBase;
export type GetGogoRequestAsContentProviderApiArg = {
  /** Gogo::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGogoRequestAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutGogoRequestAsContentProviderApiArg = {
  /** Gogo::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putGogoRequestRequestBodyAsContentProvider: PutGogoRequestRequestBodyAsContentProvider;
};
export type GetGogoMessagesAsContentProviderApiResponse = /** status 200 OK */ {
  gogo_messages: GogoRequestListBase2[];
  total_count: number;
};
export type GetGogoMessagesAsContentProviderApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostGogoMessageAsContentProviderApiResponse =
  /** status 201 Created */ GogoRequestListBase2;
export type PostGogoMessageAsContentProviderApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type PutGogoMessageAsContentProviderApiResponse =
  /** status 202 Accepted */ GogoRequestListBase2;
export type PutGogoMessageAsContentProviderApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  /** Gogo::Message ID */
  id: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type DeleteGogoMessageAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoMessageAsContentProviderApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  /** Gogo::Message ID */
  id: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoRequestUnreadCountsAsContentProviderApiResponse =
  /** status 200 OK */ {
    unread_counts: GogoRequestUnreadCountBase[];
  };
export type GetGogoRequestUnreadCountsAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  requestIds?: string[];
};
export type GetGogoMessageReadAsContentProviderApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type GetGogoMessageReadAsContentProviderApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    unread_message_ids: string[];
  };
};
export type GetGogoProgramsAsContentProviderApiResponse = /** status 200 OK */ {
  gogo_programs: Items[];
  total_count: number;
};
export type GetGogoProgramsAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** 企業側のプログラムのみを返却 */
  isManaged?: boolean;
  /** 学校側のプログラムのみを返却 */
  isRequested?: boolean;
};
export type PostGogoProgramAsContentProviderApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostGogoProgramAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  schema: GogoProgramRequestBody;
};
export type GetGogoProgramAsContentProviderApiResponse =
  /** status 200 OK */ Schema2;
export type GetGogoProgramAsContentProviderApiArg = {
  /** Gogo::Program ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGogoProgramAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutGogoProgramAsContentProviderApiArg = {
  /** Gogo::Program ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  schema: GogoProgramRequestBody;
};
export type DeleteGogoProgramAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoProgramAsContentProviderApiArg = {
  /** Gogo::Program ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoProgramRequestAsContentProviderApiResponse =
  /** status 200 OK */ Schema3;
export type GetGogoProgramRequestAsContentProviderApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGogoProgramRequestAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutGogoProgramRequestAsContentProviderApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  schema: PutGogoProgramRequestRequestBody;
};
export type DeleteGogoProgramRequestAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoProgramRequestAsContentProviderApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoProgramRequestMessagesAsContentProviderApiResponse =
  /** status 200 OK */ {
    gogo_messages: GogoRequestListBase2[];
    total_count: number;
  };
export type GetGogoProgramRequestMessagesAsContentProviderApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostGogoProgramRequestMessageAsContentProviderApiResponse =
  /** status 201 Created */ GogoRequestListBase2;
export type PostGogoProgramRequestMessageAsContentProviderApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type PutGogoProgramRequestMessageAsContentProviderApiResponse =
  /** status 202 Accepted */ GogoRequestListBase2;
export type PutGogoProgramRequestMessageAsContentProviderApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  /** Gogo::ProgramRequestMessage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type DeleteGogoProgramRequestMessageAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoProgramRequestMessageAsContentProviderApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  /** Gogo::ProgramRequestMessage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoProgramRequestMessageReadAsContentProviderApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type GetGogoProgramRequestMessageReadAsContentProviderApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    unread_message_ids: string[];
  };
};
export type PostGogoCompanyInformationAsContentProviderApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostGogoCompanyInformationAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  gogoCompanyInformationRequestBody: GogoCompanyInformationRequestBody;
};
export type GetGogoCompanyInformationAsContentProviderApiResponse =
  /** status 200 OK */ GogoCompanyInformationBase;
export type GetGogoCompanyInformationAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGogoCompanyInformationAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutGogoCompanyInformationAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  gogoCompanyInformationRequestBody: GogoCompanyInformationRequestBody;
};
export type DeleteGogoCompanyInformationAsContentProviderApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoCompanyInformationAsContentProviderApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoProgramPreferredDatesAsContentProviderApiResponse =
  /** status 200 OK */ {
    gogo_program_preferred_dates: GogoProgramPreferredDateBase[];
    total_count: number;
  };
export type GetGogoProgramPreferredDatesAsContentProviderApiArg = {
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetGogoProgramRequestsAsContentProviderApiResponse =
  /** status 200 OK */ {
    gogo_program_requests: Items2[];
    total_count: number;
  };
export type GetGogoProgramRequestsAsContentProviderApiArg = {
  /** Gogo::ProgramPreferredDate ID */
  programPreferredDateId: string;
  /** Gogo::Linking で紐づいている学校のみ */
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostGogoProgramRequestAsContentProviderApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostGogoProgramRequestAsContentProviderApiArg = {
  /** Gogo::ProgramPreferredDate ID */
  programPreferredDateId: string;
  /** Gogo::Linking で紐づいている学校のみ */
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type ModelBase = {
  id: string;
};
export type ArticleKind = "default" | "poster";
export type ArticleStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u518D\u63D0\u51FA/\u63D0\u51FA\u5F85\u3061"
  | "\u5B8C\u4E86"
  | "\u5B8C\u4E86"
  | "\u63D0\u51FA\u6E08\u307F/\u78BA\u8A8D\u4E2D";
export type ArticleStatus =
  | "draft"
  | "correction"
  | "reviewing"
  | "published"
  | "teacher_checking";
export type ArticleAffiliateLinking = {
  affiliate_id: string;
  user?: {
    user_id?: string;
    first_name?: string;
    last_name?: string;
    student_number?: number | null;
    nickname?: string | null;
  };
};
export type CommonTagKind = "system" | "free" | "management";
export type CommonTagBase = ModelBase & {
  kind: CommonTagKind;
  name: string;
};
export type ArticleBaseCommon = ModelBase & {
  student_id: string;
  creator_student_affiliate_id?: string | null;
  student_number: number | null;
  student_name: string | null;
  student_nickname?: string | null;
  title: string;
  kind?: ArticleKind;
  status: ArticleStatusJa;
  status_code: ArticleStatus;
  created_at: string;
  created_at_with_second: string;
  updated_at: string;
  updated_at_with_second: string;
  liked?: boolean;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  company: {
    id: string;
    name: string;
  };
  students_linking: ArticleAffiliateLinking[];
  common_tags: CommonTagBase[];
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type GroupAffiliatePackageMinimalBase = ModelBase & {
  title: string;
};
export type InterviewRequestStatusJa =
  | "\u627F\u8A8D\u5F85\u3061"
  | "\u975E\u627F\u8A8D"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86"
  | "\u7121\u52B9";
export type InterviewRequestStatus =
  | "idle"
  | "non_approved"
  | "approved"
  | "completed"
  | "invalided";
export type AffiliateName = {
  affiliate_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  nickname: string | null;
};
export type HasIdAndName = {
  id: string;
  name: string;
};
export type ProfessorInfoBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    request_count?: number;
    position?: string | null;
    department?: string | null;
    description?: string | null;
    limit_request?: number | null;
    profile_picture?: string | null;
  };
};
export type InterviewRequestArticleBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  professor_affiliate: ProfessorInfoBase;
};
export type ArticleBase = ArticleBaseCommon & {
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
  interview_requests?: InterviewRequestArticleBase[] | null;
};
export type ErrorsWithKeyMessage = {
  errors: {
    key?: string;
    message?: string;
  }[];
};
export type WordCloudWord = {
  text: string;
  value: number;
};
export type WordCloudBase = {
  word_cloud_list: WordCloudWord[];
  total_count: number;
};
export type ArticleImageBase = ModelBase & {
  file?: {
    title?: string;
    thumb_url?: string;
    file_url?: string;
  };
};
export type AllowedArticleCategorizableType =
  | "Sponsor::Info"
  | "Pbl::Sponsor::Project";
export type ArticleCategorizable = ModelBase & {
  type: AllowedArticleCategorizableType;
  name: string;
  sponsor_logo: string | null;
  company_name?: string | null;
};
export type ArticleContentType =
  | "theme"
  | "background"
  | "hypothesis"
  | "survey_method"
  | "result"
  | "summary"
  | "future_issues";
export type ArticleContent = ModelBase & {
  content_type: ArticleContentType;
  body: string | null;
};
export type ArticleSponsorContent = ModelBase & {
  pbl_sponsor_article_content_template_field_id: string;
  body: string | null;
};
export type SponsorArticleContentTemplateFieldBase = ModelBase & {
  title: string;
};
export type SponsorArticleContentTemplateBase = ModelBase & {
  title: string;
  description?: string | null;
  pbl_sponsor_lecture_id?: string;
  fields?: SponsorArticleContentTemplateFieldBase[];
};
export type ArticlePoster = {
  sponsor_logo: string | null;
  categorizable?: ArticleCategorizable;
  article_contents: ArticleContent[];
  article_sponsor_contents?: ArticleSponsorContent[];
  pbl_sponsor_article_content_template?: SponsorArticleContentTemplateBase | null;
};
export type BasicUserInfo = ModelBase & {
  first_name: string;
  last_name: string;
  email: string;
  optional_email?: string | null;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  expired_date?: string | null;
  current_sign_in_at?: string | null;
  last_sign_in_at?: string | null;
  linked_project_count?: number | null;
  affiliate_id?: string | null;
  nickname: string | null;
};
export type CompanyBase = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
};
export type Role =
  | "admin"
  | "super_owner"
  | "owner"
  | "teacher"
  | "student"
  | "reviewer"
  | "content_provider";
export type RoleJa =
  | "SV\u7BA1\u7406\u8005"
  | "\u89AA\u7BA1\u7406\u8005"
  | "\u7BA1\u7406\u8005"
  | "\u6559\u8077\u54E1"
  | "\u751F\u5F92"
  | "\u6DFB\u524A\u5229\u7528\u8005"
  | "\u30B3\u30F3\u30C6\u30F3\u30C4\u4E8B\u696D\u8005";
export type RoleBase = ModelBase & {
  name: Role;
  jp_name: RoleJa;
};
export type AffiliateBase = {
  affiliate_id: string;
  user?: BasicUserInfo;
  company?: CompanyBase;
  role?: RoleBase;
};
export type GroupAffiliatePackageBase = ModelBase & {
  title: string;
  description?: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateBase;
  affiliates: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    grade?: string;
    nickname?: string;
  }[];
  assign_teacher_affiliates?: AffiliateName[];
};
export type TeacherSponsorProjectListBase = ModelBase & {
  sponsor_info_id: string;
  name: string;
  company_name: string;
};
export type ArticleDetailBase = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type ArticleCommentImageBase = ModelBase & {
  title: string | null;
  url: string | null;
};
export type ArticleComment = ModelBase & {
  article_id: string;
  created_at: string;
  body: string;
  commentor_id: string | null;
  commentor_company_name?: string | null;
  commenter_name?: string | null;
  commenter_nickname?: string | null;
  commentor_affiliate_id?: string | null;
  role?: RoleBase;
  article_comment_images?: ArticleCommentImageBase[];
};
export type ArticleLike = ModelBase & {
  article_id: string;
  created_at: string;
};
export type SponsorSchoolBase = ModelBase & {
  id: string;
  name: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
};
export type SponsorSalesChannel = "direct_selling" | "selling_agency";
export type SponsorImageBase = {
  id: string;
  company_id: string;
  url: string | null;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type VideoFileBase = {
  url: string | null;
};
export type CookieInfo = {
  cloudfront_policy: string;
  cloudfront_signature: string;
  cloudfront_key_pair_id: string;
  unsigned_url: string;
};
export type SponsorVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorInfoListBase = ModelBase & {
  company_id: string;
  logo_url: string | null;
  name: string;
  description?: string;
  is_public: boolean;
  sales_channel?: SponsorSalesChannel | null;
  created_at: string;
  updated_at: string;
  project_count: number;
  image: SponsorImageBase | null;
  video_content?: SponsorVideoContentBase | null;
  tags: SponsorTagBase[] | null;
};
export type SponsorInfosSearchType = "name" | "description" | "tag";
export type SponsorInfoBase = ModelBase & {
  company_id: string;
  logo_url: string | null;
  name: string;
  description?: string;
  is_public: boolean;
  sales_channel?: SponsorSalesChannel | null;
  created_at: string;
  updated_at: string;
  project_count: number;
  image: SponsorImageBase | null;
  video_content?: SponsorVideoContentBase | null;
  tags: SponsorTagBase[] | null;
};
export type SponsorCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: {
    url?: string | null;
  };
};
export type SponsorProjectTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorLectureVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorProjectImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorProjectWorkProgressJa =
  | "\u53D7\u8B1B\u4E2D"
  | "\u53D7\u8B1B\u6E08\u307F";
export type SponsorProjectAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description?: string;
  display_allowed: boolean;
  display_in_carousel: boolean;
  created_at: string;
  updated_at: string;
  tags: SponsorProjectTagBase[] | null;
  file?: {
    url?: string | null;
  };
  video_content?: SponsorLectureVideoContentBase | null;
  image?: SponsorProjectImageBase | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
  project_bookmark?: ModelBase | null;
  project_recommend?: ModelBase | null;
};
export type SponsorInfoDetailsBase = SponsorInfoBase & {
  sponsor_logo_url: string | null;
  categories: SponsorCategoryBase[] | null;
  projects: SponsorProjectAndTagsBase[] | null;
};
export type SponsorProjectVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorProjectCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: {
    url?: string | null;
  };
};
export type SponsorLectureImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorLectureTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorLectureAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content?: SponsorLectureVideoContentBase | null;
  tags?: SponsorLectureTagBase[] | null;
};
export type SponsorProjectDetailsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description: string;
  display_allowed?: boolean;
  file?: {
    url?: string | null;
  };
  created_at: string;
  updated_at: string;
  image?: SponsorProjectImageBase | null;
  video_content?: SponsorProjectVideoContentBase | null;
  tags: SponsorProjectTagBase[] | null;
  categories: SponsorProjectCategoryBase[] | null;
  lectures: SponsorLectureAndTagsBase[] | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
  lecture_viewed_count: number;
  professor_affiliate_ids?: string[] | null;
  is_recommend: boolean;
  company_name?: string;
  sponsor_logo_url?: string | null;
};
export type SponsorLectureFileBase = ModelBase & {
  company_id: string;
  file: {
    title: string | null;
    url: string | null;
  };
};
export type Status = "\u65B0\u898F" | "\u78BA\u8A8D\u6E08\u307F";
export type SponsorLectureDetailsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content: SponsorLectureVideoContentBase | null;
  tags: SponsorLectureTagBase[] | null;
  files: SponsorLectureFileBase[] | null;
  learning_progress?: {
    id?: string;
    status?: Status;
  };
  article_content_template?: {
    id: string;
    title: string;
    description?: string | null;
  } | null;
  company_name?: string;
  sponsor_logo_url?: string | null;
  project_name?: string;
};
export type NotificationBase = ModelBase & {
  title: string;
  message: string;
  resource_info: string | null;
  sender?: {
    id?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
  };
  created_at: string;
  updated_at: string;
  notified_time: string;
};
export type PblBoardInfoStatusJa =
  | "\u516C\u958B\u505C\u6B62\u4E2D"
  | "\u516C\u958B\u4E2D";
export type PblBoardItemContentTypeJa =
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8"
  | "\u63A2\u7A76\u6210\u679C\u4E00\u89A7"
  | "\u30D5\u30C3\u30BF\u30FC"
  | "\u30EF\u30FC\u30C9\u30AF\u30E9\u30A6\u30C9";
export type PblBoardContentDesignTypeJa =
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C81"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C82"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C83"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C84";
export type PblBoardContentBase = ModelBase & {
  design_type: PblBoardContentDesignTypeJa;
  catchphrase?: string;
  catchphrase_description?: string;
  catchphrase_image?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
  cta_name?: string;
  cta_url?: string;
  external_link?: string;
};
export type PblBoardFooterItemBase = ModelBase & {
  title?: string;
  url?: string;
};
export type PblBoardFooterBase = ModelBase & {
  category_name?: string;
  sequence?: number;
  board_footers?: PblBoardFooterItemBase[];
};
export type PblBoardItemBase = ModelBase & {
  content_type: PblBoardItemContentTypeJa;
  sequence: number;
  board_contents?: PblBoardContentBase[];
  board_footers?: PblBoardFooterBase[];
};
export type PblBoardArticleBase = ModelBase & {
  title: string;
  kind?: ArticleKind;
  body: string;
  cover: string | null;
  company: HasIdAndName;
  images: ArticleImageBase[];
};
export type PblBoardInfoBase = ModelBase & {
  status: PblBoardInfoStatusJa;
  created_at: string;
  updated_at: string;
  board_items: PblBoardItemBase[];
  articles: PblBoardArticleBase[];
  article_total_count: number;
  company?: {
    id: string;
    name: string;
    is_content_company: boolean;
  };
};
export type PblBoardInfoEvent = "public_info" | "private_info";
export type PblBoardItemBaseAsShow = ModelBase & {
  content_type: PblBoardItemContentTypeJa;
  sequence: number;
};
export type PblBoardItemContentType =
  | "template"
  | "pbl_list"
  | "footer"
  | "word_cloud";
export type PblBoardCampaignStatusJa =
  | "\u52DF\u96C6\u524D"
  | "\u52DF\u96C6\u4E2D"
  | "\u7D42\u4E86";
export type PblBoardCampaignList = ModelBase & {
  title: string;
  start_date: string;
  end_date: string;
  status: PblBoardCampaignStatusJa;
  unconfirmed_count?: number;
  approved_count?: number;
  non_approved_count?: number;
};
export type PblBoardCampaignBase = ModelBase & {
  title: string;
  description?: string;
  start_date: string;
  end_date: string;
  status: PblBoardCampaignStatusJa;
};
export type PblBoardCampaignStatus = "draft" | "opening" | "closed";
export type PblBoardCampaignEvent = "submit" | "cancel" | "close";
export type PblBoardCampaignRequestStatusJa =
  | "\u672A\u78BA\u8A8D"
  | "\u627F\u8A8D"
  | "\u975E\u627F\u8A8D";
export type StandardAffiliate = {
  id: string;
  first_name: string;
  last_name: string;
  company_id: string;
  company_name: string;
};
export type PblBoardCampaignRequestList = ModelBase & {
  sequence: number;
  status: PblBoardCampaignRequestStatusJa;
  article: {
    id: string;
    title: string;
  };
  teacher_affiliate: StandardAffiliate;
};
export type PblBoardCampaignRequestStatus =
  | "unconfirmed"
  | "approved"
  | "non_approved";
export type PblBoardCampaignRequestEvent = "accept" | "reject" | "cancel";
export type ArticleDetailAsPblBoard = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type PblBoardContentDesignType =
  | "template_1"
  | "template_2"
  | "template_3"
  | "template_4";
export type FeedbackTemplateCommentBase = ModelBase & {
  body: string;
};
export type FeedbackTemplateBase = ModelBase & {
  icon: {
    title: string;
    url: string;
  };
  feedback_template_comments: FeedbackTemplateCommentBase[];
};
export type AttachedImageCategorizableType =
  | "Material"
  | "Ideal::Board"
  | "Article"
  | "Pbl::Board::Content"
  | "Pbl::Sponsor::Lecture"
  | "Ideal::BoardTemplate"
  | "Interview::Message"
  | "HomeworkTemplateCustomFieldFeedback"
  | "Gogo::Message";
export type AttachedImageBase = ModelBase & {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
  file_name: string;
  file_url: string;
};
export type FileRequiredRequestBody = {
  file: Blob;
};
export type RequestType = "school_visit" | "fieldwork" | "online_lesson";
export type Schema = "idle" | "adjusted" | "decided" | "cancelled";
export type GogoRequestPreferredDateBase = ModelBase & {
  choice_number: number;
  start_time: string;
  end_time: string;
};
export type GogoRequestListBase = ModelBase & {
  request_type:
    | "\u51FA\u5F35\u6388\u696D"
    | "\u30D5\u30A3\u30FC\u30EB\u30C9\u30EF\u30FC\u30AF"
    | "\u30AA\u30F3\u30E9\u30A4\u30F3\u6388\u696D";
  request_type_code: RequestType;
  status:
    | "\u8ABF\u6574\u5F85\u3061"
    | "\u8ABF\u6574\u3059\u308B"
    | "\u78BA\u5B9A"
    | "\u5B9F\u65BD\u306A\u3057";
  status_code: Schema;
  confirm_start_time: string | null;
  confirm_end_time: string | null;
  preferred_dates: GogoRequestPreferredDateBase[];
  sender_affiliate: AffiliateName;
  company: HasIdAndName;
  theme: string | null;
};
export type GogoRequestParking = "parking_available" | "parking_unavailable";
export type GogoRequestProjector =
  | "projector_available"
  | "projector_unavailable";
export type GogoRequestInternetConnection =
  | "internet_connection_available"
  | "internet_connection_unavailable";
export type GogoRequestPhotography =
  | "photography_available"
  | "photography_unavailable";
export type GogoRequestAtmosphereJa =
  | "\u5927\u4EBA\u3057\u3044"
  | "\u7DCA\u5F35\u611F\u304C\u3042\u308B"
  | "\u76DB\u308A\u4E0A\u304C\u3063\u3066\u3044\u308B"
  | "\u548C\u6C17\u3042\u3044\u3042\u3044\u3068\u3057\u3066\u3044\u308B"
  | "\u81EA\u7531\u5954\u653E\u3067\u3042\u308B";
export type GogoRequestAtmosphere =
  | "quiet"
  | "tension"
  | "exciting"
  | "harmonious"
  | "freewheeling";
export type GogoRequestHopeJa =
  | "\u8B1B\u6F14\u4F9D\u983C"
  | "\u7DCA\u5F35\u611F\u304C\u767A\u8868\u4F1A\u3078\u306E\u53C2\u52A0\u3001\u30B3\u30E1\u30F3\u30C8\u3042\u308B"
  | "\u305D\u306E\u4ED6";
export type GogoRequestHope = "invitation" | "participation" | "other";
export type GogoRequestLearningEnvironmentJa =
  | "\u73FE\u5730\u306E\u307F"
  | "\u30EA\u30E2\u30FC\u30C8\u306E\u307F"
  | "\u3069\u3061\u3089\u3067\u3082OK";
export type GogoRequestLearningEnvironment = "local" | "remote" | "either";
export type StudentGrade =
  | "under"
  | "elementary1"
  | "elementary2"
  | "elementary3"
  | "elementary4"
  | "elementary5"
  | "elementary6"
  | "junior1"
  | "junior2"
  | "junior3"
  | "high1"
  | "high2"
  | "high3"
  | "university1"
  | "university2"
  | "university3"
  | "university4"
  | "others";
export type GogoRequestDetailBase = GogoRequestListBase & {
  sender_message: string | null;
  company_message: string | null;
  unread_message_ids: string[];
  number_of_classes: number;
  number_of_students: number;
  parking: GogoRequestParking;
  projector: GogoRequestProjector;
  internet_connection: GogoRequestInternetConnection;
  photography: GogoRequestPhotography;
  atmosphere: GogoRequestAtmosphereJa;
  atmosphere_code: GogoRequestAtmosphere;
  hope: GogoRequestHopeJa;
  hope_code: GogoRequestHope;
  learning_environment: GogoRequestLearningEnvironmentJa;
  learning_environment_code: GogoRequestLearningEnvironment;
  grade: StudentGrade;
  teacher_affiliate?: AffiliateName;
};
export type PutGogoRequestRequestBodyAsContentProvider = {
  confirm_start_time?: string;
  confirm_end_time?: string;
  company_message?: string;
  event?: "adjust" | "decide" | "cancel";
};
export type GogoMessageKind = "person" | "info";
export type AffiliateInfoBase = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  role_name: string;
  nickname: string | null;
};
export type GogoRequestListBase2 = ModelBase & {
  message: string;
  kind: GogoMessageKind;
  created_at: string;
  updated_at: string;
  sender_affiliate: AffiliateInfoBase;
  attached_images?: AttachedImageBase[];
};
export type GogoMessageCategorizableType =
  | "Gogo::Request"
  | "Gogo::ProgramRequest";
export type MessageRequiredRequestBody = {
  message: string;
};
export type GogoRequestUnreadCountBase = ModelBase & {
  count: number;
};
export type Status2 = "\u975E\u516C\u958B" | "\u516C\u958B\u200B";
export type StatusCode = "draft" | "published";
export type Items = ModelBase & {
  title: string;
  status: Status2;
  status_code: StatusCode;
  created_at: string | null;
  creator_affiliate: AffiliateName;
  company: HasIdAndName;
};
export type GogoProgramRequestBody = {
  title: string;
  event?: "close" | "release";
  number_of_classes?: number;
  number_of_students?: number;
  parking?: GogoRequestParking;
  projector?: GogoRequestProjector;
  internet_connection?: GogoRequestInternetConnection;
  photography?: GogoRequestPhotography;
  atmosphere?: GogoRequestAtmosphere;
  teacher_affiliate_id?: string;
  grade?: StudentGrade;
};
export type Schema2 = Items & {
  number_of_classes: number;
  number_of_students: number;
  parking: GogoRequestParking;
  projector: GogoRequestProjector;
  internet_connection: GogoRequestInternetConnection;
  photography: GogoRequestPhotography;
  atmosphere: GogoRequestAtmosphereJa;
  atmosphere_code: GogoRequestAtmosphere;
  grade: StudentGrade;
  teacher_affiliate?: AffiliateName;
};
export type Items2 = ModelBase & {
  status: "\u8ABF\u6574\u4E2D" | "\u8ABF\u6574\u6E08\u307F";
  status_code: "adjusting" | "adjusted";
  created_at: string | null;
  company: HasIdAndName;
};
export type Schema3 = Items2 & {
  message: string;
  unread_message_ids: string[];
};
export type PutGogoProgramRequestRequestBody = {
  message?: string;
  event?: "close";
};
export type GogoCompanyInformationVisitingLessonEvent =
  | "visiting_lesson_enable"
  | "visiting_lesson_consult"
  | "visiting_lesson_disable";
export type GogoCompanyInformationOnlineLessonEvent =
  | "online_lesson_enable"
  | "online_lesson_consult"
  | "online_lesson_disable";
export type GogoCompanyInformationFieldworkEvent =
  | "fieldwork_enable"
  | "fieldwork_consult"
  | "fieldwork_disable";
export type GogoCompanyInformationRequestBody = {
  available_capacity?: number;
  visiting_lesson_event?: GogoCompanyInformationVisitingLessonEvent;
  online_lesson_event?: GogoCompanyInformationOnlineLessonEvent;
  fieldwork_event?: GogoCompanyInformationFieldworkEvent;
};
export type GogoCompanyInformationVisitingLesson =
  | "visiting_lesson_available"
  | "visiting_lesson_negotiable"
  | "visiting_lesson_not_available";
export type GogoCompanyInformationOnlineLesson =
  | "online_lesson_available"
  | "online_lesson_negotiable"
  | "online_lesson_not_available";
export type GogoCompanyInformationFieldwork =
  | "fieldwork_available"
  | "fieldwork_negotiable"
  | "fieldwork_not_available";
export type GogoCompanyInformationBase = ModelBase & {
  visiting_lesson: GogoCompanyInformationVisitingLesson;
  online_lesson: GogoCompanyInformationOnlineLesson;
  fieldwork: GogoCompanyInformationFieldwork;
  available_capacity: number;
  company: CompanyBase & {
    projects_count?: number | null;
    project_works_count?: number | null;
  };
};
export type RequestType2 =
  | "\u51FA\u5F35\u6388\u696D"
  | "\u30D5\u30A3\u30FC\u30EB\u30C9\u30EF\u30FC\u30AF"
  | "\u30AA\u30F3\u30E9\u30A4\u30F3\u6388\u696D";
export type GogoProgramPreferredDateBase = ModelBase & {
  title: string;
  status: "\u975E\u516C\u958B" | "\u516C\u958B\u200B";
  status_code: "draft" | "published";
  start_time: string;
  end_time: string;
  created_at: string;
  request_type: RequestType2;
  request_type_code: RequestType;
  hope: GogoRequestHopeJa;
  hope_code: GogoRequestHope;
};
export const {
  useGetArticlesAsContentProviderQuery,
  useFetchArticleWordCloudAsContentProviderQuery,
  useGetArticleAsContentProviderQuery,
  useGetArticleCommentsAsContentProviderQuery,
  usePostArticleCommentsAsContentProviderMutation,
  useGetArticleCommentAsContentProviderQuery,
  usePutArticleCommentAsContentProviderMutation,
  useDeleteArticleCommentAsContentProviderMutation,
  usePostArticleCommentImageAsContentProviderMutation,
  useDeleteArticleCommentImageAsContentProviderMutation,
  useGetArticleLikesAsContentProviderQuery,
  usePostArticleLikesAsContentProviderMutation,
  useDeleteArticleLikeAsContentProviderMutation,
  useGetSponsorDeliverySchoolsAsContentProviderQuery,
  useGetContentProviderSponsorInfosQuery,
  useGetContentProviderSponsorInfosIdQuery,
  useFetchOneSponsorProjectAsContentProviderQuery,
  useFetchSponsorLecturesAsContentProviderQuery,
  useFetchSponsorLectureAsContentProviderQuery,
  useGetContentProviderNotificationsQuery,
  useGetPblBoardInfoAsContentProviderQuery,
  usePostPblBoardInfoAsContentProviderMutation,
  usePutPblBoardInfoAsContentProviderMutation,
  usePostPblBoardItemAsContentProviderMutation,
  usePutPblBoardItemAsContentProviderMutation,
  useDeletePblBoardItemAsContentProviderMutation,
  useGetPblBoardCampaignsAsContentProviderQuery,
  usePostPblBoardCampaignAsContentProviderMutation,
  useGetPblBoardCampaignAsContentProviderQuery,
  usePutPblBoardCampaignAsContentProviderMutation,
  useDeletePblBoardCampaignAsContentProviderMutation,
  useGetPblBoardCampaignRequestAsContentProviderQuery,
  usePutPblBoardCampaignRequestAsContentProviderMutation,
  usePutPblBoardCampaignRequestSequenceAsContentProviderMutation,
  useGetPblBoardArticlesAsContentProviderQuery,
  useGetPblBoardArticlesWordCloudAsContentProviderQuery,
  useGetPblBoardArticleAsContentProviderQuery,
  usePostPblBoardContentAsContentProviderMutation,
  useGetPblBoardContentAsContentProviderQuery,
  usePutPblBoardContentAsContentProviderMutation,
  useDeletePblBoardContentAsContentProviderMutation,
  useGetPblBoardFootersAsContentProviderQuery,
  usePostPblBoardFooterAsContentProviderMutation,
  usePutPblBoardFooterAsContentProviderMutation,
  useDeletePblBoardFooterAsContentProviderMutation,
  usePutPblBoardFooterSequenceAsContentProviderMutation,
  usePostPblBoardFooterItemAsContentProviderMutation,
  usePutPblBoardFooterItemAsContentProviderMutation,
  useDeletePblBoardFooterItemAsContentProviderMutation,
  useGetFeedbackTemplatesAsContentProviderQuery,
  usePostAttachedImageAsContentProviderMutation,
  useGetAttachedImageAsContentProviderQuery,
  usePutAttachedImageAsContentProviderMutation,
  useDeleteAttachedImageAsContentProviderMutation,
  useGetGogoRequestsAsContentProviderQuery,
  useGetGogoRequestAsContentProviderQuery,
  usePutGogoRequestAsContentProviderMutation,
  useGetGogoMessagesAsContentProviderQuery,
  usePostGogoMessageAsContentProviderMutation,
  usePutGogoMessageAsContentProviderMutation,
  useDeleteGogoMessageAsContentProviderMutation,
  useGetGogoRequestUnreadCountsAsContentProviderQuery,
  useGetGogoMessageReadAsContentProviderMutation,
  useGetGogoProgramsAsContentProviderQuery,
  usePostGogoProgramAsContentProviderMutation,
  useGetGogoProgramAsContentProviderQuery,
  usePutGogoProgramAsContentProviderMutation,
  useDeleteGogoProgramAsContentProviderMutation,
  useGetGogoProgramRequestAsContentProviderQuery,
  usePutGogoProgramRequestAsContentProviderMutation,
  useDeleteGogoProgramRequestAsContentProviderMutation,
  useGetGogoProgramRequestMessagesAsContentProviderQuery,
  usePostGogoProgramRequestMessageAsContentProviderMutation,
  usePutGogoProgramRequestMessageAsContentProviderMutation,
  useDeleteGogoProgramRequestMessageAsContentProviderMutation,
  useGetGogoProgramRequestMessageReadAsContentProviderMutation,
  usePostGogoCompanyInformationAsContentProviderMutation,
  useGetGogoCompanyInformationAsContentProviderQuery,
  usePutGogoCompanyInformationAsContentProviderMutation,
  useDeleteGogoCompanyInformationAsContentProviderMutation,
  useGetGogoProgramPreferredDatesAsContentProviderQuery,
  useGetGogoProgramRequestsAsContentProviderQuery,
  usePostGogoProgramRequestAsContentProviderMutation,
} = injectedRtkApi;
