import { useMediaQuery, useTheme } from "@mui/material";

const useResponsive = (): {
  isMobileView: boolean;
  isTabletView: boolean;
  isDesktopView: boolean;
  isScreenView: boolean;
  isUpperThanFullDesktop: boolean;
  isUpperThanDesktopView: boolean;
  isUpperThanMobile: boolean;
} => {
  const muiTheme = useTheme();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const isTabletView = useMediaQuery(muiTheme.breakpoints.down("md"));
  const isDesktopView = useMediaQuery(muiTheme.breakpoints.down("lg"));
  const isScreenView = useMediaQuery(muiTheme.breakpoints.down("xl"));
  const isUpperThanFullDesktop = useMediaQuery(muiTheme.breakpoints.up("lg"));
  const isUpperThanDesktopView = useMediaQuery(muiTheme.breakpoints.up("md"));
  const isUpperThanMobile = useMediaQuery(muiTheme.breakpoints.up("sm"));

  return {
    isMobileView,
    isTabletView,
    isDesktopView,
    isScreenView,
    isUpperThanFullDesktop,
    isUpperThanDesktopView,
    isUpperThanMobile,
  };
};

export default useResponsive;
