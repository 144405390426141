/* eslint-disable camelcase */
import {
  CommonNotification,
  NewNotifications,
} from "@actions/types/notification";
import DetailsIcon from "@assets/images/svg/detail-green-icon.svg?react";
import LinkifyMultipleText from "@components/UI/atoms/LinkifyMultipleText";
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { usePatchNewNotificationCountAsCommonMutation } from "@root/endpoints/TimeTactApi/remainingApi";
import clsx from "clsx";
import React from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { useHistory } from "react-router-dom";

import useNotificationAsset from "./hooks/useNotificationAsset";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "200px",
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    unread: {
      backgroundColor: theme.palette.primary.light,
    },
    read: {
      backgroundColor: theme.palette.common.white,
    },
    title: {
      fontWeight: 600,
      marginLeft: "10px",
      maxWidth: "840px",
    },
    container: {
      border: `2px solid ${theme.palette.tertiary.mainDark}`,
      color: theme.palette.tertiary.mainDark,
      height: 35,
      minWidth: 80,
      borderRadius: 8,
      fontWeight: 600,
      display: "flex",
      justifyContent: "space-evenly",
    },
    btnPosition: {
      display: "flex",
      justifyContent: "flex-end",
    },
    textStyle: {
      paddingBottom: 0,
      borderLeft: `3px solid ${theme.palette.tertiary.mainDark}`,
      paddingLeft: "15px",
    },
    iconBox: {
      display: "flex",
      alignItems: "center",
      paddingBottom: theme.spacing(2),
    },
    icon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }),
);

export type Props = {
  notification?: NewNotifications | CommonNotification;
  limitedNotification?: NewNotifications | CommonNotification;
  handleClose: () => void;
  changeNewNotificationState: () => void;
  postNotifications?: ReturnType<
    typeof usePatchNewNotificationCountAsCommonMutation
  >[0];
  isLimitedNotificationsModal?: boolean;
};

const NotificationCard: React.FC<Props> = ({
  notification,
  limitedNotification,
  handleClose,
  changeNewNotificationState,
  postNotifications,
  isLimitedNotificationsModal,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    states: { displayObj, link, text },
    components: { icon },
  } = useNotificationAsset({ notification, limitedNotification });

  const handleOnClick = () => {
    if (
      (limitedNotification && !limitedNotification?.read) ||
      (notification && !notification?.read)
    ) {
      postNotifications?.({});
    }
  };

  const RedirectToPage = (e: React.MouseEvent) => {
    if (!link) {
      return;
    }

    changeNewNotificationState();
    handleOnClick();
    handleClose();
    history.push(link);
  };

  return (
    <Card
      className={clsx(
        classes.root,
        notification?.read ||
          limitedNotification?.read ||
          (isLimitedNotificationsModal && limitedNotification?.checked)
          ? classes.read
          : classes.unread,
      )}
      data-cy="notification-card"
    >
      <CardContent>
        <Box className={classes.iconBox}>
          <Box className={classes.icon}>{icon}</Box>
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            data-cy="notification-card-title"
          >
            {displayObj.title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.iconBox} data-cy="notification-card-sender">
            {displayObj.sender_name && (
              <>
                <FaRegUserCircle style={{ marginRight: "5px" }} />
                <Typography>{displayObj.sender_name}</Typography>
              </>
            )}
          </Box>
          <Box className={classes.iconBox}>
            <MdAccessTime style={{ marginRight: "5px" }} />
            <Typography>{displayObj.notified_time}</Typography>
          </Box>
        </Box>
        <Box className={classes.textStyle}>
          <LinkifyMultipleText text={displayObj.message} />
        </Box>
        {link && (
          <Box className={classes.btnPosition}>
            <SingleButton
              startIcon={<DetailsIcon />}
              onClick={RedirectToPage}
              className={classes.container}
            >
              {text}
            </SingleButton>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default NotificationCard;
