import { ITEMS_PER_PAGE } from "@constants/page";
import {
  GetDailyLogsAsTeacherApiResponse,
  useGetDailyLogsAsTeacherQuery,
} from "@root/endpoints/TimeTactApi/teacherApi";
import dayjs from "dayjs";
import React, { useState } from "react";

type LogsMainState = {
  dailyStudyLogsData?: GetDailyLogsAsTeacherApiResponse;
  isFetching: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

type LogsMainProps = {
  lastWeek?: boolean;
};

const useLogsMain = (props: LogsMainProps): LogsMainState => {
  const [page, setPage] = useState<number>(1);
  const { lastWeek } = props;
  const today = dayjs();
  const thisWeekStart = today.subtract(7, "day").format("YYYY-MM-DD");
  const thisWeekEnd = today.format("YYYY-MM-DD");
  const lastWeekStart = today.subtract(14, "day").format("YYYY-MM-DD");
  const lastWeekEnd = today.subtract(7, "day").format("YYYY-MM-DD");

  const { data: dailyStudyLogsData, isFetching } =
    useGetDailyLogsAsTeacherQuery(
      {
        perPage: ITEMS_PER_PAGE,
        page,
        from: lastWeek ? lastWeekStart : thisWeekStart,
        to: lastWeek ? lastWeekEnd : thisWeekEnd,
      },
      { refetchOnMountOrArgChange: true },
    );

  return { dailyStudyLogsData, isFetching, setPage, page };
};

export default useLogsMain;
