/* eslint-disable camelcase */
import {
  Api,
  ArticleAsTeacher,
  ArticleStatus,
  FetchArticlesAsTeacherParams,
  FetchArticleStatsAsTeacherParams,
  FetchArticleWordCloudAsTeacherParams,
  GetArticleSubmitAsTeacherParams,
  WordCloudBase,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RoleApiFunctions } from "@root/types/wordCloud";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticlesAsTeacherArg {
  params: FetchArticlesAsTeacherParams;
}

export const fetchArticlesStatsAsTeacher = createAsyncThunk<
  GetResponseType<Api["teacher"]["fetchArticleStatsAsTeacher"]>,
  FetchArticleStatsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/articles/stats", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.fetchArticleStatsAsTeacher(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchArticlesAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/article/fetch",
  async (arg: FetchArticlesAsTeacherArg, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchArticlesAsTeacher(arg.params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchArticleAsTeacher = createAsyncThunk<
  ArticleAsTeacher,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/article/fetchOne",
  async (articleId: string, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchArticleAsTeacher(articleId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdateArticleAsTeacherParams {
  new_status: ArticleStatus.Correction | ArticleStatus.Published;
  comment?: string;
  content_company_ids?: string[];
  display_student_names_for_content_provider?: boolean;
  display_comments_for_content_provider?: boolean;
}

export interface UpdateArticleAsTeacherArg {
  articleId: string;
  params: UpdateArticleAsTeacherParams;
}

export const updateArticleAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/article/update",
  async (arg: UpdateArticleAsTeacherArg, { rejectWithValue }) => {
    const { articleId, params } = arg;
    try {
      const response = await api.teacher.updateArticleAsTeacher(articleId, {
        new_status: params.new_status,
        comment: typeof params.comment !== "undefined" ? params.comment : null,
        content_company_ids:
          typeof params.content_company_ids !== "undefined"
            ? params.content_company_ids
            : null,
        display_student_names_for_content_provider:
          params.display_student_names_for_content_provider,
        display_comments_for_content_provider:
          params.display_comments_for_content_provider,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchWordCloudDataAsTeacher = createAsyncThunk<
  WordCloudBase,
  FetchArticleWordCloudAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/word_cloud/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.fetchArticleWordCloudAsTeacher(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getArticleSubmitAsTeacher = createAsyncThunk<
  any,
  GetArticleSubmitAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/articles/submit", async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(`/teacher/articles/submit`, {
      params,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetModalState = createAction("teacher/article/resetModalState");

export const resetArticleRelatedData = createAction("teacher/article/resetOne");

type TeacherActionsType = {
  teacher: RoleApiFunctions;
};
export const teacherWordCloudActions: TeacherActionsType = {
  teacher: {
    fetchWordCloudDataByRole: fetchWordCloudDataAsTeacher,
  },
};
