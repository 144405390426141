/* eslint-disable camelcase */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Api,
  GetPblBoardArticlesAsContentProviderParams,
  GetPblBoardArticlesWordCloudAsContentProviderParams,
  GetPblBoardInfoAsContentProviderParams,
  PblBoardArticleBase,
  PblBoardContentBase,
  PblBoardInfoBase,
  PblBoardInfoEvent,
  PblBoardItemBaseAsShow,
  PblBoardItemContentType,
  WordCloudBase,
} from "@root/lib/Api";
import { http } from "@root/lib/http";
import { RoleApiFunctions } from "@root/types/pblBoard";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblBoardInfoAsContentProvider = createAsyncThunk<
  PblBoardInfoBase,
  GetPblBoardInfoAsContentProviderParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_infos/fetch", async (params, { rejectWithValue }) => {
  try {
    const response =
      await api.contentProvider.getPblBoardInfoAsContentProvider(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetPblBoardArticlesAsContentProvider = createAsyncThunk<
  { articles: PblBoardArticleBase[]; total_count: number },
  GetPblBoardArticlesAsContentProviderParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "content_provider/board_articles/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getPblBoardArticlesAsContentProvider(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchArticleWordCloudAsContentProvider = createAsyncThunk<
  WordCloudBase,
  GetPblBoardArticlesWordCloudAsContentProviderParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "content_provider/board_word_cloud/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getPblBoardArticlesWordCloudAsContentProvider(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createPblBoardInfoAsContentProvider = createAsyncThunk<
  PblBoardInfoBase,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_infos/post", async (_, { rejectWithValue }) => {
  try {
    const response =
      await api.contentProvider.postPblBoardInfoAsContentProvider();
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const putPblBoardInfoAsContentProvider = createAsyncThunk<
  PblBoardInfoBase,
  { event: PblBoardInfoEvent },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_infos/put", async (params, { rejectWithValue }) => {
  try {
    const response =
      await api.contentProvider.putPblBoardInfoAsContentProvider(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createPblBoardItemAsContentProvider = createAsyncThunk<
  PblBoardItemBaseAsShow,
  { content_type: PblBoardItemContentType },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_item/post", async (params, { rejectWithValue }) => {
  try {
    const response =
      await api.contentProvider.postPblBoardItemAsContentProvider(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface CreateBoardContentType {
  itemId: string;
  templateType?: string;
  catchCopy?: string;
  catchCopyOverView?: string;
  catchCopyImage?: File;
  ctaTitle?: string;
  ctaUrl?: string;
  external_link?: string;
}
export const createPblBoardContentsAsContentProvider = createAsyncThunk<
  PblBoardContentBase,
  CreateBoardContentType,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "content_provider/board_content/post",
  async (params, { rejectWithValue }) => {
    try {
      const {
        itemId,
        templateType,
        catchCopy,
        catchCopyOverView,
        catchCopyImage,
        ctaTitle,
        ctaUrl,
        external_link,
      } = params;
      const fd = new FormData();

      fd.set("design_type", templateType as string);
      fd.set("catchphrase", catchCopy as string);
      fd.set("catchphrase_description", catchCopyOverView as string);
      fd.set("catchphrase_image", catchCopyImage as File);
      fd.set("cta_name", ctaTitle as string);
      fd.set("cta_url", ctaUrl as string);
      fd.set("external_link", external_link as string);

      const response = await http.post(
        `/content_provider/pbl/board/items/${itemId}/contents`,
        fd,
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface EditBoardContentType {
  itemId: string;
  contentId: string;
  templateType?: string;
  catchCopy?: string;
  catchCopyOverView?: string;
  catchCopyImage?: File;
  ctaTitle?: string;
  ctaUrl?: string;
  external_link?: string;
}

export const editPblBoardContentsAsContentProvider = createAsyncThunk<
  PblBoardContentBase,
  EditBoardContentType,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_content/put", async (params, { rejectWithValue }) => {
  try {
    const {
      itemId,
      contentId,
      templateType,
      catchCopy,
      catchCopyOverView,
      catchCopyImage,
      ctaTitle,
      ctaUrl,
      external_link,
    } = params;
    const fd = new FormData();

    fd.set("design_type", templateType as string);
    fd.set("catchphrase", catchCopy as string);
    fd.set("catchphrase_description", catchCopyOverView as string);
    if (catchCopyImage) {
      fd.set("catchphrase_image", catchCopyImage as File);
    }
    fd.set("cta_name", ctaTitle as string);
    fd.set("cta_url", ctaUrl as string);
    fd.set("external_link", external_link as string);

    const response = await http.put(
      `/content_provider/pbl/board/items/${itemId}/contents/${contentId}`,
      fd,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface EditBoardItemType {
  id: string;
  data: { content_type: PblBoardItemContentType; sequence?: number };
}

export const putPblBoardItemAsContentProvider = createAsyncThunk<
  PblBoardItemBaseAsShow,
  EditBoardItemType,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_item/put", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    const response = await api.contentProvider.putPblBoardItemAsContentProvider(
      id,
      data,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deletePblBoardItemAsContentProvider = createAsyncThunk<
  { message?: string },
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_content/delete", async (id, { rejectWithValue }) => {
  try {
    const response =
      await api.contentProvider.deletePblBoardItemAsContentProvider(id);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface CreateFooterTypes {
  item_id: string;
  data: {
    category_name: string;
    footer_items?: { title: string; url: string }[];
  };
}

export const postPblBoardFooterAsContentProvider = createAsyncThunk<
  { message?: string },
  CreateFooterTypes,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_footer/post", async (params, { rejectWithValue }) => {
  try {
    const { item_id, data } = params;
    const response =
      await api.contentProvider.postPblBoardFooterAsContentProvider(
        item_id,
        data,
      );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface EditFooterTypes {
  item_id: string;
  id: string;
  data: { category_name: string };
}

export const putPblBoardFooterAsContentProvider = createAsyncThunk<
  { message?: string },
  EditFooterTypes,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_footer/edit", async (params, { rejectWithValue }) => {
  try {
    const { item_id, id, data } = params;
    const response =
      await api.contentProvider.putPblBoardFooterAsContentProvider(
        item_id,
        id,
        data,
      );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deletePblBoardFooterAsContentProvider = createAsyncThunk<
  { message?: string },
  { item_id: string; id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("content_provider/board_footer/edit", async (params, { rejectWithValue }) => {
  try {
    const { item_id, id } = params;
    const response =
      await api.contentProvider.deletePblBoardFooterAsContentProvider(
        item_id,
        id,
      );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface CreateFooterItemsTypes {
  item_id: string;
  footer_id: string;
  data: { title: string; url: string };
}

export const postPblBoardFooterItemsAsContentProvider = createAsyncThunk<
  { message?: string },
  CreateFooterItemsTypes,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "content_provider/board_footer_items/post",
  async (params, { rejectWithValue }) => {
    try {
      const { item_id, footer_id, data } = params;
      const response =
        await api.contentProvider.postPblBoardFooterItemAsContentProvider(
          item_id,
          footer_id,
          data,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface EditFooterItemsTypes {
  item_id: string;
  footer_id: string;
  id: string;
  data: { title: string; url: string };
}

export const editPblBoardFooterItemsAsContentProvider = createAsyncThunk<
  { message?: string },
  EditFooterItemsTypes,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "content_provider/board_footer_items/put",
  async (params, { rejectWithValue }) => {
    try {
      const { item_id, footer_id, id, data } = params;
      const response =
        await api.contentProvider.putPblBoardFooterItemAsContentProvider(
          item_id,
          footer_id,
          id,
          data,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblBoardFooterItemsAsContentProvider = createAsyncThunk<
  { message?: string },
  { item_id: string; footer_id: string; id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "content_provider/board_footer_items/delete",
  async (params, { rejectWithValue }) => {
    try {
      const { item_id, footer_id, id } = params;
      const response =
        await api.contentProvider.deletePblBoardFooterItemAsContentProvider(
          item_id,
          footer_id,
          id,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

type FooterSequenceParams = {
  item_id: string;
  id: string;
  data: { sequence: number };
};

export const putPblBoardFooterSequenceAsContentProvider = createAsyncThunk<
  { message?: string },
  FooterSequenceParams
>("owner/board_footer/sequence", async (params, { rejectWithValue }) => {
  try {
    const { item_id, id, data } = params;
    const response =
      await api.contentProvider.putPblBoardFooterSequenceAsContentProvider(
        item_id,
        id,
        data,
      );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetBoardState = createAction(
  "content_provider/board_infos/reset",
);
export const load = createAction("content_provider/board/load");
export const success = createAction("content_provider/board/success");
export const rejected = createAction("content_provider/board/rejected");

type ContentProviderActionsType = {
  content_provider: RoleApiFunctions;
};
export const contentProviderBoardActions: ContentProviderActionsType = {
  content_provider: {
    postBoardInfos: createPblBoardInfoAsContentProvider,
    putBoardInfos: putPblBoardInfoAsContentProvider,
    createBoardItem: createPblBoardItemAsContentProvider,
    editBoardItem: putPblBoardItemAsContentProvider,
    deletePblBoardItem: deletePblBoardItemAsContentProvider,
    createBoardContent: createPblBoardContentsAsContentProvider,
    editBoardContent: editPblBoardContentsAsContentProvider,
    createBoardFooter: postPblBoardFooterAsContentProvider,
    editBoardFooter: putPblBoardFooterAsContentProvider,
    deleteBoardFooter: deletePblBoardFooterAsContentProvider,
    createBoardFooterItems: postPblBoardFooterItemsAsContentProvider,
    editBoardFooterItems: editPblBoardFooterItemsAsContentProvider,
    deleteBoardFooterItems: deletePblBoardFooterItemsAsContentProvider,
    putPblBoardFooterSequence: putPblBoardFooterSequenceAsContentProvider,
    load,
    success,
    rejected,
  },
};
