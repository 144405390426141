import {
  postCommonInquiry,
  PostInquiryParams,
  resetState,
} from "@actions/inquiry";
import { enqueueToast } from "@actions/toast";
import { InquiryKind } from "@actions/types/inquiry";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import { inquiryState } from "@reducers/inquiry";
import { nanoid } from "@reduxjs/toolkit";
import { useAppDispatch,useAppSelector } from "@root/hooks";
import React, { useEffect,useState } from "react";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { SubmitHandler,useForm } from "react-hook-form";

import ConfirmationForm from "./Form/ConfirmationForm";
import SupportInquiryForm, { InquiryFormValues } from "./Form/Form";

interface SupportInquiryModalProps {
  open: boolean;
  type: InquiryKind | null;
  handleClose: () => void;
}

function getTitle(type: InquiryKind | null) {
  if (type === "user_support") {
    return "ユーザーサポートお問合せ";
  }
  if (type === "system") {
    return "システムに関してお問合せ";
  }
  if (type === "content") {
    return "コンテンツに関してお問合せ";
  }
  if (type === "other") {
    return "その他お問合せ";
  }
  return "";
}

const SupportInquiryModal: React.FC<SupportInquiryModalProps> = ({
  open,
  type,
  handleClose,
}) => {
  const [step, setStep] = useState<0 | 1>(0);
  const dispatch = useAppDispatch();
  const { control, handleSubmit, reset, getValues } =
    useForm<InquiryFormValues>({
      defaultValues: {
        title: "",
        body: "",
      },
    });
  const { error, message, posted, posting } = useAppSelector(inquiryState);

  useEffect(() => {
    if (posted) {
      dispatch(
        enqueueToast({
          message,
          id: nanoid(),
          options: {
            variant: "success",
          },
        }),
      );
      handleClose();
    }
  }, [posted]);

  const resetFormState = () => {
    setStep(0);
    dispatch(resetState());
    reset();
  };

  const postInquiry = (params: PostInquiryParams) => {
    dispatch(postCommonInquiry(params));
  };

  const onSubmit: SubmitHandler<InquiryFormValues> = (data) => {
    postInquiry({
      title: data.title,
      body: data.body,
      os_name: osName,
      os_version: osVersion,
      browser_name: browserName,
      browser_version: browserVersion,
      kind: type as InquiryKind,
    });
  };

  const onConfirmation: SubmitHandler<InquiryFormValues> = (data) => {
    setStep(1);
  };

  let primaryButtonText = "確認する";
  let secondaryButtonText = "キャンセル";
  let handlePrimaryButtonClick = handleSubmit(onConfirmation);
  let handleSecondaryButtonClick = handleClose;
  if (step === 1) {
    primaryButtonText = "送信する";
    secondaryButtonText = "修正する";
    handlePrimaryButtonClick = handleSubmit(onSubmit);
    handleSecondaryButtonClick = () => setStep(0);
  }

  return (
    <GenericModal
      title={getTitle(type)}
      open={open}
      handleClose={handleClose}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      posting={posting}
      primaryButtonAction={handlePrimaryButtonClick}
      secondaryButtonAction={handleSecondaryButtonClick}
      TransitionProps={{ onExited: resetFormState }}
      useActionButtons
      useSecondaryButton
    >
      {step === 0 && (
        <SupportInquiryForm
          control={control}
          onSubmit={handleSubmit(onConfirmation)}
        />
      )}
      {step === 1 && (
        <ConfirmationForm
          getValues={getValues}
          onSubmit={handleSubmit(onSubmit)}
          error={error}
        />
      )}
    </GenericModal>
  );
};

export default SupportInquiryModal;
