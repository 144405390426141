/* eslint-disable camelcase */

import { Api } from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

interface AssignManagerToHomeworkTemplateDistributionAsTeacherArg {
  teacherName: string;
  id: string;
  params: {
    teacher_id: string;
  };
}

export const assignSchoolManagerAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateDistribution/assignSchoolManager",
  async (
    arg: AssignManagerToHomeworkTemplateDistributionAsTeacherArg,
    { rejectWithValue },
  ) => {
    const { teacherName, id, params } = arg;
    try {
      await api.teacher.assignManagerToHomeworkTemplateDistributionAsTeacher(
        id,
        params,
      );
      return {
        distributionId: id,
        teacherId: params.teacher_id,
        teacherName,
      };
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

interface SubmitHomeworkTemplateDistributionToReviewerAsTeacherArg {
  id: string;
  params: {
    comment: string | null;
  };
}

export const submitToReviewerAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateDistribution/submitToReviewer",
  async (
    arg: SubmitHomeworkTemplateDistributionToReviewerAsTeacherArg,
    { rejectWithValue },
  ) => {
    const { id, params } = arg;
    try {
      const response =
        await api.teacher.submitHomeworkTemplateDistributionToReviewerAsTeacher(
          id,
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

interface RevertHomeworkTemplateDistributionSubmittedToReviewerAsTeacherArg {
  id: string;
}

export const revertSubmissionToReviewerAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateDistribution/revertSubmissionToReviewer",
  async (
    arg: RevertHomeworkTemplateDistributionSubmittedToReviewerAsTeacherArg,
    { rejectWithValue },
  ) => {
    const { id } = arg;
    try {
      const response =
        await api.teacher.revertHomeworkTemplateDistributionSubmittedToReviewerAsTeacher(
          id,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

interface ReturnHomeworkTemplateDistributionToStudentsAsTeacherArg {
  id: string;
}

export const returnToStudentsAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateDistribution/returnToStudents",
  async (
    arg: ReturnHomeworkTemplateDistributionToStudentsAsTeacherArg,
    { rejectWithValue },
  ) => {
    const { id } = arg;
    try {
      const response =
        await api.teacher.returnHomeworkTemplateDistributionToStudentsAsTeacher(
          id,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

interface UpdateHomeworkTemplateDistributionAsTeacherArg {
  id: string;
  params: {
    comment: string;
  };
}

export const updateAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateDistribution/update",
  async (
    arg: UpdateHomeworkTemplateDistributionAsTeacherArg,
    { rejectWithValue },
  ) => {
    const { id, params } = arg;
    try {
      const response =
        await api.teacher.updateHomeworkTemplateDistributionAsTeacher(
          id,
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);
