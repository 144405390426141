import MainButton from "@components/UI/atoms/MainButton/MainButton";
import { Theme } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

type ThemeProps = {
  tertiary?: string;
};

export type Props = {
  setButtonState: React.Dispatch<React.SetStateAction<SelectedButton>>;
  buttonState: SelectedButton;
  primary: string;
  secondary: string;
  tertiary?: string;
  containerBoxProps?: BoxProps;
};

export enum SelectedButton {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
}

export enum ButtonType {
  Text = "text",
  Contained = "contained",
}

export const useStyles = makeStyles<Theme, ThemeProps>((theme: Theme) =>
  createStyles({
    tabContainer: {
      display: "flex",
      gap: theme.spacing(1),
      textAlign: "center",
      border: `2px solid ${theme.palette.tertiary.mainDark}`,
      borderRadius: 8,
      width: "max-content",
      padding: theme.spacing(0.5),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1),
        width: "auto",
      },
    },
  }),
);

const V2Tabs: React.FC<Props> = ({
  setButtonState,
  buttonState,
  primary,
  secondary,
  tertiary,
  containerBoxProps,
}) => {
  const classes = useStyles({ tertiary });
  return (
    <Box
      {...containerBoxProps}
      className={classes.tabContainer}
      data-cy="tab-button-container"
    >
      <MainButton
        data-cy="V2Tabs-primary-button"
        onClick={() => setButtonState(SelectedButton.Primary)}
        variant={
          buttonState === SelectedButton.Primary
            ? ButtonType.Contained
            : ButtonType.Text
        }
      >
        {primary}
      </MainButton>
      <MainButton
        data-cy="V2Tabs-secondary-button"
        onClick={() => setButtonState(SelectedButton.Secondary)}
        variant={
          buttonState === SelectedButton.Secondary
            ? ButtonType.Contained
            : ButtonType.Text
        }
      >
        {secondary}
      </MainButton>
      {tertiary && (
        <MainButton
          data-cy="V2Tabs-tertiary-button"
          onClick={() => setButtonState(SelectedButton.Tertiary)}
          variant={
            buttonState === SelectedButton.Tertiary
              ? ButtonType.Contained
              : ButtonType.Text
          }
        >
          {tertiary}
        </MainButton>
      )}
    </Box>
  );
};

export default V2Tabs;
