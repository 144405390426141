import DeleteBox from "@components/UI/atoms/Icons/DeleteBox";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import { LearningTemplatePackageDistributionHistoryBase } from "@lib/Api";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import errorWithMessage from "@lib/rtk/error-utils";
import { Box,Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useDeleteLearningTemplatePackageDistributionAsSuperOwnerMutation } from "@root/endpoints/TimeTactApi/superOwnerApi";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      margin: theme.spacing(2, 0),
    },
  }),
);

interface Props {
  handleClose: () => void;
  learningTemplatePackage: LearningTemplatePackageDistributionHistoryBase;
  fetchDistributionHistories: (newPage?: number) => void;
}

const DeleteDistribution: React.FC<Props> = ({
  handleClose,
  learningTemplatePackage,
  fetchDistributionHistories,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["modal"]);
  const { actions } = useEnqueueToast();

  const [deleteDistribution, { isLoading }] =
    useDeleteLearningTemplatePackageDistributionAsSuperOwnerMutation();

  const onSubmit = () => {
    const deleteParams = {
      id: learningTemplatePackage.id,
    };
    deleteDistribution(deleteParams)
      .unwrap()
      .then((payload) => {
        actions.showSuccess(payload.message);
        fetchDistributionHistories();
        handleClose();
      })
      .catch((err) => actions.showError(errorWithMessage(err)));
  };

  return (
    <GenericModal
      useActionButtons
      useSecondaryButton
      title="プログラム配信の削除"
      modalSize="sm"
      handleClose={handleClose}
      secondaryButtonAction={handleClose}
      primaryButtonAction={onSubmit}
      primaryButtonText={t("modal:delete")}
      secondaryButtonText={t("modal:cancel")}
      open
      posting={isLoading}
    >
      <Box className={classes.title}>
        <Typography>一度削除するとデータの復元はできません。</Typography>
        <Typography>本当に削除しますか？</Typography>
      </Box>
      <DeleteBox width={300} height={300} />
    </GenericModal>
  );
};

export default DeleteDistribution;
