import { postMaterialHomeworkTemplatePackagesAsSuperOwner } from "@actions/superOwnerApp/materialHomeworkPackage/materialHomeworkPackage";
import {
  addPackagesSuccess,
  postPackagingAsSuperOwner,
} from "@actions/superOwnerApp/templatePackages/learningTemplatePackage";
import { SelectedButton } from "@components/UIv2/molecules/V2Tabs";
import { ReduxStatus } from "@constants/redux";
import {
  LearningTemplatePackageBase,
  MaterialHomeworkTemplatePackageBase,
  PackagingPackageableType,
} from "@lib/Api";
import { useAppDispatch } from "@root/hooks";
import React from "react";

import { coPackageType } from ".";

type Props = {
  values: { packageTitle: string };
  learningTemplate: LearningTemplatePackageBase | null;
  packageType: PackagingPackageableType;
  chosenPackage: coPackageType[];
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  packagedIds: string[] | undefined;
  dispatch: ReturnType<typeof useAppDispatch>;
};

const AddCoPackage = async ({
  values,
  learningTemplate,
  packageType,
  chosenPackage,
  setErrorMessage,
  packagedIds,
  dispatch,
}: Props): Promise<void> => {
  const idAlreadyChosen = chosenPackage.some((item: coPackageType) =>
    packagedIds?.includes(item.id),
  );

  if (idAlreadyChosen) {
    setErrorMessage("コンテンツはすでに存在します。");
  } else {
    const promiseAll: Promise<ReturnType<typeof useAppDispatch>>[] = [];
    const MaterialHomeworkTemplatePackageResult: MaterialHomeworkTemplatePackageBase =
      await dispatch(
        postMaterialHomeworkTemplatePackagesAsSuperOwner({
          title: values.packageTitle,
          description: values.packageTitle,
        }),
      ).unwrap();

    chosenPackage.forEach(async (packageValue: coPackageType) => {
      promiseAll.push(
        dispatch(
          postPackagingAsSuperOwner({
            package_id: MaterialHomeworkTemplatePackageResult.id,
            packageable_type:
              packageValue.type === SelectedButton.Primary
                ? PackagingPackageableType.Material
                : PackagingPackageableType.HomeworkTemplate,
            packageable_id: packageValue.id,
          }),
        ),
      );
    });

    promiseAll.push(
      dispatch(
        postPackagingAsSuperOwner({
          package_id: learningTemplate ? learningTemplate.id : "",
          packageable_type: packageType,
          packageable_id: MaterialHomeworkTemplatePackageResult.id,
        }),
      ),
    );

    Promise.allSettled(promiseAll).then((resolves) => {
      const successResolve: PromiseSettledResult<any> | undefined =
        resolves.find(
          (resolve: any) =>
            resolve.value.meta.requestStatus === ReduxStatus.fulfilled,
        );
      if (successResolve) {
        dispatch(addPackagesSuccess());
      } else {
        const failedResolve: PromiseSettledResult<any> | undefined =
          resolves.find(
            (resolve: any) =>
              resolve.value.meta.requestStatus === ReduxStatus.rejected,
          );
        if (failedResolve) {
          setErrorMessage("コンテンツはすでに存在します。");
        }
      }
    });
  }
};
export default AddCoPackage;
