import { baseApi as api } from "../baseApi";
export const addTagTypes = [
  "SuperOwner/GradeMasters",
  "SuperOwnerEmails",
  "SuperOwner/MaterialsTicket",
  "CategoryRenameMap",
  "SuperOwner/CategoryRenameMap",
  "SuperOwner/CustomMasterMap",
  "SuperOwner/CustomMaster",
  "SuperOwner/CustomMasterMapRelationships",
  "SuperOwner/CustomMasterRelationship",
  "SuperOwner/Notification",
  "SuperOwner/UserTagType",
  "SuperOwner/UsersSearch",
  "SuperOwner/ShowAffiliate",
  "SuperOwner/BulkDeleteAffiliatesOfUser",
  "SuperOwner/UserTagging",
  "SuperOwner/ReviewerCompanyGroup",
  "SuperOwner/Company",
  "Companies",
  "SuperOwner/SuperOwner",
  "SuperOwner/Owner",
  "SuperOwner/Teacher",
  "SuperOwner/Teachers",
  "SuperOwner/Student",
  "SuperOwner/Students",
  "SuperOwner/Reviewer",
  "SuperOwner/User",
  "SuperOwner/UserTag",
  "Owner/UserTag",
  "SuperOwner/UserTag/Student",
  "SuperOwner/CompanyTagType",
  "SuperOwner/UserTagDistributionHistories",
  "SuperOwner/CompanyTag",
  "SuperOwner/CompanyTagging",
  "SuperOwner/HomeworkTemplate",
  "SuperOwner/Homework",
  "Homework",
  "HomeworkTemplate",
  "SuperOwner/HomeworkTemplateDistribution",
  "HomeworkTemplateDistribution",
  "SuperOwner/HomeworkTemplateImage",
  "SuperOwner/UserTag/User",
  "SuperOwner/Reservation",
  "SuperOwner/MaterialWork",
  "SuperOwner/Material",
  "SuperOwner/Category",
  "SuperOwner/CompanyTag/Company",
  "SuperOwner/MaterialDistributionHistoryGroup",
  "SuperOwner/MaterialDistributionHistory",
  "SuperOwner/HomeworkTemplateDistributionHistories",
  "SuperOwner/HomeworkTemplateCustomFields",
  "SuperOwner/Inquiries",
  "SuperOwner/InquiryComments",
  "SuperOwner/LearningTemplatePackages",
  "SuperOwner/LearningTemplatePackageDistributions",
  "SuperOwner/LearningTemplatePackageDistributionHistories",
  "SuperOwner/MaterialHomeworkTemplatePackages",
  "SuperOwner/MaterialHomeworkTemplatePackagePackagingSequence",
  "SuperOwner/Packaging",
  "SuperOwner/PblBoardInfos",
  "SuperOwner/PblBoardItems",
  "SuperOwner/PblBoardCampaigns",
  "SuperOwner/PblBoardCampaignRequests",
  "SuperOwner/PblBoardArticles",
  "SuperOwner/PblBoardContents",
  "SuperOwner/PblBoardFooters",
  "SuperOwner/PblBoardFooterItems",
  "SuperOwner/Affiliate",
  "SuperOwner/AttachedImages",
  "SuperOwner/LimitedTimeNotifications",
  "SuperOwner/Steam/Contents",
  "SuperOwner/Steam/Lectures",
  "SuperOwner/Sponsor/Projects",
  "SuperOwner/Sponsor/Lectures",
  "SuperOwner/TtCsvExports",
  "SuperOwner/TtCategoryBases",
  "SuperOwner/FeedbackTemplate/Homework",
  "SuperOwner/FeedbackTemplate/Comments",
  "SuperOwner/FeedbackTemplate/Tag",
  "SuperOwner/Help/Contents",
  "SuperOwner/IdealBoardTemplates",
  "SuperOwner/IdealBoardTemplate",
  "SuperOwner/IdealBoards",
  "SuperOwner/IdealBoard",
  "SuperOwner/BoardAffiliates",
  "SuperOwner/CommonTags",
  "SuperOwner/CommonTaggings",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getSuperOwnerGradeMasters: build.query<
        GetSuperOwnerGradeMastersApiResponse,
        GetSuperOwnerGradeMastersApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/grade_masters`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
        }),
        providesTags: ["SuperOwner/GradeMasters"],
      }),
      postSuperOwnerGradeMasters: build.mutation<
        PostSuperOwnerGradeMastersApiResponse,
        PostSuperOwnerGradeMastersApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/grade_masters`,
          method: "POST",
          body: queryArg.gradeAndGraduationYearRequiredRequestBody,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
        }),
        invalidatesTags: ["SuperOwner/GradeMasters"],
      }),
      putSuperOwnerGradeMastersById: build.mutation<
        PutSuperOwnerGradeMastersByIdApiResponse,
        PutSuperOwnerGradeMastersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/grade_masters/${queryArg.id}`,
          method: "PUT",
          body: queryArg.graduationYearRequiredRequestBody,
        }),
        invalidatesTags: ["SuperOwner/GradeMasters"],
      }),
      getSuperOwnerEmailsBySuperOwnerEmailId: build.query<
        GetSuperOwnerEmailsBySuperOwnerEmailIdApiResponse,
        GetSuperOwnerEmailsBySuperOwnerEmailIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/emails/${queryArg.superOwnerEmailId}`,
        }),
        providesTags: ["SuperOwnerEmails"],
      }),
      getSuperOwnerEmails: build.query<
        GetSuperOwnerEmailsApiResponse,
        GetSuperOwnerEmailsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/emails`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["SuperOwnerEmails"],
      }),
      postSuperOwnerEmails: build.mutation<
        PostSuperOwnerEmailsApiResponse,
        PostSuperOwnerEmailsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/emails`,
          method: "POST",
          body: queryArg.emailRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwnerEmails"],
      }),
      postSuperOwnerMaterialsTickets: build.mutation<
        PostSuperOwnerMaterialsTicketsApiResponse,
        PostSuperOwnerMaterialsTicketsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials_tickets`,
          method: "POST",
          body: queryArg.materialCategoryIdRequiredRequestBody,
        }),
        invalidatesTags: ["SuperOwner/MaterialsTicket"],
      }),
      putSuperOwnerMaterialsTicketsAccept: build.mutation<
        PutSuperOwnerMaterialsTicketsAcceptApiResponse,
        PutSuperOwnerMaterialsTicketsAcceptApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials_tickets/accept`,
          method: "PUT",
          body: queryArg.putMaterialsTicketRequestBody,
        }),
        invalidatesTags: ["SuperOwner/MaterialsTicket"],
      }),
      updateCategoryRenameMapAsSuperOwner: build.mutation<
        UpdateCategoryRenameMapAsSuperOwnerApiResponse,
        UpdateCategoryRenameMapAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/category_rename_maps/update`,
          method: "PATCH",
          body: queryArg.categoryRenameMapRequestBody,
        }),
        invalidatesTags: ["CategoryRenameMap", "SuperOwner/CategoryRenameMap"],
      }),
      getSuperOwnerCustomMasterMaps: build.query<
        GetSuperOwnerCustomMasterMapsApiResponse,
        GetSuperOwnerCustomMasterMapsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_maps`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/CustomMasterMap"],
      }),
      postSuperOwnerCustomMasterMaps: build.mutation<
        PostSuperOwnerCustomMasterMapsApiResponse,
        PostSuperOwnerCustomMasterMapsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_maps`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["SuperOwner/CustomMasterMap"],
      }),
      putSuperOwnerCustomMasterMapsSequence: build.mutation<
        PutSuperOwnerCustomMasterMapsSequenceApiResponse,
        PutSuperOwnerCustomMasterMapsSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_maps/sequence`,
          method: "PUT",
          body: queryArg.customMasterMapSequencesRequestBody,
        }),
        invalidatesTags: ["SuperOwner/CustomMasterMap"],
      }),
      getSuperOwnerCustomMasterMapsByCustomMasterMapId: build.query<
        GetSuperOwnerCustomMasterMapsByCustomMasterMapIdApiResponse,
        GetSuperOwnerCustomMasterMapsByCustomMasterMapIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_maps/${queryArg.customMasterMapId}`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/CustomMasterMap"],
      }),
      putSuperOwnerCustomMasterMapsByCustomMasterMapId: build.mutation<
        PutSuperOwnerCustomMasterMapsByCustomMasterMapIdApiResponse,
        PutSuperOwnerCustomMasterMapsByCustomMasterMapIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_maps/${queryArg.customMasterMapId}`,
          method: "PUT",
          body: queryArg.customMasterMapBase,
        }),
        invalidatesTags: ["SuperOwner/CustomMasterMap"],
      }),
      deleteSuperOwnerCustomMasterMapsByCustomMasterMapId: build.mutation<
        DeleteSuperOwnerCustomMasterMapsByCustomMasterMapIdApiResponse,
        DeleteSuperOwnerCustomMasterMapsByCustomMasterMapIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_maps/${queryArg.customMasterMapId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/CustomMasterMap"],
      }),
      postSuperOwnerCustomMasters: build.mutation<
        PostSuperOwnerCustomMastersApiResponse,
        PostSuperOwnerCustomMastersApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_masters`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["SuperOwner/CustomMaster"],
      }),
      putSuperOwnerCustomMastersByCustomMasterId: build.mutation<
        PutSuperOwnerCustomMastersByCustomMasterIdApiResponse,
        PutSuperOwnerCustomMastersByCustomMasterIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_masters/${queryArg.customMasterId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["SuperOwner/CustomMaster"],
      }),
      deleteSuperOwnerCustomMastersByCustomMasterId: build.mutation<
        DeleteSuperOwnerCustomMastersByCustomMasterIdApiResponse,
        DeleteSuperOwnerCustomMastersByCustomMasterIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_masters/${queryArg.customMasterId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/CustomMaster"],
      }),
      getSuperOwnerCustomMastersLinkingCompanies: build.query<
        GetSuperOwnerCustomMastersLinkingCompaniesApiResponse,
        GetSuperOwnerCustomMastersLinkingCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_masters/linking_companies`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            custom_master_map_id: queryArg.customMasterMapId,
            column_number: queryArg.columnNumber,
            column_values: queryArg.columnValues,
          },
        }),
        providesTags: ["SuperOwner/CustomMaster"],
      }),
      putSuperOwnerCustomMastersByCustomMasterIdLinkingCompany: build.mutation<
        PutSuperOwnerCustomMastersByCustomMasterIdLinkingCompanyApiResponse,
        PutSuperOwnerCustomMastersByCustomMasterIdLinkingCompanyApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_masters/${queryArg.customMasterId}/linking_company`,
          method: "PUT",
          body: queryArg.schema,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["SuperOwner/CustomMaster"],
      }),
      getCustomMasterMapRelationshipsAsSuperOwner: build.query<
        GetCustomMasterMapRelationshipsAsSuperOwnerApiResponse,
        GetCustomMasterMapRelationshipsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_map_relationships`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            custom_master_map_id: queryArg.customMasterMapId,
          },
        }),
        providesTags: ["SuperOwner/CustomMasterMapRelationships"],
      }),
      postCustomMasterMapRelationshipsAsSuperOwner: build.mutation<
        PostCustomMasterMapRelationshipsAsSuperOwnerApiResponse,
        PostCustomMasterMapRelationshipsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_map_relationships`,
          method: "POST",
          body: queryArg.postCustomMasterMapRelationshipRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { custom_master_map_id: queryArg.customMasterMapId },
        }),
        invalidatesTags: ["SuperOwner/CustomMasterMapRelationships"],
      }),
      getCustomMasterMapRelationshipAsSuperOwner: build.query<
        GetCustomMasterMapRelationshipAsSuperOwnerApiResponse,
        GetCustomMasterMapRelationshipAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_map_relationships/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { custom_master_map_id: queryArg.customMasterMapId },
        }),
        providesTags: ["SuperOwner/CustomMasterMapRelationships"],
      }),
      putCustomMasterMapRelationshipsAsSuperOwner: build.mutation<
        PutCustomMasterMapRelationshipsAsSuperOwnerApiResponse,
        PutCustomMasterMapRelationshipsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_map_relationships/${queryArg.id}`,
          method: "PUT",
          body: queryArg.nameRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { custom_master_map_id: queryArg.customMasterMapId },
        }),
        invalidatesTags: ["SuperOwner/CustomMasterMapRelationships"],
      }),
      deleteControllerAsSuperOwner: build.mutation<
        DeleteControllerAsSuperOwnerApiResponse,
        DeleteControllerAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_map_relationships/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { custom_master_map_id: queryArg.customMasterMapId },
        }),
        invalidatesTags: ["SuperOwner/CustomMasterMapRelationships"],
      }),
      getCustomMasterMapRelationshipChildCustomMastersAsSuperOwner: build.query<
        GetCustomMasterMapRelationshipChildCustomMastersAsSuperOwnerApiResponse,
        GetCustomMasterMapRelationshipChildCustomMastersAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_map_relationships/${queryArg.id}/child_custom_masters`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { custom_master_map_id: queryArg.customMasterMapId },
        }),
        providesTags: ["SuperOwner/CustomMasterMapRelationships"],
      }),
      batchCreateCustomMasterRelationshipAsSuperOwner: build.mutation<
        BatchCreateCustomMasterRelationshipAsSuperOwnerApiResponse,
        BatchCreateCustomMasterRelationshipAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/custom_master_relationships/batch_create`,
          method: "POST",
          body: queryArg.batchCreateCustomMasterMapRelationshipRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["SuperOwner/CustomMasterRelationship"],
      }),
      getSuperOwnerNotifications: build.query<
        GetSuperOwnerNotificationsApiResponse,
        GetSuperOwnerNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/notifications`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            sent: queryArg.sent,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["SuperOwner/Notification"],
      }),
      postSuperOwnerNotifications: build.mutation<
        PostSuperOwnerNotificationsApiResponse,
        PostSuperOwnerNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/notifications`,
          method: "POST",
          body: queryArg.notificationRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/Notification"],
      }),
      getSuperOwnerNotificationsById: build.query<
        GetSuperOwnerNotificationsByIdApiResponse,
        GetSuperOwnerNotificationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/notifications/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Notification"],
      }),
      putSuperOwnerNotificationsById: build.mutation<
        PutSuperOwnerNotificationsByIdApiResponse,
        PutSuperOwnerNotificationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/notifications/${queryArg.id}`,
          method: "PUT",
          body: queryArg.notificationRequestBodyAsSuperOwner,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/Notification"],
      }),
      deleteSuperOwnerNotificationsById: build.mutation<
        DeleteSuperOwnerNotificationsByIdApiResponse,
        DeleteSuperOwnerNotificationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/notifications/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/Notification"],
      }),
      getSuperOwnerCreatedUserTagTypes: build.query<
        GetSuperOwnerCreatedUserTagTypesApiResponse,
        GetSuperOwnerCreatedUserTagTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/created_user_tag_types`,
          params: {
            user_tag_type_name: queryArg.userTagTypeName,
            user_tag_name: queryArg.userTagName,
            page: queryArg.page,
            per_page: queryArg.perPage,
            kind: queryArg.kind,
            role: queryArg.role,
          },
        }),
        providesTags: ["SuperOwner/UserTagType"],
      }),
      getSearchUserControllersAsSuperOwner: build.query<
        GetSearchUserControllersAsSuperOwnerApiResponse,
        GetSearchUserControllersAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/users/search`,
          params: {
            user_name: queryArg.userName,
            email: queryArg.email,
            company_name: queryArg.companyName,
            tag_name: queryArg.tagName,
            role_name: queryArg.roleName,
            status: queryArg.status,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/UsersSearch"],
      }),
      getShowAffiliateUserControllersAsSuperOwner: build.query<
        GetShowAffiliateUserControllersAsSuperOwnerApiResponse,
        GetShowAffiliateUserControllersAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/users/${queryArg.id}/show_affiliate`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/ShowAffiliate"],
      }),
      bulkDeleteControllerAsSuperOwner: build.mutation<
        BulkDeleteControllerAsSuperOwnerApiResponse,
        BulkDeleteControllerAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/users/${queryArg.id}/bulk_destroy`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/BulkDeleteAffiliatesOfUser"],
      }),
      postSuperOwnerUserTagTypes: build.mutation<
        PostSuperOwnerUserTagTypesApiResponse,
        PostSuperOwnerUserTagTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tag_types`,
          method: "POST",
          body: queryArg.postUserTagTypeRequestBody,
        }),
        invalidatesTags: ["SuperOwner/UserTagType"],
      }),
      postSuperOwnerUserTaggings: build.mutation<
        PostSuperOwnerUserTaggingsApiResponse,
        PostSuperOwnerUserTaggingsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_taggings`,
          method: "POST",
          body: queryArg.postUserTaggingRequestBody,
        }),
        invalidatesTags: ["SuperOwner/UserTagging"],
      }),
      deleteSuperOwnerUserTaggings: build.mutation<
        DeleteSuperOwnerUserTaggingsApiResponse,
        DeleteSuperOwnerUserTaggingsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_taggings`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["SuperOwner/UserTagging"],
      }),
      superOwnerUserTaggingBatchCreate: build.mutation<
        SuperOwnerUserTaggingBatchCreateApiResponse,
        SuperOwnerUserTaggingBatchCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_taggings/batch_create`,
          method: "POST",
          body: queryArg.userTaggingBatchCreateParams,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["SuperOwner/UserTagging"],
      }),
      putSuperOwnerUserTagTypesById: build.mutation<
        PutSuperOwnerUserTagTypesByIdApiResponse,
        PutSuperOwnerUserTagTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tag_types/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putUserTagTypeRequestBody,
        }),
        invalidatesTags: ["SuperOwner/UserTagType"],
      }),
      deleteSuperOwnerUserTagTypesById: build.mutation<
        DeleteSuperOwnerUserTagTypesByIdApiResponse,
        DeleteSuperOwnerUserTagTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tag_types/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/UserTagType"],
      }),
      getReviewerCompanyGroupControllersAsSuperOwner: build.query<
        GetReviewerCompanyGroupControllersAsSuperOwnerApiResponse,
        GetReviewerCompanyGroupControllersAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/reviewer_company_groups`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/ReviewerCompanyGroup"],
      }),
      getReviewerCompanyGroupAsSuperOwner: build.query<
        GetReviewerCompanyGroupAsSuperOwnerApiResponse,
        GetReviewerCompanyGroupAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/reviewer_company_groups/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/ReviewerCompanyGroup"],
      }),
      putReviewerCompanyGroupAsSuperOwner: build.mutation<
        PutReviewerCompanyGroupAsSuperOwnerApiResponse,
        PutReviewerCompanyGroupAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/reviewer_company_groups/${queryArg.id}`,
          method: "PUT",
          body: queryArg.reviewerCompanyGroupRequestBodyAsSuperOwner,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/ReviewerCompanyGroup"],
      }),
      fetchCompaniesAsSuperOwner: build.query<
        FetchCompaniesAsSuperOwnerApiResponse,
        FetchCompaniesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            company_role: queryArg.companyRole,
            company_tag_ids: queryArg.companyTagIds,
            tag_filter: queryArg.tagFilter,
            homework_template_id: queryArg.homeworkTemplateId,
            distribution: queryArg.distribution,
            company_codes: queryArg.companyCodes,
            master_name: queryArg.masterName,
            column_num: queryArg.columnNum,
            company_name: queryArg.companyName,
            company_tag_name: queryArg.companyTagName,
            simple_info: queryArg.simpleInfo,
          },
        }),
        providesTags: ["SuperOwner/Company"],
      }),
      createCompanyAsSuperOwner: build.mutation<
        CreateCompanyAsSuperOwnerApiResponse,
        CreateCompanyAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies`,
          method: "POST",
          body: queryArg.postCompanyRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/Company"],
      }),
      fetchCompanyAsSuperOwner: build.query<
        FetchCompanyAsSuperOwnerApiResponse,
        FetchCompanyAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({ url: `/super_owner/companies/${queryArg.id}` }),
        providesTags: ["SuperOwner/Company"],
      }),
      updateCompanyAsSuperOwner: build.mutation<
        UpdateCompanyAsSuperOwnerApiResponse,
        UpdateCompanyAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putCompanyRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/Company"],
      }),
      deleteCompanyAsSuperOwner: build.mutation<
        DeleteCompanyAsSuperOwnerApiResponse,
        DeleteCompanyAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/Company"],
      }),
      getCompanyLimitOptionAsSuperOwner: build.query<
        GetCompanyLimitOptionAsSuperOwnerApiResponse,
        GetCompanyLimitOptionAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.id}/limit_option`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Company"],
      }),
      getSuperOwnerCompaniesByIdMaterialCapacity: build.query<
        GetSuperOwnerCompaniesByIdMaterialCapacityApiResponse,
        GetSuperOwnerCompaniesByIdMaterialCapacityApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.id}/material_capacity`,
        }),
        providesTags: ["Companies"],
      }),
      getSuperOwnerCompaniesByCompanyIdSuperOwners: build.query<
        GetSuperOwnerCompaniesByCompanyIdSuperOwnersApiResponse,
        GetSuperOwnerCompaniesByCompanyIdSuperOwnersApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/super_owners`,
          params: {
            search: queryArg.search,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/SuperOwner"],
      }),
      getSuperOwnerCompaniesByCompanyIdOwners: build.query<
        GetSuperOwnerCompaniesByCompanyIdOwnersApiResponse,
        GetSuperOwnerCompaniesByCompanyIdOwnersApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/owners`,
          params: {
            search: queryArg.search,
            email: queryArg.email,
            search_statuses: queryArg.searchStatuses,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/Owner"],
      }),
      postSuperOwnerCompaniesByCompanyIdOwners: build.mutation<
        PostSuperOwnerCompaniesByCompanyIdOwnersApiResponse,
        PostSuperOwnerCompaniesByCompanyIdOwnersApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/owners`,
          method: "POST",
          body: queryArg.userNameAndEmailRequestBody,
          params: {
            search: queryArg.search,
            email: queryArg.email,
            search_statuses: queryArg.searchStatuses,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        invalidatesTags: ["SuperOwner/Owner"],
      }),
      deleteSuperOwnerCompaniesByCompanyIdOwnersAndId: build.mutation<
        DeleteSuperOwnerCompaniesByCompanyIdOwnersAndIdApiResponse,
        DeleteSuperOwnerCompaniesByCompanyIdOwnersAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/owners/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/Owner"],
      }),
      putSuperOwnerCompaniesByCompanyIdOwnersAndId: build.mutation<
        PutSuperOwnerCompaniesByCompanyIdOwnersAndIdApiResponse,
        PutSuperOwnerCompaniesByCompanyIdOwnersAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/owners/${queryArg.id}`,
          method: "PUT",
          body: queryArg.userNameRequestBody,
        }),
        invalidatesTags: ["SuperOwner/Owner"],
      }),
      postSuperOwnerCompaniesByCompanyIdOwnersAndIdAuthorizations:
        build.mutation<
          PostSuperOwnerCompaniesByCompanyIdOwnersAndIdAuthorizationsApiResponse,
          PostSuperOwnerCompaniesByCompanyIdOwnersAndIdAuthorizationsApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/companies/${queryArg.companyId}/owners/${queryArg.id}/authorizations`,
            method: "POST",
            body: queryArg.body,
          }),
          invalidatesTags: ["SuperOwner/Owner"],
        }),
      getSuperOwnerCompaniesByCompanyIdTeachers: build.query<
        GetSuperOwnerCompaniesByCompanyIdTeachersApiResponse,
        GetSuperOwnerCompaniesByCompanyIdTeachersApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/teachers`,
          params: {
            search: queryArg.search,
            email: queryArg.email,
            search_statuses: queryArg.searchStatuses,
            per_page: queryArg.perPage,
            page: queryArg.page,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["SuperOwner/Teacher"],
      }),
      getTeachersUserTagsAsSuperOwner: build.query<
        GetTeachersUserTagsAsSuperOwnerApiResponse,
        GetTeachersUserTagsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/teachers/${queryArg.teacherId}/user_tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Teachers"],
      }),
      getSuperOwnerCompaniesByCompanyIdStudents: build.query<
        GetSuperOwnerCompaniesByCompanyIdStudentsApiResponse,
        GetSuperOwnerCompaniesByCompanyIdStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/students`,
          params: {
            search: queryArg.search,
            email: queryArg.email,
            grade: queryArg.grade,
            grades: queryArg.grades,
            parent_name: queryArg.parentName,
            user_tag: queryArg.userTag,
            nickname: queryArg.nickname,
            search_statuses: queryArg.searchStatuses,
            per_page: queryArg.perPage,
            page: queryArg.page,
            sorted_by: queryArg.sortedBy,
          },
        }),
        providesTags: ["SuperOwner/Student"],
      }),
      getSuperOwnerCompaniesByCompanyIdStudentsListGrade: build.query<
        GetSuperOwnerCompaniesByCompanyIdStudentsListGradeApiResponse,
        GetSuperOwnerCompaniesByCompanyIdStudentsListGradeApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/students/list_grade`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/Student"],
      }),
      getStudentsUserTagsAsSuperOwner: build.query<
        GetStudentsUserTagsAsSuperOwnerApiResponse,
        GetStudentsUserTagsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/students/${queryArg.id}/user_tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Students"],
      }),
      getStudentsGroupAffiliatesAsSuperOwner: build.query<
        GetStudentsGroupAffiliatesAsSuperOwnerApiResponse,
        GetStudentsGroupAffiliatesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/students/${queryArg.id}/group_affiliates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Students"],
      }),
      fetchReviewersAsSuperOwner: build.query<
        FetchReviewersAsSuperOwnerApiResponse,
        FetchReviewersAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/reviewers`,
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["SuperOwner/Reviewer"],
      }),
      createReviewerAsSuperOwner: build.mutation<
        CreateReviewerAsSuperOwnerApiResponse,
        CreateReviewerAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/reviewers`,
          method: "POST",
          body: queryArg.userReviewersRequestBody,
        }),
        invalidatesTags: ["SuperOwner/Reviewer"],
      }),
      deleteReviewerAsSuperOwner: build.mutation<
        DeleteReviewerAsSuperOwnerApiResponse,
        DeleteReviewerAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/reviewers/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/Reviewer"],
      }),
      updateReviewerAsSuperOwner: build.mutation<
        UpdateReviewerAsSuperOwnerApiResponse,
        UpdateReviewerAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/reviewers/${queryArg.id}`,
          method: "PUT",
          body: queryArg.userReviewerRequestBody,
        }),
        invalidatesTags: ["SuperOwner/Reviewer"],
      }),
      createUserAsSuperOwner: build.mutation<
        CreateUserAsSuperOwnerApiResponse,
        CreateUserAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/users`,
          method: "POST",
          body: queryArg.postUserRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/User"],
      }),
      createAccountUserAsSuperOwner: build.mutation<
        CreateAccountUserAsSuperOwnerApiResponse,
        CreateAccountUserAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/users/create_account_user`,
          method: "POST",
          body: queryArg.createAccountUserRequestBody,
        }),
        invalidatesTags: ["SuperOwner/User"],
      }),
      createAccountAvailableAsSuperOwner: build.mutation<
        CreateAccountAvailableAsSuperOwnerApiResponse,
        CreateAccountAvailableAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/users/account_available`,
          method: "PUT",
          body: queryArg.affiliateIdRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/User"],
      }),
      resetPasswordAsSuperOwner: build.mutation<
        ResetPasswordAsSuperOwnerApiResponse,
        ResetPasswordAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/users/${queryArg.id}/reset_password`,
          method: "PUT",
          body: queryArg.authorizationPasswordRequestBody,
        }),
        invalidatesTags: ["SuperOwner/User"],
      }),
      getSuperOwnerCompaniesByCompanyIdUsersAndId: build.query<
        GetSuperOwnerCompaniesByCompanyIdUsersAndIdApiResponse,
        GetSuperOwnerCompaniesByCompanyIdUsersAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/users/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { role: queryArg.role },
        }),
        providesTags: ["SuperOwner/User"],
      }),
      putSuperOwnerCompaniesByCompanyIdUsersAndId: build.mutation<
        PutSuperOwnerCompaniesByCompanyIdUsersAndIdApiResponse,
        PutSuperOwnerCompaniesByCompanyIdUsersAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/users/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putUserRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/User"],
      }),
      deleteUserAsSuperOwner: build.mutation<
        DeleteUserAsSuperOwnerApiResponse,
        DeleteUserAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/users/${queryArg.id}`,
          method: "DELETE",
          body: queryArg.roleRequiredRequestBody,
        }),
        invalidatesTags: ["SuperOwner/User"],
      }),
      getSuperOwnerUserTags: build.query<
        GetSuperOwnerUserTagsApiResponse,
        GetSuperOwnerUserTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags`,
          params: {
            user_tag_type_id: queryArg.userTagTypeId,
            kind: queryArg.kind,
            page: queryArg.page,
            per_page: queryArg.perPage,
            role_type: queryArg.roleType,
          },
        }),
        providesTags: ["SuperOwner/UserTag"],
      }),
      postSuperOwnerUserTags: build.mutation<
        PostSuperOwnerUserTagsApiResponse,
        PostSuperOwnerUserTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags`,
          method: "POST",
          body: queryArg.postUserTagRequestBody,
        }),
        invalidatesTags: ["SuperOwner/UserTag"],
      }),
      getSuperOwnerUserTagsById: build.query<
        GetSuperOwnerUserTagsByIdApiResponse,
        GetSuperOwnerUserTagsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/super_owner/user_tags/${queryArg.id}` }),
        providesTags: ["SuperOwner/UserTag"],
      }),
      putSuperOwnerUserTagsById: build.mutation<
        PutSuperOwnerUserTagsByIdApiResponse,
        PutSuperOwnerUserTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putUserTagRequestBody,
        }),
        invalidatesTags: ["SuperOwner/UserTag"],
      }),
      deleteSuperOwnerUserTagsById: build.mutation<
        DeleteSuperOwnerUserTagsByIdApiResponse,
        DeleteSuperOwnerUserTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/UserTag"],
      }),
      putSuperOwnerUserTagsSequence: build.mutation<
        PutSuperOwnerUserTagsSequenceApiResponse,
        PutSuperOwnerUserTagsSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags/sequence`,
          method: "PUT",
          body: queryArg.userTagSequencesRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/UserTag"],
      }),
      postSuperOwnerUserTagsByIdAssign: build.mutation<
        PostSuperOwnerUserTagsByIdAssignApiResponse,
        PostSuperOwnerUserTagsByIdAssignApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags/${queryArg.id}/assign`,
          method: "POST",
          body: queryArg.assignUserTagRequestBody,
        }),
        invalidatesTags: ["SuperOwner/UserTag"],
      }),
      patchSuperOwnerUserTagsByIdAssignUpdate: build.mutation<
        PatchSuperOwnerUserTagsByIdAssignUpdateApiResponse,
        PatchSuperOwnerUserTagsByIdAssignUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags/${queryArg.id}/assign_update`,
          method: "PATCH",
          body: queryArg.assignUserTagRequestBody,
        }),
        invalidatesTags: ["SuperOwner/UserTag"],
      }),
      deleteSuperOwnerUserTagsByIdUnassign: build.mutation<
        DeleteSuperOwnerUserTagsByIdUnassignApiResponse,
        DeleteSuperOwnerUserTagsByIdUnassignApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags/${queryArg.id}/unassign`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["SuperOwner/UserTag"],
      }),
      getSuperOwnerUserTagsByIdDistributedStudents: build.query<
        GetSuperOwnerUserTagsByIdDistributedStudentsApiResponse,
        GetSuperOwnerUserTagsByIdDistributedStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags/${queryArg.id}/distributed_students`,
          params: {
            company_id: queryArg.companyId,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["SuperOwner/UserTag/Student"],
      }),
      postSuperOwnerCompanyTagTypes: build.mutation<
        PostSuperOwnerCompanyTagTypesApiResponse,
        PostSuperOwnerCompanyTagTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tag_types`,
          method: "POST",
          body: queryArg.nameRequestBody,
        }),
        invalidatesTags: ["SuperOwner/CompanyTagType"],
      }),
      putSuperOwnerCompanyTagTypesById: build.mutation<
        PutSuperOwnerCompanyTagTypesByIdApiResponse,
        PutSuperOwnerCompanyTagTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tag_types/${queryArg.id}`,
          method: "PUT",
          body: queryArg.nameRequestBody,
        }),
        invalidatesTags: ["SuperOwner/CompanyTagType"],
      }),
      deleteSuperOwnerCompanyTagTypesById: build.mutation<
        DeleteSuperOwnerCompanyTagTypesByIdApiResponse,
        DeleteSuperOwnerCompanyTagTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tag_types/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/CompanyTagType"],
      }),
      getSuperOwnerCreatedCompanyTagTypes: build.query<
        GetSuperOwnerCreatedCompanyTagTypesApiResponse,
        GetSuperOwnerCreatedCompanyTagTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/created_company_tag_types`,
          params: {
            company_tag_type_name: queryArg.companyTagTypeName,
            company_tag_name: queryArg.companyTagName,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/CompanyTagType"],
      }),
      putSuperOwnerCompanyTagTypesSequence: build.mutation<
        PutSuperOwnerCompanyTagTypesSequenceApiResponse,
        PutSuperOwnerCompanyTagTypesSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tag_types/sequence`,
          method: "PUT",
          body: queryArg.companyTagTypeSequencesRequestBody,
        }),
        invalidatesTags: ["SuperOwner/CompanyTagType"],
      }),
      putSuperOwnerUserTagTypesSequence: build.mutation<
        PutSuperOwnerUserTagTypesSequenceApiResponse,
        PutSuperOwnerUserTagTypesSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tag_types/sequence`,
          method: "PUT",
          body: queryArg.userTagTypeSequencesRequestBody,
        }),
        invalidatesTags: ["SuperOwner/UserTagType"],
      }),
      getUserTagDistributionHistoriesAsSuperOwner: build.query<
        GetUserTagDistributionHistoriesAsSuperOwnerApiResponse,
        GetUserTagDistributionHistoriesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tag_distribution_histories/`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            status: queryArg.status,
            company_name: queryArg.companyName,
            user_tag_name: queryArg.userTagName,
          },
        }),
        providesTags: ["SuperOwner/UserTagDistributionHistories"],
      }),
      getSuperOwnerCompaniesByCompanyIdCompanyTags: build.query<
        GetSuperOwnerCompaniesByCompanyIdCompanyTagsApiResponse,
        GetSuperOwnerCompaniesByCompanyIdCompanyTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/company_tags`,
        }),
        providesTags: ["SuperOwner/CompanyTag"],
      }),
      getSuperOwnerCompanyTags: build.query<
        GetSuperOwnerCompanyTagsApiResponse,
        GetSuperOwnerCompanyTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tags`,
          params: {
            company_tag_type_id: queryArg.companyTagTypeId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/CompanyTag"],
      }),
      postSuperOwnerCompanyTags: build.mutation<
        PostSuperOwnerCompanyTagsApiResponse,
        PostSuperOwnerCompanyTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tags`,
          method: "POST",
          body: queryArg.postCompanyTagRequestBody,
        }),
        invalidatesTags: ["SuperOwner/CompanyTag"],
      }),
      putSuperOwnerCompanyTagsById: build.mutation<
        PutSuperOwnerCompanyTagsByIdApiResponse,
        PutSuperOwnerCompanyTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tags/${queryArg.id}`,
          method: "PUT",
          body: queryArg.nameRequestBody,
        }),
        invalidatesTags: ["SuperOwner/CompanyTag"],
      }),
      deleteSuperOwnerCompanyTagsById: build.mutation<
        DeleteSuperOwnerCompanyTagsByIdApiResponse,
        DeleteSuperOwnerCompanyTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tags/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/CompanyTag"],
      }),
      putSuperOwnerCompanyTagsSequence: build.mutation<
        PutSuperOwnerCompanyTagsSequenceApiResponse,
        PutSuperOwnerCompanyTagsSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tags/sequence`,
          method: "PUT",
          body: queryArg.companyTagSequencesRequestBody,
        }),
        invalidatesTags: ["SuperOwner/CompanyTag"],
      }),
      postSuperOwnerCompanyTaggings: build.mutation<
        PostSuperOwnerCompanyTaggingsApiResponse,
        PostSuperOwnerCompanyTaggingsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_taggings`,
          method: "POST",
          body: queryArg.companyTaggingRequestBody,
        }),
        invalidatesTags: ["SuperOwner/CompanyTagging"],
      }),
      deleteSuperOwnerCompanyTaggings: build.mutation<
        DeleteSuperOwnerCompanyTaggingsApiResponse,
        DeleteSuperOwnerCompanyTaggingsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_taggings`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["SuperOwner/CompanyTagging"],
      }),
      getSuperOwnerHomeworkTemplates: build.query<
        GetSuperOwnerHomeworkTemplatesApiResponse,
        GetSuperOwnerHomeworkTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            undelivery: queryArg.undelivery,
            for_group: queryArg.forGroup,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            common_tag_name: queryArg.commonTagName,
          },
        }),
        providesTags: [
          "SuperOwner/HomeworkTemplate",
          "SuperOwner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      postSuperOwnerHomeworkTemplates: build.mutation<
        PostSuperOwnerHomeworkTemplatesApiResponse,
        PostSuperOwnerHomeworkTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates`,
          method: "POST",
          body: queryArg.homeworkTemplateCreateRequest,
        }),
        invalidatesTags: [
          "SuperOwner/HomeworkTemplate",
          "SuperOwner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      getSuperOwnerHomeworkTemplatesByHomeworkTemplateId: build.query<
        GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiResponse,
        GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}`,
        }),
        providesTags: [
          "SuperOwner/HomeworkTemplate",
          "SuperOwner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      putSuperOwnerHomeworkTemplatesByHomeworkTemplateId: build.mutation<
        PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiResponse,
        PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}`,
          method: "PUT",
          body: queryArg.homeworkTemplateUpdateRequest,
        }),
        invalidatesTags: [
          "SuperOwner/HomeworkTemplate",
          "SuperOwner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      deleteSuperOwnerHomeworkTemplatesByHomeworkTemplateId: build.mutation<
        DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiResponse,
        DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/HomeworkTemplate"],
      }),
      getSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributions:
        build.query<
          GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsApiResponse,
          GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/distributions`,
            headers: {
              "access-token": queryArg["access-token"],
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: [
            "SuperOwner/HomeworkTemplate",
            "SuperOwner/Homework",
            "Homework",
            "HomeworkTemplate",
          ],
        }),
      batchCreateHomeworkTemplateDistributionsAsSuperOwner: build.mutation<
        BatchCreateHomeworkTemplateDistributionsAsSuperOwnerApiResponse,
        BatchCreateHomeworkTemplateDistributionsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/distributions/batch_create`,
          method: "PATCH",
          body: queryArg.batchCreateHomeworkTemplateDistributionRequestBodyAsSuperOwner,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "SuperOwner/HomeworkTemplateDistribution",
          "SuperOwner/HomeworkTemplate",
          "SuperOwner/Homework",
          "Homework",
          "HomeworkTemplate",
          "HomeworkTemplateDistribution",
        ],
      }),
      batchUpdateHomeworkTemplateDistributionsAsSuperOwner: build.mutation<
        BatchUpdateHomeworkTemplateDistributionsAsSuperOwnerApiResponse,
        BatchUpdateHomeworkTemplateDistributionsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/distributions/batch_update`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "SuperOwner/HomeworkTemplateDistribution",
          "SuperOwner/HomeworkTemplate",
          "SuperOwner/Homework",
          "Homework",
          "HomeworkTemplate",
          "HomeworkTemplateDistribution",
        ],
      }),
      batchDeleteHomeworkTemplateDistributionsAsSuperOwner: build.mutation<
        BatchDeleteHomeworkTemplateDistributionsAsSuperOwnerApiResponse,
        BatchDeleteHomeworkTemplateDistributionsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/distributions/batch_delete`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "HomeworkTemplate",
          "HomeworkTemplateDistribution",
          "SuperOwner/HomeworkTemplate",
          "SuperOwner/HomeworkTemplateDistribution",
        ],
      }),
      getSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionId:
        build.query<
          GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiResponse,
          GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/distributions/${queryArg.distributionId}`,
            headers: {
              "access-token": queryArg["access-token"],
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          providesTags: [
            "SuperOwner/HomeworkTemplateDistribution",
            "SuperOwner/HomeworkTemplate",
            "SuperOwner/Homework",
            "Homework",
            "HomeworkTemplate",
          ],
        }),
      putSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionId:
        build.mutation<
          PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiResponse,
          PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/distributions/${queryArg.distributionId}`,
            method: "PUT",
            body: queryArg.putHomeworkTemplateDistributionRequestBodyAsSuperOwner,
          }),
          invalidatesTags: [
            "SuperOwner/HomeworkTemplateDistribution",
            "SuperOwner/HomeworkTemplate",
            "SuperOwner/Homework",
            "Homework",
            "HomeworkTemplate",
          ],
        }),
      deleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionId:
        build.mutation<
          DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiResponse,
          DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/distributions/${queryArg.distributionId}`,
            method: "DELETE",
          }),
          invalidatesTags: ["SuperOwner/HomeworkTemplateDistribution"],
        }),
      postHomeworkTemplateDuplicateAsSuperOwner: build.mutation<
        PostHomeworkTemplateDuplicateAsSuperOwnerApiResponse,
        PostHomeworkTemplateDuplicateAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.id}/duplicate`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/HomeworkTemplate", "HomeworkTemplate"],
      }),
      postHomeworkTemplateImageAsSuperOwner: build.mutation<
        PostHomeworkTemplateImageAsSuperOwnerApiResponse,
        PostHomeworkTemplateImageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/HomeworkTemplateImage"],
      }),
      putHomeworkTemplateImageAsSuperOwner: build.mutation<
        PutHomeworkTemplateImageAsSuperOwnerApiResponse,
        PutHomeworkTemplateImageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/HomeworkTemplateImage"],
      }),
      deleteHomeworkTemplateImageAsSuperOwner: build.mutation<
        DeleteHomeworkTemplateImageAsSuperOwnerApiResponse,
        DeleteHomeworkTemplateImageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_images/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/HomeworkTemplateImage"],
      }),
      getSuperOwnerCompaniesByCompanyIdUserTags: build.query<
        GetSuperOwnerCompaniesByCompanyIdUserTagsApiResponse,
        GetSuperOwnerCompaniesByCompanyIdUserTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/companies/${queryArg.companyId}/user_tags`,
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["SuperOwner/UserTag"],
      }),
      getSuperOwnerUserTagsByUserTagIdUsers: build.query<
        GetSuperOwnerUserTagsByUserTagIdUsersApiResponse,
        GetSuperOwnerUserTagsByUserTagIdUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/user_tags/${queryArg.userTagId}/users`,
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["SuperOwner/UserTag/User"],
      }),
      deleteSuperOwnerReservationsById: build.mutation<
        DeleteSuperOwnerReservationsByIdApiResponse,
        DeleteSuperOwnerReservationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/reservations/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/Reservation"],
      }),
      postSuperOwnerMaterialWorksBatchCreateUsingTags: build.mutation<
        PostSuperOwnerMaterialWorksBatchCreateUsingTagsApiResponse,
        PostSuperOwnerMaterialWorksBatchCreateUsingTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_works/batch_create_using_tags`,
          method: "POST",
          body: queryArg.batchCreateMaterialWorkRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/MaterialWork"],
      }),
      getSuperOwnerMaterials: build.query<
        GetSuperOwnerMaterialsApiResponse,
        GetSuperOwnerMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials`,
          params: {
            category_id: queryArg.categoryId,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["SuperOwner/Material"],
      }),
      postSuperOwnerMaterials: build.mutation<
        PostSuperOwnerMaterialsApiResponse,
        PostSuperOwnerMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials`,
          method: "POST",
          body: queryArg.createMaterialRequestBody,
        }),
        invalidatesTags: ["SuperOwner/Material"],
      }),
      getSuperOwnerMaterialsAllContainedCategory: build.query<
        GetSuperOwnerMaterialsAllContainedCategoryApiResponse,
        GetSuperOwnerMaterialsAllContainedCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials/all_contained_category`,
          params: {
            category_ids: queryArg.categoryIds,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["SuperOwner/Material"],
      }),
      getSuperOwnerMaterialsById: build.query<
        GetSuperOwnerMaterialsByIdApiResponse,
        GetSuperOwnerMaterialsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/super_owner/materials/${queryArg.id}` }),
        providesTags: ["SuperOwner/Material"],
      }),
      putSuperOwnerMaterialsById: build.mutation<
        PutSuperOwnerMaterialsByIdApiResponse,
        PutSuperOwnerMaterialsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateMaterialRequestBody,
        }),
        invalidatesTags: ["SuperOwner/Material"],
      }),
      deleteSuperOwnerMaterialsById: build.mutation<
        DeleteSuperOwnerMaterialsByIdApiResponse,
        DeleteSuperOwnerMaterialsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/Material"],
      }),
      putSuperOwnerMaterialsSequence: build.mutation<
        PutSuperOwnerMaterialsSequenceApiResponse,
        PutSuperOwnerMaterialsSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials/sequence`,
          method: "PUT",
          body: queryArg.materialSequencesRequiredRequestBody,
        }),
        invalidatesTags: ["SuperOwner/Material"],
      }),
      putMaterialImportHtmlAsSuperOwner: build.mutation<
        PutMaterialImportHtmlAsSuperOwnerApiResponse,
        PutMaterialImportHtmlAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials/${queryArg.materialId}/import_html`,
          method: "PUT",
          body: queryArg.zipFileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/Material"],
      }),
      postMaterialDuplicateAsSuperOwner: build.mutation<
        PostMaterialDuplicateAsSuperOwnerApiResponse,
        PostMaterialDuplicateAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/materials/${queryArg.id}/duplicate`,
          method: "POST",
          body: queryArg.materialCategoryIdRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/Material"],
      }),
      getSuperOwnerCategories: build.query<
        GetSuperOwnerCategoriesApiResponse,
        GetSuperOwnerCategoriesApiArg
      >({
        query: () => ({ url: `/super_owner/categories` }),
        providesTags: ["SuperOwner/Category"],
      }),
      postSuperOwnerCategories: build.mutation<
        PostSuperOwnerCategoriesApiResponse,
        PostSuperOwnerCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/categories`,
          method: "POST",
          body: queryArg.postCategoryRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/Category"],
      }),
      getSuperOwnerCategoriesById: build.query<
        GetSuperOwnerCategoriesByIdApiResponse,
        GetSuperOwnerCategoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/categories/${queryArg.id}`,
        }),
        providesTags: ["SuperOwner/Category"],
      }),
      putSuperOwnerCategoriesById: build.mutation<
        PutSuperOwnerCategoriesByIdApiResponse,
        PutSuperOwnerCategoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/categories/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putCategoryRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/Category"],
      }),
      deleteSuperOwnerCategoriesById: build.mutation<
        DeleteSuperOwnerCategoriesByIdApiResponse,
        DeleteSuperOwnerCategoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/categories/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SuperOwner/Category"],
      }),
      putSuperOwnerCategoriesSequence: build.mutation<
        PutSuperOwnerCategoriesSequenceApiResponse,
        PutSuperOwnerCategoriesSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/categories/sequence`,
          method: "PUT",
          body: queryArg.putCategorySequencesRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/Category"],
      }),
      getSuperOwnerCompanyTagsByCompanyTagIdCompanies: build.query<
        GetSuperOwnerCompanyTagsByCompanyTagIdCompaniesApiResponse,
        GetSuperOwnerCompanyTagsByCompanyTagIdCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/company_tags/${queryArg.companyTagId}/companies`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/CompanyTag/Company"],
      }),
      fetchRevieweeCompaniesAsSuperOwner: build.query<
        FetchRevieweeCompaniesAsSuperOwnerApiResponse,
        FetchRevieweeCompaniesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/reviewer_companies/${queryArg.reviewerCompanyId}/reviewee_companies`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/Company"],
      }),
      batchCreateRevieweeCompaniesAsSuperOwner: build.mutation<
        BatchCreateRevieweeCompaniesAsSuperOwnerApiResponse,
        BatchCreateRevieweeCompaniesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/reviewer_companies/${queryArg.reviewerCompanyId}/reviewee_companies/batch_create`,
          method: "PATCH",
          body: queryArg.revieweeCompaniesRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/Company"],
      }),
      batchDeleteRevieweeCompaniesAsSuperOwner: build.mutation<
        BatchDeleteRevieweeCompaniesAsSuperOwnerApiResponse,
        BatchDeleteRevieweeCompaniesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/reviewer_companies/${queryArg.reviewerCompanyId}/reviewee_companies/batch_delete`,
          method: "PATCH",
          body: queryArg.revieweeCompaniesRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/Company"],
      }),
      fetchAssignableCompaniesAsSuperOwner: build.query<
        FetchAssignableCompaniesAsSuperOwnerApiResponse,
        FetchAssignableCompaniesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/reviewer_companies/${queryArg.reviewerCompanyId}/assignable_companies`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            company_tag_ids: queryArg.companyTagIds,
            tag_filter: queryArg.tagFilter,
          },
        }),
        providesTags: ["SuperOwner/Company"],
      }),
      putSuperOwnerMaterialDistributionHistoryGroupsById: build.mutation<
        PutSuperOwnerMaterialDistributionHistoryGroupsByIdApiResponse,
        PutSuperOwnerMaterialDistributionHistoryGroupsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_distribution_history_groups/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["SuperOwner/MaterialDistributionHistoryGroup"],
      }),
      getSuperOwnerMaterialDistributionHistoriesList: build.query<
        GetSuperOwnerMaterialDistributionHistoriesListApiResponse,
        GetSuperOwnerMaterialDistributionHistoriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_distribution_histories/list`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            material_id: queryArg.materialId,
            sent_company_name: queryArg.sentCompanyName,
            user_tag_name: queryArg.userTagName,
          },
        }),
        providesTags: ["SuperOwner/MaterialDistributionHistory"],
      }),
      getSuperOwnerMaterialDistributionHistoriesListStudents: build.query<
        GetSuperOwnerMaterialDistributionHistoriesListStudentsApiResponse,
        GetSuperOwnerMaterialDistributionHistoriesListStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_distribution_histories/list_students`,
          params: {
            distribution_id: queryArg.distributionId,
            material_id: queryArg.materialId,
            sent_company_id: queryArg.sentCompanyId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/MaterialDistributionHistory"],
      }),
      getSuperOwnerMaterialDistributionHistoriesSearchByUserId: build.query<
        GetSuperOwnerMaterialDistributionHistoriesSearchByUserIdApiResponse,
        GetSuperOwnerMaterialDistributionHistoriesSearchByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_distribution_histories/search_by_user_id`,
          params: {
            user_id: queryArg.userId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/MaterialDistributionHistory"],
      }),
      getSuperOwnerHomeworkTemplatesDistributionHistories: build.query<
        GetSuperOwnerHomeworkTemplatesDistributionHistoriesApiResponse,
        GetSuperOwnerHomeworkTemplatesDistributionHistoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates_distribution_histories`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/HomeworkTemplateDistributionHistories"],
      }),
      getSuperOwnerHomeworkTemplateDistributionHistoryAffiliates: build.query<
        GetSuperOwnerHomeworkTemplateDistributionHistoryAffiliatesApiResponse,
        GetSuperOwnerHomeworkTemplateDistributionHistoryAffiliatesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/homework_templates_distribution_histories/${queryArg.homeworkTemplateDistributionHistoryId}/affiliates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/HomeworkTemplateDistributionHistories"],
      }),
      getSuperOwnerHomeworkTemplatesDistributionHistoriesByHomeworkTemplateDistributionHistoryIdCompanies:
        build.query<
          GetSuperOwnerHomeworkTemplatesDistributionHistoriesByHomeworkTemplateDistributionHistoryIdCompaniesApiResponse,
          GetSuperOwnerHomeworkTemplatesDistributionHistoriesByHomeworkTemplateDistributionHistoryIdCompaniesApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates_distribution_histories/${queryArg.homeworkTemplateDistributionHistoryId}/companies`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: ["SuperOwner/HomeworkTemplateDistributionHistories"],
        }),
      getSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFields:
        build.query<
          GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse,
          GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: ["SuperOwner/HomeworkTemplateCustomFields"],
        }),
      postSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFields:
        build.mutation<
          PostSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse,
          PostSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields`,
            method: "POST",
            body: queryArg.body,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["SuperOwner/HomeworkTemplateCustomFields"],
        }),
      getSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndId:
        build.query<
          GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse,
          GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/${queryArg.id}`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          providesTags: ["SuperOwner/HomeworkTemplateCustomFields"],
        }),
      putSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndId:
        build.mutation<
          PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse,
          PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/${queryArg.id}`,
            method: "PUT",
            body: queryArg.body,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["SuperOwner/HomeworkTemplateCustomFields"],
        }),
      deleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndId:
        build.mutation<
          DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse,
          DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/${queryArg.id}`,
            method: "DELETE",
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["SuperOwner/HomeworkTemplateCustomFields"],
        }),
      getSuperOwnerInquiries: build.query<
        GetSuperOwnerInquiriesApiResponse,
        GetSuperOwnerInquiriesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/inquiries`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            status: queryArg.status,
            kind: queryArg.kind,
            person_in_charge_name: queryArg.personInChargeName,
            company_name: queryArg.companyName,
            user_name: queryArg.userName,
          },
        }),
        providesTags: ["SuperOwner/Inquiries"],
      }),
      getSuperOwnerInquiriesById: build.query<
        GetSuperOwnerInquiriesByIdApiResponse,
        GetSuperOwnerInquiriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/inquiries/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Inquiries"],
      }),
      putSuperOwnerInquiriesById: build.mutation<
        PutSuperOwnerInquiriesByIdApiResponse,
        PutSuperOwnerInquiriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/inquiries/${queryArg.id}`,
          method: "PUT",
          body: queryArg.inquiryIsNewRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/Inquiries"],
      }),
      getTheNumberOfNewCommentInquiriesAsSuperOwner: build.query<
        GetTheNumberOfNewCommentInquiriesAsSuperOwnerApiResponse,
        GetTheNumberOfNewCommentInquiriesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/inquiries/new_comment_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Inquiries"],
      }),
      getTheNumberOfNewInquiryInquiriesAsSuperOwner: build.query<
        GetTheNumberOfNewInquiryInquiriesAsSuperOwnerApiResponse,
        GetTheNumberOfNewInquiryInquiriesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/inquiries/new_inquiry_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Inquiries"],
      }),
      postSuperOwnerInquiryComments: build.mutation<
        PostSuperOwnerInquiryCommentsApiResponse,
        PostSuperOwnerInquiryCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/inquiry_comments`,
          method: "POST",
          body: queryArg.bodyRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["SuperOwner/InquiryComments"],
      }),
      putSuperOwnerInquiryCommentsById: build.mutation<
        PutSuperOwnerInquiryCommentsByIdApiResponse,
        PutSuperOwnerInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/inquiry_comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.inquiryCommentRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["SuperOwner/InquiryComments"],
      }),
      deleteSuperOwnerInquiryCommentsById: build.mutation<
        DeleteSuperOwnerInquiryCommentsByIdApiResponse,
        DeleteSuperOwnerInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/inquiry_comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["SuperOwner/InquiryComments"],
      }),
      getLearningTemplatePackagesAsSuperOwner: build.query<
        GetLearningTemplatePackagesAsSuperOwnerApiResponse,
        GetLearningTemplatePackagesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/learning_template_packages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            title: queryArg.title,
          },
        }),
        providesTags: ["SuperOwner/LearningTemplatePackages"],
      }),
      postLearningTemplatePackageAsSuperOwner: build.mutation<
        PostLearningTemplatePackageAsSuperOwnerApiResponse,
        PostLearningTemplatePackageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/learning_template_packages`,
          method: "POST",
          body: queryArg.titleAndDescriptionRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/LearningTemplatePackages"],
      }),
      getLearningTemplatePackageAsSuperOwner: build.query<
        GetLearningTemplatePackageAsSuperOwnerApiResponse,
        GetLearningTemplatePackageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/learning_template_packages/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/LearningTemplatePackages"],
      }),
      putLearningTemplatePackageAsSuperOwner: build.mutation<
        PutLearningTemplatePackageAsSuperOwnerApiResponse,
        PutLearningTemplatePackageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/learning_template_packages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.titleAndDescriptionRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/LearningTemplatePackages"],
      }),
      deleteLearningTemplatePackagAsSuperOwner: build.mutation<
        DeleteLearningTemplatePackagAsSuperOwnerApiResponse,
        DeleteLearningTemplatePackagAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/learning_template_packages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/LearningTemplatePackages"],
      }),
      putLearningTemplatePackagesSequenceAsSuperOwner: build.mutation<
        PutLearningTemplatePackagesSequenceAsSuperOwnerApiResponse,
        PutLearningTemplatePackagesSequenceAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/learning_template_packages/sequence`,
          method: "PUT",
          body: queryArg.learningTemplatePackageSequencesRequestBody,
        }),
        invalidatesTags: ["SuperOwner/LearningTemplatePackages"],
      }),
      getLearningTemplatePackageDistributionsAsSuperOwner: build.query<
        GetLearningTemplatePackageDistributionsAsSuperOwnerApiResponse,
        GetLearningTemplatePackageDistributionsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/learning_template_package_distributions`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/LearningTemplatePackageDistributions"],
      }),
      deleteLearningTemplatePackageDistributionAsSuperOwner: build.mutation<
        DeleteLearningTemplatePackageDistributionAsSuperOwnerApiResponse,
        DeleteLearningTemplatePackageDistributionAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/learning_template_package_distributions/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/LearningTemplatePackageDistributions"],
      }),
      patchLearningTemplatePackageDistributionsBatchCreateAsSuperOwner:
        build.mutation<
          PatchLearningTemplatePackageDistributionsBatchCreateAsSuperOwnerApiResponse,
          PatchLearningTemplatePackageDistributionsBatchCreateAsSuperOwnerApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/learning_template_packages/${queryArg.learningTemplatePackageId}/learning_template_package_distributions/batch_create`,
            method: "PATCH",
            body: queryArg.batchCreateLearningTemplatePackageDistributionRequiredRequestBody,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["SuperOwner/LearningTemplatePackageDistributions"],
        }),
      getLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwner:
        build.query<
          GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerApiResponse,
          GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/learning_template_packages/${queryArg.learningTemplatePackageId}/learning_template_package_distributions/target_student_affiliates_from_companies`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: {
              page: queryArg.page,
              per_page: queryArg.perPage,
              company_ids: queryArg.companyIds,
              grade: queryArg.grade,
            },
          }),
          providesTags: ["SuperOwner/LearningTemplatePackageDistributions"],
        }),
      getLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwner:
        build.query<
          GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerApiResponse,
          GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/learning_template_packages/${queryArg.learningTemplatePackageId}/learning_template_package_distributions/target_student_affiliates_from_user_tags`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: {
              page: queryArg.page,
              per_page: queryArg.perPage,
              user_tag_ids: queryArg.userTagIds,
              grade: queryArg.grade,
            },
          }),
          providesTags: ["SuperOwner/LearningTemplatePackageDistributions"],
        }),
      getLearningTemplatePackageDistributionHistoriesAsSuperOwner: build.query<
        GetLearningTemplatePackageDistributionHistoriesAsSuperOwnerApiResponse,
        GetLearningTemplatePackageDistributionHistoriesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/learning_template_package_distribution_histories`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: [
          "SuperOwner/LearningTemplatePackageDistributionHistories",
        ],
      }),
      putLearningTemplatePackageDistributionHistoriesAsSuperOwner:
        build.mutation<
          PutLearningTemplatePackageDistributionHistoriesAsSuperOwnerApiResponse,
          PutLearningTemplatePackageDistributionHistoriesAsSuperOwnerApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/learning_template_package_distribution_histories/${queryArg.id}`,
            method: "PUT",
            body: queryArg.startAtAndEndAtRequestBody,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: [
            "SuperOwner/LearningTemplatePackageDistributionHistories",
          ],
        }),
      getLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwner:
        build.query<
          GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwnerApiResponse,
          GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwnerApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/learning_template_package_distribution_histories/${queryArg.id}/distributed_students`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: [
            "SuperOwner/LearningTemplatePackageDistributionHistories",
          ],
        }),
      getMaterialHomeworkTemplatePackagesAsSuperOwner: build.query<
        GetMaterialHomeworkTemplatePackagesAsSuperOwnerApiResponse,
        GetMaterialHomeworkTemplatePackagesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_homework_template_packages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/MaterialHomeworkTemplatePackages"],
      }),
      postMaterialHomeworkTemplatePackageAsSuperOwner: build.mutation<
        PostMaterialHomeworkTemplatePackageAsSuperOwnerApiResponse,
        PostMaterialHomeworkTemplatePackageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_homework_template_packages`,
          method: "POST",
          body: queryArg.titleAndDescriptionRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/MaterialHomeworkTemplatePackages"],
      }),
      getMaterialHomeworkTemplatePackageAsSuperOwner: build.query<
        GetMaterialHomeworkTemplatePackageAsSuperOwnerApiResponse,
        GetMaterialHomeworkTemplatePackageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_homework_template_packages/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/MaterialHomeworkTemplatePackages"],
      }),
      putMaterialHomeworkTemplatePackageAsSuperOwner: build.mutation<
        PutMaterialHomeworkTemplatePackageAsSuperOwnerApiResponse,
        PutMaterialHomeworkTemplatePackageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_homework_template_packages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.titleAndDescriptionRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/MaterialHomeworkTemplatePackages"],
      }),
      deleteMaterialHomeworkTemplatePackageAsSuperOwner: build.mutation<
        DeleteMaterialHomeworkTemplatePackageAsSuperOwnerApiResponse,
        DeleteMaterialHomeworkTemplatePackageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_homework_template_packages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/MaterialHomeworkTemplatePackages"],
      }),
      putMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwner:
        build.mutation<
          PutMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwnerApiResponse,
          PutMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwnerApiArg
        >({
          query: (queryArg) => ({
            url: `/super_owner/material_homework_template_packages/${queryArg.id}/packagings/${queryArg.packagingId}/sequence`,
            method: "PUT",
            body: queryArg.sequenceRequiredRequestBody,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: [
            "SuperOwner/MaterialHomeworkTemplatePackagePackagingSequence",
          ],
        }),
      getSuperOwnerMaterialWorksTargetCompanies: build.query<
        GetSuperOwnerMaterialWorksTargetCompaniesApiResponse,
        GetSuperOwnerMaterialWorksTargetCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_works/target_companies`,
          params: {
            material_id: queryArg.materialId,
            company_ids: queryArg.companyIds,
            user_tag_ids: queryArg.userTagIds,
            grade: queryArg.grade,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/MaterialWork"],
      }),
      getSuperOwnerMaterialWorksTargetMaterials: build.query<
        GetSuperOwnerMaterialWorksTargetMaterialsApiResponse,
        GetSuperOwnerMaterialWorksTargetMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/material_works/target_materials`,
          params: {
            category_id: queryArg.categoryId,
            company_ids: queryArg.companyIds,
            user_tag_ids: queryArg.userTagIds,
            grade: queryArg.grade,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/MaterialWork"],
      }),
      postPackagingAsSuperOwner: build.mutation<
        PostPackagingAsSuperOwnerApiResponse,
        PostPackagingAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/packagings`,
          method: "POST",
          body: queryArg.packagingRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/Packaging"],
      }),
      putPackagingAsSuperOwner: build.mutation<
        PutPackagingAsSuperOwnerApiResponse,
        PutPackagingAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/packagings`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            package_id: queryArg.packageId,
            packageable_type: queryArg.packageableType,
            packageable_id: queryArg.packageableId,
          },
        }),
        invalidatesTags: ["SuperOwner/Packaging"],
      }),
      deletePackagingAsSuperOwner: build.mutation<
        DeletePackagingAsSuperOwnerApiResponse,
        DeletePackagingAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/packagings`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            package_id: queryArg.packageId,
            packageable_type: queryArg.packageableType,
            packageable_id: queryArg.packageableId,
          },
        }),
        invalidatesTags: ["SuperOwner/Packaging"],
      }),
      getPackagingsAsSuperOwner: build.query<
        GetPackagingsAsSuperOwnerApiResponse,
        GetPackagingsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/packagings/list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            package_id: queryArg.packageId,
          },
        }),
        providesTags: ["SuperOwner/Packaging"],
      }),
      getPblBoardInfoAsSuperOwner: build.query<
        GetPblBoardInfoAsSuperOwnerApiResponse,
        GetPblBoardInfoAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/infos`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            preview: queryArg.preview,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["SuperOwner/PblBoardInfos"],
      }),
      postPblBoardInfoAsSuperOwner: build.mutation<
        PostPblBoardInfoAsSuperOwnerApiResponse,
        PostPblBoardInfoAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/infos`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardInfos"],
      }),
      putPblBoardInfoAsSuperOwner: build.mutation<
        PutPblBoardInfoAsSuperOwnerApiResponse,
        PutPblBoardInfoAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/infos`,
          method: "PUT",
          body: queryArg.pblBoardInfoEventRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardInfos"],
      }),
      postPblBoardItemAsSuperOwner: build.mutation<
        PostPblBoardItemAsSuperOwnerApiResponse,
        PostPblBoardItemAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items`,
          method: "POST",
          body: queryArg.pblBoardItemRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardItems"],
      }),
      putPblBoardItemAsSuperOwner: build.mutation<
        PutPblBoardItemAsSuperOwnerApiResponse,
        PutPblBoardItemAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.pblBoardItemRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardItems"],
      }),
      deletePblBoardItemAsSuperOwner: build.mutation<
        DeletePblBoardItemAsSuperOwnerApiResponse,
        DeletePblBoardItemAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardItems"],
      }),
      getPblBoardCampaignsAsSuperOwner: build.query<
        GetPblBoardCampaignsAsSuperOwnerApiResponse,
        GetPblBoardCampaignsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/campaigns`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/PblBoardCampaigns"],
      }),
      postPblBoardCampaignAsSuperOwner: build.mutation<
        PostPblBoardCampaignAsSuperOwnerApiResponse,
        PostPblBoardCampaignAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/campaigns`,
          method: "POST",
          body: queryArg.postPblBoardCampaignRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardCampaigns"],
      }),
      getPblBoardCampaignAsSuperOwner: build.query<
        GetPblBoardCampaignAsSuperOwnerApiResponse,
        GetPblBoardCampaignAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/campaigns/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/PblBoardCampaigns"],
      }),
      putPblBoardCampaignAsSuperOwner: build.mutation<
        PutPblBoardCampaignAsSuperOwnerApiResponse,
        PutPblBoardCampaignAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/campaigns/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putPblBoardCampaignRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardCampaigns"],
      }),
      deletePblBoardCampaignAsSuperOwner: build.mutation<
        DeletePblBoardCampaignAsSuperOwnerApiResponse,
        DeletePblBoardCampaignAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/campaigns/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardCampaigns"],
      }),
      postPblBoardCampaignRequestAsSuperOwner: build.query<
        PostPblBoardCampaignRequestAsSuperOwnerApiResponse,
        PostPblBoardCampaignRequestAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/campaigns/${queryArg.campaignId}/campaign_requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_status: queryArg.searchStatus,
          },
        }),
        providesTags: ["SuperOwner/PblBoardCampaignRequests"],
      }),
      putPblBoardCampaignRequestAsSuperOwner: build.mutation<
        PutPblBoardCampaignRequestAsSuperOwnerApiResponse,
        PutPblBoardCampaignRequestAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/campaigns/${queryArg.campaignId}/campaign_requests/${queryArg.id}`,
          method: "PUT",
          body: queryArg.pblBoardCampaignRequestEventRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardCampaignRequests"],
      }),
      putPblBoardCampaignRequestSequenceAsSuperOwner: build.mutation<
        PutPblBoardCampaignRequestSequenceAsSuperOwnerApiResponse,
        PutPblBoardCampaignRequestSequenceAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/campaigns/${queryArg.campaignId}/campaign_requests/${queryArg.id}/sequence`,
          method: "PUT",
          body: queryArg.sequenceRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardCampaignRequests"],
      }),
      getPblBoardArticlesAsSuperOwner: build.query<
        GetPblBoardArticlesAsSuperOwnerApiResponse,
        GetPblBoardArticlesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/articles`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/PblBoardArticles"],
      }),
      getPblBoardArticleAsSuperOwner: build.query<
        GetPblBoardArticleAsSuperOwnerApiResponse,
        GetPblBoardArticleAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/articles/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/PblBoardArticles"],
      }),
      postPblBoardContentAsSuperOwner: build.mutation<
        PostPblBoardContentAsSuperOwnerApiResponse,
        PostPblBoardContentAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/contents`,
          method: "POST",
          body: queryArg.pblBoardContentRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardContents"],
      }),
      getPblBoardContentAsSuperOwner: build.query<
        GetPblBoardContentAsSuperOwnerApiResponse,
        GetPblBoardContentAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/contents/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/PblBoardContents"],
      }),
      putPblBoardContentAsSuperOwner: build.mutation<
        PutPblBoardContentAsSuperOwnerApiResponse,
        PutPblBoardContentAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/contents/${queryArg.id}`,
          method: "PUT",
          body: queryArg.pblBoardContentRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardContents"],
      }),
      deletePblBoardContentAsSuperOwner: build.mutation<
        DeletePblBoardContentAsSuperOwnerApiResponse,
        DeletePblBoardContentAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/contents/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardContents"],
      }),
      getPblBoardFootersAsSuperOwner: build.query<
        GetPblBoardFootersAsSuperOwnerApiResponse,
        GetPblBoardFootersAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/footers`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/PblBoardFooters"],
      }),
      postPblBoardFooterAsSuperOwner: build.mutation<
        PostPblBoardFooterAsSuperOwnerApiResponse,
        PostPblBoardFooterAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/footers`,
          method: "POST",
          body: queryArg.postPblBoardFooterRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardFooters"],
      }),
      putPblBoardFooterAsSuperOwner: build.mutation<
        PutPblBoardFooterAsSuperOwnerApiResponse,
        PutPblBoardFooterAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putPblBoardFooterRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardFooters"],
      }),
      deletePblBoardFooterAsSuperOwner: build.mutation<
        DeletePblBoardFooterAsSuperOwnerApiResponse,
        DeletePblBoardFooterAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardFooters"],
      }),
      postPblBoardFooterItemAsSuperOwner: build.mutation<
        PostPblBoardFooterItemAsSuperOwnerApiResponse,
        PostPblBoardFooterItemAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.footerId}/footer_items`,
          method: "POST",
          body: queryArg.pblBoardFooterItemRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardFooterItems"],
      }),
      putPblBoardFooterItemAsSuperOwner: build.mutation<
        PutPblBoardFooterItemAsSuperOwnerApiResponse,
        PutPblBoardFooterItemAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.footerId}/footer_items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.pblBoardFooterItemRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardFooterItems"],
      }),
      deletePblBoardFooterItemAsSuperOwner: build.mutation<
        DeletePblBoardFooterItemAsSuperOwnerApiResponse,
        DeletePblBoardFooterItemAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.footerId}/footer_items/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/PblBoardFooterItems"],
      }),
      affiliateUpdateAsSuperOwner: build.mutation<
        AffiliateUpdateAsSuperOwnerApiResponse,
        AffiliateUpdateAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/affiliates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.affiliateStudentSuspensionRequiredRequestBody,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["SuperOwner/Affiliate"],
      }),
      affiliatesUpdateAsSuperOwner: build.mutation<
        AffiliatesUpdateAsSuperOwnerApiResponse,
        AffiliatesUpdateAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/affiliates/bulk_update`,
          method: "PUT",
          body: queryArg.bulkUpdateRequestBody,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["SuperOwner/Affiliate"],
      }),
      affiliatesDestroyAsSuperOwner: build.mutation<
        AffiliatesDestroyAsSuperOwnerApiResponse,
        AffiliatesDestroyAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/affiliates/bulk_destroy`,
          method: "DELETE",
          body: queryArg.idsRequiredRequestBody,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["SuperOwner/Affiliate"],
      }),
      postAttachedImageAsSuperOwner: build.mutation<
        PostAttachedImageAsSuperOwnerApiResponse,
        PostAttachedImageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/attached_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["SuperOwner/AttachedImages"],
      }),
      getAttachedImageAsSuperOwner: build.query<
        GetAttachedImageAsSuperOwnerApiResponse,
        GetAttachedImageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/attached_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["SuperOwner/AttachedImages"],
      }),
      putAttachedImageAsSuperOwner: build.mutation<
        PutAttachedImageAsSuperOwnerApiResponse,
        PutAttachedImageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/attached_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["SuperOwner/AttachedImages"],
      }),
      deleteAttachedImageAsSuperOwner: build.mutation<
        DeleteAttachedImageAsSuperOwnerApiResponse,
        DeleteAttachedImageAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/attached_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["SuperOwner/AttachedImages"],
      }),
      getLimitedTimeNotificationsAsSuperOwner: build.query<
        GetLimitedTimeNotificationsAsSuperOwnerApiResponse,
        GetLimitedTimeNotificationsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/limited_time_notifications`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/LimitedTimeNotifications"],
      }),
      postLimitedTimeNotificationAsSuperOwner: build.mutation<
        PostLimitedTimeNotificationAsSuperOwnerApiResponse,
        PostLimitedTimeNotificationAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/limited_time_notifications`,
          method: "POST",
          body: queryArg.postLimitedTimeNotificationRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/LimitedTimeNotifications"],
      }),
      getLimitedTimeNotificationAsSuperOwner: build.query<
        GetLimitedTimeNotificationAsSuperOwnerApiResponse,
        GetLimitedTimeNotificationAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/limited_time_notifications/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/LimitedTimeNotifications"],
      }),
      putLimitedTimeNotificationAsSuperOwner: build.mutation<
        PutLimitedTimeNotificationAsSuperOwnerApiResponse,
        PutLimitedTimeNotificationAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/limited_time_notifications/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putLimitedTimeNotificationRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/LimitedTimeNotifications"],
      }),
      deleteLimitedTimeNotificationAsSuperOwner: build.mutation<
        DeleteLimitedTimeNotificationAsSuperOwnerApiResponse,
        DeleteLimitedTimeNotificationAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/limited_time_notifications/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/LimitedTimeNotifications"],
      }),
      getSteamContentsAsSuperOwner: build.query<
        GetSteamContentsAsSuperOwnerApiResponse,
        GetSteamContentsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/steam/contents`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/Steam/Contents"],
      }),
      getSteamLecturesAsSuperOwner: build.query<
        GetSteamLecturesAsSuperOwnerApiResponse,
        GetSteamLecturesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/steam/lectures`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            sl_content_id: queryArg.slContentId,
          },
        }),
        providesTags: ["SuperOwner/Steam/Lectures"],
      }),
      postSuperOwnerSteamLectures: build.mutation<
        PostSuperOwnerSteamLecturesApiResponse,
        PostSuperOwnerSteamLecturesApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/steam/lectures`,
          method: "POST",
          body: queryArg.createSteamLectureRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { sl_content_id: queryArg.slContentId },
        }),
        invalidatesTags: ["SuperOwner/Steam/Lectures"],
      }),
      getSuperOwnerSteamLecturesId: build.query<
        GetSuperOwnerSteamLecturesIdApiResponse,
        GetSuperOwnerSteamLecturesIdApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/pbl/steam/lectures/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Steam/Lectures"],
      }),
      fetchSponsorProjectsAsSuperOwner: build.query<
        FetchSponsorProjectsAsSuperOwnerApiResponse,
        FetchSponsorProjectsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/sponsor/projects`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/Sponsor/Projects"],
      }),
      fetchSponsorLecturesAsSuperOwner: build.query<
        FetchSponsorLecturesAsSuperOwnerApiResponse,
        FetchSponsorLecturesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/sponsor/projects/lectures`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            project_id: queryArg.projectId,
          },
        }),
        providesTags: ["SuperOwner/Sponsor/Lectures"],
      }),
      fetchSponsorLectureAsSuperOwner: build.query<
        FetchSponsorLectureAsSuperOwnerApiResponse,
        FetchSponsorLectureAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/sponsor/projects/lectures/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/Sponsor/Lectures"],
      }),
      getTtCsvExportsAsSuperOwner: build.query<
        GetTtCsvExportsAsSuperOwnerApiResponse,
        GetTtCsvExportsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/tt_csv/exports`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            types: queryArg.types,
          },
        }),
        providesTags: ["SuperOwner/TtCsvExports"],
      }),
      postTtCsvExportAsSuperOwner: build.mutation<
        PostTtCsvExportAsSuperOwnerApiResponse,
        PostTtCsvExportAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/tt_csv/exports`,
          method: "POST",
          body: queryArg.ttCsvExportRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            sorted_by: queryArg.sortedBy,
            search_status: queryArg.searchStatus,
            search_type: queryArg.searchType,
            search_value: queryArg.searchValue,
          },
        }),
        invalidatesTags: ["SuperOwner/TtCsvExports"],
      }),
      postTtCategoryBaseAsSuperOwner: build.mutation<
        PostTtCategoryBaseAsSuperOwnerApiResponse,
        PostTtCategoryBaseAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/tt_category/bases`,
          method: "POST",
          body: queryArg.createTtCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/TtCategoryBases"],
      }),
      putTtCategoryBaseAsSuperOwner: build.mutation<
        PutTtCategoryBaseAsSuperOwnerApiResponse,
        PutTtCategoryBaseAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/tt_category/bases/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateTtCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/TtCategoryBases"],
      }),
      getFeedbackTemplateHomeworksAsSuperOwner: build.query<
        GetFeedbackTemplateHomeworksAsSuperOwnerApiResponse,
        GetFeedbackTemplateHomeworksAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/feedback_template/homeworks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["SuperOwner/FeedbackTemplate/Homework"],
      }),
      postFeedbackTemplateHomeworkAsSuperOwner: build.mutation<
        PostFeedbackTemplateHomeworkAsSuperOwnerApiResponse,
        PostFeedbackTemplateHomeworkAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/feedback_template/homeworks`,
          method: "POST",
          body: queryArg.postFeedbackTemplateHomeworkRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/FeedbackTemplate/Homework"],
      }),
      getFeedbackTemplateHomeworkAsSuperOwner: build.query<
        GetFeedbackTemplateHomeworkAsSuperOwnerApiResponse,
        GetFeedbackTemplateHomeworkAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/feedback_template/homeworks/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/FeedbackTemplate/Homework"],
      }),
      putFeedbackTemplateHomeworkAsSuperOwner: build.mutation<
        PutFeedbackTemplateHomeworkAsSuperOwnerApiResponse,
        PutFeedbackTemplateHomeworkAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/feedback_template/homeworks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putFeedbackTemplateHomeworkRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/FeedbackTemplate/Homework"],
      }),
      deleteFeedbackTemplateHomeworkAsSuperOwner: build.mutation<
        DeleteFeedbackTemplateHomeworkAsSuperOwnerApiResponse,
        DeleteFeedbackTemplateHomeworkAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/feedback_template/homeworks/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/FeedbackTemplate/Homework"],
      }),
      postFeedbackTemplateCommentAsSuperOwner: build.mutation<
        PostFeedbackTemplateCommentAsSuperOwnerApiResponse,
        PostFeedbackTemplateCommentAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/feedback_template/homeworks/${queryArg.feedbackTemplateId}/comments`,
          method: "POST",
          body: queryArg.bodyRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/FeedbackTemplate/Comments"],
      }),
      putFeedbackTemplateCommentAsSuperOwner: build.mutation<
        PutFeedbackTemplateCommentAsSuperOwnerApiResponse,
        PutFeedbackTemplateCommentAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/feedback_template/homeworks/${queryArg.feedbackTemplateId}/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.bodyRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/FeedbackTemplate/Comments"],
      }),
      deleteFeedbackTemplateCommentAsSuperOwner: build.mutation<
        DeleteFeedbackTemplateCommentAsSuperOwnerApiResponse,
        DeleteFeedbackTemplateCommentAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/feedback_template/homeworks/${queryArg.feedbackTemplateId}/comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/FeedbackTemplate/Comments"],
      }),
      getFeedbackTemplateTagsAsSuperOwner: build.query<
        GetFeedbackTemplateTagsAsSuperOwnerApiResponse,
        GetFeedbackTemplateTagsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/feedback_template/tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["SuperOwner/FeedbackTemplate/Tag"],
      }),
      fetchHelpContentsAsSuperOwner: build.query<
        FetchHelpContentsAsSuperOwnerApiResponse,
        FetchHelpContentsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/help/contents`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            kind: queryArg.kind,
          },
        }),
        providesTags: ["SuperOwner/Help/Contents"],
      }),
      putHelpContentsAsSuperOwner: build.mutation<
        PutHelpContentsAsSuperOwnerApiResponse,
        PutHelpContentsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/help/contents/${queryArg.id}`,
          method: "PUT",
          body: queryArg.helpRequestBodyAsSuperOwner,
        }),
        invalidatesTags: ["SuperOwner/Help/Contents"],
      }),
      getIdealBoardTemplatesAsSuperOwner: build.query<
        GetIdealBoardTemplatesAsSuperOwnerApiResponse,
        GetIdealBoardTemplatesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/board_templates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_status: queryArg.searchStatus,
            no_homework_template: queryArg.noHomeworkTemplate,
          },
        }),
        providesTags: ["SuperOwner/IdealBoardTemplates"],
      }),
      postIdealBoardTemplatesAsSuperOwner: build.mutation<
        PostIdealBoardTemplatesAsSuperOwnerApiResponse,
        PostIdealBoardTemplatesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/board_templates`,
          method: "POST",
          body: queryArg.postIdealBoardTemplateRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/IdealBoardTemplates"],
      }),
      getIdealBoardTemplateAsSuperOwner: build.query<
        GetIdealBoardTemplateAsSuperOwnerApiResponse,
        GetIdealBoardTemplateAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/board_templates/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/IdealBoardTemplate"],
      }),
      putIdealBoardTemplateAsSuperOwner: build.mutation<
        PutIdealBoardTemplateAsSuperOwnerApiResponse,
        PutIdealBoardTemplateAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/board_templates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putIdealBoardTemplateRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/IdealBoardTemplate"],
      }),
      deleteIdealBoardTemplateAsSuperOwner: build.mutation<
        DeleteIdealBoardTemplateAsSuperOwnerApiResponse,
        DeleteIdealBoardTemplateAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/board_templates/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/IdealBoardTemplate"],
      }),
      getIdealBoardsAsSuperOwner: build.query<
        GetIdealBoardsAsSuperOwnerApiResponse,
        GetIdealBoardsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/boards`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            ideal_board_template_id: queryArg.idealBoardTemplateId,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: ["SuperOwner/IdealBoards"],
      }),
      postIdealBoardsAsSuperOwner: build.mutation<
        PostIdealBoardsAsSuperOwnerApiResponse,
        PostIdealBoardsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/boards`,
          method: "POST",
          body: queryArg.postIdealBoardRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/IdealBoards"],
      }),
      getIdealBoardAsSuperOwner: build.query<
        GetIdealBoardAsSuperOwnerApiResponse,
        GetIdealBoardAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/boards/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["SuperOwner/IdealBoard"],
      }),
      putIdealBoardAsSuperOwner: build.mutation<
        PutIdealBoardAsSuperOwnerApiResponse,
        PutIdealBoardAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/boards/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putIdealBoardRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/IdealBoard"],
      }),
      deleteIdealBoardAsSuperOwner: build.mutation<
        DeleteIdealBoardAsSuperOwnerApiResponse,
        DeleteIdealBoardAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/boards/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/IdealBoard"],
      }),
      postIdealBoardsAffiliatesAsSuperOwner: build.mutation<
        PostIdealBoardsAffiliatesAsSuperOwnerApiResponse,
        PostIdealBoardsAffiliatesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/boards_affiliates`,
          method: "POST",
          body: queryArg.idealBoardAffiliateRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/BoardAffiliates"],
      }),
      deleteIdealBoardsAffiliatesAsSuperOwner: build.mutation<
        DeleteIdealBoardsAffiliatesAsSuperOwnerApiResponse,
        DeleteIdealBoardsAffiliatesAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/ideal/boards_affiliates`,
          method: "DELETE",
          body: queryArg.idealBoardAffiliateRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["SuperOwner/BoardAffiliates"],
      }),
      getCommonTagsAsSuperOwner: build.query<
        GetCommonTagsAsSuperOwnerApiResponse,
        GetCommonTagsAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/common/tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            kind: queryArg.kind,
            categorizable_type: queryArg.categorizableType,
          },
        }),
        providesTags: ["SuperOwner/CommonTags"],
      }),
      postCommonTaggingAsSuperOwner: build.mutation<
        PostCommonTaggingAsSuperOwnerApiResponse,
        PostCommonTaggingAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/common/taggings`,
          method: "POST",
          body: queryArg.commonTaggingRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["SuperOwner/CommonTaggings"],
      }),
      deleteCommonTaggingAsSuperOwner: build.mutation<
        DeleteCommonTaggingAsSuperOwnerApiResponse,
        DeleteCommonTaggingAsSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/super_owner/common/taggings`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
            common_tag_id: queryArg.commonTagId,
          },
        }),
        invalidatesTags: ["SuperOwner/CommonTaggings"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as superOwnerApi };
export type GetSuperOwnerGradeMastersApiResponse = /** status 200 OK */ {
  grades?: {
    id?: string;
    grade?: string;
    grade_name?: string;
    graduation_year?: string;
  }[];
};
export type GetSuperOwnerGradeMastersApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
};
export type PostSuperOwnerGradeMastersApiResponse = /** status 200 OK */ {
  grades?: {
    id?: string;
    grade?: string;
    grade_name?: string;
    graduation_year?: string;
  }[];
};
export type PostSuperOwnerGradeMastersApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  gradeAndGraduationYearRequiredRequestBody: GradeAndGraduationYearRequiredRequestBody;
};
export type PutSuperOwnerGradeMastersByIdApiResponse = /** status 200 OK */
  | {
      id?: string;
      grade?: string;
      grade_name?: string;
      graduation_year?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type PutSuperOwnerGradeMastersByIdApiArg = {
  id: string;
  graduationYearRequiredRequestBody: GraduationYearRequiredRequestBody;
};
export type GetSuperOwnerEmailsBySuperOwnerEmailIdApiResponse =
  /** status 200 OK */ {
    super_owner_emails: EmailBase[];
    total_count?: number;
  };
export type GetSuperOwnerEmailsBySuperOwnerEmailIdApiArg = {
  superOwnerEmailId: string;
};
export type GetSuperOwnerEmailsApiResponse = /** status 200 OK */ {
  emails?: EmailBase[];
  total_count: number;
};
export type GetSuperOwnerEmailsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
};
export type PostSuperOwnerEmailsApiResponse = /** status 200 OK */ {
  super_owner_emails?: EmailBase[];
  total_count: number;
};
export type PostSuperOwnerEmailsApiArg = {
  emailRequestBodyAsSuperOwner: EmailRequestBodyAsSuperOwner;
};
export type PostSuperOwnerMaterialsTicketsApiResponse = /** status 200 OK */ {
  hash_ticket_id: string;
};
export type PostSuperOwnerMaterialsTicketsApiArg = {
  materialCategoryIdRequiredRequestBody: MaterialCategoryIdRequiredRequestBody;
};
export type PutSuperOwnerMaterialsTicketsAcceptApiResponse =
  /** status 200 OK */ {
    message: string;
  };
export type PutSuperOwnerMaterialsTicketsAcceptApiArg = {
  putMaterialsTicketRequestBody: PutMaterialsTicketRequestBody;
};
export type UpdateCategoryRenameMapAsSuperOwnerApiResponse =
  /** status 200 OK */ CategoryRenameMap;
export type UpdateCategoryRenameMapAsSuperOwnerApiArg = {
  categoryRenameMapRequestBody: CategoryRenameMapRequestBody;
};
export type GetSuperOwnerCustomMasterMapsApiResponse = /** status 200 OK */ {
  custom_master_maps: CustomMasterMap[];
  total_count: number;
};
export type GetSuperOwnerCustomMasterMapsApiArg = {
  page?: number;
  perPage?: number;
};
export type PostSuperOwnerCustomMasterMapsApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostSuperOwnerCustomMasterMapsApiArg = {
  /** Specifying master_name and used_columns, then specifying titles for each columns */
  body: PostCustomMasterMapRequiredRequestBody & CustomMasterMapBase;
};
export type PutSuperOwnerCustomMasterMapsSequenceApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerCustomMasterMapsSequenceApiArg = {
  customMasterMapSequencesRequestBody: CustomMasterMapSequencesRequestBody;
};
export type GetSuperOwnerCustomMasterMapsByCustomMasterMapIdApiResponse =
  /** status 200 OK */ ModelBase & {
    master_name: string;
    used_columns: number;
    master_column_names: CustomMasterMap;
    master_values: CustomMaster[];
    master_values_count: number;
  };
export type GetSuperOwnerCustomMasterMapsByCustomMasterMapIdApiArg = {
  /** CMM ID for getting master values */
  customMasterMapId: string;
  page?: number;
  perPage?: number;
};
export type PutSuperOwnerCustomMasterMapsByCustomMasterMapIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerCustomMasterMapsByCustomMasterMapIdApiArg = {
  /** CMM ID for getting master values */
  customMasterMapId: string;
  customMasterMapBase: CustomMasterMapBase;
};
export type DeleteSuperOwnerCustomMasterMapsByCustomMasterMapIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerCustomMasterMapsByCustomMasterMapIdApiArg = {
  /** CMM ID for getting master values */
  customMasterMapId: string;
};
export type PostSuperOwnerCustomMastersApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostSuperOwnerCustomMastersApiArg = {
  body: CustomMasterRequestBody & PostCustomMasterRequestBody;
};
export type PutSuperOwnerCustomMastersByCustomMasterIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerCustomMastersByCustomMasterIdApiArg = {
  customMasterId: string;
  body: CustomMasterBase & PutCustomMasterRequestBody;
};
export type DeleteSuperOwnerCustomMastersByCustomMasterIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerCustomMastersByCustomMasterIdApiArg = {
  customMasterId: string;
};
export type GetSuperOwnerCustomMastersLinkingCompaniesApiResponse =
  /** status 200 OK */
    | {
        linking_companies?: {
          id?: string;
          name?: string;
          column_value?: string;
        }[];
      }
    | /** status 201 Created */ {
        message?: string;
      };
export type GetSuperOwnerCustomMastersLinkingCompaniesApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  /** custom_master_mapのhashid */
  customMasterMapId: string;
  /** 検索するカラムのnumberのみ "2" */
  columnNumber: string;
  /** 検索するカラムの値(複数検索可能) */
  columnValues: string[];
};
export type PutSuperOwnerCustomMastersByCustomMasterIdLinkingCompanyApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 201 Created */ {
        message?: string;
      };
export type PutSuperOwnerCustomMastersByCustomMasterIdLinkingCompanyApiArg = {
  customMasterId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  schema: CompanyIdRequiredRequestBody;
};
export type GetCustomMasterMapRelationshipsAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    relationships: CustomMasterMapRelationshipBase[];
    total_count: number;
  };
export type GetCustomMasterMapRelationshipsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  customMasterMapId?: string;
};
export type PostCustomMasterMapRelationshipsAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostCustomMasterMapRelationshipsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** CMM ID for getting master values */
  customMasterMapId: string;
  postCustomMasterMapRelationshipRequestBody: PostCustomMasterMapRelationshipRequestBody;
};
export type GetCustomMasterMapRelationshipAsSuperOwnerApiResponse =
  /** status 200 OK */ CustomMasterMapRelationshipBase;
export type GetCustomMasterMapRelationshipAsSuperOwnerApiArg = {
  /** CMM ID for getting master values */
  customMasterMapId: string;
  /** カスタムリストのリレーション設定 ID (Common::CustomMasterMapRelationship ID) */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutCustomMasterMapRelationshipsAsSuperOwnerApiResponse =
  /** status 202 Accepted */ CustomMasterMapRelationshipBase;
export type PutCustomMasterMapRelationshipsAsSuperOwnerApiArg = {
  /** CMM ID for getting master values */
  customMasterMapId: string;
  /** カスタムリストのリレーション設定 ID (Common::CustomMasterMapRelationship ID) */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  nameRequiredRequestBody: NameRequiredRequestBody;
};
export type DeleteControllerAsSuperOwnerApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteControllerAsSuperOwnerApiArg = {
  /** CMM ID for getting master values */
  customMasterMapId: string;
  /** カスタムリストのリレーション設定 ID (Common::CustomMasterMapRelationship ID) */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetCustomMasterMapRelationshipChildCustomMastersAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    child_custom_master_columns: {
      id: string;
      name: string;
    }[];
  };
export type GetCustomMasterMapRelationshipChildCustomMastersAsSuperOwnerApiArg =
  {
    /** カスタムリストのリレーション設定 ID (Common::CustomMasterMapRelationship ID) */
    id: string;
    /** CMM ID for getting master values */
    customMasterMapId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  };
export type BatchCreateCustomMasterRelationshipAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type BatchCreateCustomMasterRelationshipAsSuperOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  batchCreateCustomMasterMapRelationshipRequestBody: BatchCreateCustomMasterMapRelationshipRequestBody;
};
export type GetSuperOwnerNotificationsApiResponse =
  /** status 200 OK */ Notifications;
export type GetSuperOwnerNotificationsApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  sent?: string;
  perPage?: number;
  page?: number;
};
export type PostSuperOwnerNotificationsApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 201 Created */ {
      message?: string;
    };
export type PostSuperOwnerNotificationsApiArg = {
  notificationRequestBodyAsSuperOwner: NotificationRequestBodyAsSuperOwner;
};
export type GetSuperOwnerNotificationsByIdApiResponse =
  /** status 200 OK */ Notification;
export type GetSuperOwnerNotificationsByIdApiArg = {
  /** Notification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutSuperOwnerNotificationsByIdApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 202 Accepted */ Notification;
export type PutSuperOwnerNotificationsByIdApiArg = {
  /** Notification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  notificationRequestBodyAsSuperOwner: NotificationRequestBodyAsSuperOwner;
};
export type DeleteSuperOwnerNotificationsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerNotificationsByIdApiArg = {
  /** Notification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSuperOwnerCreatedUserTagTypesApiResponse = /** status 200 OK */ {
  user_tag_types: CreatedUserTagType[];
  total_count: number;
};
export type GetSuperOwnerCreatedUserTagTypesApiArg = {
  userTagTypeName?: string;
  userTagName?: string;
  page?: number;
  perPage?: number;
  kind?: UserTagTypeKind;
  role?: UserTagTypeRoleType;
};
export type GetSearchUserControllersAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    users: UserInfoForSuperOwner[];
    total_count: number;
  };
export type GetSearchUserControllersAsSuperOwnerApiArg = {
  userName?: string;
  email: string;
  companyName?: string;
  tagName?: string;
  roleName?: string;
  status?: AffiliateStatus;
  page?: number;
  perPage: number;
};
export type GetShowAffiliateUserControllersAsSuperOwnerApiResponse =
  /** status 200 OK */ BasicUserInfo & AffiliateListBase;
export type GetShowAffiliateUserControllersAsSuperOwnerApiArg = {
  /** User ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type BulkDeleteControllerAsSuperOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type BulkDeleteControllerAsSuperOwnerApiArg = {
  /** User ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostSuperOwnerUserTagTypesApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostSuperOwnerUserTagTypesApiArg = {
  postUserTagTypeRequestBody: PostUserTagTypeRequestBody;
};
export type PostSuperOwnerUserTaggingsApiResponse = /** status 201 Created */
  | {
      number_of_tagging_users?: number;
      remaining_number_of_tagging_users?: number | null;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type PostSuperOwnerUserTaggingsApiArg = {
  postUserTaggingRequestBody: PostUserTaggingRequestBody;
};
export type DeleteSuperOwnerUserTaggingsApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type DeleteSuperOwnerUserTaggingsApiArg = {
  body: {
    user_id: string;
    user_tag_id: string;
    company_id: string;
  };
};
export type SuperOwnerUserTaggingBatchCreateApiResponse =
  /** status 200 OK */ UserTaggingBatchCreateResponses;
export type SuperOwnerUserTaggingBatchCreateApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  userTaggingBatchCreateParams: UserTaggingBatchCreateParams;
};
export type PutSuperOwnerUserTagTypesByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerUserTagTypesByIdApiArg = {
  id: string;
  putUserTagTypeRequestBody: PutUserTagTypeRequestBody;
};
export type DeleteSuperOwnerUserTagTypesByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerUserTagTypesByIdApiArg = {
  id: string;
};
export type GetReviewerCompanyGroupControllersAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    reviewer_company_groups: ReviewerCompanyGroupBase[];
    total_count: number;
  };
export type GetReviewerCompanyGroupControllersAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetReviewerCompanyGroupAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    reviewer_company_group: ReviewerCompanyGroupDetail;
  };
export type GetReviewerCompanyGroupAsSuperOwnerApiArg = {
  /** ReviewerCompanyGroupID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutReviewerCompanyGroupAsSuperOwnerApiResponse =
  /** status 202 Accepted */ {
    reviewer_company_group: ReviewerCompanyGroupDetail;
  };
export type PutReviewerCompanyGroupAsSuperOwnerApiArg = {
  /** CompanyID */
  companyId: string;
  /** ReviewerCompanyGroupID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  reviewerCompanyGroupRequestBodyAsSuperOwner: RevieweeCompaniesRequestBodyAsSuperOwner;
};
export type FetchCompaniesAsSuperOwnerApiResponse = /** status 200 OK */ {
  companies?: (Company & {
    reviewer_companies_by_reviewer_company_group?: {
      name?: string;
      reviewer_companies?: {
        id?: string;
        name?: string;
      }[];
    }[];
  })[];
  total_count?: number;
};
export type FetchCompaniesAsSuperOwnerApiArg = {
  page?: number;
  perPage?: number;
  companyRole?: CompanyRoleType;
  companyTagIds?: string[];
  tagFilter?: "AND" | "OR";
  homeworkTemplateId?: string;
  distribution?: "distributed" | "not_distributed";
  companyCodes?: string;
  masterName?: string;
  columnNum?: string;
  /** Search by company name */
  companyName?: string;
  /** Search by company tag name */
  companyTagName?: string;
  simpleInfo?: boolean;
};
export type CreateCompanyAsSuperOwnerApiResponse = /** status 201 Created */ {
  message?: string;
};
export type CreateCompanyAsSuperOwnerApiArg = {
  postCompanyRequestBodyAsSuperOwner: PostCompanyRequestBodyAsSuperOwner;
};
export type FetchCompanyAsSuperOwnerApiResponse =
  /** status 200 OK */ CompanyAsSuperOwner;
export type FetchCompanyAsSuperOwnerApiArg = {
  id: string;
};
export type UpdateCompanyAsSuperOwnerApiResponse = /** status 200 OK */ Company;
export type UpdateCompanyAsSuperOwnerApiArg = {
  id: string;
  putCompanyRequestBodyAsSuperOwner: PutCompanyRequestBodyAsSuperOwner;
};
export type DeleteCompanyAsSuperOwnerApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteCompanyAsSuperOwnerApiArg = {
  id: string;
};
export type GetCompanyLimitOptionAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    limit_option: LimitOptionBase;
    total_count?: number;
  };
export type GetCompanyLimitOptionAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  id: string;
};
export type GetSuperOwnerCompaniesByIdMaterialCapacityApiResponse =
  /** status 200 OK */ {
    material_capacity: string;
    material_limit_capacity: string | null;
  };
export type GetSuperOwnerCompaniesByIdMaterialCapacityApiArg = {
  id: string;
};
export type GetSuperOwnerCompaniesByCompanyIdSuperOwnersApiResponse =
  /** status 200 OK */ {
    super_owners?: AffiliateBase[];
    total_count?: number;
  };
export type GetSuperOwnerCompaniesByCompanyIdSuperOwnersApiArg = {
  companyId: string;
  /** Incremental search by last_name and first_name */
  search?: string;
  page?: number;
  perPage?: number;
};
export type GetSuperOwnerCompaniesByCompanyIdOwnersApiResponse =
  /** status 200 OK */ {
    owners: OwnerListAsSuperOwner[];
    total_count: number;
  };
export type GetSuperOwnerCompaniesByCompanyIdOwnersApiArg = {
  companyId: string;
  /** Incremental search by last_name and first_name */
  search?: string;
  email?: string;
  /** search users by statuses */
  searchStatuses?: AffiliateStatusJa[];
  page?: number;
  perPage?: number;
};
export type PostSuperOwnerCompaniesByCompanyIdOwnersApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostSuperOwnerCompaniesByCompanyIdOwnersApiArg = {
  companyId: string;
  /** Incremental search by last_name and first_name */
  search?: string;
  email?: string;
  /** search users by statuses */
  searchStatuses?: AffiliateStatusJa[];
  page?: number;
  perPage?: number;
  userNameAndEmailRequestBody: UserNameAndEmailRequestBody;
};
export type DeleteSuperOwnerCompaniesByCompanyIdOwnersAndIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerCompaniesByCompanyIdOwnersAndIdApiArg = {
  companyId: string;
  id: string;
};
export type PutSuperOwnerCompaniesByCompanyIdOwnersAndIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerCompaniesByCompanyIdOwnersAndIdApiArg = {
  companyId: string;
  id: string;
  userNameRequestBody: UserNameRequestBody;
};
export type PostSuperOwnerCompaniesByCompanyIdOwnersAndIdAuthorizationsApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PostSuperOwnerCompaniesByCompanyIdOwnersAndIdAuthorizationsApiArg =
  {
    companyId: string;
    id: string;
    body: {
      authorizations?: UserAuthorization;
    };
  };
export type GetSuperOwnerCompaniesByCompanyIdTeachersApiResponse =
  /** status 200 OK */ {
    teachers: TeacherListAsSuperOwner[];
    total_count: number;
  };
export type GetSuperOwnerCompaniesByCompanyIdTeachersApiArg = {
  companyId: string;
  /** Incremental search by last_name and first_name */
  search?: string;
  email?: string;
  /** search users by statuses */
  searchStatuses?: AffiliateStatusJa[];
  perPage?: number;
  page?: number;
  tagName?: string;
};
export type GetTeachersUserTagsAsSuperOwnerApiResponse = /** status 200 OK */ {
  user_tag_types?: (UserTagTypeBasic & {
    user_tags?: UserTagBasic[];
  })[];
};
export type GetTeachersUserTagsAsSuperOwnerApiArg = {
  /** Teacher ID */
  teacherId: string;
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSuperOwnerCompaniesByCompanyIdStudentsApiResponse =
  /** status 200 OK */ {
    students: StudentListAsSuperOwner[];
    student_input_settings: StudentInputSetting;
    total_count: number;
  };
export type GetSuperOwnerCompaniesByCompanyIdStudentsApiArg = {
  companyId: string;
  /** Incremental search by last_name and first_name */
  search?: string;
  email?: string;
  grade?: string;
  /** 複数のgradeで検索する時 */
  grades?: string;
  parentName?: string;
  userTag?: string;
  nickname?: string;
  /** search users by statuses */
  searchStatuses?: AffiliateStatusJa[];
  perPage?: number;
  page?: number;
  sortedBy?: SortedByStudentList;
};
export type GetSuperOwnerCompaniesByCompanyIdStudentsListGradeApiResponse =
  /** status 200 OK */ {
    number_of_students_per_grade: {
      grade: StudentGradeJa;
      count: number;
    }[];
    students_total_count: number;
  };
export type GetSuperOwnerCompaniesByCompanyIdStudentsListGradeApiArg = {
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetStudentsUserTagsAsSuperOwnerApiResponse = /** status 200 OK */ {
  user_tag_types?: (UserTagTypeBasic & {
    user_tags?: UserTagBasic[];
  })[];
};
export type GetStudentsUserTagsAsSuperOwnerApiArg = {
  /** Student ID */
  id: string;
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudentsGroupAffiliatesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    group_affiliate_packages?: GroupAffiliatePackageMinimalBase[];
  };
export type GetStudentsGroupAffiliatesAsSuperOwnerApiArg = {
  /** Student ID */
  id: string;
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchReviewersAsSuperOwnerApiResponse = /** status 200 OK */ {
  reviewers: ReviewerDetail[];
  total_count: number;
};
export type FetchReviewersAsSuperOwnerApiArg = {
  companyId: string;
  perPage?: number;
  page?: number;
};
export type CreateReviewerAsSuperOwnerApiResponse =
  /** status 201 Created */ ReviewerDetail;
export type CreateReviewerAsSuperOwnerApiArg = {
  companyId: string;
  userReviewersRequestBody: UserReviewerRequestBody;
};
export type DeleteReviewerAsSuperOwnerApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteReviewerAsSuperOwnerApiArg = {
  companyId: string;
  id: string;
};
export type UpdateReviewerAsSuperOwnerApiResponse =
  /** status 200 OK */ ReviewerDetail;
export type UpdateReviewerAsSuperOwnerApiArg = {
  companyId: string;
  id: string;
  userReviewerRequestBody: UserReviewerRequestBody2;
};
export type CreateUserAsSuperOwnerApiResponse = /** status 201 Created */ {
  message?: string;
};
export type CreateUserAsSuperOwnerApiArg = {
  companyId: string;
  /** invitation_scheduled_atを設定時、招待メールは設定した日時に送信される。未設定時、招待メールは即時送信される。 */
  postUserRequestBodyAsSuperOwner: PostUserRequestBodyAsSuperOwner;
};
export type CreateAccountUserAsSuperOwnerApiResponse =
  /** status 201 Created */ CreateAccountUserResponse;
export type CreateAccountUserAsSuperOwnerApiArg = {
  companyId: string;
  createAccountUserRequestBody: CreateAccountUserRequestBody;
};
export type CreateAccountAvailableAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type CreateAccountAvailableAsSuperOwnerApiArg = {
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  affiliateIdRequiredRequestBody: AffiliateIdRequiredRequestBody;
};
export type ResetPasswordAsSuperOwnerApiResponse =
  /** status 200 ok */ AccountUserInfo;
export type ResetPasswordAsSuperOwnerApiArg = {
  id: string;
  authorizationPasswordRequestBody: AuthorizationPasswordRequestBody;
};
export type GetSuperOwnerCompaniesByCompanyIdUsersAndIdApiResponse =
  /** status 200 OK */ UserDetail;
export type GetSuperOwnerCompaniesByCompanyIdUsersAndIdApiArg = {
  companyId: string;
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  role?: "teacher" | "owner";
};
export type PutSuperOwnerCompaniesByCompanyIdUsersAndIdApiResponse =
  /** status 200 OK */ UserDetail;
export type PutSuperOwnerCompaniesByCompanyIdUsersAndIdApiArg = {
  companyId: string;
  id: string;
  putUserRequestBodyAsSuperOwner: PutUserRequestBodyAsSuperOwner;
};
export type DeleteUserAsSuperOwnerApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type DeleteUserAsSuperOwnerApiArg = {
  companyId: string;
  id: string;
  roleRequiredRequestBody: RoleRequiredRequestBody;
};
export type GetSuperOwnerUserTagsApiResponse = /** status 200 OK */ {
  user_tags?: UserTag[];
  total_count?: number;
};
export type GetSuperOwnerUserTagsApiArg = {
  userTagTypeId?: string;
  kind?: "school" | "nationwide" | "limited";
  page?: number;
  perPage?: number;
  roleType?: UserTagTypeRoleType;
};
export type PostSuperOwnerUserTagsApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostSuperOwnerUserTagsApiArg = {
  postUserTagRequestBody: PostUserTagRequestBody;
};
export type GetSuperOwnerUserTagsByIdApiResponse =
  /** status 200 OK */ UserTagDetail;
export type GetSuperOwnerUserTagsByIdApiArg = {
  id: string;
};
export type PutSuperOwnerUserTagsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutSuperOwnerUserTagsByIdApiArg = {
  id: string;
  putUserTagRequestBody: PutUserTagRequestBody;
};
export type DeleteSuperOwnerUserTagsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteSuperOwnerUserTagsByIdApiArg = {
  id: string;
};
export type PutSuperOwnerUserTagsSequenceApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutSuperOwnerUserTagsSequenceApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  userTagSequencesRequestBody: UserTagSequencesRequestBody;
};
export type PostSuperOwnerUserTagsByIdAssignApiResponse =
  /** status 201 Created */
    | {
        message?: string;
      }
    | /** status 202 202 Accepted */ {
        message?: string;
      };
export type PostSuperOwnerUserTagsByIdAssignApiArg = {
  id: string;
  assignUserTagRequestBody: AssignUserTagRequestBody;
};
export type PatchSuperOwnerUserTagsByIdAssignUpdateApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 202 202 Accepted */ {
        message?: string;
      };
export type PatchSuperOwnerUserTagsByIdAssignUpdateApiArg = {
  id: string;
  assignUserTagRequestBody: AssignUserTagRequestBody;
};
export type DeleteSuperOwnerUserTagsByIdUnassignApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 202 202 Accepted */ {
        message?: string;
      };
export type DeleteSuperOwnerUserTagsByIdUnassignApiArg = {
  id: string;
  body: {
    company_id: string;
  };
};
export type GetSuperOwnerUserTagsByIdDistributedStudentsApiResponse =
  /** status 200 OK */ {
    students: SimpleUserList[];
    total_count: number;
  };
export type GetSuperOwnerUserTagsByIdDistributedStudentsApiArg = {
  id: string;
  companyId: string;
  perPage?: number;
  page?: number;
};
export type PostSuperOwnerCompanyTagTypesApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostSuperOwnerCompanyTagTypesApiArg = {
  nameRequestBody: NameRequestBody;
};
export type PutSuperOwnerCompanyTagTypesByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerCompanyTagTypesByIdApiArg = {
  id: string;
  nameRequestBody: NameRequestBody;
};
export type DeleteSuperOwnerCompanyTagTypesByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerCompanyTagTypesByIdApiArg = {
  id: string;
};
export type GetSuperOwnerCreatedCompanyTagTypesApiResponse =
  /** status 200 OK */ {
    company_tag_types?: (ModelBase & {
      name: string;
      company_tags?: (ModelBase & {
        name: string;
        number_of_linking_companies?: number;
        linking_companies?: {
          id: string;
          name: string;
          number_of_linking_users?: number;
        }[];
      })[];
    })[];
    total_count?: number;
  };
export type GetSuperOwnerCreatedCompanyTagTypesApiArg = {
  companyTagTypeName?: string;
  companyTagName?: string;
  page?: number;
  perPage?: number;
};
export type PutSuperOwnerCompanyTagTypesSequenceApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerCompanyTagTypesSequenceApiArg = {
  companyTagTypeSequencesRequestBody: CompanyTagTypeSequencesRequestBody;
};
export type PutSuperOwnerUserTagTypesSequenceApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerUserTagTypesSequenceApiArg = {
  userTagTypeSequencesRequestBody: UserTagTypeSequencesRequestBody;
};
export type GetUserTagDistributionHistoriesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    user_tag_distribution_histories: Items[];
    total_count: number;
  };
export type GetUserTagDistributionHistoriesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  status?: UserTagDistributionSettingStatus;
  companyName?: string;
  userTagName?: string;
};
export type GetSuperOwnerCompaniesByCompanyIdCompanyTagsApiResponse =
  /** status 200 OK */ {
    company_tags?: CompanyTag[];
    total_count?: number;
  };
export type GetSuperOwnerCompaniesByCompanyIdCompanyTagsApiArg = {
  companyId: string;
};
export type GetSuperOwnerCompanyTagsApiResponse = /** status 200 OK */ {
  company_tags?: CompanyTag[];
  total_count?: number;
};
export type GetSuperOwnerCompanyTagsApiArg = {
  companyTagTypeId?: string;
  page?: number;
  perPage?: number;
};
export type PostSuperOwnerCompanyTagsApiResponse =
  /** status 201 Created */ CompanyTag;
export type PostSuperOwnerCompanyTagsApiArg = {
  postCompanyTagRequestBody: PostCompanyTagRequestBody;
};
export type PutSuperOwnerCompanyTagsByIdApiResponse =
  /** status 200 OK */ CompanyTag;
export type PutSuperOwnerCompanyTagsByIdApiArg = {
  id: string;
  nameRequestBody: NameRequestBody;
};
export type DeleteSuperOwnerCompanyTagsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerCompanyTagsByIdApiArg = {
  id: string;
};
export type PutSuperOwnerCompanyTagsSequenceApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerCompanyTagsSequenceApiArg = {
  companyTagSequencesRequestBody: CompanyTagSequencesRequestBody;
};
export type PostSuperOwnerCompanyTaggingsApiResponse =
  /** status 201 Created */
    | {
        message?: string;
      }
    | /** status 202 202 Accepted */ {
        message?: string;
      };
export type PostSuperOwnerCompanyTaggingsApiArg = {
  companyTaggingRequestBody: CompanyTaggingRequestBody;
};
export type DeleteSuperOwnerCompanyTaggingsApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 202 202 Accepted */ {
        message?: string;
      };
export type DeleteSuperOwnerCompanyTaggingsApiArg = {
  body: {
    company_id?: string;
    company_tag_id?: string;
  };
};
export type GetSuperOwnerHomeworkTemplatesApiResponse = /** status 200 OK */ {
  homework_templates: HomeworkTemplateBaseForSuperOwner[];
  total_count: number;
};
export type GetSuperOwnerHomeworkTemplatesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  undelivery?: boolean;
  forGroup?: boolean;
  searchType?: HomeworkTemplateSearchType;
  searchWord?: string;
  /** common_tag_nameのフィルタリング */
  commonTagName?: string;
};
export type PostSuperOwnerHomeworkTemplatesApiResponse =
  /** status 201 Created */ HomeworkTemplate;
export type PostSuperOwnerHomeworkTemplatesApiArg = {
  homeworkTemplateCreateRequest: HomeworkTemplateCreateRequest;
};
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiResponse =
  /** status 200 OK */ HomeworkTemplate;
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiArg = {
  homeworkTemplateId: string;
};
export type PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiResponse =
  /** status 200 OK */ HomeworkTemplate;
export type PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiArg = {
  homeworkTemplateId: string;
  homeworkTemplateUpdateRequest: HomeworkTemplateUpdateRequest;
};
export type DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdApiArg = {
  homeworkTemplateId: string;
};
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsApiResponse =
  /** status 200 OK */ {
    homework_template_distributions: HomeworkTemplateDistribution[];
    total_count: number;
  };
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsApiArg =
  {
    homeworkTemplateId: string;
    "access-token"?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
  };
export type BatchCreateHomeworkTemplateDistributionsAsSuperOwnerApiResponse =
  /** status 200 OK */
    | {
        distributions: HomeworkTemplateDistribution[];
        company_ids: number[];
        errors: string[];
      }
    | /** status 201 Created */ {
        message?: string;
      };
export type BatchCreateHomeworkTemplateDistributionsAsSuperOwnerApiArg = {
  homeworkTemplateId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  batchCreateHomeworkTemplateDistributionRequestBodyAsSuperOwner: BatchCreateHomeworkTemplateDistributionRequestBodyAsSuperOwner;
};
export type BatchUpdateHomeworkTemplateDistributionsAsSuperOwnerApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 201 OK */ {
        distributions: HomeworkTemplateDistribution[];
        errors: string[];
      };
export type BatchUpdateHomeworkTemplateDistributionsAsSuperOwnerApiArg = {
  homeworkTemplateId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    distribution_ids: string[];
    max_distribution_end_users: number;
    start_at?: string;
    end_at?: string;
    reviewer_end_at?: string;
  };
};
export type BatchDeleteHomeworkTemplateDistributionsAsSuperOwnerApiResponse =
  /** status 200 OK */ BatchDeleteHomeworkTemplateDistributionRequestBodyAsSuperOwner;
export type BatchDeleteHomeworkTemplateDistributionsAsSuperOwnerApiArg = {
  homeworkTemplateId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    distribution_ids: string[];
  };
};
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiResponse =
  /** status 200 OK */ HomeworkTemplateDistribution;
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiArg =
  {
    homeworkTemplateId: string;
    distributionId: string;
    "access-token"?: string;
    client?: string;
    uid?: string;
  };
export type PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiResponse =
  /** status 200 OK */ HomeworkTemplateDistribution;
export type PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiArg =
  {
    homeworkTemplateId: string;
    distributionId: string;
    putHomeworkTemplateDistributionRequestBodyAsSuperOwner: PutHomeworkTemplateDistributionRequestBodyAsSuperOwner;
  };
export type DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdApiArg =
  {
    homeworkTemplateId: string;
    distributionId: string;
  };
export type PostHomeworkTemplateDuplicateAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostHomeworkTemplateDuplicateAsSuperOwnerApiArg = {
  /** HomeworkTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostHomeworkTemplateImageAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostHomeworkTemplateImageAsSuperOwnerApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type PutHomeworkTemplateImageAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutHomeworkTemplateImageAsSuperOwnerApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  /** HomeworkTemplateImage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteHomeworkTemplateImageAsSuperOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteHomeworkTemplateImageAsSuperOwnerApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  /** HomeworkTemplateImage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSuperOwnerCompaniesByCompanyIdUserTagsApiResponse =
  /** status 200 OK */ {
    user_tags: UserTag[];
    total_count: number;
  };
export type GetSuperOwnerCompaniesByCompanyIdUserTagsApiArg = {
  companyId: string;
  perPage?: number;
  page?: number;
};
export type GetSuperOwnerUserTagsByUserTagIdUsersApiResponse =
  /** status 200 OK */ {
    users: BasicUserInfo[];
    total_count: number;
  };
export type GetSuperOwnerUserTagsByUserTagIdUsersApiArg = {
  userTagId: string;
  perPage?: number;
  page?: number;
};
export type DeleteSuperOwnerReservationsByIdApiResponse =
  /** status 204 No Content */ undefined;
export type DeleteSuperOwnerReservationsByIdApiArg = {
  id: string;
};
export type PostSuperOwnerMaterialWorksBatchCreateUsingTagsApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PostSuperOwnerMaterialWorksBatchCreateUsingTagsApiArg = {
  batchCreateMaterialWorkRequestBodyAsSuperOwner: BatchCreateMaterialWorkRequestBodyAsSuperOwner;
};
export type GetSuperOwnerMaterialsApiResponse = /** status 200 OK */ {
  materials: MaterialList[];
  total_count: number;
};
export type GetSuperOwnerMaterialsApiArg = {
  categoryId?: string;
  perPage?: number;
  page?: number;
};
export type PostSuperOwnerMaterialsApiResponse =
  /** status 201 OK */ MaterialDetail;
export type PostSuperOwnerMaterialsApiArg = {
  createMaterialRequestBody: CreateMaterialRequestBody;
};
export type GetSuperOwnerMaterialsAllContainedCategoryApiResponse =
  /** status 200 OK */ {
    materials: HasIdAndTitle[];
    total_count: number;
  };
export type GetSuperOwnerMaterialsAllContainedCategoryApiArg = {
  categoryIds?: string[];
  perPage?: number;
  page?: number;
};
export type GetSuperOwnerMaterialsByIdApiResponse =
  /** status 200 OK */ MaterialDetail;
export type GetSuperOwnerMaterialsByIdApiArg = {
  id: string;
};
export type PutSuperOwnerMaterialsByIdApiResponse =
  /** status 200 OK */ MaterialDetail;
export type PutSuperOwnerMaterialsByIdApiArg = {
  id: string;
  updateMaterialRequestBody: UpdateMaterialRequestBody;
};
export type DeleteSuperOwnerMaterialsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerMaterialsByIdApiArg = {
  id: string;
};
export type PutSuperOwnerMaterialsSequenceApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerMaterialsSequenceApiArg = {
  materialSequencesRequiredRequestBody: MaterialSequencesRequiredRequestBody;
};
export type PutMaterialImportHtmlAsSuperOwnerApiResponse =
  /** status 201 Accepted */ MaterialDetail;
export type PutMaterialImportHtmlAsSuperOwnerApiArg = {
  materialId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  zipFileRequiredRequestBody: ZipFileRequiredRequestBody;
};
export type PostMaterialDuplicateAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostMaterialDuplicateAsSuperOwnerApiArg = {
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  materialCategoryIdRequiredRequestBody: MaterialCategoryIdRequiredRequestBody;
};
export type GetSuperOwnerCategoriesApiResponse = /** status 200 OK */ {
  grades?: GradeCategoryInfo[];
  texts?: TextCategoryInfo[];
  subjects?: SubjectCategoryInfo[];
  major_categories?: MajorCategoryInfo[];
  middle_categories?: MiddleCategoryInfo[];
  minor_categories?: MinorCategoryInfo[];
};
export type GetSuperOwnerCategoriesApiArg = void;
export type PostSuperOwnerCategoriesApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostSuperOwnerCategoriesApiArg = {
  postCategoryRequestBodyAsSuperOwner: PostCategoryRequestBodyAsSuperOwner;
};
export type GetSuperOwnerCategoriesByIdApiResponse =
  /** status 200 OK */ CategoryInfo;
export type GetSuperOwnerCategoriesByIdApiArg = {
  id: string;
};
export type PutSuperOwnerCategoriesByIdApiResponse =
  /** status 200 OK */ CategoryInfo;
export type PutSuperOwnerCategoriesByIdApiArg = {
  id: string;
  putCategoryRequestBodyAsSuperOwner: PutCategoryRequestBodyAsSuperOwner;
};
export type DeleteSuperOwnerCategoriesByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerCategoriesByIdApiArg = {
  id: string;
};
export type PutSuperOwnerCategoriesSequenceApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerCategoriesSequenceApiArg = {
  putCategorySequencesRequestBodyAsSuperOwner: PutCategorySequencesRequestBodyAsSuperOwner;
};
export type GetSuperOwnerCompanyTagsByCompanyTagIdCompaniesApiResponse =
  /** status 200 OK */ {
    companies: Company[];
    total_count: number;
  };
export type GetSuperOwnerCompanyTagsByCompanyTagIdCompaniesApiArg = {
  companyTagId: string;
  page?: number;
  perPage?: number;
};
export type FetchRevieweeCompaniesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    companies: Company[];
    total_count: number;
  };
export type FetchRevieweeCompaniesAsSuperOwnerApiArg = {
  reviewerCompanyId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type BatchCreateRevieweeCompaniesAsSuperOwnerApiResponse =
  /** status 200 OK */
    | {
        errors: string[];
        reviewee_companies: Company[];
      }
    | /** status 201 Created */ {
        message?: string;
      };
export type BatchCreateRevieweeCompaniesAsSuperOwnerApiArg = {
  reviewerCompanyId: string;
  revieweeCompaniesRequestBodyAsSuperOwner: RevieweeCompaniesRequestBodyAsSuperOwner2;
};
export type BatchDeleteRevieweeCompaniesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    errors: string[];
    removed_reviewee_ids: string[];
  };
export type BatchDeleteRevieweeCompaniesAsSuperOwnerApiArg = {
  reviewerCompanyId: string;
  revieweeCompaniesRequestBodyAsSuperOwner: RevieweeCompaniesRequestBodyAsSuperOwner2;
};
export type FetchAssignableCompaniesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    companies: ReviewerAssignableCompany[];
    total_count: number;
  };
export type FetchAssignableCompaniesAsSuperOwnerApiArg = {
  reviewerCompanyId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  companyTagIds?: string[];
  tagFilter?: "AND" | "OR";
};
export type PutSuperOwnerMaterialDistributionHistoryGroupsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutSuperOwnerMaterialDistributionHistoryGroupsByIdApiArg = {
  id: string;
  body: {
    distribution_time?: string;
    expiration_time?: string;
  };
};
export type GetSuperOwnerMaterialDistributionHistoriesListApiResponse =
  /** status 200 OK */ {
    material_distribution_histories: MaterialDistributionHistory[];
    total_count: number;
  };
export type GetSuperOwnerMaterialDistributionHistoriesListApiArg = {
  page?: number;
  perPage?: number;
  materialId?: string;
  sentCompanyName?: string;
  userTagName?: string;
};
export type GetSuperOwnerMaterialDistributionHistoriesListStudentsApiResponse =
  /** status 200 OK */ MaterialDistributionHistoryListStudents;
export type GetSuperOwnerMaterialDistributionHistoriesListStudentsApiArg = {
  distributionId?: string;
  materialId?: string;
  sentCompanyId?: string;
  page?: number;
  perPage?: number;
};
export type GetSuperOwnerMaterialDistributionHistoriesSearchByUserIdApiResponse =
  /** status 200 OK */ {
    material_distribution_histories: MaterialDistributionHistorySearchByUserId[];
    total_count: number;
  };
export type GetSuperOwnerMaterialDistributionHistoriesSearchByUserIdApiArg = {
  userId: string;
  page?: number;
  perPage?: number;
};
export type GetSuperOwnerHomeworkTemplatesDistributionHistoriesApiResponse =
  /** status 200 OK */ {
    homework_template_distribution_histories: SuperOwnerHomeworkTemplateDistributionHistory[];
    total_count: number;
  };
export type GetSuperOwnerHomeworkTemplatesDistributionHistoriesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetSuperOwnerHomeworkTemplateDistributionHistoryAffiliatesApiResponse =
  /** status 200 OK */ SuperOwnerHomeworkTemplateDistributionHistoryAffiliates;
export type GetSuperOwnerHomeworkTemplateDistributionHistoryAffiliatesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** HomeworkTemplateDistributionHistory ID */
  homeworkTemplateDistributionHistoryId: string;
};
export type GetSuperOwnerHomeworkTemplatesDistributionHistoriesByHomeworkTemplateDistributionHistoryIdCompaniesApiResponse =
  /** status 200 OK */ SuperOwnerHomeworkTemplateDistributionHistoryCompanies;
export type GetSuperOwnerHomeworkTemplatesDistributionHistoriesByHomeworkTemplateDistributionHistoryIdCompaniesApiArg =
  {
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
    /** HomeworkTemplateDistributionHistory ID */
    homeworkTemplateDistributionHistoryId: string;
  };
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse =
  /** status 200 OK */ {
    homework_template_custom_fields: HomeworkTemplateCustomField[];
    total_count: number;
  };
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
  };
export type PostSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse =
  /** status 201 Created */ HomeworkTemplateCustomField;
export type PostSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    body: CustomFieldRequestBody & {
      type: HomeworkTemplateCustomFieldType;
    };
  };
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse =
  /** status 200 OK */ HomeworkTemplateCustomField;
export type GetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg =
  {
    id: string;
    /** HomeworkTemplateCustomField ID */
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  };
export type PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse =
  /** status 202 Accepted */ HomeworkTemplateCustomField;
export type PutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg =
  {
    id: string;
    /** HomeworkTemplateCustomField ID */
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    body: CustomFieldRequestBody;
  };
export type DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg =
  {
    id: string;
    /** HomeworkTemplateCustomField ID */
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  };
export type GetSuperOwnerInquiriesApiResponse = /** status 200 OK */ {
  inquiries: InquiryDetailListBase[];
  total_count: number;
};
export type GetSuperOwnerInquiriesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
  status?: InquiryStatus;
  kind?: InquiryKind;
  personInChargeName?: string;
  companyName?: string;
  userName?: string;
};
export type GetSuperOwnerInquiriesByIdApiResponse =
  /** status 200 OK */ InquiryDetailForSuperOwner;
export type GetSuperOwnerInquiriesByIdApiArg = {
  /** inquiries ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutSuperOwnerInquiriesByIdApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutSuperOwnerInquiriesByIdApiArg = {
  /** inquiries ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  inquiryIsNewRequiredRequestBody: InquiryIsNewRequiredRequestBody;
};
export type GetTheNumberOfNewCommentInquiriesAsSuperOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type GetTheNumberOfNewCommentInquiriesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTheNumberOfNewInquiryInquiriesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    is_new_count: number;
  };
export type GetTheNumberOfNewInquiryInquiriesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostSuperOwnerInquiryCommentsApiResponse =
  /** status 201 Created */ InquiryComment;
export type PostSuperOwnerInquiryCommentsApiArg = {
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  bodyRequiredRequestBody: BodyRequiredRequestBody;
};
export type PutSuperOwnerInquiryCommentsByIdApiResponse =
  /** status 202 Accepted */ InquiryComment;
export type PutSuperOwnerInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  inquiryCommentRequestBody: InquiryCommentRequestBody;
};
export type DeleteSuperOwnerInquiryCommentsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSuperOwnerInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetLearningTemplatePackagesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    learning_template_packages: LearningTemplatePackageBase[];
    total_count: number;
  };
export type GetLearningTemplatePackagesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** プログラムのタイトルで絞り込み */
  title?: string;
};
export type PostLearningTemplatePackageAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostLearningTemplatePackageAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  titleAndDescriptionRequiredRequestBody: TitleAndDescriptionRequiredRequestBody;
};
export type GetLearningTemplatePackageAsSuperOwnerApiResponse =
  /** status 200 OK */ LearningTemplatePackageBase;
export type GetLearningTemplatePackageAsSuperOwnerApiArg = {
  /** LearningTemplatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutLearningTemplatePackageAsSuperOwnerApiResponse =
  /** status 202 Accepted */ LearningTemplatePackageBase;
export type PutLearningTemplatePackageAsSuperOwnerApiArg = {
  /** LearningTemplatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  titleAndDescriptionRequiredRequestBody: TitleAndDescriptionRequiredRequestBody;
};
export type DeleteLearningTemplatePackagAsSuperOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteLearningTemplatePackagAsSuperOwnerApiArg = {
  /** LearningTemplatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutLearningTemplatePackagesSequenceAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutLearningTemplatePackagesSequenceAsSuperOwnerApiArg = {
  learningTemplatePackageSequencesRequestBody: LearningTemplatePackageSequencesRequestBody;
};
export type GetLearningTemplatePackageDistributionsAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    learning_template_package_distribution_settings: LearningTemplatePackageDistributionSettingBase[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type DeleteLearningTemplatePackageDistributionAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteLearningTemplatePackageDistributionAsSuperOwnerApiArg = {
  /** LearningTemplatePackageDistributionSetting ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PatchLearningTemplatePackageDistributionsBatchCreateAsSuperOwnerApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 201 Created */ {
        message?: string;
      };
export type PatchLearningTemplatePackageDistributionsBatchCreateAsSuperOwnerApiArg =
  {
    /** LearningTemplatePackage ID */
    learningTemplatePackageId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    batchCreateLearningTemplatePackageDistributionRequiredRequestBody: BatchCreateLearningTemplatePackageDistributionRequiredRequestBody;
  };
export type GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    student_affiliates: AffiliateStudentName[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerApiArg =
  {
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
    /** LearningTemplatePackage ID */
    learningTemplatePackageId: string;
    companyIds: string[];
    grade?: string;
  };
export type GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    student_affiliates: AffiliateStudentName[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerApiArg =
  {
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
    /** LearningTemplatePackage ID */
    learningTemplatePackageId: string;
    userTagIds: string[];
    grade?: string;
  };
export type GetLearningTemplatePackageDistributionHistoriesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    learning_template_package_distribution_histories: (ModelBase & {
      learning_template_package_id: string;
      distributor_affiliate_id: string;
      start_at: string;
      end_at?: string;
      distribution_at: string;
      kind: string;
      grade?: StudentGrade;
      forbid_download: boolean;
      number_of_completed_learning_package?: number;
      number_of_students_distributed: number;
      number_of_school_distributed?: number;
      status?: DistributionStatusStatus | null;
      deletion_progress: DeletionProgress;
      companies: HasIdAndName[];
      user_tags: HasIdAndName[];
      learning_template_package: LearningTemplatePackageBase;
    })[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionHistoriesAsSuperOwnerApiArg =
  {
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
  };
export type PutLearningTemplatePackageDistributionHistoriesAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutLearningTemplatePackageDistributionHistoriesAsSuperOwnerApiArg =
  {
    /** LearningTemplatePackageDistributionSetting ID */
    id: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    startAtAndEndAtRequestBody: StartAtAndEndAtRequestBody;
  };
export type GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    distributed_students: DistributedStudents[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwnerApiArg =
  {
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
    /** LearningTemplatePackageDistributionSetting ID */
    id: string;
  };
export type GetMaterialHomeworkTemplatePackagesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    material_homework_template_packages: MaterialHomeworkTemplatePackageBase[];
    total_count: number;
  };
export type GetMaterialHomeworkTemplatePackagesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostMaterialHomeworkTemplatePackageAsSuperOwnerApiResponse =
  /** status 201 Created */ MaterialHomeworkTemplatePackageBase;
export type PostMaterialHomeworkTemplatePackageAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  titleAndDescriptionRequiredRequestBody: TitleAndDescriptionRequiredRequestBody;
};
export type GetMaterialHomeworkTemplatePackageAsSuperOwnerApiResponse =
  /** status 200 OK */ MaterialHomeworkTemplatePackageBase;
export type GetMaterialHomeworkTemplatePackageAsSuperOwnerApiArg = {
  /** MaterialHomeworkTemplatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutMaterialHomeworkTemplatePackageAsSuperOwnerApiResponse =
  /** status 202 Accepted */ MaterialHomeworkTemplatePackageBase;
export type PutMaterialHomeworkTemplatePackageAsSuperOwnerApiArg = {
  /** MaterialHomeworkTemplatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  titleAndDescriptionRequiredRequestBody: TitleAndDescriptionRequiredRequestBody;
};
export type DeleteMaterialHomeworkTemplatePackageAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteMaterialHomeworkTemplatePackageAsSuperOwnerApiArg = {
  /** MaterialHomeworkTemplatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwnerApiArg =
  {
    /** MaterialHomeworkTemplatePackage ID */
    id: string;
    /** Packaging ID */
    packagingId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    sequenceRequiredRequestBody: SequenceRequiredRequestBody;
  };
export type GetSuperOwnerMaterialWorksTargetCompaniesApiResponse =
  /** status 200 OK */ {
    companies: {
      id: string;
      name: string;
      postcode: string;
      prefecture: string;
      address1: string;
      address2: string;
      phone: string;
      email: string;
      logo: {
        url?: string;
        thumb?: {
          url?: string;
        };
        icon?: {
          url?: string;
        };
      };
      target_student_count: number;
      distributed_student_count: number;
    }[];
    total_count: number;
  };
export type GetSuperOwnerMaterialWorksTargetCompaniesApiArg = {
  materialId: string;
  companyIds?: string[];
  userTagIds?: string[];
  grade?: StudentGrade;
  page?: number;
  perPage?: number;
};
export type GetSuperOwnerMaterialWorksTargetMaterialsApiResponse =
  /** status 200 OK */ {
    materials: (MaterialDetail & {
      target_company_count: number;
      target_student_count: number;
      distributed_student_count: number;
    })[];
    total_count: number;
  };
export type GetSuperOwnerMaterialWorksTargetMaterialsApiArg = {
  categoryId: string;
  companyIds?: string[];
  userTagIds?: string[];
  grade?: StudentGrade;
  page?: number;
  perPage?: number;
};
export type PostPackagingAsSuperOwnerApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostPackagingAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  packagingRequestBody: PackagingRequestBody;
};
export type PutPackagingAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPackagingAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** - パッケージのID */
  packageId: string;
  /** - パッケージに登録するコンテンツの Modelの名前 を指定 */
  packageableType: string;
  /** - パッケージに登録するコンテンツの ID を指定 */
  packageableId: string;
  body: {
    sequence: number;
  };
};
export type DeletePackagingAsSuperOwnerApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeletePackagingAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** - パッケージのID */
  packageId: string;
  /** - パッケージに登録するコンテンツの Modelの名前 を指定 */
  packageableType: string;
  /** - パッケージに登録するコンテンツの ID を指定 */
  packageableId: string;
};
export type GetPackagingsAsSuperOwnerApiResponse = /** status 200 OK */ {
  packagings: PackagingAsLearningTemplatePackageBase[];
  total_count: number;
};
export type GetPackagingsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  packageId: string;
};
export type GetPblBoardInfoAsSuperOwnerApiResponse =
  /** status 200 OK */ PblBoardInfoBase;
export type GetPblBoardInfoAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** 文字列のtrueを設定をすると探究成果一覧を取得する */
  preview?: string;
  /** 探究成果一覧のページング */
  page?: number;
  /** 探究成果一覧のページング */
  perPage?: number;
};
export type PostPblBoardInfoAsSuperOwnerApiResponse =
  /** status 201 Created */ PblBoardInfoBase;
export type PostPblBoardInfoAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardInfoAsSuperOwnerApiResponse =
  /** status 202 Accepted */ PblBoardInfoBase;
export type PutPblBoardInfoAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  pblBoardInfoEventRequiredRequestBody: PblBoardInfoEventRequiredRequestBody;
};
export type PostPblBoardItemAsSuperOwnerApiResponse =
  /** status 201 Created */ PblBoardItemBaseAsShow;
export type PostPblBoardItemAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  pblBoardItemRequestBody: PblBoardItemRequestBody;
};
export type PutPblBoardItemAsSuperOwnerApiResponse =
  /** status 202 Accepted */ PblBoardItemBaseAsShow;
export type PutPblBoardItemAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  pblBoardItemRequestBody: PblBoardItemRequestBody;
};
export type DeletePblBoardItemAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardItemAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblBoardCampaignsAsSuperOwnerApiResponse = /** status 200 OK */ {
  board_campaigns: PblBoardCampaignList[];
  total_count: number;
};
export type GetPblBoardCampaignsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostPblBoardCampaignAsSuperOwnerApiResponse =
  /** status 201 Created */ PblBoardCampaignBase;
export type PostPblBoardCampaignAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postPblBoardCampaignRequestBody: PostPblBoardCampaignRequestBody;
};
export type GetPblBoardCampaignAsSuperOwnerApiResponse =
  /** status 200 OK */ PblBoardCampaignBase;
export type GetPblBoardCampaignAsSuperOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardCampaignAsSuperOwnerApiResponse =
  /** status 202 Accepted */ PblBoardCampaignBase;
export type PutPblBoardCampaignAsSuperOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putPblBoardCampaignRequestBody: PutPblBoardCampaignRequestBody;
};
export type DeletePblBoardCampaignAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardCampaignAsSuperOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostPblBoardCampaignRequestAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    board_campaign_requests: PblBoardCampaignRequestList[];
    total_count: number;
  };
export type PostPblBoardCampaignRequestAsSuperOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  campaignId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchStatus?: PblBoardCampaignRequestStatus;
};
export type PutPblBoardCampaignRequestAsSuperOwnerApiResponse =
  /** status 202 Accepted */ PblBoardCampaignRequestList;
export type PutPblBoardCampaignRequestAsSuperOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  campaignId: string;
  /** 探究ボードの探究成果募集リクエスト ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  pblBoardCampaignRequestEventRequiredRequestBody: PblBoardCampaignRequestEventRequiredRequestBody;
};
export type PutPblBoardCampaignRequestSequenceAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardCampaignRequestSequenceAsSuperOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  campaignId: string;
  /** 探究ボードの探究成果募集リクエスト ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  sequenceRequiredRequestBody: SequenceRequiredRequestBody;
};
export type GetPblBoardArticlesAsSuperOwnerApiResponse = /** status 200 OK */ {
  articles: PblBoardArticleBase[];
  total_count: number;
};
export type GetPblBoardArticlesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetPblBoardArticleAsSuperOwnerApiResponse =
  /** status 200 OK */ ArticleDetailAsPblBoard;
export type GetPblBoardArticleAsSuperOwnerApiArg = {
  /** Article ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostPblBoardContentAsSuperOwnerApiResponse =
  /** status 201 Created */ PblBoardContentBase;
export type PostPblBoardContentAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  pblBoardContentRequestBody: PblBoardContentRequestBody;
};
export type GetPblBoardContentAsSuperOwnerApiResponse =
  /** status 200 OK */ PblBoardContentBase;
export type GetPblBoardContentAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのコンテンツ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardContentAsSuperOwnerApiResponse =
  /** status 202 Accepted */ PblBoardContentBase;
export type PutPblBoardContentAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのコンテンツ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  pblBoardContentRequestBody: PblBoardContentRequestBody;
};
export type DeletePblBoardContentAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardContentAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのコンテンツ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblBoardFootersAsSuperOwnerApiResponse = /** status 200 OK */ {
  board_footers: PblBoardFooterBase[];
  total_count: number;
};
export type GetPblBoardFootersAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostPblBoardFooterAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostPblBoardFooterAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  postPblBoardFooterRequestBody: PostPblBoardFooterRequestBody;
};
export type PutPblBoardFooterAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardFooterAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putPblBoardFooterRequestBody: PblBoardFooterItemRequiredRequestBody;
};
export type DeletePblBoardFooterAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardFooterAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostPblBoardFooterItemAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostPblBoardFooterItemAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  footerId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  pblBoardFooterItemRequiredRequestBody: PblBoardFooterItemRequiredRequestBody2;
};
export type PutPblBoardFooterItemAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardFooterItemAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  footerId: string;
  /** 探究ボードのフッターリンク ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  pblBoardFooterItemRequiredRequestBody: PblBoardFooterItemRequiredRequestBody2;
};
export type DeletePblBoardFooterItemAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardFooterItemAsSuperOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  footerId: string;
  /** 探究ボードのフッターリンク ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type AffiliateUpdateAsSuperOwnerApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type AffiliateUpdateAsSuperOwnerApiArg = {
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  affiliateStudentSuspensionRequiredRequestBody: AffiliateStudentSuspensionRequiredRequestBody;
};
export type AffiliatesUpdateAsSuperOwnerApiResponse = /** status 200 200 OK */
  | {
      success_ids?: string[];
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type AffiliatesUpdateAsSuperOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  bulkUpdateRequestBody: BulkUpdateRequestBody;
};
export type AffiliatesDestroyAsSuperOwnerApiResponse = /** status 200 200 OK */
  | {
      success_ids?: string[];
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type AffiliatesDestroyAsSuperOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  idsRequiredRequestBody: IdsRequiredRequestBody;
};
export type PostAttachedImageAsSuperOwnerApiResponse =
  /** status 201 Created */ AttachedImageBase;
export type PostAttachedImageAsSuperOwnerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetAttachedImageAsSuperOwnerApiResponse =
  /** status 200 OK */ AttachedImageBase;
export type GetAttachedImageAsSuperOwnerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PutAttachedImageAsSuperOwnerApiResponse =
  /** status 202 Accepted */ AttachedImageBase;
export type PutAttachedImageAsSuperOwnerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteAttachedImageAsSuperOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteAttachedImageAsSuperOwnerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type GetLimitedTimeNotificationsAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    limited_time_notifications: LimitedTimeNotificationList[];
    total_count: number;
  };
export type GetLimitedTimeNotificationsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostLimitedTimeNotificationAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostLimitedTimeNotificationAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postLimitedTimeNotificationRequestBody: PostLimitedTimeNotificationRequestBody;
};
export type GetLimitedTimeNotificationAsSuperOwnerApiResponse =
  /** status 200 OK */ LimitedTimeNotificationDetail;
export type GetLimitedTimeNotificationAsSuperOwnerApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutLimitedTimeNotificationAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutLimitedTimeNotificationAsSuperOwnerApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putLimitedTimeNotificationRequestBody: PutLimitedTimeNotificationRequestBody;
};
export type DeleteLimitedTimeNotificationAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteLimitedTimeNotificationAsSuperOwnerApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSteamContentsAsSuperOwnerApiResponse = /** status 200 OK */ {
  steam_contents?: PblSteamContent[];
  total_count?: number;
};
export type GetSteamContentsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetSteamLecturesAsSuperOwnerApiResponse = /** status 200 OK */ {
  steam_lectures: PblSteamLecture[];
  total_count: number;
};
export type GetSteamLecturesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  slContentId: number;
};
export type PostSuperOwnerSteamLecturesApiResponse = /** status 200 OK */
  | {
      is_registered: boolean;
    }
  | /** status 201 Created */ PblSteamLecture
  | /** status 202 Accepted */ PblSteamLecture;
export type PostSuperOwnerSteamLecturesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  slContentId: number;
  createSteamLectureRequestBody: CreateSteamLectureRequestBody;
};
export type GetSuperOwnerSteamLecturesIdApiResponse =
  /** status 200 OK */ PblSteamLecture;
export type GetSuperOwnerSteamLecturesIdApiArg = {
  /** SteamライブラリーのレクチャーIDを指定する。 */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorProjectsAsSuperOwnerApiResponse = /** status 200 OK */ {
  projects: SponsorProjectAndTagsBase[];
  total_count: number;
};
export type FetchSponsorProjectsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type FetchSponsorLecturesAsSuperOwnerApiResponse = /** status 200 OK */ {
  project_lectures: SponsorLectureDetailsBase[];
  total_count: number;
};
export type FetchSponsorLecturesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  projectId: string;
};
export type FetchSponsorLectureAsSuperOwnerApiResponse =
  /** status 200 OK */ SponsorLectureDetailsBase;
export type FetchSponsorLectureAsSuperOwnerApiArg = {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTtCsvExportsAsSuperOwnerApiResponse = /** status 200 OK */ {
  tt_csv_exports: TtCsvExportBase[];
  total_count: number;
};
export type GetTtCsvExportsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  types?: TtCsvExportType[];
};
export type PostTtCsvExportAsSuperOwnerApiResponse =
  /** status 200 OK */ TtCsvExportBase;
export type PostTtCsvExportAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  sortedBy?: TtCsvExportSearchTypeSortedBy;
  searchStatus?: AffiliateStatus;
  searchType?: TtCsvExportSearchType;
  searchValue?: string;
  ttCsvExportRequestBody: TtCsvExportRequestBody;
};
export type PostTtCategoryBaseAsSuperOwnerApiResponse =
  /** status 201 Created */ TtCategoryBase;
export type PostTtCategoryBaseAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  createTtCategoryRequestBody: CreateTtCategoryRequestBody;
};
export type PutTtCategoryBaseAsSuperOwnerApiResponse =
  /** status 202 Accepted */ TtCategoryBase;
export type PutTtCategoryBaseAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  id: string;
  updateTtCategoryRequestBody: UpdateTtCategoryRequestBody;
};
export type GetFeedbackTemplateHomeworksAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    feedback_templates: FeedbackTemplateHomeworkBase[];
    total_count: number;
  };
export type GetFeedbackTemplateHomeworksAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: FeedbackTemplateSearchType;
  searchWord?: string;
  /** タグ名に完全一致するものを返却する */
  tagName?: string;
};
export type PostFeedbackTemplateHomeworkAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostFeedbackTemplateHomeworkAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postFeedbackTemplateHomeworkRequestBody: PostFeedbackTemplateHomeworkRequestBody;
};
export type GetFeedbackTemplateHomeworkAsSuperOwnerApiResponse =
  /** status 200 OK */ FeedbackTemplateHomeworkBase;
export type GetFeedbackTemplateHomeworkAsSuperOwnerApiArg = {
  /** FeedbackTemplateHomework ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutFeedbackTemplateHomeworkAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutFeedbackTemplateHomeworkAsSuperOwnerApiArg = {
  /** FeedbackTemplateHomework ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putFeedbackTemplateHomeworkRequestBody: PutFeedbackTemplateHomeworkRequestBody;
};
export type DeleteFeedbackTemplateHomeworkAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteFeedbackTemplateHomeworkAsSuperOwnerApiArg = {
  /** FeedbackTemplateHomework ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostFeedbackTemplateCommentAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostFeedbackTemplateCommentAsSuperOwnerApiArg = {
  /** FeedbackTemplate ID */
  feedbackTemplateId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  bodyRequiredRequestBody: BodyRequiredRequestBody;
};
export type PutFeedbackTemplateCommentAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutFeedbackTemplateCommentAsSuperOwnerApiArg = {
  /** FeedbackTemplate ID */
  feedbackTemplateId: string;
  /** FeedbackTemplateComment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  bodyRequiredRequestBody: BodyRequiredRequestBody;
};
export type DeleteFeedbackTemplateCommentAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteFeedbackTemplateCommentAsSuperOwnerApiArg = {
  /** FeedbackTemplate ID */
  feedbackTemplateId: string;
  /** FeedbackTemplateComment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetFeedbackTemplateTagsAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    feedback_template_tags: HasName[];
    total_count: number;
  };
export type GetFeedbackTemplateTagsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type FetchHelpContentsAsSuperOwnerApiResponse = /** status 200 OK */ {
  help_master?: HelpMasterBase;
  help_contents: HelpContentBase[];
  total_count: number;
};
export type FetchHelpContentsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  kind: HelpMasterKind;
};
export type PutHelpContentsAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutHelpContentsAsSuperOwnerApiArg = {
  /** Help::Content ID */
  id: string;
  helpRequestBodyAsSuperOwner: HelpRequestBodyAsSuperOwner;
};
export type GetIdealBoardTemplatesAsSuperOwnerApiResponse =
  /** status 200 OK */ {
    board_templates: BoardTemplateList[];
    total_count: number;
  };
export type GetIdealBoardTemplatesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchStatus?: IdealBoardTemplateStatus;
  noHomeworkTemplate?: boolean;
};
export type PostIdealBoardTemplatesAsSuperOwnerApiResponse =
  /** status 201 Created */ BoardTemplate;
export type PostIdealBoardTemplatesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postIdealBoardTemplateRequestBody: PostIdealBoardTemplateRequestBody;
};
export type GetIdealBoardTemplateAsSuperOwnerApiResponse =
  /** status 200 OK */ BoardTemplate;
export type GetIdealBoardTemplateAsSuperOwnerApiArg = {
  /** Ideal::BoardTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutIdealBoardTemplateAsSuperOwnerApiResponse =
  /** status 202 Accepted */ BoardTemplate;
export type PutIdealBoardTemplateAsSuperOwnerApiArg = {
  /** Ideal::BoardTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putIdealBoardTemplateRequestBody: PutIdealBoardTemplateRequestBody;
};
export type DeleteIdealBoardTemplateAsSuperOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteIdealBoardTemplateAsSuperOwnerApiArg = {
  /** Ideal::BoardTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetIdealBoardsAsSuperOwnerApiResponse = /** status 200 OK */ {
  boards: BoardList[];
  total_count: number;
};
export type GetIdealBoardsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  idealBoardTemplateId?: string;
  groupAffiliateId?: string;
};
export type PostIdealBoardsAsSuperOwnerApiResponse =
  /** status 201 Created */ Board;
export type PostIdealBoardsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postIdealBoardRequestBody: PostIdealBoardRequestBody;
};
export type GetIdealBoardAsSuperOwnerApiResponse = /** status 200 OK */ Board;
export type GetIdealBoardAsSuperOwnerApiArg = {
  /** Ideal::Board ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutIdealBoardAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutIdealBoardAsSuperOwnerApiArg = {
  /** Ideal::Board ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putIdealBoardRequestBody: PutIdealBoardRequestBody;
};
export type DeleteIdealBoardAsSuperOwnerApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteIdealBoardAsSuperOwnerApiArg = {
  /** Ideal::Board ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostIdealBoardsAffiliatesAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostIdealBoardsAffiliatesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  idealBoardAffiliateRequestBody: IdealBoardAffiliateRequestBody;
};
export type DeleteIdealBoardsAffiliatesAsSuperOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteIdealBoardsAffiliatesAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  idealBoardAffiliateRequestBody: IdealBoardAffiliateRequestBody;
};
export type GetCommonTagsAsSuperOwnerApiResponse = /** status 200 OK */ {
  common_tags: CommonTagBase[];
  total_count: number;
};
export type GetCommonTagsAsSuperOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: Schema;
  searchWord?: string;
  kind?: CommonTagKind;
  categorizableType?: CommonTaggingCategorizableType;
};
export type PostCommonTaggingAsSuperOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostCommonTaggingAsSuperOwnerApiArg = {
  categorizableType: CommonTaggingCategorizableType;
  categorizableId: string;
  commonTaggingRequestBody: CommonTaggingRequestBody;
};
export type DeleteCommonTaggingAsSuperOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteCommonTaggingAsSuperOwnerApiArg = {
  categorizableType: CommonTaggingCategorizableType;
  categorizableId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  commonTagId: string;
};
export type ErrorsWithKeyMessage = {
  errors: {
    key?: string;
    message?: string;
  }[];
};
export type GraduationYearRequiredRequestBody = {
  graduation_year: string;
};
export type GradeAndGraduationYearRequiredRequestBody =
  GraduationYearRequiredRequestBody & {
    grade: number;
  };
export type ModelBase = {
  id: string;
};
export type UserTagBasic = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_linking_users?: number;
  kind?: string;
};
export type EmailBase = ModelBase & {
  title?: string;
  created_at?: string;
  to_all_students?: boolean;
  to_all_teachers?: boolean;
  to_all_owners?: boolean;
  from_user?: string;
  sent_count?: number;
  body?: string;
  user_tags?: UserTagBasic[];
  sender_companies?: {
    id?: string;
    name?: string;
  }[];
};
export type EmailRequestBodyAsSuperOwner = {
  title: string;
  body: string;
  target_user_tags?: string[];
  target_companies: string[];
  to_all_students?: boolean;
  to_all_teachers?: boolean;
  to_all_owners?: boolean;
};
export type MaterialCategoryIdRequiredRequestBody = {
  category_id: string;
};
export type PutMaterialsTicketRequestBody = {
  hashed_ticket_id: string;
};
export type CategoryRenameMap = {
  id?: string | null;
  for_text: string | null;
  for_subject: string | null;
  for_grade: string | null;
  for_major: string | null;
  for_middle: string | null;
  for_minor: string | null;
} | null;
export type CategoryRenameMapRequestBody = {
  for_text?: string;
  for_subject?: string;
  for_grade?: string;
  for_major?: string;
  for_middle?: string;
  for_minor?: string;
};
export type CustomMasterMapBase = {
  master_name: string;
  used_columns: number;
  title_for_column1: string;
  title_for_column2?: string | null;
  title_for_column3?: string | null;
  title_for_column4?: string | null;
  title_for_column5?: string | null;
  title_for_column6?: string | null;
  title_for_column7?: string | null;
  title_for_column8?: string | null;
  title_for_column9?: string | null;
  title_for_column10?: string | null;
  title_for_column11?: string | null;
  title_for_column12?: string | null;
  title_for_column13?: string | null;
  title_for_column14?: string | null;
  title_for_column15?: string | null;
  title_for_column16?: string | null;
  title_for_column17?: string | null;
  title_for_column18?: string | null;
  title_for_column19?: string | null;
  title_for_column20?: string | null;
  column_visible_flags: boolean[];
  has_school_relation: boolean;
};
export type CustomMasterRelationship = {
  relationship_types_id: string;
  relation_custom_master_id: string;
  column_title: string;
  column: string;
};
export type CustomMasterMap = ModelBase &
  CustomMasterMapBase & {
    relationships: CustomMasterRelationship[];
  };
export type PostCustomMasterMapRequiredRequestBody = {
  master_name: string;
  used_columns: number;
};
export type CustomMasterMapSequencesRequestBody = {
  sequences?: {
    custom_master_map_id?: string;
    sequence?: number;
  }[];
};
export type CustomMasterBase = {
  column1: string | null;
  column2: string | null;
  column3: string | null;
  column4: string | null;
  column5: string | null;
  column6: string | null;
  column7: string | null;
  column8: string | null;
  column9: string | null;
  column10: string | null;
  column11: string | null;
  column12: string | null;
  column13: string | null;
  column14: string | null;
  column15: string | null;
  column16: string | null;
  column17: string | null;
  column18: string | null;
  column19: string | null;
  column20: string | null;
  column_visible_flags: boolean[];
  company_id?: string;
  company_name?: string;
};
export type CustomMaster = ModelBase &
  CustomMasterBase & {
    relationships: CustomMasterRelationship[];
  };
export type CustomMasterRequestBody = {
  column1?: string;
  column2?: string;
  column3?: string;
  column4?: string;
  column5?: string;
  column6?: string;
  column7?: string;
  column8?: string;
  column9?: string;
  column10?: string;
  column11?: string;
  column12?: string;
  column13?: string;
  column14?: string;
  column15?: string;
  column16?: string;
  column17?: string;
  column18?: string;
  column19?: string;
  column20?: string;
};
export type PostCustomMasterRequestBody = {
  custom_master_map_id: string;
  company_id?: string;
  relationships?: {
    relationship_types_id: string;
    child_relation_id: string;
  }[];
};
export type PutCustomMasterRequestBody = {
  relationships?: {
    relationship_types_id: string;
    child_relation_id: string;
  }[];
  delete_relationships?: {
    relationship_types_id: string;
    child_relation_id: string;
  }[];
};
export type CompanyIdRequiredRequestBody = {
  company_id: string;
};
export type HasIdAndName = {
  id: string;
  name: string;
};
export type TitleForColumn = {
  number: number;
  name?: string;
};
export type CustomMasterMapRelationshipBase = ModelBase & {
  company_id: string;
  name: string;
  parent_custom_master_map: HasIdAndName;
  child_custom_master_map: HasIdAndName;
  parent_title_for_column: TitleForColumn;
  child_title_for_column: TitleForColumn;
};
export type PostCustomMasterMapRelationshipRequestBody = {
  name: string;
  child_custom_master_map_id: string;
  parent_title_for_column_number: number;
  child_title_for_column_number: number;
};
export type NameRequiredRequestBody = {
  name: string;
};
export type BatchCreateCustomMasterMapRelationshipRequestBody = {
  custom_master_map_relationship_id: string;
  parent_relation_ids: string[];
  child_relation_ids: string[];
};
export type NotificationBase = ModelBase & {
  title: string;
  message: string;
  resource_info: string | null;
  sender?: {
    id?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
  };
  created_at: string;
  updated_at: string;
  notified_time: string;
};
export type NotificationStatus =
  | "reserved"
  | "waiting"
  | "delivering"
  | "delivered";
export type Notifications = {
  notifications?: (NotificationBase & {
    user_tags?: UserTagBasic[];
    company_tags?: {
      id?: string;
      name?: string;
    }[];
    roles?: {
      id?: string;
      name?: string;
      jp_name?: string;
    }[];
    reservation_time?: string;
    number_of_reserve_deliveries?: number | null;
    status?: NotificationStatus | null;
    companies?: {
      id?: string;
      name?: string;
    }[];
  })[];
  total_count?: number;
};
export type NotificationRequestBody = {
  title?: string;
  message?: string;
};
export type NotificationRequestBodyAsAdmin = NotificationRequestBody & {
  target_company_ids: string[];
  target_roles: string[];
};
export type NotificationRequestBodyAsSuperOwner =
  NotificationRequestBodyAsAdmin & {
    target_user_tag_ids?: string[];
    target_company_tag_ids?: string[];
    publication_time?: string;
    with_email?: boolean;
  };
export type Notification = NotificationBase & {
  user_tags?: UserTagBasic[];
  company_tags?: {
    id?: string;
    name?: string;
  }[];
  roles?: {
    id?: string;
    name?: string;
    jp_name?: string;
  }[];
  reservation_time?: string;
  number_of_reserve_deliveries?: number | null;
  companies?: {
    id?: string;
    name?: string;
  }[];
  number_of_deliveries?: number;
};
export type UserTagTypeBasic = ModelBase & {
  name: string;
  kind?: string;
  teacher_visible: boolean;
  role_type?: string;
};
export type UserTagWithCompaniesNum = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_assigned_companies: number;
};
export type CreatedUserTagType = UserTagTypeBasic & {
  user_tags: UserTagWithCompaniesNum[];
};
export type UserTagTypeKind = "limited" | "nationwide";
export type UserTagTypeRoleType = "student" | "teacher";
export type BasicUserInfo = ModelBase & {
  first_name: string;
  last_name: string;
  email: string;
  optional_email?: string | null;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  expired_date?: string | null;
  current_sign_in_at?: string | null;
  last_sign_in_at?: string | null;
  linked_project_count?: number | null;
  affiliate_id?: string | null;
  nickname: string | null;
};
export type AffiliateStatusJa =
  | "\u5229\u7528\u4E2D"
  | "\u671F\u9650\u5207\u308C"
  | "\u767B\u9332\u5F85\u3061"
  | "\u30ED\u30C3\u30AF\u4E2D"
  | "\u6E96\u5099\u4E2D"
  | "\u672A\u627F\u8A8D"
  | "\u5229\u7528\u505C\u6B62";
export type UserInfoForSuperOwner = BasicUserInfo & {
  affiliate_id: string;
  affiliate_status?: AffiliateStatusJa;
  company_id: string;
  company_name: string;
  role_name: string;
  role_jp_name: string;
  user_tags: {
    name?: string;
  }[];
};
export type AffiliateStatus =
  | "enable"
  | "expired"
  | "waiting"
  | "locked"
  | "scheduling"
  | "unapproved"
  | "suspension_of_use";
export type AffiliateItem = {
  company_id?: string;
  company_name?: string;
  roles?: {
    id?: string;
    role?: string;
    affiliate_id?: string;
    only_pbl_projects_feature?: string;
    student_suspension?: string;
    status?: string;
  }[];
};
export type AffiliateListBase = {
  affiliates: AffiliateItem[];
};
export type PutUserTagTypeRequestBody = {
  name?: string;
  teacher_visible?: boolean;
  role_type?: "student" | "teacher";
};
export type PostUserTagTypeRequestBody = PutUserTagTypeRequestBody & {
  kind?: UserTagTypeKind;
};
export type PostUserTaggingRequestBody = {
  user_id: string;
  user_tag_id: string;
  company_id: string;
};
export type UserTaggingBatchCreateResponses = {
  success_user_taggings: {
    user_id?: string;
    user_name?: string;
    user_tag_id?: string;
    user_tag_name?: string;
  }[];
  errors: (string | null)[];
};
export type UserTaggingBatchCreateParams = {
  user_ids: string[];
  user_tag_id: string;
  company_id?: string;
};
export type ReviewerCompanyGroupBase = ModelBase & {
  name: string;
  is_main_reviewer_group: boolean;
  super_company: ModelBase & {
    name?: string;
  };
  is_public_profile?: boolean;
};
export type ReviewerCompanyGroupDetail = ReviewerCompanyGroupBase & {
  reviewer_companies: (ModelBase & {
    name?: string;
  })[];
  reviewee_companies: (ModelBase & {
    name?: string;
  })[];
};
export type RevieweeCompaniesRequestBodyAsSuperOwner = {
  name: string;
  reviewer_company_ids?: string[];
};
export type Option = {
  use_video?: boolean;
  custom_student_ui?: boolean;
  homework_feature?: boolean;
  blog_feature?: boolean;
  text_seller?: boolean;
  use_image?: boolean;
  material?: boolean;
  teacher_material?: boolean;
  report?: boolean;
  payment?: boolean;
  calender?: boolean;
  review_feature?: boolean;
  reviewer_company?: boolean;
  tag?: boolean;
  google_classroom?: boolean;
  steam_library?: boolean;
  content_company?: boolean;
  content_company_review_feature?: boolean;
  dashboard_feature?: boolean;
  study_log_feature?: boolean;
  ideal_feature?: boolean;
  pbl_board_feature?: boolean;
  exam_feature?: boolean;
  rubric_feature?: boolean;
  word_cloud_feature?: boolean;
  rubric_evaluate_feature?: boolean;
  google_drive_feature?: boolean;
  one_drive_feature?: boolean;
  university_cooperation_feature?: boolean;
  article_student_comment_feature?: boolean;
  homework_automatic_scoring_feature?: boolean;
  class_nav_feature?: boolean;
  use_account_lock_mail?: boolean;
  gogo_feature?: boolean;
  pbl_projects_feature?: boolean;
  use_article_and_homework_mail?: boolean;
};
export type UserInputSetting = "required" | "optional" | "hidden";
export type StudentInputSetting = {
  avatar: UserInputSetting;
  postcode: UserInputSetting;
  prefecture: UserInputSetting;
  address1: UserInputSetting;
  address2: UserInputSetting;
  birth_date: UserInputSetting;
  grade: UserInputSetting;
  phone: UserInputSetting;
  parent_first_name: UserInputSetting;
  parent_last_name: UserInputSetting;
  number: UserInputSetting;
};
export type DeletionProgress = "running" | "failed" | "idle";
export type Company = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  custom_master?: {
    title?: string;
    value?: string;
  }[];
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
  report_recipient_email?: string;
  report_the_number_of_users_email?: string;
  report_homework_template_distribution_histories_email?: string;
  inquiry_email?: string;
  limited_user_tag_monthly_report_email?: string;
  created_at?: string;
  owner_number?: number;
  teacher_number?: number;
  student_number?: number;
  has_super_owners?: boolean;
  options?: Option;
  company_tags?: {
    id?: string;
    name?: string;
    grade?: string | null;
    company_tag_type_id?: string;
    company_tag_type_name?: string;
  }[];
  linking_reviewer_companies?: boolean;
  student_input_settings?: StudentInputSetting;
  unique_id?: string | null;
  deletion_progress?: DeletionProgress | null;
  reviewer_company_group?: {
    id?: string;
    name?: string;
  };
  student_count_by_grade?: {
    grade?: string;
    count?: number;
  }[];
};
export type CompanyRoleType = "reviewer";
export type CompanyRequestBody = {
  name?: string;
  postcode?: string;
  prefecture?: string;
  adress1?: string;
  adress2?: string;
  phone?: string;
  logo?: string;
  options?: Option;
  email?: string;
};
export type PostCompanyRequestBodyAsSuperOwner = CompanyRequestBody & {
  is_reviewer?: boolean;
  unique_id?: string;
  reviewer_company_group_id?: string;
};
export type CompanyAsSuperOwner = Company & {
  granted_user_tags?: UserTagBasic[];
  reviewer_companies_by_reviewer_company_group?: {
    name?: string;
    reviewer_companies?: {
      id?: string;
      name?: string;
    }[];
  }[];
};
export type PutCompanyRequestBodyAsSuperOwner = CompanyRequestBody & {
  report_recipient_email?: string;
  report_the_number_of_users_email?: string;
  report_homework_template_distribution_histories_email?: string;
  inquiry_email?: string;
  student_input_settings?: StudentInputSetting;
  unique_id?: string;
  limited_user_tag_monthly_report_email?: string;
  reviewer_company_group_id?: string;
};
export type LimitOptionBase = {
  over_capacity_material?: boolean | null;
  over_capacity_ideal?: boolean | null;
};
export type CompanyBase = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
};
export type Role =
  | "admin"
  | "super_owner"
  | "owner"
  | "teacher"
  | "student"
  | "reviewer"
  | "content_provider";
export type RoleJa =
  | "SV\u7BA1\u7406\u8005"
  | "\u89AA\u7BA1\u7406\u8005"
  | "\u7BA1\u7406\u8005"
  | "\u6559\u8077\u54E1"
  | "\u751F\u5F92"
  | "\u6DFB\u524A\u5229\u7528\u8005"
  | "\u30B3\u30F3\u30C6\u30F3\u30C4\u4E8B\u696D\u8005";
export type RoleBase = ModelBase & {
  name: Role;
  jp_name: RoleJa;
};
export type AffiliateBase = {
  affiliate_id: string;
  user?: BasicUserInfo;
  company?: CompanyBase;
  role?: RoleBase;
};
export type AffiliateAccount = ModelBase & {
  invitation_scheduled_at: string | null;
  status: AffiliateStatusJa;
  last_accessed_at: string | null;
};
export type OwnerListAsSuperOwner = BasicUserInfo & {
  affiliate: AffiliateAccount;
};
export type UserNameRequestBody = {
  first_name?: string;
  last_name?: string;
};
export type UserNameAndEmailRequestBody = UserNameRequestBody & {
  email?: string;
};
export type UserAuthorization = {
  material_distribution?: boolean;
  nationwide_user_tag?: boolean;
  limited_user_tag?: boolean;
  homework_review?: boolean;
  user_edit?: boolean;
  notification_delivery?: boolean;
  send_inquiry_email?: boolean;
};
export type TeacherListAsSuperOwner = BasicUserInfo & {
  affiliate: AffiliateAccount;
};
export type UserList = BasicUserInfo & {
  affiliate: AffiliateAccount;
};
export type StudentListAsSuperOwner = UserList & {
  grade?: string;
  number?: number | null;
  user_tags_count: number;
  group_affiliate_packages_count: number;
};
export type SortedByStudentList = "grade" | "numberAsc" | "numberDesc";
export type StudentGradeJa =
  | "\u5B66\u5E74\u672A\u8A2D\u5B9A"
  | "\u5C0F\u5B661\u5E74"
  | "\u5C0F\u5B662\u5E74"
  | "\u5C0F\u5B663\u5E74"
  | "\u5C0F\u5B664\u5E74"
  | "\u5C0F\u5B665\u5E74"
  | "\u5C0F\u5B666\u5E74"
  | "\u4E2D\u5B661\u5E74"
  | "\u4E2D\u5B662\u5E74"
  | "\u4E2D\u5B663\u5E74"
  | "\u9AD8\u68211\u5E74"
  | "\u9AD8\u68212\u5E74"
  | "\u9AD8\u68213\u5E74"
  | "\u5927\u5B661\u5E74"
  | "\u5927\u5B662\u5E74"
  | "\u5927\u5B663\u5E74"
  | "\u5927\u5B664\u5E74"
  | "\u305D\u306E\u4ED6"
  | "\u5B66\u5E74\u306A\u3057";
export type GroupAffiliatePackageMinimalBase = ModelBase & {
  title: string;
};
export type ReviewerInfo = {
  is_manager: boolean;
};
export type ReviewerDetail = BasicUserInfo & {
  reviewer_info?: ReviewerInfo;
};
export type ReviewerInfoAttributes = {
  is_manager?: boolean;
};
export type UserReviewerRequestBody = UserNameAndEmailRequestBody & {
  nickname?: string;
  reviewer_info_attributes?: ReviewerInfoAttributes;
};
export type UserReviewerRequestBody2 = UserNameRequestBody & {
  nickname?: string;
  reviewer_info_attributes?: ReviewerInfoAttributes;
};
export type StudentInfoAttributes = {
  birth_date?: string;
  grade?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  number?: number;
};
export type TeacherInfoAttributes = {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  has_register_student?: boolean;
};
export type PostUserRequestBodyAsSuperOwner = UserNameAndEmailRequestBody & {
  nickname?: string;
  role?: string;
  optional_email?: string;
  authorizations?: UserAuthorization;
  student_info_attributes?: StudentInfoAttributes;
  teacher_info_attributes?: TeacherInfoAttributes;
  invitation_scheduled_at?: string;
  user_tag_ids?: string[] | null;
  group_ids?: string[] | null;
};
export type CreateAccountUserResponse = {
  user?: {
    id: string;
    account_id: string;
    full_name: string;
    password: string;
    affiliate: {
      company_id: string;
      company_name: string;
      role: string;
    };
  };
};
export type CreateAccountUserRequestBody = {
  email: string;
  last_name: string;
  first_name: string;
  optional_email?: string | null;
  role_name: string;
  password: string;
  authorizations?: UserAuthorization;
  user_params?: {
    grade?: string;
    parent_last_name?: string;
    parent_first_name?: string;
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    birth_date?: string;
    number?: number;
  };
  user_tag_ids?: string[] | null;
  group_ids?: string[] | null;
};
export type AffiliateIdRequiredRequestBody = {
  affiliate_id: string;
};
export type AccountUserInfo = {
  user_id: string;
  full_name: string;
  email: string;
  new_password: string;
  group_affiliate_packages?:
    | {
        title?: string;
      }[]
    | null;
};
export type AuthorizationPasswordRequestBody = {
  password: string;
  affiliate_id?: string;
};
export type Avatar = {
  url: string | null;
};
export type StudentGrade =
  | "under"
  | "elementary1"
  | "elementary2"
  | "elementary3"
  | "elementary4"
  | "elementary5"
  | "elementary6"
  | "junior1"
  | "junior2"
  | "junior3"
  | "high1"
  | "high2"
  | "high3"
  | "university1"
  | "university2"
  | "university3"
  | "university4"
  | "others";
export type BasicStudentInfo = {
  birth_date?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  grade?: string;
  grade_value?: StudentGrade;
  number?: number | null;
};
export type TeacherInfo = {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  has_register_student?: boolean;
};
export type ProfessorInfo = {
  position: string | null;
  department: string | null;
  description: string | null;
  limit_request: number | null;
  profile_picture: string | null;
};
export type UserAccountType = "email" | "account_id";
export type UserDetail = BasicUserInfo & {
  avatar?: Avatar;
  student_input_settings?: StudentInputSetting;
  student_info?: BasicStudentInfo;
  teacher_info?: TeacherInfo;
  user_authorizations?: UserAuthorization;
  student_affiliate_id?: string;
  professor_info?: ProfessorInfo;
  account_type?: UserAccountType;
  unique_id?: string | null;
};
export type PutUserRequestBodyAsSuperOwner = UserNameRequestBody & {
  optional_email?: string | null;
  user_type?: UserAccountType & any;
  new_id?: string;
  authorizations?: UserAuthorization;
  student_info_attributes?: StudentInfoAttributes;
  teacher_info_attributes?: TeacherInfoAttributes;
  user_tag_ids?: string[] | null;
  group_ids?: string[] | null;
  role?: "teacher" | "owner";
};
export type RoleRequiredRequestBody = {
  role: string;
};
export type UserTag = ModelBase & {
  name: string;
  number_of_linking_users?: number;
  limit_number?: number | null;
  is_editable?: boolean | null;
  user_tag_type?: UserTagTypeBasic;
  user_tag_company?: {
    start_at?: string | null;
  };
};
export type PutUserTagRequestBody = {
  name?: string;
  limit_number?: number;
};
export type PostUserTagRequestBody = PutUserTagRequestBody & {
  user_tag_type_id: string;
};
export type AssignedCompany = ModelBase & {
  name: string;
  start_at?: string | null;
  number_of_linking_users: number;
};
export type UserTagDetail = ModelBase & {
  name: string;
  limit_number?: number | null;
  is_editable: boolean;
  assigned_companies: AssignedCompany[];
  user_tag_type: UserTagTypeBasic;
};
export type UserTagSequencesRequestBody = {
  sequences: {
    id: string;
    sequence_number?: number;
  }[];
};
export type AssignUserTagRequestBody = {
  company_id: string;
  start_at?: string | null;
};
export type SimpleUserList = {
  id: string;
  name: string;
  nickname: string;
};
export type NameRequestBody = {
  name?: string;
};
export type CompanyTagTypeSequencesRequestBody = {
  sequences?: {
    company_tag_type_id?: string;
    sequence?: number;
  }[];
};
export type UserTagTypeSequencesRequestBody = {
  sequences?: {
    user_tag_type_id?: string;
    sequence?: number;
  }[];
};
export type Items = ModelBase & {
  status: string;
  tag_type: string;
  tag_name: string;
  start_at: string | null;
  limit_number: number | null;
  company: HasIdAndName;
};
export type UserTagDistributionSettingStatus = "unpublished" | "published";
export type CompanyTagType = ModelBase & {
  name: string;
};
export type CompanyTag = ModelBase & {
  name: string;
  free?: boolean;
  number_of_linking_companies?: number;
  number_of_linking_users?: number;
  tag_type?: CompanyTagType;
};
export type PostCompanyTagRequestBody = {
  company_tag_type_id?: string;
  name: string;
  free?: boolean;
};
export type CompanyTagSequencesRequestBody = {
  sequences?: {
    company_tag_id?: string;
    sequence?: number;
  }[];
};
export type CompanyTaggingRequestBody = {
  company_id?: string;
  company_tag_id?: string;
};
export type HomeworkTemplateStatus = "draft" | "active" | "inactive";
export type HomeworkTemplateStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u6709\u52B9"
  | "\u505C\u6B62\u4E2D";
export type HomeworkTemplateConvertStatus =
  | "not_needed"
  | "converting"
  | "adding_marks"
  | "adjusting"
  | "merging"
  | "processed"
  | "failed";
export type HomeworkTemplateConvertStatusJa =
  | "\u5909\u63DB\u306A\u3057"
  | "\u5909\u63DB\u4E2D"
  | "\u30DE\u30FC\u30AF\u4E2D"
  | "\u88DC\u6B63\u4E2D"
  | "\u30DE\u30FC\u30B8\u4E2D"
  | "\u51E6\u7406\u5B8C\u4E86"
  | "\u51E6\u7406\u5931\u6557";
export type HomeworkTemplateDistributionStatusJa =
  | "\u65B0\u898F"
  | "\u751F\u5F92\u5C55\u958B\u6E08\u307F"
  | "\u30EC\u30D3\u30E5\u30A2\u30FC\u78BA\u8A8D\u4E2D"
  | "\u30EC\u30D3\u30E5\u30A2\u30FC\u56DE\u7B54\u6E08\u307F"
  | "\u8FD4\u5374\u6E08\u307F";
export type HomeworkTemplateDistributionStatus =
  | "todo"
  | "distributed_students"
  | "submitted_reviewer"
  | "checked_reviewer"
  | "returned";
export type HomeworkTemplateDistributionBase = ModelBase & {
  created_at: string;
  memo: string | null;
  max_distribution_end_users: number | null;
  end_user_count: number;
  start_at: string | null;
  end_at: string | null;
  reviewer_end_at: string | null;
  status: HomeworkTemplateDistributionStatusJa;
  status_code: HomeworkTemplateDistributionStatus;
  is_exam: boolean;
};
export type HomeworkTemplateDistributionPblPlanItem = ModelBase & {
  policy_summary: string;
};
export type HomeworkTemplateDistribution = HomeworkTemplateDistributionBase & {
  submitted_at: string | null;
  checked_at: string | null;
  checked_at_raw: string | null;
  returned_at: string | null;
  reviewer_memo: string | null;
  company_memo: string | null;
  company?: Company;
  distribution_images?: {
    reviewer_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
    super_owner_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
    teacher_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
  };
  school_manager?: HasIdAndName;
  pbl_plan_items: HomeworkTemplateDistributionPblPlanItem[];
};
export type CommonTagKind = "system" | "free" | "management";
export type CommonTagBase = ModelBase & {
  kind: CommonTagKind;
  name: string;
};
export type HomeworkTemplateBase = {
  id: string;
  created_at: string;
  title: string;
  status_code: HomeworkTemplateStatus;
  status: HomeworkTemplateStatusJa;
  convert_status_code: HomeworkTemplateConvertStatus;
  convert_status: HomeworkTemplateConvertStatusJa;
  homework_template_distribution?: HomeworkTemplateDistribution;
  needs_review: boolean;
  needs_score: boolean;
  is_exam: boolean;
  for_group: boolean;
  has_automatic_scoring: boolean;
  has_attached_file: boolean;
  end_user_count?: number;
  creator_role?: string | null;
  learning_template_package: {
    is_set: boolean;
    title: string | null;
  };
  ideal_thumbnail_file: string | null;
  common_tags: CommonTagBase[];
};
export type HomeworkTemplateBaseForSuperOwner = HomeworkTemplateBase & {
  num_schools_distributed: number;
  num_distributed_to_students: number;
  num_submitted_to_reviewer: number;
  num_checked_reviewer: number;
  num_returned_to_students: number;
  reviewer_company_group: {
    id?: string | null;
    name?: string | null;
  };
};
export type HomeworkTemplateSearchType = "title";
export type HomeworkTemplateCustomFieldsTypes =
  | "HomeworkTemplateCustomTextField"
  | "HomeworkTemplateCustomRadioField"
  | "HomeworkTemplateCustomSelectField"
  | "HomeworkTemplateCustomProfileField";
export type CustomFieldScoreBase = {
  possible_scores: number[] | null;
  possible_scores2: number[] | null;
  answer_flags?: boolean[] | null;
  is_exact_match: boolean | null;
};
export type HasNumberIdAndName = {
  id: number;
  name: string;
};
export type CustomFieldCustomMasterMap = ModelBase & {
  name: string;
  public_columns: HasNumberIdAndName[];
  filter_columns: HasNumberIdAndName[];
  filter_cmmrs: HasIdAndName[];
};
export type TtCategoryBaseType = "TtCategory::HomeworkTemplateCustomField";
export type TtCategoryBase = ModelBase & {
  name: string;
  type: TtCategoryBaseType;
};
export type HomeworkTemplateCustomField = ModelBase & {
  homework_templates_id: string;
  type: HomeworkTemplateCustomFieldsTypes;
  sequence: number;
  title: string;
  possible_values: string[];
  min_length: number | null;
  max_length: number | null;
  is_required: boolean;
  created_at: string;
  updated_at: string;
  score?: CustomFieldScoreBase;
  custom_master_map?: CustomFieldCustomMasterMap;
  custom_field_category?: TtCategoryBase;
};
export type IdealBoardTemplateStatus = "draft" | "reserved" | "distributed";
export type IdealBoardSize = "S" | "M" | "MV" | "L";
export type HasIdAndTitle = {
  id: string;
  title: string;
};
export type BoardTemplateList = ModelBase & {
  name: string;
  status: IdealBoardTemplateStatus;
  size: IdealBoardSize;
  start_at: string;
  homework_template?: HasIdAndTitle | null;
};
export type HomeworkTemplateAddition = {
  content: string;
  reviewer_company_group: {
    id?: string | null;
    name?: string | null;
    is_public_profile?: boolean | null;
  };
  homework_template_images?: {
    id: string;
    title: string;
    thumb_url: string;
    file_url: string;
    adjusted_file_url: string | null;
  }[];
  homework_template_custom_fields: HomeworkTemplateCustomField[];
  ideal_board_template?: BoardTemplateList | null;
  distributed: boolean;
};
export type HomeworkTemplate = HomeworkTemplateBase & HomeworkTemplateAddition;
export type HomeworkTemplateCreateRequest = {
  title: string;
  content?: string;
  needs_review?: boolean;
  needs_score?: boolean;
  is_exam?: boolean;
  for_group?: boolean;
  end_at?: string | null;
  reviewer_end_at?: string | null;
  reviewer_company_group_id?: string;
  ideal_board_template_id?: string;
  has_automatic_scoring?: boolean;
  has_attached_file?: boolean;
  homework_images?: Blob[];
  ideal_thumbnail_file?: Blob;
};
export type HomeworkTemplateUpdateRequest = {
  title?: string;
  content?: string;
  needs_score?: boolean;
  is_exam?: boolean;
  reviewer_company_group_id?: string;
  end_at?: string | null;
  reviewer_end_at?: string | null;
  has_automatic_scoring?: boolean;
  ideal_thumbnail_file?: Blob;
  has_attached_file?: boolean;
};
export type BatchCreateHomeworkTemplateDistributionRequestBodyAsSuperOwner = {
  start_at?: string;
  end_at?: string;
  reviewer_end_at?: string;
  image_contents?: Blob[];
  create_params_list: {
    company_id: string;
    max_distribution_end_users: number | null;
    memo?: string | null;
  }[];
};
export type BatchDeleteHomeworkTemplateDistributionRequestBodyAsSuperOwner = {
  deleted_ids: string[];
  errors: string[];
};
export type PutHomeworkTemplateDistributionRequestBodyAsSuperOwner = {
  max_distribution_end_users?: string;
  memo?: string;
  start_at?: string;
  end_at?: string;
  reviewer_end_at?: string;
};
export type FileRequiredRequestBody = {
  file: Blob;
};
export type MaterialWorkBaseRequestBody = {
  material_id?: string;
  forbid_download?: boolean;
  category_id?: string;
  user_tag_ids?: string[];
  publication_time?: string;
  expiration_time?: string;
  grade?: StudentGrade;
};
export type BatchCreateMaterialWorkRequestBodyAsSuperOwner =
  MaterialWorkBaseRequestBody & {
    company_tag_ids?: string[];
    user_tag_ids?: string[];
  };
export type CategoryPart = {
  id: string;
  name: string;
  depth: number;
  type: string;
  text_id?: string;
  grade_id?: string;
  subject_id?: string;
  major_category_id?: string;
  middle_category_id?: string;
};
export type CategoryBase = {
  category: CategoryPart;
};
export type MaterialList = ModelBase &
  CategoryBase & {
    title: string;
    thumbnail_file?: string | null;
    is_public: boolean;
    is_video_updating: boolean;
    tags: HasIdAndName[];
  };
export type ImageInfo = {
  id: string;
  title: string;
  material_id: string;
  file: {
    url: string;
    thumb?: {
      url: string | null;
    };
    icon?: {
      url: string | null;
    };
  };
  edited_image_url?: string | null;
};
export type CookieInfo = {
  cloudfront_policy: string;
  cloudfront_signature: string;
  cloudfront_key_pair_id: string;
  unsigned_url: string;
};
export type VideoInfo = {
  id: string;
  title: string;
  material_id: string;
  file_url: string | null;
  file: {
    url?: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string;
  thumbnail: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
};
export type MaterialDetail = MaterialList & {
  description: string;
  memo?: string | null;
  download_allowed: boolean;
  is_owned: boolean;
  images: ImageInfo[];
  videos: VideoInfo[];
  html_file: {
    title?: string | null;
    url?: string | null;
  };
};
export type CreateMaterialRequestBody = {
  title: string;
  thumbnail_file?: Blob;
  memo?: string;
  category_id?: string;
  tag_list?: string[];
  image_content_titles?: string[];
  image_contents?: Blob[];
  video_content_title?: string;
  video_content_file?: Blob;
  video_content_thumbnail?: Blob;
  video_content_external_link?: string;
  description?: string;
  is_public: boolean;
  is_video_updating?: boolean;
  download_allowed: boolean;
};
export type UpdateMaterialRequestBody = {
  title: string;
  category_id?: string;
  memo?: string;
  description?: string;
  download_allowed: boolean;
  is_public: boolean;
  is_video_updating?: boolean;
  thumbnail_file?: Blob;
  video_id?: string;
  video_content_title?: string;
  video_content_thumbnail?: Blob;
  video_content_file?: Blob;
  video_content_external_link?: string;
  image_content_titles?: string[];
  image_contents?: Blob[];
  tag_list?: string[];
  removed_image_ids?: string[];
  removed_video_ids?: string[];
};
export type MaterialSequencesRequiredRequestBody = {
  sequences?: {
    material_id?: string;
    sequence?: number;
  }[];
};
export type ZipFileRequiredRequestBody = {
  zip_file: Blob;
};
export type CategoryType =
  | "text"
  | "grade"
  | "subject"
  | "major"
  | "middle"
  | "minor";
export type BaseCategoryInfo = {
  id: string;
  name: string;
  depth: number;
  type: CategoryType;
  student_visible?: boolean;
  teacher_visible?: boolean;
  owner_visible?: boolean;
  is_help?: boolean;
  student_help_page?: boolean;
  teacher_help_page?: boolean;
  owner_help_page?: boolean;
  reviewer_help_page?: boolean;
  image?: string | null;
};
export type GradeCategoryInfo = BaseCategoryInfo & {
  text_id: string;
};
export type TextCategoryInfo = BaseCategoryInfo & {
  linking_company_tags?: HasIdAndName[];
  linking_user_tags?: UserTagBasic[];
};
export type SubjectCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  text_id: string;
};
export type MajorCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type MiddleCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  major_category_id: string;
  text_id: string;
};
export type MinorCategoryInfo = BaseCategoryInfo & {
  major_category_id: string;
  middle_category_id: string;
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type CategoryRequestBaseBodyAsSuperOwner = {
  name?: string;
  image?: Blob;
  is_help?: boolean;
  roles?: (string | null)[];
  add_company_tags?: (string | null)[];
  add_user_tags?: (string | null)[];
  student_visible?: boolean;
  teacher_visible?: boolean;
  owner_visible?: boolean;
};
export type PostCategoryRequestBodyAsSuperOwner =
  CategoryRequestBaseBodyAsSuperOwner & {
    parent_id?: string;
  };
export type CategoryInfo =
  | TextCategoryInfo
  | GradeCategoryInfo
  | SubjectCategoryInfo
  | MajorCategoryInfo
  | MiddleCategoryInfo
  | MinorCategoryInfo;
export type PutCategoryRequestBodyAsSuperOwner =
  CategoryRequestBaseBodyAsSuperOwner & {
    remove_company_tags?: (string | null)[];
    remove_user_tags?: (string | null)[];
  };
export type PutCategorySequencesRequestBodyAsSuperOwner = {
  sequences?: {
    category_id?: string;
    sequence?: number;
  }[];
};
export type RevieweeCompaniesRequestBodyAsSuperOwner2 = {
  company_ids: string[];
  reviewer_company_group_id: string;
};
export type ReviewerAssignableCompany = ModelBase & {
  name: string;
  linking_reviewer_companies?: boolean;
  company_tags?: {
    id?: string;
    name?: string;
    company_tag_type_id?: string;
    company_tag_type_name?: string;
  }[];
};
export type HistoryCompany = {
  id: string;
  name: string;
  number_of_students_distributed: number;
};
export type HistoryMaterial = CategoryBase & {
  id: string;
  title: string;
  number_of_people_distributed: number;
  number_of_schools_distributed: number;
  distributed_companies: HistoryCompany[];
};
export type DistributionStatusStatus =
  | "idle"
  | "save_running"
  | "save_failed"
  | "distribution_idle"
  | "distribution_running"
  | "distribution_failed"
  | "completed";
export type MaterialDistributionHistory = {
  id: string;
  user_tags: (UserTagBasic & {
    user_tag_type_id?: string;
    user_tag_type_name?: string;
  })[];
  company_tags: CompanyTag[];
  selected_companies: HasIdAndName[];
  selected_category?: CategoryBase | null;
  materials: HistoryMaterial[];
  distribution_time?: string;
  expiration_time?: string | null;
  created_at?: string;
  status: DistributionStatusStatus | null;
};
export type MaterialDistributionHistoryListStudents = {
  total_count: number;
  number_of_students_per_grade: {
    grade: string | null;
    count: number;
  }[];
  students: (BasicUserInfo & {
    grade: string | null;
  })[];
};
export type MaterialDistributionHistorySearchByUserId = {
  id: string;
  user_hashid: string;
  last_name: string;
  first_name: string;
  company_name: string;
  distribution_time?: string;
  expiration_time?: string | null;
  reservation_time?: string | null;
  created_at?: string;
  material?: CategoryBase & {
    id?: string;
    title?: string;
    memo?: string;
    description?: string;
    image_contents?: ImageInfo[];
    video_contents?: VideoInfo[];
  };
};
export type SuperOwnerHomeworkTemplateDistributionHistory = ModelBase & {
  homework_template_id: string;
  homework_template_distributions_id: string;
  template_name: string;
  distribution_time: string;
  start_at?: string | null;
  end_at?: string | null;
  count_of_users: number;
  count_of_companies: number;
  memo?: string | null;
  status: string;
  status_code: string;
  company_names: {
    company_id?: string;
    company_name?: string;
  }[];
};
export type SuperOwnerHomeworkTemplateDistributionHistoryAffiliates = {
  homework_template_distribution_history: SuperOwnerHomeworkTemplateDistributionHistory;
  affiliates: AffiliateBase[];
  total_count: number;
};
export type SuperOwnerHomeworkTemplateDistributionHistoryCompanies = {
  homework_template_distribution_history: SuperOwnerHomeworkTemplateDistributionHistory;
  companies: Company[];
  total_count: number;
};
export type HomeworkTemplateCustomFieldType =
  | "text"
  | "radio"
  | "select"
  | "profile";
export type CustomFieldCommonRequestBody = {
  type: HomeworkTemplateCustomFieldType;
  title: string;
  sequence: number;
  min_length?: number;
  max_length?: number;
  is_required: boolean;
  possible_values?: string[];
  is_exact_match?: boolean;
  possible_scores?: number[];
  possible_scores2?: number[];
  answer_flags?: number[];
  custom_field_category_id?: string;
};
export type CustomFieldRequestBody = CustomFieldCommonRequestBody & {
  common_custom_master_map_id?: string;
  public_column_number?: number[];
  filter_column_numbers?: number[];
  filter_cmm_relationship_ids?: string[];
};
export type InquiryAffiliateBase = {
  affiliate_id?: string | null;
  has_deleted: boolean;
  company_id?: string | null;
  company_name?: string | null;
  user_id?: string | null;
  user_name?: string | null;
  email?: string | null;
  role?: RoleBase | null;
};
export type InquiryCommentCount = {
  count: number;
  is_new: boolean;
};
export type InquiryDetailListBase = ModelBase & {
  user_id: string;
  user?: InquiryAffiliateBase;
  person_in_charge?: InquiryAffiliateBase;
  status: string;
  kind: string;
  title: string;
  created_at: string;
  updated_at: string;
  is_new: boolean;
  comments: InquiryCommentCount;
};
export type InquiryStatus =
  | "unsolved"
  | "answered"
  | "replied"
  | "in_progress"
  | "completed";
export type InquiryKind = "system" | "user_support" | "content" | "other";
export type InquiryComment = ModelBase & {
  user: {
    affiliate_id?: string;
    company_id?: string;
    company_name?: string;
    user_id?: string;
    user_name?: string;
  };
  status: string;
  body: string;
  created_at: string;
  updated_at: string;
};
export type AffiliateCompanyAndUserBase = {
  affiliate_id: string;
  user_id: string;
  company_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: RoleBase;
  nickname: string | null;
};
export type InquirySimpleBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  body: string;
  category?: string | null;
  os_name: string;
  os_version: string;
  browser_name: string;
  browser_version: string;
  created_at: string;
  updated_at: string;
  comments: InquiryComment[];
  target_affiliates: AffiliateCompanyAndUserBase[];
};
export type InquiryDetailForSuperOwner = InquirySimpleBase & {
  person_in_charge: InquiryAffiliateBase;
  status: string;
  is_new: boolean;
};
export type InquiryIsNewRequiredRequestBody = {
  is_new: boolean;
};
export type BodyRequiredRequestBody = {
  body: string;
};
export type InquiryCommentRequestBody = {
  body: string;
  status?: string;
};
export type AffiliateName = {
  affiliate_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  nickname: string | null;
};
export type LearningTemplatePackageBase = ModelBase & {
  title: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  is_distributed: boolean;
  affiliate: AffiliateName;
  packagings_count: number;
};
export type TitleAndDescriptionRequiredRequestBody = {
  title: string;
  description: string;
};
export type LearningTemplatePackageSequencesRequestBody = {
  sequences: {
    package_id: string;
    sequence: number;
  }[];
};
export type LearningTemplatePackageDistributionSettingBase = ModelBase & {
  learning_template_package_id: string;
  start_at: string;
  end_at?: string;
  kind: string;
  grade?: StudentGrade;
  forbid_download: boolean;
  created_at: string;
  updated_at: string;
  distributor_affiliate?: AffiliateBase | null;
  companies?: CompanyBase[];
  user_tags?: UserTagBasic[];
};
export type BatchCreateLearningTemplatePackageDistributionRequiredRequestBody =
  {
    start_at?: string;
    end_at?: string;
    grade?: StudentGrade;
    forbid_download: boolean;
    company_ids?: string[];
    user_tag_ids?: string[];
    student_affiliate_ids: string[];
    homework_template_settings?: {
      homework_template_id?: string;
      company_id?: string;
      start_at?: string;
      end_at?: string;
      reviewer_end_at?: string;
      memo?: string;
    }[];
  };
export type AffiliateStudentName = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  nickname?: string | null;
  grades: {
    grade?: string | null;
  }[];
  company: HasIdAndName;
};
export type StartAtAndEndAtRequestBody = {
  start_at?: string;
  end_at?: string;
};
export type DistributedStudents = {
  id: string;
  first_name: string;
  last_name: string;
  company_id: string;
  company_name: string;
  grade: string | null;
};
export type PackagingBase = ModelBase & {
  packageable_type: string;
  packageable_id: string;
  packageable_title: string;
  sl_lecture_id?: number | null;
};
export type MaterialHomeworkTemplatePackageBase = ModelBase & {
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateName;
  packagings: PackagingBase[];
};
export type SequenceRequiredRequestBody = {
  sequence: number;
};
export type PackagingPackageableType =
  | "HomeworkTemplate"
  | "Material"
  | "Package"
  | "Pbl::Steam::Lecture"
  | "Pbl::Sponsor::Lecture"
  | "Homework"
  | "MaterialWork"
  | "Affiliate";
export type PackagingRequestBody = {
  package_id: string;
  packageable_type: PackagingPackageableType;
  packageable_id: string;
};
export type PackagingAsLearningTemplatePackageBase = ModelBase & {
  packageable_type: string;
  packageable_id: string;
  packageable_title: string;
  material_homework_template_package?: MaterialHomeworkTemplatePackageBase | null;
  sl_lecture_id?: number | null;
};
export type PblBoardInfoStatusJa =
  | "\u516C\u958B\u505C\u6B62\u4E2D"
  | "\u516C\u958B\u4E2D";
export type PblBoardItemContentTypeJa =
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8"
  | "\u63A2\u7A76\u6210\u679C\u4E00\u89A7"
  | "\u30D5\u30C3\u30BF\u30FC"
  | "\u30EF\u30FC\u30C9\u30AF\u30E9\u30A6\u30C9";
export type PblBoardContentDesignTypeJa =
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C81"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C82"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C83"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C84";
export type PblBoardContentBase = ModelBase & {
  design_type: PblBoardContentDesignTypeJa;
  catchphrase?: string;
  catchphrase_description?: string;
  catchphrase_image?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
  cta_name?: string;
  cta_url?: string;
  external_link?: string;
};
export type PblBoardFooterItemBase = ModelBase & {
  title?: string;
  url?: string;
};
export type PblBoardFooterBase = ModelBase & {
  category_name?: string;
  sequence?: number;
  board_footers?: PblBoardFooterItemBase[];
};
export type PblBoardItemBase = ModelBase & {
  content_type: PblBoardItemContentTypeJa;
  sequence: number;
  board_contents?: PblBoardContentBase[];
  board_footers?: PblBoardFooterBase[];
};
export type ArticleKind = "default" | "poster";
export type ArticleImageBase = ModelBase & {
  file?: {
    title?: string;
    thumb_url?: string;
    file_url?: string;
  };
};
export type PblBoardArticleBase = ModelBase & {
  title: string;
  kind?: ArticleKind;
  body: string;
  cover: string | null;
  company: HasIdAndName;
  images: ArticleImageBase[];
};
export type PblBoardInfoBase = ModelBase & {
  status: PblBoardInfoStatusJa;
  created_at: string;
  updated_at: string;
  board_items: PblBoardItemBase[];
  articles: PblBoardArticleBase[];
  article_total_count: number;
  company?: {
    id: string;
    name: string;
    is_content_company: boolean;
  };
};
export type PblBoardInfoEvent = "public_info" | "private_info";
export type PblBoardInfoEventRequiredRequestBody = {
  event: PblBoardInfoEvent;
};
export type PblBoardItemBaseAsShow = ModelBase & {
  content_type: PblBoardItemContentTypeJa;
  sequence: number;
};
export type PblBoardItemContentType =
  | "template"
  | "pbl_list"
  | "footer"
  | "word_cloud";
export type PblBoardItemRequestBody = {
  content_type: PblBoardItemContentType;
  sequence?: number;
};
export type PblBoardCampaignStatusJa =
  | "\u52DF\u96C6\u524D"
  | "\u52DF\u96C6\u4E2D"
  | "\u7D42\u4E86";
export type PblBoardCampaignList = ModelBase & {
  title: string;
  start_date: string;
  end_date: string;
  status: PblBoardCampaignStatusJa;
  unconfirmed_count?: number;
  approved_count?: number;
  non_approved_count?: number;
};
export type PblBoardCampaignBase = ModelBase & {
  title: string;
  description?: string;
  start_date: string;
  end_date: string;
  status: PblBoardCampaignStatusJa;
};
export type PostPblBoardCampaignRequestBody = {
  title: string;
  description?: string;
  start_date: string;
  end_date: string;
};
export type PblBoardCampaignEvent = "submit" | "cancel" | "close";
export type PutPblBoardCampaignRequestBody = PostPblBoardCampaignRequestBody & {
  event: PblBoardCampaignEvent;
};
export type PblBoardCampaignRequestStatusJa =
  | "\u672A\u78BA\u8A8D"
  | "\u627F\u8A8D"
  | "\u975E\u627F\u8A8D";
export type StandardAffiliate = {
  id: string;
  first_name: string;
  last_name: string;
  company_id: string;
  company_name: string;
};
export type PblBoardCampaignRequestList = ModelBase & {
  sequence: number;
  status: PblBoardCampaignRequestStatusJa;
  article: {
    id: string;
    title: string;
  };
  teacher_affiliate: StandardAffiliate;
};
export type PblBoardCampaignRequestStatus =
  | "unconfirmed"
  | "approved"
  | "non_approved";
export type PblBoardCampaignRequestEvent = "accept" | "reject" | "cancel";
export type PblBoardCampaignRequestEventRequiredRequestBody = {
  event: PblBoardCampaignRequestEvent;
};
export type ArticleStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u518D\u63D0\u51FA/\u63D0\u51FA\u5F85\u3061"
  | "\u5B8C\u4E86"
  | "\u5B8C\u4E86"
  | "\u63D0\u51FA\u6E08\u307F/\u78BA\u8A8D\u4E2D";
export type ArticleStatus =
  | "draft"
  | "correction"
  | "reviewing"
  | "published"
  | "teacher_checking";
export type ArticleAffiliateLinking = {
  affiliate_id: string;
  user?: {
    user_id?: string;
    first_name?: string;
    last_name?: string;
    student_number?: number | null;
    nickname?: string | null;
  };
};
export type ArticleBaseCommon = ModelBase & {
  student_id: string;
  creator_student_affiliate_id?: string | null;
  student_number: number | null;
  student_name: string | null;
  student_nickname?: string | null;
  title: string;
  kind?: ArticleKind;
  status: ArticleStatusJa;
  status_code: ArticleStatus;
  created_at: string;
  created_at_with_second: string;
  updated_at: string;
  updated_at_with_second: string;
  liked?: boolean;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  company: {
    id: string;
    name: string;
  };
  students_linking: ArticleAffiliateLinking[];
  common_tags: CommonTagBase[];
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type AllowedArticleCategorizableType =
  | "Sponsor::Info"
  | "Pbl::Sponsor::Project";
export type ArticleCategorizable = ModelBase & {
  type: AllowedArticleCategorizableType;
  name: string;
  sponsor_logo: string | null;
  company_name?: string | null;
};
export type ArticleContentType =
  | "theme"
  | "background"
  | "hypothesis"
  | "survey_method"
  | "result"
  | "summary"
  | "future_issues";
export type ArticleContent = ModelBase & {
  content_type: ArticleContentType;
  body: string | null;
};
export type ArticleSponsorContent = ModelBase & {
  pbl_sponsor_article_content_template_field_id: string;
  body: string | null;
};
export type SponsorArticleContentTemplateFieldBase = ModelBase & {
  title: string;
};
export type SponsorArticleContentTemplateBase = ModelBase & {
  title: string;
  description?: string | null;
  pbl_sponsor_lecture_id?: string;
  fields?: SponsorArticleContentTemplateFieldBase[];
};
export type ArticlePoster = {
  sponsor_logo: string | null;
  categorizable?: ArticleCategorizable;
  article_contents: ArticleContent[];
  article_sponsor_contents?: ArticleSponsorContent[];
  pbl_sponsor_article_content_template?: SponsorArticleContentTemplateBase | null;
};
export type GroupAffiliatePackageBase = ModelBase & {
  title: string;
  description?: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateBase;
  affiliates: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    grade?: string;
    nickname?: string;
  }[];
  assign_teacher_affiliates?: AffiliateName[];
};
export type InterviewRequestStatusJa =
  | "\u627F\u8A8D\u5F85\u3061"
  | "\u975E\u627F\u8A8D"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86"
  | "\u7121\u52B9";
export type InterviewRequestStatus =
  | "idle"
  | "non_approved"
  | "approved"
  | "completed"
  | "invalided";
export type ProfessorInfoBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    request_count?: number;
    position?: string | null;
    department?: string | null;
    description?: string | null;
    limit_request?: number | null;
    profile_picture?: string | null;
  };
};
export type InterviewRequestArticleBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  professor_affiliate: ProfessorInfoBase;
};
export type TeacherSponsorProjectListBase = ModelBase & {
  sponsor_info_id: string;
  name: string;
  company_name: string;
};
export type ArticleDetailAsPblBoard = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type PblBoardContentDesignType =
  | "template_1"
  | "template_2"
  | "template_3"
  | "template_4";
export type PblBoardContentRequestBody = {
  design_type?: PblBoardContentDesignType;
  catchphrase?: string;
  catchphrase_description?: string;
  catchphrase_image?: Blob;
  cta_name?: string;
  cta_url?: string;
  external_link?: string;
};
export type PblBoardFooterItemRequiredRequestBody = {
  category_name: string;
};
export type PostPblBoardFooterRequestBody =
  PblBoardFooterItemRequiredRequestBody & {
    footer_items?: {
      title: string;
      url: string;
    }[];
  };
export type PblBoardFooterItemRequiredRequestBody2 = {
  title: string;
  url: string;
};
export type AffiliateStudentSuspensionRequiredRequestBody = {
  student_suspension: boolean;
};
export type BulkUpdateRequestBody = {
  ids: string[];
  student_suspension: boolean;
};
export type IdsRequiredRequestBody = {
  ids: string[];
};
export type AttachedImageCategorizableType =
  | "Material"
  | "Ideal::Board"
  | "Article"
  | "Pbl::Board::Content"
  | "Pbl::Sponsor::Lecture"
  | "Ideal::BoardTemplate"
  | "Interview::Message"
  | "HomeworkTemplateCustomFieldFeedback"
  | "Gogo::Message";
export type AttachedImageBase = ModelBase & {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
  file_name: string;
  file_url: string;
};
export type LimitedTimeNotificationStatus =
  | "\u516C\u958B\u524D"
  | "\u516C\u958B\u4E2D"
  | "\u63B2\u8F09\u7D42\u4E86";
export type LimitedTimeNotificationList = ModelBase & {
  title: string;
  message: string;
  status: LimitedTimeNotificationStatus;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  roles: RoleBase[];
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
};
export type SelectRoleByLimitedTimeNotification =
  | "owner"
  | "teacher"
  | "student";
export type PostLimitedTimeNotificationRequestBody = {
  title: string;
  message: string;
  start_time: string;
  end_time: string;
  add_roles: SelectRoleByLimitedTimeNotification[];
  add_company_ids?: string[];
  add_user_tag_ids?: string[];
};
export type LimitedTimeNotificationDetail = ModelBase & {
  title: string;
  message: string;
  roles: RoleBase[];
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
};
export type PutLimitedTimeNotificationRequestBody =
  PostLimitedTimeNotificationRequestBody & {
    remove_role_ids?: string[];
    remove_company_ids?: string[];
    remove_user_tag_ids?: string[];
  };
export type SteamLibraryContentDifficulty = "easy" | "normal" | "difficult";
export type SteamLibraryContentAspect = "familiar" | "japan" | "world";
export type PblSteamContentBase = ModelBase & {
  sl_content_id: number;
  title: string;
  difficulty: SteamLibraryContentDifficulty;
  aspect: SteamLibraryContentAspect;
  is_scientific: boolean;
  has_carousel: boolean;
  created_at: string;
  updated_at: string;
};
export type PblSteamContent = PblSteamContentBase & {
  bookmark: {
    id: string | null;
  };
  recommendation: {
    id: string | null;
  };
};
export type PblSteamLecture = ModelBase & {
  pbl_steam_content_id: string;
  sl_lecture_id: number;
  title?: string;
  sequence: number;
  created_at: string;
  updated_at: string;
};
export type CreateSteamLectureRequestBody = {
  title: string;
  sl_lecture_id: number;
  sequence: number;
};
export type SponsorProjectTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type VideoFileBase = {
  url: string | null;
};
export type SponsorLectureVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorProjectImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorProjectWorkProgressJa =
  | "\u53D7\u8B1B\u4E2D"
  | "\u53D7\u8B1B\u6E08\u307F";
export type SponsorProjectAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description?: string;
  display_allowed: boolean;
  display_in_carousel: boolean;
  created_at: string;
  updated_at: string;
  tags: SponsorProjectTagBase[] | null;
  file?: {
    url?: string | null;
  };
  video_content?: SponsorLectureVideoContentBase | null;
  image?: SponsorProjectImageBase | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
  project_bookmark?: ModelBase | null;
  project_recommend?: ModelBase | null;
};
export type SponsorLectureImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorLectureTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorLectureFileBase = ModelBase & {
  company_id: string;
  file: {
    title: string | null;
    url: string | null;
  };
};
export type Status = "\u65B0\u898F" | "\u78BA\u8A8D\u6E08\u307F";
export type SponsorLectureDetailsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content: SponsorLectureVideoContentBase | null;
  tags: SponsorLectureTagBase[] | null;
  files: SponsorLectureFileBase[] | null;
  learning_progress?: {
    id?: string;
    status?: Status;
  };
  article_content_template?: {
    id: string;
    title: string;
    description?: string | null;
  } | null;
  company_name?: string;
  sponsor_logo_url?: string | null;
  project_name?: string;
};
export type TtCsvExportStatus = "running" | "completed" | "failed";
export type TtCsvExportTypeJa =
  | "\u4F01\u696D\u30D7\u30ED\u30B8\u30A7\u30AF\u30C8\u60C5\u5831"
  | "\u5B66\u6821\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u5B66\u6821\u30BF\u30B0\u306E\u5B66\u6821\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u6559\u6750\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u4E00\u89A7\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u30EC\u30B3\u30FC\u30C9\u60C5\u5831"
  | "\u9650\u5B9A\u30BF\u30B0\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u5927\u5B66\u6559\u54E1\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u6388\u696D\u8A08\u753B"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831";
export type TtCsvExportBase = ModelBase & {
  status: TtCsvExportStatus;
  type_ja: TtCsvExportTypeJa;
  created_at: string;
  completed_at: string | null;
  file: {
    name?: string | null;
    url?: string | null;
  };
};
export type TtCsvExportType =
  | "admin_sponsor_info_list"
  | "super_owner_company_list"
  | "super_owner_owner_list"
  | "super_owner_teacher_list"
  | "super_owner_student_list"
  | "super_owner_tag_list"
  | "super_owner_company_tag_list"
  | "super_owner_nationwide_user_tag_and_student_list"
  | "super_owner_limited_user_tag_and_student_list"
  | "super_owner_material_distribution_history_list"
  | "super_owner_learning_package_distribution_history_list"
  | "super_owner_learning_package_distribution_history_detail"
  | "super_owner_homework_template_distribution_history_list"
  | "super_owner_homework_template_distribution_history_detail"
  | "super_owner_custom_list"
  | "super_owner_user_tag_distribution_history_list"
  | "owner_owner_list"
  | "owner_teacher_list"
  | "owner_student_list"
  | "owner_user_tag_list"
  | "owner_nationwide_user_tag_and_student_list"
  | "owner_limited_user_tag_and_student_list"
  | "owner_university_teacher_list"
  | "owner_homework_comment_list"
  | "owner_homework_template_custom_field_list"
  | "teacher_homework_template_custom_field_list"
  | "teacher_student_list"
  | "teacher_pbl_plan_schedule_list"
  | "teacher_homework_comment_list"
  | "reviewer_homework_template_custom_field_list"
  | "reviewer_homework_comment_list";
export type TtCsvExportSearchTypeSortedBy = "numberAsc" | "numberDesc";
export type TtCsvExportSearchType =
  | "user_name"
  | "email"
  | "grade"
  | "parent_name"
  | "user_tag";
export type TtCsvExportRequestBody = {
  type: TtCsvExportType;
  target_company_id?: string;
  homework_template_id?: string;
  learning_template_package_distribution_setting_id?: string;
  common_custom_master_map_id?: string;
};
export type CreateTtCategoryRequestBody = {
  name: string;
  type: TtCategoryBaseType;
};
export type UpdateTtCategoryRequestBody = {
  name: string;
};
export type FeedbackTemplateCommentBase = ModelBase & {
  body: string;
};
export type FeedbackTemplateKind =
  | "anyone"
  | "draft"
  | "teacher_only"
  | "reviewer_only";
export type FeedbackTemplateHomeworkBase = ModelBase & {
  icon: {
    title: string;
    url: string;
  };
  feedback_template_comments: FeedbackTemplateCommentBase[];
  kind: FeedbackTemplateKind;
  tags?: HasIdAndName[] | null;
};
export type FeedbackTemplateSearchType = "tag";
export type PutFeedbackTemplateHomeworkRequestBody = {
  icon?: Blob;
  kind?: FeedbackTemplateKind;
  tag_list?: string[];
};
export type PostFeedbackTemplateHomeworkRequestBody =
  PutFeedbackTemplateHomeworkRequestBody & {
    comments?: {
      body: string;
    }[];
  };
export type HasName = {
  name: string;
};
export type HelpMasterKind = "article_poster";
export type HelpMasterBase = ModelBase & {
  kind: HelpMasterKind;
};
export type HelpContentBase = ModelBase & {
  help_no: number;
  material?: HasIdAndTitle;
};
export type HelpRequestBodyAsSuperOwner = {
  material_id: string;
  kind: HelpMasterKind;
};
export type BoardTemplate = BoardTemplateList & {
  data: string | null;
  affiliate?: AffiliateName;
};
export type PostIdealBoardTemplateRequestBody = {
  name: string;
  size: IdealBoardSize;
  start_at?: string;
};
export type PutIdealBoardTemplateRequestBody = {
  name?: string;
  data?: string;
  start_at?: string;
};
export type IdealBoardStatusJa =
  | "\u65B0\u898F"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86";
export type IdealBoardStatus = "todo" | "in_progress" | "completed";
export type HomeworkBoardBase = ModelBase & {
  title?: string;
  template_id?: string | null;
};
export type BoardBase = ModelBase & {
  name: string;
  status: IdealBoardStatusJa;
  status_code: IdealBoardStatus;
  size: IdealBoardSize;
  created_at: string;
  template_id: string | null;
  homework?: HomeworkBoardBase | null;
  host_affiliate_id: string | null;
};
export type AffiliateIdName = {
  id: string;
  first_name: string;
  last_name: string;
};
export type AffiliateAvatar = AffiliateIdName & {
  avatar?: Avatar;
};
export type BoardList = BoardBase & {
  invited_affiliates: AffiliateAvatar[];
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
};
export type GroupAffiliatePackageBaseAvatar =
  GroupAffiliatePackageMinimalBase & {
    affiliates: AffiliateAvatar[];
  };
export type Board = BoardBase & {
  data: string | null;
  host_affiliate?: AffiliateName;
  host_role?: RoleBase;
  invited_affiliates: AffiliateAvatar[];
  allowed_group_affiliates: GroupAffiliatePackageBaseAvatar[];
};
export type PostIdealBoardRequestBody =
  | {
      name: string;
      size: IdealBoardSize;
    }
  | {
      ideal_board_template_id: string;
    };
export type IdealBoardEvent = "start" | "complete" | "restart";
export type PutIdealBoardRequestBody = {
  name?: string;
  data?: string;
  event?: IdealBoardEvent;
};
export type IdealBoardAffiliateRequestBody = {
  ideal_board_id: string;
  affiliate_id: string;
};
export type Schema = "name";
export type CommonTaggingCategorizableType =
  | "Article"
  | "Pbl::Cards::InformationLiteracy::Bookmark"
  | "Homework"
  | "HomeworkTemplate";
export type PostCommonTagKind = "free" | "management";
export type CommonTaggingRequestBody = {
  tag_name: string;
  kind: PostCommonTagKind;
};
export const {
  useGetSuperOwnerGradeMastersQuery,
  usePostSuperOwnerGradeMastersMutation,
  usePutSuperOwnerGradeMastersByIdMutation,
  useGetSuperOwnerEmailsBySuperOwnerEmailIdQuery,
  useGetSuperOwnerEmailsQuery,
  usePostSuperOwnerEmailsMutation,
  usePostSuperOwnerMaterialsTicketsMutation,
  usePutSuperOwnerMaterialsTicketsAcceptMutation,
  useUpdateCategoryRenameMapAsSuperOwnerMutation,
  useGetSuperOwnerCustomMasterMapsQuery,
  usePostSuperOwnerCustomMasterMapsMutation,
  usePutSuperOwnerCustomMasterMapsSequenceMutation,
  useGetSuperOwnerCustomMasterMapsByCustomMasterMapIdQuery,
  usePutSuperOwnerCustomMasterMapsByCustomMasterMapIdMutation,
  useDeleteSuperOwnerCustomMasterMapsByCustomMasterMapIdMutation,
  usePostSuperOwnerCustomMastersMutation,
  usePutSuperOwnerCustomMastersByCustomMasterIdMutation,
  useDeleteSuperOwnerCustomMastersByCustomMasterIdMutation,
  useGetSuperOwnerCustomMastersLinkingCompaniesQuery,
  usePutSuperOwnerCustomMastersByCustomMasterIdLinkingCompanyMutation,
  useGetCustomMasterMapRelationshipsAsSuperOwnerQuery,
  usePostCustomMasterMapRelationshipsAsSuperOwnerMutation,
  useGetCustomMasterMapRelationshipAsSuperOwnerQuery,
  usePutCustomMasterMapRelationshipsAsSuperOwnerMutation,
  useDeleteControllerAsSuperOwnerMutation,
  useGetCustomMasterMapRelationshipChildCustomMastersAsSuperOwnerQuery,
  useBatchCreateCustomMasterRelationshipAsSuperOwnerMutation,
  useGetSuperOwnerNotificationsQuery,
  usePostSuperOwnerNotificationsMutation,
  useGetSuperOwnerNotificationsByIdQuery,
  usePutSuperOwnerNotificationsByIdMutation,
  useDeleteSuperOwnerNotificationsByIdMutation,
  useGetSuperOwnerCreatedUserTagTypesQuery,
  useGetSearchUserControllersAsSuperOwnerQuery,
  useGetShowAffiliateUserControllersAsSuperOwnerQuery,
  useBulkDeleteControllerAsSuperOwnerMutation,
  usePostSuperOwnerUserTagTypesMutation,
  usePostSuperOwnerUserTaggingsMutation,
  useDeleteSuperOwnerUserTaggingsMutation,
  useSuperOwnerUserTaggingBatchCreateMutation,
  usePutSuperOwnerUserTagTypesByIdMutation,
  useDeleteSuperOwnerUserTagTypesByIdMutation,
  useGetReviewerCompanyGroupControllersAsSuperOwnerQuery,
  useGetReviewerCompanyGroupAsSuperOwnerQuery,
  usePutReviewerCompanyGroupAsSuperOwnerMutation,
  useFetchCompaniesAsSuperOwnerQuery,
  useCreateCompanyAsSuperOwnerMutation,
  useFetchCompanyAsSuperOwnerQuery,
  useUpdateCompanyAsSuperOwnerMutation,
  useDeleteCompanyAsSuperOwnerMutation,
  useGetCompanyLimitOptionAsSuperOwnerQuery,
  useGetSuperOwnerCompaniesByIdMaterialCapacityQuery,
  useGetSuperOwnerCompaniesByCompanyIdSuperOwnersQuery,
  useGetSuperOwnerCompaniesByCompanyIdOwnersQuery,
  usePostSuperOwnerCompaniesByCompanyIdOwnersMutation,
  useDeleteSuperOwnerCompaniesByCompanyIdOwnersAndIdMutation,
  usePutSuperOwnerCompaniesByCompanyIdOwnersAndIdMutation,
  usePostSuperOwnerCompaniesByCompanyIdOwnersAndIdAuthorizationsMutation,
  useGetSuperOwnerCompaniesByCompanyIdTeachersQuery,
  useGetTeachersUserTagsAsSuperOwnerQuery,
  useGetSuperOwnerCompaniesByCompanyIdStudentsQuery,
  useGetSuperOwnerCompaniesByCompanyIdStudentsListGradeQuery,
  useGetStudentsUserTagsAsSuperOwnerQuery,
  useGetStudentsGroupAffiliatesAsSuperOwnerQuery,
  useFetchReviewersAsSuperOwnerQuery,
  useCreateReviewerAsSuperOwnerMutation,
  useDeleteReviewerAsSuperOwnerMutation,
  useUpdateReviewerAsSuperOwnerMutation,
  useCreateUserAsSuperOwnerMutation,
  useCreateAccountUserAsSuperOwnerMutation,
  useCreateAccountAvailableAsSuperOwnerMutation,
  useResetPasswordAsSuperOwnerMutation,
  useGetSuperOwnerCompaniesByCompanyIdUsersAndIdQuery,
  usePutSuperOwnerCompaniesByCompanyIdUsersAndIdMutation,
  useDeleteUserAsSuperOwnerMutation,
  useGetSuperOwnerUserTagsQuery,
  usePostSuperOwnerUserTagsMutation,
  useGetSuperOwnerUserTagsByIdQuery,
  usePutSuperOwnerUserTagsByIdMutation,
  useDeleteSuperOwnerUserTagsByIdMutation,
  usePutSuperOwnerUserTagsSequenceMutation,
  usePostSuperOwnerUserTagsByIdAssignMutation,
  usePatchSuperOwnerUserTagsByIdAssignUpdateMutation,
  useDeleteSuperOwnerUserTagsByIdUnassignMutation,
  useGetSuperOwnerUserTagsByIdDistributedStudentsQuery,
  usePostSuperOwnerCompanyTagTypesMutation,
  usePutSuperOwnerCompanyTagTypesByIdMutation,
  useDeleteSuperOwnerCompanyTagTypesByIdMutation,
  useGetSuperOwnerCreatedCompanyTagTypesQuery,
  usePutSuperOwnerCompanyTagTypesSequenceMutation,
  usePutSuperOwnerUserTagTypesSequenceMutation,
  useGetUserTagDistributionHistoriesAsSuperOwnerQuery,
  useGetSuperOwnerCompaniesByCompanyIdCompanyTagsQuery,
  useGetSuperOwnerCompanyTagsQuery,
  usePostSuperOwnerCompanyTagsMutation,
  usePutSuperOwnerCompanyTagsByIdMutation,
  useDeleteSuperOwnerCompanyTagsByIdMutation,
  usePutSuperOwnerCompanyTagsSequenceMutation,
  usePostSuperOwnerCompanyTaggingsMutation,
  useDeleteSuperOwnerCompanyTaggingsMutation,
  useGetSuperOwnerHomeworkTemplatesQuery,
  usePostSuperOwnerHomeworkTemplatesMutation,
  useGetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdQuery,
  usePutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdMutation,
  useDeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdMutation,
  useGetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsQuery,
  useBatchCreateHomeworkTemplateDistributionsAsSuperOwnerMutation,
  useBatchUpdateHomeworkTemplateDistributionsAsSuperOwnerMutation,
  useBatchDeleteHomeworkTemplateDistributionsAsSuperOwnerMutation,
  useGetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdQuery,
  usePutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdMutation,
  useDeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdDistributionsAndDistributionIdMutation,
  usePostHomeworkTemplateDuplicateAsSuperOwnerMutation,
  usePostHomeworkTemplateImageAsSuperOwnerMutation,
  usePutHomeworkTemplateImageAsSuperOwnerMutation,
  useDeleteHomeworkTemplateImageAsSuperOwnerMutation,
  useGetSuperOwnerCompaniesByCompanyIdUserTagsQuery,
  useGetSuperOwnerUserTagsByUserTagIdUsersQuery,
  useDeleteSuperOwnerReservationsByIdMutation,
  usePostSuperOwnerMaterialWorksBatchCreateUsingTagsMutation,
  useGetSuperOwnerMaterialsQuery,
  usePostSuperOwnerMaterialsMutation,
  useGetSuperOwnerMaterialsAllContainedCategoryQuery,
  useGetSuperOwnerMaterialsByIdQuery,
  usePutSuperOwnerMaterialsByIdMutation,
  useDeleteSuperOwnerMaterialsByIdMutation,
  usePutSuperOwnerMaterialsSequenceMutation,
  usePutMaterialImportHtmlAsSuperOwnerMutation,
  usePostMaterialDuplicateAsSuperOwnerMutation,
  useGetSuperOwnerCategoriesQuery,
  usePostSuperOwnerCategoriesMutation,
  useGetSuperOwnerCategoriesByIdQuery,
  usePutSuperOwnerCategoriesByIdMutation,
  useDeleteSuperOwnerCategoriesByIdMutation,
  usePutSuperOwnerCategoriesSequenceMutation,
  useGetSuperOwnerCompanyTagsByCompanyTagIdCompaniesQuery,
  useFetchRevieweeCompaniesAsSuperOwnerQuery,
  useBatchCreateRevieweeCompaniesAsSuperOwnerMutation,
  useBatchDeleteRevieweeCompaniesAsSuperOwnerMutation,
  useFetchAssignableCompaniesAsSuperOwnerQuery,
  usePutSuperOwnerMaterialDistributionHistoryGroupsByIdMutation,
  useGetSuperOwnerMaterialDistributionHistoriesListQuery,
  useGetSuperOwnerMaterialDistributionHistoriesListStudentsQuery,
  useGetSuperOwnerMaterialDistributionHistoriesSearchByUserIdQuery,
  useGetSuperOwnerHomeworkTemplatesDistributionHistoriesQuery,
  useGetSuperOwnerHomeworkTemplateDistributionHistoryAffiliatesQuery,
  useGetSuperOwnerHomeworkTemplatesDistributionHistoriesByHomeworkTemplateDistributionHistoryIdCompaniesQuery,
  useGetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsQuery,
  usePostSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsMutation,
  useGetSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdQuery,
  usePutSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdMutation,
  useDeleteSuperOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdMutation,
  useGetSuperOwnerInquiriesQuery,
  useGetSuperOwnerInquiriesByIdQuery,
  usePutSuperOwnerInquiriesByIdMutation,
  useGetTheNumberOfNewCommentInquiriesAsSuperOwnerQuery,
  useGetTheNumberOfNewInquiryInquiriesAsSuperOwnerQuery,
  usePostSuperOwnerInquiryCommentsMutation,
  usePutSuperOwnerInquiryCommentsByIdMutation,
  useDeleteSuperOwnerInquiryCommentsByIdMutation,
  useGetLearningTemplatePackagesAsSuperOwnerQuery,
  usePostLearningTemplatePackageAsSuperOwnerMutation,
  useGetLearningTemplatePackageAsSuperOwnerQuery,
  usePutLearningTemplatePackageAsSuperOwnerMutation,
  useDeleteLearningTemplatePackagAsSuperOwnerMutation,
  usePutLearningTemplatePackagesSequenceAsSuperOwnerMutation,
  useGetLearningTemplatePackageDistributionsAsSuperOwnerQuery,
  useDeleteLearningTemplatePackageDistributionAsSuperOwnerMutation,
  usePatchLearningTemplatePackageDistributionsBatchCreateAsSuperOwnerMutation,
  useGetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerQuery,
  useGetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerQuery,
  useGetLearningTemplatePackageDistributionHistoriesAsSuperOwnerQuery,
  usePutLearningTemplatePackageDistributionHistoriesAsSuperOwnerMutation,
  useGetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwnerQuery,
  useGetMaterialHomeworkTemplatePackagesAsSuperOwnerQuery,
  usePostMaterialHomeworkTemplatePackageAsSuperOwnerMutation,
  useGetMaterialHomeworkTemplatePackageAsSuperOwnerQuery,
  usePutMaterialHomeworkTemplatePackageAsSuperOwnerMutation,
  useDeleteMaterialHomeworkTemplatePackageAsSuperOwnerMutation,
  usePutMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwnerMutation,
  useGetSuperOwnerMaterialWorksTargetCompaniesQuery,
  useGetSuperOwnerMaterialWorksTargetMaterialsQuery,
  usePostPackagingAsSuperOwnerMutation,
  usePutPackagingAsSuperOwnerMutation,
  useDeletePackagingAsSuperOwnerMutation,
  useGetPackagingsAsSuperOwnerQuery,
  useGetPblBoardInfoAsSuperOwnerQuery,
  usePostPblBoardInfoAsSuperOwnerMutation,
  usePutPblBoardInfoAsSuperOwnerMutation,
  usePostPblBoardItemAsSuperOwnerMutation,
  usePutPblBoardItemAsSuperOwnerMutation,
  useDeletePblBoardItemAsSuperOwnerMutation,
  useGetPblBoardCampaignsAsSuperOwnerQuery,
  usePostPblBoardCampaignAsSuperOwnerMutation,
  useGetPblBoardCampaignAsSuperOwnerQuery,
  usePutPblBoardCampaignAsSuperOwnerMutation,
  useDeletePblBoardCampaignAsSuperOwnerMutation,
  usePostPblBoardCampaignRequestAsSuperOwnerQuery,
  usePutPblBoardCampaignRequestAsSuperOwnerMutation,
  usePutPblBoardCampaignRequestSequenceAsSuperOwnerMutation,
  useGetPblBoardArticlesAsSuperOwnerQuery,
  useGetPblBoardArticleAsSuperOwnerQuery,
  usePostPblBoardContentAsSuperOwnerMutation,
  useGetPblBoardContentAsSuperOwnerQuery,
  usePutPblBoardContentAsSuperOwnerMutation,
  useDeletePblBoardContentAsSuperOwnerMutation,
  useGetPblBoardFootersAsSuperOwnerQuery,
  usePostPblBoardFooterAsSuperOwnerMutation,
  usePutPblBoardFooterAsSuperOwnerMutation,
  useDeletePblBoardFooterAsSuperOwnerMutation,
  usePostPblBoardFooterItemAsSuperOwnerMutation,
  usePutPblBoardFooterItemAsSuperOwnerMutation,
  useDeletePblBoardFooterItemAsSuperOwnerMutation,
  useAffiliateUpdateAsSuperOwnerMutation,
  useAffiliatesUpdateAsSuperOwnerMutation,
  useAffiliatesDestroyAsSuperOwnerMutation,
  usePostAttachedImageAsSuperOwnerMutation,
  useGetAttachedImageAsSuperOwnerQuery,
  usePutAttachedImageAsSuperOwnerMutation,
  useDeleteAttachedImageAsSuperOwnerMutation,
  useGetLimitedTimeNotificationsAsSuperOwnerQuery,
  usePostLimitedTimeNotificationAsSuperOwnerMutation,
  useGetLimitedTimeNotificationAsSuperOwnerQuery,
  usePutLimitedTimeNotificationAsSuperOwnerMutation,
  useDeleteLimitedTimeNotificationAsSuperOwnerMutation,
  useGetSteamContentsAsSuperOwnerQuery,
  useGetSteamLecturesAsSuperOwnerQuery,
  usePostSuperOwnerSteamLecturesMutation,
  useGetSuperOwnerSteamLecturesIdQuery,
  useFetchSponsorProjectsAsSuperOwnerQuery,
  useFetchSponsorLecturesAsSuperOwnerQuery,
  useFetchSponsorLectureAsSuperOwnerQuery,
  useGetTtCsvExportsAsSuperOwnerQuery,
  usePostTtCsvExportAsSuperOwnerMutation,
  usePostTtCategoryBaseAsSuperOwnerMutation,
  usePutTtCategoryBaseAsSuperOwnerMutation,
  useGetFeedbackTemplateHomeworksAsSuperOwnerQuery,
  usePostFeedbackTemplateHomeworkAsSuperOwnerMutation,
  useGetFeedbackTemplateHomeworkAsSuperOwnerQuery,
  usePutFeedbackTemplateHomeworkAsSuperOwnerMutation,
  useDeleteFeedbackTemplateHomeworkAsSuperOwnerMutation,
  usePostFeedbackTemplateCommentAsSuperOwnerMutation,
  usePutFeedbackTemplateCommentAsSuperOwnerMutation,
  useDeleteFeedbackTemplateCommentAsSuperOwnerMutation,
  useGetFeedbackTemplateTagsAsSuperOwnerQuery,
  useFetchHelpContentsAsSuperOwnerQuery,
  usePutHelpContentsAsSuperOwnerMutation,
  useGetIdealBoardTemplatesAsSuperOwnerQuery,
  usePostIdealBoardTemplatesAsSuperOwnerMutation,
  useGetIdealBoardTemplateAsSuperOwnerQuery,
  usePutIdealBoardTemplateAsSuperOwnerMutation,
  useDeleteIdealBoardTemplateAsSuperOwnerMutation,
  useGetIdealBoardsAsSuperOwnerQuery,
  usePostIdealBoardsAsSuperOwnerMutation,
  useGetIdealBoardAsSuperOwnerQuery,
  usePutIdealBoardAsSuperOwnerMutation,
  useDeleteIdealBoardAsSuperOwnerMutation,
  usePostIdealBoardsAffiliatesAsSuperOwnerMutation,
  useDeleteIdealBoardsAffiliatesAsSuperOwnerMutation,
  useGetCommonTagsAsSuperOwnerQuery,
  usePostCommonTaggingAsSuperOwnerMutation,
  useDeleteCommonTaggingAsSuperOwnerMutation,
} = injectedRtkApi;
