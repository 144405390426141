import { http } from "@lib/steamLibraryhttp";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export const getSteamLibraryContentDetail = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("steamLibraryContent/get", async (id, { rejectWithValue }) => {
  try {
    const response = await http.get(`/contents/detail/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getSteamLibraryLectureDetail = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("steamLibraryLecture/get", async (id, { rejectWithValue }) => {
  try {
    const response = await http.get(`/content_items/detail/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getSteamLibraryContentDetailMoreLectures = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "steamLibraryContent/getMoreLectures",
  async (contentId, { rejectWithValue }) => {
    try {
      const response = await http.get("/content_items", {
        params: {
          content_id: contentId,
          // This request is used to fetch all lectures on content detail page
          // If max number of lectures in a content is more than 20, increase this number to match
          per_page: 20,
          page: 1,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSteamLibraryContentImages = createAsyncThunk<
  any,
  number[],
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("steamLibraryContent/getImages", async (contentIds, { rejectWithValue }) => {
  try {
    const response = await http.get("/contents/get_datas", {
      params: {
        content_ids: contentIds,
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getSteamLibraryLecturesNumber = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "steamLibraryContent/getLecturesNumber",
  async (contentId, { rejectWithValue }) => {
    try {
      const response = await http.get("/contents/number_of_lectures", {
        params: {
          content_ids: contentId,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
