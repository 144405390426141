/* eslint-disable camelcase */
import { fetchTeacherSchedules } from "@actions/teacherApp/schedules";
import { ScheduleBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface TeacherAppSchedulesState {
  fetching: boolean;
  fetched: boolean;
  fetchError: any;
  schedules: ScheduleBase[];
}

export const initialState: TeacherAppSchedulesState = {
  fetching: false,
  fetched: false,
  fetchError: false,
  schedules: [],
};

const teacherAppSchedulesSlice = createSlice({
  name: "TeacherApp/Schedules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeacherSchedules.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: false,
        };
      })
      .addCase(fetchTeacherSchedules.fulfilled, (state, action) => {
        const { payload } = action;
        const { schedules } = payload;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: false,
          schedules,
        };
      })
      .addCase(fetchTeacherSchedules.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      });
  },
});

export const teacherAppSchedulesState = (
  state: RootState,
): TeacherAppSchedulesState => state.teacherApp.schedules;

export default teacherAppSchedulesSlice.reducer;
