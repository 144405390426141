/* eslint-disable camelcase */
import { CreateMaterialRequestBody, UpdateMaterialRequestBody } from "@lib/Api";

type SnakeToCamelCase<T extends string> = T extends `${infer R}_${infer U}`
  ? `${R}${Capitalize<SnakeToCamelCase<U>>}`
  : T;

type SnakeToCamel<T extends Record<string, unknown>> = {
  [K in keyof T as `${SnakeToCamelCase<string & K>}`]: T[K] extends Record<
    string,
    unknown
  >
    ? SnakeToCamel<T[K]>
    : T[K];
};

export enum AllowedToDownload {
  Allowed = "true",
  NotAllowed = "false",
}

export enum isPublic {
  Allowed = "true",
  NotAllowed = "false",
}

export type CreateMaterialParams = SnakeToCamel<
  Pick<CreateMaterialRequestBody, "title" | "category_id">
> & {
  downloadAllowed: boolean;
  isPublic: boolean;
};

export type CommonUpdateMaterialParams = SnakeToCamel<
  Omit<UpdateMaterialRequestBody, "download_allowed" | "is_public">
> & {
  id: string;
  tags: string[];
  downloadAllowed: AllowedToDownload;
  isPublic: isPublic;
  materialVideoId: string;
  videoFileData: VideoFileData | null;
  videoChanged: boolean;
};

export type UpdateMaterialParams = CommonUpdateMaterialParams & {
  pdfFilesData: PdfFileData[];
  thumbnailData: string | ThumbnailData | undefined;
  removedImages: string[];
  external_link: string;
  removedVideos: string[];
};

export type UpdateVideoInMaterialParams = CommonUpdateMaterialParams;

export interface UpdateVideoInMaterialWithVideoParams {
  params: UpdateVideoInMaterialParams;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
}

export interface MaterialTicketInfo {
  hash_id: string;
}

/* eslint-disable camelcase */
export type PdfFileData = {
  id: string | null;
  name: string;
  file: File | null;
  type: string;
};

export type VideoFileData = {
  name: string;
  file: File;
  type: string;
  thumbnail: File | string;
};

export type ThumbnailData = {
  name: string;
  file: File;
  type: string;
};

export type SetThumbnailData = {
  title: string;
  file: File;
  type: string;
};
export interface CreateMaterialTicketParams {
  category_id: string;
}
export interface CreateMaterialTicketResponse {
  materialTicket: string;
  completed: true;
}
export interface PutMaterialTicketParams {
  hashed_ticket_id: string;
}
export interface PutMaterialTicketResponse {
  materialTicket: string;
  completed: true;
}
