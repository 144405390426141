/* eslint-disable camelcase */

import { logout } from "@actions/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH } from "@root/constants/localStorageKeys";
import { RootState } from "@store/store";
import Cookies from "js-cookie";

import { Api } from "../../lib/Api";

const api = new Api();

export interface FetchAffiliateInvitationArg {
  token: string;
}

export const fetchAffiliateInvitation = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "affiliateInvitation/fetchWithToken",
  async (arg: FetchAffiliateInvitationArg, { dispatch, rejectWithValue }) => {
    try {
      if (Cookies.get(AUTH.ACCESS_TOKEN)) {
        dispatch(logout());
      }
      const response =
        await api.affiliateInvitations.fetchAffiliateInvitationWithToken(
          arg.token,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
