/* eslint-disable camelcase */
import { Api, GetStudentSponsorInfosParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchOnePblSponsorInfoAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/sponsor/infos/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.student.getStudentSponsorInfosId(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblSponsorInfoAsStudent = createAsyncThunk<
  any,
  GetStudentSponsorInfosParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/pbl/sponsor/infos/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.getStudentSponsorInfos(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const reset = createAction("student/pbl/sponsor/infos/reset");
export const resetOne = createAction("student/pbl/sponsor/infos/resetOne");
