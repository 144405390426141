import { PackagingAsLearningTemplatePackageBase } from "@lib/Api";
import { reorder } from "@utils/reorder/reorder";
import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";

type UseCoPackage = {
  handleSortState: () => void;
  onDragEnd: (result: DropResult) => void;
  sortActivated: boolean;
  reorderCoPackageList: PackagingAsLearningTemplatePackageBase[];
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setSortActivated: React.Dispatch<React.SetStateAction<boolean>>;
};

// eslint-disable-next-line import/prefer-default-export
export const useCoPackage = (
  packageContents: PackagingAsLearningTemplatePackageBase[],
): UseCoPackage => {
  const editablePackageContent = [...packageContents];
  const [reorderCoPackageList, setReorderCoPackageList] = useState(
    editablePackageContent,
  );

  const [sortActivated, setSortActivated] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const handleSortState = () => {
    setIsSaved(false);
    if (!isSaved && editablePackageContent) {
      setReorderCoPackageList(editablePackageContent);
    }
    setSortActivated(!sortActivated);
  };

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const newListSequence = reorder(
      reorderCoPackageList,
      result.source.index,
      result.destination.index,
    );

    setReorderCoPackageList(newListSequence);
  };

  return {
    handleSortState,
    onDragEnd,
    sortActivated,
    reorderCoPackageList,
    setIsSaved,
    setSortActivated,
  };
};
