/* eslint-disable camelcase */
import {
  deleteSuperOwnerCompanyTag,
  fetchCompanyTagsAsSuperOwner,
  postSuperOwnerCompanyTag,
  resetState,
  saveCompanyTagSequence,
  updateSuperOwnerCompanyTag,
} from "@actions/superOwnerApp/companyTag";
import { CompanyTag } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface SuperOwnerCompanyTagsState {
  fetching: boolean;
  fetched: boolean;
  companyTags: CompanyTag[];
  totalCount: number;
  error: any;
  changeStatus: ReduxStatusType;
  changeError: any; // for post, update, delete, and sort
}

export const initialState: SuperOwnerCompanyTagsState = {
  fetching: false,
  fetched: false,
  companyTags: [],
  totalCount: 0,
  error: null,
  changeStatus: ReduxStatus.idle,
  changeError: null,
};

function companyTagPendingState(state: SuperOwnerCompanyTagsState) {
  return {
    ...state,
    changeStatus: ReduxStatus.pending,
    changeError: null,
  };
}

export const superOwnerAppCompanyTagsSlice = createSlice({
  name: "SuperOwnerApp/Tags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyTagsAsSuperOwner.pending, (state) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null,
        };
      })
      .addCase(fetchCompanyTagsAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          companyTags: action.payload.company_tags,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchCompanyTagsAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: getErrorMessage(action.payload.error as any),
        };
      })
      .addCase(postSuperOwnerCompanyTag.pending, (state) => {
        return companyTagPendingState(state);
      })
      .addCase(postSuperOwnerCompanyTag.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
        };
      })
      .addCase(postSuperOwnerCompanyTag.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(deleteSuperOwnerCompanyTag.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(deleteSuperOwnerCompanyTag.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
        };
      })
      .addCase(deleteSuperOwnerCompanyTag.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(updateSuperOwnerCompanyTag.pending, (state) => {
        return companyTagPendingState(state);
      })
      .addCase(updateSuperOwnerCompanyTag.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
        };
      })
      .addCase(updateSuperOwnerCompanyTag.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(saveCompanyTagSequence.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(saveCompanyTagSequence.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
        };
      })
      .addCase(saveCompanyTagSequence.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: null,
          changeStatus: ReduxStatus.idle,
          changeError: null,
        };
      });
  },
});

export const superOwnerAppCompanyTagsState = (
  state: RootState,
): SuperOwnerCompanyTagsState => state.superOwnerApp.companyTags;

export default superOwnerAppCompanyTagsSlice.reducer;
