/* eslint-disable camelcase */
import {
  Api,
  FetchSponsorLecturesAsStudentParams,
  FetchSponsorProjectsAsStudentParams,
  FetchSponsorProjectsForCarouselAsStudentParams,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblSponsorProjectAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/sponsor/projects/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchOneSponsorProjectAsStudent(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblSponsorProjectCarouselAsStudent = createAsyncThunk<
  GetResponseType<Api["student"]["fetchSponsorProjectsForCarouselAsStudent"]>,
  FetchSponsorProjectsForCarouselAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/sponsor/projects/carousel",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.student.fetchSponsorProjectsForCarouselAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblSponsorProjectsAsStudent = createAsyncThunk<
  any,
  FetchSponsorProjectsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/pbl/sponsor/projects", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.fetchSponsorProjectsAsStudent(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchLecturesByProjectAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/sponsor/projects/fetchLectures",
  async (params: FetchSponsorLecturesAsStudentParams, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchSponsorLecturesAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetOne = createAction("student/pbl/sponsor/projects/resetOne");
