import ScrollLeftIcon from "@assets/images/svg/tabsScrollLeft_icon.svg?react";
import { Box,IconButton, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

type Props = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: theme.spacing(2),
    },
    iconButtonRoot: {
      padding: 0,
    },
  }),
);

const Header: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <IconButton
        onClick={onClose}
        classes={{ root: classes.iconButtonRoot }}
        size="large"
      >
        <ScrollLeftIcon />
      </IconButton>
    </Box>
  );
};

export default Header;
