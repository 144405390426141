import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import { ITEMS_PER_PAGE } from "@constants/page";
import errorWithMessage from "@lib/rtk/error-utils";
import { Grid, List, ListItem,ListItemText, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  InterviewRequestDetailBase,
  InterviewRequestListBase,
  InterviewRequestUnreadCountBase,
} from "@root/endpoints/TimeTactApi/studentApi";
import React from "react";
import { useTranslation } from "react-i18next";

import InterviewsList from "./List";
import ListItemsSkeleton from "./List/ListItemsSkeleton";

type Props = {
  interviewRequestsList: InterviewRequestListBase[];
  requestsListError: unknown;
  isRequestsListFetching: boolean;
  isRequestsListSuccess: boolean;
  totalInterviewRequestCount: number;
  currentInterviewPage: number;
  currentInterviewRequest: InterviewRequestListBase;
  unreadMessages: InterviewRequestUnreadCountBase[];
  handleSelectInterview: (request: InterviewRequestDetailBase) => void;
  handleMobileChat: () => void;
  handlePageTransition: (newPage: number) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "100%",
      height: "100%",
      marginTop: 0,
      overflow: "scroll",
      "scrollbar-width": "none" /* Firefox対応のスクロールバー非表示コード*/,
      "-ms-overflow-style":
        "none" /* Internet Explore対応のスクロールバー非表示コード*/,
      "&::-webkit-scrollbar": {
        display:
          "none" /* Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/,
      },
      borderRight: `2px solid ${theme.palette.customColor.boardGrey}`,
      [theme.breakpoints.down("md")]: {
        borderRight: "none",
      },
    },
    listContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      maxHeight: "100%",
      overflow: "scroll",
      "scrollbar-width": "none" /* Firefox対応のスクロールバー非表示コード*/,
      "-ms-overflow-style":
        "none" /* Internet Explore対応のスクロールバー非表示コード*/,
      "&::-webkit-scrollbar": {
        display:
          "none" /* Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/,
      },
    },
    noDataContainer: {
      padding: theme.spacing(2),
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: theme.spacing(2),
    },
    iconButtonRoot: {
      padding: 0,
    },
    iconStyle: {
      color: theme.palette.tertiary.mainDark,
      fontSize: "2.2rem",
    },
  }),
);

const InterviewRequestsList: React.FC<Props> = ({
  interviewRequestsList,
  requestsListError,
  isRequestsListFetching,
  isRequestsListSuccess,
  currentInterviewRequest,
  totalInterviewRequestCount,
  currentInterviewPage,
  unreadMessages,
  handleSelectInterview,
  handleMobileChat,
  handlePageTransition,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  let listContent;

  if (isRequestsListFetching) {
    listContent = <ListItemsSkeleton />;
  } else if (isRequestsListSuccess && interviewRequestsList.length) {
    listContent = (
      <>
        <InterviewsList
          handleMobileChat={handleMobileChat}
          handleSelectInterview={handleSelectInterview}
          interviewRequestsList={interviewRequestsList}
          currentInterviewRequest={currentInterviewRequest}
          unreadMessages={unreadMessages}
        />
        <V2Pagination
          totalCount={totalInterviewRequestCount}
          perPage={ITEMS_PER_PAGE}
          currentPage={currentInterviewPage}
          handlePageTransition={handlePageTransition}
        />
      </>
    );
  } else {
    listContent = (
      <ListItem classes={{ root: classes.noDataContainer }}>
        <ListItemText
          primary={
            requestsListError
              ? errorWithMessage(requestsListError)
              : t("noData")
          }
        />
      </ListItem>
    );
  }

  return (
    <Grid item md={4} xs={12} className={classes.mainContainer}>
      <List className={classes.listContainer}>{listContent}</List>
    </Grid>
  );
};

export default InterviewRequestsList;
