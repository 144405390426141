/* eslint-disable camelcase */
import {
  Api,
  FetchAssignableCompaniesAsSuperOwnerParams,
  FetchRevieweeCompaniesAsSuperOwnerParams,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

// Fetch copanies connted to the super owner company
// Can filter with company_tags
export const fetchRevieweeCompaniesAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewer/reviewee/fetch",
  async (
    params: FetchRevieweeCompaniesAsSuperOwnerParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.fetchRevieweeCompaniesAsSuperOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchAssignableCompaniesAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewer/reviewee/assignable/fetch",
  async (
    params: FetchAssignableCompaniesAsSuperOwnerParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.fetchAssignableCompaniesAsSuperOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface BatchCreateRevieweeCompaniesAsSuperOwnerParams {
  company_ids: string[];
  reviewer_company_group_id: string;
}

export interface BatchCreateRevieweeCompaniesAsSuperOwnerArg {
  reviewerCompanyId: string;
  params: BatchCreateRevieweeCompaniesAsSuperOwnerParams;
}

export const batchCreate = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/companies/reviewer/reviewee/batchCreate",
  async (
    { reviewerCompanyId, params }: BatchCreateRevieweeCompaniesAsSuperOwnerArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response =
        await api.superOwner.batchCreateRevieweeCompaniesAsSuperOwner(
          reviewerCompanyId,
          params,
        );
      // Refetch Distributions
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface BatchDeleteRevieweeCompaniesAsSuperOwnerParams {
  company_ids: string[];
  reviewer_company_group_id: string;
}

export interface BatchDeleteRevieweeCompaniesAsSuperOwnerArg {
  reviewerCompanyId: string;
  params: BatchDeleteRevieweeCompaniesAsSuperOwnerParams;
}

export const batchDelete = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/companies/reviewer/reviewee/batchDelete",
  async (
    { reviewerCompanyId, params }: BatchDeleteRevieweeCompaniesAsSuperOwnerArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response =
        await api.superOwner.batchDeleteRevieweeCompaniesAsSuperOwner(
          reviewerCompanyId,
          params,
        );
      // Refetch Distributions
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "super_owner/companies/reviewer/reviewee/resetState",
);

export const resetDeleteActionState = createAction(
  "super_owner/companies/reviewer/reviewee/resetDeleteActionState",
);
