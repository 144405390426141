/* eslint-disable camelcase */

import { Api, FetchArticleCommentsAsReviewerParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticleCommentsAsReviewerArg {
  params: FetchArticleCommentsAsReviewerParams;
}

export const fetchArticleCommentsAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/articleComment/fetch",
  async (arg: FetchArticleCommentsAsReviewerArg, { rejectWithValue }) => {
    try {
      const response = await api.reviewer.fetchArticleCommentsAsReviewer(
        arg.params,
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreateArticleCommentAsReviewerArg {
  articleId: string;
  params: {
    body: string;
  };
}

export const createArticleCommentAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/articleComment/create",
  async (arg: CreateArticleCommentAsReviewerArg, { rejectWithValue }) => {
    const { articleId, params } = arg;
    try {
      const response = await api.reviewer.createArticleCommentAsReviewer(
        articleId,
        { body: params.body },
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface DeleteArticleCommentAsReviewerArg {
  articleId: string;
  commentId: string;
}

export const deleteArticleCommentAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/articleComment/delete",
  async (arg: DeleteArticleCommentAsReviewerArg, { rejectWithValue }) => {
    const { articleId, commentId } = arg;
    try {
      await api.reviewer.deleteArticleCommentAsReviewer(articleId, commentId);
      return {
        articleId,
        commentId,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetCommentRelatedFormState = createAction(
  "reviewer/article/comment/resetFormState",
);

export const resetDeleteCommentRelatedState = createAction(
  "reviewer/article/comment/resetDeleteRelatedState",
);
