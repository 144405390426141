/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { formatedDateTime } from "@lib/time-utils";
import { useState } from "react";
import { useForm } from "react-hook-form";

export type StartAndEndDateFormValue = {
  startDate: string;
  endDate: string;
};

const useHandleStartAndEndDate = () => {
  const defaultState = {
    startDate: "",
    endDate: "",
  };
  const [{ startDate, endDate }, setDates] =
    useState<StartAndEndDateFormValue>(defaultState);
  const { control, handleSubmit, setValue, getValues, reset } =
    useForm<StartAndEndDateFormValue>({
      defaultValues: {
        startDate: "",
        endDate: "",
      },
    });
  const onSubmit = (values: StartAndEndDateFormValue) => {
    setDates({
      startDate: formatedDateTime(values.startDate),
      endDate: formatedDateTime(values.endDate),
    });
  };
  const handleClear = () => {
    setDates(defaultState);
    reset(defaultState);
  };
  return {
    endDate,
    startDate,
    control,
    submit: handleSubmit(onSubmit),
    setValue,
    getValues,
    handleClear,
  };
};

export default useHandleStartAndEndDate;
