import {
  deletePblBoardCampaignRequestsAsTeacher,
  postPblBoardCampaignRequestAsTeacher,
  resetTeacherAppPblBoardCampaignRequestState,
} from "@actions/teacherApp/pblBoard/campaignRequest";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface TeacherAppPblBoardCampaignRequestState {
  postPblBoardCampaignRequest: ReduxStatusType;
  postPblBoardCampaignRequestError: string | null;
  deletePblBoardCampaignRequest: ReduxStatusType;
  deletePblBoardCampaignRequestError: string | null;
}

export const initialState: TeacherAppPblBoardCampaignRequestState = {
  postPblBoardCampaignRequest: ReduxStatus.idle,
  postPblBoardCampaignRequestError: null,
  deletePblBoardCampaignRequest: ReduxStatus.idle,
  deletePblBoardCampaignRequestError: null,
};

const teacherAppPblBoardCampaignRequestSlice = createSlice({
  name: "teacherApp/pblBoard/campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        postPblBoardCampaignRequestAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            postPblBoardCampaignRequest: ReduxStatus.pending,
            postPblBoardCampaignRequestError: null,
          };
        },
      )
      .addCase(
        postPblBoardCampaignRequestAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            postPblBoardCampaignRequest: ReduxStatus.fulfilled,
            postPblBoardCampaignRequestError: null,
          };
        },
      )
      .addCase(
        postPblBoardCampaignRequestAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            postPblBoardCampaignRequest: ReduxStatus.rejected,
            postPblBoardCampaignRequestError: "探究成果の応募に失敗しました。",
          };
        },
      )
      .addCase(
        deletePblBoardCampaignRequestsAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            deletePblBoardCampaignRequest: ReduxStatus.pending,
            deletePblBoardCampaignRequestError: null,
          };
        },
      )
      .addCase(
        deletePblBoardCampaignRequestsAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            deletePblBoardCampaignRequest: ReduxStatus.fulfilled,
            deletePblBoardCampaignRequestError: null,
          };
        },
      )
      .addCase(
        deletePblBoardCampaignRequestsAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            deletePblBoardCampaignRequest: ReduxStatus.rejected,
            deletePblBoardCampaignRequestError:
              "探究成果の取り下げに失敗しました。",
          };
        },
      )
      .addCase(
        resetTeacherAppPblBoardCampaignRequestState,
        (state, _action) => {
          return {
            ...state,
            postPblBoardCampaignRequest: ReduxStatus.idle,
            postPblBoardCampaignRequestError: null,
            deletePblBoardCampaignRequest: ReduxStatus.idle,
            deletePblBoardCampaignRequestError: null,
          };
        },
      )
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppPblBoardCampaignRequestState = (
  state: RootState,
): TeacherAppPblBoardCampaignRequestState =>
  state.teacherApp.pblBoardCampaignRequest;

export default teacherAppPblBoardCampaignRequestSlice.reducer;
