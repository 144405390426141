/* eslint-disable camelcase */

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Api,
  GetSponsorDeliverySchoolsAsContentProviderParams,
} from "@root/lib/Api";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchSponsorDeliverySchoolsAsContentProviderArg {
  params: GetSponsorDeliverySchoolsAsContentProviderParams;
}

export const fetchSponsorDeliverySchoolsAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/sponsor/deliverySchools/fetch",
  async (
    arg: FetchSponsorDeliverySchoolsAsContentProviderArg,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.contentProvider.getSponsorDeliverySchoolsAsContentProvider(
          arg.params,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
