export type SortOrder = "numberAsc" | "numberDesc";

export enum SchoolSearchType {
  Name = "name",
  Tag = "tag",
}

export type SearchParam = {
  searchSelect?: SchoolSearchType;
  searchText?: string;
};

export type UrlParam = { page: string } & SearchParam;
