/* eslint-disable camelcase */
import Section from "@components/UI/organisms/Layout/SectionWrapper";
import LazyPieGraphStats from "@components/UI/organisms/PieGraphStats/LazyPieGraphStats";
import { Box,Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DailyLogStatsBase } from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";
import { useTranslation } from "react-i18next";

import { formatEachStudentLogs,getStudyTime } from "../../utils";
import StatsSkeleton from "./StatsSkeleton";

interface Props {
  dataPie: DailyLogStatsBase;
  isFetchingStudyLog: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.quaternary.grey}`,
      boxShadow:
        "2px 2px 4px 0px rgba(71, 81, 73, 0.05),-2px -2px 4px 0px rgba(71, 81, 73, 0.05)",
    },
    itemContainer: {
      padding: theme.spacing(2),
    },
    time: {
      color: theme.palette.tertiary.orange,
      margin: "0 auto",
      fontSize: "64px",
    },
    noStudyTime: {
      margin: "auto",
    },
    timeContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
    },
  }),
);

const Stats: React.FC<Props> = ({ dataPie, isFetchingStudyLog }) => {
  const classes = useStyles();
  const { t } = useTranslation("study_logs");
  const studyTime = getStudyTime(dataPie);
  const weekLogs = formatEachStudentLogs(dataPie, t);
  const totalTime = weekLogs.reduce(
    (sum, element) => sum + (element.study_time ?? 0),
    0,
  );

  const pieDataFromWeekLogs = weekLogs.map((log) => {
    const timePercent = Math.round(((log.study_time ?? 0) / totalTime) * 100);
    return {
      title: log.category as string,
      value: timePercent as number,
    };
  });

  return (
    <Section title={t("studyTime")}>
      {isFetchingStudyLog ? (
        <StatsSkeleton />
      ) : (
        <Grid container className={classes.mainContainer}>
          <Grid
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className={classes.itemContainer}
            item
          >
            <Box className={classes.timeContainer}>
              <Typography className={classes.time}>
                {`${studyTime}`}
                <span style={{ fontSize: "40px" }}>{t("hours")}</span>
              </Typography>
            </Box>
          </Grid>
          {!pieDataFromWeekLogs.length ? (
            <Typography className={classes.noStudyTime}>
              {t("noStudyLogYet")}
            </Typography>
          ) : (
            <Grid xs={12} sm={12} md={8} lg={8} xl={8} item>
              <LazyPieGraphStats
                data={pieDataFromWeekLogs}
                name="title"
                value="value"
                valueFormatter={(percent) => `${percent.toFixed(0)}%`}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Section>
  );
};

export default Stats;
