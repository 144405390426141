/* eslint-disable camelcase */
import {
  Api,
  Company,
  CompanyAsSuperOwner,
  FetchCompaniesAsSuperOwnerParams,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { UpdateCompanyParams } from "./school";

const api = new Api();

export interface FetchCompaniesAsSuperOwnerArg {
  params: FetchCompaniesAsSuperOwnerParams;
  inModal?: boolean;
}

// Fetch copanies connted to the super owner company
// Can filter with company_tags
export const fetchCompaniesAsSuperOwner = createAsyncThunk<
  {
    companies: CompanyAsSuperOwner[];
    total_count: number;
  },
  FetchCompaniesAsSuperOwnerArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewer/fetch",
  async ({ params }, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.fetchCompaniesAsSuperOwner(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchCompanyAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewer/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.fetchCompanyAsSuperOwner(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export type CreateCompanyParams = {
  name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  is_reviewer?: boolean;
};

export const createCompanyAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewer/post",
  async (data: CreateCompanyParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.createCompanyAsSuperOwner(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCompanyAsSuperOwner = createAsyncThunk<
  Company,
  UpdateCompanyParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewer/update",
  async (args, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.updateCompanyAsSuperOwner(
        args.id,
        args.data,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const deleteCompanyAsSuperOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/reviewer/delete", async (id, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.deleteCompanyAsSuperOwner(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetState = createAction(
  "super_owner/companies/reviewer/resetState",
);

export const resetModalState = createAction(
  "super_owner/companies/reviewer/resetModalState",
);

export const resetOne = createAction("super_owner/companies/reviewer/resetOne");
