import V2DatePicker from "@components/UIv2/atoms/V2DatePicker";
import V2DateTimePicker from "@components/UIv2/atoms/V2DateTimePicker";
import { isValidDuration } from "@lib/time-utils";
import { Box, BoxProps } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { Controller, UseFormGetValues } from "react-hook-form";

import { FormCategoryTitle } from "../../atoms/Forms/FormComponents";

interface Props {
  /**
   * _____REQUIRED_PROPS_____
  /**
  /**
   * From React-hook-form to handle end input.
   */
  setValue: any;
  /**
   * From React-hook-form to control the input value.
   */
  control: any;
  /**
   * From React-hook-form to get the input value.
   */
  getValues: UseFormGetValues<any>;
  /**
   * For React-hook-form -> StartDate/Time input name.
   */
  startInputName: string;

  /**
   * For React-hook-form -> EndDate/Time input name.
   */
  endInputName: string;
  /**
   * Title of start input.
   */
  startInputTitle: string | React.ReactNode;

  /**
   * Title of end input.
   */
  endInputTitle: string | React.ReactNode;
  /**
   * ------------------------------------------------------------
  /**
   * _____OPTIONAL_PROPS_____
  /**
   * 
    /**
   * Min date of start
   */
  minStartDate?: string | Dayjs;
  /**
   * Max date of start
   */
  maxStartDate?: string | Dayjs;
  /**
   * Min date of end
   */
  minEndDate?: string | Dayjs;
  /**
   * Max date of end
   */
  maxEndDate?: string | Dayjs;

  /**
   * Required the inputs or not.
   */
  isRequired?: boolean;

  /**
   * If isRequired True.
   * Add The Helper Msg
   */
  startHelperMsg?: string;

  /**
   * If isRequired true.
   * Add The Helper Msg
   */
  endHelperMsg?: string;

  /**
   * Is time also needed ?
   */
  time?: boolean;
  /**
   * Disable past dates
   */
  disablePast?: boolean;
  /**
   * User has permission to edit?
   */
  editPermission?: boolean;
  /**
   * Arguments of main wrapper
   */
  mainWrapperProps?: BoxProps;
  /**
   * Arguments of start input wrapper
   */
  startInputWrapperProps?: BoxProps;
  /**
   * Arguments of end input wrapper
   */
  endInputWrapperProps?: BoxProps;
}

export const getBeforeEndDateValidateMessage = ({
  endInputTitle,
  startInputTitle,
}: {
  endInputTitle: string | React.ReactNode;
  startInputTitle: string | React.ReactNode;
}): string => {
  if (
    typeof endInputTitle === "string" &&
    typeof startInputTitle === "string"
  ) {
    return `${endInputTitle}は${startInputTitle}よりも後に設定してください。`;
  }
  return `終了日時は開始日時よりも後に設定してください。`;
};

const DatePicker: React.FC<Props> = ({
  isRequired,
  time,
  startInputTitle,
  endInputTitle,
  startInputName,
  endInputName,
  minStartDate,
  maxStartDate,
  minEndDate,
  maxEndDate,
  startHelperMsg,
  endHelperMsg,
  control,
  setValue,
  getValues,
  disablePast,
  editPermission,
  mainWrapperProps,
  startInputWrapperProps,
  endInputWrapperProps,
}) => {
  const PickerComponent = time ? V2DateTimePicker : V2DatePicker;

  return (
    <Box {...mainWrapperProps}>
      <Box {...startInputWrapperProps}>
        <FormCategoryTitle>{startInputTitle}</FormCategoryTitle>
        <Controller
          control={control}
          name={startInputName}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PickerComponent
              disablePast={disablePast}
              disabled={!editPermission}
              value={value}
              onChange={onChange}
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error ? error.message : null,
                  inputProps: { "data-cy": "start-picker" },
                  style: { marginRight: 8 },
                },
              }}
              minDate={minStartDate ? dayjs(minStartDate) : undefined}
              maxDate={maxStartDate ? dayjs(maxStartDate) : undefined}
            />
          )}
          rules={{
            required: {
              value: isRequired as boolean,
              message: startHelperMsg as string,
            },
          }}
        />
      </Box>
      <Box {...endInputWrapperProps}>
        <FormCategoryTitle>{endInputTitle}</FormCategoryTitle>
        <Controller
          control={control}
          name={endInputName}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PickerComponent
              disablePast={disablePast}
              disabled={!editPermission}
              value={value}
              onChange={onChange}
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error ? error.message : null,
                  inputProps: { "data-cy": "end-picker" },
                },
              }}
              minDate={minEndDate ? dayjs(minEndDate) : undefined}
              maxDate={maxEndDate ? dayjs(maxEndDate) : undefined}
            />
          )}
          rules={{
            required: {
              value: (isRequired as boolean) || false,
              message: endHelperMsg as string,
            },
            validate: {
              beforeEndDate: (value) =>
                isValidDuration(getValues(startInputName), value) ||
                getBeforeEndDateValidateMessage({
                  endInputTitle,
                  startInputTitle,
                }),
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default DatePicker;

DatePicker.defaultProps = {
  editPermission: true,
  disablePast: false,
  minStartDate: undefined,
  maxStartDate: undefined,
  minEndDate: undefined,
  maxEndDate: undefined,
  isRequired: false,
  startHelperMsg: "",
  endHelperMsg: "",
  time: false,
  mainWrapperProps: undefined,
  startInputWrapperProps: undefined,
  endInputWrapperProps: undefined,
};
