import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

// eslint-disable-next-line import/prefer-default-export
export const fetchStudentMaterialWorksAsTeacher = createAsyncThunk<
  GetResponseType<Api["teacher"]["fetchStudentMaterialWorksAsTeacher"]>,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/studentMaterialWorks/fetch",
  async (studentId, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchStudentMaterialWorksAsTeacher({
        student_id: studentId,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchOneStudentMaterialWorkAsTeacher = createAsyncThunk<
  GetResponseType<Api["teacher"]["fetchOneStudentMaterialWorkAsTeacher"]>,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/studentMaterialWorks/fetchOne",
  async (materialWorkId, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchOneStudentMaterialWorkAsTeacher({
        material_work_id: materialWorkId,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
