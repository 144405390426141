import { combineReducers } from "redux";

import CategoryRenameMapReducer from "./categoryRenameMap";
import CompanyOptionReducer from "./companyOption";
import CompanyTagsReducer from "./companyTag";
import CompanyTaggingReducer from "./companyTagging";
import CompanyTagTypesReducer from "./companyTagType";
import CustomListReducer from "./customList";
import CustomListRecordReducer from "./customListRecord";
import HomeworkReducer from "./homework";
import HomeworkTemplateReducer from "./homeworkTemplate";
import HomeworkTemplateDistributionReducer from "./homeworkTemplateDistribution";
import HomeworkTemplateDistributionHistoryReducer from "./homeworkTemplateHistories";
import InquiryReducer from "./inquiry";
import MaterialReducer from "./material";
import MaterialDistributionReducer from "./materialDistribution";
import MaterialWorkReducer from "./materialWork";
import NotificationReducer from "./notification";
import revieweeCompanyReducer from "./revieweeCompany";
import reviewerCompanyReducer from "./reviewerCompany";
import SchoolsReducer from "./school";
import learningTemplateDistributionReducer from "./templatePackages/learningTemplateDistribution";
import learningTemplatePackagesHistoryReducer from "./templatePackages/learningTemplateHistory";
import learningTemplatePackagesReducer from "./templatePackages/learningTemplatePackage";
import UsersReducer from "./user";
import UserTagsReducer from "./userTag";
import UserTaggingReducer from "./userTagging";
import UserTagTypesReducer from "./userTagType";

const superOwnerAppReducer = combineReducers({
  users: UsersReducer,
  material: MaterialReducer,
  materialWork: MaterialWorkReducer,
  homework: HomeworkReducer,
  homeworkTemplate: HomeworkTemplateReducer,
  homeworkTemplateDistribution: HomeworkTemplateDistributionReducer,
  homeworkTemplateDistributionHistory:
    HomeworkTemplateDistributionHistoryReducer,
  schools: SchoolsReducer,
  reviewerCompany: reviewerCompanyReducer,
  revieweeCompany: revieweeCompanyReducer,
  companyOption: CompanyOptionReducer,
  companyTagTypes: CompanyTagTypesReducer,
  companyTags: CompanyTagsReducer,
  userTags: UserTagsReducer,
  userTagTypes: UserTagTypesReducer,
  companyTagging: CompanyTaggingReducer,
  materialDistribution: MaterialDistributionReducer,
  customList: CustomListReducer,
  customListRecord: CustomListRecordReducer,
  learningTemplatePackages: learningTemplatePackagesReducer,
  learningTemplateDistribution: learningTemplateDistributionReducer,
  learningTemplateHistory: learningTemplatePackagesHistoryReducer,
  notification: NotificationReducer,
  userTagging: UserTaggingReducer,
  inquiry: InquiryReducer,
  categoryRenameMap: CategoryRenameMapReducer,
});

export default superOwnerAppReducer;
