/* eslint-disable camelcase */
import { STUDENT_HOMEWORKS_PATH } from "@constants/api";
import {
  Api,
  CustomValueRequestBody,
  FetchHomeworksAsStudentParams,
  PostStudentHomeworkTemplateCustomValuesParams,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { UpdateHomeworkAsStudentArg } from "./types/homework";

const api = new Api();

export const fetchHomeworkAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/homework/fetch",
  async (params: FetchHomeworksAsStudentParams, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchHomeworksAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchOneHomeworkAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/homework/fetchOne",
  async (homeworkId: string, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchHomeworkAsStudent(homeworkId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchOneHomeworkAnswersAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/homework/fetchOneAnswers",
  async (homeworkId: string, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchHomeworkAsStudent(homeworkId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateOneHomeworkAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/homework/update",
  async (
    { homeworkId, params }: UpdateHomeworkAsStudentArg,
    { rejectWithValue },
  ) => {
    try {
      const fd = new FormData();
      fd.set("status", params.status);
      fd.set(
        "student_comment",
        typeof params.student_comment !== "undefined"
          ? params.student_comment
          : "",
      );
      if (typeof params.homework_images !== "undefined") {
        params.homework_images.forEach((fileData: any, index: number) => {
          fd.append(`student_homework_images[]`, fileData.file, fileData.title);
        });
      }
      const response = await http.put(
        `${STUDENT_HOMEWORKS_PATH}/${homeworkId}`,
        fd,
      );
      const resData = response.data;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface AnswerCustomFieldAsStudentParams {
  query: PostStudentHomeworkTemplateCustomValuesParams;
  data: CustomValueRequestBody;
}

export const answerCustomFieldAsStudent = createAsyncThunk<
  any,
  AnswerCustomFieldAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/homeworkCustomField/create", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.postStudentHomeworkTemplateCustomValues(
      params.query,
      params.data,
    );
    return response.data;
  } catch (err) {
    const status = err.response?.status || 500;
    const data = err.response?.data || { message: "An error occurred" };
    const errCustomFieldId = params.query.homework_template_custom_field_id;
    return rejectWithValue({ data, status, errCustomFieldId });
  }
});

export interface UpdateOrDeleteCustomFieldAsStudentParams {
  id: string;
  data: CustomValueRequestBody;
}

export const updateCustomFieldAsStudent = createAsyncThunk<
  any,
  UpdateOrDeleteCustomFieldAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/homeworkCustomField/update", async (params, { rejectWithValue }) => {
  try {
    const response = await api.student.putStudentHomeworkTemplateCustomValuesId(
      params.id,
      params.data,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteCustomFieldAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/homeworkCustomField/delete",
  async (
    params: UpdateOrDeleteCustomFieldAsStudentParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.student.deleteStudentHomeworkTemplateCustomValuesId(
          params.id,
          params.data,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatedHomeworkFile = createAction(
  "studentApp/homework/updatedHomeworkFile",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const fileUploaded = createAction(
  "studentApp/homework/fileUploaded",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const fileDeleted = createAction(
  "studentApp/homework/fileDeleted",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const resetStudentAppHomeworkFormData = createAction(
  "studentApp/homework/resetFormRelatedData",
);
export const resetStudentAppHomeworkFormError = createAction(
  "studentApp/homework/resetFormRelatedError",
);
export const updateAllCustomFieldAsStudent = createAction(
  "studentApp/homework/updateAllCustomFieldAsStudent",
);
