import { closeToast, enqueueToast, removeToast } from "@actions/toast";
import { remove } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

interface ToastState {
  toasts: any[];
}

const initialState = { toasts: [] } as ToastState;

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(enqueueToast, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          toasts: [...state.toasts, payload],
        };
      })
      .addCase(closeToast, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        let newToasts = currentState.toasts;
        if (payload.dismissAll) {
          newToasts = [];
        } else {
          newToasts = remove(currentState.toasts, payload.id as string);
        }
        return {
          ...state,
          toasts: newToasts,
        };
      })
      .addCase(removeToast, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          toasts: currentState.toasts.filter(
            (toast) => toast.id === payload.id,
          ),
        };
      });
  },
});

export const toastState = (state: RootState): ToastState => state.toast;

export default toastSlice.reducer;
