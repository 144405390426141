import { Box, Theme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: theme.spacing(2),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      cursor: "pointer",
      border: `2px solid ${theme.palette.quaternary.grey}`,
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    studentNameContainer: {
      width: "fit-content",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.quaternary.grey}`,
    },
    sectionContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    sectionWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "180px",
      height: "90px",
      borderRadius: theme.spacing(1),
      gap: theme.spacing(2),
      padding: theme.spacing(1),
      border: `2px solid ${theme.palette.quaternary.grey}`,
      background: theme.palette.common.white,
      boxShadow:
        "2px 2px 4px 0px rgba(71, 81, 73, 0.05),-2px -2px 4px 0px rgba(71, 81, 73, 0.05)",
    },
  }),
);

const StudentLogsItemSkeleton: React.FC = () => {
  const classes = useStyles();
  const skeletonSectionArray = Array.from({ length: 5 }, (_, idx) => idx + 1);
  const studentLogsItemArray = Array.from({ length: 5 }, (_, idx) => idx + 1);
  return (
    <>
      {studentLogsItemArray.map((value) => (
        <Box className={classes.mainContainer} key={value}>
          <Box className={classes.studentNameContainer}>
            <Skeleton variant="circular" width={25} height={25} />
            <Skeleton variant="text" width={130} />
          </Box>
          <Box className={classes.sectionContainer}>
            {skeletonSectionArray.map((item) => (
              <Box key={item} className={classes.sectionWrapper}>
                <Box className={classes.wrapper}>
                  <Skeleton variant="circular" width={25} height={25} />
                  <Skeleton variant="text" width={100} />
                </Box>
                <Skeleton variant="text" width={80} />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default StudentLogsItemSkeleton;
