import { getNumberOfCommentInquiries } from "@actions/inquiry";
import GreenWatch from "@assets/images/svg/GreenWatch.svg?react";
import MenuIcon from "@assets/images/svg/menu_icon.svg?react";
import NotificationIcon from "@assets/images/svg/notificationIcon.svg?react";
import ProfileIcon from "@assets/images/svg/profile-icon.svg?react";
import SettingsIcon from "@assets/images/svg/settings.svg?react";
import CreateLog from "@components/Student/StudentLogs/Modal/CreteOrUpdateLogModal";
import {
  StudyLogsModalState,
  StudyLogsModalTypes,
} from "@components/Teacher/StudentLogs";
import Languages from "@components/UI/atoms/Languages";
import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import SupportButton from "@components/UI/atoms/SupportButton/SupportButton";
import useNotification from "@components/UI/organisms/Navigation/Common/Notifications/useNotification";
import CustomMenu, { MenuItemProps } from "@components/UIv2/atoms/CustomMenu";
import Notifications from "@containers/Common/Notification/NotificationContainer";
import { useAuth } from "@contexts/Auth";
import useCloudRoleAndFeature from "@lib/hooks/useCloudRoleAndFeature";
import useOptionFeature from "@lib/hooks/useOptionFeature";
import DomainIcon from "@mui/icons-material/Domain";
import { Badge, Box, Divider, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Theme, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { inquiryState } from "@reducers/inquiry";
import { studentAppStudentLogsState } from "@reducers/studentApp/student_logs";
import { STUDENT_ROUTES } from "@root/constants/routes";
import { remainingApi } from "@root/endpoints/TimeTactApi/remainingApi";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import * as AuthActions from "@store/actions/auth";
import * as CommonActions from "@store/actions/common";
import { commonState } from "@store/reducers/common";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import MobileHeaderMenu from "../MobileDrawerMenu/MobileHeaderMenu";
import { useDefaultMenuItems } from "./hooks/useDefaultMenuItems";
import {
  CalendarTab,
  useTeacherSettingsMenuItems,
} from "./hooks/useTeacherSettingsMenuItems";
import NewNotificationsModal from "./Notifications/NewNotificationsModal";
import useNewNotificationCount from "./Notifications/useNewNotificationCount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrap: {
      display: "flex",
      justifyContent: "flex-end",
    },
    iconButton: {
      padding: 4,
      margin: theme.spacing(0, 1),
      color: theme.palette.grey[400],
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0.5),
      },
    },
    lastIcon: {
      marginRight: theme.spacing(0),
    },
    smallIcon: {
      width: "28px",
      height: "28px",
    },
    iconFullSize: {
      width: "38px",
      height: "38px",
    },
    userName: {
      display: "flex",
      alignItems: "center",
    },
    companySection: {
      display: "flex",
      gap: theme.spacing(1),
    },
  }),
);

interface CommonHeaderIconsProps {
  notificationsOff?: boolean;
  setOpenProfile?: React.Dispatch<React.SetStateAction<boolean>>;
  isClassNav?: boolean;
}

const CommonHeaderIcons: React.FC<CommonHeaderIconsProps> = ({
  notificationsOff,
  setOpenProfile,
  isClassNav = false,
}) => {
  const { fetched } = useAppSelector(commonState);
  const studentLogsCache = useAppSelector(studentAppStudentLogsState);
  const { posted, posting } = studentLogsCache;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { currentUser, isCurrentRoles, loading } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();
  const muiTheme = useTheme();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const isTabletView = useMediaQuery(muiTheme.breakpoints.down("md"));
  const [anchorProfile, setAnchorProfile] = useState<null | HTMLElement>(null);
  const [openTeacherSettings, setOpenTeacherSettings] =
    useState<null | HTMLElement>(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [menuModal, setMenuModal] = useState(false);
  const showNotification = !notificationsOff && currentUser?.current_role;
  const hasNewMessage = !!currentUser?.new_notification;
  const [googleDriveIsConnected, setGoogleDriveIsConnected] = useState(false);
  const [microsoftDriveIsConnected, setMicrosoftDriveIsConnected] =
    useState(false);

  const [modalState, setModalState] = useState<StudyLogsModalTypes>({
    open: false,
    modalState: StudyLogsModalState.Idle,
    step: 0,
  });
  const { isOwner, isTeacher, isStudent, isReviewer } = isCurrentRoles;
  const isSchoolRole = isOwner || isTeacher || isStudent;
  const { t } = useTranslation(["common", "class_nav", "modal", "header_menu"]);
  const { newCommentCount } = useAppSelector(inquiryState);
  const { canUseOneDrive, canUseGoogleDrive } = useCloudRoleAndFeature();
  const { isClassNavFeature } = useOptionFeature();
  const {
    newLimitedTimeNotificationCount,
    newNotificationCount,
    notifications,
    newNotificationCountResult,
  } = useNewNotificationCount(notificationsOff);

  const { postNotifications, postNotificationsResult } = useNotification();

  const mobileView = () => {
    if (isMobileView) {
      return classes.smallIcon;
    }
    return classes.iconFullSize;
  };

  const getCommonAffiliates = () => {
    dispatch(CommonActions.getCommonAffiliates({}));
  };

  const changeNewNotificationState = () => {
    dispatch(AuthActions.changeNewNotification());
  };

  const [checkGoogleConnected] =
    remainingApi.endpoints.getTtGoogleDrivesConnectionAsCommon.useLazyQuery();
  const checkAndSetGoogleConnected = async () => {
    if (canUseGoogleDrive) {
      const result = (await checkGoogleConnected({})).data?.has_token;
      setGoogleDriveIsConnected(!!result);
    }
  };

  const [checkMicrosoftConnected] =
    remainingApi.endpoints.getTtMicrosoftDrivesConnectionAsCommon.useLazyQuery();
  const checkAndSetMicrosoftConnected = async () => {
    if (canUseOneDrive) {
      const result = (await checkMicrosoftConnected({})).data?.has_token;
      setMicrosoftDriveIsConnected(!!result);
    }
  };

  let avatarPart = <ProfileIcon className={mobileView()} />;
  if (currentUser) {
    if (currentUser.avatar.url) {
      avatarPart = (
        <Avatar src={currentUser.avatar.url} alt={currentUser.full_name} />
      );
    }
  }

  const handleStudyLogModal = () => {
    setModalState({
      open: true,
      modalState: StudyLogsModalState.Create,
      dailyInfos: undefined,
      step: 3,
    });
  };

  const handleClose = () => {
    setModalState({
      open: false,
      dailyInfos: undefined,
      modalState: StudyLogsModalState.Idle,
      step: 0,
    });
  };

  const handleAnchorClose = () => {
    setAnchorProfile(null);
    setOpenTeacherSettings(null);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleMenuModal = (isModalOpen: boolean) => {
    setMenuModal(isModalOpen);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.id === "user-menu") {
      setAnchorProfile(e.currentTarget);
      if (!fetched) {
        getCommonAffiliates();
      }
      checkAndSetGoogleConnected();
      checkAndSetMicrosoftConnected();
    }

    if (e.currentTarget.id === "user-notification") {
      setOpenNotifications(true);
    }
    if (e.currentTarget.id === "setting-menu") {
      setOpenTeacherSettings(e.currentTarget);
    }
  };

  useEffect(() => {
    if (
      currentUser &&
      pathname !== "/affiliates" &&
      (isSchoolRole || isReviewer) &&
      !loading
    ) {
      dispatch(getNumberOfCommentInquiries(currentUser.current_role));
      checkAndSetGoogleConnected();
      checkAndSetMicrosoftConnected();
    }
  }, [currentUser, loading]);

  useEffect(() => {
    if (posted) {
      handleClose();
      history.push({
        pathname: STUDENT_ROUTES.STUDENT_LOGS,
        state: {
          detail: "student_logs",
        },
      });
    }
  }, [posted]);

  const handleUserName = () => {
    if (!currentUser) return "";
    const role = `(${t(currentUser.current_role)})`;
    const commonNameAndRole = `${currentUser.full_name} ${role}`;
    if (isStudent) {
      if (currentUser.nickname) return `${currentUser.nickname} ${role}`;
      return commonNameAndRole;
    }
    return commonNameAndRole;
  };

  const userNameAndRole = handleUserName();
  const defaultMenuItems = useDefaultMenuItems({
    googleDriveIsConnected,
    microsoftDriveIsConnected,
    setOpenProfile: setOpenProfile ?? undefined,
    checkAndSetGoogleConnected,
    setAnchorProfile,
    checkAndSetMicrosoftConnected,
  });
  const teacherSettingsMenuItems = useTeacherSettingsMenuItems(t);

  return (
    <>
      {showNotification && (
        <Notifications
          open={openNotifications}
          hasNewMessage={hasNewMessage}
          handleClose={handleNotificationsClose}
          setOpenNotifications={setOpenNotifications}
          changeNewNotificationState={changeNewNotificationState}
        />
      )}
      {showNotification && !isCurrentRoles.isSuperOwner && (
        <NewNotificationsModal
          hasNewMessage={hasNewMessage}
          changeNewNotificationState={changeNewNotificationState}
          postNotifications={postNotifications}
          newLimitedTimeNotificationCount={newLimitedTimeNotificationCount}
          newNotificationCount={newNotificationCount}
          setOpenNotifications={setOpenNotifications}
          postNotificationsIsLoading={postNotificationsResult.isLoading}
          notifications={notifications}
          newNotificationCountResult={newNotificationCountResult}
        />
      )}
      <CreateLog
        handleClose={handleClose}
        modalState={modalState}
        posting={posting}
        posted={posted}
      />
      {!isClassNav && (
        <MobileHeaderMenu
          handleClose={() => handleMenuModal(false)}
          open={menuModal}
        />
      )}
      <Grid
        container
        item
        direction={isMobileView ? "column-reverse" : "row"}
        justifyContent={isMobileView ? "center" : "space-between"}
        alignItems={isMobileView ? "flex-end" : "center"}
      >
        {isSchoolRole && !isMobileView ? (
          <Box ml={3} className={classes.companySection}>
            <DomainIcon />
            <Typography>{currentUser?.company_name}</Typography>
          </Box>
        ) : (
          <Box />
        )}
        <div className={classes.iconWrap}>
          {!isMobileView && (
            <Typography
              variant={isMobileView ? "caption" : "body2"}
              className={classes.userName}
            >
              {userNameAndRole}
            </Typography>
          )}
          {isTeacher && isClassNavFeature && !notificationsOff && (
            <StyledTooltip title={t("settings")}>
              <IconButton
                id="setting-menu"
                className={clsx(
                  classes.iconButton,
                  isTabletView && classes.lastIcon,
                )}
                aria-controls="setting-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
              >
                <SettingsIcon className={mobileView()} />
              </IconButton>
            </StyledTooltip>
          )}
          {!notificationsOff && (
            <StyledTooltip title={t("notifications")}>
              <IconButton
                id="user-notification"
                data-cy="header-notification-button"
                aria-controls="user-notification"
                className={classes.iconButton}
                onClick={handleClick}
                size="large"
              >
                <Badge
                  color="error"
                  overlap="circular"
                  badgeContent={
                    newLimitedTimeNotificationCount + newNotificationCount
                  }
                  variant={isMobileView ? "dot" : "standard"}
                >
                  <NotificationIcon className={mobileView()} />
                </Badge>
              </IconButton>
            </StyledTooltip>
          )}
          <SupportButton inquiryCount={newCommentCount} />
          {isStudent &&
            currentUser?.company_authorization?.study_log_feature && (
              <StyledTooltip title={t("recordTime")}>
                <IconButton
                  id="user-logs"
                  aria-controls="user-logs"
                  className={classes.iconButton}
                  onClick={handleStudyLogModal}
                  size="large"
                >
                  <GreenWatch className={mobileView()} />
                </IconButton>
              </StyledTooltip>
            )}
          <StyledTooltip title={t("myPage")}>
            <IconButton
              id="user-menu"
              data-cy="user-menu"
              // Remove margin right in tablet view to evenly space header
              className={clsx(
                classes.iconButton,
                isTabletView && classes.lastIcon,
              )}
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={handleClick}
              size="large"
            >
              {avatarPart}
            </IconButton>
          </StyledTooltip>
          {isStudent && (isMobileView || isTabletView) && !isClassNav && (
            <IconButton
              data-cy="mobile-menu"
              id="menu"
              aria-controls="menu"
              className={classes.iconButton}
              onClick={() => handleMenuModal(true)}
              size="large"
            >
              <MenuIcon className={mobileView()} />
            </IconButton>
          )}
          <CustomMenu
            menuItems={defaultMenuItems as MenuItemProps[]}
            id="user-menu"
            anchorEl={anchorProfile}
            keepMounted
            open={Boolean(anchorProfile)}
            onClose={handleAnchorClose}
            customElement={
              <div key="logout">
                <Divider sx={{ opacity: "0.6" }} />
                <Languages />
              </div>
            }
            isLoading={!fetched}
          />
          <CustomMenu
            menuItems={teacherSettingsMenuItems as MenuItemProps[]}
            id="setting-menu"
            anchorEl={openTeacherSettings}
            keepMounted
            open={Boolean(openTeacherSettings)}
            onClose={handleAnchorClose}
            customElement={<CalendarTab t={t} />}
          />
        </div>
      </Grid>
    </>
  );
};

CommonHeaderIcons.defaultProps = {
  notificationsOff: undefined,
  setOpenProfile: undefined,
  isClassNav: false,
};

export default CommonHeaderIcons;
