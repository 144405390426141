/* eslint-disable camelcase */
import { Api, FetchCompanyTagsAsSuperOwnerParams } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { DeleteTagParams,PostTagParams, UpdateTagParams } from "../types/tag";

const api = new Api();

export const fetchCompanyTagsAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/company_tags/fetch",
  async (params: FetchCompanyTagsAsSuperOwnerParams, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.fetchCompanyTagsAsSuperOwner(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSuperOwnerCompanyTag = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/company_tags/post",
  async (data: PostTagParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.createCompanyTagAsSuperOwner({
        name: data.name,
        company_tag_type_id: data.tagTypeId,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSuperOwnerCompanyTag = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/company_tags/update",
  async (data: UpdateTagParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.updateCompanyTagAsSuperOwner(
        data.id,
        { name: data.name },
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSuperOwnerCompanyTag = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/company_tags/delete",
  async (data: DeleteTagParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.deleteCompanyTagAsSuperOwner(
        data.id,
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface SaveCompanyTagSequenceParams {
  sequences: Array<{
    company_tag_id: string;
    sequence: number;
  }>;
}

export const saveCompanyTagSequence = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companyTag/saveSequence",
  async (data: SaveCompanyTagSequenceParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.companyTagsSequenceUpdate(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction("super_owner/companyTags/resetState");
