import { disableScroll, resetScrollState } from "@actions/scroll";
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

export interface ScrollState {
  scrollDisabled: boolean;
}

const initialState: ScrollState = {
  scrollDisabled: false,
};

const scrollSlice = createSlice({
  name: "ScrollToTop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(disableScroll, (state) => {
        return {
          ...state,
          scrollDisabled: true,
        };
      })
      .addCase(resetScrollState, (state) => {
        return {
          ...state,
          scrollDisabled: false,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const scrollState = (state: RootState): ScrollState => state.scrollTop;

export default scrollSlice.reducer;
