/* eslint-disable camelcase */
import {
  fileUploaded,
  updatedMaterialWork,
} from "@actions/studentApp/materialWork";
import { fixFileExtension } from "@lib/file-utils";
import { baseUrl, setRTKQueryHeaders } from "@lib/http";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { UpdateImageArg, UploadImageArg } from "../type";

interface SaveImageArg {
  id: string;
  params: {
    image_content_id: string;
    file: File;
  };
}

export const studentAppMaterialWorkApi = createApi({
  reducerPath: "studentApp/materialWorkApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/student/`,
    prepareHeaders: (headers) => setRTKQueryHeaders(headers),
  }),
  endpoints: (build) => ({
    uploadFiles: build.mutation<any, UploadImageArg>({
      query: (arg: UploadImageArg) => {
        const { id, params } = arg;
        const fd = new FormData();
        params.files.forEach((file) => {
          fd.append("files[]", file);
        });
        return {
          url: `material_works/${id}/upload_images`,
          method: "POST",
          body: fd,
        };
      },
      transformResponse: (response: any) => response,
      async onQueryStarted(arg: any, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(fileUploaded(data));
      },
    }),
    saveFile: build.mutation<any, SaveImageArg>({
      query: (arg: SaveImageArg) => {
        const { id, params } = arg;
        const fd = new FormData();

        const fixedFile = fixFileExtension(params.file);

        fd.append("file", fixedFile);
        fd.append("image_content_id", params.image_content_id);
        return {
          url: `material_works/${id}/save_image`,
          method: "POST",
          body: fd,
        };
      },
      transformResponse: (response: any) => response,
      async onQueryStarted(arg: any, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updatedMaterialWork(data));
      },
    }),
    updateFile: build.mutation<any, UpdateImageArg>({
      query: (arg: UpdateImageArg) => {
        const { id, params } = arg;
        const fd = new FormData();
        fd.append("file", params.file);
        fd.append("image_id", params.imageId);
        return {
          url: `material_works/${id}/update_image`,
          method: "PUT",
          body: fd,
        };
      },
      transformResponse: (response: any) => response,
      async onQueryStarted(arg: any, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updatedMaterialWork(data));
      },
    }),
  }),
});

export const {
  useUploadFilesMutation,
  useSaveFileMutation,
  useUpdateFileMutation,
} = studentAppMaterialWorkApi;
