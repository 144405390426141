import { configureStore } from "@reduxjs/toolkit";

import { SVImageReducer } from "./SVImageSlice";

const reducer = {
  SVImage: SVImageReducer,
};

const store = configureStore({
  reducer,
});

export default store;
