import { CommonStyledTableThCell } from "@components/UI/organisms/Tables/TableComponents";
import { TableHead, TableRow } from "@mui/material";
import React from "react";

const TableHeader: React.FC = () => {
  const headCells = [
    { id: "package_name", label: "プログラム名", numeric: false },
    { id: "creation_date_", label: "作成日時", numeric: true },
  ].filter(Boolean);
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CommonStyledTableThCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
          >
            {headCell.label}
          </CommonStyledTableThCell>
        ))}
        <CommonStyledTableThCell />
        <CommonStyledTableThCell />
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
