import DeleteIcon from "@assets/images/svg/trash-icon-green.svg?react";
import {
  StudyLogsModalState,
  StudyLogsModalTypes,
} from "@components/Teacher/StudentLogs";
import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import {
  HoverClickableAdminTableRow,
  StyledTableCell,
} from "@components/UI/organisms/Tables/TableComponents";
import { COLORS } from "@constants/pieChartColors";
import { roles } from "@constants/roles";
import { useAuth } from "@contexts/Auth";
import { DailyLogCategoryJa } from "@lib/Api";
import isTouchDevice from "@lib/responsive-utils";
import { fullName, hasFirstAndLastName } from "@lib/user/name-utils";
import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DailyLogBase } from "@root/endpoints/TimeTactApi/teacherApi";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  dailyLogInfo: DailyLogBase;
  setModalState: React.Dispatch<React.SetStateAction<StudyLogsModalTypes>>;
  modalState: StudyLogsModalTypes;
};

const getCategoryStyleColor = (logCategory?: string) => {
  switch (logCategory) {
    case DailyLogCategoryJa.theme_settings:
      return COLORS[0];
    case DailyLogCategoryJa.analysis:
      return COLORS[1];
    case DailyLogCategoryJa.research:
      return COLORS[2];
    case DailyLogCategoryJa.summary:
      return COLORS[3];
    default:
      return COLORS[0];
  }
};

type CategoryProps = {
  categoryColor: string;
};

const useStyles = makeStyles<Theme, CategoryProps>((theme: Theme) =>
  createStyles({
    categoryContainer: {
      display: "flex",
      alignItems: "center",
    },
    categoryPoint: {
      fontWeight: 700,
      fontSize: 30,
      color: (props) => props.categoryColor,
    },
    rigthSection: {
      display: "flex",
    },
  }),
);

const DailyStudyLogItem: React.FC<Props> = ({
  dailyLogInfo,
  setModalState,
  modalState,
}) => {
  const categoryColor = getCategoryStyleColor(dailyLogInfo.category);
  const classes = useStyles({ categoryColor });
  const { currentUser, isCurrentRoles } = useAuth();
  const { t } = useTranslation(["study_logs"]);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleDelete = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setModalState({
      open: true,
      step: 4,
      modalState: StudyLogsModalState.Delete,
      dailyInfos: dailyLogInfo,
    });
  };
  const handleOpenDetailModal = () => {
    setModalState({
      step: 2,
      open: true,
      modalState: StudyLogsModalState.Details,
      dailyInfos: dailyLogInfo,
      lastWeek: modalState.lastWeek,
    });
  };

  const deleteButton = (
    <>
      {currentUser && currentUser.current_role === roles.STUDENT && (
        <Box onClick={(e) => handleDelete(e)}>
          <StyledTooltip title={t("deleteLog")} aria-label="delete-tag-type">
            <DeleteIcon />
          </StyledTooltip>
        </Box>
      )}
    </>
  );

  return (
    <HoverClickableAdminTableRow
      onClick={handleOpenDetailModal}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledTableCell scope="row">
        {dayjs(dailyLogInfo.date).format(
          `YYYY${t("year")}MM${t("month")}DD${t("day")}`,
        )}
      </StyledTableCell>
      {isCurrentRoles.isTeacher && (
        <StyledTableCell scope="row">
          {fullName(dailyLogInfo.student as hasFirstAndLastName)}
        </StyledTableCell>
      )}
      <StyledTableCell align="left">
        <Box className={classes.categoryContainer}>
          <Typography className={classes.categoryPoint}>・</Typography>
          {t(dailyLogInfo.category_status_code as string)}
        </Box>
      </StyledTableCell>
      <StyledTableCell align="left">{dailyLogInfo.study_time}</StyledTableCell>
      <StyledTableCell align="left">{dailyLogInfo.memo}</StyledTableCell>
      {currentUser && currentUser.current_role === roles.STUDENT && (
        <StyledTableCell align="center">
          <Box width={15}>
            {isHovered || isTouchDevice() ? deleteButton : undefined}
          </Box>
        </StyledTableCell>
      )}
    </HoverClickableAdminTableRow>
  );
};

export default DailyStudyLogItem;
