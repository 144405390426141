import {
  StudyLogsModalState,
  StudyLogsModalTypes,
} from "@components/Teacher/StudentLogs";
import { FormCategoryTitle } from "@components/UI/atoms/Forms/FormComponents";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import { useAuth } from "@contexts/Auth";
import { fullName, hasFirstAndLastName } from "@lib/user/name-utils";
import { Container,Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  modalState: StudyLogsModalTypes;
  setModalState: React.Dispatch<React.SetStateAction<StudyLogsModalTypes>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      marginBottom: theme.spacing(3),
    },
    message: {
      padding: theme.spacing(2),
    },
  }),
);

const DailyLogsDetailModal: React.FC<Props> = ({
  modalState,
  setModalState,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("study_logs");
  const { isCurrentRoles } = useAuth();
  const { open, modalState: status, dailyInfos, lastWeek } = modalState;
  const handleDetailClose = () => {
    setModalState({
      open: true,
      step: 1,
      modalState: StudyLogsModalState.Main,
      lastWeek,
      dailyInfos,
    });
  };

  const handleEditOpen = () => {
    setModalState({
      open: true,
      step: 3,
      modalState: StudyLogsModalState.Edit,
      lastWeek,
      dailyInfos,
    });
  };
  let mainContent: ReactNode;

  if (dailyInfos) {
    mainContent = (
      <>
        <FormCategoryTitle>{t("studyDate")}</FormCategoryTitle>
        <Typography>
          {dayjs(dailyInfos.date).format(
            `YYYY${t("year")}MM${t("month")}DD${t("day")}`,
          )}
        </Typography>
        {isCurrentRoles.isTeacher && (
          <>
            <FormCategoryTitle>{t("studentName")}</FormCategoryTitle>
            <Typography>
              {fullName(dailyInfos.student as hasFirstAndLastName)}
            </Typography>
          </>
        )}
        <FormCategoryTitle>{t("studyCategory")}</FormCategoryTitle>
        <Typography>{dailyInfos.category}</Typography>
        <FormCategoryTitle>{t("studyTime")}</FormCategoryTitle>
        <Typography>{dailyInfos.study_time}</Typography>
        <FormCategoryTitle>{t("memo")}</FormCategoryTitle>
        <Typography>{dailyInfos.memo}</Typography>
      </>
    );
  } else {
    mainContent = (
      <Typography className={classes.message}>{t("noStudyData")}。</Typography>
    );
  }

  return (
    <GenericModal
      open={open && status === StudyLogsModalState.Details}
      handleClose={handleDetailClose}
      title={t("studyLogDetails")}
      useActionButtons
      primaryButtonText={isCurrentRoles.isStudent ? t("update") : t("close")}
      primaryButtonAction={() =>
        isCurrentRoles.isStudent ? handleEditOpen() : handleDetailClose()
      }
      secondaryButtonAction={handleDetailClose}
      secondaryButtonText={isCurrentRoles.isStudent ? t("cancel") : undefined}
      useSecondaryButton={isCurrentRoles.isStudent}
    >
      <Container className={classes.main}>{mainContent}</Container>
    </GenericModal>
  );
};

export default DailyLogsDetailModal;
