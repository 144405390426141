import BackArrowIcon from "@assets/images/svg/back-arrow-sheet-white.svg?react";
import FileUploadIcon from "@assets/images/svg/file_upload.svg?react";
import FrameIcon from "@assets/images/svg/frame_icon.svg?react";
import NextOutlinedIcon from "@assets/images/svg/next_green_icon.svg?react";
import NextIcon from "@assets/images/svg/next_icon.svg?react";
import NextRightIcon from "@assets/images/svg/next_icon_right.svg?react";
import NextContainedIcon from "@assets/images/svg/next_white_icon.svg?react";
import TrashIcon from "@assets/images/svg/trashIconWithoutLine.svg?react";
import UpdatingIcon from "@assets/images/svg/update.svg?react";
import UpdatingContainedIcon from "@assets/images/svg/updating-icon.svg?react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RemoveCircleOutlineRounded from "@mui/icons-material/RemoveCircleOutlineRounded";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import RightArrowIcon from "@root/assets/images/svg/startIcon.svg?react";
import React, { ReactNode } from "react";
import { BsFillSendFill } from "react-icons/bs";
import { ImFloppyDisk } from "react-icons/im";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButtonWhite: {
      color: theme.palette.common.white,
      width: "16px",
      height: "16px",
    },
    strokeWhite: {
      stroke: theme.palette.common.white,
    },
    returnBlackArrow: {
      fill: theme.palette.common.black,
      stroke: theme.palette.common.white,
    },
    gray: {
      "& path": {
        fill: theme.palette.tertiary.darkGrey,
      },
    },
    disableGray: {
      stroke: theme.palette.common.white,
      "& > path": {
        fill: theme.palette.customColor.darkGray,
      },
    },
    disableWhite: {
      "& > path": {
        stroke: theme.palette.customColor.darkGray,
      },
    },
  }),
);

export enum ButtonIconType {
  Trash = "trash",
  FloppyDisk = "floppyDisk",
  Cancel = "cancel",
  Plus = "plus",
  Update = "update",
  UpdateContained = "updateContained",
  UpdateGray = "updateGray",
  CancelWhite = "cancelWhite",
  BackArrow = "backArrow",
  Next = "next",
  FileUpload = "fileUpload",
  FileUploadGray = "fileUploadGray",
  NextRight = "nextRight",
  NextRightGray = "nextRightGray",
  ReturnBlackArrow = "returnBlackArrow",
  Minus = "minus",
  Download = "download",
  DownloadGray = "downloadGray",
  Check = "check",
  Upload = "upload",
  Settings = "settings",
  ReturnContained = "returnContained",
  ReturnOutlined = "returnOutlined",
  Edit = "edit",
  Search = "search",
  Frame = "frame",
  Publish = "publish",
  SEND = "send",
  Notification = "notification",
}
export function GetButtonIconType(buttonIconType: ButtonIconType): ReactNode {
  const classes = useStyles();

  switch (buttonIconType) {
    case "trash":
      return <TrashIcon />;
    case "cancel":
      return <CancelIcon />;
    case "floppyDisk":
      return <ImFloppyDisk size={14} />;
    case "plus":
      return <AddCircleOutlinedIcon />;
    case "update":
      return <UpdatingIcon />;
    case "updateContained":
      return <UpdatingContainedIcon />;
    case "updateGray":
      return <UpdatingContainedIcon className={classes.gray} />;
    case "cancelWhite":
      return <CancelIcon className={classes.cancelButtonWhite} />;
    case "backArrow":
      return <BackArrowIcon />;
    case "next":
      return <NextIcon />;
    case "fileUpload":
      return <FileUploadIcon className={classes.strokeWhite} />;
    case "fileUploadGray":
      return <FileUploadIcon className={classes.disableGray} />;
    case "nextRight":
      return <NextRightIcon />;
    case "nextRightGray":
      return <NextRightIcon className={classes.disableWhite} />;
    case "returnBlackArrow":
      return <NextIcon className={classes.returnBlackArrow} />;
    case "minus":
      return <RemoveCircleOutlineRounded />;
    case "download":
      return <DownloadRoundedIcon />;
    case "downloadGray":
      return <DownloadRoundedIcon className={classes.gray} />;
    case "check":
      return <CheckCircleOutlineRoundedIcon />;
    case "upload":
      return <FileUploadRoundedIcon />;
    case "settings":
      return <SettingsIcon />;
    case "returnContained":
      return <NextContainedIcon />;
    case "returnOutlined":
      return <NextOutlinedIcon />;
    case "edit":
      return <EditIcon />;
    case "search":
      return <SearchOutlinedIcon />;
    case "frame":
      return <FrameIcon />;
    case "publish":
      return <RightArrowIcon />;
    case "send":
      return <BsFillSendFill style={{ color: "white", fontSize: "16px" }} />;
    case "notification":
      return <NotificationsIcon />;
    default:
      return "";
  }
}
