/* eslint-disable simple-import-sort/imports */

/**
 * Because of the bug with MUI, if we change the order of import from @mui/material, we will receive errors.
 * So, turn off simple-import-sort/imports in this file
 */

import "core-js/stable";
import "@fontsource/roboto";
// https://github.com/import-js/eslint-plugin-import/issues/2266
import "swiper/swiper-bundle.min.css";
import "./index.css";
import "dayjs/locale/ja";

import { CssBaseline } from "@mui/material";

import ScrollToTop from "@components/Common/ScrollToTop";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";

import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { jaJP } from "@mui/x-date-pickers/locales";
import { config } from "@root/constants/env";
import actionCable, { Cable } from "actioncable";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import * as historyObject from "history";
import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import AuthProvider from "./contexts/Auth";
import i18n from "./i18n/config";
import { InjectAxiosInterceptors } from "./lib/injectInterceptors";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import theme from "./styles/theme";

ReactGA.initialize("G-MZCL1JZZN9");
const history = historyObject.createBrowserHistory();
history.listen((pathname) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname.location.pathname);
});

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const cableConsumer: Cable = actionCable.createConsumer("ws://");
export default cableConsumer;

export function useTheme(): Theme {
  return theme;
}

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={config.sitekey.recaptchaV3}
      language="ja"
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<CircularLoading />}>
            <Provider store={store}>
              <HelmetProvider>
                <SnackbarProvider maxSnack={3}>
                  <AuthProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                      localeText={
                        jaJP.components.MuiLocalizationProvider.defaultProps
                          .localeText
                      }
                    >
                      <DndProvider backend={HTML5Backend}>
                        <BrowserRouter>
                          <ScrollToTop>
                            <InjectAxiosInterceptors />
                            <App />
                          </ScrollToTop>
                        </BrowserRouter>
                      </DndProvider>
                    </LocalizationProvider>
                  </AuthProvider>
                </SnackbarProvider>
              </HelmetProvider>
            </Provider>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
