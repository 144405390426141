/* eslint-disable camelcase */
import getSteamRecommendationAsStudent from "@actions/studentApp/steam/steamRecommendations";
import { SteamLibraryContentCard } from "@actions/types/steamLibrary";
import { ReduxStatus,ReduxStatusType } from "@constants/redux";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface StudentAppSteamRecommendationState {
  steamRecommendations: SteamLibraryContentCard[];
  totalCount: number;
  fetch: ReduxStatusType;
  fetchError: string | null;
}

const initialState: StudentAppSteamRecommendationState = {
  fetch: ReduxStatus.idle,
  steamRecommendations: [],
  fetchError: null,
  totalCount: 0,
};

const studentAppSteamRecommendationSlice = createSlice({
  name: "StudentApp/SteamLectureHistories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSteamRecommendationAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetch: ReduxStatus.pending,
        };
      })
      .addCase(getSteamRecommendationAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          fetch: ReduxStatus.fulfilled,
          steamRecommendations: action.payload.SteamContentRecommendations,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(getSteamRecommendationAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetch: ReduxStatus.rejected,
          fetchError: getErrorMessage(action.payload.errors),
        };
      });
  },
});

export const StudentAppSteamRecommendationsState = (
  state: RootState,
): StudentAppSteamRecommendationState => state.studentApp.steamRecommendations;

export default studentAppSteamRecommendationSlice.reducer;
