import NavigationMenuSkelton from "@components/UI/organisms/Navigation/Common/NavigationMenuSkelton";
import {
  Box,
  MenuItem,
  Popover,
  PopoverProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export type MenuItemProps = {
  key: string;
  title: string | JSX.Element;
  description?: string;
  helperText?: string;
  icon?: JSX.Element;
  dataCy?: string | undefined;
  isHidden?: boolean;
  action: (e?: any) => void;
  disabled?: boolean;
};

export type CustomMenuProps = PopoverProps & {
  menuItems: MenuItemProps[];
  customElement?: JSX.Element;
  dataCy?: string | undefined;
  isLoading?: boolean;
};

type StyleProps = {
  isMobileView: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    menuItemStyle: {
      width: "100%",
      minWidth: 0,
      "&:hover": {
        backgroundColor: theme.palette.quaternary.lightGreen,
        borderRadius: 8,
        boxShadow: `inset 0 0 0 1px ${theme.palette.tertiary.mainDark}`,
      },
    },
    textWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
      width: "300px",
      marginLeft: ({ isMobileView }) => (isMobileView ? 0 : theme.spacing(2)),
    },
    title: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    description: {
      fontSize: "14px",
      whiteSpace: "break-spaces",
    },
    helperText: {
      fontSize: "12px",
      color: theme.palette.customColor.text,
      opacity: 0.5,
    },
  }),
);

const CustomMenu: React.FC<CustomMenuProps> = ({
  menuItems,
  customElement,
  dataCy,
  isLoading,
  ...popoverProps
}) => {
  const muiTheme = useTheme();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const classes = useStyles({ isMobileView });

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          padding: 10,
          borderRadius: 8,
          marginTop: 8,
          backgroundColor: "white",
        },
      }}
      {...popoverProps}
      data-cy={dataCy || "menu-items"}
    >
      {isLoading ? (
        <NavigationMenuSkelton />
      ) : (
        <>
          {menuItems
            .filter((menuItem) => !menuItem.isHidden)
            .map((menuItem) => [
              <MenuItem
                data-cy={menuItem.dataCy || "custom-menu-item"}
                key={menuItem.key}
                className={classes.menuItemStyle}
                disabled={menuItem.disabled}
                onClick={(e) => {
                  popoverProps?.onClose?.(e, "backdropClick");
                  menuItem.action(e);
                }}
              >
                {!isMobileView && menuItem.icon}
                {menuItem.description ? (
                  <Box className={classes.textWrapper}>
                    <Box className={classes.title}>{menuItem.title}</Box>
                    <Box className={classes.description}>
                      {menuItem.description}
                    </Box>
                    <Box className={classes.helperText}>
                      {menuItem.helperText}
                    </Box>
                  </Box>
                ) : (
                  menuItem.title
                )}
              </MenuItem>,
            ])}
          {customElement}
        </>
      )}
    </Popover>
  );
};

export default CustomMenu;
