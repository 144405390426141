import {
  deleteCommonInquiryComment,
  fetchCommonInquiries,
  fetchCommonInquiryDetails,
  getNumberOfCommentInquiries,
  postCommonInquiry,
  postCommonInquiryComment,
  resetCommentFormState,
  resetState,
  updateCommonInquiryCommentBody,
} from "@actions/inquiry";
import { Inquiry } from "@actions/types/inquiry";
import { InquirySimpleBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

interface InquiryState {
  fetching: boolean;
  fetched: boolean;
  fetchError: string | null;
  inquiries: Inquiry[];
  inquiryDetails: InquirySimpleBase | null;
  totalCount: number;
  posting: boolean;
  posted: boolean;
  error: any;
  message: string | null;
  postingComment: boolean;
  postedComment: boolean;
  changingComment: boolean; // To be used for update, and deleting comments
  changedComment: boolean;
  commentError: any;
  commentMessage: string;
  newCommentCount: number;
}

const initialState: InquiryState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  inquiries: [],
  inquiryDetails: null,
  totalCount: 0,
  posting: false,
  posted: false,
  error: null,
  message: null,
  postingComment: false,
  postedComment: false,
  changingComment: false,
  changedComment: false,
  commentError: null,
  commentMessage: "",
  newCommentCount: 0,
};

export const inquirySlice = createSlice({
  name: "Inquiry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommonInquiries.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          inquiries: [],
          fetchError: null,
          inquiryDetails: null,
        };
      })
      .addCase(fetchCommonInquiries.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          inquiries: action.payload.inquiries,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchCommonInquiries.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(fetchCommonInquiryDetails.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          inquiryDetails: null,
          fetchError: null,
        };
      })
      .addCase(fetchCommonInquiryDetails.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          inquiryDetails: action.payload,
        };
      })
      .addCase(fetchCommonInquiryDetails.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(postCommonInquiry.pending, (state, action) => {
        return {
          ...state,
          posting: true,
          posted: false,
          error: null,
          message: null,
        };
      })
      .addCase(postCommonInquiry.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          message: action.payload.message,
          posted: true,
        };
      })
      .addCase(postCommonInquiry.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          error: action.error as string,
        };
      })
      .addCase(postCommonInquiryComment.pending, (state, action) => {
        return {
          ...state,
          postingComment: true,
          postedComment: false,
          commentError: null,
        };
      })
      .addCase(postCommonInquiryComment.fulfilled, (state, action) => {
        return {
          ...state,
          postingComment: false,
          postedComment: true,
          inquiryDetails: state.inquiryDetails
            ? {
                ...state.inquiryDetails,
                comments: [...state.inquiryDetails.comments, action.payload],
              }
            : null,
          commentMessage: "コメントを追加しました。",
        };
      })
      .addCase(postCommonInquiryComment.rejected, (state, action) => {
        return {
          ...state,
          postingComment: false,
          commentError: action.payload?.errors,
        };
      })
      .addCase(updateCommonInquiryCommentBody.pending, (state, action) => {
        return {
          ...state,
          changingComment: true,
          changedComment: false,
          commentError: null,
        };
      })
      .addCase(updateCommonInquiryCommentBody.fulfilled, (state, action) => {
        const comments =
          state.inquiryDetails?.comments.map((comment) =>
            comment.id !== action.payload.id ? comment : action.payload,
          ) || [];
        return {
          ...state,
          changingComment: false,
          changedComment: true,
          inquiryDetails: state.inquiryDetails
            ? {
                ...state.inquiryDetails,
                comments,
              }
            : null,
          commentMessage: "コメントを編集しました。",
        };
      })
      .addCase(updateCommonInquiryCommentBody.rejected, (state, action) => {
        return {
          ...state,
          changingComment: false,
          commentError: action.payload?.errors,
        };
      })
      .addCase(deleteCommonInquiryComment.pending, (state, action) => {
        return {
          ...state,
          changingComment: true,
          changedComment: false,
          commentError: null,
        };
      })
      .addCase(deleteCommonInquiryComment.fulfilled, (state, action) => {
        const comments =
          state.inquiryDetails?.comments.filter(
            (comment) => comment.id !== action.meta.arg.id,
          ) || [];
        return {
          ...state,
          changingComment: false,
          changedComment: true,
          inquiryDetails: state.inquiryDetails
            ? {
                ...state.inquiryDetails,
                comments,
              }
            : null,
          commentMessage: "コメントを削除しました。",
        };
      })
      .addCase(deleteCommonInquiryComment.rejected, (state, action) => {
        return {
          ...state,
          changingComment: false,
          commentError: action.payload?.errors,
        };
      })
      .addCase(getNumberOfCommentInquiries.fulfilled, (state, action) => {
        return {
          ...state,
          newCommentCount: action.payload.count,
        };
      })
      .addCase(resetCommentFormState, (state, action) => {
        return {
          ...state,
          postingComment: false,
          postedComment: false,
          changingComment: false,
          changedComment: false,
          commentError: null,
          commentMessage: "",
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          posting: false,
          posted: false,
          error: null,
          message: null,
        };
      });
  },
});

export const inquiryState = (state: RootState): InquiryState => state.inquiry;

export default inquirySlice.reducer;
