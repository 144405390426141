/* eslint-disable camelcase */
import { StyledTagColor } from "@components/UI/atoms/StyledTag";
import { roles } from "@constants/roles";
import { TAGS_HASH } from "@constants/Tag/tags";
import {
  TtCsvExportStatus,
  TtCsvExportType,
  TtCsvExportTypeJa,
  UserType,
} from "@lib/Api";
import { TFunction } from "react-i18next";

export const getTypeTrans = (typeEn: TtCsvExportType, t: TFunction): string => {
  const mappings = [
    {
      typeEn: TtCsvExportType.SuperOwnerCompanyList,
      typeJa: TtCsvExportTypeJa.SuperOwnerCompanyList,
      typeTranslate: t("super_owner_company_list"),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerOwnerList,
      typeJa: TtCsvExportTypeJa.SuperOwnerOwnerList,
      typeTranslate: t("super_owner_owner_list"),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerTeacherList,
      typeJa: TtCsvExportTypeJa.SuperOwnerTeacherList,
      typeTranslate: t("super_owner_teacher_list"),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerStudentList,
      typeJa: TtCsvExportTypeJa.SuperOwnerStudentList,
      typeTranslate: t("super_owner_student_list"),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerTagList,
      typeJa: TtCsvExportTypeJa.SuperOwnerTagList,
      typeTranslate: t("super_owner_tag_list"),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerCompanyTagList,
      typeJa: TtCsvExportTypeJa.SuperOwnerCompanyTagList.substring(
        TtCsvExportTypeJa.SuperOwnerCompanyTagList.indexOf("の") + 1,
      ),
      typeTranslate: t("super_owner_company_tag_list"),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerNationwideUserTagAndStudentList,
      typeJa:
        TtCsvExportTypeJa.SuperOwnerNationwideUserTagAndStudentList.substring(
          TtCsvExportTypeJa.SuperOwnerNationwideUserTagAndStudentList.indexOf(
            "の",
          ) + 1,
        ),
      typeTranslate: t("super_owner_nationwide_user_tag_and_student_list"),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerLimitedUserTagAndStudentList,
      typeJa:
        TtCsvExportTypeJa.SuperOwnerLimitedUserTagAndStudentList.substring(
          TtCsvExportTypeJa.SuperOwnerLimitedUserTagAndStudentList.indexOf(
            "の",
          ) + 1,
        ),
      typeTranslate: t("super_owner_limited_user_tag_and_student_list"),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerMaterialDistributionHistoryList,
      typeJa: TtCsvExportTypeJa.SuperOwnerMaterialDistributionHistoryList,
      typeTranslate: t("super_owner_material_distribution_history_list"),
    },
    {
      typeEn:
        TtCsvExportType.SuperOwnerLearningPackageDistributionHistoryDetail,
      typeJa:
        TtCsvExportTypeJa.SuperOwnerLearningPackageDistributionHistoryDetail,
      typeTranslate: t(
        "super_owner_learning_package_distribution_history_detail",
      ),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerHomeworkTemplateDistributionHistoryList,
      typeJa:
        TtCsvExportTypeJa.SuperOwnerHomeworkTemplateDistributionHistoryList,
      typeTranslate: t(
        "super_owner_homework_template_distribution_history_list",
      ),
    },
    {
      typeEn:
        TtCsvExportType.SuperOwnerHomeworkTemplateDistributionHistoryDetail,
      typeJa:
        TtCsvExportTypeJa.SuperOwnerHomeworkTemplateDistributionHistoryDetail,
      typeTranslate: t(
        "super_owner_homework_template_distribution_history_detail",
      ),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerCustomList,
      typeJa: TtCsvExportTypeJa.SuperOwnerCustomList,
      typeTranslate: t("super_owner_custom_list"),
    },
    {
      typeEn: TtCsvExportType.OwnerOwnerList,
      typeJa: TtCsvExportTypeJa.OwnerOwnerList,
      typeTranslate: t("owner_owner_list"),
    },
    {
      typeEn: TtCsvExportType.OwnerTeacherList,
      typeJa: TtCsvExportTypeJa.OwnerTeacherList,
      typeTranslate: t("owner_teacher_list"),
    },
    {
      typeEn: TtCsvExportType.OwnerStudentList,
      typeJa: TtCsvExportTypeJa.OwnerStudentList,
      typeTranslate: t("owner_student_list"),
    },
    {
      typeEn: TtCsvExportType.OwnerUserTagList,
      typeJa: TtCsvExportTypeJa.OwnerUserTagList,
      typeTranslate: t("owner_user_tag_list"),
    },
    {
      typeEn: TtCsvExportType.OwnerNationwideUserTagAndStudentList,
      typeJa: TtCsvExportTypeJa.OwnerNationwideUserTagAndStudentList.substring(
        TtCsvExportTypeJa.OwnerNationwideUserTagAndStudentList.indexOf("の") +
          1,
      ),
      typeTranslate: t("owner_nationwide_user_tag_and_student_list"),
    },
    {
      typeEn: TtCsvExportType.OwnerLimitedUserTagAndStudentList,
      typeJa: TtCsvExportTypeJa.OwnerLimitedUserTagAndStudentList.substring(
        TtCsvExportTypeJa.OwnerLimitedUserTagAndStudentList.indexOf("の") + 1,
      ),
      typeTranslate: t("owner_limited_user_tag_and_student_list"),
    },
    {
      typeEn: TtCsvExportType.OwnerHomeworkCommentList,
      typeJa: TtCsvExportTypeJa.OwnerHomeworkCommentList,
      typeTranslate: t("reviewer_homework_comment_list"),
    },
    {
      typeEn: TtCsvExportType.OwnerHomeworkTemplateCustomFieldList,
      typeJa: TtCsvExportTypeJa.OwnerHomeworkTemplateCustomFieldList,
      typeTranslate: t("teacher_homework_template_custom_field_list"),
    },
    {
      typeEn: TtCsvExportType.TeacherHomeworkTemplateCustomFieldList,
      typeJa: TtCsvExportTypeJa.TeacherHomeworkTemplateCustomFieldList,
      typeTranslate: t("teacher_homework_template_custom_field_list"),
    },
    {
      typeEn: TtCsvExportType.TeacherStudentList,
      typeJa: TtCsvExportTypeJa.TeacherStudentList,
      typeTranslate: t("teacher_student_list"),
    },
    {
      typeEn: TtCsvExportType.TeacherHomeworkCommentList,
      typeJa: TtCsvExportTypeJa.TeacherHomeworkCommentList,
      typeTranslate: t("reviewer_homework_comment_list"),
    },
    {
      typeEn: TtCsvExportType.SuperOwnerLearningPackageDistributionHistoryList,
      typeJa:
        TtCsvExportTypeJa.SuperOwnerLearningPackageDistributionHistoryList,
      typeTranslate: t(
        "super_owner_learning_package_distribution_history_list",
      ),
    },
    {
      typeEn: TtCsvExportType.OwnerUniversityTeacherList,
      typeJa: TtCsvExportTypeJa.OwnerUniversityTeacherList,
      typeTranslate: t("universityTeacherInfos"),
    },
    {
      typeEn: TtCsvExportType.AdminSponsorInfoList,
      typeJa: TtCsvExportTypeJa.AdminSponsorInfoList,
      typeTranslate: t("admin_sponsor_info_list"),
    },
    {
      typeEn: TtCsvExportType.ReviewerHomeworkTemplateCustomFieldList,
      typeJa: TtCsvExportTypeJa.ReviewerHomeworkTemplateCustomFieldList,
      typeTranslate: t("reviewer_homework_template_list"),
    },
    {
      typeEn: TtCsvExportType.ReviewerHomeworkCommentList,
      typeJa: TtCsvExportTypeJa.ReviewerHomeworkCommentList,
      typeTranslate: t("reviewer_homework_comment_list"),
    },
  ];

  const typeJa =
    mappings.find((mapping) => mapping.typeEn === typeEn)?.typeTranslate ??
    (TtCsvExportTypeJa.SuperOwnerCompanyList as string);
  return typeJa;
};

export const getFilterParam = (
  currentRole: string | undefined,
): [string, TtCsvExportTypeJa][] => {
  const allFilterEnums = Object.entries(TtCsvExportTypeJa);

  switch (currentRole) {
    case roles.SUPER_OWNER:
      return allFilterEnums.filter((item) => item[0].startsWith("SuperOwner"));
    case roles.OWNER:
      return allFilterEnums.filter((item) => item[0].startsWith("Owner"));
    case roles.TEACHER:
      return allFilterEnums.filter((item) => item[0].startsWith("Teacher"));
    case roles.ADMIN:
      return allFilterEnums.filter((item) => item[0].startsWith("Admin"));
    case roles.REVIEWER:
      return allFilterEnums.filter((item) => item[0].startsWith("Reviewer"));
    default:
      return allFilterEnums;
  }
};

export const getCsvExportStatusTagColor = (
  status: TtCsvExportStatus,
): StyledTagColor => {
  switch (status) {
    case TtCsvExportStatus.Completed:
      return StyledTagColor.BrightGreen;
    case TtCsvExportStatus.Failed:
      return StyledTagColor.Grey;
    case TtCsvExportStatus.Running:
      return StyledTagColor.Pink;
    default:
      return StyledTagColor.Grey;
  }
};

export const getCsvExportStatusTagLabel = (
  status: TtCsvExportStatus,
  t: TFunction,
): TtCsvExportStatus => {
  switch (status) {
    case TtCsvExportStatus.Completed:
      return t("completed");
    case TtCsvExportStatus.Failed:
      return t("failed");
    case TtCsvExportStatus.Running:
      return t("running");
    default:
      return t("running");
  }
};
export const getType = (
  detailView: string | undefined,
  role?: string | undefined,
): TtCsvExportType => {
  switch (detailView) {
    case TAGS_HASH.SCHOOL:
      return TtCsvExportType.SuperOwnerCompanyTagList;
    case TAGS_HASH.USER:
      return role === roles.SUPER_OWNER
        ? TtCsvExportType.SuperOwnerNationwideUserTagAndStudentList
        : TtCsvExportType.OwnerNationwideUserTagAndStudentList;
    case TAGS_HASH.LIMITED:
      return role === roles.SUPER_OWNER
        ? TtCsvExportType.SuperOwnerLimitedUserTagAndStudentList
        : TtCsvExportType.OwnerLimitedUserTagAndStudentList;
    case UserType.Student:
      return role === roles.SUPER_OWNER
        ? TtCsvExportType.SuperOwnerStudentList
        : TtCsvExportType.OwnerStudentList;
    case UserType.Teacher:
      return role === roles.SUPER_OWNER
        ? TtCsvExportType.SuperOwnerTeacherList
        : TtCsvExportType.OwnerTeacherList;
    case UserType.Owner:
      return role === roles.SUPER_OWNER
        ? TtCsvExportType.SuperOwnerOwnerList
        : TtCsvExportType.OwnerOwnerList;
    default:
      return TtCsvExportType.SuperOwnerOwnerList;
  }
};
