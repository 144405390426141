/* eslint-disable camelcase */
import { Api, StudentInputSetting } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export type StudentCompany = {
  id: string;
  name: string;
  postcode: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
  student_input_settings?: StudentInputSetting;
};

// eslint-disable-next-line import/prefer-default-export
export const getStudentCompany = createAsyncThunk<
  StudentCompany,
  string,
  { state: RootState; rejectWithValue: any }
>("studentApp/getStudentCompanies", async (id, { rejectWithValue }) => {
  try {
    const response = await api.student.getStudentCompaniesCompanyId(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
