import {
  createSubmitHomeworkFilesRequestAsReviewer,
  fetchSubmitHomeworkFilesRequestAsReviewer,
  resetModalState,
} from "@actions/reviewerApp/submitHomeworksReviewerRequest";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface ReviewerAppSubmitHomeworkFilesRequestState {
  fetching: boolean;
  creating: boolean;
  created: boolean;
  createError: string | null;
  requests: any;
}

export const initialState: ReviewerAppSubmitHomeworkFilesRequestState = {
  fetching: false,
  creating: false,
  created: false,
  createError: null,
  requests: [],
};

const reviewerAppSubmitHomeworkFilesRequestSlice = createSlice({
  name: "reviewerApp/SubmitHomeworkFilesRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchSubmitHomeworkFilesRequestAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            fetchError: null,
          };
        },
      )
      .addCase(
        fetchSubmitHomeworkFilesRequestAsReviewer.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            fetched: true,
            requests: payload.requests,
            fetchError: null,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        fetchSubmitHomeworkFilesRequestAsReviewer.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            fetchError: action.error.message as string,
          };
        },
      )
      .addCase(
        createSubmitHomeworkFilesRequestAsReviewer.pending,
        (state, _action) => {
          return {
            ...state,
            creating: true,
            created: false,
            fetchError: null,
          };
        },
      )
      .addCase(
        createSubmitHomeworkFilesRequestAsReviewer.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currenState = current(state);
          return {
            ...state,
            creating: false,
            created: true,
            fetchError: null,
            requests: [payload, ...currenState.requests],
          };
        },
      )
      .addCase(
        createSubmitHomeworkFilesRequestAsReviewer.rejected,
        (state, _action) => {
          return {
            ...state,
            fetching: false,
            fetchError: null,
          };
        },
      )
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          createdError: null,
        };
      });
  },
});

export const reviewerAppSubmitHomeworkFilesRequestState = (
  state: RootState,
): ReviewerAppSubmitHomeworkFilesRequestState =>
  state.reviewerApp.submitHomeworksFilesRequest;

export default reviewerAppSubmitHomeworkFilesRequestSlice.reducer;
