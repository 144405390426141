import {
  fetchArticleAsTeacher,
  fetchArticlesAsTeacher,
  fetchArticlesStatsAsTeacher,
  fetchWordCloudDataAsTeacher,
  getArticleSubmitAsTeacher,
  resetArticleRelatedData,
  resetModalState,
  updateArticleAsTeacher,
} from "@actions/teacherApp/article";
import { fetchArticleCommentsAsTeacher } from "@actions/teacherApp/articleComment";
import {
  createArticleFeedbackAsTeacher,
  deleteArticleFeedbackAsTeacher,
  fetchArticleFeedbacksAsTeacher,
  resetDeleteFeedbackRelatedState,
  resetFeedbackRelatedFormState,
} from "@actions/teacherApp/articleFeedback";
import {
  ArticleAsTeacher,
  ArticleBase,
  ArticleComment,
  ArticleFeedback,
  ArticleStatsBase,
  ArticleSubmitBase,
  WordCloudWord,
} from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";

import { RootState } from "../../store";

export interface TeacherAppArticleState {
  updating: boolean;
  updated: boolean;
  changeError: any; // updateError
  fetching: ReduxStatusType;
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneError: any;
  error: any;
  serverError: any;
  totalCount: number;
  articles: ArticleBase[];
  article: ArticleAsTeacher | null;
  articlesStats: ArticleStatsBase[];
  articleComments: ArticleComment[];
  fetchingComments: boolean;
  fetchedComments: boolean;
  fetchCommentsError: any;
  articleFeedbacks: ArticleFeedback[];
  fetchingFeedbacks: boolean;
  fetchedFeedbacks: boolean;
  fetchFeedbackError: any;
  creatingFeedback: boolean;
  createdFeedback: boolean;
  createFeedbackError: any;
  deletingFeedback: boolean;
  deletedFeedback: boolean;
  deleteFeedbackError: any;
  fetchingStats: boolean;
  fetchedStats: boolean;
  fetchStatsError: string | null;
  fetchWordCloudData: ReduxStatusType;
  wordCloudData: WordCloudWord[];
  fetchingCompanies: ReduxStatusType;
  companiesInfo: ArticleSubmitBase[];
  totalCountSubmit: number;
}

export const initialState: TeacherAppArticleState = {
  updating: false,
  updated: false,
  changeError: null,
  fetching: ReduxStatus.idle,
  fetchingOne: false,
  fetchedOne: false,
  fetchOneError: null,
  error: null,
  serverError: null,
  articles: [],
  totalCount: 0,
  article: null,
  articleComments: [],
  articlesStats: [],
  fetchingComments: false,
  fetchedComments: false,
  fetchCommentsError: null,
  articleFeedbacks: [],
  fetchingFeedbacks: false,
  fetchedFeedbacks: false,
  fetchFeedbackError: null,
  creatingFeedback: false,
  createdFeedback: false,
  createFeedbackError: null,
  deletingFeedback: false,
  deletedFeedback: false,
  deleteFeedbackError: null,
  fetchingStats: false,
  fetchedStats: false,
  fetchStatsError: null,
  fetchWordCloudData: ReduxStatus.idle,
  wordCloudData: [],
  fetchingCompanies: ReduxStatus.idle,
  companiesInfo: [],
  totalCountSubmit: 0,
};

export const teacherAppArticleSlice = createSlice({
  name: "TeacherApp/Article",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticleSubmitAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingCompanies: ReduxStatus.pending,
          companiesInfo: [],
        };
      })
      .addCase(getArticleSubmitAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingCompanies: ReduxStatus.fulfilled,
          companiesInfo: payload.articles,
          totalCountSubmit: payload.total_count,
          error: null,
        };
      })
      .addCase(getArticleSubmitAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingCompanies: ReduxStatus.rejected,
          companiesInfo: [],
          error: action.error,
        };
      })
      .addCase(fetchArticlesAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: ReduxStatus.pending,
          serverError: null,
        };
      })
      .addCase(fetchArticlesAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: ReduxStatus.fulfilled,
          articles: payload.articles,
          totalCount: payload.total_count,
          error: "",
        };
      })
      .addCase(fetchArticlesAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: ReduxStatus.rejected,
          articles: [],
          error: action.error,
        };
      })
      .addCase(fetchArticleAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchedOne: false,
          fetchingOne: true,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          article: payload,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error,
          article: null,
        };
      })
      .addCase(fetchWordCloudDataAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchWordCloudData: ReduxStatus.pending,
        };
      })
      .addCase(fetchWordCloudDataAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchWordCloudData: ReduxStatus.fulfilled,
          wordCloudData: payload.word_cloud_list,
        };
      })
      .addCase(fetchWordCloudDataAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchWordCloudData: ReduxStatus.rejected,
          fetchWordCloudError: action.error,
        };
      })
      .addCase(updateArticleAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeError: null,
        };
      })
      .addCase(updateArticleAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          articles: update(currentState.articles, payload),
          article: payload,
          changeError: null,
        };
      })
      .addCase(updateArticleAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: action.error,
        };
      })
      .addCase(fetchArticlesStatsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingStats: true,
          fetchedStats: false,
          fetchStatsError: null,
        };
      })
      .addCase(fetchArticlesStatsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingStats: false,
          fetchedStats: true,
          fetchStatsError: null,
          articlesStats: payload.articles,
        };
      })
      .addCase(fetchArticlesStatsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingStats: false,
          fetchedStats: false,
          fetchStatsError: action.error.message as string,
        };
      })
      .addCase(fetchArticleCommentsAsTeacher.pending, (state, action) => {
        return {
          ...state,
          fetchingComments: true,
          fetchedComments: false,
          fetchCommentsError: null,
        };
      })
      .addCase(fetchArticleCommentsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingComments: false,
          fetchedComments: true,
          articleComments: payload.article_comments,
          fetchCommentsError: null,
        };
      })
      .addCase(fetchArticleCommentsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingComments: false,
          fetchedComments: false,
          fetchCommentsError: action.error,
        };
      })
      .addCase(fetchArticleFeedbacksAsTeacher.pending, (state, action) => {
        return {
          ...state,
          fetchingFeedbacks: true,
          fetchedFeedbacks: false,
          fetchFeedbacksError: null,
        };
      })
      .addCase(fetchArticleFeedbacksAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingFeedback: false,
          fetchedFeedbacks: true,
          articleFeedbacks: payload.article_feedbacks,
          fetchFeedbacksError: null,
        };
      })
      .addCase(fetchArticleFeedbacksAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingFeedbacks: false,
          fetchedFeedbacks: false,
          fetchFeedbacksError: action.error,
        };
      })
      .addCase(createArticleFeedbackAsTeacher.pending, (state, action) => {
        return {
          ...state,
          creatingFeedback: true,
          createdFeedback: false,
          createFeedbackError: null,
        };
      })
      .addCase(createArticleFeedbackAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          creatingFeedback: false,
          createdFeedback: true,
          articleFeedbacks: [...currentState.articleFeedbacks, payload],
          createFeedbackError: null,
        };
      })
      .addCase(createArticleFeedbackAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          creatingFeedback: false,
          createdFeedback: false,
          createFeedbackError: action.error,
        };
      })
      .addCase(deleteArticleFeedbackAsTeacher.pending, (state, action) => {
        return {
          ...state,
          deletingFeedback: true,
          deletedFeedback: false,
          deleteFeedbackError: null,
        };
      })
      .addCase(deleteArticleFeedbackAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          deletingFeedback: false,
          deletedFeedback: true,
          articleFeedbacks: remove(
            currentState.articleFeedbacks,
            payload.feedbackId,
          ),
          deleteFeedbackError: null,
        };
      })
      .addCase(deleteArticleFeedbackAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          deletingFeedback: false,
          deletedFeedback: false,
          deleteFeedbackError: action.error,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: null,
          fetchingOne: false,
          fetchOneError: null,
        };
      })
      .addCase(resetArticleRelatedData, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: null,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: null,
          fetchingFeedbacks: false,
          fetchedFeedbacks: false,
          fetchedComments: false,
          fetchingComments: false,
          fetchCommentsError: null,
          fetchFeedbackError: null,
          articleFeedbacks: [],
          articleComments: [],
          article: null,
        };
      })
      .addCase(resetFeedbackRelatedFormState, (state, _action) => {
        return {
          ...state,
          creatingFeedback: false,
          createdFeedback: false,
          createFeedbackError: null,
          deletingFeedback: false,
          deletedFeedback: false,
          deleteFeedbackError: null,
        };
      })
      .addCase(resetDeleteFeedbackRelatedState, (state, _action) => {
        return {
          ...state,
          deletingFeedback: false,
          deletedFeedback: false,
          deleteFeedbackError: null,
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const teacherAppArticleState = (
  state: RootState,
): TeacherAppArticleState => state.teacherApp.article;

export default teacherAppArticleSlice.reducer;
