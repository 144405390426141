import Slide, { SlideProps } from "@mui/material/Slide";
import React from "react";

type ModalTransitionProps = {
  props: SlideProps;
  ref: React.Ref<unknown>;
};

const Transition = React.forwardRef<
  ModalTransitionProps["ref"],
  ModalTransitionProps["props"]
>((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Transition;
