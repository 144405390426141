import Notifications from "@components/UI/organisms/Navigation/Common/Notifications/Notifications";
import useNotification from "@components/UI/organisms/Navigation/Common/Notifications/useNotification";
import { SelectedButton } from "@components/UIv2/molecules/V2Tabs";
import { ITEMS_PER_PAGE } from "@constants/page";
import { useAuth } from "@root/contexts/Auth";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import {
  fetchNotificationsAsAdmin,
  fetchNotificationsAsContentProvider,
  fetchNotificationsAsOwner,
  fetchNotificationsAsReviewer,
  fetchNotificationsAsStudent,
  fetchNotificationsAsSuperOwner,
  fetchNotificationsAsTeacher,
  getLimitedTimeNotificationNoticeAsOwner,
  getLimitedTimeNotificationNoticeAsStudent,
  getLimitedTimeNotificationNoticeAsTeacher,
} from "@store/actions/notification";
import { notificationState } from "@store/reducers/notification";
import React, { useEffect,useState } from "react";

interface Props {
  open: boolean;
  handleClose: () => void;
  hasNewMessage: boolean;
  setOpenNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  changeNewNotificationState: () => void;
}

const NotificationContainer: React.FC<Props> = ({
  open,
  handleClose,
  hasNewMessage,
  setOpenNotifications,
  changeNewNotificationState,
}) => {
  const {
    notifications,
    fetched,
    fetching,
    totalCount,
    error,
    limitedTimeNotificationsCount,
  } = useAppSelector(notificationState);
  const dispatch = useAppDispatch();
  const {
    buttonState,
    setButtonState,
    allowedToDisplayLimitedTimeNotification,
    postNotifications,
  } = useNotification();
  const {
    isCurrentRoles: {
      isStudent,
      isTeacher,
      isOwner,
      isSuperOwner,
      isReviewer,
      isContentProvider,
      isAdmin,
    },
    proxy,
  } = useAuth();

  const perPage = ITEMS_PER_PAGE;
  const [page, updatePage] = useState(1);

  const getFetchNotificationsAction = () => {
    if (isStudent) {
      return fetchNotificationsAsStudent;
    }
    if (isTeacher) {
      return fetchNotificationsAsTeacher;
    }
    if (isOwner) {
      return fetchNotificationsAsOwner;
    }
    if (isSuperOwner) {
      return fetchNotificationsAsSuperOwner;
    }
    if (isReviewer) {
      return fetchNotificationsAsReviewer;
    }
    if (isContentProvider) {
      return fetchNotificationsAsContentProvider;
    }
    if (isAdmin) {
      return fetchNotificationsAsAdmin;
    }

    throw new Error("Unexpected role");
  };

  const fetchNotificationsAction = getFetchNotificationsAction();

  const limitedNotificationNotice = () => {
    if (isStudent) {
      return getLimitedTimeNotificationNoticeAsStudent;
    }
    if (isTeacher) {
      return getLimitedTimeNotificationNoticeAsTeacher;
    }
    if (isOwner) {
      return getLimitedTimeNotificationNoticeAsOwner;
    }

    return undefined;
  };

  const getLimitedTimeNotificationNoticeAction = limitedNotificationNotice();

  const fetchNotifications = (params: { perPage?: number; page?: number }) => {
    if (buttonState === SelectedButton.Primary && fetchNotificationsAction) {
      dispatch(fetchNotificationsAction(params));
    } else if (
      buttonState === SelectedButton.Secondary &&
      getLimitedTimeNotificationNoticeAction
    ) {
      dispatch(getLimitedTimeNotificationNoticeAction(params));
    }
  };

  const handleUpdatePage = (newPage: number) => {
    fetchNotifications({ perPage, page: newPage });
    updatePage(newPage);
  };

  // It gets data which display on 1st page when you open modal.
  useEffect(() => {
    if (open && !proxy) {
      fetchNotifications({ perPage, page });
    }
    if (!open) {
      updatePage(1);
    }
  }, [open]);

  useEffect(() => {
    if (buttonState === SelectedButton.Secondary) {
      updatePage(1);
      fetchNotifications({ perPage, page: 1 });
    }
  }, [buttonState]);

  return (
    <Notifications
      currentPage={page}
      handleUpdatePage={handleUpdatePage}
      open={open}
      handleClose={handleClose}
      notifications={notifications}
      fetching={fetching}
      fetched={fetched}
      fetchError={error}
      totalCount={totalCount}
      changeNewNotificationState={changeNewNotificationState}
      buttonState={buttonState}
      setButtonState={setButtonState}
      allowedToDisplayLimitedTimeNotification={
        allowedToDisplayLimitedTimeNotification
      }
      postNotifications={postNotifications}
      limitedTimeNotificationsCount={limitedTimeNotificationsCount}
    />
  );
};

export default NotificationContainer;
