/* eslint-disable camelcase */
import {
  Api,
  BatchUpdateHomeworkRequest,
  FetchHomeworksAsOwnerParams,
  HomeworkBase,
  HomeworkDetails,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { UpdateHomeworkAsOwnerArg } from "./types/homework";

const api = new Api();

export const fetchHomeworkAsOwner = createAsyncThunk<
  any,
  FetchHomeworksAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/homework/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.fetchHomeworksAsOwner(params, {});
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const fetchOneHomeworkAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homework/fetchOne",
  async (homeworkId: string, { rejectWithValue }) => {
    try {
      const response = await api.owner.getOwnerHomeworksHomeworkId(homeworkId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const updateOneHomeworkAsOwner = createAsyncThunk<
  HomeworkDetails,
  UpdateHomeworkAsOwnerArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homework/update",
  async ({ homeworkId, params }, { rejectWithValue }) => {
    try {
      const response = await api.owner.updateHomeworkAsOwner(
        homeworkId,
        params,
      );
      const resData = response.data;
      return resData;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export interface BatchCreateHomeworksAsOwnerParams {
  homework_template_id?: string;
  start_at?: string;
  end_at?: string;
  create_params_list: { student_id: string }[];
}

export const batchUpdate = createAsyncThunk<
  { homeworks: HomeworkBase[] },
  BatchUpdateHomeworkRequest,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/homework/batchUpdate", async (data, { rejectWithValue }) => {
  try {
    const response = await api.owner.batchUpdateHomeworksAsOwner(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const batchDelete = createAsyncThunk<
  any,
  string[],
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homework/batch_delete",
  async (homework_ids: string[], { rejectWithValue }) => {
    try {
      const response = await api.owner.batchDeleteHomeworksAsOwner({
        homework_ids,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.error);
    }
  },
);

export const updatedHomework = createAction(
  "ownerApp/homework/updatedHomework",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const fileUploaded = createAction(
  "ownerApp/homework/fileUploaded",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const fileDeleted = createAction(
  "ownerApp/homework/fileDeleted",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const resetModalState = createAction("owner/homework/resetModalState");

export const resetDeleteActionState = createAction(
  "owner/homework/resetDeleteActionState",
);

export const resetState = createAction("owner/homework/resetState");
export const resetUpdateState = createAction("owner/homework/resetUpdateState");
