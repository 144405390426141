import { MenuItem, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";

import { AttachmentInfo } from "./types";

export const StyledMenuItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.customColor.text,
      fontSize: "16px",
      "&:hover": {
        backgroundColor: theme.palette.learningTemplatePackage.main,
      },
    },
  }),
)(MenuItem);

type Props = {
  handleDownload: (attachment: AttachmentInfo) => void;
  handleQRDownload: (attachment: AttachmentInfo) => void;
  attachment: AttachmentInfo;
  handleMenuClose: () => void;
};

const MenuItems: React.FC<Props> = ({
  handleQRDownload,
  handleDownload,
  attachment,
  handleMenuClose,
}) => {
  return (
    <>
      <StyledMenuItem>
        <Button
          type="button"
          onClick={() => {
            handleDownload(attachment);
            handleMenuClose();
          }}
          style={{ backgroundColor: "transparent" }}
        >
          ダウンロード
        </Button>
      </StyledMenuItem>

      <StyledMenuItem>
        <Button
          type="button"
          onClick={() => {
            handleQRDownload(attachment);
            handleMenuClose();
          }}
          style={{ backgroundColor: "transparent" }}
        >
          QRコード付きダウンロード
        </Button>
      </StyledMenuItem>
    </>
  );
};

export default MenuItems;
