export enum InterviewRequestStatusEnumJa {
  APPROVAL_PENDING = "未対応",
  APPROVAL_PENDING_TEACHER = "承認待ち",
  IN_PROGRESS = "対応中",
  IN_PROGRESS_TEACHER = "進行中",
  COMPLETED = "完了",
  NON_APPROVED = "非承認",
  INVALIDED = "無効",
}

export enum InterviewRequestStatusEnum {
  NULL = "null",
  APPROVAL_PENDING = "idle",
  IN_PROGRESS = "approved",
  COMPLETED = "completed",
  NON_APPROVED = "non_approved",
  INVALIDED = "invalided",
}
