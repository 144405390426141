import PlusIcon from "@assets/images/svg/plus_icon.svg?react";
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import {
  LearningTemplatePackageBase,
  PackagingAsLearningTemplatePackageBase,
} from "@lib/Api";
import SortIcon from "@mui/icons-material/Sort";
import { Box, ButtonGroup,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC } from "react";

type Props = {
  learningTemplate: LearningTemplatePackageBase;
  packagings: PackagingAsLearningTemplatePackageBase[];
  sortDisabled: boolean;
  handleModalOpen: (
    modalStatus: ModalStatus,
    learningTemplateInfos?: LearningTemplatePackageBase,
    packageInfos?: PackagingAsLearningTemplatePackageBase,
    packagesArrayInfos?: PackagingAsLearningTemplatePackageBase[],
  ) => void;
  handleActivateSort: () => void;
  setSortDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButtonWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
    },

    sortIcon: {
      marginRight: theme.spacing(1),
      cursor: "pointer",
    },
  }),
);

const ActionButtons: FC<Props> = ({
  learningTemplate,
  sortDisabled,
  packagings,
  handleModalOpen,
  handleActivateSort,
  setSortDisabled,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.addButtonWrap}>
      {!learningTemplate.is_distributed && (
        <>
          <SingleButton
            onClick={() =>
              handleModalOpen(
                ModalStatus.ADD_PACKAGE,
                learningTemplate,
                undefined,
                packagings,
              )
            }
            startIcon={<PlusIcon />}
            data-cy="add-content"
          >
            コンテンツを追加する
          </SingleButton>
          <ButtonGroup>
            <SingleButton
              onClick={handleActivateSort}
              variant={sortDisabled ? "outlined" : "contained"}
              startIcon={
                sortDisabled ? <SortIcon className={classes.sortIcon} /> : null
              }
            >
              {sortDisabled ? "コンテンツの並び替え" : "保存する"}
            </SingleButton>
            {!sortDisabled && (
              <SingleButton onClick={() => setSortDisabled(true)}>
                キャンセル
              </SingleButton>
            )}
          </ButtonGroup>
        </>
      )}
    </Box>
  );
};

export default ActionButtons;
