/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useAuth } from "@contexts/Auth";

import useOptionFeature from "./useOptionFeature";

const useCloudRoleAndFeature = () => {
  const {
    isCurrentRoles: { isTeacher, isStudent },
  } = useAuth();
  const { isOneDriveFeature, isGoogleDriveFeature } = useOptionFeature();

  const isOneDriveRole = isTeacher || isStudent;

  return {
    canUseOneDrive: isOneDriveFeature && isOneDriveRole,
    canUseGoogleDrive: isGoogleDriveFeature && isOneDriveRole,
  };
};

export default useCloudRoleAndFeature;
