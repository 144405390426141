/* eslint-disable camelcase */

import { Api, FetchArticleCommentsAsTeacherParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticleCommentsAsTeacherArg {
  params: FetchArticleCommentsAsTeacherParams;
}

export const fetchArticleCommentsAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/articleComment/fetch",
  async (arg: FetchArticleCommentsAsTeacherArg, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchArticleCommentsAsTeacher(
        arg.params,
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
