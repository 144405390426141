// eslint-disable-next-line import/prefer-default-export
export const svgContentType = "image/svg+xml";

// (xlsx/pptx/docx/jpeg/jpg/png/pdf/pages/numbers/key)
export const attachmentMIMEtypes = [
  "application/vnd.google-apps.document",
  "application/vnd.google-apps.presentation",
  "application/vnd.google-apps.form",
  "application/vnd.google-apps.spreadsheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
  "application/vnd.apple.pages",
  "application/vnd.apple.numbers",
  "application/vnd.apple.keynote",
  "application/x-iwork-pages-sffpages",
];

export const FILE_TYPE = {
  VIDEO: {
    MIME_TYPES: ["video/mp4"],
    MESSAGE: "mp4のファイルのみ許可されています。",
  },
  IMAGE: {
    MIME_TYPES: ["image/jpg", "image/jpeg", "image/png"],
    MESSAGE: "jpg, jpeg, pngのいずれかのファイルのみ許可されています。",
  },
  IMAGE_AND_PDF: {
    MIME_TYPES: [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/x-iwork-pages-sffpages",
      "application/x-iwork-numbers-sffnumbers",
      "application/x-iwork-keynote-sffkey",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    MESSAGE:
      "pdf, xlsx, pptx, docx, pages, numbers, key, jpg, jpeg, pngのいずれかのファイルのみ許可されています。",
  },
  ATTACHMENT: {
    MIME_TYPES: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/vnd.apple.pages",
      "application/vnd.apple.numbers",
      "application/vnd.apple.keynote",
      "application/x-iwork-pages-sffpages",
      "application/x-iwork-numbers-sffnumbers",
      "application/x-iwork-keynote-sffkey",
    ],
    GOOGLE_MIME_TYPES: [
      "application/vnd.google-apps.document",
      "application/vnd.google-apps.presentation",
      "application/vnd.google-apps.form",
      "application/vnd.google-apps.spreadsheet",
      "application/vnd.google-apps.drawing",
      "application/x-iwork-pages-sffpages",
    ],
    MESSAGE:
      "pdf, xlsx, pptx, docx, pages, numbers, key, jpg, jpeg, pngのいずれかのファイルのみ許可されています。",
  },
};

export const MIME_TYPES = {
  PDF: "application/pdf",
  WORD: {
    DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    DOC: "application/msword",
  },
  EXCEL: {
    XLS: "application/vnd.ms-excel",
    XLSM: "application/vnd.ms-excel.sheet.macroEnabled.12",
    XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  POWERPOINT: {
    PPS: "application/ms-powerpoint",
    PPSX: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    PPT: "application/vnd.ms-powerpoint",
    PPTX: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  IMAGE: {
    JPEG: "image/jpeg",
    JPG: "image/jpeg",
    PNG: "image/png",
  },
  CONVERTIBLE_TO_PDF: {
    DOC: "application/msword",
    DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    HTM: "text/html",
    HTML: "text/html",
    GAD: "application/vnd.google-apps.document",
    GAP: "application/vnd.google-apps.presentation",
    GAF: "application/vnd.google-apps.form",
    GAS: "application/vnd.google-apps.spreadsheet",
    ODP: "application/vnd.oasis.opendocument.presentation",
    ODS: "application/vnd.oasis.opendocument.spreadsheet",
    ODT: "application/vnd.oasis.opendocument.text",
    PPS: "application/vnd.ms-powerpoint",
    PPSX: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    PPT: "application/vnd.ms-powerpoint",
    PPTX: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    TIF: "image/tiff",
    TIFF: "image/tiff",
    XLS: "application/vnd.ms-excel",
    XLSM: "application/vnd.ms-excel.sheet.macroEnabled.12",
    XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // 以下のファイルはapplication/octet-streamに来るのでCONVERTIBLE_BUT_ONE_DRIVE_CANNOT_READも使用する
    EPUB: "application/epub+zip",
    EML: "application/vnd.sealed.eml",
    MD: "text/markdown",
    MSG: "message/global",
    RTF: "application/rtf",
  },
  CONVERTIBLE_BUT_ONE_DRIVE_CANNOT_READ: ["epub", "eml", "md", "msg", "rtf"],
};

export type FileType = {
  MIME_TYPES: string[];
  MESSAGE: string;
};

export const videoJStreamRegex =
  /(^https?:\/\/)((?:api01-platform.stream.co.jp))(\/(?:[\w-]+\?)?)([\w-]+)(\S+)?$/;

export const GOOGLE_FILE_TYPE = {
  FOLDER: "application/vnd.google-apps.folder",
};
