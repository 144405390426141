import ErrorCat from "@assets/images/svg/error.svg";
import ProfileIcon from "@assets/images/svg/profile-icon.svg?react";
import SupportButton from "@components/UI/atoms/SupportButton/SupportButton";
import { useAuth } from "@contexts/Auth";
import makeAppInfo from "@lib/app/app-info-utils";
import { errorWithMessage } from "@lib/rtk/error-utils";
import {
  IconButton,
  Menu,
  MenuItem,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useAppDispatch } from "@root/hooks";
import muiTheme from "@root/styles/theme";
import * as AuthActions from "@store/actions/auth";
import clsx from "clsx";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
  helpIconContainers: {
    height: "auto",
    position: "absolute",
    top: "25px",
    right: "25px",
    marginBottom: "24px",
  },
  iconButton: {
    padding: 4,
    margin: theme.spacing(0, 1),
    color: theme.palette.grey[400],
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0.5),
    },
  },
  lastIcon: {
    marginRight: theme.spacing(0),
  },
  smallIcon: {
    width: "28px",
    height: "28px",
  },
  iconFullSize: {
    width: "38px",
    height: "38px",
  },
}));

interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorComponent: React.FC<Props> = ({ error, resetErrorBoundary }) => {
  const { isInIframe } = makeAppInfo();
  const classes = useStyles();
  const history = useHistory();

  const isMobileView = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const isTabletView = useMediaQuery(muiTheme.breakpoints.down("md"));

  const mobileView = () => {
    if (isMobileView) {
      return classes.smallIcon;
    }
    return classes.iconFullSize;
  };

  const [anchorProfile, setAnchorProfile] = useState<null | HTMLElement>(null);

  const handleClick = () => {
    history.push({
      pathname: "/",
    });
  };

  const errorStatusMessage = "エラーコード： 500 Server Error";

  const { currentUser } = useAuth();

  const { loginPath } = makeAppInfo(currentUser ? currentUser.app_name : "");

  const dispatch = useAppDispatch();

  const deleteTokenAndLogout = () => {
    dispatch(AuthActions.deleteTokenAndLogout({}));
    history.push(loginPath);
  };
  const handleLogout = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault();
    deleteTokenAndLogout();
  };

  return (
    <>
      <Box
        role="alert"
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {!isInIframe && (
          <div className={classes.helpIconContainers}>
            <SupportButton />
            {currentUser != null && (
              <IconButton
                id="user-menu"
                // Remove margin right in tablet view to evenly space header
                className={clsx(
                  classes.iconButton,
                  isTabletView && classes.lastIcon,
                )}
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={(e) => setAnchorProfile(e.currentTarget)}
                data-cy="server-error-profile-button"
                size="large"
              >
                <ProfileIcon className={mobileView()} />
              </IconButton>
            )}
            <Menu
              id="user-menu"
              anchorEl={anchorProfile}
              keepMounted
              open={Boolean(anchorProfile)}
              onClose={() => setAnchorProfile(null)}
            >
              <MenuItem
                onClick={handleLogout}
                data-cy="server-error-profile-button-menu-logout"
              >
                ログアウト
              </MenuItem>
            </Menu>
          </div>
        )}
        <img src={ErrorCat} alt="error" />
        <Box my={8} textAlign="center">
          <Typography className={classes.text} variant="h4">
            エラーが発生しました。
          </Typography>
          <Typography className={classes.text}>
            予期せぬエラーが発生しました。
          </Typography>
          <Typography className={classes.text}>
            {error ? errorWithMessage(error) : errorStatusMessage}
          </Typography>
        </Box>
        {!isInIframe && (
          <Button
            color="primary"
            variant="contained"
            onClick={error ? resetErrorBoundary : handleClick}
          >
            トップに戻る
          </Button>
        )}
      </Box>
    </>
  );
};

export default ErrorComponent;
