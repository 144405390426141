/* eslint-disable camelcase */
import {
  addPackagesSuccess,
  deleteLearningTemplatePackageAsSuperOwner,
  deletePackagesSuccess,
  deletePackagingAsSuperOwner,
  editLearningTemplatePackageAsSuperOwner,
  fetchLearningTemplatePackagesAsSuperOwner,
  fetchOneLearningTemplatePackageAsSuperOwner,
  packageStatus,
  postLearningTemplatePackageAsSuperOwner,
  postPackagingAsSuperOwner,
  putPackagingSequenceAsSuperOwner,
  resetTemplatePackageState,
  updatePackagingAsSuperOwner,
} from "@actions/superOwnerApp/templatePackages/learningTemplatePackage";
import { LearningTemplatePackageBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

type PackageStatusType = {
  message: string;
  status: ReduxStatusType;
};
export interface SuperOwnerAppLearningTemplatePackagesState {
  fetching: boolean;
  fetched: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  postingPackage: boolean;
  postedPackage: boolean;
  posting: boolean;
  posted: boolean;
  updating: boolean;
  updated: boolean;
  deleteLeaningTemplate: ReduxStatusType;
  deleteLeaningTemplateError: any;
  deletingPackage: boolean;
  deletedPackage: boolean;
  deleteSuccess: boolean;
  success: boolean;
  updateError: any;
  postError: any;
  postPackageError: any;
  fetchOneError: any;
  fetchError: any;
  deletePackageError: any;
  packages: LearningTemplatePackageBase[];
  onePackage: any;
  totalCount: number;
  sequenceUpdate: ReduxStatusType;
  packageStatus: PackageStatusType;
}

const initialState: SuperOwnerAppLearningTemplatePackagesState = {
  fetching: false,
  fetched: false,
  fetchingOne: false,
  fetchedOne: false,
  postingPackage: false,
  postedPackage: false,
  posting: false,
  posted: false,
  updating: false,
  updated: false,
  deleteLeaningTemplate: ReduxStatus.idle,
  deleteLeaningTemplateError: null,
  deletingPackage: false,
  deletedPackage: false,
  deleteSuccess: false,
  success: false,
  updateError: null,
  postError: null,
  postPackageError: null,
  fetchOneError: null,
  fetchError: null,
  deletePackageError: null,
  packages: [],
  onePackage: null,
  totalCount: 0,
  sequenceUpdate: ReduxStatus.idle,
  packageStatus: {} as PackageStatusType,
};

export const superOwnerAppLearningTemplatePackages = createSlice({
  name: "superOwner/learningTemplatePackages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchLearningTemplatePackagesAsSuperOwner.pending,
        (state, action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
          };
        },
      )
      .addCase(
        fetchLearningTemplatePackagesAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            fetched: true,
            packages: payload.learning_template_packages,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        fetchLearningTemplatePackagesAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            fetchError: action.payload,
          };
        },
      )
      .addCase(
        fetchOneLearningTemplatePackageAsSuperOwner.pending,
        (state, action) => {
          return {
            ...state,
            fetchingOne: true,
            fetchedOne: false,
          };
        },
      )
      .addCase(
        fetchOneLearningTemplatePackageAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: true,
            onePackage: action.payload,
          };
        },
      )
      .addCase(
        fetchOneLearningTemplatePackageAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: false,
            fetchOneError: action.payload,
          };
        },
      )
      .addCase(
        postLearningTemplatePackageAsSuperOwner.pending,
        (state, action) => {
          return {
            ...state,
            posting: true,
            posted: false,
          };
        },
      )
      .addCase(
        postLearningTemplatePackageAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            posting: false,
            posted: true,
          };
        },
      )
      .addCase(
        postLearningTemplatePackageAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            posting: false,
            posted: false,
            postError: action.payload,
          };
        },
      )
      .addCase(
        editLearningTemplatePackageAsSuperOwner.pending,
        (state, action) => {
          return {
            ...state,
            updating: true,
            updated: false,
          };
        },
      )
      .addCase(
        editLearningTemplatePackageAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: true,
          };
        },
      )
      .addCase(
        editLearningTemplatePackageAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: false,
            updateError: action.payload,
          };
        },
      )
      .addCase(
        deleteLearningTemplatePackageAsSuperOwner.pending,
        (state, action) => {
          return {
            ...state,
            deleteLeaningTemplate: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        deleteLearningTemplatePackageAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            deleteLeaningTemplate: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        deleteLearningTemplatePackageAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            deleteLeaningTemplate: ReduxStatus.rejected,
            deleteLeaningTemplateError: action.payload,
          };
        },
      )
      .addCase(postPackagingAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          postingPackage: true,
          postedPackage: false,
        };
      })
      .addCase(postPackagingAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          postingPackage: false,
          postedPackage: true,
        };
      })
      .addCase(postPackagingAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          postingPackage: false,
          postedPackage: false,
          postPackageError: action.payload,
        };
      })
      .addCase(updatePackagingAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          postingPackage: true,
          postedPackage: false,
        };
      })
      .addCase(updatePackagingAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          postingPackage: false,
          postedPackage: true,
        };
      })
      .addCase(updatePackagingAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          postingPackage: false,
          postedPackage: false,
          postPackageError: action.payload,
        };
      })
      .addCase(putPackagingSequenceAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          sequenceUpdate: ReduxStatus.pending,
        };
      })
      .addCase(putPackagingSequenceAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          sequenceUpdate: ReduxStatus.fulfilled,
        };
      })
      .addCase(putPackagingSequenceAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          sequenceUpdate: ReduxStatus.rejected,
          packageSequenceError: action.payload,
        };
      })
      .addCase(deletePackagingAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          deletingPackage: true,
          deletedPackage: false,
        };
      })
      .addCase(deletePackagingAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          deletingPackage: false,
          deletedPackage: true,
        };
      })
      .addCase(deletePackagingAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          deletingPackage: false,
          deletedPackage: false,
          deletePackageError: action.payload,
        };
      })
      .addCase(addPackagesSuccess, (state) => {
        return {
          ...state,
          success: true,
        };
      })
      .addCase(deletePackagesSuccess, (state) => {
        return {
          ...state,
          deleteSuccess: true,
        };
      })
      .addCase(packageStatus, (state, _action) => {
        const { payload } = _action;
        return {
          ...state,
          packageStatus: payload,
        };
      })
      .addCase(resetTemplatePackageState, (state) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postingPackage: false,
          postedPackage: false,
          deletingPackage: false,
          deletedPackage: false,
          deleteLeaningTemplate: ReduxStatus.idle,
          updating: false,
          updated: false,
          updateError: null,
          postPackageError: null,
          deletePackageError: null,
          postError: null,
          success: false,
          deleteSuccess: false,
          packageStatus: {} as PackageStatusType,
        };
      });
  },
});

export const superOwnerAppLearningTemplatePackagesState = (
  state: RootState,
): SuperOwnerAppLearningTemplatePackagesState =>
  state.superOwnerApp.learningTemplatePackages;

export default superOwnerAppLearningTemplatePackages.reducer;
