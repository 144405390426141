import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import errorWithMessage from "@lib/rtk/error-utils";
import { Box,Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { usePostInterviewRequestAsStudentMutation } from "@root/endpoints/TimeTactApi/studentApi";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HiExclamationCircle } from "react-icons/hi";

import DegreeOfInterest from "./FormInputs/DegreeOfInterest";
import DegreeOfLearning from "./FormInputs/DegreeOfLearning";
import Description from "./FormInputs/Description";
import IsRequestSendMaterial from "./FormInputs/IsRequestSendMaterial";

type Props = {
  handleModalClose: () => void;
  refetchMessageList: () => void;
  refetchInterviewRequestDetail: () => void;
  refetchInterviewRequestList: () => void;
  refetchFeedBacks: () => void;
  isOpen: boolean;
  requestId: string;
};

export type FormValuesTypes = {
  degreeOfInterest: string;
  degreeOfLearning: string;
  description: string;
  isRequestSendMaterial: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerContentWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      gap: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
      },
    },
    iconStyle: {
      fontSize: "25px",
    },
  }),
);

const SendFeedBacksAnswerModal: React.FC<Props> = ({
  isOpen,
  requestId,
  handleModalClose,
  refetchMessageList,
  refetchInterviewRequestDetail,
  refetchInterviewRequestList,
  refetchFeedBacks,
}) => {
  const { t } = useTranslation("university");
  const classes = useStyles();
  const { actions } = useEnqueueToast();
  const commonRadioBoxValues = Array.from({ length: 10 }, (_, idx) => idx + 1);
  const defaultValues = {
    degreeOfInterest: "",
    degreeOfLearning: "",
    description: "",
    isRequestSendMaterial: "",
  };

  const { control, handleSubmit, reset } = useForm<FormValuesTypes>({
    defaultValues,
  });

  const [
    postInterviewRequestAsStudent,
    { isLoading: postingInterviewRequestAsStudent },
  ] = usePostInterviewRequestAsStudentMutation();

  const onSubmit = (formValues: FormValuesTypes) => {
    const {
      degreeOfInterest,
      degreeOfLearning,
      description,
      isRequestSendMaterial,
    } = formValues;
    const isSendMaterial = isRequestSendMaterial === "true";
    postInterviewRequestAsStudent({
      requestId,
      body: {
        degree_of_interest: Number(degreeOfInterest),
        degree_of_learning: Number(degreeOfLearning),
        description,
        is_request_send_material: isSendMaterial,
      },
    })
      .unwrap()
      .then(() => {
        refetchInterviewRequestList();
        refetchInterviewRequestDetail();
        refetchMessageList();
        refetchFeedBacks();
        reset(defaultValues);
        handleModalClose();
        actions.showSuccess(t("questionarySuccess"));
      })
      .catch((error: unknown) => actions.showError(errorWithMessage(error)));
  };
  return (
    <GenericModal
      title={t("questionary")}
      modalSize="md"
      open={isOpen}
      handleClose={handleModalClose}
      secondaryButtonText={t("cancel")}
      primaryButtonText={t("send")}
      primaryButtonAction={handleSubmit(onSubmit)}
      secondaryButtonAction={handleModalClose}
      useActionButtons
      useSecondaryButton
      posting={postingInterviewRequestAsStudent}
    >
      <DegreeOfInterest
        commonRadioBoxValues={commonRadioBoxValues}
        control={control}
      />
      <DegreeOfLearning
        commonRadioBoxValues={commonRadioBoxValues}
        control={control}
      />
      <IsRequestSendMaterial control={control} />
      <Description control={control} />
      <Box className={classes.footerContentWrapper}>
        <HiExclamationCircle className={classes.iconStyle} />
        <Typography>{t("afterSubmitFeedBack")}</Typography>
      </Box>
    </GenericModal>
  );
};

export default SendFeedBacksAnswerModal;
