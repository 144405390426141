import { Theme } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallSize: {
      padding: theme.spacing(0.5, 1),
      height: "25px!important",
      fontSize: "14px!important",
      lineHeight: "14px!important",
      minWidth: 52,
    },
    outlinedButton: {
      whiteSpace: "nowrap",
      color: theme.palette.tertiary.mainDark,
      height: 35,
      minWidth: "fit-content",
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${theme.palette.tertiary.mainDark}`,
      fontWeight: 600,
      fontSize: 14,
      borderRadius: 8,
      lineHeight: "16px",
      "&:hover": {
        backgroundColor: "#F2F8F7",
        border: `2px solid ${theme.palette.tertiary.mainDark}`,
      },
      textTransform: "none",
    },
    containedButton: {
      whiteSpace: "nowrap",
      color: theme.palette.common.white,
      height: 35,
      minWidth: "fit-content",
      backgroundColor: theme.palette.tertiary.mainDark,
      "&:hover": {
        backgroundColor: theme.palette.tertiary.darkGreen,
      },
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "16px",
      borderRadius: 8,
      textTransform: "none",
    },
    // disabledButton: {
    //   color: `${theme.palette.common.white}`,
    // }, Fix at the end of the button design update to avoid bugs
  }),
);

export type Props = { dataCy?: string } & Omit<ButtonProps, "color">;

const SingleButton: React.FC<Props> = ({ children, dataCy, ...args }) => {
  const classes = useStyles();
  const smallSizeClass = args.size === "small" && classes.smallSize;
  return (
    <Button
      data-cy={dataCy || "single-button"}
      disableElevation
      classes={{
        outlined: clsx(smallSizeClass, classes.outlinedButton),
        contained: clsx(smallSizeClass, classes.containedButton),
        // disabled: classes.disabledButton,
      }}
      variant="outlined"
      {...args}
    >
      {children}
    </Button>
  );
};

export default SingleButton;
