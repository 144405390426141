/* eslint-disable camelcase */
import { UserParamsOnCsvImport } from "@actions/ownerApp/user";
import { AccountUserCSVData } from "@containers/SuperOwner/SuperOwnerSchoolsContainer/logic/types";
import {
  Api,
  FetchStudentsAsTeacherParams,
  UsersStudentAffiliatesListParams,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { safeString } from "@lib/string-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

// [先生] 生徒のAffiliateのリストを取得。
// Get a list of students affiliated to the Teacher
export const fetchAffiliatedStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/students/fetchTeacherAffiliatedStudent",
  async (params: UsersStudentAffiliatesListParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.usersStudentAffiliatesList(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Create users from csv

export interface BatchCreateUsersAsTeacherArg {
  slice_start: number;
  user_params_list: UserParamsOnCsvImport[];
  invitation_scheduled_at?: string;
}

export const batchCreateUsersAsTeacher = createAsyncThunk<
  any,
  BatchCreateUsersAsTeacherArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/users/batchCreate", async (params, { rejectWithValue }) => {
  try {
    const response =
      await api.teacher.studentsBatchCreateUsersAsTeacher(params);
    const resData = response.data;
    return resData;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

type BathCreateUserAccountId = {
  params: AccountUserCSVData;
  requestIndex: number;
};

export const batchCreateUserAccountId = createAsyncThunk<
  any,
  BathCreateUserAccountId,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/users/batchCreateUserId", async (arg, { rejectWithValue }) => {
  const { params } = arg;

  try {
    const currentYear = new Date().getFullYear();
    const response = await api.teacher.createAccountUserAsTeacher({
      account_id: params.account_id,
      year: params.year ?? currentYear,
      last_name: safeString(params.last_name),
      first_name: safeString(params.first_name),
      optional_email: params.optional_email,
      password: safeString(params.password),
      user_params: {
        grade: params.grade,
        parent_last_name: params.parent_last_name,
        parent_first_name: params.parent_first_name,
        postcode: params.postcode,
        prefecture: params.prefecture,
        address1: params.address1,
        address2: params.address2,
        phone: params.phone,
        birth_date: params.birth_date,
        number: params.number,
      },
      group_code: params.group_code,
      user_tag_ids: params?.user_tag_ids ?? [],
      group_ids: params?.group_ids ?? [],
    });
    return response;
  } catch (err) {
    return rejectWithValue({
      error: err.response.data.errors[0].message,
      requestIndex: arg.requestIndex,
    });
  }
});

export const updateUserAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/users/update", async ({ id, params }, { rejectWithValue }) => {
  try {
    const response = await api.teacher.putTeacherStudentsUserId(id, params);
    const resData = response.data;
    return {
      id: resData.id,
    };
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});
export const changeCsvImportState = createAction(
  "teacher/users/changeCsvImportState",
);

export const resetCsvImportState = createAction(
  "teacher/users/resetCsvImportState",
);

export const fetchStudentsAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/students/fetch",
  async (params: FetchStudentsAsTeacherParams, { signal, rejectWithValue }) => {
    try {
      const data = { ...params };
      const controller = new AbortController();
      signal.addEventListener("abort", () => controller.abort());
      const response = await api.teacher.fetchStudentsAsTeacher(data, {
        signal: controller.signal,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchStudentAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/students/fetchOne", async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getTeacherUsersUserId(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
