import { ITEMS_PER_PAGE } from "@constants/page";
import { SelectChangeEvent } from "@mui/material";
import {
  InterviewFeedbackBase,
  InterviewRequestDetailBase,
  InterviewRequestListBase,
  InterviewRequestListBase2,
  InterviewRequestStatus,
  InterviewRequestUnreadCountBase,
  useGetInterviewFeedbacksAsStudentQuery,
  useGetInterviewMessageReadAsStudentMutation,
  useGetInterviewMessagesAsStudentQuery,
  useGetInterviewRequestAsStudentQuery,
  useGetInterviewRequestsAsStudentQuery,
  useGetInterviewRequestUnreadCountsAsStudentQuery,
} from "@root/endpoints/TimeTactApi/studentApi";
import { InterviewRequestStatusEnum } from "@root/types/interviewRequest";
import { useEffect, useState } from "react";

export enum ModalToOpen {
  IDLE = "IDLE",
  SCHEDULE_PROPOSAL = "SCHEDULE_PROPOSAL",
  QUESTIONARY = "QUESTIONARY",
}

type ModalStateType = {
  isOpen: boolean;
  currentModal: ModalToOpen;
};

type useInterviewRequestTypes = {
  states: {
    currentInterviewPage: number;
    totalInterviewRequestCount: number;
    interviewRequestsList: InterviewRequestListBase[];
    requestsListError: unknown;
    currentStatus: InterviewRequestStatusEnum;
    currentInterviewRequest: InterviewRequestDetailBase;
    messagesList: InterviewRequestListBase2[];
    isRequestsListFetching: boolean;
    isRequestsListSuccess: boolean;
    modalState: ModalStateType;
    isMobileChatOpen: boolean;
    isFetchingMsgList: boolean;
    interviewRequestDetails: InterviewRequestDetailBase;
    feedBacks: InterviewFeedbackBase[];
    unreadMessages: InterviewRequestUnreadCountBase[];
  };
  actions: {
    handlePageTransition: (newPage: number) => void;
    handleStatusFilter: (event: SelectChangeEvent) => void;
    handleSelectInterview: (request: InterviewRequestDetailBase) => void;
    refetchMessagesList: () => void;
    refetchInterviewRequestDetail: () => void;
    refetchInterviewRequestList: () => void;
    handleMobileChat: () => void;
    handleModalState: (modalName: ModalToOpen) => void;
    handleModalClose: () => void;
    refetchFeedBacks: () => void;
  };
};

const useHandleRequestsInterview = (
  currentInterviewPage: number,
  currentStatus: InterviewRequestStatusEnum,
) => {
  const {
    data: requestResponse,
    error: requestsListError,
    isFetching: isRequestsListFetching,
    isSuccess: isRequestsListSuccess,
    refetch: refetchInterviewRequestList,
  } = useGetInterviewRequestsAsStudentQuery(
    {
      page: currentInterviewPage,
      perPage: ITEMS_PER_PAGE,
      status: currentStatus as InterviewRequestStatus,
    },
    { refetchOnMountOrArgChange: true },
  );
  const requestListResponse = requestResponse?.interview_requests || [];
  const totalInterviewRequestCount = requestResponse?.total_count || 0;
  return {
    requestListResponse,
    requestsListError,
    isRequestsListFetching,
    isRequestsListSuccess,
    totalInterviewRequestCount,
    refetchInterviewRequestList,
  };
};

const useFetchOneInterviewRequest = (
  currentInterviewRequest: InterviewRequestListBase,
) => {
  const {
    data,
    refetch: refetchInterviewRequestDetail,
    isSuccess: isRequestDetailsFetched,
  } = useGetInterviewRequestAsStudentQuery(
    {
      id: currentInterviewRequest?.id as string,
    },
    { refetchOnMountOrArgChange: true, skip: !currentInterviewRequest?.id },
  );
  const interviewRequestDetails = data || ({} as InterviewRequestDetailBase);
  return {
    interviewRequestDetails,
    refetchInterviewRequestDetail,
    isRequestDetailsFetched,
  };
};

const useHandleMessages = (
  currentInterviewRequest: InterviewRequestListBase,
) => {
  const {
    data,
    refetch: refetchMessagesList,
    isFetching: isFetchingMsgList,
  } = useGetInterviewMessagesAsStudentQuery(
    {
      requestId: currentInterviewRequest?.id as string,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !currentInterviewRequest?.id,
    },
  );
  const messagesList = [...(data?.interview_messages || [])].reverse();
  return { messagesList, isFetchingMsgList, refetchMessagesList };
};

const useFetchFeedBack = (
  currentInterviewRequest: InterviewRequestListBase,
) => {
  const { data, refetch: refetchFeedBacks } =
    useGetInterviewFeedbacksAsStudentQuery(
      {
        requestId: currentInterviewRequest?.id as string,
      },
      { refetchOnMountOrArgChange: true, skip: !currentInterviewRequest?.id },
    );
  const feedBacks = data?.interview_feedbacks || [];
  return { feedBacks, refetchFeedBacks };
};

const useFetchUnreadMessages = (
  isRequestsListSuccess: boolean,
  requestListResponse: InterviewRequestListBase[],
) => {
  const requestIds = requestListResponse.map((request) => request.id);
  const { data, refetch: refetchUnreadMessagesCount } =
    useGetInterviewRequestUnreadCountsAsStudentQuery(
      {
        requestIds,
      },
      { refetchOnMountOrArgChange: true, skip: !isRequestsListSuccess },
    );
  const unreadMessages = data?.unread_counts || [];
  return { unreadMessages, refetchUnreadMessagesCount };
};

const useInterviewRequest = (): useInterviewRequestTypes => {
  const [currentInterviewPage, setCurrentInterviewPage] = useState(1);
  const [isMobileChatOpen, setIsMobileChatOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalStateType>({
    isOpen: false,
    currentModal: ModalToOpen.IDLE,
  });
  const [currentStatus, setCurrentStatus] =
    useState<InterviewRequestStatusEnum>(InterviewRequestStatusEnum.NULL);

  const {
    requestsListError,
    isRequestsListFetching,
    isRequestsListSuccess,
    requestListResponse,
    totalInterviewRequestCount,
    refetchInterviewRequestList,
  } = useHandleRequestsInterview(currentInterviewPage, currentStatus);
  const [currentInterviewRequest, setCurrentInterviewRequest] =
    useState<InterviewRequestDetailBase>({} as InterviewRequestDetailBase);
  const { messagesList, isFetchingMsgList, refetchMessagesList } =
    useHandleMessages(currentInterviewRequest);
  const {
    interviewRequestDetails,
    refetchInterviewRequestDetail,
    isRequestDetailsFetched,
  } = useFetchOneInterviewRequest(currentInterviewRequest);
  const { feedBacks, refetchFeedBacks } = useFetchFeedBack(
    currentInterviewRequest,
  );
  const { unreadMessages, refetchUnreadMessagesCount } = useFetchUnreadMessages(
    isRequestsListSuccess,
    requestListResponse,
  );
  const [postMessageAsReadAsStudent] =
    useGetInterviewMessageReadAsStudentMutation();

  useEffect(() => {
    if (isRequestDetailsFetched && interviewRequestDetails) {
      if (interviewRequestDetails.unread_message_ids?.length) {
        postMessageAsReadAsStudent({
          requestId: interviewRequestDetails.id,
          body: {
            unread_message_ids: interviewRequestDetails.unread_message_ids,
          },
        })
          .unwrap()
          .then(() => {
            refetchInterviewRequestDetail();
            refetchUnreadMessagesCount();
          });
      }
    }
  }, [interviewRequestDetails]);

  return {
    states: {
      currentInterviewPage,
      interviewRequestsList: requestListResponse,
      requestsListError,
      isRequestsListFetching,
      isRequestsListSuccess,
      currentStatus,
      currentInterviewRequest,
      messagesList,
      modalState,
      isMobileChatOpen,
      isFetchingMsgList,
      totalInterviewRequestCount,
      interviewRequestDetails,
      feedBacks,
      unreadMessages,
    },
    actions: {
      handlePageTransition: (newPage: number) =>
        setCurrentInterviewPage(newPage),
      handleStatusFilter: (event: SelectChangeEvent) => {
        setCurrentInterviewPage(1);
        setCurrentStatus(event.target.value as InterviewRequestStatusEnum);
      },

      refetchMessagesList,
      refetchInterviewRequestList,
      refetchInterviewRequestDetail,
      refetchFeedBacks,
      handleModalState: (modalName: ModalToOpen) => {
        setModalState({
          isOpen: true,
          currentModal: modalName,
        });
      },
      handleModalClose: () =>
        setModalState({
          isOpen: true,
          currentModal: ModalToOpen.IDLE,
        }),
      handleMobileChat: () => setIsMobileChatOpen((prev) => !prev),
      handleSelectInterview: (request: InterviewRequestDetailBase) => {
        setCurrentInterviewRequest(request);
      },
    },
  };
};

export default useInterviewRequest;
