/* eslint-disable camelcase */
import { Api, FetchReportsAsOwnerParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchReportsAsOwner = createAsyncThunk<
  any,
  FetchReportsAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/reports/fetch",
  async (params: FetchReportsAsOwnerParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.fetchReportsAsOwner(params, {});
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchOneReportAsOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/reports/fetchOne", async (reportId: string, { rejectWithValue }) => {
  try {
    const response = await api.owner.getOwnerReportsReportId(reportId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
