import {
  ArticleBase,
  ArticleComment,
  ArticleDetailWithAdditionalInfo,
  ArticleFeedback,
} from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";

import {
  fetchArticleAsReviewer,
  fetchArticlesAsReviewer,
  likeArticleAsReviewer,
  unlikeArticleAsReviewer,
} from "../../actions/reviewerApp/article";
import {
  createArticleCommentAsReviewer,
  deleteArticleCommentAsReviewer,
  fetchArticleCommentsAsReviewer,
  resetCommentRelatedFormState,
  resetDeleteCommentRelatedState,
} from "../../actions/reviewerApp/articleComment";
import { RootState } from "../../store";

export interface ReviewerAppArticleState {
  fetching: boolean;
  fetched: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneError: any;
  serverError: any;
  totalCount: number;
  articles: ArticleBase[];
  article: ArticleDetailWithAdditionalInfo | null;
  articleComments: ArticleComment[];
  fetchingComments: boolean;
  fetchedComments: boolean;
  articleFeedbacks: ArticleFeedback[];
  fetchingFeedbacks: boolean;
  fetchedFeedbacks: boolean;
  creatingComment: boolean;
  createdComment: boolean;
  createCommentError: any;
  deletingComment: boolean;
  deletedComment: boolean;
  deleteCommentError: any;
  liking: boolean;
  liked: boolean;
  likeError: any;
  unliking: boolean;
  unliked: boolean;
  unlikeError: any;
}

export const initialState: ReviewerAppArticleState = {
  fetching: false,
  fetched: false,
  fetchingOne: false,
  fetchedOne: false,
  fetchOneError: null,
  serverError: null,
  articles: [],
  totalCount: 0,
  article: null,
  articleComments: [],
  fetchingComments: false,
  fetchedComments: false,
  articleFeedbacks: [],
  fetchingFeedbacks: false,
  fetchedFeedbacks: false,
  creatingComment: false,
  createdComment: false,
  createCommentError: null,
  deletingComment: false,
  deletedComment: false,
  deleteCommentError: null,
  liking: false,
  liked: false,
  likeError: null,
  unliking: false,
  unliked: false,
  unlikeError: null,
};

export const reviewerAppArticleSlice = createSlice({
  name: "ReviewerApp/Article",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticlesAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          serverError: null,
        };
      })
      .addCase(fetchArticlesAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          articles: payload.articles,
          totalCount: payload.total_count,
          serverError: "",
        };
      })
      .addCase(fetchArticlesAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          articles: [],
          serverError: action.error,
        };
      })
      .addCase(fetchArticleAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          fetchedOne: false,
          fetchingOne: true,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          article: payload,
          fetchOneError: null,
        };
      })
      .addCase(fetchArticleAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error,
          article: null,
        };
      })
      .addCase(fetchArticleCommentsAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          fetchedComments: false,
          fetchingComments: true,
          serverError: null,
        };
      })
      .addCase(fetchArticleCommentsAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingComments: false,
          fetchedComments: true,
          articleComments: payload.article_comments,
          serverError: "",
        };
      })
      .addCase(fetchArticleCommentsAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          articles: [],
          serverError: action.error,
        };
      })
      .addCase(createArticleCommentAsReviewer.pending, (state, action) => {
        return {
          ...state,
          creatingComment: true,
          createdComment: false,
          createCommentError: null,
        };
      })
      .addCase(createArticleCommentAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        let updatedArtcile = null;
        if (currentState.article) {
          updatedArtcile = {
            ...currentState.article,
            num_comments: currentState.article.num_comments + 1,
          };
        }
        let updatedArtciles = currentState.articles;
        if (updatedArtcile) {
          updatedArtciles = update(currentState.articles, updatedArtcile);
        }
        return {
          ...state,
          creatingComment: false,
          createdComment: true,
          article: updatedArtcile,
          articles: updatedArtciles,
          articleComments: [...currentState.articleComments, payload],
          createCommentError: null,
        };
      })
      .addCase(createArticleCommentAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          creatingComment: false,
          createdComment: false,
          createCommentError: action.error,
        };
      })
      .addCase(deleteArticleCommentAsReviewer.pending, (state, action) => {
        return {
          ...state,
          deletingComment: true,
          deletedComment: false,
          deleteCommentError: null,
        };
      })
      .addCase(deleteArticleCommentAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        let updatedArtcile = null;
        const currentState = current(state);
        if (currentState.article) {
          updatedArtcile = {
            ...currentState.article,
            num_comments: currentState.article.num_comments - 1,
          };
        }
        let updatedArtciles = currentState.articles;
        if (updatedArtcile) {
          updatedArtciles = update(currentState.articles, updatedArtcile);
        }
        return {
          ...state,
          deletingComment: false,
          deletedComment: true,
          article: updatedArtcile,
          articles: updatedArtciles,
          articleComments: remove(
            currentState.articleComments,
            payload.commentId,
          ),
          deleteCommentError: null,
        };
      })
      .addCase(deleteArticleCommentAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          deletingComment: false,
          deletedComment: false,
          deleteCommentError: action.error,
        };
      })
      .addCase(likeArticleAsReviewer.pending, (state, action) => {
        return {
          ...state,
          liking: true,
          liked: false,
          likeError: null,
        };
      })
      .addCase(likeArticleAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          liking: false,
          liked: true,
          articles: update(currentState.articles, payload),
          article: payload,
          likeError: null,
        };
      })
      .addCase(likeArticleAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          liking: false,
          liked: false,
          likeError: action.payload,
        };
      })
      .addCase(unlikeArticleAsReviewer.pending, (state, action) => {
        return {
          ...state,
          unliking: true,
          unliked: false,
          unlikeError: null,
        };
      })
      .addCase(unlikeArticleAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          unliking: false,
          unliked: true,
          articles: update(currentState.articles, payload),
          article: payload,
          unlikeError: null,
        };
      })
      .addCase(unlikeArticleAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          unliking: false,
          unliked: false,
          unlikeError: action.payload,
        };
      })
      .addCase(resetCommentRelatedFormState, (state, _action) => {
        return {
          ...state,
          creatingComment: false,
          createdComment: false,
          createCommentError: null,
          deletingComment: false,
          deletedComment: false,
          deleteCommentError: null,
        };
      })
      .addCase(resetDeleteCommentRelatedState, (state, _action) => {
        return {
          ...state,
          deletingComment: false,
          deletedComment: false,
          deleteCommentError: null,
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const reviewerAppArticleState = (
  state: RootState,
): ReviewerAppArticleState => state.reviewerApp.article;

export default reviewerAppArticleSlice.reducer;
