import {
  fetchBookmarkedPblSponsorProjectsAsTeacher,
  fetchLecturesByBookmarkedProjectAsTeacher,
  reset,
} from "@actions/teacherApp/pbl/sponsor/bookmarkedProject";
import { SponsorLectureDetailsBase, SponsorProjectDetailsBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";
import { remove } from "lodash";

export interface LectureInfo {
  projectId: string;
  lectures: SponsorLectureDetailsBase[];
  totalCount: number;
}

export type CustomContentType = {
  id: string;
  title: string;
};

export interface TeacherAppBookmarkedPblSponsorProjectState {
  fetchProjectsStatus: ReduxStatusType;
  fetchErrors: any[] | null;
  projects: SponsorProjectDetailsBase[];
  projectTotalCount: number;
  lectures: LectureInfo[];
  projectIdsOnfetchingLectures: string[]; // Put the id of project
  customContent: CustomContentType;
}

export const initialState: TeacherAppBookmarkedPblSponsorProjectState = {
  fetchProjectsStatus: ReduxStatus.idle,
  fetchErrors: null,
  projects: [],
  projectTotalCount: 0,
  lectures: [],
  projectIdsOnfetchingLectures: [],
  customContent: {
    id: "",
    title: "",
  },
};

const teacherAppBookmarkedPblSponsorProjectSlice = createSlice({
  name: "teacherApp/pbl/sponsors/info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchBookmarkedPblSponsorProjectsAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetchProjectsStatus: ReduxStatus.pending,
            fetchErrors: null,
          };
        },
      )
      .addCase(
        fetchBookmarkedPblSponsorProjectsAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetchProjectsStatus: ReduxStatus.rejected,
            fetchErrors: action.payload,
          };
        },
      )
      .addCase(
        fetchBookmarkedPblSponsorProjectsAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;

          return {
            ...state,
            fetchProjectsStatus: ReduxStatus.fulfilled,
            fetchErrors: null,
            projects: [...state.projects, ...payload.projects],
            customContent: payload.custom_content,
            projectTotalCount: payload.total_count,
          };
        },
      )
      .addCase(
        fetchLecturesByBookmarkedProjectAsTeacher.pending,
        (state, action) => {
          const { meta } = action;
          return {
            ...state,
            projectIdsOnfetchingLectures: [
              ...state.projectIdsOnfetchingLectures,
              meta.arg.project_id,
            ],
          };
        },
      )
      .addCase(
        fetchLecturesByBookmarkedProjectAsTeacher.rejected,
        (state, action) => {
          const { meta } = action;
          return {
            ...state,
            projectIdsOnfetchingLectures: remove(
              state.projectIdsOnfetchingLectures,
              meta.arg.project_id,
            ),
          };
        },
      )
      .addCase(
        fetchLecturesByBookmarkedProjectAsTeacher.fulfilled,
        (state, action) => {
          const { payload, meta } = action;
          const projectId = meta.arg.project_id;
          // const currentState = current(state);
          const currentInfoIdx = state.lectures.findIndex(
            (l) => l.projectId === projectId,
          );
          let newInfo: LectureInfo;
          let newLectures: LectureInfo[];
          if (currentInfoIdx === -1) {
            newInfo = {
              projectId,
              lectures: payload.project_lectures,
              totalCount: payload.total_count,
            };
            newLectures = [...state.lectures, newInfo];
          } else {
            const currentInfo = state.lectures[currentInfoIdx];
            const updatedInfo = {
              ...currentInfo,
              lectures: [...currentInfo.lectures, ...payload.project_lectures],
            };
            newLectures = [...state.lectures];
            newLectures.splice(currentInfoIdx, 1, updatedInfo);
          }

          return {
            ...state,
            projectIdsOnfetchingLectures: remove(
              state.projectIdsOnfetchingLectures,
              projectId,
            ),
            lectures: newLectures,
          };
        },
      )
      .addCase(reset, (state, _action) => {
        return initialState;
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppBookmarkedPblSponsorProjectState = (
  state: RootState,
): TeacherAppBookmarkedPblSponsorProjectState =>
  state.teacherApp.bookmarkedPblSponsorProject;

export default teacherAppBookmarkedPblSponsorProjectSlice.reducer;
