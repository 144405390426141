import { getStudentCompany, StudentCompany } from "@actions/studentApp/company";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface StudentAppCompanyState {
  fetching: boolean;
  fetched: boolean;
  company: StudentCompany | null;
  error: any;
}

export const initialState: StudentAppCompanyState = {
  fetching: false,
  fetched: false,
  company: null,
  error: null,
};

export const studentAppCompanySlice = createSlice({
  name: "StudentApp/Company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStudentCompany.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      })
      .addCase(getStudentCompany.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          company: action.payload,
        };
      })
      .addCase(getStudentCompany.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          company: null,
          error: action.payload,
        };
      });
  },
});

export const studentAppCompanyState = (
  state: RootState,
): StudentAppCompanyState => state.studentApp.company;

export default studentAppCompanySlice.reducer;
