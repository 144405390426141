/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { CurrentUserInfo } from "@root/endpoints/TimeTactApi/remainingApi";
import { tracker } from "@store/store";
import { useEffect } from "react";

const useOpenReplay = (currentUser: CurrentUserInfo | null) => {
  const currentUserEmail = currentUser?.email;
  const currentCompany = currentUser?.company_name;
  useEffect(() => {
    if (currentUserEmail && tracker) {
      tracker.setUserID(currentUserEmail);
    }
    if (currentCompany && tracker) {
      tracker.setMetadata("company", currentCompany);
    }
  }, [currentUserEmail, currentCompany, tracker]);
};

export default useOpenReplay;
