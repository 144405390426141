import { BaseCategoryInfo, GradeCategoryInfo } from "@lib/Api";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { MinorCategoryState } from "@reducers/category/minor";
import React from "react";

import { StyledAccordion, StyledAccordionSummary } from "..";
import MinorLayer from "./Minor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    cardContent: {
      padding: theme.spacing(0),
    },
    middletext: {
      fontSize: 16,
      marginLeft: theme.spacing(5),
      cursor: "pointer",
    },
    card: {
      borderRadius: 0,
    },
    summary: {
      backgroundColor: theme.palette.common.white,
    },
    activeSummary: {
      backgroundColor: theme.palette.grey[100],
    },
  }),
);

interface Props {
  categoryItem: GradeCategoryInfo;
  minor: MinorCategoryState;
  selectedCategoryId: string[];
  handleSelectedIds: (item: BaseCategoryInfo) => void;
}

const MiddleLayer: React.FC<Props> = (props: Props) => {
  const { categoryItem, minor, selectedCategoryId, handleSelectedIds } = props;

  const classes = useStyles();

  return (
    <StyledAccordion expanded square className={classes.card}>
      <StyledAccordionSummary
        className={
          selectedCategoryId.includes(categoryItem.id)
            ? classes.activeSummary
            : classes.summary
        }
        onClick={() => handleSelectedIds(categoryItem)}
      >
        <Typography className={classes.middletext}>
          {categoryItem.name}
        </Typography>
      </StyledAccordionSummary>
      {minor.items
        .filter((m) => categoryItem.id === m.middle_category_id)
        .map((middleItem) => (
          <MinorLayer
            key={middleItem.id}
            categoryItem={middleItem}
            selectedCategoryId={selectedCategoryId}
            handleSelectedIds={handleSelectedIds}
          />
        ))}
    </StyledAccordion>
  );
};

export default MiddleLayer;
