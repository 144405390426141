/* eslint-disable @typescript-eslint/ban-types */

import { Box,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { InterviewRequestDetailBase } from "@root/endpoints/TimeTactApi/studentApi";
import React from "react";
import { Control, UseFormHandleSubmit } from "react-hook-form";

import { ModalToOpen } from "../../hook/useInterviewRequests";
import { FormValues } from "..";
import ActionsButtons from "./ActionsButtons";
import Input from "./Input";

type Props = {
  currentInterviewRequest: InterviewRequestDetailBase;
  control: Control<FormValues, object>;
  requestId: string;
  isElementsDisabled: boolean;
  isCalendarIconDisplay: boolean;
  onSubmit: (formValues: FormValues) => void;
  handleSubmit: UseFormHandleSubmit<FormValues>;
  handleModalState: (modalName: ModalToOpen) => void;
  refetchMessageList: () => void;
  resetChatInput: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    root: {
      backgroundColor: theme.palette.common.white,
      width: "100%",
    },
  }),
);

const ChatInput: React.FC<Props> = ({
  handleSubmit,
  onSubmit,
  handleModalState,
  refetchMessageList,
  resetChatInput,
  isElementsDisabled,
  control,
  currentInterviewRequest,
  requestId,
  isCalendarIconDisplay,
}) => {
  const classes = useStyles();
  const isStudentAlreadyMadeProposal =
    currentInterviewRequest?.preferred_dates &&
    currentInterviewRequest?.preferred_dates?.length > 0;

  return (
    <Box className={classes.mainContainer}>
      <ActionsButtons
        isElementsDisabled={isElementsDisabled}
        isStudentAlreadyMadeProposal={Boolean(isStudentAlreadyMadeProposal)}
        requestId={requestId}
        isCalendarIconDisplay={isCalendarIconDisplay}
        refetchMessageList={refetchMessageList}
        resetChatInput={resetChatInput}
        handleModalState={handleModalState}
      />
      <Input
        isElementsDisabled={isElementsDisabled}
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};

export default ChatInput;
