/* eslint-disable @typescript-eslint/ban-types */
import { FormSectionWrap } from "@components/UI/atoms/Forms/FormComponents";
import { Typography } from "@mui/material";
import React from "react";
import { Control, UseFormSetValue,UseFormWatch } from "react-hook-form";

import { DistributionEnum } from "../../..";
import { FormType } from "../usePackageDistributionModal";
import SchoolsAccordion from "./SchoolsAccordion";

interface Props {
  watch: UseFormWatch<FormType>;
  distributionType: DistributionEnum;
  control: Control<FormType, object>;
  setValue: UseFormSetValue<FormType>;
  hasAttachedTemplates: boolean;
}

const StepThree: React.FC<Props> = ({
  watch,
  distributionType,
  control,
  setValue,
  hasAttachedTemplates,
}) => {
  const getAttachedTemplates = () =>
    hasAttachedTemplates ? (
      <SchoolsAccordion control={control} setValue={setValue} watch={watch} />
    ) : (
      <span>提出物テンプレートがありません。</span>
    );

  const grepSchool = (type: DistributionEnum) => {
    switch (type) {
      case DistributionEnum.BY_SCHOOL:
      case DistributionEnum.BY_USER_TAG:
      case DistributionEnum.BY_LIMITED_USER_TAG:
        return getAttachedTemplates();
      default:
        return null;
    }
  };

  const schoolName = grepSchool(distributionType);
  return (
    <div>
      <FormSectionWrap>
        <Typography>
          {schoolName || (
            <span style={{ color: "red" }}>対象学校がありません。</span>
          )}
        </Typography>
      </FormSectionWrap>
    </div>
  );
};

export default StepThree;
