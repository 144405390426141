import CastleFlag from "@assets/images/svg/CastleFlag.svg?react";
import Document from "@assets/images/svg/documentIcon.svg?react";
import Pen from "@assets/images/svg/edit_pen.svg?react";
import HomeWork from "@assets/images/svg/homeworkIcon.svg?react";
import BallotIcon from "@mui/icons-material/Ballot";
import { Box,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DeliverableListBase } from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";
import { useTranslation } from "react-i18next";

import { LogsContent } from "../../hooks/useHandleStudyLogsByContentType";
import SectionItem from "./SectionItem";

type Props = {
  deliverableLog: DeliverableListBase;
  selectedContent: LogsContent | null;
  handleSelectedContent: (type: LogsContent) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(2),
      [theme.breakpoints.down("lg")]: {
        flexWrap: "wrap",
      },
    },
    iconStyle: {
      fill: theme.palette.tertiary.mainDark,
      width: "25px",
      height: "25px",
      marginRight: theme.spacing(1),
      alignSelf: "center",
    },
  }),
);

const LogsSections: React.FC<Props> = ({
  deliverableLog,
  selectedContent,
  handleSelectedContent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("dashboard");
  const sections = [
    {
      id: 1,
      title: t("learningPackage"),
      icon: <BallotIcon className={classes.iconStyle} />,
      count: deliverableLog.learning_packages.count,
      contentType: LogsContent.LEARNING_PACKAGE,
    },
    {
      id: 2,
      title: t("pbl"),
      icon: <CastleFlag className={classes.iconStyle} />,
      count: deliverableLog.articles.count,
      contentType: LogsContent.ARTICLE,
    },
    {
      id: 3,
      title: t("answers"),
      icon: <Document className={classes.iconStyle} />,
      count: deliverableLog.answers.count,
      contentType: LogsContent.REPORTS,
    },
    {
      id: 4,
      title: t("homework"),
      icon: <HomeWork className={classes.iconStyle} />,
      count: deliverableLog.homeworks.count,
      contentType: LogsContent.HOMEWORKS,
    },
    {
      id: 5,
      title: t("materialWork"),
      icon: <Pen className={classes.iconStyle} />,
      count: deliverableLog.material_works.count,
      contentType: LogsContent.MATERIAL_WORKS,
    },
  ];
  return (
    <Box className={classes.sectionContainer}>
      {sections.map((section) => (
        <SectionItem
          key={section.id}
          count={section.count ?? 0}
          icon={section.icon}
          title={section.title}
          contentType={section.contentType}
          selectedContent={selectedContent}
          handleSelectedContent={handleSelectedContent}
        />
      ))}
    </Box>
  );
};

export default LogsSections;
