import { Node } from "@tiptap/core";

const Div = Node.create({
  name: "div",
  content: "block+",
  group: "block",
  defining: true,
  addAttributes() {
    return {
      style: {
        default: null,
      },
      class: {
        default: null,
      },
      "data-placeholder": {
        default: null,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["div", HTMLAttributes, 0];
  },
});

export default Div;
