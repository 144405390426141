import { createTheme, Theme, useTheme } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import originalTheme from "@root/styles/theme";

import { initialBorderWidth } from "./Extension/Table/SVTable";

export const Device = {
  SMARTPHONE: "SMARTPHONE",
  TABLET: "TABLET",
  COMPUTER: "COMPUTER",
} as const;
export type DeviceType = (typeof Device)[keyof typeof Device];
export const SMARTPHONE_WIDTH = 414;
export const TABLET_WIDTH = 834;
export const COMPUTER_WIDTH = 1366;

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    DragIndicatorMenu: {
      backgroundColor: "whitesmoke",
      zIndex: 100,
      "&:hover": {
        opacity: 0.5,
      },
    },
    HTMLPreviewScroll: {
      overflowY: "scroll",
      padding: theme.spacing(1, 2),
      "scrollbar-width": "none" /* Firefox対応のスクロールバー非表示コード*/,
      "-ms-overflow-style":
        "none" /* Internet Explore対応のスクロールバー非表示コード*/,
      "&::-webkit-scrollbar": {
        display:
          "none" /* Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/,
      },
    },
    DivWrapper: {
      display: "flex",
    },
    SVEditorBaseWrapper: {
      display: "flex",
    },
    SVEditorWrapper: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "8px",
      padding: theme.spacing(1, 0),
      overflow: "hidden",
      "&:focus-within": {
        border: "1px solid #005745",
      },
    },
    SVEditorDefault: {
      width: "95%",
    },
    SelectedSVEditor: {
      borderRadius: "8px",
      width: "100%",
      backgroundColor: `${theme.palette.tertiary.mainDark}1A`,
    },
    draggableItemWrapper: {
      "& > div": {
        cursor: "auto",
      },
    },
    AddIcon: {
      margin: "0",
      padding: "0",
    },
    ProseMirror: {
      wordBreak: "break-all",
      overflow: "hidden",
      "& > div:nth-child(1)": {
        height: "100%",
      },
      "& .ProseMirror": {
        height: "100%",
        wordBreak: "break-all",
        overflow: "hidden",
        padding: theme.spacing(0, 2),
        "& p": {
          fontSize: "16px",
        },
      },
      "& .toggleList": {
        display: "flex",
        "& .content .summary[data-placeholder], .content .details[data-placeholder]":
          {
            "&::before": {
              color: "rgba(71, 81, 73, 0.5)",
              position: "absolute",
              content: "attr(data-placeholder)",
              pointerEvents: "none",
            },
          },
        "& .content .details.clicked": {
          display: "flex",
        },
        "& .content .details": {
          display: "none",
        },
      },
      "& .toggleList p": {
        margin: 0,
        display: "flex",
      },
      "& .toggleList button": {
        display: "flex",
        backgroundColor: "transparent",
        border: "0 solid transparent",
        "&::before": {
          content: '"\\25B6"!important',
        },
      },
      "& .toggleList button.clicked": {
        display: "flex",
        backgroundColor: "transparent",
        border: "0 solid transparent",
        "&::before": {
          content: '"\\25BC"!important',
        },
      },

      "& .ProseMirror-focused": {
        backgroundColor: `${theme.palette.tertiary.mainDark}1A`,
        borderRadius: "8px",
        outline: "initial",
      },
      "& .tableWrapper": {
        width: "100%",
      },
      "& table": {
        borderCollapse: "collapse",
        tableLayout: "fixed",
        width: "100%",
        margin: 0,
        zIndex: 100,
        "& .tableCell": {
          display: "table-cell",
        },
        "& td,th": {
          minWidth: "1em",
          maxWidth: "100em",
          border: `${initialBorderWidth}px solid #ced4da`,
          padding: "3px 5px",
          verticalAlign: "top",
          boxSizing: "border-box",
          position: "relative",
          "& > *": {
            marginBottom: 0,
          },
        },
        "& th": {
          fontWeight: "bold",
          textAlign: "left",
          backgroundColor: "#f1f3f5",
        },
        "& .selectedCell:after": {
          zIndex: 2,
          position: "absolute",
          content: "",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: "rgba(200, 200, 255, 0.4)",
          pointerEvents: "none",
        },
        "& .column-resize-handle": {
          position: "absolute",
          right: "-2px",
          top: 0,
          bottom: "-2px",
          width: "4px",
          backgroundColor: "#adf",
          pointerEvents: "none",
        },
      },
      "& a": {
        color: "inherit",
      },
    },
  });
});

export const getCheckBoxImage = (
  color = originalTheme.palette.tertiary.mainDark,
  width = 16,
  height = 16,
): string => {
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="${height}" width="${width}"  viewBox="0 0 24 24"><path stroke="${encodeURIComponent(
    color,
  )}" stroke-width="3" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`;
};

type TwoBlockStyle = {
  twoBlockEachDivStyle: string;
  twoBlockWrapperStyle: string;
};

export const getTwoBlockStyles = (deviceType?: DeviceType): TwoBlockStyle => {
  const screenWidth = document.documentElement.clientWidth;
  if (
    (deviceType && deviceType === Device.SMARTPHONE) ||
    screenWidth <= SMARTPHONE_WIDTH
  ) {
    return {
      twoBlockEachDivStyle:
        "word-break:break-word; width:100%; overflow:hidden",
      twoBlockWrapperStyle: "display:block",
    };
  }
  return {
    twoBlockEachDivStyle: "word-break:break-word; width:45%; overflow:hidden",
    twoBlockWrapperStyle: "display:flex; justify-content:space-between",
  };
};

export const getOneBlockStyles = (): string =>
  "overflow:hidden; word-break:break-word";

type useSVEditorStylesReturn = {
  SVEditorTheme: Theme;
  SVEditorClasses: ClassNameMap;
};

export const useSVEditorStyles = (): useSVEditorStylesReturn => {
  const SVEditorTheme = createTheme({
    ...useTheme(),
    breakpoints: {
      values: {
        xs: SMARTPHONE_WIDTH,
        sm: SMARTPHONE_WIDTH,
        md: TABLET_WIDTH,
        lg: COMPUTER_WIDTH,
        xl: COMPUTER_WIDTH,
      },
    },
  });

  const SVEditorClasses = useStyles(SVEditorTheme);

  return {
    SVEditorClasses,
    SVEditorTheme,
  };
};

export default useSVEditorStyles;
