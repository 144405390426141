/* eslint-disable camelcase */
import {
  Api,
  FetchUserTagsAsSuperOwnerParams,
  UserTagTypeKind,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { DeleteTagParams,PostTagParams, UpdateTagParams } from "../types/tag";

const api = new Api();

export const fetchAllUserTagsAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/user_tags/fetch_all",
  async (params: FetchUserTagsAsSuperOwnerParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.fetchUserTagsAsSuperOwner({
        ...params,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export enum RoleType {
  Student = "student",
  Teacher = "teacher",
}

export const fetchUserTagsAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/user_tags_list/fetch",
  async (params: FetchUserTagsAsSuperOwnerParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.fetchUserTagsAsSuperOwner({
        ...params,
        kind: UserTagTypeKind.Nationwide,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchLimitedUserTagsAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/limited_user_tags_list/fetch",
  async (params: FetchUserTagsAsSuperOwnerParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.fetchUserTagsAsSuperOwner({
        ...params,
        kind: UserTagTypeKind.Limited,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSuperOwnerUserTag = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/user_tags/post",
  async (data: PostTagParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.createUserTagAsSuperOwner({
        name: data.name,
        user_tag_type_id: data.tagTypeId,
        limit_number: data.limit,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSuperOwnerUserTag = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/user_tags/update",
  async (data: UpdateTagParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.updateUserTagAsSuperOwner(data.id, {
        name: data.name,
        limit_number: data.limit,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSuperOwnerUserTag = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/user_tags/delete",
  async (data: DeleteTagParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.deleteUserTagAsSuperOwner(data.id);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface SaveUserTagSequenceParams {
  sequences: Array<{
    id: string;
    sequence_number: number;
  }>;
}

export const saveUserTagSequence = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/userTag/saveSequence",
  async (data: SaveUserTagSequenceParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.userTagsSequenceUpdate(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction("super_owner/userTags/resetState");
