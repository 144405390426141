/* eslint-disable camelcase */

import {
  Api,
  GetLearningPackagesAsStudentParams,
  LearningPackageStatus,
} from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getLearningPackagesAsStudent = createAsyncThunk<
  any,
  GetLearningPackagesAsStudentParams,
  { state: RootState; rejectWithValue: any }
>(
  "studentApp/learning_package/fetchAll",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.student.getLearningPackagesAsStudent(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface PutLearningPackageTypes {
  id: string;
  data: { status: LearningPackageStatus };
}

export const editLearningPackagesAsStudent = createAsyncThunk<
  any,
  PutLearningPackageTypes,
  { state: RootState; rejectWithValue: any }
>("studentApp/learning_package/edit", async (params, { rejectWithValue }) => {
  const { id, data } = params;
  try {
    const response = await api.student.putLearningPackageAsStudent(id, data);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
