/* eslint-disable camelcase */
import { Api, FetchPaymentsAsTeacherParams, PaymentStatus } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { AddPaymentParamType } from "./types/payment";

const api = new Api();

export const fetchPaymentsAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/payments/fetch",
  async (params: FetchPaymentsAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchPaymentsAsTeacher(params, {});
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addPaymentAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/payments/create",
  async (params: AddPaymentParamType, { rejectWithValue }) => {
    try {
      const response = await api.teacher.postTeacherPayments(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export interface UpdatePaymentAsTeacherParams {
  status: PaymentStatus;
}
export interface UpdatePaymentAsTeacherArg {
  paymentId: string;
  params: UpdatePaymentAsTeacherParams;
}

export const updatePaymentAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/payments/update",
  async (
    { paymentId, params }: UpdatePaymentAsTeacherArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.teacher.updatePaymentAsTeacher(
        paymentId,
        params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetTeacherAppPaymentUpdateState = createAction(
  "teacher/payments/resetUpdateState",
);

export const resetTeacherAppPaymentCreateState = createAction(
  "teacher/payments/resetCreateState",
);
