import {
  deletePblBoardCampaignAsOwner,
  getPblBoardCampaignAsOwner,
  getPblBoardCampaignsAsOwner,
  postPblBoardCampaignAsOwner,
  putPblBoardCampaignAsOwner,
  resetOwnerAppPblBoardCampaignModalState,
  resetOwnerAppPblBoardCampaignState,
} from "@actions/ownerApp/pblBoard/campaign";
import { PblBoardCampaignBase,PblBoardCampaignList } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface OwnerAppPblBoardCampaignState {
  fetchPblBoardCampaigns: ReduxStatusType;
  fetchPblBoardCampaign: ReduxStatusType;
  postPblBoardCampaign: ReduxStatusType;
  putPblBoardCampaign: ReduxStatusType;
  deletePblBoardCampaign: ReduxStatusType;
  postPblBoardCampaignError: string | null;
  putPblBoardCampaignError: string | null;
  deletePblBoardCampaignError: string | null;
  pblBoardCampaigns: PblBoardCampaignList[];
  pblBoardCampaign: PblBoardCampaignBase | null;
  totalCount: number;
}

export const initialState: OwnerAppPblBoardCampaignState = {
  fetchPblBoardCampaigns: ReduxStatus.idle,
  fetchPblBoardCampaign: ReduxStatus.idle,
  postPblBoardCampaign: ReduxStatus.idle,
  putPblBoardCampaign: ReduxStatus.idle,
  deletePblBoardCampaign: ReduxStatus.idle,
  postPblBoardCampaignError: null,
  putPblBoardCampaignError: null,
  deletePblBoardCampaignError: null,
  pblBoardCampaigns: [],
  pblBoardCampaign: null,
  totalCount: 0,
};

const ownerAppPblBoardCampaignSlice = createSlice({
  name: "ownerApp/pblBoard/campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPblBoardCampaignsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchPblBoardCampaigns: ReduxStatus.pending,
        };
      })
      .addCase(getPblBoardCampaignsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchPblBoardCampaigns: ReduxStatus.rejected,
          pblBoardCampaigns: [],
        };
      })
      .addCase(getPblBoardCampaignsAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchPblBoardCampaigns: ReduxStatus.fulfilled,
          pblBoardCampaigns: payload.board_campaigns,
          totalCount: payload.total_count,
        };
      })
      .addCase(getPblBoardCampaignAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchPblBoardCampaign: ReduxStatus.pending,
        };
      })
      .addCase(getPblBoardCampaignAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchPblBoardCampaign: ReduxStatus.rejected,
          pblBoardCampaign: null,
        };
      })
      .addCase(getPblBoardCampaignAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchPblBoardCampaign: ReduxStatus.fulfilled,
          pblBoardCampaign: payload,
        };
      })
      .addCase(postPblBoardCampaignAsOwner.pending, (state, _action) => {
        return {
          ...state,
          postPblBoardCampaign: ReduxStatus.pending,
          postPblBoardCampaignError: null,
        };
      })
      .addCase(postPblBoardCampaignAsOwner.rejected, (state, action) => {
        return {
          ...state,
          postPblBoardCampaign: ReduxStatus.rejected,
          pblBoardCampaign: null,
          postPblBoardCampaignError: "作成に失敗しました。",
        };
      })
      .addCase(postPblBoardCampaignAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          postPblBoardCampaign: ReduxStatus.fulfilled,
          pblBoardCampaign: payload,
          postPblBoardCampaignError: null,
        };
      })
      .addCase(putPblBoardCampaignAsOwner.pending, (state, _action) => {
        return {
          ...state,
          putPblBoardCampaign: ReduxStatus.pending,
          putPblBoardCampaignError: null,
        };
      })
      .addCase(putPblBoardCampaignAsOwner.rejected, (state, action) => {
        return {
          ...state,
          putPblBoardCampaign: ReduxStatus.rejected,
          putPblBoardCampaignError: "更新に失敗しました。",
        };
      })
      .addCase(putPblBoardCampaignAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          putPblBoardCampaign: ReduxStatus.fulfilled,
          pblBoardCampaign: payload,
          putPblBoardCampaignError: null,
        };
      })
      .addCase(deletePblBoardCampaignAsOwner.pending, (state, _action) => {
        return {
          ...state,
          deletePblBoardCampaign: ReduxStatus.pending,
          deletePblBoardCampaignError: null,
        };
      })
      .addCase(deletePblBoardCampaignAsOwner.rejected, (state, action) => {
        return {
          ...state,
          deletePblBoardCampaign: ReduxStatus.rejected,
          deletePblBoardCampaignError: action.payload.error,
        };
      })
      .addCase(deletePblBoardCampaignAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          deletePblBoardCampaign: ReduxStatus.fulfilled,
          deletePblBoardCampaignError: null,
        };
      })
      .addCase(resetOwnerAppPblBoardCampaignState, (state, _action) => {
        return {
          ...state,
          fetchPblBoardCampaigns: ReduxStatus.idle,
          fetchMorePblBoardCampaigns: ReduxStatus.idle,
          pblBoardCampaigns: [],
          pblBoardCampaign: null,
        };
      })
      .addCase(resetOwnerAppPblBoardCampaignModalState, (state, _action) => {
        return {
          ...state,
          postPblBoardCampaign: ReduxStatus.idle,
          postPblBoardCampaignError: null,
          putPblBoardCampaign: ReduxStatus.idle,
          putPblBoardCampaignError: null,
          deletePblBoardCampaign: ReduxStatus.idle,
          deletePblBoardCampaignError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppPblBoardCampaignBoardState = (
  state: RootState,
): OwnerAppPblBoardCampaignState => state.ownerApp.pblBoardCampaign;

export default ownerAppPblBoardCampaignSlice.reducer;
