import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import MainWrapper from "@components/UI/organisms/Layout/MainWrapper";
import CommonHeader from "@components/UI/organisms/Navigation/Common/CommonHeader";
import { StudentLogsContext } from "@containers/Teacher/StudentLogs/StudentLogsContext";
import {
  DailyLogBase as DailyTypeFromApiTs,
  StudyLogBase,
  StudyLogCategorizableType,
} from "@lib/Api";
import { Container, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { teacherAppStudentLogsState } from "@reducers/teacherApp/studentLogs";
import { TEACHER_ROUTES } from "@root/constants/routes";
import { DailyLogBase } from "@root/endpoints/TimeTactApi/studentApi";
import { DeliverableListBase } from "@root/endpoints/TimeTactApi/teacherApi";
import { useAppSelector } from "@root/hooks";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import LogsActivity from "./Activity";
import Logs from "./Logs";
import LogsByStudent from "./LogsByStudent";
import LogsMainModal from "./LogsMainModal";
import LogsModal from "./Modal";
import StudentLogsGroupTabs from "./StudentLogsGroupTabs";

export enum StudyLogsModalState {
  Idle = "idle",
  Info = "info",
  Main = "main",
  Details = "details",
  Create = "create",
  Edit = "edit",
  Delete = "delete",
}

export type StudyLogsModalTypes = {
  open: boolean;
  modalState: StudyLogsModalState;
  step: 0 | 1 | 2 | 3 | 4 | 5;
  studentInfos?: DeliverableListBase;
  dailyInfos?: DailyLogBase | DailyTypeFromApiTs;
  lastWeek?: boolean;
};

export const defaultStudyLogsModalProps: StudyLogsModalTypes = {
  open: false,
  modalState: StudyLogsModalState.Idle,
  step: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionsContainer: {
      width: "100%",
      borderRadius: theme.spacing(1),
      boxShadow:
        "2px 2px 4px 0px rgba(71, 81, 73, 0.05),-2px -2px 4px 0px rgba(71, 81, 73, 0.05)",
      background: theme.palette.opacityColors.mainGreenTenPercentage,
      display: "flex",
      flexDirection: "column",
    },
  }),
);

const StudyLogs: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("header_menu");
  const { isGroupsFetching } = useContext(StudentLogsContext);
  const [modalState, setModalState] = useState<StudyLogsModalTypes>(
    defaultStudyLogsModalProps,
  );

  const { fetchingLogs, fetchingLogsByStudents, fetchingLogsActivity } =
    useAppSelector(teacherAppStudentLogsState);

  const handleInfoModalState = (infos: DeliverableListBase) => {
    setModalState({
      open: true,
      modalState: StudyLogsModalState.Info,
      step: 3,
      studentInfos: infos,
    });
  };

  const handleModalClose = () => {
    setModalState(defaultStudyLogsModalProps);
  };
  const history = useHistory();
  const { search } = useLocation();

  const handlePageRedirection = (log: StudyLogBase, studentId?: string) => {
    const from = TEACHER_ROUTES.STUDENTS_LOGS;
    const logStudentId = log.student?.user_id || studentId;
    const pathMap = {
      [StudyLogCategorizableType.Homework]: `${TEACHER_ROUTES.HOMEWORK_TEMPLATES}/${log.categorizable.parent_category_id}/homeworks/${log.categorizable_id}`,
      [StudyLogCategorizableType.MaterialWork]: `${TEACHER_ROUTES.USERS}/${logStudentId}/material_works/${log.categorizable_id}`,
      [StudyLogCategorizableType.PblCardsInformationLiteracyBookmark]: `${TEACHER_ROUTES.PBL_BOOKMARKS}/${log.categorizable_id}`,
      [StudyLogCategorizableType.PblReportAnswer]: `${TEACHER_ROUTES.PBL_REPORTS}/${log.categorizable.parent_category_id}/answers/${log.categorizable_id}`,
      [StudyLogCategorizableType.PblSteamComment]: `${TEACHER_ROUTES.STEAM}/lecture/${log.categorizable_id}`,
      [StudyLogCategorizableType.PblSteamContentWork]: `${TEACHER_ROUTES.STEAM}/content/${log.categorizable_id}`,
      [StudyLogCategorizableType.Article]: `${TEACHER_ROUTES.ARTICLES}/${log.categorizable_id}`,
      [StudyLogCategorizableType.Schedule]: `${TEACHER_ROUTES.CALENDAR}`,
    };
    const path = pathMap[log.categorizable_type as StudyLogCategorizableType];
    if (path) {
      history.push({
        pathname: path,
        state: { detail: log.categorizable_type, from, search },
      });
    }
  };

  let pageContent: React.ReactNode;
  if (
    isGroupsFetching ||
    fetchingLogs ||
    fetchingLogsByStudents ||
    fetchingLogsActivity
  ) {
    pageContent = <CircularLoading />;
  } else {
    pageContent = (
      <Container maxWidth="lg" className={classes.sectionsContainer}>
        <LogsByStudent handlePageRedirection={handlePageRedirection} />
        <LogsActivity setModalState={setModalState} />
        <Logs handleModalState={handleInfoModalState} />
      </Container>
    );
  }
  return (
    <>
      {modalState.open && modalState.step === 3 && (
        <LogsModal modalState={modalState} handleClose={handleModalClose} />
      )}
      <LogsMainModal
        modalState={modalState}
        handleClose={handleModalClose}
        setModalState={setModalState}
      />
      <CommonHeader title={t("student_logs")} />
      <MainWrapper>
        <StudentLogsGroupTabs />
        {pageContent}
      </MainWrapper>
    </>
  );
};

export default StudyLogs;
