/* eslint-disable camelcase */
import {
  deleteSteamContentRecommendationAsTeacher,
  getContentsAndRecommendationsAsTeacher,
  postSteamContentRecommendationAsTeacher,
  resetSteamContentRecommendationsState,
} from "@actions/teacherApp/steam/contentRecommendation";
import { SteamLibraryContentCard } from "@actions/types/steamLibrary";
import { ContentRecommendationBase } from "@lib/Api";
import { remove } from "@lib/collection";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface TeacherAppSteamContentRecommendationState {
  getStudentRecommendations: ReduxStatusType;
  recommendations: ContentRecommendationBase[];
  recommendationContents: SteamLibraryContentCard[];
  getRecommendationError: string | null;
  postRecommendation: ReduxStatusType;
  postRecommendationError: string | null;
  deleteRecommendation: ReduxStatusType;
  deleteRecommendationError: string | null;
  currentPage: number;
  totalCount: number;
}

const initialState: TeacherAppSteamContentRecommendationState = {
  getStudentRecommendations: ReduxStatus.idle,
  recommendations: [],
  recommendationContents: [],
  getRecommendationError: null,
  currentPage: 1,
  totalCount: 0,
  postRecommendation: ReduxStatus.idle,
  postRecommendationError: null,
  deleteRecommendation: ReduxStatus.idle,
  deleteRecommendationError: null,
};

const teacherAppSteamContentRecommendationSlice = createSlice({
  name: "TeacherApp/SteamRecommendation",
  initialState,
  reducers: {
    setRecommendationCurrentPage: (state, action: PayloadAction<number>) => {
      return { ...state, currentPage: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getContentsAndRecommendationsAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            getStudentRecommendations: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getContentsAndRecommendationsAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            getStudentRecommendations: ReduxStatus.fulfilled,
            totalCount: payload.total_count,
            recommendations: payload.steam_content_recommendations,
            recommendationContents: payload.recommendationContents,
          };
        },
      )
      .addCase(
        getContentsAndRecommendationsAsTeacher.rejected,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            getStudentRecommendations: ReduxStatus.rejected,
            getRecommendationError: getErrorMessage(payload.errors),
          };
        },
      )
      .addCase(
        postSteamContentRecommendationAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            postRecommendation: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        postSteamContentRecommendationAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            postRecommendation: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        postSteamContentRecommendationAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            postRecommendation: ReduxStatus.rejected,
            postRecommendationError: action.payload.message,
          };
        },
      )
      .addCase(
        deleteSteamContentRecommendationAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            deleteRecommendation: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        deleteSteamContentRecommendationAsTeacher.fulfilled,
        (state, action) => {
          const recommendationId = action.meta.arg;
          const { recommendations } = state;
          return {
            ...state,
            recommendations: remove(recommendations, recommendationId),
            deleteRecommendation: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        deleteSteamContentRecommendationAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            deleteRecommendation: ReduxStatus.rejected,
            deleteRecommendationError: action.payload.message,
          };
        },
      )
      .addCase(resetSteamContentRecommendationsState, (state, _action) => {
        return {
          ...state,
          getRecommendations: ReduxStatus.idle,
          getRecommendationsError: null,
          postRecommendation: ReduxStatus.idle,
          postRecommendationError: null,
          deleteRecommendation: ReduxStatus.idle,
          deleteRecommendationError: null,
        };
      });
  },
});

export const teacherAppSteamContentRecommendationState = (
  state: RootState,
): TeacherAppSteamContentRecommendationState =>
  state.teacherApp.steamContentRecommendation;

export const { setRecommendationCurrentPage } =
  teacherAppSteamContentRecommendationSlice.actions;
export default teacherAppSteamContentRecommendationSlice.reducer;
