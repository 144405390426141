// Authentication
export const SINGUP_PATH = "v1/auth";

// Owner
export const OWNER_CATEGORIES_PATH = "/owner/categories";
export const OWNER_PDFS_PATH = "/owner/image_contents";
export const OWNER_VIDEOS_PATH = "/owner/video_contents";

// Student
export const STUDENT_ARTICLES_PATH = "/student/articles";
export const STUDENT_HOMEWORKS_PATH = "/student/homeworks";
export const STUDENT_MATERIALS_PATH = "/student/material_works";
export const STUDENT_REPORT_ANSWERS_PATH = "/student/report_answers";
export const STUDENT_HOMEWORK_PATH = "/student/homework";
export const STUDENT_PBL_REPORT_ANSWER_IMAGES =
  "/student/pbl/report/answer_images";
export const STUDENT_PROFILE_PATH = "/student/users";
export const STUDENT_STEAM_LIBRARY_CONTENT_PATH =
  "/student/steam_library/content";
export const STUDENT_PBL_PROJECTS_LECTURES_PATH =
  "/student/pbl_projects/lectures";

// Teacher
export const TEACHER_ARTICLES_PATH = "/teacher/articles";
export const TEACHER_REPORT_TEMPLATES_PATH = "/teacher/report_templates";
export const TEACHER_CATEGORIES_PATH = "/teacher/categories";
export const TEACHER_PBL_PROJECTS_LECTURES_PATH =
  "/teacher/pbl_projects/lectures";
export const TEACHER_STEAM_LIBRARY_PATH = "/teacher/steam_library";
export const TEACHER_STEAM_LIBRARY_COMMENTS_PATH =
  "/teacher/steam_library/comments";
export const TEACHER_STEAM_LIBRARY_CONTENTS_PATH =
  "/teacher/steam_library/contents";
export const TEACHER_STEAM_LIBRARY_CONTENT_PATH =
  "/teacher/steam_library/content";
export const TEACHER_STEAM_LIBRARY_LECTURE_PATH =
  "/teacher/steam_library/lecture";

// SuperOwner
export const SUPER_OWNER_CATEGORIES_PATH = "/super_owner/categories";
export const SUPER_OWNER_SCHOOLS_PATH = "/super_owner/companies";
export const SUPER_OWNER_HOMEWORK_TEMPLATE_DISTRIBUTIONS_PATH =
  "/super_owner/homework_templates_distribution_histories";
