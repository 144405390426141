import React, { lazy,Suspense } from "react";

import { PieGraphStatsProps } from "./PieGraphStats";

const PieGraphStats = lazy(() => import("./PieGraphStats"));

const LazyPieGraphStats = (props: PieGraphStatsProps): JSX.Element => {
  return (
    <Suspense fallback={<></>}>
      <PieGraphStats {...props} />
    </Suspense>
  );
};

export default LazyPieGraphStats;
