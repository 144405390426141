import V2GroupTabs from "@components/UIv2/molecules/V2Tabs/group";
import { StudentLogsContext } from "@containers/Teacher/StudentLogs/StudentLogsContext";
import { Container, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  }),
);

const StudentLogsGroupTabs: React.FC = () => {
  const { groups, selectedGroupId, handleGroupChange } =
    useContext(StudentLogsContext);

  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <V2GroupTabs
        groups={groups}
        selectedGroupId={selectedGroupId}
        handleChange={handleGroupChange}
      />
    </Container>
  );
};

export default StudentLogsGroupTabs;
