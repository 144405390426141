// import useRevisionUp from "@lib/hooks/useRevisionUp";
import "./App.css";

import {
  getCurrentUser,
  hideErrorPage,
  proxyLogout,
  showErrorPage,
} from "@actions/auth";
import InAppBrowserWarning from "@components/Common/InAppBrowserWarning";
import { useAuth } from "@contexts/Auth";
import makeAppInfo from "@lib/app/app-info-utils";
import useUrlAppName from "@lib/hooks/useAppName";
import useOpenReplay from "@lib/hooks/useOpenReplay";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { jaJP, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import ErrorPage from "./components/UI/organisms/Error/ErrorPage";
import { ADMIN_ROUTES } from "./constants/routes";
import { useAppDispatch } from "./hooks";
import Routes from "./Routes";
import useToast from "./useToast";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    returnButton: {
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.white}`,
      marginRight: theme.spacing(1),
    },
  }),
);

const App: React.FC = () => {
  const classes = useStyles();
  const { proxy, currentUser } = useAuth();
  useOpenReplay(currentUser);
  useToast();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const handleError = (
    error: Error,
    info: {
      componentStack: string;
    },
  ) => {
    if (import.meta.env.MODE === "development") {
      // eslint-disable-next-line no-console
      console.log(new Error(error.message));
    } else {
      dispatch(showErrorPage());
    }
  };

  const handleLogout = () => {
    dispatch(proxyLogout());
    history.push(ADMIN_ROUTES.COMPANIES);
    dispatch(getCurrentUser({}));
  };

  const appNameParam = useUrlAppName();
  const { AppFaviconIco, appName } = makeAppInfo(
    currentUser ? currentUser.app_name : appNameParam,
  );
  // useRevisionUp();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language}
      localeText={
        jaJP.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <HelmetProvider>
        <Helmet>
          <title>{appName}</title>
          <link
            rel="icon"
            type="image/png"
            href={AppFaviconIco}
            sizes="64x64 32x32 24x24 16x16"
            crossOrigin="anonymous"
          />
        </Helmet>
        <ErrorBoundary
          FallbackComponent={ErrorPage}
          onError={handleError}
          onReset={() => {
            history.push("/");
            dispatch(hideErrorPage());
          }}
        >
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!proxy}
            transitionDuration={0}
          >
            <Alert
              variant="filled"
              severity="warning"
              icon={<SupervisedUserCircleIcon />}
              action={
                <Button
                  variant="outlined"
                  onClick={handleLogout}
                  className={classes.returnButton}
                >
                  終了
                </Button>
              }
            >
              代理ログインしています
            </Alert>
          </Snackbar>
          <InAppBrowserWarning />
          <Routes />
        </ErrorBoundary>
      </HelmetProvider>
    </LocalizationProvider>
  );
};

export default App;
