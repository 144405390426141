import { BaseCategoryInfo, GradeCategoryInfo } from "@lib/Api";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { MiddleCategoryState } from "@reducers/category/middle";
import { MinorCategoryState } from "@reducers/category/minor";
import React from "react";

import { StyledAccordion, StyledAccordionSummary } from "..";
import MiddleLayer from "./MiddleLayer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    majortext: {
      fontSize: 16,
      marginLeft: theme.spacing(4),
      cursor: "pointer",
    },
    card: {
      borderRadius: 0,
    },
    summary: {
      backgroundColor: theme.palette.common.white,
    },
    activeSummary: {
      backgroundColor: theme.palette.grey[100],
    },
  }),
);

interface Props {
  categoryItem: GradeCategoryInfo;
  middle: MiddleCategoryState;
  minor: MinorCategoryState;
  selectedCategoryId: string[];
  handleSelectedIds: (item: BaseCategoryInfo) => void;
}

const MajorLayer: React.FC<Props> = (props: Props) => {
  const { categoryItem, middle, minor, selectedCategoryId, handleSelectedIds } =
    props;

  const classes = useStyles();

  return (
    <StyledAccordion expanded square className={classes.card}>
      <StyledAccordionSummary
        className={
          selectedCategoryId.includes(categoryItem.id)
            ? classes.activeSummary
            : classes.summary
        }
        onClick={() => handleSelectedIds(categoryItem)}
      >
        <Typography className={classes.majortext}>
          {categoryItem.name}
        </Typography>
      </StyledAccordionSummary>
      {middle.items
        .filter((m) => categoryItem.id === m.major_category_id)
        .map((majorItem) => (
          <MiddleLayer
            key={majorItem.id}
            categoryItem={majorItem}
            minor={minor}
            selectedCategoryId={selectedCategoryId}
            handleSelectedIds={handleSelectedIds}
          />
        ))}
    </StyledAccordion>
  );
};

export default MajorLayer;
