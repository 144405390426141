/* eslint-disable camelcase */
import {
  AccountUserInfo,
  Api,
  CompaniesOwnersDetailParams,
  CompaniesStudentsDetailParams,
  CompaniesSuperOwnersDetailParams,
  CompaniesTeachersDetailParams,
  CreateAccountUserRequestBody,
  FetchReviewersAsSuperOwnerParams,
  ReviewerDetail,
  ReviewerInfoAttributes,
  UserAccountType,
  UserAuthorization,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchOwnersAsSuperOwner = createAsyncThunk<
  any,
  CompaniesOwnersDetailParams & { fetchAll?: boolean },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/owners/fetch", async (data, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.companiesOwnersDetail(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchTeachersAsSuperOwner = createAsyncThunk<
  any,
  CompaniesTeachersDetailParams & { fetchAll?: boolean },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/teachers/fetch", async (data, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.companiesTeachersDetail(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchStudentsAsSuperOwner = createAsyncThunk<
  any,
  CompaniesStudentsDetailParams & { fetchAll?: boolean },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/students/fetch", async (data, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.companiesStudentsDetail(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchSuperOwnersAsSuperOwner = createAsyncThunk<
  any,
  CompaniesSuperOwnersDetailParams & { fetchAll?: boolean },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/superOwners/fetch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.companiesSuperOwnersDetail(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchReviewersAsSuperOwner = createAsyncThunk<
  { reviewers: ReviewerDetail[]; total_count: number },
  FetchReviewersAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewers/fetch",
  async ({ company_id, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.fetchReviewersAsSuperOwner({
        company_id,
        per_page,
        page,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export type CreateUserAsSuperOwnerParams = {
  index?: number;
  company_id: string;
  user_tag_ids?: string[];
  data: {
    email?: string;
    last_name?: string;
    first_name?: string;
    nickname?: string;
    optional_email?: string;
    role?: string;
    authorizations?: UserAuthorization;
    user_tag_ids?: string[];
    student_info_attributes?: {
      grade?: string;
      parent_last_name?: string;
      parent_first_name?: string;
      postcode?: string;
      prefecture?: string;
      address1?: string;
      address2?: string;
      phone?: string;
      birth_date?: string;
      number?: number;
    };
    teacher_info_attributes?: {
      postcode?: string;
      prefecture?: string;
      address1?: string;
      address2?: string;
    };
    invitation_scheduled_at?: string;
  };
};

export const createUserAsSuperOwner = createAsyncThunk<
  { message?: string },
  CreateUserAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/user/create", async (params, { rejectWithValue }) => {
  try {
    const { company_id, data } = params;
    const response = await api.superOwner.createUserAsSuperOwner(
      company_id,
      data,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue({
      error: err.response.data,
      requestIndex: params.index,
    });
  }
});
export type CreateAccountUserAsSuperOwnerParams = {
  index?: number;
  company_id: string;
  data: CreateAccountUserRequestBody;
  user_tag_ids?: string[];
};

export const createAccountUserAsSuperOwner = createAsyncThunk<
  any,
  CreateAccountUserAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/user/create/accountUser",
  async (params, { rejectWithValue }) => {
    try {
      const { company_id, data, user_tag_ids: userTagIds } = params;
      const formatData = { ...data, user_tag_ids: userTagIds ?? [] };
      const response = await api.superOwner.createAccountUserAsSuperOwner(
        company_id,
        formatData,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue({
        error: err.response.data,
        requestIndex: params.index,
      });
    }
  },
);

type ResetPasswordAsSuperOwnerParams = {
  id: string;
  data: {
    password: string;
    affiliate_id?: string;
  };
};

export const resetPasswordAsSuperOwner = createAsyncThunk<
  AccountUserInfo,
  ResetPasswordAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/user/reset_password",
  async (params, { rejectWithValue }) => {
    try {
      const { id, data } = params;
      const response = await api.superOwner.resetPasswordAsSuperOwner(id, data);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        error: err.response.data,
      });
    }
  },
);
export interface CreateReviewerAsSuperOwnerArg {
  index?: number;
  companyId: string;
  params: {
    email: string;
    first_name: string;
    last_name: string;
  };
}

export const createReviewerAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewers/create",
  async (data: CreateReviewerAsSuperOwnerArg, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.createReviewerAsSuperOwner(
        data.companyId,
        data.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue({
        error: err.response.data,
        requestIndex: data.index,
      });
    }
  },
);

export type UpdateUserAsSuperOwnerParams = {
  index?: number;
  company_id: string;
  id: string;
  data: {
    last_name?: string;
    first_name?: string;
    optional_email?: string | null;
    user_type?: UserAccountType;
    new_id?: string;
    authorizations?: UserAuthorization;
    user_tag_ids?: string[] | null;
    group_ids?: string[] | null;
    role?: "teacher" | "owner";
    student_info_attributes?: {
      grade?: string;
      parent_last_name?: string;
      parent_first_name?: string;
      postcode?: string;
      prefecture?: string;
      address1?: string;
      address2?: string;
      phone?: string;
      birth_date?: string;
    };
    teacher_info_attributes?: {
      postcode?: string;
      prefecture?: string;
      address1?: string;
      address2?: string;
    };
  };
};

export const updateUserAsSuperOwner = createAsyncThunk<
  any,
  UpdateUserAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/user/update", async (args, { rejectWithValue }) => {
  try {
    const { company_id, id, data } = args;
    const response = await api.superOwner.companiesUsersUpdate(
      company_id,
      id,
      data,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue({
      error: err.response.data,
      requestIndex: args.index,
    });
  }
});

export interface UpdateReviewerAsSuperOwnerArg {
  companyId: string;
  userId: string;
  data: {
    first_name?: string;
    last_name?: string;
    reviewer_info_attributes?: ReviewerInfoAttributes;
    nickname?: string;
  };
}

export const updateReviewerAsSuperOwner = createAsyncThunk<
  ReviewerDetail,
  UpdateReviewerAsSuperOwnerArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewers/update",
  async ({ companyId, userId, data }, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.updateReviewerAsSuperOwner(
        companyId,
        userId,
        data,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response.data));
    }
  },
);

export type DeleteUserAsSuperOwnerParams = {
  id: string;
  companyId: string;
  batch?: boolean;
  roleType: string;
};

export const deleteUserAsSuperOwner = createAsyncThunk<
  any,
  DeleteUserAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/companies/user/delete", async (args, { rejectWithValue }) => {
  const { companyId, id, roleType } = args;
  try {
    const response = await api.superOwner.deleteUserAsSuperOwner(
      companyId,
      id,
      {
        role: roleType,
      },
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export interface DeleteReviewerAsSuperOwnerArg {
  companyId: string;
  userId: string;
}

export const deleteReviewerAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companies/reviewers/delete",
  async (
    { companyId, userId }: DeleteReviewerAsSuperOwnerArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.superOwner.deleteReviewerAsSuperOwner(
        companyId,
        userId,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "super_owner/companies/owners/resetState",
);

export const resetReviewersState = createAction(
  "super_owner/companies/reviewers/resetState",
);

export const resetModalState = createAction(
  "super_owner/companies/users/resetModalState",
);

export const resetBatchDeleteState = createAction(
  "super_owner/companies/owner/resetBatchDeleteState",
);

export const resetBatchCreateState = createAction(
  "super_owner/companies/reviewers/resetBatchCreateState",
);
