/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchCategoryRenameMapAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/categoryRenameMap/fetchOne",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.admin.fetchCategoryRenameMapByCompanyIdAsAdmin(companyId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface CreateCategoryRenameMapAsAdminArg {
  company_id: string;
  for_text?: string | undefined;
  for_subject?: string | undefined;
  for_grade?: string | undefined;
  for_major?: string | undefined;
  for_middle?: string | undefined;
  for_minor?: string | undefined;
}

export const createCategoryRenameMapAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/categoryRenameMap/create",
  async (params: CreateCategoryRenameMapAsAdminArg, { rejectWithValue }) => {
    try {
      const response = await api.admin.createCategoryRenameMapAsAdmin(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface UpdateCategoryRenameMapAsAdminArg {
  id: string;
  params: {
    for_text?: string | undefined;
    for_subject?: string | undefined;
    for_grade?: string | undefined;
    for_major?: string | undefined;
    for_middle?: string | undefined;
    for_minor?: string | undefined;
  };
}

export const updateCategoryRenameMapAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/categoryRenameMap/update",
  async (arg: UpdateCategoryRenameMapAsAdminArg, { rejectWithValue }) => {
    try {
      const response = await api.admin.updateCategoryRenameMapAsAdmin(
        arg.id,
        arg.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetOne = createAction("admin/categoryRenameMaps/resetOne");
