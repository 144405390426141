import Section from "@components/UI/organisms/Layout/SectionWrapper";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import { ITEMS_PER_PAGE } from "@constants/page";
import { StudentLogsContext } from "@containers/Teacher/StudentLogs/StudentLogsContext";
import errorWithMessage from "@lib/rtk/error-utils";
import { Box, Theme,Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DeliverableListBase } from "@root/endpoints/TimeTactApi/teacherApi";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import useHandleStudentsLogs from "./hooks/useHandleStudentsLogs";
import StudentLogsItem from "./LogsStudentItem";
import StudentLogsItemSkeleton from "./LogsStudentItem/LogsSkeleton/StudentLogsItemSkeleton";
import SearchBar from "./SearchBar";

interface Props {
  handleModalState: (infos: DeliverableListBase) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logsContentContainer: {
      width: "100%",
      flexDirection: "column",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(4),
    },
  }),
);

const Logs: React.FC<Props> = ({ handleModalState }) => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "study_logs"]);
  const { selectedGroupId } = useContext(StudentLogsContext);

  const { states, actions } = useHandleStudentsLogs({
    groupAffiliateId: selectedGroupId,
  });
  const { handlePagination, onSubmit, handleSubmit } = actions;
  const {
    studentsLogsError,
    fetchingDeliverableList,
    fetchedDeliverableList,
    totalCount,
    currentPage,
    studentLogsDeliverableList,
    control,
  } = states;

  let content: React.ReactNode;

  if (fetchingDeliverableList) {
    content = (
      <Box className={classes.logsContentContainer}>
        <StudentLogsItemSkeleton />
      </Box>
    );
  } else if (fetchedDeliverableList && studentLogsDeliverableList.length) {
    content = (
      <Box className={classes.logsContentContainer}>
        {studentLogsDeliverableList.map((deliverableLog) => (
          <StudentLogsItem
            key={deliverableLog.student_user_id}
            deliverableLog={deliverableLog}
            handleModalState={handleModalState}
          />
        ))}
      </Box>
    );
  } else {
    content = (
      <Box m={2}>
        <Typography>
          {studentsLogsError
            ? errorWithMessage(studentsLogsError)
            : t("noData")}
        </Typography>
      </Box>
    );
  }

  return (
    <Section title={t("study_logs:logsByStudent")}>
      <SearchBar
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      />
      {content}
      <V2Pagination
        totalCount={totalCount}
        perPage={ITEMS_PER_PAGE}
        currentPage={currentPage}
        handlePageTransition={handlePagination}
      />
    </Section>
  );
};

export default Logs;
