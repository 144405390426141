import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";

interface Props {
  progressMax: number;
  progressValue: number;
  percentage: boolean;
}

interface BorderLinearProgressProps extends LinearProgressProps {
  progressMax: number;
  progressValue: number;
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: (props: BorderLinearProgressProps) =>
        props.progressMax === 0 && props.progressValue === 0
          ? theme.palette.grey[theme.palette.mode === "light" ? 200 : 700]
          : theme.palette.tertiary.main,
    },
  }),
)(LinearProgress);

function LinearProgressWithLabel(
  props: LinearProgressProps & {
    value: number;
    percentage: boolean;
    progressMax: number;
    progressValue: number;
  },
) {
  const { percentage, progressMax, progressValue, ...rest } = props;
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress
          variant="determinate"
          data-cy="common-progress-bar"
          progressMax={progressMax}
          progressValue={progressValue}
          {...rest}
        />
      </Box>
      {percentage ? (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            rest.value,
          )}%`}</Typography>
        </Box>
      ) : (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {progressValue}/{progressMax}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

const LinearProgressBar: React.FC<Props> = React.memo(
  ({ progressMax, progressValue, percentage }) => {
    const normalise = (value: number) =>
      ((value - 0) * 100) / (progressMax - 0);
    return (
      <LinearProgressWithLabel
        progressMax={progressMax}
        progressValue={progressValue}
        variant="determinate"
        value={normalise(progressValue)}
        percentage={percentage}
      />
    );
  },
);

export default LinearProgressBar;
