export type AttachmentInfo = {
  id: string;
  title?: string | null;
  url?: string | null;
  [k: string]: any; // To be compatible with all additional parameters
};

export type AttachmentFileModalStateType = {
  isOpen: boolean;
  modalUrl: string;
  modalTitle: string;
};

export type AttachmentDeleteModalStateType = AttachmentFileModalStateType & {
  modalImage: string;
};

export const defaultFileModalState: AttachmentFileModalStateType = {
  isOpen: false,
  modalUrl: "",
  modalTitle: "",
};
export const defaultDeleteModalState: AttachmentDeleteModalStateType = {
  ...defaultFileModalState,
  modalImage: "",
};
