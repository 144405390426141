import {
  fetchDailyLogsAsStudent,
  fetchStudyLogsAsStudent,
  postDailyLogAsStudent,
  postStudyLogsAsStudent,
  putDailyLogAsStudent,
  resetState,
} from "@actions/studentApp/student_logs";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { DailyLogBase, StudyLogBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface StudentAppStudentLogsState {
  fetching: boolean;
  fetched: boolean;
  fetchError: string | null;
  fetchingStudyLogs: boolean;
  fetchedStudyLogs: boolean;
  fetchLogsError: string | null;
  posting: boolean;
  posted: boolean;
  postError: any;
  editing: boolean;
  edited: boolean;
  editError: any;
  postStudyLogStatus: ReduxStatusType;
  postStudyLogError: any;
  studentLogs: DailyLogBase[];
  studyLogs: StudyLogBase[];
  totalLogsCount: number;
  totalStudyLogsCount: number;
}

export const initialState: StudentAppStudentLogsState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  fetchingStudyLogs: false,
  fetchedStudyLogs: false,
  fetchLogsError: null,
  posting: false,
  posted: false,
  postError: null,
  editing: false,
  edited: false,
  editError: null,
  postStudyLogStatus: ReduxStatus.idle,
  postStudyLogError: null,
  studentLogs: [],
  studyLogs: [],
  totalLogsCount: 0,
  totalStudyLogsCount: 0,
};

const studentAppStudentLogsSlice = createSlice({
  name: "studentApp/student_logs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyLogsAsStudent.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: null,
        };
      })
      .addCase(fetchDailyLogsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.payload,
        };
      })
      .addCase(fetchDailyLogsAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          studentLogs: payload.daily_logs,
          totalLogsCount: payload.total_count,
        };
      })
      .addCase(fetchStudyLogsAsStudent.pending, (state, action) => {
        return {
          ...state,
          fetchingStudyLogs: true,
          fetchedStudyLogs: false,
          fetchLogsError: null,
        };
      })
      .addCase(fetchStudyLogsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingStudyLogs: false,
          fetchedStudyLogs: false,
          fetchLogsError: action.payload,
        };
      })
      .addCase(fetchStudyLogsAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingStudyLogs: false,
          fetchedStudyLogs: true,
          studyLogs: payload.study_logs,
          totalStudyLogsCount: payload.total_count,
        };
      })
      .addCase(postDailyLogAsStudent.pending, (state, action) => {
        return {
          ...state,
          posting: true,
          posted: false,
        };
      })
      .addCase(postDailyLogAsStudent.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postError: action.error.message,
        };
      })
      .addCase(postDailyLogAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
        };
      })
      .addCase(putDailyLogAsStudent.pending, (state, action) => {
        return {
          ...state,
          editing: true,
          edited: false,
        };
      })
      .addCase(putDailyLogAsStudent.rejected, (state, action) => {
        return {
          ...state,
          editing: false,
          edited: false,
          editError: action.error.message,
        };
      })
      .addCase(putDailyLogAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          editing: false,
          edited: true,
        };
      })
      .addCase(postStudyLogsAsStudent.pending, (state, action) => {
        return {
          ...state,
          postStudyLogStatus: ReduxStatus.pending,
        };
      })
      .addCase(postStudyLogsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          postStudyLogStatus: ReduxStatus.rejected,
          postStudyLogError: action.error.message,
        };
      })
      .addCase(postStudyLogsAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          postStudyLogStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(resetState, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: null,
          fetchingStudyLogs: false,
          fetchedStudyLogs: false,
          fetchLogsError: null,
          posting: false,
          posted: false,
          postError: null,
          editing: false,
          edited: false,
          editError: null,
          postStudyLogStatus: ReduxStatus.idle,
          postStudyLogError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppStudentLogsState = (
  state: RootState,
): StudentAppStudentLogsState => state.studentApp.studentLogs;

export default studentAppStudentLogsSlice.reducer;
