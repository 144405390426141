import {
  ContentWithNumberOfLectures,
  SteamLibraryContentImage,
} from "@actions/types/steamLibrary";
import { setRTKQueryHeaders } from "@lib/http";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const steamLibraryApi = createApi({
  reducerPath: "steamLibraryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://prod-api.steam-library.go.jp",
    prepareHeaders: (headers) => setRTKQueryHeaders(headers),
  }),
  endpoints: (builder) => ({
    getSteamLibraryContentImages: builder.query<
      { contents: SteamLibraryContentImage[] },
      number[]
    >({
      query: (contentIds) => {
        const params = contentIds.map((id) => `content_ids[]=${id}`);
        return `/contents/get_datas?${params.join("&")}`;
      },
    }),
    getSteamLibraryLecturesNumber: builder.query<
      { contents: ContentWithNumberOfLectures[] },
      number[]
    >({
      query: (contentIds) => {
        const params = contentIds.map((id) => `content_ids[]=${id}`);
        return `/contents/number_of_lectures?${params.join("&")}`;
      },
    }),
  }),
});

export const {
  useGetSteamLibraryContentImagesQuery,
  useGetSteamLibraryLecturesNumberQuery,
} = steamLibraryApi;
