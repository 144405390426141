import {
  deletePblCardBookmarkImageAsTeacher,
  fetchPblCardBookmarkAsTeacher,
  fetchPblCardBookmarkStatsAsTeacher,
  resetOne,
} from "@actions/teacherApp/pbl/cards/information_literacy/bookmark";
import {
  createPblCardBookmarkCommentAsTeacher,
  deletePblCardBookmarkCommentAsTeacher,
} from "@actions/teacherApp/pbl/cards/information_literacy/comment";
import { PblCardBookmarkBase, PblCardBookmarkStats } from "@lib/Api";
import { remove } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface TeacherAppPblCardBookmarkState {
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneError: string | null;
  fetchingStats: boolean;
  fetchedStats: boolean;
  fetchStatsError: string | null;
  bookmark: PblCardBookmarkBase | null;
  bookmarkStats: PblCardBookmarkStats[];
  bookmarkInModal: PblCardBookmarkBase | null;
  deletedImage: boolean;
  deletingImageModal: boolean;
  deletedImageModal: boolean;
}

export const initialState: TeacherAppPblCardBookmarkState = {
  fetchingOne: false,
  fetchedOne: false,
  fetchingStats: false,
  fetchedStats: false,
  fetchStatsError: null,
  fetchOneError: null,
  bookmarkStats: [],
  bookmark: null,
  bookmarkInModal: null,
  deletedImage: false,
  deletingImageModal: false,
  deletedImageModal: false,
};

const teacherAppPblCardBookmarkSlice = createSlice({
  name: "TeacherApp/Pbl/Card/Bookmark",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblCardBookmarkAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchOneError: null,
          bookmark: null,
        };
      })
      .addCase(fetchPblCardBookmarkAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: action.error.message as string,
          bookmark: null,
        };
      })
      .addCase(fetchPblCardBookmarkAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: null,
          bookmark: payload,
        };
      })
      .addCase(fetchPblCardBookmarkStatsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingStats: true,
          fetchedStats: false,
          fetchStatsError: null,
        };
      })
      .addCase(
        fetchPblCardBookmarkStatsAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          const { bookmarks } = payload;
          return {
            ...state,
            fetchingStats: false,
            fetchedStats: true,
            fetchStatsError: null,
            bookmarkStats: bookmarks,
          };
        },
      )
      .addCase(fetchPblCardBookmarkStatsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingStats: false,
          fetchedStats: false,
          fetchStatsError: action.error.message as string,
        };
      })
      .addCase(
        createPblCardBookmarkCommentAsTeacher.fulfilled,
        (state, action) => {
          const currentState = current(state);
          let newBookmark = currentState.bookmark;
          if (
            currentState.bookmark &&
            typeof currentState.bookmark !== "undefined"
          ) {
            newBookmark = {
              ...currentState.bookmark,
              num_comments: currentState.bookmark
                ? currentState.bookmark.num_comments + 1
                : 0,
            };
          }
          return {
            ...state,
            bookmark: newBookmark,
          };
        },
      )
      .addCase(
        deletePblCardBookmarkCommentAsTeacher.fulfilled,
        (state, action) => {
          const currentState = current(state);
          let newBookmark = currentState.bookmark;
          if (
            currentState.bookmark &&
            typeof currentState.bookmark !== "undefined"
          ) {
            newBookmark = {
              ...currentState.bookmark,
              num_comments: currentState.bookmark
                ? currentState.bookmark.num_comments - 1
                : 0,
            };
          }
          return {
            ...state,
            bookmark: newBookmark,
          };
        },
      )
      // Image
      .addCase(deletePblCardBookmarkImageAsTeacher.pending, (state, action) => {
        return {
          ...state,
          deletingImage: true,
          deletedImage: false,
        };
      })
      .addCase(
        deletePblCardBookmarkImageAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            deletingImage: false,
            deletedImage: false,
          };
        },
      )
      // image
      .addCase(
        deletePblCardBookmarkImageAsTeacher.fulfilled,
        (state, action) => {
          const { meta } = action;
          const currentState = current(state);
          let newBookmark = currentState.bookmark;
          if (
            currentState.bookmark &&
            typeof currentState.bookmark !== "undefined"
          ) {
            newBookmark = {
              ...currentState.bookmark,
              images: remove(currentState.bookmark.images, meta.arg),
            };
          }
          return {
            ...state,
            deletingImage: false,
            deletedImage: true,
            bookmark: newBookmark,
          };
        },
      )
      .addCase(resetOne, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          bookmark: null,
          bookmarkInModal: null,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppPblCardBookmarkState = (
  state: RootState,
): TeacherAppPblCardBookmarkState => state.teacherApp.pblCardBookmark;

export default teacherAppPblCardBookmarkSlice.reducer;
