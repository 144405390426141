/* eslint-disable camelcase */
import {
  CommunizedStatusCode,
  getTagColorByStatusCode,
} from "@components/UI/atoms/StyledTag/getTagColorByStatusCode";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import { ITEMS_PER_PAGE } from "@constants/page";
import { ArticleStatus } from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { useHandleNickName } from "@lib/string-utils";
import {
  Box,
  Collapse,
  Container,
  Divider,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  DeliverableListBase,
  StudentLogDeliverableArticleBase,
} from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaUser } from "react-icons/fa";

import { useHandleStudyLogsByContentType } from "../hooks/useHandleStudyLogsByContentType";
import LogsSectionData from "./LogsSectionData";
import LogsSectionDataSkeleton from "./LogsSectionData/LogSectionDataSkeleton";
import LogsSections from "./LogsSections";

type Props = {
  deliverableLog: DeliverableListBase;
  handleModalState: (infos: DeliverableListBase) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: theme.spacing(2),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      cursor: "pointer",
      border: `2px solid ${theme.palette.quaternary.grey}`,
      "&:hover": {
        border: `2px solid ${theme.palette.tertiary.mainDark}`,
      },
    },
    studentNameContainer: {
      width: "fit-content",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.quaternary.grey}`,
    },
    studentName: {
      color: theme.palette.customColor.text,
      fontWeight: 700,
    },
    iconStyle: {
      color: theme.palette.tertiary.main,
      fontSize: "20px",
    },
    logsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      gap: theme.spacing(1),
    },
    dividerRoot: {
      margin: theme.spacing(2, 0),
    },
  }),
);

const StudentLogsItem: React.FC<Props> = ({
  deliverableLog,
  handleModalState,
}) => {
  const classes = useStyles();
  const {
    actions: { handleNickName },
  } = useHandleNickName();
  const { t } = useTranslation("common");
  const logSectionContentStates = useHandleStudyLogsByContentType(
    deliverableLog.student_affiliate_id,
  );

  return (
    <Box
      onClick={() => handleModalState(deliverableLog)}
      className={classes.mainContainer}
    >
      <Box className={classes.studentNameContainer}>
        <span>
          <FaUser className={classes.iconStyle} />
        </span>
        <Typography className={classes.studentName}>
          {handleNickName({
            nickName: deliverableLog.nickname,
            fullName: deliverableLog.full_name,
          })}
        </Typography>
      </Box>
      <LogsSections
        selectedContent={logSectionContentStates.selectedContent}
        deliverableLog={deliverableLog}
        handleSelectedContent={logSectionContentStates.handleSelectedContent}
      />
      <Collapse
        style={{ width: "100%" }}
        in={logSectionContentStates.openModule}
        timeout="auto"
        unmountOnExit
      >
        <Container style={{ width: "100%" }}>
          <Divider
            sx={{ opacity: "0.6" }}
            classes={{ root: classes.dividerRoot }}
          />
          {logSectionContentStates &&
            Object.keys(logSectionContentStates).length &&
            logSectionContentStates.logsList && (
              <>
                {(() => {
                  if (logSectionContentStates.isFetching)
                    return <LogsSectionDataSkeleton />;
                  return (
                    <>
                      <Box className={classes.logsContainer}>
                        {logSectionContentStates.logsList.length ? (
                          logSectionContentStates.logsList.map(
                            (log: StudentLogDeliverableArticleBase) => {
                              const tagColor = getTagColorByStatusCode(
                                log.status_code as CommunizedStatusCode,
                              );
                              let tagName: string = log.status as string;
                              if (
                                log.status_code === ArticleStatus.Correction
                              ) {
                                tagName = "提出待ち";
                              } else if (
                                log.status_code ===
                                ArticleStatus.TeacherChecking
                              ) {
                                tagName = "確認中";
                              }
                              return (
                                <LogsSectionData
                                  key={log.id}
                                  log={log}
                                  studentUserId={deliverableLog.student_user_id}
                                  tagColor={tagColor}
                                  tagName={tagName}
                                />
                              );
                            },
                          )
                        ) : (
                          <Box m={2}>
                            <Typography>
                              {logSectionContentStates.error
                                ? errorWithMessage(
                                    logSectionContentStates.error,
                                  )
                                : t("noData")}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <V2Pagination
                        totalCount={logSectionContentStates.totalCount}
                        perPage={ITEMS_PER_PAGE}
                        currentPage={
                          logSectionContentStates.currentPage as number
                        }
                        handlePageTransition={
                          logSectionContentStates.handlePagination
                        }
                      />
                    </>
                  );
                })()}
              </>
            )}
        </Container>
      </Collapse>
    </Box>
  );
};

export default StudentLogsItem;
