import { Theme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";
import { V2_COLORS } from "@root/styles/v2/v2Colors";

export const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.contrastText,
    color: V2_COLORS.black,
    border: "1px solid rgba(71, 81, 73, 0.4)",
    boxShadow: "0px 2px 2px rgba(102, 102, 102, 0.2)",
    borderRadius: "8px",
    fontSize: 14,
    fontWeight: 400,
    "&.MuiTooltip-tooltip": {
      whiteSpace: "pre-line",
    },
  },
}))(Tooltip);
