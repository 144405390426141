import theme from "@root/styles/theme";

import { StyledTagColor, StyledTextColor } from ".";

export const getTagColor = (
  color?: StyledTagColor,
  variant: string | null = null,
): string => {
  const tagColor =
    variant && variant === "outlined" && color !== StyledTagColor.Black
      ? StyledTagColor.White
      : color;
  switch (tagColor) {
    case StyledTagColor.Pink:
      return theme.palette.homeworkTemplateStatusColor.new;
    case StyledTagColor.Yellow:
      return "#FFEC8A";
    case StyledTagColor.LightPink:
      return theme.palette.quaternary.lightPink;
    case StyledTagColor.Blue:
      return theme.palette.dashboard.blueTag;
    case StyledTagColor.Orange:
      return theme.palette.dashboard.orangeTag;
    case StyledTagColor.LightBlue:
      return theme.palette.dashboard.lightBlueTag;
    case StyledTagColor.Green:
      return theme.palette.tertiary.main;
    case StyledTagColor.BrightGreen:
      return theme.palette.customColor.brightGreen;
    case StyledTagColor.Grey:
      return theme.palette.arrow.grey;
    case StyledTagColor.LightGrey:
      return theme.palette.dashboard.lightGrey;
    case StyledTagColor.LightOpacityGrey:
      return `${theme.palette.customColor.text}1A`;
    case StyledTagColor.Azure:
      return theme.palette.tertiary.azure;
    case StyledTagColor.LightRed:
      return theme.palette.tertiary.lightRed;
    case StyledTagColor.Black:
      return "transparent";
    case StyledTagColor.White:
      return theme.palette.common.white;
    default:
      return theme.palette.arrow.grey;
  }
};

export const getTextColor = (color?: StyledTextColor): string => {
  switch (color) {
    case StyledTextColor.Orange:
      return theme.palette.dashboard.orangeTag;
    case StyledTextColor.Grey:
      return theme.palette.arrow.grey;
    case StyledTextColor.Blue:
      return theme.palette.dashboard.blueTag;
    case StyledTextColor.Green:
      return theme.palette.tertiary.mainDark;
    case StyledTextColor.LightGreen:
      return theme.palette.tertiary.main;
    case StyledTextColor.White:
      return theme.palette.common.white;
    case StyledTextColor.Black:
      return theme.palette.customColor.text;
    case StyledTextColor.LightRed:
      return theme.palette.tertiary.lightRed;
    case StyledTextColor.Azure:
      return theme.palette.tertiary.azure;
    default:
      return theme.palette.common.white;
  }
};
