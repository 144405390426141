/* eslint-disable camelcase */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { HomeworkTemplateImageParams } from "@root/constants/types";
import {
  Api,
  FetchHomeworkTemplatesAsOwnerParams,
  HomeworkTemplate,
  HomeworkTemplateForOwnerAndTeacher,
} from "@root/lib/Api";
import { http } from "@root/lib/http";
import { RootState } from "@store/store";

const api = new Api();

export const fetchHomeworkTemplatesAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplate/fetch",
  async (params: FetchHomeworkTemplatesAsOwnerParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.fetchHomeworkTemplatesAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const fetchHomeworkTemplateAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplate/fetchOne",
  async (templateId: string, { rejectWithValue }) => {
    try {
      const response = await api.owner.fetchHomeworkTemplateAsOwner(templateId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface CreateHomeworkTemplateAsOwnerParams {
  title: string;
  content: string | undefined;
  homework_images: any;
  needsReview: boolean | undefined;
  needsScore: boolean | undefined;
  end_at: string | undefined;
  reviewer_end_at: string | undefined;
  reviewerCompany: string;
}

export const createHomeworkTemplateAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplate/create",
  async (data: CreateHomeworkTemplateAsOwnerParams, { rejectWithValue }) => {
    const {
      title,
      content,
      needsScore,
      needsReview,
      homework_images,
      end_at,
      reviewer_end_at,
      reviewerCompany,
    } = data;

    try {
      const fd = new FormData();
      fd.set("title", title);
      if (typeof content !== "undefined") {
        fd.set("content", content);
      }
      if (typeof needsScore !== "undefined") {
        fd.append("needs_score", JSON.stringify(needsScore));
      }
      if (typeof needsReview !== "undefined") {
        fd.append("needs_review", JSON.stringify(needsReview));
      }
      if (typeof homework_images !== "undefined") {
        homework_images.forEach((fileData: any) => {
          fd.append(`homework_images[]`, fileData.file, fileData.title);
        });
      }
      if (typeof end_at !== "undefined") {
        fd.set("end_at", end_at);
      }
      if (typeof reviewer_end_at !== "undefined") {
        fd.set("reviewer_end_at", reviewer_end_at);
      }
      if (typeof reviewerCompany !== "undefined") {
        fd.set("reviewer_company_group_id", reviewerCompany);
      }
      const response = await http.post(`${"/owner/homework_templates"}`, fd);
      const resData = response.data as HomeworkTemplate;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface UpdateHomeworkTemplateAsOwnerParams {
  title?: string;
  content?: string;
  needs_score?: boolean;
  reviewer_company_group_id?: string;
  end_at?: string;
  reviewer_end_at?: string;
}
export interface UpdateHomeworkTemplateAsOwnerArg {
  homework_template_id: string;
  params: UpdateHomeworkTemplateAsOwnerParams;
}
export const updateHomeworkTemplateAsOwner = createAsyncThunk<
  HomeworkTemplateForOwnerAndTeacher,
  UpdateHomeworkTemplateAsOwnerArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/homeworkTemplate/update", async (data, { rejectWithValue }) => {
  try {
    const response = await api.owner.putHomeworkTemplateAsOwner(
      data.homework_template_id,
      data.params,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deleteHomeworkTemplateAsOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/homeworkTemplate/delete", async (id, { rejectWithValue }) => {
  try {
    const response = await api.owner.deleteHomeworkTemplateAsOwner(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const createHomeworkTemplateImageAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplateImage/create",
  async (data: HomeworkTemplateImageParams, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.set("file", data?.file as File);
      const response = await http.post(
        `/owner/homework_templates/${data.homework_template_id}/homework_template_images`,
        fd,
      );
      const resData = response.data as HomeworkTemplate;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const deleteHomeworkTemplateImageAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/homeworkTemplateImage/delete",
  async (data: HomeworkTemplateImageParams, { rejectWithValue }) => {
    try {
      await api.owner.deleteHomeworkTemplateImageAsOwner(
        data.homework_template_id,
        data.id as string,
      );
      return {
        id: data.homework_template_id,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const resetModalState = createAction(
  "owner/homeworkTemplate/resetModalState",
);

export const resetTemplateRelatedData = createAction(
  "owner/homeworkTemplate/resetOne",
);

export const successUpdateTemplateAsOwner = createAction(
  "owner/homeworkTemplate/successUpdateTemplate",
);
