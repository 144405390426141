import useResponsive from "@lib/hooks/useResponsive";
import { Box,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { InterviewRequestListBase2 as InterviewRequestListAsOwner } from "@root/endpoints/TimeTactApi/ownerApi";
import { InterviewRequestListBase2 as InterviewRequestListAsTeacher } from "@root/endpoints/TimeTactApi/teacherApi";
import React, { useState } from "react";
import { FormProvider,useForm } from "react-hook-form";

import DeleteMessageModal from "./DeleteMessageModal";
import EditMessageInput from "./EditMessageInput";
import Message from "./Message";

type Props = {
  isOwnMessage: boolean;
  isElementsDisabled: boolean;
  message: InterviewRequestListAsOwner | InterviewRequestListAsTeacher;
  requestId: string;
  refetchMessageList: () => void;
};

export type EditMessageForm = {
  messageToUpdate: string;
};

type StyleProps = {
  isOwnMessage: boolean;
  isMobileView: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    main: ({ isOwnMessage }) => ({
      width: "100%",
      display: "flex",
      justifyContent: isOwnMessage ? "flex-end" : "flex-start",
    }),
  }),
);

const MessageItem: React.FC<Props> = ({
  isOwnMessage,
  message,
  requestId,
  isElementsDisabled,
  refetchMessageList,
}) => {
  const methods = useForm<EditMessageForm>({
    defaultValues: { messageToUpdate: "" },
  });
  const { setValue } = methods;
  const { isMobileView } = useResponsive();
  const [isHover, setIsHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles({ isOwnMessage, isMobileView });

  const handleIsEditing = () => {
    setValue("messageToUpdate", message.message);
    setIsEditing((prevState) => !prevState);
  };

  const handleLeave = () => setIsHover(false);
  const handleClose = () => setIsOpen(false);

  return (
    <Box className={classes.main}>
      {isOpen && (
        <DeleteMessageModal
          refetchMessageList={refetchMessageList}
          messageId={message.id}
          requestId={requestId}
          isOpen
          handleClose={handleClose}
        />
      )}
      {isEditing ? (
        <FormProvider {...methods}>
          <EditMessageInput
            requestId={requestId}
            currentMessage={message}
            handleIsEditing={handleIsEditing}
            refetchMessageList={refetchMessageList}
            handleLeave={handleLeave}
          />
        </FormProvider>
      ) : (
        <Message
          isOwnMessage={isOwnMessage}
          isHover={isHover}
          message={message}
          isElementsDisabled={isElementsDisabled}
          handleLeave={handleLeave}
          handleHover={() => setIsHover(true)}
          handleIsEditing={handleIsEditing}
          handleDelete={() => setIsOpen(true)}
        />
      )}
    </Box>
  );
};

export default MessageItem;
