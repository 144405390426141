/* eslint-disable camelcase */
import {
  AccountUserInfo,
  Api,
  GetSearchUserControllersAsAdminParams,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchAdminUsersOwners = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("FETCH_ADMIN_OWNERS", async (companyId: string, { rejectWithValue }) => {
  try {
    const response = await api.admin.getAdminUsersOwners(companyId);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchAdminUsersTeachers = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("FETCH_ADMIN_TEACHERS", async (companyId: string, { rejectWithValue }) => {
  try {
    const response = await api.admin.getTeachersAsAdmin(companyId);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchAdminUsersStudents = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("FETCH_ADMIN_STUDENTS", async (companyId: string, { rejectWithValue }) => {
  try {
    const response = await api.admin.getStudentsAsAdmin(companyId);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchAdminSearchedUsers = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "FETCH_ADMIN_SEARCHED_USERS",
  async (data: GetSearchUserControllersAsAdminParams, { rejectWithValue }) => {
    try {
      const response = await api.admin.getSearchUserControllersAsAdmin(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchAdminUserDetail = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("FETCH_ADMIN_USERS_DETAIL", async (user_id, { rejectWithValue }) => {
  try {
    const response = await api.admin.getAdminUsersUserId(user_id);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const bulkDeleteAffiliatesAsAdmin = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("DELETE_AFFILITATES_AS_ADMIN", async (user_id, { rejectWithValue }) => {
  try {
    const response = await api.admin.bulkDeleteControllerAsAdmin(user_id);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchAdminUsersSuperOwners = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "FETCH_ADMIN_SUPER_OWNERS",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await api.admin.companiesSuperOwnersDetail(companyId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchAdminUsersContentProviders = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "FETCH_ADMIN_CONTENT_PROVIDER",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await api.admin.getContentProvidersAsAdmin(companyId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createAdminUsersContentProvider = createAsyncThunk<
  any,
  { email: string; last_name: string; first_name: string; company_id: string },
  {
    state: RootState;
    rejectValue: { errors: { key: string; message: string } };
    rejectedMeta: void;
  }
>("CREATE_ADMIN_CONTENT_PROVIDER", async (data, { rejectWithValue }) => {
  try {
    const response = await api.admin.usersContentProviderCreate(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createAdminUsersOwners = createAsyncThunk<
  { id: string },
  {
    email: string;
    last_name: string;
    first_name: string;
    company_id: string;
  },
  {
    state: RootState;
    rejectValue: { errors: { key: string; message: string } };
    rejectedMeta: void;
  }
>("CREATE_ADMIN_OWNERS", async (data, { rejectWithValue }) => {
  try {
    const response = await api.admin.postAdminUsers(data as any);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createSuperOwners = createAsyncThunk<
  { id?: string | undefined },
  {
    email: string;
    company_id: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("CREATE_SUPER_OWNERS", async (data, { rejectWithValue }) => {
  try {
    const response = await api.admin.usersSuperOwnerCreate(data as any);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
type AdminDeleteUser = {
  company_id: string;
  role: string;
};

export type AdminDeleteUserParams = {
  userId: string;
  deleteUserParams: AdminDeleteUser;
};

export const deleteUsersAsAdmin = createAsyncThunk<
  any,
  AdminDeleteUserParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("DELETE_USERS", async (args, { rejectWithValue }) => {
  try {
    const response = await api.admin.deleteAdminUsersUserId(
      args.userId,
      args.deleteUserParams,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const resetPasswordAsAdmin = createAsyncThunk<
  AccountUserInfo,
  { id: string; data: { password: string } },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/users/reset_password", async (args, { rejectWithValue }) => {
  try {
    const response = await api.admin.resetPasswordAsAdmin(args.id, args.data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetAdminUserState = createAction("RESET_ADMIN_USER_STATE");
