import { HomeworkDownloadUrl } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  getDownloadUrls,
  resetState,
} from "../../actions/teacherApp/homeworkImage";
import { RootState } from "../../store";

export interface TeacherAppHomeworkImageState {
  fetching: boolean;
  fetched: boolean;
  homeworkList: HomeworkDownloadUrl[];
  withQr: boolean;
}

export const initialState: TeacherAppHomeworkImageState = {
  fetching: false,
  fetched: false,
  homeworkList: [],
  withQr: false,
};

const teacherAppHomeworkImageSlice = createSlice({
  name: "teacherApp/HomeworkImage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDownloadUrls.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          withQr: action.meta.arg.withQr,
          homeworkList: [],
        };
      })
      .addCase(getDownloadUrls.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          homeworkList: [],
        };
      })
      .addCase(getDownloadUrls.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          homeworkList: payload,
        };
      })
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          homeworkList: [],
          withQr: false,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppHomeworkImageState = (
  state: RootState,
): TeacherAppHomeworkImageState => state.teacherApp.homeworkImage;

export default teacherAppHomeworkImageSlice.reducer;
