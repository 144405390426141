import { scrollState } from "@reducers/scroll";
import { useAppSelector } from "@root/hooks";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

const ScrollToTop: React.FC<Props> = ({ children }) => {
  const { scrollDisabled } = useAppSelector(scrollState);
  const { pathname, search } = useLocation();

  useEffect(() => {
    // 特定のケースでスクロール位置をリセッ トしない
    if (scrollDisabled) {
      return;
    }
    window.scrollTo(0, 0);
  }, [pathname, search]);

  return <>{children}</>;
};

export default ScrollToTop;
