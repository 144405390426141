import { Editor } from "@tiptap/react";
import { createContext,Dispatch, SetStateAction } from "react";

export type BubbleMenuContextType = {
  editor: Editor;
  subMenuOpen: Record<string, boolean>;
  setSubMenuOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  isSubMenuOpen: () => boolean;
  isOtherSubMenuOpen: (myMenu: string) => boolean;
};

export const BubbleMenuContext = createContext<BubbleMenuContextType>(
  undefined as never,
);
