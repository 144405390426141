/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import {
  batchCreateUsersAsOwner,
  changeCsvImportState,
  createUserAsOwner,
  deleteUserAsOwner,
  fetchAssignedMaterialWorksAsOwner,
  fetchStudentsAsOwner,
  fetchUserDetailAsOwner,
  fetchUsersAsOwner,
  resetCreateOrEditModalState,
  resetCsvImportState,
  resetDeleteState,
  resetPasswordAsOwner,
  resetState,
  resetUserError,
  updateUserAsOwner,
} from "@actions/ownerApp/user";
import {
  AccountUserInfo,
  MaterialWork,
  StudentInputSetting,
  TeacherListAsOwner,
  UserDetail,
  UserType,
} from "@lib/Api";
import { createSlice, current } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import ApiResponseType from "@root/types/ApiResponse";
import { RootState } from "@store/store";

export interface OwnerAppUserState {
  fetchingAll: boolean;
  fetchedAll: boolean;
  allUsers:
    | ApiResponseType["owner"]["fetchOwnersAsOwner"]["owners"]
    | ApiResponseType["owner"]["fetchStudentsAsOwner"]["students"]
    | ApiResponseType["owner"]["fetchTeachersAsOwner"]["teachers"];
  owners: ApiResponseType["owner"]["fetchOwnersAsOwner"]["owners"];
  ownersTotalCount: number;
  students: ApiResponseType["owner"]["fetchStudentsAsOwner"]["students"];
  studentsTotalCount: number;
  teachers: TeacherListAsOwner[];
  teachersTotalCount: number;
  userDetail: UserDetail | null;
  studentInputSettings: StudentInputSetting | null;
  fetchingOne: boolean; // Use this when fetching just one user
  fetchedOne: boolean;
  fetchingOwners: boolean;
  fetchingStudents: boolean;
  fetchingTeachers: boolean;
  fetchedOwnersOnce: boolean;
  fetchedStudents: boolean;
  fetchedTeachers: boolean;
  fetchedOwners: boolean;
  fetchedTeachersOnce: boolean;
  isLoading: boolean;
  materialWorks: Array<MaterialWork>;
  fetchOneError: any;
  error?: string;
  csvImporting: boolean;
  csvImported: boolean;
  csvImportErrors: string[];
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  batchUpdateCount: number;
  updateError: string | null;
  deleting: boolean;
  deleted: boolean;
  batchDeleteCount: number;
  batchDeleteErrors: string[];
  passwordUpdate: ReduxStatusType;
  passwordUpdateError: string | null;
  resetPasswordUser: AccountUserInfo | null;
}

export type OwnerInfoAsOwner = OwnerAppUserState["owners"][number];
export type StudentInfoAsOwner = OwnerAppUserState["students"][number];
export type UserDetailAsOwner = OwnerAppUserState["userDetail"];
export type AllUserInfoAsOwner = OwnerAppUserState["allUsers"][number];

export const initialState: OwnerAppUserState = {
  fetchingAll: false,
  fetchedAll: false,
  allUsers: [],
  owners: [],
  ownersTotalCount: 0,
  students: [],
  studentsTotalCount: 0,
  teachers: [],
  teachersTotalCount: 0,
  fetchingOne: false,
  fetchedOne: false,
  fetchingOwners: false,
  fetchedOwners: false,
  fetchingStudents: false,
  fetchedStudents: false,
  fetchingTeachers: false,
  fetchedTeachers: false,
  fetchedOwnersOnce: false,
  fetchedTeachersOnce: false,
  userDetail: null,
  studentInputSettings: null,
  isLoading: false,
  materialWorks: [],
  fetchOneError: null,
  error: undefined,
  csvImporting: false,
  csvImported: false,
  csvImportErrors: [],
  creating: false,
  created: false,
  updating: false,
  updated: false,
  batchUpdateCount: 0,
  updateError: null,
  deleting: false,
  deleted: false,
  batchDeleteCount: 0,
  batchDeleteErrors: [],
  passwordUpdate: ReduxStatus.idle,
  passwordUpdateError: null,
  resetPasswordUser: null,
};

const ownerAppUserSlice = createSlice({
  name: "OwnerApp/User",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAsOwner.pending, (state, action) => {
        const { meta } = action;
        const { userType } = meta.arg;
        return {
          ...state,
          fetchingOwners: userType === UserType.Owner,
          fetchingStudents: userType === UserType.Student,
          fetchingTeachers: userType === UserType.Teacher,
          fetchedStudents: false,
          fetchedTeachers: false,
          fetchedOwners: false,
        };
      })
      .addCase(fetchUsersAsOwner.rejected, (state, _action) => {
        return {
          ...state,
          fetchingOwners: false,
          fetchingStudents: false,
          fetchingTeachers: false,
          fetchedStudents: false,
          fetchedTeachers: false,
          fetchedOwners: false,
        };
      })
      .addCase(fetchUsersAsOwner.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const { userType } = meta.arg;
        const { users, total_count, student_input_settings, students } =
          payload;
        const currentState = current(state);
        return {
          ...state,
          fetchingOwners:
            userType === UserType.Owner ? false : currentState.fetchingOwners,
          fetchedOwners:
            userType === UserType.Owner ? true : currentState.fetchedOwners,
          fetchedOwnersOnce:
            userType === UserType.Owner ? true : currentState.fetchedOwnersOnce,
          fetchingStudents:
            userType === UserType.Student
              ? false
              : currentState.fetchingStudents,
          fetchedStudents:
            userType === UserType.Student ? true : currentState.fetchedStudents,
          fetchingTeachers:
            userType === UserType.Teacher
              ? false
              : currentState.fetchingTeachers,
          fetchedTeachers:
            userType === UserType.Teacher ? true : currentState.fetchedTeachers,
          fetchedTeachersOnce:
            userType === UserType.Teacher
              ? true
              : currentState.fetchedTeachersOnce,
          owners: userType === UserType.Owner ? users : currentState.owners,
          students:
            userType === UserType.Student ? students : currentState.students,
          teachers:
            userType === UserType.Teacher ? users : currentState.teachers,
          studentInputSettings:
            userType === UserType.Student ? student_input_settings : null,
          teachersTotalCount:
            userType === UserType.Teacher
              ? total_count
              : currentState.teachersTotalCount,
          studentsTotalCount:
            userType === UserType.Student
              ? total_count
              : currentState.teachersTotalCount,
          ownersTotalCount:
            userType === UserType.Owner
              ? total_count
              : currentState.teachersTotalCount,
        };
      })
      .addCase(fetchUserDetailAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
        };
      })
      .addCase(fetchUserDetailAsOwner.rejected, (state, _action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
        };
      })
      .addCase(fetchUserDetailAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          userDetail: payload,
          studentInputSettings: payload?.student_input_settings || null,
        };
      })
      .addCase(fetchAssignedMaterialWorksAsOwner.pending, (state, _action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchAssignedMaterialWorksAsOwner.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(fetchAssignedMaterialWorksAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          isLoading: false,
          materialWorks: payload,
        };
      })
      .addCase(fetchStudentsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchingStudents: true,
          fetchedStudents: false,
        };
      })
      .addCase(fetchStudentsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchingStudents: false,
          fetchedStudents: false,
          error: action.payload,
        };
      })
      .addCase(fetchStudentsAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingStudents: false,
          fetchedStudents: true,
          students: payload.students,
          studentsTotalCount: payload.total_count,
        };
      })
      .addCase(createUserAsOwner.pending, (state, action) => {
        return {
          ...state,
          error: undefined,
          creating: true,
          created: false,
        };
      })
      .addCase(createUserAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          error: undefined,
          creating: false,
          created: true,
        };
      })
      .addCase(createUserAsOwner.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          creating: false,
          created: false,
        };
      })
      .addCase(updateUserAsOwner.pending, (state, _action) => {
        return {
          ...state,
          updateError: null,
          updating: true,
          updated: false,
        };
      })
      .addCase(updateUserAsOwner.fulfilled, (state, _action) => {
        return {
          ...state,
          updateError: null,
          updating: false,
          updated: true,
          batchUpdateCount: state.batchUpdateCount + 1,
        };
      })
      .addCase(updateUserAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updateError: action.payload as string,
          updating: false,
          updated: false,
        };
      })
      .addCase(deleteUserAsOwner.pending, (state, action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          error: undefined,
        };
      })
      .addCase(deleteUserAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: true,
          batchDeleteCount: state.batchDeleteCount + 1,
        };
      })
      .addCase(deleteUserAsOwner.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          error: action.payload,
          batchDeleteCount: state.batchDeleteCount + 1,
          batchDeleteErrors: [
            ...state.batchDeleteErrors,
            String(action.meta.arg.id),
          ],
        };
      })
      .addCase(resetDeleteState, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          error: undefined,
          batchDeleteCount: 0,
          batchDeleteErrors: [],
        };
      })
      .addCase(resetState, (state, action) => {
        return {
          ...state,
          deleteError: null,
          passwordUpdate: ReduxStatus.idle,
          passwordUpdateError: null,
        };
      })
      .addCase(resetUserError, (state, action) => {
        return {
          ...state,
          error: undefined,
        };
      })
      .addCase(batchCreateUsersAsOwner.pending, (state, action) => {
        const { meta } = action;
        const { slice_start } = meta.arg;
        const starting = slice_start === 0;
        return {
          ...state,
          csvImporting: true,
          csvImported: false,
          csvImportErrors: starting ? [] : state.csvImportErrors,
        };
      })
      .addCase(batchCreateUsersAsOwner.rejected, (state, action) => {
        return {
          ...state,
          csvImporting: false,
          csvImported: false,
          csvImportErrors: [],
        };
      })
      .addCase(batchCreateUsersAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const errors = payload.errors as string[];
        return {
          ...state,
          csvImporting: false,
          csvImported: true,
          csvImportErrors: [...state.csvImportErrors, ...errors],
        };
      })
      .addCase(resetPasswordAsOwner.pending, (state, _action) => {
        return {
          ...state,
          passwordUpdate: ReduxStatus.pending,
        };
      })
      .addCase(resetPasswordAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          resetPasswordUser: payload,
          passwordUpdate: ReduxStatus.fulfilled,
          passwordUpdateError: null,
        };
      })
      .addCase(resetPasswordAsOwner.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          resetPasswordUser: null,
          passwordUpdate: ReduxStatus.rejected,
          passwordUpdateError: payload.error,
        };
      })
      .addCase(resetCreateOrEditModalState, (state, _action) => {
        return {
          ...state,
          created: false,
          creating: false,
          updated: false,
          updating: false,
          error: undefined,
          updateError: null,
          userDetail: null,
        };
      })
      .addCase(changeCsvImportState, (state, _action) => {
        return {
          ...state,
          csvImporting: true,
        };
      })
      .addCase(resetCsvImportState, (state, _action) => {
        return {
          ...state,
          csvImporting: false,
          csvImported: false,
          csvImportErrors: [],
          error: undefined,
          updated: false,
          updating: false,
          batchUpdateCount: 0,
          updateError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppUserState = (state: RootState): OwnerAppUserState =>
  state.ownerApp.user;

export default ownerAppUserSlice.reducer;
