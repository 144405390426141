import { HomeworkDetails, ReviewerHomework } from "@lib/Api";
import { update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";

import {
  assignReviewerToHomework,
  changeAllStatusError,
  changeAllStatusSuccess,
  fetchHomeworkAsReviewer,
  fetchOneHomeworkAsReviewer,
  fileDeleted as fileDeletedAction,
  fileUploaded,
  resetModalState,
  resetOne,
  resetReviewerAppHomeworkFormData,
  updatedHomework,
  updateOneHomeworkAsReviewer,
  updatePersonInChargeError,
  updatePersonInChargeSuccess,
} from "../../actions/reviewerApp/homework";
import { RootState } from "../../store";

export interface ReviewerAppHomeworkState {
  fetching: boolean;
  fetched: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneError: any;
  serverError: any;
  totalCount: number;
  homeworks: ReviewerHomework[];
  homework: HomeworkDetails | null;
  updating: boolean;
  updated: boolean;
  updatingAssignee: boolean;
  updatedAssignee: boolean;
  AllStatusChangedError: boolean;
  AllStatusChanged: boolean;
  uploaded: boolean;
  fileDeleted: boolean;
  updatePersonInCharge: boolean;
  updatePersonInChargeError: boolean;
}

export const initialState: ReviewerAppHomeworkState = {
  fetching: false,
  fetched: false,
  fetchingOne: false,
  fetchedOne: false,
  fetchOneError: null,
  serverError: null,
  homeworks: [],
  totalCount: 0,
  homework: null,
  updating: false,
  updated: false,
  uploaded: false,
  updatingAssignee: false,
  updatedAssignee: false,
  AllStatusChangedError: false,
  AllStatusChanged: false,
  fileDeleted: false,
  updatePersonInCharge: false,
  updatePersonInChargeError: false,
};

export const reviewerAppHomeworkSlice = createSlice({
  name: "ReviewerApp/Homework",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeworkAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          serverError: null,
        };
      })
      .addCase(fetchHomeworkAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          homeworks: payload.homeworks,
          totalCount: payload.total_count,
          serverError: "",
        };
      })
      .addCase(fetchHomeworkAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          homeworks: [],
          serverError: action.error,
        };
      })
      .addCase(fetchOneHomeworkAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          fetchedOne: false,
          fetchingOne: true,
          fetchOneError: null,
        };
      })
      .addCase(fetchOneHomeworkAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          homework: payload,
          fetchOneError: null,
        };
      })
      .addCase(fetchOneHomeworkAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error,
          article: null,
        };
      })
      .addCase(updateOneHomeworkAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          uploaded: false,
          error: null,
        };
      })
      .addCase(updateOneHomeworkAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          error: action.error.message as string,
        };
      })
      .addCase(updateOneHomeworkAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updating: false,
          updated: true,
          error: null,
          homework: payload,
        };
      })
      .addCase(assignReviewerToHomework.pending, (state, _action) => {
        return {
          ...state,
          updatingAssignee: true,
          updatedAssignee: false,
          error: null,
        };
      })
      .addCase(assignReviewerToHomework.rejected, (state, action) => {
        return {
          ...state,
          updatingAssignee: false,
          updatedAssignee: false,
          error: action.error.message as string,
        };
      })
      .addCase(assignReviewerToHomework.fulfilled, (state, action) => {
        return {
          ...state,
          updatingAssignee: false,
          updatedAssignee: true,
          error: null,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          uploaded: false,
          serverError: null,
        };
      })
      .addCase(resetReviewerAppHomeworkFormData, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          updatingAssignee: false,
          updatedAssignee: false,
          uploaded: false,
          fileDeleted: false,
          error: null,
        };
      })
      .addCase(changeAllStatusSuccess, (state, _action) => {
        return {
          ...state,
          AllStatusChanged: true,
        };
      })
      .addCase(changeAllStatusError, (state, _action) => {
        return {
          ...state,
          AllStatusChangedError: true,
        };
      })
      .addCase(updatePersonInChargeSuccess, (state, _action) => {
        return {
          ...state,
          updatePersonInCharge: true,
        };
      })
      .addCase(updatePersonInChargeError, (state, _action) => {
        return {
          ...state,
          updatePersonInChargeError: true,
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: null,
          homework: null,
          updating: false,
          updated: false,
          uploaded: false,
          fileDeleted: false,
          serverError: null,
          AllStatusChangedError: false,
          AllStatusChanged: false,
          updatePersonInCharge: false,
          updatePersonInChargeError: false,
        };
      })
      .addCase(updatedHomework, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updated: true,
          updating: false,
          uploaded: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addCase(fileUploaded, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          uploaded: true,
          updating: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addCase(fileDeletedAction, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          fileDeleted: true,
          updating: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const reviewerAppHomeworkState = (
  state: RootState,
): ReviewerAppHomeworkState => state.reviewerApp.homework;

export default reviewerAppHomeworkSlice.reducer;
