/* eslint-disable camelcase */
import {
  Api,
  FetchSponsorLecturesAsTeacherParams,
  FetchSponsorProjectsAsTeacherParams,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchBookmarkedPblSponsorProjectsAsTeacher = createAsyncThunk<
  any,
  FetchSponsorProjectsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/projects/fetchBookmarked",
  async (params: FetchSponsorProjectsAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchSponsorProjectsAsTeacher({
        ...params,
        bookmarked: true,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchLecturesByBookmarkedProjectAsTeacher = createAsyncThunk<
  any,
  FetchSponsorLecturesAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/projects/fetchLectures/ByBookmarkedProject",
  async (params: FetchSponsorLecturesAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchSponsorLecturesAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const reset = createAction(
  "teacher/pbl/sponsor/projects/bookmarked/reset",
);
