import {
  InterviewRequestDetailBase,
  InterviewRequestListBase,
  InterviewRequestUnreadCountBase,
} from "@root/endpoints/TimeTactApi/studentApi";
import React from "react";

import Item from "./Item";

type Props = {
  interviewRequestsList: InterviewRequestListBase[];
  currentInterviewRequest: InterviewRequestListBase;
  unreadMessages: InterviewRequestUnreadCountBase[];
  handleSelectInterview: (request: InterviewRequestDetailBase) => void;
  handleMobileChat: () => void;
};

const ListItems: React.FC<Props> = ({
  interviewRequestsList,
  currentInterviewRequest,
  unreadMessages,
  handleSelectInterview,
  handleMobileChat,
}) => {
  return (
    <>
      {interviewRequestsList.map((request) => {
        return (
          <Item
            key={request.id}
            request={request}
            unreadMessages={unreadMessages}
            currentInterviewRequest={currentInterviewRequest}
            handleSelectInterview={handleSelectInterview}
            handleMobileChat={handleMobileChat}
          />
        );
      })}
    </>
  );
};

export default ListItems;
