// feel free to add more common rules

export enum MaxChars {
  title = 250,
  text = 10000,
  linkTitle = 50,
  shortName = 30,
}
export enum HomeWorkScore {
  max = 100,
  min = 0,
}

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const EMAIL_DOMAIN_REGEX = /^.*@([^.]+\.)+[^.]+$/;

export const emailValidationRules = {
  pattern: {
    value: EMAIL_REGEX,
    message: "メールアドレスの形式が正しくありません。",
  },
  maxLength: {
    value: 255,
    message: "メールアドレスは255文字以下で入力してください。",
  },
};

// add !v because "validate" will ignore "require: false"
export const emailWithoutIdValidationRules = {
  validate: {
    validateId: (v: string): true | string =>
      !v || EMAIL_REGEX.test(v) || "メールアドレスの形式が正しくありません。",
    validateDomain: (v: string): true | string =>
      !v ||
      EMAIL_DOMAIN_REGEX.test(v) ||
      "メールアドレスの形式が正しくありません。",
  },
  maxLength: {
    value: 255,
    message: "メールアドレスは255文字以下で入力してください。",
  },
};

export const accountIdValidationRules = {
  validate: {
    validateId: (v: string): true | string =>
      !v || /^[^@]*$/.test(v) || "アカウントIDの形式が正しくありません。",
  },
  maxLength: {
    value: 255,
    message: "アカウントIDは255文字以下で入力してください。",
  },
};

export const MAX_INTEGER_SIZE = 2147483647;

export const formatPostalCode = (
  input: string | number | null | undefined,
): string => {
  // Convert input to string and remove non-numeric characters
  const numericValue = String(input).replace(/[^\d]/g, "");

  // Format the numeric value as "000-0000"
  if (numericValue.length <= 3) {
    return numericValue;
  }
  return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 7)}`;
};

export const POSTCODE_REGEX = /^\d{3}-\d{4}$/;

export const postCodeValidationRules = {
  pattern: {
    value: POSTCODE_REGEX,
    message: "有効な郵便番号の形式000-0000で入力してください。",
  },
};

export const removeDashPostcode = (input: string): string =>
  input.replace(/-/g, "");

export const phoneNumberValidationRules = {
  maxLength: {
    value: 25,
    message: "電話番号は25文字以下で入力してください。",
  },
};

export const firstNameValidationRules = {
  maxLength: {
    value: MaxChars.shortName,
    message: `名は${MaxChars.shortName}文字を超えることはできません。`,
  },
  validate: {
    equals: (value: string | string[]) =>
      !value.includes("　") || "全角スペースは無効です。",
  },
};

export const lastNameValidationRules = {
  maxLength: {
    value: MaxChars.shortName,
    message: `姓は${MaxChars.shortName}文字を超えることはできません。`,
  },
  validate: {
    equals: (value: string | string[]) =>
      !value.includes("　") || "全角スペースは無効です。",
  },
};
