import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import React from "react";
import { Control,Controller } from "react-hook-form";

interface Props {
  control: Control<InquiryFormValues>;
  onSubmit: () => void;
}

export type InquiryFormValues = {
  title: string;
  body: string;
};

const Form: React.FC<Props> = ({ control, onSubmit }) => {
  return (
    <Container>
      <form onSubmit={onSubmit}>
        <FormSectionWrap>
          <FormCategoryTitle>タイトル</FormCategoryTitle>
          <Controller
            name="title"
            defaultValue=""
            control={control}
            render={({
              field: { value, onChange, ref },
              fieldState: { error },
            }) => (
              <TextField
                autoFocus
                placeholder="タイトルを入力してください。"
                variant="outlined"
                value={value}
                onChange={onChange}
                inputRef={ref}
                fullWidth
                error={!!error}
                helperText={error ? error.message : null}
                data-cy="supportInquiry-input-title"
              />
            )}
            rules={{ required: "タイトルは必須です。" }}
          />
        </FormSectionWrap>
        <FormSectionWrap>
          <FormCategoryTitle>内容</FormCategoryTitle>
          <Controller
            name="body"
            defaultValue=""
            control={control}
            render={({
              field: { value, onChange, ref },
              fieldState: { error },
            }) => (
              <TextField
                placeholder="お問合せの内容を記入してください。"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={value}
                onChange={onChange}
                inputRef={ref}
                error={!!error}
                helperText={error ? error.message : null}
                data-cy="supportInquiry-input-content"
              />
            )}
            rules={{ required: "内容は必須です。" }}
          />
        </FormSectionWrap>
      </form>
    </Container>
  );
};

export default Form;
