/* eslint-disable no-else-return */
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import { ReduxStatus } from "@constants/redux";
import { Container,Typography } from "@mui/material";
import { notificationState } from "@reducers/notification";
import { usePatchNewNotificationCountAsCommonMutation } from "@root/endpoints/TimeTactApi/remainingApi";
import { useAppSelector } from "@root/hooks";
import React from "react";

import NotificationCard from "./Card/Card";

type Props = {
  postNotifications: ReturnType<
    typeof usePatchNewNotificationCountAsCommonMutation
  >[0];
  handleClose: () => void;
  changeNewNotificationState: () => void;
};
const LimitedTimeNotifications: React.FC<Props> = ({
  postNotifications,
  handleClose,
  changeNewNotificationState,
}) => {
  const { fetchLimitedTimeNotifications, limitedTimeNotifications } =
    useAppSelector(notificationState);

  let mainContent: React.ReactNode;

  if (limitedTimeNotifications.length > 0) {
    mainContent = limitedTimeNotifications.map((notification) => (
      <NotificationCard
        key={notification.id}
        limitedNotification={notification}
        handleClose={handleClose}
        changeNewNotificationState={changeNewNotificationState}
        postNotifications={postNotifications}
        isLimitedNotificationsModal
      />
    ));
  } else {
    mainContent = <Typography>データがありません。</Typography>;
  }

  if (fetchLimitedTimeNotifications === ReduxStatus.pending) {
    return <CircularLoading />;
  } else if (fetchLimitedTimeNotifications === ReduxStatus.rejected) {
    return <Typography>エラーが発生しました。</Typography>;
  } else {
    return <Container>{mainContent}</Container>;
  }
};

export default LimitedTimeNotifications;
