/* eslint-disable @typescript-eslint/ban-types */
import { CompanyTagType, isUserTagType,UserTagType } from "@actions/types/tag";
import StyledUserTag from "@components/UI/atoms/StyledTag/StyledUserTag";
import { Company } from "@lib/Api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionRoot: {
      width: "100%",
    },
    accordionDetails: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "auto",
    },
  }),
);

export interface TagAccordionProps {
  tagType: UserTagType | CompanyTagType;
  handleSchoolTagAssign: (value: Company | CompanyTagType) => {
    id: string;
    name: string;
  }[];
  value: { id: string; name: string }[];
  onChange: (...event: any[]) => void;
}

const TagItem: React.FC<TagAccordionProps> = ({
  tagType,
  handleSchoolTagAssign,
  onChange,
  value,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>("");
  const handleAccordion = (state: string) => {
    if (state === expanded) {
      setExpanded(false);
    } else {
      setExpanded(state);
    }
  };
  const compareTo = value.map((item) => item.id);
  return (
    <Accordion
      className={classes.accordionRoot}
      expanded={expanded === `${tagType.name}-content`}
      onChange={() => handleAccordion(`${tagType.name}-content`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${tagType.name}-content`}
        id={`${tagType.name}-header`}
      >
        {tagType.name}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {!isUserTagType(tagType) &&
          tagType.company_tags &&
          tagType.company_tags.map((tag) => (
            <FormControlLabel
              key={tag.id}
              label={<StyledUserTag label={tag.name} />}
              control={
                <Checkbox
                  color="primary"
                  checked={compareTo?.includes(tag.id)}
                  value={tag.id}
                  onChange={() => onChange(handleSchoolTagAssign(tag))}
                />
              }
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default TagItem;
