/* eslint-disable camelcase */
import {
  Api,
  FetchPblReportTemplateStatsAsTeacherParams,
  GetTeacherPblReportTemplatesParams,
  GetTeacherTemplateFrameworksParams,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import pLimit from "p-limit";

import { fetchPblReportItemsAsTeacher } from "./item";

const api = new Api();

export const fetchPblReportTemplateStatsAsTeacher = createAsyncThunk<
  any,
  FetchPblReportTemplateStatsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/templates/stats/fetch",
  async (
    params: FetchPblReportTemplateStatsAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.fetchPblReportTemplateStatsAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblReportTemplatesAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/templates/fetch",
  async (params: GetTeacherPblReportTemplatesParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherPblReportTemplates(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblReportTemplatesAsTeacherWithQuestionNumber =
  createAsyncThunk<
    any,
    any,
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    "teacher/pbl/report/templates/fetchWithQuestionNumber",
    async (
      params: GetTeacherPblReportTemplatesParams,
      { rejectWithValue, dispatch },
    ) => {
      try {
        const response = await api.teacher.getTeacherPblReportTemplates(params);

        const { templates, total_count } = response.data;

        const results = await Promise.all(
          templates.map((template) => {
            return pLimit(1)(() =>
              dispatch(
                fetchPblReportItemsAsTeacher({
                  template_id: template.id,
                }),
              ),
            );
          }),
        );
        const withQuestionNum = results
          .filter(
            (result) =>
              !!templates.find(
                (template) => template.id === result?.meta?.arg?.template_id,
              ),
          )
          .map((result) => {
            const item = templates.find(
              (template) => template.id === result?.meta?.arg?.template_id,
            );
            return {
              ...item,
              question_number: result.payload.length,
            };
          });

        return { templates: withQuestionNum, total_count };
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    },
  );

export const fetchPblReportTemplateAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/templates/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchPblReportTemplateAsTeacher(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreatePblReportTemplateAsTeacherParams {
  title: string;
  due_date_time?: string;
}

export const createPblReportTemplateAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/templates/create",
  async (
    params: CreatePblReportTemplateAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.teacher.postTeacherPblReportTemplates(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdatePblReportTemplateAsTeacherParams {
  title?: string;
  due_date_time?: string;
  event?: "distribute" | "fulfill";
}

export interface UpdatePblReportTemplateAsTeacherArg {
  id: string;
  params: UpdatePblReportTemplateAsTeacherParams;
}

export const updatePblReportTemplateAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/templates/update",
  async (arg: UpdatePblReportTemplateAsTeacherArg, { rejectWithValue }) => {
    try {
      const response = await api.teacher.putTeacherPblReportTemplatesId(
        arg.id,
        arg.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTeacherTemplateFrameworks = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/framwork/fetch",
  async (params: GetTeacherTemplateFrameworksParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherTemplateFrameworks(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetTemplateRelatedData = createAction(
  "teacher/pbl/report/templates/resetTemplateRelatedData",
);

export const resetModalState = createAction(
  "teacher/pbl/report/templates/resetModalState",
);

export const resetOne = createAction("teacher/pbl/report/templates/resetOne");

export const resetPblReportAnswers = createAction(
  "teacher/pbl/report/templates/resetPblReportAnswers",
);
