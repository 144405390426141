/* eslint-disable @typescript-eslint/ban-types */
import { StyledFormHelperText } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList/Modals/LearningTemplateDistributionModal/StepOne";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import StyledUserTag from "@components/UI/atoms/StyledTag/StyledUserTag";
import { UserTag } from "@lib/Api";
import { safeString } from "@lib/string-utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Control, Controller } from "react-hook-form";

import { FormType } from "../../usePackageDistributionModal";

interface Props {
  tags: UserTag[];
  control: Control<FormType, object>;
  handleIds: (value: UserTag) => void;
  errors: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionRoot: {
      width: "100%",
    },
    accordionDetails: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "auto",
    },
  }),
);

const CommonTagAccordion: React.FC<Props> = ({
  tags,
  control,
  handleIds,
  errors,
}) => {
  const classes = useStyles();

  const tagGroupingKeys = new Map<string, UserTag[]>();

  const tagList = tags;
  tagList.forEach((tag) => {
    const key = safeString(tag.user_tag_type?.name);
    const list = tagGroupingKeys.get(key) ?? [];
    list.push(tag);
    tagGroupingKeys.set(key, list);
  });

  return (
    <FormSectionWrap>
      <FormCategoryTitle>タグの設定</FormCategoryTitle>
      {Array.from(tagGroupingKeys).length > 0 ? (
        <>
          {errors.tagIds && (
            <StyledFormHelperText>
              ** タグの設定は必須です **
            </StyledFormHelperText>
          )}
          <Controller
            control={control}
            name="tagIds"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              const compareTo = value.map((item: any) => item.id);
              return (
                <>
                  {Array.from(tagGroupingKeys.keys()).map((key) => {
                    const tagsGroupings = tagGroupingKeys.get(key);
                    return (
                      <Accordion key={key} className={classes.accordionRoot}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${key}-content`}
                          id={`${key}-header`}
                        >
                          {key}
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          {tagsGroupings?.map((tag) => (
                            <FormControlLabel
                              key={tag.id}
                              label={<StyledUserTag label={tag.name} />}
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={compareTo?.includes(tag.id)}
                                  value={tag.id}
                                  onChange={() => onChange(handleIds(tag))}
                                />
                              }
                            />
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </>
              );
            }}
            rules={{ required: "タグの設定は必須です。" }}
          />
        </>
      ) : (
        <Box m={2}>
          <Typography>タグが見つかりませんでした。</Typography>
        </Box>
      )}
    </FormSectionWrap>
  );
};

export default CommonTagAccordion;
