import { BaseCategoryInfo, GradeCategoryInfo } from "@lib/Api";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { MajorCategoryState } from "@reducers/category/major";
import { MiddleCategoryState } from "@reducers/category/middle";
import { MinorCategoryState } from "@reducers/category/minor";
import React from "react";

import { StyledAccordion, StyledAccordionSummary } from "..";
import MajorLayer from "./MajorLayer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subjectText: {
      fontSize: 16,
      marginLeft: theme.spacing(3),
      cursor: "pointer",
    },
    card: {
      borderRadius: 0,
    },
    summary: {
      backgroundColor: theme.palette.common.white,
    },
    activeSummary: {
      backgroundColor: theme.palette.grey[100],
    },
  }),
);

interface Props {
  categoryItem: GradeCategoryInfo;
  major: MajorCategoryState;
  middle: MiddleCategoryState;
  minor: MinorCategoryState;
  selectedCategoryId: string[];
  handleSelectedIds: (item: BaseCategoryInfo) => void;
}

const SubjectLayer: React.FC<Props> = (props: Props) => {
  const {
    categoryItem,
    major,
    middle,
    minor,
    selectedCategoryId,
    handleSelectedIds,
  } = props;

  const classes = useStyles();

  return (
    <>
      <StyledAccordion expanded square className={classes.card}>
        <StyledAccordionSummary
          className={
            selectedCategoryId.includes(categoryItem.id)
              ? classes.activeSummary
              : classes.summary
          }
          onClick={() => handleSelectedIds(categoryItem)}
        >
          <Typography className={classes.subjectText}>
            {categoryItem.name}
          </Typography>
        </StyledAccordionSummary>
        {major.items
          .filter((m) => categoryItem.id === m.subject_id)
          .map((majorItem) => (
            <MajorLayer
              key={majorItem.id}
              categoryItem={majorItem}
              middle={middle}
              minor={minor}
              selectedCategoryId={selectedCategoryId}
              handleSelectedIds={handleSelectedIds}
            />
          ))}
      </StyledAccordion>
    </>
  );
};

export default SubjectLayer;
