import {
  getSteamLectureDetailsAsStudent,
  postSteamLectureAsStudent,
  resetSteamLectureDetails,
} from "@actions/studentApp/steam/steamLecture";
import { PblSteamLecture } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

interface StudentAppSteamLectureState {
  fetching: boolean;
  fetched: boolean;
  updating: boolean;
  updated: boolean;
  lecture: PblSteamLecture | null;
  fetchError: string | null;
  posting: boolean;
  posted: boolean;
  postError: string | null;
  updateError: string | null;
}

const initialState: StudentAppSteamLectureState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  lecture: null,
  fetchError: null,
  posting: false,
  posted: false,
  postError: null,
  updateError: null,
};

const studentAppSteamLectureSlice = createSlice({
  name: "StudentApp/SteamLecture",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSteamLectureDetailsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: null,
        };
      })
      .addCase(getSteamLectureDetailsAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          lecture: action.payload,
        };
      })
      .addCase(getSteamLectureDetailsAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(postSteamLectureAsStudent.pending, (state, _action) => {
        return {
          ...state,
          posting: true,
          posted: false,
          postError: null,
        };
      })
      .addCase(postSteamLectureAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          lecture: action.payload,
        };
      })
      .addCase(postSteamLectureAsStudent.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          postError: action.payload.errors,
        };
      })
      .addCase(resetSteamLectureDetails, (state, _action) => {
        return {
          ...state,
          lecture: null,
        };
      });
  },
});

export const studentAppSteamLectureState = (
  state: RootState,
): StudentAppSteamLectureState => state.studentApp.steamLecture;

export default studentAppSteamLectureSlice.reducer;
