/* eslint-disable @typescript-eslint/no-unused-vars */
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "ja",
    lng: "ja",
    supportedLngs: ["ja", "en"],
    interpolation: { escapeValue: false },
    debug: false, // turn true will display the state detail on the console
    detection: {
      // order: where and in which order the browser have to check to find the current language
      order: ["cookie", "htmlTag", "sessionStorage", "path", "subdomain"],
      caches: ["cookie"], // define where to store the language value
    },
    backend: {
      loadPath: "/translation/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
