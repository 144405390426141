/* eslint-disable camelcase */
import { Api, PblReportAnswerImageRequest } from "@lib/Api";
import { http, makeFormData } from "@lib/http";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { STUDENT_PBL_REPORT_ANSWER_IMAGES } from "@root/constants/api";
import { RootState } from "@store/store";

const api = new Api();

export const createPblReportAnswerImagesAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/report/answers/answer_images/create",
  async (input: PblReportAnswerImageRequest, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${STUDENT_PBL_REPORT_ANSWER_IMAGES}`,
        makeFormData(input),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface deletePblReportAnswerImagesAsStudentArg {
  id: string;
  params: Omit<PblReportAnswerImageRequest, "file">;
}

export const deletePblReportAnswerImagesAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/report/answers/answer_images/delete",
  async (arg: deletePblReportAnswerImagesAsStudentArg, { rejectWithValue }) => {
    try {
      const response = await api.student.deletePblReportAnswerImagesAsStudent(
        arg.id,
        arg.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
