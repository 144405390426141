/* eslint-disable camelcase */
import { getSteamLibraryContentImages } from "@actions/steamLibrary";
import {
  SteamLibraryContentCard,
  SteamLibraryContentImage,
} from "@actions/types/steamLibrary";
import {
  Api,
  GetSteamContentsAsTeacherParams,
  PblSteamContentBase,
  SearchTeacherSteamContentsParams,
  StudentPblSteamContentDetail,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const searchSteamContentsAsTeacher = createAsyncThunk<
  any,
  SearchTeacherSteamContentsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibraryContent/search",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await api.teacher.searchTeacherSteamContents(params);
      const contentIds = response.data.steam_contents.map(
        (content) => content.sl_content_id,
      );
      await dispatch(getSteamLibraryContentImages(contentIds));
      const contentsWithImages = response.data.steam_contents.map(
        (content) => ({
          ...content,
          top_image: getState().steamLibrary.contentImages.find(
            (image: SteamLibraryContentImage) =>
              image.id === content.sl_content_id,
          ),
        }),
      );
      return {
        ...response.data,
        steam_contents: contentsWithImages,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSteamContentDetailAsTeacher = createAsyncThunk<
  StudentPblSteamContentDetail,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibraryContent/fetch",
  async (contentId, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getSteamContentAsTeacher(contentId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSteamContentsDetailsAsTeacher = createAsyncThunk<
  any,
  number[],
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibraryContents/fetch",
  async (contentIds, { rejectWithValue, dispatch, getState }) => {
    try {
      if (!contentIds.length) {
        return [];
      }
      let contents: StudentPblSteamContentDetail[] = [];
      await Promise.all(
        contentIds.map((contentId) => {
          return dispatch(getSteamContentDetailAsTeacher(String(contentId)))
            .unwrap()
            .then((content: StudentPblSteamContentDetail) => {
              contents = [...contents, content];
            });
        }),
      );
      // fetch images by content ids
      await dispatch(getSteamLibraryContentImages(contentIds));
      // get images from state
      const images = getState().steamLibrary.contentImages;
      // combine contents with images
      const contentsWithImages: SteamLibraryContentCard[] = contents.map(
        (content: StudentPblSteamContentDetail) => ({
          ...content,
          top_image: images.find(
            (image: SteamLibraryContentImage) =>
              image.id === content.sl_content_id,
          ),
        }),
      );
      return contentsWithImages;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAllSteamLibraryContentsAsTeacher = createAsyncThunk<
  any,
  GetSteamContentsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/steamLibrary/content/fetchAll", async (_, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getSteamContentsAsTeacher({});
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getSteamLibraryContentsAsTeacher = createAsyncThunk<
  any,
  GetSteamContentsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibraryContents/fetchContents",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await api.teacher.getSteamContentsAsTeacher(params);
      const { steam_contents } = response.data;
      let contentsWithImages: SteamLibraryContentCard[] = [];
      if (steam_contents?.length) {
        // get content ids
        const contentIds = steam_contents?.map(
          (content: PblSteamContentBase) => content.sl_content_id,
        );
        // fetch images by content ids
        await dispatch(getSteamLibraryContentImages(contentIds));
        // get images from state
        const images = getState().steamLibrary.contentImages;
        // combine contents with images
        contentsWithImages = steam_contents.map(
          (content: PblSteamContentBase) =>
            ({
              ...content,
              top_image: images.find(
                (image: SteamLibraryContentImage) =>
                  image.id === content.sl_content_id,
              ),
            }) as SteamLibraryContentCard,
        );
      }
      return {
        ...response.data,
        contentsWithImages,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetSteamContentAsTeacherState = createAction(
  "teacher/steamLibraryContent/resetSteamContentState",
);

export const resetSearchSteamContentsState = createAction(
  "teacher/steamLibraryContent/resetSearchState",
);
