import axios from "axios";
import Cookies from "js-cookie";

import { config } from "../constants/env";
import { AUTH, AUTH_PROXY } from "../constants/localStorageKeys";
import { logout } from "../store/actions/auth";
import { store } from "../store/store";

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

export const baseUrl = config.url.API_URL;

const instance = axios.create({
  baseURL: baseUrl,
});

export const setRTKQueryHeaders = (headers: Headers): Headers => {
  const accessToken =
    sessionStorage.getItem(AUTH_PROXY.ACCESS_TOKEN) ||
    Cookies.get(AUTH.ACCESS_TOKEN);
  const client =
    sessionStorage.getItem(AUTH_PROXY.CLIENT) || Cookies.get(AUTH.CLIENT);
  const uid = sessionStorage.getItem(AUTH_PROXY.UID) || Cookies.get(AUTH.UID);
  if (accessToken) {
    headers.set("access-token", accessToken);
    headers.set("Authorization", accessToken);
  }
  if (client) {
    headers.set("client", client);
  }
  if (uid) {
    headers.set("uid", uid);
  }
  return headers;
};

type GetHeaders = {
  "content-type": "application/json";
  Accept: "application/json";
  "access-token"?: string;
  Authorization?: string;
  uid?: string | null;
  client?: string | null;
};

export const getHeaders = (base = {}): GetHeaders => {
  // If session storage access token exists, user is logged in as proxy
  const accessToken =
    sessionStorage.getItem(AUTH_PROXY.ACCESS_TOKEN) ||
    Cookies.get(AUTH.ACCESS_TOKEN);
  const client =
    sessionStorage.getItem(AUTH_PROXY.CLIENT) || Cookies.get(AUTH.CLIENT);
  const uid = sessionStorage.getItem(AUTH_PROXY.UID) || Cookies.get(AUTH.UID);
  return {
    ...base,
    "content-type": "application/json",
    Accept: "application/json",
    "access-token": accessToken,
    Authorization: accessToken,
    uid,
    client,
  };
};

type GetRtkQueryHeaders = {
  accessToken?: string;
  uid?: string;
  client?: string;
};

export const getRtkQueryHeaders = (): GetRtkQueryHeaders => {
  // If session storage access token exists, user is logged in as proxy
  const accessToken =
    sessionStorage.getItem(AUTH_PROXY.ACCESS_TOKEN) ||
    Cookies.get(AUTH.ACCESS_TOKEN);
  const client =
    sessionStorage.getItem(AUTH_PROXY.CLIENT) || Cookies.get(AUTH.CLIENT);
  const uid = sessionStorage.getItem(AUTH_PROXY.UID) || Cookies.get(AUTH.UID);
  return {
    accessToken,
    uid,
    client,
  };
};

// multipart/form-dataでリクエストを送りたい時のFromDataを用意する
export const makeFormData = (input: Record<string, any>): FormData => {
  return Object.keys(input).reduce((data, key) => {
    if (Array.isArray(input[key])) {
      input[key].forEach((v: any) => {
        data.append(`${key}[]`, v);
      });
    } else {
      data.append(key, input[key]);
    }
    return data;
  }, new FormData());
};

instance.interceptors.request.use(
  (reqConfig) => {
    const newConfig = {
      ...reqConfig,
      headers: getHeaders(reqConfig.headers),
    };
    return newConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.status || error.response.status;
    if ([UNAUTHORIZED, FORBIDDEN].includes(status)) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  },
);

export const http = instance;
