/* eslint-disable camelcase */
import { HasIdAndName, ImageInfo,VideoInfo } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  distributeMaterialWorkToTags,
  fetchDistributeMaterialWorkInformation,
  fetchDistributeMaterialWorkInformationByCategory,
  resetMaterialWorkTargetState,
  resetState,
  updateMaterialDistributionAsSuperOwner,
} from "../../actions/superOwnerApp/materialWork";
import { RootState } from "../../store";

export type MaterialWorksTargetCompany = {
  id: string;
  name: string;
  postcode: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
  email: string;
  logo: { url?: string; thumb?: { url?: string }; icon?: { url?: string } };
  target_student_count: number;
  distributed_student_count: number;
};

export type MaterialWorksTargetMaterial = {
  id: string;
  title: string;
  category_id: string;
  tags: HasIdAndName[];
  videos: VideoInfo[];
  images: ImageInfo[];
  description: string;
  target_company_count: number;
  target_student_count: number;
  distributed_student_count: number;
};

export interface SuperOwnerAppMaterialWorkState {
  creating: boolean;
  created: boolean;
  changeErrors: string[];
  fetching: boolean;
  fetched: boolean;
  fetchError: string | null;
  targetCompanies: MaterialWorksTargetCompany[];
  targetMaterials: MaterialWorksTargetMaterial[];
  totalCount: number;
  updating: boolean;
  updated: boolean;
  updateError: any;
}

export const initialState: SuperOwnerAppMaterialWorkState = {
  creating: false,
  created: false,
  changeErrors: [],
  fetching: false,
  fetched: false,
  fetchError: null,
  targetCompanies: [],
  targetMaterials: [],
  totalCount: 0,
  updating: false,
  updated: false,
  updateError: null,
};

const superOwnerAppMaterialWorkSlice = createSlice({
  name: "SuperOwnerApp/MaterialWork",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(distributeMaterialWorkToTags.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          changeErrors: [],
        };
      })
      .addCase(distributeMaterialWorkToTags.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeErrors: action.payload.message,
        };
      })
      .addCase(distributeMaterialWorkToTags.fulfilled, (state, action) => {
        return {
          ...state,
          creating: false,
          created: true,
          changeErrors: [],
        };
      })
      .addCase(
        updateMaterialDistributionAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            updating: true,
            updated: false,
            updateError: null,
          };
        },
      )
      .addCase(
        updateMaterialDistributionAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updateError: action.payload?.errors,
          };
        },
      )
      .addCase(
        updateMaterialDistributionAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: true,
          };
        },
      )
      .addCase(
        fetchDistributeMaterialWorkInformation.pending,
        (state, action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            fetchError: null,
          };
        },
      )
      .addCase(
        fetchDistributeMaterialWorkInformation.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            targetCompanies: action.payload.companies,
            totalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        fetchDistributeMaterialWorkInformation.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetchError: "データが取得できませんでした。",
          };
        },
      )
      .addCase(
        fetchDistributeMaterialWorkInformationByCategory.pending,
        (state, action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            fetchError: null,
          };
        },
      )
      .addCase(
        fetchDistributeMaterialWorkInformationByCategory.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            targetMaterials: action.payload.materials,
            totalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        fetchDistributeMaterialWorkInformationByCategory.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetchError: "データが取得できませんでした。",
          };
        },
      )
      .addCase(resetMaterialWorkTargetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          targetCompanies: [],
          targetMaterials: [],
          totalCount: 0,
        };
      })
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          updateError: null,
          creating: false,
          created: false,
          changeErrors: [],
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const superOwnerAppMaterialWorkState = (
  state: RootState,
): SuperOwnerAppMaterialWorkState => state.superOwnerApp.materialWork;

export default superOwnerAppMaterialWorkSlice.reducer;
