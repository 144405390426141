/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblSponsorLectureAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/lectures/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchSponsorLectureAsTeacher(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetOne = createAction("teacher/pbl/sponsor/lectures/resetOne");
