import { SetThumbnailData } from "@actions/types/material";
import PlusIcon from "@assets/images/svg/plus_icon.svg?react";
import {
  FormCategoryTitle,
  FormIconAnnotation,
} from "@components/UI/atoms/Forms/FormComponents";
import V2OutlinedButton from "@components/UIv2/atoms/buttons/V2OutlinedButton";
import { MaterialDetail } from "@lib/Api";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { fileSize } from "@root/constants/fileSize";
import React, { useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  sectionWrap: {
    margin: "1rem 0 1.5rem",
  },
  categoryTitle: {
    margin: "1rem 0 0.5rem 0",
  },
  chipWrap: {
    margin: "14px 0",
  },
  chip: {
    marginRight: "7px",
  },
}));

interface Props {
  material: MaterialDetail;
  thumbnailFile: SetThumbnailData | null;
  setThumbnailFile: React.Dispatch<
    React.SetStateAction<SetThumbnailData | null>
  >;
  isOverCapacity: boolean;
  setDeleteThumbnail: React.Dispatch<React.SetStateAction<boolean>>;
}

const MaterialThumbnailFields: React.FC<Props> = ({
  material,
  thumbnailFile,
  setThumbnailFile,
  isOverCapacity,
  setDeleteThumbnail,
}) => {
  const classes = useStyles();
  const imageInput = useRef<HTMLInputElement>(null);
  const [oldThumbnailFile, setOldThumbnailFile] = useState<null | string>(
    material?.thumbnail_file ?? null,
  );
  const [fileSizeError, setFileSizeError] = useState(false);
  const onChangeFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    const files = target.files || null;
    if (files) {
      const file = files[0];
      if (file.size > fileSize.image.size) {
        setFileSizeError(true);
      } else {
        setOldThumbnailFile(null);
        setThumbnailFile({
          file,
          title: file.name,
          type: file.type,
        });
        setFileSizeError(false);
      }
    }
  };

  return (
    <div className={classes.sectionWrap}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormCategoryTitle>サムネイル画像ファイル(png/jpg)</FormCategoryTitle>
        <FormIconAnnotation>
          <InfoIcon fontSize="small" htmlColor="#475149" />
          <div
            style={{
              paddingLeft: "8px",
            }}
          >
            1ファイル50MB以内
          </div>
        </FormIconAnnotation>
      </Box>
      {oldThumbnailFile && (
        <>
          <Box className={classes.chipWrap}>
            <Chip
              className={classes.chip}
              variant="outlined"
              label="サムネイル画像"
              onDelete={() => {
                setOldThumbnailFile(null);
                setDeleteThumbnail(true);
              }}
              color="primary"
            />
          </Box>
          <div>
            <img
              src={oldThumbnailFile}
              alt="カテゴリ一覧の画像"
              width="200px"
              height="auto"
            />
          </div>
        </>
      )}
      {thumbnailFile && (
        <>
          <Box className={classes.chipWrap}>
            <Chip
              className={classes.chip}
              variant="outlined"
              label={thumbnailFile.title}
              onDelete={() => setThumbnailFile(null)}
              color="primary"
            />
          </Box>
          <div>
            <img
              src={URL.createObjectURL(thumbnailFile.file)}
              alt={thumbnailFile.title}
              width="200px"
              height="auto"
            />
          </div>
        </>
      )}
      <V2OutlinedButton
        disabled={isOverCapacity}
        onClick={() => imageInput.current?.click()}
        startIcon={<PlusIcon />}
      >
        画像ファイルを追加
      </V2OutlinedButton>
      <input
        accept="image/png, image/jpeg"
        onChange={onChangeFileInput}
        type="file"
        ref={imageInput}
        style={{ display: "none" }}
      />
      {fileSizeError && (
        <Box>
          <Typography variant="caption" color="error">
            {fileSize.image.message}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default MaterialThumbnailFields;
