import {
  CoursesList,
  DocumentList,
  getStudentClasses,
  getStudentCouresDocuments,
  getStudentCouresList,
  StudentClassRoom,
} from "@actions/studentApp/classRoom";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface StudentAppClassRoomState {
  fetching: boolean;
  fetched: boolean;
  fetchingClasses: boolean;
  fetchedClasses: boolean;
  fetchingDocument: boolean;
  fetchedDocument: boolean;
  courseList: CoursesList[] | null;
  class: StudentClassRoom | null;
  documentsList: DocumentList | null;
  error: any;
}

export const initialState: StudentAppClassRoomState = {
  fetching: false,
  fetched: false,
  fetchingClasses: true,
  fetchedClasses: false,
  fetchingDocument: false,
  fetchedDocument: false,
  courseList: null,
  documentsList: null,
  class: null,
  error: null,
};

export const studentAppClassRoomSlice = createSlice({
  name: "StudentApp/ClassRoom",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStudentClasses.pending, (state, action) => {
        return {
          ...state,
          fetchingClasses: true,
          error: null,
        };
      })
      .addCase(getStudentClasses.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingClasses: false,
          fetchedClasses: true,
          class: action.payload,
        };
      })
      .addCase(getStudentClasses.rejected, (state, action) => {
        return {
          ...state,
          fetchingClasses: false,
          class: null,
          error: action.payload,
        };
      })
      .addCase(getStudentCouresList.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      })
      .addCase(getStudentCouresList.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          courseList: action.payload,
        };
      })
      .addCase(getStudentCouresList.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          courseList: null,
          error: action.payload,
        };
      })

      .addCase(getStudentCouresDocuments.pending, (state, action) => {
        return {
          ...state,
          fetchingDocument: true,
          error: null,
        };
      })
      .addCase(getStudentCouresDocuments.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingDocument: false,
          fetchedDocument: true,
          documentsList: action.payload,
        };
      })
      .addCase(getStudentCouresDocuments.rejected, (state, action) => {
        return {
          ...state,
          fetchingDocument: false,
          documentsList: null,
          error: action.payload,
        };
      });
  },
});

export const studentAppClassRoomState = (
  state: RootState,
): StudentAppClassRoomState => state.studentApp.classRoom;

export default studentAppClassRoomSlice.reducer;
