import GenericModal, {
  ModalProps,
} from "@components/UI/organisms/Modal/GenericModal";
import { ITEMS_PER_PAGE } from "@constants/page";
import { Box, Container, Divider,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  DailyLogStatsBase,
  useGetStudentLogsStatsAsTeacherQuery,
} from "@root/endpoints/TimeTactApi/teacherApi";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { StudyLogsModalTypes } from "..";
import useHandleStartAndEndDate from "../Logs/hooks/useHandleModalStartAndEndDate";
import LogsBySections from "./LogBySections";
import SearchByDateInputs from "./SearchByDateInputs";
import Stats from "./Stats";
import WeekLogs from "./WeekLogs";

interface LogsModalProps {
  modalState: StudyLogsModalTypes;
  handleClose: () => void;
  modalProps?: Partial<ModalProps>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainWrap: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    datesFilterWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      gap: theme.spacing(2),
    },
    dividerStyle: {
      color: theme.palette.quaternary.grey,
      background: theme.palette.quaternary.grey,
    },
    iconStyle: {
      fill: theme.palette.tertiary.mainDark,
      width: "25px",
      height: "25px",
      marginRight: theme.spacing(1),
      alignSelf: "center",
    },
  }),
);

const LogsModal: React.FC<LogsModalProps> = ({
  modalState,
  handleClose,
  modalProps,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "calendar", "study_logs"]);
  const [currentPage, updateCurrentPage] = useState<number>(1);
  const { open, step, studentInfos } = modalState;
  const isModalOpen = open && step === 3;
  const { control, getValues, submit, handleClear, endDate, startDate } =
    useHandleStartAndEndDate();
  const { data, isFetching: isFetchingStudyLog } =
    useGetStudentLogsStatsAsTeacherQuery(
      {
        studentAffiliateId: studentInfos?.student_affiliate_id as string,
        page: currentPage,
        perPage: ITEMS_PER_PAGE,
        ...(startDate && endDate ? { startDate, endDate } : {}),
      },
      { refetchOnMountOrArgChange: true, skip: !isModalOpen },
    );

  const pieData = data?.daily_log || {};
  const studyLogs = data?.study_logs || [];
  const totalCount = data?.total_count || 0;

  const handlePageTransition = (updatePage: number) =>
    updateCurrentPage(updatePage);

  return (
    <GenericModal
      fullScreen
      title={`${studentInfos?.full_name}${t("study_logs:userLearningLog")}`}
      handleClose={handleClose}
      open={isModalOpen}
      {...modalProps}
    >
      <Container maxWidth="lg">
        <Box className={classes.mainWrap}>
          <SearchByDateInputs
            submit={submit}
            handleClear={handleClear}
            getValues={getValues}
            control={control}
            {...{ endDate, startDate, isFetchingStudyLog }}
          />
          <Divider className={classes.dividerStyle} />
          <Stats
            isFetchingStudyLog={isFetchingStudyLog}
            dataPie={pieData as DailyLogStatsBase}
          />

          <WeekLogs
            handlePageTransition={handlePageTransition}
            perPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            studyLogs={studyLogs}
            totalCount={totalCount}
            isFetchingStudyLog={isFetchingStudyLog}
          />
          {/* isModalOpen === true? PreventFrom fetching on modal close */}
          {isModalOpen && (
            <LogsBySections
              isFetchingStudyLog={isFetchingStudyLog}
              studentInfos={studentInfos}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </Box>
      </Container>
    </GenericModal>
  );
};

LogsModal.defaultProps = {
  modalProps: undefined,
};

export default LogsModal;
