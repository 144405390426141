import { GroupAffiliatePackageGroupOnlyList } from "@lib/Api";
import { createContext } from "react";

export type StudentLogsContextType = {
  groups: GroupAffiliatePackageGroupOnlyList[];
  isGroupsFetching: boolean;
  selectedGroupId: string | undefined;
  handleGroupChange: (selectedGroup?: string) => void;
  studentPerPage: number;
  studentPage: number;
  handleStudentPageTransition: (newPage: number) => void;
};

export const StudentLogsContext = createContext<StudentLogsContextType>(
  undefined as never,
);
