/* eslint-disable camelcase */
import { Api, ListMaterialDistributionHistoryAsTeacherParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

export const fetchAllDistributedMaterialWorksAsTeacher = createAsyncThunk<
  GetResponseType<Api["teacher"]["listMaterialDistributionHistoryAsTeacher"]>,
  ListMaterialDistributionHistoryAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/fetchAllDistributedMaterialWork/",
  async (
    params,

    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.listMaterialDistributionHistoryAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "teacher/materialWorkDistribution/resetState",
);
