/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosInstance, default as defaultAxios, Method } from "axios";
import { config } from "../constants/env";
/**
 * idのみを含むオブジェクト。Collectionとしてモデルなどを扱うために利用する。
 */
export interface ModelBase {
  id: string;
}

export interface CustomMasterRequestBody {
  column1?: string;
  column2?: string;
  column3?: string;
  column4?: string;
  column5?: string;
  column6?: string;
  column7?: string;
  column8?: string;
  column9?: string;
  column10?: string;
  column11?: string;
  column12?: string;
  column13?: string;
  column14?: string;
  column15?: string;
  column16?: string;
  column17?: string;
  column18?: string;
  column19?: string;
  column20?: string;
}

export type CustomFieldRequestBody = CustomFieldCommonRequestBody & {
  common_custom_master_map_id?: string;
  public_column_number?: number[];
  filter_column_numbers?: number[];
  filter_cmm_relationship_ids?: string[];
};

export interface CustomFieldCommonRequestBody {
  type: HomeworkTemplateCustomFieldType;
  title: string;
  sequence: number;
  min_length?: number;
  max_length?: number;
  is_required: boolean;
  possible_values?: string[];

  /**
   * - true: 完全一致の選択問題
   * - false: 部分一致の選択問題
   */
  is_exact_match?: boolean;

  /**
   * - 単一選択の問題: possible_valuesの配置にスコアが設定される。
   * - 完全一致の選択問題: 必ずサイズは1つのみを設定すること。
   * - 部分一致の選択問題: possible_valuesの配置にスコアが設定される。選択を選んだときのスコアとなる。
   */
  possible_scores?: number[];

  /**
   * - 単一選択の問題: 設定できない
   * - 完全一致の選択問題: 設定できない
   * - 部分一致の選択問題: possible_valuesの配置にスコアが設定される。選択を選んでいないときのスコアとなる。
   */
  possible_scores2?: number[];

  /**
   * - 完全一致の選択問題（is_exact_match: true）のみ設定可能
   * - possible_valuesと同じ配列のサイズにすること
   * - 値は 0 または 1 のみ
   * - 0: 不正解
   * - 1: 正解
   */
  answer_flags?: number[];
  custom_field_category_id?: string;
}

export interface CustomFieldFeedbackRequestBody {
  /** コメント内容 */
  body: string;
  homework_id: string;
  files?: File[];

  /** WYSIWYGのコード */
  wysiwyg_body?: string;
}

export type CustomValueRequestBody = CustomProfileBody & {
  value?: string;
  group_affiliate_package_id?: string;
  homework_id: string;
  custom_master_id?: string;
  wysiwyg_body?: string;
};

export interface CustomProfileBody {
  user_last_name?: string | null;
  user_first_name?: string | null;
  email?: string | null;
  number?: number | null;
  birth_date?: string | null;
  grade?: string | null;
  phone?: string | null;
  parent_last_name?: string | null;
  parent_first_name?: string | null;
  postcode?: string | null;
  prefecture?: string | null;
  address1?: string | null;
  address2?: string | null;
}

export interface CustomValueRequestBodyAsOwnerAndTeacherAndReviewer {
  value?: string;
  group_affiliate_package_id?: string;
  homework_id: string;
  custom_master_id?: string;
  wysiwyg_body?: string;
}

export interface CreateSteamLectureRequestBody {
  title: string;
  sl_lecture_id: number;
  sequence: number;
}

export interface CreateAccountUserRequestBody {
  email: string;
  last_name: string;
  first_name: string;

  /** アカウントID使用Userのみ設定可能 */
  optional_email?: string | null;
  role_name: string;
  password: string;
  authorizations?: UserAuthorization;
  user_params?: {
    grade?: string;
    parent_last_name?: string;
    parent_first_name?: string;
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    birth_date?: string;
    number?: number;
  };

  /**
   * - 生徒のみに設定する
   * - ユーザタグ、限定タグ
   */
  user_tag_ids?: string[] | null;

  /**
   * - 生徒のみに設定する
   * - グループ
   */
  group_ids?: string[] | null;
}

export interface CreateAccountUserResponse {
  user: {
    id: string;
    account_id: string;
    full_name: string;
    password: string;
    affiliate: { company_id?: string; company_name?: string; role?: string };
  };
}

export interface UpdateMaterialRequestBody {
  title: string;
  category_id?: string;
  memo?: string;
  description?: string;
  download_allowed: boolean;
  is_public: boolean;
  is_video_updating?: boolean;

  /** removeを設定するとnilに更新 */
  thumbnail_file?: File;

  /** videoを更新する場合はidを指定すること */
  video_id?: string;
  video_content_title?: string;
  video_content_thumbnail?: File;
  video_content_file?: File;
  video_content_external_link?: string;
  image_content_titles?: string[];
  image_contents?: File[];
  tag_list?: string[];
  removed_image_ids?: string[];
  removed_video_ids?: string[];
}

export interface CreateMaterialRequestBody {
  title: string;
  thumbnail_file?: File;
  memo?: string;
  category_id?: string;
  tag_list?: string[];
  image_content_titles?: string[];
  image_contents?: File[];
  video_content_title?: string;
  video_content_file?: File;
  video_content_thumbnail?: File;
  video_content_external_link?: string;
  description?: string;
  is_public: boolean;
  is_video_updating?: boolean;
  download_allowed: boolean;
}

export interface MaterialCategoryIdRequiredRequestBody {
  category_id: string;
}

export interface MaterialSequencesRequiredRequestBody {
  sequences?: { material_id?: string; sequence?: number }[];
}

export interface CreateTtCategoryRequestBody {
  name: string;
  type: TtCategoryBaseType;
}

export interface UpdateTtCategoryRequestBody {
  name: string;
}

export interface BulkUpdateRequestBody {
  ids: string[];

  /**
   * - false: 利用再開
   * - true: 利用停止
   */
  student_suspension: boolean;
}

export type NumberOfEachStatus = { status: HomeworkStatus; count: number }[] | null;

export type HomeworkTemplateChunkDistributionStatuses =
  | { status: HomeworkTemplateChunkDistributionStatus; count: number }[]
  | null;

export type HomeworkChunkStatuses = { status: HomeworkChunkStatus; count: number }[] | null;

/**
 * Role情報
 */
export type RoleBase = ModelBase & { name: Role; jp_name: RoleJa };

/**
 * AffiliateからUser,Company、Roleの情報を返す
 */
export interface AffiliateBase {
  affiliate_id: string;
  user?: BasicUserInfo;
  company?: CompanyBase;
  role?: RoleBase;
}

export interface AffiliateItem {
  company_id?: string;
  company_name?: string;
  roles?: {
    id?: string;
    role?: string;
    affiliate_id?: string;
    only_pbl_projects_feature?: string;
    student_suspension?: string;
    status?: string;
  }[];
}

/**
 * Userが紐付いているAffiliateの情報を返す
 */
export interface AffiliateListBase {
  affiliates: AffiliateItem[];
}

/**
 * AffiliateからUserのfirst_name, last_nameの情報を返す
 */
export interface AffiliateName {
  affiliate_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  nickname: string | null;
}

/**
 * AffiliateからUserのfirst_name, last_name, gradeの情報を返す
 */
export interface AffiliateStudentName {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  nickname?: string | null;
  grades: { grade?: string | null }[];
  company: HasIdAndName;
}

/**
 * Affiliateから招待予約日時とアカウントステータスと最終アクセス時刻を返す
 */
export type AffiliateAccount = ModelBase & {
  invitation_scheduled_at: string | null;
  status: AffiliateStatusJa;
  last_accessed_at: string | null;
};

export type AffiliateAvatar = AffiliateIdName & { avatar?: Avatar };

/**
 * Affiliate ID, Userのfirst_name, last_nameの情報を返す
 */
export interface AffiliateIdName {
  id: string;
  first_name: string;
  last_name: string;
}

/**
 * id, user_id, company_id, 名前の情報を返す
 */
export interface AffiliateCompanyAndUserBase {
  affiliate_id: string;
  user_id: string;
  company_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: RoleBase;
  nickname: string | null;
}

/**
 * ユーザ情報、企業名、ロール名
 */
export interface AffiliateInfoBase {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  role_name: string;
  nickname: string | null;
}

/**
 * ユーザ情報、企業名、ロール名
 */
export interface AffiliateMessageBase {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  role_name: string;
  nickname: string | null;
  avatar: Avatar;
}

export interface CategoryRenameMap {
  id?: string | null;
  for_text: string | null;
  for_subject: string | null;
  for_grade: string | null;
  for_major: string | null;
  for_middle: string | null;
  for_minor: string | null;
}

/**
 * 現状すぐに標準エラーに移れないためシンプルなエラー形式(Rails標準ではStringのArray)を許可する。
 */
export type SimpleErrorBase = string | string[];

/**
 * 現状アプリ内で使われてるエラー形式(の多く)使われているkeyやデータが不揃いのためErrorListへ移行していく。
 */
export interface SimpleError {
  error?: SimpleErrorBase | null;
  message?: SimpleErrorBase | null;
}

/**
 * クライアントでのエラー表示標準化のため基本的なエラー形式を揃えてエラー情報は返す。
 */
export interface Error {
  /** エラーの発生した箇所を示す。 */
  key?: string;
  message?: string;
}

export type ErrorList = Error[];

export interface ErrorsWithKeyMessage {
  errors: { key?: string; message?: string }[];
}

export interface StudentProfile {
  id: string;
  email: string;
  last_name: string;
  first_name: string;
  nickname: string | null;
  avatar: Avatar;
  student_info: {
    birth_date?: string;
    grade?: string;
    grade_value?: string;
    parent_last_name?: string;
    parent_first_name?: string;
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    number?: number;
  };
}

/**
 * 企業の各機能に対する利用権限情報を含む
 */
export interface CompanyAuthorizationInfo {
  /** 動画を利用できるかどうか。Roleにvideoが含まれているかどうかで判断。 */
  use_video: boolean;

  /** 生徒用UIを用意するかどうか。初期値はfalse */
  custom_student_ui: boolean;

  /** 宿題機能を用意するかどうか。初期値はfalse */
  homework_feature: boolean;

  /** ブログ機能を用意するかどうか。初期値はfalse */
  blog_feature?: boolean;

  /** テキスト販売可能なロールか。初期値はfalse */
  text_seller?: boolean;
  use_image?: boolean;

  /** 教材配布の機能を用意するかどうか。初期値はfalse */
  material?: boolean;

  /** 指導報告書の機能を用意するかどうか。初期値はfalse */
  report?: boolean;

  /** 支払い情報管理の機能を用意するかどうか。初期値はfalse */
  payment?: boolean;

  /** Google Classroom機能を用意するかどうか。初期値はfalse */
  google_classroom?: boolean;

  /** STEAMライブラリー機能を用意するかどうか。初期値はfalse */
  steam_library?: boolean;

  /** ダッシュボード機能を用意するかどうか。初期値はfalse */
  dashboard_feature?: boolean;

  /** 学習ログ機能を用意するかどうか。初期値はfalse */
  study_log_feature?: boolean;

  /** アイデアル機能を用意するかどうか。初期値はfalse */
  ideal_feature?: boolean;

  /** 探究ボード機能を用意するかどうか。初期値はfalse */
  pbl_board_feature?: boolean;

  /** 模試機能を用意するかどうか。初期値はfalse */
  exam_feature?: boolean;

  /** ワードクラウド機能を用意するかどうか。初期値はfalse */
  word_cloud_feature?: boolean;

  /** Google Drive機能を用意するかどうか。初期値はfalse */
  google_drive_feature?: boolean;

  /** One Drive機能を用意するかどうか。初期値はfalse */
  one_drive_feature?: boolean;

  /** 高大連携機能機能を用意するかどうか。初期値はfalse */
  university_cooperation_feature?: boolean;

  /** 探究成果の生徒同士によるコメント機能を用意するかどうか。初期値はfalse */
  article_student_comment_feature?: boolean;

  /** 提出物自動採点機能を用意するかどうか。初期値はfalse */
  homework_automatic_scoring_feature?: boolean;

  /** 授業ナビページを用意するかどうか。初期値はfalse */
  class_nav_feature?: boolean;

  /** 探究GoGo機能を用意するかどうか。初期値はfalse */
  gogo_feature_feature?: boolean;
}

export enum CompanyTypeFilter {
  Any = "any",
  Admin = "admin",
  Reviewer = "reviewer",
  Content = "content",
  School = "school",
  PblBoardFeature = "pbl_board_feature",
}

export interface UserAuthorization {
  /** 教材配信権限 */
  material_distribution?: boolean;

  /** ユーザータグの付与権限 */
  nationwide_user_tag?: boolean;

  /** 限定ユーザータグの付与権限 */
  limited_user_tag?: boolean;

  /** 提出物レビュー権限 */
  homework_review?: boolean;

  /** ユーザー作成・編集・削除権限 */
  user_edit?: boolean;

  /** お知らせ配信の権限 */
  notification_delivery?: boolean;

  /** 問い合わせメールの送信 */
  send_inquiry_email?: boolean;
}

export interface Avatar {
  url: string | null;
}

/**
 * 会社オプション
 */
export interface Option {
  /** 動画を利用できるかどうか。Roleにvideoが含まれているかどうかで判断。 */
  use_video?: boolean;

  /** 生徒用UIを用意するかどうか。初期値はfalse */
  custom_student_ui?: boolean;

  /** 宿題機能を用意するかどうか。初期値はfalse */
  homework_feature?: boolean;

  /** ブログ機能を用意するかどうか。初期値はfalse */
  blog_feature?: boolean;

  /** テキスト販売可能なロールか。初期値はfalse */
  text_seller?: boolean;
  use_image?: boolean;

  /** 教材配布の機能を用意するかどうか。初期値はfalse */
  material?: boolean;

  /** 先生用の教材一覧オプションの機能を用意するかどうか。デフォルトはtrue */
  teacher_material?: boolean;

  /** 指導報告書の機能を用意するかどうか。初期値はfalse */
  report?: boolean;

  /** 支払い情報管理の機能を用意するかどうか。初期値はfalse */
  payment?: boolean;

  /** カレンダー機能を用意するかどうか。初期値はfalse */
  calender?: boolean;

  /** レビュー機能を用意するかどうか。初期値はfalse */
  review_feature?: boolean;

  /** レビュワー会社であることを識別するフラグ */
  reviewer_company?: boolean;

  /** タグ機能を用意するかどうか。初期値はfalse */
  tag?: boolean;

  /** Google Classroom機能を用意するかどうか。初期値はfalse */
  google_classroom?: boolean;

  /** STEAMライブラリー機能を用意するかどうか。初期値はfalse */
  steam_library?: boolean;

  /** コンテンツ事業者であることを識別するかどうか。初期値はfalse */
  content_company?: boolean;

  /** コンテンツ事業者からのレビュー機能を用意するかどうか。初期値はfalse */
  content_company_review_feature?: boolean;

  /** ダッシュボード機能を用意するかどうか。初期値はfalse */
  dashboard_feature?: boolean;

  /** 学習ログ機能を用意するかどうか。初期値はfalse */
  study_log_feature?: boolean;

  /** アイデアル機能を用意するかどうか。初期値はfalse */
  ideal_feature?: boolean;

  /** 探究ボード機能を用意するかどうか。初期値はfalse */
  pbl_board_feature?: boolean;

  /** 模試機能を用意するかどうか。初期値はfalse */
  exam_feature?: boolean;

  /** ルーブリック機能を用意するかどうか。初期値はfalse */
  rubric_feature?: boolean;

  /** ワードクラウド機能を用意するかどうか。初期値はfalse */
  word_cloud_feature?: boolean;

  /** ルーブリック生徒評価の機能を用意するかどうか。初期値はfalse */
  rubric_evaluate_feature?: boolean;

  /** Google Driveの機能を用意するかどうか。初期値はfalse */
  google_drive_feature?: boolean;

  /** One Driveの機能を用意するかどうか。初期値はfalse */
  one_drive_feature?: boolean;

  /** 高大連携機能の機能を用意するかどうか。初期値はfalse */
  university_cooperation_feature?: boolean;

  /** 探究成果の生徒同士によるコメント機能を用意するかどうか。初期値はfalse */
  article_student_comment_feature?: boolean;

  /** 提出物自動採点機能が使用できるオプション。初期値はfalse */
  homework_automatic_scoring_feature?: boolean;

  /** 授業ナビページが使用できるオプション。初期値はfalse */
  class_nav_feature?: boolean;

  /** 教職員アカウントロックのメール通知を使用できるオプション。初期値はfalse */
  use_account_lock_mail?: boolean;

  /** 探究GoGo機能の機能を用意するかどうか。初期値はfalse */
  gogo_feature?: boolean;

  /** 企業プロジェクト機能が使用できるオプション。初期値はfalse */
  pbl_projects_feature?: boolean;

  /** 探究成果・提出物に関する通知メールを使用できるオプション。初期値はtrue */
  use_article_and_homework_mail?: boolean;
}

export type ReviewerCompany = ModelBase & { name: string };

export enum UserInputSetting {
  Required = "required",
  Optional = "optional",
  Hidden = "hidden",
}

export enum StudentGrade {
  Under = "under",
  Elementary1 = "elementary1",
  Elementary2 = "elementary2",
  Elementary3 = "elementary3",
  Elementary4 = "elementary4",
  Elementary5 = "elementary5",
  Elementary6 = "elementary6",
  Junior1 = "junior1",
  Junior2 = "junior2",
  Junior3 = "junior3",
  High1 = "high1",
  High2 = "high2",
  High3 = "high3",
  University1 = "university1",
  University2 = "university2",
  University3 = "university3",
  University4 = "university4",
  Others = "others",
}

export enum StudentGradeJa {
  under = "学年未設定",
  elementary1 = "小学1年",
  elementary2 = "小学2年",
  elementary3 = "小学3年",
  elementary4 = "小学4年",
  elementary5 = "小学5年",
  elementary6 = "小学6年",
  junior1 = "中学1年",
  junior2 = "中学2年",
  junior3 = "中学3年",
  high1 = "高校1年",
  high2 = "高校2年",
  high3 = "高校3年",
  university1 = "大学1年",
  university2 = "大学2年",
  university3 = "大学3年",
  university4 = "大学4年",
  others = "その他",
  no_grade = "学年なし",
}

export interface StudentInputSetting {
  avatar: UserInputSetting;
  postcode: UserInputSetting;
  prefecture: UserInputSetting;
  address1: UserInputSetting;
  address2: UserInputSetting;
  birth_date: UserInputSetting;
  grade: UserInputSetting;
  phone: UserInputSetting;
  parent_first_name: UserInputSetting;
  parent_last_name: UserInputSetting;
  number: UserInputSetting;
}

/**
 * 会社の基本情報
 */
export type CompanyBase = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  logo?: { url?: string | null; thumb?: { url?: string | null }; icon?: { url?: string | null } };
};

/**
 * 会社の詳細情報 Admin限定
 */
export type AdminCompany = CompanyBase & {
  created_at: string;
  updated_at: string;
  ui_color_code: string;
  ui_second_color_code: string;
  number_of_students: number;
  number_of_teachers: number;
  number_of_owners: number;
  number_of_super_owners: number;
  options: Option;
  options_editable: Option;
  options_expansions?: OptionExpansionBase;
  is_admin_company?: boolean;
  is_reviewer?: boolean;
  is_content_company?: boolean;
  is_reviewee_company?: boolean;
  is_super_company?: boolean;
  is_child_company?: boolean;
  child_companies?: { id?: string; name?: string }[];
  parent_company?: { id?: string; name?: string };
  reviewer_companies?: { id?: string; name?: string }[];
  reviewee_companies?: { id?: string; name?: string }[];
  has_super_owners?: boolean;
  parent_company_id?: string;
  is_pbl_board_published: boolean | null;
  deletion_progress?: DeletionProgress | null;
  has_university: boolean;
  sponsors?: (ModelBase & {
    company_id: string;
    name: string;
    description: string;
    is_public: boolean;
    sales_channel: SponsorSalesChannel | null;
    project_count: number;
  })[];
};

/**
 * 会社の基本情報 Admin限定
 */
export type AdminCompanyList = ModelBase & {
  id: string;
  name: string;
  is_admin_company?: boolean;
  is_super_company?: boolean;
  is_child_company?: boolean;
  is_reviewer_company?: boolean;
  is_content_company?: boolean;
  is_reviewee_company?: boolean;
  parent_company?: HasIdAndName;
  deletion_progress?: DeletionProgress | null;
  has_university: boolean;
};

/**
 * 会社情報
 */
export type Company = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  custom_master?: { title?: string; value?: string }[];
  logo?: { url?: string | null; thumb?: { url?: string | null }; icon?: { url?: string | null } };
  report_recipient_email?: string;
  report_the_number_of_users_email?: string;
  report_homework_template_distribution_histories_email?: string;
  inquiry_email?: string;
  limited_user_tag_monthly_report_email?: string;
  created_at?: string;
  owner_number?: number;
  teacher_number?: number;
  student_number?: number;
  has_super_owners?: boolean;
  options?: Option;
  company_tags?: {
    id?: string;
    name?: string;
    grade?: string | null;
    company_tag_type_id?: string;
    company_tag_type_name?: string;
  }[];
  linking_reviewer_companies?: boolean;
  student_input_settings?: StudentInputSetting;
  unique_id?: string | null;
  deletion_progress?: DeletionProgress | null;
  reviewer_company_group?: { id?: string; name?: string };
  student_count_by_grade?: { grade?: string; count?: number }[];
};

/**
 * 会社の詳細情報 SuperOwner限定
 */
export type CompanyAsSuperOwner = Company & {
  granted_user_tags?: UserTagBasic[];
  reviewer_companies_by_reviewer_company_group?: {
    name?: string;
    reviewer_companies?: { id?: string; name?: string }[];
  }[];
};

/**
 * 会社の詳細情報 Owner限定
 */
export type CompanyAsOwner = Company & {
  student_count_by_grade: { grade?: string; count?: number }[];
  student_input_settings: StudentInputSetting;
  custom_master?: { title?: string; value?: string }[];
  deletion_progress?: DeletionProgress | null;
  options: Option;
};

/**
 * 会社の詳細情報 Student限定
 */
export type CompanyAsStudent = ModelBase & {
  name: string;
  postcode: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
  student_input_settings?: StudentInputSetting;
  options_expansions?: OptionExpansionBase;
};

/**
 * Admin用 大学連携の会社の情報
 */
export type CompanyUniversityCooperationBase = ModelBase & {
  name: string;
  postcode: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
  affiliates_count: number;
};

/**
 * Admin用 紐付け会社の情報
 */
export type CompanyLinkingListBase = ModelBase & {
  name: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
  owner_count: number;
  teacher_count: number;
  student_count: number;
};

/**
 * 添削会社に関連付け可能な会社情報
 */
export type ReviewerAssignableCompany = ModelBase & {
  name: string;
  linking_reviewer_companies?: boolean;
  company_tags?: { id?: string; name?: string; company_tag_type_id?: string; company_tag_type_name?: string }[];
};

/**
 * super_owner/user_tag_distribution_histories
 */
export type UserTagDistributionHistory = {
  user_tag_distribution_histories: (ModelBase & {
    status: string;
    tag_type: string;
    tag_name: string;
    start_at: string | null;
    limit_number: number | null;
    company: HasIdAndName;
  })[];
  total_count: number;
};

/**
 * - app/views/user_tag_type/_user_tag_type.json.jbuilder
 */
export type UserTagType = UserTagTypeBasic & { is_editable?: boolean; user_tags: UserTagBasic[] };

/**
 * (app/views/user_tag_type/_user_tag_type_basic.json.jbuilder)に対応
 */
export type UserTagTypeBasic = ModelBase & {
  name: string;
  kind?: string;
  teacher_visible: boolean;
  role_type?: string;
};

export type UserTag = ModelBase & {
  name: string;
  number_of_linking_users?: number;
  limit_number?: number | null;
  is_editable?: boolean | null;
  user_tag_type?: UserTagTypeBasic;
  user_tag_company?: { start_at?: string | null };
};

export interface UserTags {
  user_tag_companies_count: number;
  user_tagging_count: number;
}

/**
* - app/views/user_tag/_detail.json.jbuilderに対応
- owner/teacherの場合、assigned_companiesは自社のみ
*/
export type UserTagDetail = ModelBase & {
  name: string;
  limit_number?: number | null;
  is_editable: boolean;
  assigned_companies: AssignedCompany[];
  user_tag_type: UserTagTypeBasic;
};

export type AssignedCompany = ModelBase & { name: string; start_at?: string | null; number_of_linking_users: number };

export type UserTagWithCompaniesNum = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_assigned_companies: number;
};

export type CreatedUserTagType = UserTagTypeBasic & { user_tags: UserTagWithCompaniesNum[] };

/**
 * super_owner, owner, teacherがユーザータグを作成時に使用する要素
 */
export interface UserTaggingBatchCreateParams {
  user_ids: string[];
  user_tag_id: string;
  company_id?: string;
}

export interface PostUserTaggingRequestBody {
  user_id: string;
  user_tag_id: string;
  company_id: string;
}

/**
 * super_owner, owner, teacherがユーザータグ登録を一括で行ったときに帰ってくるレスポンス
 */
export interface UserTaggingBatchCreateResponses {
  success_user_taggings: { user_id?: string; user_name?: string; user_tag_id?: string; user_tag_name?: string }[];
  errors: (string | null)[];
}

/**
 * owner, teacherがユーザータグを作成時に使用する要素
 */
export interface UserTagsBatchCreateParams {
  user_ids: string[];
  user_tag_ids: string[];
}

export interface AssignUserTagRequestBody {
  company_id: string;
  start_at?: string | null;
}

export interface PutUserTagRequestBody {
  name?: string;
  limit_number?: number;
}

export type PostUserTagRequestBody = PutUserTagRequestBody & { user_tag_type_id: string };

export interface UserTagSequencesRequestBody {
  sequences: { id: string; sequence_number?: number }[];
}

/**
 * マイナビ - ユーザーの招待URL作成に利用。InvitationをもとにAffiliate作成とユーザー登録を行う。
 */
export type AffiliateInvitation = ModelBase & {
  expires_at: string | null;
  is_expired: boolean;
  role: string;
  token: string;
  grade: string;
  company: string;
  student_input_settings: StudentInputSetting;
  user_tags: UserTag[];
  signup_count: number;
  description: string;
};

/**
* - app/views/article/_article_base.json.jbuilderに対応
- 探究成果記事のベースデータの共通部分、一覧表示でも利用する
*/
export type ArticleBaseCommon = ModelBase & {
  student_id: string;
  creator_student_affiliate_id?: string | null;
  student_number: number | null;
  student_name: string | null;
  student_nickname?: string | null;
  title: string;
  kind?: ArticleKind;
  status: ArticleStatusJa;
  status_code: ArticleStatus;
  created_at: string;
  created_at_with_second: string;
  updated_at: string;
  updated_at_with_second: string;
  liked?: boolean;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  company: { id?: string; name?: string };
  students_linking: ArticleAffiliateLinking[];
  common_tags: CommonTagBase[];
  template: { id: string; description?: string | null };
};

/**
* - app/views/article/_article_index_base.json.jbuilderに対応
- 探究成果記事のベースデータ、一覧表示で利用する
*/
export type ArticleBase = ArticleBaseCommon & {
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
  interview_requests?: InterviewRequestArticleBase[] | null;
};

/**
* - app/views/article/_article_detail.json.jbuilder に対応
- 探究成果記事の詳細情報
- pbl_sponsor_projectはポスターでないときに返却される
*/
export type ArticleDetailBase = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template: { id: string; description?: string | null };
};

/**
 * 探究成果記事の一般公開用
 */
export type ArticleBaseAsPublic = ModelBase & {
  student_id: string | null;
  student_name: string | null;
  title: string;
  kind?: ArticleKind;
  status: string;
  status_code: ArticleStatus;
  created_at: string;
  updated_at: string;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  article_company: HasIdAndName;
  board_company: BoardCompany;
  images?: ArticleImageBase[];
  body: string | null;
  poster?: ArticlePoster;
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template: { id: string; description?: string | null };
};

export interface BoardCompany {
  id: string;
  name: string;
  is_content_company: boolean;
}

/**
 * - engines/student_engine/app/views/student_engine/articles/show.json.jbuilderに対応
 */
export type Article = ArticleDetailBase & { companies?: ReviewerCompany[] | null };

export type ArticleComment = ModelBase & {
  article_id: string;
  created_at: string;
  body: string;
  commentor_id: string | null;
  commentor_company_name?: string | null;
  commenter_name?: string | null;
  commenter_nickname?: string | null;
  commentor_affiliate_id?: string | null;
  role?: RoleBase;
  article_comment_images?: ArticleCommentImageBase[];
};

export type ArticleLike = ModelBase & { article_id: string; created_at: string };

export type ArticleFeedback = ModelBase & {
  article_id: string;
  teacher_id: string;
  created_at: string;
  body: string;
  teacher: { first_name: string; last_name: string };
};

/**
 * 探究成果記事のベースデータ、一覧表示でも利用する
 */
export type ArticleStatsBase = ModelBase & {
  student_id: string;
  student_name: string;
  title: string;
  kind?: ArticleKind;
  status: string;
  status_code: ArticleStatus;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  students_linking?: AffiliateBase[];
  images?: ArticleImageBase[];
  allowed_group_affiliates?: GroupAffiliatePackageMinimalBase[];
  body?: string;
  interview_requests?: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template: { id: string; description?: string | null };
};

/**
 * 探究ボード用
 */
export type ArticleDetailAsPblBoard = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template: { id: string; description?: string | null };
};

export type ArticleSubmitBase = ModelBase & {
  article_companies?: ArticleSubmitBaseParts[];
  pbl_board_campaign_requests?: PblBoardCampaignRequestCompanyName[];
};

export type ArticleAsTeacher = ArticleDetailBase & ArticleSubmitBase;

export interface StudentCommentList {
  article_comments: (ModelBase & {
    title: string;
    student_name: string;
    student_nickname: string | null;
    comment: string;
    comment_name: string;
    comment_nickname: string | null;
  })[];
  total_count: number;
}

/**
 * - 高大連携の一覧用
 */
export type ArticleInterviewRequestBase = ModelBase & {
  title: string;
  assign_user_names: string | null;
  company: { id?: string; name?: string };
};

export interface ArticlePoster {
  sponsor_logo: string | null;
  categorizable?: ArticleCategorizable;
  article_contents: ArticleContent[];
  article_sponsor_contents?: ArticleSponsorContent[];
  pbl_sponsor_article_content_template?: SponsorArticleContentTemplateBase | null;
}

/**
* - app/views/reviewer/articles/show.json.jbuilder に対応
- 探究成果記事の詳細情報
*/
export type ArticleDetailWithAdditionalInfo = ArticleDetailBase & { commented: boolean };

export type ArticleCategorizable = ModelBase & {
  type: AllowedArticleCategorizableType;
  name: string;
  sponsor_logo: string | null;
  company_name?: string | null;
};

/**
 * 生徒が学習した宿題やテストを先生に提出するための関連データを格納する。
 */
export type ArticleImageBase = ModelBase & { file?: { title?: string; thumb_url?: string; file_url?: string } };

export type ArticleContent = ModelBase & { content_type: ArticleContentType; body: string | null };

export type ArticleSponsorContent = ModelBase & {
  pbl_sponsor_article_content_template_field_id: string;
  body: string | null;
};

export enum ArticleStatus {
  Draft = "draft",
  Correction = "correction",
  Reviewing = "reviewing",
  Published = "published",
  TeacherChecking = "teacher_checking",
}

export enum ArticleStatusJa {
  Draft = "下書き",
  Correction = "再提出/提出待ち",
  Reviewing = "完了",
  Published = "完了",
  TeacherChecking = "提出済み/確認中",
}

/**
* - default : 従来
- poster : ポスター
*/
export enum ArticleKind {
  Default = "default",
  Poster = "poster",
}

/**
* - 従来
- ポスター
*/
export enum ArticleKindJa {
  default = "default",
  poster = "poster",
}

/**
* - theme : テーマ
- background : テーマ設定の背景
- hypothesis : 仮説
- survey_method : 調査方法
- result : 結果・分析
- summary : まとめ
- future_issues : 残論点・今後の課題
*/
export enum ArticleContentType {
  Theme = "theme",
  Background = "background",
  Hypothesis = "hypothesis",
  SurveyMethod = "survey_method",
  Result = "result",
  Summary = "summary",
  FutureIssues = "future_issues",
}

export enum ArticleContentTypeJa {
  theme = "テーマ",
  background = "テーマ設定の背景",
  hypothesis = "仮説",
  survey_method = "調査方法",
  result = "結果・分析",
  summary = "まとめ",
  future_issues = "残論点・今後の課題",
}

/**
* - Sponsor::Info : 企業
- Pbl::Sponsor::Project : プロジェクト
*/
export enum AllowedArticleCategorizableType {
  SponsorInfo = "Sponsor::Info",
  PblSponsorProject = "Pbl::Sponsor::Project",
}

export enum CompanyOption {
  Video = "video",
  StudentUi = "student_ui",
  HomeworkFeature = "homework_feature",
  BlogFeature = "blog_feature",
  TextSeller = "text_seller",
  UseImage = "use_image",
  Material = "material",
  Report = "report",
  Payment = "payment",
  ReviewFeature = "review_feature",
  Calender = "calender",
  GoogleClassroom = "google_classroom",
  SteamLibrary = "steam_library",
  ContentCompanyReviewFeature = "content_company_review_feature",
  DashboardFeature = "dashboard_feature",
  StudyLogFeature = "study_log_feature",
  IdealFeature = "ideal_feature",
  PblBoardFeature = "pbl_board_feature",
  ExamFeature = "exam_feature",
  WordCloudFeature = "word_cloud_feature",
  GoogleDriveFeature = "google_drive_feature",
  OneDriveFeature = "one_drive_feature",
  UniversityCooperationFeature = "university_cooperation_feature",
  ArticleStudentCommentFeature = "article_student_comment_feature",
  HomeworkAutomaticScoringFeature = "homework_automatic_scoring_feature",
  ClassNavFeature = "class_nav_feature",
  GogoFeature = "gogo_feature",
}

/**
 * ユーザタグタイプの種類
 */
export enum UserTagTypeKind {
  Limited = "limited",
  Nationwide = "nationwide",
}

/**
 * 学校ごとのタグのタイプ
 */
export type CompanyTagType = ModelBase & { name: string };

/**
 * 学校ごとのタグ情報
 */
export type CompanyTag = ModelBase & {
  name: string;
  free?: boolean;
  number_of_linking_companies?: number;
  number_of_linking_users?: number;
  tag_type?: CompanyTagType;
};

export enum MaterialWorkStatus {
  Todo = "todo",
  InProgress = "in_progress",
  Completed = "completed",
}

export enum MaterialWorkStatusJa {
  Todo = "未着手",
  InProgress = "受講中",
  Completed = "完了",
}

export enum HomeworkStatus {
  Todo = "todo",
  Submitted = "submitted",
  Checked = "checked",
  SubmittedReviewer = "submitted_reviewer",
  AssignedReviewer = "assigned_reviewer",
  CheckedReviewer = "checked_reviewer",
  ReturnedReviewer = "returned_reviewer",
  ChangeRequested = "change_requested",
}

/**
 * 提出物テンプレートを作成時に使用する要素
 */
export interface HomeworkTemplateCreateRequest {
  title: string;
  content?: string;
  needs_review?: boolean;
  needs_score?: boolean;

  /** 模試機能を利用するか否か */
  is_exam?: boolean;
  for_group?: boolean;
  end_at?: string | null;
  reviewer_end_at?: string | null;
  reviewer_company_group_id?: string;
  ideal_board_template_id?: string;

  /** 自動採点の利用 */
  has_automatic_scoring?: boolean;

  /** 提出ファイル有無 */
  has_attached_file?: boolean;
  homework_images?: File[];

  /** あいであるサムネイル画像 */
  ideal_thumbnail_file?: File;
}

/**
 * 提出物テンプレートを更新時に使用する要素
 */
export interface HomeworkTemplateUpdateRequest {
  title?: string;
  content?: string;
  needs_score?: boolean;

  /** 模試機能を利用するか否か */
  is_exam?: boolean;
  reviewer_company_group_id?: string;
  end_at?: string | null;
  reviewer_end_at?: string | null;

  /** 自動採点の利用 */
  has_automatic_scoring?: boolean;

  /** あいであるサムネイル画像 */
  ideal_thumbnail_file?: File;

  /** 提出ファイル有無 */
  has_attached_file?: boolean;
}

export enum HomeworkStatusJa {
  Todo = "新規",
  Submitted = "提出済み",
  Checked = "教職員回答済み",
  SubmittedReviewer = "添削前",
  AssignedReviewer = "添削中",
  CheckedReviewer = "添削済み",
  ReturnedReviewer = "返却済み",
  ChangeRequested = "要再提出",
}

/**
 * - title : タイトル
 */
export enum HomeworkTemplateSearchType {
  Title = "title",
}

export enum HomeworkTemplateStatus {
  Draft = "draft",
  Active = "active",
  Inactive = "inactive",
}

export enum HomeworkTemplateConvertStatus {
  NotNeeded = "not_needed",
  Converting = "converting",
  AddingMarks = "adding_marks",
  Adjusting = "adjusting",
  Merging = "merging",
  Processed = "processed",
  Failed = "failed",
}

export enum HomeworkTemplateConvertStatusJa {
  not_needed = "変換なし",
  converting = "変換中",
  adding_marks = "マーク中",
  adjusting = "補正中",
  merging = "マージ中",
  processed = "処理完了",
  failed = "処理失敗",
}

export enum HomeworkTemplateStatusJa {
  draft = "下書き",
  active = "有効",
  inactive = "停止中",
}

export enum HomeworkTemplateDistributionStatus {
  Todo = "todo",
  DistributedStudents = "distributed_students",
  SubmittedReviewer = "submitted_reviewer",
  CheckedReviewer = "checked_reviewer",
  Returned = "returned",
}

export enum HomeworkTemplateDistributionStatusJa {
  Todo = "新規",
  DistributedStudents = "生徒展開済み",
  SubmittedReviewer = "レビュアー確認中",
  CheckedReviewer = "レビュアー回答済み",
  Returned = "返却済み",
}

/**
 * 先生・管理者がHomeworkのstatusを更新する際に使用
 */
export interface BatchUpdateHomeworkRequest {
  homework_ids: string[];
  status: HomeworkStatus;
  teacher_comment?: string;
  teacher_comment_for_reviewer?: string;
}

export enum ReportStatus {
  Submitted = "submitted",
  Answered = "answered",
  Confirmed = "confirmed",
  Closed = "closed",
}

export enum PaymentStatus {
  Initiated = "initiated",
  CustomerConfirmed = "customer_confirmed",
  TeacherConfirmed = "teacher_confirmed",
  OwnerConfirmed = "owner_confirmed",
}

export enum ReportTestType {
  Regular = "regular",
  Qualify = "qualify",
  Practice = "practice",
}

export enum MaterialOptionForWhom {
  Student = "student",
  Teacher = "teacher",
}

export enum CategoryType {
  Text = "text",
  Grade = "grade",
  Subject = "subject",
  Major = "major",
  Middle = "middle",
  Minor = "minor",
}

export enum CompanyRoleType {
  Reviewer = "reviewer",
}

/**
* - Determines which application screen the request came from.
- In the case of TimeTact, there is no need to specified this information.
*/
export enum AppName {
  MYLSIL = "MYLSIL",
  TimeTact = "TimeTact",
}

export enum HomeworkOrderType {
  StudentName = "student_name",
  AffiliatePackageName = "affiliate_package_name",
  StudentNumber = "student_number",
  SubmittedAt = "submitted_at",
  Status = "status",
  UserTagName = "user_tag_name",
  UserAffiliatePackageName = "user_affiliate_package_name",
}

export interface BaseCategoryInfo {
  id: string;
  name: string;
  depth: number;
  type: CategoryType;
  student_visible?: boolean;
  teacher_visible?: boolean;
  owner_visible?: boolean;
  is_help?: boolean;
  student_help_page?: boolean;
  teacher_help_page?: boolean;
  owner_help_page?: boolean;
  reviewer_help_page?: boolean;
  image?: string | null;
}

export type TextCategoryInfo = BaseCategoryInfo & {
  linking_company_tags?: HasIdAndName[];
  linking_user_tags?: UserTagBasic[];
};

export type GradeCategoryInfo = BaseCategoryInfo & { text_id: string };

export type SubjectCategoryInfo = BaseCategoryInfo & { grade_id: string; text_id: string };

export type MajorCategoryInfo = BaseCategoryInfo & { grade_id: string; subject_id: string; text_id: string };

export type MiddleCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  major_category_id: string;
  text_id: string;
};

export type MinorCategoryInfo = BaseCategoryInfo & {
  major_category_id: string;
  middle_category_id: string;
  grade_id: string;
  subject_id: string;
  text_id: string;
};

export type CategoryInfo =
  | TextCategoryInfo
  | GradeCategoryInfo
  | SubjectCategoryInfo
  | MajorCategoryInfo
  | MiddleCategoryInfo
  | MinorCategoryInfo;

/**
 * category/_category_base.json.jbuilder
 */
export interface CategoryBase {
  category: CategoryPart;
}

/**
 * UserType for the user facing app. (ie. It doesn't contain admin role.)
 */
export enum UserType {
  Student = "student",
  Teacher = "teacher",
  Owner = "owner",
  Reviewer = "reviewer",
}

/**
 * サムネイルの情報
 */
export interface Thumbnail {
  original: string | null;
  large: string | null;
  medium: string | null;
  small: string | null;
}

export interface HasIdAndName {
  id: string;
  name: string;
}

export interface HasNumberIdAndName {
  id: number;
  name: string;
}

export interface HasName {
  name: string;
}

export interface HasIdAndTitle {
  id: string;
  title: string;
}

export interface HasTotalCount {
  total_count: number;
}

export interface HasIdAndOrder {
  id: string;
  order: number;
}

export interface HasAffiliateIdAndName {
  id: string;
  affiliate_id: string;
  name: string;
  nickname: string;
}

export interface StandardAffiliate {
  id: string;
  first_name: string;
  last_name: string;
  company_id: string;
  company_name: string;
}

/**
 * video_content/_video_info.json.jbuilder
 */
export interface VideoInfo {
  id: string;
  title: string;
  material_id: string;
  file_url: string | null;

  /** DEPRECATED - use file_url instead */
  file: { url?: string | null };

  /** エンコードされたファイルのPresigned URLを返す */
  encoded_path?: string | null;
  cookie?: CookieInfo;

  /** 外部URLフィールド */
  external_link?: string;
  thumbnail: { original?: string | null; large?: string | null; medium?: string | null; small?: string | null };
}

/**
 * CloudFrontの署名付きCookie
 */
export interface CookieInfo {
  cloudfront_policy: string;
  cloudfront_signature: string;
  cloudfront_key_pair_id: string;
  unsigned_url: string;
}

/**
 * 教材の学年、教科、大項目、中項目、小項目のカテゴリ情報のテーブル
 */
export interface Category {
  id: string;
  category1: string;
  category2?: string;
  category3?: string;
  category4?: string;
  category5?: string;
}

export interface CategoryPart {
  id: string;
  name: string;
  depth: number;
  type: string;
  text_id?: string;
  grade_id?: string;
  subject_id?: string;
  major_category_id?: string;
  middle_category_id?: string;
}

/**
 * HomeworkTemplateを介して、Homeworkを作成出来る。
 */
export type HomeworkTemplate = HomeworkTemplateBase & HomeworkTemplateAddition;

/**
 * 生徒が学習した宿題やテストを先生に提出するための関連データを格納する。
 */
export type HomeworkTemplateChunkImage = ModelBase & { url: string };

/**
 * Chunk of homework template
 */
export type HomeworkTemplateChunk = ModelBase & {
  template_id: string;
  order: number;
  image?: HomeworkTemplateChunkImage;
  placement: {
    top: number;
    left: number;
    right: number;
    bottom: number;
    natural_width: number;
    natural_height: number;
    page: number;
  }[];
};

/**
 * 学校ごとの提出物テンプレートチャンク、一覧表示で利用する
 */
export type HomeworkTemplateChunkDistributionList = ModelBase & {
  status: HomeworkTemplateChunkDistributionStatusJa;
  status_code: HomeworkTemplateChunkDistributionStatus;
  updated_at: string;
  homework_template_chunk: HasIdAndOrder;
  reviewer_affiliate?: AffiliateName;
};

/**
 * 学校ごとの提出物テンプレートチャンク、一覧表示で利用する
 */
export type HomeworkTemplateChunkDistributionDetail = HomeworkTemplateChunkDistributionList & {
  template: HasIdAndTitle;
};

/**
 * 提出物チャンク、一覧表示で利用する
 */
export type HomeworkChunkList = ModelBase & {
  status: HomeworkChunkStatusJa;
  status_code: HomeworkChunkStatus;
  student_name: string;
  homework_chunk_image: HomeworkChunkImage;
};

/**
 * 提出物チャンク、一覧表示で利用する
 */
export type HomeworkChunkDetail = HomeworkChunkList & { order: number; next_homework_chunk_id: string | null };

/**
 * 提出物チャンク画像
 */
export interface HomeworkChunkImage {
  id: string;
  file: { title?: string; thumb_url?: string | null; url?: string | null };
  reviewer_file: { title?: string; thumb_url?: string | null; url?: string | null };
}

/**
 * リスト用の提出物テンプレート基本情報
 */
export interface HomeworkTemplateBase {
  id: string;
  created_at: string;
  title: string;
  status_code: HomeworkTemplateStatus;
  status: HomeworkTemplateStatusJa;
  convert_status_code: HomeworkTemplateConvertStatus;
  convert_status: HomeworkTemplateConvertStatusJa;
  homework_template_distribution?: HomeworkTemplateDistribution;
  needs_review: boolean;
  needs_score: boolean;

  /** 模試機能の利用 */
  is_exam: boolean;
  for_group: boolean;

  /** 自動採点の利用 */
  has_automatic_scoring: boolean;

  /** 提出ファイル有無 */
  has_attached_file: boolean;

  /** エンドユーザー(基本は生徒)に対して配信された数 */
  end_user_count?: number;

  /** 作成者のRoleを出力 */
  creator_role?: string | null;
  learning_template_package: { is_set?: boolean; title?: string | null };
  ideal_thumbnail_file: string | null;
  common_tags: CommonTagBase[];
}

/**
 * 詳細表示時、baseに追加する情報
 */
export interface HomeworkTemplateAddition {
  content: string;
  reviewer_company_group: { id?: string | null; name?: string | null; is_public_profile?: boolean | null };
  homework_template_images?: {
    id: string;
    title: string;
    thumb_url: string;
    file_url: string;
    adjusted_file_url: string | null;
  }[];
  homework_template_custom_fields: HomeworkTemplateCustomField[];
  ideal_board_template?: BoardTemplateList | null;
  distributed: boolean;
}

/**
 * HomeworkTemplateに紐づくHomeworkのstatusカウント
 */
export type HomeworkTemplateMeta = {
  created_by_owned_company: boolean;
  created_homework_count: number;
  submitted_homework_count: number;
  reviewing_homework_count: number;
  finished_review_homework_count: number;
  returned_homework_count: number;
  school_homework_count: number;
  before_reviewing_homework_count: number;
  set_reviewer_homework_count: number;
};

/**
 * Reviewerページ - HomeworkTemplateを介して、Homeworkを作成出来る。
 */
export type HomeworkTemplateForReviewer = HomeworkTemplateBase &
  HomeworkTemplateAddition & {
    content?: string;
    submitted_reviewer_homeworks?: number;
    assigned_reviewer_homeworks?: number;
    checked_reviewer_homeworks?: number;
  };

/**
 * SuperOwnerアプリ内でのHomeworkTemplateDistribution一覧用のデータ
 */
export type HomeworkTemplateBaseForSuperOwner = HomeworkTemplateBase & {
  num_schools_distributed: number;
  num_distributed_to_students: number;
  num_submitted_to_reviewer: number;
  num_checked_reviewer: number;
  num_returned_to_students: number;
  reviewer_company_group: { id?: string | null; name?: string | null };
};

/**
 * Owner,TeacherでのHomeworkTemplate一覧用のデータ
 */
export type HomeworkTemplateBaseForOwnerAndTeacher = HomeworkTemplateBase &
  HomeworkTemplateMeta & { homework_template_distribution?: HomeworkTemplateDistribution };

/**
 * OwnerページとTeacherページ - HomeworkTemplateを介して、Homeworkを作成出来る。
 */
export type HomeworkTemplateForOwnerAndTeacher = HomeworkTemplateBase &
  HomeworkTemplateAddition &
  HomeworkTemplateMeta & {
    homework_template_distribution?: HomeworkTemplateDistribution;
    idle_distribution_job_present: boolean;
    reviewer_company_group: { id?: string | null; name?: string | null };
  };

/**
 * ダッシュボード機能専用データ
 */
export type HomeworkTemplateStatsBase = ModelBase & {
  title?: string;
  number_of_each_homework_status?: NumberOfEachStatus;
};

/**
 * リスト用の提出物テンプレート配信の詳細情報
 */
export type HomeworkTemplateDistribution = HomeworkTemplateDistributionBase & {
  submitted_at: string | null;
  checked_at: string | null;
  checked_at_raw: string | null;
  returned_at: string | null;
  reviewer_memo: string | null;
  company_memo: string | null;
  company?: Company;
  distribution_images?: {
    reviewer_image?: { title?: string; file?: File; file_url?: string }[];
    super_owner_image?: { title?: string; file?: File; file_url?: string }[];
    teacher_image?: { title?: string; file?: File; file_url?: string }[];
  };
  school_manager?: HasIdAndName;
  pbl_plan_items: HomeworkTemplateDistributionPblPlanItem[];
};

/**
 * リスト用の提出物テンプレート配信の基本情報
 */
export type HomeworkTemplateDistributionBase = ModelBase & {
  created_at: string;
  memo: string | null;
  max_distribution_end_users: number | null;
  end_user_count: number;
  start_at: string | null;
  end_at: string | null;
  reviewer_end_at: string | null;
  status: HomeworkTemplateDistributionStatusJa;
  status_code: HomeworkTemplateDistributionStatus;
  is_exam: boolean;
};

/**
 * Reviewerページ - リスト用のHomeworkTemplateDistribution
 */
export type HomeworkTemplateDistributionBaseForReviewer = HomeworkTemplateDistribution &
  HomeworkTemplateMeta & { company: HasIdAndName; template: HasIdAndTitle; learning_template_package?: any };

export type Homework = HomeworkDetails;

/**
 * 添削会社で提出物一覧を取得するAPI。1度に100件取得できるように設計。
 */
export type ReviewerHomework = ModelBase & {
  status: HomeworkStatusJa;
  status_code: HomeworkStatus;
  student_name: string;
  updated_at: string;
  student_number?: number | null;
  assignees?: AffiliateName[];
  user_group_affiliate_packages?: GroupAffiliatePackageMinimalBase[];
  user_tags?: UserTagBasic[];
};

/**
* - 生徒が学習した宿題やテストを先生に提出するための詳細データを格納する。
- Homeworkとaliasの関係
- Homeworkを、Serverではを既に使っていないが、UIでは90ヶ所以上で使っているので、aliasにする。
*/
export type HomeworkDetails = HomeworkBase & {
  homework_status_changed_history: { status?: HomeworkStatusJa; updated_at?: string }[];
  change_requested_at?: string | null;
  teacher_checked_at?: string | null;
  submitted_reviewer_at?: string | null;
  checked_reviewer_at?: string | null;
  returned_reviewer_at?: string | null;
  distribution_status_code?: HomeworkTemplateDistributionStatus;
  needs_review: boolean;
  needs_score: boolean;
  is_exam: boolean;
  created_at: string;
  updated_at: string;
  student_comment: string;
  teacher_comment?: string | null;
  reviewer_comments?: CategorizableCommentBase[] | null;
  teacher_comment_for_reviewer?: string | null;
  reviewer_comment_for_teacher?: string | null;
  score: number | null;
  assignees: AffiliateName[];
  teacher_homework_images: TeacherHomeworkImage[];
  reviewer_homework_images: ReviewerHomeworkImage[];
  content: string;
  company?: { id?: string; name?: string };
  template?: { id?: string; title?: string };
  reviewer_end_at?: string | null;
  homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  ideal_board?: BoardBase;
  teacher_comment_images?: CommentImageBase[];
  approvings: ApprovingBase[];
  distribution?: { start_at?: string | null; end_at?: string | null };
  ideal_thumbnail_file?: string | null;
};

/**
 * 生徒が学習した宿題やテストを先生に提出するための関連データを格納する。
 */
export type HomeworkBase = ModelBase & {
  title: string;
  status: HomeworkStatusJa;
  status_code: HomeworkStatus;
  submitted_at: string | null;
  student_affiliate_id: string | null;
  student_name: string;
  student_nickname?: string | null;
  teacher_name: string;
  start_at: string | null;
  end_at: string | null;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  student_number?: number | null;
  student_homework_images: StudentHomeworkImage[];
  has_attached_file: boolean;
  common_tags: CommonTagBase[];
  ideal_thumbnail_file: string | null;
};

export type HomeworkBoardBase = ModelBase & { title?: string; template_id?: string | null };

/**
 * 生徒が学習した宿題やテストを先生に提出するための関連データを格納する。
 */
export type HomeworkImage = ModelBase & {
  title: string;
  temp_data: string | null;
  file: { thumb_url?: string | null; file_url?: string | null };
};

/**
 * 生徒が学習した宿題やテストを先生に提出するための関連データを格納する。
 */
export interface StudentHomeworkImage {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
}

/**
 * 先生がレビューを行った際のHomeworkImage
 */
export interface TeacherHomeworkImage {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
  from_template?: boolean;
  temp_data?: string | null;
}

/**
 * レビュアーがレビューを行った際のHomeworkImage
 */
export interface ReviewerHomeworkImage {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
}

export type HomeworkDownloadUrl = ModelBase & {
  student_name: string;
  group_affiliate_name: string;
  student_number?: number | null;
  download_urls: string[];
  is_group: boolean;
};

/**
 * 生徒が学習した宿題やテストを先生に提出するための関連データを格納する。
 */
export type HomeworkStatusBase = ModelBase & { status: HomeworkStatusJa; status_code: HomeworkStatus };

/**
 * image_content/_image_info.json.jbuilder
 */
export interface ImageInfo {
  id: string;
  title: string;
  material_id: string;
  file: { url?: string; thumb: { url: string | null }; icon: { url: string | null } };
  edited_image_url?: string | null;
}

/**
 * app/views/material/_owner_list.json.jbuilder
 */
export type OwnerMaterialList = MaterialList & { material_work?: MaterialWorkList };

/**
* - app/views/material/_detail.json.jbuilder
- studentが取得する場合、メモは見えない
*/
export type MaterialDetail = MaterialList & {
  description: string;
  memo?: string | null;
  download_allowed: boolean;
  is_owned: boolean;
  images: ImageInfo[];
  videos: VideoInfo[];
  html_file: { title?: string | null; url?: string | null };
};

/**
* - app/views/material/_list.json.jbuilder
- is_public: super_ownerが設定する場合、ヘルプページ 及び 先生/生徒の教材一覧で教材を公開する ※親カテゴリで未公開の場合は、いずれを選択しても公開されません。
- is_public: ownerが設定する場合、先生/生徒の教材一覧で教材を公開する ※親カテゴリで未公開の場合は、いずれを選択しても公開されません。
*/
export type MaterialList = ModelBase &
  CategoryBase & {
    title: string;
    thumbnail_file?: string | null;
    is_public: boolean;
    is_video_updating: boolean;
    tags: HasIdAndName[];
  };

export type OwnerMaterialWork = MaterialWorkList & {
  created_at: string;
  material: {
    id?: string;
    title?: string;
    thumbnail: { original: string | null; large: string | null; medium: string | null; small: string | null };
    tags?: HasIdAndName[];
    description?: string;
  };
  user: { id?: string; first_name?: string; last_name?: string; name?: string };
};

export type StudentMaterialWork = MaterialWorkList & {
  images: (ModelBase & { title?: string | null; url?: string | null; image_content_id?: string | null })[];
  material: StudentMaterial;
};

/**
 * engines/teacher_engine/app/views/teacher_engine/material_works/_material_work.json.jbuilder
 */
export type TeacherMaterialWork = StudentMaterialWork & {
  created_at: string;
  user: { last_name: string; first_name: string; nickname: string };
};

export type MaterialWork = MaterialWorkList & { created_at: string; material: { title?: string } };

export type CategoryListBase = ModelBase & { name: string; count: number };

export type TagListBase = ModelBase & { name: string; count: number };

/**
 * app/views/material_work/_material_work_list.json.jbuilder
 */
export type MaterialWorkList = ModelBase & {
  status: MaterialWorkStatusJa;
  status_code: MaterialWorkStatus;
  forbid_download: boolean;
  distributed_by_super_company: boolean;
  is_distributed: boolean;
  is_expired: boolean;
  is_available: boolean;
};

/**
 * ユーザーの各アクションに関する権限を含む
 */
export type AuthorizationInfo = UserAuthorization & {
  create_video?: boolean;
  modify_video?: boolean;
  delete_video?: boolean;
};

/**
* 月謝の支払い管理用のデータを格納する。
管理者がレコードを登録して、生徒は支払いが完了したらステータスを更新。先生は受け取りが確認できたらステータスを更新する。
*/
export type Payment = ModelBase & {
  student_name: string | null;
  teacher_name: string | null;
  title: string | null;
  price?: number;
  status: string;
  status_code: PaymentStatus;
  created_at: string;
  due_date: string | null;
  year: number | null;
  month: number | null;
};

/**
 * 先生が管理者へ指導レポートを提出する。それに関するデータを格納する。
 */
export type Report = ModelBase & {
  created_at?: string;
  teacher_name?: string;
  student_name?: string;
  detail?: string;
  reply?: string | null;
  status?: string;
  title?: string;
  next_goal?: string;
  monthly_teaching_times?: number;
  monthly_student_schedule_change?: number;
  monthly_teacher_schedule_change?: number;
  homework_frequency?: number;
  is_schedule_change_required?: boolean;
  schedule_detail_reason?: string;
  test_misc?: string;
  text_subjects?: string[];
  schedule_change_period?: string;
  schedule_change_notified?: string;
  is_schedule_change_notified?: boolean;
  substitute_lecture?: boolean;
  test_type?: string;
  no_homework_reason?: string;
  no_text_reason?: string;
  reports_test_results?: { subject: string; current_score: number; positive_negative: boolean; score_delta: number }[];
};

export type TeacherUserInfo = BasicUserInfo & {
  teacher_info: {
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    has_register_student?: boolean;
  };
};

/**
 * Basic information of currently logged in user. Fragments of role based data would be added.
 */
export interface CurrentUserInfo {
  id: string;
  affiliate_id?: string;
  email?: string;
  first_name: string;
  last_name: string;
  full_name: string;
  nickname: string | null;

  /** 現在ログイン時刻 */
  current_sign_in_at: string | null;

  /** 前回のログイン時刻 */
  last_sign_in_at: string | null;
  avatar: Avatar;
  current_role: string;
  current_role_name?: string;
  current_status?: AffiliateStatusJa;
  company_id: string;
  company_name: string;
  company_unique_id: string | null;
  company_is_child_company?: boolean;
  company_is_super_company?: boolean;

  /** true: 大学 */
  is_university: boolean;

  /** true: admin_companyフラグの付いている会社 */
  is_admin_company?: boolean;
  app_name: AppName;
  terms_accepted: boolean;
  company_authorization?: Option | null;
  authorization?: AuthorizationInfo;
  company_logo_url?: string | null;
  ui_color_code: string;
  ui_second_color_code?: string;
  teacher_info?: {
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    has_register_student?: boolean;
  };
  new_notification: boolean;
  category_rename_map: CategoryRenameMap;

  /**
   * - 初回ログインフラグ。
   * - true: ログイン済み。
   * - false: 初回ログイン前の状態。
   */
  first_time_login: boolean;

  /** 最終アクセス時刻 */
  last_accessed_at: string | null;
  reviewer_info?: ReviewerInfo | null;

  /** AdminInfo */
  admin_info: { only_pbl_projects_feature: boolean };
}

/**
 * UserGroup(通常はクラス)に関する情報
 */
export type UserGroup = ModelBase & { grade: StudentGrade; name: string; order: number };

/**
 * (app/views/user_tag/_user_tag_basic.json.jbuilder)に対応
 */
export type UserTagBasic = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_linking_users?: number;
  kind?: string;
};

/**
 * Owner向けの生徒詳細情報
 */
export type StudentDetailsForOwner = BasicUserInfo & {
  birth_date?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  student_id?: string;
  grade?: string;
};

/**
 * ユーザの基本情報(viewのuser/_basic_user_info.json.jbuilderに対応)
 */
export type BasicUserInfo = ModelBase & {
  first_name: string;
  last_name: string;
  email: string;
  optional_email?: string | null;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  expired_date?: string | null;
  current_sign_in_at?: string | null;
  last_sign_in_at?: string | null;
  linked_project_count?: number | null;
  affiliate_id?: string | null;
  nickname: string | null;
};

export interface AccountUserInfo {
  user_id: string;
  full_name: string;
  email: string;
  new_password: string;
  group_affiliate_packages?: { title?: string }[] | null;
}

/**
 * viewのuser/_basic_student_info.json.jbuilderに対応)
 */
export interface BasicStudentInfo {
  /** date */
  birth_date?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;

  /** 生徒の学年 */
  grade?: string;
  grade_value?: StudentGrade;
  number?: number | null;
}

export interface StudentInfoAttributes {
  birth_date?: string;
  grade?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  number?: number;
}

export interface TeacherInfoAttributes {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  has_register_student?: boolean;
}

export interface ReviewerInfoAttributes {
  is_manager?: boolean;
}

/**
* 教員情報を格納。
TODO: 教員情報は各事業者毎に取得すべき。つまりこのTeacherInfoはCompany-TeacherのRelation毎に設置すべき(→Affiliateクラス？)(A社向けに入力した個人情報がB社で見えるのはおかしいため)
なお現状はTeacher - TeacherInfoでOne-to-One relationship.
*/
export interface TeacherInfo {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  has_register_student?: boolean;
}

export type UserDetail = BasicUserInfo & {
  avatar?: Avatar;
  student_input_settings?: StudentInputSetting;
  student_info?: BasicStudentInfo;
  teacher_info?: TeacherInfo;
  user_authorizations?: UserAuthorization;
  student_affiliate_id?: string;
  professor_info?: ProfessorInfo;
  account_type?: UserAccountType;
  unique_id?: string | null;
};

/**
 * 大学教員情報を格納。
 */
export interface ProfessorInfo {
  position: string | null;
  department: string | null;
  description: string | null;
  limit_request: number | null;
  profile_picture: string | null;
}

export type ReviewerDetail = BasicUserInfo & { reviewer_info?: ReviewerInfo };

/**
 * ReviewerInfo
 */
export interface ReviewerInfo {
  is_manager: boolean;
}

/**
 * SuperOwner向けのユーザー情報
 */
export type UserInfoForSuperOwner = BasicUserInfo & {
  affiliate_id: string;
  affiliate_status?: AffiliateStatusJa;
  company_id: string;
  company_name: string;
  role_name: string;
  role_jp_name: string;
  user_tags: { name?: string }[];
};

export type UserList = BasicUserInfo & { affiliate: AffiliateAccount };

/**
 * Teacherが取得する教職員一覧の情報
 */
export type TeacherListForTeacher = BasicUserInfo & { affiliate: AffiliateAccount };

/**
 * ownerが取得する管理者一覧の情報
 */
export type OwnerListAsOwner = BasicUserInfo & { affiliate: AffiliateAccount };

/**
 * ownerが取得する教職員一覧の情報
 */
export type TeacherListAsOwner = BasicUserInfo & { affiliate: AffiliateAccount };

/**
 * ownerが取得する生徒一覧の情報
 */
export type StudentListAsOwner = UserList & {
  grade?: string;
  number?: number | null;
  material_work?: MaterialWorkList & { distribution_time: string | null; expiration_time: string | null };
  user_tags_count: number;
  group_affiliate_packages_count: number;
};

/**
 * SuperOwnerが取得する管理者一覧の情報
 */
export type OwnerListAsSuperOwner = BasicUserInfo & { affiliate: AffiliateAccount };

/**
 * SuperOwnerが取得する教職員一覧の情報
 */
export type TeacherListAsSuperOwner = BasicUserInfo & { affiliate: AffiliateAccount };

/**
 * SuperOwnerが取得する生徒一覧の情報
 */
export type StudentListAsSuperOwner = UserList & {
  grade?: string;
  number?: number | null;
  user_tags_count: number;
  group_affiliate_packages_count: number;
};

/**
 * SuperOwnerまたはOwnerが生徒書き出し時に取得する情報
 */
export type SimpleStudentList = UserList & { student_info: BasicStudentInfo };

/**
 * SuperOwnerまたはOwnerが教職員書き出し時に取得する情報
 */
export type SimpleTeacherList = TeacherUserInfo & { affiliate: AffiliateAccount };

/**
 * SuperOwnerまたはOwnerが管理者書き出し時に取得する情報
 */
export type SimpleOwnerList = BasicUserInfo & { affiliate: AffiliateAccount };

/**
 * idと名前、ニックネームのみの情報
 */
export interface SimpleUserList {
  id: string;
  name: string;
  nickname: string;
}

/**
 * teacherが取得する生徒一覧の情報
 */
export type StudentListAsTeacher = UserList & {
  grade?: string | null;
  number?: number | null;
  user_tags_count: number;
  group_affiliate_packages_count: number;
};

export type EmailBase = ModelBase & {
  title?: string;
  created_at?: string;
  to_all_students?: boolean;
  to_all_teachers?: boolean;
  to_all_owners?: boolean;
  from_user?: string;
  sent_count?: number;
  body?: string;
  user_tags?: UserTagBasic[];
  sender_companies?: { id?: string; name?: string }[];
};

export interface NotificationRequest {
  title: string;
  message: string;
  target_roles: string[];
  target_user_tag_ids?: string[];
  target_company_ids: string[];
  target_company_tag_ids?: string[];
  target_affiliate_ids?: string[];
  publication_time?: string;
  with_email?: boolean;
}

export type NotificationBase = ModelBase & {
  title: string;
  message: string;
  resource_info: string | null;
  sender?: { id?: string | null; first_name?: string | null; last_name?: string | null; display_name?: string | null };
  created_at: string;
  updated_at: string;
  notified_time: string;
};

/**
 * /views/notification/index.json.jbuilder
 */
export interface Notifications {
  notifications?: (NotificationBase & {
    user_tags?: UserTagBasic[];
    company_tags?: { id?: string; name?: string }[];
    roles?: { id?: string; name?: string; jp_name?: string }[];
    reservation_time?: string;
    number_of_reserve_deliveries?: number | null;
    status?: NotificationStatus | null;
    companies?: { id?: string; name?: string }[];
  })[];
  total_count?: number;
}

/**
 * /views/notification/show.json.jbuilder
 */
export type Notification = NotificationBase & {
  user_tags?: UserTagBasic[];
  company_tags?: { id?: string; name?: string }[];
  roles?: { id?: string; name?: string; jp_name?: string }[];
  reservation_time?: string;
  number_of_reserve_deliveries?: number | null;
  companies?: { id?: string; name?: string }[];
  number_of_deliveries?: number;
};

export type NotificationResourceInfo = ModelBase & {
  type: NotificationResourceInfoType;
  template_id?: string;
  distribution_id?: string;
  material_id?: string;
};

export enum NotificationStatus {
  Reserved = "reserved",
  Waiting = "waiting",
  Delivering = "delivering",
  Delivered = "delivered",
}

export interface NotificationCounts {
  new_notifications: {
    title?: string;
    message?: string;
    status?: LimitedTimeNotificationStatus;
    start_time?: string;
    end_time?: string;
    created_at?: string;
    updated_at?: string;
    roles?: RoleBase[];
    companies?: HasIdAndName[];
    user_tags?: HasIdAndName[];
    resource_info?: string | null;
    sender?: {
      id?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      display_name?: string | null;
    };
    read?: boolean;
    notified_time?: string;
    type: "Notification" | "LimitedTimeNotification";
  }[];
  new_notification_count: number;
  new_limited_time_notification_count: number;
}

/**
 * Base columns for CustomMasterMap
 */
export interface CustomMasterMapBase {
  master_name: string;
  used_columns: number;
  title_for_column1: string;
  title_for_column2?: string | null;
  title_for_column3?: string | null;
  title_for_column4?: string | null;
  title_for_column5?: string | null;
  title_for_column6?: string | null;
  title_for_column7?: string | null;
  title_for_column8?: string | null;
  title_for_column9?: string | null;
  title_for_column10?: string | null;
  title_for_column11?: string | null;
  title_for_column12?: string | null;
  title_for_column13?: string | null;
  title_for_column14?: string | null;
  title_for_column15?: string | null;
  title_for_column16?: string | null;
  title_for_column17?: string | null;
  title_for_column18?: string | null;
  title_for_column19?: string | null;
  title_for_column20?: string | null;
  column_visible_flags: boolean[];
  has_school_relation: boolean;
}

/**
 * Custom Master Mapping
 */
export type CustomMasterMap = ModelBase & CustomMasterMapBase & { relationships: CustomMasterRelationship[] };

export interface CustomMasterBase {
  column1: string | null;
  column2: string | null;
  column3: string | null;
  column4: string | null;
  column5: string | null;
  column6: string | null;
  column7: string | null;
  column8: string | null;
  column9: string | null;
  column10: string | null;
  column11: string | null;
  column12: string | null;
  column13: string | null;
  column14: string | null;
  column15: string | null;
  column16: string | null;
  column17: string | null;
  column18: string | null;
  column19: string | null;
  column20: string | null;
  column_visible_flags: boolean[];
  company_id?: string;
  company_name?: string;
}

export type CustomMaster = ModelBase & CustomMasterBase & { relationships: CustomMasterRelationship[] };

export interface CustomMasterRelationship {
  relationship_types_id: string;
  relation_custom_master_id: string;
  column_title: string;
  column: string;
}

export interface CustomMasterPublics {
  id: string;
  public_columns: HasNumberIdAndName[];
}

/**
* - カスタムリストのリレーションの情報
- parent_custom_master_map.id: 設定側のCustomMapMasterのID
- child_custom_master_map.id: 紐づけるCustomMapMasterのID
*/
export type CustomMasterMapRelationshipBase = ModelBase & {
  company_id: string;
  name: string;
  parent_custom_master_map: HasIdAndName;
  child_custom_master_map: HasIdAndName;
  parent_title_for_column: TitleForColumn;
  child_title_for_column: TitleForColumn;
};

export interface TitleForColumn {
  number: number;
  name?: string;
}

export interface ReserveDistribution {
  job_id?: string;
  material?: { id?: string; title?: string };
  category?: { id?: string; name?: string };
  grade?: string | null;
  user_tags?: { id?: string; name?: string }[];
  company_tags?: { id?: string; name?: string }[];
}

export type Items = ModelBase & {
  category?: string;
  name?: string;
  stock?: number;
  purchasing?: number;
  description?: string;
  content?: string;
  price?: number;
};

/**
 * app/views/user_tag/distributed_tags.json.jbuilder
 */
export type DistributionHistoryInfo = ModelBase & {
  name: string;
  reservation_time?: string;
  distribution_time?: string;
  expiration_time?: string;
  number_of_distribution: number;
  material?: { id?: string; title?: string };
  category?: { id?: string; name?: string };
  grade: string | null;
  limit_number?: number;
  sent_company: HasIdAndName;
};

export enum SubmitHomeworkFilesRequestStatus {
  Waiting = "waiting",
  ProcessingQr = "processing_qr",
  ProcessingFiles = "processing_files",
  Processed = "processed",
  Failed = "failed",
  Adjusting = "adjusting",
}

/**
 * HomeworkImageの一括登録リクエスト。
 */
export type SubmitHomeworkFilesRequest = ModelBase & {
  status: string;
  status_code: SubmitHomeworkFilesRequestStatus;
  created_at: string;
  error_data: string | null;
  images: { scan_result?: string | null }[];
};

/**
 * Sponsor::Categoryの基本情報
 */
export type SponsorCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: { url?: string | null };
};

/**
 * Sponsor::Imageの基本情報
 */
export interface SponsorImageBase {
  id: string;
  company_id: string;
  url: string | null;
  thumb: { url: string | null };
  icon: { url: string | null };
}

/**
 * ImageのFile情報のみ
 */
export interface SponsorImageFileBase {
  id: string;
  company_id: string;
  url?: string;
  thumb: { url: string | null };
  icon: { url: string | null };
}

/**
 * Sponsor::Infoの基本情報
 */
export type SponsorInfoBase = ModelBase & {
  company_id: string;
  logo_url: string | null;
  name: string;
  description?: string;
  is_public: boolean;
  sales_channel?: SponsorSalesChannel | null;
  created_at: string;
  updated_at: string;
  project_count: number;
  image: SponsorImageBase | null;
  video_content?: SponsorVideoContentBase | null;
  tags: SponsorTagBase[] | null;
};

/**
 * Sponsor::Infoの企業一覧（Teacher/Student）
 */
export type SponsorInfoListBase = ModelBase & {
  company_id: string;
  logo_url: string | null;
  name: string;
  description?: string;
  is_public: boolean;
  sales_channel?: SponsorSalesChannel | null;
  created_at: string;
  updated_at: string;
  project_count: number;
  image: SponsorImageBase | null;
  video_content?: SponsorVideoContentBase | null;
  tags: SponsorTagBase[] | null;
};

/**
 * Sponsor::Infoの詳細情報
 */
export type SponsorInfoDetailsBase = SponsorInfoBase & {
  sponsor_logo_url: string | null;
  categories: SponsorCategoryBase[] | null;
  projects: SponsorProjectAndTagsBase[] | null;
};

/**
 * Sponsor::Infoの情報。Admin用
 */
export type SponsorInfoListForAdmin = SponsorInfoBase & {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  affiliates_count?: number;
  linkings?: SponsorLinkMinBase[];
};

/**
 * Sponsor::Tagの基本情報
 */
export type SponsorTagBase = ModelBase & { company_id: string; name: string };

/**
 * Sponsor::VideoContentの基本情報
 */
export interface SponsorVideoContentBase {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail: { original: string | null; large: string | null; medium: string | null; small: string | null };

  /** エンコードされたファイルのPresigned URLを返す */
  encoded_path?: string | null;
  cookie?: CookieInfo;

  /** 外部URLフィールド */
  external_link?: string | null;
}

/**
 * Pbl::Sponsor::Projectの基本情報
 */
export type SponsorProjectBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description?: string;
  display_allowed: boolean;
  display_in_carousel: boolean;
  created_at: string;
  updated_at: string;
  file?: { url?: string | null };
  image?: SponsorProjectImageBase | null;
  video_content?: SponsorProjectVideoContentBase | null;
  project_work?: { id?: string; progress?: SponsorProjectWorkProgressJa };
};

/**
 * app/views/pbl/sponsor/project/teacher_list_base.json.jbuilderのviewに対応
 */
export type TeacherSponsorProjectListBase = ModelBase & { sponsor_info_id: string; name: string; company_name: string };

/**
 * Pbl::Sponsor::Projectの基本情報とタグ
 */
export type SponsorProjectAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description?: string;
  display_allowed: boolean;
  display_in_carousel: boolean;
  created_at: string;
  updated_at: string;
  tags: SponsorProjectTagBase[] | null;
  file?: { url?: string | null };
  video_content?: SponsorLectureVideoContentBase | null;
  image?: SponsorProjectImageBase | null;
  project_work?: { id?: string; progress?: SponsorProjectWorkProgressJa };
  project_bookmark?: ModelBase | null;
  project_recommend?: ModelBase | null;
};

/**
 * /pbl/steam/_comment_base
 */
export type PblProjectCommentBase = ModelBase & {
  pbl_sponsor_project_id: string;
  pbl_sponsor_lecture_id: string;
  affiliate: AffiliateBase;
  body?: string;
  created_at: string;
  updated_at: string;
};

/**
 * teacher/pbl/project/comment_list
 */
export interface TeacherPblProjectCommentList {
  project: TeacherSponsorProjectListBase;
  lecture: SponsorTeacherLectureListBase;
  comment: TeacherPblProjectCommentListBase;
}

/**
 * /pbl/steam/teacher_list_comment_base
 */
export type TeacherPblProjectCommentListBase = ModelBase & {
  pbl_sponsor_project_id: string;
  pbl_sponsor_lecture_id: string;
  commenter_name: string;
  commenter_nickname: string | null;
  body: string;
};

/**
 * Pbl::Sponsor::Projectの詳細情報
 */
export type SponsorProjectDetailsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description: string;
  display_allowed?: boolean;
  file?: { url?: string | null };
  created_at: string;
  updated_at: string;
  image?: SponsorProjectImageBase | null;
  video_content?: SponsorProjectVideoContentBase | null;
  tags: SponsorProjectTagBase[] | null;
  categories: SponsorProjectCategoryBase[] | null;
  lectures: SponsorLectureAndTagsBase[] | null;
  project_work?: { id?: string; progress?: SponsorProjectWorkProgressJa };
  lecture_viewed_count: number;
  professor_affiliate_ids?: string[] | null;
  is_recommend: boolean;
  company_name?: string;
  sponsor_logo_url?: string | null;
};

/**
 * pbl/report/template/_base.json.jbuilder
 */
export type PblReportTemplateBase = ModelBase & { title: string; status: string; due_date_time: string | null };

/**
 * pbl/report/template/_base.json.jbuilder
 */
export type PblReportTemplateStats = ModelBase & {
  title: string;
  status: string;
  due_date_time: string | null;
  number_of_each_answer_status?: { status: string; count: number }[] | null;
  answers?: PblReportAnswerBase[] | null;
};

/**
 * pbl/report/comments/_base.json.jbuilder
 */
export interface PblReportCommentBase {
  id?: string;
  message?: string;
  created_at?: string;
  commented_by?: string;
}

/**
 * pbl/report/template/_base.json.jbuilder
 */
export type PblReportTemplateDetailsForTeacher = PblReportTemplateBase;

/**
 * pbl/report/item/_text_base.json.jbuilder
 */
export interface PblReportTextBase {
  id: string;
  sequence: number;
  title?: string | null;
  description?: string | null;
  example?: string | null;
  text_min_char_size?: number | null;
  has_title_required?: boolean | null;
}

/**
* - todo : 新規
- drafting : 下書き
- submitted : 提出
*/
export enum PblReportAnswerStatusEnum {
  Todo = "todo",
  Drafting = "drafting",
  Submitted = "submitted",
}

export enum PblReportAnswerStatusEnumJa {
  Todo = "新規",
  Drafting = "下書き",
  Submitted = "提出",
}

/**
* - draft : 下書き
- submit : 提出
*/
export enum PblReportAnswerStudentEventEnum {
  Draft = "draft",
  Submit = "submit",
}

/**
 * pbl/report/answer_item/_text_base.json.jbuilder
 */
export interface PblReportAnswerTextBase {
  id: string;
  title?: string;
  body?: string;
  item_id: string;
}

/**
 * pbl/report/answer_item/_text_base.json.jbuilder
 */
export type PblReportAnswerTextBaseWithComments = PblReportAnswerTextBase & { comments: PblReportCommentBase[] };

/**
 * pbl/report/answer/_base.json.jbuilder
 */
export type PblReportAnswerBase = ModelBase & {
  student: string;
  student_nickname?: string | null;
  status: string;
  status_code?: PblReportAnswerStatusEnum;
};

/**
 * Item(設問)情報を含むAnswerItem
 */
export type PblReportAnswerTextWithItem = PblReportAnswerTextBase & { item?: PblReportTextBase };

/**
 * 生徒アプリ内のAnswer一覧用にAnswerとTemplateの情報を返す。
 */
export type PblReportAnswerForStudent = PblReportAnswerBase & {
  template: ModelBase & { title?: string; due_date_time?: string };
};

/**
 * 生徒アプリ内のAnswer一覧用にAnswerとTemplateの情報を返す。
 */
export type PblReportAnswerDetailsForStudent = PblReportAnswerForStudent & {
  items: PblReportTextBase[];
  answer_items: PblReportAnswerTextBaseWithComments[];
  comments: PblReportCommentBase[];
  answer_images: PblReportAnswerImageBase[];
};

/**
 * 先生アプリ内のAnswer一覧用にAnswerとTemplateの情報を返す。
 */
export type PblReportAnswerDetailsForTeacher = PblReportAnswerBase & {
  template: PblReportTemplateDetailsForTeacher;
  items: PblReportTextBase[];
  answer_items: PblReportAnswerTextBaseWithComments[];
  comments: PblReportCommentBase[];
  answer_images: PblReportAnswerImageBase[];
};

/**
 * 生徒が作成した探究レポートの画像
 */
export type PblReportAnswerImageBase = ModelBase & { file?: { title?: string; thumb_url?: string; file_url?: string } };

/**
 * 生徒が登録する探究レポート画像のリクエスト
 */
export type PblReportAnswerImageRequest = { answer_id: string; file: File };

export enum PblReportItemTypeEnum {
  Text = "text",
}

export enum PblCardBookmarkSourceType {
  Newspaper = "newspaper",
  Internet = "internet",
  Book = "book",
  Other = "other",
}

/**
 * pbl/cards/information_literacy/_base.json.jbuilder
 */
export type PblCardBookmarkBase = ModelBase & {
  source_type: string;
  source_type_code: PblCardBookmarkSourceType;
  student_name: string;
  student_nickname?: string | null;
  title?: string;
  url: string | null;
  memo: string;
  wysiwyg_body?: string | null;
  created_at: string;
  updated_at: string;
  num_comments: number;
  images: { id: string; title?: string | null; url: string }[];
  allowed_group_affiliates?: GroupAffiliatePackageMinimalBase[];
  stamp_fields: StampableStampFields;
  creator_affiliate_id?: string | null;
  student_number?: number | null;
  common_tags: CommonTagBase[];
  chat_room_id: string | null;
};

/**
 * teacher/pbl/cards/information_literacy/bookmarks/stats.json.jbuilder
 */
export interface PblCardBookmarkStats {
  date: string;
  count: number;
}

/**
 * pbl/cards/information_literacy/_base.json.jbuilder
 */
export type PblCardBookmarkList = PblCardBookmarkBase & { read_status: boolean };

/**
* - pbl/cards/information_literacy/_base.json.jbuilder
- commentor_roleは現状、teacher, student, nullのみ
*/
export type PblCardCommentBase = ModelBase & {
  commentor_id: string;
  commenter_name: string;
  commentor_role: Role | null;
  body: string;
  created_at: string;
};

export interface Forbidden {
  message?: string;
}

export interface Unauthorized {
  error?: string;
}

export interface BadRequest {
  errors?: { key?: string; message?: string }[];
}

/**
 * app/views/material_distribution_history/_list_base.json.jbuilder
 */
export interface MaterialDistributionHistory {
  id: string;
  user_tags: (UserTagBasic & { user_tag_type_id?: string; user_tag_type_name?: string })[];
  company_tags: CompanyTag[];
  selected_companies: HasIdAndName[];
  selected_category?: CategoryBase | null;
  materials: HistoryMaterial[];
  distribution_time?: string;
  expiration_time?: string | null;
  created_at?: string;
  status: DistributionStatusStatus | null;
}

export interface MaterialDistributionHistoryListStudents {
  total_count: number;
  number_of_students_per_grade: { grade: string | null; count: number }[];
  students: (BasicUserInfo & { grade: string | null })[];
}

/**
 * app/views/material_distribution_history/_search_base.json.jbuilder
 */
export interface MaterialDistributionHistorySearchByUserId {
  id: string;
  user_hashid: string;
  last_name: string;
  first_name: string;
  company_name: string;
  distribution_time?: string;
  expiration_time?: string | null;
  reservation_time?: string | null;
  created_at?: string;
  material?: CategoryBase & {
    id?: string;
    title?: string;
    memo?: string;
    description?: string;
    image_contents?: ImageInfo[];
    video_contents?: VideoInfo[];
  };
}

export type HistoryMaterial = CategoryBase & {
  id: string;
  title: string;
  number_of_people_distributed: number;
  number_of_schools_distributed: number;
  distributed_companies: HistoryCompany[];
};

export interface HistoryCompany {
  id: string;
  name: string;
  number_of_students_distributed: number;
}

/**
 * app/views/material/_student_material.json.jbuilder
 */
export type StudentMaterial = MaterialDetail & {
  thumbnail: { original?: string | null; large?: string | null; medium?: string | null; small?: string | null };
  edited_images: (ModelBase & { title?: string | null; url?: string | null; image_content_id?: string | null })[];
};

/**
 * Pbl::Sponsor::ProjectTagの基本情報
 */
export type SponsorProjectTagBase = ModelBase & { company_id: string; name: string };

/**
 * Pbl::Sponsor::ProjectCategoryの基本情報
 */
export type SponsorProjectCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: { url?: string | null };
};

/**
 * Sponsor::ProjectImageの基本情報
 */
export interface SponsorProjectImageBase {
  id: string;
  company_id: string;
  url?: string;
  thumb: { url: string | null };
  icon: { url: string | null };
}

/**
 * Sponsor::ProjectVideoContentの基本情報
 */
export interface SponsorProjectVideoContentBase {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail: { original: string | null; large: string | null; medium: string | null; small: string | null };

  /** エンコードされたファイルのPresigned URLを返す */
  encoded_path?: string | null;
  cookie?: CookieInfo;

  /** 外部URLフィールド */
  external_link?: string | null;
}

/**
 * Pbl::Sponsor::ProjectBookmarkの基本情報
 */
export type SponsorProjectBookmarkBase = ModelBase & { project_id: string; affiliate: AffiliateBase };

/**
 * Pbl::Sponsor::ProjectRecommendの基本情報
 */
export type SponsorProjectRecommendBase = ModelBase & { project_id: string; affiliate: AffiliateName };

/**
* Pbl::Sponsor::Lectureの基本情報
app/views/pbl/sponsor/lecture/_base.json.jbuilderのviewに対応
*/
export type SponsorLectureBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content?: SponsorLectureVideoContentBase | null;
};

/**
* Pbl::Sponsor::Lectureの基本情報
app/views/pbl/sponsor/lecture/teacher_list_base.json.jbuilderのviewに対応
*/
export type SponsorTeacherLectureListBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id?: string;
  name: string;
};

/**
 * Pbl::Sponsor::Lectureの基本情報とタグ
 */
export type SponsorLectureAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content?: SponsorLectureVideoContentBase | null;
  tags?: SponsorLectureTagBase[] | null;
};

/**
 * Pbl::Sponsor::Lectureの詳細情報
 */
export type SponsorLectureDetailsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content: SponsorLectureVideoContentBase | null;
  tags: SponsorLectureTagBase[] | null;
  files: SponsorLectureFileBase[] | null;
  learning_progress?: { id?: string; status?: any };
  article_content_template: { id: string; title: string; description?: string | null };
  company_name?: string;
  sponsor_logo_url?: string | null;
  project_name?: string;
};

/**
 * Pbl::Sponsor::LectureTagの基本情報
 */
export type SponsorLectureTagBase = ModelBase & { company_id: string; name: string };

/**
 * Sponsor::LectureImageの基本情報
 */
export interface SponsorLectureImageBase {
  id: string;
  company_id: string;
  url?: string;
  thumb: { url: string | null };
  icon: { url: string | null };
}

/**
 * Sponsor::LectureVideoContentの基本情報
 */
export interface SponsorLectureVideoContentBase {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail: { original: string | null; large: string | null; medium: string | null; small: string | null };

  /** エンコードされたファイルのPresigned URLを返す */
  encoded_path?: string | null;
  cookie?: CookieInfo;

  /** 外部URLフィールド */
  external_link?: string | null;
}

/**
 * Pbl::Sponsor::LectureFileの基本情報
 */
export type SponsorLectureFileBase = ModelBase & {
  company_id: string;
  file: { title?: string | null; url?: string | null };
};

/**
 * Pbl::Sponsor::Linkの基本情報
 */
export type SponsorLinkBase = ModelBase & {
  company: {
    id?: string;
    name?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    owner_count?: number;
    teacher_count?: number;
    student_count?: number;
  };
};

/**
 * Pbl::Sponsor::Lectureの基本情報とタグ
 */
export type SponsorSchoolBase = ModelBase & {
  id: string;
  name: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
};

/**
 * Pbl::Sponsor::Linkの基本情報
 */
export type SponsorLinkMinBase = ModelBase & { sponsor_id: string; school_id: string };

export type SponsorArticleContentTemplateBase = ModelBase & {
  title: string;
  description?: string | null;
  pbl_sponsor_lecture_id?: string;
  fields?: SponsorArticleContentTemplateFieldBase[];
};

export type SponsorArticleContentTemplateFieldBase = ModelBase & { title: string };

/**
* - direct_selling : 直販
- selling_agency : 代理店販売
*/
export enum SponsorSalesChannel {
  DirectSelling = "direct_selling",
  SellingAgency = "selling_agency",
}

/**
 * Schedule
 */
export type ScheduleBase = ModelBase & {
  start_time: string;
  end_time: string;
  all_day: boolean;
  title: string;
  place?: string;
  private_flag: boolean;
  memo?: string;
  host?: { id?: string | null; name?: string | null; nickname?: string | null };
  attendees: { id?: string; user_id?: string; name?: string; nickname?: string | null; role?: string }[];
  plan_class?: TeacherPblPlanClass;
};

/**
 * - stamp/_base.json.jbuilder
 */
export type StampBase = ModelBase & {
  stampable_type: StampableType;
  stampable_id: string;
  affiliate_id: string;
  user_name: string;
  kind: StampType;
  created_at: string;
};

/**
 * Stampの最小限の情報
 */
export type StampFieldBase = ModelBase & { user_name: string; created_at: string };

/**
 * 会社・ロールごとにアクセスが許可されているIPアドレス
 */
export type IpWhitelistBase = ModelBase & { ip_address: string; role: RoleBase; company: ModelBase & { name: string } };

export type PblSteamContentBase = ModelBase & {
  sl_content_id: number;
  title: string;
  difficulty: SteamLibraryContentDifficulty;
  aspect: SteamLibraryContentAspect;
  is_scientific: boolean;
  has_carousel: boolean;
  created_at: string;
  updated_at: string;
};

export type PblSteamContent = PblSteamContentBase & {
  bookmark: { id?: string | null };
  recommendation: { id?: string | null };
};

export type StudentPblSteamContentDetail = PblSteamContent & { progress?: SteamLibraryContentWorkProgress };

/**
 * student/pbl/steam/conntent_works
 */
export type StudentPblSteamContentWork = ModelBase & {
  student: AffiliateBase;
  pbl_steam_content_id: string;
  progress?: string;
  created_at: string;
  updated_at: string;
};

/**
 * teacher/pbl/steam/comment_list
 */
export interface PblSteamCommentList {
  content: PblSteamContentBase;
  lecture: PblSteamLecture;
  comment: PblSteamCommentBase;
}

/**
 * teacher/pbl/steam/comments
 */
export type PblSteamCommentBase = ModelBase & {
  pbl_steam_content_id: string;
  pbl_steam_lecture_id: string;
  affiliate: AffiliateBase;
  is_public?: boolean;
  body?: string;
  created_at: string;
  updated_at: string;
};

/**
 * admin/pbl/steam/lectures
 */
export type PblSteamLecture = ModelBase & {
  pbl_steam_content_id: string;
  sl_lecture_id: number;
  title?: string;
  sequence: number;
  created_at: string;
  updated_at: string;
};

/**
 * student/pbl/steam/conntent_works
 */
export type StudentLectureHistory = ModelBase & {
  student_affiliate_id: string;
  pbl_steam_content_id: string;
  pbl_steam_lecture_id: string;
  sl_content_id: number;
  sl_lecture_id: number;
  title: string;
  created_at: string;
  updated_at: string;
};

/**
 * student/pbl/steam/lecture_histories/stats
 */
export interface StudentLectureStats {
  /** コンテンツが受講中の件数を返却 */
  viewing_contents_count: number;

  /** コンテンツが受講済みの件数を返却 */
  completed_contents_count: number;

  /** 閲覧したレクチャーをの件数を返却 */
  viewed_lectures_count: number;

  /** すべてのレクチャーの件数を返却 */
  total_lectures_count: number;
  steam_contents?: {
    id?: string;
    sl_content_id?: number;
    title?: string;
    steam_content_works?: { id: string; progress: string }[] | null;
    lectures?: { id: string; sl_lecture_id: number; title: string }[] | null;
    lecture_histories?: { id: string; sl_lecture_id: number; title: string }[] | null;
  }[];
}

/**
 * student/pbl/steam/histories/count
 */
export type StudentLectureHistoryCount = ModelBase & { title: string; viewed_lectures_count: number };

/**
 * student/pbl/steam/conntent_works
 */
export type ContentBookmarkBase = ModelBase & { pbl_steam_content_id: string; sl_content_id: number; title: string };

/**
 * student/pbl/steam/content_recommendation
 */
export type ContentRecommendationBase = ModelBase & {
  company_id: string;
  company_name: string;
  content: PblSteamContentBase;
};

/**
* - unsolved: 未処理
- answered: 回答済み
- replied: 返答あり
- in_progress: 対応中
- completed: 完了
*/
export enum InquiryStatus {
  Unsolved = "unsolved",
  Answered = "answered",
  Replied = "replied",
  InProgress = "in_progress",
  Completed = "completed",
}

export enum InquiryKind {
  System = "system",
  UserSupport = "user_support",
  Content = "content",
  Other = "other",
}

/**
 * 学習ログの基本情報
 */
export type StudyLogBasicBase = ModelBase & {
  log: string;
  categorizable_type: string;
  categorizable_id: string;
  behavior: string;
  created_at: string;
  categorizable: { parent_category_id: string | null; is_discarded: boolean };
};

/**
 * 学習ログの基本情報
 */
export type StudyLogBase = StudyLogBasicBase & { student: AffiliateName };

/**
 * 各生徒の学習ログ
 */
export interface StudyByStudentBase {
  student_user_id?: string;
  student_affiliate_id?: string;
  full_name?: string;
  study_logs?: StudyLogBasicBase[];
}

/**
 * 各生徒の学習ログ
 */
export interface DeliverableListBase {
  student_user_id: string;
  student_affiliate_id: string;
  full_name: string;
  nickname?: string;
  material_works: { count?: number; todo_count?: number; in_progress_count?: number };
  homeworks: { count?: number; todo_count?: number; change_requested_count?: number };
  articles: { count?: number; draft_count?: number; correction_count?: number };
  learning_packages: { count?: number };
  bookmarks: { count?: number };
  ideals: { count?: number; todo_count?: number; in_progress_count?: number };
  answers: { count?: number };
}

/**
 * 生徒自身の成果物
 */
export interface DeliverableListBaseAsStudent {
  material_works: { count?: number; todo_count?: number; in_progress_count?: number };
  homeworks: { count?: number; todo_count?: number; change_requested_count?: number };
  articles: { count?: number; draft_count?: number; correction_count?: number };
  learning_packages: { count?: number };
  bookmarks: { count?: number };
  ideals: { count?: number; todo_count?: number; in_progress_count?: number };
  answers: { count?: number };
}

/**
 * 各成果物のリスト
 */
export type StudentLogDeliverableListBase = ModelBase & {
  title: string;
  updated_at: string;
  categorizable_type: StudentLogCategorizableType;
};

/**
 * 受講中の教材のリスト
 */
export type StudentLogDeliverableMaterialWorkBase = StudentLogDeliverableListBase & {
  status: MaterialWorkStatusJa;
  status_code: MaterialWorkStatus;
  parent_id: string;
};

/**
 * 提出物のリスト
 */
export type StudentLogDeliverableHomeWorkBase = StudentLogDeliverableListBase & {
  status: HomeworkStatusJa;
  status_code: HomeworkStatus;
  parent_id: string;
};

/**
 * 探究レポートのリスト
 */
export type StudentLogDeliverablePblAnswerBase = StudentLogDeliverableListBase & {
  status: PblReportAnswerStatusEnumJa;
  status_code: PblReportAnswerStatusEnum;
  parent_id: string;
};

/**
 * 学習プログラムのリスト
 */
export type StudentLogDeliverableLearningPackageBase = StudentLogDeliverableListBase & {
  status: LearningPackageStatusJa;
  status_code: LearningPackageStatus;
  parent_id: string;
};

/**
 * 探究成果物のリスト
 */
export type StudentLogDeliverableArticleBase = StudentLogDeliverableListBase & {
  status: ArticleStatusJa;
  status_code: ArticleStatus;
};

/**
 * 探究成果物のリスト
 */
export type StudentLogDeliverableIdealBase = StudentLogDeliverableListBase & {
  status: IdealBoardStatusJa;
  status_code: IdealBoardStatus;
};

/**
* - Article : 探究成果
- Homework : 提出物
- MaterialWork : 受講中の教材
- Pbl::Cards::InformationLiteracy::Bookmark : 調査メモ​​
- Pbl::Report::Answer : 探究レポート​
- Pbl::Steam::Comment : STEAM Library
- Pbl::Steam::ContentWork : STEAM Library
- Schedule : カレンダー​
*/
export enum StudyLogCategorizableType {
  Article = "Article",
  Homework = "Homework",
  MaterialWork = "MaterialWork",
  PblCardsInformationLiteracyBookmark = "Pbl::Cards::InformationLiteracy::Bookmark",
  PblReportAnswer = "Pbl::Report::Answer",
  PblSteamComment = "Pbl::Steam::Comment",
  PblSteamContentWork = "Pbl::Steam::ContentWork",
  Schedule = "Schedule",
}

/**
* - saved: 保存​しました
- updated: 更新​しました
- registered: 登録​しました
- submitted: 提出​しました
- resubmitted: 再提出​しました
- draft_saved: 下書き保存​しました
- draft_updated: 下書き更新​しました
- draft_created: 下書き作成​しました
- attend_started: 受講開始​しました
- attend_finished: 受講終了​しました
- attend_completed: 受講完了​しました
- return_to_before_attend: 受講前に戻しました
- return_to_during_attend: 受講中に戻しました
- commented: コメント​しました
- confirmed_by_teacher: 教職員確認​に進めました
*/
export enum StudyLogBehavior {
  Saved = "saved",
  Updated = "updated",
  Registered = "registered",
  Submitted = "submitted",
  Resubmitted = "resubmitted",
  DraftSaved = "draft_saved",
  DraftUpdated = "draft_updated",
  DraftCreated = "draft_created",
  AttendStarted = "attend_started",
  AttendFinished = "attend_finished",
  AttendCompleted = "attend_completed",
  ReturnToBeforeAttend = "return_to_before_attend",
  ReturnToDuringAttend = "return_to_during_attend",
  Commented = "commented",
  ConfirmedByTeacher = "confirmed_by_teacher",
}

/**
 * 日報の基本情報
 */
export type DailyLogBase = ModelBase & {
  date?: string;
  study_time?: number;
  category?: string;
  category_status_code?: string;
  memo?: string;
  created_at?: string;
  updated_at?: string;
  student?: AffiliateName;
};

/**
* - theme_settings : 課題の設定
- research : 情報の収集
- analysis : 整理・分析
- summary : まとめ・表現
*/
export enum DailyLogCategory {
  ThemeSettings = "theme_settings",
  Research = "research",
  Analysis = "analysis",
  Summary = "summary",
}

export enum DailyLogCategoryJa {
  theme_settings = "課題の設定",
  research = "情報の収集",
  analysis = "整理・分析",
  summary = "まとめ・表現",
}

/**
 * 日報のcategoryの件数を返却
 */
export interface ActivityBase {
  category: string;
  count: number;
  study_time: number;
}

/**
 * 日報の各カテゴリーの件数と勉強時間を返却
 */
export interface DailyLogStatsBase {
  theme_settings: DailyLogStatsValuesBase;
  research: DailyLogStatsValuesBase;
  analysis: DailyLogStatsValuesBase;
  summary: DailyLogStatsValuesBase;
}

/**
 * 件数と勉強時間を返却
 */
export interface DailyLogStatsValuesBase {
  count: number;
  study_time: number;
}

export type RubricBase = ModelBase & {
  title: string;
  status: RubricStatusJa;
  status_code: RubricStatus;
  created_at: string;
  updated_at: string | null;
  teacher_affiliate?: AffiliateName;
  update_affiliate?: AffiliateName | null;
  rubric_evaluate_count: number;
  rubric_items?: RubricItemBase[] | null;
};

export type RubricItemBase = ModelBase & {
  rubric_id: string;
  curriculum_type: RubricItemCurriculumTypeJa;
  curriculum_type_code: RubricItemCurriculumType;
  perspective: string;
  level_1: string;
  level_2: string;
  level_3: string;
  level_4: string;
  level_5: string;
  created_at: string;
  updated_at: string;
};

/**
* - draft : 下書き
- published : 公開​
*/
export enum RubricStatus {
  Draft = "draft",
  Published = "published",
}

export enum RubricStatusJa {
  draft = "下書き",
  published = "公開​",
}

/**
* - knowledge_and_skills : 知識及び技能
- knowledge : 知識
- skills : 技能
- intelligence_and_expression_and_judgement : 思考力・表現力・判断力
- intelligence : 思考力
- expression : 表現力
- judgement : 判断力
- power_to_learn_and_humanity : 学びに向かう力・人間性
- power_to_learn : 学びに向かう力
- humanity : 人間性
*/
export enum RubricItemCurriculumType {
  KnowledgeAndSkills = "knowledge_and_skills",
  Knowledge = "knowledge",
  Skills = "skills",
  IntelligenceAndExpressionAndJudgement = "intelligence_and_expression_and_judgement",
  Intelligence = "intelligence",
  Expression = "expression",
  Judgement = "judgement",
  PowerToLearnAndHumanity = "power_to_learn_and_humanity",
  PowerToLearn = "power_to_learn",
  Humanity = "humanity",
}

export enum RubricItemCurriculumTypeJa {
  knowledge_and_skills = "知識及び技能",
  knowledge = "知識",
  skills = "技能",
  intelligence_and_expression_and_judgement = "思考力・表現力・判断力",
  intelligence = "思考力",
  expression = "表現力",
  judgement = "判断力",
  power_to_learn_and_humanity = "学びに向かう力・人間性",
  power_to_learn = "学びに向かう力",
  humanity = "人間性",
}

export type RubricEvaluateBase = ModelBase & {
  title: string;
  description?: string;
  students: RubricEvaluateStudentBase[];
};

export type RubricEvaluateStudentBase = ModelBase & {
  comment?: string;
  student: AffiliateName;
  evaluate_student_rates?: RubricEvaluateStudentRateBase[];
};

export type RubricEvaluateStudentRateBase = ModelBase & { score: number; rubric_item: RubricItemBase };

/**
* - corporate: 企業情報
- project: プロジェクト情報
- lecture: 授業情報
*/
export enum SponsorPageLayer {
  Corporate = "corporate",
  Project = "project",
  Lecture = "lecture",
}

/**
 * 学習コンテンツの情報
 */
export type LearningTemplatePackageBase = ModelBase & {
  title: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  is_distributed: boolean;
  affiliate: AffiliateName;
  packagings_count: number;
};

/**
 * 配信された学習コンテンツの情報
 */
export type LearningPackageBase = ModelBase & {
  title: string;
  description?: string;
  status: LearningPackageStatusJa;
  start_at?: string;
  end_at?: string;
  created_at: string;
  updated_at: string;
  affiliate: {
    id?: string;
    user_id?: string;
    first_name?: string;
    last_name?: string;
    company_id?: string;
    company_name?: string;
  };
};

/**
 * 配信された学習コンテンツの詳細情報
 */
export type LearningPackageBaseDetails = LearningPackageBase & {
  packagings?: {
    id: string;
    packageable_type: string;
    packageable_id?: string;
    title: string | null;
    status?: string | null;
    packagings?: {
      id?: string;
      packageable_type?: string;
      packageable_id?: string;
      title?: string;
      status?: string;
      start_at?: string | null;
      learning_progress?: LearningProgressBase;
    }[];
  }[];
};

/**
 * LearningTemplatePackageDistributionSettingsの情報
 */
export type LearningTemplatePackageDistributionSettingBase = ModelBase & {
  learning_template_package_id: string;
  start_at: string;
  end_at?: string;
  kind: string;
  grade?: StudentGrade;
  forbid_download: boolean;
  created_at: string;
  updated_at: string;
  distributor_affiliate?: AffiliateBase | null;
  companies?: CompanyBase[];
  user_tags?: UserTagBasic[];
};

/**
 * LearningTemplatePackageDistributionSettingsの詳細情報
 */
export type LearningTemplatePackageDistributionSettingBaseDetails = LearningTemplatePackageDistributionSettingBase & {
  learning_template_package?: LearningTemplatePackageBase;
};

/**
 * LearningTemplatePackageDistributionSettingsの配信履歴の情報
 */
export type LearningTemplatePackageDistributionHistoryBase = ModelBase & {
  learning_template_package_id: string;
  distributor_affiliate_id: string;
  start_at: string;
  end_at?: string;
  distribution_at: string;
  kind: string;
  grade?: StudentGrade;
  forbid_download: boolean;
  number_of_completed_learning_package?: number;
  number_of_students_distributed: number;
  number_of_school_distributed?: number;
  status?: DistributionStatusStatus | null;
  deletion_progress: DeletionProgress;
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
  learning_template_package: LearningTemplatePackageBase;
};

/**
* - HomeworkTemplate : 学習コンテンツ（LearningTemplatePackage）のパッケージで利用可能
- Material : 学習コンテンツ（LearningTemplatePackage）のパッケージで利用可能
- Package : 学習コンテンツ（LearningTemplatePackage）のパッケージで利用可能（MaterialHomeworkTemplatePackage）
- Pbl::Steam::Lecture : 学習コンテンツ（LearningTemplatePackage）のパッケージで利用可能（Steamライブラリー）
- Pbl::Sponsor::Lecture : 学習コンテンツ（LearningTemplatePackage）のパッケージで利用可能（企業コンテンツ）
- Homework : 学習コンテンツ（LearningPackage）のパッケージで利用可能
- MaterialWork : 学習コンテンツ（LearningPackage）のパッケージで利用可能
- Affiliate : グループ（GroupAffiliatePackage）のパッケージで利用可能
*/
export enum PackagingPackageableType {
  HomeworkTemplate = "HomeworkTemplate",
  Material = "Material",
  Package = "Package",
  PblSteamLecture = "Pbl::Steam::Lecture",
  PblSponsorLecture = "Pbl::Sponsor::Lecture",
  Homework = "Homework",
  MaterialWork = "MaterialWork",
  Affiliate = "Affiliate",
}

/**
 * Pbl::Board::Infoの情報
 */
export type PblBoardInfoBase = ModelBase & {
  status: PblBoardInfoStatusJa;
  created_at: string;
  updated_at: string;
  board_items: PblBoardItemBase[];
  articles: PblBoardArticleBase[];
  article_total_count: number;
  company: { id: string; name: string; is_content_company: boolean };
};

/**
 * Pbl::Board::Infoの一覧情報
 */
export type PblBoardInfoIndexBase = ModelBase & {
  first_content: {
    catchphrase_image?: { url?: string | null; thumb?: { url?: string | null }; icon?: { url?: string | null } };
  };
  company: { id?: string; name?: string; is_content_company?: boolean };
};

/**
 * Pbl::Board::Itemの情報
 */
export type PblBoardItemBase = ModelBase & {
  content_type: PblBoardItemContentTypeJa;
  sequence: number;
  board_contents?: PblBoardContentBase[];
  board_footers?: PblBoardFooterBase[];
};

/**
 * Pbl::Board::ItemのShowページ用
 */
export type PblBoardItemBaseAsShow = ModelBase & { content_type: PblBoardItemContentTypeJa; sequence: number };

/**
 * Pbl::Board::Campaignの情報
 */
export type PblBoardCampaignBase = ModelBase & {
  title: string;
  description?: string;
  start_date: string;
  end_date: string;
  status: PblBoardCampaignStatusJa;
};

/**
 * Pbl::Board::Campaignのリスト
 */
export type PblBoardCampaignList = ModelBase & {
  title: string;
  start_date: string;
  end_date: string;
  status: PblBoardCampaignStatusJa;
  unconfirmed_count?: number;
  approved_count?: number;
  non_approved_count?: number;
};

/**
 * Teacher用Pbl::Board::Campaignのリスト
 */
export type PblBoardCampaignListAsTeacher = ModelBase & {
  title: string;
  start_date: string;
  end_date: string;
  submitted: boolean;
  description?: string;
  campaign_request?: { id?: string; status?: PblBoardCampaignRequestStatusJa };
  company: PblBoardCampaignCompany;
};

export interface PblBoardCampaignCompany {
  id: string;
  name: string;

  /** nilの場合は企業 */
  parent_company_id?: number | null;
}

/**
 * Pbl::Board::CampaignRequestのリスト
 */
export type PblBoardCampaignRequestList = ModelBase & {
  sequence: number;
  status: PblBoardCampaignRequestStatusJa;
  article: { id?: string; title?: string };
  teacher_affiliate: StandardAffiliate;
};

/**
 * Pbl::Board::CampaignRequestのリスト
 */
export type PblBoardCampaignRequestCompanyName = ModelBase & {
  status: PblBoardCampaignRequestStatusJa;
  company: HasIdAndName;
};

/**
 * Pbl::Board::Contentの情報
 */
export type PblBoardContentBase = ModelBase & {
  design_type: PblBoardContentDesignTypeJa;
  catchphrase?: string;
  catchphrase_description?: string;
  catchphrase_image?: { url?: string | null; thumb?: { url?: string | null }; icon?: { url?: string | null } };
  cta_name?: string;
  cta_url?: string;
  external_link?: string;
};

/**
 * Pbl::Board::Footerの情報
 */
export type PblBoardFooterBase = ModelBase & {
  category_name?: string;
  sequence?: number;
  board_footers?: PblBoardFooterItemBase[];
};

/**
 * Pbl::Board::FooterItemの情報
 */
export type PblBoardFooterItemBase = ModelBase & { title?: string; url?: string };

/**
 * 探究成果募集の探究成果の情報
 */
export type PblBoardArticleBase = ModelBase & {
  title: string;
  kind?: ArticleKind;
  body: string;
  cover: string | null;
  company: HasIdAndName;
  images: ArticleImageBase[];
};

export enum PblBoardInfoStatus {
  Draft = "draft",
  Published = "published",
}

export enum PblBoardInfoStatusJa {
  draft = "公開停止中",
  published = "公開中",
}

/**
* - public_info : 探究ボードを公開する
- private_info : 探究ボードを非公開にする
*/
export enum PblBoardInfoEvent {
  PublicInfo = "public_info",
  PrivateInfo = "private_info",
}

export enum PblBoardItemContentType {
  Template = "template",
  PblList = "pbl_list",
  Footer = "footer",
  WordCloud = "word_cloud",
}

export enum PblBoardItemContentTypeJa {
  template = "テンプレート",
  pbl_list = "探究成果一覧",
  footer = "フッター",
  word_cloud = "ワードクラウド",
}

export enum PblBoardContentDesignType {
  Template1 = "template_1",
  Template2 = "template_2",
  Template3 = "template_3",
  Template4 = "template_4",
}

export enum PblBoardContentDesignTypeJa {
  template_2 = "テンプレート1",
  template_3 = "テンプレート2",
  template_4 = "テンプレート3",
  template_5 = "テンプレート4",
}

export enum PblBoardCampaignStatus {
  Draft = "draft",
  Opening = "opening",
  Closed = "closed",
}

export enum PblBoardCampaignStatusJa {
  draft = "募集前",
  opening = "募集中",
  closed = "終了",
}

export enum PblBoardCampaignRequestStatus {
  Unconfirmed = "unconfirmed",
  Approved = "approved",
  NonApproved = "non_approved",
}

export enum PblBoardCampaignRequestStatusJa {
  unconfirmed = "未確認",
  approved = "承認",
  non_approved = "非承認",
}

/**
* - submit : statusを募集中にする
- cancel : statusを募集前にする
- close : statusを終了にする
*/
export enum PblBoardCampaignEvent {
  Submit = "submit",
  Cancel = "cancel",
  Close = "close",
}

/**
* - accept : 承認にする
- reject : 非承認にする
- cancel : 未確認にする
*/
export enum PblBoardCampaignRequestEvent {
  Accept = "accept",
  Reject = "reject",
  Cancel = "cancel",
}

/**
 * teacher/pbl/plan/Classes
 */
export type TeacherPblPlanClass = ModelBase & {
  pbl_plan_item_id: string;
  year?: number;
  number?: number;
  date?: string;
  description?: string;
  is_skip?: boolean;
  article_creation_time?: boolean;
  created_at: string;
  updated_at: string;
  plan_contents?: TeacherPblPlanContent[];
  schedule_id?: string | null;
  class_minutes?: number | null;
  title?: string | null;
};

/**
* - teacher/pbl/plan/Contents
- when categorizable_type is Pbl::Report::Template and current_user is student
  it returns pbl_report_answer_id as categorizable_related_ids
- when categorizable_type is HomeworkTemplateDistribution and current_user is teacher
  it returns homework_template_id as categorizable_related_ids
- when categorizable_type is HomeworkTemplateDistribution and current_user is student
  it returns homework_ids as categorizable_related_ids
*/
export type TeacherPblPlanContent = ModelBase & {
  pbl_plan_class_id: string;
  categorizable_type: PlanContentsCategorizableType;
  categorizable_id: string;
  categorizable_ja_model_name: string;
  categorizable_title: string;
  categorizable_related_ids: string[];
  kind: PblPlanContentKind;
  sl_lecture_id?: number | null;
  is_project_list?: boolean;
};

/**
* - for class nav
- when categorizable_type is HomeworkTemplateDistribution and current_user is teacher
  it returns number_of_each_homework_status
*/
export type PblPlanContentNav = ModelBase &
  TeacherPblPlanContent & { image_url?: string | null; number_of_each_homework_status?: NumberOfEachStatus };

/**
* - learn : 知る
- make : 作る​
*/
export enum PblPlanContentKind {
  Learn = "learn",
  Make = "make",
}

/**
 * teacher/pbl/plan/days
 */
export type TeacherPblPlanDay = ModelBase & {
  pbl_plan_item_id: string;
  class_date?: string;
  start_time?: string;
  created_at: string;
  updated_at: string;
};

/**
 * teacher/pbl/plan/students
 */
export type TeacherPblPlanStudent = ModelBase & {
  pbl_plan_item_id: string;
  student?: AffiliateName & { email?: string };
  created_at: string;
  updated_at: string;
};

/**
 * teacher/pbl/plan/items
 */
export type TeacherPblPlanItemBase = ModelBase & {
  class_minutes?: number;
  plan_years?: number;
  class_start_date?: string;
  class_end_date?: string;
  policy_summary?: string;
  why?: string;
  how?: string;
  what?: string;
  first_year_summary?: string;
  second_year_summary?: string;
  third_year_summary?: string;
  status: string;
  students_count: number;
  created_at: string;
  updated_at: string;
  deletion_progress: DeletionProgress;
};

/**
 * teacher/pbl/plan/items
 */
export type HomeworkTemplateDistributionPblPlanItem = ModelBase & { policy_summary: string };

/**
 * teacher/pbl/plan/items
 */
export type TeacherPblPlanItemDetails = TeacherPblPlanItemBase & { days: TeacherPblPlanDay[] };

/**
* - todo : 新規
- in_progress : 進行中（dataにデータが入ったら新規から遷移）
- completed : 完了（ユーザが完了ボタンを押したら遷移）
*/
export enum IdealBoardStatus {
  Todo = "todo",
  InProgress = "in_progress",
  Completed = "completed",
}

export enum IdealBoardStatusJa {
  Todo = "新規",
  InProgress = "進行中",
  Completed = "完了",
}

/**
* - start : 開始
- complete : 完了
- restart : 再取り組み
*/
export enum IdealBoardEvent {
  Start = "start",
  Complete = "complete",
  Restart = "restart",
}

/**
* - todo : 新規
- in_progress : 添削中（添削担当がついたら遷移）
- completed : 完了（すべてのhomework_chunkが添削完了して、添削者が完了ボタンを押下したら遷移）
*/
export enum HomeworkTemplateChunkDistributionStatus {
  Todo = "todo",
  InProgress = "in_progress",
  Completed = "completed",
}

export enum HomeworkTemplateChunkDistributionStatusJa {
  Todo = "新規",
  InProgress = "添削中",
  Completed = "添削済",
}

/**
* - complete : complete review
- restart : restart review
*/
export enum HomeworkTemplateChunkDistributionEvent {
  Complete = "complete",
  Restart = "restart",
}

/**
* - todo : 新規
- completed : 完了
*/
export enum HomeworkChunkStatus {
  Todo = "todo",
  Completed = "completed",
}

export enum HomeworkChunkStatusJa {
  Todo = "新規",
  Completed = "添削済",
}

/**
* - complete : complete review
- restart : restart review
*/
export enum HomeworkChunkEvent {
  Complete = "complete",
  Restart = "restart",
}

/**
* - name : 企業名
- description : 説明文
- tag : タグ名
*/
export enum SponsorInfosSearchType {
  Name = "name",
  Description = "description",
  Tag = "tag",
}

/**
* - name : プロジェクト名
- description : 説明文
- tag : タグ名
*/
export enum SponsorProjectsSearchType {
  Name = "name",
  Description = "description",
  Tag = "tag",
}

/**
* - sequence : シーケンスカラム昇順
- recent : 作成日時降順
- random : ランダム
*/
export enum SponsorProjectsSortType {
  Sequence = "sequence",
  Recent = "recent",
  Random = "random",
}

/**
 * アイデアボックスのリストデータ、一覧表示で利用する
 */
export type BoardBase = ModelBase & {
  name: string;
  status: IdealBoardStatusJa;
  status_code: IdealBoardStatus;
  size: IdealBoardSize;
  created_at: string;
  template_id: string | null;
  homework?: HomeworkBoardBase | null;
  host_affiliate_id: string | null;
};

/**
 * アイデアボックスのリストデータ、一覧表示で利用する
 */
export type BoardList = BoardBase & {
  invited_affiliates: AffiliateAvatar[];
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
};

/**
 * アイデアボックスの詳細データ、詳細表示で利用する
 */
export type Board = BoardBase & {
  data: string | null;
  host_affiliate?: AffiliateName;
  host_role?: RoleBase;
  invited_affiliates: AffiliateAvatar[];
  allowed_group_affiliates: GroupAffiliatePackageBaseAvatar[];
};

/**
* あいであるテンプレートのリストデータ、一覧表示で利用する
app/views/ideal/board_template/_list.json.jbuilder
*/
export type BoardTemplateList = ModelBase & {
  name: string;
  status: IdealBoardTemplateStatus;
  size: IdealBoardSize;
  start_at: string;
  homework_template?: HasIdAndTitle | null;
};

/**
* あいであるテンプレートの詳細データ、詳細表示で利用する
app/views/ideal/board_template/_detail.json.jbuilder
*/
export type BoardTemplate = BoardTemplateList & { data: string | null; affiliate?: AffiliateName };

/**
* - GroupAffiliatePackageのリスト情報
- app/views/group_affiliate_package/_list.json.jbuilder
*/
export type GroupAffiliatePackageList = ModelBase & {
  title: string;
  created_at: string;
  updated_at: string;
  affiliates: { id: string; user_id: string; first_name: string; last_name: string }[];
  assign_teacher_affiliates?: AffiliateName[];
};

/**
* - GroupAffiliatePackageのリスト情報
- app/views/group_affiliate_package/_list.json.jbuilder
*/
export type GroupAffiliatePackageGroupOnlyList = ModelBase & {
  title: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  student_count: number;
  assign_teacher_affiliates?: AffiliateName[];
};

/**
* - GroupAffiliatePackageの最小限の情報
- app/views/group_affiliate_package/_base.json.jbuilder
*/
export type GroupAffiliatePackageMinimalBase = ModelBase & { title: string };

/**
* - GroupAffiliatePackageの詳細情報
- app/views/group_affiliate_package/_detail.json.jbuilder
*/
export type GroupAffiliatePackageBase = ModelBase & {
  title: string;
  description?: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateBase;
  affiliates: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    grade?: string;
    nickname?: string;
  }[];
  assign_teacher_affiliates?: AffiliateName[];
};

/**
* - GroupAffiliatePackageの最小限の情報 + AffiliateAvatar
- app/views/group_affiliate_package/_base_avatar.json.jbuilder
*/
export type GroupAffiliatePackageBaseAvatar = GroupAffiliatePackageMinimalBase & { affiliates: AffiliateAvatar[] };

/**
 * Steamライブラリーコンテンツと生徒を紐づける情報
 */
export type StudentContentList = ModelBase & {
  content: StudentPblSteamContentDetail;
  teacher_affiliate: { id?: string; first_name?: string; last_name?: string };
  student_affiliate: { id?: string; first_name?: string; last_name?: string };
};

export enum SteamLibraryContentDifficulty {
  Easy = "easy",
  Normal = "normal",
  Difficult = "difficult",
}

export enum SteamLibraryContentAspect {
  Familiar = "familiar",
  Japan = "japan",
  World = "world",
}

export enum SteamLibraryContentWorkProgress {
  NotViewed = "not_viewed",
  Viewing = "viewing",
  Viewed = "viewed",
}

export enum LearningPackageStatus {
  Incompleted = "incompleted",
  Completed = "completed",
}

export enum LearningPackageStatusJa {
  incompleted = "未完了",
  completed = "完了",
}

export interface VideoFileBase {
  url: string | null;
}

/**
* - Article: 探究成果
- HomeworkTemplateDistribution: 提出物テンプレート配信
- Homework: 提出物
- Material: 教材配信​
- Pbl::Report::Template: 探究レポート​
- Pbl::Sponsor::Lecture: 企業プロジェクト
- Pbl::Steam::Content: STEAM Libraryのテーマ
- Pbl::Steam::Lecture: STEAM Libraryのレクチャー
- CustomContent: 企業プロジェクト一覧等
*/
export enum PlanContentsCategorizableType {
  Article = "Article",
  HomeworkTemplateDistribution = "HomeworkTemplateDistribution",
  Homework = "Homework",
  Material = "Material",
  PblReportTemplate = "Pbl::Report::Template",
  PblSponsorLecture = "Pbl::Sponsor::Lecture",
  PblSteamContent = "Pbl::Steam::Content",
  PblSteamLecture = "Pbl::Steam::Lecture",
  CustomContent = "CustomContent",
}

/**
 * FeedbackTemplateの情報
 */
export type FeedbackTemplateBase = ModelBase & {
  icon: { title?: string; url?: string };
  feedback_template_comments: FeedbackTemplateCommentBase[];
};

/**
 * FeedbackTemplateCommentの情報
 */
export type FeedbackTemplateCommentBase = ModelBase & { body: string };

/**
 * 探究成果記事のコメントに画像を付与する
 */
export type ArticleCommentImageBase = ModelBase & { title: string | null; url: string | null };

/**
 * パッケージの情報
 */
export type PackagingBase = ModelBase & {
  packageable_type: string;
  packageable_id: string;
  packageable_title: string;
  sl_lecture_id?: number | null;
};

/**
 * 学習コンテンツ用のパッケージ情報
 */
export type PackagingAsLearningTemplatePackageBase = ModelBase & {
  packageable_type: string;
  packageable_id: string;
  packageable_title: string;
  material_homework_template_package?: MaterialHomeworkTemplatePackageBase | null;
  sl_lecture_id?: number | null;
};

/**
 * 教材と提出物のパッケージの情報
 */
export type MaterialHomeworkTemplatePackageBase = ModelBase & {
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateName;
  packagings: PackagingBase[];
};

export type AttachedImageBase = ModelBase & {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
  file_name: string;
  file_url: string;
};

/**
* - Material: 教材
- Ideal::Board: あいである
- Article: 探究成果
- Pbl::Board::Content: 探究ボード
- Pbl::Sponsor::Lecture: 企業プロジェクトのレクチャー
- Ideal::BoardTemplate: あいであるテンプレート
- Interview::Message: 高大連携のメッセージ
- HomeworkTemplateCustomFieldFeedback: カスタムフィールド
- Gogo::Message: 探究GoGoのメッセージ
*/
export enum AttachedImageCategorizableType {
  Material = "Material",
  IdealBoard = "Ideal::Board",
  Article = "Article",
  PblBoardContent = "Pbl::Board::Content",
  PblSponsorLecture = "Pbl::Sponsor::Lecture",
  IdealBoardTemplate = "Ideal::BoardTemplate",
  InterviewMessage = "Interview::Message",
  HomeworkTemplateCustomFieldFeedback = "HomeworkTemplateCustomFieldFeedback",
  GogoMessage = "Gogo::Message",
}

/**
* - grade : gradeからソートする
- numberAsc : numberからソート（昇順）する
- numberDesc : numberからソート（降順）する
*/
export enum SortedByStudentList {
  Grade = "grade",
  NumberAsc = "numberAsc",
  NumberDesc = "numberDesc",
}

export enum AffiliateStatus {
  Enable = "enable",
  Expired = "expired",
  Waiting = "waiting",
  Locked = "locked",
  Scheduling = "scheduling",
  Unapproved = "unapproved",
  SuspensionOfUse = "suspension_of_use",
}

export enum AffiliateStatusJa {
  enable = "利用中",
  expired = "期限切れ",
  waiting = "登録待ち",
  locked = "ロック中",
  scheduling = "準備中",
  unapproved = "未承認",
  suspension_of_use = "利用停止",
}

export enum Role {
  Admin = "admin",
  SuperOwner = "super_owner",
  Owner = "owner",
  Teacher = "teacher",
  Student = "student",
  Reviewer = "reviewer",
  ContentProvider = "content_provider",
}

export enum RoleJa {
  admin = "SV管理者",
  super_owner = "親管理者",
  owner = "管理者",
  teacher = "教職員",
  student = "生徒",
  reviewer = "添削利用者",
  content_provider = "コンテンツ事業者",
}

/**
 * ワードクラウドのリストデータ
 */
export interface WordCloudBase {
  word_cloud_list: WordCloudWord[];
  total_count: number;
}

/**
 * ワードクラウドの単語データ
 */
export interface WordCloudWord {
  text: string;
  value: number;
}

/**
 * CSVエクスポートの基本情報
 */
export type TtCsvExportBase = ModelBase & {
  status: TtCsvExportStatus;
  type_ja: TtCsvExportTypeJa;
  created_at: string;
  completed_at: string | null;
  file: { name?: string | null; url?: string | null };
};

/**
 * カテゴリーの基本情報
 */
export type TtCategoryBase = ModelBase & { name: string; type: TtCategoryBaseType };

/**
* - DateAsc : 日付を（昇順）でソートする
- TitleAsc : タイトルを（昇順）でソートする
- TitleDesc : タイトルを（降順）でソートする
- 指定しない場合は日付を（降順）でソートする
*/
export enum SortedByMaterialWork {
  DateAsc = "DateAsc",
  TitleAsc = "TitleAsc",
  TitleDesc = "TitleDesc",
}

export enum SelectRoleByLimitedTimeNotification {
  Owner = "owner",
  Teacher = "teacher",
  Student = "student",
}

/**
 * リスト用のLimitedTimeNotification基本情報
 */
export type LimitedTimeNotificationList = ModelBase & {
  title: string;
  message: string;
  status: LimitedTimeNotificationStatus;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  roles: RoleBase[];
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
};

/**
 * リスト用のLimitedTimeNotification詳細情報
 */
export type LimitedTimeNotificationDetail = ModelBase & {
  title: string;
  message: string;
  roles: RoleBase[];
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
};

/**
 * お知らせ用のLimitedTimeNotification情報
 */
export type LimitedTimeNotificationNoticeBase = ModelBase & {
  title: string;
  message: string;
  checked: boolean;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
};

export enum LimitedTimeNotificationStatus {
  Preview = "公開前",
  Published = "公開中",
  Closed = "掲載終了",
}

/**
 * - company_name : 会社名
 */
export enum PblBoardInfoSearchType {
  CompanyName = "company_name",
}

/**
* - any : すべて
- school : 学校
- content : コンテンツ事業会社
*/
export enum PblBoardInfoCompanyType {
  Any = "any",
  School = "school",
  Content = "content",
}

/**
 * オプションの拡張
 */
export interface OptionExpansionBase {
  material_expansion?: MaterialExpansionBase;
  ideal_expansion?: IdealExpansionBase;
}

/**
 * 教材登録のディスク使用量制限
 */
export type MaterialExpansionBase = ModelBase & { capacity?: OptionsExpansionCapacity };

/**
 * あいである枚数制限のオプション追加
 */
export type IdealExpansionBase = ModelBase & { capacity?: OptionsExpansionCapacity };

/**
 * 利用可能かtrue、falseで返却
 */
export interface LimitOptionBase {
  /** 教材登録のディスク使用量制限を超えている場合、true を返却 */
  over_capacity_material?: boolean | null;

  /** あいである枚数制限を超えている場合、true を返却 */
  over_capacity_ideal?: boolean | null;
}

/**
* - unlimited
  - material_expansion: 制限なし
  - ideal_expansion: 制限なし
- capacity_1
  - material_expansion: 1GB まで
  - ideal_expansion: 1枚 まで
- capacity_2
  - material_expansion: 3GB まで
  - ideal_expansion: 3枚 まで
- capacity_3
  - material_expansion: 10GB まで
  - ideal_expansion: 6枚 まで
- capacity_4
  - material_expansion: 20GB まで
  - ideal_expansion: 10枚 まで
- capacity_5
  - material_expansion: 100GB まで
  - ideal_expansion: 20枚 まで
*/
export enum OptionsExpansionCapacity {
  Unlimited = "unlimited",
  Capacity1 = "capacity_1",
  Capacity2 = "capacity_2",
  Capacity3 = "capacity_3",
  Capacity4 = "capacity_4",
  Capacity5 = "capacity_5",
}

/**
* - Article: 探究成果
- Ideal::Board: あいである
*/
export enum AllowedGroupAffiliateCategorizableType {
  Article = "Article",
  IdealBoard = "Ideal::Board",
}

export enum IdealBoardSize {
  S = "S",
  M = "M",
  MV = "MV",
  L = "L",
}

export enum IdealBoardSizeJa {
  S = "小",
  M = "中",
  MV = "縦",
  L = "大",
}

export enum IdealBoardTemplateStatus {
  Draft = "draft",
  Reserved = "reserved",
  Distributed = "distributed",
}

export enum IdealBoardTemplateStatusJa {
  Draft = "未配信",
  Reserved = "配信設定済み",
  Distributed = "配信中",
}

/**
 * - recaptcha_verified_error : failed to verify recaptcha
 */
export enum RecaptchaError {
  RecaptchaVerifiedError = "recaptcha_verified_error",
  RecaptchaParseError = "recaptcha_parse_error",
}

/**
 * LearningTemplatePackageDistributionSettingsの配信履歴の配信先生徒の情報
 */
export interface DistributedStudents {
  id: string;
  first_name: string;
  last_name: string;
  company_id: string;
  company_name: string;
  grade: string | null;
}

export enum TtCsvExportStatus {
  Running = "running",
  Completed = "completed",
  Failed = "failed",
}

export enum TtCsvExportStatusJa {
  Running = "書き出し中",
  Completed = "完了",
  Failed = "失敗",
}

export enum TtCsvExportType {
  AdminSponsorInfoList = "admin_sponsor_info_list",
  SuperOwnerCompanyList = "super_owner_company_list",
  SuperOwnerOwnerList = "super_owner_owner_list",
  SuperOwnerTeacherList = "super_owner_teacher_list",
  SuperOwnerStudentList = "super_owner_student_list",
  SuperOwnerTagList = "super_owner_tag_list",
  SuperOwnerCompanyTagList = "super_owner_company_tag_list",
  SuperOwnerNationwideUserTagAndStudentList = "super_owner_nationwide_user_tag_and_student_list",
  SuperOwnerLimitedUserTagAndStudentList = "super_owner_limited_user_tag_and_student_list",
  SuperOwnerMaterialDistributionHistoryList = "super_owner_material_distribution_history_list",
  SuperOwnerLearningPackageDistributionHistoryList = "super_owner_learning_package_distribution_history_list",
  SuperOwnerLearningPackageDistributionHistoryDetail = "super_owner_learning_package_distribution_history_detail",
  SuperOwnerHomeworkTemplateDistributionHistoryList = "super_owner_homework_template_distribution_history_list",
  SuperOwnerHomeworkTemplateDistributionHistoryDetail = "super_owner_homework_template_distribution_history_detail",
  SuperOwnerCustomList = "super_owner_custom_list",
  SuperOwnerUserTagDistributionHistoryList = "super_owner_user_tag_distribution_history_list",
  OwnerOwnerList = "owner_owner_list",
  OwnerTeacherList = "owner_teacher_list",
  OwnerStudentList = "owner_student_list",
  OwnerUserTagList = "owner_user_tag_list",
  OwnerNationwideUserTagAndStudentList = "owner_nationwide_user_tag_and_student_list",
  OwnerLimitedUserTagAndStudentList = "owner_limited_user_tag_and_student_list",
  OwnerUniversityTeacherList = "owner_university_teacher_list",
  OwnerHomeworkCommentList = "owner_homework_comment_list",
  OwnerHomeworkTemplateCustomFieldList = "owner_homework_template_custom_field_list",
  TeacherHomeworkTemplateCustomFieldList = "teacher_homework_template_custom_field_list",
  TeacherStudentList = "teacher_student_list",
  TeacherPblPlanScheduleList = "teacher_pbl_plan_schedule_list",
  TeacherHomeworkCommentList = "teacher_homework_comment_list",
  ReviewerHomeworkTemplateCustomFieldList = "reviewer_homework_template_custom_field_list",
  ReviewerHomeworkCommentList = "reviewer_homework_comment_list",
}

export enum TtCsvExportTypeJa {
  AdminSponsorInfoList = "企業プロジェクト情報",
  SuperOwnerCompanyList = "学校情報",
  SuperOwnerOwnerList = "管理者情報",
  SuperOwnerTeacherList = "教職員情報",
  SuperOwnerStudentList = "生徒情報",
  SuperOwnerTagList = "タグ情報",
  SuperOwnerCompanyTagList = "学校タグの学校情報",
  SuperOwnerNationwideUserTagAndStudentList = "ユーザタグの生徒情報",
  SuperOwnerLimitedUserTagAndStudentList = "限定ユーザタグの生徒情報",
  SuperOwnerMaterialDistributionHistoryList = "教材配信履歴情報",
  SuperOwnerLearningPackageDistributionHistoryList = "プログラム配信履歴一覧情報",
  SuperOwnerLearningPackageDistributionHistoryDetail = "プログラム配信履歴詳細情報",
  SuperOwnerHomeworkTemplateDistributionHistoryList = "提出物配信履歴情報",
  SuperOwnerHomeworkTemplateDistributionHistoryDetail = "提出物配信履歴詳細情報",
  SuperOwnerCustomList = "レコード情報",
  SuperOwnerUserTagDistributionHistoryList = "限定タグ配信履歴情報",
  OwnerOwnerList = "管理者情報",
  OwnerTeacherList = "教職員情報",
  OwnerStudentList = "生徒情報",
  OwnerUserTagList = "タグ情報",
  OwnerNationwideUserTagAndStudentList = "ユーザタグの生徒情報",
  OwnerLimitedUserTagAndStudentList = "限定ユーザタグの生徒情報",
  OwnerUniversityTeacherList = "大学教員情報",
  OwnerHomeworkCommentList = "提出物コメント情報",
  OwnerHomeworkTemplateCustomFieldList = "提出物回答情報",
  TeacherHomeworkTemplateCustomFieldList = "提出物回答情報",
  TeacherStudentList = "生徒情報",
  TeacherPblPlanScheduleList = "授業計画",
  TeacherHomeworkCommentList = "提出物コメント情報",
  ReviewerHomeworkTemplateCustomFieldList = "提出物回答情報",
  ReviewerHomeworkCommentList = "提出物コメント情報",
}

/**
* - running: 削除中
- failed: 失敗
- idle: 何もしない
*/
export enum DeletionProgress {
  Running = "running",
  Failed = "failed",
  Idle = "idle",
}

/**
* - asc : 昇順
- desc : 降順
*/
export enum SortedByOrder {
  Asc = "asc",
  Desc = "desc",
}

/**
* - title : タイトル
- created_at : 作成日
*/
export enum RubricEvaluateSortedByColumn {
  Title = "title",
  CreatedAt = "created_at",
}

/**
* - title : タイトル
- created_at : 登録日時
*/
export enum MaterialSortedByColumn {
  Title = "title",
  CreatedAt = "created_at",
}

/**
* - Pbl::Steam::Lecture: Steamライブラリーのレクチャー
- Pbl::Sponsor::Lecture: 企業コンテンツのレクチャー
*/
export enum LearningProgressCategorizableType {
  PblSteamLecture = "Pbl::Steam::Lecture",
  PblSponsorLecture = "Pbl::Sponsor::Lecture",
}

/**
* - check : 確認中に変更する
- restart : 新規に変更する
*/
export enum LearningProgressEvent {
  Check = "check",
  Restart = "restart",
}

/**
 * super_owner/homework_template_distribution_history
 */
export type SuperOwnerHomeworkTemplateDistributionHistory = ModelBase & {
  homework_template_id: string;
  homework_template_distributions_id: string;
  template_name: string;
  distribution_time: string;
  start_at?: string | null;
  end_at?: string | null;
  count_of_users: number;
  count_of_companies: number;
  memo?: string | null;
  status: string;
  status_code: string;
  company_names: { company_id?: string; company_name?: string }[];
};

/**
 * super_owner/homework_template_distribution_history_affiliates
 */
export interface SuperOwnerHomeworkTemplateDistributionHistoryAffiliates {
  homework_template_distribution_history: SuperOwnerHomeworkTemplateDistributionHistory;
  affiliates: AffiliateBase[];
  total_count: number;
}

/**
 * super_owner/homework_template_distribution_history_companies
 */
export interface SuperOwnerHomeworkTemplateDistributionHistoryCompanies {
  homework_template_distribution_history: SuperOwnerHomeworkTemplateDistributionHistory;
  companies: Company[];
  total_count: number;
}

/**
 * reviewer_company_groups_/_list
 */
export type ReviewerCompanyGroupBase = ModelBase & {
  name: string;
  is_main_reviewer_group: boolean;
  super_company: ModelBase & { name?: string };
  is_public_profile?: boolean;
};

/**
 * reviewer_company_groups_/_detail
 */
export type ReviewerCompanyGroupDetail = ReviewerCompanyGroupBase & {
  reviewer_companies: (ModelBase & { name?: string })[];
  reviewee_companies: (ModelBase & { name?: string })[];
};

/**
 * Google DriveのFileList情報
 */
export interface GoogleDriveFileList {
  kind: string;
  next_page_token?: string;
  incomplete_search: boolean;
  files: GoogleDriveFile[];
}

/**
 * Google DriveのFile情報
 */
export type GoogleDriveFile = ModelBase & {
  kind: string;
  name: string;
  parents?: string[];
  icon_link?: string;
  thumbnail_link?: string;
  web_content_link?: string;
  mime_type?: string;
  size?: string;
};

/**
 * GoogleDriveOauth2Url
 */
export interface GoogleDriveOauth2Url {
  google_oauth2_url: string;
}

/**
 * GoogleDriveFormat
 */
export interface GoogleDriveFormat {
  content: string;
}

/**
 * GoogleDriveConnection
 */
export interface GoogleDriveConnection {
  has_token: boolean;
}

/**
 * One DriveのFileList情報
 */
export interface OneDriveFileList {
  "@odata.context": string;
  "@odata.count": number;
  "@odata.nextLink"?: string;
  value: OneDriveFile[];
}

/**
* - One DriveのFile情報
- sizeの単位はByte
*/
export type OneDriveFile = ModelBase & {
  "@microsoft.graph.downloadUrl"?: string;
  name: string;
  size: number;
  lastModifiedDateTime: string;
  webUrl: string;
  folder?: OneDriveFolderDetail;
  file?: OneDriveFileDetail;
  ParentReference?: ParentReference;
};

export type ParentReference = ModelBase & { driveId: string; driveType: string; path: string };

export interface OneDriveFileDetail {
  mimeType: string;
  hashes: { quickXorHash?: string; sha1Hash?: string; sha256Hash?: string };
}

export interface OneDriveFolderDetail {
  childCount: number;
  view: { viewType?: string; sortBy?: string; sortOrder?: string };
}

/**
 * MicrosoftOauth2Url
 */
export interface MicrosoftOauth2Url {
  microsoft_oauth2_url: string;
}

/**
 * OneDriveFormat
 */
export interface OneDriveFormat {
  content: string;
}

/**
 * OneDriveConnection
 */
export interface OneDriveConnection {
  has_token: boolean;
}

export interface MicrosoftLoginBase {
  access_token?: string;
  microsoft_oauth2_url?: string;
}

export enum TtGoogleDriveSort {
  CreatedTime = "createdTime",
  Folder = "folder",
  ModifiedByMeTime = "modifiedByMeTime",
  ModifiedTime = "modifiedTime",
  Name = "name",
  Recency = "recency",
}

/**
 * app/views/homework_template_custom_field/_base.json.jbuilder
 */
export type HomeworkTemplateCustomField = ModelBase & {
  homework_templates_id: string;
  type: HomeworkTemplateCustomFieldsTypes;
  sequence: number;
  title: string;
  possible_values: string[];
  min_length: number | null;
  max_length: number | null;
  is_required: boolean;
  created_at: string;
  updated_at: string;
  score?: CustomFieldScoreBase;
  custom_master_map?: CustomFieldCustomMasterMap;
  custom_field_category?: TtCategoryBase;
};

/**
 * homework_template_custom_fields_and_value
 */
export type HomeworkTemplateCustomFieldAndValue = HomeworkTemplateCustomField & {
  custom_field_feedbacks: HomeworkTemplateCustomFieldFeedback[];
  homework_template_custom_values: StudentHomeworkTemplateCustomValue[];
  homework_template_custom_value_score?: { score?: number | null };
};

/**
 * app/views/homework_template_custom_field/_custom_master_map.json.jbuilder
 */
export interface CustomFieldScoreBase {
  possible_scores: number[] | null;
  possible_scores2: number[] | null;
  answer_flags?: boolean[] | null;
  is_exact_match: boolean | null;
}

/**
 * app/views/homework_template_custom_field/_custom_master_map.json.jbuilder
 */
export type CustomFieldCustomMasterMap = ModelBase & {
  name: string;
  public_columns: HasNumberIdAndName[];
  filter_columns: HasNumberIdAndName[];
  filter_cmmrs: HasIdAndName[];
};

/**
 * app/views/homework_template_custom_value/_base.json.jbuilder
 */
export type StudentHomeworkTemplateCustomValue = ModelBase & {
  homework_template_custom_fields_id: string;
  student_id: string;
  profile_values?: {
    user_last_name?: string | null;
    user_first_name?: string | null;
    email?: string | null;
    number?: number | null;
    birth_date?: string | null;
    grade_value?: StudentGrade | null;
    grade?: string | null;
    phone?: string | null;
    parent_last_name?: string | null;
    parent_first_name?: string | null;
    postcode?: string | null;
    prefecture?: string | null;
    address1?: string | null;
    address2?: string | null;
  };
  value?: string | null;
  created_at: string;
  updated_at: string;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  custom_master?: CustomMasterPublics;
  wysiwyg_body?: string | null;
};

/**
 * スケジュールBatch用
 */
export type ScheduleBatchBase = ModelBase;

/**
 * 配信履歴と進捗情報の一覧を返却
 */
export type DistributionStatusList = ModelBase & {
  start_time?: string;
  end_time?: string;
  status: DistributionStatusStatusJa;
  sender_affiliate: AffiliateName;
  categorizable: DistributionStatusCategorizable;
  companies?: HasIdAndName[];
  distributors?: (AffiliateStudentName | GroupAffiliatePackageMinimalBase)[];
};

export interface DistributionStatusCategorizable {
  categorizable_id: string;
  categorizable_type: string;
  categorizable_title: string;
}

export enum DistributionStatusStatus {
  Idle = "idle",
  SaveRunning = "save_running",
  SaveFailed = "save_failed",
  DistributionIdle = "distribution_idle",
  DistributionRunning = "distribution_running",
  DistributionFailed = "distribution_failed",
  Completed = "completed",
}

export enum DistributionStatusStatusJa {
  idle = "待機中",
  save_running = "準備中",
  save_failed = "失敗",
  distribution_idle = "配信前",
  distribution_running = "配信中",
  distribution_failed = "失敗",
  completed = "配信完了",
}

/**
 * 生徒の進捗の基本情報
 */
export type LearningProgressBase = ModelBase & {
  categorizable_type?: LearningProgressCategorizableType;
  categorizable_id?: string;
  status?: "新規" | "確認済み";
  sl_lecture_id?: number | null;
};

export enum HomeworkTemplateCustomFieldsTypes {
  TextField = "HomeworkTemplateCustomTextField",
  RadioField = "HomeworkTemplateCustomRadioField",
  SelectField = "HomeworkTemplateCustomSelectField",
  ProfileField = "HomeworkTemplateCustomProfileField",
}

export enum HomeworkTemplateCustomFieldsTypesJa {
  TextField = "記述",
  RadioField = "単一選択",
  SelectField = "複数選択",
  プロフィール = "プロフィール",
}

/**
 * FeedbackTemplate::Homeworkの基本情報
 */
export type FeedbackTemplateHomeworkBase = ModelBase & {
  icon: { title?: string; url?: string };
  feedback_template_comments: FeedbackTemplateCommentBase[];
  kind: FeedbackTemplateKind;
  tags?: HasIdAndName[] | null;
};

export type CommentImageBase = ModelBase & {
  categorizable_id?: string;
  categorizable_type?: CommentImageCategorizableType;
  file_name?: string;
  file_url?: string;
};

/**
* - Homework: 先生の提出物コメント
- CategorizableComment: 汎用コメント
*/
export enum CommentImageCategorizableType {
  Homework = "Homework",
  CategorizableComment = "CategorizableComment",
}

/**
 * simple/list
 */
export type InquirySimpleListBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  created_at: string;
  updated_at: string;
  comments: InquiryCommentCount;
};

/**
 * simple/base
 */
export type InquirySimpleBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  body: string;
  category?: string | null;
  os_name: string;
  os_version: string;
  browser_name: string;
  browser_version: string;
  created_at: string;
  updated_at: string;
  comments: InquiryComment[];
  target_affiliates: AffiliateCompanyAndUserBase[];
};

/**
 * app/views/inquiry/_detail_list_base.json.jbuilder
 */
export type InquiryDetailListBase = ModelBase & {
  user_id: string;
  user?: InquiryAffiliateBase;
  person_in_charge?: InquiryAffiliateBase;
  status: string;
  kind: string;
  title: string;
  created_at: string;
  updated_at: string;
  is_new: boolean;
  comments: InquiryCommentCount;
};

/**
 * app/views/inquiry/_detail_for_super_owner.json.jbuilder
 */
export type InquiryDetailForSuperOwner = InquirySimpleBase & {
  person_in_charge: InquiryAffiliateBase;
  status: string;
  is_new: boolean;
};

/**
* - inquiry/affiliate_base
- user_name: full_name of the user
*/
export interface InquiryAffiliateBase {
  affiliate_id?: string | null;
  has_deleted: boolean;
  company_id?: string | null;
  company_name?: string | null;
  user_id?: string | null;
  user_name?: string | null;
  email?: string | null;
  role?: RoleBase | null;
}

/**
 * Inquiryに紐づいているコメントの件数とコメントの新着の判定を返却
 */
export interface InquiryCommentCount {
  count: number;
  is_new: boolean;
}

/**
 * InquiryのCommentの詳細
 */
export type InquiryComment = ModelBase & {
  user: { affiliate_id?: string; company_id?: string; company_name?: string; user_id?: string; user_name?: string };
  status: string;
  body: string;
  created_at: string;
  updated_at: string;
};

export enum SponsorProjectWorkProgress {
  Viewing = "viewing",
  Viewed = "viewed",
}

export enum SponsorProjectWorkProgressJa {
  viewing = "受講中",
  viewed = "受講済み",
}

/**
* - Article : 探究成果
- Common::Inquiry : お問い合わせ
- Homework : 提出物
- HomeworkTemplateDistribution : 提出物配信
- LearningTemplatePackage : プログラム一覧
- Material : 教材
- MaterialWork : 受講中の教材
- MaterialDistributionHistoryGroup : 教材配信履歴
- Pbl::Report::Answer : 探究レポート​
- User : ユーザー
- Gogo::Request : 探究GoGo
*/
export enum NotificationResourceInfoType {
  Article = "Article",
  CommonInquiry = "Common::Inquiry",
  Homework = "Homework",
  HomeworkTemplateDistribution = "HomeworkTemplateDistribution",
  LearningTemplatePackage = "LearningTemplatePackage",
  Material = "Material",
  MaterialWork = "MaterialWork",
  MaterialDistributionHistoryGroup = "MaterialDistributionHistoryGroup",
  PblReportAnswer = "Pbl::Report::Answer",
  User = "User",
  GogoRequest = "Gogo::Request",
}

/**
 * 大学の基本情報
 */
export type UniversityBase = ModelBase & { company: HasIdAndName };

/**
 * 大学連携の基本情報
 */
export type UniversityCooperationBase = ModelBase & {
  university: UniversityBase;
  company: CompanyUniversityCooperationBase;
};

/**
* - company_name : 学校名
- professor_name : 大学教員名
- article_title : 探究成果のタイトル
- student_name : 生徒名
*/
export enum InterviewRequestSearchType {
  CompanyName = "company_name",
  ProfessorName = "professor_name",
  ArticleTitle = "article_title",
  StudentName = "student_name",
}

export enum InterviewRequestStatus {
  idle = "idle",
  non_approved = "non_approved",
  approved = "approved",
  completed = "completed",
  invalided = "invalided",
}

export enum InterviewRequestStatusJa {
  idle = "承認待ち",
  non_approved = "非承認",
  approved = "進行中",
  completed = "完了",
  invalided = "無効",
}

/**
* - accept : 進行中に変更
- reject : 非承認に変更
- complete : 完了に変更
*/
export enum InterviewRequestEvent {
  Accept = "accept",
  Reject = "reject",
  Complete = "complete",
}

/**
* - person : 個人
- info : システム
*/
export enum InterviewMessageKind {
  Person = "person",
  Info = "info",
}

/**
 * 高大連携リクエストの一覧情報
 */
export type InterviewRequestListBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  confirm_date: string | null;
  has_preferred_dates: boolean;
  has_feedback_dates: boolean;
  professor_affiliate: ProfessorAffiliateListBase;
  article: ArticleInterviewRequestBase;
};

/**
 * 高大連携リクエストの一覧情報
 */
export type InterviewRequestDetailBase = InterviewRequestListBase & {
  student_message: string | null;
  professor_message: string | null;
  is_online: boolean;
  place: string | null;
  preferred_dates?: PreferredDateBase[];
  unread_message_ids?: string[];
};

/**
 * 探究成果の詳細ページ用
 */
export type InterviewRequestArticleBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  professor_affiliate: ProfessorInfoBase;
};

/**
 * 高大連携リクエストの希望日情報
 */
export type PreferredDateBase = ModelBase & { choice_number: number; date: string };

/**
 * 大学教員の一覧情報
 */
export type ProfessorInfoBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    request_count?: number;
    position?: string | null;
    department?: string | null;
    description?: string | null;
    limit_request?: number | null;
    profile_picture?: string | null;
  };
};

/**
 * リクエスト一覧の大学教員覧情報
 */
export type ProfessorAffiliateListBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: { position?: string | null; department?: string | null; description?: string | null };
};

/**
 * 高大連携リクエストの一覧情報
 */
export type InterviewMessageListBase = ModelBase & {
  message: string;
  kind: InterviewMessageKind;
  created_at: string;
  updated_at: string;
  sender_affiliate: AffiliateInfoBase;
  attached_images?: AttachedImageBase[];
};

/**
 * 高大連携リクエストのアンケート基本情報
 */
export type InterviewFeedbackBase = ModelBase & {
  created_at: string;
  updated_at: string;
  student_affiliate: AffiliateInfoBase;
  is_request_send_material?: boolean;
  description?: string;
  degree_of_interest?: number;
  degree_of_learning?: number;
};

/**
 * 大学教職員の一覧リスト
 */
export interface TeacherListForUniversityTeacher {
  professor_info: ProfessorInfoBase;
}

/**
 * homework_template_custom_value_feedback/_base.json.jbuilder
 */
export type HomeworkTemplateCustomFieldFeedback = ModelBase & {
  body: string;
  commenter_affiliate: AffiliateInfoBase;
  created_at: string;
  feedback_image: AttachedImageBase[];
  wysiwyg_body: string | null;
};

export interface IcalendarBase {
  /** .icsファイルの情報を出力 */
  icalendar_data: string;
}

/**
* - anyone: 全員閲覧可能
- draft: 下書き
- teacher_only: 先生のみ
- reviewer_only: 添削者のみ
*/
export enum FeedbackTemplateKind {
  Anyone = "anyone",
  Draft = "draft",
  TeacherOnly = "teacher_only",
  ReviewerOnly = "reviewer_only",
}

/**
 * - article_poster : ポスター
 */
export enum HelpMasterKind {
  ArticlePoster = "article_poster",
}

/**
* - text : 記述
- radio : 単一選択
- select : 複数選択
- profile : プロフィール
*/
export enum HomeworkTemplateCustomFieldType {
  Text = "text",
  Radio = "radio",
  Select = "select",
  Profile = "profile",
}

/**
 * 生徒が学習した宿題やテストを先生に提出するための関連データを格納する。
 */
export type HelpContentBase = ModelBase & { help_no: number; material?: HasIdAndTitle };

/**
 * 生徒が学習した宿題やテストを先生に提出するための関連データを格納する。
 */
export type HelpMasterBase = ModelBase & { kind: HelpMasterKind };

export enum UserAccountType {
  Email = "email",
  AccountId = "account_id",
}

/**
* - status : ステータス
- updated_at : 更新日
- created_at : 作成日
*/
export enum ArticleOrderColumn {
  Status = "status",
  UpdatedAt = "updated_at",
  CreatedAt = "created_at",
}

/**
* - asc : 昇順
- desc : 降順
*/
export enum ArticleOrderType {
  Asc = "asc",
  Desc = "desc",
}

/**
 * - all : 学校に所属している全ての、ステータスが『完了』となっている探究成果を取得。
 */
export enum ArticleGetType {
  All = "all",
}

/**
* - title : 提出物タイトル
- company : 会社名
*/
export enum HomeworkTemplateDistributionType {
  Title = "title",
  Company = "company",
}

/**
* - name : ユーザー名
- company : 会社名
*/
export enum TeacherSearchType {
  Name = "name",
  Company = "company",
}

/**
 * スタンプの種類
 */
export enum StampType {
  Good = "good",
  Nice = "nice",
  Great = "great",
  Surprised = "surprised",
}

/**
 * - Pbl::Cards::InformationLiteracy::Bookmark : 調査メモ​​
 */
export enum StampableType {
  PblCardsInformationLiteracyBookmark = "Pbl::Cards::InformationLiteracy::Bookmark",
}

/**
 * - stamp/_stampable_stamp_fields.json.jbuilder
 */
export interface StampableStampFields {
  stamp_total_count: number;
  own_stamp_fields: StampBase[];
  kinds: { good_counts?: number; nice_counts?: number; great_counts?: number; surprised_counts?: number };
}

/**
 * - Homework :  提出物
 */
export enum CategorizableCommentCategorizableType {
  Homework = "Homework",
}

export type CategorizableCommentBase = ModelBase & {
  categorizable_type: CategorizableCommentCategorizableType;
  categorizable_id: string;
  body: string;
  created_at: string;
  affiliate: AffiliateInfoBase;
  images: CommentImageBase[] | null;
};

export enum TtCategoryBaseType {
  TtCategoryHomeworkTemplateCustomField = "TtCategory::HomeworkTemplateCustomField",
}

/**
* - Article : 探究成果
- Homework : 提出物
- MaterialWork : 受講中の教材
- LearningPackage : プログラム
- Pbl::Report::Answer : 探究レポート
- Ideal::Board : あいである
- Pbl::Cards::InformationLiteracy::Bookmark : ブックマーク
*/
export enum StudentLogCategorizableType {
  Article = "Article",
  Homework = "Homework",
  MaterialWork = "MaterialWork",
  LearningPackage = "LearningPackage",
  PblReportAnswer = "Pbl::Report::Answer",
  IdealBoard = "Ideal::Board",
  PblCardsInformationLiteracyBookmark = "Pbl::Cards::InformationLiteracy::Bookmark",
}

/**
 * - tag : タグ名
 */
export enum FeedbackTemplateSearchType {
  Tag = "tag",
}

export interface ArticleAffiliateLinking {
  affiliate_id: string;
  user?: {
    user_id?: string;
    first_name?: string;
    last_name?: string;
    student_number?: number | null;
    nickname?: string | null;
  };
}

/**
* - draft :  下書き
- approved :  承認済み
*/
export enum ApprovingStatus {
  Draft = "draft",
  Approved = "approved",
}

/**
 * - Homework :  提出物
 */
export enum ApprovingCategorizableType {
  Homework = "Homework",
}

/**
* - submit : 承認済みに変更
- cancel : 下書きに変更
*/
export enum ApprovingEvent {
  Submit = "submit",
  Cancel = "cancel",
}

export type ApprovingBase = ModelBase & { status: ApprovingStatus; affiliate: AffiliateName };

/**
* - user_name : ユーザ名
- email : メールアドレス
- grade : 学年
- parent_name : 保護者名
- user_tag : ユーザタグ
*/
export enum TtCsvExportSearchType {
  UserName = "user_name",
  Email = "email",
  Grade = "grade",
  ParentName = "parent_name",
  UserTag = "user_tag",
}

/**
* - numberAsc : numberからソート（昇順）する
- numberDesc : numberからソート（降順）する
*/
export enum TtCsvExportSearchTypeSortedBy {
  NumberAsc = "numberAsc",
  NumberDesc = "numberDesc",
}

export interface PostGroupAffiliatePackage {
  title: string;
  description?: string;

  /**
   * - 半角英数字のみ
   * - 20文字以内
   */
  code?: string;

  /** AffiliateのID配列 */
  add_assign_teacher_affiliate_ids?: string[];

  /**
   * - 1（AffiliateのIDを使って生徒を追加)
   * - 2:(UserTagのIDを使って生徒を追加)
   */
  add_type?: number;

  /**
   * - add_typeが1の時: AffiliateのIDを指定する
   * - add_typeが2の時: UserTagのIDを指定する
   */
  assign_ids?: string[];
}

export interface PutGroupAffiliatePackage {
  title: string;
  description?: string;

  /**
   * - 半角英数字のみ
   * - 20文字以内
   */
  code?: string;
  sequence?: number;

  /** AffiliateのID配列 */
  add_assign_teacher_affiliate_ids?: string[];

  /** AffiliateのID配列 */
  remove_assign_teacher_affiliate_ids?: string[];
}

/**
* - draft : 下書き
- active : 公開
*/
export enum PblPlanItemStatus {
  Draft = "draft",
  Active = "active",
}

export enum UserTagTypeRoleType {
  Student = "student",
  Teacher = "teacher",
}

export interface GradeMasterBaseAsAdmin {
  grades?: { id?: string; grade?: string; grade_name?: string; graduation_year?: string; company_id?: string }[];
}

export interface PblSteamContentRequestBody {
  difficulty?: string;
  title: string;
  is_scientific?: boolean;
  sl_content_id: number;
  aspect?: string;
  has_carousel?: boolean;
}

export interface PutSponsorCategoryRequestBody {
  name: string;
  description?: string;
  file?: File;
}

export type PostSponsorCategoryRequestBody = PutSponsorCategoryRequestBody & { sponsor_id: string };

export type PostSponsorProjectCategoryRequestBody = PutSponsorCategoryRequestBody & { project_id: string };

export interface PutSponsorInfoRequestBody {
  company_id: string | number;
  description?: string;

  /** 公開フラグ */
  is_public: string;
  sales_channel?: SponsorSalesChannel;
  tags?: object[];
  image_contents?: File;
  video_file?: File;
  external_link?: string;

  /** 何も渡さない場合、更新されない。deletedを渡した場合、画像が削除される。 */
  sponsor_logo?: File;
}

export type PostSponsorInfoRequestBody = PutSponsorInfoRequestBody & {
  categories?: { name: string; description?: string; file?: File }[];
};

export interface SponsorInfoSchoolIdRequestBody {
  /** 学校のID */
  school_id: string;
}

/**
 * adminが学校紐付けを作成時に使用する要素
 */
export interface SponsorInfoLinkingBulkInsertParams {
  sponsor_info_ids: string[];
  school_ids: string[];
}

export type SponsorLectureFileRequestBody = FileRequiredRequestBody & { lecture_id: string };

export interface SponsorLectureSequencesRequestBody {
  sequences: { pbl_sponsor_lecture_id: string; sequence: number }[];
}

export interface SponsorLectureRequestBody {
  company_id: string;
  project_id: string;
  name: string;
  description?: string;
  tags?: string[];
  image_contents?: File;
  video_file?: File;
  external_link?: string;
}

export type PutSponsorLectureRequestBody = SponsorLectureRequestBody & object;

export type PostSponsorLectureRequestBody = SponsorLectureRequestBody & { files?: File[] };

export interface SponsorProjectSequencesRequestBody {
  sequences: { pbl_sponsor_project_id: string; sequence: number }[];
}

export interface SponsorProjectRequestBody {
  company_id: string;
  name: string;
  description?: string;
  file?: File;
  tags?: string[];
  image_contents?: File;
  video_file?: File;
  external_link?: string;
  display_allowed: boolean;
}

export type PutSponsorProjectRequestBody = SponsorProjectRequestBody & {
  add_professor_affiliate_ids?: string[];
  remove_professor_affiliate_ids?: string[];
};

export type PostSponsorProjectRequestBody = SponsorProjectRequestBody & {
  categories?: { name: string; description?: string; file?: File }[];
};

export interface TtCsvExportRequestBody {
  type: TtCsvExportType;
  target_company_id?: string;
  homework_template_id?: string;
  learning_template_package_distribution_setting_id?: string;
  common_custom_master_map_id?: string;
}

export interface AuthorizationPasswordRequestBody {
  password: string;
  affiliate_id?: string;
}

export interface CategoryRenameMapRequestBody {
  for_text?: string;
  for_subject?: string;
  for_grade?: string;
  for_major?: string;
  for_middle?: string;
  for_minor?: string;
}

export type CategoryRenameMapRequestBodyAsAdmin = CategoryRenameMapRequestBody & { company_id: string };

export interface CompanyRequestBody {
  name?: string;
  postcode?: string;
  prefecture?: string;
  adress1?: string;
  adress2?: string;
  phone?: string;
  logo?: string;
  options?: Option;
  email?: string;
}

export type CompanyRequestBodyAsAdmin = CompanyRequestBody & {
  is_reviewer?: boolean;
  parent_company_id?: string;
  university?: boolean;
};

export interface CompanyIdsRequestBody {
  company_ids: string[];
}

export interface TargetCompanyIdsRequestBody {
  target_company_ids: string[];
}

export type PutCompanyRequestBodyAsSuperOwner = CompanyRequestBody & {
  report_recipient_email?: string;
  report_the_number_of_users_email?: string;
  report_homework_template_distribution_histories_email?: string;
  inquiry_email?: string;
  student_input_settings?: StudentInputSetting;
  unique_id?: string;
  limited_user_tag_monthly_report_email?: string;
  reviewer_company_group_id?: string;
};

export type PostCompanyRequestBodyAsSuperOwner = CompanyRequestBody & {
  is_reviewer?: boolean;
  unique_id?: string;
  reviewer_company_group_id?: string;
};

export interface RevieweeCompaniesRequestBodyAsSuperOwner {
  company_ids: string[];
  reviewer_company_group_id: string;
}

export interface ReviewerCompanyGroupRequestBodyAsSuperOwner {
  /** ReviewerCompanyGroupのタイトル */
  name: string;
  reviewer_company_ids?: string[];
}

export interface CreateAccountUserRequestBodyAsTeacher {
  account_id?: string;
  year: number;
  last_name: string;
  first_name: string;

  /** アカウントID使用Userのみ設定可能 */
  optional_email?: string | null;
  password: string;
  user_params: {
    grade?: string;
    parent_last_name?: string;
    parent_first_name?: string;
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    birth_date?: string;
    number?: number;
  };

  /**
   * - 生徒のみに設定する
   * - ユーザタグ、限定タグ
   */
  user_tag_ids?: string[] | null;

  /**
   * - 生徒のみに設定する
   * - グループ
   */
  group_ids?: string[] | null;

  /**
   * - アカウントIDが存在しないとき必須
   * - グループの code をセットする
   */
  group_code?: string | null;
}

export interface IconRequiredRequestBody {
  icon: File;
}

export type FeedbackTemplateRequestBodyAsAdmin = IconRequiredRequestBody & { comments: { body: string }[] };

export interface GraduationYearRequiredRequestBody {
  graduation_year: string;
}

export type GradeAndGraduationYearRequiredRequestBody = GraduationYearRequiredRequestBody & { grade: number };

export interface PutIpWhitelistsRequestBody {
  ip_address: string;

  /** ロール名 */
  role: string;
}

export type PostIpWhitelistsRequestBody = PutIpWhitelistsRequestBody & { company_id: string };

export interface NotificationRequestBody {
  title?: string;
  message?: string;
}

export type NotificationRequestBodyAsAdmin = NotificationRequestBody & {
  target_company_ids: string[];
  target_roles: string[];
};

export type NotificationRequestBodyAsSuperOwner = NotificationRequestBodyAsAdmin & {
  target_user_tag_ids?: string[];
  target_company_tag_ids?: string[];
  publication_time?: string;
  with_email?: boolean;
};

export interface IdRequiredRequestBody {
  id: string;
}

export interface IdsRequiredRequestBody {
  ids: string[];
}

export interface FileRequiredRequestBody {
  file: File;
}

export interface NameRequestBody {
  name?: string;
}

export interface NameRequiredRequestBody {
  name: string;
}

export interface BodyRequestBody {
  body?: string;
}

export interface BodyRequiredRequestBody {
  body: string;
}

export interface FilesRequestBody {
  files?: File[];
}

export interface CommentImagesRequestBody {
  comment_images?: File[];
}

export interface ReviewerCompanyReviewingRequiredRequestBody {
  reviewer_company_id: string;
  reviewee_company_id: string;
}

export interface ReviewerCompanyReviewingRequestBodyAsAdmin {
  /** ReviewerCompanyGroupのタイトル */
  name: string;

  /** ReviewerCompanyGroupのプロフィール情報設定 */
  is_public_profile?: boolean;
}

export interface UserRegistrationRequestBodyAsAdmin {
  email: string;
  company_id: string;
  first_name: string;
  last_name: string;
}

export interface AdminRegistrationRequestBody {
  email: string;
  first_name: string;
  last_name: string;
}

export interface UserNicknameRequestBody {
  nickname?: string;
}

export interface UserInviteUsersRequestBodyAsCommon {
  /** 再招待したいUserのemailアドレス */
  email: string;

  /** 再招待したいUserのcompany_id, owner app以外では指定が必要 */
  company_id?: string;
}

export interface UserNameRequestBody {
  first_name?: string;
  last_name?: string;
}

export type UserNameAndEmailRequestBody = UserNameRequestBody & { email?: string };

export type UserReviewersRequestBody = UserNameAndEmailRequestBody & {
  nickname?: string;
  reviewer_info_attributes?: ReviewerInfoAttributes;
};

export type UserReviewerRequestBody = UserNameRequestBody & {
  nickname?: string;
  reviewer_info_attributes?: ReviewerInfoAttributes;
};

export type UserReviewersRequestBodyAsReviewer = UserNameAndEmailRequestBody & { nickname?: string };

export type UserReviewerRequestBodyAsReviewer = UserNameRequestBody & { nickname?: string };

export type PutUserRequestBodyAsSuperOwner = UserNameRequestBody & {
  optional_email?: string | null;
  user_type?: UserAccountType & any;
  new_id?: string;
  authorizations?: UserAuthorization;
  student_info_attributes?: StudentInfoAttributes;
  teacher_info_attributes?: TeacherInfoAttributes;
  user_tag_ids?: string[] | null;
  group_ids?: string[] | null;
  role?: "teacher" | "owner";
};

export type PostUserRequestBodyAsSuperOwner = UserNameAndEmailRequestBody & {
  nickname?: string;
  role?: string;
  optional_email?: string;
  authorizations?: UserAuthorization;
  student_info_attributes?: StudentInfoAttributes;
  teacher_info_attributes?: TeacherInfoAttributes;
  invitation_scheduled_at?: string;
  user_tag_ids?: string[] | null;
  group_ids?: string[] | null;
};

export interface ApprovingRequestBody {
  categorizable_type: ApprovingCategorizableType;
  categorizable_id: string;
  event?: ApprovingEvent;
}

export interface PutHomeworkRequestBodyAsReviewer {
  reviewer_comment?: string;
  reviewer_comment_for_teacher?: string;
  score?: number;

  /**
   * - 以下のステータスのみを指定可能
   * - checked_reviewer: 添削前または添削中から添削済みに変更
   * - assigned_reviewer: 添削済みから添削中に変更
   * - submitted: 添削前から提出済みに変更
   */
  status?: HomeworkStatus;
}

export interface HomeworkCountStatusesRequestBodyAsReviewer {
  distribution_id: string;
  reviewer_affiliate_ids: string[];
}

export interface HomeworkAssignRequestBodyAsReviewer {
  /** 担当者を追加 */
  add_reviewer_affiliate_ids?: string[];

  /** 担当者を削除 */
  remove_reviewer_affiliate_ids?: string[];
}

export interface HomeworkChunkReviewerFileRequiredRequestBody {
  reviewer_file: File;
}

export interface HomeworkTemplateChunkDistributionReviewerAffiliateIdRequiredRequestBody {
  reviewer_affiliate_id: string | null;
}

export interface HomeworkTemplateChunkDistributionEventRequiredRequestBody {
  event: HomeworkTemplateChunkDistributionEvent;
}

export interface HomeworkTemplateDistributionImageContentIdRequiredRequestBody {
  image_content_id: string;
}

export interface HomeworkTemplateDistributionToSchoolAsReviewerRequestBody {
  comment: string | null;
  return_at: string;
  image_contents?: File[];
}

export interface HomeworkTemplateDistributionCommentRequestBody {
  comment?: string | null;
}

export interface PutHomeworkTemplateDistributionRequestBodyAsReviewer {
  comment?: string;
  return_at?: string | null;
  image_contents?: File[];
}

export interface BatchCreateHomeworkTemplateDistributionRequestBodyAsSuperOwner {
  start_at?: string;
  end_at?: string;
  reviewer_end_at?: string;
  image_contents?: File[];
  create_params_list: { company_id: string; max_distribution_end_users: number | null; memo?: string | null }[];
}

export interface BatchDeleteHomeworkTemplateDistributionRequestBodyAsSuperOwner {
  deleted_ids: string[];
  errors: string[];
}

export interface PutHomeworkTemplateDistributionRequestBodyAsSuperOwner {
  max_distribution_end_users?: string;
  memo?: string;
  start_at?: string;
  end_at?: string;
  reviewer_end_at?: string;
}

export interface InquiryCommentRequestBody {
  body: string;

  /**
   * - not_viewed: 未確認
   * - viewed: 確認済み
   */
  status?: string;
}

export interface InquiryIsNewRequiredRequestBody {
  is_new: boolean;
}

export interface PostInquiryRequestBodyAsCommon {
  title: string;
  category?: string;
  body: string;
  os_name?: string;
  os_version?: string;
  browser_name?: string;
  browser_version?: string;

  /**
   * - system: システムに関して
   * - user_support: ユーザサポート
   * - content: コンテンツに関して
   * - other: その他
   */
  kind: string;

  /** 追加する Affiliate のIDの配列 */
  add_affiliate_ids?: string[];
}

export interface PutInquiryRequestBodyAsCommon {
  /** 担当者（user_id） */
  person_in_charge_id?: string;

  /**
   * - unsolved: 未処理
   * - answered: 回答済み
   * - replied: 返答あり
   * - in_progress: 対応中
   * - completed: 完了
   */
  status?: string;

  /** 追加する Affiliate のIDの配列 */
  add_affiliate_ids?: string[];

  /** 削除する Affiliate のIDの配列 */
  remove_affiliate_ids?: string[];
}

export enum PblPlanDayType {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export interface AffiliateBaseAsCommon {
  affiliates?: {
    id?: string;
    company_id?: string;
    company_name?: string;
    is_enterprise?: boolean;
    status?: "利用中" | "期限切れ" | "登録待ち" | "ロック中" | "準備中" | "未承認" | "利用停止";
    role_id?: string;
    role?: string;
  }[];
  user?: BasicUserInfo;
}

export interface PutScheduleRequestBodyAsCommon {
  title?: string;
  start_time?: string;
  end_time?: string;
  place?: string;
  private_flag?: boolean;
  all_day?: boolean;
  memo?: string;
  attendee_ids?: string[];
}

export type PostScheduleRequestBodyAsCommon = PutScheduleRequestBodyAsCommon & object;

export interface BatchUpdateScheduleRequestBodyAsCommon {
  /** スケジュール作成者のAffiliate ID */
  host_affiliate_id: string;

  /** 参加者のAffiliate ID */
  attendee_affiliate_id: string;

  /** 参加者を外すためのフラグ */
  delete_attendee_flag: boolean;

  /** 説明 */
  schedule_params_list: {
    id: string;
    start_time?: string;
    end_time?: string;
    title?: string;
    place?: string;
    private_flag?: boolean;
    all_day?: boolean;
    memo?: string;
    plan_class_id?: string;
  }[];
}

export interface BatchCreateScheduleRequestBodyAsCommon {
  /** スケジュール作成者のAffiliate ID */
  host_affiliate_id: string;

  /** 参加者のAffiliate ID */
  attendee_affiliate_id: string;

  /** 説明 */
  schedule_params_list: {
    start_time: string;
    end_time: string;
    title: string;
    place?: string;
    private_flag: boolean;
    all_day: boolean;
    memo?: string;
    plan_class_id?: string;
  }[];
}

export interface SponsorPageViewsRequestBody {
  object_id?: string;
  layer?: SponsorPageLayer;
}

export interface PutCustomFieldFeedbackRequestBody {
  /** コメント内容 */
  body: string;

  /** WYSIWYGのコード */
  wysiwyg_body?: string;
  homework_id: string;
}

export interface TitleRequestBody {
  title?: string;
}

export interface TitleAndDescriptionRequiredRequestBody {
  title: string;
  description: string;
}

export interface TagNameRequiredRequestBody {
  tag_name: string;
}

export interface StartAtAndEndAtRequestBody {
  start_at?: string;
  end_at?: string;
}

export interface SequenceRequiredRequestBody {
  sequence: number;
}

export interface ZipFileRequiredRequestBody {
  zip_file: File;
}

export interface PutSponsorArticleContentTemplateFieldAsAdminRequestBody {
  title: string;
  description?: string | null;
}

export interface PostSponsorArticleContentTemplateRequestBody {
  title: string;
  description?: string | null;
  field_texts?: string[] | null;
}

export type GogoLinkingBase = ModelBase & { company: CompanyLinkingListBase };

export type GogoRequestListBase = ModelBase & {
  request_type: "出張授業" | "フィールドワーク" | "オンライン授業";
  request_type_code: any;
  status: "調整待ち" | "調整する" | "確定" | "実施なし";
  status_code: any;
  confirm_start_time: string | null;
  confirm_end_time: string | null;
  preferred_dates: GogoRequestPreferredDateBase[];
  sender_affiliate: AffiliateName;
  company: HasIdAndName;
  theme: string | null;
};

export type GogoRequestDetailBase = GogoRequestListBase & {
  sender_message: string | null;
  company_message: string | null;
  unread_message_ids: string[];
  number_of_classes: number;
  number_of_students: number;
  parking: GogoRequestParking;
  projector: GogoRequestProjector;
  internet_connection: GogoRequestInternetConnection;
  photography: GogoRequestPhotography;
  atmosphere: GogoRequestAtmosphereJa;
  atmosphere_code: GogoRequestAtmosphere;
  hope: GogoRequestHopeJa;
  hope_code: GogoRequestHope;
  learning_environment: GogoRequestLearningEnvironmentJa;
  learning_environment_code: GogoRequestLearningEnvironment;
  grade: StudentGrade;
  teacher_affiliate?: AffiliateName;
};

/**
 * 高大連携未読メッセージの件数情報
 */
export type GogoRequestUnreadCountBase = ModelBase & { count: number };

/**
 * 探究GoGoのリクエストの希望日情報
 */
export type GogoRequestPreferredDateBase = ModelBase & { choice_number: number; start_time: string; end_time: string };

export interface GogoLinkingRequiredRequestBody {
  school_ids: string[];
}

/**
 * 探究GoGoリクエストの一覧情報
 */
export type GogoMessageListBase = ModelBase & {
  message: string;
  kind: GogoMessageKind;
  created_at: string;
  updated_at: string;
  sender_affiliate: AffiliateInfoBase;
  attached_images?: AttachedImageBase[];
};

/**
* - person : 個人
- info : システム
*/
export enum GogoMessageKind {
  Person = "person",
  Info = "info",
}

export interface MessageRequiredRequestBody {
  message: string;
}

export interface PostGogoRequestRequestBody {
  company_id: string;
  request_type: any;
  sender_message?: string;
  preferred_dates: { start_time?: string; end_time?: string }[];
  theme: string;
  number_of_classes?: number;
  number_of_students?: number;
  parking?: GogoRequestParking;
  projector?: GogoRequestProjector;
  internet_connection?: GogoRequestInternetConnection;
  photography?: GogoRequestPhotography;
  atmosphere?: GogoRequestAtmosphere;
  hope?: GogoRequestHope;
  learning_environment?: GogoRequestLearningEnvironment;
  teacher_affiliate_id?: string;
  grade?: StudentGrade;
}

export interface PutGogoRequestRequestBody {
  /**
   * - school_visit : 出張授業
   * - fieldwork : フィールドワーク
   * - online_lesson : オンライン授業
   */
  request_type: "school_visit" | "fieldwork" | "online_lesson";
  sender_message?: string;
}

export interface PutGogoRequestRequestBodyAsContentProvider {
  confirm_start_time?: string;
  confirm_end_time?: string;
  company_message?: string;

  /**
   * - adjust : 調整
   * - decide : 確定
   * - cancel : キャンセル
   */
  event?: "adjust" | "decide" | "cancel";
}

/**
 * - user_tag_id : ユーザータグID
 */
export enum TeacherArticleType {
  UserTagId = "user_tag_id",
}

/**
* - number : 生徒番号
- created_at : 登録日時
*/
export enum ArticleSortedByColumn {
  Number = "number",
  CreatedAt = "created_at",
}

/**
* - number : 生徒番号
- created_at : 登録日時
*/
export enum BookmarkSortedByColumn {
  Number = "number",
  CreatedAt = "created_at",
}

/**
* - Article : 探究成果
- Pbl::Cards::InformationLiteracy::Bookmark : 調査メモ​​
- Homework : 提出物
- HomeworkTemplate : 提出物テンプレート
*/
export enum CommonTaggingCategorizableType {
  Article = "Article",
  PblCardsInformationLiteracyBookmark = "Pbl::Cards::InformationLiteracy::Bookmark",
  Homework = "Homework",
  HomeworkTemplate = "HomeworkTemplate",
}

export enum CommonTagKind {
  System = "system",
  Free = "free",
  Management = "management",
}

export enum PostCommonTagKind {
  Free = "free",
  Management = "management",
}

export type CommonTagBase = ModelBase & { kind: CommonTagKind; name: string };

export type CommonTagManagementTagBase = ModelBase & {
  name: string;
  common_tag_linkings?: CommonTagLinkingBase[] | null;
};

export type CommonTagLinkingBase = ModelBase & { allow_type: CommonTagLinkingAllowType };

export interface PostPblCardBookmarkRequestBody {
  source_type?: PblCardBookmarkSourceType;
  title?: string;
  url?: string;
  memo?: string;
  wysiwyg_body?: string;
  files?: File[];

  /** 追加するGroupAffiliatePackageのIDの配列 */
  add_group_affiliate_ids?: string[];

  /** システムタグの名前 */
  common_tag_names?: string[];
}

export type PutPblCardBookmarkRequestBody = PostPblCardBookmarkRequestBody & { remove_group_affiliate_ids?: string[] };

export interface ArticleSubmitBaseParts {
  id: string;
  name: string;
  article_company_id: string;
}

export enum TeacherContentSearchType {
  Title = "title",
}

export interface AffiliateStudentSuspensionRequiredRequestBody {
  student_suspension: boolean;
}

export interface AffiliateIdRequiredRequestBody {
  affiliate_id: string;
}

export interface CategoryRequestBaseBodyAsSuperOwner {
  name?: string;
  image?: File;
  is_help?: boolean;
  roles?: (string | null)[];
  add_company_tags?: (string | null)[];
  add_user_tags?: (string | null)[];
  student_visible?: boolean;
  teacher_visible?: boolean;
  owner_visible?: boolean;
}

export type PutCategoryRequestBodyAsSuperOwner = CategoryRequestBaseBodyAsSuperOwner & {
  remove_company_tags?: (string | null)[];
  remove_user_tags?: (string | null)[];
};

export type PostCategoryRequestBodyAsSuperOwner = CategoryRequestBaseBodyAsSuperOwner & { parent_id?: string };

export interface PutCategorySequencesRequestBodyAsSuperOwner {
  sequences?: { category_id?: string; sequence?: number }[];
}

export interface PutCategoryRequestBodyAsOwner {
  name: string;
  student_visible?: boolean;
  teacher_visible?: boolean;
  image?: string;
  add_user_tags?: (string | null)[];
  remove_user_tags?: (string | null)[];
}

export interface RoleRequiredRequestBody {
  role: string;
}

export interface CompanyTagTypeSequencesRequestBody {
  sequences?: { company_tag_type_id?: string; sequence?: number }[];
}

export interface CompanyTaggingRequestBody {
  company_id?: string;
  company_tag_id?: string;
}

export interface PostCompanyTagRequestBody {
  company_tag_type_id?: string;
  name: string;
  free?: boolean;
}

export interface CompanyTagSequencesRequestBody {
  sequences?: { company_tag_id?: string; sequence?: number }[];
}

export interface PostCustomMasterMapRelationshipRequestBody {
  /** リレーション名 */
  name: string;

  /** 紐づけるカスタムリストのID */
  child_custom_master_map_id: string;

  /** 設定側のカラム番号 */
  parent_title_for_column_number: number;

  /** 紐づけるカラム番号 */
  child_title_for_column_number: number;
}

export interface BatchCreateCustomMasterMapRelationshipRequestBody {
  custom_master_map_relationship_id: string;
  parent_relation_ids: string[];
  child_relation_ids: string[];
}

export interface PostCustomMasterMapRequiredRequestBody {
  master_name: string;
  used_columns: number;
}

export interface CustomMasterMapSequencesRequestBody {
  sequences?: { custom_master_map_id?: string; sequence?: number }[];
}

export interface PutCustomMasterRequestBody {
  /** リレーションシップの紐付け用 */
  relationships?: { relationship_types_id: string; child_relation_id: string }[];

  /** リレーションシップの紐付け削除用 */
  delete_relationships?: { relationship_types_id: string; child_relation_id: string }[];
}

export interface PostCustomMasterRequestBody {
  custom_master_map_id: string;
  company_id?: string;

  /** リレーションシップの紐付け用 */
  relationships?: { relationship_types_id: string; child_relation_id: string }[];
}

export interface EmailRequestBodyAsSuperOwner {
  title: string;
  body: string;
  target_user_tags?: string[];
  target_companies: string[];
  to_all_students?: boolean;
  to_all_teachers?: boolean;
  to_all_owners?: boolean;
}

export interface PutFeedbackTemplateHomeworkRequestBody {
  /** 画像 */
  icon?: File;
  kind?: FeedbackTemplateKind;
  tag_list?: string[];
}

export type PostFeedbackTemplateHomeworkRequestBody = PutFeedbackTemplateHomeworkRequestBody & {
  comments?: { body: string }[];
};

export interface HelpRequestBodyAsSuperOwner {
  material_id: string;
  kind: HelpMasterKind;
}

export interface PutIdealBoardTemplateRequestBody {
  name?: string;
  data?: string;
  start_at?: string;
}

export interface PostIdealBoardTemplateRequestBody {
  /** 名前 */
  name: string;

  /** ボードのサイズ */
  size: IdealBoardSize;
  start_at?: string;
}

export interface PutIdealBoardRequestBody {
  name?: string;
  data?: string;
  event?: IdealBoardEvent;
}

export type PostIdealBoardRequestBody = { name: string; size: IdealBoardSize } | { ideal_board_template_id: string };

export interface IdealBoardAffiliateRequestBody {
  ideal_board_id: string;
  affiliate_id: string;
}

export interface BatchCreateLearningTemplatePackageDistributionRequiredRequestBody {
  start_at?: string;
  end_at?: string;
  grade?: StudentGrade;
  forbid_download: boolean;
  company_ids?: string[];
  user_tag_ids?: string[];
  student_affiliate_ids: string[];
  homework_template_settings?: {
    homework_template_id?: string;
    company_id?: string;
    start_at?: string;
    end_at?: string;
    reviewer_end_at?: string;
    memo?: string;
  }[];
}

export interface LearningTemplatePackageSequencesRequestBody {
  sequences: { package_id: string; sequence: number }[];
}

export interface PostLimitedTimeNotificationRequestBody {
  /** タイトル */
  title: string;

  /** 本文 */
  message: string;

  /** 開始時間 */
  start_time: string;

  /** 終了時間 */
  end_time: string;
  add_roles: SelectRoleByLimitedTimeNotification[];

  /** 選択したCompanyのHashId */
  add_company_ids?: string[];

  /** 選択したUserTagのHashId */
  add_user_tag_ids?: string[];
}

export type PutLimitedTimeNotificationRequestBody = PostLimitedTimeNotificationRequestBody & {
  remove_role_ids?: string[];
  remove_company_ids?: string[];
  remove_user_tag_ids?: string[];
};

export interface MaterialWorkBaseRequestBody {
  material_id?: string;
  forbid_download?: boolean;
  category_id?: string;
  user_tag_ids?: string[];
  publication_time?: string;
  expiration_time?: string;
  grade?: StudentGrade;
}

export type BatchCreateMaterialWorkRequestBodyAsSuperOwner = MaterialWorkBaseRequestBody & {
  company_tag_ids?: string[];
  user_tag_ids?: string[];
};

export type BatchCreateUsingTagMaterialWorkRequestBodyAsSuperOwner = MaterialWorkBaseRequestBody & {
  company_ids?: string[];
};

export interface PutMaterialsTicketRequestBody {
  hashed_ticket_id: string;
}

export interface PackagingRequestBody {
  /** - パッケージのID */
  package_id: string;
  packageable_type: PackagingPackageableType;
  packageable_id: string;
}

export interface PblBoardCampaignRequestEventRequiredRequestBody {
  event: PblBoardCampaignRequestEvent;
}

export interface PostPblBoardCampaignRequestBody {
  title: string;
  description?: string;
  start_date: string;
  end_date: string;
}

export type PutPblBoardCampaignRequestBody = PostPblBoardCampaignRequestBody & { event: PblBoardCampaignEvent };

export interface PblBoardContentRequestBody {
  design_type?: PblBoardContentDesignType;

  /** キャッチコピー */
  catchphrase?: string;

  /** キャッチコピー概要 */
  catchphrase_description?: string;

  /** キャッチコピー画像 */
  catchphrase_image?: File;

  /** CTAボタン名 */
  cta_name?: string;

  /** CTAのURL */
  cta_url?: string;

  /** 動画のURL */
  external_link?: string;
}

export interface PblBoardFooterItemRequiredRequestBody {
  /** リンク名 */
  title: string;

  /** リンクのURL */
  url: string;
}

export interface PutPblBoardFooterRequestBody {
  /** カテゴリー名 */
  category_name: string;
}

export type PostPblBoardFooterRequestBody = PutPblBoardFooterRequestBody & {
  footer_items?: { title: string; url: string }[];
};

export interface PblBoardInfoEventRequiredRequestBody {
  event: PblBoardInfoEvent;
}

export interface PblBoardItemRequestBody {
  content_type: PblBoardItemContentType;
  sequence?: number;
}

export interface PutUserTagTypeRequestBody {
  name?: string;
  teacher_visible?: boolean;
  role_type?: "student" | "teacher";
}

export type PostUserTagTypeRequestBody = PutUserTagTypeRequestBody & { kind?: UserTagTypeKind };

export interface UserTagTypeSequencesRequestBody {
  sequences?: { user_tag_type_id?: string; sequence?: number }[];
}

/**
* - unpublished : 未配信
- published : 配信済み
*/
export enum UserTagDistributionSettingStatus {
  Unpublished = "unpublished",
  Published = "published",
}

/**
* - Gogo::Request: 探究GoGo 企業リクエスト
- Gogo::ProgramRequest: 探究GoGo プログラムリクエスト
*/
export enum GogoMessageCategorizableType {
  GogoRequest = "Gogo::Request",
  GogoProgramRequest = "Gogo::ProgramRequest",
}

/**
 * - 郵便番号から住所を検索した際の返却値
 */
export type AddressBaseCommon = {
  address: {
    address1?: string;
    address2?: string;
    address3?: string;
    kana1?: string;
    kana2?: string;
    kana3?: string;
    prefcode?: string;
    zipcode?: string;
  };
};

export interface CommonTaggingRequestBody {
  tag_name: string;
  kind: PostCommonTagKind;
}

export interface CommonTagLinkingRequestBody {
  allow_type: CommonTagLinkingAllowType;
}

export interface CommonTagRequestBody {
  name: string;
  allow_types?: CommonTagLinkingAllowType[];
}

/**
* - bookmark : 調査メモ
- article : ​​探究成果
- homework : 提出物
- homework_template : 提出物テンプレート
*/
export enum CommonTagLinkingAllowType {
  Bookmark = "bookmark",
  Article = "article",
  Homework = "homework",
  HomeworkTemplate = "homework_template",
}

export interface GogoCompanyInformationRequestBody {
  available_capacity?: number;
  visiting_lesson_event?: GogoCompanyInformationVisitingLessonEvent;
  online_lesson_event?: GogoCompanyInformationOnlineLessonEvent;
  fieldwork_event?: GogoCompanyInformationFieldworkEvent;
}

export type GogoCompanyInformationBase = ModelBase & {
  visiting_lesson: GogoCompanyInformationVisitingLesson;
  online_lesson: GogoCompanyInformationOnlineLesson;
  fieldwork: GogoCompanyInformationFieldwork;
  available_capacity: number;
  company: CompanyBase & { projects_count?: number | null; project_works_count?: number | null };
};

/**
* - visiting_lesson_available : 対応可
- visiting_lesson_negotiable : 応相談
- visiting_lesson_not_available : 対応不可
*/
export enum GogoCompanyInformationVisitingLesson {
  VisitingLessonAvailable = "visiting_lesson_available",
  VisitingLessonNegotiable = "visiting_lesson_negotiable",
  VisitingLessonNotAvailable = "visiting_lesson_not_available",
}

/**
* - online_lesson_available : 対応可
- online_lesson_negotiable : 応相談
- online_lesson_not_available : 対応不可
*/
export enum GogoCompanyInformationOnlineLesson {
  OnlineLessonAvailable = "online_lesson_available",
  OnlineLessonNegotiable = "online_lesson_negotiable",
  OnlineLessonNotAvailable = "online_lesson_not_available",
}

/**
* - fieldwork_available : 対応可
- fieldwork_negotiable : 応相談
- fieldwork_not_available : 対応不可
*/
export enum GogoCompanyInformationFieldwork {
  FieldworkAvailable = "fieldwork_available",
  FieldworkNegotiable = "fieldwork_negotiable",
  FieldworkNotAvailable = "fieldwork_not_available",
}

/**
* - visiting_lesson_enable : 対応可
- visiting_lesson_consult : 応相談
- visiting_lesson_disable : 対応不可
*/
export enum GogoCompanyInformationVisitingLessonEvent {
  VisitingLessonEnable = "visiting_lesson_enable",
  VisitingLessonConsult = "visiting_lesson_consult",
  VisitingLessonDisable = "visiting_lesson_disable",
}

/**
* - online_lesson_enable : 対応可
- online_lesson_consult : 応相談
- online_lesson_disable : 対応不可
*/
export enum GogoCompanyInformationOnlineLessonEvent {
  OnlineLessonEnable = "online_lesson_enable",
  OnlineLessonConsult = "online_lesson_consult",
  OnlineLessonDisable = "online_lesson_disable",
}

/**
* - fieldwork_enable : 対応可
- fieldwork_consult : 応相談
- fieldwork_disable : 対応不可
*/
export enum GogoCompanyInformationFieldworkEvent {
  FieldworkEnable = "fieldwork_enable",
  FieldworkConsult = "fieldwork_consult",
  FieldworkDisable = "fieldwork_disable",
}

/**
* - parking_available : 駐車場有
- parking_unavailable : 駐車場無
*/
export enum GogoRequestParking {
  ParkingAvailable = "parking_available",
  ParkingUnavailable = "parking_unavailable",
}

/**
* - projector_available : プロジェクター有
- projector_unavailable : プロジェクター無
*/
export enum GogoRequestProjector {
  ProjectorAvailable = "projector_available",
  ProjectorUnavailable = "projector_unavailable",
}

/**
* - internet_connection_available : インターネット環境有
- internet_connection_unavailable : インターネット環境無
*/
export enum GogoRequestInternetConnection {
  InternetConnectionAvailable = "internet_connection_available",
  InternetConnectionUnavailable = "internet_connection_unavailable",
}

/**
* - photography_available : 写真撮影・広報利用有
- photography_unavailable : 写真撮影・広報利用無
*/
export enum GogoRequestPhotography {
  PhotographyAvailable = "photography_available",
  PhotographyUnavailable = "photography_unavailable",
}

/**
* - quiet : 大人しい
- tension : 緊張感がある
- exciting : 盛り上がっている
- harmonious : 和気あいあいとしている
- freewheeling : 自由奔放である
*/
export enum GogoRequestAtmosphere {
  Quiet = "quiet",
  Tension = "tension",
  Exciting = "exciting",
  Harmonious = "harmonious",
  Freewheeling = "freewheeling",
}

export enum GogoRequestAtmosphereJa {
  quiet = "大人しい",
  tension = "緊張感がある",
  exciting = "盛り上がっている",
  harmonious = "和気あいあいとしている",
  freewheeling = "自由奔放である",
}

/**
* - invitation : 講演依頼
- participation : 発表会への参加、コメント
- other : その他
*/
export enum GogoRequestHope {
  Invitation = "invitation",
  Participation = "participation",
  Other = "other",
}

export enum GogoRequestHopeJa {
  invitation = "講演依頼",
  participation = "緊張感が発表会への参加、コメントある",
  other = "その他",
}

/**
* - local : 現地のみ
- remote : リモートのみ
- either : どちらでもOK
*/
export enum GogoRequestLearningEnvironment {
  Local = "local",
  Remote = "remote",
  Either = "either",
}

export enum GogoRequestLearningEnvironmentJa {
  local = "現地のみ",
  remote = "リモートのみ",
  either = "どちらでもOK",
}

/**
 * 探究GoGoのプログラムの募集日程情報
 */
export type GogoProgramPreferredDateBase = ModelBase & {
  title: string;
  status: "非公開" | "公開​";
  status_code: "draft" | "published";
  start_time: string;
  end_time: string;
  created_at: string;
  request_type: any;
  request_type_code: any;
  hope: GogoRequestHopeJa;
  hope_code: GogoRequestHope;
};

export interface GogoProgramPreferredDateRequestBody {
  title: string;
  start_time: string;
  end_time: string;
  request_type?: any;
  hope?: GogoRequestHope;
  event?: GogoProgramPreferredDateEvent;
}

/**
* - withdraw : 非公開
- release : 公開​
*/
export enum GogoProgramPreferredDateEvent {
  Withdraw = "withdraw",
  Release = "release",
}

export interface AuthorizationsListParams {
  response_type?: string;
  client_id?: string;
  redirect_uri?: string;
  scope?: string;
}

export interface ConfirmationListParams {
  confirmation_token?: string;
}

export interface UnlockListParams {
  unlock_token?: string;
}

export interface GetAuthMicrosoftOauth2CallbackParams {
  /** microsoft_oauth2_url後に返却されるcodeを指定 */
  code?: string;
}

export interface SchedulesListParams {
  start_date: string;
  end_date: string;
}

export interface SchedulesSearchListParams {
  /** Affiliate ID を指定してください。 */
  affiliate_id: string;

  /** このパラメータが存在するとGoogle Calendarは表示されない。 */
  not_include_google_calendar?: string;
  start_date: string;
  end_date: string;
}

export interface MaterialsListParams {
  category_id?: string;
  per_page?: number;
  page?: number;
}

export interface PostAttachedImageAsCommonParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
}

export interface GetAttachedImageAsCommonParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PutAttachedImageAsCommonParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface DeleteAttachedImageAsCommonParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface GetTtGoogleDrivesAsCommonParams {
  page_size?: string;
  page_token?: string;
  order_by?: TtGoogleDriveSort;
  folder_id?: string;
}

export interface GetTtGoogleDrivesFormatAsCommonParams {
  file_id?: string;
}

export interface GetTtGoogleDrivesDownloadAsCommonParams {
  file_id?: string;
}

export interface GetTtGoogleDrivesOauth2CallbackAsCommonParams {
  /** Google認証後に返ってくる値 */
  code?: string;

  /** Google認証後に返ってくる値 */
  scope?: string;

  /** Google認証後に返ってくる値 */
  error?: string;
}

export interface GetTtMicrosoftDrivesAsCommonParams {
  folder_id?: string;
  top?: number;
  next_link?: string;
  search?: string;
  filter?: string;
  orderby?: string;
}

export interface GetTtMicrosoftDrivesFormatAsCommonParams {
  item_id?: string;
}

export interface GetTtMicrosoftDrivesOauth2CallbackAsCommonParams {
  /** Microsoft認証後に返ってくる値 */
  code?: string;
}

export interface GetIcalendarsAsCommonParams {
  start_time: string;
  end_time: string;
  title: string;
  description?: string;
  location?: string;
  url?: string;
}

export interface CalendarOauth2CallbackListParams {
  /** Google認証後に返ってくる値 */
  code?: string;

  /** Google認証後に返ってくる値 */
  scope?: string;
}

export interface ClassroomOauth2CallbackListParams {
  /** Google認証後に返ってくる値 */
  code?: string;

  /** Google認証後に返ってくる値 */
  scope?: string;
}

export interface FetchCompaniesAsAdminParams {
  page?: number;
  per_page?: number;

  /** For incremental search by name */
  search?: string;

  /** For incremental search by prefecture */
  prefecture?: string;
  company_type?: CompanyTypeFilter;

  /** university_cooperation_featureのオプションが有効になっているCompanyを取得 */
  has_university_cooperation_feature?: boolean;

  /** has_university_cooperation_featureが必要。指定した company_id に紐づいている学校を除外する。 */
  company_id?: string;
}

export interface GradeMastersListParams {
  company_id?: string;
}

export interface GetReviewerCompanyGroupControllersAsAdminParams {
  page?: number;
  per_page?: number;
  company_id: string;
}

export interface GetCompaniesContentCompaniesAsAdminParams {
  page?: number;
  per_page?: number;
}

export interface GetIpWhitelistsAsAdminParams {
  page?: number;
  per_page?: number;
  company_id: string;
}

export interface GetSearchUserControllersAsAdminParams {
  page?: number;
  per_page?: number;
  email: string;
}

export interface NotificationsListParams {
  sent?: string;
  per_page?: number;
  page?: number;
}

export interface GetEblItemsAsAdminParams {
  page?: number;
  per_page?: number;
}

export interface GetAdminSteamContentsParams {
  page?: number;
  per_page?: number;
}

export interface FetchAdminSteamContentsParams {
  /** Steam ライブラリーのコンテンツID */
  steam_library_content_id: number;
}

export interface GetAdminContentCompanyReviewingsParams {
  sent?: string;
  per_page?: number;
  page?: number;

  /** コンテンツ事業者のCompany ID */
  content_company_id: string;
}

export interface GetAdminContentCompanyReviewingsReviewFeatureCompaniesParams {
  sent?: string;
  per_page?: number;
  page?: number;

  /** コンテンツ事業者のCompany ID */
  content_company_id: string;
}

export interface GetAdminSponsorInfosParams {
  page?: number;
  per_page?: number;
  search_type?: SponsorInfosSearchType;
  search_word?: string;
  is_linking?: boolean;

  /**
   * - is_linking は必須
   * - is_linking が true のとき、指定した company_id に関連する企業を返却
   * - is_linking が false のとき、指定した company_id に関連しない企業を返却
   */
  company_id?: string;
}

export interface FetchPblSponsorLinkableCompaniesAsAdminParams {
  /** For incremental search by name */
  search?: string;

  /** For incremental search by prefecture */
  prefecture?: string;
  page?: number;
  per_page?: number;

  /** 企業ID（Sponsor::Info ID） */
  info_id: string;
}

export interface FetchSponsorProjectsAsAdminParams {
  page?: number;
  per_page?: number;
  company_id?: string;
  sponsor_id?: string;
  search_type?: SponsorProjectsSearchType;
  search_word?: string;

  /** 大学先生に紐づいているプロジェクトを呼び出す */
  professor_affiliate_id?: string;
}

export interface FetchSponsorProjectsForCarouselAsAdminParams {
  number_of_projects?: number;
  sort_type?: SponsorProjectsSortType;
}

export interface FetchSponsorProjectListAsAdminParams {
  page?: number;
  per_page?: number;
  professor_affiliate_id: string;
}

export interface FetchSponsorLecturesAsAdminParams {
  page?: number;
  per_page?: number;

  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  project_id: string;
}

export interface PutSponsorLectureSequenceAsAdminParams {
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  project_id: string;
}

export interface PostSponsorArticleContentTemplateAsAdminParams {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsor_lecture_id: string;
}

export interface GetSponsorArticleContentTemplateAsAdminParams {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsor_lecture_id: string;

  /** Pbl::Sponsor::ArticleContentTemplate ID */
  id: string;
}

export interface PutSponsorArticleContentTemplateAsAdminParams {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsor_lecture_id: string;

  /** Pbl::Sponsor::ArticleContentTemplate ID */
  id: string;
}

export interface DeleteSponsorArticleContentTemplateAsAdminParams {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsor_lecture_id: string;

  /** Pbl::Sponsor::ArticleContentTemplate ID */
  id: string;
}

export interface GetFeedbackTemplatesAsAdminParams {
  page?: number;
  per_page?: number;
}

export interface GetSponsorLinksAsAdminParams {
  page?: number;
  per_page?: number;

  /** 企業ID（Sponsor::Info ID） */
  info_id: string;
}

export interface PostAttachedImageAsAdminParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
}

export interface GetAttachedImageAsAdminParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PutAttachedImageAsAdminParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface DeleteAttachedImageAsAdminParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PostOptionsExpansionAsAdminParams {
  company_id: string;
}

export interface PutOptionsExpansionAsAdminParams {
  company_id: string;

  /** Options::Expansion ID */
  id: string;
}

export interface GetUniversityCooperationsAsAdminParams {
  page?: number;
  per_page?: number;

  /** Company ID */
  company_id: string;
}

export interface GetTeacherUniversitiesAsAdminParams {
  page?: number;
  per_page?: number;

  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  search_type?: TeacherSearchType;

  /** 検索単語、search_typeとsearch_wordは同時に指定する必要有り */
  search_word?: string;

  /** 企業プロジェクトでフィルタリングする */
  pbl_sponsor_project_id?: string;
}

export interface GetTtCsvExportsAsAdminParams {
  page?: number;
  per_page?: number;
  types?: TtCsvExportType[];
}

export interface PostTtCsvExportAsAdminParams {
  sorted_by?: string;
  search_key?: string;
  search_value?: string;
}

export interface GetGogoLinkingsAsAdminParams {
  /** Company ID */
  company_id: string;
  page?: number;
  per_page?: number;
}

export interface PostGogoLinkingAsAdminParams {
  /** Company ID */
  company_id: string;
}

export interface DeleteGogoLinkingAsAdminParams {
  /** Company ID */
  company_id: string;

  /** Gogo::Linking ID */
  id: string;
}

export interface EmailsListParams {
  per_page?: number;
  page?: number;
}

export interface GetSuperOwnerCustomMasterMapsParams {
  page?: number;
  per_page?: number;
}

export interface GetSuperOwnerCustomMasterMapsCustomMasterMapIdParams {
  page?: number;
  per_page?: number;

  /** CMM ID for getting master values */
  custom_master_map_id: string;
}

export interface CustomMastersLinkingCompaniesListParams {
  /** custom_master_mapのhashid */
  custom_master_map_id: string;

  /** 検索するカラムのnumberのみ "2" */
  column_number: string;

  /** 検索するカラムの値(複数検索可能) */
  column_values: string[];
}

export interface GetCustomMasterMapRelationshipsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  custom_master_map_id?: string;
}

export interface PostCustomMasterMapRelationshipsAsSuperOwnerParams {
  /** CMM ID for getting master values */
  custom_master_map_id: string;
}

export interface GetCustomMasterMapRelationshipAsSuperOwnerParams {
  /** CMM ID for getting master values */
  custom_master_map_id: string;

  /** カスタムリストのリレーション設定 ID (Common::CustomMasterMapRelationship ID) */
  id: string;
}

export interface PutCustomMasterMapRelationshipsAsSuperOwnerParams {
  /** CMM ID for getting master values */
  custom_master_map_id: string;

  /** カスタムリストのリレーション設定 ID (Common::CustomMasterMapRelationship ID) */
  id: string;
}

export interface DeleteControllerAsSuperOwnerParams {
  /** CMM ID for getting master values */
  custom_master_map_id: string;

  /** カスタムリストのリレーション設定 ID (Common::CustomMasterMapRelationship ID) */
  id: string;
}

export interface GetCustomMasterMapRelationshipChildCustomMastersAsSuperOwnerParams {
  /** CMM ID for getting master values */
  custom_master_map_id: string;

  /** カスタムリストのリレーション設定 ID (Common::CustomMasterMapRelationship ID) */
  id: string;
}

export interface CreatedUserTagTypesListParams {
  user_tag_type_name?: string;
  user_tag_name?: string;
  page?: number;
  per_page?: number;
  kind?: UserTagTypeKind;
  role?: UserTagTypeRoleType;
}

export interface GetSearchUserControllersAsSuperOwnerParams {
  user_name?: string;
  email: string;
  company_name?: string;
  tag_name?: string;
  role_name?: string;
  status?: AffiliateStatus;
  page?: number;
  per_page: number;
}

export interface GetReviewerCompanyGroupControllersAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface FetchCompaniesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  company_role?: CompanyRoleType;
  company_tag_ids?: string[];
  tag_filter?: "AND" | "OR";
  homework_template_id?: string;
  distribution?: "distributed" | "not_distributed";
  company_codes?: string;
  master_name?: string;
  column_num?: string;

  /** Search by company name */
  company_name?: string;

  /** Search by company tag name */
  company_tag_name?: string;
  simple_info?: boolean;
}

export interface CompaniesSuperOwnersDetailParams {
  /** Incremental search by last_name and first_name */
  search?: string;
  page?: number;
  per_page?: number;
  company_id: string;
}

export interface CompaniesOwnersDetailParams {
  /** Incremental search by last_name and first_name */
  search?: string;
  email?: string;

  /** search users by statuses */
  search_statuses?: AffiliateStatusJa[];
  page?: number;
  per_page?: number;
  company_id: string;
}

export interface CompaniesOwnersCreateParams {
  /** Incremental search by last_name and first_name */
  search?: string;
  email?: string;

  /** search users by statuses */
  search_statuses?: AffiliateStatusJa[];
  page?: number;
  per_page?: number;
  company_id: string;
}

export interface CompaniesTeachersDetailParams {
  /** Incremental search by last_name and first_name */
  search?: string;
  email?: string;

  /** search users by statuses */
  search_statuses?: AffiliateStatusJa[];
  per_page?: number;
  page?: number;
  tag_name?: string;
  company_id: string;
}

export interface CompaniesStudentsDetailParams {
  /** Incremental search by last_name and first_name */
  search?: string;
  email?: string;
  grade?: string;

  /** 複数のgradeで検索する時 */
  grades?: string;
  parent_name?: string;
  user_tag?: string;
  nickname?: string;

  /** search users by statuses */
  search_statuses?: AffiliateStatusJa[];
  per_page?: number;
  page?: number;
  sorted_by?: SortedByStudentList;
  company_id: string;
}

export interface StudentListGradeAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  company_id: string;
}

export interface FetchReviewersAsSuperOwnerParams {
  per_page?: number;
  page?: number;
  company_id: string;
}

export interface GetSuperOwnerUsersUserIdParams {
  role?: "teacher" | "owner";
  company_id: string;
  id: string;
}

export interface FetchUserTagsAsSuperOwnerParams {
  user_tag_type_id?: string;
  kind?: "school" | "nationwide" | "limited";
  page?: number;
  per_page?: number;
  role_type?: UserTagTypeRoleType;
}

export interface UserTagsDistributedStudentsDetailParams {
  per_page?: number;
  page?: number;
  id: string;
}

export interface CreatedCompanyTagTypesListParams {
  company_tag_type_name?: string;
  company_tag_name?: string;
  page?: number;
  per_page?: number;
}

export interface GetUserTagDistributionHistoriesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  status?: UserTagDistributionSettingStatus;
  company_name?: string;
  user_tag_name?: string;
}

export interface FetchCompanyTagsAsSuperOwnerParams {
  company_tag_type_id?: string;
  page?: number;
  per_page?: number;
}

export interface FetchHomeworkTemplatesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  undelivery?: boolean;
  for_group?: boolean;
  search_type?: HomeworkTemplateSearchType;
  search_word?: string;

  /** common_tag_nameのフィルタリング */
  common_tag_name?: string;
}

export interface FetchHomeworkTemplateDistributionsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  homework_template_id: string;
}

export interface CompaniesUserTagsDetailParams {
  per_page?: number;
  page?: number;
  company_id: string;
}

export interface UserTagsUsersDetailParams {
  per_page?: number;
  page?: number;
  user_tag_id: string;
}

export interface MaterialsAllContainedCategoryListParams {
  category_ids?: string[];
  per_page?: number;
  page?: number;
}

export interface CompanyTagsCompaniesDetailParams {
  page?: number;
  per_page?: number;
  company_tag_id: string;
}

export interface FetchRevieweeCompaniesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  reviewer_company_id: string;
}

export interface FetchAssignableCompaniesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  company_tag_ids?: string[];
  tag_filter?: "AND" | "OR";
  reviewer_company_id: string;
}

export interface ListMaterialDistributionHistoryAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  material_id?: string;
  sent_company_name?: string;
  user_tag_name?: string;
}

export interface ListShowMaterialDistributionHistoryAsSuperOwnerParams {
  distribution_id?: string;
  material_id?: string;
  sent_company_id?: string;
  page?: number;
  per_page?: number;
}

export interface SearchByUserIdMaterialDistributionHistoryAsSuperOwnerParams {
  user_id: string;
  page?: number;
  per_page?: number;
}

export interface GetSuperOwnerHomeworkTemplateDistributionHistoriesParams {
  page?: number;
  per_page?: number;
}

export interface GetSuperOwnerHomeworkTemplateDistributionHistoryAffiliatesParams {
  page?: number;
  per_page?: number;

  /** HomeworkTemplateDistributionHistory ID */
  homework_template_distribution_history_id: string;
}

export interface GetSuperOwnerHomeworkTemplateDistributionHistoryCompaniesParams {
  page?: number;
  per_page?: number;

  /** HomeworkTemplateDistributionHistory ID */
  homework_template_distribution_history_id: string;
}

export interface GetSuperOwnerHomeworkTemplateCustomFieldsParams {
  page?: number;
  per_page?: number;

  /** HomeworkTemplate ID */
  homework_template_id: string;
}

export interface GetSuperOwnerInquiriesParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
  status?: InquiryStatus;
  kind?: InquiryKind;
  person_in_charge_name?: string;
  company_name?: string;
  user_name?: string;
}

export interface PostSuperOwnerInquiryCommentsParams {
  /** inquiries ID */
  inquiry_id: string;
}

export interface PutSuperOwnerInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface DeleteSuperOwnerInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface GetLearningTemplatePackagesAsSuperOwnerParams {
  page?: number;
  per_page?: number;

  /** プログラムのタイトルで絞り込み */
  title?: string;
}

export interface GetLearningTemplatePackageDistributionsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  company_ids: string[];
  grade?: string;

  /** LearningTemplatePackage ID */
  learning_template_package_id: string;
}

export interface GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  user_tag_ids: string[];
  grade?: string;

  /** LearningTemplatePackage ID */
  learning_template_package_id: string;
}

export interface GetLearningTemplatePackageDistributionHistoriesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwnerParams {
  page?: number;
  per_page?: number;

  /** LearningTemplatePackageDistributionSetting ID */
  id: string;
}

export interface GetMaterialHomeworkTemplatePackagesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface MaterialWorksTargetCompaniesListParams {
  material_id: string;
  company_ids?: string[];
  user_tag_ids?: string[];
  grade?: StudentGrade;
  page?: number;
  per_page?: number;
}

export interface MaterialWorksTargetMaterialsListParams {
  category_id: string;
  company_ids?: string[];
  user_tag_ids?: string[];
  grade?: StudentGrade;
  page?: number;
  per_page?: number;
}

export interface PutPackagingAsSuperOwnerParams {
  /** - パッケージのID */
  package_id: string;

  /** - パッケージに登録するコンテンツの Modelの名前 を指定 */
  packageable_type: string;

  /** - パッケージに登録するコンテンツの ID を指定 */
  packageable_id: string;
}

export interface DeletePackagingAsSuperOwnerParams {
  /** - パッケージのID */
  package_id: string;

  /** - パッケージに登録するコンテンツの Modelの名前 を指定 */
  packageable_type: string;

  /** - パッケージに登録するコンテンツの ID を指定 */
  packageable_id: string;
}

export interface GetPackagingsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  package_id: string;
}

export interface GetPblBoardInfoAsSuperOwnerParams {
  /** 文字列のtrueを設定をすると探究成果一覧を取得する */
  preview?: string;

  /** 探究成果一覧のページング */
  page?: number;

  /** 探究成果一覧のページング */
  per_page?: number;
}

export interface GetPblBoardCampaignsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface PostPblBoardCampaignRequestAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  search_status?: PblBoardCampaignRequestStatus;

  /** 探究ボードの探究成果募集 ID */
  campaign_id: string;
}

export interface GetPblBoardArticlesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetPblBoardFootersAsSuperOwnerParams {
  page?: number;
  per_page?: number;

  /** 探究ボードのコンテンツタイプ ID */
  item_id: string;
}

export interface PostAttachedImageAsSuperOwnerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
}

export interface GetAttachedImageAsSuperOwnerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PutAttachedImageAsSuperOwnerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface DeleteAttachedImageAsSuperOwnerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface GetLimitedTimeNotificationsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetSteamContentsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetSteamLecturesAsSuperOwnerParams {
  page?: number;
  per_page?: number;

  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  sl_content_id: number;
}

export interface PostSuperOwnerSteamLecturesParams {
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  sl_content_id: number;
}

export interface FetchSponsorProjectsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface FetchSponsorLecturesAsSuperOwnerParams {
  page?: number;
  per_page?: number;

  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  project_id: string;
}

export interface GetTtCsvExportsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  types?: TtCsvExportType[];
}

export interface PostTtCsvExportAsSuperOwnerParams {
  sorted_by?: TtCsvExportSearchTypeSortedBy;
  search_status?: AffiliateStatus;
  search_type?: TtCsvExportSearchType;
  search_value?: string;
}

export interface GetFeedbackTemplateHomeworksAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  search_type?: FeedbackTemplateSearchType;
  search_word?: string;

  /** タグ名に完全一致するものを返却する */
  tag_name?: string;
}

export interface GetFeedbackTemplateTagsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
}

export interface FetchHelpContentsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  kind: HelpMasterKind;
}

export interface GetIdealBoardTemplatesAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  search_status?: IdealBoardTemplateStatus;
  no_homework_template?: boolean;
}

export interface GetIdealBoardsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  ideal_board_template_id?: string;
  group_affiliate_id?: string;
}

export interface GetCommonTagsAsSuperOwnerParams {
  page?: number;
  per_page?: number;
  search_type?: any;
  search_word?: string;
  kind?: CommonTagKind;
  categorizable_type?: CommonTaggingCategorizableType;
}

export interface PostCommonTaggingAsSuperOwnerParams {
  categorizable_type: CommonTaggingCategorizableType;
  categorizable_id: string;
}

export interface DeleteCommonTaggingAsSuperOwnerParams {
  categorizable_type: CommonTaggingCategorizableType;
  categorizable_id: string;
  common_tag_id: string;
}

export interface FetchCategoriesAsOwnerParams {
  /** - ownerがsuper_ownerから公開された教材一覧を取得するときに利用。 */
  from_super_owner?: boolean;
}

export interface FetchMaterialWorksAsOwnerParams {
  user_id?: string;
  material_ids?: string[];
  per_page?: number;
  page?: number;
  role: string;

  /**
   * - 検索タイプを指定する
   * - search_typeとsearch_wordは両方していする必要あり
   * - title: タイトルから検索
   * - description: 説明文から検索
   * - tag: タグから検索
   */
  search_type?: string;

  /**
   * - search_typeとsearch_wordは両方していする必要あり
   * - 検索ワードの部分一致で検索する
   */
  search_word?: string;
}

export interface FetchTeachersAsOwnerParams {
  /** For incremental search by last name and first_name */
  search?: string;
  email?: string;

  /** search users by statuses */
  search_statuses?: AffiliateStatusJa[];
  per_page?: number;
  page?: number;

  /** trueのとき、id, name のみを返却 */
  is_min_info?: boolean;
}

export interface FetchTeachersForSelectAsOwnerParams {
  /** For incremental search by last name and first_name */
  search?: string;
  per_page?: number;
  page?: number;
}

export interface FetchStudentsAsOwnerParams {
  with_material_work?: boolean;
  material_id?: string;

  /** Incremental search by last name and first_name */
  search?: string;
  email?: string;
  sorted_by?: SortedByStudentList;
  grade?: string;

  /** 複数のgradeで検索する時 */
  grades?: string;

  /** Incremental search by parent_last_name and parent_first_name */
  parent_name?: string;

  /** search users by statuses */
  search_statuses?: AffiliateStatusJa[];
  user_tag?: string;
  user_tag_ids?: string[];
  tag_filter?: "AND" | "OR";
  homework_template_id?: string;

  /** material_idで検索する時使用 */
  filter_material_id?: string;
  distribution?: "distributed" | "not_distributed";
  per_page?: number;
  page?: number;

  /** グループのID (GroupAffiliatePackage ID) */
  group_affiliate_package_id?: string;

  /** 生徒に紐づくユーザータグとグループの数のみ返却 */
  min?: boolean;

  /** グループのIDの配列 (GroupAffiliatePackage ID) */
  group_affiliate_package_ids?: string[];

  /** ニックネーム */
  nickname?: string;
}

export interface StudentListGradeAsOwnerParams {
  homework_template_id?: string;
  pbl_report_template_id?: string;
  filter_material_id?: string;
  distribution?: "distributed" | "not_distributed";
}

export interface FetchOwnersAsOwnerParams {
  /** Incremental search by last name and first_name */
  search?: string;
  email?: string;
  per_page?: number;
  page?: number;

  /** search users by statuses */
  search_statuses?: AffiliateStatusJa[];
}

export interface GetReviewerCompanyGroupControllersAsOwnerParams {
  page?: number;
  per_page?: number;
}

export interface FetchMaterialsAsOwnerParams {
  category_id?: string;
  with_material_work?: boolean;
  user_id?: string;
  role?: string;
  per_page?: number;
  page?: number;

  /**
   * - 検索タイプを指定する
   * - search_typeとsearch_wordは両方していする必要あり
   * - title: タイトルから検索
   * - description: 説明文から検索
   * - tag: タグから検索
   */
  search_type?: string;

  /**
   * - search_typeとsearch_wordは両方していする必要あり
   * - 検索ワードの部分一致で検索する
   */
  search_word?: string;
}

export interface MaterialsTargetUsersDetailParams {
  user_tag_ids: string[];
  grade?: StudentGrade;
  material_id: string;
}

export interface FetchMaterialsFromSuperOwnerAsOwnerParams {
  per_page?: number;
  page?: number;
  category_ids?: string[];
  tag_names?: string[];

  /**
   * - 検索タイプを指定する
   * - search_typeとsearch_wordは両方していする必要あり
   * - title: タイトルから検索
   * - description: 説明文から検索
   * - tag: タグから検索
   */
  search_type?: string;

  /**
   * - search_typeとsearch_wordは両方していする必要あり
   * - 検索ワードの部分一致で検索する
   */
  search_word?: string;
  search_column?: MaterialSortedByColumn;
  search_order?: SortedByOrder;
}

export interface FetchReportsAsOwnerParams {
  page?: number;
  per_page?: number;
  teacher_name?: string;
  student_name?: string;
  start_date?: string;
  end_date?: string;
}

export interface FetchPaymentsAsOwnerParams {
  teacher_name?: string;
  student_name?: string;
  start_date?: string;
  end_date?: string;
  per_page?: number;
  page?: number;
}

export interface FetchHomeworksAsOwnerParams {
  page?: number;
  per_page?: number;
  template_id?: string;
  teacher_name?: string;
  student_name?: string;
  start_date?: string;
  end_date?: string;
  statuses?: HomeworkStatus[];
  order_type?: HomeworkOrderType;
  sort_type?: SortedByOrder;
}

export interface GetHomeworkImageDownloadUrlsAsOwnerParams {
  homework_ids: string[];
  file_content?: "file" | "answer_file" | "reviewer_file";
  template_id?: string;
  order_type?: HomeworkOrderType;
  sort_type?: SortedByOrder;
}

export interface FetchOwnerEmailsParams {
  per_page?: number;
  page?: number;
}

export interface FetchArticlesAsOwnerParams {
  per_page?: number;
  page?: number;

  /** グループのID (GroupAffiliatePackage ID) */
  group_affiliate_package_id?: string;

  /** グループのIDの配列 (GroupAffiliatePackage ID) */
  group_affiliate_package_ids?: string[];

  /** articleのstatus */
  status?: ArticleStatus;

  /** kindのフィルタリング */
  kind?: ArticleKind;
}

export interface FetchArticleWordCloudAsOwnerParams {
  list_len?: number;
  offset?: number;
  min_num_of_char?: number;
}

export interface FetchArticleFeedbacksAsOwnerParams {
  per_page?: number;
  page?: number;
  article_id: string;
}

export interface FetchArticleCommentsAsOwnerParams {
  per_page?: number;
  page?: number;
  article_id: string;
}

export interface FetchAffiliateInvitationsAsOwnerParams {
  role?: UserType;
}

export interface UserTagTypesListParams {
  page?: number;
  per_page?: number;
  kind?: "nationwide" | "limited";
  role_type?: "student" | "teacher";
}

export interface FetchUserTagsAsOwnerParams {
  kind?: "nationwide" | "limited";
  page?: number;
  per_page?: number;
  role_type?: UserTagTypeRoleType;
}

export interface GetUserTagsAlreadyBeenDistributedAsOwnerParams {
  material_id: string;
  kind?: string;
  page?: number;
  per_page?: number;
}

export interface FetchHomeworkTemplatesAsOwnerParams {
  page?: number;
  per_page?: number;
  search_type?: HomeworkTemplateSearchType;
  search_word?: string;

  /** common_tag_nameのフィルタリング */
  common_tag_name?: string;
}

export interface GetHomeworkTemplateDetailListAsOwnerParams {
  homework_template_ids: string[];
}

export interface GetOwnerHomeworkTemplateCustomFieldsParams {
  page?: number;
  per_page?: number;

  /** HomeworkTemplate ID */
  homework_template_id: string;
}

export interface ListMaterialDistributionHistoryAsOwnerParams {
  page?: number;
  per_page?: number;
}

export interface ListShowMaterialDistributionHistoryAsOwnerParams {
  distribution_id?: string;
  material_id?: string;
  page?: number;
  per_page?: number;
  sent_company_id?: string;
}

export interface SearchByUserIdMaterialDistributionHistoryAsOwnerParams {
  user_id: string;
  page?: number;
  per_page?: number;
}

export interface GetOwnerTemplateFrameworksParams {
  page?: number;
  per_page?: number;
}

export interface GetOwnerInquiriesParams {
  page?: number;
  per_page?: number;
}

export interface PostOwnerInquiryCommentsParams {
  /** inquiries ID */
  inquiry_id: string;
}

export interface PutOwnerInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface DeleteOwnerInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface GetLearningTemplatePackageDistributionsAsOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetLearningTemplatePackageDistributionHistoriesAsOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwnerParams {
  page?: number;
  per_page?: number;

  /** LearningTemplatePackageDistributionSetting ID */
  id: string;
}

export interface GetLearningTemplatePackageDistributionHistoriesPackagingsAsOwnerParams {
  page?: number;
  per_page?: number;

  /** LearningTemplatePackageDistributionSetting ID */
  id: string;
}

export interface GetPblBoardInfoAsOwnerParams {
  /** 文字列のtrueを設定をすると探究成果一覧を取得する */
  preview?: string;

  /** 探究成果一覧のページング */
  page?: number;

  /** 探究成果一覧のページング */
  per_page?: number;
}

export interface GetPblBoardCampaignsAsOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetPblBoardCampaignRequestAsOwnerParams {
  page?: number;
  per_page?: number;
  search_status?: PblBoardCampaignRequestStatus;

  /** 探究ボードの探究成果募集 ID */
  campaign_id: string;
}

export interface GetPblBoardArticlesAsOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetPblBoardArticlesWordCloudAsOwnerParams {
  list_len?: number;
  offset?: number;
  min_num_of_char?: number;
}

export interface GetPblBoardFootersAsOwnerParams {
  page?: number;
  per_page?: number;

  /** 探究ボードのコンテンツタイプ ID */
  item_id: string;
}

export interface PostAttachedImageAsOwnerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
}

export interface GetAttachedImageAsOwnerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PutAttachedImageAsOwnerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface DeleteAttachedImageAsOwnerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface GetLimitedTimeNotificationsAsOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetLimitedTimeNotificationNoticeAsOwnerParams {
  page?: number;
  per_page?: number;
}

export interface GetTtCsvExportsAsOwnerParams {
  page?: number;
  per_page?: number;
  types?: TtCsvExportType[];
}

export interface PostTtCsvExportAsOwnerParams {
  sorted_by?: string;
  search_key?: string[];
  search_value?: string[];
}

export interface GetDistributionStatusesAsOwnerParams {
  page?: number;
  per_page?: number;
  homework_template_id?: string;
}

export interface DeleteCommentImageAsOwnerParams {
  categorizable_type: CommentImageCategorizableType;
  categorizable_id: string;

  /** CommentImage ID */
  id: string;
}

export interface GetInterviewRequestsAsOwnerParams {
  page?: number;
  per_page?: number;
  status?: InterviewRequestStatus;

  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  search_type?: InterviewRequestSearchType;

  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  search_word?: string;
}

export interface GetInterviewRequestUnreadCountsAsOwnerParams {
  request_ids?: string[];
}

export interface GetInterviewMessagesAsOwnerParams {
  page?: number;
  per_page?: number;

  /** Interview::Request ID */
  request_id: string;
}

export interface GetInterviewFeedbacksAsOwnerParams {
  page?: number;
  per_page?: number;

  /** Interview::Request ID */
  request_id: string;
}

export interface FetchSponsorProjectListAsOwnerParams {
  page?: number;
  per_page?: number;
  professor_affiliate_id: string;
}

export interface GetGroupAffiliatePackagesAsOwnerParams {
  page?: number;
  per_page?: number;
  homework_template_id?: string;
  distributed?: boolean;
}

export interface PutPackagingAsOwnerParams {
  /**
   * - パッケージのID
   * - e.g. GroupAffiliatePackage の場合は、その id を指定する
   */
  package_id: string;

  /**
   * - パッケージに登録するコンテンツの Modelの名前 を指定
   * - e.g. GroupAffiliatePackage の場合は、Affiliate を指定する
   */
  packageable_type: PackagingPackageableType;

  /**
   * - パッケージに登録するコンテンツの ID を指定
   * - e.g. GroupAffiliatePackage の場合は、Affiliateのid を指定する
   */
  packageable_id: string;
}

export interface DeletePackagingAsOwnerParams {
  /**
   * - パッケージのID
   * - e.g. GroupAffiliatePackage の場合は、その id を指定する
   */
  package_id: string;

  /**
   * - パッケージに登録するコンテンツの Modelの名前 を指定
   * - e.g. GroupAffiliatePackage の場合は、Affiliate を指定する
   */
  packageable_type: string;

  /**
   * - パッケージに登録するコンテンツの ID を指定
   * - e.g. GroupAffiliatePackage の場合は、Affiliateのid を指定する
   */
  packageable_id: string;
}

export interface GetCommonTagsAsOwnerParams {
  page?: number;
  per_page?: number;

  /** - name : タグ名 */
  search_type?: "name";
  search_word?: string;
  kind?: CommonTagKind;
  categorizable_type?: CommonTaggingCategorizableType;
}

export interface PostCommonTaggingAsOwnerParams {
  categorizable_type: CommonTaggingCategorizableType;
  categorizable_id: string;
}

export interface DeleteCommonTaggingAsOwnerParams {
  categorizable_type: CommonTaggingCategorizableType;
  categorizable_id: string;
  common_tag_id: string;
}

export interface FetchStudentsAsTeacherParams {
  user_tag_ids?: string[];
  tag_filter?: "AND" | "OR";
  homework_template_id?: string;
  pbl_report_template_id?: string;

  /** material_idで検索する時使用 */
  filter_material_id?: string;
  distribution?: "distributed" | "not_distributed";

  /** Incremental search by last name */
  search?: string;
  email?: string;
  grade?: string;

  /** 複数のgradeで検索する時 */
  grades?: string;

  /** Incremental search by parent_last_name and parent_first_name */
  parent_name?: string;
  user_tag?: string;
  per_page?: number;
  page?: number;

  /** グループのID (GroupAffiliatePackage ID) */
  group_affiliate_package_id?: string;
  sorted_by?: SortedByStudentList;

  /** グループのIDの配列 (GroupAffiliatePackage ID) */
  group_affiliate_package_ids?: string[];

  /** search users by statues */
  search_statuses?: AffiliateStatusJa[];

  /** カリキュラムに関連する生徒を除外する */
  pbl_plan_item_id?: string;

  /** ニックネーム */
  nickname?: string;
}

export interface StudentListGradeAsTeacherParams {
  homework_template_id?: string;
  pbl_report_template_id?: string;
  filter_material_id?: string;
  distribution?: "distributed" | "not_distributed";
}

export interface UsersStudentAffiliatesListParams {
  /** Incremental search by last_name and first_name */
  search?: string;
  page?: number;
  per_page?: number;
}

export interface GetTeachersAsTeacherParams {
  page?: number;
  per_page?: number;

  /** For incremental search by last name and first_name */
  search?: string;
  email?: string;

  /** search users by statuses */
  search_statuses?: AffiliateStatusJa[];

  /** trueのとき、id, name, nickname のみを返却 */
  is_min_info?: boolean;
}

export interface GetTeacherUniversitiesAsTeacherParams {
  page?: number;
  per_page?: number;

  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  search_type?: TeacherSearchType;

  /** 検索単語、search_typeとsearch_wordは同時に指定する必要有り */
  search_word?: string;

  /** 企業プロジェクトID */
  pbl_sponsor_project_id: string;
}

export interface FetchMaterialsAsTeacherParams {
  per_page?: number;
  page?: number;
  category_ids?: string[];
  tag_names?: string[];

  /**
   * - 検索タイプを指定する
   * - search_typeとsearch_wordは両方していする必要あり
   * - title: タイトルから検索
   * - description: 説明文から検索
   * - tag: タグから検索
   */
  search_type?: string;

  /**
   * - search_typeとsearch_wordは両方していする必要あり
   * - 検索ワードの部分一致で検索する
   */
  search_word?: string;
  search_column?: MaterialSortedByColumn;
  search_order?: SortedByOrder;

  /**
   * - true
   * - false
   * - default: false
   */
  for_pbl_plan_item?: string;
}

export interface GetTeacherMaterialWorksParams {
  per_page?: number;
  page?: number;

  /**
   * - 検索タイプを指定する
   * - search_typeとsearch_wordは両方していする必要あり
   * - title: タイトルから検索
   * - description: 説明文から検索
   * - tag: タグから検索
   */
  search_type?: string;

  /**
   * - search_typeとsearch_wordは両方していする必要あり
   * - 検索ワードの部分一致で検索する
   */
  search_word?: string;
}

export interface GetMaterialTagsAsTeacherParams {
  page?: number;
  per_page?: number;

  /**
   * - true
   * - false
   * - default: false
   */
  for_pbl_plan_item?: string;
}

export interface FetchStudentMaterialWorksAsTeacherParams {
  student_id?: string;
}

export interface FetchOneStudentMaterialWorkAsTeacherParams {
  student_id?: string;
  material_work_id: string;
}

export interface FetchReportsAsTeacherParams {
  per_page?: number;
  page?: number;
}

export interface FetchPaymentsAsTeacherParams {
  per_page?: number;
  page?: number;
}

export interface FetchHomeworksAsTeacherParams {
  per_page?: number;
  page?: number;
  template_id?: string;
  teacher_name?: string;
  student_name?: string;
  start_date?: string;
  end_date?: string;
  statuses?: HomeworkStatus[];

  /** グループID */
  group_affiliate_ids?: string[];

  /** 指定する場合は、submitted_start_at と submitted_end_at が必須 */
  submitted_start_at?: string;

  /** 指定する場合は、submitted_start_at と submitted_end_at が必須 */
  submitted_end_at?: string;
  order_type?: HomeworkOrderType;
  sort_type?: SortedByOrder;
  user_tag_ids?: string[];
  group_title?: string;

  /** common_tag_nameのフィルタリング */
  common_tag_name?: string;
}

export interface GetHomeworkImageDownloadUrlsAsTeacherParams {
  homework_ids: string[];
  file_content?: "file" | "answer_file" | "reviewer_file";
  template_id?: string;
  order_type?: HomeworkOrderType;
  sort_type?: SortedByOrder;
}

export interface FetchSubmitHomeworkFilesRequestsAsTeacherParams {
  per_page?: number;
  page?: number;
  template_id?: string;

  /** ステータスを基にフィルターをかける */
  statuses?: SubmitHomeworkFilesRequestStatus[];
}

export interface GetReviewerCompanyGroupControllersAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface FetchArticlesAsTeacherParams {
  per_page?: number;
  page?: number;

  /** グループのID (GroupAffiliatePackage ID) */
  group_affiliate_package_id?: string;

  /** グループのIDの配列 (GroupAffiliatePackage ID) */
  group_affiliate_package_ids?: string[];

  /** articleのstatus */
  status?: ArticleStatus;

  /** kindのフィルタリング */
  kind?: ArticleKind;

  /** titleとbodyに対しての検索ワード */
  free_word?: string;
  search_column?: ArticleSortedByColumn;
  search_order?: SortedByOrder;

  /**
   * - 検索タイプを指定する
   * - search_typeとsearch_wordは両方指定する必要あり
   */
  search_type?: TeacherArticleType;

  /** - search_typeとsearch_wordは両方指定する必要あり */
  search_word?: string;

  /** user_tag_nameのフィルタリング */
  user_tag_name?: string;

  /** student_numberのフィルタリング */
  student_number?: string;

  /** common_tag_nameのフィルタリング */
  common_tag_name?: string;
}

export interface FetchArticleStatsAsTeacherParams {
  /** 件数指定 */
  limit?: number;

  /** グループID */
  group_affiliate_id?: string;
}

export interface FetchArticleWordCloudAsTeacherParams {
  list_len?: number;
  offset?: number;
  min_num_of_char?: number;
}

export interface GetArticleSubmitAsTeacherParams {
  /** 件数指定 */
  limit?: number;

  /** 探究成果物のhashidの配列 */
  article_ids: string[];
}

export interface FetchArticleCommentsAsTeacherParams {
  per_page?: number;
  page?: number;
  article_id: string;
}

export interface FetchArticleFeedbacksAsTeacherParams {
  per_page?: number;
  page?: number;
  article_id: string;
}

export interface ArticleCommentsStudentCommentsListListParams {
  per_page?: number;
  page?: number;
  search_type?: string;
  search_word?: string;
}

export interface FetchHomeworkTemplatesAsTeacherParams {
  page?: number;
  per_page?: number;
  search_type?: HomeworkTemplateSearchType;
  search_word?: string;

  /** common_tag_nameのフィルタリング */
  common_tag_name?: string;
}

export interface FetchHomeworkTemplateStatsAsTeacherParams {
  /** 件数指定 */
  limit?: number;

  /** グループID */
  group_affiliate_id?: string;
}

export interface GetHomeworkTemplateDetailListAsTeacherParams {
  homework_template_ids: string[];
}

export interface GetTeacherHomeworkTemplateCustomFieldsParams {
  page?: number;
  per_page?: number;

  /** HomeworkTemplate ID */
  homework_template_id: string;
}

export interface FetchUserTagsAsTeacherParams {
  kind?: "nationwide" | "limited";
  page?: number;
  per_page?: number;
  role_type?: UserTagTypeRoleType;
}

export interface GetTeacherPblReportTemplatesParams {
  page?: number;
  per_page?: number;
}

export interface FetchPblReportTemplateStatsAsTeacherParams {
  /** 件数指定 */
  limit?: number;

  /** グループID */
  group_affiliate_id?: string;
}

export interface GetTeacherPblReportTemplatesIdAnswersParams {
  page?: number;
  per_page?: number;

  /** グループからフィルタリングする */
  group_affiliate_id?: string;

  /** テンプレートID */
  id: string;
}

export interface GetTeacherTemplateFrameworksParams {
  page?: number;
  per_page?: number;
}

export interface GetTeacherPblReportAnswerParams {
  page?: number;
  per_page?: number;
}

export interface PblReportCommentsListParams {
  output_id: string;
  output_type: "answer" | "answer_item";
}

export interface GetTeacherPblReportItemsParams {
  /** 元となるテンプレートのID */
  template_id: string;
}

export interface FetchPblCardBookmarksAsTeacherParams {
  page?: number;
  per_page?: number;

  /** studentのaffiliate_id */
  student_id?: string;

  /** グループのID (GroupAffiliatePackage ID) */
  group_affiliate_id?: string;

  /** ransackで検索ワードから検索する */
  search_word?: string;

  /** ransackの検索する要素 */
  search_type?: string;

  /**
   * - 作成日から範囲検索する
   * - 開始日
   * - start_dateとend_dateは両方必須
   */
  start_date?: string;

  /**
   * - 作成日から範囲検索する
   * - 終了日
   * - start_dateとend_dateは両方必須
   */
  end_date?: string;

  /** - ユーザータグに紐付いている生徒の調査メモを検索する */
  user_tag_id?: string;

  /** - sort_typeで検索をかける */
  search_source_type?: PblCardBookmarkSourceType;

  /** 全ての検索に対してのワード */
  free_word?: string;

  /** studentの名前を検索 */
  user_name?: string;

  /** studentの番号を検索 */
  student_number?: string;
  search_column?: BookmarkSortedByColumn;
  search_order?: SortedByOrder;

  /** common_tag_nameのフィルタリング */
  common_tag_name?: string;
}

export interface FetchPblCardBookmarkStatsAsTeacherParams {
  /** グループID */
  group_affiliate_id?: string;
}

export interface FetchPblCardBookmarkCommentsAsTeacherParams {
  bookmark_id: string;
  page?: number;
  per_page?: number;
}

export interface GetPblCardBookmarkStampsAsTeacherParams {
  page?: number;
  per_page?: number;

  /** スタンプを作成するモデル名 */
  stampable_type: string;

  /** スタンプを作成するモデルのID */
  stampable_id: string;
  kind?: StampType;
}

export interface ListMaterialDistributionHistoryAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface ListShowMaterialDistributionHistoryAsTeacherParams {
  distribution_id?: string;
  material_id?: string;
}

export interface SearchByUserIdMaterialDistributionHistoryAsTeacherParams {
  user_id: string;
  page?: number;
  per_page?: number;
}

export interface GetSteamContentsAsTeacherParams {
  page?: number;
  per_page?: number;
  is_bookmarked?: boolean;
  is_recommended?: boolean;
  search_type?: TeacherContentSearchType;
  search_word?: string;
}

export interface SearchTeacherSteamContentsParams {
  difficulty: number;
  aspect: number;
  is_scientific: boolean;
  page?: number;
  per_page?: number;
}

export interface GetTeacherSteamCommentsParams {
  content_title?: string;
  lecture_title?: string;
  page?: number;
  per_page?: number;
}

export interface PostTeacherSteamCommentsParams {
  /** Pbl::Steam::Lecture ID */
  lecture_id: string;
}

export interface GetSteamLecturesAsTeacherParams {
  page?: number;
  per_page?: number;

  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  sl_content_id: number;
}

export interface PostTeacherSteamLecturesParams {
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  sl_content_id: number;
}

export interface GetTeacherSteamLectureCommentsParams {
  page?: number;
  per_page?: number;

  /** SteamライブラリーのレクチャーIDを指定する。 */
  id: string;
}

export interface GetSteamContentBookmarksAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetPblSteamStudentContentsAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetSteamContentRecommendationsAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetTeacherPlanItemsParams {
  page?: number;
  per_page?: number;
  status?: PblPlanItemStatus;
}

export interface GetTeacherGetClassDateListParams {
  /** 開始日: YYYY/MM/DD */
  start_date: string;

  /** 終了日: YYYY/MM/DD */
  end_date: string;

  /**
   * - 曜日： 0〜6を指定する。
   * - 0: Sunday
   * - 1: Monday
   * - 2: Tuesday
   * - 3: Wednesday
   * - 4: Thursday
   * - 5: Friday
   * - 6: Saturday
   */
  day_of_weeks: string[];
}

export interface GetTeacherPlanClassesParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
  status?: PblPlanItemStatus;

  /** Pbl::Plan::Item ID (or all in get) */
  item_id: string;
}

export interface GetPblPlanContentsAsTeacherParams {
  page?: number;
  per_page?: number;

  /** Pbl::Plan::Item ID */
  item_id: string;

  /** Pbl::Plan::Class ID */
  class_id: string;
}

export interface GetTeacherPlanStudentsParams {
  page?: number;
  per_page?: number;

  /** グループID */
  group_affiliate_id?: string;

  /** Pbl::Plan::Item ID */
  item_id: string;
}

export interface GetTeacherInquiriesParams {
  page?: number;
  per_page?: number;
}

export interface PostTeacherInquiryCommentsParams {
  /** inquiries ID */
  inquiry_id: string;
}

export interface PutTeacherInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface DeleteTeacherInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface GetTeacherSponsorInfosParams {
  page?: number;
  per_page?: number;
  search_type?: SponsorInfosSearchType;
  search_word?: string;
}

export interface FetchSponsorProjectsAsTeacherParams {
  page?: number;
  per_page?: number;
  bookmarked?: boolean;
  recommended?: boolean;
  search_type?: SponsorProjectsSearchType;
  search_word?: string;
}

export interface FetchSponsorProjectsForCarouselAsTeacherParams {
  number_of_projects?: number;
  sort_type?: SponsorProjectsSortType;
}

export interface FetchSponsorProjectListAsTeacherParams {
  page?: number;
  per_page?: number;
  professor_affiliate_id: string;
}

export interface FetchSponsorLecturesAsTeacherParams {
  page?: number;
  per_page?: number;

  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  project_id: string;
}

export interface FetchSponsorCommentListAsTeacherParams {
  page?: number;
  per_page?: number;

  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  id: string;
}

export interface FetchSponsorProjectBookmarksAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface FetchSponsorProjectBookmarkByProjectAsTeacherParams {
  project_id: string;
}

export interface FetchSponsorProjectRecommendsAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface FetchSponsorCommentsAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface PostSponsorCommentsAsTeacherParams {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  lecture_id: string;
}

export interface SponsorCommentsDeleteParams {
  page?: number;
  per_page?: number;
}

export interface GetTeacherContentCompaniesParams {
  page?: number;
  per_page?: number;
}

export interface GetSchedulesAsTeacherParams {
  /** 件数指定 */
  limit?: number;
}

export interface GetStudentLogsStudyLogsAsTeacherParams {
  page?: number;
  per_page?: number;

  /** グループID */
  group_affiliate_id?: string;
}

export interface GetStudentLogsStudyByStudentAsTeacherParams {
  page?: number;
  per_page?: number;

  /** グループID */
  group_affiliate_id?: string;
}

export interface GetStudentLogsStatsAsTeacherParams {
  student_affiliate_id: string;
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
}

export interface GetStudentLogsActivityAsTeacherParams {
  /** グループID */
  group_affiliate_id?: string;
}

export interface GetStudentLogsDeliverableListAsTeacherParams {
  page?: number;
  per_page?: number;

  /** グループID */
  group_affiliate_id?: string;

  /** 生徒名から検索 */
  search?: string;
  start_date?: string;
  end_date?: string;

  /**
   * - numberAsc : numberからソート（昇順）する
   * - numberDesc : numberからソート（降順）する
   * - nameAsc : 名前からソート（昇順）する
   * - nameDesc : 名前からソート（降順）する
   */
  sorted_by?: "numberAsc" | "numberDesc" | "nameAsc" | "nameDesc";
  tag_name?: string;
}

export interface GetStudentLogsMaterialWorksAsTeacherParams {
  page?: number;
  per_page?: number;
  student_affiliate_id: string;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudentLogsHomeworksAsTeacherParams {
  page?: number;
  per_page?: number;
  student_affiliate_id: string;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudentLogsPblAnswersAsTeacherParams {
  page?: number;
  per_page?: number;
  student_affiliate_id: string;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudentLogsLearningPackagesAsTeacherParams {
  page?: number;
  per_page?: number;
  student_affiliate_id: string;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudentLogsArticlesAsTeacherParams {
  page?: number;
  per_page?: number;
  student_affiliate_id: string;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudentLogsIdealsAsTeacherParams {
  page?: number;
  per_page?: number;
  student_affiliate_id: string;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudentLogsBookmarksAsTeacherParams {
  page?: number;
  per_page?: number;
  student_affiliate_id: string;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetRubricsAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetRubricEvaluatesAsTeacherParams {
  page?: number;
  per_page?: number;
  search_column?: RubricEvaluateSortedByColumn;
  search_order?: SortedByOrder;

  /** ルーブリックID */
  rubric_id: string;
}

export interface GetLearningTemplatePackageDistributionsAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetLearningTemplatePackageDistributionHistoriesAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacherParams {
  page?: number;
  per_page?: number;

  /** LearningTemplatePackageDistributionSetting ID */
  id: string;
}

export interface GetLearningTemplatePackageDistributionHistoriesPackagingsAsTeacherParams {
  page?: number;
  per_page?: number;

  /** LearningTemplatePackageDistributionSetting ID */
  id: string;
}

export interface GetPblBoardCampaignsAsTeacherParams {
  page?: number;
  per_page?: number;
  article_id: string;
}

export interface GetGroupAffiliatePackagesAsTeacherParams {
  page?: number;
  per_page?: number;
  homework_template_id?: string;
  distributed?: boolean;
  is_assigned_teacher?: boolean;
}

export interface PutPackagingAsTeacherParams {
  /**
   * - パッケージのID
   * - e.g. GroupAffiliatePackage の場合は、その id を指定する
   */
  package_id: string;

  /**
   * - パッケージに登録するコンテンツの Modelの名前 を指定
   * - e.g. GroupAffiliatePackage の場合は、Affiliate を指定する
   */
  packageable_type: PackagingPackageableType;

  /**
   * - パッケージに登録するコンテンツの ID を指定
   * - e.g. GroupAffiliatePackage の場合は、Affiliateのid を指定する
   */
  packageable_id: string;
}

export interface DeletePackagingAsTeacherParams {
  /**
   * - パッケージのID
   * - e.g. GroupAffiliatePackage の場合は、その id を指定する
   */
  package_id: string;

  /**
   * - パッケージに登録するコンテンツの Modelの名前 を指定
   * - e.g. GroupAffiliatePackage の場合は、Affiliate を指定する
   */
  packageable_type: string;

  /**
   * - パッケージに登録するコンテンツの ID を指定
   * - e.g. GroupAffiliatePackage の場合は、Affiliateのid を指定する
   */
  packageable_id: string;
}

export interface PostAttachedImageAsTeacherParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
}

export interface GetAttachedImageAsTeacherParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PutAttachedImageAsTeacherParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface DeleteAttachedImageAsTeacherParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface GetLimitedTimeNotificationsAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetLimitedTimeNotificationNoticeAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetDailyLogsAsTeacherParams {
  page?: number;
  per_page?: number;

  /**
   * - 開始日から範囲検索する
   * - fromとtoは両方必須
   */
  from?: string;

  /**
   * - 開始日から範囲検索する
   * - fromとtoは両方必須
   */
  to?: string;

  /** - 学習カテゴリーから検索 */
  category?: DailyLogCategory;
}

export interface GetIdealBoardTemplatesAsTeacherParams {
  page?: number;
  per_page?: number;
  search_status?: IdealBoardTemplateStatus;
  no_homework_template?: boolean;
}

export interface GetIdealBoardsAsTeacherParams {
  page?: number;
  per_page?: number;
  ideal_board_template_id?: string;
  group_affiliate_id?: string;
}

export interface GetTtCsvExportsAsTeacherParams {
  page?: number;
  per_page?: number;
  types?: TtCsvExportType[];
}

export interface PostTtCsvExportAsTeacherParams {
  sorted_by?: string;
  search_key?: string[];
  search_value?: string[];
}

export interface GetFeedbackTemplateHomeworksAsTeacherParams {
  page?: number;
  per_page?: number;
  search_type?: FeedbackTemplateSearchType;
  search_word?: string;

  /** タグ名に完全一致するものを返却する */
  tag_name?: string;
}

export interface GetFeedbackTemplateTagsAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface PostCommentImageAsTeacherParams {
  categorizable_type: CommentImageCategorizableType;
  categorizable_id: string;
}

export interface GetCommentImageAsTeacherParams {
  categorizable_type: CommentImageCategorizableType;
  categorizable_id: string;

  /** CommentImage ID */
  id: string;
}

export interface PutCommentImageAsTeacherParams {
  categorizable_type: CommentImageCategorizableType;
  categorizable_id: string;

  /** CommentImage ID */
  id: string;
}

export interface DeleteCommentImageAsTeacherParams {
  categorizable_type: CommentImageCategorizableType;
  categorizable_id: string;

  /** CommentImage ID */
  id: string;
}

export interface GetDistributionStatusesAsTeacherParams {
  page?: number;
  per_page?: number;
  homework_template_id?: string;
}

export interface GetInterviewRequestsAsTeacherParams {
  page?: number;
  per_page?: number;
  status?: InterviewRequestStatus;

  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  search_type?: InterviewRequestSearchType;

  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  search_word?: string;
}

export interface GetInterviewRequestUnreadCountsAsTeacherParams {
  request_ids?: string[];
}

export interface GetInterviewMessagesAsTeacherParams {
  page?: number;
  per_page?: number;

  /** Interview::Request ID */
  request_id: string;
}

export interface GetInterviewFeedbacksAsTeacherParams {
  page?: number;
  per_page?: number;

  /** Interview::Request ID */
  request_id: string;
}

export interface FetchAffiliateInvitationsAsTeacherParams {
  role?: UserType;
}

export interface GetGogoLinkingsAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetGogoRequestsAsTeacherParams {
  page?: number;
  per_page?: number;
  request_type?: any;

  /**
   * - idle : 調整待ち
   * - adjusted : 調整する
   * - decided : 確定
   * - cancelled : 実施なし
   */
  status?: "idle" | "adjusted" | "decided" | "cancelled";
  company_id?: string;
}

export interface GetGogoRequestUnreadCountsAsTeacherParams {
  request_ids?: string[];
}

export interface GetGogoMessagesAsTeacherParams {
  categorizable_type: GogoMessageCategorizableType;
  page?: number;
  per_page?: number;

  /** Gogo::Request ID */
  request_id: string;
}

export interface PostGogoMessageAsTeacherParams {
  categorizable_type: GogoMessageCategorizableType;

  /** Gogo::Request ID */
  request_id: string;
}

export interface PutGogoMessageAsTeacherParams {
  categorizable_type: GogoMessageCategorizableType;

  /** Gogo::Request ID */
  request_id: string;

  /** Gogo::Message ID */
  id: string;
}

export interface DeleteGogoMessageAsTeacherParams {
  categorizable_type: GogoMessageCategorizableType;

  /** Gogo::Request ID */
  request_id: string;

  /** Gogo::Message ID */
  id: string;
}

export interface GetChatRoomAsTeacherParams {
  /** 調査メモID */
  bookmark_id: string;
}

export interface PostChatRoomAsTeacherParams {
  /** 調査メモID */
  bookmark_id: string;
}

export interface GetChatRoomMessagesAsTeacherParams {
  /** 調査メモID */
  bookmark_id: string;
  page?: number;
  per_page?: number;
}

export interface PostChatRoomMessageAsTeacherParams {
  /** 調査メモID */
  bookmark_id: string;
}

export interface PutChatRoomMessageAsTeacherParams {
  /** 調査メモID */
  bookmark_id: string;

  /** Chat::RoomMessage ID */
  id: string;
}

export interface DeleteChatRoomMessageAsTeacherParams {
  /** 調査メモID */
  bookmark_id: string;

  /** Chat::RoomMessage ID */
  id: string;
}

export interface GetGogoMessageReadAsTeacherParams {
  categorizable_type: GogoMessageCategorizableType;

  /** Gogo::Request ID */
  request_id: string;
}

export interface GetCommonTagsAsTeacherParams {
  page?: number;
  per_page?: number;
  search_type?: any;
  search_word?: string;
  kind?: CommonTagKind;
  categorizable_type?: CommonTaggingCategorizableType;
}

export interface GetCommonTagsManagementTagListAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface PostCommonTaggingAsTeacherParams {
  categorizable_type: CommonTaggingCategorizableType;
  categorizable_id: string;
}

export interface DeleteCommonTaggingAsTeacherParams {
  categorizable_type: CommonTaggingCategorizableType;
  categorizable_id: string;
  common_tag_id: string;
}

export interface GetGogoProgramsAsTeacherParams {
  page?: number;
  per_page?: number;

  /** 学校側のプログラムのみを返却 */
  is_managed?: boolean;

  /** 企業側のプログラムのみを返却 */
  is_requested?: boolean;
}

export interface GetGogoProgramRequestsAsTeacherParams {
  page?: number;
  per_page?: number;

  /** Gogo::Programt ID */
  program_id: string;
}

export interface GetGogoProgramRequestMessagesAsTeacherParams {
  page?: number;
  per_page?: number;

  /** Gogo::Programt ID */
  program_id: string;

  /** Gogo::ProgramRequest ID */
  program_request_id: string;
}

export interface GetGogoProgramPreferredDatesAsTeacherParams {
  page?: number;
  per_page?: number;

  /** Gogo::Programt ID */
  program_id: string;
}

export interface GetGogoCompanyInformationListAsTeacherParams {
  page?: number;
  per_page?: number;
}

export interface GetUserStudentsAsStudentParams {
  page?: number;
  per_page?: number;
}

export interface GetUserStudentAffiliatesAsStudentParams {
  per_page?: string;
  page?: string;
}

export interface FetchMaterialsAsStudentParams {
  per_page?: number;
  page?: number;
  category_ids?: string[];
  tag_names?: string[];

  /**
   * - 検索タイプを指定する
   * - search_typeとsearch_wordは両方していする必要あり
   * - title: タイトルから検索
   * - description: 説明文から検索
   * - tag: タグから検索
   */
  search_type?: string;

  /**
   * - search_typeとsearch_wordは両方していする必要あり
   * - 検索ワードの部分一致で検索する
   */
  search_word?: string;
  search_column?: MaterialSortedByColumn;
  search_order?: SortedByOrder;
}

export interface FetchMaterialWorksAsStudentParams {
  page?: number;
  per_page?: number;

  /** 教材配信のstatus */
  status?: string;

  /**
   * - 検索タイプを指定する
   * - search_typeとsearch_wordは両方指定する必要あり
   * - title: タイトルから検索
   * - description: 説明文から検索
   * - tag: タグから検索
   */
  search_type?: string;

  /**
   * - search_typeとsearch_wordは両方指定する必要あり
   * - 検索ワードの部分一致で検索する
   */
  search_word?: string;

  /**
   * - カテゴリーのIDを指定する
   * - text カテゴリーに関連する（子孫）するものを取得する
   */
  category_id?: string;

  /**
   * - タグのIDを指定する
   * - タグIDに一致するものを取得する
   */
  tag_id?: string;
  order?: SortedByMaterialWork;
}

export interface DeleteMaterialWorkImageAsStudentParams {
  /** 画像ID */
  image_id: string;
  material_work_id: string;
}

export interface FetchPaymentsAsStudentParams {
  per_page?: number;
  page?: number;
}

export interface FetchHomeworksAsStudentParams {
  per_page?: number;
  page?: number;

  /** common_tag_nameのフィルタリング */
  common_tag_name?: string;
}

export interface GetHomeworkStatusesAsStudentParams {
  per_page?: number;
  page?: number;
  homework_ids?: string[];
}

export interface GetStudentReportsParams {
  per_page?: number;
  page?: number;
}

export interface FetchArticlesAsStudentParams {
  per_page?: number;
  page?: number;

  /** kindのフィルタリング */
  kind?: ArticleKind;

  /** statusのフィルタリング */
  status?: ArticleStatus;

  /** group_affiliate_package_idのフィルタリング */
  group_affiliate_package_id?: string;

  /** order_columnとorder_typeは両方必須 */
  order_column?: ArticleOrderColumn;

  /** order_columnとorder_typeは両方必須 */
  order_type?: ArticleOrderType;
  get_type?: ArticleGetType;

  /** common_tag_nameのフィルタリング */
  common_tag_name?: string;
}

export interface FetchArticleCommentsAsStudentParams {
  per_page?: number;
  page?: number;
  article_id: string;
}

export interface FetchArticleFeedbacksAsStudentParams {
  per_page?: number;
  page?: number;
  article_id: string;
}

export interface FetchArticleStatsAsStudentParams {
  per_page?: number;
  page?: number;
}

export interface FetchArticleWordCloudAsStudentParams {
  list_len?: number;
  offset?: number;
  min_num_of_char?: number;
}

export interface FetchPblReportAnswersAsStudentParams {
  per_page?: number;
  page?: number;
}

export interface FetchPblCardBookmarksAsStudentParams {
  page?: number;
  per_page?: number;

  /** GroupAffiliatePackageのIDを指定する */
  group_affiliate_id?: string;

  /** パラメータが存在すると自分自身のものを取得する */
  create_self?: boolean;

  /** パラメータが存在するとsource_typeを指定し取得する */
  search_source_type?: string;

  /** パラメータが存在するとcreated_atの範囲検索の開始日以降のものを取得する */
  start_date?: string;

  /** パラメータが存在するとcreated_atの範囲検索の終了日以前のものを取得する */
  end_date?: string;

  /** common_tag_nameのフィルタリング */
  common_tag_name?: string;
}

export interface FetchPblCardBookmarkCommentsAsStudentParams {
  bookmark_id: string;
  page?: number;
  per_page?: number;
}

export interface GetPblCardBookmarkStampsAsStudentParams {
  page?: number;
  per_page?: number;

  /** スタンプを作成するモデル名 */
  stampable_type: string;

  /** スタンプを作成するモデルのID */
  stampable_id: string;
  kind?: StampType;
}

export interface FetchGoogleIntegrationCoursesAsStudentParams {
  page?: number;
  per_page?: number;
}

export interface FetchGoogleIntegrationCourseWorksAsStudentParams {
  page?: number;
  per_page?: number;
  course_id: string;
}

export interface FetchGoogleIntegrationCourseWorkMaterialsAsStudentParams {
  page?: number;
  per_page?: number;
  course_id: string;
  course_work_id: string;
}

export interface GetSteamContentsAsStudentParams {
  page?: number;
  per_page?: number;
  is_bookmarked?: boolean;
  is_recommended?: boolean;
}

export interface SearchStudentSteamContentsParams {
  difficulty: number;
  aspect: number;
  is_scientific: boolean;
  page?: number;
  per_page?: number;
}

export interface PostStudentSteamLecturesParams {
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  sl_content_id: number;
}

export interface GetStudentSteamLectureCommentsParams {
  page?: number;
  per_page?: number;

  /** SteamライブラリーのレクチャーIDを指定する。 */
  id: string;
}

export interface GetStudentSteamCommentsParams {
  page?: number;
  per_page?: number;

  /** Pbl::Steam::Lecture ID */
  lecture_id: string;
}

export interface GetStudentSteamContentWorksParams {
  content_title?: string;
  lecture_title?: string;
  page?: number;
  per_page?: number;
}

export interface PostStudentSteamContentWorksParams {
  /** Steam ライブラリーのコンテンツID */
  content_id: number;
}

export interface GetSteamLectureHistoriesAsStudentParams {
  page?: number;
  per_page?: number;
}

export interface FetchSteamLectureHistoryStatsAsStudentParams {
  page?: number;
  per_page?: number;
}

export interface FetchSteamLectureHistoryCountAsStudentParams {
  /** sl_content_idを配列で渡す */
  sl_content_ids?: number[];
}

export interface GetSteamContentRecommendationsAsStudentParams {
  page?: number;
  per_page?: number;
}

export interface PostStudentHomeworkTemplateCustomValuesParams {
  /** HomeworkTemplate ID */
  homework_template_id: string;

  /** HomeworkTemplateCustomField ID */
  homework_template_custom_field_id: string;
}

export interface GetPblPlanItemsAsStudentParams {
  page?: number;
  per_page?: number;
}

export interface GetPblPlanClassesAsStudentParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;

  /** Pbl::Plan::Item ID (or all in get) */
  item_id: string;
}

export interface GetPblPlanContentsAsStudentParams {
  page?: number;
  per_page?: number;

  /** Pbl::Plan::Item ID */
  item_id: string;

  /** Pbl::Plan::Class ID */
  class_id: string;
}

export interface GetStudentInquiriesParams {
  page?: number;
  per_page?: number;
}

export interface PostStudentInquiryCommentsParams {
  /** inquiries ID */
  inquiry_id: string;
}

export interface PutStudentInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface DeleteStudentInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface GetStudentSponsorInfosParams {
  page?: number;
  per_page?: number;
  search_type?: SponsorInfosSearchType;
  search_word?: string;
}

export interface FetchSponsorProjectsAsStudentParams {
  page?: number;
  per_page?: number;
  bookmarked?: boolean;
  recommended?: boolean;
  search_type?: SponsorProjectsSearchType;
  search_word?: string;
  viewing?: boolean;
}

export interface FetchSponsorProjectsForCarouselAsStudentParams {
  number_of_projects?: number;
  sort_type?: SponsorProjectsSortType;
}

export interface FetchSponsorLecturesAsStudentParams {
  page?: number;
  per_page?: number;

  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  project_id: string;
}

export interface GetSponsorContentsAsStudentParams {
  page?: number;
  per_page?: number;
  lecture_id?: string;
}

export interface PostPblSponsorCommentAsStudentParams {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  lecture_id: string;
}

export interface GetSponsorArticleContentTemplateAsStudentParams {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsor_lecture_id: string;

  /** Pbl::Sponsor::ArticleContentTemplate ID */
  id: string;
}

export interface GetTodosAsStudentParams {
  start_date?: string;
  end_date?: string;
}

export interface GetDailyLogsAsStudentParams {
  page?: number;
  per_page?: number;

  /** すべての情報を取得するフラグ */
  all?: boolean;

  /**
   * - 開始日から範囲検索する
   * - fromとtoは両方必須
   */
  from?: string;

  /**
   * - 開始日から範囲検索する
   * - fromとtoは両方必須
   */
  to?: string;

  /** - 学習カテゴリーから検索 */
  category?: DailyLogCategory;
}

export interface GetStudyLogsAsStudentParams {
  page?: number;
  per_page?: number;

  /**
   * - 開始日から範囲検索する
   * - fromとtoは両方必須
   */
  from?: string;

  /**
   * - 開始日から範囲検索する
   * - fromとtoは両方必須
   */
  to?: string;
}

export interface GetStudyLogsDeliverableListAsStudentParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudyLogsMaterialWorksAsStudentParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudyLogsHomeworksAsStudentParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudyLogsLearningPackagesAsStudentParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudyLogsArticlesAsStudentParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudyLogsIdealsAsStudentParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetStudyLogsBookmarksAsStudentParams {
  page?: number;
  per_page?: number;
  start_date?: string;
  end_date?: string;
  tag_name?: string;
}

export interface GetLearningPackagesAsStudentParams {
  page?: number;
  per_page?: number;

  /**
   * - 検索タイプを指定する
   * - search_typeとsearch_wordは両方していする必要あり
   * - title: タイトルから検索
   * - description: 説明文から検索
   * - tag: タグから検索
   */
  search_type?: string;

  /**
   * - search_typeとsearch_wordは両方していする必要あり
   * - 検索ワードの部分一致で検索する
   */
  search_word?: string;
}

export interface GetIdealBoardsAsStudentParams {
  page?: number;
  per_page?: number;
  group_affiliate_id?: string;
}

export interface GetGroupAffiliatePackagesAsStudentParams {
  page?: number;
  per_page?: number;
}

export interface PostAttachedImageAsStudentParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
}

export interface GetAttachedImageAsStudentParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PutAttachedImageAsStudentParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface DeleteAttachedImageAsStudentParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface GetLimitedTimeNotificationNoticeAsStudentParams {
  page?: number;
  per_page?: number;
}

export interface GetInterviewRequestsAsStudentParams {
  page?: number;
  per_page?: number;
  status?: InterviewRequestStatus;

  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  search_type?: InterviewRequestSearchType;

  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  search_word?: string;
}

export interface GetInterviewRequestUnreadCountsAsStudentParams {
  request_ids?: string[];
}

export interface GetInterviewMessagesAsStudentParams {
  page?: number;
  per_page?: number;

  /** Interview::Request ID */
  request_id: string;
}

export interface GetInterviewFeedbacksAsStudentParams {
  page?: number;
  per_page?: number;

  /** Interview::Request ID */
  request_id: string;
}

export interface FetchHelpContentsAsStudentParams {
  page?: number;
  per_page?: number;
  kind: HelpMasterKind;
}

export interface GetCustomMastersAsStudentParams {
  page?: number;
  per_page?: number;

  /** custom_master_mapのhashid */
  custom_master_map_id: string;

  /** homework_template_custom_fieldのhashid */
  custom_field_id: string;

  /**
   * - keyは以下のいずれか
   * - 検索するカラムのnumber
   * - custom_master_map_relationshipのhashid
   */
  filter_infos: { key: string; value: string[] }[];
}

export interface GetCustomMastersSelectListAsStudentParams {
  page?: number;
  per_page?: number;

  /** custom_master_mapのhashid */
  custom_master_map_id: string;

  /**
   * - 検索するカラムのnumberのみ "2"
   * - filter_column_number と filter_cmmr_id はどちらか片方必須
   */
  filter_column_number?: string;

  /**
   * - フィルターするcustom_master_map_relationshipのhashid
   * - filter_column_number と filter_cmmr_id はどちらか片方必須
   */
  filter_cmmr_id?: string;

  /** 検索単語 */
  search_word?: string;
}

export interface GetChatRoomAsStudentParams {
  /** 調査メモID */
  bookmark_id: string;
}

export interface PostChatRoomAsStudentParams {
  /** 調査メモID */
  bookmark_id: string;
}

export interface GetChatRoomMessagesAsStudentParams {
  /** 調査メモID */
  bookmark_id: string;
  page?: number;
  per_page?: number;
}

export interface PostChatRoomMessageAsStudentParams {
  /** 調査メモID */
  bookmark_id: string;
}

export interface PutChatRoomMessageAsStudentParams {
  /** 調査メモID */
  bookmark_id: string;

  /** Chat::RoomMessage ID */
  id: string;
}

export interface DeleteChatRoomMessageAsStudentParams {
  /** 調査メモID */
  bookmark_id: string;

  /** Chat::RoomMessage ID */
  id: string;
}

export interface GetCommonTagsAsStudentParams {
  page?: number;
  per_page?: number;
  search_type?: any;
  search_word?: string;
  kind?: CommonTagKind;
  categorizable_type?: CommonTaggingCategorizableType;
}

export interface PostCommonTaggingAsStudentParams {
  categorizable_type: CommonTaggingCategorizableType;
  categorizable_id: string;
}

export interface DeleteCommonTaggingAsStudentParams {
  categorizable_type: CommonTaggingCategorizableType;
  categorizable_id: string;
  common_tag_id: string;
}

export interface FetchArticlesAsReviewerParams {
  per_page?: number;
  page?: number;
}

export interface FetchArticleCommentsAsReviewerParams {
  per_page?: number;
  page?: number;
  article_id: string;
}

export interface FetchArticleLikesAsReviewerParams {
  per_page?: number;
  page?: number;
  article_id: string;
}

export interface ReviewersListParams {
  page?: number;
  per_page?: number;
  non_reviewer_manager?: boolean;
}

export interface FetchReviewerAffiliatesAsReviewerParams {
  page?: number;
  per_page?: number;
}

export interface FetchNotificationsAsReviewerParams {
  page?: number;
  per_page?: number;
  sent?: string;
}

export interface FetchHomeworkTemplatesAsReviewerParams {
  page?: number;
  per_page?: number;
  company_id?: string;
}

export interface GetHomeworkTemplateAnswersAsReviewerParams {
  company_id?: string;

  /** HomeworkTemplate ID */
  id: string;
}

export interface FetchHomeworkTemplateDistributionsAsReviewerParams {
  page?: number;
  per_page?: number;
  company_id?: string;
  homework_template_title?: string;
  company_name?: string;
  submitted_start_at?: string;
  submitted_end_at?: string;
  reviewer_start_at?: string;
  reviewer_end_at?: string;
  memo?: string;
  status?: HomeworkTemplateDistributionStatus;
}

export interface GetHomeworkTemplateChunkDistributionsAsReviewerParams {
  page?: number;
  per_page?: number;
  homework_template_distribution_id: string;
}

export interface GetHomeworkChunksAsReviewerParams {
  page?: number;
  per_page?: number;
  homework_template_chunk_distribution_id: string;
}

export interface FetchHomeworksAsReviewerParams {
  page?: number;
  per_page?: number;
  status?: HomeworkStatus;
  company_id?: string;
  distribution_id?: string;
  template_id?: string;
  self_assign?: string;
  reviewer_affiliate_id?: string;
  group_affiliate_package_ids?: string[];
  user_tag_ids?: string[];
  order_type?: HomeworkOrderType;
  sort_type?: SortedByOrder;
}

export interface GetHomeworkImageDownloadUrlsAsReviewerParams {
  homework_ids: string[];
  file_content?: "file" | "answer_file" | "reviewer_file";
  template_id?: string;
  order_type?: HomeworkOrderType;
  sort_type?: SortedByOrder;
}

export interface FetchSubmitHomeworkFilesRequestsAsReviewerParams {
  per_page?: number;
  page?: number;
  template_id?: string;

  /** ステータスを基にフィルターをかける */
  statuses?: SubmitHomeworkFilesRequestStatus[];
}

export interface GetReviewerInquiriesParams {
  page?: number;
  per_page?: number;
}

export interface PostReviewerInquiryCommentsParams {
  /** inquiries ID */
  inquiry_id: string;
}

export interface PutReviewerInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface DeleteReviewerInquiryCommentsIdParams {
  /** inquiries ID */
  inquiry_id: string;

  /** inquiries ID */
  id: string;
}

export interface PostAttachedImageAsReviewerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
}

export interface GetAttachedImageAsReviewerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PutAttachedImageAsReviewerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface DeleteAttachedImageAsReviewerParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface GetFeedbackTemplateHomeworksAsReviewerParams {
  page?: number;
  per_page?: number;
  search_type?: FeedbackTemplateSearchType;
  search_word?: string;

  /** タグ名に完全一致するものを返却する */
  tag_name?: string;
}

export interface GetCategorizableCommentsAsReviewerParams {
  categorizable_type: CategorizableCommentCategorizableType;

  /** categorizable_type ID */
  categorizable_id: string;
  page?: number;
  per_page?: number;
}

export interface PostCategorizableCommentAsReviewerParams {
  categorizable_type: CategorizableCommentCategorizableType;

  /** categorizable_type ID */
  categorizable_id: string;
}

export interface PutCategorizableCommentAsReviewerParams {
  categorizable_type: CategorizableCommentCategorizableType;
  categorizable_id: string;

  /** categorizable comment ID */
  id: string;
}

export interface DeleteCategorizableCommentAsReviewerParams {
  categorizable_type: CategorizableCommentCategorizableType;
  categorizable_id: string;

  /** categorizable comment ID */
  id: string;
}

export interface GetFeedbackTemplateTagsAsReviewerParams {
  page?: number;
  per_page?: number;
}

export interface GetTtCsvExportsAsReviewerParams {
  page?: number;
  per_page?: number;
  types?: TtCsvExportType[];
}

export interface PostTtCsvExportAsReviewerParams {
  sorted_by?: string;
  search_key?: string[];
  search_value?: string[];
}

export interface PostCommentImageAsReviewerParams {
  categorizable_type: CommentImageCategorizableType;
  categorizable_id: string;
}

export interface GetCommentImageAsReviewerParams {
  categorizable_type: CommentImageCategorizableType;
  categorizable_id: string;

  /** CommentImage ID */
  id: string;
}

export interface PutCommentImageAsReviewerParams {
  categorizable_type: CommentImageCategorizableType;
  categorizable_id: string;

  /** CommentImage ID */
  id: string;
}

export interface DeleteCommentImageAsReviewerParams {
  categorizable_type: CommentImageCategorizableType;
  categorizable_id: string;

  /** CommentImage ID */
  id: string;
}

export interface DeleteApprovingAsReviewerParams {
  categorizable_type: ApprovingCategorizableType;
  categorizable_id: string;

  /** Approving ID */
  id: string;
}

export interface GetArticlesAsContentProviderParams {
  per_page?: number;
  page?: number;
}

export interface FetchArticleWordCloudAsContentProviderParams {
  list_len?: number;
  offset?: number;
  min_num_of_char?: number;
}

export interface GetArticleCommentsAsContentProviderParams {
  per_page?: number;
  page?: number;

  /** 探究成果物のID */
  article_id: string;
}

export interface GetArticleLikesAsContentProviderParams {
  per_page?: number;
  page?: number;

  /** 探究成果物のID */
  article_id: string;
}

export interface GetSponsorDeliverySchoolsAsContentProviderParams {
  page?: number;
  per_page?: number;
}

export interface GetContentProviderSponsorInfosParams {
  page?: number;
  per_page?: number;
  search_type?: SponsorInfosSearchType;
  search_word?: string;
}

export interface FetchSponsorLecturesAsContentProviderParams {
  page?: number;
  per_page?: number;

  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  project_id: string;
}

export interface GetPblBoardInfoAsContentProviderParams {
  /** 文字列のtrueを設定をすると探究成果一覧を取得する */
  preview?: string;

  /** 探究成果一覧のページング */
  page?: number;

  /** 探究成果一覧のページング */
  per_page?: number;
}

export interface GetPblBoardCampaignsAsContentProviderParams {
  page?: number;
  per_page?: number;
}

export interface GetPblBoardCampaignRequestAsContentProviderParams {
  page?: number;
  per_page?: number;
  search_status?: PblBoardCampaignRequestStatus;

  /** 探究ボードの探究成果募集 ID */
  campaign_id: string;
}

export interface GetPblBoardArticlesAsContentProviderParams {
  page?: number;
  per_page?: number;
}

export interface GetPblBoardArticlesWordCloudAsContentProviderParams {
  list_len?: number;
  offset?: number;
  min_num_of_char?: number;
}

export interface GetPblBoardFootersAsContentProviderParams {
  page?: number;
  per_page?: number;

  /** 探究ボードのコンテンツタイプ ID */
  item_id: string;
}

export interface GetFeedbackTemplatesAsContentProviderParams {
  page?: number;
  per_page?: number;
}

export interface PostAttachedImageAsContentProviderParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
}

export interface GetAttachedImageAsContentProviderParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PutAttachedImageAsContentProviderParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface DeleteAttachedImageAsContentProviderParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface GetGogoRequestsAsContentProviderParams {
  page?: number;
  per_page?: number;
  request_type?: any;
  status?: any;
  company_id?: string;
}

export interface GetGogoMessagesAsContentProviderParams {
  categorizable_type: GogoMessageCategorizableType;
  page?: number;
  per_page?: number;

  /** Gogo::Request ID */
  request_id: string;
}

export interface PostGogoMessageAsContentProviderParams {
  categorizable_type: GogoMessageCategorizableType;

  /** Gogo::Request ID */
  request_id: string;
}

export interface PutGogoMessageAsContentProviderParams {
  categorizable_type: GogoMessageCategorizableType;

  /** Gogo::Request ID */
  request_id: string;

  /** Gogo::Message ID */
  id: string;
}

export interface DeleteGogoMessageAsContentProviderParams {
  categorizable_type: GogoMessageCategorizableType;

  /** Gogo::Request ID */
  request_id: string;

  /** Gogo::Message ID */
  id: string;
}

export interface GetGogoRequestUnreadCountsAsContentProviderParams {
  request_ids?: string[];
}

export interface GetGogoMessageReadAsContentProviderParams {
  categorizable_type: GogoMessageCategorizableType;

  /** Gogo::Request ID */
  request_id: string;
}

export interface GetGogoProgramsAsContentProviderParams {
  page?: number;
  per_page?: number;

  /** 企業側のプログラムのみを返却 */
  is_managed?: boolean;

  /** 学校側のプログラムのみを返却 */
  is_requested?: boolean;
}

export interface GetGogoProgramRequestMessagesAsContentProviderParams {
  page?: number;
  per_page?: number;

  /** Gogo::Programt ID */
  program_id: string;

  /** Gogo::ProgramRequest ID */
  program_request_id: string;
}

export interface GetGogoProgramPreferredDatesAsContentProviderParams {
  company_id: string;
  page?: number;
  per_page?: number;
}

export interface GetGogoProgramRequestsAsContentProviderParams {
  /** Gogo::Linking で紐づいている学校のみ */
  company_id: string;
  page?: number;
  per_page?: number;

  /** Gogo::ProgramPreferredDate ID */
  program_preferred_date_id: string;
}

export interface PostGogoProgramRequestAsContentProviderParams {
  /** Gogo::Linking で紐づいている学校のみ */
  company_id: string;

  /** Gogo::ProgramPreferredDate ID */
  program_preferred_date_id: string;
}

export interface GetPblBoardInfosAsPublicParams {
  /** 探究ボード一覧のページング */
  page?: number;

  /** 探究ボード一覧のページング */
  per_page?: number;

  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  search_type?: PblBoardInfoSearchType;

  /** 検索単語、search_typeとsearch_wordは同時に指定する必要有り */
  search_word?: string;

  /** 会社の種類で絞り込み */
  company_type: PblBoardInfoCompanyType;

  /** carousel表示で利用 */
  is_random?: boolean;
}

export interface GetPblBoardInfoAsPublicParams {
  company_id: number;

  /** 探究成果一覧のページング */
  page?: number;

  /** 探究成果一覧のページング */
  per_page?: number;
}

export interface GetPblBoardArticleAsPublicParams {
  /** Company ID */
  company_id: string;

  /** Article ID */
  id: string;
}

export interface GetPblBoardArticlesWordCloudAsPublicParams {
  list_len?: number;
  offset?: number;
  min_num_of_char?: number;

  /** Company ID */
  company_id: string;
}

export interface PostAttachedImageAsPublicParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
}

export interface GetAttachedImageAsPublicParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface PutAttachedImageAsPublicParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export interface DeleteAttachedImageAsPublicParams {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;

  /** AttachedImage ID */
  id: string;
}

export type RequestParams = Omit<RequestInit, "body" | "method"> & {
  secure?: boolean;
};

export type RequestQueryParamsType = Record<string | number, any>;

interface ApiConfig<SecurityDataType> {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
}

/** Overrided Promise type. Needs for additional typings of `.catch` callback */
type TPromise<ResolveType, RejectType = any> = Omit<Promise<ResolveType>, "then" | "catch"> & {
  then<TResult1 = ResolveType, TResult2 = never>(
    onfulfilled?: ((value: ResolveType) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: RejectType) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): TPromise<TResult1 | TResult2, RejectType>;
  catch<TResult = never>(
    onrejected?: ((reason: RejectType) => TResult | PromiseLike<TResult>) | undefined | null,
  ): TPromise<ResolveType | TResult, RejectType>;
};

interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

enum BodyType {
  Json,
  FormData,
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = config.url.API_URL;
  private securityData: SecurityDataType = null as any;
  private securityWorker: null | ApiConfig<SecurityDataType>["securityWorker"] = null;

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}, private axios: AxiosInstance = defaultAxios) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
    [BodyType.FormData]: (input: any) =>
      Object.keys(input).reduce((data, key) => {
        data.append(key, input[key]);
        return data;
      }, new FormData()),
  };

  private mergeRequestOptions(params: RequestParams, securityParams?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(response: Response): Promise<HttpResponse<T, E>> => {
    const r = response as HttpResponse<T, E>;
    r.data = (null as unknown) as T;
    r.error = (null as unknown) as E;

    return response
      .json()
      .then((data) => {
        if (r.ok) {
          r.data = data;
        } else {
          r.error = data;
        }
        return r;
      })
      .catch((e) => {
        r.error = e;
        return r;
      });
  };

  public request = <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean,
  ) => {
    const requestUrl = `${this.baseUrl}${path}`;
    const secureOptions =
      (secureByDefault || secure) && this.securityWorker ? this.securityWorker(this.securityData) : {};
    const requestOptions = {
      ...this.mergeRequestOptions(params, secureOptions),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    };

    return this.axios.request<T>({
      url: requestUrl,
      ...requestOptions,
      data: body,
      method: requestOptions.method as Method,
    });
  };
}

/**
 * @title TimeTact-v1
 * @version 1.0
 * @baseUrl http://localhost:3000/{api_version}
 * TimeTact API
 */
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  affiliateInvitations = {
    /**
     * @description Fetch an affiliate invitation with the invitation token
     *
     * @tags AffiliateInvitation
     * @name FetchAffiliateInvitationWithToken
     * @summary AffiliateInvitation Index
     * @request GET:/affiliate_invitations/with_token/{token}
     * @response `200` `AffiliateInvitation` OK
     * @response `400` `any` Bad Request
     * @response `401` `any` Unauthorized
     * @response `500` `any` Internal Server Error
     */
    fetchAffiliateInvitationWithToken: (token: string, params?: RequestParams) =>
      this.request<AffiliateInvitation, any>(`/affiliate_invitations/with_token/${token}`, "GET", params),
  };
  authorizations = {
    /**
     * No description
     *
     * @tags SSO
     * @name AuthorizationsList
     * @request GET:/authorizations
     * @response `302` `any` ### 認証に成功した場合 RP(マイナビ)のリダイレクトURLに飛ばします。 例) `https://mynavi.jp?code=**********` ### 失敗した場合 /auth/errorに飛ばします。 例) `localhost:3002/auth/error?message=ログインされていません。`
     */
    authorizationsList: (query: AuthorizationsListParams, params?: RequestParams) =>
      this.request<any, any>(`/authorizations${this.addQueryParams(query)}`, "GET", params),
  };
  auth = {
    /**
     * @description メールアドレスとパスワードを利用してログインを実施
     *
     * @tags Auth
     * @name PostAuthSignIn
     * @summary サインイン
     * @request POST:/auth/sign_in
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postAuthSignIn: (
      data: {
        email: string;
        password: string;
        recaptcha_token: string;
        is_recaptcha_v2?: boolean;
        terms_accepted: boolean;
        app_name?: AppName;
        remember_me?: boolean;
      },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/auth/sign_in`, "POST", params, data),

    /**
     * @description ログアウト処理を行い、トークンを削除
     *
     * @tags Auth
     * @name SignOutDelete
     * @summary ログアウト
     * @request DELETE:/auth/sign_out
     * @response `200` `any` OK
     * @response `404` `any` Not Found
     */
    signOutDelete: (params?: RequestParams) => this.request<any, any>(`/auth/sign_out`, "DELETE", params),

    /**
     * @description AffiliateInvitationを利用してユーザー登録
     *
     * @tags Auth
     * @name SignupWithInvitation
     * @summary AffiliateInvitationを利用してユーザー登録
     * @request POST:/auth/signup_with_invitation
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    signupWithInvitation: (
      data: {
        invitation_id: string;
        email: string;
        first_name: string;
        last_name: string;
        nickname?: string;
        parent_first_name?: string;
        parent_last_name?: string;
        birth_date?: string;
        phone?: string;
        postcode?: string;
        prefecture?: string;
        address1?: string;
        address2?: string;
        password: string;
        recaptcha_token: string;
        is_recaptcha_v2?: boolean;
        terms_accepted: boolean;
        app_name?: AppName;
      },
      params?: RequestParams,
    ) => this.request<{ message?: string }, { message?: string }>(`/auth/signup_with_invitation`, "POST", params, data),

    /**
     * @description パスワードを忘れたユーザがEmailアドレスを送信し、トークンを発行します。 セキュリティ上、該当のEmailアドレスがない場合でもメールは送信せず、200を返します。
     *
     * @tags Auth
     * @name PostAuthPassword
     * @summary パスワードリセットのためのトークンを発行
     * @request POST:/auth/password
     * @response `200` `{ success?: boolean, message?: string }` OK
     * @response `400` `{ message?: string }` Bad Request
     */
    postAuthPassword: (data: { email?: string; redirect_url?: string; app_name?: AppName }, params?: RequestParams) =>
      this.request<{ success?: boolean; message?: string }, { message?: string }>(
        `/auth/password`,
        "POST",
        params,
        data,
      ),

    /**
     * @description パスワードと確認パスワードをトークンと合わせて送信し、パスワードを変更する。
     *
     * @tags Auth
     * @name PutAuthPassword
     * @summary パスワードリセット
     * @request PUT:/auth/password
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `any` Unauthorized
     * @response `422` `ErrorsWithKeyMessage`
     */
    putAuthPassword: (data: { password?: string; password_confirmation?: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | ErrorsWithKeyMessage>(`/auth/password`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Auth
     * @name GetAuthInvitationTokenStatus
     * @summary トークンのステータスを取得
     * @request GET:/auth/invitation/token_status
     * @response `200` `{ invitation_token: string, status?: string }` OK
     */
    getAuthInvitationTokenStatus: (params?: RequestParams) =>
      this.request<{ invitation_token: string; status?: string }, any>(`/auth/invitation/token_status`, "GET", params),

    /**
     * @description - 再招待して招待メール（トークン再発行）を送付する。 - app_nameにmylsilが存在する場合は指定すること
     *
     * @tags Auth
     * @name PutResendInvitationAsAuth
     * @summary 再招待して招待メール（トークン再発行）を送付する。
     * @request PUT:/auth/invitation/resend_invitation
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putResendInvitationAsAuth: (data: { invitation_token: string; app_name?: AppName }, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/auth/invitation/resend_invitation`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 招待URLからアカウント登録時のメール中の「アカウントを有効にする」ボタンを押すと遷移する。 トークンが有効であれば成功画面(email_confirmed)に失敗であればエラー画面(oauth/error)に遷移する。
     *
     * @tags Auth
     * @name ConfirmationList
     * @request GET:/auth/confirmation
     * @response `302` `any` Redirect
     */
    confirmationList: (query: ConfirmationListParams, params?: RequestParams) =>
      this.request<any, any>(`/auth/confirmation${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description invitationのリダイレクト先にてログイン用パスワードを設定するためのAPI
     *
     * @tags Auth
     * @name PutAuthInvitation
     * @summary 招待をAcceptし、ログイン用パスワードを設定
     * @request PUT:/auth/invitation
     * @response `200` `any` OK
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putAuthInvitation: (
      data: { invitation_token: string; password: string; password_confirmation: string; app_name?: AppName },
      params?: RequestParams,
    ) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/auth/invitation`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description ID tokenを検証して招待されたユーザであればログインできます。Googleログインを一度でもすると、メールアドレスでのログインはできなくなります。
     *
     * @tags Auth
     * @name RequestCreate
     * @summary Googleログイン
     * @request POST:/auth/request
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    requestCreate: (
      data: { id_token?: string; access_token?: string; remember_me?: boolean },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/auth/request`, "POST", params, data),

    /**
     * @description ログインに5回失敗し、アカウントロックされたユーザをロック解除する。現在の仕様ではロック解除後ログイン画面に遷移する様に実装。二回同じトークンのリクエストが送られた場合はログイン画面に直接遷移します。
     *
     * @tags Auth
     * @name UnlockList
     * @summary アカウントロック解除
     * @request GET:/auth/unlock
     * @response `200` `any` OK
     */
    unlockList: (query: UnlockListParams, params?: RequestParams) =>
      this.request<any, any>(`/auth/unlock${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - microsoft_oauth2_url を返却 - code が存在する場合、access_tokenが返却される
     *
     * @tags Auth
     * @name GetAuthMicrosoftOauth2Callback
     * @summary Microsoftログイン
     * @request GET:/auth/microsoft_oauth2callback
     * @response `200` `MicrosoftLoginBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getAuthMicrosoftOauth2Callback: (query: GetAuthMicrosoftOauth2CallbackParams, params?: RequestParams) =>
      this.request<MicrosoftLoginBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/auth/microsoft_oauth2callback${this.addQueryParams(query)}`,
        "GET",
        params,
      ),
  };
  roles = {
    /**
     * @description 自身が保有するロールのリストを返却
     *
     * @tags Roles
     * @name GetRoles
     * @summary 自身のロールを取得
     * @request GET:/roles
     * @response `200` `{ roles: (string)[], company_id: string }` OK
     * @response `401` `any` Unauthorized
     */
    getRoles: (params?: RequestParams) =>
      this.request<{ roles: string[]; company_id: string }, any>(`/roles`, "GET", params),
  };
  common = {
    /**
     * @description 現在のユーザ情報の取得
     *
     * @tags CurrentUser
     * @name GetCommonCurrentUserMe
     * @summary fetch currentUser
     * @request GET:/common/current_user/me
     * @response `200` `CurrentUserInfo` OK
     * @response `401` `any` Unauthorized
     * @response `403` `Forbidden`
     */
    getCommonCurrentUserMe: (params?: RequestParams) =>
      this.request<CurrentUserInfo, Forbidden>(`/common/current_user/me`, "GET", params),

    /**
     * @description - 郵便番号から住所を返却
     *
     * @tags Common/Addresses
     * @name GetAddressAsCommon
     * @summary 郵便番号から住所を返却
     * @request GET:/common/addresses/search/{zipcode}
     * @response `200` `AddressBaseCommon` 200 OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getAddressAsCommon: (zipcode: string, params?: RequestParams) =>
      this.request<
        AddressBaseCommon,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/common/addresses/search/${zipcode}`, "GET", params),

    /**
     * @description Commonly used by TimeTact user to post a new inquiry and support request. Authentication required but role permission is not required.
     *
     * @tags Common/Inquiries
     * @name PostCommonInquiries
     * @summary [Common] Post a new inquiry
     * @request POST:/common/inquiries
     * @response `201` `{ message?: string }` Created
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `any` Unauthorized
     * @response `422` `any` Unprocessable Entity (WebDAV)
     */
    postCommonInquiries: (data: PostInquiryRequestBodyAsCommon, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string }>(`/common/inquiries`, "POST", params, data),

    /**
     * @description お問い合せのステータスと担当者のみを変更可能
     *
     * @tags Common/Inquiries
     * @name PutCommonInquiries
     * @summary お問い合せの情報更新
     * @request PUT:/common/inquiries/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `{ message?: string }` Bad Request
     */
    putCommonInquiries: (id: string, data: PutInquiryRequestBodyAsCommon, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string }>(`/common/inquiries/${id}`, "PUT", params, data),

    /**
     * @description Get all affiliates for the requesting user
     *
     * @tags Common/Affiliates
     * @name GetCommonAffiliates
     * @summary [Common] Index all affiliates
     * @request GET:/common/affiliates
     * @response `200` `AffiliateBaseAsCommon` OK
     * @response `401` `Unauthorized`
     */
    getCommonAffiliates: (params?: RequestParams) =>
      this.request<AffiliateBaseAsCommon, Unauthorized>(`/common/affiliates`, "GET", params),

    /**
     * @description By passing company_id that user want to switch, this will switch active affiliate
     *
     * @tags Common/Affiliates
     * @name GetCommonAffiliatesSwitch
     * @summary Switch active affiliate
     * @request POST:/common/affiliates/switch
     * @response `200` `{ message?: string }` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `500` `any` Internal Server Error
     */
    getCommonAffiliatesSwitch: (data: { company_id?: string; role_id?: string }, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized>(
        `/common/affiliates/switch`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Schedules
     * @name SchedulesList
     * @request GET:/common/schedules
     * @response `200` `(ScheduleBase)[]` privateフラグがtrueのときには、startとendのみ返します。
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    schedulesList: (query: SchedulesListParams, params?: RequestParams) =>
      this.request<ScheduleBase[], Unauthorized | Forbidden | { error?: string }>(
        `/common/schedules${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Schedules
     * @name SchedulesCreate
     * @request POST:/common/schedules
     * @response `201` `{ message?: string }` created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    schedulesCreate: (data: PostScheduleRequestBodyAsCommon, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden | { error?: string } | { message?: string }>(
        `/common/schedules`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Schedules
     * @name SchedulesDetail
     * @request GET:/common/schedules/{id}
     * @response `200` `ScheduleBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    schedulesDetail: (id: string, params?: RequestParams) =>
      this.request<ScheduleBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/schedules/${id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Schedules
     * @name SchedulesUpdate
     * @request PUT:/common/schedules/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    schedulesUpdate: (id: string, data: PutScheduleRequestBodyAsCommon, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | { message?: string }
      >(`/common/schedules/${id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Schedules
     * @name SchedulesDelete
     * @request DELETE:/common/schedules/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    schedulesDelete: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/schedules/${id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags Schedules
     * @name SchedulesSearchList
     * @request GET:/common/schedules/search
     * @response `200` `(ScheduleBase)[]` privateフラグがtrueのときには、startとendのみ返します。
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    schedulesSearchList: (query: SchedulesSearchListParams, params?: RequestParams) =>
      this.request<ScheduleBase[], Unauthorized | Forbidden | { error?: string }>(
        `/common/schedules/search${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - スケジュールを配列で保存する
     *
     * @tags Schedules
     * @name PatchSchedulesBatchCreate
     * @summary スケジュールを配列で保存
     * @request PATCH:/common/schedules/batch_create
     * @response `201` `(ScheduleBatchBase)[]` created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    patchSchedulesBatchCreate: (data: BatchCreateScheduleRequestBodyAsCommon, params?: RequestParams) =>
      this.request<ScheduleBatchBase[], Unauthorized | Forbidden | { error?: string } | { message?: string }>(
        `/common/schedules/batch_create`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description - スケジュールを配列で更新する
     *
     * @tags Schedules
     * @name PatchSchedulesBatchUpdate
     * @summary スケジュールを配列で更新
     * @request PATCH:/common/schedules/batch_update
     * @response `202` `(ScheduleBatchBase)[]` accepted
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    patchSchedulesBatchUpdate: (data: BatchUpdateScheduleRequestBodyAsCommon, params?: RequestParams) =>
      this.request<ScheduleBatchBase[], Unauthorized | Forbidden | { error?: string }>(
        `/common/schedules/batch_update`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description 学年の一覧取得
     *
     * @tags Common/GradeMaster
     * @name GradeMastersList
     * @request GET:/common/grade_masters
     * @response `200` `({ id?: string, grade?: string, grade_name?: string, graduation_year?: string })[]` OK
     * @response `401` `Unauthorized`
     */
    gradeMastersList: (params?: RequestParams) =>
      this.request<{ id?: string; grade?: string; grade_name?: string; graduation_year?: string }[], Unauthorized>(
        `/common/grade_masters`,
        "GET",
        params,
      ),

    /**
     * @description ヘルプページ一覧を返却
     *
     * @tags Common/Category
     * @name CategoriesList
     * @request GET:/common/categories
     * @response `200` `{ texts: (TextCategoryInfo)[], grades: (GradeCategoryInfo)[], subjects: (SubjectCategoryInfo)[], major_categories: (MajorCategoryInfo)[], middle_categories: (MiddleCategoryInfo)[], minor_categories: (MinorCategoryInfo)[] }` OK
     */
    categoriesList: (params?: RequestParams) =>
      this.request<
        {
          texts: TextCategoryInfo[];
          grades: GradeCategoryInfo[];
          subjects: SubjectCategoryInfo[];
          major_categories: MajorCategoryInfo[];
          middle_categories: MiddleCategoryInfo[];
          minor_categories: MinorCategoryInfo[];
        },
        any
      >(`/common/categories`, "GET", params),

    /**
     * @description 対象カテゴリのヘルプページ教材を返却
     *
     * @tags Common/Material
     * @name MaterialsList
     * @request GET:/common/materials
     * @response `200` `{ materials: (MaterialList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `404` `{ error?: string }`
     */
    materialsList: (query: MaterialsListParams, params?: RequestParams) =>
      this.request<{ materials: MaterialList[]; total_count: number }, BadRequest | Unauthorized | { error?: string }>(
        `/common/materials${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 指定されたヘルプページの詳細を返却
     *
     * @tags Common/Material
     * @name MaterialsDetail
     * @request GET:/common/materials/{id}
     * @response `200` `MaterialDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `404` `{ error?: string }`
     */
    materialsDetail: (id: string, params?: RequestParams) =>
      this.request<MaterialDetail, BadRequest | Unauthorized | { error?: string }>(
        `/common/materials/${id}`,
        "GET",
        params,
      ),

    /**
     * @description admin/super_owner/ownerでユーザーに再度招待メールを送る super_ownerは子会社のユーザーのみ再招待できる ownerは自分の会社のユーザーのみ再招待できる 既にアクティベートしているユーザーの再招待はできない
     *
     * @tags Common/InviteUsers
     * @name InviteUserMailersCreate
     * @summary ユーザー再招待メールを送る
     * @request POST:/common/invite_user_mailers
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    inviteUserMailersCreate: (data: UserInviteUsersRequestBodyAsCommon, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/common/invite_user_mailers`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Common/TermsAccepts
     * @name TermsAcceptsUpdate
     * @request PUT:/common/terms_accepts/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    termsAcceptsUpdate: (id: string, data: { terms_accepted?: boolean }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/common/terms_accepts/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @name PostCommonKpisSponsorPageViews
     * @summary [Common] Post a view state for KPI monitoring
     * @request POST:/common/kpis/sponsor_page_views
     * @response `200` `any` OK(but not recorded)
     * @response `201` `{ message?: string }` Created
     * @response `401` `any` Unauthorized
     */
    postCommonKpisSponsorPageViews: (data: SponsorPageViewsRequestBody, params?: RequestParams) =>
      this.request<any, any>(`/common/kpis/sponsor_page_views`, "POST", params, data),

    /**
     * @description - User を更新 - first_time_loginのフラグのみ更新可能
     *
     * @tags Common/Users
     * @name PutControllerAsRole
     * @summary ユーザ情報（first_time_loginのみ）を更新
     * @request PUT:/common/users
     * @response `202` `{ user?: { first_time_login?: boolean } }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putControllerAsRole: (data: { first_time_login: boolean }, params?: RequestParams) =>
      this.request<
        { user?: { first_time_login?: boolean } },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/common/users`, "PUT", params, data),

    /**
     * @description - Common限定 AttachedImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags Common/AttachedImages
     * @name PostAttachedImageAsCommon
     * @summary 画像 を登録
     * @request POST:/common/attached_images
     * @response `201` `AttachedImageBase` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    postAttachedImageAsCommon: (
      query: PostAttachedImageAsCommonParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | { error?: string }>(
        `/common/attached_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Common限定 AttachedImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Common/AttachedImages
     * @name GetAttachedImageAsCommon
     * @summary 画像 の詳細を返却
     * @request GET:/common/attached_images/{id}
     * @response `200` `AttachedImageBase` OK
     * @response `400` `BadRequest`
     */
    getAttachedImageAsCommon: ({ id, ...query }: GetAttachedImageAsCommonParams, params?: RequestParams) =>
      this.request<AttachedImageBase, BadRequest>(
        `/common/attached_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperCommon限定 AttachedImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Common/AttachedImages
     * @name PutAttachedImageAsCommon
     * @summary 画像 を更新
     * @request PUT:/common/attached_images/{id}
     * @response `202` `AttachedImageBase` Accepted
     * @response `400` `BadRequest`
     */
    putAttachedImageAsCommon: (
      { id, ...query }: PutAttachedImageAsCommonParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest>(
        `/common/attached_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Common限定 AttachedImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Common/AttachedImages
     * @name DeleteAttachedImageAsCommon
     * @summary 画像 を削除
     * @request DELETE:/common/attached_images/{id}
     * @response `200` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     */
    deleteAttachedImageAsCommon: ({ id, ...query }: DeleteAttachedImageAsCommonParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest>(
        `/common/attached_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - "新しいお知らせ10件と総数を返却"
     *
     * @tags Common/NewNotificationCount
     * @name GetNewNotificationCountAsCommon
     * @summary 新しいお知らせ10件と総数を返却
     * @request GET:/common/new_notifications
     * @response `200` `NotificationCounts` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getNewNotificationCountAsCommon: (params?: RequestParams) =>
      this.request<NotificationCounts, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/new_notifications`,
        "GET",
        params,
      ),

    /**
     * @description - "未読のお知らせを更新する"
     *
     * @tags Common/NewNotificationCount
     * @name PatchNewNotificationCountAsCommon
     * @summary 未読のお知らせを更新する
     * @request PATCH:/common/new_notifications/update_read_and_check
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    patchNewNotificationCountAsCommon: (params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/new_notifications/update_read_and_check`,
        "PATCH",
        params,
      ),

    /**
     * @description - "連携したGoogle Driveからファイル一覧を返却"
     *
     * @tags Common/TtGoogleDrives
     * @name GetTtGoogleDrivesAsCommon
     * @summary 連携したGoogle Driveからファイル一覧を返却
     * @request GET:/common/tt_google/drives
     * @response `200` `GoogleDriveFileList` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtGoogleDrivesAsCommon: (query: GetTtGoogleDrivesAsCommonParams, params?: RequestParams) =>
      this.request<GoogleDriveFileList, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_google/drives${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Formats a Google Workspace document to PDF encoded to base64. - Note that the exported content is limited to 10MB.
     *
     * @tags Common/TtGoogleDrives
     * @name GetTtGoogleDrivesFormatAsCommon
     * @request GET:/common/tt_google/drives/format
     * @response `200` `GoogleDriveFormat` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtGoogleDrivesFormatAsCommon: (query: GetTtGoogleDrivesFormatAsCommonParams, params?: RequestParams) =>
      this.request<GoogleDriveFormat, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_google/drives/format${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Download a file as base64. - Note that the download content is limited to 50MB.
     *
     * @tags Common/TtGoogleDrives
     * @name GetTtGoogleDrivesDownloadAsCommon
     * @request GET:/common/tt_google/drives/download
     * @response `200` `GoogleDriveFormat` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtGoogleDrivesDownloadAsCommon: (query: GetTtGoogleDrivesDownloadAsCommonParams, params?: RequestParams) =>
      this.request<GoogleDriveFormat, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_google/drives/download${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - "Sync Google Drive to TimeTact"
     *
     * @tags Common/TtGoogleDrives
     * @name GetTtGoogleDrivesOauth2CallbackAsCommon
     * @summary Sync Google Drive to TimeTact
     * @request GET:/common/tt_google/drives/oauth2callback
     * @response `200` `GoogleDriveOauth2Url` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtGoogleDrivesOauth2CallbackAsCommon: (
      query: GetTtGoogleDrivesOauth2CallbackAsCommonParams,
      params?: RequestParams,
    ) =>
      this.request<GoogleDriveOauth2Url, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_google/drives/oauth2callback${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - "Google Driveの連携を解除する"
     *
     * @tags Common/TtGoogleDrives
     * @name PostTtGoogleDrivesRevokeAsCommon
     * @summary Google Driveの連携を解除する
     * @request POST:/common/tt_google/drives/revoke
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postTtGoogleDrivesRevokeAsCommon: (params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_google/drives/revoke`,
        "POST",
        params,
      ),

    /**
     * @description - "Google Driveとの連携情報を返却する"
     *
     * @tags Common/TtGoogleDrives
     * @name GetTtGoogleDrivesConnectionAsCommon
     * @summary Google Driveとの連携情報を返却する
     * @request GET:/common/tt_google/drives/connection
     * @response `200` `GoogleDriveConnection` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtGoogleDrivesConnectionAsCommon: (params?: RequestParams) =>
      this.request<GoogleDriveConnection, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_google/drives/connection`,
        "GET",
        params,
      ),

    /**
     * @description - "連携したOne Driveからファイル一覧を返却" - how to use orderby: https://learn.microsoft.com/en-us/graph/query-parameters?tabs=http#orderby-parameter - how to use filter: https://learn.microsoft.com/en-us/graph/filter-query-parameter?tabs=http
     *
     * @tags Common/TtMicrosoftDrives
     * @name GetTtMicrosoftDrivesAsCommon
     * @summary 連携したOne Driveからファイル一覧を返却
     * @request GET:/common/tt_microsoft/drives
     * @response `200` `OneDriveFileList` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtMicrosoftDrivesAsCommon: (query: GetTtMicrosoftDrivesAsCommonParams, params?: RequestParams) =>
      this.request<OneDriveFileList, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_microsoft/drives${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - One DriveのファイルIDからファイルを特定し、PDFに変換、base64形式で返却する - サポートされている変換元の拡張子: doc、docx、epub、eml、htm、html、md、msg、odp、ods、odt、pps、ppsx、ppt、pptx、rtf、tif、tiff、xls、xlsm、xlsx
     *
     * @tags Common/TtMicrosoftDrives
     * @name GetTtMicrosoftDrivesFormatAsCommon
     * @summary One DriveのファイルIDからファイルを特定し、PDFに変換する
     * @request GET:/common/tt_microsoft/drives/format
     * @response `200` `OneDriveFormat` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtMicrosoftDrivesFormatAsCommon: (query: GetTtMicrosoftDrivesFormatAsCommonParams, params?: RequestParams) =>
      this.request<OneDriveFormat, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_microsoft/drives/format${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - "Sync One Drive to TimeTact"
     *
     * @tags Common/TtMicrosoftDrives
     * @name GetTtMicrosoftDrivesOauth2CallbackAsCommon
     * @summary Sync One Drive to TimeTact
     * @request GET:/common/tt_microsoft/drives/oauth2callback
     * @response `200` `MicrosoftOauth2Url` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtMicrosoftDrivesOauth2CallbackAsCommon: (
      query: GetTtMicrosoftDrivesOauth2CallbackAsCommonParams,
      params?: RequestParams,
    ) =>
      this.request<MicrosoftOauth2Url, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_microsoft/drives/oauth2callback${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - "One Driveとの連携を解除する"
     *
     * @tags Common/TtMicrosoftDrives
     * @name PostTtMicrosoftDrivesRevokeAsCommon
     * @summary One Driveとの連携を解除する
     * @request POST:/common/tt_microsoft/drives/revoke
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postTtMicrosoftDrivesRevokeAsCommon: (params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_microsoft/drives/revoke`,
        "POST",
        params,
      ),

    /**
     * @description - "One Driveとの連携情報を返却する"
     *
     * @tags Common/TtMicrosoftDrives
     * @name GetTtMicrosoftDrivesConnectionAsCommon
     * @summary One Driveとの連携情報を返却する
     * @request GET:/common/tt_microsoft/drives/connection
     * @response `200` `OneDriveConnection` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtMicrosoftDrivesConnectionAsCommon: (params?: RequestParams) =>
      this.request<OneDriveConnection, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/common/tt_microsoft/drives/connection`,
        "GET",
        params,
      ),

    /**
     * @description - .ics ファイルのデータを返却
     *
     * @tags Common/Icalendar
     * @name GetIcalendarsAsCommon
     * @summary .ics ファイルのデータを返却
     * @request GET:/common/icalendars
     * @response `200` `IcalendarBase` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getIcalendarsAsCommon: (query: GetIcalendarsAsCommonParams, params?: RequestParams) =>
      this.request<IcalendarBase, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/common/icalendars${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 関連サービスへのアクセス可否を返却する
     *
     * @tags Common/ConnectivityCheckStatus
     * @name GetConnectivityCheckStatus
     * @summary 関連サービスへの通信状況確認
     * @request GET:/common/connectivity/check_status
     * @response `200` `{ status_list?: ({ id?: number, name?: string, url?: string, status?: boolean })[] }` OK
     * @response `400` `BadRequest`
     */
    getConnectivityCheckStatus: (params?: RequestParams) =>
      this.request<{ status_list?: { id?: number; name?: string; url?: string; status?: boolean }[] }, BadRequest>(
        `/common/connectivity/check_status`,
        "GET",
        params,
      ),
  };
  googleApi = {
    /**
     * @description Sync Google calendar to TimeTact calendar.
     *
     * @tags GoogleApi/Calendar
     * @name CalendarOauth2CallbackList
     * @summary Google Calendar API
     * @request GET:/google_api/calendar/oauth2callback
     * @response `200` `any` OK
     * @response `403` `{ error: string }` Forbidden
     * @response `500` `{ error: string }` Internal Server Error
     */
    calendarOauth2CallbackList: (query: CalendarOauth2CallbackListParams, params?: RequestParams) =>
      this.request<any, { error: string }>(
        `/google_api/calendar/oauth2callback${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Google classroom integration.
     *
     * @tags GoogleApi/Classroom
     * @name ClassroomOauth2CallbackList
     * @summary Google Classroom API
     * @request GET:/google_api/classroom/oauth2callback
     * @response `200` `any` OK
     * @response `403` `{ error: string }` Not found
     * @response `404` `{ error: string }` Forbidden
     * @response `500` `{ error: string }` Internal Server Error
     */
    classroomOauth2CallbackList: (query: ClassroomOauth2CallbackListParams, params?: RequestParams) =>
      this.request<any, { error: string }>(
        `/google_api/classroom/oauth2callback${this.addQueryParams(query)}`,
        "GET",
        params,
      ),
  };
  webhooks = {
    /**
     * @description Webhook endpoint for registering encoded_path to video contents
     *
     * @name VideosEncodedCreate
     * @request POST:/webhooks/videos/encoded
     * @response `200` `any` OK
     */
    videosEncodedCreate: (data: { id: number; encoded_path: string; model_name: string }, params?: RequestParams) =>
      this.request<any, any>(`/webhooks/videos/encoded`, "POST", params, data),
  };
  admin = {
    /**
     * @description (AffiliateID使用)SV管理者がAffiliateをアップデート
     *
     * @tags AdminOwner/Affiliate
     * @name AffiliateUpdateAsAdmin
     * @summary (AffiliateID使用)SV管理者がAffiliateをアップデート
     * @request PUT:/admin/affiliates/{id}
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    affiliateUpdateAsAdmin: (
      id: string,
      data: { student_suspension?: boolean; only_pbl_projects_feature?: boolean },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/affiliates/${id}`, "PUT", params, data),

    /**
     * @description Admin限定 会社情報の一覧を返却
     *
     * @tags Companies
     * @name FetchCompaniesAsAdmin
     * @summary 会社情報一覧の取得
     * @request GET:/admin/companies
     * @response `200` `{ companies: (AdminCompanyList)[], total_count: number }` OK
     * @response `401` `any` Unauthorized
     * @response `403` `Forbidden`
     */
    fetchCompaniesAsAdmin: (query: FetchCompaniesAsAdminParams, params?: RequestParams) =>
      this.request<{ companies: AdminCompanyList[]; total_count: number }, Forbidden>(
        `/admin/companies${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Adminが新規に企業を登録 添削会社を作成する時はreviewer_company, reviewer_featureオプションを設定する。
     *
     * @tags Companies
     * @name PostCompanies
     * @summary 新規 企業登録
     * @request POST:/admin/companies
     * @response `201` `{ id: string }` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postCompanies: (data: CompanyRequestBodyAsAdmin, params?: RequestParams) =>
      this.request<{ id: string }, Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/admin/companies`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定企業情報取得
     *
     * @tags Companies
     * @name GetCompaniesCompanyId
     * @summary Admin 企業情報参照
     * @request GET:/admin/companies/{company_id}
     * @response `200` `AdminCompany` OK
     * @response `400` `{ error_code?: number, messsage?: string }` Bad Request
     * @response `403` `Forbidden`
     */
    getCompaniesCompanyId: (company_id: string, params?: RequestParams) =>
      this.request<AdminCompany, { error_code?: number; messsage?: string } | Forbidden>(
        `/admin/companies/${company_id}`,
        "GET",
        params,
      ),

    /**
     * @description admin限定企業情報の更新
     *
     * @tags Companies
     * @name PutCompaniesCompanyId
     * @summary 企業情報更新
     * @request PUT:/admin/companies/{company_id}
     * @response `202` `{ id: string, options: Option, options_editable: Option }` Accepted
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putCompaniesCompanyId: (company_id: string, data: CompanyRequestBody, params?: RequestParams) =>
      this.request<
        { id: string; options: Option; options_editable: Option },
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/companies/${company_id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Companies
     * @name DeleteCompanyAsAdmin
     * @summary 会社の論理削除
     * @request DELETE:/admin/companies/{company_id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteCompanyAsAdmin: (company_id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/companies/${company_id}`, "DELETE", params),

    /**
     * @description すべてのオーナー権限保有ユーザの一覧を返却
     *
     * @tags Users
     * @name GetAdminUsersOwners
     * @summary [Admin] オーナー一覧の取得
     * @request GET:/admin/companies/{company_id}/owners
     * @response `200` `(BasicUserInfo)[]` OK
     * @response `401` `any` Unauthorized
     * @response `403` `Forbidden`
     */
    getAdminUsersOwners: (company_id: string, params?: RequestParams) =>
      this.request<BasicUserInfo[], Forbidden>(`/admin/companies/${company_id}/owners`, "GET", params),

    /**
     * @description 親会社がいない添削会社と普通の学校の紐付け 小会社はadminでは紐付けをすることができない。
     *
     * @tags Companies
     * @name PostConnectReviewerCompany
     * @summary 親会社がいない添削会社と普通の学校の紐付け
     * @request POST:/admin/reviewer_company_reviewings
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postConnectReviewerCompany: (data: ReviewerCompanyReviewingRequiredRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/admin/reviewer_company_reviewings`,
        "POST",
        params,
        data,
      ),

    /**
     * @description params[:company_id]で指定の学校のgrade一覧を取得。ない場合は全ての学校のgradeを取得
     *
     * @tags Admin/grade_masters
     * @name GradeMastersList
     * @summary 会社に紐づいた卒業年度を取得
     * @request GET:/admin/grade_masters
     * @response `200` `GradeMasterBaseAsAdmin` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    gradeMastersList: (query: GradeMastersListParams, params?: RequestParams) =>
      this.request<GradeMasterBaseAsAdmin, Unauthorized | Forbidden>(
        `/admin/grade_masters${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 指定したidの卒業年度を更新
     *
     * @tags Admin/grade_masters
     * @name GradeMastersUpdate
     * @summary 指定のgradeの卒業年度を更新
     * @request PUT:/admin/grade_masters/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `any` Bad Request
     * @response `401` `any` Unauthorized
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    gradeMastersUpdate: (id: string, data: GraduationYearRequiredRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, Forbidden>(`/admin/grade_masters/${id}`, "PUT", params, data),

    /**
     * @description 教材の容量制限
     *
     * @tags Companies
     * @name GetMaterialCapacityAsAdmin
     * @summary 教材の容量制限
     * @request GET:/admin/companies/{company_id}/material_capacity
     * @response `200` `{ material_capacity: string, material_limit_capacity: string | null }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getMaterialCapacityAsAdmin: (company_id: string, params?: RequestParams) =>
      this.request<
        { material_capacity: string; material_limit_capacity: string | null },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/companies/${company_id}/material_capacity`, "GET", params),

    /**
     * @description 先生一覧取得
     *
     * @tags Users
     * @name GetTeachersAsAdmin
     * @summary 先生一覧取得
     * @request GET:/admin/companies/{company_id}/teachers
     * @response `200` `(BasicUserInfo)[]` OK
     * @response `401` `any` Unauthorized
     * @response `403` `Forbidden`
     */
    getTeachersAsAdmin: (company_id: string, params?: RequestParams) =>
      this.request<BasicUserInfo[], Forbidden>(`/admin/companies/${company_id}/teachers`, "GET", params),

    /**
     * @description 生徒一覧取得
     *
     * @tags Users
     * @name GetStudentsAsAdmin
     * @summary 生徒一覧取得
     * @request GET:/admin/companies/{company_id}/students
     * @response `200` `(BasicUserInfo)[]` OK
     * @response `401` `any` Unauthorized
     * @response `403` `Forbidden`
     */
    getStudentsAsAdmin: (company_id: string, params?: RequestParams) =>
      this.request<BasicUserInfo[], Forbidden>(`/admin/companies/${company_id}/students`, "GET", params),

    /**
     * @description 会社に所属するスーパーオーナーを一括取得する
     *
     * @tags Users
     * @name CompaniesSuperOwnersDetail
     * @summary スーパーオーナー一覧取得
     * @request GET:/admin/companies/{company_id}/super_owners
     * @response `200` `(BasicUserInfo)[]` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    companiesSuperOwnersDetail: (company_id: string, params?: RequestParams) =>
      this.request<BasicUserInfo[], Unauthorized | Forbidden>(
        `/admin/companies/${company_id}/super_owners`,
        "GET",
        params,
      ),

    /**
     * @description コンテンツ事業者一覧取得
     *
     * @tags Users
     * @name GetContentProvidersAsAdmin
     * @summary コンテンツ事業者一覧取得
     * @request GET:/admin/companies/{company_id}/content_providers
     * @response `200` `(BasicUserInfo)[]` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getContentProvidersAsAdmin: (company_id: string, params?: RequestParams) =>
      this.request<BasicUserInfo[], Unauthorized | Forbidden>(
        `/admin/companies/${company_id}/content_providers`,
        "GET",
        params,
      ),

    /**
     * @description - "Admin限定 ReviewerCompanyGroupを一覧を返却" - "提出物タイプ一覧の返却"
     *
     * @tags Admin/ReviewerCompanyGroup
     * @name GetReviewerCompanyGroupControllersAsAdmin
     * @summary ReviewerCompanyGroup一覧を返却
     * @request GET:/admin/companies/{company_id}/reviewer_company_groups
     * @response `200` `{ reviewer_company_groups: (ReviewerCompanyGroupBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getReviewerCompanyGroupControllersAsAdmin: (
      { company_id, ...query }: GetReviewerCompanyGroupControllersAsAdminParams,
      params?: RequestParams,
    ) =>
      this.request<
        { reviewer_company_groups: ReviewerCompanyGroupBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/companies/${company_id}/reviewer_company_groups${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Admin限定 ReviewerCompanyGroupを登録" - "提出物タイプ一覧の登録"
     *
     * @tags Admin/ReviewerCompanyGroup
     * @name PostReviewerCompanyGroupAsAdmin
     * @summary ReviewerCompanyGroupを登録
     * @request POST:/admin/companies/{company_id}/reviewer_company_groups
     * @response `201` `{ reviewer_company_group: ReviewerCompanyGroupDetail }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postReviewerCompanyGroupAsAdmin: (
      company_id: string,
      data: ReviewerCompanyReviewingRequestBodyAsAdmin,
      params?: RequestParams,
    ) =>
      this.request<
        { reviewer_company_group: ReviewerCompanyGroupDetail },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/companies/${company_id}/reviewer_company_groups`, "POST", params, data),

    /**
     * @description - "Admin限定 ReviewerCompanyGroupの詳細を返却"
     *
     * @tags Admin/ReviewerCompanyGroup
     * @name GetReviewerCompanyGroupAsAdmin
     * @summary ReviewerCompanyGroupの詳細を返却
     * @request GET:/admin/companies/{company_id}/reviewer_company_groups/{id}
     * @response `200` `{ reviewer_company_group: ReviewerCompanyGroupDetail }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getReviewerCompanyGroupAsAdmin: (company_id: string, id: string, params?: RequestParams) =>
      this.request<
        { reviewer_company_group: ReviewerCompanyGroupDetail },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/companies/${company_id}/reviewer_company_groups/${id}`, "GET", params),

    /**
     * @description - "Admin限定 ReviewerCompanyGroupを更新" - "コメント1"
     *
     * @tags Admin/ReviewerCompanyGroup
     * @name PutReviewerCompanyGroupAsAdmin
     * @summary ReviewerCompanyGroupを更新
     * @request PUT:/admin/companies/{company_id}/reviewer_company_groups/{id}
     * @response `202` `{ reviewer_company_group: ReviewerCompanyGroupDetail }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putReviewerCompanyGroupAsAdmin: (
      company_id: string,
      id: string,
      data: ReviewerCompanyReviewingRequestBodyAsAdmin,
      params?: RequestParams,
    ) =>
      this.request<
        { reviewer_company_group: ReviewerCompanyGroupDetail },
        BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage
      >(`/admin/companies/${company_id}/reviewer_company_groups/${id}`, "PUT", params, data),

    /**
     * @description - "Admin限定 ReviewerCompanyGroupを削除" - "コメント1"
     *
     * @tags Admin/ReviewerCompanyGroup
     * @name DeleteControllerAsAdmin
     * @summary ReviewerCompanyGroupを削除
     * @request DELETE:/admin/companies/{company_id}/reviewer_company_groups/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteControllerAsAdmin: (company_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/companies/${company_id}/reviewer_company_groups/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Admin限定 企業の一覧を返却
     *
     * @tags Companies
     * @name GetCompaniesContentCompaniesAsAdmin
     * @request GET:/admin/companies/content_companies
     * @response `200` `{ companies: ((ModelBase & { name: string, sponsor_info_id?: string | null, projects?: ({ id?: string, name?: string, lectures?: ({ id?: string, name?: string })[] })[] }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCompaniesContentCompaniesAsAdmin: (query: GetCompaniesContentCompaniesAsAdminParams, params?: RequestParams) =>
      this.request<
        {
          companies: (ModelBase & {
            name: string;
            sponsor_info_id?: string | null;
            projects?: { id?: string; name?: string; lectures?: { id?: string; name?: string }[] }[];
          })[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/companies/content_companies${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Admin限定 IpWhitelistの一覧を返却" - "会社・ロールごとにアクセスが許可されているIPアドレス" - "対象の会社・ロールにリストが一つもない場合、全IPアドレスを通す"
     *
     * @tags Admin/IpWhitelists
     * @name GetIpWhitelistsAsAdmin
     * @summary IpWhitelistの一覧を返却
     * @request GET:/admin/ip_whitelists
     * @response `200` `{ ip_whitelists: (IpWhitelistBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getIpWhitelistsAsAdmin: (query: GetIpWhitelistsAsAdminParams, params?: RequestParams) =>
      this.request<
        { ip_whitelists: IpWhitelistBase[]; total_count: number },
        Unauthorized | Forbidden | { error?: string }
      >(`/admin/ip_whitelists${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Admin限定 IpWhitelist を登録"
     *
     * @tags Admin/IpWhitelists
     * @name PostIpWhitelistsAsAdmin
     * @summary IpWhitelist を登録
     * @request POST:/admin/ip_whitelists
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postIpWhitelistsAsAdmin: (data: PostIpWhitelistsRequestBody, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/ip_whitelists`, "POST", params, data),

    /**
     * @description - "Admin限定 IpWhitelist を更新"
     *
     * @tags Admin/IpWhitelists
     * @name PutIpWhitelistAsAdmin
     * @summary IpWhitelist を更新
     * @request PUT:/admin/ip_whitelists/{id}
     * @response `202` `IpWhitelistBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putIpWhitelistAsAdmin: (id: string, data: PutIpWhitelistsRequestBody, params?: RequestParams) =>
      this.request<IpWhitelistBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/admin/ip_whitelists/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "Admin限定 IpWhitelist を削除"
     *
     * @tags Admin/IpWhitelists
     * @name DeleteIpWhitelistAsAdmin
     * @summary IpWhitelist を削除
     * @request DELETE:/admin/ip_whitelists/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteIpWhitelistAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/ip_whitelists/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description 対象ユーザのRoleに応じて返却される情報は異なる
     *
     * @tags Users
     * @name GetAdminUsersUserId
     * @summary Adminがユーザ詳細を取得
     * @request GET:/admin/users/{user_id}
     * @response `200` `(BasicUserInfo & AffiliateListBase)` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getAdminUsersUserId: (user_id: string, params?: RequestParams) =>
      this.request<BasicUserInfo & AffiliateListBase, Unauthorized | Forbidden>(
        `/admin/users/${user_id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Users
     * @name DeleteAdminUsersUserId
     * @summary ユーザの論理削除
     * @request DELETE:/admin/users/{user_id}
     * @response `202` `string` Accepted
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error(削除失敗)
     */
    deleteAdminUsersUserId: (user_id: string, data: { company_id: string; role: string }, params?: RequestParams) =>
      this.request<string, { message?: string } | Unauthorized | Forbidden>(
        `/admin/users/${user_id}`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description ユーザの物理削除
     *
     * @tags Admin/User
     * @name UsersHardDelete
     * @request DELETE:/admin/users/{id}/hard
     * @response `204` `any` No Content
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }` Not Found
     */
    usersHardDelete: (id: string, params?: RequestParams) =>
      this.request<any, Forbidden | { error?: string }>(`/admin/users/${id}/hard`, "DELETE", params),

    /**
     * @description Adminの新規作成
     *
     * @tags Admin/User
     * @name UsersAdminCreate
     * @request POST:/admin/users/admin
     * @response `201` `{ id?: string }` Created
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    usersAdminCreate: (data: AdminRegistrationRequestBody, params?: RequestParams) =>
      this.request<{ id?: string }, BadRequest | Forbidden | ErrorsWithKeyMessage>(
        `/admin/users/admin`,
        "POST",
        params,
        data,
      ),

    /**
     * @description スーパーオーナーの新規作成
     *
     * @tags Admin/User
     * @name UsersSuperOwnerCreate
     * @request POST:/admin/users/super_owner
     * @response `201` `{ id?: string }` Created
     * @response `400` `{ message?: string }` Bad Request
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    usersSuperOwnerCreate: (data: UserRegistrationRequestBodyAsAdmin, params?: RequestParams) =>
      this.request<{ id?: string }, { message?: string } | Forbidden | ErrorsWithKeyMessage>(
        `/admin/users/super_owner`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 添削者の新規作成
     *
     * @tags Admin/User
     * @name UsersReviewerCreate
     * @request POST:/admin/users/reviewer
     * @response `201` `{ id?: string }` Created
     * @response `400` `{ message?: string }` Bad Request
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    usersReviewerCreate: (data: UserRegistrationRequestBodyAsAdmin, params?: RequestParams) =>
      this.request<{ id?: string }, { message?: string } | Forbidden | ErrorsWithKeyMessage>(
        `/admin/users/reviewer`,
        "POST",
        params,
        data,
      ),

    /**
     * @description コンテンツ事業者の新規作成
     *
     * @tags Admin/User
     * @name UsersContentProviderCreate
     * @request POST:/admin/users/content_provider
     * @response `201` `{ id?: string }` Created
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    usersContentProviderCreate: (data: UserRegistrationRequestBodyAsAdmin, params?: RequestParams) =>
      this.request<{ id?: string }, BadRequest | Forbidden | ErrorsWithKeyMessage>(
        `/admin/users/content_provider`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 管理者からユーザの代理ログインをする。
     *
     * @tags Admin/User
     * @name UsersAuthorizedSignInCreate
     * @request POST:/admin/users/{id}/authorized_sign_in
     * @response `200` `{ data?: { id?: number, email?: string, provider?: string, avatar?: Avatar, uid?: string, last_name?: string, first_name?: string, allow_password_change?: boolean, discarded_at?: string | null, type?: string } }` OK
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    usersAuthorizedSignInCreate: (id: string, params?: RequestParams) =>
      this.request<
        {
          data?: {
            id?: number;
            email?: string;
            provider?: string;
            avatar?: Avatar;
            uid?: string;
            last_name?: string;
            first_name?: string;
            allow_password_change?: boolean;
            discarded_at?: string | null;
            type?: string;
          };
        },
        Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/users/${id}/authorized_sign_in`, "POST", params),

    /**
     * @description - "Admin限定 User検索一覧を返却" - "emailで検索したUserを返す"
     *
     * @tags Admin/User
     * @name GetSearchUserControllersAsAdmin
     * @summary User検索一覧を返却
     * @request GET:/admin/users/search
     * @response `200` `{ users: (BasicUserInfo)[], is_admin_creator: boolean, total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSearchUserControllersAsAdmin: (query: GetSearchUserControllersAsAdminParams, params?: RequestParams) =>
      this.request<
        { users: BasicUserInfo[]; is_admin_creator: boolean; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/users/search${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Admin限定 Userと紐づくAffiliateを削除"
     *
     * @tags Admin/Controllers
     * @name BulkDeleteControllerAsAdmin
     * @summary Userと紐づくAffiliateを削除
     * @request DELETE:/admin/users/{id}/bulk_destroy
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    bulkDeleteControllerAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden>(
        `/admin/users/${id}/bulk_destroy`,
        "DELETE",
        params,
      ),

    /**
     * @description Adminがユーザーのパスワードを変更(アカウントロックも解除)
     *
     * @tags Admin/User
     * @name ResetPasswordAsAdmin
     * @summary Adminがユーザーのパスワードを変更(アカウントロックも解除)
     * @request PUT:/admin/users/{id}/reset_password
     * @response `200` `AccountUserInfo` ok
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    resetPasswordAsAdmin: (id: string, data: AuthorizationPasswordRequestBody, params?: RequestParams) =>
      this.request<AccountUserInfo, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/users/${id}/reset_password`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 新規にユーザを生成。 想定として、Adminはオーナーのみ生成可能、という前提で実装
     *
     * @tags Users
     * @name PostAdminUsers
     * @summary 新規オーナーの作成
     * @request POST:/admin/users
     * @response `201` `{ id: string }` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error(Failed to save to DB)
     */
    postAdminUsers: (data: BasicUserInfo, params?: RequestParams) =>
      this.request<{ id: string }, Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/admin/users`,
        "POST",
        params,
        data,
      ),

    /**
     * @description params[:sent]がtrueの時はadminが送信したお知らせを取得する。ない場合はadminが受信したお知らせを取得する。
     *
     * @tags Admin/Notification
     * @name NotificationsList
     * @summary adminが受信したお知らせを取得 or adminが送信したお知らせを取得(他のadminも含む)
     * @request GET:/admin/notifications
     * @response `200` `Notifications` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    notificationsList: (query: NotificationsListParams, params?: RequestParams) =>
      this.request<Notifications, Unauthorized | Forbidden>(
        `/admin/notifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Admin/Notification
     * @name NotificationsCreate
     * @request POST:/admin/notifications
     * @response `200` `{ message?: string }` OK
     * @response `400` `{ message?: (any)[] }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    notificationsCreate: (data: NotificationRequestBodyAsAdmin, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: any[] } | Unauthorized | Forbidden>(
        `/admin/notifications`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Admin/Notification
     * @name NotificationsDetail
     * @request GET:/admin/notifications/{id}
     * @response `200` `NotificationBase` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    notificationsDetail: (id: string, params?: RequestParams) =>
      this.request<NotificationBase, Unauthorized | Forbidden>(`/admin/notifications/${id}`, "GET", params),

    /**
     * No description
     *
     * @tags Admin/Notification
     * @name NotificationsUpdate
     * @request PUT:/admin/notifications/{id}
     * @response `200` `NotificationBase` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    notificationsUpdate: (id: string, data: NotificationRequestBody, params?: RequestParams) =>
      this.request<NotificationBase, Unauthorized | Forbidden>(`/admin/notifications/${id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Admin/Notification
     * @name NotificationsDelete
     * @request DELETE:/admin/notifications/{id}
     * @response `200` `{ message?: string }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    notificationsDelete: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(`/admin/notifications/${id}`, "DELETE", params),

    /**
     * @description 商品の一覧取得
     *
     * @tags Admin/Ebl/Item
     * @name GetEblItemsAsAdmin
     * @request GET:/admin/ebl/items
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getEblItemsAsAdmin: (query: GetEblItemsAsAdminParams, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/admin/ebl/items${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 商品を登録
     *
     * @tags Admin/Ebl/Item
     * @name PostEblItemAsAdmin
     * @request POST:/admin/ebl/items
     * @response `201` `{ message?: string }` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postEblItemAsAdmin: (data: Items & { images?: { id?: number; file?: File }[] }, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/admin/ebl/items`,
        "POST",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description 商品の詳細情報を取得
     *
     * @tags Admin/Ebl/Item
     * @name GetEblItemAsAdmin
     * @request GET:/admin/ebl/items/{id}
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getEblItemAsAdmin: (id: number, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(`/admin/ebl/items/${id}`, "GET", params),

    /**
     * @description 商品の情報を更新
     *
     * @tags Admin/Ebl/Item
     * @name PutEblItemAsAdmin
     * @request PATCH:/admin/ebl/items/{id}
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putEblItemAsAdmin: (id: number, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(`/admin/ebl/items/${id}`, "PATCH", params),

    /**
     * @description 商品を削除
     *
     * @tags Admin/Ebl/Item
     * @name DeleteEblItemAsAdmin
     * @request DELETE:/admin/ebl/items/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `204` `any` No Content
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteEblItemAsAdmin: (id: number, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(`/admin/ebl/items/${id}`, "DELETE", params),

    /**
     * @description Admin限定 Steamライブラリーのコンテンツ一覧を返却
     *
     * @tags Admin/Steam/Contents
     * @name GetAdminSteamContents
     * @summary Admin: Steamライブラリーコンテンツ一覧の取得
     * @request GET:/admin/pbl/steam/contents
     * @response `200` `{ steam_contents?: (PblSteamContentBase)[], total_count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getAdminSteamContents: (query: GetAdminSteamContentsParams, params?: RequestParams) =>
      this.request<{ steam_contents?: PblSteamContentBase[]; total_count?: number }, Unauthorized | Forbidden>(
        `/admin/pbl/steam/contents${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Admin限定 Steamライブラリーのコンテンツの登録
     *
     * @tags Admin/Steam/Contents
     * @name PostAdminSteamContents
     * @summary Admin: Steamライブラリーコンテンツ一覧の登録
     * @request POST:/admin/pbl/steam/contents
     * @response `201` `PblSteamContentBase` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postAdminSteamContents: (data: PblSteamContentRequestBody, params?: RequestParams) =>
      this.request<PblSteamContentBase, Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/admin/pbl/steam/contents`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 Steamライブラリーのコンテンツの情報取得
     *
     * @tags Admin/Steam/Contents
     * @name GetAdminSteamContentsId
     * @summary Admin: Steamライブラリーコンテンツの情報参照
     * @request GET:/admin/pbl/steam/contents/{id}
     * @response `200` `PblSteamContentBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getAdminSteamContentsId: (id: string, params?: RequestParams) =>
      this.request<PblSteamContentBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/pbl/steam/contents/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Admin限定 Steamライブラリーのコンテンツの情報の更新
     *
     * @tags Admin/Steam/Contents
     * @name PutAdminSteamContentsId
     * @summary Admin: Steamライブラリーコンテンツの情報更新
     * @request PUT:/admin/pbl/steam/contents/{id}
     * @response `202` `PblSteamContentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putAdminSteamContentsId: (id: string, data: PblSteamContentRequestBody, params?: RequestParams) =>
      this.request<PblSteamContentBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/pbl/steam/contents/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 Steamライブラリーのコンテンツの情報の削除
     *
     * @tags Admin/Steam/Contents
     * @name DeleteAdminSteamContentsId
     * @summary Admin: Steamライブラリーコンテンツの削除
     * @request DELETE:/admin/pbl/steam/contents/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteAdminSteamContentsId: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/pbl/steam/contents/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Admin限定 SteamライブラリーAPIからタイトルを返却する
     *
     * @tags Admin/Steam/Contents
     * @name FetchAdminSteamContents
     * @summary Admin: SteamライブラリーのコンテンツIDからタイトルを返却
     * @request POST:/admin/pbl/steam/contents/fetch
     * @response `200` `{ steam_library_content_id: number, steam_library_content_title: string }` OK
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     */
    fetchAdminSteamContents: (query: FetchAdminSteamContentsParams, params?: RequestParams) =>
      this.request<{ steam_library_content_id: number; steam_library_content_title: string }, BadRequest | Forbidden>(
        `/admin/pbl/steam/contents/fetch${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @description 教材カテゴリ名の変更
     *
     * @tags CategoryRenameMap, Admin/CategoryRenameMap
     * @name CreateCategoryRenameMapAsAdmin
     * @request POST:/admin/category_rename_maps
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createCategoryRenameMapAsAdmin: (data: CategoryRenameMapRequestBodyAsAdmin, params?: RequestParams) =>
      this.request<
        { message?: string },
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/category_rename_maps`, "POST", params, data),

    /**
     * @description 教材カテゴリ名の変更を取得
     *
     * @tags CategoryRenameMap, Admin/CategoryRenameMap
     * @name FetchCategoryRenameMapAsAdmin
     * @request GET:/admin/category_rename_maps/{id}
     * @response `200` `CategoryRenameMap` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchCategoryRenameMapAsAdmin: (id: string, params?: RequestParams) =>
      this.request<CategoryRenameMap, Unauthorized | Forbidden>(`/admin/category_rename_maps/${id}`, "GET", params),

    /**
     * @description 教材カテゴリ名の変更
     *
     * @tags CategoryRenameMap, Admin/CategoryRenameMap
     * @name UpdateCategoryRenameMapAsAdmin
     * @request PUT:/admin/category_rename_maps/{id}
     * @response `200` `CategoryRenameMap` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updateCategoryRenameMapAsAdmin: (id: string, data: CategoryRenameMapRequestBody, params?: RequestParams) =>
      this.request<
        CategoryRenameMap,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/category_rename_maps/${id}`, "PUT", params, data),

    /**
     * @description 教材カテゴリ名の変更を取得
     *
     * @tags CategoryRenameMap, Admin/CategoryRenameMap
     * @name FetchCategoryRenameMapByCompanyIdAsAdmin
     * @request GET:/admin/category_rename_maps/fetch_by_company/{company_id}
     * @response `200` `CategoryRenameMap` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchCategoryRenameMapByCompanyIdAsAdmin: (company_id: string, params?: RequestParams) =>
      this.request<CategoryRenameMap, Unauthorized | Forbidden>(
        `/admin/category_rename_maps/fetch_by_company/${company_id}`,
        "GET",
        params,
      ),

    /**
     * @description コンテンツ事業者に紐づいている会社(学校)を取得できる。コンテンツ事業者ではない会社は取得できない。
     *
     * @tags Admin/ContentCompanyReviewings
     * @name GetAdminContentCompanyReviewings
     * @summary コンテンツ事業者からレビューされる会社(学校)を取得できること
     * @request GET:/admin/content_company_reviewings/{content_company_id}/
     * @response `200` `{ reviewee_companies: (CompanyBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getAdminContentCompanyReviewings: (
      { content_company_id, ...query }: GetAdminContentCompanyReviewingsParams,
      params?: RequestParams,
    ) =>
      this.request<
        { reviewee_companies: CompanyBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/content_company_reviewings/${content_company_id}/${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description コンテンツ事業者からのreview機能のオプションがONになっている会社（学校）を取得する
     *
     * @tags Admin/ContentCompanyReviewings
     * @name GetAdminContentCompanyReviewingsReviewFeatureCompanies
     * @summary コンテンツ事業者からのreview機能が使用できる会社（学校）を取得する
     * @request GET:/admin/content_company_reviewings/{content_company_id}/review_feature_companies
     * @response `200` `{ review_feature_companies: (CompanyBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getAdminContentCompanyReviewingsReviewFeatureCompanies: (
      { content_company_id, ...query }: GetAdminContentCompanyReviewingsReviewFeatureCompaniesParams,
      params?: RequestParams,
    ) =>
      this.request<
        { review_feature_companies: CompanyBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/admin/content_company_reviewings/${content_company_id}/review_feature_companies${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description コンテンツ事業者からレビューされる会社(学校)を登録できること
     *
     * @tags Admin/ContentCompanyReviewings
     * @name PatchAdminContentCompanyReviewingsBatchCreate
     * @summary コンテンツ事業者からレビューされる会社(学校)を登録できること
     * @request PATCH:/admin/content_company_reviewings/{content_company_id}/batch_create
     * @response `200` `{ errors: (string)[], reviewee_companies: (CompanyBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    patchAdminContentCompanyReviewingsBatchCreate: (
      content_company_id: string,
      data: CompanyIdsRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { errors: string[]; reviewee_companies: CompanyBase[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/content_company_reviewings/${content_company_id}/batch_create`, "PATCH", params, data),

    /**
     * @description コンテンツ事業者からレビューされる会社(学校)を削除できること
     *
     * @tags Admin/ContentCompanyReviewings
     * @name PatchAdminContentCompanyReviewingsBatchDelete
     * @summary コンテンツ事業者からレビューされる会社(学校)を削除できること
     * @request PATCH:/admin/content_company_reviewings/{content_company_id}/batch_delete
     * @response `200` `{ removed_reviewee_ids: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    patchAdminContentCompanyReviewingsBatchDelete: (
      content_company_id: string,
      data: CompanyIdsRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ removed_reviewee_ids: string[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/content_company_reviewings/${content_company_id}/batch_delete`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業一覧を返却
     *
     * @tags Admin/Sponsor/Infos
     * @name GetAdminSponsorInfos
     * @summary 企業一覧を返却
     * @request GET:/admin/sponsor/infos
     * @response `200` `{ sponsors: (SponsorInfoListForAdmin)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getAdminSponsorInfos: (query: GetAdminSponsorInfosParams, params?: RequestParams) =>
      this.request<{ sponsors: SponsorInfoListForAdmin[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/infos${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Admin限定 企業を登録。タグ、カテゴリー、画像、動画を一括保存する。
     *
     * @tags Admin/Sponsor/Infos
     * @name CreateSponsorInfoAsAdmin
     * @summary 企業を登録
     * @request POST:/admin/sponsor/infos
     * @response `201` `SponsorInfoDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    createSponsorInfoAsAdmin: (data: PostSponsorInfoRequestBody, params?: RequestParams) =>
      this.request<SponsorInfoDetailsBase, BadRequest | Unauthorized | Forbidden | { message?: string }>(
        `/admin/sponsor/infos`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業の詳細情報を返却
     *
     * @tags Admin/Sponsor/Infos
     * @name GetAdminSponsorInfosId
     * @summary 企業の詳細情報を返却
     * @request GET:/admin/sponsor/infos/{id}
     * @response `200` `SponsorInfoDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getAdminSponsorInfosId: (id: string, params?: RequestParams) =>
      this.request<SponsorInfoDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/infos/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Admin限定 企業情報を更新。タグ、画像、動画を一括保存する。 - カテゴリーは、putAdminSponsorInfoCategoriesIdをお使いください。
     *
     * @tags Admin/Sponsor/Infos
     * @name UpdateSponsorInfoAsAdmin
     * @summary 企業情報を更新
     * @request PUT:/admin/sponsor/infos/{id}
     * @response `202` `SponsorInfoDetailsBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    updateSponsorInfoAsAdmin: (id: string, data: PutSponsorInfoRequestBody, params?: RequestParams) =>
      this.request<SponsorInfoDetailsBase, BadRequest | Unauthorized | Forbidden | { message?: string }>(
        `/admin/sponsor/infos/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業を削除
     *
     * @tags Admin/Sponsor/Infos
     * @name DeleteSponsorInfoAsAdmin
     * @summary 企業を削除
     * @request DELETE:/admin/sponsor/infos/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSponsorInfoAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/infos/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Admin限定 会社情報の一覧を返却
     *
     * @tags Companies
     * @name FetchPblSponsorLinkableCompaniesAsAdmin
     * @summary 会社情報一覧の取得
     * @request GET:/admin/sponsor/infos/{info_id}/linkable_companies
     * @response `200` `{ companies: ({ id: string, name: string, link_id: string | null })[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchPblSponsorLinkableCompaniesAsAdmin: (
      { info_id, ...query }: FetchPblSponsorLinkableCompaniesAsAdminParams,
      params?: RequestParams,
    ) =>
      this.request<
        { companies: { id: string; name: string; link_id: string | null }[]; total_count: number },
        Unauthorized | Forbidden
      >(`/admin/sponsor/infos/${info_id}/linkable_companies${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Admin限定 企業を登録。タグ、カテゴリー、画像、動画を一括保存する。
     *
     * @tags Admin/Sponsor/Infos
     * @name CreateSponsorInfoCategoryAsAdmin
     * @summary 企業のフリーカテゴリーを登録
     * @request POST:/admin/sponsor/info_categories
     * @response `201` `SponsorCategoryBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    createSponsorInfoCategoryAsAdmin: (data: PostSponsorCategoryRequestBody, params?: RequestParams) =>
      this.request<SponsorCategoryBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/info_categories`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業カテゴリーの詳細情報を返却
     *
     * @tags Admin/Sponsor/InfoCategories
     * @name FetchSponsorInfoCategoryAsAdmin
     * @summary 企業カテゴリーの詳細情報を返却
     * @request GET:/admin/sponsor/info_categories/{id}
     * @response `200` `SponsorCategoryBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorInfoCategoryAsAdmin: (id: string, params?: RequestParams) =>
      this.request<SponsorCategoryBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/info_categories/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Admin限定 企業カテゴリーの詳細情報を更新
     *
     * @tags Admin/Sponsor/InfoCategories
     * @name UpdateSponsorInfoCategoryAsAdmin
     * @summary 企業カテゴリーの詳細情報を更新
     * @request PUT:/admin/sponsor/info_categories/{id}
     * @response `202` `SponsorCategoryBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    updateSponsorInfoCategoryAsAdmin: (id: string, data: PutSponsorCategoryRequestBody, params?: RequestParams) =>
      this.request<SponsorCategoryBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/info_categories/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業カテゴリーの詳細情報を削除
     *
     * @tags Admin/Sponsor/InfoCategories
     * @name DeleteSponsorInfoCategoryAsAdmin
     * @summary 企業カテゴリーの詳細情報を削除
     * @request DELETE:/admin/sponsor/info_categories/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSponsorInfoCategoryAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/info_categories/${id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags Admin/Sponsor/Projects
     * @name FetchSponsorProjectsAsAdmin
     * @summary 企業プロジェクト一覧を取得
     * @request GET:/admin/sponsor/projects
     * @response `200` `{ projects: (SponsorProjectAndTagsBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectsAsAdmin: (query: FetchSponsorProjectsAsAdminParams, params?: RequestParams) =>
      this.request<
        { projects: SponsorProjectAndTagsBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/admin/sponsor/projects${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Admin限定 企業プロジェクトを登録。タグ、カテゴリー、画像、動画を一括保存する。 - company_idは、その企業情報(Sponsor::Info)が登録されていること
     *
     * @tags Admin/Sponsor/Projects
     * @name CreateSponsorProjectAsAdmin
     * @summary 企業プロジェクトを登録
     * @request POST:/admin/sponsor/projects
     * @response `201` `SponsorProjectDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createSponsorProjectAsAdmin: (data: PostSponsorProjectRequestBody, params?: RequestParams) =>
      this.request<SponsorProjectDetailsBase, BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/admin/sponsor/projects`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業プロジェクトの詳細情報を返却
     *
     * @tags Admin/Sponsor/Projects
     * @name FetchSponsorProjectAsAdmin
     * @summary 企業プロジェクトの詳細情報を返却
     * @request GET:/admin/sponsor/projects/{id}
     * @response `200` `SponsorProjectDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectAsAdmin: (id: string, params?: RequestParams) =>
      this.request<SponsorProjectDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Admin限定 企業プロジェクト情報を更新。タグ、画像、動画を一括保存する。 - company_idは、その企業情報(Sponsor::Info)が登録されていること - Projectカテゴリーは、putAdminSponsorProjectCategoriesIdをお使いください。
     *
     * @tags Admin/Sponsor/Projects
     * @name UpdateSponsorProjectAsAdmin
     * @summary 企業プロジェクト情報を更新
     * @request PUT:/admin/sponsor/projects/{id}
     * @response `202` `SponsorProjectDetailsBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    updateSponsorProjectAsAdmin: (id: string, data: PutSponsorProjectRequestBody, params?: RequestParams) =>
      this.request<SponsorProjectDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業プロジェクトを削除
     *
     * @tags Admin/Sponsor/Projects
     * @name DeleteSponsorProjectAsAdmin
     * @summary 企業プロジェクトを削除
     * @request DELETE:/admin/sponsor/projects/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSponsorProjectAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/${id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags Admin/Sponsor/Projects
     * @name SortSponsorProjectAsAdmin
     * @summary 企業プロジェクトの並べ替え
     * @request PUT:/admin/sponsor/projects/sequence
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    sortSponsorProjectAsAdmin: (data: SponsorProjectSequencesRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/sponsor/projects/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Admin/Sponsor/Projects
     * @name FetchSponsorProjectsForCarouselAsAdmin
     * @summary カルーセル表示用企業プロジェクトを取得
     * @request GET:/admin/sponsor/projects/carousel
     * @response `200` `{ projects: (SponsorProjectAndTagsBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectsForCarouselAsAdmin: (
      query: FetchSponsorProjectsForCarouselAsAdminParams,
      params?: RequestParams,
    ) =>
      this.request<
        { projects: SponsorProjectAndTagsBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/admin/sponsor/projects/carousel${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 大学先生に紐づいている企業プロジェクトの一覧を返却。
     *
     * @tags Admin/Sponsor/Projects
     * @name FetchSponsorProjectListAsAdmin
     * @summary 大学先生に紐づいている企業プロジェクトの一覧を返却
     * @request GET:/admin/sponsor/projects/list
     * @response `200` `{ projects: (HasIdAndName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectListAsAdmin: (query: FetchSponsorProjectListAsAdminParams, params?: RequestParams) =>
      this.request<{ projects: HasIdAndName[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/list${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Admin/Sponsor/Projects
     * @name ToggleDisplayOfProjectOnCarouselAsAdmin
     * @summary 企業プロジェクトのカルーセル表示有無を切り替え
     * @request PUT:/admin/sponsor/projects/{id}/toggle_carousel_display
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    toggleDisplayOfProjectOnCarouselAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/sponsor/projects/${id}/toggle_carousel_display`,
        "PUT",
        params,
      ),

    /**
     * @description Admin限定 企業を登録。タグ、カテゴリー、画像、動画を一括保存する。
     *
     * @tags Admin/Sponsor/Categories
     * @name CreateSponsorProjectCategoryAsAdmin
     * @summary 企業プロジェクトのフリーカテゴリーを登録
     * @request POST:/admin/sponsor/project_categories
     * @response `201` `SponsorProjectCategoryBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    createSponsorProjectCategoryAsAdmin: (data: PostSponsorProjectCategoryRequestBody, params?: RequestParams) =>
      this.request<SponsorProjectCategoryBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/project_categories`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業プロジェクトのカテゴリー詳細情報を返却
     *
     * @tags Admin/Sponsor/ProjectCategories
     * @name GetAdminSponsorProjectCategoriesId
     * @summary 企業プロジェクトのカテゴリー詳細情報を返却
     * @request GET:/admin/sponsor/project_categories/{id}
     * @response `200` `SponsorProjectCategoryBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getAdminSponsorProjectCategoriesId: (id: string, params?: RequestParams) =>
      this.request<SponsorProjectCategoryBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/project_categories/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Admin限定 企業プロジェクトのカテゴリー詳細情報を更新
     *
     * @tags Admin/Sponsor/ProjectCategories
     * @name UpdateSponsorProjectCategoryAsAdmin
     * @summary 企業プロジェクトのカテゴリー詳細情報を更新
     * @request PUT:/admin/sponsor/project_categories/{id}
     * @response `202` `SponsorProjectCategoryBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    updateSponsorProjectCategoryAsAdmin: (id: string, data: PutSponsorCategoryRequestBody, params?: RequestParams) =>
      this.request<SponsorProjectCategoryBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/project_categories/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業プロジェクトのカテゴリー詳細情報を削除
     *
     * @tags Admin/Sponsor/ProjectCategories
     * @name DeleteSponsorProjectCategoryAsAdmin
     * @summary 企業プロジェクトのカテゴリー詳細情報を削除
     * @request DELETE:/admin/sponsor/project_categories/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSponsorProjectCategoryAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/project_categories/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Admin限定 企業プロジェクトのレクチャーの一覧を返却
     *
     * @tags Admin/Sponsor/Lectures
     * @name FetchSponsorLecturesAsAdmin
     * @summary 企業プロジェクトのレクチャーの一覧を返却
     * @request GET:/admin/sponsor/projects/lectures
     * @response `200` `{ project_lectures: (SponsorLectureDetailsBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLecturesAsAdmin: (query: FetchSponsorLecturesAsAdminParams, params?: RequestParams) =>
      this.request<
        { project_lectures: SponsorLectureDetailsBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/admin/sponsor/projects/lectures${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Admin限定 企業プロジェクトのレクチャーを登録。タグ、ファイル、画像、動画を一括保存する。 - company_idは、その企業情報(Sponsor::Info)とプロジェクト情報(Pbl::Sponsor::Project)が登録されていること
     *
     * @tags Admin/Sponsor/Lectures
     * @name CreateSponsorLectureAsAdmin
     * @summary 企業プロジェクトのレクチャーを登録
     * @request POST:/admin/sponsor/projects/lectures
     * @response `201` `SponsorLectureDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    createSponsorLectureAsAdmin: (data: PostSponsorLectureRequestBody, params?: RequestParams) =>
      this.request<SponsorLectureDetailsBase, BadRequest | Unauthorized | Forbidden | { message?: string }>(
        `/admin/sponsor/projects/lectures`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業プロジェクトのレクチャーの詳細情報を返却
     *
     * @tags Admin/Sponsor/Lectures
     * @name FetchSponsorLectureAsAdmin
     * @summary 企業プロジェクトのレクチャーの詳細情報を返却
     * @request GET:/admin/sponsor/projects/lectures/{id}
     * @response `200` `SponsorLectureDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLectureAsAdmin: (id: string, params?: RequestParams) =>
      this.request<SponsorLectureDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/lectures/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Admin限定 企業プロジェクトのレクチャーを更新。タグ、画像、動画を一括保存する。 - company_idは、その企業情報(Sponsor::Info)とプロジェクト情報(Pbl::Sponsor::Project)が登録されていること - ファイルは、putAdminSponsorLectureFilesIdをお使いください。
     *
     * @tags Admin/Sponsor/Lectures
     * @name UpdateSponsorLectureAsAdmin
     * @summary 企業プロジェクトのレクチャーを更新
     * @request PUT:/admin/sponsor/projects/lectures/{id}
     * @response `202` `SponsorLectureDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    updateSponsorLectureAsAdmin: (id: string, data: PutSponsorLectureRequestBody, params?: RequestParams) =>
      this.request<SponsorLectureDetailsBase, BadRequest | Unauthorized | Forbidden | { message?: string }>(
        `/admin/sponsor/projects/lectures/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業プロジェクトのレクチャーを削除
     *
     * @tags Admin/Sponsor/Lectures
     * @name DeleteSponsorLectureAsAdmin
     * @summary 企業プロジェクトのレクチャーを削除
     * @request DELETE:/admin/sponsor/projects/lectures/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSponsorLectureAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/lectures/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Admin限定 企業プロジェクトのレクチャーを並び替える。
     *
     * @tags Admin/Sponsor/Lectures
     * @name PutSponsorLectureSequenceAsAdmin
     * @summary 企業プロジェクトのレクチャーを並び替える
     * @request PUT:/admin/sponsor/projects/lectures/sequence
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putSponsorLectureSequenceAsAdmin: (
      query: PutSponsorLectureSequenceAsAdminParams,
      data: SponsorLectureSequencesRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/admin/sponsor/projects/lectures/sequence${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業を登録。タグ、カテゴリー、画像、動画を一括保存する。
     *
     * @tags Admin/Sponsor/LectureFiles
     * @name CreateSponsorLectureFileAsAdmin
     * @summary レクチャーのファイルを登録
     * @request POST:/admin/sponsor/projects/lecture_files
     * @response `201` `SponsorLectureFileBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    createSponsorLectureFileAsAdmin: (data: SponsorLectureFileRequestBody, params?: RequestParams) =>
      this.request<SponsorLectureFileBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/lecture_files`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業プロジェクトのレクチャーのファイル詳細情報を返却
     *
     * @tags Admin/Sponsor/LectureFiles
     * @name FetchSponsorLectureFileAsAdmin
     * @summary 企業プロジェクトのレクチャーのファイル詳細情報を返却
     * @request GET:/admin/sponsor/projects/lecture_files/{id}
     * @response `200` `SponsorLectureFileBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLectureFileAsAdmin: (id: string, params?: RequestParams) =>
      this.request<SponsorLectureFileBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/lecture_files/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Admin限定 企業プロジェクトのレクチャーのファイル詳細情報を更新
     *
     * @tags Admin/Sponsor/LectureFiles
     * @name UpdateSponsorLectureFileAsAdmin
     * @summary 企業プロジェクトのレクチャーのファイル詳細情報を更新
     * @request PUT:/admin/sponsor/projects/lecture_files/{id}
     * @response `202` `SponsorLectureFileBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    updateSponsorLectureFileAsAdmin: (id: string, data: FileRequiredRequestBody, params?: RequestParams) =>
      this.request<SponsorLectureFileBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/lecture_files/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業プロジェクトのレクチャーファイルを削除
     *
     * @tags Admin/Sponsor/LectureFiles
     * @name DeleteSponsorLectureFileAsAdmin
     * @summary 企業プロジェクトのレクチャーファイルを削除
     * @request DELETE:/admin/sponsor/projects/lecture_files/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSponsorLectureFileAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/projects/lecture_files/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Admin限定 Pbl::Sponsor::ArticleContentTemplate を登録
     *
     * @tags Admin/Sponsor/ArticleContentTemplate
     * @name PostSponsorArticleContentTemplateAsAdmin
     * @summary 企業プロジェクトのポスターテンプレートを登録
     * @request POST:/admin/sponsor/article_content_templates
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postSponsorArticleContentTemplateAsAdmin: (
      query: PostSponsorArticleContentTemplateAsAdminParams,
      data: PostSponsorArticleContentTemplateRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/article_content_templates${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 Pbl::Sponsor::ArticleContentTemplate を返却
     *
     * @tags Admin/Sponsor/ArticleContentTemplate
     * @name GetSponsorArticleContentTemplateAsAdmin
     * @summary 企業プロジェクトのポスターテンプレート詳細情報を返却
     * @request GET:/admin/sponsor/article_content_templates/{id}
     * @response `200` `SponsorArticleContentTemplateBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getSponsorArticleContentTemplateAsAdmin: (
      { id, ...query }: GetSponsorArticleContentTemplateAsAdminParams,
      params?: RequestParams,
    ) =>
      this.request<SponsorArticleContentTemplateBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/article_content_templates/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Admin限定 Pbl::Sponsor::ArticleContentTemplate を更新
     *
     * @tags Admin/Sponsor/ArticleContentTemplate
     * @name PutSponsorArticleContentTemplateAsAdmin
     * @summary 企業プロジェクトのポスターテンプレートを更新
     * @request PUT:/admin/sponsor/article_content_templates/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putSponsorArticleContentTemplateAsAdmin: (
      { id, ...query }: PutSponsorArticleContentTemplateAsAdminParams,
      data: PutSponsorArticleContentTemplateFieldAsAdminRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/article_content_templates/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 Pbl::Sponsor::ArticleContentTemplate を削除
     *
     * @tags Admin/Sponsor/ArticleContentTemplate
     * @name DeleteSponsorArticleContentTemplateAsAdmin
     * @summary 企業プロジェクトのポスターテンプレートを削除
     * @request DELETE:/admin/sponsor/article_content_templates/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSponsorArticleContentTemplateAsAdmin: (
      { id, ...query }: DeleteSponsorArticleContentTemplateAsAdminParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/article_content_templates/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description Admin限定 Pbl::Sponsor::ArticleContentTemplateField を登録
     *
     * @tags Admin/Sponsor/ArticleContentTemplateField
     * @name PostSponsorArticleContentTemplateFieldAsAdmin
     * @summary 企業プロジェクトのポスターテンプレートの設問を登録
     * @request POST:/admin/sponsor/article_content_templates/{article_content_template_id}/article_content_template_fields
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postSponsorArticleContentTemplateFieldAsAdmin: (
      article_content_template_id: string,
      data: PutSponsorArticleContentTemplateFieldAsAdminRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/article_content_templates/${article_content_template_id}/article_content_template_fields`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 Pbl::Sponsor::ArticleContentTemplateField を更新
     *
     * @tags Admin/Sponsor/ArticleContentTemplateField
     * @name PutSponsorArticleContentTemplateFieldAsAdmin
     * @summary 企業プロジェクトのポスターテンプレートの設問を更新
     * @request PUT:/admin/sponsor/article_content_templates/{article_content_template_id}/article_content_template_fields/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putSponsorArticleContentTemplateFieldAsAdmin: (
      article_content_template_id: string,
      id: string,
      data: PutSponsorArticleContentTemplateFieldAsAdminRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/article_content_templates/${article_content_template_id}/article_content_template_fields/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Admin限定 Pbl::Sponsor::ArticleContentTemplateField を削除
     *
     * @tags Admin/Sponsor/ArticleContentTemplateField
     * @name DeleteSponsorArticleContentTemplateFieldAsAdmin
     * @summary 企業プロジェクトのポスターテンプレートの設問を削除
     * @request DELETE:/admin/sponsor/article_content_templates/{article_content_template_id}/article_content_template_fields/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSponsorArticleContentTemplateFieldAsAdmin: (
      article_content_template_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/article_content_templates/${article_content_template_id}/article_content_template_fields/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Admin限定 FeedbackTemplate を一覧を返却
     *
     * @tags Admin/FeedbackTemplates
     * @name GetFeedbackTemplatesAsAdmin
     * @summary 企業FBのテンプレート 一覧を返却
     * @request GET:/admin/feedback_templates
     * @response `200` `{ feedback_templates: (FeedbackTemplateBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplatesAsAdmin: (query: GetFeedbackTemplatesAsAdminParams, params?: RequestParams) =>
      this.request<
        { feedback_templates: FeedbackTemplateBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/feedback_templates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Admin限定 FeedbackTemplate を登録 - 配列でコメント追加可能
     *
     * @tags Admin/FeedbackTemplates
     * @name PostFeedbackTemplateAsAdmin
     * @summary 企業FBのテンプレート を登録
     * @request POST:/admin/feedback_templates
     * @response `201` `{ message?: string }` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postFeedbackTemplateAsAdmin: (data: FeedbackTemplateRequestBodyAsAdmin, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/feedback_templates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Admin限定 FeedbackTemplate の詳細を返却
     *
     * @tags Admin/FeedbackTemplates
     * @name GetFeedbackTemplateAsAdmin
     * @summary 企業FBのテンプレート の詳細を返却
     * @request GET:/admin/feedback_templates/{id}
     * @response `200` `FeedbackTemplateBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplateAsAdmin: (id: string, params?: RequestParams) =>
      this.request<FeedbackTemplateBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/feedback_templates/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Admin限定 FeedbackTemplate を更新
     *
     * @tags Admin/FeedbackTemplates
     * @name PutFeedbackTemplateAsAdmin
     * @summary 企業FBのテンプレート を更新
     * @request PUT:/admin/feedback_templates/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putFeedbackTemplateAsAdmin: (id: string, data: IconRequiredRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/feedback_templates/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Admin限定 FeedbackTemplate を削除
     *
     * @tags Admin/FeedbackTemplates
     * @name DeleteFeedbackTemplateAsAdmin
     * @summary 企業FBのテンプレート を削除
     * @request DELETE:/admin/feedback_templates/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteFeedbackTemplateAsAdmin: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/feedback_templates/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Admin限定 FeedbackTemplateComment を登録
     *
     * @tags Admin/FeedbackTemplateComments
     * @name PostFeedbackTemplateCommentAsAdmin
     * @summary 企業FBのテンプレートのコメント を登録
     * @request POST:/admin/feedback_templates/{feedback_template_id}/feedback_template_comments
     * @response `201` `{ message?: string }` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postFeedbackTemplateCommentAsAdmin: (
      feedback_template_id: string,
      data: BodyRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/feedback_templates/${feedback_template_id}/feedback_template_comments`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Admin限定 FeedbackTemplateComment を更新
     *
     * @tags Admin/FeedbackTemplateComments
     * @name PutFeedbackTemplateCommentAsAdmin
     * @summary 企業FBのテンプレートのコメント を更新
     * @request PUT:/admin/feedback_templates/{feedback_template_id}/feedback_template_comments/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putFeedbackTemplateCommentAsAdmin: (
      feedback_template_id: string,
      id: string,
      data: BodyRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/feedback_templates/${feedback_template_id}/feedback_template_comments/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Admin限定 FeedbackTemplateComment を削除
     *
     * @tags Admin/FeedbackTemplateComments
     * @name DeleteFeedbackTemplateCommentAsAdmin
     * @summary 企業FBのテンプレートのコメント を削除
     * @request DELETE:/admin/feedback_templates/{feedback_template_id}/feedback_template_comments/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteFeedbackTemplateCommentAsAdmin: (feedback_template_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/feedback_templates/${feedback_template_id}/feedback_template_comments/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Admin限定 企業に紐づかれている学校一覧を返却
     *
     * @tags Admin/Sponsor/Links
     * @name GetSponsorLinksAsAdmin
     * @summary 企業に紐づかれている学校一覧を返却
     * @request GET:/admin/sponsor/infos/{info_id}/links
     * @response `200` `{ sponsor_links: (SponsorLinkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSponsorLinksAsAdmin: ({ info_id, ...query }: GetSponsorLinksAsAdminParams, params?: RequestParams) =>
      this.request<
        { sponsor_links: SponsorLinkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/sponsor/infos/${info_id}/links${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Admin限定 企業に紐づかれている学校一覧を返却
     *
     * @tags Admin/Sponsor/Links
     * @name PostSponsorLinksAsAdmin
     * @summary 企業に紐づかれている学校を登録
     * @request POST:/admin/sponsor/infos/{info_id}/links
     * @response `201` `SponsorLinkBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postSponsorLinksAsAdmin: (info_id: string, data: SponsorInfoSchoolIdRequestBody, params?: RequestParams) =>
      this.request<SponsorLinkBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/admin/sponsor/infos/${info_id}/links`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Admin限定 企業に紐づかれている学校を削除
     *
     * @tags Admin/Sponsor/Links
     * @name DeleteSponsorLinkAsAdmin
     * @summary 企業に紐づかれている学校を削除
     * @request DELETE:/admin/sponsor/infos/{info_id}/links/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSponsorLinkAsAdmin: (info_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/sponsor/infos/${info_id}/links/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description 一括で学校紐付けを付与
     *
     * @tags Admin/Sponsor/Infos
     * @name AdminSponsorInfosSponsorLinkingBulkInsert
     * @request PATCH:/admin/sponsor/links/sponsor_linking_bulk_insert
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    adminSponsorInfosSponsorLinkingBulkInsert: (data: SponsorInfoLinkingBulkInsertParams, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/sponsor/links/sponsor_linking_bulk_insert`, "PATCH", params, data),

    /**
     * @description - Admin限定 AttachedImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags Admin/AttachedImages
     * @name PostAttachedImageAsAdmin
     * @summary 画像 を登録
     * @request POST:/admin/attached_images
     * @response `201` `AttachedImageBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postAttachedImageAsAdmin: (
      query: PostAttachedImageAsAdminParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/attached_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Admin限定 AttachedImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Admin/AttachedImages
     * @name GetAttachedImageAsAdmin
     * @summary 画像 の詳細を返却
     * @request GET:/admin/attached_images/{id}
     * @response `200` `AttachedImageBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getAttachedImageAsAdmin: ({ id, ...query }: GetAttachedImageAsAdminParams, params?: RequestParams) =>
      this.request<AttachedImageBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/attached_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperAdmin限定 AttachedImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Admin/AttachedImages
     * @name PutAttachedImageAsAdmin
     * @summary 画像 を更新
     * @request PUT:/admin/attached_images/{id}
     * @response `202` `AttachedImageBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putAttachedImageAsAdmin: (
      { id, ...query }: PutAttachedImageAsAdminParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | Unauthorized | Forbidden>(
        `/admin/attached_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Admin限定 AttachedImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Admin/AttachedImages
     * @name DeleteAttachedImageAsAdmin
     * @summary 画像 を削除
     * @request DELETE:/admin/attached_images/{id}
     * @response `200` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteAttachedImageAsAdmin: ({ id, ...query }: DeleteAttachedImageAsAdminParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/admin/attached_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Admin限定 Options::Expansion を登録 - 教材登録のディスク使用量制限 - あいである枚数制限のオプション追加
     *
     * @tags Admin/OptionsExpansion
     * @name PostOptionsExpansionAsAdmin
     * @summary オプションの拡張 を登録
     * @request POST:/admin/option_expansions
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postOptionsExpansionAsAdmin: (
      query: PostOptionsExpansionAsAdminParams,
      data: { type: "material_expansion" | "ideal_expansion"; capacity: OptionsExpansionCapacity },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/admin/option_expansions${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description - Admin限定 Options::Expansion を登録 - 教材登録のディスク使用量制限 - あいである枚数制限のオプション追加
     *
     * @tags Admin/OptionsExpansion
     * @name PutOptionsExpansionAsAdmin
     * @summary オプションの拡張 を登録
     * @request PUT:/admin/option_expansions/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putOptionsExpansionAsAdmin: (
      { id, ...query }: PutOptionsExpansionAsAdminParams,
      data: { capacity: OptionsExpansionCapacity },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/admin/option_expansions/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Admin限定 UniversityCooperation を一覧を返却 - 大学のみ
     *
     * @tags Admin/UniversityCooperations
     * @name GetUniversityCooperationsAsAdmin
     * @summary 大学連携一覧を返却
     * @request GET:/admin/companies/{company_id}/university_cooperations
     * @response `200` `{ university_cooperations: (UniversityCooperationBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getUniversityCooperationsAsAdmin: (
      { company_id, ...query }: GetUniversityCooperationsAsAdminParams,
      params?: RequestParams,
    ) =>
      this.request<
        { university_cooperations: UniversityCooperationBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/companies/${company_id}/university_cooperations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Admin限定 UniversityCooperation を登録 - 大学のみ
     *
     * @tags Admin/UniversityCooperations
     * @name PostUniversityCooperationAsAdmin
     * @summary 大学連携を登録
     * @request POST:/admin/companies/{company_id}/university_cooperations
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postUniversityCooperationAsAdmin: (company_id: string, data: TargetCompanyIdsRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/admin/companies/${company_id}/university_cooperations`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Admin限定 UniversityCooperation を削除 - 大学のみ
     *
     * @tags Admin/UniversityCooperations
     * @name DeleteUniversityCooperationAsAdmin
     * @summary 大学連携を削除
     * @request DELETE:/admin/companies/{company_id}/university_cooperations/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteUniversityCooperationAsAdmin: (company_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/companies/${company_id}/university_cooperations/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - admin限定 大学教職員一覧を返却
     *
     * @tags Users
     * @name GetTeacherUniversitiesAsAdmin
     * @summary 大学教職員一覧を返却
     * @request GET:/admin/teachers/university
     * @response `200` `{ university_teachers: (TeacherListForUniversityTeacher)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherUniversitiesAsAdmin: (query: GetTeacherUniversitiesAsAdminParams, params?: RequestParams) =>
      this.request<
        { university_teachers: TeacherListForUniversityTeacher[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/teachers/university${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Admin限定 TtCsvExport を一覧を返却"
     *
     * @tags Admin/TtCsvExports
     * @name GetTtCsvExportsAsAdmin
     * @summary CSV一覧を返却
     * @request GET:/admin/tt_csv/exports
     * @response `200` `{ tt_csv_exports: (TtCsvExportBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtCsvExportsAsAdmin: (query: GetTtCsvExportsAsAdminParams, params?: RequestParams) =>
      this.request<
        { tt_csv_exports: TtCsvExportBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/tt_csv/exports${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Admin限定 TtCsvExport を登録 - type: admin can only use starting with admin - target_company_id: required when exporting student_list, teacher_list and owner_list - homework_template_id: required when exporting homework_template_distribution_history_detail - learning_template_package_distribution_setting_id: required when exporting learning_package_distribution_history_detail - common_custom_master_map_id: required when exporting custom_list
     *
     * @tags Admin/TtCsvExports
     * @name PostTtCsvExportAsAdmin
     * @summary CSV出力 をリクエスト
     * @request POST:/admin/tt_csv/exports
     * @response `200` `TtCsvExportBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTtCsvExportAsAdmin: (
      query: PostTtCsvExportAsAdminParams,
      data: TtCsvExportRequestBody,
      params?: RequestParams,
    ) =>
      this.request<TtCsvExportBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/admin/tt_csv/exports${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Admin限定 Gogo::Linking 一覧を返却
     *
     * @tags Admin/Gogo/Linkings
     * @name GetGogoLinkingsAsAdmin
     * @summary 探究GoGoに紐づいている学校一覧を返却
     * @request GET:/admin/gogo/linkings
     * @response `200` `{ gogo_linkings: (GogoLinkingBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoLinkingsAsAdmin: (query: GetGogoLinkingsAsAdminParams, params?: RequestParams) =>
      this.request<
        { gogo_linkings: GogoLinkingBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/admin/gogo/linkings${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Admin限定 Gogo::Linking を登録
     *
     * @tags Admin/Gogo/Linkings
     * @name PostGogoLinkingAsAdmin
     * @summary 探究GoGoに紐づける学校を登録
     * @request POST:/admin/gogo/linkings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoLinkingAsAdmin: (
      query: PostGogoLinkingAsAdminParams,
      data: GogoLinkingRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/gogo/linkings${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Admin限定 Gogo::Linking を削除
     *
     * @tags Admin/Gogo/Linkings
     * @name DeleteGogoLinkingAsAdmin
     * @summary 探究GoGoに紐づいている学校を削除
     * @request DELETE:/admin/gogo/linkings/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoLinkingAsAdmin: ({ id, ...query }: DeleteGogoLinkingAsAdminParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/admin/gogo/linkings/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),
  };
  superOwner = {
    /**
     * No description
     *
     * @tags SuperOwner/GradeMasters
     * @name GradeMastersList
     * @summary スーパーオーナーの会社の卒業年度を取得
     * @request GET:/super_owner/grade_masters
     * @response `200` `{ grades?: ({ id?: string, grade?: string, grade_name?: string, graduation_year?: string })[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    gradeMastersList: (params?: RequestParams) =>
      this.request<
        { grades?: { id?: string; grade?: string; grade_name?: string; graduation_year?: string }[] },
        BadRequest | Unauthorized | Forbidden
      >(`/super_owner/grade_masters`, "GET", params),

    /**
     * No description
     *
     * @tags SuperOwner/GradeMasters
     * @name GradeMastersCreate
     * @summary スーパーオーナーの会社の卒業年度の作成
     * @request POST:/super_owner/grade_masters
     * @response `200` `{ grades?: ({ id?: string, grade?: string, grade_name?: string, graduation_year?: string })[] }` OK
     * @response `400` `{ message?: (any)[] }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    gradeMastersCreate: (data: GradeAndGraduationYearRequiredRequestBody, params?: RequestParams) =>
      this.request<
        { grades?: { id?: string; grade?: string; grade_name?: string; graduation_year?: string }[] },
        { message?: any[] } | Unauthorized | Forbidden
      >(`/super_owner/grade_masters`, "POST", params, data),

    /**
     * No description
     *
     * @tags SuperOwner/GradeMasters
     * @name GradeMastersUpdate
     * @summary gradeの卒業年度を更新
     * @request PUT:/super_owner/grade_masters/{id}
     * @response `200` `{ id?: string, grade?: string, grade_name?: string, graduation_year?: string }` OK
     * @response `202` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    gradeMastersUpdate: (id: string, data: GraduationYearRequiredRequestBody, params?: RequestParams) =>
      this.request<
        { id?: string; grade?: string; grade_name?: string; graduation_year?: string },
        Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/grade_masters/${id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags SuperOwnerEmails
     * @name EmailsDetail
     * @summary スーパーオーナーのEメール詳細取得
     * @request GET:/super_owner/emails/{super_owner_email_id}
     * @response `200` `{ super_owner_emails: (EmailBase)[], total_count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    emailsDetail: (super_owner_email_id: string, params?: RequestParams) =>
      this.request<
        { super_owner_emails: EmailBase[]; total_count?: number },
        Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/emails/${super_owner_email_id}`, "GET", params),

    /**
     * No description
     *
     * @tags SuperOwnerEmails
     * @name EmailsList
     * @summary スーパーオーナーが送ったEメール取得
     * @request GET:/super_owner/emails
     * @response `200` `{ emails?: (EmailBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    emailsList: (query: EmailsListParams, params?: RequestParams) =>
      this.request<{ emails?: EmailBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/super_owner/emails${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags SuperOwnerEmails
     * @name EmailsCreate
     * @summary スーパーオーナーがEメールを送信
     * @request POST:/super_owner/emails
     * @response `200` `{ super_owner_emails?: (EmailBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    emailsCreate: (data: EmailRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<{ super_owner_emails?: EmailBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/super_owner/emails`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags SuperOwner/MaterialsTicket
     * @name MaterialsTicketsCreate
     * @summary Create text selling hash ID
     * @request POST:/super_owner/materials_tickets
     * @response `200` `{ hash_ticket_id: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    materialsTicketsCreate: (data: MaterialCategoryIdRequiredRequestBody, params?: RequestParams) =>
      this.request<{ hash_ticket_id: string }, BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/super_owner/materials_tickets`,
        "POST",
        params,
        data,
      ),

    /**
     * @description チケットをもとに教材をコピーする
     *
     * @tags SuperOwner/MaterialsTicket
     * @name MaterialsTicketsAcceptUpdate
     * @request PUT:/super_owner/materials_tickets/accept
     * @response `200` `{ message: string }` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    materialsTicketsAcceptUpdate: (data: PutMaterialsTicketRequestBody, params?: RequestParams) =>
      this.request<{ message: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/materials_tickets/accept`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 教材カテゴリ名の変更
     *
     * @tags CategoryRenameMap, SuperOwner/CategoryRenameMap
     * @name UpdateCategoryRenameMapAsSuperOwner
     * @request PATCH:/super_owner/category_rename_maps/update
     * @response `200` `CategoryRenameMap` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updateCategoryRenameMapAsSuperOwner: (data: CategoryRenameMapRequestBody, params?: RequestParams) =>
      this.request<CategoryRenameMap, BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/super_owner/category_rename_maps/update`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description [Not implemented yet] https://github.com/study-valley/timetact-server/issues/441 This will return custom master map for super_owner.
     *
     * @tags SuperOwner/CustomMasterMap
     * @name GetSuperOwnerCustomMasterMaps
     * @summary SuperOwner to get list of custom master maps
     * @request GET:/super_owner/custom_master_maps
     * @response `200` `{ custom_master_maps: (CustomMasterMap)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSuperOwnerCustomMasterMaps: (query: GetSuperOwnerCustomMasterMapsParams, params?: RequestParams) =>
      this.request<
        { custom_master_maps: CustomMasterMap[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/custom_master_maps${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description [Not implemented yet] https://github.com/study-valley/timetact-server/issues/441 Super_owner to create a new master, by specifying the number of columns and column title
     *
     * @tags SuperOwner/CustomMasterMap
     * @name PostSuperOwnerCustomMasterMaps
     * @summary [SuperOwner] Create new master
     * @request POST:/super_owner/custom_master_maps
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postSuperOwnerCustomMasterMaps: (
      data: PostCustomMasterMapRequiredRequestBody & CustomMasterMapBase,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/custom_master_maps`,
        "POST",
        params,
        data,
      ),

    /**
     * @description カスタムリスト一覧の並べ替えが可能
     *
     * @tags SuperOwner/CustomMasterMap
     * @name CustomMasterMapsSequenceUpdate
     * @summary [SuperOwner] Update custom_master_map sequences
     * @request PUT:/super_owner/custom_master_maps/sequence
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    customMasterMapsSequenceUpdate: (data: CustomMasterMapSequencesRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden>(
        `/super_owner/custom_master_maps/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description [Not implemented yet] https://github.com/study-valley/timetact-server/issues/441 This will respond custom master detail, with actual master values
     *
     * @tags SuperOwner/CustomMasterMap
     * @name GetSuperOwnerCustomMasterMapsCustomMasterMapId
     * @summary SuperOwner to get master detail
     * @request GET:/super_owner/custom_master_maps/{custom_master_map_id}
     * @response `200` `(ModelBase & { master_name: string, used_columns: number, master_column_names: CustomMasterMap, master_values: (CustomMaster)[], master_values_count: number })` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSuperOwnerCustomMasterMapsCustomMasterMapId: (
      { custom_master_map_id, ...query }: GetSuperOwnerCustomMasterMapsCustomMasterMapIdParams,
      params?: RequestParams,
    ) =>
      this.request<
        ModelBase & {
          master_name: string;
          used_columns: number;
          master_column_names: CustomMasterMap;
          master_values: CustomMaster[];
          master_values_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/custom_master_maps/${custom_master_map_id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description [Not implemented yet] https://github.com/study-valley/timetact-server/issues/441 This will update Custom Master Map
     *
     * @tags SuperOwner/CustomMasterMap
     * @name PutSuperOwnerCustomMasterMapsCustomMasterMapId
     * @request PUT:/super_owner/custom_master_maps/{custom_master_map_id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putSuperOwnerCustomMasterMapsCustomMasterMapId: (
      custom_master_map_id: string,
      data: CustomMasterMapBase,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/custom_master_maps/${custom_master_map_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Delete the specified CustomMasterMap
     *
     * @tags SuperOwner/CustomMasterMap
     * @name DeleteSuperOwnerCustomMasterMapsCustomMasterMapId
     * @request DELETE:/super_owner/custom_master_maps/{custom_master_map_id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteSuperOwnerCustomMasterMapsCustomMasterMapId: (custom_master_map_id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/custom_master_maps/${custom_master_map_id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - company_idがparameterに含まれている場合、作成されたcustom_masterにcompanyを紐付ける。 - custom_master_map_id is required to identify which CMM the CM belongs to.
     *
     * @tags SuperOwner/CustomMaster
     * @name PostSuperOwnerCustomMasters
     * @summary [SuperOwner] Create new master value
     * @request POST:/super_owner/custom_masters
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postSuperOwnerCustomMasters: (
      data: CustomMasterRequestBody & PostCustomMasterRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/custom_masters`,
        "POST",
        params,
        data,
      ),

    /**
     * @description [Not implemented yet] https://github.com/study-valley/timetact-server/issues/443
     *
     * @tags SuperOwner/CustomMaster
     * @name PutSuperOwnerCustomMastersCustomMasterId
     * @summary [SuperOwner] Update master value
     * @request PUT:/super_owner/custom_masters/{custom_master_id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putSuperOwnerCustomMastersCustomMasterId: (
      custom_master_id: string,
      data: CustomMasterBase & PutCustomMasterRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/custom_masters/${custom_master_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description [Not implemented yet] https://github.com/study-valley/timetact-server/issues/443
     *
     * @tags SuperOwner/CustomMaster
     * @name DeleteSuperOwnerCustomMastersCustomMasterId
     * @request DELETE:/super_owner/custom_masters/{custom_master_id}
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteSuperOwnerCustomMastersCustomMasterId: (custom_master_id: string, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/custom_masters/${custom_master_id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags SuperOwner/CustomMaster
     * @name CustomMastersLinkingCompaniesList
     * @summary 指定のカラムと値に合致したカスタムリストに紐づいた学校を取得する
     * @request GET:/super_owner/custom_masters/linking_companies
     * @response `200` `{ linking_companies?: ({ id?: string, name?: string, column_value?: string })[] }` OK
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    customMastersLinkingCompaniesList: (query: CustomMastersLinkingCompaniesListParams, params?: RequestParams) =>
      this.request<
        { linking_companies?: { id?: string; name?: string; column_value?: string }[] },
        { message?: string } | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/custom_masters/linking_companies${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 既に作成したカスタムリストと学校を紐づける
     *
     * @tags SuperOwner/CustomMaster
     * @name CustomMastersLinkingCompanyUpdate
     * @summary カスタムリストと学校を紐づける
     * @request PUT:/super_owner/custom_masters/{custom_master_id}/linking_company
     * @response `200` `{ message?: string }`
     * @response `201` `{ message?: string }`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    customMastersLinkingCompanyUpdate: (custom_master_id: string, data: any, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(
        `/super_owner/custom_masters/${custom_master_id}/linking_company`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "SuperOwner限定 カスタムリストのリレーション設定一覧を返却"
     *
     * @tags SuperOwner/CustomMasterMapRelationships
     * @name GetCustomMasterMapRelationshipsAsSuperOwner
     * @summary カスタムリストのリレーション設定一覧を返却
     * @request GET:/super_owner/custom_master_map_relationships
     * @response `200` `{ relationships: (CustomMasterMapRelationshipBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCustomMasterMapRelationshipsAsSuperOwner: (
      query: GetCustomMasterMapRelationshipsAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { relationships: CustomMasterMapRelationshipBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/custom_master_map_relationships${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "SuperOwner限定 カスタムリストのリレーション設定を登録"
     *
     * @tags SuperOwner/CustomMasterMapRelationships
     * @name PostCustomMasterMapRelationshipsAsSuperOwner
     * @summary カスタムリストのリレーション設定を登録
     * @request POST:/super_owner/custom_master_map_relationships
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postCustomMasterMapRelationshipsAsSuperOwner: (
      query: PostCustomMasterMapRelationshipsAsSuperOwnerParams,
      data: PostCustomMasterMapRelationshipRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/custom_master_map_relationships${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description - "SuperOwner限定 カスタムリストのリレーション設定 の詳細を返却"
     *
     * @tags SuperOwner/CustomMasterMapRelationships
     * @name GetCustomMasterMapRelationshipAsSuperOwner
     * @summary カスタムリストのリレーション設定 の詳細を返却
     * @request GET:/super_owner/custom_master_map_relationships/{id}
     * @response `200` `CustomMasterMapRelationshipBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCustomMasterMapRelationshipAsSuperOwner: (
      { id, ...query }: GetCustomMasterMapRelationshipAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<CustomMasterMapRelationshipBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/custom_master_map_relationships/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - "SuperOwner限定 カスタムリストのリレーション設定 を更新" - nameのみ更新可能
     *
     * @tags SuperOwner/CustomMasterMapRelationships
     * @name PutCustomMasterMapRelationshipsAsSuperOwner
     * @summary カスタムリストのリレーション設定 を更新
     * @request PUT:/super_owner/custom_master_map_relationships/{id}
     * @response `202` `CustomMasterMapRelationshipBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putCustomMasterMapRelationshipsAsSuperOwner: (
      { id, ...query }: PutCustomMasterMapRelationshipsAsSuperOwnerParams,
      data: NameRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        CustomMasterMapRelationshipBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/custom_master_map_relationships/${id}${this.addQueryParams(query)}`, "PUT", params, data),

    /**
     * @description - "SuperOwner限定 カスタムリストのリレーション設定 を削除"
     *
     * @tags SuperOwner/CustomMasterMapRelationships
     * @name DeleteControllerAsSuperOwner
     * @summary カスタムリストのリレーション設定 を削除
     * @request DELETE:/super_owner/custom_master_map_relationships/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteControllerAsSuperOwner: ({ id, ...query }: DeleteControllerAsSuperOwnerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/custom_master_map_relationships/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - "SuperOwner限定 リレーション設定に紐づいているCustomMapの値を返却"
     *
     * @tags SuperOwner/CustomMasterMapRelationships
     * @name GetCustomMasterMapRelationshipChildCustomMastersAsSuperOwner
     * @summary リレーション設定に紐づいているCustomMapの値を返却
     * @request GET:/super_owner/custom_master_map_relationships/{id}/child_custom_masters
     * @response `200` `{ child_custom_master_columns: ({ id: string, name: string })[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCustomMasterMapRelationshipChildCustomMastersAsSuperOwner: (
      { id, ...query }: GetCustomMasterMapRelationshipChildCustomMastersAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { child_custom_master_columns: { id: string; name: string }[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/super_owner/custom_master_map_relationships/${id}/child_custom_masters${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description CustomMasterRelationshipを一括で作成
     *
     * @tags SuperOwner/CustomMasterRelationship
     * @name BatchCreateCustomMasterRelationshipAsSuperOwner
     * @request POST:/super_owner/custom_master_relationships/batch_create
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    batchCreateCustomMasterRelationshipAsSuperOwner: (
      data: BatchCreateCustomMasterMapRelationshipRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/custom_master_relationships/batch_create`, "POST", params, data),

    /**
     * @description params[:sent]がtrueの時はスーパーオーナーが送信したお知らせを取得する。ない場合はスーパーオーナー宛に送信されたお知らせを取得する。
     *
     * @tags SuperOwner/Notification
     * @name NotificationsList
     * @summary スーパーオーナー宛に送信されたお知らせを取得 or スーパーオーナーが送信したお知らせを取得(他のスーパーオーナーも含む)
     * @request GET:/super_owner/notifications
     * @response `200` `Notifications` OK
     */
    notificationsList: (query: NotificationsListParams, params?: RequestParams) =>
      this.request<Notifications, any>(`/super_owner/notifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - target_company_idsとtarget_rolesで指定したロールの対象会社のユーザにお知らせを送信 - target_user_tag_idsで紐づくユーザにお知らせを送信 - with_emailパラメータが送られてきている場合は、お知らせと同じ内容のメールを配信 - publication_timeが送られてきた場合は、その時間にお知らせを配信
     *
     * @tags SuperOwner/Notification
     * @name NotificationsCreate
     * @summary スーパーオーナーがオーナー、先生、生徒全体にお知らせを送信
     * @request POST:/super_owner/notifications
     * @response `200` `{ message?: string }`
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `404` `{ error?: string }`
     */
    notificationsCreate: (data: NotificationRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | { error?: string }>(
        `/super_owner/notifications`,
        "POST",
        params,
        data,
      ),

    /**
     * @description SuperOwner限定 お知らせの詳細を取得
     *
     * @tags SuperOwner/Notification
     * @name GetSuperOwnerNotificationId
     * @summary お知らせの詳細を取得
     * @request GET:/super_owner/notifications/{id}
     * @response `200` `Notification` OK
     * @response `400` `BadRequest`
     */
    getSuperOwnerNotificationId: (id: string, params?: RequestParams) =>
      this.request<Notification, BadRequest>(`/super_owner/notifications/${id}`, "GET", params),

    /**
     * @description - target_user_tag_idsが送られてきた場合は紐づくユーザにお知らせを送信 - target_company_tag_idsとtarget_rolesが送られてきた場合は指定したロールの紐づくユーザにお知らせを送信 - target_company_idsとtarget_rolesが送られてきた場合は指定したロールの対象会社のユーザにお知らせを送信 - target_company_tag_idsまたはtarget_company_idsを指定する場合はtarget_rolesは必須 - publication_timeは必須 - 予約配信時間が3分前のお知らせは変更できない
     *
     * @tags SuperOwner/Notification
     * @name PutSuperOwnerNotificationId
     * @summary SuperOwner: お知らせの情報更新
     * @request PUT:/super_owner/notifications/{id}
     * @response `200` `{ message?: string }`
     * @response `202` `Notification` Accepted
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    putSuperOwnerNotificationId: (id: string, data: NotificationRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/super_owner/notifications/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - 予約配信時間が3分前のお知らせは変更できない - お知らせ済みのものは削除できない
     *
     * @tags SuperOwner/Notification
     * @name DeleteSuperOwnerNotificationId
     * @summary SuperOwner: お知らせの削除
     * @request DELETE:/super_owner/notifications/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteSuperOwnerNotificationId: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/notifications/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description スーパーオーナーがユーザタグ分類を一覧取得。ユーザータグもすべて取得 user_tagsのオブジェクトに定義している
     *
     * @tags SuperOwner/UserTagType
     * @name CreatedUserTagTypesList
     * @request GET:/super_owner/created_user_tag_types
     * @response `200` `{ user_tag_types: (CreatedUserTagType)[], total_count: number }` OK
     */
    createdUserTagTypesList: (query: CreatedUserTagTypesListParams, params?: RequestParams) =>
      this.request<{ user_tag_types: CreatedUserTagType[]; total_count: number }, any>(
        `/super_owner/created_user_tag_types${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags SuperOwner/UsersSearch
     * @name GetSearchUserControllersAsSuperOwner
     * @summary Search for all users as a superowner
     * @request GET:/super_owner/users/search
     * @response `200` `{ users: (UserInfoForSuperOwner)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSearchUserControllersAsSuperOwner: (query: GetSearchUserControllersAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { users: UserInfoForSuperOwner[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/users/search${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - 親会社に関連するCompanyに所属しているユーザのみ - Owner, Teacher, Studentのロールのみ
     *
     * @tags SuperOwner/ShowAffiliate
     * @name GetShowAffiliateUserControllersAsSuperOwner
     * @summary Affiliate情報を取得する
     * @request GET:/super_owner/users/{id}/show_affiliate
     * @response `200` `(BasicUserInfo & AffiliateListBase)` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `404` `{ error?: string }`
     */
    getShowAffiliateUserControllersAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<BasicUserInfo & AffiliateListBase, BadRequest | Unauthorized | { error?: string }>(
        `/super_owner/users/${id}/show_affiliate`,
        "GET",
        params,
      ),

    /**
     * @description - 親会社に関連するCompanyのみを削除する - 自分自身を削除できない - Owner, Teacher, Studentのロールのみを削除
     *
     * @tags SuperOwner/BulkDeleteAffiliatesOfUser
     * @name BulkDeleteControllerAsSuperOwner
     * @summary Delete Affiliate associated with User
     * @request DELETE:/super_owner/users/{id}/bulk_destroy
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `404` `{ error?: string }`
     */
    bulkDeleteControllerAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | { error?: string }>(
        `/super_owner/users/${id}/bulk_destroy`,
        "DELETE",
        params,
      ),

    /**
     * @description スーパーオーナーがユーザタグ分類を新規作成
     *
     * @tags SuperOwner/UserTagType
     * @name UserTagTypesCreate
     * @request POST:/super_owner/user_tag_types
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    userTagTypesCreate: (data: PostUserTagTypeRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest>(`/super_owner/user_tag_types`, "POST", params, data),

    /**
     * @description ユーザにタグを付与
     *
     * @tags SuperOwner/UserTagging
     * @name UserTaggingsCreate
     * @request POST:/super_owner/user_taggings
     * @response `201` `{ number_of_tagging_users?: number, remaining_number_of_tagging_users?: number | null }` Created
     * @response `202` `{ message?: string }`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    userTaggingsCreate: (data: PostUserTaggingRequestBody, params?: RequestParams) =>
      this.request<
        { number_of_tagging_users?: number; remaining_number_of_tagging_users?: number | null },
        Forbidden | ErrorsWithKeyMessage
      >(`/super_owner/user_taggings`, "POST", params, data),

    /**
     * @description ユーザからタグを削除
     *
     * @tags SuperOwner/UserTagging
     * @name UserTaggingsDelete
     * @request DELETE:/super_owner/user_taggings
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     */
    userTaggingsDelete: (data: { user_id: string; user_tag_id: string; company_id: string }, params?: RequestParams) =>
      this.request<{ message?: string }, any>(`/super_owner/user_taggings`, "DELETE", params, data),

    /**
     * @description ユーザに一括でタグを付与
     *
     * @tags SuperOwner/UserTagging
     * @name SuperOwnerUserTaggingBatchCreate
     * @request POST:/super_owner/user_taggings/batch_create
     * @response `200` `UserTaggingBatchCreateResponses` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    superOwnerUserTaggingBatchCreate: (data: UserTaggingBatchCreateParams, params?: RequestParams) =>
      this.request<UserTaggingBatchCreateResponses, BadRequest | Unauthorized | Forbidden>(
        `/super_owner/user_taggings/batch_create`,
        "POST",
        params,
        data,
      ),

    /**
     * @description kindやtarget_company_idの更新は不可
     *
     * @tags SuperOwner/UserTagType
     * @name UserTagTypesUpdate
     * @request PUT:/super_owner/user_tag_types/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    userTagTypesUpdate: (id: string, data: PutUserTagTypeRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/super_owner/user_tag_types/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 削除
     *
     * @tags SuperOwner/UserTagType
     * @name UserTagTypesDelete
     * @request DELETE:/super_owner/user_tag_types/{id}
     * @response `200` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    userTagTypesDelete: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(`/super_owner/user_tag_types/${id}`, "DELETE", params),

    /**
     * @description - "SuperOwner限定 ReviewerCompanyGroupを一覧を返却" - "提出物タイプ一覧の返却"
     *
     * @tags SuperOwner/ReviewerCompanyGroup
     * @name GetReviewerCompanyGroupControllersAsSuperOwner
     * @summary ReviewerCompanyGroup一覧を返却
     * @request GET:/super_owner/reviewer_company_groups
     * @response `200` `{ reviewer_company_groups: (ReviewerCompanyGroupBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getReviewerCompanyGroupControllersAsSuperOwner: (
      query: GetReviewerCompanyGroupControllersAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { reviewer_company_groups: ReviewerCompanyGroupBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/reviewer_company_groups${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "SuperOwner限定 ReviewerCompanyGroupの詳細を返却"
     *
     * @tags SuperOwner/ReviewerCompanyGroup
     * @name GetReviewerCompanyGroupAsSuperOwner
     * @summary ReviewerCompanyGroupの詳細を返却
     * @request GET:/super_owner/reviewer_company_groups/{id}
     * @response `200` `{ reviewer_company_group: ReviewerCompanyGroupDetail }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getReviewerCompanyGroupAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<
        { reviewer_company_group: ReviewerCompanyGroupDetail },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/reviewer_company_groups/${id}`, "GET", params),

    /**
     * @description - "SuperOwner限定 ReviewerCompanyGroupを更新" - "コメント1"
     *
     * @tags SuperOwner/ReviewerCompanyGroup
     * @name PutReviewerCompanyGroupAsSuperOwner
     * @summary ReviewerCompanyGroupを更新
     * @request PUT:/super_owner/reviewer_company_groups/{id}
     * @response `202` `{ reviewer_company_group: ReviewerCompanyGroupDetail }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putReviewerCompanyGroupAsSuperOwner: (
      company_id: string,
      id: string,
      data: ReviewerCompanyGroupRequestBodyAsSuperOwner,
      params?: RequestParams,
    ) =>
      this.request<{ reviewer_company_group: ReviewerCompanyGroupDetail }, BadRequest | Unauthorized | Forbidden>(
        `/super_owner/reviewer_company_groups/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description スーパーアカウントと契約している会社の一覧を取得する。 「company_codes」と「master_name」と「column_num」をパラメーターとして受け取る場合、 それにより一致する会社の絞り込みを行う。
     *
     * @tags SuperOwner/Company
     * @name FetchCompaniesAsSuperOwner
     * @request GET:/super_owner/companies
     * @response `200` `{ companies?: ((Company & { reviewer_companies_by_reviewer_company_group?: ({ name?: string, reviewer_companies?: ({ id?: string, name?: string })[] })[] }))[], total_count?: number }` OK
     * @response `404` `{ error?: string }`
     */
    fetchCompaniesAsSuperOwner: (query: FetchCompaniesAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        {
          companies?: (Company & {
            reviewer_companies_by_reviewer_company_group?: {
              name?: string;
              reviewer_companies?: { id?: string; name?: string }[];
            }[];
          })[];
          total_count?: number;
        },
        { error?: string }
      >(`/super_owner/companies${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description スーパーアカウントと契約している会社を作成する
     *
     * @tags SuperOwner/Company
     * @name CreateCompanyAsSuperOwner
     * @request POST:/super_owner/companies
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     */
    createCompanyAsSuperOwner: (data: PostCompanyRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string }>(`/super_owner/companies`, "POST", params, data),

    /**
     * @description 契約中の会社の詳細情報を取得
     *
     * @tags SuperOwner/Company
     * @name FetchCompanyAsSuperOwner
     * @request GET:/super_owner/companies/{id}
     * @response `200` `CompanyAsSuperOwner` OK
     * @response `404` `{ error?: string }`
     */
    fetchCompanyAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<CompanyAsSuperOwner, { error?: string }>(`/super_owner/companies/${id}`, "GET", params),

    /**
     * @description 契約中の会社情報を変更
     *
     * @tags SuperOwner/Company
     * @name UpdateCompanyAsSuperOwner
     * @request PUT:/super_owner/companies/{id}
     * @response `200` `Company` OK
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updateCompanyAsSuperOwner: (id: string, data: PutCompanyRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<Company, { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/companies/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 契約会社の削除
     *
     * @tags SuperOwner/Company
     * @name DeleteCompanyAsSuperOwner
     * @request DELETE:/super_owner/companies/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `404` `{ error?: string }`
     */
    deleteCompanyAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | { error?: string }>(
        `/super_owner/companies/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 オプション拡張による制限の確認 - 教材登録のディスク使用量制限 - あいである枚数制限
     *
     * @tags SuperOwner/Company
     * @name GetCompanyLimitOptionAsSuperOwner
     * @summary オプション拡張による制限の確認
     * @request GET:/super_owner/companies/{id}/limit_option
     * @response `200` `{ limit_option: LimitOptionBase, total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCompanyLimitOptionAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<
        { limit_option: LimitOptionBase; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/companies/${id}/limit_option`, "GET", params),

    /**
     * @description 教材の容量制限
     *
     * @tags Companies
     * @name GetMaterialCapacityAsSuperOwner
     * @summary 教材の容量制限
     * @request GET:/super_owner/companies/{id}/material_capacity
     * @response `200` `{ material_capacity: string, material_limit_capacity: string | null }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getMaterialCapacityAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<
        { material_capacity: string; material_limit_capacity: string | null },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/companies/${id}/material_capacity`, "GET", params),

    /**
     * @description スーパーオーナーを一覧取得
     *
     * @tags SuperOwner/SuperOwner
     * @name CompaniesSuperOwnersDetail
     * @request GET:/super_owner/companies/{company_id}/super_owners
     * @response `200` `{ super_owners?: (AffiliateBase)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    companiesSuperOwnersDetail: ({ company_id, ...query }: CompaniesSuperOwnersDetailParams, params?: RequestParams) =>
      this.request<{ super_owners?: AffiliateBase[]; total_count?: number }, BadRequest | Unauthorized | Forbidden>(
        `/super_owner/companies/${company_id}/super_owners${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description オーナーを一覧取得
     *
     * @tags SuperOwner/Owner
     * @name CompaniesOwnersDetail
     * @request GET:/super_owner/companies/{company_id}/owners
     * @response `200` `{ owners: (OwnerListAsSuperOwner)[], total_count: number }` OK
     */
    companiesOwnersDetail: ({ company_id, ...query }: CompaniesOwnersDetailParams, params?: RequestParams) =>
      this.request<{ owners: OwnerListAsSuperOwner[]; total_count: number }, any>(
        `/super_owner/companies/${company_id}/owners${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description オーナーを新規作成
     *
     * @tags SuperOwner/Owner
     * @name CompaniesOwnersCreate
     * @request POST:/super_owner/companies/{company_id}/owners
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    companiesOwnersCreate: (
      { company_id, ...query }: CompaniesOwnersCreateParams,
      data: UserNameAndEmailRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/companies/${company_id}/owners${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description オーナーを削除
     *
     * @tags SuperOwner/Owner
     * @name CompaniesOwnersDelete
     * @request DELETE:/super_owner/companies/{company_id}/owners/{id}
     * @response `200` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    companiesOwnersDelete: (company_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(
        `/super_owner/companies/${company_id}/owners/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description オーナー情報編集(emailは不可)
     *
     * @tags SuperOwner/Owner
     * @name CompaniesOwnersUpdate
     * @request PUT:/super_owner/companies/{company_id}/owners/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    companiesOwnersUpdate: (company_id: string, id: string, data: UserNameRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/companies/${company_id}/owners/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 権限を付与するAPI
     *
     * @tags SuperOwner/Owner
     * @name CompaniesOwnersAuthorizationsCreate
     * @request POST:/super_owner/companies/{company_id}/owners/{id}/authorizations
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `422` `ErrorsWithKeyMessage`
     */
    companiesOwnersAuthorizationsCreate: (
      company_id: string,
      id: string,
      data: { authorizations?: UserAuthorization },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | ErrorsWithKeyMessage>(
        `/super_owner/companies/${company_id}/owners/${id}/authorizations`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 子会社の先生を一覧取得
     *
     * @tags SuperOwner/Teacher
     * @name CompaniesTeachersDetail
     * @request GET:/super_owner/companies/{company_id}/teachers
     * @response `200` `{ teachers: (TeacherListAsSuperOwner)[], total_count: number }` OK
     */
    companiesTeachersDetail: ({ company_id, ...query }: CompaniesTeachersDetailParams, params?: RequestParams) =>
      this.request<{ teachers: TeacherListAsSuperOwner[]; total_count: number }, any>(
        `/super_owner/companies/${company_id}/teachers${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - "Teacherが紐付いているユーザータグの情報を返却"
     *
     * @tags SuperOwner/Teachers
     * @name GetTeachersUserTagsAsSuperOwner
     * @summary Teacherが紐付いているユーザータグの情報を返却
     * @request GET:/super_owner/companies/{company_id}/teachers/{teacher_id}/user_tags
     * @response `200` `{ user_tag_types?: ((UserTagTypeBasic & { user_tags?: (UserTagBasic)[] }))[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeachersUserTagsAsSuperOwner: (teacher_id: string, company_id: string, params?: RequestParams) =>
      this.request<
        { user_tag_types?: (UserTagTypeBasic & { user_tags?: UserTagBasic[] })[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/companies/${company_id}/teachers/${teacher_id}/user_tags`, "GET", params),

    /**
     * @description 子会社の生徒を一覧取得
     *
     * @tags SuperOwner/Student
     * @name CompaniesStudentsDetail
     * @request GET:/super_owner/companies/{company_id}/students
     * @response `200` `{ students: (StudentListAsSuperOwner)[], student_input_settings: StudentInputSetting, total_count: number }` OK
     */
    companiesStudentsDetail: ({ company_id, ...query }: CompaniesStudentsDetailParams, params?: RequestParams) =>
      this.request<
        { students: StudentListAsSuperOwner[]; student_input_settings: StudentInputSetting; total_count: number },
        any
      >(`/super_owner/companies/${company_id}/students${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 学年ごとの生徒の数を返却する
     *
     * @tags SuperOwner/Student
     * @name StudentListGradeAsSuperOwner
     * @summary 学年ごとの生徒の数を返却する
     * @request GET:/super_owner/companies/{company_id}/students/list_grade
     * @response `200` `{ number_of_students_per_grade: ({ grade: StudentGradeJa, count: number })[], students_total_count: number }` OK
     */
    studentListGradeAsSuperOwner: (
      { company_id, ...query }: StudentListGradeAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { number_of_students_per_grade: { grade: StudentGradeJa; count: number }[]; students_total_count: number },
        any
      >(`/super_owner/companies/${company_id}/students/list_grade${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Studentが紐付いているユーザータグの情報を返却"
     *
     * @tags SuperOwner/Students
     * @name GetStudentsUserTagsAsSuperOwner
     * @summary Studentが紐付いているユーザータグの情報を返却
     * @request GET:/super_owner/companies/{company_id}/students/{id}/user_tags
     * @response `200` `{ user_tag_types?: ((UserTagTypeBasic & { user_tags?: (UserTagBasic)[] }))[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentsUserTagsAsSuperOwner: (id: string, company_id: string, params?: RequestParams) =>
      this.request<
        { user_tag_types?: (UserTagTypeBasic & { user_tags?: UserTagBasic[] })[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/companies/${company_id}/students/${id}/user_tags`, "GET", params),

    /**
     * @description - "Studentが紐付いているグループの情報を返却"
     *
     * @tags SuperOwner/Students
     * @name GetStudentsGroupAffiliatesAsSuperOwner
     * @summary Studentが紐付いているグループの情報を返却
     * @request GET:/super_owner/companies/{company_id}/students/{id}/group_affiliates
     * @response `200` `{ group_affiliate_packages?: (GroupAffiliatePackageMinimalBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentsGroupAffiliatesAsSuperOwner: (id: string, company_id: string, params?: RequestParams) =>
      this.request<
        { group_affiliate_packages?: GroupAffiliatePackageMinimalBase[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/companies/${company_id}/students/${id}/group_affiliates`, "GET", params),

    /**
     * @description 子会社のレビュアーを一覧取得
     *
     * @tags SuperOwner/Reviewer
     * @name FetchReviewersAsSuperOwner
     * @request GET:/super_owner/companies/{company_id}/reviewers
     * @response `200` `{ reviewers: (ReviewerDetail)[], total_count: number }` OK
     */
    fetchReviewersAsSuperOwner: ({ company_id, ...query }: FetchReviewersAsSuperOwnerParams, params?: RequestParams) =>
      this.request<{ reviewers: ReviewerDetail[]; total_count: number }, any>(
        `/super_owner/companies/${company_id}/reviewers${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description レビュアーを新規作成
     *
     * @tags SuperOwner/Reviewer
     * @name CreateReviewerAsSuperOwner
     * @request POST:/super_owner/companies/{company_id}/reviewers
     * @response `201` `ReviewerDetail` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createReviewerAsSuperOwner: (company_id: string, data: UserReviewersRequestBody, params?: RequestParams) =>
      this.request<ReviewerDetail, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/companies/${company_id}/reviewers`,
        "POST",
        params,
        data,
      ),

    /**
     * @description レビュアーを削除
     *
     * @tags SuperOwner/Reviewer
     * @name DeleteReviewerAsSuperOwner
     * @request DELETE:/super_owner/companies/{company_id}/reviewers/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    deleteReviewerAsSuperOwner: (company_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/super_owner/companies/${company_id}/reviewers/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description レビュアー情報編集(emailは不可)
     *
     * @tags SuperOwner/Reviewer
     * @name UpdateReviewerAsSuperOwner
     * @request PUT:/super_owner/companies/{company_id}/reviewers/{id}
     * @response `200` `ReviewerDetail` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updateReviewerAsSuperOwner: (
      company_id: string,
      id: string,
      data: UserReviewerRequestBody,
      params?: RequestParams,
    ) =>
      this.request<ReviewerDetail, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/companies/${company_id}/reviewers/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description スーパーオーナーが子会社の管理者or教職員or生徒を作成
     *
     * @tags SuperOwner/User
     * @name CreateUserAsSuperOwner
     * @summary スーパーオーナーが子会社の管理者or教職員or生徒を作成
     * @request POST:/super_owner/companies/{company_id}/users
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createUserAsSuperOwner: (company_id: string, data: PostUserRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/companies/${company_id}/users`,
        "POST",
        params,
        data,
      ),

    /**
     * @description (アカウントID使用)スーパーオーナーが子会社の管理者or教職員or生徒を作成
     *
     * @tags SuperOwner/User
     * @name CreateAccountUserAsSuperOwner
     * @summary (アカウントID使用)スーパーオーナーが子会社の管理者or教職員or生徒を作成
     * @request POST:/super_owner/companies/{company_id}/users/create_account_user
     * @response `201` `CreateAccountUserResponse` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createAccountUserAsSuperOwner: (company_id: string, data: CreateAccountUserRequestBody, params?: RequestParams) =>
      this.request<CreateAccountUserResponse, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/companies/${company_id}/users/create_account_user`,
        "POST",
        params,
        data,
      ),

    /**
     * @description SuperOwner限定:アカウント停止を利用中に変更する
     *
     * @tags SuperOwner/User
     * @name CreateAccountAvailableAsSuperOwner
     * @summary アカウント停止のユーザを利用中に変更
     * @request PUT:/super_owner/companies/{company_id}/users/account_available
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createAccountAvailableAsSuperOwner: (
      company_id: string,
      data: AffiliateIdRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/companies/${company_id}/users/account_available`, "PUT", params, data),

    /**
     * @description (アカウントID使用)スーパーオーナーが子会社の管理者or教職員or生徒のパスワードを変更
     *
     * @tags SuperOwner/User
     * @name ResetPasswordAsSuperOwner
     * @summary (アカウントID使用)スーパーオーナーが子会社の管理者or教職員or生徒のパスワードを変更
     * @request PUT:/super_owner/companies/users/{id}/reset_password
     * @response `200` `AccountUserInfo` ok
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    resetPasswordAsSuperOwner: (id: string, data: AuthorizationPasswordRequestBody, params?: RequestParams) =>
      this.request<AccountUserInfo, BadRequest | { error?: string }>(
        `/super_owner/companies/users/${id}/reset_password`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags SuperOwner/User
     * @name GetSuperOwnerUsersUserId
     * @summary スーパーオーナーが子会社の管理者or教職員or生徒情報を取得
     * @request GET:/super_owner/companies/{company_id}/users/{id}
     * @response `200` `UserDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSuperOwnerUsersUserId: ({ company_id, id, ...query }: GetSuperOwnerUsersUserIdParams, params?: RequestParams) =>
      this.request<UserDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/companies/${company_id}/users/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザー情報編集(emailは不可)
     *
     * @tags SuperOwner/User
     * @name CompaniesUsersUpdate
     * @summary スーパーオーナーが子会社の管理者or教職員or生徒情報を更新
     * @request PUT:/super_owner/companies/{company_id}/users/{id}
     * @response `200` `UserDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    companiesUsersUpdate: (
      company_id: string,
      id: string,
      data: PutUserRequestBodyAsSuperOwner,
      params?: RequestParams,
    ) =>
      this.request<UserDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/companies/${company_id}/users/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description ユーザーを削除
     *
     * @tags SuperOwner/User
     * @name DeleteUserAsSuperOwner
     * @request DELETE:/super_owner/companies/{company_id}/users/{id}
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    deleteUserAsSuperOwner: (company_id: string, id: string, data: RoleRequiredRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/super_owner/companies/${company_id}/users/${id}`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description タグ分類に紐づくタグを一覧取得
     *
     * @tags SuperOwner/UserTag
     * @name FetchUserTagsAsSuperOwner
     * @request GET:/super_owner/user_tags
     * @response `200` `{ user_tags?: (UserTag)[], total_count?: number }` OK
     * @response `400` `{ message?: string }` bad_request
     */
    fetchUserTagsAsSuperOwner: (query: FetchUserTagsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<{ user_tags?: UserTag[]; total_count?: number }, { message?: string }>(
        `/super_owner/user_tags${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 新規タグ作成
     *
     * @tags SuperOwner/UserTag
     * @name CreateUserTagAsSuperOwner
     * @request POST:/super_owner/user_tags
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    createUserTagAsSuperOwner: (data: PostUserTagRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest>(`/super_owner/user_tags`, "POST", params, data),

    /**
     * @description ユーザタグの詳細情報取得
     *
     * @tags SuperOwner/UserTag
     * @name GetUserTagAsSuperOwenr
     * @request GET:/super_owner/user_tags/{id}
     * @response `200` `UserTagDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getUserTagAsSuperOwenr: (id: string, params?: RequestParams) =>
      this.request<UserTagDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/user_tags/${id}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザタグ情報を更新
     *
     * @tags SuperOwner/UserTag
     * @name UpdateUserTagAsSuperOwner
     * @request PUT:/super_owner/user_tags/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    updateUserTagAsSuperOwner: (id: string, data: PutUserTagRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/user_tags/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 削除
     *
     * @tags SuperOwner/UserTag
     * @name DeleteUserTagAsSuperOwner
     * @request DELETE:/super_owner/user_tags/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteUserTagAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/user_tags/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description ユーザタグの並び替え
     *
     * @tags Owner/UserTag
     * @name UserTagsSequenceUpdate
     * @request PUT:/super_owner/user_tags/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTagsSequenceUpdate: (data: UserTagSequencesRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/user_tags/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ユーザタグを会社に割り当てる - 配信開始時を各学校の管理者と先生にメールで通知する
     *
     * @tags SuperOwner/UserTag
     * @name AssignUserTagToCompanyAsSuperOwner
     * @request POST:/super_owner/user_tags/{id}/assign
     * @response `201` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    assignUserTagToCompanyAsSuperOwner: (id: string, data: AssignUserTagRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/user_tags/${id}/assign`,
        "POST",
        params,
        data,
      ),

    /**
     * @description ユーザタグの配信情報を更新する
     *
     * @tags SuperOwner/UserTag
     * @name AssignUpdateUserTagToCompanyAsSuperOwner
     * @request PATCH:/super_owner/user_tags/{id}/assign_update
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    assignUpdateUserTagToCompanyAsSuperOwner: (id: string, data: AssignUserTagRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/user_tags/${id}/assign_update`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description - ユーザタグを会社からアンアサインする - 配信開始前の通知を削除する
     *
     * @tags SuperOwner/UserTag
     * @name UnassignUserTagToCompanyAsSuperOwner
     * @request DELETE:/super_owner/user_tags/{id}/unassign
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    unassignUserTagToCompanyAsSuperOwner: (id: string, data: { company_id: string }, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/super_owner/user_tags/${id}/unassign`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description ユーザタグに紐づく生徒を一覧取得
     *
     * @tags SuperOwner/UserTag/Student
     * @name UserTagsDistributedStudentsDetail
     * @request GET:/super_owner/user_tags/{id}/distributed_students
     * @response `200` `{ students: (SimpleUserList)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    userTagsDistributedStudentsDetail: (
      { id, ...query }: UserTagsDistributedStudentsDetailParams,
      params?: RequestParams,
    ) =>
      this.request<{ students: SimpleUserList[]; total_count: number }, { error?: string }>(
        `/super_owner/user_tags/${id}/distributed_students${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description オーナーがカンパニータグ分類を新規作成
     *
     * @tags SuperOwner/CompanyTagType
     * @name CompanyTagTypesCreate
     * @request POST:/super_owner/company_tag_types
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: (any)[] }` bad_request
     */
    companyTagTypesCreate: (data: NameRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: any[] }>(`/super_owner/company_tag_types`, "POST", params, data),

    /**
     * @description 更新
     *
     * @tags SuperOwner/CompanyTagType
     * @name CompanyTagTypesUpdate
     * @request PUT:/super_owner/company_tag_types/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: (any)[] }` bad_request
     * @response `404` `{ error?: string }`
     */
    companyTagTypesUpdate: (id: string, data: NameRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: any[] } | { error?: string }>(
        `/super_owner/company_tag_types/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 削除
     *
     * @tags SuperOwner/CompanyTagType
     * @name CompanyTagTypesDelete
     * @request DELETE:/super_owner/company_tag_types/{id}
     * @response `200` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    companyTagTypesDelete: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(`/super_owner/company_tag_types/${id}`, "DELETE", params),

    /**
     * @description 自分の会社が作成したカンパニータグを一覧取得
     *
     * @tags SuperOwner/CompanyTagType
     * @name CreatedCompanyTagTypesList
     * @request GET:/super_owner/created_company_tag_types
     * @response `200` `{ company_tag_types?: ((ModelBase & { name: string, company_tags?: ((ModelBase & { name: string, number_of_linking_companies?: number, linking_companies?: ({ id: string, name: string, number_of_linking_users?: number })[] }))[] }))[], total_count?: number }` OK
     */
    createdCompanyTagTypesList: (query: CreatedCompanyTagTypesListParams, params?: RequestParams) =>
      this.request<
        {
          company_tag_types?: (ModelBase & {
            name: string;
            company_tags?: (ModelBase & {
              name: string;
              number_of_linking_companies?: number;
              linking_companies?: { id: string; name: string; number_of_linking_users?: number }[];
            })[];
          })[];
          total_count?: number;
        },
        any
      >(`/super_owner/created_company_tag_types${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description カンパニータグタイプの並び替え
     *
     * @tags SuperOwner/CompanyTagType
     * @name CompanyTagTypesSequenceUpdate
     * @request PUT:/super_owner/company_tag_types/sequence
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `404` `{ error?: string }`
     */
    companyTagTypesSequenceUpdate: (data: CompanyTagTypeSequencesRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | { error?: string }>(
        `/super_owner/company_tag_types/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description ユーザタグタイプの並び替え
     *
     * @tags SuperOwner/UserTagType
     * @name UserTagTypesSequenceUpdate
     * @request PUT:/super_owner/user_tag_types/sequence
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `404` `{ error?: string }`
     */
    userTagTypesSequenceUpdate: (data: UserTagTypeSequencesRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | { error?: string }>(
        `/super_owner/user_tag_types/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 UserTagDistributionHistory の配信履歴一覧を返却 - SuperOwnerのCompanyに属しているスーパーオーナーは取得可能
     *
     * @tags SuperOwner/UserTagDistributionHistories
     * @name GetUserTagDistributionHistoriesAsSuperOwner
     * @summary 限定ユーザータグの配信履歴 一覧を返却
     * @request GET:/super_owner/user_tag_distribution_histories/
     * @response `200` `{ user_tag_distribution_histories: (any)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getUserTagDistributionHistoriesAsSuperOwner: (
      query: GetUserTagDistributionHistoriesAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { user_tag_distribution_histories: any[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/user_tag_distribution_histories/${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 自社が作成したカンパニータグを一覧取得
     *
     * @tags SuperOwner/CompanyTag
     * @name CompaniesCompanyTagsDetail
     * @request GET:/super_owner/companies/{company_id}/company_tags
     * @response `200` `{ company_tags?: (CompanyTag)[], total_count?: number }` OK
     */
    companiesCompanyTagsDetail: (company_id: string, params?: RequestParams) =>
      this.request<{ company_tags?: CompanyTag[]; total_count?: number }, any>(
        `/super_owner/companies/${company_id}/company_tags`,
        "GET",
        params,
      ),

    /**
     * @description カンパニータグを一覧取得
     *
     * @tags SuperOwner/CompanyTag
     * @name FetchCompanyTagsAsSuperOwner
     * @request GET:/super_owner/company_tags
     * @response `200` `{ company_tags?: (CompanyTag)[], total_count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchCompanyTagsAsSuperOwner: (query: FetchCompanyTagsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<{ company_tags?: CompanyTag[]; total_count?: number }, Unauthorized | Forbidden>(
        `/super_owner/company_tags${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 新規タグ作成
     *
     * @tags SuperOwner/CompanyTag
     * @name CreateCompanyTagAsSuperOwner
     * @request POST:/super_owner/company_tags
     * @response `201` `CompanyTag` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    createCompanyTagAsSuperOwner: (data: PostCompanyTagRequestBody, params?: RequestParams) =>
      this.request<CompanyTag, BadRequest | { error?: string }>(`/super_owner/company_tags`, "POST", params, data),

    /**
     * @description 更新
     *
     * @tags SuperOwner/CompanyTag
     * @name UpdateCompanyTagAsSuperOwner
     * @request PUT:/super_owner/company_tags/{id}
     * @response `200` `CompanyTag` OK
     * @response `400` `{ message?: (any)[] }` bad_request
     * @response `404` `{ error?: string }`
     */
    updateCompanyTagAsSuperOwner: (id: string, data: NameRequestBody, params?: RequestParams) =>
      this.request<CompanyTag, { message?: any[] } | { error?: string }>(
        `/super_owner/company_tags/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 削除
     *
     * @tags SuperOwner/CompanyTag
     * @name DeleteCompanyTagAsSuperOwner
     * @request DELETE:/super_owner/company_tags/{id}
     * @response `200` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    deleteCompanyTagAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(`/super_owner/company_tags/${id}`, "DELETE", params),

    /**
     * @description カンパニータグの並び替え
     *
     * @tags SuperOwner/CompanyTag
     * @name CompanyTagsSequenceUpdate
     * @request PUT:/super_owner/company_tags/sequence
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `404` `{ error?: string }`
     */
    companyTagsSequenceUpdate: (data: CompanyTagSequencesRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | { error?: string }>(
        `/super_owner/company_tags/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 会社にタグを付与
     *
     * @tags SuperOwner/CompanyTagging
     * @name CompanyTaggingsCreate
     * @request POST:/super_owner/company_taggings
     * @response `201` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    companyTaggingsCreate: (data: CompanyTaggingRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(`/super_owner/company_taggings`, "POST", params, data),

    /**
     * @description ユーザからタグを削除
     *
     * @tags SuperOwner/CompanyTagging
     * @name CompanyTaggingsDelete
     * @request DELETE:/super_owner/company_taggings
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    companyTaggingsDelete: (data: { company_id?: string; company_tag_id?: string }, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(`/super_owner/company_taggings`, "DELETE", params, data),

    /**
     * @description スーパーオーナーが作成したHomeworkTemplateの一覧を取得
     *
     * @tags SuperOwner/HomeworkTemplate, SuperOwner/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplatesAsSuperOwner
     * @request GET:/super_owner/homework_templates
     * @response `200` `{ homework_templates: (HomeworkTemplateBaseForSuperOwner)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    fetchHomeworkTemplatesAsSuperOwner: (query: FetchHomeworkTemplatesAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { homework_templates: HomeworkTemplateBaseForSuperOwner[]; total_count: number },
        { error?: string }
      >(`/super_owner/homework_templates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description HomeworkTemplateを作成し、作成されたHomeworkTemplateを返す
     *
     * @tags SuperOwner/HomeworkTemplate, SuperOwner/Homework, Homework, HomeworkTemplate
     * @name CreateHomeworkTemplateAsSuperOwner
     * @request POST:/super_owner/homework_templates
     * @response `201` `HomeworkTemplate` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createHomeworkTemplateAsSuperOwner: (data: HomeworkTemplateCreateRequest, params?: RequestParams) =>
      this.request<HomeworkTemplate, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/homework_templates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 指定したHomeworkTemplateを取得する
     *
     * @tags SuperOwner/HomeworkTemplate, SuperOwner/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplateAsSuperOwner
     * @request GET:/super_owner/homework_templates/{homework_template_id}
     * @response `200` `HomeworkTemplate` OK
     * @response `404` `{ error?: string }`
     */
    fetchHomeworkTemplateAsSuperOwner: (homework_template_id: string, params?: RequestParams) =>
      this.request<HomeworkTemplate, { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}`,
        "GET",
        params,
      ),

    /**
     * @description HomeworkTemplateをアップデートする。
     *
     * @tags SuperOwner/HomeworkTemplate, SuperOwner/Homework, Homework, HomeworkTemplate
     * @name UpdateHomeworkTemplateAsSuperOwner
     * @request PUT:/super_owner/homework_templates/{homework_template_id}
     * @response `200` `HomeworkTemplate` OK
     * @response `404` `{ error?: string }`
     */
    updateHomeworkTemplateAsSuperOwner: (
      homework_template_id: string,
      data: HomeworkTemplateUpdateRequest,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplate, { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description HomeworkTemplateの削除
     *
     * @tags SuperOwner/HomeworkTemplate
     * @name DeleteHomeworkTemplateAsSuperOwner
     * @request DELETE:/super_owner/homework_templates/{homework_template_id}
     * @response `200` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    deleteHomeworkTemplateAsSuperOwner: (homework_template_id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}`,
        "DELETE",
        params,
      ),

    /**
     * @description HomeworkTempalteと結びついたdistribution一覧を取得
     *
     * @tags SuperOwner/HomeworkTemplate, SuperOwner/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplateDistributionsAsSuperOwner
     * @request GET:/super_owner/homework_templates/{homework_template_id}/distributions
     * @response `200` `{ homework_template_distributions: (HomeworkTemplateDistribution)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    fetchHomeworkTemplateDistributionsAsSuperOwner: (
      { homework_template_id, ...query }: FetchHomeworkTemplateDistributionsAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { homework_template_distributions: HomeworkTemplateDistribution[]; total_count: number },
        { error?: string }
      >(
        `/super_owner/homework_templates/${homework_template_id}/distributions${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - メール通知先：companyに所属するownerとteacher全員。個別。 - メール内容：宿題テンプレートが配信されたこと
     *
     * @tags SuperOwner/HomeworkTemplateDistribution, SuperOwner/HomeworkTemplate, SuperOwner/Homework, Homework, HomeworkTemplate, HomeworkTemplateDistribution
     * @name BatchCreateHomeworkTemplateDistributionsAsSuperOwner
     * @summary 企業と宿題テンプレートの組み合わせに対してそれぞれHomeworkTemplateDistributionを作成する。同時にメール通知する。
     * @request PATCH:/super_owner/homework_templates/{homework_template_id}/distributions/batch_create
     * @response `200` `{ distributions: (HomeworkTemplateDistribution)[], company_ids: (number)[], errors: (string)[] }` OK
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    batchCreateHomeworkTemplateDistributionsAsSuperOwner: (
      homework_template_id: string,
      data: BatchCreateHomeworkTemplateDistributionRequestBodyAsSuperOwner,
      params?: RequestParams,
    ) =>
      this.request<
        { distributions: HomeworkTemplateDistribution[]; company_ids: number[]; errors: string[] },
        { message?: string } | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/homework_templates/${homework_template_id}/distributions/batch_create`, "PATCH", params, data),

    /**
     * @description start_atとend_atを更新した場合、該当するHomeworkのstart_atとend_atも更新される。
     *
     * @tags SuperOwner/HomeworkTemplateDistribution, SuperOwner/HomeworkTemplate, SuperOwner/Homework, Homework, HomeworkTemplate, HomeworkTemplateDistribution
     * @name BatchUpdateHomeworkTemplateDistributionsAsSuperOwner
     * @summary 複数のHomeworkTemplateDistributionを一度に更新する。
     * @request PATCH:/super_owner/homework_templates/{homework_template_id}/distributions/batch_update
     * @response `200` `{ message?: string }`
     * @response `201` `{ distributions: (HomeworkTemplateDistribution)[], errors: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    batchUpdateHomeworkTemplateDistributionsAsSuperOwner: (
      homework_template_id: string,
      data: {
        distribution_ids: string[];
        max_distribution_end_users: number;
        start_at?: string;
        end_at?: string;
        reviewer_end_at?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/distributions/batch_update`,
        "PATCH",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags HomeworkTemplate, HomeworkTemplateDistribution, SuperOwner/HomeworkTemplate, SuperOwner/HomeworkTemplateDistribution
     * @name BatchDeleteHomeworkTemplateDistributionsAsSuperOwner
     * @summary 複数の宿題テンプレートの配信を削除する。
     * @request PATCH:/super_owner/homework_templates/{homework_template_id}/distributions/batch_delete
     * @response `200` `BatchDeleteHomeworkTemplateDistributionRequestBodyAsSuperOwner` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    batchDeleteHomeworkTemplateDistributionsAsSuperOwner: (
      homework_template_id: string,
      data: { distribution_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<
        BatchDeleteHomeworkTemplateDistributionRequestBodyAsSuperOwner,
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/homework_templates/${homework_template_id}/distributions/batch_delete`, "PATCH", params, data),

    /**
     * @description 指定したdistributionを取得
     *
     * @tags SuperOwner/HomeworkTemplateDistribution, SuperOwner/HomeworkTemplate, SuperOwner/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplateDistributionAsSuperOwner
     * @request GET:/super_owner/homework_templates/{homework_template_id}/distributions/{distribution_id}
     * @response `200` `HomeworkTemplateDistribution` OK
     * @response `404` `{ error?: string }`
     */
    fetchHomeworkTemplateDistributionAsSuperOwner: (
      homework_template_id: string,
      distribution_id: string,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateDistribution, { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/distributions/${distribution_id}`,
        "GET",
        params,
      ),

    /**
     * @description start_atとend_atを更新した場合、該当するHomeworkのstart_atとend_atも更新される。
     *
     * @tags SuperOwner/HomeworkTemplateDistribution, SuperOwner/HomeworkTemplate, SuperOwner/Homework, Homework, HomeworkTemplate
     * @name UpdateHomeworkTemplateDistributionAsSuperOwner
     * @summary HomeworkTemplateDistributionをアップデートする。
     * @request PUT:/super_owner/homework_templates/{homework_template_id}/distributions/{distribution_id}
     * @response `200` `HomeworkTemplateDistribution` OK
     * @response `404` `{ error?: string }`
     */
    updateHomeworkTemplateDistributionAsSuperOwner: (
      homework_template_id: string,
      distribution_id: string,
      data: PutHomeworkTemplateDistributionRequestBodyAsSuperOwner,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateDistribution, { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/distributions/${distribution_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description HomeworkTemplateDistributionの削除
     *
     * @tags SuperOwner/HomeworkTemplateDistribution
     * @name DeleteHomeworkTemplateDistributionAsSuperOwner
     * @request DELETE:/super_owner/homework_templates/{homework_template_id}/distributions/{distribution_id}
     * @response `200` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    deleteHomeworkTemplateDistributionAsSuperOwner: (
      homework_template_id: string,
      distribution_id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/distributions/${distribution_id}`,
        "DELETE",
        params,
      ),

    /**
     * @description HomeworkTemplateを複製する
     *
     * @tags SuperOwner/HomeworkTemplate, HomeworkTemplate
     * @name PostHomeworkTemplateDuplicateAsSuperOwner
     * @request POST:/super_owner/homework_templates/{id}/duplicate
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postHomeworkTemplateDuplicateAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/homework_templates/${id}/duplicate`,
        "POST",
        params,
      ),

    /**
     * @description - SuperOwner限定 HomeworkTemplateImage を登録
     *
     * @tags SuperOwner/HomeworkTemplateImage
     * @name PostHomeworkTemplateImageAsSuperOwner
     * @summary 提出物テンプレートの画像 を登録
     * @request POST:/super_owner/homework_templates/{homework_template_id}/homework_template_images
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postHomeworkTemplateImageAsSuperOwner: (
      homework_template_id: string,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/homework_template_images`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 HomeworkTemplateImage を更新
     *
     * @tags SuperOwner/HomeworkTemplateImage
     * @name PutHomeworkTemplateImageAsSuperOwner
     * @summary 提出物テンプレートの画像 を更新
     * @request PUT:/super_owner/homework_templates/{homework_template_id}/homework_template_images/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putHomeworkTemplateImageAsSuperOwner: (
      homework_template_id: string,
      id: string,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/homework_template_images/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 HomeworkTemplateImage を削除
     *
     * @tags SuperOwner/HomeworkTemplateImage
     * @name DeleteHomeworkTemplateImageAsSuperOwner
     * @summary 提出物テンプレートの画像 を削除
     * @request DELETE:/super_owner/homework_templates/{homework_template_id}/homework_template_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteHomeworkTemplateImageAsSuperOwner: (homework_template_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/homework_template_images/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description 小会社に付与されているユーザタグを取得
     *
     * @tags SuperOwner/UserTag
     * @name CompaniesUserTagsDetail
     * @request GET:/super_owner/companies/{company_id}/user_tags
     * @response `200` `{ user_tags: (UserTag)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    companiesUserTagsDetail: ({ company_id, ...query }: CompaniesUserTagsDetailParams, params?: RequestParams) =>
      this.request<{ user_tags: UserTag[]; total_count: number }, { error?: string }>(
        `/super_owner/companies/${company_id}/user_tags${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザタグに紐づく生徒を一覧取得
     *
     * @tags SuperOwner/UserTag/User
     * @name UserTagsUsersDetail
     * @request GET:/super_owner/user_tags/{user_tag_id}/users
     * @response `200` `{ users: (BasicUserInfo)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    userTagsUsersDetail: ({ user_tag_id, ...query }: UserTagsUsersDetailParams, params?: RequestParams) =>
      this.request<{ users: BasicUserInfo[]; total_count: number }, { error?: string }>(
        `/super_owner/user_tags/${user_tag_id}/users${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 予約配信の削除
     *
     * @tags SuperOwner/Reservation
     * @name ReservationsDelete
     * @request DELETE:/super_owner/reservations/{id}
     * @response `204` `any` No Content
     * @response `404` `{ error?: string }`
     */
    reservationsDelete: (id: string, params?: RequestParams) =>
      this.request<any, { error?: string }>(`/super_owner/reservations/${id}`, "DELETE", params),

    /**
     * @description タグに紐づくユーザに教材を一斉配信。 publication_timeを指定する場合はその時刻に配信する。 関連するsuper_owner, owner, teacher, studentにお知らせ・メールを送信する。 処理はバックグラウンドで実行する
     *
     * @tags SuperOwner/MaterialWork
     * @name MaterialWorksBatchCreateUsingTagsCreate
     * @request POST:/super_owner/material_works/batch_create_using_tags
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    materialWorksBatchCreateUsingTagsCreate: (
      data: BatchCreateMaterialWorkRequestBodyAsSuperOwner,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/material_works/batch_create_using_tags`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 自社の教材を一覧取得
     *
     * @tags SuperOwner/Material
     * @name MaterialsList
     * @request GET:/super_owner/materials
     * @response `200` `{ materials: (MaterialList)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    materialsList: (query: MaterialsListParams, params?: RequestParams) =>
      this.request<{ materials: MaterialList[]; total_count: number }, Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/materials${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 教材を新規作成
     *
     * @tags SuperOwner/Material
     * @name MaterialsCreate
     * @request POST:/super_owner/materials
     * @response `201` `MaterialDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    materialsCreate: (data: CreateMaterialRequestBody, params?: RequestParams) =>
      this.request<MaterialDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/materials`,
        "POST",
        params,
        data,
      ),

    /**
     * @description カテゴリー配下にある教材を全て取得する。
     *
     * @tags SuperOwner/Material
     * @name MaterialsAllContainedCategoryList
     * @request GET:/super_owner/materials/all_contained_category
     * @response `200` `{ materials: (HasIdAndTitle)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    materialsAllContainedCategoryList: (query: MaterialsAllContainedCategoryListParams, params?: RequestParams) =>
      this.request<{ materials: HasIdAndTitle[]; total_count: number }, BadRequest | { error?: string }>(
        `/super_owner/materials/all_contained_category${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 詳細情報取得
     *
     * @tags SuperOwner/Material
     * @name MaterialsDetail
     * @request GET:/super_owner/materials/{id}
     * @response `200` `MaterialDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    materialsDetail: (id: string, params?: RequestParams) =>
      this.request<MaterialDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/materials/${id}`,
        "GET",
        params,
      ),

    /**
     * @description 教材情報を編集(画像や動画の追加、削除)
     *
     * @tags SuperOwner/Material
     * @name MaterialsUpdate
     * @request PUT:/super_owner/materials/{id}
     * @response `200` `MaterialDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    materialsUpdate: (id: string, data: UpdateMaterialRequestBody, params?: RequestParams) =>
      this.request<MaterialDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/materials/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 自社教材を削除
     *
     * @tags SuperOwner/Material
     * @name MaterialsDelete
     * @request DELETE:/super_owner/materials/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    materialsDelete: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/materials/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Update material sequences to order by sequence. If sequences are not specified, sort order will be done by object ID.
     *
     * @tags SuperOwner/Material
     * @name PutSuperOwnerMaterialsSequence
     * @summary [SuperOwner] Update material sequences
     * @request PUT:/super_owner/materials/sequence
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putSuperOwnerMaterialsSequence: (data: MaterialSequencesRequiredRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/super_owner/materials/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 ZIPファイルから教材 を登録 - アップロードはZIPファイルのみ - ZIPファイルにHTMLファイルが一つのみ存在すること - ZIPファイルにHTMLファイルが複数存在するとエラーになる - ZIPファイルにHTMLファイルが存在しない場合エラーになる
     *
     * @tags SuperOwner/Material
     * @name PutMaterialImportHtmlAsSuperOwner
     * @summary ZIPファイルから教材 を登録
     * @request PUT:/super_owner/materials/{material_id}/import_html
     * @response `201` `MaterialDetail` Accepted
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putMaterialImportHtmlAsSuperOwner: (
      material_id: string,
      data: ZipFileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<MaterialDetail, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/materials/${material_id}/import_html`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 教材 を複製する - category_idを指定すること
     *
     * @tags SuperOwner/Material
     * @name PostMaterialDuplicateAsSuperOwner
     * @summary 教材 を複製する
     * @request POST:/super_owner/materials/{id}/duplicate
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postMaterialDuplicateAsSuperOwner: (
      id: string,
      data: MaterialCategoryIdRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/materials/${id}/duplicate`,
        "POST",
        params,
        data,
      ),

    /**
     * @description カテゴリーの一覧取得
     *
     * @tags SuperOwner/Category
     * @name CategoriesList
     * @request GET:/super_owner/categories
     * @response `200` `{ grades?: (GradeCategoryInfo)[], texts?: (TextCategoryInfo)[], subjects?: (SubjectCategoryInfo)[], major_categories?: (MajorCategoryInfo)[], middle_categories?: (MiddleCategoryInfo)[], minor_categories?: (MinorCategoryInfo)[] }` OK
     */
    categoriesList: (params?: RequestParams) =>
      this.request<
        {
          grades?: GradeCategoryInfo[];
          texts?: TextCategoryInfo[];
          subjects?: SubjectCategoryInfo[];
          major_categories?: MajorCategoryInfo[];
          middle_categories?: MiddleCategoryInfo[];
          minor_categories?: MinorCategoryInfo[];
        },
        any
      >(`/super_owner/categories`, "GET", params),

    /**
     * @description - カテゴリーの新規登録 - is_helpがtueのカテゴリの場合、rolesで見える権限を指定できる
     *
     * @tags SuperOwner/Category
     * @name CategoriesCreate
     * @request POST:/super_owner/categories
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    categoriesCreate: (data: PostCategoryRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/super_owner/categories`,
        "POST",
        params,
        data,
      ),

    /**
     * @description カテゴリーの詳細情報を取得
     *
     * @tags SuperOwner/Category
     * @name CategoriesDetail
     * @request GET:/super_owner/categories/{id}
     * @response `200` `CategoryInfo` OK
     * @response `404` `{ error?: string }`
     */
    categoriesDetail: (id: string, params?: RequestParams) =>
      this.request<CategoryInfo, { error?: string }>(`/super_owner/categories/${id}`, "GET", params),

    /**
     * @description - カテゴリー情報編集 - is_helpがtueのカテゴリの場合、rolesで見える権限を指定できる
     *
     * @tags SuperOwner/Category
     * @name CategoriesUpdate
     * @request PUT:/super_owner/categories/{id}
     * @response `200` `CategoryInfo` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    categoriesUpdate: (id: string, data: PutCategoryRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<CategoryInfo, BadRequest | { error?: string }>(`/super_owner/categories/${id}`, "PUT", params, data),

    /**
     * @description カテゴリー削除
     *
     * @tags SuperOwner/Category
     * @name CategoriesDelete
     * @request DELETE:/super_owner/categories/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    categoriesDelete: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/super_owner/categories/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description カテゴリーの表示順序変更
     *
     * @tags SuperOwner/Category
     * @name CategoriesSequenceUpdate
     * @request PUT:/super_owner/categories/sequence
     * @response `200` `{ message?: string }`
     */
    categoriesSequenceUpdate: (data: PutCategorySequencesRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<{ message?: string }, any>(`/super_owner/categories/sequence`, "PUT", params, data),

    /**
     * @description カンパニータグがついている会社を一覧取得
     *
     * @tags SuperOwner/CompanyTag/Company
     * @name CompanyTagsCompaniesDetail
     * @request GET:/super_owner/company_tags/{company_tag_id}/companies
     * @response `200` `{ companies: (Company)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    companyTagsCompaniesDetail: (
      { company_tag_id, ...query }: CompanyTagsCompaniesDetailParams,
      params?: RequestParams,
    ) =>
      this.request<{ companies: Company[]; total_count: number }, { error?: string }>(
        `/super_owner/company_tags/${company_tag_id}/companies${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 添削対象企業の一覧を取得する
     *
     * @tags SuperOwner/Company
     * @name FetchRevieweeCompaniesAsSuperOwner
     * @request GET:/super_owner/reviewer_companies/{reviewer_company_id}/reviewee_companies
     * @response `200` `{ companies: (Company)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    fetchRevieweeCompaniesAsSuperOwner: (
      { reviewer_company_id, ...query }: FetchRevieweeCompaniesAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<{ companies: Company[]; total_count: number }, { error?: string }>(
        `/super_owner/reviewer_companies/${reviewer_company_id}/reviewee_companies${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 添削対象企業を複数作成
     *
     * @tags SuperOwner/Company
     * @name BatchCreateRevieweeCompaniesAsSuperOwner
     * @request PATCH:/super_owner/reviewer_companies/{reviewer_company_id}/reviewee_companies/batch_create
     * @response `200` `{ errors: (string)[], reviewee_companies: (Company)[] }` OK
     * @response `201` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    batchCreateRevieweeCompaniesAsSuperOwner: (
      reviewer_company_id: string,
      data: RevieweeCompaniesRequestBodyAsSuperOwner,
      params?: RequestParams,
    ) =>
      this.request<{ errors: string[]; reviewee_companies: Company[] }, { error?: string }>(
        `/super_owner/reviewer_companies/${reviewer_company_id}/reviewee_companies/batch_create`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description 添削対象企業を複数作成
     *
     * @tags SuperOwner/Company
     * @name BatchDeleteRevieweeCompaniesAsSuperOwner
     * @request PATCH:/super_owner/reviewer_companies/{reviewer_company_id}/reviewee_companies/batch_delete
     * @response `200` `{ errors: (string)[], removed_reviewee_ids: (string)[] }` OK
     * @response `404` `{ error?: string }`
     */
    batchDeleteRevieweeCompaniesAsSuperOwner: (
      reviewer_company_id: string,
      data: RevieweeCompaniesRequestBodyAsSuperOwner,
      params?: RequestParams,
    ) =>
      this.request<{ errors: string[]; removed_reviewee_ids: string[] }, { error?: string }>(
        `/super_owner/reviewer_companies/${reviewer_company_id}/reviewee_companies/batch_delete`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description 添削対象企業の一覧を取得する linking_reviewer_companiesがtrueの場合は既に添削会社に設定されている会社
     *
     * @tags SuperOwner/Company
     * @name FetchAssignableCompaniesAsSuperOwner
     * @request GET:/super_owner/reviewer_companies/{reviewer_company_id}/assignable_companies
     * @response `200` `{ companies: (ReviewerAssignableCompany)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    fetchAssignableCompaniesAsSuperOwner: (
      { reviewer_company_id, ...query }: FetchAssignableCompaniesAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<{ companies: ReviewerAssignableCompany[]; total_count: number }, { error?: string }>(
        `/super_owner/reviewer_companies/${reviewer_company_id}/assignable_companies${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - 配信した教材の公開日を編集できる - 開始日、終了日を過去に設定すると取り下げ可能 - 開始日は過去の場合、配信済のため編集不可 - 開始日を編集した場合、お知らせ&メールの配信日時も変更される -   開始日：未来→未来に変更する場合、お知らせ&メールの配信日時は開始日に変更 -   開始日：未来→過去に変更する場合、お知らせ&メールは削除する。（教材を取り下げするのに、通知されると困るため）
     *
     * @tags SuperOwner/MaterialDistributionHistoryGroup
     * @name MaterialDistributionHistoryGroupsUpdate
     * @request PUT:/super_owner/material_distribution_history_groups/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    materialDistributionHistoryGroupsUpdate: (
      id: string,
      data: { distribution_time?: string; expiration_time?: string },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/material_distribution_history_groups/${id}`, "PUT", params, data),

    /**
     * @description Super Ownerに関連する配信履歴を返却する
     *
     * @tags SuperOwner/MaterialDistributionHistory
     * @name ListMaterialDistributionHistoryAsSuperOwner
     * @summary Super Ownerに関連する配信履歴を返却する
     * @request GET:/super_owner/material_distribution_histories/list
     * @response `200` `{ material_distribution_histories: (MaterialDistributionHistory)[], total_count: number }` OK
     */
    listMaterialDistributionHistoryAsSuperOwner: (
      query: ListMaterialDistributionHistoryAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<{ material_distribution_histories: MaterialDistributionHistory[]; total_count: number }, any>(
        `/super_owner/material_distribution_histories/list${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Super Ownerに関連する配信履歴の配信先、生徒情報を返却する
     *
     * @tags SuperOwner/MaterialDistributionHistory
     * @name ListShowMaterialDistributionHistoryAsSuperOwner
     * @summary Super Ownerに関連する配信履歴の配信先、生徒情報を返却する
     * @request GET:/super_owner/material_distribution_histories/list_students
     * @response `200` `MaterialDistributionHistoryListStudents` OK
     */
    listShowMaterialDistributionHistoryAsSuperOwner: (
      query: ListShowMaterialDistributionHistoryAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<MaterialDistributionHistoryListStudents, any>(
        `/super_owner/material_distribution_histories/list_students${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description UserIDから配信履歴を返却する
     *
     * @tags SuperOwner/MaterialDistributionHistory
     * @name SearchByUserIdMaterialDistributionHistoryAsSuperOwner
     * @summary UserIDから配信履歴を返却する
     * @request GET:/super_owner/material_distribution_histories/search_by_user_id
     * @response `200` `{ material_distribution_histories: (MaterialDistributionHistorySearchByUserId)[], total_count: number }` OK
     * @response `400` `BadRequest`
     */
    searchByUserIdMaterialDistributionHistoryAsSuperOwner: (
      query: SearchByUserIdMaterialDistributionHistoryAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { material_distribution_histories: MaterialDistributionHistorySearchByUserId[]; total_count: number },
        BadRequest
      >(`/super_owner/material_distribution_histories/search_by_user_id${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description SuperOwner限定 提出物の配信履歴の一覧を返却
     *
     * @tags SuperOwner/HomeworkTemplateDistributionHistories
     * @name GetSuperOwnerHomeworkTemplateDistributionHistories
     * @summary SuperOwner: 提出物の配信履歴の一覧を返却
     * @request GET:/super_owner/homework_templates_distribution_histories
     * @response `200` `{ homework_template_distribution_histories: (SuperOwnerHomeworkTemplateDistributionHistory)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getSuperOwnerHomeworkTemplateDistributionHistories: (
      query: GetSuperOwnerHomeworkTemplateDistributionHistoriesParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          homework_template_distribution_histories: SuperOwnerHomeworkTemplateDistributionHistory[];
          total_count: number;
        },
        Unauthorized | Forbidden
      >(`/super_owner/homework_templates_distribution_histories${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description SuperOwner限定 提出物の配信履歴のAffiliate一覧を返却
     *
     * @tags SuperOwner/HomeworkTemplateDistributionHistories
     * @name GetSuperOwnerHomeworkTemplateDistributionHistoryAffiliates
     * @summary SuperOwner: 提出物の配信履歴のAffiliate一覧を返却
     * @request GET:/super_owner/homework_templates_distribution_histories/{homework_template_distribution_history_id}/affiliates
     * @response `200` `SuperOwnerHomeworkTemplateDistributionHistoryAffiliates` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getSuperOwnerHomeworkTemplateDistributionHistoryAffiliates: (
      {
        homework_template_distribution_history_id,
        ...query
      }: GetSuperOwnerHomeworkTemplateDistributionHistoryAffiliatesParams,
      params?: RequestParams,
    ) =>
      this.request<SuperOwnerHomeworkTemplateDistributionHistoryAffiliates, Unauthorized | Forbidden>(
        `/super_owner/homework_templates_distribution_histories/${homework_template_distribution_history_id}/affiliates${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description SuperOwner限定 提出物の配信履歴のCompany一覧を返却
     *
     * @tags SuperOwner/HomeworkTemplateDistributionHistories
     * @name GetSuperOwnerHomeworkTemplateDistributionHistoryCompanies
     * @summary SuperOwner: 提出物の配信履歴のCompany一覧を返却
     * @request GET:/super_owner/homework_templates_distribution_histories/{homework_template_distribution_history_id}/companies
     * @response `200` `SuperOwnerHomeworkTemplateDistributionHistoryCompanies` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getSuperOwnerHomeworkTemplateDistributionHistoryCompanies: (
      {
        homework_template_distribution_history_id,
        ...query
      }: GetSuperOwnerHomeworkTemplateDistributionHistoryCompaniesParams,
      params?: RequestParams,
    ) =>
      this.request<SuperOwnerHomeworkTemplateDistributionHistoryCompanies, Unauthorized | Forbidden>(
        `/super_owner/homework_templates_distribution_histories/${homework_template_distribution_history_id}/companies${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description 提出物のカスタムフィールドの一覧を返却
     *
     * @tags SuperOwner/HomeworkTemplateCustomFields
     * @name GetSuperOwnerHomeworkTemplateCustomFields
     * @summary SuperOwner: 提出物のカスタムフィールドの一覧を返却
     * @request GET:/super_owner/homework_templates/{homework_template_id}/homework_template_custom_fields
     * @response `200` `{ homework_template_custom_fields: (HomeworkTemplateCustomField)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSuperOwnerHomeworkTemplateCustomFields: (
      { homework_template_id, ...query }: GetSuperOwnerHomeworkTemplateCustomFieldsParams,
      params?: RequestParams,
    ) =>
      this.request<
        { homework_template_custom_fields: HomeworkTemplateCustomField[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/super_owner/homework_templates/${homework_template_id}/homework_template_custom_fields${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description 提出物のカスタムフィールドの登録
     *
     * @tags SuperOwner/HomeworkTemplateCustomFields
     * @name PostSuperOwnerHomeworkTemplateCustomFields
     * @summary SuperOwner: 提出物のカスタムフィールドの登録
     * @request POST:/super_owner/homework_templates/{homework_template_id}/homework_template_custom_fields
     * @response `201` `HomeworkTemplateCustomField` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postSuperOwnerHomeworkTemplateCustomFields: (
      homework_template_id: string,
      data: CustomFieldRequestBody & { type: HomeworkTemplateCustomFieldType },
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomField, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/homework_template_custom_fields`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 提出物のカスタムフィールドの情報取得
     *
     * @tags SuperOwner/HomeworkTemplateCustomFields
     * @name GetSuperOwnerHomeworkTemplateCustomFieldsId
     * @summary SuperOwner: 提出物のカスタムフィールドの情報参照
     * @request GET:/super_owner/homework_templates/{homework_template_id}/homework_template_custom_fields/{id}
     * @response `200` `HomeworkTemplateCustomField` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSuperOwnerHomeworkTemplateCustomFieldsId: (id: string, homework_template_id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateCustomField, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/homework_template_custom_fields/${id}`,
        "GET",
        params,
      ),

    /**
     * @description 提出物のカスタムフィールドの情報の更新
     *
     * @tags SuperOwner/HomeworkTemplateCustomFields
     * @name PutSuperOwnerHomeworkTemplateCustomFieldsId
     * @summary SuperOwner: 提出物のカスタムフィールドの情報更新
     * @request PUT:/super_owner/homework_templates/{homework_template_id}/homework_template_custom_fields/{id}
     * @response `202` `HomeworkTemplateCustomField` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putSuperOwnerHomeworkTemplateCustomFieldsId: (
      id: string,
      homework_template_id: string,
      data: CustomFieldRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomField, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/homework_template_custom_fields/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 提出物のカスタムフィールドの情報の削除
     *
     * @tags SuperOwner/HomeworkTemplateCustomFields
     * @name DeleteSuperOwnerHomeworkTemplateCustomFieldsId
     * @summary SuperOwner: 提出物のカスタムフィールドの削除
     * @request DELETE:/super_owner/homework_templates/{homework_template_id}/homework_template_custom_fields/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteSuperOwnerHomeworkTemplateCustomFieldsId: (
      id: string,
      homework_template_id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/homework_templates/${homework_template_id}/homework_template_custom_fields/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description SuperOwner限定 お問い合わせ一覧を返却
     *
     * @tags SuperOwner/Inquiries
     * @name GetSuperOwnerInquiries
     * @summary SuperOwner: お問い合わせ一覧を返却
     * @request GET:/super_owner/inquiries
     * @response `200` `{ inquiries: (InquiryDetailListBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getSuperOwnerInquiries: (query: GetSuperOwnerInquiriesParams, params?: RequestParams) =>
      this.request<{ inquiries: InquiryDetailListBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/super_owner/inquiries${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description SuperOwner限定 お問い合わせ情報取得
     *
     * @tags SuperOwner/Inquiries
     * @name GetSuperOwnerInquiriesId
     * @summary SuperOwner: 提出物のお問い合わせ情報参照
     * @request GET:/super_owner/inquiries/{id}
     * @response `200` `InquiryDetailForSuperOwner` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    getSuperOwnerInquiriesId: (id: string, params?: RequestParams) =>
      this.request<InquiryDetailForSuperOwner, BadRequest | { error?: string }>(
        `/super_owner/inquiries/${id}`,
        "GET",
        params,
      ),

    /**
     * @description SuperOwner限定 お問い合わせ既読更新
     *
     * @tags SuperOwner/Inquiries
     * @name GetSuperOwnerInquiriesIdUpdate
     * @summary SuperOwner: お問い合わせの既読更新
     * @request PUT:/super_owner/inquiries/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getSuperOwnerInquiriesIdUpdate: (id: string, data: InquiryIsNewRequiredRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/super_owner/inquiries/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description SuperOwner限定 新着コメントが存在するお問い合わせの件数を返却
     *
     * @tags SuperOwner/Inquiries
     * @name GetTheNumberOfNewCommentInquiriesAsSuperOwner
     * @summary 新着コメントが存在するお問い合わせの件数を返却
     * @request GET:/super_owner/inquiries/new_comment_count
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTheNumberOfNewCommentInquiriesAsSuperOwner: (params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/super_owner/inquiries/new_comment_count`,
        "GET",
        params,
      ),

    /**
     * @description SuperOwner限定 新着のお問い合わせの件数を返却
     *
     * @tags SuperOwner/Inquiries
     * @name GetTheNumberOfNewInquiryInquiriesAsSuperOwner
     * @summary 新着のお問い合わせの件数を返却
     * @request GET:/super_owner/inquiries/new_inquiry_count
     * @response `200` `{ is_new_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTheNumberOfNewInquiryInquiriesAsSuperOwner: (params?: RequestParams) =>
      this.request<{ is_new_count: number }, Unauthorized | Forbidden>(
        `/super_owner/inquiries/new_inquiry_count`,
        "GET",
        params,
      ),

    /**
     * @description SuperOwner限定 お問い合わせコメントの登録
     *
     * @tags SuperOwner/InquiryComments
     * @name PostSuperOwnerInquiryComments
     * @summary SuperOwner: お問い合わせコメントの登録
     * @request POST:/super_owner/inquiry_comments
     * @response `201` `InquiryComment` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postSuperOwnerInquiryComments: (
      query: PostSuperOwnerInquiryCommentsParams,
      data: BodyRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<InquiryComment, BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/super_owner/inquiry_comments${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description SuperOwner限定 お問い合わせコメントの情報の更新
     *
     * @tags SuperOwner/InquiryComments
     * @name PutSuperOwnerInquiryCommentsId
     * @summary SuperOwner: お問い合わせコメントの情報更新
     * @request PUT:/super_owner/inquiry_comments/{id}
     * @response `202` `InquiryComment` Accepted
     * @response `400` `BadRequest`
     */
    putSuperOwnerInquiryCommentsId: (
      { id, ...query }: PutSuperOwnerInquiryCommentsIdParams,
      data: InquiryCommentRequestBody,
      params?: RequestParams,
    ) =>
      this.request<InquiryComment, BadRequest>(
        `/super_owner/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description SuperOwner限定 お問い合わせコメントの情報の削除
     *
     * @tags SuperOwner/InquiryComments
     * @name DeleteSuperOwnerInquiryCommentsId
     * @summary SuperOwner: お問い合わせコメントの削除
     * @request DELETE:/super_owner/inquiry_comments/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSuperOwnerInquiryCommentsId: (
      { id, ...query }: DeleteSuperOwnerInquiryCommentsIdParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/super_owner/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - "SuperOwner限定 LearningTemplatePackage を一覧を返却" - "登録したSuperOwnerのCompanyに属しているスーパーオーナーは取得可能"
     *
     * @tags SuperOwner/LearningTemplatePackages
     * @name GetLearningTemplatePackagesAsSuperOwner
     * @summary 学習コンテンツ 一覧を返却
     * @request GET:/super_owner/learning_template_packages
     * @response `200` `{ learning_template_packages: (LearningTemplatePackageBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackagesAsSuperOwner: (
      query: GetLearningTemplatePackagesAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { learning_template_packages: LearningTemplatePackageBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/learning_template_packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "SuperOwner限定 LearningTemplatePackage を登録"
     *
     * @tags SuperOwner/LearningTemplatePackages
     * @name PostLearningTemplatePackageAsSuperOwner
     * @summary 学習コンテンツ を登録
     * @request POST:/super_owner/learning_template_packages
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postLearningTemplatePackageAsSuperOwner: (data: TitleAndDescriptionRequiredRequestBody, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/learning_template_packages`, "POST", params, data),

    /**
     * @description - "SuperOwner限定 LearningTemplatePackage の詳細を返却" - "登録したSuperOwnerのCompanyに属しているスーパーオーナーは取得可能"
     *
     * @tags SuperOwner/LearningTemplatePackages
     * @name GetLearningTemplatePackageAsSuperOwner
     * @summary 学習コンテンツ の詳細を返却
     * @request GET:/super_owner/learning_template_packages/{id}
     * @response `200` `LearningTemplatePackageBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<LearningTemplatePackageBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/learning_template_packages/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - "SuperOwner限定 LearningTemplatePackage を更新"
     *
     * @tags SuperOwner/LearningTemplatePackages
     * @name PutLearningTemplatePackageAsSuperOwner
     * @summary 学習コンテンツ を更新
     * @request PUT:/super_owner/learning_template_packages/{id}
     * @response `202` `LearningTemplatePackageBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putLearningTemplatePackageAsSuperOwner: (
      id: string,
      data: TitleAndDescriptionRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        LearningTemplatePackageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/learning_template_packages/${id}`, "PUT", params, data),

    /**
     * @description - "SuperOwner限定 LearningTemplatePackage を削除"
     *
     * @tags SuperOwner/LearningTemplatePackages
     * @name DeleteLearningTemplatePackagAsSuperOwner
     * @summary 学習コンテンツ を削除
     * @request DELETE:/super_owner/learning_template_packages/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteLearningTemplatePackagAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/learning_template_packages/${id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags SuperOwner/LearningTemplatePackages
     * @name PutLearningTemplatePackagesSequenceAsSuperOwner
     * @summary 学習コンテンツの並べ替え
     * @request PUT:/super_owner/learning_template_packages/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putLearningTemplatePackagesSequenceAsSuperOwner: (
      data: LearningTemplatePackageSequencesRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/learning_template_packages/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 LearningTemplatePackageDistributionSetting の一覧を返却 - SuperOwnerのCompanyに属しているスーパーオーナーは取得可能
     *
     * @tags SuperOwner/LearningTemplatePackageDistributions
     * @name GetLearningTemplatePackageDistributionsAsSuperOwner
     * @summary 学習コンテンツの配信設定 一覧を返却
     * @request GET:/super_owner/learning_template_package_distributions
     * @response `200` `{ learning_template_package_distribution_settings: (LearningTemplatePackageDistributionSettingBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionsAsSuperOwner: (
      query: GetLearningTemplatePackageDistributionsAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          learning_template_package_distribution_settings: LearningTemplatePackageDistributionSettingBase[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/learning_template_package_distributions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperOwner限定 LearningTemplatePackageDistributionSetting を削除 - start_atが現在時刻を過ぎている場合は削除不可
     *
     * @tags SuperOwner/LearningTemplatePackageDistributions
     * @name DeleteLearningTemplatePackageDistributionAsSuperOwner
     * @summary 学習コンテンツの配信設定 を削除
     * @request DELETE:/super_owner/learning_template_package_distributions/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteLearningTemplatePackageDistributionAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/learning_template_package_distributions/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 LearningTemplatePackage を生徒に配信する - start_atがend_atの前になるように設定する - company_idsまたはuser_tag_idsの片方のみを指定する - company_idsとuser_tag_idsは両方指定しない - student_affiliate_idsに指定した生徒に配信する
     *
     * @tags SuperOwner/LearningTemplatePackageDistributions
     * @name PatchLearningTemplatePackageDistributionsBatchCreateAsSuperOwner
     * @summary 学習コンテンツ を配信
     * @request PATCH:/super_owner/learning_template_packages/{learning_template_package_id}/learning_template_package_distributions/batch_create
     * @response `200` `{ message?: string }`
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    patchLearningTemplatePackageDistributionsBatchCreateAsSuperOwner: (
      learning_template_package_id: string,
      data: BatchCreateLearningTemplatePackageDistributionRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(
        `/super_owner/learning_template_packages/${learning_template_package_id}/learning_template_package_distributions/batch_create`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 生徒のAffiliateの一覧を返却 - 配信済みの学習コンテンツの生徒は表示されない
     *
     * @tags SuperOwner/LearningTemplatePackageDistributions
     * @name GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwner
     * @summary 学校IDから学習コンテンツの配信ができる生徒一覧を返却
     * @request GET:/super_owner/learning_template_packages/{learning_template_package_id}/learning_template_package_distributions/target_student_affiliates_from_companies
     * @response `200` `{ student_affiliates: (AffiliateStudentName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwner: (
      {
        learning_template_package_id,
        ...query
      }: GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromCompaniesAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { student_affiliates: AffiliateStudentName[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/super_owner/learning_template_packages/${learning_template_package_id}/learning_template_package_distributions/target_student_affiliates_from_companies${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperOwner限定 生徒のAffiliateの一覧を返却 - 配信済みの学習コンテンツの生徒は表示されない
     *
     * @tags SuperOwner/LearningTemplatePackageDistributions
     * @name GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwner
     * @summary ユーザタグIDから学習コンテンツの配信ができる生徒一覧を返却
     * @request GET:/super_owner/learning_template_packages/{learning_template_package_id}/learning_template_package_distributions/target_student_affiliates_from_user_tags
     * @response `200` `{ student_affiliates: (AffiliateStudentName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwner: (
      {
        learning_template_package_id,
        ...query
      }: GetLearningTemplatePackageDistributionsTargetStudentAffiliatesFromUserTagsAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { student_affiliates: AffiliateStudentName[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/super_owner/learning_template_packages/${learning_template_package_id}/learning_template_package_distributions/target_student_affiliates_from_user_tags${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperOwner限定 LearningTemplatePackageDistributionSetting の配信履歴一覧を返却 - SuperOwnerのCompanyに属しているスーパーオーナーは取得可能
     *
     * @tags SuperOwner/LearningTemplatePackageDistributionHistories
     * @name GetLearningTemplatePackageDistributionHistoriesAsSuperOwner
     * @summary 学習コンテンツの配信履歴 一覧を返却
     * @request GET:/super_owner/learning_template_package_distribution_histories
     * @response `200` `{ learning_template_package_distribution_histories: ((ModelBase & { learning_template_package_id: string, distributor_affiliate_id: string, start_at: string, end_at?: string, distribution_at: string, kind: string, grade?: StudentGrade, forbid_download: boolean, number_of_completed_learning_package?: number, number_of_students_distributed: number, number_of_school_distributed?: number, status?: DistributionStatusStatus | null, deletion_progress: DeletionProgress, companies: (HasIdAndName)[], user_tags: (HasIdAndName)[], learning_template_package: LearningTemplatePackageBase }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionHistoriesAsSuperOwner: (
      query: GetLearningTemplatePackageDistributionHistoriesAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          learning_template_package_distribution_histories: (ModelBase & {
            learning_template_package_id: string;
            distributor_affiliate_id: string;
            start_at: string;
            end_at?: string;
            distribution_at: string;
            kind: string;
            grade?: StudentGrade;
            forbid_download: boolean;
            number_of_completed_learning_package?: number;
            number_of_students_distributed: number;
            number_of_school_distributed?: number;
            status?: DistributionStatusStatus | null;
            deletion_progress: DeletionProgress;
            companies: HasIdAndName[];
            user_tags: HasIdAndName[];
            learning_template_package: LearningTemplatePackageBase;
          })[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/learning_template_package_distribution_histories${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperOwner限定 LearningTemplatePackageDistributionSetting の配信設定を更新" - 終了日のみ更新可能
     *
     * @tags SuperOwner/LearningTemplatePackageDistributionHistories
     * @name PutLearningTemplatePackageDistributionHistoriesAsSuperOwner
     * @summary 学習コンテンツの配信設定を更新
     * @request PUT:/super_owner/learning_template_package_distribution_histories/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putLearningTemplatePackageDistributionHistoriesAsSuperOwner: (
      id: string,
      data: StartAtAndEndAtRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/learning_template_package_distribution_histories/${id}`, "PUT", params, data),

    /**
     * @description - SuperOwner限定 LearningTemplatePackageDistributionSettingの配信履歴 配信先一覧を返却 - SuperOwnerのCompanyに属しているスーパーオーナーは取得可能
     *
     * @tags SuperOwner/LearningTemplatePackageDistributionHistories
     * @name GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwner
     * @summary 学習コンテンツの配信履歴 配信先一覧を返却
     * @request GET:/super_owner/learning_template_package_distribution_histories/{id}/distributed_students
     * @response `200` `{ distributed_students: (DistributedStudents)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwner: (
      { id, ...query }: GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { distributed_students: DistributedStudents[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/super_owner/learning_template_package_distribution_histories/${id}/distributed_students${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description - "SuperOwner限定 MaterialHomeworkTemplatePackage を一覧を返却" - "登録したSuperOwnerのCompanyに属しているスーパーオーナーは取得可能"
     *
     * @tags SuperOwner/MaterialHomeworkTemplatePackages
     * @name GetMaterialHomeworkTemplatePackagesAsSuperOwner
     * @summary 教材と提出物のパッケージ 一覧を返却
     * @request GET:/super_owner/material_homework_template_packages
     * @response `200` `{ material_homework_template_packages: (MaterialHomeworkTemplatePackageBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getMaterialHomeworkTemplatePackagesAsSuperOwner: (
      query: GetMaterialHomeworkTemplatePackagesAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { material_homework_template_packages: MaterialHomeworkTemplatePackageBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/material_homework_template_packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "SuperOwner限定 MaterialHomeworkTemplatePackage を登録"
     *
     * @tags SuperOwner/MaterialHomeworkTemplatePackages
     * @name PostMaterialHomeworkTemplatePackageAsSuperOwner
     * @summary 教材と提出物のパッケージ を登録
     * @request POST:/super_owner/material_homework_template_packages
     * @response `201` `MaterialHomeworkTemplatePackageBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postMaterialHomeworkTemplatePackageAsSuperOwner: (
      data: TitleAndDescriptionRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        MaterialHomeworkTemplatePackageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/material_homework_template_packages`, "POST", params, data),

    /**
     * @description - "SuperOwner限定 MaterialHomeworkTemplatePackage の詳細を返却" - "登録したSuperOwnerのCompanyに属しているスーパーオーナーは取得可能"
     *
     * @tags SuperOwner/MaterialHomeworkTemplatePackages
     * @name GetMaterialHomeworkTemplatePackageAsSuperOwner
     * @summary 教材と提出物のパッケージ の詳細を返却
     * @request GET:/super_owner/material_homework_template_packages/{id}
     * @response `200` `MaterialHomeworkTemplatePackageBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getMaterialHomeworkTemplatePackageAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<MaterialHomeworkTemplatePackageBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/material_homework_template_packages/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - "SuperOwner限定 MaterialHomeworkTemplatePackage を更新"
     *
     * @tags SuperOwner/MaterialHomeworkTemplatePackages
     * @name PutMaterialHomeworkTemplatePackageAsSuperOwner
     * @summary 教材と提出物のパッケージ を更新
     * @request PUT:/super_owner/material_homework_template_packages/{id}
     * @response `202` `MaterialHomeworkTemplatePackageBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putMaterialHomeworkTemplatePackageAsSuperOwner: (
      id: string,
      data: TitleAndDescriptionRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        MaterialHomeworkTemplatePackageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/material_homework_template_packages/${id}`, "PUT", params, data),

    /**
     * @description - "SuperOwner限定 MaterialHomeworkTemplatePackage を削除"
     *
     * @tags SuperOwner/MaterialHomeworkTemplatePackages
     * @name DeleteMaterialHomeworkTemplatePackageAsSuperOwner
     * @summary 教材 を削除
     * @request DELETE:/super_owner/material_homework_template_packages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteMaterialHomeworkTemplatePackageAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/material_homework_template_packages/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - "SuperOwner限定 MaterialHomeworkTemplatePackageに紐付いているPackagingの並び替えを更新" - "コメント1"
     *
     * @tags SuperOwner/MaterialHomeworkTemplatePackagePackagingSequence
     * @name PutMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwner
     * @summary MaterialHomeworkTemplatePackageに紐付いているPackagingの並び替えを更新
     * @request PUT:/super_owner/material_homework_template_packages/{id}/packagings/{packaging_id}/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putMaterialHomeworkTemplatePackagePackagingSequenceAsSuperOwner: (
      id: string,
      packaging_id: string,
      data: SequenceRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/super_owner/material_homework_template_packages/${id}/packagings/${packaging_id}/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description ユーザタグまたは学校を指定して教材配信の対象人数を取得する。
     *
     * @tags SuperOwner/MaterialWork
     * @name MaterialWorksTargetCompaniesList
     * @request GET:/super_owner/material_works/target_companies
     * @response `200` `{ companies: ({ id: string, name: string, postcode: string, prefecture: string, "address1": string, "address2": string, phone: string, email: string, logo: { url?: string, thumb?: { url?: string }, icon?: { url?: string } }, target_student_count: number, distributed_student_count: number })[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    materialWorksTargetCompaniesList: (query: MaterialWorksTargetCompaniesListParams, params?: RequestParams) =>
      this.request<
        {
          companies: {
            id: string;
            name: string;
            postcode: string;
            prefecture: string;
            address1: string;
            address2: string;
            phone: string;
            email: string;
            logo: { url?: string; thumb?: { url?: string }; icon?: { url?: string } };
            target_student_count: number;
            distributed_student_count: number;
          }[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden
      >(`/super_owner/material_works/target_companies${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description ユーザタグまたは学校を指定して教材配信の対象人数を取得する。
     *
     * @tags SuperOwner/MaterialWork
     * @name MaterialWorksTargetMaterialsList
     * @request GET:/super_owner/material_works/target_materials
     * @response `200` `{ materials: ((MaterialDetail & { target_company_count: number, target_student_count: number, distributed_student_count: number }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    materialWorksTargetMaterialsList: (query: MaterialWorksTargetMaterialsListParams, params?: RequestParams) =>
      this.request<
        {
          materials: (MaterialDetail & {
            target_company_count: number;
            target_student_count: number;
            distributed_student_count: number;
          })[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden
      >(`/super_owner/material_works/target_materials${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperOwner限定 Packaging を登録する - e.g. 学習コンテンツ（LearningTemplatePackage）に提出物（HomeworkTemplate）や教材（Material）を登録する
     *
     * @tags SuperOwner/Packaging
     * @name PostPackagingAsSuperOwner
     * @summary パッケージにコンテンツ を登録
     * @request POST:/super_owner/packagings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPackagingAsSuperOwner: (data: PackagingRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/packagings`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Packagingのsequence を更新する - パッケージのコンテンツを並び替える
     *
     * @tags SuperOwner/Packaging
     * @name PutPackagingAsSuperOwner
     * @summary パッケージにコンテンツの並び替えの順番 を更新
     * @request PUT:/super_owner/packagings
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPackagingAsSuperOwner: (
      query: PutPackagingAsSuperOwnerParams,
      data: { sequence: number },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/packagings${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Packaging を削除する - 論理削除される
     *
     * @tags SuperOwner/Packaging
     * @name DeletePackagingAsSuperOwner
     * @summary パッケージからコンテンツ を削除
     * @request DELETE:/super_owner/packagings
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePackagingAsSuperOwner: (query: DeletePackagingAsSuperOwnerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/packagings${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 Packaging を返却 - PackageのIDを指定したコンテンツ一覧を返却
     *
     * @tags SuperOwner/Packaging
     * @name GetPackagingsAsSuperOwner
     * @summary パッケージに紐づいているコンテンツのリストを返却
     * @request GET:/super_owner/packagings/list
     * @response `200` `{ packagings: (PackagingAsLearningTemplatePackageBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPackagingsAsSuperOwner: (query: GetPackagingsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { packagings: PackagingAsLearningTemplatePackageBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/packagings/list${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperOwner限定 Pbl::Board::Info の詳細を返却
     *
     * @tags SuperOwner/PblBoardInfos
     * @name GetPblBoardInfoAsSuperOwner
     * @summary 探究ボード の詳細を返却
     * @request GET:/super_owner/pbl/board/infos
     * @response `200` `PblBoardInfoBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardInfoAsSuperOwner: (query: GetPblBoardInfoAsSuperOwnerParams, params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/infos${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Info を登録
     *
     * @tags SuperOwner/PblBoardInfos
     * @name PostPblBoardInfoAsSuperOwner
     * @summary 探究ボード を登録
     * @request POST:/super_owner/pbl/board/infos
     * @response `201` `PblBoardInfoBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardInfoAsSuperOwner: (params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/infos`,
        "POST",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Info を更新
     *
     * @tags SuperOwner/PblBoardInfos
     * @name PutPblBoardInfoAsSuperOwner
     * @summary 探究ボード を更新
     * @request PUT:/super_owner/pbl/board/infos
     * @response `202` `PblBoardInfoBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardInfoAsSuperOwner: (data: PblBoardInfoEventRequiredRequestBody, params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/infos`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Item を登録
     *
     * @tags SuperOwner/PblBoardItems
     * @name PostPblBoardItemAsSuperOwner
     * @summary 探究ボードのコンテンツタイプ を登録
     * @request POST:/super_owner/pbl/board/items
     * @response `201` `PblBoardItemBaseAsShow` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardItemAsSuperOwner: (data: PblBoardItemRequestBody, params?: RequestParams) =>
      this.request<PblBoardItemBaseAsShow, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Item を更新
     *
     * @tags SuperOwner/PblBoardItems
     * @name PutPblBoardItemAsSuperOwner
     * @summary 探究ボードのコンテンツタイプ を更新
     * @request PUT:/super_owner/pbl/board/items/{id}
     * @response `202` `PblBoardItemBaseAsShow` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardItemAsSuperOwner: (id: string, data: PblBoardItemRequestBody, params?: RequestParams) =>
      this.request<PblBoardItemBaseAsShow, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Item を削除
     *
     * @tags SuperOwner/PblBoardItems
     * @name DeletePblBoardItemAsSuperOwner
     * @summary 探究ボードのコンテンツタイプ を削除
     * @request DELETE:/super_owner/pbl/board/items/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardItemAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Campaign の一覧を取得
     *
     * @tags SuperOwner/PblBoardCampaigns
     * @name GetPblBoardCampaignsAsSuperOwner
     * @summary 探究ボードの探究成果募集 の一覧を取得
     * @request GET:/super_owner/pbl/board/campaigns
     * @response `200` `{ board_campaigns: (PblBoardCampaignList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardCampaignsAsSuperOwner: (query: GetPblBoardCampaignsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { board_campaigns: PblBoardCampaignList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/pbl/board/campaigns${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperOwner限定 Pbl::Board::Campaign を登録
     *
     * @tags SuperOwner/PblBoardCampaigns
     * @name PostPblBoardCampaignAsSuperOwner
     * @summary 探究ボードの探究成果募集 を登録
     * @request POST:/super_owner/pbl/board/campaigns
     * @response `201` `PblBoardCampaignBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardCampaignAsSuperOwner: (data: PostPblBoardCampaignRequestBody, params?: RequestParams) =>
      this.request<PblBoardCampaignBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/campaigns`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Campaign の詳細を取得
     *
     * @tags SuperOwner/PblBoardCampaigns
     * @name GetPblBoardCampaignAsSuperOwner
     * @summary 探究ボードの探究成果募集 の詳細を取得
     * @request GET:/super_owner/pbl/board/campaigns/{id}
     * @response `200` `PblBoardCampaignBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardCampaignAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<PblBoardCampaignBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/campaigns/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Campaign を更新
     *
     * @tags SuperOwner/PblBoardCampaigns
     * @name PutPblBoardCampaignAsSuperOwner
     * @summary 探究ボードの探究成果募集 を更新
     * @request PUT:/super_owner/pbl/board/campaigns/{id}
     * @response `202` `PblBoardCampaignBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardCampaignAsSuperOwner: (id: string, data: PutPblBoardCampaignRequestBody, params?: RequestParams) =>
      this.request<PblBoardCampaignBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/campaigns/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Campaign を削除
     *
     * @tags SuperOwner/PblBoardCampaigns
     * @name DeletePblBoardCampaignAsSuperOwner
     * @summary 探究ボードの探究成果募集 を削除
     * @request DELETE:/super_owner/pbl/board/campaigns/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardCampaignAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/campaigns/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::CampaignRequest の一覧を取得
     *
     * @tags SuperOwner/PblBoardCampaignRequests
     * @name PostPblBoardCampaignRequestAsSuperOwner
     * @summary 探究ボードの探究成果募集のリクエスト一覧を取得
     * @request GET:/super_owner/pbl/board/campaigns/{campaign_id}/campaign_requests
     * @response `200` `{ board_campaign_requests: (PblBoardCampaignRequestList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardCampaignRequestAsSuperOwner: (
      { campaign_id, ...query }: PostPblBoardCampaignRequestAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { board_campaign_requests: PblBoardCampaignRequestList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/super_owner/pbl/board/campaigns/${campaign_id}/campaign_requests${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::CampaignRequest を更新
     *
     * @tags SuperOwner/PblBoardCampaignRequests
     * @name PutPblBoardCampaignRequestAsSuperOwner
     * @summary 探究ボードの探究成果募集のリクエスト更新
     * @request PUT:/super_owner/pbl/board/campaigns/{campaign_id}/campaign_requests/{id}
     * @response `202` `PblBoardCampaignRequestList` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardCampaignRequestAsSuperOwner: (
      campaign_id: string,
      id: string,
      data: PblBoardCampaignRequestEventRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<PblBoardCampaignRequestList, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/campaigns/${campaign_id}/campaign_requests/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::CampaignRequest を更新
     *
     * @tags SuperOwner/PblBoardCampaignRequests
     * @name PutPblBoardCampaignRequestSequenceAsSuperOwner
     * @summary 探究ボードの探究成果募集のリクエスト更新
     * @request PUT:/super_owner/pbl/board/campaigns/{campaign_id}/campaign_requests/{id}/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardCampaignRequestSequenceAsSuperOwner: (
      campaign_id: string,
      id: string,
      data: SequenceRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/campaigns/${campaign_id}/campaign_requests/${id}/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Article を一覧を返却 - 承認済みのものを取得 - 探究成果のstatusがpublishedのものを取得
     *
     * @tags SuperOwner/PblBoardArticles
     * @name GetPblBoardArticlesAsSuperOwner
     * @summary 探究成果募集の探究成果情報 の一覧を返却
     * @request GET:/super_owner/pbl/board/articles
     * @response `200` `{ articles: (PblBoardArticleBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticlesAsSuperOwner: (query: GetPblBoardArticlesAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { articles: PblBoardArticleBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/pbl/board/articles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperOwner限定 Article の詳細を返却 - 承認済みのものを取得 - 探究成果のstatusがpublishedのものを取得
     *
     * @tags SuperOwner/PblBoardArticles
     * @name GetPblBoardArticleAsSuperOwner
     * @summary 探究成果募集の探究成果情報 の詳細を返却
     * @request GET:/super_owner/pbl/board/articles/{id}
     * @response `200` `ArticleDetailAsPblBoard` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticleAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<ArticleDetailAsPblBoard, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/articles/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Contents を登録 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags SuperOwner/PblBoardContents
     * @name PostPblBoardContentAsSuperOwner
     * @summary 探究ボードのコンテンツ を登録
     * @request POST:/super_owner/pbl/board/items/{item_id}/contents
     * @response `201` `PblBoardContentBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardContentAsSuperOwner: (item_id: string, data: PblBoardContentRequestBody, params?: RequestParams) =>
      this.request<PblBoardContentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/contents`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Contents の詳細を返却 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags SuperOwner/PblBoardContents
     * @name GetPblBoardContentAsSuperOwner
     * @summary 探究ボードのコンテンツ の詳細を返却
     * @request GET:/super_owner/pbl/board/items/{item_id}/contents/{id}
     * @response `200` `PblBoardContentBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardContentAsSuperOwner: (item_id: string, id: string, params?: RequestParams) =>
      this.request<PblBoardContentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/contents/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Contents を更新 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags SuperOwner/PblBoardContents
     * @name PutPblBoardContentAsSuperOwner
     * @summary 探究ボードのコンテンツ を更新
     * @request PUT:/super_owner/pbl/board/items/{item_id}/contents/{id}
     * @response `202` `PblBoardContentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardContentAsSuperOwner: (
      item_id: string,
      id: string,
      data: PblBoardContentRequestBody,
      params?: RequestParams,
    ) =>
      this.request<PblBoardContentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/contents/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Contents を削除 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags SuperOwner/PblBoardContents
     * @name DeletePblBoardContentAsSuperOwner
     * @summary 探究ボードのコンテンツ を削除
     * @request DELETE:/super_owner/pbl/board/items/{item_id}/contents/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardContentAsSuperOwner: (item_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/contents/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Footers を登録
     *
     * @tags SuperOwner/PblBoardFooters
     * @name GetPblBoardFootersAsSuperOwner
     * @summary 探究ボードのフッター の一覧を取得
     * @request GET:/super_owner/pbl/board/items/{item_id}/footers
     * @response `200` `{ board_footers: (PblBoardFooterBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardFootersAsSuperOwner: (
      { item_id, ...query }: GetPblBoardFootersAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { board_footers: PblBoardFooterBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/pbl/board/items/${item_id}/footers${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperOwner限定 Pbl::Board::Footers を登録 - 探究ボードのコンテンツタイプが footer のみ可能
     *
     * @tags SuperOwner/PblBoardFooters
     * @name PostPblBoardFooterAsSuperOwner
     * @summary 探究ボードのフッター を登録
     * @request POST:/super_owner/pbl/board/items/{item_id}/footers
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardFooterAsSuperOwner: (item_id: string, data: PostPblBoardFooterRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/footers`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Footers を更新 - 探究ボードのコンテンツタイプが footer のみ可能
     *
     * @tags SuperOwner/PblBoardFooters
     * @name PutPblBoardFooterAsSuperOwner
     * @summary 探究ボードのフッター を更新
     * @request PUT:/super_owner/pbl/board/items/{item_id}/footers/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardFooterAsSuperOwner: (
      item_id: string,
      id: string,
      data: PutPblBoardFooterRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/footers/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::Footers を削除 - 探究ボードのコンテンツタイプが footer のみ可能
     *
     * @tags SuperOwner/PblBoardFooters
     * @name DeletePblBoardFooterAsSuperOwner
     * @summary 探究ボードのフッター を削除
     * @request DELETE:/super_owner/pbl/board/items/{item_id}/footers/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardFooterAsSuperOwner: (item_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/footers/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::FooterItems を登録
     *
     * @tags SuperOwner/PblBoardFooterItems
     * @name PostPblBoardFooterItemAsSuperOwner
     * @summary 探究ボードのフッターのリンク を登録
     * @request POST:/super_owner/pbl/board/items/{item_id}/footers/{footer_id}/footer_items
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardFooterItemAsSuperOwner: (
      item_id: string,
      footer_id: string,
      data: PblBoardFooterItemRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/footers/${footer_id}/footer_items`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::FooterItems を更新
     *
     * @tags SuperOwner/PblBoardFooterItems
     * @name PutPblBoardFooterItemAsSuperOwner
     * @summary 探究ボードのフッターのリンク を更新
     * @request PUT:/super_owner/pbl/board/items/{item_id}/footers/{footer_id}/footer_items/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardFooterItemAsSuperOwner: (
      item_id: string,
      footer_id: string,
      id: string,
      data: PblBoardFooterItemRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/footers/${footer_id}/footer_items/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Board::FooterItems を削除
     *
     * @tags SuperOwner/PblBoardFooterItems
     * @name DeletePblBoardFooterItemAsSuperOwner
     * @summary 探究ボードのフッターのリンク を削除
     * @request DELETE:/super_owner/pbl/board/items/{item_id}/footers/{footer_id}/footer_items/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardFooterItemAsSuperOwner: (item_id: string, footer_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/pbl/board/items/${item_id}/footers/${footer_id}/footer_items/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description (AffiliateID使用)親管理者が自社の管理者or教職員or生徒のAffiliateをアップデート
     *
     * @tags SuperOwner/Affiliate
     * @name AffiliateUpdateAsSuperOwner
     * @summary (AffiliateID使用)親管理者が自社の管理者or教職員or生徒のAffiliateをアップデート
     * @request PUT:/super_owner/affiliates/{id}
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    affiliateUpdateAsSuperOwner: (
      id: string,
      data: AffiliateStudentSuspensionRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/affiliates/${id}`, "PUT", params, data),

    /**
     * @description (AffiliateID使用)親管理者が自社の管理者or教職員or生徒の複数のAffiliateをアップデート
     *
     * @tags SuperOwner/Affiliate
     * @name AffiliatesUpdateAsSuperOwner
     * @summary (AffiliateID使用)親管理者が自社の管理者or教職員or生徒の複数のAffiliateをアップデート
     * @request PUT:/super_owner/affiliates/bulk_update
     * @response `200` `any`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `any`
     */
    affiliatesUpdateAsSuperOwner: (data: BulkUpdateRequestBody, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/affiliates/bulk_update`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description (AffiliateID使用)親管理者が自社の管理者or教職員or生徒の複数のAffiliateを削除
     *
     * @tags SuperOwner/Affiliate
     * @name AffiliatesDestroyAsSuperOwner
     * @summary (AffiliateID使用)親管理者が自社の管理者or教職員or生徒の複数のAffiliateを削除
     * @request DELETE:/super_owner/affiliates/bulk_destroy
     * @response `200` `any`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `any`
     */
    affiliatesDestroyAsSuperOwner: (data: IdsRequiredRequestBody, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/affiliates/bulk_destroy`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 AttachedImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags SuperOwner/AttachedImages
     * @name PostAttachedImageAsSuperOwner
     * @summary 画像 を登録
     * @request POST:/super_owner/attached_images
     * @response `201` `AttachedImageBase` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    postAttachedImageAsSuperOwner: (
      query: PostAttachedImageAsSuperOwnerParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | { error?: string }>(
        `/super_owner/attached_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 AttachedImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags SuperOwner/AttachedImages
     * @name GetAttachedImageAsSuperOwner
     * @summary 画像 の詳細を返却
     * @request GET:/super_owner/attached_images/{id}
     * @response `200` `AttachedImageBase` OK
     * @response `400` `BadRequest`
     */
    getAttachedImageAsSuperOwner: ({ id, ...query }: GetAttachedImageAsSuperOwnerParams, params?: RequestParams) =>
      this.request<AttachedImageBase, BadRequest>(
        `/super_owner/attached_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperOwner限定 AttachedImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags SuperOwner/AttachedImages
     * @name PutAttachedImageAsSuperOwner
     * @summary 画像 を更新
     * @request PUT:/super_owner/attached_images/{id}
     * @response `202` `AttachedImageBase` Accepted
     * @response `400` `BadRequest`
     */
    putAttachedImageAsSuperOwner: (
      { id, ...query }: PutAttachedImageAsSuperOwnerParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest>(
        `/super_owner/attached_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 AttachedImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags SuperOwner/AttachedImages
     * @name DeleteAttachedImageAsSuperOwner
     * @summary 画像 を削除
     * @request DELETE:/super_owner/attached_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    deleteAttachedImageAsSuperOwner: (
      { id, ...query }: DeleteAttachedImageAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest>(
        `/super_owner/attached_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - "SuperOwner限定 LimitedTimeNotification を一覧を返却"
     *
     * @tags SuperOwner/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationsAsSuperOwner
     * @summary 期間限定のお知らせの一覧を返却
     * @request GET:/super_owner/limited_time_notifications
     * @response `200` `{ limited_time_notifications: (LimitedTimeNotificationList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationsAsSuperOwner: (
      query: GetLimitedTimeNotificationsAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { limited_time_notifications: LimitedTimeNotificationList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/limited_time_notifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "SuperOwner限定 LimitedTimeNotification を登録"
     *
     * @tags SuperOwner/LimitedTimeNotifications
     * @name PostLimitedTimeNotificationAsSuperOwner
     * @summary 期間限定のお知らせ を登録
     * @request POST:/super_owner/limited_time_notifications
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postLimitedTimeNotificationAsSuperOwner: (data: PostLimitedTimeNotificationRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/limited_time_notifications`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - "SuperOwner限定 LimitedTimeNotification の詳細を返却"
     *
     * @tags SuperOwner/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationAsSuperOwner
     * @summary 期間限定のお知らせ の詳細を返却
     * @request GET:/super_owner/limited_time_notifications/{id}
     * @response `200` `LimitedTimeNotificationDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<LimitedTimeNotificationDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/limited_time_notifications/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - "SuperOwner限定 LimitedTimeNotification を更新"
     *
     * @tags SuperOwner/LimitedTimeNotifications
     * @name PutLimitedTimeNotificationAsSuperOwner
     * @summary 期間限定のお知らせ を更新
     * @request PUT:/super_owner/limited_time_notifications/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putLimitedTimeNotificationAsSuperOwner: (
      id: string,
      data: PutLimitedTimeNotificationRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/limited_time_notifications/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "SuperOwner限定 LimitedTimeNotification を削除"
     *
     * @tags SuperOwner/LimitedTimeNotifications
     * @name DeleteLimitedTimeNotificationAsSuperOwner
     * @summary 期間限定のお知らせ を削除
     * @request DELETE:/super_owner/limited_time_notifications/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteLimitedTimeNotificationAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/limited_time_notifications/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 Pbl::Steam:Content を一覧を返却
     *
     * @tags SuperOwner/Steam/Contents
     * @name GetSteamContentsAsSuperOwner
     * @summary Steamライブラリーコンテンツの一覧を返却
     * @request GET:/super_owner/pbl/steam/contents
     * @response `200` `{ steam_contents?: (PblSteamContent)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSteamContentsAsSuperOwner: (query: GetSteamContentsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { steam_contents?: PblSteamContent[]; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/pbl/steam/contents${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description SuperOwner限定 Steamライブラリーのレクチャーの一覧を取得
     *
     * @tags SuperOwner/Steam/Lectures
     * @name GetSteamLecturesAsSuperOwner
     * @summary SuperOwner: Steamライブラリーコンテンツのレクチャーの一覧を取得
     * @request GET:/super_owner/pbl/steam/lectures
     * @response `200` `{ steam_lectures: (PblSteamLecture)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSteamLecturesAsSuperOwner: (query: GetSteamLecturesAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { steam_lectures: PblSteamLecture[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/pbl/steam/lectures${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description SuperOwner限定 Steamライブラリーのレクチャーを登録
     *
     * @tags SuperOwner/Steam/Lectures
     * @name PostSuperOwnerSteamLectures
     * @summary SuperOwner: Steamライブラリーコンテンツのレクチャーを登録
     * @request POST:/super_owner/pbl/steam/lectures
     * @response `200` `{ is_registered: boolean }` OK
     * @response `201` `PblSteamLecture` Created
     * @response `202` `PblSteamLecture` Accepted
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postSuperOwnerSteamLectures: (
      query: PostSuperOwnerSteamLecturesParams,
      data: CreateSteamLectureRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ is_registered: boolean }, Unauthorized | Forbidden>(
        `/super_owner/pbl/steam/lectures${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description SuperOwner限定 Steamライブラリーのコンテンツのレクチャー名の情報取得
     *
     * @tags SuperOwner/Steam/Lectures
     * @name GetSuperOwnerSteamLecturesId
     * @summary SuperOwner: Steamライブラリーコンテンツのレクチャー名の情報参照
     * @request GET:/super_owner/pbl/steam/lectures/{id}
     * @response `200` `PblSteamLecture` OK
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     */
    getSuperOwnerSteamLecturesId: (id: string, params?: RequestParams) =>
      this.request<PblSteamLecture, BadRequest | Forbidden>(`/super_owner/pbl/steam/lectures/${id}`, "GET", params),

    /**
     * @description 企業プロジェクトの一覧を返却。現状はブックマークしているプロジェクトの取得のみに利用
     *
     * @tags SuperOwner/Sponsor/Projects
     * @name FetchSponsorProjectsAsSuperOwner
     * @summary 企業プロジェクトの一覧を返却
     * @request GET:/super_owner/sponsor/projects
     * @response `200` `{ projects: (SponsorProjectAndTagsBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectsAsSuperOwner: (query: FetchSponsorProjectsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { projects: SponsorProjectAndTagsBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/super_owner/sponsor/projects${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 企業プロジェクトのレクチャーの一覧を返却
     *
     * @tags SuperOwner/Sponsor/Lectures
     * @name FetchSponsorLecturesAsSuperOwner
     * @summary 企業プロジェクトのレクチャーの一覧を返却
     * @request GET:/super_owner/sponsor/projects/lectures
     * @response `200` `{ project_lectures: (SponsorLectureDetailsBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLecturesAsSuperOwner: (query: FetchSponsorLecturesAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { project_lectures: SponsorLectureDetailsBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/super_owner/sponsor/projects/lectures${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description SuperOwner限定 企業プロジェクトのレクチャーの詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags SuperOwner/Sponsor/Lectures
     * @name FetchSponsorLectureAsSuperOwner
     * @summary 企業プロジェクトのレクチャーの詳細情報を返却
     * @request GET:/super_owner/sponsor/projects/lectures/{id}
     * @response `200` `SponsorLectureDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLectureAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<SponsorLectureDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/super_owner/sponsor/projects/lectures/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - "SuperOwner限定 TtCsvExport を一覧を返却"
     *
     * @tags SuperOwner/TtCsvExports
     * @name GetTtCsvExportsAsSuperOwner
     * @summary CSV一覧を返却
     * @request GET:/super_owner/tt_csv/exports
     * @response `200` `{ tt_csv_exports: (TtCsvExportBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtCsvExportsAsSuperOwner: (query: GetTtCsvExportsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { tt_csv_exports: TtCsvExportBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/tt_csv/exports${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperOwner限定 TtCsvExport を登録 - type: super owner can only use starting with super_owner - target_company_id: required when exporting student_list, teacher_list and owner_list - homework_template_id: required when exporting homework_template_distribution_history_detail - learning_template_package_distribution_setting_id: required when exporting learning_package_distribution_history_detail - common_custom_master_map_id: required when exporting custom_list
     *
     * @tags SuperOwner/TtCsvExports
     * @name PostTtCsvExportAsSuperOwner
     * @summary CSV出力 をリクエスト
     * @request POST:/super_owner/tt_csv/exports
     * @response `200` `TtCsvExportBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTtCsvExportAsSuperOwner: (
      query: PostTtCsvExportAsSuperOwnerParams,
      data: TtCsvExportRequestBody,
      params?: RequestParams,
    ) =>
      this.request<TtCsvExportBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/tt_csv/exports${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 TtCategoryBase を登録
     *
     * @tags SuperOwner/TtCategoryBases
     * @name PostTtCategoryBaseAsSuperOwner
     * @request POST:/super_owner/tt_category/bases
     * @response `201` `TtCategoryBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTtCategoryBaseAsSuperOwner: (data: CreateTtCategoryRequestBody, params?: RequestParams) =>
      this.request<TtCategoryBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/tt_category/bases`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 TtCategoryBase を更新
     *
     * @tags SuperOwner/TtCategoryBases
     * @name PutTtCategoryBaseAsSuperOwner
     * @request PUT:/super_owner/tt_category/bases/{id}
     * @response `202` `TtCategoryBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTtCategoryBaseAsSuperOwner: (id: string, data: UpdateTtCategoryRequestBody, params?: RequestParams) =>
      this.request<TtCategoryBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/tt_category/bases/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "SuperOwner限定 FeedbackTemplate::Homework を一覧を返却"
     *
     * @tags SuperOwner/FeedbackTemplate/Homework
     * @name GetFeedbackTemplateHomeworksAsSuperOwner
     * @summary 提出物FBの一覧を返却
     * @request GET:/super_owner/feedback_template/homeworks
     * @response `200` `{ feedback_templates: (FeedbackTemplateHomeworkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplateHomeworksAsSuperOwner: (
      query: GetFeedbackTemplateHomeworksAsSuperOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { feedback_templates: FeedbackTemplateHomeworkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/feedback_template/homeworks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "SuperOwner限定 FeedbackTemplate::Homework を登録"
     *
     * @tags SuperOwner/FeedbackTemplate/Homework
     * @name PostFeedbackTemplateHomeworkAsSuperOwner
     * @summary 提出物FBを登録
     * @request POST:/super_owner/feedback_template/homeworks
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postFeedbackTemplateHomeworkAsSuperOwner: (data: PostFeedbackTemplateHomeworkRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/feedback_template/homeworks`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - "SuperOwner限定 FeedbackTemplate::Homework の詳細を返却"
     *
     * @tags SuperOwner/FeedbackTemplate/Homework
     * @name GetFeedbackTemplateHomeworkAsSuperOwner
     * @summary 提出物FBの詳細を返却
     * @request GET:/super_owner/feedback_template/homeworks/{id}
     * @response `200` `FeedbackTemplateHomeworkBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplateHomeworkAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<FeedbackTemplateHomeworkBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/feedback_template/homeworks/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - "SuperOwner限定 FeedbackTemplate::Homework を更新"
     *
     * @tags SuperOwner/FeedbackTemplate/Homework
     * @name PutFeedbackTemplateHomeworkAsSuperOwner
     * @summary 提出物FBを更新
     * @request PUT:/super_owner/feedback_template/homeworks/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putFeedbackTemplateHomeworkAsSuperOwner: (
      id: string,
      data: PutFeedbackTemplateHomeworkRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/feedback_template/homeworks/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "SuperOwner限定 FeedbackTemplate::Homework を削除"
     *
     * @tags SuperOwner/FeedbackTemplate/Homework
     * @name DeleteFeedbackTemplateHomeworkAsSuperOwner
     * @summary 提出物FBを削除
     * @request DELETE:/super_owner/feedback_template/homeworks/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteFeedbackTemplateHomeworkAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/feedback_template/homeworks/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 FeedbackTemplateComment を登録
     *
     * @tags SuperOwner/FeedbackTemplate/Comments
     * @name PostFeedbackTemplateCommentAsSuperOwner
     * @summary 提出物FBのテンプレートのコメント を登録
     * @request POST:/super_owner/feedback_template/homeworks/{feedback_template_id}/comments
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postFeedbackTemplateCommentAsSuperOwner: (
      feedback_template_id: string,
      data: BodyRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/feedback_template/homeworks/${feedback_template_id}/comments`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 FeedbackTemplateComment を更新
     *
     * @tags SuperOwner/FeedbackTemplate/Comments
     * @name PutFeedbackTemplateCommentAsSuperOwner
     * @summary 提出物FBのテンプレートのコメント を更新
     * @request PUT:/super_owner/feedback_template/homeworks/{feedback_template_id}/comments/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putFeedbackTemplateCommentAsSuperOwner: (
      feedback_template_id: string,
      id: string,
      data: BodyRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/feedback_template/homeworks/${feedback_template_id}/comments/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 FeedbackTemplateComment を削除
     *
     * @tags SuperOwner/FeedbackTemplate/Comments
     * @name DeleteFeedbackTemplateCommentAsSuperOwner
     * @summary 提出物FBのテンプレートのコメント を削除
     * @request DELETE:/super_owner/feedback_template/homeworks/{feedback_template_id}/comments/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteFeedbackTemplateCommentAsSuperOwner: (feedback_template_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/feedback_template/homeworks/${feedback_template_id}/comments/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - "SuperOwner限定 FeedbackTemplate::Tag を一覧を返却"
     *
     * @tags SuperOwner/FeedbackTemplate/Tag
     * @name GetFeedbackTemplateTagsAsSuperOwner
     * @summary 提出物FBのタグ一覧を返却
     * @request GET:/super_owner/feedback_template/tags
     * @response `200` `{ feedback_template_tags: (HasName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplateTagsAsSuperOwner: (query: GetFeedbackTemplateTagsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { feedback_template_tags: HasName[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/feedback_template/tags${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description ヘルプコンテンツの一覧を返却
     *
     * @tags SuperOwner/Help/Contents
     * @name FetchHelpContentsAsSuperOwner
     * @summary ヘルプコンテンツの一覧を返却
     * @request GET:/super_owner/help/contents
     * @response `200` `{ help_master?: HelpMasterBase, help_contents: (HelpContentBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchHelpContentsAsSuperOwner: (query: FetchHelpContentsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { help_master?: HelpMasterBase; help_contents: HelpContentBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/help/contents${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description HelpContentをアップデートする。
     *
     * @tags SuperOwner/Help/Contents
     * @name PutHelpContentsAsSuperOwner
     * @request PUT:/super_owner/help/contents/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putHelpContentsAsSuperOwner: (id: string, data: HelpRequestBodyAsSuperOwner, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/help/contents/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Ideal::BoardTemplate の一覧を返却 - 自分の所属する学校に紐づくテンプレートのみ取得可能
     *
     * @tags SuperOwner/IdealBoardTemplates
     * @name GetIdealBoardTemplatesAsSuperOwner
     * @summary Ideal::BoardTemplate の一覧を返却
     * @request GET:/super_owner/ideal/board_templates
     * @response `200` `{ board_templates: (BoardTemplateList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getIdealBoardTemplatesAsSuperOwner: (query: GetIdealBoardTemplatesAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { board_templates: BoardTemplateList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/ideal/board_templates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Ideal::BoardTemplate を登録
     *
     * @tags SuperOwner/IdealBoardTemplates
     * @name PostIdealBoardTemplatesAsSuperOwner
     * @summary Ideal::BoardTemplate を登録
     * @request POST:/super_owner/ideal/board_templates
     * @response `201` `BoardTemplate` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postIdealBoardTemplatesAsSuperOwner: (data: PostIdealBoardTemplateRequestBody, params?: RequestParams) =>
      this.request<BoardTemplate, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/ideal/board_templates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description SuperOwner限定 Ideal::BoardTemplate の詳細を返却
     *
     * @tags SuperOwner/IdealBoardTemplate
     * @name GetIdealBoardTemplateAsSuperOwner
     * @summary Ideal::BoardTemplate の詳細を返却
     * @request GET:/super_owner/ideal/board_templates/{id}
     * @response `200` `BoardTemplate` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getIdealBoardTemplateAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<BoardTemplate, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/ideal/board_templates/${id}`,
        "GET",
        params,
      ),

    /**
     * @description SuperOwner限定 Ideal::BoardTemplate を更新
     *
     * @tags SuperOwner/IdealBoardTemplate
     * @name PutIdealBoardTemplateAsSuperOwner
     * @summary Ideal::BoardTemplate を更新
     * @request PUT:/super_owner/ideal/board_templates/{id}
     * @response `202` `BoardTemplate` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putIdealBoardTemplateAsSuperOwner: (id: string, data: PutIdealBoardTemplateRequestBody, params?: RequestParams) =>
      this.request<BoardTemplate, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/ideal/board_templates/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Ideal::BoardTemplate を論理削除 - 関連するboardsも論理削除される
     *
     * @tags SuperOwner/IdealBoardTemplate
     * @name DeleteIdealBoardTemplateAsSuperOwner
     * @summary Ideal::BoardTemplate を削除
     * @request DELETE:/super_owner/ideal/board_templates/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteIdealBoardTemplateAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/ideal/board_templates/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Ideal::Board の一覧を返却 - 自社のIdeal::Boardのみ取得可能 - 特定のあいであるテンプレートで絞り込み可能 - グループでも絞り込み可能
     *
     * @tags SuperOwner/IdealBoards
     * @name GetIdealBoardsAsSuperOwner
     * @summary Ideal::Board の一覧を返却
     * @request GET:/super_owner/ideal/boards
     * @response `200` `{ boards: (BoardList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getIdealBoardsAsSuperOwner: (query: GetIdealBoardsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { boards: BoardList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/ideal/boards${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Ideal::Board を登録
     *
     * @tags SuperOwner/IdealBoards
     * @name PostIdealBoardsAsSuperOwner
     * @summary Ideal::Board を登録
     * @request POST:/super_owner/ideal/boards
     * @response `201` `Board` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postIdealBoardsAsSuperOwner: (data: PostIdealBoardRequestBody, params?: RequestParams) =>
      this.request<Board, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/super_owner/ideal/boards`,
        "POST",
        params,
        data,
      ),

    /**
     * @description SuperOwner限定 Ideal::Board の詳細を返却
     *
     * @tags SuperOwner/IdealBoard
     * @name GetIdealBoardAsSuperOwner
     * @summary Ideal::Board の詳細を返却
     * @request GET:/super_owner/ideal/boards/{id}
     * @response `200` `Board` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getIdealBoardAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<Board, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/ideal/boards/${id}`,
        "GET",
        params,
      ),

    /**
     * @description SuperOwner限定 Ideal::Board を更新
     *
     * @tags SuperOwner/IdealBoard
     * @name PutIdealBoardAsSuperOwner
     * @summary Ideal::Board を更新
     * @request PUT:/super_owner/ideal/boards/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putIdealBoardAsSuperOwner: (id: string, data: PutIdealBoardRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/ideal/boards/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Ideal::Board を論理削除 - 関連するboards_affiliateも論理削除される
     *
     * @tags SuperOwner/IdealBoard
     * @name DeleteIdealBoardAsSuperOwner
     * @summary Ideal::Board を削除
     * @request DELETE:/super_owner/ideal/boards/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteIdealBoardAsSuperOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/ideal/boards/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - SuperOwner限定 アイデアボックスに生徒（Affiliate）を紐づける - 指定された生徒もアイデアボックスの閲覧、編集可能になる
     *
     * @tags SuperOwner/BoardAffiliates
     * @name PostIdealBoardsAffiliatesAsSuperOwner
     * @summary アイデアボックスに生徒を紐づける
     * @request POST:/super_owner/ideal/boards_affiliates
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postIdealBoardsAffiliatesAsSuperOwner: (data: IdealBoardAffiliateRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/ideal/boards_affiliates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description SuperOwner限定 アイデアボックスに紐づいている生徒を削除する
     *
     * @tags SuperOwner/BoardAffiliates
     * @name DeleteIdealBoardsAffiliatesAsSuperOwner
     * @summary アイデアボックスに紐づいている生徒を削除する
     * @request DELETE:/super_owner/ideal/boards_affiliates
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteIdealBoardsAffiliatesAsSuperOwner: (data: IdealBoardAffiliateRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/ideal/boards_affiliates`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description - SuperOwner限定 Common::Tag の一覧を返却
     *
     * @tags SuperOwner/CommonTags
     * @name GetCommonTagsAsSuperOwner
     * @summary システムタグ・フリータグの一覧を返却
     * @request GET:/super_owner/common/tags
     * @response `200` `{ common_tags: (CommonTagBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCommonTagsAsSuperOwner: (query: GetCommonTagsAsSuperOwnerParams, params?: RequestParams) =>
      this.request<
        { common_tags: CommonTagBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/super_owner/common/tags${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperOwner限定 CommonTagging を登録 - 許可されたModelのみ登録可能
     *
     * @tags SuperOwner/CommonTaggings
     * @name PostCommonTaggingAsSuperOwner
     * @summary フリータグを登録
     * @request POST:/super_owner/common/taggings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postCommonTaggingAsSuperOwner: (
      query: PostCommonTaggingAsSuperOwnerParams,
      data: CommonTaggingRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/super_owner/common/taggings${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description - SuperOwner限定 CommonTagging を削除
     *
     * @tags SuperOwner/CommonTaggings
     * @name DeleteCommonTaggingAsSuperOwner
     * @summary フリータグ を解除
     * @request DELETE:/super_owner/common/taggings
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteCommonTaggingAsSuperOwner: (query: DeleteCommonTaggingAsSuperOwnerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/super_owner/common/taggings${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),
  };
  owner = {
    /**
     * @description (AffiliateID使用)管理者が自社の管理者or教職員or生徒の複数のAffiliateをアップデート
     *
     * @tags Owner/Affiliate
     * @name AffiliatesUpdateAsOwner
     * @summary (AffiliateID使用)管理者が自社の管理者or教職員or生徒の複数のAffiliateをアップデート
     * @request PUT:/owner/affiliates/bulk_update
     * @response `200` `{ success_ids?: (string)[] }` 200 OK
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `{ success_ids: (string)[], failed_updates: ({ id?: string, error?: string })[] }` 422: Validation Error or Duplicate Error on Database
     */
    affiliatesUpdateAsOwner: (data: BulkUpdateRequestBody, params?: RequestParams) =>
      this.request<
        { success_ids?: string[] },
        | BadRequest
        | Unauthorized
        | Forbidden
        | { error?: string }
        | { success_ids: string[]; failed_updates: { id?: string; error?: string }[] }
      >(`/owner/affiliates/bulk_update`, "PUT", params, data),

    /**
     * @description 教材カテゴリ名の変更
     *
     * @tags CategoryRenameMap, Owner/CategoryRenameMap
     * @name UpdateCategoryRenameMapAsOwner
     * @request PATCH:/owner/category_rename_maps/update
     * @response `200` `CategoryRenameMap` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updateCategoryRenameMapAsOwner: (
      data: {
        for_text?: string;
        for_subject?: string;
        for_grade?: string;
        for_major?: string;
        for_middle?: string;
        for_minor?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<CategoryRenameMap, BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/owner/category_rename_maps/update`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description オーナーがカテゴリの一覧を取得
     *
     * @tags Owner/Categories, Categories
     * @name FetchCategoriesAsOwner
     * @summary カテゴリー一覧の取得
     * @request GET:/owner/categories
     * @response `200` `{ texts: (TextCategoryInfo)[], grades: (GradeCategoryInfo)[], subjects: (SubjectCategoryInfo)[], major_categories: (MajorCategoryInfo)[], middle_categories: (MiddleCategoryInfo)[], minor_categories: (MinorCategoryInfo)[] }` OK
     * @response `401` `Unauthorized`
     * @response `500` `any` Internal Server Error
     */
    fetchCategoriesAsOwner: (query: FetchCategoriesAsOwnerParams, params?: RequestParams) =>
      this.request<
        {
          texts: TextCategoryInfo[];
          grades: GradeCategoryInfo[];
          subjects: SubjectCategoryInfo[];
          major_categories: MajorCategoryInfo[];
          middle_categories: MiddleCategoryInfo[];
          minor_categories: MinorCategoryInfo[];
        },
        Unauthorized
      >(`/owner/categories${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description For Owner: this will create a category and if the category has parent_id, specify one.
     *
     * @tags Owner/Categories
     * @name CreateCategoryAsOwner
     * @summary Owner to create a category
     * @request POST:/owner/categories
     * @response `200` `CategoryInfo` OK
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `500` `any` Internal Server Error
     */
    createCategoryAsOwner: (
      data: { parent_id: string; name: string; student_visible?: boolean; teacher_visible?: boolean; image?: string },
      params?: RequestParams,
    ) => this.request<CategoryInfo, { message?: string } | Unauthorized>(`/owner/categories`, "POST", params, data),

    /**
     * @description オーナーがカテゴリーを編集
     *
     * @tags Owner/Categories
     * @name UpdateCategoryAsOwner
     * @summary カテゴリーの更新
     * @request PUT:/owner/categories/{id}
     * @response `200` `CategoryInfo` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    updateCategoryAsOwner: (id: string, data: PutCategoryRequestBodyAsOwner, params?: RequestParams) =>
      this.request<CategoryInfo, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/categories/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description オーナーがカテゴリーを削除
     *
     * @tags Owner/Categories
     * @name DeleteCategoryAsOwner
     * @request DELETE:/owner/categories/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteCategoryAsOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/categories/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description オーナーが教材ファイルを登録
     *
     * @tags Owner/Images
     * @name UploadImage
     * @summary 教材の画像をアップロード
     * @request POST:/owner/image_contents
     * @response `200` `{ id: string, title: string, file: { file?: string }, material_id: string }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    uploadImage: (data: { material_id?: string; title?: string; file?: File }, params?: RequestParams) =>
      this.request<
        { id: string; title: string; file: { file?: string }; material_id: string },
        Unauthorized | Forbidden | { error?: string } | { message?: string }
      >(`/owner/image_contents`, "POST", params, data, BodyType.FormData),

    /**
     * @description Owner 限定 教材に紐付いた画像を削除。
     *
     * @tags Owner/Images
     * @name DeleteImage
     * @summary 教材の画像を削除
     * @request DELETE:/owner/image_contents/{id}
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    deleteImage: (id: string, data: { materialId?: string }, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden | { error?: string }>(
        `/owner/image_contents/${id}`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description Response containing image detail: i.e. title, image url etc
     *
     * @tags Owner/Images
     * @name GetOwnerImageContentsId
     * @summary For Owner: return a image detail
     * @request GET:/owner/image_contents/{id}
     * @response `200` `{ id?: string, title?: string, file?: { url?: string }, material_id?: string }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getOwnerImageContentsId: (id: string, params?: RequestParams) =>
      this.request<
        { id?: string; title?: string; file?: { url?: string }; material_id?: string },
        Unauthorized | Forbidden | { error?: string }
      >(`/owner/image_contents/${id}`, "GET", params),

    /**
     * @description 新規動画の作成
     *
     * @tags Owner/Videos
     * @name UploadVideo
     * @summary 教材の動画をアップロード
     * @request POST:/owner/video_contents
     * @response `200` `VideoInfo` OK
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `{ message?: string }` unprocessable_entity
     */
    uploadVideo: (
      data: { material_id?: string; title?: string; file?: File; thumbnail?: File },
      params?: RequestParams,
    ) =>
      this.request<VideoInfo, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/owner/video_contents`,
        "POST",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description Owner 限定 教材に紐付いた動画を削除。
     *
     * @tags Owner/Videos
     * @name DeleteVideo
     * @summary 教材の動画を削除
     * @request DELETE:/owner/video_contents/{id}
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    deleteVideo: (id: string, data: { materialId?: string }, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden | { error?: string }>(
        `/owner/video_contents/${id}`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description https://github.com/study-valley/timetact-server/issues/27 自身の会社に所属する指定したUserの配信されているMaterial一覧及そのStatus（未受講、受講済）を取得する。
     *
     * @tags Owner/MaterialWork
     * @name FetchMaterialWorksAsOwner
     * @summary 教材の進捗情報の一覧取得
     * @request GET:/owner/material_works
     * @response `200` `{ material_works: (OwnerMaterialWork)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchMaterialWorksAsOwner: (query: FetchMaterialWorksAsOwnerParams, params?: RequestParams) =>
      this.request<
        { material_works: OwnerMaterialWork[]; total_count: number },
        Unauthorized | Forbidden | { error?: string }
      >(`/owner/material_works${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description https://github.com/study-valley/timetact-server/issues/28 自分の会社の先生、生徒に対して教材を配信することが可能。 user_idまたはuser_idsとmaterial_idまたはmaterial_idsの組み合わせ出ない限り400を返却 また、動画や画像の閲覧権限は会社のオプションによる(デフォルトではオン) 処理はバックグラウンドで実行する
     *
     * @tags Owner/MaterialWork
     * @name BatchCreateMaterialWorksAsOwner
     * @summary ユーザーと教材の組み合わせに対してそれぞれMaterialWorkを作成する。
     * @request PATCH:/owner/material_works/batch_create
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    batchCreateMaterialWorksAsOwner: (
      data: {
        material_id?: string;
        material_ids?: string[];
        forbid_download_list?: boolean[];
        user_ids?: string[];
        role: string;
        grade?: string;
        publication_time?: string;
        expiration_time?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/material_works/batch_create`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description https://github.com/study-valley/timetact-server/issues/28 自分の会社の先生、生徒に対して教材を配信することが可能。
     *
     * @tags Owner/MaterialWork
     * @name BatchDeleteMaterialWorksAsOwner
     * @summary 1人のユーザー、あるいは1件の教材に対して複数のMaterialWorkを削除する。
     * @request PATCH:/owner/material_works/batch_delete
     * @response `200` `{ updated_user_ids: (string)[], updated_material_ids: (string)[] | null, deleted_material_work_ids: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `{ message?: string }` unprocessable_entity
     * @response `500` `any` Internal Server Error
     */
    batchDeleteMaterialWorksAsOwner: (
      data: { material_id?: string; material_ids?: string[]; user_id?: string; user_ids?: string[]; role: string },
      params?: RequestParams,
    ) =>
      this.request<
        { updated_user_ids: string[]; updated_material_ids: string[] | null; deleted_material_work_ids: string[] },
        BadRequest | Unauthorized | Forbidden | { error?: string } | { message?: string }
      >(`/owner/material_works/batch_delete`, "PATCH", params, data),

    /**
     * @description https://github.com/study-valley/timetact-server/issues/29 自分の会社の先生、生徒へ配信しているMaterialのStatusを取得
     *
     * @tags Owner/MaterialWork
     * @name GetMaterialWorksMaterialWorkId
     * @summary オーナーが教材配信/受講状況の詳細取得
     * @request GET:/owner/material_works/{material_work_id}
     * @response `200` `({ message_to_student?: string, message_to_owner?: string } & { id: string, material: { id?: string, title?: string }, user: { id?: string, first_name?: string, last_name?: string }, status: string })` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getMaterialWorksMaterialWorkId: (
      material_work_id: string,
      data: { materialWorkId?: string },
      params?: RequestParams,
    ) =>
      this.request<
        { message_to_student?: string; message_to_owner?: string } & {
          id: string;
          material: { id?: string; title?: string };
          user: { id?: string; first_name?: string; last_name?: string };
          status: string;
        },
        Unauthorized | Forbidden | { error?: string }
      >(`/owner/material_works/${material_work_id}`, "GET", params, data),

    /**
     * @description https://github.com/study-valley/timetact-server/issues/30 自分の会社の配信済みの教材について更新が可能。
     *
     * @tags Owner/MaterialWork
     * @name PutMaterialWorksMaterialWorkId
     * @summary 教材の配信/受講状況の更新
     * @request PUT:/owner/material_works/{material_work_id}
     * @response `202` `{ id: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `{ message?: string }` unprocessable_entity
     * @response `500` `any` Internal Server Error
     */
    putMaterialWorksMaterialWorkId: (
      material_work_id: string,
      data: { status?: string; forbid_download?: boolean },
      params?: RequestParams,
    ) =>
      this.request<{ id: string }, BadRequest | Unauthorized | Forbidden | { error?: string } | { message?: string }>(
        `/owner/material_works/${material_work_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - オーナーが属する企業に属している教員一覧を返却
     *
     * @tags Users
     * @name FetchTeachersAsOwner
     * @summary [オーナー] 教員一覧の取得
     * @request GET:/owner/users/teachers
     * @response `200` `{ total_count: number, teachers: ((TeacherListAsOwner | HasAffiliateIdAndName))[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchTeachersAsOwner: (query: FetchTeachersAsOwnerParams, params?: RequestParams) =>
      this.request<
        { total_count: number; teachers: (TeacherListAsOwner | HasAffiliateIdAndName)[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/users/teachers${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description オーナーが属する企業に属している教員一覧を返却
     *
     * @tags Users
     * @name FetchTeachersForSelectAsOwner
     * @summary [オーナー] 教員一覧の取得
     * @request GET:/owner/users/teachers/for_select
     * @response `200` `{ total_count: number, users: (HasIdAndName)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchTeachersForSelectAsOwner: (query: FetchTeachersForSelectAsOwnerParams, params?: RequestParams) =>
      this.request<{ total_count: number; users: HasIdAndName[] }, Unauthorized | Forbidden>(
        `/owner/users/teachers/for_select${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 先生情報を取得(/owner/users/teachers/{id})
     *
     * @tags Users
     * @name GetTeacherDetailAsOwner
     * @summary 先生情報を取得
     * @request GET:/owner/users/teachers/{id}
     * @response `200` `TeacherUserInfo` OK
     */
    getTeacherDetailAsOwner: (id: string, params?: RequestParams) =>
      this.request<TeacherUserInfo, any>(`/owner/users/teachers/${id}`, "GET", params),

    /**
     * @description - Owner限定 progress を更新 - unapproved から available に変更する
     *
     * @tags Users
     * @name PutTeachersProgressUpdateAsOwner
     * @summary 未承認のステータスを承認に更新
     * @request PUT:/owner/users/teachers/progress_update/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putTeachersProgressUpdateAsOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/owner/users/teachers/progress_update/${id}`,
        "PUT",
        params,
      ),

    /**
     * @description - オーナーが所属する企業に登録されている生徒一覧を返却
     *
     * @tags Owner/User
     * @name FetchStudentsAsOwner
     * @summary [オーナー] 生徒一覧の取得
     * @request GET:/owner/users/students
     * @response `200` `{ students: (StudentListAsOwner)[], student_input_settings: StudentInputSetting, total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchStudentsAsOwner: (query: FetchStudentsAsOwnerParams, params?: RequestParams) =>
      this.request<
        { students: StudentListAsOwner[]; student_input_settings: StudentInputSetting; total_count: number },
        Unauthorized | Forbidden
      >(`/owner/users/students${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 学年ごとの生徒の数を返却する
     *
     * @tags Owner/Student
     * @name StudentListGradeAsOwner
     * @summary 学年ごとの生徒の数を返却する
     * @request GET:/owner/users/students/list_grade
     * @response `200` `{ number_of_students_per_grade: ({ grade: StudentGradeJa, count: number })[], students_total_count: number }` OK
     */
    studentListGradeAsOwner: (query: StudentListGradeAsOwnerParams, params?: RequestParams) =>
      this.request<
        { number_of_students_per_grade: { grade: StudentGradeJa; count: number }[]; students_total_count: number },
        any
      >(`/owner/users/students/list_grade${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Studentが紐付いているユーザータグの情報を返却"
     *
     * @tags Owner/Students
     * @name GetStudentsUserTagsAsOwner
     * @summary Studentが紐付いているユーザータグの情報を返却
     * @request GET:/owner/users/students/{id}/user_tags
     * @response `200` `{ user_tag_types: ((UserTagTypeBasic & { user_tags: (UserTagBasic)[] }))[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentsUserTagsAsOwner: (id: string, params?: RequestParams) =>
      this.request<
        { user_tag_types: (UserTagTypeBasic & { user_tags: UserTagBasic[] })[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/users/students/${id}/user_tags`, "GET", params),

    /**
     * @description - "Studentが紐付いているグループの情報を返却"
     *
     * @tags Owner/Students
     * @name GetStudentsGroupAffiliatesAsOwner
     * @summary Studentが紐付いているグループの情報を返却
     * @request GET:/owner/users/students/{id}/group_affiliates
     * @response `200` `{ group_affiliate_packages?: (GroupAffiliatePackageMinimalBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentsGroupAffiliatesAsOwner: (id: string, params?: RequestParams) =>
      this.request<
        { group_affiliate_packages?: GroupAffiliatePackageMinimalBase[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/users/students/${id}/group_affiliates`, "GET", params),

    /**
     * @description オーナーが属する企業に属しているオーナー一覧の返却
     *
     * @tags Users
     * @name FetchOwnersAsOwner
     * @summary [オーナー] オーナー一覧の取得
     * @request GET:/owner/users/owners
     * @response `200` `{ total_count: number, owners: (OwnerListAsOwner)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchOwnersAsOwner: (query: FetchOwnersAsOwnerParams, params?: RequestParams) =>
      this.request<
        { total_count: number; owners: OwnerListAsOwner[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/users/owners${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description オーナー専用API。 ただし、取得できるのは対象ユーザのCompany IDがAPI呼び出し元ユーザのcompany IDと一致する場合のみ。 対象のUser IDに応じて取得できる
     *
     * @tags Users
     * @name GetOwnerUsersUserId
     * @summary オーナーがオーナーor教員or生徒情報を取得
     * @request GET:/owner/users/{user_id}
     * @response `200` `UserDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getOwnerUsersUserId: (user_id: string, params?: RequestParams) =>
      this.request<UserDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/users/${user_id}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザの作成
     *
     * @tags Users
     * @name PostOwnerUsersUserId
     * @summary ユーザの作成
     * @request POST:/owner/users/{user_id}
     * @response `201` `{ message?: string }`
     */
    postOwnerUsersUserId: (user_id: string, params?: RequestParams) =>
      this.request<{ message?: string }, any>(`/owner/users/${user_id}`, "POST", params),

    /**
     * @description 自分以外のオーナー、もしくはオーナーが属する企業に登録している教員および生徒を削除 自動的にAssignの関連付けも削除
     *
     * @tags Users
     * @name DeleteOwnerUsersUserId
     * @summary オーナー権限 ユーザの削除
     * @request DELETE:/owner/users/{user_id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteOwnerUsersUserId: (user_id: string, data: { role: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/users/${user_id}`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description ユーザ情報の更新はStudentInfo,TeacherInfo含めて行えるようにする。 情報を更新できるのは現段階ではオーナーのみ、とする。
     *
     * @tags Users
     * @name PutOwnerUsersUserId
     * @summary オーナーがオーナーor教員or生徒情報を更新
     * @request PUT:/owner/users/{user_id}
     * @response `200` `UserDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putOwnerUsersUserId: (
      user_id: string,
      data: {
        last_name?: string;
        first_name?: string;
        nickname?: string | null;
        optional_email?: string;
        user_type?: UserAccountType & any;
        new_id?: string;
        student_info_attributes?: StudentInfoAttributes;
        teacher_info_attributes?: TeacherInfoAttributes;
        professor_info_attributes?: {
          position?: string;
          department?: string;
          description?: string;
          limit_request?: number;
          profile_picture?: File;
        };
        user_tag_ids?: string[] | null;
        group_ids?: string[] | null;
      },
      params?: RequestParams,
    ) =>
      this.request<UserDetail, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/owner/users/${user_id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description Owner 限定 https://github.com/study-valley/timetact-server/issues/14
     *
     * @tags Companies
     * @name GetOwnerCompaniesCompanyId
     * @summary 企業情報詳細取得
     * @request GET:/owner/companies/{company_id}
     * @response `200` `CompanyAsOwner` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getOwnerCompaniesCompanyId: (company_id: string, params?: RequestParams) =>
      this.request<CompanyAsOwner, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/companies/${company_id}`,
        "GET",
        params,
      ),

    /**
     * @description ownerはロゴのみ変更できる。(住所や電話番号の変更はできない)
     *
     * @tags Companies
     * @name PutOwnerCompaniesCompanyId
     * @summary 企業情報の更新
     * @request PUT:/owner/companies/{company_id}
     * @response `204` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putOwnerCompaniesCompanyId: (
      company_id: string,
      data: {
        logo?: string;
        email?: string;
        options?: { use_account_lock_mail?: boolean; use_article_and_homework_mail?: boolean };
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/companies/${company_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 オプション拡張による制限の確認 - 教材登録のディスク使用量制限 - あいである枚数制限
     *
     * @tags Owner/Company
     * @name GetCompanyLimitOptionAsOwner
     * @summary オプション拡張による制限の確認
     * @request GET:/owner/companies/{id}/limit_option
     * @response `200` `{ limit_option: LimitOptionBase, total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCompanyLimitOptionAsOwner: (id: string, params?: RequestParams) =>
      this.request<
        { limit_option: LimitOptionBase; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/companies/${id}/limit_option`, "GET", params),

    /**
     * @description 教材の容量制限
     *
     * @tags Companies
     * @name GetMaterialCapacityAsOwner
     * @summary 教材の容量制限
     * @request GET:/owner/companies/{id}/material_capacity
     * @response `200` `{ material_capacity: string, material_limit_capacity: string | null }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getMaterialCapacityAsOwner: (id: string, params?: RequestParams) =>
      this.request<
        { material_capacity: string; material_limit_capacity: string | null },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/companies/${id}/material_capacity`, "GET", params),

    /**
     * @description - "Owner限定 ReviewerCompanyGroupを一覧を返却" - "提出物タイプ一覧の返却"
     *
     * @tags Owner/ReviewerCompanyGroup
     * @name GetReviewerCompanyGroupControllersAsOwner
     * @summary ReviewerCompanyGroup一覧を返却
     * @request GET:/owner/reviewer_company_groups
     * @response `200` `{ reviewer_company_groups: (ReviewerCompanyGroupBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getReviewerCompanyGroupControllersAsOwner: (
      query: GetReviewerCompanyGroupControllersAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { reviewer_company_groups: ReviewerCompanyGroupBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/reviewer_company_groups${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description オーナーが新規にユーザを生成するためのAPI。 生成後、Parameterに指定されたメールアドレスに招待メールを送付
     *
     * @tags Users
     * @name PostOwnerUsers
     * @summary オーナー限 新規ユーザの作成(Owner/Teacher/Student)
     * @request POST:/owner/users
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    postOwnerUsers: (
      data: {
        email: string;
        role: string;
        first_name?: string;
        last_name?: string;
        nickname?: string;
        optional_email?: string;
        student_info_attributes?: StudentInfoAttributes;
        teacher_info_attributes?: TeacherInfoAttributes;
        professor_info_attributes?: {
          position?: string;
          department?: string;
          description?: string;
          limit_request?: number;
          profile_picture?: File;
        };
        invitation_scheduled_at?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/owner/users`,
        "POST",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description (アカウントID使用)スーパーオーナーが子会社の管理者or教職員or生徒を作成
     *
     * @tags Owner/User
     * @name CreateAccountUserAsOwner
     * @summary (アカウントID使用)スーパーオーナーが子会社の管理者or教職員or生徒を作成
     * @request POST:/owner/users/create_account_user
     * @response `201` `CreateAccountUserResponse` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    createAccountUserAsOwner: (data: CreateAccountUserRequestBody, params?: RequestParams) =>
      this.request<CreateAccountUserResponse, BadRequest | { error?: string }>(
        `/owner/users/create_account_user`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Owner限定:アカウント停止を利用中に変更する
     *
     * @tags Owner/User
     * @name CreateAccountAvailableAsOwner
     * @summary アカウント停止のユーザを利用中に変更
     * @request PUT:/owner/users/account_available
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createAccountAvailableAsOwner: (data: { affiliate_id: string }, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/users/account_available`, "PUT", params, data),

    /**
     * @description (アカウントID使用)オーナーが自社の管理者or教職員or生徒のパスワードを変更
     *
     * @tags Owner/User
     * @name ResetPasswordAsOwner
     * @summary (アカウントID使用)オーナーが自社の管理者or教職員or生徒のパスワードを変更
     * @request PUT:/owner/users/{id}/reset_password
     * @response `200` `AccountUserInfo` ok
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    resetPasswordAsOwner: (id: string, data: AuthorizationPasswordRequestBody, params?: RequestParams) =>
      this.request<AccountUserInfo, BadRequest | { error?: string }>(
        `/owner/users/${id}/reset_password`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description (UserID使用)アカウントロックを解除
     *
     * @tags Owner/User
     * @name AccountUnlockAsOwner
     * @summary (UserID使用)アカウントロックを解除
     * @request PUT:/owner/users/bulk_unlock_access
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    accountUnlockAsOwner: (data: { user_ids: string[] }, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/users/bulk_unlock_access`, "PUT", params, data),

    /**
     * @description (アカウントID使用)オーナーが自社の管理者or教職員or生徒のアカウントを停止
     *
     * @tags Owner/User
     * @name AccountStopAsOwner
     * @summary (アカウントID使用)オーナーが自社の管理者or教職員or生徒のアカウントを停止
     * @request PUT:/owner/users/{id}/account_stop
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    accountStopAsOwner: (id: string, data: { affiliate_id: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/users/${id}/account_stop`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 生徒、先生、オーナーのタイプごとに複数ユーザーを作成する。
     *
     * @tags Users
     * @name BatchCreateUsersAsOwner
     * @summary 生徒、先生、オーナーのタイプごとに複数ユーザーを作成する。
     * @request PATCH:/owner/users/batch_create
     * @response `200` `{ errors?: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    batchCreateUsersAsOwner: (
      data: {
        slice_start?: number;
        user_params_list?: {
          email?: string;
          user_type?: UserType;
          first_name?: string;
          last_name?: string;
          birth_date?: string;
          grade?: number;
          parent_last_name?: string;
          parent_first_name?: string;
          postcode?: string;
          prefecture?: string;
          address1?: string;
          address2?: string;
          phone?: string;
          number?: number;
          position?: string;
          department?: string;
          description?: string;
          limit_request?: number;
          optional_email?: string;
          user_tag_ids?: string[] | null;
          group_ids?: string[] | null;
        }[];
        invitation_scheduled_at?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<{ errors?: string[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/users/batch_create`,
        "PATCH",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Owner/Materials, materials
     * @name FetchMaterialsAsOwner
     * @summary 教材情報の一覧取得
     * @request GET:/owner/materials
     * @response `200` `{ materials: (OwnerMaterialList)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchMaterialsAsOwner: (query: FetchMaterialsAsOwnerParams, params?: RequestParams) =>
      this.request<
        { materials: OwnerMaterialList[]; total_count: number },
        Unauthorized | Forbidden | { error?: string }
      >(`/owner/materials${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description https://github.com/study-valley/timetact-server/issues/20
     *
     * @tags materials
     * @name CreateMaterialAsOwner
     * @summary 新規 教材登録
     * @request POST:/owner/materials
     * @response `201` `MaterialDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    createMaterialAsOwner: (data: CreateMaterialRequestBody, params?: RequestParams) =>
      this.request<MaterialDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/materials`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 自分の会社の教材について詳細情報を取得する。
     *
     * @tags materials
     * @name FetchMaterialAsOwner
     * @summary オーナーが教材詳細情報取得
     * @request GET:/owner/materials/{id}
     * @response `200` `MaterialDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchMaterialAsOwner: (id: string, params?: RequestParams) =>
      this.request<MaterialDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/materials/${id}`,
        "GET",
        params,
      ),

    /**
     * @description 自分の会社の教材について更新できる。
     *
     * @tags materials
     * @name UpdateMaterialAsOwner
     * @summary オーナーが教材情報を更新
     * @request PUT:/owner/materials/{id}
     * @response `200` `MaterialDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    updateMaterialAsOwner: (id: string, data: UpdateMaterialRequestBody, params?: RequestParams) =>
      this.request<MaterialDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/materials/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 自分の会社の教材について削除可能。
     *
     * @tags materials
     * @name DeleteMaterialAsOwner
     * @summary オーナーが教材を削除
     * @request DELETE:/owner/materials/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteMaterialAsOwner: (id: string, data: { id: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/materials/${id}`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description ユーザタグまたは学年を指定して教材配信の対象人数を取得する。
     *
     * @tags Owner/Material
     * @name MaterialsTargetUsersDetail
     * @summary ユーザタグまたは学年を指定して教材配信の対象人数を取得する。
     * @request GET:/owner/materials/{material_id}/target_users
     * @response `200` `{ students: { target_student_count?: number, target_students?: ({ id?: string, name?: string, "distributed?"?: boolean })[], distributed_student_count?: number } }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    materialsTargetUsersDetail: ({ material_id, ...query }: MaterialsTargetUsersDetailParams, params?: RequestParams) =>
      this.request<
        {
          students: {
            target_student_count?: number;
            target_students?: { id?: string; name?: string; "distributed?"?: boolean }[];
            distributed_student_count?: number;
          };
        },
        BadRequest | Unauthorized | Forbidden
      >(`/owner/materials/${material_id}/target_users${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Update material sequences to order by sequence. If sequences are not specified, sort order will be done by object ID.
     *
     * @tags Owner/Materials
     * @name PutOwnerMaterialsSequence
     * @summary [Owner] Update material sequences
     * @request PUT:/owner/materials/sequence
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putOwnerMaterialsSequence: (
      data: { sequences?: { material_id?: string; sequence?: number }[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/owner/materials/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description カテゴリー配下にある教材を全て取得する。
     *
     * @tags Owner/Material
     * @name MaterialsAllContainedCategoryList
     * @request GET:/owner/materials/all_contained_category
     * @response `200` `{ materials: (HasIdAndTitle)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    materialsAllContainedCategoryList: (query: MaterialsAllContainedCategoryListParams, params?: RequestParams) =>
      this.request<{ materials: HasIdAndTitle[]; total_count: number }, BadRequest | { error?: string }>(
        `/owner/materials/all_contained_category${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 自分に配信されている教材の詳細情報を取得する
     *
     * @tags Materials
     * @name FetchMaterialsFromSuperOwnerAsOwner
     * @summary 管理者が教材一覧を取得
     * @request GET:/owner/materials/from_super_owners
     * @response `200` `{ materials: (MaterialList)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchMaterialsFromSuperOwnerAsOwner: (query: FetchMaterialsFromSuperOwnerAsOwnerParams, params?: RequestParams) =>
      this.request<{ materials: MaterialList[]; total_count: number }, Unauthorized | Forbidden | { error?: string }>(
        `/owner/materials/from_super_owners${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description By posting category_id, this will share underlying child categories and materials by publishing hash ID. If the user's company dones't have eligibility of :text_seller, this will return 403.
     *
     * @tags Owner/MaterialsTickets
     * @name PostOwnerMaterialsTickets
     * @summary [Owner] Create text selling hash ID
     * @request POST:/owner/materials_tickets
     * @response `200` `{ hash_ticket_id: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postOwnerMaterialsTickets: (data: { category_id: string }, params?: RequestParams) =>
      this.request<{ hash_ticket_id: string }, BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/owner/materials_tickets`,
        "POST",
        params,
        data,
      ),

    /**
     * @description By sending hash ID of materials ticket, this endpoint will create categories and materials for current_user's company
     *
     * @tags Owner/MaterialsTickets
     * @name PutOwnerMaterialsTicketsAccept
     * @summary [For Owner] Accept text selling ID
     * @request PUT:/owner/materials_tickets/accept
     * @response `200` `{ message: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `{ error?: string }` Internal Server Error
     */
    putOwnerMaterialsTicketsAccept: (data: { hashed_ticket_id: string }, params?: RequestParams) =>
      this.request<{ message: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/materials_tickets/accept`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description オーナーが保有しているユーザ（生徒・教員共通）にアサインされている教材進捗のリストを返却
     *
     * @tags Users
     * @name GetOwnerUsersUserIdMaterialWorks
     * @summary オーナーが指定のユーザの教材進捗一覧を取得
     * @request GET:/owner/users/{id}/material_works
     * @response `200` `(MaterialWork)[]` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getOwnerUsersUserIdMaterialWorks: (id: string, params?: RequestParams) =>
      this.request<MaterialWork[], Unauthorized | Forbidden>(`/owner/users/${id}/material_works`, "GET", params),

    /**
     * @description 自身の会社の指導レポート一覧を取得
     *
     * @tags Report
     * @name FetchReportsAsOwner
     * @summary 指導レポート一覧取得 (検索キーワードが送信された場合、検索が可能)
     * @request GET:/owner/reports
     * @response `200` `{ total_count: number, reports: (Report)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchReportsAsOwner: (query: FetchReportsAsOwnerParams, params?: RequestParams) =>
      this.request<{ total_count: number; reports: Report[] }, Unauthorized | Forbidden>(
        `/owner/reports${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 任意の指導レポートの詳細情報を取得する
     *
     * @tags Report
     * @name GetOwnerReportsReportId
     * @summary 指導レポート詳細を取得
     * @request GET:/owner/reports/{report_id}
     * @response `200` `(Report & { message_to_owner: string, message_to_student: string })` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getOwnerReportsReportId: (report_id: string, params?: RequestParams) =>
      this.request<
        Report & { message_to_owner: string; message_to_student: string },
        Unauthorized | Forbidden | { error?: string }
      >(`/owner/reports/${report_id}`, "GET", params),

    /**
     * @description 提出された指導レポートにコメントを記載、かつステータスの更新ができる。
     *
     * @tags Report
     * @name PutOwnerReportsReportId
     * @summary 指導レポートの更新
     * @request PUT:/owner/reports/{report_id}
     * @response `200` `(Report & { message_to_owner: string, message_to_student: string })` OK
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putOwnerReportsReportId: (
      report_id: string,
      data: { reply?: string; status?: ReportStatus },
      params?: RequestParams,
    ) =>
      this.request<
        Report & { message_to_owner: string; message_to_student: string },
        { message?: string } | Unauthorized | Forbidden | { error?: string }
      >(`/owner/reports/${report_id}`, "PUT", params, data),

    /**
     * No description
     *
     * @name ReportsDelete
     * @request DELETE:/owner/reports/{report_id}
     * @response `200` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    reportsDelete: (report_id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(`/owner/reports/${report_id}`, "DELETE", params),

    /**
     * @description 自社に登録されている全ての支払情報の一覧を取得
     *
     * @tags Payment
     * @name FetchPaymentsAsOwner
     * @summary オーナーが支払い一覧を取得 (検索キーワードが送信された場合、検索が可能)
     * @request GET:/owner/payments
     * @response `200` `{ payments: (Payment)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchPaymentsAsOwner: (query: FetchPaymentsAsOwnerParams, params?: RequestParams) =>
      this.request<{ payments: Payment[]; total_count: number }, Unauthorized | Forbidden>(
        `/owner/payments${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description オーナーが支払い詳細を取得
     *
     * @tags Payment
     * @name GetOwnerPaymentsPaymentId
     * @summary 支払いの詳細を取得
     * @request GET:/owner/payments/{payment_id}
     * @response `200` `Payment` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getOwnerPaymentsPaymentId: (payment_id: string, params?: RequestParams) =>
      this.request<Payment, Unauthorized | Forbidden | { error?: string }>(
        `/owner/payments/${payment_id}`,
        "GET",
        params,
      ),

    /**
     * @description オーナーが支払状況を更新する
     *
     * @tags Payment
     * @name PutOwnerPaymentsPaymentId
     * @summary 支払い情報の更新
     * @request PUT:/owner/payments/{payment_id}
     * @response `200` `Payment` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putOwnerPaymentsPaymentId: (payment_id: string, data: { status?: number }, params?: RequestParams) =>
      this.request<Payment, Unauthorized | Forbidden | { error?: string }>(
        `/owner/payments/${payment_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description オーナーが自社が抱えている宿題の一覧を取得
     *
     * @tags Owner/Homework
     * @name FetchHomeworksAsOwner
     * @summary オーナーが宿題の一覧を取得 (検索キーワードが送信された場合、検索が可能)
     * @request GET:/owner/homeworks
     * @response `200` `{ total_count: number, homeworks: (HomeworkBase)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchHomeworksAsOwner: (query: FetchHomeworksAsOwnerParams, params?: RequestParams) =>
      this.request<{ total_count: number; homeworks: HomeworkBase[] }, Unauthorized | Forbidden>(
        `/owner/homeworks${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description オーナーが宿題の詳細を取得する
     *
     * @tags Owner/Homework
     * @name GetOwnerHomeworksHomeworkId
     * @summary オーナーが宿題の詳細を取得
     * @request GET:/owner/homeworks/{homework_id}
     * @response `200` `HomeworkDetails` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getOwnerHomeworksHomeworkId: (homework_id: string, params?: RequestParams) =>
      this.request<HomeworkDetails, Unauthorized | Forbidden>(`/owner/homeworks/${homework_id}`, "GET", params),

    /**
     * @description - 提出物の添削権限を持ったOwnerのみ使用できる - Ownerが宿題を更新するAPI - メール通知先: 更新された提出物にアクションを取る必要がある人。 - メール内容: 提出物が届いたこと。
     *
     * @tags Homework, Owner/Homework
     * @name UpdateHomeworkAsOwner
     * @summary Ownerが宿題を更新
     * @request PUT:/owner/homeworks/{homework_id}
     * @response `200` `HomeworkDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    updateHomeworkAsOwner: (
      homework_id: string,
      data: {
        status?: HomeworkStatus;
        teacher_comment?: string;
        teacher_comment_for_reviewer?: string;
        score?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<HomeworkDetails, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/homeworks/${homework_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Owner/HomeworkTemplate, Owner/Homework, Homework, HomeworkTemplate
     * @name UploadHomeworkImagesAsOwner
     * @request POST:/owner/homeworks/{homework_id}/upload_images
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    uploadHomeworkImagesAsOwner: (homework_id: string, data: { files: string[] }, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/owner/homeworks/${homework_id}/upload_images`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Owner/HomeworkTemplate, Owner/Homework, Homework, HomeworkTemplate
     * @name SaveEditedHomeworkImageAsOwner
     * @request POST:/owner/homeworks/{homework_id}/save_image
     * @response `200` `HomeworkDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    saveEditedHomeworkImageAsOwner: (homework_id: string, data: { file: string }, params?: RequestParams) =>
      this.request<HomeworkDetails, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/owner/homeworks/${homework_id}/save_image`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Owner/HomeworkTemplate, Owner/Homework, Homework, HomeworkTemplate
     * @name UpdateHomeworkImageAsOwner
     * @request PUT:/owner/homeworks/{homework_id}/update_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    updateHomeworkImageAsOwner: (
      homework_id: string,
      data: { image_id: string; file: string },
      params?: RequestParams,
    ) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/owner/homeworks/${homework_id}/update_image`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Owner/HomeworkTemplate, Owner/Homework, Homework, HomeworkTemplate
     * @name DeleteHomeworkImageAsOwner
     * @request DELETE:/owner/homeworks/{homework_id}/delete_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    deleteHomeworkImageAsOwner: (homework_id: string, data: { image_id: string }, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/owner/homeworks/${homework_id}/delete_image`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description - メール通知先: 提出物を配布された生徒。 - メール内容: 提出物の課題が届いたこと。
     *
     * @tags Owner/Homework, Homework
     * @name BatchCreateHomeworksAsOwner
     * @summary HomeworkTemplateを利用してHomeworkを複数作成する。同時にメール通知する。
     * @request PATCH:/owner/homeworks/batch_create
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    batchCreateHomeworksAsOwner: (
      data: { homework_template_id?: string; create_params_list: { student_id: string; teacher_id?: string | null }[] },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/homeworks/batch_create`, "PATCH", params, data),

    /**
     * @description - 提出物の添削権限を持ったOwnerのみ使用できる - メール通知先：更新された提出物にアクションを取る必要がある人。 - メール内容：提出物が届いたこと。
     *
     * @tags Owner/Homework, Homework
     * @name BatchUpdateHomeworksAsOwner
     * @summary Homeworkのステータスを一括更新する。同時にメール通知する。
     * @request PATCH:/owner/homeworks/batch_update
     * @response `200` `{ homeworks: (HomeworkBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    batchUpdateHomeworksAsOwner: (data: BatchUpdateHomeworkRequest, params?: RequestParams) =>
      this.request<{ homeworks: HomeworkBase[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/homeworks/batch_update`,
        "PATCH",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Homework, HomeworkTemplate, Owner/Homework, Owner/HomeworkTemplate
     * @name BatchDeleteHomeworksAsOwner
     * @summary 複数の宿題テンプレートの配信を削除する。
     * @request PATCH:/owner/homeworks/batch_delete
     * @response `200` `{ deleted_ids?: (number)[], errors?: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    batchDeleteHomeworksAsOwner: (data: { homework_ids: string[] }, params?: RequestParams) =>
      this.request<
        { deleted_ids?: number[]; errors?: string[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/homeworks/batch_delete`, "PATCH", params, data),

    /**
     * @description 管理者が宿題の添削画像ダウンロード用URL一覧を取得
     *
     * @tags Homework
     * @name GetHomeworkImageDownloadUrlsAsOwner
     * @summary 管理者が宿題の詳細を取得
     * @request GET:/owner/homeworks/get_download_urls
     * @response `200` `{ homeworks: (HomeworkDownloadUrl)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getHomeworkImageDownloadUrlsAsOwner: (query: GetHomeworkImageDownloadUrlsAsOwnerParams, params?: RequestParams) =>
      this.request<{ homeworks: HomeworkDownloadUrl[] }, Unauthorized | Forbidden | { error?: string }>(
        `/owner/homeworks/get_download_urls${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - "Owner限定 HomeworkTemplateCustomFieldFeedbackを登録"
     *
     * @tags Owner/HomeworkTemplateCustomFieldFeedbacks
     * @name PostHomeworkTemplateCustomFieldFeedbackAsOwner
     * @summary HomeworkTemplateCustomFieldFeedbackを登録
     * @request POST:/owner/homework_template_custom_fields/{homework_template_custom_field_id}/feedbacks
     * @response `201` `HomeworkTemplateCustomFieldFeedback` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postHomeworkTemplateCustomFieldFeedbackAsOwner: (
      homework_template_custom_field_id: string,
      data: CustomFieldFeedbackRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomFieldFeedback, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/homework_template_custom_fields/${homework_template_custom_field_id}/feedbacks`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - "Owner限定 HomeworkTemplateCustomFieldFeedbackを更新"
     *
     * @tags Owner/HomeworkTemplateCustomFieldFeedbacks
     * @name PutHomeworkTemplateCustomFieldFeedbacksAsOwner
     * @summary HomeworkTemplateCustomFieldFeedbackを更新
     * @request PUT:/owner/homework_template_custom_fields/{homework_template_custom_field_id}/feedbacks/{id}
     * @response `202` `HomeworkTemplateCustomFieldFeedback` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putHomeworkTemplateCustomFieldFeedbacksAsOwner: (
      homework_template_custom_field_id: string,
      id: string,
      data: PutCustomFieldFeedbackRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomFieldFeedback, BadRequest | Unauthorized | Forbidden>(
        `/owner/homework_template_custom_fields/${homework_template_custom_field_id}/feedbacks/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "Owner限定 HomeworkTemplateCustomFieldFeedbackを削除"
     *
     * @tags Owner/HomeworkTemplateCustomFieldFeedbacks
     * @name DeleteHomeworkTemplateCustomFieldFeedbacksAsOwner
     * @summary HomeworkTemplateCustomFieldFeedbackを削除
     * @request DELETE:/owner/homework_template_custom_fields/{homework_template_custom_field_id}/feedbacks/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteHomeworkTemplateCustomFieldFeedbacksAsOwner: (
      homework_template_custom_field_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/owner/homework_template_custom_fields/${homework_template_custom_field_id}/feedbacks/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Get the list of owner emails
     *
     * @tags OwnerEmails
     * @name FetchOwnerEmails
     * @summary [For Owner] Get list of owner emails
     * @request GET:/owner/emails
     * @response `200` `{ emails: (EmailBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchOwnerEmails: (query: FetchOwnerEmailsParams, params?: RequestParams) =>
      this.request<{ emails: EmailBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/owner/emails${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Owner to create an owner email. Once API accepts the request, email will be sent to selected user chosen by to_all_students and to_all_teachers flag
     *
     * @tags OwnerEmails
     * @name CreateOwnerEmail
     * @summary [For Owner] Create an email entry
     * @request POST:/owner/emails
     * @response `200` `EmailBase` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    createOwnerEmail: (
      data: {
        title: string;
        body: string;
        to_all_students?: boolean;
        to_all_teachers?: boolean;
        target_user_tags?: string[];
      },
      params?: RequestParams,
    ) => this.request<EmailBase, Unauthorized | Forbidden>(`/owner/emails`, "POST", params, data),

    /**
     * @description Return Owner Email detail
     *
     * @tags OwnerEmails
     * @name FetchOneOwnerEmailById
     * @summary [For Owner] Owner Email detail
     * @request GET:/owner/emails/{owner_email_id}
     * @response `200` `({ body?: string } & EmailBase)` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchOneOwnerEmailById: (owner_email_id: string, params?: RequestParams) =>
      this.request<{ body?: string } & EmailBase, Unauthorized | Forbidden | { error?: string }>(
        `/owner/emails/${owner_email_id}`,
        "GET",
        params,
      ),

    /**
     * @description Update category sequences to order by sequence. If sequences are not specified, sort order will be done by object ID.
     *
     * @tags Owner/Categories
     * @name PutOwnerCategoriesSequence
     * @summary [Owner] Update category sequences
     * @request PUT:/owner/categories/sequence
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putOwnerCategoriesSequence: (
      data: { sequences?: { category_id?: string; sequence?: number }[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden>(
        `/owner/categories/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description params[:sent]がtrueの時はオーナーが送信したお知らせを取得する。ない場合はオーナー宛に送信されたお知らせを取得する。
     *
     * @tags Owner/Notification
     * @name NotificationsList
     * @summary オーナー宛に送信されたお知らせを取得 or オーナーが送信したお知らせを取得(他のオーナーも含む)
     * @request GET:/owner/notifications
     * @response `200` `Notifications` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    notificationsList: (query: NotificationsListParams, params?: RequestParams) =>
      this.request<Notifications, Unauthorized | Forbidden | { error?: string }>(
        `/owner/notifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - ユーザタグのIDが送られてきた場合は、それに紐付いたユーザにお知らせを送付 - ターゲットロールが送られてきた場合は、そのロールを持つ自社ユーザにお知らせを送付(target_company_idsが必要) - 両方送られてきた場合、両方に紐づくユーザにお知らせを送付 - with_emailパラメータが送られてきている場合は、お知らせと同じ内容のメールを配信 - publication_timeが送られてきた場合は、その時間にお知らせを配信
     *
     * @tags Owner/Notification
     * @name CreateNotificationAsOwner
     * @summary オーナーが先生全体、生徒全体にお知らせを送信
     * @request POST:/owner/notifications
     * @response `200` `{ message?: string }`
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    createNotificationAsOwner: (
      data: {
        target_roles?: string[];
        target_user_tag_ids?: string[];
        target_company_ids?: string[];
        title: string;
        message: string;
        with_email?: boolean;
        publication_time?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/owner/notifications`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Owner限定 お知らせの詳細を取得
     *
     * @tags Owner/Notification
     * @name GetOwnerNotificationId
     * @summary お知らせの詳細を取得
     * @request GET:/owner/notifications/{id}
     * @response `200` `Notification` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getOwnerNotificationId: (id: string, params?: RequestParams) =>
      this.request<Notification, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/notifications/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - target_user_tag_idsが送られてきた場合は紐づくユーザにお知らせを送信 - target_company_tag_idsとtarget_rolesが送られてきた場合は指定したロールの紐づくユーザにお知らせを送信 - target_company_idsとtarget_rolesが送られてきた場合は指定したロールの対象会社のユーザにお知らせを送信 - target_company_tag_idsまたはtarget_company_idsを指定する場合はtarget_rolesは必須 - publication_timeは必須 - 予約配信時間が3分前のお知らせは変更できない
     *
     * @tags Owner/Notification
     * @name PutOwnerNotificationId
     * @summary Owner: お知らせの情報更新
     * @request PUT:/owner/notifications/{id}
     * @response `202` `Notification` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putOwnerNotificationId: (id: string, data: NotificationRequest, params?: RequestParams) =>
      this.request<Notification, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/notifications/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - 予約配信時間が3分前のお知らせは変更できない - お知らせ済みのものは削除できない
     *
     * @tags Owner/Notification
     * @name DeleteOwnerNotificationId
     * @summary Owner: お知らせの削除
     * @request DELETE:/owner/notifications/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteOwnerNotificationId: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/notifications/${id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags Owner/Article, articles
     * @name FetchArticleAsOwner
     * @summary 探究成果記事の詳細を取得
     * @request GET:/owner/articles/{article_id}
     * @response `200` `ArticleDetailBase` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchArticleAsOwner: (article_id: string, params?: RequestParams) =>
      this.request<ArticleDetailBase, Unauthorized | Forbidden | { error?: string }>(
        `/owner/articles/${article_id}`,
        "GET",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a Reviewer
     *
     * @tags Owner/Article
     * @name FetchArticlesAsOwner
     * @summary Articles Index
     * @request GET:/owner/articles
     * @response `200` `{ articles: ((ArticleBase & { excerpt?: string, liked?: boolean, unliked?: boolean }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    fetchArticlesAsOwner: (query: FetchArticlesAsOwnerParams, params?: RequestParams) =>
      this.request<
        { articles: (ArticleBase & { excerpt?: string; liked?: boolean; unliked?: boolean })[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/owner/articles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - this endpoint needs word_cloud_feature
     *
     * @tags Owner/Article
     * @name FetchArticleWordCloudAsOwner
     * @summary 自身が閲覧可能な探究成果から生成したワードクラウドリストを返却
     * @request GET:/owner/articles/word_cloud
     * @response `200` `WordCloudBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchArticleWordCloudAsOwner: (query: FetchArticleWordCloudAsOwnerParams, params?: RequestParams) =>
      this.request<WordCloudBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/articles/word_cloud${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a teacher
     *
     * @tags Owner/Article
     * @name FetchArticleFeedbacksAsOwner
     * @summary Article Feedbacks Index
     * @request GET:/owner/articles/{article_id}/feedbacks
     * @response `200` `{ article_feedbacks: (ArticleFeedback)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    fetchArticleFeedbacksAsOwner: (
      { article_id, ...query }: FetchArticleFeedbacksAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { article_feedbacks: ArticleFeedback[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/owner/articles/${article_id}/feedbacks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Return articles to the current user logged in as a owner
     *
     * @tags Owner/Article
     * @name FetchArticleCommentsAsOwner
     * @summary Article Comments Index
     * @request GET:/owner/articles/{article_id}/comments
     * @response `200` `{ article_comments: (ArticleComment)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    fetchArticleCommentsAsOwner: (
      { article_id, ...query }: FetchArticleCommentsAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<{ article_comments: ArticleComment[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/owner/articles/${article_id}/comments${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a Reviewer
     *
     * @tags Owner/AffiliateInvitation
     * @name FetchAffiliateInvitationsAsOwner
     * @summary AffiliateInvitation Index
     * @request GET:/owner/affiliate_invitations
     * @response `200` `{ invitations: (AffiliateInvitation)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    fetchAffiliateInvitationsAsOwner: (query: FetchAffiliateInvitationsAsOwnerParams, params?: RequestParams) =>
      this.request<{ invitations: AffiliateInvitation[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/owner/affiliate_invitations${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Owner/AffiliateInvitation, AffiliateInvitation
     * @name FetchAffiliateInvitationAsOwner
     * @summary AffiliateInvitationId
     * @request GET:/owner/affiliate_invitations/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchAffiliateInvitationAsOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/affiliate_invitations/${id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @name AffiliateInvitationsUpdate
     * @request PUT:/owner/affiliate_invitations/{id}
     * @response `200` `{ message?: string }`
     * @response `202` `AffiliateInvitation` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    affiliateInvitationsUpdate: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/affiliate_invitations/${id}`,
        "PUT",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a Reviewer
     *
     * @tags Owner/AffiliateInvitation
     * @name ActivateAffiliateInvitationAsOwner
     * @summary activate affiliate invitation based on grade
     * @request PUT:/owner/affiliate_invitations/activate
     * @response `200` `AffiliateInvitation` OK
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    activateAffiliateInvitationAsOwner: (
      data: { grade?: StudentGrade; user_tag_ids?: string[]; role?: UserType },
      params?: RequestParams,
    ) =>
      this.request<AffiliateInvitation, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/owner/affiliate_invitations/activate`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Return articles to the current user logged in as a Reviewer
     *
     * @tags AffiliateInvitation
     * @name InactivateAffiliateInvitationAsOwner
     * @summary activate affiliate invitation based on grade
     * @request PUT:/owner/affiliate_invitations/{id}/inactivate
     * @response `200` `AffiliateInvitation` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    inactivateAffiliateInvitationAsOwner: (id: string, params?: RequestParams) =>
      this.request<AffiliateInvitation, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/affiliate_invitations/${id}/inactivate`,
        "PUT",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a Reviewer
     *
     * @tags AffiliateInvitation
     * @name ReactivateAffiliateInvitationAsOwner
     * @summary reactivate affiliate invitation based on grade
     * @request PUT:/owner/affiliate_invitations/{id}/reactivate
     * @response `200` `AffiliateInvitation` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    reactivateAffiliateInvitationAsOwner: (id: string, params?: RequestParams) =>
      this.request<AffiliateInvitation, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/affiliate_invitations/${id}/reactivate`,
        "PUT",
        params,
      ),

    /**
     * @description ユーザにタグを付与
     *
     * @tags Owner/UserTagging
     * @name UserTaggingsCreate
     * @request POST:/owner/user_taggings
     * @response `201` `{ number_of_tagging_users?: number, remaining_number_of_tagging_users?: number | null }` Created
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    userTaggingsCreate: (data: { user_id?: string; user_tag_id?: string }, params?: RequestParams) =>
      this.request<
        { number_of_tagging_users?: number; remaining_number_of_tagging_users?: number | null },
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/user_taggings`, "POST", params, data),

    /**
     * @description ユーザからタグを削除
     *
     * @tags Owner/UserTagging
     * @name UserTaggingsDelete
     * @request DELETE:/owner/user_taggings
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     */
    userTaggingsDelete: (data: { user_id?: string; user_tag_id?: string }, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string }>(`/owner/user_taggings`, "DELETE", params, data),

    /**
     * @description ユーザに一括でタグを付与
     *
     * @tags Owner/UserTagging
     * @name OwnerUserTaggingBatchCreate
     * @request POST:/owner/user_taggings/batch_create
     * @response `200` `UserTaggingBatchCreateResponses` OK
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    ownerUserTaggingBatchCreate: (data: UserTaggingBatchCreateParams, params?: RequestParams) =>
      this.request<UserTaggingBatchCreateResponses, { message?: string } | Unauthorized | Forbidden>(
        `/owner/user_taggings/batch_create`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 自社作成含む自社に紐づく「ユーザータグ」と「ユーザータグタイプ」を一覧取得
     *
     * @tags Owner/UserTagType
     * @name UserTagTypesList
     * @request GET:/owner/user_tag_types
     * @response `200` `{ user_tag_types: (UserTagType)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    userTagTypesList: (query: UserTagTypesListParams, params?: RequestParams) =>
      this.request<{ user_tag_types: UserTagType[]; total_count: number }, Unauthorized | Forbidden>(
        `/owner/user_tag_types${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Ownerがユーザタグ分類を新規作成
     *
     * @tags Owner/UserTagType
     * @name UserTagTypesCreate
     * @request POST:/owner/user_tag_types
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    userTagTypesCreate: (
      data: { name?: string; kind?: UserTagTypeKind; teacher_visible?: boolean },
      params?: RequestParams,
    ) => this.request<{ message?: string }, BadRequest>(`/owner/user_tag_types`, "POST", params, data),

    /**
     * @description kindやtarget_company_idの更新は不可
     *
     * @tags Owner/UserTagType
     * @name UserTagTypesUpdate
     * @request PUT:/owner/user_tag_types/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    userTagTypesUpdate: (id: string, data: { name?: string; teacher_visible?: boolean }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/owner/user_tag_types/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 削除
     *
     * @tags Owner/UserTagType
     * @name UserTagTypesDelete
     * @request DELETE:/owner/user_tag_types/{id}
     * @response `200` `{ message?: string }`
     * @response `404` `{ error?: string }`
     */
    userTagTypesDelete: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(`/owner/user_tag_types/${id}`, "DELETE", params),

    /**
     * @description 指定したユーザタグ分類に紐づくユーザタグを一覧取得
     *
     * @tags Owner/UserTag
     * @name UserTagTypesUserTagsDetail
     * @request GET:/owner/user_tag_types/{user_tag_type_id}/user_tags
     * @response `200` `{ user_tags?: (UserTag)[], total_count?: number }` OK
     * @response `404` `{ error?: string }`
     */
    userTagTypesUserTagsDetail: (user_tag_type_id: string, params?: RequestParams) =>
      this.request<{ user_tags?: UserTag[]; total_count?: number }, { error?: string }>(
        `/owner/user_tag_types/${user_tag_type_id}/user_tags`,
        "GET",
        params,
      ),

    /**
     * @description UserGroup一覧を取得する
     *
     * @tags UserGroup, Owner/UserGroup
     * @name FetchUserGroupsAsOwner
     * @request GET:/owner/user_groups
     * @response `200` `{ user_groups?: (UserGroup)[], total_count?: number }` OK
     * @response `404` `{ error?: string }`
     */
    fetchUserGroupsAsOwner: (params?: RequestParams) =>
      this.request<{ user_groups?: UserGroup[]; total_count?: number }, { error?: string }>(
        `/owner/user_groups`,
        "GET",
        params,
      ),

    /**
     * @description オーナーが新規で教員と生徒の紐付けを作成
     *
     * @tags UserGroups
     * @name CreateUserGroupAsOwner
     * @summary 新規にUserGroupを作成する
     * @request POST:/owner/user_groups
     * @response `201` `UserGroup` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    createUserGroupAsOwner: (data: { grade?: StudentGrade; name?: string }, params?: RequestParams) =>
      this.request<UserGroup, Unauthorized | Forbidden>(`/owner/user_groups`, "POST", params, data),

    /**
     * @description タグに紐づくユーザに教材を一斉配信(マイナビ担当オーナーのみ可能) publication_timeが指定されている場合はその時間に配信 関連するowner, teacher, studentにお知らせ・メールを送信する。 処理はバックグラウンドで実行する
     *
     * @tags Owner/MaterialWork
     * @name MaterialWorksBatchCreateUsingTagsCreate
     * @request POST:/owner/material_works/batch_create_using_tags
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    materialWorksBatchCreateUsingTagsCreate: (
      data: {
        material_id?: string;
        user_tag_ids?: string[];
        publication_time?: string;
        expiration_time?: string;
        forbid_download?: boolean;
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/material_works/batch_create_using_tags`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 予約配信の削除
     *
     * @tags Owner/Reservation
     * @name ReservationsDelete
     * @request DELETE:/owner/reservations/{id}
     * @response `204` `any` No Content
     * @response `404` `{ error?: string }`
     */
    reservationsDelete: (id: string, params?: RequestParams) =>
      this.request<any, { error?: string }>(`/owner/reservations/${id}`, "DELETE", params),

    /**
     * @description 管理者が参照可能なUserTag一覧を取得する
     *
     * @tags Owner/UserTag
     * @name FetchUserTagsAsOwner
     * @request GET:/owner/user_tags
     * @response `200` `{ user_tags: (UserTag)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchUserTagsAsOwner: (query: FetchUserTagsAsOwnerParams, params?: RequestParams) =>
      this.request<
        { user_tags: UserTag[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/user_tags${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 新規タグ作成
     *
     * @tags Owner/UserTag
     * @name CreateUserTagAsOwner
     * @request POST:/owner/user_tags
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    createUserTagAsOwner: (
      data: { user_tag_type_id: string; name: string; limit_number?: number },
      params?: RequestParams,
    ) => this.request<{ message?: string }, BadRequest>(`/owner/user_tags`, "POST", params, data),

    /**
     * @description ユーザタグの詳細情報取得
     *
     * @tags Owner/UserTag
     * @name GetUserTagAsOwner
     * @request GET:/owner/user_tags/{id}
     * @response `200` `UserTagDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getUserTagAsOwner: (id: string, params?: RequestParams) =>
      this.request<UserTagDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/user_tags/${id}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザタグ情報を更新
     *
     * @tags Owner/UserTag
     * @name UpdateUserTagAsOwner
     * @request PUT:/owner/user_tags/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    updateUserTagAsOwner: (id: string, data: { name?: string; limit_number?: number }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/user_tags/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 削除
     *
     * @tags Owner/UserTag
     * @name DeleteUserTagAsOwner
     * @request DELETE:/owner/user_tags/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteUserTagAsOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/user_tags/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description ユーザタグの並び替え
     *
     * @tags Owner/UserTag
     * @name UserTagsSequenceUpdate
     * @request PUT:/owner/user_tags/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTagsSequenceUpdate: (data: { sequences: { id: string; sequence_number?: number }[] }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/user_tags/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description ユーザに一括でタグを付与
     *
     * @tags Owner/UserTags
     * @name OwnerUserTagsBatchCreate
     * @request PATCH:/owner/user_tags/batch_create
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    ownerUserTagsBatchCreate: (data: UserTagsBatchCreateParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/owner/user_tags/batch_create`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description 指定した教材の配信に使用したタグを取得
     *
     * @tags Owner/UserTag
     * @name GetUserTagsAlreadyBeenDistributedAsOwner
     * @request GET:/owner/user_tags/distributed_tags
     * @response `200` `{ user_tags: (DistributionHistoryInfo)[], total_count: number }` OK
     */
    getUserTagsAlreadyBeenDistributedAsOwner: (
      query: GetUserTagsAlreadyBeenDistributedAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<{ user_tags: DistributionHistoryInfo[]; total_count: number }, any>(
        `/owner/user_tags/distributed_tags${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザタグに紐づく生徒を一覧取得
     *
     * @tags Owner/UserTag/User
     * @name UserTagsUsersDetail
     * @request GET:/owner/user_tags/{user_tag_id}/users
     * @response `200` `{ users: (BasicUserInfo)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTagsUsersDetail: ({ user_tag_id, ...query }: UserTagsUsersDetailParams, params?: RequestParams) =>
      this.request<{ users: BasicUserInfo[]; total_count: number }, Unauthorized | Forbidden | { error?: string }>(
        `/owner/user_tags/${user_tag_id}/users${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザタグに紐づく生徒を一覧取得
     *
     * @tags Owner/UserTag/Student
     * @name UserTagsDistributedStudentsDetail
     * @request GET:/owner/user_tags/{id}/distributed_students
     * @response `200` `{ students: (SimpleUserList)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    userTagsDistributedStudentsDetail: (
      { id, ...query }: UserTagsDistributedStudentsDetailParams,
      params?: RequestParams,
    ) =>
      this.request<{ students: SimpleUserList[]; total_count: number }, { error?: string }>(
        `/owner/user_tags/${id}/distributed_students${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description オーナーとして提出物テンプレート一覧を取得する。
     *
     * @tags Owner/HomeworkTemplate, Owner/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplatesAsOwner
     * @request GET:/owner/homework_templates
     * @response `200` `{ homework_templates: (HomeworkTemplateBaseForOwnerAndTeacher)[], total_count: number }` OK
     * @response `404` `{ error?: string }` Not Found
     */
    fetchHomeworkTemplatesAsOwner: (query: FetchHomeworkTemplatesAsOwnerParams, params?: RequestParams) =>
      this.request<
        { homework_templates: HomeworkTemplateBaseForOwnerAndTeacher[]; total_count: number },
        { error?: string }
      >(`/owner/homework_templates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description HomeworkTemplateを作成し、作成されたHomeworkTemplateを返す
     *
     * @tags Owner/HomeworkTemplate, Owner/Homework, Homework, HomeworkTemplate
     * @name PostHomeworkTemplateAsOwner
     * @request POST:/owner/homework_templates
     * @response `201` `HomeworkTemplateForOwnerAndTeacher` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }` Not Found
     * @response `422` `ErrorsWithKeyMessage`
     */
    postHomeworkTemplateAsOwner: (data: HomeworkTemplateCreateRequest, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/owner/homework_templates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Owner限定 提出物テンプレートの配信ステータス
     *
     * @tags Owner/HomeworkTemplate, Owner/Homework, Homework, HomeworkTemplate
     * @name GetHomeworkTemplateDetailListAsOwner
     * @summary 指定した提出物テンプレートの配信ステータスを返却
     * @request GET:/owner/homework_templates/detail_list
     * @response `200` `{ homework_templates: ((ModelBase & HomeworkTemplateMeta & { homework_template_distribution?: HomeworkTemplateDistribution }))[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getHomeworkTemplateDetailListAsOwner: (query: GetHomeworkTemplateDetailListAsOwnerParams, params?: RequestParams) =>
      this.request<
        {
          homework_templates: (ModelBase &
            HomeworkTemplateMeta & { homework_template_distribution?: HomeworkTemplateDistribution })[];
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/homework_templates/detail_list${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 指定したHomeworkTemplateを取得する
     *
     * @tags Owner/HomeworkTemplate, Owner/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplateAsOwner
     * @request GET:/owner/homework_templates/{homework_template_id}
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `404` `{ error?: string }` Not Found
     */
    fetchHomeworkTemplateAsOwner: (homework_template_id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, { error?: string }>(
        `/owner/homework_templates/${homework_template_id}`,
        "GET",
        params,
      ),

    /**
     * @description HomeworkTemplateをアップデートする。
     *
     * @tags Owner/HomeworkTemplate, Owner/Homework, Homework, HomeworkTemplate
     * @name PutHomeworkTemplateAsOwner
     * @request PUT:/owner/homework_templates/{homework_template_id}
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `404` `{ error?: string }` Not Found
     */
    putHomeworkTemplateAsOwner: (
      homework_template_id: string,
      data: HomeworkTemplateUpdateRequest,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, { error?: string }>(
        `/owner/homework_templates/${homework_template_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description HomeworkTemplateの削除
     *
     * @tags Owner/HomeworkTemplate
     * @name DeleteHomeworkTemplateAsOwner
     * @request DELETE:/owner/homework_templates/{homework_template_id}
     * @response `200` `{ message?: string }` OK
     * @response `404` `{ error?: string }` Not Found
     */
    deleteHomeworkTemplateAsOwner: (homework_template_id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { error?: string }>(
        `/owner/homework_templates/${homework_template_id}`,
        "DELETE",
        params,
      ),

    /**
     * @description 提出物のカスタムフィールドの一覧を返却
     *
     * @tags Owner/HomeworkTemplateCustomFields
     * @name GetOwnerHomeworkTemplateCustomFields
     * @summary Owner: 提出物のカスタムフィールドの一覧を返却
     * @request GET:/owner/homework_templates/{homework_template_id}/homework_template_custom_fields
     * @response `200` `{ homework_template_custom_fields: (HomeworkTemplateCustomField)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getOwnerHomeworkTemplateCustomFields: (
      { homework_template_id, ...query }: GetOwnerHomeworkTemplateCustomFieldsParams,
      params?: RequestParams,
    ) =>
      this.request<
        { homework_template_custom_fields: HomeworkTemplateCustomField[]; total_count: number },
        Unauthorized | Forbidden
      >(
        `/owner/homework_templates/${homework_template_id}/homework_template_custom_fields${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description 提出物のカスタムフィールドの登録
     *
     * @tags Owner/HomeworkTemplateCustomFields
     * @name PostOwnerHomeworkTemplateCustomFields
     * @summary Owner: 提出物のカスタムフィールドの登録
     * @request POST:/owner/homework_templates/{homework_template_id}/homework_template_custom_fields
     * @response `201` `HomeworkTemplateCustomField` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postOwnerHomeworkTemplateCustomFields: (
      homework_template_id: string,
      data: CustomFieldCommonRequestBody & { type: HomeworkTemplateCustomFieldType },
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomField, Unauthorized | Forbidden>(
        `/owner/homework_templates/${homework_template_id}/homework_template_custom_fields`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 提出物のカスタムフィールドの情報取得
     *
     * @tags Owner/HomeworkTemplateCustomFields
     * @name GetOwnerHomeworkTemplateCustomFieldsId
     * @summary Owner: 提出物のカスタムフィールドの情報参照
     * @request GET:/owner/homework_templates/{homework_template_id}/homework_template_custom_fields/{id}
     * @response `200` `HomeworkTemplateCustomField` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getOwnerHomeworkTemplateCustomFieldsId: (homework_template_id: string, id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateCustomField, BadRequest | Unauthorized | Forbidden>(
        `/owner/homework_templates/${homework_template_id}/homework_template_custom_fields/${id}`,
        "GET",
        params,
      ),

    /**
     * @description 提出物のカスタムフィールドの情報の更新
     *
     * @tags Owner/HomeworkTemplateCustomFields
     * @name PutOwnerHomeworkTemplateCustomFieldsId
     * @summary Owner: 提出物のカスタムフィールドの情報更新
     * @request PUT:/owner/homework_templates/{homework_template_id}/homework_template_custom_fields/{id}
     * @response `202` `HomeworkTemplateCustomField` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putOwnerHomeworkTemplateCustomFieldsId: (
      homework_template_id: string,
      id: string,
      data: CustomFieldCommonRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomField, BadRequest | Unauthorized | Forbidden>(
        `/owner/homework_templates/${homework_template_id}/homework_template_custom_fields/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 提出物のカスタムフィールドの情報の削除
     *
     * @tags Owner/HomeworkTemplateCustomFields
     * @name DeleteOwnerHomeworkTemplateCustomFieldsId
     * @summary Owner: 提出物のカスタムフィールドの削除
     * @request DELETE:/owner/homework_templates/{homework_template_id}/homework_template_custom_fields/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteOwnerHomeworkTemplateCustomFieldsId: (homework_template_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/owner/homework_templates/${homework_template_id}/homework_template_custom_fields/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description カスタムフィールドの並び替え
     *
     * @tags Owner/HomeworkTemplateCustomField
     * @name HomeworkTemplatesHomeworkTemplateCustomFieldsSequenceUpdate
     * @request PUT:/owner/homework_templates/{homework_template_id}/homework_template_custom_fields/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    homeworkTemplatesHomeworkTemplateCustomFieldsSequenceUpdate: (
      homework_template_id: string,
      data: { sequences: { id: string; sequence_number: number }[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/homework_templates/${homework_template_id}/homework_template_custom_fields/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Owner/HomeworkTemplate
     * @name PutOwnerHomeworkTemplateConvertPdf
     * @summary HomeworkTemplateに登録されているPDFファイルの画像化
     * @request PUT:/owner/homework_templates/{id}/convert_pdf
     * @response `201` `{ message?: string }` OK
     */
    putOwnerHomeworkTemplateConvertPdf: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, any>(`/owner/homework_templates/${id}/convert_pdf`, "PUT", params),

    /**
     * @description HomeworkTemplateを複製する
     *
     * @tags Owner/HomeworkTemplate, HomeworkTemplate
     * @name PostHomeworkTemplateDuplicateAsOwner
     * @request POST:/owner/homework_templates/{id}/duplicate
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postHomeworkTemplateDuplicateAsOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/homework_templates/${id}/duplicate`,
        "POST",
        params,
      ),

    /**
     * No description
     *
     * @tags HomeworkTemplateDistribution, Owner/HomeworkTemplateDistribution
     * @name UpdateHomeworkTemplateDistributionAsOwner
     * @summary OwnerがHomeworkTemplateDistributionを更新
     * @request PUT:/owner/homework_template_distributions/{id}
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    updateHomeworkTemplateDistributionAsOwner: (id: string, data: { comment?: string }, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, BadRequest | Unauthorized | Forbidden>(
        `/owner/homework_template_distributions/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Owner/HomeworkTemplate, Owner/HomeworkTemplateDistribution
     * @name AssignManagerToHomeworkTemplateDistributionAsOwner
     * @summary HomeworkTemplateDistributionにSchoolManagerを割り当てる
     * @request PUT:/owner/homework_template_distributions/{id}/assign_manager
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    assignManagerToHomeworkTemplateDistributionAsOwner: (
      id: string,
      data: { teacher_id: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/owner/homework_template_distributions/${id}/assign_manager`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Owner/HomeworkTemplate, Owner/HomeworkTemplateDistribution
     * @name SubmitHomeworkTemplateDistributionToReviewerAsOwner
     * @summary HomeworkTemplateDistributionをReviewerに提出する
     * @request PUT:/owner/homework_template_distributions/{id}/submit_to_reviewer
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    submitHomeworkTemplateDistributionToReviewerAsOwner: (
      id: string,
      data: { comment?: string | null; image_contents?: File[] },
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, { message?: string } | Unauthorized | Forbidden>(
        `/owner/homework_template_distributions/${id}/submit_to_reviewer`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Owner/HomeworkTemplate, Owner/HomeworkTemplateDistribution
     * @name RevertHomeworkTemplateDistributionSubmittedToReviewerAsOwner
     * @summary Reviewerに提出したHomeworkTemplateDistributionを取り下げる
     * @request PUT:/owner/homework_template_distributions/{id}/revert_submission_to_reviewer
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    revertHomeworkTemplateDistributionSubmittedToReviewerAsOwner: (id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, { message?: string } | Unauthorized | Forbidden>(
        `/owner/homework_template_distributions/${id}/revert_submission_to_reviewer`,
        "PUT",
        params,
      ),

    /**
     * No description
     *
     * @tags Owner/HomeworkTemplate, Owner/HomeworkTemplateDistribution
     * @name ReturnHomeworkTemplateDistributionToStudentsAsOwner
     * @summary HomeworkTemplateDistributionをStudentに返却する
     * @request PUT:/owner/homework_template_distributions/{id}/return_to_students
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    returnHomeworkTemplateDistributionToStudentsAsOwner: (id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, { message?: string } | Unauthorized | Forbidden>(
        `/owner/homework_template_distributions/${id}/return_to_students`,
        "PUT",
        params,
      ),

    /**
     * @description - Owner限定 HomeworkTemplateImage を登録
     *
     * @tags Owner/HomeworkTemplateImage
     * @name PostHomeworkTemplateImageAsOwner
     * @summary 提出物テンプレートの画像 を登録
     * @request POST:/owner/homework_templates/{homework_template_id}/homework_template_images
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postHomeworkTemplateImageAsOwner: (
      homework_template_id: string,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/homework_templates/${homework_template_id}/homework_template_images`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 HomeworkTemplateImage を更新
     *
     * @tags Owner/HomeworkTemplateImage
     * @name PutHomeworkTemplateImageAsOwner
     * @summary 提出物テンプレートの画像 を更新
     * @request PUT:/owner/homework_templates/{homework_template_id}/homework_template_images/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putHomeworkTemplateImageAsOwner: (
      homework_template_id: string,
      id: string,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/homework_templates/${homework_template_id}/homework_template_images/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 HomeworkTemplateImage を削除
     *
     * @tags Owner/HomeworkTemplateImage
     * @name DeleteHomeworkTemplateImageAsOwner
     * @summary 提出物テンプレートの画像 を削除
     * @request DELETE:/owner/homework_templates/{homework_template_id}/homework_template_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteHomeworkTemplateImageAsOwner: (homework_template_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/homework_templates/${homework_template_id}/homework_template_images/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Super Ownerが自社にタグ配信した履歴を返却する
     *
     * @tags Owner/MaterialDistributionHistory
     * @name ListMaterialDistributionHistoryAsOwner
     * @summary Super Ownerが自社にタグ配信した履歴を返却する
     * @request GET:/owner/material_distribution_histories/list
     * @response `200` `{ material_distribution_histories: (MaterialDistributionHistory)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    listMaterialDistributionHistoryAsOwner: (
      query: ListMaterialDistributionHistoryAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { material_distribution_histories: MaterialDistributionHistory[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/owner/material_distribution_histories/list${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Ownerに関連する配信履歴の配信先詳細を返却する
     *
     * @tags Owner/MaterialDistributionHistory
     * @name ListShowMaterialDistributionHistoryAsOwner
     * @summary Ownerに関連する配信履歴の配信先詳細を返却する
     * @request GET:/owner/material_distribution_histories/list_students
     * @response `200` `MaterialDistributionHistoryListStudents` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    listShowMaterialDistributionHistoryAsOwner: (
      query: ListShowMaterialDistributionHistoryAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<MaterialDistributionHistoryListStudents, Unauthorized | Forbidden>(
        `/owner/material_distribution_histories/list_students${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description UserIDから配信履歴を返却する
     *
     * @tags Owner/MaterialDistributionHistory
     * @name SearchByUserIdMaterialDistributionHistoryAsOwner
     * @summary UserIDから配信履歴を返却する
     * @request GET:/owner/material_distribution_histories/search_by_user_id
     * @response `200` `{ material_distribution_histories: (MaterialDistributionHistorySearchByUserId)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    searchByUserIdMaterialDistributionHistoryAsOwner: (
      query: SearchByUserIdMaterialDistributionHistoryAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { material_distribution_histories: MaterialDistributionHistorySearchByUserId[]; total_count: number },
        Unauthorized | Forbidden
      >(`/owner/material_distribution_histories/search_by_user_id${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Owner限定 探究レポートのフレームワークの一覧を返却
     *
     * @tags Owner/Pbl/Report/TemplateFrameworks
     * @name GetOwnerTemplateFrameworks
     * @summary Owner: 探究レポートのフレームワークの一覧を返却
     * @request GET:/owner/pbl/report/template_frameworks
     * @response `200` `{ report_template_frameworks?: (any)[], total_count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getOwnerTemplateFrameworks: (query: GetOwnerTemplateFrameworksParams, params?: RequestParams) =>
      this.request<{ report_template_frameworks?: any[]; total_count?: number }, Unauthorized | Forbidden>(
        `/owner/pbl/report/template_frameworks${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Owner限定 探究レポートのフレームワークの登録
     *
     * @tags Owner/Pbl/Report/TemplateFrameworks
     * @name PostOwnerTemplateFrameworks
     * @summary Owner: 探究レポートのフレームワークの登録
     * @request POST:/owner/pbl/report/template_frameworks
     * @response `201` `(ModelBase & { owner: AffiliateBase, title: string, created_at: string, updated_at: string, item_frameworks?: (any)[] })` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postOwnerTemplateFrameworks: (data: { title: string }, params?: RequestParams) =>
      this.request<
        ModelBase & {
          owner: AffiliateBase;
          title: string;
          created_at: string;
          updated_at: string;
          item_frameworks?: any[];
        },
        Unauthorized | Forbidden | ErrorsWithKeyMessage
      >(`/owner/pbl/report/template_frameworks`, "POST", params, data),

    /**
     * @description Owner限定 探究レポートのフレームワークの情報取得
     *
     * @tags Owner/Pbl/Report/TemplateFrameworks
     * @name GetOwnerTemplateFrameworksId
     * @summary Owner: 探究レポートのフレームワークの情報参照
     * @request GET:/owner/pbl/report/template_frameworks/{id}
     * @response `200` `any` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getOwnerTemplateFrameworksId: (id: string, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden>(
        `/owner/pbl/report/template_frameworks/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Owner限定 探究レポートのフレームワークの情報の更新
     *
     * @tags Owner/Pbl/Report/TemplateFrameworks
     * @name PutOwnerTemplateFrameworksId
     * @summary Owner: 探究レポートのフレームワークの情報更新
     * @request PUT:/owner/pbl/report/template_frameworks/{id}
     * @response `202` `any` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putOwnerTemplateFrameworksId: (id: string, data: { title: string }, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden>(
        `/owner/pbl/report/template_frameworks/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Owner限定 探究レポートのフレームワークの情報の削除
     *
     * @tags Owner/Pbl/Report/TemplateFrameworks
     * @name DeleteOwnerTemplateFrameworksId
     * @summary Owner: 探究レポートのフレームワークの削除
     * @request DELETE:/owner/pbl/report/template_frameworks/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteOwnerTemplateFrameworksId: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/owner/pbl/report/template_frameworks/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Owner限定 探究レポートのフレームワークのアイテムを登録
     *
     * @tags Owner/Pbl/Report/TemplateItemFrameworks
     * @name PostOwnerTemplateItemFrameworks
     * @summary Owner: 探究レポートのフレームワークのアイテムを登録
     * @request POST:/owner/pbl/report/template_frameworks/{template_framework_id}/item_frameworks
     * @response `201` `(ModelBase & { template_framework_id?: string, sequence?: number, title: string, description?: string, text_example?: string, text_min_char_size?: number, created_at: string, updated_at: string })` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postOwnerTemplateItemFrameworks: (
      template_framework_id: string,
      data: {
        type: "text";
        sequence?: number;
        title: string;
        description?: string;
        text_example?: string;
        text_min_char_size?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<
        ModelBase & {
          template_framework_id?: string;
          sequence?: number;
          title: string;
          description?: string;
          text_example?: string;
          text_min_char_size?: number;
          created_at: string;
          updated_at: string;
        },
        Unauthorized | Forbidden | ErrorsWithKeyMessage
      >(`/owner/pbl/report/template_frameworks/${template_framework_id}/item_frameworks`, "POST", params, data),

    /**
     * @description Owner限定 探究レポートのフレームワークの情報のアイテムを更新
     *
     * @tags Owner/Pbl/Report/TemplateItemFrameworks
     * @name PutOwnerTemplateItemFrameworksId
     * @summary Owner: 探究レポートのフレームワークのアイテムを更新
     * @request PUT:/owner/pbl/report/template_frameworks/{template_framework_id}/item_frameworks/{id}
     * @response `202` `any` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putOwnerTemplateItemFrameworksId: (
      template_framework_id: string,
      id: string,
      data: {
        sequence?: number;
        title: string;
        description?: string;
        text_example?: string;
        text_min_char_size?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<any, BadRequest | Unauthorized | Forbidden>(
        `/owner/pbl/report/template_frameworks/${template_framework_id}/item_frameworks/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Owner限定 探究レポートのフレームワークの情報のアイテムを削除
     *
     * @tags Owner/Pbl/Report/TemplateItemFrameworks
     * @name DeleteOwnerTemplateItemFrameworksId
     * @summary Owner: 探究レポートのフレームワークのアイテムを削除
     * @request DELETE:/owner/pbl/report/template_frameworks/{template_framework_id}/item_frameworks/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteOwnerTemplateItemFrameworksId: (template_framework_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/owner/pbl/report/template_frameworks/${template_framework_id}/item_frameworks/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Owner限定 お問い合わせ一覧を返却
     *
     * @tags Owner/Inquiries
     * @name GetOwnerInquiries
     * @summary Owner: お問い合わせ一覧を返却
     * @request GET:/owner/inquiries
     * @response `200` `{ inquiries: (InquirySimpleListBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getOwnerInquiries: (query: GetOwnerInquiriesParams, params?: RequestParams) =>
      this.request<{ inquiries: InquirySimpleListBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/owner/inquiries${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Owner限定 お問い合わせ情報取得
     *
     * @tags Owner/Inquiries
     * @name GetOwnerInquiriesId
     * @summary Owner: 提出物のお問い合わせ情報参照
     * @request GET:/owner/inquiries/{id}
     * @response `200` `InquirySimpleBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getOwnerInquiriesId: (id: string, params?: RequestParams) =>
      this.request<InquirySimpleBase, BadRequest | Unauthorized | Forbidden>(`/owner/inquiries/${id}`, "GET", params),

    /**
     * @description Owner限定 新着コメントが存在するお問い合わせの件数を返却
     *
     * @tags Owner/Inquiries
     * @name GetTheNumberOfNewCommentInquiriesAsOwner
     * @summary 新着コメントが存在するお問い合わせの件数を返却
     * @request GET:/owner/inquiries/new_comment_count
     * @response `200` `{ count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTheNumberOfNewCommentInquiriesAsOwner: (params?: RequestParams) =>
      this.request<{ count?: number }, Unauthorized | Forbidden>(`/owner/inquiries/new_comment_count`, "GET", params),

    /**
     * @description Owner限定 お問い合わせコメントの登録
     *
     * @tags Owner/InquiryComments
     * @name PostOwnerInquiryComments
     * @summary Owner: お問い合わせコメントの登録
     * @request POST:/owner/inquiry_comments
     * @response `201` `InquiryComment` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postOwnerInquiryComments: (query: PostOwnerInquiryCommentsParams, data: { body: string }, params?: RequestParams) =>
      this.request<InquiryComment, Unauthorized | Forbidden>(
        `/owner/inquiry_comments${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Owner限定 お問い合わせコメントの情報の更新
     *
     * @tags Owner/InquiryComments
     * @name PutOwnerInquiryCommentsId
     * @summary Owner: お問い合わせコメントの情報更新
     * @request PUT:/owner/inquiry_comments/{id}
     * @response `202` `InquiryComment` Accepted
     * @response `400` `BadRequest`
     */
    putOwnerInquiryCommentsId: (
      { id, ...query }: PutOwnerInquiryCommentsIdParams,
      data: { body: string; status?: string },
      params?: RequestParams,
    ) =>
      this.request<InquiryComment, BadRequest>(
        `/owner/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Owner限定 お問い合わせコメントの情報の削除
     *
     * @tags Owner/InquiryComments
     * @name DeleteOwnerInquiryCommentsId
     * @summary Owner: お問い合わせコメントの削除
     * @request DELETE:/owner/inquiry_comments/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteOwnerInquiryCommentsId: ({ id, ...query }: DeleteOwnerInquiryCommentsIdParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/owner/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 LearningTemplatePackageDistributionSetting を一覧を返却 - SuperOwnerが配信された学習コンテンツを取得
     *
     * @tags Owner/LearningTemplatePackageDistributions
     * @name GetLearningTemplatePackageDistributionsAsOwner
     * @summary 配信された学習コンテンツの 一覧を返却
     * @request GET:/owner/learning_template_package_distributions
     * @response `200` `{ learning_template_package_distribution_settings: (LearningTemplatePackageDistributionSettingBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionsAsOwner: (
      query: GetLearningTemplatePackageDistributionsAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          learning_template_package_distribution_settings: LearningTemplatePackageDistributionSettingBase[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/learning_template_package_distributions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 LearningTemplatePackageDistributionSetting を一覧を返却 - SuperOwnerが配信された学習コンテンツを取得 - 学習コンテンツの提出物と教材を返却
     *
     * @tags Owner/LearningTemplatePackageDistributions
     * @name GetLearningTemplatePackageDistributionAsOwner
     * @summary 配信された学習コンテンツの 詳細を返却
     * @request GET:/owner/learning_template_package_distributions/{id}
     * @response `200` `LearningTemplatePackageDistributionSettingBaseDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionAsOwner: (id: string, params?: RequestParams) =>
      this.request<
        LearningTemplatePackageDistributionSettingBaseDetails,
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/learning_template_package_distributions/${id}`, "GET", params),

    /**
     * @description - Owner限定 LearningPackage の詳細を返却 - 生徒に配信された学習コンテンツを取得 - 所属しているCompanyの生徒のみ取得可能
     *
     * @tags Owner/LearningPackages
     * @name GetLearningPackageAsOwner
     * @summary 生徒に配信された学習コンテンツの 詳細を返却
     * @request GET:/owner/learning_packages/{id}
     * @response `200` `LearningPackageBaseDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningPackageAsOwner: (id: string, params?: RequestParams) =>
      this.request<LearningPackageBaseDetails, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/learning_packages/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 LearningTemplatePackageDistributionSetting の配信履歴一覧を返却 - SuperTeacherが配信された学習コンテンツの配信履歴を取得
     *
     * @tags Owner/LearningTemplatePackageDistributionHistories
     * @name GetLearningTemplatePackageDistributionHistoriesAsOwner
     * @summary 学習コンテンツの配信履歴 一覧を返却
     * @request GET:/owner/learning_template_package_distribution_histories
     * @response `200` `{ learning_template_package_distribution_histories: (LearningTemplatePackageDistributionHistoryBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionHistoriesAsOwner: (
      query: GetLearningTemplatePackageDistributionHistoriesAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          learning_template_package_distribution_histories: LearningTemplatePackageDistributionHistoryBase[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/learning_template_package_distribution_histories${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 LearningTemplatePackageDistributionSetting の配信履歴 配信先一覧を返却
     *
     * @tags Owner/LearningTemplatePackageDistributionHistories
     * @name GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwner
     * @summary 学習コンテンツの配信履歴 配信先一覧を返却
     * @request GET:/owner/learning_template_package_distribution_histories/{id}/distributed_students
     * @response `200` `{ distributed_students: (DistributedStudents)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwner: (
      { id, ...query }: GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { distributed_students: DistributedStudents[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/owner/learning_template_package_distribution_histories/${id}/distributed_students${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 Packaging を返却
     *
     * @tags Owner/LearningTemplatePackageDistributionHistories
     * @name GetLearningTemplatePackageDistributionHistoriesPackagingsAsOwner
     * @summary 学習コンテンツに紐づいているコンテンツのリストを返却
     * @request GET:/owner/learning_template_package_distribution_histories/{id}/packagings
     * @response `200` `{ packagings: (PackagingAsLearningTemplatePackageBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionHistoriesPackagingsAsOwner: (
      { id, ...query }: GetLearningTemplatePackageDistributionHistoriesPackagingsAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { packagings: PackagingAsLearningTemplatePackageBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/owner/learning_template_package_distribution_histories/${id}/packagings${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Info の詳細を返却
     *
     * @tags Owner/PblBoardInfos
     * @name GetPblBoardInfoAsOwner
     * @summary 探究ボード の詳細を返却
     * @request GET:/owner/pbl/board/infos
     * @response `200` `PblBoardInfoBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardInfoAsOwner: (query: GetPblBoardInfoAsOwnerParams, params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/infos${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Info を登録
     *
     * @tags Owner/PblBoardInfos
     * @name PostPblBoardInfoAsOwner
     * @summary 探究ボード を登録
     * @request POST:/owner/pbl/board/infos
     * @response `201` `PblBoardInfoBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardInfoAsOwner: (params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/infos`,
        "POST",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Info を更新
     *
     * @tags Owner/PblBoardInfos
     * @name PutPblBoardInfoAsOwner
     * @summary 探究ボード を更新
     * @request PUT:/owner/pbl/board/infos
     * @response `202` `PblBoardInfoBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardInfoAsOwner: (data: { event: PblBoardInfoEvent }, params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/infos`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Item を登録
     *
     * @tags Owner/PblBoardItems
     * @name PostPblBoardItemAsOwner
     * @summary 探究ボードのコンテンツタイプ を登録
     * @request POST:/owner/pbl/board/items
     * @response `201` `PblBoardItemBaseAsShow` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardItemAsOwner: (
      data: { content_type: PblBoardItemContentType; sequence?: number },
      params?: RequestParams,
    ) =>
      this.request<PblBoardItemBaseAsShow, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Item を更新
     *
     * @tags Owner/PblBoardItems
     * @name PutPblBoardItemAsOwner
     * @summary 探究ボードのコンテンツタイプ を更新
     * @request PUT:/owner/pbl/board/items/{id}
     * @response `202` `PblBoardItemBaseAsShow` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardItemAsOwner: (
      id: string,
      data: { content_type: PblBoardItemContentType; sequence?: number },
      params?: RequestParams,
    ) =>
      this.request<PblBoardItemBaseAsShow, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Item を削除
     *
     * @tags Owner/PblBoardItems
     * @name DeletePblBoardItemAsOwner
     * @summary 探究ボードのコンテンツタイプ を削除
     * @request DELETE:/owner/pbl/board/items/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardItemAsOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Campaign の一覧を取得
     *
     * @tags Owner/PblBoardCampaigns
     * @name GetPblBoardCampaignsAsOwner
     * @summary 探究ボードの探究成果募集 の一覧を取得
     * @request GET:/owner/pbl/board/campaigns
     * @response `200` `{ board_campaigns: (PblBoardCampaignList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardCampaignsAsOwner: (query: GetPblBoardCampaignsAsOwnerParams, params?: RequestParams) =>
      this.request<
        { board_campaigns: PblBoardCampaignList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/pbl/board/campaigns${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 Pbl::Board::Campaign を登録
     *
     * @tags Owner/PblBoardCampaigns
     * @name PostPblBoardCampaignAsOwner
     * @summary 探究ボードの探究成果募集 を登録
     * @request POST:/owner/pbl/board/campaigns
     * @response `201` `PblBoardCampaignBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardCampaignAsOwner: (
      data: { title: string; description?: string; start_date: string; end_date: string },
      params?: RequestParams,
    ) =>
      this.request<PblBoardCampaignBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/campaigns`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Campaign の詳細を取得
     *
     * @tags Owner/PblBoardCampaigns
     * @name GetPblBoardCampaignAsOwner
     * @summary 探究ボードの探究成果募集 の詳細を取得
     * @request GET:/owner/pbl/board/campaigns/{id}
     * @response `200` `PblBoardCampaignBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardCampaignAsOwner: (id: string, params?: RequestParams) =>
      this.request<PblBoardCampaignBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/campaigns/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Campaign を更新
     *
     * @tags Owner/PblBoardCampaigns
     * @name PutPblBoardCampaignAsOwner
     * @summary 探究ボードの探究成果募集 を更新
     * @request PUT:/owner/pbl/board/campaigns/{id}
     * @response `202` `PblBoardCampaignBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardCampaignAsOwner: (
      id: string,
      data: { title: string; description?: string; start_date: string; end_date: string; event: PblBoardCampaignEvent },
      params?: RequestParams,
    ) =>
      this.request<PblBoardCampaignBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/campaigns/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Campaign を削除
     *
     * @tags Owner/PblBoardCampaigns
     * @name DeletePblBoardCampaignAsOwner
     * @summary 探究ボードの探究成果募集 を削除
     * @request DELETE:/owner/pbl/board/campaigns/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardCampaignAsOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/campaigns/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::CampaignRequest の一覧を取得
     *
     * @tags Owner/PblBoardCampaignRequests
     * @name GetPblBoardCampaignRequestAsOwner
     * @summary 探究ボードの探究成果募集のリクエスト一覧を取得
     * @request GET:/owner/pbl/board/campaigns/{campaign_id}/campaign_requests
     * @response `200` `{ board_campaign_requests: (PblBoardCampaignRequestList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardCampaignRequestAsOwner: (
      { campaign_id, ...query }: GetPblBoardCampaignRequestAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { board_campaign_requests: PblBoardCampaignRequestList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/pbl/board/campaigns/${campaign_id}/campaign_requests${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 Pbl::Board::CampaignRequest を更新
     *
     * @tags Owner/PblBoardCampaignRequests
     * @name PutPblBoardCampaignRequestAsOwner
     * @summary 探究ボードの探究成果募集のリクエスト更新
     * @request PUT:/owner/pbl/board/campaigns/{campaign_id}/campaign_requests/{id}
     * @response `202` `PblBoardCampaignRequestList` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardCampaignRequestAsOwner: (
      campaign_id: string,
      id: string,
      data: { event: PblBoardCampaignRequestEvent },
      params?: RequestParams,
    ) =>
      this.request<PblBoardCampaignRequestList, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/campaigns/${campaign_id}/campaign_requests/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::CampaignRequest を更新
     *
     * @tags Owner/PblBoardCampaignRequests
     * @name PutPblBoardCampaignRequestSequenceAsOwner
     * @summary 探究ボードの探究成果募集のリクエスト更新
     * @request PUT:/owner/pbl/board/campaigns/{campaign_id}/campaign_requests/{id}/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardCampaignRequestSequenceAsOwner: (
      campaign_id: string,
      id: string,
      data: { sequence: number },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/campaigns/${campaign_id}/campaign_requests/${id}/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Article を一覧を返却 - 承認済みのものを取得 - 探究成果のstatusがpublishedのものを取得
     *
     * @tags Owner/PblBoardArticles
     * @name GetPblBoardArticlesAsOwner
     * @summary 探究成果募集の探究成果情報 の一覧を返却
     * @request GET:/owner/pbl/board/articles
     * @response `200` `{ articles: (PblBoardArticleBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticlesAsOwner: (query: GetPblBoardArticlesAsOwnerParams, params?: RequestParams) =>
      this.request<
        { articles: PblBoardArticleBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/pbl/board/articles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - this endpoint needs word_cloud_feature
     *
     * @tags Owner/PblBoardArticles
     * @name GetPblBoardArticlesWordCloudAsOwner
     * @summary 自身が閲覧可能な探究成果から生成したワードクラウドリストを返却
     * @request GET:/owner/pbl/board/articles/word_cloud
     * @response `200` `WordCloudBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticlesWordCloudAsOwner: (query: GetPblBoardArticlesWordCloudAsOwnerParams, params?: RequestParams) =>
      this.request<WordCloudBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/articles/word_cloud${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 Article の詳細を返却 - 承認済みのものを取得 - 探究成果のstatusがpublishedのものを取得
     *
     * @tags Owner/PblBoardArticles
     * @name GetPblBoardArticleAsOwner
     * @summary 探究成果募集の探究成果情報 の詳細を返却
     * @request GET:/owner/pbl/board/articles/{id}
     * @response `200` `ArticleDetailAsPblBoard` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticleAsOwner: (id: string, params?: RequestParams) =>
      this.request<ArticleDetailAsPblBoard, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/articles/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Contents を登録 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags Owner/PblBoardContents
     * @name PostPblBoardContentAsOwner
     * @summary 探究ボードのコンテンツ を登録
     * @request POST:/owner/pbl/board/items/{item_id}/contents
     * @response `201` `PblBoardContentBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardContentAsOwner: (
      item_id: string,
      data: {
        design_type?: PblBoardContentDesignType;
        catchphrase?: string;
        catchphrase_description?: string;
        catchphrase_image?: File;
        cta_name?: string;
        cta_url?: string;
        external_link?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<PblBoardContentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/contents`,
        "POST",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Contents の詳細を返却 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags Owner/PblBoardContents
     * @name GetPblBoardContentAsOwner
     * @summary 探究ボードのコンテンツ の詳細を返却
     * @request GET:/owner/pbl/board/items/{item_id}/contents/{id}
     * @response `200` `PblBoardContentBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardContentAsOwner: (item_id: string, id: string, params?: RequestParams) =>
      this.request<PblBoardContentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/contents/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Contents を更新 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags Owner/PblBoardContents
     * @name PutPblBoardContentAsOwner
     * @summary 探究ボードのコンテンツ を更新
     * @request PUT:/owner/pbl/board/items/{item_id}/contents/{id}
     * @response `202` `PblBoardContentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardContentAsOwner: (
      item_id: string,
      id: string,
      data: {
        design_type?: PblBoardContentDesignType;
        catchphrase?: string;
        catchphrase_description?: string;
        catchphrase_image?: File;
        cta_name?: string;
        cta_url?: string;
        external_link?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<PblBoardContentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/contents/${id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Contents を削除 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags Owner/PblBoardContents
     * @name DeletePblBoardContentAsOwner
     * @summary 探究ボードのコンテンツ を削除
     * @request DELETE:/owner/pbl/board/items/{item_id}/contents/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardContentAsOwner: (item_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/contents/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Footers を登録
     *
     * @tags Owner/PblBoardFooters
     * @name GetPblBoardFootersAsOwner
     * @summary 探究ボードのフッター の一覧を取得
     * @request GET:/owner/pbl/board/items/{item_id}/footers
     * @response `200` `{ board_footers: (PblBoardFooterBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardFootersAsOwner: ({ item_id, ...query }: GetPblBoardFootersAsOwnerParams, params?: RequestParams) =>
      this.request<
        { board_footers: PblBoardFooterBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/pbl/board/items/${item_id}/footers${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 Pbl::Board::Footers を登録 - 探究ボードのコンテンツタイプが footer のみ可能
     *
     * @tags Owner/PblBoardFooters
     * @name PostPblBoardFooterAsOwner
     * @summary 探究ボードのフッター を登録
     * @request POST:/owner/pbl/board/items/{item_id}/footers
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardFooterAsOwner: (
      item_id: string,
      data: { category_name: string; footer_items?: { title: string; url: string }[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/footers`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Footers を更新 - 探究ボードのコンテンツタイプが footer のみ可能
     *
     * @tags Owner/PblBoardFooters
     * @name PutPblBoardFooterAsOwner
     * @summary 探究ボードのフッター を更新
     * @request PUT:/owner/pbl/board/items/{item_id}/footers/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardFooterAsOwner: (item_id: string, id: string, data: { category_name: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/footers/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Footers を削除 - 探究ボードのコンテンツタイプが footer のみ可能
     *
     * @tags Owner/PblBoardFooters
     * @name DeletePblBoardFooterAsOwner
     * @summary 探究ボードのフッター を削除
     * @request DELETE:/owner/pbl/board/items/{item_id}/footers/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardFooterAsOwner: (item_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/footers/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 Pbl::Board::Footers を更新 - 探究ボードのフッターの順番を更新
     *
     * @tags Owner/PblBoardFooters
     * @name PutPblBoardFooterSequenceAsOwner
     * @summary 探究ボードのフッター を更新
     * @request PUT:/owner/pbl/board/items/{item_id}/footers/{id}/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardFooterSequenceAsOwner: (
      item_id: string,
      id: string,
      data: { sequence: number },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/footers/${id}/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::FooterItems を登録
     *
     * @tags Owner/PblBoardFooterItems
     * @name PostPblBoardFooterItemAsOwner
     * @summary 探究ボードのフッターのリンク を登録
     * @request POST:/owner/pbl/board/items/{item_id}/footers/{footer_id}/footer_items
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardFooterItemAsOwner: (
      item_id: string,
      footer_id: string,
      data: { title: string; url: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/footers/${footer_id}/footer_items`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::FooterItems を更新
     *
     * @tags Owner/PblBoardFooterItems
     * @name PutPblBoardFooterItemAsOwner
     * @summary 探究ボードのフッターのリンク を更新
     * @request PUT:/owner/pbl/board/items/{item_id}/footers/{footer_id}/footer_items/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardFooterItemAsOwner: (
      item_id: string,
      footer_id: string,
      id: string,
      data: { title: string; url: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/footers/${footer_id}/footer_items/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Pbl::Board::FooterItems を削除
     *
     * @tags Owner/PblBoardFooterItems
     * @name DeletePblBoardFooterItemAsOwner
     * @summary 探究ボードのフッターのリンク を削除
     * @request DELETE:/owner/pbl/board/items/{item_id}/footers/{footer_id}/footer_items/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardFooterItemAsOwner: (item_id: string, footer_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/pbl/board/items/${item_id}/footers/${footer_id}/footer_items/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 AttachedImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags Owner/AttachedImages
     * @name PostAttachedImageAsOwner
     * @summary 画像 を登録
     * @request POST:/owner/attached_images
     * @response `201` `AttachedImageBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postAttachedImageAsOwner: (
      query: PostAttachedImageAsOwnerParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/attached_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 AttachedImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Owner/AttachedImages
     * @name GetAttachedImageAsOwner
     * @summary 画像 の詳細を返却
     * @request GET:/owner/attached_images/{id}
     * @response `200` `AttachedImageBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getAttachedImageAsOwner: ({ id, ...query }: GetAttachedImageAsOwnerParams, params?: RequestParams) =>
      this.request<AttachedImageBase, BadRequest | Unauthorized | Forbidden>(
        `/owner/attached_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperOwner限定 AttachedImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Owner/AttachedImages
     * @name PutAttachedImageAsOwner
     * @summary 画像 を更新
     * @request PUT:/owner/attached_images/{id}
     * @response `202` `AttachedImageBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putAttachedImageAsOwner: (
      { id, ...query }: PutAttachedImageAsOwnerParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | Unauthorized | Forbidden>(
        `/owner/attached_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 AttachedImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Owner/AttachedImages
     * @name DeleteAttachedImageAsOwner
     * @summary 画像 を削除
     * @request DELETE:/owner/attached_images/{id}
     * @response `200` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteAttachedImageAsOwner: ({ id, ...query }: DeleteAttachedImageAsOwnerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/owner/attached_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 LimitedTimeNotification を一覧を返却
     *
     * @tags Owner/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationsAsOwner
     * @summary 期間限定のお知らせの一覧を返却
     * @request GET:/owner/limited_time_notifications
     * @response `200` `{ limited_time_notifications: (LimitedTimeNotificationList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationsAsOwner: (query: GetLimitedTimeNotificationsAsOwnerParams, params?: RequestParams) =>
      this.request<
        { limited_time_notifications: LimitedTimeNotificationList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/limited_time_notifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 LimitedTimeNotification を登録
     *
     * @tags Owner/LimitedTimeNotifications
     * @name PostLimitedTimeNotificationAsOwner
     * @summary 期間限定のお知らせ を登録
     * @request POST:/owner/limited_time_notifications
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postLimitedTimeNotificationAsOwner: (
      data: {
        title: string;
        message: string;
        start_time: string;
        end_time: string;
        add_roles: SelectRoleByLimitedTimeNotification[];
        add_company_ids?: string[];
        add_user_tag_ids?: string[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/limited_time_notifications`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 LimitedTimeNotification の詳細を返却
     *
     * @tags Owner/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationAsOwner
     * @summary 期間限定のお知らせ の詳細を返却
     * @request GET:/owner/limited_time_notifications/{id}
     * @response `200` `LimitedTimeNotificationDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationAsOwner: (id: string, params?: RequestParams) =>
      this.request<LimitedTimeNotificationDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/limited_time_notifications/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 LimitedTimeNotification を更新
     *
     * @tags Owner/LimitedTimeNotifications
     * @name PutLimitedTimeNotificationAsOwner
     * @summary 期間限定のお知らせ を更新
     * @request PUT:/owner/limited_time_notifications/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putLimitedTimeNotificationAsOwner: (
      id: string,
      data: {
        title: string;
        message: string;
        start_time: string;
        end_time: string;
        add_roles: SelectRoleByLimitedTimeNotification[];
        add_company_ids?: string[];
        add_user_tag_ids?: string[];
        remove_role_ids?: string[];
        remove_company_ids?: string[];
        remove_user_tag_ids?: string[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/limited_time_notifications/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 LimitedTimeNotification を削除
     *
     * @tags Owner/LimitedTimeNotifications
     * @name DeleteLimitedTimeNotificationAsOwner
     * @summary 期間限定のお知らせ を削除
     * @request DELETE:/owner/limited_time_notifications/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteLimitedTimeNotificationAsOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/limited_time_notifications/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 LimitedTimeNotification を一覧を返却 - 現在時刻がstart_timeとend_timeの範囲内のものを取得 - 指定された条件（Role, Company）にマッチしたものを取得
     *
     * @tags Owner/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationNoticeAsOwner
     * @summary 配信された期間限定のお知らせの一覧を返却
     * @request GET:/owner/limited_time_notifications/notice
     * @response `200` `{ limited_time_notifications: (LimitedTimeNotificationNoticeBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationNoticeAsOwner: (
      query: GetLimitedTimeNotificationNoticeAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { limited_time_notifications: LimitedTimeNotificationNoticeBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/limited_time_notifications/notice${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 LimitedTimeNotification の詳細を返却 - 現在時刻がstart_timeとend_timeの範囲内のものを取得 - 指定された条件（Role, Company）にマッチしたものを取得
     *
     * @tags Owner/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationNoticeDetailsAsOwner
     * @summary 配信された期間限定のお知らせ の詳細を返却
     * @request GET:/owner/limited_time_notifications/{id}/notice_details
     * @response `200` `LimitedTimeNotificationNoticeBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationNoticeDetailsAsOwner: (id: string, params?: RequestParams) =>
      this.request<LimitedTimeNotificationNoticeBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/limited_time_notifications/${id}/notice_details`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 このお知らせを確認したかを保存する - Newアイコンを外すため
     *
     * @tags Owner/LimitedTimeNotifications
     * @name PostLimitedTimeNotificationNoticeCheckAsOwner
     * @summary 期間限定のお知らせをチェックしたフラグを保存する
     * @request POST:/owner/limited_time_notifications/{id}/notice_check
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postLimitedTimeNotificationNoticeCheckAsOwner: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/limited_time_notifications/${id}/notice_check`,
        "POST",
        params,
      ),

    /**
     * @description - Owner限定 TtCsvExport を一覧を返却
     *
     * @tags Owner/TtCsvExports
     * @name GetTtCsvExportsAsOwner
     * @summary CSV一覧を返却
     * @request GET:/owner/tt_csv/exports
     * @response `200` `{ tt_csv_exports: (TtCsvExportBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtCsvExportsAsOwner: (query: GetTtCsvExportsAsOwnerParams, params?: RequestParams) =>
      this.request<
        { tt_csv_exports: TtCsvExportBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/tt_csv/exports${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 TtCsvExport を登録 - type: owner can only use starting with owner
     *
     * @tags Owner/TtCsvExports
     * @name PostTtCsvExportAsOwner
     * @summary CSV出力 をリクエスト
     * @request POST:/owner/tt_csv/exports
     * @response `200` `TtCsvExportBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTtCsvExportAsOwner: (
      query: PostTtCsvExportAsOwnerParams,
      data: { type: TtCsvExportType },
      params?: RequestParams,
    ) =>
      this.request<TtCsvExportBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/owner/tt_csv/exports${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 TtCategoryBase を登録
     *
     * @tags Owner/TtCategoryBases
     * @name PostTtCategoryBaseAsOwner
     * @request POST:/owner/tt_category/bases
     * @response `201` `TtCategoryBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTtCategoryBaseAsOwner: (data: CreateTtCategoryRequestBody, params?: RequestParams) =>
      this.request<TtCategoryBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/owner/tt_category/bases`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 TtCategoryBase を更新
     *
     * @tags Owner/TtCategoryBases
     * @name PutTtCategoryBaseAsOwner
     * @request PUT:/owner/tt_category/bases/{id}
     * @response `202` `TtCategoryBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTtCategoryBaseAsOwner: (id: string, data: UpdateTtCategoryRequestBody, params?: RequestParams) =>
      this.request<TtCategoryBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/owner/tt_category/bases/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 DistributionStatus を一覧を返却
     *
     * @tags Owner/DistributionStatus
     * @name GetDistributionStatusesAsOwner
     * @summary 配信履歴と進捗情報の一覧を返却
     * @request GET:/owner/distribution_statuses
     * @response `200` `{ distribution_statuses: (DistributionStatusList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getDistributionStatusesAsOwner: (query: GetDistributionStatusesAsOwnerParams, params?: RequestParams) =>
      this.request<
        { distribution_statuses: DistributionStatusList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/distribution_statuses${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 CommentImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Owner/CommentImages
     * @name DeleteCommentImageAsOwner
     * @summary 画像 を削除
     * @request DELETE:/owner/comment_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    deleteCommentImageAsOwner: ({ id, ...query }: DeleteCommentImageAsOwnerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest>(
        `/owner/comment_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 Interview::Request を一覧を返却
     *
     * @tags Owner/Interview/Request
     * @name GetInterviewRequestsAsOwner
     * @summary 高大連携リクエスト一覧を返却
     * @request GET:/owner/interview/requests
     * @response `200` `{ interview_requests: (InterviewRequestListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestsAsOwner: (query: GetInterviewRequestsAsOwnerParams, params?: RequestParams) =>
      this.request<
        { interview_requests: InterviewRequestListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/interview/requests${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 Interview::Request の詳細を返却
     *
     * @tags Owner/Interview/Request
     * @name GetInterviewRequestAsOwner
     * @summary 高大連携リクエストの詳細を返却
     * @request GET:/owner/interview/requests/{id}
     * @response `200` `InterviewRequestDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestAsOwner: (id: string, params?: RequestParams) =>
      this.request<InterviewRequestDetailBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/interview/requests/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 Article の詳細を返却 - 高大連携リクエストされている探究成果のみ閲覧可能
     *
     * @tags Owner/Interview/Request
     * @name GetInterviewRequestArticleAsOwner
     * @summary 高大連携リクエストの探究成果詳細を返却
     * @request GET:/owner/interview/requests/{id}/article
     * @response `200` `ArticleDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestArticleAsOwner: (id: string, params?: RequestParams) =>
      this.request<ArticleDetailBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/interview/requests/${id}/article`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 高大連携リクエストの未読メッセージの件数を返却
     *
     * @tags Owner/Interview/Request
     * @name GetInterviewRequestUnreadCountsAsOwner
     * @summary 高大連携リクエストの未読メッセージの件数を返却
     * @request GET:/owner/interview/requests/unread_count
     * @response `200` `{ unread_counts: ((ModelBase & { count: number }))[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestUnreadCountsAsOwner: (
      query: GetInterviewRequestUnreadCountsAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { unread_counts: (ModelBase & { count: number })[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/interview/requests/unread_count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 Interview::Message を一覧を返却
     *
     * @tags Owner/Interview/Message
     * @name GetInterviewMessagesAsOwner
     * @summary 高大連携リクエストのメッセージ一覧を返却
     * @request GET:/owner/interview/requests/{request_id}/messages
     * @response `200` `{ interview_messages: (InterviewMessageListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewMessagesAsOwner: (
      { request_id, ...query }: GetInterviewMessagesAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { interview_messages: InterviewMessageListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/interview/requests/${request_id}/messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 Interview::Read を登録
     *
     * @tags Owner/Interview/Message
     * @name GetInterviewMessageReadAsOwner
     * @summary 高大連携リクエストの未読メッセージを既読メッセージに変更する
     * @request POST:/owner/interview/requests/{request_id}/messages/read
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewMessageReadAsOwner: (
      request_id: string,
      data: { unread_message_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/interview/requests/${request_id}/messages/read`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Interview::Feedback を一覧を返却
     *
     * @tags Owner/Interview/Feedback
     * @name GetInterviewFeedbacksAsOwner
     * @summary 高大連携リクエストのアンケート一覧を返却
     * @request GET:/owner/interview/requests/{request_id}/feedbacks
     * @response `200` `{ interview_feedbacks: (InterviewFeedbackBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewFeedbacksAsOwner: (
      { request_id, ...query }: GetInterviewFeedbacksAsOwnerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { interview_feedbacks: InterviewFeedbackBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/interview/requests/${request_id}/feedbacks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 大学先生に紐づいている企業プロジェクトの一覧を返却。
     *
     * @tags Owner/Sponsor/Projects
     * @name FetchSponsorProjectListAsOwner
     * @summary 大学先生に紐づいている企業プロジェクトの一覧を返却
     * @request GET:/owner/sponsor/projects/list
     * @response `200` `{ projects: (HasIdAndName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectListAsOwner: (query: FetchSponsorProjectListAsOwnerParams, params?: RequestParams) =>
      this.request<{ projects: HasIdAndName[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/owner/sponsor/projects/list${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Owner限定 GroupAffiliatePackage を一覧を返却 - distributedはhomework_template_idと一緒に使う。 - distributed: trueで既に提出物を配信済みのグループを取得 - distributed: falseで提出物を未配信のグループを取得
     *
     * @tags Owner/GroupAffiliatePackages
     * @name GetGroupAffiliatePackagesAsOwner
     * @summary 生徒のグループ分け の一覧を返却
     * @request GET:/owner/group_affiliate_packages
     * @response `200` `{ group_affiliate_packages: (GroupAffiliatePackageGroupOnlyList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getGroupAffiliatePackagesAsOwner: (query: GetGroupAffiliatePackagesAsOwnerParams, params?: RequestParams) =>
      this.request<
        { group_affiliate_packages: GroupAffiliatePackageGroupOnlyList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/group_affiliate_packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 GroupAffiliatePackage を登録
     *
     * @tags Owner/GroupAffiliatePackages
     * @name PostGroupAffiliatePackageAsOwner
     * @summary 生徒のグループ分け を登録
     * @request POST:/owner/group_affiliate_packages
     * @response `201` `GroupAffiliatePackageBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postGroupAffiliatePackageAsOwner: (data: PostGroupAffiliatePackage, params?: RequestParams) =>
      this.request<
        GroupAffiliatePackageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/group_affiliate_packages`, "POST", params, data),

    /**
     * @description - Owner限定 GroupAffiliatePackage の詳細を返却
     *
     * @tags Owner/GroupAffiliatePackages
     * @name GetGroupAffiliatePackageAsOwner
     * @summary 生徒のグループ分け の詳細を返却
     * @request GET:/owner/group_affiliate_packages/{id}
     * @response `200` `GroupAffiliatePackageBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getGroupAffiliatePackageAsOwner: (id: string, params?: RequestParams) =>
      this.request<
        GroupAffiliatePackageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/group_affiliate_packages/${id}`, "GET", params),

    /**
     * @description - Owner限定 GroupAffiliatePackage を更新
     *
     * @tags Owner/GroupAffiliatePackages
     * @name PutGroupAffiliatePackageAsOwner
     * @summary 生徒のグループ分け を更新
     * @request PUT:/owner/group_affiliate_packages/{id}
     * @response `202` `GroupAffiliatePackageBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putGroupAffiliatePackageAsOwner: (id: string, data: PutGroupAffiliatePackage, params?: RequestParams) =>
      this.request<
        GroupAffiliatePackageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/group_affiliate_packages/${id}`, "PUT", params, data),

    /**
     * @description - Owner限定 GroupAffiliatePackage を削除
     *
     * @tags Owner/GroupAffiliatePackages
     * @name DeleteGroupAffiliatePackageAsOwner
     * @summary 生徒のグループ分け を削除
     * @request DELETE:/owner/group_affiliate_packages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteGroupAffiliatePackageAsOwner: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/group_affiliate_packages/${id}`, "DELETE", params),

    /**
     * @description - Owner限定 Packaging を登録する - e.g. 生徒グループ分け機能（GroupAffiliatePackage）にAffiliateを登録する
     *
     * @tags Owner/Packaging
     * @name PostPackagingAsOwner
     * @summary パッケージにコンテンツ を登録
     * @request POST:/owner/packagings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPackagingAsOwner: (
      data: { package_id: string; packageable_type: PackagingPackageableType; packageable_id: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/packagings`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Packagingのsequence を更新する - パッケージのコンテンツを並び替える
     *
     * @tags Owner/Packaging
     * @name PutPackagingAsOwner
     * @summary パッケージにコンテンツの並び替えの順番 を更新
     * @request PUT:/owner/packagings
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPackagingAsOwner: (query: PutPackagingAsOwnerParams, data: { sequence: number }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/packagings${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Packaging を削除する - 論理削除される
     *
     * @tags Owner/Packaging
     * @name DeletePackagingAsOwner
     * @summary パッケージからコンテンツ を削除
     * @request DELETE:/owner/packagings
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePackagingAsOwner: (query: DeletePackagingAsOwnerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/packagings${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Owner限定 Packaging を一括登録する - e.g. 生徒グループ分け機能（GroupAffiliatePackage）にAffiliateを登録する
     *
     * @tags Owner/Packaging
     * @name BatchCreatePackagingAsOwner
     * @summary パッケージにコンテンツ を一括登録
     * @request PATCH:/owner/packagings/batch_create
     * @response `201` `{ message?: string }` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    batchCreatePackagingAsOwner: (
      data: { package_id: string; packageable_type: PackagingPackageableType; packageable_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/packagings/batch_create`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Packagingのsequence を一括更新する - パッケージのコンテンツを並び替える
     *
     * @tags Owner/Packaging
     * @name BatchUpdatePackagingAsOwner
     * @summary パッケージにコンテンツの並び替えの順番 を一括更新
     * @request PATCH:/owner/packagings/batch_update_sequence
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    batchUpdatePackagingAsOwner: (
      data: {
        package_id: string;
        packageable_type: PackagingPackageableType;
        packageable_ids: string[];
        sequence: { packageable_id: string; sequence: number }[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/packagings/batch_update_sequence`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description - Owner限定 Packaging を一括削除する - 論理削除される
     *
     * @tags Owner/Packaging
     * @name BatchDeletePackagingAsOwner
     * @summary パッケージからコンテンツ を一括削除
     * @request DELETE:/owner/packagings/batch_destroy
     * @response `200` `{ message?: string }` Ok
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    batchDeletePackagingAsOwner: (
      data: { package_id: string; packageable_type: string; packageable_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/packagings/batch_destroy`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description - Owner限定 提出物のカスタムフィールドの値の情報の更新 - group_affiliate_package_id: グループ向けの提出物に関連する場合必須。
     *
     * @tags Owner/HomeworkTemplateCustomValues
     * @name PutHomeworkTemplateCustomValueAsOwner
     * @summary Owner: 提出物のカスタムフィールドの値の情報更新
     * @request PUT:/owner/homework_template_custom_values/{id}
     * @response `202` `{ homework_template_custom_fields: (HomeworkTemplateCustomFieldAndValue)[] }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putHomeworkTemplateCustomValueAsOwner: (
      id: string,
      data: CustomValueRequestBodyAsOwnerAndTeacherAndReviewer,
      params?: RequestParams,
    ) =>
      this.request<
        { homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[] },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/homework_template_custom_values/${id}`, "PUT", params, data),

    /**
     * @description - Owner限定 Common::Tag の一覧を返却
     *
     * @tags Owner/CommonTags
     * @name GetCommonTagsAsOwner
     * @summary システムタグ・フリータグの一覧を返却
     * @request GET:/owner/common/tags
     * @response `200` `{ common_tags: (CommonTagBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCommonTagsAsOwner: (query: GetCommonTagsAsOwnerParams, params?: RequestParams) =>
      this.request<
        { common_tags: CommonTagBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/owner/common/tags${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Owner限定 CommonTagging を登録 - 許可されたModelのみ登録可能
     *
     * @tags Owner/CommonTaggings
     * @name PostCommonTaggingAsOwner
     * @summary フリータグを登録
     * @request POST:/owner/common/taggings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postCommonTaggingAsOwner: (
      query: PostCommonTaggingAsOwnerParams,
      data: CommonTaggingRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/owner/common/taggings${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description - Owner限定 CommonTagging を削除
     *
     * @tags Owner/CommonTaggings
     * @name DeleteCommonTaggingAsOwner
     * @summary フリータグ を解除
     * @request DELETE:/owner/common/taggings
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteCommonTaggingAsOwner: (query: DeleteCommonTaggingAsOwnerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/owner/common/taggings${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),
  };
  teacher = {
    /**
     * @description 常にmin trueの情報を返却する。
     *
     * @tags Teacher/User
     * @name FetchStudentsAsTeacher
     * @summary [先生] 生徒のリストを取得。ユーザタグは先生が参照可能なもののみ取得
     * @request GET:/teacher/users/students
     * @response `200` `{ students: (StudentListAsTeacher)[], student_input_settings: StudentInputSetting, total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchStudentsAsTeacher: (query: FetchStudentsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { students: StudentListAsTeacher[]; student_input_settings: StudentInputSetting; total_count: number },
        Unauthorized | Forbidden
      >(`/teacher/users/students${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 生徒を作成 - 生徒を登録権限を持っている先生のみ有効
     *
     * @tags Teacher/User
     * @name PostTeacherStudents
     * @summary 生徒を作成
     * @request POST:/teacher/users/students
     * @response `201` `{ id?: string }` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    postTeacherStudents: (
      data: {
        email: string;
        role: string;
        first_name?: string;
        last_name?: string;
        optional_email?: string;
        student_info_attributes?: {
          position?: string;
          department?: string;
          description?: string;
          limit_request?: number;
          profile_picture?: File;
        };
        invitation_scheduled_at?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<{ id?: string }, Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/teacher/users/students`,
        "POST",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description - Teacher限定 複数の生徒を作成 - 生徒を登録権限を持っている先生のみ有効
     *
     * @tags Teacher/User
     * @name StudentsBatchCreateUsersAsTeacher
     * @summary 複数の生徒を作成
     * @request PATCH:/teacher/users/students/batch_create
     * @response `200` `{ errors?: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    studentsBatchCreateUsersAsTeacher: (
      data: {
        slice_start?: number;
        user_params_list?: {
          email?: string;
          user_type?: UserType;
          first_name?: string;
          last_name?: string;
          birth_date?: string;
          grade?: number;
          parent_last_name?: string;
          parent_first_name?: string;
          postcode?: string;
          prefecture?: string;
          address1?: string;
          address2?: string;
          phone?: string;
          number?: number;
          optional_email?: string;
          user_tag_ids?: string[] | null;
          group_ids?: string[] | null;
        }[];
        invitation_scheduled_at?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<
        { errors?: string[] },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/users/students/batch_create`, "PATCH", params, data),

    /**
     * @description 学年ごとの生徒の数を返却する
     *
     * @tags Teacher/Student
     * @name StudentListGradeAsTeacher
     * @summary 学年ごとの生徒の数を返却する
     * @request GET:/teacher/users/students/list_grade
     * @response `200` `{ number_of_students_per_grade: ({ grade: StudentGradeJa, count: number })[], students_total_count: number }` OK
     */
    studentListGradeAsTeacher: (query: StudentListGradeAsTeacherParams, params?: RequestParams) =>
      this.request<
        { number_of_students_per_grade: { grade: StudentGradeJa; count: number }[]; students_total_count: number },
        any
      >(`/teacher/users/students/list_grade${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 アカウントID使用の生徒を作成 - 生徒を登録権限を持っている先生のみ有効
     *
     * @tags Teacher/User
     * @name CreateAccountUserAsTeacher
     * @summary アカウントID使用の生徒を作成
     * @request POST:/teacher/users/students/create_account_user
     * @response `201` `CreateAccountUserResponse` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createAccountUserAsTeacher: (data: CreateAccountUserRequestBodyAsTeacher, params?: RequestParams) =>
      this.request<
        CreateAccountUserResponse,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/users/students/create_account_user`, "POST", params, data),

    /**
     * @description Teacher限定:アカウント停止を利用中に変更する
     *
     * @tags Teacher/User
     * @name CreateAccountAvailableAsTeacher
     * @summary アカウント停止のユーザを利用中に変更
     * @request PUT:/teacher/users/students/account_available
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createAccountAvailableAsTeacher: (data: { affiliate_id: string }, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/users/students/account_available`, "PUT", params, data),

    /**
     * @description 生徒のAffiliateのリストを取得。
     *
     * @tags Teacher/User
     * @name UsersStudentAffiliatesList
     * @summary [先生] 生徒のAffiliateのリストを取得。
     * @request GET:/teacher/users/student_affiliates
     * @response `200` `{ students?: (AffiliateName)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    usersStudentAffiliatesList: (query: UsersStudentAffiliatesListParams, params?: RequestParams) =>
      this.request<{ students?: AffiliateName[]; total_count?: number }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/users/student_affiliates${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - "Studentが紐付いているユーザータグの情報を返却"
     *
     * @tags Teacher/Students
     * @name GetStudentsUserTagsAsTeacher
     * @summary Studentが紐付いているユーザータグの情報を返却
     * @request GET:/teacher/users/students/{id}/user_tags
     * @response `200` `{ user_tag_types: ((UserTagTypeBasic & { user_tags: (UserTagBasic)[] }))[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentsUserTagsAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        { user_tag_types: (UserTagTypeBasic & { user_tags: UserTagBasic[] })[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/users/students/${id}/user_tags`, "GET", params),

    /**
     * @description - "Studentが紐付いているグループの情報を返却"
     *
     * @tags Teacher/Students
     * @name GetStudentsGroupAffiliatesAsTeacher
     * @summary Studentが紐付いているグループの情報を返却
     * @request GET:/teacher/users/students/{id}/group_affiliates
     * @response `200` `{ group_affiliate_packages?: (GroupAffiliatePackageMinimalBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentsGroupAffiliatesAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        { group_affiliate_packages?: GroupAffiliatePackageMinimalBase[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/users/students/${id}/group_affiliates`, "GET", params),

    /**
     * @description 教職員専用API。 ただし、取得できるのは対象ユーザのCompanyの生徒のみ。
     *
     * @tags Users
     * @name GetTeacherStudentsUserId
     * @summary 教職員が生徒情報を取得
     * @request GET:/teacher/users/students/{id}
     * @response `200` `UserDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherStudentsUserId: (id: string, params?: RequestParams) =>
      this.request<UserDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/users/students/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 生徒情報を更新 - 生徒を登録権限を持っている先生: number 以外更新可能 - 生徒を登録権限を持っていない先生: number のみ更新可能
     *
     * @tags Users, Teacher/User
     * @name PutTeacherStudentsUserId
     * @summary 教職員が生徒情報を更新
     * @request PUT:/teacher/users/students/{id}
     * @response `200` `UserDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putTeacherStudentsUserId: (
      id: string,
      data: {
        last_name?: string;
        first_name?: string;
        optional_email?: string;
        user_type?: UserAccountType & any;
        new_id?: string;
        student_info_attributes?: StudentInfoAttributes;
        user_tag_ids?: string[] | null;
        group_ids?: string[] | null;
      },
      params?: RequestParams,
    ) =>
      this.request<UserDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/users/students/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 生徒を削除 - 生徒を登録権限を持っている先生のみ実行可能
     *
     * @tags Users, Teacher/User
     * @name DeleteTeacherStudentsUserId
     * @summary 生徒を削除
     * @request DELETE:/teacher/users/students/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteTeacherStudentsUserId: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/users/students/${id}`, "DELETE", params),

    /**
     * @description - Teacher限定 Teacher一覧を返却 - 教員が属する企業に属している教員一覧を返却
     *
     * @tags Users
     * @name GetTeachersAsTeacher
     * @summary 先生一覧を返却
     * @request GET:/teacher/users/teachers
     * @response `200` `{ teachers: ((TeacherListForTeacher | HasAffiliateIdAndName))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeachersAsTeacher: (query: GetTeachersAsTeacherParams, params?: RequestParams) =>
      this.request<
        { teachers: (TeacherListForTeacher | HasAffiliateIdAndName)[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/users/teachers${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 先生を削除 - 生徒を登録権限を持っている先生のみ実行可能
     *
     * @tags Users, Teacher/User
     * @name DeleteTeacherTeachersUserId
     * @summary 先生を削除
     * @request DELETE:/teacher/users/teachers/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteTeacherTeachersUserId: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/users/teachers/${id}`, "DELETE", params),

    /**
     * @description - Teacher限定 progress を更新 - unapproved から available に変更する
     *
     * @tags Users
     * @name PutTeachersProgressUpdateAsTeacher
     * @summary 未承認のステータスを承認に更新
     * @request PUT:/teacher/users/teachers/progress_update/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putTeachersProgressUpdateAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/users/teachers/progress_update/${id}`,
        "PUT",
        params,
      ),

    /**
     * @description - Teacher限定 大学教職員一覧を返却 - 企業プロジェクトに紐づいている大学教職員一覧を返却
     *
     * @tags Users
     * @name GetTeacherUniversitiesAsTeacher
     * @summary 大学教職員一覧を返却
     * @request GET:/teacher/users/teachers/university
     * @response `200` `{ university_teachers: (TeacherListForUniversityTeacher)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherUniversitiesAsTeacher: (query: GetTeacherUniversitiesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { university_teachers: TeacherListForUniversityTeacher[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/users/teachers/university${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 教員用API 生徒の詳細情報を確認するのに必要
     *
     * @tags Teacher/User
     * @name GetTeacherUsersUserId
     * @summary 教員が生徒or自分自身の詳細を取得する。
     * @request GET:/teacher/users/{user_id}
     * @response `200` `UserDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherUsersUserId: (user_id: string, params?: RequestParams) =>
      this.request<UserDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/users/${user_id}`,
        "GET",
        params,
      ),

    /**
     * @description Teachers can update their own account info
     *
     * @tags Teacher/Users
     * @name UpdateCurrentUserAsTeacher
     * @summary Teacher to update own account
     * @request PUT:/teacher/users/{user_id}
     * @response `200` `CurrentUserInfo` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    updateCurrentUserAsTeacher: (
      user_id: string,
      data: {
        first_name?: string;
        last_name?: string;
        nickname?: string;
        teacher_info_attributes?: TeacherInfoAttributes;
      },
      params?: RequestParams,
    ) =>
      this.request<CurrentUserInfo, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/users/${user_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 先生が属する学校に登録している期限切れ、ロック中の生徒を削除
     *
     * @tags Users
     * @name DeleteUsersUserIdAsTeacher
     * @summary 先生権限 ユーザの削除
     * @request DELETE:/teacher/users/{user_id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteUsersUserIdAsTeacher: (user_id: string, data: { role: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/users/${user_id}`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description (アカウントID使用)教職員が自社の管理者or教職員or生徒のパスワードを変更
     *
     * @tags Teacher/User
     * @name ResetPasswordAsTeacher
     * @summary (アカウントID使用)教職員が自社の管理者or教職員or生徒のパスワードを変更
     * @request PUT:/teacher/users/{id}/reset_password
     * @response `200` `AccountUserInfo` ok
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    resetPasswordAsTeacher: (id: string, data: AuthorizationPasswordRequestBody, params?: RequestParams) =>
      this.request<AccountUserInfo, BadRequest | { error?: string }>(
        `/teacher/users/${id}/reset_password`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description (UserID使用)アカウントロックを解除
     *
     * @tags Teacher/User
     * @name AccountUnlockAsTeacher
     * @summary (UserID使用)アカウントロックを解除
     * @request PUT:/teacher/users/bulk_unlock_access
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    accountUnlockAsTeacher: (data: { user_ids: string[] }, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/users/bulk_unlock_access`, "PUT", params, data),

    /**
     * @description Return material list based on Category Id
     *
     * @tags Materials
     * @name FetchMaterialsAsTeacher
     * @summary For Teacher: Material list based on category ID
     * @request GET:/teacher/materials
     * @response `200` `{ materials: (MaterialList)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchMaterialsAsTeacher: (query: FetchMaterialsAsTeacherParams, params?: RequestParams) =>
      this.request<{ materials: MaterialList[]; total_count: number }, Unauthorized | Forbidden | { error?: string }>(
        `/teacher/materials${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 自分に配信されている教材、および担当生徒に配信されている教材の詳細を取得する。
     *
     * @tags materials
     * @name GetTeacherMaterialsMaterialId
     * @summary 先生が教材詳細を取得
     * @request GET:/teacher/materials/{material_id}
     * @response `200` `MaterialDetail` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherMaterialsMaterialId: (material_id: string, params?: RequestParams) =>
      this.request<MaterialDetail, Unauthorized | Forbidden>(`/teacher/materials/${material_id}`, "GET", params),

    /**
     * @description 自身もしくは、担当の生徒に配信されているMaterial一覧及そのStatus（未受講、受講済）を取得する。
     *
     * @tags MaterialWorks
     * @name GetTeacherMaterialWorks
     * @summary 先生が教材の進捗一覧を取得
     * @request GET:/teacher/material_works
     * @response `200` `{ material_works: (TeacherMaterialWork)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherMaterialWorks: (query: GetTeacherMaterialWorksParams, params?: RequestParams) =>
      this.request<
        { material_works: TeacherMaterialWork[]; total_count: number },
        Unauthorized | Forbidden | { error?: string }
      >(`/teacher/material_works${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher to create a material work for student by specifying user and material. The teacher can only add new material work if the student is assigned to the teacher.
     *
     * @tags MaterialWorks
     * @name CreateMaterialWorkAsTeacher
     * @summary [For Teacher] Teacher to create a material work for student
     * @request POST:/teacher/material_works
     * @response `201` `TeacherMaterialWork` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createMaterialWorkAsTeacher: (
      data: { user_id: string; material_id: string; forbid_download?: boolean; role: string },
      params?: RequestParams,
    ) =>
      this.request<TeacherMaterialWork, Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/material_works`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 自身もしくは、担当生徒の配信されているMaterialのStatusを取得。
     *
     * @tags MaterialWorks
     * @name FetchMaterialWorkAsTeacher
     * @summary 先生が教材進捗情報を取得
     * @request GET:/teacher/material_works/{material_work_id}
     * @response `200` `TeacherMaterialWork` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchMaterialWorkAsTeacher: (material_work_id: string, params?: RequestParams) =>
      this.request<TeacherMaterialWork, Unauthorized | Forbidden | { error?: string }>(
        `/teacher/material_works/${material_work_id}`,
        "GET",
        params,
      ),

    /**
     * @description 自分に配信された教材について、受講ステータス（未受講、受講済み）のみ更新が可能。
     *
     * @tags MaterialWorks
     * @name UpdateMaterialWorkAsTeacher
     * @summary 先生が教材の進捗情報を更新
     * @request PUT:/teacher/material_works/{material_work_id}
     * @response `200` `TeacherMaterialWork` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    updateMaterialWorkAsTeacher: (
      material_work_id: string,
      data: { material_works_id?: string; status?: MaterialWorkStatus; forbid_download?: boolean },
      params?: RequestParams,
    ) =>
      this.request<TeacherMaterialWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/material_works/${material_work_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Tag 一覧を返却 - 重複しないタグ名を返す
     *
     * @tags Teacher/Material/Tag
     * @name GetMaterialTagsAsTeacher
     * @summary 教材のタグ一覧を返却
     * @request GET:/teacher/material/tags
     * @response `200` `{ material_tags: (HasName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getMaterialTagsAsTeacher: (query: GetMaterialTagsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { material_tags: HasName[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/material/tags${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 教員が自身の持つ生徒のmaterial_worksの一覧を取得
     *
     * @tags Users
     * @name FetchStudentMaterialWorksAsTeacher
     * @summary Your GET endpoint
     * @request GET:/teacher/student_material_works
     * @response `200` `(MaterialWork)[]` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchStudentMaterialWorksAsTeacher: (query: FetchStudentMaterialWorksAsTeacherParams, params?: RequestParams) =>
      this.request<MaterialWork[], Unauthorized | Forbidden>(
        `/teacher/student_material_works${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 教員が自身の持つ生徒のmaterial_workの詳細を取得
     *
     * @tags Users, MaterialWorks
     * @name FetchOneStudentMaterialWorkAsTeacher
     * @summary Your GET endpoint
     * @request GET:/teacher/student_material_works/{material_work_id}
     * @response `200` `TeacherMaterialWork` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchOneStudentMaterialWorkAsTeacher: (
      { material_work_id, ...query }: FetchOneStudentMaterialWorkAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<TeacherMaterialWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/student_material_works/${material_work_id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 自身の提出した指導レポートの一覧を取得する
     *
     * @tags Report
     * @name FetchReportsAsTeacher
     * @summary 指導レポート一覧取得
     * @request GET:/teacher/reports
     * @response `200` `{ reports: (({ message_to_owner?: string, message_to_student?: string } & Report))[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchReportsAsTeacher: (query: FetchReportsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { reports: ({ message_to_owner?: string; message_to_student?: string } & Report)[]; total_count: number },
        Unauthorized | Forbidden
      >(`/teacher/reports${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 先生が管理者向けに指導レポートを作成する
     *
     * @tags Teacher/Report
     * @name PostTeacherReports
     * @summary [Teacher] Create a new Report entry
     * @request POST:/teacher/reports
     * @response `201` `(Report & { message_to_owner: string, message_to_student: string })` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postTeacherReports: (
      data: {
        title?: string;
        detail?: string;
        student_id?: string;
        next_goal?: string;
        monthly_teaching_times?: number;
        monthly_student_schedule_change?: number;
        monthly_teacher_schedule_change?: number;
        homework_frequency?: number;
        is_schedule_change_required?: boolean;
        schedule_detail_reason?: string;
        test_misc?: string;
        message_to_owner?: string;
        message_to_student?: string;
        text_subjects?: string[];
        schedule_change_period?: string;
        is_schedule_change_notified?: boolean;
        substitute_lecture?: boolean;
        test_type?: ReportTestType;
        reports_test_results?: {
          subject?: string;
          current_score?: number;
          positive_negative?: boolean;
          score_delta?: number;
        }[];
        no_text_reason?: string;
        no_homework_reason?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<Report & { message_to_owner: string; message_to_student: string }, Unauthorized | Forbidden>(
        `/teacher/reports`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 自身の提出した指導レポートの詳細情報を取得する
     *
     * @tags Report
     * @name GetTeacherReportsReportId
     * @summary 指導レポート詳細を取得
     * @request GET:/teacher/reports/{report_id}
     * @response `200` `(Report & { message_to_owner: string, message_to_student: string })` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherReportsReportId: (report_id: string, params?: RequestParams) =>
      this.request<
        Report & { message_to_owner: string; message_to_student: string },
        Unauthorized | Forbidden | { error?: string }
      >(`/teacher/reports/${report_id}`, "GET", params),

    /**
     * @description 自身が提出した指導レポートのタイトル、本文の内容を更新できる。
     *
     * @tags Report
     * @name PutTeacherReportsReportId
     * @summary 指導レポートの更新
     * @request PUT:/teacher/reports/{report_id}
     * @response `200` `(Report & { message_to_owner: string, message_to_student: string })` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putTeacherReportsReportId: (
      report_id: string,
      data: { title?: string; detail?: string; status?: ReportStatus },
      params?: RequestParams,
    ) =>
      this.request<
        Report & { message_to_owner: string; message_to_student: string },
        { message?: string } | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/reports/${report_id}`, "PUT", params, data),

    /**
     * @description 自身の提出した指導レポートを削除する
     *
     * @tags Report
     * @name DeleteTeacherReportsReportId
     * @summary 指導レポートの削除
     * @request DELETE:/teacher/reports/{report_id}
     * @response `204` `any` No Content
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteTeacherReportsReportId: (report_id: string, params?: RequestParams) =>
      this.request<any, Unauthorized | Forbidden | { error?: string }>(
        `/teacher/reports/${report_id}`,
        "DELETE",
        params,
      ),

    /**
     * @description 自分が抱えている生徒分の支払い一覧を取得
     *
     * @tags Payment
     * @name FetchPaymentsAsTeacher
     * @summary 教員が支払いの一覧を取得
     * @request GET:/teacher/payments
     * @response `200` `{ payments: (Payment)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    fetchPaymentsAsTeacher: (query: FetchPaymentsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { payments: Payment[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/payments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 教員が新しく支払履歴を作成
     *
     * @tags Payment
     * @name PostTeacherPayments
     * @summary 教員が新しい支払い履歴を作成
     * @request POST:/teacher/payments
     * @response `201` `Payment` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTeacherPayments: (
      data: { student_id?: string; title?: string; price?: number; year?: number; month?: number },
      params?: RequestParams,
    ) =>
      this.request<Payment, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/payments`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 教員向けの支払い履歴の詳細確認を行う
     *
     * @tags Payment
     * @name GetTeacherPaymentsPaymentId
     * @summary 教員が支払いの詳細を取得
     * @request GET:/teacher/payments/{payment_id}
     * @response `200` `Payment` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getTeacherPaymentsPaymentId: (payment_id: string, params?: RequestParams) =>
      this.request<Payment, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/payments/${payment_id}`,
        "GET",
        params,
      ),

    /**
     * @description 教員が自分の所有している支払い状況のうち、一つをアップデート
     *
     * @tags Payment
     * @name UpdatePaymentAsTeacher
     * @summary 教員が支払状況のステータスを更新
     * @request PUT:/teacher/payments/{payment_id}
     * @response `200` `Payment` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updatePaymentAsTeacher: (payment_id: string, data: { status?: PaymentStatus }, params?: RequestParams) =>
      this.request<
        Payment,
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/payments/${payment_id}`, "PUT", params, data),

    /**
     * @description Logical deletion of payment by teacher
     *
     * @tags Payment
     * @name DeleteTeacherPaymentsPaymentId
     * @summary Teacher: logically delete payment
     * @request DELETE:/teacher/payments/{payment_id}
     * @response `202` `{ message: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    deleteTeacherPaymentsPaymentId: (payment_id: string, params?: RequestParams) =>
      this.request<
        { message: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/payments/${payment_id}`, "DELETE", params),

    /**
     * @description 教員が自身が作成した宿題の一覧を取得
     *
     * @tags Homework
     * @name FetchHomeworksAsTeacher
     * @summary 教員が宿題の一覧を取得 (検索キーワードが送信された場合、検索が可能)
     * @request GET:/teacher/homeworks
     * @response `200` `{ homeworks: (HomeworkBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchHomeworksAsTeacher: (query: FetchHomeworksAsTeacherParams, params?: RequestParams) =>
      this.request<{ homeworks: HomeworkBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/teacher/homeworks${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 教員が宿題の詳細を取得
     *
     * @tags Homework
     * @name GetTeacherHomeworksHomeworkId
     * @summary 教員が宿題の詳細を取得
     * @request GET:/teacher/homeworks/{homework_id}
     * @response `200` `HomeworkDetails` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherHomeworksHomeworkId: (homework_id: string, params?: RequestParams) =>
      this.request<HomeworkDetails, Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homeworks/${homework_id}`,
        "GET",
        params,
      ),

    /**
     * @description - 教員が宿題を更新するAPI - メール通知先：更新された提出物にアクションを取る必要がある人。 - メール内容：提出物が届いたこと。
     *
     * @tags Homework
     * @name PutTeacherHomeworksHomeworkId
     * @summary 教員が宿題を更新。同時にメール通知する。
     * @request PUT:/teacher/homeworks/{homework_id}
     * @response `200` `HomeworkDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTeacherHomeworksHomeworkId: (
      homework_id: string,
      data: {
        status?: HomeworkStatus;
        teacher_comment?: string;
        teacher_comment_for_reviewer?: string;
        score?: number;
        ideal_thumbnail_file?: File;
        comment_images?: File[];
      },
      params?: RequestParams,
    ) =>
      this.request<HomeworkDetails, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/homeworks/${homework_id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description 教員が宿題を削除する
     *
     * @tags Homework
     * @name DeleteTeacherHomeworksHomeworkId
     * @summary 教員が宿題を削除
     * @request DELETE:/teacher/homeworks/{homework_id}
     * @response `204` `any` No Content
     * @response `404` `{ error?: string }`
     */
    deleteTeacherHomeworksHomeworkId: (homework_id: string, params?: RequestParams) =>
      this.request<any, { error?: string }>(`/teacher/homeworks/${homework_id}`, "DELETE", params),

    /**
     * @description 教員が宿題の添削画像ダウンロード用URL一覧を取得
     *
     * @tags Homework
     * @name GetHomeworkImageDownloadUrlsAsTeacher
     * @summary 教員が宿題の詳細を取得
     * @request GET:/teacher/homeworks/get_download_urls
     * @response `200` `{ homeworks: (HomeworkDownloadUrl)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getHomeworkImageDownloadUrlsAsTeacher: (
      query: GetHomeworkImageDownloadUrlsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<{ homeworks: HomeworkDownloadUrl[] }, Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homeworks/get_download_urls${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - メール通知先：提出物を配布された生徒。 - メール内容：提出物の課題が届いたこと。
     *
     * @tags Teacher/Homework, Homework
     * @name BatchCreateHomeworksAsTeacher
     * @summary Homeworkを複数作成する。同時にメール通知する。
     * @request PATCH:/teacher/homeworks/batch_create
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    batchCreateHomeworksAsTeacher: (
      data: { homework_template_id?: string; create_params_list?: { student_id: string }[] },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/homeworks/batch_create`, "PATCH", params, data),

    /**
     * @description - メール通知先：提出物を配布された生徒。 - メール内容：提出物の課題が届いたこと。
     *
     * @tags Teacher/Homework, Homework
     * @name BatchCreateGroupHomeworksAsTeacher
     * @summary Homeworkを複数作成する。同時にメール通知する。
     * @request PATCH:/teacher/group_homeworks/batch_create
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    batchCreateGroupHomeworksAsTeacher: (
      data: { homework_template_id: string; group_affiliate_package_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/group_homeworks/batch_create`, "PATCH", params, data),

    /**
     * @description - メール通知先：更新された提出物にアクションを取る必要がある人。 - メール内容：提出物が届いたこと。
     *
     * @tags Teacher/Homework, Homework
     * @name BatchUpdateHomeworksAsTeacher
     * @summary Homeworkのステータスを一括更新する。同時にメール通知する。
     * @request PATCH:/teacher/homeworks/batch_update
     * @response `200` `{ homeworks: (HomeworkBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    batchUpdateHomeworksAsTeacher: (data: BatchUpdateHomeworkRequest, params?: RequestParams) =>
      this.request<{ homeworks: HomeworkBase[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homeworks/batch_update`,
        "PATCH",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/Homework, Homework
     * @name SubmitHomeworkAnswerFilesAsTeacher
     * @summary 先生が生徒から回収した提出物を一斉登録する
     * @request PATCH:/teacher/homeworks/submit_answer_files
     * @response `200` `{ homeworks: (HomeworkBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    submitHomeworkAnswerFilesAsTeacher: (
      data: { file_params_list: { homework_id: string; file_data: string }[] },
      params?: RequestParams,
    ) =>
      this.request<{ homeworks: HomeworkBase[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homeworks/submit_answer_files`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description 教員が自身が作成した宿題ファイル一括登録依頼を取得する ステータス毎にソートしたほうが良いかも？
     *
     * @tags Homework, SubmitHomeworkFilesRequest
     * @name FetchSubmitHomeworkFilesRequestsAsTeacher
     * @summary 教員が宿題のQRコード付き回答ファイルの処理依頼を行う
     * @request GET:/teacher/submit_homework_files_requests
     * @response `200` `{ requests: (SubmitHomeworkFilesRequest)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSubmitHomeworkFilesRequestsAsTeacher: (
      query: FetchSubmitHomeworkFilesRequestsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<{ requests: SubmitHomeworkFilesRequest[]; total_count: number }, Unauthorized | Forbidden>(
        `/teacher/submit_homework_files_requests${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Homework, SubmitHomeworkFilesRequest
     * @name CreateSubmitHomeworkFilesRequestAsTeacher
     * @summary 教員が宿題ファイル一括登録リクエストを作成
     * @request POST:/teacher/submit_homework_files_requests
     * @response `201` `SubmitHomeworkFilesRequest` Created
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createSubmitHomeworkFilesRequestAsTeacher: (data: { files?: File[] }, params?: RequestParams) =>
      this.request<
        SubmitHomeworkFilesRequest,
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/submit_homework_files_requests`, "POST", params, data),

    /**
     * No description
     *
     * @tags Homework, Owner, Owner/Homework
     * @name BatchDeleteHomeworksAsTeacher
     * @summary 複数の宿題テンプレートの配信を削除する。
     * @request PATCH:/teacher/homeworks/batch_delete
     * @response `200` `{ deleted_ids: (number)[], errors?: (string)[] | null }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    batchDeleteHomeworksAsTeacher: (data: { homework_ids: string[] }, params?: RequestParams) =>
      this.request<
        { deleted_ids: number[]; errors?: string[] | null },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/homeworks/batch_delete`, "PATCH", params, data),

    /**
     * @description - メール通知先：更新された提出物にアクションを取る必要がある人。 - メール内容：提出物が届いたこと。 - ステータスが提出済み（submitted）のみ有効。それ以外はスキップ。 - パラメータの homework_ids と teacher_comment_list のサイズは同一であること
     *
     * @tags Teacher/Homework, Homework
     * @name BatchUpdateTeacherCommentsHomeworksAsTeacher
     * @summary HomeworkのコメントをCSV一括更新する。同時にメール通知する。
     * @request PATCH:/teacher/homeworks/batch_update_teacher_comments
     * @response `200` `{ homeworks: (HomeworkBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    batchUpdateTeacherCommentsHomeworksAsTeacher: (
      data: {
        homework_template_id: string;
        homework_ids: string[];
        teacher_comment_list: { csv_status: "NG" | "OK"; comment: string }[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ homeworks: HomeworkBase[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homeworks/batch_update_teacher_comments`,
        "PATCH",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name UploadHomeworkImagesAsTeacher
     * @request POST:/teacher/homeworks/{homework_id}/upload_images
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    uploadHomeworkImagesAsTeacher: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/homeworks/${homework_id}/upload_images`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name SaveEditedHomeworkImageAsTeacher
     * @request POST:/teacher/homeworks/{homework_id}/save_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    saveEditedHomeworkImageAsTeacher: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/homeworks/${homework_id}/save_image`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name UpdateHomeworkImageAsTeacher
     * @request PUT:/teacher/homeworks/{homework_id}/update_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    updateHomeworkImageAsTeacher: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/homeworks/${homework_id}/update_image`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name DeleteHomeworkImageAsTeacher
     * @request DELETE:/teacher/homeworks/{homework_id}/delete_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    deleteHomeworkImageAsTeacher: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/homeworks/${homework_id}/delete_image`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description - "Teacher限定 HomeworkTemplateCustomFieldFeedbackを登録"
     *
     * @tags Teacher/HomeworkTemplateCustomFieldFeedbacks
     * @name PostHomeworkTemplateCustomFieldFeedbackAsTeacher
     * @summary HomeworkTemplateCustomFieldFeedbackを登録
     * @request POST:/teacher/homework_template_custom_fields/{homework_template_custom_field_id}/feedbacks
     * @response `201` `HomeworkTemplateCustomFieldFeedback` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postHomeworkTemplateCustomFieldFeedbackAsTeacher: (
      homework_template_custom_field_id: string,
      data: CustomFieldFeedbackRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomFieldFeedback, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homework_template_custom_fields/${homework_template_custom_field_id}/feedbacks`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - "Teacher限定 HomeworkTemplateCustomFieldFeedbackを更新"
     *
     * @tags Teacher/HomeworkTemplateCustomFieldFeedbacks
     * @name PutHomeworkTemplateCustomFieldFeedbacksAsTeacher
     * @summary HomeworkTemplateCustomFieldFeedbackを更新
     * @request PUT:/teacher/homework_template_custom_fields/{homework_template_custom_field_id}/feedbacks/{id}
     * @response `202` `HomeworkTemplateCustomFieldFeedback` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putHomeworkTemplateCustomFieldFeedbacksAsTeacher: (
      homework_template_custom_field_id: string,
      id: string,
      data: PutCustomFieldFeedbackRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomFieldFeedback, BadRequest | Unauthorized | Forbidden>(
        `/teacher/homework_template_custom_fields/${homework_template_custom_field_id}/feedbacks/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "Teacher限定 HomeworkTemplateCustomFieldFeedbackを削除"
     *
     * @tags Teacher/HomeworkTemplateCustomFieldFeedbacks
     * @name DeleteHomeworkTemplateCustomFieldFeedbacksAsTeacher
     * @summary HomeworkTemplateCustomFieldFeedbackを削除
     * @request DELETE:/teacher/homework_template_custom_fields/{homework_template_custom_field_id}/feedbacks/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteHomeworkTemplateCustomFieldFeedbacksAsTeacher: (
      homework_template_custom_field_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/homework_template_custom_fields/${homework_template_custom_field_id}/feedbacks/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description カスタムフィールドの並び替え
     *
     * @tags Teacher/HomeworkTemplateCustomField
     * @name HomeworkTemplatesHomeworkTemplateCustomFieldsSequenceUpdate
     * @request PUT:/teacher/homework_templates/{homework_template_id}/homework_template_custom_fields/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    homeworkTemplatesHomeworkTemplateCustomFieldsSequenceUpdate: (
      homework_template_id: string,
      data: { sequences: { id: string; sequence_number: number }[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homework_templates/${homework_template_id}/homework_template_custom_fields/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description This will return company detail information about the company the teacher actually belongs to. Specifying different company_id will result to return the one the teacher belongs to.
     *
     * @tags Companies
     * @name GetTeacherCompaniesCompanyId
     * @summary Teacher to get company detail
     * @request GET:/teacher/companies/{company_id}
     * @response `200` `{ id: string, name: string, postcode: string, prefecture: string, "address1": string, "address2": string, phone: string, student_count_by_grade: ({ grade?: string, count?: number })[], student_input_settings: StudentInputSetting, options: Option }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherCompaniesCompanyId: (company_id: string, params?: RequestParams) =>
      this.request<
        {
          id: string;
          name: string;
          postcode: string;
          prefecture: string;
          address1: string;
          address2: string;
          phone: string;
          student_count_by_grade: { grade?: string; count?: number }[];
          student_input_settings: StudentInputSetting;
          options: Option;
        },
        Unauthorized | Forbidden
      >(`/teacher/companies/${company_id}`, "GET", params),

    /**
     * @description teacherはアカウントロック通知メール設定のみ変更できる。(住所や電話番号の変更はできない)
     *
     * @tags Companies
     * @name PutTeacherCompaniesCompanyId
     * @summary 企業情報の更新
     * @request PUT:/teacher/companies/{company_id}
     * @response `204` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTeacherCompaniesCompanyId: (
      company_id: string,
      data: { options?: { use_account_lock_mail?: boolean; use_article_and_homework_mail?: boolean } },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/companies/${company_id}`, "PUT", params, data),

    /**
     * @description - Teacher限定 オプション拡張による制限の確認 - 教材登録のディスク使用量制限 - あいである枚数制限
     *
     * @tags Teacher/Company
     * @name GetCompanyLimitOptionAsTeacher
     * @summary オプション拡張による制限の確認
     * @request GET:/teacher/companies/{id}/limit_option
     * @response `200` `{ limit_option: LimitOptionBase, total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCompanyLimitOptionAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        { limit_option: LimitOptionBase; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/companies/${id}/limit_option`, "GET", params),

    /**
     * @description - "Teacher限定 ReviewerCompanyGroupを一覧を返却" - "提出物タイプ一覧の返却"
     *
     * @tags Teacher/ReviewerCompanyGroup
     * @name GetReviewerCompanyGroupControllersAsTeacher
     * @summary ReviewerCompanyGroup一覧を返却
     * @request GET:/teacher/reviewer_company_groups
     * @response `200` `{ reviewer_company_groups: (ReviewerCompanyGroupBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getReviewerCompanyGroupControllersAsTeacher: (
      query: GetReviewerCompanyGroupControllersAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { reviewer_company_groups: ReviewerCompanyGroupBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/reviewer_company_groups${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Ownerが teacher_visible: true で設定したものを表示 - SuperOwnerが Categoryの設定で teacher_visible: true かつ 学校タグ を設定している場合、その 学校タグ に紐づいている場合表示される
     *
     * @tags Categories
     * @name GetTeacherCategories
     * @summary For Teacher: Get category data
     * @request GET:/teacher/categories
     * @response `200` `{ grades: (GradeCategoryInfo)[], subjects: (SubjectCategoryInfo)[], major_categories: (MajorCategoryInfo)[], middle_categories: (MiddleCategoryInfo)[], minor_categories: (MinorCategoryInfo)[], texts?: (TextCategoryInfo)[] }` OK
     */
    getTeacherCategories: (params?: RequestParams) =>
      this.request<
        {
          grades: GradeCategoryInfo[];
          subjects: SubjectCategoryInfo[];
          major_categories: MajorCategoryInfo[];
          middle_categories: MiddleCategoryInfo[];
          minor_categories: MinorCategoryInfo[];
          texts?: TextCategoryInfo[];
        },
        any
      >(`/teacher/categories`, "GET", params),

    /**
     * No description
     *
     * @tags Teacher/Notification
     * @name NotificationsList
     * @request GET:/teacher/notifications
     * @response `200` `Notifications` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    notificationsList: (query: NotificationsListParams, params?: RequestParams) =>
      this.request<Notifications, Unauthorized | Forbidden>(
        `/teacher/notifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - AffiliateのIDが送られてきた場合は、それに紐付いたユーザにお知らせを送付 - ユーザタグのIDが送られてきた場合は、それに紐付いたユーザにお知らせを送付 - ターゲットロールが送られてきた場合は、そのロールを持つ自社ユーザにお知らせを送付(target_company_idsが必要) - 両方送られてきた場合、両方に紐づくユーザにお知らせを送付 - with_emailパラメータが送られてきている場合は、お知らせと同じ内容のメールを配信 - publication_timeが送られてきた場合は、その時間にお知らせを配信
     *
     * @tags Teacher/Notification
     * @name CreateNotificationAsTeacher
     * @request POST:/teacher/notifications
     * @response `200` `{ message?: string }`
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    createNotificationAsTeacher: (
      data: {
        target_roles?: string[];
        target_user_tag_ids?: string[];
        target_company_ids?: string[];
        target_affiliate_ids?: string[];
        title: string;
        message: string;
        with_email?: boolean;
        publication_time?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/notifications`,
        "POST",
        params,
        data,
      ),

    /**
     * @description お知らせの詳細を取得
     *
     * @tags Teacher/Notification
     * @name GetOwnerNotificationId
     * @summary お知らせの詳細を取得
     * @request GET:/teacher/notifications/{id}
     * @response `200` `Notification` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getOwnerNotificationId: (id: string, params?: RequestParams) =>
      this.request<Notification, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/notifications/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - target_affiliate_idsが送られてきた場合は、それに紐付いたユーザにお知らせを送付 - target_user_tag_idsが送られてきた場合は紐づくユーザにお知らせを送信 - target_company_tag_idsとtarget_rolesが送られてきた場合は指定したロールの紐づくユーザにお知らせを送信 - target_company_idsとtarget_rolesが送られてきた場合は指定したロールの対象会社のユーザにお知らせを送信 - target_company_tag_idsまたはtarget_company_idsを指定する場合はtarget_rolesは必須 - publication_timeは必須 - 予約配信時間が3分前のお知らせは変更できない
     *
     * @tags Teacher/Notification
     * @name PutTeacherNotificationId
     * @summary Teacher: お知らせの情報更新
     * @request PUT:/teacher/notifications/{id}
     * @response `202` `Notification` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putTeacherNotificationId: (id: string, data: NotificationRequest, params?: RequestParams) =>
      this.request<Notification, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/notifications/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - 予約配信時間が3分前のお知らせは変更できない - お知らせ済みのものは削除できない
     *
     * @tags Teacher/Notification
     * @name DeleteTeacherNotificationId
     * @summary Teacher: お知らせの削除
     * @request DELETE:/teacher/notifications/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteTeacherNotificationId: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/notifications/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a teacher
     *
     * @tags Teacher/Article
     * @name FetchArticlesAsTeacher
     * @summary Articles Index
     * @request GET:/teacher/articles
     * @response `200` `{ articles: (ArticleBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `500` `any` Internal Server Error
     */
    fetchArticlesAsTeacher: (query: FetchArticlesAsTeacherParams, params?: RequestParams) =>
      this.request<{ articles: ArticleBase[]; total_count: number }, BadRequest | Unauthorized>(
        `/teacher/articles${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 直近の探究成果物一覧を返却 - limitで件数を変更可能（デフォルト6件）
     *
     * @tags Teacher/Article
     * @name FetchArticleStatsAsTeacher
     * @summary 直近の探究成果物一覧を返却
     * @request GET:/teacher/articles/stats
     * @response `200` `{ articles: (ArticleStatsBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchArticleStatsAsTeacher: (query: FetchArticleStatsAsTeacherParams, params?: RequestParams) =>
      this.request<{ articles: ArticleStatsBase[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/articles/stats${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - this endpoint needs word_cloud_feature
     *
     * @tags Teacher/Article
     * @name FetchArticleWordCloudAsTeacher
     * @summary 自身が閲覧可能な探究成果から生成したワードクラウドリストを返却
     * @request GET:/teacher/articles/word_cloud
     * @response `200` `WordCloudBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchArticleWordCloudAsTeacher: (query: FetchArticleWordCloudAsTeacherParams, params?: RequestParams) =>
      this.request<WordCloudBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/articles/word_cloud${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 探究成果物の提出先と掲載先を返却 - article_idsの指定は必須
     *
     * @tags Teacher/Article
     * @name GetArticleSubmitAsTeacher
     * @summary 探究成果物の提出先と掲載先を返却
     * @request GET:/teacher/articles/submit
     * @response `200` `{ articles: (ArticleSubmitBase)[], total_count: number }` OK
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getArticleSubmitAsTeacher: (query: GetArticleSubmitAsTeacherParams, params?: RequestParams) =>
      this.request<
        { articles: ArticleSubmitBase[]; total_count: number },
        { message?: string } | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/articles/submit${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Teacher/Article, articles
     * @name FetchArticleAsTeacher
     * @summary 探究成果記事の詳細を取得
     * @request GET:/teacher/articles/{article_id}
     * @response `200` `ArticleAsTeacher` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchArticleAsTeacher: (article_id: string, params?: RequestParams) =>
      this.request<ArticleAsTeacher, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/articles/${article_id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags articles, Teacher/Article
     * @name UpdateArticleAsTeacher
     * @summary 探究成果の更新
     * @request PUT:/teacher/articles/{article_id}
     * @response `202` `ArticleAsTeacher` accepted
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    updateArticleAsTeacher: (
      article_id: string,
      data: {
        comment?: string | null;
        new_status: ArticleStatus;
        content_company_ids?: string[] | null;
        display_student_names_for_content_provider?: boolean;
        display_comments_for_content_provider?: boolean;
      },
      params?: RequestParams,
    ) =>
      this.request<ArticleAsTeacher, BadRequest | { error?: string }>(
        `/teacher/articles/${article_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Return articles to the current user logged in as a teacher
     *
     * @tags Teacher/Article
     * @name FetchArticleCommentsAsTeacher
     * @summary Article Comments Index
     * @request GET:/teacher/articles/{article_id}/comments
     * @response `200` `{ article_comments: (ArticleComment)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    fetchArticleCommentsAsTeacher: (
      { article_id, ...query }: FetchArticleCommentsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { article_comments: ArticleComment[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/articles/${article_id}/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags articles, Teacher/Article
     * @name CreateArticleCommentAsTeacher
     * @summary 探究成果の登録
     * @request POST:/teacher/articles/{article_id}/comments
     * @response `201` `ArticleDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    createArticleCommentAsTeacher: (
      article_id: string,
      data: { title: string; body?: string },
      params?: RequestParams,
    ) =>
      this.request<
        ArticleDetailBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/articles/${article_id}/comments`, "POST", params, data),

    /**
     * @description Return articles to the current user logged in as a teacher
     *
     * @tags Teacher/Article
     * @name FetchArticleFeedbacksAsTeacher
     * @summary Article Feedbacks Index
     * @request GET:/teacher/articles/{article_id}/feedbacks
     * @response `200` `{ article_feedbacks: (ArticleFeedback)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    fetchArticleFeedbacksAsTeacher: (
      { article_id, ...query }: FetchArticleFeedbacksAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { article_feedbacks: ArticleFeedback[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/articles/${article_id}/feedbacks${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags articles, Teacher/Article
     * @name CreateArticleFeedbackAsTeacher
     * @summary 探究成果の登録
     * @request POST:/teacher/articles/{article_id}/feedbacks
     * @response `201` `ArticleFeedback` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any` Internal Server Error
     */
    createArticleFeedbackAsTeacher: (article_id: string, data: { body: string }, params?: RequestParams) =>
      this.request<
        ArticleFeedback,
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/articles/${article_id}/feedbacks`, "POST", params, data),

    /**
     * @description 先生として自分が書いたフィードバックコメントを削除する。
     *
     * @tags Teacher/Article
     * @name DeleteArticleFeedbackAsTeacher
     * @request DELETE:/teacher/articles/{article_id}/feedbacks/{feedback_id}
     * @response `200` `{ message: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteArticleFeedbackAsTeacher: (article_id: string, feedback_id: string, params?: RequestParams) =>
      this.request<
        { message: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/articles/${article_id}/feedbacks/${feedback_id}`, "DELETE", params),

    /**
     * @description - Teacher限定 探究成果記事の画像を更新する
     *
     * @tags Teacher/ArticleImages
     * @name PutArticleImageAsTeacher
     * @summary 探究成果記事の画像を更新
     * @request PUT:/teacher/articles/{article_id}/article_images/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putArticleImageAsTeacher: (article_id: string, id: string, data: { file: File }, params?: RequestParams) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/articles/${article_id}/article_images/${id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description Return articles to the current user logged in as a teacher
     *
     * @tags Teacher/ArticleComment
     * @name ArticleCommentsStudentCommentsListList
     * @summary Article Student Comments List
     * @request GET:/teacher/article_comments/student_comments_list
     * @response `200` `StudentCommentList` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    articleCommentsStudentCommentsListList: (
      query: ArticleCommentsStudentCommentsListListParams,
      params?: RequestParams,
    ) =>
      this.request<
        StudentCommentList,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/article_comments/student_comments_list${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 先生としてフィードバック依頼先を削除する。
     *
     * @tags Teacher/Article
     * @name DeleteArticleCompanyAsTeacher
     * @request DELETE:/teacher/articles/{article_id}/articles_companies/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteArticleCompanyAsTeacher: (article_id: string, id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/articles/${article_id}/articles_companies/${id}`, "DELETE", params),

    /**
     * @description ユーザにタグを付与
     *
     * @tags Teacher/UserTagging
     * @name UserTaggingsCreate
     * @request POST:/teacher/user_taggings
     * @response `201` `{ number_of_tagging_users?: number, remaining_number_of_tagging_users?: number | null }` Created
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    userTaggingsCreate: (data: { user_id?: string; user_tag_id?: string }, params?: RequestParams) =>
      this.request<
        { number_of_tagging_users?: number; remaining_number_of_tagging_users?: number | null },
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/user_taggings`, "POST", params, data),

    /**
     * @description ユーザからタグを削除
     *
     * @tags Teacher/UserTagging
     * @name UserTaggingsDelete
     * @request DELETE:/teacher/user_taggings
     * @response `200` `{ messaege?: string }` OK
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTaggingsDelete: (data: { user_id?: string; user_tag_id?: string }, params?: RequestParams) =>
      this.request<{ messaege?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/user_taggings`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description ユーザに一括でタグを付与
     *
     * @tags Teacher/UserTagging
     * @name TeacherUserTaggingBatchCreate
     * @request POST:/teacher/user_taggings/batch_create
     * @response `200` `UserTaggingBatchCreateResponses` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    teacherUserTaggingBatchCreate: (data: UserTaggingBatchCreateParams, params?: RequestParams) =>
      this.request<
        UserTaggingBatchCreateResponses,
        { message?: string } | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/user_taggings/batch_create`, "POST", params, data),

    /**
     * @description 自社作成含む自社に紐づき、かつ先生が参照可能な「ユーザータグ」と「ユーザータグタイプ」を一覧取得
     *
     * @tags Teacher/UserTagType
     * @name UserTagTypesList
     * @request GET:/teacher/user_tag_types
     * @response `200` `{ user_tag_types: (UserTagType)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTagTypesList: (query: UserTagTypesListParams, params?: RequestParams) =>
      this.request<
        { user_tag_types: UserTagType[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/user_tag_types${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacherがユーザタグ分類を新規作成
     *
     * @tags Teacher/UserTagType
     * @name UserTagTypesCreate
     * @request POST:/teacher/user_tag_types
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTagTypesCreate: (
      data: { name?: string; kind?: UserTagTypeKind; teacher_visible?: boolean },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/user_tag_types`,
        "POST",
        params,
        data,
      ),

    /**
     * @description kindやtarget_company_idの更新は不可
     *
     * @tags Teacher/UserTagType
     * @name UserTagTypesUpdate
     * @request PUT:/teacher/user_tag_types/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTagTypesUpdate: (id: string, data: { name?: string; teacher_visible?: boolean }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/user_tag_types/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 削除
     *
     * @tags Teacher/UserTagType
     * @name UserTagTypesDelete
     * @request DELETE:/teacher/user_tag_types/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTagTypesDelete: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/user_tag_types/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description ユーザタグに紐づく生徒を一覧取得
     *
     * @tags Teacher/UserTag/User
     * @name UserTagsUsersDetail
     * @request GET:/teacher/user_tags/{user_tag_id}/users
     * @response `200` `{ users: (BasicUserInfo)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTagsUsersDetail: ({ user_tag_id, ...query }: UserTagsUsersDetailParams, params?: RequestParams) =>
      this.request<{ users: BasicUserInfo[]; total_count: number }, Unauthorized | Forbidden | { error?: string }>(
        `/teacher/user_tags/${user_tag_id}/users${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザタグに紐づく生徒を一覧取得
     *
     * @tags Teacher/UserTag/Student
     * @name UserTagsDistributedStudentsDetail
     * @request GET:/teacher/user_tags/{id}/distributed_students
     * @response `200` `{ students: (SimpleUserList)[], total_count: number }` OK
     * @response `404` `{ error?: string }`
     */
    userTagsDistributedStudentsDetail: (
      { id, ...query }: UserTagsDistributedStudentsDetailParams,
      params?: RequestParams,
    ) =>
      this.request<{ students: SimpleUserList[]; total_count: number }, { error?: string }>(
        `/teacher/user_tags/${id}/distributed_students${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザタグの並び替え
     *
     * @tags Teacher/UserTag
     * @name UserTagsSequenceUpdate
     * @request PUT:/teacher/user_tags/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    userTagsSequenceUpdate: (data: { sequences: { id: string; sequence_number?: number }[] }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/user_tags/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description ユーザに一括でタグを付与
     *
     * @tags Teacher/UserTags
     * @name TeacherUserTagsBatchCreate
     * @request PATCH:/teacher/user_tags/batch_create
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    teacherUserTagsBatchCreate: (data: UserTagsBatchCreateParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/user_tags/batch_create`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description 先生として提出物テンプレート一覧を取得する。
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplatesAsTeacher
     * @request GET:/teacher/homework_templates
     * @response `200` `{ homework_templates: (HomeworkTemplateBaseForOwnerAndTeacher)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }` Not Found
     */
    fetchHomeworkTemplatesAsTeacher: (query: FetchHomeworkTemplatesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { homework_templates: HomeworkTemplateBaseForOwnerAndTeacher[]; total_count: number },
        Unauthorized | Forbidden | { error?: string }
      >(`/teacher/homework_templates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description HomeworkTemplateを作成し、作成されたHomeworkTemplateを返す
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name CreateHomeworkTemplateAsTeacher
     * @request POST:/teacher/homework_templates
     * @response `201` `HomeworkTemplateForOwnerAndTeacher` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }` Not Found
     * @response `422` `ErrorsWithKeyMessage`
     */
    createHomeworkTemplateAsTeacher: (data: HomeworkTemplateCreateRequest, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/homework_templates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 直近の提出物テンプレートと提出物一覧を返却 - limitで件数を変更可能（デフォルト5件） - 提出物テンプレートに紐づいた提出物を返却 - 提出物のステータスの件数を返却
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplateStatsAsTeacher
     * @summary 直近の提出物テンプレートと提出物一覧を返却
     * @request GET:/teacher/homework_templates/stats
     * @response `200` `{ homework_templates: (HomeworkTemplateStatsBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchHomeworkTemplateStatsAsTeacher: (query: FetchHomeworkTemplateStatsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { homework_templates: HomeworkTemplateStatsBase[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/homework_templates/stats${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 提出物テンプレートの配信ステータス
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name GetHomeworkTemplateDetailListAsTeacher
     * @summary 指定した提出物テンプレートの配信ステータスを返却
     * @request GET:/teacher/homework_templates/detail_list
     * @response `200` `{ homework_templates: (any)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getHomeworkTemplateDetailListAsTeacher: (
      query: GetHomeworkTemplateDetailListAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<{ homework_templates: any[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homework_templates/detail_list${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 指定したHomeworkTemplateを取得する
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplateAsTeacher
     * @request GET:/teacher/homework_templates/{homework_template_id}
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }` Not Found
     */
    fetchHomeworkTemplateAsTeacher: (homework_template_id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homework_templates/${homework_template_id}`,
        "GET",
        params,
      ),

    /**
     * @description HomeworkTemplateをアップデートする。
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name UpdateHomeworkTemplateAsTeacher
     * @request PUT:/teacher/homework_templates/{homework_template_id}
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }` Not Found
     * @response `422` `any` Unprocessable Entity
     */
    updateHomeworkTemplateAsTeacher: (
      homework_template_id: string,
      data: HomeworkTemplateUpdateRequest,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, BadRequest | { error?: string }>(
        `/teacher/homework_templates/${homework_template_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description HomeworkTemplateの削除
     *
     * @tags Owner/HomeworkTemplate
     * @name DeleteHomeworkTemplateAsTeacher
     * @request DELETE:/teacher/homework_templates/{homework_template_id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }` Not Found
     */
    deleteHomeworkTemplateAsTeacher: (homework_template_id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/teacher/homework_templates/${homework_template_id}`,
        "DELETE",
        params,
      ),

    /**
     * @description 提出物のカスタムフィールドの一覧を返却
     *
     * @tags Teacher/HomeworkTemplateCustomFields
     * @name GetTeacherHomeworkTemplateCustomFields
     * @summary Teacher: 提出物のカスタムフィールドの一覧を返却
     * @request GET:/teacher/homework_templates/{homework_template_id}/homework_template_custom_fields
     * @response `200` `{ homework_template_custom_fields: (HomeworkTemplateCustomField)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherHomeworkTemplateCustomFields: (
      { homework_template_id, ...query }: GetTeacherHomeworkTemplateCustomFieldsParams,
      params?: RequestParams,
    ) =>
      this.request<
        { homework_template_custom_fields: HomeworkTemplateCustomField[]; total_count: number },
        Unauthorized | Forbidden
      >(
        `/teacher/homework_templates/${homework_template_id}/homework_template_custom_fields${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description 提出物のカスタムフィールドの登録
     *
     * @tags Teacher/HomeworkTemplateCustomFields
     * @name PostTeacherHomeworkTemplateCustomFields
     * @summary Teacher: 提出物のカスタムフィールドの登録
     * @request POST:/teacher/homework_templates/{homework_template_id}/homework_template_custom_fields
     * @response `201` `HomeworkTemplateCustomField` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postTeacherHomeworkTemplateCustomFields: (
      homework_template_id: string,
      data: CustomFieldCommonRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomField, Unauthorized | Forbidden>(
        `/teacher/homework_templates/${homework_template_id}/homework_template_custom_fields`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 提出物のカスタムフィールドの情報取得
     *
     * @tags Teacher/HomeworkTemplateCustomFields
     * @name GetTeacherHomeworkTemplateCustomFieldsId
     * @summary Teacher: 提出物のカスタムフィールドの情報参照
     * @request GET:/teacher/homework_templates/{homework_template_id}/homework_template_custom_fields/{id}
     * @response `200` `HomeworkTemplateCustomField` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getTeacherHomeworkTemplateCustomFieldsId: (homework_template_id: string, id: string, params?: RequestParams) =>
      this.request<
        HomeworkTemplateCustomField,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/homework_templates/${homework_template_id}/homework_template_custom_fields/${id}`, "GET", params),

    /**
     * @description 提出物のカスタムフィールドの情報の更新
     *
     * @tags Teacher/HomeworkTemplateCustomFields
     * @name PutTeacherHomeworkTemplateCustomFieldsId
     * @summary Teacher: 提出物のカスタムフィールドの情報更新
     * @request PUT:/teacher/homework_templates/{homework_template_id}/homework_template_custom_fields/{id}
     * @response `202` `HomeworkTemplateCustomField` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTeacherHomeworkTemplateCustomFieldsId: (
      homework_template_id: string,
      id: string,
      data: CustomFieldCommonRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        HomeworkTemplateCustomField,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(
        `/teacher/homework_templates/${homework_template_id}/homework_template_custom_fields/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 提出物のカスタムフィールドの情報の削除
     *
     * @tags Teacher/HomeworkTemplateCustomFields
     * @name DeleteTeacherHomeworkTemplateCustomFieldsId
     * @summary Teacher: 提出物のカスタムフィールドの削除
     * @request DELETE:/teacher/homework_templates/{homework_template_id}/homework_template_custom_fields/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteTeacherHomeworkTemplateCustomFieldsId: (homework_template_id: string, id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/homework_templates/${homework_template_id}/homework_template_custom_fields/${id}`, "DELETE", params),

    /**
     * @description HomeworkTemplateを複製する
     *
     * @tags Teacher/HomeworkTemplate, HomeworkTemplate
     * @name PostHomeworkTemplateDuplicateAsTeacher
     * @request POST:/teacher/homework_templates/{id}/duplicate
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postHomeworkTemplateDuplicateAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homework_templates/${id}/duplicate`,
        "POST",
        params,
      ),

    /**
     * @description - Teacher限定 提出物テンプレートのフォームの回答内容 - CSV書き出し用
     *
     * @tags Teacher/HomeworkTemplate, HomeworkTemplate
     * @name GetHomeworkTemplateAnswersAsTeacher
     * @summary 提出物テンプレートのフォームの回答内容を返却
     * @request GET:/teacher/homework_templates/{id}/answers
     * @response `200` `{ questions: ({ id: number, title: string })[], answers: (({ name: string, question_id: number, value: string })[])[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getHomeworkTemplateAnswersAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        {
          questions: { id: number; title: string }[];
          answers: { name: string; question_id: number; value: string }[][];
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/homework_templates/${id}/answers`, "GET", params),

    /**
     * No description
     *
     * @tags HomeworkTemplateDistribution, Teacher/HomeworkTemplateDistribution
     * @name UpdateHomeworkTemplateDistributionAsTeacher
     * @summary SchoolManager権限を持つTeacherがHomeworkTemplateDistributionを更新
     * @request PUT:/teacher/homework_template_distributions/{id}
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    updateHomeworkTemplateDistributionAsTeacher: (id: string, data: { comment?: string }, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, { message?: string } | Unauthorized | Forbidden>(
        `/teacher/homework_template_distributions/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/HomeworkTemplate, Teacher/HomeworkTemplateDistribution
     * @name AssignManagerToHomeworkTemplateDistributionAsTeacher
     * @summary HomeworkTemplateDistributionにSchoolManagerを割り当てる
     * @request PUT:/teacher/homework_template_distributions/{id}/assign_manager
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    assignManagerToHomeworkTemplateDistributionAsTeacher: (
      id: string,
      data: { teacher_id: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/homework_template_distributions/${id}/assign_manager`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/HomeworkTemplate, Teacher/HomeworkTemplateDistribution
     * @name SubmitHomeworkTemplateDistributionToReviewerAsTeacher
     * @summary HomeworkTemplateDistributionをReviewerに提出する
     * @request PUT:/teacher/homework_template_distributions/{id}/submit_to_reviewer
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    submitHomeworkTemplateDistributionToReviewerAsTeacher: (
      id: string,
      data: { comment?: string | null; image_contents?: File[] },
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, { message?: string } | Unauthorized | Forbidden>(
        `/teacher/homework_template_distributions/${id}/submit_to_reviewer`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/HomeworkTemplate, Teacher/HomeworkTemplateDistribution
     * @name RevertHomeworkTemplateDistributionSubmittedToReviewerAsTeacher
     * @summary Reviewerに提出したHomeworkTemplateDistributionを取り下げる
     * @request PUT:/teacher/homework_template_distributions/{id}/revert_submission_to_reviewer
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    revertHomeworkTemplateDistributionSubmittedToReviewerAsTeacher: (id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, { message?: string } | Unauthorized | Forbidden>(
        `/teacher/homework_template_distributions/${id}/revert_submission_to_reviewer`,
        "PUT",
        params,
      ),

    /**
     * No description
     *
     * @tags Teacher/HomeworkTemplate, Teacher/HomeworkTemplateDistribution
     * @name ReturnHomeworkTemplateDistributionToStudentsAsTeacher
     * @summary Reviewerに提出したHomeworkTemplateDistributionを取り下げる
     * @request PUT:/teacher/homework_template_distributions/{id}/return_to_students
     * @response `200` `HomeworkTemplateForOwnerAndTeacher` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    returnHomeworkTemplateDistributionToStudentsAsTeacher: (id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateForOwnerAndTeacher, { message?: string } | Unauthorized | Forbidden>(
        `/teacher/homework_template_distributions/${id}/return_to_students`,
        "PUT",
        params,
      ),

    /**
     * @description 先生として提出物テンプレートのチャンク一覧を取得する。
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name FetchHomeworkTemplateChunksAsTeacher
     * @request GET:/teacher/homework_templates/{template_id}/homework_template_chunks
     * @response `200` `{ chunks: (HomeworkTemplateChunk)[] }` OK
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    fetchHomeworkTemplateChunksAsTeacher: (template_id: string, params?: RequestParams) =>
      this.request<
        { chunks: HomeworkTemplateChunk[] },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/homework_templates/${template_id}/homework_template_chunks`, "GET", params),

    /**
     * @description HomeworkTemplateを作成し、作成されたHomeworkTemplateを返す
     *
     * @tags Teacher/HomeworkTemplate, Teacher/Homework, Homework, HomeworkTemplate
     * @name CreateHomeworkTemplateChunkAsTeacher
     * @request POST:/teacher/homework_templates/{template_id}/homework_template_chunks
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createHomeworkTemplateChunkAsTeacher: (
      template_id: string,
      data: {
        homework_template_image_id?: string;
        image?: string;
        placement: {
          top: number;
          left: number;
          right: number;
          bottom: number;
          natural_width: number;
          natural_height: number;
          page: number;
        };
      },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/homework_templates/${template_id}/homework_template_chunks`, "POST", params, data),

    /**
     * @description HomeworkTemplateChunkの削除
     *
     * @tags Teacher/HomeworkTemplateChunk
     * @name DeleteHomeworkTemplateChunkAsTeacher
     * @request DELETE:/teacher/homework_templates/{template_id}/homework_template_chunks/{id}
     * @response `200` `{ message?: string }`
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteHomeworkTemplateChunkAsTeacher: (template_id: string, id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/homework_templates/${template_id}/homework_template_chunks/${id}`, "DELETE", params),

    /**
     * @description - Teacher限定 HomeworkTemplateImage を登録
     *
     * @tags Teacher/HomeworkTemplateImage
     * @name PostHomeworkTemplateImageAsTeacher
     * @summary 提出物テンプレートの画像 を登録
     * @request POST:/teacher/homework_templates/{homework_template_id}/homework_template_images
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postHomeworkTemplateImageAsTeacher: (
      homework_template_id: string,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homework_templates/${homework_template_id}/homework_template_images`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 HomeworkTemplateImage を更新
     *
     * @tags Teacher/HomeworkTemplateImage
     * @name PutHomeworkTemplateImageAsTeacher
     * @summary 提出物テンプレートの画像 を更新
     * @request PUT:/teacher/homework_templates/{homework_template_id}/homework_template_images/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putHomeworkTemplateImageAsTeacher: (
      homework_template_id: string,
      id: string,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homework_templates/${homework_template_id}/homework_template_images/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 HomeworkTemplateImage を削除
     *
     * @tags Teacher/HomeworkTemplateImage
     * @name DeleteHomeworkTemplateImageAsTeacher
     * @summary 提出物テンプレートの画像 を削除
     * @request DELETE:/teacher/homework_templates/{homework_template_id}/homework_template_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteHomeworkTemplateImageAsTeacher: (homework_template_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/homework_templates/${homework_template_id}/homework_template_images/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description UserTag一覧を取得する
     *
     * @tags Teacher/UserTag
     * @name FetchUserTagsAsTeacher
     * @request GET:/teacher/user_tags
     * @response `200` `{ user_tags: (UserTag)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    fetchUserTagsAsTeacher: (query: FetchUserTagsAsTeacherParams, params?: RequestParams) =>
      this.request<{ user_tags: UserTag[]; total_count: number }, BadRequest | { error?: string }>(
        `/teacher/user_tags${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 新規タグ作成
     *
     * @tags Teacher/UserTag
     * @name CreateUserTagAsTeacher
     * @request POST:/teacher/user_tags
     * @response `201` `{ message?: string }` Created
     * @response `400` `BadRequest`
     */
    createUserTagAsTeacher: (
      data: { user_tag_type_id: string; name: string; limit_number?: number },
      params?: RequestParams,
    ) => this.request<{ message?: string }, BadRequest>(`/teacher/user_tags`, "POST", params, data),

    /**
     * @description ユーザタグの詳細情報取得
     *
     * @tags Teacher/UserTag
     * @name GetUserTagAsTeacher
     * @request GET:/teacher/user_tags/{id}
     * @response `200` `UserTagDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getUserTagAsTeacher: (id: string, params?: RequestParams) =>
      this.request<UserTagDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/user_tags/${id}`,
        "GET",
        params,
      ),

    /**
     * @description ユーザタグ情報を更新
     *
     * @tags Teacher/UserTag
     * @name UpdateUserTagAsTeacher
     * @request PUT:/teacher/user_tags/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    updateUserTagAsTeacher: (id: string, data: { name?: string; limit_number?: number }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/user_tags/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 削除
     *
     * @tags Teacher/UserTag
     * @name DeleteUserTagAsTeacher
     * @request DELETE:/teacher/user_tags/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteUserTagAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/user_tags/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Returns the list of templates, owned by the teacher
     *
     * @tags Teacher/PblReportTemplate
     * @name GetTeacherPblReportTemplates
     * @summary List of templates
     * @request GET:/teacher/pbl/report/templates
     * @response `200` `{ templates: (PblReportTemplateBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherPblReportTemplates: (query: GetTeacherPblReportTemplatesParams, params?: RequestParams) =>
      this.request<{ templates: PblReportTemplateBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/teacher/pbl/report/templates${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 新規作成用
     *
     * @tags Teacher/PblReportTemplate
     * @name PostTeacherPblReportTemplates
     * @summary 探究レポートのテンプレ新規作成
     * @request POST:/teacher/pbl/report/templates
     * @response `201` `PblReportTemplateDetailsForTeacher` OK
     * @response `403` `Forbidden`
     */
    postTeacherPblReportTemplates: (data: { title: string; due_date_time?: string }, params?: RequestParams) =>
      this.request<PblReportTemplateDetailsForTeacher, Forbidden>(
        `/teacher/pbl/report/templates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 直近の探究レポートデータ一覧を返却 - limitで件数を変更可能（デフォルト5件）
     *
     * @tags Teacher/PblReportTemplate
     * @name FetchPblReportTemplateStatsAsTeacher
     * @summary 直近の探究レポートデータ一覧を返却
     * @request GET:/teacher/pbl/report/templates/stats
     * @response `200` `{ templates: (PblReportTemplateStats)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchPblReportTemplateStatsAsTeacher: (query: FetchPblReportTemplateStatsAsTeacherParams, params?: RequestParams) =>
      this.request<{ templates: PblReportTemplateStats[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/pbl/report/templates/stats${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblReportTemplate
     * @name FetchPblReportTemplateAsTeacher
     * @summary 探究レポートテンプレートの取得
     * @request GET:/teacher/pbl/report/templates/{id}
     * @response `200` `PblReportTemplateDetailsForTeacher` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    fetchPblReportTemplateAsTeacher: (id: string, params?: RequestParams) =>
      this.request<PblReportTemplateDetailsForTeacher, Unauthorized | Forbidden | { error?: string }>(
        `/teacher/pbl/report/templates/${id}`,
        "GET",
        params,
      ),

    /**
     * @description テンプレを更新。許可されていないeventが発行された場合は400
     *
     * @tags Teacher/PblReportTemplate
     * @name PutTeacherPblReportTemplatesId
     * @summary 探究レポートテンプレの更新
     * @request PUT:/teacher/pbl/report/templates/{id}
     * @response `200` `PblReportTemplateDetailsForTeacher` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    putTeacherPblReportTemplatesId: (
      id: string,
      data: { title?: string; due_date_time?: string; event?: "distribute" | "fulfill" },
      params?: RequestParams,
    ) =>
      this.request<
        PblReportTemplateDetailsForTeacher,
        { message?: string } | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/report/templates/${id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Teacher/PblReportTemplate
     * @name GetTeacherPblReportTemplatesIdAnswers
     * @summary 探究レポートテンプレに紐づく回答一覧
     * @request GET:/teacher/pbl/report/templates/{id}/answers
     * @response `200` `{ pbl_report_template_answers?: (PblReportAnswerBase)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherPblReportTemplatesIdAnswers: (
      { id, ...query }: GetTeacherPblReportTemplatesIdAnswersParams,
      params?: RequestParams,
    ) =>
      this.request<{ pbl_report_template_answers?: PblReportAnswerBase[] }, Unauthorized | Forbidden>(
        `/teacher/pbl/report/templates/${id}/answers${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblReportTemplate
     * @name GetTeacherPblReportTemplatesIdItems
     * @summary 探究レポートテンプレに紐づく設問一覧
     * @request GET:/teacher/pbl/report/templates/{id}/items
     * @response `200` `(PblReportTextBase)[]` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherPblReportTemplatesIdItems: (id: string, params?: RequestParams) =>
      this.request<PblReportTextBase[], Unauthorized | Forbidden>(
        `/teacher/pbl/report/templates/${id}/items`,
        "GET",
        params,
      ),

    /**
     * @description Teacher限定 探究レポートのフレームワークの一覧を返却
     *
     * @tags Teacher/PblReportTemplate
     * @name GetTeacherTemplateFrameworks
     * @summary Teacher: 探究レポートのフレームワークの一覧を返却
     * @request GET:/teacher/pbl/report/templates/frameworks
     * @response `200` `{ report_template_frameworks?: (any)[], total_count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherTemplateFrameworks: (query: GetTeacherTemplateFrameworksParams, params?: RequestParams) =>
      this.request<{ report_template_frameworks?: any[]; total_count?: number }, Unauthorized | Forbidden>(
        `/teacher/pbl/report/templates/frameworks${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblReportAnswer
     * @name GetTeacherPblReportAnswerId
     * @summary 回答の詳細を取得
     * @request GET:/teacher/pbl/report/answers/{id}
     * @response `200` `PblReportAnswerDetailsForTeacher` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getTeacherPblReportAnswerId: (id: string, params?: RequestParams) =>
      this.request<
        PblReportAnswerDetailsForTeacher,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/report/answers/${id}`, "GET", params),

    /**
     * @description - "Teacher限定 生徒を結びつけるAnswerを一覧を返却"
     *
     * @tags Teacher/PblReportAnswer
     * @name GetTeacherPblReportAnswer
     * @summary 生徒を結びつけるAnswer一覧を返却
     * @request GET:/teacher/pbl/report/answers
     * @response `200` `{ answers: (PblReportAnswerBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherPblReportAnswer: (query: GetTeacherPblReportAnswerParams, params?: RequestParams) =>
      this.request<
        { answers: PblReportAnswerBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/report/answers${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Teacher/PblReportAnswer
     * @name PostTeacherPblReportAnswer
     * @summary Templateと生徒を結びつけるAnswerを作成
     * @request POST:/teacher/pbl/report/answers
     * @response `201` `PblReportAnswerBase` Created
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTeacherPblReportAnswer: (data: { student_id: string; template_id: string }, params?: RequestParams) =>
      this.request<
        PblReportAnswerBase,
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/report/answers`, "POST", params, data),

    /**
     * No description
     *
     * @tags Teacher/PblReportComment
     * @name PblReportCommentsList
     * @summary コメント一覧の取得
     * @request GET:/teacher/pbl/report/comments
     * @response `200` `(PblReportCommentBase)[]` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    pblReportCommentsList: (query: PblReportCommentsListParams, params?: RequestParams) =>
      this.request<PblReportCommentBase[], Unauthorized | Forbidden>(
        `/teacher/pbl/report/comments${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblReportComment
     * @name PblReportCommentsCreate
     * @summary コメントの新規作成
     * @request POST:/teacher/pbl/report/comments
     * @response `201` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    pblReportCommentsCreate: (
      data: { message?: string; output_id?: string; output_type?: "answer" | "answer_item" },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/teacher/pbl/report/comments`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblReportComment
     * @name PutTeacherPblReportCommentId
     * @summary コメントの更新
     * @request PUT:/teacher/pbl/report/comments/{id}
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putTeacherPblReportCommentId: (id: string, data: { message?: string }, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/teacher/pbl/report/comments/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblReportItem
     * @name GetTeacherPblReportItems
     * @summary 出題アイテム一覧の取得
     * @request GET:/teacher/pbl/report/items
     * @response `200` `(PblReportTextBase)[]` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getTeacherPblReportItems: (query: GetTeacherPblReportItemsParams, params?: RequestParams) =>
      this.request<
        PblReportTextBase[],
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/report/items${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Teacher/PblReportItem
     * @name PostTeacherPblReportItems
     * @summary 出題アイテムの新規作成
     * @request POST:/teacher/pbl/report/items
     * @response `201` `PblReportTextBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTeacherPblReportItems: (
      data: {
        template_id?: string;
        type?: PblReportItemTypeEnum;
        title?: string;
        description?: string;
        sequence?: number;
        text_min_char_size?: number;
        has_title_required?: boolean;
      },
      params?: RequestParams,
    ) =>
      this.request<
        PblReportTextBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/report/items`, "POST", params, data),

    /**
     * No description
     *
     * @tags Teacher/PblReportItem
     * @name PutTeacherPblReoprtItemId
     * @summary 出題アイテムの更新
     * @request PUT:/teacher/pbl/report/items/{id}
     * @response `200` `PblReportTextBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTeacherPblReoprtItemId: (
      id: string,
      data: {
        sequence?: number;
        title?: string;
        description?: string;
        text_example?: string;
        text_min_char_size?: number;
        has_title_required?: boolean;
      },
      params?: RequestParams,
    ) =>
      this.request<
        PblReportTextBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/report/items/${id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Teacher/PblReportItem
     * @name DeleteTeacherPblReportItemId
     * @summary 出題アイテムの削除
     * @request DELETE:/teacher/pbl/report/items/{id}
     * @response `204` `string` NoContent
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteTeacherPblReportItemId: (id: string, params?: RequestParams) =>
      this.request<string, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/pbl/report/items/${id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyBookmark
     * @name FetchPblCardBookmarksAsTeacher
     * @summary 自分が担当する生徒のBookmark一覧を取得
     * @request GET:/teacher/pbl/cards/information_literacy/bookmarks
     * @response `200` `{ bookmarks?: (PblCardBookmarkList)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    fetchPblCardBookmarksAsTeacher: (query: FetchPblCardBookmarksAsTeacherParams, params?: RequestParams) =>
      this.request<
        { bookmarks?: PblCardBookmarkList[]; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/cards/information_literacy/bookmarks${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyBookmark
     * @name PostPblCardBookmarkAsTeacher
     * @summary 新規ブックマークの作成
     * @request POST:/teacher/pbl/cards/information_literacy/bookmarks
     * @response `201` `PblCardBookmarkBase` Created
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postPblCardBookmarkAsTeacher: (data: PostPblCardBookmarkRequestBody, params?: RequestParams) =>
      this.request<PblCardBookmarkBase, Forbidden | ErrorsWithKeyMessage>(
        `/teacher/pbl/cards/information_literacy/bookmarks`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyBookmark
     * @name FetchPblCardBookmarkAsTeacher
     * @summary Bookmarkの詳細
     * @request GET:/teacher/pbl/cards/information_literacy/bookmarks/{id}
     * @response `200` `PblCardBookmarkBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    fetchPblCardBookmarkAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        PblCardBookmarkBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/cards/information_literacy/bookmarks/${id}`, "GET", params),

    /**
     * @description Bookmarkの更新
     *
     * @tags Teacher/PblCardsInformationLiteracyBookmark
     * @name PutPblCardBookmarkAsTeacher
     * @request PUT:/teacher/pbl/cards/information_literacy/bookmarks/{id}
     * @response `200` `PblCardBookmarkBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putPblCardBookmarkAsTeacher: (id: string, data: PutPblCardBookmarkRequestBody, params?: RequestParams) =>
      this.request<
        PblCardBookmarkBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/cards/information_literacy/bookmarks/${id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyBookmark
     * @name DeletePblCardBookmarkAsTeacher
     * @summary Bookmarkの削除
     * @request DELETE:/teacher/pbl/cards/information_literacy/bookmarks/{id}
     * @response `204` `any` No content
     * @response `403` `Forbidden`
     */
    deletePblCardBookmarkAsTeacher: (id: string, params?: RequestParams) =>
      this.request<any, Forbidden>(`/teacher/pbl/cards/information_literacy/bookmarks/${id}`, "DELETE", params),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyBookmark
     * @name PutPblCardBookmarkWysiwygBodyAsTeacher
     * @summary BookmarkのWysiwygBodyの更新
     * @request PUT:/teacher/pbl/cards/information_literacy/bookmarks/{id}/update_wysiwyg_body
     * @response `200` `PblCardBookmarkBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putPblCardBookmarkWysiwygBodyAsTeacher: (id: string, data: { wysiwyg_body?: string }, params?: RequestParams) =>
      this.request<
        PblCardBookmarkBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/cards/information_literacy/bookmarks/${id}/update_wysiwyg_body`, "PUT", params, data),

    /**
     * @description - Teacher限定 担当する生徒の調査メモの日ごとの件数を返却 - 直近1ヶ月のデータを返却
     *
     * @tags Teacher/PblCardsInformationLiteracyBookmark
     * @name FetchPblCardBookmarkStatsAsTeacher
     * @summary 担当する生徒の調査メモの日ごとの件数を返却
     * @request GET:/teacher/pbl/cards/information_literacy/bookmarks/stats
     * @response `200` `{ bookmarks: (PblCardBookmarkStats)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchPblCardBookmarkStatsAsTeacher: (query: FetchPblCardBookmarkStatsAsTeacherParams, params?: RequestParams) =>
      this.request<{ bookmarks: PblCardBookmarkStats[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/pbl/cards/information_literacy/bookmarks/stats${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyComment
     * @name FetchPblCardBookmarkCommentsAsTeacher
     * @summary コメントの一覧を取得
     * @request GET:/teacher/pbl/cards/information_literacy/comments
     * @response `200` `{ pbl_card_comments?: (PblCardCommentBase)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchPblCardBookmarkCommentsAsTeacher: (
      query: FetchPblCardBookmarkCommentsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { pbl_card_comments?: PblCardCommentBase[]; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/cards/information_literacy/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyComment
     * @name CreatePblCardBookmarkCommentAsTeacher
     * @summary コメントの新規作成
     * @request POST:/teacher/pbl/cards/information_literacy/comments
     * @response `201` `PblCardCommentBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createPblCardBookmarkCommentAsTeacher: (data: { bookmark_id: string; body: string }, params?: RequestParams) =>
      this.request<
        PblCardCommentBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/cards/information_literacy/comments`, "POST", params, data),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyComment
     * @name UpdatePblCardBookmarkCommentAsTeacher
     * @summary コメントの更新
     * @request PUT:/teacher/pbl/cards/information_literacy/comments/{id}
     * @response `200` `PblCardCommentBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updatePblCardBookmarkCommentAsTeacher: (id: string, data: { body?: string }, params?: RequestParams) =>
      this.request<
        PblCardCommentBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/cards/information_literacy/comments/${id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyComment
     * @name DeletePblCardBookmarkCommentAsTeacher
     * @summary コメントの削除
     * @request DELETE:/teacher/pbl/cards/information_literacy/comments/{id}
     * @response `204` `any` No content
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deletePblCardBookmarkCommentAsTeacher: (id: string, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/pbl/cards/information_literacy/comments/${id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyBookmarkImage
     * @name DeletePblCardBookmarkImageAsTeacher
     * @summary BookmarkImageの削除
     * @request DELETE:/teacher/pbl/cards/information_literacy/bookmark_images/{id}
     * @response `204` `any` No content
     */
    deletePblCardBookmarkImageAsTeacher: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/teacher/pbl/cards/information_literacy/bookmark_images/${id}`, "DELETE", params),

    /**
     * No description
     *
     * @tags Teacher/Stamp
     * @name GetPblCardBookmarkStampsAsTeacher
     * @summary スタンプ一覧の取得
     * @request GET:/teacher/stamps
     * @response `200` `{ stamp_fields: (StampBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getPblCardBookmarkStampsAsTeacher: (query: GetPblCardBookmarkStampsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { stamp_fields: StampBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/stamps${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Teacher/Stamp
     * @name PostPblCardBookmarkStampAsTeacher
     * @summary スタンプの作成
     * @request POST:/teacher/stamps
     * @response `200` `StampBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postPblCardBookmarkStampAsTeacher: (
      data: { stampable_type: StampableType; stampable_id: string; actor_id: string; kind: StampType },
      params?: RequestParams,
    ) =>
      this.request<StampBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/stamps`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/Stamp
     * @name PutPblCardBookmarkStampAsTeacher
     * @summary スタンプの更新
     * @request PUT:/teacher/stamps/{id}
     * @response `200` `StampBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putPblCardBookmarkStampAsTeacher: (id: string, data: { kind: StampType }, params?: RequestParams) =>
      this.request<StampBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/stamps/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Teacher/Stamp
     * @name DeletePblCardBookmarkStampAsTeacher
     * @summary スタンプの削除
     * @request DELETE:/teacher/stamps/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deletePblCardBookmarkStampAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/stamps/${id}`, "DELETE", params),

    /**
     * @description Super Ownerが自社にタグ配信した履歴を返却する
     *
     * @tags Teacher/MaterialDistributionHistory
     * @name ListMaterialDistributionHistoryAsTeacher
     * @summary Super Ownerが自社にタグ配信した履歴を返却する
     * @request GET:/teacher/material_distribution_histories/list
     * @response `200` `{ material_distribution_histories: (MaterialDistributionHistory)[], total_count: number }` OK
     */
    listMaterialDistributionHistoryAsTeacher: (
      query: ListMaterialDistributionHistoryAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<{ material_distribution_histories: MaterialDistributionHistory[]; total_count: number }, any>(
        `/teacher/material_distribution_histories/list${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Teacherに関連する配信履歴の配信先詳細を返却する
     *
     * @tags Teacher/MaterialDistributionHistory
     * @name ListShowMaterialDistributionHistoryAsTeacher
     * @summary Teacherに関連する配信履歴の配信先詳細を返却する
     * @request GET:/teacher/material_distribution_histories/list_students
     * @response `200` `MaterialDistributionHistoryListStudents` OK
     */
    listShowMaterialDistributionHistoryAsTeacher: (
      query: ListShowMaterialDistributionHistoryAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<MaterialDistributionHistoryListStudents, any>(
        `/teacher/material_distribution_histories/list_students${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description UserIDから配信履歴を返却する
     *
     * @tags Teacher/MaterialDistributionHistory
     * @name SearchByUserIdMaterialDistributionHistoryAsTeacher
     * @summary UserIDから配信履歴を返却する
     * @request GET:/teacher/material_distribution_histories/search_by_user_id
     * @response `200` `{ material_distribution_histories: (MaterialDistributionHistorySearchByUserId)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    searchByUserIdMaterialDistributionHistoryAsTeacher: (
      query: SearchByUserIdMaterialDistributionHistoryAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { material_distribution_histories: MaterialDistributionHistorySearchByUserId[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/material_distribution_histories/search_by_user_id${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Pbl::Steam:Content を一覧を返却
     *
     * @tags Teacher/Steam/Contents
     * @name GetSteamContentsAsTeacher
     * @summary Steamライブラリーコンテンツの一覧を返却
     * @request GET:/teacher/pbl/steam/contents
     * @response `200` `{ steam_contents?: (PblSteamContent)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSteamContentsAsTeacher: (query: GetSteamContentsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { steam_contents?: PblSteamContent[]; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/steam/contents${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 Steamライブラリーのコンテンツの情報取得
     *
     * @tags Teacher/Steam/Contents
     * @name GetSteamContentAsTeacher
     * @summary Teacher: Steamライブラリーコンテンツの情報参照
     * @request GET:/teacher/pbl/steam/contents/{id}
     * @response `200` `StudentPblSteamContentDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getSteamContentAsTeacher: (id: string, params?: RequestParams) =>
      this.request<StudentPblSteamContentDetail, BadRequest | Unauthorized | Forbidden>(
        `/teacher/pbl/steam/contents/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Teacher限定 Steamライブラリーのコンテンツ一覧を返却
     *
     * @tags Teacher/Steam/Contents
     * @name SearchTeacherSteamContents
     * @summary Teacher: Steamライブラリーコンテンツ一覧の取得
     * @request GET:/teacher/pbl/steam/contents/search
     * @response `200` `{ steam_contents: (StudentPblSteamContentDetail)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    searchTeacherSteamContents: (query: SearchTeacherSteamContentsParams, params?: RequestParams) =>
      this.request<
        { steam_contents: StudentPblSteamContentDetail[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/teacher/pbl/steam/contents/search${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 Steamライブラリーのコンテンツのレクチャーに対するコメント一覧を返却
     *
     * @tags Teacher/Steam/Comments
     * @name GetTeacherSteamComments
     * @summary Teacher: Steamライブラリーコンテンツのレクチャー名に対するコメント一覧の取得
     * @request GET:/teacher/pbl/steam/comments
     * @response `200` `{ steam_content_comments?: (PblSteamCommentList)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getTeacherSteamComments: (query: GetTeacherSteamCommentsParams, params?: RequestParams) =>
      this.request<
        { steam_content_comments?: PblSteamCommentList[]; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 Steamライブラリーのコンテンツのレクチャーに対するコメントを登録
     *
     * @tags Teacher/Steam/Comments
     * @name PostTeacherSteamComments
     * @summary Teacher: Steamライブラリーコンテンツのレクチャーに対するコメントの登録
     * @request POST:/teacher/pbl/steam/comments
     * @response `201` `PblSteamCommentBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTeacherSteamComments: (
      query: PostTeacherSteamCommentsParams,
      data: { is_public?: boolean; body?: string },
      params?: RequestParams,
    ) =>
      this.request<
        PblSteamCommentBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/comments${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description Teacher限定 Steamライブラリーのコンテンツのレクチャーに対するコメントの情報の更新
     *
     * @tags Teacher/Steam/Comments
     * @name PutTeacherSteamContentsId
     * @summary Teacher: Steamライブラリーコンテンツのレクチャーに対するコメントの情報更新
     * @request PUT:/teacher/pbl/steam/comments/{id}
     * @response `202` `PblSteamCommentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTeacherSteamContentsId: (id: string, data: { is_public?: boolean; body?: string }, params?: RequestParams) =>
      this.request<
        PblSteamCommentBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/comments/${id}`, "PUT", params, data),

    /**
     * @description Teacher限定 Steamライブラリーのコンテンツの情報のレクチャーに対するコメントの削除
     *
     * @tags Teacher/Steam/Comments
     * @name DeleteTeacherSteamContentsId
     * @summary Teacher: Steamライブラリーコンテンツのレクチャーに対するコメントの削除
     * @request DELETE:/teacher/pbl/steam/comments/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteTeacherSteamContentsId: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/comments/${id}`, "DELETE", params),

    /**
     * @description Teacher限定 Steamライブラリーのレクチャーの一覧を取得
     *
     * @tags Teacher/Steam/Lectures
     * @name GetSteamLecturesAsTeacher
     * @summary Teacher: Steamライブラリーコンテンツのレクチャーの一覧を取得
     * @request GET:/teacher/pbl/steam/lectures
     * @response `200` `{ steam_lectures: (PblSteamLecture)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSteamLecturesAsTeacher: (query: GetSteamLecturesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { steam_lectures: PblSteamLecture[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/steam/lectures${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 Steamライブラリーのレクチャーを登録
     *
     * @tags Teacher/Steam/Lectures
     * @name PostTeacherSteamLectures
     * @summary Teacher: Steamライブラリーコンテンツのレクチャーを登録
     * @request POST:/teacher/pbl/steam/lectures
     * @response `200` `{ is_registered: boolean }` OK
     * @response `201` `PblSteamLecture` Created
     * @response `202` `PblSteamLecture` Accepted
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postTeacherSteamLectures: (
      query: PostTeacherSteamLecturesParams,
      data: CreateSteamLectureRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ is_registered: boolean }, Unauthorized | Forbidden>(
        `/teacher/pbl/steam/lectures${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Teacher限定 Steamライブラリーのコンテンツのレクチャー名の情報取得
     *
     * @tags Teacher/Steam/Lectures
     * @name GetTeacherSteamLecturesId
     * @summary Teacher: Steamライブラリーコンテンツのレクチャー名の情報参照
     * @request GET:/teacher/pbl/steam/lectures/{id}
     * @response `200` `PblSteamLecture` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherSteamLecturesId: (id: string, params?: RequestParams) =>
      this.request<PblSteamLecture, BadRequest | Unauthorized | Forbidden>(
        `/teacher/pbl/steam/lectures/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Teacher限定 Steamライブラリーのレクチャーに対するコメント一覧を返却
     *
     * @tags Teacher/Steam/Lectures
     * @name GetTeacherSteamLectureComments
     * @summary Teacher: Steamライブラリーのレクチャーに対するコメント一覧の取得
     * @request GET:/teacher/pbl/steam/lectures/{id}/comments
     * @response `200` `{ teacher_comments?: (PblSteamCommentBase)[], student_comments?: (PblSteamCommentBase)[], teacher_comments_total_count?: number, student_comments_total_count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherSteamLectureComments: ({ id, ...query }: GetTeacherSteamLectureCommentsParams, params?: RequestParams) =>
      this.request<
        {
          teacher_comments?: PblSteamCommentBase[];
          student_comments?: PblSteamCommentBase[];
          teacher_comments_total_count?: number;
          student_comments_total_count?: number;
        },
        Unauthorized | Forbidden
      >(`/teacher/pbl/steam/lectures/${id}/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Steamライブラリーのコンテンツのブックマーク一覧を返却
     *
     * @tags Teacher/Steam/ContentBookmarks
     * @name GetSteamContentBookmarksAsTeacher
     * @summary Steamライブラリーのコンテンツのブックマーク一覧を返却
     * @request GET:/teacher/pbl/steam/content_bookmarks
     * @response `200` `{ bookmarks: (ContentBookmarkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getSteamContentBookmarksAsTeacher: (query: GetSteamContentBookmarksAsTeacherParams, params?: RequestParams) =>
      this.request<
        { bookmarks: ContentBookmarkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/content_bookmarks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Steamライブラリーのコンテンツのブックマーク を登録
     *
     * @tags Teacher/Steam/ContentBookmarks
     * @name PostSteamContentBookmarksAsTeacher
     * @summary Steamライブラリーのコンテンツのブックマーク を登録
     * @request POST:/teacher/pbl/steam/content_bookmarks
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postSteamContentBookmarksAsTeacher: (data: { pbl_steam_content_id: string }, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/content_bookmarks`, "POST", params, data),

    /**
     * @description - Teacher限定 Steamライブラリーのコンテンツのブックマークを削除
     *
     * @tags Teacher/Steam/ContentBookmarks
     * @name DeleteSteamContentBookmarksAsTeacher
     * @summary Steamライブラリーのコンテンツのブックマークを削除
     * @request DELETE:/teacher/pbl/steam/content_bookmarks/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteSteamContentBookmarksAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/pbl/steam/content_bookmarks/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Pbl::Steam::StudentContent を一覧を返却
     *
     * @tags Teacher/Steam/StudentContents
     * @name GetPblSteamStudentContentsAsTeacher
     * @summary Steamライブラリーコンテンツと生徒を紐づける情報 一覧を返却
     * @request GET:/teacher/pbl/steam/student_contents
     * @response `200` `{ steam_student_contents: (StudentContentList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getPblSteamStudentContentsAsTeacher: (query: GetPblSteamStudentContentsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { steam_student_contents: StudentContentList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/student_contents${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Pbl::Steam::StudentContent を登録
     *
     * @tags Teacher/Steam/StudentContents
     * @name PostPblSteamStudentContentAsTeacher
     * @summary Steamライブラリーコンテンツと生徒を紐づける情報 を登録
     * @request POST:/teacher/pbl/steam/student_contents
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postPblSteamStudentContentAsTeacher: (
      data: { student_affiliate_id: string; sl_content_id: number },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/student_contents`, "POST", params, data),

    /**
     * @description - Teacher限定 Pbl::Steam::StudentContent を更新
     *
     * @tags Teacher/Steam/StudentContents
     * @name PutPblSteamStudentContentAsTeacher
     * @summary Steamライブラリーコンテンツと生徒を紐づける情報 を更新
     * @request PUT:/teacher/pbl/steam/student_contents/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putPblSteamStudentContentAsTeacher: (
      id: string,
      data: { student_affiliate_id: string; sl_content_id: number },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/student_contents/${id}`, "PUT", params, data),

    /**
     * @description - Teacher限定 Pbl::Steam::StudentContent を削除
     *
     * @tags Teacher/Steam/StudentContents
     * @name DeletePblSteamStudentContentAsTeacher
     * @summary Steamライブラリーコンテンツと生徒を紐づける情報 を削除
     * @request DELETE:/teacher/pbl/steam/student_contents/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deletePblSteamStudentContentAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/steam/student_contents/${id}`, "DELETE", params),

    /**
     * @description - Teacher限定 Steamライブラリーのおすすめコンテンツ一覧を返却
     *
     * @tags Teacher/Steam/ContentRecommendations
     * @name GetSteamContentRecommendationsAsTeacher
     * @summary Steamライブラリーのおすすめコンテンツの一覧を返却
     * @request GET:/teacher/pbl/steam/content_recommendations
     * @response `200` `{ steam_content_recommendations: (ContentRecommendationBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSteamContentRecommendationsAsTeacher: (
      query: GetSteamContentRecommendationsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { steam_content_recommendations: ContentRecommendationBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/steam/content_recommendations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Steamライブラリーのおすすめコンテンツ を登録
     *
     * @tags Teacher/Steam/ContentRecommendations
     * @name PostSteamContentRecommendationAsTeacher
     * @summary Steamライブラリーのおすすめコンテンツ を登録
     * @request POST:/teacher/pbl/steam/content_recommendations
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postSteamContentRecommendationAsTeacher: (data: { pbl_steam_content_id: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/pbl/steam/content_recommendations`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Steamライブラリーのおすすめコンテンツを削除
     *
     * @tags Teacher/Steam/ContentRecommendations
     * @name DeleteSteamContentRecommendationAsTeacher
     * @summary Steamライブラリーのおすすめコンテンツを削除
     * @request DELETE:/teacher/pbl/steam/content_recommendations/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteSteamContentRecommendationAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/pbl/steam/content_recommendations/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Teacher限定 探究学習授業計画の一覧を返却
     *
     * @tags Teacher/Plan/Items
     * @name GetTeacherPlanItems
     * @summary Teacher: 探究学習授業計画の一覧を返却
     * @request GET:/teacher/pbl/plan/items
     * @response `200` `{ plan_items: (TeacherPblPlanItemDetails)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getTeacherPlanItems: (query: GetTeacherPlanItemsParams, params?: RequestParams) =>
      this.request<
        { plan_items: TeacherPblPlanItemDetails[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 探究学習授業計画の登録
     *
     * @tags Teacher/Plan/Items
     * @name PostTeacherPlanItems
     * @summary Teacher: 探究学習授業計画の登録
     * @request POST:/teacher/pbl/plan/items
     * @response `201` `TeacherPblPlanItemDetails` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTeacherPlanItems: (
      data: {
        class_minutes?: number;
        plan_years?: number;
        class_start_date?: string;
        class_end_date?: string;
        policy_summary?: string;
        why?: string;
        how?: string;
        what?: string;
        first_year_summary?: string;
        second_year_summary?: string;
        third_year_summary?: string;
        status?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<
        TeacherPblPlanItemDetails,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items`, "POST", params, data),

    /**
     * @description Teacher限定 探究学習授業計画の情報取得
     *
     * @tags Teacher/Plan/Items
     * @name GetTeacherPlanItemsId
     * @summary Teacher: 探究学習授業計画の情報参照
     * @request GET:/teacher/pbl/plan/items/{id}
     * @response `200` `TeacherPblPlanItemDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getTeacherPlanItemsId: (id: string, params?: RequestParams) =>
      this.request<
        TeacherPblPlanItemDetails,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items/${id}`, "GET", params),

    /**
     * @description Teacher限定 探究学習授業計画の情報の更新
     *
     * @tags Teacher/Plan/Items
     * @name PutTeacherPlanItemsId
     * @summary Teacher: 探究学習授業計画の情報更新
     * @request PUT:/teacher/pbl/plan/items/{id}
     * @response `202` `TeacherPblPlanItemDetails` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTeacherPlanItemsId: (
      id: string,
      data: {
        class_minutes?: number;
        plan_years?: number;
        class_start_date?: string;
        class_end_date?: string;
        policy_summary?: string;
        why?: string;
        how?: string;
        what?: string;
        first_year_summary?: string;
        second_year_summary?: string;
        third_year_summary?: string;
        status?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<
        TeacherPblPlanItemDetails,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items/${id}`, "PUT", params, data),

    /**
     * @description "Teacher限定 探究学習授業計画の情報の削除" "削除対象が多いので、非同期で処理"
     *
     * @tags Teacher/Plan/Items
     * @name DeleteTeacherPlanItemsId
     * @summary Teacher: 探究学習授業計画の削除
     * @request DELETE:/teacher/pbl/plan/items/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteTeacherPlanItemsId: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items/${id}`, "DELETE", params),

    /**
     * @description Teacher限定 開始日と終了日と曜日から曜日と日付の情報を返却する
     *
     * @tags Teacher/Plan/Items
     * @name GetTeacherGetClassDateList
     * @summary Teacher: 開始日と終了日と曜日から曜日と日付の情報を返却
     * @request GET:/teacher/pbl/plan/items/get_class_date_list
     * @response `200` `{ days?: { year?: ({ wday?: number, date?: string })[] } }` OK
     * @response `400` `BadRequest`
     */
    getTeacherGetClassDateList: (query: GetTeacherGetClassDateListParams, params?: RequestParams) =>
      this.request<{ days?: { year?: { wday?: number; date?: string }[] } }, BadRequest>(
        `/teacher/pbl/plan/items/get_class_date_list${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 探究学習授業計画のカレンダー情報一覧
     *
     * @tags Teacher/Plan/Classes
     * @name GetTeacherPlanClasses
     * @summary 探究学習授業計画のカレンダー情報一覧
     * @request GET:/teacher/pbl/plan/items/{item_id}/classes
     * @response `200` `{ plan_classes: (TeacherPblPlanClass)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherPlanClasses: ({ item_id, ...query }: GetTeacherPlanClassesParams, params?: RequestParams) =>
      this.request<
        { plan_classes: TeacherPblPlanClass[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/plan/items/${item_id}/classes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 探究学習授業計画のカレンダー情報を登録
     *
     * @tags Teacher/Plan/Classes
     * @name PostTeacherPlanClasses
     * @summary Teacher: 探究学習授業計画のカレンダー情報を登録
     * @request POST:/teacher/pbl/plan/items/{item_id}/classes
     * @response `201` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postTeacherPlanClasses: (
      item_id: string,
      data: {
        plan_classes?: {
          year: number;
          number: number;
          date: string;
          description: string;
          is_skip: boolean;
          article_creation_time: boolean;
          lecture_id?: string;
          class_minutes?: number;
          plan_contents?: {
            categorizable_type: PlanContentsCategorizableType;
            categorizable_id: string;
            categorizable_title?: string;
          }[];
          title?: string;
        }[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/teacher/pbl/plan/items/${item_id}/classes`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Teacher限定 探究学習授業計画のカレンダー情報の情報更新
     *
     * @tags Teacher/Plan/Classes
     * @name PutTeacherPlanClassesId
     * @summary Teacher: 探究学習授業計画のカレンダー情報の情報更新
     * @request PUT:/teacher/pbl/plan/items/{item_id}/classes/{id}
     * @response `202` `TeacherPblPlanClass` Accepted
     * @response `400` `BadRequest`
     */
    putTeacherPlanClassesId: (
      item_id: string,
      id: string,
      data: {
        year?: number;
        number?: number;
        date?: string;
        description?: string;
        is_skip?: boolean;
        article_creation_time?: boolean;
        schedule_id?: string;
        class_minutes?: number;
        title?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<TeacherPblPlanClass, BadRequest>(
        `/teacher/pbl/plan/items/${item_id}/classes/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Teacher限定 探究学習授業計画のカレンダー情報の情報削除
     *
     * @tags Teacher/Plan/Classes
     * @name DeleteTeacherPlanClassesId
     * @summary Teacher: 探究学習授業計画のカレンダー情報の情報削除
     * @request DELETE:/teacher/pbl/plan/items/{item_id}/classes/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteTeacherPlanClassesId: (item_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/pbl/plan/items/${item_id}/classes/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 探究学習授業計画のカレンダー情報一覧
     *
     * @tags Teacher/Plan/Classes
     * @name GetPblPlanContentsAsTeacher
     * @summary 探究学習授業計画のカレンダー情報一覧
     * @request GET:/teacher/pbl/plan/items/{item_id}/classes/{class_id}/contents
     * @response `200` `{ plan_contents: (PblPlanContentNav)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblPlanContentsAsTeacher: (
      { item_id, class_id, ...query }: GetPblPlanContentsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { plan_contents: PblPlanContentNav[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/plan/items/${item_id}/classes/${class_id}/contents${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 探究学習授業計画のカレンダー情報にコンテンツの情報を登録
     *
     * @tags Teacher/Plan/Contents
     * @name PostPblPlanContentsAsTeacher
     * @summary 探究学習授業計画のカレンダー情報にコンテンツの情報を登録
     * @request POST:/teacher/pbl/plan/items/{item_id}/classes/{class_id}/contents
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblPlanContentsAsTeacher: (
      item_id: string,
      class_id: string,
      data: { plan_contents: { categorizable_type: PlanContentsCategorizableType; categorizable_id: string }[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/pbl/plan/items/${item_id}/classes/${class_id}/contents`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 探究学習授業計画のカレンダー情報のコンテンツの情報を削除
     *
     * @tags Teacher/Plan/Contents
     * @name DeletePblPlanContentsAsTeacher
     * @summary 探究学習授業計画のカレンダー情報のコンテンツの情報を削除
     * @request DELETE:/teacher/pbl/plan/items/{item_id}/classes/{class_id}/contents/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblPlanContentsAsTeacher: (item_id: string, class_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/pbl/plan/items/${item_id}/classes/${class_id}/contents/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Teacher限定 探究学習授業計画の授業曜日／開始時間を登録
     *
     * @tags Teacher/Plan/Days
     * @name PostTeacherPlanDays
     * @summary Teacher: 探究学習授業計画の授業曜日／開始時間を登録
     * @request POST:/teacher/pbl/plan/items/{item_id}/days
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTeacherPlanDays: (
      item_id: string,
      data: { days?: { class_date?: PblPlanDayType; start_time?: string }[] },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items/${item_id}/days`, "POST", params, data),

    /**
     * @description Teacher限定 探究学習授業計画の授業曜日／開始時間の情報更新
     *
     * @tags Teacher/Plan/Days
     * @name PutTeacherPlanDaysId
     * @summary Teacher: 探究学習授業計画の授業曜日／開始時間の情報更新
     * @request PUT:/teacher/pbl/plan/items/{item_id}/days/{id}
     * @response `202` `TeacherPblPlanDay` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTeacherPlanDaysId: (
      item_id: string,
      id: string,
      data: { class_date?: PblPlanDayType; start_time?: string },
      params?: RequestParams,
    ) =>
      this.request<
        TeacherPblPlanDay,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items/${item_id}/days/${id}`, "PUT", params, data),

    /**
     * @description Teacher限定 探究学習授業計画の授業曜日／開始時間の情報の削除
     *
     * @tags Teacher/Plan/Days
     * @name DeleteTeacherPlanDaysId
     * @summary Teacher: 探究学習授業計画の授業曜日／開始時間の情報の削除
     * @request DELETE:/teacher/pbl/plan/items/{item_id}/days/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteTeacherPlanDaysId: (item_id: string, id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items/${item_id}/days/${id}`, "DELETE", params),

    /**
     * @description - Teacher限定 探究学習授業計画の生徒一覧
     *
     * @tags Teacher/Plan/Students
     * @name GetTeacherPlanStudents
     * @summary 探究学習授業計画の生徒一覧
     * @request GET:/teacher/pbl/plan/items/{item_id}/students
     * @response `200` `{ plan_students: (TeacherPblPlanStudent)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTeacherPlanStudents: ({ item_id, ...query }: GetTeacherPlanStudentsParams, params?: RequestParams) =>
      this.request<
        { plan_students: TeacherPblPlanStudent[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/plan/items/${item_id}/students${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 探究学習授業計画の受講生とを登録
     *
     * @tags Teacher/Plan/Students
     * @name PostTeacherPlanStudents
     * @summary Teacher: 探究学習授業計画の受講を登録
     * @request POST:/teacher/pbl/plan/items/{item_id}/students
     * @response `201` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTeacherPlanStudents: (item_id: string, data: { student_ids?: string[] }, params?: RequestParams) =>
      this.request<
        { message?: string },
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items/${item_id}/students`, "POST", params, data),

    /**
     * @description Teacher限定 探究学習授業計画の受講生徒の情報更新
     *
     * @tags Teacher/Plan/Students
     * @name PutTeacherPlanStudentsId
     * @summary Teacher: 探究学習授業計画の受講生徒の情報更新
     * @request PUT:/teacher/pbl/plan/items/{item_id}/students/{id}
     * @response `202` `TeacherPblPlanStudent` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTeacherPlanStudentsId: (item_id: string, id: string, data: { student_id?: string }, params?: RequestParams) =>
      this.request<
        TeacherPblPlanStudent,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items/${item_id}/students/${id}`, "PUT", params, data),

    /**
     * @description Teacher限定 探究学習授業計画の受講生徒の情報の削除
     *
     * @tags Teacher/Plan/Students
     * @name DeleteTeacherPlanStudentsId
     * @summary Teacher: 探究学習授業計画の受講生徒の情報の削除
     * @request DELETE:/teacher/pbl/plan/items/{item_id}/students/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteTeacherPlanStudentsId: (item_id: string, id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/plan/items/${item_id}/students/${id}`, "DELETE", params),

    /**
     * @description Teacher限定 お問い合わせ一覧を返却
     *
     * @tags Teacher/Inquiries
     * @name GetTeacherInquiries
     * @summary Teacher: お問い合わせ一覧を返却
     * @request GET:/teacher/inquiries
     * @response `200` `{ inquiries: (InquirySimpleListBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherInquiries: (query: GetTeacherInquiriesParams, params?: RequestParams) =>
      this.request<{ inquiries: InquirySimpleListBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/teacher/inquiries${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Teacher限定 お問い合わせ情報取得
     *
     * @tags Teacher/Inquiries
     * @name GetTeacherInquiriesId
     * @summary Teacher: 提出物のお問い合わせ情報参照
     * @request GET:/teacher/inquiries/{id}
     * @response `200` `InquirySimpleBase` OK
     * @response `400` `BadRequest`
     */
    getTeacherInquiriesId: (id: string, params?: RequestParams) =>
      this.request<InquirySimpleBase, BadRequest>(`/teacher/inquiries/${id}`, "GET", params),

    /**
     * @description Teacher限定 新着コメントが存在するお問い合わせの件数を返却
     *
     * @tags Teacher/Inquiries
     * @name GetTheNumberOfNewCommentInquiriesAsTeacher
     * @summary 新着コメントが存在するお問い合わせの件数を返却
     * @request GET:/teacher/inquiries/new_comment_count
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTheNumberOfNewCommentInquiriesAsTeacher: (params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/teacher/inquiries/new_comment_count`,
        "GET",
        params,
      ),

    /**
     * @description Teacher限定 お問い合わせコメントの登録
     *
     * @tags Teacher/InquiryComments
     * @name PostTeacherInquiryComments
     * @summary Teacher: お問い合わせコメントの登録
     * @request POST:/teacher/inquiry_comments
     * @response `201` `InquiryComment` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postTeacherInquiryComments: (
      query: PostTeacherInquiryCommentsParams,
      data: { body: string },
      params?: RequestParams,
    ) =>
      this.request<InquiryComment, Unauthorized | Forbidden>(
        `/teacher/inquiry_comments${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Teacher限定 お問い合わせコメントの情報の更新
     *
     * @tags Teacher/InquiryComments
     * @name PutTeacherInquiryCommentsId
     * @summary Teacher: お問い合わせコメントの情報更新
     * @request PUT:/teacher/inquiry_comments/{id}
     * @response `202` `InquiryComment` Accepted
     * @response `400` `BadRequest`
     */
    putTeacherInquiryCommentsId: (
      { id, ...query }: PutTeacherInquiryCommentsIdParams,
      data: { body: string; status?: string },
      params?: RequestParams,
    ) =>
      this.request<InquiryComment, BadRequest>(
        `/teacher/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Teacher限定 お問い合わせコメントの情報の削除
     *
     * @tags Teacher/InquiryComments
     * @name DeleteTeacherInquiryCommentsId
     * @summary Teacher: お問い合わせコメントの削除
     * @request DELETE:/teacher/inquiry_comments/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteTeacherInquiryCommentsId: ({ id, ...query }: DeleteTeacherInquiryCommentsIdParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description Teacher限定 企業一覧(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags Teacher/Sponsor/Infos
     * @name GetTeacherSponsorInfos
     * @summary 企業一覧を返却
     * @request GET:/teacher/sponsor/infos
     * @response `200` `{ sponsors: (SponsorInfoListBase)[], total_count: number, all_sponsor_names?: (string)[], all_sponsor_tags?: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherSponsorInfos: (query: GetTeacherSponsorInfosParams, params?: RequestParams) =>
      this.request<
        {
          sponsors: SponsorInfoListBase[];
          total_count: number;
          all_sponsor_names?: string[];
          all_sponsor_tags?: string[];
        },
        BadRequest | Unauthorized | Forbidden
      >(`/teacher/sponsor/infos${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 企業の詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags Teacher/Sponsor/Infos
     * @name GetTeacherSponsorInfosId
     * @summary 企業の詳細情報を返却
     * @request GET:/teacher/sponsor/infos/{id}
     * @response `200` `SponsorInfoDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherSponsorInfosId: (id: string, params?: RequestParams) =>
      this.request<SponsorInfoDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/teacher/sponsor/infos/${id}`,
        "GET",
        params,
      ),

    /**
     * @description 企業プロジェクトの一覧を返却。現状はブックマークしているプロジェクトの取得のみに利用
     *
     * @tags Teacher/Sponsor/Projects
     * @name FetchSponsorProjectsAsTeacher
     * @summary 企業プロジェクトの一覧を返却
     * @request GET:/teacher/sponsor/projects
     * @response `200` `{ projects: (SponsorProjectAndTagsBase)[], custom_content?: (ModelBase & { title: string }), total_count: number, all_project_names?: (string)[], all_project_tags?: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectsAsTeacher: (query: FetchSponsorProjectsAsTeacherParams, params?: RequestParams) =>
      this.request<
        {
          projects: SponsorProjectAndTagsBase[];
          custom_content?: ModelBase & { title: string };
          total_count: number;
          all_project_names?: string[];
          all_project_tags?: string[];
        },
        BadRequest | Unauthorized | Forbidden
      >(`/teacher/sponsor/projects${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 企業プロジェクトの詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags Teacher/Sponsor/Projects
     * @name FetchOneSponsorProjectAsTeacher
     * @summary 企業プロジェクトの詳細情報を返却
     * @request GET:/teacher/sponsor/projects/{id}
     * @response `200` `SponsorProjectDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchOneSponsorProjectAsTeacher: (id: string, params?: RequestParams) =>
      this.request<SponsorProjectDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/teacher/sponsor/projects/${id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Teacher/Sponsor/Projects
     * @name FetchSponsorProjectsForCarouselAsTeacher
     * @summary カルーセル表示用企業プロジェクトを取得
     * @request GET:/teacher/sponsor/projects/carousel
     * @response `200` `{ projects: (SponsorProjectBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectsForCarouselAsTeacher: (
      query: FetchSponsorProjectsForCarouselAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<{ projects: SponsorProjectBase[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/sponsor/projects/carousel${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 大学先生に紐づいている企業プロジェクトの一覧を返却。
     *
     * @tags Teacher/Sponsor/Projects
     * @name FetchSponsorProjectListAsTeacher
     * @summary 大学先生に紐づいている企業プロジェクトの一覧を返却
     * @request GET:/teacher/sponsor/projects/list
     * @response `200` `{ projects: (HasIdAndName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectListAsTeacher: (query: FetchSponsorProjectListAsTeacherParams, params?: RequestParams) =>
      this.request<{ projects: HasIdAndName[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/sponsor/projects/list${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 企業プロジェクトのレクチャーの一覧を返却
     *
     * @tags Teacher/Sponsor/Lectures
     * @name FetchSponsorLecturesAsTeacher
     * @summary 企業プロジェクトのレクチャーの一覧を返却
     * @request GET:/teacher/sponsor/projects/lectures
     * @response `200` `{ project_lectures: (SponsorLectureDetailsBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLecturesAsTeacher: (query: FetchSponsorLecturesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { project_lectures: SponsorLectureDetailsBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/teacher/sponsor/projects/lectures${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 企業プロジェクトのレクチャーの詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags Teacher/Sponsor/Lectures
     * @name FetchSponsorLectureAsTeacher
     * @summary 企業プロジェクトのレクチャーの詳細情報を返却
     * @request GET:/teacher/sponsor/projects/lectures/{id}
     * @response `200` `SponsorLectureDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLectureAsTeacher: (id: string, params?: RequestParams) =>
      this.request<SponsorLectureDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/teacher/sponsor/projects/lectures/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Teacher限定 企業プロジェクトのレクチャーのコメントの一覧を返却
     *
     * @tags Teacher/Sponsor/CommentList
     * @name FetchSponsorCommentListAsTeacher
     * @summary 企業プロジェクトのレクチャーのコメントを返却
     * @request GET:/teacher/sponsor/projects/lectures/{id}/comments
     * @response `200` `{ teacher_comments: (PblProjectCommentBase)[], student_comments: (PblProjectCommentBase)[], current_user_comment: PblProjectCommentBase, teacher_comments_total_count: number, student_comments_total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorCommentListAsTeacher: (
      { id, ...query }: FetchSponsorCommentListAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          teacher_comments: PblProjectCommentBase[];
          student_comments: PblProjectCommentBase[];
          current_user_comment: PblProjectCommentBase;
          teacher_comments_total_count: number;
          student_comments_total_count: number;
        },
        BadRequest | Unauthorized | Forbidden
      >(`/teacher/sponsor/projects/lectures/${id}/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 企業プロジェクトのブックマーク一覧を返却
     *
     * @tags Teacher/Sponsor/Bookmarks
     * @name FetchSponsorProjectBookmarksAsTeacher
     * @summary 企業プロジェクトのブックマーク一覧を返却
     * @request GET:/teacher/sponsor/bookmarks
     * @response `200` `{ bookmarks: (SponsorProjectBookmarkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectBookmarksAsTeacher: (
      query: FetchSponsorProjectBookmarksAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { bookmarks: SponsorProjectBookmarkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/teacher/sponsor/bookmarks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 企業プロジェクトのブックマークを追加
     *
     * @tags Teacher/Sponsor/Bookmarks
     * @name AddSponsorProjectBookmarkAsTeacher
     * @summary 企業プロジェクトのブックマークを追加
     * @request POST:/teacher/sponsor/bookmarks
     * @response `201` `SponsorProjectBookmarkBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    addSponsorProjectBookmarkAsTeacher: (data: { project_id: string }, params?: RequestParams) =>
      this.request<SponsorProjectBookmarkBase, BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/teacher/sponsor/bookmarks`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Teacher限定 企業プロジェクトのブックマークを削除
     *
     * @tags Teacher/Sponsor/Bookmarks
     * @name FetchSponsorProjectBookmarkByProjectAsTeacher
     * @summary 企業プロジェクトのブックマークをプロジェクトとCurrentUserから取得
     * @request GET:/teacher/sponsor/bookmarks/fetch_by_project
     * @response `200` `({ id?: string | null } & SponsorProjectBookmarkBase)` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchSponsorProjectBookmarkByProjectAsTeacher: (
      query: FetchSponsorProjectBookmarkByProjectAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { id?: string | null } & SponsorProjectBookmarkBase,
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/sponsor/bookmarks/fetch_by_project${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 企業プロジェクトのブックマークを削除
     *
     * @tags Teacher/Sponsor/Bookmarks
     * @name RemoveSponsorProjectBookmarkAsTeacher
     * @summary 企業プロジェクトのブックマークを削除
     * @request DELETE:/teacher/sponsor/bookmarks/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    removeSponsorProjectBookmarkAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/sponsor/bookmarks/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Teacher限定 企業プロジェクトのおすすめ一覧を返却
     *
     * @tags Teacher/Sponsor/Recommends
     * @name FetchSponsorProjectRecommendsAsTeacher
     * @summary 企業プロジェクトのおすすめ一覧を返却
     * @request GET:/teacher/sponsor/recommends
     * @response `200` `{ recommends: (SponsorProjectRecommendBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectRecommendsAsTeacher: (
      query: FetchSponsorProjectRecommendsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { recommends: SponsorProjectRecommendBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/teacher/sponsor/recommends${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Teacher限定 企業プロジェクトのおすすめを追加
     *
     * @tags Teacher/Sponsor/Recommends
     * @name AddSponsorProjectRecommendAsTeacher
     * @summary 企業プロジェクトのおすすめを追加
     * @request POST:/teacher/sponsor/recommends
     * @response `201` `SponsorProjectRecommendBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    addSponsorProjectRecommendAsTeacher: (data: { project_id: string }, params?: RequestParams) =>
      this.request<SponsorProjectRecommendBase, BadRequest | Unauthorized | Forbidden>(
        `/teacher/sponsor/recommends`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Teacher限定 企業プロジェクトのおすすめを削除
     *
     * @tags Teacher/Sponsor/Recommends
     * @name RemoveSponsorProjectRecommendAsTeacher
     * @summary 企業プロジェクトのおすすめを削除
     * @request DELETE:/teacher/sponsor/recommends/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    removeSponsorProjectRecommendAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/sponsor/recommends/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description 企業プロジェクトの生徒コメント一覧を返却
     *
     * @tags Teacher/Sponsor/Comments
     * @name FetchSponsorCommentsAsTeacher
     * @summary 企業プロジェクトの生徒コメント一覧を返却
     * @request GET:/teacher/sponsor/comments
     * @response `200` `{ sponsor_lecture_comments: (TeacherPblProjectCommentList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorCommentsAsTeacher: (query: FetchSponsorCommentsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { sponsor_lecture_comments: TeacherPblProjectCommentList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/teacher/sponsor/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Teacher限定 企業プロジェクトのコメントを登録"
     *
     * @tags Teacher/Sponsor/Comments
     * @name PostSponsorCommentsAsTeacher
     * @summary 企業プロジェクトのコメントを登録
     * @request POST:/teacher/sponsor/comments
     * @response `201` `PblProjectCommentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postSponsorCommentsAsTeacher: (
      query: PostSponsorCommentsAsTeacherParams,
      data: { body: string },
      params?: RequestParams,
    ) =>
      this.request<
        PblProjectCommentBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/sponsor/comments${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * No description
     *
     * @tags Teacher/Sponsor/Comments
     * @name SponsorCommentsDelete
     * @request DELETE:/teacher/sponsor/comments
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    sponsorCommentsDelete: (query: SponsorCommentsDeleteParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/sponsor/comments${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - "Teacher限定 企業プロジェクトのレクチャーコメントを更新" - "コメント1"
     *
     * @tags Teacher/Pbl/Sponsor/Comment
     * @name PutPblSponsorCommentAsTeacher
     * @summary 企業プロジェクトのレクチャーコメントを更新
     * @request PUT:/teacher/sponsor/comments/{id}
     * @response `202` `PblProjectCommentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putPblSponsorCommentAsTeacher: (id: string, data: { body: string }, params?: RequestParams) =>
      this.request<PblProjectCommentBase, BadRequest | Unauthorized | Forbidden>(
        `/teacher/sponsor/comments/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "Teacher限定 企業プロジェクトのレクチャーコメントを削除" - "コメント1"
     *
     * @tags Teacher/Pbl/Sponsor/Comment
     * @name DeletePblSponsorCommentAsTeacher
     * @summary 企業プロジェクトのレクチャーコメントを削除
     * @request DELETE:/teacher/sponsor/comments/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblSponsorCommentAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/sponsor/comments/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Teacher限定 探究成果物のレビューに対象されているコンテンツ事業者を返却
     *
     * @tags Teacher/ContentCompanies
     * @name GetTeacherContentCompanies
     * @summary コンテンツ事業者一覧を返却
     * @request GET:/teacher/content_companies
     * @response `200` `{ content_companies: (CompanyBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTeacherContentCompanies: (query: GetTeacherContentCompaniesParams, params?: RequestParams) =>
      this.request<{ content_companies: CompanyBase[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/content_companies${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 直近のスケジュール一覧を返却 - limitで件数を変更可能（デフォルト5件）
     *
     * @tags Teacher/Schedules
     * @name GetSchedulesAsTeacher
     * @summary 直近のスケジュール一覧を返却
     * @request GET:/teacher/schedules
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSchedulesAsTeacher: (query: GetSchedulesAsTeacherParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/schedules${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 StudyLogの一覧を返却 - 担当している生徒のみ
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsStudyLogsAsTeacher
     * @summary 学習ログの一覧を返却
     * @request GET:/teacher/student_logs/study_logs
     * @response `200` `{ study_logs: (StudyLogBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsStudyLogsAsTeacher: (query: GetStudentLogsStudyLogsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { study_logs: StudyLogBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/study_logs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 StudyLogの一覧を返却 - 各生徒のStudyLogを5件返却
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsStudyByStudentAsTeacher
     * @summary 各生徒の学習ログの一覧を返却
     * @request GET:/teacher/student_logs/study_by_students
     * @response `200` `{ study_logs: (StudyByStudentBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsStudyByStudentAsTeacher: (
      query: GetStudentLogsStudyByStudentAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { study_logs: StudyByStudentBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/study_by_students${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 生徒の学習ログと日報と日報のcategory毎の件数を返却 - 担当している生徒のみ - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsStatsAsTeacher
     * @summary 生徒の学習ログと日報と日報のcategory毎の件数を返却
     * @request GET:/teacher/student_logs/stats
     * @response `200` `{ study_logs: (StudyLogBase)[], daily_log: DailyLogStatsBase, total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsStatsAsTeacher: (query: GetStudentLogsStatsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { study_logs: StudyLogBase[]; daily_log: DailyLogStatsBase; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/stats${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 担当生徒の日報のcategory毎の件数を先週・今週の二種類返却 - 担当している生徒のみ
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsActivityAsTeacher
     * @summary 担当生徒の日報のcategory毎の件数を先週・今週の二種類返却
     * @request GET:/teacher/student_logs/activity
     * @response `200` `{ this_week_daily_logs: (ActivityBase)[], last_week_daily_logs: (ActivityBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsActivityAsTeacher: (query: GetStudentLogsActivityAsTeacherParams, params?: RequestParams) =>
      this.request<
        { this_week_daily_logs: ActivityBase[]; last_week_daily_logs: ActivityBase[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/activity${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 生徒の成果物の件数を返却 - 4/1 から 3/31 の範囲を取得（デフォルト） - プログラム、探究成果、探究レポート、提出物、受講中の教材、あいである、調査メモの件数を返却
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsDeliverableListAsTeacher
     * @summary 生徒の成果物の件数を返却
     * @request GET:/teacher/student_logs/deliverable_list
     * @response `200` `{ study_logs: (DeliverableListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsDeliverableListAsTeacher: (
      query: GetStudentLogsDeliverableListAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { study_logs: DeliverableListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/deliverable_list${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 指定した生徒の受講中の教材の一覧を返却 - student_affiliate_idは必須 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsMaterialWorksAsTeacher
     * @summary 指定した生徒の受講中の教材の一覧を返却
     * @request GET:/teacher/student_logs/material_works
     * @response `200` `{ material_works: (StudentLogDeliverableMaterialWorkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsMaterialWorksAsTeacher: (query: GetStudentLogsMaterialWorksAsTeacherParams, params?: RequestParams) =>
      this.request<
        { material_works: StudentLogDeliverableMaterialWorkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/material_works${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 指定した生徒の提出物の一覧を返却 - student_affiliate_idは必須 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsHomeworksAsTeacher
     * @summary 指定した生徒の提出物の一覧を返却
     * @request GET:/teacher/student_logs/homeworks
     * @response `200` `{ homeworks: (StudentLogDeliverableHomeWorkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsHomeworksAsTeacher: (query: GetStudentLogsHomeworksAsTeacherParams, params?: RequestParams) =>
      this.request<
        { homeworks: StudentLogDeliverableHomeWorkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/homeworks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 指定した生徒の探究レポートの一覧を返却 - student_affiliate_idは必須 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsPblAnswersAsTeacher
     * @summary 指定した生徒の探究レポートの一覧を返却
     * @request GET:/teacher/student_logs/pbl_answers
     * @response `200` `{ pbl_answers: (StudentLogDeliverablePblAnswerBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsPblAnswersAsTeacher: (query: GetStudentLogsPblAnswersAsTeacherParams, params?: RequestParams) =>
      this.request<
        { pbl_answers: StudentLogDeliverablePblAnswerBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/pbl_answers${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 指定した生徒の学習プログラムの一覧を返却 - student_affiliate_idは必須 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsLearningPackagesAsTeacher
     * @summary 指定した生徒の学習プログラムの一覧を返却
     * @request GET:/teacher/student_logs/learning_packages
     * @response `200` `{ learning_packages: (StudentLogDeliverableLearningPackageBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsLearningPackagesAsTeacher: (
      query: GetStudentLogsLearningPackagesAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { learning_packages: StudentLogDeliverableLearningPackageBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/learning_packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 指定した生徒の探究成果物の一覧を返却 - student_affiliate_idは必須 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsArticlesAsTeacher
     * @summary 指定した生徒の探究成果物の一覧を返却
     * @request GET:/teacher/student_logs/articles
     * @response `200` `{ articles: (StudentLogDeliverableArticleBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsArticlesAsTeacher: (query: GetStudentLogsArticlesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { articles: StudentLogDeliverableArticleBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/articles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 指定した生徒のあいであるの一覧を返却 - student_affiliate_idは必須 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsIdealsAsTeacher
     * @summary 指定した生徒のあいであるの一覧を返却
     * @request GET:/teacher/student_logs/ideals
     * @response `200` `{ ideals: (StudentLogDeliverableIdealBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsIdealsAsTeacher: (query: GetStudentLogsIdealsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { ideals: StudentLogDeliverableIdealBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/ideals${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 指定した生徒の調査メモの一覧を返却 - student_affiliate_idは必須 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Teacher/StudentLogs
     * @name GetStudentLogsBookmarksAsTeacher
     * @summary 指定した生徒の調査メモの一覧を返却
     * @request GET:/teacher/student_logs/bookmarks
     * @response `200` `{ bookmarks: (StudentLogDeliverableListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentLogsBookmarksAsTeacher: (query: GetStudentLogsBookmarksAsTeacherParams, params?: RequestParams) =>
      this.request<
        { bookmarks: StudentLogDeliverableListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/student_logs/bookmarks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 ルーブリックを一覧を返却
     *
     * @tags Teacher/Rubrics
     * @name GetRubricsAsTeacher
     * @summary ルーブリック一覧を返却
     * @request GET:/teacher/rubrics
     * @response `200` `{ rubrics: (RubricBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getRubricsAsTeacher: (query: GetRubricsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { rubrics: RubricBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/rubrics${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 ルーブリックを登録
     *
     * @tags Teacher/Rubrics
     * @name PostRubricsAsTeacher
     * @summary ルーブリックを登録
     * @request POST:/teacher/rubrics
     * @response `201` `RubricBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postRubricsAsTeacher: (data: { title: string; status: RubricStatus }, params?: RequestParams) =>
      this.request<RubricBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/rubrics`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリックの詳細を返却
     *
     * @tags Teacher/Rubrics
     * @name GetRubricAsTeacher
     * @summary ルーブリックの詳細を返却
     * @request GET:/teacher/rubrics/{id}
     * @response `200` `RubricBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getRubricAsTeacher: (id: string, params?: RequestParams) =>
      this.request<RubricBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/rubrics/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 ルーブリックを更新
     *
     * @tags Teacher/Rubrics
     * @name PutRubricAsTeacher
     * @summary ルーブリック を更新
     * @request PUT:/teacher/rubrics/{id}
     * @response `202` `RubricBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putRubricAsTeacher: (id: string, data: { title: string; status: RubricStatus }, params?: RequestParams) =>
      this.request<RubricBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/rubrics/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリックを削除
     *
     * @tags Teacher/Rubrics
     * @name DeleteRubricAsTeacher
     * @summary ルーブリック を削除
     * @request DELETE:/teacher/rubrics/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteRubricAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/rubrics/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 ルーブリックアイテムを登録
     *
     * @tags Teacher/RubricItems
     * @name PostRubricItemsAsTeacher
     * @summary ルーブリックアイテムを登録
     * @request POST:/teacher/rubrics/{rubric_id}/rubric_items
     * @response `201` `RubricItemBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postRubricItemsAsTeacher: (
      rubric_id: string,
      data: {
        curriculum_type: RubricItemCurriculumType;
        perspective: string;
        level_1?: string;
        level_2?: string;
        level_3?: string;
        level_4?: string;
        level_5?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<RubricItemBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/rubrics/${rubric_id}/rubric_items`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリックアイテムの詳細を返却
     *
     * @tags Teacher/RubricItems
     * @name GetRubricItemAsTeacher
     * @summary ルーブリックアイテムの詳細を返却
     * @request GET:/teacher/rubrics/{rubric_id}/rubric_items/{id}
     * @response `200` `RubricItemBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getRubricItemAsTeacher: (rubric_id: string, id: string, params?: RequestParams) =>
      this.request<RubricItemBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/rubrics/${rubric_id}/rubric_items/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 ルーブリックアイテムを更新
     *
     * @tags Teacher/RubricItems
     * @name PutRubricItemAsTeacher
     * @summary ルーブリックアイテム を更新
     * @request PUT:/teacher/rubrics/{rubric_id}/rubric_items/{id}
     * @response `202` `RubricItemBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putRubricItemAsTeacher: (
      rubric_id: string,
      id: string,
      data: {
        curriculum_type: RubricItemCurriculumType;
        perspective: string;
        level_1?: string;
        level_2?: string;
        level_3?: string;
        level_4?: string;
        level_5?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<RubricItemBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/rubrics/${rubric_id}/rubric_items/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリックアイテムを削除
     *
     * @tags Teacher/RubricItems
     * @name DeleteRubricItemAsTeacher
     * @summary ルーブリックアイテム を削除
     * @request DELETE:/teacher/rubrics/{rubric_id}/rubric_items/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteRubricItemAsTeacher: (rubric_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/rubrics/${rubric_id}/rubric_items/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 ルーブリック生徒評価を一覧を返却
     *
     * @tags Teacher/RubricEvaluates
     * @name GetRubricEvaluatesAsTeacher
     * @summary ルーブリック生徒評価一覧を返却
     * @request GET:/teacher/rubrics/{rubric_id}/rubric_evaluates
     * @response `200` `{ rubric_evaluates: (RubricEvaluateBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getRubricEvaluatesAsTeacher: ({ rubric_id, ...query }: GetRubricEvaluatesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { rubric_evaluates: RubricEvaluateBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/rubrics/${rubric_id}/rubric_evaluates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 ルーブリックを登録
     *
     * @tags Teacher/RubricEvaluates
     * @name PostRubricEvaluateAsTeacher
     * @summary ルーブリック生徒評価を登録
     * @request POST:/teacher/rubrics/{rubric_id}/rubric_evaluates
     * @response `201` `RubricEvaluateBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postRubricEvaluateAsTeacher: (
      rubric_id: string,
      data: { title: string; description: string },
      params?: RequestParams,
    ) =>
      this.request<
        RubricEvaluateBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/rubrics/${rubric_id}/rubric_evaluates`, "POST", params, data),

    /**
     * @description - Teacher限定 ルーブリック評価の詳細を返却
     *
     * @tags Teacher/RubricEvaluates
     * @name GetRubricEvaluateAsTeacher
     * @summary ルーブリック評価の詳細を返却
     * @request GET:/teacher/rubrics/{rubric_id}/rubric_evaluates/{id}
     * @response `200` `RubricEvaluateBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getRubricEvaluateAsTeacher: (rubric_id: string, id: string, params?: RequestParams) =>
      this.request<
        RubricEvaluateBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/rubrics/${rubric_id}/rubric_evaluates/${id}`, "GET", params),

    /**
     * @description - Teacher限定 ルーブリック評価を更新
     *
     * @tags Teacher/RubricEvaluates
     * @name PutRubricEvaluateAsTeacher
     * @summary ルーブリック評価を更新
     * @request PUT:/teacher/rubrics/{rubric_id}/rubric_evaluates/{id}
     * @response `202` `RubricEvaluateBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putRubricEvaluateAsTeacher: (
      rubric_id: string,
      id: string,
      data: { title: string; description?: string },
      params?: RequestParams,
    ) =>
      this.request<
        RubricEvaluateBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/rubrics/${rubric_id}/rubric_evaluates/${id}`, "PUT", params, data),

    /**
     * @description - Teacher限定 ルーブリック評価を削除
     *
     * @tags Teacher/RubricEvaluates
     * @name DeleteRubricEvaluateAsTeacher
     * @summary ルーブリック評価を削除
     * @request DELETE:/teacher/rubrics/{rubric_id}/rubric_evaluates/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteRubricEvaluateAsTeacher: (rubric_id: string, id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/rubrics/${rubric_id}/rubric_evaluates/${id}`, "DELETE", params),

    /**
     * @description - Teacher限定 ルーブリック評価を順序を更新する
     *
     * @tags Teacher/RubricEvaluates
     * @name PutRubricEvaluateSequenceAsTeacher
     * @summary ルーブリック評価の順序を更新
     * @request PUT:/teacher/rubrics/{rubric_id}/rubric_evaluates/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putRubricEvaluateSequenceAsTeacher: (
      rubric_id: string,
      data: { sequences: { id: string; sequence_number?: number }[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/rubrics/${rubric_id}/rubric_evaluates/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリック生徒評価を登録
     *
     * @tags Teacher/RubricEvaluateStudents
     * @name PostRubricEvaluateStudentAsTeacher
     * @summary ルーブリック生徒評価を登録
     * @request POST:/teacher/rubrics/{rubric_id}/rubric_evaluates/{rubric_evaluate_id}/rubric_evaluate_students
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postRubricEvaluateStudentAsTeacher: (
      rubric_id: string,
      rubric_evaluate_id: string,
      data: {
        student_affiliate_id: string;
        comment?: string;
        student_rates?: { rubric_item_id: string; score: number }[];
      },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(
        `/teacher/rubrics/${rubric_id}/rubric_evaluates/${rubric_evaluate_id}/rubric_evaluate_students`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリック生徒評価の詳細を返却
     *
     * @tags Teacher/RubricEvaluateStudents
     * @name GetRubricEvaluateStudentAsTeacher
     * @summary ルーブリック生徒評価の詳細を返却
     * @request GET:/teacher/rubrics/{rubric_id}/rubric_evaluates/{rubric_evaluate_id}/rubric_evaluate_students/{id}
     * @response `200` `RubricEvaluateStudentBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getRubricEvaluateStudentAsTeacher: (
      rubric_id: string,
      rubric_evaluate_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<
        RubricEvaluateStudentBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(
        `/teacher/rubrics/${rubric_id}/rubric_evaluates/${rubric_evaluate_id}/rubric_evaluate_students/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 ルーブリック生徒評価を更新
     *
     * @tags Teacher/RubricEvaluateStudents
     * @name PutRubricEvaluateStudentAsTeacher
     * @summary ルーブリック生徒評価を更新
     * @request PUT:/teacher/rubrics/{rubric_id}/rubric_evaluates/{rubric_evaluate_id}/rubric_evaluate_students/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putRubricEvaluateStudentAsTeacher: (
      rubric_id: string,
      rubric_evaluate_id: string,
      id: string,
      data: { comment?: string },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(
        `/teacher/rubrics/${rubric_id}/rubric_evaluates/${rubric_evaluate_id}/rubric_evaluate_students/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリック生徒評価を削除
     *
     * @tags Teacher/RubricEvaluateStudents
     * @name DeleteRubricEvaluateStudentAsTeacher
     * @summary ルーブリック生徒評価を削除
     * @request DELETE:/teacher/rubrics/{rubric_id}/rubric_evaluates/{rubric_evaluate_id}/rubric_evaluate_students/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteRubricEvaluateStudentAsTeacher: (
      rubric_id: string,
      rubric_evaluate_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(
        `/teacher/rubrics/${rubric_id}/rubric_evaluates/${rubric_evaluate_id}/rubric_evaluate_students/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 ルーブリック生徒評価を順序を更新する
     *
     * @tags Teacher/RubricEvaluateStudents
     * @name PutRubricEvaluateStudentSequenceAsTeacher
     * @summary ルーブリック生徒評価の順序を更新
     * @request PUT:/teacher/rubrics/{rubric_id}/rubric_evaluates/{rubric_evaluate_id}/rubric_evaluate_students/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putRubricEvaluateStudentSequenceAsTeacher: (
      rubric_id: string,
      rubric_evaluate_id: string,
      data: { sequences: { id: string; sequence_number?: number }[] },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        { message?: string } | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(
        `/teacher/rubrics/${rubric_id}/rubric_evaluates/${rubric_evaluate_id}/rubric_evaluate_students/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリック生徒評価ポイントを登録
     *
     * @tags Teacher/RubricEvaluateStudentRates
     * @name PostRubricEvaluateStudentRateAsTeacher
     * @summary ルーブリック生徒評価ポイントを登録
     * @request POST:/teacher/rubrics/{rubric_id}/rubric_evaluates/{rubric_evaluate_id}/rubric_evaluate_students/{rubric_evaluate_student_id}/rubric_evaluate_student_rates
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postRubricEvaluateStudentRateAsTeacher: (
      rubric_id: string,
      rubric_evaluate_id: string,
      rubric_evaluate_student_id: string,
      data: { rubric_item_id: string; score: number },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(
        `/teacher/rubrics/${rubric_id}/rubric_evaluates/${rubric_evaluate_id}/rubric_evaluate_students/${rubric_evaluate_student_id}/rubric_evaluate_student_rates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリック生徒評価ポイントを更新
     *
     * @tags Teacher/RubricEvaluateStudentRates
     * @name PutRubricEvaluateStudentRateAsTeacher
     * @summary ルーブリック生徒評価ポイントを更新
     * @request PUT:/teacher/rubrics/{rubric_id}/rubric_evaluates/{rubric_evaluate_id}/rubric_evaluate_students/{rubric_evaluate_student_id}/rubric_evaluate_student_rates/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putRubricEvaluateStudentRateAsTeacher: (
      rubric_id: string,
      rubric_evaluate_id: string,
      rubric_evaluate_student_id: string,
      id: string,
      data: { score: number },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(
        `/teacher/rubrics/${rubric_id}/rubric_evaluates/${rubric_evaluate_id}/rubric_evaluate_students/${rubric_evaluate_student_id}/rubric_evaluate_student_rates/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 ルーブリック生徒評価ポイントを削除
     *
     * @tags Teacher/RubricEvaluateStudentRates
     * @name DeleteRubricEvaluateStudentRateAsTeacher
     * @summary ルーブリック生徒評価ポイントを削除
     * @request DELETE:/teacher/rubrics/{rubric_id}/rubric_evaluates/{rubric_evaluate_id}/rubric_evaluate_students/{rubric_evaluate_student_id}/rubric_evaluate_student_rates/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteRubricEvaluateStudentRateAsTeacher: (
      rubric_id: string,
      rubric_evaluate_id: string,
      rubric_evaluate_student_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/rubrics/${rubric_id}/rubric_evaluates/${rubric_evaluate_id}/rubric_evaluate_students/${rubric_evaluate_student_id}/rubric_evaluate_student_rates/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 LearningTemplatePackageDistributionSetting を一覧を返却 - SuperTeacherが配信された学習コンテンツを取得
     *
     * @tags Teacher/LearningTemplatePackageDistributions
     * @name GetLearningTemplatePackageDistributionsAsTeacher
     * @summary 配信された学習コンテンツの 一覧を返却
     * @request GET:/teacher/learning_template_package_distributions
     * @response `200` `{ learning_template_package_distribution_settings: (LearningTemplatePackageDistributionSettingBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionsAsTeacher: (
      query: GetLearningTemplatePackageDistributionsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          learning_template_package_distribution_settings: LearningTemplatePackageDistributionSettingBase[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/learning_template_package_distributions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 LearningTemplatePackageDistributionSetting を一覧を返却 - SuperTeacherが配信された学習コンテンツを取得 - 学習コンテンツの提出物と教材を返却
     *
     * @tags Teacher/LearningTemplatePackageDistributions
     * @name GetLearningTemplatePackageDistributionAsTeacher
     * @summary 配信された学習コンテンツの 詳細を返却
     * @request GET:/teacher/learning_template_package_distributions/{id}
     * @response `200` `LearningTemplatePackageDistributionSettingBaseDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        LearningTemplatePackageDistributionSettingBaseDetails,
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/learning_template_package_distributions/${id}`, "GET", params),

    /**
     * @description - Teacher限定 LearningPackage の詳細を返却 - 生徒に配信された学習コンテンツを取得 - 所属しているCompanyの生徒のみ取得可能
     *
     * @tags Teacher/LearningPackages
     * @name GetLearningPackageAsTeacher
     * @summary 生徒に配信された学習コンテンツの 詳細を返却
     * @request GET:/teacher/learning_packages/{id}
     * @response `200` `LearningPackageBaseDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningPackageAsTeacher: (id: string, params?: RequestParams) =>
      this.request<LearningPackageBaseDetails, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/learning_packages/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 LearningTemplatePackageDistributionSetting の配信履歴一覧を返却 - SuperTeacherが配信された学習コンテンツの配信履歴を取得
     *
     * @tags Teacher/LearningTemplatePackageDistributionHistories
     * @name GetLearningTemplatePackageDistributionHistoriesAsTeacher
     * @summary 学習コンテンツの配信履歴 一覧を返却
     * @request GET:/teacher/learning_template_package_distribution_histories
     * @response `200` `{ learning_template_package_distribution_histories: (LearningTemplatePackageDistributionHistoryBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionHistoriesAsTeacher: (
      query: GetLearningTemplatePackageDistributionHistoriesAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          learning_template_package_distribution_histories: LearningTemplatePackageDistributionHistoryBase[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/learning_template_package_distribution_histories${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 LearningTemplatePackageDistributionSetting の配信履歴 配信先一覧を返却
     *
     * @tags Teacher/LearningTemplatePackageDistributionHistories
     * @name GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacher
     * @summary 学習コンテンツの配信履歴 配信先一覧を返却
     * @request GET:/teacher/learning_template_package_distribution_histories/{id}/distributed_students
     * @response `200` `{ distributed_students: (DistributedStudents)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacher: (
      { id, ...query }: GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { distributed_students: DistributedStudents[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/teacher/learning_template_package_distribution_histories/${id}/distributed_students${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 Packaging を返却
     *
     * @tags Teacher/LearningTemplatePackageDistributionHistories
     * @name GetLearningTemplatePackageDistributionHistoriesPackagingsAsTeacher
     * @summary 学習コンテンツに紐づいているコンテンツのリストを返却
     * @request GET:/teacher/learning_template_package_distribution_histories/{id}/packagings
     * @response `200` `{ packagings: (PackagingAsLearningTemplatePackageBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningTemplatePackageDistributionHistoriesPackagingsAsTeacher: (
      { id, ...query }: GetLearningTemplatePackageDistributionHistoriesPackagingsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { packagings: PackagingAsLearningTemplatePackageBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/teacher/learning_template_package_distribution_histories/${id}/packagings${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 Pbl::Board::Campaign の一覧を取得 - status が募集中のものを取得 - 現在時刻がstart_dateとend_dateの範囲内のものを取得する - 企業と所属の学校の募集を取得する
     *
     * @tags Teacher/PblBoardCampaigns
     * @name GetPblBoardCampaignsAsTeacher
     * @summary 探究ボードの探究成果募集 の一覧を取得
     * @request GET:/teacher/pbl/board/campaigns
     * @response `200` `{ board_campaigns: (PblBoardCampaignListAsTeacher)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardCampaignsAsTeacher: (query: GetPblBoardCampaignsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { board_campaigns: PblBoardCampaignListAsTeacher[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/pbl/board/campaigns${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Pbl::Board::CampaignRequest を登録
     *
     * @tags Teacher/PblBoardCampaignRequest
     * @name PostPblBoardCampaignRequestAsTeacher
     * @summary 探究ボードの探究成果募集のリクエスト を登録
     * @request POST:/teacher/pbl/board/campaign_requests
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardCampaignRequestAsTeacher: (
      data: { board_campaign_requests: { board_campaign_id: string; article_id: string }[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/pbl/board/campaign_requests`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Pbl::Board::CampaignRequestを一括削除
     *
     * @tags Teacher/PblBoardCampaignRequest
     * @name DeletePblBoardCampaignRequestsAsTeacher
     * @summary 探究ボードの探究成果募集のリクエストを一括削除
     * @request DELETE:/teacher/pbl/board/campaign_requests/bulk_destroy
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deletePblBoardCampaignRequestsAsTeacher: (data: { ids: string[] }, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/pbl/board/campaign_requests/bulk_destroy`, "DELETE", params, data),

    /**
     * @description - Teacher限定 GroupAffiliatePackage を一覧を返却 - distributedはhomework_template_idと一緒に使う。 - distributed: trueで既に提出物を配信済みのグループを取得 - distributed: falseで提出物を未配信のグループを取得
     *
     * @tags Teacher/GroupAffiliatePackages
     * @name GetGroupAffiliatePackagesAsTeacher
     * @summary 生徒のグループ分け の一覧を返却
     * @request GET:/teacher/group_affiliate_packages
     * @response `200` `{ group_affiliate_packages: (GroupAffiliatePackageGroupOnlyList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getGroupAffiliatePackagesAsTeacher: (query: GetGroupAffiliatePackagesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { group_affiliate_packages: GroupAffiliatePackageGroupOnlyList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/group_affiliate_packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 GroupAffiliatePackage を登録
     *
     * @tags Teacher/GroupAffiliatePackages
     * @name PostGroupAffiliatePackageAsTeacher
     * @summary 生徒のグループ分け を登録
     * @request POST:/teacher/group_affiliate_packages
     * @response `201` `GroupAffiliatePackageBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postGroupAffiliatePackageAsTeacher: (data: PostGroupAffiliatePackage, params?: RequestParams) =>
      this.request<
        GroupAffiliatePackageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/group_affiliate_packages`, "POST", params, data),

    /**
     * @description - Teacher限定 GroupAffiliatePackage の詳細を返却
     *
     * @tags Teacher/GroupAffiliatePackages
     * @name GetGroupAffiliatePackageAsTeacher
     * @summary 生徒のグループ分け の詳細を返却
     * @request GET:/teacher/group_affiliate_packages/{id}
     * @response `200` `GroupAffiliatePackageBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getGroupAffiliatePackageAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        GroupAffiliatePackageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/group_affiliate_packages/${id}`, "GET", params),

    /**
     * @description - Teacher限定 GroupAffiliatePackage を更新
     *
     * @tags Teacher/GroupAffiliatePackages
     * @name PutGroupAffiliatePackageAsTeacher
     * @summary 生徒のグループ分け を更新
     * @request PUT:/teacher/group_affiliate_packages/{id}
     * @response `202` `GroupAffiliatePackageBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putGroupAffiliatePackageAsTeacher: (id: string, data: PutGroupAffiliatePackage, params?: RequestParams) =>
      this.request<
        GroupAffiliatePackageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/group_affiliate_packages/${id}`, "PUT", params, data),

    /**
     * @description - Teacher限定 GroupAffiliatePackage を削除
     *
     * @tags Teacher/GroupAffiliatePackages
     * @name DeleteGroupAffiliatePackageAsTeacher
     * @summary 生徒のグループ分け を削除
     * @request DELETE:/teacher/group_affiliate_packages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteGroupAffiliatePackageAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/group_affiliate_packages/${id}`, "DELETE", params),

    /**
     * @description - Teacher限定 Packaging を登録する - e.g. 生徒グループ分け機能（GroupAffiliatePackage）にAffiliateを登録する
     *
     * @tags Teacher/Packaging
     * @name PostPackagingAsTeacher
     * @summary パッケージにコンテンツ を登録
     * @request POST:/teacher/packagings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPackagingAsTeacher: (
      data: { package_id: string; packageable_type: PackagingPackageableType; packageable_id: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/packagings`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Packagingのsequence を更新する - パッケージのコンテンツを並び替える
     *
     * @tags Teacher/Packaging
     * @name PutPackagingAsTeacher
     * @summary パッケージにコンテンツの並び替えの順番 を更新
     * @request PUT:/teacher/packagings
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPackagingAsTeacher: (query: PutPackagingAsTeacherParams, data: { sequence: number }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/packagings${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Packaging を削除する - 論理削除される
     *
     * @tags Teacher/Packaging
     * @name DeletePackagingAsTeacher
     * @summary パッケージからコンテンツ を削除
     * @request DELETE:/teacher/packagings
     * @response `200` `{ message?: string }`
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePackagingAsTeacher: (query: DeletePackagingAsTeacherParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/packagings${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Packaging を一括登録する - e.g. 生徒グループ分け機能（GroupAffiliatePackage）にAffiliateを登録する
     *
     * @tags Teacher/Packaging
     * @name BatchCreatePackagingAsTeacher
     * @summary パッケージにコンテンツ を一括登録
     * @request PATCH:/teacher/packagings/batch_create
     * @response `201` `{ message?: string }` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    batchCreatePackagingAsTeacher: (
      data: { package_id: string; packageable_type: PackagingPackageableType; packageable_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/packagings/batch_create`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Packagingのsequence を一括更新する - パッケージのコンテンツを並び替える
     *
     * @tags Teacher/Packaging
     * @name BatchUpdatePackagingAsTeacher
     * @summary パッケージにコンテンツの並び替えの順番 を一括更新
     * @request PATCH:/teacher/packagings/batch_update_sequence
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    batchUpdatePackagingAsTeacher: (
      data: {
        package_id: string;
        packageable_type: PackagingPackageableType;
        packageable_ids: string[];
        sequence: { packageable_id: string; sequence: number }[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/packagings/batch_update_sequence`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Packaging を一括削除する - 論理削除される
     *
     * @tags Teacher/Packaging
     * @name BatchDeletePackagingAsTeacher
     * @summary パッケージからコンテンツ を一括削除
     * @request DELETE:/teacher/packagings/batch_destroy
     * @response `200` `{ message?: string }` Ok
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    batchDeletePackagingAsTeacher: (
      data: { package_id: string; packageable_type: string; packageable_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/packagings/batch_destroy`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 AttachedImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags Teacher/AttachedImages
     * @name PostAttachedImageAsTeacher
     * @summary 画像 を登録
     * @request POST:/teacher/attached_images
     * @response `201` `AttachedImageBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postAttachedImageAsTeacher: (
      query: PostAttachedImageAsTeacherParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        AttachedImageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/attached_images${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description - Teacher限定 AttachedImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Teacher/AttachedImages
     * @name GetAttachedImageAsTeacher
     * @summary 画像 の詳細を返却
     * @request GET:/teacher/attached_images/{id}
     * @response `200` `AttachedImageBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getAttachedImageAsTeacher: ({ id, ...query }: GetAttachedImageAsTeacherParams, params?: RequestParams) =>
      this.request<
        AttachedImageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/attached_images/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - SuperTeacher限定 AttachedImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Teacher/AttachedImages
     * @name PutAttachedImageAsTeacher
     * @summary 画像 を更新
     * @request PUT:/teacher/attached_images/{id}
     * @response `202` `AttachedImageBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putAttachedImageAsTeacher: (
      { id, ...query }: PutAttachedImageAsTeacherParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        AttachedImageBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/attached_images/${id}${this.addQueryParams(query)}`, "PUT", params, data),

    /**
     * @description - Teacher限定 AttachedImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Teacher/AttachedImages
     * @name DeleteAttachedImageAsTeacher
     * @summary 画像 を削除
     * @request DELETE:/teacher/attached_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteAttachedImageAsTeacher: ({ id, ...query }: DeleteAttachedImageAsTeacherParams, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/attached_images/${id}${this.addQueryParams(query)}`, "DELETE", params),

    /**
     * @description - LimitedTimeNotification を一覧を返却
     *
     * @tags Teacher/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationsAsTeacher
     * @summary 期間限定のお知らせの一覧を返却
     * @request GET:/teacher/limited_time_notifications
     * @response `200` `{ limited_time_notifications: (LimitedTimeNotificationList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationsAsTeacher: (query: GetLimitedTimeNotificationsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { limited_time_notifications: LimitedTimeNotificationList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/limited_time_notifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - LimitedTimeNotification を登録
     *
     * @tags Teacher/LimitedTimeNotifications
     * @name PostLimitedTimeNotificationAsTeacher
     * @summary 期間限定のお知らせ を登録
     * @request POST:/teacher/limited_time_notifications
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postLimitedTimeNotificationAsTeacher: (
      data: {
        title: string;
        message: string;
        start_time: string;
        end_time: string;
        add_roles: SelectRoleByLimitedTimeNotification[];
        add_company_ids?: string[];
        add_user_tag_ids?: string[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/limited_time_notifications`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - LimitedTimeNotification の詳細を返却
     *
     * @tags Teacher/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationAsTeacher
     * @summary 期間限定のお知らせ の詳細を返却
     * @request GET:/teacher/limited_time_notifications/{id}
     * @response `200` `LimitedTimeNotificationDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationAsTeacher: (id: string, params?: RequestParams) =>
      this.request<LimitedTimeNotificationDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/limited_time_notifications/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 LimitedTimeNotification を更新
     *
     * @tags Teacher/LimitedTimeNotifications
     * @name PutLimitedTimeNotificationAsTeacher
     * @summary 期間限定のお知らせ を更新
     * @request PUT:/teacher/limited_time_notifications/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putLimitedTimeNotificationAsTeacher: (
      id: string,
      data: {
        title: string;
        message: string;
        start_time: string;
        end_time: string;
        add_roles: SelectRoleByLimitedTimeNotification[];
        add_company_ids?: string[];
        add_user_tag_ids?: string[];
        remove_role_ids?: string[];
        remove_company_ids?: string[];
        remove_user_tag_ids?: string[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/limited_time_notifications/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - LimitedTimeNotification を削除
     *
     * @tags Teacher/LimitedTimeNotifications
     * @name DeleteLimitedTimeNotificationAsTeacher
     * @summary 期間限定のお知らせ を削除
     * @request DELETE:/teacher/limited_time_notifications/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteLimitedTimeNotificationAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/limited_time_notifications/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 LimitedTimeNotification を一覧を返却 - 現在時刻がstart_timeとend_timeの範囲内のものを取得 - 指定された条件（Role, Company）にマッチしたものを取得
     *
     * @tags Teacher/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationNoticeAsTeacher
     * @summary 配信された期間限定のお知らせの一覧を返却
     * @request GET:/teacher/limited_time_notifications/notice
     * @response `200` `{ limited_time_notifications: (LimitedTimeNotificationNoticeBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationNoticeAsTeacher: (
      query: GetLimitedTimeNotificationNoticeAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { limited_time_notifications: LimitedTimeNotificationNoticeBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/limited_time_notifications/notice${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 LimitedTimeNotification の詳細を返却 - 現在時刻がstart_timeとend_timeの範囲内のものを取得 - 指定された条件（Role, Company）にマッチしたものを取得
     *
     * @tags Teacher/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationNoticeDetailsAsTeacher
     * @summary 配信された期間限定のお知らせ の詳細を返却
     * @request GET:/teacher/limited_time_notifications/{id}/notice_details
     * @response `200` `LimitedTimeNotificationNoticeBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationNoticeDetailsAsTeacher: (id: string, params?: RequestParams) =>
      this.request<LimitedTimeNotificationNoticeBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/limited_time_notifications/${id}/notice_details`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 このお知らせを確認したかを保存する - Newアイコンを外すため
     *
     * @tags Teacher/LimitedTimeNotifications
     * @name PostLimitedTimeNotificationNoticeCheckAsTeacher
     * @summary 期間限定のお知らせをチェックしたフラグを保存する
     * @request POST:/teacher/limited_time_notifications/{id}/notice_check
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postLimitedTimeNotificationNoticeCheckAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/limited_time_notifications/${id}/notice_check`,
        "POST",
        params,
      ),

    /**
     * @description - Teacher限定 DailyLogの一覧を返却 - 開始日（昇順）、カテゴリー（昇順）、作成日（降順）で並べている
     *
     * @tags Teacher/DailyLog
     * @name GetDailyLogsAsTeacher
     * @summary 日報の一覧を返却
     * @request GET:/teacher/daily_logs
     * @response `200` `{ daily_logs: (DailyLogBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getDailyLogsAsTeacher: (query: GetDailyLogsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { daily_logs: DailyLogBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/daily_logs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Ideal::BoardTemplate の一覧を返却 - 自分の所属する学校に紐づくテンプレートのみ取得可能
     *
     * @tags Teacher/IdealBoardTemplates
     * @name GetIdealBoardTemplatesAsTeacher
     * @summary Ideal::BoardTemplate の一覧を返却
     * @request GET:/teacher/ideal/board_templates
     * @response `200` `{ board_templates: (BoardTemplateList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getIdealBoardTemplatesAsTeacher: (query: GetIdealBoardTemplatesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { board_templates: BoardTemplateList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/ideal/board_templates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Ideal::BoardTemplate を登録
     *
     * @tags Teacher/IdealBoardTemplates
     * @name PostIdealBoardTemplatesAsTeacher
     * @summary Ideal::BoardTemplate を登録
     * @request POST:/teacher/ideal/board_templates
     * @response `201` `BoardTemplate` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postIdealBoardTemplatesAsTeacher: (data: PostIdealBoardTemplateRequestBody, params?: RequestParams) =>
      this.request<BoardTemplate, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/ideal/board_templates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Teacher限定 Ideal::BoardTemplate の詳細を返却
     *
     * @tags Teacher/IdealBoardTemplate
     * @name GetIdealBoardTemplateAsTeacher
     * @summary Ideal::BoardTemplate の詳細を返却
     * @request GET:/teacher/ideal/board_templates/{id}
     * @response `200` `BoardTemplate` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getIdealBoardTemplateAsTeacher: (id: string, params?: RequestParams) =>
      this.request<BoardTemplate, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/ideal/board_templates/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Teacher限定 Ideal::BoardTemplate を更新
     *
     * @tags Teacher/IdealBoardTemplate
     * @name PutIdealBoardTemplateAsTeacher
     * @summary Ideal::BoardTemplate を更新
     * @request PUT:/teacher/ideal/board_templates/{id}
     * @response `202` `BoardTemplate` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putIdealBoardTemplateAsTeacher: (id: string, data: PutIdealBoardTemplateRequestBody, params?: RequestParams) =>
      this.request<BoardTemplate, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/ideal/board_templates/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Ideal::BoardTemplate を論理削除 - 関連するboardsも論理削除される
     *
     * @tags Teacher/IdealBoardTemplate
     * @name DeleteIdealBoardTemplateAsTeacher
     * @summary Ideal::BoardTemplate を削除
     * @request DELETE:/teacher/ideal/board_templates/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteIdealBoardTemplateAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/ideal/board_templates/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Ideal::Board の一覧を返却 - 自社のIdeal::Boardのみ取得可能 - 特定のあいであるテンプレートで絞り込み可能 - グループでも絞り込み可能
     *
     * @tags Teacher/IdealBoards
     * @name GetIdealBoardsAsTeacher
     * @summary Ideal::Board の一覧を返却
     * @request GET:/teacher/ideal/boards
     * @response `200` `{ boards: (BoardList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getIdealBoardsAsTeacher: (query: GetIdealBoardsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { boards: BoardList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/ideal/boards${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Ideal::Board を登録
     *
     * @tags Teacher/IdealBoards
     * @name PostIdealBoardsAsTeacher
     * @summary Ideal::Board を登録
     * @request POST:/teacher/ideal/boards
     * @response `201` `Board` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postIdealBoardsAsTeacher: (data: PostIdealBoardRequestBody, params?: RequestParams) =>
      this.request<Board, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/ideal/boards`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Teacher限定 Ideal::Board の詳細を返却
     *
     * @tags Teacher/IdealBoard
     * @name GetIdealBoardAsTeacher
     * @summary Ideal::Board の詳細を返却
     * @request GET:/teacher/ideal/boards/{id}
     * @response `200` `Board` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getIdealBoardAsTeacher: (id: string, params?: RequestParams) =>
      this.request<Board, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/ideal/boards/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Teacher限定 Ideal::Board を更新
     *
     * @tags Teacher/IdealBoard
     * @name PutIdealBoardAsTeacher
     * @summary Ideal::Board を更新
     * @request PUT:/teacher/ideal/boards/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putIdealBoardAsTeacher: (
      id: string,
      data: { name?: string; data?: string; event?: IdealBoardEvent },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/ideal/boards/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Ideal::Board を論理削除 - 関連するboards_affiliateも論理削除される
     *
     * @tags Teacher/IdealBoard
     * @name DeleteIdealBoardAsTeacher
     * @summary Ideal::Board を削除
     * @request DELETE:/teacher/ideal/boards/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteIdealBoardAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/ideal/boards/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 アイデアボックスに生徒（Affiliate）を紐づける - 指定された生徒もアイデアボックスの閲覧、編集可能になる
     *
     * @tags Teacher/BoardAffiliates
     * @name PostIdealBoardsAffiliatesAsTeacher
     * @summary アイデアボックスに生徒を紐づける
     * @request POST:/teacher/ideal/boards_affiliates
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postIdealBoardsAffiliatesAsTeacher: (
      data: { ideal_board_id: string; affiliate_id: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/ideal/boards_affiliates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Teacher限定 アイデアボックスに紐づいている生徒を削除する
     *
     * @tags Teacher/BoardAffiliates
     * @name DeleteIdealBoardsAffiliatesAsTeacher
     * @summary アイデアボックスに紐づいている生徒を削除する
     * @request DELETE:/teacher/ideal/boards_affiliates
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteIdealBoardsAffiliatesAsTeacher: (
      data: { ideal_board_id: string; affiliate_id: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/ideal/boards_affiliates`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description - 特定のresourceに紐づくグループをまとめて更新する
     *
     * @tags AllowedGroupAffiliates
     * @name PatchAllowedGroupAffiliatesBatchUpdateAsTeacher
     * @summary 特定のresourceに紐づくグループをまとめて更新
     * @request PATCH:/teacher/allowed_group_affiliates/batch_update
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    patchAllowedGroupAffiliatesBatchUpdateAsTeacher: (
      data: {
        categorizable_type: AllowedGroupAffiliateCategorizableType;
        categorizable_id: string;
        add_group_affiliate_ids?: string[];
        remove_group_affiliate_ids?: string[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/allowed_group_affiliates/batch_update`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description - "Teacher限定 TtCsvExport を一覧を返却"
     *
     * @tags Teacher/TtCsvExports
     * @name GetTtCsvExportsAsTeacher
     * @summary CSV一覧を返却
     * @request GET:/teacher/tt_csv/exports
     * @response `200` `{ tt_csv_exports: (TtCsvExportBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtCsvExportsAsTeacher: (query: GetTtCsvExportsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { tt_csv_exports: TtCsvExportBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/tt_csv/exports${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 TtCsvExport を登録 - type: teacher can only use starting with teacher - homework_template_id: required when exporting homework_template_custom_field_list
     *
     * @tags Teacher/TtCsvExports
     * @name PostTtCsvExportAsTeacher
     * @summary CSV出力 をリクエスト
     * @request POST:/teacher/tt_csv/exports
     * @response `200` `TtCsvExportBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTtCsvExportAsTeacher: (
      query: PostTtCsvExportAsTeacherParams,
      data: { type: TtCsvExportType; homework_template_id?: string; pbl_plan_item_id?: string },
      params?: RequestParams,
    ) =>
      this.request<TtCsvExportBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/tt_csv/exports${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 TtCategoryBase を登録
     *
     * @tags Teacher/TtCategoryBases
     * @name PostTtCategoryBaseAsTeacher
     * @request POST:/teacher/tt_category/bases
     * @response `201` `TtCategoryBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTtCategoryBaseAsTeacher: (data: CreateTtCategoryRequestBody, params?: RequestParams) =>
      this.request<TtCategoryBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/tt_category/bases`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 TtCategoryBase を更新
     *
     * @tags Teacher/TtCategoryBases
     * @name PutTtCategoryBaseAsTeacher
     * @request PUT:/teacher/tt_category/bases/{id}
     * @response `202` `TtCategoryBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putTtCategoryBaseAsTeacher: (id: string, data: UpdateTtCategoryRequestBody, params?: RequestParams) =>
      this.request<TtCategoryBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/teacher/tt_category/bases/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "Teacher限定 FeedbackTemplate::Homework を一覧を返却"
     *
     * @tags Teacher/FeedbackTemplate/Homework
     * @name GetFeedbackTemplateHomeworksAsTeacher
     * @summary 提出物FBの一覧を返却
     * @request GET:/teacher/feedback_template/homeworks
     * @response `200` `{ feedback_templates: (FeedbackTemplateHomeworkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplateHomeworksAsTeacher: (
      query: GetFeedbackTemplateHomeworksAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { feedback_templates: FeedbackTemplateHomeworkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/feedback_template/homeworks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Teacher限定 FeedbackTemplate::Tag を一覧を返却"
     *
     * @tags Teacher/FeedbackTemplate/Tag
     * @name GetFeedbackTemplateTagsAsTeacher
     * @summary 提出物FBのタグ一覧を返却
     * @request GET:/teacher/feedback_template/tags
     * @response `200` `{ feedback_template_tags: (HasName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplateTagsAsTeacher: (query: GetFeedbackTemplateTagsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { feedback_template_tags: HasName[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/feedback_template/tags${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 CommentImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags Teacher/CommentImages
     * @name PostCommentImageAsTeacher
     * @summary 画像 を登録
     * @request POST:/teacher/comment_images
     * @response `201` `CommentImageBase` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    postCommentImageAsTeacher: (
      query: PostCommentImageAsTeacherParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<CommentImageBase, BadRequest | { error?: string }>(
        `/teacher/comment_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 CommentImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Teacher/CommentImages
     * @name GetCommentImageAsTeacher
     * @summary 画像 の詳細を返却
     * @request GET:/teacher/comment_images/{id}
     * @response `200` `CommentImageBase` OK
     * @response `400` `BadRequest`
     */
    getCommentImageAsTeacher: ({ id, ...query }: GetCommentImageAsTeacherParams, params?: RequestParams) =>
      this.request<CommentImageBase, BadRequest>(
        `/teacher/comment_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperTeacher限定 CommentImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Teacher/CommentImages
     * @name PutCommentImageAsTeacher
     * @summary 画像 を更新
     * @request PUT:/teacher/comment_images/{id}
     * @response `202` `CommentImageBase` Accepted
     * @response `400` `BadRequest`
     */
    putCommentImageAsTeacher: (
      { id, ...query }: PutCommentImageAsTeacherParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<CommentImageBase, BadRequest>(
        `/teacher/comment_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 CommentImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Teacher/CommentImages
     * @name DeleteCommentImageAsTeacher
     * @summary 画像 を削除
     * @request DELETE:/teacher/comment_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    deleteCommentImageAsTeacher: ({ id, ...query }: DeleteCommentImageAsTeacherParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest>(
        `/teacher/comment_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 DistributionStatus を一覧を返却
     *
     * @tags Teacher/DistributionStatus
     * @name GetDistributionStatusesAsTeacher
     * @summary 配信履歴と進捗情報の一覧を返却
     * @request GET:/teacher/distribution_statuses
     * @response `200` `{ distribution_statuses: (DistributionStatusList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getDistributionStatusesAsTeacher: (query: GetDistributionStatusesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { distribution_statuses: DistributionStatusList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/distribution_statuses${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Interview::Request を一覧を返却
     *
     * @tags Teacher/Interview/Request
     * @name GetInterviewRequestsAsTeacher
     * @summary 高大連携リクエスト一覧を返却
     * @request GET:/teacher/interview/requests
     * @response `200` `{ interview_requests: (InterviewRequestListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestsAsTeacher: (query: GetInterviewRequestsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { interview_requests: InterviewRequestListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/interview/requests${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Interview::Request を登録 - 大学連携のオプションが有効であること - 大学と紐づいている大学教員のみリクエスト可能
     *
     * @tags Teacher/Interview/Request
     * @name PostInterviewRequestAsTeacher
     * @summary 高大連携リクエストを登録
     * @request POST:/teacher/interview/requests
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postInterviewRequestAsTeacher: (
      data: { professor_affiliate_id: string; article_id: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/interview/requests`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Interview::Request の詳細を返却
     *
     * @tags Teacher/Interview/Request
     * @name GetInterviewRequestAsTeacher
     * @summary 高大連携リクエストの詳細を返却
     * @request GET:/teacher/interview/requests/{id}
     * @response `200` `InterviewRequestDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestAsTeacher: (id: string, params?: RequestParams) =>
      this.request<InterviewRequestDetailBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/interview/requests/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 Interview::Request の詳細を返却
     *
     * @tags Teacher/Interview/Request
     * @name PutInterviewRequestAsTeacher
     * @summary 高大連携リクエストの詳細を更新
     * @request PUT:/teacher/interview/requests/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putInterviewRequestAsTeacher: (
      id: string,
      data: {
        professor_message?: string;
        is_online?: boolean;
        confirm_date?: string;
        place?: string;
        event?: InterviewRequestEvent;
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/interview/requests/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Article の詳細を返却 - 高大連携リクエストされている探究成果のみ閲覧可能
     *
     * @tags Teacher/Interview/Request
     * @name GetInterviewRequestArticleAsTeacher
     * @summary 高大連携リクエストの探究成果詳細を返却
     * @request GET:/teacher/interview/requests/{id}/article
     * @response `200` `ArticleDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestArticleAsTeacher: (id: string, params?: RequestParams) =>
      this.request<ArticleDetailBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/interview/requests/${id}/article`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 高大連携リクエストの未読メッセージの件数を返却
     *
     * @tags Teacher/Interview/Request
     * @name GetInterviewRequestUnreadCountsAsTeacher
     * @summary 高大連携リクエストの未読メッセージの件数を返却
     * @request GET:/teacher/interview/requests/unread_count
     * @response `200` `{ unread_counts: (any)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestUnreadCountsAsTeacher: (
      query: GetInterviewRequestUnreadCountsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<{ unread_counts: any[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/interview/requests/unread_count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 Interview::Message を一覧を返却
     *
     * @tags Teacher/Interview/Message
     * @name GetInterviewMessagesAsTeacher
     * @summary 高大連携リクエストのメッセージ一覧を返却
     * @request GET:/teacher/interview/requests/{request_id}/messages
     * @response `200` `{ interview_messages: (InterviewMessageListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewMessagesAsTeacher: (
      { request_id, ...query }: GetInterviewMessagesAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { interview_messages: InterviewMessageListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/interview/requests/${request_id}/messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Interview::Message を登録
     *
     * @tags Teacher/Interview/Message
     * @name PostInterviewMessageAsTeacher
     * @summary 高大連携リクエストのメッセージを登録
     * @request POST:/teacher/interview/requests/{request_id}/messages
     * @response `201` `InterviewMessageListBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postInterviewMessageAsTeacher: (request_id: string, data: { message: string }, params?: RequestParams) =>
      this.request<InterviewMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/interview/requests/${request_id}/messages`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Interview::Read を登録
     *
     * @tags Teacher/Interview/Message
     * @name GetInterviewMessageReadAsTeacher
     * @summary 高大連携リクエストの未読メッセージを既読メッセージに変更する
     * @request POST:/teacher/interview/requests/{request_id}/messages/read
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewMessageReadAsTeacher: (
      request_id: string,
      data: { unread_message_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/interview/requests/${request_id}/messages/read`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Interview::Message を更新
     *
     * @tags Teacher/Interview/Message
     * @name PutInterviewMessageAsTeacher
     * @summary 高大連携リクエストのメッセージ を更新
     * @request PUT:/teacher/interview/requests/{request_id}/messages/{id}
     * @response `202` `InterviewMessageListBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putInterviewMessageAsTeacher: (request_id: string, id: string, data: { message: string }, params?: RequestParams) =>
      this.request<InterviewMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/interview/requests/${request_id}/messages/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Interview::Message を削除
     *
     * @tags Teacher/Interview/Message
     * @name DeleteInterviewMessageAsTeacher
     * @summary 高大連携リクエストのメッセージ を削除
     * @request DELETE:/teacher/interview/requests/{request_id}/messages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteInterviewMessageAsTeacher: (request_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/interview/requests/${request_id}/messages/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Interview::Feedback を一覧を返却
     *
     * @tags Teacher/Interview/Feedback
     * @name GetInterviewFeedbacksAsTeacher
     * @summary 高大連携リクエストのアンケート一覧を返却
     * @request GET:/teacher/interview/requests/{request_id}/feedbacks
     * @response `200` `{ interview_feedbacks: (InterviewFeedbackBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewFeedbacksAsTeacher: (
      { request_id, ...query }: GetInterviewFeedbacksAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { interview_feedbacks: InterviewFeedbackBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/interview/requests/${request_id}/feedbacks${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Teacher/AffiliateInvitation
     * @name FetchAffiliateInvitationsAsTeacher
     * @summary AffiliateInvitation Index
     * @request GET:/teacher/affiliate_invitations
     * @response `200` `{ invitations: (AffiliateInvitation)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    fetchAffiliateInvitationsAsTeacher: (query: FetchAffiliateInvitationsAsTeacherParams, params?: RequestParams) =>
      this.request<{ invitations: AffiliateInvitation[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/teacher/affiliate_invitations${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 提出物のカスタムフィールドの値の情報の更新 - group_affiliate_package_id: グループ向けの提出物に関連する場合必須。
     *
     * @tags Teacher/HomeworkTemplateCustomValues
     * @name PutHomeworkTemplateCustomValueAsTeacher
     * @summary Teacher: 提出物のカスタムフィールドの値の情報更新
     * @request PUT:/teacher/homework_template_custom_values/{id}
     * @response `202` `{ homework_template_custom_fields: (HomeworkTemplateCustomFieldAndValue)[] }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putHomeworkTemplateCustomValueAsTeacher: (
      id: string,
      data: CustomValueRequestBodyAsOwnerAndTeacherAndReviewer,
      params?: RequestParams,
    ) =>
      this.request<
        { homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[] },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/homework_template_custom_values/${id}`, "PUT", params, data),

    /**
     * @description - Teacher限定 Gogo::Linkingに紐づいているCompany一覧を返却
     *
     * @tags Teacher/Gogo/Linkings
     * @name GetGogoLinkingsAsTeacher
     * @summary 探究GoGoに紐づいている企業一覧を返却
     * @request GET:/teacher/gogo/linkings
     * @response `200` `{ gogo_companies: (HasIdAndName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoLinkingsAsTeacher: (query: GetGogoLinkingsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { gogo_companies: HasIdAndName[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/linkings${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::Request を一覧を返却
     *
     * @tags Teacher/Gogo/Request
     * @name GetGogoRequestsAsTeacher
     * @summary 探究GoGoのリクエスト一覧を返却
     * @request GET:/teacher/gogo/requests
     * @response `200` `{ gogo_requests: (GogoRequestListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoRequestsAsTeacher: (query: GetGogoRequestsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { gogo_requests: GogoRequestListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/requests${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::Request を登録
     *
     * @tags Teacher/Gogo/Request
     * @name PostGogoRequestAsTeacher
     * @summary 探究GoGoのリクエストを登録
     * @request POST:/teacher/gogo/requests
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoRequestAsTeacher: (data: PostGogoRequestRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/requests`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::Request の詳細を返却
     *
     * @tags Teacher/Gogo/Request
     * @name GetGogoRequestAsTeacher
     * @summary 探究GoGoのリクエストの詳細を返却
     * @request GET:/teacher/gogo/requests/{id}
     * @response `200` `GogoRequestDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoRequestAsTeacher: (id: string, params?: RequestParams) =>
      this.request<GogoRequestDetailBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/requests/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 Gogo::Request を更新
     *
     * @tags Teacher/Gogo/Request
     * @name PutGogoRequestAsTeacher
     * @summary 探究GoGoのリクエストを更新
     * @request PUT:/teacher/gogo/requests/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoRequestAsTeacher: (id: string, data: PutGogoRequestRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/requests/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::Request を削除
     *
     * @tags Teacher/Gogo/Request
     * @name DeleteGogoRequestAsTeacher
     * @summary 探究GoGoのリクエスト を削除
     * @request DELETE:/teacher/gogo/requests/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoRequestAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/requests/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 探究GoGoのリクエストの未読メッセージの件数を返却
     *
     * @tags Teacher/Gogo/Request
     * @name GetGogoRequestUnreadCountsAsTeacher
     * @summary 探究GoGoのリクエストの未読メッセージの件数を返却
     * @request GET:/teacher/gogo/requests/unread_count
     * @response `200` `{ unread_counts: (GogoRequestUnreadCountBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoRequestUnreadCountsAsTeacher: (query: GetGogoRequestUnreadCountsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { unread_counts: GogoRequestUnreadCountBase[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/requests/unread_count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::Message を一覧を返却
     *
     * @tags Teacher/Gogo/Message
     * @name GetGogoMessagesAsTeacher
     * @summary 探究GoGoリクエストのメッセージ一覧を返却
     * @request GET:/teacher/gogo/requests/{request_id}/messages
     * @response `200` `{ gogo_messages: (GogoMessageListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoMessagesAsTeacher: ({ request_id, ...query }: GetGogoMessagesAsTeacherParams, params?: RequestParams) =>
      this.request<
        { gogo_messages: GogoMessageListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/requests/${request_id}/messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::Message を登録
     *
     * @tags Teacher/Gogo/Message
     * @name PostGogoMessageAsTeacher
     * @summary 探究GoGoリクエストのメッセージを登録
     * @request POST:/teacher/gogo/requests/{request_id}/messages
     * @response `201` `GogoMessageListBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoMessageAsTeacher: (
      { request_id, ...query }: PostGogoMessageAsTeacherParams,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<GogoMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/requests/${request_id}/messages${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::Message を更新
     *
     * @tags Teacher/Gogo/Message
     * @name PutGogoMessageAsTeacher
     * @summary 探究GoGoリクエストのメッセージ を更新
     * @request PUT:/teacher/gogo/requests/{request_id}/messages/{id}
     * @response `202` `GogoMessageListBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoMessageAsTeacher: (
      { request_id, id, ...query }: PutGogoMessageAsTeacherParams,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<GogoMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/requests/${request_id}/messages/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::Message を削除
     *
     * @tags Teacher/Gogo/Message
     * @name DeleteGogoMessageAsTeacher
     * @summary 探究GoGoリクエストのメッセージ を削除
     * @request DELETE:/teacher/gogo/requests/{request_id}/messages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoMessageAsTeacher: (
      { request_id, id, ...query }: DeleteGogoMessageAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/requests/${request_id}/messages/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Chat::Room を表示
     *
     * @tags Teacher/Chat/Room
     * @name GetChatRoomAsTeacher
     * @summary 調査メモのチャットルームを詳細表示
     * @request GET:/teacher/chat/rooms
     * @response `200` `(ModelBase & { bookmark: HasIdAndTitle })` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getChatRoomAsTeacher: (query: GetChatRoomAsTeacherParams, params?: RequestParams) =>
      this.request<ModelBase & { bookmark: HasIdAndTitle }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/chat/rooms${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 Chat::Room を登録
     *
     * @tags Teacher/Chat/Room
     * @name PostChatRoomAsTeacher
     * @summary 調査メモのチャットルームを登録
     * @request POST:/teacher/chat/rooms
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postChatRoomAsTeacher: (query: PostChatRoomAsTeacherParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/chat/rooms${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @description - Teacher限定 Gogo::Message を一覧を返却
     *
     * @tags Teacher/Chat/RoomMessage
     * @name GetChatRoomMessagesAsTeacher
     * @summary 調査メモのチャットルームのメッセージ一覧を返却
     * @request GET:/teacher/chat/rooms/room_messages
     * @response `200` `{ chat_room_messages: ((ModelBase & { message: string, message_wysiwyg_body?: string | null, created_at: string, sender_affiliate: AffiliateMessageBase }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getChatRoomMessagesAsTeacher: (query: GetChatRoomMessagesAsTeacherParams, params?: RequestParams) =>
      this.request<
        {
          chat_room_messages: (ModelBase & {
            message: string;
            message_wysiwyg_body?: string | null;
            created_at: string;
            sender_affiliate: AffiliateMessageBase;
          })[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/chat/rooms/room_messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::Message を登録
     *
     * @tags Teacher/Chat/RoomMessage
     * @name PostChatRoomMessageAsTeacher
     * @summary 調査メモのチャットルームのメッセージを登録
     * @request POST:/teacher/chat/rooms/room_messages
     * @response `201` `(any & { bookmark_wysiwyg_body?: string | null })` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postChatRoomMessageAsTeacher: (
      query: PostChatRoomMessageAsTeacherParams,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        any & { bookmark_wysiwyg_body?: string | null },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/chat/rooms/room_messages${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description - Teacher限定 Gogo::Message を更新
     *
     * @tags Teacher/Chat/RoomMessage
     * @name PutChatRoomMessageAsTeacher
     * @summary 調査メモのチャットルームのメッセージ を更新
     * @request PUT:/teacher/chat/rooms/room_messages/{id}
     * @response `202` `any` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putChatRoomMessageAsTeacher: (
      { id, ...query }: PutChatRoomMessageAsTeacherParams,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/chat/rooms/room_messages/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::Message を削除
     *
     * @tags Teacher/Chat/RoomMessage
     * @name DeleteChatRoomMessageAsTeacher
     * @summary 調査メモのチャットルームのメッセージ を削除
     * @request DELETE:/teacher/chat/rooms/room_messages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteChatRoomMessageAsTeacher: ({ id, ...query }: DeleteChatRoomMessageAsTeacherParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/chat/rooms/room_messages/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Gogo::Read を登録
     *
     * @tags Teacher/Gogo/Message
     * @name GetGogoMessageReadAsTeacher
     * @summary 探究GoGoのリクエストの未読メッセージを既読メッセージに変更する
     * @request POST:/teacher/gogo/requests/{request_id}/messages/read
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoMessageReadAsTeacher: (
      { request_id, ...query }: GetGogoMessageReadAsTeacherParams,
      data: { unread_message_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/requests/${request_id}/messages/read${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Common::Tag の一覧を返却
     *
     * @tags Teacher/CommonTags
     * @name GetCommonTagsAsTeacher
     * @summary システムタグ・フリータグの一覧を返却
     * @request GET:/teacher/common/tags
     * @response `200` `{ common_tags: (CommonTagBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCommonTagsAsTeacher: (query: GetCommonTagsAsTeacherParams, params?: RequestParams) =>
      this.request<
        { common_tags: CommonTagBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/common/tags${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Common::Tag を登録
     *
     * @tags Teacher/CommonTags
     * @name PostCommonTagAsTeacher
     * @summary 管理タグを登録
     * @request POST:/teacher/common/tags
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postCommonTagAsTeacher: (data: CommonTagRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/common/tags`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Common::Tag を更新
     *
     * @tags Teacher/CommonTags
     * @name PutCommonTagAsTeacher
     * @summary 管理タグを更新
     * @request PUT:/teacher/common/tags/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putCommonTagAsTeacher: (id: string, data: NameRequiredRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/common/tags/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Common::Tag を削除
     *
     * @tags Teacher/CommonTags
     * @name DeleteCommonTagAsTeacher
     * @summary 管理タグを削除
     * @request DELETE:/teacher/common/tags/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteCommonTagAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/common/tags/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Common::Tag の一覧を返却
     *
     * @tags Teacher/CommonTags
     * @name GetCommonTagsManagementTagListAsTeacher
     * @summary 管理タグの一覧を返却
     * @request GET:/teacher/common/tags/management_tag_list
     * @response `200` `{ common_management_tags: (CommonTagManagementTagBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCommonTagsManagementTagListAsTeacher: (
      query: GetCommonTagsManagementTagListAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { common_management_tags: CommonTagManagementTagBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/common/tags/management_tag_list${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 CommonTagging を登録 - 許可されたModelのみ登録可能
     *
     * @tags Teacher/CommonTaggings
     * @name PostCommonTaggingAsTeacher
     * @summary フリータグを登録
     * @request POST:/teacher/common/taggings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postCommonTaggingAsTeacher: (
      query: PostCommonTaggingAsTeacherParams,
      data: CommonTaggingRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/common/taggings${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description - Teacher限定 CommonTagging を削除
     *
     * @tags Teacher/CommonTaggings
     * @name DeleteCommonTaggingAsTeacher
     * @summary フリータグ を解除
     * @request DELETE:/teacher/common/taggings
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteCommonTaggingAsTeacher: (query: DeleteCommonTaggingAsTeacherParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/common/taggings${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 CommonLinking を登録
     *
     * @tags Teacher/CommonTagLinking
     * @name PostCommonTagLinkingAsTeacher
     * @summary 管理タグに紐付け機能を追加
     * @request POST:/teacher/common/tags/{tag_id}/tag_linkings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postCommonTagLinkingAsTeacher: (tag_id: string, data: CommonTagLinkingRequestBody, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/teacher/common/tags/${tag_id}/tag_linkings`, "POST", params, data),

    /**
     * @description - Teacher限定 CommonTagLinking を削除
     *
     * @tags Teacher/CommonTagLinking
     * @name DeleteCommonTagLinkingAsTeacher
     * @summary 管理タグの紐付けを解除
     * @request DELETE:/teacher/common/tags/{tag_id}/tag_linkings/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteCommonTagLinkingAsTeacher: (tag_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/common/tags/${tag_id}/tag_linkings/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Gogo::Program を一覧を返却
     *
     * @tags Teacher/Gogo/Program
     * @name GetGogoProgramsAsTeacher
     * @summary 探究GoGooのプログラム一覧を返却
     * @request GET:/teacher/gogo/programs
     * @response `200` `{ gogo_programs: ((ModelBase & { title: string, status: any, status_code: any, created_at: string | null, creator_affiliate: AffiliateName, company: HasIdAndName }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramsAsTeacher: (query: GetGogoProgramsAsTeacherParams, params?: RequestParams) =>
      this.request<
        {
          gogo_programs: (ModelBase & {
            title: string;
            status: any;
            status_code: any;
            created_at: string | null;
            creator_affiliate: AffiliateName;
            company: HasIdAndName;
          })[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/programs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::Program を登録
     *
     * @tags Teacher/Gogo/Program
     * @name PostGogoProgramAsTeacher
     * @summary 探究GoGoのプログラムを登録
     * @request POST:/teacher/gogo/programs
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoProgramAsTeacher: (
      data: {
        title: string;
        event?: "close" | "release";
        number_of_classes?: number;
        number_of_students?: number;
        parking?: GogoRequestParking;
        projector?: GogoRequestProjector;
        internet_connection?: GogoRequestInternetConnection;
        photography?: GogoRequestPhotography;
        atmosphere?: GogoRequestAtmosphere;
        teacher_affiliate_id?: string;
        grade?: StudentGrade;
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::Program の詳細を返却
     *
     * @tags Teacher/Gogo/Program
     * @name GetGogoProgramAsTeacher
     * @summary 探究GoGoのプログラムの詳細を返却
     * @request GET:/teacher/gogo/programs/{id}
     * @response `200` `(any & { number_of_classes: number, number_of_students: number, parking: GogoRequestParking, projector: GogoRequestProjector, internet_connection: GogoRequestInternetConnection, photography: GogoRequestPhotography, atmosphere: GogoRequestAtmosphereJa, atmosphere_code: GogoRequestAtmosphere, grade: StudentGrade, teacher_affiliate?: AffiliateName })` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramAsTeacher: (id: string, params?: RequestParams) =>
      this.request<
        any & {
          number_of_classes: number;
          number_of_students: number;
          parking: GogoRequestParking;
          projector: GogoRequestProjector;
          internet_connection: GogoRequestInternetConnection;
          photography: GogoRequestPhotography;
          atmosphere: GogoRequestAtmosphereJa;
          atmosphere_code: GogoRequestAtmosphere;
          grade: StudentGrade;
          teacher_affiliate?: AffiliateName;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/programs/${id}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::Request を更新
     *
     * @tags Teacher/Gogo/Program
     * @name PutGogoProgramAsTeacher
     * @summary 探究GoGoのプログラムを更新
     * @request PUT:/teacher/gogo/programs/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoProgramAsTeacher: (id: string, data: any, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::Program を削除
     *
     * @tags Teacher/Gogo/Program
     * @name DeleteGogoProgramAsTeacher
     * @summary 探究GoGoのプログラムを削除
     * @request DELETE:/teacher/gogo/programs/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoProgramAsTeacher: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Gogo::ProgramRequest を一覧を返却
     *
     * @tags Teacher/Gogo/ProgramRequest
     * @name GetGogoProgramRequestsAsTeacher
     * @summary 探究GoGooのプログラムリクエストリクエスト一覧を返却
     * @request GET:/teacher/gogo/programs/{program_id}/program_requests
     * @response `200` `{ gogo_program_requests: ((ModelBase & { status: "調整中" | "調整済み", status_code: "adjusting" | "adjusted", created_at: string | null, company: HasIdAndName }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramRequestsAsTeacher: (
      { program_id, ...query }: GetGogoProgramRequestsAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          gogo_program_requests: (ModelBase & {
            status: "調整中" | "調整済み";
            status_code: "adjusting" | "adjusted";
            created_at: string | null;
            company: HasIdAndName;
          })[];
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/programs/${program_id}/program_requests${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::ProgramRequest を登録
     *
     * @tags Teacher/Gogo/ProgramRequest
     * @name PostGogoProgramRequestAsTeacher
     * @summary 探究GoGoのプログラムリクエストを登録
     * @request POST:/teacher/gogo/programs/{program_id}/program_requests
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoProgramRequestAsTeacher: (program_id: string, data: { message: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_requests`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::ProgramRequest の詳細を返却
     *
     * @tags Teacher/Gogo/ProgramRequest
     * @name GetGogoProgramRequestAsTeacher
     * @summary 探究GoGoのプログラムリクエストの詳細を返却
     * @request GET:/teacher/gogo/programs/{program_id}/program_requests/{id}
     * @response `200` `(any & { message: string, unread_message_ids: (string)[] })` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramRequestAsTeacher: (program_id: string, id: string, params?: RequestParams) =>
      this.request<
        any & { message: string; unread_message_ids: string[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/programs/${program_id}/program_requests/${id}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::Request を更新
     *
     * @tags Teacher/Gogo/ProgramRequest
     * @name PutGogoProgramRequestAsTeacher
     * @summary 探究GoGoのプログラムリクエストを更新
     * @request PUT:/teacher/gogo/programs/{program_id}/program_requests/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoProgramRequestAsTeacher: (
      program_id: string,
      id: string,
      data: { message?: string; event?: "close" },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_requests/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::ProgramRequest を削除
     *
     * @tags Teacher/Gogo/ProgramRequest
     * @name DeleteGogoProgramRequestAsTeacher
     * @summary 探究GoGoのプログラムリクエストを削除
     * @request DELETE:/teacher/gogo/programs/{program_id}/program_requests/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoProgramRequestAsTeacher: (program_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_requests/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Gogo::ProgramRequestMessage を一覧を返却
     *
     * @tags Teacher/Gogo/ProgramRequestMessage
     * @name GetGogoProgramRequestMessagesAsTeacher
     * @summary 探究GoGoプログラムプログラムリクエストのメッセージ一覧を返却
     * @request GET:/teacher/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages
     * @response `200` `{ gogo_messages: (GogoMessageListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramRequestMessagesAsTeacher: (
      { program_id, program_request_id, ...query }: GetGogoProgramRequestMessagesAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { gogo_messages: GogoMessageListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/teacher/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 Gogo::ProgramRequestMessage を登録
     *
     * @tags Teacher/Gogo/ProgramRequestMessage
     * @name PostGogoProgramRequestMessageAsTeacher
     * @summary 探究GoGoプログラムリクエストのメッセージを登録
     * @request POST:/teacher/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages
     * @response `201` `GogoMessageListBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoProgramRequestMessageAsTeacher: (
      program_id: string,
      program_request_id: string,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<GogoMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::ProgramRequestMessage を更新
     *
     * @tags Teacher/Gogo/ProgramRequestMessage
     * @name PutGogoProgramRequestMessageAsTeacher
     * @summary 探究GoGoプログラムリクエストのメッセージ を更新
     * @request PUT:/teacher/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages/{id}
     * @response `202` `GogoMessageListBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoProgramRequestMessageAsTeacher: (
      program_id: string,
      program_request_id: string,
      id: string,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<GogoMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::ProgramRequestMessage を削除
     *
     * @tags Teacher/Gogo/ProgramRequestMessage
     * @name DeleteGogoProgramRequestMessageAsTeacher
     * @summary 探究GoGoプログラムリクエストのメッセージ を削除
     * @request DELETE:/teacher/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoProgramRequestMessageAsTeacher: (
      program_id: string,
      program_request_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Gogo::Read を登録
     *
     * @tags Teacher/Gogo/ProgramRequestMessage
     * @name GetGogoProgramRequestMessageReadAsTeacher
     * @summary 探究GoGoのプログラムリクエストの未読メッセージを既読メッセージに変更する
     * @request POST:/teacher/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages/read
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramRequestMessageReadAsTeacher: (
      program_id: string,
      program_request_id: string,
      data: { unread_message_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages/read`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::ProgramPreferredDate を一覧を返却
     *
     * @tags Teacher/Gogo/ProgramPreferredDate
     * @name GetGogoProgramPreferredDatesAsTeacher
     * @summary 探究GoGooのプログラムの募集日程一覧を返却
     * @request GET:/teacher/gogo/programs/{program_id}/program_preferred_dates
     * @response `200` `{ gogo_program_preferred_dates: (GogoProgramPreferredDateBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramPreferredDatesAsTeacher: (
      { program_id, ...query }: GetGogoProgramPreferredDatesAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { gogo_program_preferred_dates: GogoProgramPreferredDateBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/programs/${program_id}/program_preferred_dates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::ProgramPreferredDate を登録
     *
     * @tags Teacher/Gogo/ProgramPreferredDate
     * @name PostGogoProgramPreferredDateAsTeacher
     * @summary 探究GoGoのプログラムの募集日程を登録
     * @request POST:/teacher/gogo/programs/{program_id}/program_preferred_dates
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoProgramPreferredDateAsTeacher: (
      program_id: string,
      data: GogoProgramPreferredDateRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_preferred_dates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::Request を更新
     *
     * @tags Teacher/Gogo/ProgramPreferredDate
     * @name PutGogoProgramPreferredDateAsTeacher
     * @summary 探究GoGoのプログラムの募集日程を更新
     * @request PUT:/teacher/gogo/programs/{program_id}/program_preferred_dates/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoProgramPreferredDateAsTeacher: (
      program_id: string,
      id: string,
      data: GogoProgramPreferredDateRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_preferred_dates/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::ProgramPreferredDate を削除
     *
     * @tags Teacher/Gogo/ProgramPreferredDate
     * @name DeleteGogoProgramPreferredDateAsTeacher
     * @summary 探究GoGoのプログラムの募集日程を削除
     * @request DELETE:/teacher/gogo/programs/{program_id}/program_preferred_dates/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoProgramPreferredDateAsTeacher: (program_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/programs/${program_id}/program_preferred_dates/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Teacher限定 Gogo::CompanyInformation の詳細を返却
     *
     * @tags Teacher/Gogo/CompanyInformation
     * @name GetGogoCompanyInformationAsTeacher
     * @summary 探究GoGo 企業情報の詳細を返却
     * @request GET:/teacher/gogo/company_informations
     * @response `200` `GogoCompanyInformationBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoCompanyInformationAsTeacher: (params?: RequestParams) =>
      this.request<GogoCompanyInformationBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/company_informations`,
        "GET",
        params,
      ),

    /**
     * @description - Teacher限定 企業情報一覧を返却
     *
     * @tags Teacher/Gogo/CompanyInformation
     * @name GetGogoCompanyInformationListAsTeacher
     * @summary 探究GoGoに紐づいている企業一覧を返却
     * @request GET:/teacher/gogo/company_informations/list
     * @response `200` `{ gogo_company_informations: (GogoCompanyInformationBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoCompanyInformationListAsTeacher: (
      query: GetGogoCompanyInformationListAsTeacherParams,
      params?: RequestParams,
    ) =>
      this.request<
        { gogo_company_informations: GogoCompanyInformationBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/company_informations/list${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::SchoolInformation を登録
     *
     * @tags Teacher/Gogo/SchoolInformation
     * @name PostGogoSchoolInformationAsTeacher
     * @summary 探究GoGok 学校設定情報を登録
     * @request POST:/teacher/gogo/school_informations
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoSchoolInformationAsTeacher: (data: any, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/school_informations`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::SchoolInformation の詳細を返却
     *
     * @tags Teacher/Gogo/SchoolInformation
     * @name GetGogoSchoolInformationAsTeacher
     * @summary 探究GoGo 学校設定情報の詳細を返却
     * @request GET:/teacher/gogo/school_informations
     * @response `200` `(ModelBase & { parking: any, projector: any, internet_connection: any, photography: any })` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoSchoolInformationAsTeacher: (params?: RequestParams) =>
      this.request<
        ModelBase & { parking: any; projector: any; internet_connection: any; photography: any },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/teacher/gogo/school_informations`, "GET", params),

    /**
     * @description - Teacher限定 Gogo::SchoolInformation を更新
     *
     * @tags Teacher/Gogo/SchoolInformation
     * @name PutGogoSchoolInformationAsTeacher
     * @summary 探究GoGo 学校設定情報を更新
     * @request PUT:/teacher/gogo/school_informations
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoSchoolInformationAsTeacher: (
      data: {
        parking?: "parking_unavailable" | "parking_available";
        projector?: "projector_unavailable" | "projector_available";
        internet_connection?: "internet_connection_unavailable" | "internet_connection_available";
        photography?: "photography_unavailable" | "photography_available";
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/school_informations`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Teacher限定 Gogo::SchoolInformation を削除
     *
     * @tags Teacher/Gogo/SchoolInformation
     * @name DeleteGogoSchoolInformationAsTeacher
     * @summary 探究GoGoリクエストのメッセージ を削除
     * @request DELETE:/teacher/gogo/school_informations
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoSchoolInformationAsTeacher: (params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/teacher/gogo/school_informations`,
        "DELETE",
        params,
      ),
  };
  student = {
    /**
     * @description - "Student限定 その生徒に属するCompanyの生徒一覧を返却" - "Affiliate IDも返却する"
     *
     * @tags Users
     * @name GetUserStudentsAsStudent
     * @summary 生徒 一覧を返却
     * @request GET:/student/users/students
     * @response `200` `{ students: (AffiliateName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getUserStudentsAsStudent: (query: GetUserStudentsAsStudentParams, params?: RequestParams) =>
      this.request<{ students: AffiliateName[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/student/users/students${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Users
     * @name GetUserStudentAffiliatesAsStudent
     * @summary [生徒] 担当の先生のAffiliateリストを取得
     * @request GET:/student/users/teacher_affiliates
     * @response `200` `{ teacher_affiliates: (AffiliateBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getUserStudentAffiliatesAsStudent: (query: GetUserStudentAffiliatesAsStudentParams, params?: RequestParams) =>
      this.request<
        { teacher_affiliates: AffiliateBase[]; total_count: number },
        Unauthorized | Forbidden | { error?: string }
      >(`/student/users/teacher_affiliates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 自分自身の情報のみ取得可能 他人を閲覧しようとした場合404
     *
     * @tags Users
     * @name GetStudentUsersUserId
     * @summary 生徒自身の情報詳細を取得
     * @request GET:/student/users/{user_id}
     * @response `200` `StudentProfile` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentUsersUserId: (user_id: string, params?: RequestParams) =>
      this.request<StudentProfile, Unauthorized | Forbidden | { error?: string }>(
        `/student/users/${user_id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Student/Users
     * @name UpdateCurrentUserAsStudent
     * @summary 生徒が自身のプロフィールを更新する
     * @request PUT:/student/users/{user_id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    updateCurrentUserAsStudent: (
      user_id: string,
      data: { nickname?: string; avatar?: File; student_info_attributes?: StudentInfoAttributes },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest>(
        `/student/users/${user_id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description 退会機能
     *
     * @tags Student/Users
     * @name UsersDelete
     * @request DELETE:/student/users/{user_id}
     * @response `204` `any` No Content
     */
    usersDelete: (user_id: string, params?: RequestParams) =>
      this.request<any, any>(`/student/users/${user_id}`, "DELETE", params),

    /**
     * @description 自分に配信されている教材の詳細情報を取得する
     *
     * @tags Materials
     * @name FetchMaterialsAsStudent
     * @summary 生徒が教材一覧を取得
     * @request GET:/student/materials
     * @response `200` `{ materials: (MaterialList)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchMaterialsAsStudent: (query: FetchMaterialsAsStudentParams, params?: RequestParams) =>
      this.request<{ materials: MaterialList[]; total_count: number }, Unauthorized | Forbidden | { error?: string }>(
        `/student/materials${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 自分に配信されている教材の詳細情報を取得する
     *
     * @tags Student/Material
     * @name FetchMaterialAsStudent
     * @summary 生徒が教材詳細情報を取得
     * @request GET:/student/materials/{material_id}
     * @response `200` `MaterialDetail` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchMaterialAsStudent: (material_id: string, params?: RequestParams) =>
      this.request<MaterialDetail, Unauthorized | Forbidden>(`/student/materials/${material_id}`, "GET", params),

    /**
     * @description 自身に配信されているMaterial一覧及そのStatus（未受講、受講済）を取得する。 params[:status]でstatusごとにフィルタリングする。
     *
     * @tags MaterialWorks
     * @name FetchMaterialWorksAsStudent
     * @summary Your GET endpoint
     * @request GET:/student/material_works
     * @response `200` `{ total_count: number, material_works: (StudentMaterialWork)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchMaterialWorksAsStudent: (query: FetchMaterialWorksAsStudentParams, params?: RequestParams) =>
      this.request<
        { total_count: number; material_works: StudentMaterialWork[] },
        Unauthorized | Forbidden | { error?: string }
      >(`/student/material_works${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 自身に配信されているMaterialのStatus（未受講、受講済）を取得する。
     *
     * @tags MaterialWorks
     * @name FetchOneMaterialWorkAsStudent
     * @summary 生徒が教材進捗の詳細を取得
     * @request GET:/student/material_works/{material_work_id}
     * @response `200` `StudentMaterialWork` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchOneMaterialWorkAsStudent: (material_work_id: string, params?: RequestParams) =>
      this.request<StudentMaterialWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/material_works/${material_work_id}`,
        "GET",
        params,
      ),

    /**
     * @description 自分に配信された教材について、受講ステータス（未受講、受講済み）のみ更新が可能。
     *
     * @tags MaterialWorks
     * @name PutStudentMaterialWorksMaterialWorkId
     * @summary 生徒が教材の進捗情報を更新
     * @request PUT:/student/material_works/{material_work_id}
     * @response `200` `StudentMaterialWork` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    putStudentMaterialWorksMaterialWorkId: (
      material_work_id: string,
      data: { material_works_id: string; status: MaterialWorkStatus },
      params?: RequestParams,
    ) =>
      this.request<StudentMaterialWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/material_works/${material_work_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 自分に配信された教材について、受講ステータス（未受講、受講済み）のみ更新が可能。
     *
     * @tags MaterialWorks
     * @name UploadMaterialWorkImagesAsStudent
     * @summary 生徒が教材の進捗情報上で編集した画像を保存
     * @request POST:/student/material_works/{material_work_id}/upload_images
     * @response `200` `StudentMaterialWork` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    uploadMaterialWorkImagesAsStudent: (
      material_work_id: string,
      data: { image_content_id: string; file: string },
      params?: RequestParams,
    ) =>
      this.request<StudentMaterialWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/material_works/${material_work_id}/upload_images`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 自分に配信された教材について、受講ステータス（未受講、受講済み）のみ更新が可能。
     *
     * @tags MaterialWorks
     * @name SaveEditedMaterialWorkImageAsStudent
     * @summary Create a material work image after editing the original material image
     * @request POST:/student/material_works/{material_work_id}/save_image
     * @response `200` `StudentMaterialWork` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    saveEditedMaterialWorkImageAsStudent: (
      material_work_id: string,
      data: { image_content_id: string; file: string },
      params?: RequestParams,
    ) =>
      this.request<StudentMaterialWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/material_works/${material_work_id}/save_image`,
        "POST",
        params,
        data,
      ),

    /**
     * @description 自分に配信された教材について、受講ステータス（未受講、受講済み）のみ更新が可能。
     *
     * @tags MaterialWorks
     * @name UpdateMaterialWorkImageAsStudent
     * @summary material_work_imageのファイルを上書きする
     * @request PUT:/student/material_works/{material_work_id}/update_image
     * @response `200` `StudentMaterialWork` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    updateMaterialWorkImageAsStudent: (
      material_work_id: string,
      data: { image_id: string; file: string },
      params?: RequestParams,
    ) =>
      this.request<StudentMaterialWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/material_works/${material_work_id}/update_image`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - material_work_imageのファイルを削除する。
     *
     * @tags MaterialWorks
     * @name DeleteMaterialWorkImageAsStudent
     * @summary material_work_imageのファイルを削除
     * @request DELETE:/student/material_works/{material_work_id}/delete_image
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteMaterialWorkImageAsStudent: (
      { material_work_id, ...query }: DeleteMaterialWorkImageAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/material_works/${material_work_id}/delete_image${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Student限定 受講中の教材に紐づいているカテゴリーの一覧を返却 - 教材の親カテゴリー(text)を表示
     *
     * @tags MaterialWorks
     * @name GetMaterialWorksCategoryListAsStudent
     * @summary 受講中の教材に紐づいているカテゴリーの一覧を返却
     * @request GET:/student/material_works/category_list
     * @response `200` `{ category_list: (CategoryListBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getMaterialWorksCategoryListAsStudent: (params?: RequestParams) =>
      this.request<{ category_list: CategoryListBase[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/material_works/category_list`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 受講中の教材に紐づいているタグの一覧を返却
     *
     * @tags MaterialWorks
     * @name GetMaterialWorksTagListAsStudent
     * @summary 受講中の教材に紐づいているタグの一覧を返却
     * @request GET:/student/material_works/tag_list
     * @response `200` `{ tag_list: (TagListBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getMaterialWorksTagListAsStudent: (params?: RequestParams) =>
      this.request<{ tag_list: TagListBase[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/material_works/tag_list`,
        "GET",
        params,
      ),

    /**
     * @description 生徒が保有する支払いの一覧を取得
     *
     * @tags Payment
     * @name FetchPaymentsAsStudent
     * @summary 生徒が支払いの一覧を取得
     * @request GET:/student/payments
     * @response `200` `{ payments: (Payment)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchPaymentsAsStudent: (query: FetchPaymentsAsStudentParams, params?: RequestParams) =>
      this.request<{ payments: Payment[]; total_count: number }, Unauthorized | Forbidden>(
        `/student/payments${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description 支払いの詳細を取得する
     *
     * @tags Payment
     * @name GetStudentPaymentsPaymentId
     * @summary 生徒が支払いの詳細を取得
     * @request GET:/student/payments/{payment_id}
     * @response `200` `Payment` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentPaymentsPaymentId: (payment_id: string, params?: RequestParams) =>
      this.request<Payment, Unauthorized | Forbidden | { error?: string }>(
        `/student/payments/${payment_id}`,
        "GET",
        params,
      ),

    /**
     * @description 生徒が支払状況を更新するためのEndpoint
     *
     * @tags Payment
     * @name UpdatePaymentAsStudent
     * @summary 生徒が支払状況を更新
     * @request PUT:/student/payments/{payment_id}
     * @response `200` `Payment` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updatePaymentAsStudent: (payment_id: string, data: { status?: PaymentStatus }, params?: RequestParams) =>
      this.request<Payment, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/payments/${payment_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description 生徒が宿題の一覧を取得する Statusでソートしたほうが良いかも？
     *
     * @tags Homework
     * @name FetchHomeworksAsStudent
     * @summary 生徒が宿題の一覧を取得
     * @request GET:/student/homeworks
     * @response `200` `{ homeworks: (HomeworkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchHomeworksAsStudent: (query: FetchHomeworksAsStudentParams, params?: RequestParams) =>
      this.request<
        { homeworks: HomeworkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/homeworks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 生徒が宿題の詳細を取得
     *
     * @tags Homework
     * @name FetchHomeworkAsStudent
     * @summary 生徒が宿題の詳細を表示
     * @request GET:/student/homeworks/{homework_id}
     * @response `200` `HomeworkDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchHomeworkAsStudent: (homework_id: string, params?: RequestParams) =>
      this.request<HomeworkDetails, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/homeworks/${homework_id}`,
        "GET",
        params,
      ),

    /**
     * @description - 生徒用の宿題更新API - メール通知先：担当の先生。 - メール内容：提出物が提出されたこと。
     *
     * @tags Homework
     * @name UpdateHomeworkAsStudent
     * @summary 生徒が宿題のステータスを更新。同時にメール通知する。
     * @request PUT:/student/homeworks/{homework_id}
     * @response `200` `HomeworkDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    updateHomeworkAsStudent: (
      homework_id: string,
      data: {
        status?: HomeworkStatus;
        student_comment?: string;
        student_homework_images?: File[];
        ideal_thumbnail_file?: File | null;
      },
      params?: RequestParams,
    ) =>
      this.request<HomeworkDetails, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/homeworks/${homework_id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * No description
     *
     * @tags Student/HomeworkTemplate, Student/Homework, Homework, HomeworkTemplate
     * @name UploadHomeworkImagesAsStudent
     * @request POST:/student/homeworks/{homework_id}/upload_images
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    uploadHomeworkImagesAsStudent: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/homeworks/${homework_id}/upload_images`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/HomeworkTemplate, Student/Homework, Homework, HomeworkTemplate
     * @name SaveEditedHomeworkImageAsStudent
     * @request POST:/student/homeworks/{homework_id}/save_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    saveEditedHomeworkImageAsStudent: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/homeworks/${homework_id}/save_image`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/HomeworkTemplate, Student/Homework, Homework, HomeworkTemplate
     * @name UpdateHomeworkImageAsStudent
     * @request PUT:/student/homeworks/{homework_id}/update_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    updateHomeworkImageAsStudent: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/homeworks/${homework_id}/update_image`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/HomeworkTemplate, Student/Homework, Homework, HomeworkTemplate
     * @name DeleteHomeworkImageAsStudent
     * @request DELETE:/student/homeworks/{homework_id}/delete_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    deleteHomeworkImageAsStudent: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/homeworks/${homework_id}/delete_image`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description 生徒のみ指定した提出物IDのステータスのみを返却
     *
     * @tags Homework
     * @name GetHomeworkStatusesAsStudent
     * @summary 指定した提出物IDのステータスのみを返却
     * @request GET:/student/homeworks/status
     * @response `200` `{ homeworks: (HomeworkStatusBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getHomeworkStatusesAsStudent: (query: GetHomeworkStatusesAsStudentParams, params?: RequestParams) =>
      this.request<{ homeworks: HomeworkStatusBase[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/homeworks/status${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description It returns information about the company to which the student actually belongs. You can also check the input settings required for that student.
     *
     * @tags Companies
     * @name GetStudentCompaniesCompanyId
     * @summary Student to get company detail
     * @request GET:/student/companies/{company_id}
     * @response `200` `CompanyAsStudent` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentCompaniesCompanyId: (company_id: string, params?: RequestParams) =>
      this.request<CompanyAsStudent, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/companies/${company_id}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 オプション拡張による制限の確認 - 教材登録のディスク使用量制限 - あいである枚数制限
     *
     * @tags Student/Company
     * @name GetCompanyLimitOptionAsStudent
     * @summary オプション拡張による制限の確認
     * @request GET:/student/companies/{id}/limit_option
     * @response `200` `{ limit_option: LimitOptionBase, total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCompanyLimitOptionAsStudent: (id: string, params?: RequestParams) =>
      this.request<
        { limit_option: LimitOptionBase; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/companies/${id}/limit_option`, "GET", params),

    /**
     * No description
     *
     * @tags HomeworkImage
     * @name SaveTempDataOfHomeworkImageAsStudent
     * @summary 下書き情報を保存するためのAPI。HomeworkImageがない場合は作成ある場合はUpdateを行う。
     * @request PATCH:/student/homework_images/save_temp_data
     * @response `200` `HomeworkImage` Saved the temp data
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    saveTempDataOfHomeworkImageAsStudent: (
      data: { homework_template_image_id?: string | null; homework_image_id?: string | null; temp_data: string },
      params?: RequestParams,
    ) =>
      this.request<HomeworkImage, Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/homework_images/save_temp_data`,
        "PATCH",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags HomeworkImage
     * @name DeleteTempDataOfHomeworkImageAsStudent
     * @summary 下書き情報を消去するためのAPI。
     * @request PATCH:/student/homework_images/{id}/delete_temp_data
     * @response `200` `HomeworkImage` Removed the temp data
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteTempDataOfHomeworkImageAsStudent: (id: string, params?: RequestParams) =>
      this.request<HomeworkImage, Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/homework_images/${id}/delete_temp_data`,
        "PATCH",
        params,
      ),

    /**
     * @description 生徒画面で必要な項目のみのリストを返却するAPI
     *
     * @tags Report
     * @name GetStudentReports
     * @summary 生徒用指導報告書一覧取得
     * @request GET:/student/reports
     * @response `200` `{ reports: ({ id: string, teacher_name: string, created_at: string })[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getStudentReports: (query: GetStudentReportsParams, params?: RequestParams) =>
      this.request<
        { reports: { id: string; teacher_name: string; created_at: string }[]; total_count: number },
        Unauthorized | Forbidden
      >(`/student/reports${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 指導レポートのうち、生徒が閲覧可能なもののみ返却するAPI
     *
     * @tags Report
     * @name GetStudentReportsReportId
     * @summary 生徒用指導レポート詳細
     * @request GET:/student/reports/{report_id}
     * @response `200` `({ message_to_owner?: string, message_to_student?: string } & Report)` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentReportsReportId: (report_id: string, params?: RequestParams) =>
      this.request<
        { message_to_owner?: string; message_to_student?: string } & Report,
        Unauthorized | Forbidden | { error?: string }
      >(`/student/reports/${report_id}`, "GET", params),

    /**
     * @description - Ownerが student_visible: true で設定したものを表示 - SuperOwnerが Categoryの設定で student_visible: true かつ 学校タグ を設定している場合、その 学校タグ に紐づいている場合表示される - SuperOwnerが Categoryの設定で student_visible: true かつ 学校タグ かつ ユーザタグ を設定している場合、その 学校タグ と ユーザータグ に紐づいている場合表示される
     *
     * @tags Categories
     * @name GetStudentCategories
     * @summary For Student: Get allowed categories
     * @request GET:/student/categories
     * @response `200` `{ grades: (GradeCategoryInfo)[], subjects: (SubjectCategoryInfo)[], major_categories: (MajorCategoryInfo)[], middle_categories: (MiddleCategoryInfo)[], minor_categories: (MinorCategoryInfo)[], texts?: (TextCategoryInfo)[] }` OK
     */
    getStudentCategories: (params?: RequestParams) =>
      this.request<
        {
          grades: GradeCategoryInfo[];
          subjects: SubjectCategoryInfo[];
          major_categories: MajorCategoryInfo[];
          middle_categories: MiddleCategoryInfo[];
          minor_categories: MinorCategoryInfo[];
          texts?: TextCategoryInfo[];
        },
        any
      >(`/student/categories`, "GET", params),

    /**
     * No description
     *
     * @tags Student/Notification
     * @name NotificationsList
     * @request GET:/student/notifications
     * @response `200` `{ notifications?: ((NotificationBase & { read?: boolean }))[], total_count?: number }` OK
     */
    notificationsList: (query: NotificationsListParams, params?: RequestParams) =>
      this.request<{ notifications?: (NotificationBase & { read?: boolean })[]; total_count?: number }, any>(
        `/student/notifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a student
     *
     * @tags Student/Article
     * @name FetchArticlesAsStudent
     * @summary Articles Index
     * @request GET:/student/articles
     * @response `200` `{ articles: (ArticleBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    fetchArticlesAsStudent: (query: FetchArticlesAsStudentParams, params?: RequestParams) =>
      this.request<{ articles: ArticleBase[]; total_count: number }, BadRequest | Unauthorized | { error?: string }>(
        `/student/articles${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags articles, Student/Article
     * @name CreateArticleAsStudent
     * @summary 探究成果の登録
     * @request POST:/student/articles
     * @response `201` `Article` created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createArticleAsStudent: (
      data: {
        title: string;
        body?: string;
        kind?: ArticleKind;
        cover?: string;
        sponsor_logo?: File;
        image_contents?: File[];
        add_group_affiliate_ids?: string[];
        add_or_update_categorizable_id?: string;
        add_or_update_categorizable_type?: AllowedArticleCategorizableType;
        is_article_sponsor_content?: boolean;
        pbl_sponsor_article_content_template_id?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<Article, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/articles`,
        "POST",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * No description
     *
     * @tags Student/Article, articles
     * @name FetchArticleAsStudent
     * @summary 探究成果記事の詳細を取得
     * @request GET:/student/articles/{article_id}
     * @response `200` `Article` OK
     * @response `404` `{ error?: string }`
     */
    fetchArticleAsStudent: (article_id: string, params?: RequestParams) =>
      this.request<Article, { error?: string }>(`/student/articles/${article_id}`, "GET", params),

    /**
     * No description
     *
     * @tags articles, Student/Article
     * @name UpdateArticleAsStudent
     * @summary 探究成果の更新
     * @request PUT:/student/articles/{article_id}
     * @response `202` `Article` accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updateArticleAsStudent: (
      article_id: string,
      data: {
        title: string;
        body: string;
        updated_at_with_second: string;
        body_theme?: string;
        body_background?: string;
        body_hypothesis?: string;
        body_survey_method?: string;
        body_result?: string;
        body_summary?: string;
        body_future_issues?: string;
        cover?: string;
        delete_cover?: boolean;
        sponsor_logo?: File;
        add_group_affiliate_ids?: string[];
        remove_group_affiliate_ids?: string[];
        add_or_update_categorizable_id?: string;
        add_or_update_categorizable_type?: AllowedArticleCategorizableType;
        remove_categorizable_id?: string;
        remove_categorizable_type?: AllowedArticleCategorizableType;
        article_sponsor_contents?: { pbl_sponsor_article_content_template_field_id: string; body: string }[];
      },
      params?: RequestParams,
    ) =>
      this.request<Article, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/articles/${article_id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * No description
     *
     * @tags articles, Student/Article
     * @name DeleteArticleAsStudent
     * @summary 探究成果の削除
     * @request DELETE:/student/articles/{article_id}
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    deleteArticleAsStudent: (article_id: string, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden | { error?: string }>(
        `/student/articles/${article_id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags articles, Student/Article
     * @name SubmitArticleToTeacherAsStudent
     * @summary 探究成果を先生確認に進める
     * @request POST:/student/articles/{article_id}/submit_to_teacher
     * @response `200` `Article` OK
     * @response `404` `{ error?: string }`
     */
    submitArticleToTeacherAsStudent: (article_id: string, params?: RequestParams) =>
      this.request<Article, { error?: string }>(`/student/articles/${article_id}/submit_to_teacher`, "POST", params),

    /**
     * @description Return articles to the current user logged in as a student
     *
     * @tags Student/Article
     * @name FetchArticleCommentsAsStudent
     * @summary Article Comments Index
     * @request GET:/student/articles/{article_id}/comments
     * @response `200` `{ article_comments: (ArticleComment)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    fetchArticleCommentsAsStudent: (
      { article_id, ...query }: FetchArticleCommentsAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { article_comments: ArticleComment[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/articles/${article_id}/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Student限定 探究成果物のコメントを保存する
     *
     * @tags Student/ArticleComments
     * @name PostArticleCommentsAsStudent
     * @summary 探究成果物のコメントを保存
     * @request POST:/student/articles/{article_id}/comments
     * @response `201` `ArticleComment` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postArticleCommentsAsStudent: (
      article_id: string,
      data: { body: string; comment_images?: File[] },
      params?: RequestParams,
    ) =>
      this.request<ArticleComment, Unauthorized | Forbidden>(
        `/student/articles/${article_id}/comments`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Student限定 探究成果物のコメント詳細を更新
     *
     * @tags Student/ArticleComments
     * @name PutArticleCommentAsStudent
     * @summary 探究成果物のコメント詳細を更新
     * @request PUT:/student/articles/{article_id}/comments/{id}
     * @response `202` `ArticleComment` Accepted
     * @response `400` `BadRequest`
     */
    putArticleCommentAsStudent: (article_id: string, id: string, data: { body: string }, params?: RequestParams) =>
      this.request<ArticleComment, BadRequest>(`/student/articles/${article_id}/comments/${id}`, "PUT", params, data),

    /**
     * @description Student限定 探究成果物のコメント詳細を削除
     *
     * @tags Student/ArticleComments
     * @name DeleteArticleCommentAsStudent
     * @summary 探究成果物のコメント詳細を削除
     * @request DELETE:/student/articles/{article_id}/comments/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteArticleCommentAsStudent: (article_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/student/articles/${article_id}/comments/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a student
     *
     * @tags Student/Article
     * @name FetchArticleFeedbacksAsStudent
     * @summary Article Feedbacks Index
     * @request GET:/student/articles/{article_id}/feedbacks
     * @response `200` `{ article_feedbacks: (ArticleFeedback)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `500` `any` Internal Server Error
     */
    fetchArticleFeedbacksAsStudent: (
      { article_id, ...query }: FetchArticleFeedbacksAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<{ article_feedbacks: ArticleFeedback[]; total_count: number }, BadRequest | Unauthorized>(
        `/student/articles/${article_id}/feedbacks${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a student
     *
     * @tags Student/Article
     * @name FetchReviewerCompaniesAsStudent
     * @summary Reviewer Company Index
     * @request GET:/student/article/reviewer_companies
     * @response `200` `{ companies: (ReviewerCompany)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `500` `any` Internal Server Error
     */
    fetchReviewerCompaniesAsStudent: (params?: RequestParams) =>
      this.request<{ companies: ReviewerCompany[] }, BadRequest | Unauthorized>(
        `/student/article/reviewer_companies`,
        "GET",
        params,
      ),

    /**
     * @description - "Student限定 探究成果物に生徒（Affiliate）を紐づける" - "指定された生徒もコンテンツの閲覧、編集可能になる"
     *
     * @tags Student/ArticleAffiliates
     * @name PostArticleAffiliatesAsStudent
     * @summary 探究成果物に生徒を紐づける
     * @request POST:/student/articles/article_affiliates
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postArticleAffiliatesAsStudent: (data: { article_id: string; affiliate_id: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/articles/article_affiliates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - "Student限定 探究成果物に紐づいている生徒を削除する"
     *
     * @tags Student/ArticleAffiliates
     * @name DeleteArticleAffiliatesAsStudent
     * @summary 探究成果物に紐づいている生徒を削除する
     * @request DELETE:/student/articles/article_affiliates
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteArticleAffiliatesAsStudent: (data: { article_id: string; affiliate_id: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/articles/article_affiliates`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description - Student限定 自身が作成し提出済みとなった探究成果の一覧を返却 - メイン画像、タイトル、いいね数、コメント数を含む
     *
     * @tags Student/Article
     * @name FetchArticleStatsAsStudent
     * @summary 自身が作成し提出済みとなった探究成果の一覧を返却
     * @request GET:/student/articles/stats
     * @response `200` `{ articles: (ArticleBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchArticleStatsAsStudent: (query: FetchArticleStatsAsStudentParams, params?: RequestParams) =>
      this.request<
        { articles: ArticleBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/articles/stats${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - this endpoint needs word_cloud_feature
     *
     * @tags Student/Article
     * @name FetchArticleWordCloudAsStudent
     * @summary 自身が閲覧可能な探究成果から生成したワードクラウドリストを返却
     * @request GET:/student/articles/word_cloud
     * @response `200` `WordCloudBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchArticleWordCloudAsStudent: (query: FetchArticleWordCloudAsStudentParams, params?: RequestParams) =>
      this.request<WordCloudBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/articles/word_cloud${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - "Student限定 探究成果の画像を登録
     *
     * @tags Student/ArticleImages
     * @name PostArticleImagesAsStudent
     * @summary 探究成果の画像を登録
     * @request POST:/student/articles/{article_id}/article_images
     * @response `201` `ArticleImageBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postArticleImagesAsStudent: (article_id: string, data: FileRequiredRequestBody, params?: RequestParams) =>
      this.request<ArticleImageBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/articles/${article_id}/article_images`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - "Student限定 探究成果の画像を更新"
     *
     * @tags Student/ArticleImages
     * @name PutArticleImagesAsStudent
     * @summary 探究成果の画像を更新
     * @request PUT:/student/articles/{article_id}/article_images/{id}
     * @response `202` `ArticleImageBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putArticleImagesAsStudent: (article_id: string, id: string, data: { file: File }, params?: RequestParams) =>
      this.request<ArticleImageBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/articles/${article_id}/article_images/${id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description - "Student限定 探究成果の画像を削除"
     *
     * @tags Student/ArticleImages
     * @name DeleteControllerAsStudent
     * @summary 探究成果の画像を削除
     * @request DELETE:/student/articles/{article_id}/article_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteControllerAsStudent: (article_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/articles/${article_id}/article_images/${id}`,
        "DELETE",
        params,
      ),

    /**
     * No description
     *
     * @tags Student/PblReportComment
     * @name PutStudentPblReportCommentId
     * @summary コメントの更新
     * @request PUT:/student/pbl/report/comments/{id}
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putStudentPblReportCommentId: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(`/student/pbl/report/comments/${id}`, "PUT", params),

    /**
     * No description
     *
     * @tags Student/PblReportComment
     * @name PblReportCommentsList
     * @summary コメント一覧の取得
     * @request GET:/student/pbl/report/comments
     * @response `200` `(PblReportCommentBase)[]` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    pblReportCommentsList: (query: PblReportCommentsListParams, params?: RequestParams) =>
      this.request<PblReportCommentBase[], Unauthorized | Forbidden>(
        `/student/pbl/report/comments${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Student/PblReportComment
     * @name PblReportCommentsCreate
     * @summary コメントの新規作成
     * @request POST:/student/pbl/report/comments
     * @response `201` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    pblReportCommentsCreate: (
      data: { message?: string; output_id?: string; output_type?: "answer" | "answer_item" },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/student/pbl/report/comments`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/PblReportAnswer
     * @name FetchPblReportAnswersAsStudent
     * @summary 回答の一覧を取得
     * @request GET:/student/pbl/report/answers
     * @response `200` `{ total_count: number, answers: (PblReportAnswerForStudent)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchPblReportAnswersAsStudent: (query: FetchPblReportAnswersAsStudentParams, params?: RequestParams) =>
      this.request<{ total_count: number; answers: PblReportAnswerForStudent[] }, Unauthorized | Forbidden>(
        `/student/pbl/report/answers${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Student/PblReportAnswer
     * @name UpdatePblReportAnswerAsStudent
     * @summary 回答の更新
     * @request PUT:/student/pbl/report/answers/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    updatePblReportAnswerAsStudent: (
      id: string,
      data: { event?: PblReportAnswerStudentEventEnum },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/report/answers/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/PblReportAnswer
     * @name FetchPblReportAnswerAsStudent
     * @summary 回答詳細の取得
     * @request GET:/student/pbl/report/answers/{id}
     * @response `200` `PblReportAnswerDetailsForStudent` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchPblReportAnswerAsStudent: (id: string, params?: RequestParams) =>
      this.request<PblReportAnswerDetailsForStudent, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/report/answers/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - "Student限定 探究レポートの画像を登録
     *
     * @tags Student/PblReportAnswerImages
     * @name PostPblReportAnswerImagesAsStudent
     * @summary 探究レポートの画像を登録
     * @request POST:/student/pbl/report/answer_images
     * @response `201` `PblReportAnswerImageBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblReportAnswerImagesAsStudent: (data: PblReportAnswerImageRequest, params?: RequestParams) =>
      this.request<PblReportAnswerImageBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/report/answer_images`,
        "POST",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description - "Student限定 探究レポートの画像を更新"
     *
     * @tags Student/PblReportAnswerImages
     * @name PutPblReportAnswerImagesAsStudent
     * @summary 探究レポートの画像を更新
     * @request PUT:/student/pbl/report/answer_images/{id}
     * @response `202` `PblReportAnswerImageBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblReportAnswerImagesAsStudent: (id: string, data: PblReportAnswerImageRequest, params?: RequestParams) =>
      this.request<PblReportAnswerImageBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/report/answer_images/${id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description - "Student限定 探究レポートの画像を削除"
     *
     * @tags Student/PblReportAnswerImages
     * @name DeletePblReportAnswerImagesAsStudent
     * @summary 探究レポートの画像を削除
     * @request DELETE:/student/pbl/report/answer_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblReportAnswerImagesAsStudent: (id: string, data: { answer_id: string }, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/report/answer_images/${id}`,
        "DELETE",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/PblReportAnswerItem
     * @name UpdatePblReportAnswerItemAsStudent
     * @summary 回答の更新
     * @request PUT:/student/pbl/report/answer_items/{id}
     * @response `202` `PblReportAnswerTextBaseWithComments` Accepted
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updatePblReportAnswerItemAsStudent: (id: string, data: { title?: string; body?: string }, params?: RequestParams) =>
      this.request<PblReportAnswerTextBaseWithComments, Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/student/pbl/report/answer_items/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/PblReportAnswerItem
     * @name CreatePblReportAnswerItemAsStudent
     * @summary 回答項目の作成
     * @request POST:/student/pbl/report/answer_items
     * @response `201` `PblReportAnswerTextBaseWithComments` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createPblReportAnswerItemAsStudent: (
      data: { answer_id: string; item_id: string; title?: string; body?: string },
      params?: RequestParams,
    ) =>
      this.request<PblReportAnswerTextBaseWithComments, Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/student/pbl/report/answer_items`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/PblCardsInformationLiteracyBookmark
     * @name FetchPblCardBookmarksAsStudent
     * @summary 自分のブックマーク一覧の取得
     * @request GET:/student/pbl/cards/information_literacy/bookmarks
     * @response `200` `{ bookmarks?: (PblCardBookmarkList)[], total_count?: number }` OK
     * @response `403` `Forbidden`
     */
    fetchPblCardBookmarksAsStudent: (query: FetchPblCardBookmarksAsStudentParams, params?: RequestParams) =>
      this.request<{ bookmarks?: PblCardBookmarkList[]; total_count?: number }, Forbidden>(
        `/student/pbl/cards/information_literacy/bookmarks${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Student/PblCardsInformationLiteracyBookmark
     * @name CreatePblCardBookmarkAsStudent
     * @summary 新規ブックマークの作成
     * @request POST:/student/pbl/cards/information_literacy/bookmarks
     * @response `201` `PblCardBookmarkBase` Created
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createPblCardBookmarkAsStudent: (data: PostPblCardBookmarkRequestBody, params?: RequestParams) =>
      this.request<PblCardBookmarkBase, Forbidden | ErrorsWithKeyMessage>(
        `/student/pbl/cards/information_literacy/bookmarks`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/PblCardsInformationLiteracyBookmark
     * @name FetchPblCardBookmarkAsStudent
     * @summary Bookmarkの詳細
     * @request GET:/student/pbl/cards/information_literacy/bookmarks/{id}
     * @response `200` `PblCardBookmarkBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    fetchPblCardBookmarkAsStudent: (id: string, params?: RequestParams) =>
      this.request<
        PblCardBookmarkBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/pbl/cards/information_literacy/bookmarks/${id}`, "GET", params),

    /**
     * @description Bookmarkの更新
     *
     * @tags Student/PblCardsInformationLiteracyBookmark
     * @name UpdatePblCardBookmarkAsStudent
     * @request PUT:/student/pbl/cards/information_literacy/bookmarks/{id}
     * @response `200` `PblCardBookmarkBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updatePblCardBookmarkAsStudent: (id: string, data: PutPblCardBookmarkRequestBody, params?: RequestParams) =>
      this.request<
        PblCardBookmarkBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/pbl/cards/information_literacy/bookmarks/${id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Student/PblCardsInformationLiteracyBookmark
     * @name DeletePblCardBookmarkAsStudent
     * @summary Bookmarkの削除
     * @request DELETE:/student/pbl/cards/information_literacy/bookmarks/{id}
     * @response `204` `any` No content
     * @response `403` `Forbidden`
     */
    deletePblCardBookmarkAsStudent: (id: string, params?: RequestParams) =>
      this.request<any, Forbidden>(`/student/pbl/cards/information_literacy/bookmarks/${id}`, "DELETE", params),

    /**
     * No description
     *
     * @tags Student/PblCardsInformationLiteracyBookmark
     * @name UpdatePblCardBookmarkWysiwygBodyAsStudent
     * @summary BookmarkのWysiwygBodyの更新
     * @request PUT:/student/pbl/cards/information_literacy/bookmarks/{id}/update_wysiwyg_body
     * @response `200` `PblCardBookmarkBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updatePblCardBookmarkWysiwygBodyAsStudent: (id: string, data: any, params?: RequestParams) =>
      this.request<
        PblCardBookmarkBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/pbl/cards/information_literacy/bookmarks/${id}/update_wysiwyg_body`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Student/PblCardsInformationLiteracyBookmarkImage
     * @name DeletePblCardBookmarkImageAsStudent
     * @summary BookmarkImageの削除
     * @request DELETE:/student/pbl/cards/information_literacy/bookmark_images/{id}
     * @response `204` `any` No content
     */
    deletePblCardBookmarkImageAsStudent: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/student/pbl/cards/information_literacy/bookmark_images/${id}`, "DELETE", params),

    /**
     * No description
     *
     * @tags Student/PblCardsInformationLiteracyComment
     * @name FetchPblCardBookmarkCommentsAsStudent
     * @summary コメントの一覧を取得
     * @request GET:/student/pbl/cards/information_literacy/comments
     * @response `200` `{ pbl_card_comments?: (PblCardCommentBase)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchPblCardBookmarkCommentsAsStudent: (
      query: FetchPblCardBookmarkCommentsAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { pbl_card_comments?: PblCardCommentBase[]; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/pbl/cards/information_literacy/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Student/PblCardsInformationLiteracyComment
     * @name CreatePblCardBookmarkCommentAsStudent
     * @summary コメントの新規作成
     * @request POST:/student/pbl/cards/information_literacy/comments
     * @response `201` `PblCardCommentBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createPblCardBookmarkCommentAsStudent: (data: { bookmark_id: string; body: string }, params?: RequestParams) =>
      this.request<
        PblCardCommentBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/pbl/cards/information_literacy/comments`, "POST", params, data),

    /**
     * No description
     *
     * @tags Student/PblCardsInformationLiteracyComment
     * @name UpdatePblCardBookmarkCommentAsStudent
     * @summary コメントの更新
     * @request PUT:/student/pbl/cards/information_literacy/comments/{id}
     * @response `200` `PblCardCommentBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updatePblCardBookmarkCommentAsStudent: (id: string, data: { body?: string }, params?: RequestParams) =>
      this.request<
        PblCardCommentBase,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/pbl/cards/information_literacy/comments/${id}`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Teacher/PblCardsInformationLiteracyComment
     * @name DeletePblCardBookmarkCommentAsStudent
     * @summary コメントの削除
     * @request DELETE:/student/pbl/cards/information_literacy/comments/{id}
     * @response `204` `any` No content
     * @response `403` `Forbidden`
     */
    deletePblCardBookmarkCommentAsStudent: (id: string, params?: RequestParams) =>
      this.request<any, Forbidden>(`/student/pbl/cards/information_literacy/comments/${id}`, "DELETE", params),

    /**
     * No description
     *
     * @tags Student/Stamp
     * @name GetPblCardBookmarkStampsAsStudent
     * @summary スタンプ一覧の取得
     * @request GET:/student/stamps
     * @response `200` `{ stamp_fields: (StampBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getPblCardBookmarkStampsAsStudent: (query: GetPblCardBookmarkStampsAsStudentParams, params?: RequestParams) =>
      this.request<
        { stamp_fields: StampBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/stamps${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Student/Stamp
     * @name PostPblCardBookmarkStampAsStudent
     * @summary スタンプの作成
     * @request POST:/student/stamps
     * @response `200` `StampBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postPblCardBookmarkStampAsStudent: (
      data: { stampable_type: StampableType; stampable_id: string; actor_id: string; kind: StampType },
      params?: RequestParams,
    ) =>
      this.request<StampBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/stamps`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/Stamp
     * @name PutPblCardBookmarkStampAsStudent
     * @summary スタンプの更新
     * @request PUT:/student/stamps/{id}
     * @response `200` `StampBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putPblCardBookmarkStampAsStudent: (id: string, data: { kind: StampType }, params?: RequestParams) =>
      this.request<StampBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/stamps/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Student/Stamp
     * @name DeletePblCardBookmarkStampAsStudent
     * @summary スタンプの削除
     * @request DELETE:/student/stamps/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deletePblCardBookmarkStampAsStudent: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/stamps/${id}`, "DELETE", params),

    /**
     * @description Google Classroom で同期したコースの一覧を返す
     *
     * @tags Student/GoogleIntegrations
     * @name FetchGoogleIntegrationCoursesAsStudent
     * @summary Google Integration のコース一覧取得
     * @request GET:/student/google_integrations
     * @response `200` `{ gi_courses: ((ModelBase & { course_id?: string, name?: string, section?: string, room?: string, courses?: ({ course_work_id?: string, title?: string })[] }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     */
    fetchGoogleIntegrationCoursesAsStudent: (
      query: FetchGoogleIntegrationCoursesAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          gi_courses: (ModelBase & {
            course_id?: string;
            name?: string;
            section?: string;
            room?: string;
            courses?: { course_work_id?: string; title?: string }[];
          })[];
          total_count: number;
        },
        BadRequest | Forbidden
      >(`/student/google_integrations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Google Classroom で同期したコース学習の一覧を返す
     *
     * @tags Student/GoogleIntegrations
     * @name FetchGoogleIntegrationCourseWorksAsStudent
     * @summary Google Integration のコース学習の一覧取得
     * @request GET:/student/google_integrations/{course_id}
     * @response `200` `{ gi_course_works: ((ModelBase & { course_work_id?: string, title?: string }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     */
    fetchGoogleIntegrationCourseWorksAsStudent: (
      { course_id, ...query }: FetchGoogleIntegrationCourseWorksAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { gi_course_works: (ModelBase & { course_work_id?: string; title?: string })[]; total_count: number },
        BadRequest | Forbidden
      >(`/student/google_integrations/${course_id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Google Classroom で同期したDriveファイルの一覧を返す
     *
     * @tags Student/GoogleIntegrations
     * @name FetchGoogleIntegrationCourseWorkMaterialsAsStudent
     * @summary Google Integration のDriveファイルの一覧取得
     * @request GET:/student/google_integrations/{course_id}/{course_work_id}
     * @response `200` `{ gi_materials: ((ModelBase & { material_id?: string, title?: string, alternate_link?: string, thumbnail_url?: string }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     */
    fetchGoogleIntegrationCourseWorkMaterialsAsStudent: (
      { course_id, course_work_id, ...query }: FetchGoogleIntegrationCourseWorkMaterialsAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          gi_materials: (ModelBase & {
            material_id?: string;
            title?: string;
            alternate_link?: string;
            thumbnail_url?: string;
          })[];
          total_count: number;
        },
        BadRequest | Forbidden
      >(`/student/google_integrations/${course_id}/${course_work_id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 Pbl::Steam:Content を一覧を返却
     *
     * @tags Student/Steam/Contents
     * @name GetSteamContentsAsStudent
     * @summary Steamライブラリーコンテンツの一覧を返却
     * @request GET:/student/pbl/steam/contents
     * @response `200` `{ steam_contents?: (PblSteamContent)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSteamContentsAsStudent: (query: GetSteamContentsAsStudentParams, params?: RequestParams) =>
      this.request<
        { steam_contents?: PblSteamContent[]; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/pbl/steam/contents${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Student限定 Steamライブラリーのコンテンツの情報取得
     *
     * @tags Student/Steam/Contents
     * @name GetStudentSteamContentsId
     * @summary Student: Steamライブラリーコンテンツの情報参照
     * @request GET:/student/pbl/steam/contents/{id}
     * @response `200` `StudentPblSteamContentDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentSteamContentsId: (id: string, params?: RequestParams) =>
      this.request<StudentPblSteamContentDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/steam/contents/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 Steamライブラリーのコンテンツ一覧を返却
     *
     * @tags Student/Steam/Contents
     * @name SearchStudentSteamContents
     * @summary Student: Steamライブラリーコンテンツ一覧の取得
     * @request GET:/student/pbl/steam/contents/search
     * @response `200` `{ steam_contents: (StudentPblSteamContentDetail)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    searchStudentSteamContents: (query: SearchStudentSteamContentsParams, params?: RequestParams) =>
      this.request<{ steam_contents: StudentPblSteamContentDetail[]; total_count: number }, Unauthorized | Forbidden>(
        `/student/pbl/steam/contents/search${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 Steamライブラリーのレクチャーを登録
     *
     * @tags Student/Steam/Lectures
     * @name PostStudentSteamLectures
     * @summary Student: Steamライブラリーコンテンツのレクチャーを登録
     * @request POST:/student/pbl/steam/lectures
     * @response `201` `PblSteamLecture` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postStudentSteamLectures: (
      query: PostStudentSteamLecturesParams,
      data: CreateSteamLectureRequestBody,
      params?: RequestParams,
    ) =>
      this.request<PblSteamLecture, Unauthorized | Forbidden>(
        `/student/pbl/steam/lectures${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Student限定 Steamライブラリーのコンテンツのレクチャー名の情報取得
     *
     * @tags Student/Steam/Lectures
     * @name GetStudentSteamLecturesId
     * @summary Student: Steamライブラリーコンテンツのレクチャー名の情報参照
     * @request GET:/student/pbl/steam/lectures/{id}
     * @response `200` `PblSteamLecture` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentSteamLecturesId: (id: string, params?: RequestParams) =>
      this.request<PblSteamLecture, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/steam/lectures/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 Steamライブラリーのレクチャーに対するコメント一覧を返却
     *
     * @tags Student/Steam/Lectures
     * @name GetStudentSteamLectureComments
     * @summary Student: Steamライブラリーのレクチャーに対するコメント一覧の取得
     * @request GET:/student/pbl/steam/lectures/{id}/comments
     * @response `200` `{ comments?: (PblSteamCommentBase)[], total_count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getStudentSteamLectureComments: ({ id, ...query }: GetStudentSteamLectureCommentsParams, params?: RequestParams) =>
      this.request<{ comments?: PblSteamCommentBase[]; total_count?: number }, Unauthorized | Forbidden>(
        `/student/pbl/steam/lectures/${id}/comments${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 Steamライブラリーのコンテンツのレクチャーに対するコメント一覧を返却
     *
     * @tags Student/Steam/Comments
     * @name GetStudentSteamComments
     * @summary Student: Steamライブラリーコンテンツのレクチャー名に対するコメント一覧の取得
     * @request GET:/student/pbl/steam/comments
     * @response `200` `{ steam_content_comments?: (PblSteamCommentBase)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentSteamComments: (query: GetStudentSteamCommentsParams, params?: RequestParams) =>
      this.request<
        { steam_content_comments?: PblSteamCommentBase[]; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/pbl/steam/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Student限定 Steamライブラリーのコンテンツのレクチャーに対するコメントを登録
     *
     * @tags Student/Steam/Comments
     * @name PostStudentSteamComments
     * @summary Student: Steamライブラリーコンテンツのレクチャーに対するコメントの登録
     * @request POST:/student/pbl/steam/comments
     * @response `201` `PblSteamCommentBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postStudentSteamComments: (
      data: { lecture_id: string; is_public: boolean; body: string },
      params?: RequestParams,
    ) =>
      this.request<PblSteamCommentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/steam/comments`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Student限定 Steamライブラリーのコンテンツのレクチャーに対するコメントの情報の更新
     *
     * @tags Student/Steam/Comments
     * @name PutStudentSteamContentsId
     * @summary Student: Steamライブラリーコンテンツのレクチャーに対するコメントの情報更新
     * @request PUT:/student/pbl/steam/comments/{id}
     * @response `202` `PblSteamCommentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putStudentSteamContentsId: (id: string, data: { is_public?: boolean; body?: string }, params?: RequestParams) =>
      this.request<PblSteamCommentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/steam/comments/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Student限定 Steamライブラリーのコンテンツの情報のレクチャーに対するコメントの削除
     *
     * @tags Student/Steam/Comments
     * @name DeleteStudentSteamContentsId
     * @summary Student: Steamライブラリーコンテンツのレクチャーに対するコメントの削除
     * @request DELETE:/student/pbl/steam/comments/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteStudentSteamContentsId: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/student/pbl/steam/comments/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Student限定  閲覧中または閲覧済みのSteamライブラリーのコンテンツ一覧を返却
     *
     * @tags Student/Steam/ContentWorks
     * @name GetStudentSteamContentWorks
     * @summary Student: 閲覧中または閲覧済みのSteamライブラリーコンテンツ一覧の取得
     * @request GET:/student/pbl/steam/content_works
     * @response `200` `{ steam_contents?: (StudentPblSteamContentDetail)[], total_count?: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentSteamContentWorks: (query: GetStudentSteamContentWorksParams, params?: RequestParams) =>
      this.request<
        { steam_contents?: StudentPblSteamContentDetail[]; total_count?: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/pbl/steam/content_works${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Student限定 Steamライブラリーのコンテンツの登録
     *
     * @tags Student/Steam/ContentWorks
     * @name PostStudentSteamContentWorks
     * @summary Student: Steamライブラリーコンテンツ一覧の登録
     * @request POST:/student/pbl/steam/content_works
     * @response `201` `StudentPblSteamContentWork` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postStudentSteamContentWorks: (
      query: PostStudentSteamContentWorksParams,
      data: { progress?: string },
      params?: RequestParams,
    ) =>
      this.request<StudentPblSteamContentWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/steam/content_works${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Student限定 Steamライブラリーのコンテンツの情報取得
     *
     * @tags Student/Steam/ContentWorks
     * @name GetStudentSteamContentWorksId
     * @summary Student: Steamライブラリーコンテンツの情報参照
     * @request GET:/student/pbl/steam/content_works/{id}
     * @response `200` `StudentPblSteamContentWork` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudentSteamContentWorksId: (id: string, params?: RequestParams) =>
      this.request<StudentPblSteamContentWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/steam/content_works/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 Steamライブラリーのコンテンツのレクチャーに対するコメントの情報の更新
     *
     * @tags Student/Steam/ContentWorks
     * @name PutStudentSteamContentWorksId
     * @summary Student: Steamライブラリーコンテンツのレクチャーに対するコメントの情報更新
     * @request PUT:/student/pbl/steam/content_works/{id}
     * @response `202` `StudentPblSteamContentWork` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putStudentSteamContentWorksId: (id: string, data: { progress?: string }, params?: RequestParams) =>
      this.request<StudentPblSteamContentWork, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/steam/content_works/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Student限定 Steamライブラリーのレクチャーの閲覧ログ一覧を返却
     *
     * @tags Student/Steam/LectureHistories
     * @name GetSteamLectureHistoriesAsStudent
     * @summary Student: Steamライブラリーのレクチャーの閲覧ログ一覧の取得
     * @request GET:/student/pbl/steam/lecture_histories
     * @response `200` `{ steam_histories?: (StudentLectureHistory)[], total_count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getSteamLectureHistoriesAsStudent: (query: GetSteamLectureHistoriesAsStudentParams, params?: RequestParams) =>
      this.request<{ steam_histories?: StudentLectureHistory[]; total_count?: number }, Unauthorized | Forbidden>(
        `/student/pbl/steam/lecture_histories${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 Steamライブラリーのレクチャーの閲覧ログを登録
     *
     * @tags Student/Steam/LectureHistories
     * @name PostSteamLectureHistoriesAsStudent
     * @summary Student: Steamライブラリーのレクチャーの閲覧ログを登録
     * @request POST:/student/pbl/steam/lecture_histories
     * @response `201` `StudentLectureHistory` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postSteamLectureHistoriesAsStudent: (data: { sl_lecture_id?: number }, params?: RequestParams) =>
      this.request<StudentLectureHistory, Unauthorized | Forbidden>(
        `/student/pbl/steam/lecture_histories`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Student限定 自身のSTEAMライブラリー​の状況を返却 - 自分が検索・閲覧したコンテンツの件数を返却 - 全レクチャーを閲覧したコンテンツの件数を返却 - 自分が検索・閲覧したレクチャーの件数を返却 - コンテンツが受講済みになったレクチャーをの件数を返却
     *
     * @tags Student/Steam/LectureHistories
     * @name FetchSteamLectureHistoryStatsAsStudent
     * @summary 自身のSTEAMライブラリー​の状況を返却
     * @request GET:/student/pbl/steam/lecture_histories/stats
     * @response `200` `StudentLectureStats` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchSteamLectureHistoryStatsAsStudent: (
      query: FetchSteamLectureHistoryStatsAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<StudentLectureStats, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/pbl/steam/lecture_histories/stats${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 自身のSTEAMライブラリー​の履歴件数を返却
     *
     * @tags Student/Steam/LectureHistories
     * @name FetchSteamLectureHistoryCountAsStudent
     * @summary 自身のSTEAMライブラリー​の履歴件数を返却
     * @request GET:/student/pbl/steam/lecture_histories/count
     * @response `200` `{ steam_contents: (StudentLectureHistoryCount)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchSteamLectureHistoryCountAsStudent: (
      query: FetchSteamLectureHistoryCountAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { steam_contents: StudentLectureHistoryCount[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/pbl/steam/lecture_histories/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Student限定 Steamライブラリーのレクチャーの閲覧ログの削除
     *
     * @tags Student/Steam/LectureHistories
     * @name DeleteSteamLectureHistoryAsStudent
     * @summary Student: Steamライブラリーのレクチャーの閲覧ログの削除
     * @request DELETE:/student/pbl/steam/lecture_histories/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteSteamLectureHistoryAsStudent: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/student/pbl/steam/lecture_histories/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Student限定 Steamライブラリーのおすすめコンテンツ一覧を返却
     *
     * @tags Student/Steam/ContentRecommendations
     * @name GetSteamContentRecommendationsAsStudent
     * @summary Steamライブラリーのおすすめコンテンツの一覧を返却
     * @request GET:/student/pbl/steam/content_recommendations
     * @response `200` `{ steam_content_recommendations: (ContentRecommendationBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSteamContentRecommendationsAsStudent: (
      query: GetSteamContentRecommendationsAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { steam_content_recommendations: ContentRecommendationBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/pbl/steam/content_recommendations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 提出物のカスタムフィールドの値を登録 - group_affiliate_package_id: グループ向けの提出物に関連する場合必須。
     *
     * @tags Student/HomeworkTemplateCustomValues
     * @name PostStudentHomeworkTemplateCustomValues
     * @summary Student: 提出物のカスタムフィールドの値を登録
     * @request POST:/student/homework_template_custom_values
     * @response `201` `{ homework_template_custom_fields: (HomeworkTemplateCustomFieldAndValue)[] }` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `409` `{ message?: string, hashid?: string, body?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postStudentHomeworkTemplateCustomValues: (
      query: PostStudentHomeworkTemplateCustomValuesParams,
      data: CustomValueRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[] },
        | Unauthorized
        | Forbidden
        | { error?: string }
        | { message?: string; hashid?: string; body?: string }
        | ErrorsWithKeyMessage
      >(`/student/homework_template_custom_values${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description - Student限定 提出物のカスタムフィールドの値の情報の更新 - group_affiliate_package_id: グループ向けの提出物に関連する場合必須。
     *
     * @tags Student/HomeworkTemplateCustomValues
     * @name PutStudentHomeworkTemplateCustomValuesId
     * @summary Student: 提出物のカスタムフィールドの値の情報更新
     * @request PUT:/student/homework_template_custom_values/{id}
     * @response `202` `{ homework_template_custom_fields: (HomeworkTemplateCustomFieldAndValue)[] }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putStudentHomeworkTemplateCustomValuesId: (id: string, data: CustomValueRequestBody, params?: RequestParams) =>
      this.request<
        { homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[] },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/homework_template_custom_values/${id}`, "PUT", params, data),

    /**
     * @description - Student限定 提出物のカスタムフィールドの値の情報の削除 - group_affiliate_package_id: グループ向けの提出物に関連する場合必須。
     *
     * @tags Student/HomeworkTemplateCustomValues
     * @name DeleteStudentHomeworkTemplateCustomValuesId
     * @summary Student: 提出物のカスタムフィールドの値の削除
     * @request DELETE:/student/homework_template_custom_values/{id}
     * @response `200` `{ homework_template_custom_fields: (HomeworkTemplateCustomFieldAndValue)[] }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteStudentHomeworkTemplateCustomValuesId: (
      id: string,
      data: { homework_id: string; group_affiliate_package_id?: string },
      params?: RequestParams,
    ) =>
      this.request<
        { homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/homework_template_custom_values/${id}`, "DELETE", params, data),

    /**
     * @description Student限定 探究学習授業計画の一覧を返却
     *
     * @tags Student/Plan/Items
     * @name GetPblPlanItemsAsStudent
     * @summary Student: 探究学習授業計画の一覧を返却
     * @request GET:/student/pbl/plan/items
     * @response `200` `{ plan_items: (TeacherPblPlanItemDetails)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getPblPlanItemsAsStudent: (query: GetPblPlanItemsAsStudentParams, params?: RequestParams) =>
      this.request<
        { plan_items: TeacherPblPlanItemDetails[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/pbl/plan/items${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 探究学習授業計画のカレンダー情報一覧
     *
     * @tags Student/Plan/Classes
     * @name GetPblPlanClassesAsStudent
     * @summary 探究学習授業計画のカレンダー情報一覧
     * @request GET:/student/pbl/plan/items/{item_id}/classes
     * @response `200` `{ plan_classes: (TeacherPblPlanClass)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblPlanClassesAsStudent: ({ item_id, ...query }: GetPblPlanClassesAsStudentParams, params?: RequestParams) =>
      this.request<
        { plan_classes: TeacherPblPlanClass[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/pbl/plan/items/${item_id}/classes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 探究学習授業計画のカレンダー情報一覧
     *
     * @tags Student/Plan/Classes
     * @name GetPblPlanContentsAsStudent
     * @summary 探究学習授業計画のカレンダー情報一覧
     * @request GET:/student/pbl/plan/items/{item_id}/classes/{class_id}/contents
     * @response `200` `{ plan_contents: (PblPlanContentNav)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblPlanContentsAsStudent: (
      { item_id, class_id, ...query }: GetPblPlanContentsAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { plan_contents: PblPlanContentNav[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/pbl/plan/items/${item_id}/classes/${class_id}/contents${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Student限定 お問い合わせ一覧を返却
     *
     * @tags Student/Inquiries
     * @name GetStudentInquiries
     * @summary Student: お問い合わせ一覧を返却
     * @request GET:/student/inquiries
     * @response `200` `{ inquiries: (InquirySimpleListBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getStudentInquiries: (query: GetStudentInquiriesParams, params?: RequestParams) =>
      this.request<{ inquiries: InquirySimpleListBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/student/inquiries${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 お問い合わせ情報取得
     *
     * @tags Student/Inquiries
     * @name GetStudentInquiriesId
     * @summary Student: 提出物のお問い合わせ情報参照
     * @request GET:/student/inquiries/{id}
     * @response `200` `InquirySimpleBase` OK
     * @response `400` `BadRequest`
     */
    getStudentInquiriesId: (id: string, params?: RequestParams) =>
      this.request<InquirySimpleBase, BadRequest>(`/student/inquiries/${id}`, "GET", params),

    /**
     * @description Student限定 新着コメントが存在するお問い合わせの件数を返却
     *
     * @tags Student/Inquiries
     * @name GetTheNumberOfNewCommentInquiriesAsStudent
     * @summary 新着コメントが存在するお問い合わせの件数を返却
     * @request GET:/student/inquiries/new_comment_count
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTheNumberOfNewCommentInquiriesAsStudent: (params?: RequestParams) =>
      this.request<{ message?: string }, Unauthorized | Forbidden>(
        `/student/inquiries/new_comment_count`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 お問い合わせコメントの登録
     *
     * @tags Student/InquiryComments
     * @name PostStudentInquiryComments
     * @summary Student: お問い合わせコメントの登録
     * @request POST:/student/inquiry_comments
     * @response `201` `InquiryComment` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postStudentInquiryComments: (
      query: PostStudentInquiryCommentsParams,
      data: { body: string },
      params?: RequestParams,
    ) =>
      this.request<InquiryComment, Unauthorized | Forbidden>(
        `/student/inquiry_comments${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Student限定 お問い合わせコメントの情報の更新
     *
     * @tags Student/InquiryComments
     * @name PutStudentInquiryCommentsId
     * @summary Student: お問い合わせコメントの情報更新
     * @request PUT:/student/inquiry_comments/{id}
     * @response `202` `InquiryComment` Accepted
     * @response `400` `BadRequest`
     */
    putStudentInquiryCommentsId: (
      { id, ...query }: PutStudentInquiryCommentsIdParams,
      data: { body: string; status?: string },
      params?: RequestParams,
    ) =>
      this.request<InquiryComment, BadRequest>(
        `/student/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Student限定 お問い合わせコメントの情報の削除
     *
     * @tags Student/InquiryComments
     * @name DeleteStudentInquiryCommentsId
     * @summary Student: お問い合わせコメントの削除
     * @request DELETE:/student/inquiry_comments/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteStudentInquiryCommentsId: ({ id, ...query }: DeleteStudentInquiryCommentsIdParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/student/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description Student限定 企業一覧(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags Student/Sponsor/Infos
     * @name GetStudentSponsorInfos
     * @summary 企業一覧を返却
     * @request GET:/student/sponsor/infos
     * @response `200` `{ sponsors: (SponsorInfoListBase)[], total_count: number, all_sponsor_names?: (string)[], all_sponsor_tags?: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getStudentSponsorInfos: (query: GetStudentSponsorInfosParams, params?: RequestParams) =>
      this.request<
        {
          sponsors: SponsorInfoListBase[];
          total_count: number;
          all_sponsor_names?: string[];
          all_sponsor_tags?: string[];
        },
        BadRequest | Unauthorized | Forbidden
      >(`/student/sponsor/infos${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Student限定 企業の詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags Student/Sponsor/Infos
     * @name GetStudentSponsorInfosId
     * @summary 企業の詳細情報を返却
     * @request GET:/student/sponsor/infos/{id}
     * @response `200` `SponsorInfoDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getStudentSponsorInfosId: (id: string, params?: RequestParams) =>
      this.request<SponsorInfoDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/student/sponsor/infos/${id}`,
        "GET",
        params,
      ),

    /**
     * @description 企業プロジェクトの一覧を返却。現状はブックマークしているプロジェクトの取得のみに利用
     *
     * @tags Student/Sponsor/Projects
     * @name FetchSponsorProjectsAsStudent
     * @summary 企業プロジェクトの一覧を返却
     * @request GET:/student/sponsor/projects
     * @response `200` `{ projects: (SponsorProjectAndTagsBase)[], total_count: number, all_project_names?: (string)[], all_project_tags?: (string)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchSponsorProjectsAsStudent: (query: FetchSponsorProjectsAsStudentParams, params?: RequestParams) =>
      this.request<
        {
          projects: SponsorProjectAndTagsBase[];
          total_count: number;
          all_project_names?: string[];
          all_project_tags?: string[];
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/sponsor/projects${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Student限定 企業プロジェクトの詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags Student/Sponsor/Projects
     * @name FetchOneSponsorProjectAsStudent
     * @summary 企業プロジェクトの詳細情報を返却
     * @request GET:/student/sponsor/projects/{id}
     * @response `200` `SponsorProjectDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchOneSponsorProjectAsStudent: (id: string, params?: RequestParams) =>
      this.request<SponsorProjectDetailsBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/sponsor/projects/${id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Student/Sponsor/Projects
     * @name FetchSponsorProjectsForCarouselAsStudent
     * @summary カルーセル表示用企業プロジェクトを取得
     * @request GET:/student/sponsor/projects/carousel
     * @response `200` `{ projects: (SponsorProjectBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectsForCarouselAsStudent: (
      query: FetchSponsorProjectsForCarouselAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<{ projects: SponsorProjectBase[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/student/sponsor/projects/carousel${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 企業プロジェクトのレクチャー数とレクチャー完了数を返却
     *
     * @tags Student/Sponsor/Projects
     * @name FetchSponsorProjectStatsAsStudent
     * @summary レクチャー数とレクチャー完了数を返却
     * @request GET:/student/sponsor/projects/{id}/stats
     * @response `200` `{ learning_progress?: { checked_lectures_count?: number, total_lectures_count?: number } }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorProjectStatsAsStudent: (id: string, params?: RequestParams) =>
      this.request<
        { learning_progress?: { checked_lectures_count?: number; total_lectures_count?: number } },
        BadRequest | Unauthorized | Forbidden
      >(`/student/sponsor/projects/${id}/stats`, "GET", params),

    /**
     * @description 企業プロジェクトのレクチャーの一覧を返却
     *
     * @tags Student/Sponsor/Lectures
     * @name FetchSponsorLecturesAsStudent
     * @summary 企業プロジェクトのレクチャーの一覧を返却
     * @request GET:/student/sponsor/projects/lectures
     * @response `200` `{ project_lectures: (SponsorLectureDetailsBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLecturesAsStudent: (query: FetchSponsorLecturesAsStudentParams, params?: RequestParams) =>
      this.request<
        { project_lectures: SponsorLectureDetailsBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden
      >(`/student/sponsor/projects/lectures${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Student限定 企業プロジェクトのレクチャーの詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags Student/Sponsor/Lectures
     * @name FetchSponsorLectureAsStudent
     * @summary 企業プロジェクトのレクチャーの詳細情報を返却
     * @request GET:/student/sponsor/projects/lectures/{id}
     * @response `200` `SponsorLectureDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLectureAsStudent: (id: string, params?: RequestParams) =>
      this.request<SponsorLectureDetailsBase, BadRequest | Unauthorized | Forbidden>(
        `/student/sponsor/projects/lectures/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - "Student限定 Pbl::Sponsor::ProjectWorkを登録"
     *
     * @tags Student/PblSponsorProjectWork
     * @name PostPblSponsorProjectWorkAsStudent
     * @summary Pbl::Sponsor::ProjectWorkを登録
     * @request POST:/student/sponsor/project_works
     * @response `201` `(ModelBase & { pbl_sponsor_project_id: string, student: AffiliateBase, progress: SponsorProjectWorkProgressJa, created_at: string, updated_at: string })` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblSponsorProjectWorkAsStudent: (data: { project_id: string }, params?: RequestParams) =>
      this.request<
        ModelBase & {
          pbl_sponsor_project_id: string;
          student: AffiliateBase;
          progress: SponsorProjectWorkProgressJa;
          created_at: string;
          updated_at: string;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/sponsor/project_works`, "POST", params, data),

    /**
     * @description - "Student限定 Pbl::Sponsor::ProjectWorkを更新"
     *
     * @tags Student/SponsorProjectWork
     * @name PutSponsorProjectWorkAsStudent
     * @summary Pbl::Sponsor::ProjectWorkを更新
     * @request PUT:/student/sponsor/project_works/{id}
     * @response `202` `any` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putSponsorProjectWorkAsStudent: (
      id: string,
      data: { project_id: string; progress: SponsorProjectWorkProgress },
      params?: RequestParams,
    ) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/sponsor/project_works/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Student限定 企業レクチャーの自分のコメントを一覧を返却
     *
     * @tags Student/Sponsor/Contents
     * @name GetSponsorContentsAsStudent
     * @summary 企業レクチャーの自分のコメントを返却
     * @request GET:/student/sponsor/comments
     * @response `200` `{ sponsor_lecture_comments: (PblProjectCommentBase)[], current_user_comment: PblProjectCommentBase, total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSponsorContentsAsStudent: (query: GetSponsorContentsAsStudentParams, params?: RequestParams) =>
      this.request<
        {
          sponsor_lecture_comments: PblProjectCommentBase[];
          current_user_comment: PblProjectCommentBase;
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/sponsor/comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Student限定 Pbl::Sponsor::Commentを登録
     *
     * @tags Student/Controllers
     * @name PostPblSponsorCommentAsStudent
     * @summary Pbl::Sponsor::Commentを登録
     * @request POST:/student/sponsor/comments
     * @response `201` `PblProjectCommentBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblSponsorCommentAsStudent: (
      query: PostPblSponsorCommentAsStudentParams,
      data: { body: string },
      params?: RequestParams,
    ) =>
      this.request<PblProjectCommentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/sponsor/comments${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - "Student限定 Pbl::Sponsor::Commentを更新"
     *
     * @tags Student/PblSponsorComment
     * @name PutPblSponsorCommentAsStudent
     * @summary Pbl::Sponsor::Commentを更新
     * @request PUT:/student/sponsor/comments/{id}
     * @response `202` `PblProjectCommentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putPblSponsorCommentAsStudent: (id: string, data: { body: string }, params?: RequestParams) =>
      this.request<PblProjectCommentBase, BadRequest | Unauthorized | Forbidden>(
        `/student/sponsor/comments/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "Student限定 Pbl::Sponsor::Commentを削除"
     *
     * @tags Student/PblSponsorComment
     * @name DeletePblSponsorCommentsStudent
     * @summary Pbl::Sponsor::Commentを削除
     * @request DELETE:/student/sponsor/comments/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deletePblSponsorCommentsStudent: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/student/sponsor/comments/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description Student限定 Pbl::Sponsor::ArticleContentTemplate を返却
     *
     * @tags Student/Sponsor/ArticleContentTemplate
     * @name GetSponsorArticleContentTemplateAsStudent
     * @summary 企業プロジェクトのポスターテンプレート詳細情報を返却
     * @request GET:/student/sponsor/article_content_templates/{id}
     * @response `200` `SponsorArticleContentTemplateBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getSponsorArticleContentTemplateAsStudent: (
      { id, ...query }: GetSponsorArticleContentTemplateAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<SponsorArticleContentTemplateBase, BadRequest | Unauthorized | Forbidden>(
        `/student/sponsor/article_content_templates/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 教材、提出物、探究成果、探究レポート、プログラムの件数を返却" - 受講中の教材（未受講のみ）​ - 提出物（新規のみ）​ - 探究成果（下書きと加筆修正のみ）​ - 探究レポート（下書きのみ） - プログラム一覧（100％達成していないもの） - start_dateとend_dateのパラメータで作成日の範囲指定可能
     *
     * @tags Student/Todos
     * @name GetTodosAsStudent
     * @summary 教材、提出物、探究成果、探究レポート、プログラムの件数を返却
     * @request GET:/student/todos/
     * @response `200` `{ material_works: HasTotalCount, homeworks: HasTotalCount, articles: HasTotalCount, answers: HasTotalCount, learning_templates: HasTotalCount }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTodosAsStudent: (query: GetTodosAsStudentParams, params?: RequestParams) =>
      this.request<
        {
          material_works: HasTotalCount;
          homeworks: HasTotalCount;
          articles: HasTotalCount;
          answers: HasTotalCount;
          learning_templates: HasTotalCount;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/todos/${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 DailyLogの一覧を返却 - 今週の日報を取得する - 開始日（昇順）、カテゴリー（昇順）、作成日（降順）で並べている - all パラメータが存在するとき、すべて取得可能
     *
     * @tags Student/DailyLog
     * @name GetDailyLogsAsStudent
     * @summary 日報の一覧を返却
     * @request GET:/student/daily_logs/
     * @response `200` `{ daily_logs: (DailyLogBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getDailyLogsAsStudent: (query: GetDailyLogsAsStudentParams, params?: RequestParams) =>
      this.request<
        { daily_logs: DailyLogBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/daily_logs/${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 DailyLogを登録
     *
     * @tags Student/DailyLog
     * @name PostDailyLogAsStudent
     * @summary 日報を登録
     * @request POST:/student/daily_logs/
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postDailyLogAsStudent: (
      data: { date: string; study_time: number; category: DailyLogCategory; memo?: string },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/daily_logs/`, "POST", params, data),

    /**
     * @description - Student限定 DailyLog の詳細を返却
     *
     * @tags Student/DailyLog
     * @name GetDailyLogAsStudent
     * @summary 日報の詳細を返却
     * @request GET:/student/daily_logs/{id}
     * @response `200` `DailyLogBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getDailyLogAsStudent: (id: string, params?: RequestParams) =>
      this.request<DailyLogBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/daily_logs/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 DailyLog を更新
     *
     * @tags Student/DailyLog
     * @name PutDailyLogAsStudent
     * @summary 日報を更新
     * @request PUT:/student/daily_logs/{id}
     * @response `202` `DailyLogBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putDailyLogAsStudent: (
      id: string,
      data: { date: string; study_time: number; category: DailyLogCategory; memo?: string },
      params?: RequestParams,
    ) =>
      this.request<DailyLogBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/daily_logs/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Student限定 DailyLog を削除
     *
     * @tags Student/DailyLog
     * @name DeleteDailyLogAsStudent
     * @summary 日報を削除
     * @request DELETE:/student/daily_logs/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    deleteDailyLogAsStudent: (id: string, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/daily_logs/${id}`, "DELETE", params),

    /**
     * @description - Student限定 StudyLogの一覧を返却
     *
     * @tags Student/StudyLog
     * @name GetStudyLogsAsStudent
     * @summary 学習ログの一覧を返却
     * @request GET:/student/study_logs/
     * @response `200` `{ study_logs: (StudyLogBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudyLogsAsStudent: (query: GetStudyLogsAsStudentParams, params?: RequestParams) =>
      this.request<
        { study_logs: StudyLogBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/study_logs/${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 StudyLogを登録
     *
     * @tags Student/StudyLog
     * @name PostStudyLogAsStudent
     * @summary 学習ログを登録
     * @request POST:/student/study_logs/
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postStudyLogAsStudent: (
      data: {
        categorizable_type: StudyLogCategorizableType;
        categorizable_id: string;
        behavior: StudyLogBehavior;
        group_affiliate_package_id?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/study_logs/`, "POST", params, data),

    /**
     * @description - Student限定 StudyLog の詳細を返却
     *
     * @tags Student/StudyLog
     * @name GetStudyLogAsStudent
     * @summary 学習ログの詳細を返却
     * @request GET:/student/study_logs/{id}
     * @response `200` `StudyLogBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getStudyLogAsStudent: (id: string, params?: RequestParams) =>
      this.request<StudyLogBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/study_logs/${id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Student/StudyLog
     * @name StudyLogsUpdate
     * @request PUT:/student/study_logs/{id}
     * @response `422` `ErrorsWithKeyMessage`
     */
    studyLogsUpdate: (id: string, params?: RequestParams) =>
      this.request<any, ErrorsWithKeyMessage>(`/student/study_logs/${id}`, "PUT", params),

    /**
     * @description - Student限定 生徒の成果物の件数を返却 - 4/1 から 3/31 の範囲を取得（デフォルト） - プログラム、探究成果、探究レポート、提出物、受講中の教材、あいである、調査メモの件数を返却
     *
     * @tags Student/StudyLog
     * @name GetStudyLogsDeliverableListAsStudent
     * @summary 生徒自身の成果物の件数を返却
     * @request GET:/student/study_logs/deliverable_list
     * @response `200` `DeliverableListBaseAsStudent` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudyLogsDeliverableListAsStudent: (query: GetStudyLogsDeliverableListAsStudentParams, params?: RequestParams) =>
      this.request<DeliverableListBaseAsStudent, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/study_logs/deliverable_list${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 指定した生徒の受講中の教材の一覧を返却 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Student/StudyLog
     * @name GetStudyLogsMaterialWorksAsStudent
     * @summary 指定した生徒の受講中の教材の一覧を返却
     * @request GET:/student/study_logs/material_works
     * @response `200` `{ material_works: (StudentLogDeliverableMaterialWorkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudyLogsMaterialWorksAsStudent: (query: GetStudyLogsMaterialWorksAsStudentParams, params?: RequestParams) =>
      this.request<
        { material_works: StudentLogDeliverableMaterialWorkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/study_logs/material_works${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 指定した生徒の提出物の一覧を返却 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Student/StudyLog
     * @name GetStudyLogsHomeworksAsStudent
     * @summary 指定した生徒の提出物の一覧を返却
     * @request GET:/student/study_logs/homeworks
     * @response `200` `{ homeworks: (StudentLogDeliverableHomeWorkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudyLogsHomeworksAsStudent: (query: GetStudyLogsHomeworksAsStudentParams, params?: RequestParams) =>
      this.request<
        { homeworks: StudentLogDeliverableHomeWorkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/study_logs/homeworks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 指定した生徒の学習プログラムの一覧を返却 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Student/StudyLog
     * @name GetStudyLogsLearningPackagesAsStudent
     * @summary 指定した生徒の学習プログラムの一覧を返却
     * @request GET:/student/study_logs/learning_packages
     * @response `200` `{ learning_packages: (StudentLogDeliverableLearningPackageBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudyLogsLearningPackagesAsStudent: (
      query: GetStudyLogsLearningPackagesAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { learning_packages: StudentLogDeliverableLearningPackageBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/study_logs/learning_packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 指定した生徒の探究成果物の一覧を返却 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Student/StudyLog
     * @name GetStudyLogsArticlesAsStudent
     * @summary 指定した生徒の探究成果物の一覧を返却
     * @request GET:/student/study_logs/articles
     * @response `200` `{ articles: (StudentLogDeliverableArticleBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudyLogsArticlesAsStudent: (query: GetStudyLogsArticlesAsStudentParams, params?: RequestParams) =>
      this.request<
        { articles: StudentLogDeliverableArticleBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/study_logs/articles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 指定した生徒のあいであるの一覧を返却 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Student/StudyLog
     * @name GetStudyLogsIdealsAsStudent
     * @summary 指定した生徒のあいであるの一覧を返却
     * @request GET:/student/study_logs/ideals
     * @response `200` `{ ideals: (StudentLogDeliverableIdealBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudyLogsIdealsAsStudent: (query: GetStudyLogsIdealsAsStudentParams, params?: RequestParams) =>
      this.request<
        { ideals: StudentLogDeliverableIdealBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/study_logs/ideals${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 指定した生徒の調査メモの一覧を返却 - 日付指定する場合は、start_dateとend_dateは両方必須です
     *
     * @tags Student/StudyLog
     * @name GetStudyLogsBookmarksAsStudent
     * @summary 指定した生徒の調査メモの一覧を返却
     * @request GET:/student/study_logs/bookmarks
     * @response `200` `{ bookmarks: (StudentLogDeliverableListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getStudyLogsBookmarksAsStudent: (query: GetStudyLogsBookmarksAsStudentParams, params?: RequestParams) =>
      this.request<
        { bookmarks: StudentLogDeliverableListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/study_logs/bookmarks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 LearningPackage を一覧を返却 - SuperOwnerが配信された学習コンテンツを取得
     *
     * @tags Student/LearningPackages
     * @name GetLearningPackagesAsStudent
     * @summary 配信された学習コンテンツの 一覧を返却
     * @request GET:/student/learning_packages
     * @response `200` `{ learning_packages: (LearningPackageBaseDetails)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningPackagesAsStudent: (query: GetLearningPackagesAsStudentParams, params?: RequestParams) =>
      this.request<
        { learning_packages: LearningPackageBaseDetails[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/learning_packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 LearningPackage の詳細を返却 - SuperOwnerが配信された学習コンテンツを取得 - HomeworkとMaterialWorkを返却
     *
     * @tags Student/LearningPackages
     * @name GetLearningPackageAsStudent
     * @summary 配信された学習コンテンツの 詳細を返却
     * @request GET:/student/learning_packages/{id}
     * @response `200` `LearningPackageBaseDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLearningPackageAsStudent: (id: string, params?: RequestParams) =>
      this.request<LearningPackageBaseDetails, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/learning_packages/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 LearningPackage のステータスを更新 - statusのみを変更可能
     *
     * @tags Student/LearningPackages
     * @name PutLearningPackageAsStudent
     * @summary 配信された学習コンテンツのステータスを更新
     * @request PUT:/student/learning_packages/{id}
     * @response `202` `LearningPackageBaseDetails` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putLearningPackageAsStudent: (id: string, data: { status: LearningPackageStatus }, params?: RequestParams) =>
      this.request<LearningPackageBaseDetails, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/learning_packages/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Ideal::Board の一覧を返却 - 自分のaffiliateに紐づくボードのみ取得可能
     *
     * @tags Student/IdealBoards
     * @name GetIdealBoardsAsStudent
     * @summary Ideal::Board の一覧を返却
     * @request GET:/student/ideal/boards
     * @response `200` `{ boards: (BoardList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getIdealBoardsAsStudent: (query: GetIdealBoardsAsStudentParams, params?: RequestParams) =>
      this.request<
        { boards: BoardList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/ideal/boards${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Ideal::Board を登録 - 登録時、host_affiliateに紐づくboards_affiliateも作成される。
     *
     * @tags Student/IdealBoards
     * @name PostIdealBoardsAsStudent
     * @summary Ideal::Board を登録
     * @request POST:/student/ideal/boards
     * @response `201` `Board` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postIdealBoardsAsStudent: (data: { name: string; size: IdealBoardSize }, params?: RequestParams) =>
      this.request<Board, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/ideal/boards`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Student限定 Ideal::Board の詳細を返却
     *
     * @tags Student/IdealBoard
     * @name GetIdealBoardAsStudent
     * @summary Ideal::Board の詳細を返却
     * @request GET:/student/ideal/boards/{id}
     * @response `200` `Board` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getIdealBoardAsStudent: (id: string, params?: RequestParams) =>
      this.request<Board, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/ideal/boards/${id}`,
        "GET",
        params,
      ),

    /**
     * @description Student限定 Ideal::Board を更新
     *
     * @tags Student/IdealBoard
     * @name PutIdealBoardAsStudent
     * @summary Ideal::Board を更新
     * @request PUT:/student/ideal/boards/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putIdealBoardAsStudent: (
      id: string,
      data: { name?: string; data?: string; event?: IdealBoardEvent },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/ideal/boards/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Student限定 Ideal::Board を論理削除 - 関連するboards_affiliateも論理削除される
     *
     * @tags Student/IdealBoard
     * @name DeleteIdealBoardAsStudent
     * @summary Ideal::Board を削除
     * @request DELETE:/student/ideal/boards/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteIdealBoardAsStudent: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/ideal/boards/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Student限定 アイデアボックスに生徒（Affiliate）を紐づける - 指定された生徒もアイデアボックスの閲覧、編集可能になる
     *
     * @tags Student/BoardAffiliates
     * @name PostIdealBoardsAffiliatesAsStudent
     * @summary アイデアボックスに生徒を紐づける
     * @request POST:/student/ideal/boards_affiliates
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postIdealBoardsAffiliatesAsStudent: (
      data: { ideal_board_id: string; affiliate_id: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/ideal/boards_affiliates`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Student限定 アイデアボックスに紐づいている生徒を削除する
     *
     * @tags Student/BoardAffiliates
     * @name DeleteIdealBoardsAffiliatesAsStudent
     * @summary アイデアボックスに紐づいている生徒を削除する
     * @request DELETE:/student/ideal/boards_affiliates
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteIdealBoardsAffiliatesAsStudent: (
      data: { ideal_board_id: string; affiliate_id: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/ideal/boards_affiliates`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description - Student限定 GroupAffiliatePackage を一覧を返却 - 生徒に関連するグループの一覧を返却
     *
     * @tags Student/GroupAffiliatePackages
     * @name GetGroupAffiliatePackagesAsStudent
     * @summary 生徒に関連するグループの一覧を返却
     * @request GET:/student/group_affiliate_packages
     * @response `200` `{ group_affiliate_packages: (GroupAffiliatePackageList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGroupAffiliatePackagesAsStudent: (query: GetGroupAffiliatePackagesAsStudentParams, params?: RequestParams) =>
      this.request<
        { group_affiliate_packages: GroupAffiliatePackageList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/group_affiliate_packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 AttachedImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags Student/AttachedImages
     * @name PostAttachedImageAsStudent
     * @summary 画像 を登録
     * @request POST:/student/attached_images
     * @response `201` `AttachedImageBase` Created
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postAttachedImageAsStudent: (
      query: PostAttachedImageAsStudentParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | Forbidden | { error?: string }>(
        `/student/attached_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Student限定 AttachedImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Student/AttachedImages
     * @name GetAttachedImageAsStudent
     * @summary 画像 の詳細を返却
     * @request GET:/student/attached_images/{id}
     * @response `200` `AttachedImageBase` OK
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     */
    getAttachedImageAsStudent: ({ id, ...query }: GetAttachedImageAsStudentParams, params?: RequestParams) =>
      this.request<AttachedImageBase, BadRequest | Forbidden>(
        `/student/attached_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 AttachedImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Student/AttachedImages
     * @name PutAttachedImageAsStudent
     * @summary 画像 を更新
     * @request PUT:/student/attached_images/{id}
     * @response `202` `AttachedImageBase` Accepted
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     */
    putAttachedImageAsStudent: (
      { id, ...query }: PutAttachedImageAsStudentParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | Forbidden>(
        `/student/attached_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Student限定 AttachedImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Student/AttachedImages
     * @name DeleteAttachedImageAsStudent
     * @summary 画像 を削除
     * @request DELETE:/student/attached_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `403` `Forbidden`
     */
    deleteAttachedImageAsStudent: ({ id, ...query }: DeleteAttachedImageAsStudentParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Forbidden>(
        `/student/attached_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Student限定 LimitedTimeNotification を一覧を返却 - 現在時刻がstart_timeとend_timeの範囲内のものを取得 - 指定された条件（Role, Company）にマッチしたものを取得 - UserTagを指定しているお知らせは、そのUserTagを付与されている生徒しか見られない
     *
     * @tags Student/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationNoticeAsStudent
     * @summary 配信された期間限定のお知らせの一覧を返却
     * @request GET:/student/limited_time_notifications/notice
     * @response `200` `{ limited_time_notifications: (LimitedTimeNotificationNoticeBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationNoticeAsStudent: (
      query: GetLimitedTimeNotificationNoticeAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { limited_time_notifications: LimitedTimeNotificationNoticeBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/limited_time_notifications/notice${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 LimitedTimeNotification の詳細を返却 - 現在時刻がstart_timeとend_timeの範囲内のものを取得 - 指定された条件（Role, Company）にマッチしたものを取得 - UserTagを指定しているお知らせは、そのUserTagを付与されている生徒しか見られない
     *
     * @tags Student/LimitedTimeNotifications
     * @name GetLimitedTimeNotificationNoticeDetailsAsStudent
     * @summary 配信された期間限定のお知らせ の詳細を返却
     * @request GET:/student/limited_time_notifications/{id}/notice_details
     * @response `200` `LimitedTimeNotificationNoticeBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getLimitedTimeNotificationNoticeDetailsAsStudent: (id: string, params?: RequestParams) =>
      this.request<LimitedTimeNotificationNoticeBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/limited_time_notifications/${id}/notice_details`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 このお知らせを確認したかを保存する - Newアイコンを外すため
     *
     * @tags Student/LimitedTimeNotifications
     * @name PostLimitedTimeNotificationNoticeCheckAsStudent
     * @summary 期間限定のお知らせをチェックしたフラグを保存する
     * @request POST:/student/limited_time_notifications/{id}/notice_check
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postLimitedTimeNotificationNoticeCheckAsStudent: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/limited_time_notifications/${id}/notice_check`,
        "POST",
        params,
      ),

    /**
     * @description - 特定のresourceに紐づくグループをまとめて更新する
     *
     * @tags AllowedGroupAffiliates
     * @name PatchAllowedGroupAffiliatesBatchUpdateAsStudent
     * @summary 特定のresourceに紐づくグループをまとめて更新
     * @request PATCH:/student/allowed_group_affiliates/batch_update
     * @response `200` `{ message?: string }`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    patchAllowedGroupAffiliatesBatchUpdateAsStudent: (
      data: {
        categorizable_type: AllowedGroupAffiliateCategorizableType;
        categorizable_id: string;
        add_group_affiliate_ids?: string[];
        remove_group_affiliate_ids?: string[];
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/student/allowed_group_affiliates/batch_update`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @description - Student限定 LearningProgress を登録 - Steamライブラリーのレクチャー(Pbl::Steam::Lecture)の進捗を登録可能 - 企業コンテンツのレクチャー(Pbl::Sponsor::Lecture)の進捗を登録可能
     *
     * @tags Student/LearningProgress
     * @name PostLearningProgressAsStudent
     * @summary 生徒の進捗 を登録
     * @request POST:/student/learning_progress
     * @response `201` `LearningProgressBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postLearningProgressAsStudent: (
      data: {
        categorizable_type: LearningProgressCategorizableType;
        categorizable_id: string;
        event?: LearningProgressEvent;
      },
      params?: RequestParams,
    ) =>
      this.request<LearningProgressBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/learning_progress`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Student限定 LearningProgress を更新 - ステータスのみ更新可能
     *
     * @tags Student/LearningProgress
     * @name PutLearningProgressAsStudent
     * @summary 生徒の進捗 を更新
     * @request PUT:/student/learning_progress/{id}
     * @response `202` `LearningProgressBase` Accepted
     * @response `400` `{ message?: string }` bad_request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putLearningProgressAsStudent: (id: string, data: { event: LearningProgressEvent }, params?: RequestParams) =>
      this.request<LearningProgressBase, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/student/learning_progress/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Student限定 Interview::Request を一覧を返却
     *
     * @tags Student/Interview/Request
     * @name GetInterviewRequestsAsStudent
     * @summary 高大連携リクエスト一覧を返却
     * @request GET:/student/interview/requests
     * @response `200` `{ interview_requests: (InterviewRequestListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestsAsStudent: (query: GetInterviewRequestsAsStudentParams, params?: RequestParams) =>
      this.request<
        { interview_requests: InterviewRequestListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/interview/requests${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 Interview::Request の詳細を返却
     *
     * @tags Student/Interview/Request
     * @name GetInterviewRequestAsStudent
     * @summary 高大連携リクエストの詳細を返却
     * @request GET:/student/interview/requests/{id}
     * @response `200` `InterviewRequestDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestAsStudent: (id: string, params?: RequestParams) =>
      this.request<InterviewRequestDetailBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/interview/requests/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 Interview::Request の詳細を返却
     *
     * @tags Student/Interview/Request
     * @name PutInterviewRequestAsStudent
     * @summary 高大連携リクエストの詳細を更新
     * @request PUT:/student/interview/requests/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putInterviewRequestAsStudent: (
      id: string,
      data: { student_message?: string; is_online?: boolean; preferred_dates?: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/interview/requests/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Student限定 Article の詳細を返却 - 高大連携リクエストされている探究成果のみ閲覧可能
     *
     * @tags Student/Interview/Request
     * @name GetInterviewRequestArticleAsStudent
     * @summary 高大連携リクエストの探究成果詳細を返却
     * @request GET:/student/interview/requests/{id}/article
     * @response `200` `ArticleDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestArticleAsStudent: (id: string, params?: RequestParams) =>
      this.request<ArticleDetailBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/interview/requests/${id}/article`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 高大連携リクエストの未読メッセージの件数を返却
     *
     * @tags Student/Interview/Request
     * @name GetInterviewRequestUnreadCountsAsStudent
     * @summary 高大連携リクエストの未読メッセージの件数を返却
     * @request GET:/student/interview/requests/unread_count
     * @response `200` `{ unread_counts: (any)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewRequestUnreadCountsAsStudent: (
      query: GetInterviewRequestUnreadCountsAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<{ unread_counts: any[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/interview/requests/unread_count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 Interview::Message を一覧を返却
     *
     * @tags Student/Interview/Message
     * @name GetInterviewMessagesAsStudent
     * @summary 高大連携リクエストのメッセージ一覧を返却
     * @request GET:/student/interview/requests/{request_id}/messages
     * @response `200` `{ interview_messages: (InterviewMessageListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewMessagesAsStudent: (
      { request_id, ...query }: GetInterviewMessagesAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { interview_messages: InterviewMessageListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/interview/requests/${request_id}/messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 Interview::Message を登録
     *
     * @tags Student/Interview/Message
     * @name PostInterviewMessageAsStudent
     * @summary 高大連携リクエストのメッセージを登録
     * @request POST:/student/interview/requests/{request_id}/messages
     * @response `201` `InterviewMessageListBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postInterviewMessageAsStudent: (request_id: string, data: { message: string }, params?: RequestParams) =>
      this.request<InterviewMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/interview/requests/${request_id}/messages`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Student限定 Interview::Read を登録
     *
     * @tags Student/Interview/Message
     * @name GetInterviewMessageReadAsStudent
     * @summary 高大連携リクエストの未読メッセージを既読メッセージに変更する
     * @request POST:/student/interview/requests/{request_id}/messages/read
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewMessageReadAsStudent: (
      request_id: string,
      data: { unread_message_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/interview/requests/${request_id}/messages/read`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Student限定 Interview::Message を更新
     *
     * @tags Student/Interview/Message
     * @name PutInterviewMessageAsStudent
     * @summary 高大連携リクエストのメッセージ を更新
     * @request PUT:/student/interview/requests/{request_id}/messages/{id}
     * @response `202` `InterviewMessageListBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putInterviewMessageAsStudent: (request_id: string, id: string, data: { message: string }, params?: RequestParams) =>
      this.request<InterviewMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/interview/requests/${request_id}/messages/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Student限定 Interview::Message を削除
     *
     * @tags Student/Interview/Message
     * @name DeleteInterviewMessageAsStudent
     * @summary 高大連携リクエストのメッセージ を削除
     * @request DELETE:/student/interview/requests/{request_id}/messages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteInterviewMessageAsStudent: (request_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/interview/requests/${request_id}/messages/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Student限定 Interview::Feedback を一覧を返却
     *
     * @tags Student/Interview/Feedback
     * @name GetInterviewFeedbacksAsStudent
     * @summary 高大連携リクエストのアンケート一覧を返却
     * @request GET:/student/interview/requests/{request_id}/feedbacks
     * @response `200` `{ interview_feedbacks: (InterviewFeedbackBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getInterviewFeedbacksAsStudent: (
      { request_id, ...query }: GetInterviewFeedbacksAsStudentParams,
      params?: RequestParams,
    ) =>
      this.request<
        { interview_feedbacks: InterviewFeedbackBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/interview/requests/${request_id}/feedbacks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 Interview::Feedback を登録
     *
     * @tags Student/Interview/Feedback
     * @name PostInterviewRequestAsStudent
     * @summary 高大連携リクエストのアンケートを登録
     * @request POST:/student/interview/requests/{request_id}/feedbacks
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postInterviewRequestAsStudent: (
      request_id: string,
      data: {
        description: string;
        degree_of_interest: number;
        degree_of_learning: number;
        is_request_send_material: boolean;
      },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/interview/requests/${request_id}/feedbacks`,
        "POST",
        params,
        data,
      ),

    /**
     * @description ヘルプコンテンツの一覧を返却
     *
     * @tags Student/Help/Contents
     * @name FetchHelpContentsAsStudent
     * @summary ヘルプコンテンツの一覧を返却
     * @request GET:/student/help/contents
     * @response `200` `{ help_master?: HelpMasterBase, help_contents: (HelpContentBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchHelpContentsAsStudent: (query: FetchHelpContentsAsStudentParams, params?: RequestParams) =>
      this.request<
        { help_master?: HelpMasterBase; help_contents: HelpContentBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/help/contents${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Student/CustomMaster
     * @name GetCustomMastersAsStudent
     * @summary 指定のカラムと値に合致したカスタムリストのうち重複しない指定カラムの値を返す
     * @request GET:/student/custom_masters
     * @response `200` `{ custom_masters: (CustomMasterPublics)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCustomMastersAsStudent: (query: GetCustomMastersAsStudentParams, params?: RequestParams) =>
      this.request<
        { custom_masters: CustomMasterPublics[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/custom_masters${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Student/CustomMaster
     * @name GetCustomMastersSelectListAsStudent
     * @summary 指定のカラムと値に合致したカスタムリストのうち重複しない指定カラムの値を返す
     * @request GET:/student/custom_masters/select_list
     * @response `200` `{ custom_masters: (HasName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCustomMastersSelectListAsStudent: (query: GetCustomMastersSelectListAsStudentParams, params?: RequestParams) =>
      this.request<
        { custom_masters: HasName[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/custom_masters/select_list${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 Chat::Room を表示
     *
     * @tags Student/Chat/Room
     * @name GetChatRoomAsStudent
     * @summary 調査メモのチャットルームを詳細表示
     * @request GET:/student/chat/rooms
     * @response `200` `any` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getChatRoomAsStudent: (query: GetChatRoomAsStudentParams, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/chat/rooms${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Student限定 Chat::Room を登録
     *
     * @tags Student/Chat/Room
     * @name PostChatRoomAsStudent
     * @summary 調査メモのチャットルームを登録
     * @request POST:/student/chat/rooms
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postChatRoomAsStudent: (query: PostChatRoomAsStudentParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/chat/rooms${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @description - Student限定 Gogo::Message を一覧を返却
     *
     * @tags Student/Chat/RoomMessage
     * @name GetChatRoomMessagesAsStudent
     * @summary 調査メモのチャットルームのメッセージ一覧を返却
     * @request GET:/student/chat/rooms/room_messages
     * @response `200` `{ chat_room_messages: (any)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getChatRoomMessagesAsStudent: (query: GetChatRoomMessagesAsStudentParams, params?: RequestParams) =>
      this.request<
        { chat_room_messages: any[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/chat/rooms/room_messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 Gogo::Message を登録
     *
     * @tags Student/Chat/RoomMessage
     * @name PostChatRoomMessageAsStudent
     * @summary 調査メモのチャットルームのメッセージを登録
     * @request POST:/student/chat/rooms/room_messages
     * @response `201` `any` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postChatRoomMessageAsStudent: (
      query: PostChatRoomMessageAsStudentParams,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/chat/rooms/room_messages${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Student限定 Gogo::Message を更新
     *
     * @tags Student/Chat/RoomMessage
     * @name PutChatRoomMessageAsStudent
     * @summary 調査メモのチャットルームのメッセージ を更新
     * @request PUT:/student/chat/rooms/room_messages/{id}
     * @response `202` `any` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putChatRoomMessageAsStudent: (
      { id, ...query }: PutChatRoomMessageAsStudentParams,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/chat/rooms/room_messages/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Student限定 Gogo::Message を削除
     *
     * @tags Student/Chat/RoomMessage
     * @name DeleteChatRoomMessageAsStudent
     * @summary 調査メモのチャットルームのメッセージ を削除
     * @request DELETE:/student/chat/rooms/room_messages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteChatRoomMessageAsStudent: ({ id, ...query }: DeleteChatRoomMessageAsStudentParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/chat/rooms/room_messages/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Student限定 Common::Tag の一覧を返却
     *
     * @tags Student/CommonTags
     * @name GetCommonTagsAsStudent
     * @summary システムタグ・フリータグの一覧を返却
     * @request GET:/student/common/tags
     * @response `200` `{ common_tags: (CommonTagBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCommonTagsAsStudent: (query: GetCommonTagsAsStudentParams, params?: RequestParams) =>
      this.request<
        { common_tags: CommonTagBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/student/common/tags${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Student限定 CommonTagging を登録 - 許可されたModelのみ登録可能
     *
     * @tags Student/CommonTaggings
     * @name PostCommonTaggingAsStudent
     * @summary フリータグを登録
     * @request POST:/student/common/taggings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postCommonTaggingAsStudent: (
      query: PostCommonTaggingAsStudentParams,
      data: CommonTaggingRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/student/common/taggings${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @description - Student限定 CommonTagging を削除
     *
     * @tags Student/CommonTaggings
     * @name DeleteCommonTaggingAsStudent
     * @summary フリータグ を解除
     * @request DELETE:/student/common/taggings
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteCommonTaggingAsStudent: (query: DeleteCommonTaggingAsStudentParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/student/common/taggings${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),
  };
  reviewer = {
    /**
     * No description
     *
     * @tags Reviewer/Article, articles
     * @name FetchArticleAsReviewer
     * @summary 探究成果記事の詳細を取得
     * @request GET:/reviewer/articles/{article_id}
     * @response `200` `ArticleDetailWithAdditionalInfo` OK
     * @response `404` `{ error?: string }`
     */
    fetchArticleAsReviewer: (article_id: string, params?: RequestParams) =>
      this.request<ArticleDetailWithAdditionalInfo, { error?: string }>(
        `/reviewer/articles/${article_id}`,
        "GET",
        params,
      ),

    /**
     * @description Return articles to the current user logged in as a Reviewer
     *
     * @tags Reviewer/Article
     * @name FetchArticlesAsReviewer
     * @summary Articles Index
     * @request GET:/reviewer/articles
     * @response `200` `{ articles: ((ArticleBase & { excerpt?: string, liked?: boolean, unliked?: boolean }))[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `500` `any` Internal Server Error
     */
    fetchArticlesAsReviewer: (query: FetchArticlesAsReviewerParams, params?: RequestParams) =>
      this.request<
        { articles: (ArticleBase & { excerpt?: string; liked?: boolean; unliked?: boolean })[]; total_count: number },
        BadRequest | Unauthorized
      >(`/reviewer/articles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description Return articles to the current user logged in as a reviewer
     *
     * @tags Reviewer/Article
     * @name FetchArticleCommentsAsReviewer
     * @summary Article Comments Index
     * @request GET:/reviewer/articles/{article_id}/comments
     * @response `200` `{ article_comments: (ArticleComment)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `500` `any` Internal Server Error
     */
    fetchArticleCommentsAsReviewer: (
      { article_id, ...query }: FetchArticleCommentsAsReviewerParams,
      params?: RequestParams,
    ) =>
      this.request<{ article_comments: ArticleComment[]; total_count: number }, BadRequest | Unauthorized>(
        `/reviewer/articles/${article_id}/comments${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags articles, Reviewer/Article
     * @name CreateArticleCommentAsReviewer
     * @summary 探究成果の登録
     * @request POST:/reviewer/articles/{article_id}/comments
     * @response `201` `ArticleComment` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    createArticleCommentAsReviewer: (article_id: string, data: BodyRequiredRequestBody, params?: RequestParams) =>
      this.request<ArticleComment, BadRequest | Unauthorized | Forbidden>(
        `/reviewer/articles/${article_id}/comments`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Return articles to the current user logged in as a reviewer
     *
     * @tags Reviewer/Article
     * @name FetchArticleLikesAsReviewer
     * @summary Article Likes Index
     * @request GET:/reviewer/articles/{article_id}/likes
     * @response `200` `{ article_likes: (ArticleLike)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `500` `any` Internal Server Error
     */
    fetchArticleLikesAsReviewer: (
      { article_id, ...query }: FetchArticleLikesAsReviewerParams,
      params?: RequestParams,
    ) =>
      this.request<{ article_likes: ArticleLike[]; total_count: number }, BadRequest | Unauthorized>(
        `/reviewer/articles/${article_id}/likes${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags articles, Reviewer/Article
     * @name CreateArticleLikeAsReviewer
     * @summary 探究成果の登録
     * @request POST:/reviewer/articles/{article_id}/likes
     * @response `201` `ArticleLike` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    createArticleLikeAsReviewer: (article_id: string, params?: RequestParams) =>
      this.request<ArticleLike, BadRequest | Unauthorized | Forbidden>(
        `/reviewer/articles/${article_id}/likes`,
        "POST",
        params,
      ),

    /**
     * @description レビュアーとして自分が書いたコメントを削除する。
     *
     * @tags Reviewer/Article
     * @name DeleteArticleCommentAsReviewer
     * @request DELETE:/reviewer/articles/{article_id}/comments/{comment_id}
     * @response `200` `{ message: string }` OK
     */
    deleteArticleCommentAsReviewer: (article_id: string, comment_id: string, params?: RequestParams) =>
      this.request<{ message: string }, any>(
        `/reviewer/articles/${article_id}/comments/${comment_id}`,
        "DELETE",
        params,
      ),

    /**
     * @description レビュアーとして自分が書いたコメントを削除する。
     *
     * @tags Reviewer/Article
     * @name LikeArticleAsReviewer
     * @request PUT:/reviewer/articles/{article_id}/like
     * @response `200` `Article` OK
     * @response `404` `{ error?: string }`
     */
    likeArticleAsReviewer: (article_id: string, params?: RequestParams) =>
      this.request<Article, { error?: string }>(`/reviewer/articles/${article_id}/like`, "PUT", params),

    /**
     * @description レビュアーとして自分が書いたコメントを削除する。
     *
     * @tags Reviewer/Article
     * @name UnlikeArticleAsReviewer
     * @request PUT:/reviewer/articles/{article_id}/unlike
     * @response `200` `Article` OK
     * @response `404` `{ error?: string }`
     */
    unlikeArticleAsReviewer: (article_id: string, params?: RequestParams) =>
      this.request<Article, { error?: string }>(`/reviewer/articles/${article_id}/unlike`, "PUT", params),

    /**
     * @description レビュアーとして自分が書いたコメントを削除する。
     *
     * @tags Reviewer/Article
     * @name DeleteArticleLikeAsReviewer
     * @request DELETE:/reviewer/articles/{article_id}/likes/{like_id}
     * @response `200` `{ message: string }` OK
     */
    deleteArticleLikeAsReviewer: (article_id: string, like_id: string, params?: RequestParams) =>
      this.request<{ message: string }, any>(`/reviewer/articles/${article_id}/likes/${like_id}`, "DELETE", params),

    /**
     * No description
     *
     * @tags Reviewer/reviewers
     * @name ReviewersList
     * @summary 添削者が所属している会社の添削者を取得
     * @request GET:/reviewer/reviewers
     * @response `200` `{ total_count: number, reviewers: (BasicUserInfo)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    reviewersList: (query: ReviewersListParams, params?: RequestParams) =>
      this.request<{ total_count: number; reviewers: BasicUserInfo[] }, Unauthorized | Forbidden>(
        `/reviewer/reviewers${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description レビュアーを新規作成
     *
     * @tags Reviewer/reviewers
     * @name CreateReviewerAsReviewer
     * @request POST:/reviewer/reviewers
     * @response `201` `ReviewerDetail` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    createReviewerAsReviewer: (data: UserReviewersRequestBodyAsReviewer, params?: RequestParams) =>
      this.request<ReviewerDetail, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/reviewer/reviewers`,
        "POST",
        params,
        data,
      ),

    /**
     * @description レビュアーを削除
     *
     * @tags Reviewer/reviewers
     * @name DeleteReviewerAsReviewer
     * @request DELETE:/reviewer/reviewers/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    deleteReviewerAsReviewer: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(
        `/reviewer/reviewers/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description レビュアー情報編集(emailは不可)
     *
     * @tags Reviewer/reviewers
     * @name UpdateReviewerAsReviewer
     * @request PUT:/reviewer/reviewers/{id}
     * @response `200` `ReviewerDetail` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    updateReviewerAsReviewer: (id: string, data: UserReviewerRequestBodyAsReviewer, params?: RequestParams) =>
      this.request<ReviewerDetail, BadRequest | { error?: string } | ErrorsWithKeyMessage>(
        `/reviewer/reviewers/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Reviewer/reviewer_affiliates
     * @name FetchReviewerAffiliatesAsReviewer
     * @summary 添削者が所属している会社の添削者を取得
     * @request GET:/reviewer/reviewers/affiliates
     * @response `200` `{ total_count: number, reviewer_affiliates: (AffiliateName)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchReviewerAffiliatesAsReviewer: (query: FetchReviewerAffiliatesAsReviewerParams, params?: RequestParams) =>
      this.request<{ total_count: number; reviewer_affiliates: AffiliateName[] }, Unauthorized | Forbidden>(
        `/reviewer/reviewers/affiliates${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Reviewer/Notifications
     * @name FetchNotificationsAsReviewer
     * @summary 添削者宛てに送信されたお知らせを取得
     * @request GET:/reviewer/notifications
     * @response `200` `{ notifications?: ((NotificationBase & { read?: boolean, company?: { id?: string, name?: string } }))[], total_count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchNotificationsAsReviewer: (query: FetchNotificationsAsReviewerParams, params?: RequestParams) =>
      this.request<
        {
          notifications?: (NotificationBase & { read?: boolean; company?: { id?: string; name?: string } })[];
          total_count?: number;
        },
        Unauthorized | Forbidden
      >(`/reviewer/notifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description オーナーが自社が抱えている宿題の一覧を取得
     *
     * @tags Reviewer/Homework, Reviewer/HomeworkTemplate, Homework
     * @name FetchHomeworkTemplatesAsReviewer
     * @summary fetch homework templates as a reviewer.
     * @request GET:/reviewer/homework_templates
     * @response `200` `{ total_count: number, templates: (HomeworkTemplateForReviewer)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchHomeworkTemplatesAsReviewer: (query: FetchHomeworkTemplatesAsReviewerParams, params?: RequestParams) =>
      this.request<{ total_count: number; templates: HomeworkTemplateForReviewer[] }, Unauthorized | Forbidden>(
        `/reviewer/homework_templates${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Reviewer限定 提出物テンプレートのフォームの回答内容 - CSV書き出し用 - fetchHomeworkTemplatesAsReviewerと同様にcompany_idを指定可能
     *
     * @tags Reviewer/HomeworkTemplate, HomeworkTemplate
     * @name GetHomeworkTemplateAnswersAsReviewer
     * @summary 提出物テンプレートのフォームの回答内容を返却
     * @request GET:/reviewer/homework_templates/{id}/answers
     * @response `200` `{ questions: (any)[], answers: ((any)[])[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getHomeworkTemplateAnswersAsReviewer: (
      { id, ...query }: GetHomeworkTemplateAnswersAsReviewerParams,
      params?: RequestParams,
    ) =>
      this.request<{ questions: any[]; answers: any[][] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/homework_templates/${id}/answers${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description オーナーが自社が抱えている宿題の一覧を取得
     *
     * @tags Reviewer/Homework, Reviewer/HomeworkTemplateDistribution, Homework
     * @name FetchHomeworkTemplateDistributionsAsReviewer
     * @summary fetch homework template distributions as a reviewer.
     * @request GET:/reviewer/homework_template_distributions
     * @response `200` `{ total_count: number, distributions: (HomeworkTemplateDistributionBaseForReviewer)[] }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchHomeworkTemplateDistributionsAsReviewer: (
      query: FetchHomeworkTemplateDistributionsAsReviewerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { total_count: number; distributions: HomeworkTemplateDistributionBaseForReviewer[] },
        Unauthorized | Forbidden
      >(`/reviewer/homework_template_distributions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description 指定したHomeworkTemplateDistributionを取得する
     *
     * @tags Reviewer/HomeworkTemplateDistribution, Reviewer/HomeworkTemplate, Reviewer/Homework, Homework, HomeworkTemplate, HomeworkTemplateDistribution
     * @name FetchHomeworkTemplateDistributionAsReviewer
     * @request GET:/reviewer/homework_template_distributions/{id}
     * @response `200` `HomeworkTemplateDistributionBaseForReviewer` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchHomeworkTemplateDistributionAsReviewer: (id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateDistributionBaseForReviewer, Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/homework_template_distributions/${id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags HomeworkTemplateDistribution, Reviewer/HomeworkTemplateDistribution
     * @name UpdateHomeworkTemplateDistributionAsReviewer
     * @summary ReviewerがHomeworkTemplateDistributionを更新
     * @request PUT:/reviewer/homework_template_distributions/{id}
     * @response `200` `HomeworkTemplateDistributionBaseForReviewer` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    updateHomeworkTemplateDistributionAsReviewer: (
      id: string,
      data: PutHomeworkTemplateDistributionRequestBodyAsReviewer,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateDistributionBaseForReviewer, BadRequest | Unauthorized | Forbidden>(
        `/reviewer/homework_template_distributions/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Reviewer/HomeworkTemplate, Reviewer/HomeworkTemplateDistribution
     * @name ReturnHomeworkTemplateDistributionToSchoolAsReviewer
     * @summary HomeworkTemplateDistributionを学校に返却する
     * @request PUT:/reviewer/homework_template_distributions/{id}/return_to_school
     * @response `200` `HomeworkTemplateDistributionBaseForReviewer` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `500` `any` Internal Server Error
     */
    returnHomeworkTemplateDistributionToSchoolAsReviewer: (
      id: string,
      data: HomeworkTemplateDistributionToSchoolAsReviewerRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateDistributionBaseForReviewer, BadRequest | Unauthorized | Forbidden>(
        `/reviewer/homework_template_distributions/${id}/return_to_school`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Reviewer/HomeworkTemplate, Reviewer/HomeworkTemplateDistribution
     * @name RevertHomeworkTemplateDistributionToSchoolAsReviewer
     * @summary HomeworkTemplateDistributionを学校に差し戻す
     * @request PUT:/reviewer/homework_template_distributions/{id}/revert_submission_from_school
     * @response `200` `HomeworkTemplateDistributionBaseForReviewer` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    revertHomeworkTemplateDistributionToSchoolAsReviewer: (
      id: string,
      data: HomeworkTemplateDistributionCommentRequestBody,
      params?: RequestParams,
    ) =>
      this.request<
        HomeworkTemplateDistributionBaseForReviewer,
        BadRequest | Unauthorized | Forbidden | ErrorsWithKeyMessage
      >(`/reviewer/homework_template_distributions/${id}/revert_submission_from_school`, "PUT", params, data),

    /**
     * No description
     *
     * @tags Reviewer/HomeworkTemplate, Reviewer/HomeworkTemplateDistribution
     * @name MergeChunksInHomeworkTemplateDistributionAsReviewer
     * @summary 模試機能ONの提出物の各レビュー済みの回答をマージする
     * @request PUT:/reviewer/homework_template_distributions/{id}/merge_chunks
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    mergeChunksInHomeworkTemplateDistributionAsReviewer: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/reviewer/homework_template_distributions/${id}/merge_chunks`,
        "PUT",
        params,
      ),

    /**
     * No description
     *
     * @name DeleteHomeworkTemplateDistributionImageAsReviewer
     * @summary 提出物テンプレート配信の添付ファイルを削除する
     * @request DELETE:/reviewer/homework_template_distributions/{id}/delete_distribution_image
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    deleteHomeworkTemplateDistributionImageAsReviewer: (
      id: string,
      data: HomeworkTemplateDistributionImageContentIdRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest>(
        `/reviewer/homework_template_distributions/${id}/delete_distribution_image`,
        "DELETE",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Reviewer/HomeworkTemplate, Reviewer/HomeworkTemplateDistribution
     * @name GetUserGroupAffiliatesPackagesAsReviewer
     * @summary HomeworkTemplateDistributionに紐づくUserのGroupAffiliatesPackagesを返す
     * @request GET:/reviewer/homework_template_distributions/{id}/user_group_affiliates_packages
     * @response `200` `{ group_affiliate_packages: (GroupAffiliatePackageMinimalBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getUserGroupAffiliatesPackagesAsReviewer: (id: string, params?: RequestParams) =>
      this.request<
        { group_affiliate_packages: GroupAffiliatePackageMinimalBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/reviewer/homework_template_distributions/${id}/user_group_affiliates_packages`, "GET", params),

    /**
     * No description
     *
     * @tags Reviewer/HomeworkTemplate, Reviewer/HomeworkTemplateDistribution
     * @name GetUserTagsAsReviewer
     * @summary HomeworkTemplateDistributionに紐づくUserのUserTagsを返す
     * @request GET:/reviewer/homework_template_distributions/{id}/user_tags
     * @response `200` `{ user_tags: (UserTagBasic)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getUserTagsAsReviewer: (id: string, params?: RequestParams) =>
      this.request<
        { user_tags: UserTagBasic[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/reviewer/homework_template_distributions/${id}/user_tags`, "GET", params),

    /**
     * @description - Reviewer限定 HomeworkTemplateChunkDistribution の一覧を返却
     *
     * @tags Reviewer/HomeworkTemplateChunkDistributions
     * @name GetHomeworkTemplateChunkDistributionsAsReviewer
     * @summary HomeworkTemplateChunkDistribution の一覧を返却
     * @request GET:/reviewer/homework_template_chunk_distributions
     * @response `200` `{ homework_template_chunk_distributions: (HomeworkTemplateChunkDistributionList)[], template_chunk_distribution_statuses: HomeworkTemplateChunkDistributionStatuses, total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getHomeworkTemplateChunkDistributionsAsReviewer: (
      query: GetHomeworkTemplateChunkDistributionsAsReviewerParams,
      params?: RequestParams,
    ) =>
      this.request<
        {
          homework_template_chunk_distributions: HomeworkTemplateChunkDistributionList[];
          template_chunk_distribution_statuses: HomeworkTemplateChunkDistributionStatuses;
          total_count: number;
        },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/reviewer/homework_template_chunk_distributions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Reviewer限定 HomeworkTemplateChunkDistribution の詳細を返却
     *
     * @tags Reviewer/HomeworkTemplateChunkDistribution
     * @name GetHomeworkTemplateChunkDistributionAsReviewer
     * @summary HomeworkTemplateChunkDistribution の詳細を返却
     * @request GET:/reviewer/homework_template_chunk_distributions/{id}
     * @response `200` `HomeworkTemplateChunkDistributionDetail` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getHomeworkTemplateChunkDistributionAsReviewer: (id: string, params?: RequestParams) =>
      this.request<HomeworkTemplateChunkDistributionDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/homework_template_chunk_distributions/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - Reviewer限定 HomeworkTemplateChunkDistributionのstatusを更新
     *
     * @tags Reviewer/HomeworkTemplateChunkDistributions
     * @name PutHomeworkTemplateChunkDistributionAsReviewer
     * @summary HomeworkTemplateChunkDistributionのstatusを更新
     * @request PUT:/reviewer/homework_template_chunk_distributions/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putHomeworkTemplateChunkDistributionAsReviewer: (
      id: string,
      data: HomeworkTemplateChunkDistributionEventRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, { message?: string } | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/homework_template_chunk_distributions/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 HomeworkTemplateChunkDistributionの添削担当者を更新
     *
     * @tags Reviewer/HomeworkTemplateChunkDistributions
     * @name AssignHomeworkTemplateChunkDistributionToReviewerAsReviewer
     * @summary HomeworkTemplateChunkDistributionの添削担当者を更新
     * @request PUT:/reviewer/homework_template_chunk_distributions/{id}/assign
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    assignHomeworkTemplateChunkDistributionToReviewerAsReviewer: (
      id: string,
      data: HomeworkTemplateChunkDistributionReviewerAffiliateIdRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/homework_template_chunk_distributions/${id}/assign`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "Reviewer限定 HomeworkTemplateCustomFieldFeedbackを登録"
     *
     * @tags Reviewer/HomeworkTemplateCustomFieldFeedbacks
     * @name PostHomeworkTemplateCustomFieldFeedbackAsReviewer
     * @summary HomeworkTemplateCustomFieldFeedbackを登録
     * @request POST:/reviewer/homework_template_custom_fields/{homework_template_custom_field_id}/feedbacks
     * @response `201` `HomeworkTemplateCustomFieldFeedback` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postHomeworkTemplateCustomFieldFeedbackAsReviewer: (
      homework_template_custom_field_id: string,
      data: CustomFieldFeedbackRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomFieldFeedback, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/homework_template_custom_fields/${homework_template_custom_field_id}/feedbacks`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - "Reviewer限定 HomeworkTemplateCustomFieldFeedbackを更新"
     *
     * @tags Reviewer/HomeworkTemplateCustomFieldFeedbacks
     * @name PutHomeworkTemplateCustomFieldFeedbacksAsReviewer
     * @summary HomeworkTemplateCustomFieldFeedbackを更新
     * @request PUT:/reviewer/homework_template_custom_fields/{homework_template_custom_field_id}/feedbacks/{id}
     * @response `202` `HomeworkTemplateCustomFieldFeedback` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    putHomeworkTemplateCustomFieldFeedbacksAsReviewer: (
      homework_template_custom_field_id: string,
      id: string,
      data: PutCustomFieldFeedbackRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkTemplateCustomFieldFeedback, BadRequest | Unauthorized | Forbidden>(
        `/reviewer/homework_template_custom_fields/${homework_template_custom_field_id}/feedbacks/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - "Reviewer限定 HomeworkTemplateCustomFieldFeedbackを削除"
     *
     * @tags Reviewer/HomeworkTemplateCustomFieldFeedbacks
     * @name DeleteHomeworkTemplateCustomFieldFeedbacksAsReviewer
     * @summary HomeworkTemplateCustomFieldFeedbackを削除
     * @request DELETE:/reviewer/homework_template_custom_fields/{homework_template_custom_field_id}/feedbacks/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteHomeworkTemplateCustomFieldFeedbacksAsReviewer: (
      homework_template_custom_field_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/reviewer/homework_template_custom_fields/${homework_template_custom_field_id}/feedbacks/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Reviewer限定 HomeworkChunk の一覧を返却
     *
     * @tags Reviewer/HomeworkChunks
     * @name GetHomeworkChunksAsReviewer
     * @summary HomeworkChunk の一覧を返却
     * @request GET:/reviewer/homework_chunks
     * @response `200` `{ homework_chunks: (HomeworkChunkList)[], homework_chunk_statuses: HomeworkChunkStatuses, total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getHomeworkChunksAsReviewer: (query: GetHomeworkChunksAsReviewerParams, params?: RequestParams) =>
      this.request<
        { homework_chunks: HomeworkChunkList[]; homework_chunk_statuses: HomeworkChunkStatuses; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/reviewer/homework_chunks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Reviewer限定 HomeworkChunk の詳細を返却
     *
     * @tags Reviewer/HomeworkChunk
     * @name GetHomeworkChunkAsReviewer
     * @summary HomeworkChunk の詳細を返却
     * @request GET:/reviewer/homework_chunks/{id}
     * @response `200` `HomeworkChunkDetail` OK
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    getHomeworkChunkAsReviewer: (id: string, params?: RequestParams) =>
      this.request<
        HomeworkChunkDetail,
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/reviewer/homework_chunks/${id}`, "GET", params),

    /**
     * @description - Reviewer限定 HomeworkChunk を更新
     *
     * @tags Reviewer/HomeworkChunk
     * @name PutHomeworkChunkAsReviewer
     * @summary HomeworkChunk を更新
     * @request PUT:/reviewer/homework_chunks/{id}
     * @response `202` `HomeworkChunkDetail` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putHomeworkChunkAsReviewer: (
      id: string,
      data: HomeworkChunkReviewerFileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<HomeworkChunkDetail, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/homework_chunks/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description レビュアーの自社が抱えている宿題の一覧を取得 [:self_assign]で自身が担当しているHomeworkのみ取得
     *
     * @tags Reviewer/Homework, Homework
     * @name FetchHomeworksAsReviewer
     * @summary レビュアーが所属添削会社に紐づく添削会社へ提出済みのHomework一覧を取得
     * @request GET:/reviewer/homeworks
     * @response `200` `{ total_count: number, homeworks: (ReviewerHomework)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `any` Forbidden
     * @response `404` `{ error?: string }`
     */
    fetchHomeworksAsReviewer: (query: FetchHomeworksAsReviewerParams, params?: RequestParams) =>
      this.request<
        { total_count: number; homeworks: ReviewerHomework[] },
        BadRequest | Unauthorized | { error?: string }
      >(`/reviewer/homeworks${this.addQueryParams(query)}`, "GET", params),

    /**
     * No description
     *
     * @tags Reviewer/Homework, Homework
     * @name FetchHomeworkAsReviewer
     * @summary レビュアーが所属添削会社に紐づく添削会社へ提出済みのHomework一覧を
     * @request GET:/reviewer/homeworks/{homework_id}
     * @response `200` `HomeworkDetails` OK
     * @response `400` `any` Bad Request
     * @response `401` `any` Unauthorized
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    fetchHomeworkAsReviewer: (homework_id: string, params?: RequestParams) =>
      this.request<HomeworkDetails, { error?: string }>(`/reviewer/homeworks/${homework_id}`, "GET", params),

    /**
     * @description - Reviewerが宿題を更新する。 - メール通知先：提出物の担当先生 - メール内容：添削が完了したこと - 管理者権限がある添削者のみ実行可能
     *
     * @tags Reviewer/Homework
     * @name UpdateHomeworkAsReviewer
     * @summary 宿題の更新。同時にメール通知する。
     * @request PUT:/reviewer/homeworks/{homework_id}
     * @response `200` `HomeworkDetails` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `404` `{ error?: string }`
     * @response `500` `any` Internal Server Error
     */
    updateHomeworkAsReviewer: (homework_id: string, data: PutHomeworkRequestBodyAsReviewer, params?: RequestParams) =>
      this.request<HomeworkDetails, BadRequest | Unauthorized | { error?: string }>(
        `/reviewer/homeworks/${homework_id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 レビュアー担当を複数設定可能
     *
     * @tags Reviewer/Homework
     * @name HomeworksAssignUpdate
     * @summary レビュアーが提出物の担当を複数設定可能
     * @request PUT:/reviewer/homeworks/{homework_id}/assign
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    homeworksAssignUpdate: (homework_id: string, data: HomeworkAssignRequestBodyAsReviewer, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/homeworks/${homework_id}/assign`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Reviewer/HomeworkTemplate, Reviewer/Homework, Homework, HomeworkTemplate
     * @name UploadHomeworkImagesAsReviewer
     * @request POST:/reviewer/homeworks/{homework_id}/upload_images
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    uploadHomeworkImagesAsReviewer: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/reviewer/homeworks/${homework_id}/upload_images`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Reviewer/HomeworkTemplate, Reviewer/Homework, Homework, HomeworkTemplate
     * @name SaveEditedHomeworkImageAsReviewer
     * @request POST:/reviewer/homeworks/{homework_id}/save_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    saveEditedHomeworkImageAsReviewer: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/reviewer/homeworks/${homework_id}/save_image`,
        "POST",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Reviewer/HomeworkTemplate, Reviewer/Homework, Homework, HomeworkTemplate
     * @name UpdateHomeworkImageAsReviewer
     * @request PUT:/reviewer/homeworks/{homework_id}/update_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    updateHomeworkImageAsReviewer: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/reviewer/homeworks/${homework_id}/update_image`,
        "PUT",
        params,
        data,
      ),

    /**
     * No description
     *
     * @tags Reviewer/HomeworkTemplate, Reviewer/Homework, Homework, HomeworkTemplate
     * @name DeleteHomeworkImageAsReviewer
     * @request DELETE:/reviewer/homeworks/{homework_id}/delete_image
     * @response `200` `any`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     * @response `500` `any`
     */
    deleteHomeworkImageAsReviewer: (homework_id: string, data: any, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/reviewer/homeworks/${homework_id}/delete_image`,
        "DELETE",
        params,
        data,
      ),

    /**
     * @description 添削者が宿題の添削画像ダウンロード用URL一覧を取得
     *
     * @tags Homework
     * @name GetHomeworkImageDownloadUrlsAsReviewer
     * @summary 添削者が宿題の詳細を取得
     * @request GET:/reviewer/homeworks/get_download_urls
     * @response `200` `{ homeworks: (HomeworkDownloadUrl)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getHomeworkImageDownloadUrlsAsReviewer: (
      query: GetHomeworkImageDownloadUrlsAsReviewerParams,
      params?: RequestParams,
    ) =>
      this.request<{ homeworks: HomeworkDownloadUrl[] }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/homeworks/get_download_urls${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Reviewer/Homework
     * @name CountHomeworkStatusesAsReviewer
     * @summary 提出物の添削担当者へ、担当している提出物のステータスと件数を集計して、通知・メールを送付する
     * @request POST:/reviewer/homeworks/count_statuses
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    countHomeworkStatusesAsReviewer: (data: HomeworkCountStatusesRequestBodyAsReviewer, params?: RequestParams) =>
      this.request<
        { message?: string },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/reviewer/homeworks/count_statuses`, "POST", params, data),

    /**
     * @description 添削者が自身が作成した宿題ファイル一括登録依頼を取得する
     *
     * @tags Homework, SubmitHomeworkFilesRequest
     * @name FetchSubmitHomeworkFilesRequestsAsReviewer
     * @summary 添削者が宿題のQRコード付き回答ファイルの処理依頼を取得する
     * @request GET:/reviewer/submit_homework_files_requests
     * @response `200` `{ requests: (SubmitHomeworkFilesRequest)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSubmitHomeworkFilesRequestsAsReviewer: (
      query: FetchSubmitHomeworkFilesRequestsAsReviewerParams,
      params?: RequestParams,
    ) =>
      this.request<{ requests: SubmitHomeworkFilesRequest[]; total_count: number }, Unauthorized | Forbidden>(
        `/reviewer/submit_homework_files_requests${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Homework, SubmitHomeworkFilesRequest
     * @name CreateSubmitHomeworkFilesRequestAsReviewer
     * @summary 添削者が宿題ファイル一括登録リクエストを作成
     * @request POST:/reviewer/submit_homework_files_requests
     * @response `201` `SubmitHomeworkFilesRequest` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `any` Unprocessable Entity (WebDAV)
     */
    createSubmitHomeworkFilesRequestAsReviewer: (data: UserNicknameRequestBody, params?: RequestParams) =>
      this.request<SubmitHomeworkFilesRequest, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/submit_homework_files_requests`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Reviewer限定 お問い合わせ一覧を返却
     *
     * @tags Reviewer/Inquiries
     * @name GetReviewerInquiries
     * @summary Reviewer: お問い合わせ一覧を返却
     * @request GET:/reviewer/inquiries
     * @response `200` `{ inquiries: (InquirySimpleListBase)[], total_count: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getReviewerInquiries: (query: GetReviewerInquiriesParams, params?: RequestParams) =>
      this.request<{ inquiries: InquirySimpleListBase[]; total_count: number }, Unauthorized | Forbidden>(
        `/reviewer/inquiries${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description Reviewer限定 お問い合わせ情報取得
     *
     * @tags Reviewer/Inquiries
     * @name GetReviewerInquiriesId
     * @summary Reviewer: 提出物のお問い合わせ情報参照
     * @request GET:/reviewer/inquiries/{id}
     * @response `200` `InquirySimpleBase` OK
     * @response `400` `BadRequest`
     */
    getReviewerInquiriesId: (id: string, params?: RequestParams) =>
      this.request<InquirySimpleBase, BadRequest>(`/reviewer/inquiries/${id}`, "GET", params),

    /**
     * @description Reviewer限定 新着コメントが存在するお問い合わせの件数を返却
     *
     * @tags Reviewer/Inquiries
     * @name GetTheNumberOfNewCommentInquiriesAsReviewer
     * @summary 新着コメントが存在するお問い合わせの件数を返却
     * @request GET:/reviewer/inquiries/new_comment_count
     * @response `200` `{ count?: number }` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getTheNumberOfNewCommentInquiriesAsReviewer: (params?: RequestParams) =>
      this.request<{ count?: number }, Unauthorized | Forbidden>(
        `/reviewer/inquiries/new_comment_count`,
        "GET",
        params,
      ),

    /**
     * @description Reviewer限定 お問い合わせコメントの登録
     *
     * @tags Reviewer/InquiryComments
     * @name PostReviewerInquiryComments
     * @summary Reviewer: お問い合わせコメントの登録
     * @request POST:/reviewer/inquiry_comments
     * @response `201` `InquiryComment` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postReviewerInquiryComments: (
      query: PostReviewerInquiryCommentsParams,
      data: BodyRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<InquiryComment, Unauthorized | Forbidden>(
        `/reviewer/inquiry_comments${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description Reviewer限定 お問い合わせコメントの情報の更新
     *
     * @tags Reviewer/InquiryComments
     * @name PutReviewerInquiryCommentsId
     * @summary Reviewer: お問い合わせコメントの情報更新
     * @request PUT:/reviewer/inquiry_comments/{id}
     * @response `202` `InquiryComment` Accepted
     * @response `400` `BadRequest`
     */
    putReviewerInquiryCommentsId: (
      { id, ...query }: PutReviewerInquiryCommentsIdParams,
      data: InquiryCommentRequestBody,
      params?: RequestParams,
    ) =>
      this.request<InquiryComment, BadRequest>(
        `/reviewer/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description Reviewer限定 お問い合わせコメントの情報の削除
     *
     * @tags Reviewer/InquiryComments
     * @name DeleteReviewerInquiryCommentsId
     * @summary Reviewer: お問い合わせコメントの削除
     * @request DELETE:/reviewer/inquiry_comments/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteReviewerInquiryCommentsId: (
      { id, ...query }: DeleteReviewerInquiryCommentsIdParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/reviewer/inquiry_comments/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Reviewer限定 AttachedImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags Reviewer/AttachedImages
     * @name PostAttachedImageAsReviewer
     * @summary 画像 を登録
     * @request POST:/reviewer/attached_images
     * @response `201` `AttachedImageBase` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    postAttachedImageAsReviewer: (
      query: PostAttachedImageAsReviewerParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | { error?: string }>(
        `/reviewer/attached_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 AttachedImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Reviewer/AttachedImages
     * @name GetAttachedImageAsReviewer
     * @summary 画像 の詳細を返却
     * @request GET:/reviewer/attached_images/{id}
     * @response `200` `AttachedImageBase` OK
     * @response `400` `BadRequest`
     */
    getAttachedImageAsReviewer: ({ id, ...query }: GetAttachedImageAsReviewerParams, params?: RequestParams) =>
      this.request<AttachedImageBase, BadRequest>(
        `/reviewer/attached_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperReviewer限定 AttachedImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Reviewer/AttachedImages
     * @name PutAttachedImageAsReviewer
     * @summary 画像 を更新
     * @request PUT:/reviewer/attached_images/{id}
     * @response `202` `AttachedImageBase` Accepted
     * @response `400` `BadRequest`
     */
    putAttachedImageAsReviewer: (
      { id, ...query }: PutAttachedImageAsReviewerParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest>(
        `/reviewer/attached_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 AttachedImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Reviewer/AttachedImages
     * @name DeleteAttachedImageAsReviewer
     * @summary 画像 を削除
     * @request DELETE:/reviewer/attached_images/{id}
     * @response `200` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     */
    deleteAttachedImageAsReviewer: ({ id, ...query }: DeleteAttachedImageAsReviewerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest>(
        `/reviewer/attached_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - "Reviewer限定 FeedbackTemplate::Homework を一覧を返却"
     *
     * @tags Reviewer/FeedbackTemplate/Homework
     * @name GetFeedbackTemplateHomeworksAsReviewer
     * @summary 提出物FBの一覧を返却
     * @request GET:/reviewer/feedback_template/homeworks
     * @response `200` `{ feedback_templates: (FeedbackTemplateHomeworkBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplateHomeworksAsReviewer: (
      query: GetFeedbackTemplateHomeworksAsReviewerParams,
      params?: RequestParams,
    ) =>
      this.request<
        { feedback_templates: FeedbackTemplateHomeworkBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/reviewer/feedback_template/homeworks${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Reviewer限定 コメント一覧を返却 - categorizable_type と categorizable_id は必須
     *
     * @tags Reviewer/CategorizableComments
     * @name GetCategorizableCommentsAsReviewer
     * @summary コメント一覧を返却
     * @request GET:/reviewer/categorizable_comments
     * @response `200` `{ categorizable_comments: (CategorizableCommentBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getCategorizableCommentsAsReviewer: (query: GetCategorizableCommentsAsReviewerParams, params?: RequestParams) =>
      this.request<
        { categorizable_comments: CategorizableCommentBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/reviewer/categorizable_comments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Reviewer限定 コメントを登録 - categorizable_type と categorizable_id は必須
     *
     * @tags Reviewer/CategorizableComments
     * @name PostCategorizableCommentAsReviewer
     * @summary コメントを登録
     * @request POST:/reviewer/categorizable_comments
     * @response `201` `CategorizableCommentBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postCategorizableCommentAsReviewer: (
      query: PostCategorizableCommentAsReviewerParams,
      data: BodyRequiredRequestBody & CommentImagesRequestBody,
      params?: RequestParams,
    ) =>
      this.request<CategorizableCommentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/categorizable_comments${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 コメントを更新 - categorizable_type と categorizable_id は必須
     *
     * @tags Reviewer/CategorizableComments
     * @name PutCategorizableCommentAsReviewer
     * @summary コメントを更新
     * @request PUT:/reviewer/categorizable_comments/{id}
     * @response `202` `CategorizableCommentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putCategorizableCommentAsReviewer: (
      { id, ...query }: PutCategorizableCommentAsReviewerParams,
      data: BodyRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<CategorizableCommentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/categorizable_comments/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 コメントを削除 - categorizable_type と categorizable_id は必須
     *
     * @tags Reviewer/CategorizableComments
     * @name DeleteCategorizableCommentAsReviewer
     * @summary コメントを削除
     * @request DELETE:/reviewer/categorizable_comments/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteCategorizableCommentAsReviewer: (
      { id, ...query }: DeleteCategorizableCommentAsReviewerParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/categorizable_comments/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - "Reviewer限定 FeedbackTemplate::Tag を一覧を返却"
     *
     * @tags Reviewer/FeedbackTemplate/Tag
     * @name GetFeedbackTemplateTagsAsReviewer
     * @summary 提出物FBのタグ一覧を返却
     * @request GET:/reviewer/feedback_template/tags
     * @response `200` `{ feedback_template_tags: (HasName)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplateTagsAsReviewer: (query: GetFeedbackTemplateTagsAsReviewerParams, params?: RequestParams) =>
      this.request<
        { feedback_template_tags: HasName[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/reviewer/feedback_template/tags${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - "Reviewer限定 TtCsvExport を一覧を返却"
     *
     * @tags Reviewer/TtCsvExports
     * @name GetTtCsvExportsAsReviewer
     * @summary CSV一覧を返却
     * @request GET:/reviewer/tt_csv/exports
     * @response `200` `{ tt_csv_exports: (TtCsvExportBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getTtCsvExportsAsReviewer: (query: GetTtCsvExportsAsReviewerParams, params?: RequestParams) =>
      this.request<
        { tt_csv_exports: TtCsvExportBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/reviewer/tt_csv/exports${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - Reviewer限定 TtCsvExport を登録 - type: reviewer can only use starting with reviewer - target_company_id: required when exporting reviewer_homework_template_custom_field_list - homework_template_id: required when exporting reviewer_homework_template_custom_field_list
     *
     * @tags Reviewer/TtCsvExports
     * @name PostTtCsvExportAsReviewer
     * @summary CSV出力 をリクエスト
     * @request POST:/reviewer/tt_csv/exports
     * @response `200` `TtCsvExportBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postTtCsvExportAsReviewer: (
      query: PostTtCsvExportAsReviewerParams,
      data: TtCsvExportRequestBody,
      params?: RequestParams,
    ) =>
      this.request<TtCsvExportBase, BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage>(
        `/reviewer/tt_csv/exports${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 CommentImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags Reviewer/CommentImages
     * @name PostCommentImageAsReviewer
     * @summary 画像 を登録
     * @request POST:/reviewer/comment_images
     * @response `201` `CommentImageBase` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    postCommentImageAsReviewer: (
      query: PostCommentImageAsReviewerParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<CommentImageBase, BadRequest | { error?: string }>(
        `/reviewer/comment_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 CommentImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Reviewer/CommentImages
     * @name GetCommentImageAsReviewer
     * @summary 画像 の詳細を返却
     * @request GET:/reviewer/comment_images/{id}
     * @response `200` `CommentImageBase` OK
     * @response `400` `BadRequest`
     */
    getCommentImageAsReviewer: ({ id, ...query }: GetCommentImageAsReviewerParams, params?: RequestParams) =>
      this.request<CommentImageBase, BadRequest>(
        `/reviewer/comment_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperReviewer限定 CommentImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Reviewer/CommentImages
     * @name PutCommentImageAsReviewer
     * @summary 画像 を更新
     * @request PUT:/reviewer/comment_images/{id}
     * @response `202` `CommentImageBase` Accepted
     * @response `400` `BadRequest`
     */
    putCommentImageAsReviewer: (
      { id, ...query }: PutCommentImageAsReviewerParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<CommentImageBase, BadRequest>(
        `/reviewer/comment_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 CommentImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Reviewer/CommentImages
     * @name DeleteCommentImageAsReviewer
     * @summary 画像 を削除
     * @request DELETE:/reviewer/comment_images/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     */
    deleteCommentImageAsReviewer: ({ id, ...query }: DeleteCommentImageAsReviewerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest>(
        `/reviewer/comment_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - Reviewer限定 Approvingを登録
     *
     * @tags Reviewer/Approvings
     * @name PostApprovingAsReviewer
     * @summary フィードバックのステータスを登録
     * @request POST:/reviewer/approvings
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postApprovingAsReviewer: (data: ApprovingRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/approvings`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Reviewer限定 Approvingを削除 - categorizable_type と categorizable_id は必須
     *
     * @tags Reviewer/Approvings
     * @name DeleteApprovingAsReviewer
     * @summary フィードバックのステータスを削除
     * @request DELETE:/reviewer/approvings/{id}
     * @response `200` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteApprovingAsReviewer: ({ id, ...query }: DeleteApprovingAsReviewerParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/approvings/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description 自分自身の情報のみ取得可能 他人を閲覧しようとした場合404
     *
     * @tags Users
     * @name GetUserAsReviewer
     * @summary 添削者自身の情報詳細を取得
     * @request GET:/reviewer/users/{id}
     * @response `200` `BasicUserInfo` OK
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getUserAsReviewer: (id: string, params?: RequestParams) =>
      this.request<BasicUserInfo, Unauthorized | Forbidden | { error?: string }>(
        `/reviewer/users/${id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags Reviewer/Users
     * @name PutUserAsReviewer
     * @summary 添削者が自身のプロフィールを更新する
     * @request PUT:/reviewer/users/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    putUserAsReviewer: (id: string, data: UserNicknameRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | { error?: string }>(`/reviewer/users/${id}`, "PUT", params, data),

    /**
     * @description - Reviewer限定 提出物のカスタムフィールドの値の情報の更新 - group_affiliate_package_id: グループ向けの提出物に関連する場合必須。
     *
     * @tags Reviewer/HomeworkTemplateCustomValues
     * @name PutHomeworkTemplateCustomValueAsReviewer
     * @summary Reviewer: 提出物のカスタムフィールドの値の情報更新
     * @request PUT:/reviewer/homework_template_custom_values/{id}
     * @response `202` `{ homework_template_custom_fields: (HomeworkTemplateCustomFieldAndValue)[] }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     * @response `422` `ErrorsWithKeyMessage`
     */
    putHomeworkTemplateCustomValueAsReviewer: (
      id: string,
      data: CustomValueRequestBodyAsOwnerAndTeacherAndReviewer,
      params?: RequestParams,
    ) =>
      this.request<
        { homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[] },
        BadRequest | Unauthorized | Forbidden | { error?: string } | ErrorsWithKeyMessage
      >(`/reviewer/homework_template_custom_values/${id}`, "PUT", params, data),
  };
  contentProvider = {
    /**
     * @description ContentProvider限定 探究成果物一覧を返却
     *
     * @tags ContentProvider/Articles
     * @name GetArticlesAsContentProvider
     * @summary 探究成果物一覧を返却
     * @request GET:/content_provider/articles
     * @response `200` `{ articles: (ArticleBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getArticlesAsContentProvider: (query: GetArticlesAsContentProviderParams, params?: RequestParams) =>
      this.request<{ articles: ArticleBase[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/content_provider/articles${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - this endpoint needs word_cloud_feature
     *
     * @tags ContentProvider/Article
     * @name FetchArticleWordCloudAsContentProvider
     * @summary 自身が閲覧可能な探究成果から生成したワードクラウドリストを返却
     * @request GET:/content_provider/articles/word_cloud
     * @response `200` `WordCloudBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchArticleWordCloudAsContentProvider: (
      query: FetchArticleWordCloudAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<WordCloudBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/articles/word_cloud${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ContentProvider限定 探究成果物の詳細を返却
     *
     * @tags ContentProvider/Articles
     * @name GetArticleAsContentProvider
     * @summary 探究成果物の詳細を返却
     * @request GET:/content_provider/articles/{id}
     * @response `200` `ArticleDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getArticleAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<ArticleDetailBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/articles/${id}`,
        "GET",
        params,
      ),

    /**
     * @description ContentProvider限定 探究成果物のコメント一覧を返却
     *
     * @tags ContentProvider/ArticleComments
     * @name GetArticleCommentsAsContentProvider
     * @summary 探究成果物のコメント一覧を返却
     * @request GET:/content_provider/articles/{article_id}/comments
     * @response `200` `{ article_comments: (ArticleComment)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getArticleCommentsAsContentProvider: (
      { article_id, ...query }: GetArticleCommentsAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<{ article_comments: ArticleComment[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/content_provider/articles/${article_id}/comments${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ContentProvider限定 探究成果物のコメントを保存する
     *
     * @tags ContentProvider/ArticleComments
     * @name PostArticleCommentsAsContentProvider
     * @summary 探究成果物のコメントを保存
     * @request POST:/content_provider/articles/{article_id}/comments
     * @response `201` `ArticleComment` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    postArticleCommentsAsContentProvider: (
      article_id: string,
      data: { body: string; comment_images?: File[] },
      params?: RequestParams,
    ) =>
      this.request<ArticleComment, Unauthorized | Forbidden>(
        `/content_provider/articles/${article_id}/comments`,
        "POST",
        params,
        data,
      ),

    /**
     * @description ContentProvider限定 探究成果物のコメント詳細を更新
     *
     * @tags ContentProvider/ArticleComments
     * @name GetArticleCommentAsContentProvider
     * @summary 探究成果物のコメント詳細を更新
     * @request GET:/content_provider/articles/{article_id}/comments/{id}
     * @response `200` `ArticleComment` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getArticleCommentAsContentProvider: (article_id: string, id: string, params?: RequestParams) =>
      this.request<ArticleComment, BadRequest | Unauthorized | Forbidden>(
        `/content_provider/articles/${article_id}/comments/${id}`,
        "GET",
        params,
      ),

    /**
     * @description ContentProvider限定 探究成果物のコメント詳細を返却
     *
     * @tags ContentProvider/ArticleComments
     * @name PutArticleCommentAsContentProvider
     * @summary 探究成果物のコメント詳細を返却
     * @request PUT:/content_provider/articles/{article_id}/comments/{id}
     * @response `202` `ArticleComment` Accepted
     * @response `400` `BadRequest`
     */
    putArticleCommentAsContentProvider: (
      article_id: string,
      id: string,
      data: { body: string },
      params?: RequestParams,
    ) =>
      this.request<ArticleComment, BadRequest>(
        `/content_provider/articles/${article_id}/comments/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description ContentProvider限定 探究成果物のコメント詳細を削除
     *
     * @tags ContentProvider/ArticleComments
     * @name DeleteArticleCommentAsContentProvider
     * @summary 探究成果物のコメント詳細を削除
     * @request DELETE:/content_provider/articles/{article_id}/comments/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteArticleCommentAsContentProvider: (article_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/content_provider/articles/${article_id}/comments/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 ArticleCommentImage を登録
     *
     * @tags ContentProvider/ArticleCommentImages
     * @name PostArticleCommentImageAsContentProvider
     * @summary 探究成果物のコメントに画像 を登録
     * @request POST:/content_provider/articles/{article_id}/comments/{comment_id}/article_comment_images
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postArticleCommentImageAsContentProvider: (
      article_id: string,
      comment_id: string,
      data: { file: File },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/articles/${article_id}/comments/${comment_id}/article_comment_images`,
        "POST",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description - ContentProvider限定 ArticleCommentImage を削除
     *
     * @tags ContentProvider/ArticleCommentImages
     * @name DeleteArticleCommentImageAsContentProvider
     * @summary 探究成果物のコメント画像 を削除
     * @request DELETE:/content_provider/articles/{article_id}/comments/{comment_id}/article_comment_images/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteArticleCommentImageAsContentProvider: (
      article_id: string,
      comment_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/articles/${article_id}/comments/${comment_id}/article_comment_images/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description ContentProvider限定 探究成果物に対するいいねを返却
     *
     * @tags ContentProvider/ArticleLikes
     * @name GetArticleLikesAsContentProvider
     * @summary 探究成果物に対するいいねを返却
     * @request GET:/content_provider/articles/{article_id}/likes
     * @response `200` `{ article_likes: (ArticleLike)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getArticleLikesAsContentProvider: (
      { article_id, ...query }: GetArticleLikesAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<{ article_likes: ArticleLike[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/content_provider/articles/${article_id}/likes${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ContentProvider限定 探究成果物に対するいいねを保存する
     *
     * @tags ContentProvider/ArticleLikes
     * @name PostArticleLikesAsContentProvider
     * @summary 探究成果物に対するいいねを保存
     * @request POST:/content_provider/articles/{article_id}/likes
     * @response `201` `ArticleLike` Created
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `422` `ErrorsWithKeyMessage`
     */
    postArticleLikesAsContentProvider: (article_id: string, params?: RequestParams) =>
      this.request<ArticleLike, Unauthorized | Forbidden | ErrorsWithKeyMessage>(
        `/content_provider/articles/${article_id}/likes`,
        "POST",
        params,
      ),

    /**
     * @description ContentProvider限定 探究成果物に対するいいねを削除
     *
     * @tags ContentProvider/ArticleLikes
     * @name DeleteArticleLikeAsContentProvider
     * @summary 探究成果物に対するいいねを削除
     * @request DELETE:/content_provider/articles/{article_id}/likes/{id}
     * @response `200` `{ message?: string }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    deleteArticleLikeAsContentProvider: (article_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden>(
        `/content_provider/articles/${article_id}/likes/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 企業のコンテンツが閲覧可能な学校の一覧を返却
     *
     * @tags ContentProvider/Sponsor
     * @name GetSponsorDeliverySchoolsAsContentProvider
     * @summary 企業のコンテンツが閲覧可能な学校の一覧を返却
     * @request GET:/content_provider/sponsor/delivery_schools
     * @response `200` `{ delivery_schools: (SponsorSchoolBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getSponsorDeliverySchoolsAsContentProvider: (
      query: GetSponsorDeliverySchoolsAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { delivery_schools: SponsorSchoolBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/sponsor/delivery_schools${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description ContentProvider限定 企業一覧(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags ContentProvider/Sponsor/Infos
     * @name GetContentProviderSponsorInfos
     * @summary 企業一覧を返却
     * @request GET:/content_provider/sponsor/infos
     * @response `200` `{ sponsors: (SponsorInfoListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    getContentProviderSponsorInfos: (query: GetContentProviderSponsorInfosParams, params?: RequestParams) =>
      this.request<{ sponsors: SponsorInfoListBase[]; total_count: number }, BadRequest | Unauthorized | Forbidden>(
        `/content_provider/sponsor/infos${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description ContentProvider限定 企業の詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags ContentProvider/Sponsor/Infos
     * @name GetContentProviderSponsorInfosId
     * @summary 企業の詳細情報を返却
     * @request GET:/content_provider/sponsor/infos/{id}
     * @response `200` `SponsorInfoDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getContentProviderSponsorInfosId: (id: string, params?: RequestParams) =>
      this.request<SponsorInfoDetailsBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/sponsor/infos/${id}`,
        "GET",
        params,
      ),

    /**
     * @description ContentProvider限定 企業プロジェクトの詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags ContentProvider/Sponsor/Projects
     * @name FetchOneSponsorProjectAsContentProvider
     * @summary 企業プロジェクトの詳細情報を返却
     * @request GET:/content_provider/sponsor/projects/{id}
     * @response `200` `SponsorProjectDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchOneSponsorProjectAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<SponsorProjectDetailsBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/sponsor/projects/${id}`,
        "GET",
        params,
      ),

    /**
     * @description 企業プロジェクトのレクチャーの一覧を返却
     *
     * @tags ContentProvider/Sponsor/Lectures
     * @name FetchSponsorLecturesAsContentProvider
     * @summary 企業プロジェクトのレクチャーの一覧を返却
     * @request GET:/content_provider/sponsor/projects/lectures
     * @response `200` `{ project_lectures: (SponsorLectureDetailsBase)[], total_count: number }` OK
     * @response `400` `{ message?: string }` Bad Request
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     */
    fetchSponsorLecturesAsContentProvider: (
      query: FetchSponsorLecturesAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { project_lectures: SponsorLectureDetailsBase[]; total_count: number },
        { message?: string } | Unauthorized | Forbidden
      >(`/content_provider/sponsor/projects/lectures${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description ContentProvider限定 企業プロジェクトのレクチャーの詳細情報(Sponsor::Infoのis_publicがtrueのみ)を返却
     *
     * @tags ContentProvider/Sponsor/Lectures
     * @name FetchSponsorLectureAsContentProvider
     * @summary 企業プロジェクトのレクチャーの詳細情報を返却
     * @request GET:/content_provider/sponsor/projects/lectures/{id}
     * @response `200` `SponsorLectureDetailsBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    fetchSponsorLectureAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<SponsorLectureDetailsBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/sponsor/projects/lectures/${id}`,
        "GET",
        params,
      ),

    /**
     * No description
     *
     * @tags ContentProvider/Notification
     * @name NotificationsList
     * @request GET:/content_provider/notifications
     * @response `200` `{ notifications?: ((NotificationBase & { read?: boolean }))[], total_count?: number }` OK
     */
    notificationsList: (query: NotificationsListParams, params?: RequestParams) =>
      this.request<{ notifications?: (NotificationBase & { read?: boolean })[]; total_count?: number }, any>(
        `/content_provider/notifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Info の詳細を返却
     *
     * @tags ContentProvider/PblBoardInfos
     * @name GetPblBoardInfoAsContentProvider
     * @summary 探究ボード の詳細を返却
     * @request GET:/content_provider/pbl/board/infos
     * @response `200` `PblBoardInfoBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardInfoAsContentProvider: (query: GetPblBoardInfoAsContentProviderParams, params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/infos${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Info を登録
     *
     * @tags ContentProvider/PblBoardInfos
     * @name PostPblBoardInfoAsContentProvider
     * @summary 探究ボード を登録
     * @request POST:/content_provider/pbl/board/infos
     * @response `201` `PblBoardInfoBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardInfoAsContentProvider: (params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/infos`,
        "POST",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Info を更新
     *
     * @tags ContentProvider/PblBoardInfos
     * @name PutPblBoardInfoAsContentProvider
     * @summary 探究ボード を更新
     * @request PUT:/content_provider/pbl/board/infos
     * @response `202` `PblBoardInfoBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardInfoAsContentProvider: (data: { event: PblBoardInfoEvent }, params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/infos`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Item を登録
     *
     * @tags ContentProvider/PblBoardItems
     * @name PostPblBoardItemAsContentProvider
     * @summary 探究ボードのコンテンツタイプ を登録
     * @request POST:/content_provider/pbl/board/items
     * @response `201` `PblBoardItemBaseAsShow` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardItemAsContentProvider: (
      data: { content_type: PblBoardItemContentType; sequence?: number },
      params?: RequestParams,
    ) =>
      this.request<PblBoardItemBaseAsShow, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Item を更新
     *
     * @tags ContentProvider/PblBoardItems
     * @name PutPblBoardItemAsContentProvider
     * @summary 探究ボードのコンテンツタイプ を更新
     * @request PUT:/content_provider/pbl/board/items/{id}
     * @response `202` `PblBoardItemBaseAsShow` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardItemAsContentProvider: (
      id: string,
      data: { content_type: PblBoardItemContentType; sequence?: number },
      params?: RequestParams,
    ) =>
      this.request<PblBoardItemBaseAsShow, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Item を削除
     *
     * @tags ContentProvider/PblBoardItems
     * @name DeletePblBoardItemAsContentProvider
     * @summary 探究ボードのコンテンツタイプ を削除
     * @request DELETE:/content_provider/pbl/board/items/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardItemAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Campaign の一覧を取得
     *
     * @tags ContentProvider/PblBoardCampaigns
     * @name GetPblBoardCampaignsAsContentProvider
     * @summary 探究ボードの探究成果募集 の一覧を取得
     * @request GET:/content_provider/pbl/board/campaigns
     * @response `200` `{ board_campaigns: (PblBoardCampaignList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardCampaignsAsContentProvider: (
      query: GetPblBoardCampaignsAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { board_campaigns: PblBoardCampaignList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/pbl/board/campaigns${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - ContentProvider限定 Pbl::Board::Campaign を登録
     *
     * @tags ContentProvider/PblBoardCampaigns
     * @name PostPblBoardCampaignAsContentProvider
     * @summary 探究ボードの探究成果募集 を登録
     * @request POST:/content_provider/pbl/board/campaigns
     * @response `201` `PblBoardCampaignBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardCampaignAsContentProvider: (
      data: {
        title: string;
        description?: string;
        start_date: string;
        end_date: string;
        status: PblBoardCampaignStatus;
      },
      params?: RequestParams,
    ) =>
      this.request<PblBoardCampaignBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/campaigns`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Campaign の詳細を取得
     *
     * @tags ContentProvider/PblBoardCampaigns
     * @name GetPblBoardCampaignAsContentProvider
     * @summary 探究ボードの探究成果募集 の詳細を取得
     * @request GET:/content_provider/pbl/board/campaigns/{id}
     * @response `200` `PblBoardCampaignBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardCampaignAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<PblBoardCampaignBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/campaigns/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Campaign を更新
     *
     * @tags ContentProvider/PblBoardCampaigns
     * @name PutPblBoardCampaignAsContentProvider
     * @summary 探究ボードの探究成果募集 を更新
     * @request PUT:/content_provider/pbl/board/campaigns/{id}
     * @response `202` `PblBoardCampaignBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardCampaignAsContentProvider: (
      id: string,
      data: { title: string; description?: string; start_date: string; end_date: string; event: PblBoardCampaignEvent },
      params?: RequestParams,
    ) =>
      this.request<PblBoardCampaignBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/campaigns/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Campaign を削除
     *
     * @tags ContentProvider/PblBoardCampaigns
     * @name DeletePblBoardCampaignAsContentProvider
     * @summary 探究ボードの探究成果募集 を削除
     * @request DELETE:/content_provider/pbl/board/campaigns/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardCampaignAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/campaigns/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::CampaignRequest の一覧を取得
     *
     * @tags ContentProvider/PblBoardCampaignRequests
     * @name GetPblBoardCampaignRequestAsContentProvider
     * @summary 探究ボードの探究成果募集のリクエスト一覧を取得
     * @request GET:/content_provider/pbl/board/campaigns/{campaign_id}/campaign_requests
     * @response `200` `{ board_campaign_requests: (PblBoardCampaignRequestList)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardCampaignRequestAsContentProvider: (
      { campaign_id, ...query }: GetPblBoardCampaignRequestAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { board_campaign_requests: PblBoardCampaignRequestList[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/content_provider/pbl/board/campaigns/${campaign_id}/campaign_requests${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::CampaignRequest を更新
     *
     * @tags ContentProvider/PblBoardCampaignRequests
     * @name PutPblBoardCampaignRequestAsContentProvider
     * @summary 探究ボードの探究成果募集のリクエスト更新
     * @request PUT:/content_provider/pbl/board/campaigns/{campaign_id}/campaign_requests/{id}
     * @response `202` `PblBoardCampaignRequestList` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardCampaignRequestAsContentProvider: (
      campaign_id: string,
      id: string,
      data: { event: PblBoardCampaignRequestEvent },
      params?: RequestParams,
    ) =>
      this.request<PblBoardCampaignRequestList, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/campaigns/${campaign_id}/campaign_requests/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::CampaignRequest を更新
     *
     * @tags ContentProvider/PblBoardCampaignRequests
     * @name PutPblBoardCampaignRequestSequenceAsContentProvider
     * @summary 探究ボードの探究成果募集の並び替えを更新
     * @request PUT:/content_provider/pbl/board/campaigns/{campaign_id}/campaign_requests/{id}/sequence
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardCampaignRequestSequenceAsContentProvider: (
      campaign_id: string,
      id: string,
      data: { sequence: number },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/campaigns/${campaign_id}/campaign_requests/${id}/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Article を一覧を返却 - 承認済みのものを取得 - 探究成果のstatusがpublishedのものを取得
     *
     * @tags ContentProvider/PblBoardArticles
     * @name GetPblBoardArticlesAsContentProvider
     * @summary 探究成果募集の探究成果情報 の一覧を返却
     * @request GET:/content_provider/pbl/board/articles
     * @response `200` `{ articles: (PblBoardArticleBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticlesAsContentProvider: (query: GetPblBoardArticlesAsContentProviderParams, params?: RequestParams) =>
      this.request<
        { articles: PblBoardArticleBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/pbl/board/articles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - this endpoint needs word_cloud_feature
     *
     * @tags ContentProvider/PblBoardArticles
     * @name GetPblBoardArticlesWordCloudAsContentProvider
     * @summary 自身が閲覧可能な探究成果から生成したワードクラウドリストを返却
     * @request GET:/content_provider/pbl/board/articles/word_cloud
     * @response `200` `WordCloudBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticlesWordCloudAsContentProvider: (
      query: GetPblBoardArticlesWordCloudAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<WordCloudBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/articles/word_cloud${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Article の詳細を返却 - 承認済みのものを取得 - 探究成果のstatusがpublishedのものを取得
     *
     * @tags ContentProvider/PblBoardArticles
     * @name GetPblBoardArticleAsContentProvider
     * @summary 探究成果募集の探究成果情報 の詳細を返却
     * @request GET:/content_provider/pbl/board/articles/{id}
     * @response `200` `ArticleDetailAsPblBoard` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticleAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<ArticleDetailAsPblBoard, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/articles/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Contents を登録 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags ContentProvider/PblBoardContents
     * @name PostPblBoardContentAsContentProvider
     * @summary 探究ボードのコンテンツ を登録
     * @request POST:/content_provider/pbl/board/items/{item_id}/contents
     * @response `201` `PblBoardContentBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardContentAsContentProvider: (
      item_id: string,
      data: {
        design_type?: PblBoardContentDesignType;
        catchphrase?: string;
        catchphrase_description?: string;
        catchphrase_image?: File;
        cta_name?: string;
        cta_url?: string;
        external_link?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<PblBoardContentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/contents`,
        "POST",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Contents の詳細を返却 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags ContentProvider/PblBoardContents
     * @name GetPblBoardContentAsContentProvider
     * @summary 探究ボードのコンテンツ の詳細を返却
     * @request GET:/content_provider/pbl/board/items/{item_id}/contents/{id}
     * @response `200` `PblBoardContentBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardContentAsContentProvider: (item_id: string, id: string, params?: RequestParams) =>
      this.request<PblBoardContentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/contents/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Contents を更新 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags ContentProvider/PblBoardContents
     * @name PutPblBoardContentAsContentProvider
     * @summary 探究ボードのコンテンツ を更新
     * @request PUT:/content_provider/pbl/board/items/{item_id}/contents/{id}
     * @response `202` `PblBoardContentBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardContentAsContentProvider: (
      item_id: string,
      id: string,
      data: {
        design_type?: PblBoardContentDesignType;
        catchphrase?: string;
        catchphrase_description?: string;
        catchphrase_image?: File;
        cta_name?: string;
        cta_url?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<PblBoardContentBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/contents/${id}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Contents を削除 - 探究ボードのコンテンツタイプが template のみ可能
     *
     * @tags ContentProvider/PblBoardContents
     * @name DeletePblBoardContentAsContentProvider
     * @summary 探究ボードのコンテンツ を削除
     * @request DELETE:/content_provider/pbl/board/items/{item_id}/contents/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardContentAsContentProvider: (item_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/contents/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Footers を登録
     *
     * @tags ContentProvider/PblBoardFooters
     * @name GetPblBoardFootersAsContentProvider
     * @summary 探究ボードのフッター の一覧を取得
     * @request GET:/content_provider/pbl/board/items/{item_id}/footers
     * @response `200` `{ board_footers: (PblBoardFooterBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardFootersAsContentProvider: (
      { item_id, ...query }: GetPblBoardFootersAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { board_footers: PblBoardFooterBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/pbl/board/items/${item_id}/footers${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - ContentProvider限定 Pbl::Board::Footers を登録 - 探究ボードのコンテンツタイプが footer のみ可能
     *
     * @tags ContentProvider/PblBoardFooters
     * @name PostPblBoardFooterAsContentProvider
     * @summary 探究ボードのフッター を登録
     * @request POST:/content_provider/pbl/board/items/{item_id}/footers
     * @response `201` `{ message?: string }` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardFooterAsContentProvider: (
      item_id: string,
      data: { category_name: string; footer_items?: { title: string; url: string }[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/footers`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Footers を更新 - 探究ボードのコンテンツタイプが footer のみ可能
     *
     * @tags ContentProvider/PblBoardFooters
     * @name PutPblBoardFooterAsContentProvider
     * @summary 探究ボードのフッター を更新
     * @request PUT:/content_provider/pbl/board/items/{item_id}/footers/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardFooterAsContentProvider: (
      item_id: string,
      id: string,
      data: { category_name: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/footers/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Footers を削除 - 探究ボードのコンテンツタイプが footer のみ可能
     *
     * @tags ContentProvider/PblBoardFooters
     * @name DeletePblBoardFooterAsContentProvider
     * @summary 探究ボードのフッター を削除
     * @request DELETE:/content_provider/pbl/board/items/{item_id}/footers/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardFooterAsContentProvider: (item_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/footers/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::Footers を更新 - 探究ボードのフッターの順番を更新
     *
     * @tags ContentProvider/PblBoardFooters
     * @name PutPblBoardFooterSequenceAsContentProvider
     * @summary 探究ボードのフッター を更新
     * @request PUT:/content_provider/pbl/board/items/{item_id}/footers/{id}/sequence
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardFooterSequenceAsContentProvider: (
      item_id: string,
      id: string,
      data: { sequence: number },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/footers/${id}/sequence`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::FooterItems を登録
     *
     * @tags ContentProvider/PblBoardFooterItems
     * @name PostPblBoardFooterItemAsContentProvider
     * @summary 探究ボードのフッターのリンク を登録
     * @request POST:/content_provider/pbl/board/items/{item_id}/footers/{footer_id}/footer_items
     * @response `201` `{ message?: string }` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postPblBoardFooterItemAsContentProvider: (
      item_id: string,
      footer_id: string,
      data: { title: string; url: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/footers/${footer_id}/footer_items`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::FooterItems を更新
     *
     * @tags ContentProvider/PblBoardFooterItems
     * @name PutPblBoardFooterItemAsContentProvider
     * @summary 探究ボードのフッターのリンク を更新
     * @request PUT:/content_provider/pbl/board/items/{item_id}/footers/{footer_id}/footer_items/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putPblBoardFooterItemAsContentProvider: (
      item_id: string,
      footer_id: string,
      id: string,
      data: { title: string; url: string },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/footers/${footer_id}/footer_items/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Pbl::Board::FooterItems を削除
     *
     * @tags ContentProvider/PblBoardFooterItems
     * @name DeletePblBoardFooterItemAsContentProvider
     * @summary 探究ボードのフッターのリンク を削除
     * @request DELETE:/content_provider/pbl/board/items/{item_id}/footers/{footer_id}/footer_items/{id}
     * @response `202` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deletePblBoardFooterItemAsContentProvider: (
      item_id: string,
      footer_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/pbl/board/items/${item_id}/footers/${footer_id}/footer_items/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 FeedbackTemplate を一覧を返却
     *
     * @tags ContentProvider/FeedbackTemplates
     * @name GetFeedbackTemplatesAsContentProvider
     * @summary 企業FBのテンプレート 一覧を返却
     * @request GET:/content_provider/feedback_templates
     * @response `200` `{ feedback_templates: (FeedbackTemplateBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getFeedbackTemplatesAsContentProvider: (
      query: GetFeedbackTemplatesAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { feedback_templates: FeedbackTemplateBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/feedback_templates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - ContentProvider限定 AttachedImage を登録 - 汎用的に扱える - 許可されたModelのみ登録可能
     *
     * @tags ContentProvider/AttachedImages
     * @name PostAttachedImageAsContentProvider
     * @summary 画像 を登録
     * @request POST:/content_provider/attached_images
     * @response `201` `AttachedImageBase` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    postAttachedImageAsContentProvider: (
      query: PostAttachedImageAsContentProviderParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | { error?: string }>(
        `/content_provider/attached_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 AttachedImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags ContentProvider/AttachedImages
     * @name GetAttachedImageAsContentProvider
     * @summary 画像 の詳細を返却
     * @request GET:/content_provider/attached_images/{id}
     * @response `200` `AttachedImageBase` OK
     * @response `400` `BadRequest`
     */
    getAttachedImageAsContentProvider: (
      { id, ...query }: GetAttachedImageAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest>(
        `/content_provider/attached_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - SuperContentProvider限定 AttachedImage を更新 - 汎用的に扱える - 許可されたModelのみ更新可能 - 該当の画像がない場合404でなく400を返す
     *
     * @tags ContentProvider/AttachedImages
     * @name PutAttachedImageAsContentProvider
     * @summary 画像 を更新
     * @request PUT:/content_provider/attached_images/{id}
     * @response `202` `AttachedImageBase` Accepted
     * @response `400` `BadRequest`
     */
    putAttachedImageAsContentProvider: (
      { id, ...query }: PutAttachedImageAsContentProviderParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest>(
        `/content_provider/attached_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 AttachedImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags ContentProvider/AttachedImages
     * @name DeleteAttachedImageAsContentProvider
     * @summary 画像 を削除
     * @request DELETE:/content_provider/attached_images/{id}
     * @response `200` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     */
    deleteAttachedImageAsContentProvider: (
      { id, ...query }: DeleteAttachedImageAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest>(
        `/content_provider/attached_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Request を一覧を返却
     *
     * @tags ContentProvider/Gogo/Request
     * @name GetGogoRequestsAsContentProvider
     * @summary 探究GoGoのリクエスト一覧を返却
     * @request GET:/content_provider/gogo/requests
     * @response `200` `{ gogo_requests: (GogoRequestListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoRequestsAsContentProvider: (query: GetGogoRequestsAsContentProviderParams, params?: RequestParams) =>
      this.request<
        { gogo_requests: GogoRequestListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/gogo/requests${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - ContentProvider限定 Gogo::Request の詳細を返却
     *
     * @tags ContentProvider/Gogo/Request
     * @name GetGogoRequestAsContentProvider
     * @summary 探究GoGoのリクエストの詳細を返却
     * @request GET:/content_provider/gogo/requests/{id}
     * @response `200` `GogoRequestDetailBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoRequestAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<GogoRequestDetailBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/requests/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Request を更新
     *
     * @tags ContentProvider/Gogo/Request
     * @name PutGogoRequestAsContentProvider
     * @summary 探究GoGoのリクエストを更新
     * @request PUT:/content_provider/gogo/requests/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoRequestAsContentProvider: (
      id: string,
      data: PutGogoRequestRequestBodyAsContentProvider,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/requests/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Message を一覧を返却
     *
     * @tags ContentProvider/Gogo/Message
     * @name GetGogoMessagesAsContentProvider
     * @summary 探究GoGoリクエストのメッセージ一覧を返却
     * @request GET:/content_provider/gogo/requests/{request_id}/messages
     * @response `200` `{ gogo_messages: (GogoMessageListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoMessagesAsContentProvider: (
      { request_id, ...query }: GetGogoMessagesAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { gogo_messages: GogoMessageListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/gogo/requests/${request_id}/messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - ContentProvider限定 Gogo::Message を登録
     *
     * @tags ContentProvider/Gogo/Message
     * @name PostGogoMessageAsContentProvider
     * @summary 探究GoGoリクエストのメッセージを登録
     * @request POST:/content_provider/gogo/requests/{request_id}/messages
     * @response `201` `GogoMessageListBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoMessageAsContentProvider: (
      { request_id, ...query }: PostGogoMessageAsContentProviderParams,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<GogoMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/requests/${request_id}/messages${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Message を更新
     *
     * @tags ContentProvider/Gogo/Message
     * @name PutGogoMessageAsContentProvider
     * @summary 探究GoGoリクエストのメッセージ を更新
     * @request PUT:/content_provider/gogo/requests/{request_id}/messages/{id}
     * @response `202` `GogoMessageListBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoMessageAsContentProvider: (
      { request_id, id, ...query }: PutGogoMessageAsContentProviderParams,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<GogoMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/requests/${request_id}/messages/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Message を削除
     *
     * @tags ContentProvider/Gogo/Message
     * @name DeleteGogoMessageAsContentProvider
     * @summary 探究GoGoリクエストのメッセージ を削除
     * @request DELETE:/content_provider/gogo/requests/{request_id}/messages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoMessageAsContentProvider: (
      { request_id, id, ...query }: DeleteGogoMessageAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/requests/${request_id}/messages/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 探究GoGoのリクエストの未読メッセージの件数を返却
     *
     * @tags ContentProvider/Gogo/Request
     * @name GetGogoRequestUnreadCountsAsContentProvider
     * @summary 探究GoGoのリクエストの未読メッセージの件数を返却
     * @request GET:/content_provider/gogo/requests/unread_count
     * @response `200` `{ unread_counts: (GogoRequestUnreadCountBase)[] }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoRequestUnreadCountsAsContentProvider: (
      query: GetGogoRequestUnreadCountsAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { unread_counts: GogoRequestUnreadCountBase[] },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/gogo/requests/unread_count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - ContentProvider限定 Gogo::Read を登録
     *
     * @tags ContentProvider/Gogo/Message
     * @name GetGogoMessageReadAsContentProvider
     * @summary 探究GoGoのリクエストの未読メッセージを既読メッセージに変更する
     * @request POST:/content_provider/gogo/requests/{request_id}/messages/read
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoMessageReadAsContentProvider: (
      { request_id, ...query }: GetGogoMessageReadAsContentProviderParams,
      data: { unread_message_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/requests/${request_id}/messages/read${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Program を一覧を返却
     *
     * @tags ContentProvider/Gogo/Program
     * @name GetGogoProgramsAsContentProvider
     * @summary 探究GoGooのプログラム一覧を返却
     * @request GET:/content_provider/gogo/programs
     * @response `200` `{ gogo_programs: (any)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramsAsContentProvider: (query: GetGogoProgramsAsContentProviderParams, params?: RequestParams) =>
      this.request<
        { gogo_programs: any[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/gogo/programs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - ContentProvider限定 Gogo::Program を登録
     *
     * @tags ContentProvider/Gogo/Program
     * @name PostGogoProgramAsContentProvider
     * @summary 探究GoGoのプログラムを登録
     * @request POST:/content_provider/gogo/programs
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoProgramAsContentProvider: (data: any, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Program の詳細を返却
     *
     * @tags ContentProvider/Gogo/Program
     * @name GetGogoProgramAsContentProvider
     * @summary 探究GoGoのプログラムの詳細を返却
     * @request GET:/content_provider/gogo/programs/{id}
     * @response `200` `any` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Request を更新
     *
     * @tags ContentProvider/Gogo/Program
     * @name PutGogoProgramAsContentProvider
     * @summary 探究GoGoのプログラムを更新
     * @request PUT:/content_provider/gogo/programs/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoProgramAsContentProvider: (id: string, data: any, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Program を削除
     *
     * @tags ContentProvider/Gogo/Program
     * @name DeleteGogoProgramAsContentProvider
     * @summary 探究GoGoのプログラムを削除
     * @request DELETE:/content_provider/gogo/programs/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoProgramAsContentProvider: (id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::ProgramRequest の詳細を返却
     *
     * @tags ContentProvider/Gogo/ProgramRequest
     * @name GetGogoProgramRequestAsContentProvider
     * @summary 探究GoGoのプログラムリクエストの詳細を返却
     * @request GET:/content_provider/gogo/programs/{program_id}/program_requests/{id}
     * @response `200` `any` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramRequestAsContentProvider: (program_id: string, id: string, params?: RequestParams) =>
      this.request<any, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${program_id}/program_requests/${id}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Request を更新
     *
     * @tags ContentProvider/Gogo/ProgramRequest
     * @name PutGogoProgramRequestAsContentProvider
     * @summary 探究GoGoのプログラムリクエストを更新
     * @request PUT:/content_provider/gogo/programs/{program_id}/program_requests/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoProgramRequestAsContentProvider: (program_id: string, id: string, data: any, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${program_id}/program_requests/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::ProgramRequest を削除
     *
     * @tags ContentProvider/Gogo/ProgramRequest
     * @name DeleteGogoProgramRequestAsContentProvider
     * @summary 探究GoGoのプログラムリクエストを削除
     * @request DELETE:/content_provider/gogo/programs/{program_id}/program_requests/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoProgramRequestAsContentProvider: (program_id: string, id: string, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${program_id}/program_requests/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::ProgramRequestMessage を一覧を返却
     *
     * @tags ContentProvider/Gogo/ProgramRequestMessage
     * @name GetGogoProgramRequestMessagesAsContentProvider
     * @summary 探究GoGoプログラムプログラムリクエストのメッセージ一覧を返却
     * @request GET:/content_provider/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages
     * @response `200` `{ gogo_messages: (GogoMessageListBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramRequestMessagesAsContentProvider: (
      { program_id, program_request_id, ...query }: GetGogoProgramRequestMessagesAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { gogo_messages: GogoMessageListBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/content_provider/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::ProgramRequestMessage を登録
     *
     * @tags ContentProvider/Gogo/ProgramRequestMessage
     * @name PostGogoProgramRequestMessageAsContentProvider
     * @summary 探究GoGoプログラムリクエストのメッセージを登録
     * @request POST:/content_provider/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages
     * @response `201` `GogoMessageListBase` Created
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoProgramRequestMessageAsContentProvider: (
      program_id: string,
      program_request_id: string,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<GogoMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::ProgramRequestMessage を更新
     *
     * @tags ContentProvider/Gogo/ProgramRequestMessage
     * @name PutGogoProgramRequestMessageAsContentProvider
     * @summary 探究GoGoプログラムリクエストのメッセージ を更新
     * @request PUT:/content_provider/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages/{id}
     * @response `202` `GogoMessageListBase` Accepted
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoProgramRequestMessageAsContentProvider: (
      program_id: string,
      program_request_id: string,
      id: string,
      data: MessageRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<GogoMessageListBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::ProgramRequestMessage を削除
     *
     * @tags ContentProvider/Gogo/ProgramRequestMessage
     * @name DeleteGogoProgramRequestMessageAsContentProvider
     * @summary 探究GoGoプログラムリクエストのメッセージ を削除
     * @request DELETE:/content_provider/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages/{id}
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoProgramRequestMessageAsContentProvider: (
      program_id: string,
      program_request_id: string,
      id: string,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::Read を登録
     *
     * @tags ContentProvider/Gogo/ProgramRequestMessage
     * @name GetGogoProgramRequestMessageReadAsContentProvider
     * @summary 探究GoGoのプログラムリクエストの未読メッセージを既読メッセージに変更する
     * @request POST:/content_provider/gogo/programs/{program_id}/program_requests/{program_request_id}/program_request_messages/read
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramRequestMessageReadAsContentProvider: (
      program_id: string,
      program_request_id: string,
      data: { unread_message_ids: string[] },
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/programs/${program_id}/program_requests/${program_request_id}/program_request_messages/read`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::CompanyInformation を登録
     *
     * @tags ContentProvider/Gogo/CompanyInformation
     * @name PostGogoCompanyInformationAsContentProvider
     * @summary 探究GoGok 企業情報を登録
     * @request POST:/content_provider/gogo/company_informations
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoCompanyInformationAsContentProvider: (data: GogoCompanyInformationRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/company_informations`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::CompanyInformation の詳細を返却
     *
     * @tags ContentProvider/Gogo/CompanyInformation
     * @name GetGogoCompanyInformationAsContentProvider
     * @summary 探究GoGo 企業情報の詳細を返却
     * @request GET:/content_provider/gogo/company_informations
     * @response `200` `GogoCompanyInformationBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoCompanyInformationAsContentProvider: (params?: RequestParams) =>
      this.request<GogoCompanyInformationBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/company_informations`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::CompanyInformation を更新
     *
     * @tags ContentProvider/Gogo/CompanyInformation
     * @name PutGogoCompanyInformationAsContentProvider
     * @summary 探究GoGo 企業情報を更新
     * @request PUT:/content_provider/gogo/company_informations
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    putGogoCompanyInformationAsContentProvider: (data: GogoCompanyInformationRequestBody, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/company_informations`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - ContentProvider限定 Gogo::CompanyInformation を削除
     *
     * @tags ContentProvider/Gogo/CompanyInformation
     * @name DeleteGogoCompanyInformationAsContentProvider
     * @summary 探究GoGoリクエストのメッセージ を削除
     * @request DELETE:/content_provider/gogo/company_informations
     * @response `202` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    deleteGogoCompanyInformationAsContentProvider: (params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/company_informations`,
        "DELETE",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::ProgramPreferredDate を一覧を返却
     *
     * @tags ContentProvider/Gogo/ProgramPreferredDate
     * @name GetGogoProgramPreferredDatesAsContentProvider
     * @summary 探究GoGooのプログラムの募集日程一覧を返却
     * @request GET:/content_provider/gogo/program_preferred_dates
     * @response `200` `{ gogo_program_preferred_dates: (GogoProgramPreferredDateBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramPreferredDatesAsContentProvider: (
      query: GetGogoProgramPreferredDatesAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { gogo_program_preferred_dates: GogoProgramPreferredDateBase[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(`/content_provider/gogo/program_preferred_dates${this.addQueryParams(query)}`, "GET", params),

    /**
     * @description - ContentProvider限定 Gogo::ProgramRequest を一覧を返却
     *
     * @tags ContentProvider/Gogo/ProgramRequest
     * @name GetGogoProgramRequestsAsContentProvider
     * @summary 探究GoGooのプログラムリクエストリクエスト一覧を返却
     * @request GET:/content_provider/gogo/program_preferred_dates/{program_preferred_date_id}/program_requests
     * @response `200` `{ gogo_program_requests: (any)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getGogoProgramRequestsAsContentProvider: (
      { program_preferred_date_id, ...query }: GetGogoProgramRequestsAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<
        { gogo_program_requests: any[]; total_count: number },
        BadRequest | Unauthorized | Forbidden | { error?: string }
      >(
        `/content_provider/gogo/program_preferred_dates/${program_preferred_date_id}/program_requests${this.addQueryParams(
          query,
        )}`,
        "GET",
        params,
      ),

    /**
     * @description - ContentProvider限定 Gogo::ProgramRequest を登録
     *
     * @tags ContentProvider/Gogo/ProgramRequest
     * @name PostGogoProgramRequestAsContentProvider
     * @summary 探究GoGoの授業リクエストを送信
     * @request POST:/content_provider/gogo/program_preferred_dates/{program_preferred_date_id}/program_requests
     * @response `201` `{ message?: string }`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    postGogoProgramRequestAsContentProvider: (
      { program_preferred_date_id, ...query }: PostGogoProgramRequestAsContentProviderParams,
      params?: RequestParams,
    ) =>
      this.request<{ message?: string }, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/content_provider/gogo/program_preferred_dates/${program_preferred_date_id}/program_requests${this.addQueryParams(
          query,
        )}`,
        "POST",
        params,
      ),
  };
  public = {
    /**
     * @description - 一般アクセス限定 Pbl::Board::Info の一覧を返却
     *
     * @tags Public/PblBoardInfos
     * @name GetPblBoardInfosAsPublic
     * @summary 探究ボード の一覧を返却
     * @request GET:/public/pbl/board/infos/index
     * @response `200` `{ board_infos: (PblBoardInfoIndexBase)[], total_count: number }` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    getPblBoardInfosAsPublic: (query: GetPblBoardInfosAsPublicParams, params?: RequestParams) =>
      this.request<{ board_infos: PblBoardInfoIndexBase[]; total_count: number }, BadRequest | { error?: string }>(
        `/public/pbl/board/infos/index${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - 一般アクセス限定 Pbl::Board::Info の詳細を返却
     *
     * @tags Public/PblBoardInfos
     * @name GetPblBoardInfoAsPublic
     * @summary 探究ボード の詳細を返却
     * @request GET:/public/pbl/board/infos
     * @response `200` `PblBoardInfoBase` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    getPblBoardInfoAsPublic: (query: GetPblBoardInfoAsPublicParams, params?: RequestParams) =>
      this.request<PblBoardInfoBase, BadRequest | { error?: string }>(
        `/public/pbl/board/infos${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - 一般アクセス限定 Article の詳細を返却
     *
     * @tags Public/PblBoardArticles
     * @name GetPblBoardArticleAsPublic
     * @summary 探究成果 の詳細を返却
     * @request GET:/public/pbl/board/articles/{id}
     * @response `200` `ArticleBaseAsPublic` OK
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticleAsPublic: ({ id, ...query }: GetPblBoardArticleAsPublicParams, params?: RequestParams) =>
      this.request<ArticleBaseAsPublic, BadRequest | { error?: string }>(
        `/public/pbl/board/articles/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - this endpoint needs word_cloud_feature
     *
     * @tags Public/PblBoardArticles
     * @name GetPblBoardArticlesWordCloudAsPublic
     * @summary 自身が閲覧可能な探究成果から生成したワードクラウドリストを返却
     * @request GET:/public/pbl/board/articles/word_cloud
     * @response `200` `WordCloudBase` OK
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `{ error?: string }`
     */
    getPblBoardArticlesWordCloudAsPublic: (query: GetPblBoardArticlesWordCloudAsPublicParams, params?: RequestParams) =>
      this.request<WordCloudBase, BadRequest | Unauthorized | Forbidden | { error?: string }>(
        `/public/pbl/board/articles/word_cloud${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Storybook用 AttachedImage を登録
     *
     * @tags Public/AttachedImages
     * @name PostAttachedImageAsPublic
     * @summary Storybook用の画像 を登録
     * @request POST:/public/attached_images
     * @response `201` `AttachedImageBase` Created
     * @response `400` `BadRequest`
     * @response `404` `{ error?: string }`
     */
    postAttachedImageAsPublic: (
      query: PostAttachedImageAsPublicParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest | { error?: string }>(
        `/public/attached_images${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @description - Storybook用 AttachedImage の詳細を返却 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Public/AttachedImages
     * @name GetAttachedImageAsPublic
     * @summary Storybook用の画像 の詳細を返却
     * @request GET:/public/attached_images/{id}
     * @response `200` `AttachedImageBase` OK
     * @response `400` `BadRequest`
     */
    getAttachedImageAsPublic: ({ id, ...query }: GetAttachedImageAsPublicParams, params?: RequestParams) =>
      this.request<AttachedImageBase, BadRequest>(
        `/public/attached_images/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @description - Storybook用 AttachedImage を更新 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Public/AttachedImages
     * @name PutAttachedImageAsPublic
     * @summary Storybook用の画像 を更新
     * @request PUT:/public/attached_images/{id}
     * @response `202` `AttachedImageBase` Accepted
     * @response `400` `BadRequest`
     */
    putAttachedImageAsPublic: (
      { id, ...query }: PutAttachedImageAsPublicParams,
      data: FileRequiredRequestBody,
      params?: RequestParams,
    ) =>
      this.request<AttachedImageBase, BadRequest>(
        `/public/attached_images/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @description - Storybook用 AttachedImage を削除 - 該当の画像がない場合404でなく400を返す
     *
     * @tags Public/AttachedImages
     * @name DeleteAttachedImageAsPublic
     * @summary Storybook用の画像 を削除
     * @request DELETE:/public/attached_images/{id}
     * @response `200` `{ message?: string }` Accepted
     * @response `400` `BadRequest`
     */
    deleteAttachedImageAsPublic: ({ id, ...query }: DeleteAttachedImageAsPublicParams, params?: RequestParams) =>
      this.request<{ message?: string }, BadRequest>(
        `/public/attached_images/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),
  };
}
