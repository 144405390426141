/* eslint-disable camelcase */
import {
  fetchLearningTemplatePackageDistributedStudent,
  fetchLearningTemplatePackageDistributionHistories,
} from "@actions/superOwnerApp/templatePackages/learningTemplateHistory";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import {
  DistributedStudents,
  LearningTemplatePackageDistributionHistoryBase,
} from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface SuperOwnerAppLearningTemplateDistributionHistoryState {
  fetching: boolean;
  fetched: boolean;
  fetchUser: ReduxStatusType;
  fetchError: any;
  learningTemplatePackage: LearningTemplatePackageDistributionHistoryBase[];
  distributedUsers: DistributedStudents[];
  totalCount: number;
  totalCountFetched: number;
}

const initialState: SuperOwnerAppLearningTemplateDistributionHistoryState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  fetchUser: ReduxStatus.idle,
  distributedUsers: [],
  learningTemplatePackage: [],
  totalCount: 0,
  totalCountFetched: 0,
};

export const superOwnerAppLearningTemplateDistributionHistory = createSlice({
  name: "superOwner/learningTemplateHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchLearningTemplatePackageDistributionHistories.pending,
        (state, action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
          };
        },
      )
      .addCase(
        fetchLearningTemplatePackageDistributionHistories.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            fetched: true,
            learningTemplatePackage:
              payload.learning_template_package_distribution_histories,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        fetchLearningTemplatePackageDistributionHistories.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            fetchError: action.payload,
          };
        },
      )
      .addCase(
        fetchLearningTemplatePackageDistributedStudent.pending,
        (state, action) => {
          return {
            ...state,
            fetchUser: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetchLearningTemplatePackageDistributedStudent.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchUser: ReduxStatus.fulfilled,
            distributedUsers: payload.distributed_students,
            totalCountFetched: payload.total_count,
          };
        },
      )
      .addCase(
        fetchLearningTemplatePackageDistributedStudent.rejected,
        (state, action) => {
          return {
            ...state,
            fetchUser: ReduxStatus.rejected,
            fetchUserError: action.payload,
          };
        },
      );
  },
});

export const superOwnerAppLearningTemplateDistributionHistoryState = (
  state: RootState,
): SuperOwnerAppLearningTemplateDistributionHistoryState =>
  state.superOwnerApp.learningTemplateHistory;

export default superOwnerAppLearningTemplateDistributionHistory.reducer;
