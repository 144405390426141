import { useEffect } from "react";

export const useDisableNumberInputScroll = () => {
  useEffect(() => {
    // Define a function to prevent the default scroll behavior
    const handleWheel: EventListener = (e) => {
      e.preventDefault();
    };

    // Find all number input elements in the document
    const numberInputs = document.querySelectorAll('input[type="number"]');

    // Attach the handleWheel function as an event listener to each number input
    numberInputs.forEach((input) => {
      input.addEventListener("wheel", handleWheel, { passive: false });
    });

    return () => {
      numberInputs.forEach((input) => {
        input.removeEventListener("wheel", handleWheel);
      });
    };
  }, []);
};

export default useDisableNumberInputScroll;
