/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import {
  Api,
  FetchMaterialWorksAsOwnerParams,
  MaterialsTargetUsersDetailParams,
  MaterialWorkStatus,
  OwnerMaterialWork,
} from "@lib/Api";
import { get, update } from "@lib/collection";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import {
  BatchChangeMaterialWorksAsOwnerByMaterialParams,
  BatchChangeMaterialWorksAsOwnerByUserParams,
  CreateMaterialWorksAsOwnerByMaterialParams,
  CreateMaterialWorksAsOwnerByUserParams,
  CreateMaterialWorksAsOwnerParams,
  DeleteMaterialWorksAsOwnerByMaterialParams,
  DeleteMaterialWorksAsOwnerByUserParams,
  DeleteMaterialWorksAsOwnerParams,
} from "./types/materialWork";

const api = new Api();

export const resetDistributeActionData = createAction(
  "ownerApp/materialWorks/resetDistributeActionData",
);

export const deletedChangedMaterialWorksAsOwnerByUser = createAction(
  "ownerApp/materialWorks/deletedChangedByUser",
);

export interface FetchMaterialWorksAsOwnerArg {
  params: FetchMaterialWorksAsOwnerParams;
  inModal: boolean;
}

export const fetchMaterialWorksAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/materialWorks/fetch",
  async (
    { params, inModal }: FetchMaterialWorksAsOwnerArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.owner.fetchMaterialWorksAsOwner(params);
      const resData = response.data;
      return {
        material_works: resData.material_works,
        total_count: resData.total_count as number,
        inModal,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchMaterialsTargetUsersDetail = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/materialWorks/materialsTargetUsersDetail",
  async (params: MaterialsTargetUsersDetailParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.materialsTargetUsersDetail(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const handleAfterCreateMaterialWorkByMaterialAsOwner = (
  materialWorks: OwnerMaterialWork[],
  students: any[],
  updatedUserIds: string[],
  idsToRemove?: string[] | undefined,
): Promise<{ materialWorks: OwnerMaterialWork[]; students: any[] }> => {
  return new Promise((resolve) => {
    let copy = [...students];
    if (typeof idsToRemove !== "undefined") {
      copy = idsToRemove.reduce((accumulator, userId) => {
        const currentStudent = get(copy, userId);
        if (currentStudent) {
          const updatedStudent = {
            ...currentStudent,
            material_work: null,
          };
          const newStudents = update(accumulator, updatedStudent);
          return newStudents;
        }
        return accumulator;
      }, copy);
    }
    let updatedStudents = copy;
    if (updatedUserIds) {
      updatedStudents = updatedUserIds.reduce((accumulator, userId) => {
        const currentStudent = get(copy, userId);
        if (currentStudent) {
          const materialWork = materialWorks.find(
            (mw) => mw.user?.id === userId,
          );
          const updatedStudent = {
            ...currentStudent,
            material_work: materialWork,
          };
          const newStudents = update(accumulator, updatedStudent);
          return newStudents;
        }
        return accumulator;
      }, copy);
    }
    resolve({
      materialWorks,
      students: updatedStudents,
    });
  });
};

export const handleAfterCreateMaterialWorkByUserAsOwner = (
  materialWorks: OwnerMaterialWork[],
  materials: any[],
  updatedMaterialIds: string[],
  idsToRemove?: string[] | undefined,
): Promise<{ materialWorks: OwnerMaterialWork[]; materials: any[] }> => {
  return new Promise((resolve) => {
    let copy = [...materials];
    if (typeof idsToRemove !== "undefined") {
      copy = idsToRemove.reduce((accumulator, materialId) => {
        const currentMaterial = get(copy, materialId);
        if (currentMaterial) {
          const updatedMaterial = {
            ...currentMaterial,
            material_work: null,
          };
          const newMaterials = update(accumulator, updatedMaterial);
          return newMaterials;
        }
        return accumulator;
      }, copy);
    }
    let updatedMaterials = copy;
    if (updatedMaterialIds) {
      updatedMaterials = updatedMaterialIds.reduce(
        (accumulator, materialId) => {
          const currentMaterial = get(copy, materialId);
          if (currentMaterial) {
            const materialWork = materialWorks.find(
              (mw) => mw.material.id === materialId,
            );
            const updatedMaterial = {
              ...currentMaterial,
              material_work: materialWork,
            };
            const newMaterials = update(accumulator, updatedMaterial);
            return newMaterials;
          }
          return accumulator;
        },
        copy,
      );
    }
    resolve({
      materialWorks,
      materials: updatedMaterials,
    });
  });
};

export interface CreateMaterialWorksAsOwnerArg {
  params: CreateMaterialWorksAsOwnerParams;
  idsToRemove?: string[];
}

export const createMaterialWorksAsOwner = createAsyncThunk<
  any,
  CreateMaterialWorksAsOwnerArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/materialWorks/create",
  async (
    arg: CreateMaterialWorksAsOwnerArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    const { params, idsToRemove } = arg;
    try {
      const distributeParams = {
        ...params,
        status: MaterialWorkStatus.Todo,
      };
      const response = await api.owner.batchCreateMaterialWorksAsOwner(
        distributeParams,
        {},
      );
      const resData = response.data as any;
      if ("material_id" in params) {
        const { students } = getState().ownerApp.user;
        const updateResult =
          await handleAfterCreateMaterialWorkByMaterialAsOwner(
            resData.material_works,
            students,
            resData.updated_user_ids,
            idsToRemove,
          );
        return updateResult;
      }
      const { materials } = getState().ownerApp.material;
      const updateResult = await handleAfterCreateMaterialWorkByUserAsOwner(
        resData.material_works,
        materials,
        resData.updated_material_ids,
        idsToRemove,
      );
      return updateResult;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const handleAfterDeleteMaterialWorkByMaterialAsOwner = (
  deletedIds: string[],
  students: any[],
  updatedUserIds: string[],
): Promise<{ deletedMaterialWorkIds: string[]; students: any[] }> => {
  return new Promise((resolve) => {
    const copy = [...students];
    let updatedStudents = copy;
    if (updatedUserIds) {
      updatedStudents = updatedUserIds.reduce((accumulator, userId) => {
        const currentStudent = get(copy, userId);
        if (currentStudent) {
          const updatedStudent = { ...currentStudent, material_work: null };
          const newStudents = update(accumulator, updatedStudent);
          return newStudents;
        }
        return accumulator;
      }, copy);
    }
    resolve({
      deletedMaterialWorkIds: deletedIds,
      students: updatedStudents,
    });
  });
};

export const handleAfterDeleteMaterialWorkByUserAsOwner = (
  deletedIds: string[],
  students: any[],
  updatedUserIds: string[],
): Promise<{ deletedMaterialWorkIds: string[]; students: any[] }> => {
  return new Promise((resolve) => {
    const copy = [...students];
    let updatedStudents = copy;
    if (updatedUserIds) {
      updatedStudents = updatedUserIds.reduce((accumulator, userId) => {
        const currentStudent = get(copy, userId);
        if (currentStudent) {
          const updatedStudent = { ...currentStudent, material_work: null };
          const newStudents = update(accumulator, updatedStudent);
          return newStudents;
        }
        return accumulator;
      }, copy);
    }
    resolve({
      deletedMaterialWorkIds: deletedIds,
      students: updatedStudents,
    });
  });
};

export const deleteMaterialWorksAsOwner = createAsyncThunk<
  any,
  DeleteMaterialWorksAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/materialWorks/delete",
  async (
    params: DeleteMaterialWorksAsOwnerParams,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const distributeParams = {
        ...params,
        status: MaterialWorkStatus.Todo,
      };
      const response = await api.owner.batchDeleteMaterialWorksAsOwner(
        distributeParams,
        {},
      );
      const resData = response.data as any;
      if ("material_id" in params) {
        const { students } = getState().ownerApp.user;
        const updateResult =
          await handleAfterDeleteMaterialWorkByMaterialAsOwner(
            resData.deleted_material_work_ids,
            students,
            resData.updated_user_ids,
          );
        return updateResult;
      }
      const { materials } = getState().ownerApp.material;
      const updateResult = await handleAfterDeleteMaterialWorkByUserAsOwner(
        resData.deleted_material_work_ids,
        materials,
        resData.updated_material_ids,
      );
      return updateResult;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const batchChangeMaterialWorksAsOwnerByMaterial = createAsyncThunk(
  "owner/materialWorks/batchChangeByMaterial",
  async (
    params: BatchChangeMaterialWorksAsOwnerByMaterialParams,
    { dispatch },
  ) => {
    const distributeParams: CreateMaterialWorksAsOwnerByMaterialParams = {
      material_id: params.material_id,
      user_ids: params.users_to_add.map((info) => info.id),
      forbid_download_list: params.users_to_add.map(
        (info) => info.forbidDownload,
      ),
      role: params.role,
    };
    const deleteParams: DeleteMaterialWorksAsOwnerByMaterialParams = {
      material_id: params.material_id,
      user_ids: params.user_ids_to_remove,
      role: params.role,
    };
    if (distributeParams.user_ids.length > 0) {
      dispatch(
        createMaterialWorksAsOwner({
          params: distributeParams,
          idsToRemove: params.user_ids_to_remove,
        }),
      );
    }
    if (deleteParams.user_ids.length > 0) {
      dispatch(deleteMaterialWorksAsOwner(deleteParams));
    }
  },
);

export const batchChangeMaterialWorksAsOwnerByUser = createAsyncThunk(
  "owner/materialWorks/batchChangeByUser",
  async (params: BatchChangeMaterialWorksAsOwnerByUserParams, { dispatch }) => {
    const { materials_to_add, user_id, material_ids_to_remove } = params;
    const distributeParams: CreateMaterialWorksAsOwnerByUserParams = {
      user_id,
      material_ids: materials_to_add.map((info) => info.id),
      role: params.role,
      forbid_download_list: materials_to_add.map((info) => info.forbidDownload),
    };

    const deleteParams: DeleteMaterialWorksAsOwnerByUserParams = {
      user_id: params.user_id,
      material_ids: params.material_ids_to_remove,
      role: params.role,
    };
    if (distributeParams.user_id && distributeParams.material_ids.length) {
      dispatch(
        createMaterialWorksAsOwner({
          params: distributeParams,
          idsToRemove: material_ids_to_remove,
        }),
      );
    }
    if (deleteParams.user_id && deleteParams.material_ids.length) {
      dispatch(deleteMaterialWorksAsOwner(deleteParams));
    }

    if (
      distributeParams.material_ids.length === 0 &&
      deleteParams.material_ids.length
    ) {
      dispatch(deletedChangedMaterialWorksAsOwnerByUser());
    }
    return {};
  },
);
