import {
  createPblBoardContentsAsContentProvider,
  createPblBoardInfoAsContentProvider,
  createPblBoardItemAsContentProvider,
  deletePblBoardItemAsContentProvider,
  editPblBoardContentsAsContentProvider,
  fetchArticleWordCloudAsContentProvider,
  fetchPblBoardInfoAsContentProvider,
  fetPblBoardArticlesAsContentProvider,
  load,
  putPblBoardInfoAsContentProvider,
  rejected,
  resetBoardState,
  success,
} from "@actions/contentProviderApp/pblBoard/boardSettings";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus,ReduxStatusType } from "@root/constants/redux";
import {
  PblBoardArticleBase,
  PblBoardInfoBase,
  PblBoardItemContentTypeJa,
  WordCloudWord,
} from "@root/lib/Api";
import { RootState } from "@store/store";

export interface ContentProviderAppBoardSettingsState {
  fetchBoardInfos: ReduxStatusType;
  fetchBoardArticles: ReduxStatusType;
  fetchBoardWordCloud: ReduxStatusType;
  createBoardInfos: ReduxStatusType;
  createBoardContent: ReduxStatusType;
  createBoardItem: ReduxStatusType;
  boardItemType: PblBoardItemContentTypeJa | string;
  editBoardContent: ReduxStatusType;
  putBoardInfos: ReduxStatusType;
  deleteBoardItem: ReduxStatusType;
  boardInfos: PblBoardInfoBase;
  boardArticles: PblBoardArticleBase[];
  wordCloudData: WordCloudWord[];
  footerDeleted: boolean;
  footerDeleteError: boolean;
  loading: boolean;
  success: boolean;
  rejected: boolean;
  totalCount: number;
}

export const initialState: ContentProviderAppBoardSettingsState = {
  fetchBoardInfos: ReduxStatus.idle,
  fetchBoardArticles: ReduxStatus.idle,
  fetchBoardWordCloud: ReduxStatus.idle,
  createBoardInfos: ReduxStatus.idle,
  createBoardContent: ReduxStatus.idle,
  createBoardItem: ReduxStatus.idle,
  boardItemType: "",
  editBoardContent: ReduxStatus.idle,
  putBoardInfos: ReduxStatus.idle,
  deleteBoardItem: ReduxStatus.idle,
  footerDeleted: false,
  footerDeleteError: false,
  loading: false,
  success: false,
  rejected: false,
  boardInfos: {} as PblBoardInfoBase,
  boardArticles: [],
  wordCloudData: [],
  totalCount: 0,
};

const contentProviderAppBoardSettingsSlice = createSlice({
  name: "OwnerApp/Assign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblBoardInfoAsContentProvider.pending, (state, _action) => {
        return {
          ...state,
          fetchBoardInfos: ReduxStatus.pending,
        };
      })
      .addCase(fetchPblBoardInfoAsContentProvider.rejected, (state, action) => {
        return {
          ...state,
          fetchBoardInfos: ReduxStatus.rejected,
          fetchBoardInfosError: action.error,
        };
      })
      .addCase(
        fetchPblBoardInfoAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchBoardInfos: ReduxStatus.fulfilled,
            boardInfos: payload,
          };
        },
      )
      .addCase(
        fetPblBoardArticlesAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchBoardArticles: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetPblBoardArticlesAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchBoardArticles: ReduxStatus.rejected,
            fetchBoardArticlesError: action.error,
          };
        },
      )
      .addCase(
        fetPblBoardArticlesAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchBoardArticles: ReduxStatus.fulfilled,
            boardArticles: payload.articles,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        fetchArticleWordCloudAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchBoardWordCloud: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetchArticleWordCloudAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchBoardWordCloud: ReduxStatus.rejected,
            fetchBoardWordCloudError: action.error,
          };
        },
      )
      .addCase(
        fetchArticleWordCloudAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchBoardWordCloud: ReduxStatus.fulfilled,
            wordCloudData: payload.word_cloud_list,
          };
        },
      )
      .addCase(
        createPblBoardInfoAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            createBoardInfos: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        createPblBoardInfoAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            createBoardInfos: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        createPblBoardInfoAsContentProvider.fulfilled,
        (state, action) => {
          return {
            ...state,
            createBoardInfos: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        createPblBoardItemAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            createBoardItem: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        createPblBoardItemAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            createBoardItem: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        createPblBoardItemAsContentProvider.fulfilled,
        (state, action) => {
          return {
            ...state,
            createBoardItem: ReduxStatus.fulfilled,
            boardItemType: action.payload.content_type,
          };
        },
      )
      .addCase(
        createPblBoardContentsAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            createBoardContent: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        createPblBoardContentsAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            createBoardContent: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        createPblBoardContentsAsContentProvider.fulfilled,
        (state, action) => {
          return {
            ...state,
            createBoardContent: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        editPblBoardContentsAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            editBoardContent: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        editPblBoardContentsAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            editBoardContent: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        editPblBoardContentsAsContentProvider.fulfilled,
        (state, action) => {
          return {
            ...state,
            editBoardContent: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(putPblBoardInfoAsContentProvider.pending, (state, _action) => {
        return {
          ...state,
          putBoardInfos: ReduxStatus.pending,
        };
      })
      .addCase(putPblBoardInfoAsContentProvider.rejected, (state, action) => {
        return {
          ...state,
          putBoardInfos: ReduxStatus.rejected,
          putBoardInfosError: action.error,
        };
      })
      .addCase(putPblBoardInfoAsContentProvider.fulfilled, (state, action) => {
        return {
          ...state,
          putBoardInfos: ReduxStatus.fulfilled,
        };
      })
      .addCase(
        deletePblBoardItemAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            deleteBoardItem: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        deletePblBoardItemAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            deleteBoardItem: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        deletePblBoardItemAsContentProvider.fulfilled,
        (state, action) => {
          return {
            ...state,
            deleteBoardItem: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(load, (state, _action) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(success, (state, _action) => {
        return {
          ...state,
          success: true,
        };
      })
      .addCase(rejected, (state, _action) => {
        return {
          ...state,
          rejected: true,
        };
      })
      .addCase(resetBoardState, (state, _action) => {
        return {
          ...state,
          fetchBoardInfos: ReduxStatus.idle,
          fetchBoardArticles: ReduxStatus.idle,
          fetchBoardWordCloud: ReduxStatus.idle,
          fetchBoardArticlesError: null,
          fetchBoardInfosError: null,
          createBoardInfos: ReduxStatus.idle,
          createBoardItem: ReduxStatus.idle,
          createBoardContent: ReduxStatus.idle,
          boardItemType: "",
          editBoardContent: ReduxStatus.idle,
          putBoardInfos: ReduxStatus.idle,
          deleteBoardItem: ReduxStatus.idle,
          loading: false,
          success: false,
          rejected: false,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const contentProviderAppBoardSettingsState = (
  state: RootState,
): ContentProviderAppBoardSettingsState =>
  state.contentProviderApp.pblBoardSettings;

export default contentProviderAppBoardSettingsSlice.reducer;
