import { HomeworkTemplate } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import { fetchHomeworkTemplatesAsReviewer } from "../../actions/reviewerApp/homeworkTemplate";
import { RootState } from "../../store";

export interface ReviewerAppHomeworkTemplateState {
  fetching: boolean;
  totalCount: number;
  templates: HomeworkTemplate[];
}

export const initialState: ReviewerAppHomeworkTemplateState = {
  fetching: false,
  templates: [],
  totalCount: 0,
};

export const reviewerAppHomeworkTemplateSlice = createSlice({
  name: "ReviewerApp/HomeworkTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeworkTemplatesAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          templates: [],
        };
      })
      .addCase(fetchHomeworkTemplatesAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          templates: payload.templates,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchHomeworkTemplatesAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          templates: [],
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const reviewerAppHomeworkTemplateState = (
  state: RootState,
): ReviewerAppHomeworkTemplateState => state.reviewerApp.homeworkTemplate;

export default reviewerAppHomeworkTemplateSlice.reducer;
