import { FormCategoryTitle } from "@components/UI/atoms/Forms/FormComponents";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  isRequired?: boolean;
  title: string;
  annotation?: string;
  titleDataCy?: string;
  annotationDataCy?: string;
  isHideTag?: boolean;
  formAnnotation?: JSX.Element | string;
};

const useStyles = (tagColor: string) => ({
  container: (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  }),
  tagStyle: (theme: Theme) => ({
    borderRadius: "3px",
    margin: "1rem 0 0.5rem",
    padding: "1px 8px",
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: "12px",
    backgroundColor: tagColor,
  }),
  annotation: () => ({
    color: "grey",
    padding: "6px 1px",
    fontSize: "12px",
    alignSelf: "end",
  }),
});

const V2StyledFormTitle: React.FC<Props> = ({
  title,
  isRequired,
  annotation,
  titleDataCy,
  annotationDataCy,
  isHideTag,
  formAnnotation,
}) => {
  const timeTactTheme = useTheme();
  const { t } = useTranslation("common");
  let tagLabel;
  let tagColor;

  if (isRequired) {
    tagLabel = t("required");
    tagColor = timeTactTheme.palette.tertiary.lightRed;
  } else {
    tagLabel = t("optional");
    tagColor = timeTactTheme.palette.customColor.brightGreen;
  }

  const classes = useStyles(tagColor);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        justifyContent: formAnnotation ? "space-between" : "flex-start",
      }}
    >
      <Box sx={classes.container}>
        <FormCategoryTitle data-cy={titleDataCy} style={{ fontSize: 16 }}>
          {title}
        </FormCategoryTitle>
        {!isHideTag && (
          <Typography sx={classes.tagStyle}>{tagLabel}</Typography>
        )}
        {annotation && (
          <Typography
            data-cy={annotationDataCy}
            sx={classes.annotation}
            variant="caption"
          >
            {annotation}
          </Typography>
        )}
      </Box>
      {formAnnotation && <Box>{formAnnotation}</Box>}
    </Box>
  );
};

export default V2StyledFormTitle;

V2StyledFormTitle.defaultProps = {
  formAnnotation: "",
  annotation: "",
  isRequired: false,
  isHideTag: false,
};
