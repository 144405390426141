/* eslint-disable camelcase */
import {
  deleteSuperOwnerUserTagType,
  fetchSuperOwnerUserTagTypes,
  postSuperOwnerLimitedUserTagType,
  postSuperOwnerUserTagType,
  resetState,
  saveUserTagTypeSequence,
  updateSuperOwnerUserTagType,
} from "@actions/superOwnerApp/userTagType";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import ApiResponseType from "@root/types/ApiResponse";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface SuperOwnerUserTagTypesState {
  fetching: boolean;
  fetched: boolean;
  changeStatus: ReduxStatusType;
  userTagTypes: NonNullable<
    ApiResponseType["superOwner"]["createdUserTagTypesList"]["user_tag_types"]
  >;
  totalCount: number;
  error: any;
  changeError: any;
  snackbarMessage: string;
  fetchingInModal: boolean;
  fetchedInModal: boolean;
  fetchErrorInModal: string | null;
  userTagTypesInModal: NonNullable<
    ApiResponseType["superOwner"]["createdUserTagTypesList"]["user_tag_types"]
  >;
}

export const initialState: SuperOwnerUserTagTypesState = {
  fetching: false,
  fetched: false,
  changeStatus: ReduxStatus.idle,
  userTagTypes: [],
  totalCount: 0,
  error: null,
  changeError: null,
  snackbarMessage: "",
  fetchingInModal: false,
  fetchedInModal: false,
  fetchErrorInModal: null,
  userTagTypesInModal: [],
};

export const superOwnerAppUserTagTypesSlice = createSlice({
  name: "SuperOwnerApp/UserTagTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuperOwnerUserTagTypes.pending, (state, action) => {
        const { arg } = action.meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: true,
            fetchedInModal: false,
            fetchErrorInModal: null,
          };
        }
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null,
        };
      })
      .addCase(fetchSuperOwnerUserTagTypes.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: false,
            fetchedInModal: false,
            userTagTypesInModal: payload.user_tag_types,
          };
        }
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          userTagTypes: payload.user_tag_types,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchSuperOwnerUserTagTypes.rejected, (state, action) => {
        const { meta, payload } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: false,
            fetchedInModal: false,
            fetchErrorInModal: action.error.message as any,
          };
        }
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: getErrorMessage(payload as any),
        };
      })
      .addCase(postSuperOwnerUserTagType.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(postSuperOwnerUserTagType.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
          snackbarMessage: "追加しました。",
        };
      })
      .addCase(postSuperOwnerUserTagType.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(postSuperOwnerLimitedUserTagType.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(postSuperOwnerLimitedUserTagType.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
          snackbarMessage: "追加しました。",
        };
      })
      .addCase(postSuperOwnerLimitedUserTagType.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(updateSuperOwnerUserTagType.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(updateSuperOwnerUserTagType.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
          snackbarMessage: "保存しました。",
        };
      })
      .addCase(updateSuperOwnerUserTagType.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(deleteSuperOwnerUserTagType.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(deleteSuperOwnerUserTagType.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
          snackbarMessage: "削除しました。",
        };
      })
      .addCase(deleteSuperOwnerUserTagType.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(saveUserTagTypeSequence.pending, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.pending,
          changeError: null,
        };
      })
      .addCase(saveUserTagTypeSequence.fulfilled, (state) => {
        return {
          ...state,
          changeStatus: ReduxStatus.fulfilled,
          changeError: null,
          snackbarMessage: "順番が更新されました。",
        };
      })
      .addCase(saveUserTagTypeSequence.rejected, (state, action) => {
        return {
          ...state,
          changeStatus: ReduxStatus.rejected,
          changeError: action.payload as any,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: null,
          changeStatus: ReduxStatus.idle,
          changeError: null,
          snackbarMessage: "",
        };
      });
  },
});

export const superOwnerAppUserTagTypesState = (
  state: RootState,
): SuperOwnerUserTagTypesState => state.superOwnerApp.userTagTypes;

export default superOwnerAppUserTagTypesSlice.reducer;
