import { SelectedButton } from "@components/UIv2/molecules/V2Tabs";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import { HasIdAndTitle,HomeworkTemplateBaseForSuperOwner } from "@lib/Api";

import { coPackageType } from "..";

const HandleDisable = (
  content: HomeworkTemplateBaseForSuperOwner | HasIdAndTitle,
  chosenPackage: coPackageType[] | string[],
  status: ModalStatus,
  coPackage: boolean | undefined,
): boolean => {
  const chosenCoPackages = chosenPackage as coPackageType[];
  const hasPrimaryCoPackage = chosenCoPackages.some(
    (item) => item.type === SelectedButton.Secondary,
  );
  const isAdded = chosenCoPackages.some((item) => item.id === content.id);
  switch (status) {
    case ModalStatus.EDIT_ADDED_PACKAGE:
      return (
        !coPackage &&
        !!chosenPackage.length &&
        !(chosenPackage as string[]).includes(content.id)
      );
    case ModalStatus.ADD_PACKAGE:
      return coPackage ? false : hasPrimaryCoPackage && !isAdded;
    default:
      return false;
  }
};

export default HandleDisable;
