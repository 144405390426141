import { Api, StudentGrade, UserGroup } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchUserGroupsAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/userGroups/fetch", async (_arg: void, { rejectWithValue }) => {
  try {
    const response = await api.owner.fetchUserGroupsAsOwner();
    const resData = response.data;
    return {
      totalCount: resData.total_count as number,
      userGroups: resData.user_groups as UserGroup[],
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export interface CreateUserGroupAsOwnerParams {
  grade: StudentGrade;
  name: string;
}

export interface CreateUserGroupAsOwnerArg {
  params: CreateUserGroupAsOwnerParams;
}

export const createUserGroupAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/userGroups/create",
  async (arg: CreateUserGroupAsOwnerArg, { rejectWithValue }) => {
    const { params } = arg;
    try {
      const response = await api.owner.createUserGroupAsOwner({ ...params });
      const resData = response.data;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetCreateUserGroupModalState = createAction(
  "owner/userGroups/resetCreateModalState",
);
