import {
  createPblCardBookmarkCommentAsTeacher,
  deletePblCardBookmarkCommentAsTeacher,
  fetchPblCardBookmarkCommentsAsTeacher,
  resetActionState,
  resetState,
  updatePblCardBookmarkCommentAsTeacher,
} from "@actions/teacherApp/pbl/cards/information_literacy/comment";
import { PblCardCommentBase } from "@lib/Api";
import { remove,update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface TeacherAppPblCardBookmarkCommentState {
  fetching: boolean;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  changeError: string | null;
  fetchError: string | null;
  bookmarkId: string | null;
  comments: PblCardCommentBase[];
  totalCount: number;
}

export const initialState: TeacherAppPblCardBookmarkCommentState = {
  fetching: false,
  fetchError: null,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  changeError: null,
  bookmarkId: null,
  comments: [],
  totalCount: 0,
};

const teacherAppPblCardBookmarkSlice = createSlice({
  name: "TeacherApp/Pbl/Card/Bookmark",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPblCardBookmarkCommentsAsTeacher.pending,
        (state, action) => {
          return {
            ...state,
            bookmarkId: action.meta.arg.bookmark_id,
            fetching: true,
            fetchError: null,
          };
        },
      )
      .addCase(
        fetchPblCardBookmarkCommentsAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetchError: action.error.message as string,
            comments: [],
          };
        },
      )
      .addCase(
        fetchPblCardBookmarkCommentsAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            fetchError: null,
            comments: payload.pbl_card_comments,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        createPblCardBookmarkCommentAsTeacher.pending,
        (state, action) => {
          return {
            ...state,
            creating: true,
            created: false,
          };
        },
      )
      .addCase(
        createPblCardBookmarkCommentAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            creating: false,
            created: false,
          };
        },
      )
      .addCase(
        createPblCardBookmarkCommentAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            creating: false,
            created: true,
            comments: [...state.comments, payload],
          };
        },
      )
      .addCase(
        updatePblCardBookmarkCommentAsTeacher.pending,
        (state, action) => {
          return {
            ...state,
            updating: true,
            updated: false,
          };
        },
      )
      .addCase(
        updatePblCardBookmarkCommentAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: false,
          };
        },
      )
      .addCase(
        updatePblCardBookmarkCommentAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          return {
            ...state,
            updating: false,
            updated: true,
            comments: update(currentState.comments, payload),
          };
        },
      )
      .addCase(
        deletePblCardBookmarkCommentAsTeacher.pending,
        (state, action) => {
          return {
            ...state,
            deleting: true,
            deleted: false,
          };
        },
      )
      .addCase(
        deletePblCardBookmarkCommentAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            deleting: false,
            deleted: false,
          };
        },
      )
      .addCase(
        deletePblCardBookmarkCommentAsTeacher.fulfilled,
        (state, action) => {
          const currentState = current(state);
          return {
            ...state,
            deleting: false,
            deleted: true,
            comments: remove(currentState.comments, action.meta.arg),
          };
        },
      )
      .addCase(resetState, (state, action) => {
        return state;
      })
      .addCase(resetActionState, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          changeError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppPblCardBookmarkCommentState = (
  state: RootState,
): TeacherAppPblCardBookmarkCommentState =>
  state.teacherApp.pblCardBookmarkComment;

export default teacherAppPblCardBookmarkSlice.reducer;
