import {
  getSteamContentDetailsAsStudent,
  getSteamLibraryContentsAsStudent,
  resetSteamContentsState,
} from "@actions/studentApp/steam/steamContent";
import { SteamLibraryContentCard } from "@actions/types/steamLibrary";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

interface StudentAppSteamContentState {
  fetching: boolean;
  fetched: boolean;
  fetchAllContents: ReduxStatusType;
  content: SteamLibraryContentCard | null;
  fetchError: string | null;
  allContents: SteamLibraryContentCard[];
  fetchAllContentsError: string | null;
  totalCount: number;
}

const initialState: StudentAppSteamContentState = {
  fetching: false,
  fetched: false,
  fetchAllContents: ReduxStatus.idle,
  content: null,
  fetchError: null,
  allContents: [],
  fetchAllContentsError: ReduxStatus.idle,
  totalCount: 0,
};

const studentAppSteamContentSlice = createSlice({
  name: "StudentApp/SteamContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSteamLibraryContentsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchAllContents: ReduxStatus.pending,
          fetchAllContentsError: null,
        };
      })
      .addCase(getSteamLibraryContentsAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          fetchAllContents: ReduxStatus.fulfilled,
          allContents: action.payload.steam_contents,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(getSteamLibraryContentsAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          fetchAllContents: ReduxStatus.rejected,
          fetchAllContentsError: "データが取得できませんでした。",
        };
      })
      .addCase(getSteamContentDetailsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: null,
        };
      })
      .addCase(getSteamContentDetailsAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          content: action.payload,
        };
      })
      .addCase(getSteamContentDetailsAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(resetSteamContentsState, (state, action) => {
        return {
          ...state,
          fetchAllContents: ReduxStatus.idle,
          fetchAllContentsError: null,
          allContents: [],
          totalCount: 0,
        };
      });
  },
});

export const studentAppSteamContentState = (
  state: RootState,
): StudentAppSteamContentState => state.studentApp.steamContent;

export default studentAppSteamContentSlice.reducer;
