import { TableRow } from "@tiptap/extension-table-row";

const CustomTableRow = TableRow.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
      border: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-border"),
        renderHTML: (attributes) => {
          return {
            "data-border": attributes.border,
            style: `border: ${attributes.border}`,
          };
        },
      },
    };
  },
});

export default CustomTableRow;
