/* eslint-disable @typescript-eslint/ban-types */
import { fetchCommonGrades } from "@actions/grade";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import V2StyledSelect from "@components/UIv2/atoms/V2StyledSelect";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { gradeState } from "@reducers/grade";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import React, { useEffect } from "react";
import { Control, Controller } from "react-hook-form";

import { FormType } from "../../usePackageDistributionModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputSelect: {
      minWidth: 195,
    },
    noValue: {
      color: theme.palette.grey[500],
    },
  }),
);

export type GradeSelectorProps = {
  control: Control<FormType, object>;
};

const GradeSelector: React.FC<GradeSelectorProps> = ({ control }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { grades, fetchedOnce } = useAppSelector(gradeState);

  useEffect(() => {
    if (!fetchedOnce) {
      dispatch(fetchCommonGrades({}));
    }
  }, []);

  return (
    <FormSectionWrap>
      <FormCategoryTitle>学年の設定</FormCategoryTitle>
      <FormControl variant="outlined" className={classes.inputSelect}>
        <Controller
          name="grade"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <V2StyledSelect
                  selectItems={
                    grades &&
                    grades.map((grade) => {
                      return {
                        value: grade.grade,
                        label: grade.graduation_year
                          ? `${grade.grade_name} (${grade.graduation_year})`
                          : grade.grade_name,
                      };
                    })
                  }
                  selectorValue={value}
                  handleSelectorChange={(e) => onChange(e)}
                  customLabel="学年を選んでください"
                  fullWidth
                  margin="normal"
                />
                <FormHelperText error>{error?.message}</FormHelperText>
              </>
            );
          }}
        />
      </FormControl>
    </FormSectionWrap>
  );
};
export default GradeSelector;
