/* eslint-disable react/jsx-no-duplicate-props */
import makeAppInfo from "@lib/app/app-info-utils";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import {
  DatePicker,
  DatePickerProps,
  DatePickerSlotsComponentsProps,
} from "@mui/x-date-pickers";
import defaultTheme from "@root/styles/theme";
import dayjs, { Dayjs } from "dayjs";
import { merge } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

// To override the default theme otherwise the styles of Picker will be broken
const theme = createTheme({
  ...defaultTheme,
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.common.white,
        },
      },
    },
  },
  typography: {
    h4: {
      fontSize: "2rem",
    },
    h3: {
      lineHeight: 1,
      marginTop: 0,
      marginBottom: 0,
    },
  },
});

type Props = {
  value: Dayjs | string | null | undefined;
  onChange: (newValue: string) => void;
  format?: string;
  pickerFormat?: string;
} & Omit<DatePickerProps<Dayjs>, "value" | "onChange">;

const V2DatePicker: React.FC<Props> = ({
  format = "YYYY/MM/DD",
  pickerFormat = "YYYY年M月D日",
  ...props
}) => {
  const { isCypressEnv } = makeAppInfo();
  const { t, i18n } = useTranslation(["common"]);

  const pickerParams: DatePickerProps<Dayjs> = {
    ...props,
    value: props.value ? dayjs(props.value) : null, // value="" is not valid for picker, replace it with null
    onChange: (value) =>
      props.onChange(value ? value.format("YYYY/MM/DD") : ""),
  };

  const defaultSlotProps: DatePickerSlotsComponentsProps<Dayjs> = {
    toolbar: {
      toolbarFormat: i18n.language === "ja" ? pickerFormat : undefined,
    },
    field: {
      clearable: true,
      onClear: () => props.onChange(""),
      readOnly: !isCypressEnv,
    },
    textField: {
      FormHelperTextProps: {
        "data-cy": "v2-date-picker-helper-text",
      } as never,
      inputProps: {
        "data-cy": "v2-date-picker",
      },
      placeholder: t("date_time_picker_format"),
    },
    day: {
      sx: {
        "&.MuiPickersDay-root.Mui-selected": {
          backgroundColor: theme.palette.tertiary.mainDark,
        },
      },
    },
    desktopPaper: {
      sx: {
        "& .MuiPickersYear-yearButton.Mui-selected": {
          backgroundColor: theme.palette.tertiary.mainDark,
        },
        "& .MuiPickersYear-yearButton.Mui-selected:focus": {
          backgroundColor: theme.palette.tertiary.mainDark,
        },
      },
    },
  };

  let slotProps = merge(defaultSlotProps, props.slotProps);
  if (
    slotProps.field &&
    "clearable" in slotProps.field &&
    slotProps.field.clearable
  ) {
    slotProps = {
      ...slotProps,
      actionBar: { actions: ["clear", "cancel", "accept"] },
    };
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DatePicker {...pickerParams} slotProps={slotProps} format={format} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default V2DatePicker;
