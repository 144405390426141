/* eslint-disable camelcase */
import {
  SponsorImageData,
  VideoData,
} from "@components/Admin/Sponsors/modals/types";
import {
  Api,
  FetchSponsorLecturesAsAdminParams,
  FetchSponsorProjectsAsAdminParams,
  SponsorProjectAndTagsBase,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblSponsorProjectAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.admin.fetchSponsorProjectAsAdmin(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleDisplayOfProjectOnCarouselAsAdmin = createAsyncThunk<
  GetResponseType<Api["admin"]["toggleDisplayOfProjectOnCarouselAsAdmin"]>,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/id/toggle_carousel_display",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.admin.toggleDisplayOfProjectOnCarouselAsAdmin(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblSponsorProjectsAsAdmin = createAsyncThunk<
  { projects: SponsorProjectAndTagsBase[]; total_count: number },
  FetchSponsorProjectsAsAdminParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/pbl/sponsor/projects/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.fetchSponsorProjectsAsAdmin(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchLecturesByProjectAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/fetchLectures",
  async (params: FetchSponsorLecturesAsAdminParams, { rejectWithValue }) => {
    try {
      const response = await api.admin.fetchSponsorLecturesAsAdmin(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreateSponsorProjectAsAdminParams {
  sponsor_id: string;
  name: string;
  description: string;
  tags: string;
  image: SponsorImageData | null;
  video_file: VideoData | null;
  external_link: string | null;
  is_public: boolean;
}

export const createSponsorProjectAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/create",
  async (params: CreateSponsorProjectAsAdminParams, { rejectWithValue }) => {
    try {
      const {
        sponsor_id,
        name,
        description,
        tags,
        image,
        video_file,
        external_link,
        is_public,
      } = params;
      const fd = new FormData();
      fd.set("sponsor_id", sponsor_id);
      fd.set("name", name);
      fd.set("description", description);
      if (typeof tags !== "undefined" && tags.length) {
        tags.split(",").forEach((tag) => {
          fd.append(`tags[]`, tag);
        });
      }
      if (typeof image?.file !== "undefined" && image.file) {
        fd.set("image_contents", image.file);
      }
      if (video_file && video_file.file) {
        fd.set("video_file", video_file.file as File);
      }
      if (external_link) {
        fd.set("external_link", external_link as string);
      }
      fd.set("display_allowed", JSON.stringify(is_public));
      const response = await http.post(`/admin/sponsor/projects`, fd);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdateSponsorProjectAsAdminParams {
  name: string;
  description: string;
  tags: string;
  image: SponsorImageData | null;
  video_file: VideoData | null;
  external_link: string | null;
  isChanged: boolean;
  isImageChanged: boolean;
  is_public: boolean;
}

export interface UpdateSponsorProjectAsAdminArg {
  id: string;
  params: UpdateSponsorProjectAsAdminParams;
}

export const updateSponsorProjectAsAdmin = createAsyncThunk<
  any,
  UpdateSponsorProjectAsAdminArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/update",
  async (
    { id, params }: UpdateSponsorProjectAsAdminArg,
    { rejectWithValue },
  ) => {
    try {
      const {
        name,
        description,
        tags,
        image,
        video_file,
        external_link,
        isChanged,
        isImageChanged,
        is_public,
      } = params;
      const fd = new FormData();
      fd.set("name", name);
      fd.set("description", description);
      if (typeof tags !== "undefined" && tags.length) {
        tags.split(",").forEach((tag) => {
          fd.append(`tags[]`, tag);
        });
      }
      if (isImageChanged) {
        if (typeof image?.file !== "undefined" && image.file) {
          fd.set("image_contents", image.file);
        } else {
          fd.set("image_contents", "delete");
        }
      }
      if (isChanged) {
        if (video_file && video_file.file) {
          fd.set("video_file", video_file.file as File);
        } else {
          fd.set("video_file", "delete");
        }
      }
      if (external_link) {
        fd.set("external_link", external_link as string);
      } else {
        fd.set("external_link", "");
      }
      fd.set("display_allowed", JSON.stringify(is_public));
      const response = await http.put(`/admin/sponsor/projects/${id}`, fd);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSponsorProjectAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.admin.deleteSponsorProjectAsAdmin(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetAction = createAction(
  "admin/pbl/sponsor/projects/resetAction",
);
export const reset = createAction("admin/pbl/sponsor/projects/reset");
export const resetOne = createAction("admin/pbl/sponsor/projects/resetOne");
