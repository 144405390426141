import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import { ImageOperationComponent } from "./ImageOperation";

export const ImageWrapper = Node.create({
  name: "image-wrapper",
  group: "block",
  content: "SVImage",
  atom: true,

  parseHTML() {
    return [{ tag: "image-wrapper" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "image-wrapper",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(ImageOperationComponent);
  },
});

export default ImageWrapper;
