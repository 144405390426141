/* eslint-disable camelcase */

import { Api, FetchArticleCommentsAsOwnerParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticleCommentsAsOwnerArg {
  params: FetchArticleCommentsAsOwnerParams;
}

export const fetchArticleCommentsAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/articleComment/fetch",
  async (arg: FetchArticleCommentsAsOwnerArg, { rejectWithValue }) => {
    try {
      const response = await api.owner.fetchArticleCommentsAsOwner(arg.params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetCommentRelatedFormState = createAction(
  "owner/article/comment/resetFormState",
);
