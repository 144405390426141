/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/ban-types */
import { MaxChars } from "@constants/Form/input-rules";
import { IconButton, InputAdornment, TextField, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Control, Controller, UseFormHandleSubmit } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiSendPlaneFill } from "react-icons/ri";

import { FormValues } from "../..";

type Props = {
  isElementsDisabled: boolean;
  control: Control<FormValues, object>;
  onSubmit: (formValues: FormValues) => void;
  handleSubmit: UseFormHandleSubmit<FormValues>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconsStyles: {
      color: theme.palette.tertiary.darkGrey,
      "&:hover": {
        color: theme.palette.tertiary.mainDark,
      },
    },
    root: {
      backgroundColor: theme.palette.common.white,
      width: "100%",
    },
  }),
);

const Input: React.FC<Props> = ({
  isElementsDisabled,
  control,
  onSubmit,
  handleSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("university");
  return (
    <Controller
      control={control}
      name="message"
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          margin="dense"
          variant="outlined"
          placeholder={error ? error.message : t("sendMessage")}
          disabled={isElementsDisabled}
          fullWidth
          multiline
          value={value}
          data-cy="interview-chat-input"
          error={!!error}
          onChange={onChange}
          onKeyDown={(ev) => {
            if (ev.key === "Enter" && !ev.shiftKey) {
              ev.preventDefault();
              handleSubmit(onSubmit)();
            }
          }}
          InputProps={{
            className: classes.root,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="search"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isElementsDisabled}
                  data-cy="interview-chat-submit"
                  size="large"
                >
                  <RiSendPlaneFill className={classes.iconsStyles} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: MaxChars.text,
          }}
        />
      )}
      rules={{ required: t("enterAMessageToContinue") }}
    />
  );
};

export default Input;
