/* eslint-disable camelcase */
import { CustomMasterMap, CustomMasterMapRelationshipBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  deleteCustomMasterListAsSuperOwner,
  deleteCustomMasterRelationListAsSuperOwner,
  fetchCustomMasterListAsSuperOwner,
  fetchCustomMasterMapRelationListAsSuperOwner,
  postCustomMasterListAsSuperOwner,
  postCustomMasterMapRelationshipsAsSuperOwner,
  resetSearchedCompaniesState,
  resetState,
  saveCustomListSequence,
  searchCustomMasterListRecordsAsSuperOwner,
  updateCustomMasterListAsSuperOwner,
  updateCustomMasterListRelationSuperOwner,
} from "../../actions/superOwnerApp/customList";
import { RootState } from "../../store";

export interface SuperOwnerAppCustomListState {
  fetching: boolean;
  fetched: boolean;
  postedCustomListRelation: boolean;
  postingCustomListRelation: boolean;
  customListRelationError: any;
  fetchError: string | null;
  customMasterList: CustomMasterMap[];
  fetchingCustomMapRelationList: boolean;
  fetchedCustomMapRelationList: boolean;
  customMasterRelationships: CustomMasterMapRelationshipBase[];
  cmmrTotalCount: number;
  cmTotalCount: number;
  searching: boolean;
  companies: { id: string; name: string; column_value: string }[];
  companyTotalCount: number;
  changing: boolean; // for post, edit, and delete
  sorting: boolean;
  changed: boolean;
  changeError: any;
  snackbarMessage: string;
}
const initialState: SuperOwnerAppCustomListState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  postedCustomListRelation: false,
  postingCustomListRelation: false,
  customListRelationError: null,
  fetchingCustomMapRelationList: false,
  fetchedCustomMapRelationList: false,
  customMasterRelationships: [],
  customMasterList: [],
  cmmrTotalCount: 0,
  cmTotalCount: 0,
  searching: false,
  companies: [],
  companyTotalCount: 0,
  changing: false,
  sorting: false,
  changed: false,
  changeError: null,
  snackbarMessage: "",
};

const superOwnerAppCustomListSlice = createSlice({
  name: "SuperOwnerApp/CustomList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomMasterListAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: null,
        };
      })
      .addCase(fetchCustomMasterListAsSuperOwner.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
          customMasterList: [],
        };
      })
      .addCase(fetchCustomMasterListAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          customMasterList: action.payload.custom_master_maps,
          cmTotalCount: action.payload.total_count,
        };
      })
      .addCase(
        fetchCustomMasterMapRelationListAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingCustomMapRelationList: true,
            fetchedCustomMapRelationList: false,
            fetchError: null,
          };
        },
      )
      .addCase(
        fetchCustomMasterMapRelationListAsSuperOwner.rejected,
        (state, _action) => {
          return {
            ...state,
            fetchingCustomMapRelationList: false,
            fetchedCustomMapRelationList: false,
            fetchError: "データが取得できませんでした。",
            customMasterRelationships: [],
          };
        },
      )
      .addCase(
        fetchCustomMasterMapRelationListAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetchingCustomMapRelationList: false,
            fetchedCustomMapRelationList: true,
            customMasterRelationships: action.payload.relationships,
            cmmrTotalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        searchCustomMasterListRecordsAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            searching: true,
            fetchError: null,
          };
        },
      )
      .addCase(
        searchCustomMasterListRecordsAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            searching: false,
            fetchError: "データが取得できませんでした。",
            companies: [],
          };
        },
      )
      .addCase(
        searchCustomMasterListRecordsAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            searching: false,
            companies: action.payload.linking_companies,
            companyTotalCount: action.payload.total_count,
          };
        },
      )
      .addCase(postCustomMasterListAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(postCustomMasterListAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changeError: action.payload?.errors || ["エラーが発生しました。"],
        };
      })
      .addCase(postCustomMasterListAsSuperOwner.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          snackbarMessage: "カスタムリストを追加しました。",
        };
      })
      .addCase(
        postCustomMasterMapRelationshipsAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            postingCustomListRelation: true,
            postedCustomListRelation: false,
            customListRelationError: null,
          };
        },
      )
      .addCase(
        postCustomMasterMapRelationshipsAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            postedCustomListRelation: false,
            postingCustomListRelation: false,
            customListRelationError: action.payload?.errors || [
              "エラーが発生しました。",
            ],
          };
        },
      )
      .addCase(
        postCustomMasterMapRelationshipsAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            postingCustomListRelation: false,
            postedCustomListRelation: true,
            customListRelationError: null,
          };
        },
      )
      .addCase(updateCustomMasterListAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(updateCustomMasterListAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changeError: action.payload?.errors || ["エラーが発生しました。"],
        };
      })
      .addCase(
        updateCustomMasterListAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            snackbarMessage: "カスタムリストを編集しました。",
          };
        },
      )
      .addCase(
        updateCustomMasterListRelationSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            changing: true,
            changed: false,
            changeError: null,
          };
        },
      )
      .addCase(
        updateCustomMasterListRelationSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changeError: action.payload?.errors || ["エラーが発生しました。"],
          };
        },
      )
      .addCase(
        updateCustomMasterListRelationSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            snackbarMessage: "リレーションを編集しました。",
          };
        },
      )
      .addCase(deleteCustomMasterListAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(
        deleteCustomMasterListAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            snackbarMessage: "カスタムリストを削除しました。",
          };
        },
      )
      .addCase(deleteCustomMasterListAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changeError: action.payload?.message || "エラーが発生しました。",
        };
      })
      .addCase(
        deleteCustomMasterRelationListAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            changing: true,
            changed: false,
            changeError: null,
          };
        },
      )
      .addCase(
        deleteCustomMasterRelationListAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            snackbarMessage: "リレーションを削除しました。",
          };
        },
      )
      .addCase(
        deleteCustomMasterRelationListAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changeError: action.payload?.message || "エラーが発生しました。",
          };
        },
      )
      .addCase(saveCustomListSequence.pending, (state, _action) => {
        return {
          ...state,
          sorting: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(saveCustomListSequence.fulfilled, (state, _action) => {
        return {
          ...state,
          sorting: false,
          changed: true,
          snackbarMessage: "順番が更新されました。",
        };
      })
      .addCase(saveCustomListSequence.rejected, (state, action) => {
        return {
          ...state,
          sorting: false,
          changeError: action.payload?.message || "エラーが発生しました。",
        };
      })
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: null,
          postedCustomListRelation: false,
          postingCustomListRelation: false,
          customListRelationError: null,
          snackbarMessage: "",
        };
      })
      .addCase(resetSearchedCompaniesState, (state, _action) => {
        return {
          ...state,
          companies: [],
          companyTotalCount: 0,
        };
      });
  },
});

export const superOwnerAppCustomListState = (
  state: RootState,
): SuperOwnerAppCustomListState => state.superOwnerApp.customList;

export default superOwnerAppCustomListSlice.reducer;
