/* eslint-disable camelcase */
import { PostNotificationAsSuperOwnerParams } from "@actions/types/notification";
import {
  Api,
  GetLimitedTimeNotificationsAsSuperOwnerParams,
  NotificationsListParams,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchNotificationsSentAsSuperOwner = createAsyncThunk<
  any,
  NotificationsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/notificationsSent/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.notificationsList({
      ...params,
      sent: "sent",
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const fetchLimitedTimeNotificationsAsSuperOwner = createAsyncThunk<
  any,
  GetLimitedTimeNotificationsAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/limitedTimeNotifications/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.getLimitedTimeNotificationsAsSuperOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const postNotificationAsSuperOwner = createAsyncThunk<
  any,
  PostNotificationAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/notifications/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.notificationsCreate(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const putNotificationAsSuperOwner = createAsyncThunk<
  any,
  {
    notifyParams: PostNotificationAsSuperOwnerParams;
    notifId: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/notifications/put", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.putSuperOwnerNotificationId(
      params.notifId,
      params.notifyParams,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deleteNotificationAsSuperOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/notifications/delete",
  async (notificationId, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.deleteSuperOwnerNotificationId(notificationId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

type PostLimitedTimeNotificationParams = {
  title: string;
  message: string;
  start_time: string;
  end_time: string;
  add_roles: string[];
  add_company_ids?: string[];
  add_user_tag_ids?: string[];
  remove_role_ids?: string[];
  remove_company_ids?: string[];
  remove_user_tag_ids?: string[];
};

export const postLimitedTimeNotificationAsSuperOwner = createAsyncThunk<
  any,
  PostLimitedTimeNotificationParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/limitedTimeNotifications/post",
  async (data, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.set("title", data.title);
      fd.set("message", data.message);
      fd.set("start_time", data.start_time);
      fd.set("end_time", data.end_time);

      data.add_roles.forEach((role) => {
        fd.append("add_roles[]", role);
      });

      if (typeof data.add_company_ids !== "undefined") {
        data.add_company_ids.forEach((companyId) => {
          fd.append("add_company_ids[]", companyId);
        });
      }

      if (typeof data.add_user_tag_ids !== "undefined") {
        data.add_user_tag_ids.forEach((tagId) => {
          fd.append("add_user_tag_ids[]", tagId);
        });
      }
      const response = await http.post(
        "/super_owner/limited_time_notifications",
        fd,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

type PutLimitedTimeNotificationParams = {
  id: string;
  data: PostLimitedTimeNotificationParams;
};

export const putLimitedTimeNotificationAsSuperOwner = createAsyncThunk<
  any,
  PutLimitedTimeNotificationParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/limitedTimeNotifications/put",
  async (params, { rejectWithValue }) => {
    const { id, data } = params;
    try {
      const fd = new FormData();
      fd.set("title", data.title);
      fd.set("message", data.message);
      fd.set("start_time", data.start_time);
      fd.set("end_time", data.end_time);

      data.add_roles.forEach((role) => {
        fd.append("add_roles[]", role);
      });

      if (typeof data.add_company_ids !== "undefined") {
        data.add_company_ids.forEach((companyId) => {
          fd.append("add_company_ids[]", companyId);
        });
      }

      if (typeof data.add_user_tag_ids !== "undefined") {
        data.add_user_tag_ids.forEach((tagId) => {
          fd.append("add_user_tag_ids[]", tagId);
        });
      }

      if (typeof data.remove_role_ids !== "undefined") {
        data.remove_role_ids.forEach((roleId) => {
          fd.append("remove_role_ids[]", roleId);
        });
      }
      if (typeof data.remove_company_ids !== "undefined") {
        data.remove_company_ids.forEach((companyId) => {
          fd.append("remove_company_ids[]", companyId);
        });
      }
      if (typeof data.remove_user_tag_ids !== "undefined") {
        data.remove_user_tag_ids.forEach((userId) => {
          fd.append("remove_user_tag_ids[]", userId);
        });
      }
      const response = await http.put(
        `/super_owner/limited_time_notifications/${id}`,
        fd,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const deleteLimitedTimeNotification = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/limitedNotification/delete",
  async (notificationId, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.deleteLimitedTimeNotificationAsSuperOwner(
          notificationId,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const resetFormState = createAction(
  "superOwner/notifications/resetState",
);
