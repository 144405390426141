/* eslint-disable camelcase */
import {
  AccountUserInfo,
  Api,
  FetchOwnersAsOwnerParams,
  FetchStudentsAsOwnerParams,
  FetchTeachersAsOwnerParams,
  UserType,
} from "@lib/Api";
import { http } from "@lib/http";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { CreateUserParamType, UpdateUserParamType } from "./types/user";

const api = new Api();
const perPage = 10;

export type FetchUsersAsOwnerQueryParams =
  | FetchOwnersAsOwnerParams
  | FetchStudentsAsOwnerParams
  | FetchTeachersAsOwnerParams;

interface FetchUsersAsOwnerArg {
  params: FetchUsersAsOwnerQueryParams;
  userType: UserType;
}

export const fetchStudentsAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/users/fetchStudents",
  async (params: FetchStudentsAsOwnerParams, { rejectWithValue }) => {
    try {
      let fetchParams: FetchStudentsAsOwnerParams = params;
      if (params.material_id) {
        fetchParams = {
          ...fetchParams,
          material_id: params.material_id,
          with_material_work: true,
        };
      }
      const response = await api.owner.fetchStudentsAsOwner(fetchParams);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const fetchUsersAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/users/fetch",
  async (arg: FetchUsersAsOwnerArg, { rejectWithValue }) => {
    const { params, userType } = arg;
    let response;
    let users;
    let student_input_settings;
    let students;

    try {
      switch (userType) {
        case "owner":
          response = await api.owner.fetchOwnersAsOwner(params);
          users = response.data.owners;
          break;
        case "teacher":
          response = await api.owner.fetchTeachersAsOwner(params);
          users = response.data.teachers;
          break;
        default:
          response = await api.owner.fetchStudentsAsOwner(params);
          student_input_settings = response.data.student_input_settings;
          students = response.data.students;
      }
      const resData = response.data;

      return {
        userType,
        total_count: resData.total_count,
        users,
        student_input_settings,
        students,
      };
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export interface DeleteUserAsOwnerArg {
  userId: string;
  userType: UserType;
  currentPage: number;
  batch?: boolean;
}

// TODO: FetchUsersAsOwnerを別で呼び出すのはイケてないのでクライアント側のキャッシュを更新するようにしたい。
export const deleteUserAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/users/delete",
  async (arg: DeleteUserAsOwnerArg, { rejectWithValue, dispatch }) => {
    const { userId, userType, currentPage, batch } = arg;
    try {
      const response = await api.owner.deleteOwnerUsersUserId(userId, {
        role: userType,
      });
      const resData = response.data;
      if (!batch) {
        dispatch(
          fetchUsersAsOwner({
            userType,
            params: { page: currentPage, perPage },
          }),
        );
      }
      return {
        message: resData.message ? resData.message : "",
      };
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const fetchUserDetailAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/users/fetchOne",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.owner.getOwnerUsersUserId(userId);
      const resData = response.data;
      return resData;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export interface CreateUserAsOwnerArg {
  params: CreateUserParamType;
  handlePageTransition: (page: number) => void;
}

type ProfessorAttributes =
  | {
      position?: string;
      department?: string;
      description?: string;
      limit_request?: number;
      profile_picture?: File | string;
    }
  | undefined;

type RequiredUserParams = {
  firstName: string;
  lastName: string;
  optional_email?: string;
  email?: string;
  role?: string;
};

const getProfessorParams = (
  professorInfoAttributes: ProfessorAttributes,
  otherParams: RequiredUserParams,
) => {
  const fd = new FormData();
  if (professorInfoAttributes) {
    const {
      position,
      department,
      limit_request,
      description,
      profile_picture,
    } = professorInfoAttributes;

    const { firstName, lastName, optional_email, email, role } = otherParams;
    if (email) {
      fd.set("email", email);
    }
    if (lastName) {
      fd.set("last_name", lastName);
    }
    if (firstName) {
      fd.set("first_name", firstName);
    }
    if (optional_email) {
      fd.set("optional_email", optional_email);
    }
    if (role) {
      fd.set("role", role);
    }
    if (position) {
      fd.set("professor_info_attributes[position]", position);
    }
    if (department) {
      fd.set("professor_info_attributes[department]", department);
    }
    if (limit_request) {
      fd.set(
        "professor_info_attributes[limit_request]",
        limit_request.toString(),
      );
    }
    if (description) {
      fd.set("professor_info_attributes[description]", description);
    }
    if (profile_picture) {
      fd.append("professor_info_attributes[profile_picture]", profile_picture);
    }
  }
  return fd;
};

export const createUserAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/users/create",
  async (
    { params, handlePageTransition }: CreateUserAsOwnerArg,
    { rejectWithValue },
  ) => {
    const {
      professor_info_attributes,
      email,
      last_name,
      first_name,
      optional_email,
      role,
    } = params;
    let response;
    try {
      if (professor_info_attributes && first_name && last_name) {
        response = await http.post(
          "/owner/users",
          getProfessorParams(professor_info_attributes, {
            firstName: first_name,
            lastName: last_name,
            optional_email,
            email,
            role,
          }),
        );
      } else {
        response = await api.owner.postOwnerUsers(params);
      }

      if (response.status >= 200 && response.status < 300) {
        handlePageTransition(1);
      }
      const resData = response.data;
      return {
        id: resData.id,
      };
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export interface UpdateUserAsOwnerArg {
  id: string;
  userType: UserType;
  params: UpdateUserParamType;
}

export const updateUserAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/users/update",
  async (
    { id, params }: UpdateUserAsOwnerArg,
    { rejectWithValue, dispatch },
  ) => {
    let response;
    const {
      professor_info_attributes,
      last_name,
      first_name,
      optional_email,
      email,
    } = params;
    try {
      if (professor_info_attributes && first_name && last_name) {
        response = await http.put(
          `/owner/users/${id}`,
          getProfessorParams(professor_info_attributes, {
            firstName: first_name,
            lastName: last_name,
            optional_email,
            email,
          }),
        );
      } else {
        response = await api.owner.putOwnerUsersUserId(id, params);
      }
      const resData = response.data;
      return {
        id: resData.id,
      };
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const fetchAssignedMaterialWorksAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/users/fetchAssignedMaterialWorks",
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await api.owner.getOwnerUsersUserIdMaterialWorks(userId);
      const resData = response.data;
      return resData;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const changeCsvImportState = createAction(
  "ownerApp/users/changeCsvImportState",
);
export const resetCsvImportState = createAction(
  "ownerApp/users/resetCsvImportState",
);

export type UserParamsOnCsvImport = {
  email?: string;
  user_type?: UserType;
  first_name?: string;
  last_name?: string;
  optional_email?: string;
  birth_date?: string;
  grade?: number;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  number?: number;
  user_tag_ids?: string[];
  group_ids?: string[];
};

export interface BatchCreateUsersAsOwnerArg {
  slice_start: number;
  user_params_list: UserParamsOnCsvImport[];
  invitation_scheduled_at?: string;
}

export const batchCreateUsersAsOwner = createAsyncThunk<
  any,
  BatchCreateUsersAsOwnerArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/users/batchCreate", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.batchCreateUsersAsOwner(params);
    const resData = response.data;
    return resData;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

type ResetPasswordAsOwnerParams = {
  id: string;
  data: {
    password: string;
    affiliate_id?: string;
  };
};

export const resetPasswordAsOwner = createAsyncThunk<
  AccountUserInfo,
  ResetPasswordAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/companies/user/reset_password",
  async (params, { rejectWithValue }) => {
    try {
      const { id, data } = params;
      const response = await api.owner.resetPasswordAsOwner(id, data);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        error: err.response.data,
      });
    }
  },
);

export const resetState = createAction("ownerApp/users/resetState");
export const resetUserError = createAction("ownerApp/users/resetUserError");
export const resetCreateOrEditModalState = createAction(
  "ownerApp/users/resetCreateOrEditModalState",
);
export const resetDeleteState = createAction("ownerApp/users/resetDeleteState");
