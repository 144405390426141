/* eslint-disable camelcase */
import { postLearningTemplatePackageDistributions } from "@actions/superOwnerApp/templatePackages/learningTemplateDistribution";
import { DistributionData } from "@actions/superOwnerApp/templatePackages/types";
import { DistributionEnum } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import { ContentType } from "@containers/SuperOwner/LearningTemplatePackages";
import {
  ModalStateParams,
  ModalStatus,
} from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import {
  Company,
  LearningTemplatePackageBase,
  PackagingPackageableType,
  StudentGrade,
  UserTag,
} from "@lib/Api";
import { Alert, Box } from "@mui/material";
import { useAppDispatch } from "@root/hooks";
import _ from "lodash";
import React from "react";

import StepFour from "./StepFour";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import usePackageDistributionModal, {
  AttachedTemplatesWith,
  FormType,
  StepType,
  TemplatesSettings,
} from "./usePackageDistributionModal";

interface Props {
  modalState: ModalStateParams;
  posting: boolean;
  handleClose: () => void;
  distributionType: DistributionEnum;
  distributed: boolean;
  tabClickHandler: (value: string) => void;
}

const LearningTemplateDistributionModal: React.FC<Props> = ({
  handleClose,
  modalState,
  distributionType,
  posting,
  distributed,
  tabClickHandler,
}) => {
  const dispatch = useAppDispatch();

  const {
    useFetchPackages,
    defaultValues,
    handleSchoolWithAttachedTemplates,
    methods,
    step,
    setStep,
    setErrorMessage,
  } = usePackageDistributionModal(distributed);

  const {
    handleSubmit: handleSubmit1,
    control: control1,
    setValue: setValue1,
    watch: watch1,
    reset: reset1,
    getValues: getValues1,
    formState: { errors },
  } = methods;

  const { isOpen, status, learningTemplate } = modalState;
  const isModalOpen = isOpen && status === ModalStatus.DISTRIBUTION;
  const { packagingsList, isPackagingsFetching } = useFetchPackages(
    learningTemplate?.id as string,
    isModalOpen,
  );

  const attachedHomeWorkTemplate = packagingsList.filter(
    (p) => p.packageable_type === PackagingPackageableType.HomeworkTemplate,
  );
  const hasAttachedTemplates = attachedHomeWorkTemplate.length > 0;
  const studentIds = watch1("studentIds");
  const schoolsValues = watch1("schools");
  const schoolWithAttachedTemplates = handleSchoolWithAttachedTemplates(
    schoolsValues,
    attachedHomeWorkTemplate,
  );

  const isContainingPackage = () => {
    if (!learningTemplate) {
      return null;
    }
    return learningTemplate.packagings_count > 0;
  };

  const handleDistributionParamsIds = (
    schools: Company[],
    tagIds: UserTag[],
  ) => {
    if (
      distributionType === DistributionEnum.BY_USER_TAG ||
      distributionType === DistributionEnum.BY_LIMITED_USER_TAG
    ) {
      return {
        user_tag_ids: tagIds.map((item: UserTag) => item.id),
        student_affiliate_ids: studentIds,
        company_ids: [],
      };
    }
    return {
      company_ids: schools.map((item: Company) => item.id),
      student_affiliate_ids: studentIds,
      user_tag_ids: [],
    };
  };

  const formatTemplateForApi = (template: TemplatesSettings) => ({
    homework_template_id: template.homework_template_id,
    company_id: template.company_id,
    start_at: template.start_at,
    end_at: template.end_at,
    reviewer_end_at: template.reviewer_end_at,
    memo: template.memo,
  });

  function hasEmptyStartAtInTemplate(
    attachedTemplates: AttachedTemplatesWith[],
  ) {
    return _.some(attachedTemplates, (school) =>
      _.some(school.templates, { start_at: "" }),
    );
  }

  const handleAttachedTemplatesData = (
    schoolsWithAttachedTemplates: AttachedTemplatesWith[],
  ) => {
    return _.flatMap(schoolsWithAttachedTemplates, (school) => {
      return school.templates.map(formatTemplateForApi);
    });
  };

  const distributePackage = async (packageData: DistributionData) => {
    await dispatch(
      postLearningTemplatePackageDistributions({
        learning_template_package_id: learningTemplate?.id as string,
        data: packageData,
      }),
    );
  };

  const onSubmit = async (values: FormType) => {
    const {
      startDate,
      endDate,
      grade,
      schools,
      tagIds,
      schoolsWithAttachedTemplates,
    } = values;
    const commonData = {
      start_at: startDate || undefined,
      end_at: endDate || undefined,
      grade: (grade as StudentGrade) || undefined,
      // forbid_download functionality is not handle
      // and have no purpose yet in the app (fixing type error)
      forbid_download: true,
    };
    const tagOrShoolIds = handleDistributionParamsIds(schools, tagIds);
    const attachedTemplates = handleAttachedTemplatesData(
      schoolsWithAttachedTemplates,
    );

    if (!schools.length) {
      return setErrorMessage("学校の設定が必須です。");
    }
    await distributePackage({
      ...commonData,
      ...tagOrShoolIds,
      homework_template_settings: attachedTemplates,
    });
    return tabClickHandler(ContentType.PACKAGE_HISTORY);
  };

  const nextStep = () => {
    const { startDate, endDate, schoolsWithAttachedTemplates } = getValues1();
    if (!startDate && endDate) {
      return setErrorMessage("開始日時を設定してください。");
    }
    if (step === 2 && !studentIds.length) {
      return setErrorMessage("生徒が選択されていません。");
    }
    if (step === 2) {
      setValue1("schoolsWithAttachedTemplates", schoolWithAttachedTemplates);
    }
    if (step === 3 && hasAttachedTemplates) {
      if (hasEmptyStartAtInTemplate(schoolsWithAttachedTemplates)) {
        return setErrorMessage("必須項目が入力されていません。");
      }
    }
    setErrorMessage("");
    return setStep((prevStep) => (prevStep + 1) as StepType);
  };

  const backStep = () => {
    if ([2, 3, 4].includes(step)) {
      return setStep((prevStep) => (prevStep - 1) as StepType);
    }
    return null;
  };

  const modalCloser = () => {
    handleClose();
    reset1(defaultValues);
    setStep(1);
  };

  const handleModalTitlesAndActions = (modalStep: number) => {
    let primaryButtonTitle = "次へ";
    let primaryAction = handleSubmit1(nextStep);
    let secondaryAction = () => modalCloser();
    let secondaryButtonTitle = "キャンセル";
    let modalTitle;
    if (modalStep === 1) {
      if (distributionType === DistributionEnum.BY_SCHOOL) {
        modalTitle = "学校で配信する";
      } else if (
        distributionType === DistributionEnum.BY_USER_TAG ||
        distributionType === DistributionEnum.BY_LIMITED_USER_TAG
      ) {
        modalTitle = "配信の設定";
      }
    } else if (modalStep === 2) {
      modalTitle = "生徒の選択";
      secondaryButtonTitle = "配信の設定に戻る";
      secondaryAction = () => backStep();
      primaryAction = handleSubmit1(nextStep);
    } else if (modalStep === 3) {
      modalTitle = "提出物の設定";
      secondaryButtonTitle = "生徒の選択に戻る";
      secondaryAction = () => backStep();
      primaryAction = handleSubmit1(nextStep);
    } else if (modalStep === 4) {
      modalTitle = "配信の確認";
      primaryButtonTitle = "配信する";
      secondaryButtonTitle = "生徒の選択に戻る";
      secondaryAction = () => backStep();
      primaryAction = handleSubmit1(onSubmit);
    }
    return {
      modalTitle,
      primaryButtonTitle,
      secondaryButtonTitle,
      primaryAction,
      secondaryAction,
    };
  };

  const handleModalBody = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            control={control1}
            setValue={setValue1}
            watch={watch1}
            getValues={getValues1}
            distributionType={distributionType}
            errors={errors}
            reset={reset1}
          />
        );
      case 2:
        return (
          <StepTwo
            distributionType={distributionType}
            learningTemplate={learningTemplate as LearningTemplatePackageBase}
            control={control1}
            setValue={setValue1}
            getValues={getValues1}
            watch={watch1}
            reset={reset1}
          />
        );
      case 3:
        return (
          <StepThree
            distributionType={distributionType}
            watch={watch1}
            control={control1}
            setValue={setValue1}
            hasAttachedTemplates={hasAttachedTemplates}
          />
        );
      case 4:
        return (
          <StepFour
            distributionType={distributionType}
            getValues={getValues1}
            watch={watch1}
          />
        );
      default:
        return null;
    }
  };

  const modalBody = handleModalBody();

  const {
    modalTitle,
    primaryButtonTitle,
    secondaryButtonTitle,
    secondaryAction,
    primaryAction,
  } = handleModalTitlesAndActions(step);

  return (
    <GenericModal
      useActionButtons
      useSecondaryButton
      title={modalTitle}
      modalSize={step === 3 && hasAttachedTemplates ? "lg" : "md"}
      handleClose={modalCloser}
      secondaryButtonAction={secondaryAction}
      primaryButtonAction={primaryAction}
      primaryButtonText={primaryButtonTitle}
      secondaryButtonText={secondaryButtonTitle}
      open={isModalOpen}
      posting={posting || isPackagingsFetching}
      primaryLock={!isContainingPackage() || isPackagingsFetching}
    >
      {isPackagingsFetching ? (
        <CircularLoading />
      ) : (
        <>
          {isContainingPackage() ? (
            modalBody
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                height: "100px",
                width: "100%",
              }}
            >
              <Alert
                severity="warning"
                style={{
                  width: "100%",
                }}
              >
                配信するためにはコンテンツを追加してください。
              </Alert>
            </Box>
          )}
        </>
      )}
    </GenericModal>
  );
};

export default LearningTemplateDistributionModal;
