import { ListItemIcon, ListItemText, MenuItem, Tooltip } from "@mui/material";
import { Editor } from "@tiptap/react";
import React, { memo } from "react";
import { AiOutlineTable } from "react-icons/ai";

import { useDOMParse } from "../useDOMParse";
import useStyles from "./styles";

type Props = {
  editor: Editor;
  handleClose: () => void;
};

const TableMenuItem: React.FC<Props> = ({ editor, handleClose }) => {
  const plusButtonMenuStyles = useStyles();
  const { canInsertTable } = useDOMParse();
  const isDisabled = !canInsertTable(editor);

  const handleItemClick = () => {
    if (isDisabled) return;
    const insertPosition = editor.state.selection.$head.pos;
    editor
      .chain()
      .focus(insertPosition ?? false)
      .insertTable({
        rows: 3,
        cols: 2,
        withHeaderRow: false,
      })
      .run();
    handleClose();
  };

  return (
    <Tooltip
      title={canInsertTable(editor) ? "" : "表の挿入は１ブロック１つまでです。"}
      placement="top"
    >
      <MenuItem
        onClick={handleItemClick}
        classes={{ root: plusButtonMenuStyles.MenuItem }}
      >
        <ListItemIcon className={plusButtonMenuStyles.ListItemIconStyle}>
          <AiOutlineTable size={16} />
        </ListItemIcon>
        <ListItemText primary="テーブル" style={{ fontSize: 16 }} />
      </MenuItem>
    </Tooltip>
  );
};

export default memo(TableMenuItem);
