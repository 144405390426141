import { PER_PAGE_LIMIT } from "@constants/page";
import { SchoolSearchType } from "@containers/SuperOwner/SuperOwnerSchoolsContainer/pageStore/types";
import { GroupAffiliatePackageGroupOnlyList } from "@root/endpoints/TimeTactApi/teacherApi";

import {
  AffiliateStatus,
  AffiliateStatusJa,
  ArticleKind,
  ArticleStatus,
  PblCardBookmarkSourceType,
  SortedByMaterialWork,
  UserType,
} from "./Api";

export const getValidStringParam = (
  value: string | null | undefined,
): string | undefined => {
  if (typeof value === "string" && !!value.trim()) {
    return value;
  }
  return undefined;
};

export const getValidPageNumber = (
  pageParam: string | number | undefined,
): number => {
  if (pageParam && !Number.isNaN(pageParam)) {
    const pageNumber = Number(pageParam);
    if (pageNumber >= 1) {
      return Math.floor(pageNumber);
    }
  }
  return 1;
};

export const getValidPerPageNumber = (
  perPageParam: string | number | undefined,
  defaultPerPage: number,
): number => {
  if (perPageParam && !Number.isNaN(perPageParam)) {
    const perPageNumber = Number(perPageParam);
    if (perPageNumber > 0 && perPageNumber <= PER_PAGE_LIMIT) {
      return Math.floor(perPageNumber);
    }
  }
  return defaultPerPage;
};

export const getValidUserType = (
  typeParam: string | undefined,
  defaultUserType: UserType,
): UserType => {
  switch (typeParam) {
    case UserType.Reviewer:
    case UserType.Owner:
    case UserType.Teacher:
    case UserType.Student:
      return typeParam;
    default:
      return defaultUserType;
  }
};

export const getValidSchoolSearchType = (
  searchType: string | undefined,
): SchoolSearchType => {
  switch (searchType) {
    case SchoolSearchType.Name:
    case SchoolSearchType.Tag:
      return searchType;
    default:
      return SchoolSearchType.Name;
  }
};

export const getValidGroup = (
  groupParam: string,
  groups: GroupAffiliatePackageGroupOnlyList[],
): GroupAffiliatePackageGroupOnlyList | undefined =>
  groups.find((group) => group.id === groupParam);

type RecordMapStatus = Record<string, ArticleStatus | undefined>;
type RecordMapKind = Record<string, ArticleKind> | undefined;

const validStatusMap: RecordMapStatus = {
  [ArticleStatus.Draft]: ArticleStatus.Draft,
  [ArticleStatus.Correction]: ArticleStatus.Correction,
  [ArticleStatus.Reviewing]: ArticleStatus.Reviewing,
  [ArticleStatus.Published]: ArticleStatus.Published,
  [ArticleStatus.TeacherChecking]: ArticleStatus.TeacherChecking,
};

export const getValidStatus = (
  statusType: ArticleStatus,
): ArticleStatus | undefined => {
  return validStatusMap[statusType] || undefined;
};

const validKindMap: RecordMapKind = {
  [ArticleKind.Default]: ArticleKind.Default,
  [ArticleKind.Poster]: ArticleKind.Poster,
};
export const getValidArticleKind = (
  articleKind: ArticleKind,
): ArticleKind | undefined => {
  return validKindMap[articleKind] || undefined;
};

export const getValidAffiliateStatusArray = (
  status: string,
): AffiliateStatusJa[] | undefined => {
  switch (status) {
    case AffiliateStatusJa.enable:
    case AffiliateStatusJa.expired:
    case AffiliateStatusJa.locked:
    case AffiliateStatusJa.scheduling:
    case AffiliateStatusJa.waiting:
    case AffiliateStatusJa.unapproved:
    case AffiliateStatusJa.suspension_of_use:
      return [status];
    default:
      return undefined;
  }
};

export const getValidAffiliateStatus = (
  status: string | undefined,
): AffiliateStatus | undefined => {
  switch (status) {
    case AffiliateStatus.Enable:
    case AffiliateStatus.Expired:
    case AffiliateStatus.Locked:
    case AffiliateStatus.Scheduling:
    case AffiliateStatus.Waiting:
    case AffiliateStatus.Unapproved:
    case AffiliateStatus.SuspensionOfUse:
      return status;
    default:
      return undefined;
  }
};

export const getValidBookmarkSourceType = (
  sourceType: string | undefined,
): PblCardBookmarkSourceType | undefined => {
  switch (sourceType) {
    case PblCardBookmarkSourceType.Book:
    case PblCardBookmarkSourceType.Internet:
    case PblCardBookmarkSourceType.Newspaper:
    case PblCardBookmarkSourceType.Other:
      return sourceType;
    default:
      return undefined;
  }
};

export const getValidMaterialSortedOrder = (
  order: string,
): SortedByMaterialWork | undefined => {
  switch (order) {
    case SortedByMaterialWork.DateAsc:
    case SortedByMaterialWork.TitleAsc:
    case SortedByMaterialWork.TitleDesc:
      return order;
    default:
      return undefined;
  }
};
export const getValidUrlGeneralString = (
  evaluatedParam: string,
  defaultParam: string,
  defaultParamTwo: string,
): string => {
  switch (evaluatedParam) {
    case defaultParam:
    case defaultParamTwo:
      return evaluatedParam;
    default:
      return evaluatedParam;
  }
};
