/* eslint-disable camelcase */
import { Api, CreatedCompanyTagTypesListParams } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import {
  DeleteTagTypeParams,
  PostTagTypeParams,
  UpdateTagTypeParams,
} from "../types/tag";

const api = new Api();

export interface FetchSuperOwnerCompanyTagTypes {
  params: CreatedCompanyTagTypesListParams;
  inModal?: boolean;
}

export const fetchSuperOwnerCompanyTagTypes = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/company_tags/tag_type/fetch",
  async ({ params }: FetchSuperOwnerCompanyTagTypes, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.createdCompanyTagTypesList(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data as any);
    }
  },
);

export const postSuperOwnerCompanyTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/company_tags/tag_type/post",
  async (data: PostTagTypeParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.companyTagTypesCreate(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSuperOwnerCompanyTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/company_tags/tag_type/update",
  async (data: UpdateTagTypeParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.companyTagTypesUpdate(data.id, {
        name: data.name,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSuperOwnerCompanyTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/company_tags/tag_type/delete",
  async (data: DeleteTagTypeParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.companyTagTypesDelete(data.id);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface SaveCompanyTagTypeSequenceParams {
  sequences: Array<{
    company_tag_type_id: string;
    sequence: number;
  }>;
}

export const saveCompanyTagTypeSequence = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companyTagTypes/saveSequence",
  async (data: SaveCompanyTagTypeSequenceParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.companyTagTypesSequenceUpdate(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "super_owner/companyTagTypes/resetState",
);
