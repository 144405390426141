/* eslint-disable camelcase */
import {
  Api,
  GetHomeworkImageDownloadUrlsAsTeacherParams,
  HomeworkDownloadUrl,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface GetImageUrlsArg {
  withQr: boolean;
  params: GetHomeworkImageDownloadUrlsAsTeacherParams;
}

// eslint-disable-next-line import/prefer-default-export
export const getDownloadUrls = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkImage/fetchDownloadUrls",
  async (arg: GetImageUrlsArg, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getHomeworkImageDownloadUrlsAsTeacher(
        arg.params,
      );
      return response.data.homeworks as HomeworkDownloadUrl[];
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction("teacher/homeworkImage/resetState");
