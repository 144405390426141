// It's impossible to detect the Twitter In-App browser, because the Twitter's UA is the same as Chrome's
export const webViewRules = [
  // if it says it's a webview, let's go with that
  "WebView",
  // iOS webview will be the same as safari but missing "Safari"
  "(iPhone|iPod|iPad)(?!.*Safari)",
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
  "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
  // old chrome android webview agent
  "Linux; U; Android",
  // special rule for LINE
  "Line/",
  // special rule for Facebook
  "FBAN|FBIOS",
  // special rule for Instagram
  "Instagram",
];

const webViewRulesRegex = new RegExp(`(${webViewRules.join("|")})`, "ig");

export const isInAppBrowser = (userAgent: string): boolean =>
  !!userAgent.match(webViewRulesRegex)?.length;
