import {
  fetchOnePblSponsorInfoAsContentProvider,
  fetchPblSponsorInfoAsContentProvider,
  resetOne,
} from "@actions/contentProviderApp/pbl/sponsor/info";
import { SponsorInfoDetailsBase,SponsorInfoListBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface ContentProviderAppPblSponsorInfoState {
  fetching: boolean;
  fetchingOne: boolean;
  fetched: boolean;
  fetchedOne: boolean;
  fetchErrors: string | null;
  fetchOneErrors: string | null;
  infoList: SponsorInfoListBase[];
  infoDetails: SponsorInfoDetailsBase | null;
  totalCount: number;
}

export const initialState: ContentProviderAppPblSponsorInfoState = {
  fetching: false,
  fetchingOne: false,
  fetched: false,
  fetchedOne: false,
  fetchErrors: null,
  fetchOneErrors: null,
  infoList: [],
  infoDetails: null,
  totalCount: 0,
};

const contentProviderAppPblSponsorInfoSlice = createSlice({
  name: "contentProviderApp/pbl/sponsors/info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPblSponsorInfoAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
          };
        },
      )
      .addCase(
        fetchPblSponsorInfoAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            fetchError: action.error.message as string,
          };
        },
      )
      .addCase(
        fetchPblSponsorInfoAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            fetched: true,
            fetchError: null,
            infoList: payload.sponsors,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        fetchOnePblSponsorInfoAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingOne: true,
            fetchedOne: false,
          };
        },
      )
      .addCase(
        fetchOnePblSponsorInfoAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: false,
            fetchOneError: action.error.message as string,
          };
        },
      )
      .addCase(
        fetchOnePblSponsorInfoAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: true,
            fetchOneError: null,
            infoDetails: payload,
          };
        },
      )
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          infoDetails: null,
          infoList: [],
          fetched: false,
          fetchedOne: false,
          fetchError: null,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const contentProviderAppPblSponsorInfoState = (
  state: RootState,
): ContentProviderAppPblSponsorInfoState =>
  state.contentProviderApp.pblSponsorInfo;

export default contentProviderAppPblSponsorInfoSlice.reducer;
