import {
  fetchMaterialAsOwner,
  updateMaterialAsOwner,
  updateVideoInMaterialAsOwner,
} from "@actions/ownerApp/material";
import {
  fetchMaterialAsSuperOwner,
  updateMaterialAsSuperOwner,
  updateVideoInMaterialAsSuperOwner,
} from "@actions/superOwnerApp/material";
import {
  UpdateMaterialParams,
  UpdateVideoInMaterialParams,
} from "@actions/types/material";
import { ReduxStatusType } from "@constants/redux";
import { useAuth } from "@contexts/Auth";
import { ownerAppMaterialState } from "@reducers/ownerApp/material";
import { superOwnerAppMaterialState } from "@reducers/superOwnerApp/material";
import { useAppDispatch, useAppSelector } from "@root/hooks";

type UseMaterialReturnType = {
  fetchMaterial: (id: string) => void;
  updateMaterialStatus: ReduxStatusType;
  updateVideoStatus: ReduxStatusType;
  updateMaterial: (params: UpdateMaterialParams) => Promise<void>;
  updateVideoInMaterial: (
    params: UpdateVideoInMaterialParams,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
  ) => void;
};

export const useMaterial = (): UseMaterialReturnType => {
  const dispatch = useAppDispatch();
  const {
    isCurrentRoles: { isOwner, isSuperOwner },
  } = useAuth();

  const ownerAppMaterialCache = useAppSelector(ownerAppMaterialState);
  const superOwnerAppMaterialCache = useAppSelector(superOwnerAppMaterialState);

  const getCurrentUserCache = () => {
    if (isOwner) {
      return ownerAppMaterialCache;
    }
    if (isSuperOwner) {
      return superOwnerAppMaterialCache;
    }
    throw new Error("Invalid Cache");
  };
  const { updateMaterialStatus, updateVideoStatus } = getCurrentUserCache();

  const getFetchMaterialCurrentUser = () => {
    if (isOwner) {
      return fetchMaterialAsOwner;
    }
    if (isSuperOwner) {
      return fetchMaterialAsSuperOwner;
    }
    throw new Error("Invalid fetch action");
  };
  const fetchMaterialAsOwnerOrSuperOwner = getFetchMaterialCurrentUser();

  const getUpdateVideoInMaterialAsOwnerOrSuperOwner = () => {
    if (isOwner) {
      return updateVideoInMaterialAsOwner;
    }
    if (isSuperOwner) {
      return updateVideoInMaterialAsSuperOwner;
    }
    throw new Error("Invalid update video action");
  };
  const getUpdateMaterialCurrentUser = () => {
    if (isOwner) {
      return updateMaterialAsOwner;
    }
    if (isSuperOwner) {
      return updateMaterialAsSuperOwner;
    }
    throw new Error("Invalid update material action");
  };

  const updateMaterialAsOwnerOrSuperOwner = getUpdateMaterialCurrentUser();
  const fetchMaterial = (id: string) => {
    dispatch(fetchMaterialAsOwnerOrSuperOwner(id));
  };

  const updateMaterial = async (params: UpdateMaterialParams) => {
    await dispatch(updateMaterialAsOwnerOrSuperOwner(params));
  };

  const updateVideoInMaterialAsOwnerOrSuperOwner =
    getUpdateVideoInMaterialAsOwnerOrSuperOwner();
  const updateVideoInMaterial = (
    params: UpdateVideoInMaterialParams,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    dispatch(
      updateVideoInMaterialAsOwnerOrSuperOwner({
        params,
        setProgress,
      }),
    );
  };

  return {
    fetchMaterial,
    updateMaterialStatus,
    updateVideoStatus,
    updateMaterial,
    updateVideoInMaterial,
  };
};

export default useMaterial;
