/* eslint-disable no-case-declarations */
import { getHelpCategories } from "@actions/common";
import { CategoryType, SubjectCategoryInfo } from "@lib/Api";
import { remove, removeChildren, update } from "@lib/collection";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  createCategoryAsOwner,
  deleteCategoryAsOwner,
  fetchCategoriesAsOwner,
  saveSequenceAsOwner,
  updateCategoryAsOwner,
} from "../../actions/ownerApp/category";
import { fetchCategoriesAsStudent } from "../../actions/studentApp/category";
import {
  createCategoryAsSuperOwner,
  deleteCategoryAsSuperOwner,
  fetchCategoriesAsSuperOwner,
  saveSequenceAsSuperOwner,
  updateCategoryAsSuperOwner,
} from "../../actions/superOwnerApp/category";
import { fetchCategoriesAsTeacher } from "../../actions/teacherApp/category";

export interface SubjectCategoryState {
  items: SubjectCategoryInfo[];
}
const initialState: SubjectCategoryState = {
  items: [],
};
const subjectReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: PayloadAction<any>,
): SubjectCategoryState => {
  const { payload } = action;
  const { type, updatedItems, categoryType } = payload;
  switch (action.type) {
    case fetchCategoriesAsOwner.fulfilled.type:
    case fetchCategoriesAsSuperOwner.fulfilled.type:
    case getHelpCategories.fulfilled.type:
    case fetchCategoriesAsStudent.fulfilled.type:
    case fetchCategoriesAsTeacher.fulfilled.type:
      return { ...state, items: payload.subjects };
    case createCategoryAsOwner.fulfilled.type:
    case createCategoryAsSuperOwner.fulfilled.type:
      return {
        ...state,
        items: [...state.items, payload],
      };
    case updateCategoryAsOwner.fulfilled.type:
    case updateCategoryAsSuperOwner.fulfilled.type:
      return {
        ...state,
        items: update(state.items, payload),
      };
    case deleteCategoryAsOwner.fulfilled.type:
    case deleteCategoryAsSuperOwner.fulfilled.type:
      return {
        ...state,
        items:
          categoryType === CategoryType.Grade
            ? removeChildren(state.items, payload.id, "grade_id")
            : remove(state.items, payload.id),
      };
    case saveSequenceAsOwner.fulfilled.type:
    case saveSequenceAsSuperOwner.fulfilled.type:
      return {
        ...state,
        items:
          type === CategoryType.Subject
            ? (updatedItems as SubjectCategoryInfo[])
            : state.items,
      };
    default:
      return state;
  }
};
export default subjectReducer;
