/* eslint-disable camelcase */
import { updateCategoryRenameMapAsSuperOwner } from "@actions/superOwnerApp/categoryRenameMap";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface SuperOwnerAppCategoryRenameMapState {
  changing: boolean;
  changed: boolean;
  changeError: any;
}

const initialState: SuperOwnerAppCategoryRenameMapState = {
  changing: false,
  changed: false,
  changeError: null,
};

export const superOwnerAppCategoryRenameMapSlice = createSlice({
  name: "Admin/CategoryRenameMap",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        updateCategoryRenameMapAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            changing: true,
            changed: false,
          };
        },
      )
      .addCase(
        updateCategoryRenameMapAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            changing: false,
            changed: true,
          };
        },
      )
      .addCase(
        updateCategoryRenameMapAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changed: false,
            changeError: errorWithMessage(action.payload),
          };
        },
      );
  },
});

export const superOwnerAppCategoryRenameMapState = (
  state: RootState,
): SuperOwnerAppCategoryRenameMapState => state.superOwnerApp.categoryRenameMap;

export default superOwnerAppCategoryRenameMapSlice.reducer;
