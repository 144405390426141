/* eslint-disable camelcase */
import { config } from "@constants/env";
import { AUTH, AUTH_PROXY, SIGN_IN_SETTING } from "@constants/localStorageKeys";
import { Api, AppName,RequestParams } from "@lib/Api";
import makeAppInfo from "@lib/app/app-info-utils";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import Cookies from "js-cookie";

import { getCommonAffiliates, resetState } from "./common";
import {
  AcceptInvitationParams,
  ResetPasswordParams,
  ResetPasswordRequestParams,
  SignInParams,
} from "./types/auth";

const api = new Api();

export interface SignupWithAffiliateInvitationParams {
  invitation_id: string;
  email: string;
  first_name: string;
  last_name: string;
  parent_first_name?: string;
  parent_last_name?: string;
  birth_date?: string;
  phone?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  password: string;
  recaptcha_token: string;
  is_recaptcha_v2: boolean;
  terms_accepted: boolean;
  app_name?: AppName;
}

export const getCurrentUser = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("auth/getCurrentUser", async (_: void, { rejectWithValue }) => {
  try {
    const response = await api.common.getCommonCurrentUserMe();
    const resData = response.data;
    const role = resData.current_role;
    return {
      currentUser: resData,
      role,
      companyId: resData.company_id,
    } as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const signin = createAsyncThunk<
  any,
  SignInParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("auth/signin", async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.auth.postAuthSignIn(params);

    const { headers } = response;
    const accessToken = headers["access-token"];
    const { client, uid } = headers;
    const expires = params.remember_me ? 14 : 1;
    Cookies.set(AUTH.ACCESS_TOKEN, accessToken as string, { expires });
    Cookies.set(AUTH.CLIENT, client as string, { expires });
    Cookies.set(AUTH.UID, uid as string, { expires });
    Cookies.set(SIGN_IN_SETTING.REMEMBER_ME, String(!!params.remember_me), {
      expires,
    });
    await dispatch(resetState());
    return {
      accessToken,
      client,
      uid,
    } as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const adminProxyLoginAsUser = createAsyncThunk<
  any,
  { id: string; company_id: string; role: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("auth/signinAsUser", async (params, { dispatch, rejectWithValue }) => {
  try {
    const response = await api.admin.usersAuthorizedSignInCreate(params.id);
    const { headers } = response;
    const accessToken = headers["access-token"];
    const { client, uid } = headers;
    sessionStorage.setItem(AUTH_PROXY.ACCESS_TOKEN, accessToken as string);
    sessionStorage.setItem(AUTH_PROXY.CLIENT, client as string);
    sessionStorage.setItem(AUTH_PROXY.UID, uid as string);
    await dispatch(getCommonAffiliates({}));
    return {
      accessToken,
      companyId: params.company_id,
      role: params.role,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const proxyLogout = createAction("auth/proxyLogout", function prepare() {
  sessionStorage.removeItem(AUTH_PROXY.ACCESS_TOKEN);
  sessionStorage.removeItem(AUTH_PROXY.CLIENT);
  sessionStorage.removeItem(AUTH_PROXY.UID);
  return {
    payload: {},
  };
});

export const logout = createAction("auth/logout", function prepare() {
  Cookies.remove(AUTH.ACCESS_TOKEN);
  Cookies.remove(AUTH.CLIENT);
  Cookies.remove(AUTH.UID);
  Cookies.remove("googleAuth");
  sessionStorage.removeItem(AUTH_PROXY.ACCESS_TOKEN);
  sessionStorage.removeItem(AUTH_PROXY.CLIENT);
  sessionStorage.removeItem(AUTH_PROXY.UID);
  return {
    payload: {},
  };
});

export const acceptInvitation = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "auth/acceptInvitation",
  async (params: AcceptInvitationParams, { rejectWithValue }) => {
    const { password, passwordConfirmation, invitationToken, appName } = params;
    try {
      await api.auth.putAuthInvitation({
        invitation_token: invitationToken,
        password,
        password_confirmation: passwordConfirmation,
        app_name: appName,
      });
      return {} as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetPasswordRequest = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "auth/resetPasswordRequest",
  async (params: ResetPasswordRequestParams, { rejectWithValue }) => {
    const { email, appName } = params;
    try {
      await api.auth.postAuthPassword({
        email,
        redirect_url: `${config.url.FRONT_URL}/auth/password_reset`,
        app_name: appName,
      });
      return {} as any;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const resetPassword = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "auth/resetPassword",
  async (params: ResetPasswordParams, { dispatch, rejectWithValue }) => {
    const { password, passwordConfirmation, accessToken, client, uid, expiry } =
      params;
    Cookies.set(AUTH.ACCESS_TOKEN, accessToken);
    Cookies.set(AUTH.CLIENT, client);
    Cookies.set(AUTH.UID, uid);
    const reqParams = {
      headers: {
        expiry,
        "token-type": "Bearer",
      },
    } as RequestParams;
    try {
      await api.auth.putAuthPassword(
        {
          password,
          password_confirmation: passwordConfirmation,
        },
        reqParams,
      );
      dispatch(logout());
      return {} as any;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const deleteTokenAndLogout = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "auth/deleteTokenAndLogout",
  async (_: void, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.auth.signOutDelete();
      dispatch(logout());
      return response.data;
    } catch (err) {
      // Remove keys from local storage even on error
      dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  },
);

export const showErrorPage = createAction("auth/showErrorPage");
export const hideErrorPage = createAction("auth/hideErrorPage");
export const resetForm = createAction("auth/resetAuthForm");
export const resetFirstTimeLoginState = createAction(
  "auth/resetFirstTimeLoginState",
);

export const authCheckState = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("auth/authCheckState", async (_: void, { dispatch, rejectWithValue }) => {
  const accessToken = Cookies.get(AUTH.ACCESS_TOKEN);
  const proxyAccessToken = sessionStorage.getItem(AUTH_PROXY.ACCESS_TOKEN);
  const client = Cookies.get(AUTH.CLIENT);
  const uid = Cookies.get(AUTH.UID);

  if (!accessToken) {
    dispatch(logout());
    return rejectWithValue({});
  }
  dispatch(getCurrentUser({}));
  return {
    proxyAccessToken,
    accessToken,
    client,
    uid,
  };
});

export interface RequestCreateParams {
  id_token?: string;
  remember_me?: boolean;
  appName?: AppName;
  access_token?: string;
}

export const requestCreate = createAsyncThunk<
  any,
  RequestCreateParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("auth/requestCreate", async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.auth.requestCreate(params);
    const resData = response.data;
    const { message } = resData;
    const accessToken = response.headers["access-token"];
    const { client, uid } = response.headers;
    const expires = params.remember_me ? 14 : 1;
    Cookies.set(AUTH.ACCESS_TOKEN, accessToken as string, { expires });
    Cookies.set(AUTH.CLIENT, client as string, { expires });
    Cookies.set(AUTH.UID, uid as string, { expires });
    if (params.id_token) {
      Cookies.set(
        SIGN_IN_SETTING.REMEMBER_ME_GOOGLE,
        String(!!params.remember_me),
        {
          expires,
        },
      );
    }
    return {
      message,
      accessToken,
      client,
      uid,
    };
  } catch (err) {
    const { appName } = makeAppInfo(params.appName);
    return rejectWithValue(`${appName}に招待されていません。`);
  }
});

export const signupWithAffiliateInvitation = createAsyncThunk<
  any,
  SignupWithAffiliateInvitationParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "auth/signupWithAffiliateInvitation",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      await api.auth.signupWithInvitation(params);
      return {};
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resendInvitationEmail = createAsyncThunk<
  any,
  { invitation_token: string; app_name?: AppName },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("auth/resendInvitationEmail", async (arg, { rejectWithValue }) => {
  try {
    const res = await api.auth.putResendInvitationAsAuth(arg);
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

type FirstTimeLoginParams = {
  first_time_login: boolean;
};

export const firstTimeLogin = createAsyncThunk<
  { user?: { first_time_login?: boolean } },
  FirstTimeLoginParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/users", async (params, { rejectWithValue }) => {
  try {
    const response = await api.common.putControllerAsRole(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});

export const changeNewNotification = createAction(
  "common/changeNewNotifiaction",
);
