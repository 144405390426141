/* eslint-disable camelcase */
import { ReduxStatus,ReduxStatusType } from "@constants/redux";
import { AdminCompany,AdminCompanyList } from "@lib/Api";
import { errorWithMessage } from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import {
  createCompanies,
  deleteCompanyAsAdmin,
  fetchCompanies,
  fetchCompanyDetail,
  fetchMaterialCapacity,
  postOptionsExpansion,
  postReviewCompanyAsAdmin,
  putOptionsExpansion,
  resetCompanyState,
  resetReviewCompanyCount,
  resetReviewCompanyState,
  updateCompanyDetail,
} from "../../actions/adminApp/company";
import { RootState } from "../../store";

export type MaterialCapacity = {
  material_capacity: string;
  material_limit_capacity: string;
} | null;

export interface AdminAppCompanyState {
  fetching: boolean;
  fetchingDetails: boolean;
  fetchingMaterialCapacity: boolean;
  posting: boolean;
  posted: boolean;
  updating: boolean;
  updated: boolean;
  error: any;
  companies: AdminCompanyList[];
  companyDetail: AdminCompany | null;
  materialCapacityDetail: MaterialCapacity;
  totalCount: number;
  batchCount: number;
  batchErrors: any;
  postOptionsExpansionStatus: ReduxStatusType;
  postOptionsExpansionError: string | null;
  putOptionsExpansionStatus: ReduxStatusType;
  putOptionsExpansionError: string | null;
  deleteCompany: ReduxStatusType;
  deleteCompanyError: string | null;
  deletedCompany: string | null;
}

export const initialState: AdminAppCompanyState = {
  fetching: false,
  fetchingDetails: false,
  fetchingMaterialCapacity: false,
  posting: false,
  posted: false,
  updating: false,
  updated: false,
  error: null,
  companies: [],
  companyDetail: null,
  materialCapacityDetail: null,
  totalCount: 0,
  batchCount: 0,
  batchErrors: null,
  postOptionsExpansionStatus: ReduxStatus.idle,
  postOptionsExpansionError: null,
  putOptionsExpansionStatus: ReduxStatus.idle,
  putOptionsExpansionError: null,
  deleteCompany: ReduxStatus.idle,
  deleteCompanyError: null,
  deletedCompany: null,
};

export const companySlice = createSlice({
  name: "Admin/Company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          companies: action.payload.companies,
          error: "",
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          companies: [],
          error: action.error,
        };
      })
      .addCase(fetchCompanyDetail.pending, (state, action) => {
        return {
          ...state,
          fetchingDetails: true,
        };
      })
      .addCase(fetchCompanyDetail.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingDetails: false,
          companyDetail: action.payload,
          error: "",
        };
      })
      .addCase(fetchCompanyDetail.rejected, (state, action) => {
        return {
          ...state,
          fetchingDetails: false,
          companyDetail: null,
          error: action.error,
        };
      })
      .addCase(fetchMaterialCapacity.pending, (state, action) => {
        return {
          ...state,
          fetchingMaterialCapacity: true,
        };
      })
      .addCase(fetchMaterialCapacity.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingMaterialCapacity: false,
          materialCapacityDetail: action.payload,
          error: "",
        };
      })
      .addCase(fetchMaterialCapacity.rejected, (state, action) => {
        return {
          ...state,
          fetchingMaterialCapacity: false,
          materialCapacityDetail: null,
          error: action.error,
        };
      })
      .addCase(createCompanies.pending, (state, action) => {
        return {
          ...state,
          posting: true,
          posted: false,
        };
      })
      .addCase(createCompanies.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          companyDetail: action.payload,
          error: "",
        };
      })
      .addCase(createCompanies.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          companyDetail: null,
          error: action.error,
        };
      })
      .addCase(deleteCompanyAsAdmin.pending, (state, action) => {
        return {
          ...state,
          deleteCompany: ReduxStatus.pending,
          deleteCompanyError: null,
        };
      })
      .addCase(deleteCompanyAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          deleteCompany: ReduxStatus.fulfilled,
          deleteCompanyError: null,
          deletedCompany: action.payload,
        };
      })
      .addCase(deleteCompanyAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          deleteCompany: ReduxStatus.rejected,
          deleteCompanyError: getErrorMessage(action.payload.error),
          deletedCompany: action.payload,
        };
      })
      .addCase(updateCompanyDetail.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(updateCompanyDetail.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
          companyDetail: action.payload,
          error: "",
        };
      })
      .addCase(updateCompanyDetail.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          companyDetail: null,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(postOptionsExpansion.pending, (state, _action) => {
        return {
          ...state,
          postOptionsExpansionStatus: ReduxStatus.pending,
          postOptionsExpansionError: null,
        };
      })
      .addCase(postOptionsExpansion.fulfilled, (state, _action) => {
        return {
          ...state,
          postOptionsExpansionStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(postOptionsExpansion.rejected, (state, action) => {
        return {
          ...state,
          postOptionsExpansionStatus: ReduxStatus.rejected,
          postOptionsExpansionError: action.payload.message,
        };
      })
      .addCase(putOptionsExpansion.pending, (state, _action) => {
        return {
          ...state,
          putOptionsExpansionStatus: ReduxStatus.pending,
          putOptionsExpansionError: null,
        };
      })
      .addCase(putOptionsExpansion.fulfilled, (state, _action) => {
        return {
          ...state,
          putOptionsExpansionStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(putOptionsExpansion.rejected, (state, action) => {
        return {
          ...state,
          putOptionsExpansionStatus: ReduxStatus.rejected,
          putOptionsExpansionError: action.payload.message,
        };
      })
      .addCase(postReviewCompanyAsAdmin.pending, (state, action) => {
        return {
          ...state,
          posting: true,
          posted: false,
          batchErrors: null,
        };
      })
      .addCase(postReviewCompanyAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(postReviewCompanyAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          batchErrors: action.payload?.errors,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(resetReviewCompanyState, (state, _action) => {
        return {
          ...state,
          batchErrors: null,
          batchCount: 0,
        };
      })
      .addCase(resetReviewCompanyCount, (state, _action) => {
        return {
          ...state,
          batchCount: 0,
        };
      })
      .addCase(resetCompanyState, (state, _action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          updating: false,
          updated: false,
          error: null,
          postOptionsExpansionStatus: ReduxStatus.idle,
          postOptionsExpansionError: null,
          putOptionsExpansionStatus: ReduxStatus.idle,
          putOptionsExpansionError: null,
          deleteCompany: ReduxStatus.idle,
          deleteCompanyError: null,
          deletedCompany: null,
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const companyState = (state: RootState): AdminAppCompanyState =>
  state.adminApp.company;

export default companySlice.reducer;
