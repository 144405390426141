import { combineReducers } from "redux";

import ArticleReducer from "./article";
import pblSponsorInfoReducer from "./pbl/sponsor/info";
import pblSponsorLectureReducer from "./pbl/sponsor/lecture";
import pblSponsorProjectReducer from "./pbl/sponsor/project";
import pblBoardSettingsReducer from "./pblBoard/boardSettings";
import pblBoardCampaignReducer from "./pblBoard/campaign";
import pblBoardCampaignRequestReducer from "./pblBoard/campaignRequest";
import SchoolReducer from "./school";

const contentProviderAppReducer = combineReducers({
  article: ArticleReducer,
  pblSponsorInfo: pblSponsorInfoReducer,
  pblSponsorProject: pblSponsorProjectReducer,
  pblSponsorLecture: pblSponsorLectureReducer,
  school: SchoolReducer,
  pblBoardCampaign: pblBoardCampaignReducer,
  pblBoardCampaignRequest: pblBoardCampaignRequestReducer,
  pblBoardSettings: pblBoardSettingsReducer,
});

export default contentProviderAppReducer;
