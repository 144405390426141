import { Theme } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallSize: {
      padding: theme.spacing(0.5, 1),
      height: "25px!important",
      fontSize: "14px!important",
      lineHeight: "14px!important",
      minWidth: 52,
    },
    containedButton: {
      color: theme.palette.common.white,
      height: 35,
      backgroundColor: theme.palette.tertiary.mainDark,
      "&:hover": {
        opacity: 0.8,
        backgroundColor: theme.palette.tertiary.mainDark,
      },
      fontWeight: 600,
      fontSize: 14,
      borderRadius: 8,
      textTransform: "none",
    },
    outlinedButton: {
      color: theme.palette.customColor.text,
      height: 35,
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${theme.palette.customColor.text}`,
      fontWeight: 600,
      fontSize: 14,
      borderRadius: 8,
      "&:hover": {
        backGroundColor: "#F6F6F6",
        border: `2px solid ${theme.palette.customColor.text}`,
      },
      textTransform: "none",
    },
    textButton: {
      color: theme.palette.tertiary.mainDark,
      backgroundColor: "transparent",
      height: 35,
      borderRadius: 8,
      paddingLeft: 16,
      paddingRight: 16,
      fontWeight: 600,
      fontSize: 14,
      "&:hover": {
        backgroundColor: theme.palette.quaternary.opacityGrey,
        opacity: 0.8,
      },
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  }),
);

export type Props = Omit<ButtonProps, "color">;

const MainButton: React.FC<Props> = ({ children, ...args }) => {
  const classes = useStyles();
  const smallSizeClass = args.size === "small" && classes.smallSize;
  return (
    <Button
      data-cy="main-button"
      classes={{
        contained: clsx(smallSizeClass, classes.containedButton),
        outlined: clsx(smallSizeClass, classes.outlinedButton),
        text: clsx(smallSizeClass, classes.textButton),
      }}
      variant="outlined"
      {...args}
    >
      {children}
    </Button>
  );
};

export default MainButton;
