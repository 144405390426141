import { combineReducers } from "redux";

import affiliateInvitationReducer from "./affiliateInvitation";
import ArticleReducer from "./article";
import CategoryRenameMapReducer from "./categoryRenameMap";
import CompanyOptionReducer from "./companyOption";
import frameWorkReportsReducer from "./frameWorkReports";
import homeworkReducer from "./homework";
import homeworkTemplateReducer from "./homeworkTemplate";
import learningTemplatePackageDistributionsMapReducer from "./learningTemplatePackageDistributionsReducer";
import materialReducer from "./material";
import materialDistributionReducer from "./materialDistribution";
import materialWorkReducer from "./materialWork";
import notificationReducer from "./notification";
import paymentReducer from "./payment";
import PblBoardSettingsReducer from "./pblBoard/boardSettings";
import pblBoardCampaignReducer from "./pblBoard/campaign";
import pblBoardCampaignRequestReducer from "./pblBoard/campaignRequest";
import reportReducer from "./report";
import userReducer from "./user";
import userGroupReducer from "./userGroup";
import userTagReducer from "./userTag";

const ownerAppReducer = combineReducers({
  affiliateInvitation: affiliateInvitationReducer,
  article: ArticleReducer,
  material: materialReducer,
  materialDistribution: materialDistributionReducer,
  materialWork: materialWorkReducer,
  frameWorkReports: frameWorkReportsReducer,
  homeworkTemplate: homeworkTemplateReducer,
  homework: homeworkReducer,
  notification: notificationReducer,
  payment: paymentReducer,
  report: reportReducer,
  user: userReducer,
  userGroup: userGroupReducer,
  userTag: userTagReducer,
  categoryRenameMap: CategoryRenameMapReducer,
  companyOption: CompanyOptionReducer,
  learningTemplatePackageDistributions:
    learningTemplatePackageDistributionsMapReducer,
  boardSettings: PblBoardSettingsReducer,
  pblBoardCampaign: pblBoardCampaignReducer,
  pblBoardCampaignRequest: pblBoardCampaignRequestReducer,
});

export default ownerAppReducer;
