/* eslint-disable no-nested-ternary */
import {
  resetState,
  updateMaterialDistributionAsSuperOwner,
} from "@actions/superOwnerApp/materialWork";
import { fetchLearningTemplatePackageDistributionHistories } from "@actions/superOwnerApp/templatePackages/learningTemplateHistory";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import ErrorMessageList from "@components/UI/molecules/ErrorMessageList";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import V2DateTimePicker from "@components/UIv2/atoms/V2DateTimePicker";
import {
  LearningTemplatePackageDistributionHistoryBase,
  MaterialDistributionHistory,
} from "@lib/Api";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import { errorWithMessage } from "@lib/rtk/error-utils";
import { safeString } from "@lib/string-utils";
import { formatedDateTime } from "@lib/time-utils";
import Container from "@mui/material/Container";
import { superOwnerAppMaterialWorkState } from "@reducers/superOwnerApp/materialWork";
import { usePutLearningTemplatePackageDistributionHistoriesAsSuperOwnerMutation } from "@root/endpoints/TimeTactApi/superOwnerApi";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import dayjs from "dayjs";
import React, { useEffect,useState } from "react";

interface EditDistributionsModalProps {
  open: boolean;
  handleClose: () => void;
  editableInformation:
    | MaterialDistributionHistory
    | null
    | LearningTemplatePackageDistributionHistoryBase;
  handleUpdatePage: (newPage: number, userId?: string) => void;
}

const EditDistributionsModal: React.FC<EditDistributionsModalProps> = ({
  open,
  handleClose,
  editableInformation,
  handleUpdatePage,
}) => {
  const { actions } = useEnqueueToast();
  const [distributionTime, setDistributionTime] = useState<string>("");
  const [expirationTime, setExpirationTime] = useState<string>("");
  const [inputError, setInputError] = useState<
    "distribution" | "expiration" | "required" | null
  >(null);
  const dispatch = useAppDispatch();
  const { updating, updated, updateError } = useAppSelector(
    superOwnerAppMaterialWorkState,
  );
  const [
    putLearningTemplatePackageDistributionHistoriesAsSuperOwner,
    { isError, error, isSuccess },
  ] = usePutLearningTemplatePackageDistributionHistoriesAsSuperOwnerMutation();

  useEffect(() => {
    if (editableInformation && "distribution_time" in editableInformation) {
      const distribution = formatedDateTime(
        editableInformation.distribution_time,
      );
      const expiration = formatedDateTime(editableInformation.expiration_time);
      setDistributionTime(distribution);
      setExpirationTime(expiration);
    }

    if (editableInformation && "end_at" in editableInformation) {
      const distribution = formatedDateTime(editableInformation.start_at);
      const expiration = formatedDateTime(editableInformation.end_at);
      setDistributionTime(distribution);
      setExpirationTime(expiration);
    }
  }, [editableInformation]);

  const handleChangeDistributionTime = (val: string) => {
    setDistributionTime(val);
    if (!val) {
      setInputError("required");
    } else if (expirationTime && dayjs(val).isAfter(dayjs(expirationTime))) {
      setInputError("distribution");
    } else if (inputError === "distribution" || inputError === "required") {
      setInputError(null);
    }
  };

  const handleChangeExpirationTime = (val: string) => {
    setExpirationTime(val);
    if (val && dayjs(val).isBefore(dayjs(distributionTime))) {
      setInputError("expiration");
    } else if (inputError === "expiration") {
      setInputError(null);
    }
  };

  let startHasPassed;
  if (editableInformation && "start_at" in editableInformation) {
    startHasPassed =
      (formatedDateTime(editableInformation.start_at) as string) <
      formatedDateTime(undefined);
  } else {
    startHasPassed =
      (formatedDateTime(editableInformation?.distribution_time) as string) <
      formatedDateTime(undefined);
  }

  const startDisabled = startHasPassed && inputError !== "distribution";

  const onSubmit = () => {
    if (
      editableInformation &&
      "distributor_affiliate_id" in editableInformation
    ) {
      putLearningTemplatePackageDistributionHistoriesAsSuperOwner({
        id: editableInformation.id || "",
        startAtAndEndAtRequestBody: {
          start_at: startDisabled ? undefined : distributionTime,
          end_at: expirationTime,
        },
      });
    } else {
      dispatch(
        updateMaterialDistributionAsSuperOwner({
          id: safeString(editableInformation?.id),
          distribution_time: startDisabled ? undefined : distributionTime,
          expiration_time: expirationTime || undefined,
        }),
      );
    }
  };

  const resetModalState = () => {
    dispatch(resetState());
    setDistributionTime("");
    setExpirationTime("");
    setInputError(null);
  };

  useEffect(() => {
    if (updated && open) {
      actions.showSuccess("教材設定を編集しました。");
      handleClose();
      handleUpdatePage(1);
    }
  }, [updated]);

  useEffect(() => {
    if (isSuccess) {
      actions.showSuccess("プログラムの配信期間を更新しました。");
      handleClose();
      dispatch(fetchLearningTemplatePackageDistributionHistories({}));
    }

    if (isError) {
      actions.showError(errorWithMessage(error));
      handleClose();
    }
  }, [isSuccess, isError]);

  return (
    <GenericModal
      title="配信設定の編集"
      open={open}
      handleClose={handleClose}
      secondaryButtonAction={handleClose}
      primaryButtonText="保存する"
      secondaryButtonText="キャンセル"
      primaryButtonAction={onSubmit}
      posting={updating}
      primaryLock={!!inputError}
      TransitionProps={{ onExited: resetModalState }}
      useActionButtons
      useSecondaryButton
    >
      <Container>
        <FormSectionWrap>
          <FormCategoryTitle>
            配信開始日の設定
            <span style={{ color: "#7a7a7a", marginLeft: 8 }}>※必須</span>
          </FormCategoryTitle>
          <V2DateTimePicker
            value={distributionTime}
            onChange={(value) => handleChangeDistributionTime(value || "")}
            disablePast
            disabled={!!startDisabled}
            slotProps={{
              textField: {
                error:
                  inputError === "distribution" || inputError === "required",
                helperText:
                  inputError === "required"
                    ? "開始日は必須です。"
                    : inputError === "distribution"
                      ? "開始日が終了日の前になるように設定してください。"
                      : startDisabled
                        ? "すでに配信済みのため、配信開始日は編集できません。"
                        : null,
              },
            }}
          />
        </FormSectionWrap>
        <FormSectionWrap>
          <FormCategoryTitle>
            配信終了日の設定
            <span style={{ color: "#7a7a7a", marginLeft: 8 }}>※任意</span>
          </FormCategoryTitle>
          <V2DateTimePicker
            value={expirationTime}
            onChange={(value) => handleChangeExpirationTime(value || "")}
            disablePast
            minDate={dayjs(distributionTime)}
            slotProps={{
              textField: {
                error: inputError === "expiration",
                helperText:
                  inputError === "expiration"
                    ? "開始日が終了日の前になるように設定してください。"
                    : null,
              },
            }}
          />
        </FormSectionWrap>
        {updateError && <ErrorMessageList errors={updateError} />}
      </Container>
    </GenericModal>
  );
};

export default EditDistributionsModal;
