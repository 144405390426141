import { BatchUpdateFeedbackCommentParams } from "@components/Common/Feedback/types";
import { Api, GetFeedbackTemplatesAsAdminParams } from "@lib/Api";
import { http } from "@lib/http";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchFeedbackTemplatesAsAdmin = createAsyncThunk<
  any,
  GetFeedbackTemplatesAsAdminParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/feedbackComment/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.getFeedbackTemplatesAsAdmin(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const batchUpdateFeedbackComment = createAsyncThunk<
  any,
  BatchUpdateFeedbackCommentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/feedbackComment/post", async (params, { rejectWithValue }) => {
  try {
    const {
      postFeedback,
      deleteFeedback,
      putFeedbackComment,
      putFeedback,
      postFeedbackComment,
    } = params;
    const responses: any[] = [];
    if (putFeedbackComment) {
      responses.push(
        await Promise.all(
          putFeedbackComment.map((comment) => {
            return api.admin.putFeedbackTemplateCommentAsAdmin(
              comment.feedback_template_id,
              comment.id,
              comment.data,
            );
          }),
        ),
      );
    }
    if (deleteFeedback) {
      responses.push(
        await Promise.all(
          deleteFeedback.map((item) => {
            return api.admin.deleteFeedbackTemplateAsAdmin(item.id);
          }),
        ),
      );
    }
    if (postFeedback) {
      responses.push(
        await Promise.all(
          postFeedback.map((feedback) => {
            const fd = new FormData();
            fd.set("icon", feedback.icon);
            if (typeof feedback.comments !== "undefined") {
              feedback.comments.forEach((comment) => {
                fd.append(`comments[][body]`, comment.body);
              });
            }
            return http.post(`/admin/feedback_templates`, fd);
          }),
        ),
      );
    }
    if (putFeedback) {
      responses.push(
        await Promise.all(
          putFeedback
            .filter((icon) => !!icon.icon)
            .map((icon) => {
              const fd = new FormData();
              fd.set("icon", icon.icon as File);
              return http.put(`/admin/feedback_templates/${icon.id}`, fd);
            }),
        ),
      );
    }

    if (postFeedbackComment) {
      responses.push(
        await Promise.all(
          postFeedbackComment.map((comment) => {
            const data = { body: comment.body };
            return api.admin.postFeedbackTemplateCommentAsAdmin(
              comment.id,
              data,
            );
          }),
        ),
      );
    }

    return responses;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetFormState = createAction("admin/feedback/resetState");
