import { Report } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  createReportAsTeacher,
  fetchReportAsTeacher,
  fetchReportsAsTeacher,
} from "../../actions/teacherApp/report";
import { RootState } from "../../store";

export interface TeacherAppReportState {
  fetching: boolean;
  posting: boolean;
  posted: boolean;
  postedReport: string | null;
  error: any;
  reports: Report[];
  report: // eslint-disable-next-line camelcase
  (Report & { message_to_owner: string; message_to_student: string }) | null;
  totalCount: number;
}

export const initialState: TeacherAppReportState = {
  fetching: false,
  posting: false,
  posted: false,
  postedReport: null,
  error: null,
  reports: [],
  report: null,
  totalCount: 0,
};

const teacherAppReportSlice = createSlice({
  name: "TeacherApp/Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          reports: [],
        };
      })
      .addCase(fetchReportsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: action.error.message as string,
          reports: [],
        };
      })
      .addCase(fetchReportsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          reports: payload.reports,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchReportAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchReportAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: action.error.message as string,
        };
      })
      .addCase(fetchReportAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          report: payload,
        };
      })
      .addCase(createReportAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          posting: true,
          postedReport: null,
          error: null,
          posted: false,
        };
      })
      .addCase(createReportAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          postedReport: null,
          posted: false,
          error: action.error.message as string,
        };
      })
      .addCase(createReportAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          posting: false,
          postedReport: payload.id,
          error: null,
          posted: true,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppReportState = (
  state: RootState,
): TeacherAppReportState => state.teacherApp.report;

export default teacherAppReportSlice.reducer;
