/* eslint-disable camelcase */
import {
  CreateCategoryParams,
  UpdateCategoryParams,
} from "@actions/types/category";
import { Api, CategoryInfo, CategoryType } from "@lib/Api";
import { http } from "@lib/http";
import { safeString } from "@lib/string-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { SUPER_OWNER_CATEGORIES_PATH } from "@root/constants/api";
import { RootState } from "@store/store";

import { fetchMaterialsAsSuperOwner } from "./material";

const api = new Api();

export const fetchCategoriesAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/category/fetch", async (_: void, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.categoriesList();
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const createCategoryAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/category/create",
  async (data: CreateCategoryParams, { rejectWithValue }) => {
    try {
      const { name, parent_id, student_visible, is_help, image, roles } = data;
      const fd = new FormData();
      if (parent_id) {
        fd.set("parent_id", parent_id as string);
      }
      fd.set("name", name);
      if (image) {
        fd.set("image", image as File, image?.name);
      }
      const response = await http.post(`${SUPER_OWNER_CATEGORIES_PATH}`, fd, {
        params: {
          student_visible,
          is_help,
          roles,
        },
      });

      return response.data as CategoryInfo;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const updateCategoryAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/category/update",
  async (params: UpdateCategoryParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.categoriesUpdate(
        safeString(params.id),
        params,
      );
      return response.data as CategoryInfo;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export type DeleteCategoryParams = {
  id: string;
  type: CategoryType;
  textId: string;
};

export const deleteCategoryAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/category/delete",
  async (params: DeleteCategoryParams, { rejectWithValue, dispatch }) => {
    const { id, type, textId } = params;
    try {
      await api.superOwner.categoriesDelete(id);
      if (type !== CategoryType.Text) {
        dispatch(fetchMaterialsAsSuperOwner({ category_id: textId }));
      }
      return {
        id,
        categoryType: type,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface SaveSequenceArg {
  type: CategoryType;
  updatedItems: CategoryInfo[];
  sequences: Array<{
    category_id: string;
    sequence: number;
  }>;
}

export const saveSequenceAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/category/saveSequence",
  async (arg: SaveSequenceArg, { rejectWithValue }) => {
    const { updatedItems, type, sequences } = arg;
    try {
      const response = await api.superOwner.categoriesSequenceUpdate({
        sequences,
      });
      return {
        updatedItems,
        data: response.data,
        type,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export type DuplicateMaterialArg = {
  id: string;
  data: {
    category_id: string;
  };
};

export const createMaterialDuplicateAsSuperOwner = createAsyncThunk<
  any,
  DuplicateMaterialArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/materials/id/duplicate", async (arg, { rejectWithValue }) => {
  const { id, data } = arg;
  try {
    const response = await api.superOwner.postMaterialDuplicateAsSuperOwner(
      id,
      data,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const resetSortData = createAction("category/resetSortData");
export const resetCategoryFormRelatedData = createAction(
  "category/resetFormRelatedData",
);
