/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export type PostPblBoardCampaignRequestAsTeacherParams = {
  board_campaign_requests: {
    board_campaign_id: string;
    article_id: string;
  }[];
};

export const postPblBoardCampaignRequestAsTeacher = createAsyncThunk<
  any,
  PostPblBoardCampaignRequestAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/board/campaign/request/post",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.postPblBoardCampaignRequestAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblBoardCampaignRequestsAsTeacher = createAsyncThunk<
  any,
  string[],
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/board/campaign/request/bulkDelete",
  async (ids, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.deletePblBoardCampaignRequestsAsTeacher({ ids });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetTeacherAppPblBoardCampaignRequestState = createAction(
  "teacherApp/pbl/board/resetPblBoardCampaignRequestState",
);
