/* eslint-disable @typescript-eslint/no-shadow */
import AppBar from "@mui/material/AppBar";
import { Theme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import theme from "@root/styles/theme";
import React from "react";

const lightColor = "rgba(255, 255, 255, 0.7)";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

interface StyledTabProps {
  label: string;
  value: string;
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      width: "100%",
      height: "3px",
      backgroundColor: theme.palette.tertiary.main,
    },
  },
})((props: StyledTabsProps) => (
  <Tabs
    action={(ref) => {
      setTimeout(() => {
        ref?.updateIndicator();
      }, 250);
    }}
    {...props}
    TabIndicatorProps={{
      children: <span />,
      style: { backgroundColor: theme.palette.tertiary.main },
    }}
  />
));

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: theme.palette.customColor.text,
      fontWeight: theme.typography.fontWeightRegular as any,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      "&:focus": {
        opacity: 1,
        color: theme.palette.tertiary.main,
      },
      "&$selected": {
        opacity: 1,
        color: theme.palette.tertiary.main,
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const styles = (theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: lightColor,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
    secondaryBar: {
      zIndex: 0,
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.customColor.text,
      boxShadow: "0px 2px 4px 0px rgba(71,81,73,0.05)",
      paddingBottom: theme.spacing(1),
    },
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    divider: {
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
    },
  });

interface TabDetailHeaderProps extends WithStyles<typeof styles> {
  tabClickHandler: (id: string) => void;
  selectedType: any;
  tabs: { label: string; value: string }[];
}

const TabDetailHeader = (props: TabDetailHeaderProps) => {
  const { classes, tabClickHandler, selectedType, tabs } = props;

  const tabClicked = (value: string) => {
    tabClickHandler(value);
  };

  return (
    <>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <StyledTabs
          value={selectedType}
          onChange={(event, value) => tabClicked(value)}
        >
          {tabs &&
            tabs.map((tab) => (
              <StyledTab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                data-cy="header-tab"
              />
            ))}
        </StyledTabs>
      </AppBar>
    </>
  );
};

export default withStyles(styles)(TabDetailHeader);
