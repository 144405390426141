import {
  getAffiliateTeachersAsStudent,
  getStudentProfile,
  getUserStudentsAsStudent,
  resetStudentProfileFormState,
  StudentTeacher,
  updateStudentProfile,
} from "@actions/studentApp/user";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { AffiliateBase, AffiliateName, StudentProfile } from "@lib/Api";
import { createSlice, SerializedError } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface StudentAppUsersState {
  fetchStudentsStatus: ReduxStatusType;
  fetchingTeachers: boolean;
  fetchedTeachers: boolean;
  teachers: StudentTeacher[];
  students: AffiliateName[];
  error: any;
  fetchingProfile: boolean;
  fetchedProfile: boolean;
  fetchingAffiliateTeachers: boolean;
  fetchedAffiliateTeachers: boolean;
  affiliateTeachers: AffiliateBase[];
  getAffiliateTeachersError: any;
  studentProfile: StudentProfile | null;
  updatingProfile: boolean;
  updatedProfile: boolean;
  profileError: any;
  getStudentsError: SerializedError | null;
}

export const initialState: StudentAppUsersState = {
  fetchStudentsStatus: ReduxStatus.idle,
  fetchingTeachers: false,
  fetchedTeachers: false,
  teachers: [],
  students: [],
  error: null,
  fetchingProfile: false,
  fetchedProfile: false,
  studentProfile: null,
  fetchingAffiliateTeachers: false,
  fetchedAffiliateTeachers: false,
  affiliateTeachers: [],
  getAffiliateTeachersError: null,
  updatingProfile: false,
  updatedProfile: false,
  profileError: null,
  getStudentsError: null,
};

export const studentAppUsersSlice = createSlice({
  name: "StudentApp/Users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserStudentsAsStudent.pending, (state, action) => {
        return {
          ...state,
          fetchStudentsStatus: ReduxStatus.pending,
          getStudentsError: null,
          teachers: [],
        };
      })
      .addCase(getUserStudentsAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          fetchStudentsStatus: ReduxStatus.fulfilled,
          getStudentsError: null,
          students: action.payload.students ?? [],
        };
      })
      .addCase(getUserStudentsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchStudentsStatus: ReduxStatus.rejected,
          getStudentsError: action.error,
          students: [],
        };
      })
      .addCase(getAffiliateTeachersAsStudent.pending, (state, action) => {
        return {
          ...state,
          fetchingAffiliateTeachers: true,
          fetchedAffiliateTeachers: false,
          getAffiliateTeachersError: null,
        };
      })
      .addCase(getAffiliateTeachersAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingAffiliateTeachers: false,
          fetchedAffiliateTeachers: true,
          getAffiliateTeachersError: null,
          affiliateTeachers: action.payload.teacher_affiliates,
        };
      })
      .addCase(getAffiliateTeachersAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingAffiliateTeachers: false,
          fetchedAffiliateTeachers: false,
          getAffiliateTeachersError: action.error,
        };
      })
      .addCase(getStudentProfile.pending, (state, action) => {
        return {
          ...state,
          fetchingProfile: true,
          profileError: null,
        };
      })
      .addCase(getStudentProfile.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingProfile: false,
          fetchedProfile: true,
          studentProfile: action.payload,
        };
      })
      .addCase(getStudentProfile.rejected, (state, action) => {
        return {
          ...state,
          fetchingProfile: false,
          studentProfile: null,
          profileError: action.payload,
        };
      })
      .addCase(updateStudentProfile.pending, (state, action) => {
        return {
          ...state,
          updatingProfile: true,
          updatedProfile: false,
          profileError: null,
        };
      })
      .addCase(updateStudentProfile.fulfilled, (state, action) => {
        return {
          ...state,
          updatingProfile: false,
          updatedProfile: true,
          studentProfile: action.payload,
        };
      })
      .addCase(updateStudentProfile.rejected, (state, action) => {
        return {
          ...state,
          updatingProfile: false,
          studentProfile: null,
          profileError: action.payload,
        };
      })
      .addCase(resetStudentProfileFormState, (state, action) => {
        return {
          ...state,
          updatedProfile: false,
          profileError: null,
        };
      });
  },
});

export const studentAppUsersState = (state: RootState): StudentAppUsersState =>
  state.studentApp.users;

export default studentAppUsersSlice.reducer;
