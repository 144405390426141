import theme from "@root/styles/theme";

const schoolAccordionStyles = {
  mainContainer: {
    marginLeft: theme.spacing(2),
    gap: theme.spacing(2),
  },
  schoolTitleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(2),
    cursor: "pointer",
  },
  templatesContainer: {
    margin: theme.spacing(2, 0, 2, 2),
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  inputsWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(2),
    margin: theme.spacing(1, 0, 2, 2),
  },
  commonFont: {
    fontWeight: 700,
    color: theme.palette.customColor.text,
  },
  templateTitle: {
    flexShrink: 0,
    width: 175,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    "&:hover": {
      cursor: "default",
    },
  },
  labelTitle: {
    fontSize: 17,
    fontWeight: 700,
    color: theme.palette.customColor.text,
  },
  tagStyle: {
    borderRadius: "8px",
    marginLeft: "1rem",
    padding: "4px 8px",
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 17,
  },
  required: {
    background: theme.palette.tertiary.lightRed,
  },
  optional: {
    background: theme.palette.customColor.brightGreen,
  },
  gridItem: {
    padding: "1rem 0px",
  },
};

export default schoolAccordionStyles;
