/* eslint-disable camelcase */
import { Api, CompanyBase, GetTeacherContentCompaniesParams } from "@lib/Api";
import { http } from "@lib/http";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import {
  UpdateOwnCompanyInfoAsOwnerParams,
  UpdateOwnCompanyInfoAsSuperOwnerParams,
} from "./types/company";

const api = new Api();

export const fetchCompanyInfoAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "company/fetchAsSuperOwner",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.fetchCompanyAsSuperOwner(companyId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchCompanyInfoAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("company/fetchAsOwner", async (companyId: string, { rejectWithValue }) => {
  try {
    const response = await api.owner.getOwnerCompaniesCompanyId(companyId);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchMaterialCapacityAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "company/fetchMaterialCapacityAsOwner",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await api.owner.getMaterialCapacityAsOwner(companyId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchCompanyInfoAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("company/fetchAsTeacher", async (companyId: string, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getTeacherCompaniesCompanyId(companyId);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchCompanyProviderAsTeacher = createAsyncThunk<
  { content_companies: CompanyBase[]; total_count: number },
  GetTeacherContentCompaniesParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("company/fetchCompanyProvider", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getTeacherContentCompanies(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateOwnCompanyInfoAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "company/updateAsOwner",
  async (params: UpdateOwnCompanyInfoAsOwnerParams, { rejectWithValue }) => {
    try {
      const { companyId, data } = params;
      const fd = new FormData();
      if (data.logo) {
        fd.set("logo", data.logo as File);
      }

      if (data.options) {
        fd.append("options", JSON.stringify(data.options));
      }

      const response = await http.put(`/owner/companies/${companyId}`, fd);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export type OptionsType = {
  use_account_lock_mail: boolean;
  use_article_and_homework_mail: boolean;
};
export type UpdateUserAccountLockParams = {
  companyId: string;
  data: {
    options: OptionsType;
  };
};
export const updateUserAccountLockAsTeacher = createAsyncThunk<
  { message?: string },
  UpdateUserAccountLockParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "company/teacher/update/account_lock",
  async (params, { rejectWithValue }) => {
    const { data, companyId } = params;

    try {
      const response = await api.teacher.putTeacherCompaniesCompanyId(
        companyId,
        data,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUserAccountLockAsOwner = createAsyncThunk<
  { message?: string },
  UpdateUserAccountLockParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("company/owner/update/account_lock", async (params, { rejectWithValue }) => {
  const { data, companyId } = params;

  try {
    const response = await api.owner.putOwnerCompaniesCompanyId(
      companyId,
      data,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateOwnCompanyInfoAsSuperOwner = createAsyncThunk<
  any,
  UpdateOwnCompanyInfoAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("company/updateAsSuperOwner", async (params, { rejectWithValue }) => {
  try {
    const { companyId, data } = params;
    const fd = new FormData();
    if (typeof data.logo !== "undefined") {
      fd.set("logo", data.logo as File);
    }

    fd.set("report_recipient_email", data.report_recipient_email || "");
    fd.set(
      "report_homework_template_distribution_histories_email",
      data.report_homework_template_distribution_histories_email || "",
    );
    fd.set(
      "report_the_number_of_users_email",
      data.report_the_number_of_users_email || "",
    );
    fd.set("inquiry_email", data.inquiry_email || "");
    fd.set(
      "limited_user_tag_monthly_report_email",
      data.limited_user_tag_monthly_report_email || "",
    );
    fd.set(
      "reviewer_company_group_id",
      data.limited_user_tag_monthly_report_email || "",
    );

    const response = await http.put(`/super_owner/companies/${companyId}`, fd);
    return response.data;
  } catch (err) {
    return rejectWithValue(errorWithMessage(err.response));
  }
});
