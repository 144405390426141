import { Box, Theme, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { memo } from "react";
import { BiError } from "react-icons/bi";

import { isInAppBrowser } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      padding: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
      },
    },
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(1),
      border: `1px solid ${theme.palette.tertiary.mainDark}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 3),
      },
    },
    titleWrapper: {
      display: "flex",
      alignItems: "center",
      columnGap: theme.spacing(2),
    },
    title: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.customColor.text,
    },
    description: {
      fontSize: 14,
      fontWeight: 400,
    },
  }),
);

const InAppBrowserWarning: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();

  if (isInAppBrowser(navigator.userAgent)) {
    return (
      <Box className={classes.main}>
        <Box className={classes.innerContainer}>
          <Box className={classes.titleWrapper}>
            <BiError size={20} color={theme.palette.customColor.text} />
            <Typography className={classes.title}>
              アプリ内ブラウザは未対応です。
            </Typography>
          </Box>
          <Typography className={classes.description}>
            一部機能が動作しない場合があるため、標準ブラウザをご利用ください。
          </Typography>
        </Box>
      </Box>
    );
  }

  return <></>;
};

export default memo(InAppBrowserWarning);
