import { Node } from "@tiptap/core";
import { DOMParser as PMDOMParser } from "prosemirror-model";
import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import Div from "./Div";

// buttonタグのonClick要素は、保存された後のHTMLPreviewの際にトグルの開閉や表示非表示を行う際に必要
export const ToggleListHTML = `<div class="toggleList">
                <button class="toggleButton" onClick={this.classList.toggle("clicked");this.parentElement.querySelector("div.details").classList.toggle("clicked")}></button>
                <div class="content">
                  <div class="summary" data-placeholder="トグル">
                  </div>
                  <div class="details" data-placeholder="空のトグルブロックです。クリックして編集する。">
                  </div>
                </div>
              </div>`;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    toggleButton: {
      setToggleList: (insertPosition?: number) => ReturnType;
    };
  }
}

// SVEditor内部でのトグルボタンの機能や制御を定義
const ToggleButton = Node.create({
  name: "toggleButton",
  content: "inline*",
  group: "block",
  defining: true,
  selectable: false,
  addAttributes() {
    return {
      style: {
        default: null,
      },
      class: {
        default: "toggleButton",
      },
      onclick: {
        default: null,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "button",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["button", HTMLAttributes, 0];
  },
  addCommands() {
    return {
      setToggleList:
        (insertPosition) =>
        ({ editor, chain, state }) => {
          const insertPos = editor.state.selection.$to.pos;
          const dom = new DOMParser().parseFromString(
            ToggleListHTML,
            "text/html",
          );
          const fragment = PMDOMParser.fromSchema(editor.schema).parse(
            dom.body,
          );
          chain()
            .insertContentAt(insertPosition ?? insertPos, {
              type: "div",
              attrs: { class: "toggleList" },
              content: fragment.content.firstChild?.content.toJSON(),
            })
            .blur()
            .run();

          return true;
        },
    };
  },
  addProseMirrorPlugins() {
    // プレースホルダの設定
    // 何か入力があった場合にプレースホルダを削除する
    const update = (view: EditorView) => {
      const { state, dispatch } = view;
      const { tr } = state;
      state.doc.descendants((node, pos) => {
        if (
          node.type.name !== "div" ||
          !["summary", "details clicked"].includes(node.attrs.class)
        ) {
          return;
        }

        if (node.textContent && node.attrs["data-placeholder"]) {
          const attrs = { ...node.attrs };
          delete attrs["data-placeholder"];
          dispatch(tr.setNodeMarkup(pos, null, attrs));
        }
      });
    };

    return [
      new Plugin({
        key: new PluginKey("toggleButtonEventHandler"),
        view() {
          return { update };
        },
        props: {
          // trueを返すとKeyDownの時に何もしない
          // falseを返すときは文字が入力される
          handleKeyDown(view, event) {
            const { dispatch, state } = view;
            const { selection } = state;
            const { $from, $to } = selection;
            // div.toggleListを祖先にもつ要素にセレクションがあるときに
            // 文字が何もない状態で削除されたらトグル自体削除
            if (event.key === "Backspace") {
              for (let i = 0; i < $from.depth; i += 1) {
                const node = $from.node(-1 * i);
                if (
                  node &&
                  node.type.name === "div" &&
                  node.attrs.class === "toggleList"
                ) {
                  const validTextContent = node.textContent.replace(/\s+/g, "");
                  if (validTextContent.length === 0) {
                    // div.toggleList要素の削除
                    // (削除する前にノードの挿入を行う
                    // div.toggleListを削除すると前後のノードの予期しない結合が起こるので
                    // それが起こらないように適当なノードを事前に挿入する)
                    const tr = state.tr
                      .insert(
                        $from.after(-1 * i),
                        state.schema.node(
                          Div.name,
                          {},
                          state.schema.node("paragraph"),
                        ),
                      )
                      .delete($from.before(-1 * i), $from.after(-1 * i));
                    dispatch(tr);
                    return false;
                  }
                }
              }
            }

            // buttonタブでの操作を制限
            // buttonタブの中にテキストノードなどを入れられないようにしている
            if (
              $from.parent.type.name === "toggleButton" &&
              $from.parentOffset === $to.parentOffset
            ) {
              // Check if the cursor is at the beginning or end of the button element
              const isAtStart = selection.$from.parentOffset === 0;
              const isAtEnd =
                selection.$from.parentOffset === $from.parent.nodeSize - 2;

              // Check if the left or right arrow key was pressed
              if (event.key === "ArrowLeft" && isAtStart) {
                // Move the cursor to the left of the button element
                dispatch(
                  state.tr.setSelection(
                    TextSelection.create(state.doc, selection.$from.before()),
                  ),
                );
              }
              if (event.key === "ArrowRight" && isAtEnd) {
                // Move the cursor to the right of the button element
                dispatch(
                  state.tr.setSelection(
                    TextSelection.create(state.doc, selection.$from.after()),
                  ),
                );
              }

              // カーソルがbuttonタブ上にあり、かつ選択範囲が空の場合、何もしない
              return true;
            }
            // それ以外の場合はデフォルトの処理を行う
            return false;
          },
          // クリックされた際にbuttonのclickedクラスを付け替えて、下向き三角、右向き三角の表示を切り替えている
          handleClick(view, _pos, event) {
            const coords = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            });
            if (!coords) {
              return;
            }

            const { state, dispatch } = view;
            const { tr, doc } = state;

            const $pos = doc.resolve(coords?.pos);
            const $node = $pos.parent;

            // toggleListクラスに囲われたdivをクリックしたときにdiv.summaryへカーソルを移動させる
            if (
              $node.type.name === "div" &&
              $node.attrs.class?.match(/toggleList/)
            ) {
              $node.descendants((node, pos) => {
                if (
                  node.type.name === "div" &&
                  node.attrs.class === "summary"
                ) {
                  dispatch(
                    tr.setSelection(
                      TextSelection.create(state.doc, $pos.start() + pos),
                    ),
                  );
                }
              });
            }

            // トグルボタン(三角のボタン)をクリックした場合の処理、トグルボタンでない場合は早期リターン
            if (
              $node.type.name !== "toggleButton" ||
              !$node.attrs.class.match(/toggleButton/)
            ) {
              return;
            }

            const nodeAttrs = {
              ...$node.attrs,
              class:
                $node.type.name === "toggleButton" &&
                $node.attrs.class === "toggleButton"
                  ? "toggleButton clicked"
                  : "toggleButton",
            };

            const newNode = $node.type.create(
              nodeAttrs,
              $node.content,
              $node.marks,
            );
            // トグルボタンの下向き三角と右向き三角の切り替え
            tr.replaceWith($pos.before(), $pos.after(), newNode);

            // トグルリストの内部の表示/非表示の切り替え
            $pos.node(-1).descendants((node, pos) => {
              if (
                node.type.name === "div" &&
                ["details", "details clicked"].includes(node.attrs.class)
              ) {
                tr.setNodeMarkup($pos.start(-1) + pos, null, {
                  ...node.attrs,
                  class:
                    node.attrs.class === "details"
                      ? "details clicked"
                      : "details",
                });
              }
            });

            dispatch(tr);
          },
        },
      }),
    ];
  },
});

export default ToggleButton;
