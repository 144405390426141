/* eslint-disable camelcase */
import {
  Api,
  FetchSponsorLecturesAsTeacherParams,
  FetchSponsorProjectsAsTeacherParams,
  FetchSponsorProjectsForCarouselAsTeacherParams,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblSponsorProjectAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/projects/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchOneSponsorProjectAsTeacher(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblSponsorProjectCarouselAsTeacher = createAsyncThunk<
  GetResponseType<Api["teacher"]["fetchSponsorProjectsForCarouselAsTeacher"]>,
  FetchSponsorProjectsForCarouselAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/projects/carousel",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.fetchSponsorProjectsForCarouselAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblSponsorProjectsAsTeacher = createAsyncThunk<
  any,
  FetchSponsorProjectsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/pbl/sponsor/projects/", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.fetchSponsorProjectsAsTeacher(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchLecturesByProjectAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/projects/fetchLectures",
  async (params: FetchSponsorLecturesAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchSponsorLecturesAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetOne = createAction("teacher/pbl/sponsor/projects/resetOne");
