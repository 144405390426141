/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
import {
  ContentWithNumberOfLectures,
  LecturesAndHistoriesCountResponse,
} from "@actions/types/steamLibrary";
import {
  Api,
  FetchSteamLectureHistoryCountAsStudentParams,
  PblSteamLecture,
  StudentLectureHistoryCount,
} from "@lib/Api";
import { http as localUrl } from "@lib/http";
import { http } from "@lib/steamLibraryhttp";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { PostLectureParams } from "@root/types/Steam/lecture";
import { RootState } from "@store/store";

const api = new Api();
const STEAM_LIBRARY_URLS = {
  steamLibraryNumberOfLectures: "/contents/number_of_lectures",
  timetactLectureHistories: "/student/pbl/steam/lecture_histories/count",
};

export const getSteamLectureDetailsAsStudent = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibraryLecture/fetch",
  async (lectureId, { rejectWithValue }) => {
    try {
      const response = await api.student.getStudentSteamLecturesId(lectureId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSteamLectureAsStudent = createAsyncThunk<
  PblSteamLecture,
  PostLectureParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/steamLibraryLecture/post", async (params, { rejectWithValue }) => {
  try {
    const { contentId, title, sl_lecture_id, sequence } = params;
    const response = await api.student.postStudentSteamLectures(
      { sl_content_id: contentId },
      { title, sl_lecture_id, sequence },
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const postSteamLectureHistoriesAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steam/lecturehistories/post",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.student.postSteamLectureHistoriesAsStudent({
        sl_lecture_id: params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getNumberOfHistoriesAndLecturesAsStudent = createAsyncThunk<
  LecturesAndHistoriesCountResponse[],
  FetchSteamLectureHistoryCountAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steam/lecturehistories/count",
  async (params, { rejectWithValue }) => {
    let allCountsResponse: LecturesAndHistoriesCountResponse[] = [];
    try {
      const response = await localUrl.get(
        STEAM_LIBRARY_URLS.timetactLectureHistories,
        {
          params,
        },
      );
      const responseTwo = await http.get(
        STEAM_LIBRARY_URLS.steamLibraryNumberOfLectures,
        {
          params: { content_ids: params.sl_content_ids },
        },
      );

      if (response.data && responseTwo.data) {
        allCountsResponse = response.data.steam_contents.map(
          (content: StudentLectureHistoryCount) => {
            const matchingContent = responseTwo.data.contents.filter(
              (matchContent: ContentWithNumberOfLectures) =>
                matchContent.title === content.title,
            )[0];
            return {
              ...content,
              number_of_lectures: matchingContent.number_of_lectures,
            };
          },
        );
      }
      return allCountsResponse;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchContentNumberOfLecturesAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/contents/numberOfLectures",
  async (arg: any, { rejectWithValue }) => {
    try {
      const responses: any[] = [];
      const response =
        await api.student.fetchSteamLectureHistoryStatsAsStudent(arg);
      if (response.data.steam_contents) {
        const contentIds = response.data.steam_contents.map(
          (content) => content.sl_content_id,
        );
        if (contentIds.length) {
          const lecturesCount = await http.get(
            STEAM_LIBRARY_URLS.steamLibraryNumberOfLectures,
            {
              params: { content_ids: contentIds },
            },
          );
          const contentsAndCounts = response.data.steam_contents.map(
            (content) => {
              const matchingContent = lecturesCount.data.contents.filter(
                (lecture: ContentWithNumberOfLectures) =>
                  lecture.title === content.title,
              )[0];
              return {
                ...content,
                ...matchingContent,
              };
            },
          );
          const contentsAndNumberOfLectures = contentsAndCounts.filter((obj) =>
            obj.hasOwnProperty("number_of_lectures"),
          );
          responses.push(contentsAndNumberOfLectures);
        } else {
          responses.push(response.data.steam_contents);
        }
      }
      return {
        ...response.data,
        steam_contents: responses[0],
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const resetSteamLectureDetails = createAction(
  "student/steamLibraryLecture/resetState",
);
