/* eslint-disable camelcase */
import {
  PostIdealBoardsParams,
  PutIdealBoardsParams,
  UpdateIdealBoardHeaderParams,
} from "@actions/types/ideal";
import { Api, Board, BoardList, GetIdealBoardsAsTeacherParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getIdealBoardsAsTeacher = createAsyncThunk<
  { boards: BoardList[]; total_count: number },
  GetIdealBoardsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/ideal/boards/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getIdealBoardsAsTeacher(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getIdealBoardsMoreAsTeacher = createAsyncThunk<
  { boards: BoardList[]; total_count: number },
  GetIdealBoardsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/ideal/boards/fetchMore", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getIdealBoardsAsTeacher(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getIdealBoardAsTeacher = createAsyncThunk<
  Board,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/ideal/boards/fetchOne", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getIdealBoardAsTeacher(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getIdealBoardForAffiliateAvatarsAsTeacher = createAsyncThunk<
  Board,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/ideal/boards/fetchOneForAffiliateAvatars",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getIdealBoardAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const postIdealBoardsAsTeacher = createAsyncThunk<
  any,
  PostIdealBoardsParams | { ideal_board_template_id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/ideal/boards/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.postIdealBoardsAsTeacher(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const putIdealBoardAsTeacher = createAsyncThunk<
  any,
  PutIdealBoardsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/ideal/boards/put", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    const response = await api.teacher.putIdealBoardAsTeacher(id, data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const putIdealBoardDataAsTeacher = createAsyncThunk<
  any,
  PutIdealBoardsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/ideal/boards/putData", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    const response = await api.teacher.putIdealBoardAsTeacher(id, data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deleteIdealBoardAsTeacher = createAsyncThunk<
  any,
  { id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/ideal/boards/delete", async (params, { rejectWithValue }) => {
  try {
    const { id } = params;
    const response = await api.teacher.deleteIdealBoardAsTeacher(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const resetTeacherAppIdealState = createAction(
  "teacherApp/ideal/resetIdealState",
);

export const resetTeacherAppIdealModalState = createAction(
  "teacherApp/ideal/resetIdealModalState",
);

// params array of affiliate_id
export const updateIdealBoardOnlineAffiliateIdsAsTeacher = createAction<
  string[]
>("teacherApp/ideal/updateOnlineAffiliateIds");

export const updateIdealBoardHeaderAsTeacher =
  createAction<UpdateIdealBoardHeaderParams>(
    "teacherApp/ideal/updateIdealBoardHeader",
  );
