/* eslint-disable @typescript-eslint/ban-types */
import { FormSectionWrap } from "@components/UI/atoms/Forms/FormComponents";
import V2StyledFormTitle from "@components/UIv2/atoms/V2StyledFormTitle";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValuesTypes } from "..";

type Props = {
  control: Control<FormValuesTypes, object>;
  commonRadioBoxValues: number[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formHelperText: {
      color: theme.palette.error.main,
    },
  }),
);

const DegreeOfInterest: React.FC<Props> = ({
  control,
  commonRadioBoxValues,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("university");
  return (
    <div>
      <FormSectionWrap>
        <Controller
          control={control}
          name="degreeOfInterest"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl>
              <V2StyledFormTitle isRequired title={t("degreeOfInterest")} />
              <RadioGroup row value={value} onChange={onChange}>
                {commonRadioBoxValues.map((rate) => (
                  <FormControlLabel
                    key={String(rate)}
                    value={String(rate)}
                    control={<Radio color="primary" />}
                    label={String(rate)}
                  />
                ))}
              </RadioGroup>
              <FormHelperText className={classes.formHelperText}>
                {error ? error.message : null}
              </FormHelperText>
            </FormControl>
          )}
          rules={{ required: t("choosePlease") }}
        />
      </FormSectionWrap>
    </div>
  );
};

export default DegreeOfInterest;
