/* eslint-disable camelcase */
import {
  deleteLearningTemplatePackageAsSuperOwner,
  deletePackagesSuccess,
  deletePackagingAsSuperOwner,
} from "@actions/superOwnerApp/templatePackages/learningTemplatePackage";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import {
  ModalStateParams,
  ModalStatus,
} from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import { PackagingPackageableType } from "@lib/Api";
import { Container, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useAppDispatch } from "@root/hooks";
import React from "react";

interface Props {
  modalState: ModalStateParams;
  posting: boolean;
  handleClose: () => void;
}

const DeletePackageModal: React.FC<Props> = ({
  handleClose,
  modalState,
  posting,
}) => {
  const dispatch = useAppDispatch();
  const {
    isOpen,
    status,
    package: templatePackage,
    learningTemplate,
  } = modalState;

  const isModalOpen =
    isOpen &&
    (status === ModalStatus.DELETE_PACKAGE ||
      status === ModalStatus.DELETE_TEMPLATE ||
      status === ModalStatus.DELETE_CO_PACKAGE);

  const isDeleteLearningTemplate = status === ModalStatus.DELETE_TEMPLATE;

  const isDeleteMaterialHomeworkPackage =
    status === ModalStatus.DELETE_CO_PACKAGE;

  const onSubmit = () => {
    if (!learningTemplate) return;
    if (isDeleteLearningTemplate) {
      dispatch(deleteLearningTemplatePackageAsSuperOwner(learningTemplate.id));
    } else if (isDeleteMaterialHomeworkPackage) {
      const packageToDelete = {
        package_id: learningTemplate.id,
        packageable_type: PackagingPackageableType.Package,
        packageable_id: templatePackage?.material_homework_template_package
          ?.id as string,
      };
      dispatch(deletePackagingAsSuperOwner(packageToDelete));
    } else {
      if (!templatePackage) return;
      const packageToDelete = {
        package_id: learningTemplate.id,
        packageable_type: templatePackage.packageable_type,
        packageable_id: templatePackage.packageable_id,
      };
      dispatch(deletePackagingAsSuperOwner(packageToDelete));
    }
    dispatch(deletePackagesSuccess());
  };

  return (
    <GenericModal
      useActionButtons
      useSecondaryButton
      title={isDeleteLearningTemplate ? "プログラムの削除" : "コンテンツの削除"}
      modalSize="sm"
      handleClose={posting ? () => null : handleClose}
      secondaryButtonAction={handleClose}
      primaryButtonAction={onSubmit}
      primaryButtonText="削除する"
      secondaryButtonText="キャンセル"
      open={isModalOpen}
      posting={posting}
      secondaryButtonActionPosting={posting}
    >
      <Container>
        <FormSectionWrap>
          <FormCategoryTitle>
            {isDeleteLearningTemplate && learningTemplate
              ? "プログラム名"
              : "コンテンツ名"}
          </FormCategoryTitle>
          <Typography>
            {isDeleteLearningTemplate && learningTemplate
              ? learningTemplate.title
              : templatePackage?.packageable_title}
          </Typography>
        </FormSectionWrap>
        <FormSectionWrap>
          <Alert severity="warning">
            一度削除するとデータの復元はできません。本当に削除しますか？
          </Alert>
        </FormSectionWrap>
      </Container>
    </GenericModal>
  );
};

export default DeletePackageModal;
