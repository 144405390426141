/* eslint-disable camelcase */
import PlusIcon from "@assets/images/svg/plus_icon.svg?react";
import SearchInput from "@components/UI/atoms/SearchInput/SearchInput";
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import { StyledTableContainer } from "@components/UI/organisms/Tables/TableComponents";
import { useFilterStyles } from "@components/UIv2/molecules/V2Filter/V2FilterSelectorSection";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import {
  ModalStateParams,
  ModalStatus,
} from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import {
  LearningTemplatePackageBase,
  PackagingAsLearningTemplatePackageBase,
} from "@lib/Api";
import { Box, Theme } from "@mui/material";
import Table from "@mui/material/Table";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { GradeCategoryState } from "@reducers/category/grade";
import { MajorCategoryState } from "@reducers/category/major";
import { MiddleCategoryState } from "@reducers/category/middle";
import { MinorCategoryState } from "@reducers/category/minor";
import { SubjectCategoryState } from "@reducers/category/subject";
import { TextCategoryState } from "@reducers/category/text";
import React, { useState } from "react";

import Body from "./Body";
import Header from "./Header";
import AddOrUpdatePackageModal from "./Modals/AddOrUpdatePackageModal";
import CreateOrEditLearningTemplateModal from "./Modals/CreateAndEditLearningTemplateModal";
import DeletePackageModal from "./Modals/DeletePackageModal";
import LearningTemplateDistributionModal from "./Modals/LearningTemplateDistributionModal";

interface TableProps {
  fetched: boolean;
  fetching: boolean;
  posted: boolean;
  distributing: boolean;
  distributed: boolean;
  posting: boolean;
  updating: boolean;
  deletingPackage: boolean;
  postingPackage: boolean;
  postedPackage: boolean;
  modalState: ModalStateParams;
  fetchError: string | null;
  packages: LearningTemplatePackageBase[];
  resetModalState: () => void;
  handleModalOpen: (
    modalStatus: ModalStatus,
    learningTemplateInfos?: LearningTemplatePackageBase,
    packageInfos?: PackagingAsLearningTemplatePackageBase,
    packagesArrayInfos?: PackagingAsLearningTemplatePackageBase[],
  ) => void;
  totalCount: number;
  currentPage: number;
  perPage: number;
  handlePageTransition: (newPage: number) => void;
  text: TextCategoryState;
  grade: GradeCategoryState;
  subject: SubjectCategoryState;
  major: MajorCategoryState;
  middle: MiddleCategoryState;
  minor: MinorCategoryState;

  tabClickHandler: (value: string) => void;

  onSearch: () => void;

  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  searchText: string;
}

export enum DistributionEnum {
  BY_SCHOOL = "BySchool",
  BY_USER_TAG = "ByUserTag",
  BY_LIMITED_USER_TAG = "ByLimitedUserTag",
}

export interface FormType {
  template_title: string;
  template_description: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
  }),
);

const LearningTemplateTable: React.FC<TableProps> = ({
  fetched,
  fetching,
  posting,
  postingPackage,
  postedPackage,
  distributing,
  distributed,
  updating,
  deletingPackage,
  fetchError,
  packages,
  modalState,
  resetModalState,
  handleModalOpen,
  totalCount,
  currentPage,
  perPage,
  handlePageTransition,
  posted,
  text,
  grade,
  subject,
  major,
  middle,
  minor,
  tabClickHandler,
  onSearch,
  onInputChange,
  searchText,
}) => {
  const classes = useStyles();
  const filterStyles = useFilterStyles();

  const [distributionType, setDistributionType] = useState<DistributionEnum>(
    DistributionEnum.BY_SCHOOL,
  );
  const handleDistributionModal = (
    type: DistributionEnum,
    learningTemplateInfos: LearningTemplatePackageBase,
    packagesArrayInfos: PackagingAsLearningTemplatePackageBase[],
  ) => {
    setDistributionType(type);
    handleModalOpen(
      ModalStatus.DISTRIBUTION,
      learningTemplateInfos,
      undefined,
      packagesArrayInfos,
    );
  };
  const handleClose = () => {
    resetModalState();
  };

  return (
    <>
      <CreateOrEditLearningTemplateModal
        handleClose={resetModalState}
        posting={posting || updating}
        modalState={modalState}
        posted={posted}
      />

      <AddOrUpdatePackageModal
        handleClose={handleClose}
        posting={postingPackage}
        postedPackage={postedPackage}
        modalState={modalState}
        text={text}
        grade={grade}
        subject={subject}
        major={major}
        middle={middle}
        minor={minor}
        fetching={fetching}
      />
      <DeletePackageModal
        handleClose={handleClose}
        posting={deletingPackage}
        modalState={modalState}
      />
      <LearningTemplateDistributionModal
        handleClose={handleClose}
        posting={distributing}
        modalState={modalState}
        distributionType={distributionType}
        distributed={distributed}
        tabClickHandler={tabClickHandler}
      />
      <Box className={classes.buttonWrapper}>
        <SearchInput
          placeholder="プログラム名検で索する"
          handleSubmit={onSearch}
          handleChange={onInputChange}
          value={searchText}
          inputClassName={filterStyles.searchInputWide}
        />
        <SingleButton
          onClick={() => handleModalOpen(ModalStatus.CREATE_TEMPLATE)}
          startIcon={<PlusIcon />}
          data-cy="add_program"
        >
          プログラムを追加する
        </SingleButton>
      </Box>

      <StyledTableContainer>
        <Table aria-label="simple table" style={{ width: "100%" }}>
          <Header />
          <Body
            fetched={fetched}
            fetching={fetching}
            fetchError={fetchError}
            packages={packages}
            handleModalOpen={handleModalOpen}
            handleDistributionModal={handleDistributionModal}
          />
        </Table>
      </StyledTableContainer>
      <V2Pagination
        totalCount={totalCount}
        currentPage={currentPage}
        perPage={perPage}
        handlePageTransition={handlePageTransition}
      />
    </>
  );
};

export default LearningTemplateTable;
