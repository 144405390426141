import {
  fetchOnePblSponsorInfoAsTeacher,
  fetchPblSponsorInfoAsTeacher,
  resetOne,
} from "@actions/teacherApp/pbl/sponsor/info";
import { SponsorInfoDetailsBase,SponsorInfoListBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface TeacherAppPblSponsorInfoState {
  fetching: boolean;
  fetchingOne: boolean;
  fetched: boolean;
  fetchedOne: boolean;
  fetchErrors: string | null;
  fetchOneErrors: string | null;
  infoList: SponsorInfoListBase[];
  infoDetails: SponsorInfoDetailsBase | null;
  totalCount: number;
}

export const initialState: TeacherAppPblSponsorInfoState = {
  fetching: false,
  fetchingOne: false,
  fetched: false,
  fetchedOne: false,
  fetchErrors: null,
  fetchOneErrors: null,
  infoList: [],
  infoDetails: null,
  totalCount: 0,
};

const teacherAppPblSponsorInfoSlice = createSlice({
  name: "teacherApp/pbl/sponsors/info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblSponsorInfoAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchPblSponsorInfoAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchPblSponsorInfoAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: null,
          infoList: payload.sponsors,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchOnePblSponsorInfoAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
        };
      })
      .addCase(fetchOnePblSponsorInfoAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error.message as string,
        };
      })
      .addCase(fetchOnePblSponsorInfoAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          fetchOneError: null,
          infoDetails: payload,
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          infoDetails: null,
          infoList: [],
          fetched: false,
          fetchedOne: false,
          fetchError: null,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppPblSponsorInfoState = (
  state: RootState,
): TeacherAppPblSponsorInfoState => state.teacherApp.pblSponsorInfo;

export default teacherAppPblSponsorInfoSlice.reducer;
