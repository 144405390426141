/* eslint-disable @typescript-eslint/ban-types */
import { CompanyTagType } from "@actions/types/tag";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import { Company } from "@lib/Api";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useEffect } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import {
  FormType,
  SchoolDistributionEnum,
} from "../../usePackageDistributionModal";
import SchoolList from "./SchoolList";
import TagAccordion from "./SchoolTags";

export interface Props {
  control: Control<FormType, object>;
  setValue: UseFormSetValue<FormType>;
  getValues: UseFormGetValues<FormType>;
  watch: UseFormWatch<FormType>;
  reset: UseFormReset<FormType>;
  errors: any;
  companyTagTypes: CompanyTagType[];
  schools: Company[];
}

const DistributeBySchool: React.FC<Props> = ({
  control,
  getValues,
  setValue,
  watch,
  companyTagTypes,
  schools,
  errors,
  reset,
}) => {
  const schoolType: string = watch("schoolDistributionType");

  const handleIds = (value: Company | CompanyTagType) => {
    const { schoolTags, schools: schoolIds } = getValues();
    const schoolsOrTags:
      | Company[]
      | {
          id: string;
          name: string;
        }[] =
      schoolType === SchoolDistributionEnum.BY_SCHOOL_TAG
        ? schoolTags
        : schoolIds;
    const compareTo = schoolsOrTags.map((item) => item.id);
    const newIds = compareTo?.includes(value.id)
      ? schoolsOrTags?.filter((school: Company) => school.id !== value.id)
      : [...(schoolsOrTags ?? []), value];
    return newIds;
  };

  const toggleAllSchool = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue("schools", schools);
    } else {
      setValue("schools", []);
    }
  };

  const formValues = getValues();
  useEffect(() => {
    reset({
      ...getValues(),
      schools: [],
      schoolTags: [],
      schoolDistributionType: formValues.schoolDistributionType,
    });
  }, [schoolType]);

  const handleSchoolComponent = (type: string) => {
    switch (type) {
      case SchoolDistributionEnum.BY_SCHOOL:
        return (
          <SchoolList
            control={control}
            handleSchools={handleIds}
            schools={schools}
            toggleAllSchool={toggleAllSchool}
            errors={errors}
          />
        );
      case SchoolDistributionEnum.BY_SCHOOL_TAG:
        return (
          <TagAccordion
            control={control}
            handleSchoolTagAssign={handleIds}
            companyTagTypes={companyTagTypes}
            errors={errors}
          />
        );
      default:
        return null;
    }
  };

  const schoolComponent = handleSchoolComponent(schoolType);

  return (
    <>
      <FormSectionWrap>
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Box mr={3} display="flex" alignItems="center">
            <FormCategoryTitle style={{ margin: 0 }}>
              配信方法
            </FormCategoryTitle>
          </Box>
          <Controller
            control={control}
            name="schoolDistributionType"
            render={({ field: { value, onChange } }) => {
              return (
                <RadioGroup row value={value} onChange={onChange}>
                  <FormControlLabel
                    value={SchoolDistributionEnum.BY_SCHOOL}
                    control={<Radio color="primary" />}
                    label="学校から選択する"
                    data-cy="school-option"
                  />
                  <FormControlLabel
                    value={SchoolDistributionEnum.BY_SCHOOL_TAG}
                    control={<Radio color="primary" />}
                    label="タグから選択する"
                  />
                </RadioGroup>
              );
            }}
          />
        </Box>
      </FormSectionWrap>
      {schoolComponent}
    </>
  );
};

export default DistributeBySchool;
