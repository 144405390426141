/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

interface UpdateCategoryRenameMapAsOwnerArg {
  for_text?: string | undefined;
  for_subject?: string | undefined;
  for_grade?: string | undefined;
  for_major?: string | undefined;
  for_middle?: string | undefined;
  for_minor?: string | undefined;
}

// eslint-disable-next-line import/prefer-default-export
export const updateCategoryRenameMapAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/categoryRenameMap/update",
  async (arg: UpdateCategoryRenameMapAsOwnerArg, { rejectWithValue }) => {
    try {
      const response = await api.owner.updateCategoryRenameMapAsOwner(arg);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
