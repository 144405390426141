/* eslint-disable camelcase */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Api,
  GetTeacherPblReportItemsParams,
  PblReportItemTypeEnum,
} from "@root/lib/Api";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblReportItemsAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/items/fetch",
  async (params: GetTeacherPblReportItemsParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherPblReportItems(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreatePblReportItemAsTeacherParams {
  template_id: string;
  type?: PblReportItemTypeEnum;
  title?: string;
  description?: string;
  sequence: number;
  text_min_char_size?: number;
}

export const createPblReportItemAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/items/create",
  async (params: CreatePblReportItemAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.postTeacherPblReportItems(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdatePblReportItemAsTeacherParams {
  sequence?: number;
  title?: string;
  description?: string;
  text_example?: string;
  text_min_char_size?: number;
  has_title_required?: boolean;
}

export interface UpdatePblReportItemAsTeacherArg {
  id: string;
  params: UpdatePblReportItemAsTeacherParams;
}

export const updatePblReportItemAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/items/update",
  async (arg: UpdatePblReportItemAsTeacherArg, { rejectWithValue }) => {
    try {
      const response = await api.teacher.putTeacherPblReoprtItemId(
        arg.id,
        arg.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblReportItemAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/report/items/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.teacher.deleteTeacherPblReportItemId(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
