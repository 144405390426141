import {
  batchUpdateIpWhiteListsAsAdmin,
  fetchIpWhiteListsAsAdmin,
  resetIpWhiteListFormState,
} from "@actions/adminApp/ipWhiteList";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

interface IpWhiteListState {
  fetchingIpWhiteList: boolean;
  fetchedIpWhiteList: boolean;
  batchUpdatingIpWhiteLists: boolean;
  batchUpdatedIpWhiteLists: boolean;
  ipWhiteLists: any[];
  fetchIpWhiteListError: string | null;
  batchUpdateIpWhiteListsError: string | null;
  totalCount: number;
}

const initialState: IpWhiteListState = {
  fetchingIpWhiteList: false,
  fetchedIpWhiteList: false,
  ipWhiteLists: [],
  fetchIpWhiteListError: null,
  totalCount: 0,
  batchUpdatingIpWhiteLists: false,
  batchUpdatedIpWhiteLists: false,
  batchUpdateIpWhiteListsError: null,
};

const ipWhiteListSlice = createSlice({
  name: "Admin/IpWhiteList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIpWhiteListsAsAdmin.pending, (state, action) => {
        return {
          ...state,
          fetchingIpWhiteList: true,
          fetchedIpWhiteList: false,
          ipWhiteLists: [],
          fetchIpWhiteListError: null,
        };
      })
      .addCase(fetchIpWhiteListsAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingIpWhiteList: false,
          fetchedIpWhiteList: true,
          ipWhiteLists: action.payload.ip_whitelists,
          totalCountSent: action.payload.total_count,
        };
      })
      .addCase(fetchIpWhiteListsAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetchingIpWhiteList: false,
          fetchIpWhiteListError: "データが取得できませんでした。",
        };
      })
      .addCase(batchUpdateIpWhiteListsAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          batchUpdatingIpWhiteLists: true,
          batchUpdatedIpWhiteLists: false,
          batchUpdateIpWhiteListsError: null,
        };
      })
      .addCase(batchUpdateIpWhiteListsAsAdmin.fulfilled, (state, _action) => {
        return {
          ...state,
          batchUpdatingIpWhiteLists: false,
          batchUpdatedIpWhiteLists: true,
        };
      })
      .addCase(batchUpdateIpWhiteListsAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          batchUpdatingIpWhiteLists: false,
          batchUpdateIpWhiteListsError: action.payload?.errors || [
            "エラーが発生しました。",
          ],
        };
      })
      .addCase(resetIpWhiteListFormState, (state, _action) => {
        return {
          ...state,
          batchUpdatingIpWhiteLists: false,
          batchUpdatedIpWhiteLists: false,
          batchUpdateIpWhiteListsError: null,
        };
      });
  },
});

export const adminAppIpWhiteListState = (state: RootState): IpWhiteListState =>
  state.adminApp.ipWhiteList;

export default ipWhiteListSlice.reducer;
