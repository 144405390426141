/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-no-duplicate-props */
import { SEARCH_MAX_LENGTH } from "@constants/searchBar";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment,TextField, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Control,Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValues } from "../../hooks/useHandleStudentsLogs";

type Props = {
  control: Control<FormValues, object>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 0,
      marginBottom: 0,
      backgroundColor: theme.palette.common.white,
      maxWidth: 400,
    },
  }),
);

const Input: React.FC<Props> = ({ control }) => {
  const classes = useStyles();
  const { t } = useTranslation("study_logs");
  return (
    <Controller
      name="searchText"
      control={control}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <TextField
          size="small"
          variant="outlined"
          margin="dense"
          placeholder={t("searchByStudent")}
          value={value}
          onChange={onChange}
          InputProps={{
            className: classes.root,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: SEARCH_MAX_LENGTH,
          }}
        />
      )}
    />
  );
};

export default Input;
