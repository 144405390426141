/* eslint-disable react/no-array-index-key */
import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

import ErrorMessage from "../ErrorMessage";

interface Props {
  errors: { key: string | null; message: string }[] | string[];
}

const ErrorMessageList: React.FC<Props> = ({ errors }: Props) => {
  const [showAll, setShowAll] = useState(false);
  useEffect(() => {
    if (errors.length <= 5) {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
  }, [errors]);

  const showMorePart = !showAll ? (
    <Box mt={2} mb={4} display="flex" justify-content="end">
      <Button color="secondary" onClick={() => setShowAll(true)}>
        すべて表示(全{errors.length}件)
      </Button>
    </Box>
  ) : null;
  let errorsToShow = [...errors];
  errorsToShow = showAll ? errorsToShow : errorsToShow.slice(0, 5);
  const errorMessages = errorsToShow.map((err, index) => {
    return (
      <ErrorMessage
        key={`error-message-${index}`}
        message={typeof err === "string" ? err : err.message}
      />
    );
  });

  return (
    <Box>
      {errorMessages}
      {showMorePart}
    </Box>
  );
};

export default ErrorMessageList;
