import { BrowserHistory } from "history";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

type QueryParams = Partial<Record<string, string>>;

export const useQuery = <T extends QueryParams>(): T => {
  const { search } = useLocation();

  return useMemo(() => {
    const query = new URLSearchParams(search);
    return Object.fromEntries(query.entries()) as T;
  }, [search]);
};

export const useSetQuery = <T extends QueryParams>(
  isReplacing = false,
): ((input: T, hash?: string) => void) => {
  const query = useQuery();
  const history: BrowserHistory = useHistory();

  return (input: T, hash?: string) => {
    const newQuery = isReplacing ? { ...input } : { ...query, ...input };

    const filteredQuery = Object.entries(newQuery).filter(
      (entry): entry is [string, string] =>
        typeof entry[1] === "string" && entry[1].trim().length > 0,
    );

    const queryParams = `?${new URLSearchParams(filteredQuery).toString()}`;

    history.push({ search: queryParams, hash: hash ? `#${hash}` : "" });
  };
};
