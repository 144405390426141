import {
  getHomeworkChunkAsReviewer,
  getHomeworkChunksAsReviewer,
  resetModalState,
  updateHomeworkChunkAsReviewer,
} from "@actions/reviewerApp/homeworkChunk";
import {
  HomeworkChunkDetail,
  HomeworkChunkList,
  HomeworkChunkStatuses,
} from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface ReviewerAppHomeworkChunkState {
  getHomeworkChunksStatus: ReduxStatusType;
  getHomeworkChunkStatus: ReduxStatusType;
  updateHomeworkChunkStatus: ReduxStatusType;
  homeworkChunks: HomeworkChunkList[];
  homeworkChunkStatuses: HomeworkChunkStatuses;
  homeworkChunk: HomeworkChunkDetail | null;
  totalCount: number;
}

export const initialState: ReviewerAppHomeworkChunkState = {
  getHomeworkChunksStatus: ReduxStatus.idle,
  getHomeworkChunkStatus: ReduxStatus.idle,
  updateHomeworkChunkStatus: ReduxStatus.idle,
  homeworkChunks: [],
  homeworkChunkStatuses: null,
  homeworkChunk: null,
  totalCount: 0,
};

export const reviewerAppHomeworkChunkSlice = createSlice({
  name: "ReviewerApp/HomeworkChunk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomeworkChunksAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          getHomeworkChunksStatus: ReduxStatus.pending,
          chunkDistributionStatuses: null,
          homeworkChunks: [],
          homeworkChunkStatuses: null,
        };
      })
      .addCase(getHomeworkChunksAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          getHomeworkChunksStatus: ReduxStatus.fulfilled,
          homeworkChunks: payload.homework_chunks,
          homeworkChunkStatuses: payload.homework_chunk_statuses,
          totalCount: payload.total_count,
        };
      })
      .addCase(getHomeworkChunksAsReviewer.rejected, (state, _action) => {
        return {
          ...state,
          getHomeworkChunksStatus: ReduxStatus.rejected,
        };
      })
      .addCase(getHomeworkChunkAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          getHomeworkChunkStatus: ReduxStatus.pending,
          homeworkChunk: null,
        };
      })
      .addCase(getHomeworkChunkAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          getHomeworkChunkStatus: ReduxStatus.fulfilled,
          homeworkChunk: payload,
        };
      })
      .addCase(getHomeworkChunkAsReviewer.rejected, (state, _action) => {
        return {
          ...state,
          getHomeworkChunkStatus: ReduxStatus.rejected,
        };
      })
      .addCase(updateHomeworkChunkAsReviewer, (state, action) => {
        return {
          ...state,
          updateHomeworkChunkStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          updateHomeworkChunkStatus: ReduxStatus.idle,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const reviewerAppHomeworkChunkState = (
  state: RootState,
): ReviewerAppHomeworkChunkState => state.reviewerApp.homeworkChunk;

export default reviewerAppHomeworkChunkSlice.reducer;
