/* eslint-disable camelcase */
import {
  Api,
  MaterialWorksTargetCompaniesListParams,
  MaterialWorksTargetMaterialsListParams,
  StudentGrade,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface DistributeMaterialWorkParams {
  material_id: string | undefined;
  category_id: string | undefined;
  company_ids: string[] | undefined;
  user_tag_ids: string[] | undefined;
  publication_time: string | undefined;
  expiration_time: string | undefined;
  grade?: StudentGrade;
  forbid_download?: boolean;
}

export const distributeMaterialWorkToTags = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/materialWork/distribution",
  async (data: DistributeMaterialWorkParams, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.materialWorksBatchCreateUsingTagsCreate(data);
      const resData = response.data;
      return {
        materialWork: resData,
        completed: true,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateMaterialDistributionAsSuperOwner = createAsyncThunk<
  any,
  { id: string; distribution_time?: string; expiration_time?: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/materialWork/distribution/update",
  async (data, { rejectWithValue }) => {
    const { id, distribution_time, expiration_time } = data;
    try {
      const response =
        await api.superOwner.materialDistributionHistoryGroupsUpdate(id, {
          distribution_time,
          expiration_time,
        });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchDistributeMaterialWorkInformation = createAsyncThunk<
  any,
  MaterialWorksTargetCompaniesListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/materialWork/distributionInformation/fetch",
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.materialWorksTargetCompaniesList(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchDistributeMaterialWorkInformationByCategory =
  createAsyncThunk<
    any,
    MaterialWorksTargetMaterialsListParams,
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    "super_owner/materialWork/distributionInformation/fetchByCategory",
    async (data, { rejectWithValue }) => {
      try {
        const response =
          await api.superOwner.materialWorksTargetMaterialsList(data);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    },
  );

export const resetState = createAction(
  "super_owner/materialWorkDistribution/resetState",
);

export const resetMaterialWorkTargetState = createAction(
  "super_owner/materialWork/distributionInformation/resetState",
);
