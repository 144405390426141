/* eslint-disable camelcase */
import {
  Api,
  DeletePackagingAsSuperOwnerParams,
  GetLearningTemplatePackagesAsSuperOwnerParams,
  PutPackagingAsSuperOwnerParams,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import {
  EditTemplatePackageParams,
  PackageParams,
  PostTemplatePackageParams,
  UpdatePackageParams,
} from "./types";

const api = new Api();

export const fetchLearningTemplatePackagesAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/learning_template_package/fetch",
  async (
    params: GetLearningTemplatePackagesAsSuperOwnerParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.getLearningTemplatePackagesAsSuperOwner(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchOneLearningTemplatePackageAsSuperOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/learningTemplatePackage/fetchOne",
  async (id, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.getLearningTemplatePackageAsSuperOwner(id);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postLearningTemplatePackageAsSuperOwner = createAsyncThunk<
  any,
  PostTemplatePackageParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/learningTemplatePackage/post",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.postLearningTemplatePackageAsSuperOwner(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editLearningTemplatePackageAsSuperOwner = createAsyncThunk<
  any,
  EditTemplatePackageParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/learningTemplatePackage/edit",
  async (params, { rejectWithValue }) => {
    const { id, data } = params;
    try {
      const response =
        await api.superOwner.putLearningTemplatePackageAsSuperOwner(id, data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteLearningTemplatePackageAsSuperOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/learningTemplatePackage/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.deleteLearningTemplatePackagAsSuperOwner(id);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postPackagingAsSuperOwner = createAsyncThunk<
  any,
  PackageParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/package/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.postPackagingAsSuperOwner(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deletePackagingAsSuperOwner = createAsyncThunk<
  any,
  DeletePackagingAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/package/delete", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.deletePackagingAsSuperOwner(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updatePackagingAsSuperOwner = createAsyncThunk<
  any,
  UpdatePackageParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("super_owner/package/update", async (params, { rejectWithValue }) => {
  const { data, currentPackage } = params;
  const packageToDelete = {
    package_id: data.package_id,
    packageable_type: currentPackage.packageable_type,
    packageable_id: currentPackage.packageable_id,
  };
  try {
    await api.superOwner.deletePackagingAsSuperOwner(packageToDelete);
    const response = await api.superOwner.postPackagingAsSuperOwner(data);

    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface PackageSequenceParamsType {
  query: PutPackagingAsSuperOwnerParams;
  data: { sequence: number };
}

export const putPackagingSequenceAsSuperOwner = createAsyncThunk<
  any,
  PackageSequenceParamsType,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/packaging/update_sequence",
  async (params, { rejectWithValue }) => {
    const { query, data } = params;
    try {
      const response = await api.superOwner.putPackagingAsSuperOwner(
        query,
        data,
      );

      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetTemplatePackageState = createAction(
  "super_owner/learningTemplatePackage/resetState",
);

export const addPackagesSuccess = createAction(
  "super_owner/learningTemplatePackage/addPackagesSuccess",
);
export const deletePackagesSuccess = createAction(
  "super_owner/learningTemplatePackage/deletePackagesSuccess",
);

export const packageStatus = createAction(
  "teacherApp/packaging_status",
  (arg) => {
    return {
      payload: arg,
    };
  },
);
