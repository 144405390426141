import Tooltip from "@mui/material/Tooltip";
import { useUpdate } from "ahooks";
import React, { memo, useContext } from "react";

import useDOMParse from "../useDOMParse";
import { BubbleMenuContext } from "./BubbleMenuContext";
import { useStyles } from "./styles";

export const TableGroup = (): JSX.Element => {
  const { editor, isSubMenuOpen } = useContext(BubbleMenuContext);
  const classes = useStyles();
  const { canInsertTable } = useDOMParse();
  const update = useUpdate();
  const disabled = isSubMenuOpen();

  const handleTableInsert = () => {
    if (canInsertTable(editor)) {
      const insertPosition = editor.state.selection.$head.pos;
      editor
        .chain()
        .focus(insertPosition ?? false)
        .insertTable({
          rows: 3,
          cols: 2,
          withHeaderRow: false,
        })
        .run();
    }
    update();
  };

  let mainContent = (
    <Tooltip title="表の挿入は１ブロック１つまでです。" placement="top">
      <button type="button" className={classes.default} disabled={disabled}>
        テーブル
      </button>
    </Tooltip>
  );
  if (canInsertTable(editor)) {
    mainContent = (
      <button
        type="button"
        className={classes.default}
        disabled={disabled}
        onClick={handleTableInsert}
      >
        テーブル
      </button>
    );
  }
  return <>{mainContent}</>;
};

export default memo(TableGroup);
