/* eslint-disable @typescript-eslint/ban-types */
import V2IconButton from "@components/UIv2/atoms/buttons/V2IconButton";
import { useAuth } from "@contexts/Auth";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import errorWithMessage from "@lib/rtk/error-utils";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { InterviewRequestListBase2 as InterviewRequestListAsOwner } from "@root/endpoints/TimeTactApi/ownerApi";
import { usePutInterviewMessageAsStudentMutation } from "@root/endpoints/TimeTactApi/studentApi";
import {
  InterviewRequestListBase2 as InterviewRequestListAsTeacher,
  usePutInterviewMessageAsTeacherMutation,
} from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";

import { EditMessageForm } from "..";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    buttonsContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    buttonsWrapper: {
      width: "fit-content",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexShrink: 0,
      gap: theme.spacing(1),
      borderRadius: "4px",
      padding: "1px 8px",
      background: theme.palette.common.white,
      boxShadow: `-1px -1px 4px ${theme.palette.customColor.boardGrey},-10px -10px 9px ${theme.palette.common.white}`,
      border: `1px solid ${theme.palette.customColor.boardGrey}`,
      position: "absolute",
      bottom: "-16px",
      zIndex: 100,
      right: 0,
    },
    editIconsStyle: {
      color: theme.palette.tertiary.mainDark,
      fontSize: 25,
    },
    cancelIconsStyle: {
      color: theme.palette.tertiary.mainDark,
      fontSize: 30,
    },
    loaderContainer: {
      width: "80px",
      height: "65px",
    },
    loaderIcon: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  }),
);

interface Props {
  requestId: string;
  currentMessage: InterviewRequestListAsOwner | InterviewRequestListAsTeacher;
  handleIsEditing: () => void;
  refetchMessageList: () => void;
  handleLeave: () => void;
}

const EditMessageInput: React.FC<Props> = ({
  requestId,
  currentMessage,
  handleIsEditing,
  refetchMessageList,
  handleLeave,
}) => {
  const classes = useStyles();
  const { isCurrentRoles } = useAuth();
  const { control, handleSubmit } = useFormContext<EditMessageForm>();
  const { t } = useTranslation("university");
  const { actions } = useEnqueueToast();

  const teacherEditMessageMutation = usePutInterviewMessageAsTeacherMutation();
  const studentEditMessageMutation = usePutInterviewMessageAsStudentMutation();

  const getEditOwnMessageMutation = () => {
    if (isCurrentRoles.isTeacher) {
      return teacherEditMessageMutation;
    }
    if (isCurrentRoles.isStudent) {
      return studentEditMessageMutation;
    }
    throw new Error("Invalid query");
  };
  const [editOwnMessage, { isLoading: isEditing }] =
    getEditOwnMessageMutation();

  const onSubmit = (data: EditMessageForm) => {
    if (data.messageToUpdate === currentMessage.message) return;
    editOwnMessage({
      requestId,
      id: currentMessage.id,
      body: {
        message: data.messageToUpdate,
      },
    })
      .then(() => {
        handleLeave();
        handleIsEditing();
        refetchMessageList();
      })
      .catch((error: unknown) => actions.showError(errorWithMessage(error)));
  };

  return (
    <form className={classes.root}>
      <Controller
        control={control}
        name="messageToUpdate"
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <TextField
            disabled={isEditing}
            fullWidth
            multiline
            minRows={4}
            maxRows={Infinity}
            inputRef={ref}
            variant="outlined"
            placeholder={t("messagePlaceholder")}
            data-cy="edit-interview-chat-input"
            onChange={onChange}
            value={value}
            error={!!error}
            helperText={
              error
                ? error.message
                : t("messageValidation", { number: "10,000" })
            }
          />
        )}
        rules={{
          required: t("enterAMessageToContinue"),
          maxLength: {
            value: 10000,
            message: t("messageValidation", { number: "10,000" }),
          },
        }}
      />
      <Box className={classes.buttonsContainer}>
        <Box className={classes.buttonsWrapper}>
          <V2IconButton
            disabled={isEditing}
            className={classes.cancelIconsStyle}
            size="small"
            onClick={handleIsEditing}
            title={t("cancel")}
          >
            <MdOutlineCancel />
          </V2IconButton>

          <V2IconButton
            disabled={isEditing}
            className={classes.editIconsStyle}
            size="small"
            onClick={handleSubmit(onSubmit)}
            title={t("send")}
            data-cy="edit-interview-chat-submit"
          >
            <BsFillCheckCircleFill />
          </V2IconButton>
        </Box>
      </Box>
    </form>
  );
};

export default EditMessageInput;
