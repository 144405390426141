import {
  batchUpdateFeedbackComment,
  fetchFeedbackTemplatesAsAdmin,
  resetFormState,
} from "@actions/adminApp/feedbackComment";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { FeedbackTemplateBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

interface FeedbackCommentsState {
  fetch: ReduxStatusType;
  feedbackComments: FeedbackTemplateBase[];
  fetchError: string | null;
  totalCount: number;
  update: ReduxStatusType;
  updateError: string | null;
}

const initialState: FeedbackCommentsState = {
  fetch: ReduxStatus.idle,
  feedbackComments: [],
  fetchError: null,
  totalCount: 0,
  update: ReduxStatus.idle,
  updateError: null,
};

const feedbackCommentSlice = createSlice({
  name: "Admin/NotificationSent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedbackTemplatesAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetch: ReduxStatus.pending,
        };
      })
      .addCase(fetchFeedbackTemplatesAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetch: ReduxStatus.fulfilled,
          feedbackComments: payload.feedback_templates,
        };
      })
      .addCase(fetchFeedbackTemplatesAsAdmin.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetch: ReduxStatus.rejected,
          fetchError: getErrorMessage(payload.error),
        };
      })
      .addCase(batchUpdateFeedbackComment.pending, (state, _action) => {
        return {
          ...state,
          update: ReduxStatus.pending,
        };
      })
      .addCase(batchUpdateFeedbackComment.rejected, (state, _action) => {
        return {
          ...state,
          update: ReduxStatus.rejected,
        };
      })
      .addCase(batchUpdateFeedbackComment.fulfilled, (state, _action) => {
        return {
          ...state,
          update: ReduxStatus.fulfilled,
        };
      })
      .addCase(resetFormState, (state, _action) => {
        return {
          ...state,
          update: ReduxStatus.idle,
          fetch: ReduxStatus.idle,
        };
      });
  },
});

export const adminAppFeedbackCommentsState = (
  state: RootState,
): FeedbackCommentsState => state.adminApp.feedbackComment;

export default feedbackCommentSlice.reducer;
