/* eslint-disable @typescript-eslint/ban-types */
import { FormSectionWrap } from "@components/UI/atoms/Forms/FormComponents";
import V2StyledFormTitle from "@components/UIv2/atoms/V2StyledFormTitle";
import { MaxChars } from "@constants/Form/input-rules";
import { TextField } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValuesTypes } from "..";

type Props = {
  control: Control<FormValuesTypes, object>;
};

const Description: React.FC<Props> = ({ control }) => {
  const { t } = useTranslation("university");
  return (
    <FormSectionWrap>
      <V2StyledFormTitle isRequired title={t("impressions")} />
      <Controller
        control={control}
        name="description"
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            margin="dense"
            rows={6}
            multiline
            variant="outlined"
            placeholder="感想/意見を記入する"
            fullWidth
            value={value}
            error={!!error}
            onChange={onChange}
            inputProps={{
              maxLength: MaxChars.text,
            }}
          />
        )}
        rules={{ required: true }}
      />
    </FormSectionWrap>
  );
};

export default Description;
