import {
  packageStatus,
  putPackagingSequenceAsSuperOwner,
} from "@actions/superOwnerApp/templatePackages/learningTemplatePackage";
import LinkifyMultipleText from "@components/UI/atoms/LinkifyMultipleText";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import { ReduxStatus } from "@constants/redux";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import {
  LearningTemplatePackageBase,
  PackagingAsLearningTemplatePackageBase,
  PackagingPackageableType,
} from "@lib/Api";
import {
  Box,
  Collapse,
  Container,
  Divider,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { superOwnerAppLearningTemplatePackagesState } from "@reducers/superOwnerApp/templatePackages/learningTemplatePackage";
import { useGetPackagingsAsSuperOwnerQuery } from "@root/endpoints/TimeTactApi/superOwnerApi";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import { reorder } from "@utils/reorder/reorder";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import Package from "../Package";
import ActionButtons from "./ActionButtons";

type Props = {
  openModule: boolean;
  handleModalOpen: (
    modalStatus: ModalStatus,
    learningTemplateInfos?: LearningTemplatePackageBase,
    packageInfos?: PackagingAsLearningTemplatePackageBase,
    packagesArrayInfos?: PackagingAsLearningTemplatePackageBase[],
  ) => void;
  learningTemplate: LearningTemplatePackageBase;
};

type AccordionContentsReturn = {
  render: JSX.Element;
  packagesArray: PackagingAsLearningTemplatePackageBase[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    packagesWrapper: {
      backgroundColor: "rgba(0, 130, 103, 0.05)",
      marginTop: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      width: "auto",
      borderRadius: 8,
    },
    dividerRoot: {
      backgroundColor: theme.palette.learningTemplatePackage.main,
      margin: theme.spacing(2, 0),
      height: "2px",
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
  }),
);

const useAccordionContents = ({
  openModule,
  learningTemplate,
  handleModalOpen,
}: Props): AccordionContentsReturn => {
  const classes = useStyles();
  const learningTemplateCache = useAppSelector(
    superOwnerAppLearningTemplatePackagesState,
  );
  const {
    updated,
    success: postedPackage,
    deletedPackage,
    deleteSuccess,
  } = learningTemplateCache;

  const packagingsQuery = useGetPackagingsAsSuperOwnerQuery(
    {
      perPage: 100,
      packageId: learningTemplate.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !openModule,
    },
  );
  const { data, isSuccess, refetch, isLoading } = packagingsQuery;
  const dispatch = useAppDispatch();
  const [sortDisabled, setSortDisabled] = useState<boolean>(true);
  const [reorderPackageList, setReorderPackageList] = useState(
    isSuccess ? data?.packagings ?? [] : [],
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const newListSequence = reorder(
      reorderPackageList,
      result.source.index,
      result.destination.index,
    );

    setReorderPackageList(newListSequence);
  };

  const handleSaveSortedPackageList = () => {
    const promiseAll: Promise<ReturnType<typeof useAppDispatch>>[] = [];
    const updateRequests = reorderPackageList.map((item, idx: number) => {
      const packageableType = item?.material_homework_template_package
        ? PackagingPackageableType.Package
        : item.packageable_type;
      const packageableId =
        item?.material_homework_template_package?.id ?? item.packageable_id;

      return {
        query: {
          package_id: learningTemplate.id,
          packageable_type: packageableType,
          packageable_id: packageableId,
        },
        data: { sequence: idx },
      };
    });

    updateRequests.forEach((request) => {
      promiseAll.push(dispatch(putPackagingSequenceAsSuperOwner(request)));
    });
    Promise.allSettled(promiseAll).then((resolves) => {
      const successResolve: PromiseSettledResult<any> | undefined =
        resolves.find(
          (resolve: any) =>
            resolve.value.meta.requestStatus === ReduxStatus.fulfilled,
        );
      if (successResolve) {
        dispatch(
          packageStatus({
            message: "順番が更新されました。",
            status: ReduxStatus.fulfilled,
          }),
        );
      } else {
        const failedResolve: PromiseSettledResult<any> | undefined =
          resolves.find(
            (resolve: any) =>
              resolve.value.meta.requestStatus === ReduxStatus.rejected,
          );
        if (failedResolve) {
          dispatch(
            packageStatus({
              message: "順番が更新されませんでした。",
              status: ReduxStatus.rejected,
            }),
          );
        }
      }
    });
    setSortDisabled(true);
  };

  const handleActivateSort = () => {
    if (sortDisabled) {
      setSortDisabled(false);
    } else {
      handleSaveSortedPackageList();
    }
  };

  useEffect(() => {
    setReorderPackageList(data?.packagings ?? []);
  }, [isSuccess, data]);

  useEffect(() => {
    if (postedPackage || updated || (deletedPackage && deleteSuccess)) {
      refetch();
    }
  }, [postedPackage, updated, deletedPackage, deleteSuccess]);

  const packagesArray = data?.packagings ?? [];
  return {
    packagesArray,
    render: (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={openModule} timeout="auto" unmountOnExit>
            <Container className={classes.packagesWrapper}>
              {learningTemplate.description ? (
                <>
                  <LinkifyMultipleText text={learningTemplate.description} />
                  <Divider
                    sx={{ opacity: "0.6" }}
                    classes={{ root: classes.dividerRoot }}
                  />
                </>
              ) : null}
              <Box>
                {isLoading ? (
                  <Box style={{ width: "100%", height: "250" }}>
                    <CircularLoading />
                  </Box>
                ) : (
                  <>
                    {reorderPackageList.length ? (
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="packageList">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {reorderPackageList.map(
                                (templatePackage, idx) => (
                                  <Package
                                    key={templatePackage.id}
                                    idx={idx}
                                    sortDisabled={sortDisabled}
                                    templatePackageId={
                                      templatePackage
                                        ?.material_homework_template_package
                                        ?.id ?? templatePackage.id
                                    }
                                    handleModalOpen={handleModalOpen}
                                    templatePackage={templatePackage}
                                    templatePackageArray={
                                      data?.packagings || []
                                    }
                                    learningTemplate={learningTemplate}
                                    currentPage={1}
                                  />
                                ),
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <Box
                        style={{
                          margin: "16px 0",
                        }}
                      >
                        <Typography>コンテンツはありません。</Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
              <Divider className={classes.divider} />
              <ActionButtons
                handleModalOpen={handleModalOpen}
                setSortDisabled={setSortDisabled}
                sortDisabled={sortDisabled}
                packagings={data?.packagings || []}
                learningTemplate={learningTemplate}
                handleActivateSort={handleActivateSort}
              />
            </Container>
          </Collapse>
        </TableCell>
      </TableRow>
    ),
  };
};

export default useAccordionContents;
