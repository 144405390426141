/* eslint-disable camelcase */
import { Company } from "@lib/Api";
import { removeMany } from "@lib/collection";
import { createSlice } from "@reduxjs/toolkit";

import {
  batchCreate,
  batchDelete,
  fetchAssignableCompaniesAsSuperOwner,
  fetchRevieweeCompaniesAsSuperOwner,
  resetDeleteActionState,
  resetState,
} from "../../actions/superOwnerApp/revieweeCompany";
import { RootState } from "../../store";

export interface SuperOwnerRevieweeCompanyState {
  fetching: boolean;
  fetchingAssignable: boolean;
  fetched: boolean;
  fetchedAssignable: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  creating: boolean;
  created: boolean;
  deleting: boolean;
  deleted: boolean;
  assignableCompanies: Company[];
  revieweeCompanies: Company[];
  revieweeCompany: Company | null;
  totalCount: number;
  totalCountAssignable: number;
  error: any;
  changeError: any;
  fetchError: string | null;
  fetchOneError: string | null;
  fetchErrorAssignable: string | null;
  batchChangeError: string[] | null;
  batchCreateCompanies: Company[];
}

export const initialState: SuperOwnerRevieweeCompanyState = {
  fetching: false,
  fetchingOne: false,
  fetchingAssignable: false,
  fetched: false,
  fetchedOne: false,
  fetchedAssignable: false,
  fetchError: null,
  fetchOneError: null,
  fetchErrorAssignable: null,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  revieweeCompanies: [],
  assignableCompanies: [],
  revieweeCompany: null,
  totalCount: 0,
  totalCountAssignable: 0,
  error: null,
  changeError: null,
  batchChangeError: null,
  batchCreateCompanies: [],
};

export const superOwnerAppRevieweeCompanySlice = createSlice({
  name: "SuperOwnerApp/RevieweeCompany",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevieweeCompaniesAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          fetched: false,
          fetching: true,
          error: null,
        };
      })
      .addCase(
        fetchRevieweeCompaniesAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetched: true,
            fetching: false,
            fetchError: null,
            error: null,
            revieweeCompanies: payload.companies,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(fetchRevieweeCompaniesAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetched: false,
          fetching: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(
        fetchAssignableCompaniesAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingAssignable: true,
            fetchedAssignable: false,
          };
        },
      )
      .addCase(
        fetchAssignableCompaniesAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingAssignable: false,
            fetchedAssignable: true,
            assignableCompanies: payload.companies,
            totalCountAssignable: payload.total_count,
          };
        },
      )
      .addCase(
        fetchAssignableCompaniesAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingAssignable: false,
            fetchedAssignable: true,
            fetchErrorAssignable: action.error.message as string,
          };
        },
      )
      .addCase(batchCreate.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          batchChangeError: null,
        };
      })
      .addCase(batchCreate.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          batchChangeError: [action.payload.error],
        };
      })
      .addCase(batchCreate.fulfilled, (state, action) => {
        const { payload } = action;
        const newRevieweeCompanies = [
          ...payload.reviewee_companies,
          ...state.revieweeCompanies,
        ];
        const newAssignableCompanies = removeMany(
          state.assignableCompanies,
          payload.reviewee_companies.map((c: any) => c.id),
        );

        return {
          ...state,
          creating: false,
          created: true,
          batchChangeError: payload.errors,
          batchCreateCompanies: payload.reviewee_companies,
          error: "",
          revieweeCompanies: newRevieweeCompanies,
          assignableCompanies: newAssignableCompanies,
          totalCount: state.totalCount + payload.reviewee_companies.length,
        };
      })
      .addCase(batchDelete.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          batchChangeError: null,
        };
      })
      .addCase(batchDelete.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          batchChangeError: action.payload,
        };
      })
      .addCase(batchDelete.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          deleting: false,
          deleted: true,
          batchChangeError: null,
          error: "",
          revieweeCompanies: removeMany(
            state.revieweeCompanies,
            payload.removed_reviewee_ids,
          ),
          totalCount: state.totalCount - payload.removed_reviewee_ids.length,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: null,
          creating: false,
          created: false,
          postError: null,
          updating: false,
          updated: false,
          changeError: null,
          batchChangeError: null,
          batchCreateCompanie: [],
        };
      })
      .addCase(resetDeleteActionState, (state) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          batchChangeError: null,
          batchCreateCompanie: [],
        };
      });
  },
});

export const superOwnerAppRevieweeCompanyState = (
  state: RootState,
): SuperOwnerRevieweeCompanyState => state.superOwnerApp.revieweeCompany;

export default superOwnerAppRevieweeCompanySlice.reducer;
