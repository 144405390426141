import loader from "@assets/images/gif/TimeTact_Loading.gif";
import Box from "@mui/material/Box";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import React from "react";

type Props = {
  height?: any;
};

const CircularProgressWithLabel = (props: CircularProgressProps) => {
  return (
    <Box position="relative" display="inline-flex">
      <img src={loader} alt="Loading..." height={300} />
    </Box>
  );
};

const CircularLoading: React.FC<Props> = ({ height }) => {
  return (
    <Box
      height={height}
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <CircularProgressWithLabel />
      </Box>
    </Box>
  );
};

export default CircularLoading;
CircularLoading.defaultProps = {
  height: "80vh",
};
