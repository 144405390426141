import { objectHas } from "@lib/object-utils";
import { ClassNameMap } from "@mui/styles";
import { isEmpty } from "lodash";

import {
  EditorOneBlockJSON,
  EditorOneBlockWithSVEditorBase,
  EditorTwoBlockJSON,
  EditorTwoBlockWithSVEditorBase,
} from "./types";
import {
  DeviceType,
  getOneBlockStyles,
  getTwoBlockStyles,
} from "./useSVEditorStyles";

export const checkIsBlockContentEmpty = (htmlStr: string) => {
  // Create a virtual div element
  const div = document.createElement("div");
  div.innerHTML = htmlStr.trim();
  // Get all elements inside the div
  const tags = div.querySelectorAll("*");
  // Check if there are no tags inside the div
  if (tags.length === 0) {
    return isEmpty(div.textContent?.trim());
  }
  // Check if the innerHTML of the tag (or textContent for non-tag nodes) is empty
  return Array.from(tags).every((tag) => {
    const content =
      tag.nodeType === Node.TEXT_NODE
        ? tag.textContent?.trim()
        : tag.innerHTML.trim();
    return isEmpty(content);
  });
};

// 1列ブロックの場合は、親ブロックのみのidを返す
// +parentと末尾についているidは、複数あるブロックのうち、縦のどのブロックかを特定するのに利用しているid
export const generateIdForOneBlock = (): { id: string } => {
  const time = new Date().getTime().toString();
  const id = `editor-block+${time}+parent`;
  return { id };
};

// 2列ブロックの場合は、親ブロックの中に子ブロックが2つあるイメージ
// 左右の子ブロック用のidと親ブロック用のidを返す
// +childと末尾についているidは、2列ブロックのうち、左右のどちらのブロックを特定するのかに利用している
// +parentで縦のどの位置のかを特定し、+childで左右のどちらかを特定する
export const generateIdForTwoBlock = (): {
  id: string;
  id1: string;
  id2: string;
} => {
  const time = new Date().getTime().toString();
  const id = `editor-block+${time}+parent`;
  const id1 = `editor-block+${time}+child1`;
  const id2 = `editor-block+${time}+child2`;

  return { id, id1, id2 };
};

// 指定されたidがeditorBlocks変数のどこと対応しているかを調べる
// 指定されたidを持つオブジェクトがeditorBlock変数のどのindexに格納されているかを調べる
export const findIndexForEditorBlocks = (
  id: string, // idは`editor-block+${time}+parent`,`editor-block+${time}+child1`,`editor-block+${time}+child2`のいずれか
  editorBlocks: (
    | EditorOneBlockWithSVEditorBase
    | EditorTwoBlockWithSVEditorBase
  )[],
): { parentIndex: number; childIndex: number } => {
  let parentIndex = -1;
  let childIndex = -1;
  // idが親ブロックのidの場合
  if (id.indexOf("parent") !== -1) {
    // 親ブロックのidからeditorBlocks変数のどこのindexにあるかを調べる
    parentIndex = editorBlocks.findIndex(
      (description) => description.id === id,
    );
  }

  // idが子ブロックのidの場合
  if (id.indexOf("child") !== -1) {
    // 子ブロックのidから親ブロックのidを調べる
    const parentId = `${id.substring(0, id.indexOf("child"))}parent`;
    // 調べた親ブロックのidからeditorBlocks変数のどこのindexにあるかを調べる
    parentIndex = editorBlocks.findIndex(
      (description) => description.id === parentId,
    );
    if (parentIndex !== -1) {
      // 調べた親ブロックのidから子ブロックの中身を取り出す
      const children = (
        editorBlocks[parentIndex] as EditorTwoBlockWithSVEditorBase
      ).content;
      // 取り出した子ブロックから左右のどちらの子ブロックかを調べる
      childIndex = children.findIndex((description) => description.id === id);
    }
  }
  return {
    parentIndex,
    childIndex,
  };
};

// SVEditorのオブジェクトデータを文字列として保存できる形式に変換
// この関数で変換した値をレコードに保存する
// (SVEditorのオブジェクトデータを含まない形に変換する)
export const editorBlocksToJSON = (
  editorBlocks: (
    | EditorOneBlockWithSVEditorBase
    | EditorTwoBlockWithSVEditorBase
  )[],
): string => {
  const saveData = editorBlocks.map((description) => {
    if (Array.isArray(description.content)) {
      return {
        id: description.id,
        content: description.content.map((item) => {
          const { id, value } = item;
          return { id, value };
        }),
      };
    }
    const { id, value } = description;

    return {
      id,
      value,
    };
  });

  return JSON.stringify(saveData);
};

// SVEditorの値をHTMLに変換する
// SVEditorで編集した値を表示する際に利用する
export const editorBlocksToHTML = (
  editorBlocks: (EditorOneBlockJSON | EditorTwoBlockJSON)[],
  classes: ClassNameMap,
  deviceType?: DeviceType,
): string => {
  const oneBlockDivStyle = getOneBlockStyles();

  const { twoBlockEachDivStyle, twoBlockWrapperStyle } =
    getTwoBlockStyles(deviceType);

  if (!Array.isArray(editorBlocks)) {
    return editorBlocks;
  }
  const html = editorBlocks
    .map((editorBlock) => {
      if (
        objectHas(editorBlock, "content") &&
        Array.isArray((editorBlock as EditorTwoBlockJSON).content)
      ) {
        const childHtml = (editorBlock as EditorTwoBlockJSON).content
          .map(
            (item) =>
              `<div id="${item.id}" style="${twoBlockEachDivStyle}">${item.value}</div>`,
          )
          .join("");

        return `<div id="${editorBlock.id}" style="${twoBlockWrapperStyle}">${childHtml}</div>`;
      }
      return `<div id="${editorBlock.id}" style="${oneBlockDivStyle}">${
        (editorBlock as EditorOneBlockJSON).value as string
      }</div>`;
    })
    .join("");

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const divList = doc.querySelectorAll("div");
  if (divList?.length) {
    divList.forEach((div) => {
      if (objectHas(div.attributes, "data-placeholder")) {
        div.removeAttribute("data-placeholder");
      }
    });
  }

  const tableTd = doc.querySelectorAll("table-wrapper tr:first-child td");
  if (tableTd && tableTd.length > 0) {
    tableTd.forEach((td, index) => {
      if (objectHas(td.attributes, "colwidth")) {
        (tableTd[index] as any).style.width = td.getAttribute("colwidth");
      }
    });
  }

  return `<div class="${classes.ProseMirror}">${doc.body.innerHTML}</div>`;
};

// HTMLを元にSVEditorにセットするためのデータを作成する
// SVEtitorのオブジェクトデータは含まない
export const HTMLToEditorBlocksJSONArray = (
  contentHTML: string,
): (EditorOneBlockJSON | EditorTwoBlockJSON)[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(contentHTML, "text/html");
  const divs = doc.querySelectorAll("div[id$='parent']");

  const editorBlockJSONArray: (EditorOneBlockJSON | EditorTwoBlockJSON)[] = [];

  if (divs.length === 0) {
    return editorBlockJSONArray;
  }

  divs.forEach((div) => {
    if (!div.id.includes("editor-block")) {
      return;
    }
    const divHTML = parser.parseFromString(div.innerHTML, "text/html");
    const divChildren = divHTML.querySelectorAll("div[id*='child']");

    if (divChildren.length === 0) {
      editorBlockJSONArray.push({ id: div.id, value: div.innerHTML });
    } else {
      editorBlockJSONArray.push({
        id: div.id,
        content: [
          { id: divChildren[0].id, value: divChildren[0].innerHTML },
          { id: divChildren[1].id, value: divChildren[1].innerHTML },
        ],
      });
    }
  });

  return editorBlockJSONArray;
};
