import {
  EditorOneBlockJSON,
  EditorTwoBlockJSON,
  SVEditorFormValue,
} from "@components/UI/molecules/SVEditor/types";
import useSVEditor from "@components/UI/molecules/SVEditor/useSVEditor";
import V2StyledSelect from "@components/UIv2/atoms/V2StyledSelect";
import { getParsedJSON, safeString } from "@lib/string-utils";
import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Path, useFormContext } from "react-hook-form";

import useSVEditorStyles, { Device,DeviceType } from "../useSVEditorStyles";
import HTMLPreview from "./HTMLPreview";

export const SVEditorPreview = <T extends SVEditorFormValue>({
  FormValueName,
}: {
  FormValueName: Path<T>;
}): JSX.Element => {
  const { watch, setValue } = useFormContext<T>();
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [deviceType, setDeviceType] = useState<DeviceType | undefined>(
    undefined,
  );

  const { restoreSVEditorData, EditorBlocksKey } =
    useSVEditor<T>(FormValueName);
  const { SVEditorTheme } = useSVEditorStyles();
  const description = safeString(watch(FormValueName));

  useEffect(() => {
    const parsedDescription:
      | (EditorOneBlockJSON | EditorTwoBlockJSON)[]
      | false = getParsedJSON(description);

    if (parsedDescription !== false) {
      setValue(EditorBlocksKey, restoreSVEditorData(parsedDescription) as any);
    }
  }, [description]);

  const previewSelectorItems = [
    "画面プレビュー",
    "コンピュータ(デフォルト)",
    "タブレット",
    "スマートフォン",
  ];

  return (
    <>
      <V2StyledSelect
        selectItems={previewSelectorItems}
        selectorValue={previewSelectorItems[0]}
        handleSelectorChange={(e) => {
          setPreviewModalOpen(true);
          if (e.target.value === previewSelectorItems[1]) {
            setDeviceType(Device.COMPUTER);
          } else if (e.target.value === previewSelectorItems[2]) {
            setDeviceType(Device.TABLET);
          } else if (e.target.value === previewSelectorItems[3]) {
            setDeviceType(Device.SMARTPHONE);
          } else {
            setDeviceType(Device.COMPUTER);
          }
        }}
        customStyles={{ margin: "0 0 8px 8px" }}
      />

      <Modal
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Box
          border={`2px solid ${SVEditorTheme.palette.primary.main}`}
          borderColor="primary"
          borderRadius="8px"
          bgcolor="background.paper"
          style={{
            overflow: "scroll",
          }}
        >
          <HTMLPreview deviceType={deviceType} description={description} />
        </Box>
      </Modal>
    </>
  );
};

export default SVEditorPreview;
