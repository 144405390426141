import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

const MicrosoftLogin: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const accessToken = query.get("code");
  const error = query.get("error");

  useEffect(() => {
    if (accessToken) {
      history.push({ pathname: "/login", state: { accessToken } });
    }
    if (error) {
      history.push({ pathname: "/login", state: { errorMessageMS: error } });
    }
  }, [accessToken, error]);

  return <CircularLoading />;
};

export default MicrosoftLogin;
