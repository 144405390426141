import MainButton from "@components/UI/atoms/MainButton/MainButton";
import React from "react";

export type Props = React.ComponentProps<typeof MainButton>;

const V2Button: React.FunctionComponent<Props> = (props: Props) => (
  <MainButton {...props} />
);

export default V2Button;
