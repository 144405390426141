/* eslint-disable camelcase */
import { Api, FetchSponsorLecturesAsContentProviderParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblSponsorProjectAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/sponsor/projects/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.fetchOneSponsorProjectAsContentProvider(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchLecturesByProjectAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/pbl/sponsor/projects/fetchLectures",
  async (
    params: FetchSponsorLecturesAsContentProviderParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.contentProvider.fetchSponsorLecturesAsContentProvider(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetOne = createAction(
  "contentProvider/pbl/sponsor/projects/resetOne",
);
