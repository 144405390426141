import { MenuItem,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

const CustomMenuItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.customColor.text,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      minHeight: 40,
      minWidth: 120, // If the menu items container has a weird movement when you hover
      // Add a new props to customize this minWidth to fix the issue.
      whiteSpace: "break-spaces",
      textOverflow: "ellipsis",
      overflow: "hidden",
      "&:hover": {
        backgroundColor: theme.palette.quaternary.lightGreen,
        transition: "0s",
        borderRadius: 8,
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        boxShadow: `inset 0 0 0 1px ${theme.palette.tertiary.mainDark}`,
      },
      "&.Mui-selected:hover": {
        backgroundColor: theme.palette.quaternary.lightGreen,
        borderRadius: 8,
        boxShadow: `inset 0 0 0 1px ${theme.palette.tertiary.mainDark}`,
      },
    },
  }),
)(MenuItem);

export default CustomMenuItem;
