import {
  createPblBoardContentsAsOwner,
  createPblBoardInfoAsOwner,
  createPblBoardItemAsOwner,
  deletePblBoardItemAsOwner,
  editPblBoardContentsAsOwner,
  fetchArticleWordCloudAsOwner,
  fetchPblBoardInfoAsOwner,
  fetPblBoardArticlesAsOwner,
  load,
  putPblBoardInfoAsOwner,
  rejected,
  resetBoardState,
  success,
} from "@actions/ownerApp/pblBoard/boardSettings";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus,ReduxStatusType } from "@root/constants/redux";
import {
  PblBoardArticleBase,
  PblBoardInfoBase,
  PblBoardItemContentTypeJa,
  WordCloudWord,
} from "@root/lib/Api";
import { RootState } from "@store/store";

export interface OwnerAppBoardSettingsState {
  fetchBoardInfos: ReduxStatusType;
  fetchBoardArticles: ReduxStatusType;
  fetchBoardWordCloud: ReduxStatusType;
  createBoardInfos: ReduxStatusType;
  createBoardContent: ReduxStatusType;
  createBoardItem: ReduxStatusType;
  boardItemType: PblBoardItemContentTypeJa | string;
  editBoardContent: ReduxStatusType;
  putBoardInfos: ReduxStatusType;
  deleteBoardItem: ReduxStatusType;
  boardInfos: PblBoardInfoBase;
  boardArticles: PblBoardArticleBase[];
  wordCloudData: WordCloudWord[];
  footerDeleted: boolean;
  footerDeleteError: boolean;
  loading: boolean;
  success: boolean;
  rejected: boolean;
  totalCount: number;
}

export const initialState: OwnerAppBoardSettingsState = {
  fetchBoardInfos: ReduxStatus.idle,
  fetchBoardArticles: ReduxStatus.idle,
  fetchBoardWordCloud: ReduxStatus.idle,
  createBoardInfos: ReduxStatus.idle,
  createBoardContent: ReduxStatus.idle,
  createBoardItem: ReduxStatus.idle,
  boardItemType: "",
  editBoardContent: ReduxStatus.idle,
  putBoardInfos: ReduxStatus.idle,
  deleteBoardItem: ReduxStatus.idle,
  footerDeleted: false,
  footerDeleteError: false,
  loading: false,
  success: false,
  rejected: false,
  boardInfos: {} as PblBoardInfoBase,
  boardArticles: [],
  wordCloudData: [],
  totalCount: 0,
};

const ownerAppBoardSettingsSlice = createSlice({
  name: "OwnerApp/Assign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblBoardInfoAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchBoardInfos: ReduxStatus.pending,
        };
      })
      .addCase(fetchPblBoardInfoAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchBoardInfos: ReduxStatus.rejected,
          fetchBoardInfosError: action.error,
        };
      })
      .addCase(fetchPblBoardInfoAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchBoardInfos: ReduxStatus.fulfilled,
          boardInfos: payload,
        };
      })
      .addCase(fetPblBoardArticlesAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchBoardArticles: ReduxStatus.pending,
        };
      })
      .addCase(fetPblBoardArticlesAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchBoardArticles: ReduxStatus.rejected,
          fetchBoardArticlesError: action.error,
        };
      })
      .addCase(fetPblBoardArticlesAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchBoardArticles: ReduxStatus.fulfilled,
          boardArticles: payload.articles,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchArticleWordCloudAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchBoardWordCloud: ReduxStatus.pending,
        };
      })
      .addCase(fetchArticleWordCloudAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchBoardWordCloud: ReduxStatus.rejected,
          fetchBoardWordCloudError: action.error,
        };
      })
      .addCase(fetchArticleWordCloudAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchBoardWordCloud: ReduxStatus.fulfilled,
          wordCloudData: payload.word_cloud_list,
        };
      })
      .addCase(createPblBoardInfoAsOwner.pending, (state, _action) => {
        return {
          ...state,
          createBoardInfos: ReduxStatus.pending,
        };
      })
      .addCase(createPblBoardInfoAsOwner.rejected, (state, action) => {
        return {
          ...state,
          createBoardInfos: ReduxStatus.rejected,
        };
      })
      .addCase(createPblBoardInfoAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          createBoardInfos: ReduxStatus.fulfilled,
        };
      })
      .addCase(createPblBoardContentsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          createBoardContent: ReduxStatus.pending,
        };
      })
      .addCase(createPblBoardContentsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          createBoardContent: ReduxStatus.rejected,
        };
      })
      .addCase(createPblBoardContentsAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          createBoardContent: ReduxStatus.fulfilled,
        };
      })
      .addCase(createPblBoardItemAsOwner.pending, (state, _action) => {
        return {
          ...state,
          createBoardItem: ReduxStatus.pending,
        };
      })
      .addCase(createPblBoardItemAsOwner.rejected, (state, action) => {
        return {
          ...state,
          createBoardItem: ReduxStatus.rejected,
        };
      })
      .addCase(createPblBoardItemAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          createBoardItem: ReduxStatus.fulfilled,
          boardItemType: action.payload.content_type,
        };
      })
      .addCase(editPblBoardContentsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          editBoardContent: ReduxStatus.pending,
        };
      })
      .addCase(editPblBoardContentsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          editBoardContent: ReduxStatus.rejected,
        };
      })
      .addCase(editPblBoardContentsAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          editBoardContent: ReduxStatus.fulfilled,
        };
      })
      .addCase(putPblBoardInfoAsOwner.pending, (state, _action) => {
        return {
          ...state,
          putBoardInfos: ReduxStatus.pending,
        };
      })
      .addCase(putPblBoardInfoAsOwner.rejected, (state, action) => {
        return {
          ...state,
          putBoardInfos: ReduxStatus.rejected,
          putBoardInfosError: action.error,
        };
      })
      .addCase(putPblBoardInfoAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          putBoardInfos: ReduxStatus.fulfilled,
        };
      })
      .addCase(deletePblBoardItemAsOwner.pending, (state, _action) => {
        return {
          ...state,
          deleteBoardItem: ReduxStatus.pending,
        };
      })
      .addCase(deletePblBoardItemAsOwner.rejected, (state, action) => {
        return {
          ...state,
          deleteBoardItem: ReduxStatus.rejected,
        };
      })
      .addCase(deletePblBoardItemAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          deleteBoardItem: ReduxStatus.fulfilled,
        };
      })
      .addCase(load, (state, _action) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(success, (state, _action) => {
        return {
          ...state,
          success: true,
        };
      })
      .addCase(rejected, (state, _action) => {
        return {
          ...state,
          rejected: true,
        };
      })
      .addCase(resetBoardState, (state, _action) => {
        return {
          ...state,
          fetchBoardInfos: ReduxStatus.idle,
          fetchBoardArticles: ReduxStatus.idle,
          fetchBoardWordCloud: ReduxStatus.idle,
          fetchBoardArticlesError: null,
          fetchBoardInfosError: null,
          createBoardInfos: ReduxStatus.idle,
          createBoardContent: ReduxStatus.idle,
          createBoardItem: ReduxStatus.idle,
          boardItemType: "",
          editBoardContent: ReduxStatus.idle,
          putBoardInfos: ReduxStatus.idle,
          deleteBoardItem: ReduxStatus.idle,
          loading: false,
          success: false,
          rejected: false,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppBoardSettingsState = (
  state: RootState,
): OwnerAppBoardSettingsState => state.ownerApp.boardSettings;

export default ownerAppBoardSettingsSlice.reducer;
