/* eslint-disable camelcase */
import { getCompanyLimitOption } from "@actions/ownerApp/companyOption";
import { ReduxStatus,ReduxStatusType } from "@constants/redux";
import { LimitOptionBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface OwnerAppCompanyOptionState {
  limitOption: LimitOptionBase | null;
  fetchLimitOption: ReduxStatusType;
  fetchLimitOptionError: string | null;
}

const initialState: OwnerAppCompanyOptionState = {
  limitOption: null,
  fetchLimitOption: ReduxStatus.idle,
  fetchLimitOptionError: null,
};

export const OwnerAppCompanyOptionSlice = createSlice({
  name: "OwnerApp/CompanyOption",
  initialState,
  reducers: {
    resetCompanyOptionStates: (_state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyLimitOption.pending, (state, _action) => {
        return {
          ...state,
          fetchLimitOption: ReduxStatus.pending,
          fetchLimitOptionError: null,
        };
      })
      .addCase(getCompanyLimitOption.fulfilled, (state, action) => {
        return {
          ...state,
          fetchLimitOption: ReduxStatus.fulfilled,
          limitOption: action.payload?.limit_option || null,
        };
      })
      .addCase(getCompanyLimitOption.rejected, (state, action) => {
        return {
          ...state,
          fetchLimitOption: ReduxStatus.rejected,
          fetchLimitOptionError: getErrorMessage(action.payload?.error),
        };
      });
  },
});

export const ownerAppCompanyOptionState = (
  state: RootState,
): OwnerAppCompanyOptionState => state.ownerApp.companyOption;

export const { resetCompanyOptionStates } = OwnerAppCompanyOptionSlice.actions;
export default OwnerAppCompanyOptionSlice.reducer;
