import { InquiryKind } from "@actions/types/inquiry";
import ComputerIcon from "@mui/icons-material/Computer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import React from "react";

export const inquiryMenuButtons: {
  id: InquiryKind;
  title: string;
  caption: string;
  icon: JSX.Element;
  dataCy: string;
}[] = [
  {
    id: "user_support",
    title: "ユーザサポート",
    caption: "howToUseIt",
    icon: <SupervisorAccountIcon />,
    dataCy: "user-support-button-howToUseIt",
  },
  {
    id: "content",
    title: "コンテンツに関して",
    caption: "regardingContent",
    icon: <MenuBookIcon />,
    dataCy: "user-support-button-regardingContent",
  },
  {
    id: "system",
    title: "システムに関して",
    caption: "featureRequests",
    icon: <ComputerIcon />,
    dataCy: "user-support-button-featureRequests",
  },
  {
    id: "other",
    title: "その他",
    caption: "otherInquiries",
    icon: <HelpOutlineIcon />,
    dataCy: "user-support-button-otherInquiries",
  },
];
