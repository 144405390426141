/* eslint-disable camelcase */
import { Api, FetchHomeworkTemplatesAsReviewerParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchHomeworkTemplatesAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/homework/fetch",
  async (
    params: FetchHomeworkTemplatesAsReviewerParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.reviewer.fetchHomeworkTemplatesAsReviewer(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
