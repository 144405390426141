/* eslint-disable camelcase */
import {
  deleteCustomMasterListDetailAsSuperOwner,
  fetchCustomMasterListDetailAsSuperOwner,
  getCustomMasterMapRelationshipChildAsSuperOwner,
  postCustomMasterListDetailAsSuperOwner,
  resetBatchCreateState,
  resetState,
  updateCustomMasterListDetailAsSuperOwner,
  updateCustomMasterRecordCompanyLink,
} from "@actions/superOwnerApp/customListRecord";
import { RelationshipChildItem } from "@actions/types/customList";
import { createSlice } from "@reduxjs/toolkit";
import {
  CustomMaster,
  CustomMasterMap,
  CustomMasterMapRelationshipBase,
} from "@root/lib/Api";
import { RootState } from "@store/store";

export type CustomMasterRelationships = {
  column_title: string;
};

export type CustomMasterMapDetail = {
  id: string;
  master_name: string;
  used_columns: number;
  has_school_relation: boolean;
  master_values: CustomMaster[];
  master_column_names: CustomMasterMap;
  master_values_count: number;
  relationships: CustomMasterMapRelationship[];
};

export type CustomMasterMapRelationship = {
  relationship_types_id: string;
  relation_custom_master_id: string;
  column_title: string;
  column: string;
};

export interface SuperOwnerAppCustomListRecordState {
  fetching: boolean;
  fetched: boolean;
  fetchError: string | null;
  fetchingRelationshipChild: boolean;
  fetchedRelationshipChild: boolean;
  fetchErrorRelationshipChild: string | null;
  customMasterListDetail: CustomMasterMapDetail | null;
  changing: boolean; // changing is for post, edit, and delete
  changed: boolean;
  changeError: any;
  snackbarMessage: string;
  batchCount: number;
  relationshipChildItems: RelationshipChildItem[];
  customMasterRelation: CustomMasterMapRelationshipBase[];
  batchErrors: { index: number; message: string }[];
}

const initialState: SuperOwnerAppCustomListRecordState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  fetchingRelationshipChild: false,
  fetchedRelationshipChild: false,
  fetchErrorRelationshipChild: null,
  customMasterListDetail: null,
  changing: false,
  changed: false,
  changeError: null,
  snackbarMessage: "",
  batchCount: 0,
  relationshipChildItems: [],
  customMasterRelation: [],
  batchErrors: [],
};

const superOwnerAppCustomListRecordSlice = createSlice({
  name: "SuperOwnerApp/CustomListRecord",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCustomMasterListDetailAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            fetchError: null,
          };
        },
      )
      .addCase(
        fetchCustomMasterListDetailAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetchError: "データが取得できませんでした。",
            customMasterListDetail: null,
          };
        },
      )
      .addCase(
        fetchCustomMasterListDetailAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            customMasterListDetail: action.payload,
          };
        },
      )
      .addCase(
        getCustomMasterMapRelationshipChildAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingRelationshipChild: true,
            fetchedRelationshipChild: false,
            fetchErrorRelationshipChild: null,
          };
        },
      )
      .addCase(
        getCustomMasterMapRelationshipChildAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingRelationshipChild: false,
            fetchedRelationshipChild: false,
            fetchErrorRelationshipChild: "データが取得できませんでした。",
            relationshipChildItems: [],
            customMasterRelation: [],
          };
        },
      )
      .addCase(
        getCustomMasterMapRelationshipChildAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetchingRelationshipChild: false,
            fetchedRelationshipChild: true,
            relationshipChildItems: action.payload.childItems,
            customMasterRelation: action.payload.customMasterRelation,
          };
        },
      )
      .addCase(
        postCustomMasterListDetailAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            changing: true,
            changeError: null,
            changed: false,
          };
        },
      )
      .addCase(
        postCustomMasterListDetailAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changeError:
              action.payload.error?.message || "エラーが発生しました。",
            batchCount: state.batchCount + 1,
            batchErrors: action.payload.requestIndex
              ? [
                  ...state.batchErrors,
                  {
                    index: action.payload.requestIndex - 1,
                    message:
                      action.payload.error?.message || "エラーが発生しました。",
                  },
                ]
              : [],
          };
        },
      )
      .addCase(
        postCustomMasterListDetailAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            batchCount: state.batchCount + 1,
            snackbarMessage: "追加しました。",
          };
        },
      )
      .addCase(
        updateCustomMasterListDetailAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            changing: true,
            changeError: null,
            changed: false,
          };
        },
      )
      .addCase(
        updateCustomMasterListDetailAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changeError: action.payload?.message || "エラーが発生しました。",
          };
        },
      )
      .addCase(
        updateCustomMasterListDetailAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            snackbarMessage: "編集しました。",
          };
        },
      )
      .addCase(
        deleteCustomMasterListDetailAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            changing: true,
            changeError: null,
            changed: false,
          };
        },
      )
      .addCase(
        deleteCustomMasterListDetailAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changeError: action.payload?.message || "エラーが発生しました。",
          };
        },
      )
      .addCase(
        deleteCustomMasterListDetailAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            changing: false,
            changed: true,
            snackbarMessage: "削除しました。",
          };
        },
      )
      .addCase(
        updateCustomMasterRecordCompanyLink.pending,
        (state, _action) => {
          return {
            ...state,
            changing: true,
            changeError: null,
            changed: false,
          };
        },
      )
      .addCase(
        updateCustomMasterRecordCompanyLink.rejected,
        (state, action) => {
          return {
            ...state,
            changing: false,
            changeError: action.payload?.message || "エラーが発生しました。",
          };
        },
      )
      .addCase(
        updateCustomMasterRecordCompanyLink.fulfilled,
        (state, _action) => {
          return {
            ...state,
            changing: false,
            changed: true,
          };
        },
      )
      .addCase(resetBatchCreateState, (state) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: null,
          batchCount: 0,
          batchErrors: [],
        };
      })
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: null,
          snackbarMessage: "",
        };
      });
  },
});

export const superOwnerAppCustomListRecordState = (
  state: RootState,
): SuperOwnerAppCustomListRecordState => state.superOwnerApp.customListRecord;

export default superOwnerAppCustomListRecordSlice.reducer;
