import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Api,
  GetLearningTemplatePackageDistributionHistoriesAsOwnerParams,
} from "@root/lib/Api";
import { RootState } from "@store/store";

const api = new Api();

export const getLearningTemplatePackageDistributionHistoriesAsOwner =
  createAsyncThunk<
    any,
    GetLearningTemplatePackageDistributionHistoriesAsOwnerParams,
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    "/owner/learning_template_package_distribution_histories",
    async (params, { rejectWithValue }) => {
      try {
        const response =
          await api.owner.getLearningTemplatePackageDistributionHistoriesAsOwner(
            params,
          );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    },
  );
