import { StudentGrade } from "../lib/Api";

export interface GradeItem {
  name: string;
  value: StudentGrade;
}

export const grades: GradeItem[] = [
  {
    name: "就学前",
    value: StudentGrade.Under,
  },
  {
    name: "小学1年",
    value: StudentGrade.Elementary1,
  },
  {
    name: "小学2年",
    value: StudentGrade.Elementary2,
  },
  {
    name: "小学3年",
    value: StudentGrade.Elementary3,
  },
  {
    name: "小学4年",
    value: StudentGrade.Elementary4,
  },
  {
    name: "小学5年",
    value: StudentGrade.Elementary5,
  },
  {
    name: "小学6年",
    value: StudentGrade.Elementary6,
  },
  {
    name: "中学1年",
    value: StudentGrade.Junior1,
  },
  {
    name: "中学2年",
    value: StudentGrade.Junior2,
  },
  {
    name: "中学3年",
    value: StudentGrade.Junior3,
  },
  {
    name: "高校1年",
    value: StudentGrade.High1,
  },
  {
    name: "高校2年",
    value: StudentGrade.High2,
  },
  {
    name: "高校3年",
    value: StudentGrade.High3,
  },
  {
    name: "大学1年",
    value: StudentGrade.University1,
  },
  {
    name: "大学2年",
    value: StudentGrade.University2,
  },
  {
    name: "大学3年",
    value: StudentGrade.University3,
  },
  {
    name: "大学4年",
    value: StudentGrade.University4,
  },
  {
    name: "その他",
    value: StudentGrade.Others,
  },
];
