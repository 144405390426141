/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { adminApi } from "@root/endpoints/TimeTactApi/adminApi";
import { contentProviderApi } from "@root/endpoints/TimeTactApi/contentProviderApi";
import { ownerApi } from "@root/endpoints/TimeTactApi/ownerApi";
import { remainingApi } from "@root/endpoints/TimeTactApi/remainingApi";
import { reviewerApi } from "@root/endpoints/TimeTactApi/reviewerApi";
import { studentApi } from "@root/endpoints/TimeTactApi/studentApi";
import { superOwnerApi } from "@root/endpoints/TimeTactApi/superOwnerApi";
import { teacherApi } from "@root/endpoints/TimeTactApi/teacherApi";
import { ownerAppHomeworkApi } from "@store/services/api/ownerApp/homework";
import { reviewerAppHomeworkApi } from "@store/services/api/reviewerApp/homework";
import { reviewerAppHomeworkChunkApi } from "@store/services/api/reviewerApp/homeworkChunk";
import { steamLibraryApi } from "@store/services/api/steamLibraryApp/steamLibraryApi";
import { studentAppHomeworkApi } from "@store/services/api/studentApp/homework";
import { studentAppMaterialWorkApi } from "@store/services/api/studentApp/materialWork";
import { teacherAppHomeworkApi } from "@store/services/api/teacherApp/homework";
import { combineReducers } from "redux";

import { logout } from "../actions/auth";
import adminAppReducer from "./adminApp";
import affiliateInvitationReducer from "./affiliateInvitation";
import authReducer from "./auth";
import categoryReducer from "./category";
import commonReducer from "./common";
import companyReducer from "./company";
import contentProviderAppReducer from "./contentProviderApp";
import gradeReducer from "./grade";
import inquiryReducer from "./inquiry";
import notificationReducer from "./notification";
import ownerAppReducer from "./ownerApp";
import reviewerAppReducer from "./reviewerApp";
import scrollReducer from "./scroll";
import steamLibraryReducer from "./steamLibrary";
import studentAppReducer from "./studentApp";
import superOwnerAppReducer from "./superOwnerApp";
import teacherAppReducer from "./teacherApp";
import toastReducer from "./toast";

const appReducer = combineReducers({
  affiliateInvitation: affiliateInvitationReducer,
  auth: authReducer,
  adminApp: adminAppReducer,
  ownerApp: ownerAppReducer,
  teacherApp: teacherAppReducer,
  studentApp: studentAppReducer,
  reviewerApp: reviewerAppReducer,
  contentProviderApp: contentProviderAppReducer,
  company: companyReducer,
  category: categoryReducer,
  common: commonReducer,
  grade: gradeReducer,
  inquiry: inquiryReducer,
  notification: notificationReducer,
  steamLibrary: steamLibraryReducer,
  superOwnerApp: superOwnerAppReducer,
  toast: toastReducer,
  scrollTop: scrollReducer,
  [studentAppMaterialWorkApi.reducerPath]: studentAppMaterialWorkApi.reducer,
  [studentAppHomeworkApi.reducerPath]: studentAppHomeworkApi.reducer,
  [teacherAppHomeworkApi.reducerPath]: teacherAppHomeworkApi.reducer,
  [ownerAppHomeworkApi.reducerPath]: ownerAppHomeworkApi.reducer,
  [reviewerAppHomeworkApi.reducerPath]: reviewerAppHomeworkApi.reducer,
  [reviewerAppHomeworkChunkApi.reducerPath]:
    reviewerAppHomeworkChunkApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [superOwnerApi.reducerPath]: superOwnerApi.reducer,
  [ownerApi.reducerPath]: ownerApi.reducer,
  [teacherApi.reducerPath]: teacherApi.reducer,
  [studentApi.reducerPath]: studentApi.reducer,
  [reviewerApi.reducerPath]: reviewerApi.reducer,
  [contentProviderApi.reducerPath]: contentProviderApi.reducer,
  [remainingApi.reducerPath]: remainingApi.reducer,
  [steamLibraryApi.reducerPath]: steamLibraryApi.reducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = (state: any, action: any): any => {
  let newState = state;
  if (
    action.type === logout.type ||
    action.type === "auth/deleteTokenAndLogout/pending"
  ) {
    newState = undefined; // This will reset the reducers to initial state.
  } else if (action.type === "common/postCommonAffiliates/fulfilled") {
    const { auth } = state;
    newState = { auth }; // This will reset the reducers to initial state except for auth when switching affiliates.
  }
  return appReducer(newState, action);
};

export default rootReducer;
