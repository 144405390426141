import "react-resizable/css/styles.css";

import { Button,Tooltip } from "@mui/material";
import React, { memo, useContext,useState } from "react";
import { useFormContext } from "react-hook-form";

import ImageInput from "../../Extension/Image/ImageInput";
import ImageResizer from "../../Extension/Image/ImageResizer";
import { defaultSelectedFile,SelectedFile } from "../../Extension/Image/types";
import { useStyles as useCommonStyles } from "../../PlusButtonMenu/styles";
import useDOMParse from "../../useDOMParse";
import { BubbleMenuContext } from "../BubbleMenuContext";

export const ImageGroup = (): JSX.Element => {
  const { canInsertImage } = useDOMParse();
  const { editor, isSubMenuOpen } = useContext(BubbleMenuContext);
  const { getValues } = useFormContext();
  const [isResizerOpen, setIsResizerOpen] = useState(false);
  const [selectedFile, setSelectedFile] =
    useState<SelectedFile>(defaultSelectedFile);

  const commonClasses = useCommonStyles();
  const disabled = isSubMenuOpen();
  const categorizableId = getValues("categorizableId");

  const onImageHtmlCreated = async (imageHTML: string) => {
    const insertPos = editor.state.selection.$to.pos;
    editor.chain().insertContentAt(insertPos, imageHTML).focus().run();
  };

  const ImageAddButton = (
    <Button
      component="label"
      className={commonClasses.default}
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      画像
    </Button>
  );

  let mainContent = (
    <Tooltip title="画像の挿入は１ブロック１つまでです。" placement="top">
      {ImageAddButton}
    </Tooltip>
  );

  // 画像が既に挿入されている場合は、挿入できないようにする
  if (canInsertImage(editor)) {
    // categorizableIdがない場合はツールチップを表示
    mainContent = (
      <Tooltip title="画像の挿入は更新時のみ利用可能です" placement="top">
        {ImageAddButton}
      </Tooltip>
    );

    if (categorizableId) {
      mainContent = (
        <Button
          component="label"
          className={commonClasses.default}
          disabled={disabled}
          style={{
            padding: 0,
            margin: 0,
            cursor: disabled ? "default" : "pointer",
          }}
        >
          画像
          <ImageInput
            setIsResizerOpen={setIsResizerOpen}
            setSelectedFile={setSelectedFile}
          />
        </Button>
      );
    }
  }
  return (
    <>
      <ImageResizer
        editor={editor}
        isResizerOpen={isResizerOpen}
        setIsResizerOpen={setIsResizerOpen}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        onImageHtmlCreated={onImageHtmlCreated}
      />
      {mainContent}
    </>
  );
};

export default memo(ImageGroup);
