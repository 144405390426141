import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import useOptionFeature from "@lib/hooks/useOptionFeature";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { PackagingPackageableType } from "@root/lib/Api";
import React from "react";

enum PackageTypeJa {
  Material = "教材",
  HomeworkTemplate = "提出物",
  Package = "教材＋提出物",
  PblSteamLecture = "STEAM Library",
  PblSponsorLecture = "企業コンテンツ",
}

type PackageTypeRadio = {
  value: PackagingPackageableType;
  label: PackageTypeJa;
  disabled?: boolean;
};

interface Props {
  handlePackageType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  packageType: PackagingPackageableType;
  disabled: boolean;
  disablePackage: boolean;
}

const PanelHeader: React.FC<Props> = ({
  handlePackageType,
  packageType,
  disabled,
  disablePackage,
}) => {
  const { isPblProjectsFeature } = useOptionFeature();
  let title: string;

  switch (packageType) {
    case PackagingPackageableType.HomeworkTemplate:
      title = `${PackageTypeJa.HomeworkTemplate}を選択する`;
      break;
    case PackagingPackageableType.Material:
      title = `${PackageTypeJa.Material}を選択する`;
      break;
    case PackagingPackageableType.Package:
      title = `${PackageTypeJa.Package}を選択する`;
      break;
    case PackagingPackageableType.PblSteamLecture:
    case PackagingPackageableType.PblSponsorLecture:
      title = `レクチャーを選択する`;
      break;
    default:
      title = "";
  }

  const packageTypeList: PackageTypeRadio[] = [
    {
      value: PackagingPackageableType.Material,
      label: PackageTypeJa.Material,
      disabled,
    },
    {
      value: PackagingPackageableType.HomeworkTemplate,
      label: PackageTypeJa.HomeworkTemplate,
      disabled,
    },
    {
      value: PackagingPackageableType.Package,
      label: PackageTypeJa.Package,
      disabled: disablePackage,
    },
    {
      value: PackagingPackageableType.PblSteamLecture,
      label: PackageTypeJa.PblSteamLecture,
      disabled,
    },
    ...(isPblProjectsFeature
      ? [
          {
            value: PackagingPackageableType.PblSponsorLecture,
            label: PackageTypeJa.PblSponsorLecture,
            disabled,
          },
        ]
      : []),
  ];

  return (
    <FormSectionWrap>
      <Box display="flex" alignItems="flex-start" flexDirection="column">
        <Box mr={3} display="flex" alignItems="center">
          <FormCategoryTitle style={{ margin: 0 }}>{title}</FormCategoryTitle>
        </Box>
        <RadioGroup
          row
          name="package_type"
          value={packageType}
          onChange={handlePackageType}
        >
          {packageTypeList.map((type) => (
            <FormControlLabel
              key={type.value}
              value={type.value}
              control={<Radio color="primary" />}
              label={type.label}
              disabled={type.disabled}
              data-cy={`${type.value}-option`}
            />
          ))}
        </RadioGroup>
      </Box>
    </FormSectionWrap>
  );
};

export default PanelHeader;
