/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export type UserTaggingParams = {
  user_id: string;
  user_tag_id: string;
  company_id: string;
};

export const createUserTaggingAsSuperOwner = createAsyncThunk<
  {
    number_of_tagging_users?: number;
    remaining_number_of_tagging_users?: number | null;
  },
  UserTaggingParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/userTagging/createUserTagging",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.userTaggingsCreate(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUserTaggingAsSuperOwner = createAsyncThunk<
  any,
  UserTaggingParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/userTagging/deleteUserTagging",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.userTaggingsDelete(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetUserTaggingState = createAction(
  "super_owner/userTagging/resetState",
);
