/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-array-index-key */
import TagIcon from "@assets/images/svg/fuda_icon.svg?react";
import PlusIcon from "@assets/images/svg/plus_icon.svg?react";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, TextField, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { pullAt } from "lodash";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

import { FormValues } from "../../types";

const useStyles = makeStyles((theme: Theme) => ({
  tagContainer: {
    display: "flex",
    flexWrap: "wrap",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    gap: theme.spacing(1),
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    height: 36,
    whiteSpace: "nowrap",
    color: theme.palette.tertiary.mainDark,
    fontWeight: 600,
    backgroundColor: theme.palette.search.lightGreenBackground,
  },
  clearButton: {
    cursor: "pointer",
    color: theme.palette.tertiary.mainDark,
    fontSize: 18,
    fontWeight: 600,
  },
  addButton: {
    color: "rgba(71, 81, 73, 0.3)",
  },
  plusIcon: {
    "& > path": {
      fill: "rgba(71, 81, 73, 0.3)",
    },
  },
  tagInput: {
    width: 125,
    height: 36,
  },
}));

const MaterialTagFields: React.FC = () => {
  const { setValue, watch } = useFormContext<FormValues>();
  const classes = useStyles();
  const tags = watch("tags") || [];

  const [isTagEditing, setIsTagEditing] = useState<boolean>(false);
  const [tagInputValue, setTagInputValue] = useState<string>("");

  const resetTagInput = () => {
    setTagInputValue("");
    setIsTagEditing(false);
  };

  const handleTagAdd = () => {
    if (tagInputValue) {
      setValue("tags", [...tags, tagInputValue]);
      setTagInputValue("");
    }
    setIsTagEditing(false);
  };

  const handleTagRemove = (index: number) => {
    const newTagList = [...tags];
    pullAt(newTagList, index);
    setValue("tags", newTagList);
  };

  const handleTagInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagInputValue(e.target.value);
  };

  const handleTagInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleTagAdd();
    }
    if (e.key === "Escape") {
      resetTagInput();
    }
  };

  const handleInputBlur = () => {
    handleTagAdd();
  };

  return (
    <FormSectionWrap>
      <FormCategoryTitle>タグ情報</FormCategoryTitle>
      <Box className={classes.tagContainer}>
        {tags.map((tag, index) => (
          <Box
            key={`${tag}-${index}`}
            className={classes.wrapper}
            data-cy="tag-wrapper"
          >
            <TagIcon />
            {tag}
            <CancelIcon
              className={classes.clearButton}
              onClick={() => handleTagRemove(index)}
              data-cy="tag-remove-button"
            />
          </Box>
        ))}

        {isTagEditing ? (
          <TextField
            variant="outlined"
            size="small"
            autoFocus
            inputProps={{ "data-cy": "tag-input" }}
            InputProps={{ className: classes.tagInput }}
            value={tagInputValue}
            onChange={handleTagInputChange}
            onKeyDown={handleTagInputKeyDown}
            onBlur={handleInputBlur}
          />
        ) : (
          <Button
            color="inherit"
            variant="outlined"
            className={classes.addButton}
            startIcon={<PlusIcon className={classes.plusIcon} />}
            onClick={() => setIsTagEditing(true)}
            data-cy="tag-add-button"
          >
            タグの追加
          </Button>
        )}
      </Box>
    </FormSectionWrap>
  );
};

export default MaterialTagFields;
