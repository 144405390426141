import MainButton from "@components/UI/atoms/MainButton/MainButton";
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import { safeString } from "@lib/string-utils";
import { Box, Dialog, DialogActions, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Editor } from "@tiptap/react";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Resizable,ResizeCallbackData } from "react-resizable";

import { POSITION_LEFT } from "../../SVBubble/types";
import { defaultSelectedFile,SelectedFile } from "./types";
import usePostAttachedImage from "./usePostAttachedImage";

const useStyles = makeStyles<Theme, ThemeProps>((theme) =>
  createStyles({
    loadingPaper: {
      width: 600,
      height: 450,
    },
    paper: ({ dialogWidth, dialogHeight, isSmallSize }) => ({
      width: dialogWidth,
      height: dialogHeight,
      overflow: "hidden",
      "& .react-resizable-handle": {
        padding: isSmallSize ? "0 3px 3px 0" : "0 5px 5px 0",
      },
      "& .react-resizable-handle-sw": {
        transform: `rotate(90deg) scale(${isSmallSize ? 1 : 1.5})`,
      },
      "& .react-resizable-handle-se": {
        transform: `scale(${isSmallSize ? 1 : 1.5})`,
      },
      "& .react-resizable-handle-nw": {
        transform: `rotate(180deg) scale(${isSmallSize ? 1 : 1.5})`,
      },
      "& .react-resizable-handle-ne": {
        transform: `rotate(270deg) scale(${isSmallSize ? 1 : 1.5})`,
      },
    }),
    imgWrapper: ({ imgWidth, imgHeight }) => ({
      width: imgWidth,
      height: imgHeight,
    }),
    dialogActions: ({ isSmallSize }) => ({
      padding: theme.spacing(1, isSmallSize ? 1 : 2),
    }),
  }),
);

type ThemeProps = {
  dialogWidth: number;
  dialogHeight: number;
  imgWidth: number;
  imgHeight: number;
  isSmallSize: boolean;
};

type Dimension = {
  width: number;
  height: number;
};

type Props = {
  editor: Editor;
  isResizerOpen: boolean;
  setIsResizerOpen: Dispatch<SetStateAction<boolean>>;
  selectedFile: SelectedFile;
  setSelectedFile: Dispatch<SetStateAction<SelectedFile>>;
  onImageHtmlCreated: (imageHtml: string) => void;
};

const ImageResizer: React.FC<Props> = ({
  editor,
  isResizerOpen,
  setIsResizerOpen,
  selectedFile,
  setSelectedFile,
  onImageHtmlCreated,
}) => {
  const { t } = useTranslation("common");
  const imageRef = useRef<HTMLImageElement | null>(null);

  const defaultWidth = 400;
  const actionButtonHeight = 42;
  const maxSize: Dimension = { width: 800, height: 1000 };
  const minSize: Dimension = { width: 180, height: 120 };
  const [dialogSize, setDialogSize] = useState<Dimension>({
    height: 0,
    width: 0,
  });

  const classes = useStyles({
    dialogWidth: dialogSize.width,
    dialogHeight: dialogSize.height,
    imgWidth: selectedFile.width,
    imgHeight: selectedFile.height,
    isSmallSize: dialogSize.width < 200,
  });

  const { getValues } = useFormContext();
  const { postAttachedImage, isUploading } = usePostAttachedImage();

  const categorizableId = getValues("categorizableId");
  const categorizableType = getValues("categorizableType");

  const setImage = async () => {
    if (
      !selectedFile.changedFile ||
      isUploading ||
      !categorizableId ||
      !categorizableType
    ) {
      return;
    }
    const queryArg = {
      fileRequiredRequestBody: { file: selectedFile.changedFile },
      categorizableId,
      categorizableType,
    };
    const attachedImage = await postAttachedImage(queryArg).unwrap();
    // 画像の再取得の際に必要なパラメータをdata属性にセットしている
    // 画像を取得するAPIにid,categorizableId,categorizableTypeの3つの値が必要
    const imageHTML = `<image-wrapper>
      <div style="text-align: ${POSITION_LEFT}">
        <img src="${attachedImage.file_url}" width="${selectedFile.width}" height="${selectedFile.height}" imageposition="${POSITION_LEFT}" data-id="${attachedImage.id}" data-categorizable-id="${categorizableId}" data-categorizable-type="${categorizableType}" />
      </div>
    </image-wrapper>`;

    onImageHtmlCreated(imageHTML);
  };

  const setImageSize = ({ width, height }: Dimension) => {
    setSelectedFile((prev) => ({
      ...prev,
      width,
      height,
    }));
  };

  const handleResize = (
    _: React.SyntheticEvent,
    { size }: ResizeCallbackData,
  ) => {
    const imageAspectRatio =
      selectedFile.originalWidth / selectedFile.originalHeight;
    const newImgWidth = size.width;
    const newImgHeight = size.width / imageAspectRatio;
    if (
      newImgHeight < Math.max(maxSize.height, selectedFile.originalHeight) &&
      newImgWidth < Math.max(maxSize.width, selectedFile.originalWidth) &&
      newImgHeight > Math.min(minSize.height, selectedFile.originalHeight) &&
      newImgWidth > Math.min(minSize.width, selectedFile.originalWidth)
    ) {
      setImageSize({
        height: newImgHeight,
        width: newImgWidth,
      });
      setDialogSize({
        height: newImgHeight + actionButtonHeight,
        width: newImgWidth,
      });
    }
  };

  const handleImageLoad = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      let width = naturalWidth;
      let height = naturalHeight;

      if (width > defaultWidth) {
        width = defaultWidth;
        height = naturalHeight * (defaultWidth / naturalWidth);
      }
      setSelectedFile((prev) => ({
        ...prev,
        width,
        height,
        originalWidth: width,
        originalHeight: height,
      }));
      setDialogSize({
        width,
        height: height + actionButtonHeight,
      });
    }
  };

  const resetStates = () => {
    setSelectedFile(defaultSelectedFile);
    setIsResizerOpen(false);
  };

  const handleAdd = async () => {
    const { objectUrl, width, height } = selectedFile;
    if (editor && Boolean(objectUrl) && Boolean(width) && Boolean(height)) {
      await setImage();
    }
    resetStates();
  };

  const handleClose = () => {
    resetStates();
  };

  return (
    <Dialog
      open={isResizerOpen}
      onClose={handleClose}
      classes={{ paper: isUploading ? classes.loadingPaper : classes.paper }}
      maxWidth="md"
    >
      {isUploading ? (
        <CircularLoading />
      ) : (
        <Resizable
          height={dialogSize.height}
          width={dialogSize.width}
          onResize={handleResize}
          resizeHandles={["sw", "nw", "se", "ne"]}
        >
          <>
            <Box className={classes.imgWrapper}>
              <img
                ref={imageRef}
                src={safeString(selectedFile.objectUrl)}
                alt={safeString(selectedFile.changedFile?.name)}
                width="100%"
                height="100%"
                onLoad={handleImageLoad}
              />
            </Box>
            <DialogActions classes={{ root: classes.dialogActions }}>
              <MainButton variant="outlined" size="small" onClick={handleClose}>
                {t("cancel")}
              </MainButton>
              <SingleButton
                variant="contained"
                size="small"
                onClick={handleAdd}
              >
                {t("add")}
              </SingleButton>
            </DialogActions>
          </>
        </Resizable>
      )}
    </Dialog>
  );
};

export default ImageResizer;
