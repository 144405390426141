import { MultilineText } from "@components/UI/atoms/MultilineText/MultilineText";
import React from "react";
import Linkify from "react-linkify";

type Props = {
  text: string;
};

const LinkifyMultipleText: React.FC<Props> = (props: Props) => {
  const { text } = props;
  return (
    <>
      <Linkify
        // eslint-disable-next-line react/no-unstable-nested-components
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={decoratedHref}
            key={key}
          >
            {decoratedText}
          </a>
        )}
      >
        <MultilineText data-cy="multilineText">{text}</MultilineText>
      </Linkify>
    </>
  );
};

export default LinkifyMultipleText;
