/* eslint-disable camelcase */
import {
  Api,
  GetTeacherGetClassDateListParams,
  GetTeacherPlanClassesParams,
  GetTeacherPlanItemsParams,
  GetTeacherPlanStudentsParams,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import {
  CreatePlanItemsParams,
  DaysParams,
  PlanStudents,
  PostTeacherPlanClassesParams,
  UpdateTeacherPlanClassesParams,
} from "./types/pblPlan";

const api = new Api();

export const postTeacherPlanItems = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/postPblPlanItems",
  async (param: CreatePlanItemsParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.postTeacherPlanItems(param);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const putTeacherPlanItemsId = createAsyncThunk<
  any,
  {
    itemId: string;
    planItemParams: CreatePlanItemsParams;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/putPblPlanItems", async (param, { rejectWithValue }) => {
  try {
    const response = await api.teacher.putTeacherPlanItemsId(
      param.itemId,
      param.planItemParams,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const postTeacherPlanDays = createAsyncThunk<
  any,
  {
    planItemId: string;
    planDaysParams: DaysParams[];
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/postPblPlanDays", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.postTeacherPlanDays(args.planItemId, {
      days: args.planDaysParams,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const putTeacherPlanDaysId = createAsyncThunk<
  any,
  {
    planItemId: string;
    itemId: string;
    planDaysParams: DaysParams;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/putPblPlanDays", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.putTeacherPlanDaysId(
      args.planItemId,
      args.itemId,
      args.planDaysParams,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const putTeacherPlanStudentsId = createAsyncThunk<
  any,
  {
    planItemId: string;
    itemId: string;
    studentIds: PlanStudents;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/putPblPlanStudents", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.putTeacherPlanStudentsId(
      args.planItemId,
      args.itemId,
      args.studentIds,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const postTeacherPlanStudents = createAsyncThunk<
  any,
  {
    planItemId: string;
    planStudents: string[];
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/postPblPlanStudents", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.postTeacherPlanStudents(
      args.planItemId,
      { student_ids: args.planStudents },
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getTeacherPlanItems = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/getPlanItems",
  async (args: GetTeacherPlanItemsParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherPlanItems(args);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getTeacherPlanClasses = createAsyncThunk<
  any,
  GetTeacherPlanClassesParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/getPlanClasses",
  async (args: GetTeacherPlanClassesParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherPlanClasses(args);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getTeacherPlanStudents = createAsyncThunk<
  any,
  GetTeacherPlanStudentsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/getPlanStudents",
  async (args: GetTeacherPlanStudentsParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherPlanStudents(args);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getOneTeacherPlanItem = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/getOnePlanItem", async (planItemId, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getTeacherPlanItemsId(planItemId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getOneTeacherPlanItemReload = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/getOnePlanItemReload", async (planItemId, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getTeacherPlanItemsId(planItemId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getTeacherGetClassDateList = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/getClassDateList",
  async (params: GetTeacherGetClassDateListParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherGetClassDateList(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const postTeacherPlanClasses = createAsyncThunk<
  any,
  {
    itemId: string;
    data: PostTeacherPlanClassesParams[];
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/postPblPlanClasses", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.postTeacherPlanClasses(args.itemId, {
      plan_classes: args.data,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const putTeacherPlanClassesId = createAsyncThunk<
  any,
  {
    itemId: string;
    classId: any;
    data: UpdateTeacherPlanClassesParams;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/putPblPlanClasses", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.putTeacherPlanClassesId(
      args.itemId,
      args.classId,
      args.data,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const postPblPlanContentsAsTeacher = createAsyncThunk<
  any,
  {
    itemId: string;
    classId: string;
    data: Parameters<Api["teacher"]["postPblPlanContentsAsTeacher"]>[2];
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/postPblPlanContentsAsTeacher", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.postPblPlanContentsAsTeacher(
      args.itemId,
      args.classId,
      args.data,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deleteTeacherPlanDaysId = createAsyncThunk<
  any,
  {
    itemId: string;
    dayId: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/deletePblPlanDays", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.deleteTeacherPlanDaysId(
      args.itemId,
      args.dayId,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deleteTeacherPlanClasse = createAsyncThunk<
  any,
  {
    itemId: string;
    classId: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/deletePblPlanClasse", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.deleteTeacherPlanClassesId(
      args.itemId,
      args.classId,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deleteTeacherPlanStudent = createAsyncThunk<
  any,
  {
    itemId: string;
    studentId: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/deletePblPlanStudent", async (args, { rejectWithValue }) => {
  try {
    const response = await api.teacher.deleteTeacherPlanStudentsId(
      args.itemId,
      args.studentId,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const deletePblPlanContentsAsTeacher = createAsyncThunk<
  any,
  {
    itemId: string;
    classId: string;
    id: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/deletePblPlanContentsAsTeacher",
  async (args, { rejectWithValue }) => {
    try {
      const response = await api.teacher.deletePblPlanContentsAsTeacher(
        args.itemId,
        args.classId,
        args.id,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const resetState = createAction("teacher/resetAllPlanState");
export const resetUploadingPercent = createAction(
  "teacher/resetUploadingPercent",
);
export const editedClasses = createAction("teacher/editedClasses");
export const editedSubjects = createAction("teacher/editedSubjects");
export const draftStatus = createAction("teacher/handleDraft");
export const activeStatus = createAction("teacher/handleActive");
export const creatingStatus = createAction("teacher/handleCreating");
export const addedStudentsToPlan = createAction("teacher/addedStudentsToPlan");
export const deletedStudentsToPlan = createAction(
  "teacher/deletedStudentsToPlan",
);

export const handleLoading = createAction(
  "teacher/handleLoading/arg",
  (counter, msg) => {
    return {
      payload: {
        count: counter,
        message: msg,
      },
    };
  },
);
