import { Button,Theme, Typography } from "@mui/material";
import { createStyles,makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.tertiary.darkGreen,
      textAlign: "left",
      textTransform: "none",
      borderRadius: "none",
      border: "none",
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.typography.button,
      },
    },
    text: {
      fontSize: "1rem",
      fontWeight: "bold",
      padding: theme.spacing(0, 1),
      textDecoration: "underline",
      textUnderlineOffset: "3px",
      color: theme.palette.secondary.contrastText,
      textDecorationColor: theme.palette.secondary.contrastText,
      "&:hover": {
        color: theme.palette.tertiary.mainDark,
        textDecorationColor: theme.palette.tertiary.mainDark,
      },
    },
  }),
);

interface Props {
  handleClick: () => void;
  text: string;
}

const LinkButton: React.FC<Props> = ({ handleClick, text }) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} onClick={handleClick}>
      <Typography className={classes.text}>{text}</Typography>
    </Button>
  );
};

export default LinkButton;
