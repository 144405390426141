import { AffiliateStudentName } from "@lib/Api";
import { useHandleNickName } from "@lib/string-utils";
import { fullName } from "@lib/user/name-utils";
import { Table, TableBody } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  student: AffiliateStudentName;
  value: string[];
  handleStudentIds: (checkedId: string) => string[];
  onChange: (...event: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRowInactive: {
      backgroundColor: theme.palette.customColor.grey,
      borderRadius: "8px",
      padding: theme.spacing(0),
    },
    lastCell: {
      paddingRight: theme.spacing(3),
    },
  }),
);

const StudentListItem: React.FC<Props> = ({
  student,
  value,
  handleStudentIds,
  onChange,
}) => {
  const classes = useStyles();
  const {
    actions: { handleNickName },
  } = useHandleNickName();

  return (
    <Table aria-label="table" style={{ width: "100%" }}>
      <TableBody>
        <TableRow
          key={`student-${student.affiliate_id}`}
          className={classes.tableRowInactive}
        >
          <TableCell padding="checkbox">
            <Checkbox
              edge="end"
              color="primary"
              onChange={() => {
                onChange(handleStudentIds(student.affiliate_id));
              }}
              checked={value?.includes(student.affiliate_id)}
              value={student.affiliate_id}
            />
          </TableCell>
          <TableCell align="left">
            {handleNickName({
              nickName: student.nickname,
              fullName: fullName(student),
            })}
          </TableCell>
          <TableCell classes={{ root: classes.lastCell }} align="right">
            {student.company.name}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default StudentListItem;
