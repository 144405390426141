/* eslint-disable camelcase */
import {
  deleteSteamLibraryContentsAsAdmin,
  fetchSteamLibraryContentsAsAdmin,
  getSteamLibraryContentTitle,
  postSteamLibraryContentsAsAdmin,
  resetContentFormState,
  resetContentTitleState,
  updateSteamLibraryContentsAsAdmin,
} from "@actions/adminApp/steamContent";
import { ReduxStatus,ReduxStatusType } from "@constants/redux";
import { PblSteamContentBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import {
  getErrorMessage,
  getErrorsInArray,
} from "@utils/errorMessage/errorMessage";

interface SteamContentState {
  fetching: boolean;
  fetched: boolean;
  fetchError: string | null;
  fetchingTitle: boolean;
  fetchedTitle: boolean;
  fetchTitleError: string | null;
  posting: boolean; // Used for create, update, and delete
  create: ReduxStatusType;
  createErrors: string[];
  deleteSteam: ReduxStatusType;
  deleteError: string | null;
  posted: boolean;
  postErrors: string[];
  contentTitle: {
    steam_library_content_id: number;
    steam_library_content_title: string;
  } | null;
  contents: PblSteamContentBase[];
  totalCount: number;
}

const initialState: SteamContentState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  fetchingTitle: false,
  fetchedTitle: false,
  fetchTitleError: null,
  create: ReduxStatus.idle,
  createErrors: [],
  deleteSteam: ReduxStatus.idle,
  deleteError: null,
  posting: false,
  posted: false,
  postErrors: [],
  contentTitle: null,
  contents: [],
  totalCount: 0,
};

const changePendingState = (state: SteamContentState) => {
  return {
    ...state,
    posting: true,
    posted: false,
    postErrors: [],
  };
};

const changeFulfilledState = (state: SteamContentState) => {
  return {
    ...state,
    posting: false,
    posted: true,
  };
};

const changeRejectedState = (state: SteamContentState, action: any) => {
  return {
    ...state,
    posting: false,
    postErrors: getErrorsInArray(action.payload as unknown),
  };
};

const steamContentSlice = createSlice({
  name: "Admin/SteamContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSteamLibraryContentsAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: null,
          contents: [],
        };
      })
      .addCase(fetchSteamLibraryContentsAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          contents: action.payload.steam_contents || [],
          totalCount: action.payload.total_count || 0,
        };
      })
      .addCase(fetchSteamLibraryContentsAsAdmin.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(getSteamLibraryContentTitle.pending, (state, _action) => {
        return {
          ...state,
          fetchingTitle: true,
          fetchedTitle: false,
          fetchTitleError: null,
          contentTitle: null,
        };
      })
      .addCase(getSteamLibraryContentTitle.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingTitle: false,
          fetchedTitle: true,
          contentTitle: action.payload,
        };
      })
      .addCase(getSteamLibraryContentTitle.rejected, (state, _action) => {
        return {
          ...state,
          fetchingTitle: false,
          fetchTitleError: "データが見つかりませんでした。",
        };
      })
      .addCase(postSteamLibraryContentsAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          create: ReduxStatus.pending,
        };
      })
      .addCase(postSteamLibraryContentsAsAdmin.fulfilled, (state, _action) => {
        return {
          ...state,
          create: ReduxStatus.fulfilled,
        };
      })
      .addCase(postSteamLibraryContentsAsAdmin.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          create: ReduxStatus.rejected,
          createErrors: getErrorsInArray(payload as unknown),
        };
      })
      .addCase(updateSteamLibraryContentsAsAdmin.pending, (state, _action) => {
        return changePendingState(state);
      })
      .addCase(
        updateSteamLibraryContentsAsAdmin.fulfilled,
        (state, _action) => {
          return changeFulfilledState(state);
        },
      )
      .addCase(updateSteamLibraryContentsAsAdmin.rejected, (state, action) => {
        return changeRejectedState(state, action);
      })
      .addCase(deleteSteamLibraryContentsAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          deleteSteam: ReduxStatus.pending,
        };
      })
      .addCase(
        deleteSteamLibraryContentsAsAdmin.fulfilled,
        (state, _action) => {
          return {
            ...state,
            deleteSteam: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(deleteSteamLibraryContentsAsAdmin.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          deleteSteam: ReduxStatus.rejected,
          deleteError: getErrorMessage(payload.errors),
        };
      })
      .addCase(resetContentTitleState, (state, _action) => {
        return {
          ...state,
          fetchingTitle: false,
          fetchedTitle: false,
          fetchTitleError: null,
          contentTitle: null,
        };
      })
      .addCase(resetContentFormState, (state, _action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postErrors: [],
          create: ReduxStatus.idle,
          createErrors: [],
          deleteSteam: ReduxStatus.idle,
          deleteError: null,
        };
      });
  },
});

export const adminAppSteamContentState = (
  state: RootState,
): SteamContentState => state.adminApp.steamContent;

export default steamContentSlice.reducer;
