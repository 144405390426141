import { Payment } from "@lib/Api";
import { update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";

import {
  fetchPaymentsAsStudent,
  resetStudentAppUpdatePaymentFormData,
  updatePaymentAsStudent,
} from "../../actions/studentApp/payment";
import { RootState } from "../../store";

export interface StudentAppPaymentState {
  fetching: boolean;
  fetched: boolean;
  updating: boolean;
  updated: boolean;
  error: any;
  payments: Payment[];
  totalCount: number;
}

export const initialState: StudentAppPaymentState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  error: null,
  payments: [],
  totalCount: 0,
};

const studentAppPaymentSlice = createSlice({
  name: "StudentApp/Payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchPaymentsAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: action.error.message,
        };
      })
      .addCase(fetchPaymentsAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          payments: payload.payments,
          totalCount: payload.total_count,
        };
      })
      .addCase(updatePaymentAsStudent.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(updatePaymentAsStudent.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          error: action.error.message,
        };
      })
      .addCase(updatePaymentAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          payments: update(currentState.payments, payload),
        };
      })
      .addCase(resetStudentAppUpdatePaymentFormData, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          error: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppPaymentState = (
  state: RootState,
): StudentAppPaymentState => state.studentApp.payment;

export default studentAppPaymentSlice.reducer;
