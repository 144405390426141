/* eslint-disable @typescript-eslint/ban-types */
import {
  FormAnnotation,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import V2DateTimePicker from "@components/UIv2/atoms/V2DateTimePicker";
import V2StyledFormTitle from "@components/UIv2/atoms/V2StyledFormTitle";
import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCalendarAlt } from "react-icons/fa";

import { FormValuesTypes } from "..";

type Props = {
  control: Control<FormValuesTypes, object>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: theme.spacing(1),
      },
    },
    inputWrapper: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    iconsStyles: {
      fontSize: "20px",
      color: theme.palette.tertiary.mainDark,
    },
  }),
);

const InterviewTimeInputs: React.FC<Props> = ({ control }) => {
  const classes = useStyles();
  const { t } = useTranslation("university");
  return (
    <>
      <V2StyledFormTitle isRequired title={t("scheduledTime")} />
      <FormAnnotation>{t("scheduledTimeAnnotation")}</FormAnnotation>

      <FormSectionWrap className={classes.wrapper}>
        <Controller
          control={control}
          name="interviewTime1"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box className={classes.inputWrapper}>
              <FaCalendarAlt className={classes.iconsStyles} />
              <Typography>{t("firstChoice")}</Typography>
              <V2DateTimePicker
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: error ? error.message : null,
                  },
                }}
                value={value}
                onChange={onChange}
                disablePast
              />
            </Box>
          )}
          rules={{ required: t("selectAConfirmTime") }}
        />

        <Controller
          control={control}
          name="interviewTime2"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box className={classes.inputWrapper}>
              <FaCalendarAlt className={classes.iconsStyles} />
              <Typography>{t("secondChoice")}</Typography>
              <V2DateTimePicker
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: error ? error.message : null,
                  },
                }}
                value={value}
                onChange={onChange}
                disablePast
              />
            </Box>
          )}
          rules={{ required: t("selectAConfirmTime") }}
        />
      </FormSectionWrap>

      <FormSectionWrap className={classes.wrapper}>
        <Controller
          control={control}
          name="interviewTime3"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box className={classes.inputWrapper}>
              <FaCalendarAlt className={classes.iconsStyles} />
              <Typography>{t("thirdChoice")}</Typography>
              <V2DateTimePicker
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: error ? error.message : null,
                  },
                }}
                value={value}
                onChange={onChange}
                disablePast
              />
            </Box>
          )}
          rules={{ required: t("selectAConfirmTime") }}
        />

        <Controller
          control={control}
          name="interviewTime4"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box className={classes.inputWrapper}>
              <FaCalendarAlt className={classes.iconsStyles} />
              <Typography>{t("fourthChoice")}</Typography>
              <V2DateTimePicker
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: error ? error.message : null,
                  },
                }}
                value={value}
                onChange={onChange}
                disablePast
              />
            </Box>
          )}
          rules={{ required: t("selectAConfirmTime") }}
        />
      </FormSectionWrap>
    </>
  );
};
export default InterviewTimeInputs;
