import {
  fetchLecturesByProjectAsStudent,
  fetchPblSponsorProjectAsStudent,
  fetchPblSponsorProjectCarouselAsStudent,
  fetchPblSponsorProjectsAsStudent,
  resetOne,
} from "@actions/studentApp/pbl/sponsor/project";
import {
  SponsorProjectAndTagsBase,
  SponsorProjectBase,
  SponsorProjectDetailsBase,
} from "@lib/Api";
import { createSlice, current } from "@reduxjs/toolkit";
import { ReduxStatus,ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface StudentAppPblSponsorProjectState {
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneErrors: string | null;
  fetchProjects: ReduxStatusType;
  projects: SponsorProjectAndTagsBase[];
  projectDetails: SponsorProjectDetailsBase | null;
  fetchingLectures: boolean;
  fetchErrors: string | null;
  projectsTotalCount: number;
  fetchCarouselError: string | null;
  fetchCarousel: ReduxStatusType;
  carouselProjects: SponsorProjectBase[];
}

export const initialState: StudentAppPblSponsorProjectState = {
  fetchingOne: false,
  fetchedOne: false,
  fetchOneErrors: null,
  projectDetails: null,
  fetchingLectures: false,
  fetchProjects: ReduxStatus.idle,
  fetchErrors: null,
  projects: [],
  projectsTotalCount: 0,
  fetchCarouselError: null,
  fetchCarousel: ReduxStatus.idle,
  carouselProjects: [],
};

const studentAppPblSponsorProjectSlice = createSlice({
  name: "studentApp/pbl/sponsors/info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblSponsorProjectsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchProjects: ReduxStatus.pending,
        };
      })
      .addCase(fetchPblSponsorProjectsAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchProjects: ReduxStatus.fulfilled,
          projects: payload.projects,
          projectsTotalCount: payload.total_count,
        };
      })
      .addCase(fetchPblSponsorProjectsAsStudent.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchProjects: ReduxStatus.pending,
          fetchErrors: getErrorMessage(payload.errors),
        };
      })
      .addCase(
        fetchPblSponsorProjectCarouselAsStudent.pending,
        (state, _action) => {
          return {
            ...state,
            fetchCarousel: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetchPblSponsorProjectCarouselAsStudent.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchCarousel: ReduxStatus.fulfilled,
            carouselProjects: payload.projects,
          };
        },
      )
      .addCase(
        fetchPblSponsorProjectCarouselAsStudent.rejected,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchCarousel: ReduxStatus.rejected,
            fetchCarouselError: getErrorMessage(payload.errors),
          };
        },
      )
      .addCase(fetchPblSponsorProjectAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
        };
      })
      .addCase(fetchPblSponsorProjectAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error.message as string,
        };
      })
      .addCase(fetchPblSponsorProjectAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          fetchOneError: null,
          projectDetails: payload,
        };
      })
      .addCase(fetchLecturesByProjectAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchingLectures: true,
        };
      })
      .addCase(fetchLecturesByProjectAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          fetchingLectures: false,
        };
      })
      .addCase(fetchLecturesByProjectAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        const currentLectures =
          currentState.projectDetails && currentState.projectDetails.lectures
            ? currentState.projectDetails.lectures
            : [];
        const newProjectDetails = currentState.projectDetails
          ? {
              ...currentState.projectDetails,
              lectures: [...currentLectures, ...payload.project_lectures],
            }
          : null;
        return {
          ...state,
          fetchingLectures: false,
          projectDetails: newProjectDetails,
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          projectDetails: null,
          fetchedOne: false,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppPblSponsorProjectState = (
  state: RootState,
): StudentAppPblSponsorProjectState => state.studentApp.pblSponsorProject;

export default studentAppPblSponsorProjectSlice.reducer;
