/* eslint-disable camelcase */
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import CustomMenu from "@components/UIv2/atoms/CustomMenu";
import { roles } from "@constants/roles";
import { useAuth } from "@contexts/Auth";
import {
  PostTtCsvExportAsOwnerParams,
  PostTtCsvExportAsSuperOwnerParams,
  PostTtCsvExportAsTeacherParams,
  TtCsvExportType,
} from "@lib/Api";
import { getTypeTrans } from "@lib/file/csvExport/csv-export-utils";
import {
  ButtonIconType,
  GetButtonIconType,
} from "@lib/getButtonIconType-utils";
import { usePostTtCsvExportAsAdminMutation } from "@root/endpoints/TimeTactApi/adminApi";
import { usePostTtCsvExportAsOwnerMutation } from "@root/endpoints/TimeTactApi/ownerApi";
import { usePostTtCsvExportAsReviewerMutation } from "@root/endpoints/TimeTactApi/reviewerApi";
import { usePostTtCsvExportAsSuperOwnerMutation } from "@root/endpoints/TimeTactApi/superOwnerApi";
import { usePostTtCsvExportAsTeacherMutation } from "@root/endpoints/TimeTactApi/teacherApi";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

type Props = {
  target_company_id?: string;
  homework_template_id?: string;
  learning_template_package_distribution_setting_id?: string;
  common_custom_master_map_id?: string;
  type: TtCsvExportType;
  content_company_ids?: string[];
  school_ids?: string[];
  searchSuperOwnerParam?: PostTtCsvExportAsSuperOwnerParams;
  searchParam?: PostTtCsvExportAsOwnerParams | PostTtCsvExportAsTeacherParams;
  isAll?: boolean;
};

export const POST_TT_CSV_EXPORT_KEY = "post-tt-csv-export-key";

const SelectButton: React.FC<Props> = ({
  type,
  target_company_id,
  homework_template_id,
  learning_template_package_distribution_setting_id,
  common_custom_master_map_id,
  content_company_ids,
  school_ids,
  searchSuperOwnerParam,
  searchParam,
  isAll,
}) => {
  const history = useHistory();
  const { currentUser } = useAuth();
  const { t } = useTranslation(["common"]);
  const [anchorProfile, setAnchorProfile] = useState<null | HTMLElement>(null);
  const typeJa = getTypeTrans(type, t);

  const [postTtCsvExportAsSuperOwner] = usePostTtCsvExportAsSuperOwnerMutation({
    fixedCacheKey: POST_TT_CSV_EXPORT_KEY,
  });

  const [postTtCsvExportAsOwner] = usePostTtCsvExportAsOwnerMutation({
    fixedCacheKey: POST_TT_CSV_EXPORT_KEY,
  });

  const [postTtCsvExportAsTeacher] = usePostTtCsvExportAsTeacherMutation({
    fixedCacheKey: POST_TT_CSV_EXPORT_KEY,
  });

  const [postTtCsvExportAsAdmin] = usePostTtCsvExportAsAdminMutation({
    fixedCacheKey: POST_TT_CSV_EXPORT_KEY,
  });
  const [postTtCsvExportAsReviewer] = usePostTtCsvExportAsReviewerMutation({
    fixedCacheKey: POST_TT_CSV_EXPORT_KEY,
  });

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.id === "csv-export-menu") {
      setAnchorProfile(e.currentTarget);
    }
  };
  const handleAnchorClose = () => {
    setAnchorProfile(null);
  };

  const handleRedirect = () => {
    if (currentUser) {
      history.push({
        pathname: `/${currentUser.current_role}/csv_export`,
        search: `type=${type}`,
        state: { fromList: true },
      });
    }
  };

  const postParamAsSuperOwner = {
    sortedBy: searchSuperOwnerParam?.sorted_by,
    searchStatus: searchSuperOwnerParam?.search_status,
    searchType: searchSuperOwnerParam?.search_type,
    searchValue: searchSuperOwnerParam?.search_value,
    ttCsvExportRequestBody: {
      type,
      target_company_id,
      homework_template_id,
      learning_template_package_distribution_setting_id,
      common_custom_master_map_id,
      content_company_ids,
      school_ids,
    },
  };

  const postParam = {
    searchValue: searchParam?.search_value,
    body: {
      type,
      target_company_id,
      homework_template_id,
      learning_template_package_distribution_setting_id,
      common_custom_master_map_id,
      content_company_ids,
      school_ids,
    },
  };

  const postParamAsAdminAndReviewer = {
    ttCsvExportRequestBody: {
      type,
      target_company_id,
      homework_template_id,
      learning_template_package_distribution_setting_id,
      common_custom_master_map_id,
      content_company_ids,
      school_ids,
    },
  };

  const handleExportCsv = () => {
    switch (currentUser?.current_role) {
      case roles.SUPER_OWNER:
        postTtCsvExportAsSuperOwner(postParamAsSuperOwner);
        break;
      case roles.OWNER:
        postTtCsvExportAsOwner(postParam);
        break;
      case roles.TEACHER:
        postTtCsvExportAsTeacher(postParam);
        break;
      case roles.ADMIN:
        postTtCsvExportAsAdmin(postParamAsAdminAndReviewer);
        break;
      case roles.REVIEWER:
        postTtCsvExportAsReviewer(postParamAsAdminAndReviewer);
        break;
      default:
        return;
    }
    handleRedirect();
  };

  return (
    <>
      <SingleButton
        data-cy="csv-export-menu-button"
        id="csv-export-menu"
        variant="outlined"
        onClick={handleOpenMenu}
        startIcon={GetButtonIconType(ButtonIconType.Download)}
      >
        {isAll
          ? t("all_export", { type: typeJa })
          : t("export", { type: typeJa })}
      </SingleButton>
      <CustomMenu
        menuItems={[
          {
            key: "csv-export",
            title: t("csv_export"),
            action: handleExportCsv,
          },
          {
            key: "csv-list",
            title: t("csv_list"),
            action: handleRedirect,
          },
        ]}
        id="csv-export-menu"
        anchorEl={anchorProfile}
        open={Boolean(anchorProfile)}
        onClose={handleAnchorClose}
      />
    </>
  );
};

SelectButton.defaultProps = {
  target_company_id: undefined,
  homework_template_id: undefined,
  learning_template_package_distribution_setting_id: undefined,
  common_custom_master_map_id: undefined,
  content_company_ids: undefined,
  school_ids: undefined,
  searchSuperOwnerParam: undefined,
  searchParam: undefined,
  isAll: false,
};

export default SelectButton;
