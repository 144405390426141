/* eslint-disable camelcase */
import { Api, FetchMaterialsAsStudentParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RoleApiFunctions } from "@root/types/materials";
import { RootState } from "@store/store";

const api = new Api();

export const fetchMaterialAsStudent = createAsyncThunk<
  GetResponseType<Api["student"]["fetchMaterialAsStudent"]>,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/materials/fetchOne", async (materialId, { rejectWithValue }) => {
  try {
    const response = await api.student.fetchMaterialAsStudent(materialId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchMaterialsAsStudent = createAsyncThunk<
  GetResponseType<Api["student"]["fetchMaterialsAsStudent"]>,
  FetchMaterialsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/materials/fetch",
  async (params: FetchMaterialsAsStudentParams, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchMaterialsAsStudent(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

type StudentActionsType = {
  student: RoleApiFunctions;
};

export const studentMaterialsActions: StudentActionsType = {
  student: {
    getMaterials: fetchMaterialsAsStudent,
  },
};
