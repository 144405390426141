import {
  batchDelete,
  batchUpdate,
  fetchHomeworkAsOwner,
  fetchOneHomeworkAsOwner,
  fileDeleted as fileDeletedAction,
  fileUploaded,
  resetDeleteActionState,
  resetModalState,
  resetState,
  resetUpdateState,
  updatedHomework,
  updateOneHomeworkAsOwner,
} from "@actions/ownerApp/homework";
import { Homework, HomeworkDetails } from "@lib/Api";
import { removeMany, update } from "@lib/collection";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice, current } from "@reduxjs/toolkit";
import { ReduxStatus } from "@root/constants/redux";

import { RootState } from "../../store";

export interface OwnerAppHomeworkState {
  fetching: boolean;
  error: string;
  updateOneHomeworkErrorMsg: string;
  homeworks: Homework[];
  homework: HomeworkDetails | null;
  totalCount: number;
  batchChangeErrors: string[];
  deleting: boolean;
  deleted: boolean;
  updating: boolean;
  updated: boolean;
  updatedHomeworkStatus: string;
}

export const initialState: OwnerAppHomeworkState = {
  fetching: false,
  error: "",
  updateOneHomeworkErrorMsg: "",
  homeworks: [],
  homework: null,
  totalCount: 0,
  batchChangeErrors: [],
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  updatedHomeworkStatus: ReduxStatus.idle,
};

const ownerAppHomeworkSlice = createSlice({
  name: "OwnerApp/Homework",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeworkAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: "",
        };
      })
      .addCase(fetchHomeworkAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: errorWithMessage(action.payload),
          homeworks: [],
        };
      })
      .addCase(fetchHomeworkAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          error: "",
          homeworks: payload.homeworks,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchOneHomeworkAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: "",
        };
      })
      .addCase(fetchOneHomeworkAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: errorWithMessage(action.payload),
          homework: null,
        };
      })
      .addCase(fetchOneHomeworkAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          error: "",
          homework: payload,
        };
      })
      .addCase(updateOneHomeworkAsOwner.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(updateOneHomeworkAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          updateOneHomeworkErrorMsg: action.payload,
        };
      })
      .addCase(updateOneHomeworkAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          updateOneHomeworkErrorMsg: "",
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addCase(batchUpdate.pending, (state, _action) => {
        return {
          ...state,
          updatedHomeworkStatus: ReduxStatus.pending,
          error: "",
        };
      })
      .addCase(batchUpdate.rejected, (state, action) => {
        return {
          ...state,
          updatedHomeworkStatus: ReduxStatus.rejected,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(batchUpdate.fulfilled, (state, action) => {
        return {
          ...state,
          updatedHomeworkStatus: ReduxStatus.fulfilled,
          error: "",
        };
      })
      .addCase(batchDelete.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          batchChangeErrors: [],
        };
      })
      .addCase(batchDelete.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(batchDelete.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          deleting: false,
          deleted: true,
          batchChangeErrors: payload.errors,
          error: "",
          homeworks: removeMany(state.homeworks, payload.deleted_ids),
          totalCount: state.totalCount - payload.deleted_ids.length,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          batchChangeErrors: [],
          deleting: false,
          deleted: false,
          error: "",
        };
      })
      .addCase(resetDeleteActionState, (state, _action) => {
        return {
          ...state,
          batchChangeErrors: [],
          deleted: false,
          error: "",
        };
      })
      .addCase(updatedHomework, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updated: true,
          updating: false,
          uploaded: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addCase(fileUploaded, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          uploaded: true,
          updating: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addCase(fileDeletedAction, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          fileDeleted: true,
          updating: false,
          homework: payload,
          homeworks: update(currentState.homeworks, payload),
        };
      })
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          fetching: false,
          error: "",
          homeworks: [],
          homework: null,
          totalCount: 0,
          batchChangeErrors: [],
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
        };
      })
      .addCase(resetUpdateState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppHomeworkState = (
  state: RootState,
): OwnerAppHomeworkState => state.ownerApp.homework;

export default ownerAppHomeworkSlice.reducer;
