import { Box, SvgIconTypeMap, SxProps, Theme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";
import { IconType } from "react-icons";

const useStyles = () => ({
  container: (theme: Theme) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(2),
      flexDirection: "column",
    },
  }),
  filterTitle: (theme: Theme) => ({
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: "100px",
    alignItems: "center",
    display: "flex",
    flexShrink: 0,
    whiteSpace: "pre-line",
  }),
  icon: {
    height: 18,
    width: 18,
  },
});

export const useFilterStyles = () => ({
  inputContainer: (theme: Theme) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(2),
      alignItems: "flex-start",
      flexDirection: "column",
    },
  }),
  filterInputTitle: (theme: Theme) => ({
    gap: theme.spacing(1),
    minWidth: "100px",
    alignItems: "center",
    display: "flex",
    flexShrink: 0,
    whiteSpace: "pre-line",
  }),
  filterInputTitleWide: (theme: Theme) => ({
    gap: theme.spacing(1),
    minWidth: 160,
    alignItems: "center",
    display: "flex",
    flexShrink: 0,
    whiteSpace: "pre-line",
  }),
  filterTitleWide: (theme: Theme) => ({
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 160,
    alignItems: "center",
    display: "flex",
    flexShrink: 0,
    whiteSpace: "pre-line",
  }),
  searchInputWide: (theme: Theme) => ({
    backgroundColor: theme.palette.common.white,
    minWidth: 280,
    [theme.breakpoints.down("sm")]: {
      minWidth: 230,
    },
  }),
  icon: {
    height: 18,
    width: 18,
    "& path": { fillOpacity: 1 },
  },
});

export type ClassNameType = {
  container?: SxProps<Theme>;
  title?: SxProps<Theme>;
  icon?: SxProps<Theme>;
};

type Props = {
  IconComponent: // eslint-disable-next-line @typescript-eslint/ban-types
  | OverridableComponent<SvgIconTypeMap<{}, "svg">>
    | React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  className?: ClassNameType;
  dataCy?: { container?: string; title?: string; icon?: string };
  ReactIcon?: IconType;
  reactIconSize?: number;
  children: React.ReactNode;
};

const V2FilterSelectorSection: React.FC<Props> = ({
  IconComponent,
  title,
  className,
  dataCy,
  children,
  ReactIcon,
  reactIconSize,
}) => {
  const classes = useStyles();
  return (
    <Box
      sx={className?.container ? className.container : classes.container}
      data-cy={dataCy?.container}
    >
      <Box
        sx={className?.title ? className?.title : classes.filterTitle}
        data-cy={dataCy?.title}
      >
        {ReactIcon ? (
          <ReactIcon size={reactIconSize ?? 24} />
        ) : (
          <Box
            component={IconComponent}
            sx={className?.icon ? className?.icon : classes.icon}
            data-cy={dataCy?.icon}
          />
        )}
        {title}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

V2FilterSelectorSection.defaultProps = {
  className: undefined,
  dataCy: undefined,
};

export default V2FilterSelectorSection;
