/* eslint-disable default-param-last */

import dayjs, { ManipulateType, OpUnitType } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { isNil } from "lodash";

dayjs.extend(isSameOrBefore);

export const isValidDuration = (
  startTime: string | undefined,
  endTime: string | undefined,
): boolean => {
  if (startTime && endTime) {
    return dayjs(startTime).isSameOrBefore(dayjs(endTime));
  }
  return true;
};

export const isAfterTime = (
  time1?: string,
  time2?: number,
  timeUnit: ManipulateType = "m",
): boolean => {
  if (time1 && time2) {
    return dayjs(time1).isAfter(dayjs().add(time2, timeUnit));
  }
  return true;
};

export const formatedDate = (
  dateTime: string | null | undefined | number,
  useSlash = false,
): string => {
  const template = useSlash ? "YYYY/MM/DD" : "YYYY-MM-DD";
  return dayjs(dateTime).tz("Asia/Tokyo").format(template);
};

/** dateTime: nullかundefinedを渡す場合にnew Date()が指定されたフォーマットで返される */
export const formatedDateTime = (
  dateTime: string | null | undefined | number,
  useSlash = false,
  endOfUnit?: OpUnitType,
): string => {
  const template = useSlash ? "YYYY/MM/DDTHH:mm" : "YYYY-MM-DDTHH:mm";
  return endOfUnit
    ? dayjs(dateTime).endOf(endOfUnit).format(template)
    : dayjs(dateTime).format(template);
};
export const formattedDateWithTime = (
  dateTime: string | null | undefined,
  useSlash = false,
  endOfUnit?: OpUnitType,
): string => {
  if (isNil(dateTime)) {
    return "";
  }
  const template = useSlash ? "YYYY/MM/DD HH:mm" : "YYYY-MM-DD HH:mm";
  return endOfUnit
    ? dayjs(dateTime).endOf(endOfUnit).format(template)
    : dayjs(dateTime).format(template);
};

export const isSameDate = (
  targetDateA: string,
  targetDateB: string,
): boolean => {
  return formatedDate(targetDateA) === formatedDate(targetDateB);
};

export const isSameDateTime = (
  targetDateA: string,
  targetDateB: string,
): boolean => {
  return formatedDateTime(targetDateA) === formatedDateTime(targetDateB);
};

export const isXYear = (
  targetDateA: string,
  targetDateB: string,
  x: number,
): boolean => {
  return (
    parseInt(dayjs(targetDateA).subtract(3, "months").format("YYYY"), 10) +
      x ===
    parseInt(dayjs(targetDateB).subtract(3, "months").format("YYYY"), 10)
  );
};

export const isFirstYear = (
  targetDateA: string,
  targetDateB: string,
): boolean => {
  return isXYear(targetDateA, targetDateB, 0);
};

export const isSecondYear = (
  targetDateA: string,
  targetDateB: string,
): boolean => {
  return isXYear(targetDateA, targetDateB, 1);
};

export const isThirdYear = (
  targetDateA: string,
  targetDateB: string,
): boolean => {
  return isXYear(targetDateA, targetDateB, 2);
};
