import {
  fetchPblReportAnswerAsStudent,
  fetchPblReportAnswersAsStudent,
  resetPblReportAnswerRelatedData,
  updatePblReportAnswerAsStudent,
} from "@actions/studentApp/pbl/report/answer";
import {
  createPblReportAnswerImagesAsStudent,
  deletePblReportAnswerImagesAsStudent,
} from "@actions/studentApp/pbl/report/answerImages";
import {
  createPblReportAnswerItemAsStudent,
  updatePblReportAnswerItemAsStudent,
} from "@actions/studentApp/pbl/report/answerItem";
import {
  PblReportAnswerDetailsForStudent,
  PblReportAnswerForStudent,
  PblReportAnswerImageBase,
} from "@lib/Api";
import { remove,update } from "@lib/collection";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface StudentAppPblReportAnswerState {
  fetching: boolean;
  fetchingOne: boolean;
  updatePblReportAnswer: ReduxStatusType;
  deletedFile: ReduxStatusType;
  fetchingError: string | null;
  fetchingOneError: string | null;
  answers: PblReportAnswerForStudent[];
  answer: PblReportAnswerDetailsForStudent | null;
  totalCount: number;
}

export const initialState: StudentAppPblReportAnswerState = {
  fetching: false,
  fetchingOne: false,
  updatePblReportAnswer: ReduxStatus.idle,
  deletedFile: ReduxStatus.idle,
  fetchingError: null,
  fetchingOneError: null,
  answers: [],
  answer: null,
  totalCount: 0,
};

const studentAppPblReportAnswerSlice = createSlice({
  name: "StudentApp/Pbl/Report/Answer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblReportAnswersAsStudent.pending, (state, _) => {
        return {
          ...state,
          fetching: true,
          fetchingError: null,
        };
      })
      .addCase(fetchPblReportAnswersAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchingError: action.error.message as string,
          answers: [],
        };
      })
      .addCase(fetchPblReportAnswersAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetchingError: null,
          answers: payload.answers,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchPblReportAnswerAsStudent.pending, (state, _) => {
        return {
          ...state,
          fetchingOne: true,
          fetchingOneError: null,
        };
      })
      .addCase(fetchPblReportAnswerAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchingOneError: action.error.message as string,
          answer: null,
        };
      })
      .addCase(fetchPblReportAnswerAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchingOneError: null,
          answer: payload,
        };
      })
      .addCase(updatePblReportAnswerAsStudent.pending, (state) => {
        return {
          ...state,
          updatePblReportAnswer: ReduxStatus.pending,
        };
      })
      .addCase(updatePblReportAnswerAsStudent.fulfilled, (state) => {
        return {
          ...state,
          updatePblReportAnswer: ReduxStatus.fulfilled,
        };
      })
      .addCase(updatePblReportAnswerAsStudent.rejected, (state) => {
        return {
          ...state,
          updatePblReportAnswer: ReduxStatus.rejected,
        };
      })
      .addCase(createPblReportAnswerItemAsStudent.pending, (state) => {
        return {
          ...state,
        };
      })
      .addCase(
        createPblReportAnswerItemAsStudent.fulfilled,
        (state, action) => {
          let newAnswer: PblReportAnswerDetailsForStudent | null = state.answer;
          if (state.answer) {
            newAnswer = {
              ...state.answer,
              answer_items: [...state.answer.answer_items, action.payload],
            };
          }
          return {
            ...state,
            answer: newAnswer,
          };
        },
      )
      .addCase(createPblReportAnswerItemAsStudent.rejected, (state) => {
        return {
          ...state,
        };
      })
      .addCase(
        updatePblReportAnswerItemAsStudent.fulfilled,
        (state, action) => {
          let newAnswer: PblReportAnswerDetailsForStudent | null = state.answer;
          if (state.answer) {
            newAnswer = {
              ...state.answer,
              answer_items: update(state.answer.answer_items, action.payload),
            };
          }
          return {
            ...state,
            answer: newAnswer,
          };
        },
      )
      .addCase(createPblReportAnswerImagesAsStudent.pending, (state) => {
        return {
          ...state,
        };
      })
      .addCase(
        createPblReportAnswerImagesAsStudent.fulfilled,
        (state, action) => {
          let newAnswer: PblReportAnswerDetailsForStudent | null = state.answer;
          if (state.answer) {
            newAnswer = {
              ...state.answer,
              answer_images: [...state.answer.answer_images, action.payload],
            };
          }
          return {
            ...state,
            answer: newAnswer,
          };
        },
      )
      .addCase(createPblReportAnswerImagesAsStudent.rejected, (state) => {
        return {
          ...state,
        };
      })
      .addCase(deletePblReportAnswerImagesAsStudent.pending, (state) => {
        return {
          ...state,
          deletedFile: ReduxStatus.pending,
        };
      })
      .addCase(
        deletePblReportAnswerImagesAsStudent.fulfilled,
        (state, action) => {
          let newAnswer: PblReportAnswerDetailsForStudent | null = state.answer;
          if (state.answer) {
            newAnswer = {
              ...state.answer,
              answer_images: [
                ...remove<PblReportAnswerImageBase>(
                  state.answer.answer_images,
                  action.meta.arg.id,
                ),
              ],
            };
          }
          return {
            ...state,
            answer: newAnswer,
            deletedFile: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(deletePblReportAnswerImagesAsStudent.rejected, (state) => {
        return {
          ...state,
          deletedFile: ReduxStatus.rejected,
        };
      })
      .addCase(resetPblReportAnswerRelatedData, (state) => {
        return {
          ...state,
          fetching: false,
          fetchingOne: false,
          deletedFile: ReduxStatus.idle,
          fetchingError: null,
          fetchingOneError: null,
        };
      })
      .addDefaultCase((state, _) => {
        return state;
      });
  },
});

export const studentAppPblReportAnswerState = (
  state: RootState,
): StudentAppPblReportAnswerState => state.studentApp.pblReportAnswer;

export default studentAppPblReportAnswerSlice.reducer;
