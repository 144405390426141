/* eslint-disable camelcase */
import {
  getStudentsAffiliatesFromCompanies,
  getStudentsAffiliatesFromUserTags,
  postLearningTemplatePackageDistributions,
  resetTemplateDistributionState,
} from "@actions/superOwnerApp/templatePackages/learningTemplateDistribution";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { AffiliateStudentName } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface SuperOwnerAppLearningTemplateDistributionState {
  posting: boolean;
  posted: boolean;
  postSuccessMessage: string | null;
  postError: any;
  fetchStudentList: ReduxStatusType;
  studentsAffiliate: AffiliateStudentName[];
  totalCount: number;
}

const initialState: SuperOwnerAppLearningTemplateDistributionState = {
  posting: false,
  posted: false,
  postSuccessMessage: null,
  postError: null,
  fetchStudentList: ReduxStatus.idle,
  studentsAffiliate: [],
  totalCount: 0,
};

export const superOwnerAppLearningTemplateDistribution = createSlice({
  name: "superOwner/learningTemplateDistribution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        postLearningTemplatePackageDistributions.pending,
        (state, action) => {
          return {
            ...state,
            posting: true,
            posted: false,
          };
        },
      )
      .addCase(
        postLearningTemplatePackageDistributions.fulfilled,
        (state, action) => {
          return {
            ...state,
            postSuccessMessage: action.payload.message,
            posting: false,
            posted: true,
          };
        },
      )
      .addCase(
        postLearningTemplatePackageDistributions.rejected,
        (state, action) => {
          return {
            ...state,
            posting: false,
            posted: false,
            postError: action.payload,
          };
        },
      )
      .addCase(getStudentsAffiliatesFromCompanies.pending, (state, action) => {
        return {
          ...state,
          fetchStudentList: ReduxStatus.pending,
        };
      })
      .addCase(
        getStudentsAffiliatesFromCompanies.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetchStudentList: ReduxStatus.fulfilled,
            studentsAffiliate: action.payload.student_affiliates,
            totalCount: action.payload.total_count,
          };
        },
      )
      .addCase(getStudentsAffiliatesFromCompanies.rejected, (state, action) => {
        return {
          ...state,
          fetchStudentList: ReduxStatus.rejected,
        };
      })
      .addCase(getStudentsAffiliatesFromUserTags.pending, (state, action) => {
        return {
          ...state,
          fetchStudentList: ReduxStatus.pending,
        };
      })
      .addCase(getStudentsAffiliatesFromUserTags.fulfilled, (state, action) => {
        return {
          ...state,
          fetchStudentList: ReduxStatus.fulfilled,
          studentsAffiliate: action.payload.student_affiliates,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(getStudentsAffiliatesFromUserTags.rejected, (state, action) => {
        return {
          ...state,
          fetchStudentList: ReduxStatus.rejected,
        };
      })
      .addCase(resetTemplateDistributionState, (state) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postError: null,
          postSuccessMessage: null,
          fetchStudentList: ReduxStatus.idle,
        };
      });
  },
});

export const superOwnerAppLearningTemplateDistributionState = (
  state: RootState,
): SuperOwnerAppLearningTemplateDistributionState =>
  state.superOwnerApp.learningTemplateDistribution;

export default superOwnerAppLearningTemplateDistribution.reducer;
