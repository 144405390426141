import { fetchLearningTemplatePackageDistributionHistories } from "@actions/superOwnerApp/templatePackages/learningTemplateHistory";
import LearningTemplateHistoryTable from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateHistory";
import {
  LearningTemplateHistoryModalKind,
  LearningTemplateHistoryModalType,
} from "@components/SuperOwner/LearningTemplatePackages/types";
import { LearningTemplatePackageDistributionHistoryBase } from "@lib/Api";
import { superOwnerAppLearningTemplateDistributionHistoryState } from "@reducers/superOwnerApp/templatePackages/learningTemplateHistory";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import React, { useEffect,useState } from "react";

import { ContentType } from "../index";

interface Props {
  detailView: ContentType;
}

const LearningTemplateHistoryContainer: React.FC<Props> = ({ detailView }) => {
  const dispatch = useAppDispatch();
  const learningTemplateHistoryCache = useAppSelector(
    superOwnerAppLearningTemplateDistributionHistoryState,
  );
  const { fetching, fetched, fetchError, totalCount, learningTemplatePackage } =
    learningTemplateHistoryCache;

  const defaultState = {
    isOpen: false,
    kind: LearningTemplateHistoryModalKind.IDLE,
    learningTemplatePackage: null,
  };

  const [modalState, setModalState] =
    useState<LearningTemplateHistoryModalType>(defaultState);

  const handleModalState = (
    data: LearningTemplatePackageDistributionHistoryBase,
    kind: LearningTemplateHistoryModalKind,
  ) => {
    setModalState({
      isOpen: true,
      learningTemplatePackage: data,
      kind,
    });
  };

  const handleClose = () => {
    setModalState(defaultState);
  };

  const fetchDistributionHistories = (
    newPage?: number,
    newPerPage?: number,
  ) => {
    dispatch(
      fetchLearningTemplatePackageDistributionHistories({
        page: newPage,
        per_page: newPerPage,
      }),
    );
  };

  useEffect(() => {
    if (detailView === ContentType.PACKAGE_HISTORY) {
      fetchDistributionHistories();
    }
  }, [detailView]);

  return (
    <LearningTemplateHistoryTable
      totalCount={totalCount}
      fetchDistributionHistories={fetchDistributionHistories}
      fetched={fetched}
      fetching={fetching}
      fetchError={fetchError}
      learningTemplatePackage={learningTemplatePackage}
      handleModalState={handleModalState}
      handleClose={handleClose}
      modalState={modalState}
    />
  );
};

export default LearningTemplateHistoryContainer;
