import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      flex: 1,
      padding: theme.spacing(6, 4),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3, 1),
      },
    },
  }),
);

type Props = {
  children: React.ReactNode;
};

const MainWrapper: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return <main className={classes.main}>{children}</main>;
};

export default MainWrapper;
