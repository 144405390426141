/* eslint-disable no-case-declarations */
import { getHelpCategories } from "@actions/common";
import { CategoryType, MinorCategoryInfo } from "@lib/Api";
import { remove, removeChildren, update } from "@lib/collection";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  createCategoryAsOwner,
  deleteCategoryAsOwner,
  fetchCategoriesAsOwner,
  saveSequenceAsOwner,
  updateCategoryAsOwner,
} from "../../actions/ownerApp/category";
import { fetchCategoriesAsStudent } from "../../actions/studentApp/category";
import {
  createCategoryAsSuperOwner,
  deleteCategoryAsSuperOwner,
  fetchCategoriesAsSuperOwner,
  saveSequenceAsSuperOwner,
  updateCategoryAsSuperOwner,
} from "../../actions/superOwnerApp/category";
import { fetchCategoriesAsTeacher } from "../../actions/teacherApp/category";

export interface MinorCategoryState {
  items: MinorCategoryInfo[];
}

const initialState: MinorCategoryState = {
  items: [],
};

const minorReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: PayloadAction<any>,
): MinorCategoryState => {
  const { payload } = action;
  const { type, updatedItems, categoryType } = payload;
  switch (action.type) {
    case fetchCategoriesAsOwner.fulfilled.type:
      return { ...state, items: payload.minor_categories };
    case fetchCategoriesAsStudent.fulfilled.type:
      return { ...state, items: payload.minor_categories };
    case fetchCategoriesAsTeacher.fulfilled.type:
      return { ...state, items: payload.minor_categories };
    case fetchCategoriesAsSuperOwner.fulfilled.type:
      return { ...state, items: payload.minor_categories };
    case getHelpCategories.fulfilled.type:
      return { ...state, items: payload.minor_categories };
    case createCategoryAsOwner.fulfilled.type:
      return {
        ...state,
        items: [...state.items, payload],
      };
    case updateCategoryAsOwner.fulfilled.type:
      return {
        ...state,
        items: update(state.items, payload),
      };
    case deleteCategoryAsOwner.fulfilled.type:
      return {
        ...state,
        items:
          categoryType === CategoryType.Middle
            ? removeChildren(state.items, payload.id, "middle_category_id")
            : remove(state.items, payload.id),
      };
    case createCategoryAsSuperOwner.fulfilled.type:
      return {
        ...state,
        items: [...state.items, payload],
      };
    case updateCategoryAsSuperOwner.fulfilled.type:
      return {
        ...state,
        items: update(state.items, payload),
      };
    case deleteCategoryAsSuperOwner.fulfilled.type:
      return {
        ...state,
        items:
          categoryType === CategoryType.Middle
            ? removeChildren(state.items, payload.id, "middle_category_id")
            : remove(state.items, payload.id),
      };
    case saveSequenceAsOwner.fulfilled.type:
      return {
        ...state,
        items:
          type === CategoryType.Minor
            ? (updatedItems as MinorCategoryInfo[])
            : state.items,
      };
    case saveSequenceAsSuperOwner.fulfilled.type:
      return {
        ...state,
        items:
          type === CategoryType.Minor
            ? (updatedItems as MinorCategoryInfo[])
            : state.items,
      };
    default:
      return state;
  }
};

export default minorReducer;
