import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { Path, useFormState } from "react-hook-form";

import { saveColumnName, SVEditorFormValue } from "../types";

type Props<T extends SVEditorFormValue> = {
  FormValueName: Path<T>;
};

const EditorError = <T extends SVEditorFormValue>({
  FormValueName,
}: Props<T>): JSX.Element => {
  const { errors } = useFormState<T>();
  return (
    <>
      {errors[FormValueName as saveColumnName] && (
        <Box>
          <Typography variant="caption" color="error">
            {errors[FormValueName as saveColumnName]?.message as string}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default memo(EditorError) as typeof EditorError;
