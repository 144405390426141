import TableBodyLoading from "@components/UI/organisms/TableBodyLoading/TableBodyLoading";
import {
  CommonStyledTableThCell,
  StyledTableContainer,
} from "@components/UI/organisms/Tables/TableComponents";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import RowSection from "./RowSection";
import { BodyItemsTypes, HeaderItemsTypes } from "./tableTypes";

export type Props<T> = {
  /**
   * Pass an array of headers
   */
  headers: HeaderItemsTypes[];
  /**
   * Pass an array of Elements for the body
   */
  bodyItems: BodyItemsTypes<T>[];
  /**
   * Pass an array of Elements for the body
   */
  isLoading: boolean;
  /**
   * Enter a message when there is not data
   */
  noDataMessage: string;
  /**
   * Pass an onClick action when for going to detail
   */
  onClick?: (bodyItem: BodyItemsTypes<T>) => void;
  onMouseEnter?: (bodyItem: BodyItemsTypes<T>) => void;
  onMouseLeave?: () => void;
  /**
   * Decorate for selected row
   */
  selectedRow?: string;
};

const CommonTable = <T,>({
  headers,
  bodyItems,
  isLoading,
  noDataMessage,
  onClick,
  selectedRow,
  ...rest
}: Props<T>): JSX.Element => {
  let body: React.ReactNode;

  // If you include accordion function to this component
  // Don't forget to add the AccordionElement Prop inside bodyItems

  const hasAccordion = bodyItems.some((item) => item.accordionElement);
  const colCount = hasAccordion ? headers.length + 1 : headers.length;

  if (isLoading) {
    body = <TableBodyLoading colCount={colCount} />;
  } else if (bodyItems?.length) {
    body = (
      <TableBody data-cy="common-table-body">
        {bodyItems.map((bodyItem) => (
          <RowSection
            {...rest}
            key={bodyItem.id}
            bodyItem={bodyItem}
            accordionElement={bodyItem.accordionElement}
            colCount={colCount}
            hasAccordion={hasAccordion}
            onClick={onClick}
          />
        ))}
      </TableBody>
    );
  } else {
    body = (
      <TableBody data-cy="common-table-body">
        <TableRow>
          <TableCell data-cy="common-table-no-data">{noDataMessage}</TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <StyledTableContainer data-cy="common-table-container">
      <Table>
        <TableHead data-cy="common-table-header">
          <TableRow>
            {headers.map((header) => (
              <CommonStyledTableThCell key={header.id} align={header.align}>
                {header.title}
              </CommonStyledTableThCell>
            ))}
            {hasAccordion && <CommonStyledTableThCell />}
          </TableRow>
        </TableHead>
        {body}
      </Table>
    </StyledTableContainer>
  );
};

export default CommonTable;
