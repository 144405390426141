import {
  checkTemplateConvertStatusAsTeacher,
  createHomeworkTemplateAsTeacher,
  createHomeworkTemplateChunk,
  deleteHomeworkTemplateAsTeacher,
  deleteHomeworkTemplateChunks,
  fetchHomeworkTemplateAsTeacher,
  fetchHomeworkTemplateChunks,
  fetchHomeworkTemplatesAsTeacher,
  fetchHomeworkTemplateStatsAsTeacher,
  resetConvertedState,
  resetModalState,
  resetTemplateRelatedData,
  successUpdateTemplateAsTeacher,
  updateHomeworkTemplateAsTeacher,
} from "@actions/teacherApp/homeworkTemplate";
import {
  assignSchoolManagerAsTeacher,
  returnToStudentsAsTeacher,
  revertSubmissionToReviewerAsTeacher,
  submitToReviewerAsTeacher,
  updateAsTeacher as updateDistributionAsTeacher,
} from "@actions/teacherApp/homeworkTemplateDistribution";
import {
  HomeworkTemplateChunk,
  HomeworkTemplateConvertStatus,
  HomeworkTemplateDistribution,
  HomeworkTemplateForOwnerAndTeacher,
} from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { HomeworkTemplateStatusGraph } from "@root/components/Teacher/TeacherDashboard/HomeworkTemplateStatus/types";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";

import { RootState } from "../../store";

export interface TeacherAppHomeworkTemplateState {
  fetching: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  changeError: string | null;
  fetchError: string | null;
  fetchOneError: string | null;
  templates: HomeworkTemplateForOwnerAndTeacher[];
  template: HomeworkTemplateForOwnerAndTeacher | null;
  chunks: HomeworkTemplateChunk[];
  templateStats: HomeworkTemplateStatusGraph[];
  totalCount: number;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  creatingChunk: boolean;
  createdChunk: boolean;
  fetchingStats: boolean;
  fetchedStats: boolean;
  fetchStatsError: string | null;
  deleteError: string | null;
  deleteChunkError: string | null;
  deleteChunkStatus: ReduxStatusType;
  fetchChunksStatus: ReduxStatusType;
  checkConvertStatus: ReduxStatusType;
  templateForConvert: HomeworkTemplateForOwnerAndTeacher | null;
  converted: number;
  successUpdate: boolean;
}

export const initialState: TeacherAppHomeworkTemplateState = {
  fetching: false,
  fetchingOne: false,
  fetchedOne: false,
  fetchError: null,
  fetchOneError: null,
  changeError: null,
  deleteError: null,
  deleteChunkError: null,
  templates: [],
  template: null,
  chunks: [],
  totalCount: 0,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  creatingChunk: false,
  createdChunk: false,
  fetchingStats: false,
  fetchedStats: false,
  fetchStatsError: null,
  templateStats: [],
  deleteChunkStatus: ReduxStatus.idle,
  fetchChunksStatus: ReduxStatus.idle,
  checkConvertStatus: ReduxStatus.idle,
  templateForConvert: null,
  converted: 0,
  successUpdate: false,
};

const updateStatus = (
  converted: number,
  statusCode: HomeworkTemplateConvertStatus,
): number => {
  if (
    converted === 0 &&
    statusCode !== HomeworkTemplateConvertStatus.Processed &&
    statusCode !== HomeworkTemplateConvertStatus.Failed
  ) {
    return 1;
  }
  if (
    converted === 1 &&
    (statusCode === HomeworkTemplateConvertStatus.Processed ||
      statusCode === HomeworkTemplateConvertStatus.Failed)
  ) {
    return 2;
  }
  return converted;
};

const teacherAppHomeworkTemplateSlice = createSlice({
  name: "TeacherApp/HomeworkTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeworkTemplatesAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetchError: null,
        };
      })
      .addCase(fetchHomeworkTemplatesAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: action.payload,
          templates: [],
        };
      })
      .addCase(fetchHomeworkTemplatesAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetchError: null,
          templates: payload.homework_templates,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchHomeworkTemplateAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
          fetchOneError: null,
        };
      })
      .addCase(fetchHomeworkTemplateAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.payload,
          template: null,
        };
      })
      .addCase(fetchHomeworkTemplateAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          fetchOneError: null,
          template: payload,
        };
      })
      .addCase(
        checkTemplateConvertStatusAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            checkConvertStatus: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        checkTemplateConvertStatusAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            checkConvertStatus: ReduxStatus.rejected,
            templateForConvert: null,
          };
        },
      )
      .addCase(
        checkTemplateConvertStatusAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          return {
            ...state,
            checkConvertStatus: ReduxStatus.fulfilled,
            templateForConvert: payload,
            converted: updateStatus(
              currentState.converted,
              payload.convert_status_code,
            ),
          };
        },
      )
      .addCase(createHomeworkTemplateAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          changeError: null,
        };
      })
      .addCase(createHomeworkTemplateAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          creating: false,
          created: true,
          templates: [...currentState.templates, payload],
          template: payload,
          totalCount: currentState.totalCount + 1,
          changeError: null,
        };
      })
      .addCase(createHomeworkTemplateAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeError: action.payload,
        };
      })
      .addCase(updateHomeworkTemplateAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeError: null,
        };
      })
      .addCase(updateHomeworkTemplateAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          templates: update(currentState.templates, payload),
          template: payload,
          changeError: null,
        };
      })
      .addCase(updateHomeworkTemplateAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: action.payload,
        };
      })

      .addCase(deleteHomeworkTemplateAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          changeError: null,
        };
      })
      .addCase(deleteHomeworkTemplateAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          deleting: false,
          deleted: true,
          templates: remove(currentState.templates, payload.id),
          template: null,
          changeError: null,
          totalCount: currentState.totalCount - 1,
        };
      })
      .addCase(deleteHomeworkTemplateAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          changeError: action.payload,
        };
      })
      .addCase(
        fetchHomeworkTemplateStatsAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingStats: true,
            fetchedStats: false,
            fetchStatsError: null,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateStatsAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingStats: false,
            fetchedStats: true,
            fetchStatsError: null,
            templateStats: payload.homework_templates,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateStatsAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingStats: true,
            fetchedStats: false,
            fetchStatsError: action.payload,
          };
        },
      )
      .addCase(assignSchoolManagerAsTeacher.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(assignSchoolManagerAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: action.payload,
        };
      })
      .addCase(assignSchoolManagerAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        const oldTemplate =
          state.template as HomeworkTemplateForOwnerAndTeacher;
        const newTemplate = {
          ...oldTemplate,
          homework_template_distribution: {
            ...(oldTemplate.homework_template_distribution as HomeworkTemplateDistribution),
            school_manager: {
              id: payload.teacherId,
              name: payload.teacherName,
            },
          },
        };
        return {
          ...state,
          updating: false,
          updated: true,
          template: newTemplate,
          templates: update(currentState.templates, newTemplate),
        };
      })
      .addCase(submitToReviewerAsTeacher.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(submitToReviewerAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: action.payload,
        };
      })
      .addCase(submitToReviewerAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          template: payload,
          templates: update(currentState.templates, payload),
        };
      })
      .addCase(revertSubmissionToReviewerAsTeacher.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(
        revertSubmissionToReviewerAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: false,
            changeError: action.payload,
          };
        },
      )
      .addCase(
        revertSubmissionToReviewerAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          return {
            ...state,
            updating: false,
            updated: true,
            template: payload,
            templates: update(currentState.templates, payload),
          };
        },
      )
      .addCase(returnToStudentsAsTeacher.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(returnToStudentsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: action.payload,
        };
      })
      .addCase(returnToStudentsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          template: payload,
          templates: update(currentState.templates, payload),
        };
      })
      .addCase(updateDistributionAsTeacher.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
        };
      })
      .addCase(updateDistributionAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
        };
      })
      .addCase(updateDistributionAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          template: payload,
          templates: update(currentState.templates, payload),
        };
      })
      .addCase(fetchHomeworkTemplateChunks.pending, (state, _action) => {
        return {
          ...state,
          fetchChunksStatus: ReduxStatus.pending,
          fetchError: null,
        };
      })
      .addCase(fetchHomeworkTemplateChunks.rejected, (state, action) => {
        return {
          ...state,
          fetchChunksStatus: ReduxStatus.rejected,
          fetchError: action.payload,
          chunks: [],
        };
      })
      .addCase(fetchHomeworkTemplateChunks.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchChunksStatus: ReduxStatus.fulfilled,
          fetchError: null,
          chunks: payload.chunks,
        };
      })
      .addCase(createHomeworkTemplateChunk.pending, (state, _action) => {
        return {
          ...state,
          creatingChunk: true,
          createdChunk: false,
          createChunkError: null,
        };
      })
      .addCase(createHomeworkTemplateChunk.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          creatingChunk: false,
          createdChunk: true,
          chunks: [...state.chunks, payload],
          createChunkError: null,
        };
      })
      .addCase(createHomeworkTemplateChunk.rejected, (state, action) => {
        return {
          ...state,
          creatingChunk: false,
          createdChunk: false,
          createChunkError: action.payload,
        };
      })
      .addCase(deleteHomeworkTemplateChunks.pending, (state, _action) => {
        return {
          ...state,
          deleteChunkStatus: ReduxStatus.pending,
          deleteChunkError: null,
        };
      })
      .addCase(deleteHomeworkTemplateChunks.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          deleteChunkStatus: ReduxStatus.fulfilled,
          chunks: [...state.chunks, payload],
          deleteChunkError: null,
        };
      })
      .addCase(deleteHomeworkTemplateChunks.rejected, (state, action) => {
        return {
          ...state,
          deleteChunkStatus: ReduxStatus.rejected,
          deleteChunkError: action.payload,
        };
      })
      .addCase(resetTemplateRelatedData, (state, _action) => {
        return {
          ...state,
          changeError: null,
          deleteError: null,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: null,
          template: null,
          creatingChunk: false,
          createdChunk: false,
          deleteChunkStatus: ReduxStatus.idle,
          deleteChunkError: null,
          successUpdate: false,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          creating: false,
          created: false,
          changeError: null,
          deleteError: null,
          fetchingOne: false,
          fetchOneError: null,
          creatingChunk: false,
          createdChunk: false,
          deleteChunkError: null,
          successUpdate: false,
        };
      })
      .addCase(resetConvertedState, (state, _action) => {
        return {
          ...state,
          converted: 0,
        };
      })
      .addCase(successUpdateTemplateAsTeacher, (state, _action) => {
        return {
          ...state,
          successUpdate: true,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppHomeworkTemplateState = (
  state: RootState,
): TeacherAppHomeworkTemplateState => state.teacherApp.homeworkTemplate;

export default teacherAppHomeworkTemplateSlice.reducer;
