import {
  createPblSponsorInfoCategoryAsAdmin,
  deletePblSponsorInfoCategoryAsAdmin,
  resetAction,
  updatePblSponsorInfoCategoryAsAdmin,
} from "@actions/adminApp/pbl/sponsor/infoCategory";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface AdminAppPblSponsorInfoCategoryState {
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  changeErrors: any[] | null;
}

export const initialState: AdminAppPblSponsorInfoCategoryState = {
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  changeErrors: null,
};

const adminAppPblSponsorInfoCategorySlice = createSlice({
  name: "adminApp/pbl/sponsors/info/category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        createPblSponsorInfoCategoryAsAdmin.pending,
        (state, _action) => {
          return {
            ...state,
            creating: true,
            created: false,
            changeErrors: null,
          };
        },
      )
      .addCase(
        createPblSponsorInfoCategoryAsAdmin.rejected,
        (state, action) => {
          return {
            ...state,
            creating: false,
            created: false,
            changeErrors: action.payload,
          };
        },
      )
      .addCase(
        createPblSponsorInfoCategoryAsAdmin.fulfilled,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: true,
            changeErrors: null,
          };
        },
      )
      .addCase(
        updatePblSponsorInfoCategoryAsAdmin.pending,
        (state, _action) => {
          return {
            ...state,
            updating: true,
            updated: false,
            changeErrors: null,
          };
        },
      )
      .addCase(
        updatePblSponsorInfoCategoryAsAdmin.rejected,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: false,
            changeErrors: action.payload,
          };
        },
      )
      .addCase(
        updatePblSponsorInfoCategoryAsAdmin.fulfilled,
        (state, action) => {
          return {
            ...state,
            updating: false,
            updated: true,
            changeErrors: null,
          };
        },
      )
      .addCase(
        deletePblSponsorInfoCategoryAsAdmin.pending,
        (state, _action) => {
          return {
            ...state,
            deleting: true,
            deleted: false,
            changeErrors: null,
          };
        },
      )
      .addCase(
        deletePblSponsorInfoCategoryAsAdmin.rejected,
        (state, action) => {
          return {
            ...state,
            deleting: false,
            deleted: false,
            changeErrors: action.payload,
          };
        },
      )
      .addCase(
        deletePblSponsorInfoCategoryAsAdmin.fulfilled,
        (state, action) => {
          return {
            ...state,
            deleting: false,
            deleted: true,
            changeErrors: null,
          };
        },
      )
      .addCase(resetAction, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          changeErrors: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const adminAppPblSponsorInfoCategoryState = (
  state: RootState,
): AdminAppPblSponsorInfoCategoryState => state.adminApp.pblSponsorInfoCategory;

export default adminAppPblSponsorInfoCategorySlice.reducer;
