/* eslint-disable camelcase */
import {
  Api,
  FetchGoogleIntegrationCoursesAsStudentParams,
  FetchGoogleIntegrationCourseWorkMaterialsAsStudentParams,
  FetchGoogleIntegrationCourseWorksAsStudentParams,
} from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();
interface StudentCourses {
  course_work_id?: string;
  title?: string;
}
interface StudentClass {
  id?: string;
  course_id?: string;
  name?: string;
  section?: string;
  room?: string;
  courses?: StudentCourses[];
}
export type StudentClassRoom = {
  gi_courses: StudentClass[];
  total_count: number;
};

export type CoursesList = {
  id?: string;
  course_work_id?: string;
  title?: string;
  section?: string;
  room?: string;
};
interface DocumentContent {
  id?: string;
  material_id: string;
  title?: string;
  alternate_link: string;
  thumbnail_url: string;
}
export type DocumentList = {
  gi_materials: DocumentContent[];
  total_count: number;
};

export const getStudentClasses = createAsyncThunk<
  StudentClassRoom,
  any,
  { state: RootState; rejectWithValue: any }
>(
  "studentApp/getStudentClasses",
  async (
    param: FetchGoogleIntegrationCoursesAsStudentParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.student.fetchGoogleIntegrationCoursesAsStudent(param);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getStudentCouresList = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectWithValue: any }
>(
  "studentApp/getStudentCouresList",
  async (
    arg: FetchGoogleIntegrationCourseWorksAsStudentParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.student.fetchGoogleIntegrationCourseWorksAsStudent(arg);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getStudentCouresDocuments = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectWithValue: any }
>(
  "studentApp/getStudentCouresDocuments",
  async (
    arg: FetchGoogleIntegrationCourseWorkMaterialsAsStudentParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.student.fetchGoogleIntegrationCourseWorkMaterialsAsStudent(
          arg,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
