/* eslint-disable camelcase */

import {
  Api,
  ArticleDetailWithAdditionalInfo,
  FetchArticlesAsReviewerParams,
} from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticlesAsReviewerArg {
  params: FetchArticlesAsReviewerParams;
}

export const fetchArticlesAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/article/fetch",
  async (arg: FetchArticlesAsReviewerArg, { rejectWithValue }) => {
    try {
      const response = await api.reviewer.fetchArticlesAsReviewer(arg.params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchArticleAsReviewer = createAsyncThunk<
  ArticleDetailWithAdditionalInfo,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/article/fetchOne",
  async (articleId: string, { rejectWithValue }) => {
    try {
      const response = await api.reviewer.fetchArticleAsReviewer(articleId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const likeArticleAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/articleLike/like",
  async (articleId: string, { rejectWithValue }) => {
    try {
      const response = await api.reviewer.likeArticleAsReviewer(articleId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const unlikeArticleAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("reviewer/article/unlike", async (articleId: string, { rejectWithValue }) => {
  try {
    const response = await api.reviewer.unlikeArticleAsReviewer(articleId);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
