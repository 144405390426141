import {
  fetchPblSponsorLectureAsStudent,
  resetOne,
} from "@actions/studentApp/pbl/sponsor/lecture";
import { SponsorLectureDetailsBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface StudentAppPblSponsorLectureState {
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneErrors: string | null;
  lectureDetails: SponsorLectureDetailsBase | null;
}

export const initialState: StudentAppPblSponsorLectureState = {
  fetchingOne: false,
  fetchedOne: false,
  fetchOneErrors: null,
  lectureDetails: null,
};

const studentAppPblSponsorLectureSlice = createSlice({
  name: "studentApp/pbl/sponsors/lecture",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblSponsorLectureAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
        };
      })
      .addCase(fetchPblSponsorLectureAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error.message as string,
        };
      })
      .addCase(fetchPblSponsorLectureAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          fetchOneError: null,
          lectureDetails: payload,
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          lectureDetails: null,
          fetchedOne: false,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppPblSponsorLectureState = (
  state: RootState,
): StudentAppPblSponsorLectureState => state.studentApp.pblSponsorLecture;

export default studentAppPblSponsorLectureSlice.reducer;
