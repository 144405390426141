import {
  createHomeworkTemplateAsSuperOwner,
  deleteHomeworkTemplateAsSuperOwner,
  duplicateHomeworkTemplateAsSuperOwner,
  fetchHomeworkTemplateAsSuperOwner,
  fetchHomeworkTemplatesAsSuperOwner,
  resetModalState,
  resetTemplateRelatedData,
  successUpdateTemplateAsSuperOwner,
  updateHomeworkTemplateAsSuperOwner,
} from "@actions/superOwnerApp/homeworkTemplate";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { HomeworkTemplate, HomeworkTemplateBaseForSuperOwner } from "@lib/Api";
import { remove, update } from "@lib/collection";
import { errorWithMessage } from "@lib/rtk/error-utils";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface SuperOwnerAppHomeworkTemplateState {
  fetching: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  changeError: string | null;
  fetchError: string | null;
  fetchOneError: string | null;
  templates: HomeworkTemplateBaseForSuperOwner[];
  template: HomeworkTemplate | null;
  totalCount: number;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  successUpdate: boolean;
  duplicateTemplate: ReduxStatusType;
  duplicateTemplateError: string | null;
}

export const initialState: SuperOwnerAppHomeworkTemplateState = {
  fetching: false,
  fetchingOne: false,
  fetchedOne: false,
  fetchError: null,
  fetchOneError: null,
  changeError: null,
  templates: [],
  template: null,
  totalCount: 0,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  successUpdate: false,
  duplicateTemplate: ReduxStatus.idle,
  duplicateTemplateError: null,
};

const superOwnerAppHomeworkTemplateSlice = createSlice({
  name: "SuperOwnerApp/HomeworkTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeworkTemplatesAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetchError: null,
        };
      })
      .addCase(fetchHomeworkTemplatesAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: action.error.message as string,
          templates: [],
        };
      })
      .addCase(
        fetchHomeworkTemplatesAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            fetchError: null,
            templates: payload.homework_templates,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(fetchHomeworkTemplateAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchOneerror: null,
        };
      })
      .addCase(fetchHomeworkTemplateAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: action.error.message as string,
          template: null,
        };
      })
      .addCase(fetchHomeworkTemplateAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: null,
          template: payload,
        };
      })
      .addCase(createHomeworkTemplateAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          changeError: null,
        };
      })
      .addCase(
        createHomeworkTemplateAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          return {
            ...state,
            creating: false,
            created: true,
            templates: [...currentState.templates, payload],
            template: payload,
            totalCount: currentState.totalCount + 1,
            changeError: null,
          };
        },
      )
      .addCase(createHomeworkTemplateAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeError: action.error.message as string,
        };
      })
      .addCase(updateHomeworkTemplateAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeError: null,
        };
      })
      .addCase(
        updateHomeworkTemplateAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          return {
            ...state,
            templates: update(currentState.templates, payload),
            template: payload,
            changeError: null,
          };
        },
      )
      .addCase(updateHomeworkTemplateAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: action.payload as string,
        };
      })

      .addCase(deleteHomeworkTemplateAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          changeError: null,
        };
      })
      .addCase(
        deleteHomeworkTemplateAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          return {
            ...state,
            deleting: false,
            deleted: true,
            templates: remove(currentState.templates, payload.id),
            template: null,
            changeError: null,
            totalCount: currentState.totalCount - 1,
          };
        },
      )
      .addCase(deleteHomeworkTemplateAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          changeError: errorWithMessage(action.payload),
        };
      })
      .addCase(
        duplicateHomeworkTemplateAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            duplicateTemplate: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        duplicateHomeworkTemplateAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            duplicateTemplate: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        duplicateHomeworkTemplateAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            duplicateTemplate: ReduxStatus.rejected,
            duplicateTemplateError: action.payload.error,
          };
        },
      )
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          creating: false,
          created: false,
          changeError: null,
          fetchingOne: false,
          fetchOneError: null,
          successUpdate: false,
          duplicateTemplate: ReduxStatus.idle,
          duplicateTemplateError: null,
        };
      })
      .addCase(resetTemplateRelatedData, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          creating: false,
          created: false,
          changeError: null,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: null,
          template: null,
          successUpdate: false,
          duplicateTemplate: ReduxStatus.idle,
          duplicateTemplateError: null,
        };
      })
      .addCase(successUpdateTemplateAsSuperOwner, (state, _action) => {
        return {
          ...state,
          successUpdate: true,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const superOwnerAppHomeworkTemplateState = (
  state: RootState,
): SuperOwnerAppHomeworkTemplateState => state.superOwnerApp.homeworkTemplate;

export default superOwnerAppHomeworkTemplateSlice.reducer;
