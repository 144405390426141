/* eslint-disable camelcase */
import { Notification, NotificationStatus } from "@lib/Api";
import {
  HasIdAndName,
  LimitedTimeNotificationStatus,
  RoleBase,
} from "@root/endpoints/TimeTactApi/remainingApi";

export type PostNotificationAsOwnerParams = {
  target_roles?: string[];
  target_user_tag_ids?: string[];
  target_company_ids?: string[];
  title: string;
  message: string;
  with_email?: boolean;
  publication_time?: string;
};

export type PostNotificationAsSuperOwnerParams = {
  title: string;
  message: string;
  target_roles: string[];
  target_user_tag_ids?: string[];
  target_company_ids: string[];
  publication_time?: string;
  with_email?: boolean;
};

export type PostNotificationAsAdminParams = {
  target_company_ids: string[];
  target_roles: string[];
  title: string;
  message: string;
};

export type CommonNotification = Notification & {
  read?: boolean;
  checked?: boolean;
  start_time?: string;
  end_time?: string;
  type?: "Notification" | "LimitedTimeNotification";
  status?: LimitedTimeNotificationStatus;
};

export type NewNotifications = {
  title?: string;
  message?: string;
  status?: LimitedTimeNotificationStatus;
  start_time?: string;
  end_time?: string;
  created_at?: string;
  updated_at?: string;
  roles?: RoleBase[];
  companies?: HasIdAndName[];
  user_tags?: HasIdAndName[];
  resource_info?: string | null;
  sender?: {
    id?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
  };
  read?: boolean;
  checked?: boolean;
  notified_time?: string;
  type?: "Notification" | "LimitedTimeNotification";
};

export enum NotificationType {
  NEW = "Notification",
  LIMITED = "LimitedTimeNotification",
}

export type NotificationSent = Notification & {
  status: NotificationStatus;
};
