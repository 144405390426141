import {
  getPblBoardCampaignsAsTeacher,
  resetTeacherAppPblBoardCampaignState,
} from "@actions/teacherApp/pblBoard/campaign";
import { PblBoardCampaignListAsTeacher } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface TeacherAppPblBoardCampaignState {
  fetchPblBoardCampaigns: ReduxStatusType;
  pblBoardCampaigns: PblBoardCampaignListAsTeacher[];
  totalCount: number;
}

export const initialState: TeacherAppPblBoardCampaignState = {
  fetchPblBoardCampaigns: ReduxStatus.idle,
  pblBoardCampaigns: [],
  totalCount: 0,
};

const teacherAppPblBoardCampaignSlice = createSlice({
  name: "teacherApp/pblBoard/campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPblBoardCampaignsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchPblBoardCampaigns: ReduxStatus.pending,
        };
      })
      .addCase(getPblBoardCampaignsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchPblBoardCampaigns: ReduxStatus.rejected,
          pblBoardCampaigns: [],
        };
      })
      .addCase(getPblBoardCampaignsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchPblBoardCampaigns: ReduxStatus.fulfilled,
          pblBoardCampaigns: payload.board_campaigns,
          totalCount: payload.total_count,
        };
      })
      .addCase(resetTeacherAppPblBoardCampaignState, (state, _action) => {
        return {
          ...state,
          fetchPblBoardCampaigns: ReduxStatus.idle,
          fetchMorePblBoardCampaigns: ReduxStatus.idle,
          pblBoardCampaigns: [],
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppPblBoardCampaignBoardState = (
  state: RootState,
): TeacherAppPblBoardCampaignState => state.teacherApp.pblBoardCampaign;

export default teacherAppPblBoardCampaignSlice.reducer;
