/* eslint-disable camelcase */
import { CompanyAsSuperOwner } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  createCompanyAsSuperOwner,
  deleteCompanyAsSuperOwner,
  fetchCompaniesAsSuperOwner,
  fetchCompanyAsSuperOwner,
  resetModalState,
  resetState,
  updateCompanyAsSuperOwner,
} from "../../actions/superOwnerApp/reviewerCompany";
import { RootState } from "../../store";

export const initialCompanyState: CompanyAsSuperOwner = {
  id: "",
  name: "",
  postcode: "",
  prefecture: "",
  address1: "",
  address2: "",
  phone: "",
  email: "",
  created_at: "",
  owner_number: 0,
  teacher_number: 0,
  student_number: 0,
  company_tags: [],
  logo: {},
};

export interface SuperOwnerReviewerCompanyState {
  fetching: boolean;
  fetchingInModal: boolean;
  fetched: boolean;
  fetchedInModal: boolean;
  fetchingOne: boolean;
  fetchedOne: boolean;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  reviewerCompaniesInModal: CompanyAsSuperOwner[];
  reviewerCompanies: CompanyAsSuperOwner[];
  reviewerCompany: CompanyAsSuperOwner;
  totalCount: number;
  totalCountInModal: number;
  error: any;
  changeError: any;
  fetchError: string | null;
  fetchOneError: string | null;
  fetchErrorInModal: string | null;
}

export const initialState: SuperOwnerReviewerCompanyState = {
  fetching: false,
  fetchingOne: false,
  fetchingInModal: false,
  fetched: false,
  fetchedOne: false,
  fetchedInModal: false,
  fetchError: null,
  fetchOneError: null,
  fetchErrorInModal: null,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  reviewerCompanies: [],
  reviewerCompaniesInModal: [],
  reviewerCompany: initialCompanyState,
  totalCount: 0,
  totalCountInModal: 0,
  error: null,
  changeError: null,
};

export const superOwnerAppReviewerCompanySlice = createSlice({
  name: "SuperOwnerApp/ReviewerCompany",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompaniesAsSuperOwner.pending, (state, action) => {
        const { meta } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: true,
            fetchedInModal: false,
          };
        }
        return {
          ...state,
          fetched: false,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchCompaniesAsSuperOwner.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: false,
            fetchedInModal: true,
            reviewerCompaniesInModal: payload.companies,
            totalCountInModal: payload.total_count,
          };
        }
        return {
          ...state,
          fetched: true,
          fetching: false,
          fetchError: null,
          error: null,
          reviewerCompanies: payload.companies,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchCompaniesAsSuperOwner.rejected, (state, action) => {
        const { meta } = action;
        const { arg } = meta;
        if (arg.inModal) {
          return {
            ...state,
            fetchingInModal: false,
            fetchedInModal: true,
            fetchErrorInModal: action.error.message as string,
          };
        }
        return {
          ...state,
          fetched: false,
          fetching: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchCompanyAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          fetchedOne: false,
          fetchingOne: true,
          fetchOnError: null,
        };
      })
      .addCase(fetchCompanyAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetchedOne: true,
          fetchingOne: false,
          fetchOneError: null,
          reviewerCompany: action.payload,
        };
      })
      .addCase(fetchCompanyAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchedOne: false,
          fetchingOne: false,
          fetchOneError: action.error.message as string,
        };
      })
      .addCase(createCompanyAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          created: false,
          creating: true,
          chnageError: null,
        };
      })
      .addCase(createCompanyAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          created: true,
          creating: false,
          changeError: null,
          reviewerCompany: action.payload,
          reviewerCompanies: [action.payload, ...state.reviewerCompanies],
        };
      })
      .addCase(createCompanyAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          created: false,
          creating: false,
          postError: action.payload,
        };
      })
      .addCase(updateCompanyAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          updated: false,
          updating: true,
          changeError: null,
        };
      })
      .addCase(updateCompanyAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updated: true,
          updating: false,
          changeError: null,
          reviewerCompany: action.payload,
        };
      })
      .addCase(updateCompanyAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          updated: false,
          updating: false,
          changeError: action.payload,
        };
      })
      .addCase(deleteCompanyAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          changeError: null,
        };
      })
      .addCase(deleteCompanyAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: true,
        };
      })
      .addCase(deleteCompanyAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          changeError: action.payload?.errors || ["エラーが発生しました。"],
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: null,
          creating: false,
          created: false,
          postError: null,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          changeError: null,
        };
      })
      .addCase(resetModalState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchErrorInModal: null,
          schoolsInModal: [],
          totalCountInModal: 0,
        };
      });
  },
});

export const superOwnerAppReviewerCompanyState = (
  state: RootState,
): SuperOwnerReviewerCompanyState => state.superOwnerApp.reviewerCompany;

export default superOwnerAppReviewerCompanySlice.reducer;
