import MicrosoftLogin from "@components/Auth/MicrosoftLogin";
import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";

const EmailRecoveryPage = lazy(
  () => import("@components/UI/organisms/Auth/EmailRecoveryPage"),
);
const AdminContainer = lazy(() => import("./containers/Admin/AdminContainer"));
const InvitationAcceptContainer = lazy(
  () => import("./containers/Auth/InvitationAcceptContainer"),
);
const Login = lazy(() => import("./containers/Login/Login"));
const OwnerContainer = lazy(() => import("./containers/Owner/OwnerContainer"));
const ReviewerContainer = lazy(
  () => import("./containers/Reviewer/ReviewerContainer"),
);
const ContentProviderContainer = lazy(
  () => import("./containers/ContentProvider"),
);
const SuperOwnerContainer = lazy(
  () => import("./containers/SuperOwner/SuperOwnerContainer"),
);
const WaitingForApproval = lazy(
  () => import("./components/UI/organisms/WaitingForApproval"),
);
const RootContainer = lazy(() => import("./containers/RootContainer"));
const StudentContainer = lazy(
  () => import("./containers/Student/StudentContainer"),
);
const TeacherContainer = lazy(
  () => import("./containers/Teacher/TeacherContainer"),
);
const ErrorFallback = lazy(
  () => import("./components/UI/organisms/Error/ErrorPage"),
);
const PasswordResetRequestContainer = lazy(
  () => import("./containers/Auth/PasswordResetRequestContainer"),
);
const PasswordResetContainer = lazy(
  () => import("./containers/Auth/PasswordResetContainer"),
);
const NotFoundErrorPage = lazy(
  () => import("./components/UI/organisms/Error/NotFoundErrorPage"),
);
const NetworkErrorPage = lazy(
  () => import("./components/UI/organisms/Error/NetworkErrorPage"),
);
const NotSupportedPage = lazy(
  () => import("./components/UI/organisms/NotSupported/NotSupported"),
);
const ForbiddenPage = lazy(
  () => import("./components/UI/organisms/Forbidden/Forbidden"),
);
const AffiliatesContainer = lazy(
  () => import("./containers/Common/Affiliates/AffiliatesContainer"),
);
const OAuthContainer = lazy(() => import("./containers/OAuth/OAuthContainer"));
const GoogleCalendarContainer = lazy(
  () => import("./containers/Common/GoogleCalendar/GoogleCalendarContainer"),
);
const GoogleClassRoomContainer = lazy(
  () => import("./containers/Common/GoogleClassRoom/GoogleClassRoomContainer"),
);
const GoogleDrivesContainer = lazy(
  () => import("./containers/Common/GoogleDrives/GoogleDrivesContainer"),
);
const MicrosoftDrivesContainer = lazy(
  () => import("./containers/Common/MicrosoftDrives/MicrosoftDrivesContainer"),
);
const SignupWithAffiliateInvitationContainer = lazy(
  () =>
    import(
      "./containers/Auth/SignupWithAffiliateInvitation/SignupWithAffiliateInvitation"
    ),
);
const EmailConfirmed = lazy(
  () => import("./components/UI/organisms/EmailConfirmed/EmailConfirmed"),
);
const ChangePasswordContainer = lazy(
  () => import("./containers/Auth/ChangePasswordContainer"),
);
const HelpPageContainer = lazy(
  () => import("./containers/Common/Help/HelpPageContainer"),
);
const InquiryListContainer = lazy(
  () => import("./containers/Common/Inquiry/InquiryListContainer"),
);
const InquiryDetailsContainer = lazy(
  () => import("./containers/Common/Inquiry/InquiryDetailsContainer"),
);
const CheckPageContainer = lazy(
  () => import("./components/UI/organisms/Check/CheckPage"),
);

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={RootContainer} />
      <Route path="/login" component={Login} />
      <Route path="/oauth" component={OAuthContainer} />
      <Route path="/google_api/calendar" component={GoogleCalendarContainer} />
      <Route
        path="/google_api/classroom"
        component={GoogleClassRoomContainer}
      />
      <Route path="/google_api/drive" component={GoogleDrivesContainer} />
      <Route
        path="/microsoft_graph_api/drive"
        component={MicrosoftDrivesContainer}
      />
      <Route path="/microsoft_graph_api/login" component={MicrosoftLogin} />
      <Route
        path="/auth/invitation/accept"
        component={InvitationAcceptContainer}
      />
      <Route path="/auth/email_recovery" component={EmailRecoveryPage} />
      <Route
        path="/auth/password_reset_request"
        component={PasswordResetRequestContainer}
      />
      <Route
        path="/auth/signup-with-link/:token"
        component={SignupWithAffiliateInvitationContainer}
      />
      <Route path="/auth/password_reset" component={PasswordResetContainer} />
      <Route path="/admin" component={AdminContainer} />
      <Route path="/student" component={StudentContainer} />
      <Route path="/teacher" component={TeacherContainer} />
      <Route path="/owner" component={OwnerContainer} />
      <Route path="/super_owner" component={SuperOwnerContainer} />
      <Route path="/reviewer" component={ReviewerContainer} />
      <Route path="/content_provider" component={ContentProviderContainer} />
      <Route path="/error" component={ErrorFallback} />
      <Route path="/not_supported" component={NotSupportedPage} />
      <Route path="/forbidden" component={ForbiddenPage} />
      <Route path="/help" component={HelpPageContainer} />
      <Route path="/affiliates" component={AffiliatesContainer} />
      <Route path="/inquiries/:id" component={InquiryDetailsContainer} />
      <Route path="/inquiries" component={InquiryListContainer} />
      <Route path="/email_confirmed" component={EmailConfirmed} />
      <Route path="/auth/change_password" component={ChangePasswordContainer} />
      <Route path="/not_found" component={NotFoundErrorPage} />
      <Route path="/network_error" component={NetworkErrorPage} />
      <Route path="/approval" component={WaitingForApproval} />
      <Route path="/check" component={CheckPageContainer} />
      <Route component={NotFoundErrorPage} />
    </Switch>
  );
};

export default Routes;
