import { Api, GetTodosAsStudentParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface GetTodosAsStudentArg {
  params: GetTodosAsStudentParams;
}

export const getTodosAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectMeta: void }
>("student/todos", async (arg: GetTodosAsStudentArg, { rejectWithValue }) => {
  try {
    const response = await api.student.getTodosAsStudent(arg.params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
