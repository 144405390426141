/* eslint-disable camelcase */

import {
  ActivityBase,
  Api,
  DailyLogBase,
  GetStudentLogsActivityAsTeacherParams,
  GetStudentLogsStatsAsTeacherParams,
  GetStudentLogsStudyByStudentAsTeacherParams,
  GetStudentLogsStudyLogsAsTeacherParams,
  StudyLogBase,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchStudyLogsAsTeacher = createAsyncThunk<
  any,
  GetStudentLogsStudyLogsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/student_logs/study",
  async (
    params: GetStudentLogsStudyLogsAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.getStudentLogsStudyLogsAsTeacher(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchStudyLogsByStudentsAsTeacher = createAsyncThunk<
  any,
  GetStudentLogsStudyByStudentAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/student_logs/study_by_student",
  async (
    params: GetStudentLogsStudyByStudentAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.getStudentLogsStudyByStudentAsTeacher(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface ActivityParams {
  this_week_daily_logs: ActivityBase[];
  last_week_daily_logs: ActivityBase[];
}

export const fetchStudyLogsActivityAsTeacher = createAsyncThunk<
  ActivityParams,
  GetStudentLogsActivityAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/student_logs/activity",
  async (
    params: GetStudentLogsActivityAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.getStudentLogsActivityAsTeacher(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface StatsParams {
  study_logs: StudyLogBase[];
  daily_logs: DailyLogBase[];
  this_week_daily_logs: ActivityBase[];
  total_count: number;
}

export const fetchStudyLogsStatsAsTeacher = createAsyncThunk<
  StatsParams,
  GetStudentLogsStatsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/student_logs/stats", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getStudentLogsStatsAsTeacher(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetOne = createAction("teacher/student_logs/resetOne");
