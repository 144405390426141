import { updateCategoryRenameMapAsOwner } from "@actions/ownerApp/categoryRenameMap";
import { updateCategoryRenameMapAsSuperOwner } from "@actions/superOwnerApp/categoryRenameMap";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { CategoryRenameMap, CurrentUserInfo, RecaptchaError } from "@lib/Api";
import getRoleInfo, { initialIsRoles,IsRoles } from "@lib/user/get-role-utils";
import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import {
  acceptInvitation,
  adminProxyLoginAsUser,
  authCheckState,
  changeNewNotification,
  firstTimeLogin,
  getCurrentUser,
  hideErrorPage,
  proxyLogout,
  requestCreate,
  resendInvitationEmail,
  resetFirstTimeLoginState,
  resetForm,
  resetPassword,
  resetPasswordRequest,
  showErrorPage,
  signin,
  signupWithAffiliateInvitation,
} from "../actions/auth";
import { updateCurrentUserAsTeacher } from "../actions/teacherApp/user";
import { RootState } from "../store";

export type AuthState = {
  signupCompleted: boolean;
  accessToken: string | null;
  uid: string | null;
  client: string | null;
  loading: boolean;
  loadingCurrentUser: boolean;
  updating: boolean;
  updated: boolean;
  role: string | null;
  companyId?: string | null;
  invitationAccepted: boolean;
  currentUser: CurrentUserInfo | null;
  isCurrentRoles: IsRoles;
  showErrorPage: boolean;
  loginSuccess: boolean;
  error: any;
  signinError: string | null;
  resetPasswordAccepted: boolean;
  passwordResetRequestAccepted: boolean;
  proxy: string | null;
  resendInvitationEmail: ReduxStatusType;
  resendInvitationEmailMsg: string | null;
  updateFirstTimeLogin: ReduxStatusType;
  updateFirstTimeLoginError: string | null;
  isRecaptchaFailed: boolean;
};

export const initialState: AuthState = {
  signupCompleted: false,
  accessToken: null,
  uid: null,
  client: null,
  loginSuccess: false,
  error: null,
  signinError: null,
  loading: false,
  loadingCurrentUser: false,
  updating: false,
  updated: false,
  role: "",
  companyId: "",
  invitationAccepted: false,
  resetPasswordAccepted: false,
  passwordResetRequestAccepted: false,
  currentUser: null,
  isCurrentRoles: initialIsRoles,
  showErrorPage: false,
  proxy: null,
  resendInvitationEmail: ReduxStatus.idle,
  resendInvitationEmailMsg: null,
  updateFirstTimeLogin: ReduxStatus.idle,
  updateFirstTimeLoginError: null,
  isRecaptchaFailed: false,
};

const isRecaptchaFailed = (payload: any) => {
  return payload?.status_code === RecaptchaError.RecaptchaVerifiedError;
};

const getErrorForRecaptcha = (payload: any) => {
  let result = "";
  if (isRecaptchaFailed(payload)) {
    result = "recaptchaAuthError";
  } else if (payload?.error === RecaptchaError.RecaptchaParseError) {
    result = "recaptchaParseError";
  } else {
    result = payload?.errors || "recaptchaAuthErrorTwo";
  }
  return result;
};

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signupWithAffiliateInvitation.pending, (state, action) => {
        return {
          ...state,
          error: null,
          loading: true,
        };
      })
      .addCase(signupWithAffiliateInvitation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          error: null,
          signupCompleted: true,
        };
      })
      .addCase(signupWithAffiliateInvitation.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: getErrorForRecaptcha(action.payload),
          isRecaptchaFailed: isRecaptchaFailed(action.payload),
        };
      })
      .addCase(showErrorPage, (state) => {
        return {
          ...state,
          showErrorPage: true,
        };
      })
      .addCase(hideErrorPage, (state) => {
        return {
          ...state,
          showErrorPage: false,
        };
      })
      .addCase(signin.pending, (state) => {
        return {
          ...state,
          signinError: null,
          loading: true,
        };
      })
      .addCase(signin.fulfilled, (state, action) => {
        const { accessToken, client, uid } = action.payload;
        return {
          ...state,
          loading: false,
          loginSuccess: true,
          signinError: null,
          accessToken,
          client,
          uid,
          isRecaptchaFailed: false,
        };
      })
      .addCase(signin.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          loginSuccess: action.payload.success,
          signinError: getErrorForRecaptcha(action.payload),
          isRecaptchaFailed: isRecaptchaFailed(action.payload),
        };
      })
      .addCase(adminProxyLoginAsUser.pending, (state) => {
        return {
          ...state,
          error: null,
          loading: true,
        };
      })
      .addCase(adminProxyLoginAsUser.fulfilled, (state, action) => {
        const { companyId, role, accessToken } = action.payload;
        return {
          ...state,
          loading: false,
          error: null,
          proxy: accessToken || sessionStorage.getItem("proxyAccessToken"),
          companyId,
          role,
        };
      })
      .addCase(adminProxyLoginAsUser.rejected, (state, action) => {
        return {
          ...state,
          error:
            action.payload.errors ||
            "サーバーに接続できません。ネットワークをご確認ください。",
          loading: false,
        };
      })
      .addCase(proxyLogout, (state) => {
        return {
          ...state,
          proxy: null,
          role: "admin",
        };
      })
      .addCase(getCurrentUser.pending, (state) => {
        return {
          ...state,
          loadingCurrentUser: true,
        };
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        const { companyId, currentUser, role } = action.payload;
        return {
          ...state,
          currentUser,
          role,
          companyId,
          loadingCurrentUser: false,
          showErrorPage: false,
          isCurrentRoles: getRoleInfo(currentUser),
        };
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        return {
          ...state,
          loadingCurrentUser: false,
        };
      })
      .addCase(
        updateCategoryRenameMapAsSuperOwner.fulfilled,
        (state, action) => {
          const updatedCurrentUser: CurrentUserInfo = {
            ...(state.currentUser as CurrentUserInfo),
            category_rename_map: action.payload as CategoryRenameMap,
          };
          return {
            ...state,
            currentUser: updatedCurrentUser,
          };
        },
      )
      .addCase(updateCategoryRenameMapAsOwner.fulfilled, (state, action) => {
        const updatedCurrentUser: CurrentUserInfo = {
          ...(state.currentUser as CurrentUserInfo),
          category_rename_map: action.payload as CategoryRenameMap,
        };
        return {
          ...state,
          currentUser: updatedCurrentUser,
        };
      })
      .addCase(acceptInvitation.pending, (state, action) => {
        return {
          ...state,
          loading: true,
          invitationAccepted: false,
        };
      })
      .addCase(acceptInvitation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          invitationAccepted: true,
        };
      })
      .addCase(acceptInvitation.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(resetPasswordRequest.pending, (state, action) => {
        return {
          ...state,
          loading: true,
          passwordResetRequestAccepted: false,
        };
      })
      .addCase(resetPasswordRequest.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          passwordResetRequestAccepted: true,
        };
      })
      .addCase(resetPasswordRequest.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(resetPassword.pending, (state, action) => {
        return {
          ...state,
          loading: true,
          resetPasswordAccepted: false,
        };
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          resetPasswordAccepted: true,
        };
      })
      .addCase(resetPassword.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(requestCreate.pending, (state, action) => {
        return {
          ...state,
          loading: true,
          signinError: null,
        };
      })
      .addCase(requestCreate.fulfilled, (state, action) => {
        const { payload } = action;
        const { accessToken, client, uid } = payload as any;
        return {
          ...state,
          loading: false,
          signinError: null,
          accessToken,
          client,
          uid,
        };
      })
      .addCase(requestCreate.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          signinError: action.payload,
        };
      })
      .addCase(updateCurrentUserAsTeacher.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          error: null,
        };
      })
      .addCase(updateCurrentUserAsTeacher.fulfilled, (state, action) => {
        const { user, accessToken, client, uid } = action.payload;
        return {
          ...state,
          updating: false,
          updated: true,
          currentUser: user,
          accessToken,
          client,
          uid,
        };
      })
      .addCase(authCheckState.fulfilled, (state, action) => {
        const { proxyAccessToken, accessToken, client, uid } =
          action.payload as any;
        return {
          ...state,
          proxy: proxyAccessToken || null,
          accessToken,
          client,
          uid,
        };
      })
      .addCase(resetForm, (state, _action) => {
        return {
          ...state,
          loading: false,
          updating: false,
          updated: false,
          error: null,
          signinError: null,
        };
      })
      .addCase(changeNewNotification, (state) => {
        const { currentUser } = state;
        return {
          ...state,
          currentUser: {
            ...currentUser,
            new_notification: false,
          } as CurrentUserInfo,
        };
      })
      .addCase(resendInvitationEmail.pending, (state, _action) => {
        return {
          ...state,
          resendInvitationEmail: ReduxStatus.pending,
          resendInvitationEmailMsg: null,
        };
      })
      .addCase(resendInvitationEmail.fulfilled, (state, action) => {
        return {
          ...state,
          resendInvitationEmail: ReduxStatus.fulfilled,
          resendInvitationEmailMsg: action.payload.message,
        };
      })
      .addCase(resendInvitationEmail.rejected, (state, action) => {
        return {
          ...state,
          resendInvitationEmail: ReduxStatus.rejected,
          resendInvitationEmailMsg: getErrorMessage(action.payload),
        };
      })
      .addCase(firstTimeLogin.pending, (state, _action) => {
        return {
          ...state,
          updateFirstTimeLogin: ReduxStatus.pending,
          updateFirstTimeLoginError: null,
        };
      })
      .addCase(firstTimeLogin.fulfilled, (state, action) => {
        if (state.currentUser) {
          return {
            ...state,
            updateFirstTimeLogin: ReduxStatus.fulfilled,
            currentUser: {
              ...state.currentUser,
              first_time_login: action.payload.user?.first_time_login ?? false,
            },
          };
        }
        return state;
      })
      .addCase(firstTimeLogin.rejected, (state, action) => {
        return {
          ...state,
          updateFirstTimeLogin: ReduxStatus.rejected,
          updateFirstTimeLoginError: action.payload,
        };
      })
      .addCase(resetFirstTimeLoginState, (state) => {
        return {
          ...state,
          updateFirstTimeLogin: ReduxStatus.idle,
          updateFirstTimeLoginError: null,
        };
      })
      .addDefaultCase((state) => {
        return state;
      });
  },
});

export const authState = (state: RootState): AuthState => state.auth;

export default authSlice.reducer;
