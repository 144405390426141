/* eslint-disable camelcase */
import { Company } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

import {
  fetchAllHomeworkTemplateHistoriesAsSuperOwner,
  fetchHomeworkTemplateHistoriesCompanies,
  fetchHomeworkTemplateHistoriesUsers,
  HomeworkTemplateDistributionHistory,
  resetState,
  UserHistoryAffiliation,
} from "../../actions/superOwnerApp/homeworkTemplateDistributionHistory";
import { RootState } from "../../store";

export interface DistributionHistoryInfo {
  id: string;
  name: string;
  distribution_time: string;
  number_of_distribution: number;
  reservation_time: string;
  limit_number: number;
}

export interface SuperOwnerAppHomeworkTemplateDistributionHistoryState {
  creating: boolean;
  created: boolean;
  error: any;
  fetching: boolean;
  fetched: boolean;
  fetchedCompanies: boolean;
  fetchedUsers: boolean;
  distributedHistories: HomeworkTemplateDistributionHistory[];
  historyCompanies: Company[];
  historyUsers: UserHistoryAffiliation[];
  totalCount: number;
}

const initialState: SuperOwnerAppHomeworkTemplateDistributionHistoryState = {
  creating: false,
  created: false,
  error: null,
  fetching: false,
  fetched: false,
  fetchedCompanies: false,
  fetchedUsers: false,
  distributedHistories: [],
  historyCompanies: [],
  historyUsers: [],
  totalCount: 0,
};

const superOwnerAppHomeworkTemplateDistributionHistorySlice = createSlice({
  name: "SuperOwnerApp/HomeworkTemplateHistories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAllHomeworkTemplateHistoriesAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            error: null,
          };
        },
      )
      .addCase(
        fetchAllHomeworkTemplateHistoriesAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            error: null,
            distributedHistories:
              action.payload.homework_template_distribution_histories,
            totalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        fetchAllHomeworkTemplateHistoriesAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            error: getErrorMessage(action.payload.error),
            distributedHistories: [],
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateHistoriesCompanies.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetchedCompanies: false,
            error: null,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateHistoriesCompanies.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetchedCompanies: true,
            error: null,
            historyCompanies: action.payload.companies,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateHistoriesCompanies.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetchedCompanies: false,
            error: action.payload,
            historyCompanies: [],
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateHistoriesUsers.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetchedUsers: false,
            error: null,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateHistoriesUsers.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetchedUsers: true,
            error: null,
            historyUsers: action.payload.affiliates,
          };
        },
      )
      .addCase(
        fetchHomeworkTemplateHistoriesUsers.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetchedUsers: false,
            error: action.payload,
            historyUsers: [],
          };
        },
      )
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          created: false,
          creating: false,
          fetchedCompanies: false,
          fetchedUsers: false,
          error: null,
        };
      });
  },
});

export const superOwnerAppHomeworkTemplateDistributionHistoryState = (
  state: RootState,
): SuperOwnerAppHomeworkTemplateDistributionHistoryState =>
  state.superOwnerApp.homeworkTemplateDistributionHistory;

export default superOwnerAppHomeworkTemplateDistributionHistorySlice.reducer;
