import { CommonStyledTableThCell } from "@components/UI/organisms/Tables/TableComponents";
import { TableHead, TableRow } from "@mui/material";
import React from "react";

const TableHeader: React.FC = () => {
  const headCells = [
    { id: "status", label: "ステータス", numeric: false },
    { id: "package_name", label: "プログラム名", numeric: false },
    { id: "creation_date", label: "配信日時", numeric: false },
    { id: "start_end_date", label: "公開期間", numeric: false },
    { id: "setting", label: "配信設定", numeric: false },
    { id: "distributed_to", label: "配信先", numeric: false },
    { id: "delete", label: "", numeric: false },
  ].filter(Boolean);
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CommonStyledTableThCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
          >
            {headCell.label}
          </CommonStyledTableThCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
