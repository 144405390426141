/* eslint-disable react/jsx-no-duplicate-props */
import makeAppInfo from "@lib/app/app-info-utils";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import {
  DateTimePickerProps,
  DateTimePickerSlotsComponentsProps,
  DateTimePicker,
} from "@mui/x-date-pickers";
import defaultTheme from "@root/styles/theme";
import dayjs, { Dayjs } from "dayjs";
import { merge } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

// To override the default theme otherwise the styles of Picker will be broken
const theme = createTheme({
  ...defaultTheme,
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.common.white,
        },
      },
    },
  },
  typography: {
    h4: {
      fontSize: "2rem",
    },
    h3: {
      lineHeight: 1,
      marginTop: 0,
      marginBottom: 0,
    },
  },
});

type Props = {
  value: Dayjs | string | null | undefined;
  onChange: (newValue: string) => void;
} & Omit<DateTimePickerProps<Dayjs>, "value" | "onChange">;

const V2DateTimePicker: React.FC<Props> = (props) => {
  const { isCypressEnv } = makeAppInfo();
  const { t } = useTranslation(["common"]);

  const pickerParams: DateTimePickerProps<Dayjs> = {
    ...props,
    value: props.value ? dayjs(props.value) : null, // value="" is not valid for picker, replace it with null
    onChange: (value) =>
      props.onChange(value ? value.format("YYYY/MM/DDTHH:mm") : ""),
    onOpen: () =>
      props.onChange(
        props.value
          ? dayjs(props.value).format("YYYY/MM/DDTHH:mm")
          : dayjs().format("YYYY/MM/DDTHH:mm"),
      ),
  };

  const defaultSlotProps: DateTimePickerSlotsComponentsProps<Dayjs> = {
    field: { clearable: true, readOnly: !isCypressEnv },
    textField: {
      FormHelperTextProps: {
        "data-cy": "date-time-picker-helper-text",
      } as never,
      inputProps: { "data-cy": "date-time-picker" },
      placeholder: t("date_time_picker_format"),
    },
  };

  let slotProps = merge(defaultSlotProps, props.slotProps);
  if (
    slotProps.field &&
    "clearable" in slotProps.field &&
    slotProps.field.clearable
  ) {
    slotProps = {
      ...slotProps,
      actionBar: { actions: ["clear", "cancel", "accept"] },
    };
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DateTimePicker
          {...pickerParams}
          slotProps={slotProps}
          format="YYYY/MM/DD HH:mm"
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default V2DateTimePicker;
