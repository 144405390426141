/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface CreatePblReportAnswerItemAsStudentParams {
  answer_id: string;
  item_id: string;
  title?: string | undefined;
  body?: string | undefined;
}

export const createPblReportAnswerItemAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/report/answers/answerItems/create",
  async (
    params: CreatePblReportAnswerItemAsStudentParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.student.createPblReportAnswerItemAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdatePblReportAnswerItemAsStudentParams {
  title?: string | undefined;
  body?: string | undefined;
}

export interface UpdatePblReportAnswerItemAsStudentArg {
  id: string;
  params: UpdatePblReportAnswerItemAsStudentParams;
}

export const updatePblReportAnswerItemAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/report/answers/answerItems/update",
  async (arg: UpdatePblReportAnswerItemAsStudentArg, { rejectWithValue }) => {
    try {
      const response = await api.student.updatePblReportAnswerItemAsStudent(
        arg.id,
        arg.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
