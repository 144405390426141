/* eslint-disable camelcase */
import { Api, FetchPblCardBookmarkCommentsAsStudentParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblCardBookmarkCommentsAsStudent = createAsyncThunk<
  any,
  FetchPblCardBookmarkCommentsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/comments/fetch",
  async (
    params: FetchPblCardBookmarkCommentsAsStudentParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.student.fetchPblCardBookmarkCommentsAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreatePblCardBookmarkCommentAsStudentParams {
  bookmark_id: string;
  body: string;
}

export const createPblCardBookmarkCommentAsStudent = createAsyncThunk<
  any,
  CreatePblCardBookmarkCommentAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/comments/create",
  async (
    params: CreatePblCardBookmarkCommentAsStudentParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.student.createPblCardBookmarkCommentAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdatePblCardBookmarkCommentAsStudentParams {
  body: string;
}

export interface UpdatePblCardBookmarkAsStudentArg {
  id: string;
  params: UpdatePblCardBookmarkCommentAsStudentParams;
}

export const updatePblCardBookmarkCommentAsStudent = createAsyncThunk<
  any,
  UpdatePblCardBookmarkAsStudentArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/comments/update",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await api.student.updatePblCardBookmarkCommentAsStudent(
        arg.id,
        arg.params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblCardBookmarkCommentAsStudent = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/comments/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.student.deletePblCardBookmarkCommentAsStudent(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "student/pbl/cards/bookmarks/comments/resetState",
);
export const resetActionState = createAction(
  "student/pbl/cards/bookmarks/comments/resetActionState",
);
