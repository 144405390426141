import {
  handleTagInfos,
  handleUnreadMessagesCount,
} from "@components/Common/UniversityInterviewRequestList/util/interviewRequestUtil";
import { StyledTag } from "@components/UI/atoms/StyledTag";
import { useAuth } from "@contexts/Auth";
import { fullName } from "@lib/user/name-utils";
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import DefaultPic from "@root/assets/images/png/defaultThumbnail_without_dash.png";
import {
  InterviewRequestDetailBase,
  InterviewRequestListBase,
  InterviewRequestUnreadCountBase,
} from "@root/endpoints/TimeTactApi/studentApi";
import { InterviewRequestStatusEnum } from "@root/types/interviewRequest";
import React from "react";

type Props = {
  request: InterviewRequestListBase;
  unreadMessages: InterviewRequestUnreadCountBase[];
  currentInterviewRequest: InterviewRequestListBase;
  handleSelectInterview: (request: InterviewRequestDetailBase) => void;
  handleMobileChat: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemContentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      padding: theme.spacing(1),
    },
    listItem: {
      wordBreak: "break-word",
      margin: "20px, 0",
      "&.Mui-selected": {
        backgroundColor: theme.palette.tertiary.mainLight,
      },
      "&.Mui-selected:hover": {
        backgroundColor: theme.palette.tertiary.mainLight,
      },
    },
    avatarContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
    avatar: {
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      objectFit: "cover",
    },
    unreadMsgStyle: {
      position: "absolute",
      display: "flex",
      width: "21px",
      height: "21px",
      justifyContent: "center",
      alignItems: "center",
      top: "28px",
      left: "34px",
      background: theme.palette.tertiary.lightRed,
      borderRadius: "50%",
      border: `1px solid ${theme.palette.common.white}`,
      color: theme.palette.common.white,
      fontWeight: 700,
      fontSize: "12px",
    },
  }),
);

const Item: React.FC<Props> = ({
  request,
  currentInterviewRequest,
  unreadMessages,
  handleSelectInterview,
  handleMobileChat,
}) => {
  const classes = useStyles();
  const isSelected = currentInterviewRequest?.id === request.id;
  const isTablet = useMediaQuery(useTheme().breakpoints.down("md"));
  const { isCurrentRoles, currentUser } = useAuth();
  const statusCode = request.status_code;
  const { tagColor, label } = handleTagInfos(
    (statusCode as InterviewRequestStatusEnum) ??
      InterviewRequestStatusEnum.APPROVAL_PENDING,
    isCurrentRoles,
    currentUser,
  );
  const teacherName = fullName(request.professor_affiliate);
  const teacherDepartment =
    request.professor_affiliate.professor_info.department;
  const articleName = request.article.title;

  const { isUnread, count } = handleUnreadMessagesCount(
    unreadMessages,
    request,
  );

  return (
    <ListItem
      data-cy="interview-request-item"
      classes={{ root: classes.listItem }}
      button
      onClick={() => {
        handleSelectInterview(request as InterviewRequestDetailBase);
        if (isTablet) {
          handleMobileChat();
        }
      }}
      selected={isSelected}
    >
      <ListItemIcon>
        <Box className={classes.avatarContainer}>
          <img className={classes.avatar} src={DefaultPic} alt="Avatar" />
          {isUnread && (
            <span
              data-cy="interview-message-count"
              className={classes.unreadMsgStyle}
            >
              {count}
            </span>
          )}
        </Box>
      </ListItemIcon>
      <Box className={classes.listItemContentWrapper}>
        <span>
          <StyledTag borderRadius={15} label={label} tagColor={tagColor} />
        </span>
        <ListItemText
          primary={`${teacherName} (${teacherDepartment})`}
          data-cy="request-teacher"
        />
        <ListItemText primary={articleName} data-cy="request-student" />
      </Box>
    </ListItem>
  );
};

export default Item;
