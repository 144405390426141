/* eslint-disable camelcase */
import { Api, CreatedUserTagTypesListParams, UserTagTypeKind } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import {
  DeleteTagTypeParams,
  PostTagTypeParams,
  UpdateTagTypeParams,
} from "../types/tag";

const api = new Api();

export interface FetchSuperOwnerUserTagTypes {
  params: CreatedUserTagTypesListParams;
  inModal?: boolean;
}

export const fetchSuperOwnerUserTagTypes = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/user_tags/tag_type/fetch",
  async ({ params }: FetchSuperOwnerUserTagTypes, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.createdUserTagTypesList(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSuperOwnerUserTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/user_tags/tag_type/post",
  async (data: PostTagTypeParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.userTagTypesCreate({
        name: data.name,
        kind: UserTagTypeKind.Nationwide,
        teacher_visible: data.teacher_visible,
        role_type: data.role_type,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSuperOwnerLimitedUserTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/limtied_user_tags/tag_type/post",
  async (data: PostTagTypeParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.userTagTypesCreate({
        name: data.name,
        kind: UserTagTypeKind.Limited,
        teacher_visible: data.teacher_visible,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSuperOwnerUserTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/user_tags/tag_type/update",
  async (data: UpdateTagTypeParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.userTagTypesUpdate(data.id, {
        name: data.name,
        teacher_visible: data.teacher_visible,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSuperOwnerUserTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/user_tags/tag_type/delete",
  async (data: DeleteTagTypeParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.userTagTypesDelete(data.id);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface SaveUserTagTypeSequenceParams {
  sequences: Array<{
    user_tag_type_id: string;
    sequence: number;
  }>;
}

export const saveUserTagTypeSequence = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/userTagTypes/saveSequence",
  async (data: SaveUserTagTypeSequenceParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.userTagTypesSequenceUpdate(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction("super_owner/userTagTypes/resetState");
