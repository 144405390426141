import { baseApi as api } from "../baseApi";
export const addTagTypes = [
  "Reviewer/Article",
  "articles",
  "Reviewer/reviewers",
  "Reviewer/reviewer_affiliates",
  "Reviewer/Notifications",
  "Reviewer/Homework",
  "Reviewer/HomeworkTemplate",
  "Homework",
  "HomeworkTemplate",
  "Reviewer/HomeworkTemplateDistribution",
  "HomeworkTemplateDistribution",
  "Reviewer/HomeworkTemplateChunkDistributions",
  "Reviewer/HomeworkTemplateChunkDistribution",
  "Reviewer/HomeworkTemplateCustomFieldFeedbacks",
  "Reviewer/HomeworkChunks",
  "Reviewer/HomeworkChunk",
  "SubmitHomeworkFilesRequest",
  "Reviewer/Inquiries",
  "Reviewer/InquiryComments",
  "Reviewer/AttachedImages",
  "Reviewer/FeedbackTemplate/Homework",
  "Reviewer/CategorizableComments",
  "Reviewer/FeedbackTemplate/Tag",
  "Reviewer/TtCsvExports",
  "Reviewer/CommentImages",
  "Reviewer/Approvings",
  "Users",
  "Reviewer/Users",
  "Reviewer/HomeworkTemplateCustomValues",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getReviewerArticlesByArticleId: build.query<
        GetReviewerArticlesByArticleIdApiResponse,
        GetReviewerArticlesByArticleIdApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles/${queryArg.articleId}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Reviewer/Article", "articles"],
      }),
      getReviewerArticles: build.query<
        GetReviewerArticlesApiResponse,
        GetReviewerArticlesApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Reviewer/Article"],
      }),
      getReviewerArticlesByArticleIdComments: build.query<
        GetReviewerArticlesByArticleIdCommentsApiResponse,
        GetReviewerArticlesByArticleIdCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles/${queryArg.articleId}/comments`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Reviewer/Article"],
      }),
      postReviewerArticlesByArticleIdComments: build.mutation<
        PostReviewerArticlesByArticleIdCommentsApiResponse,
        PostReviewerArticlesByArticleIdCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles/${queryArg.articleId}/comments`,
          method: "POST",
          body: queryArg.bodyRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["articles", "Reviewer/Article"],
      }),
      getReviewerArticlesByArticleIdLikes: build.query<
        GetReviewerArticlesByArticleIdLikesApiResponse,
        GetReviewerArticlesByArticleIdLikesApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles/${queryArg.articleId}/likes`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Reviewer/Article"],
      }),
      postReviewerArticlesByArticleIdLikes: build.mutation<
        PostReviewerArticlesByArticleIdLikesApiResponse,
        PostReviewerArticlesByArticleIdLikesApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles/${queryArg.articleId}/likes`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["articles", "Reviewer/Article"],
      }),
      deleteReviewerArticlesByArticleIdCommentsAndCommentId: build.mutation<
        DeleteReviewerArticlesByArticleIdCommentsAndCommentIdApiResponse,
        DeleteReviewerArticlesByArticleIdCommentsAndCommentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles/${queryArg.articleId}/comments/${queryArg.commentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Reviewer/Article"],
      }),
      putReviewerArticlesByArticleIdLike: build.mutation<
        PutReviewerArticlesByArticleIdLikeApiResponse,
        PutReviewerArticlesByArticleIdLikeApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles/${queryArg.articleId}/like`,
          method: "PUT",
        }),
        invalidatesTags: ["Reviewer/Article"],
      }),
      putReviewerArticlesByArticleIdUnlike: build.mutation<
        PutReviewerArticlesByArticleIdUnlikeApiResponse,
        PutReviewerArticlesByArticleIdUnlikeApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles/${queryArg.articleId}/unlike`,
          method: "PUT",
        }),
        invalidatesTags: ["Reviewer/Article"],
      }),
      deleteReviewerArticlesByArticleIdLikesAndLikeId: build.mutation<
        DeleteReviewerArticlesByArticleIdLikesAndLikeIdApiResponse,
        DeleteReviewerArticlesByArticleIdLikesAndLikeIdApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/articles/${queryArg.articleId}/likes/${queryArg.likeId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Reviewer/Article"],
      }),
      getReviewerReviewers: build.query<
        GetReviewerReviewersApiResponse,
        GetReviewerReviewersApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/reviewers`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            non_reviewer_manager: queryArg.nonReviewerManager,
          },
        }),
        providesTags: ["Reviewer/reviewers"],
      }),
      createReviewerAsReviewer: build.mutation<
        CreateReviewerAsReviewerApiResponse,
        CreateReviewerAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/reviewers`,
          method: "POST",
          body: queryArg.userReviewersRequestBodyAsReviewer,
        }),
        invalidatesTags: ["Reviewer/reviewers"],
      }),
      deleteReviewerAsReviewer: build.mutation<
        DeleteReviewerAsReviewerApiResponse,
        DeleteReviewerAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/reviewers/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Reviewer/reviewers"],
      }),
      updateReviewerAsReviewer: build.mutation<
        UpdateReviewerAsReviewerApiResponse,
        UpdateReviewerAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/reviewers/${queryArg.id}`,
          method: "PUT",
          body: queryArg.userReviewerRequestBodyAsReviewer,
        }),
        invalidatesTags: ["Reviewer/reviewers"],
      }),
      fetchReviewerAffiliatesAsReviewer: build.query<
        FetchReviewerAffiliatesAsReviewerApiResponse,
        FetchReviewerAffiliatesAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/reviewers/affiliates`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Reviewer/reviewer_affiliates"],
      }),
      fetchNotificationsAsReviewer: build.query<
        FetchNotificationsAsReviewerApiResponse,
        FetchNotificationsAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/notifications`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            sent: queryArg.sent,
          },
        }),
        providesTags: ["Reviewer/Notifications"],
      }),
      fetchHomeworkTemplatesAsReviewer: build.query<
        FetchHomeworkTemplatesAsReviewerApiResponse,
        FetchHomeworkTemplatesAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_templates`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            company_id: queryArg.companyId,
          },
        }),
        providesTags: [
          "Reviewer/Homework",
          "Reviewer/HomeworkTemplate",
          "Homework",
        ],
      }),
      getHomeworkTemplateAnswersAsReviewer: build.query<
        GetHomeworkTemplateAnswersAsReviewerApiResponse,
        GetHomeworkTemplateAnswersAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_templates/${queryArg.id}/answers`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { company_id: queryArg.companyId },
        }),
        providesTags: ["Reviewer/HomeworkTemplate", "HomeworkTemplate"],
      }),
      fetchHomeworkTemplateDistributionsAsReviewer: build.query<
        FetchHomeworkTemplateDistributionsAsReviewerApiResponse,
        FetchHomeworkTemplateDistributionsAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_distributions`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            company_id: queryArg.companyId,
            homework_template_title: queryArg.homeworkTemplateTitle,
            company_name: queryArg.companyName,
            submitted_start_at: queryArg.submittedStartAt,
            submitted_end_at: queryArg.submittedEndAt,
            reviewer_start_at: queryArg.reviewerStartAt,
            reviewer_end_at: queryArg.reviewerEndAt,
            memo: queryArg.memo,
            status: queryArg.status,
          },
        }),
        providesTags: [
          "Reviewer/Homework",
          "Reviewer/HomeworkTemplateDistribution",
          "Homework",
        ],
      }),
      fetchHomeworkTemplateDistributionAsReviewer: build.query<
        FetchHomeworkTemplateDistributionAsReviewerApiResponse,
        FetchHomeworkTemplateDistributionAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_distributions/${queryArg.id}`,
        }),
        providesTags: [
          "Reviewer/HomeworkTemplateDistribution",
          "Reviewer/HomeworkTemplate",
          "Reviewer/Homework",
          "Homework",
          "HomeworkTemplate",
          "HomeworkTemplateDistribution",
        ],
      }),
      updateHomeworkTemplateDistributionAsReviewer: build.mutation<
        UpdateHomeworkTemplateDistributionAsReviewerApiResponse,
        UpdateHomeworkTemplateDistributionAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_distributions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putHomeworkTemplateDistributionRequestBodyAsReviewer,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: [
          "HomeworkTemplateDistribution",
          "Reviewer/HomeworkTemplateDistribution",
        ],
      }),
      returnHomeworkTemplateDistributionToSchoolAsReviewer: build.mutation<
        ReturnHomeworkTemplateDistributionToSchoolAsReviewerApiResponse,
        ReturnHomeworkTemplateDistributionToSchoolAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_distributions/${queryArg.id}/return_to_school`,
          method: "PUT",
          body: queryArg.homeworkTemplateDistributionToSchoolAsReviewerRequestBody,
        }),
        invalidatesTags: [
          "Reviewer/HomeworkTemplate",
          "Reviewer/HomeworkTemplateDistribution",
        ],
      }),
      revertHomeworkTemplateDistributionToSchoolAsReviewer: build.mutation<
        RevertHomeworkTemplateDistributionToSchoolAsReviewerApiResponse,
        RevertHomeworkTemplateDistributionToSchoolAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_distributions/${queryArg.id}/revert_submission_from_school`,
          method: "PUT",
          body: queryArg.homeworkTemplateDistributionCommentRequestBody,
        }),
        invalidatesTags: [
          "Reviewer/HomeworkTemplate",
          "Reviewer/HomeworkTemplateDistribution",
        ],
      }),
      mergeChunksInHomeworkTemplateDistributionAsReviewer: build.mutation<
        MergeChunksInHomeworkTemplateDistributionAsReviewerApiResponse,
        MergeChunksInHomeworkTemplateDistributionAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_distributions/${queryArg.id}/merge_chunks`,
          method: "PUT",
        }),
        invalidatesTags: [
          "Reviewer/HomeworkTemplate",
          "Reviewer/HomeworkTemplateDistribution",
        ],
      }),
      deleteHomeworkTemplateDistributionImageAsReviewer: build.mutation<
        DeleteHomeworkTemplateDistributionImageAsReviewerApiResponse,
        DeleteHomeworkTemplateDistributionImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_distributions/${queryArg.id}/delete_distribution_image`,
          method: "DELETE",
          body: queryArg.homeworkTemplateDistributionImageContentIdRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
      }),
      getUserGroupAffiliatesPackagesAsReviewer: build.query<
        GetUserGroupAffiliatesPackagesAsReviewerApiResponse,
        GetUserGroupAffiliatesPackagesAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_distributions/${queryArg.id}/user_group_affiliates_packages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: [
          "Reviewer/HomeworkTemplate",
          "Reviewer/HomeworkTemplateDistribution",
        ],
      }),
      getUserTagsAsReviewer: build.query<
        GetUserTagsAsReviewerApiResponse,
        GetUserTagsAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_distributions/${queryArg.id}/user_tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: [
          "Reviewer/HomeworkTemplate",
          "Reviewer/HomeworkTemplateDistribution",
        ],
      }),
      getHomeworkTemplateChunkDistributionsAsReviewer: build.query<
        GetHomeworkTemplateChunkDistributionsAsReviewerApiResponse,
        GetHomeworkTemplateChunkDistributionsAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_chunk_distributions`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            homework_template_distribution_id:
              queryArg.homeworkTemplateDistributionId,
          },
        }),
        providesTags: ["Reviewer/HomeworkTemplateChunkDistributions"],
      }),
      getHomeworkTemplateChunkDistributionAsReviewer: build.query<
        GetHomeworkTemplateChunkDistributionAsReviewerApiResponse,
        GetHomeworkTemplateChunkDistributionAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_chunk_distributions/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Reviewer/HomeworkTemplateChunkDistribution"],
      }),
      putHomeworkTemplateChunkDistributionAsReviewer: build.mutation<
        PutHomeworkTemplateChunkDistributionAsReviewerApiResponse,
        PutHomeworkTemplateChunkDistributionAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_chunk_distributions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.homeworkTemplateChunkDistributionEventRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Reviewer/HomeworkTemplateChunkDistributions"],
      }),
      assignHomeworkTemplateChunkDistributionToReviewerAsReviewer:
        build.mutation<
          AssignHomeworkTemplateChunkDistributionToReviewerAsReviewerApiResponse,
          AssignHomeworkTemplateChunkDistributionToReviewerAsReviewerApiArg
        >({
          query: (queryArg) => ({
            url: `/reviewer/homework_template_chunk_distributions/${queryArg.id}/assign`,
            method: "PUT",
            body: queryArg.homeworkTemplateChunkDistributionReviewerAffiliateIdRequiredRequestBody,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["Reviewer/HomeworkTemplateChunkDistributions"],
        }),
      postHomeworkTemplateCustomFieldFeedbackAsReviewer: build.mutation<
        PostHomeworkTemplateCustomFieldFeedbackAsReviewerApiResponse,
        PostHomeworkTemplateCustomFieldFeedbackAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_custom_fields/${queryArg.homeworkTemplateCustomFieldId}/feedbacks`,
          method: "POST",
          body: queryArg.customFieldFeedbackRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Reviewer/HomeworkTemplateCustomFieldFeedbacks"],
      }),
      putHomeworkTemplateCustomFieldFeedbacksAsReviewer: build.mutation<
        PutHomeworkTemplateCustomFieldFeedbacksAsReviewerApiResponse,
        PutHomeworkTemplateCustomFieldFeedbacksAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_custom_fields/${queryArg.homeworkTemplateCustomFieldId}/feedbacks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putCustomFieldFeedbackRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Reviewer/HomeworkTemplateCustomFieldFeedbacks"],
      }),
      deleteHomeworkTemplateCustomFieldFeedbacksAsReviewer: build.mutation<
        DeleteHomeworkTemplateCustomFieldFeedbacksAsReviewerApiResponse,
        DeleteHomeworkTemplateCustomFieldFeedbacksAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_custom_fields/${queryArg.homeworkTemplateCustomFieldId}/feedbacks/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Reviewer/HomeworkTemplateCustomFieldFeedbacks"],
      }),
      getHomeworkChunksAsReviewer: build.query<
        GetHomeworkChunksAsReviewerApiResponse,
        GetHomeworkChunksAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_chunks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            homework_template_chunk_distribution_id:
              queryArg.homeworkTemplateChunkDistributionId,
          },
        }),
        providesTags: ["Reviewer/HomeworkChunks"],
      }),
      getHomeworkChunkAsReviewer: build.query<
        GetHomeworkChunkAsReviewerApiResponse,
        GetHomeworkChunkAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_chunks/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Reviewer/HomeworkChunk"],
      }),
      putHomeworkChunkAsReviewer: build.mutation<
        PutHomeworkChunkAsReviewerApiResponse,
        PutHomeworkChunkAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_chunks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.homeworkChunkReviewerFileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Reviewer/HomeworkChunk"],
      }),
      fetchHomeworksAsReviewer: build.query<
        FetchHomeworksAsReviewerApiResponse,
        FetchHomeworksAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            status: queryArg.status,
            company_id: queryArg.companyId,
            distribution_id: queryArg.distributionId,
            template_id: queryArg.templateId,
            self_assign: queryArg.selfAssign,
            reviewer_affiliate_id: queryArg.reviewerAffiliateId,
            group_affiliate_package_ids: queryArg.groupAffiliatePackageIds,
            user_tag_ids: queryArg.userTagIds,
            order_type: queryArg.orderType,
            sort_type: queryArg.sortType,
          },
        }),
        providesTags: ["Reviewer/Homework", "Homework"],
      }),
      fetchHomeworkAsReviewer: build.query<
        FetchHomeworkAsReviewerApiResponse,
        FetchHomeworkAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks/${queryArg.homeworkId}`,
        }),
        providesTags: ["Reviewer/Homework", "Homework"],
      }),
      putReviewerHomeworksByHomeworkId: build.mutation<
        PutReviewerHomeworksByHomeworkIdApiResponse,
        PutReviewerHomeworksByHomeworkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks/${queryArg.homeworkId}`,
          method: "PUT",
          body: queryArg.putHomeworkRequestBodyAsReviewer,
        }),
        invalidatesTags: ["Reviewer/Homework"],
      }),
      putReviewerHomeworksByHomeworkIdAssign: build.mutation<
        PutReviewerHomeworksByHomeworkIdAssignApiResponse,
        PutReviewerHomeworksByHomeworkIdAssignApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks/${queryArg.homeworkId}/assign`,
          method: "PUT",
          body: queryArg.homeworkAssignRequestBodyAsReviewer,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Reviewer/Homework"],
      }),
      uploadHomeworkImagesAsReviewer: build.mutation<
        UploadHomeworkImagesAsReviewerApiResponse,
        UploadHomeworkImagesAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks/${queryArg.homeworkId}/upload_images`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Reviewer/HomeworkTemplate",
          "Reviewer/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      saveEditedHomeworkImageAsReviewer: build.mutation<
        SaveEditedHomeworkImageAsReviewerApiResponse,
        SaveEditedHomeworkImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks/${queryArg.homeworkId}/save_image`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Reviewer/HomeworkTemplate",
          "Reviewer/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      updateHomeworkImageAsReviewer: build.mutation<
        UpdateHomeworkImageAsReviewerApiResponse,
        UpdateHomeworkImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks/${queryArg.homeworkId}/update_image`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Reviewer/HomeworkTemplate",
          "Reviewer/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      deleteHomeworkImageAsReviewer: build.mutation<
        DeleteHomeworkImageAsReviewerApiResponse,
        DeleteHomeworkImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks/${queryArg.homeworkId}/delete_image`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Reviewer/HomeworkTemplate",
          "Reviewer/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      getHomeworkImageDownloadUrlsAsReviewer: build.query<
        GetHomeworkImageDownloadUrlsAsReviewerApiResponse,
        GetHomeworkImageDownloadUrlsAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks/get_download_urls`,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
          params: {
            homework_ids: queryArg.homeworkIds,
            file_content: queryArg.fileContent,
            template_id: queryArg.templateId,
            order_type: queryArg.orderType,
            sort_type: queryArg.sortType,
          },
        }),
        providesTags: ["Homework"],
      }),
      countHomeworkStatusesAsReviewer: build.mutation<
        CountHomeworkStatusesAsReviewerApiResponse,
        CountHomeworkStatusesAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homeworks/count_statuses`,
          method: "POST",
          body: queryArg.homeworkCountStatusesRequestBodyAsReviewer,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
        }),
        invalidatesTags: ["Reviewer/Homework"],
      }),
      fetchSubmitHomeworkFilesRequestsAsReviewer: build.query<
        FetchSubmitHomeworkFilesRequestsAsReviewerApiResponse,
        FetchSubmitHomeworkFilesRequestsAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/submit_homework_files_requests`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            template_id: queryArg.templateId,
            statuses: queryArg.statuses,
          },
        }),
        providesTags: ["Homework", "SubmitHomeworkFilesRequest"],
      }),
      createSubmitHomeworkFilesRequestAsReviewer: build.mutation<
        CreateSubmitHomeworkFilesRequestAsReviewerApiResponse,
        CreateSubmitHomeworkFilesRequestAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/submit_homework_files_requests`,
          method: "POST",
          body: queryArg.userNicknameRequestBody,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
        }),
        invalidatesTags: ["Homework", "SubmitHomeworkFilesRequest"],
      }),
      getReviewerInquiries: build.query<
        GetReviewerInquiriesApiResponse,
        GetReviewerInquiriesApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/inquiries`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Reviewer/Inquiries"],
      }),
      getReviewerInquiriesById: build.query<
        GetReviewerInquiriesByIdApiResponse,
        GetReviewerInquiriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/inquiries/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Reviewer/Inquiries"],
      }),
      getTheNumberOfNewCommentInquiriesAsReviewer: build.query<
        GetTheNumberOfNewCommentInquiriesAsReviewerApiResponse,
        GetTheNumberOfNewCommentInquiriesAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/inquiries/new_comment_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Reviewer/Inquiries"],
      }),
      postReviewerInquiryComments: build.mutation<
        PostReviewerInquiryCommentsApiResponse,
        PostReviewerInquiryCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/inquiry_comments`,
          method: "POST",
          body: queryArg.bodyRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Reviewer/InquiryComments"],
      }),
      putReviewerInquiryCommentsById: build.mutation<
        PutReviewerInquiryCommentsByIdApiResponse,
        PutReviewerInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/inquiry_comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.inquiryCommentRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Reviewer/InquiryComments"],
      }),
      deleteReviewerInquiryCommentsById: build.mutation<
        DeleteReviewerInquiryCommentsByIdApiResponse,
        DeleteReviewerInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/inquiry_comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Reviewer/InquiryComments"],
      }),
      postAttachedImageAsReviewer: build.mutation<
        PostAttachedImageAsReviewerApiResponse,
        PostAttachedImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/attached_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/AttachedImages"],
      }),
      getAttachedImageAsReviewer: build.query<
        GetAttachedImageAsReviewerApiResponse,
        GetAttachedImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/attached_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["Reviewer/AttachedImages"],
      }),
      putAttachedImageAsReviewer: build.mutation<
        PutAttachedImageAsReviewerApiResponse,
        PutAttachedImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/attached_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/AttachedImages"],
      }),
      deleteAttachedImageAsReviewer: build.mutation<
        DeleteAttachedImageAsReviewerApiResponse,
        DeleteAttachedImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/attached_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/AttachedImages"],
      }),
      getFeedbackTemplateHomeworksAsReviewer: build.query<
        GetFeedbackTemplateHomeworksAsReviewerApiResponse,
        GetFeedbackTemplateHomeworksAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/feedback_template/homeworks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Reviewer/FeedbackTemplate/Homework"],
      }),
      getCategorizableCommentsAsReviewer: build.query<
        GetCategorizableCommentsAsReviewerApiResponse,
        GetCategorizableCommentsAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/categorizable_comments`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Reviewer/CategorizableComments"],
      }),
      postCategorizableCommentAsReviewer: build.mutation<
        PostCategorizableCommentAsReviewerApiResponse,
        PostCategorizableCommentAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/categorizable_comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/CategorizableComments"],
      }),
      putCategorizableCommentAsReviewer: build.mutation<
        PutCategorizableCommentAsReviewerApiResponse,
        PutCategorizableCommentAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/categorizable_comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.bodyRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/CategorizableComments"],
      }),
      deleteCategorizableCommentAsReviewer: build.mutation<
        DeleteCategorizableCommentAsReviewerApiResponse,
        DeleteCategorizableCommentAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/categorizable_comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/CategorizableComments"],
      }),
      getFeedbackTemplateTagsAsReviewer: build.query<
        GetFeedbackTemplateTagsAsReviewerApiResponse,
        GetFeedbackTemplateTagsAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/feedback_template/tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Reviewer/FeedbackTemplate/Tag"],
      }),
      getTtCsvExportsAsReviewer: build.query<
        GetTtCsvExportsAsReviewerApiResponse,
        GetTtCsvExportsAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/tt_csv/exports`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            types: queryArg.types,
          },
        }),
        providesTags: ["Reviewer/TtCsvExports"],
      }),
      postTtCsvExportAsReviewer: build.mutation<
        PostTtCsvExportAsReviewerApiResponse,
        PostTtCsvExportAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/tt_csv/exports`,
          method: "POST",
          body: queryArg.ttCsvExportRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            sorted_by: queryArg.sortedBy,
            search_key: queryArg.searchKey,
            search_value: queryArg.searchValue,
          },
        }),
        invalidatesTags: ["Reviewer/TtCsvExports"],
      }),
      postCommentImageAsReviewer: build.mutation<
        PostCommentImageAsReviewerApiResponse,
        PostCommentImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/comment_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/CommentImages"],
      }),
      getCommentImageAsReviewer: build.query<
        GetCommentImageAsReviewerApiResponse,
        GetCommentImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/comment_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["Reviewer/CommentImages"],
      }),
      putCommentImageAsReviewer: build.mutation<
        PutCommentImageAsReviewerApiResponse,
        PutCommentImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/comment_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/CommentImages"],
      }),
      deleteCommentImageAsReviewer: build.mutation<
        DeleteCommentImageAsReviewerApiResponse,
        DeleteCommentImageAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/comment_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/CommentImages"],
      }),
      postApprovingAsReviewer: build.mutation<
        PostApprovingAsReviewerApiResponse,
        PostApprovingAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/approvings`,
          method: "POST",
          body: queryArg.approvingRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Reviewer/Approvings"],
      }),
      deleteApprovingAsReviewer: build.mutation<
        DeleteApprovingAsReviewerApiResponse,
        DeleteApprovingAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/approvings/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Reviewer/Approvings"],
      }),
      getUserAsReviewer: build.query<
        GetUserAsReviewerApiResponse,
        GetUserAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/users/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Users"],
      }),
      putUserAsReviewer: build.mutation<
        PutUserAsReviewerApiResponse,
        PutUserAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/users/${queryArg.id}`,
          method: "PUT",
          body: queryArg.userNicknameRequestBody,
        }),
        invalidatesTags: ["Reviewer/Users"],
      }),
      putHomeworkTemplateCustomValueAsReviewer: build.mutation<
        PutHomeworkTemplateCustomValueAsReviewerApiResponse,
        PutHomeworkTemplateCustomValueAsReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/reviewer/homework_template_custom_values/${queryArg.id}`,
          method: "PUT",
          body: queryArg.customValueRequestBodyAsOwnerAndTeacherAndReviewer,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Reviewer/HomeworkTemplateCustomValues"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as reviewerApi };
export type GetReviewerArticlesByArticleIdApiResponse =
  /** status 200 OK */ ArticleDetailBase;
export type GetReviewerArticlesByArticleIdApiArg = {
  articleId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type GetReviewerArticlesApiResponse = /** status 200 OK */ {
  articles: (ArticleBase & {
    excerpt?: string;
    liked?: boolean;
    unliked?: boolean;
  })[];
  total_count: number;
};
export type GetReviewerArticlesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
};
export type GetReviewerArticlesByArticleIdCommentsApiResponse =
  /** status 200 OK */ {
    article_comments: ArticleComment[];
    total_count: number;
  };
export type GetReviewerArticlesByArticleIdCommentsApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  articleId: string;
};
export type PostReviewerArticlesByArticleIdCommentsApiResponse =
  /** status 201 OK */ ArticleComment;
export type PostReviewerArticlesByArticleIdCommentsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  articleId: string;
  bodyRequiredRequestBody: BodyRequiredRequestBody;
};
export type GetReviewerArticlesByArticleIdLikesApiResponse =
  /** status 200 OK */ {
    article_likes: ArticleLike[];
    total_count: number;
  };
export type GetReviewerArticlesByArticleIdLikesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  articleId: string;
};
export type PostReviewerArticlesByArticleIdLikesApiResponse =
  /** status 201 OK */ ArticleLike;
export type PostReviewerArticlesByArticleIdLikesApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  articleId: string;
};
export type DeleteReviewerArticlesByArticleIdCommentsAndCommentIdApiResponse =
  /** status 200 OK */ {
    message: string;
  };
export type DeleteReviewerArticlesByArticleIdCommentsAndCommentIdApiArg = {
  articleId: string;
  commentId: string;
};
export type PutReviewerArticlesByArticleIdLikeApiResponse =
  /** status 200 OK */ Article;
export type PutReviewerArticlesByArticleIdLikeApiArg = {
  articleId: string;
};
export type PutReviewerArticlesByArticleIdUnlikeApiResponse =
  /** status 200 OK */ Article;
export type PutReviewerArticlesByArticleIdUnlikeApiArg = {
  articleId: string;
};
export type DeleteReviewerArticlesByArticleIdLikesAndLikeIdApiResponse =
  /** status 200 OK */ {
    message: string;
  };
export type DeleteReviewerArticlesByArticleIdLikesAndLikeIdApiArg = {
  articleId: string;
  likeId: string;
};
export type GetReviewerReviewersApiResponse = /** status 200 OK */ {
  total_count: number;
  reviewers: BasicUserInfo[];
};
export type GetReviewerReviewersApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  nonReviewerManager?: boolean;
};
export type CreateReviewerAsReviewerApiResponse =
  /** status 201 Created */ ReviewerDetail;
export type CreateReviewerAsReviewerApiArg = {
  userReviewersRequestBodyAsReviewer: UserReviewerRequestBody;
};
export type DeleteReviewerAsReviewerApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteReviewerAsReviewerApiArg = {
  id: string;
};
export type UpdateReviewerAsReviewerApiResponse =
  /** status 200 OK */ ReviewerDetail;
export type UpdateReviewerAsReviewerApiArg = {
  id: string;
  userReviewerRequestBodyAsReviewer: UserReviewerRequestBody2;
};
export type FetchReviewerAffiliatesAsReviewerApiResponse =
  /** status 200 OK */ {
    total_count: number;
    reviewer_affiliates: AffiliateName[];
  };
export type FetchReviewerAffiliatesAsReviewerApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type FetchNotificationsAsReviewerApiResponse = /** status 200 OK */ {
  notifications?: (NotificationBase & {
    read?: boolean;
    company?: {
      id?: string;
      name?: string;
    };
  })[];
  total_count?: number;
};
export type FetchNotificationsAsReviewerApiArg = {
  page?: number;
  perPage?: number;
  sent?: string;
};
export type FetchHomeworkTemplatesAsReviewerApiResponse = /** status 200 OK */ {
  total_count: number;
  templates: HomeworkTemplateForReviewer[];
};
export type FetchHomeworkTemplatesAsReviewerApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  companyId?: string;
};
export type GetHomeworkTemplateAnswersAsReviewerApiResponse =
  /** status 200 OK */ {
    questions: QuestionBase[];
    answers: AnswerBase[][];
  };
export type GetHomeworkTemplateAnswersAsReviewerApiArg = {
  /** HomeworkTemplate ID */
  id: string;
  companyId?: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchHomeworkTemplateDistributionsAsReviewerApiResponse =
  /** status 200 OK */ {
    total_count: number;
    distributions: HomeworkTemplateDistributionBaseForReviewer[];
  };
export type FetchHomeworkTemplateDistributionsAsReviewerApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  companyId?: string;
  homeworkTemplateTitle?: string;
  companyName?: string;
  submittedStartAt?: string;
  submittedEndAt?: string;
  reviewerStartAt?: string;
  reviewerEndAt?: string;
  memo?: string;
  status?: HomeworkTemplateDistributionStatus;
};
export type FetchHomeworkTemplateDistributionAsReviewerApiResponse =
  /** status 200 OK */ HomeworkTemplateDistributionBaseForReviewer;
export type FetchHomeworkTemplateDistributionAsReviewerApiArg = {
  id: string;
};
export type UpdateHomeworkTemplateDistributionAsReviewerApiResponse =
  /** status 200 OK */ HomeworkTemplateDistributionBaseForReviewer;
export type UpdateHomeworkTemplateDistributionAsReviewerApiArg = {
  id: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
  putHomeworkTemplateDistributionRequestBodyAsReviewer: PutHomeworkTemplateDistributionRequestBodyAsReviewer;
};
export type ReturnHomeworkTemplateDistributionToSchoolAsReviewerApiResponse =
  /** status 200 OK */ HomeworkTemplateDistributionBaseForReviewer;
export type ReturnHomeworkTemplateDistributionToSchoolAsReviewerApiArg = {
  /** HomeworkTemplateDistribution ID */
  id: string;
  homeworkTemplateDistributionToSchoolAsReviewerRequestBody: HomeworkTemplateDistributionToSchoolAsReviewerRequestBody;
};
export type RevertHomeworkTemplateDistributionToSchoolAsReviewerApiResponse =
  /** status 200 OK */ HomeworkTemplateDistributionBaseForReviewer;
export type RevertHomeworkTemplateDistributionToSchoolAsReviewerApiArg = {
  /** HomeworkTemplateDistribution ID */
  id: string;
  homeworkTemplateDistributionCommentRequestBody: HomeworkTemplateDistributionCommentRequestBody;
};
export type MergeChunksInHomeworkTemplateDistributionAsReviewerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type MergeChunksInHomeworkTemplateDistributionAsReviewerApiArg = {
  /** HomeworkTemplateDistribution ID */
  id: string;
};
export type DeleteHomeworkTemplateDistributionImageAsReviewerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteHomeworkTemplateDistributionImageAsReviewerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  /** HomeworkTemplateDistribution ID */
  id: string;
  homeworkTemplateDistributionImageContentIdRequiredRequestBody: HomeworkTemplateDistributionImageContentIdRequiredRequestBody;
};
export type GetUserGroupAffiliatesPackagesAsReviewerApiResponse =
  /** status 200 OK */ {
    group_affiliate_packages: GroupAffiliatePackageMinimalBase[];
    total_count: number;
  };
export type GetUserGroupAffiliatesPackagesAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: string;
  perPage?: string;
  /** HomeworkTemplateDistribution ID */
  id: string;
};
export type GetUserTagsAsReviewerApiResponse = /** status 200 OK */ {
  user_tags: UserTagBasic[];
  total_count: number;
};
export type GetUserTagsAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: string;
  perPage?: string;
  /** HomeworkTemplateDistribution ID */
  id: string;
};
export type GetHomeworkTemplateChunkDistributionsAsReviewerApiResponse =
  /** status 200 OK */ {
    homework_template_chunk_distributions: HomeworkTemplateChunkDistributionList[];
    template_chunk_distribution_statuses: HomeworkTemplateChunkDistributionStatuses;
    total_count: number;
  };
export type GetHomeworkTemplateChunkDistributionsAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  homeworkTemplateDistributionId: string;
};
export type GetHomeworkTemplateChunkDistributionAsReviewerApiResponse =
  /** status 200 OK */ HomeworkTemplateChunkDistributionDetail;
export type GetHomeworkTemplateChunkDistributionAsReviewerApiArg = {
  /** HomeworkTemplateChunkDistribution ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutHomeworkTemplateChunkDistributionAsReviewerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutHomeworkTemplateChunkDistributionAsReviewerApiArg = {
  /** HomeworkTemplateChunkDistribution ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  homeworkTemplateChunkDistributionEventRequiredRequestBody: HomeworkTemplateChunkDistributionEventRequiredRequestBody;
};
export type AssignHomeworkTemplateChunkDistributionToReviewerAsReviewerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type AssignHomeworkTemplateChunkDistributionToReviewerAsReviewerApiArg =
  {
    /** HomeworkTemplateChunkDistribution ID */
    id: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    homeworkTemplateChunkDistributionReviewerAffiliateIdRequiredRequestBody: HomeworkTemplateChunkDistributionReviewerAffiliateIdRequiredRequestBody;
  };
export type PostHomeworkTemplateCustomFieldFeedbackAsReviewerApiResponse =
  /** status 201 Created */ HomeworkTemplateCustomFieldFeedback;
export type PostHomeworkTemplateCustomFieldFeedbackAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  homeworkTemplateCustomFieldId: string;
  customFieldFeedbackRequestBody: CustomFieldFeedbackRequestBody;
};
export type PutHomeworkTemplateCustomFieldFeedbacksAsReviewerApiResponse =
  /** status 202 Accepted */ HomeworkTemplateCustomFieldFeedback;
export type PutHomeworkTemplateCustomFieldFeedbacksAsReviewerApiArg = {
  homeworkTemplateCustomFieldId: string;
  /** HomeworkTemplateCustomFieldFeedbackID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putCustomFieldFeedbackRequestBody: PutCustomFieldFeedbackRequestBody;
};
export type DeleteHomeworkTemplateCustomFieldFeedbacksAsReviewerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteHomeworkTemplateCustomFieldFeedbacksAsReviewerApiArg = {
  homeworkTemplateCustomFieldId: string;
  /** HomeworkTemplateCustomFieldFeedbackID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetHomeworkChunksAsReviewerApiResponse = /** status 200 OK */ {
  homework_chunks: HomeworkChunkList[];
  homework_chunk_statuses: HomeworkChunkStatuses;
  total_count: number;
};
export type GetHomeworkChunksAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  homeworkTemplateChunkDistributionId: string;
};
export type GetHomeworkChunkAsReviewerApiResponse = /** status 200 OK */
  | HomeworkChunkDetail
  | /** status 201 Created */ {
      message?: string;
    };
export type GetHomeworkChunkAsReviewerApiArg = {
  /** HomeworkChunk ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutHomeworkChunkAsReviewerApiResponse =
  /** status 202 Accepted */ HomeworkChunkDetail;
export type PutHomeworkChunkAsReviewerApiArg = {
  /** HomeworkChunk ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  homeworkChunkReviewerFileRequiredRequestBody: HomeworkChunkReviewerFileRequiredRequestBody;
};
export type FetchHomeworksAsReviewerApiResponse = /** status 200 OK */ {
  total_count: number;
  homeworks: ReviewerHomework[];
};
export type FetchHomeworksAsReviewerApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  status?: HomeworkStatus;
  companyId?: string;
  distributionId?: string;
  templateId?: string;
  selfAssign?: string;
  reviewerAffiliateId?: string;
  groupAffiliatePackageIds?: string[];
  userTagIds?: string[];
  orderType?: HomeworkOrderType;
  sortType?: SortedByOrder;
};
export type FetchHomeworkAsReviewerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type FetchHomeworkAsReviewerApiArg = {
  homeworkId: string;
};
export type PutReviewerHomeworksByHomeworkIdApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type PutReviewerHomeworksByHomeworkIdApiArg = {
  homeworkId: string;
  putHomeworkRequestBodyAsReviewer: PutHomeworkRequestBodyAsReviewer;
};
export type PutReviewerHomeworksByHomeworkIdAssignApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutReviewerHomeworksByHomeworkIdAssignApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  homeworkId: string;
  homeworkAssignRequestBodyAsReviewer: HomeworkAssignRequestBodyAsReviewer;
};
export type UploadHomeworkImagesAsReviewerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type UploadHomeworkImagesAsReviewerApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    files: string[];
  };
};
export type SaveEditedHomeworkImageAsReviewerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type SaveEditedHomeworkImageAsReviewerApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    file: string;
  };
};
export type UpdateHomeworkImageAsReviewerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type UpdateHomeworkImageAsReviewerApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_id: string;
    file: string;
  };
};
export type DeleteHomeworkImageAsReviewerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type DeleteHomeworkImageAsReviewerApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_id: string;
  };
};
export type GetHomeworkImageDownloadUrlsAsReviewerApiResponse =
  /** status 200 OK */ {
    homeworks: HomeworkDownloadUrl[];
  };
export type GetHomeworkImageDownloadUrlsAsReviewerApiArg = {
  client?: string;
  "access-token"?: string;
  uid?: string;
  homeworkIds: string[];
  fileContent?: "file" | "answer_file" | "reviewer_file";
  templateId?: string;
  orderType?: HomeworkOrderType;
  sortType?: SortedByOrder;
};
export type CountHomeworkStatusesAsReviewerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type CountHomeworkStatusesAsReviewerApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  homeworkCountStatusesRequestBodyAsReviewer: HomeworkCountStatusesRequestBodyAsReviewer;
};
export type FetchSubmitHomeworkFilesRequestsAsReviewerApiResponse =
  /** status 200 OK */ {
    requests: SubmitHomeworkFilesRequest[];
    total_count: number;
  };
export type FetchSubmitHomeworkFilesRequestsAsReviewerApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  perPage?: number;
  page?: number;
  templateId?: string;
  /** ステータスを基にフィルターをかける */
  statuses?: SubmitHomeworkFilesRequestStatus[];
};
export type CreateSubmitHomeworkFilesRequestAsReviewerApiResponse =
  /** status 201 Created */ SubmitHomeworkFilesRequest;
export type CreateSubmitHomeworkFilesRequestAsReviewerApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  userNicknameRequestBody: UserNicknameRequestBody;
};
export type GetReviewerInquiriesApiResponse = /** status 200 OK */ {
  inquiries: InquirySimpleListBase[];
  total_count: number;
};
export type GetReviewerInquiriesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetReviewerInquiriesByIdApiResponse =
  /** status 200 OK */ InquirySimpleBase;
export type GetReviewerInquiriesByIdApiArg = {
  /** inquiries ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTheNumberOfNewCommentInquiriesAsReviewerApiResponse =
  /** status 200 OK */ {
    count?: number;
  };
export type GetTheNumberOfNewCommentInquiriesAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostReviewerInquiryCommentsApiResponse =
  /** status 201 Created */ InquiryComment;
export type PostReviewerInquiryCommentsApiArg = {
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  bodyRequiredRequestBody: BodyRequiredRequestBody;
};
export type PutReviewerInquiryCommentsByIdApiResponse =
  /** status 202 Accepted */ InquiryComment;
export type PutReviewerInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  inquiryCommentRequestBody: InquiryCommentRequestBody;
};
export type DeleteReviewerInquiryCommentsByIdApiResponse =
  /** status 200 OK */ {
    message?: string;
  };
export type DeleteReviewerInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostAttachedImageAsReviewerApiResponse =
  /** status 201 Created */ AttachedImageBase;
export type PostAttachedImageAsReviewerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetAttachedImageAsReviewerApiResponse =
  /** status 200 OK */ AttachedImageBase;
export type GetAttachedImageAsReviewerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PutAttachedImageAsReviewerApiResponse =
  /** status 202 Accepted */ AttachedImageBase;
export type PutAttachedImageAsReviewerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteAttachedImageAsReviewerApiResponse =
  /** status 200 Accepted */ {
    message?: string;
  };
export type DeleteAttachedImageAsReviewerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type GetFeedbackTemplateHomeworksAsReviewerApiResponse =
  /** status 200 OK */ {
    feedback_templates: FeedbackTemplateHomeworkBase[];
    total_count: number;
  };
export type GetFeedbackTemplateHomeworksAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: FeedbackTemplateSearchType;
  searchWord?: string;
  /** タグ名に完全一致するものを返却する */
  tagName?: string;
};
export type GetCategorizableCommentsAsReviewerApiResponse =
  /** status 200 OK */ {
    categorizable_comments: CategorizableCommentBase[];
    total_count: number;
  };
export type GetCategorizableCommentsAsReviewerApiArg = {
  categorizableType: CategorizableCommentCategorizableType;
  /** categorizable_type ID */
  categorizableId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostCategorizableCommentAsReviewerApiResponse =
  /** status 201 Created */ CategorizableCommentBase;
export type PostCategorizableCommentAsReviewerApiArg = {
  categorizableType: CategorizableCommentCategorizableType;
  /** categorizable_type ID */
  categorizableId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: BodyRequiredRequestBody & CommentImagesRequestBody;
};
export type PutCategorizableCommentAsReviewerApiResponse =
  /** status 202 Accepted */ CategorizableCommentBase;
export type PutCategorizableCommentAsReviewerApiArg = {
  categorizableType: CategorizableCommentCategorizableType;
  categorizableId: string;
  /** categorizable comment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  bodyRequiredRequestBody: BodyRequiredRequestBody;
};
export type DeleteCategorizableCommentAsReviewerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteCategorizableCommentAsReviewerApiArg = {
  categorizableType: CategorizableCommentCategorizableType;
  categorizableId: string;
  /** categorizable comment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetFeedbackTemplateTagsAsReviewerApiResponse =
  /** status 200 OK */ {
    feedback_template_tags: HasName[];
    total_count: number;
  };
export type GetFeedbackTemplateTagsAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetTtCsvExportsAsReviewerApiResponse = /** status 200 OK */ {
  tt_csv_exports: TtCsvExportBase[];
  total_count: number;
};
export type GetTtCsvExportsAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  types?: TtCsvExportType[];
};
export type PostTtCsvExportAsReviewerApiResponse =
  /** status 200 OK */ TtCsvExportBase;
export type PostTtCsvExportAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  sortedBy?: string;
  searchKey?: string[];
  searchValue?: string[];
  ttCsvExportRequestBody: TtCsvExportRequestBody;
};
export type PostCommentImageAsReviewerApiResponse =
  /** status 201 Created */ CommentImageBase;
export type PostCommentImageAsReviewerApiArg = {
  categorizableType: CommentImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetCommentImageAsReviewerApiResponse =
  /** status 200 OK */ CommentImageBase;
export type GetCommentImageAsReviewerApiArg = {
  categorizableType: CommentImageCategorizableType;
  categorizableId: string;
  /** CommentImage ID */
  id: string;
};
export type PutCommentImageAsReviewerApiResponse =
  /** status 202 Accepted */ CommentImageBase;
export type PutCommentImageAsReviewerApiArg = {
  categorizableType: CommentImageCategorizableType;
  categorizableId: string;
  /** CommentImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteCommentImageAsReviewerApiResponse =
  /** status 200 202 Accepted */ {
    message?: string;
  };
export type DeleteCommentImageAsReviewerApiArg = {
  categorizableType: CommentImageCategorizableType;
  categorizableId: string;
  /** CommentImage ID */
  id: string;
};
export type PostApprovingAsReviewerApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostApprovingAsReviewerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  approvingRequestBody: ApprovingRequestBody;
};
export type DeleteApprovingAsReviewerApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteApprovingAsReviewerApiArg = {
  categorizableType: ApprovingCategorizableType;
  categorizableId: string;
  /** Approving ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetUserAsReviewerApiResponse = /** status 200 OK */ BasicUserInfo;
export type GetUserAsReviewerApiArg = {
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutUserAsReviewerApiResponse = /** status 202 202 Accepted */ {
  message?: string;
};
export type PutUserAsReviewerApiArg = {
  id: string;
  userNicknameRequestBody: UserNicknameRequestBody;
};
export type PutHomeworkTemplateCustomValueAsReviewerApiResponse =
  /** status 202 Accepted */ {
    homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  };
export type PutHomeworkTemplateCustomValueAsReviewerApiArg = {
  /** HomeworkTemplateCustomValue ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  customValueRequestBodyAsOwnerAndTeacherAndReviewer: CustomValueRequestBodyAsOwnerAndTeacherAndReviewer;
};
export type ModelBase = {
  id: string;
};
export type ArticleKind = "default" | "poster";
export type ArticleStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u518D\u63D0\u51FA/\u63D0\u51FA\u5F85\u3061"
  | "\u5B8C\u4E86"
  | "\u5B8C\u4E86"
  | "\u63D0\u51FA\u6E08\u307F/\u78BA\u8A8D\u4E2D";
export type ArticleStatus =
  | "draft"
  | "correction"
  | "reviewing"
  | "published"
  | "teacher_checking";
export type ArticleAffiliateLinking = {
  affiliate_id: string;
  user?: {
    user_id?: string;
    first_name?: string;
    last_name?: string;
    student_number?: number | null;
    nickname?: string | null;
  };
};
export type CommonTagKind = "system" | "free" | "management";
export type CommonTagBase = ModelBase & {
  kind: CommonTagKind;
  name: string;
};
export type ArticleBaseCommon = ModelBase & {
  student_id: string;
  creator_student_affiliate_id?: string | null;
  student_number: number | null;
  student_name: string | null;
  student_nickname?: string | null;
  title: string;
  kind?: ArticleKind;
  status: ArticleStatusJa;
  status_code: ArticleStatus;
  created_at: string;
  created_at_with_second: string;
  updated_at: string;
  updated_at_with_second: string;
  liked?: boolean;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  company: {
    id: string;
    name: string;
  };
  students_linking: ArticleAffiliateLinking[];
  common_tags: CommonTagBase[];
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type ArticleImageBase = ModelBase & {
  file?: {
    title?: string;
    thumb_url?: string;
    file_url?: string;
  };
};
export type AllowedArticleCategorizableType =
  | "Sponsor::Info"
  | "Pbl::Sponsor::Project";
export type ArticleCategorizable = ModelBase & {
  type: AllowedArticleCategorizableType;
  name: string;
  sponsor_logo: string | null;
  company_name?: string | null;
};
export type ArticleContentType =
  | "theme"
  | "background"
  | "hypothesis"
  | "survey_method"
  | "result"
  | "summary"
  | "future_issues";
export type ArticleContent = ModelBase & {
  content_type: ArticleContentType;
  body: string | null;
};
export type ArticleSponsorContent = ModelBase & {
  pbl_sponsor_article_content_template_field_id: string;
  body: string | null;
};
export type SponsorArticleContentTemplateFieldBase = ModelBase & {
  title: string;
};
export type SponsorArticleContentTemplateBase = ModelBase & {
  title: string;
  description?: string | null;
  pbl_sponsor_lecture_id?: string;
  fields?: SponsorArticleContentTemplateFieldBase[];
};
export type ArticlePoster = {
  sponsor_logo: string | null;
  categorizable?: ArticleCategorizable;
  article_contents: ArticleContent[];
  article_sponsor_contents?: ArticleSponsorContent[];
  pbl_sponsor_article_content_template?: SponsorArticleContentTemplateBase | null;
};
export type BasicUserInfo = ModelBase & {
  first_name: string;
  last_name: string;
  email: string;
  optional_email?: string | null;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  expired_date?: string | null;
  current_sign_in_at?: string | null;
  last_sign_in_at?: string | null;
  linked_project_count?: number | null;
  affiliate_id?: string | null;
  nickname: string | null;
};
export type CompanyBase = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
};
export type Role =
  | "admin"
  | "super_owner"
  | "owner"
  | "teacher"
  | "student"
  | "reviewer"
  | "content_provider";
export type RoleJa =
  | "SV\u7BA1\u7406\u8005"
  | "\u89AA\u7BA1\u7406\u8005"
  | "\u7BA1\u7406\u8005"
  | "\u6559\u8077\u54E1"
  | "\u751F\u5F92"
  | "\u6DFB\u524A\u5229\u7528\u8005"
  | "\u30B3\u30F3\u30C6\u30F3\u30C4\u4E8B\u696D\u8005";
export type RoleBase = ModelBase & {
  name: Role;
  jp_name: RoleJa;
};
export type AffiliateBase = {
  affiliate_id: string;
  user?: BasicUserInfo;
  company?: CompanyBase;
  role?: RoleBase;
};
export type AffiliateName = {
  affiliate_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  nickname: string | null;
};
export type GroupAffiliatePackageBase = ModelBase & {
  title: string;
  description?: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateBase;
  affiliates: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    grade?: string;
    nickname?: string;
  }[];
  assign_teacher_affiliates?: AffiliateName[];
};
export type InterviewRequestStatusJa =
  | "\u627F\u8A8D\u5F85\u3061"
  | "\u975E\u627F\u8A8D"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86"
  | "\u7121\u52B9";
export type InterviewRequestStatus =
  | "idle"
  | "non_approved"
  | "approved"
  | "completed"
  | "invalided";
export type HasIdAndName = {
  id: string;
  name: string;
};
export type ProfessorInfoBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    request_count?: number;
    position?: string | null;
    department?: string | null;
    description?: string | null;
    limit_request?: number | null;
    profile_picture?: string | null;
  };
};
export type InterviewRequestArticleBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  professor_affiliate: ProfessorInfoBase;
};
export type TeacherSponsorProjectListBase = ModelBase & {
  sponsor_info_id: string;
  name: string;
  company_name: string;
};
export type ArticleDetailBase2 = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type ArticleDetailBase = ArticleDetailBase2 & {
  commented: boolean;
};
export type GroupAffiliatePackageMinimalBase = ModelBase & {
  title: string;
};
export type ArticleBase = ArticleBaseCommon & {
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
  interview_requests?: InterviewRequestArticleBase[] | null;
};
export type ErrorsWithKeyMessage = {
  errors: {
    key?: string;
    message?: string;
  }[];
};
export type ArticleCommentImageBase = ModelBase & {
  title: string | null;
  url: string | null;
};
export type ArticleComment = ModelBase & {
  article_id: string;
  created_at: string;
  body: string;
  commentor_id: string | null;
  commentor_company_name?: string | null;
  commenter_name?: string | null;
  commenter_nickname?: string | null;
  commentor_affiliate_id?: string | null;
  role?: RoleBase;
  article_comment_images?: ArticleCommentImageBase[];
};
export type BodyRequiredRequestBody = {
  body: string;
};
export type ArticleLike = ModelBase & {
  article_id: string;
  created_at: string;
};
export type ReviewerCompany = ModelBase & {
  name: string;
};
export type Article = ArticleDetailBase2 & {
  companies?: ReviewerCompany[] | null;
};
export type ReviewerInfo = {
  is_manager: boolean;
};
export type ReviewerDetail = BasicUserInfo & {
  reviewer_info?: ReviewerInfo;
};
export type UserNameRequestBody = {
  first_name?: string;
  last_name?: string;
};
export type UserNameAndEmailRequestBody = UserNameRequestBody & {
  email?: string;
};
export type UserReviewerRequestBody = UserNameAndEmailRequestBody & {
  nickname?: string;
};
export type UserReviewerRequestBody2 = UserNameRequestBody & {
  nickname?: string;
};
export type NotificationBase = ModelBase & {
  title: string;
  message: string;
  resource_info: string | null;
  sender?: {
    id?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
  };
  created_at: string;
  updated_at: string;
  notified_time: string;
};
export type HomeworkTemplateStatus = "draft" | "active" | "inactive";
export type HomeworkTemplateStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u6709\u52B9"
  | "\u505C\u6B62\u4E2D";
export type HomeworkTemplateConvertStatus =
  | "not_needed"
  | "converting"
  | "adding_marks"
  | "adjusting"
  | "merging"
  | "processed"
  | "failed";
export type HomeworkTemplateConvertStatusJa =
  | "\u5909\u63DB\u306A\u3057"
  | "\u5909\u63DB\u4E2D"
  | "\u30DE\u30FC\u30AF\u4E2D"
  | "\u88DC\u6B63\u4E2D"
  | "\u30DE\u30FC\u30B8\u4E2D"
  | "\u51E6\u7406\u5B8C\u4E86"
  | "\u51E6\u7406\u5931\u6557";
export type HomeworkTemplateDistributionStatusJa =
  | "\u65B0\u898F"
  | "\u751F\u5F92\u5C55\u958B\u6E08\u307F"
  | "\u30EC\u30D3\u30E5\u30A2\u30FC\u78BA\u8A8D\u4E2D"
  | "\u30EC\u30D3\u30E5\u30A2\u30FC\u56DE\u7B54\u6E08\u307F"
  | "\u8FD4\u5374\u6E08\u307F";
export type HomeworkTemplateDistributionStatus =
  | "todo"
  | "distributed_students"
  | "submitted_reviewer"
  | "checked_reviewer"
  | "returned";
export type HomeworkTemplateDistributionBase = ModelBase & {
  created_at: string;
  memo: string | null;
  max_distribution_end_users: number | null;
  end_user_count: number;
  start_at: string | null;
  end_at: string | null;
  reviewer_end_at: string | null;
  status: HomeworkTemplateDistributionStatusJa;
  status_code: HomeworkTemplateDistributionStatus;
  is_exam: boolean;
};
export type Option = {
  use_video?: boolean;
  custom_student_ui?: boolean;
  homework_feature?: boolean;
  blog_feature?: boolean;
  text_seller?: boolean;
  use_image?: boolean;
  material?: boolean;
  teacher_material?: boolean;
  report?: boolean;
  payment?: boolean;
  calender?: boolean;
  review_feature?: boolean;
  reviewer_company?: boolean;
  tag?: boolean;
  google_classroom?: boolean;
  steam_library?: boolean;
  content_company?: boolean;
  content_company_review_feature?: boolean;
  dashboard_feature?: boolean;
  study_log_feature?: boolean;
  ideal_feature?: boolean;
  pbl_board_feature?: boolean;
  exam_feature?: boolean;
  rubric_feature?: boolean;
  word_cloud_feature?: boolean;
  rubric_evaluate_feature?: boolean;
  google_drive_feature?: boolean;
  one_drive_feature?: boolean;
  university_cooperation_feature?: boolean;
  article_student_comment_feature?: boolean;
  homework_automatic_scoring_feature?: boolean;
  class_nav_feature?: boolean;
  use_account_lock_mail?: boolean;
  gogo_feature?: boolean;
  pbl_projects_feature?: boolean;
  use_article_and_homework_mail?: boolean;
};
export type UserInputSetting = "required" | "optional" | "hidden";
export type StudentInputSetting = {
  avatar: UserInputSetting;
  postcode: UserInputSetting;
  prefecture: UserInputSetting;
  address1: UserInputSetting;
  address2: UserInputSetting;
  birth_date: UserInputSetting;
  grade: UserInputSetting;
  phone: UserInputSetting;
  parent_first_name: UserInputSetting;
  parent_last_name: UserInputSetting;
  number: UserInputSetting;
};
export type DeletionProgress = "running" | "failed" | "idle";
export type Company = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  custom_master?: {
    title?: string;
    value?: string;
  }[];
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
  report_recipient_email?: string;
  report_the_number_of_users_email?: string;
  report_homework_template_distribution_histories_email?: string;
  inquiry_email?: string;
  limited_user_tag_monthly_report_email?: string;
  created_at?: string;
  owner_number?: number;
  teacher_number?: number;
  student_number?: number;
  has_super_owners?: boolean;
  options?: Option;
  company_tags?: {
    id?: string;
    name?: string;
    grade?: string | null;
    company_tag_type_id?: string;
    company_tag_type_name?: string;
  }[];
  linking_reviewer_companies?: boolean;
  student_input_settings?: StudentInputSetting;
  unique_id?: string | null;
  deletion_progress?: DeletionProgress | null;
  reviewer_company_group?: {
    id?: string;
    name?: string;
  };
  student_count_by_grade?: {
    grade?: string;
    count?: number;
  }[];
};
export type HomeworkTemplateDistributionPblPlanItem = ModelBase & {
  policy_summary: string;
};
export type HomeworkTemplateDistribution = HomeworkTemplateDistributionBase & {
  submitted_at: string | null;
  checked_at: string | null;
  checked_at_raw: string | null;
  returned_at: string | null;
  reviewer_memo: string | null;
  company_memo: string | null;
  company?: Company;
  distribution_images?: {
    reviewer_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
    super_owner_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
    teacher_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
  };
  school_manager?: HasIdAndName;
  pbl_plan_items: HomeworkTemplateDistributionPblPlanItem[];
};
export type HomeworkTemplateBase = {
  id: string;
  created_at: string;
  title: string;
  status_code: HomeworkTemplateStatus;
  status: HomeworkTemplateStatusJa;
  convert_status_code: HomeworkTemplateConvertStatus;
  convert_status: HomeworkTemplateConvertStatusJa;
  homework_template_distribution?: HomeworkTemplateDistribution;
  needs_review: boolean;
  needs_score: boolean;
  is_exam: boolean;
  for_group: boolean;
  has_automatic_scoring: boolean;
  has_attached_file: boolean;
  end_user_count?: number;
  creator_role?: string | null;
  learning_template_package: {
    is_set: boolean;
    title: string | null;
  };
  ideal_thumbnail_file: string | null;
  common_tags: CommonTagBase[];
};
export type HomeworkTemplateCustomFieldsTypes =
  | "HomeworkTemplateCustomTextField"
  | "HomeworkTemplateCustomRadioField"
  | "HomeworkTemplateCustomSelectField"
  | "HomeworkTemplateCustomProfileField";
export type CustomFieldScoreBase = {
  possible_scores: number[] | null;
  possible_scores2: number[] | null;
  answer_flags?: boolean[] | null;
  is_exact_match: boolean | null;
};
export type HasNumberIdAndName = {
  id: number;
  name: string;
};
export type CustomFieldCustomMasterMap = ModelBase & {
  name: string;
  public_columns: HasNumberIdAndName[];
  filter_columns: HasNumberIdAndName[];
  filter_cmmrs: HasIdAndName[];
};
export type TtCategoryBaseType = "TtCategory::HomeworkTemplateCustomField";
export type TtCategoryBase = ModelBase & {
  name: string;
  type: TtCategoryBaseType;
};
export type HomeworkTemplateCustomField = ModelBase & {
  homework_templates_id: string;
  type: HomeworkTemplateCustomFieldsTypes;
  sequence: number;
  title: string;
  possible_values: string[];
  min_length: number | null;
  max_length: number | null;
  is_required: boolean;
  created_at: string;
  updated_at: string;
  score?: CustomFieldScoreBase;
  custom_master_map?: CustomFieldCustomMasterMap;
  custom_field_category?: TtCategoryBase;
};
export type IdealBoardTemplateStatus = "draft" | "reserved" | "distributed";
export type IdealBoardSize = "S" | "M" | "MV" | "L";
export type HasIdAndTitle = {
  id: string;
  title: string;
};
export type BoardTemplateList = ModelBase & {
  name: string;
  status: IdealBoardTemplateStatus;
  size: IdealBoardSize;
  start_at: string;
  homework_template?: HasIdAndTitle | null;
};
export type HomeworkTemplateAddition = {
  content: string;
  reviewer_company_group: {
    id?: string | null;
    name?: string | null;
    is_public_profile?: boolean | null;
  };
  homework_template_images?: {
    id: string;
    title: string;
    thumb_url: string;
    file_url: string;
    adjusted_file_url: string | null;
  }[];
  homework_template_custom_fields: HomeworkTemplateCustomField[];
  ideal_board_template?: BoardTemplateList | null;
  distributed: boolean;
};
export type HomeworkTemplateForReviewer = HomeworkTemplateBase &
  HomeworkTemplateAddition & {
    content?: string;
    submitted_reviewer_homeworks?: number;
    assigned_reviewer_homeworks?: number;
    checked_reviewer_homeworks?: number;
  };
export type QuestionBase = {
  id: number;
  title: string;
};
export type AnswerBase = {
  name: string;
  question_id: number;
  value: string;
};
export type HomeworkTemplateMeta = {
  created_by_owned_company: boolean;
  created_homework_count: number;
  submitted_homework_count: number;
  reviewing_homework_count: number;
  finished_review_homework_count: number;
  returned_homework_count: number;
  school_homework_count: number;
  before_reviewing_homework_count: number;
  set_reviewer_homework_count: number;
};
export type LearningTemplatePackage = {
  is_set: boolean;
  title: string | null;
};
export type HomeworkTemplateDistributionBaseForReviewer =
  HomeworkTemplateDistribution &
    HomeworkTemplateMeta & {
      company: HasIdAndName;
      template: HasIdAndTitle;
      learning_template_package?: LearningTemplatePackage;
    };
export type PutHomeworkTemplateDistributionRequestBodyAsReviewer = {
  comment?: string;
  return_at?: string | null;
  image_contents?: Blob[];
};
export type HomeworkTemplateDistributionToSchoolAsReviewerRequestBody = {
  comment: string | null;
  return_at: string;
  image_contents?: Blob[];
};
export type HomeworkTemplateDistributionCommentRequestBody = {
  comment?: string | null;
};
export type HomeworkTemplateDistributionImageContentIdRequiredRequestBody = {
  image_content_id: string;
};
export type UserTagBasic = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_linking_users?: number;
  kind?: string;
};
export type HomeworkTemplateChunkDistributionStatusJa =
  | "\u65B0\u898F"
  | "\u6DFB\u524A\u4E2D"
  | "\u6DFB\u524A\u6E08";
export type HomeworkTemplateChunkDistributionStatus =
  | "todo"
  | "in_progress"
  | "completed";
export type HasIdAndOrder = {
  id: string;
  order: number;
};
export type HomeworkTemplateChunkDistributionList = ModelBase & {
  status: HomeworkTemplateChunkDistributionStatusJa;
  status_code: HomeworkTemplateChunkDistributionStatus;
  updated_at: string;
  homework_template_chunk: HasIdAndOrder;
  reviewer_affiliate?: AffiliateName;
};
export type HomeworkTemplateChunkDistributionStatuses =
  | {
      status: HomeworkTemplateChunkDistributionStatus;
      count: number;
    }[]
  | null;
export type HomeworkTemplateChunkDistributionDetail =
  HomeworkTemplateChunkDistributionList & {
    template: HasIdAndTitle;
  };
export type HomeworkTemplateChunkDistributionEvent = "complete" | "restart";
export type HomeworkTemplateChunkDistributionEventRequiredRequestBody = {
  event: HomeworkTemplateChunkDistributionEvent;
};
export type HomeworkTemplateChunkDistributionReviewerAffiliateIdRequiredRequestBody =
  {
    reviewer_affiliate_id: string | null;
  };
export type AffiliateInfoBase = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  role_name: string;
  nickname: string | null;
};
export type AttachedImageCategorizableType =
  | "Material"
  | "Ideal::Board"
  | "Article"
  | "Pbl::Board::Content"
  | "Pbl::Sponsor::Lecture"
  | "Ideal::BoardTemplate"
  | "Interview::Message"
  | "HomeworkTemplateCustomFieldFeedback"
  | "Gogo::Message";
export type AttachedImageBase = ModelBase & {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
  file_name: string;
  file_url: string;
};
export type HomeworkTemplateCustomFieldFeedback = ModelBase & {
  body: string;
  commenter_affiliate: AffiliateInfoBase;
  created_at: string;
  feedback_image: AttachedImageBase[];
  wysiwyg_body: string | null;
};
export type CustomFieldFeedbackRequestBody = {
  body: string;
  homework_id: string;
  files?: Blob[];
  wysiwyg_body?: string;
};
export type PutCustomFieldFeedbackRequestBody = {
  body: string;
  wysiwyg_body?: string;
  homework_id: string;
};
export type HomeworkChunkStatusJa = "\u65B0\u898F" | "\u6DFB\u524A\u6E08";
export type HomeworkChunkStatus = "todo" | "completed";
export type HomeworkChunkImage = {
  id: string;
  file: {
    title?: string;
    thumb_url?: string | null;
    url?: string | null;
  };
  reviewer_file: {
    title?: string;
    thumb_url?: string | null;
    url?: string | null;
  };
};
export type HomeworkChunkList = ModelBase & {
  status: HomeworkChunkStatusJa;
  status_code: HomeworkChunkStatus;
  student_name: string;
  homework_chunk_image: HomeworkChunkImage;
};
export type HomeworkChunkStatuses =
  | {
      status: HomeworkChunkStatus;
      count: number;
    }[]
  | null;
export type HomeworkChunkDetail = HomeworkChunkList & {
  order: number;
  next_homework_chunk_id: string | null;
};
export type HomeworkChunkReviewerFileRequiredRequestBody = {
  reviewer_file: Blob;
};
export type HomeworkStatusJa =
  | "\u65B0\u898F"
  | "\u63D0\u51FA\u6E08\u307F"
  | "\u6559\u8077\u54E1\u56DE\u7B54\u6E08\u307F"
  | "\u6DFB\u524A\u524D"
  | "\u6DFB\u524A\u4E2D"
  | "\u6DFB\u524A\u6E08\u307F"
  | "\u8FD4\u5374\u6E08\u307F"
  | "\u8981\u518D\u63D0\u51FA";
export type HomeworkStatus =
  | "todo"
  | "submitted"
  | "checked"
  | "submitted_reviewer"
  | "assigned_reviewer"
  | "checked_reviewer"
  | "returned_reviewer"
  | "change_requested";
export type ReviewerHomework = ModelBase & {
  status: HomeworkStatusJa;
  status_code: HomeworkStatus;
  student_name: string;
  updated_at: string;
  student_number?: number | null;
  assignees?: AffiliateName[];
  user_group_affiliate_packages?: GroupAffiliatePackageMinimalBase[];
  user_tags?: UserTagBasic[];
};
export type HomeworkOrderType =
  | "student_name"
  | "affiliate_package_name"
  | "student_number"
  | "submitted_at"
  | "status"
  | "user_tag_name"
  | "user_affiliate_package_name";
export type SortedByOrder = "asc" | "desc";
export type StudentHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
};
export type HomeworkBase = ModelBase & {
  title: string;
  status: HomeworkStatusJa;
  status_code: HomeworkStatus;
  submitted_at: string | null;
  student_affiliate_id: string | null;
  student_name: string;
  student_nickname?: string | null;
  teacher_name: string;
  start_at: string | null;
  end_at: string | null;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  student_number?: number | null;
  student_homework_images: StudentHomeworkImage[];
  has_attached_file: boolean;
  common_tags: CommonTagBase[];
  ideal_thumbnail_file: string | null;
};
export type CategorizableCommentCategorizableType = "Homework";
export type CommentImageCategorizableType = "Homework" | "CategorizableComment";
export type CommentImageBase = ModelBase & {
  categorizable_id?: string;
  categorizable_type?: CommentImageCategorizableType;
  file_name?: string;
  file_url?: string;
};
export type CategorizableCommentBase = ModelBase & {
  categorizable_type: CategorizableCommentCategorizableType;
  categorizable_id: string;
  body: string;
  created_at: string;
  affiliate: AffiliateInfoBase;
  images: CommentImageBase[] | null;
};
export type TeacherHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
  from_template?: boolean;
  temp_data?: string | null;
};
export type ReviewerHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
};
export type StudentGrade =
  | "under"
  | "elementary1"
  | "elementary2"
  | "elementary3"
  | "elementary4"
  | "elementary5"
  | "elementary6"
  | "junior1"
  | "junior2"
  | "junior3"
  | "high1"
  | "high2"
  | "high3"
  | "university1"
  | "university2"
  | "university3"
  | "university4"
  | "others";
export type CustomMasterPublics = {
  id: string;
  public_columns: HasNumberIdAndName[];
};
export type StudentHomeworkTemplateCustomValue = ModelBase & {
  homework_template_custom_fields_id: string;
  student_id: string;
  profile_values?: {
    user_last_name?: string | null;
    user_first_name?: string | null;
    email?: string | null;
    number?: number | null;
    birth_date?: string | null;
    grade_value?: StudentGrade | null;
    grade?: string | null;
    phone?: string | null;
    parent_last_name?: string | null;
    parent_first_name?: string | null;
    postcode?: string | null;
    prefecture?: string | null;
    address1?: string | null;
    address2?: string | null;
  };
  value?: string | null;
  created_at: string;
  updated_at: string;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  custom_master?: CustomMasterPublics;
  wysiwyg_body?: string | null;
};
export type HomeworkTemplateCustomFieldAndValue =
  HomeworkTemplateCustomField & {
    custom_field_feedbacks: HomeworkTemplateCustomFieldFeedback[];
    homework_template_custom_values: StudentHomeworkTemplateCustomValue[];
    homework_template_custom_value_score?: {
      score?: number | null;
    } | null;
  };
export type IdealBoardStatusJa =
  | "\u65B0\u898F"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86";
export type IdealBoardStatus = "todo" | "in_progress" | "completed";
export type HomeworkBoardBase = ModelBase & {
  title?: string;
  template_id?: string | null;
};
export type BoardBase = ModelBase & {
  name: string;
  status: IdealBoardStatusJa;
  status_code: IdealBoardStatus;
  size: IdealBoardSize;
  created_at: string;
  template_id: string | null;
  homework?: HomeworkBoardBase | null;
  host_affiliate_id: string | null;
};
export type ApprovingStatus = "draft" | "approved";
export type ApprovingBase = ModelBase & {
  status: ApprovingStatus;
  affiliate: AffiliateName;
};
export type HomeworkDetails = HomeworkBase & {
  homework_status_changed_history: {
    status?: HomeworkStatusJa;
    updated_at?: string;
  }[];
  change_requested_at?: string | null;
  teacher_checked_at?: string | null;
  submitted_reviewer_at?: string | null;
  checked_reviewer_at?: string | null;
  returned_reviewer_at?: string | null;
  distribution_status_code?: HomeworkTemplateDistributionStatus;
  needs_review: boolean;
  needs_score: boolean;
  is_exam: boolean;
  created_at: string;
  updated_at: string;
  student_comment: string;
  teacher_comment?: string | null;
  reviewer_comments?: CategorizableCommentBase[] | null;
  teacher_comment_for_reviewer?: string | null;
  reviewer_comment_for_teacher?: string | null;
  score: number | null;
  assignees: AffiliateName[];
  teacher_homework_images: TeacherHomeworkImage[];
  reviewer_homework_images: ReviewerHomeworkImage[];
  content: string;
  company?: {
    id?: string;
    name?: string;
  };
  template?: {
    id?: string;
    title?: string;
  };
  reviewer_end_at?: string | null;
  homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  ideal_board?: BoardBase;
  teacher_comment_images?: CommentImageBase[];
  approvings: ApprovingBase[];
  distribution?: {
    start_at?: string | null;
    end_at?: string | null;
  };
  ideal_thumbnail_file?: string | null;
};
export type PutHomeworkRequestBodyAsReviewer = {
  reviewer_comment?: string;
  reviewer_comment_for_teacher?: string;
  score?: number;
  status?: HomeworkStatus;
};
export type HomeworkAssignRequestBodyAsReviewer = {
  add_reviewer_affiliate_ids?: string[];
  remove_reviewer_affiliate_ids?: string[];
};
export type HomeworkDownloadUrl = ModelBase & {
  student_name: string;
  group_affiliate_name: string;
  student_number?: number | null;
  download_urls: string[];
  is_group: boolean;
};
export type HomeworkCountStatusesRequestBodyAsReviewer = {
  distribution_id: string;
  reviewer_affiliate_ids: string[];
};
export type SubmitHomeworkFilesRequestStatus =
  | "waiting"
  | "processing_qr"
  | "processing_files"
  | "processed"
  | "failed"
  | "adjusting";
export type SubmitHomeworkFilesRequest = ModelBase & {
  status: string;
  status_code: SubmitHomeworkFilesRequestStatus;
  created_at: string;
  error_data: string | null;
  images: {
    scan_result?: string | null;
  }[];
};
export type UserNicknameRequestBody = {
  nickname?: string;
};
export type InquiryAffiliateBase = {
  affiliate_id?: string | null;
  has_deleted: boolean;
  company_id?: string | null;
  company_name?: string | null;
  user_id?: string | null;
  user_name?: string | null;
  email?: string | null;
  role?: RoleBase | null;
};
export type InquiryCommentCount = {
  count: number;
  is_new: boolean;
};
export type InquirySimpleListBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  created_at: string;
  updated_at: string;
  comments: InquiryCommentCount;
};
export type InquiryComment = ModelBase & {
  user: {
    affiliate_id?: string;
    company_id?: string;
    company_name?: string;
    user_id?: string;
    user_name?: string;
  };
  status: string;
  body: string;
  created_at: string;
  updated_at: string;
};
export type AffiliateCompanyAndUserBase = {
  affiliate_id: string;
  user_id: string;
  company_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: RoleBase;
  nickname: string | null;
};
export type InquirySimpleBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  body: string;
  category?: string | null;
  os_name: string;
  os_version: string;
  browser_name: string;
  browser_version: string;
  created_at: string;
  updated_at: string;
  comments: InquiryComment[];
  target_affiliates: AffiliateCompanyAndUserBase[];
};
export type InquiryCommentRequestBody = {
  body: string;
  status?: string;
};
export type FileRequiredRequestBody = {
  file: Blob;
};
export type FeedbackTemplateCommentBase = ModelBase & {
  body: string;
};
export type FeedbackTemplateKind =
  | "anyone"
  | "draft"
  | "teacher_only"
  | "reviewer_only";
export type FeedbackTemplateHomeworkBase = ModelBase & {
  icon: {
    title: string;
    url: string;
  };
  feedback_template_comments: FeedbackTemplateCommentBase[];
  kind: FeedbackTemplateKind;
  tags?: HasIdAndName[] | null;
};
export type FeedbackTemplateSearchType = "tag";
export type CommentImagesRequestBody = {
  comment_images?: Blob[];
};
export type HasName = {
  name: string;
};
export type TtCsvExportStatus = "running" | "completed" | "failed";
export type TtCsvExportTypeJa =
  | "\u4F01\u696D\u30D7\u30ED\u30B8\u30A7\u30AF\u30C8\u60C5\u5831"
  | "\u5B66\u6821\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u5B66\u6821\u30BF\u30B0\u306E\u5B66\u6821\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u6559\u6750\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u4E00\u89A7\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u30EC\u30B3\u30FC\u30C9\u60C5\u5831"
  | "\u9650\u5B9A\u30BF\u30B0\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u5927\u5B66\u6559\u54E1\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u6388\u696D\u8A08\u753B"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831";
export type TtCsvExportBase = ModelBase & {
  status: TtCsvExportStatus;
  type_ja: TtCsvExportTypeJa;
  created_at: string;
  completed_at: string | null;
  file: {
    name?: string | null;
    url?: string | null;
  };
};
export type TtCsvExportType =
  | "admin_sponsor_info_list"
  | "super_owner_company_list"
  | "super_owner_owner_list"
  | "super_owner_teacher_list"
  | "super_owner_student_list"
  | "super_owner_tag_list"
  | "super_owner_company_tag_list"
  | "super_owner_nationwide_user_tag_and_student_list"
  | "super_owner_limited_user_tag_and_student_list"
  | "super_owner_material_distribution_history_list"
  | "super_owner_learning_package_distribution_history_list"
  | "super_owner_learning_package_distribution_history_detail"
  | "super_owner_homework_template_distribution_history_list"
  | "super_owner_homework_template_distribution_history_detail"
  | "super_owner_custom_list"
  | "super_owner_user_tag_distribution_history_list"
  | "owner_owner_list"
  | "owner_teacher_list"
  | "owner_student_list"
  | "owner_user_tag_list"
  | "owner_nationwide_user_tag_and_student_list"
  | "owner_limited_user_tag_and_student_list"
  | "owner_university_teacher_list"
  | "owner_homework_comment_list"
  | "owner_homework_template_custom_field_list"
  | "teacher_homework_template_custom_field_list"
  | "teacher_student_list"
  | "teacher_pbl_plan_schedule_list"
  | "teacher_homework_comment_list"
  | "reviewer_homework_template_custom_field_list"
  | "reviewer_homework_comment_list";
export type TtCsvExportRequestBody = {
  type: TtCsvExportType;
  target_company_id?: string;
  homework_template_id?: string;
  learning_template_package_distribution_setting_id?: string;
  common_custom_master_map_id?: string;
};
export type ApprovingCategorizableType = "Homework";
export type ApprovingEvent = "submit" | "cancel";
export type ApprovingRequestBody = {
  categorizable_type: ApprovingCategorizableType;
  categorizable_id: string;
  event?: ApprovingEvent;
};
export type CustomValueRequestBodyAsOwnerAndTeacherAndReviewer = {
  value?: string;
  group_affiliate_package_id?: string;
  homework_id: string;
  custom_master_id?: string;
  wysiwyg_body?: string;
};
export const {
  useGetReviewerArticlesByArticleIdQuery,
  useGetReviewerArticlesQuery,
  useGetReviewerArticlesByArticleIdCommentsQuery,
  usePostReviewerArticlesByArticleIdCommentsMutation,
  useGetReviewerArticlesByArticleIdLikesQuery,
  usePostReviewerArticlesByArticleIdLikesMutation,
  useDeleteReviewerArticlesByArticleIdCommentsAndCommentIdMutation,
  usePutReviewerArticlesByArticleIdLikeMutation,
  usePutReviewerArticlesByArticleIdUnlikeMutation,
  useDeleteReviewerArticlesByArticleIdLikesAndLikeIdMutation,
  useGetReviewerReviewersQuery,
  useCreateReviewerAsReviewerMutation,
  useDeleteReviewerAsReviewerMutation,
  useUpdateReviewerAsReviewerMutation,
  useFetchReviewerAffiliatesAsReviewerQuery,
  useFetchNotificationsAsReviewerQuery,
  useFetchHomeworkTemplatesAsReviewerQuery,
  useGetHomeworkTemplateAnswersAsReviewerQuery,
  useFetchHomeworkTemplateDistributionsAsReviewerQuery,
  useFetchHomeworkTemplateDistributionAsReviewerQuery,
  useUpdateHomeworkTemplateDistributionAsReviewerMutation,
  useReturnHomeworkTemplateDistributionToSchoolAsReviewerMutation,
  useRevertHomeworkTemplateDistributionToSchoolAsReviewerMutation,
  useMergeChunksInHomeworkTemplateDistributionAsReviewerMutation,
  useDeleteHomeworkTemplateDistributionImageAsReviewerMutation,
  useGetUserGroupAffiliatesPackagesAsReviewerQuery,
  useGetUserTagsAsReviewerQuery,
  useGetHomeworkTemplateChunkDistributionsAsReviewerQuery,
  useGetHomeworkTemplateChunkDistributionAsReviewerQuery,
  usePutHomeworkTemplateChunkDistributionAsReviewerMutation,
  useAssignHomeworkTemplateChunkDistributionToReviewerAsReviewerMutation,
  usePostHomeworkTemplateCustomFieldFeedbackAsReviewerMutation,
  usePutHomeworkTemplateCustomFieldFeedbacksAsReviewerMutation,
  useDeleteHomeworkTemplateCustomFieldFeedbacksAsReviewerMutation,
  useGetHomeworkChunksAsReviewerQuery,
  useGetHomeworkChunkAsReviewerQuery,
  usePutHomeworkChunkAsReviewerMutation,
  useFetchHomeworksAsReviewerQuery,
  useFetchHomeworkAsReviewerQuery,
  usePutReviewerHomeworksByHomeworkIdMutation,
  usePutReviewerHomeworksByHomeworkIdAssignMutation,
  useUploadHomeworkImagesAsReviewerMutation,
  useSaveEditedHomeworkImageAsReviewerMutation,
  useUpdateHomeworkImageAsReviewerMutation,
  useDeleteHomeworkImageAsReviewerMutation,
  useGetHomeworkImageDownloadUrlsAsReviewerQuery,
  useCountHomeworkStatusesAsReviewerMutation,
  useFetchSubmitHomeworkFilesRequestsAsReviewerQuery,
  useCreateSubmitHomeworkFilesRequestAsReviewerMutation,
  useGetReviewerInquiriesQuery,
  useGetReviewerInquiriesByIdQuery,
  useGetTheNumberOfNewCommentInquiriesAsReviewerQuery,
  usePostReviewerInquiryCommentsMutation,
  usePutReviewerInquiryCommentsByIdMutation,
  useDeleteReviewerInquiryCommentsByIdMutation,
  usePostAttachedImageAsReviewerMutation,
  useGetAttachedImageAsReviewerQuery,
  usePutAttachedImageAsReviewerMutation,
  useDeleteAttachedImageAsReviewerMutation,
  useGetFeedbackTemplateHomeworksAsReviewerQuery,
  useGetCategorizableCommentsAsReviewerQuery,
  usePostCategorizableCommentAsReviewerMutation,
  usePutCategorizableCommentAsReviewerMutation,
  useDeleteCategorizableCommentAsReviewerMutation,
  useGetFeedbackTemplateTagsAsReviewerQuery,
  useGetTtCsvExportsAsReviewerQuery,
  usePostTtCsvExportAsReviewerMutation,
  usePostCommentImageAsReviewerMutation,
  useGetCommentImageAsReviewerQuery,
  usePutCommentImageAsReviewerMutation,
  useDeleteCommentImageAsReviewerMutation,
  usePostApprovingAsReviewerMutation,
  useDeleteApprovingAsReviewerMutation,
  useGetUserAsReviewerQuery,
  usePutUserAsReviewerMutation,
  usePutHomeworkTemplateCustomValueAsReviewerMutation,
} = injectedRtkApi;
