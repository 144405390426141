import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import IconButton from "@mui/material/IconButton";
import { Editor,NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import React, { useEffect, useState } from "react";

import useStyles from "../../PlusButtonMenu/styles";
import {
  POSITION_CENTER,
  POSITION_LEFT,
  POSITION_RIGHT,
} from "../../SVBubble/types";

export const ImageOperationComponent = ({
  editor,
}: {
  editor: Editor;
}): JSX.Element => {
  const classes = useStyles();
  const [imageDisplay, setImageDisplay] = useState(false);

  const selectedNodes = editor.view.state.selection.content().content;
  const isImageSelected = selectedNodes.firstChild?.type?.name === "SVImage";

  useEffect(() => {
    editor.view.state.doc.descendants((node) => {
      if (node.type.name === "SVImage") {
        setImageDisplay(true);
      }
      if (node.type.name === "image-wrapper") {
        const { dom } = editor.view;
        const imageWrapper = dom.querySelector(
          "div.image-wrapper",
        ) as HTMLElement;
        if (imageWrapper && !imageWrapper.onmouseenter) {
          imageWrapper.onmouseenter = () => {
            setImageDisplay(true);
          };
        }
        if (imageWrapper && !imageWrapper.onmouseleave) {
          imageWrapper.onmouseleave = () => {
            setImageDisplay(false);
          };
        }
      }
    });
  }, [editor.view.state]);

  return (
    <NodeViewWrapper className="image-wrapper">
      <div
        id="container"
        style={{
          position: "relative",
        }}
      >
        {imageDisplay && isImageSelected && (
          <div className={classes.adjustPosition}>
            <IconButton
              onClick={() => {
                editor.commands.updateAttributes("SVImage", {
                  imagePosition: POSITION_LEFT,
                });
              }}
              className={
                editor.isActive("SVImage", {
                  imagePosition: POSITION_LEFT,
                })
                  ? classes.selectedPosition
                  : ""
              }
              size="large"
            >
              <FormatAlignLeftIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                editor.commands.updateAttributes("SVImage", {
                  imagePosition: POSITION_CENTER,
                });
              }}
              className={
                editor.isActive("SVImage", {
                  imagePosition: POSITION_CENTER,
                })
                  ? classes.selectedPosition
                  : ""
              }
              size="large"
            >
              <FormatAlignCenterIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                editor.commands.updateAttributes("SVImage", {
                  imagePosition: POSITION_RIGHT,
                });
              }}
              className={
                editor.isActive("SVImage", {
                  imagePosition: POSITION_RIGHT,
                })
                  ? classes.selectedPosition
                  : ""
              }
              size="large"
            >
              <FormatAlignRightIcon />
            </IconButton>
          </div>
        )}
      </div>

      <NodeViewContent as="div" />
    </NodeViewWrapper>
  );
};

export default ImageOperationComponent;
