import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import { ModalToOpen } from "@components/UI/organisms/Navigation/Common/Notifications/StudentMessages/hook/useInterviewRequests";
import { FILE_TYPE } from "@constants/file";
import { AttachedImageCategorizableType } from "@lib/Api";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import errorWithMessage from "@lib/rtk/error-utils";
import { Box, IconButton,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { fileSize } from "@root/constants/fileSize";
import {
  InterviewRequestListBase2,
  usePostAttachedImageAsStudentMutation,
  usePostInterviewMessageAsStudentMutation,
} from "@root/endpoints/TimeTactApi/studentApi";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaCalendarAlt, FaPaperclip } from "react-icons/fa";

type Props = {
  isCalendarIconDisplay: boolean;
  isElementsDisabled: boolean;
  isStudentAlreadyMadeProposal: boolean;
  requestId: string;
  refetchMessageList: () => void;
  resetChatInput: () => void;
  handleModalState: (modalName: ModalToOpen) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsIconsWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    iconButtonStyle: {
      boxShadow: "0px 0px 4px 0px #4751494D",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    iconsStyles: {
      color: theme.palette.tertiary.darkGrey,
      "&:hover": {
        color: theme.palette.tertiary.mainDark,
      },
    },
  }),
);

const ActionsButtons: React.FC<Props> = ({
  isCalendarIconDisplay,
  isElementsDisabled,
  requestId,
  isStudentAlreadyMadeProposal,
  refetchMessageList,
  resetChatInput,
  handleModalState,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("university");
  const imageInput = useRef<HTMLInputElement>(null);
  const { actions } = useEnqueueToast();
  const { showError } = actions;

  const [postInterviewMessageAsStudent] =
    usePostInterviewMessageAsStudentMutation();
  const [postAttachedImageAsStudent] = usePostAttachedImageAsStudentMutation();

  const onChangeFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const target = e.target as HTMLInputElement;
    const files = target.files || null;
    if (files && files.length === 1) {
      const file = files[0];
      target.value = "";
      if (file.size > fileSize.image.size) {
        showError(fileSize.image.message);
      } else if (!FILE_TYPE.IMAGE_AND_PDF.MIME_TYPES.includes(file.type)) {
        showError(FILE_TYPE.IMAGE_AND_PDF.MESSAGE);
      } else {
        const messageResult: InterviewRequestListBase2 | void =
          await postInterviewMessageAsStudent({
            requestId,
            body: {
              message: "",
            },
          })
            .unwrap()
            .catch((error: unknown) => showError(errorWithMessage(error)));
        if ((messageResult as InterviewRequestListBase2)?.id) {
          await postAttachedImageAsStudent({
            categorizableId: (messageResult as InterviewRequestListBase2).id,
            categorizableType: AttachedImageCategorizableType.InterviewMessage,
            fileRequiredRequestBody: {
              file,
            },
          })
            .unwrap()
            .then(() => {
              refetchMessageList();
              resetChatInput();
            })
            .catch((error: unknown) => showError(errorWithMessage(error)));
        }
      }
    }
  };
  return (
    <Box className={classes.buttonsIconsWrapper}>
      {isCalendarIconDisplay && (
        <StyledTooltip title={t("scheduleProposalTooltip")}>
          <IconButton
            disabled={isElementsDisabled || isStudentAlreadyMadeProposal}
            onClick={() => handleModalState(ModalToOpen.SCHEDULE_PROPOSAL)}
            className={classes.iconButtonStyle}
            data-cy="interview-chat-calendar-icon"
            size="large"
          >
            <FaCalendarAlt className={classes.iconsStyles} />
          </IconButton>
        </StyledTooltip>
      )}
      <StyledTooltip title={t("fileTooltip")}>
        <IconButton
          disabled={isElementsDisabled}
          onClick={() => imageInput.current?.click()}
          className={classes.iconButtonStyle}
          size="large"
        >
          <FaPaperclip className={classes.iconsStyles} />
        </IconButton>
      </StyledTooltip>
      <input
        accept={FILE_TYPE.IMAGE_AND_PDF.MIME_TYPES.join(",")}
        onChange={onChangeFileInput}
        type="file"
        ref={imageInput}
        style={{ display: "none" }}
      />
    </Box>
  );
};

export default ActionsButtons;
