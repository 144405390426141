export enum SteamLibraryContentEnum {
  contents = "contents",
  comments = "comments",
}

export enum SteamLibraryContentAsStudent {
  contents = "contents",
  inProgress = "inProgress",
}

export enum SteamLibraryContentsEnum {
  bookmarks = "bookmarks",
  contentsList = "contentsList",
}

export const SteamLibraryContentTabs: {
  label: string;
  value: SteamLibraryContentEnum;
}[] = [
  { label: "テーマ", value: SteamLibraryContentEnum.contents },
  { label: "生徒コメント", value: SteamLibraryContentEnum.comments },
];

export const SteamLibraryContentTabsAsStudent: {
  label: string;
  value: SteamLibraryContentAsStudent;
}[] = [
  { label: "テーマ一覧", value: SteamLibraryContentAsStudent.contents },
  { label: "受講中", value: SteamLibraryContentAsStudent.inProgress },
];

export const SteamLibraryContentsTabs: {
  label: string;
  value: SteamLibraryContentsEnum;
}[] = [
  { label: "テーマ一覧", value: SteamLibraryContentsEnum.contentsList },
  { label: "ブックマーク", value: SteamLibraryContentsEnum.bookmarks },
];

export const ITEM_PER_PAGE_COUNT = 12;

export const ITEMS_PER_PAGE = 10;
export const GRID_ITEMS_PER_PAGE = 20;
export const ITEMS_PER_PAGE_FIFTY = 50;

export const MODAL_LIST_CHECK_ITEMS_PER_PAGE = 20;

export const PER_PAGE_LIMIT = 100;

export const parsePageCount = (page: string | null): number => {
  return page ? parseInt(page, 10) : 1;
};
