/* eslint-disable camelcase */
import {
  CreateMaterialParams,
  UpdateMaterialParams,
  UpdateVideoInMaterialWithVideoParams,
} from "@actions/types/material";
import {
  NO_CHANGE,
  THUMBNAIL_DELETED,
} from "@components/Common/Categories/Modals/Material/Edit/Main";
import { NETWORK_ERROR } from "@constants/Messages/responseMessage";
import { SUPER_OWNER_ROUTES } from "@constants/routes";
import {
  Api,
  MaterialsAllContainedCategoryListParams,
  MaterialsListParams,
} from "@lib/Api";
import { http } from "@lib/http";
import { prepareCommonFormForVideoUploading } from "@lib/material/vieo-upload-utils";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

export const fetchMaterialsAsSuperOwner = createAsyncThunk<
  GetResponseType<Api["superOwner"]["materialsList"]>,
  MaterialsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/materials/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.materialsList(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchMaterialAsSuperOwner = createAsyncThunk<
  GetResponseType<Api["superOwner"]["materialsDetail"]>,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/materials/fetchOne", async (materialId, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.materialsDetail(materialId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createMaterialAsSuperOwner = createAsyncThunk<
  any,
  CreateMaterialParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/materials/create", async (params, { rejectWithValue }) => {
  try {
    const { title, categoryId, downloadAllowed, isPublic } = params;
    const response = await api.superOwner.materialsCreate({
      title,
      ...(categoryId && { category_id: categoryId }),
      download_allowed: downloadAllowed,
      is_public: isPublic,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateMaterialAsSuperOwner = createAsyncThunk<
  any,
  UpdateMaterialParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/materials/update", async (params, { rejectWithValue }) => {
  try {
    const fd = prepareCommonFormForVideoUploading(params);
    const {
      pdfFilesData,
      removedImages,
      thumbnailData,
      removedVideos,
      external_link,
      materialVideoId,
      videoChanged,
      videoFileData,
    } = params;

    if (pdfFilesData) {
      pdfFilesData.forEach((fileData) => {
        if (fileData.file) {
          fd.append("image_content_titles[]", fileData.name);
          fd.append(`image_contents[]`, fileData.file, fileData.name);
        } else {
          fd.append("image_content_titles[]", fileData.name);
          fd.append("image_contents[]", "");
        }
      });
    }
    if (removedImages) {
      removedImages.forEach((id) => {
        fd.append("removed_image_ids[]", id);
      });
    }
    if (
      thumbnailData !== NO_CHANGE &&
      typeof thumbnailData !== "string" &&
      typeof thumbnailData !== "undefined"
    ) {
      fd.set("thumbnail_file", thumbnailData.file);
    } else if (thumbnailData === THUMBNAIL_DELETED) {
      fd.set("thumbnail_file", "remove");
    }

    fd.set("video_content_external_link", external_link || "");

    if (external_link && materialVideoId) {
      fd.set("video_id", materialVideoId);
    }
    if (videoChanged && videoFileData) {
      fd.set("is_video_updating", "true");
    }

    if (removedVideos.length) {
      removedVideos.forEach((id) => {
        fd.append("removed_video_ids[]", id);
      });
    }

    const response = await http.put(
      `${SUPER_OWNER_ROUTES.MATERIALS}/${params.id}`,
      fd,
    );
    const resData = response.data;
    return {
      material: resData,
    };
  } catch (err) {
    const status = err?.status || err?.response?.status;
    let errorMessage = "";
    if (status === undefined) {
      errorMessage = NETWORK_ERROR;
    } else {
      errorMessage = errorWithMessage(err.response);
    }
    return rejectWithValue(errorMessage);
  }
});

export const updateVideoInMaterialAsSuperOwner = createAsyncThunk<
  any,
  UpdateVideoInMaterialWithVideoParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/materials/update_video", async (args, { rejectWithValue }) => {
  const { params, setProgress } = args;
  try {
    const fd = prepareCommonFormForVideoUploading(params);
    const { materialVideoId, videoChanged, videoFileData } = params;

    if (materialVideoId) {
      fd.set("video_id", materialVideoId);
    }

    if (videoChanged && videoFileData) {
      fd.set("video_content_title", videoFileData.name);
      fd.set("video_content_thumbnail", videoFileData.thumbnail);
      fd.set("video_content_file", videoFileData.file);
      fd.set("is_video_updating", "false");
    }

    const config = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percent = Math.round((loaded * 100) / total);
        setProgress(percent);
      },
    };
    const response = await http.put(
      `${SUPER_OWNER_ROUTES.MATERIALS}/${params.id}`,
      fd,
      config,
    );
    const resData = response.data;
    return {
      material: resData,
    };
  } catch (err) {
    const status = err?.status || err?.response?.status;
    let errorMessage = "";
    if (status === undefined) {
      errorMessage = NETWORK_ERROR;
    } else {
      errorMessage = errorWithMessage(err.response);
    }
    return rejectWithValue(errorMessage);
  }
});

export const deleteMaterialAsSuperOwner = createAsyncThunk<
  { materialId: string },
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/materials/delete", async (materialId, { rejectWithValue }) => {
  try {
    await api.superOwner.materialsDelete(materialId);
    return {
      materialId,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetFormRelatedData = createAction(
  "superownerApp/materials/resetFormRelatedData",
);

export const resetSuperOwnerSequenceState = createAction(
  "superownerApp/materials/sequence",
);
export interface SaveMaterialSequenceParams {
  sequences: Array<{
    material_id: string;
    sequence: number;
  }>;
}

export const saveMaterialSequenceAsSuperOwner = createAsyncThunk<
  GetResponseType<Api["superOwner"]["putSuperOwnerMaterialsSequence"]>,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/materials/saveSequence",
  async (data: SaveMaterialSequenceParams, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.putSuperOwnerMaterialsSequence(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface ImportMaterialZipFileParams {
  material_id: string;
  zip_file: File;
}

export const importMaterialZipFileAsSuperOwner = createAsyncThunk<
  GetResponseType<Api["superOwner"]["putMaterialImportHtmlAsSuperOwner"]>,
  ImportMaterialZipFileParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(`super_owner/materials/import_html`, async (data, { rejectWithValue }) => {
  try {
    const { zip_file, material_id } = data;
    const fd = new FormData();
    fd.set("material_id", material_id as string);
    fd.set("zip_file", zip_file);
    const response = await http.put(
      `super_owner/materials/${material_id}/import_html`,
      fd,
    );

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchMaterialsAllCategoriesAsSuperOwner = createAsyncThunk<
  GetResponseType<Api["superOwner"]["materialsAllContainedCategoryList"]>,
  MaterialsAllContainedCategoryListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  `super_owner/materials/all_contained_categories`,
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.materialsAllContainedCategoryList(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
