import { useAuth } from "@contexts/Auth";
import useLogoutIfNotLoggedIn from "@lib/hooks/useLogoutIfNotLoggedIn";
import useOptionFeature from "@lib/hooks/useOptionFeature";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

import CommonHeaderIcons from "./CommonHeaderIcons";

const useStyles = () => ({
  headerBar: {
    paddingTop: 2,
  },
  menuButton: (theme: Theme) => ({
    marginLeft: theme.spacing(1),
  }),
  toolbar: (theme: Theme) => ({
    marginTop: theme.spacing(2),
    boxShadow: "0px 2px 4px 0px rgba(71,81,73,0.05)",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
    },
  }),
  secondaryBar: (theme: Theme) => ({
    backgroundColor: theme.palette.common.white,
    zIndex: 0,
  }),
  title: (theme: Theme) => ({
    marginBottom: theme.spacing(2),
    wordBreak: "break-word",
  }),
  isClassNavContainer: (theme: Theme) => ({
    height: "70px",
    width: "100%",
    background: "#F6F6F6",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2, 4),
  }),
  classNavTitle: {},
});

export interface CommonHeaderProps {
  title: string | JSX.Element;
}

const CustomGrid = styled(Grid)`
  background: #fff;
`;

const CommonHeader: React.FC<CommonHeaderProps> = ({ title }) => {
  const classes = useStyles();

  useLogoutIfNotLoggedIn();
  const {
    isCurrentRoles: { isTeacher },
  } = useAuth();
  const { isClassNavFeature } = useOptionFeature();
  const isUsingClassNav = isClassNavFeature && isTeacher;
  if (isUsingClassNav)
    return (
      <Box sx={classes.isClassNavContainer}>
        <Typography variant="h5">{title}</Typography>
      </Box>
    );
  return (
    <>
      <CustomGrid
        container
        spacing={0}
        alignItems="center"
        sx={classes.headerBar}
      >
        <CommonHeaderIcons />
      </CustomGrid>
      <AppBar
        component="div"
        sx={classes.secondaryBar}
        color="default"
        position="static"
        elevation={0}
      >
        <Toolbar sx={classes.toolbar}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography
                sx={classes.title}
                color="inherit"
                variant="h5"
                component="h1"
                data-cy="header-title"
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default CommonHeader;
