import BookIcon from "@assets/images/svg/book_icon.svg?react";
import CaseIcon from "@assets/images/svg/case_icon.svg?react";
import PenIcon from "@assets/images/svg/editIcon.svg?react";
import PblProjectIcon from "@assets/images/svg/pbl-project-icon-grey.svg?react";
import SteamLibraryIcon from "@assets/images/svg/steam-library-icon-grey.svg?react";
import TrashIcon from "@assets/images/svg/trash-icon-green.svg?react";
import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import {
  LearningTemplatePackageBase,
  PackagingAsLearningTemplatePackageBase,
  PackagingPackageableType,
} from "@lib/Api";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, IconButton, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

import CoPackage from "./CoPackage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "60px",
      padding: theme.spacing(0, 1),
    },
    icons: {
      color: theme.palette.common.white,
    },
    packageContainer: {
      position: "relative",
      padding: theme.spacing(0, 1),
      "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.learningTemplatePackage.grey}`,
      },
    },
    dagIcon: {
      position: "absolute",
      top: "13px",
      left: "-39px",
    },
  }),
);

interface Props {
  handleModalOpen: (
    modalStatus: ModalStatus,
    learningTemplateInfos?: LearningTemplatePackageBase,
    packageInfos?: PackagingAsLearningTemplatePackageBase,
    packagesArrayInfos?: PackagingAsLearningTemplatePackageBase[],
  ) => void;
  templatePackageArray: PackagingAsLearningTemplatePackageBase[];
  templatePackage: PackagingAsLearningTemplatePackageBase;
  learningTemplate: LearningTemplatePackageBase;
  idx: number;
  templatePackageId: string;
  sortDisabled: boolean;
  currentPage: number;
}

const Package: React.FC<Props> = ({
  handleModalOpen,
  learningTemplate,
  templatePackage,
  templatePackageArray,
  idx,
  templatePackageId,
  sortDisabled,
  currentPage,
}) => {
  const classes = useStyles();

  let templatePackageIcon: React.ReactNode;

  switch (templatePackage.packageable_type) {
    case PackagingPackageableType.HomeworkTemplate:
      templatePackageIcon = <CaseIcon />;
      break;
    case PackagingPackageableType.PblSteamLecture:
      templatePackageIcon = <SteamLibraryIcon />;
      break;
    case PackagingPackageableType.PblSponsorLecture:
      templatePackageIcon = <PblProjectIcon />;
      break;
    default:
      templatePackageIcon = <BookIcon />;
  }

  return (
    <Draggable
      key={templatePackageId}
      draggableId={templatePackageId}
      index={idx}
      isDragDisabled={sortDisabled}
    >
      {(provided) => (
        <div
          className={classes.packageContainer}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {!sortDisabled && <DragIndicatorIcon className={classes.dagIcon} />}
          {templatePackage.material_homework_template_package ? (
            <CoPackage
              handleModalOpen={handleModalOpen}
              templatePackage={templatePackage}
              learningTemplate={learningTemplate}
              sortDisabled={sortDisabled}
              currentPage={currentPage}
            />
          ) : (
            <Box className={classes.main}>
              <Box display="flex" alignItems="center" data-cy="contents-box">
                <span style={{ marginRight: "16px" }}>
                  {templatePackageIcon}
                </span>
                <Typography data-cy="contents-item">
                  {templatePackage.packageable_title}
                </Typography>
              </Box>
              {!learningTemplate.is_distributed && sortDisabled && (
                <Box>
                  <StyledTooltip title="コンテンツの削除">
                    <IconButton
                      className={classes.icons}
                      onClick={() =>
                        handleModalOpen(
                          ModalStatus.DELETE_PACKAGE,
                          learningTemplate,
                          templatePackage,
                          templatePackageArray,
                        )
                      }
                      size="large"
                    >
                      <TrashIcon />
                    </IconButton>
                  </StyledTooltip>
                  <StyledTooltip title="コンテンツの更新">
                    <IconButton
                      onClick={() =>
                        handleModalOpen(
                          ModalStatus.EDIT_ADDED_PACKAGE,
                          learningTemplate,
                          templatePackage,
                          templatePackageArray,
                        )
                      }
                      className={classes.icons}
                      size="large"
                    >
                      <PenIcon />
                    </IconButton>
                  </StyledTooltip>
                </Box>
              )}
            </Box>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default Package;
