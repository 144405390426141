/* eslint-disable camelcase */

import {
  Api,
  FetchArticleWordCloudAsContentProviderParams,
  GetArticlesAsContentProviderParams,
  WordCloudBase,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RoleApiFunctions } from "@root/types/wordCloud";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticlesAsContentProviderArg {
  params: GetArticlesAsContentProviderParams;
}

export const fetchArticlesAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/article/fetch",
  async (arg: FetchArticlesAsContentProviderArg, { rejectWithValue }) => {
    try {
      const response = await api.contentProvider.getArticlesAsContentProvider(
        arg.params,
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchArticleAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/article/fetchOne",
  async (articleId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getArticleAsContentProvider(articleId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchArticleLikeAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/article/likes",
  async (articleId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.getArticleLikesAsContentProvider({
          article_id: articleId,
        });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const likeArticleAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/articleLike/like",
  async (articleId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.postArticleLikesAsContentProvider(articleId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface UnlikeAsContentProviderArg {
  article_id: string;
  id: string;
}

export const unlikeArticleAsContentProvider = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "contentProvider/article/unlike",
  async (params: UnlikeAsContentProviderArg, { rejectWithValue }) => {
    try {
      const response =
        await api.contentProvider.deleteArticleLikeAsContentProvider(
          params.article_id,
          params.id,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchWordCloudDataAsContentProvider = createAsyncThunk<
  WordCloudBase,
  FetchArticleWordCloudAsContentProviderParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("contentProvider/word_cloud/fetch", async (params, { rejectWithValue }) => {
  try {
    const response =
      await api.contentProvider.fetchArticleWordCloudAsContentProvider(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetLikeRelatedState = createAction(
  "contentProvider/article/resetLikeRelatedState",
);

export const resetArticleRelatedData = createAction(
  "contentProvider/article/resetOne",
);

type ContentProviderActionsType = {
  content_provider: RoleApiFunctions;
};
export const contentProviderWordCloudActions: ContentProviderActionsType = {
  content_provider: {
    fetchWordCloudDataByRole: fetchWordCloudDataAsContentProvider,
  },
};
