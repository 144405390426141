/* eslint-disable camelcase */
import {
  Api,
  FetchHomeworkTemplatesAsTeacherParams,
  FetchHomeworkTemplateStatsAsTeacherParams,
  HomeworkTemplate,
  HomeworkTemplateChunk,
  HomeworkTemplateForOwnerAndTeacher,
} from "@lib/Api";
import { http } from "@lib/http";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { HomeworkTemplateImageParams } from "@root/constants/types";
import { RootState } from "@store/store";

const api = new Api();

export const fetchHomeworkTemplateStatsAsTeacher = createAsyncThunk<
  any,
  FetchHomeworkTemplateStatsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplates/stats/",
  async (
    params: FetchHomeworkTemplateStatsAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.fetchHomeworkTemplateStatsAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const fetchHomeworkTemplatesAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplate/fetch",
  async (
    params: FetchHomeworkTemplatesAsTeacherParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.teacher.fetchHomeworkTemplatesAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const fetchHomeworkTemplateAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplate/fetchOne",
  async (templateId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.fetchHomeworkTemplateAsTeacher(templateId);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const checkTemplateConvertStatusAsTeacher = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplate/checkConvertStatus",
  async (templateId, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.fetchHomeworkTemplateAsTeacher(templateId);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export interface CreateHomeworkTemplateAsTeacherParams {
  title: string;
  content: string | undefined;
  homework_images: any;
  needsReview: boolean | undefined;
  needsScore: boolean | undefined;
  isExam: boolean | undefined;
  forGroup: boolean | undefined;
  idealBoardTemplateId: string | undefined;
  end_at: string | undefined;
  reviewer_end_at: string | undefined;
  reviewerCompany: string;
}

export const createHomeworkTemplateAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplate/create",
  async (data: CreateHomeworkTemplateAsTeacherParams, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.set("title", data.title);
      if (typeof data.content !== "undefined") {
        fd.set("content", data.content);
      }
      if (typeof data.needsReview !== "undefined") {
        fd.append("needs_review", JSON.stringify(data.needsReview));
      }
      if (typeof data.needsScore !== "undefined") {
        fd.append("needs_score", JSON.stringify(data.needsScore));
      }
      if (typeof data.isExam !== "undefined") {
        fd.append("is_exam", JSON.stringify(data.isExam));
      }
      if (typeof data.forGroup !== "undefined") {
        fd.append("for_group", JSON.stringify(data.forGroup));
      }
      if (typeof data.homework_images !== "undefined") {
        data.homework_images.forEach((fileData: any, index: number) => {
          fd.append(`homework_images[]`, fileData.file, fileData.title);
        });
      }
      if (typeof data.end_at !== "undefined") {
        fd.set("end_at", data.end_at);
      }
      if (typeof data.reviewer_end_at !== "undefined") {
        fd.set("reviewer_end_at", data.reviewer_end_at);
      }
      if (typeof data.reviewerCompany !== "undefined") {
        fd.set("reviewer_company_group_id", data.reviewerCompany);
      }
      if (data.idealBoardTemplateId) {
        fd.set("ideal_board_template_id", data.idealBoardTemplateId);
      }

      const response = await http.post(`${"/teacher/homework_templates"}`, fd);
      const resData = response.data as HomeworkTemplate;
      return resData;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const createHomeworkTemplateImageAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateImage/create",
  async (data: HomeworkTemplateImageParams, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.set("file", data?.file as File);
      const response = await http.post(
        `/teacher/homework_templates/${data.homework_template_id}/homework_template_images`,
        fd,
      );
      const resData = response.data as HomeworkTemplate;
      return resData;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const deleteHomeworkTemplateImageAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateImage/delete",
  async (data: HomeworkTemplateImageParams, { rejectWithValue }) => {
    try {
      await api.teacher.deleteHomeworkTemplateImageAsTeacher(
        data.homework_template_id,
        data.id as string,
      );
      return {
        id: data.homework_template_id,
      };
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export interface UpdateHomeworkTemplateAsTeacherParams {
  title?: string;
  content?: string;
  needs_score?: boolean;
  is_exam?: boolean;
  reviewer_company_group_id?: string;
  end_at?: string | null;
  reviewer_end_at?: string | null;
}
export interface UpdateHomeworkTemplateAsTeacherArg {
  templateId: string;
  params: UpdateHomeworkTemplateAsTeacherParams;
}

export const updateHomeworkTemplateAsTeacher = createAsyncThunk<
  HomeworkTemplateForOwnerAndTeacher,
  UpdateHomeworkTemplateAsTeacherArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplate/update",
  async ({ templateId, params }, { rejectWithValue }) => {
    try {
      const response = await api.teacher.updateHomeworkTemplateAsTeacher(
        templateId,
        params,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const deleteHomeworkTemplateAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplate/delete",
  async (templateId: string, { rejectWithValue }) => {
    try {
      await api.teacher.deleteHomeworkTemplateAsTeacher(templateId);
      return {
        id: templateId,
      };
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const fetchHomeworkTemplateChunks = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateChunk/fetch",
  async (template_id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.fetchHomeworkTemplateChunksAsTeacher(template_id);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export interface CreateHomeworkTemplateChunkArg {
  template_id: string | undefined;
  data: {
    image: any;
    homework_template_image_id: string;
    placement: {
      page: number;
      top: number;
      bottom: number;
      left: number;
      right: number;
      natural_width: number;
      natural_height: number;
    };
  };
}

export const createHomeworkTemplateChunk = createAsyncThunk<
  HomeworkTemplateChunk,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateChunk/create",
  async (params: CreateHomeworkTemplateChunkArg, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      if (typeof params.data !== "undefined") {
        fd.append("placement", JSON.stringify([params.data.placement]));
        fd.append(
          "homework_template_image_id",
          params.data.homework_template_image_id,
        );
        fd.append(
          `image`,
          params.data.image,
          `chunk_${params.data.placement.page}.png`,
        );
      }
      const response = await http.post(
        `/teacher/homework_templates/${params.template_id}/homework_template_chunks`,
        fd,
      );
      const resData = response.data as any;
      return resData;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export type DeleteTemplateChunk = {
  template_id: string;
  id: string;
};

export const deleteHomeworkTemplateChunks = createAsyncThunk<
  any,
  DeleteTemplateChunk,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homeworkTemplateChunk/delete",
  async (data: DeleteTemplateChunk, { rejectWithValue }) => {
    try {
      const response = await api.teacher.deleteHomeworkTemplateChunkAsTeacher(
        data.template_id,
        data.id,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const resetModalState = createAction(
  "teacher/homeworkTemplate/resetModalState",
);

export const resetTemplateRelatedData = createAction(
  "teacher/homeworkTemplate/resetOne",
);

export const resetDeleteActionState = createAction(
  "teacher/homeworkTemplate/resetDeleteActionState",
);
export const resetDeleteCustomFields = createAction(
  "teacher/homeworkTemplate/resetCustomFields",
);

export const resetConvertedState = createAction(
  "teacher/homeworkTemplate/resetConvertedState",
);

export const successUpdateTemplateAsTeacher = createAction(
  "teacher/homeworkTemplate/successUpdateTemplate",
);
