/* eslint-disable camelcase */
import { getCompanyLimitOption } from "@actions/superOwnerApp/companyOption";
import { ReduxStatus,ReduxStatusType } from "@constants/redux";
import { LimitOptionBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { getErrorMessage } from "@utils/errorMessage/errorMessage";

export interface SuperOwnerAppCompanyOptionState {
  limitOption: LimitOptionBase | null;
  fetchLimitOption: ReduxStatusType;
  fetchLimitOptionError: string | null;
}

const initialState: SuperOwnerAppCompanyOptionState = {
  limitOption: null,
  fetchLimitOption: ReduxStatus.idle,
  fetchLimitOptionError: null,
};

export const superOwnerAppCompanyOptionSlice = createSlice({
  name: "SuperOwnerApp/CompanyOption",
  initialState,
  reducers: {
    resetCompanyOptionStates: (_state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyLimitOption.pending, (state, _action) => {
        return {
          ...state,
          fetchLimitOption: ReduxStatus.pending,
          fetchLimitOptionError: null,
        };
      })
      .addCase(getCompanyLimitOption.fulfilled, (state, action) => {
        return {
          ...state,
          fetchLimitOption: ReduxStatus.fulfilled,
          limitOption: action.payload?.limit_option || null,
        };
      })
      .addCase(getCompanyLimitOption.rejected, (state, action) => {
        return {
          ...state,
          fetchLimitOption: ReduxStatus.rejected,
          fetchLimitOptionError: getErrorMessage(action.payload?.error),
        };
      });
  },
});

export const superOwnerAppCompanyOptionState = (
  state: RootState,
): SuperOwnerAppCompanyOptionState => state.superOwnerApp.companyOption;

export const { resetCompanyOptionStates } =
  superOwnerAppCompanyOptionSlice.actions;
export default superOwnerAppCompanyOptionSlice.reducer;
