import { AffiliateStudentName } from "@lib/Api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Checkbox, Collapse, TableCell, TableRow, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import ListItem from "../ListItem";

interface Props {
  students: AffiliateStudentName[];
  value: string[];
  handleStudentIds: (checkedId: string) => string[];
  onChange: (...event: any[]) => void;
  gradeName: string;
  handleCheckedByGrade: (
    event: React.ChangeEvent<HTMLInputElement>,
    studentsInfos: AffiliateStudentName[],
  ) => void;
  checkIfGradeIsChecked: (studentPerGrade: AffiliateStudentName[]) => boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainRow: {
      cursor: "pointer",
      borderRadius: "10px",
      boxShadow: `0px 2px 4px #d9d9d9, -5px -5px 9px ${theme.palette.common.white}`,
      backgroundColor: theme.palette.learningTemplatePackage.main,
    },
    tableCell: {
      border: "none",
      color: theme.palette.customColor.text,
      fontWeight: 700,
      padding: "8px 16px",
    },
    gradeCheckBox: {
      color: theme.palette.customColor.text,
      "&.Mui-checked": {
        color: theme.palette.customColor.text,
      },
    },
    collapse: {
      width: "100%",
    },
  }),
);

const Body: React.FC<Props> = ({
  students,
  value,
  gradeName,
  handleStudentIds,
  onChange,
  handleCheckedByGrade,
  checkIfGradeIsChecked,
}) => {
  const [openModule, setOpenModule] = React.useState(false);
  const classes = useStyles();
  return (
    <>
      <TableRow classes={{ root: classes.mainRow }}>
        <TableCell
          classes={{ root: classes.tableCell }}
          align="left"
          style={{ width: "20px" }}
        >
          <Checkbox
            classes={{ root: classes.gradeCheckBox }}
            edge="end"
            color="primary"
            onChange={(event) => {
              handleCheckedByGrade(event, students);
            }}
            checked={checkIfGradeIsChecked(students)}
            data-cy={`${gradeName}-option`}
          />
        </TableCell>
        <TableCell
          classes={{ root: classes.tableCell }}
          align="left"
          onClick={() => setOpenModule((prev) => !prev)}
        >
          {`${gradeName} (${students.length}人)`}
        </TableCell>
        <TableCell
          onClick={() => setOpenModule((prev) => !prev)}
          classes={{ root: classes.tableCell }}
          style={{ width: 63 }}
          align="right"
        >
          {openModule ? (
            <KeyboardArrowUpIcon style={{ display: "block" }} />
          ) : (
            <KeyboardArrowDownIcon style={{ display: "block" }} />
          )}
        </TableCell>
      </TableRow>
      <TableRow style={{ padding: 0 }}>
        <TableCell classes={{ root: classes.tableCell }} colSpan={3}>
          <Collapse
            in={openModule}
            timeout="auto"
            unmountOnExit
            className={classes.collapse}
          >
            {students &&
              students.map((s) => (
                <ListItem
                  key={`student-${s.affiliate_id}`}
                  student={s}
                  handleStudentIds={handleStudentIds}
                  value={value}
                  onChange={onChange}
                />
              ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Body;
