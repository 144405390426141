/* eslint-disable camelcase */
import {
  ImageData,
  SponsorImageData,
  VideoData,
} from "@components/Admin/Sponsors/modals/types";
import {
  Api,
  FetchSponsorLecturesAsAdminParams,
  PutSponsorLectureSequenceAsAdminParams,
  SponsorLectureDetailsBase,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblSponsorLecturesAsAdmin = createAsyncThunk<
  { project_lectures: SponsorLectureDetailsBase[]; total_count: number },
  FetchSponsorLecturesAsAdminParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/pbl/sponsor/lectures/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.fetchSponsorLecturesAsAdmin(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchPblSponsorLectureAsAdmin = createAsyncThunk<
  SponsorLectureDetailsBase,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/pbl/sponsor/lectures/fetchOne", async (id, { rejectWithValue }) => {
  try {
    const response = await api.admin.fetchSponsorLectureAsAdmin(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export interface CreateSponsorLectureAsAdminParams {
  project_id: string;
  name: string;
  description: string;
  tags: string;
  image: SponsorImageData | null;
  video_file: VideoData | null;
  external_link: string | null;
  files: ImageData[];
}

export const createSponsorLectureAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/lectures/create",
  async (params: CreateSponsorLectureAsAdminParams, { rejectWithValue }) => {
    try {
      const {
        project_id,
        name,
        description,
        tags,
        image,
        video_file,
        external_link,
        files,
      } = params;
      const fd = new FormData();
      fd.set("project_id", project_id);
      fd.set("name", name);
      fd.set("description", description);
      if (typeof tags !== "undefined" && tags.length) {
        tags.split(",").forEach((tag) => {
          fd.append(`tags[]`, tag);
        });
      }
      if (typeof image?.file !== "undefined" && image.file) {
        fd.set("image_contents", image.file);
      }
      if (video_file && video_file.file) {
        fd.set("video_file", video_file.file as File);
      }
      if (files.length) {
        files.forEach((f) => {
          if (f.file) {
            fd.append(`files[]`, f.file, f.file.name);
          }
        });
      }
      if (external_link) {
        fd.set("external_link", external_link as string);
      }

      const response = await http.post(`/admin/sponsor/projects/lectures`, fd);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdateSponsorLectureAsAdminParams {
  name: string;
  description: string;
  tags: string;
  image: SponsorImageData | null;
  video_file: VideoData | null;
  external_link: string | null;
  isChanged: boolean;
  isImageChanged: boolean;
}

export interface UpdateSponsorLectureAsAdminArg {
  id: string;
  params: UpdateSponsorLectureAsAdminParams;
}

export const updateSponsorLectureAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/lectures/update",
  async (
    { id, params }: UpdateSponsorLectureAsAdminArg,
    { rejectWithValue },
  ) => {
    try {
      const {
        name,
        description,
        tags,
        image,
        video_file,
        external_link,
        isChanged,
        isImageChanged,
      } = params;
      const fd = new FormData();
      fd.set("name", name);
      fd.set("description", description);
      if (typeof tags !== "undefined" && tags.length) {
        tags.split(",").forEach((tag) => {
          fd.append(`tags[]`, tag);
        });
      }
      if (isImageChanged) {
        if (typeof image?.file !== "undefined" && image.file) {
          fd.set("image_contents", image.file);
        } else {
          fd.set("image_contents", "delete");
        }
      }
      if (isChanged) {
        if (video_file && video_file.file) {
          fd.set("video_file", video_file.file as File);
        } else {
          fd.set("video_file", "delete");
        }
      }
      if (external_link) {
        fd.set("external_link", external_link as string);
      } else {
        fd.set("external_link", "");
      }
      const response = await http.put(
        `/admin/sponsor/projects/lectures/${id}`,
        fd,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSponsorLectureAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/lectures/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.admin.deleteSponsorLectureAsAdmin(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
type putSponsorLectureSequenceParams = {
  query: PutSponsorLectureSequenceAsAdminParams;
  sequences: { pbl_sponsor_lecture_id: string; sequence: number }[];
};
export const saveLectureListSequence = createAsyncThunk<
  any,
  putSponsorLectureSequenceParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/sponsor/projects/lecture/sequence",
  async (arg, { rejectWithValue }) => {
    const { query } = arg;
    try {
      const response = await api.admin.putSponsorLectureSequenceAsAdmin(
        query,
        arg,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetOne = createAction("admin/pbl/sponsor/lectures/resetOne");
export const resetAction = createAction(
  "admin/pbl/sponsor/lectures/resetAction",
);
export const reset = createAction("admin/pbl/sponsor/lectures/reset");
