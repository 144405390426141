/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { PostTemplatePackageParams } from "./types";

const api = new Api();

export const postMaterialHomeworkTemplatePackagesAsSuperOwner =
  createAsyncThunk<
    any,
    PostTemplatePackageParams,
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    "super_owner/materialHomeworkTemplatePackages/post",
    async (params, { rejectWithValue }) => {
      try {
        const response =
          await api.superOwner.postMaterialHomeworkTemplatePackageAsSuperOwner(
            params,
          );
        return response.data as any;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    },
  );

export interface UpdateMaterialHomeworkTemplatePackageAsSuperOwnerParams {
  title: string;
  description: string;
}
export interface UpdateMaterialHomeworkTemplatePackageAsSuperOwner {
  id: string;
  params: UpdateMaterialHomeworkTemplatePackageAsSuperOwnerParams;
}
export const putMaterialHomeworkTemplatePackageAsSuperOwner = createAsyncThunk<
  any,
  UpdateMaterialHomeworkTemplatePackageAsSuperOwner,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/materialHomeworkTemplatePackages/put",
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.putMaterialHomeworkTemplatePackageAsSuperOwner(
          data.id,
          data.params,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const resetMaterialHomeworkTemplatePackagesState = createAction(
  "teacher/materialHomeworkTemplatePackages/resetMaterialHomeworkTemplatePackagesState",
);
