/* eslint-disable react/no-array-index-key */
import MainButton from "@components/UI/atoms/MainButton/MainButton";
import {
  ButtonType,
  Props as V2TabsProps,
  SelectedButton,
  useStyles as useV2TabsStyles,
} from "@components/UIv2/molecules/V2Tabs";
import { Badge, Box } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    tabContainer: {
      display: "flex",
      columnGap: 10,
      width: "max-content",
    },
    badgeAnchor: {
      top: "6%",
      right: "1.5%",
    },
  }),
);

type Props = V2TabsProps & {
  primaryBadgeContent?: React.ReactNode;
  secondaryBadgeContent?: React.ReactNode;
  tertiaryBadgeContent?: React.ReactNode;
};

const V2NotificationTabs: React.FC<Props> = ({
  setButtonState,
  buttonState,
  primary,
  secondary,
  tertiary,
  primaryBadgeContent,
  secondaryBadgeContent,
  tertiaryBadgeContent,
}) => {
  const v2TabsClasses = useV2TabsStyles({ tertiary });
  const classes = useStyles();

  const buttonArray = [
    {
      content: primary,
      badgeContent: primaryBadgeContent,
      dataCy: "primary-button",
      onClick: () => setButtonState(SelectedButton.Primary),
      variant:
        buttonState === SelectedButton.Primary
          ? ButtonType.Contained
          : ButtonType.Text,
    },
    {
      content: secondary,
      badgeContent: secondaryBadgeContent,
      dataCy: "secondary-button",
      onClick: () => setButtonState(SelectedButton.Secondary),
      variant:
        buttonState === SelectedButton.Secondary
          ? ButtonType.Contained
          : ButtonType.Text,
    },
    {
      content: tertiary,
      badgeContent: tertiaryBadgeContent,
      dataCy: "tertiary-button",
      onClick: () => setButtonState(SelectedButton.Tertiary),
      variant:
        buttonState === SelectedButton.Tertiary
          ? ButtonType.Contained
          : ButtonType.Text,
    },
  ];

  return (
    <Box
      className={clsx(classes.tabContainer, v2TabsClasses.tabContainer)}
      data-cy="notification-tabs"
    >
      {buttonArray
        .filter((info) => info.content)
        .map((info, index) => (
          <Badge
            key={index}
            color="error"
            overlap="circular"
            badgeContent={info.badgeContent}
            variant="dot"
            classes={{ anchorOriginTopRightCircular: classes.badgeAnchor }}
            data-cy={`tab-header-${index}`}
          >
            <MainButton
              onClick={info.onClick}
              variant={info.variant}
              data-cy={info.dataCy}
            >
              {info.content}
            </MainButton>
          </Badge>
        ))}
    </Box>
  );
};

V2NotificationTabs.defaultProps = {
  primaryBadgeContent: undefined,
  secondaryBadgeContent: undefined,
  tertiaryBadgeContent: undefined,
};

export default V2NotificationTabs;
