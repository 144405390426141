import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import TableOperationComponent from "./TableOperation";

export const TableWrapper = Node.create({
  name: "table-wrapper",
  group: "block",
  content: "SVTable",

  parseHTML() {
    return [{ tag: "table-wrapper" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "table-wrapper",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(TableOperationComponent);
  },
});

export default TableWrapper;
