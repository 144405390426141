export type SelectedFile = {
  changedFile: File | null;
  base64: any;
  objectUrl: string | null;
  width: number;
  height: number;
  originalWidth: number;
  originalHeight: number;
};

export const defaultSelectedFile = {
  changedFile: null,
  base64: null,
  objectUrl: null,
  height: 0,
  width: 0,
  originalWidth: 0,
  originalHeight: 0,
};
