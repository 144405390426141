/* eslint-disable camelcase */

import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchReviewerCompaniesAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/article/reviewerCompany/fetch",
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchReviewerCompaniesAsStudent();
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
