import {
  getPblBoardCampaignRequestAsOwner,
  pblBoardCampaignRequestSequenceRejected,
  pblBoardCampaignRequestSequenceSuccess,
  putPblBoardCampaignRequestAsOwner,
  putPblBoardCampaignRequestSequenceAsOwner,
  resetOwnerAppPblBoardCampaignRequestModalState,
  resetOwnerAppPblBoardCampaignRequestState,
} from "@actions/ownerApp/pblBoard/campaignRequest";
import { PblBoardCampaignRequestList } from "@lib/Api";
import { update } from "@lib/collection";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface OwnerAppPblBoardCampaignRequestState {
  fetchPblBoardCampaignRequests: ReduxStatusType;
  putPblBoardCampaignRequest: ReduxStatusType;
  campaignRequestSequence: ReduxStatusType;
  putPblBoardCampaignRequestError: string | null;
  pblBoardCampaignRequests: PblBoardCampaignRequestList[];
  totalCount: number;
  sortedSuccess: boolean;
  sortedRejected: boolean;
}

export const initialState: OwnerAppPblBoardCampaignRequestState = {
  fetchPblBoardCampaignRequests: ReduxStatus.idle,
  putPblBoardCampaignRequest: ReduxStatus.idle,
  campaignRequestSequence: ReduxStatus.idle,
  putPblBoardCampaignRequestError: null,
  pblBoardCampaignRequests: [],
  totalCount: 0,
  sortedSuccess: false,
  sortedRejected: false,
};

const ownerAppPblBoardCampaignRequestSlice = createSlice({
  name: "ownerApp/pblBoard/campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPblBoardCampaignRequestAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchPblBoardCampaignRequests: ReduxStatus.pending,
        };
      })
      .addCase(getPblBoardCampaignRequestAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchPblBoardCampaignRequests: ReduxStatus.rejected,
          pblBoardCampaignRequests: [],
        };
      })
      .addCase(getPblBoardCampaignRequestAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchPblBoardCampaignRequests: ReduxStatus.fulfilled,
          pblBoardCampaignRequests: payload.board_campaign_requests,
          totalCount: payload.total_count,
        };
      })
      .addCase(
        putPblBoardCampaignRequestSequenceAsOwner.pending,
        (state, _action) => {
          return {
            ...state,
            campaignRequestSequence: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        putPblBoardCampaignRequestSequenceAsOwner.rejected,
        (state, action) => {
          return {
            ...state,
            campaignRequestSequence: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        putPblBoardCampaignRequestSequenceAsOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            campaignRequestSequence: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(putPblBoardCampaignRequestAsOwner.pending, (state, _action) => {
        return {
          ...state,
          putPblBoardCampaignRequest: ReduxStatus.pending,
          putPblBoardCampaignRequestError: null,
        };
      })
      .addCase(putPblBoardCampaignRequestAsOwner.rejected, (state, action) => {
        return {
          ...state,
          putPblBoardCampaignRequest: ReduxStatus.rejected,
          putPblBoardCampaignRequestError: errorWithMessage(action.payload),
        };
      })
      .addCase(putPblBoardCampaignRequestAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          putPblBoardCampaignRequest: ReduxStatus.fulfilled,
          pblBoardCampaignRequests: update(
            state.pblBoardCampaignRequests,
            payload,
          ),
          putPblBoardCampaignRequestError: null,
        };
      })
      .addCase(resetOwnerAppPblBoardCampaignRequestState, (state, _action) => {
        return {
          ...state,
          fetchPblBoardCampaignRequests: ReduxStatus.idle,
          pblBoardCampaignRequests: [],
          pblBoardCampaignRequest: null,
        };
      })
      .addCase(
        resetOwnerAppPblBoardCampaignRequestModalState,
        (state, _action) => {
          return {
            ...state,
            putPblBoardCampaignRequest: ReduxStatus.idle,
            putPblBoardCampaignRequestError: null,
            sortedSuccess: false,
            sortedRejected: false,
          };
        },
      )
      .addCase(pblBoardCampaignRequestSequenceSuccess, (state, _action) => {
        return {
          ...state,
          sortedSuccess: true,
        };
      })
      .addCase(pblBoardCampaignRequestSequenceRejected, (state, _action) => {
        return {
          ...state,
          sortedRejected: true,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppPblBoardCampaignRequestBoardState = (
  state: RootState,
): OwnerAppPblBoardCampaignRequestState =>
  state.ownerApp.pblBoardCampaignRequest;

export default ownerAppPblBoardCampaignRequestSlice.reducer;
