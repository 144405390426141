/* eslint-disable no-unsafe-optional-chaining */
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { BubbleMenu, Editor } from "@tiptap/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { BubbleMenuContext } from "./BubbleMenuContext";
import ColorGroup from "./ColorGroup";
import HighlightGroup from "./HighlightGroup";
import ImageGroup from "./ImageGroup";
import LinkGroup from "./LinkGroup";
import MaterialLinkGroup from "./MaterialLinkGroup";
import TableGroup from "./TableGroup";
import TextAlignGroup from "./TextAlignGroup";
import TextDecorationGroup from "./TextDecorationGroup";
import TextGroup from "./TextGroup";

const MenuLengthWithMaterialLink = 610;
const MenuLengthWithoutMaterialLink = 520;

const useStyles = makeStyles<Theme, ThemeProps>((theme) =>
  createStyles({
    bubbleMenuAllStyle: ({ menuLength }) => ({
      display: "flex",
      alignItems: "center",
      boxShadow: "0px 0px 6px rgba(102, 102, 102, 0.25)",
      padding: theme.spacing(0.5, 0.5),
      backgroundColor: "white",
      borderRadius: 8,
      width: menuLength,
    }),
  }),
);

type ThemeProps = {
  menuLength: number;
};

type Props = {
  editor: Editor | null;
};

const SVBubble: React.FC<Props> = ({ editor }) => {
  const location = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState<Record<string, boolean>>({
    textGroup: false,
    textAlignGroup: false,
    colorGroup: false,
    linkGroup: false,
    highlightGroup: false,
    materialLinkGroup: false,
  });

  const turnOnMaterialLink = (): boolean => {
    return (
      location.pathname.includes("/materials") || location.pathname === "/"
    );
  };
  const classes = useStyles({
    menuLength: turnOnMaterialLink()
      ? MenuLengthWithMaterialLink
      : MenuLengthWithoutMaterialLink,
  });

  const isOtherSubMenuOpen = (myMenu: string) => {
    const openMenu = Object.keys(subMenuOpen).filter(
      (subMenu) => subMenuOpen[subMenu],
    );

    if (openMenu.length === 0) {
      return false;
    }
    return !openMenu.includes(myMenu);
  };

  const isSubMenuOpen = () =>
    Object.keys(subMenuOpen).filter((subMenu) => subMenuOpen[subMenu])
      .length !== 0;

  const bubbleMenuOpen = () => {
    if (editor?.view) {
      const { state } = editor?.view;
      const { pos } = state.selection.$anchor;
      if (state.doc.resolve(pos).parent.type.name === "image-wrapper") {
        return false;
      }
      return !state.selection.empty;
    }
    return Boolean(editor);
  };

  return (
    <>
      {editor && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{
            zIndex: 100,
            arrow: false,
            maxWidth: 0,
            offset: [-120, 0],
            popperOptions: {
              strategy: "fixed",
              modifiers: [
                {
                  name: "preventOverflow",
                  options: {
                    altAxis: true,
                    padding: 24,
                  },
                },
              ],
            },
          }}
          shouldShow={bubbleMenuOpen}
        >
          <BubbleMenuContext.Provider
            value={{
              editor,
              subMenuOpen,
              setSubMenuOpen,
              isSubMenuOpen,
              isOtherSubMenuOpen,
            }}
          >
            <div className={classes.bubbleMenuAllStyle}>
              <TextGroup />
              <TextAlignGroup />
              <ColorGroup />
              <HighlightGroup />
              <TextDecorationGroup />
              <LinkGroup />
              {turnOnMaterialLink() && <MaterialLinkGroup />}
              <ImageGroup />
              <TableGroup />
            </div>
          </BubbleMenuContext.Provider>
        </BubbleMenu>
      )}
    </>
  );
};

export default SVBubble;
