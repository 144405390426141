// eslint-disable-next-line @typescript-eslint/ban-types
export const objectHas = (o: object, v: PropertyKey): boolean => {
  // Requires a higher version of typescript
  // if (Object.hasOwn && typeof Object.hasOwn === "function") {
  //   return Object.hasOwn(o, v);
  // }
  if (Object.prototype.hasOwnProperty) {
    return Object.prototype.hasOwnProperty.call(o, v);
  }
  return false;
};
