/* eslint-disable camelcase */
import {
  Api,
  GetPblBoardCampaignsAsTeacherParams,
  PblBoardCampaignListAsTeacher,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getPblBoardCampaignsAsTeacher = createAsyncThunk<
  { board_campaigns: PblBoardCampaignListAsTeacher[]; total_count: number },
  GetPblBoardCampaignsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/board/campaigns/fetchOne",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getPblBoardCampaignsAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetTeacherAppPblBoardCampaignState = createAction(
  "teacherApp/pbl/board/resetPblBoardCampaignState",
);
