/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";

import {
  fetchCommonGrades,
  fetchGradesAsAdmin,
  fetchGradesAsSuperOwner,
  resetState,
  updateGradeAsAdmin,
  updateGradeAsSuperOwner,
} from "../actions/grade";
import { RootState } from "../store";

export interface Grade {
  id: string;
  grade: string;
  grade_name: string;
  graduation_year: string;
}

export interface GradeState {
  fetching: boolean;
  fetchedOnce: boolean;
  error: any;
  updating: boolean;
  updated: boolean;
  updateError: any;
  grades: Grade[];
}

export const initialState: GradeState = {
  fetching: false,
  fetchedOnce: false,
  error: null,
  updating: false,
  updated: false,
  updateError: null,
  grades: [],
};

export const gradeSlice = createSlice({
  name: "Grade",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommonGrades.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchCommonGrades.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchedOnce: true,
          grades: action.payload,
        };
      })
      .addCase(fetchCommonGrades.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchedOnce: false,
          error: action.payload,
        };
      })
      .addCase(fetchGradesAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchGradesAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          grades: action.payload.grades,
        };
      })
      .addCase(fetchGradesAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: action.payload,
        };
      })
      .addCase(fetchGradesAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchGradesAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          grades: action.payload.grades,
        };
      })
      .addCase(fetchGradesAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: action.payload,
        };
      })
      .addCase(updateGradeAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
        };
      })
      .addCase(updateGradeAsAdmin.fulfilled, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: true,
          fetchedOnce: false,
        };
      })
      .addCase(updateGradeAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updateError: action.payload,
        };
      })
      .addCase(updateGradeAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
        };
      })
      .addCase(updateGradeAsSuperOwner.fulfilled, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: true,
          fetchedOnce: false,
        };
      })
      .addCase(updateGradeAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updateError: action.payload,
        };
      })
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          updateError: null,
        };
      });
  },
});

export const gradeState = (state: RootState): GradeState => state.grade;

export default gradeSlice.reducer;
