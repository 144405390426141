/* eslint-disable camelcase */
import {
  createCompanyTagCompanyTagging,
  createUserTagCompanyTagging,
  deleteCompanyTagCompanyTagging,
  deleteUserTagCompanyTagging,
  fetchOneCompanyTagsAsSuperOwner,
  fetchOneUserTagsAsSuperOwner,
  resetState,
  updateUserTagCompanyTagging,
} from "@actions/superOwnerApp/companyTagging";
import { CompanyTag, UserTag } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface SuperOwnerCompanyTaggingState {
  changing: boolean;
  changed: boolean;
  changeError: any;
  fetching: boolean;
  fetched: boolean;
  error: any;
  tagsForTagging: CompanyTag[] | UserTag[];
  batchCount: number;
}

export const initialState = {
  changing: false,
  changed: false,
  changeError: null,
  fetching: false,
  fetched: false,
  error: null,
  tagsForTagging: [],
  batchCount: 0,
};

export const superOwnerAppCompanyTaggingSlice = createSlice({
  name: "SuperOwnerApp/CompanyTagging",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCompanyTagCompanyTagging.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(createCompanyTagCompanyTagging.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(createCompanyTagCompanyTagging.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: action.payload as any,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(deleteCompanyTagCompanyTagging.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(deleteCompanyTagCompanyTagging.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(deleteCompanyTagCompanyTagging.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: action.payload as any,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(createUserTagCompanyTagging.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(createUserTagCompanyTagging.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(createUserTagCompanyTagging.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: action.payload as any,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(updateUserTagCompanyTagging.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(updateUserTagCompanyTagging.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(updateUserTagCompanyTagging.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: action.payload as any,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(deleteUserTagCompanyTagging.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
          changeError: null,
        };
      })
      .addCase(deleteUserTagCompanyTagging.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(deleteUserTagCompanyTagging.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          changeError: action.payload as any,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(fetchOneCompanyTagsAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null,
        };
      })
      .addCase(fetchOneCompanyTagsAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          tagsForTagging: action.payload.company_tags,
        };
      })
      .addCase(fetchOneCompanyTagsAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: action.payload as any,
        };
      })
      .addCase(fetchOneUserTagsAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null,
        };
      })
      .addCase(fetchOneUserTagsAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          tagsForTagging: action.payload.user_tags,
        };
      })
      .addCase(fetchOneUserTagsAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: action.payload as any,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: null,
          changing: false,
          changed: false,
          changeError: null,
          tagsForTagging: [],
          batchCount: 0,
        };
      });
  },
});

export const superOwnerAppCompanyTaggingState = (
  state: RootState,
): SuperOwnerCompanyTaggingState => state.superOwnerApp.companyTagging;

export default superOwnerAppCompanyTaggingSlice.reducer;
