import {
  batchUpdateBoardsAffiliateAsStudent,
  resetStudentAppIdealBoardsAffiliates,
} from "@actions/studentApp/ideal/boardsAffiliate";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface StudentAppIdealBoardsAffiliateState {
  batchUpdateIdealAffiliates: ReduxStatusType;
  batchUpdateIdealAffiliatesError: string | null;
}

export const initialState: StudentAppIdealBoardsAffiliateState = {
  batchUpdateIdealAffiliates: ReduxStatus.idle,
  batchUpdateIdealAffiliatesError: null,
};

const StudentAppIdealBoardsAffiliateSlice = createSlice({
  name: "studentApp/IdealBoard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        batchUpdateBoardsAffiliateAsStudent.pending,
        (state, _action) => {
          return {
            ...state,
            batchUpdateIdealAffiliates: ReduxStatus.pending,
            batchUpdateIdealAffiliatesError: null,
          };
        },
      )
      .addCase(
        batchUpdateBoardsAffiliateAsStudent.rejected,
        (state, action) => {
          return {
            ...state,
            batchUpdateIdealAffiliates: ReduxStatus.rejected,
            batchUpdateIdealAffiliatesError:
              "他の人が同時に編集したため、更新に失敗しました。",
          };
        },
      )
      .addCase(
        batchUpdateBoardsAffiliateAsStudent.fulfilled,
        (state, action) => {
          return {
            ...state,
            batchUpdateIdealAffiliates: ReduxStatus.fulfilled,
            batchUpdateIdealAffiliatesError: null,
          };
        },
      )
      .addCase(resetStudentAppIdealBoardsAffiliates, (state, _action) => {
        return {
          ...state,
          batchUpdateIdealAffiliates: ReduxStatus.idle,
          batchUpdateIdealAffiliatesError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppIdealBoardsAffiliateState = (
  state: RootState,
): StudentAppIdealBoardsAffiliateState => state.studentApp.idealBoardsAffiliate;

export default StudentAppIdealBoardsAffiliateSlice.reducer;
