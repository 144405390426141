/* eslint-disable camelcase */
import { DistributionHistoryInfo,MaterialDistributionHistory } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import {
  getErrorMessage,
  getErrorsInArray,
} from "@root/utils/errorMessage/errorMessage";

import {
  distributeMaterialWorkAsOwner,
  fetchAllDistributedMaterialWorksAsOwner,
  fetchUserTagsDistributedByMaterialAsOwner,
  fetchUserTagsForDistributionAsOwner,
  resetState,
} from "../../actions/ownerApp/materialDistribution";
import { RootState } from "../../store";

export interface OwnerAppMaterialDistributionState {
  creating: boolean;
  created: boolean;
  error: any;
  fetching: boolean;
  fetched: boolean;
  fetchError: any;
  distributedHistories: MaterialDistributionHistory[];
  distributedTagInfo: DistributionHistoryInfo[];
  tagInfoForDistribution: DistributionHistoryInfo[];
  totalCount: number;
  changeErrors: string[];
}

const initialState: OwnerAppMaterialDistributionState = {
  creating: false,
  created: false,
  error: null,
  fetching: false,
  fetched: false,
  fetchError: null,
  distributedHistories: [],
  distributedTagInfo: [],
  tagInfoForDistribution: [],
  totalCount: 0,
  changeErrors: [],
};

const ownerAppMaterialDistributionSlice = createSlice({
  name: "OwnerApp/MaterialDistribution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(distributeMaterialWorkAsOwner.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          changeErrors: [],
        };
      })
      .addCase(distributeMaterialWorkAsOwner.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeErrors: getErrorsInArray(action.payload),
        };
      })
      .addCase(distributeMaterialWorkAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          creating: false,
          created: true,
          changeErrors: [],
        };
      })
      .addCase(
        fetchUserTagsDistributedByMaterialAsOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            fetchError: null,
          };
        },
      )
      .addCase(
        fetchUserTagsDistributedByMaterialAsOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            fetchError: null,
            distributedTagInfo: action.payload.user_tags,
            totalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        fetchUserTagsDistributedByMaterialAsOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            fetchError: getErrorMessage(action.payload),
          };
        },
      )
      .addCase(
        fetchUserTagsForDistributionAsOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            fetchError: null,
          };
        },
      )
      .addCase(
        fetchUserTagsForDistributionAsOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            fetchError: null,
            tagInfoForDistribution: action.payload.user_tags,
          };
        },
      )
      .addCase(
        fetchUserTagsForDistributionAsOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            fetchError: getErrorMessage(action.payload),
          };
        },
      )
      .addCase(
        fetchAllDistributedMaterialWorksAsOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            error: null,
          };
        },
      )
      .addCase(
        fetchAllDistributedMaterialWorksAsOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            error: null,
            distributedHistories:
              action.payload.material_distribution_histories,
            totalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        fetchAllDistributedMaterialWorksAsOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            error: action.payload,
            distributedHistories: [],
          };
        },
      )
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          created: false,
          creating: false,
          error: null,
          changeErrors: [],
        };
      });
  },
});

export const ownerAppMaterialDistributionState = (
  state: RootState,
): OwnerAppMaterialDistributionState => state.ownerApp.materialDistribution;

export default ownerAppMaterialDistributionSlice.reducer;
