/* eslint-disable camelcase */
import { Api, GetTeacherSponsorInfosParams } from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchOnePblSponsorInfoAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/infos/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherSponsorInfosId(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPblSponsorInfoAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/pbl/sponsor/infos/fetch",
  async (params: GetTeacherSponsorInfosParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherSponsorInfos(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const reset = createAction("teacher/pbl/sponsor/infos/reset");
export const resetOne = createAction("teacher/pbl/sponsor/infos/resetOne");
