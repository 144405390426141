import { useAuth } from "@contexts/Auth";
import useResponsive from "@lib/hooks/useResponsive";
import { formatLong } from "@lib/time/dateUtils";
import { fullName } from "@lib/user/name-utils";
import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import DefaultPic from "@root/assets/images/png/defaultThumbnail_without_dash.png";
import { InterviewFeedbackBase } from "@root/endpoints/TimeTactApi/studentApi";
import React from "react";
import { FaUser } from "react-icons/fa";

type Props = {
  feedBacks: InterviewFeedbackBase[];
  currentUserId?: string;
};

type StyleProps = {
  isMobileView: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    main: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    wrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
      width: "100%",
      minWidth: "311px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "none",
      },
    },
    mainContentWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      gap: theme.spacing(1),
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        minWidth: "none",
      },
    },
    messageContainer: {
      padding: theme.spacing(2),

      width: "100%",
      minWidth: "311px",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flexDirection: "column",
      gap: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.grey[300]}`,
      [theme.breakpoints.down("sm")]: {
        minWidth: "none",
      },
    },
    messageHeaderWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    avatarWrapper: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    avatarContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
    },
    avatar: {
      borderRadius: "50%",
      width: "auto",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "cover",
    },
    commonTextStyle: {
      fontWeight: 400,
      color: theme.palette.tertiary.mainDark,
      wordBreak: "break-word",
    },
  }),
);

const FeedBacksAnswerTemplate: React.FC<Props> = ({
  feedBacks,
  currentUserId,
}) => {
  const { isMobileView } = useResponsive();
  const classes = useStyles({ isMobileView });
  const {
    isCurrentRoles: { isOwner, isTeacher },
  } = useAuth();

  return (
    <>
      {feedBacks.map((feedBack) => {
        const isFeedBackFiltered =
          isOwner ||
          isTeacher ||
          currentUserId === feedBack.student_affiliate.affiliate_id;
        if (!isFeedBackFiltered) return null;
        return (
          <Box key={feedBack.id} className={classes.main}>
            <Box className={classes.wrapper}>
              {!isMobileView && (
                <Box className={classes.avatarWrapper}>
                  <Box className={classes.avatarContainer}>
                    <img
                      className={classes.avatar}
                      src={DefaultPic}
                      alt="Avatar"
                    />
                  </Box>
                </Box>
              )}
              <Box className={classes.messageContainer}>
                <Box className={classes.messageHeaderWrapper}>
                  <Box className={classes.wrapper}>
                    <span>
                      <FaUser />
                    </span>
                    <Typography>
                      {fullName(feedBack.student_affiliate)}
                    </Typography>
                  </Box>

                  <Box style={{ display: "flex", flexShrink: 0 }}>
                    {formatLong(feedBack.created_at)}
                  </Box>
                </Box>

                <Box className={classes.mainContentWrapper}>
                  <Typography className={classes.commonTextStyle}>
                    【アンケート】
                  </Typography>
                  <Typography className={classes.commonTextStyle}>
                    ・関心が高まりましたか？：{feedBack.degree_of_interest}
                    <br />
                    ・探究が深まりましたか？：{feedBack.degree_of_learning}
                    <br />
                    ・大学からの資料送付：
                    {feedBack.is_request_send_material
                      ? "送付を希望する"
                      : "送付を希望しない"}
                  </Typography>
                  <Typography className={classes.commonTextStyle}>
                    【面談の感想/意見】
                  </Typography>
                  <Box>
                    <Typography className={classes.commonTextStyle}>
                      {feedBack.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default FeedBacksAnswerTemplate;
FeedBacksAnswerTemplate.defaultProps = {
  currentUserId: "",
};
