/* eslint-disable camelcase */
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { Api, GradeMastersListParams } from "../../lib/Api";

const api = new Api();

export const fetchCommonGrades = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("grade/common/fetch", async (_: void, { rejectWithValue }) => {
  try {
    const response = await api.common.gradeMastersList();
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data as any);
  }
});

export const fetchGradesAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("grade/superOwner/fetch", async (_: void, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.gradeMastersList();
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data as any);
  }
});

export const fetchGradesAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "grade/admin/fetch",
  async (data: GradeMastersListParams, { rejectWithValue }) => {
    try {
      const response = await api.admin.gradeMastersList(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data as any);
    }
  },
);

type UpdateGradeParams = {
  id: string;
  graduation_year: string;
};

export const updateGradeAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "grade/superOwner/update",
  async (data: UpdateGradeParams, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.gradeMastersUpdate(data.id, {
        graduation_year: data.graduation_year,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data as any);
    }
  },
);

export const updateGradeAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "grade/admin/update",
  async (data: UpdateGradeParams, { rejectWithValue }) => {
    try {
      const response = await api.admin.gradeMastersUpdate(data.id, {
        graduation_year: data.graduation_year,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data as any);
    }
  },
);

export const resetState = createAction("grade/resetState");
