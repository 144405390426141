import {
  editLearningTemplatePackageAsSuperOwner,
  postLearningTemplatePackageAsSuperOwner,
} from "@actions/superOwnerApp/templatePackages/learningTemplatePackage";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import {
  ModalStateParams,
  ModalStatus,
} from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import { TextField } from "@mui/material";
import { useAppDispatch } from "@root/hooks";
import React, { useEffect } from "react";
import { Controller,useForm } from "react-hook-form";

import { FormType } from "../../index";

interface Props {
  modalState: ModalStateParams;
  posting: boolean;
  posted: boolean;
  handleClose: () => void;
}

const CreateAndEditLearningTemplate: React.FC<Props> = ({
  handleClose,
  modalState,
  posting,
  posted,
}) => {
  const defaultValues = {
    template_title: "",
    template_description: "",
  };
  const dispatch = useAppDispatch();
  const {
    handleSubmit: handleSubmit0,
    reset: reset0,
    control: control0,
  } = useForm<FormType>({ defaultValues });
  const { isOpen, status, learningTemplate } = modalState;
  const isModalOpen =
    isOpen &&
    (status === ModalStatus.CREATE_TEMPLATE ||
      status === ModalStatus.EDIT_TEMPLATE);

  const onSubmit = (values: FormType) => {
    const createParams = {
      title: values.template_title,
      description: values.template_description,
    };
    if (status === ModalStatus.CREATE_TEMPLATE) {
      dispatch(postLearningTemplatePackageAsSuperOwner(createParams));
    } else if (status === ModalStatus.EDIT_TEMPLATE && learningTemplate) {
      if (
        values.template_title === learningTemplate.title &&
        values.template_description === learningTemplate.description
      ) {
        return handleClose();
      }
      const editParams = {
        id: learningTemplate.id,
        data: {
          title: values.template_title,
          description: values.template_description,
        },
      };
      dispatch(editLearningTemplatePackageAsSuperOwner(editParams));
    }
    return null;
  };

  const modalCloser = () => {
    handleClose();
    reset0(defaultValues);
  };

  useEffect(() => {
    if (isOpen && status === ModalStatus.EDIT_TEMPLATE && learningTemplate) {
      reset0({
        template_title: learningTemplate.title,
        template_description: learningTemplate.description,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (posted) {
      reset0(defaultValues);
    }
  }, [posted]);

  let modalTitle = "プログラムの作成";
  let primaryButtonText = "作成する";
  if (status === ModalStatus.EDIT_TEMPLATE) {
    modalTitle = "プログラムの変更";
    primaryButtonText = "更新する";
  }

  return (
    <GenericModal
      useActionButtons
      useSecondaryButton
      title={modalTitle}
      modalSize="sm"
      handleClose={modalCloser}
      secondaryButtonAction={modalCloser}
      primaryButtonAction={handleSubmit0(onSubmit)}
      primaryButtonText={primaryButtonText}
      secondaryButtonText="キャンセル"
      open={isModalOpen}
      posting={posting}
    >
      <>
        <FormSectionWrap>
          <FormCategoryTitle>プログラム名*</FormCategoryTitle>
          <Controller
            control={control0}
            name="template_title"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                id="template_title"
                placeholder="プログラム名を入力してください"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                data-cy="program-name"
              />
            )}
            rules={{ required: "プログラム名は必須です" }}
          />
        </FormSectionWrap>
        <FormSectionWrap>
          <FormCategoryTitle>プログラム詳細</FormCategoryTitle>
          <Controller
            control={control0}
            name="template_description"
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                id="template_description"
                placeholder="プログラムの説明を入力してください"
                onChange={onChange}
                value={value}
                data-cy="program-detail"
              />
            )}
          />
        </FormSectionWrap>
      </>
    </GenericModal>
  );
};

export default CreateAndEditLearningTemplate;
