/* eslint-disable camelcase */
import PenIcon from "@assets/images/svg/editIcon.svg?react";
import SendIcon from "@assets/images/svg/send-icon.svg?react";
import TrashIcon from "@assets/images/svg/trash-icon-green.svg?react";
import { DistributionEnum } from "@components/SuperOwner/LearningTemplatePackages/LearningTemplateList";
import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import CustomMenu from "@components/UIv2/atoms/CustomMenu";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import {
  LearningTemplatePackageBase,
  PackagingAsLearningTemplatePackageBase,
} from "@lib/Api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, IconButton,TableCell, TableRow, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import dayjs from "dayjs";
import React, { useState } from "react";

import useAccordionContents from "./AccordionContents";

interface Props {
  learningTemplate: LearningTemplatePackageBase;
  handleModalOpen: (
    modalStatus: ModalStatus,
    learningTemplateInfos?: LearningTemplatePackageBase,
    packageInfos?: PackagingAsLearningTemplatePackageBase,
    packagesArrayInfos?: PackagingAsLearningTemplatePackageBase[],
  ) => void;
  handleDistributionModal: (
    type: DistributionEnum,
    learningTemplateInfos: LearningTemplatePackageBase,
    packagesArrayInfos: PackagingAsLearningTemplatePackageBase[],
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButtonWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
    },
    sequenceButtonContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 5,
    },
    cell: {
      cursor: "pointer",
    },
    icons: {
      width: 44,
      height: 44,
      color: theme.palette.common.white,
    },
    packagesWrapper: {
      backgroundColor: "rgba(0, 130, 103, 0.05)",
      marginTop: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      width: "auto",
      borderRadius: 8,
    },
    dividerRoot: {
      backgroundColor: theme.palette.learningTemplatePackage.main,
      margin: theme.spacing(2, 0),
      height: "2px",
    },
    description: {
      color: theme.palette.customColor.text,
      width: "100%",
    },
    sortIcon: {
      marginRight: theme.spacing(1),
      cursor: "pointer",
    },
  }),
);

export const StyledRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
      "& > *": {
        borderBottom: "unset",
      },
    },
  }),
)(TableRow);

const ControlledAccordions: React.FC<Props> = ({
  learningTemplate,
  handleModalOpen,
  handleDistributionModal,
}) => {
  const [openModule, setOpenModule] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();
  const { render: AccordionContents, packagesArray } = useAccordionContents({
    learningTemplate,
    openModule,
    handleModalOpen,
  });
  const openDistributionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      key: "school-distribution",
      title: "学校で配信する",
      action: () => {
        handleDistributionModal(
          DistributionEnum.BY_SCHOOL,
          learningTemplate,
          packagesArray,
        );
        handleMenuClose();
      },
    },
    {
      key: "userTag-distribution",
      title: "ユーザータグで配信する",
      action: () => {
        handleDistributionModal(
          DistributionEnum.BY_USER_TAG,
          learningTemplate,
          packagesArray,
        );
        handleMenuClose();
      },
    },
    {
      key: "limitedTag-distribution",
      title: "限定ユーザータグで配信する",
      action: () => {
        handleDistributionModal(
          DistributionEnum.BY_LIMITED_USER_TAG,
          learningTemplate,
          packagesArray,
        );
        handleMenuClose();
      },
    },
  ];

  return (
    <>
      <StyledRow data-cy={`program-${learningTemplate.title}-row`}>
        <TableCell
          className={classes.cell}
          onClick={() => setOpenModule((prev) => !prev)}
          align="left"
          style={{ color: "black" }}
          data-cy="program-title"
        >
          {learningTemplate.title}
        </TableCell>
        <TableCell
          className={classes.cell}
          onClick={() => setOpenModule((prev) => !prev)}
          align="right"
          style={{ color: "black" }}
        >
          {dayjs(learningTemplate.created_at).format("YYYY/MM/DD")}
        </TableCell>
        <TableCell style={{ width: 180 }} align="left">
          <Box
            style={{
              width: "100%",
              gap: 10,
            }}
          >
            <StyledTooltip title="プログラムの配信">
              <IconButton
                onClick={openDistributionMenu}
                aria-label="expand row"
                className={classes.icons}
                data-cy="program-distribution"
                size="large"
              >
                <SendIcon />
              </IconButton>
            </StyledTooltip>
            <CustomMenu
              menuItems={menuItems}
              id="distribution-menu-settings"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            />
            {!learningTemplate.is_distributed && (
              <>
                <StyledTooltip title="プログラムの更新">
                  <IconButton
                    onClick={() =>
                      handleModalOpen(
                        ModalStatus.EDIT_TEMPLATE,
                        learningTemplate,
                      )
                    }
                    aria-label="expand row"
                    className={classes.icons}
                    data-cy="program-edit"
                    size="large"
                  >
                    <PenIcon />
                  </IconButton>
                </StyledTooltip>
                <StyledTooltip title="プログラムの削除">
                  <IconButton
                    className={classes.icons}
                    onClick={() =>
                      handleModalOpen(
                        ModalStatus.DELETE_TEMPLATE,
                        learningTemplate,
                      )
                    }
                    data-cy="program-delete"
                    size="large"
                  >
                    <TrashIcon />
                  </IconButton>
                </StyledTooltip>
              </>
            )}
          </Box>
        </TableCell>
        <TableCell
          className={classes.cell}
          onClick={() => setOpenModule((prev) => !prev)}
          style={{ width: 63 }}
          align="right"
          data-cy="open-content"
        >
          {openModule ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
      </StyledRow>
      {AccordionContents}
    </>
  );
};
export default ControlledAccordions;
