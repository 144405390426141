import MainWrapper from "@components/UI/organisms/Layout/MainWrapper";
import TabDetailHeader from "@components/UI/organisms/Navigation/Admin/Header/TabDetailHeader";
import CommonHeader from "@components/UI/organisms/Navigation/Common/CommonHeader";
import { ContentType } from "@containers/SuperOwner/LearningTemplatePackages";
import React from "react";

interface PackageTemplateHeaderProps {
  children: React.ReactNode;
  detailView: ContentType;
  tabClickHandler: (value: string) => void;
}

const SuperOwnerLearningPackageTemplate: React.FC<
  PackageTemplateHeaderProps
> = ({ children, detailView, tabClickHandler }) => {
  const tabs = [
    { label: "プログラム一覧", value: ContentType.PACKAGE },
    { label: "配信履歴一覧", value: ContentType.PACKAGE_HISTORY },
  ];

  return (
    <>
      <CommonHeader title="プログラム一覧" />
      <TabDetailHeader
        selectedType={detailView}
        tabClickHandler={tabClickHandler}
        tabs={tabs}
      />
      <MainWrapper>{children}</MainWrapper>
    </>
  );
};

export default SuperOwnerLearningPackageTemplate;
