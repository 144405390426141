import {
  createMaterialTicketAsSuperOwner,
  putMaterialTicketAsSuperOwner,
} from "@actions/superOwnerApp/materialTicket";
import { createSlice, current } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { HasIdAndTitle,MaterialDetail, MaterialList } from "@root/lib/Api";
import { remove, update } from "@root/lib/collection";
import {
  createMaterialAsSuperOwner,
  deleteMaterialAsSuperOwner,
  fetchMaterialAsSuperOwner,
  fetchMaterialsAllCategoriesAsSuperOwner,
  fetchMaterialsAsSuperOwner,
  importMaterialZipFileAsSuperOwner,
  resetFormRelatedData,
  resetSuperOwnerSequenceState,
  saveMaterialSequenceAsSuperOwner,
  updateMaterialAsSuperOwner,
  updateVideoInMaterialAsSuperOwner,
} from "@root/store/actions/superOwnerApp/material";
import { getErrorMessage } from "@root/utils/errorMessage/errorMessage";
import { RootState } from "@store/store";

export interface SuperOwnerAppMaterialState {
  fetchStatus: ReduxStatusType;
  fetchingOne: boolean;
  fetchedOne: boolean;
  savingSequence: boolean;
  savedSequence: boolean;
  saveSequenceError: any;
  processing: boolean;
  processed: boolean; // whole process
  error: any;
  fetchOneError: any;
  createMaterialTicketError: string | null;
  putMaterialTicketError: string | null;
  materials: MaterialList[];
  totalCount: number;
  materialAllCategoriesCount: number;
  material: MaterialDetail | null;
  materialTicket: string | null;
  importMaterialZip: ReduxStatusType;
  importMaterialZipError: any;
  fetchingAllCategories: ReduxStatusType;
  materialAllCategories: HasIdAndTitle[];
  createMaterialStatus: ReduxStatusType;
  updateMaterialStatus: ReduxStatusType;
  deleteMaterialStatus: ReduxStatusType;
  updateVideoStatus: ReduxStatusType;
  updateVideoError: string | null;
}

export const initialState: SuperOwnerAppMaterialState = {
  fetchStatus: ReduxStatus.idle,
  fetchingOne: false,
  fetchedOne: false,
  savingSequence: false,
  savedSequence: false,
  processing: false,
  processed: false,
  error: null,
  fetchOneError: null,
  createMaterialTicketError: null,
  putMaterialTicketError: null,
  saveSequenceError: null,
  materials: [],
  totalCount: 0,
  materialAllCategoriesCount: 0,
  material: null,
  materialTicket: null,
  importMaterialZip: ReduxStatus.idle,
  importMaterialZipError: false,
  fetchingAllCategories: ReduxStatus.idle,
  materialAllCategories: [],
  createMaterialStatus: ReduxStatus.idle,
  updateMaterialStatus: ReduxStatus.idle,
  deleteMaterialStatus: ReduxStatus.idle,
  updateVideoStatus: ReduxStatus.idle,
  updateVideoError: null,
};

const superOwnerAppMaterialSlice = createSlice({
  name: "SuperOwnerApp/Material",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterialsAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchStatus: ReduxStatus.pending,
        };
      })
      .addCase(fetchMaterialsAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchStatus: ReduxStatus.rejected,
          error: action.error.message,
        };
      })
      .addCase(fetchMaterialsAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchStatus: ReduxStatus.fulfilled,
          materials: payload.materials,
          totalCount: payload.total_count,
        };
      })
      .addCase(
        fetchMaterialsAllCategoriesAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingAllCategories: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetchMaterialsAllCategoriesAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingAllCategories: ReduxStatus.rejected,
            errorAllCategories: action.error.message,
          };
        },
      )
      .addCase(
        fetchMaterialsAllCategoriesAsSuperOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            materialAllCategories: payload.materials,
            materialAllCategoriesCount: payload.total_count,
            fetchingAllCategories: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(fetchMaterialAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
          fetchOneError: null,
          material: null,
        };
      })
      .addCase(fetchMaterialAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: action.error.message,
        };
      })
      .addCase(fetchMaterialAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          material: payload,
        };
      })
      .addCase(createMaterialAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          createMaterialStatus: ReduxStatus.pending,
          error: null,
        };
      })
      .addCase(createMaterialAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
          createMaterialStatus: ReduxStatus.rejected,
        };
      })
      .addCase(createMaterialAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          error: null,
          material: payload,
          materials: [...currentState.materials, payload],
          createMaterialStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(updateMaterialAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          error: null,
          updateMaterialStatus: ReduxStatus.pending,
        };
      })
      .addCase(updateMaterialAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          updateMaterialStatus: ReduxStatus.rejected,
        };
      })
      .addCase(updateMaterialAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          error: null,
          material: payload.material,
          materials: update(currentState.materials, payload.material),
          updateMaterialStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(updateVideoInMaterialAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          updateVideoStatus: ReduxStatus.pending,
          updateVideoError: null,
        };
      })
      .addCase(updateVideoInMaterialAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          updateVideoError: action.payload,
          updateVideoStatus: ReduxStatus.rejected,
        };
      })
      .addCase(updateVideoInMaterialAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updateVideoError: null,
          updateVideoStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(deleteMaterialAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          deleteMaterialStatus: ReduxStatus.pending,
        };
      })
      .addCase(deleteMaterialAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          error: getErrorMessage(action.payload.errors),
          deleteMaterialStatus: ReduxStatus.rejected,
        };
      })
      .addCase(deleteMaterialAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          deleteMaterialStatus: ReduxStatus.fulfilled,
          materials: remove(currentState.materials, payload.materialId),
        };
      })
      .addCase(saveMaterialSequenceAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          savingSequence: true,
          savedSequence: false,
          saveSequenceError: null,
        };
      })
      .addCase(saveMaterialSequenceAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          savingSequence: false,
          savedSequence: true,
          saveSequenceError: null,
        };
      })
      .addCase(saveMaterialSequenceAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          savingSequence: false,
          savedSequence: false,
          saveSequenceError: action.payload as any,
        };
      })
      .addCase(importMaterialZipFileAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          importMaterialZip: ReduxStatus.pending,
          importMaterialZipError: null,
        };
      })
      .addCase(importMaterialZipFileAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          importMaterialZip: ReduxStatus.fulfilled,
          importMaterialZipError: null,
        };
      })
      .addCase(importMaterialZipFileAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          importMaterialZip: ReduxStatus.rejected,
          importMaterialZipError: action.payload as any,
        };
      })
      .addCase(createMaterialTicketAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          createMaterialTicketError: null,
          processing: true,
          processed: false,
        };
      })
      .addCase(createMaterialTicketAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          createMaterialTicketError: action.payload.error,
          processing: false,
          processed: false,
        };
      })
      .addCase(createMaterialTicketAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          createMaterialTicketError: null,
          materialTicket: payload.materialTicket,
          processing: !payload.completed,
          processed: payload.completed,
        };
      })
      .addCase(putMaterialTicketAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          putMaterialTicketError: null,
          processing: true,
          processed: false,
        };
      })
      .addCase(putMaterialTicketAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          putMaterialTicketError:
            "無効なキーまたは、既に追加処理が実行されています。",
          processing: false,
          processed: false,
        };
      })
      .addCase(putMaterialTicketAsSuperOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          putMaterialTicketError: null,
          materialTicket: payload.materialTicket,
          processing: !payload.completed,
          processed: payload.completed,
        };
      })
      .addCase(resetFormRelatedData, (state, _action) => {
        return {
          ...state,
          fetchstatus: ReduxStatus.idle,
          processing: false,
          processed: false,
          error: null,
          createMaterialTicketError: null,
          putMaterialTicketError: null,
          materialTicket: null,
          importMaterialZip: ReduxStatus.idle,
          importMaterialZipError: null,
          fetchingAllCategories: ReduxStatus.idle,
          materialAllCategories: [],
          createMaterialStatus: ReduxStatus.idle,
          updateMaterialStatus: ReduxStatus.idle,
          deleteMaterialStatus: ReduxStatus.idle,
        };
      })
      .addCase(resetSuperOwnerSequenceState, (state, _action) => {
        return {
          ...state,
          savingSequence: false,
          savedSequence: false,
          saveSequenceError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const superOwnerAppMaterialState = (
  state: RootState,
): SuperOwnerAppMaterialState => state.superOwnerApp.material;

export default superOwnerAppMaterialSlice.reducer;
