/* eslint-disable @typescript-eslint/no-use-before-define */
import SVEditorBase from "@components/UI/molecules/SVEditor/SVEditorBase";
import { safeString } from "@lib/string-utils";
import { useCallback } from "react";
import {
  Path,
  PathValue,
  UnpackNestedValue,
  useFormContext,
} from "react-hook-form";

import {
  EditorBlocksValue,
  EditorOneBlockJSON,
  EditorOneBlockParam,
  EditorOneBlockWithSVEditorBase,
  EditorTwoBlockJSON,
  EditorTwoBlockParam,
  EditorTwoBlockWithSVEditorBase,
  SVEditorFormValue,
} from "./types";
import useDOMParse from "./useDOMParse";
import {
  checkIsBlockContentEmpty,
  editorBlocksToJSON,
  findIndexForEditorBlocks,
  generateIdForOneBlock,
  generateIdForTwoBlock,
} from "./utils";

type useSVEditorReturn<T> = {
  EditorOneBlock: (arg0: EditorOneBlockParam) => EditorOneBlockWithSVEditorBase;
  restoreSVEditorData: (
    savedData: (EditorOneBlockJSON | EditorTwoBlockJSON)[],
  ) => EditorBlocksValue;
  setSomeValues: (editorBlocks: EditorBlocksValue) => void;
  getDefaultInitialEditorBlocks: (
    description: string,
  ) => UnpackNestedValue<PathValue<T, Path<T>>>;
  getDefaultInitialEditorBlocksJSON: (description: string) => string;
  EditorBlocksKey: Path<T>;
};

export const useSVEditor = <T extends SVEditorFormValue>(
  FormValueName: Path<T>,
): useSVEditorReturn<T> => {
  const { setValue, getValues } = useFormContext<T>();
  const { addDeleteImageOnClickDeleteButton } = useDOMParse();

  // src/components/UI/molecules/SVEditor/types.tsのEditorBlocksKeyを想定している
  const EditorBlocksKey = `editorBlocksFor${FormValueName}` as Path<T>;

  // SVEditorを利用するにあたり不可欠なsetValueをまとめた関数
  // (SVEditorが更新された際にオブジェクトデータをセットする処理
  // 更新されたSVEditorの値を保存できる形式にして値をセットする処理)
  const setSomeValues = (editorBlocks: EditorBlocksValue) => {
    // SVEditorのオブジェクトデータを文字列として保存できる形式に変換
    const editorJSON = editorBlocksToJSON(editorBlocks);
    setValue(
      FormValueName,
      editorJSON as UnpackNestedValue<PathValue<T, Path<T>>>,
    );
    // SVEditorのオブジェクトデータを更新
    setValue(EditorBlocksKey, [...editorBlocks] as UnpackNestedValue<
      PathValue<T, Path<T>>
    >);
  };

  // 指定された場所に1列ブロックor2列ブロックを追加する
  const clickAction = (
    insertPositionId: string,
    block: EditorBlocksValue[number],
  ) => {
    const editorBlocksLatest = getValues(EditorBlocksKey) as EditorBlocksValue;
    const index = editorBlocksLatest.findIndex(
      (description) => description.id === insertPositionId,
    );
    editorBlocksLatest.splice(index + 1, 0, block);

    setSomeValues(editorBlocksLatest);
  };

  // 1列ブロックを追加する
  const clickOneBlockAddAction = (insertPositionId: string, value?: string) => {
    const { id } = generateIdForOneBlock();

    clickAction(
      insertPositionId,
      EditorOneBlock({
        id,
        value,
      }),
    );
  };

  // 2列ブロックを追加する
  const clickTwoBlockAddAction = (insertPositionId: string) => {
    const { id, id1, id2 } = generateIdForTwoBlock();
    clickAction(
      insertPositionId,
      EditorTwoBlock({
        id,
        content: [
          {
            id1,
          },
          {
            id2,
          },
        ],
      }),
    );
  };

  // SVEditorが複数ブロックあるときにブロックを上へ移動させる
  const clickUpAction = (upId: string) => {
    const editorBlocksLatest = getValues(EditorBlocksKey);
    if (editorBlocksLatest.length < 1) {
      return;
    }
    const { parentIndex } = findIndexForEditorBlocks(upId, editorBlocksLatest);
    if (parentIndex - 1 < 0) {
      return;
    }

    const block = editorBlocksLatest[parentIndex];
    editorBlocksLatest[parentIndex] = editorBlocksLatest[parentIndex - 1];
    editorBlocksLatest[parentIndex - 1] = block;

    setSomeValues(editorBlocksLatest);
  };

  // SVEditorが複数ブロックあるときにブロックを下へ移動させる
  const clickDownAction = (upId: string) => {
    const editorBlocksLatest = getValues(EditorBlocksKey);
    if (editorBlocksLatest.length < 1) {
      return;
    }

    const { parentIndex } = findIndexForEditorBlocks(upId, editorBlocksLatest);
    if (editorBlocksLatest.length - 1 === parentIndex) {
      return;
    }

    const block = editorBlocksLatest[parentIndex];
    editorBlocksLatest[parentIndex] = editorBlocksLatest[parentIndex + 1];
    editorBlocksLatest[parentIndex + 1] = block;

    setSomeValues(editorBlocksLatest);
  };

  // SVEditorが複数ブロックあるときにブロックを削除する
  const clickDeleteAction = (deleteId: string) => {
    const editorBlocksLatest = getValues(EditorBlocksKey);
    const { parentIndex, childIndex } = findIndexForEditorBlocks(
      deleteId,
      editorBlocksLatest,
    );
    // idがparentIdの場合
    // (1列ブロックを削除する場合)
    if (parentIndex !== -1 && childIndex === -1) {
      if (
        !checkIsBlockContentEmpty(
          safeString(editorBlocksLatest[parentIndex].value),
        )
      ) {
        const { id: newId } = generateIdForOneBlock();
        // 削除ボタンが押下された際には画像を削除リストに追加する
        addDeleteImageOnClickDeleteButton(
          editorBlocksLatest[parentIndex].value,
        );

        editorBlocksLatest[parentIndex] = EditorOneBlock({
          id: `${newId}`,
        });
      } else {
        editorBlocksLatest.splice(parentIndex, 1);
      }
    }

    // idがchildIdの場合
    // (2列ブロックの左右どちらかを削除する場合)
    if (parentIndex !== -1 && childIndex !== -1) {
      const childContent = (
        editorBlocksLatest[parentIndex] as EditorTwoBlockWithSVEditorBase
      ).content;

      if (!checkIsBlockContentEmpty(childContent?.[childIndex].value ?? "")) {
        // 削除ボタンが押下された際には画像を削除リストに追加する
        addDeleteImageOnClickDeleteButton(childContent[childIndex].value);

        childContent[childIndex] = EditorOneBlock({
          id: `${childContent[childIndex].id}+deleted`,
          style: { width: "50%" },
        });
      } else {
        childContent.splice(childIndex, 1);

        editorBlocksLatest[parentIndex] = EditorOneBlock({
          id: editorBlocksLatest[parentIndex].id,
          value: childContent[0].value,
        });
      }
    }

    setSomeValues(editorBlocksLatest);
  };

  // 1列ブロック用のオブジェクトを作成する
  const EditorOneBlock = useCallback(
    ({
      id,
      insertPositionId,
      value,
      style,
    }: EditorOneBlockParam): EditorOneBlockWithSVEditorBase => {
      const SVEditorArg = {
        id,
        editable: true,
        value: safeString(value),
        clickOneBlockAddAction: (oneBlockValue?: string) =>
          clickOneBlockAddAction(insertPositionId ?? id, oneBlockValue),
        clickTwoBlockAddAction: () =>
          clickTwoBlockAddAction(insertPositionId ?? id),
        clickDeleteAction: () => clickDeleteAction(id),
        clickUpAction: () => clickUpAction(id),
        clickDownAction: () => clickDownAction(id),
        ...(typeof style !== "undefined" && { style }),
      };
      const content = (
        <SVEditorBase<T> {...SVEditorArg} FormValueName={FormValueName} />
      );
      return {
        id: SVEditorArg.id,
        content,
        value: SVEditorArg.value,
      };
    },
    [FormValueName],
  );

  // ２列ブロック用のオブジェクトを作成する
  const EditorTwoBlock = useCallback(
    ({
      id,
      content: [{ id1, value1 }, { id2, value2 }],
    }: EditorTwoBlockParam): EditorTwoBlockWithSVEditorBase => {
      return {
        id,
        content: [
          EditorOneBlock({
            id: id1,
            insertPositionId: id,
            value: value1,
            style: { width: "50%" },
          }),
          EditorOneBlock({
            id: id2,
            insertPositionId: id,
            value: value2,
            style: { width: "50%" },
          }),
        ],
      };
    },
    [EditorOneBlock],
  );

  // レコードに保存されているJSONデータをparseした後にこの関数を利用して
  // SVEditorをリストアする
  const restoreSVEditorData = (
    savedData: (EditorOneBlockJSON | EditorTwoBlockJSON)[],
  ): (EditorOneBlockWithSVEditorBase | EditorTwoBlockWithSVEditorBase)[] => {
    return savedData.map((data) => {
      if ("content" in data) {
        return EditorTwoBlock({
          id: data.id,
          content: [
            {
              id1: safeString(data.content?.[0].id),
              value1: safeString(data.content?.[0].value),
            },
            {
              id2: safeString(data.content?.[1].id),
              value2: safeString(data.content?.[1].value),
            },
          ],
        });
      }
      return EditorOneBlock({ id: data.id, value: data.value });
    });
  };

  // SVEditorを一度も利用していない場合のオブジェクトを用意する
  // (descriptionの値が以前のリッチテキストエディタの場合や空白の場合にこちらを利用する
  // 以前のリッチテキストエディタの場合にはdescriptionにはHTMLが入っている)
  const getDefaultInitialEditorBlocks = (
    description: string,
  ): UnpackNestedValue<PathValue<T, Path<T>>> => {
    return [
      EditorOneBlock({
        id: generateIdForOneBlock().id,
        value: description,
      }),
    ] as UnpackNestedValue<PathValue<T, Path<T>>>;
  };

  // SVEditorのオブジェクトデータを含まない形での初期値を用意する
  const getDefaultInitialEditorBlocksJSON = (description: string) => {
    return editorBlocksToJSON(getDefaultInitialEditorBlocks(description));
  };

  return {
    EditorOneBlock,
    restoreSVEditorData,
    setSomeValues,
    getDefaultInitialEditorBlocks,
    getDefaultInitialEditorBlocksJSON,
    EditorBlocksKey,
  };
};

export default useSVEditor;
