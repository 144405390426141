/* eslint-disable @typescript-eslint/ban-types */
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import { Box,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Control, UseFormHandleSubmit } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValues } from "../hooks/useHandleStudentsLogs";
import Input from "./Input";

export type SearchBarProps = {
  control: Control<FormValues, object>;
  onSubmit: (values: FormValues) => void;
  handleSubmit: UseFormHandleSubmit<FormValues>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(2),
      margin: theme.spacing(3, 0),
    },
    dropDownContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
    },
  }),
);

const SearchBar: React.FC<SearchBarProps> = ({
  control,
  onSubmit,
  handleSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Box className={classes.mainContainer}>
      <Input control={control} />
      <Box>
        <SingleButton onClick={handleSubmit(onSubmit)} variant="contained">
          {t("search")}
        </SingleButton>
      </Box>
    </Box>
  );
};

export default SearchBar;
