import { DeleteAttachedImageAsCommonParams } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

export type initialState = {
  deleteImagesOnSaving: DeleteAttachedImageAsCommonParams[];
  deleteImagesOnCanceling: DeleteAttachedImageAsCommonParams[];
};
export const initialState: initialState = {
  deleteImagesOnSaving: [],
  deleteImagesOnCanceling: [],
};

const SVImageSlice = createSlice({
  name: "SVEditor",
  initialState,
  reducers: {
    addDeleteImagesOnCanceling: (state, action) => {
      return {
        ...state,
        deleteImagesOnCanceling: [
          ...state.deleteImagesOnCanceling,
          action.payload,
        ],
      };
    },
    addDeleteImagesOnSaving: (state, action) => {
      return {
        ...state,
        deleteImagesOnSaving: [...state.deleteImagesOnSaving, action.payload],
      };
    },
    resetDeleteImages: (state) => {
      return {
        ...state,
        deleteImagesOnSaving: [],
        deleteImagesOnCanceling: [],
      };
    },
  },
});

export const {
  addDeleteImagesOnCanceling,
  addDeleteImagesOnSaving,
  resetDeleteImages,
} = SVImageSlice.actions;
export const SVImageReducer = SVImageSlice.reducer;
