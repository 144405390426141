/* eslint-disable react/no-array-index-key */
import { usePagination, UsePaginationResult } from "@mui/lab";
import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import ArrowLeftIcon from "@root/assets/images/svg/arrowleft_outlined.svg";
import ArrowRightIcon from "@root/assets/images/svg/arrowright_outlined.svg";
import React from "react";
import { useTranslation } from "react-i18next";

export type PaginationProps = {
  totalCount: number;
  currentPage: number;
  handlePageTransition: (newPage: number) => void;
  perPage?: number;
};

const useV2Pagination = (
  count: number,
  page: number,
  onChange: (page: number) => void,
): UsePaginationResult => {
  const paginationResult = usePagination({
    count,
    page,
    onChange: (e, _page) => {
      e.stopPropagation();
      onChange(_page);
    },
  });

  return paginationResult;
};

const useStyles = makeStyles((theme) => ({
  button: {
    "min-width": "35px",
    width: "35px",
    height: "35px",
  },
  selectedBox: {
    border: "1px solid #008267",
    borderRadius: 8,
    backgroundColor: "#008267",
  },
  box: {
    border: "1px solid #008267",
    borderRadius: 8,
  },
  arrowButton: {
    "&.Mui-disabled img": {
      opacity: 0.25,
    },
  },
  mobileStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
  },
}));

export const V2Pagination: React.FC<PaginationProps> = ({
  totalCount,
  currentPage,
  handlePageTransition,
  perPage = 10,
}) => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const isMobileView = useMediaQuery(useTheme().breakpoints.down("sm"));

  const paginationResult = useV2Pagination(
    Math.ceil(totalCount / perPage),
    currentPage,
    handlePageTransition,
  );

  return (
    <>
      {totalCount > perPage && (
        <>
          <Box
            data-cy="pagination"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ width: "100%" }}
            my={2}
          >
            <Box display="inline-block">
              <Box display="flex" alignItems="center">
                {paginationResult.items.map(
                  ({ page, type, selected, ...item }, index) => {
                    let children = null;
                    if (type === "start-ellipsis" || type === "end-ellipsis") {
                      children = (
                        <Box bgcolor="transparent">
                          <Button className={classes.button} color="primary">
                            …
                          </Button>
                        </Box>
                      );
                    } else if (type === "page") {
                      children = (
                        <Box
                          className={
                            selected ? classes.selectedBox : classes.box
                          }
                        >
                          <Button
                            type="button"
                            className={classes.button}
                            style={{
                              color: selected ? "white" : "#008267",
                            }}
                            {...item}
                            disabled={selected}
                            data-cy="page"
                          >
                            <Box fontWeight="bold">{page}</Box>
                          </Button>
                        </Box>
                      );
                    } else if (type === "previous" && !isMobileView) {
                      children = (
                        <Button
                          className={classes.arrowButton}
                          type="button"
                          {...item}
                          color="primary"
                          style={{
                            marginRight: "24px",
                            paddingRight: "0px",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <img src={ArrowLeftIcon} alt="ArrowLeftIcon" />
                            {t("previous")}
                          </Box>
                        </Button>
                      );
                    } else if (type === "next" && !isMobileView) {
                      children = (
                        <Button
                          className={classes.arrowButton}
                          data-cy="next-page"
                          type="button"
                          {...item}
                          color="primary"
                          style={{
                            marginLeft: "24px",
                            paddingLeft: "0px",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            {t("next")}
                            <img src={ArrowRightIcon} alt="ArrowRightIcon" />
                          </Box>
                        </Button>
                      );
                    }
                    return (
                      <Box key={index} p={0.2}>
                        {children}
                      </Box>
                    );
                  },
                )}
              </Box>
            </Box>
          </Box>
          {isMobileView && (
            <Box
              data-cy="pagination"
              className={classes.mobileStyle}
              style={{ width: "100%" }}
              my={2}
            >
              <Box display="flex">
                {paginationResult.items
                  .filter(
                    (item) => item.type === "next" || item.type === "previous",
                  )
                  .map(({ page, type, selected, ...item }, index) => {
                    if (type === "previous") {
                      return (
                        <Button
                          key={index}
                          className={classes.arrowButton}
                          type="button"
                          {...item}
                          color="primary"
                        >
                          <Box display="flex" alignItems="center">
                            <img src={ArrowLeftIcon} alt="ArrowLeftIcon" />
                            {t("previous")}
                          </Box>
                        </Button>
                      );
                    }
                    return (
                      <Button
                        key={index}
                        className={classes.arrowButton}
                        data-cy="next-page"
                        type="button"
                        {...item}
                        color="primary"
                      >
                        <Box display="flex" alignItems="center">
                          {t("next")}
                          <img src={ArrowRightIcon} alt="ArrowRightIcon" />
                        </Box>
                      </Button>
                    );
                  })}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default V2Pagination;
