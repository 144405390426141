export const V2_COLORS = {
  green: "#008267",
  lightGreen: "#E6F3F0",
  backgroundGreen: "#FBFFFA",
  black: "#475149",
  white: "#FFFFFF",
  gray: "#B3B9B3",
  lightGray: "#EDEEED",
  darkGray: "#89908B",
  lightRed: "#FF8080",
  orange: "#FD9667",
  purple: "#6B79FF",
} as const;

// reference
// https://m3.material.io/styles/color/the-color-system/color-roles
// https://www.figma.com/file/EWnEq0FtK5p4J8fWySrjJU/Material-3-Design-Kit-(Community)?node-id=49823%3A12142&t=O9Aoe4KUcpRbbaT6-0
export const V2_THEME_COLORS = {
  primary: V2_COLORS.green,
  onPrimary: V2_COLORS.white,
  primaryContainer: V2_COLORS.lightGreen,
  onPrimaryContainer: V2_COLORS.black,
  background: V2_COLORS.white,
  onBackground: V2_COLORS.black,
  outline: V2_COLORS.gray,
} as const;
