/* eslint-disable camelcase */
import {
  Api,
  BasicUserInfo,
  FetchUserTagsAsOwnerParams,
  UserTagTypeKind,
  UserTagTypesListParams,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { OwnerTeacherApiFunctions } from "@root/types/tags";
import { RootState } from "@store/store";

import { FetchStudentTaggingByUserTag, UserTaggingParams } from "../types/tag";

const api = new Api();

export const fetchUserTagTypesAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/userTagTypes/fetch",
  async (data: UserTagTypesListParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.userTagTypesList(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchUserTagsAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/userTag/fetch",
  async (data: FetchUserTagsAsOwnerParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.fetchUserTagsAsOwner({
        ...data,
        kind: UserTagTypeKind.Nationwide,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchLimitedUserTagsAsOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/limitedUserTag/fetch",
  async (data: FetchUserTagsAsOwnerParams, { rejectWithValue }) => {
    try {
      const response = await api.owner.fetchUserTagsAsOwner({
        ...data,
        kind: UserTagTypeKind.Limited,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchUserTagsAsOwnerForTagging = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/allUserTag/fetch",
  async (data: UserTagTypesListParams, { rejectWithValue }) => {
    try {
      const { page, per_page } = data;
      const response = await api.owner.userTagTypesList({ page, per_page });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchStudentTaggingByUserTag = createAsyncThunk<
  { users: BasicUserInfo[]; total_count: number },
  FetchStudentTaggingByUserTag,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/fetchStudentsByUserTag", async (data, { rejectWithValue }) => {
  try {
    const response = await api.owner.userTagsUsersDetail(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createUserTagging = createAsyncThunk<
  {
    response: {
      number_of_tagging_users?: number;
      remaining_number_of_tagging_users?: number | null;
    };
    tagName?: string;
  },
  UserTaggingParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/userTagging/create", async (data, { rejectWithValue }) => {
  try {
    const response = await api.owner.userTaggingsCreate({
      user_id: data.user_id,
      user_tag_id: data.user_tag_id,
    });
    return {
      response: response.data,
      tagName: data.tag_name,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export type UpdateTaggingBatchParams = {
  user_ids: string[];
  user_tag_id: string;
  company_id?: string;
};

export const userTaggingsBatchCreatePartialUpdate = createAsyncThunk<
  any,
  UpdateTaggingBatchParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/updateTaggingsAsOwner", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.ownerUserTaggingBatchCreate(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteUserTagging = createAsyncThunk<
  { response: { messaege?: string }; tagName?: string },
  UserTaggingParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/userTagging/delete", async (data, { rejectWithValue }) => {
  try {
    const response: any = await api.owner.userTaggingsDelete({
      user_id: data.user_id,
      user_tag_id: data.user_tag_id,
    });
    return {
      response: response.data,
      tagName: data.tag_name,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// ___USER_TAG -- Create/Edit/Delete/Success Actions___

export interface CreateUserTagParamsType {
  user_tag_type_id: string;
  name: string;
  limit_number?: number;
}

export const createUserTag = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/user_tag/create",
  async (params: CreateUserTagParamsType, { rejectWithValue }) => {
    try {
      const response = await api.owner.createUserTagAsOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface EditUserTagParamsType {
  id: string;
  commonParams: {
    name?: string;
    limit_number?: number;
  };
}

export const editUserTag = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/user_tag/edit",
  async (params: EditUserTagParamsType, { rejectWithValue }) => {
    const { id, commonParams } = params;
    try {
      const response = await api.owner.updateUserTagAsOwner(id, commonParams);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUserTag = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/user_tag/delete", async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.owner.deleteUserTagAsOwner(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const userTagSuccess = createAction("owner/userTag/success");
export const userTagRejected = createAction("owner/userTag/rejected");

// ___User_tag_type___

interface CreateUserTagTypeTypes {
  name?: string;
  kind?: UserTagTypeKind;
  teacher_visible?: boolean;
}

export const createUserTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/user_tag_type/create",
  async (params: CreateUserTagTypeTypes, { rejectWithValue }) => {
    try {
      const response = await api.owner.userTagTypesCreate(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

interface EditUserTagTypeTypes {
  id: string;
  data: {
    name?: string;
    teacher_visible?: boolean;
  };
}

export const editUserTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "owner/user_tag_type/edit",
  async (params: EditUserTagTypeTypes, { rejectWithValue }) => {
    const { id, data } = params;
    try {
      const response = await api.owner.userTagTypesUpdate(id, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUserTagType = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/user_tag_type/delete", async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.owner.userTagTypesDelete(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetBatchCountState = createAction(
  "owner/userTag/resetBatchCountState",
);
export const resetState = createAction("owner/userTag/resetState");

export const isRequestsRunning = createAction(
  "owner/userTag/isRequestsRunning",
);

type OwnerActionsType = {
  owner: OwnerTeacherApiFunctions;
};
export const ownerActions: OwnerActionsType = {
  owner: {
    post: createUserTag,
    edit: editUserTag,
    delete: deleteUserTag,
    createTagType: createUserTagType,
    editTagType: editUserTagType,
    deleteTagType: deleteUserTagType,
    success: userTagSuccess,
    rejected: userTagRejected,
    isRequestsRunning,
  },
};

// __Owner_Tags_Sequence_Update__

type SequenceType = {
  id: string;
  sequence_number?: number;
};
export type UpdateSequenceParams = {
  sequences: SequenceType[];
};

export const updateOwnerUserTagsSequence = createAsyncThunk<
  GetResponseType<Api["owner"]["userTagsSequenceUpdate"]>,
  UpdateSequenceParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/user_tag_sequence/update", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.userTagsSequenceUpdate(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
