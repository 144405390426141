/* eslint-disable camelcase */
import { ImageData } from "@components/Admin/Sponsors/modals/types";
import { Api } from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

interface CreateSponsorInfoCategoryAsAdminParams {
  sponsor_id: string;
  name: string;
  description: string;
  file: ImageData | null;
}

export const createPblSponsorInfoCategory = async (
  params: CreateSponsorInfoCategoryAsAdminParams,
): Promise<{ success: boolean; data: any }> => {
  try {
    const { sponsor_id, name, description, file } = params;
    const fd = new FormData();
    fd.set("sponsor_id", sponsor_id);
    fd.set("name", name);
    if (typeof description !== "undefined") {
      fd.set("description", description);
    }
    if (file && file.file) {
      fd.set("file", file.file);
    }
    const response = await http.post(`/admin/sponsor/info_categories`, fd);
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, data: err.response.data };
  }
};

export const createPblSponsorInfoCategoryAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/infos/categories/create",
  async (
    params: CreateSponsorInfoCategoryAsAdminParams,
    { rejectWithValue },
  ) => {
    const result = await createPblSponsorInfoCategory(params);
    if (result.success) {
      return result.data;
    }
    return rejectWithValue(result.data);
  },
);

interface UpdateSponsorInfoCategoryAsAdminParams {
  name: string;
  description: string;
  file: ImageData | null;
}

type UpdateSponsorInfoCategoryAsAdminArg = {
  id: string;
  params: UpdateSponsorInfoCategoryAsAdminParams;
};

export const updatePblSponsorInfoCategoryAsAdmin = createAsyncThunk<
  any,
  UpdateSponsorInfoCategoryAsAdminArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/infos/categories/update",
  async (
    { id, params }: UpdateSponsorInfoCategoryAsAdminArg,
    { rejectWithValue },
  ) => {
    try {
      const { name, description, file } = params;
      const fd = new FormData();
      fd.set("name", name);
      if (typeof description !== "undefined") {
        fd.set("description", description);
      }
      if (file && file.file) {
        fd.set("file", file.file);
      }
      const response = await http.put(
        `/admin/sponsor/info_categories/${id}`,
        fd,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblSponsorInfoCategoryAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/infos/categories/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.admin.deleteSponsorInfoCategoryAsAdmin(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetAction = createAction(
  "admin/pbl/sponsor/infos/categories/resetAction",
);
