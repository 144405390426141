/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { roles } from "@constants/roles";
import { useAuth } from "@contexts/Auth";
import { adminApi } from "@root/endpoints/TimeTactApi/adminApi";
import { contentProviderApi } from "@root/endpoints/TimeTactApi/contentProviderApi";
import { ownerApi } from "@root/endpoints/TimeTactApi/ownerApi";
import { remainingApi } from "@root/endpoints/TimeTactApi/remainingApi";
import { reviewerApi } from "@root/endpoints/TimeTactApi/reviewerApi";
import { studentApi } from "@root/endpoints/TimeTactApi/studentApi";
import { superOwnerApi } from "@root/endpoints/TimeTactApi/superOwnerApi";
import { teacherApi } from "@root/endpoints/TimeTactApi/teacherApi";

const usePostAttachedImage = () => {
  const { currentUser } = useAuth();

  const [postAttachedImageAsPublic, { isLoading: isUploadingPublic }] =
    remainingApi.endpoints.postAttachedImageAsPublic.useMutation();
  const [postAttachedImageAsSuperOwner, { isLoading: isUploadingSuperOwner }] =
    superOwnerApi.endpoints.postAttachedImageAsSuperOwner.useMutation();
  const [postAttachedImageAsOwner, { isLoading: isUploadingOwner }] =
    ownerApi.endpoints.postAttachedImageAsOwner.useMutation();
  const [postAttachedImageAsTeacher, { isLoading: isUploadingTeacher }] =
    teacherApi.endpoints.postAttachedImageAsTeacher.useMutation();
  const [postAttachedImageAsStudent, { isLoading: isUploadingStudent }] =
    studentApi.endpoints.postAttachedImageAsStudent.useMutation();
  const [postAttachedImageAsAdmin, { isLoading: isUploadingAdmin }] =
    adminApi.endpoints.postAttachedImageAsAdmin.useMutation();
  const [postAttachedImageAsReviewer, { isLoading: isUploadingReviewer }] =
    reviewerApi.endpoints.postAttachedImageAsReviewer.useMutation();
  const [
    postAttachedImageAsContentProvider,
    { isLoading: isUploadingContentProvider },
  ] =
    contentProviderApi.endpoints.postAttachedImageAsContentProvider.useMutation();

  let postAttachedImage;
  let isUploading;

  [postAttachedImage, isUploading] = (() => {
    if (currentUser?.current_role === roles.SUPER_OWNER) {
      postAttachedImage = postAttachedImageAsSuperOwner;
      isUploading = isUploadingSuperOwner;
    } else if (currentUser?.current_role === roles.OWNER) {
      postAttachedImage = postAttachedImageAsOwner;
      isUploading = isUploadingOwner;
    } else if (currentUser?.current_role === roles.TEACHER) {
      postAttachedImage = postAttachedImageAsTeacher;
      isUploading = isUploadingTeacher;
    } else if (currentUser?.current_role === roles.STUDENT) {
      postAttachedImage = postAttachedImageAsStudent;
      isUploading = isUploadingStudent;
    } else if (currentUser?.current_role === roles.REVIEWER) {
      postAttachedImage = postAttachedImageAsReviewer;
      isUploading = isUploadingReviewer;
    } else if (currentUser?.current_role === roles.ADMIN) {
      postAttachedImage = postAttachedImageAsAdmin;
      isUploading = isUploadingAdmin;
    } else if (currentUser?.current_role === roles.CONTENT_PROVIDER) {
      postAttachedImage = postAttachedImageAsContentProvider;
      isUploading = isUploadingContentProvider;
    } else {
      postAttachedImage = postAttachedImageAsPublic;
      isUploading = isUploadingPublic;
    }
    return [postAttachedImage, isUploading];
  })();

  return {
    postAttachedImage,
    isUploading,
  };
};

export default usePostAttachedImage;
