/* eslint-disable camelcase */
import CastleFlag from "@assets/images/svg/CastleFlag.svg?react";
import Document from "@assets/images/svg/documentIcon.svg?react";
import Pen from "@assets/images/svg/edit_pen.svg?react";
import HomeWork from "@assets/images/svg/homeworkIcon.svg?react";
import {
  ActivityBase,
  DailyLogCategory,
  StudyLogBase,
  StudyLogCategorizableType,
} from "@lib/Api";
import BallotIcon from "@mui/icons-material/Ballot";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { DailyLogStatsBase as DailyLogsType } from "@root/endpoints/TimeTactApi/teacherApi";
import theme from "@root/styles/theme";
import React from "react";
import { TFunction } from "react-i18next";

export interface LogsFilteredByStudent {
  studentFullName: string;
  logList: StudyLogBase[];
}

export const getStudyTime = (dailyLogs: DailyLogsType): number => {
  if (!Object.keys(dailyLogs).length) return 0;
  const logs = [
    { study_time: dailyLogs.analysis.study_time },
    { study_time: dailyLogs.research.study_time },
    { study_time: dailyLogs.summary.study_time },
    { study_time: dailyLogs.theme_settings.study_time },
  ];
  let studyTime = 0;
  logs.forEach((log: { study_time: number }) => {
    if (log.study_time) {
      studyTime += log.study_time;
    }
    return null;
  });

  return Math.floor(studyTime / 60);
};

const LogsCategoriesToJpValue = (
  category: string | undefined,
  t: TFunction,
): string => {
  switch (category) {
    case DailyLogCategory.ThemeSettings:
      return t("theme_settings");
    case DailyLogCategory.Research:
      return t("research");
    case DailyLogCategory.Analysis:
      return t("analysis");
    default:
      return t("summary");
  }
};

interface FormatLogsCategoriesToJp {
  title: string;
  value: number | null;
  tooltipValue?: number;
}

export const formatLogsCategoriesToJp = (
  logs: ActivityBase[],
  t: TFunction,
): FormatLogsCategoriesToJp[] => {
  const totalCount = logs.reduce((acc, log) => {
    return acc + (log.count ?? 0);
  }, 0);
  const jpLogsCategories: FormatLogsCategoriesToJp[] = logs.map(
    (item: ActivityBase) => {
      return {
        title: LogsCategoriesToJpValue(item.category, t),
        value: ((item.count ?? 0) / totalCount) * 100,
        tooltipValue: item.study_time,
      };
    },
  );
  return jpLogsCategories;
};

export const formatEachStudentLogs = (
  dailyLogs: DailyLogsType,
  t: TFunction,
): ActivityBase[] => {
  if (!Object.keys(dailyLogs).length) return [];
  return [
    {
      category: t("theme_settings"),
      count: dailyLogs.theme_settings.count,
      study_time: dailyLogs.theme_settings.study_time,
    },
    {
      category: t("research"),
      count: dailyLogs.research.count,
      study_time: dailyLogs.research.study_time,
    },
    {
      category: t("analysis"),
      count: dailyLogs.analysis.count,
      study_time: dailyLogs.analysis.study_time,
    },
    {
      category: t("summary"),
      count: dailyLogs.summary.count,
      study_time: dailyLogs.summary.study_time,
    },
  ];
};

export const handleModalLogTableInfos = (
  type: StudyLogCategorizableType,
): {
  name: string;
  icon: JSX.Element;
} => {
  const iconStyle = {
    fill: theme.palette.tertiary.mainDark,
    width: "25px",
    height: "25px",
    marginRight: theme.spacing(1),
    alignSelf: "center",
  };

  switch (type) {
    case StudyLogCategorizableType.Homework:
      return {
        icon: <HomeWork style={{ ...iconStyle }} />,
        name: "提出物",
      };
    case StudyLogCategorizableType.MaterialWork:
      return {
        icon: <Pen style={{ ...iconStyle }} />,
        name: "受講中の教材",
      };
    case StudyLogCategorizableType.Article:
      return {
        icon: <CastleFlag style={{ ...iconStyle }} />,
        name: "探究成果",
      };
    case StudyLogCategorizableType.PblReportAnswer:
      return {
        icon: <Document style={{ ...iconStyle }} />,
        name: "探究レポート",
      };
    case StudyLogCategorizableType.PblCardsInformationLiteracyBookmark:
      return {
        icon: <BookmarkIcon style={{ ...iconStyle }} />,
        name: "ノート＆トーク",
      };
    case StudyLogCategorizableType.PblSteamComment:
    case StudyLogCategorizableType.PblSteamContentWork:
      return {
        icon: <DashboardIcon style={{ ...iconStyle }} />,
        name: "Steam",
      };

    default:
      return {
        icon: <BallotIcon style={{ ...iconStyle }} />,
        name: "なし",
      };
  }
};
