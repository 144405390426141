/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line import/prefer-default-export

import {
  ModelBase,
  StudentHomeworkImage,
  TeacherHomeworkImage,
} from "@lib/Api";
import { PDFDocumentType, PDFFontType, PDFPageType } from "@root/types/file";
import Axios from "axios";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import qrcode from "qrcode";

import { safeString } from "./string-utils";

export const ALLOWED_IMAGE_TYPES = /(\.pdf|\.jpeg|\.jpg|\.png)(\?|$)/i;

export enum FileTypeJa {
  AnswerFile = "提出ファイル",
  ReviewerFile = "添削ファイル",
  DeliveryFile = "配布用ファイル",
}

export function readFileAsync(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const binaryStr = reader.result;
      const content = new Blob([binaryStr as string], {
        type: file.type,
      });
      resolve(content);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

export function readFileAndGetBase64Async(
  file: File,
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Str = reader.result;
      resolve(base64Str);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export const fetchRemoteFile = async (fileUrl: string): Promise<Blob> => {
  const { data }: { data: Promise<Blob> } = await Axios.get(fileUrl, {
    responseType: "blob",
  });
  return data;
};

export const getNum = (str: string) => {
  const re = /[0-9]+.(png|jpg|jpeg|pdf)$/i;
  const match = str.match(re);
  if (match) {
    const num = parseInt(match[0].split(".")[0], 10);
    return num;
  }
  return 0;
};

export const simpleSort = (fields: any[]): any[] => {
  const len = fields.length;
  const copy = [...fields] as any[];
  for (let i = 0; i < len; i++) {
    let min = i;
    let minNum = getNum(copy[i].title);
    for (let j = i + 1; j < len; j++) {
      const jNum = getNum(copy[j].title);
      if (minNum - jNum > 0) {
        min = j;
        minNum = jNum;
      }
    }
    const toSwap = copy[i];
    copy[i] = copy[min];
    copy[min] = toSwap;
  }
  return copy;
};

export type StudentMaterialWorkImage = ModelBase & {
  title?: string | null;
  url?: string | null;
  // eslint-disable-next-line camelcase
  image_content_id?: string | null;
};

export const getSelectedFile = (
  images: Array<
    StudentMaterialWorkImage | StudentHomeworkImage | TeacherHomeworkImage
  >,
  imageId: string | null,
):
  | StudentMaterialWorkImage
  | StudentHomeworkImage
  | TeacherHomeworkImage
  | null => {
  if (!images || imageId === null) {
    return null;
  }
  const image = images.find((tmpImage) => tmpImage.id === imageId);
  return image ?? null;
};

export const getFileUrl = (
  images:
    | Array<StudentMaterialWorkImage>
    | Array<StudentHomeworkImage>
    | Array<TeacherHomeworkImage>,
  imageId: string | null,
): string | null => {
  if (imageId === null) {
    return null;
  }
  const image = getSelectedFile(images, imageId);
  if (!image) {
    return null;
  }

  let url;
  if ("file_url" in image) {
    url = image.file_url;
  } else {
    url = image.url;
  }
  return url ?? null;
};

export const getFileName = (
  images:
    | Array<StudentMaterialWorkImage>
    | Array<StudentHomeworkImage>
    | Array<TeacherHomeworkImage>,
  imageId: string | null,
): string | null => {
  const image = getSelectedFile(images, imageId);
  if (!image) {
    return null;
  }
  const { title } = image;
  return safeString(title);
};

export const getFileByUrl = async (
  url: string | null | undefined,
  title: string | null | undefined,
) => {
  if (!url || !title) {
    return Promise.reject(new Error("読み込みに失敗しました。"));
  }
  const blob = await fetchRemoteFile(url);
  return new File([blob], title, { type: blob.type });
};

export const handleFileDownload = async (
  images:
    | Array<StudentHomeworkImage>
    | Array<StudentMaterialWorkImage>
    | undefined,
  imageId: string | null,
): Promise<void> => {
  if (!images || imageId === null) {
    return;
  }
  const file = getSelectedFile(images, imageId);
  const fileUrl = getFileUrl(images, imageId);
  const { title } = file as any;
  const fileData = await getFileByUrl(fileUrl, title);
  fileDownload(fileData, title);
};

export const handleFileDownloadToArticle = async (
  images:
    | Array<StudentHomeworkImage>
    | Array<StudentMaterialWorkImage>
    | undefined,
  imageId: string | null,
) => {
  if (!images || imageId === null) {
    return null;
  }
  const file = getSelectedFile(images, imageId);
  const fileUrl = getFileUrl(images, imageId);
  const { title } = file as any;
  return getFileByUrl(fileUrl, title);
};

export const handleAttachmentDownload = async (
  attachmentUrl: string,
  attachmentTitle: string,
) => {
  const { data } = await Axios.get(attachmentUrl, {
    responseType: "blob",
  });
  fileDownload(data, attachmentTitle);
};

export const dataURItoBlob = (dataURI: string) => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);
  const blob = new Blob([dataView], { type: mimeString });
  return blob;
};

export const blobToBase64 = async (file: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

export const isPdf = (fileUrl: string | null | undefined): boolean => {
  if (!fileUrl) {
    return false;
  }
  const re = /pdf\?Expires|pdf$/i;
  return re.test(fileUrl);
};

export function isImage(url: string | null | undefined): boolean {
  if (!url) {
    return false;
  }
  const regexFirstType =
    /\.(jpg|jpeg|png|webp|avif|gif|svg|tiff|bmp)(?:[^\w\s]|$)/i;
  const regexSecondType =
    /\/([^/]+)\.(jpg|jpeg|png|webp|avif|gif|svg|tiff|bmp)(?:\?|$)/i;
  const match = regexSecondType.exec(url);
  return !!match || regexFirstType.test(url);
}

export const getImageMeta = (fileUrl: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error("読み込みに失敗しました。"));
    img.src = fileUrl;
  });
};

export const genQrTextForHomework = async (
  homeworkId: string,
  pageNumber: number,
) => {
  const text = await JSON.stringify({
    homework_id: homeworkId,
    page: pageNumber.toString(),
  });
  return text;
};

// qrData should be JSON string.
export const generateQR = async (qrData: string) => {
  const data = await qrcode.toDataURL(qrData);
  return data;
};

export const getJpFontData = async () => {
  const myFont = await fetch(`/fonts/ipaexg.ttf`);
  const fontBytes = await myFont.arrayBuffer();
  return fontBytes;
};

export const addJpFontToPdf = async (
  pdf: PDFDocumentType,
  fontData: ArrayBuffer | null = null,
) => {
  let fontBytes: ArrayBuffer;
  if (!fontData) {
    fontBytes = await getJpFontData();
  } else {
    fontBytes = fontData;
  }
  const fontkit = await import("@pdf-lib/fontkit").then(
    (value) => value.default,
  );
  pdf.registerFontkit(fontkit);
  const customFont = await pdf.embedFont(fontBytes, { subset: true });
  return { pdf, customFont };
};

export const optionalQrPosition = (
  position: "upperR" | "underR" | "underL",
  width: number,
  height: number,
  isExam = false,
) => {
  const qrSize = 90; // qrWidth * qrHeight = 90 * 90
  const marginX = isExam ? 35 : 10; // Avoid aruco marker when isExam is true
  const marginY = 10;
  // 左下が基準 (x, y) = (0, 0)
  switch (position) {
    case "upperR":
      return {
        x: width - qrSize - marginX,
        y: height - qrSize - marginY,
        width: qrSize,
        height: qrSize,
      };
    case "underR":
      return {
        x: width - qrSize - marginX,
        y: marginY,
        width: qrSize,
        height: qrSize,
      };
    case "underL":
      return {
        x: marginX,
        y: marginY,
        width: qrSize,
        height: qrSize,
      };
    default:
      return {
        x: width - qrSize - marginX,
        y: height - qrSize - marginY,
        width: qrSize,
        height: qrSize,
      };
  }
};

// qrData should be JSON string.
export const addQrToPdfPage = async (
  pdfToWrite: PDFDocumentType,
  page: PDFPageType,
  qrData: string,
  qrPosition: "upperR" | "underR" | "underL",
  isExam = false,
) => {
  const newQr = await generateQR(qrData);
  const qrPng = await pdfToWrite.embedPng(newQr);
  const { width, height } = page.getSize();
  page.drawImage(qrPng, optionalQrPosition(qrPosition, width, height, isExam));
  return { pdfToWrite, page };
};

export enum PlaceOption {
  TopLeft = "top-left",
  BottomRight = "bottom-right",
}

export const addTextToPdfPage = async (
  page: PDFPageType,
  text: string,
  customFont: PDFFontType, // Use addJpFontToPdf to receive customFont.
  place: PlaceOption = PlaceOption.TopLeft,
  isExam = false,
) => {
  const { height, width } = page.getSize();
  let xPos = isExam ? 40 : 20;
  let yPos = height - 20;
  if (place === PlaceOption.BottomRight) {
    xPos = width - 40 - text.length * 8;
    yPos = 20;
  }
  const { rgb } = await import("pdf-lib");
  page.drawText(text, {
    x: xPos,
    y: yPos,
    size: 12,
    font: customFont,
    color: rgb(0.33, 0.33, 0.33),
  });
  return page;
};

export const addExtensionIfNeeded = (
  rawTitle: string,
  type: string,
): string => {
  let title = rawTitle;
  const regExp = `{/^.*.(${type})$/i}`;
  const hasNoExtension = !title.match(regExp);
  if (hasNoExtension) {
    title = `${title}.${type}`;
  }
  return title;
};
export const zipPdf = async (pdfFile: any) => {
  const JSZip = await import("jszip").then((value) => value.default);
  const zip = new JSZip();
  const pdfCurrent = pdfFile.current;
  const savedData = await pdfCurrent.save();
  pdfFile.current = pdfCurrent;
  const formattedDate = dayjs().format(`YYYYMMDD`);
  const pdfZip = zip.file(`${formattedDate}_merged.pdf`, savedData);
  const final = await pdfZip.generateAsync({ type: "uint8array" });
  return final;
};

export const formattedDate = dayjs().format(`YYYYMMDD`);

export const promisedFileDownload = async (
  pdfFile: any,
  fileName: FileTypeJa,
) => {
  const finalFile = await zipPdf(pdfFile);
  return new Promise((resolve) => {
    const title = `${fileName}${formattedDate}.zip`;
    resolve(fileDownload(finalFile, title));
  });
};

export const fixFileExtension = (file: File): File => {
  let fixedFile = file;

  if (file.type.match(/pdf/) && !file.name.match(/\.pdf$/)) {
    fixedFile = new File([file], `${file.name}.pdf`, {
      type: "application/pdf",
    });
  } else if (file.type.match(/jpeg/) && !file.name.match(/\.(jpg|jpeg)$/)) {
    fixedFile = new File([file], `${file.name}.jpg`, {
      type: "image/jpeg",
    });
  } else if (file.type.match(/png/) && !file.name.match(/\.(png)$/)) {
    fixedFile = new File([file], `${file.name}.png`, {
      type: "image/png",
    });
  }

  return fixedFile;
};
