import { ReduxStatus,ReduxStatusType } from "@constants/redux";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import { CategoryInfo, CategoryType } from "../../lib/Api";
import { getHelpCategories, resetCategoryData } from "../actions/common";
import {
  createCategoryAsOwner,
  deleteCategoryAsOwner,
  fetchCategoriesAsOwner,
  resetCategoryFormRelatedData,
  resetCopyModalState,
  resetSortData,
  saveSequenceAsOwner,
  updateCategoryAsOwner,
} from "../actions/ownerApp/category";
import { fetchCategoriesAsStudent } from "../actions/studentApp/category";
import {
  createCategoryAsSuperOwner,
  createMaterialDuplicateAsSuperOwner,
  deleteCategoryAsSuperOwner,
  fetchCategoriesAsSuperOwner,
  saveSequenceAsSuperOwner,
  updateCategoryAsSuperOwner,
} from "../actions/superOwnerApp/category";
import { fetchCategoriesAsTeacher } from "../actions/teacherApp/category";
import { RootState } from "../store";
import gradeReducer, { GradeCategoryState } from "./category/grade";
import majorCategoryReducer, { MajorCategoryState } from "./category/major";
import middleCategoryReducer, { MiddleCategoryState } from "./category/middle";
import minorCategoryReducer, { MinorCategoryState } from "./category/minor";
import subjectReducer, { SubjectCategoryState } from "./category/subject";
import textReducer, { TextCategoryState } from "./category/text";

export interface CategoryState {
  savingSequence: boolean;
  savingCategoryType: CategoryType | null;
  savedCategoryType: CategoryType | null;
  fetching: boolean;
  fetchedOnce: boolean;
  duplicate: ReduxStatusType;
  duplicateError: string | null;
  changing: boolean; // Create or Update
  changed: boolean;
  deleting: boolean;
  deleted: boolean;
  error: string | null;
  text: TextCategoryState;
  grade: GradeCategoryState;
  subject: SubjectCategoryState;
  major: MajorCategoryState;
  middle: MiddleCategoryState;
  minor: MinorCategoryState;
}

export const initialState: CategoryState = {
  fetching: false,
  fetchedOnce: false,
  changing: false,
  changed: false,
  duplicate: ReduxStatus.idle,
  duplicateError: null,
  deleting: false,
  deleted: false,
  error: null,
  text: {
    items: [],
  },
  grade: {
    items: [],
  },
  subject: {
    items: [],
  },
  major: {
    items: [],
  },
  middle: {
    items: [],
  },
  minor: {
    items: [],
  },
  savingSequence: false,
  savingCategoryType: null,
  savedCategoryType: null,
};

const PAYLOAD_CATEGORY_ID_COLUMNS = {
  TEXT: "text_id",
  GRADE: "grade_id",
  SUBJECT: "subject_id",
  MAJOR: "major_category_id",
  MIDDLE: "middle_category_id",
};

function changeSuccessActionDelegater(
  state: CategoryState,
  action: PayloadAction<any>,
) {
  const payload = action.payload as CategoryInfo;
  const baseChangedState = {
    ...state,
    changed: true,
    changing: false,
    error: null,
  };
  if (PAYLOAD_CATEGORY_ID_COLUMNS.MIDDLE in payload) {
    return {
      ...baseChangedState,
      minor: minorCategoryReducer(state.minor, action),
    };
  }
  if (PAYLOAD_CATEGORY_ID_COLUMNS.MAJOR in payload) {
    return {
      ...baseChangedState,
      middle: middleCategoryReducer(state.middle, action),
    };
  }
  if (PAYLOAD_CATEGORY_ID_COLUMNS.SUBJECT in payload) {
    return {
      ...baseChangedState,
      major: majorCategoryReducer(state.major, action),
    };
  }
  if (PAYLOAD_CATEGORY_ID_COLUMNS.GRADE in payload) {
    return {
      ...baseChangedState,
      subject: subjectReducer(state.subject, action),
    };
  }
  if (PAYLOAD_CATEGORY_ID_COLUMNS.TEXT in payload) {
    return {
      ...baseChangedState,
      grade: gradeReducer(state.grade, action),
    };
  }
  return {
    ...baseChangedState,
    text: textReducer(state.text, action),
  };
}

function deleteSuccessActionDelegater(
  state: CategoryState,
  action: PayloadAction<any>,
) {
  const { payload } = action;
  const baseChangedState = {
    ...state,
    deleted: true,
    deleting: false,
    error: null,
  };
  switch (payload.categoryType) {
    case CategoryType.Text:
      return {
        ...baseChangedState,
        text: textReducer(state.text, action),
        grade: gradeReducer(state.grade, action),
      };
    case CategoryType.Grade:
      return {
        ...baseChangedState,
        grade: gradeReducer(state.grade, action),
        subject: subjectReducer(state.subject, action),
      };
    case CategoryType.Subject:
      return {
        ...baseChangedState,
        subject: subjectReducer(state.subject, action),
        major: majorCategoryReducer(state.major, action),
      };
    case CategoryType.Major:
      return {
        ...baseChangedState,
        major: majorCategoryReducer(state.major, action),
        middle: middleCategoryReducer(state.middle, action),
      };
    case CategoryType.Middle:
      return {
        ...baseChangedState,
        middle: middleCategoryReducer(state.middle, action),
        minor: minorCategoryReducer(state.minor, action),
      };
    case CategoryType.Minor:
      return {
        ...baseChangedState,
        minor: minorCategoryReducer(state.minor, action),
      };
    default:
      return baseChangedState;
  }
}

const handleFetchSuccess = (
  state: CategoryState,
  action: PayloadAction<any>,
): CategoryState => {
  const currentState = current(state);
  return {
    ...state,
    fetching: false,
    fetchedOnce: true,
    text: textReducer(currentState.text, action),
    grade: gradeReducer(currentState.grade, action),
    subject: subjectReducer(currentState.subject, action),
    major: majorCategoryReducer(currentState.major, action),
    middle: middleCategoryReducer(currentState.middle, action),
    minor: minorCategoryReducer(currentState.minor, action),
  };
};

export const categorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchCategoriesAsOwner.fulfilled, (state, action) => {
        return handleFetchSuccess(state, action);
      })
      .addCase(fetchCategoriesAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchedOnce: true,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(fetchCategoriesAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchCategoriesAsStudent.fulfilled, (state, action) => {
        return handleFetchSuccess(state, action);
      })
      .addCase(fetchCategoriesAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchedOnce: true,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(fetchCategoriesAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchCategoriesAsTeacher.fulfilled, (state, action) => {
        return handleFetchSuccess(state, action);
      })
      .addCase(fetchCategoriesAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchedOnce: true,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(fetchCategoriesAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(fetchCategoriesAsSuperOwner.fulfilled, (state, action) => {
        return handleFetchSuccess(state, action);
      })
      .addCase(fetchCategoriesAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchedOnce: true,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(getHelpCategories.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
        };
      })
      .addCase(getHelpCategories.fulfilled, (state, action) => {
        return handleFetchSuccess(state, action);
      })
      .addCase(getHelpCategories.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchedOnce: true,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(createCategoryAsOwner.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(createCategoryAsOwner.fulfilled, (state, action) => {
        return changeSuccessActionDelegater(state, action);
      })
      .addCase(createCategoryAsOwner.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(createCategoryAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
        };
      })
      .addCase(createCategoryAsSuperOwner.fulfilled, (state, action) => {
        return changeSuccessActionDelegater(state, action);
      })
      .addCase(createCategoryAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          error: errorWithMessage(action.payload),
        };
      })

      .addCase(updateCategoryAsOwner.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(updateCategoryAsOwner.fulfilled, (state, action) => {
        return changeSuccessActionDelegater(state, action);
      })
      .addCase(updateCategoryAsOwner.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(updateCategoryAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(updateCategoryAsSuperOwner.fulfilled, (state, action) => {
        return changeSuccessActionDelegater(state, action);
      })
      .addCase(updateCategoryAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          error: errorWithMessage(action.payload),
        };
      })

      .addCase(deleteCategoryAsOwner.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
        };
      })
      .addCase(deleteCategoryAsOwner.fulfilled, (state, action) => {
        return deleteSuccessActionDelegater(state, action);
      })
      .addCase(deleteCategoryAsOwner.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(deleteCategoryAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
        };
      })
      .addCase(deleteCategoryAsSuperOwner.fulfilled, (state, action) => {
        return deleteSuccessActionDelegater(state, action);
      })
      .addCase(deleteCategoryAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          error: errorWithMessage(action.payload),
        };
      })
      .addCase(saveSequenceAsOwner.pending, (state, action) => {
        return {
          ...state,
          savingSequence: true,
          savingCategoryType: action.meta.arg.type,
        };
      })
      .addCase(saveSequenceAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          savingSequence: false,
          savingCategoryType: null,
          error: null,
          savedCategoryType: state.savingCategoryType,
          text: textReducer(state.text, action),
          grade: gradeReducer(state.grade, action),
          subject: subjectReducer(state.subject, action),
          major: majorCategoryReducer(state.major, action),
          middle: middleCategoryReducer(state.middle, action),
          minor: minorCategoryReducer(state.minor, action),
        };
      })
      .addCase(saveSequenceAsOwner.rejected, (state, action) => {
        return {
          ...state,
          savingSequence: false,
          savingCategoryType: null,
          error: errorWithMessage(action.payload),
          savedCategoryType: state.savingCategoryType,
        };
      })
      .addCase(saveSequenceAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          savingSequence: true,
          savingCategoryType: action.meta.arg.type,
        };
      })
      .addCase(saveSequenceAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          savingSequence: false,
          savingCategoryType: null,
          error: null,
          savedCategoryType: state.savingCategoryType,
          text: textReducer(state.text, action),
          grade: gradeReducer(state.grade, action),
          subject: subjectReducer(state.subject, action),
          major: majorCategoryReducer(state.major, action),
          middle: middleCategoryReducer(state.middle, action),
          minor: minorCategoryReducer(state.minor, action),
        };
      })
      .addCase(saveSequenceAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          savingSequence: false,
          savingCategoryType: null,
          error: errorWithMessage(action.payload),
          savedCategoryType: state.savingCategoryType,
        };
      })
      .addCase(
        createMaterialDuplicateAsSuperOwner.pending,
        (state, _action) => {
          return {
            ...state,
            duplicate: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        createMaterialDuplicateAsSuperOwner.fulfilled,
        (state, _action) => {
          return {
            ...state,
            duplicate: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        createMaterialDuplicateAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            duplicate: ReduxStatus.rejected,
            duplicateError: errorWithMessage(action.payload),
          };
        },
      )
      .addCase(resetCategoryFormRelatedData, (state, action) => {
        return {
          ...state,
          changing: false,
          changed: false,
          deleting: false,
          deleted: false,
          error: null,
        };
      })
      .addCase(resetSortData, (state, action) => {
        return {
          ...state,
          savingSequence: false,
          savingCategoryType: null,
          savedCategoryType: null,
          error: null,
        };
      })
      .addCase(resetCopyModalState, (state, _action) => {
        return {
          ...state,
          duplicate: ReduxStatus.idle,
          duplicateError: null,
        };
      })
      .addCase(resetCategoryData, (state, action) => {
        return {
          ...state,
          text: {
            items: [],
          },
          grade: {
            items: [],
          },
          subject: {
            items: [],
          },
          major: {
            items: [],
          },
          middle: {
            items: [],
          },
          minor: {
            items: [],
          },
          error: null,
          fetching: false,
          fetchedOnce: false,
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const categoryState = (state: RootState): CategoryState =>
  state.category;

export default categorySlice.reducer;
