import { Api, GetSchedulesAsTeacherParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchTeacherSchedules = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectMeta: void }
>(
  "teacher/schedules",
  async (params: GetSchedulesAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getSchedulesAsTeacher(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
