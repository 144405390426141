/* eslint-disable camelcase */

import { Api, FetchArticleCommentsAsStudentParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface FetchArticleCommentsAsStudentArg {
  params: FetchArticleCommentsAsStudentParams;
}

export const fetchArticleCommentsAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/articleComment/fetch",
  async (arg: FetchArticleCommentsAsStudentArg, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchArticleCommentsAsStudent(
        arg.params,
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteArticleCommentsAsStudent = createAsyncThunk<
  any,
  { article_id: string; id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/articleComment/fetch", async (arg, { rejectWithValue }) => {
  const { article_id, id } = arg;
  try {
    const response = await api.student.deleteArticleCommentAsStudent(
      article_id,
      id,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
