import { editorBlocksToHTML } from "@components/UI/molecules/SVEditor/utils";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import { getParsedJSON } from "@lib/string-utils";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";

import useDOMParse from "../useDOMParse";
import useSVEditorStyles, {
  COMPUTER_WIDTH,
  Device,
  DeviceType,
  SMARTPHONE_WIDTH,
  TABLET_WIDTH,
} from "../useSVEditorStyles";

type HTMLPreviewProp = {
  description: string;
  deviceType?: DeviceType;
};

export const HTMLPreview: React.FC<HTMLPreviewProp> = ({
  description,
  deviceType,
}) => {
  const defaultHtml = "<p><br></p>";

  const { SVEditorClasses: classes } = useSVEditorStyles();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [previewHtml, setPreviewHtml] = useState<string>(description);
  const { refetchResizeImageSrc, setMaterialLinkHref } = useDOMParse();

  const previewWidth = useMemo(() => {
    switch (deviceType) {
      case Device.SMARTPHONE:
        return SMARTPHONE_WIDTH;
      case Device.TABLET:
        return TABLET_WIDTH;
      case Device.COMPUTER:
        return COMPUTER_WIDTH;
      default:
        return window.innerWidth;
    }
  }, [deviceType]);

  useEffect(() => {
    if (description && getParsedJSON(description) !== false) {
      let html = editorBlocksToHTML(
        getParsedJSON(description),
        classes,
        deviceType,
      );
      html = setMaterialLinkHref(html);
      // tiptapのエディタ上では空のpタグの中にbrタグが見えるが,getHTMLするときにはbrタグが出力されない問題がある
      // https://github.com/ueberdosis/tiptap/issues/412
      html = html.replaceAll("<p></p>", defaultHtml);
      refetchResizeImageSrc(html, previewWidth)
        .then((result: string) => {
          setPreviewHtml(result);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }

    return () => {
      setIsLoading(true);
      setPreviewHtml(defaultHtml);
    };
  }, [description]);

  return (
    <div style={{ width: !deviceType ? "100%" : `${previewWidth}px` }}>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <div
          style={{ fontSize: "16px" }}
          data-cy="html-preview-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: previewHtml,
          }}
          className={clsx(classes.HTMLPreviewScroll, classes.ProseMirror)}
        />
      )}
    </div>
  );
};

HTMLPreview.defaultProps = {
  deviceType: undefined,
};

export default HTMLPreview;
