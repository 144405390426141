import { CommonUpdateMaterialParams } from "@actions/types/material";

export const prepareCommonFormForVideoUploading = (
  params: CommonUpdateMaterialParams,
): FormData => {
  const {
    title,
    categoryId,
    tags,
    description,
    memo,
    downloadAllowed,
    isPublic,
  } = params;
  const fd = new FormData();
  fd.set("title", title);
  fd.set("download_allowed", downloadAllowed);
  fd.set("is_public", isPublic);
  if (description) {
    fd.set("description", description);
  }
  if (memo) {
    fd.set("memo", memo);
  }
  if (categoryId) {
    fd.set("category_id", categoryId);
  }
  if (tags.length) {
    tags.forEach((tag) => {
      fd.append(`tag_list[]`, tag);
    });
  } else {
    fd.append(`tag_list[]`, "");
  }

  return fd;
};
