/* eslint-disable camelcase */
import {
  getAllSteamLibraryContentsAsTeacher,
  getSteamContentDetailAsTeacher,
  getSteamLibraryContentsAsTeacher,
  resetSearchSteamContentsState,
  resetSteamContentAsTeacherState,
  searchSteamContentsAsTeacher,
} from "@actions/teacherApp/steam/content";
import { SteamLibraryContentCard } from "@actions/types/steamLibrary";
import { PblSteamContent } from "@lib/Api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface TeacherAppSteamContentState {
  search: ReduxStatusType;
  getSteamContentDetail: ReduxStatusType;
  searchedContents: SteamLibraryContentCard[];
  content: PblSteamContent | null;
  searchError: string | null;
  getSteamContentDetailError: string | null;
  searchCount: number;
  contents: PblSteamContent[];
  contentsWithImages: SteamLibraryContentCard[];
  fetchContents: ReduxStatusType;
  fetchContentsError: string | null;
  currentPage: number;
  totalCount: number;
  getAllContents: ReduxStatusType;
  getAllContentsError: string | null;
  allContents: PblSteamContent[];
}

const initialState: TeacherAppSteamContentState = {
  search: ReduxStatus.idle,
  getSteamContentDetail: ReduxStatus.idle,
  searchedContents: [],
  content: null,
  searchError: null,
  getSteamContentDetailError: null,
  searchCount: 0,
  fetchContents: ReduxStatus.idle,
  fetchContentsError: null,
  contents: [],
  contentsWithImages: [],
  currentPage: 1,
  totalCount: 0,
  getAllContents: ReduxStatus.idle,
  getAllContentsError: null,
  allContents: [],
};

const teacherAppSteamContentSlice = createSlice({
  name: "teacherApp/SteamContent",
  initialState,
  reducers: {
    setContentCurrentPage: (state, action: PayloadAction<number>) => {
      return { ...state, currentPage: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSteamLibraryContentsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchContents: ReduxStatus.pending,
          fetchContentsError: null,
        };
      })
      .addCase(getSteamLibraryContentsAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetchContents: ReduxStatus.fulfilled,
          fetchContentsError: null,
          contents: action.payload.steam_contents,
          contentsWithImages: action.payload.contentsWithImages,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(getSteamLibraryContentsAsTeacher.rejected, (state, _action) => {
        return {
          ...state,
          fetchContents: ReduxStatus.rejected,
          fetchContentsError: "データが取得できませんでした。",
        };
      })
      .addCase(
        getAllSteamLibraryContentsAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            getAllContents: ReduxStatus.pending,
            getAllContentsError: null,
          };
        },
      )
      .addCase(
        getAllSteamLibraryContentsAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            getAllContents: ReduxStatus.fulfilled,
            getAllContentsError: null,
            allContents: action.payload.steam_contents,
          };
        },
      )
      .addCase(
        getAllSteamLibraryContentsAsTeacher.rejected,
        (state, _action) => {
          return {
            ...state,
            getAllContents: ReduxStatus.rejected,
            getAllContentsError: "データが取得できませんでした。",
          };
        },
      )
      .addCase(resetSteamContentAsTeacherState, (state, _action) => {
        return {
          ...state,
          fetchContents: ReduxStatus.idle,
          fetchContentsError: null,
          getAllContents: ReduxStatus.idle,
          getAllContentsError: null,
        };
      })
      .addCase(searchSteamContentsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          search: ReduxStatus.pending,
          searchError: null,
        };
      })
      .addCase(searchSteamContentsAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          search: ReduxStatus.fulfilled,
          searchedContents: action.payload.steam_contents,
          searchCount: action.payload.total_count,
        };
      })
      .addCase(searchSteamContentsAsTeacher.rejected, (state, _action) => {
        return {
          ...state,
          search: ReduxStatus.rejected,
          searchError: "データが取得できませんでした。",
        };
      })
      .addCase(getSteamContentDetailAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          getSteamContentDetail: ReduxStatus.pending,
          getSteamContentDetailError: null,
        };
      })
      .addCase(getSteamContentDetailAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          getSteamContentDetail: ReduxStatus.fulfilled,
          content: action.payload,
        };
      })
      .addCase(getSteamContentDetailAsTeacher.rejected, (state, _action) => {
        return {
          ...state,
          getSteamContentDetail: ReduxStatus.rejected,
          getSteamContentDetailError: "データが取得できませんでした。",
        };
      })

      .addCase(resetSearchSteamContentsState, (state) => {
        return {
          ...state,
          searchedContents: [],
          searchCount: 0,
          search: ReduxStatus.idle,
        };
      });
  },
});

export const TeacherAppSteamContentState = (
  state: RootState,
): TeacherAppSteamContentState => state.teacherApp.steamContent;

export const { setContentCurrentPage } = teacherAppSteamContentSlice.actions;
export default teacherAppSteamContentSlice.reducer;
