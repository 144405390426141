import { useHandleStudyLogsByContentType } from "@components/Teacher/StudentLogs/Logs/hooks/useHandleStudyLogsByContentType";
import LogsSectionData from "@components/Teacher/StudentLogs/Logs/LogsStudentItem/LogsSectionData";
import LogsSectionDataSkeleton from "@components/Teacher/StudentLogs/Logs/LogsStudentItem/LogsSectionData/LogSectionDataSkeleton";
import {
  CommunizedStatusCode,
  getTagColorByStatusCode,
} from "@components/UI/atoms/StyledTag/getTagColorByStatusCode";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import { ITEMS_PER_PAGE } from "@constants/page";
import { ArticleStatus } from "@lib/Api";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box,Collapse, Container, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  DeliverableListBase,
  StudentLogDeliverableArticleBase,
} from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";

import { SectionsArrayType } from "..";

type Props = {
  section: SectionsArrayType;
  studentInfos: DeliverableListBase | undefined;
  startDate: string;
  endDate: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    sectionTitleContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.customColor.text}`,
      cursor: "pointer",
    },
    logsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      gap: theme.spacing(1),
      margin: theme.spacing(2, 0),
    },
    sectionName: {
      color: theme.palette.customColor.text,
      fontWeight: 700,
    },
  }),
);

const SectionsAndData: React.FC<Props> = ({
  section,
  studentInfos,
  startDate,
  endDate,
}) => {
  const classes = useStyles();

  const logSectionContentStates = useHandleStudyLogsByContentType(
    studentInfos?.student_affiliate_id as string,
    startDate,
    endDate,
  );

  return (
    <Box className={classes.mainContainer}>
      <Box
        onClick={() =>
          logSectionContentStates.handleSelectedContent(section.contentType)
        }
        className={classes.sectionTitleContainer}
      >
        <Box>
          <span>{section.icon}</span>
        </Box>
        <Box style={{ width: "120px" }}>
          <Typography className={classes.sectionName}>
            {section.title}
          </Typography>
        </Box>
        <Box>
          {logSectionContentStates.openModule ? (
            <ArrowUpwardIcon />
          ) : (
            <ArrowDownwardIcon />
          )}
        </Box>
      </Box>
      <Collapse
        style={{ width: "100%" }}
        in={logSectionContentStates.openModule}
        timeout="auto"
        unmountOnExit
      >
        <Container style={{ width: "100%" }}>
          {logSectionContentStates &&
            Object.keys(logSectionContentStates).length &&
            logSectionContentStates.logsList && (
              <>
                {(() => {
                  if (logSectionContentStates.isFetching)
                    return <LogsSectionDataSkeleton />;
                  return (
                    <>
                      <Box className={classes.logsContainer}>
                        {logSectionContentStates.logsList.length ? (
                          logSectionContentStates.logsList.map(
                            (log: StudentLogDeliverableArticleBase) => {
                              const tagColor = getTagColorByStatusCode(
                                log.status_code as CommunizedStatusCode,
                              );
                              let tagName: string = log.status as string;
                              if (
                                log.status_code === ArticleStatus.Correction
                              ) {
                                tagName = "提出待ち";
                              } else if (
                                log.status_code ===
                                ArticleStatus.TeacherChecking
                              ) {
                                tagName = "確認中";
                              }
                              return (
                                <LogsSectionData
                                  key={log.id}
                                  log={log}
                                  studentUserId={
                                    studentInfos?.student_user_id as string
                                  }
                                  tagColor={tagColor}
                                  tagName={tagName}
                                />
                              );
                            },
                          )
                        ) : (
                          <Box m={2}>
                            <Typography>
                              データが見つかりませんでした。
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <V2Pagination
                        totalCount={
                          logSectionContentStates.totalCount as number
                        }
                        perPage={ITEMS_PER_PAGE}
                        currentPage={
                          logSectionContentStates.currentPage as number
                        }
                        handlePageTransition={
                          logSectionContentStates.handlePagination
                        }
                      />
                    </>
                  );
                })()}
              </>
            )}
        </Container>
      </Collapse>
    </Box>
  );
};

export default SectionsAndData;
