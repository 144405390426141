/* eslint-disable camelcase */
import { getTodosAsStudent } from "@actions/studentApp/dashboard/student_todo";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { HasTotalCount } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface TodoListTypes {
  answers: HasTotalCount;
  articles: HasTotalCount;
  homeworks: HasTotalCount;
  material_works: HasTotalCount;
  learning_templates: HasTotalCount;
}
export interface StudentAppArticleState {
  fetchTodoList: ReduxStatusType;
  todoList: TodoListTypes;
  fetchingError: any;
}

export const initialState: StudentAppArticleState = {
  fetchTodoList: ReduxStatus.idle,
  todoList: {} as TodoListTypes,
  fetchingError: null,
};

export const studentTodosSlice = createSlice({
  name: "student/todos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTodosAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchTodoList: ReduxStatus.pending,
          fetchingError: null,
        };
      })
      .addCase(getTodosAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchTodoList: ReduxStatus.fulfilled,
          todoList: payload,
          fetchingError: null,
        };
      })
      .addCase(getTodosAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchTodoList: ReduxStatus.rejected,
          fetchingError: action.error.message as string,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentTodosState = (state: RootState): StudentAppArticleState =>
  state.studentApp.todos;

export default studentTodosSlice.reducer;
