// eslint-disable-next-line import/prefer-default-export
export const AUTH = {
  ACCESS_TOKEN: "accessToken",
  CLIENT: "client",
  UID: "uid",
  EXPIRY: "expiry",
};

export const AUTH_PROXY = {
  ACCESS_TOKEN: "accessTokenProxy",
  CLIENT: "clientProxy",
  UID: "uidProxy",
  EXPIRY: "expiryProxy",
};

export const SIGN_IN_SETTING = {
  REMEMBER_ME: "rememberMe",
  REMEMBER_ME_GOOGLE: "rememberMeGoogle",
};
