import { baseApi as api } from "../baseApi";
export const addTagTypes = [
  "Teacher/User",
  "Teacher/Student",
  "Teacher/Students",
  "Users",
  "Teacher/Users",
  "Materials",
  "materials",
  "MaterialWorks",
  "Teacher/Material/Tag",
  "Report",
  "Teacher/Report",
  "Payment",
  "Homework",
  "Teacher/Homework",
  "SubmitHomeworkFilesRequest",
  "Owner",
  "Owner/Homework",
  "Teacher/HomeworkTemplate",
  "HomeworkTemplate",
  "Teacher/HomeworkTemplateCustomFieldFeedbacks",
  "Teacher/HomeworkTemplateCustomField",
  "Companies",
  "Teacher/Company",
  "Teacher/ReviewerCompanyGroup",
  "Categories",
  "Teacher/Notification",
  "Teacher/Article",
  "articles",
  "Teacher/ArticleImages",
  "Teacher/ArticleComment",
  "Teacher/UserTagging",
  "Teacher/UserTagType",
  "Teacher/UserTag/User",
  "Teacher/UserTag/Student",
  "Teacher/UserTag",
  "Teacher/UserTags",
  "Owner/HomeworkTemplate",
  "Teacher/HomeworkTemplateCustomFields",
  "HomeworkTemplateDistribution",
  "Teacher/HomeworkTemplateDistribution",
  "Teacher/HomeworkTemplateChunk",
  "Teacher/HomeworkTemplateImage",
  "Teacher/PblReportTemplate",
  "Teacher/PblReportAnswer",
  "Teacher/PblReportComment",
  "Teacher/PblReportItem",
  "Teacher/PblCardsInformationLiteracyBookmark",
  "Teacher/PblCardsInformationLiteracyComment",
  "Teacher/PblCardsInformationLiteracyBookmarkImage",
  "Teacher/Stamp",
  "Teacher/MaterialDistributionHistory",
  "Teacher/Steam/Contents",
  "Teacher/Steam/Comments",
  "Teacher/Steam/Lectures",
  "Teacher/Steam/ContentBookmarks",
  "Teacher/Steam/StudentContents",
  "Teacher/Steam/ContentRecommendations",
  "Teacher/Plan/Items",
  "Teacher/Plan/Classes",
  "Teacher/Plan/Contents",
  "Teacher/Plan/Days",
  "Teacher/Plan/Students",
  "Teacher/Inquiries",
  "Teacher/InquiryComments",
  "Teacher/Sponsor/Infos",
  "Teacher/Sponsor/Projects",
  "Teacher/Sponsor/Lectures",
  "Teacher/Sponsor/CommentList",
  "Teacher/Sponsor/Bookmarks",
  "Teacher/Sponsor/Recommends",
  "Teacher/Sponsor/Comments",
  "Teacher/Pbl/Sponsor/Comment",
  "Teacher/ContentCompanies",
  "Teacher/Schedules",
  "Teacher/StudentLogs",
  "Teacher/Rubrics",
  "Teacher/RubricItems",
  "Teacher/RubricEvaluates",
  "Teacher/RubricEvaluateStudents",
  "Teacher/RubricEvaluateStudentRates",
  "Teacher/LearningTemplatePackageDistributions",
  "Teacher/LearningPackages",
  "Teacher/LearningTemplatePackageDistributionHistories",
  "Teacher/PblBoardCampaigns",
  "Teacher/PblBoardCampaignRequest",
  "Teacher/GroupAffiliatePackages",
  "Teacher/Packaging",
  "Teacher/AttachedImages",
  "Teacher/LimitedTimeNotifications",
  "Teacher/DailyLog",
  "Teacher/IdealBoardTemplates",
  "Teacher/IdealBoardTemplate",
  "Teacher/IdealBoards",
  "Teacher/IdealBoard",
  "Teacher/BoardAffiliates",
  "AllowedGroupAffiliates",
  "Teacher/TtCsvExports",
  "Teacher/TtCategoryBases",
  "Teacher/FeedbackTemplate/Homework",
  "Teacher/FeedbackTemplate/Tag",
  "Teacher/CommentImages",
  "Teacher/DistributionStatus",
  "Teacher/Interview/Request",
  "Teacher/Interview/Message",
  "Teacher/Interview/Feedback",
  "Teacher/AffiliateInvitation",
  "Teacher/HomeworkTemplateCustomValues",
  "Teacher/Gogo/Linkings",
  "Teacher/Gogo/Request",
  "Teacher/Gogo/Message",
  "Teacher/Chat/Room",
  "Teacher/Chat/RoomMessage",
  "Teacher/CommonTags",
  "Teacher/CommonTaggings",
  "Teacher/CommonTagLinking",
  "Teacher/Gogo/Program",
  "Teacher/Gogo/ProgramRequest",
  "Teacher/Gogo/ProgramRequestMessage",
  "Teacher/Gogo/ProgramPreferredDate",
  "Teacher/Gogo/CompanyInformation",
  "Teacher/Gogo/SchoolInformation",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getTeacherUsersStudents: build.query<
        GetTeacherUsersStudentsApiResponse,
        GetTeacherUsersStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            user_tag_ids: queryArg.userTagIds,
            tag_filter: queryArg.tagFilter,
            homework_template_id: queryArg.homeworkTemplateId,
            pbl_report_template_id: queryArg.pblReportTemplateId,
            filter_material_id: queryArg.filterMaterialId,
            distribution: queryArg.distribution,
            search: queryArg.search,
            email: queryArg.email,
            grade: queryArg.grade,
            grades: queryArg.grades,
            parent_name: queryArg.parentName,
            user_tag: queryArg.userTag,
            per_page: queryArg.perPage,
            page: queryArg.page,
            group_affiliate_package_id: queryArg.groupAffiliatePackageId,
            sorted_by: queryArg.sortedBy,
            group_affiliate_package_ids: queryArg.groupAffiliatePackageIds,
            search_statuses: queryArg.searchStatuses,
            pbl_plan_item_id: queryArg.pblPlanItemId,
            nickname: queryArg.nickname,
          },
        }),
        providesTags: ["Teacher/User"],
      }),
      postTeacherStudents: build.mutation<
        PostTeacherStudentsApiResponse,
        PostTeacherStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/User"],
      }),
      studentsBatchCreateUsersAsTeacher: build.mutation<
        StudentsBatchCreateUsersAsTeacherApiResponse,
        StudentsBatchCreateUsersAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students/batch_create`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/User"],
      }),
      getTeacherUsersStudentsListGrade: build.query<
        GetTeacherUsersStudentsListGradeApiResponse,
        GetTeacherUsersStudentsListGradeApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students/list_grade`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            homework_template_id: queryArg.homeworkTemplateId,
            pbl_report_template_id: queryArg.pblReportTemplateId,
            filter_material_id: queryArg.filterMaterialId,
            distribution: queryArg.distribution,
          },
        }),
        providesTags: ["Teacher/Student"],
      }),
      createAccountUserAsTeacher: build.mutation<
        CreateAccountUserAsTeacherApiResponse,
        CreateAccountUserAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students/create_account_user`,
          method: "POST",
          body: queryArg.createAccountUserRequestBodyAsTeacher,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/User"],
      }),
      createAccountAvailableAsTeacher: build.mutation<
        CreateAccountAvailableAsTeacherApiResponse,
        CreateAccountAvailableAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students/account_available`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/User"],
      }),
      getTeacherUsersStudentAffiliates: build.query<
        GetTeacherUsersStudentAffiliatesApiResponse,
        GetTeacherUsersStudentAffiliatesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/student_affiliates`,
          params: {
            search: queryArg.search,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Teacher/User"],
      }),
      getStudentsUserTagsAsTeacher: build.query<
        GetStudentsUserTagsAsTeacherApiResponse,
        GetStudentsUserTagsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students/${queryArg.id}/user_tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Students"],
      }),
      getStudentsGroupAffiliatesAsTeacher: build.query<
        GetStudentsGroupAffiliatesAsTeacherApiResponse,
        GetStudentsGroupAffiliatesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students/${queryArg.id}/group_affiliates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Students"],
      }),
      getTeacherUsersStudentsById: build.query<
        GetTeacherUsersStudentsByIdApiResponse,
        GetTeacherUsersStudentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Users"],
      }),
      putTeacherUsersStudentsById: build.mutation<
        PutTeacherUsersStudentsByIdApiResponse,
        PutTeacherUsersStudentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Users", "Teacher/User"],
      }),
      deleteTeacherStudentsUserId: build.mutation<
        DeleteTeacherStudentsUserIdApiResponse,
        DeleteTeacherStudentsUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/students/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Users", "Teacher/User"],
      }),
      getTeachersAsTeacher: build.query<
        GetTeachersAsTeacherApiResponse,
        GetTeachersAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/teachers`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
            email: queryArg.email,
            search_statuses: queryArg.searchStatuses,
            is_min_info: queryArg.isMinInfo,
          },
        }),
        providesTags: ["Users"],
      }),
      deleteTeacherTeachersUserId: build.mutation<
        DeleteTeacherTeachersUserIdApiResponse,
        DeleteTeacherTeachersUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/teachers/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Users", "Teacher/User"],
      }),
      putTeachersProgressUpdateAsTeacher: build.mutation<
        PutTeachersProgressUpdateAsTeacherApiResponse,
        PutTeachersProgressUpdateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/teachers/progress_update/${queryArg.id}`,
          method: "PUT",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Users"],
      }),
      getTeacherUniversitiesAsTeacher: build.query<
        GetTeacherUniversitiesAsTeacherApiResponse,
        GetTeacherUniversitiesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/teachers/university`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            pbl_sponsor_project_id: queryArg.pblSponsorProjectId,
          },
        }),
        providesTags: ["Users"],
      }),
      getTeacherUsersByUserId: build.query<
        GetTeacherUsersByUserIdApiResponse,
        GetTeacherUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/${queryArg.userId}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/User"],
      }),
      putTeacherUsersByUserId: build.mutation<
        PutTeacherUsersByUserIdApiResponse,
        PutTeacherUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/${queryArg.userId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/Users"],
      }),
      deleteTeacherUsersByUserId: build.mutation<
        DeleteTeacherUsersByUserIdApiResponse,
        DeleteTeacherUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/${queryArg.userId}`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Users"],
      }),
      resetPasswordAsTeacher: build.mutation<
        ResetPasswordAsTeacherApiResponse,
        ResetPasswordAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/${queryArg.id}/reset_password`,
          method: "PUT",
          body: queryArg.authorizationPasswordRequestBody,
        }),
        invalidatesTags: ["Teacher/User"],
      }),
      accountUnlockAsTeacher: build.mutation<
        AccountUnlockAsTeacherApiResponse,
        AccountUnlockAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/users/bulk_unlock_access`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/User"],
      }),
      getTeacherMaterials: build.query<
        GetTeacherMaterialsApiResponse,
        GetTeacherMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/materials`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            category_ids: queryArg.categoryIds,
            tag_names: queryArg.tagNames,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            search_column: queryArg.searchColumn,
            search_order: queryArg.searchOrder,
            for_pbl_plan_item: queryArg.forPblPlanItem,
          },
        }),
        providesTags: ["Materials"],
      }),
      getTeacherMaterialsByMaterialId: build.query<
        GetTeacherMaterialsByMaterialIdApiResponse,
        GetTeacherMaterialsByMaterialIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/materials/${queryArg.materialId}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["materials"],
      }),
      getTeacherMaterialWorks: build.query<
        GetTeacherMaterialWorksApiResponse,
        GetTeacherMaterialWorksApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/material_works`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["MaterialWorks"],
      }),
      postTeacherMaterialWorks: build.mutation<
        PostTeacherMaterialWorksApiResponse,
        PostTeacherMaterialWorksApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/material_works`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["MaterialWorks"],
      }),
      getTeacherMaterialWorksByMaterialWorkId: build.query<
        GetTeacherMaterialWorksByMaterialWorkIdApiResponse,
        GetTeacherMaterialWorksByMaterialWorkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/material_works/${queryArg.materialWorkId}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["MaterialWorks"],
      }),
      putTeacherMaterialWorksByMaterialWorkId: build.mutation<
        PutTeacherMaterialWorksByMaterialWorkIdApiResponse,
        PutTeacherMaterialWorksByMaterialWorkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/material_works/${queryArg.materialWorkId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["MaterialWorks"],
      }),
      getMaterialTagsAsTeacher: build.query<
        GetMaterialTagsAsTeacherApiResponse,
        GetMaterialTagsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/material/tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            for_pbl_plan_item: queryArg.forPblPlanItem,
          },
        }),
        providesTags: ["Teacher/Material/Tag"],
      }),
      fetchStudentMaterialWorksAsTeacher: build.query<
        FetchStudentMaterialWorksAsTeacherApiResponse,
        FetchStudentMaterialWorksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_material_works`,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
          params: { student_id: queryArg.studentId },
        }),
        providesTags: ["Users"],
      }),
      fetchOneStudentMaterialWorkAsTeacher: build.query<
        FetchOneStudentMaterialWorkAsTeacherApiResponse,
        FetchOneStudentMaterialWorkAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_material_works/${queryArg.materialWorkId}`,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
          params: { student_id: queryArg.studentId },
        }),
        providesTags: ["Users", "MaterialWorks"],
      }),
      getTeacherReports: build.query<
        GetTeacherReportsApiResponse,
        GetTeacherReportsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/reports`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Report"],
      }),
      postTeacherReports: build.mutation<
        PostTeacherReportsApiResponse,
        PostTeacherReportsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/reports`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/Report"],
      }),
      getTeacherReportsByReportId: build.query<
        GetTeacherReportsByReportIdApiResponse,
        GetTeacherReportsByReportIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/reports/${queryArg.reportId}`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Report"],
      }),
      putTeacherReportsByReportId: build.mutation<
        PutTeacherReportsByReportIdApiResponse,
        PutTeacherReportsByReportIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/reports/${queryArg.reportId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Report"],
      }),
      deleteTeacherReportsByReportId: build.mutation<
        DeleteTeacherReportsByReportIdApiResponse,
        DeleteTeacherReportsByReportIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/reports/${queryArg.reportId}`,
          method: "DELETE",
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Report"],
      }),
      getTeacherPayments: build.query<
        GetTeacherPaymentsApiResponse,
        GetTeacherPaymentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/payments`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Payment"],
      }),
      postTeacherPayments: build.mutation<
        PostTeacherPaymentsApiResponse,
        PostTeacherPaymentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/payments`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Payment"],
      }),
      getTeacherPaymentsByPaymentId: build.query<
        GetTeacherPaymentsByPaymentIdApiResponse,
        GetTeacherPaymentsByPaymentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/payments/${queryArg.paymentId}`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Payment"],
      }),
      putTeacherPaymentsByPaymentId: build.mutation<
        PutTeacherPaymentsByPaymentIdApiResponse,
        PutTeacherPaymentsByPaymentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/payments/${queryArg.paymentId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Payment"],
      }),
      deleteTeacherPaymentsByPaymentId: build.mutation<
        DeleteTeacherPaymentsByPaymentIdApiResponse,
        DeleteTeacherPaymentsByPaymentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/payments/${queryArg.paymentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Payment"],
      }),
      getTeacherHomeworks: build.query<
        GetTeacherHomeworksApiResponse,
        GetTeacherHomeworksApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            template_id: queryArg.templateId,
            teacher_name: queryArg.teacherName,
            student_name: queryArg.studentName,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            statuses: queryArg.statuses,
            group_affiliate_ids: queryArg.groupAffiliateIds,
            submitted_start_at: queryArg.submittedStartAt,
            submitted_end_at: queryArg.submittedEndAt,
            order_type: queryArg.orderType,
            sort_type: queryArg.sortType,
            user_tag_ids: queryArg.userTagIds,
            group_title: queryArg.groupTitle,
            common_tag_name: queryArg.commonTagName,
          },
        }),
        providesTags: ["Homework"],
      }),
      getTeacherHomeworksByHomeworkId: build.query<
        GetTeacherHomeworksByHomeworkIdApiResponse,
        GetTeacherHomeworksByHomeworkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/${queryArg.homeworkId}`,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Homework"],
      }),
      putTeacherHomeworksByHomeworkId: build.mutation<
        PutTeacherHomeworksByHomeworkIdApiResponse,
        PutTeacherHomeworksByHomeworkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/${queryArg.homeworkId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Homework"],
      }),
      deleteTeacherHomeworksByHomeworkId: build.mutation<
        DeleteTeacherHomeworksByHomeworkIdApiResponse,
        DeleteTeacherHomeworksByHomeworkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/${queryArg.homeworkId}`,
          method: "DELETE",
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Homework"],
      }),
      getHomeworkImageDownloadUrlsAsTeacher: build.query<
        GetHomeworkImageDownloadUrlsAsTeacherApiResponse,
        GetHomeworkImageDownloadUrlsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/get_download_urls`,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
          params: {
            homework_ids: queryArg.homeworkIds,
            file_content: queryArg.fileContent,
            template_id: queryArg.templateId,
            order_type: queryArg.orderType,
            sort_type: queryArg.sortType,
          },
        }),
        providesTags: ["Homework"],
      }),
      batchCreateHomeworksAsTeacher: build.mutation<
        BatchCreateHomeworksAsTeacherApiResponse,
        BatchCreateHomeworksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/batch_create`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/Homework", "Homework"],
      }),
      batchCreateGroupHomeworksAsTeacher: build.mutation<
        BatchCreateGroupHomeworksAsTeacherApiResponse,
        BatchCreateGroupHomeworksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/group_homeworks/batch_create`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/Homework", "Homework"],
      }),
      batchUpdateHomeworksAsTeacher: build.mutation<
        BatchUpdateHomeworksAsTeacherApiResponse,
        BatchUpdateHomeworksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/batch_update`,
          method: "PATCH",
          body: queryArg.batchUpdateHomeworkRequest,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/Homework", "Homework"],
      }),
      submitHomeworkAnswerFilesAsTeacher: build.mutation<
        SubmitHomeworkAnswerFilesAsTeacherApiResponse,
        SubmitHomeworkAnswerFilesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/submit_answer_files`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/Homework", "Homework"],
      }),
      fetchSubmitHomeworkFilesRequestsAsTeacher: build.query<
        FetchSubmitHomeworkFilesRequestsAsTeacherApiResponse,
        FetchSubmitHomeworkFilesRequestsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/submit_homework_files_requests`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            template_id: queryArg.templateId,
            statuses: queryArg.statuses,
          },
        }),
        providesTags: ["Homework", "SubmitHomeworkFilesRequest"],
      }),
      createSubmitHomeworkFilesRequestAsTeacher: build.mutation<
        CreateSubmitHomeworkFilesRequestAsTeacherApiResponse,
        CreateSubmitHomeworkFilesRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/submit_homework_files_requests`,
          method: "POST",
          body: queryArg.body,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
        }),
        invalidatesTags: ["Homework", "SubmitHomeworkFilesRequest"],
      }),
      batchDeleteHomeworksAsTeacher: build.mutation<
        BatchDeleteHomeworksAsTeacherApiResponse,
        BatchDeleteHomeworksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/batch_delete`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Homework", "Owner", "Owner/Homework"],
      }),
      batchUpdateTeacherCommentsHomeworksAsTeacher: build.mutation<
        BatchUpdateTeacherCommentsHomeworksAsTeacherApiResponse,
        BatchUpdateTeacherCommentsHomeworksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/batch_update_teacher_comments`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/Homework", "Homework"],
      }),
      uploadHomeworkImagesAsTeacher: build.mutation<
        UploadHomeworkImagesAsTeacherApiResponse,
        UploadHomeworkImagesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/${queryArg.homeworkId}/upload_images`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      saveEditedHomeworkImageAsTeacher: build.mutation<
        SaveEditedHomeworkImageAsTeacherApiResponse,
        SaveEditedHomeworkImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/${queryArg.homeworkId}/save_image`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      updateHomeworkImageAsTeacher: build.mutation<
        UpdateHomeworkImageAsTeacherApiResponse,
        UpdateHomeworkImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/${queryArg.homeworkId}/update_image`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      deleteHomeworkImageAsTeacher: build.mutation<
        DeleteHomeworkImageAsTeacherApiResponse,
        DeleteHomeworkImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homeworks/${queryArg.homeworkId}/delete_image`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      postHomeworkTemplateCustomFieldFeedbackAsTeacher: build.mutation<
        PostHomeworkTemplateCustomFieldFeedbackAsTeacherApiResponse,
        PostHomeworkTemplateCustomFieldFeedbackAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_template_custom_fields/${queryArg.homeworkTemplateCustomFieldId}/feedbacks`,
          method: "POST",
          body: queryArg.customFieldFeedbackRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/HomeworkTemplateCustomFieldFeedbacks"],
      }),
      putHomeworkTemplateCustomFieldFeedbacksAsTeacher: build.mutation<
        PutHomeworkTemplateCustomFieldFeedbacksAsTeacherApiResponse,
        PutHomeworkTemplateCustomFieldFeedbacksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_template_custom_fields/${queryArg.homeworkTemplateCustomFieldId}/feedbacks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putCustomFieldFeedbackRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/HomeworkTemplateCustomFieldFeedbacks"],
      }),
      deleteHomeworkTemplateCustomFieldFeedbacksAsTeacher: build.mutation<
        DeleteHomeworkTemplateCustomFieldFeedbacksAsTeacherApiResponse,
        DeleteHomeworkTemplateCustomFieldFeedbacksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_template_custom_fields/${queryArg.homeworkTemplateCustomFieldId}/feedbacks/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/HomeworkTemplateCustomFieldFeedbacks"],
      }),
      putTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequence:
        build.mutation<
          PutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceApiResponse,
          PutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceApiArg
        >({
          query: (queryArg) => ({
            url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/sequence`,
            method: "PUT",
            body: queryArg.body,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["Teacher/HomeworkTemplateCustomField"],
        }),
      getTeacherCompaniesByCompanyId: build.query<
        GetTeacherCompaniesByCompanyIdApiResponse,
        GetTeacherCompaniesByCompanyIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/companies/${queryArg.companyId}`,
        }),
        providesTags: ["Companies"],
      }),
      putTeacherCompaniesCompanyId: build.mutation<
        PutTeacherCompaniesCompanyIdApiResponse,
        PutTeacherCompaniesCompanyIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/companies/${queryArg.companyId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Companies"],
      }),
      getCompanyLimitOptionAsTeacher: build.query<
        GetCompanyLimitOptionAsTeacherApiResponse,
        GetCompanyLimitOptionAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/companies/${queryArg.id}/limit_option`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Company"],
      }),
      getReviewerCompanyGroupControllersAsTeacher: build.query<
        GetReviewerCompanyGroupControllersAsTeacherApiResponse,
        GetReviewerCompanyGroupControllersAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/reviewer_company_groups`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/ReviewerCompanyGroup"],
      }),
      getTeacherCategories: build.query<
        GetTeacherCategoriesApiResponse,
        GetTeacherCategoriesApiArg
      >({
        query: () => ({ url: `/teacher/categories` }),
        providesTags: ["Categories"],
      }),
      getTeacherNotifications: build.query<
        GetTeacherNotificationsApiResponse,
        GetTeacherNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/notifications`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            sent: queryArg.sent,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["Teacher/Notification"],
      }),
      createNotificationAsTeacher: build.mutation<
        CreateNotificationAsTeacherApiResponse,
        CreateNotificationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/notifications`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/Notification"],
      }),
      getOwnerNotificationId: build.query<
        GetOwnerNotificationIdApiResponse,
        GetOwnerNotificationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/notifications/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Notification"],
      }),
      putTeacherNotificationId: build.mutation<
        PutTeacherNotificationIdApiResponse,
        PutTeacherNotificationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/notifications/${queryArg.id}`,
          method: "PUT",
          body: queryArg.notificationRequest,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Notification"],
      }),
      deleteTeacherNotificationId: build.mutation<
        DeleteTeacherNotificationIdApiResponse,
        DeleteTeacherNotificationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/notifications/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Notification"],
      }),
      getTeacherArticles: build.query<
        GetTeacherArticlesApiResponse,
        GetTeacherArticlesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            group_affiliate_package_id: queryArg.groupAffiliatePackageId,
            group_affiliate_package_ids: queryArg.groupAffiliatePackageIds,
            status: queryArg.status,
            kind: queryArg.kind,
            free_word: queryArg.freeWord,
            search_column: queryArg.searchColumn,
            search_order: queryArg.searchOrder,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            user_tag_name: queryArg.userTagName,
            student_number: queryArg.studentNumber,
            common_tag_name: queryArg.commonTagName,
          },
        }),
        providesTags: ["Teacher/Article"],
      }),
      fetchArticleStatsAsTeacher: build.query<
        FetchArticleStatsAsTeacherApiResponse,
        FetchArticleStatsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/stats`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: {
            limit: queryArg.limit,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: ["Teacher/Article"],
      }),
      fetchArticleWordCloudAsTeacher: build.query<
        FetchArticleWordCloudAsTeacherApiResponse,
        FetchArticleWordCloudAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/word_cloud`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            list_len: queryArg.listLen,
            offset: queryArg.offset,
            min_num_of_char: queryArg.minNumOfChar,
          },
        }),
        providesTags: ["Teacher/Article"],
      }),
      getArticleSubmitAsTeacher: build.query<
        GetArticleSubmitAsTeacherApiResponse,
        GetArticleSubmitAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/submit`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: { limit: queryArg.limit, article_ids: queryArg.articleIds },
        }),
        providesTags: ["Teacher/Article"],
      }),
      getTeacherArticlesByArticleId: build.query<
        GetTeacherArticlesByArticleIdApiResponse,
        GetTeacherArticlesByArticleIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/${queryArg.articleId}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Teacher/Article", "articles"],
      }),
      putTeacherArticlesByArticleId: build.mutation<
        PutTeacherArticlesByArticleIdApiResponse,
        PutTeacherArticlesByArticleIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/${queryArg.articleId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["articles", "Teacher/Article"],
      }),
      getTeacherArticlesByArticleIdComments: build.query<
        GetTeacherArticlesByArticleIdCommentsApiResponse,
        GetTeacherArticlesByArticleIdCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/${queryArg.articleId}/comments`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Teacher/Article"],
      }),
      postTeacherArticlesByArticleIdComments: build.mutation<
        PostTeacherArticlesByArticleIdCommentsApiResponse,
        PostTeacherArticlesByArticleIdCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/${queryArg.articleId}/comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["articles", "Teacher/Article"],
      }),
      getTeacherArticlesByArticleIdFeedbacks: build.query<
        GetTeacherArticlesByArticleIdFeedbacksApiResponse,
        GetTeacherArticlesByArticleIdFeedbacksApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/${queryArg.articleId}/feedbacks`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Teacher/Article"],
      }),
      postTeacherArticlesByArticleIdFeedbacks: build.mutation<
        PostTeacherArticlesByArticleIdFeedbacksApiResponse,
        PostTeacherArticlesByArticleIdFeedbacksApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/${queryArg.articleId}/feedbacks`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["articles", "Teacher/Article"],
      }),
      deleteTeacherArticlesByArticleIdFeedbacksAndFeedbackId: build.mutation<
        DeleteTeacherArticlesByArticleIdFeedbacksAndFeedbackIdApiResponse,
        DeleteTeacherArticlesByArticleIdFeedbacksAndFeedbackIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/${queryArg.articleId}/feedbacks/${queryArg.feedbackId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/Article"],
      }),
      putArticleImageAsTeacher: build.mutation<
        PutArticleImageAsTeacherApiResponse,
        PutArticleImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/${queryArg.articleId}/article_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/ArticleImages"],
      }),
      getTeacherArticleCommentsStudentCommentsList: build.query<
        GetTeacherArticleCommentsStudentCommentsListApiResponse,
        GetTeacherArticleCommentsStudentCommentsListApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/article_comments/student_comments_list`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Teacher/ArticleComment"],
      }),
      deleteTeacherArticlesByArticleIdArticlesCompaniesAndId: build.mutation<
        DeleteTeacherArticlesByArticleIdArticlesCompaniesAndIdApiResponse,
        DeleteTeacherArticlesByArticleIdArticlesCompaniesAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/articles/${queryArg.articleId}/articles_companies/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/Article"],
      }),
      postTeacherUserTaggings: build.mutation<
        PostTeacherUserTaggingsApiResponse,
        PostTeacherUserTaggingsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_taggings`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/UserTagging"],
      }),
      deleteTeacherUserTaggings: build.mutation<
        DeleteTeacherUserTaggingsApiResponse,
        DeleteTeacherUserTaggingsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_taggings`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/UserTagging"],
      }),
      teacherUserTaggingBatchCreate: build.mutation<
        TeacherUserTaggingBatchCreateApiResponse,
        TeacherUserTaggingBatchCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_taggings/batch_create`,
          method: "POST",
          body: queryArg.userTaggingBatchCreateParams,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/UserTagging"],
      }),
      getTeacherUserTagTypes: build.query<
        GetTeacherUserTagTypesApiResponse,
        GetTeacherUserTagTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tag_types`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            kind: queryArg.kind,
            role_type: queryArg.roleType,
          },
        }),
        providesTags: ["Teacher/UserTagType"],
      }),
      postTeacherUserTagTypes: build.mutation<
        PostTeacherUserTagTypesApiResponse,
        PostTeacherUserTagTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tag_types`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/UserTagType"],
      }),
      putTeacherUserTagTypesById: build.mutation<
        PutTeacherUserTagTypesByIdApiResponse,
        PutTeacherUserTagTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tag_types/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/UserTagType"],
      }),
      deleteTeacherUserTagTypesById: build.mutation<
        DeleteTeacherUserTagTypesByIdApiResponse,
        DeleteTeacherUserTagTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tag_types/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/UserTagType"],
      }),
      getTeacherUserTagsByUserTagIdUsers: build.query<
        GetTeacherUserTagsByUserTagIdUsersApiResponse,
        GetTeacherUserTagsByUserTagIdUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tags/${queryArg.userTagId}/users`,
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Teacher/UserTag/User"],
      }),
      getTeacherUserTagsByIdDistributedStudents: build.query<
        GetTeacherUserTagsByIdDistributedStudentsApiResponse,
        GetTeacherUserTagsByIdDistributedStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tags/${queryArg.id}/distributed_students`,
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Teacher/UserTag/Student"],
      }),
      putTeacherUserTagsSequence: build.mutation<
        PutTeacherUserTagsSequenceApiResponse,
        PutTeacherUserTagsSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tags/sequence`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/UserTag"],
      }),
      teacherUserTagsBatchCreate: build.mutation<
        TeacherUserTagsBatchCreateApiResponse,
        TeacherUserTagsBatchCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tags/batch_create`,
          method: "PATCH",
          body: queryArg.userTagsBatchCreateParams,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/UserTags"],
      }),
      getTeacherHomeworkTemplates: build.query<
        GetTeacherHomeworkTemplatesApiResponse,
        GetTeacherHomeworkTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            common_tag_name: queryArg.commonTagName,
          },
        }),
        providesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      createHomeworkTemplateAsTeacher: build.mutation<
        CreateHomeworkTemplateAsTeacherApiResponse,
        CreateHomeworkTemplateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates`,
          method: "POST",
          body: queryArg.homeworkTemplateCreateRequest,
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      fetchHomeworkTemplateStatsAsTeacher: build.query<
        FetchHomeworkTemplateStatsAsTeacherApiResponse,
        FetchHomeworkTemplateStatsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/stats`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: {
            limit: queryArg.limit,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      getHomeworkTemplateDetailListAsTeacher: build.query<
        GetHomeworkTemplateDetailListAsTeacherApiResponse,
        GetHomeworkTemplateDetailListAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/detail_list`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: { homework_template_ids: queryArg.homeworkTemplateIds },
        }),
        providesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      getTeacherHomeworkTemplatesByHomeworkTemplateId: build.query<
        GetTeacherHomeworkTemplatesByHomeworkTemplateIdApiResponse,
        GetTeacherHomeworkTemplatesByHomeworkTemplateIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}`,
        }),
        providesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      updateHomeworkTemplateAsTeacher: build.mutation<
        UpdateHomeworkTemplateAsTeacherApiResponse,
        UpdateHomeworkTemplateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}`,
          method: "PUT",
          body: queryArg.homeworkTemplateUpdateRequest,
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      deleteHomeworkTemplateAsTeacher: build.mutation<
        DeleteHomeworkTemplateAsTeacherApiResponse,
        DeleteHomeworkTemplateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Owner/HomeworkTemplate"],
      }),
      getTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFields:
        build.query<
          GetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse,
          GetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg
        >({
          query: (queryArg) => ({
            url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: ["Teacher/HomeworkTemplateCustomFields"],
        }),
      postTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFields:
        build.mutation<
          PostTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse,
          PostTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg
        >({
          query: (queryArg) => ({
            url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields`,
            method: "POST",
            body: queryArg.body,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["Teacher/HomeworkTemplateCustomFields"],
        }),
      getTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndId:
        build.query<
          GetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse,
          GetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg
        >({
          query: (queryArg) => ({
            url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/${queryArg.id}`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          providesTags: ["Teacher/HomeworkTemplateCustomFields"],
        }),
      putTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndId:
        build.mutation<
          PutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse,
          PutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg
        >({
          query: (queryArg) => ({
            url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/${queryArg.id}`,
            method: "PUT",
            body: queryArg.body,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["Teacher/HomeworkTemplateCustomFields"],
        }),
      deleteTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndId:
        build.mutation<
          DeleteTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse,
          DeleteTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg
        >({
          query: (queryArg) => ({
            url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/${queryArg.id}`,
            method: "DELETE",
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["Teacher/HomeworkTemplateCustomFields"],
        }),
      postHomeworkTemplateDuplicateAsTeacher: build.mutation<
        PostHomeworkTemplateDuplicateAsTeacherApiResponse,
        PostHomeworkTemplateDuplicateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.id}/duplicate`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/HomeworkTemplate", "HomeworkTemplate"],
      }),
      getHomeworkTemplateAnswersAsTeacher: build.query<
        GetHomeworkTemplateAnswersAsTeacherApiResponse,
        GetHomeworkTemplateAnswersAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.id}/answers`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/HomeworkTemplate", "HomeworkTemplate"],
      }),
      updateHomeworkTemplateDistributionAsTeacher: build.mutation<
        UpdateHomeworkTemplateDistributionAsTeacherApiResponse,
        UpdateHomeworkTemplateDistributionAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_template_distributions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: [
          "HomeworkTemplateDistribution",
          "Teacher/HomeworkTemplateDistribution",
        ],
      }),
      assignManagerToHomeworkTemplateDistributionAsTeacher: build.mutation<
        AssignManagerToHomeworkTemplateDistributionAsTeacherApiResponse,
        AssignManagerToHomeworkTemplateDistributionAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_template_distributions/${queryArg.id}/assign_manager`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/HomeworkTemplateDistribution",
        ],
      }),
      submitHomeworkTemplateDistributionToReviewerAsTeacher: build.mutation<
        SubmitHomeworkTemplateDistributionToReviewerAsTeacherApiResponse,
        SubmitHomeworkTemplateDistributionToReviewerAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_template_distributions/${queryArg.id}/submit_to_reviewer`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/HomeworkTemplateDistribution",
        ],
      }),
      revertHomeworkTemplateDistributionSubmittedToReviewerAsTeacher:
        build.mutation<
          RevertHomeworkTemplateDistributionSubmittedToReviewerAsTeacherApiResponse,
          RevertHomeworkTemplateDistributionSubmittedToReviewerAsTeacherApiArg
        >({
          query: (queryArg) => ({
            url: `/teacher/homework_template_distributions/${queryArg.id}/revert_submission_to_reviewer`,
            method: "PUT",
          }),
          invalidatesTags: [
            "Teacher/HomeworkTemplate",
            "Teacher/HomeworkTemplateDistribution",
          ],
        }),
      returnHomeworkTemplateDistributionToStudentsAsTeacher: build.mutation<
        ReturnHomeworkTemplateDistributionToStudentsAsTeacherApiResponse,
        ReturnHomeworkTemplateDistributionToStudentsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_template_distributions/${queryArg.id}/return_to_students`,
          method: "PUT",
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/HomeworkTemplateDistribution",
        ],
      }),
      fetchHomeworkTemplateChunksAsTeacher: build.query<
        FetchHomeworkTemplateChunksAsTeacherApiResponse,
        FetchHomeworkTemplateChunksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.templateId}/homework_template_chunks`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      createHomeworkTemplateChunkAsTeacher: build.mutation<
        CreateHomeworkTemplateChunkAsTeacherApiResponse,
        CreateHomeworkTemplateChunkAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.templateId}/homework_template_chunks`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: [
          "Teacher/HomeworkTemplate",
          "Teacher/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      deleteHomeworkTemplateChunkAsTeacher: build.mutation<
        DeleteHomeworkTemplateChunkAsTeacherApiResponse,
        DeleteHomeworkTemplateChunkAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.templateId}/homework_template_chunks/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/HomeworkTemplateChunk"],
      }),
      postHomeworkTemplateImageAsTeacher: build.mutation<
        PostHomeworkTemplateImageAsTeacherApiResponse,
        PostHomeworkTemplateImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}/homework_template_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/HomeworkTemplateImage"],
      }),
      putHomeworkTemplateImageAsTeacher: build.mutation<
        PutHomeworkTemplateImageAsTeacherApiResponse,
        PutHomeworkTemplateImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}/homework_template_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/HomeworkTemplateImage"],
      }),
      deleteHomeworkTemplateImageAsTeacher: build.mutation<
        DeleteHomeworkTemplateImageAsTeacherApiResponse,
        DeleteHomeworkTemplateImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_templates/${queryArg.homeworkTemplateId}/homework_template_images/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/HomeworkTemplateImage"],
      }),
      getTeacherUserTags: build.query<
        GetTeacherUserTagsApiResponse,
        GetTeacherUserTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tags`,
          params: {
            kind: queryArg.kind,
            page: queryArg.page,
            per_page: queryArg.perPage,
            role_type: queryArg.roleType,
          },
        }),
        providesTags: ["Teacher/UserTag"],
      }),
      postTeacherUserTags: build.mutation<
        PostTeacherUserTagsApiResponse,
        PostTeacherUserTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tags`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/UserTag"],
      }),
      getTeacherUserTagsById: build.query<
        GetTeacherUserTagsByIdApiResponse,
        GetTeacherUserTagsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/teacher/user_tags/${queryArg.id}` }),
        providesTags: ["Teacher/UserTag"],
      }),
      putTeacherUserTagsById: build.mutation<
        PutTeacherUserTagsByIdApiResponse,
        PutTeacherUserTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tags/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/UserTag"],
      }),
      deleteTeacherUserTagsById: build.mutation<
        DeleteTeacherUserTagsByIdApiResponse,
        DeleteTeacherUserTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/user_tags/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/UserTag"],
      }),
      getTeacherPblReportTemplates: build.query<
        GetTeacherPblReportTemplatesApiResponse,
        GetTeacherPblReportTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/templates`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/PblReportTemplate"],
      }),
      postTeacherPblReportTemplates: build.mutation<
        PostTeacherPblReportTemplatesApiResponse,
        PostTeacherPblReportTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/templates`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblReportTemplate"],
      }),
      fetchPblReportTemplateStatsAsTeacher: build.query<
        FetchPblReportTemplateStatsAsTeacherApiResponse,
        FetchPblReportTemplateStatsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/templates/stats`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: {
            limit: queryArg.limit,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: ["Teacher/PblReportTemplate"],
      }),
      fetchPblReportTemplateAsTeacher: build.query<
        FetchPblReportTemplateAsTeacherApiResponse,
        FetchPblReportTemplateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/templates/${queryArg.id}`,
        }),
        providesTags: ["Teacher/PblReportTemplate"],
      }),
      putTeacherPblReportTemplatesById: build.mutation<
        PutTeacherPblReportTemplatesByIdApiResponse,
        PutTeacherPblReportTemplatesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/templates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblReportTemplate"],
      }),
      getTeacherPblReportTemplatesByIdAnswers: build.query<
        GetTeacherPblReportTemplatesByIdAnswersApiResponse,
        GetTeacherPblReportTemplatesByIdAnswersApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/templates/${queryArg.id}/answers`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: ["Teacher/PblReportTemplate"],
      }),
      getTeacherPblReportTemplatesByIdItems: build.query<
        GetTeacherPblReportTemplatesByIdItemsApiResponse,
        GetTeacherPblReportTemplatesByIdItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/templates/${queryArg.id}/items`,
        }),
        providesTags: ["Teacher/PblReportTemplate"],
      }),
      getTeacherPblReportTemplatesFrameworks: build.query<
        GetTeacherPblReportTemplatesFrameworksApiResponse,
        GetTeacherPblReportTemplatesFrameworksApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/templates/frameworks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/PblReportTemplate"],
      }),
      getTeacherPblReportAnswersById: build.query<
        GetTeacherPblReportAnswersByIdApiResponse,
        GetTeacherPblReportAnswersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/answers/${queryArg.id}`,
        }),
        providesTags: ["Teacher/PblReportAnswer"],
      }),
      getTeacherPblReportAnswers: build.query<
        GetTeacherPblReportAnswersApiResponse,
        GetTeacherPblReportAnswersApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/answers`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/PblReportAnswer"],
      }),
      postTeacherPblReportAnswers: build.mutation<
        PostTeacherPblReportAnswersApiResponse,
        PostTeacherPblReportAnswersApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/answers`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblReportAnswer"],
      }),
      getTeacherPblReportComments: build.query<
        GetTeacherPblReportCommentsApiResponse,
        GetTeacherPblReportCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/comments`,
          params: {
            output_id: queryArg.outputId,
            output_type: queryArg.outputType,
          },
        }),
        providesTags: ["Teacher/PblReportComment"],
      }),
      postTeacherPblReportComments: build.mutation<
        PostTeacherPblReportCommentsApiResponse,
        PostTeacherPblReportCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/comments`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblReportComment"],
      }),
      putTeacherPblReportCommentsById: build.mutation<
        PutTeacherPblReportCommentsByIdApiResponse,
        PutTeacherPblReportCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblReportComment"],
      }),
      getTeacherPblReportItems: build.query<
        GetTeacherPblReportItemsApiResponse,
        GetTeacherPblReportItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/items`,
          params: { template_id: queryArg.templateId },
        }),
        providesTags: ["Teacher/PblReportItem"],
      }),
      postTeacherPblReportItems: build.mutation<
        PostTeacherPblReportItemsApiResponse,
        PostTeacherPblReportItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/items`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblReportItem"],
      }),
      putTeacherPblReportItemsById: build.mutation<
        PutTeacherPblReportItemsByIdApiResponse,
        PutTeacherPblReportItemsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblReportItem"],
      }),
      deleteTeacherPblReportItemsById: build.mutation<
        DeleteTeacherPblReportItemsByIdApiResponse,
        DeleteTeacherPblReportItemsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/report/items/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/PblReportItem"],
      }),
      fetchPblCardBookmarksAsTeacher: build.query<
        FetchPblCardBookmarksAsTeacherApiResponse,
        FetchPblCardBookmarksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/bookmarks`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            student_id: queryArg.studentId,
            group_affiliate_id: queryArg.groupAffiliateId,
            search_word: queryArg.searchWord,
            search_type: queryArg.searchType,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            user_tag_id: queryArg.userTagId,
            search_source_type: queryArg.searchSourceType,
            free_word: queryArg.freeWord,
            user_name: queryArg.userName,
            student_number: queryArg.studentNumber,
            search_column: queryArg.searchColumn,
            search_order: queryArg.searchOrder,
            common_tag_name: queryArg.commonTagName,
          },
        }),
        providesTags: ["Teacher/PblCardsInformationLiteracyBookmark"],
      }),
      postPblCardBookmarkAsTeacher: build.mutation<
        PostPblCardBookmarkAsTeacherApiResponse,
        PostPblCardBookmarkAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/bookmarks`,
          method: "POST",
          body: queryArg.postPblCardBookmarkRequestBody,
        }),
        invalidatesTags: ["Teacher/PblCardsInformationLiteracyBookmark"],
      }),
      fetchPblCardBookmarkAsTeacher: build.query<
        FetchPblCardBookmarkAsTeacherApiResponse,
        FetchPblCardBookmarkAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/bookmarks/${queryArg.id}`,
        }),
        providesTags: ["Teacher/PblCardsInformationLiteracyBookmark"],
      }),
      putPblCardBookmarkAsTeacher: build.mutation<
        PutPblCardBookmarkAsTeacherApiResponse,
        PutPblCardBookmarkAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/bookmarks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putPblCardBookmarkRequestBody,
        }),
        invalidatesTags: ["Teacher/PblCardsInformationLiteracyBookmark"],
      }),
      deletePblCardBookmarkAsTeacher: build.mutation<
        DeletePblCardBookmarkAsTeacherApiResponse,
        DeletePblCardBookmarkAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/bookmarks/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/PblCardsInformationLiteracyBookmark"],
      }),
      putPblCardBookmarkWysiwygBodyAsTeacher: build.mutation<
        PutPblCardBookmarkWysiwygBodyAsTeacherApiResponse,
        PutPblCardBookmarkWysiwygBodyAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/bookmarks/${queryArg.id}/update_wysiwyg_body`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblCardsInformationLiteracyBookmark"],
      }),
      fetchPblCardBookmarkStatsAsTeacher: build.query<
        FetchPblCardBookmarkStatsAsTeacherApiResponse,
        FetchPblCardBookmarkStatsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/bookmarks/stats`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: { group_affiliate_id: queryArg.groupAffiliateId },
        }),
        providesTags: ["Teacher/PblCardsInformationLiteracyBookmark"],
      }),
      fetchPblCardBookmarkCommentsAsTeacher: build.query<
        FetchPblCardBookmarkCommentsAsTeacherApiResponse,
        FetchPblCardBookmarkCommentsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/comments`,
          params: {
            bookmark_id: queryArg.bookmarkId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Teacher/PblCardsInformationLiteracyComment"],
      }),
      createPblCardBookmarkCommentAsTeacher: build.mutation<
        CreatePblCardBookmarkCommentAsTeacherApiResponse,
        CreatePblCardBookmarkCommentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/comments`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblCardsInformationLiteracyComment"],
      }),
      updatePblCardBookmarkCommentAsTeacher: build.mutation<
        UpdatePblCardBookmarkCommentAsTeacherApiResponse,
        UpdatePblCardBookmarkCommentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/PblCardsInformationLiteracyComment"],
      }),
      deletePblCardBookmarkCommentAsTeacher: build.mutation<
        DeletePblCardBookmarkCommentAsTeacherApiResponse,
        DeletePblCardBookmarkCommentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/comments/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/PblCardsInformationLiteracyComment"],
      }),
      deletePblCardBookmarkImageAsTeacher: build.mutation<
        DeletePblCardBookmarkImageAsTeacherApiResponse,
        DeletePblCardBookmarkImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/cards/information_literacy/bookmark_images/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/PblCardsInformationLiteracyBookmarkImage"],
      }),
      getPblCardBookmarkStampsAsTeacher: build.query<
        GetPblCardBookmarkStampsAsTeacherApiResponse,
        GetPblCardBookmarkStampsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/stamps`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            stampable_type: queryArg.stampableType,
            stampable_id: queryArg.stampableId,
            kind: queryArg.kind,
          },
        }),
        providesTags: ["Teacher/Stamp"],
      }),
      postPblCardBookmarkStampAsTeacher: build.mutation<
        PostPblCardBookmarkStampAsTeacherApiResponse,
        PostPblCardBookmarkStampAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/stamps`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Stamp"],
      }),
      putPblCardBookmarkStampAsTeacher: build.mutation<
        PutPblCardBookmarkStampAsTeacherApiResponse,
        PutPblCardBookmarkStampAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/stamps/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Teacher/Stamp"],
      }),
      deletePblCardBookmarkStampAsTeacher: build.mutation<
        DeletePblCardBookmarkStampAsTeacherApiResponse,
        DeletePblCardBookmarkStampAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/stamps/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/Stamp"],
      }),
      getTeacherMaterialDistributionHistoriesList: build.query<
        GetTeacherMaterialDistributionHistoriesListApiResponse,
        GetTeacherMaterialDistributionHistoriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/material_distribution_histories/list`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/MaterialDistributionHistory"],
      }),
      getTeacherMaterialDistributionHistoriesListStudents: build.query<
        GetTeacherMaterialDistributionHistoriesListStudentsApiResponse,
        GetTeacherMaterialDistributionHistoriesListStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/material_distribution_histories/list_students`,
          params: {
            distribution_id: queryArg.distributionId,
            material_id: queryArg.materialId,
          },
        }),
        providesTags: ["Teacher/MaterialDistributionHistory"],
      }),
      getTeacherMaterialDistributionHistoriesSearchByUserId: build.query<
        GetTeacherMaterialDistributionHistoriesSearchByUserIdApiResponse,
        GetTeacherMaterialDistributionHistoriesSearchByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/material_distribution_histories/search_by_user_id`,
          params: {
            user_id: queryArg.userId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Teacher/MaterialDistributionHistory"],
      }),
      getSteamContentsAsTeacher: build.query<
        GetSteamContentsAsTeacherApiResponse,
        GetSteamContentsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/contents`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            is_bookmarked: queryArg.isBookmarked,
            is_recommended: queryArg.isRecommended,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Teacher/Steam/Contents"],
      }),
      getSteamContentAsTeacher: build.query<
        GetSteamContentAsTeacherApiResponse,
        GetSteamContentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/contents/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Steam/Contents"],
      }),
      searchTeacherSteamContents: build.query<
        SearchTeacherSteamContentsApiResponse,
        SearchTeacherSteamContentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/contents/search`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            difficulty: queryArg.difficulty,
            aspect: queryArg.aspect,
            is_scientific: queryArg.isScientific,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Teacher/Steam/Contents"],
      }),
      getTeacherSteamComments: build.query<
        GetTeacherSteamCommentsApiResponse,
        GetTeacherSteamCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/comments`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            content_title: queryArg.contentTitle,
            lecture_title: queryArg.lectureTitle,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Teacher/Steam/Comments"],
      }),
      postTeacherSteamComments: build.mutation<
        PostTeacherSteamCommentsApiResponse,
        PostTeacherSteamCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { lecture_id: queryArg.lectureId },
        }),
        invalidatesTags: ["Teacher/Steam/Comments"],
      }),
      putTeacherSteamContentsId: build.mutation<
        PutTeacherSteamContentsIdApiResponse,
        PutTeacherSteamContentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Steam/Comments"],
      }),
      deleteTeacherSteamContentsId: build.mutation<
        DeleteTeacherSteamContentsIdApiResponse,
        DeleteTeacherSteamContentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Steam/Comments"],
      }),
      getSteamLecturesAsTeacher: build.query<
        GetSteamLecturesAsTeacherApiResponse,
        GetSteamLecturesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/lectures`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            sl_content_id: queryArg.slContentId,
          },
        }),
        providesTags: ["Teacher/Steam/Lectures"],
      }),
      postTeacherSteamLectures: build.mutation<
        PostTeacherSteamLecturesApiResponse,
        PostTeacherSteamLecturesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/lectures`,
          method: "POST",
          body: queryArg.createSteamLectureRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { sl_content_id: queryArg.slContentId },
        }),
        invalidatesTags: ["Teacher/Steam/Lectures"],
      }),
      getTeacherSteamLecturesId: build.query<
        GetTeacherSteamLecturesIdApiResponse,
        GetTeacherSteamLecturesIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/lectures/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Steam/Lectures"],
      }),
      getTeacherSteamLectureComments: build.query<
        GetTeacherSteamLectureCommentsApiResponse,
        GetTeacherSteamLectureCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/lectures/${queryArg.id}/comments`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Steam/Lectures"],
      }),
      getSteamContentBookmarksAsTeacher: build.query<
        GetSteamContentBookmarksAsTeacherApiResponse,
        GetSteamContentBookmarksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/content_bookmarks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Steam/ContentBookmarks"],
      }),
      postSteamContentBookmarksAsTeacher: build.mutation<
        PostSteamContentBookmarksAsTeacherApiResponse,
        PostSteamContentBookmarksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/content_bookmarks`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Steam/ContentBookmarks"],
      }),
      deleteSteamContentBookmarksAsTeacher: build.mutation<
        DeleteSteamContentBookmarksAsTeacherApiResponse,
        DeleteSteamContentBookmarksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/content_bookmarks/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Steam/ContentBookmarks"],
      }),
      getPblSteamStudentContentsAsTeacher: build.query<
        GetPblSteamStudentContentsAsTeacherApiResponse,
        GetPblSteamStudentContentsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/student_contents`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Steam/StudentContents"],
      }),
      postPblSteamStudentContentAsTeacher: build.mutation<
        PostPblSteamStudentContentAsTeacherApiResponse,
        PostPblSteamStudentContentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/student_contents`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Steam/StudentContents"],
      }),
      putPblSteamStudentContentAsTeacher: build.mutation<
        PutPblSteamStudentContentAsTeacherApiResponse,
        PutPblSteamStudentContentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/student_contents/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Steam/StudentContents"],
      }),
      deletePblSteamStudentContentAsTeacher: build.mutation<
        DeletePblSteamStudentContentAsTeacherApiResponse,
        DeletePblSteamStudentContentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/student_contents/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Steam/StudentContents"],
      }),
      getSteamContentRecommendationsAsTeacher: build.query<
        GetSteamContentRecommendationsAsTeacherApiResponse,
        GetSteamContentRecommendationsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/content_recommendations`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Steam/ContentRecommendations"],
      }),
      postSteamContentRecommendationAsTeacher: build.mutation<
        PostSteamContentRecommendationAsTeacherApiResponse,
        PostSteamContentRecommendationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/content_recommendations`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Steam/ContentRecommendations"],
      }),
      deleteSteamContentRecommendationAsTeacher: build.mutation<
        DeleteSteamContentRecommendationAsTeacherApiResponse,
        DeleteSteamContentRecommendationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/steam/content_recommendations/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Steam/ContentRecommendations"],
      }),
      getTeacherPlanItems: build.query<
        GetTeacherPlanItemsApiResponse,
        GetTeacherPlanItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            status: queryArg.status,
          },
        }),
        providesTags: ["Teacher/Plan/Items"],
      }),
      postTeacherPlanItems: build.mutation<
        PostTeacherPlanItemsApiResponse,
        PostTeacherPlanItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Items"],
      }),
      getTeacherPlanItemsId: build.query<
        GetTeacherPlanItemsIdApiResponse,
        GetTeacherPlanItemsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Plan/Items"],
      }),
      putTeacherPlanItemsId: build.mutation<
        PutTeacherPlanItemsIdApiResponse,
        PutTeacherPlanItemsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Items"],
      }),
      deleteTeacherPlanItemsId: build.mutation<
        DeleteTeacherPlanItemsIdApiResponse,
        DeleteTeacherPlanItemsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Items"],
      }),
      getTeacherGetClassDateList: build.query<
        GetTeacherGetClassDateListApiResponse,
        GetTeacherGetClassDateListApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/get_class_date_list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            day_of_weeks: queryArg.dayOfWeeks,
          },
        }),
        providesTags: ["Teacher/Plan/Items"],
      }),
      getTeacherPlanClasses: build.query<
        GetTeacherPlanClassesApiResponse,
        GetTeacherPlanClassesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/classes`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            status: queryArg.status,
          },
        }),
        providesTags: ["Teacher/Plan/Classes"],
      }),
      postTeacherPlanClasses: build.mutation<
        PostTeacherPlanClassesApiResponse,
        PostTeacherPlanClassesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/classes`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Classes"],
      }),
      putTeacherPlanClassesId: build.mutation<
        PutTeacherPlanClassesIdApiResponse,
        PutTeacherPlanClassesIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/classes/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Classes"],
      }),
      deleteTeacherPlanClassesId: build.mutation<
        DeleteTeacherPlanClassesIdApiResponse,
        DeleteTeacherPlanClassesIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/classes/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Classes"],
      }),
      getPblPlanContentsAsTeacher: build.query<
        GetPblPlanContentsAsTeacherApiResponse,
        GetPblPlanContentsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/classes/${queryArg.classId}/contents`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Plan/Classes"],
      }),
      postPblPlanContentsAsTeacher: build.mutation<
        PostPblPlanContentsAsTeacherApiResponse,
        PostPblPlanContentsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/classes/${queryArg.classId}/contents`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Contents"],
      }),
      deletePblPlanContentsAsTeacher: build.mutation<
        DeletePblPlanContentsAsTeacherApiResponse,
        DeletePblPlanContentsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/classes/${queryArg.classId}/contents/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Contents"],
      }),
      postTeacherPlanDays: build.mutation<
        PostTeacherPlanDaysApiResponse,
        PostTeacherPlanDaysApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/days`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Days"],
      }),
      putTeacherPlanDaysId: build.mutation<
        PutTeacherPlanDaysIdApiResponse,
        PutTeacherPlanDaysIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/days/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Days"],
      }),
      deleteTeacherPlanDaysId: build.mutation<
        DeleteTeacherPlanDaysIdApiResponse,
        DeleteTeacherPlanDaysIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/days/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Days"],
      }),
      getTeacherPlanStudents: build.query<
        GetTeacherPlanStudentsApiResponse,
        GetTeacherPlanStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/students`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: ["Teacher/Plan/Students"],
      }),
      postTeacherPlanStudents: build.mutation<
        PostTeacherPlanStudentsApiResponse,
        PostTeacherPlanStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/students`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Students"],
      }),
      putTeacherPlanStudentsId: build.mutation<
        PutTeacherPlanStudentsIdApiResponse,
        PutTeacherPlanStudentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/students/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Students"],
      }),
      deleteTeacherPlanStudentsId: build.mutation<
        DeleteTeacherPlanStudentsIdApiResponse,
        DeleteTeacherPlanStudentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/plan/items/${queryArg.itemId}/students/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Plan/Students"],
      }),
      getTeacherInquiries: build.query<
        GetTeacherInquiriesApiResponse,
        GetTeacherInquiriesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/inquiries`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Inquiries"],
      }),
      getTeacherInquiriesById: build.query<
        GetTeacherInquiriesByIdApiResponse,
        GetTeacherInquiriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/inquiries/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Inquiries"],
      }),
      getTheNumberOfNewCommentInquiriesAsTeacher: build.query<
        GetTheNumberOfNewCommentInquiriesAsTeacherApiResponse,
        GetTheNumberOfNewCommentInquiriesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/inquiries/new_comment_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Inquiries"],
      }),
      postTeacherInquiryComments: build.mutation<
        PostTeacherInquiryCommentsApiResponse,
        PostTeacherInquiryCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/inquiry_comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Teacher/InquiryComments"],
      }),
      putTeacherInquiryCommentsById: build.mutation<
        PutTeacherInquiryCommentsByIdApiResponse,
        PutTeacherInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/inquiry_comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Teacher/InquiryComments"],
      }),
      deleteTeacherInquiryCommentsById: build.mutation<
        DeleteTeacherInquiryCommentsByIdApiResponse,
        DeleteTeacherInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/inquiry_comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Teacher/InquiryComments"],
      }),
      getTeacherSponsorInfos: build.query<
        GetTeacherSponsorInfosApiResponse,
        GetTeacherSponsorInfosApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/infos`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Teacher/Sponsor/Infos"],
      }),
      getTeacherSponsorInfosId: build.query<
        GetTeacherSponsorInfosIdApiResponse,
        GetTeacherSponsorInfosIdApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/infos/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Sponsor/Infos"],
      }),
      fetchSponsorProjectsAsTeacher: build.query<
        FetchSponsorProjectsAsTeacherApiResponse,
        FetchSponsorProjectsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/projects`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            bookmarked: queryArg.bookmarked,
            recommended: queryArg.recommended,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Teacher/Sponsor/Projects"],
      }),
      fetchOneSponsorProjectAsTeacher: build.query<
        FetchOneSponsorProjectAsTeacherApiResponse,
        FetchOneSponsorProjectAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/projects/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Sponsor/Projects"],
      }),
      fetchSponsorProjectsForCarouselAsTeacher: build.query<
        FetchSponsorProjectsForCarouselAsTeacherApiResponse,
        FetchSponsorProjectsForCarouselAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/projects/carousel`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            number_of_projects: queryArg.numberOfProjects,
            sort_type: queryArg.sortType,
          },
        }),
        providesTags: ["Teacher/Sponsor/Projects"],
      }),
      fetchSponsorProjectListAsTeacher: build.query<
        FetchSponsorProjectListAsTeacherApiResponse,
        FetchSponsorProjectListAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/projects/list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            professor_affiliate_id: queryArg.professorAffiliateId,
          },
        }),
        providesTags: ["Teacher/Sponsor/Projects"],
      }),
      fetchSponsorLecturesAsTeacher: build.query<
        FetchSponsorLecturesAsTeacherApiResponse,
        FetchSponsorLecturesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/projects/lectures`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            project_id: queryArg.projectId,
          },
        }),
        providesTags: ["Teacher/Sponsor/Lectures"],
      }),
      fetchSponsorLectureAsTeacher: build.query<
        FetchSponsorLectureAsTeacherApiResponse,
        FetchSponsorLectureAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/projects/lectures/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Sponsor/Lectures"],
      }),
      fetchSponsorCommentListAsTeacher: build.query<
        FetchSponsorCommentListAsTeacherApiResponse,
        FetchSponsorCommentListAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/projects/lectures/${queryArg.id}/comments`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Sponsor/CommentList"],
      }),
      fetchSponsorProjectBookmarksAsTeacher: build.query<
        FetchSponsorProjectBookmarksAsTeacherApiResponse,
        FetchSponsorProjectBookmarksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/bookmarks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Sponsor/Bookmarks"],
      }),
      addSponsorProjectBookmarkAsTeacher: build.mutation<
        AddSponsorProjectBookmarkAsTeacherApiResponse,
        AddSponsorProjectBookmarkAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/bookmarks`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Sponsor/Bookmarks"],
      }),
      fetchSponsorProjectBookmarkByProjectAsTeacher: build.query<
        FetchSponsorProjectBookmarkByProjectAsTeacherApiResponse,
        FetchSponsorProjectBookmarkByProjectAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/bookmarks/fetch_by_project`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { project_id: queryArg.projectId },
        }),
        providesTags: ["Teacher/Sponsor/Bookmarks"],
      }),
      removeSponsorProjectBookmarkAsTeacher: build.mutation<
        RemoveSponsorProjectBookmarkAsTeacherApiResponse,
        RemoveSponsorProjectBookmarkAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/bookmarks/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Sponsor/Bookmarks"],
      }),
      fetchSponsorProjectRecommendsAsTeacher: build.query<
        FetchSponsorProjectRecommendsAsTeacherApiResponse,
        FetchSponsorProjectRecommendsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/recommends`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Sponsor/Recommends"],
      }),
      addSponsorProjectRecommendAsTeacher: build.mutation<
        AddSponsorProjectRecommendAsTeacherApiResponse,
        AddSponsorProjectRecommendAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/recommends`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Sponsor/Recommends"],
      }),
      removeSponsorProjectRecommendAsTeacher: build.mutation<
        RemoveSponsorProjectRecommendAsTeacherApiResponse,
        RemoveSponsorProjectRecommendAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/recommends/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Sponsor/Recommends"],
      }),
      fetchSponsorCommentsAsTeacher: build.query<
        FetchSponsorCommentsAsTeacherApiResponse,
        FetchSponsorCommentsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/comments`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Sponsor/Comments"],
      }),
      postSponsorCommentsAsTeacher: build.mutation<
        PostSponsorCommentsAsTeacherApiResponse,
        PostSponsorCommentsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { lecture_id: queryArg.lectureId },
        }),
        invalidatesTags: ["Teacher/Sponsor/Comments"],
      }),
      deleteTeacherSponsorComments: build.mutation<
        DeleteTeacherSponsorCommentsApiResponse,
        DeleteTeacherSponsorCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/comments`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        invalidatesTags: ["Teacher/Sponsor/Comments"],
      }),
      putPblSponsorCommentAsTeacher: build.mutation<
        PutPblSponsorCommentAsTeacherApiResponse,
        PutPblSponsorCommentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Pbl/Sponsor/Comment"],
      }),
      deletePblSponsorCommentAsTeacher: build.mutation<
        DeletePblSponsorCommentAsTeacherApiResponse,
        DeletePblSponsorCommentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/sponsor/comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Pbl/Sponsor/Comment"],
      }),
      getTeacherContentCompanies: build.query<
        GetTeacherContentCompaniesApiResponse,
        GetTeacherContentCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/content_companies`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/ContentCompanies"],
      }),
      getSchedulesAsTeacher: build.query<
        GetSchedulesAsTeacherApiResponse,
        GetSchedulesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/schedules`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { limit: queryArg.limit },
        }),
        providesTags: ["Teacher/Schedules"],
      }),
      getStudentLogsStudyLogsAsTeacher: build.query<
        GetStudentLogsStudyLogsAsTeacherApiResponse,
        GetStudentLogsStudyLogsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/study_logs`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsStudyByStudentAsTeacher: build.query<
        GetStudentLogsStudyByStudentAsTeacherApiResponse,
        GetStudentLogsStudyByStudentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/study_by_students`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsStatsAsTeacher: build.query<
        GetStudentLogsStatsAsTeacherApiResponse,
        GetStudentLogsStatsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/stats`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            student_affiliate_id: queryArg.studentAffiliateId,
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsActivityAsTeacher: build.query<
        GetStudentLogsActivityAsTeacherApiResponse,
        GetStudentLogsActivityAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/activity`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { group_affiliate_id: queryArg.groupAffiliateId },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsDeliverableListAsTeacher: build.query<
        GetStudentLogsDeliverableListAsTeacherApiResponse,
        GetStudentLogsDeliverableListAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/deliverable_list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            group_affiliate_id: queryArg.groupAffiliateId,
            search: queryArg.search,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            sorted_by: queryArg.sortedBy,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsMaterialWorksAsTeacher: build.query<
        GetStudentLogsMaterialWorksAsTeacherApiResponse,
        GetStudentLogsMaterialWorksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/material_works`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            student_affiliate_id: queryArg.studentAffiliateId,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsHomeworksAsTeacher: build.query<
        GetStudentLogsHomeworksAsTeacherApiResponse,
        GetStudentLogsHomeworksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/homeworks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            student_affiliate_id: queryArg.studentAffiliateId,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsPblAnswersAsTeacher: build.query<
        GetStudentLogsPblAnswersAsTeacherApiResponse,
        GetStudentLogsPblAnswersAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/pbl_answers`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            student_affiliate_id: queryArg.studentAffiliateId,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsLearningPackagesAsTeacher: build.query<
        GetStudentLogsLearningPackagesAsTeacherApiResponse,
        GetStudentLogsLearningPackagesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/learning_packages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            student_affiliate_id: queryArg.studentAffiliateId,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsArticlesAsTeacher: build.query<
        GetStudentLogsArticlesAsTeacherApiResponse,
        GetStudentLogsArticlesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/articles`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            student_affiliate_id: queryArg.studentAffiliateId,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsIdealsAsTeacher: build.query<
        GetStudentLogsIdealsAsTeacherApiResponse,
        GetStudentLogsIdealsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/ideals`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            student_affiliate_id: queryArg.studentAffiliateId,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getStudentLogsBookmarksAsTeacher: build.query<
        GetStudentLogsBookmarksAsTeacherApiResponse,
        GetStudentLogsBookmarksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/student_logs/bookmarks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            student_affiliate_id: queryArg.studentAffiliateId,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Teacher/StudentLogs"],
      }),
      getRubricsAsTeacher: build.query<
        GetRubricsAsTeacherApiResponse,
        GetRubricsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Rubrics"],
      }),
      postRubricsAsTeacher: build.mutation<
        PostRubricsAsTeacherApiResponse,
        PostRubricsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Rubrics"],
      }),
      getRubricAsTeacher: build.query<
        GetRubricAsTeacherApiResponse,
        GetRubricAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Rubrics"],
      }),
      putRubricAsTeacher: build.mutation<
        PutRubricAsTeacherApiResponse,
        PutRubricAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Rubrics"],
      }),
      deleteRubricAsTeacher: build.mutation<
        DeleteRubricAsTeacherApiResponse,
        DeleteRubricAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Rubrics"],
      }),
      postRubricItemsAsTeacher: build.mutation<
        PostRubricItemsAsTeacherApiResponse,
        PostRubricItemsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_items`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricItems"],
      }),
      getRubricItemAsTeacher: build.query<
        GetRubricItemAsTeacherApiResponse,
        GetRubricItemAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_items/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/RubricItems"],
      }),
      putRubricItemAsTeacher: build.mutation<
        PutRubricItemAsTeacherApiResponse,
        PutRubricItemAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricItems"],
      }),
      deleteRubricItemAsTeacher: build.mutation<
        DeleteRubricItemAsTeacherApiResponse,
        DeleteRubricItemAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_items/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricItems"],
      }),
      getRubricEvaluatesAsTeacher: build.query<
        GetRubricEvaluatesAsTeacherApiResponse,
        GetRubricEvaluatesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_column: queryArg.searchColumn,
            search_order: queryArg.searchOrder,
          },
        }),
        providesTags: ["Teacher/RubricEvaluates"],
      }),
      postRubricEvaluateAsTeacher: build.mutation<
        PostRubricEvaluateAsTeacherApiResponse,
        PostRubricEvaluateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluates"],
      }),
      getRubricEvaluateAsTeacher: build.query<
        GetRubricEvaluateAsTeacherApiResponse,
        GetRubricEvaluateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/RubricEvaluates"],
      }),
      putRubricEvaluateAsTeacher: build.mutation<
        PutRubricEvaluateAsTeacherApiResponse,
        PutRubricEvaluateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluates"],
      }),
      deleteRubricEvaluateAsTeacher: build.mutation<
        DeleteRubricEvaluateAsTeacherApiResponse,
        DeleteRubricEvaluateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluates"],
      }),
      putRubricEvaluateSequenceAsTeacher: build.mutation<
        PutRubricEvaluateSequenceAsTeacherApiResponse,
        PutRubricEvaluateSequenceAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/sequence`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluates"],
      }),
      postRubricEvaluateStudentAsTeacher: build.mutation<
        PostRubricEvaluateStudentAsTeacherApiResponse,
        PostRubricEvaluateStudentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.rubricEvaluateId}/rubric_evaluate_students`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluateStudents"],
      }),
      getRubricEvaluateStudentAsTeacher: build.query<
        GetRubricEvaluateStudentAsTeacherApiResponse,
        GetRubricEvaluateStudentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.rubricEvaluateId}/rubric_evaluate_students/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/RubricEvaluateStudents"],
      }),
      putRubricEvaluateStudentAsTeacher: build.mutation<
        PutRubricEvaluateStudentAsTeacherApiResponse,
        PutRubricEvaluateStudentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.rubricEvaluateId}/rubric_evaluate_students/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluateStudents"],
      }),
      deleteRubricEvaluateStudentAsTeacher: build.mutation<
        DeleteRubricEvaluateStudentAsTeacherApiResponse,
        DeleteRubricEvaluateStudentAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.rubricEvaluateId}/rubric_evaluate_students/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluateStudents"],
      }),
      putRubricEvaluateStudentSequenceAsTeacher: build.mutation<
        PutRubricEvaluateStudentSequenceAsTeacherApiResponse,
        PutRubricEvaluateStudentSequenceAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.rubricEvaluateId}/rubric_evaluate_students/sequence`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluateStudents"],
      }),
      postRubricEvaluateStudentRateAsTeacher: build.mutation<
        PostRubricEvaluateStudentRateAsTeacherApiResponse,
        PostRubricEvaluateStudentRateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.rubricEvaluateId}/rubric_evaluate_students/${queryArg.rubricEvaluateStudentId}/rubric_evaluate_student_rates`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluateStudentRates"],
      }),
      putRubricEvaluateStudentRateAsTeacher: build.mutation<
        PutRubricEvaluateStudentRateAsTeacherApiResponse,
        PutRubricEvaluateStudentRateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.rubricEvaluateId}/rubric_evaluate_students/${queryArg.rubricEvaluateStudentId}/rubric_evaluate_student_rates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluateStudentRates"],
      }),
      deleteRubricEvaluateStudentRateAsTeacher: build.mutation<
        DeleteRubricEvaluateStudentRateAsTeacherApiResponse,
        DeleteRubricEvaluateStudentRateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/rubrics/${queryArg.rubricId}/rubric_evaluates/${queryArg.rubricEvaluateId}/rubric_evaluate_students/${queryArg.rubricEvaluateStudentId}/rubric_evaluate_student_rates/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/RubricEvaluateStudentRates"],
      }),
      getLearningTemplatePackageDistributionsAsTeacher: build.query<
        GetLearningTemplatePackageDistributionsAsTeacherApiResponse,
        GetLearningTemplatePackageDistributionsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/learning_template_package_distributions`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/LearningTemplatePackageDistributions"],
      }),
      getLearningTemplatePackageDistributionAsTeacher: build.query<
        GetLearningTemplatePackageDistributionAsTeacherApiResponse,
        GetLearningTemplatePackageDistributionAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/learning_template_package_distributions/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/LearningTemplatePackageDistributions"],
      }),
      getLearningPackageAsTeacher: build.query<
        GetLearningPackageAsTeacherApiResponse,
        GetLearningPackageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/learning_packages/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/LearningPackages"],
      }),
      getLearningTemplatePackageDistributionHistoriesAsTeacher: build.query<
        GetLearningTemplatePackageDistributionHistoriesAsTeacherApiResponse,
        GetLearningTemplatePackageDistributionHistoriesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/learning_template_package_distribution_histories`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/LearningTemplatePackageDistributionHistories"],
      }),
      getLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacher:
        build.query<
          GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacherApiResponse,
          GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacherApiArg
        >({
          query: (queryArg) => ({
            url: `/teacher/learning_template_package_distribution_histories/${queryArg.id}/distributed_students`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: [
            "Teacher/LearningTemplatePackageDistributionHistories",
          ],
        }),
      getLearningTemplatePackageDistributionHistoriesPackagingsAsTeacher:
        build.query<
          GetLearningTemplatePackageDistributionHistoriesPackagingsAsTeacherApiResponse,
          GetLearningTemplatePackageDistributionHistoriesPackagingsAsTeacherApiArg
        >({
          query: (queryArg) => ({
            url: `/teacher/learning_template_package_distribution_histories/${queryArg.id}/packagings`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: [
            "Teacher/LearningTemplatePackageDistributionHistories",
          ],
        }),
      getPblBoardCampaignsAsTeacher: build.query<
        GetPblBoardCampaignsAsTeacherApiResponse,
        GetPblBoardCampaignsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/board/campaigns`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            article_id: queryArg.articleId,
          },
        }),
        providesTags: ["Teacher/PblBoardCampaigns"],
      }),
      postPblBoardCampaignRequestAsTeacher: build.mutation<
        PostPblBoardCampaignRequestAsTeacherApiResponse,
        PostPblBoardCampaignRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/board/campaign_requests`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/PblBoardCampaignRequest"],
      }),
      deletePblBoardCampaignRequestsAsTeacher: build.mutation<
        DeletePblBoardCampaignRequestsAsTeacherApiResponse,
        DeletePblBoardCampaignRequestsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/pbl/board/campaign_requests/bulk_destroy`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Teacher/PblBoardCampaignRequest"],
      }),
      getGroupAffiliatePackagesAsTeacher: build.query<
        GetGroupAffiliatePackagesAsTeacherApiResponse,
        GetGroupAffiliatePackagesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/group_affiliate_packages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            homework_template_id: queryArg.homeworkTemplateId,
            distributed: queryArg.distributed,
            is_assigned_teacher: queryArg.isAssignedTeacher,
          },
        }),
        providesTags: ["Teacher/GroupAffiliatePackages"],
      }),
      postGroupAffiliatePackageAsTeacher: build.mutation<
        PostGroupAffiliatePackageAsTeacherApiResponse,
        PostGroupAffiliatePackageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/group_affiliate_packages`,
          method: "POST",
          body: queryArg.postGroupAffiliatePackage,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/GroupAffiliatePackages"],
      }),
      getGroupAffiliatePackageAsTeacher: build.query<
        GetGroupAffiliatePackageAsTeacherApiResponse,
        GetGroupAffiliatePackageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/group_affiliate_packages/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/GroupAffiliatePackages"],
      }),
      putGroupAffiliatePackageAsTeacher: build.mutation<
        PutGroupAffiliatePackageAsTeacherApiResponse,
        PutGroupAffiliatePackageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/group_affiliate_packages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putGroupAffiliatePackage,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/GroupAffiliatePackages"],
      }),
      deleteGroupAffiliatePackageAsTeacher: build.mutation<
        DeleteGroupAffiliatePackageAsTeacherApiResponse,
        DeleteGroupAffiliatePackageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/group_affiliate_packages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/GroupAffiliatePackages"],
      }),
      postPackagingAsTeacher: build.mutation<
        PostPackagingAsTeacherApiResponse,
        PostPackagingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/packagings`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Packaging"],
      }),
      putPackagingAsTeacher: build.mutation<
        PutPackagingAsTeacherApiResponse,
        PutPackagingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/packagings`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            package_id: queryArg.packageId,
            packageable_type: queryArg.packageableType,
            packageable_id: queryArg.packageableId,
          },
        }),
        invalidatesTags: ["Teacher/Packaging"],
      }),
      deletePackagingAsTeacher: build.mutation<
        DeletePackagingAsTeacherApiResponse,
        DeletePackagingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/packagings`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            package_id: queryArg.packageId,
            packageable_type: queryArg.packageableType,
            packageable_id: queryArg.packageableId,
          },
        }),
        invalidatesTags: ["Teacher/Packaging"],
      }),
      batchCreatePackagingAsTeacher: build.mutation<
        BatchCreatePackagingAsTeacherApiResponse,
        BatchCreatePackagingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/packagings/batch_create`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Packaging"],
      }),
      batchUpdatePackagingAsTeacher: build.mutation<
        BatchUpdatePackagingAsTeacherApiResponse,
        BatchUpdatePackagingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/packagings/batch_update_sequence`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Packaging"],
      }),
      batchDeletePackagingAsTeacher: build.mutation<
        BatchDeletePackagingAsTeacherApiResponse,
        BatchDeletePackagingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/packagings/batch_destroy`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Packaging"],
      }),
      postAttachedImageAsTeacher: build.mutation<
        PostAttachedImageAsTeacherApiResponse,
        PostAttachedImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/attached_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Teacher/AttachedImages"],
      }),
      getAttachedImageAsTeacher: build.query<
        GetAttachedImageAsTeacherApiResponse,
        GetAttachedImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/attached_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["Teacher/AttachedImages"],
      }),
      putAttachedImageAsTeacher: build.mutation<
        PutAttachedImageAsTeacherApiResponse,
        PutAttachedImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/attached_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Teacher/AttachedImages"],
      }),
      deleteAttachedImageAsTeacher: build.mutation<
        DeleteAttachedImageAsTeacherApiResponse,
        DeleteAttachedImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/attached_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Teacher/AttachedImages"],
      }),
      getLimitedTimeNotificationsAsTeacher: build.query<
        GetLimitedTimeNotificationsAsTeacherApiResponse,
        GetLimitedTimeNotificationsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/limited_time_notifications`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/LimitedTimeNotifications"],
      }),
      postLimitedTimeNotificationAsTeacher: build.mutation<
        PostLimitedTimeNotificationAsTeacherApiResponse,
        PostLimitedTimeNotificationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/limited_time_notifications`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/LimitedTimeNotifications"],
      }),
      getLimitedTimeNotificationAsTeacher: build.query<
        GetLimitedTimeNotificationAsTeacherApiResponse,
        GetLimitedTimeNotificationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/limited_time_notifications/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/LimitedTimeNotifications"],
      }),
      putLimitedTimeNotificationAsTeacher: build.mutation<
        PutLimitedTimeNotificationAsTeacherApiResponse,
        PutLimitedTimeNotificationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/limited_time_notifications/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/LimitedTimeNotifications"],
      }),
      deleteLimitedTimeNotificationAsTeacher: build.mutation<
        DeleteLimitedTimeNotificationAsTeacherApiResponse,
        DeleteLimitedTimeNotificationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/limited_time_notifications/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/LimitedTimeNotifications"],
      }),
      getLimitedTimeNotificationNoticeAsTeacher: build.query<
        GetLimitedTimeNotificationNoticeAsTeacherApiResponse,
        GetLimitedTimeNotificationNoticeAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/limited_time_notifications/notice`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/LimitedTimeNotifications"],
      }),
      getLimitedTimeNotificationNoticeDetailsAsTeacher: build.query<
        GetLimitedTimeNotificationNoticeDetailsAsTeacherApiResponse,
        GetLimitedTimeNotificationNoticeDetailsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/limited_time_notifications/${queryArg.id}/notice_details`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/LimitedTimeNotifications"],
      }),
      postLimitedTimeNotificationNoticeCheckAsTeacher: build.mutation<
        PostLimitedTimeNotificationNoticeCheckAsTeacherApiResponse,
        PostLimitedTimeNotificationNoticeCheckAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/limited_time_notifications/${queryArg.id}/notice_check`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/LimitedTimeNotifications"],
      }),
      getDailyLogsAsTeacher: build.query<
        GetDailyLogsAsTeacherApiResponse,
        GetDailyLogsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/daily_logs`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            from: queryArg["from"],
            to: queryArg.to,
            category: queryArg.category,
          },
        }),
        providesTags: ["Teacher/DailyLog"],
      }),
      getIdealBoardTemplatesAsTeacher: build.query<
        GetIdealBoardTemplatesAsTeacherApiResponse,
        GetIdealBoardTemplatesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/board_templates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_status: queryArg.searchStatus,
            no_homework_template: queryArg.noHomeworkTemplate,
          },
        }),
        providesTags: ["Teacher/IdealBoardTemplates"],
      }),
      postIdealBoardTemplatesAsTeacher: build.mutation<
        PostIdealBoardTemplatesAsTeacherApiResponse,
        PostIdealBoardTemplatesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/board_templates`,
          method: "POST",
          body: queryArg.postIdealBoardTemplateRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/IdealBoardTemplates"],
      }),
      getIdealBoardTemplateAsTeacher: build.query<
        GetIdealBoardTemplateAsTeacherApiResponse,
        GetIdealBoardTemplateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/board_templates/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/IdealBoardTemplate"],
      }),
      putIdealBoardTemplateAsTeacher: build.mutation<
        PutIdealBoardTemplateAsTeacherApiResponse,
        PutIdealBoardTemplateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/board_templates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putIdealBoardTemplateRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/IdealBoardTemplate"],
      }),
      deleteIdealBoardTemplateAsTeacher: build.mutation<
        DeleteIdealBoardTemplateAsTeacherApiResponse,
        DeleteIdealBoardTemplateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/board_templates/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/IdealBoardTemplate"],
      }),
      getIdealBoardsAsTeacher: build.query<
        GetIdealBoardsAsTeacherApiResponse,
        GetIdealBoardsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/boards`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            ideal_board_template_id: queryArg.idealBoardTemplateId,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: ["Teacher/IdealBoards"],
      }),
      postIdealBoardsAsTeacher: build.mutation<
        PostIdealBoardsAsTeacherApiResponse,
        PostIdealBoardsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/boards`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/IdealBoards"],
      }),
      getIdealBoardAsTeacher: build.query<
        GetIdealBoardAsTeacherApiResponse,
        GetIdealBoardAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/boards/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/IdealBoard"],
      }),
      putIdealBoardAsTeacher: build.mutation<
        PutIdealBoardAsTeacherApiResponse,
        PutIdealBoardAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/boards/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/IdealBoard"],
      }),
      deleteIdealBoardAsTeacher: build.mutation<
        DeleteIdealBoardAsTeacherApiResponse,
        DeleteIdealBoardAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/boards/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/IdealBoard"],
      }),
      postIdealBoardsAffiliatesAsTeacher: build.mutation<
        PostIdealBoardsAffiliatesAsTeacherApiResponse,
        PostIdealBoardsAffiliatesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/boards_affiliates`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/BoardAffiliates"],
      }),
      deleteIdealBoardsAffiliatesAsTeacher: build.mutation<
        DeleteIdealBoardsAffiliatesAsTeacherApiResponse,
        DeleteIdealBoardsAffiliatesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/ideal/boards_affiliates`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/BoardAffiliates"],
      }),
      patchAllowedGroupAffiliatesBatchUpdateAsTeacher: build.mutation<
        PatchAllowedGroupAffiliatesBatchUpdateAsTeacherApiResponse,
        PatchAllowedGroupAffiliatesBatchUpdateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/allowed_group_affiliates/batch_update`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["AllowedGroupAffiliates"],
      }),
      getTtCsvExportsAsTeacher: build.query<
        GetTtCsvExportsAsTeacherApiResponse,
        GetTtCsvExportsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/tt_csv/exports`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            types: queryArg.types,
          },
        }),
        providesTags: ["Teacher/TtCsvExports"],
      }),
      postTtCsvExportAsTeacher: build.mutation<
        PostTtCsvExportAsTeacherApiResponse,
        PostTtCsvExportAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/tt_csv/exports`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            sorted_by: queryArg.sortedBy,
            search_key: queryArg.searchKey,
            search_value: queryArg.searchValue,
          },
        }),
        invalidatesTags: ["Teacher/TtCsvExports"],
      }),
      postTtCategoryBaseAsTeacher: build.mutation<
        PostTtCategoryBaseAsTeacherApiResponse,
        PostTtCategoryBaseAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/tt_category/bases`,
          method: "POST",
          body: queryArg.createTtCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/TtCategoryBases"],
      }),
      putTtCategoryBaseAsTeacher: build.mutation<
        PutTtCategoryBaseAsTeacherApiResponse,
        PutTtCategoryBaseAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/tt_category/bases/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateTtCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/TtCategoryBases"],
      }),
      getFeedbackTemplateHomeworksAsTeacher: build.query<
        GetFeedbackTemplateHomeworksAsTeacherApiResponse,
        GetFeedbackTemplateHomeworksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/feedback_template/homeworks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Teacher/FeedbackTemplate/Homework"],
      }),
      getFeedbackTemplateTagsAsTeacher: build.query<
        GetFeedbackTemplateTagsAsTeacherApiResponse,
        GetFeedbackTemplateTagsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/feedback_template/tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/FeedbackTemplate/Tag"],
      }),
      postCommentImageAsTeacher: build.mutation<
        PostCommentImageAsTeacherApiResponse,
        PostCommentImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/comment_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Teacher/CommentImages"],
      }),
      getCommentImageAsTeacher: build.query<
        GetCommentImageAsTeacherApiResponse,
        GetCommentImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/comment_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["Teacher/CommentImages"],
      }),
      putCommentImageAsTeacher: build.mutation<
        PutCommentImageAsTeacherApiResponse,
        PutCommentImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/comment_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Teacher/CommentImages"],
      }),
      deleteCommentImageAsTeacher: build.mutation<
        DeleteCommentImageAsTeacherApiResponse,
        DeleteCommentImageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/comment_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Teacher/CommentImages"],
      }),
      getDistributionStatusesAsTeacher: build.query<
        GetDistributionStatusesAsTeacherApiResponse,
        GetDistributionStatusesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/distribution_statuses`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            homework_template_id: queryArg.homeworkTemplateId,
          },
        }),
        providesTags: ["Teacher/DistributionStatus"],
      }),
      getInterviewRequestsAsTeacher: build.query<
        GetInterviewRequestsAsTeacherApiResponse,
        GetInterviewRequestsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            status: queryArg.status,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Teacher/Interview/Request"],
      }),
      postInterviewRequestAsTeacher: build.mutation<
        PostInterviewRequestAsTeacherApiResponse,
        PostInterviewRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Interview/Request"],
      }),
      getInterviewRequestAsTeacher: build.query<
        GetInterviewRequestAsTeacherApiResponse,
        GetInterviewRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Interview/Request"],
      }),
      putInterviewRequestAsTeacher: build.mutation<
        PutInterviewRequestAsTeacherApiResponse,
        PutInterviewRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Interview/Request"],
      }),
      getInterviewRequestArticleAsTeacher: build.query<
        GetInterviewRequestArticleAsTeacherApiResponse,
        GetInterviewRequestArticleAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/${queryArg.id}/article`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Interview/Request"],
      }),
      getInterviewRequestUnreadCountsAsTeacher: build.query<
        GetInterviewRequestUnreadCountsAsTeacherApiResponse,
        GetInterviewRequestUnreadCountsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/unread_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { request_ids: queryArg.requestIds },
        }),
        providesTags: ["Teacher/Interview/Request"],
      }),
      getInterviewMessagesAsTeacher: build.query<
        GetInterviewMessagesAsTeacherApiResponse,
        GetInterviewMessagesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/${queryArg.requestId}/messages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Interview/Message"],
      }),
      postInterviewMessageAsTeacher: build.mutation<
        PostInterviewMessageAsTeacherApiResponse,
        PostInterviewMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/${queryArg.requestId}/messages`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Interview/Message"],
      }),
      getInterviewMessageReadAsTeacher: build.mutation<
        GetInterviewMessageReadAsTeacherApiResponse,
        GetInterviewMessageReadAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/${queryArg.requestId}/messages/read`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Interview/Message"],
      }),
      putInterviewMessageAsTeacher: build.mutation<
        PutInterviewMessageAsTeacherApiResponse,
        PutInterviewMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/${queryArg.requestId}/messages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Interview/Message"],
      }),
      deleteInterviewMessageAsTeacher: build.mutation<
        DeleteInterviewMessageAsTeacherApiResponse,
        DeleteInterviewMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/${queryArg.requestId}/messages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Interview/Message"],
      }),
      getInterviewFeedbacksAsTeacher: build.query<
        GetInterviewFeedbacksAsTeacherApiResponse,
        GetInterviewFeedbacksAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/interview/requests/${queryArg.requestId}/feedbacks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Interview/Feedback"],
      }),
      fetchAffiliateInvitationsAsTeacher: build.query<
        FetchAffiliateInvitationsAsTeacherApiResponse,
        FetchAffiliateInvitationsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/affiliate_invitations`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { role: queryArg.role },
        }),
        providesTags: ["Teacher/AffiliateInvitation"],
      }),
      putHomeworkTemplateCustomValueAsTeacher: build.mutation<
        PutHomeworkTemplateCustomValueAsTeacherApiResponse,
        PutHomeworkTemplateCustomValueAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/homework_template_custom_values/${queryArg.id}`,
          method: "PUT",
          body: queryArg.customValueRequestBodyAsOwnerAndTeacherAndReviewer,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/HomeworkTemplateCustomValues"],
      }),
      getGogoLinkingsAsTeacher: build.query<
        GetGogoLinkingsAsTeacherApiResponse,
        GetGogoLinkingsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/linkings`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Gogo/Linkings"],
      }),
      getGogoRequestsAsTeacher: build.query<
        GetGogoRequestsAsTeacherApiResponse,
        GetGogoRequestsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            request_type: queryArg.requestType,
            status: queryArg.status,
            company_id: queryArg.companyId,
          },
        }),
        providesTags: ["Teacher/Gogo/Request"],
      }),
      postGogoRequestAsTeacher: build.mutation<
        PostGogoRequestAsTeacherApiResponse,
        PostGogoRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests`,
          method: "POST",
          body: queryArg.postGogoRequestRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/Request"],
      }),
      getGogoRequestAsTeacher: build.query<
        GetGogoRequestAsTeacherApiResponse,
        GetGogoRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Gogo/Request"],
      }),
      putGogoRequestAsTeacher: build.mutation<
        PutGogoRequestAsTeacherApiResponse,
        PutGogoRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putGogoRequestRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/Request"],
      }),
      deleteGogoRequestAsTeacher: build.mutation<
        DeleteGogoRequestAsTeacherApiResponse,
        DeleteGogoRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/Request"],
      }),
      getGogoRequestUnreadCountsAsTeacher: build.query<
        GetGogoRequestUnreadCountsAsTeacherApiResponse,
        GetGogoRequestUnreadCountsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests/unread_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { request_ids: queryArg.requestIds },
        }),
        providesTags: ["Teacher/Gogo/Request"],
      }),
      getGogoMessagesAsTeacher: build.query<
        GetGogoMessagesAsTeacherApiResponse,
        GetGogoMessagesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests/${queryArg.requestId}/messages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Teacher/Gogo/Message"],
      }),
      postGogoMessageAsTeacher: build.mutation<
        PostGogoMessageAsTeacherApiResponse,
        PostGogoMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests/${queryArg.requestId}/messages`,
          method: "POST",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { categorizable_type: queryArg.categorizableType },
        }),
        invalidatesTags: ["Teacher/Gogo/Message"],
      }),
      putGogoMessageAsTeacher: build.mutation<
        PutGogoMessageAsTeacherApiResponse,
        PutGogoMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests/${queryArg.requestId}/messages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { categorizable_type: queryArg.categorizableType },
        }),
        invalidatesTags: ["Teacher/Gogo/Message"],
      }),
      deleteGogoMessageAsTeacher: build.mutation<
        DeleteGogoMessageAsTeacherApiResponse,
        DeleteGogoMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests/${queryArg.requestId}/messages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { categorizable_type: queryArg.categorizableType },
        }),
        invalidatesTags: ["Teacher/Gogo/Message"],
      }),
      getChatRoomAsTeacher: build.query<
        GetChatRoomAsTeacherApiResponse,
        GetChatRoomAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/chat/rooms`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        providesTags: ["Teacher/Chat/Room"],
      }),
      postChatRoomAsTeacher: build.mutation<
        PostChatRoomAsTeacherApiResponse,
        PostChatRoomAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/chat/rooms`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        invalidatesTags: ["Teacher/Chat/Room"],
      }),
      getChatRoomMessagesAsTeacher: build.query<
        GetChatRoomMessagesAsTeacherApiResponse,
        GetChatRoomMessagesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/chat/rooms/room_messages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            bookmark_id: queryArg.bookmarkId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Teacher/Chat/RoomMessage"],
      }),
      postChatRoomMessageAsTeacher: build.mutation<
        PostChatRoomMessageAsTeacherApiResponse,
        PostChatRoomMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/chat/rooms/room_messages`,
          method: "POST",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        invalidatesTags: ["Teacher/Chat/RoomMessage"],
      }),
      putChatRoomMessageAsTeacher: build.mutation<
        PutChatRoomMessageAsTeacherApiResponse,
        PutChatRoomMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/chat/rooms/room_messages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        invalidatesTags: ["Teacher/Chat/RoomMessage"],
      }),
      deleteChatRoomMessageAsTeacher: build.mutation<
        DeleteChatRoomMessageAsTeacherApiResponse,
        DeleteChatRoomMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/chat/rooms/room_messages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        invalidatesTags: ["Teacher/Chat/RoomMessage"],
      }),
      getGogoMessageReadAsTeacher: build.mutation<
        GetGogoMessageReadAsTeacherApiResponse,
        GetGogoMessageReadAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/requests/${queryArg.requestId}/messages/read`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { categorizable_type: queryArg.categorizableType },
        }),
        invalidatesTags: ["Teacher/Gogo/Message"],
      }),
      getCommonTagsAsTeacher: build.query<
        GetCommonTagsAsTeacherApiResponse,
        GetCommonTagsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/common/tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            kind: queryArg.kind,
            categorizable_type: queryArg.categorizableType,
          },
        }),
        providesTags: ["Teacher/CommonTags"],
      }),
      postCommonTagAsTeacher: build.mutation<
        PostCommonTagAsTeacherApiResponse,
        PostCommonTagAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/common/tags`,
          method: "POST",
          body: queryArg.commonTagRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/CommonTags"],
      }),
      putCommonTagAsTeacher: build.mutation<
        PutCommonTagAsTeacherApiResponse,
        PutCommonTagAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/common/tags/${queryArg.id}`,
          method: "PUT",
          body: queryArg.nameRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/CommonTags"],
      }),
      deleteCommonTagAsTeacher: build.mutation<
        DeleteCommonTagAsTeacherApiResponse,
        DeleteCommonTagAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/common/tags/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/CommonTags"],
      }),
      getCommonTagsManagementTagListAsTeacher: build.query<
        GetCommonTagsManagementTagListAsTeacherApiResponse,
        GetCommonTagsManagementTagListAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/common/tags/management_tag_list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/CommonTags"],
      }),
      postCommonTaggingAsTeacher: build.mutation<
        PostCommonTaggingAsTeacherApiResponse,
        PostCommonTaggingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/common/taggings`,
          method: "POST",
          body: queryArg.commonTaggingRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Teacher/CommonTaggings"],
      }),
      deleteCommonTaggingAsTeacher: build.mutation<
        DeleteCommonTaggingAsTeacherApiResponse,
        DeleteCommonTaggingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/common/taggings`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
            common_tag_id: queryArg.commonTagId,
          },
        }),
        invalidatesTags: ["Teacher/CommonTaggings"],
      }),
      postCommonTagLinkingAsTeacher: build.mutation<
        PostCommonTagLinkingAsTeacherApiResponse,
        PostCommonTagLinkingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/common/tags/${queryArg.tagId}/tag_linkings`,
          method: "POST",
          body: queryArg.commonTagLinkingRequestBody,
        }),
        invalidatesTags: ["Teacher/CommonTagLinking"],
      }),
      deleteCommonTagLinkingAsTeacher: build.mutation<
        DeleteCommonTagLinkingAsTeacherApiResponse,
        DeleteCommonTagLinkingAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/common/tags/${queryArg.tagId}/tag_linkings/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/CommonTagLinking"],
      }),
      getGogoProgramsAsTeacher: build.query<
        GetGogoProgramsAsTeacherApiResponse,
        GetGogoProgramsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            is_managed: queryArg.isManaged,
            is_requested: queryArg.isRequested,
          },
        }),
        providesTags: ["Teacher/Gogo/Program"],
      }),
      postGogoProgramAsTeacher: build.mutation<
        PostGogoProgramAsTeacherApiResponse,
        PostGogoProgramAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/Program"],
      }),
      getGogoProgramAsTeacher: build.query<
        GetGogoProgramAsTeacherApiResponse,
        GetGogoProgramAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Gogo/Program"],
      }),
      putGogoProgramAsTeacher: build.mutation<
        PutGogoProgramAsTeacherApiResponse,
        PutGogoProgramAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.id}`,
          method: "PUT",
          body: queryArg.schema,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/Program"],
      }),
      deleteGogoProgramAsTeacher: build.mutation<
        DeleteGogoProgramAsTeacherApiResponse,
        DeleteGogoProgramAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/Program"],
      }),
      getGogoProgramRequestsAsTeacher: build.query<
        GetGogoProgramRequestsAsTeacherApiResponse,
        GetGogoProgramRequestsAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Gogo/ProgramRequest"],
      }),
      postGogoProgramRequestAsTeacher: build.mutation<
        PostGogoProgramRequestAsTeacherApiResponse,
        PostGogoProgramRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramRequest"],
      }),
      getGogoProgramRequestAsTeacher: build.query<
        GetGogoProgramRequestAsTeacherApiResponse,
        GetGogoProgramRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Gogo/ProgramRequest"],
      }),
      putGogoProgramRequestAsTeacher: build.mutation<
        PutGogoProgramRequestAsTeacherApiResponse,
        PutGogoProgramRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramRequest"],
      }),
      deleteGogoProgramRequestAsTeacher: build.mutation<
        DeleteGogoProgramRequestAsTeacherApiResponse,
        DeleteGogoProgramRequestAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramRequest"],
      }),
      getGogoProgramRequestMessagesAsTeacher: build.query<
        GetGogoProgramRequestMessagesAsTeacherApiResponse,
        GetGogoProgramRequestMessagesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Gogo/ProgramRequestMessage"],
      }),
      postGogoProgramRequestMessageAsTeacher: build.mutation<
        PostGogoProgramRequestMessageAsTeacherApiResponse,
        PostGogoProgramRequestMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages`,
          method: "POST",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramRequestMessage"],
      }),
      putGogoProgramRequestMessageAsTeacher: build.mutation<
        PutGogoProgramRequestMessageAsTeacherApiResponse,
        PutGogoProgramRequestMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramRequestMessage"],
      }),
      deleteGogoProgramRequestMessageAsTeacher: build.mutation<
        DeleteGogoProgramRequestMessageAsTeacherApiResponse,
        DeleteGogoProgramRequestMessageAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramRequestMessage"],
      }),
      getGogoProgramRequestMessageReadAsTeacher: build.mutation<
        GetGogoProgramRequestMessageReadAsTeacherApiResponse,
        GetGogoProgramRequestMessageReadAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_requests/${queryArg.programRequestId}/program_request_messages/read`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramRequestMessage"],
      }),
      getGogoProgramPreferredDatesAsTeacher: build.query<
        GetGogoProgramPreferredDatesAsTeacherApiResponse,
        GetGogoProgramPreferredDatesAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_preferred_dates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Gogo/ProgramPreferredDate"],
      }),
      postGogoProgramPreferredDateAsTeacher: build.mutation<
        PostGogoProgramPreferredDateAsTeacherApiResponse,
        PostGogoProgramPreferredDateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_preferred_dates`,
          method: "POST",
          body: queryArg.gogoProgramPreferredDateRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramPreferredDate"],
      }),
      putGogoProgramPreferredDateAsTeacher: build.mutation<
        PutGogoProgramPreferredDateAsTeacherApiResponse,
        PutGogoProgramPreferredDateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_preferred_dates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.gogoProgramPreferredDateRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramPreferredDate"],
      }),
      deleteGogoProgramPreferredDateAsTeacher: build.mutation<
        DeleteGogoProgramPreferredDateAsTeacherApiResponse,
        DeleteGogoProgramPreferredDateAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/programs/${queryArg.programId}/program_preferred_dates/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/ProgramPreferredDate"],
      }),
      getGogoCompanyInformationAsTeacher: build.query<
        GetGogoCompanyInformationAsTeacherApiResponse,
        GetGogoCompanyInformationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/company_informations`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
            content_company_id: queryArg.contentCompanyId,
          },
        }),
        providesTags: ["Teacher/Gogo/CompanyInformation"],
      }),
      getGogoCompanyInformationListAsTeacher: build.query<
        GetGogoCompanyInformationListAsTeacherApiResponse,
        GetGogoCompanyInformationListAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/company_informations/list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Teacher/Gogo/CompanyInformation"],
      }),
      postGogoSchoolInformationAsTeacher: build.mutation<
        PostGogoSchoolInformationAsTeacherApiResponse,
        PostGogoSchoolInformationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/school_informations`,
          method: "POST",
          body: queryArg.schema,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/SchoolInformation"],
      }),
      getGogoSchoolInformationAsTeacher: build.query<
        GetGogoSchoolInformationAsTeacherApiResponse,
        GetGogoSchoolInformationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/school_informations`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Teacher/Gogo/SchoolInformation"],
      }),
      putGogoSchoolInformationAsTeacher: build.mutation<
        PutGogoSchoolInformationAsTeacherApiResponse,
        PutGogoSchoolInformationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/school_informations`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/SchoolInformation"],
      }),
      deleteGogoSchoolInformationAsTeacher: build.mutation<
        DeleteGogoSchoolInformationAsTeacherApiResponse,
        DeleteGogoSchoolInformationAsTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/teacher/gogo/school_informations`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Teacher/Gogo/SchoolInformation"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as teacherApi };
export type GetTeacherUsersStudentsApiResponse = /** status 200 OK */ {
  students: StudentListAsTeacher[];
  student_input_settings: StudentInputSetting;
  total_count: number;
};
export type GetTeacherUsersStudentsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  userTagIds?: string[];
  tagFilter?: "AND" | "OR";
  homeworkTemplateId?: string;
  pblReportTemplateId?: string;
  /** material_idで検索する時使用 */
  filterMaterialId?: string;
  distribution?: "distributed" | "not_distributed";
  /** Incremental search by last name */
  search?: string;
  email?: string;
  grade?: string;
  /** 複数のgradeで検索する時 */
  grades?: string;
  /** Incremental search by parent_last_name and parent_first_name */
  parentName?: string;
  userTag?: string;
  perPage?: number;
  page?: number;
  /** グループのID (GroupAffiliatePackage ID) */
  groupAffiliatePackageId?: string;
  sortedBy?: SortedByStudentList;
  /** グループのIDの配列 (GroupAffiliatePackage ID) */
  groupAffiliatePackageIds?: string[];
  /** search users by statues */
  searchStatuses?: AffiliateStatusJa[];
  /** カリキュラムに関連する生徒を除外する */
  pblPlanItemId?: string;
  /** ニックネーム */
  nickname?: string;
};
export type PostTeacherStudentsApiResponse = /** status 201 Created */ {
  id?: string;
};
export type PostTeacherStudentsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  /** invitation_scheduled_atを設定時、招待メールは設定した日時に送信される。未設定時、招待メールは即時送信される。 */
  body: {
    email: string;
    role: string;
    first_name?: string;
    last_name?: string;
    optional_email?: string;
    student_info_attributes?: StudentInfoAttributes;
    invitation_scheduled_at?: string;
  };
};
export type StudentsBatchCreateUsersAsTeacherApiResponse =
  /** status 200 OK */ {
    errors?: string[];
  };
export type StudentsBatchCreateUsersAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  /** invitation_scheduled_atを設定時、招待メールは設定した日時に送信される。未設定時、招待メールは即時送信される。 */
  body: {
    slice_start?: number;
    user_params_list?: {
      email?: string;
      user_type?: UserType;
      first_name?: string;
      last_name?: string;
      birth_date?: string;
      grade?: number;
      parent_last_name?: string;
      parent_first_name?: string;
      postcode?: string;
      prefecture?: string;
      address1?: string;
      address2?: string;
      phone?: string;
      number?: number;
      optional_email?: string;
      user_tag_ids?: string[] | null;
      group_ids?: string[] | null;
    }[];
    invitation_scheduled_at?: string;
  };
};
export type GetTeacherUsersStudentsListGradeApiResponse = /** status 200 OK */ {
  number_of_students_per_grade: {
    grade: StudentGradeJa;
    count: number;
  }[];
  students_total_count: number;
};
export type GetTeacherUsersStudentsListGradeApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  homeworkTemplateId?: string;
  pblReportTemplateId?: string;
  filterMaterialId?: string;
  distribution?: "distributed" | "not_distributed";
};
export type CreateAccountUserAsTeacherApiResponse =
  /** status 201 Created */ CreateAccountUserResponse;
export type CreateAccountUserAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  createAccountUserRequestBodyAsTeacher: CreateAccountUserRequestBodyAsTeacher;
};
export type CreateAccountAvailableAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type CreateAccountAvailableAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    affiliate_id: string;
  };
};
export type GetTeacherUsersStudentAffiliatesApiResponse = /** status 200 OK */ {
  students?: AffiliateName[];
  total_count?: number;
};
export type GetTeacherUsersStudentAffiliatesApiArg = {
  /** Incremental search by last_name and first_name */
  search?: string;
  page?: number;
  perPage?: number;
};
export type GetStudentsUserTagsAsTeacherApiResponse = /** status 200 OK */ {
  user_tag_types: (UserTagTypeBasic & {
    user_tags: UserTagBasic[];
  })[];
};
export type GetStudentsUserTagsAsTeacherApiArg = {
  /** Student ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudentsGroupAffiliatesAsTeacherApiResponse =
  /** status 200 OK */ {
    group_affiliate_packages?: GroupAffiliatePackageMinimalBase[];
  };
export type GetStudentsGroupAffiliatesAsTeacherApiArg = {
  /** Student ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherUsersStudentsByIdApiResponse =
  /** status 200 OK */ UserDetail;
export type GetTeacherUsersStudentsByIdApiArg = {
  /** Student ID */
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type PutTeacherUsersStudentsByIdApiResponse =
  /** status 200 OK */ UserDetail;
export type PutTeacherUsersStudentsByIdApiArg = {
  /** Student ID */
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    last_name?: string;
    first_name?: string;
    optional_email?: string;
    user_type?: UserAccountType & any;
    new_id?: string;
    student_info_attributes?: StudentInfoAttributes;
    user_tag_ids?: string[] | null;
    group_ids?: string[] | null;
  };
};
export type DeleteTeacherStudentsUserIdApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteTeacherStudentsUserIdApiArg = {
  /** User ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeachersAsTeacherApiResponse = /** status 200 OK */ {
  teachers: (TeacherListForTeacher | HasAffiliateIdAndName)[];
  total_count: number;
};
export type GetTeachersAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** For incremental search by last name and first_name */
  search?: string;
  email?: string;
  /** search users by statuses */
  searchStatuses?: AffiliateStatusJa[];
  /** trueのとき、id, name, nickname のみを返却 */
  isMinInfo?: boolean;
};
export type DeleteTeacherTeachersUserIdApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteTeacherTeachersUserIdApiArg = {
  /** User ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutTeachersProgressUpdateAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutTeachersProgressUpdateAsTeacherApiArg = {
  /** User ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherUniversitiesAsTeacherApiResponse = /** status 200 OK */ {
  university_teachers: TeacherListForUniversityTeacher[];
  total_count: number;
};
export type GetTeacherUniversitiesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  searchType?: TeacherSearchType;
  /** 検索単語、search_typeとsearch_wordは同時に指定する必要有り */
  searchWord?: string;
  /** 企業プロジェクトID */
  pblSponsorProjectId: string;
};
export type GetTeacherUsersByUserIdApiResponse =
  /** status 200 OK */ UserDetail;
export type GetTeacherUsersByUserIdApiArg = {
  userId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutTeacherUsersByUserIdApiResponse =
  /** status 200 OK */ CurrentUserInfo;
export type PutTeacherUsersByUserIdApiArg = {
  userId: string;
  body: {
    first_name?: string;
    last_name?: string;
    nickname?: string;
    teacher_info_attributes?: TeacherInfoAttributes;
  };
};
export type DeleteTeacherUsersByUserIdApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteTeacherUsersByUserIdApiArg = {
  userId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    role: string;
  };
};
export type ResetPasswordAsTeacherApiResponse =
  /** status 200 ok */ AccountUserInfo;
export type ResetPasswordAsTeacherApiArg = {
  id: string;
  authorizationPasswordRequestBody: AuthorizationPasswordRequestBody;
};
export type AccountUnlockAsTeacherApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type AccountUnlockAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    user_ids: string[];
  };
};
export type GetTeacherMaterialsApiResponse = /** status 200 OK */ {
  materials: MaterialList[];
  total_count: number;
};
export type GetTeacherMaterialsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
  categoryIds?: string[];
  tagNames?: string[];
  /** - 検索タイプを指定する
    - search_typeとsearch_wordは両方していする必要あり
    - title: タイトルから検索
    - description: 説明文から検索
    - tag: タグから検索 */
  searchType?: string;
  /** - search_typeとsearch_wordは両方していする必要あり
    - 検索ワードの部分一致で検索する */
  searchWord?: string;
  searchColumn?: MaterialSortedByColumn;
  searchOrder?: SortedByOrder;
  /** - true
    - false
    - default: false */
  forPblPlanItem?: string;
};
export type GetTeacherMaterialsByMaterialIdApiResponse =
  /** status 200 OK */ MaterialDetail;
export type GetTeacherMaterialsByMaterialIdApiArg = {
  materialId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type GetTeacherMaterialWorksApiResponse = /** status 200 OK */ {
  material_works: TeacherMaterialWork[];
  total_count: number;
};
export type GetTeacherMaterialWorksApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
  /** - 検索タイプを指定する
    - search_typeとsearch_wordは両方していする必要あり
    - title: タイトルから検索
    - description: 説明文から検索
    - tag: タグから検索 */
  searchType?: string;
  /** - search_typeとsearch_wordは両方していする必要あり
    - 検索ワードの部分一致で検索する */
  searchWord?: string;
};
export type PostTeacherMaterialWorksApiResponse =
  /** status 201 OK */ TeacherMaterialWork;
export type PostTeacherMaterialWorksApiArg = {
  /** Both are required */
  body: {
    user_id: string;
    material_id: string;
    forbid_download?: boolean;
    role: string;
  };
};
export type GetTeacherMaterialWorksByMaterialWorkIdApiResponse =
  /** status 200 OK */ TeacherMaterialWork;
export type GetTeacherMaterialWorksByMaterialWorkIdApiArg = {
  materialWorkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type PutTeacherMaterialWorksByMaterialWorkIdApiResponse =
  /** status 200 OK */ TeacherMaterialWork;
export type PutTeacherMaterialWorksByMaterialWorkIdApiArg = {
  materialWorkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    material_works_id?: string;
    status?: MaterialWorkStatus;
    forbid_download?: boolean;
  };
};
export type GetMaterialTagsAsTeacherApiResponse = /** status 200 OK */ {
  material_tags: HasName[];
  total_count: number;
};
export type GetMaterialTagsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** - true
    - false
    - default: false */
  forPblPlanItem?: string;
};
export type FetchStudentMaterialWorksAsTeacherApiResponse =
  /** status 200 OK */ MaterialWork[];
export type FetchStudentMaterialWorksAsTeacherApiArg = {
  client?: string;
  "access-token"?: string;
  uid?: string;
  studentId?: string;
};
export type FetchOneStudentMaterialWorkAsTeacherApiResponse =
  /** status 200 OK */ TeacherMaterialWork;
export type FetchOneStudentMaterialWorkAsTeacherApiArg = {
  materialWorkId: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
  studentId?: string;
};
export type GetTeacherReportsApiResponse = /** status 200 OK */ {
  reports: ({
    message_to_owner?: string;
    message_to_student?: string;
  } & Report)[];
  total_count: number;
};
export type GetTeacherReportsApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
};
export type PostTeacherReportsApiResponse = /** status 201 OK */ Report & {
  message_to_owner: string;
  message_to_student: string;
};
export type PostTeacherReportsApiArg = {
  body: {
    title?: string;
    detail?: string;
    student_id?: string;
    next_goal?: string;
    monthly_teaching_times?: number;
    monthly_student_schedule_change?: number;
    monthly_teacher_schedule_change?: number;
    homework_frequency?: number;
    is_schedule_change_required?: boolean;
    schedule_detail_reason?: string;
    test_misc?: string;
    message_to_owner?: string;
    message_to_student?: string;
    text_subjects?: string[];
    schedule_change_period?: string;
    is_schedule_change_notified?: boolean;
    substitute_lecture?: boolean;
    test_type?: ReportTestType;
    reports_test_results?: {
      subject?: string;
      current_score?: number;
      positive_negative?: boolean;
      score_delta?: number;
    }[];
    no_text_reason?: string;
    no_homework_reason?: string;
  };
};
export type GetTeacherReportsByReportIdApiResponse =
  /** status 200 OK */ Report & {
    message_to_owner: string;
    message_to_student: string;
  };
export type GetTeacherReportsByReportIdApiArg = {
  reportId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
};
export type PutTeacherReportsByReportIdApiResponse =
  /** status 200 OK */ Report & {
    message_to_owner: string;
    message_to_student: string;
  };
export type PutTeacherReportsByReportIdApiArg = {
  reportId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
  body: {
    title?: string;
    detail?: string;
    status?: ReportStatus;
  };
};
export type DeleteTeacherReportsByReportIdApiResponse =
  /** status 204 No Content */ undefined;
export type DeleteTeacherReportsByReportIdApiArg = {
  reportId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherPaymentsApiResponse = /** status 200 OK */ {
  payments: Payment[];
  total_count: number;
};
export type GetTeacherPaymentsApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
};
export type PostTeacherPaymentsApiResponse = /** status 201 Created */ Payment;
export type PostTeacherPaymentsApiArg = {
  body: {
    student_id?: string;
    title?: string;
    price?: number;
    year?: number;
    month?: number;
  };
};
export type GetTeacherPaymentsByPaymentIdApiResponse =
  /** status 200 OK */ Payment;
export type GetTeacherPaymentsByPaymentIdApiArg = {
  /** payment id to delete */
  paymentId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
};
export type PutTeacherPaymentsByPaymentIdApiResponse =
  /** status 200 OK */ Payment;
export type PutTeacherPaymentsByPaymentIdApiArg = {
  /** payment id to delete */
  paymentId: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
  body: {
    status?: PaymentStatus;
  };
};
export type DeleteTeacherPaymentsByPaymentIdApiResponse =
  /** status 202 Accepted */ {
    message: string;
  };
export type DeleteTeacherPaymentsByPaymentIdApiArg = {
  /** payment id to delete */
  paymentId: string;
};
export type GetTeacherHomeworksApiResponse = /** status 200 OK */ {
  homeworks: HomeworkBase[];
  total_count: number;
};
export type GetTeacherHomeworksApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  perPage?: number;
  page?: number;
  templateId?: string;
  teacherName?: string;
  studentName?: string;
  startDate?: string;
  endDate?: string;
  statuses?: HomeworkStatus[];
  /** グループID */
  groupAffiliateIds?: string[];
  /** 指定する場合は、submitted_start_at と submitted_end_at が必須 */
  submittedStartAt?: string;
  /** 指定する場合は、submitted_start_at と submitted_end_at が必須 */
  submittedEndAt?: string;
  orderType?: HomeworkOrderType;
  sortType?: SortedByOrder;
  userTagIds?: string[];
  groupTitle?: string;
  /** common_tag_nameのフィルタリング */
  commonTagName?: string;
};
export type GetTeacherHomeworksByHomeworkIdApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type GetTeacherHomeworksByHomeworkIdApiArg = {
  homeworkId: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
};
export type PutTeacherHomeworksByHomeworkIdApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type PutTeacherHomeworksByHomeworkIdApiArg = {
  homeworkId: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
  body: {
    status?: HomeworkStatus;
    teacher_comment?: string;
    teacher_comment_for_reviewer?: string;
    score?: number;
    ideal_thumbnail_file?: Blob;
    comment_images?: Blob[];
  };
};
export type DeleteTeacherHomeworksByHomeworkIdApiResponse =
  /** status 204 No Content */ undefined;
export type DeleteTeacherHomeworksByHomeworkIdApiArg = {
  homeworkId: string;
  "access-token"?: string;
  uid?: string;
  client?: string;
};
export type GetHomeworkImageDownloadUrlsAsTeacherApiResponse =
  /** status 200 OK */ {
    homeworks: HomeworkDownloadUrl[];
  };
export type GetHomeworkImageDownloadUrlsAsTeacherApiArg = {
  client?: string;
  "access-token"?: string;
  uid?: string;
  homeworkIds: string[];
  fileContent?: "file" | "answer_file" | "reviewer_file";
  templateId?: string;
  orderType?: HomeworkOrderType;
  sortType?: SortedByOrder;
};
export type BatchCreateHomeworksAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type BatchCreateHomeworksAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    homework_template_id?: string;
    create_params_list?: {
      student_id: string;
    }[];
  };
};
export type BatchCreateGroupHomeworksAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type BatchCreateGroupHomeworksAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    homework_template_id: string;
    group_affiliate_package_ids: string[];
  };
};
export type BatchUpdateHomeworksAsTeacherApiResponse = /** status 200 OK */ {
  homeworks: HomeworkBase[];
};
export type BatchUpdateHomeworksAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  batchUpdateHomeworkRequest: BatchUpdateHomeworkRequest;
};
export type SubmitHomeworkAnswerFilesAsTeacherApiResponse =
  /** status 200 OK */ {
    homeworks: HomeworkBase[];
  };
export type SubmitHomeworkAnswerFilesAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    file_params_list: {
      homework_id: string;
      file_data: string;
    }[];
  };
};
export type FetchSubmitHomeworkFilesRequestsAsTeacherApiResponse =
  /** status 200 OK */ {
    requests: SubmitHomeworkFilesRequest[];
    total_count: number;
  };
export type FetchSubmitHomeworkFilesRequestsAsTeacherApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  perPage?: number;
  page?: number;
  templateId?: string;
  /** ステータスを基にフィルターをかける */
  statuses?: SubmitHomeworkFilesRequestStatus[];
};
export type CreateSubmitHomeworkFilesRequestAsTeacherApiResponse =
  /** status 201 Created */ SubmitHomeworkFilesRequest;
export type CreateSubmitHomeworkFilesRequestAsTeacherApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  body: {
    files?: Blob[];
  };
};
export type BatchDeleteHomeworksAsTeacherApiResponse = /** status 200 OK */ {
  deleted_ids: number[];
  errors?: string[] | null;
};
export type BatchDeleteHomeworksAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    homework_ids: string[];
  };
};
export type BatchUpdateTeacherCommentsHomeworksAsTeacherApiResponse =
  /** status 200 OK */ {
    homeworks: HomeworkBase[];
  };
export type BatchUpdateTeacherCommentsHomeworksAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    homework_template_id: string;
    homework_ids: string[];
    teacher_comment_list: {
      csv_status: "NG" | "OK";
      comment: string;
    }[];
  };
};
export type UploadHomeworkImagesAsTeacherApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type UploadHomeworkImagesAsTeacherApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    files: string[];
  };
};
export type SaveEditedHomeworkImageAsTeacherApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type SaveEditedHomeworkImageAsTeacherApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    file: string;
  };
};
export type UpdateHomeworkImageAsTeacherApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type UpdateHomeworkImageAsTeacherApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_id: string;
    file: string;
  };
};
export type DeleteHomeworkImageAsTeacherApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type DeleteHomeworkImageAsTeacherApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_id: string;
  };
};
export type PostHomeworkTemplateCustomFieldFeedbackAsTeacherApiResponse =
  /** status 201 Created */ HomeworkTemplateCustomFieldFeedback;
export type PostHomeworkTemplateCustomFieldFeedbackAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  homeworkTemplateCustomFieldId: string;
  customFieldFeedbackRequestBody: CustomFieldFeedbackRequestBody;
};
export type PutHomeworkTemplateCustomFieldFeedbacksAsTeacherApiResponse =
  /** status 202 Accepted */ HomeworkTemplateCustomFieldFeedback;
export type PutHomeworkTemplateCustomFieldFeedbacksAsTeacherApiArg = {
  homeworkTemplateCustomFieldId: string;
  /** HomeworkTemplateCustomFieldFeedbackID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putCustomFieldFeedbackRequestBody: PutCustomFieldFeedbackRequestBody;
};
export type DeleteHomeworkTemplateCustomFieldFeedbacksAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteHomeworkTemplateCustomFieldFeedbacksAsTeacherApiArg = {
  homeworkTemplateCustomFieldId: string;
  /** HomeworkTemplateCustomFieldFeedbackID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceApiArg =
  {
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    body: {
      sequences: {
        id: string;
        sequence_number: number;
      }[];
    };
  };
export type GetTeacherCompaniesByCompanyIdApiResponse = /** status 200 OK */ {
  id: string;
  name: string;
  postcode: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
  student_count_by_grade: {
    grade?: string;
    count?: number;
  }[];
  student_input_settings: StudentInputSetting;
  options: Option;
};
export type GetTeacherCompaniesByCompanyIdApiArg = {
  companyId: string;
};
export type PutTeacherCompaniesCompanyIdApiResponse = /** status 204 OK */ {
  message?: string;
};
export type PutTeacherCompaniesCompanyIdApiArg = {
  companyId: string;
  body: {
    options?: {
      use_account_lock_mail?: boolean;
      use_article_and_homework_mail?: boolean;
    };
  };
};
export type GetCompanyLimitOptionAsTeacherApiResponse = /** status 200 OK */ {
  limit_option: LimitOptionBase;
  total_count?: number;
};
export type GetCompanyLimitOptionAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  id: string;
};
export type GetReviewerCompanyGroupControllersAsTeacherApiResponse =
  /** status 200 OK */ {
    reviewer_company_groups: ReviewerCompanyGroupBase[];
    total_count: number;
  };
export type GetReviewerCompanyGroupControllersAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetTeacherCategoriesApiResponse = /** status 200 OK */ {
  grades: GradeCategoryInfo[];
  subjects: SubjectCategoryInfo[];
  major_categories: MajorCategoryInfo[];
  middle_categories: MiddleCategoryInfo[];
  minor_categories: MinorCategoryInfo[];
  texts?: TextCategoryInfo[];
};
export type GetTeacherCategoriesApiArg = void;
export type GetTeacherNotificationsApiResponse =
  /** status 200 OK */ Notifications;
export type GetTeacherNotificationsApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  sent?: string;
  perPage?: number;
  page?: number;
};
export type CreateNotificationAsTeacherApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 201 Created */ {
      message?: string;
    };
export type CreateNotificationAsTeacherApiArg = {
  body: {
    target_roles?: string[];
    target_user_tag_ids?: string[];
    target_company_ids?: string[];
    target_affiliate_ids?: string[];
    title: string;
    message: string;
    with_email?: boolean;
    publication_time?: string;
  };
};
export type GetOwnerNotificationIdApiResponse =
  /** status 200 OK */ Notification;
export type GetOwnerNotificationIdApiArg = {
  /** Notification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutTeacherNotificationIdApiResponse =
  /** status 202 Accepted */ Notification;
export type PutTeacherNotificationIdApiArg = {
  /** Notification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  notificationRequest: NotificationRequest;
};
export type DeleteTeacherNotificationIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteTeacherNotificationIdApiArg = {
  /** Notification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherArticlesApiResponse = /** status 200 OK */ {
  articles: ArticleBase[];
  total_count: number;
};
export type GetTeacherArticlesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  /** グループのID (GroupAffiliatePackage ID) */
  groupAffiliatePackageId?: string;
  /** グループのIDの配列 (GroupAffiliatePackage ID) */
  groupAffiliatePackageIds?: string[];
  /** articleのstatus */
  status?: ArticleStatus;
  /** kindのフィルタリング */
  kind?: ArticleKind;
  /** titleとbodyに対しての検索ワード */
  freeWord?: string;
  searchColumn?: ArticleSortedByColumn;
  searchOrder?: SortedByOrder;
  /** - 検索タイプを指定する
    - search_typeとsearch_wordは両方指定する必要あり */
  searchType?: TeacherArticleType;
  /** - search_typeとsearch_wordは両方指定する必要あり */
  searchWord?: string;
  /** user_tag_nameのフィルタリング */
  userTagName?: string;
  /** student_numberのフィルタリング */
  studentNumber?: string;
  /** common_tag_nameのフィルタリング */
  commonTagName?: string;
};
export type FetchArticleStatsAsTeacherApiResponse = /** status 200 OK */ {
  articles: ArticleStatsBase[];
};
export type FetchArticleStatsAsTeacherApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  /** 件数指定 */
  limit?: number;
  /** グループID */
  groupAffiliateId?: string;
};
export type FetchArticleWordCloudAsTeacherApiResponse =
  /** status 200 OK */ WordCloudBase;
export type FetchArticleWordCloudAsTeacherApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  listLen?: number;
  offset?: number;
  minNumOfChar?: number;
};
export type GetArticleSubmitAsTeacherApiResponse = /** status 200 OK */ {
  articles: ArticleSubmitBase[];
  total_count: number;
};
export type GetArticleSubmitAsTeacherApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  /** 件数指定 */
  limit?: number;
  /** 探究成果物のhashidの配列 */
  articleIds: string[];
};
export type GetTeacherArticlesByArticleIdApiResponse =
  /** status 200 OK */ ArticleAsTeacher;
export type GetTeacherArticlesByArticleIdApiArg = {
  articleId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type PutTeacherArticlesByArticleIdApiResponse =
  /** status 202 accepted */ ArticleAsTeacher;
export type PutTeacherArticlesByArticleIdApiArg = {
  articleId: string;
  body: {
    comment?: string | null;
    new_status: ArticleStatus;
    content_company_ids?: string[] | null;
    display_student_names_for_content_provider?: boolean;
    display_comments_for_content_provider?: boolean;
  };
};
export type GetTeacherArticlesByArticleIdCommentsApiResponse =
  /** status 200 OK */ {
    article_comments: ArticleComment[];
    total_count: number;
  };
export type GetTeacherArticlesByArticleIdCommentsApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  articleId: string;
};
export type PostTeacherArticlesByArticleIdCommentsApiResponse =
  /** status 201 OK */ ArticleDetailBase;
export type PostTeacherArticlesByArticleIdCommentsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  articleId: string;
  body: {
    title: string;
    body?: string;
  };
};
export type GetTeacherArticlesByArticleIdFeedbacksApiResponse =
  /** status 200 OK */ {
    article_feedbacks: ArticleFeedback[];
    total_count: number;
  };
export type GetTeacherArticlesByArticleIdFeedbacksApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  articleId: string;
};
export type PostTeacherArticlesByArticleIdFeedbacksApiResponse =
  /** status 201 OK */ ArticleFeedback;
export type PostTeacherArticlesByArticleIdFeedbacksApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  articleId: string;
  body: {
    body: string;
  };
};
export type DeleteTeacherArticlesByArticleIdFeedbacksAndFeedbackIdApiResponse =
  /** status 200 OK */ {
    message: string;
  };
export type DeleteTeacherArticlesByArticleIdFeedbacksAndFeedbackIdApiArg = {
  articleId: string;
  feedbackId: string;
};
export type PutArticleImageAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutArticleImageAsTeacherApiArg = {
  articleId: string;
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    file: Blob;
  };
};
export type GetTeacherArticleCommentsStudentCommentsListApiResponse =
  /** status 200 OK */ StudentCommentList;
export type GetTeacherArticleCommentsStudentCommentsListApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  searchType?: string;
  searchWord?: string;
};
export type DeleteTeacherArticlesByArticleIdArticlesCompaniesAndIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteTeacherArticlesByArticleIdArticlesCompaniesAndIdApiArg = {
  articleId: string;
  id: string;
};
export type PostTeacherUserTaggingsApiResponse = /** status 201 Created */
  | {
      number_of_tagging_users?: number;
      remaining_number_of_tagging_users?: number | null;
    }
  | /** status 202 Accepted */ {
      message?: string;
    };
export type PostTeacherUserTaggingsApiArg = {
  body: {
    user_id?: string;
    user_tag_id?: string;
  };
};
export type DeleteTeacherUserTaggingsApiResponse = /** status 200 OK */
  | {
      messaege?: string;
    }
  | /** status 202 Accepted */ {
      message?: string;
    };
export type DeleteTeacherUserTaggingsApiArg = {
  body: {
    user_id?: string;
    user_tag_id?: string;
  };
};
export type TeacherUserTaggingBatchCreateApiResponse =
  /** status 200 OK */ UserTaggingBatchCreateResponses;
export type TeacherUserTaggingBatchCreateApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  userTaggingBatchCreateParams: UserTaggingBatchCreateParams;
};
export type GetTeacherUserTagTypesApiResponse = /** status 200 OK */ {
  user_tag_types: UserTagType[];
  total_count: number;
};
export type GetTeacherUserTagTypesApiArg = {
  page?: number;
  perPage?: number;
  kind?: "nationwide" | "limited";
  roleType?: "student" | "teacher";
};
export type PostTeacherUserTagTypesApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostTeacherUserTagTypesApiArg = {
  body: {
    name?: string;
    kind?: UserTagTypeKind;
    teacher_visible?: boolean;
  };
};
export type PutTeacherUserTagTypesByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutTeacherUserTagTypesByIdApiArg = {
  id: string;
  body: {
    name?: string;
    teacher_visible?: boolean;
  };
};
export type DeleteTeacherUserTagTypesByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteTeacherUserTagTypesByIdApiArg = {
  id: string;
};
export type GetTeacherUserTagsByUserTagIdUsersApiResponse =
  /** status 200 OK */ {
    users: BasicUserInfo[];
    total_count: number;
  };
export type GetTeacherUserTagsByUserTagIdUsersApiArg = {
  userTagId: string;
  perPage?: number;
  page?: number;
};
export type GetTeacherUserTagsByIdDistributedStudentsApiResponse =
  /** status 200 OK */ {
    students: SimpleUserList[];
    total_count: number;
  };
export type GetTeacherUserTagsByIdDistributedStudentsApiArg = {
  id: string;
  perPage?: number;
  page?: number;
};
export type PutTeacherUserTagsSequenceApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutTeacherUserTagsSequenceApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sequences: {
      id: string;
      sequence_number?: number;
    }[];
  };
};
export type TeacherUserTagsBatchCreateApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type TeacherUserTagsBatchCreateApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  userTagsBatchCreateParams: UserTagsBatchCreateParams;
};
export type GetTeacherHomeworkTemplatesApiResponse = /** status 200 OK */ {
  homework_templates: HomeworkTemplateBaseForOwnerAndTeacher[];
  total_count: number;
};
export type GetTeacherHomeworkTemplatesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: HomeworkTemplateSearchType;
  searchWord?: string;
  /** common_tag_nameのフィルタリング */
  commonTagName?: string;
};
export type CreateHomeworkTemplateAsTeacherApiResponse =
  /** status 201 Created */ HomeworkTemplateForOwnerAndTeacher;
export type CreateHomeworkTemplateAsTeacherApiArg = {
  homeworkTemplateCreateRequest: HomeworkTemplateCreateRequest;
};
export type FetchHomeworkTemplateStatsAsTeacherApiResponse =
  /** status 200 OK */ {
    homework_templates: HomeworkTemplateStatsBase[];
  };
export type FetchHomeworkTemplateStatsAsTeacherApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  /** 件数指定 */
  limit?: number;
  /** グループID */
  groupAffiliateId?: string;
};
export type GetHomeworkTemplateDetailListAsTeacherApiResponse =
  /** status 200 OK */ {
    homework_templates: HomeworkTemplateDetailListBase[];
  };
export type GetHomeworkTemplateDetailListAsTeacherApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  homeworkTemplateIds: string[];
};
export type GetTeacherHomeworkTemplatesByHomeworkTemplateIdApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type GetTeacherHomeworkTemplatesByHomeworkTemplateIdApiArg = {
  homeworkTemplateId: string;
};
export type UpdateHomeworkTemplateAsTeacherApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type UpdateHomeworkTemplateAsTeacherApiArg = {
  homeworkTemplateId: string;
  homeworkTemplateUpdateRequest: HomeworkTemplateUpdateRequest;
};
export type DeleteHomeworkTemplateAsTeacherApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteHomeworkTemplateAsTeacherApiArg = {
  homeworkTemplateId: string;
};
export type GetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse =
  /** status 200 OK */ {
    homework_template_custom_fields: HomeworkTemplateCustomField[];
    total_count: number;
  };
export type GetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
  };
export type PostTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse =
  /** status 201 Created */ HomeworkTemplateCustomField;
export type PostTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    body: CustomFieldCommonRequestBody;
  };
export type GetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse =
  /** status 200 OK */ HomeworkTemplateCustomField;
export type GetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    /** HomeworkTemplateCustomField ID */
    id: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  };
export type PutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse =
  /** status 202 Accepted */ HomeworkTemplateCustomField;
export type PutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    /** HomeworkTemplateCustomField ID */
    id: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    body: CustomFieldCommonRequestBody;
  };
export type DeleteTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    /** HomeworkTemplateCustomField ID */
    id: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  };
export type PostHomeworkTemplateDuplicateAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostHomeworkTemplateDuplicateAsTeacherApiArg = {
  /** HomeworkTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetHomeworkTemplateAnswersAsTeacherApiResponse =
  /** status 200 OK */ {
    questions: {
      id: number;
      title: string;
    }[];
    answers: {
      name: string;
      question_id: number;
      value: string;
    }[][];
  };
export type GetHomeworkTemplateAnswersAsTeacherApiArg = {
  /** HomeworkTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type UpdateHomeworkTemplateDistributionAsTeacherApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type UpdateHomeworkTemplateDistributionAsTeacherApiArg = {
  id: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
  body: {
    comment?: string;
  };
};
export type AssignManagerToHomeworkTemplateDistributionAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type AssignManagerToHomeworkTemplateDistributionAsTeacherApiArg = {
  /** HomeworkTemplateDistribution ID */
  id: string;
  body: {
    teacher_id: string;
  };
};
export type SubmitHomeworkTemplateDistributionToReviewerAsTeacherApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type SubmitHomeworkTemplateDistributionToReviewerAsTeacherApiArg = {
  /** HomeworkTemplateDistribution ID */
  id: string;
  body: {
    comment?: string | null;
    image_contents?: Blob[];
  };
};
export type RevertHomeworkTemplateDistributionSubmittedToReviewerAsTeacherApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type RevertHomeworkTemplateDistributionSubmittedToReviewerAsTeacherApiArg =
  {
    /** HomeworkTemplateDistribution ID */
    id: string;
  };
export type ReturnHomeworkTemplateDistributionToStudentsAsTeacherApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type ReturnHomeworkTemplateDistributionToStudentsAsTeacherApiArg = {
  /** HomeworkTemplateDistribution ID */
  id: string;
};
export type FetchHomeworkTemplateChunksAsTeacherApiResponse =
  /** status 200 OK */
    | {
        chunks: HomeworkTemplateChunk[];
      }
    | /** status 201 Created */ {
        message?: string;
      };
export type FetchHomeworkTemplateChunksAsTeacherApiArg = {
  templateId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
};
export type CreateHomeworkTemplateChunkAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type CreateHomeworkTemplateChunkAsTeacherApiArg = {
  templateId: string;
  body: {
    homework_template_image_id?: string;
    image?: string;
    placement?: {
      top: number;
      left: number;
      right: number;
      bottom: number;
      natural_width: number;
      natural_height: number;
      page: number;
    };
  };
};
export type DeleteHomeworkTemplateChunkAsTeacherApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 201 Created */ {
        message?: string;
      };
export type DeleteHomeworkTemplateChunkAsTeacherApiArg = {
  templateId: string;
  id: string;
};
export type PostHomeworkTemplateImageAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostHomeworkTemplateImageAsTeacherApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type PutHomeworkTemplateImageAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutHomeworkTemplateImageAsTeacherApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  /** HomeworkTemplateImage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteHomeworkTemplateImageAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteHomeworkTemplateImageAsTeacherApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  /** HomeworkTemplateImage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherUserTagsApiResponse = /** status 200 OK */ {
  user_tags: UserTag[];
  total_count: number;
};
export type GetTeacherUserTagsApiArg = {
  kind?: "nationwide" | "limited";
  page?: number;
  perPage?: number;
  roleType?: UserTagTypeRoleType;
};
export type PostTeacherUserTagsApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostTeacherUserTagsApiArg = {
  body: {
    user_tag_type_id: string;
    name: string;
    limit_number?: number;
  };
};
export type GetTeacherUserTagsByIdApiResponse =
  /** status 200 OK */ UserTagDetail;
export type GetTeacherUserTagsByIdApiArg = {
  id: string;
};
export type PutTeacherUserTagsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutTeacherUserTagsByIdApiArg = {
  id: string;
  body: {
    name?: string;
    limit_number?: number;
  };
};
export type DeleteTeacherUserTagsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteTeacherUserTagsByIdApiArg = {
  id: string;
};
export type GetTeacherPblReportTemplatesApiResponse = /** status 200 OK */ {
  templates: PblReportTemplateBase[];
  total_count: number;
};
export type GetTeacherPblReportTemplatesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostTeacherPblReportTemplatesApiResponse =
  /** status 201 OK */ PblReportTemplateDetailsForTeacher;
export type PostTeacherPblReportTemplatesApiArg = {
  body: {
    title: string;
    due_date_time?: string;
  };
};
export type FetchPblReportTemplateStatsAsTeacherApiResponse =
  /** status 200 OK */ {
    templates: PblReportTemplateStats[];
  };
export type FetchPblReportTemplateStatsAsTeacherApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  /** 件数指定 */
  limit?: number;
  /** グループID */
  groupAffiliateId?: string;
};
export type FetchPblReportTemplateAsTeacherApiResponse =
  /** status 200 OK */ PblReportTemplateDetailsForTeacher;
export type FetchPblReportTemplateAsTeacherApiArg = {
  /** テンプレートID */
  id: string;
};
export type PutTeacherPblReportTemplatesByIdApiResponse =
  /** status 200 OK */ PblReportTemplateDetailsForTeacher;
export type PutTeacherPblReportTemplatesByIdApiArg = {
  /** テンプレートID */
  id: string;
  body: {
    title?: string;
    due_date_time?: string;
    event?: "distribute" | "fulfill";
  };
};
export type GetTeacherPblReportTemplatesByIdAnswersApiResponse =
  /** status 200 OK */ {
    pbl_report_template_answers?: PblReportAnswerBase[];
  };
export type GetTeacherPblReportTemplatesByIdAnswersApiArg = {
  page?: number;
  perPage?: number;
  /** テンプレートID */
  id: string;
  /** グループからフィルタリングする */
  groupAffiliateId?: string;
};
export type GetTeacherPblReportTemplatesByIdItemsApiResponse =
  /** status 200 OK */ PblReportTextBase[];
export type GetTeacherPblReportTemplatesByIdItemsApiArg = {
  /** テンプレートID */
  id: string;
};
export type GetTeacherPblReportTemplatesFrameworksApiResponse =
  /** status 200 OK */ {
    report_template_frameworks?: Schema[];
    total_count?: number;
  };
export type GetTeacherPblReportTemplatesFrameworksApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetTeacherPblReportAnswersByIdApiResponse =
  /** status 200 OK */ PblReportAnswerDetailsForTeacher;
export type GetTeacherPblReportAnswersByIdApiArg = {
  /** 回答ID */
  id: string;
};
export type GetTeacherPblReportAnswersApiResponse = /** status 200 OK */ {
  answers: PblReportAnswerBase[];
  total_count: number;
};
export type GetTeacherPblReportAnswersApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostTeacherPblReportAnswersApiResponse =
  /** status 201 Created */ PblReportAnswerBase;
export type PostTeacherPblReportAnswersApiArg = {
  body: {
    student_id: string;
    template_id: string;
  };
};
export type GetTeacherPblReportCommentsApiResponse =
  /** status 200 OK */ PblReportCommentBase[];
export type GetTeacherPblReportCommentsApiArg = {
  outputId: string;
  outputType: "answer" | "answer_item";
};
export type PostTeacherPblReportCommentsApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostTeacherPblReportCommentsApiArg = {
  body: {
    message?: string;
    output_id?: string;
    output_type?: "answer" | "answer_item";
  };
};
export type PutTeacherPblReportCommentsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutTeacherPblReportCommentsByIdApiArg = {
  /** 更新したいコメントのID */
  id: string;
  body: {
    message?: string;
  };
};
export type GetTeacherPblReportItemsApiResponse =
  /** status 200 OK */ PblReportTextBase[];
export type GetTeacherPblReportItemsApiArg = {
  /** 元となるテンプレートのID */
  templateId: string;
};
export type PostTeacherPblReportItemsApiResponse =
  /** status 201 Created */ PblReportTextBase;
export type PostTeacherPblReportItemsApiArg = {
  body: {
    template_id?: string;
    type?: PblReportItemTypeEnum;
    title?: string;
    description?: string;
    sequence?: number;
    text_min_char_size?: number;
    has_title_required?: boolean;
  };
};
export type PutTeacherPblReportItemsByIdApiResponse =
  /** status 200 OK */ PblReportTextBase;
export type PutTeacherPblReportItemsByIdApiArg = {
  /** 更新したい出題アイテムのID */
  id: string;
  body: {
    sequence?: number;
    title?: string;
    description?: string;
    text_example?: string;
    text_min_char_size?: number;
    has_title_required?: boolean;
  };
};
export type DeleteTeacherPblReportItemsByIdApiResponse =
  /** status 204 NoContent */ string;
export type DeleteTeacherPblReportItemsByIdApiArg = {
  /** 削除したいコメントのID */
  id: string;
};
export type FetchPblCardBookmarksAsTeacherApiResponse = /** status 200 OK */ {
  bookmarks?: PblCardBookmarkList[];
  total_count?: number;
};
export type FetchPblCardBookmarksAsTeacherApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** studentのaffiliate_id */
  studentId?: string;
  /** グループのID (GroupAffiliatePackage ID) */
  groupAffiliateId?: string;
  /** ransackで検索ワードから検索する */
  searchWord?: string;
  /** ransackの検索する要素 */
  searchType?: string;
  /** - 作成日から範囲検索する
    - 開始日
    - start_dateとend_dateは両方必須 */
  startDate?: string;
  /** - 作成日から範囲検索する
    - 終了日
    - start_dateとend_dateは両方必須 */
  endDate?: string;
  /** - ユーザータグに紐付いている生徒の調査メモを検索する */
  userTagId?: string;
  /** - sort_typeで検索をかける */
  searchSourceType?: PblCardBookmarkSourceType;
  /** 全ての検索に対してのワード */
  freeWord?: string;
  /** studentの名前を検索 */
  userName?: string;
  /** studentの番号を検索 */
  studentNumber?: string;
  searchColumn?: BookmarkSortedByColumn;
  searchOrder?: SortedByOrder;
  /** common_tag_nameのフィルタリング */
  commonTagName?: string;
};
export type PostPblCardBookmarkAsTeacherApiResponse =
  /** status 201 Created */ PblCardBookmarkBase;
export type PostPblCardBookmarkAsTeacherApiArg = {
  postPblCardBookmarkRequestBody: PostPblCardBookmarkRequestBody;
};
export type FetchPblCardBookmarkAsTeacherApiResponse =
  /** status 200 OK */ PblCardBookmarkBase;
export type FetchPblCardBookmarkAsTeacherApiArg = {
  id: string;
};
export type PutPblCardBookmarkAsTeacherApiResponse =
  /** status 200 OK */ PblCardBookmarkBase;
export type PutPblCardBookmarkAsTeacherApiArg = {
  id: string;
  putPblCardBookmarkRequestBody: PutPblCardBookmarkRequestBody;
};
export type DeletePblCardBookmarkAsTeacherApiResponse =
  /** status 204 No content */ undefined;
export type DeletePblCardBookmarkAsTeacherApiArg = {
  id: string;
};
export type PutPblCardBookmarkWysiwygBodyAsTeacherApiResponse =
  /** status 200 OK */ PblCardBookmarkBase;
export type PutPblCardBookmarkWysiwygBodyAsTeacherApiArg = {
  id: string;
  body: {
    wysiwyg_body?: string;
  };
};
export type FetchPblCardBookmarkStatsAsTeacherApiResponse =
  /** status 200 OK */ {
    bookmarks: PblCardBookmarkStats[];
  };
export type FetchPblCardBookmarkStatsAsTeacherApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  /** グループID */
  groupAffiliateId?: string;
};
export type FetchPblCardBookmarkCommentsAsTeacherApiResponse =
  /** status 200 OK */ {
    pbl_card_comments?: PblCardCommentBase[];
    total_count?: number;
  };
export type FetchPblCardBookmarkCommentsAsTeacherApiArg = {
  bookmarkId: string;
  page?: number;
  perPage?: number;
};
export type CreatePblCardBookmarkCommentAsTeacherApiResponse =
  /** status 201 Created */ PblCardCommentBase;
export type CreatePblCardBookmarkCommentAsTeacherApiArg = {
  body: {
    bookmark_id: string;
    body: string;
  };
};
export type UpdatePblCardBookmarkCommentAsTeacherApiResponse =
  /** status 200 OK */ PblCardCommentBase;
export type UpdatePblCardBookmarkCommentAsTeacherApiArg = {
  /** 更新したいコメントのID */
  id: string;
  body: {
    body?: string;
  };
};
export type DeletePblCardBookmarkCommentAsTeacherApiResponse =
  /** status 204 No content */ undefined;
export type DeletePblCardBookmarkCommentAsTeacherApiArg = {
  /** 削除したいコメントのID */
  id: string;
};
export type DeletePblCardBookmarkImageAsTeacherApiResponse = unknown;
export type DeletePblCardBookmarkImageAsTeacherApiArg = {
  id: string;
};
export type GetPblCardBookmarkStampsAsTeacherApiResponse =
  /** status 200 OK */ {
    stamp_fields: StampBase[];
    total_count: number;
  };
export type GetPblCardBookmarkStampsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** スタンプを作成するモデル名 */
  stampableType: string;
  /** スタンプを作成するモデルのID */
  stampableId: string;
  kind?: StampType;
};
export type PostPblCardBookmarkStampAsTeacherApiResponse =
  /** status 200 OK */ StampBase;
export type PostPblCardBookmarkStampAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    stampable_type: StampableType;
    stampable_id: string;
    actor_id: string;
    kind: StampType;
  };
};
export type PutPblCardBookmarkStampAsTeacherApiResponse =
  /** status 200 OK */ StampBase;
export type PutPblCardBookmarkStampAsTeacherApiArg = {
  /** 更新したいスタンプのID */
  id: string;
  body: {
    kind: StampType;
  };
};
export type DeletePblCardBookmarkStampAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeletePblCardBookmarkStampAsTeacherApiArg = {
  /** 削除したいスタンプのID */
  id: string;
};
export type GetTeacherMaterialDistributionHistoriesListApiResponse =
  /** status 200 OK */ {
    material_distribution_histories: MaterialDistributionHistory[];
    total_count: number;
  };
export type GetTeacherMaterialDistributionHistoriesListApiArg = {
  page?: number;
  perPage?: number;
};
export type GetTeacherMaterialDistributionHistoriesListStudentsApiResponse =
  /** status 200 OK */ MaterialDistributionHistoryListStudents;
export type GetTeacherMaterialDistributionHistoriesListStudentsApiArg = {
  distributionId?: string;
  materialId?: string;
};
export type GetTeacherMaterialDistributionHistoriesSearchByUserIdApiResponse =
  /** status 200 OK */ {
    material_distribution_histories: MaterialDistributionHistorySearchByUserId[];
    total_count: number;
  };
export type GetTeacherMaterialDistributionHistoriesSearchByUserIdApiArg = {
  userId: string;
  page?: number;
  perPage?: number;
};
export type GetSteamContentsAsTeacherApiResponse = /** status 200 OK */ {
  steam_contents?: PblSteamContent[];
  total_count?: number;
};
export type GetSteamContentsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  isBookmarked?: boolean;
  isRecommended?: boolean;
  searchType?: TeacherContentSearchType;
  searchWord?: string;
};
export type GetSteamContentAsTeacherApiResponse =
  /** status 200 OK */ StudentPblSteamContentDetail;
export type GetSteamContentAsTeacherApiArg = {
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type SearchTeacherSteamContentsApiResponse = /** status 200 OK */ {
  steam_contents: StudentPblSteamContentDetail[];
  total_count: number;
};
export type SearchTeacherSteamContentsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  difficulty: number;
  aspect: number;
  isScientific: boolean;
  page?: number;
  perPage?: number;
};
export type GetTeacherSteamCommentsApiResponse = /** status 200 OK */ {
  steam_content_comments?: PblSteamCommentList[];
  total_count?: number;
};
export type GetTeacherSteamCommentsApiArg = {
  contentTitle?: string;
  lectureTitle?: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostTeacherSteamCommentsApiResponse =
  /** status 201 Created */ PblSteamCommentBase;
export type PostTeacherSteamCommentsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** Pbl::Steam::Lecture ID */
  lectureId: string;
  body: {
    is_public?: boolean;
    body?: string;
  };
};
export type PutTeacherSteamContentsIdApiResponse =
  /** status 202 Accepted */ PblSteamCommentBase;
export type PutTeacherSteamContentsIdApiArg = {
  /** Pbl::Steam::Comment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    is_public?: boolean;
    body?: string;
  };
};
export type DeleteTeacherSteamContentsIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteTeacherSteamContentsIdApiArg = {
  /** Pbl::Steam::Comment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSteamLecturesAsTeacherApiResponse = /** status 200 OK */ {
  steam_lectures: PblSteamLecture[];
  total_count: number;
};
export type GetSteamLecturesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  slContentId: number;
};
export type PostTeacherSteamLecturesApiResponse = /** status 200 OK */
  | {
      is_registered: boolean;
    }
  | /** status 201 Created */ PblSteamLecture
  | /** status 202 Accepted */ PblSteamLecture;
export type PostTeacherSteamLecturesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  slContentId: number;
  createSteamLectureRequestBody: CreateSteamLectureRequestBody;
};
export type GetTeacherSteamLecturesIdApiResponse =
  /** status 200 OK */ PblSteamLecture;
export type GetTeacherSteamLecturesIdApiArg = {
  /** SteamライブラリーのレクチャーIDを指定する。 */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherSteamLectureCommentsApiResponse = /** status 200 OK */ {
  teacher_comments?: PblSteamCommentBase[];
  student_comments?: PblSteamCommentBase[];
  teacher_comments_total_count?: number;
  student_comments_total_count?: number;
};
export type GetTeacherSteamLectureCommentsApiArg = {
  /** SteamライブラリーのレクチャーIDを指定する。 */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetSteamContentBookmarksAsTeacherApiResponse =
  /** status 200 OK */ {
    bookmarks: ContentBookmarkBase[];
    total_count: number;
  };
export type GetSteamContentBookmarksAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostSteamContentBookmarksAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostSteamContentBookmarksAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    pbl_steam_content_id: string;
  };
};
export type DeleteSteamContentBookmarksAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSteamContentBookmarksAsTeacherApiArg = {
  /** Bookmark ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblSteamStudentContentsAsTeacherApiResponse =
  /** status 200 OK */ {
    steam_student_contents: StudentContentList[];
    total_count: number;
  };
export type GetPblSteamStudentContentsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostPblSteamStudentContentAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostPblSteamStudentContentAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    student_affiliate_id: string;
    sl_content_id: number;
  };
};
export type PutPblSteamStudentContentAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPblSteamStudentContentAsTeacherApiArg = {
  /** Pbl::Steam::StudentContent ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    student_affiliate_id: string;
    sl_content_id: number;
  };
};
export type DeletePblSteamStudentContentAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblSteamStudentContentAsTeacherApiArg = {
  /** Pbl::Steam::StudentContent ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSteamContentRecommendationsAsTeacherApiResponse =
  /** status 200 OK */ {
    steam_content_recommendations: ContentRecommendationBase[];
    total_count: number;
  };
export type GetSteamContentRecommendationsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostSteamContentRecommendationAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostSteamContentRecommendationAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    pbl_steam_content_id: string;
  };
};
export type DeleteSteamContentRecommendationAsTeacherApiResponse =
  /** status 202 200 OK */ {
    message?: string;
  };
export type DeleteSteamContentRecommendationAsTeacherApiArg = {
  /** ContentRecommendation ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherPlanItemsApiResponse = /** status 200 OK */ {
  plan_items: TeacherPblPlanItemDetails[];
  total_count: number;
};
export type GetTeacherPlanItemsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  status?: PblPlanItemStatus;
};
export type PostTeacherPlanItemsApiResponse =
  /** status 201 Created */ TeacherPblPlanItemDetails;
export type PostTeacherPlanItemsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    class_minutes?: number;
    plan_years?: number;
    class_start_date?: string;
    class_end_date?: string;
    policy_summary?: string;
    why?: string;
    how?: string;
    what?: string;
    first_year_summary?: string;
    second_year_summary?: string;
    third_year_summary?: string;
    status?: string;
  };
};
export type GetTeacherPlanItemsIdApiResponse =
  /** status 200 OK */ TeacherPblPlanItemDetails;
export type GetTeacherPlanItemsIdApiArg = {
  /** Pbl::Plan::Item ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutTeacherPlanItemsIdApiResponse =
  /** status 202 Accepted */ TeacherPblPlanItemDetails;
export type PutTeacherPlanItemsIdApiArg = {
  /** Pbl::Plan::Item ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    class_minutes?: number;
    plan_years?: number;
    class_start_date?: string;
    class_end_date?: string;
    policy_summary?: string;
    why?: string;
    how?: string;
    what?: string;
    first_year_summary?: string;
    second_year_summary?: string;
    third_year_summary?: string;
    status?: string;
  };
};
export type DeleteTeacherPlanItemsIdApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteTeacherPlanItemsIdApiArg = {
  /** Pbl::Plan::Item ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherGetClassDateListApiResponse = /** status 200 OK */ {
  days?: {
    year?: {
      wday?: number;
      date?: string;
    }[];
  };
};
export type GetTeacherGetClassDateListApiArg = {
  /** 開始日: YYYY/MM/DD */
  startDate: string;
  /** 終了日: YYYY/MM/DD */
  endDate: string;
  /** - 曜日： 0〜6を指定する。
    - 0: Sunday
    - 1: Monday
    - 2: Tuesday
    - 3: Wednesday
    - 4: Thursday
    - 5: Friday
    - 6: Saturday */
  dayOfWeeks: string[];
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherPlanClassesApiResponse = /** status 200 OK */ {
  plan_classes: TeacherPblPlanClass[];
  total_count: number;
};
export type GetTeacherPlanClassesApiArg = {
  /** Pbl::Plan::Item ID (or all in get) */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
  status?: PblPlanItemStatus;
};
export type PostTeacherPlanClassesApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostTeacherPlanClassesApiArg = {
  /** Pbl::Plan::Item ID (or all in get) */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    plan_classes?: {
      year: number;
      number: number;
      date: string;
      description: string;
      is_skip: boolean;
      article_creation_time: boolean;
      lecture_id?: string;
      class_minutes?: number;
      plan_contents?: {
        categorizable_type: PlanContentsCategorizableType;
        categorizable_id: string;
        categorizable_title?: string;
      }[];
      title?: string;
    }[];
  };
};
export type PutTeacherPlanClassesIdApiResponse =
  /** status 202 Accepted */ TeacherPblPlanClass;
export type PutTeacherPlanClassesIdApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Class ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    year?: number;
    number?: number;
    date?: string;
    description?: string;
    is_skip?: boolean;
    article_creation_time?: boolean;
    schedule_id?: string;
    class_minutes?: number;
    title?: string;
  };
};
export type DeleteTeacherPlanClassesIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteTeacherPlanClassesIdApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Class ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblPlanContentsAsTeacherApiResponse = /** status 200 OK */ {
  plan_contents: PblPlanContentNav[];
  total_count: number;
};
export type GetPblPlanContentsAsTeacherApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Class ID */
  classId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostPblPlanContentsAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostPblPlanContentsAsTeacherApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Class ID */
  classId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    plan_contents: {
      categorizable_type: PlanContentsCategorizableType;
      categorizable_id: string;
    }[];
  };
};
export type DeletePblPlanContentsAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeletePblPlanContentsAsTeacherApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Class ID */
  classId: string;
  /** Pbl::Plan::Content ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostTeacherPlanDaysApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostTeacherPlanDaysApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** Pbl::Plan::Item ID */
  itemId: string;
  body: {
    days?: {
      class_date?: PblPlanDayType;
      start_time?: string;
    }[];
  };
};
export type PutTeacherPlanDaysIdApiResponse =
  /** status 202 Accepted */ TeacherPblPlanDay;
export type PutTeacherPlanDaysIdApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Day ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    class_date?: PblPlanDayType;
    start_time?: string;
  };
};
export type DeleteTeacherPlanDaysIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteTeacherPlanDaysIdApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Day ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherPlanStudentsApiResponse = /** status 200 OK */ {
  plan_students: TeacherPblPlanStudent[];
  total_count: number;
};
export type GetTeacherPlanStudentsApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** グループID */
  groupAffiliateId?: string;
};
export type PostTeacherPlanStudentsApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostTeacherPlanStudentsApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    student_ids?: string[];
  };
};
export type PutTeacherPlanStudentsIdApiResponse =
  /** status 202 Accepted */ TeacherPblPlanStudent;
export type PutTeacherPlanStudentsIdApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Student ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    student_id?: string;
  };
};
export type DeleteTeacherPlanStudentsIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteTeacherPlanStudentsIdApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Student ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherInquiriesApiResponse = /** status 200 OK */ {
  inquiries: InquirySimpleListBase[];
  total_count: number;
};
export type GetTeacherInquiriesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetTeacherInquiriesByIdApiResponse =
  /** status 200 OK */ InquirySimpleBase;
export type GetTeacherInquiriesByIdApiArg = {
  /** inquiries ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTheNumberOfNewCommentInquiriesAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type GetTheNumberOfNewCommentInquiriesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostTeacherInquiryCommentsApiResponse =
  /** status 201 Created */ InquiryComment;
export type PostTeacherInquiryCommentsApiArg = {
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
  };
};
export type PutTeacherInquiryCommentsByIdApiResponse =
  /** status 202 Accepted */ InquiryComment;
export type PutTeacherInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
    status?: string;
  };
};
export type DeleteTeacherInquiryCommentsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteTeacherInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherSponsorInfosApiResponse = /** status 200 OK */ {
  sponsors: SponsorInfoListBase[];
  total_count: number;
  all_sponsor_names?: string[];
  all_sponsor_tags?: string[];
};
export type GetTeacherSponsorInfosApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: SponsorInfosSearchType;
  searchWord?: string;
};
export type GetTeacherSponsorInfosIdApiResponse =
  /** status 200 OK */ SponsorInfoDetailsBase;
export type GetTeacherSponsorInfosIdApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorProjectsAsTeacherApiResponse = /** status 200 OK */ {
  projects: SponsorProjectAndTagsBase[];
  custom_content?: ModelBase & {
    title: string;
  };
  total_count: number;
  all_project_names?: string[];
  all_project_tags?: string[];
};
export type FetchSponsorProjectsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  bookmarked?: boolean;
  recommended?: boolean;
  searchType?: SponsorProjectsSearchType;
  searchWord?: string;
};
export type FetchOneSponsorProjectAsTeacherApiResponse =
  /** status 200 OK */ SponsorProjectDetailsBase;
export type FetchOneSponsorProjectAsTeacherApiArg = {
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorProjectsForCarouselAsTeacherApiResponse =
  /** status 200 OK */ {
    projects: SponsorProjectBase[];
    total_count: number;
  };
export type FetchSponsorProjectsForCarouselAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  numberOfProjects?: number;
  sortType?: SponsorProjectsSortType;
};
export type FetchSponsorProjectListAsTeacherApiResponse = /** status 200 OK */ {
  projects: HasIdAndName[];
  total_count: number;
};
export type FetchSponsorProjectListAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  professorAffiliateId: string;
};
export type FetchSponsorLecturesAsTeacherApiResponse = /** status 200 OK */ {
  project_lectures: SponsorLectureDetailsBase[];
  total_count: number;
};
export type FetchSponsorLecturesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  projectId: string;
};
export type FetchSponsorLectureAsTeacherApiResponse =
  /** status 200 OK */ SponsorLectureDetailsBase;
export type FetchSponsorLectureAsTeacherApiArg = {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorCommentListAsTeacherApiResponse = /** status 200 OK */ {
  teacher_comments: PblProjectCommentBase[];
  student_comments: PblProjectCommentBase[];
  current_user_comment: PblProjectCommentBase;
  teacher_comments_total_count: number;
  student_comments_total_count: number;
};
export type FetchSponsorCommentListAsTeacherApiArg = {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type FetchSponsorProjectBookmarksAsTeacherApiResponse =
  /** status 200 OK */ {
    bookmarks: SponsorProjectBookmarkBase[];
    total_count: number;
  };
export type FetchSponsorProjectBookmarksAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type AddSponsorProjectBookmarkAsTeacherApiResponse =
  /** status 201 Created */ SponsorProjectBookmarkBase;
export type AddSponsorProjectBookmarkAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    project_id: string;
  };
};
export type FetchSponsorProjectBookmarkByProjectAsTeacherApiResponse =
  /** status 200 OK */ {
    id?: string | null;
  } & SponsorProjectBookmarkBase;
export type FetchSponsorProjectBookmarkByProjectAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  projectId: string;
};
export type RemoveSponsorProjectBookmarkAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type RemoveSponsorProjectBookmarkAsTeacherApiArg = {
  /** ブックマークID（Sponsor::Bookmark ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorProjectRecommendsAsTeacherApiResponse =
  /** status 200 OK */ {
    recommends: SponsorProjectRecommendBase[];
    total_count: number;
  };
export type FetchSponsorProjectRecommendsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type AddSponsorProjectRecommendAsTeacherApiResponse =
  /** status 201 Created */ SponsorProjectRecommendBase;
export type AddSponsorProjectRecommendAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    project_id: string;
  };
};
export type RemoveSponsorProjectRecommendAsTeacherApiResponse =
  /** status 200 OK */ {
    message?: string;
  };
export type RemoveSponsorProjectRecommendAsTeacherApiArg = {
  /** おすすめID（Sponsor::Recommend ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorCommentsAsTeacherApiResponse = /** status 200 OK */ {
  sponsor_lecture_comments: TeacherPblProjectCommentList[];
  total_count: number;
};
export type FetchSponsorCommentsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostSponsorCommentsAsTeacherApiResponse =
  /** status 201 Accepted */ PblProjectCommentBase;
export type PostSponsorCommentsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  lectureId: string;
  body: {
    body: string;
  };
};
export type DeleteTeacherSponsorCommentsApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteTeacherSponsorCommentsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PutPblSponsorCommentAsTeacherApiResponse =
  /** status 202 Accepted */ PblProjectCommentBase;
export type PutPblSponsorCommentAsTeacherApiArg = {
  /** 企業プロジェクトのレクチャーコメントID（Pbl::Sponsor::Comment ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
  };
};
export type DeletePblSponsorCommentAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeletePblSponsorCommentAsTeacherApiArg = {
  /** 企業プロジェクトのレクチャーコメントID（Pbl::Sponsor::Comment ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherContentCompaniesApiResponse = /** status 200 OK */ {
  content_companies: CompanyBase[];
  total_count: number;
};
export type GetTeacherContentCompaniesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetSchedulesAsTeacherApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type GetSchedulesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** 件数指定 */
  limit?: number;
};
export type GetStudentLogsStudyLogsAsTeacherApiResponse = /** status 200 OK */ {
  study_logs: StudyLogBase[];
  total_count: number;
};
export type GetStudentLogsStudyLogsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** グループID */
  groupAffiliateId?: string;
};
export type GetStudentLogsStudyByStudentAsTeacherApiResponse =
  /** status 200 OK */ {
    study_logs: StudyByStudentBase[];
    total_count: number;
  };
export type GetStudentLogsStudyByStudentAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** グループID */
  groupAffiliateId?: string;
};
export type GetStudentLogsStatsAsTeacherApiResponse = /** status 200 OK */ {
  study_logs: StudyLogBase[];
  daily_log: DailyLogStatsBase;
  total_count: number;
};
export type GetStudentLogsStatsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  studentAffiliateId: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
};
export type GetStudentLogsActivityAsTeacherApiResponse = /** status 200 OK */ {
  this_week_daily_logs: ActivityBase[];
  last_week_daily_logs: ActivityBase[];
};
export type GetStudentLogsActivityAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** グループID */
  groupAffiliateId?: string;
};
export type GetStudentLogsDeliverableListAsTeacherApiResponse =
  /** status 200 OK */ {
    study_logs: DeliverableListBase[];
    total_count: number;
  };
export type GetStudentLogsDeliverableListAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** グループID */
  groupAffiliateId?: string;
  /** 生徒名から検索 */
  search?: string;
  startDate?: string;
  endDate?: string;
  sortedBy?: "numberAsc" | "numberDesc" | "nameAsc" | "nameDesc";
  tagName?: string;
};
export type GetStudentLogsMaterialWorksAsTeacherApiResponse =
  /** status 200 OK */ {
    material_works: StudentLogDeliverableMaterialWorkBase[];
    total_count: number;
  };
export type GetStudentLogsMaterialWorksAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  studentAffiliateId: string;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudentLogsHomeworksAsTeacherApiResponse = /** status 200 OK */ {
  homeworks: StudentLogDeliverableHomeWorkBase[];
  total_count: number;
};
export type GetStudentLogsHomeworksAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  studentAffiliateId: string;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudentLogsPblAnswersAsTeacherApiResponse =
  /** status 200 OK */ {
    pbl_answers: StudentLogDeliverablePblAnswerBase[];
    total_count: number;
  };
export type GetStudentLogsPblAnswersAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  studentAffiliateId: string;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudentLogsLearningPackagesAsTeacherApiResponse =
  /** status 200 OK */ {
    learning_packages: StudentLogDeliverableLearningPackageBase[];
    total_count: number;
  };
export type GetStudentLogsLearningPackagesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  studentAffiliateId: string;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudentLogsArticlesAsTeacherApiResponse = /** status 200 OK */ {
  articles: StudentLogDeliverableArticleBase[];
  total_count: number;
};
export type GetStudentLogsArticlesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  studentAffiliateId: string;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudentLogsIdealsAsTeacherApiResponse = /** status 200 OK */ {
  ideals: StudentLogDeliverableIdealBase[];
  total_count: number;
};
export type GetStudentLogsIdealsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  studentAffiliateId: string;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudentLogsBookmarksAsTeacherApiResponse = /** status 200 OK */ {
  bookmarks: StudentLogDeliverableListBase[];
  total_count: number;
};
export type GetStudentLogsBookmarksAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  studentAffiliateId: string;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetRubricsAsTeacherApiResponse = /** status 200 OK */ {
  rubrics: RubricBase[];
  total_count: number;
};
export type GetRubricsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostRubricsAsTeacherApiResponse =
  /** status 201 Created */ RubricBase;
export type PostRubricsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    status: RubricStatus;
  };
};
export type GetRubricAsTeacherApiResponse = /** status 200 OK */ RubricBase;
export type GetRubricAsTeacherApiArg = {
  /** ルーブリックID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutRubricAsTeacherApiResponse =
  /** status 202 Accepted */ RubricBase;
export type PutRubricAsTeacherApiArg = {
  /** ルーブリックID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    status: RubricStatus;
  };
};
export type DeleteRubricAsTeacherApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteRubricAsTeacherApiArg = {
  /** ルーブリックID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostRubricItemsAsTeacherApiResponse =
  /** status 201 Created */ RubricItemBase;
export type PostRubricItemsAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    curriculum_type: RubricItemCurriculumType;
    perspective: string;
    level_1?: string;
    level_2?: string;
    level_3?: string;
    level_4?: string;
    level_5?: string;
  };
};
export type GetRubricItemAsTeacherApiResponse =
  /** status 200 OK */ RubricItemBase;
export type GetRubricItemAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリックアイテムID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutRubricItemAsTeacherApiResponse =
  /** status 202 Accepted */ RubricItemBase;
export type PutRubricItemAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリックアイテムID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    curriculum_type: RubricItemCurriculumType;
    perspective: string;
    level_1?: string;
    level_2?: string;
    level_3?: string;
    level_4?: string;
    level_5?: string;
  };
};
export type DeleteRubricItemAsTeacherApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteRubricItemAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリックアイテムID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetRubricEvaluatesAsTeacherApiResponse = /** status 200 OK */ {
  rubric_evaluates: RubricEvaluateBase[];
  total_count: number;
};
export type GetRubricEvaluatesAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchColumn?: RubricEvaluateSortedByColumn;
  searchOrder?: SortedByOrder;
};
export type PostRubricEvaluateAsTeacherApiResponse =
  /** status 201 Created */ RubricEvaluateBase;
export type PostRubricEvaluateAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    description: string;
  };
};
export type GetRubricEvaluateAsTeacherApiResponse =
  /** status 200 OK */ RubricEvaluateBase;
export type GetRubricEvaluateAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutRubricEvaluateAsTeacherApiResponse =
  /** status 202 Accepted */ RubricEvaluateBase;
export type PutRubricEvaluateAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    description?: string;
  };
};
export type DeleteRubricEvaluateAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteRubricEvaluateAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutRubricEvaluateSequenceAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutRubricEvaluateSequenceAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sequences: {
      id: string;
      sequence_number?: number;
    }[];
  };
};
export type PostRubricEvaluateStudentAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostRubricEvaluateStudentAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  rubricEvaluateId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    student_affiliate_id: string;
    comment?: string;
    student_rates?: {
      rubric_item_id: string;
      score: number;
    }[];
  };
};
export type GetRubricEvaluateStudentAsTeacherApiResponse =
  /** status 200 OK */ RubricEvaluateStudentBase;
export type GetRubricEvaluateStudentAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  rubricEvaluateId: string;
  /** ルーブリック生徒評価ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutRubricEvaluateStudentAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutRubricEvaluateStudentAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  rubricEvaluateId: string;
  /** ルーブリック生徒評価ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    comment?: string;
  };
};
export type DeleteRubricEvaluateStudentAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteRubricEvaluateStudentAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  rubricEvaluateId: string;
  /** ルーブリック生徒評価ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutRubricEvaluateStudentSequenceAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutRubricEvaluateStudentSequenceAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  rubricEvaluateId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sequences: {
      id: string;
      sequence_number?: number;
    }[];
  };
};
export type PostRubricEvaluateStudentRateAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostRubricEvaluateStudentRateAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  rubricEvaluateId: string;
  /** ルーブリック生徒評価ID */
  rubricEvaluateStudentId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    rubric_item_id: string;
    score: number;
  };
};
export type PutRubricEvaluateStudentRateAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutRubricEvaluateStudentRateAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  rubricEvaluateId: string;
  /** ルーブリック生徒評価ID */
  rubricEvaluateStudentId: string;
  /** ルーブリック生徒評価ポイントID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    score: number;
  };
};
export type DeleteRubricEvaluateStudentRateAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteRubricEvaluateStudentRateAsTeacherApiArg = {
  /** ルーブリックID */
  rubricId: string;
  /** ルーブリック評価ID */
  rubricEvaluateId: string;
  /** ルーブリック生徒評価ID */
  rubricEvaluateStudentId: string;
  /** ルーブリック生徒評価ポイントID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetLearningTemplatePackageDistributionsAsTeacherApiResponse =
  /** status 200 OK */ {
    learning_template_package_distribution_settings: LearningTemplatePackageDistributionSettingBase[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetLearningTemplatePackageDistributionAsTeacherApiResponse =
  /** status 200 OK */ LearningTemplatePackageDistributionSettingBaseDetails;
export type GetLearningTemplatePackageDistributionAsTeacherApiArg = {
  /** LearningTemplatePackageDistributionSetting ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetLearningPackageAsTeacherApiResponse =
  /** status 200 OK */ LearningPackageBaseDetails;
export type GetLearningPackageAsTeacherApiArg = {
  /** LearningPackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetLearningTemplatePackageDistributionHistoriesAsTeacherApiResponse =
  /** status 200 OK */ {
    learning_template_package_distribution_histories: LearningTemplatePackageDistributionHistoryBase[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionHistoriesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacherApiResponse =
  /** status 200 OK */ {
    distributed_students: DistributedStudents[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacherApiArg =
  {
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
    /** LearningTemplatePackageDistributionSetting ID */
    id: string;
  };
export type GetLearningTemplatePackageDistributionHistoriesPackagingsAsTeacherApiResponse =
  /** status 200 OK */ {
    packagings: PackagingAsLearningTemplatePackageBase[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionHistoriesPackagingsAsTeacherApiArg =
  {
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
    /** LearningTemplatePackageDistributionSetting ID */
    id: string;
  };
export type GetPblBoardCampaignsAsTeacherApiResponse = /** status 200 OK */ {
  board_campaigns: PblBoardCampaignListAsTeacher[];
  total_count: number;
};
export type GetPblBoardCampaignsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  articleId: string;
};
export type PostPblBoardCampaignRequestAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostPblBoardCampaignRequestAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    board_campaign_requests: {
      board_campaign_id: string;
      article_id: string;
    }[];
  };
};
export type DeletePblBoardCampaignRequestsAsTeacherApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 202 202 Accepted */ {
        message?: string;
      };
export type DeletePblBoardCampaignRequestsAsTeacherApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    ids: string[];
  };
};
export type GetGroupAffiliatePackagesAsTeacherApiResponse =
  /** status 200 OK */ {
    group_affiliate_packages: GroupAffiliatePackageGroupOnlyList[];
    total_count: number;
  };
export type GetGroupAffiliatePackagesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  homeworkTemplateId?: string;
  distributed?: boolean;
  isAssignedTeacher?: boolean;
};
export type PostGroupAffiliatePackageAsTeacherApiResponse =
  /** status 201 Created */ GroupAffiliatePackageBase;
export type PostGroupAffiliatePackageAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postGroupAffiliatePackage: PostGroupAffiliatePackage;
};
export type GetGroupAffiliatePackageAsTeacherApiResponse =
  /** status 200 OK */ GroupAffiliatePackageBase;
export type GetGroupAffiliatePackageAsTeacherApiArg = {
  /** GroupAffiliatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGroupAffiliatePackageAsTeacherApiResponse =
  /** status 202 Accepted */ GroupAffiliatePackageBase;
export type PutGroupAffiliatePackageAsTeacherApiArg = {
  /** GroupAffiliatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putGroupAffiliatePackage: PutGroupAffiliatePackage;
};
export type DeleteGroupAffiliatePackageAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGroupAffiliatePackageAsTeacherApiArg = {
  /** GroupAffiliatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostPackagingAsTeacherApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostPackagingAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    package_id: string;
    packageable_type: PackagingPackageableType;
    packageable_id: string;
  };
};
export type PutPackagingAsTeacherApiResponse = /** status 202 202 Accepted */ {
  message?: string;
};
export type PutPackagingAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** - パッケージのID
    - e.g. GroupAffiliatePackage の場合は、その id を指定する */
  packageId: string;
  /** - パッケージに登録するコンテンツの Modelの名前 を指定
    - e.g. GroupAffiliatePackage の場合は、Affiliate を指定する */
  packageableType: PackagingPackageableType;
  /** - パッケージに登録するコンテンツの ID を指定
    - e.g. GroupAffiliatePackage の場合は、Affiliateのid を指定する */
  packageableId: string;
  body: {
    sequence: number;
  };
};
export type DeletePackagingAsTeacherApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type DeletePackagingAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** - パッケージのID
    - e.g. GroupAffiliatePackage の場合は、その id を指定する */
  packageId: string;
  /** - パッケージに登録するコンテンツの Modelの名前 を指定
    - e.g. GroupAffiliatePackage の場合は、Affiliate を指定する */
  packageableType: string;
  /** - パッケージに登録するコンテンツの ID を指定
    - e.g. GroupAffiliatePackage の場合は、Affiliateのid を指定する */
  packageableId: string;
};
export type BatchCreatePackagingAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type BatchCreatePackagingAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    package_id: string;
    packageable_type: PackagingPackageableType;
    packageable_ids: string[];
  };
};
export type BatchUpdatePackagingAsTeacherApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type BatchUpdatePackagingAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    package_id: string;
    packageable_type: PackagingPackageableType;
    packageable_ids: string[];
    sequence: {
      packageable_id: string;
      sequence: number;
    }[];
  };
};
export type BatchDeletePackagingAsTeacherApiResponse = /** status 200 Ok */ {
  message?: string;
};
export type BatchDeletePackagingAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    package_id: string;
    packageable_type: string;
    packageable_ids: string[];
  };
};
export type PostAttachedImageAsTeacherApiResponse =
  /** status 201 Created */ AttachedImageBase;
export type PostAttachedImageAsTeacherApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetAttachedImageAsTeacherApiResponse =
  /** status 200 OK */ AttachedImageBase;
export type GetAttachedImageAsTeacherApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PutAttachedImageAsTeacherApiResponse =
  /** status 202 Accepted */ AttachedImageBase;
export type PutAttachedImageAsTeacherApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteAttachedImageAsTeacherApiResponse =
  /** status 200 202 Accepted */ {
    message?: string;
  };
export type DeleteAttachedImageAsTeacherApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type GetLimitedTimeNotificationsAsTeacherApiResponse =
  /** status 200 OK */ {
    limited_time_notifications: LimitedTimeNotificationList[];
    total_count: number;
  };
export type GetLimitedTimeNotificationsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostLimitedTimeNotificationAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostLimitedTimeNotificationAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    message: string;
    start_time: string;
    end_time: string;
    add_roles: SelectRoleByLimitedTimeNotification[];
    add_company_ids?: string[];
    add_user_tag_ids?: string[];
  };
};
export type GetLimitedTimeNotificationAsTeacherApiResponse =
  /** status 200 OK */ LimitedTimeNotificationDetail;
export type GetLimitedTimeNotificationAsTeacherApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutLimitedTimeNotificationAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutLimitedTimeNotificationAsTeacherApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    message: string;
    start_time: string;
    end_time: string;
    add_roles: SelectRoleByLimitedTimeNotification[];
    add_company_ids?: string[];
    add_user_tag_ids?: string[];
    remove_role_ids?: string[];
    remove_company_ids?: string[];
    remove_user_tag_ids?: string[];
  };
};
export type DeleteLimitedTimeNotificationAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteLimitedTimeNotificationAsTeacherApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetLimitedTimeNotificationNoticeAsTeacherApiResponse =
  /** status 200 OK */ {
    limited_time_notifications: LimitedTimeNotificationNoticeBase[];
    total_count: number;
  };
export type GetLimitedTimeNotificationNoticeAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetLimitedTimeNotificationNoticeDetailsAsTeacherApiResponse =
  /** status 200 OK */ LimitedTimeNotificationNoticeBase;
export type GetLimitedTimeNotificationNoticeDetailsAsTeacherApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostLimitedTimeNotificationNoticeCheckAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostLimitedTimeNotificationNoticeCheckAsTeacherApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetDailyLogsAsTeacherApiResponse = /** status 200 OK */ {
  daily_logs: DailyLogBase[];
  total_count: number;
};
export type GetDailyLogsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** - 開始日から範囲検索する
    - fromとtoは両方必須 */
  from?: string;
  /** - 開始日から範囲検索する
    - fromとtoは両方必須 */
  to?: string;
  /** - 学習カテゴリーから検索 */
  category?: DailyLogCategory;
};
export type GetIdealBoardTemplatesAsTeacherApiResponse = /** status 200 OK */ {
  board_templates: BoardTemplateList[];
  total_count: number;
};
export type GetIdealBoardTemplatesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchStatus?: IdealBoardTemplateStatus;
  noHomeworkTemplate?: boolean;
};
export type PostIdealBoardTemplatesAsTeacherApiResponse =
  /** status 201 Created */ BoardTemplate;
export type PostIdealBoardTemplatesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postIdealBoardTemplateRequestBody: PostIdealBoardTemplateRequestBody;
};
export type GetIdealBoardTemplateAsTeacherApiResponse =
  /** status 200 OK */ BoardTemplate;
export type GetIdealBoardTemplateAsTeacherApiArg = {
  /** Ideal::BoardTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutIdealBoardTemplateAsTeacherApiResponse =
  /** status 202 Accepted */ BoardTemplate;
export type PutIdealBoardTemplateAsTeacherApiArg = {
  /** Ideal::BoardTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putIdealBoardTemplateRequestBody: PutIdealBoardTemplateRequestBody;
};
export type DeleteIdealBoardTemplateAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteIdealBoardTemplateAsTeacherApiArg = {
  /** Ideal::BoardTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetIdealBoardsAsTeacherApiResponse = /** status 200 OK */ {
  boards: BoardList[];
  total_count: number;
};
export type GetIdealBoardsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  idealBoardTemplateId?: string;
  groupAffiliateId?: string;
};
export type PostIdealBoardsAsTeacherApiResponse =
  /** status 201 Created */ Board;
export type PostIdealBoardsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body:
    | {
        name: string;
        size: IdealBoardSize;
      }
    | {
        ideal_board_template_id: string;
      };
};
export type GetIdealBoardAsTeacherApiResponse = /** status 200 OK */ Board;
export type GetIdealBoardAsTeacherApiArg = {
  /** Ideal::Board ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutIdealBoardAsTeacherApiResponse = /** status 202 202 Accepted */ {
  message?: string;
};
export type PutIdealBoardAsTeacherApiArg = {
  /** Ideal::Board ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    name?: string;
    data?: string;
    event?: IdealBoardEvent;
  };
};
export type DeleteIdealBoardAsTeacherApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteIdealBoardAsTeacherApiArg = {
  /** Ideal::Board ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostIdealBoardsAffiliatesAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostIdealBoardsAffiliatesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    ideal_board_id: string;
    affiliate_id: string;
  };
};
export type DeleteIdealBoardsAffiliatesAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteIdealBoardsAffiliatesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    ideal_board_id: string;
    affiliate_id: string;
  };
};
export type PatchAllowedGroupAffiliatesBatchUpdateAsTeacherApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PatchAllowedGroupAffiliatesBatchUpdateAsTeacherApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  body: {
    categorizable_type: AllowedGroupAffiliateCategorizableType;
    categorizable_id: string;
    add_group_affiliate_ids?: string[];
    remove_group_affiliate_ids?: string[];
  };
};
export type GetTtCsvExportsAsTeacherApiResponse = /** status 200 OK */ {
  tt_csv_exports: TtCsvExportBase[];
  total_count: number;
};
export type GetTtCsvExportsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  types?: TtCsvExportType[];
};
export type PostTtCsvExportAsTeacherApiResponse =
  /** status 200 OK */ TtCsvExportBase;
export type PostTtCsvExportAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  sortedBy?: string;
  searchKey?: string[];
  searchValue?: string[];
  body: {
    type: TtCsvExportType;
    homework_template_id?: string;
    pbl_plan_item_id?: string;
  };
};
export type PostTtCategoryBaseAsTeacherApiResponse =
  /** status 201 Created */ TtCategoryBase;
export type PostTtCategoryBaseAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  createTtCategoryRequestBody: CreateTtCategoryRequestBody;
};
export type PutTtCategoryBaseAsTeacherApiResponse =
  /** status 202 Accepted */ TtCategoryBase;
export type PutTtCategoryBaseAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  id: string;
  updateTtCategoryRequestBody: UpdateTtCategoryRequestBody;
};
export type GetFeedbackTemplateHomeworksAsTeacherApiResponse =
  /** status 200 OK */ {
    feedback_templates: FeedbackTemplateHomeworkBase[];
    total_count: number;
  };
export type GetFeedbackTemplateHomeworksAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: FeedbackTemplateSearchType;
  searchWord?: string;
  /** タグ名に完全一致するものを返却する */
  tagName?: string;
};
export type GetFeedbackTemplateTagsAsTeacherApiResponse = /** status 200 OK */ {
  feedback_template_tags: HasName[];
  total_count: number;
};
export type GetFeedbackTemplateTagsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostCommentImageAsTeacherApiResponse =
  /** status 201 Created */ CommentImageBase;
export type PostCommentImageAsTeacherApiArg = {
  categorizableType: CommentImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetCommentImageAsTeacherApiResponse =
  /** status 200 OK */ CommentImageBase;
export type GetCommentImageAsTeacherApiArg = {
  categorizableType: CommentImageCategorizableType;
  categorizableId: string;
  /** CommentImage ID */
  id: string;
};
export type PutCommentImageAsTeacherApiResponse =
  /** status 202 Accepted */ CommentImageBase;
export type PutCommentImageAsTeacherApiArg = {
  categorizableType: CommentImageCategorizableType;
  categorizableId: string;
  /** CommentImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteCommentImageAsTeacherApiResponse =
  /** status 200 202 Accepted */ {
    message?: string;
  };
export type DeleteCommentImageAsTeacherApiArg = {
  categorizableType: CommentImageCategorizableType;
  categorizableId: string;
  /** CommentImage ID */
  id: string;
};
export type GetDistributionStatusesAsTeacherApiResponse = /** status 200 OK */ {
  distribution_statuses: DistributionStatusList[];
  total_count: number;
};
export type GetDistributionStatusesAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  homeworkTemplateId?: string;
};
export type GetInterviewRequestsAsTeacherApiResponse = /** status 200 OK */ {
  interview_requests: InterviewRequestListBase[];
  total_count: number;
};
export type GetInterviewRequestsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  status?: InterviewRequestStatus;
  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  searchType?: InterviewRequestSearchType;
  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  searchWord?: string;
};
export type PostInterviewRequestAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostInterviewRequestAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    professor_affiliate_id: string;
    article_id: string;
  };
};
export type GetInterviewRequestAsTeacherApiResponse =
  /** status 200 OK */ InterviewRequestDetailBase;
export type GetInterviewRequestAsTeacherApiArg = {
  /** Interview::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutInterviewRequestAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutInterviewRequestAsTeacherApiArg = {
  /** Interview::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    professor_message?: string;
    is_online?: boolean;
    confirm_date?: string;
    place?: string;
    event?: InterviewRequestEvent;
  };
};
export type GetInterviewRequestArticleAsTeacherApiResponse =
  /** status 200 OK */ ArticleDetailBase;
export type GetInterviewRequestArticleAsTeacherApiArg = {
  /** Interview::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetInterviewRequestUnreadCountsAsTeacherApiResponse =
  /** status 200 OK */ {
    unread_counts: InterviewRequestUnreadCountBase[];
  };
export type GetInterviewRequestUnreadCountsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  requestIds?: string[];
};
export type GetInterviewMessagesAsTeacherApiResponse = /** status 200 OK */ {
  interview_messages: InterviewRequestListBase2[];
  total_count: number;
};
export type GetInterviewMessagesAsTeacherApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostInterviewMessageAsTeacherApiResponse =
  /** status 201 Created */ InterviewRequestListBase2;
export type PostInterviewMessageAsTeacherApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    message: string;
  };
};
export type GetInterviewMessageReadAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type GetInterviewMessageReadAsTeacherApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    unread_message_ids: string[];
  };
};
export type PutInterviewMessageAsTeacherApiResponse =
  /** status 202 Accepted */ InterviewRequestListBase2;
export type PutInterviewMessageAsTeacherApiArg = {
  /** Interview::Request ID */
  requestId: string;
  /** Interview::Message ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    message: string;
  };
};
export type DeleteInterviewMessageAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteInterviewMessageAsTeacherApiArg = {
  /** Interview::Request ID */
  requestId: string;
  /** Interview::Message ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetInterviewFeedbacksAsTeacherApiResponse = /** status 200 OK */ {
  interview_feedbacks: InterviewFeedbackBase[];
  total_count: number;
};
export type GetInterviewFeedbacksAsTeacherApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type FetchAffiliateInvitationsAsTeacherApiResponse =
  /** status 200 OK */ {
    invitations: AffiliateInvitation[];
    total_count: number;
  };
export type FetchAffiliateInvitationsAsTeacherApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  role?: UserType;
};
export type PutHomeworkTemplateCustomValueAsTeacherApiResponse =
  /** status 202 Accepted */ {
    homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  };
export type PutHomeworkTemplateCustomValueAsTeacherApiArg = {
  /** HomeworkTemplateCustomValue ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  customValueRequestBodyAsOwnerAndTeacherAndReviewer: CustomValueRequestBodyAsOwnerAndTeacherAndReviewer;
};
export type GetGogoLinkingsAsTeacherApiResponse = /** status 200 OK */ {
  gogo_companies: HasIdAndName[];
  total_count: number;
};
export type GetGogoLinkingsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetGogoRequestsAsTeacherApiResponse = /** status 200 OK */ {
  gogo_requests: GogoRequestListBase[];
  total_count: number;
};
export type GetGogoRequestsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  requestType?: RequestType;
  status?: "idle" | "adjusted" | "decided" | "cancelled";
  companyId?: string;
};
export type PostGogoRequestAsTeacherApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostGogoRequestAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postGogoRequestRequestBody: PostGogoRequestRequestBody;
};
export type GetGogoRequestAsTeacherApiResponse =
  /** status 200 OK */ GogoRequestDetailBase;
export type GetGogoRequestAsTeacherApiArg = {
  /** Gogo::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGogoRequestAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutGogoRequestAsTeacherApiArg = {
  /** Gogo::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putGogoRequestRequestBody: PutGogoRequestRequestBody;
};
export type DeleteGogoRequestAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoRequestAsTeacherApiArg = {
  /** Gogo::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoRequestUnreadCountsAsTeacherApiResponse =
  /** status 200 OK */ {
    unread_counts: GogoRequestUnreadCountBase[];
  };
export type GetGogoRequestUnreadCountsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  requestIds?: string[];
};
export type GetGogoMessagesAsTeacherApiResponse = /** status 200 OK */ {
  gogo_messages: GogoRequestListBase2[];
  total_count: number;
};
export type GetGogoMessagesAsTeacherApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostGogoMessageAsTeacherApiResponse =
  /** status 201 Created */ GogoRequestListBase2;
export type PostGogoMessageAsTeacherApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type PutGogoMessageAsTeacherApiResponse =
  /** status 202 Accepted */ GogoRequestListBase2;
export type PutGogoMessageAsTeacherApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  /** Gogo::Message ID */
  id: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type DeleteGogoMessageAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoMessageAsTeacherApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  /** Gogo::Message ID */
  id: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetChatRoomAsTeacherApiResponse = /** status 200 OK */ ModelBase & {
  bookmark: HasIdAndTitle;
};
export type GetChatRoomAsTeacherApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostChatRoomAsTeacherApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostChatRoomAsTeacherApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetChatRoomMessagesAsTeacherApiResponse = /** status 200 OK */ {
  chat_room_messages: (ModelBase & {
    message: string;
    message_wysiwyg_body?: string | null;
    created_at: string;
    sender_affiliate: AffiliateMessageBase;
  })[];
  total_count: number;
};
export type GetChatRoomMessagesAsTeacherApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostChatRoomMessageAsTeacherApiResponse =
  /** status 201 Created */ ChatRoomMessageListBase & {
    bookmark_wysiwyg_body?: string | null;
  };
export type PostChatRoomMessageAsTeacherApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type PutChatRoomMessageAsTeacherApiResponse =
  /** status 202 Accepted */ ChatRoomMessageDetail;
export type PutChatRoomMessageAsTeacherApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  /** Chat::RoomMessage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type DeleteChatRoomMessageAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteChatRoomMessageAsTeacherApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  /** Chat::RoomMessage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoMessageReadAsTeacherApiResponse = /** status 201 Created */ {
  message?: string;
};
export type GetGogoMessageReadAsTeacherApiArg = {
  /** Gogo::Request ID */
  requestId: string;
  categorizableType: GogoMessageCategorizableType;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    unread_message_ids: string[];
  };
};
export type GetCommonTagsAsTeacherApiResponse = /** status 200 OK */ {
  common_tags: CommonTagBase[];
  total_count: number;
};
export type GetCommonTagsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: Schema4;
  searchWord?: string;
  kind?: CommonTagKind;
  categorizableType?: CommonTaggingCategorizableType;
};
export type PostCommonTagAsTeacherApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostCommonTagAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  commonTagRequestBody: CommonTagLinkingRequestBody;
};
export type PutCommonTagAsTeacherApiResponse = /** status 202 202 Accepted */ {
  message?: string;
};
export type PutCommonTagAsTeacherApiArg = {
  /** Common::Tag id */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  nameRequiredRequestBody: NameRequiredRequestBody;
};
export type DeleteCommonTagAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteCommonTagAsTeacherApiArg = {
  /** Common::Tag id */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetCommonTagsManagementTagListAsTeacherApiResponse =
  /** status 200 OK */ {
    common_management_tags: CommonTagManagementTagBase[];
    total_count: number;
  };
export type GetCommonTagsManagementTagListAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostCommonTaggingAsTeacherApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostCommonTaggingAsTeacherApiArg = {
  categorizableType: CommonTaggingCategorizableType;
  categorizableId: string;
  commonTaggingRequestBody: CommonTaggingRequestBody;
};
export type DeleteCommonTaggingAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteCommonTaggingAsTeacherApiArg = {
  categorizableType: CommonTaggingCategorizableType;
  categorizableId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  commonTagId: string;
};
export type PostCommonTagLinkingAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostCommonTagLinkingAsTeacherApiArg = {
  /** Common::Tag id */
  tagId: string;
  commonTagLinkingRequestBody: CommonTagLinkingRequestBody2;
};
export type DeleteCommonTagLinkingAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteCommonTagLinkingAsTeacherApiArg = {
  /** Common::Tag id */
  tagId: string;
  /** Common::TagLinking id */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoProgramsAsTeacherApiResponse = /** status 200 OK */ {
  gogo_programs: (ModelBase & {
    title: string;
    status: Status2;
    status_code: StatusCode;
    created_at: string | null;
    creator_affiliate: AffiliateName;
    company: HasIdAndName;
  })[];
  total_count: number;
};
export type GetGogoProgramsAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** 学校側のプログラムのみを返却 */
  isManaged?: boolean;
  /** 企業側のプログラムのみを返却 */
  isRequested?: boolean;
};
export type PostGogoProgramAsTeacherApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostGogoProgramAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    event?: "close" | "release";
    number_of_classes?: number;
    number_of_students?: number;
    parking?: GogoRequestParking;
    projector?: GogoRequestProjector;
    internet_connection?: GogoRequestInternetConnection;
    photography?: GogoRequestPhotography;
    atmosphere?: GogoRequestAtmosphere;
    teacher_affiliate_id?: string;
    grade?: StudentGrade;
  };
};
export type GetGogoProgramAsTeacherApiResponse = /** status 200 OK */ Items & {
  number_of_classes: number;
  number_of_students: number;
  parking: GogoRequestParking;
  projector: GogoRequestProjector;
  internet_connection: GogoRequestInternetConnection;
  photography: GogoRequestPhotography;
  atmosphere: GogoRequestAtmosphereJa;
  atmosphere_code: GogoRequestAtmosphere;
  grade: StudentGrade;
  teacher_affiliate?: AffiliateName;
};
export type GetGogoProgramAsTeacherApiArg = {
  /** Gogo::Program ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGogoProgramAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutGogoProgramAsTeacherApiArg = {
  /** Gogo::Program ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  schema: GogoProgramRequestBody;
};
export type DeleteGogoProgramAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoProgramAsTeacherApiArg = {
  /** Gogo::Program ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoProgramRequestsAsTeacherApiResponse = /** status 200 OK */ {
  gogo_program_requests: (ModelBase & {
    status: "\u8ABF\u6574\u4E2D" | "\u8ABF\u6574\u6E08\u307F";
    status_code: "adjusting" | "adjusted";
    created_at: string | null;
    company: HasIdAndName;
  })[];
  total_count: number;
};
export type GetGogoProgramRequestsAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostGogoProgramRequestAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostGogoProgramRequestAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    message: string;
  };
};
export type GetGogoProgramRequestAsTeacherApiResponse =
  /** status 200 OK */ Items2 & {
    message: string;
    unread_message_ids: string[];
  };
export type GetGogoProgramRequestAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGogoProgramRequestAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutGogoProgramRequestAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    message?: string;
    event?: "close";
  };
};
export type DeleteGogoProgramRequestAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoProgramRequestAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoProgramRequestMessagesAsTeacherApiResponse =
  /** status 200 OK */ {
    gogo_messages: GogoRequestListBase2[];
    total_count: number;
  };
export type GetGogoProgramRequestMessagesAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostGogoProgramRequestMessageAsTeacherApiResponse =
  /** status 201 Created */ GogoRequestListBase2;
export type PostGogoProgramRequestMessageAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type PutGogoProgramRequestMessageAsTeacherApiResponse =
  /** status 202 Accepted */ GogoRequestListBase2;
export type PutGogoProgramRequestMessageAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  /** Gogo::ProgramRequestMessage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type DeleteGogoProgramRequestMessageAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoProgramRequestMessageAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  /** Gogo::ProgramRequestMessage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoProgramRequestMessageReadAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type GetGogoProgramRequestMessageReadAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramRequest ID */
  programRequestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    unread_message_ids: string[];
  };
};
export type GetGogoProgramPreferredDatesAsTeacherApiResponse =
  /** status 200 OK */ {
    gogo_program_preferred_dates: GogoProgramPreferredDateBase[];
    total_count: number;
  };
export type GetGogoProgramPreferredDatesAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostGogoProgramPreferredDateAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostGogoProgramPreferredDateAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  gogoProgramPreferredDateRequestBody: GogoProgramPreferredDateRequestBody;
};
export type PutGogoProgramPreferredDateAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutGogoProgramPreferredDateAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramPreferredDate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  gogoProgramPreferredDateRequestBody: GogoProgramPreferredDateRequestBody;
};
export type DeleteGogoProgramPreferredDateAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoProgramPreferredDateAsTeacherApiArg = {
  /** Gogo::Programt ID */
  programId: string;
  /** Gogo::ProgramPreferredDate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetGogoCompanyInformationAsTeacherApiResponse =
  /** status 200 OK */ GogoCompanyInformationBase;
export type GetGogoCompanyInformationAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  contentCompanyId: string;
};
export type GetGogoCompanyInformationListAsTeacherApiResponse =
  /** status 200 OK */ {
    gogo_company_informations: GogoCompanyInformationBase[];
    total_count: number;
  };
export type GetGogoCompanyInformationListAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostGogoSchoolInformationAsTeacherApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostGogoSchoolInformationAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  schema: GogoSchoolInformationRequestBody;
};
export type GetGogoSchoolInformationAsTeacherApiResponse =
  /** status 200 OK */ ModelBase & {
    parking: Parking;
    projector: Projector;
    internet_connection: InternetConnection;
    photography: Photography;
  };
export type GetGogoSchoolInformationAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGogoSchoolInformationAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutGogoSchoolInformationAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    parking?: "parking_unavailable" | "parking_available";
    projector?: "projector_unavailable" | "projector_available";
    internet_connection?:
      | "internet_connection_unavailable"
      | "internet_connection_available";
    photography?: "photography_unavailable" | "photography_available";
  };
};
export type DeleteGogoSchoolInformationAsTeacherApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoSchoolInformationAsTeacherApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type ModelBase = {
  id: string;
};
export type BasicUserInfo = ModelBase & {
  first_name: string;
  last_name: string;
  email: string;
  optional_email?: string | null;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  expired_date?: string | null;
  current_sign_in_at?: string | null;
  last_sign_in_at?: string | null;
  linked_project_count?: number | null;
  affiliate_id?: string | null;
  nickname: string | null;
};
export type AffiliateStatusJa =
  | "\u5229\u7528\u4E2D"
  | "\u671F\u9650\u5207\u308C"
  | "\u767B\u9332\u5F85\u3061"
  | "\u30ED\u30C3\u30AF\u4E2D"
  | "\u6E96\u5099\u4E2D"
  | "\u672A\u627F\u8A8D"
  | "\u5229\u7528\u505C\u6B62";
export type AffiliateAccount = ModelBase & {
  invitation_scheduled_at: string | null;
  status: AffiliateStatusJa;
  last_accessed_at: string | null;
};
export type UserList = BasicUserInfo & {
  affiliate: AffiliateAccount;
};
export type StudentListAsTeacher = UserList & {
  grade?: string | null;
  number?: number | null;
  user_tags_count: number;
  group_affiliate_packages_count: number;
};
export type UserInputSetting = "required" | "optional" | "hidden";
export type StudentInputSetting = {
  avatar: UserInputSetting;
  postcode: UserInputSetting;
  prefecture: UserInputSetting;
  address1: UserInputSetting;
  address2: UserInputSetting;
  birth_date: UserInputSetting;
  grade: UserInputSetting;
  phone: UserInputSetting;
  parent_first_name: UserInputSetting;
  parent_last_name: UserInputSetting;
  number: UserInputSetting;
};
export type SortedByStudentList = "grade" | "numberAsc" | "numberDesc";
export type ErrorsWithKeyMessage = {
  errors: {
    key?: string;
    message?: string;
  }[];
};
export type StudentInfoAttributes = {
  birth_date?: string;
  grade?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  number?: number;
};
export type UserType = "student" | "teacher" | "owner" | "reviewer";
export type StudentGradeJa =
  | "\u5B66\u5E74\u672A\u8A2D\u5B9A"
  | "\u5C0F\u5B661\u5E74"
  | "\u5C0F\u5B662\u5E74"
  | "\u5C0F\u5B663\u5E74"
  | "\u5C0F\u5B664\u5E74"
  | "\u5C0F\u5B665\u5E74"
  | "\u5C0F\u5B666\u5E74"
  | "\u4E2D\u5B661\u5E74"
  | "\u4E2D\u5B662\u5E74"
  | "\u4E2D\u5B663\u5E74"
  | "\u9AD8\u68211\u5E74"
  | "\u9AD8\u68212\u5E74"
  | "\u9AD8\u68213\u5E74"
  | "\u5927\u5B661\u5E74"
  | "\u5927\u5B662\u5E74"
  | "\u5927\u5B663\u5E74"
  | "\u5927\u5B664\u5E74"
  | "\u305D\u306E\u4ED6"
  | "\u5B66\u5E74\u306A\u3057";
export type CreateAccountUserResponse = {
  user?: {
    id: string;
    account_id: string;
    full_name: string;
    password: string;
    affiliate: {
      company_id: string;
      company_name: string;
      role: string;
    };
  };
};
export type CreateAccountUserRequestBodyAsTeacher = {
  account_id?: string;
  year: number;
  last_name: string;
  first_name: string;
  optional_email?: string | null;
  password: string;
  user_params: {
    grade: string;
    parent_last_name?: string;
    parent_first_name?: string;
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    birth_date?: string;
    number: number;
  };
  user_tag_ids?: string[] | null;
  group_ids?: string[] | null;
  group_code?: string | null;
};
export type AffiliateName = {
  affiliate_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  nickname: string | null;
};
export type UserTagTypeBasic = ModelBase & {
  name: string;
  kind?: string;
  teacher_visible: boolean;
  role_type?: string;
};
export type UserTagBasic = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_linking_users?: number;
  kind?: string;
};
export type GroupAffiliatePackageMinimalBase = ModelBase & {
  title: string;
};
export type Avatar = {
  url: string | null;
};
export type StudentGrade =
  | "under"
  | "elementary1"
  | "elementary2"
  | "elementary3"
  | "elementary4"
  | "elementary5"
  | "elementary6"
  | "junior1"
  | "junior2"
  | "junior3"
  | "high1"
  | "high2"
  | "high3"
  | "university1"
  | "university2"
  | "university3"
  | "university4"
  | "others";
export type BasicStudentInfo = {
  birth_date?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  grade?: string;
  grade_value?: StudentGrade;
  number?: number | null;
};
export type TeacherInfo = {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  has_register_student?: boolean;
};
export type UserAuthorization = {
  material_distribution?: boolean;
  nationwide_user_tag?: boolean;
  limited_user_tag?: boolean;
  homework_review?: boolean;
  user_edit?: boolean;
  notification_delivery?: boolean;
  send_inquiry_email?: boolean;
};
export type ProfessorInfo = {
  position: string | null;
  department: string | null;
  description: string | null;
  limit_request: number | null;
  profile_picture: string | null;
};
export type UserAccountType = "email" | "account_id";
export type UserDetail = BasicUserInfo & {
  avatar?: Avatar;
  student_input_settings?: StudentInputSetting;
  student_info?: BasicStudentInfo;
  teacher_info?: TeacherInfo;
  user_authorizations?: UserAuthorization;
  student_affiliate_id?: string;
  professor_info?: ProfessorInfo;
  account_type?: UserAccountType;
  unique_id?: string | null;
};
export type TeacherListForTeacher = BasicUserInfo & {
  affiliate: AffiliateAccount;
};
export type HasAffiliateIdAndName = {
  id: string;
  affiliate_id: string;
  name: string;
  nickname: string;
};
export type HasIdAndName = {
  id: string;
  name: string;
};
export type ProfessorInfoBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    request_count?: number;
    position?: string | null;
    department?: string | null;
    description?: string | null;
    limit_request?: number | null;
    profile_picture?: string | null;
  };
};
export type TeacherListForUniversityTeacher = {
  professor_info: ProfessorInfoBase;
};
export type TeacherSearchType = "name" | "company";
export type AppName = "MYLSIL" | "TimeTact";
export type Option = {
  use_video?: boolean;
  custom_student_ui?: boolean;
  homework_feature?: boolean;
  blog_feature?: boolean;
  text_seller?: boolean;
  use_image?: boolean;
  material?: boolean;
  teacher_material?: boolean;
  report?: boolean;
  payment?: boolean;
  calender?: boolean;
  review_feature?: boolean;
  reviewer_company?: boolean;
  tag?: boolean;
  google_classroom?: boolean;
  steam_library?: boolean;
  content_company?: boolean;
  content_company_review_feature?: boolean;
  dashboard_feature?: boolean;
  study_log_feature?: boolean;
  ideal_feature?: boolean;
  pbl_board_feature?: boolean;
  exam_feature?: boolean;
  rubric_feature?: boolean;
  word_cloud_feature?: boolean;
  rubric_evaluate_feature?: boolean;
  google_drive_feature?: boolean;
  one_drive_feature?: boolean;
  university_cooperation_feature?: boolean;
  article_student_comment_feature?: boolean;
  homework_automatic_scoring_feature?: boolean;
  class_nav_feature?: boolean;
  use_account_lock_mail?: boolean;
  gogo_feature?: boolean;
  pbl_projects_feature?: boolean;
  use_article_and_homework_mail?: boolean;
};
export type AuthorizationInfo = UserAuthorization & {
  create_video?: boolean;
  modify_video?: boolean;
  delete_video?: boolean;
};
export type CategoryRenameMap = {
  id?: string | null;
  for_text: string | null;
  for_subject: string | null;
  for_grade: string | null;
  for_major: string | null;
  for_middle: string | null;
  for_minor: string | null;
} | null;
export type ReviewerInfo = {
  is_manager: boolean;
};
export type CurrentUserInfo = {
  id: string;
  affiliate_id?: string;
  email?: string;
  first_name: string;
  last_name: string;
  full_name: string;
  nickname: string | null;
  current_sign_in_at: string | null;
  last_sign_in_at: string | null;
  avatar: Avatar;
  current_role: string;
  current_role_name?: string;
  current_status?: AffiliateStatusJa;
  company_id: string;
  company_name: string;
  company_unique_id: string | null;
  company_is_child_company?: boolean;
  company_is_super_company?: boolean;
  is_university: boolean;
  is_admin_company?: boolean;
  app_name: AppName;
  terms_accepted: boolean;
  company_authorization?: Option | null;
  authorization?: AuthorizationInfo;
  company_logo_url?: string | null;
  ui_color_code: string;
  ui_second_color_code?: string;
  teacher_info?: {
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    has_register_student?: boolean;
  } | null;
  new_notification: boolean;
  category_rename_map: CategoryRenameMap;
  first_time_login: boolean;
  last_accessed_at: string | null;
  reviewer_info?: ReviewerInfo | null;
  admin_info?: {
    only_pbl_projects_feature: boolean;
  } | null;
};
export type TeacherInfoAttributes = {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  has_register_student?: boolean;
};
export type AccountUserInfo = {
  user_id: string;
  full_name: string;
  email: string;
  new_password: string;
  group_affiliate_packages?:
    | {
        title?: string;
      }[]
    | null;
};
export type AuthorizationPasswordRequestBody = {
  password: string;
  affiliate_id?: string;
};
export type CategoryPart = {
  id: string;
  name: string;
  depth: number;
  type: string;
  text_id?: string;
  grade_id?: string;
  subject_id?: string;
  major_category_id?: string;
  middle_category_id?: string;
};
export type CategoryBase = {
  category: CategoryPart;
};
export type MaterialList = ModelBase &
  CategoryBase & {
    title: string;
    thumbnail_file?: string | null;
    is_public: boolean;
    is_video_updating: boolean;
    tags: HasIdAndName[];
  };
export type MaterialSortedByColumn = "title" | "created_at";
export type SortedByOrder = "asc" | "desc";
export type ImageInfo = {
  id: string;
  title: string;
  material_id: string;
  file: {
    url: string;
    thumb?: {
      url: string | null;
    };
    icon?: {
      url: string | null;
    };
  };
  edited_image_url?: string | null;
};
export type CookieInfo = {
  cloudfront_policy: string;
  cloudfront_signature: string;
  cloudfront_key_pair_id: string;
  unsigned_url: string;
};
export type VideoInfo = {
  id: string;
  title: string;
  material_id: string;
  file_url: string | null;
  file: {
    url?: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string;
  thumbnail: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
};
export type MaterialDetail = MaterialList & {
  description: string;
  memo?: string | null;
  download_allowed: boolean;
  is_owned: boolean;
  images: ImageInfo[];
  videos: VideoInfo[];
  html_file: {
    title?: string | null;
    url?: string | null;
  };
};
export type MaterialWorkStatusJa =
  | "\u672A\u7740\u624B"
  | "\u53D7\u8B1B\u4E2D"
  | "\u5B8C\u4E86";
export type MaterialWorkStatus = "todo" | "in_progress" | "completed";
export type MaterialWorkList = ModelBase & {
  status: MaterialWorkStatusJa;
  status_code: MaterialWorkStatus;
  forbid_download: boolean;
  distributed_by_super_company: boolean;
  is_distributed: boolean;
  is_expired: boolean;
  is_available: boolean;
};
export type StudentMaterial = MaterialDetail & {
  thumbnail: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  edited_images: (ModelBase & {
    title?: string | null;
    url?: string | null;
    image_content_id?: string | null;
  })[];
};
export type StudentMaterialWork = MaterialWorkList & {
  images: (ModelBase & {
    title?: string | null;
    url?: string | null;
    image_content_id?: string | null;
  })[];
  material: StudentMaterial;
};
export type TeacherMaterialWork = StudentMaterialWork & {
  created_at: string;
  user?: {
    last_name: string;
    first_name: string;
    nickname: string;
  };
};
export type HasName = {
  name: string;
};
export type MaterialWork = MaterialWorkList & {
  created_at: string;
  material: {
    title: string;
  };
};
export type Report = ModelBase & {
  created_at?: string;
  teacher_name?: string;
  student_name?: string;
  detail?: string;
  reply?: string | null;
  status?: string;
  title?: string;
  next_goal?: string;
  monthly_teaching_times?: number;
  monthly_student_schedule_change?: number;
  monthly_teacher_schedule_change?: number;
  homework_frequency?: number;
  is_schedule_change_required?: boolean;
  schedule_detail_reason?: string;
  test_misc?: string;
  text_subjects?: string[];
  schedule_change_period?: string;
  schedule_change_notified?: string;
  is_schedule_change_notified?: boolean;
  substitute_lecture?: boolean;
  test_type?: string;
  no_homework_reason?: string;
  no_text_reason?: string;
  reports_test_results?: {
    subject: string;
    current_score: number;
    positive_negative: boolean;
    score_delta: number;
  }[];
};
export type ReportTestType = "regular" | "qualify" | "practice";
export type ReportStatus = "submitted" | "answered" | "confirmed" | "closed";
export type PaymentStatus =
  | "initiated"
  | "customer_confirmed"
  | "teacher_confirmed"
  | "owner_confirmed";
export type Payment = ModelBase & {
  student_name: string | null;
  teacher_name: string | null;
  title: string | null;
  price?: number;
  status: string;
  status_code: PaymentStatus;
  created_at: string;
  due_date: string | null;
  year: number | null;
  month: number | null;
};
export type HomeworkStatusJa =
  | "\u65B0\u898F"
  | "\u63D0\u51FA\u6E08\u307F"
  | "\u6559\u8077\u54E1\u56DE\u7B54\u6E08\u307F"
  | "\u6DFB\u524A\u524D"
  | "\u6DFB\u524A\u4E2D"
  | "\u6DFB\u524A\u6E08\u307F"
  | "\u8FD4\u5374\u6E08\u307F"
  | "\u8981\u518D\u63D0\u51FA";
export type HomeworkStatus =
  | "todo"
  | "submitted"
  | "checked"
  | "submitted_reviewer"
  | "assigned_reviewer"
  | "checked_reviewer"
  | "returned_reviewer"
  | "change_requested";
export type StudentHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
};
export type CommonTagKind = "system" | "free" | "management";
export type CommonTagBase = ModelBase & {
  kind: CommonTagKind;
  name: string;
};
export type HomeworkBase = ModelBase & {
  title: string;
  status: HomeworkStatusJa;
  status_code: HomeworkStatus;
  submitted_at: string | null;
  student_affiliate_id: string | null;
  student_name: string;
  student_nickname?: string | null;
  teacher_name: string;
  start_at: string | null;
  end_at: string | null;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  student_number?: number | null;
  student_homework_images: StudentHomeworkImage[];
  has_attached_file: boolean;
  common_tags: CommonTagBase[];
  ideal_thumbnail_file: string | null;
};
export type HomeworkOrderType =
  | "student_name"
  | "affiliate_package_name"
  | "student_number"
  | "submitted_at"
  | "status"
  | "user_tag_name"
  | "user_affiliate_package_name";
export type HomeworkTemplateDistributionStatus =
  | "todo"
  | "distributed_students"
  | "submitted_reviewer"
  | "checked_reviewer"
  | "returned";
export type CategorizableCommentCategorizableType = "Homework";
export type AffiliateInfoBase = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  role_name: string;
  nickname: string | null;
};
export type CommentImageCategorizableType = "Homework" | "CategorizableComment";
export type CommentImageBase = ModelBase & {
  categorizable_id?: string;
  categorizable_type?: CommentImageCategorizableType;
  file_name?: string;
  file_url?: string;
};
export type CategorizableCommentBase = ModelBase & {
  categorizable_type: CategorizableCommentCategorizableType;
  categorizable_id: string;
  body: string;
  created_at: string;
  affiliate: AffiliateInfoBase;
  images: CommentImageBase[] | null;
};
export type TeacherHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
  from_template?: boolean;
  temp_data?: string | null;
};
export type ReviewerHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
};
export type HomeworkTemplateCustomFieldsTypes =
  | "HomeworkTemplateCustomTextField"
  | "HomeworkTemplateCustomRadioField"
  | "HomeworkTemplateCustomSelectField"
  | "HomeworkTemplateCustomProfileField";
export type CustomFieldScoreBase = {
  possible_scores: number[] | null;
  possible_scores2: number[] | null;
  answer_flags?: boolean[] | null;
  is_exact_match: boolean | null;
};
export type HasNumberIdAndName = {
  id: number;
  name: string;
};
export type CustomFieldCustomMasterMap = ModelBase & {
  name: string;
  public_columns: HasNumberIdAndName[];
  filter_columns: HasNumberIdAndName[];
  filter_cmmrs: HasIdAndName[];
};
export type TtCategoryBaseType = "TtCategory::HomeworkTemplateCustomField";
export type TtCategoryBase = ModelBase & {
  name: string;
  type: TtCategoryBaseType;
};
export type HomeworkTemplateCustomField = ModelBase & {
  homework_templates_id: string;
  type: HomeworkTemplateCustomFieldsTypes;
  sequence: number;
  title: string;
  possible_values: string[];
  min_length: number | null;
  max_length: number | null;
  is_required: boolean;
  created_at: string;
  updated_at: string;
  score?: CustomFieldScoreBase;
  custom_master_map?: CustomFieldCustomMasterMap;
  custom_field_category?: TtCategoryBase;
};
export type AttachedImageCategorizableType =
  | "Material"
  | "Ideal::Board"
  | "Article"
  | "Pbl::Board::Content"
  | "Pbl::Sponsor::Lecture"
  | "Ideal::BoardTemplate"
  | "Interview::Message"
  | "HomeworkTemplateCustomFieldFeedback"
  | "Gogo::Message";
export type AttachedImageBase = ModelBase & {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
  file_name: string;
  file_url: string;
};
export type HomeworkTemplateCustomFieldFeedback = ModelBase & {
  body: string;
  commenter_affiliate: AffiliateInfoBase;
  created_at: string;
  feedback_image: AttachedImageBase[];
  wysiwyg_body: string | null;
};
export type CustomMasterPublics = {
  id: string;
  public_columns: HasNumberIdAndName[];
};
export type StudentHomeworkTemplateCustomValue = ModelBase & {
  homework_template_custom_fields_id: string;
  student_id: string;
  profile_values?: {
    user_last_name?: string | null;
    user_first_name?: string | null;
    email?: string | null;
    number?: number | null;
    birth_date?: string | null;
    grade_value?: StudentGrade | null;
    grade?: string | null;
    phone?: string | null;
    parent_last_name?: string | null;
    parent_first_name?: string | null;
    postcode?: string | null;
    prefecture?: string | null;
    address1?: string | null;
    address2?: string | null;
  };
  value?: string | null;
  created_at: string;
  updated_at: string;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  custom_master?: CustomMasterPublics;
  wysiwyg_body?: string | null;
};
export type HomeworkTemplateCustomFieldAndValue =
  HomeworkTemplateCustomField & {
    custom_field_feedbacks: HomeworkTemplateCustomFieldFeedback[];
    homework_template_custom_values: StudentHomeworkTemplateCustomValue[];
    homework_template_custom_value_score?: {
      score?: number | null;
    } | null;
  };
export type IdealBoardStatusJa =
  | "\u65B0\u898F"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86";
export type IdealBoardStatus = "todo" | "in_progress" | "completed";
export type IdealBoardSize = "S" | "M" | "MV" | "L";
export type HomeworkBoardBase = ModelBase & {
  title?: string;
  template_id?: string | null;
};
export type BoardBase = ModelBase & {
  name: string;
  status: IdealBoardStatusJa;
  status_code: IdealBoardStatus;
  size: IdealBoardSize;
  created_at: string;
  template_id: string | null;
  homework?: HomeworkBoardBase | null;
  host_affiliate_id: string | null;
};
export type ApprovingStatus = "draft" | "approved";
export type ApprovingBase = ModelBase & {
  status: ApprovingStatus;
  affiliate: AffiliateName;
};
export type HomeworkDetails = HomeworkBase & {
  homework_status_changed_history: {
    status?: HomeworkStatusJa;
    updated_at?: string;
  }[];
  change_requested_at?: string | null;
  teacher_checked_at?: string | null;
  submitted_reviewer_at?: string | null;
  checked_reviewer_at?: string | null;
  returned_reviewer_at?: string | null;
  distribution_status_code?: HomeworkTemplateDistributionStatus;
  needs_review: boolean;
  needs_score: boolean;
  is_exam: boolean;
  created_at: string;
  updated_at: string;
  student_comment: string;
  teacher_comment?: string | null;
  reviewer_comments?: CategorizableCommentBase[] | null;
  teacher_comment_for_reviewer?: string | null;
  reviewer_comment_for_teacher?: string | null;
  score: number | null;
  assignees: AffiliateName[];
  teacher_homework_images: TeacherHomeworkImage[];
  reviewer_homework_images: ReviewerHomeworkImage[];
  content: string;
  company?: {
    id?: string;
    name?: string;
  };
  template?: {
    id?: string;
    title?: string;
  };
  reviewer_end_at?: string | null;
  homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  ideal_board?: BoardBase;
  teacher_comment_images?: CommentImageBase[];
  approvings: ApprovingBase[];
  distribution?: {
    start_at?: string | null;
    end_at?: string | null;
  };
  ideal_thumbnail_file?: string | null;
};
export type HomeworkDownloadUrl = ModelBase & {
  student_name: string;
  group_affiliate_name: string;
  student_number?: number | null;
  download_urls: string[];
  is_group: boolean;
};
export type BatchUpdateHomeworkRequest = {
  homework_ids: string[];
  status: HomeworkStatus;
  teacher_comment?: string;
  teacher_comment_for_reviewer?: string;
};
export type SubmitHomeworkFilesRequestStatus =
  | "waiting"
  | "processing_qr"
  | "processing_files"
  | "processed"
  | "failed"
  | "adjusting";
export type SubmitHomeworkFilesRequest = ModelBase & {
  status: string;
  status_code: SubmitHomeworkFilesRequestStatus;
  created_at: string;
  error_data: string | null;
  images: {
    scan_result?: string | null;
  }[];
};
export type CustomFieldFeedbackRequestBody = {
  body: string;
  homework_id: string;
  files?: Blob[];
  wysiwyg_body?: string;
};
export type PutCustomFieldFeedbackRequestBody = {
  body: string;
  wysiwyg_body?: string;
  homework_id: string;
};
export type LimitOptionBase = {
  over_capacity_material?: boolean | null;
  over_capacity_ideal?: boolean | null;
};
export type ReviewerCompanyGroupBase = ModelBase & {
  name: string;
  is_main_reviewer_group: boolean;
  super_company: ModelBase & {
    name?: string;
  };
  is_public_profile?: boolean;
};
export type CategoryType =
  | "text"
  | "grade"
  | "subject"
  | "major"
  | "middle"
  | "minor";
export type BaseCategoryInfo = {
  id: string;
  name: string;
  depth: number;
  type: CategoryType;
  student_visible?: boolean;
  teacher_visible?: boolean;
  owner_visible?: boolean;
  is_help?: boolean;
  student_help_page?: boolean;
  teacher_help_page?: boolean;
  owner_help_page?: boolean;
  reviewer_help_page?: boolean;
  image?: string | null;
};
export type GradeCategoryInfo = BaseCategoryInfo & {
  text_id: string;
};
export type SubjectCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  text_id: string;
};
export type MajorCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type MiddleCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  major_category_id: string;
  text_id: string;
};
export type MinorCategoryInfo = BaseCategoryInfo & {
  major_category_id: string;
  middle_category_id: string;
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type TextCategoryInfo = BaseCategoryInfo & {
  linking_company_tags?: HasIdAndName[];
  linking_user_tags?: UserTagBasic[];
};
export type NotificationBase = ModelBase & {
  title: string;
  message: string;
  resource_info: string | null;
  sender?: {
    id?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
  };
  created_at: string;
  updated_at: string;
  notified_time: string;
};
export type NotificationStatus =
  | "reserved"
  | "waiting"
  | "delivering"
  | "delivered";
export type Notifications = {
  notifications?: (NotificationBase & {
    user_tags?: UserTagBasic[];
    company_tags?: {
      id?: string;
      name?: string;
    }[];
    roles?: {
      id?: string;
      name?: string;
      jp_name?: string;
    }[];
    reservation_time?: string;
    number_of_reserve_deliveries?: number | null;
    status?: NotificationStatus | null;
    companies?: {
      id?: string;
      name?: string;
    }[];
  })[];
  total_count?: number;
};
export type Notification = NotificationBase & {
  user_tags?: UserTagBasic[];
  company_tags?: {
    id?: string;
    name?: string;
  }[];
  roles?: {
    id?: string;
    name?: string;
    jp_name?: string;
  }[];
  reservation_time?: string;
  number_of_reserve_deliveries?: number | null;
  companies?: {
    id?: string;
    name?: string;
  }[];
  number_of_deliveries?: number;
};
export type NotificationRequest = {
  title: string;
  message: string;
  target_roles: string[];
  target_user_tag_ids?: string[];
  target_company_ids: string[];
  target_company_tag_ids?: string[];
  target_affiliate_ids?: string[];
  publication_time?: string;
  with_email?: boolean;
};
export type ArticleKind = "default" | "poster";
export type ArticleStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u518D\u63D0\u51FA/\u63D0\u51FA\u5F85\u3061"
  | "\u5B8C\u4E86"
  | "\u5B8C\u4E86"
  | "\u63D0\u51FA\u6E08\u307F/\u78BA\u8A8D\u4E2D";
export type ArticleStatus =
  | "draft"
  | "correction"
  | "reviewing"
  | "published"
  | "teacher_checking";
export type ArticleAffiliateLinking = {
  affiliate_id: string;
  user?: {
    user_id?: string;
    first_name?: string;
    last_name?: string;
    student_number?: number | null;
    nickname?: string | null;
  };
};
export type ArticleBaseCommon = ModelBase & {
  student_id: string;
  creator_student_affiliate_id?: string | null;
  student_number: number | null;
  student_name: string | null;
  student_nickname?: string | null;
  title: string;
  kind?: ArticleKind;
  status: ArticleStatusJa;
  status_code: ArticleStatus;
  created_at: string;
  created_at_with_second: string;
  updated_at: string;
  updated_at_with_second: string;
  liked?: boolean;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  company: {
    id: string;
    name: string;
  };
  students_linking: ArticleAffiliateLinking[];
  common_tags: CommonTagBase[];
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type InterviewRequestStatusJa =
  | "\u627F\u8A8D\u5F85\u3061"
  | "\u975E\u627F\u8A8D"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86"
  | "\u7121\u52B9";
export type InterviewRequestStatus =
  | "idle"
  | "non_approved"
  | "approved"
  | "completed"
  | "invalided";
export type InterviewRequestArticleBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  professor_affiliate: ProfessorInfoBase;
};
export type ArticleBase = ArticleBaseCommon & {
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
  interview_requests?: InterviewRequestArticleBase[] | null;
};
export type ArticleSortedByColumn = "number" | "created_at";
export type TeacherArticleType = "user_tag_id";
export type CompanyBase = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
};
export type Role =
  | "admin"
  | "super_owner"
  | "owner"
  | "teacher"
  | "student"
  | "reviewer"
  | "content_provider";
export type RoleJa =
  | "SV\u7BA1\u7406\u8005"
  | "\u89AA\u7BA1\u7406\u8005"
  | "\u7BA1\u7406\u8005"
  | "\u6559\u8077\u54E1"
  | "\u751F\u5F92"
  | "\u6DFB\u524A\u5229\u7528\u8005"
  | "\u30B3\u30F3\u30C6\u30F3\u30C4\u4E8B\u696D\u8005";
export type RoleBase = ModelBase & {
  name: Role;
  jp_name: RoleJa;
};
export type AffiliateBase = {
  affiliate_id: string;
  user?: BasicUserInfo;
  company?: CompanyBase;
  role?: RoleBase;
};
export type ArticleImageBase = ModelBase & {
  file?: {
    title?: string;
    thumb_url?: string;
    file_url?: string;
  };
};
export type TeacherSponsorProjectListBase = ModelBase & {
  sponsor_info_id: string;
  name: string;
  company_name: string;
};
export type ArticleStatsBase = ModelBase & {
  student_id: string;
  student_name: string;
  title: string;
  kind?: ArticleKind;
  status: string;
  status_code: ArticleStatus;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  students_linking?: AffiliateBase[];
  images?: ArticleImageBase[];
  allowed_group_affiliates?: GroupAffiliatePackageMinimalBase[];
  body?: string;
  interview_requests?: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type WordCloudWord = {
  text: string;
  value: number;
};
export type WordCloudBase = {
  word_cloud_list: WordCloudWord[];
  total_count: number;
};
export type ArticleSubmitBaseParts = {
  id: string;
  name: string;
  article_company_id: string;
};
export type PblBoardCampaignRequestStatusJa =
  | "\u672A\u78BA\u8A8D"
  | "\u627F\u8A8D"
  | "\u975E\u627F\u8A8D";
export type PblBoardCampaignRequestCompanyName = ModelBase & {
  status: PblBoardCampaignRequestStatusJa;
  company: HasIdAndName;
};
export type ArticleSubmitBase = ModelBase & {
  article_companies?: ArticleSubmitBaseParts[];
  pbl_board_campaign_requests?: PblBoardCampaignRequestCompanyName[];
};
export type AllowedArticleCategorizableType =
  | "Sponsor::Info"
  | "Pbl::Sponsor::Project";
export type ArticleCategorizable = ModelBase & {
  type: AllowedArticleCategorizableType;
  name: string;
  sponsor_logo: string | null;
  company_name?: string | null;
};
export type ArticleContentType =
  | "theme"
  | "background"
  | "hypothesis"
  | "survey_method"
  | "result"
  | "summary"
  | "future_issues";
export type ArticleContent = ModelBase & {
  content_type: ArticleContentType;
  body: string | null;
};
export type ArticleSponsorContent = ModelBase & {
  pbl_sponsor_article_content_template_field_id: string;
  body: string | null;
};
export type SponsorArticleContentTemplateFieldBase = ModelBase & {
  title: string;
};
export type SponsorArticleContentTemplateBase = ModelBase & {
  title: string;
  description?: string | null;
  pbl_sponsor_lecture_id?: string;
  fields?: SponsorArticleContentTemplateFieldBase[];
};
export type ArticlePoster = {
  sponsor_logo: string | null;
  categorizable?: ArticleCategorizable;
  article_contents: ArticleContent[];
  article_sponsor_contents?: ArticleSponsorContent[];
  pbl_sponsor_article_content_template?: SponsorArticleContentTemplateBase | null;
};
export type GroupAffiliatePackageBase = ModelBase & {
  title: string;
  description?: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateBase;
  affiliates: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    grade?: string;
    nickname?: string;
  }[];
  assign_teacher_affiliates?: AffiliateName[];
};
export type ArticleDetailBase = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type ArticleAsTeacher = ArticleDetailBase & ArticleSubmitBase;
export type ArticleCommentImageBase = ModelBase & {
  title: string | null;
  url: string | null;
};
export type ArticleComment = ModelBase & {
  article_id: string;
  created_at: string;
  body: string;
  commentor_id: string | null;
  commentor_company_name?: string | null;
  commenter_name?: string | null;
  commenter_nickname?: string | null;
  commentor_affiliate_id?: string | null;
  role?: RoleBase;
  article_comment_images?: ArticleCommentImageBase[];
};
export type ArticleFeedback = ModelBase & {
  article_id: string;
  teacher_id: string;
  created_at: string;
  body: string;
  teacher?: {
    first_name: string;
    last_name: string;
  };
};
export type StudentCommentList = {
  article_comments: (ModelBase & {
    title: string;
    student_name: string;
    student_nickname: string | null;
    comment: string;
    comment_name: string;
    comment_nickname: string | null;
  })[];
  total_count: number;
};
export type UserTaggingBatchCreateResponses = {
  success_user_taggings: {
    user_id?: string;
    user_name?: string;
    user_tag_id?: string;
    user_tag_name?: string;
  }[];
  errors: (string | null)[];
};
export type UserTaggingBatchCreateParams = {
  user_ids: string[];
  user_tag_id: string;
  company_id?: string;
};
export type UserTagType = UserTagTypeBasic & {
  is_editable?: boolean;
  user_tags: UserTagBasic[];
};
export type UserTagTypeKind = "limited" | "nationwide";
export type SimpleUserList = {
  id: string;
  name: string;
  nickname: string;
};
export type UserTagsBatchCreateParams = {
  user_ids: string[];
  user_tag_ids: string[];
};
export type HomeworkTemplateStatus = "draft" | "active" | "inactive";
export type HomeworkTemplateStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u6709\u52B9"
  | "\u505C\u6B62\u4E2D";
export type HomeworkTemplateConvertStatus =
  | "not_needed"
  | "converting"
  | "adding_marks"
  | "adjusting"
  | "merging"
  | "processed"
  | "failed";
export type HomeworkTemplateConvertStatusJa =
  | "\u5909\u63DB\u306A\u3057"
  | "\u5909\u63DB\u4E2D"
  | "\u30DE\u30FC\u30AF\u4E2D"
  | "\u88DC\u6B63\u4E2D"
  | "\u30DE\u30FC\u30B8\u4E2D"
  | "\u51E6\u7406\u5B8C\u4E86"
  | "\u51E6\u7406\u5931\u6557";
export type HomeworkTemplateDistributionStatusJa =
  | "\u65B0\u898F"
  | "\u751F\u5F92\u5C55\u958B\u6E08\u307F"
  | "\u30EC\u30D3\u30E5\u30A2\u30FC\u78BA\u8A8D\u4E2D"
  | "\u30EC\u30D3\u30E5\u30A2\u30FC\u56DE\u7B54\u6E08\u307F"
  | "\u8FD4\u5374\u6E08\u307F";
export type HomeworkTemplateDistributionBase = ModelBase & {
  created_at: string;
  memo: string | null;
  max_distribution_end_users: number | null;
  end_user_count: number;
  start_at: string | null;
  end_at: string | null;
  reviewer_end_at: string | null;
  status: HomeworkTemplateDistributionStatusJa;
  status_code: HomeworkTemplateDistributionStatus;
  is_exam: boolean;
};
export type DeletionProgress = "running" | "failed" | "idle";
export type Company = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  custom_master?: {
    title?: string;
    value?: string;
  }[];
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
  report_recipient_email?: string;
  report_the_number_of_users_email?: string;
  report_homework_template_distribution_histories_email?: string;
  inquiry_email?: string;
  limited_user_tag_monthly_report_email?: string;
  created_at?: string;
  owner_number?: number;
  teacher_number?: number;
  student_number?: number;
  has_super_owners?: boolean;
  options?: Option;
  company_tags?: {
    id?: string;
    name?: string;
    grade?: string | null;
    company_tag_type_id?: string;
    company_tag_type_name?: string;
  }[];
  linking_reviewer_companies?: boolean;
  student_input_settings?: StudentInputSetting;
  unique_id?: string | null;
  deletion_progress?: DeletionProgress | null;
  reviewer_company_group?: {
    id?: string;
    name?: string;
  };
  student_count_by_grade?: {
    grade?: string;
    count?: number;
  }[];
};
export type HomeworkTemplateDistributionPblPlanItem = ModelBase & {
  policy_summary: string;
};
export type HomeworkTemplateDistribution = HomeworkTemplateDistributionBase & {
  submitted_at: string | null;
  checked_at: string | null;
  checked_at_raw: string | null;
  returned_at: string | null;
  reviewer_memo: string | null;
  company_memo: string | null;
  company?: Company;
  distribution_images?: {
    reviewer_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
    super_owner_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
    teacher_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
  };
  school_manager?: HasIdAndName;
  pbl_plan_items: HomeworkTemplateDistributionPblPlanItem[];
};
export type HomeworkTemplateBase = {
  id: string;
  created_at: string;
  title: string;
  status_code: HomeworkTemplateStatus;
  status: HomeworkTemplateStatusJa;
  convert_status_code: HomeworkTemplateConvertStatus;
  convert_status: HomeworkTemplateConvertStatusJa;
  homework_template_distribution?: HomeworkTemplateDistribution;
  needs_review: boolean;
  needs_score: boolean;
  is_exam: boolean;
  for_group: boolean;
  has_automatic_scoring: boolean;
  has_attached_file: boolean;
  end_user_count?: number;
  creator_role?: string | null;
  learning_template_package: {
    is_set: boolean;
    title: string | null;
  };
  ideal_thumbnail_file: string | null;
  common_tags: CommonTagBase[];
};
export type HomeworkTemplateMeta = {
  created_by_owned_company: boolean;
  created_homework_count: number;
  submitted_homework_count: number;
  reviewing_homework_count: number;
  finished_review_homework_count: number;
  returned_homework_count: number;
  school_homework_count: number;
  before_reviewing_homework_count: number;
  set_reviewer_homework_count: number;
};
export type HomeworkTemplateBaseForOwnerAndTeacher = HomeworkTemplateBase &
  HomeworkTemplateMeta & {
    homework_template_distribution?: HomeworkTemplateDistribution;
  };
export type HomeworkTemplateSearchType = "title";
export type IdealBoardTemplateStatus = "draft" | "reserved" | "distributed";
export type HasIdAndTitle = {
  id: string;
  title: string;
};
export type BoardTemplateList = ModelBase & {
  name: string;
  status: IdealBoardTemplateStatus;
  size: IdealBoardSize;
  start_at: string;
  homework_template?: HasIdAndTitle | null;
};
export type HomeworkTemplateAddition = {
  content: string;
  reviewer_company_group: {
    id?: string | null;
    name?: string | null;
    is_public_profile?: boolean | null;
  };
  homework_template_images?: {
    id: string;
    title: string;
    thumb_url: string;
    file_url: string;
    adjusted_file_url: string | null;
  }[];
  homework_template_custom_fields: HomeworkTemplateCustomField[];
  ideal_board_template?: BoardTemplateList | null;
  distributed: boolean;
};
export type HomeworkTemplateForOwnerAndTeacher = HomeworkTemplateBase &
  HomeworkTemplateAddition &
  HomeworkTemplateMeta & {
    homework_template_distribution?: HomeworkTemplateDistribution;
    idle_distribution_job_present: boolean;
    reviewer_company_group: {
      id?: string | null;
      name?: string | null;
    };
  };
export type HomeworkTemplateCreateRequest = {
  title: string;
  content?: string;
  needs_review?: boolean;
  needs_score?: boolean;
  is_exam?: boolean;
  for_group?: boolean;
  end_at?: string | null;
  reviewer_end_at?: string | null;
  reviewer_company_group_id?: string;
  ideal_board_template_id?: string;
  has_automatic_scoring?: boolean;
  has_attached_file?: boolean;
  homework_images?: Blob[];
  ideal_thumbnail_file?: Blob;
};
export type NumberOfEachStatus =
  | {
      status: HomeworkStatus;
      count: number;
    }[]
  | null;
export type HomeworkTemplateStatsBase = ModelBase & {
  title?: string;
  number_of_each_homework_status?: NumberOfEachStatus;
};
export type HomeworkTemplateDetailListBase = ModelBase &
  HomeworkTemplateMeta & {
    homework_template_distribution?: HomeworkTemplateDistribution;
  };
export type HomeworkTemplateUpdateRequest = {
  title?: string;
  content?: string;
  needs_score?: boolean;
  is_exam?: boolean;
  reviewer_company_group_id?: string;
  end_at?: string | null;
  reviewer_end_at?: string | null;
  has_automatic_scoring?: boolean;
  ideal_thumbnail_file?: Blob;
  has_attached_file?: boolean;
};
export type HomeworkTemplateCustomFieldType =
  | "text"
  | "radio"
  | "select"
  | "profile";
export type CustomFieldCommonRequestBody = {
  type: HomeworkTemplateCustomFieldType;
  title: string;
  sequence: number;
  min_length?: number;
  max_length?: number;
  is_required: boolean;
  possible_values?: string[];
  is_exact_match?: boolean;
  possible_scores?: number[];
  possible_scores2?: number[];
  answer_flags?: number[];
  custom_field_category_id?: string;
};
export type HomeworkTemplateChunkImage = ModelBase & {
  url: string;
};
export type HomeworkTemplateChunk = ModelBase & {
  template_id: string;
  order: number;
  image?: HomeworkTemplateChunkImage;
  placement: {
    top: number;
    left: number;
    right: number;
    bottom: number;
    natural_width: number;
    natural_height: number;
    page: number;
  }[];
};
export type FileRequiredRequestBody = {
  file: Blob;
};
export type UserTag = ModelBase & {
  name: string;
  number_of_linking_users?: number;
  limit_number?: number | null;
  is_editable?: boolean | null;
  user_tag_type?: UserTagTypeBasic;
  user_tag_company?: {
    start_at?: string | null;
  };
};
export type UserTagTypeRoleType = "student" | "teacher";
export type AssignedCompany = ModelBase & {
  name: string;
  start_at?: string | null;
  number_of_linking_users: number;
};
export type UserTagDetail = ModelBase & {
  name: string;
  limit_number?: number | null;
  is_editable: boolean;
  assigned_companies: AssignedCompany[];
  user_tag_type: UserTagTypeBasic;
};
export type PblReportTemplateBase = ModelBase & {
  title: string;
  status: string;
  due_date_time: string | null;
};
export type PblReportTemplateDetailsForTeacher = PblReportTemplateBase;
export type PblReportAnswerStatusEnum = "todo" | "drafting" | "submitted";
export type PblReportAnswerBase = ModelBase & {
  student: string;
  student_nickname?: string | null;
  status: string;
  status_code?: PblReportAnswerStatusEnum;
};
export type PblReportTemplateStats = ModelBase & {
  title: string;
  status: string;
  due_date_time: string | null;
  number_of_each_answer_status?:
    | {
        status: string;
        count: number;
      }[]
    | null;
  answers?: PblReportAnswerBase[] | null;
};
export type PblReportTextBase = {
  id: string;
  sequence: number;
  title?: string | null;
  description?: string | null;
  example?: string | null;
  text_min_char_size?: number | null;
  has_title_required?: boolean | null;
};
export type Schema2 = ModelBase & {
  template_framework_id?: string;
  sequence?: number;
  title: string;
  description?: string;
  text_example?: string;
  text_min_char_size?: number;
  created_at: string;
  updated_at: string;
};
export type Schema = ModelBase & {
  owner: AffiliateBase;
  title: string;
  created_at: string;
  updated_at: string;
  item_frameworks?: Schema2[];
};
export type PblReportAnswerTextBase = {
  id: string;
  title?: string;
  body?: string;
  item_id: string;
};
export type PblReportCommentBase = {
  id?: string;
  message?: string;
  created_at?: string;
  commented_by?: string;
};
export type PblReportAnswerTextBaseWithComments = PblReportAnswerTextBase & {
  comments: PblReportCommentBase[];
};
export type PblReportAnswerImageBase = ModelBase & {
  file?: {
    title?: string;
    thumb_url?: string;
    file_url?: string;
  };
};
export type PblReportAnswerDetailsForTeacher = PblReportAnswerBase & {
  template: PblReportTemplateDetailsForTeacher;
  items: PblReportTextBase[];
  answer_items: PblReportAnswerTextBaseWithComments[];
  comments: PblReportCommentBase[];
  answer_images: PblReportAnswerImageBase[];
};
export type PblReportItemTypeEnum = "text";
export type PblCardBookmarkSourceType =
  | "newspaper"
  | "internet"
  | "book"
  | "other";
export type StampableType = "Pbl::Cards::InformationLiteracy::Bookmark";
export type StampType = "good" | "nice" | "great" | "surprised";
export type StampBase = ModelBase & {
  stampable_type: StampableType;
  stampable_id: string;
  affiliate_id: string;
  user_name: string;
  kind: StampType;
  created_at: string;
};
export type StampableStampFields = {
  stamp_total_count: number;
  own_stamp_fields: StampBase[];
  kinds: {
    good_counts: number;
    nice_counts: number;
    great_counts: number;
    surprised_counts: number;
  };
};
export type PblCardBookmarkBase = ModelBase & {
  source_type: string;
  source_type_code: PblCardBookmarkSourceType;
  student_name: string;
  student_nickname?: string | null;
  title?: string;
  url: string | null;
  memo: string;
  wysiwyg_body?: string | null;
  created_at: string;
  updated_at: string;
  num_comments: number;
  images: {
    id: string;
    title?: string | null;
    url: string;
  }[];
  allowed_group_affiliates?: GroupAffiliatePackageMinimalBase[];
  stamp_fields: StampableStampFields;
  creator_affiliate_id?: string | null;
  student_number?: number | null;
  common_tags: CommonTagBase[];
  chat_room_id: string | null;
};
export type PblCardBookmarkList = PblCardBookmarkBase & {
  read_status: boolean;
};
export type BookmarkSortedByColumn = "number" | "created_at";
export type PostPblCardBookmarkRequestBody = {
  source_type?: PblCardBookmarkSourceType;
  title?: string;
  url?: string;
  memo?: string;
  wysiwyg_body?: string;
  files?: Blob[];
  add_group_affiliate_ids?: string[];
  common_tag_names?: string[];
};
export type PutPblCardBookmarkRequestBody = PostPblCardBookmarkRequestBody & {
  remove_group_affiliate_ids?: string[];
};
export type PblCardBookmarkStats = {
  date: string;
  count: number;
};
export type PblCardCommentBase = ModelBase & {
  commentor_id: string;
  commenter_name: string;
  commentor_role: Role | null;
  body: string;
  created_at: string;
};
export type CompanyTagType = ModelBase & {
  name: string;
};
export type CompanyTag = ModelBase & {
  name: string;
  free?: boolean;
  number_of_linking_companies?: number;
  number_of_linking_users?: number;
  tag_type?: CompanyTagType;
};
export type HistoryCompany = {
  id: string;
  name: string;
  number_of_students_distributed: number;
};
export type HistoryMaterial = CategoryBase & {
  id: string;
  title: string;
  number_of_people_distributed: number;
  number_of_schools_distributed: number;
  distributed_companies: HistoryCompany[];
};
export type DistributionStatusStatus =
  | "idle"
  | "save_running"
  | "save_failed"
  | "distribution_idle"
  | "distribution_running"
  | "distribution_failed"
  | "completed";
export type MaterialDistributionHistory = {
  id: string;
  user_tags: (UserTagBasic & {
    user_tag_type_id?: string;
    user_tag_type_name?: string;
  })[];
  company_tags: CompanyTag[];
  selected_companies: HasIdAndName[];
  selected_category?: CategoryBase | null;
  materials: HistoryMaterial[];
  distribution_time?: string;
  expiration_time?: string | null;
  created_at?: string;
  status: DistributionStatusStatus | null;
};
export type MaterialDistributionHistoryListStudents = {
  total_count: number;
  number_of_students_per_grade: {
    grade: string | null;
    count: number;
  }[];
  students: (BasicUserInfo & {
    grade: string | null;
  })[];
};
export type MaterialDistributionHistorySearchByUserId = {
  id: string;
  user_hashid: string;
  last_name: string;
  first_name: string;
  company_name: string;
  distribution_time?: string;
  expiration_time?: string | null;
  reservation_time?: string | null;
  created_at?: string;
  material?: CategoryBase & {
    id?: string;
    title?: string;
    memo?: string;
    description?: string;
    image_contents?: ImageInfo[];
    video_contents?: VideoInfo[];
  };
};
export type SteamLibraryContentDifficulty = "easy" | "normal" | "difficult";
export type SteamLibraryContentAspect = "familiar" | "japan" | "world";
export type PblSteamContentBase = ModelBase & {
  sl_content_id: number;
  title: string;
  difficulty: SteamLibraryContentDifficulty;
  aspect: SteamLibraryContentAspect;
  is_scientific: boolean;
  has_carousel: boolean;
  created_at: string;
  updated_at: string;
};
export type PblSteamContent = PblSteamContentBase & {
  bookmark: {
    id: string | null;
  };
  recommendation: {
    id: string | null;
  };
};
export type TeacherContentSearchType = "title";
export type SteamLibraryContentWorkProgress =
  | "not_viewed"
  | "viewing"
  | "viewed";
export type StudentPblSteamContentDetail = PblSteamContent & {
  progress?: SteamLibraryContentWorkProgress;
};
export type PblSteamLecture = ModelBase & {
  pbl_steam_content_id: string;
  sl_lecture_id: number;
  title?: string;
  sequence: number;
  created_at: string;
  updated_at: string;
};
export type PblSteamCommentBase = ModelBase & {
  pbl_steam_content_id: string;
  pbl_steam_lecture_id: string;
  affiliate: AffiliateBase;
  is_public?: boolean;
  body?: string;
  created_at: string;
  updated_at: string;
};
export type PblSteamCommentList = {
  content: PblSteamContentBase;
  lecture: PblSteamLecture;
  comment: PblSteamCommentBase;
};
export type CreateSteamLectureRequestBody = {
  title: string;
  sl_lecture_id: number;
  sequence: number;
};
export type ContentBookmarkBase = ModelBase & {
  pbl_steam_content_id: string;
  sl_content_id: number;
  title: string;
};
export type StudentContentList = ModelBase & {
  content: StudentPblSteamContentDetail;
  teacher_affiliate: {
    id: string;
    first_name: string;
    last_name: string;
  };
  student_affiliate: {
    id: string;
    first_name: string;
    last_name: string;
  };
};
export type ContentRecommendationBase = ModelBase & {
  company_id: string;
  company_name: string;
  content: PblSteamContentBase;
};
export type TeacherPblPlanItemBase = ModelBase & {
  class_minutes?: number;
  plan_years?: number;
  class_start_date?: string;
  class_end_date?: string;
  policy_summary?: string;
  why?: string;
  how?: string;
  what?: string;
  first_year_summary?: string;
  second_year_summary?: string;
  third_year_summary?: string;
  status: string;
  students_count: number;
  created_at: string;
  updated_at: string;
  deletion_progress: DeletionProgress;
};
export type TeacherPblPlanDay = ModelBase & {
  pbl_plan_item_id: string;
  class_date?: string;
  start_time?: string;
  created_at: string;
  updated_at: string;
};
export type TeacherPblPlanItemDetails = TeacherPblPlanItemBase & {
  days: TeacherPblPlanDay[];
};
export type PblPlanItemStatus = "draft" | "active";
export type PlanContentsCategorizableType =
  | "Article"
  | "HomeworkTemplateDistribution"
  | "Homework"
  | "Material"
  | "Pbl::Report::Template"
  | "Pbl::Sponsor::Lecture"
  | "Pbl::Steam::Content"
  | "Pbl::Steam::Lecture"
  | "CustomContent";
export type PblPlanContentKind = "learn" | "make";
export type TeacherPblPlanContent = ModelBase & {
  pbl_plan_class_id: string;
  categorizable_type: PlanContentsCategorizableType;
  categorizable_id: string;
  categorizable_ja_model_name: string;
  categorizable_title: string;
  categorizable_related_ids: string[];
  kind: PblPlanContentKind;
  sl_lecture_id?: number | null;
  is_project_list?: boolean;
};
export type TeacherPblPlanClass = ModelBase & {
  pbl_plan_item_id: string;
  year?: number;
  number?: number;
  date?: string;
  description?: string;
  is_skip?: boolean;
  article_creation_time?: boolean;
  created_at: string;
  updated_at: string;
  plan_contents?: TeacherPblPlanContent[];
  schedule_id?: string | null;
  class_minutes?: number | null;
  title?: string | null;
};
export type PblPlanContentNav = ModelBase &
  TeacherPblPlanContent & {
    image_url?: string | null;
    number_of_each_homework_status?: NumberOfEachStatus;
  };
export type PblPlanDayType =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";
export type TeacherPblPlanStudent = ModelBase & {
  pbl_plan_item_id: string;
  student?: AffiliateName & {
    email?: string;
  };
  created_at: string;
  updated_at: string;
};
export type InquiryAffiliateBase = {
  affiliate_id?: string | null;
  has_deleted: boolean;
  company_id?: string | null;
  company_name?: string | null;
  user_id?: string | null;
  user_name?: string | null;
  email?: string | null;
  role?: RoleBase | null;
};
export type InquiryCommentCount = {
  count: number;
  is_new: boolean;
};
export type InquirySimpleListBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  created_at: string;
  updated_at: string;
  comments: InquiryCommentCount;
};
export type InquiryComment = ModelBase & {
  user: {
    affiliate_id?: string;
    company_id?: string;
    company_name?: string;
    user_id?: string;
    user_name?: string;
  };
  status: string;
  body: string;
  created_at: string;
  updated_at: string;
};
export type AffiliateCompanyAndUserBase = {
  affiliate_id: string;
  user_id: string;
  company_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: RoleBase;
  nickname: string | null;
};
export type InquirySimpleBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  body: string;
  category?: string | null;
  os_name: string;
  os_version: string;
  browser_name: string;
  browser_version: string;
  created_at: string;
  updated_at: string;
  comments: InquiryComment[];
  target_affiliates: AffiliateCompanyAndUserBase[];
};
export type SponsorSalesChannel = "direct_selling" | "selling_agency";
export type SponsorImageBase = {
  id: string;
  company_id: string;
  url: string | null;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type VideoFileBase = {
  url: string | null;
};
export type SponsorVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorInfoListBase = ModelBase & {
  company_id: string;
  logo_url: string | null;
  name: string;
  description?: string;
  is_public: boolean;
  sales_channel?: SponsorSalesChannel | null;
  created_at: string;
  updated_at: string;
  project_count: number;
  image: SponsorImageBase | null;
  video_content?: SponsorVideoContentBase | null;
  tags: SponsorTagBase[] | null;
};
export type SponsorInfosSearchType = "name" | "description" | "tag";
export type SponsorInfoBase = ModelBase & {
  company_id: string;
  logo_url: string | null;
  name: string;
  description?: string;
  is_public: boolean;
  sales_channel?: SponsorSalesChannel | null;
  created_at: string;
  updated_at: string;
  project_count: number;
  image: SponsorImageBase | null;
  video_content?: SponsorVideoContentBase | null;
  tags: SponsorTagBase[] | null;
};
export type SponsorCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: {
    url?: string | null;
  };
};
export type SponsorProjectTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorLectureVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorProjectImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorProjectWorkProgressJa =
  | "\u53D7\u8B1B\u4E2D"
  | "\u53D7\u8B1B\u6E08\u307F";
export type SponsorProjectAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description?: string;
  display_allowed: boolean;
  display_in_carousel: boolean;
  created_at: string;
  updated_at: string;
  tags: SponsorProjectTagBase[] | null;
  file?: {
    url?: string | null;
  };
  video_content?: SponsorLectureVideoContentBase | null;
  image?: SponsorProjectImageBase | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
  project_bookmark?: ModelBase | null;
  project_recommend?: ModelBase | null;
};
export type SponsorInfoDetailsBase = SponsorInfoBase & {
  sponsor_logo_url: string | null;
  categories: SponsorCategoryBase[] | null;
  projects: SponsorProjectAndTagsBase[] | null;
};
export type SponsorProjectsSearchType = "name" | "description" | "tag";
export type SponsorProjectVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorProjectCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: {
    url?: string | null;
  };
};
export type SponsorLectureImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorLectureTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorLectureAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content?: SponsorLectureVideoContentBase | null;
  tags?: SponsorLectureTagBase[] | null;
};
export type SponsorProjectDetailsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description: string;
  display_allowed?: boolean;
  file?: {
    url?: string | null;
  };
  created_at: string;
  updated_at: string;
  image?: SponsorProjectImageBase | null;
  video_content?: SponsorProjectVideoContentBase | null;
  tags: SponsorProjectTagBase[] | null;
  categories: SponsorProjectCategoryBase[] | null;
  lectures: SponsorLectureAndTagsBase[] | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
  lecture_viewed_count: number;
  professor_affiliate_ids?: string[] | null;
  is_recommend: boolean;
  company_name?: string;
  sponsor_logo_url?: string | null;
};
export type SponsorProjectBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description?: string;
  display_allowed: boolean;
  display_in_carousel: boolean;
  created_at: string;
  updated_at: string;
  file?: {
    url?: string | null;
  };
  image?: SponsorProjectImageBase | null;
  video_content?: SponsorProjectVideoContentBase | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
};
export type SponsorProjectsSortType = "sequence" | "recent" | "random";
export type SponsorLectureFileBase = ModelBase & {
  company_id: string;
  file: {
    title: string | null;
    url: string | null;
  };
};
export type Status = "\u65B0\u898F" | "\u78BA\u8A8D\u6E08\u307F";
export type SponsorLectureDetailsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content: SponsorLectureVideoContentBase | null;
  tags: SponsorLectureTagBase[] | null;
  files: SponsorLectureFileBase[] | null;
  learning_progress?: {
    id?: string;
    status?: Status;
  };
  article_content_template?: {
    id: string;
    title: string;
    description?: string | null;
  } | null;
  company_name?: string;
  sponsor_logo_url?: string | null;
  project_name?: string;
};
export type PblProjectCommentBase = ModelBase & {
  pbl_sponsor_project_id: string;
  pbl_sponsor_lecture_id: string;
  affiliate: AffiliateBase;
  body?: string;
  created_at: string;
  updated_at: string;
};
export type SponsorProjectBookmarkBase = ModelBase & {
  project_id: string;
  affiliate: AffiliateBase;
};
export type SponsorProjectRecommendBase = ModelBase & {
  project_id: string;
  affiliate: AffiliateName;
};
export type SponsorTeacherLectureListBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id?: string;
  name: string;
};
export type TeacherPblProjectCommentListBase = ModelBase & {
  pbl_sponsor_project_id: string;
  pbl_sponsor_lecture_id: string;
  commenter_name: string;
  commenter_nickname: string | null;
  body: string;
};
export type TeacherPblProjectCommentList = {
  project: TeacherSponsorProjectListBase;
  lecture: SponsorTeacherLectureListBase;
  comment: TeacherPblProjectCommentListBase;
};
export type StudyLogBasicBase = ModelBase & {
  log: string;
  categorizable_type: string;
  categorizable_id: string;
  behavior: string;
  created_at: string;
  categorizable?: {
    parent_category_id: string | null;
    is_discarded: boolean;
  };
};
export type StudyLogBase = StudyLogBasicBase & {
  student: AffiliateName;
};
export type StudyByStudentBase = {
  student_user_id?: string;
  student_affiliate_id?: string;
  full_name?: string;
  study_logs?: StudyLogBasicBase[];
};
export type DailyLogStatsValuesBase = {
  count: number;
  study_time: number;
};
export type DailyLogStatsBase = {
  theme_settings: DailyLogStatsValuesBase;
  research: DailyLogStatsValuesBase;
  analysis: DailyLogStatsValuesBase;
  summary: DailyLogStatsValuesBase;
};
export type ActivityBase = {
  category: string;
  count: number;
  study_time: number;
};
export type DeliverableListBase = {
  student_user_id: string;
  student_affiliate_id: string;
  full_name: string;
  nickname?: string;
  material_works: {
    count?: number;
    todo_count?: number;
    in_progress_count?: number;
  };
  homeworks: {
    count?: number;
    todo_count?: number;
    change_requested_count?: number;
  };
  articles: {
    count?: number;
    draft_count?: number;
    correction_count?: number;
  };
  learning_packages: {
    count?: number;
  };
  bookmarks: {
    count?: number;
  };
  ideals: {
    count?: number;
    todo_count?: number;
    in_progress_count?: number;
  };
  answers: {
    count?: number;
  };
};
export type StudentLogCategorizableType =
  | "Article"
  | "Homework"
  | "MaterialWork"
  | "LearningPackage"
  | "Pbl::Report::Answer"
  | "Ideal::Board"
  | "Pbl::Cards::InformationLiteracy::Bookmark";
export type StudentLogDeliverableListBase = ModelBase & {
  title: string;
  updated_at: string;
  categorizable_type: StudentLogCategorizableType;
};
export type StudentLogDeliverableMaterialWorkBase =
  StudentLogDeliverableListBase & {
    status: MaterialWorkStatusJa;
    status_code: MaterialWorkStatus;
    parent_id: string;
  };
export type StudentLogDeliverableHomeWorkBase =
  StudentLogDeliverableListBase & {
    status: HomeworkStatusJa;
    status_code: HomeworkStatus;
    parent_id: string;
  };
export type PblReportAnswerStatusEnumJa =
  | "\u65B0\u898F"
  | "\u4E0B\u66F8\u304D"
  | "\u63D0\u51FA";
export type StudentLogDeliverablePblAnswerBase =
  StudentLogDeliverableListBase & {
    status: PblReportAnswerStatusEnumJa;
    status_code: PblReportAnswerStatusEnum;
    parent_id: string;
  };
export type LearningPackageStatusJa = "\u672A\u5B8C\u4E86" | "\u5B8C\u4E86";
export type LearningPackageStatus = "incompleted" | "completed";
export type StudentLogDeliverableLearningPackageBase =
  StudentLogDeliverableListBase & {
    status: LearningPackageStatusJa;
    status_code: LearningPackageStatus;
    parent_id: string;
  };
export type StudentLogDeliverableArticleBase = StudentLogDeliverableListBase & {
  status: ArticleStatusJa;
  status_code: ArticleStatus;
};
export type StudentLogDeliverableIdealBase = StudentLogDeliverableListBase & {
  status: IdealBoardStatusJa;
  status_code: IdealBoardStatus;
};
export type RubricStatusJa = "\u4E0B\u66F8\u304D" | "\u516C\u958B\u200B";
export type RubricStatus = "draft" | "published";
export type RubricItemCurriculumTypeJa =
  | "\u77E5\u8B58\u53CA\u3073\u6280\u80FD"
  | "\u77E5\u8B58"
  | "\u6280\u80FD"
  | "\u601D\u8003\u529B\u30FB\u8868\u73FE\u529B\u30FB\u5224\u65AD\u529B"
  | "\u601D\u8003\u529B"
  | "\u8868\u73FE\u529B"
  | "\u5224\u65AD\u529B"
  | "\u5B66\u3073\u306B\u5411\u304B\u3046\u529B\u30FB\u4EBA\u9593\u6027"
  | "\u5B66\u3073\u306B\u5411\u304B\u3046\u529B"
  | "\u4EBA\u9593\u6027";
export type RubricItemCurriculumType =
  | "knowledge_and_skills"
  | "knowledge"
  | "skills"
  | "intelligence_and_expression_and_judgement"
  | "intelligence"
  | "expression"
  | "judgement"
  | "power_to_learn_and_humanity"
  | "power_to_learn"
  | "humanity";
export type RubricItemBase = ModelBase & {
  rubric_id: string;
  curriculum_type: RubricItemCurriculumTypeJa;
  curriculum_type_code: RubricItemCurriculumType;
  perspective: string;
  level_1: string;
  level_2: string;
  level_3: string;
  level_4: string;
  level_5: string;
  created_at: string;
  updated_at: string;
};
export type RubricBase = ModelBase & {
  title: string;
  status: RubricStatusJa;
  status_code: RubricStatus;
  created_at: string;
  updated_at: string | null;
  teacher_affiliate?: AffiliateName;
  update_affiliate?: AffiliateName | null;
  rubric_evaluate_count: number;
  rubric_items?: RubricItemBase[] | null;
};
export type RubricEvaluateStudentRateBase = ModelBase & {
  score: number;
  rubric_item: RubricItemBase;
};
export type RubricEvaluateStudentBase = ModelBase & {
  comment?: string;
  student: AffiliateName;
  evaluate_student_rates?: RubricEvaluateStudentRateBase[];
};
export type RubricEvaluateBase = ModelBase & {
  title: string;
  description?: string;
  students: RubricEvaluateStudentBase[];
};
export type RubricEvaluateSortedByColumn = "title" | "created_at";
export type LearningTemplatePackageDistributionSettingBase = ModelBase & {
  learning_template_package_id: string;
  start_at: string;
  end_at?: string;
  kind: string;
  grade?: StudentGrade;
  forbid_download: boolean;
  created_at: string;
  updated_at: string;
  distributor_affiliate?: AffiliateBase | null;
  companies?: CompanyBase[];
  user_tags?: UserTagBasic[];
};
export type LearningTemplatePackageBase = ModelBase & {
  title: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  is_distributed: boolean;
  affiliate: AffiliateName;
  packagings_count: number;
};
export type LearningTemplatePackageDistributionSettingBaseDetails =
  LearningTemplatePackageDistributionSettingBase & {
    learning_template_package?: LearningTemplatePackageBase;
  };
export type LearningPackageBase = ModelBase & {
  title: string;
  description?: string;
  status: LearningPackageStatusJa;
  start_at?: string;
  end_at?: string;
  created_at: string;
  updated_at: string;
  affiliate: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    company_id: string;
    company_name: string;
  };
};
export type LearningProgressCategorizableType =
  | "Pbl::Steam::Lecture"
  | "Pbl::Sponsor::Lecture";
export type LearningProgressBase = ModelBase & {
  categorizable_type?: LearningProgressCategorizableType;
  categorizable_id?: string;
  status?: "\u65B0\u898F" | "\u78BA\u8A8D\u6E08\u307F";
  sl_lecture_id?: number | null;
};
export type LearningPackageBaseDetails = LearningPackageBase & {
  packagings?: {
    id: string;
    packageable_type: string;
    packageable_id?: string;
    title: string | null;
    status?: string | null;
    packagings?:
      | {
          id?: string;
          packageable_type?: string;
          packageable_id?: string;
          title?: string;
          status?: string;
          start_at?: string | null;
          learning_progress?: LearningProgressBase;
        }[]
      | null;
  }[];
};
export type LearningTemplatePackageDistributionHistoryBase = ModelBase & {
  learning_template_package_id: string;
  distributor_affiliate_id: string;
  start_at: string;
  end_at?: string;
  distribution_at: string;
  kind: string;
  grade?: StudentGrade;
  forbid_download: boolean;
  number_of_completed_learning_package?: number;
  number_of_students_distributed: number;
  number_of_school_distributed?: number;
  status?: DistributionStatusStatus | null;
  deletion_progress: DeletionProgress;
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
  learning_template_package: LearningTemplatePackageBase;
};
export type DistributedStudents = {
  id: string;
  first_name: string;
  last_name: string;
  company_id: string;
  company_name: string;
  grade: string | null;
};
export type PackagingBase = ModelBase & {
  packageable_type: string;
  packageable_id: string;
  packageable_title: string;
  sl_lecture_id?: number | null;
};
export type MaterialHomeworkTemplatePackageBase = ModelBase & {
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateName;
  packagings: PackagingBase[];
};
export type PackagingAsLearningTemplatePackageBase = ModelBase & {
  packageable_type: string;
  packageable_id: string;
  packageable_title: string;
  material_homework_template_package?: MaterialHomeworkTemplatePackageBase | null;
  sl_lecture_id?: number | null;
};
export type PblBoardCampaignCompany = {
  id: string;
  name: string;
  parent_company_id?: number | null;
};
export type PblBoardCampaignListAsTeacher = ModelBase & {
  title: string;
  start_date: string;
  end_date: string;
  submitted: boolean;
  description?: string;
  campaign_request?: {
    id?: string;
    status?: PblBoardCampaignRequestStatusJa;
  };
  company: PblBoardCampaignCompany;
};
export type GroupAffiliatePackageGroupOnlyList = ModelBase & {
  title: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  student_count: number;
  assign_teacher_affiliates?: AffiliateName[];
};
export type PostGroupAffiliatePackage = {
  title: string;
  description?: string;
  code?: string;
  add_assign_teacher_affiliate_ids?: string[];
  add_type?: number;
  assign_ids?: string[];
};
export type PutGroupAffiliatePackage = {
  title: string;
  description?: string;
  code?: string;
  sequence?: number;
  add_assign_teacher_affiliate_ids?: string[];
  remove_assign_teacher_affiliate_ids?: string[];
};
export type PackagingPackageableType =
  | "HomeworkTemplate"
  | "Material"
  | "Package"
  | "Pbl::Steam::Lecture"
  | "Pbl::Sponsor::Lecture"
  | "Homework"
  | "MaterialWork"
  | "Affiliate";
export type LimitedTimeNotificationStatus =
  | "\u516C\u958B\u524D"
  | "\u516C\u958B\u4E2D"
  | "\u63B2\u8F09\u7D42\u4E86";
export type LimitedTimeNotificationList = ModelBase & {
  title: string;
  message: string;
  status: LimitedTimeNotificationStatus;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  roles: RoleBase[];
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
};
export type SelectRoleByLimitedTimeNotification =
  | "owner"
  | "teacher"
  | "student";
export type LimitedTimeNotificationDetail = ModelBase & {
  title: string;
  message: string;
  roles: RoleBase[];
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
};
export type LimitedTimeNotificationNoticeBase = ModelBase & {
  title: string;
  message: string;
  checked: boolean;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
};
export type DailyLogBase = ModelBase & {
  date?: string;
  study_time?: number;
  category?: string;
  category_status_code?: string;
  memo?: string;
  created_at?: string;
  updated_at?: string;
  student?: AffiliateName;
};
export type DailyLogCategory =
  | "theme_settings"
  | "research"
  | "analysis"
  | "summary";
export type BoardTemplate = BoardTemplateList & {
  data: string | null;
  affiliate?: AffiliateName;
};
export type PostIdealBoardTemplateRequestBody = {
  name: string;
  size: IdealBoardSize;
  start_at?: string;
};
export type PutIdealBoardTemplateRequestBody = {
  name?: string;
  data?: string;
  start_at?: string;
};
export type AffiliateIdName = {
  id: string;
  first_name: string;
  last_name: string;
};
export type AffiliateAvatar = AffiliateIdName & {
  avatar?: Avatar;
};
export type BoardList = BoardBase & {
  invited_affiliates: AffiliateAvatar[];
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
};
export type GroupAffiliatePackageBaseAvatar =
  GroupAffiliatePackageMinimalBase & {
    affiliates: AffiliateAvatar[];
  };
export type Board = BoardBase & {
  data: string | null;
  host_affiliate?: AffiliateName;
  host_role?: RoleBase;
  invited_affiliates: AffiliateAvatar[];
  allowed_group_affiliates: GroupAffiliatePackageBaseAvatar[];
};
export type IdealBoardEvent = "start" | "complete" | "restart";
export type AllowedGroupAffiliateCategorizableType = "Article" | "Ideal::Board";
export type TtCsvExportStatus = "running" | "completed" | "failed";
export type TtCsvExportTypeJa =
  | "\u4F01\u696D\u30D7\u30ED\u30B8\u30A7\u30AF\u30C8\u60C5\u5831"
  | "\u5B66\u6821\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u5B66\u6821\u30BF\u30B0\u306E\u5B66\u6821\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u6559\u6750\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u4E00\u89A7\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u30EC\u30B3\u30FC\u30C9\u60C5\u5831"
  | "\u9650\u5B9A\u30BF\u30B0\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u5927\u5B66\u6559\u54E1\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u6388\u696D\u8A08\u753B"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831";
export type TtCsvExportBase = ModelBase & {
  status: TtCsvExportStatus;
  type_ja: TtCsvExportTypeJa;
  created_at: string;
  completed_at: string | null;
  file: {
    name?: string | null;
    url?: string | null;
  };
};
export type TtCsvExportType =
  | "admin_sponsor_info_list"
  | "super_owner_company_list"
  | "super_owner_owner_list"
  | "super_owner_teacher_list"
  | "super_owner_student_list"
  | "super_owner_tag_list"
  | "super_owner_company_tag_list"
  | "super_owner_nationwide_user_tag_and_student_list"
  | "super_owner_limited_user_tag_and_student_list"
  | "super_owner_material_distribution_history_list"
  | "super_owner_learning_package_distribution_history_list"
  | "super_owner_learning_package_distribution_history_detail"
  | "super_owner_homework_template_distribution_history_list"
  | "super_owner_homework_template_distribution_history_detail"
  | "super_owner_custom_list"
  | "super_owner_user_tag_distribution_history_list"
  | "owner_owner_list"
  | "owner_teacher_list"
  | "owner_student_list"
  | "owner_user_tag_list"
  | "owner_nationwide_user_tag_and_student_list"
  | "owner_limited_user_tag_and_student_list"
  | "owner_university_teacher_list"
  | "owner_homework_comment_list"
  | "owner_homework_template_custom_field_list"
  | "teacher_homework_template_custom_field_list"
  | "teacher_student_list"
  | "teacher_pbl_plan_schedule_list"
  | "teacher_homework_comment_list"
  | "reviewer_homework_template_custom_field_list"
  | "reviewer_homework_comment_list";
export type CreateTtCategoryRequestBody = {
  name: string;
  type: TtCategoryBaseType;
};
export type UpdateTtCategoryRequestBody = {
  name: string;
};
export type FeedbackTemplateCommentBase = ModelBase & {
  body: string;
};
export type FeedbackTemplateKind =
  | "anyone"
  | "draft"
  | "teacher_only"
  | "reviewer_only";
export type FeedbackTemplateHomeworkBase = ModelBase & {
  icon: {
    title: string;
    url: string;
  };
  feedback_template_comments: FeedbackTemplateCommentBase[];
  kind: FeedbackTemplateKind;
  tags?: HasIdAndName[] | null;
};
export type FeedbackTemplateSearchType = "tag";
export type DistributionStatusStatusJa =
  | "\u5F85\u6A5F\u4E2D"
  | "\u6E96\u5099\u4E2D"
  | "\u5931\u6557"
  | "\u914D\u4FE1\u524D"
  | "\u914D\u4FE1\u4E2D"
  | "\u5931\u6557"
  | "\u914D\u4FE1\u5B8C\u4E86";
export type DistributionStatusCategorizable = {
  categorizable_id: string;
  categorizable_type: string;
  categorizable_title: string;
};
export type AffiliateStudentName = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  nickname?: string | null;
  grades: {
    grade?: string | null;
  }[];
  company: HasIdAndName;
};
export type DistributionStatusList = ModelBase & {
  start_time?: string;
  end_time?: string;
  status: DistributionStatusStatusJa;
  sender_affiliate: AffiliateName;
  categorizable: DistributionStatusCategorizable;
  companies?: HasIdAndName[];
  distributors?: (AffiliateStudentName | GroupAffiliatePackageMinimalBase)[];
};
export type ProfessorAffiliateListBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    position?: string | null;
    department?: string | null;
    description?: string | null;
  };
};
export type ArticleInterviewRequestBase = ModelBase & {
  title: string;
  assign_user_names: string | null;
  company: {
    id: string;
    name: string;
  };
};
export type InterviewRequestListBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  confirm_date: string | null;
  has_preferred_dates: boolean;
  has_feedback_dates: boolean;
  professor_affiliate: ProfessorAffiliateListBase;
  article: ArticleInterviewRequestBase;
};
export type InterviewRequestSearchType =
  | "company_name"
  | "professor_name"
  | "article_title"
  | "student_name";
export type PreferredDateBase = ModelBase & {
  choice_number: number;
  date: string;
};
export type InterviewRequestDetailBase = InterviewRequestListBase & {
  student_message: string | null;
  professor_message: string | null;
  is_online: boolean;
  place: string | null;
  preferred_dates?: PreferredDateBase[];
  unread_message_ids?: string[];
};
export type InterviewRequestEvent = "accept" | "reject" | "complete";
export type InterviewRequestUnreadCountBase = ModelBase & {
  count: number;
};
export type InterviewMessageKind = "person" | "info";
export type InterviewRequestListBase2 = ModelBase & {
  message: string;
  kind: InterviewMessageKind;
  created_at: string;
  updated_at: string;
  sender_affiliate: AffiliateInfoBase;
  attached_images?: AttachedImageBase[];
};
export type InterviewFeedbackBase = ModelBase & {
  created_at: string;
  updated_at: string;
  student_affiliate: AffiliateInfoBase;
  is_request_send_material?: boolean;
  description?: string;
  degree_of_interest?: number;
  degree_of_learning?: number;
};
export type AffiliateInvitation = ModelBase & {
  expires_at: string | null;
  is_expired: boolean;
  role: string;
  token: string;
  grade: string;
  company: string;
  student_input_settings: StudentInputSetting;
  user_tags: UserTag[];
  signup_count: number;
  description: string;
};
export type CustomValueRequestBodyAsOwnerAndTeacherAndReviewer = {
  value?: string;
  group_affiliate_package_id?: string;
  homework_id: string;
  custom_master_id?: string;
  wysiwyg_body?: string;
};
export type RequestType = "school_visit" | "fieldwork" | "online_lesson";
export type Schema3 = "idle" | "adjusted" | "decided" | "cancelled";
export type GogoRequestPreferredDateBase = ModelBase & {
  choice_number: number;
  start_time: string;
  end_time: string;
};
export type GogoRequestListBase = ModelBase & {
  request_type:
    | "\u51FA\u5F35\u6388\u696D"
    | "\u30D5\u30A3\u30FC\u30EB\u30C9\u30EF\u30FC\u30AF"
    | "\u30AA\u30F3\u30E9\u30A4\u30F3\u6388\u696D";
  request_type_code: RequestType;
  status:
    | "\u8ABF\u6574\u5F85\u3061"
    | "\u8ABF\u6574\u3059\u308B"
    | "\u78BA\u5B9A"
    | "\u5B9F\u65BD\u306A\u3057";
  status_code: Schema3;
  confirm_start_time: string | null;
  confirm_end_time: string | null;
  preferred_dates: GogoRequestPreferredDateBase[];
  sender_affiliate: AffiliateName;
  company: HasIdAndName;
  theme: string | null;
};
export type GogoRequestParking = "parking_available" | "parking_unavailable";
export type GogoRequestProjector =
  | "projector_available"
  | "projector_unavailable";
export type GogoRequestInternetConnection =
  | "internet_connection_available"
  | "internet_connection_unavailable";
export type GogoRequestPhotography =
  | "photography_available"
  | "photography_unavailable";
export type GogoRequestAtmosphere =
  | "quiet"
  | "tension"
  | "exciting"
  | "harmonious"
  | "freewheeling";
export type GogoRequestHope = "invitation" | "participation" | "other";
export type GogoRequestLearningEnvironment = "local" | "remote" | "either";
export type PostGogoRequestRequestBody = {
  company_id: string;
  request_type: RequestType;
  sender_message?: string;
  preferred_dates: {
    start_time?: string;
    end_time?: string;
  }[];
  theme: string;
  number_of_classes?: number;
  number_of_students?: number;
  parking?: GogoRequestParking;
  projector?: GogoRequestProjector;
  internet_connection?: GogoRequestInternetConnection;
  photography?: GogoRequestPhotography;
  atmosphere?: GogoRequestAtmosphere;
  hope?: GogoRequestHope;
  learning_environment?: GogoRequestLearningEnvironment;
  teacher_affiliate_id?: string;
  grade?: StudentGrade;
};
export type GogoRequestAtmosphereJa =
  | "\u5927\u4EBA\u3057\u3044"
  | "\u7DCA\u5F35\u611F\u304C\u3042\u308B"
  | "\u76DB\u308A\u4E0A\u304C\u3063\u3066\u3044\u308B"
  | "\u548C\u6C17\u3042\u3044\u3042\u3044\u3068\u3057\u3066\u3044\u308B"
  | "\u81EA\u7531\u5954\u653E\u3067\u3042\u308B";
export type GogoRequestHopeJa =
  | "\u8B1B\u6F14\u4F9D\u983C"
  | "\u7DCA\u5F35\u611F\u304C\u767A\u8868\u4F1A\u3078\u306E\u53C2\u52A0\u3001\u30B3\u30E1\u30F3\u30C8\u3042\u308B"
  | "\u305D\u306E\u4ED6";
export type GogoRequestLearningEnvironmentJa =
  | "\u73FE\u5730\u306E\u307F"
  | "\u30EA\u30E2\u30FC\u30C8\u306E\u307F"
  | "\u3069\u3061\u3089\u3067\u3082OK";
export type GogoRequestDetailBase = GogoRequestListBase & {
  sender_message: string | null;
  company_message: string | null;
  unread_message_ids: string[];
  number_of_classes: number;
  number_of_students: number;
  parking: GogoRequestParking;
  projector: GogoRequestProjector;
  internet_connection: GogoRequestInternetConnection;
  photography: GogoRequestPhotography;
  atmosphere: GogoRequestAtmosphereJa;
  atmosphere_code: GogoRequestAtmosphere;
  hope: GogoRequestHopeJa;
  hope_code: GogoRequestHope;
  learning_environment: GogoRequestLearningEnvironmentJa;
  learning_environment_code: GogoRequestLearningEnvironment;
  grade: StudentGrade;
  teacher_affiliate?: AffiliateName;
};
export type PutGogoRequestRequestBody = {
  request_type: "school_visit" | "fieldwork" | "online_lesson";
  sender_message?: string;
};
export type GogoRequestUnreadCountBase = ModelBase & {
  count: number;
};
export type GogoMessageKind = "person" | "info";
export type GogoRequestListBase2 = ModelBase & {
  message: string;
  kind: GogoMessageKind;
  created_at: string;
  updated_at: string;
  sender_affiliate: AffiliateInfoBase;
  attached_images?: AttachedImageBase[];
};
export type GogoMessageCategorizableType =
  | "Gogo::Request"
  | "Gogo::ProgramRequest";
export type MessageRequiredRequestBody = {
  message: string;
};
export type AffiliateMessageBase = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  role_name: string;
  nickname: string | null;
  avatar: Avatar;
};
export type ChatRoomMessageListBase = ModelBase & {
  message: string;
  message_wysiwyg_body?: string | null;
  created_at: string;
  sender_affiliate: AffiliateMessageBase;
};
export type ChatRoomMessageDetail = ChatRoomMessageListBase & {
  bookmark_wysiwyg_body?: string | null;
};
export type Schema4 = "name";
export type CommonTaggingCategorizableType =
  | "Article"
  | "Pbl::Cards::InformationLiteracy::Bookmark"
  | "Homework"
  | "HomeworkTemplate";
export type CommonTagLinkingAllowType =
  | "bookmark"
  | "article"
  | "homework"
  | "homework_template";
export type CommonTagLinkingRequestBody = {
  name: string;
  allow_types?: CommonTagLinkingAllowType[];
};
export type NameRequiredRequestBody = {
  name: string;
};
export type CommonTagLinkingBase = ModelBase & {
  allow_type: CommonTagLinkingAllowType;
};
export type CommonTagManagementTagBase = ModelBase & {
  name: string;
  common_tag_linkings?: CommonTagLinkingBase[] | null;
};
export type PostCommonTagKind = "free" | "management";
export type CommonTaggingRequestBody = {
  tag_name: string;
  kind: PostCommonTagKind;
};
export type CommonTagLinkingRequestBody2 = {
  allow_type: CommonTagLinkingAllowType;
};
export type Status2 = "\u975E\u516C\u958B" | "\u516C\u958B\u200B";
export type StatusCode = "draft" | "published";
export type Items = ModelBase & {
  title: string;
  status: Status2;
  status_code: StatusCode;
  created_at: string | null;
  creator_affiliate: AffiliateName;
  company: HasIdAndName;
};
export type GogoProgramRequestBody = {
  title: string;
  event?: "close" | "release";
  number_of_classes?: number;
  number_of_students?: number;
  parking?: GogoRequestParking;
  projector?: GogoRequestProjector;
  internet_connection?: GogoRequestInternetConnection;
  photography?: GogoRequestPhotography;
  atmosphere?: GogoRequestAtmosphere;
  teacher_affiliate_id?: string;
  grade?: StudentGrade;
};
export type Items2 = ModelBase & {
  status: "\u8ABF\u6574\u4E2D" | "\u8ABF\u6574\u6E08\u307F";
  status_code: "adjusting" | "adjusted";
  created_at: string | null;
  company: HasIdAndName;
};
export type RequestType2 =
  | "\u51FA\u5F35\u6388\u696D"
  | "\u30D5\u30A3\u30FC\u30EB\u30C9\u30EF\u30FC\u30AF"
  | "\u30AA\u30F3\u30E9\u30A4\u30F3\u6388\u696D";
export type GogoProgramPreferredDateBase = ModelBase & {
  title: string;
  status: "\u975E\u516C\u958B" | "\u516C\u958B\u200B";
  status_code: "draft" | "published";
  start_time: string;
  end_time: string;
  created_at: string;
  request_type: RequestType2;
  request_type_code: RequestType;
  hope: GogoRequestHopeJa;
  hope_code: GogoRequestHope;
};
export type GogoProgramPreferredDateEvent = "withdraw" | "release";
export type GogoProgramPreferredDateRequestBody = {
  title: string;
  start_time: string;
  end_time: string;
  request_type?: RequestType;
  hope?: GogoRequestHope;
  event?: GogoProgramPreferredDateEvent;
};
export type GogoCompanyInformationVisitingLesson =
  | "visiting_lesson_available"
  | "visiting_lesson_negotiable"
  | "visiting_lesson_not_available";
export type GogoCompanyInformationOnlineLesson =
  | "online_lesson_available"
  | "online_lesson_negotiable"
  | "online_lesson_not_available";
export type GogoCompanyInformationFieldwork =
  | "fieldwork_available"
  | "fieldwork_negotiable"
  | "fieldwork_not_available";
export type GogoCompanyInformationBase = ModelBase & {
  visiting_lesson: GogoCompanyInformationVisitingLesson;
  online_lesson: GogoCompanyInformationOnlineLesson;
  fieldwork: GogoCompanyInformationFieldwork;
  available_capacity: number;
  company: CompanyBase & {
    projects_count?: number | null;
    project_works_count?: number | null;
  };
};
export type GogoSchoolInformationRequestBody = {
  parking?: "parking_unavailable" | "parking_available";
  projector?: "projector_unavailable" | "projector_available";
  internet_connection?:
    | "internet_connection_unavailable"
    | "internet_connection_available";
  photography?: "photography_unavailable" | "photography_available";
};
export type Parking = "parking_unavailable" | "parking_available";
export type Projector = "projector_unavailable" | "projector_available";
export type InternetConnection =
  | "internet_connection_unavailable"
  | "internet_connection_available";
export type Photography = "photography_unavailable" | "photography_available";
export const {
  useGetTeacherUsersStudentsQuery,
  usePostTeacherStudentsMutation,
  useStudentsBatchCreateUsersAsTeacherMutation,
  useGetTeacherUsersStudentsListGradeQuery,
  useCreateAccountUserAsTeacherMutation,
  useCreateAccountAvailableAsTeacherMutation,
  useGetTeacherUsersStudentAffiliatesQuery,
  useGetStudentsUserTagsAsTeacherQuery,
  useGetStudentsGroupAffiliatesAsTeacherQuery,
  useGetTeacherUsersStudentsByIdQuery,
  usePutTeacherUsersStudentsByIdMutation,
  useDeleteTeacherStudentsUserIdMutation,
  useGetTeachersAsTeacherQuery,
  useDeleteTeacherTeachersUserIdMutation,
  usePutTeachersProgressUpdateAsTeacherMutation,
  useGetTeacherUniversitiesAsTeacherQuery,
  useGetTeacherUsersByUserIdQuery,
  usePutTeacherUsersByUserIdMutation,
  useDeleteTeacherUsersByUserIdMutation,
  useResetPasswordAsTeacherMutation,
  useAccountUnlockAsTeacherMutation,
  useGetTeacherMaterialsQuery,
  useGetTeacherMaterialsByMaterialIdQuery,
  useGetTeacherMaterialWorksQuery,
  usePostTeacherMaterialWorksMutation,
  useGetTeacherMaterialWorksByMaterialWorkIdQuery,
  usePutTeacherMaterialWorksByMaterialWorkIdMutation,
  useGetMaterialTagsAsTeacherQuery,
  useFetchStudentMaterialWorksAsTeacherQuery,
  useFetchOneStudentMaterialWorkAsTeacherQuery,
  useGetTeacherReportsQuery,
  usePostTeacherReportsMutation,
  useGetTeacherReportsByReportIdQuery,
  usePutTeacherReportsByReportIdMutation,
  useDeleteTeacherReportsByReportIdMutation,
  useGetTeacherPaymentsQuery,
  usePostTeacherPaymentsMutation,
  useGetTeacherPaymentsByPaymentIdQuery,
  usePutTeacherPaymentsByPaymentIdMutation,
  useDeleteTeacherPaymentsByPaymentIdMutation,
  useGetTeacherHomeworksQuery,
  useGetTeacherHomeworksByHomeworkIdQuery,
  usePutTeacherHomeworksByHomeworkIdMutation,
  useDeleteTeacherHomeworksByHomeworkIdMutation,
  useGetHomeworkImageDownloadUrlsAsTeacherQuery,
  useBatchCreateHomeworksAsTeacherMutation,
  useBatchCreateGroupHomeworksAsTeacherMutation,
  useBatchUpdateHomeworksAsTeacherMutation,
  useSubmitHomeworkAnswerFilesAsTeacherMutation,
  useFetchSubmitHomeworkFilesRequestsAsTeacherQuery,
  useCreateSubmitHomeworkFilesRequestAsTeacherMutation,
  useBatchDeleteHomeworksAsTeacherMutation,
  useBatchUpdateTeacherCommentsHomeworksAsTeacherMutation,
  useUploadHomeworkImagesAsTeacherMutation,
  useSaveEditedHomeworkImageAsTeacherMutation,
  useUpdateHomeworkImageAsTeacherMutation,
  useDeleteHomeworkImageAsTeacherMutation,
  usePostHomeworkTemplateCustomFieldFeedbackAsTeacherMutation,
  usePutHomeworkTemplateCustomFieldFeedbacksAsTeacherMutation,
  useDeleteHomeworkTemplateCustomFieldFeedbacksAsTeacherMutation,
  usePutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceMutation,
  useGetTeacherCompaniesByCompanyIdQuery,
  usePutTeacherCompaniesCompanyIdMutation,
  useGetCompanyLimitOptionAsTeacherQuery,
  useGetReviewerCompanyGroupControllersAsTeacherQuery,
  useGetTeacherCategoriesQuery,
  useGetTeacherNotificationsQuery,
  useCreateNotificationAsTeacherMutation,
  useGetOwnerNotificationIdQuery,
  usePutTeacherNotificationIdMutation,
  useDeleteTeacherNotificationIdMutation,
  useGetTeacherArticlesQuery,
  useFetchArticleStatsAsTeacherQuery,
  useFetchArticleWordCloudAsTeacherQuery,
  useGetArticleSubmitAsTeacherQuery,
  useGetTeacherArticlesByArticleIdQuery,
  usePutTeacherArticlesByArticleIdMutation,
  useGetTeacherArticlesByArticleIdCommentsQuery,
  usePostTeacherArticlesByArticleIdCommentsMutation,
  useGetTeacherArticlesByArticleIdFeedbacksQuery,
  usePostTeacherArticlesByArticleIdFeedbacksMutation,
  useDeleteTeacherArticlesByArticleIdFeedbacksAndFeedbackIdMutation,
  usePutArticleImageAsTeacherMutation,
  useGetTeacherArticleCommentsStudentCommentsListQuery,
  useDeleteTeacherArticlesByArticleIdArticlesCompaniesAndIdMutation,
  usePostTeacherUserTaggingsMutation,
  useDeleteTeacherUserTaggingsMutation,
  useTeacherUserTaggingBatchCreateMutation,
  useGetTeacherUserTagTypesQuery,
  usePostTeacherUserTagTypesMutation,
  usePutTeacherUserTagTypesByIdMutation,
  useDeleteTeacherUserTagTypesByIdMutation,
  useGetTeacherUserTagsByUserTagIdUsersQuery,
  useGetTeacherUserTagsByIdDistributedStudentsQuery,
  usePutTeacherUserTagsSequenceMutation,
  useTeacherUserTagsBatchCreateMutation,
  useGetTeacherHomeworkTemplatesQuery,
  useCreateHomeworkTemplateAsTeacherMutation,
  useFetchHomeworkTemplateStatsAsTeacherQuery,
  useGetHomeworkTemplateDetailListAsTeacherQuery,
  useGetTeacherHomeworkTemplatesByHomeworkTemplateIdQuery,
  useUpdateHomeworkTemplateAsTeacherMutation,
  useDeleteHomeworkTemplateAsTeacherMutation,
  useGetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsQuery,
  usePostTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsMutation,
  useGetTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdQuery,
  usePutTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdMutation,
  useDeleteTeacherHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdMutation,
  usePostHomeworkTemplateDuplicateAsTeacherMutation,
  useGetHomeworkTemplateAnswersAsTeacherQuery,
  useUpdateHomeworkTemplateDistributionAsTeacherMutation,
  useAssignManagerToHomeworkTemplateDistributionAsTeacherMutation,
  useSubmitHomeworkTemplateDistributionToReviewerAsTeacherMutation,
  useRevertHomeworkTemplateDistributionSubmittedToReviewerAsTeacherMutation,
  useReturnHomeworkTemplateDistributionToStudentsAsTeacherMutation,
  useFetchHomeworkTemplateChunksAsTeacherQuery,
  useCreateHomeworkTemplateChunkAsTeacherMutation,
  useDeleteHomeworkTemplateChunkAsTeacherMutation,
  usePostHomeworkTemplateImageAsTeacherMutation,
  usePutHomeworkTemplateImageAsTeacherMutation,
  useDeleteHomeworkTemplateImageAsTeacherMutation,
  useGetTeacherUserTagsQuery,
  usePostTeacherUserTagsMutation,
  useGetTeacherUserTagsByIdQuery,
  usePutTeacherUserTagsByIdMutation,
  useDeleteTeacherUserTagsByIdMutation,
  useGetTeacherPblReportTemplatesQuery,
  usePostTeacherPblReportTemplatesMutation,
  useFetchPblReportTemplateStatsAsTeacherQuery,
  useFetchPblReportTemplateAsTeacherQuery,
  usePutTeacherPblReportTemplatesByIdMutation,
  useGetTeacherPblReportTemplatesByIdAnswersQuery,
  useGetTeacherPblReportTemplatesByIdItemsQuery,
  useGetTeacherPblReportTemplatesFrameworksQuery,
  useGetTeacherPblReportAnswersByIdQuery,
  useGetTeacherPblReportAnswersQuery,
  usePostTeacherPblReportAnswersMutation,
  useGetTeacherPblReportCommentsQuery,
  usePostTeacherPblReportCommentsMutation,
  usePutTeacherPblReportCommentsByIdMutation,
  useGetTeacherPblReportItemsQuery,
  usePostTeacherPblReportItemsMutation,
  usePutTeacherPblReportItemsByIdMutation,
  useDeleteTeacherPblReportItemsByIdMutation,
  useFetchPblCardBookmarksAsTeacherQuery,
  usePostPblCardBookmarkAsTeacherMutation,
  useFetchPblCardBookmarkAsTeacherQuery,
  usePutPblCardBookmarkAsTeacherMutation,
  useDeletePblCardBookmarkAsTeacherMutation,
  usePutPblCardBookmarkWysiwygBodyAsTeacherMutation,
  useFetchPblCardBookmarkStatsAsTeacherQuery,
  useFetchPblCardBookmarkCommentsAsTeacherQuery,
  useCreatePblCardBookmarkCommentAsTeacherMutation,
  useUpdatePblCardBookmarkCommentAsTeacherMutation,
  useDeletePblCardBookmarkCommentAsTeacherMutation,
  useDeletePblCardBookmarkImageAsTeacherMutation,
  useGetPblCardBookmarkStampsAsTeacherQuery,
  usePostPblCardBookmarkStampAsTeacherMutation,
  usePutPblCardBookmarkStampAsTeacherMutation,
  useDeletePblCardBookmarkStampAsTeacherMutation,
  useGetTeacherMaterialDistributionHistoriesListQuery,
  useGetTeacherMaterialDistributionHistoriesListStudentsQuery,
  useGetTeacherMaterialDistributionHistoriesSearchByUserIdQuery,
  useGetSteamContentsAsTeacherQuery,
  useGetSteamContentAsTeacherQuery,
  useSearchTeacherSteamContentsQuery,
  useGetTeacherSteamCommentsQuery,
  usePostTeacherSteamCommentsMutation,
  usePutTeacherSteamContentsIdMutation,
  useDeleteTeacherSteamContentsIdMutation,
  useGetSteamLecturesAsTeacherQuery,
  usePostTeacherSteamLecturesMutation,
  useGetTeacherSteamLecturesIdQuery,
  useGetTeacherSteamLectureCommentsQuery,
  useGetSteamContentBookmarksAsTeacherQuery,
  usePostSteamContentBookmarksAsTeacherMutation,
  useDeleteSteamContentBookmarksAsTeacherMutation,
  useGetPblSteamStudentContentsAsTeacherQuery,
  usePostPblSteamStudentContentAsTeacherMutation,
  usePutPblSteamStudentContentAsTeacherMutation,
  useDeletePblSteamStudentContentAsTeacherMutation,
  useGetSteamContentRecommendationsAsTeacherQuery,
  usePostSteamContentRecommendationAsTeacherMutation,
  useDeleteSteamContentRecommendationAsTeacherMutation,
  useGetTeacherPlanItemsQuery,
  usePostTeacherPlanItemsMutation,
  useGetTeacherPlanItemsIdQuery,
  usePutTeacherPlanItemsIdMutation,
  useDeleteTeacherPlanItemsIdMutation,
  useGetTeacherGetClassDateListQuery,
  useGetTeacherPlanClassesQuery,
  usePostTeacherPlanClassesMutation,
  usePutTeacherPlanClassesIdMutation,
  useDeleteTeacherPlanClassesIdMutation,
  useGetPblPlanContentsAsTeacherQuery,
  usePostPblPlanContentsAsTeacherMutation,
  useDeletePblPlanContentsAsTeacherMutation,
  usePostTeacherPlanDaysMutation,
  usePutTeacherPlanDaysIdMutation,
  useDeleteTeacherPlanDaysIdMutation,
  useGetTeacherPlanStudentsQuery,
  usePostTeacherPlanStudentsMutation,
  usePutTeacherPlanStudentsIdMutation,
  useDeleteTeacherPlanStudentsIdMutation,
  useGetTeacherInquiriesQuery,
  useGetTeacherInquiriesByIdQuery,
  useGetTheNumberOfNewCommentInquiriesAsTeacherQuery,
  usePostTeacherInquiryCommentsMutation,
  usePutTeacherInquiryCommentsByIdMutation,
  useDeleteTeacherInquiryCommentsByIdMutation,
  useGetTeacherSponsorInfosQuery,
  useGetTeacherSponsorInfosIdQuery,
  useFetchSponsorProjectsAsTeacherQuery,
  useFetchOneSponsorProjectAsTeacherQuery,
  useFetchSponsorProjectsForCarouselAsTeacherQuery,
  useFetchSponsorProjectListAsTeacherQuery,
  useFetchSponsorLecturesAsTeacherQuery,
  useFetchSponsorLectureAsTeacherQuery,
  useFetchSponsorCommentListAsTeacherQuery,
  useFetchSponsorProjectBookmarksAsTeacherQuery,
  useAddSponsorProjectBookmarkAsTeacherMutation,
  useFetchSponsorProjectBookmarkByProjectAsTeacherQuery,
  useRemoveSponsorProjectBookmarkAsTeacherMutation,
  useFetchSponsorProjectRecommendsAsTeacherQuery,
  useAddSponsorProjectRecommendAsTeacherMutation,
  useRemoveSponsorProjectRecommendAsTeacherMutation,
  useFetchSponsorCommentsAsTeacherQuery,
  usePostSponsorCommentsAsTeacherMutation,
  useDeleteTeacherSponsorCommentsMutation,
  usePutPblSponsorCommentAsTeacherMutation,
  useDeletePblSponsorCommentAsTeacherMutation,
  useGetTeacherContentCompaniesQuery,
  useGetSchedulesAsTeacherQuery,
  useGetStudentLogsStudyLogsAsTeacherQuery,
  useGetStudentLogsStudyByStudentAsTeacherQuery,
  useGetStudentLogsStatsAsTeacherQuery,
  useGetStudentLogsActivityAsTeacherQuery,
  useGetStudentLogsDeliverableListAsTeacherQuery,
  useGetStudentLogsMaterialWorksAsTeacherQuery,
  useGetStudentLogsHomeworksAsTeacherQuery,
  useGetStudentLogsPblAnswersAsTeacherQuery,
  useGetStudentLogsLearningPackagesAsTeacherQuery,
  useGetStudentLogsArticlesAsTeacherQuery,
  useGetStudentLogsIdealsAsTeacherQuery,
  useGetStudentLogsBookmarksAsTeacherQuery,
  useGetRubricsAsTeacherQuery,
  usePostRubricsAsTeacherMutation,
  useGetRubricAsTeacherQuery,
  usePutRubricAsTeacherMutation,
  useDeleteRubricAsTeacherMutation,
  usePostRubricItemsAsTeacherMutation,
  useGetRubricItemAsTeacherQuery,
  usePutRubricItemAsTeacherMutation,
  useDeleteRubricItemAsTeacherMutation,
  useGetRubricEvaluatesAsTeacherQuery,
  usePostRubricEvaluateAsTeacherMutation,
  useGetRubricEvaluateAsTeacherQuery,
  usePutRubricEvaluateAsTeacherMutation,
  useDeleteRubricEvaluateAsTeacherMutation,
  usePutRubricEvaluateSequenceAsTeacherMutation,
  usePostRubricEvaluateStudentAsTeacherMutation,
  useGetRubricEvaluateStudentAsTeacherQuery,
  usePutRubricEvaluateStudentAsTeacherMutation,
  useDeleteRubricEvaluateStudentAsTeacherMutation,
  usePutRubricEvaluateStudentSequenceAsTeacherMutation,
  usePostRubricEvaluateStudentRateAsTeacherMutation,
  usePutRubricEvaluateStudentRateAsTeacherMutation,
  useDeleteRubricEvaluateStudentRateAsTeacherMutation,
  useGetLearningTemplatePackageDistributionsAsTeacherQuery,
  useGetLearningTemplatePackageDistributionAsTeacherQuery,
  useGetLearningPackageAsTeacherQuery,
  useGetLearningTemplatePackageDistributionHistoriesAsTeacherQuery,
  useGetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsTeacherQuery,
  useGetLearningTemplatePackageDistributionHistoriesPackagingsAsTeacherQuery,
  useGetPblBoardCampaignsAsTeacherQuery,
  usePostPblBoardCampaignRequestAsTeacherMutation,
  useDeletePblBoardCampaignRequestsAsTeacherMutation,
  useGetGroupAffiliatePackagesAsTeacherQuery,
  usePostGroupAffiliatePackageAsTeacherMutation,
  useGetGroupAffiliatePackageAsTeacherQuery,
  usePutGroupAffiliatePackageAsTeacherMutation,
  useDeleteGroupAffiliatePackageAsTeacherMutation,
  usePostPackagingAsTeacherMutation,
  usePutPackagingAsTeacherMutation,
  useDeletePackagingAsTeacherMutation,
  useBatchCreatePackagingAsTeacherMutation,
  useBatchUpdatePackagingAsTeacherMutation,
  useBatchDeletePackagingAsTeacherMutation,
  usePostAttachedImageAsTeacherMutation,
  useGetAttachedImageAsTeacherQuery,
  usePutAttachedImageAsTeacherMutation,
  useDeleteAttachedImageAsTeacherMutation,
  useGetLimitedTimeNotificationsAsTeacherQuery,
  usePostLimitedTimeNotificationAsTeacherMutation,
  useGetLimitedTimeNotificationAsTeacherQuery,
  usePutLimitedTimeNotificationAsTeacherMutation,
  useDeleteLimitedTimeNotificationAsTeacherMutation,
  useGetLimitedTimeNotificationNoticeAsTeacherQuery,
  useGetLimitedTimeNotificationNoticeDetailsAsTeacherQuery,
  usePostLimitedTimeNotificationNoticeCheckAsTeacherMutation,
  useGetDailyLogsAsTeacherQuery,
  useGetIdealBoardTemplatesAsTeacherQuery,
  usePostIdealBoardTemplatesAsTeacherMutation,
  useGetIdealBoardTemplateAsTeacherQuery,
  usePutIdealBoardTemplateAsTeacherMutation,
  useDeleteIdealBoardTemplateAsTeacherMutation,
  useGetIdealBoardsAsTeacherQuery,
  usePostIdealBoardsAsTeacherMutation,
  useGetIdealBoardAsTeacherQuery,
  usePutIdealBoardAsTeacherMutation,
  useDeleteIdealBoardAsTeacherMutation,
  usePostIdealBoardsAffiliatesAsTeacherMutation,
  useDeleteIdealBoardsAffiliatesAsTeacherMutation,
  usePatchAllowedGroupAffiliatesBatchUpdateAsTeacherMutation,
  useGetTtCsvExportsAsTeacherQuery,
  usePostTtCsvExportAsTeacherMutation,
  usePostTtCategoryBaseAsTeacherMutation,
  usePutTtCategoryBaseAsTeacherMutation,
  useGetFeedbackTemplateHomeworksAsTeacherQuery,
  useGetFeedbackTemplateTagsAsTeacherQuery,
  usePostCommentImageAsTeacherMutation,
  useGetCommentImageAsTeacherQuery,
  usePutCommentImageAsTeacherMutation,
  useDeleteCommentImageAsTeacherMutation,
  useGetDistributionStatusesAsTeacherQuery,
  useGetInterviewRequestsAsTeacherQuery,
  usePostInterviewRequestAsTeacherMutation,
  useGetInterviewRequestAsTeacherQuery,
  usePutInterviewRequestAsTeacherMutation,
  useGetInterviewRequestArticleAsTeacherQuery,
  useGetInterviewRequestUnreadCountsAsTeacherQuery,
  useGetInterviewMessagesAsTeacherQuery,
  usePostInterviewMessageAsTeacherMutation,
  useGetInterviewMessageReadAsTeacherMutation,
  usePutInterviewMessageAsTeacherMutation,
  useDeleteInterviewMessageAsTeacherMutation,
  useGetInterviewFeedbacksAsTeacherQuery,
  useFetchAffiliateInvitationsAsTeacherQuery,
  usePutHomeworkTemplateCustomValueAsTeacherMutation,
  useGetGogoLinkingsAsTeacherQuery,
  useGetGogoRequestsAsTeacherQuery,
  usePostGogoRequestAsTeacherMutation,
  useGetGogoRequestAsTeacherQuery,
  usePutGogoRequestAsTeacherMutation,
  useDeleteGogoRequestAsTeacherMutation,
  useGetGogoRequestUnreadCountsAsTeacherQuery,
  useGetGogoMessagesAsTeacherQuery,
  usePostGogoMessageAsTeacherMutation,
  usePutGogoMessageAsTeacherMutation,
  useDeleteGogoMessageAsTeacherMutation,
  useGetChatRoomAsTeacherQuery,
  usePostChatRoomAsTeacherMutation,
  useGetChatRoomMessagesAsTeacherQuery,
  usePostChatRoomMessageAsTeacherMutation,
  usePutChatRoomMessageAsTeacherMutation,
  useDeleteChatRoomMessageAsTeacherMutation,
  useGetGogoMessageReadAsTeacherMutation,
  useGetCommonTagsAsTeacherQuery,
  usePostCommonTagAsTeacherMutation,
  usePutCommonTagAsTeacherMutation,
  useDeleteCommonTagAsTeacherMutation,
  useGetCommonTagsManagementTagListAsTeacherQuery,
  usePostCommonTaggingAsTeacherMutation,
  useDeleteCommonTaggingAsTeacherMutation,
  usePostCommonTagLinkingAsTeacherMutation,
  useDeleteCommonTagLinkingAsTeacherMutation,
  useGetGogoProgramsAsTeacherQuery,
  usePostGogoProgramAsTeacherMutation,
  useGetGogoProgramAsTeacherQuery,
  usePutGogoProgramAsTeacherMutation,
  useDeleteGogoProgramAsTeacherMutation,
  useGetGogoProgramRequestsAsTeacherQuery,
  usePostGogoProgramRequestAsTeacherMutation,
  useGetGogoProgramRequestAsTeacherQuery,
  usePutGogoProgramRequestAsTeacherMutation,
  useDeleteGogoProgramRequestAsTeacherMutation,
  useGetGogoProgramRequestMessagesAsTeacherQuery,
  usePostGogoProgramRequestMessageAsTeacherMutation,
  usePutGogoProgramRequestMessageAsTeacherMutation,
  useDeleteGogoProgramRequestMessageAsTeacherMutation,
  useGetGogoProgramRequestMessageReadAsTeacherMutation,
  useGetGogoProgramPreferredDatesAsTeacherQuery,
  usePostGogoProgramPreferredDateAsTeacherMutation,
  usePutGogoProgramPreferredDateAsTeacherMutation,
  useDeleteGogoProgramPreferredDateAsTeacherMutation,
  useGetGogoCompanyInformationAsTeacherQuery,
  useGetGogoCompanyInformationListAsTeacherQuery,
  usePostGogoSchoolInformationAsTeacherMutation,
  useGetGogoSchoolInformationAsTeacherQuery,
  usePutGogoSchoolInformationAsTeacherMutation,
  useDeleteGogoSchoolInformationAsTeacherMutation,
} = injectedRtkApi;
