/* eslint-disable react/jsx-no-duplicate-props */
import MainButton from "@components/UI/atoms/MainButton/MainButton";
import handleDisableScroll from "@lib/scroll-utils";
import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, SxProps, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { SEARCH_MAX_LENGTH } from "@root/constants/searchBar";
import { useAppDispatch } from "@root/hooks";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = () => ({
  wrap: (theme: Theme) => ({
    gap: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  }),
  searchInput: (theme: Theme) => ({
    backgroundColor: theme.palette.common.white,
    maxWidth: 400,
  }),
});

export interface Props {
  placeholder: string;
  handleSubmit?: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  disabled?: boolean;
  id?: string;
  inputClassName?: SxProps<Theme>;
  outsideSearchButton?: boolean;
  isSearching?: boolean;
  isSearchDisabled?: boolean;
}

const SearchInput: React.FC<Props> = ({
  placeholder,
  handleSubmit,
  handleChange,
  value,
  disabled,
  id,
  inputClassName,
  outsideSearchButton,
  isSearching,
  isSearchDisabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return handleSubmit?.();
    }
    return false;
  };

  const handleSearch = () => {
    if (handleSubmit) {
      handleSubmit();
    }
    handleDisableScroll(dispatch);
  };

  return (
    <Box sx={classes.wrap}>
      <TextField
        size="small"
        variant="outlined"
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        value={value}
        id={id}
        InputProps={{
          sx: inputClassName || classes.searchInput,
          endAdornment:
            handleSubmit && !outsideSearchButton ? (
              <InputAdornment position="end">
                <IconButton
                  data-cy="common-search-input-submit"
                  aria-label="search user"
                  onClick={handleSearch}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }}
        inputProps={{
          "data-cy": "common-search-input",
          maxLength: SEARCH_MAX_LENGTH,
        }}
      />
      {outsideSearchButton && (
        <MainButton
          variant="contained"
          onClick={handleSearch}
          disabled={isSearchDisabled || isSearching}
          data-cy="search-button"
        >
          {isSearching ? <CircularProgress size="16px" /> : t("search")}
        </MainButton>
      )}
    </Box>
  );
};

export default SearchInput;
