/* eslint-disable camelcase */
import { SteamLibraryContentCard } from "@actions/types/steamLibrary";
import {
  Api,
  ContentRecommendationBase,
  GetSteamContentRecommendationsAsTeacherParams,
} from "@lib/Api";
import { TeacherAppSteamContentRecommendationState } from "@reducers/teacherApp/steam/contentRecommendations";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import { getSteamContentsDetailsAsTeacher } from "./content";

const api = new Api();

export const getContentsAndRecommendationsAsTeacher = createAsyncThunk<
  {
    steam_content_recommendations: ContentRecommendationBase[];
    total_count: number;
    recommendationContents: SteamLibraryContentCard[];
  },
  GetSteamContentRecommendationsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/content/fetchRecommendations",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await api.teacher.getSteamContentRecommendationsAsTeacher(params);

      const { steam_content_recommendations } = response.data;
      let recommendationContents: SteamLibraryContentCard[] = [];
      if (steam_content_recommendations.length !== 0) {
        const contentIds = steam_content_recommendations.map(
          (steamStudentContents) => steamStudentContents.content.sl_content_id,
        );
        await dispatch(getSteamContentsDetailsAsTeacher(contentIds))
          .unwrap()
          .then(
            (
              contents: TeacherAppSteamContentRecommendationState["recommendationContents"],
            ) => {
              recommendationContents = contents;
            },
          );
      }

      return { ...response.data, recommendationContents };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSteamContentRecommendationAsTeacher = createAsyncThunk<
  any,
  {
    pblSteamContentId: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/content/postRecommendation",
  async (params, { rejectWithValue, getState }) => {
    try {
      const { pblSteamContentId } = params;
      const response =
        await api.teacher.postSteamContentRecommendationAsTeacher({
          pbl_steam_content_id:
            pblSteamContentId || getState().teacherApp.steamLecture.content.id,
        });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSteamContentRecommendationAsTeacher = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/content/deleteRecommendation",
  async (contentId, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.deleteSteamContentRecommendationAsTeacher(contentId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetSteamContentRecommendationsState = createAction(
  "teacher/steamLibrary/content/resetRecommendationState",
);
