/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RoleApiFunctions } from "@root/types/category";
import { RootState } from "@store/store";

const api = new Api();

// eslint-disable-next-line import/prefer-default-export
export const fetchCategoriesAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/category/fetch", async (_: void, { rejectWithValue }) => {
  try {
    const response = await api.student.getStudentCategories();
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type StudentActionsType = {
  student: RoleApiFunctions;
};

export const studentCategoryActions: StudentActionsType = {
  student: {
    getCategories: fetchCategoriesAsStudent,
  },
};
