import {
  isErrorString,
  isErrorsWithKeyMessage,
  isMessageString,
} from "@lib/rtk/error-utils";

const defaultMessage = "エラーが発生しました。";

export const getErrorMessage = (
  errors:
    | string
    | string[]
    | {
        key: string;
        message: string;
      }[]
    | undefined,
): string => {
  if (!errors) {
    return defaultMessage;
  }
  if (typeof errors === "string") {
    return errors;
  }
  if (typeof errors === "object" && errors.length) {
    const errorStrings = errors.map(
      (error: string | { key: string; message: string }) => {
        if (typeof error === "string") {
          return error;
        }
        return error.message;
      },
    );
    return errorStrings[0];
  }
  return defaultMessage;
};

export const getErrorsInArray = (value: unknown): string[] => {
  let messages: string[] = [];
  if (typeof value === "string") {
    messages = [value];
  } else if (isMessageString(value)) {
    messages = [value.message];
  } else if (isErrorString(value)) {
    messages = [value.error];
  } else if (isErrorsWithKeyMessage(value)) {
    messages = value.errors.map((keyMessage) => keyMessage.message);
  } else {
    messages = ["エラーが発生しました。"];
  }
  return messages;
};
