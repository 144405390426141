import { fetchSponsorDeliverySchoolsAsContentProvider } from "@actions/contentProviderApp/school";
import { SponsorSchoolBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface ContentProviderAppSchoolState {
  fetching: boolean;
  fetched: boolean;
  serverError: any;
  deliverySchools: SponsorSchoolBase[];
  totalCount: number;
}

export const initialState: ContentProviderAppSchoolState = {
  fetching: false,
  fetched: false,
  serverError: null,
  deliverySchools: [],
  totalCount: 0,
};

export const contentProviderAppSchoolSlice = createSlice({
  name: "ContentProviderApp/School",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchSponsorDeliverySchoolsAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetched: false,
            fetching: true,
            serverError: null,
          };
        },
      )
      .addCase(
        fetchSponsorDeliverySchoolsAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetching: false,
            fetched: true,
            deliverySchools: payload.delivery_schools,
            totalCount: payload.total_count,
            serverError: "",
          };
        },
      )
      .addCase(
        fetchSponsorDeliverySchoolsAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            deliverySchools: [],
            serverError: action.error,
          };
        },
      )
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const contentProviderAppSchoolState = (
  state: RootState,
): ContentProviderAppSchoolState => state.contentProviderApp.school;

export default contentProviderAppSchoolSlice.reducer;
