import {
  fetchOnePblSponsorInfoAsStudent,
  fetchPblSponsorInfoAsStudent,
  resetOne,
} from "@actions/studentApp/pbl/sponsor/info";
import { SponsorInfoDetailsBase,SponsorInfoListBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface StudentAppPblSponsorInfoState {
  fetching: boolean;
  fetchingOne: boolean;
  fetched: boolean;
  fetchedOne: boolean;
  fetchErrors: string | null;
  fetchOneErrors: string | null;
  infoList: SponsorInfoListBase[];
  infoDetails: SponsorInfoDetailsBase | null;
  totalCount: number;
}

export const initialState: StudentAppPblSponsorInfoState = {
  fetching: false,
  fetchingOne: false,
  fetched: false,
  fetchedOne: false,
  fetchErrors: null,
  fetchOneErrors: null,
  infoList: [],
  infoDetails: null,
  totalCount: 0,
};

const studentAppPblSponsorInfoSlice = createSlice({
  name: "studentApp/pbl/sponsors/info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblSponsorInfoAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchPblSponsorInfoAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchPblSponsorInfoAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: null,
          infoList: payload.sponsors,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchOnePblSponsorInfoAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
        };
      })
      .addCase(fetchOnePblSponsorInfoAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.error.message as string,
        };
      })
      .addCase(fetchOnePblSponsorInfoAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          fetchOneError: null,
          infoDetails: payload,
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          infoDetails: null,
          infoList: [],
          fetched: false,
          fetchedOne: false,
          fetchError: null,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppPblSponsorInfoState = (
  state: RootState,
): StudentAppPblSponsorInfoState => state.studentApp.pblSponsorInfo;

export default studentAppPblSponsorInfoSlice.reducer;
