import { StudyLogsModalTypes } from "@components/Teacher/StudentLogs";
import {
  CommonStyledTableThCell,
  StyledTableContainer,
} from "@components/UI/organisms/Tables/TableComponents";
import { useAuth } from "@contexts/Auth";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DailyLogBase } from "@root/endpoints/TimeTactApi/teacherApi";
import React, { ReactNode } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import DailyStudyLogItem from "./DailyStudyLogItem";

type Props = {
  dailyStudyLogs?: DailyLogBase[];
  setModalState: React.Dispatch<React.SetStateAction<StudyLogsModalTypes>>;
  modalState: StudyLogsModalTypes;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
      outline: "none",
      boxShadow: "none",
      border: "none",
    },
    message: {
      padding: theme.spacing(2),
    },
  }),
);

const StudyLogTable: React.FC<Props> = ({
  dailyStudyLogs,
  setModalState,
  modalState,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("study_logs");
  const { isCurrentRoles } = useAuth();
  let bodyPart: ReactNode;

  if (dailyStudyLogs) {
    bodyPart = (
      <TableBody>
        {dailyStudyLogs && dailyStudyLogs.length > 0 ? (
          dailyStudyLogs.map((logs) => (
            <DailyStudyLogItem
              key={logs.id}
              dailyLogInfo={logs}
              setModalState={setModalState}
              modalState={modalState}
            />
          ))
        ) : (
          <TableRow>
            <Typography className={classes.message}>
              {t("noStudyLog")}
            </Typography>
          </TableRow>
        )}
      </TableBody>
    );
  }

  return (
    <StyledTableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <CommonStyledTableThCell>{t("studyDate")}</CommonStyledTableThCell>
            {isCurrentRoles.isTeacher && (
              <CommonStyledTableThCell align="left">
                {t("studentName")}
              </CommonStyledTableThCell>
            )}
            <CommonStyledTableThCell align="left">
              {t("studyCategory")}
            </CommonStyledTableThCell>
            <CommonStyledTableThCell align="left">
              {t("studyTime")}
            </CommonStyledTableThCell>
            <CommonStyledTableThCell align="left">
              {t("memo")}
            </CommonStyledTableThCell>
            {isCurrentRoles.isStudent && (
              <CommonStyledTableThCell align="center" />
            )}
          </TableRow>
        </TableHead>
        {bodyPart}
      </Table>
    </StyledTableContainer>
  );
};
StudyLogTable.defaultProps = {
  dailyStudyLogs: undefined,
};
export default withTranslation(["study_logs"])(StudyLogTable);
