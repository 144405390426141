import { NewNotifications } from "@actions/types/notification";
import { safeString } from "@lib/string-utils";
import { authState } from "@reducers/auth";
import { useGetNewNotificationCountAsCommonQuery } from "@root/endpoints/TimeTactApi/remainingApi";
import { useAppSelector } from "@root/hooks";

type Return = {
  newNotificationCountResult: ReturnType<
    typeof useGetNewNotificationCountAsCommonQuery
  >;
  newLimitedTimeNotificationCount: number;
  newNotificationCount: number;
  notifications: NewNotifications[];
};

const useNewNotificationCount = (notificationsOff = false): Return => {
  const { accessToken, client, uid } = useAppSelector(authState);
  const newNotificationCountResult = useGetNewNotificationCountAsCommonQuery(
    {
      accessToken: safeString(accessToken),
      client: safeString(client),
      uid: safeString(uid),
    },
    {
      skip: !(accessToken && client && uid) || notificationsOff,
    },
  );
  return {
    newNotificationCountResult,
    newLimitedTimeNotificationCount:
      newNotificationCountResult.data?.new_limited_time_notification_count ?? 0,
    newNotificationCount:
      newNotificationCountResult.data?.new_notification_count ?? 0,
    notifications: newNotificationCountResult.data?.new_notifications ?? [],
  };
};

export default useNewNotificationCount;
