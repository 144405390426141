import { LogsContent } from "@components/Teacher/StudentLogs/Logs/hooks/useHandleStudyLogsByContentType";
import { MultilineText } from "@components/UI/atoms/MultilineText/MultilineText";
import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

type Props = {
  icon: JSX.Element;
  title: string;
  count: number;
  contentType: LogsContent;
  selectedContent: LogsContent | null;
  handleSelectedContent: (type: LogsContent) => void;
};

type StyleType = {
  isSelected: boolean;
};

const useStyles = makeStyles<Theme, StyleType>((theme) =>
  createStyles({
    mainContainer: ({ isSelected }) => ({
      position: "relative",
      width: "200px",
      height: "100px",
      borderRadius: theme.spacing(1),
      gap: theme.spacing(2),
      padding: theme.spacing(1),
      border: `2px solid ${theme.palette.quaternary.grey}`,
      display: "flex",
      flexDirection: "column",
      background: isSelected
        ? theme.palette.opacityColors.mainGreenTenPercentage
        : theme.palette.common.white,
      boxShadow:
        "2px 2px 4px 0px rgba(71, 81, 73, 0.05),-2px -2px 4px 0px rgba(71, 81, 73, 0.05)",
      "&:hover": {
        backgroundColor: !isSelected ? "rgba(71, 81, 73, 0.05)" : "none",
      },
    }),
    countStyle: {
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: "auto",
      textAlign: "center",
      fontSize: "16px",
    },
    count: {
      fontSize: "24px",
      color: theme.palette.customColor.text,
    },
    titleContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    title: {
      color: theme.palette.customColor.text,
      fontWeight: 700,
    },
  }),
);

const SectionItem: React.FC<Props> = ({
  count,
  icon,
  title,
  contentType,
  selectedContent,
  handleSelectedContent,
}) => {
  const classes = useStyles({ isSelected: selectedContent === contentType });
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        handleSelectedContent(contentType);
      }}
      className={classes.mainContainer}
    >
      <Box className={classes.titleContainer}>
        <span>{icon}</span>
        <MultilineText className={classes.title}>{title}</MultilineText>
      </Box>

      <Typography className={classes.countStyle}>
        <span className={classes.count}>{count}</span>件
      </Typography>
    </Box>
  );
};

export default SectionItem;
