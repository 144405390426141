/* eslint-disable camelcase */
import {
  createPblSponsorSchoolLinksAsAdmin,
  createSponsorSchoolLinkAsAdmin,
  deleteSponsorSchoolLinkAsAdmin,
  fetchLinkableSchools,
  fetchPblSponsorSchoolLinksAsAdmin,
  reset,
  resetAction,
} from "@actions/adminApp/pbl/sponsor/schoolLink";
import { SponsorLinkBase } from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface LinkableSchoolInfo {
  id: string;
  name: string;
  link_id: string | null;
}

export interface AdminAppPblSponsorSchoolLinkState {
  fetching: boolean;
  fetched: boolean;
  fetchErrors: string | null;
  schoolLinks: SponsorLinkBase[];
  totalCount: number;
  schoolIdsCreating: string[];
  linkIdsDeleting: string[];
  creating: boolean;
  created: boolean;
  changeErrors: any[] | null;
  linkableSchools: LinkableSchoolInfo[];
  linkableSchoolCount: number;
  fetchingLinkableSchools: boolean;
}

export const initialState: AdminAppPblSponsorSchoolLinkState = {
  fetching: false,
  fetched: false,
  fetchErrors: null,
  totalCount: 0,
  schoolLinks: [],
  creating: false,
  created: false,
  changeErrors: null,
  linkableSchools: [],
  linkableSchoolCount: 0,
  fetchingLinkableSchools: false,
  linkIdsDeleting: [],
  schoolIdsCreating: [],
};

const adminAppPblSponsorSchoolLinkSlice = createSlice({
  name: "adminApp/pbl/sponsors/schoolLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblSponsorSchoolLinksAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchPblSponsorSchoolLinksAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message as string,
        };
      })
      .addCase(fetchPblSponsorSchoolLinksAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: null,
          schoolLinks: payload.sponsor_links,
          totalCount: payload.total_count,
        };
      })
      .addCase(createSponsorSchoolLinkAsAdmin.pending, (state, action) => {
        return {
          ...state,
          schoolIdsCreating: [
            ...state.schoolIdsCreating,
            action.meta.arg.school_id,
          ],
          changeErrors: null,
        };
      })
      .addCase(createSponsorSchoolLinkAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          schoolIdsCreating: state.schoolIdsCreating.filter(
            (id) => id !== action.meta.arg.school_id,
          ),
          changeErrors: action.payload,
        };
      })
      .addCase(createSponsorSchoolLinkAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        const newSchool = {
          id: payload.company.id,
          name: payload.company.name,
          link_id: payload.id,
        };
        return {
          ...state,
          schoolIdsCreating: state.schoolIdsCreating.filter(
            (id) => id !== action.meta.arg.school_id,
          ),
          schoolLinks: [...state.schoolLinks, payload],
          totalCount: state.totalCount + 1,
          linkableSchools: update(state.linkableSchools, newSchool),
        };
      })
      .addCase(createPblSponsorSchoolLinksAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          changeErrors: null,
        };
      })
      .addCase(createPblSponsorSchoolLinksAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeErrors: action.payload,
        };
      })
      .addCase(
        createPblSponsorSchoolLinksAsAdmin.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            creating: false,
            created: true,
            changeErrors: null,
            schoolLinks: [...state.schoolLinks, payload.createdItems],
            totalCount: state.totalCount + payload.createdItems.length,
          };
        },
      )
      .addCase(deleteSponsorSchoolLinkAsAdmin.pending, (state, action) => {
        return {
          ...state,
          linkIdsDeleting: [...state.linkIdsDeleting, action.meta.arg.id],
          changeErrors: null,
        };
      })
      .addCase(deleteSponsorSchoolLinkAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          linkIdsDeleting: state.linkIdsDeleting.filter(
            (id) => id !== action.meta.arg.id,
          ),
          changeErrors: action.payload,
        };
      })
      .addCase(deleteSponsorSchoolLinkAsAdmin.fulfilled, (state, action) => {
        const { meta } = action;
        const { arg } = meta;
        const found = state.linkableSchools.find((s) => s.link_id === arg.id);
        const newlinkableSchools =
          typeof found === "undefined"
            ? state.linkableSchools
            : update(state.linkableSchools, {
                ...found,
                link_id: null,
              });
        return {
          ...state,
          linkIdsDeleting: state.linkIdsDeleting.filter(
            (id) => id !== action.meta.arg.id,
          ),
          changeErrors: null,
          schoolLinks: remove(state.schoolLinks, arg.id),
          totalCount: state.totalCount - 1,
          linkableSchools: newlinkableSchools,
        };
      })
      .addCase(fetchLinkableSchools.pending, (state, _action) => {
        return {
          ...state,
          fetchingLinkableSchools: true,
        };
      })
      .addCase(fetchLinkableSchools.rejected, (state, action) => {
        return {
          ...state,
          fetchingLinkableSchools: false,
        };
      })
      .addCase(fetchLinkableSchools.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingLinkableSchools: false,
          linkableSchools: payload.companies,
          linkableSchoolCount: payload.total_count,
        };
      })
      .addCase(reset, (state, _action) => {
        return {
          ...state,
          schoolLinks: [],
          fetched: false,
          fetchError: null,
        };
      })
      .addCase(resetAction, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          deleting: false,
          deleted: false,
          changeErrors: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const adminAppPblSponsorSchoolLinkState = (
  state: RootState,
): AdminAppPblSponsorSchoolLinkState => state.adminApp.pblSponsorSchoolLink;

export default adminAppPblSponsorSchoolLinkSlice.reducer;
