/* eslint-disable camelcase */
import {
  fileDeleted,
  fileUploaded,
  updatedHomework,
} from "@actions/ownerApp/homework";
import { enqueueToast } from "@actions/toast";
import { baseUrl, setRTKQueryHeaders } from "@lib/http";
import { nanoid } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  DeleteImageArg,
  SaveImageArg,
  UpdateImageArg,
  UploadImageArg,
} from "../type";

export const ownerAppHomeworkApi = createApi({
  reducerPath: "ownerApp/homeworkApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/owner/`,
    prepareHeaders: (headers) => setRTKQueryHeaders(headers),
  }),
  endpoints: (build) => ({
    uploadFiles: build.mutation({
      query: (arg: UploadImageArg) => {
        const { id, params } = arg;
        const fd = new FormData();
        params.files.forEach((file) => {
          fd.append("files[]", file);
        });
        return {
          url: `homeworks/${id}/upload_images`,
          method: "POST",
          body: fd,
        };
      },
      transformResponse: (response: any) => response,
      async onQueryStarted(arg: any, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(fileUploaded(data));
      },
    }),
    saveFile: build.mutation({
      query: (arg: SaveImageArg) => {
        const { id, params } = arg;
        const fd = new FormData();
        fd.append("file", params.file);
        return {
          url: `homeworks/${id}/save_image`,
          method: "POST",
          body: fd,
        };
      },
      transformResponse: (response: any) => response,
      async onQueryStarted(arg: any, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updatedHomework(data));
      },
    }),
    updateFile: build.mutation({
      query: (arg: UpdateImageArg) => {
        const { id, params } = arg;
        const fd = new FormData();
        fd.append("file", params.file);
        fd.append("image_id", params.imageId);
        return {
          url: `homeworks/${id}/update_image`,
          method: "PUT",
          body: fd,
        };
      },
      transformResponse: (response: any) => response,
      async onQueryStarted(arg: any, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updatedHomework(data));
      },
    }),
    deleteFile: build.mutation({
      query: (arg: DeleteImageArg) => {
        const { id, params } = arg;
        return {
          url: `homeworks/${id}/delete_image`,
          method: "DELETE",
          body: {
            image_id: params.imageId,
          },
        };
      },
      transformResponse: (response: any) => response,
      async onQueryStarted(arg: any, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(fileDeleted(data));
        dispatch(
          enqueueToast({
            message: "ファイルを削除しました。",
            id: nanoid(),
            options: {
              variant: "success",
            },
          }),
        );
      },
    }),
  }),
});

export const {
  useUploadFilesMutation,
  useSaveFileMutation,
  useUpdateFileMutation,
  useDeleteFileMutation,
} = ownerAppHomeworkApi;
