import {
  fetchAllReviewers,
  fetchReviewerAffiliatesAsReviewer,
} from "@actions/reviewerApp/user";
import { AffiliateName, BasicUserInfo } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface ReviewerAppUserState {
  fetching: boolean;
  feched: boolean;
  totalCount: number;
  reviewers: BasicUserInfo[];
  fetchReviewerAffiliatesStatus: ReduxStatusType;
  totalAffiliatesCount: number;
  reviewerAffiliates: AffiliateName[];
}

export const initialState: ReviewerAppUserState = {
  fetching: false,
  feched: false,
  reviewers: [],
  totalCount: 0,
  fetchReviewerAffiliatesStatus: ReduxStatus.idle,
  totalAffiliatesCount: 0,
  reviewerAffiliates: [],
};

export const reviewerAppUserSlice = createSlice({
  name: "ReviewerApp/User",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllReviewers.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          feched: false,
          reviewers: [],
        };
      })
      .addCase(fetchAllReviewers.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          feched: true,
          reviewers: payload.reviewers,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchAllReviewers.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          feched: false,
          reviewers: [],
        };
      })
      .addCase(fetchReviewerAffiliatesAsReviewer.pending, (state, _action) => {
        return {
          ...state,
          fetchReviewerAffiliatesStatus: ReduxStatus.pending,
          totalAffiliatesCount: 0,
          reviewerAffiliates: [],
        };
      })
      .addCase(fetchReviewerAffiliatesAsReviewer.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchReviewerAffiliatesStatus: ReduxStatus.fulfilled,
          totalAffiliatesCount: payload.total_count,
          reviewerAffiliates: payload.reviewer_affiliates,
        };
      })
      .addCase(fetchReviewerAffiliatesAsReviewer.rejected, (state, action) => {
        return {
          ...state,
          fetchReviewerAffiliatesStatus: ReduxStatus.rejected,
          totalAffiliatesCount: 0,
          reviewerAffiliates: [],
        };
      })
      .addDefaultCase((state, action) => {
        return state;
      });
  },
});

export const reviewerAppUserState = (state: RootState): ReviewerAppUserState =>
  state.reviewerApp.user;

export default reviewerAppUserSlice.reducer;
