import {
  Api,
  FetchSubmitHomeworkFilesRequestsAsReviewerParams,
  SubmitHomeworkFilesRequest,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchSubmitHomeworkFilesRequestAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/submitHomeworkFilesRequest/fetch",
  async (
    params: FetchSubmitHomeworkFilesRequestsAsReviewerParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.reviewer.fetchSubmitHomeworkFilesRequestsAsReviewer(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreateSubmitHomeworkFilesRequestParams {
  files: File[];
  templateId: string;
}

export const createSubmitHomeworkFilesRequestAsReviewer = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "reviewer/submitHomeworkFilesRequest/fetch",
  async (data: CreateSubmitHomeworkFilesRequestParams, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.append("template_id", data.templateId);
      data.files.forEach((file) => {
        fd.append(`files[]`, file, file.name);
      });
      const response = await http.post(
        `${"/reviewer/submit_homework_files_requests"}`,
        fd,
      );
      const resData = response.data as SubmitHomeworkFilesRequest;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetModalState = createAction(
  "reviewer/submitHomeworkFilesRequestresetModalState",
);
