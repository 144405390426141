import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

// eslint-disable-next-line import/prefer-default-export
export const MultilineText = withStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      wordBreak: "break-word",
      whiteSpace: "pre-line",
      color: theme.palette.customColor.text,
    },
  }),
)(Typography);
