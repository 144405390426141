/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/media-has-caption */
import {
  AllowedToDownload,
  isPublic,
  SetThumbnailData,
} from "@actions/types/material";
import {
  FormCategoryTitle,
  FormIconAnnotation,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import SVEditorPreview from "@components/UI/molecules/SVEditor/Preview/SVEditorPreview";
import SVEditor from "@components/UI/molecules/SVEditor/SVEditor";
import {
  AttachedImageCategorizableType,
  CategoryInfo,
  MaterialDetail,
} from "@lib/Api";
import { byteLengthOf, TextColumnMaxByte } from "@lib/string-utils";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useAuth } from "@root/contexts/Auth";
import { get } from "@root/lib/collection";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormValues, MaterialImageData } from "../../types";
import MaterialImageFields from "./MaterialImageFields";
import MaterialTagFields from "./MaterialTagFields";
import MaterialThumbnailFields from "./MaterialThumbnailFields";
import MaterialVideoFields from "./MaterialVideoFields";

interface Props {
  material: MaterialDetail;
  categories: CategoryInfo[];
  selectedCategory?: CategoryInfo;
  materialImages: MaterialImageData[];
  updateMaterialImages: React.Dispatch<
    React.SetStateAction<MaterialImageData[]>
  >;
  thumbnailFile: SetThumbnailData | null;
  setThumbnailFile: React.Dispatch<
    React.SetStateAction<SetThumbnailData | null>
  >;
  videoChanged: boolean;
  removedImages?: string[];
  setRemovedImages?: React.Dispatch<React.SetStateAction<string[]>>;
  setVideoChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setRemovedVideos: React.Dispatch<React.SetStateAction<string[]>>;
  isOverCapacity: boolean;
  progress: number;
  progressRef: React.MutableRefObject<HTMLDivElement | null>;
  setDeleteThumbnail: React.Dispatch<React.SetStateAction<boolean>>;
}

const createTitleArray = (
  categories: CategoryInfo[],
  category: CategoryInfo,
): Array<string | undefined> => {
  const arr: Array<string | undefined> = new Array(4);
  if ("text_id" in category) {
    const text = get(categories, category.text_id as string);
    arr[0] = text?.name;
  }
  if ("grade_id" in category) {
    const grade = get(categories, category.grade_id);
    arr[1] = grade?.name;
  }
  if ("subject_id" in category) {
    const subject = get(categories, category.subject_id);
    arr[2] = subject?.name;
  }
  if ("major_category_id" in category) {
    const major = get(categories, category.major_category_id);
    arr[3] = major?.name;
  }
  if ("middle_category_id" in category) {
    const middle = get(categories, category.middle_category_id);
    arr[4] = middle?.name;
  }
  return arr;
};

const Form: React.FC<Props> = ({
  material,
  selectedCategory,
  categories,
  removedImages,
  setRemovedImages,
  materialImages,
  updateMaterialImages,
  thumbnailFile,
  setThumbnailFile,
  isOverCapacity,
  progressRef,
  setDeleteThumbnail,
  ...rest
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();
  const {
    isCurrentRoles: { isSuperOwner, isOwner },
  } = useAuth();
  const [parentCategoryArr, setParentCategoryArr] = useState<
    Array<string | undefined>
  >([]);

  useEffect(() => {
    if (selectedCategory) {
      const arr = createTitleArray(categories, selectedCategory);
      setParentCategoryArr(arr);
    }
  }, [selectedCategory]);

  // 画像を挿入するにはcategorizableIdとcategorizableTypeの2つの値をセットする必要がある
  useEffect(() => {
    setValue("categorizableType", AttachedImageCategorizableType.Material);
    setValue("categorizableId", material.id);
  }, []);

  const inputRatio = Math.round(
    (byteLengthOf(watch("description")) / TextColumnMaxByte) * 100,
  );

  return (
    <form>
      {selectedCategory ? (
        <FormSectionWrap>
          <FormCategoryTitle>選択したカテゴリ</FormCategoryTitle>
          <Box display="flex" alignItems="center">
            {parentCategoryArr.map((item) => {
              if (typeof item === "undefined") {
                return null;
              }
              return (
                <Box display="flex" alignItems="center" key={item}>
                  <span>{item}</span>
                  <ChevronRightIcon />
                </Box>
              );
            })}
            <Box display="flex" alignItems="center">
              {selectedCategory.name}
            </Box>
          </Box>
        </FormSectionWrap>
      ) : null}
      <FormSectionWrap>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <FormCategoryTitle>
            教材名
            <div
              style={{
                backgroundColor: "gray",
                color: "white",
                textAlign: "center",
                borderRadius: "3px",
                padding: "2.5px 4px 2.5px 4px",
                marginLeft: "8px",
                display: "inline",
              }}
            >
              必須
            </div>
          </FormCategoryTitle>
          <FormIconAnnotation>
            <InfoIcon fontSize="small" htmlColor="#475149" />
            <div
              style={{
                paddingLeft: "8px",
              }}
            >
              255文字以内
            </div>
          </FormIconAnnotation>
        </Box>
        <Controller
          control={control}
          name="title"
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              variant="outlined"
              fullWidth
              autoFocus
              id="title"
              data-cy="material-name-input"
              placeholder="教材名"
              error={!!errors.title}
              helperText={errors.title?.message ?? null}
            />
          )}
          rules={{
            required: "教材名は必須です。",
            maxLength: {
              value: 255,
              message: "教材名は最大255文字まで入力が可能です。",
            },
          }}
        />
      </FormSectionWrap>
      <FormSectionWrap>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div
            style={{
              display: "flex",
            }}
          >
            <FormCategoryTitle>教材詳細</FormCategoryTitle>
            <SVEditorPreview<FormValues> FormValueName="description" />
          </div>
          <FormIconAnnotation>
            <InfoIcon fontSize="small" htmlColor="#475149" />
            <div
              style={{
                paddingLeft: "8px",
              }}
            >
              利用率
              {inputRatio}%
            </div>
          </FormIconAnnotation>
        </Box>
        <SVEditor<FormValues> FormValueName="description" />
      </FormSectionWrap>
      <FormSectionWrap>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <FormCategoryTitle>メモ</FormCategoryTitle>
          <FormIconAnnotation>
            <InfoIcon fontSize="small" htmlColor="#475149" />
            <div
              style={{
                paddingLeft: "8px",
              }}
            >
              255文字以内
            </div>
          </FormIconAnnotation>
        </Box>
        <Controller
          control={control}
          name="memo"
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              variant="outlined"
              fullWidth
              id="memo"
              placeholder="メモ"
              error={!!errors.memo}
              helperText={errors.memo?.message ?? null}
              data-cy="material-memo-input"
            />
          )}
          rules={{
            maxLength: {
              value: 255,
              message: "メモは最大255文字まで入力が可能です。",
            },
          }}
        />
      </FormSectionWrap>
      <MaterialTagFields />
      <MaterialVideoFields
        material={material}
        isOverCapacity={isOverCapacity}
        progressRef={progressRef}
        {...rest}
      />
      <MaterialImageFields
        materialImages={materialImages}
        updateMaterialImages={updateMaterialImages}
        removedImages={removedImages}
        setRemovedImages={setRemovedImages}
        isOverCapacity={isOverCapacity}
      />
      <FormSectionWrap>
        <FormCategoryTitle>ファイルのダウンロードを許可する</FormCategoryTitle>
        <Controller
          control={control}
          name="downloadAllowed"
          render={({ field: { onChange, value } }) => (
            <Box display="flex">
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel
                  value={AllowedToDownload.Allowed}
                  control={<Radio color="primary" />}
                  label="許可する"
                />
                <FormControlLabel
                  value={AllowedToDownload.NotAllowed}
                  control={<Radio color="primary" />}
                  label="許可しない"
                />
              </RadioGroup>
            </Box>
          )}
        />
      </FormSectionWrap>
      <MaterialThumbnailFields
        material={material}
        thumbnailFile={thumbnailFile}
        setThumbnailFile={setThumbnailFile}
        isOverCapacity={isOverCapacity}
        setDeleteThumbnail={setDeleteThumbnail}
      />
      {(isSuperOwner || isOwner) && (
        <FormSectionWrap>
          <FormCategoryTitle>
            {isSuperOwner
              ? "ヘルプページ 及び 管理者/先生/生徒の教材一覧で教材を公開する ※親カテゴリで未公開の場合は、いずれを選択しても公開されません。"
              : "先生/生徒の教材一覧で教材を公開する ※親カテゴリで未公開の場合は、いずれを選択しても公開されません。"}
          </FormCategoryTitle>
          <Controller
            control={control}
            name="isPublic"
            render={({ field: { onChange, value } }) => (
              <Box display="flex">
                <RadioGroup
                  data-cy="material-isPublic-radioBox"
                  row
                  value={value}
                  onChange={onChange}
                >
                  <FormControlLabel
                    value={isPublic.Allowed}
                    control={<Radio color="primary" />}
                    label="公開する"
                  />
                  <FormControlLabel
                    value={isPublic.NotAllowed}
                    control={<Radio color="primary" />}
                    label="公開しない"
                  />
                </RadioGroup>
              </Box>
            )}
          />
        </FormSectionWrap>
      )}
    </form>
  );
};

export default Form;
