import { useAppDispatch } from "@root/hooks";

import { disableScroll, resetScrollState } from "../store/actions/scroll";

const handleDisableScroll = (
  dispatch: ReturnType<typeof useAppDispatch>,
): void => {
  dispatch(disableScroll());
  setTimeout(() => dispatch(resetScrollState()), 300);
};

export default handleDisableScroll;
