import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import i18n from "i18next";
import React, { useState } from "react";

const lightColor = "rgba(255, 255, 255, 0.7)";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

interface StyledTabProps {
  label: string;
  value: string;
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      width: "100%",
      height: "3px",
      backgroundColor: "#4bb568",
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} />);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: theme.palette.customColor.text,
      padding: theme.spacing(1),
      fontWeight: theme.typography.fontWeightRegular as any,
      fontSize: theme.typography.pxToRem(15),
      minWidth: "70px",
      "&:focus": {
        opacity: 1,
        color: "#4BB568",
      },
      "&$selected": {
        opacity: 1,
        color: "#4BB568",
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const styles = (theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: lightColor,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
    secondaryBar: {
      zIndex: 0,
      backgroundColor: "#fff",
      color: "#475149",
    },
  });

const Languages: React.FC = () => {
  const [language, setLanguage] = useState(i18n.language);
  const tabClicked = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  const tabs = [
    { label: "日本語", value: "ja" },
    { label: "English", value: "en" },
  ];

  return (
    <Box style={{ padding: "0 5px" }}>
      <StyledTabs
        value={language}
        onChange={(event, value) => tabClicked(value)}
      >
        {tabs.map(({ value, label }) => (
          <StyledTab key={`language-${value}`} label={label} value={value} />
        ))}
      </StyledTabs>
    </Box>
  );
};

export default withStyles(styles)(Languages);
