/* eslint-disable @typescript-eslint/ban-types */
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import CategoryTitleAnnotation from "@components/UIv2/atoms/CategoryTitleAnnotation";
import { DailyLogCategory } from "@lib/Api";
import useDisableNumberInputScroll from "@lib/hooks/useDisableNumberInputScroll";
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormType } from "../index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalTitle: {
      position: "relative",
      margin: 0,
      padding: theme.spacing(2),
      background: theme.palette.action.hover,
      color: theme.palette.common.black,
    },
    modalBody: {
      background: theme.palette.tertiary.main,
    },
    contentWrapper: {
      background: theme.palette.common.white,
    },
    withoutDelete: {
      margin: 0,
      padding: theme.spacing(1),
      background: theme.palette.action.hover,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: "-3px",
      color: theme.palette.tertiary.main,
    },
    noValue: {
      color: theme.palette.grey[500],
    },
  }),
);

interface Props {
  control: Control<FormType, object>;
}

export const StyledMenuItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.customColor.text,
      fontSize: "16px",
      "&:hover": {
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.common.white,
      },
    },
  }),
)(MenuItem);

const LogForm: React.FC<Props> = ({ control }) => {
  const classes = useStyles();
  useDisableNumberInputScroll();
  const { t } = useTranslation(["study_logs", "common"]);

  return (
    <>
      <FormSectionWrap style={{ width: "100%" }}>
        <CategoryTitleAnnotation
          title={t("studyDate")}
          annotation={t("required")}
        />
        <Controller
          control={control}
          name="date"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              type="date"
              variant="outlined"
              fullWidth
              onChange={onChange}
              value={value}
              error={!!error}
              placeholder="学習日を選択"
              helperText={error ? error.message : null}
              InputProps={{ inputProps: { min: 0 } }}
            />
          )}
          rules={{ required: t("studyDayRequired") }}
        />
      </FormSectionWrap>

      <FormSectionWrap>
        <CategoryTitleAnnotation
          title={t("studyCategory")}
          annotation={t("required")}
        />
        <Controller
          name="category"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Select
                  variant="outlined"
                  labelId="category-select-label"
                  id="category-select"
                  value={value}
                  onChange={onChange}
                  fullWidth
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  data-cy="category-select"
                  error={!!error}
                >
                  <StyledMenuItem value="">
                    <em className={classes.noValue}>{t("categorySelect")}</em>
                  </StyledMenuItem>

                  <StyledMenuItem
                    value={DailyLogCategory.ThemeSettings}
                    data-cy={DailyLogCategory.ThemeSettings}
                  >
                    {t(DailyLogCategory.ThemeSettings)}
                  </StyledMenuItem>

                  <StyledMenuItem
                    value={DailyLogCategory.Research}
                    data-cy={DailyLogCategory.Research}
                  >
                    {t(DailyLogCategory.Research)}
                  </StyledMenuItem>

                  <StyledMenuItem
                    value={DailyLogCategory.Analysis}
                    data-cy={DailyLogCategory.Analysis}
                  >
                    {t(DailyLogCategory.Analysis)}
                  </StyledMenuItem>

                  <StyledMenuItem
                    value={DailyLogCategory.Summary}
                    data-cy={DailyLogCategory.Summary}
                  >
                    {t(DailyLogCategory.Summary)}
                  </StyledMenuItem>
                </Select>
                {error && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </>
            );
          }}
          rules={{ required: t("categoryIsRequired") }}
        />
      </FormSectionWrap>

      <FormSectionWrap>
        <CategoryTitleAnnotation
          title={t("studyTime")}
          annotation={`${t("common:number_input")} ${t("required")} `}
        />
        <Box display="flex" alignItems="center">
          <Controller
            control={control}
            name="logTime"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                onChange={onChange}
                value={value}
                placeholder={t("enterStudyTime")}
                error={!!error}
                helperText={error ? error.message : null}
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
            rules={{
              required: t("studyTimeRequired"),
              max: {
                value: 1440,
                message: t("correctStudyTimeMessage"),
              },
              min: {
                value: 1,
                message: t("correctStudyTimeMessage"),
              },
            }}
          />
          <span style={{ marginLeft: 10, fontSize: 18 }}>{t("minutes")}</span>
        </Box>
      </FormSectionWrap>

      <FormSectionWrap>
        <FormCategoryTitle>{t("memo")}</FormCategoryTitle>
        <Controller
          control={control}
          name="memo"
          render={({ field: { onChange, value } }) => (
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FormSectionWrap>
    </>
  );
};

export default LogForm;
