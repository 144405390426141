import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SteamLibraryContentEnum } from "@root/constants/page";
import { SteamLibraryContentEnumType } from "@root/types/page";
import { RootState } from "@store/store";

const initialState: { tab: SteamLibraryContentEnumType } = {
  tab: SteamLibraryContentEnum.contents,
};

const steamLibraryTabSlice = createSlice({
  name: "teacher/steamLibraryTab",
  initialState,
  reducers: {
    updateTab: (state, action: PayloadAction<SteamLibraryContentEnumType>) => {
      return { ...state, tab: action.payload };
    },
  },
});
export const teacherAppSteamLibraryTabState = (
  state: RootState,
): typeof initialState => state.teacherApp.steamLibraryTab;

export const { updateTab } = steamLibraryTabSlice.actions;
export default steamLibraryTabSlice.reducer;
