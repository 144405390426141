/* eslint-disable camelcase */
import {
  AdminCompany,
  AdminCompanyList,
  Api,
  FetchCompaniesAsAdminParams,
  OptionsExpansionCapacity,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { AxiosError } from "axios";

import { CompanyDataParam, UpdateParamType } from "./types/company";

const api = new Api();

export const fetchCompanies = createAsyncThunk<
  { companies: AdminCompanyList[]; total_count: number },
  FetchCompaniesAsAdminParams | undefined,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "FETCH_COMPANIES",
  async (
    query: FetchCompaniesAsAdminParams | undefined,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.admin.fetchCompaniesAsAdmin({ ...query });
      return response.data as any;
    } catch (err) {
      const typedErr = err as AxiosError;
      return rejectWithValue(typedErr.response?.data);
    }
  },
);

export const fetchMaterialCapacity = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("FETCH_MATERIAL_COMPANY", async (companyId: string, { rejectWithValue }) => {
  try {
    const data = {};
    const response = await api.admin.getMaterialCapacityAsAdmin(
      companyId,
      data,
    );
    return response.data;
  } catch (err) {
    const typedErr = err as AxiosError;
    return rejectWithValue(typedErr.response?.data);
  }
});

export const fetchCompanyDetail = createAsyncThunk<
  AdminCompany,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("FETCH_COMPANY_DETAIL", async (companyId: string, { rejectWithValue }) => {
  try {
    const data = {};
    const response = await api.admin.getCompaniesCompanyId(companyId, data);
    return response.data;
  } catch (err) {
    const typedErr = err as AxiosError;
    return rejectWithValue(typedErr.response?.data);
  }
});

export const deleteCompanyAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/companies/delete", async (companyId: string, { rejectWithValue }) => {
  try {
    const response = await api.admin.deleteCompanyAsAdmin(companyId);
    return response.data;
  } catch (err) {
    const typedErr = err as AxiosError;
    return rejectWithValue(typedErr.response?.data);
  }
});

export const createCompanies = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("CREATE_COMPANIES", async (data: CompanyDataParam, { rejectWithValue }) => {
  try {
    const response = await api.admin.postCompanies(data);
    return response.data as any;
  } catch (err) {
    const typedErr = err as AxiosError;
    return rejectWithValue(typedErr.response?.data);
  }
});

export const updateCompanyDetail = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "update_COMPANY_DETAIL",
  async (params: UpdateParamType, { rejectWithValue }) => {
    try {
      const response = await api.admin.putCompaniesCompanyId(
        params.companyId,
        params.data,
      );
      return response.data as any;
    } catch (err) {
      const typedErr = err as AxiosError;
      return rejectWithValue(typedErr.response);
    }
  },
);

export const postReviewCompanyAsAdmin = createAsyncThunk<
  any,
  { reviewer_company_id: string; reviewee_company_id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("admin/postReviewCompany", async (params, { rejectWithValue }) => {
  try {
    const response = await api.admin.postConnectReviewerCompany(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const postOptionsExpansion = createAsyncThunk<
  { message?: string | undefined },
  {
    company_id: string;
    type: "material_expansion" | "ideal_expansion";
    capacity: OptionsExpansionCapacity;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/option_expansion/post",
  async (
    params: {
      company_id: string;
      type: "material_expansion" | "ideal_expansion";
      capacity: OptionsExpansionCapacity;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.admin.postOptionsExpansionAsAdmin(
        { company_id: params.company_id },
        { type: params.type, capacity: params.capacity },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const putOptionsExpansion = createAsyncThunk<
  { message?: string | undefined },
  {
    company_id: string;
    id: string;
    capacity: OptionsExpansionCapacity;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/option_expansion/put",
  async (
    params: {
      company_id: string;
      id: string;
      capacity: OptionsExpansionCapacity;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.admin.putOptionsExpansionAsAdmin(
        { company_id: params.company_id, id: params.id },
        { capacity: params.capacity },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetReviewCompanyState = createAction(
  "admin/resetReviewCompanyState",
);

export const resetReviewCompanyCount = createAction(
  "admin/resetReviewCompanyCount",
);

export const resetCompanyState = createAction("admin/resetCompanyState");
