import {
  fetchNotificationsSentAsAdmin,
  postNotificationAsAdmin,
  resetFormState,
} from "@actions/adminApp/notification";
import { NotificationSent } from "@actions/types/notification";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

interface NotificationState {
  fetching: boolean;
  fetched: boolean;
  posting: boolean;
  posted: boolean;
  notificationsSent: NotificationSent[];
  fetchError: string | null;
  postError: any;
  totalCountSent: number;
}

const initialState: NotificationState = {
  fetching: false,
  fetched: false,
  posting: false,
  posted: false,
  notificationsSent: [],
  fetchError: null,
  postError: null,
  totalCountSent: 0,
};

const notificationSlice = createSlice({
  name: "Admin/NotificationSent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsSentAsAdmin.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          notificationsSent: [],
          fetchError: null,
        };
      })
      .addCase(fetchNotificationsSentAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          notificationsSent: action.payload.notifications,
          totalCountSent: action.payload.total_count,
        };
      })
      .addCase(fetchNotificationsSentAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(postNotificationAsAdmin.pending, (state, action) => {
        return {
          ...state,
          posting: true,
          posted: false,
          postError: null,
        };
      })
      .addCase(postNotificationAsAdmin.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
        };
      })
      .addCase(postNotificationAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          postError: action.payload?.error || "エラーが発生しました。",
        };
      })
      .addCase(resetFormState, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: false,
          postError: null,
        };
      });
  },
});

export const adminAppNotificationState = (
  state: RootState,
): NotificationState => state.adminApp.notification;

export default notificationSlice.reducer;
