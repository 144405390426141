/* eslint-disable camelcase */
export enum STUDENT_TAB_TYPES {
  study_contents_menu = "study_contents_menu",
  study_content = "study_contents",
  student_logs = "student_logs",
  dashboard = "dashboard",
  ideal = "ideal",
  homework = "homework",
  material_works = "material_works",
  materials = "materials",
  pbl_menu = "pbl_menu",
  classroom = "classroom",
  report_answers = "report_answers",
  steam_library = "steam_library",
  calendar = "calendar",
  pbl_projects = "pbl_projects",
  learning_packages = "learning_packages",
  memo = "memo",
  articles = "articles",
}

export type Tab = {
  value: string;
  icon: JSX.Element;
  label: string;
};

export type MobileTab = {
  id: number;
  icon: JSX.Element;
  title: string;
  url: string;
  value: STUDENT_TAB_TYPES;
};

export type Category = {
  id: string;
  title: string;
  children: {
    id: string;
    link: string;
    childTitle: string;
    icon: JSX.Element;
  }[];
};
