import "dayjs/locale/ja";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export const formatLong = (dateTime?: string): string => {
  if (!dateTime || !dayjs(dateTime).isValid()) {
    return "設定なし";
  }
  return dayjs(dateTime).format("YYYY/MM/DD HH:mm");
};

export const formatLongWithDayOfWeek = (dateTime: string): string => {
  const formatedDate = dayjs(dateTime)
    .locale("ja")
    .format("YYYY年MM月DD日(ddd) HH:mm");
  if (formatedDate === "Invalid Date") return "";
  return formatedDate;
};

export const getStartAndEndDate = (start?: string, end?: string): string => {
  if (start && end) {
    return `${formatLong(start)} ~ ${formatLong(end)}`;
  }
  if (!start && end) {
    return `設定なし ~ ${formatLong(end)}`;
  }
  if (start && !end) {
    return `${formatLong(start)} ~ 設定なし`;
  }
  return "設定なし";
};

export const compareNowWithPeriod = (
  start?: string,
  end?: string,
): 1 | 2 | 3 => {
  const startTime = start ? dayjs(start) : dayjs().add(-10, "year");
  const endTime = end ? dayjs(end) : dayjs().add(10, "year");
  const currentTime = dayjs();
  if (currentTime.isBefore(startTime)) return 1;
  if (currentTime.isBetween(startTime, endTime)) return 2;
  if (currentTime.isAfter(endTime)) return 3;
  throw new Error();
};

const calculateDateRanges = (date: string) => {
  const year = dayjs(date).year();
  const isBetweenAprilAndDecember = dayjs(date).isBetween(
    `${year}-04-01`,
    `${year}-12-31`,
    "day",
    "[]",
  );
  const isBetweenJanuaryAndMarch = dayjs(date).isBetween(
    `${year}-01-01`,
    `${year}-03-31`,
    "day",
    "[]",
  );

  return {
    isBetweenAprilAndDecember,
    isBetweenJanuaryAndMarch,
  };
};

export const calculateYearAndCheckRanges = (date: string): string => {
  const { isBetweenAprilAndDecember, isBetweenJanuaryAndMarch } =
    calculateDateRanges(date);

  let calculatedEndDate = "";

  if (isBetweenAprilAndDecember) {
    calculatedEndDate = dayjs(date).add(1, "year").format("YYYY-03-31");
  } else if (isBetweenJanuaryAndMarch) {
    calculatedEndDate = dayjs(date).format("YYYY-03-31");
  }

  return calculatedEndDate;
};

export const calculateSchoolYear = (date: string): number => {
  const { isBetweenAprilAndDecember, isBetweenJanuaryAndMarch } =
    calculateDateRanges(date);

  let calculatedEndDate = "";

  if (isBetweenAprilAndDecember) {
    calculatedEndDate = dayjs(date).subtract(1, "year").format("YYYY");
  } else if (isBetweenJanuaryAndMarch) {
    calculatedEndDate = dayjs(date).subtract(2, "year").format("YYYY");
  }

  return parseInt(calculatedEndDate, 10);
};
