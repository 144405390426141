/* eslint-disable @typescript-eslint/ban-types */
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import V2DatePicker from "@components/UIv2/atoms/V2DatePicker";
import { isValidDuration } from "@lib/time-utils";
import { Box,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Control,Controller, UseFormGetValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { StartAndEndDateFormValue } from "../../Logs/hooks/useHandleModalStartAndEndDate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      marginTop: theme.spacing(2),
      marginLeft: 100,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start",
        marginTop: theme.spacing(0),
        marginLeft: theme.spacing(2),
        flexDirection: "column",
      },
    },
    dateContainer: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0),
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    labelStyle: {
      fontSize: 14,
    },
  }),
);

type Props = {
  getValues: UseFormGetValues<StartAndEndDateFormValue>;
  handleClear: () => void;
  submit: () => void;
  control: Control<StartAndEndDateFormValue, object>;
  endDate: string;
  startDate: string;
  isFetchingStudyLog: boolean;
};

const SearchByDateInputs: React.FC<Props> = ({
  getValues,
  handleClear,
  control,
  submit,
  startDate,
  endDate,
  isFetchingStudyLog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Box className={classes.main}>
      <Box className={classes.dateContainer}>
        <Controller
          name="startDate"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <V2DatePicker
              value={value}
              onChange={(newDate) => onChange(newDate)}
              slotProps={{
                field: { clearable: false },
                textField: {
                  size: "small",
                  error: !!error,
                  label: error ? error.message : t("startDate"),
                  InputLabelProps: {
                    classes: {
                      root: classes.labelStyle,
                    },
                  },
                },
              }}
            />
          )}
          rules={{
            required: t("start_date"),
          }}
        />
        <Box m={1.25}>～</Box>
        <Controller
          name="endDate"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <V2DatePicker
              value={value}
              onChange={(newDate) => onChange(newDate)}
              slotProps={{
                field: { clearable: false },
                textField: {
                  size: "small",
                  error: !!error,
                  label: error ? error.message : t("endDate"),
                  InputLabelProps: {
                    classes: {
                      root: classes.labelStyle,
                    },
                  },
                },
              }}
            />
          )}
          rules={{
            required: t("end_date"),
            validate: {
              beforeEndDate: (value) =>
                isValidDuration(getValues("startDate"), value) ||
                t("endDateIsBeforeError"),
            },
          }}
        />
      </Box>
      <SingleButton
        variant="contained"
        onClick={submit}
        disabled={isFetchingStudyLog}
      >
        {t("search")}
      </SingleButton>
      {startDate && endDate && (
        <SingleButton
          disabled={isFetchingStudyLog}
          variant="outlined"
          onClick={handleClear}
        >
          {t("refresh")}
        </SingleButton>
      )}
    </Box>
  );
};

export default SearchByDateInputs;
