/* eslint-disable camelcase */
import { Api } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const createCompanyTagCompanyTagging = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companyTagging/createCompanyTag",
  async (
    data: { company_id: string; company_tag_id: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.superOwner.companyTaggingsCreate({
        company_id: data.company_id,
        company_tag_id: data.company_tag_id,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteCompanyTagCompanyTagging = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companyTagging/deleteCompanyTag",
  async (
    data: { company_id: string; company_tag_id: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.superOwner.companyTaggingsDelete({
        company_id: data.company_id,
        company_tag_id: data.company_tag_id,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchOneCompanyTagsAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companyTag/fetchOneCompany",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.companiesCompanyTagsDetail(companyId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createUserTagCompanyTagging = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companyTagging/createUserTag",
  async (
    data: { company_id: string; user_tag_id: string; start_at: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.superOwner.assignUserTagToCompanyAsSuperOwner(
        data.user_tag_id,
        { company_id: data.company_id, start_at: data.start_at },
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUserTagCompanyTagging = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companyTagging/updateUserTag",
  async (
    data: { company_id: string; user_tag_id: string; start_at: string },
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.assignUpdateUserTagToCompanyAsSuperOwner(
          data.user_tag_id,
          { company_id: data.company_id, start_at: data.start_at },
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUserTagCompanyTagging = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/companyTagging/deleteUserTag",
  async (
    data: { company_id: string; user_tag_id: string },
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.unassignUserTagToCompanyAsSuperOwner(
          data.user_tag_id,
          { company_id: data.company_id },
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchOneUserTagsAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/userTag/fetchOneCompany",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await api.superOwner.companiesUserTagsDetail({
        company_id: companyId,
      });
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction("super_owner/companyTagging/resetState");
