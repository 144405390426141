/* eslint-disable camelcase */

import { Api } from "@lib/Api";
import { http } from "@lib/http";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { STUDENT_ARTICLES_PATH } from "@root/constants/api";
import { RootState } from "@store/store";

const api = new Api();

type PostArticleImagesAsStudentParams = {
  file: File;
};

export interface PostArticleImagesAsStudentArg {
  articleId: string;
  params: PostArticleImagesAsStudentParams;
}
export interface DeleteArticleImagesAsStudent {
  articleId: string;
  id: string;
}

export const postArticleImagesAsStudent = createAsyncThunk<
  any,
  PostArticleImagesAsStudentArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/article/articleId/article_images",
  async (arg, { rejectWithValue }) => {
    const { articleId, params } = arg;
    const { file } = params;
    const fd = new FormData();
    if (typeof file !== "undefined") {
      fd.set("file", file);
    }
    try {
      const response = await http.post(
        `${STUDENT_ARTICLES_PATH}/${articleId}/article_images`,
        fd,
      );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const deleteArticleImagesAsStudent = createAsyncThunk<
  { message?: string },
  DeleteArticleImagesAsStudent,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/article/articleId/article_images/id",
  async (arg, { rejectWithValue }) => {
    const { articleId, id } = arg;
    try {
      const response = await api.student.deleteControllerAsStudent(
        articleId,
        id,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
