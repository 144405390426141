/* eslint-disable camelcase */
import {
  AffiliateStatusJa,
  Api,
  CalendarOauth2CallbackListParams,
  ClassroomOauth2CallbackListParams,
  GetTtGoogleDrivesOauth2CallbackAsCommonParams,
  MaterialsListParams,
  ScheduleBatchBase,
  SchedulesListParams,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";
import _ from "lodash";

import { getCurrentUser } from "./auth";
import {
  CurriculumSchedulesEventParams,
  UpdateCurriculumSchedulesEventParams,
} from "./types/common";

const api = new Api();

export type CommonAffiliate = {
  id?: string;
  company_id?: string;
  company_name?: string;
  roles: [
    {
      id: string;
      role: string;
      status: AffiliateStatusJa;
    },
  ];
  is_enterprise: boolean;
};

export const resetState = createAction("common/resetState");

export const getCommonAffiliates = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/getCommonAffiliates", async (_params: void, { rejectWithValue }) => {
  try {
    const response = await api.common.getCommonAffiliates();
    return response.data as CommonAffiliate[];
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const postCommonAffiliates = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/postCommonAffiliates",
  async (
    data: {
      company_id: string | undefined;
      role_id: string | undefined;
      asProxy?: boolean;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.common.getCommonAffiliatesSwitch(data);
      if (!data.asProxy) {
        await dispatch(getCurrentUser({}));
      }
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getCommonSchedules = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/getCommonSchedules",
  async (data: SchedulesListParams, { rejectWithValue }) => {
    try {
      const response = await api.common.schedulesList(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const postCommonSchedules = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/postCommonSchedules", async (data: any, { rejectWithValue }) => {
  try {
    const response = await api.common.schedulesCreate(data);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

// This method is used to create event
// from the teacher curriculum

export const postCurriculumSchedulesEvent = createAsyncThunk<
  ScheduleBatchBase[],
  CurriculumSchedulesEventParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/postCurriculumSchedulesEvent", async (data, { rejectWithValue }) => {
  try {
    const response = await api.common.patchSchedulesBatchCreate(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

// This method is used to update event
// from the teacher curriculum

export const updateCurriculumSchedulesEvent = createAsyncThunk<
  any,
  UpdateCurriculumSchedulesEventParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/updateCurriculumSchedulesEvent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.common.patchSchedulesBatchUpdate(data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getCommonSchedulesDetail = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/getCommonSchedulesDetail",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.common.schedulesDetail(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const updateCommonSchedules = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/updateCommonSchedules",
  async (
    params: {
      id: string;
      data: {
        start_time?: string;
        end_time?: string;
        title?: string;
        place?: string;
        private_flag?: boolean;
        all_day?: boolean;
        memo?: string;
        attendee_ids?: string[];
      };
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.common.schedulesUpdate(params.id, params.data);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCommonSchedules = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/deleteCommonSchedules",
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      const response = await api.common.schedulesDelete(scheduleId);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getHelpCategories = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("help/categories/fetch", async (_params: void, { rejectWithValue }) => {
  try {
    const response = await api.common.categoriesList();
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const resetCategoryData = createAction("common/resetCategoryData");

export const getHelpMaterials = createAsyncThunk<
  GetResponseType<Api["common"]["materialsList"]>,
  MaterialsListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("help/materials/fetch", async (query, { rejectWithValue }) => {
  try {
    const response = await api.common.materialsList(query);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getHelpMaterialDetail = createAsyncThunk<
  GetResponseType<Api["common"]["materialsDetail"]>,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("help/materialDetail/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await api.common.materialsDetail(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const syncGoogleCalendar = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/syncGoogleCalendar",
  async (params: CalendarOauth2CallbackListParams, { rejectWithValue }) => {
    try {
      const response = await api.googleApi.calendarOauth2CallbackList(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  },
);

export const synchedGoogleCalendar = createAction(
  "common/synchedGoogleCalendar",
);

export const syncGoogleClassRoom = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/syncGoogleClassRoom",
  async (params: ClassroomOauth2CallbackListParams, { rejectWithValue }) => {
    try {
      const response = await api.googleApi.classroomOauth2CallbackList(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

type InviteParams = {
  email: string;
  company_id?: string;
};

export const sentInvitationsToUsers = createAsyncThunk<
  any,
  InviteParams[],
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/inviteUsers", async (params, { rejectWithValue }) => {
  try {
    const responses: any[] = [];
    // Set the number of simultaneous requests, because sending 100 requests
    // at the same time would catch the maximum number of connections in mysql.
    const CONCURRENCY = 25;
    const batches = _.chunk(params, CONCURRENCY);

    // eslint-disable-next-line no-restricted-syntax
    for (const batch of batches) {
      const promises = batch.map((item: InviteParams) => {
        return api.common.inviteUserMailersCreate(item);
      });
      // eslint-disable-next-line no-await-in-loop
      const results = await Promise.all(promises);
      responses.push(...results);
    }
    return responses;
  } catch (err) {
    return rejectWithValue(err.response.data.message);
  }
});

export const sentInvitationToUser = createAsyncThunk<
  any,
  InviteParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("common/inviteUser", async (params: InviteParams, { rejectWithValue }) => {
  try {
    const response = await api.common.inviteUserMailersCreate(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message);
  }
});

type AcceptParams = {
  id: string;
  data: {
    terms_accepted: boolean;
  };
};

export const acceptTermsOfUse = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/acceptTermsOfUse",
  async (params: AcceptParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.common.termsAcceptsUpdate(
        params.id,
        params.data,
      );
      dispatch(getCommonAffiliates({}));

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  },
);

export const getMicrosoftDrives = createAction(
  "common/getMicrosoftDrives",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const getMicrosoftDrivesFormat = createAction(
  "common/getMicrosoftDrivesFormat",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const synchedMicrosoftDrives = createAction(
  "common/synchedMicrosoftDrives",
);

export const getGoogleDrives = createAction("common/getGoogleDrives", (arg) => {
  return {
    payload: arg,
  };
});

export const syncGoogleDrives = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "common/syncGoogleDrives",
  async (
    params: GetTtGoogleDrivesOauth2CallbackAsCommonParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.common.getTtGoogleDrivesOauth2CallbackAsCommon(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  },
);
export const synchedGoogleDrives = createAction("common/synchedGoogleDrives");

export const toggleHelpPageDrawer = createAction("common/helpPage/drawer");

export const toggleJoyride = createAction("common/helpPage/joyride");

export const synchedGoogleClassRoom = createAction(
  "common/synchedGoogleClassRoom",
);

export const resetStatusValue = createAction("common/resetStatusValue");
export const resetClassStatusValue = createAction(
  "common/resetClassStatusValue",
);
export const connectedToClassRoom = createAction("common/connectedToClassRoom");
export const scheduleEventStarted = createAction("common/schedule/started");
export const scheduleEventEnded = createAction("common/schedule/ended");
