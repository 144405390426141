/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AdminTableRow,
  HoverClickableAdminTableRow,
} from "@components/UI/organisms/Tables/TableComponents";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, TableCell, TableRow, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

import { AccordionElementProps, BodyItemsTypes } from "./tableTypes";

type Props<T> = {
  bodyItem: BodyItemsTypes<T>;
  accordionElement?: React.FC<AccordionElementProps>;
  hasAccordion: boolean;
  colCount: number;
  onClick?: (bodyItem: BodyItemsTypes<T>) => void;
  onMouseEnter?: (bodyItem: BodyItemsTypes<T>) => void;
  onMouseLeave?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      "&:not(:last-child)": {
        borderBottom: "1px solid rgba(71, 81, 73, 0.2)",
      },
    },
  }),
);

const RowSection = <T,>({
  bodyItem,
  accordionElement: AccordionElement,
  colCount,
  hasAccordion,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props<T>): JSX.Element => {
  const classes = useStyles();
  const [openModule, setOpenModule] = useState<boolean>(false);
  const CommonTableRow = onClick ? HoverClickableAdminTableRow : AdminTableRow;
  const { id } = bodyItem;
  return (
    <>
      <CommonTableRow
        key={bodyItem.id}
        className={classes.border}
        data-cy="common-table-row"
        onClick={onClick ? () => onClick(bodyItem) : undefined}
        onMouseEnter={onMouseEnter ? () => onMouseEnter(bodyItem) : undefined}
        onMouseLeave={onMouseLeave}
      >
        {bodyItem.cells.map((cell) => (
          <TableCell
            key={cell.id}
            align={cell.align}
            data-cy="common-table-cell"
          >
            {cell.body}
          </TableCell>
        ))}
        {hasAccordion && (
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, cursor: "pointer" }}
            onClick={() => setOpenModule((prev) => !prev)}
            data-cy="accordion-button"
          >
            {AccordionElement &&
              (openModule ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              ))}
          </TableCell>
        )}
      </CommonTableRow>
      {AccordionElement && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={colCount}
          >
            <Collapse in={openModule} timeout="auto" unmountOnExit>
              {AccordionElement && (
                <AccordionElement id={id} isExpanded={openModule} />
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

RowSection.defaultProps = {
  accordionElement: undefined,
  onClick: undefined,
};
export default RowSection;
