import {
  createPblSponsorInfoAsAdmin,
  deletePblSponsorInfoAsAdmin,
  fetchOnePblSponsorInfoAsAdmin,
  fetchPblSponsorInfoAsAdmin,
  resetAction,
  resetOne,
  updatePblSponsorInfoAsAdmin,
} from "@actions/adminApp/pbl/sponsor/info";
import {
  createPblSponsorInfoCategoryAsAdmin,
  deletePblSponsorInfoCategoryAsAdmin,
  updatePblSponsorInfoCategoryAsAdmin,
} from "@actions/adminApp/pbl/sponsor/infoCategory";
import { SponsorInfoDetailsBase,SponsorInfoListBase } from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface AdminAppPblSponsorInfoState {
  fetching: boolean;
  fetchingOne: boolean;
  fetched: boolean;
  fetchedOne: boolean;
  fetchError: string | null;
  fetchOneError: string | null;
  infoList: SponsorInfoListBase[];
  infoDetails: SponsorInfoDetailsBase | null;
  totalCount: number;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  changeError: string | null;
}

export const initialState: AdminAppPblSponsorInfoState = {
  fetching: false,
  fetchingOne: false,
  fetched: false,
  fetchedOne: false,
  fetchError: null,
  fetchOneError: null,
  infoList: [],
  infoDetails: null,
  totalCount: 0,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  changeError: null,
};

const adminAppPblSponsorInfoSlice = createSlice({
  name: "adminApp/pbl/sponsors/info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPblSponsorInfoAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
        };
      })
      .addCase(fetchPblSponsorInfoAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.payload,
        };
      })
      .addCase(fetchPblSponsorInfoAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          fetched: true,
          fetchError: null,
          infoList: payload.sponsors,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchOnePblSponsorInfoAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
        };
      })
      .addCase(fetchOnePblSponsorInfoAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: action.payload,
        };
      })
      .addCase(fetchOnePblSponsorInfoAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          fetchOneError: null,
          infoDetails: payload,
        };
      })
      .addCase(createPblSponsorInfoAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          changeError: null,
        };
      })
      .addCase(createPblSponsorInfoAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeError: action.payload,
        };
      })
      .addCase(createPblSponsorInfoAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updating: false,
          updated: true,
          changeError: null,
          infoList: [...state.infoList, payload],
          totalCount: state.totalCount + 1,
        };
      })
      .addCase(updatePblSponsorInfoAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeError: null,
        };
      })
      .addCase(updatePblSponsorInfoAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: action.payload,
        };
      })
      .addCase(updatePblSponsorInfoAsAdmin.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updating: false,
          updated: true,
          changeError: null,
          infoDetails: payload,
          infoList: update(state.infoList, payload),
        };
      })
      .addCase(deletePblSponsorInfoAsAdmin.pending, (state, _action) => {
        return {
          ...state,
          deleting: true,
          deleted: false,
          changeError: null,
        };
      })
      .addCase(deletePblSponsorInfoAsAdmin.rejected, (state, action) => {
        return {
          ...state,
          deleting: false,
          deleted: false,
          changeError: action.payload,
        };
      })
      .addCase(deletePblSponsorInfoAsAdmin.fulfilled, (state, action) => {
        const deletedId = action.meta.arg;
        return {
          ...state,
          deleting: false,
          deleted: true,
          changeError: null,
          infoDetails: null,
          infoList: remove(state.infoList, deletedId),
          totalCount: state.totalCount - 1,
        };
      })
      .addCase(resetAction, (state, _action) => {
        return {
          ...state,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
          changeError: null,
        };
      })
      .addCase(
        createPblSponsorInfoCategoryAsAdmin.fulfilled,
        (state, action) => {
          const { payload } = action;
          let updatedInfoDetails = state.infoDetails;
          if (state.infoDetails) {
            const { infoDetails } = state;
            const cats = infoDetails.categories ? infoDetails.categories : [];
            updatedInfoDetails = {
              ...infoDetails,
              categories: [...cats, payload],
            };
          }
          return {
            ...state,
            infoDetails: updatedInfoDetails,
          };
        },
      )
      .addCase(
        updatePblSponsorInfoCategoryAsAdmin.fulfilled,
        (state, action) => {
          const { payload } = action;
          let updatedInfoDetails = state.infoDetails;
          if (state.infoDetails) {
            const { infoDetails } = state;
            const cats = infoDetails.categories ? infoDetails.categories : [];
            updatedInfoDetails = {
              ...infoDetails,
              categories: update(cats, payload),
            };
          }
          return {
            ...state,
            infoDetails: updatedInfoDetails,
          };
        },
      )
      .addCase(
        deletePblSponsorInfoCategoryAsAdmin.fulfilled,
        (state, action) => {
          const deletedId = action.meta.arg;
          let updatedInfoDetails = state.infoDetails;
          if (state.infoDetails) {
            const { infoDetails } = state;
            const cats = infoDetails.categories ? infoDetails.categories : [];
            updatedInfoDetails = {
              ...infoDetails,
              categories: remove(cats, deletedId),
            };
          }
          return {
            ...state,
            infoDetails: updatedInfoDetails,
          };
        },
      )
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          infoDetails: null,
          infoList: [],
          fetched: false,
          fetchedOne: false,
          fetchError: null,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const adminAppPblSponsorInfoState = (
  state: RootState,
): AdminAppPblSponsorInfoState => state.adminApp.pblSponsorInfo;

export default adminAppPblSponsorInfoSlice.reducer;
