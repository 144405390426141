import { Box,Grid, Hidden, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import ChatBox from "./Chat";
import MobileChatDrawer from "./Chat/ChatMobileView";
import useInterviewRequest, { ModalToOpen } from "./hook/useInterviewRequests";
import InterviewRequestsList from "./InterviewRequestsList";
import SelectStatusDropDown from "./InterviewRequestsList/SelectStatusDropDown";
import SendFeedBacksModal from "./Modals/SendFeedBacksAnswerModal";
import SendScheduleProposalModal from "./Modals/SendScheduleProposal";
import StudentHelpMessage from "./StudentHelper";

type Props = {
  handleNotificationModalClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      margin: "0 auto",
      display: "flex",
      flex: 1,
      height: "100%",
      borderTop: `2px solid ${theme.palette.customColor.boardGrey}`,
      [theme.breakpoints.down("md")]: {
        borderTop: "none",
        height: "100%",
      },
    },
    chatContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      padding: theme.spacing(2, 3),
      margin: theme.spacing(2, 0, 3, 0),
      backgroundColor: "white",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 1),
      },
    },
    filterContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  }),
);

const StudentMessages: React.FC<Props> = ({ handleNotificationModalClose }) => {
  const classes = useStyles();

  const {
    states: {
      interviewRequestsList,
      requestsListError,
      isRequestsListFetching,
      isRequestsListSuccess,
      currentStatus,
      currentInterviewRequest,
      messagesList,
      modalState,
      isMobileChatOpen,
      totalInterviewRequestCount,
      interviewRequestDetails,
      feedBacks,
      currentInterviewPage,
      unreadMessages,
    },
    actions: {
      handlePageTransition,
      handleStatusFilter,
      handleSelectInterview,
      refetchMessagesList,
      handleMobileChat,
      handleModalClose,
      handleModalState,
      refetchInterviewRequestDetail,
      refetchInterviewRequestList,
      refetchFeedBacks,
    },
  } = useInterviewRequest();

  return (
    <>
      {modalState.isOpen &&
        modalState.currentModal === ModalToOpen.QUESTIONARY && (
          <SendFeedBacksModal
            requestId={currentInterviewRequest.id}
            isOpen
            refetchFeedBacks={refetchFeedBacks}
            handleModalClose={handleModalClose}
            refetchMessageList={refetchMessagesList}
            refetchInterviewRequestList={refetchInterviewRequestList}
            refetchInterviewRequestDetail={refetchInterviewRequestDetail}
          />
        )}
      {modalState.isOpen &&
        modalState.currentModal === ModalToOpen.SCHEDULE_PROPOSAL && (
          <SendScheduleProposalModal
            requestId={currentInterviewRequest.id}
            isScheduleProposalModalOpen
            refetchInterviewRequestDetail={refetchInterviewRequestDetail}
            refetchMessageList={refetchMessagesList}
            handleModalClose={handleModalClose}
          />
        )}
      <Box className={classes.filterContainer}>
        <SelectStatusDropDown
          currentStatus={currentStatus}
          handleStatusFilter={handleStatusFilter}
        />
        <StudentHelpMessage />
      </Box>
      <Grid container className={classes.mainContainer}>
        <InterviewRequestsList
          interviewRequestsList={interviewRequestsList}
          requestsListError={requestsListError}
          isRequestsListFetching={isRequestsListFetching}
          isRequestsListSuccess={isRequestsListSuccess}
          currentInterviewRequest={interviewRequestDetails}
          totalInterviewRequestCount={totalInterviewRequestCount}
          currentInterviewPage={currentInterviewPage}
          unreadMessages={unreadMessages}
          handleSelectInterview={handleSelectInterview}
          handleMobileChat={handleMobileChat}
          handlePageTransition={handlePageTransition}
        />
        <Hidden mdDown>
          <ChatBox
            messagesList={messagesList}
            currentInterviewRequest={interviewRequestDetails}
            feedBacks={feedBacks}
            refetchMessageList={refetchMessagesList}
            handleModalState={handleModalState}
            handleNotificationModalClose={handleNotificationModalClose}
          />
        </Hidden>
        <MobileChatDrawer
          messagesList={messagesList}
          currentInterviewRequest={currentInterviewRequest}
          isOpen={isMobileChatOpen}
          feedBacks={feedBacks}
          refetchMessageList={refetchMessagesList}
          handleModalState={handleModalState}
          onClose={handleMobileChat}
          handleNotificationModalClose={handleNotificationModalClose}
        />
      </Grid>
    </>
  );
};

export default StudentMessages;
