/* eslint-disable camelcase */
import {
  Api,
  BasicUserInfo,
  Company,
  GetSuperOwnerHomeworkTemplateDistributionHistoriesParams,
  GetSuperOwnerHomeworkTemplateDistributionHistoryAffiliatesParams,
  GetSuperOwnerHomeworkTemplateDistributionHistoryCompaniesParams,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export interface HomeworkTemplateDistributionHistory {
  id: string;
  homework_template_id: string;
  homework_template_distributions_id: string;
  template_name: string;
  distribution_time: string;
  start_at?: string | null;
  end_at?: string | null;
  count_of_users: number;
  count_of_companies: number;
  company_names: { company_id?: string; company_name?: string }[];
  status: string;
}

export const fetchAllHomeworkTemplateHistoriesAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateHistories/fetch",
  async (
    params: GetSuperOwnerHomeworkTemplateDistributionHistoriesParams,

    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.getSuperOwnerHomeworkTemplateDistributionHistories(
          params,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchHomeworkTemplateHistoriesCompanies = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateHistoryCompanies/fetch",
  async (
    params: GetSuperOwnerHomeworkTemplateDistributionHistoryCompaniesParams,

    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.getSuperOwnerHomeworkTemplateDistributionHistoryCompanies(
          {
            homework_template_distribution_history_id:
              params.homework_template_distribution_history_id,
          },
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export interface UserHistoryAffiliation {
  affiliate_id: string;
  company: Company;
  role: { id: string; name: string; jp_name: string };
  user: BasicUserInfo;
}

export const fetchHomeworkTemplateHistoriesUsers = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateHistoryUsers/fetch",
  async (
    params: GetSuperOwnerHomeworkTemplateDistributionHistoryAffiliatesParams,

    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.getSuperOwnerHomeworkTemplateDistributionHistoryAffiliates(
          params,
        );
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "superOwner/homeworkTemplateHistories/resetState",
);
