/* eslint-disable camelcase */
import {
  createUserTaggingAsSuperOwner,
  deleteUserTaggingAsSuperOwner,
  resetUserTaggingState,
} from "@actions/superOwnerApp/userTagging";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@root/store/store";

interface SuperOwnerUserTaggingState {
  totalCount: number;
  taggingCount: number;
  taggingErrors: string[];
}

const initialState: SuperOwnerUserTaggingState = {
  totalCount: 0,
  taggingCount: 0,
  taggingErrors: [],
};

const superOwnerAppUserTaggingSlice = createSlice({
  name: "SuperOwnerApp/UserTagging",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserTaggingAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          taggingCount: 0,
          taggingErrors: [],
        };
      })
      .addCase(createUserTaggingAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          taggingCount: state.taggingCount + 1,
        };
      })
      .addCase(createUserTaggingAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          taggingCount: state.taggingCount + 1,
          taggingErrors: [...state.taggingErrors, action.meta.arg.user_id],
        };
      })
      .addCase(deleteUserTaggingAsSuperOwner.pending, (state, _action) => {
        return {
          ...state,
          taggingCount: 0,
          taggingErrors: [],
        };
      })
      .addCase(deleteUserTaggingAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          taggingCount: state.taggingCount + 1,
        };
      })
      .addCase(deleteUserTaggingAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          taggingCount: state.taggingCount + 1,
          taggingErrors: [...state.taggingErrors, action.meta.arg.user_id],
        };
      })
      .addCase(resetUserTaggingState, (state, _action) => {
        return {
          ...state,
          taggingCount: 0,
          taggingErrors: [],
        };
      });
  },
});

export const superOwnerAppUserTaggingState = (
  state: RootState,
): SuperOwnerUserTaggingState => state.superOwnerApp.userTagging;

export default superOwnerAppUserTaggingSlice.reducer;
