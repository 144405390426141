import { Theme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  InterviewFeedbackBase,
  InterviewRequestDetailBase,
  InterviewRequestListBase2,
} from "@root/endpoints/TimeTactApi/studentApi";
import React from "react";

import { ModalToOpen } from "../../hook/useInterviewRequests";
import ChatBox from "..";
import Header from "./Header";

export interface Props {
  isOpen: boolean;
  currentInterviewRequest: InterviewRequestDetailBase;
  messagesList: InterviewRequestListBase2[];
  feedBacks: InterviewFeedbackBase[];
  refetchMessageList: () => void;
  handleModalState: (modalName: ModalToOpen) => void;
  onClose: () => void;
  handleNotificationModalClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      width: "100%",
    },
    wrapper: {
      boxShadow: "2px 0px 4px 0px rgba(71, 81, 73, 0.1)",
      [theme.breakpoints.down("sm")]: {
        boxShadow: "initial",
      },
    },
  }),
);

const MobileChatDrawer: React.FC<Props> = ({
  messagesList,
  isOpen,
  currentInterviewRequest,
  feedBacks,
  refetchMessageList,
  onClose,
  handleModalState,
  handleNotificationModalClose,
}) => {
  const classes = useStyles();
  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <Header onClose={onClose} />
      <ChatBox
        messagesList={messagesList}
        currentInterviewRequest={currentInterviewRequest}
        feedBacks={feedBacks}
        refetchMessageList={refetchMessageList}
        handleModalState={handleModalState}
        handleNotificationModalClose={handleNotificationModalClose}
      />
    </Drawer>
  );
};

export default MobileChatDrawer;
