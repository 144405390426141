import { enqueueToast } from "@actions/toast";
import { nanoid } from "@reduxjs/toolkit";
import { useAppDispatch } from "@root/hooks";

export type UseToast = {
  actions: {
    showSuccess: (message?: string, toastId?: string | null) => void;
    showError: (message?: string, toastId?: string | null) => void;
    showErrors: (message?: string[]) => void;
    showWarning: (message: string, toastId?: string | null) => void;
    showInfo: (message: string, toastId?: string | null) => void;
  };
};

const useEnqueueToast = (): UseToast => {
  const dispatch = useAppDispatch();
  const showSuccess = (message?: string, toastId: string | null = null) => {
    dispatch(
      enqueueToast({
        message: message || "保存しました。",
        id: toastId || nanoid(),
        options: {
          variant: "success",
        },
      }),
    );
  };

  const showError = (message?: string, toastId: string | null = null) => {
    dispatch(
      enqueueToast({
        message: message || "エラーが発生しました。",
        id: toastId || nanoid(),
        options: {
          variant: "error",
        },
      }),
    );
  };

  const showErrors = (messages?: string[]) => {
    if (messages?.length) {
      messages.forEach((message) => {
        showError(message);
      });
    } else {
      showError();
    }
  };

  const showWarning = (message: string, toastId: string | null = null) => {
    dispatch(
      enqueueToast({
        message,
        id: toastId || nanoid(),
        options: {
          variant: "warning",
        },
      }),
    );
  };

  const showInfo = (message: string, toastId: string | null = null) => {
    dispatch(
      enqueueToast({
        message,
        id: toastId || nanoid(),
        options: {
          variant: "info",
        },
      }),
    );
  };

  return {
    actions: {
      showSuccess,
      showError,
      showErrors,
      showWarning,
      showInfo,
    },
  };
};

export default useEnqueueToast;
