/* eslint-disable camelcase */
import { fetchLearningTemplatePackageDistributedStudent } from "@actions/superOwnerApp/templatePackages/learningTemplateHistory";
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import SelectButton from "@components/UI/molecules/DownloadCsvFileListItem/SelectButton";
import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import { ReduxStatus } from "@constants/redux";
import { DistributedStudents, TtCsvExportType } from "@lib/Api";
import { Box, Container, Theme, Typography } from "@mui/material";
import { superOwnerAppLearningTemplateDistributionHistoryState } from "@reducers/superOwnerApp/templatePackages/learningTemplateHistory";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import React, { useEffect, useMemo } from "react";

import {
  LearningTemplateHistoryModalKind,
  LearningTemplateHistoryModalType,
} from "../../types";

interface Props {
  handleClose: () => void;
  modalState: LearningTemplateHistoryModalType;
}

type GroupedResult = {
  key: string | null;
  users: DistributedStudents[];
}[];

const useStyles = () => ({
  buttonWrapper: (theme: Theme) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    alignItems: "center",
    flexFlow: "wrap",
  }),
});

const DistributionInfosModal: React.FC<Props> = ({
  handleClose,
  modalState,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isOpen, kind, learningTemplatePackage } = modalState;
  const isInfoModalOpen =
    isOpen && kind === LearningTemplateHistoryModalKind.SHOW;
  const distributionId = learningTemplatePackage?.id as string;
  const learningTemplateHistoryCache = useAppSelector(
    superOwnerAppLearningTemplateDistributionHistoryState,
  );
  const { distributedUsers, fetchUser } = learningTemplateHistoryCache;

  const distributedUsersByGroup = useMemo(
    () =>
      distributedUsers.reduce((acc: GroupedResult, user) => {
        const key = user["company_name" as keyof DistributedStudents];
        const keyString = key ?? "なし";
        const existingGroup = acc.find((group) => group.key === keyString);
        if (existingGroup) {
          existingGroup.users.push(user);
        } else {
          acc.push({
            key: keyString,
            users: [user],
          });
        }
        return acc;
      }, []),
    [distributedUsers],
  );

  useEffect(() => {
    if (isInfoModalOpen) {
      dispatch(
        fetchLearningTemplatePackageDistributedStudent({
          id: distributionId,
        }),
      );
    }
  }, [isInfoModalOpen, distributionId]);

  return (
    <GenericModal
      useSecondaryButton
      title="配信先の情報"
      modalSize="sm"
      handleClose={handleClose}
      secondaryButtonAction={handleClose}
      secondaryButtonText="閉じる"
      open={isInfoModalOpen}
    >
      {fetchUser === ReduxStatus.pending ? (
        <CircularLoading />
      ) : (
        <Container>
          <FormSectionWrap>
            <FormCategoryTitle>コンテンツ名</FormCategoryTitle>
            <Box sx={classes.buttonWrapper}>
              <Typography>
                {learningTemplatePackage?.learning_template_package?.title}
              </Typography>
              <SelectButton
                learning_template_package_distribution_setting_id={
                  distributionId
                }
                type={
                  TtCsvExportType.SuperOwnerLearningPackageDistributionHistoryDetail
                }
              />
            </Box>
          </FormSectionWrap>
          <FormSectionWrap>
            <FormCategoryTitle>配信先</FormCategoryTitle>
            {distributedUsersByGroup.map((Group) => (
              <Typography
                key={Group.key}
              >{`${Group.key} (${Group.users.length}人)`}</Typography>
            ))}
          </FormSectionWrap>
        </Container>
      )}
    </GenericModal>
  );
};

export default DistributionInfosModal;
