/* eslint-disable camelcase */
import {
  Api,
  FetchMaterialWorksAsStudentParams,
  MaterialWorkStatus,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchMaterialWorksAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/materialWorks/fetch",
  async (params: FetchMaterialWorksAsStudentParams, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchMaterialWorksAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchOneMaterialWorkAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/materialWorks/fetchOne", async (id: string, { rejectWithValue }) => {
  try {
    const response = await api.student.fetchOneMaterialWorkAsStudent(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export interface UpdateMateriwlWorkAsStudentArg {
  material_work_id: string;
  data: {
    material_works_id: string;
    status: MaterialWorkStatus;
  };
}

export const updateMaterialWorkAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/materialWorks/update",
  async (arg: UpdateMateriwlWorkAsStudentArg, { rejectWithValue }) => {
    const { data, material_work_id } = arg;
    try {
      const response = await api.student.putStudentMaterialWorksMaterialWorkId(
        material_work_id,
        data,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface SaveMaterialWorksImageArg {
  material_work_id: string;
  data: {
    image_content_id: string;
    file: string;
  };
}

export const saveMaterialWorkImageAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/materialWorks/saveImage",
  async (arg: SaveMaterialWorksImageArg, { rejectWithValue }) => {
    const { data, material_work_id } = arg;
    try {
      const response = await api.student.saveEditedMaterialWorkImageAsStudent(
        material_work_id,
        data,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatedMaterialWork = createAction(
  "studentApp/materialWorks/updatedMaterialWork",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const fileUploaded = createAction(
  "studentApp/materialWorks/fileUploaded",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const resetActionState = createAction(
  "studentApp/materialWorks/resetActionState",
);

export const resetModalState = createAction(
  "studentApp/materialWorks/resetModalState",
);
