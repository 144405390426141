/* eslint-disable camelcase */
import {
  MaterialDistributionHistory,
  MaterialDistributionHistoryListStudents,
  MaterialDistributionHistorySearchByUserId,
} from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAllDistributedMaterialWorks,
  fetchDistributedMaterialHistoryByMaterial,
  fetchDistributedSchoolsByMaterial,
  searchDistributedHistoryByUserId,
} from "../../actions/superOwnerApp/materialDistribution";
import { RootState } from "../../store";

export type DistributedStudent =
  MaterialDistributionHistoryListStudents["students"][number];

interface SuperOwnerMaterialDistributionState {
  fetching: boolean;
  fetched: boolean;
  error: any;
  distributedInfo: MaterialDistributionHistory[];
  totalCount: number;
  distributedHistories: MaterialDistributionHistory[];
  distributedHistoriesSearchByUserId: MaterialDistributionHistorySearchByUserId[];
  fetchingStudents: boolean;
  fetchStudentsError: string | null;
  studentsTotalCount: number;
  students: DistributedStudent[];
}

const initialState: SuperOwnerMaterialDistributionState = {
  fetching: false,
  fetched: false,
  error: null,
  distributedInfo: [],
  totalCount: 0,
  distributedHistories: [],
  distributedHistoriesSearchByUserId: [],
  fetchingStudents: false,
  fetchStudentsError: null,
  studentsTotalCount: 0,
  students: [],
};

export const superOwnerAppMaterialDistributionSlice = createSlice({
  name: "SuperOwnerApp/MaterialDistribution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDistributedMaterialWorks.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null,
        };
      })
      .addCase(fetchAllDistributedMaterialWorks.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          distributedHistories: action.payload.material_distribution_histories,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchAllDistributedMaterialWorks.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: "データが取得できませんでした。",
          distributedHistories: [],
        };
      })
      .addCase(searchDistributedHistoryByUserId.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null,
        };
      })
      .addCase(searchDistributedHistoryByUserId.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          distributedHistoriesSearchByUserId:
            action.payload.material_distribution_histories,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(searchDistributedHistoryByUserId.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: "データが取得できませんでした。",
          distributedHistoriesSearchByUserId: [],
        };
      })
      .addCase(fetchDistributedSchoolsByMaterial.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null,
        };
      })
      .addCase(fetchDistributedSchoolsByMaterial.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          distributedInfo: action.payload.material_distribution_histories,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchDistributedSchoolsByMaterial.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          error: "データが取得できませんでした。",
        };
      })
      .addCase(
        fetchDistributedMaterialHistoryByMaterial.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingStudents: true,
            students: [],
            fetchStudentsError: null,
            studentsTotalCount: 0,
          };
        },
      )
      .addCase(
        fetchDistributedMaterialHistoryByMaterial.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetchingStudents: false,
            students: action.payload.students,
            studentsTotalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        fetchDistributedMaterialHistoryByMaterial.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingStudents: false,
            fetchStudentsError: "データが取得できませんでした。",
          };
        },
      );
  },
});

export const superOwnerAppMaterialDistributionState = (
  state: RootState,
): SuperOwnerMaterialDistributionState =>
  state.superOwnerApp.materialDistribution;

export default superOwnerAppMaterialDistributionSlice.reducer;
