/* eslint-disable camelcase */
import {
  getSteamLibraryContentDetail,
  getSteamLibraryLecturesNumber,
} from "@actions/steamLibrary";
import { SteamLibraryContentCard } from "@actions/types/steamLibrary";
import { Api, GetSteamContentBookmarksAsTeacherParams } from "@lib/Api";
import { TeacherAppSteamContentBookmarkState } from "@reducers/teacherApp/steam/contentBookmark";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import pLimit from "p-limit";

import { getSteamContentsDetailsAsTeacher } from "./content";

const api = new Api();

export const getAllSteamContentBookmarksAsTeacher = createAsyncThunk<
  any,
  GetSteamContentBookmarksAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/content/getBookmarks",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getSteamContentBookmarksAsTeacher({});
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getContentsAndBookmarksAsTeacher = createAsyncThunk<
  any,
  GetSteamContentBookmarksAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/content/getAllBookmarks",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await api.teacher.getSteamContentBookmarksAsTeacher(params);

      const { bookmarks } = response.data;
      let bookmarkedContents: SteamLibraryContentCard[] = [];
      if (bookmarks.length !== 0) {
        const contentIds = bookmarks.map((bookmark) => bookmark.sl_content_id);
        await dispatch(getSteamContentsDetailsAsTeacher(contentIds))
          .unwrap()
          .then(
            (
              contents: TeacherAppSteamContentBookmarkState["bookmarkedContents"],
            ) => {
              bookmarkedContents = contents;
            },
          );
      }

      return { ...response.data, bookmarkedContents };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getContentsAndBookmarksWithLectureNumberAsTeacher =
  createAsyncThunk<
    any,
    GetSteamContentBookmarksAsTeacherParams,
    { state: RootState; rejectValue: any; rejectedMeta: void }
  >(
    "teacher/steamLibrary/content/getAllBookmarksWithLectureNumber",
    async (params, { rejectWithValue, dispatch }) => {
      try {
        const response = await dispatch(
          getContentsAndBookmarksAsTeacher(params),
        ).unwrap();
        const { bookmarkedContents } = response;
        const results = await Promise.all(
          bookmarkedContents.map(
            (
              bookmarkedContent: TeacherAppSteamContentBookmarkState["bookmarkedContents"][number],
            ) => {
              return pLimit(1)(() =>
                dispatch(
                  getSteamLibraryContentDetail(
                    bookmarkedContent?.sl_content_id as number,
                  ),
                ),
              );
            },
          ),
        );

        const lecturesWithNumbers = await Promise.all(
          bookmarkedContents.map(
            (
              bookmarkedContent: TeacherAppSteamContentBookmarkState["bookmarkedContents"][number],
            ) => {
              return pLimit(1)(() =>
                dispatch(
                  getSteamLibraryLecturesNumber(
                    bookmarkedContent?.sl_content_id as number,
                  ),
                ),
              );
            },
          ),
        );

        const withLectureNum = results
          .filter(
            (result) =>
              !!bookmarkedContents.find(
                (
                  bookmarkedContent: TeacherAppSteamContentBookmarkState["bookmarkedContents"][number],
                ) => bookmarkedContent.sl_content_id === result?.meta?.arg,
              ),
          )
          .map((result) => {
            const item = bookmarkedContents.find(
              (
                bookmarkedContent: TeacherAppSteamContentBookmarkState["bookmarkedContents"][number],
              ) => bookmarkedContent.sl_content_id === result?.meta?.arg,
            );

            const numberOfLectures = lecturesWithNumbers.find(
              (content) => content.payload.contents[0].id === result?.meta?.arg,
            );

            return {
              ...item,
              lecture_number:
                numberOfLectures.payload.contents[0].number_of_lectures,
            };
          });

        return {
          ...response,
          bookmarkedContentsWithLectureNumber: withLectureNum,
        };
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    },
  );

export const postSteamContentBookmarksAsTeacher = createAsyncThunk<
  any,
  {
    pblSteamContentId: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/content/postBookmark",
  async (params, { rejectWithValue, getState }) => {
    try {
      const { pblSteamContentId } = params;
      const response = await api.teacher.postSteamContentBookmarksAsTeacher({
        pbl_steam_content_id:
          pblSteamContentId || getState().teacherApp.steamLecture.content.id,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSteamContentBookmarksAsTeacher = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/content/deleteBookmark",
  async (contentId, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.deleteSteamContentBookmarksAsTeacher(contentId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetSteamContentBookmarksState = createAction(
  "teacher/steamLibrary/content/resetBookmarkState",
);
