import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import { HomeworkTemplateBaseForSuperOwner } from "@lib/Api";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { coPackageType } from "..";
import HandleChecked from "./HandleChecked";
import HandleDisable from "./HandleDisable";

interface Props {
  templates: HomeworkTemplateBaseForSuperOwner[];
  handleChosePackage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  chosenPackage: string[] | coPackageType[];
  status: ModalStatus;
  coPackage?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      height: 400,
      width: "100%",
      borderTop: `2px solid ${theme.palette.learningTemplatePackage.lightGrey}`,
    },
    items: {
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      overflow: "scroll",
      height: "100%",
    },
    title: {
      backgroundColor: theme.palette.learningTemplatePackage.main,
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      textAlign: "center",
    },
  }),
);

const HomeWorkTemplateList: React.FC<Props> = ({
  templates,
  chosenPackage,
  handleChosePackage,
  status,
  coPackage,
}) => {
  const classes = useStyles();

  const templatesWithoutIdeal = templates.filter(
    (template) => !template.for_group,
  );
  return (
    <Grid container className={classes.main}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.items}>
        <Typography className={classes.title}>提出物一覧</Typography>
        {templatesWithoutIdeal.length ? (
          templatesWithoutIdeal.map((template) => {
            return (
              <FormControlLabel
                key={template.id}
                control={
                  <Checkbox
                    value={template.id}
                    disabled={HandleDisable(
                      template,
                      chosenPackage,
                      status,
                      coPackage,
                    )}
                    checked={HandleChecked(template, chosenPackage, coPackage)}
                    onChange={handleChosePackage}
                  />
                }
                label={template.title}
              />
            );
          })
        ) : (
          <Box m={2}>
            <Typography>データが見つかりませんでした。</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

HomeWorkTemplateList.defaultProps = {
  coPackage: undefined,
};

export default HomeWorkTemplateList;
