import { baseApi as api } from "../baseApi";
export const addTagTypes = [
  "Owner/Affiliate",
  "CategoryRenameMap",
  "Owner/CategoryRenameMap",
  "Owner/Categories",
  "Categories",
  "Owner/Images",
  "Owner/Videos",
  "Owner/MaterialWork",
  "Users",
  "Owner/User",
  "Owner/Student",
  "Owner/Students",
  "Companies",
  "Owner/Company",
  "Owner/ReviewerCompanyGroup",
  "Owner/Materials",
  "materials",
  "Owner/Material",
  "Materials",
  "Owner/MaterialsTickets",
  "Report",
  "Payment",
  "Owner/Homework",
  "Homework",
  "Owner/HomeworkTemplate",
  "HomeworkTemplate",
  "Owner/HomeworkTemplateCustomFieldFeedbacks",
  "OwnerEmails",
  "Owner/Notification",
  "Owner/Article",
  "articles",
  "Owner/AffiliateInvitation",
  "AffiliateInvitation",
  "Owner/UserTagging",
  "Owner/UserTagType",
  "Owner/UserTag",
  "UserGroup",
  "Owner/UserGroup",
  "UserGroups",
  "Owner/Reservation",
  "Owner/UserTags",
  "Owner/UserTag/User",
  "Owner/UserTag/Student",
  "Owner/HomeworkTemplateCustomFields",
  "Owner/HomeworkTemplateCustomField",
  "HomeworkTemplateDistribution",
  "Owner/HomeworkTemplateDistribution",
  "Owner/HomeworkTemplateImage",
  "Owner/MaterialDistributionHistory",
  "Owner/Pbl/Report/TemplateFrameworks",
  "Owner/Pbl/Report/TemplateItemFrameworks",
  "Owner/Inquiries",
  "Owner/InquiryComments",
  "Owner/LearningTemplatePackageDistributions",
  "Owner/LearningPackages",
  "Owner/LearningTemplatePackageDistributionHistories",
  "Owner/PblBoardInfos",
  "Owner/PblBoardItems",
  "Owner/PblBoardCampaigns",
  "Owner/PblBoardCampaignRequests",
  "Owner/PblBoardArticles",
  "Owner/PblBoardContents",
  "Owner/PblBoardFooters",
  "Owner/PblBoardFooterItems",
  "Owner/AttachedImages",
  "Owner/LimitedTimeNotifications",
  "Owner/TtCsvExports",
  "Owner/TtCategoryBases",
  "Owner/DistributionStatus",
  "Owner/CommentImages",
  "Owner/Interview/Request",
  "Owner/Interview/Message",
  "Owner/Interview/Feedback",
  "Owner/Sponsor/Projects",
  "Owner/GroupAffiliatePackages",
  "Owner/Packaging",
  "Owner/HomeworkTemplateCustomValues",
  "Owner/CommonTags",
  "Owner/CommonTaggings",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      affiliatesUpdateAsOwner: build.mutation<
        AffiliatesUpdateAsOwnerApiResponse,
        AffiliatesUpdateAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/affiliates/bulk_update`,
          method: "PUT",
          body: queryArg.bulkUpdateRequestBody,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/Affiliate"],
      }),
      updateCategoryRenameMapAsOwner: build.mutation<
        UpdateCategoryRenameMapAsOwnerApiResponse,
        UpdateCategoryRenameMapAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/category_rename_maps/update`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["CategoryRenameMap", "Owner/CategoryRenameMap"],
      }),
      getOwnerCategories: build.query<
        GetOwnerCategoriesApiResponse,
        GetOwnerCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/categories`,
          params: { from_super_owner: queryArg.fromSuperOwner },
        }),
        providesTags: ["Owner/Categories", "Categories"],
      }),
      postOwnerCategories: build.mutation<
        PostOwnerCategoriesApiResponse,
        PostOwnerCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/categories`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/Categories"],
      }),
      putOwnerCategoriesById: build.mutation<
        PutOwnerCategoriesByIdApiResponse,
        PutOwnerCategoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/categories/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putCategoryRequestBodyAsOwner,
        }),
        invalidatesTags: ["Owner/Categories"],
      }),
      deleteOwnerCategoriesById: build.mutation<
        DeleteOwnerCategoriesByIdApiResponse,
        DeleteOwnerCategoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/categories/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Owner/Categories"],
      }),
      postOwnerImageContents: build.mutation<
        PostOwnerImageContentsApiResponse,
        PostOwnerImageContentsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/image_contents`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/Images"],
      }),
      deleteOwnerImageContentsById: build.mutation<
        DeleteOwnerImageContentsByIdApiResponse,
        DeleteOwnerImageContentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/image_contents/${queryArg.id}`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/Images"],
      }),
      getOwnerImageContentsById: build.query<
        GetOwnerImageContentsByIdApiResponse,
        GetOwnerImageContentsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/owner/image_contents/${queryArg.id}` }),
        providesTags: ["Owner/Images"],
      }),
      postOwnerVideoContents: build.mutation<
        PostOwnerVideoContentsApiResponse,
        PostOwnerVideoContentsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/video_contents`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/Videos"],
      }),
      deleteOwnerVideoContentsById: build.mutation<
        DeleteOwnerVideoContentsByIdApiResponse,
        DeleteOwnerVideoContentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/video_contents/${queryArg.id}`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/Videos"],
      }),
      getOwnerMaterialWorks: build.query<
        GetOwnerMaterialWorksApiResponse,
        GetOwnerMaterialWorksApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/material_works`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            user_id: queryArg.userId,
            material_ids: queryArg.materialIds,
            per_page: queryArg.perPage,
            page: queryArg.page,
            role: queryArg.role,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Owner/MaterialWork"],
      }),
      batchCreateMaterialWorksAsOwner: build.mutation<
        BatchCreateMaterialWorksAsOwnerApiResponse,
        BatchCreateMaterialWorksAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/material_works/batch_create`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/MaterialWork"],
      }),
      patchOwnerMaterialWorksBatchDelete: build.mutation<
        PatchOwnerMaterialWorksBatchDeleteApiResponse,
        PatchOwnerMaterialWorksBatchDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/material_works/batch_delete`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/MaterialWork"],
      }),
      getOwnerMaterialWorksByMaterialWorkId: build.query<
        GetOwnerMaterialWorksByMaterialWorkIdApiResponse,
        GetOwnerMaterialWorksByMaterialWorkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/material_works/${queryArg.materialWorkId}`,
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Owner/MaterialWork"],
      }),
      putOwnerMaterialWorksByMaterialWorkId: build.mutation<
        PutOwnerMaterialWorksByMaterialWorkIdApiResponse,
        PutOwnerMaterialWorksByMaterialWorkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/material_works/${queryArg.materialWorkId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/MaterialWork"],
      }),
      getOwnerUsersTeachers: build.query<
        GetOwnerUsersTeachersApiResponse,
        GetOwnerUsersTeachersApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/teachers`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            search: queryArg.search,
            email: queryArg.email,
            search_statuses: queryArg.searchStatuses,
            per_page: queryArg.perPage,
            page: queryArg.page,
            is_min_info: queryArg.isMinInfo,
          },
        }),
        providesTags: ["Users"],
      }),
      fetchTeachersForSelectAsOwner: build.query<
        FetchTeachersForSelectAsOwnerApiResponse,
        FetchTeachersForSelectAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/teachers/for_select`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            search: queryArg.search,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["Users"],
      }),
      getOwnerUsersTeachersById: build.query<
        GetOwnerUsersTeachersByIdApiResponse,
        GetOwnerUsersTeachersByIdApiArg
      >({
        query: (queryArg) => ({ url: `/owner/users/teachers/${queryArg.id}` }),
        providesTags: ["Users"],
      }),
      putTeachersProgressUpdateAsOwner: build.mutation<
        PutTeachersProgressUpdateAsOwnerApiResponse,
        PutTeachersProgressUpdateAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/teachers/progress_update/${queryArg.id}`,
          method: "PUT",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Users"],
      }),
      getOwnerUsersStudents: build.query<
        GetOwnerUsersStudentsApiResponse,
        GetOwnerUsersStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/students`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            with_material_work: queryArg.withMaterialWork,
            material_id: queryArg.materialId,
            search: queryArg.search,
            email: queryArg.email,
            sorted_by: queryArg.sortedBy,
            grade: queryArg.grade,
            grades: queryArg.grades,
            parent_name: queryArg.parentName,
            search_statuses: queryArg.searchStatuses,
            user_tag: queryArg.userTag,
            user_tag_ids: queryArg.userTagIds,
            tag_filter: queryArg.tagFilter,
            homework_template_id: queryArg.homeworkTemplateId,
            filter_material_id: queryArg.filterMaterialId,
            distribution: queryArg.distribution,
            per_page: queryArg.perPage,
            page: queryArg.page,
            group_affiliate_package_id: queryArg.groupAffiliatePackageId,
            min: queryArg.min,
            group_affiliate_package_ids: queryArg.groupAffiliatePackageIds,
            nickname: queryArg.nickname,
          },
        }),
        providesTags: ["Owner/User"],
      }),
      getOwnerUsersStudentsListGrade: build.query<
        GetOwnerUsersStudentsListGradeApiResponse,
        GetOwnerUsersStudentsListGradeApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/students/list_grade`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            homework_template_id: queryArg.homeworkTemplateId,
            pbl_report_template_id: queryArg.pblReportTemplateId,
            filter_material_id: queryArg.filterMaterialId,
            distribution: queryArg.distribution,
          },
        }),
        providesTags: ["Owner/Student"],
      }),
      getStudentsUserTagsAsOwner: build.query<
        GetStudentsUserTagsAsOwnerApiResponse,
        GetStudentsUserTagsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/students/${queryArg.id}/user_tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/Students"],
      }),
      getStudentsGroupAffiliatesAsOwner: build.query<
        GetStudentsGroupAffiliatesAsOwnerApiResponse,
        GetStudentsGroupAffiliatesAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/students/${queryArg.id}/group_affiliates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/Students"],
      }),
      getOwnerUsersOwners: build.query<
        GetOwnerUsersOwnersApiResponse,
        GetOwnerUsersOwnersApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/owners`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            search: queryArg.search,
            email: queryArg.email,
            per_page: queryArg.perPage,
            page: queryArg.page,
            search_statuses: queryArg.searchStatuses,
          },
        }),
        providesTags: ["Users"],
      }),
      getOwnerUsersByUserId: build.query<
        GetOwnerUsersByUserIdApiResponse,
        GetOwnerUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/${queryArg.userId}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Users"],
      }),
      postOwnerUsersByUserId: build.mutation<
        PostOwnerUsersByUserIdApiResponse,
        PostOwnerUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/${queryArg.userId}`,
          method: "POST",
        }),
        invalidatesTags: ["Users"],
      }),
      deleteOwnerUsersByUserId: build.mutation<
        DeleteOwnerUsersByUserIdApiResponse,
        DeleteOwnerUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/${queryArg.userId}`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Users"],
      }),
      putOwnerUsersByUserId: build.mutation<
        PutOwnerUsersByUserIdApiResponse,
        PutOwnerUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/${queryArg.userId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Users"],
      }),
      getOwnerCompaniesByCompanyId: build.query<
        GetOwnerCompaniesByCompanyIdApiResponse,
        GetOwnerCompaniesByCompanyIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/companies/${queryArg.companyId}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Companies"],
      }),
      putOwnerCompaniesByCompanyId: build.mutation<
        PutOwnerCompaniesByCompanyIdApiResponse,
        PutOwnerCompaniesByCompanyIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/companies/${queryArg.companyId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Companies"],
      }),
      getCompanyLimitOptionAsOwner: build.query<
        GetCompanyLimitOptionAsOwnerApiResponse,
        GetCompanyLimitOptionAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/companies/${queryArg.id}/limit_option`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/Company"],
      }),
      getOwnerCompaniesByIdMaterialCapacity: build.query<
        GetOwnerCompaniesByIdMaterialCapacityApiResponse,
        GetOwnerCompaniesByIdMaterialCapacityApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/companies/${queryArg.id}/material_capacity`,
        }),
        providesTags: ["Companies"],
      }),
      getReviewerCompanyGroupControllersAsOwner: build.query<
        GetReviewerCompanyGroupControllersAsOwnerApiResponse,
        GetReviewerCompanyGroupControllersAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/reviewer_company_groups`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/ReviewerCompanyGroup"],
      }),
      postOwnerUsers: build.mutation<
        PostOwnerUsersApiResponse,
        PostOwnerUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Users"],
      }),
      createAccountUserAsOwner: build.mutation<
        CreateAccountUserAsOwnerApiResponse,
        CreateAccountUserAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/create_account_user`,
          method: "POST",
          body: queryArg.createAccountUserRequestBody,
        }),
        invalidatesTags: ["Owner/User"],
      }),
      createAccountAvailableAsOwner: build.mutation<
        CreateAccountAvailableAsOwnerApiResponse,
        CreateAccountAvailableAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/account_available`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/User"],
      }),
      resetPasswordAsOwner: build.mutation<
        ResetPasswordAsOwnerApiResponse,
        ResetPasswordAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/${queryArg.id}/reset_password`,
          method: "PUT",
          body: queryArg.authorizationPasswordRequestBody,
        }),
        invalidatesTags: ["Owner/User"],
      }),
      accountUnlockAsOwner: build.mutation<
        AccountUnlockAsOwnerApiResponse,
        AccountUnlockAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/bulk_unlock_access`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/User"],
      }),
      accountStopAsOwner: build.mutation<
        AccountStopAsOwnerApiResponse,
        AccountStopAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/${queryArg.id}/account_stop`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/User"],
      }),
      patchOwnerUsersBatchCreate: build.mutation<
        PatchOwnerUsersBatchCreateApiResponse,
        PatchOwnerUsersBatchCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/batch_create`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Users"],
      }),
      getOwnerMaterials: build.query<
        GetOwnerMaterialsApiResponse,
        GetOwnerMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials`,
          params: {
            category_id: queryArg.categoryId,
            with_material_work: queryArg.withMaterialWork,
            user_id: queryArg.userId,
            role: queryArg.role,
            per_page: queryArg.perPage,
            page: queryArg.page,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Owner/Materials", "materials"],
      }),
      postOwnerMaterials: build.mutation<
        PostOwnerMaterialsApiResponse,
        PostOwnerMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials`,
          method: "POST",
          body: queryArg.createMaterialRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["materials"],
      }),
      getOwnerMaterialsById: build.query<
        GetOwnerMaterialsByIdApiResponse,
        GetOwnerMaterialsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["materials"],
      }),
      putOwnerMaterialsById: build.mutation<
        PutOwnerMaterialsByIdApiResponse,
        PutOwnerMaterialsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateMaterialRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["materials"],
      }),
      deleteOwnerMaterialsById: build.mutation<
        DeleteOwnerMaterialsByIdApiResponse,
        DeleteOwnerMaterialsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials/${queryArg.id}`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["materials"],
      }),
      getOwnerMaterialsByMaterialIdTargetUsers: build.query<
        GetOwnerMaterialsByMaterialIdTargetUsersApiResponse,
        GetOwnerMaterialsByMaterialIdTargetUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials/${queryArg.materialId}/target_users`,
          params: { user_tag_ids: queryArg.userTagIds, grade: queryArg.grade },
        }),
        providesTags: ["Owner/Material"],
      }),
      putOwnerMaterialsSequence: build.mutation<
        PutOwnerMaterialsSequenceApiResponse,
        PutOwnerMaterialsSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials/sequence`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/Materials"],
      }),
      getOwnerMaterialsAllContainedCategory: build.query<
        GetOwnerMaterialsAllContainedCategoryApiResponse,
        GetOwnerMaterialsAllContainedCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials/all_contained_category`,
          params: {
            category_ids: queryArg.categoryIds,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["Owner/Material"],
      }),
      getOwnerMaterialsFromSuperOwners: build.query<
        GetOwnerMaterialsFromSuperOwnersApiResponse,
        GetOwnerMaterialsFromSuperOwnersApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials/from_super_owners`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            category_ids: queryArg.categoryIds,
            tag_names: queryArg.tagNames,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            search_column: queryArg.searchColumn,
            search_order: queryArg.searchOrder,
          },
        }),
        providesTags: ["Materials"],
      }),
      postOwnerMaterialsTickets: build.mutation<
        PostOwnerMaterialsTicketsApiResponse,
        PostOwnerMaterialsTicketsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials_tickets`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/MaterialsTickets"],
      }),
      putOwnerMaterialsTicketsAccept: build.mutation<
        PutOwnerMaterialsTicketsAcceptApiResponse,
        PutOwnerMaterialsTicketsAcceptApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/materials_tickets/accept`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/MaterialsTickets"],
      }),
      getOwnerUsersByIdMaterialWorks: build.query<
        GetOwnerUsersByIdMaterialWorksApiResponse,
        GetOwnerUsersByIdMaterialWorksApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/users/${queryArg.id}/material_works`,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Users"],
      }),
      getOwnerReports: build.query<
        GetOwnerReportsApiResponse,
        GetOwnerReportsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/reports`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            teacher_name: queryArg.teacherName,
            student_name: queryArg.studentName,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ["Report"],
      }),
      getOwnerReportsByReportId: build.query<
        GetOwnerReportsByReportIdApiResponse,
        GetOwnerReportsByReportIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/reports/${queryArg.reportId}`,
          headers: {
            "access-token": queryArg["access-token"],
            clinet: queryArg.clinet,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Report"],
      }),
      putOwnerReportsByReportId: build.mutation<
        PutOwnerReportsByReportIdApiResponse,
        PutOwnerReportsByReportIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/reports/${queryArg.reportId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Report"],
      }),
      deleteOwnerReportsByReportId: build.mutation<
        DeleteOwnerReportsByReportIdApiResponse,
        DeleteOwnerReportsByReportIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/reports/${queryArg.reportId}`,
          method: "DELETE",
        }),
      }),
      getOwnerPayments: build.query<
        GetOwnerPaymentsApiResponse,
        GetOwnerPaymentsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/payments`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            teacher_name: queryArg.teacherName,
            student_name: queryArg.studentName,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["Payment"],
      }),
      getOwnerPaymentsByPaymentId: build.query<
        GetOwnerPaymentsByPaymentIdApiResponse,
        GetOwnerPaymentsByPaymentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/payments/${queryArg.paymentId}`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Payment"],
      }),
      putOwnerPaymentsByPaymentId: build.mutation<
        PutOwnerPaymentsByPaymentIdApiResponse,
        PutOwnerPaymentsByPaymentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/payments/${queryArg.paymentId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Payment"],
      }),
      getOwnerHomeworks: build.query<
        GetOwnerHomeworksApiResponse,
        GetOwnerHomeworksApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            template_id: queryArg.templateId,
            teacher_name: queryArg.teacherName,
            student_name: queryArg.studentName,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            statuses: queryArg.statuses,
            order_type: queryArg.orderType,
            sort_type: queryArg.sortType,
          },
        }),
        providesTags: ["Owner/Homework"],
      }),
      getOwnerHomeworksByHomeworkId: build.query<
        GetOwnerHomeworksByHomeworkIdApiResponse,
        GetOwnerHomeworksByHomeworkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/${queryArg.homeworkId}`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
        }),
        providesTags: ["Owner/Homework"],
      }),
      updateHomeworkAsOwner: build.mutation<
        UpdateHomeworkAsOwnerApiResponse,
        UpdateHomeworkAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/${queryArg.homeworkId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Homework", "Owner/Homework"],
      }),
      uploadHomeworkImagesAsOwner: build.mutation<
        UploadHomeworkImagesAsOwnerApiResponse,
        UploadHomeworkImagesAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/${queryArg.homeworkId}/upload_images`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Owner/HomeworkTemplate",
          "Owner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      saveEditedHomeworkImageAsOwner: build.mutation<
        SaveEditedHomeworkImageAsOwnerApiResponse,
        SaveEditedHomeworkImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/${queryArg.homeworkId}/save_image`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Owner/HomeworkTemplate",
          "Owner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      updateHomeworkImageAsOwner: build.mutation<
        UpdateHomeworkImageAsOwnerApiResponse,
        UpdateHomeworkImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/${queryArg.homeworkId}/update_image`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Owner/HomeworkTemplate",
          "Owner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      deleteHomeworkImageAsOwner: build.mutation<
        DeleteHomeworkImageAsOwnerApiResponse,
        DeleteHomeworkImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/${queryArg.homeworkId}/delete_image`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Owner/HomeworkTemplate",
          "Owner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      batchCreateHomeworksAsOwner: build.mutation<
        BatchCreateHomeworksAsOwnerApiResponse,
        BatchCreateHomeworksAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/batch_create`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/Homework", "Homework"],
      }),
      batchUpdateHomeworksAsOwner: build.mutation<
        BatchUpdateHomeworksAsOwnerApiResponse,
        BatchUpdateHomeworksAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/batch_update`,
          method: "PATCH",
          body: queryArg.batchUpdateHomeworkRequest,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/Homework", "Homework"],
      }),
      batchDeleteHomeworksAsOwner: build.mutation<
        BatchDeleteHomeworksAsOwnerApiResponse,
        BatchDeleteHomeworksAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/batch_delete`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Homework",
          "HomeworkTemplate",
          "Owner/Homework",
          "Owner/HomeworkTemplate",
        ],
      }),
      getHomeworkImageDownloadUrlsAsOwner: build.query<
        GetHomeworkImageDownloadUrlsAsOwnerApiResponse,
        GetHomeworkImageDownloadUrlsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homeworks/get_download_urls`,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
          params: {
            homework_ids: queryArg.homeworkIds,
            file_content: queryArg.fileContent,
            template_id: queryArg.templateId,
            order_type: queryArg.orderType,
            sort_type: queryArg.sortType,
          },
        }),
        providesTags: ["Homework"],
      }),
      postHomeworkTemplateCustomFieldFeedbackAsOwner: build.mutation<
        PostHomeworkTemplateCustomFieldFeedbackAsOwnerApiResponse,
        PostHomeworkTemplateCustomFieldFeedbackAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_template_custom_fields/${queryArg.homeworkTemplateCustomFieldId}/feedbacks`,
          method: "POST",
          body: queryArg.customFieldFeedbackRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/HomeworkTemplateCustomFieldFeedbacks"],
      }),
      putHomeworkTemplateCustomFieldFeedbacksAsOwner: build.mutation<
        PutHomeworkTemplateCustomFieldFeedbacksAsOwnerApiResponse,
        PutHomeworkTemplateCustomFieldFeedbacksAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_template_custom_fields/${queryArg.homeworkTemplateCustomFieldId}/feedbacks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putCustomFieldFeedbackRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/HomeworkTemplateCustomFieldFeedbacks"],
      }),
      deleteHomeworkTemplateCustomFieldFeedbacksAsOwner: build.mutation<
        DeleteHomeworkTemplateCustomFieldFeedbacksAsOwnerApiResponse,
        DeleteHomeworkTemplateCustomFieldFeedbacksAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_template_custom_fields/${queryArg.homeworkTemplateCustomFieldId}/feedbacks/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/HomeworkTemplateCustomFieldFeedbacks"],
      }),
      getOwnerEmails: build.query<
        GetOwnerEmailsApiResponse,
        GetOwnerEmailsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/emails`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["OwnerEmails"],
      }),
      postOwnerEmails: build.mutation<
        PostOwnerEmailsApiResponse,
        PostOwnerEmailsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/emails`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["OwnerEmails"],
      }),
      getOwnerEmailsByOwnerEmailId: build.query<
        GetOwnerEmailsByOwnerEmailIdApiResponse,
        GetOwnerEmailsByOwnerEmailIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/emails/${queryArg.ownerEmailId}`,
        }),
        providesTags: ["OwnerEmails"],
      }),
      putOwnerCategoriesSequence: build.mutation<
        PutOwnerCategoriesSequenceApiResponse,
        PutOwnerCategoriesSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/categories/sequence`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/Categories"],
      }),
      getOwnerNotifications: build.query<
        GetOwnerNotificationsApiResponse,
        GetOwnerNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/notifications`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            sent: queryArg.sent,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["Owner/Notification"],
      }),
      createNotificationAsOwner: build.mutation<
        CreateNotificationAsOwnerApiResponse,
        CreateNotificationAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/notifications`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/Notification"],
      }),
      getOwnerNotificationsById: build.query<
        GetOwnerNotificationsByIdApiResponse,
        GetOwnerNotificationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/notifications/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/Notification"],
      }),
      putOwnerNotificationsById: build.mutation<
        PutOwnerNotificationsByIdApiResponse,
        PutOwnerNotificationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/notifications/${queryArg.id}`,
          method: "PUT",
          body: queryArg.notificationRequest,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Notification"],
      }),
      deleteOwnerNotificationsById: build.mutation<
        DeleteOwnerNotificationsByIdApiResponse,
        DeleteOwnerNotificationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/notifications/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Notification"],
      }),
      getOwnerArticlesByArticleId: build.query<
        GetOwnerArticlesByArticleIdApiResponse,
        GetOwnerArticlesByArticleIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/articles/${queryArg.articleId}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Owner/Article", "articles"],
      }),
      getOwnerArticles: build.query<
        GetOwnerArticlesApiResponse,
        GetOwnerArticlesApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/articles`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            group_affiliate_package_id: queryArg.groupAffiliatePackageId,
            group_affiliate_package_ids: queryArg.groupAffiliatePackageIds,
            status: queryArg.status,
            kind: queryArg.kind,
          },
        }),
        providesTags: ["Owner/Article"],
      }),
      fetchArticleWordCloudAsOwner: build.query<
        FetchArticleWordCloudAsOwnerApiResponse,
        FetchArticleWordCloudAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/articles/word_cloud`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            list_len: queryArg.listLen,
            offset: queryArg.offset,
            min_num_of_char: queryArg.minNumOfChar,
          },
        }),
        providesTags: ["Owner/Article"],
      }),
      getOwnerArticlesByArticleIdFeedbacks: build.query<
        GetOwnerArticlesByArticleIdFeedbacksApiResponse,
        GetOwnerArticlesByArticleIdFeedbacksApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/articles/${queryArg.articleId}/feedbacks`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Owner/Article"],
      }),
      getOwnerArticlesByArticleIdComments: build.query<
        GetOwnerArticlesByArticleIdCommentsApiResponse,
        GetOwnerArticlesByArticleIdCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/articles/${queryArg.articleId}/comments`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Owner/Article"],
      }),
      fetchAffiliateInvitationsAsOwner: build.query<
        FetchAffiliateInvitationsAsOwnerApiResponse,
        FetchAffiliateInvitationsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/affiliate_invitations`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { role: queryArg.role },
        }),
        providesTags: ["Owner/AffiliateInvitation"],
      }),
      getOwnerAffiliateInvitationsById: build.query<
        GetOwnerAffiliateInvitationsByIdApiResponse,
        GetOwnerAffiliateInvitationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/affiliate_invitations/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Owner/AffiliateInvitation", "AffiliateInvitation"],
      }),
      putOwnerAffiliateInvitationsById: build.mutation<
        PutOwnerAffiliateInvitationsByIdApiResponse,
        PutOwnerAffiliateInvitationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/affiliate_invitations/${queryArg.id}`,
          method: "PUT",
        }),
      }),
      putOwnerAffiliateInvitationsActivate: build.mutation<
        PutOwnerAffiliateInvitationsActivateApiResponse,
        PutOwnerAffiliateInvitationsActivateApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/affiliate_invitations/activate`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/AffiliateInvitation"],
      }),
      putOwnerAffiliateInvitationsByIdInactivate: build.mutation<
        PutOwnerAffiliateInvitationsByIdInactivateApiResponse,
        PutOwnerAffiliateInvitationsByIdInactivateApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/affiliate_invitations/${queryArg.id}/inactivate`,
          method: "PUT",
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["AffiliateInvitation"],
      }),
      putOwnerAffiliateInvitationsByIdReactivate: build.mutation<
        PutOwnerAffiliateInvitationsByIdReactivateApiResponse,
        PutOwnerAffiliateInvitationsByIdReactivateApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/affiliate_invitations/${queryArg.id}/reactivate`,
          method: "PUT",
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["AffiliateInvitation"],
      }),
      postOwnerUserTaggings: build.mutation<
        PostOwnerUserTaggingsApiResponse,
        PostOwnerUserTaggingsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_taggings`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/UserTagging"],
      }),
      deleteOwnerUserTaggings: build.mutation<
        DeleteOwnerUserTaggingsApiResponse,
        DeleteOwnerUserTaggingsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_taggings`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/UserTagging"],
      }),
      ownerUserTaggingBatchCreate: build.mutation<
        OwnerUserTaggingBatchCreateApiResponse,
        OwnerUserTaggingBatchCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_taggings/batch_create`,
          method: "POST",
          body: queryArg.userTaggingBatchCreateParams,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/UserTagging"],
      }),
      getOwnerUserTagTypes: build.query<
        GetOwnerUserTagTypesApiResponse,
        GetOwnerUserTagTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tag_types`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            kind: queryArg.kind,
            role_type: queryArg.roleType,
          },
        }),
        providesTags: ["Owner/UserTagType"],
      }),
      postOwnerUserTagTypes: build.mutation<
        PostOwnerUserTagTypesApiResponse,
        PostOwnerUserTagTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tag_types`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/UserTagType"],
      }),
      putOwnerUserTagTypesById: build.mutation<
        PutOwnerUserTagTypesByIdApiResponse,
        PutOwnerUserTagTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tag_types/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/UserTagType"],
      }),
      deleteOwnerUserTagTypesById: build.mutation<
        DeleteOwnerUserTagTypesByIdApiResponse,
        DeleteOwnerUserTagTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tag_types/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Owner/UserTagType"],
      }),
      getOwnerUserTagTypesByUserTagTypeIdUserTags: build.query<
        GetOwnerUserTagTypesByUserTagTypeIdUserTagsApiResponse,
        GetOwnerUserTagTypesByUserTagTypeIdUserTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tag_types/${queryArg.userTagTypeId}/user_tags`,
        }),
        providesTags: ["Owner/UserTag"],
      }),
      getOwnerUserGroups: build.query<
        GetOwnerUserGroupsApiResponse,
        GetOwnerUserGroupsApiArg
      >({
        query: () => ({ url: `/owner/user_groups` }),
        providesTags: ["UserGroup", "Owner/UserGroup"],
      }),
      postOwnerUserGroups: build.mutation<
        PostOwnerUserGroupsApiResponse,
        PostOwnerUserGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_groups`,
          method: "POST",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["UserGroups"],
      }),
      postOwnerMaterialWorksBatchCreateUsingTags: build.mutation<
        PostOwnerMaterialWorksBatchCreateUsingTagsApiResponse,
        PostOwnerMaterialWorksBatchCreateUsingTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/material_works/batch_create_using_tags`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/MaterialWork"],
      }),
      deleteOwnerReservationsById: build.mutation<
        DeleteOwnerReservationsByIdApiResponse,
        DeleteOwnerReservationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/reservations/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Owner/Reservation"],
      }),
      getOwnerUserTags: build.query<
        GetOwnerUserTagsApiResponse,
        GetOwnerUserTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tags`,
          params: {
            kind: queryArg.kind,
            page: queryArg.page,
            per_page: queryArg.perPage,
            role_type: queryArg.roleType,
          },
        }),
        providesTags: ["Owner/UserTag"],
      }),
      postOwnerUserTags: build.mutation<
        PostOwnerUserTagsApiResponse,
        PostOwnerUserTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tags`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/UserTag"],
      }),
      getOwnerUserTagsById: build.query<
        GetOwnerUserTagsByIdApiResponse,
        GetOwnerUserTagsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/owner/user_tags/${queryArg.id}` }),
        providesTags: ["Owner/UserTag"],
      }),
      putOwnerUserTagsById: build.mutation<
        PutOwnerUserTagsByIdApiResponse,
        PutOwnerUserTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tags/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Owner/UserTag"],
      }),
      deleteOwnerUserTagsById: build.mutation<
        DeleteOwnerUserTagsByIdApiResponse,
        DeleteOwnerUserTagsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tags/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Owner/UserTag"],
      }),
      putOwnerUserTagsSequence: build.mutation<
        PutOwnerUserTagsSequenceApiResponse,
        PutOwnerUserTagsSequenceApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tags/sequence`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/UserTag"],
      }),
      ownerUserTagsBatchCreate: build.mutation<
        OwnerUserTagsBatchCreateApiResponse,
        OwnerUserTagsBatchCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tags/batch_create`,
          method: "PATCH",
          body: queryArg.userTagsBatchCreateParams,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Owner/UserTags"],
      }),
      getOwnerUserTagsDistributedTags: build.query<
        GetOwnerUserTagsDistributedTagsApiResponse,
        GetOwnerUserTagsDistributedTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tags/distributed_tags`,
          params: {
            material_id: queryArg.materialId,
            kind: queryArg.kind,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Owner/UserTag"],
      }),
      getOwnerUserTagsByUserTagIdUsers: build.query<
        GetOwnerUserTagsByUserTagIdUsersApiResponse,
        GetOwnerUserTagsByUserTagIdUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tags/${queryArg.userTagId}/users`,
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Owner/UserTag/User"],
      }),
      getOwnerUserTagsByIdDistributedStudents: build.query<
        GetOwnerUserTagsByIdDistributedStudentsApiResponse,
        GetOwnerUserTagsByIdDistributedStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/user_tags/${queryArg.id}/distributed_students`,
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Owner/UserTag/Student"],
      }),
      getOwnerHomeworkTemplates: build.query<
        GetOwnerHomeworkTemplatesApiResponse,
        GetOwnerHomeworkTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            common_tag_name: queryArg.commonTagName,
          },
        }),
        providesTags: [
          "Owner/HomeworkTemplate",
          "Owner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      postHomeworkTemplateAsOwner: build.mutation<
        PostHomeworkTemplateAsOwnerApiResponse,
        PostHomeworkTemplateAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates`,
          method: "POST",
          body: queryArg.homeworkTemplateCreateRequest,
        }),
        invalidatesTags: [
          "Owner/HomeworkTemplate",
          "Owner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      getHomeworkTemplateDetailListAsOwner: build.query<
        GetHomeworkTemplateDetailListAsOwnerApiResponse,
        GetHomeworkTemplateDetailListAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates/detail_list`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: { homework_template_ids: queryArg.homeworkTemplateIds },
        }),
        providesTags: [
          "Owner/HomeworkTemplate",
          "Owner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      getOwnerHomeworkTemplatesByHomeworkTemplateId: build.query<
        GetOwnerHomeworkTemplatesByHomeworkTemplateIdApiResponse,
        GetOwnerHomeworkTemplatesByHomeworkTemplateIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates/${queryArg.homeworkTemplateId}`,
        }),
        providesTags: [
          "Owner/HomeworkTemplate",
          "Owner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      putHomeworkTemplateAsOwner: build.mutation<
        PutHomeworkTemplateAsOwnerApiResponse,
        PutHomeworkTemplateAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates/${queryArg.homeworkTemplateId}`,
          method: "PUT",
          body: queryArg.homeworkTemplateUpdateRequest,
        }),
        invalidatesTags: [
          "Owner/HomeworkTemplate",
          "Owner/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      deleteHomeworkTemplateAsOwner: build.mutation<
        DeleteHomeworkTemplateAsOwnerApiResponse,
        DeleteHomeworkTemplateAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates/${queryArg.homeworkTemplateId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Owner/HomeworkTemplate"],
      }),
      getOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFields:
        build.query<
          GetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse,
          GetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg
        >({
          query: (queryArg) => ({
            url: `/owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: ["Owner/HomeworkTemplateCustomFields"],
        }),
      postOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFields:
        build.mutation<
          PostOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse,
          PostOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg
        >({
          query: (queryArg) => ({
            url: `/owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields`,
            method: "POST",
            body: queryArg.body,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["Owner/HomeworkTemplateCustomFields"],
        }),
      getOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndId:
        build.query<
          GetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse,
          GetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg
        >({
          query: (queryArg) => ({
            url: `/owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/${queryArg.id}`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          providesTags: ["Owner/HomeworkTemplateCustomFields"],
        }),
      putOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndId:
        build.mutation<
          PutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse,
          PutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg
        >({
          query: (queryArg) => ({
            url: `/owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/${queryArg.id}`,
            method: "PUT",
            body: queryArg.body,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["Owner/HomeworkTemplateCustomFields"],
        }),
      deleteOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndId:
        build.mutation<
          DeleteOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse,
          DeleteOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg
        >({
          query: (queryArg) => ({
            url: `/owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/${queryArg.id}`,
            method: "DELETE",
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["Owner/HomeworkTemplateCustomFields"],
        }),
      putOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequence:
        build.mutation<
          PutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceApiResponse,
          PutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceApiArg
        >({
          query: (queryArg) => ({
            url: `/owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_custom_fields/sequence`,
            method: "PUT",
            body: queryArg.body,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
          }),
          invalidatesTags: ["Owner/HomeworkTemplateCustomField"],
        }),
      putOwnerHomeworkTemplateConvertPdf: build.mutation<
        PutOwnerHomeworkTemplateConvertPdfApiResponse,
        PutOwnerHomeworkTemplateConvertPdfApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates/${queryArg.id}/convert_pdf`,
          method: "PUT",
        }),
        invalidatesTags: ["Owner/HomeworkTemplate"],
      }),
      postHomeworkTemplateDuplicateAsOwner: build.mutation<
        PostHomeworkTemplateDuplicateAsOwnerApiResponse,
        PostHomeworkTemplateDuplicateAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates/${queryArg.id}/duplicate`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/HomeworkTemplate", "HomeworkTemplate"],
      }),
      updateHomeworkTemplateDistributionAsOwner: build.mutation<
        UpdateHomeworkTemplateDistributionAsOwnerApiResponse,
        UpdateHomeworkTemplateDistributionAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_template_distributions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: [
          "HomeworkTemplateDistribution",
          "Owner/HomeworkTemplateDistribution",
        ],
      }),
      assignManagerToHomeworkTemplateDistributionAsOwner: build.mutation<
        AssignManagerToHomeworkTemplateDistributionAsOwnerApiResponse,
        AssignManagerToHomeworkTemplateDistributionAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_template_distributions/${queryArg.id}/assign_manager`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: [
          "Owner/HomeworkTemplate",
          "Owner/HomeworkTemplateDistribution",
        ],
      }),
      submitHomeworkTemplateDistributionToReviewerAsOwner: build.mutation<
        SubmitHomeworkTemplateDistributionToReviewerAsOwnerApiResponse,
        SubmitHomeworkTemplateDistributionToReviewerAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_template_distributions/${queryArg.id}/submit_to_reviewer`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: [
          "Owner/HomeworkTemplate",
          "Owner/HomeworkTemplateDistribution",
        ],
      }),
      revertHomeworkTemplateDistributionSubmittedToReviewerAsOwner:
        build.mutation<
          RevertHomeworkTemplateDistributionSubmittedToReviewerAsOwnerApiResponse,
          RevertHomeworkTemplateDistributionSubmittedToReviewerAsOwnerApiArg
        >({
          query: (queryArg) => ({
            url: `/owner/homework_template_distributions/${queryArg.id}/revert_submission_to_reviewer`,
            method: "PUT",
          }),
          invalidatesTags: [
            "Owner/HomeworkTemplate",
            "Owner/HomeworkTemplateDistribution",
          ],
        }),
      returnHomeworkTemplateDistributionToStudentsAsOwner: build.mutation<
        ReturnHomeworkTemplateDistributionToStudentsAsOwnerApiResponse,
        ReturnHomeworkTemplateDistributionToStudentsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_template_distributions/${queryArg.id}/return_to_students`,
          method: "PUT",
        }),
        invalidatesTags: [
          "Owner/HomeworkTemplate",
          "Owner/HomeworkTemplateDistribution",
        ],
      }),
      postHomeworkTemplateImageAsOwner: build.mutation<
        PostHomeworkTemplateImageAsOwnerApiResponse,
        PostHomeworkTemplateImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/HomeworkTemplateImage"],
      }),
      putHomeworkTemplateImageAsOwner: build.mutation<
        PutHomeworkTemplateImageAsOwnerApiResponse,
        PutHomeworkTemplateImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/HomeworkTemplateImage"],
      }),
      deleteHomeworkTemplateImageAsOwner: build.mutation<
        DeleteHomeworkTemplateImageAsOwnerApiResponse,
        DeleteHomeworkTemplateImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_templates/${queryArg.homeworkTemplateId}/homework_template_images/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/HomeworkTemplateImage"],
      }),
      getOwnerMaterialDistributionHistoriesList: build.query<
        GetOwnerMaterialDistributionHistoriesListApiResponse,
        GetOwnerMaterialDistributionHistoriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/material_distribution_histories/list`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/MaterialDistributionHistory"],
      }),
      getOwnerMaterialDistributionHistoriesListStudents: build.query<
        GetOwnerMaterialDistributionHistoriesListStudentsApiResponse,
        GetOwnerMaterialDistributionHistoriesListStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/material_distribution_histories/list_students`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            distribution_id: queryArg.distributionId,
            material_id: queryArg.materialId,
            page: queryArg.page,
            per_page: queryArg.perPage,
            sent_company_id: queryArg.sentCompanyId,
          },
        }),
        providesTags: ["Owner/MaterialDistributionHistory"],
      }),
      getOwnerMaterialDistributionHistoriesSearchByUserId: build.query<
        GetOwnerMaterialDistributionHistoriesSearchByUserIdApiResponse,
        GetOwnerMaterialDistributionHistoriesSearchByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/material_distribution_histories/search_by_user_id`,
          params: {
            user_id: queryArg.userId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Owner/MaterialDistributionHistory"],
      }),
      getOwnerTemplateFrameworks: build.query<
        GetOwnerTemplateFrameworksApiResponse,
        GetOwnerTemplateFrameworksApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/report/template_frameworks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/Pbl/Report/TemplateFrameworks"],
      }),
      postOwnerTemplateFrameworks: build.mutation<
        PostOwnerTemplateFrameworksApiResponse,
        PostOwnerTemplateFrameworksApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/report/template_frameworks`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Pbl/Report/TemplateFrameworks"],
      }),
      getOwnerTemplateFrameworksId: build.query<
        GetOwnerTemplateFrameworksIdApiResponse,
        GetOwnerTemplateFrameworksIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/report/template_frameworks/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/Pbl/Report/TemplateFrameworks"],
      }),
      putOwnerTemplateFrameworksId: build.mutation<
        PutOwnerTemplateFrameworksIdApiResponse,
        PutOwnerTemplateFrameworksIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/report/template_frameworks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Pbl/Report/TemplateFrameworks"],
      }),
      deleteOwnerTemplateFrameworksId: build.mutation<
        DeleteOwnerTemplateFrameworksIdApiResponse,
        DeleteOwnerTemplateFrameworksIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/report/template_frameworks/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Pbl/Report/TemplateFrameworks"],
      }),
      postOwnerTemplateItemFrameworks: build.mutation<
        PostOwnerTemplateItemFrameworksApiResponse,
        PostOwnerTemplateItemFrameworksApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/report/template_frameworks/${queryArg.templateFrameworkId}/item_frameworks`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Pbl/Report/TemplateItemFrameworks"],
      }),
      putOwnerTemplateItemFrameworksId: build.mutation<
        PutOwnerTemplateItemFrameworksIdApiResponse,
        PutOwnerTemplateItemFrameworksIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/report/template_frameworks/${queryArg.templateFrameworkId}/item_frameworks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Pbl/Report/TemplateItemFrameworks"],
      }),
      deleteOwnerTemplateItemFrameworksId: build.mutation<
        DeleteOwnerTemplateItemFrameworksIdApiResponse,
        DeleteOwnerTemplateItemFrameworksIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/report/template_frameworks/${queryArg.templateFrameworkId}/item_frameworks/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Pbl/Report/TemplateItemFrameworks"],
      }),
      getOwnerInquiries: build.query<
        GetOwnerInquiriesApiResponse,
        GetOwnerInquiriesApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/inquiries`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/Inquiries"],
      }),
      getOwnerInquiriesById: build.query<
        GetOwnerInquiriesByIdApiResponse,
        GetOwnerInquiriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/inquiries/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/Inquiries"],
      }),
      getTheNumberOfNewCommentInquiriesAsOwner: build.query<
        GetTheNumberOfNewCommentInquiriesAsOwnerApiResponse,
        GetTheNumberOfNewCommentInquiriesAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/inquiries/new_comment_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/Inquiries"],
      }),
      postOwnerInquiryComments: build.mutation<
        PostOwnerInquiryCommentsApiResponse,
        PostOwnerInquiryCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/inquiry_comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Owner/InquiryComments"],
      }),
      putOwnerInquiryCommentsById: build.mutation<
        PutOwnerInquiryCommentsByIdApiResponse,
        PutOwnerInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/inquiry_comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Owner/InquiryComments"],
      }),
      deleteOwnerInquiryCommentsById: build.mutation<
        DeleteOwnerInquiryCommentsByIdApiResponse,
        DeleteOwnerInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/inquiry_comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Owner/InquiryComments"],
      }),
      getLearningTemplatePackageDistributionsAsOwner: build.query<
        GetLearningTemplatePackageDistributionsAsOwnerApiResponse,
        GetLearningTemplatePackageDistributionsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/learning_template_package_distributions`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/LearningTemplatePackageDistributions"],
      }),
      getLearningTemplatePackageDistributionAsOwner: build.query<
        GetLearningTemplatePackageDistributionAsOwnerApiResponse,
        GetLearningTemplatePackageDistributionAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/learning_template_package_distributions/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/LearningTemplatePackageDistributions"],
      }),
      getLearningPackageAsOwner: build.query<
        GetLearningPackageAsOwnerApiResponse,
        GetLearningPackageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/learning_packages/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/LearningPackages"],
      }),
      getLearningTemplatePackageDistributionHistoriesAsOwner: build.query<
        GetLearningTemplatePackageDistributionHistoriesAsOwnerApiResponse,
        GetLearningTemplatePackageDistributionHistoriesAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/learning_template_package_distribution_histories`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/LearningTemplatePackageDistributionHistories"],
      }),
      getLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwner:
        build.query<
          GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwnerApiResponse,
          GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwnerApiArg
        >({
          query: (queryArg) => ({
            url: `/owner/learning_template_package_distribution_histories/${queryArg.id}/distributed_students`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: ["Owner/LearningTemplatePackageDistributionHistories"],
        }),
      getLearningTemplatePackageDistributionHistoriesPackagingsAsOwner:
        build.query<
          GetLearningTemplatePackageDistributionHistoriesPackagingsAsOwnerApiResponse,
          GetLearningTemplatePackageDistributionHistoriesPackagingsAsOwnerApiArg
        >({
          query: (queryArg) => ({
            url: `/owner/learning_template_package_distribution_histories/${queryArg.id}/packagings`,
            headers: {
              access_token: queryArg.accessToken,
              client: queryArg.client,
              uid: queryArg.uid,
            },
            params: { page: queryArg.page, per_page: queryArg.perPage },
          }),
          providesTags: ["Owner/LearningTemplatePackageDistributionHistories"],
        }),
      getPblBoardInfoAsOwner: build.query<
        GetPblBoardInfoAsOwnerApiResponse,
        GetPblBoardInfoAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/infos`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            preview: queryArg.preview,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Owner/PblBoardInfos"],
      }),
      postPblBoardInfoAsOwner: build.mutation<
        PostPblBoardInfoAsOwnerApiResponse,
        PostPblBoardInfoAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/infos`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardInfos"],
      }),
      putPblBoardInfoAsOwner: build.mutation<
        PutPblBoardInfoAsOwnerApiResponse,
        PutPblBoardInfoAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/infos`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardInfos"],
      }),
      postPblBoardItemAsOwner: build.mutation<
        PostPblBoardItemAsOwnerApiResponse,
        PostPblBoardItemAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardItems"],
      }),
      putPblBoardItemAsOwner: build.mutation<
        PutPblBoardItemAsOwnerApiResponse,
        PutPblBoardItemAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardItems"],
      }),
      deletePblBoardItemAsOwner: build.mutation<
        DeletePblBoardItemAsOwnerApiResponse,
        DeletePblBoardItemAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardItems"],
      }),
      getPblBoardCampaignsAsOwner: build.query<
        GetPblBoardCampaignsAsOwnerApiResponse,
        GetPblBoardCampaignsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/campaigns`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/PblBoardCampaigns"],
      }),
      postPblBoardCampaignAsOwner: build.mutation<
        PostPblBoardCampaignAsOwnerApiResponse,
        PostPblBoardCampaignAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/campaigns`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardCampaigns"],
      }),
      getPblBoardCampaignAsOwner: build.query<
        GetPblBoardCampaignAsOwnerApiResponse,
        GetPblBoardCampaignAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/campaigns/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/PblBoardCampaigns"],
      }),
      putPblBoardCampaignAsOwner: build.mutation<
        PutPblBoardCampaignAsOwnerApiResponse,
        PutPblBoardCampaignAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/campaigns/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardCampaigns"],
      }),
      deletePblBoardCampaignAsOwner: build.mutation<
        DeletePblBoardCampaignAsOwnerApiResponse,
        DeletePblBoardCampaignAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/campaigns/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardCampaigns"],
      }),
      getPblBoardCampaignRequestAsOwner: build.query<
        GetPblBoardCampaignRequestAsOwnerApiResponse,
        GetPblBoardCampaignRequestAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/campaigns/${queryArg.campaignId}/campaign_requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_status: queryArg.searchStatus,
          },
        }),
        providesTags: ["Owner/PblBoardCampaignRequests"],
      }),
      putPblBoardCampaignRequestAsOwner: build.mutation<
        PutPblBoardCampaignRequestAsOwnerApiResponse,
        PutPblBoardCampaignRequestAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/campaigns/${queryArg.campaignId}/campaign_requests/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardCampaignRequests"],
      }),
      putPblBoardCampaignRequestSequenceAsOwner: build.mutation<
        PutPblBoardCampaignRequestSequenceAsOwnerApiResponse,
        PutPblBoardCampaignRequestSequenceAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/campaigns/${queryArg.campaignId}/campaign_requests/${queryArg.id}/sequence`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardCampaignRequests"],
      }),
      getPblBoardArticlesAsOwner: build.query<
        GetPblBoardArticlesAsOwnerApiResponse,
        GetPblBoardArticlesAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/articles`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/PblBoardArticles"],
      }),
      getPblBoardArticlesWordCloudAsOwner: build.query<
        GetPblBoardArticlesWordCloudAsOwnerApiResponse,
        GetPblBoardArticlesWordCloudAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/articles/word_cloud`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            list_len: queryArg.listLen,
            offset: queryArg.offset,
            min_num_of_char: queryArg.minNumOfChar,
          },
        }),
        providesTags: ["Owner/PblBoardArticles"],
      }),
      getPblBoardArticleAsOwner: build.query<
        GetPblBoardArticleAsOwnerApiResponse,
        GetPblBoardArticleAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/articles/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/PblBoardArticles"],
      }),
      postPblBoardContentAsOwner: build.mutation<
        PostPblBoardContentAsOwnerApiResponse,
        PostPblBoardContentAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/contents`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardContents"],
      }),
      getPblBoardContentAsOwner: build.query<
        GetPblBoardContentAsOwnerApiResponse,
        GetPblBoardContentAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/contents/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/PblBoardContents"],
      }),
      putPblBoardContentAsOwner: build.mutation<
        PutPblBoardContentAsOwnerApiResponse,
        PutPblBoardContentAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/contents/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardContents"],
      }),
      deletePblBoardContentAsOwner: build.mutation<
        DeletePblBoardContentAsOwnerApiResponse,
        DeletePblBoardContentAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/contents/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardContents"],
      }),
      getPblBoardFootersAsOwner: build.query<
        GetPblBoardFootersAsOwnerApiResponse,
        GetPblBoardFootersAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/footers`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/PblBoardFooters"],
      }),
      postPblBoardFooterAsOwner: build.mutation<
        PostPblBoardFooterAsOwnerApiResponse,
        PostPblBoardFooterAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/footers`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardFooters"],
      }),
      putPblBoardFooterAsOwner: build.mutation<
        PutPblBoardFooterAsOwnerApiResponse,
        PutPblBoardFooterAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardFooters"],
      }),
      deletePblBoardFooterAsOwner: build.mutation<
        DeletePblBoardFooterAsOwnerApiResponse,
        DeletePblBoardFooterAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardFooters"],
      }),
      putPblBoardFooterSequenceAsOwner: build.mutation<
        PutPblBoardFooterSequenceAsOwnerApiResponse,
        PutPblBoardFooterSequenceAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.id}/sequence`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardFooters"],
      }),
      postPblBoardFooterItemAsOwner: build.mutation<
        PostPblBoardFooterItemAsOwnerApiResponse,
        PostPblBoardFooterItemAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.footerId}/footer_items`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardFooterItems"],
      }),
      putPblBoardFooterItemAsOwner: build.mutation<
        PutPblBoardFooterItemAsOwnerApiResponse,
        PutPblBoardFooterItemAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.footerId}/footer_items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardFooterItems"],
      }),
      deletePblBoardFooterItemAsOwner: build.mutation<
        DeletePblBoardFooterItemAsOwnerApiResponse,
        DeletePblBoardFooterItemAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/pbl/board/items/${queryArg.itemId}/footers/${queryArg.footerId}/footer_items/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/PblBoardFooterItems"],
      }),
      postAttachedImageAsOwner: build.mutation<
        PostAttachedImageAsOwnerApiResponse,
        PostAttachedImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/attached_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Owner/AttachedImages"],
      }),
      getAttachedImageAsOwner: build.query<
        GetAttachedImageAsOwnerApiResponse,
        GetAttachedImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/attached_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["Owner/AttachedImages"],
      }),
      putAttachedImageAsOwner: build.mutation<
        PutAttachedImageAsOwnerApiResponse,
        PutAttachedImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/attached_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Owner/AttachedImages"],
      }),
      deleteAttachedImageAsOwner: build.mutation<
        DeleteAttachedImageAsOwnerApiResponse,
        DeleteAttachedImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/attached_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Owner/AttachedImages"],
      }),
      getLimitedTimeNotificationsAsOwner: build.query<
        GetLimitedTimeNotificationsAsOwnerApiResponse,
        GetLimitedTimeNotificationsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/limited_time_notifications`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/LimitedTimeNotifications"],
      }),
      postLimitedTimeNotificationAsOwner: build.mutation<
        PostLimitedTimeNotificationAsOwnerApiResponse,
        PostLimitedTimeNotificationAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/limited_time_notifications`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/LimitedTimeNotifications"],
      }),
      getLimitedTimeNotificationAsOwner: build.query<
        GetLimitedTimeNotificationAsOwnerApiResponse,
        GetLimitedTimeNotificationAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/limited_time_notifications/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/LimitedTimeNotifications"],
      }),
      putLimitedTimeNotificationAsOwner: build.mutation<
        PutLimitedTimeNotificationAsOwnerApiResponse,
        PutLimitedTimeNotificationAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/limited_time_notifications/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/LimitedTimeNotifications"],
      }),
      deleteLimitedTimeNotificationAsOwner: build.mutation<
        DeleteLimitedTimeNotificationAsOwnerApiResponse,
        DeleteLimitedTimeNotificationAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/limited_time_notifications/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/LimitedTimeNotifications"],
      }),
      getLimitedTimeNotificationNoticeAsOwner: build.query<
        GetLimitedTimeNotificationNoticeAsOwnerApiResponse,
        GetLimitedTimeNotificationNoticeAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/limited_time_notifications/notice`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/LimitedTimeNotifications"],
      }),
      getLimitedTimeNotificationNoticeDetailsAsOwner: build.query<
        GetLimitedTimeNotificationNoticeDetailsAsOwnerApiResponse,
        GetLimitedTimeNotificationNoticeDetailsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/limited_time_notifications/${queryArg.id}/notice_details`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/LimitedTimeNotifications"],
      }),
      postLimitedTimeNotificationNoticeCheckAsOwner: build.mutation<
        PostLimitedTimeNotificationNoticeCheckAsOwnerApiResponse,
        PostLimitedTimeNotificationNoticeCheckAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/limited_time_notifications/${queryArg.id}/notice_check`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/LimitedTimeNotifications"],
      }),
      getTtCsvExportsAsOwner: build.query<
        GetTtCsvExportsAsOwnerApiResponse,
        GetTtCsvExportsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/tt_csv/exports`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            types: queryArg.types,
          },
        }),
        providesTags: ["Owner/TtCsvExports"],
      }),
      postTtCsvExportAsOwner: build.mutation<
        PostTtCsvExportAsOwnerApiResponse,
        PostTtCsvExportAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/tt_csv/exports`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            sorted_by: queryArg.sortedBy,
            search_key: queryArg.searchKey,
            search_value: queryArg.searchValue,
          },
        }),
        invalidatesTags: ["Owner/TtCsvExports"],
      }),
      postTtCategoryBaseAsOwner: build.mutation<
        PostTtCategoryBaseAsOwnerApiResponse,
        PostTtCategoryBaseAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/tt_category/bases`,
          method: "POST",
          body: queryArg.createTtCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/TtCategoryBases"],
      }),
      putTtCategoryBaseAsOwner: build.mutation<
        PutTtCategoryBaseAsOwnerApiResponse,
        PutTtCategoryBaseAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/tt_category/bases/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateTtCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/TtCategoryBases"],
      }),
      getDistributionStatusesAsOwner: build.query<
        GetDistributionStatusesAsOwnerApiResponse,
        GetDistributionStatusesAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/distribution_statuses`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            homework_template_id: queryArg.homeworkTemplateId,
          },
        }),
        providesTags: ["Owner/DistributionStatus"],
      }),
      deleteCommentImageAsOwner: build.mutation<
        DeleteCommentImageAsOwnerApiResponse,
        DeleteCommentImageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/comment_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Owner/CommentImages"],
      }),
      getInterviewRequestsAsOwner: build.query<
        GetInterviewRequestsAsOwnerApiResponse,
        GetInterviewRequestsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/interview/requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            status: queryArg.status,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Owner/Interview/Request"],
      }),
      getInterviewRequestAsOwner: build.query<
        GetInterviewRequestAsOwnerApiResponse,
        GetInterviewRequestAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/interview/requests/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/Interview/Request"],
      }),
      getInterviewRequestArticleAsOwner: build.query<
        GetInterviewRequestArticleAsOwnerApiResponse,
        GetInterviewRequestArticleAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/interview/requests/${queryArg.id}/article`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/Interview/Request"],
      }),
      getInterviewRequestUnreadCountsAsOwner: build.query<
        GetInterviewRequestUnreadCountsAsOwnerApiResponse,
        GetInterviewRequestUnreadCountsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/interview/requests/unread_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { request_ids: queryArg.requestIds },
        }),
        providesTags: ["Owner/Interview/Request"],
      }),
      getInterviewMessagesAsOwner: build.query<
        GetInterviewMessagesAsOwnerApiResponse,
        GetInterviewMessagesAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/interview/requests/${queryArg.requestId}/messages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/Interview/Message"],
      }),
      getInterviewMessageReadAsOwner: build.mutation<
        GetInterviewMessageReadAsOwnerApiResponse,
        GetInterviewMessageReadAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/interview/requests/${queryArg.requestId}/messages/read`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Interview/Message"],
      }),
      getInterviewFeedbacksAsOwner: build.query<
        GetInterviewFeedbacksAsOwnerApiResponse,
        GetInterviewFeedbacksAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/interview/requests/${queryArg.requestId}/feedbacks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Owner/Interview/Feedback"],
      }),
      fetchSponsorProjectListAsOwner: build.query<
        FetchSponsorProjectListAsOwnerApiResponse,
        FetchSponsorProjectListAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/sponsor/projects/list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            professor_affiliate_id: queryArg.professorAffiliateId,
          },
        }),
        providesTags: ["Owner/Sponsor/Projects"],
      }),
      getGroupAffiliatePackagesAsOwner: build.query<
        GetGroupAffiliatePackagesAsOwnerApiResponse,
        GetGroupAffiliatePackagesAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/group_affiliate_packages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            homework_template_id: queryArg.homeworkTemplateId,
            distributed: queryArg.distributed,
          },
        }),
        providesTags: ["Owner/GroupAffiliatePackages"],
      }),
      postGroupAffiliatePackageAsOwner: build.mutation<
        PostGroupAffiliatePackageAsOwnerApiResponse,
        PostGroupAffiliatePackageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/group_affiliate_packages`,
          method: "POST",
          body: queryArg.postGroupAffiliatePackage,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/GroupAffiliatePackages"],
      }),
      getGroupAffiliatePackageAsOwner: build.query<
        GetGroupAffiliatePackageAsOwnerApiResponse,
        GetGroupAffiliatePackageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/group_affiliate_packages/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Owner/GroupAffiliatePackages"],
      }),
      putGroupAffiliatePackageAsOwner: build.mutation<
        PutGroupAffiliatePackageAsOwnerApiResponse,
        PutGroupAffiliatePackageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/group_affiliate_packages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putGroupAffiliatePackage,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/GroupAffiliatePackages"],
      }),
      deleteGroupAffiliatePackageAsOwner: build.mutation<
        DeleteGroupAffiliatePackageAsOwnerApiResponse,
        DeleteGroupAffiliatePackageAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/group_affiliate_packages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/GroupAffiliatePackages"],
      }),
      postPackagingAsOwner: build.mutation<
        PostPackagingAsOwnerApiResponse,
        PostPackagingAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/packagings`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Packaging"],
      }),
      putPackagingAsOwner: build.mutation<
        PutPackagingAsOwnerApiResponse,
        PutPackagingAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/packagings`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            package_id: queryArg.packageId,
            packageable_type: queryArg.packageableType,
            packageable_id: queryArg.packageableId,
          },
        }),
        invalidatesTags: ["Owner/Packaging"],
      }),
      deletePackagingAsOwner: build.mutation<
        DeletePackagingAsOwnerApiResponse,
        DeletePackagingAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/packagings`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            package_id: queryArg.packageId,
            packageable_type: queryArg.packageableType,
            packageable_id: queryArg.packageableId,
          },
        }),
        invalidatesTags: ["Owner/Packaging"],
      }),
      batchCreatePackagingAsOwner: build.mutation<
        BatchCreatePackagingAsOwnerApiResponse,
        BatchCreatePackagingAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/packagings/batch_create`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Packaging"],
      }),
      batchUpdatePackagingAsOwner: build.mutation<
        BatchUpdatePackagingAsOwnerApiResponse,
        BatchUpdatePackagingAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/packagings/batch_update_sequence`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Packaging"],
      }),
      batchDeletePackagingAsOwner: build.mutation<
        BatchDeletePackagingAsOwnerApiResponse,
        BatchDeletePackagingAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/packagings/batch_destroy`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/Packaging"],
      }),
      putHomeworkTemplateCustomValueAsOwner: build.mutation<
        PutHomeworkTemplateCustomValueAsOwnerApiResponse,
        PutHomeworkTemplateCustomValueAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/homework_template_custom_values/${queryArg.id}`,
          method: "PUT",
          body: queryArg.customValueRequestBodyAsOwnerAndTeacherAndReviewer,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Owner/HomeworkTemplateCustomValues"],
      }),
      getCommonTagsAsOwner: build.query<
        GetCommonTagsAsOwnerApiResponse,
        GetCommonTagsAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/common/tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            kind: queryArg.kind,
            categorizable_type: queryArg.categorizableType,
          },
        }),
        providesTags: ["Owner/CommonTags"],
      }),
      postCommonTaggingAsOwner: build.mutation<
        PostCommonTaggingAsOwnerApiResponse,
        PostCommonTaggingAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/common/taggings`,
          method: "POST",
          body: queryArg.commonTaggingRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Owner/CommonTaggings"],
      }),
      deleteCommonTaggingAsOwner: build.mutation<
        DeleteCommonTaggingAsOwnerApiResponse,
        DeleteCommonTaggingAsOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/owner/common/taggings`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
            common_tag_id: queryArg.commonTagId,
          },
        }),
        invalidatesTags: ["Owner/CommonTaggings"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as ownerApi };
export type AffiliatesUpdateAsOwnerApiResponse = /** status 200 200 OK */
  | {
      success_ids?: string[];
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type AffiliatesUpdateAsOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  bulkUpdateRequestBody: BulkUpdateRequestBody;
};
export type UpdateCategoryRenameMapAsOwnerApiResponse =
  /** status 200 OK */ CategoryRenameMap;
export type UpdateCategoryRenameMapAsOwnerApiArg = {
  body: {
    for_text?: string;
    for_subject?: string;
    for_grade?: string;
    for_major?: string;
    for_middle?: string;
    for_minor?: string;
  };
};
export type GetOwnerCategoriesApiResponse = /** status 200 OK */ {
  texts: TextCategoryInfo[];
  grades: GradeCategoryInfo[];
  subjects: SubjectCategoryInfo[];
  major_categories: MajorCategoryInfo[];
  middle_categories: MiddleCategoryInfo[];
  minor_categories: MinorCategoryInfo[];
};
export type GetOwnerCategoriesApiArg = {
  /** - ownerがsuper_ownerから公開された教材一覧を取得するときに利用。 */
  fromSuperOwner?: boolean;
};
export type PostOwnerCategoriesApiResponse = /** status 200 OK */ CategoryInfo;
export type PostOwnerCategoriesApiArg = {
  body: {
    parent_id: string;
    name: string;
    student_visible?: boolean;
    teacher_visible?: boolean;
    image?: string;
  };
};
export type PutOwnerCategoriesByIdApiResponse =
  /** status 200 OK */ CategoryInfo;
export type PutOwnerCategoriesByIdApiArg = {
  id: string;
  putCategoryRequestBodyAsOwner: PutCategoryRequestBodyAsOwner;
};
export type DeleteOwnerCategoriesByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteOwnerCategoriesByIdApiArg = {
  id: string;
};
export type PostOwnerImageContentsApiResponse = /** status 200 OK */ {
  id: string;
  title: string;
  file: {
    file?: string;
  };
  material_id: string;
};
export type PostOwnerImageContentsApiArg = {
  body: {
    material_id?: string;
    title?: string;
    file?: Blob;
  };
};
export type DeleteOwnerImageContentsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteOwnerImageContentsByIdApiArg = {
  id: string;
  body: {
    materialId?: string;
  };
};
export type GetOwnerImageContentsByIdApiResponse = /** status 200 OK */ {
  id?: string;
  title?: string;
  file?: {
    url?: string;
  };
  material_id?: string;
};
export type GetOwnerImageContentsByIdApiArg = {
  id: string;
};
export type PostOwnerVideoContentsApiResponse = /** status 200 OK */ VideoInfo;
export type PostOwnerVideoContentsApiArg = {
  body: {
    material_id?: string;
    title?: string;
    file?: Blob;
    thumbnail?: Blob;
  };
};
export type DeleteOwnerVideoContentsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteOwnerVideoContentsByIdApiArg = {
  id: string;
  body: {
    materialId?: string;
  };
};
export type GetOwnerMaterialWorksApiResponse = /** status 200 OK */ {
  material_works: OwnerMaterialWork[];
  total_count: number;
};
export type GetOwnerMaterialWorksApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  userId?: string;
  materialIds?: string[];
  perPage?: number;
  page?: number;
  role: string;
  /** - 検索タイプを指定する
    - search_typeとsearch_wordは両方していする必要あり
    - title: タイトルから検索
    - description: 説明文から検索
    - tag: タグから検索 */
  searchType?: string;
  /** - search_typeとsearch_wordは両方していする必要あり
    - 検索ワードの部分一致で検索する */
  searchWord?: string;
};
export type BatchCreateMaterialWorksAsOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type BatchCreateMaterialWorksAsOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    material_id?: string;
    material_ids?: string[];
    forbid_download_list?: boolean[];
    user_ids?: string[];
    role: string;
    grade?: string;
    publication_time?: string;
    expiration_time?: string;
  };
};
export type PatchOwnerMaterialWorksBatchDeleteApiResponse =
  /** status 200 OK */ {
    updated_user_ids: string[];
    updated_material_ids: string[] | null;
    deleted_material_work_ids: string[];
  };
export type PatchOwnerMaterialWorksBatchDeleteApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    material_id?: string;
    material_ids?: string[];
    user_id?: string;
    user_ids?: string[];
    role: string;
  };
};
export type GetOwnerMaterialWorksByMaterialWorkIdApiResponse =
  /** status 200 OK */ {
    message_to_student?: string;
    message_to_owner?: string;
  } & {
    id: string;
    material: {
      id: string;
      title: string;
    };
    user: {
      id: string;
      first_name: string;
      last_name: string;
    };
    status: string;
  };
export type GetOwnerMaterialWorksByMaterialWorkIdApiArg = {
  materialWorkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    materialWorkId?: string;
  };
};
export type PutOwnerMaterialWorksByMaterialWorkIdApiResponse =
  /** status 202 OK */ {
    id: string;
  };
export type PutOwnerMaterialWorksByMaterialWorkIdApiArg = {
  materialWorkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    status?: string;
    forbid_download?: boolean;
  };
};
export type GetOwnerUsersTeachersApiResponse = /** status 200 OK */ {
  total_count: number;
  teachers: (TeacherListAsOwner | HasAffiliateIdAndName)[];
};
export type GetOwnerUsersTeachersApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  /** For incremental search by last name and first_name */
  search?: string;
  email?: string;
  /** search users by statuses */
  searchStatuses?: AffiliateStatusJa[];
  perPage?: number;
  page?: number;
  /** trueのとき、id, name のみを返却 */
  isMinInfo?: boolean;
};
export type FetchTeachersForSelectAsOwnerApiResponse = /** status 200 OK */ {
  total_count: number;
  users: HasIdAndName[];
};
export type FetchTeachersForSelectAsOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  /** For incremental search by last name and first_name */
  search?: string;
  perPage?: number;
  page?: number;
};
export type GetOwnerUsersTeachersByIdApiResponse =
  /** status 200 OK */ TeacherUserInfo;
export type GetOwnerUsersTeachersByIdApiArg = {
  id: string;
};
export type PutTeachersProgressUpdateAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutTeachersProgressUpdateAsOwnerApiArg = {
  /** User ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetOwnerUsersStudentsApiResponse = /** status 200 OK */ {
  students: StudentListAsOwner[];
  student_input_settings: StudentInputSetting;
  total_count: number;
};
export type GetOwnerUsersStudentsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  withMaterialWork?: boolean;
  materialId?: string;
  /** Incremental search by last name and first_name */
  search?: string;
  email?: string;
  sortedBy?: SortedByStudentList;
  grade?: string;
  /** 複数のgradeで検索する時 */
  grades?: string;
  /** Incremental search by parent_last_name and parent_first_name */
  parentName?: string;
  /** search users by statuses */
  searchStatuses?: AffiliateStatusJa[];
  userTag?: string;
  userTagIds?: string[];
  tagFilter?: "AND" | "OR";
  homeworkTemplateId?: string;
  /** material_idで検索する時使用 */
  filterMaterialId?: string;
  distribution?: "distributed" | "not_distributed";
  perPage?: number;
  page?: number;
  /** グループのID (GroupAffiliatePackage ID) */
  groupAffiliatePackageId?: string;
  /** 生徒に紐づくユーザータグとグループの数のみ返却 */
  min?: boolean;
  /** グループのIDの配列 (GroupAffiliatePackage ID) */
  groupAffiliatePackageIds?: string[];
  /** ニックネーム */
  nickname?: string;
};
export type GetOwnerUsersStudentsListGradeApiResponse = /** status 200 OK */ {
  number_of_students_per_grade: {
    grade: StudentGradeJa;
    count: number;
  }[];
  students_total_count: number;
};
export type GetOwnerUsersStudentsListGradeApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  homeworkTemplateId?: string;
  pblReportTemplateId?: string;
  filterMaterialId?: string;
  distribution?: "distributed" | "not_distributed";
};
export type GetStudentsUserTagsAsOwnerApiResponse = /** status 200 OK */ {
  user_tag_types: (UserTagTypeBasic & {
    user_tags: UserTagBasic[];
  })[];
};
export type GetStudentsUserTagsAsOwnerApiArg = {
  /** Student ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudentsGroupAffiliatesAsOwnerApiResponse =
  /** status 200 OK */ {
    group_affiliate_packages?: GroupAffiliatePackageMinimalBase[];
  };
export type GetStudentsGroupAffiliatesAsOwnerApiArg = {
  /** Student ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetOwnerUsersOwnersApiResponse = /** status 200 OK */ {
  total_count: number;
  owners: OwnerListAsOwner[];
};
export type GetOwnerUsersOwnersApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  /** Incremental search by last name and first_name */
  search?: string;
  email?: string;
  perPage?: number;
  page?: number;
  /** search users by statuses */
  searchStatuses?: AffiliateStatusJa[];
};
export type GetOwnerUsersByUserIdApiResponse = /** status 200 OK */ UserDetail;
export type GetOwnerUsersByUserIdApiArg = {
  /** user ID */
  userId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type PostOwnerUsersByUserIdApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostOwnerUsersByUserIdApiArg = {
  /** user ID */
  userId: string;
};
export type DeleteOwnerUsersByUserIdApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteOwnerUsersByUserIdApiArg = {
  /** user ID */
  userId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    role: string;
  };
};
export type PutOwnerUsersByUserIdApiResponse = /** status 200 OK */ UserDetail;
export type PutOwnerUsersByUserIdApiArg = {
  /** user ID */
  userId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    last_name?: string;
    first_name?: string;
    nickname?: string | null;
    optional_email?: string;
    user_type?: UserAccountType & any;
    new_id?: string;
    student_info_attributes?: StudentInfoAttributes;
    teacher_info_attributes?: TeacherInfoAttributes;
    professor_info_attributes?: {
      position?: string;
      department?: string;
      description?: string;
      limit_request?: number;
      profile_picture?: Blob;
    };
    user_tag_ids?: string[] | null;
    group_ids?: string[] | null;
  };
};
export type GetOwnerCompaniesByCompanyIdApiResponse =
  /** status 200 OK */ CompanyAsOwner;
export type GetOwnerCompaniesByCompanyIdApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  companyId: string;
};
export type PutOwnerCompaniesByCompanyIdApiResponse = /** status 204 OK */ {
  message?: string;
};
export type PutOwnerCompaniesByCompanyIdApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  companyId: string;
  body: {
    logo?: string;
    email?: string;
    options?: {
      use_account_lock_mail?: boolean;
      use_article_and_homework_mail?: boolean;
    };
  };
};
export type GetCompanyLimitOptionAsOwnerApiResponse = /** status 200 OK */ {
  limit_option: LimitOptionBase;
  total_count?: number;
};
export type GetCompanyLimitOptionAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  id: string;
};
export type GetOwnerCompaniesByIdMaterialCapacityApiResponse =
  /** status 200 OK */ {
    material_capacity: string;
    material_limit_capacity: string | null;
  };
export type GetOwnerCompaniesByIdMaterialCapacityApiArg = {
  id: string;
};
export type GetReviewerCompanyGroupControllersAsOwnerApiResponse =
  /** status 200 OK */ {
    reviewer_company_groups: ReviewerCompanyGroupBase[];
    total_count: number;
  };
export type GetReviewerCompanyGroupControllersAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostOwnerUsersApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostOwnerUsersApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  /** company_idは自動的にオーナーを参照。
    ロール指定の文字列が間違っている場合、Unknownでロールを設定
    invitation_scheduled_atを設定時、招待メールは設定した日時に送信される。未設定時、招待メールは即時送信される。 */
  body: {
    email: string;
    role: string;
    first_name?: string;
    last_name?: string;
    nickname?: string;
    optional_email?: string;
    student_info_attributes?: StudentInfoAttributes;
    teacher_info_attributes?: TeacherInfoAttributes;
    professor_info_attributes?: {
      position?: string;
      department?: string;
      description?: string;
      limit_request?: number;
      profile_picture?: Blob;
    };
    invitation_scheduled_at?: string;
  };
};
export type CreateAccountUserAsOwnerApiResponse =
  /** status 201 Created */ CreateAccountUserResponse;
export type CreateAccountUserAsOwnerApiArg = {
  createAccountUserRequestBody: CreateAccountUserRequestBody;
};
export type CreateAccountAvailableAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type CreateAccountAvailableAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    affiliate_id: string;
  };
};
export type ResetPasswordAsOwnerApiResponse =
  /** status 200 ok */ AccountUserInfo;
export type ResetPasswordAsOwnerApiArg = {
  id: string;
  authorizationPasswordRequestBody: AuthorizationPasswordRequestBody;
};
export type AccountUnlockAsOwnerApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type AccountUnlockAsOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    user_ids: string[];
  };
};
export type AccountStopAsOwnerApiResponse = /** status 202 202 Accepted */ {
  message?: string;
};
export type AccountStopAsOwnerApiArg = {
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    affiliate_id: string;
  };
};
export type PatchOwnerUsersBatchCreateApiResponse = /** status 200 OK */ {
  errors?: string[];
};
export type PatchOwnerUsersBatchCreateApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  /** invitation_scheduled_atを設定時、招待メールは設定した日時に送信される。未設定時、招待メールは即時送信される。 */
  body: {
    slice_start?: number;
    user_params_list?: {
      email?: string;
      user_type?: UserType;
      first_name?: string;
      last_name?: string;
      birth_date?: string;
      grade?: number;
      parent_last_name?: string;
      parent_first_name?: string;
      postcode?: string;
      prefecture?: string;
      address1?: string;
      address2?: string;
      phone?: string;
      number?: number;
      position?: string;
      department?: string;
      description?: string;
      limit_request?: number;
      optional_email?: string;
      user_tag_ids?: string[] | null;
      group_ids?: string[] | null;
    }[];
    invitation_scheduled_at?: string;
  };
};
export type GetOwnerMaterialsApiResponse = /** status 200 OK */ {
  materials: OwnerMaterialList[];
  total_count: number;
};
export type GetOwnerMaterialsApiArg = {
  categoryId?: string;
  withMaterialWork?: boolean;
  userId?: string;
  role?: string;
  perPage?: number;
  page?: number;
  /** - 検索タイプを指定する
    - search_typeとsearch_wordは両方していする必要あり
    - title: タイトルから検索
    - description: 説明文から検索
    - tag: タグから検索 */
  searchType?: string;
  /** - search_typeとsearch_wordは両方していする必要あり
    - 検索ワードの部分一致で検索する */
  searchWord?: string;
};
export type PostOwnerMaterialsApiResponse = /** status 201 OK */ MaterialDetail;
export type PostOwnerMaterialsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  createMaterialRequestBody: CreateMaterialRequestBody;
};
export type GetOwnerMaterialsByIdApiResponse =
  /** status 200 OK */ MaterialDetail;
export type GetOwnerMaterialsByIdApiArg = {
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type PutOwnerMaterialsByIdApiResponse =
  /** status 200 OK */ MaterialDetail;
export type PutOwnerMaterialsByIdApiArg = {
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  updateMaterialRequestBody: UpdateMaterialRequestBody;
};
export type DeleteOwnerMaterialsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteOwnerMaterialsByIdApiArg = {
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    id: string;
  };
};
export type GetOwnerMaterialsByMaterialIdTargetUsersApiResponse =
  /** status 200 OK */ {
    students: {
      target_student_count: number;
      target_students?: {
        id?: string;
        name?: string;
        "distributed?"?: boolean;
      }[];
      distributed_student_count: number;
    };
  };
export type GetOwnerMaterialsByMaterialIdTargetUsersApiArg = {
  materialId: string;
  userTagIds: string[];
  grade?: StudentGrade;
};
export type PutOwnerMaterialsSequenceApiResponse = /** status 200 OK */ {
  message?: string;
};
export type PutOwnerMaterialsSequenceApiArg = {
  body: {
    sequences?: {
      material_id?: string;
      sequence?: number;
    }[];
  };
};
export type GetOwnerMaterialsAllContainedCategoryApiResponse =
  /** status 200 OK */ {
    materials: HasIdAndTitle[];
    total_count: number;
  };
export type GetOwnerMaterialsAllContainedCategoryApiArg = {
  categoryIds?: string[];
  perPage?: number;
  page?: number;
};
export type GetOwnerMaterialsFromSuperOwnersApiResponse = /** status 200 OK */ {
  materials: MaterialList[];
  total_count: number;
};
export type GetOwnerMaterialsFromSuperOwnersApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
  categoryIds?: string[];
  tagNames?: string[];
  /** - 検索タイプを指定する
    - search_typeとsearch_wordは両方していする必要あり
    - title: タイトルから検索
    - description: 説明文から検索
    - tag: タグから検索 */
  searchType?: string;
  /** - search_typeとsearch_wordは両方していする必要あり
    - 検索ワードの部分一致で検索する */
  searchWord?: string;
  searchColumn?: MaterialSortedByColumn;
  searchOrder?: SortedByOrder;
};
export type PostOwnerMaterialsTicketsApiResponse = /** status 200 OK */ {
  hash_ticket_id: string;
};
export type PostOwnerMaterialsTicketsApiArg = {
  body: {
    category_id: string;
  };
};
export type PutOwnerMaterialsTicketsAcceptApiResponse = /** status 200 OK */ {
  message: string;
};
export type PutOwnerMaterialsTicketsAcceptApiArg = {
  body: {
    hashed_ticket_id: string;
  };
};
export type GetOwnerUsersByIdMaterialWorksApiResponse =
  /** status 200 OK */ MaterialWork[];
export type GetOwnerUsersByIdMaterialWorksApiArg = {
  /** ユーザID */
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type GetOwnerReportsApiResponse = /** status 200 OK */ {
  total_count: number;
  reports: Report[];
};
export type GetOwnerReportsApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  teacherName?: string;
  studentName?: string;
  startDate?: string;
  endDate?: string;
};
export type GetOwnerReportsByReportIdApiResponse =
  /** status 200 OK */ Report & {
    message_to_owner: string;
    message_to_student: string;
  };
export type GetOwnerReportsByReportIdApiArg = {
  reportId: string;
  "access-token"?: string;
  clinet?: string;
  uid?: string;
};
export type PutOwnerReportsByReportIdApiResponse =
  /** status 200 OK */ Report & {
    message_to_owner: string;
    message_to_student: string;
  };
export type PutOwnerReportsByReportIdApiArg = {
  reportId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
  body: {
    reply?: string;
    status?: ReportStatus;
  };
};
export type DeleteOwnerReportsByReportIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteOwnerReportsByReportIdApiArg = {
  reportId: string;
};
export type GetOwnerPaymentsApiResponse = /** status 200 OK */ {
  payments: Payment[];
  total_count: number;
};
export type GetOwnerPaymentsApiArg = {
  teacherName?: string;
  studentName?: string;
  startDate?: string;
  endDate?: string;
  "access-token"?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
};
export type GetOwnerPaymentsByPaymentIdApiResponse =
  /** status 200 OK */ Payment;
export type GetOwnerPaymentsByPaymentIdApiArg = {
  paymentId: string;
  "access-token"?: string;
  uid?: string;
  client?: string;
};
export type PutOwnerPaymentsByPaymentIdApiResponse =
  /** status 200 OK */ Payment;
export type PutOwnerPaymentsByPaymentIdApiArg = {
  paymentId: string;
  "access-token"?: string;
  uid?: string;
  client?: string;
  body: {
    status?: number;
  };
};
export type GetOwnerHomeworksApiResponse = /** status 200 OK */ {
  total_count: number;
  homeworks: HomeworkBase[];
};
export type GetOwnerHomeworksApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  templateId?: string;
  teacherName?: string;
  studentName?: string;
  startDate?: string;
  endDate?: string;
  statuses?: HomeworkStatus[];
  orderType?: HomeworkOrderType;
  sortType?: SortedByOrder;
};
export type GetOwnerHomeworksByHomeworkIdApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type GetOwnerHomeworksByHomeworkIdApiArg = {
  homeworkId: string;
  client?: string;
  uid?: string;
  "access-token"?: string;
};
export type UpdateHomeworkAsOwnerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type UpdateHomeworkAsOwnerApiArg = {
  homeworkId: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
  body: {
    status?: HomeworkStatus;
    teacher_comment?: string;
    teacher_comment_for_reviewer?: string;
    score?: number;
  };
};
export type UploadHomeworkImagesAsOwnerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type UploadHomeworkImagesAsOwnerApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    files: string[];
  };
};
export type SaveEditedHomeworkImageAsOwnerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type SaveEditedHomeworkImageAsOwnerApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    file: string;
  };
};
export type UpdateHomeworkImageAsOwnerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type UpdateHomeworkImageAsOwnerApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_id: string;
    file: string;
  };
};
export type DeleteHomeworkImageAsOwnerApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type DeleteHomeworkImageAsOwnerApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_id: string;
  };
};
export type BatchCreateHomeworksAsOwnerApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type BatchCreateHomeworksAsOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    homework_template_id?: string;
    create_params_list: {
      student_id: string;
      teacher_id?: string | null;
    }[];
  };
};
export type BatchUpdateHomeworksAsOwnerApiResponse = /** status 200 OK */ {
  homeworks: HomeworkBase[];
};
export type BatchUpdateHomeworksAsOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  batchUpdateHomeworkRequest: BatchUpdateHomeworkRequest;
};
export type BatchDeleteHomeworksAsOwnerApiResponse = /** status 200 OK */ {
  deleted_ids?: number[];
  errors?: string[];
};
export type BatchDeleteHomeworksAsOwnerApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    homework_ids: string[];
  };
};
export type GetHomeworkImageDownloadUrlsAsOwnerApiResponse =
  /** status 200 OK */ {
    homeworks: HomeworkDownloadUrl[];
  };
export type GetHomeworkImageDownloadUrlsAsOwnerApiArg = {
  client?: string;
  "access-token"?: string;
  uid?: string;
  homeworkIds: string[];
  fileContent?: "file" | "answer_file" | "reviewer_file";
  templateId?: string;
  orderType?: HomeworkOrderType;
  sortType?: SortedByOrder;
};
export type PostHomeworkTemplateCustomFieldFeedbackAsOwnerApiResponse =
  /** status 201 Created */ HomeworkTemplateCustomFieldFeedback;
export type PostHomeworkTemplateCustomFieldFeedbackAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  homeworkTemplateCustomFieldId: string;
  customFieldFeedbackRequestBody: CustomFieldFeedbackRequestBody;
};
export type PutHomeworkTemplateCustomFieldFeedbacksAsOwnerApiResponse =
  /** status 202 Accepted */ HomeworkTemplateCustomFieldFeedback;
export type PutHomeworkTemplateCustomFieldFeedbacksAsOwnerApiArg = {
  homeworkTemplateCustomFieldId: string;
  /** HomeworkTemplateCustomFieldFeedbackID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putCustomFieldFeedbackRequestBody: PutCustomFieldFeedbackRequestBody;
};
export type DeleteHomeworkTemplateCustomFieldFeedbacksAsOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteHomeworkTemplateCustomFieldFeedbacksAsOwnerApiArg = {
  homeworkTemplateCustomFieldId: string;
  /** HomeworkTemplateCustomFieldFeedbackID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetOwnerEmailsApiResponse = /** status 200 OK */ {
  emails: EmailBase[];
  total_count: number;
};
export type GetOwnerEmailsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
};
export type PostOwnerEmailsApiResponse = /** status 200 OK */ EmailBase;
export type PostOwnerEmailsApiArg = {
  body: {
    title: string;
    body: string;
    to_all_students?: boolean;
    to_all_teachers?: boolean;
    target_user_tags?: string[];
  };
};
export type GetOwnerEmailsByOwnerEmailIdApiResponse = /** status 200 OK */ {
  body?: string;
} & EmailBase;
export type GetOwnerEmailsByOwnerEmailIdApiArg = {
  ownerEmailId: string;
};
export type PutOwnerCategoriesSequenceApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutOwnerCategoriesSequenceApiArg = {
  /** Array of category and sequence combination */
  body: {
    sequences?: {
      category_id?: string;
      sequence?: number;
    }[];
  };
};
export type GetOwnerNotificationsApiResponse =
  /** status 200 OK */ Notifications;
export type GetOwnerNotificationsApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  sent?: string;
  perPage?: number;
  page?: number;
};
export type CreateNotificationAsOwnerApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 201 Created */ {
      message?: string;
    };
export type CreateNotificationAsOwnerApiArg = {
  body: {
    target_roles?: string[];
    target_user_tag_ids?: string[];
    target_company_ids?: string[];
    title: string;
    message: string;
    with_email?: boolean;
    publication_time?: string;
  };
};
export type GetOwnerNotificationsByIdApiResponse =
  /** status 200 OK */ Notification;
export type GetOwnerNotificationsByIdApiArg = {
  /** Notification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutOwnerNotificationsByIdApiResponse =
  /** status 202 Accepted */ Notification;
export type PutOwnerNotificationsByIdApiArg = {
  /** Notification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  notificationRequest: NotificationRequest;
};
export type DeleteOwnerNotificationsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteOwnerNotificationsByIdApiArg = {
  /** Notification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetOwnerArticlesByArticleIdApiResponse =
  /** status 200 OK */ ArticleDetailBase;
export type GetOwnerArticlesByArticleIdApiArg = {
  articleId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type GetOwnerArticlesApiResponse = /** status 200 OK */ {
  articles: (ArticleBase & {
    excerpt?: string;
    liked?: boolean;
    unliked?: boolean;
  })[];
  total_count: number;
};
export type GetOwnerArticlesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  /** グループのID (GroupAffiliatePackage ID) */
  groupAffiliatePackageId?: string;
  /** グループのIDの配列 (GroupAffiliatePackage ID) */
  groupAffiliatePackageIds?: string[];
  /** articleのstatus */
  status?: ArticleStatus;
  /** kindのフィルタリング */
  kind?: ArticleKind;
};
export type FetchArticleWordCloudAsOwnerApiResponse =
  /** status 200 OK */ WordCloudBase;
export type FetchArticleWordCloudAsOwnerApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  listLen?: number;
  offset?: number;
  minNumOfChar?: number;
};
export type GetOwnerArticlesByArticleIdFeedbacksApiResponse =
  /** status 200 OK */ {
    article_feedbacks: ArticleFeedback[];
    total_count: number;
  };
export type GetOwnerArticlesByArticleIdFeedbacksApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  articleId: string;
};
export type GetOwnerArticlesByArticleIdCommentsApiResponse =
  /** status 200 OK */ {
    article_comments: ArticleComment[];
    total_count: number;
  };
export type GetOwnerArticlesByArticleIdCommentsApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  articleId: string;
};
export type FetchAffiliateInvitationsAsOwnerApiResponse = /** status 200 OK */ {
  invitations: AffiliateInvitation[];
  total_count: number;
};
export type FetchAffiliateInvitationsAsOwnerApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  role?: UserType;
};
export type GetOwnerAffiliateInvitationsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type GetOwnerAffiliateInvitationsByIdApiArg = {
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type PutOwnerAffiliateInvitationsByIdApiResponse =
  /** status 200 200 OK */
    | {
        message?: string;
      }
    | /** status 202 Accepted */ AffiliateInvitation;
export type PutOwnerAffiliateInvitationsByIdApiArg = {
  id: string;
};
export type PutOwnerAffiliateInvitationsActivateApiResponse =
  /** status 200 OK */ AffiliateInvitation;
export type PutOwnerAffiliateInvitationsActivateApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  body: {
    grade?: StudentGrade;
    user_tag_ids?: string[];
    role?: UserType;
  };
};
export type PutOwnerAffiliateInvitationsByIdInactivateApiResponse =
  /** status 200 OK */ AffiliateInvitation;
export type PutOwnerAffiliateInvitationsByIdInactivateApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  id: string;
};
export type PutOwnerAffiliateInvitationsByIdReactivateApiResponse =
  /** status 200 OK */ AffiliateInvitation;
export type PutOwnerAffiliateInvitationsByIdReactivateApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  id: string;
};
export type PostOwnerUserTaggingsApiResponse = /** status 201 Created */
  | {
      number_of_tagging_users?: number;
      remaining_number_of_tagging_users?: number | null;
    }
  | /** status 202 Accepted */ {
      message?: string;
    };
export type PostOwnerUserTaggingsApiArg = {
  body: {
    user_id?: string;
    user_tag_id?: string;
  };
};
export type DeleteOwnerUserTaggingsApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type DeleteOwnerUserTaggingsApiArg = {
  body: {
    user_id?: string;
    user_tag_id?: string;
  };
};
export type OwnerUserTaggingBatchCreateApiResponse =
  /** status 200 OK */ UserTaggingBatchCreateResponses;
export type OwnerUserTaggingBatchCreateApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  userTaggingBatchCreateParams: UserTaggingBatchCreateParams;
};
export type GetOwnerUserTagTypesApiResponse = /** status 200 OK */ {
  user_tag_types: UserTagType[];
  total_count: number;
};
export type GetOwnerUserTagTypesApiArg = {
  page?: number;
  perPage?: number;
  kind?: "nationwide" | "limited";
  roleType?: "student" | "teacher";
};
export type PostOwnerUserTagTypesApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostOwnerUserTagTypesApiArg = {
  body: {
    name?: string;
    kind?: UserTagTypeKind;
    teacher_visible?: boolean;
  };
};
export type PutOwnerUserTagTypesByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutOwnerUserTagTypesByIdApiArg = {
  id: string;
  body: {
    name?: string;
    teacher_visible?: boolean;
  };
};
export type DeleteOwnerUserTagTypesByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteOwnerUserTagTypesByIdApiArg = {
  id: string;
};
export type GetOwnerUserTagTypesByUserTagTypeIdUserTagsApiResponse =
  /** status 200 OK */ {
    user_tags?: UserTag[];
    total_count?: number;
  };
export type GetOwnerUserTagTypesByUserTagTypeIdUserTagsApiArg = {
  userTagTypeId: string;
};
export type GetOwnerUserGroupsApiResponse = /** status 200 OK */ {
  user_groups?: UserGroup[];
  total_count?: number;
};
export type GetOwnerUserGroupsApiArg = void;
export type PostOwnerUserGroupsApiResponse =
  /** status 201 Created */ UserGroup;
export type PostOwnerUserGroupsApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  body: {
    grade?: StudentGrade;
    name?: string;
  };
};
export type PostOwnerMaterialWorksBatchCreateUsingTagsApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PostOwnerMaterialWorksBatchCreateUsingTagsApiArg = {
  body: {
    material_id?: string;
    user_tag_ids?: string[];
    publication_time?: string;
    expiration_time?: string;
    forbid_download?: boolean;
  };
};
export type DeleteOwnerReservationsByIdApiResponse =
  /** status 204 No Content */ undefined;
export type DeleteOwnerReservationsByIdApiArg = {
  id: string;
};
export type GetOwnerUserTagsApiResponse = /** status 200 OK */ {
  user_tags: UserTag[];
  total_count: number;
};
export type GetOwnerUserTagsApiArg = {
  kind?: "nationwide" | "limited";
  page?: number;
  perPage?: number;
  roleType?: UserTagTypeRoleType;
};
export type PostOwnerUserTagsApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostOwnerUserTagsApiArg = {
  body: {
    user_tag_type_id: string;
    name: string;
    limit_number?: number;
  };
};
export type GetOwnerUserTagsByIdApiResponse =
  /** status 200 OK */ UserTagDetail;
export type GetOwnerUserTagsByIdApiArg = {
  id: string;
};
export type PutOwnerUserTagsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutOwnerUserTagsByIdApiArg = {
  id: string;
  body: {
    name?: string;
    limit_number?: number;
  };
};
export type DeleteOwnerUserTagsByIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteOwnerUserTagsByIdApiArg = {
  id: string;
};
export type PutOwnerUserTagsSequenceApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutOwnerUserTagsSequenceApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sequences: {
      id: string;
      sequence_number?: number;
    }[];
  };
};
export type OwnerUserTagsBatchCreateApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type OwnerUserTagsBatchCreateApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  userTagsBatchCreateParams: UserTagsBatchCreateParams;
};
export type GetOwnerUserTagsDistributedTagsApiResponse = /** status 200 OK */ {
  user_tags: DistributionHistoryInfo[];
  total_count: number;
};
export type GetOwnerUserTagsDistributedTagsApiArg = {
  materialId: string;
  kind?: string;
  page?: number;
  perPage?: number;
};
export type GetOwnerUserTagsByUserTagIdUsersApiResponse = /** status 200 OK */ {
  users: BasicUserInfo[];
  total_count: number;
};
export type GetOwnerUserTagsByUserTagIdUsersApiArg = {
  userTagId: string;
  perPage?: number;
  page?: number;
};
export type GetOwnerUserTagsByIdDistributedStudentsApiResponse =
  /** status 200 OK */ {
    students: SimpleUserList[];
    total_count: number;
  };
export type GetOwnerUserTagsByIdDistributedStudentsApiArg = {
  id: string;
  perPage?: number;
  page?: number;
};
export type GetOwnerHomeworkTemplatesApiResponse = /** status 200 OK */ {
  homework_templates: HomeworkTemplateBaseForOwnerAndTeacher[];
  total_count: number;
};
export type GetOwnerHomeworkTemplatesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: HomeworkTemplateSearchType;
  searchWord?: string;
  /** common_tag_nameのフィルタリング */
  commonTagName?: string;
};
export type PostHomeworkTemplateAsOwnerApiResponse =
  /** status 201 Created */ HomeworkTemplateForOwnerAndTeacher;
export type PostHomeworkTemplateAsOwnerApiArg = {
  homeworkTemplateCreateRequest: HomeworkTemplateCreateRequest;
};
export type GetHomeworkTemplateDetailListAsOwnerApiResponse =
  /** status 200 OK */ {
    homework_templates: (ModelBase &
      HomeworkTemplateMeta & {
        homework_template_distribution?: HomeworkTemplateDistribution;
      })[];
  };
export type GetHomeworkTemplateDetailListAsOwnerApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  homeworkTemplateIds: string[];
};
export type GetOwnerHomeworkTemplatesByHomeworkTemplateIdApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type GetOwnerHomeworkTemplatesByHomeworkTemplateIdApiArg = {
  homeworkTemplateId: string;
};
export type PutHomeworkTemplateAsOwnerApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type PutHomeworkTemplateAsOwnerApiArg = {
  homeworkTemplateId: string;
  homeworkTemplateUpdateRequest: HomeworkTemplateUpdateRequest;
};
export type DeleteHomeworkTemplateAsOwnerApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteHomeworkTemplateAsOwnerApiArg = {
  homeworkTemplateId: string;
};
export type GetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse =
  /** status 200 OK */ {
    homework_template_custom_fields: HomeworkTemplateCustomField[];
    total_count: number;
  };
export type GetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
  };
export type PostOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiResponse =
  /** status 201 Created */ HomeworkTemplateCustomField;
export type PostOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    body: CustomFieldCommonRequestBody & {
      type: HomeworkTemplateCustomFieldType;
    };
  };
export type GetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse =
  /** status 200 OK */ HomeworkTemplateCustomField;
export type GetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    /** HomeworkTemplateCustomField ID */
    id: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  };
export type PutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse =
  /** status 202 Accepted */ HomeworkTemplateCustomField;
export type PutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    /** HomeworkTemplateCustomField ID */
    id: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    body: CustomFieldCommonRequestBody;
  };
export type DeleteOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiResponse =
  /** status 200 OK */ {
    message?: string;
  };
export type DeleteOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdApiArg =
  {
    /** HomeworkTemplate ID */
    homeworkTemplateId: string;
    /** HomeworkTemplateCustomField ID */
    id: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  };
export type PutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceApiArg =
  {
    homeworkTemplateId: string;
    accessToken?: string;
    client?: string;
    uid?: string;
    body: {
      sequences: {
        id: string;
        sequence_number: number;
      }[];
    };
  };
export type PutOwnerHomeworkTemplateConvertPdfApiResponse =
  /** status 201 OK */ {
    message?: string;
  };
export type PutOwnerHomeworkTemplateConvertPdfApiArg = {
  /** HomeworkTemplate ID for conversion */
  id: string;
};
export type PostHomeworkTemplateDuplicateAsOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostHomeworkTemplateDuplicateAsOwnerApiArg = {
  /** HomeworkTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type UpdateHomeworkTemplateDistributionAsOwnerApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type UpdateHomeworkTemplateDistributionAsOwnerApiArg = {
  id: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
  body: {
    comment?: string;
  };
};
export type AssignManagerToHomeworkTemplateDistributionAsOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type AssignManagerToHomeworkTemplateDistributionAsOwnerApiArg = {
  /** HomeworkTemplateDistribution ID */
  id: string;
  body: {
    teacher_id: string;
  };
};
export type SubmitHomeworkTemplateDistributionToReviewerAsOwnerApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type SubmitHomeworkTemplateDistributionToReviewerAsOwnerApiArg = {
  /** HomeworkTemplateDistribution ID */
  id: string;
  body: {
    comment?: string | null;
    image_contents?: Blob[];
  };
};
export type RevertHomeworkTemplateDistributionSubmittedToReviewerAsOwnerApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type RevertHomeworkTemplateDistributionSubmittedToReviewerAsOwnerApiArg =
  {
    /** HomeworkTemplateDistribution ID */
    id: string;
  };
export type ReturnHomeworkTemplateDistributionToStudentsAsOwnerApiResponse =
  /** status 200 OK */ HomeworkTemplateForOwnerAndTeacher;
export type ReturnHomeworkTemplateDistributionToStudentsAsOwnerApiArg = {
  /** HomeworkTemplateDistribution ID */
  id: string;
};
export type PostHomeworkTemplateImageAsOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostHomeworkTemplateImageAsOwnerApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type PutHomeworkTemplateImageAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutHomeworkTemplateImageAsOwnerApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  /** HomeworkTemplateImage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteHomeworkTemplateImageAsOwnerApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteHomeworkTemplateImageAsOwnerApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  /** HomeworkTemplateImage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetOwnerMaterialDistributionHistoriesListApiResponse =
  /** status 200 OK */ {
    material_distribution_histories: MaterialDistributionHistory[];
    total_count: number;
  };
export type GetOwnerMaterialDistributionHistoriesListApiArg = {
  page?: number;
  perPage?: number;
};
export type GetOwnerMaterialDistributionHistoriesListStudentsApiResponse =
  /** status 200 OK */ MaterialDistributionHistoryListStudents;
export type GetOwnerMaterialDistributionHistoriesListStudentsApiArg = {
  distributionId?: string;
  materialId?: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  sentCompanyId?: string;
};
export type GetOwnerMaterialDistributionHistoriesSearchByUserIdApiResponse =
  /** status 200 OK */ {
    material_distribution_histories: MaterialDistributionHistorySearchByUserId[];
    total_count: number;
  };
export type GetOwnerMaterialDistributionHistoriesSearchByUserIdApiArg = {
  userId: string;
  page?: number;
  perPage?: number;
};
export type GetOwnerTemplateFrameworksApiResponse = /** status 200 OK */ {
  report_template_frameworks?: Schema[];
  total_count?: number;
};
export type GetOwnerTemplateFrameworksApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostOwnerTemplateFrameworksApiResponse =
  /** status 201 Created */ ModelBase & {
    owner: AffiliateBase;
    title: string;
    created_at: string;
    updated_at: string;
    item_frameworks?: Schema2[];
  };
export type PostOwnerTemplateFrameworksApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
  };
};
export type GetOwnerTemplateFrameworksIdApiResponse =
  /** status 200 OK */ Schema;
export type GetOwnerTemplateFrameworksIdApiArg = {
  /** Pbl::Report::TemplateFramework ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutOwnerTemplateFrameworksIdApiResponse =
  /** status 202 Accepted */ Schema;
export type PutOwnerTemplateFrameworksIdApiArg = {
  /** Pbl::Report::TemplateFramework ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
  };
};
export type DeleteOwnerTemplateFrameworksIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteOwnerTemplateFrameworksIdApiArg = {
  /** Pbl::Report::TemplateFramework ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostOwnerTemplateItemFrameworksApiResponse =
  /** status 201 Created */ ModelBase & {
    template_framework_id?: string;
    sequence?: number;
    title: string;
    description?: string;
    text_example?: string;
    text_min_char_size?: number;
    created_at: string;
    updated_at: string;
  };
export type PostOwnerTemplateItemFrameworksApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** Pbl::Report::TemplateFramework ID */
  templateFrameworkId: string;
  body: {
    type: "text";
    sequence?: number;
    title: string;
    description?: string;
    text_example?: string;
    text_min_char_size?: number;
  };
};
export type PutOwnerTemplateItemFrameworksIdApiResponse =
  /** status 202 Accepted */ Schema2;
export type PutOwnerTemplateItemFrameworksIdApiArg = {
  /** Pbl::Report::TemplateItemFramework ID */
  templateFrameworkId: string;
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sequence?: number;
    title: string;
    description?: string;
    text_example?: string;
    text_min_char_size?: number;
  };
};
export type DeleteOwnerTemplateItemFrameworksIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteOwnerTemplateItemFrameworksIdApiArg = {
  /** Pbl::Report::TemplateItemFramework ID */
  templateFrameworkId: string;
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetOwnerInquiriesApiResponse = /** status 200 OK */ {
  inquiries: InquirySimpleListBase[];
  total_count: number;
};
export type GetOwnerInquiriesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetOwnerInquiriesByIdApiResponse =
  /** status 200 OK */ InquirySimpleBase;
export type GetOwnerInquiriesByIdApiArg = {
  /** inquiries ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTheNumberOfNewCommentInquiriesAsOwnerApiResponse =
  /** status 200 OK */ {
    count?: number;
  };
export type GetTheNumberOfNewCommentInquiriesAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostOwnerInquiryCommentsApiResponse =
  /** status 201 Created */ InquiryComment;
export type PostOwnerInquiryCommentsApiArg = {
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
  };
};
export type PutOwnerInquiryCommentsByIdApiResponse =
  /** status 202 Accepted */ InquiryComment;
export type PutOwnerInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
    status?: string;
  };
};
export type DeleteOwnerInquiryCommentsByIdApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteOwnerInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetLearningTemplatePackageDistributionsAsOwnerApiResponse =
  /** status 200 OK */ {
    learning_template_package_distribution_settings: LearningTemplatePackageDistributionSettingBase[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionsAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetLearningTemplatePackageDistributionAsOwnerApiResponse =
  /** status 200 OK */ LearningTemplatePackageDistributionSettingBaseDetails;
export type GetLearningTemplatePackageDistributionAsOwnerApiArg = {
  /** LearningTemplatePackageDistributionSetting ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetLearningPackageAsOwnerApiResponse =
  /** status 200 OK */ LearningPackageBaseDetails;
export type GetLearningPackageAsOwnerApiArg = {
  /** LearningPackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetLearningTemplatePackageDistributionHistoriesAsOwnerApiResponse =
  /** status 200 OK */ {
    learning_template_package_distribution_histories: LearningTemplatePackageDistributionHistoryBase[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionHistoriesAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwnerApiResponse =
  /** status 200 OK */ {
    distributed_students: DistributedStudents[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwnerApiArg =
  {
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
    /** LearningTemplatePackageDistributionSetting ID */
    id: string;
  };
export type GetLearningTemplatePackageDistributionHistoriesPackagingsAsOwnerApiResponse =
  /** status 200 OK */ {
    packagings: PackagingAsLearningTemplatePackageBase[];
    total_count: number;
  };
export type GetLearningTemplatePackageDistributionHistoriesPackagingsAsOwnerApiArg =
  {
    accessToken?: string;
    client?: string;
    uid?: string;
    page?: number;
    perPage?: number;
    /** LearningTemplatePackageDistributionSetting ID */
    id: string;
  };
export type GetPblBoardInfoAsOwnerApiResponse =
  /** status 200 OK */ PblBoardInfoBase;
export type GetPblBoardInfoAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** 文字列のtrueを設定をすると探究成果一覧を取得する */
  preview?: string;
  /** 探究成果一覧のページング */
  page?: number;
  /** 探究成果一覧のページング */
  perPage?: number;
};
export type PostPblBoardInfoAsOwnerApiResponse =
  /** status 201 Created */ PblBoardInfoBase;
export type PostPblBoardInfoAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardInfoAsOwnerApiResponse =
  /** status 202 Accepted */ PblBoardInfoBase;
export type PutPblBoardInfoAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    event: PblBoardInfoEvent;
  };
};
export type PostPblBoardItemAsOwnerApiResponse =
  /** status 201 Created */ PblBoardItemBaseAsShow;
export type PostPblBoardItemAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    content_type: PblBoardItemContentType;
    sequence?: number;
  };
};
export type PutPblBoardItemAsOwnerApiResponse =
  /** status 202 Accepted */ PblBoardItemBaseAsShow;
export type PutPblBoardItemAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    content_type: PblBoardItemContentType;
    sequence?: number;
  };
};
export type DeletePblBoardItemAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardItemAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblBoardCampaignsAsOwnerApiResponse = /** status 200 OK */ {
  board_campaigns: PblBoardCampaignList[];
  total_count: number;
};
export type GetPblBoardCampaignsAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostPblBoardCampaignAsOwnerApiResponse =
  /** status 201 Created */ PblBoardCampaignBase;
export type PostPblBoardCampaignAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    description?: string;
    start_date: string;
    end_date: string;
  };
};
export type GetPblBoardCampaignAsOwnerApiResponse =
  /** status 200 OK */ PblBoardCampaignBase;
export type GetPblBoardCampaignAsOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardCampaignAsOwnerApiResponse =
  /** status 202 Accepted */ PblBoardCampaignBase;
export type PutPblBoardCampaignAsOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    description?: string;
    start_date: string;
    end_date: string;
    event: PblBoardCampaignEvent;
  };
};
export type DeletePblBoardCampaignAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardCampaignAsOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblBoardCampaignRequestAsOwnerApiResponse =
  /** status 200 OK */ {
    board_campaign_requests: PblBoardCampaignRequestList[];
    total_count: number;
  };
export type GetPblBoardCampaignRequestAsOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  campaignId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchStatus?: PblBoardCampaignRequestStatus;
};
export type PutPblBoardCampaignRequestAsOwnerApiResponse =
  /** status 202 Accepted */ PblBoardCampaignRequestList;
export type PutPblBoardCampaignRequestAsOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  campaignId: string;
  /** 探究ボードの探究成果募集リクエスト ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    event: PblBoardCampaignRequestEvent;
  };
};
export type PutPblBoardCampaignRequestSequenceAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardCampaignRequestSequenceAsOwnerApiArg = {
  /** 探究ボードの探究成果募集 ID */
  campaignId: string;
  /** 探究ボードの探究成果募集リクエスト ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sequence: number;
  };
};
export type GetPblBoardArticlesAsOwnerApiResponse = /** status 200 OK */ {
  articles: PblBoardArticleBase[];
  total_count: number;
};
export type GetPblBoardArticlesAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetPblBoardArticlesWordCloudAsOwnerApiResponse =
  /** status 200 OK */ WordCloudBase;
export type GetPblBoardArticlesWordCloudAsOwnerApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  listLen?: number;
  offset?: number;
  minNumOfChar?: number;
};
export type GetPblBoardArticleAsOwnerApiResponse =
  /** status 200 OK */ ArticleDetailAsPblBoard;
export type GetPblBoardArticleAsOwnerApiArg = {
  /** Article ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostPblBoardContentAsOwnerApiResponse =
  /** status 201 Created */ PblBoardContentBase;
export type PostPblBoardContentAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    design_type?: PblBoardContentDesignType;
    catchphrase?: string;
    catchphrase_description?: string;
    catchphrase_image?: Blob;
    cta_name?: string;
    cta_url?: string;
    external_link?: string;
  };
};
export type GetPblBoardContentAsOwnerApiResponse =
  /** status 200 OK */ PblBoardContentBase;
export type GetPblBoardContentAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのコンテンツ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardContentAsOwnerApiResponse =
  /** status 202 Accepted */ PblBoardContentBase;
export type PutPblBoardContentAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのコンテンツ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    design_type?: PblBoardContentDesignType;
    catchphrase?: string;
    catchphrase_description?: string;
    catchphrase_image?: Blob;
    cta_name?: string;
    cta_url?: string;
    external_link?: string;
  };
};
export type DeletePblBoardContentAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardContentAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのコンテンツ ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetPblBoardFootersAsOwnerApiResponse = /** status 200 OK */ {
  board_footers: PblBoardFooterBase[];
  total_count: number;
};
export type GetPblBoardFootersAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostPblBoardFooterAsOwnerApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostPblBoardFooterAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    category_name: string;
    footer_items?: {
      title: string;
      url: string;
    }[];
  };
};
export type PutPblBoardFooterAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardFooterAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    category_name: string;
  };
};
export type DeletePblBoardFooterAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardFooterAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutPblBoardFooterSequenceAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardFooterSequenceAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sequence: number;
  };
};
export type PostPblBoardFooterItemAsOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostPblBoardFooterItemAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  footerId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    url: string;
  };
};
export type PutPblBoardFooterItemAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutPblBoardFooterItemAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  footerId: string;
  /** 探究ボードのフッターリンク ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    url: string;
  };
};
export type DeletePblBoardFooterItemAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeletePblBoardFooterItemAsOwnerApiArg = {
  /** 探究ボードのコンテンツタイプ ID */
  itemId: string;
  /** 探究ボードのフッター ID */
  footerId: string;
  /** 探究ボードのフッターリンク ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostAttachedImageAsOwnerApiResponse =
  /** status 201 Created */ AttachedImageBase;
export type PostAttachedImageAsOwnerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetAttachedImageAsOwnerApiResponse =
  /** status 200 OK */ AttachedImageBase;
export type GetAttachedImageAsOwnerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PutAttachedImageAsOwnerApiResponse =
  /** status 202 Accepted */ AttachedImageBase;
export type PutAttachedImageAsOwnerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteAttachedImageAsOwnerApiResponse = /** status 200 Accepted */ {
  message?: string;
};
export type DeleteAttachedImageAsOwnerApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type GetLimitedTimeNotificationsAsOwnerApiResponse =
  /** status 200 OK */ {
    limited_time_notifications: LimitedTimeNotificationList[];
    total_count: number;
  };
export type GetLimitedTimeNotificationsAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostLimitedTimeNotificationAsOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostLimitedTimeNotificationAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    message: string;
    start_time: string;
    end_time: string;
    add_roles: SelectRoleByLimitedTimeNotification[];
    add_company_ids?: string[];
    add_user_tag_ids?: string[];
  };
};
export type GetLimitedTimeNotificationAsOwnerApiResponse =
  /** status 200 OK */ LimitedTimeNotificationDetail;
export type GetLimitedTimeNotificationAsOwnerApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutLimitedTimeNotificationAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutLimitedTimeNotificationAsOwnerApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    title: string;
    message: string;
    start_time: string;
    end_time: string;
    add_roles: SelectRoleByLimitedTimeNotification[];
    add_company_ids?: string[];
    add_user_tag_ids?: string[];
    remove_role_ids?: string[];
    remove_company_ids?: string[];
    remove_user_tag_ids?: string[];
  };
};
export type DeleteLimitedTimeNotificationAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteLimitedTimeNotificationAsOwnerApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetLimitedTimeNotificationNoticeAsOwnerApiResponse =
  /** status 200 OK */ {
    limited_time_notifications: LimitedTimeNotificationNoticeBase[];
    total_count: number;
  };
export type GetLimitedTimeNotificationNoticeAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetLimitedTimeNotificationNoticeDetailsAsOwnerApiResponse =
  /** status 200 OK */ LimitedTimeNotificationNoticeBase;
export type GetLimitedTimeNotificationNoticeDetailsAsOwnerApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostLimitedTimeNotificationNoticeCheckAsOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostLimitedTimeNotificationNoticeCheckAsOwnerApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTtCsvExportsAsOwnerApiResponse = /** status 200 OK */ {
  tt_csv_exports: TtCsvExportBase[];
  total_count: number;
};
export type GetTtCsvExportsAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  types?: TtCsvExportType[];
};
export type PostTtCsvExportAsOwnerApiResponse =
  /** status 200 OK */ TtCsvExportBase;
export type PostTtCsvExportAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  sortedBy?: string;
  searchKey?: string[];
  searchValue?: string[];
  body: {
    type: TtCsvExportType;
  };
};
export type PostTtCategoryBaseAsOwnerApiResponse =
  /** status 201 Created */ TtCategoryBase;
export type PostTtCategoryBaseAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  createTtCategoryRequestBody: CreateTtCategoryRequestBody;
};
export type PutTtCategoryBaseAsOwnerApiResponse =
  /** status 202 Accepted */ TtCategoryBase;
export type PutTtCategoryBaseAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  id: string;
  updateTtCategoryRequestBody: UpdateTtCategoryRequestBody;
};
export type GetDistributionStatusesAsOwnerApiResponse = /** status 200 OK */ {
  distribution_statuses: DistributionStatusList[];
  total_count: number;
};
export type GetDistributionStatusesAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  homeworkTemplateId?: string;
};
export type DeleteCommentImageAsOwnerApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteCommentImageAsOwnerApiArg = {
  categorizableType: CommentImageCategorizableType;
  categorizableId: string;
  /** CommentImage ID */
  id: string;
};
export type GetInterviewRequestsAsOwnerApiResponse = /** status 200 OK */ {
  interview_requests: InterviewRequestListBase[];
  total_count: number;
};
export type GetInterviewRequestsAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  status?: InterviewRequestStatus;
  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  searchType?: InterviewRequestSearchType;
  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  searchWord?: string;
};
export type GetInterviewRequestAsOwnerApiResponse =
  /** status 200 OK */ InterviewRequestDetailBase;
export type GetInterviewRequestAsOwnerApiArg = {
  /** Interview::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetInterviewRequestArticleAsOwnerApiResponse =
  /** status 200 OK */ ArticleDetailBase;
export type GetInterviewRequestArticleAsOwnerApiArg = {
  /** Interview::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetInterviewRequestUnreadCountsAsOwnerApiResponse =
  /** status 200 OK */ {
    unread_counts: (ModelBase & {
      count: number;
    })[];
  };
export type GetInterviewRequestUnreadCountsAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  requestIds?: string[];
};
export type GetInterviewMessagesAsOwnerApiResponse = /** status 200 OK */ {
  interview_messages: InterviewRequestListBase2[];
  total_count: number;
};
export type GetInterviewMessagesAsOwnerApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetInterviewMessageReadAsOwnerApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type GetInterviewMessageReadAsOwnerApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    unread_message_ids: string[];
  };
};
export type GetInterviewFeedbacksAsOwnerApiResponse = /** status 200 OK */ {
  interview_feedbacks: InterviewFeedbackBase[];
  total_count: number;
};
export type GetInterviewFeedbacksAsOwnerApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type FetchSponsorProjectListAsOwnerApiResponse = /** status 200 OK */ {
  projects: HasIdAndName[];
  total_count: number;
};
export type FetchSponsorProjectListAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  professorAffiliateId: string;
};
export type GetGroupAffiliatePackagesAsOwnerApiResponse = /** status 200 OK */ {
  group_affiliate_packages: GroupAffiliatePackageGroupOnlyList[];
  total_count: number;
};
export type GetGroupAffiliatePackagesAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  homeworkTemplateId?: string;
  distributed?: boolean;
};
export type PostGroupAffiliatePackageAsOwnerApiResponse =
  /** status 201 Created */ GroupAffiliatePackageBase;
export type PostGroupAffiliatePackageAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postGroupAffiliatePackage: PostGroupAffiliatePackage;
};
export type GetGroupAffiliatePackageAsOwnerApiResponse =
  /** status 200 OK */ GroupAffiliatePackageBase;
export type GetGroupAffiliatePackageAsOwnerApiArg = {
  /** GroupAffiliatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutGroupAffiliatePackageAsOwnerApiResponse =
  /** status 202 Accepted */ GroupAffiliatePackageBase;
export type PutGroupAffiliatePackageAsOwnerApiArg = {
  /** GroupAffiliatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putGroupAffiliatePackage: PutGroupAffiliatePackage;
};
export type DeleteGroupAffiliatePackageAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGroupAffiliatePackageAsOwnerApiArg = {
  /** GroupAffiliatePackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostPackagingAsOwnerApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostPackagingAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    package_id: string;
    packageable_type: PackagingPackageableType;
    packageable_id: string;
  };
};
export type PutPackagingAsOwnerApiResponse = /** status 202 202 Accepted */ {
  message?: string;
};
export type PutPackagingAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** - パッケージのID
    - e.g. GroupAffiliatePackage の場合は、その id を指定する */
  packageId: string;
  /** - パッケージに登録するコンテンツの Modelの名前 を指定
    - e.g. GroupAffiliatePackage の場合は、Affiliate を指定する */
  packageableType: PackagingPackageableType;
  /** - パッケージに登録するコンテンツの ID を指定
    - e.g. GroupAffiliatePackage の場合は、Affiliateのid を指定する */
  packageableId: string;
  body: {
    sequence: number;
  };
};
export type DeletePackagingAsOwnerApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type DeletePackagingAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** - パッケージのID
    - e.g. GroupAffiliatePackage の場合は、その id を指定する */
  packageId: string;
  /** - パッケージに登録するコンテンツの Modelの名前 を指定
    - e.g. GroupAffiliatePackage の場合は、Affiliate を指定する */
  packageableType: string;
  /** - パッケージに登録するコンテンツの ID を指定
    - e.g. GroupAffiliatePackage の場合は、Affiliateのid を指定する */
  packageableId: string;
};
export type BatchCreatePackagingAsOwnerApiResponse = /** status 201 Created */ {
  message?: string;
};
export type BatchCreatePackagingAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    package_id: string;
    packageable_type: PackagingPackageableType;
    packageable_ids: string[];
  };
};
export type BatchUpdatePackagingAsOwnerApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type BatchUpdatePackagingAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    package_id: string;
    packageable_type: PackagingPackageableType;
    packageable_ids: string[];
    sequence: {
      packageable_id: string;
      sequence: number;
    }[];
  };
};
export type BatchDeletePackagingAsOwnerApiResponse = /** status 200 Ok */ {
  message?: string;
};
export type BatchDeletePackagingAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    package_id: string;
    packageable_type: string;
    packageable_ids: string[];
  };
};
export type PutHomeworkTemplateCustomValueAsOwnerApiResponse =
  /** status 202 Accepted */ {
    homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  };
export type PutHomeworkTemplateCustomValueAsOwnerApiArg = {
  /** HomeworkTemplateCustomValue ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  customValueRequestBodyAsOwnerAndTeacherAndReviewer: CustomValueRequestBodyAsOwnerAndTeacherAndReviewer;
};
export type GetCommonTagsAsOwnerApiResponse = /** status 200 OK */ {
  common_tags: CommonTagBase[];
  total_count: number;
};
export type GetCommonTagsAsOwnerApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: "name";
  searchWord?: string;
  kind?: CommonTagKind;
  categorizableType?: CommonTaggingCategorizableType;
};
export type PostCommonTaggingAsOwnerApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostCommonTaggingAsOwnerApiArg = {
  categorizableType: CommonTaggingCategorizableType;
  categorizableId: string;
  commonTaggingRequestBody: CommonTaggingRequestBody;
};
export type DeleteCommonTaggingAsOwnerApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteCommonTaggingAsOwnerApiArg = {
  categorizableType: CommonTaggingCategorizableType;
  categorizableId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  commonTagId: string;
};
export type ErrorsWithKeyMessage = {
  errors: {
    key?: string;
    message?: string;
  }[];
};
export type BulkUpdateRequestBody = {
  ids: string[];
  student_suspension: boolean;
};
export type CategoryRenameMap = {
  id?: string | null;
  for_text: string | null;
  for_subject: string | null;
  for_grade: string | null;
  for_major: string | null;
  for_middle: string | null;
  for_minor: string | null;
} | null;
export type CategoryType =
  | "text"
  | "grade"
  | "subject"
  | "major"
  | "middle"
  | "minor";
export type BaseCategoryInfo = {
  id: string;
  name: string;
  depth: number;
  type: CategoryType;
  student_visible?: boolean;
  teacher_visible?: boolean;
  owner_visible?: boolean;
  is_help?: boolean;
  student_help_page?: boolean;
  teacher_help_page?: boolean;
  owner_help_page?: boolean;
  reviewer_help_page?: boolean;
  image?: string | null;
};
export type HasIdAndName = {
  id: string;
  name: string;
};
export type ModelBase = {
  id: string;
};
export type UserTagBasic = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_linking_users?: number;
  kind?: string;
};
export type TextCategoryInfo = BaseCategoryInfo & {
  linking_company_tags?: HasIdAndName[];
  linking_user_tags?: UserTagBasic[];
};
export type GradeCategoryInfo = BaseCategoryInfo & {
  text_id: string;
};
export type SubjectCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  text_id: string;
};
export type MajorCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type MiddleCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  major_category_id: string;
  text_id: string;
};
export type MinorCategoryInfo = BaseCategoryInfo & {
  major_category_id: string;
  middle_category_id: string;
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type CategoryInfo =
  | TextCategoryInfo
  | GradeCategoryInfo
  | SubjectCategoryInfo
  | MajorCategoryInfo
  | MiddleCategoryInfo
  | MinorCategoryInfo;
export type PutCategoryRequestBodyAsOwner = {
  name: string;
  student_visible?: boolean;
  teacher_visible?: boolean;
  image?: string;
  add_user_tags?: (string | null)[];
  remove_user_tags?: (string | null)[];
};
export type CookieInfo = {
  cloudfront_policy: string;
  cloudfront_signature: string;
  cloudfront_key_pair_id: string;
  unsigned_url: string;
};
export type VideoInfo = {
  id: string;
  title: string;
  material_id: string;
  file_url: string | null;
  file: {
    url?: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string;
  thumbnail: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
};
export type MaterialWorkStatusJa =
  | "\u672A\u7740\u624B"
  | "\u53D7\u8B1B\u4E2D"
  | "\u5B8C\u4E86";
export type MaterialWorkStatus = "todo" | "in_progress" | "completed";
export type MaterialWorkList = ModelBase & {
  status: MaterialWorkStatusJa;
  status_code: MaterialWorkStatus;
  forbid_download: boolean;
  distributed_by_super_company: boolean;
  is_distributed: boolean;
  is_expired: boolean;
  is_available: boolean;
};
export type OwnerMaterialWork = MaterialWorkList & {
  created_at: string;
  material: {
    id: string;
    title: string;
    thumbnail: {
      original: string | null;
      large: string | null;
      medium: string | null;
      small: string | null;
    };
    tags?: HasIdAndName[];
    description: string;
  };
  user: {
    id: string;
    first_name: string;
    last_name: string;
    name: string;
  };
};
export type BasicUserInfo = ModelBase & {
  first_name: string;
  last_name: string;
  email: string;
  optional_email?: string | null;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  expired_date?: string | null;
  current_sign_in_at?: string | null;
  last_sign_in_at?: string | null;
  linked_project_count?: number | null;
  affiliate_id?: string | null;
  nickname: string | null;
};
export type AffiliateStatusJa =
  | "\u5229\u7528\u4E2D"
  | "\u671F\u9650\u5207\u308C"
  | "\u767B\u9332\u5F85\u3061"
  | "\u30ED\u30C3\u30AF\u4E2D"
  | "\u6E96\u5099\u4E2D"
  | "\u672A\u627F\u8A8D"
  | "\u5229\u7528\u505C\u6B62";
export type AffiliateAccount = ModelBase & {
  invitation_scheduled_at: string | null;
  status: AffiliateStatusJa;
  last_accessed_at: string | null;
};
export type TeacherListAsOwner = BasicUserInfo & {
  affiliate: AffiliateAccount;
};
export type HasAffiliateIdAndName = {
  id: string;
  affiliate_id: string;
  name: string;
  nickname: string;
};
export type TeacherUserInfo = BasicUserInfo & {
  teacher_info: {
    postcode: string;
    prefecture: string;
    address1: string;
    address2: string;
    has_register_student: boolean;
  };
};
export type UserList = BasicUserInfo & {
  affiliate: AffiliateAccount;
};
export type StudentListAsOwner = UserList & {
  grade?: string;
  number?: number | null;
  material_work?: MaterialWorkList & {
    distribution_time: string | null;
    expiration_time: string | null;
  };
  user_tags_count: number;
  group_affiliate_packages_count: number;
};
export type UserInputSetting = "required" | "optional" | "hidden";
export type StudentInputSetting = {
  avatar: UserInputSetting;
  postcode: UserInputSetting;
  prefecture: UserInputSetting;
  address1: UserInputSetting;
  address2: UserInputSetting;
  birth_date: UserInputSetting;
  grade: UserInputSetting;
  phone: UserInputSetting;
  parent_first_name: UserInputSetting;
  parent_last_name: UserInputSetting;
  number: UserInputSetting;
};
export type SortedByStudentList = "grade" | "numberAsc" | "numberDesc";
export type StudentGradeJa =
  | "\u5B66\u5E74\u672A\u8A2D\u5B9A"
  | "\u5C0F\u5B661\u5E74"
  | "\u5C0F\u5B662\u5E74"
  | "\u5C0F\u5B663\u5E74"
  | "\u5C0F\u5B664\u5E74"
  | "\u5C0F\u5B665\u5E74"
  | "\u5C0F\u5B666\u5E74"
  | "\u4E2D\u5B661\u5E74"
  | "\u4E2D\u5B662\u5E74"
  | "\u4E2D\u5B663\u5E74"
  | "\u9AD8\u68211\u5E74"
  | "\u9AD8\u68212\u5E74"
  | "\u9AD8\u68213\u5E74"
  | "\u5927\u5B661\u5E74"
  | "\u5927\u5B662\u5E74"
  | "\u5927\u5B663\u5E74"
  | "\u5927\u5B664\u5E74"
  | "\u305D\u306E\u4ED6"
  | "\u5B66\u5E74\u306A\u3057";
export type UserTagTypeBasic = ModelBase & {
  name: string;
  kind?: string;
  teacher_visible: boolean;
  role_type?: string;
};
export type GroupAffiliatePackageMinimalBase = ModelBase & {
  title: string;
};
export type OwnerListAsOwner = BasicUserInfo & {
  affiliate: AffiliateAccount;
};
export type Avatar = {
  url: string | null;
};
export type StudentGrade =
  | "under"
  | "elementary1"
  | "elementary2"
  | "elementary3"
  | "elementary4"
  | "elementary5"
  | "elementary6"
  | "junior1"
  | "junior2"
  | "junior3"
  | "high1"
  | "high2"
  | "high3"
  | "university1"
  | "university2"
  | "university3"
  | "university4"
  | "others";
export type BasicStudentInfo = {
  birth_date?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  grade?: string;
  grade_value?: StudentGrade;
  number?: number | null;
};
export type TeacherInfo = {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  has_register_student?: boolean;
};
export type UserAuthorization = {
  material_distribution?: boolean;
  nationwide_user_tag?: boolean;
  limited_user_tag?: boolean;
  homework_review?: boolean;
  user_edit?: boolean;
  notification_delivery?: boolean;
  send_inquiry_email?: boolean;
};
export type ProfessorInfo = {
  position: string | null;
  department: string | null;
  description: string | null;
  limit_request: number | null;
  profile_picture: string | null;
};
export type UserAccountType = "email" | "account_id";
export type UserDetail = BasicUserInfo & {
  avatar?: Avatar;
  student_input_settings?: StudentInputSetting;
  student_info?: BasicStudentInfo;
  teacher_info?: TeacherInfo;
  user_authorizations?: UserAuthorization;
  student_affiliate_id?: string;
  professor_info?: ProfessorInfo;
  account_type?: UserAccountType;
  unique_id?: string | null;
};
export type StudentInfoAttributes = {
  birth_date?: string;
  grade?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  number?: number;
};
export type TeacherInfoAttributes = {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  has_register_student?: boolean;
};
export type Option = {
  use_video?: boolean;
  custom_student_ui?: boolean;
  homework_feature?: boolean;
  blog_feature?: boolean;
  text_seller?: boolean;
  use_image?: boolean;
  material?: boolean;
  teacher_material?: boolean;
  report?: boolean;
  payment?: boolean;
  calender?: boolean;
  review_feature?: boolean;
  reviewer_company?: boolean;
  tag?: boolean;
  google_classroom?: boolean;
  steam_library?: boolean;
  content_company?: boolean;
  content_company_review_feature?: boolean;
  dashboard_feature?: boolean;
  study_log_feature?: boolean;
  ideal_feature?: boolean;
  pbl_board_feature?: boolean;
  exam_feature?: boolean;
  rubric_feature?: boolean;
  word_cloud_feature?: boolean;
  rubric_evaluate_feature?: boolean;
  google_drive_feature?: boolean;
  one_drive_feature?: boolean;
  university_cooperation_feature?: boolean;
  article_student_comment_feature?: boolean;
  homework_automatic_scoring_feature?: boolean;
  class_nav_feature?: boolean;
  use_account_lock_mail?: boolean;
  gogo_feature?: boolean;
  pbl_projects_feature?: boolean;
  use_article_and_homework_mail?: boolean;
};
export type DeletionProgress = "running" | "failed" | "idle";
export type Company = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  custom_master?: {
    title?: string;
    value?: string;
  }[];
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
  report_recipient_email?: string;
  report_the_number_of_users_email?: string;
  report_homework_template_distribution_histories_email?: string;
  inquiry_email?: string;
  limited_user_tag_monthly_report_email?: string;
  created_at?: string;
  owner_number?: number;
  teacher_number?: number;
  student_number?: number;
  has_super_owners?: boolean;
  options?: Option;
  company_tags?: {
    id?: string;
    name?: string;
    grade?: string | null;
    company_tag_type_id?: string;
    company_tag_type_name?: string;
  }[];
  linking_reviewer_companies?: boolean;
  student_input_settings?: StudentInputSetting;
  unique_id?: string | null;
  deletion_progress?: DeletionProgress | null;
  reviewer_company_group?: {
    id?: string;
    name?: string;
  };
  student_count_by_grade?: {
    grade?: string;
    count?: number;
  }[];
};
export type CompanyAsOwner = Company & {
  student_count_by_grade: {
    grade?: string;
    count?: number;
  }[];
  student_input_settings: StudentInputSetting;
  custom_master?: {
    title?: string;
    value?: string;
  }[];
  deletion_progress?: DeletionProgress | null;
  options: Option;
};
export type LimitOptionBase = {
  over_capacity_material?: boolean | null;
  over_capacity_ideal?: boolean | null;
};
export type ReviewerCompanyGroupBase = ModelBase & {
  name: string;
  is_main_reviewer_group: boolean;
  super_company: ModelBase & {
    name?: string;
  };
  is_public_profile?: boolean;
};
export type CreateAccountUserResponse = {
  user?: {
    id: string;
    account_id: string;
    full_name: string;
    password: string;
    affiliate: {
      company_id: string;
      company_name: string;
      role: string;
    };
  };
};
export type CreateAccountUserRequestBody = {
  email: string;
  last_name: string;
  first_name: string;
  optional_email?: string | null;
  role_name: string;
  password: string;
  authorizations?: UserAuthorization;
  user_params?: {
    grade?: string;
    parent_last_name?: string;
    parent_first_name?: string;
    postcode?: string;
    prefecture?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    birth_date?: string;
    number?: number;
  };
  user_tag_ids?: string[] | null;
  group_ids?: string[] | null;
};
export type AccountUserInfo = {
  user_id: string;
  full_name: string;
  email: string;
  new_password: string;
  group_affiliate_packages?:
    | {
        title?: string;
      }[]
    | null;
};
export type AuthorizationPasswordRequestBody = {
  password: string;
  affiliate_id?: string;
};
export type UserType = "student" | "teacher" | "owner" | "reviewer";
export type CategoryPart = {
  id: string;
  name: string;
  depth: number;
  type: string;
  text_id?: string;
  grade_id?: string;
  subject_id?: string;
  major_category_id?: string;
  middle_category_id?: string;
};
export type CategoryBase = {
  category: CategoryPart;
};
export type MaterialList = ModelBase &
  CategoryBase & {
    title: string;
    thumbnail_file?: string | null;
    is_public: boolean;
    is_video_updating: boolean;
    tags: HasIdAndName[];
  };
export type OwnerMaterialList = MaterialList & {
  material_work?: MaterialWorkList;
};
export type ImageInfo = {
  id: string;
  title: string;
  material_id: string;
  file: {
    url: string;
    thumb?: {
      url: string | null;
    };
    icon?: {
      url: string | null;
    };
  };
  edited_image_url?: string | null;
};
export type MaterialDetail = MaterialList & {
  description: string;
  memo?: string | null;
  download_allowed: boolean;
  is_owned: boolean;
  images: ImageInfo[];
  videos: VideoInfo[];
  html_file: {
    title?: string | null;
    url?: string | null;
  };
};
export type CreateMaterialRequestBody = {
  title: string;
  thumbnail_file?: Blob;
  memo?: string;
  category_id?: string;
  tag_list?: string[];
  image_content_titles?: string[];
  image_contents?: Blob[];
  video_content_title?: string;
  video_content_file?: Blob;
  video_content_thumbnail?: Blob;
  video_content_external_link?: string;
  description?: string;
  is_public: boolean;
  is_video_updating?: boolean;
  download_allowed: boolean;
};
export type UpdateMaterialRequestBody = {
  title: string;
  category_id?: string;
  memo?: string;
  description?: string;
  download_allowed: boolean;
  is_public: boolean;
  is_video_updating?: boolean;
  thumbnail_file?: Blob;
  video_id?: string;
  video_content_title?: string;
  video_content_thumbnail?: Blob;
  video_content_file?: Blob;
  video_content_external_link?: string;
  image_content_titles?: string[];
  image_contents?: Blob[];
  tag_list?: string[];
  removed_image_ids?: string[];
  removed_video_ids?: string[];
};
export type HasIdAndTitle = {
  id: string;
  title: string;
};
export type MaterialSortedByColumn = "title" | "created_at";
export type SortedByOrder = "asc" | "desc";
export type MaterialWork = MaterialWorkList & {
  created_at: string;
  material: {
    title: string;
  };
};
export type Report = ModelBase & {
  created_at?: string;
  teacher_name?: string;
  student_name?: string;
  detail?: string;
  reply?: string | null;
  status?: string;
  title?: string;
  next_goal?: string;
  monthly_teaching_times?: number;
  monthly_student_schedule_change?: number;
  monthly_teacher_schedule_change?: number;
  homework_frequency?: number;
  is_schedule_change_required?: boolean;
  schedule_detail_reason?: string;
  test_misc?: string;
  text_subjects?: string[];
  schedule_change_period?: string;
  schedule_change_notified?: string;
  is_schedule_change_notified?: boolean;
  substitute_lecture?: boolean;
  test_type?: string;
  no_homework_reason?: string;
  no_text_reason?: string;
  reports_test_results?: {
    subject: string;
    current_score: number;
    positive_negative: boolean;
    score_delta: number;
  }[];
};
export type ReportStatus = "submitted" | "answered" | "confirmed" | "closed";
export type PaymentStatus =
  | "initiated"
  | "customer_confirmed"
  | "teacher_confirmed"
  | "owner_confirmed";
export type Payment = ModelBase & {
  student_name: string | null;
  teacher_name: string | null;
  title: string | null;
  price?: number;
  status: string;
  status_code: PaymentStatus;
  created_at: string;
  due_date: string | null;
  year: number | null;
  month: number | null;
};
export type HomeworkStatusJa =
  | "\u65B0\u898F"
  | "\u63D0\u51FA\u6E08\u307F"
  | "\u6559\u8077\u54E1\u56DE\u7B54\u6E08\u307F"
  | "\u6DFB\u524A\u524D"
  | "\u6DFB\u524A\u4E2D"
  | "\u6DFB\u524A\u6E08\u307F"
  | "\u8FD4\u5374\u6E08\u307F"
  | "\u8981\u518D\u63D0\u51FA";
export type HomeworkStatus =
  | "todo"
  | "submitted"
  | "checked"
  | "submitted_reviewer"
  | "assigned_reviewer"
  | "checked_reviewer"
  | "returned_reviewer"
  | "change_requested";
export type StudentHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
};
export type CommonTagKind = "system" | "free" | "management";
export type CommonTagBase = ModelBase & {
  kind: CommonTagKind;
  name: string;
};
export type HomeworkBase = ModelBase & {
  title: string;
  status: HomeworkStatusJa;
  status_code: HomeworkStatus;
  submitted_at: string | null;
  student_affiliate_id: string | null;
  student_name: string;
  student_nickname?: string | null;
  teacher_name: string;
  start_at: string | null;
  end_at: string | null;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  student_number?: number | null;
  student_homework_images: StudentHomeworkImage[];
  has_attached_file: boolean;
  common_tags: CommonTagBase[];
  ideal_thumbnail_file: string | null;
};
export type HomeworkOrderType =
  | "student_name"
  | "affiliate_package_name"
  | "student_number"
  | "submitted_at"
  | "status"
  | "user_tag_name"
  | "user_affiliate_package_name";
export type HomeworkTemplateDistributionStatus =
  | "todo"
  | "distributed_students"
  | "submitted_reviewer"
  | "checked_reviewer"
  | "returned";
export type CategorizableCommentCategorizableType = "Homework";
export type AffiliateInfoBase = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  role_name: string;
  nickname: string | null;
};
export type CommentImageCategorizableType = "Homework" | "CategorizableComment";
export type CommentImageBase = ModelBase & {
  categorizable_id?: string;
  categorizable_type?: CommentImageCategorizableType;
  file_name?: string;
  file_url?: string;
};
export type CategorizableCommentBase = ModelBase & {
  categorizable_type: CategorizableCommentCategorizableType;
  categorizable_id: string;
  body: string;
  created_at: string;
  affiliate: AffiliateInfoBase;
  images: CommentImageBase[] | null;
};
export type AffiliateName = {
  affiliate_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  nickname: string | null;
};
export type TeacherHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
  from_template?: boolean;
  temp_data?: string | null;
};
export type ReviewerHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
};
export type HomeworkTemplateCustomFieldsTypes =
  | "HomeworkTemplateCustomTextField"
  | "HomeworkTemplateCustomRadioField"
  | "HomeworkTemplateCustomSelectField"
  | "HomeworkTemplateCustomProfileField";
export type CustomFieldScoreBase = {
  possible_scores: number[] | null;
  possible_scores2: number[] | null;
  answer_flags?: boolean[] | null;
  is_exact_match: boolean | null;
};
export type HasNumberIdAndName = {
  id: number;
  name: string;
};
export type CustomFieldCustomMasterMap = ModelBase & {
  name: string;
  public_columns: HasNumberIdAndName[];
  filter_columns: HasNumberIdAndName[];
  filter_cmmrs: HasIdAndName[];
};
export type TtCategoryBaseType = "TtCategory::HomeworkTemplateCustomField";
export type TtCategoryBase = ModelBase & {
  name: string;
  type: TtCategoryBaseType;
};
export type HomeworkTemplateCustomField = ModelBase & {
  homework_templates_id: string;
  type: HomeworkTemplateCustomFieldsTypes;
  sequence: number;
  title: string;
  possible_values: string[];
  min_length: number | null;
  max_length: number | null;
  is_required: boolean;
  created_at: string;
  updated_at: string;
  score?: CustomFieldScoreBase;
  custom_master_map?: CustomFieldCustomMasterMap;
  custom_field_category?: TtCategoryBase;
};
export type AttachedImageCategorizableType =
  | "Material"
  | "Ideal::Board"
  | "Article"
  | "Pbl::Board::Content"
  | "Pbl::Sponsor::Lecture"
  | "Ideal::BoardTemplate"
  | "Interview::Message"
  | "HomeworkTemplateCustomFieldFeedback"
  | "Gogo::Message";
export type AttachedImageBase = ModelBase & {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
  file_name: string;
  file_url: string;
};
export type HomeworkTemplateCustomFieldFeedback = ModelBase & {
  body: string;
  commenter_affiliate: AffiliateInfoBase;
  created_at: string;
  feedback_image: AttachedImageBase[];
  wysiwyg_body: string | null;
};
export type CustomMasterPublics = {
  id: string;
  public_columns: HasNumberIdAndName[];
};
export type StudentHomeworkTemplateCustomValue = ModelBase & {
  homework_template_custom_fields_id: string;
  student_id: string;
  profile_values?: {
    user_last_name?: string | null;
    user_first_name?: string | null;
    email?: string | null;
    number?: number | null;
    birth_date?: string | null;
    grade_value?: StudentGrade | null;
    grade?: string | null;
    phone?: string | null;
    parent_last_name?: string | null;
    parent_first_name?: string | null;
    postcode?: string | null;
    prefecture?: string | null;
    address1?: string | null;
    address2?: string | null;
  };
  value?: string | null;
  created_at: string;
  updated_at: string;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  custom_master?: CustomMasterPublics;
  wysiwyg_body?: string | null;
};
export type HomeworkTemplateCustomFieldAndValue =
  HomeworkTemplateCustomField & {
    custom_field_feedbacks: HomeworkTemplateCustomFieldFeedback[];
    homework_template_custom_values: StudentHomeworkTemplateCustomValue[];
    homework_template_custom_value_score?: {
      score?: number | null;
    } | null;
  };
export type IdealBoardStatusJa =
  | "\u65B0\u898F"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86";
export type IdealBoardStatus = "todo" | "in_progress" | "completed";
export type IdealBoardSize = "S" | "M" | "MV" | "L";
export type HomeworkBoardBase = ModelBase & {
  title?: string;
  template_id?: string | null;
};
export type BoardBase = ModelBase & {
  name: string;
  status: IdealBoardStatusJa;
  status_code: IdealBoardStatus;
  size: IdealBoardSize;
  created_at: string;
  template_id: string | null;
  homework?: HomeworkBoardBase | null;
  host_affiliate_id: string | null;
};
export type ApprovingStatus = "draft" | "approved";
export type ApprovingBase = ModelBase & {
  status: ApprovingStatus;
  affiliate: AffiliateName;
};
export type HomeworkDetails = HomeworkBase & {
  homework_status_changed_history: {
    status?: HomeworkStatusJa;
    updated_at?: string;
  }[];
  change_requested_at?: string | null;
  teacher_checked_at?: string | null;
  submitted_reviewer_at?: string | null;
  checked_reviewer_at?: string | null;
  returned_reviewer_at?: string | null;
  distribution_status_code?: HomeworkTemplateDistributionStatus;
  needs_review: boolean;
  needs_score: boolean;
  is_exam: boolean;
  created_at: string;
  updated_at: string;
  student_comment: string;
  teacher_comment?: string | null;
  reviewer_comments?: CategorizableCommentBase[] | null;
  teacher_comment_for_reviewer?: string | null;
  reviewer_comment_for_teacher?: string | null;
  score: number | null;
  assignees: AffiliateName[];
  teacher_homework_images: TeacherHomeworkImage[];
  reviewer_homework_images: ReviewerHomeworkImage[];
  content: string;
  company?: {
    id?: string;
    name?: string;
  };
  template?: {
    id?: string;
    title?: string;
  };
  reviewer_end_at?: string | null;
  homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  ideal_board?: BoardBase;
  teacher_comment_images?: CommentImageBase[];
  approvings: ApprovingBase[];
  distribution?: {
    start_at?: string | null;
    end_at?: string | null;
  };
  ideal_thumbnail_file?: string | null;
};
export type BatchUpdateHomeworkRequest = {
  homework_ids: string[];
  status: HomeworkStatus;
  teacher_comment?: string;
  teacher_comment_for_reviewer?: string;
};
export type HomeworkDownloadUrl = ModelBase & {
  student_name: string;
  group_affiliate_name: string;
  student_number?: number | null;
  download_urls: string[];
  is_group: boolean;
};
export type CustomFieldFeedbackRequestBody = {
  body: string;
  homework_id: string;
  files?: Blob[];
  wysiwyg_body?: string;
};
export type PutCustomFieldFeedbackRequestBody = {
  body: string;
  wysiwyg_body?: string;
  homework_id: string;
};
export type EmailBase = ModelBase & {
  title?: string;
  created_at?: string;
  to_all_students?: boolean;
  to_all_teachers?: boolean;
  to_all_owners?: boolean;
  from_user?: string;
  sent_count?: number;
  body?: string;
  user_tags?: UserTagBasic[];
  sender_companies?: {
    id?: string;
    name?: string;
  }[];
};
export type NotificationBase = ModelBase & {
  title: string;
  message: string;
  resource_info: string | null;
  sender?: {
    id?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
  };
  created_at: string;
  updated_at: string;
  notified_time: string;
};
export type NotificationStatus =
  | "reserved"
  | "waiting"
  | "delivering"
  | "delivered";
export type Notifications = {
  notifications?: (NotificationBase & {
    user_tags?: UserTagBasic[];
    company_tags?: {
      id?: string;
      name?: string;
    }[];
    roles?: {
      id?: string;
      name?: string;
      jp_name?: string;
    }[];
    reservation_time?: string;
    number_of_reserve_deliveries?: number | null;
    status?: NotificationStatus | null;
    companies?: {
      id?: string;
      name?: string;
    }[];
  })[];
  total_count?: number;
};
export type Notification = NotificationBase & {
  user_tags?: UserTagBasic[];
  company_tags?: {
    id?: string;
    name?: string;
  }[];
  roles?: {
    id?: string;
    name?: string;
    jp_name?: string;
  }[];
  reservation_time?: string;
  number_of_reserve_deliveries?: number | null;
  companies?: {
    id?: string;
    name?: string;
  }[];
  number_of_deliveries?: number;
};
export type NotificationRequest = {
  title: string;
  message: string;
  target_roles: string[];
  target_user_tag_ids?: string[];
  target_company_ids: string[];
  target_company_tag_ids?: string[];
  target_affiliate_ids?: string[];
  publication_time?: string;
  with_email?: boolean;
};
export type ArticleKind = "default" | "poster";
export type ArticleStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u518D\u63D0\u51FA/\u63D0\u51FA\u5F85\u3061"
  | "\u5B8C\u4E86"
  | "\u5B8C\u4E86"
  | "\u63D0\u51FA\u6E08\u307F/\u78BA\u8A8D\u4E2D";
export type ArticleStatus =
  | "draft"
  | "correction"
  | "reviewing"
  | "published"
  | "teacher_checking";
export type ArticleAffiliateLinking = {
  affiliate_id: string;
  user?: {
    user_id?: string;
    first_name?: string;
    last_name?: string;
    student_number?: number | null;
    nickname?: string | null;
  };
};
export type ArticleBaseCommon = ModelBase & {
  student_id: string;
  creator_student_affiliate_id?: string | null;
  student_number: number | null;
  student_name: string | null;
  student_nickname?: string | null;
  title: string;
  kind?: ArticleKind;
  status: ArticleStatusJa;
  status_code: ArticleStatus;
  created_at: string;
  created_at_with_second: string;
  updated_at: string;
  updated_at_with_second: string;
  liked?: boolean;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  company: {
    id: string;
    name: string;
  };
  students_linking: ArticleAffiliateLinking[];
  common_tags: CommonTagBase[];
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type ArticleImageBase = ModelBase & {
  file?: {
    title?: string;
    thumb_url?: string;
    file_url?: string;
  };
};
export type AllowedArticleCategorizableType =
  | "Sponsor::Info"
  | "Pbl::Sponsor::Project";
export type ArticleCategorizable = ModelBase & {
  type: AllowedArticleCategorizableType;
  name: string;
  sponsor_logo: string | null;
  company_name?: string | null;
};
export type ArticleContentType =
  | "theme"
  | "background"
  | "hypothesis"
  | "survey_method"
  | "result"
  | "summary"
  | "future_issues";
export type ArticleContent = ModelBase & {
  content_type: ArticleContentType;
  body: string | null;
};
export type ArticleSponsorContent = ModelBase & {
  pbl_sponsor_article_content_template_field_id: string;
  body: string | null;
};
export type SponsorArticleContentTemplateFieldBase = ModelBase & {
  title: string;
};
export type SponsorArticleContentTemplateBase = ModelBase & {
  title: string;
  description?: string | null;
  pbl_sponsor_lecture_id?: string;
  fields?: SponsorArticleContentTemplateFieldBase[];
};
export type ArticlePoster = {
  sponsor_logo: string | null;
  categorizable?: ArticleCategorizable;
  article_contents: ArticleContent[];
  article_sponsor_contents?: ArticleSponsorContent[];
  pbl_sponsor_article_content_template?: SponsorArticleContentTemplateBase | null;
};
export type CompanyBase = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
};
export type Role =
  | "admin"
  | "super_owner"
  | "owner"
  | "teacher"
  | "student"
  | "reviewer"
  | "content_provider";
export type RoleJa =
  | "SV\u7BA1\u7406\u8005"
  | "\u89AA\u7BA1\u7406\u8005"
  | "\u7BA1\u7406\u8005"
  | "\u6559\u8077\u54E1"
  | "\u751F\u5F92"
  | "\u6DFB\u524A\u5229\u7528\u8005"
  | "\u30B3\u30F3\u30C6\u30F3\u30C4\u4E8B\u696D\u8005";
export type RoleBase = ModelBase & {
  name: Role;
  jp_name: RoleJa;
};
export type AffiliateBase = {
  affiliate_id: string;
  user?: BasicUserInfo;
  company?: CompanyBase;
  role?: RoleBase;
};
export type GroupAffiliatePackageBase = ModelBase & {
  title: string;
  description?: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateBase;
  affiliates: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    grade?: string;
    nickname?: string;
  }[];
  assign_teacher_affiliates?: AffiliateName[];
};
export type InterviewRequestStatusJa =
  | "\u627F\u8A8D\u5F85\u3061"
  | "\u975E\u627F\u8A8D"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86"
  | "\u7121\u52B9";
export type InterviewRequestStatus =
  | "idle"
  | "non_approved"
  | "approved"
  | "completed"
  | "invalided";
export type ProfessorInfoBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    request_count?: number;
    position?: string | null;
    department?: string | null;
    description?: string | null;
    limit_request?: number | null;
    profile_picture?: string | null;
  };
};
export type InterviewRequestArticleBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  professor_affiliate: ProfessorInfoBase;
};
export type TeacherSponsorProjectListBase = ModelBase & {
  sponsor_info_id: string;
  name: string;
  company_name: string;
};
export type ArticleDetailBase = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type ArticleBase = ArticleBaseCommon & {
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
  interview_requests?: InterviewRequestArticleBase[] | null;
};
export type WordCloudWord = {
  text: string;
  value: number;
};
export type WordCloudBase = {
  word_cloud_list: WordCloudWord[];
  total_count: number;
};
export type ArticleFeedback = ModelBase & {
  article_id: string;
  teacher_id: string;
  created_at: string;
  body: string;
  teacher?: {
    first_name: string;
    last_name: string;
  };
};
export type ArticleCommentImageBase = ModelBase & {
  title: string | null;
  url: string | null;
};
export type ArticleComment = ModelBase & {
  article_id: string;
  created_at: string;
  body: string;
  commentor_id: string | null;
  commentor_company_name?: string | null;
  commenter_name?: string | null;
  commenter_nickname?: string | null;
  commentor_affiliate_id?: string | null;
  role?: RoleBase;
  article_comment_images?: ArticleCommentImageBase[];
};
export type UserTag = ModelBase & {
  name: string;
  number_of_linking_users?: number;
  limit_number?: number | null;
  is_editable?: boolean | null;
  user_tag_type?: UserTagTypeBasic;
  user_tag_company?: {
    start_at?: string | null;
  };
};
export type AffiliateInvitation = ModelBase & {
  expires_at: string | null;
  is_expired: boolean;
  role: string;
  token: string;
  grade: string;
  company: string;
  student_input_settings: StudentInputSetting;
  user_tags: UserTag[];
  signup_count: number;
  description: string;
};
export type UserTaggingBatchCreateResponses = {
  success_user_taggings: {
    user_id?: string;
    user_name?: string;
    user_tag_id?: string;
    user_tag_name?: string;
  }[];
  errors: (string | null)[];
};
export type UserTaggingBatchCreateParams = {
  user_ids: string[];
  user_tag_id: string;
  company_id?: string;
};
export type UserTagType = UserTagTypeBasic & {
  is_editable?: boolean;
  user_tags: UserTagBasic[];
};
export type UserTagTypeKind = "limited" | "nationwide";
export type UserGroup = ModelBase & {
  grade: StudentGrade;
  name: string;
  order: number;
};
export type UserTagTypeRoleType = "student" | "teacher";
export type AssignedCompany = ModelBase & {
  name: string;
  start_at?: string | null;
  number_of_linking_users: number;
};
export type UserTagDetail = ModelBase & {
  name: string;
  limit_number?: number | null;
  is_editable: boolean;
  assigned_companies: AssignedCompany[];
  user_tag_type: UserTagTypeBasic;
};
export type UserTagsBatchCreateParams = {
  user_ids: string[];
  user_tag_ids: string[];
};
export type DistributionHistoryInfo = ModelBase & {
  name: string;
  reservation_time?: string;
  distribution_time?: string;
  expiration_time?: string;
  number_of_distribution: number;
  material?: {
    id?: string;
    title?: string;
  };
  category?: {
    id?: string;
    name?: string;
  };
  grade: string | null;
  limit_number?: number;
  sent_company: HasIdAndName;
};
export type SimpleUserList = {
  id: string;
  name: string;
  nickname: string;
};
export type HomeworkTemplateStatus = "draft" | "active" | "inactive";
export type HomeworkTemplateStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u6709\u52B9"
  | "\u505C\u6B62\u4E2D";
export type HomeworkTemplateConvertStatus =
  | "not_needed"
  | "converting"
  | "adding_marks"
  | "adjusting"
  | "merging"
  | "processed"
  | "failed";
export type HomeworkTemplateConvertStatusJa =
  | "\u5909\u63DB\u306A\u3057"
  | "\u5909\u63DB\u4E2D"
  | "\u30DE\u30FC\u30AF\u4E2D"
  | "\u88DC\u6B63\u4E2D"
  | "\u30DE\u30FC\u30B8\u4E2D"
  | "\u51E6\u7406\u5B8C\u4E86"
  | "\u51E6\u7406\u5931\u6557";
export type HomeworkTemplateDistributionStatusJa =
  | "\u65B0\u898F"
  | "\u751F\u5F92\u5C55\u958B\u6E08\u307F"
  | "\u30EC\u30D3\u30E5\u30A2\u30FC\u78BA\u8A8D\u4E2D"
  | "\u30EC\u30D3\u30E5\u30A2\u30FC\u56DE\u7B54\u6E08\u307F"
  | "\u8FD4\u5374\u6E08\u307F";
export type HomeworkTemplateDistributionBase = ModelBase & {
  created_at: string;
  memo: string | null;
  max_distribution_end_users: number | null;
  end_user_count: number;
  start_at: string | null;
  end_at: string | null;
  reviewer_end_at: string | null;
  status: HomeworkTemplateDistributionStatusJa;
  status_code: HomeworkTemplateDistributionStatus;
  is_exam: boolean;
};
export type HomeworkTemplateDistributionPblPlanItem = ModelBase & {
  policy_summary: string;
};
export type HomeworkTemplateDistribution = HomeworkTemplateDistributionBase & {
  submitted_at: string | null;
  checked_at: string | null;
  checked_at_raw: string | null;
  returned_at: string | null;
  reviewer_memo: string | null;
  company_memo: string | null;
  company?: Company;
  distribution_images?: {
    reviewer_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
    super_owner_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
    teacher_image?: {
      title?: string;
      file?: Blob;
      file_url?: string;
    }[];
  };
  school_manager?: HasIdAndName;
  pbl_plan_items: HomeworkTemplateDistributionPblPlanItem[];
};
export type HomeworkTemplateBase = {
  id: string;
  created_at: string;
  title: string;
  status_code: HomeworkTemplateStatus;
  status: HomeworkTemplateStatusJa;
  convert_status_code: HomeworkTemplateConvertStatus;
  convert_status: HomeworkTemplateConvertStatusJa;
  homework_template_distribution?: HomeworkTemplateDistribution;
  needs_review: boolean;
  needs_score: boolean;
  is_exam: boolean;
  for_group: boolean;
  has_automatic_scoring: boolean;
  has_attached_file: boolean;
  end_user_count?: number;
  creator_role?: string | null;
  learning_template_package: {
    is_set: boolean;
    title: string | null;
  };
  ideal_thumbnail_file: string | null;
  common_tags: CommonTagBase[];
};
export type HomeworkTemplateMeta = {
  created_by_owned_company: boolean;
  created_homework_count: number;
  submitted_homework_count: number;
  reviewing_homework_count: number;
  finished_review_homework_count: number;
  returned_homework_count: number;
  school_homework_count: number;
  before_reviewing_homework_count: number;
  set_reviewer_homework_count: number;
};
export type HomeworkTemplateBaseForOwnerAndTeacher = HomeworkTemplateBase &
  HomeworkTemplateMeta & {
    homework_template_distribution?: HomeworkTemplateDistribution;
  };
export type HomeworkTemplateSearchType = "title";
export type IdealBoardTemplateStatus = "draft" | "reserved" | "distributed";
export type BoardTemplateList = ModelBase & {
  name: string;
  status: IdealBoardTemplateStatus;
  size: IdealBoardSize;
  start_at: string;
  homework_template?: HasIdAndTitle | null;
};
export type HomeworkTemplateAddition = {
  content: string;
  reviewer_company_group: {
    id?: string | null;
    name?: string | null;
    is_public_profile?: boolean | null;
  };
  homework_template_images?: {
    id: string;
    title: string;
    thumb_url: string;
    file_url: string;
    adjusted_file_url: string | null;
  }[];
  homework_template_custom_fields: HomeworkTemplateCustomField[];
  ideal_board_template?: BoardTemplateList | null;
  distributed: boolean;
};
export type HomeworkTemplateForOwnerAndTeacher = HomeworkTemplateBase &
  HomeworkTemplateAddition &
  HomeworkTemplateMeta & {
    homework_template_distribution?: HomeworkTemplateDistribution;
    idle_distribution_job_present: boolean;
    reviewer_company_group: {
      id?: string | null;
      name?: string | null;
    };
  };
export type HomeworkTemplateCreateRequest = {
  title: string;
  content?: string;
  needs_review?: boolean;
  needs_score?: boolean;
  is_exam?: boolean;
  for_group?: boolean;
  end_at?: string | null;
  reviewer_end_at?: string | null;
  reviewer_company_group_id?: string;
  ideal_board_template_id?: string;
  has_automatic_scoring?: boolean;
  has_attached_file?: boolean;
  homework_images?: Blob[];
  ideal_thumbnail_file?: Blob;
};
export type HomeworkTemplateUpdateRequest = {
  title?: string;
  content?: string;
  needs_score?: boolean;
  is_exam?: boolean;
  reviewer_company_group_id?: string;
  end_at?: string | null;
  reviewer_end_at?: string | null;
  has_automatic_scoring?: boolean;
  ideal_thumbnail_file?: Blob;
  has_attached_file?: boolean;
};
export type HomeworkTemplateCustomFieldType =
  | "text"
  | "radio"
  | "select"
  | "profile";
export type CustomFieldCommonRequestBody = {
  type: HomeworkTemplateCustomFieldType;
  title: string;
  sequence: number;
  min_length?: number;
  max_length?: number;
  is_required: boolean;
  possible_values?: string[];
  is_exact_match?: boolean;
  possible_scores?: number[];
  possible_scores2?: number[];
  answer_flags?: number[];
  custom_field_category_id?: string;
};
export type FileRequiredRequestBody = {
  file: Blob;
};
export type CompanyTagType = ModelBase & {
  name: string;
};
export type CompanyTag = ModelBase & {
  name: string;
  free?: boolean;
  number_of_linking_companies?: number;
  number_of_linking_users?: number;
  tag_type?: CompanyTagType;
};
export type HistoryCompany = {
  id: string;
  name: string;
  number_of_students_distributed: number;
};
export type HistoryMaterial = CategoryBase & {
  id: string;
  title: string;
  number_of_people_distributed: number;
  number_of_schools_distributed: number;
  distributed_companies: HistoryCompany[];
};
export type DistributionStatusStatus =
  | "idle"
  | "save_running"
  | "save_failed"
  | "distribution_idle"
  | "distribution_running"
  | "distribution_failed"
  | "completed";
export type MaterialDistributionHistory = {
  id: string;
  user_tags: (UserTagBasic & {
    user_tag_type_id?: string;
    user_tag_type_name?: string;
  })[];
  company_tags: CompanyTag[];
  selected_companies: HasIdAndName[];
  selected_category?: CategoryBase | null;
  materials: HistoryMaterial[];
  distribution_time?: string;
  expiration_time?: string | null;
  created_at?: string;
  status: DistributionStatusStatus | null;
};
export type MaterialDistributionHistoryListStudents = {
  total_count: number;
  number_of_students_per_grade: {
    grade: string | null;
    count: number;
  }[];
  students: (BasicUserInfo & {
    grade: string | null;
  })[];
};
export type MaterialDistributionHistorySearchByUserId = {
  id: string;
  user_hashid: string;
  last_name: string;
  first_name: string;
  company_name: string;
  distribution_time?: string;
  expiration_time?: string | null;
  reservation_time?: string | null;
  created_at?: string;
  material?: CategoryBase & {
    id?: string;
    title?: string;
    memo?: string;
    description?: string;
    image_contents?: ImageInfo[];
    video_contents?: VideoInfo[];
  };
};
export type Schema2 = ModelBase & {
  template_framework_id?: string;
  sequence?: number;
  title: string;
  description?: string;
  text_example?: string;
  text_min_char_size?: number;
  created_at: string;
  updated_at: string;
};
export type Schema = ModelBase & {
  owner: AffiliateBase;
  title: string;
  created_at: string;
  updated_at: string;
  item_frameworks?: Schema2[];
};
export type InquiryAffiliateBase = {
  affiliate_id?: string | null;
  has_deleted: boolean;
  company_id?: string | null;
  company_name?: string | null;
  user_id?: string | null;
  user_name?: string | null;
  email?: string | null;
  role?: RoleBase | null;
};
export type InquiryCommentCount = {
  count: number;
  is_new: boolean;
};
export type InquirySimpleListBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  created_at: string;
  updated_at: string;
  comments: InquiryCommentCount;
};
export type InquiryComment = ModelBase & {
  user: {
    affiliate_id?: string;
    company_id?: string;
    company_name?: string;
    user_id?: string;
    user_name?: string;
  };
  status: string;
  body: string;
  created_at: string;
  updated_at: string;
};
export type AffiliateCompanyAndUserBase = {
  affiliate_id: string;
  user_id: string;
  company_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: RoleBase;
  nickname: string | null;
};
export type InquirySimpleBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  body: string;
  category?: string | null;
  os_name: string;
  os_version: string;
  browser_name: string;
  browser_version: string;
  created_at: string;
  updated_at: string;
  comments: InquiryComment[];
  target_affiliates: AffiliateCompanyAndUserBase[];
};
export type LearningTemplatePackageDistributionSettingBase = ModelBase & {
  learning_template_package_id: string;
  start_at: string;
  end_at?: string;
  kind: string;
  grade?: StudentGrade;
  forbid_download: boolean;
  created_at: string;
  updated_at: string;
  distributor_affiliate?: AffiliateBase | null;
  companies?: CompanyBase[];
  user_tags?: UserTagBasic[];
};
export type LearningTemplatePackageBase = ModelBase & {
  title: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  is_distributed: boolean;
  affiliate: AffiliateName;
  packagings_count: number;
};
export type LearningTemplatePackageDistributionSettingBaseDetails =
  LearningTemplatePackageDistributionSettingBase & {
    learning_template_package?: LearningTemplatePackageBase;
  };
export type LearningPackageStatusJa = "\u672A\u5B8C\u4E86" | "\u5B8C\u4E86";
export type LearningPackageBase = ModelBase & {
  title: string;
  description?: string;
  status: LearningPackageStatusJa;
  start_at?: string;
  end_at?: string;
  created_at: string;
  updated_at: string;
  affiliate: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    company_id: string;
    company_name: string;
  };
};
export type LearningProgressCategorizableType =
  | "Pbl::Steam::Lecture"
  | "Pbl::Sponsor::Lecture";
export type LearningProgressBase = ModelBase & {
  categorizable_type?: LearningProgressCategorizableType;
  categorizable_id?: string;
  status?: "\u65B0\u898F" | "\u78BA\u8A8D\u6E08\u307F";
  sl_lecture_id?: number | null;
};
export type LearningPackageBaseDetails = LearningPackageBase & {
  packagings?: {
    id: string;
    packageable_type: string;
    packageable_id?: string;
    title: string | null;
    status?: string | null;
    packagings?:
      | {
          id?: string;
          packageable_type?: string;
          packageable_id?: string;
          title?: string;
          status?: string;
          start_at?: string | null;
          learning_progress?: LearningProgressBase;
        }[]
      | null;
  }[];
};
export type LearningTemplatePackageDistributionHistoryBase = ModelBase & {
  learning_template_package_id: string;
  distributor_affiliate_id: string;
  start_at: string;
  end_at?: string;
  distribution_at: string;
  kind: string;
  grade?: StudentGrade;
  forbid_download: boolean;
  number_of_completed_learning_package?: number;
  number_of_students_distributed: number;
  number_of_school_distributed?: number;
  status?: DistributionStatusStatus | null;
  deletion_progress: DeletionProgress;
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
  learning_template_package: LearningTemplatePackageBase;
};
export type DistributedStudents = {
  id: string;
  first_name: string;
  last_name: string;
  company_id: string;
  company_name: string;
  grade: string | null;
};
export type PackagingBase = ModelBase & {
  packageable_type: string;
  packageable_id: string;
  packageable_title: string;
  sl_lecture_id?: number | null;
};
export type MaterialHomeworkTemplatePackageBase = ModelBase & {
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateName;
  packagings: PackagingBase[];
};
export type PackagingAsLearningTemplatePackageBase = ModelBase & {
  packageable_type: string;
  packageable_id: string;
  packageable_title: string;
  material_homework_template_package?: MaterialHomeworkTemplatePackageBase | null;
  sl_lecture_id?: number | null;
};
export type PblBoardInfoStatusJa =
  | "\u516C\u958B\u505C\u6B62\u4E2D"
  | "\u516C\u958B\u4E2D";
export type PblBoardItemContentTypeJa =
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8"
  | "\u63A2\u7A76\u6210\u679C\u4E00\u89A7"
  | "\u30D5\u30C3\u30BF\u30FC"
  | "\u30EF\u30FC\u30C9\u30AF\u30E9\u30A6\u30C9";
export type PblBoardContentDesignTypeJa =
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C81"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C82"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C83"
  | "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C84";
export type PblBoardContentBase = ModelBase & {
  design_type: PblBoardContentDesignTypeJa;
  catchphrase?: string;
  catchphrase_description?: string;
  catchphrase_image?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
  cta_name?: string;
  cta_url?: string;
  external_link?: string;
};
export type PblBoardFooterItemBase = ModelBase & {
  title?: string;
  url?: string;
};
export type PblBoardFooterBase = ModelBase & {
  category_name?: string;
  sequence?: number;
  board_footers?: PblBoardFooterItemBase[];
};
export type PblBoardItemBase = ModelBase & {
  content_type: PblBoardItemContentTypeJa;
  sequence: number;
  board_contents?: PblBoardContentBase[];
  board_footers?: PblBoardFooterBase[];
};
export type PblBoardArticleBase = ModelBase & {
  title: string;
  kind?: ArticleKind;
  body: string;
  cover: string | null;
  company: HasIdAndName;
  images: ArticleImageBase[];
};
export type PblBoardInfoBase = ModelBase & {
  status: PblBoardInfoStatusJa;
  created_at: string;
  updated_at: string;
  board_items: PblBoardItemBase[];
  articles: PblBoardArticleBase[];
  article_total_count: number;
  company?: {
    id: string;
    name: string;
    is_content_company: boolean;
  };
};
export type PblBoardInfoEvent = "public_info" | "private_info";
export type PblBoardItemBaseAsShow = ModelBase & {
  content_type: PblBoardItemContentTypeJa;
  sequence: number;
};
export type PblBoardItemContentType =
  | "template"
  | "pbl_list"
  | "footer"
  | "word_cloud";
export type PblBoardCampaignStatusJa =
  | "\u52DF\u96C6\u524D"
  | "\u52DF\u96C6\u4E2D"
  | "\u7D42\u4E86";
export type PblBoardCampaignList = ModelBase & {
  title: string;
  start_date: string;
  end_date: string;
  status: PblBoardCampaignStatusJa;
  unconfirmed_count?: number;
  approved_count?: number;
  non_approved_count?: number;
};
export type PblBoardCampaignBase = ModelBase & {
  title: string;
  description?: string;
  start_date: string;
  end_date: string;
  status: PblBoardCampaignStatusJa;
};
export type PblBoardCampaignEvent = "submit" | "cancel" | "close";
export type PblBoardCampaignRequestStatusJa =
  | "\u672A\u78BA\u8A8D"
  | "\u627F\u8A8D"
  | "\u975E\u627F\u8A8D";
export type StandardAffiliate = {
  id: string;
  first_name: string;
  last_name: string;
  company_id: string;
  company_name: string;
};
export type PblBoardCampaignRequestList = ModelBase & {
  sequence: number;
  status: PblBoardCampaignRequestStatusJa;
  article: {
    id: string;
    title: string;
  };
  teacher_affiliate: StandardAffiliate;
};
export type PblBoardCampaignRequestStatus =
  | "unconfirmed"
  | "approved"
  | "non_approved";
export type PblBoardCampaignRequestEvent = "accept" | "reject" | "cancel";
export type ArticleDetailAsPblBoard = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type PblBoardContentDesignType =
  | "template_1"
  | "template_2"
  | "template_3"
  | "template_4";
export type LimitedTimeNotificationStatus =
  | "\u516C\u958B\u524D"
  | "\u516C\u958B\u4E2D"
  | "\u63B2\u8F09\u7D42\u4E86";
export type LimitedTimeNotificationList = ModelBase & {
  title: string;
  message: string;
  status: LimitedTimeNotificationStatus;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  roles: RoleBase[];
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
};
export type SelectRoleByLimitedTimeNotification =
  | "owner"
  | "teacher"
  | "student";
export type LimitedTimeNotificationDetail = ModelBase & {
  title: string;
  message: string;
  roles: RoleBase[];
  companies: HasIdAndName[];
  user_tags: HasIdAndName[];
};
export type LimitedTimeNotificationNoticeBase = ModelBase & {
  title: string;
  message: string;
  checked: boolean;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
};
export type TtCsvExportStatus = "running" | "completed" | "failed";
export type TtCsvExportTypeJa =
  | "\u4F01\u696D\u30D7\u30ED\u30B8\u30A7\u30AF\u30C8\u60C5\u5831"
  | "\u5B66\u6821\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u5B66\u6821\u30BF\u30B0\u306E\u5B66\u6821\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u6559\u6750\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u4E00\u89A7\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u30EC\u30B3\u30FC\u30C9\u60C5\u5831"
  | "\u9650\u5B9A\u30BF\u30B0\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u5927\u5B66\u6559\u54E1\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u6388\u696D\u8A08\u753B"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831";
export type TtCsvExportBase = ModelBase & {
  status: TtCsvExportStatus;
  type_ja: TtCsvExportTypeJa;
  created_at: string;
  completed_at: string | null;
  file: {
    name?: string | null;
    url?: string | null;
  };
};
export type TtCsvExportType =
  | "admin_sponsor_info_list"
  | "super_owner_company_list"
  | "super_owner_owner_list"
  | "super_owner_teacher_list"
  | "super_owner_student_list"
  | "super_owner_tag_list"
  | "super_owner_company_tag_list"
  | "super_owner_nationwide_user_tag_and_student_list"
  | "super_owner_limited_user_tag_and_student_list"
  | "super_owner_material_distribution_history_list"
  | "super_owner_learning_package_distribution_history_list"
  | "super_owner_learning_package_distribution_history_detail"
  | "super_owner_homework_template_distribution_history_list"
  | "super_owner_homework_template_distribution_history_detail"
  | "super_owner_custom_list"
  | "super_owner_user_tag_distribution_history_list"
  | "owner_owner_list"
  | "owner_teacher_list"
  | "owner_student_list"
  | "owner_user_tag_list"
  | "owner_nationwide_user_tag_and_student_list"
  | "owner_limited_user_tag_and_student_list"
  | "owner_university_teacher_list"
  | "owner_homework_comment_list"
  | "owner_homework_template_custom_field_list"
  | "teacher_homework_template_custom_field_list"
  | "teacher_student_list"
  | "teacher_pbl_plan_schedule_list"
  | "teacher_homework_comment_list"
  | "reviewer_homework_template_custom_field_list"
  | "reviewer_homework_comment_list";
export type CreateTtCategoryRequestBody = {
  name: string;
  type: TtCategoryBaseType;
};
export type UpdateTtCategoryRequestBody = {
  name: string;
};
export type DistributionStatusStatusJa =
  | "\u5F85\u6A5F\u4E2D"
  | "\u6E96\u5099\u4E2D"
  | "\u5931\u6557"
  | "\u914D\u4FE1\u524D"
  | "\u914D\u4FE1\u4E2D"
  | "\u5931\u6557"
  | "\u914D\u4FE1\u5B8C\u4E86";
export type DistributionStatusCategorizable = {
  categorizable_id: string;
  categorizable_type: string;
  categorizable_title: string;
};
export type AffiliateStudentName = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  nickname?: string | null;
  grades: {
    grade?: string | null;
  }[];
  company: HasIdAndName;
};
export type DistributionStatusList = ModelBase & {
  start_time?: string;
  end_time?: string;
  status: DistributionStatusStatusJa;
  sender_affiliate: AffiliateName;
  categorizable: DistributionStatusCategorizable;
  companies?: HasIdAndName[];
  distributors?: (AffiliateStudentName | GroupAffiliatePackageMinimalBase)[];
};
export type ProfessorAffiliateListBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    position?: string | null;
    department?: string | null;
    description?: string | null;
  };
};
export type ArticleInterviewRequestBase = ModelBase & {
  title: string;
  assign_user_names: string | null;
  company: {
    id: string;
    name: string;
  };
};
export type InterviewRequestListBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  confirm_date: string | null;
  has_preferred_dates: boolean;
  has_feedback_dates: boolean;
  professor_affiliate: ProfessorAffiliateListBase;
  article: ArticleInterviewRequestBase;
};
export type InterviewRequestSearchType =
  | "company_name"
  | "professor_name"
  | "article_title"
  | "student_name";
export type PreferredDateBase = ModelBase & {
  choice_number: number;
  date: string;
};
export type InterviewRequestDetailBase = InterviewRequestListBase & {
  student_message: string | null;
  professor_message: string | null;
  is_online: boolean;
  place: string | null;
  preferred_dates?: PreferredDateBase[];
  unread_message_ids?: string[];
};
export type InterviewMessageKind = "person" | "info";
export type InterviewRequestListBase2 = ModelBase & {
  message: string;
  kind: InterviewMessageKind;
  created_at: string;
  updated_at: string;
  sender_affiliate: AffiliateInfoBase;
  attached_images?: AttachedImageBase[];
};
export type InterviewFeedbackBase = ModelBase & {
  created_at: string;
  updated_at: string;
  student_affiliate: AffiliateInfoBase;
  is_request_send_material?: boolean;
  description?: string;
  degree_of_interest?: number;
  degree_of_learning?: number;
};
export type GroupAffiliatePackageGroupOnlyList = ModelBase & {
  title: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  student_count: number;
  assign_teacher_affiliates?: AffiliateName[];
};
export type PostGroupAffiliatePackage = {
  title: string;
  description?: string;
  code?: string;
  add_assign_teacher_affiliate_ids?: string[];
  add_type?: number;
  assign_ids?: string[];
};
export type PutGroupAffiliatePackage = {
  title: string;
  description?: string;
  code?: string;
  sequence?: number;
  add_assign_teacher_affiliate_ids?: string[];
  remove_assign_teacher_affiliate_ids?: string[];
};
export type PackagingPackageableType =
  | "HomeworkTemplate"
  | "Material"
  | "Package"
  | "Pbl::Steam::Lecture"
  | "Pbl::Sponsor::Lecture"
  | "Homework"
  | "MaterialWork"
  | "Affiliate";
export type CustomValueRequestBodyAsOwnerAndTeacherAndReviewer = {
  value?: string;
  group_affiliate_package_id?: string;
  homework_id: string;
  custom_master_id?: string;
  wysiwyg_body?: string;
};
export type CommonTaggingCategorizableType =
  | "Article"
  | "Pbl::Cards::InformationLiteracy::Bookmark"
  | "Homework"
  | "HomeworkTemplate";
export type PostCommonTagKind = "free" | "management";
export type CommonTaggingRequestBody = {
  tag_name: string;
  kind: PostCommonTagKind;
};
export const {
  useAffiliatesUpdateAsOwnerMutation,
  useUpdateCategoryRenameMapAsOwnerMutation,
  useGetOwnerCategoriesQuery,
  usePostOwnerCategoriesMutation,
  usePutOwnerCategoriesByIdMutation,
  useDeleteOwnerCategoriesByIdMutation,
  usePostOwnerImageContentsMutation,
  useDeleteOwnerImageContentsByIdMutation,
  useGetOwnerImageContentsByIdQuery,
  usePostOwnerVideoContentsMutation,
  useDeleteOwnerVideoContentsByIdMutation,
  useGetOwnerMaterialWorksQuery,
  useBatchCreateMaterialWorksAsOwnerMutation,
  usePatchOwnerMaterialWorksBatchDeleteMutation,
  useGetOwnerMaterialWorksByMaterialWorkIdQuery,
  usePutOwnerMaterialWorksByMaterialWorkIdMutation,
  useGetOwnerUsersTeachersQuery,
  useFetchTeachersForSelectAsOwnerQuery,
  useGetOwnerUsersTeachersByIdQuery,
  usePutTeachersProgressUpdateAsOwnerMutation,
  useGetOwnerUsersStudentsQuery,
  useGetOwnerUsersStudentsListGradeQuery,
  useGetStudentsUserTagsAsOwnerQuery,
  useGetStudentsGroupAffiliatesAsOwnerQuery,
  useGetOwnerUsersOwnersQuery,
  useGetOwnerUsersByUserIdQuery,
  usePostOwnerUsersByUserIdMutation,
  useDeleteOwnerUsersByUserIdMutation,
  usePutOwnerUsersByUserIdMutation,
  useGetOwnerCompaniesByCompanyIdQuery,
  usePutOwnerCompaniesByCompanyIdMutation,
  useGetCompanyLimitOptionAsOwnerQuery,
  useGetOwnerCompaniesByIdMaterialCapacityQuery,
  useGetReviewerCompanyGroupControllersAsOwnerQuery,
  usePostOwnerUsersMutation,
  useCreateAccountUserAsOwnerMutation,
  useCreateAccountAvailableAsOwnerMutation,
  useResetPasswordAsOwnerMutation,
  useAccountUnlockAsOwnerMutation,
  useAccountStopAsOwnerMutation,
  usePatchOwnerUsersBatchCreateMutation,
  useGetOwnerMaterialsQuery,
  usePostOwnerMaterialsMutation,
  useGetOwnerMaterialsByIdQuery,
  usePutOwnerMaterialsByIdMutation,
  useDeleteOwnerMaterialsByIdMutation,
  useGetOwnerMaterialsByMaterialIdTargetUsersQuery,
  usePutOwnerMaterialsSequenceMutation,
  useGetOwnerMaterialsAllContainedCategoryQuery,
  useGetOwnerMaterialsFromSuperOwnersQuery,
  usePostOwnerMaterialsTicketsMutation,
  usePutOwnerMaterialsTicketsAcceptMutation,
  useGetOwnerUsersByIdMaterialWorksQuery,
  useGetOwnerReportsQuery,
  useGetOwnerReportsByReportIdQuery,
  usePutOwnerReportsByReportIdMutation,
  useDeleteOwnerReportsByReportIdMutation,
  useGetOwnerPaymentsQuery,
  useGetOwnerPaymentsByPaymentIdQuery,
  usePutOwnerPaymentsByPaymentIdMutation,
  useGetOwnerHomeworksQuery,
  useGetOwnerHomeworksByHomeworkIdQuery,
  useUpdateHomeworkAsOwnerMutation,
  useUploadHomeworkImagesAsOwnerMutation,
  useSaveEditedHomeworkImageAsOwnerMutation,
  useUpdateHomeworkImageAsOwnerMutation,
  useDeleteHomeworkImageAsOwnerMutation,
  useBatchCreateHomeworksAsOwnerMutation,
  useBatchUpdateHomeworksAsOwnerMutation,
  useBatchDeleteHomeworksAsOwnerMutation,
  useGetHomeworkImageDownloadUrlsAsOwnerQuery,
  usePostHomeworkTemplateCustomFieldFeedbackAsOwnerMutation,
  usePutHomeworkTemplateCustomFieldFeedbacksAsOwnerMutation,
  useDeleteHomeworkTemplateCustomFieldFeedbacksAsOwnerMutation,
  useGetOwnerEmailsQuery,
  usePostOwnerEmailsMutation,
  useGetOwnerEmailsByOwnerEmailIdQuery,
  usePutOwnerCategoriesSequenceMutation,
  useGetOwnerNotificationsQuery,
  useCreateNotificationAsOwnerMutation,
  useGetOwnerNotificationsByIdQuery,
  usePutOwnerNotificationsByIdMutation,
  useDeleteOwnerNotificationsByIdMutation,
  useGetOwnerArticlesByArticleIdQuery,
  useGetOwnerArticlesQuery,
  useFetchArticleWordCloudAsOwnerQuery,
  useGetOwnerArticlesByArticleIdFeedbacksQuery,
  useGetOwnerArticlesByArticleIdCommentsQuery,
  useFetchAffiliateInvitationsAsOwnerQuery,
  useGetOwnerAffiliateInvitationsByIdQuery,
  usePutOwnerAffiliateInvitationsByIdMutation,
  usePutOwnerAffiliateInvitationsActivateMutation,
  usePutOwnerAffiliateInvitationsByIdInactivateMutation,
  usePutOwnerAffiliateInvitationsByIdReactivateMutation,
  usePostOwnerUserTaggingsMutation,
  useDeleteOwnerUserTaggingsMutation,
  useOwnerUserTaggingBatchCreateMutation,
  useGetOwnerUserTagTypesQuery,
  usePostOwnerUserTagTypesMutation,
  usePutOwnerUserTagTypesByIdMutation,
  useDeleteOwnerUserTagTypesByIdMutation,
  useGetOwnerUserTagTypesByUserTagTypeIdUserTagsQuery,
  useGetOwnerUserGroupsQuery,
  usePostOwnerUserGroupsMutation,
  usePostOwnerMaterialWorksBatchCreateUsingTagsMutation,
  useDeleteOwnerReservationsByIdMutation,
  useGetOwnerUserTagsQuery,
  usePostOwnerUserTagsMutation,
  useGetOwnerUserTagsByIdQuery,
  usePutOwnerUserTagsByIdMutation,
  useDeleteOwnerUserTagsByIdMutation,
  usePutOwnerUserTagsSequenceMutation,
  useOwnerUserTagsBatchCreateMutation,
  useGetOwnerUserTagsDistributedTagsQuery,
  useGetOwnerUserTagsByUserTagIdUsersQuery,
  useGetOwnerUserTagsByIdDistributedStudentsQuery,
  useGetOwnerHomeworkTemplatesQuery,
  usePostHomeworkTemplateAsOwnerMutation,
  useGetHomeworkTemplateDetailListAsOwnerQuery,
  useGetOwnerHomeworkTemplatesByHomeworkTemplateIdQuery,
  usePutHomeworkTemplateAsOwnerMutation,
  useDeleteHomeworkTemplateAsOwnerMutation,
  useGetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsQuery,
  usePostOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsMutation,
  useGetOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdQuery,
  usePutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdMutation,
  useDeleteOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsAndIdMutation,
  usePutOwnerHomeworkTemplatesByHomeworkTemplateIdHomeworkTemplateCustomFieldsSequenceMutation,
  usePutOwnerHomeworkTemplateConvertPdfMutation,
  usePostHomeworkTemplateDuplicateAsOwnerMutation,
  useUpdateHomeworkTemplateDistributionAsOwnerMutation,
  useAssignManagerToHomeworkTemplateDistributionAsOwnerMutation,
  useSubmitHomeworkTemplateDistributionToReviewerAsOwnerMutation,
  useRevertHomeworkTemplateDistributionSubmittedToReviewerAsOwnerMutation,
  useReturnHomeworkTemplateDistributionToStudentsAsOwnerMutation,
  usePostHomeworkTemplateImageAsOwnerMutation,
  usePutHomeworkTemplateImageAsOwnerMutation,
  useDeleteHomeworkTemplateImageAsOwnerMutation,
  useGetOwnerMaterialDistributionHistoriesListQuery,
  useGetOwnerMaterialDistributionHistoriesListStudentsQuery,
  useGetOwnerMaterialDistributionHistoriesSearchByUserIdQuery,
  useGetOwnerTemplateFrameworksQuery,
  usePostOwnerTemplateFrameworksMutation,
  useGetOwnerTemplateFrameworksIdQuery,
  usePutOwnerTemplateFrameworksIdMutation,
  useDeleteOwnerTemplateFrameworksIdMutation,
  usePostOwnerTemplateItemFrameworksMutation,
  usePutOwnerTemplateItemFrameworksIdMutation,
  useDeleteOwnerTemplateItemFrameworksIdMutation,
  useGetOwnerInquiriesQuery,
  useGetOwnerInquiriesByIdQuery,
  useGetTheNumberOfNewCommentInquiriesAsOwnerQuery,
  usePostOwnerInquiryCommentsMutation,
  usePutOwnerInquiryCommentsByIdMutation,
  useDeleteOwnerInquiryCommentsByIdMutation,
  useGetLearningTemplatePackageDistributionsAsOwnerQuery,
  useGetLearningTemplatePackageDistributionAsOwnerQuery,
  useGetLearningPackageAsOwnerQuery,
  useGetLearningTemplatePackageDistributionHistoriesAsOwnerQuery,
  useGetLearningTemplatePackageDistributionHistoriesDistributedStudentsAsOwnerQuery,
  useGetLearningTemplatePackageDistributionHistoriesPackagingsAsOwnerQuery,
  useGetPblBoardInfoAsOwnerQuery,
  usePostPblBoardInfoAsOwnerMutation,
  usePutPblBoardInfoAsOwnerMutation,
  usePostPblBoardItemAsOwnerMutation,
  usePutPblBoardItemAsOwnerMutation,
  useDeletePblBoardItemAsOwnerMutation,
  useGetPblBoardCampaignsAsOwnerQuery,
  usePostPblBoardCampaignAsOwnerMutation,
  useGetPblBoardCampaignAsOwnerQuery,
  usePutPblBoardCampaignAsOwnerMutation,
  useDeletePblBoardCampaignAsOwnerMutation,
  useGetPblBoardCampaignRequestAsOwnerQuery,
  usePutPblBoardCampaignRequestAsOwnerMutation,
  usePutPblBoardCampaignRequestSequenceAsOwnerMutation,
  useGetPblBoardArticlesAsOwnerQuery,
  useGetPblBoardArticlesWordCloudAsOwnerQuery,
  useGetPblBoardArticleAsOwnerQuery,
  usePostPblBoardContentAsOwnerMutation,
  useGetPblBoardContentAsOwnerQuery,
  usePutPblBoardContentAsOwnerMutation,
  useDeletePblBoardContentAsOwnerMutation,
  useGetPblBoardFootersAsOwnerQuery,
  usePostPblBoardFooterAsOwnerMutation,
  usePutPblBoardFooterAsOwnerMutation,
  useDeletePblBoardFooterAsOwnerMutation,
  usePutPblBoardFooterSequenceAsOwnerMutation,
  usePostPblBoardFooterItemAsOwnerMutation,
  usePutPblBoardFooterItemAsOwnerMutation,
  useDeletePblBoardFooterItemAsOwnerMutation,
  usePostAttachedImageAsOwnerMutation,
  useGetAttachedImageAsOwnerQuery,
  usePutAttachedImageAsOwnerMutation,
  useDeleteAttachedImageAsOwnerMutation,
  useGetLimitedTimeNotificationsAsOwnerQuery,
  usePostLimitedTimeNotificationAsOwnerMutation,
  useGetLimitedTimeNotificationAsOwnerQuery,
  usePutLimitedTimeNotificationAsOwnerMutation,
  useDeleteLimitedTimeNotificationAsOwnerMutation,
  useGetLimitedTimeNotificationNoticeAsOwnerQuery,
  useGetLimitedTimeNotificationNoticeDetailsAsOwnerQuery,
  usePostLimitedTimeNotificationNoticeCheckAsOwnerMutation,
  useGetTtCsvExportsAsOwnerQuery,
  usePostTtCsvExportAsOwnerMutation,
  usePostTtCategoryBaseAsOwnerMutation,
  usePutTtCategoryBaseAsOwnerMutation,
  useGetDistributionStatusesAsOwnerQuery,
  useDeleteCommentImageAsOwnerMutation,
  useGetInterviewRequestsAsOwnerQuery,
  useGetInterviewRequestAsOwnerQuery,
  useGetInterviewRequestArticleAsOwnerQuery,
  useGetInterviewRequestUnreadCountsAsOwnerQuery,
  useGetInterviewMessagesAsOwnerQuery,
  useGetInterviewMessageReadAsOwnerMutation,
  useGetInterviewFeedbacksAsOwnerQuery,
  useFetchSponsorProjectListAsOwnerQuery,
  useGetGroupAffiliatePackagesAsOwnerQuery,
  usePostGroupAffiliatePackageAsOwnerMutation,
  useGetGroupAffiliatePackageAsOwnerQuery,
  usePutGroupAffiliatePackageAsOwnerMutation,
  useDeleteGroupAffiliatePackageAsOwnerMutation,
  usePostPackagingAsOwnerMutation,
  usePutPackagingAsOwnerMutation,
  useDeletePackagingAsOwnerMutation,
  useBatchCreatePackagingAsOwnerMutation,
  useBatchUpdatePackagingAsOwnerMutation,
  useBatchDeletePackagingAsOwnerMutation,
  usePutHomeworkTemplateCustomValueAsOwnerMutation,
  useGetCommonTagsAsOwnerQuery,
  usePostCommonTaggingAsOwnerMutation,
  useDeleteCommonTaggingAsOwnerMutation,
} = injectedRtkApi;
