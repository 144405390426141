import TimetactIco from "@assets/images/ico/favicon.ico";
import MylsilIco from "@assets/images/ico/MYLSIL.ico";
import MylsilLogoImg from "@assets/images/logo_mylsil.jpg";
import TimeTactLogoImg from "@assets/images/svg/logo.svg";
import { AppName } from "@lib/Api";

type AppInfo = {
  isMylsil: boolean;
  AppLogoImg: string;
  AppFaviconIco: string;
  appName: AppName;
  loginPath: string;
  approvalPath: string;
  isCypressEnv: boolean;
  isInIframe: boolean;
};

const makeAppInfo = (argsAppName?: string | null): AppInfo => {
  const isMylsil = argsAppName?.toLowerCase() === "mylsil";
  const AppLogoImg = isMylsil ? MylsilLogoImg : TimeTactLogoImg;
  const AppFaviconIco = isMylsil ? MylsilIco : TimetactIco;
  const appName = isMylsil ? AppName.MYLSIL : AppName.TimeTact;
  const loginPath = `/login${isMylsil ? "?app_name=mylsil" : ""}`;
  const approvalPath = `/approval${isMylsil ? "?app_name=mylsil" : ""}`;
  const isCypressEnv = !!(globalThis as any).Cypress;
  const isInIframe = globalThis.self !== globalThis.top && !isCypressEnv;
  return {
    isMylsil,
    AppLogoImg,
    AppFaviconIco,
    appName,
    loginPath,
    approvalPath,
    isCypressEnv,
    isInIframe,
  };
};
export default makeAppInfo;
