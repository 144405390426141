import {
  deleteIdealBoardAsTeacher,
  getIdealBoardAsTeacher,
  getIdealBoardForAffiliateAvatarsAsTeacher,
  getIdealBoardsAsTeacher,
  getIdealBoardsMoreAsTeacher,
  postIdealBoardsAsTeacher,
  putIdealBoardAsTeacher,
  putIdealBoardDataAsTeacher,
  resetTeacherAppIdealModalState,
  resetTeacherAppIdealState,
  updateIdealBoardHeaderAsTeacher,
  updateIdealBoardOnlineAffiliateIdsAsTeacher,
} from "@actions/teacherApp/ideal/board";
import { Board, BoardList } from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice, current } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface TeacherAppIdealBoardState {
  fetchIdeals: ReduxStatusType;
  fetchMoreIdeals: ReduxStatusType;
  fetchIdeal: ReduxStatusType;
  fetchIdealForAffiliateAvatars: ReduxStatusType;
  postIdeal: ReduxStatusType;
  putIdeal: ReduxStatusType;
  putIdealData: ReduxStatusType;
  deleteIdeal: ReduxStatusType;
  postIdealError: string | null;
  putIdealError: string | null;
  putIdealDataError: string | null;
  deleteIdealError: string | null;
  boards: BoardList[];
  board: Board | null;
  totalCount: number;
  onlineAffiliateIds: string[];
}

export const initialState: TeacherAppIdealBoardState = {
  fetchIdeals: ReduxStatus.idle,
  fetchMoreIdeals: ReduxStatus.idle,
  fetchIdeal: ReduxStatus.idle,
  fetchIdealForAffiliateAvatars: ReduxStatus.idle,
  postIdeal: ReduxStatus.idle,
  putIdeal: ReduxStatus.idle,
  putIdealData: ReduxStatus.idle,
  deleteIdeal: ReduxStatus.idle,
  postIdealError: null,
  putIdealError: null,
  putIdealDataError: null,
  deleteIdealError: null,
  boards: [],
  board: null,
  totalCount: 0,
  onlineAffiliateIds: [],
};

const TeacherAppIdealBoardSlice = createSlice({
  name: "teacherApp/IdealBoard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIdealBoardsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchIdeals: ReduxStatus.pending,
        };
      })
      .addCase(getIdealBoardsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchIdeals: ReduxStatus.rejected,
          boards: [],
        };
      })
      .addCase(getIdealBoardsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchIdeals: ReduxStatus.fulfilled,
          boards: payload.boards,
          totalCount: payload.total_count,
        };
      })
      .addCase(getIdealBoardsMoreAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchMoreIdeals: ReduxStatus.pending,
        };
      })
      .addCase(getIdealBoardsMoreAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchMoreIdeals: ReduxStatus.rejected,
          boards: [],
        };
      })
      .addCase(getIdealBoardsMoreAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          fetchMoreIdeals: ReduxStatus.fulfilled,
          boards: [...currentState.boards, ...payload.boards],
          totalCount: payload.total_count,
        };
      })
      .addCase(getIdealBoardAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchIdeal: ReduxStatus.pending,
        };
      })
      .addCase(getIdealBoardAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchIdeal: ReduxStatus.rejected,
          board: null,
        };
      })
      .addCase(getIdealBoardAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchIdeal: ReduxStatus.fulfilled,
          board: payload,
        };
      })
      .addCase(
        getIdealBoardForAffiliateAvatarsAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetchIdealForAffiliateAvatars: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getIdealBoardForAffiliateAvatarsAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetchIdealForAffiliateAvatars: ReduxStatus.rejected,
            board: null,
          };
        },
      )
      .addCase(
        getIdealBoardForAffiliateAvatarsAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          if (currentState.board) {
            return {
              ...state,
              fetchIdealForAffiliateAvatars: ReduxStatus.fulfilled,
              board: {
                ...currentState.board,
                invited_affiliates: payload.invited_affiliates,
              },
            };
          }
          return {
            ...state,
            fetchIdealForAffiliateAvatars: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(postIdealBoardsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          postIdeal: ReduxStatus.pending,
          postIdealError: null,
        };
      })
      .addCase(postIdealBoardsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          postIdeal: ReduxStatus.rejected,
          board: null,
          postIdealError: errorWithMessage(action.payload),
        };
      })
      .addCase(postIdealBoardsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          postIdeal: ReduxStatus.fulfilled,
          board: payload,
          postIdealError: null,
        };
      })
      .addCase(putIdealBoardAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          putIdeal: ReduxStatus.pending,
          putIdealError: null,
        };
      })
      .addCase(putIdealBoardAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          putIdeal: ReduxStatus.rejected,
          putIdealError: errorWithMessage(action.payload),
        };
      })
      .addCase(putIdealBoardAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          putIdeal: ReduxStatus.fulfilled,
          putIdealError: null,
        };
      })
      .addCase(putIdealBoardDataAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          putIdealData: ReduxStatus.pending,
          putIdealDataError: null,
        };
      })
      .addCase(putIdealBoardDataAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          putIdealData: ReduxStatus.rejected,
          putIdealDataError: errorWithMessage(action.payload),
        };
      })
      .addCase(putIdealBoardDataAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          putIdealData: ReduxStatus.fulfilled,
          putIdealDataError: null,
        };
      })
      .addCase(deleteIdealBoardAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          deleteIdeal: ReduxStatus.pending,
          deleteIdealError: null,
        };
      })
      .addCase(deleteIdealBoardAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          deleteIdeal: ReduxStatus.rejected,
          deleteIdealError: errorWithMessage(action.payload),
        };
      })
      .addCase(deleteIdealBoardAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          deleteIdeal: ReduxStatus.fulfilled,
          deleteIdealError: null,
        };
      })
      .addCase(resetTeacherAppIdealState, (state, _action) => {
        return {
          ...state,
          fetchIdeals: ReduxStatus.idle,
          fetchMoreIdeals: ReduxStatus.idle,
          boards: [],
          board: null,
        };
      })
      .addCase(resetTeacherAppIdealModalState, (state, _action) => {
        return {
          ...state,
          postIdeal: ReduxStatus.idle,
          postIdealError: null,
          putIdeal: ReduxStatus.idle,
          putIdealError: null,
          deleteIdeal: ReduxStatus.idle,
          deleteIdealError: null,
        };
      })
      .addCase(updateIdealBoardOnlineAffiliateIdsAsTeacher, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          onlineAffiliateIds: payload,
        };
      })
      .addCase(updateIdealBoardHeaderAsTeacher, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        if (currentState.board) {
          return {
            ...state,
            board: {
              ...currentState.board,
              name: payload.name,
            },
          };
        }
        return {
          ...state,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppIdealBoardState = (
  state: RootState,
): TeacherAppIdealBoardState => state.teacherApp.idealBoard;

export default TeacherAppIdealBoardSlice.reducer;
