import V2Tabs, { SelectedButton } from "@components/UIv2/molecules/V2Tabs";
import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

type Props = {
  primary: string;
  secondary: string;
  setButtonState: React.Dispatch<React.SetStateAction<SelectedButton>>;
  buttonState: SelectedButton;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "end",
      },
    },
  }),
);

const PackageTabs: React.FC<Props> = ({
  primary,
  secondary,
  buttonState,
  setButtonState,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.container}>
        <V2Tabs
          primary={primary}
          secondary={secondary}
          setButtonState={setButtonState}
          buttonState={buttonState}
        />
      </Box>
    </>
  );
};

export default PackageTabs;
