import {
  deleteInquiryCommentAsSuperOwner,
  fetchInquiriesAsSuperOwner,
  fetchInquiryDetailsAsSuperOwner,
  getNumberOfCommentInquiriesAsSuperOwner,
  postInquiryCommentAsSuperOwner,
  resetCommentFormState,
  resetUpdateInquiryDetailsState,
  updateInquiryCommentBodyAsSuperOwner,
  updateInquiryDetailsAsSuperOwner,
} from "@actions/superOwnerApp/inquiry";
import { Inquiry } from "@actions/types/inquiry";
import { InquiryDetailForSuperOwner } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

interface InquiryState {
  fetching: boolean;
  fetched: boolean;
  fetchError: string | null;
  inquiries: Inquiry[];
  inquiryDetails: InquiryDetailForSuperOwner | null;
  totalCount: number;
  updating: boolean;
  updated: boolean;
  updateError: any;
  postingComment: boolean;
  postedComment: boolean;
  changingComment: boolean; // To be used update, and deleting comments
  changedComment: boolean;
  commentError: any;
  commentMessage: string;
  newCommentCount: number;
}

const initialState: InquiryState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  inquiries: [],
  inquiryDetails: null,
  totalCount: 0,
  updating: false,
  updated: false,
  updateError: null,
  postingComment: false,
  postedComment: false,
  changingComment: false,
  changedComment: false,
  commentError: null,
  commentMessage: "",
  newCommentCount: 0,
};

const inquirySlice = createSlice({
  name: "SuperOwner/Inquiry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInquiriesAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          inquiries: [],
          fetchError: null,
          inquiryDetails: null,
        };
      })
      .addCase(fetchInquiriesAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          inquiries: action.payload.inquiries,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchInquiriesAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(fetchInquiryDetailsAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          inquiryDetails: null,
          fetchError: null,
        };
      })
      .addCase(fetchInquiryDetailsAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          inquiryDetails: action.payload,
        };
      })
      .addCase(fetchInquiryDetailsAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(updateInquiryDetailsAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          updateError: null,
        };
      })
      .addCase(updateInquiryDetailsAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: true,
          fetching: true, // To keep fetching state after status or person_in_charge is changed
        };
      })
      .addCase(updateInquiryDetailsAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updateError: action.payload?.errors,
        };
      })
      .addCase(postInquiryCommentAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          postingComment: true,
          postedComment: false,
          commentError: null,
        };
      })
      .addCase(postInquiryCommentAsSuperOwner.fulfilled, (state, action) => {
        return {
          ...state,
          postingComment: false,
          postedComment: true,
          inquiryDetails: state.inquiryDetails
            ? {
                ...state.inquiryDetails,
                comments: [...state.inquiryDetails.comments, action.payload],
              }
            : null,
          commentMessage: "コメントを追加しました。",
        };
      })
      .addCase(postInquiryCommentAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          postingComment: false,
          commentError: action.payload?.errors,
        };
      })
      .addCase(
        updateInquiryCommentBodyAsSuperOwner.pending,
        (state, action) => {
          return {
            ...state,
            changingComment: true,
            changedComment: false,
            commentError: null,
          };
        },
      )
      .addCase(
        updateInquiryCommentBodyAsSuperOwner.fulfilled,
        (state, action) => {
          const comments =
            state.inquiryDetails?.comments.map((comment) =>
              comment.id !== action.payload.id ? comment : action.payload,
            ) || [];
          return {
            ...state,
            changingComment: false,
            changedComment: true,
            inquiryDetails: state.inquiryDetails
              ? {
                  ...state.inquiryDetails,
                  comments,
                }
              : null,
            commentMessage: "コメントを編集しました。",
          };
        },
      )
      .addCase(
        updateInquiryCommentBodyAsSuperOwner.rejected,
        (state, action) => {
          return {
            ...state,
            changingComment: false,
            commentError: action.payload?.errors,
          };
        },
      )
      .addCase(deleteInquiryCommentAsSuperOwner.pending, (state, action) => {
        return {
          ...state,
          changingComment: true,
          changedComment: false,
          commentError: null,
        };
      })
      .addCase(deleteInquiryCommentAsSuperOwner.fulfilled, (state, action) => {
        const comments =
          state.inquiryDetails?.comments.filter(
            (comment) => comment.id !== action.meta.arg.id,
          ) || [];
        return {
          ...state,
          changingComment: false,
          changedComment: true,
          inquiryDetails: state.inquiryDetails
            ? {
                ...state.inquiryDetails,
                comments,
              }
            : null,
          commentMessage: "コメントを削除しました。",
        };
      })
      .addCase(deleteInquiryCommentAsSuperOwner.rejected, (state, action) => {
        return {
          ...state,
          changingComment: false,
          commentError: action.payload?.errors,
        };
      })
      .addCase(
        getNumberOfCommentInquiriesAsSuperOwner.fulfilled,
        (state, action) => {
          return {
            ...state,
            newCommentCount: action.payload.count,
          };
        },
      )
      .addCase(resetUpdateInquiryDetailsState, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          updateError: null,
        };
      })
      .addCase(resetCommentFormState, (state, action) => {
        return {
          ...state,
          postingComment: false,
          postedComment: false,
          changingComment: false,
          changedComment: false,
          commentError: null,
        };
      });
  },
});

export const superOwnerAppInquiryState = (state: RootState): InquiryState =>
  state.superOwnerApp.inquiry;

export default inquirySlice.reducer;
