import {
  fetchLecturesByProjectAsContentProvider,
  fetchPblSponsorProjectAsContentProvider,
  resetOne,
} from "@actions/contentProviderApp/pbl/sponsor/project";
import { SponsorProjectDetailsBase } from "@lib/Api";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface ContentProviderAppPblSponsorProjectState {
  fetchingOne: boolean;
  fetchedOne: boolean;
  fetchOneErrors: string | null;
  projectDetails: SponsorProjectDetailsBase | null;
  fetchingLectures: boolean;
}

export const initialState: ContentProviderAppPblSponsorProjectState = {
  fetchingOne: false,
  fetchedOne: false,
  fetchOneErrors: null,
  projectDetails: null,
  fetchingLectures: false,
};

const contentProviderAppPblSponsorProjectSlice = createSlice({
  name: "contentProviderApp/pbl/sponsors/info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPblSponsorProjectAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingOne: true,
            fetchedOne: false,
          };
        },
      )
      .addCase(
        fetchPblSponsorProjectAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: false,
            fetchOneError: action.error.message as string,
          };
        },
      )
      .addCase(
        fetchPblSponsorProjectAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingOne: false,
            fetchedOne: true,
            fetchOneError: null,
            projectDetails: payload,
          };
        },
      )
      .addCase(
        fetchLecturesByProjectAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingLectures: true,
          };
        },
      )
      .addCase(
        fetchLecturesByProjectAsContentProvider.rejected,
        (state, _action) => {
          return {
            ...state,
            fetchingLectures: false,
          };
        },
      )
      .addCase(
        fetchLecturesByProjectAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          const currentState = current(state);
          const currentLectures =
            currentState.projectDetails && currentState.projectDetails.lectures
              ? currentState.projectDetails.lectures
              : [];
          const newProjectDetails = currentState.projectDetails
            ? {
                ...currentState.projectDetails,
                lectures: [...currentLectures, ...payload.project_lectures],
              }
            : null;
          return {
            ...state,
            fetchingLectures: false,
            projectDetails: newProjectDetails,
          };
        },
      )
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          projectDetails: null,
          fetchedOne: false,
          fetchOneError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const contentProviderAppPblSponsorProjectState = (
  state: RootState,
): ContentProviderAppPblSponsorProjectState =>
  state.contentProviderApp.pblSponsorProject;

export default contentProviderAppPblSponsorProjectSlice.reducer;
