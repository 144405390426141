/* eslint-disable jsx-a11y/media-has-caption */
import PlusIcon from "@assets/images/svg/plus_icon.svg?react";
import ExternalLinkInput from "@components/Admin/Sponsors/modals/Forms/ExternalLinkInput";
import LinearProgressBar from "@components/Student/LearningPackages/ListItem/LinearProgress";
import {
  FormAnnotation,
  FormCategoryTitle,
  FormIconAnnotation,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import V2OutlinedButton from "@components/UIv2/atoms/buttons/V2OutlinedButton";
import VideoUnderConstruction from "@components/UIv2/organisms/V2VideoPlayer/VideoUnderConstruction";
import { FILE_TYPE } from "@constants/file";
import { MaterialDetail, VideoInfo } from "@lib/Api";
import useOptionFeature from "@lib/hooks/useOptionFeature";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Chip, Theme, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { fileSize } from "@root/constants/fileSize";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { FormValues } from "../../types";
import { EditMaterialModalMainContext } from "../Main";

interface Props {
  material: MaterialDetail;
  isOverCapacity: boolean;
  videoChanged: boolean;
  setVideoChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setRemovedVideos: React.Dispatch<React.SetStateAction<string[]>>;
  progress: number;
  progressRef: React.MutableRefObject<HTMLDivElement | null>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertWrap: {
      width: "100%",
      margin: "1rem 0 1.5rem",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    videoError: {
      marginTop: theme.spacing(2),
    },
    thumb: {
      height: "135px",
      width: "240px",
    },
  }),
);

const MaterialVideoFields: React.FC<Props> = ({
  material,
  isOverCapacity,
  setVideoChanged,
  setRemovedVideos,
  videoChanged,
  progress,
  progressRef,
}) => {
  const classes = useStyles();
  const editMaterialModalMainContext = useContext(EditMaterialModalMainContext);
  const {
    control,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<FormValues>();
  const { isUseVideo } = useOptionFeature();
  const [materialVideo, setMaterialVideo] = useState<VideoInfo | null>(null);
  const videoInput = useRef<HTMLInputElement>(null);
  const thumbRef = useRef<HTMLImageElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoPreparedDefault = !!(
    material.videos.length && material.videos[0].file.url
  );
  const [videoPrepared, setVideoPrepared] = useState(videoPreparedDefault);
  const [thumbnailError, setThumbnailError] = useState<string>();

  useEffect(() => {
    const videoVal = getValues("video");
    if (
      videoVal &&
      typeof videoVal !== "undefined" &&
      videoVal.file &&
      videoPrepared &&
      videoRef.current
    ) {
      videoRef.current.src = videoVal.file.url
        ? videoVal.file.url
        : URL.createObjectURL(videoVal.file);
      if (thumbRef.current) {
        window.setTimeout(() => {
          try {
            const video = videoRef.current as HTMLVideoElement;
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d") as CanvasRenderingContext2D;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const videoImage = canvas.toDataURL();
            const thumb = thumbRef.current as HTMLImageElement;
            thumb.src = videoImage;
            canvas.toBlob((result) => {
              setValue("videoThumb", result as File | string);
            });
          } catch (error) {
            setThumbnailError(error);
          }
          editMaterialModalMainContext.setIsUploadingVideo(false);
        }, 1000);
      }
    }
  }, [videoPrepared]);

  // 画像を挿入するにはcategorizableIdとcategorizableTypeの2つの値をセットする必要がある
  useEffect(() => {
    if (material?.videos && material?.videos.length > 0) {
      setMaterialVideo(material.videos[0]);
    }
  }, []);

  const processVideoFile = (file: File) => {
    const formVal = {
      file,
      name: file.name,
      type: file.type,
    };
    setValue("video", formVal);
    setVideoPrepared(true);
    setVideoChanged(true);
  };

  const onChangeVideoFileInput = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    editMaterialModalMainContext.setIsUploadingVideo(true);
    const target = e.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;
    target.value = "";
    if (file) {
      if (!FILE_TYPE.VIDEO.MIME_TYPES.includes(file.type)) {
        setError("video", {
          type: "manual",
          message: FILE_TYPE.VIDEO.MESSAGE,
        });
        editMaterialModalMainContext.setIsUploadingVideo(false);
        return;
      }
      if (file.size > fileSize.materialVideo.size) {
        setError("video", {
          type: "manual",
          message: fileSize.materialVideo.message,
        });
        editMaterialModalMainContext.setIsUploadingVideo(false);
        return;
      }
      processVideoFile(file);
      clearErrors("video");
    } else {
      editMaterialModalMainContext.setIsUploadingVideo(false);
    }
  };

  const onRemoveVideoFile = () => {
    setValue("video", undefined);
    setValue("videoThumb", "");
    if (videoRef.current) {
      videoRef.current.src = "";
    }
    setVideoPrepared(false);
    setVideoChanged(true);
    if (
      videoRef.current &&
      material?.videos &&
      material?.videos.length > 0 &&
      setRemovedVideos
    ) {
      setRemovedVideos([videoRef.current.id]);
    }
  };

  const isExternalLink = watch("externalLink") !== "";
  const showThumbnail = videoChanged && !thumbnailError;
  const isVideoUpdating = !videoChanged && material.is_video_updating;

  return (
    <>
      {isUseVideo ? (
        <>
          <FormSectionWrap>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormCategoryTitle ref={progressRef}>
                動画ファイル(mp4)
              </FormCategoryTitle>
              <FormIconAnnotation>
                <InfoIcon fontSize="small" htmlColor="#475149" />
                <div
                  style={{
                    paddingLeft: "8px",
                  }}
                >
                  1ファイル3GB以内
                </div>
              </FormIconAnnotation>
            </Box>
            {isVideoUpdating ? (
              <VideoUnderConstruction />
            ) : (
              <>
                {progress > 0 && (
                  <>
                    <Typography>動画をアップロード中です...</Typography>
                    <LinearProgressBar
                      progressValue={progress}
                      progressMax={100}
                      percentage
                    />
                  </>
                )}
                <FormAnnotation>
                  ※動画ファイルと動画URLはどちらか一つだけ登録できます。容量が大きな動画ファイルを登録する場合、処理に時間がかかることがあります。
                </FormAnnotation>
                {!videoPrepared && (
                  <V2OutlinedButton
                    onClick={() => videoInput.current?.click()}
                    disabled={isExternalLink || isOverCapacity}
                    startIcon={<PlusIcon />}
                  >
                    動画ファイルを追加
                  </V2OutlinedButton>
                )}
                {videoPrepared && typeof getValues("video") !== "undefined" ? (
                  <>
                    <Box m={1}>
                      <Chip
                        variant="outlined"
                        label={`${
                          getValues("video").name
                            ? getValues("video").name.slice(0, 20)
                            : ""
                        }...`}
                        onDelete={onRemoveVideoFile}
                        color="primary"
                      />
                    </Box>
                    {getValues("video").file ? (
                      <>
                        <Box m={1}>
                          <video
                            id={materialVideo ? materialVideo.id : ""}
                            ref={videoRef}
                            controls
                            width={480}
                            height={270}
                          />
                        </Box>
                        <Box m={1} display={showThumbnail ? "block" : "none"}>
                          <h5>サムネイル:</h5>
                          <img
                            className={classes.thumb}
                            ref={thumbRef}
                            alt="サムネイル"
                          />
                        </Box>
                      </>
                    ) : null}
                  </>
                ) : null}
                <input
                  accept={FILE_TYPE.VIDEO.MIME_TYPES.join(",")}
                  onChange={onChangeVideoFileInput}
                  type="file"
                  ref={videoInput}
                  style={{ display: "none" }}
                />
                {errors.video?.message && (
                  <Alert severity="error" className={classes.videoError}>
                    {errors.video.message as string}
                  </Alert>
                )}
              </>
            )}
          </FormSectionWrap>
          {!isVideoUpdating && (
            <ExternalLinkInput control={control} disabled={videoPrepared} />
          )}
        </>
      ) : null}
    </>
  );
};

export default MaterialVideoFields;
