/* eslint-disable camelcase */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Api,
  GetPblBoardArticlesAsOwnerParams,
  GetPblBoardArticlesWordCloudAsOwnerParams,
  GetPblBoardInfoAsOwnerParams,
  PblBoardArticleBase,
  PblBoardContentBase,
  PblBoardInfoBase,
  PblBoardInfoEvent,
  PblBoardItemBaseAsShow,
  PblBoardItemContentType,
  WordCloudBase,
} from "@root/lib/Api";
import { http } from "@root/lib/http";
import { RoleApiFunctions } from "@root/types/pblBoard";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblBoardInfoAsOwner = createAsyncThunk<
  PblBoardInfoBase,
  GetPblBoardInfoAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_infos/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.getPblBoardInfoAsOwner(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetPblBoardArticlesAsOwner = createAsyncThunk<
  { articles: PblBoardArticleBase[]; total_count: number },
  GetPblBoardArticlesAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_articles/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.getPblBoardArticlesAsOwner(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchArticleWordCloudAsOwner = createAsyncThunk<
  WordCloudBase,
  GetPblBoardArticlesWordCloudAsOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_word_cloud/fetch", async (params, { rejectWithValue }) => {
  try {
    const response =
      await api.owner.getPblBoardArticlesWordCloudAsOwner(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createPblBoardInfoAsOwner = createAsyncThunk<
  PblBoardInfoBase,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_infos/post", async (_, { rejectWithValue }) => {
  try {
    const response = await api.owner.postPblBoardInfoAsOwner();
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const putPblBoardInfoAsOwner = createAsyncThunk<
  PblBoardInfoBase,
  { event: PblBoardInfoEvent },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_infos/put", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.putPblBoardInfoAsOwner(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createPblBoardItemAsOwner = createAsyncThunk<
  PblBoardItemBaseAsShow,
  { content_type: PblBoardItemContentType },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_item/post", async (params, { rejectWithValue }) => {
  try {
    const response = await api.owner.postPblBoardItemAsOwner(params);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface CreateBoardContentType {
  itemId: string;
  templateType?: string;
  catchCopy?: string;
  catchCopyOverView?: string;
  catchCopyImage?: File;
  ctaTitle?: string;
  ctaUrl?: string;
  external_link?: string;
}
export const createPblBoardContentsAsOwner = createAsyncThunk<
  PblBoardContentBase,
  CreateBoardContentType,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_content/post", async (params, { rejectWithValue }) => {
  try {
    const {
      itemId,
      templateType,
      catchCopy,
      catchCopyOverView,
      catchCopyImage,
      ctaTitle,
      ctaUrl,
      external_link,
    } = params;
    const fd = new FormData();

    fd.set("design_type", templateType as string);
    fd.set("catchphrase", catchCopy as string);
    fd.set("catchphrase_description", catchCopyOverView as string);
    fd.set("catchphrase_image", catchCopyImage as File);
    fd.set("cta_name", ctaTitle as string);
    fd.set("cta_url", ctaUrl as string);
    fd.set("external_link", external_link as string);

    const response = await http.post(
      `/owner/pbl/board/items/${itemId}/contents`,
      fd,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface EditBoardContentType {
  itemId: string;
  contentId: string;
  templateType?: string;
  catchCopy?: string;
  catchCopyOverView?: string;
  catchCopyImage?: File;
  ctaTitle?: string;
  ctaUrl?: string;
  external_link?: string;
}

export const editPblBoardContentsAsOwner = createAsyncThunk<
  PblBoardContentBase,
  EditBoardContentType,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_content/put", async (params, { rejectWithValue }) => {
  try {
    const {
      itemId,
      contentId,
      templateType,
      catchCopy,
      catchCopyOverView,
      catchCopyImage,
      ctaTitle,
      ctaUrl,
      external_link,
    } = params;
    const fd = new FormData();

    fd.set("design_type", templateType as string);
    fd.set("catchphrase", catchCopy as string);
    fd.set("catchphrase_description", catchCopyOverView as string);
    if (catchCopyImage) {
      fd.set("catchphrase_image", catchCopyImage as File);
    }
    fd.set("cta_name", ctaTitle as string);
    fd.set("cta_url", ctaUrl as string);
    fd.set("external_link", external_link as string);

    const response = await http.put(
      `/owner/pbl/board/items/${itemId}/contents/${contentId}`,
      fd,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface EditBoardItemType {
  id: string;
  data: { content_type: PblBoardItemContentType; sequence?: number };
}

export const putPblBoardItemAsOwner = createAsyncThunk<
  PblBoardItemBaseAsShow,
  EditBoardItemType,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_item/put", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    const response = await api.owner.putPblBoardItemAsOwner(id, data);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deletePblBoardItemAsOwner = createAsyncThunk<
  { message?: string },
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_content/delete", async (id, { rejectWithValue }) => {
  try {
    const response = await api.owner.deletePblBoardItemAsOwner(id);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface CreateFooterTypes {
  item_id: string;
  data: {
    category_name: string;
    footer_items?: { title: string; url: string }[];
  };
}

export const postPblBoardFooterAsOwner = createAsyncThunk<
  { message?: string },
  CreateFooterTypes,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_footer/post", async (params, { rejectWithValue }) => {
  try {
    const { item_id, data } = params;
    const response = await api.owner.postPblBoardFooterAsOwner(item_id, data);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface EditFooterTypes {
  item_id: string;
  id: string;
  data: { category_name: string };
}

export const putPblBoardFooterAsOwner = createAsyncThunk<
  { message?: string },
  EditFooterTypes,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_footer/edit", async (params, { rejectWithValue }) => {
  try {
    const { item_id, id, data } = params;
    const response = await api.owner.putPblBoardFooterAsOwner(
      item_id,
      id,
      data,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deletePblBoardFooterAsOwner = createAsyncThunk<
  { message?: string },
  { item_id: string; id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_footer/edit", async (params, { rejectWithValue }) => {
  try {
    const { item_id, id } = params;
    const response = await api.owner.deletePblBoardFooterAsOwner(item_id, id);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface CreateFooterItemsTypes {
  item_id: string;
  footer_id: string;
  data: { title: string; url: string };
}

export const postPblBoardFooterItemsAsOwner = createAsyncThunk<
  { message?: string },
  CreateFooterItemsTypes,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_footer_items/post", async (params, { rejectWithValue }) => {
  try {
    const { item_id, footer_id, data } = params;
    const response = await api.owner.postPblBoardFooterItemAsOwner(
      item_id,
      footer_id,
      data,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

interface EditFooterItemsTypes {
  item_id: string;
  footer_id: string;
  id: string;
  data: { title: string; url: string };
}

export const editPblBoardFooterItemsAsOwner = createAsyncThunk<
  { message?: string },
  EditFooterItemsTypes,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_footer_items/put", async (params, { rejectWithValue }) => {
  try {
    const { item_id, footer_id, id, data } = params;
    const response = await api.owner.putPblBoardFooterItemAsOwner(
      item_id,
      footer_id,
      id,
      data,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deletePblBoardFooterItemsAsOwner = createAsyncThunk<
  { message?: string },
  { item_id: string; footer_id: string; id: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("owner/board_footer_items/delete", async (params, { rejectWithValue }) => {
  try {
    const { item_id, footer_id, id } = params;
    const response = await api.owner.deletePblBoardFooterItemAsOwner(
      item_id,
      footer_id,
      id,
    );
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

type FooterSequenceParams = {
  item_id: string;
  id: string;
  data: { sequence: number };
};

export const putPblBoardFooterSequenceAsOwner = createAsyncThunk<
  { message?: string },
  FooterSequenceParams
>("owner/board_footer/sequence", async (params, { rejectWithValue }) => {
  try {
    const { item_id, id, data } = params;
    const response = await api.owner.putPblBoardFooterSequenceAsOwner(
      item_id,
      id,
      data,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetBoardState = createAction("owner/board_infos/reset");
export const load = createAction("owner/board/load");
export const success = createAction("owner/board/success");
export const rejected = createAction("owner/board/rejected");

type OwnerActionsType = {
  owner: RoleApiFunctions;
};
export const ownerBoardActions: OwnerActionsType = {
  owner: {
    postBoardInfos: createPblBoardInfoAsOwner,
    putBoardInfos: putPblBoardInfoAsOwner,
    createBoardItem: createPblBoardItemAsOwner,
    editBoardItem: putPblBoardItemAsOwner,
    deletePblBoardItem: deletePblBoardItemAsOwner,
    createBoardContent: createPblBoardContentsAsOwner,
    editBoardContent: editPblBoardContentsAsOwner,
    createBoardFooter: postPblBoardFooterAsOwner,
    editBoardFooter: putPblBoardFooterAsOwner,
    deleteBoardFooter: deletePblBoardFooterAsOwner,
    createBoardFooterItems: postPblBoardFooterItemsAsOwner,
    editBoardFooterItems: editPblBoardFooterItemsAsOwner,
    deleteBoardFooterItems: deletePblBoardFooterItemsAsOwner,
    putPblBoardFooterSequence: putPblBoardFooterSequenceAsOwner,
    load,
    success,
    rejected,
  },
};
