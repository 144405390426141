import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useEnqueueToast from "./hooks/useEnqueueToast";
import { setupInterceptors } from "./setupInterceptors";

export const InjectAxiosInterceptors = (): null => {
  const history = useHistory();
  const { actions } = useEnqueueToast();

  useEffect(() => {
    setupInterceptors(history, actions);
  }, [history]);

  return null;
};
