import { Role } from "@lib/Api";
import { mergeAttributes, Node } from "@tiptap/core";
import { ImageOptions } from "@tiptap/extension-image";
import _ from "lodash";
import { Node as ProseNode } from "prosemirror-model";
import { Plugin } from "prosemirror-state";

import {
  addDeleteImagesOnCanceling,
  addDeleteImagesOnSaving,
} from "./SVImageSlice";
import store from "./SVImageStore";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    SVImage: {
      setSVImage: (
        options: {
          src: string;
          alt?: string;
          title?: string;
          width?: string;
          height?: string;
          imagePosition: "center" | "left" | "right";
          "data-id"?: string;
          "data-categorizable-id"?: string;
          "data-categorizable-type"?: string;
        },
        insertPosition?: number,
      ) => ReturnType;
    };
  }
}

export type SVImageOptions = ImageOptions & {
  apiRole: Role | "public";
};

const compareDocuments = (oldDocument: ProseNode, newDocument: ProseNode) => {
  const diff = new Map();
  const removedMap = new Map();

  newDocument.content.forEach((child, position) => {
    diff.set(child, position);
  });

  oldDocument.content.forEach((child, position) => {
    if (diff.has(child)) {
      diff.delete(child);
    } else {
      removedMap.set(child, position);
    }
  });

  const added = Array.from(diff.entries()).map((value) => {
    return { child: value[0], position: value[1] };
  });
  const removed = Array.from(removedMap.entries()).map((value) => {
    return { child: value[0], position: value[1] };
  });

  return { removed, added };
};

const extractImgNodesAttr = (
  imgNodes: { child: ProseNode; position: number }[],
) => {
  const results = imgNodes
    .filter(({ child }) => child.type.name === "image-wrapper")
    .map(({ child }) => {
      const { attrs } = child.content.child(0);

      return {
        id: attrs["data-id"] as string,
        categorizable_id: (attrs["data-material-id"] ??
          attrs["data-categorizable-id"]) as string,
        categorizable_type: attrs["data-categorizable-type"] as string,
      };
    });

  return results;
};

// SVEditor上で追加や削除された画像をStoreに保存する
const addDeleteImageOnChangeState = () => {
  return new Plugin({
    appendTransaction(transactions, oldState, newState) {
      const isDocChanges = !oldState.doc.eq(newState.doc);
      if (!isDocChanges) {
        return null;
      }
      const { removed, added } = compareDocuments(oldState.doc, newState.doc);

      const removedImgNodes = extractImgNodesAttr(removed);
      const addedImgNodes = extractImgNodesAttr(added);
      if (removedImgNodes.length === 0 && addedImgNodes.length === 0) {
        return null;
      }

      const netRemovedImg = _.differenceWith(
        removedImgNodes,
        addedImgNodes,
        _.isEqual,
      );

      if (netRemovedImg.length !== 0) {
        netRemovedImg.forEach((img) => {
          store.dispatch(addDeleteImagesOnSaving(img));
        });
      }

      const netAddedImg = _.differenceWith(
        addedImgNodes,
        removedImgNodes,
        _.isEqual,
      );

      if (netAddedImg.length !== 0) {
        netAddedImg.forEach((img) => {
          store.dispatch(addDeleteImagesOnCanceling(img));
        });
      }
      return null;
    },
  });
};

export const SVImage = Node.create<SVImageOptions>({
  name: "SVImage",
  atom: true,

  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {
        src: null,
        alt: null,
        title: null,
        width: null,
        height: null,
        imagePosition: "left",
        "data-id": null,
        "data-categorizable-id": null,
        "data-categorizable-type": null,
      },
      apiRole: "public",
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block";
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      imagePosition: {
        default: "center",
      },
      "data-id": {
        default: null,
      },
      "data-categorizable-id": {
        default: null,
      },
      "data-categorizable-type": {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.options.allowBase64
          ? "img[src]"
          : 'img[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { imagePosition } = HTMLAttributes;
    const newHTMLAttributes = HTMLAttributes;
    const mergedAttributes = mergeAttributes(
      this.options.HTMLAttributes,
      newHTMLAttributes,
    );
    return [
      "div",
      { style: `text-align: ${imagePosition}` },
      [mergedAttributes.src ? "img" : "div", mergedAttributes],
    ];
  },

  addCommands() {
    return {
      setSVImage:
        (options, insertPosition) =>
        ({ editor, chain }) => {
          const value = {
            type: this.name,
            attrs: options,
          };

          const content = editor.schema.nodeFromJSON(value);

          chain()
            .focus(insertPosition ?? false)
            .insertContent({
              type: "image-wrapper",
              content: [content.toJSON()],
            })
            .run();

          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [addDeleteImageOnChangeState()];
  },
});
