import { useAuth } from "@contexts/Auth";

/**
 * キャメルケースへ変換 sampleString
 * @param _str string
 * @return string
 */
export const camelCase = (_str: string): string => {
  const str = _str.charAt(0).toLowerCase() + _str.slice(1);
  return str.replace(/[-_](.)/g, (match, group1) => {
    return group1.toUpperCase();
  });
};

/**
 * スネークケースへ変換 sample_string
 * @param _str string
 * @return string
 */
export const snakeCase = (_str: string): string => {
  const camel = camelCase(_str);
  return camel.replace(/[A-Z]/g, (s) => {
    return `_${s.charAt(0).toLowerCase()}`;
  });
};

/**
 * パスカルケースへ変換 SampleString
 * @param _str string
 * @return string
 */
export const pascalCase = (_str: string): string => {
  const camel = camelCase(_str);
  return camel.charAt(0).toUpperCase() + camel.slice(1);
};

/**
 * count half-width characters as 0.5
 * and full-width characters as 1
 * @param str string
 * @return number
 */
export const countStrWidth = (str: string): number => {
  return str
    .split("")
    .reduce((prev, current) => prev + (current.match(/[ -~]/) ? 0.5 : 1), 0);
};

export const getParsedJSON = (jsonString: string): any | false => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return false;
  }
};

export const TextColumnMaxByte = 65535;

/**
 * 文字列のバイト長を得る。
 * @param {string} s 文字列
 * @returns {number} 入力文字列のバイト長
 */
export const byteLengthOf = (s: string | undefined): number => {
  return s ? Buffer.byteLength(s) : 0;
};
/**
 * long strings has custom length and add ... instead of
 */

export const getTitleWithMaxLength = (
  maxLength: number,
  title?: string | null,
): string | undefined | null => {
  return title && title?.length > maxLength
    ? `${title?.slice(0, maxLength)}...`
    : title;
};

export const checkNonEmptyString = (
  value: string | null | undefined,
): string => {
  return typeof value === "string" && value.trim() !== "" ? value : "";
};

export const concatenateStrings = (input1: string, input2: string): string => {
  return `${input1.length}-${input1}${input2}`;
};

export const splitConcatenatedString = (
  concatenated: string,
): [string, string] => {
  const lengthDelimiterIndex = concatenated.indexOf("-");
  const input1Length = parseInt(
    concatenated.slice(0, lengthDelimiterIndex),
    10,
  );
  const input1FirstIndex = lengthDelimiterIndex + 1;
  const input2FirstIndex = input1FirstIndex + input1Length;
  const input1 = concatenated.slice(input1FirstIndex, input2FirstIndex);
  const input2 = concatenated.slice(input2FirstIndex);
  return [input1, input2];
};

/**
 * 空白文字（半角スペース、\t、\n、\r、\f）を除いた文字列を得る
 * @param {string} s 文字列
 * @returns {string} 空白文字を除いた文字列
 */
export const getNonBlankString = (s: string): string => {
  const regExp = /\S/g;
  const nonBlankString = s.match(regExp)?.join("") ?? "";
  return nonBlankString;
};

export type NickNameMethod = {
  nickName: string | undefined | null;
  fullName: string;
};

export const useHandleNickName = () => {
  const {
    isCurrentRoles: { isTeacher, isStudent },
  } = useAuth();

  const handleNickName = ({ nickName, fullName }: NickNameMethod): string => {
    if (isTeacher) return nickName ? `${fullName} (${nickName})` : fullName;
    if (isStudent) return nickName || fullName;
    return fullName;
  };
  return {
    actions: { handleNickName },
  };
};

export const safeString = (str: string | undefined | null): string => str ?? "";
