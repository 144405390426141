/* eslint-disable camelcase */
import { Api, GetStudentReportsParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchReportsAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/reports/fetch",
  async (params: GetStudentReportsParams, { rejectWithValue }) => {
    try {
      const response = await api.student.getStudentReports(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchReportAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("student/reports/fetchOne", async (reportId: string, { rejectWithValue }) => {
  try {
    const response = await api.student.getStudentReportsReportId(reportId);
    return response.data as any;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
