/* eslint-disable camelcase */

export type hasFirstAndLastName = {
  first_name: string;
  last_name: string;
};

export const fullName = <T extends hasFirstAndLastName>(user: T): string => {
  if (!user) return "";
  return `${user.last_name} ${user.first_name}`;
};
