import {
  getSteamContentWorksAsStudent,
  postSteamContentWorksAsStudent,
  resetSearchSteamContentsState,
  resetSteamContentWorksState,
  searchSteamContentsAsStudent,
  updateSteamContentWorksAsStudent,
} from "@actions/studentApp/steam/steamContentWorks";
import { SteamLibraryContentCard } from "@actions/types/steamLibrary";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface StudentAppSteamContentWorksState {
  fetching: boolean;
  fetched: boolean;
  contentWorks: SteamLibraryContentCard[];
  totalCount: number;
  fetchError: string | null;
  search: ReduxStatusType;
  post: ReduxStatusType;
  update: ReduxStatusType;
  searchedContents: SteamLibraryContentCard[];
  searchError: string | null;
  postError: string | null;
  updateError: string | null;
  searchCount: number;
}

const initialState: StudentAppSteamContentWorksState = {
  fetching: false,
  fetched: false,
  contentWorks: [],
  totalCount: 0,
  fetchError: null,
  search: ReduxStatus.idle,
  post: ReduxStatus.idle,
  update: ReduxStatus.idle,
  searchedContents: [],
  searchError: null,
  postError: null,
  updateError: null,
  searchCount: 0,
};

const studentAppSteamContentWorksSlice = createSlice({
  name: "StudentApp/SteamContentWorks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSteamContentWorksAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          fetched: false,
          fetchError: null,
        };
      })
      .addCase(getSteamContentWorksAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          contentWorks: action.payload.steam_contents,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(getSteamContentWorksAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          fetching: false,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(searchSteamContentsAsStudent.pending, (state, _action) => {
        return {
          ...state,
          search: ReduxStatus.pending,
          searchError: null,
        };
      })
      .addCase(searchSteamContentsAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          search: ReduxStatus.fulfilled,
          searchedContents: action.payload.steam_contents,
          searchCount: action.payload.total_count,
        };
      })
      .addCase(searchSteamContentsAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          search: ReduxStatus.rejected,
          searchError: "データが取得できませんでした。",
        };
      })
      .addCase(postSteamContentWorksAsStudent.pending, (state, _action) => {
        return {
          ...state,
          post: ReduxStatus.pending,
          postError: null,
        };
      })
      .addCase(postSteamContentWorksAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          post: ReduxStatus.fulfilled,
        };
      })
      .addCase(postSteamContentWorksAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          post: ReduxStatus.rejected,
          postError: "ステータスを変更できませんでした。",
        };
      })
      .addCase(updateSteamContentWorksAsStudent.pending, (state, _action) => {
        return {
          ...state,
          update: ReduxStatus.pending,
          updateError: null,
        };
      })
      .addCase(updateSteamContentWorksAsStudent.fulfilled, (state, action) => {
        return {
          ...state,
          update: ReduxStatus.fulfilled,
        };
      })
      .addCase(updateSteamContentWorksAsStudent.rejected, (state, _action) => {
        return {
          ...state,
          update: ReduxStatus.rejected,
          updateError: "ステータスを変更できませんでした。",
        };
      })
      .addCase(resetSteamContentWorksState, (state, _action) => {
        return {
          ...state,
          update: ReduxStatus.idle,
          post: ReduxStatus.idle,
          updateError: null,
          postError: null,
        };
      })
      .addCase(resetSearchSteamContentsState, (state, action) => {
        return {
          ...state,
          searchedContents: [],
          searchCount: 0,
          search: ReduxStatus.idle,
          update: ReduxStatus.idle,
          post: ReduxStatus.idle,
        };
      });
  },
});

export const studentAppSteamContentWorksState = (
  state: RootState,
): StudentAppSteamContentWorksState => state.studentApp.steamContentWork;

export default studentAppSteamContentWorksSlice.reducer;
