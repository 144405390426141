/* eslint-disable camelcase */
import Section from "@components/UI/organisms/Layout/SectionWrapper";
import LazyPieGraphStats from "@components/UI/organisms/PieGraphStats/LazyPieGraphStats";
import { Box,Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { teacherAppStudentLogsState } from "@reducers/teacherApp/studentLogs";
import { useAppSelector } from "@root/hooks";
import React from "react";
import { useTranslation } from "react-i18next";

import { StudyLogsModalState, StudyLogsModalTypes } from "..";
import { formatLogsCategoriesToJp } from "../utils";

type Props = {
  setModalState: React.Dispatch<React.SetStateAction<StudyLogsModalTypes>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      justifyContent: "space-around",
      gap: 10,
    },
    itemContainer: {
      borderRadius: theme.spacing(1),
      background: theme.palette.common.white,
      boxShadow:
        "2px 2px 4px 0px rgba(71, 81, 73, 0.05),-2px -2px 4px 0px rgba(71, 81, 73, 0.05)",
      margin: theme.spacing(1, 0),
      position: "relative",
    },
    cardHeaderContainer: {
      padding: theme.spacing(2),
      width: "100%",
      borderBottom: `1px solid ${theme.palette.quaternary.grey}`,
    },
    headerTitle: {
      color: theme.palette.customColor.text,
    },
  }),
);

const LogsActivity: React.FC<Props> = ({ setModalState }) => {
  const {
    activity,
    fetchLogsActivityError,
    fetchingLogsActivity,
    fetchedLogsActivity,
  } = useAppSelector(teacherAppStudentLogsState);
  const { t } = useTranslation(["study_logs", "common"]);
  const { last_week_daily_logs, this_week_daily_logs } = activity;
  const handleOpenMainModal = (lastWeek?: boolean) => {
    setModalState({
      open: true,
      step: 1,
      modalState: StudyLogsModalState.Main,
      lastWeek,
    });
  };
  const valueFormatter: (value: number) => string = (percent) =>
    `${percent.toFixed(0)}%`;
  let lastWeekLogs: React.ReactNode;
  let thisWeekLogs: React.ReactNode;
  if (
    last_week_daily_logs.length &&
    !fetchLogsActivityError &&
    !fetchingLogsActivity &&
    fetchedLogsActivity
  ) {
    const lastWeekLogsJp = formatLogsCategoriesToJp(last_week_daily_logs, t);
    lastWeekLogs = (
      <LazyPieGraphStats
        data={lastWeekLogsJp}
        name="title"
        value="value"
        valueFormatter={valueFormatter}
        onGraphClick={() => handleOpenMainModal(true)}
      />
    );
  } else if (
    last_week_daily_logs.length === 0 &&
    !fetchLogsActivityError &&
    fetchedLogsActivity
  ) {
    lastWeekLogs = (
      <Box m={2}>
        <Typography>{t("common:noData")}</Typography>
      </Box>
    );
  }
  if (
    this_week_daily_logs.length &&
    !fetchLogsActivityError &&
    fetchedLogsActivity
  ) {
    const thisWeekLogsJp = formatLogsCategoriesToJp(this_week_daily_logs, t);
    thisWeekLogs = (
      <LazyPieGraphStats
        data={thisWeekLogsJp}
        name="title"
        value="value"
        valueFormatter={valueFormatter}
        onGraphClick={() => handleOpenMainModal()}
      />
    );
  } else if (
    last_week_daily_logs.length === 0 &&
    !fetchLogsActivityError &&
    fetchedLogsActivity
  ) {
    thisWeekLogs = (
      <Box m={2}>
        <Typography>{t("common:noData")}</Typography>
      </Box>
    );
  }
  const classes = useStyles();
  return (
    <Section title={t("activity")}>
      <Grid container className={classes.mainContainer}>
        <Grid
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          className={classes.itemContainer}
          item
        >
          <Box className={classes.cardHeaderContainer}>
            <Typography className={classes.headerTitle}>
              {t("lastWeek")}
            </Typography>
          </Box>
          {lastWeekLogs}
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          className={classes.itemContainer}
          item
        >
          <Box className={classes.cardHeaderContainer}>
            <Typography className={classes.headerTitle}>
              {t("thisWeek")}
            </Typography>
          </Box>
          {thisWeekLogs}
        </Grid>
      </Grid>
    </Section>
  );
};

export default LogsActivity;
