/* eslint-disable camelcase */
import { FileUploadFiledData } from "@components/UI/molecules/FileUploadFields/types";
import {
  Api,
  FetchPblCardBookmarksAsStudentParams,
  PblCardBookmarkBase,
  PblCardBookmarkSourceType,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const fetchPblCardBookmarksAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/fetch",
  async (params: FetchPblCardBookmarksAsStudentParams, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchPblCardBookmarksAsStudent(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface FetchPblCardBookmarkAsStudentArg {
  id: string;
  inModal: boolean;
}

export const fetchPblCardBookmarkAsStudent = createAsyncThunk<
  any,
  FetchPblCardBookmarkAsStudentArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/fetchOne",
  async (arg: FetchPblCardBookmarkAsStudentArg, { rejectWithValue }) => {
    try {
      const response = await api.student.fetchPblCardBookmarkAsStudent(arg.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreatePblCardBookmarkAsStudentParams {
  source_type: PblCardBookmarkSourceType;
  title: string;
  url?: string | undefined;
  memo: string;
  files: FileUploadFiledData[];
}

export const createPblCardBookmarkAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/create",
  async (params: CreatePblCardBookmarkAsStudentParams, { rejectWithValue }) => {
    try {
      const { source_type, title, url, memo, files } = params;
      const fd = new FormData();
      fd.set("title", title);
      fd.set("url", typeof url === "undefined" ? "" : url);
      fd.set("memo", memo);
      fd.set("source_type", source_type);
      files.forEach((fileData) => {
        fd.append(`files[]`, fileData.file, fileData.file.name);
      });
      const response = await http.post(
        `${"/student/pbl/cards/information_literacy/bookmarks"}`,
        fd,
      );
      const resData = response.data as PblCardBookmarkBase;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdatePblCardBookmarkAsStudentParams {
  source_type: PblCardBookmarkSourceType;
  title: string;
  url?: string | undefined;
  memo: string;
  files: FileUploadFiledData[];
  add_group_affiliate_ids?: string[];
  remove_group_affiliate_ids?: string[];
}

export interface UpdatePblCardBookmarkAsStudentArg {
  id: string;
  params: UpdatePblCardBookmarkAsStudentParams;
}

export const updatePblCardBookmarkAsStudent = createAsyncThunk<
  any,
  UpdatePblCardBookmarkAsStudentArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/update",
  async (arg: UpdatePblCardBookmarkAsStudentArg, { rejectWithValue }) => {
    try {
      const { id, params } = arg;
      const {
        source_type,
        title,
        url,
        memo,
        files,
        add_group_affiliate_ids,
        remove_group_affiliate_ids,
      } = params;
      const fd = new FormData();
      fd.set("title", title);
      fd.set("url", typeof url === "undefined" ? "" : url);
      fd.set("memo", memo);
      fd.set("source_type", source_type);
      add_group_affiliate_ids?.forEach((group) => {
        fd.append("add_group_affiliate_ids[]", group);
      });
      remove_group_affiliate_ids?.forEach((group) => {
        fd.append("remove_group_affiliate_ids[]", group);
      });
      files.forEach((fileData) => {
        fd.append(`files[]`, fileData.file, fileData.file.name);
      });
      const response = await http.put(
        `/student/pbl/cards/information_literacy/bookmarks/${id}`,
        fd,
      );
      const resData = response.data as PblCardBookmarkBase;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblCardBookmarkAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.student.deletePblCardBookmarkAsStudent(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePblCardImageAsStudent = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/pbl/cards/bookmarks_image/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response =
        await api.student.deletePblCardBookmarkImageAsStudent(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetModalState = createAction(
  "student/pbl/cards/bookmarks/resetModalState",
);

export const resetOne = createAction("student/pbl/cards/bookmarks/resetOne");
