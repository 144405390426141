import { mergeAttributes } from "@tiptap/core";
import Table, { createTable } from "@tiptap/extension-table";
import { EditorState, Plugin } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

export const initialRowSize = 3;
export const initialColSize = 2;
export const initialCellHeight = 50;
export const initialCellWidth = 400;
export const initialBorderWidth = 2;

export const SVTable = Table.extend({
  name: "SVTable",

  renderHTML({ HTMLAttributes }) {
    return [
      "table",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      ["tbody", 0],
    ];
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-table-width"),
        renderHTML: (attributes) => ({
          "data-table-width": attributes.width,
          style: attributes.width
            ? `width:${attributes.width}px; margin:24px`
            : "margin:24px",
        }),
      },
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      insertTable:
        (options) =>
        ({ editor, commands }) => {
          const node = createTable(
            editor.schema,
            options?.rows ?? initialRowSize,
            options?.cols ?? initialColSize,
            options?.withHeaderRow ?? false,
          );
          commands.insertContent({
            type: "table-wrapper",
            content: [node.toJSON()],
          });
          return true;
        },
    };
  },
  addProseMirrorPlugins() {
    const update = (view: EditorView, prevState: EditorState) => {
      if (prevState.doc.eq(view.state.doc)) {
        return;
      }
      // Create a new transaction first
      const { tr } = view.state;
      view.state.doc.descendants((node, pos) => {
        // Get the table DOM
        if (node.type.name !== "SVTable") return;
        // The table is wrapped by "table-wrapper"
        const tableDOM = view.nodeDOM(pos)?.childNodes[0] as any;
        // Update the width attribute
        if (tableDOM?.offsetWidth) {
          view.dispatch(
            tr.setNodeMarkup(pos, undefined, {
              width: tableDOM.offsetWidth,
              "data-table-width": tableDOM.offsetWidth,
            }),
          );
        }
      });
    };

    return [
      ...(this.parent?.() ?? []),
      new Plugin({
        view() {
          return { update };
        },
      }),
    ];
  },
});

export default SVTable;
