import { deleteCategoryAsOwner } from "@actions/ownerApp/category";
import {
  createMaterialAsOwner,
  deleteMaterialAsOwner,
  fetchMaterialAsOwner,
  fetchMaterialsAllCategoriesAsOwner,
  fetchMaterialsAsOwner,
  fetchMaterialsFromSuperOwnerAsOwner,
  resetFormRelatedData,
  resetOwnerMaterialSequenceState,
  saveMaterialSequenceAsOwner,
  updateMaterialAsOwner,
  updateVideoInMaterialAsOwner,
} from "@actions/ownerApp/material";
import {
  createMaterialTicketAsOwner,
  putMaterialTicketAsOwner,
} from "@actions/ownerApp/materialTicket";
import {
  createMaterialWorksAsOwner,
  deleteMaterialWorksAsOwner,
} from "@actions/ownerApp/materialWork";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import {
  HasIdAndTitle,
  MaterialDetail,
  MaterialList,
  OwnerMaterialList,
} from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface OwnerAppMaterialState {
  fetchStatus: ReduxStatusType;
  fetchingOne: boolean;
  fetchedOne: boolean;
  processing: boolean;
  processed: boolean; // whole process
  savingOwnerMaterialSequence: boolean;
  savedOwnerMaterialSequence: boolean;
  saveOwnerMaterialSequenceError: any;
  error: any;
  fetchOneError: any;
  createMaterialTicketError: string | null;
  putMaterialTicketError: string | null;
  materials: OwnerMaterialList[];
  totalCount: number;
  publicMaterials: MaterialList[];
  publicMaterialsTotalCount: number;
  material: MaterialDetail | null;
  materialTicket: string | null;
  fetchingAllCategories: ReduxStatusType;
  fetchPublicMaterials: ReduxStatusType;
  materialAllCategories: HasIdAndTitle[];
  materialAllCategoriesCount: number;
  createMaterialStatus: ReduxStatusType;
  updateMaterialStatus: ReduxStatusType;
  deleteMaterialStatus: ReduxStatusType;
  updateVideoStatus: ReduxStatusType;
  updateVideoError: string | null;
}

export const initialState: OwnerAppMaterialState = {
  fetchStatus: ReduxStatus.idle,
  fetchingOne: false,
  fetchedOne: false,
  savingOwnerMaterialSequence: false,
  savedOwnerMaterialSequence: false,
  processing: false,
  processed: false,
  saveOwnerMaterialSequenceError: null,
  error: null,
  fetchOneError: null,
  createMaterialTicketError: null,
  putMaterialTicketError: null,
  materials: [],
  totalCount: 0,
  publicMaterials: [],
  publicMaterialsTotalCount: 0,
  material: null,
  materialTicket: null,
  fetchingAllCategories: ReduxStatus.idle,
  fetchPublicMaterials: ReduxStatus.idle,
  materialAllCategories: [],
  materialAllCategoriesCount: 0,
  createMaterialStatus: ReduxStatus.idle,
  updateMaterialStatus: ReduxStatus.idle,
  deleteMaterialStatus: ReduxStatus.idle,
  updateVideoStatus: ReduxStatus.idle,
  updateVideoError: null,
};

const ownerAppMaterialSlice = createSlice({
  name: "OwnerApp/Material",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterialsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchStatus: ReduxStatus.pending,
        };
      })
      .addCase(fetchMaterialsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchStatus: ReduxStatus.rejected,
          error: action.payload,
        };
      })
      .addCase(fetchMaterialsAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchStatus: ReduxStatus.fulfilled,
          materials: payload.materials,
          totalCount: payload.total_count,
        };
      })
      .addCase(
        fetchMaterialsFromSuperOwnerAsOwner.pending,
        (state, _action) => {
          return {
            ...state,
            fetchPublicMaterials: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetchMaterialsFromSuperOwnerAsOwner.rejected,
        (state, action) => {
          return {
            ...state,
            fetchPublicMaterials: ReduxStatus.rejected,
            error: action.payload,
          };
        },
      )
      .addCase(
        fetchMaterialsFromSuperOwnerAsOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchPublicMaterials: ReduxStatus.fulfilled,
            publicMaterials: payload.materials,
            publicMaterialsTotalCount: payload.total_count,
          };
        },
      )
      .addCase(fetchMaterialAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchedOne: false,
          fetchOneError: null,
          material: null,
        };
      })
      .addCase(fetchMaterialAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: action.payload,
        };
      })
      .addCase(fetchMaterialAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchedOne: true,
          material: payload,
        };
      })
      .addCase(fetchMaterialsAllCategoriesAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetchingAllCategories: ReduxStatus.pending,
        };
      })
      .addCase(fetchMaterialsAllCategoriesAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetchingAllCategories: ReduxStatus.rejected,
          errorAllCategories: action.error.message,
        };
      })
      .addCase(
        fetchMaterialsAllCategoriesAsOwner.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            materialAllCategories: payload.materials,
            fetchingAllCategories: ReduxStatus.fulfilled,
            materialAllCategoriesCount: payload.total_count,
          };
        },
      )

      .addCase(createMaterialAsOwner.pending, (state, _action) => {
        return {
          ...state,
          createMaterialStatus: ReduxStatus.pending,
          error: null,
        };
      })
      .addCase(createMaterialAsOwner.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          createMaterialStatus: ReduxStatus.rejected,
        };
      })
      .addCase(createMaterialAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          error: null,
          material: payload,
          materials: [
            ...currentState.materials,
            payload as unknown as OwnerMaterialList,
          ],
          createMaterialStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(updateMaterialAsOwner.pending, (state, _action) => {
        return {
          ...state,
          updateMaterialStatus: ReduxStatus.pending,
          error: null,
        };
      })
      .addCase(updateMaterialAsOwner.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          updateMaterialStatus: ReduxStatus.rejected,
        };
      })
      .addCase(updateMaterialAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          error: null,
          material: payload.material,
          materials: update(currentState.materials, payload.material),
          updateMaterialStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(updateVideoInMaterialAsOwner.pending, (state, _action) => {
        return {
          ...state,
          updateVideoStatus: ReduxStatus.pending,
          updateVideoError: null,
        };
      })
      .addCase(updateVideoInMaterialAsOwner.rejected, (state, action) => {
        return {
          ...state,
          updateVideoError: action.payload,
          updateVideoStatus: ReduxStatus.rejected,
        };
      })
      .addCase(updateVideoInMaterialAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          updateVideoError: null,
          updateVideoStatus: ReduxStatus.fulfilled,
        };
      })
      .addCase(saveMaterialSequenceAsOwner.pending, (state, action) => {
        return {
          ...state,
          savingOwnerMaterialSequence: true,
          savedOwnerMaterialSequence: false,
          saveOwnerMaterialSequenceError: null,
        };
      })
      .addCase(saveMaterialSequenceAsOwner.fulfilled, (state, action) => {
        return {
          ...state,
          savingOwnerMaterialSequence: false,
          savedOwnerMaterialSequence: true,
          saveOwnerMaterialSequenceError: null,
        };
      })
      .addCase(saveMaterialSequenceAsOwner.rejected, (state, action) => {
        return {
          ...state,
          savingOwnerMaterialSequence: false,
          savedOwnerMaterialSequence: false,
          saveOwnerMaterialSequenceError: action.error.message,
        };
      })
      .addCase(deleteMaterialAsOwner.pending, (state, _action) => {
        return {
          ...state,
          deleteMaterialStatus: ReduxStatus.pending,
        };
      })
      .addCase(deleteMaterialAsOwner.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          deleteMaterialStatus: ReduxStatus.rejected,
        };
      })
      .addCase(deleteMaterialAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          deleteMaterialStatus: ReduxStatus.fulfilled,
          materials: remove(currentState.materials, payload.materialId),
        };
      })
      .addCase(createMaterialTicketAsOwner.pending, (state, _action) => {
        return {
          ...state,
          createMaterialTicketError: null,
          processing: true,
          processed: false,
        };
      })
      .addCase(createMaterialTicketAsOwner.rejected, (state, action) => {
        return {
          ...state,
          createMaterialTicketError: action.payload.error,
          processing: false,
          processed: false,
        };
      })
      .addCase(createMaterialTicketAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          createMaterialTicketError: null,
          materialTicket: payload.materialTicket,
          processing: !payload.completed,
          processed: payload.completed,
        };
      })
      .addCase(putMaterialTicketAsOwner.pending, (state, _action) => {
        return {
          ...state,
          putMaterialTicketError: null,
          processing: true,
          processed: false,
        };
      })
      .addCase(putMaterialTicketAsOwner.rejected, (state, action) => {
        return {
          ...state,
          putMaterialTicketError:
            "無効なキーまたは、既に追加処理が実行されています。",
          processing: false,
          processed: false,
        };
      })
      .addCase(putMaterialTicketAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          putMaterialTicketError: null,
          materialTicket: payload.materialTicket,
          processing: !payload.completed,
          processed: payload.completed,
        };
      })
      .addCase(deleteCategoryAsOwner.fulfilled, (state, _action) => {
        return {
          ...state,
          material: null,
          materials: [],
          totalCount: 0,
        };
      })
      .addCase(createMaterialWorksAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          materials:
            typeof payload.materials !== "undefined"
              ? payload.materials
              : currentState.materials,
        };
      })
      .addCase(deleteMaterialWorksAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          materials:
            typeof payload.materials !== "undefined"
              ? payload.materials
              : currentState.materials,
        };
      })
      .addCase(resetFormRelatedData, (state, _action) => {
        return {
          ...state,
          fetchStatus: ReduxStatus.idle,
          processing: false,
          processed: false,
          error: null,
          createMaterialTicketError: null,
          putMaterialTicketError: null,
          materialTicket: null,
          createMaterialStatus: ReduxStatus.idle,
          updateMaterialStatus: ReduxStatus.idle,
          deleteMaterialStatus: ReduxStatus.idle,
        };
      })
      .addCase(resetOwnerMaterialSequenceState, (state, _action) => {
        return {
          ...state,
          savedOwnerMaterialSequence: false,
          savingOwnerMaterialSequence: false,
          saveOwnerMaterialSequenceError: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppMaterialState = (
  state: RootState,
): OwnerAppMaterialState => state.ownerApp.material;

export default ownerAppMaterialSlice.reducer;
