/* eslint-disable camelcase */
import {
  Api,
  BatchUpdateHomeworkRequest,
  FetchHomeworksAsTeacherParams,
  Homework,
  HomeworkBase,
  HomeworkDetails,
} from "@lib/Api";
import { http } from "@lib/http";
import errorWithMessage from "@lib/rtk/error-utils";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

import {
  AnswerFileParams,
  CreateHomeworkAsStudentParams,
  SubmitAnswerFilesAsTeacherParams,
  SubmitAnswerFilesResponse,
  UpdateHomeworkAsTeacherArg,
} from "./types/homework";

const api = new Api();

export const fetchHomeworkAsTeacher = createAsyncThunk<
  { homeworks: HomeworkBase[]; total_count: number },
  FetchHomeworksAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homework/fetch",
  async (params: FetchHomeworksAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchHomeworksAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAllHomeworkAsTeacher = createAsyncThunk<
  { homeworks: HomeworkBase[]; total_count: number },
  FetchHomeworksAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homework/fetchAll",
  async (params: FetchHomeworksAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchHomeworksAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const fetchOneHomeworkAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homework/fetchOne",
  async (homeworkId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.getTeacherHomeworksHomeworkId(homeworkId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const updateOneHomeworkAsTeacher = createAsyncThunk<
  HomeworkDetails,
  UpdateHomeworkAsTeacherArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homework/update",
  async ({ homeworkId, params }, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.set("status", params.status);
      fd.set(
        "teacher_comment",
        params.teacher_comment ? params.teacher_comment : "",
      );
      fd.set(
        "teacher_comment_for_reviewer",
        params.teacher_comment_for_reviewer
          ? params.teacher_comment_for_reviewer
          : "",
      );
      fd.set("score", String(params.score));

      if (params.comment_images?.length) {
        params.comment_images.forEach((image: File) => {
          fd.append(`comment_images[]`, image, image.name);
        });
      }

      const response = await http.put(`/teacher/homeworks/${homeworkId}`, fd);
      const resData = response.data;
      return resData;
    } catch (err) {
      return rejectWithValue(errorWithMessage(err.response));
    }
  },
);

export const createOneHomeworkAsTeacher = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homework/create",
  async (data: CreateHomeworkAsStudentParams, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.set("student_id", data.student_id);
      fd.set(
        "teacher_comment",
        typeof data.teacher_comment !== "undefined" ? data.teacher_comment : "",
      );
      fd.set("title", data.title);
      fd.set("content", data.content);
      if (typeof data.teacher_homework_images !== "undefined") {
        data.teacher_homework_images.forEach((fileData: any, index: number) => {
          fd.append(`teacher_homework_images[]`, fileData.file, fileData.title);
        });
      }
      const response = await http.post(`${"/teacher/homeworks"}`, fd);
      const resData = response.data as Homework;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export interface BatchCreateHomeworksAsTeacherParams {
  homework_template_id?: string;
  start_at?: string;
  end_at?: string;
  student_ids: string[];
}

export const batchUpdate = createAsyncThunk<
  { homeworks: HomeworkBase[] },
  BatchUpdateHomeworkRequest,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/homework/batchUpdate", async (data, { rejectWithValue }) => {
  try {
    const response = await api.teacher.batchUpdateHomeworksAsTeacher(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const submitAnswerFiles = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homework/submitAnswerFiles",
  async (data: SubmitAnswerFilesAsTeacherParams, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      data.file_params_list.forEach(
        (file_params_list: AnswerFileParams, index: number) => {
          const { file_data, homework_id } = file_params_list;
          fd.append(`homework_ids[]`, homework_id);
          fd.append(`answer_files[]`, file_data, file_data.name);
        },
      );
      const response = await http.patch(
        `${"/teacher/homeworks/submit_answer_files"}`,
        fd,
      );
      const resData = response.data as SubmitAnswerFilesResponse;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const batchDelete = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/homework/batch_delete",
  async (homework_ids: string[], { rejectWithValue }) => {
    try {
      const response = await api.teacher.batchDeleteHomeworksAsTeacher({
        homework_ids,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.error);
    }
  },
);

export const updatedHomework = createAction(
  "teacherApp/homework/updatedHomework",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const fileUploaded = createAction(
  "teacherApp/homework/fileUploaded",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const fileDeleted = createAction(
  "teacherApp/homework/fileDeleted",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const resetModalState = createAction("teacher/homework/resetModalState");

export const resetDeleteActionState = createAction(
  "teacher/homework/resetDeleteActionState",
);

export const resetState = createAction("teacher/homework/resetState");

export const resetTeacherAppHomeworkFormData = createAction(
  "teacherApp/homework/resetFormRelatedData",
);
