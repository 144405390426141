import { handleStudentQuestionary } from "@components/Common/UniversityInterviewRequestList/util/interviewRequestUtil";
import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import { STUDENT_ROUTES } from "@constants/routes";
import useResponsive from "@lib/hooks/useResponsive";
import { fullName } from "@lib/user/name-utils";
import { Box, Divider,Theme, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import DefaultPic from "@root/assets/images/png/defaultThumbnail_without_dash.png";
import { InterviewRequestDetailBase } from "@root/endpoints/TimeTactApi/studentApi";
import { InterviewRequestStatusEnum } from "@root/types/interviewRequest";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Link as RouterLink } from "react-router-dom";

import { ModalToOpen } from "../../hook/useInterviewRequests";

type Props = {
  currentInterviewRequest: InterviewRequestDetailBase;
  isFeeBackDisplay: boolean;
  handleModalState: (modalName: ModalToOpen) => void;
  handleNotificationModalClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      marginBottom: "auto",
    },
    wrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
      },
    },
    avatarContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexShring: 0,
      width: "60px",
      height: "60px",
      borderRadius: "50%",
    },
    avatar: {
      borderRadius: "50%",
      width: "auto",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "cover",
    },
    titlesWrapper: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      wordBreak: "break-word",
      flexDirection: "column",
      flexShrink: 0,
      gap: theme.spacing(1),
    },
    mainTitle: {
      fontWeight: 700,
      wordBreak: "break-word",
      color: theme.palette.customColor.text,
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(2),
      },
    },
    articleTitle: {
      fontWeight: 400,
      color: theme.palette.customColor.cyan,
      wordBreak: "break-word",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(2),
      },
    },
    divider: {
      margin: theme.spacing(2, 0),
      background: theme.palette.divider,
    },
    questionaryWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
  }),
);

const ChatHeader: React.FC<Props> = ({
  currentInterviewRequest,
  isFeeBackDisplay,
  handleModalState,
  handleNotificationModalClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("university");
  const { isMobileView } = useResponsive();
  const isCurrentInterviewSelected =
    currentInterviewRequest && Object.keys(currentInterviewRequest).length > 0;
  const isFeedBackButtonAvailable =
    isCurrentInterviewSelected &&
    currentInterviewRequest.status_code !==
      InterviewRequestStatusEnum.COMPLETED &&
    isFeeBackDisplay &&
    currentInterviewRequest?.confirm_date &&
    handleStudentQuestionary(currentInterviewRequest?.confirm_date);

  const teacherInfos = currentInterviewRequest?.professor_affiliate;
  const mainTitle = teacherInfos
    ? `${fullName(teacherInfos) || ""} / ${
        teacherInfos?.professor_info.department || ""
      } / ${teacherInfos?.professor_info.position}`
    : "";

  const handleArticleRedirection = () => {
    return `${STUDENT_ROUTES.ARTICLES}/${
      currentInterviewRequest?.article?.id || ""
    }`;
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.wrapper}>
        {!isMobileView && isCurrentInterviewSelected && (
          <Box className={classes.avatarContainer}>
            <img className={classes.avatar} src={DefaultPic} alt="Avatar" />
          </Box>
        )}
        <Box className={classes.titlesWrapper}>
          <Typography className={classes.mainTitle} component="h2" variant="h6">
            {mainTitle}
          </Typography>
          <Box onClick={handleNotificationModalClose}>
            <Link
              component={RouterLink}
              className={classes.articleTitle}
              to={handleArticleRedirection()}
            >
              <Typography>
                {currentInterviewRequest?.article?.title || ""}
              </Typography>
            </Link>
          </Box>
        </Box>
        {isFeedBackButtonAvailable && (
          <Box className={classes.questionaryWrapper}>
            <SingleButton
              onClick={() => handleModalState(ModalToOpen.QUESTIONARY)}
              startIcon={<AiOutlineCheckCircle />}
            >
              {t("feedBackButton")}
            </SingleButton>
          </Box>
        )}
      </Box>
      {isCurrentInterviewSelected && <Divider className={classes.divider} />}
    </Box>
  );
};

export default ChatHeader;
