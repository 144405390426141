/* eslint-disable camelcase */
import { ImageData } from "@components/Admin/Sponsors/modals/types";
import { Api } from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

interface CreateSponsorLectureFileAsAdminParams {
  lecture_id: string;
  file: ImageData | null;
}

export const createSponsorLectureFileAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/lectures/files/create",
  async (
    params: CreateSponsorLectureFileAsAdminParams,
    { rejectWithValue },
  ) => {
    try {
      const { lecture_id, file } = params;
      const fd = new FormData();
      fd.set("lecture_id", lecture_id);
      if (file && file.file) {
        fd.set("file", file.file);
      }
      const response = await http.post(
        `/admin/sponsor/projects/lecture_files`,
        fd,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSponsorLectureFileAsAdmin = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "admin/pbl/sponsor/projects/lectures/files/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.admin.deleteSponsorLectureFileAsAdmin(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetAction = createAction(
  "admin/pbl/sponsor/projects/lectures/files/resetAction",
);
