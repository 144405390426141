import {
  getAllSteamLibraryLecturesAsTeacher,
  getSteamLectureDetailsAsTeacher,
  getSteamLibraryLecturesAsTeacher,
  postSteamLectureAsTeacher,
  resetSteamLectureDetails,
} from "@actions/teacherApp/steam/steamLecture";
import { PblSteamLecture } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";
import _ from "lodash";

export type SteamLibraryLectures = {
  contentId: number;
  lecturesTotalCount: number;
  steamLibraryAllLectures: PblSteamLecture[];
};
interface TeacherAppSteamLectureState {
  fetch: ReduxStatusType;
  lecture: any;
  fetchError: string | null;
  posting: boolean;
  posted: boolean;
  postError: string | null;
  fetchSteamLibraryLecture: ReduxStatusType;
  steamLibraryLectures: PblSteamLecture[];
  steamLibraryAllLectures: SteamLibraryLectures[];
  fetchSteamLibraryLectureError: null;
  fetchAllSteamLibraryLecture: ReduxStatusType;
  fetchAllSteamLibraryLectureError: null;
  lecturesTotalCount: number;
}

const initialState: TeacherAppSteamLectureState = {
  fetch: ReduxStatus.idle,
  lecture: null,
  fetchError: null,
  posting: false,
  posted: false,
  postError: null,
  fetchSteamLibraryLecture: ReduxStatus.idle,
  fetchSteamLibraryLectureError: null,
  steamLibraryLectures: [],
  fetchAllSteamLibraryLecture: ReduxStatus.idle,
  fetchAllSteamLibraryLectureError: null,
  steamLibraryAllLectures: [],
  lecturesTotalCount: 0,
};

const teacherAppSteamLectureSlice = createSlice({
  name: "TeacherApp/SteamLecture",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSteamLectureDetailsAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetch: ReduxStatus.pending,
          fetchError: null,
        };
      })
      .addCase(getSteamLectureDetailsAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetch: ReduxStatus.fulfilled,
          lecture: action.payload,
        };
      })
      .addCase(getSteamLectureDetailsAsTeacher.rejected, (state, _action) => {
        return {
          ...state,
          fetch: ReduxStatus.rejected,
          fetchError: "データが取得できませんでした。",
        };
      })
      .addCase(postSteamLectureAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          posting: true,
          posted: false,
          postError: null,
        };
      })
      .addCase(postSteamLectureAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          posting: false,
          posted: true,
          lecture: action.payload,
        };
      })
      .addCase(postSteamLectureAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          posting: false,
          postError: action.payload.errors,
        };
      })
      .addCase(getSteamLibraryLecturesAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchSteamLibraryLecture: ReduxStatus.pending,
          fetchSteamLibraryLectureError: null,
        };
      })
      .addCase(getSteamLibraryLecturesAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetchSteamLibraryLecture: ReduxStatus.fulfilled,
          steamLibraryLectures: action.payload.steam_lectures,
          fetchSteamLibraryLectureError: null,
        };
      })
      .addCase(
        getAllSteamLibraryLecturesAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetchSteamLibraryLecture: ReduxStatus.rejected,
            fetchSteamLibraryLectureError: action.payload.errors,
          };
        },
      )
      .addCase(
        getAllSteamLibraryLecturesAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetchAllSteamLibraryLecture: ReduxStatus.pending,
            fetchAllSteamLibraryLectureError: null,
          };
        },
      )
      .addCase(
        getAllSteamLibraryLecturesAsTeacher.fulfilled,
        (state, action) => {
          const { payload, meta } = action;
          const contentId = meta.arg.sl_content_id;
          const currentInfoIdx = state.steamLibraryAllLectures.findIndex(
            (l: SteamLibraryLectures) => l.contentId === contentId,
          );
          let newInfo: SteamLibraryLectures;
          let newLectures: SteamLibraryLectures[];
          if (currentInfoIdx === -1) {
            newInfo = {
              contentId,
              steamLibraryAllLectures: payload.lectures,
              lecturesTotalCount: payload.totalCount,
            };
            newLectures = [...state.steamLibraryAllLectures, newInfo];
          } else {
            newLectures = _.uniq([...state.steamLibraryAllLectures]);
          }

          return {
            ...state,
            fetchAllSteamLibraryLecture: ReduxStatus.fulfilled,
            fetchAllSteamLibraryLectureError: null,
            steamLibraryAllLectures: newLectures,
          };
        },
      )
      .addCase(resetSteamLectureDetails, (state, _action) => {
        return {
          ...state,
          fetch: ReduxStatus.idle,
          lecture: null,
        };
      });
  },
});

export const teacherAppSteamLectureState = (
  state: RootState,
): TeacherAppSteamLectureState => state.teacherApp.steamLecture;

export default teacherAppSteamLectureSlice.reducer;
