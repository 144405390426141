import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import { isValidUrl } from "@lib/video-utils";
import TextField from "@mui/material/TextField";
import { videoJStreamRegex } from "@root/constants/file";
import React from "react";
import { Control, Controller } from "react-hook-form";
import ReactPlayer from "react-player";

type Props = {
  control: Control<any>;
  disabled?: boolean;
};

const ExternalLinkInput: React.FC<Props> = ({ control, disabled }) => {
  return (
    <FormSectionWrap>
      <FormCategoryTitle>動画URL</FormCategoryTitle>
      <Controller
        control={control}
        name="externalLink"
        defaultValue=""
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <TextField
            type="text"
            variant="outlined"
            fullWidth
            placeholder="動画URL"
            onChange={onChange}
            value={value}
            inputRef={ref}
            disabled={disabled}
            error={!!error}
            helperText={error ? error.message : null}
            data-cy="urlLink-input"
          />
        )}
        rules={{
          validate: {
            positive: (val) => {
              return (
                !val ||
                ReactPlayer.canPlay(val as string) ||
                videoJStreamRegex.test(val) ||
                "動画用のURLではありません。"
              );
            },
          },
          pattern: {
            value: isValidUrl,
            message: "不正なURLです。",
          },
          maxLength: {
            value: 255,
            message: "URLは最大255文字までで入力ください。",
          },
        }}
      />
    </FormSectionWrap>
  );
};

export default ExternalLinkInput;
ExternalLinkInput.defaultProps = {
  disabled: false,
};
