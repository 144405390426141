import { getSteamLibraryContentImages } from "@actions/steamLibrary";
import { SteamLibraryContentImage } from "@actions/types/steamLibrary";
import { Api, GetSteamContentsAsStudentParams } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getSteamContentDetailsAsStudent = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibraryContent/fetch",
  async (contentId, { rejectWithValue }) => {
    try {
      const response = await api.student.getStudentSteamContentsId(contentId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSteamLibraryContentsAsStudent = createAsyncThunk<
  any,
  GetSteamContentsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibraryContents/fetch",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await api.student.getSteamContentsAsStudent(params);
      const contentIds = response.data.steam_contents?.map(
        (content) => content.sl_content_id,
      );
      await dispatch(getSteamLibraryContentImages(contentIds as number[]));
      const contentsWithImages = response.data?.steam_contents?.map(
        (content) => ({
          ...content,
          top_image: getState().steamLibrary.contentImages.find(
            (image: SteamLibraryContentImage) =>
              image.id === content.sl_content_id,
          ),
        }),
      );
      return {
        ...response.data,
        steam_contents: contentsWithImages,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetSteamContentsState = createAction(
  "student/steamLibraryContents/resetState",
);
