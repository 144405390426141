import ColorChange from "@assets/images/svg/colorChange.svg?react";
import { StyledTooltip } from "@components/UI/atoms/StyledComponents/Tooltip";
import { Menu,MenuItem } from "@mui/material";
import theme from "@root/styles/theme";
import { Editor } from "@tiptap/react";
import _ from "lodash";
import React, { memo, useContext, useState } from "react";

import { getCheckBoxImage } from "../useSVEditorStyles";
import { BubbleMenuContext } from "./BubbleMenuContext";
import { Colors, useStyles } from "./styles";

export const ColorGroup = (): JSX.Element => {
  const { editor, subMenuOpen, setSubMenuOpen, isOtherSubMenuOpen } =
    useContext(BubbleMenuContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setSubMenuOpen({
      ...subMenuOpen,
      colorGroup: false,
    });

    setAnchorEl(null);
  };
  const isSameColor = (editorVal: Editor, color: string) => {
    const attributes = editorVal.getAttributes("textStyle");
    return attributes.color === color;
  };

  const getColor = (editorVal: Editor): string => {
    const color = Object.keys(Colors).find((item) => {
      return isSameColor(editorVal, Colors[item]);
    });
    return color ?? theme.palette.common.black;
  };

  return (
    <>
      <button
        type="button"
        onClick={(event) => {
          if (!anchorEl) {
            setSubMenuOpen({
              ...subMenuOpen,
              colorGroup: true,
            });
            setAnchorEl(event.currentTarget);
          } else {
            handleClose();
          }
        }}
        className={
          editor.isActive("textStyle") ? classes.isActive : classes.default
        }
        style={{
          color: getColor(editor),
        }}
        disabled={isOtherSubMenuOpen("colorGroup")}
      >
        <StyledTooltip title="文字色" placement="top">
          <ColorChange fontSize="small" />
        </StyledTooltip>
      </button>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {_.chunk(Object.keys(Colors), 4).map((columns) => {
          const key = columns.map((column) => column).join("+");
          return (
            <div className={classes.colorColumn} key={key}>
              {columns.map((color) => {
                return color === "reset" ? (
                  <MenuItem
                    onClick={() => {
                      editor.chain().focus().unsetColor().run();
                      handleClose();
                    }}
                    className={classes.colorReset}
                    key={color}
                    style={{
                      ...(!editor.isActive("textStyle") && {
                        backgroundImage: getCheckBoxImage(
                          theme.palette.common.black,
                          32,
                          32,
                        ),
                      }),
                    }}
                  />
                ) : (
                  <MenuItem
                    onClick={() => {
                      editor.chain().focus().setColor(Colors[color]).run();
                      handleClose();
                    }}
                    className={classes.colorDefault}
                    style={{
                      color: Colors[color],
                      backgroundColor: Colors[color],
                      ...(isSameColor(editor, Colors[color]) && {
                        backgroundImage: getCheckBoxImage("white", 32, 32),
                      }),
                    }}
                    key={color}
                  />
                );
              })}
            </div>
          );
        })}
      </Menu>
    </>
  );
};

export default memo(ColorGroup);
