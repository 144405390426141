/* eslint-disable camelcase */
import CastleFlag from "@assets/images/svg/CastleFlag.svg?react";
import Document from "@assets/images/svg/documentIcon.svg?react";
import Pen from "@assets/images/svg/edit_pen.svg?react";
import HomeWork from "@assets/images/svg/homeworkIcon.svg?react";
import Section from "@components/UI/organisms/Layout/SectionWrapper";
import BallotIcon from "@mui/icons-material/Ballot";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DeliverableListBase } from "@root/endpoints/TimeTactApi/teacherApi";
import React from "react";
import { useTranslation } from "react-i18next";

import { LogsContent } from "../../Logs/hooks/useHandleStudyLogsByContentType";
import SectionDataSkeleton from "./SectionDataSkeleton";
import SectionsAndData from "./SectionsAndData";

type Props = {
  isFetchingStudyLog: boolean;
  studentInfos: DeliverableListBase | undefined;
  startDate: string;
  endDate: string;
};

export const studyLogIcons = makeStyles((theme: Theme) =>
  createStyles({
    iconStyle: {
      fill: theme.palette.tertiary.mainDark,
      width: "25px",
      height: "25px",
      marginRight: theme.spacing(1),
      alignSelf: "center",
    },
  }),
);

export type SectionsArrayType = {
  id: number;
  title: string;
  icon: JSX.Element;
  contentType: LogsContent;
};

const LogsBySections: React.FC<Props> = ({
  isFetchingStudyLog,
  studentInfos,
  ...rest
}) => {
  const classes = studyLogIcons();
  const { t } = useTranslation(["dashboard", "study_logs"]);

  const sections: SectionsArrayType[] = [
    {
      id: 1,
      title: t("learningPackage"),
      icon: <BallotIcon className={classes.iconStyle} />,
      contentType: LogsContent.LEARNING_PACKAGE,
    },
    {
      id: 2,
      title: t("pbl"),
      icon: <CastleFlag className={classes.iconStyle} />,
      contentType: LogsContent.ARTICLE,
    },
    {
      id: 3,
      title: t("answers"),
      icon: <Document className={classes.iconStyle} />,
      contentType: LogsContent.REPORTS,
    },
    {
      id: 4,
      title: t("homework"),
      icon: <HomeWork className={classes.iconStyle} />,
      contentType: LogsContent.HOMEWORKS,
    },
    {
      id: 5,
      title: t("materialWork"),
      icon: <Pen className={classes.iconStyle} />,
      contentType: LogsContent.MATERIAL_WORKS,
    },
  ];

  return (
    <Section title={t("study_logs:deliverable")}>
      {isFetchingStudyLog ? (
        <SectionDataSkeleton />
      ) : (
        <>
          {sections.map((section: SectionsArrayType) => (
            <SectionsAndData
              key={section.id}
              section={section}
              studentInfos={studentInfos}
              {...rest}
            />
          ))}
        </>
      )}
    </Section>
  );
};

export default LogsBySections;
