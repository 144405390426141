import { baseApi as api } from "../baseApi";
export const addTagTypes = [
  "AdminOwner/Affiliate",
  "Companies",
  "Users",
  "Admin/grade_masters",
  "Admin/ReviewerCompanyGroup",
  "Admin/IpWhitelists",
  "Admin/User",
  "Admin/Controllers",
  "Admin/Notification",
  "Admin/Ebl/Item",
  "Admin/Steam/Contents",
  "CategoryRenameMap",
  "Admin/CategoryRenameMap",
  "Admin/ContentCompanyReviewings",
  "Admin/Sponsor/Infos",
  "Admin/Sponsor/InfoCategories",
  "Admin/Sponsor/Projects",
  "Admin/Sponsor/Categories",
  "Admin/Sponsor/ProjectCategories",
  "Admin/Sponsor/Lectures",
  "Admin/Sponsor/LectureFiles",
  "Admin/Sponsor/ArticleContentTemplate",
  "Admin/Sponsor/ArticleContentTemplateField",
  "Admin/FeedbackTemplates",
  "Admin/FeedbackTemplateComments",
  "Admin/Sponsor/Links",
  "Admin/AttachedImages",
  "Admin/OptionsExpansion",
  "Admin/UniversityCooperations",
  "Admin/TtCsvExports",
  "Admin/Gogo/Linkings",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      affiliateUpdateAsAdmin: build.mutation<
        AffiliateUpdateAsAdminApiResponse,
        AffiliateUpdateAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/affiliates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            accessToken: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["AdminOwner/Affiliate"],
      }),
      fetchCompaniesAsAdmin: build.query<
        FetchCompaniesAsAdminApiResponse,
        FetchCompaniesAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
            prefecture: queryArg.prefecture,
            company_type: queryArg.companyType,
            has_university_cooperation_feature:
              queryArg.hasUniversityCooperationFeature,
            company_id: queryArg.companyId,
          },
        }),
        providesTags: ["Companies"],
      }),
      postAdminCompanies: build.mutation<
        PostAdminCompaniesApiResponse,
        PostAdminCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies`,
          method: "POST",
          body: queryArg.companyRequestBodyAsAdmin,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Companies"],
      }),
      getAdminCompaniesByCompanyId: build.query<
        GetAdminCompaniesByCompanyIdApiResponse,
        GetAdminCompaniesByCompanyIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Companies"],
      }),
      putAdminCompaniesByCompanyId: build.mutation<
        PutAdminCompaniesByCompanyIdApiResponse,
        PutAdminCompaniesByCompanyIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}`,
          method: "PUT",
          body: queryArg.companyRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Companies"],
      }),
      deleteAdminCompaniesByCompanyId: build.mutation<
        DeleteAdminCompaniesByCompanyIdApiResponse,
        DeleteAdminCompaniesByCompanyIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Companies"],
      }),
      getAdminCompaniesByCompanyIdOwners: build.query<
        GetAdminCompaniesByCompanyIdOwnersApiResponse,
        GetAdminCompaniesByCompanyIdOwnersApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/owners`,
        }),
        providesTags: ["Users"],
      }),
      postAdminReviewerCompanyReviewings: build.mutation<
        PostAdminReviewerCompanyReviewingsApiResponse,
        PostAdminReviewerCompanyReviewingsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/reviewer_company_reviewings`,
          method: "POST",
          body: queryArg.reviewerCompanyReviewingRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Companies"],
      }),
      getAdminGradeMasters: build.query<
        GetAdminGradeMastersApiResponse,
        GetAdminGradeMastersApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/grade_masters`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: { company_id: queryArg.companyId },
        }),
        providesTags: ["Admin/grade_masters"],
      }),
      putAdminGradeMastersById: build.mutation<
        PutAdminGradeMastersByIdApiResponse,
        PutAdminGradeMastersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/grade_masters/${queryArg.id}`,
          method: "PUT",
          body: queryArg.graduationYearRequiredRequestBody,
        }),
        invalidatesTags: ["Admin/grade_masters"],
      }),
      getAdminCompaniesByCompanyIdMaterialCapacity: build.query<
        GetAdminCompaniesByCompanyIdMaterialCapacityApiResponse,
        GetAdminCompaniesByCompanyIdMaterialCapacityApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/material_capacity`,
        }),
        providesTags: ["Companies"],
      }),
      getAdminCompaniesByCompanyIdTeachers: build.query<
        GetAdminCompaniesByCompanyIdTeachersApiResponse,
        GetAdminCompaniesByCompanyIdTeachersApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/teachers`,
        }),
        providesTags: ["Users"],
      }),
      getAdminCompaniesByCompanyIdStudents: build.query<
        GetAdminCompaniesByCompanyIdStudentsApiResponse,
        GetAdminCompaniesByCompanyIdStudentsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/students`,
        }),
        providesTags: ["Users"],
      }),
      getAdminCompaniesByCompanyIdSuperOwners: build.query<
        GetAdminCompaniesByCompanyIdSuperOwnersApiResponse,
        GetAdminCompaniesByCompanyIdSuperOwnersApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/super_owners`,
        }),
        providesTags: ["Users"],
      }),
      getContentProvidersAsAdmin: build.query<
        GetContentProvidersAsAdminApiResponse,
        GetContentProvidersAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/content_providers`,
        }),
        providesTags: ["Users"],
      }),
      getReviewerCompanyGroupControllersAsAdmin: build.query<
        GetReviewerCompanyGroupControllersAsAdminApiResponse,
        GetReviewerCompanyGroupControllersAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/reviewer_company_groups`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Admin/ReviewerCompanyGroup"],
      }),
      postReviewerCompanyGroupAsAdmin: build.mutation<
        PostReviewerCompanyGroupAsAdminApiResponse,
        PostReviewerCompanyGroupAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/reviewer_company_groups`,
          method: "POST",
          body: queryArg.reviewerCompanyReviewingRequestBodyAsAdmin,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/ReviewerCompanyGroup"],
      }),
      getReviewerCompanyGroupAsAdmin: build.query<
        GetReviewerCompanyGroupAsAdminApiResponse,
        GetReviewerCompanyGroupAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/reviewer_company_groups/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Admin/ReviewerCompanyGroup"],
      }),
      putReviewerCompanyGroupAsAdmin: build.mutation<
        PutReviewerCompanyGroupAsAdminApiResponse,
        PutReviewerCompanyGroupAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/reviewer_company_groups/${queryArg.id}`,
          method: "PUT",
          body: queryArg.reviewerCompanyReviewingRequestBodyAsAdmin,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/ReviewerCompanyGroup"],
      }),
      deleteControllerAsAdmin: build.mutation<
        DeleteControllerAsAdminApiResponse,
        DeleteControllerAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/reviewer_company_groups/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/ReviewerCompanyGroup"],
      }),
      getCompaniesContentCompaniesAsAdmin: build.query<
        GetCompaniesContentCompaniesAsAdminApiResponse,
        GetCompaniesContentCompaniesAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/content_companies`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Companies"],
      }),
      getIpWhitelistsAsAdmin: build.query<
        GetIpWhitelistsAsAdminApiResponse,
        GetIpWhitelistsAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/ip_whitelists`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            company_id: queryArg.companyId,
          },
        }),
        providesTags: ["Admin/IpWhitelists"],
      }),
      postIpWhitelistsAsAdmin: build.mutation<
        PostIpWhitelistsAsAdminApiResponse,
        PostIpWhitelistsAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/ip_whitelists`,
          method: "POST",
          body: queryArg.postIpWhitelistsRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/IpWhitelists"],
      }),
      putIpWhitelistAsAdmin: build.mutation<
        PutIpWhitelistAsAdminApiResponse,
        PutIpWhitelistAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/ip_whitelists/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putIpWhitelistsRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/IpWhitelists"],
      }),
      deleteIpWhitelistAsAdmin: build.mutation<
        DeleteIpWhitelistAsAdminApiResponse,
        DeleteIpWhitelistAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/ip_whitelists/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/IpWhitelists"],
      }),
      getAdminUsersByUserId: build.query<
        GetAdminUsersByUserIdApiResponse,
        GetAdminUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/${queryArg.userId}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Users"],
      }),
      deleteAdminUsersByUserId: build.mutation<
        DeleteAdminUsersByUserIdApiResponse,
        DeleteAdminUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/${queryArg.userId}`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Users"],
      }),
      deleteAdminUsersByIdHard: build.mutation<
        DeleteAdminUsersByIdHardApiResponse,
        DeleteAdminUsersByIdHardApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/${queryArg.id}/hard`,
          method: "DELETE",
        }),
        invalidatesTags: ["Admin/User"],
      }),
      postAdminUsersAdmin: build.mutation<
        PostAdminUsersAdminApiResponse,
        PostAdminUsersAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/admin`,
          method: "POST",
          body: queryArg.adminRegistrationRequestBody,
        }),
        invalidatesTags: ["Admin/User"],
      }),
      postAdminUsersSuperOwner: build.mutation<
        PostAdminUsersSuperOwnerApiResponse,
        PostAdminUsersSuperOwnerApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/super_owner`,
          method: "POST",
          body: queryArg.userRegistrationRequestBodyAsAdmin,
        }),
        invalidatesTags: ["Admin/User"],
      }),
      postAdminUsersReviewer: build.mutation<
        PostAdminUsersReviewerApiResponse,
        PostAdminUsersReviewerApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/reviewer`,
          method: "POST",
          body: queryArg.userRegistrationRequestBodyAsAdmin,
        }),
        invalidatesTags: ["Admin/User"],
      }),
      postAdminUsersContentProvider: build.mutation<
        PostAdminUsersContentProviderApiResponse,
        PostAdminUsersContentProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/content_provider`,
          method: "POST",
          body: queryArg.userRegistrationRequestBodyAsAdmin,
        }),
        invalidatesTags: ["Admin/User"],
      }),
      postAdminUsersByIdAuthorizedSignIn: build.mutation<
        PostAdminUsersByIdAuthorizedSignInApiResponse,
        PostAdminUsersByIdAuthorizedSignInApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/${queryArg.id}/authorized_sign_in`,
          method: "POST",
        }),
        invalidatesTags: ["Admin/User"],
      }),
      getSearchUserControllersAsAdmin: build.query<
        GetSearchUserControllersAsAdminApiResponse,
        GetSearchUserControllersAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/search`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            email: queryArg.email,
          },
        }),
        providesTags: ["Admin/User"],
      }),
      bulkDeleteControllerAsAdmin: build.mutation<
        BulkDeleteControllerAsAdminApiResponse,
        BulkDeleteControllerAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/${queryArg.id}/bulk_destroy`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Controllers"],
      }),
      resetPasswordAsAdmin: build.mutation<
        ResetPasswordAsAdminApiResponse,
        ResetPasswordAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/${queryArg.id}/reset_password`,
          method: "PUT",
          body: queryArg.authorizationPasswordRequestBody,
        }),
        invalidatesTags: ["Admin/User"],
      }),
      postAdminUsers: build.mutation<
        PostAdminUsersApiResponse,
        PostAdminUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users`,
          method: "POST",
          body: queryArg.basicUserInfo,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Users"],
      }),
      getAdminNotifications: build.query<
        GetAdminNotificationsApiResponse,
        GetAdminNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/notifications`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            sent: queryArg.sent,
          },
        }),
        providesTags: ["Admin/Notification"],
      }),
      postAdminNotifications: build.mutation<
        PostAdminNotificationsApiResponse,
        PostAdminNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/notifications`,
          method: "POST",
          body: queryArg.notificationRequestBodyAsAdmin,
        }),
        invalidatesTags: ["Admin/Notification"],
      }),
      getAdminNotificationsById: build.query<
        GetAdminNotificationsByIdApiResponse,
        GetAdminNotificationsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/admin/notifications/${queryArg.id}` }),
        providesTags: ["Admin/Notification"],
      }),
      putAdminNotificationsById: build.mutation<
        PutAdminNotificationsByIdApiResponse,
        PutAdminNotificationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/notifications/${queryArg.id}`,
          method: "PUT",
          body: queryArg.notificationRequestBody,
        }),
        invalidatesTags: ["Admin/Notification"],
      }),
      deleteAdminNotificationsById: build.mutation<
        DeleteAdminNotificationsByIdApiResponse,
        DeleteAdminNotificationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/notifications/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Admin/Notification"],
      }),
      getEblItemsAsAdmin: build.query<
        GetEblItemsAsAdminApiResponse,
        GetEblItemsAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/ebl/items`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Admin/Ebl/Item"],
      }),
      postEblItemAsAdmin: build.mutation<
        PostEblItemAsAdminApiResponse,
        PostEblItemAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/ebl/items`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Admin/Ebl/Item"],
      }),
      getEblItemAsAdmin: build.query<
        GetEblItemAsAdminApiResponse,
        GetEblItemAsAdminApiArg
      >({
        query: (queryArg) => ({ url: `/admin/ebl/items/${queryArg.id}` }),
        providesTags: ["Admin/Ebl/Item"],
      }),
      putEblItemAsAdmin: build.mutation<
        PutEblItemAsAdminApiResponse,
        PutEblItemAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/ebl/items/${queryArg.id}`,
          method: "PATCH",
        }),
        invalidatesTags: ["Admin/Ebl/Item"],
      }),
      deleteEblItemAsAdmin: build.mutation<
        DeleteEblItemAsAdminApiResponse,
        DeleteEblItemAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/ebl/items/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Admin/Ebl/Item"],
      }),
      getAdminSteamContents: build.query<
        GetAdminSteamContentsApiResponse,
        GetAdminSteamContentsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/pbl/steam/contents`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Admin/Steam/Contents"],
      }),
      postAdminSteamContents: build.mutation<
        PostAdminSteamContentsApiResponse,
        PostAdminSteamContentsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/pbl/steam/contents`,
          method: "POST",
          body: queryArg.pblSteamContentRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Steam/Contents"],
      }),
      getAdminSteamContentsId: build.query<
        GetAdminSteamContentsIdApiResponse,
        GetAdminSteamContentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/pbl/steam/contents/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Admin/Steam/Contents"],
      }),
      putAdminSteamContentsId: build.mutation<
        PutAdminSteamContentsIdApiResponse,
        PutAdminSteamContentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/pbl/steam/contents/${queryArg.id}`,
          method: "PUT",
          body: queryArg.pblSteamContentRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Steam/Contents"],
      }),
      deleteAdminSteamContentsId: build.mutation<
        DeleteAdminSteamContentsIdApiResponse,
        DeleteAdminSteamContentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/pbl/steam/contents/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Steam/Contents"],
      }),
      fetchAdminSteamContents: build.mutation<
        FetchAdminSteamContentsApiResponse,
        FetchAdminSteamContentsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/pbl/steam/contents/fetch`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { steam_library_content_id: queryArg.steamLibraryContentId },
        }),
        invalidatesTags: ["Admin/Steam/Contents"],
      }),
      createCategoryRenameMapAsAdmin: build.mutation<
        CreateCategoryRenameMapAsAdminApiResponse,
        CreateCategoryRenameMapAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/category_rename_maps`,
          method: "POST",
          body: queryArg.categoryRenameMapRequestBodyAsAdmin,
        }),
        invalidatesTags: ["CategoryRenameMap", "Admin/CategoryRenameMap"],
      }),
      fetchCategoryRenameMapAsAdmin: build.query<
        FetchCategoryRenameMapAsAdminApiResponse,
        FetchCategoryRenameMapAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/category_rename_maps/${queryArg.id}`,
        }),
        providesTags: ["CategoryRenameMap", "Admin/CategoryRenameMap"],
      }),
      updateCategoryRenameMapAsAdmin: build.mutation<
        UpdateCategoryRenameMapAsAdminApiResponse,
        UpdateCategoryRenameMapAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/category_rename_maps/${queryArg.id}`,
          method: "PUT",
          body: queryArg.categoryRenameMapRequestBody,
        }),
        invalidatesTags: ["CategoryRenameMap", "Admin/CategoryRenameMap"],
      }),
      fetchCategoryRenameMapByCompanyIdAsAdmin: build.query<
        FetchCategoryRenameMapByCompanyIdAsAdminApiResponse,
        FetchCategoryRenameMapByCompanyIdAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/category_rename_maps/fetch_by_company/${queryArg.companyId}`,
        }),
        providesTags: ["CategoryRenameMap", "Admin/CategoryRenameMap"],
      }),
      getAdminContentCompanyReviewings: build.query<
        GetAdminContentCompanyReviewingsApiResponse,
        GetAdminContentCompanyReviewingsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/content_company_reviewings/${queryArg.contentCompanyId}/`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            sent: queryArg.sent,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["Admin/ContentCompanyReviewings"],
      }),
      getAdminContentCompanyReviewingsReviewFeatureCompanies: build.query<
        GetAdminContentCompanyReviewingsReviewFeatureCompaniesApiResponse,
        GetAdminContentCompanyReviewingsReviewFeatureCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/content_company_reviewings/${queryArg.contentCompanyId}/review_feature_companies`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            sent: queryArg.sent,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["Admin/ContentCompanyReviewings"],
      }),
      patchAdminContentCompanyReviewingsBatchCreate: build.mutation<
        PatchAdminContentCompanyReviewingsBatchCreateApiResponse,
        PatchAdminContentCompanyReviewingsBatchCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/content_company_reviewings/${queryArg.contentCompanyId}/batch_create`,
          method: "PATCH",
          body: queryArg.companyIdsRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/ContentCompanyReviewings"],
      }),
      patchAdminContentCompanyReviewingsBatchDelete: build.mutation<
        PatchAdminContentCompanyReviewingsBatchDeleteApiResponse,
        PatchAdminContentCompanyReviewingsBatchDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/content_company_reviewings/${queryArg.contentCompanyId}/batch_delete`,
          method: "PATCH",
          body: queryArg.companyIdsRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/ContentCompanyReviewings"],
      }),
      getAdminSponsorInfos: build.query<
        GetAdminSponsorInfosApiResponse,
        GetAdminSponsorInfosApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/infos`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            is_linking: queryArg.isLinking,
            company_id: queryArg.companyId,
          },
        }),
        providesTags: ["Admin/Sponsor/Infos"],
      }),
      createSponsorInfoAsAdmin: build.mutation<
        CreateSponsorInfoAsAdminApiResponse,
        CreateSponsorInfoAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/infos`,
          method: "POST",
          body: queryArg.postSponsorInfoRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Infos"],
      }),
      getAdminSponsorInfosId: build.query<
        GetAdminSponsorInfosIdApiResponse,
        GetAdminSponsorInfosIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/infos/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Admin/Sponsor/Infos"],
      }),
      updateSponsorInfoAsAdmin: build.mutation<
        UpdateSponsorInfoAsAdminApiResponse,
        UpdateSponsorInfoAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/infos/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putSponsorInfoRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Infos"],
      }),
      deleteSponsorInfoAsAdmin: build.mutation<
        DeleteSponsorInfoAsAdminApiResponse,
        DeleteSponsorInfoAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/infos/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Infos"],
      }),
      fetchPblSponsorLinkableCompaniesAsAdmin: build.query<
        FetchPblSponsorLinkableCompaniesAsAdminApiResponse,
        FetchPblSponsorLinkableCompaniesAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/infos/${queryArg.infoId}/linkable_companies`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            search: queryArg.search,
            prefecture: queryArg.prefecture,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Companies"],
      }),
      createSponsorInfoCategoryAsAdmin: build.mutation<
        CreateSponsorInfoCategoryAsAdminApiResponse,
        CreateSponsorInfoCategoryAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/info_categories`,
          method: "POST",
          body: queryArg.postSponsorCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Infos"],
      }),
      fetchSponsorInfoCategoryAsAdmin: build.query<
        FetchSponsorInfoCategoryAsAdminApiResponse,
        FetchSponsorInfoCategoryAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/info_categories/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Admin/Sponsor/InfoCategories"],
      }),
      updateSponsorInfoCategoryAsAdmin: build.mutation<
        UpdateSponsorInfoCategoryAsAdminApiResponse,
        UpdateSponsorInfoCategoryAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/info_categories/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putSponsorCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/InfoCategories"],
      }),
      deleteSponsorInfoCategoryAsAdmin: build.mutation<
        DeleteSponsorInfoCategoryAsAdminApiResponse,
        DeleteSponsorInfoCategoryAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/info_categories/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/InfoCategories"],
      }),
      fetchSponsorProjectsAsAdmin: build.query<
        FetchSponsorProjectsAsAdminApiResponse,
        FetchSponsorProjectsAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            company_id: queryArg.companyId,
            sponsor_id: queryArg.sponsorId,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            professor_affiliate_id: queryArg.professorAffiliateId,
          },
        }),
        providesTags: ["Admin/Sponsor/Projects"],
      }),
      createSponsorProjectAsAdmin: build.mutation<
        CreateSponsorProjectAsAdminApiResponse,
        CreateSponsorProjectAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects`,
          method: "POST",
          body: queryArg.postSponsorProjectRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Projects"],
      }),
      fetchSponsorProjectAsAdmin: build.query<
        FetchSponsorProjectAsAdminApiResponse,
        FetchSponsorProjectAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Admin/Sponsor/Projects"],
      }),
      updateSponsorProjectAsAdmin: build.mutation<
        UpdateSponsorProjectAsAdminApiResponse,
        UpdateSponsorProjectAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putSponsorProjectRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Projects"],
      }),
      deleteSponsorProjectAsAdmin: build.mutation<
        DeleteSponsorProjectAsAdminApiResponse,
        DeleteSponsorProjectAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Projects"],
      }),
      sortSponsorProjectAsAdmin: build.mutation<
        SortSponsorProjectAsAdminApiResponse,
        SortSponsorProjectAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/sequence`,
          method: "PUT",
          body: queryArg.sponsorProjectSequencesRequestBody,
        }),
        invalidatesTags: ["Admin/Sponsor/Projects"],
      }),
      fetchSponsorProjectsForCarouselAsAdmin: build.query<
        FetchSponsorProjectsForCarouselAsAdminApiResponse,
        FetchSponsorProjectsForCarouselAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/carousel`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            number_of_projects: queryArg.numberOfProjects,
            sort_type: queryArg.sortType,
          },
        }),
        providesTags: ["Admin/Sponsor/Projects"],
      }),
      fetchSponsorProjectListAsAdmin: build.query<
        FetchSponsorProjectListAsAdminApiResponse,
        FetchSponsorProjectListAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            professor_affiliate_id: queryArg.professorAffiliateId,
          },
        }),
        providesTags: ["Admin/Sponsor/Projects"],
      }),
      toggleDisplayOfProjectOnCarouselAsAdmin: build.mutation<
        ToggleDisplayOfProjectOnCarouselAsAdminApiResponse,
        ToggleDisplayOfProjectOnCarouselAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/${queryArg.id}/toggle_carousel_display`,
          method: "PUT",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Projects"],
      }),
      createSponsorProjectCategoryAsAdmin: build.mutation<
        CreateSponsorProjectCategoryAsAdminApiResponse,
        CreateSponsorProjectCategoryAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/project_categories`,
          method: "POST",
          body: queryArg.postSponsorProjectCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Categories"],
      }),
      getAdminSponsorProjectCategoriesId: build.query<
        GetAdminSponsorProjectCategoriesIdApiResponse,
        GetAdminSponsorProjectCategoriesIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/project_categories/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Admin/Sponsor/ProjectCategories"],
      }),
      updateSponsorProjectCategoryAsAdmin: build.mutation<
        UpdateSponsorProjectCategoryAsAdminApiResponse,
        UpdateSponsorProjectCategoryAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/project_categories/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putSponsorCategoryRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/ProjectCategories"],
      }),
      deleteSponsorProjectCategoryAsAdmin: build.mutation<
        DeleteSponsorProjectCategoryAsAdminApiResponse,
        DeleteSponsorProjectCategoryAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/project_categories/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/ProjectCategories"],
      }),
      fetchSponsorLecturesAsAdmin: build.query<
        FetchSponsorLecturesAsAdminApiResponse,
        FetchSponsorLecturesAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lectures`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            project_id: queryArg.projectId,
          },
        }),
        providesTags: ["Admin/Sponsor/Lectures"],
      }),
      createSponsorLectureAsAdmin: build.mutation<
        CreateSponsorLectureAsAdminApiResponse,
        CreateSponsorLectureAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lectures`,
          method: "POST",
          body: queryArg.postSponsorLectureRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Lectures"],
      }),
      fetchSponsorLectureAsAdmin: build.query<
        FetchSponsorLectureAsAdminApiResponse,
        FetchSponsorLectureAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lectures/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Admin/Sponsor/Lectures"],
      }),
      updateSponsorLectureAsAdmin: build.mutation<
        UpdateSponsorLectureAsAdminApiResponse,
        UpdateSponsorLectureAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lectures/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putSponsorLectureRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Lectures"],
      }),
      deleteSponsorLectureAsAdmin: build.mutation<
        DeleteSponsorLectureAsAdminApiResponse,
        DeleteSponsorLectureAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lectures/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Lectures"],
      }),
      putSponsorLectureSequenceAsAdmin: build.mutation<
        PutSponsorLectureSequenceAsAdminApiResponse,
        PutSponsorLectureSequenceAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lectures/sequence`,
          method: "PUT",
          body: queryArg.sponsorLectureSequencesRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { project_id: queryArg.projectId },
        }),
        invalidatesTags: ["Admin/Sponsor/Lectures"],
      }),
      createSponsorLectureFileAsAdmin: build.mutation<
        CreateSponsorLectureFileAsAdminApiResponse,
        CreateSponsorLectureFileAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lecture_files`,
          method: "POST",
          body: queryArg.sponsorLectureFileRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/LectureFiles"],
      }),
      fetchSponsorLectureFileAsAdmin: build.query<
        FetchSponsorLectureFileAsAdminApiResponse,
        FetchSponsorLectureFileAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lecture_files/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Admin/Sponsor/LectureFiles"],
      }),
      updateSponsorLectureFileAsAdmin: build.mutation<
        UpdateSponsorLectureFileAsAdminApiResponse,
        UpdateSponsorLectureFileAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lecture_files/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/LectureFiles"],
      }),
      deleteSponsorLectureFileAsAdmin: build.mutation<
        DeleteSponsorLectureFileAsAdminApiResponse,
        DeleteSponsorLectureFileAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/projects/lecture_files/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/LectureFiles"],
      }),
      postSponsorArticleContentTemplateAsAdmin: build.mutation<
        PostSponsorArticleContentTemplateAsAdminApiResponse,
        PostSponsorArticleContentTemplateAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/article_content_templates`,
          method: "POST",
          body: queryArg.postSponsorArticleContentTemplateRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { sponsor_lecture_id: queryArg.sponsorLectureId },
        }),
        invalidatesTags: ["Admin/Sponsor/ArticleContentTemplate"],
      }),
      getSponsorArticleContentTemplateAsAdmin: build.query<
        GetSponsorArticleContentTemplateAsAdminApiResponse,
        GetSponsorArticleContentTemplateAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/article_content_templates/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { sponsor_lecture_id: queryArg.sponsorLectureId },
        }),
        providesTags: ["Admin/Sponsor/ArticleContentTemplate"],
      }),
      putSponsorArticleContentTemplateAsAdmin: build.mutation<
        PutSponsorArticleContentTemplateAsAdminApiResponse,
        PutSponsorArticleContentTemplateAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/article_content_templates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putSponsorArticleContentTemplateFieldAsAdminRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { sponsor_lecture_id: queryArg.sponsorLectureId },
        }),
        invalidatesTags: ["Admin/Sponsor/ArticleContentTemplate"],
      }),
      deleteSponsorArticleContentTemplateAsAdmin: build.mutation<
        DeleteSponsorArticleContentTemplateAsAdminApiResponse,
        DeleteSponsorArticleContentTemplateAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/article_content_templates/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { sponsor_lecture_id: queryArg.sponsorLectureId },
        }),
        invalidatesTags: ["Admin/Sponsor/ArticleContentTemplate"],
      }),
      postSponsorArticleContentTemplateFieldAsAdmin: build.mutation<
        PostSponsorArticleContentTemplateFieldAsAdminApiResponse,
        PostSponsorArticleContentTemplateFieldAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/article_content_templates/${queryArg.articleContentTemplateId}/article_content_template_fields`,
          method: "POST",
          body: queryArg.putSponsorArticleContentTemplateFieldAsAdminRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/ArticleContentTemplateField"],
      }),
      putSponsorArticleContentTemplateFieldAsAdmin: build.mutation<
        PutSponsorArticleContentTemplateFieldAsAdminApiResponse,
        PutSponsorArticleContentTemplateFieldAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/article_content_templates/${queryArg.articleContentTemplateId}/article_content_template_fields/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putSponsorArticleContentTemplateFieldAsAdminRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/ArticleContentTemplateField"],
      }),
      deleteSponsorArticleContentTemplateFieldAsAdmin: build.mutation<
        DeleteSponsorArticleContentTemplateFieldAsAdminApiResponse,
        DeleteSponsorArticleContentTemplateFieldAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/article_content_templates/${queryArg.articleContentTemplateId}/article_content_template_fields/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/ArticleContentTemplateField"],
      }),
      getFeedbackTemplatesAsAdmin: build.query<
        GetFeedbackTemplatesAsAdminApiResponse,
        GetFeedbackTemplatesAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/feedback_templates`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Admin/FeedbackTemplates"],
      }),
      postFeedbackTemplateAsAdmin: build.mutation<
        PostFeedbackTemplateAsAdminApiResponse,
        PostFeedbackTemplateAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/feedback_templates`,
          method: "POST",
          body: queryArg.feedbackTemplateRequestBodyAsAdmin,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/FeedbackTemplates"],
      }),
      getFeedbackTemplateAsAdmin: build.query<
        GetFeedbackTemplateAsAdminApiResponse,
        GetFeedbackTemplateAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/feedback_templates/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Admin/FeedbackTemplates"],
      }),
      putFeedbackTemplateAsAdmin: build.mutation<
        PutFeedbackTemplateAsAdminApiResponse,
        PutFeedbackTemplateAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/feedback_templates/${queryArg.id}`,
          method: "PUT",
          body: queryArg.iconRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/FeedbackTemplates"],
      }),
      deleteFeedbackTemplateAsAdmin: build.mutation<
        DeleteFeedbackTemplateAsAdminApiResponse,
        DeleteFeedbackTemplateAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/feedback_templates/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/FeedbackTemplates"],
      }),
      postFeedbackTemplateCommentAsAdmin: build.mutation<
        PostFeedbackTemplateCommentAsAdminApiResponse,
        PostFeedbackTemplateCommentAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/feedback_templates/${queryArg.feedbackTemplateId}/feedback_template_comments`,
          method: "POST",
          body: queryArg.bodyRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/FeedbackTemplateComments"],
      }),
      putFeedbackTemplateCommentAsAdmin: build.mutation<
        PutFeedbackTemplateCommentAsAdminApiResponse,
        PutFeedbackTemplateCommentAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/feedback_templates/${queryArg.feedbackTemplateId}/feedback_template_comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.bodyRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/FeedbackTemplateComments"],
      }),
      deleteFeedbackTemplateCommentAsAdmin: build.mutation<
        DeleteFeedbackTemplateCommentAsAdminApiResponse,
        DeleteFeedbackTemplateCommentAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/feedback_templates/${queryArg.feedbackTemplateId}/feedback_template_comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/FeedbackTemplateComments"],
      }),
      getSponsorLinksAsAdmin: build.query<
        GetSponsorLinksAsAdminApiResponse,
        GetSponsorLinksAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/infos/${queryArg.infoId}/links`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Admin/Sponsor/Links"],
      }),
      postSponsorLinksAsAdmin: build.mutation<
        PostSponsorLinksAsAdminApiResponse,
        PostSponsorLinksAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/infos/${queryArg.infoId}/links`,
          method: "POST",
          body: queryArg.sponsorInfoSchoolIdRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Links"],
      }),
      deleteSponsorLinkAsAdmin: build.mutation<
        DeleteSponsorLinkAsAdminApiResponse,
        DeleteSponsorLinkAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/infos/${queryArg.infoId}/links/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Links"],
      }),
      adminSponsorInfosSponsorLinkingBulkInsert: build.mutation<
        AdminSponsorInfosSponsorLinkingBulkInsertApiResponse,
        AdminSponsorInfosSponsorLinkingBulkInsertApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/sponsor/links/sponsor_linking_bulk_insert`,
          method: "PATCH",
          body: queryArg.sponsorInfoLinkingBulkInsertParams,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["Admin/Sponsor/Infos"],
      }),
      postAttachedImageAsAdmin: build.mutation<
        PostAttachedImageAsAdminApiResponse,
        PostAttachedImageAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/attached_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Admin/AttachedImages"],
      }),
      getAttachedImageAsAdmin: build.query<
        GetAttachedImageAsAdminApiResponse,
        GetAttachedImageAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/attached_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["Admin/AttachedImages"],
      }),
      putAttachedImageAsAdmin: build.mutation<
        PutAttachedImageAsAdminApiResponse,
        PutAttachedImageAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/attached_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Admin/AttachedImages"],
      }),
      deleteAttachedImageAsAdmin: build.mutation<
        DeleteAttachedImageAsAdminApiResponse,
        DeleteAttachedImageAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/attached_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Admin/AttachedImages"],
      }),
      postOptionsExpansionAsAdmin: build.mutation<
        PostOptionsExpansionAsAdminApiResponse,
        PostOptionsExpansionAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/option_expansions`,
          method: "POST",
          body: queryArg.body,
          params: { company_id: queryArg.companyId },
        }),
        invalidatesTags: ["Admin/OptionsExpansion"],
      }),
      putOptionsExpansionAsAdmin: build.mutation<
        PutOptionsExpansionAsAdminApiResponse,
        PutOptionsExpansionAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/option_expansions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          params: { company_id: queryArg.companyId },
        }),
        invalidatesTags: ["Admin/OptionsExpansion"],
      }),
      getUniversityCooperationsAsAdmin: build.query<
        GetUniversityCooperationsAsAdminApiResponse,
        GetUniversityCooperationsAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/university_cooperations`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Admin/UniversityCooperations"],
      }),
      postUniversityCooperationAsAdmin: build.mutation<
        PostUniversityCooperationAsAdminApiResponse,
        PostUniversityCooperationAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/university_cooperations`,
          method: "POST",
          body: queryArg.targetCompanyIdsRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/UniversityCooperations"],
      }),
      deleteUniversityCooperationAsAdmin: build.mutation<
        DeleteUniversityCooperationAsAdminApiResponse,
        DeleteUniversityCooperationAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/companies/${queryArg.companyId}/university_cooperations/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Admin/UniversityCooperations"],
      }),
      getTeacherUniversitiesAsAdmin: build.query<
        GetTeacherUniversitiesAsAdminApiResponse,
        GetTeacherUniversitiesAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/teachers/university`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            pbl_sponsor_project_id: queryArg.pblSponsorProjectId,
          },
        }),
        providesTags: ["Users"],
      }),
      getTtCsvExportsAsAdmin: build.query<
        GetTtCsvExportsAsAdminApiResponse,
        GetTtCsvExportsAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/tt_csv/exports`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            types: queryArg.types,
          },
        }),
        providesTags: ["Admin/TtCsvExports"],
      }),
      postTtCsvExportAsAdmin: build.mutation<
        PostTtCsvExportAsAdminApiResponse,
        PostTtCsvExportAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/tt_csv/exports`,
          method: "POST",
          body: queryArg.ttCsvExportRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            sorted_by: queryArg.sortedBy,
            search_key: queryArg.searchKey,
            search_value: queryArg.searchValue,
          },
        }),
        invalidatesTags: ["Admin/TtCsvExports"],
      }),
      getGogoLinkingsAsAdmin: build.query<
        GetGogoLinkingsAsAdminApiResponse,
        GetGogoLinkingsAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/gogo/linkings`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            company_id: queryArg.companyId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Admin/Gogo/Linkings"],
      }),
      postGogoLinkingAsAdmin: build.mutation<
        PostGogoLinkingAsAdminApiResponse,
        PostGogoLinkingAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/gogo/linkings`,
          method: "POST",
          body: queryArg.gogoLinkingRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { company_id: queryArg.companyId },
        }),
        invalidatesTags: ["Admin/Gogo/Linkings"],
      }),
      deleteGogoLinkingAsAdmin: build.mutation<
        DeleteGogoLinkingAsAdminApiResponse,
        DeleteGogoLinkingAsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/gogo/linkings/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { company_id: queryArg.companyId },
        }),
        invalidatesTags: ["Admin/Gogo/Linkings"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as adminApi };
export type AffiliateUpdateAsAdminApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type AffiliateUpdateAsAdminApiArg = {
  id: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    student_suspension?: boolean;
    only_pbl_projects_feature?: boolean;
  };
};
export type FetchCompaniesAsAdminApiResponse = /** status 200 OK */ {
  companies: AdminCompanyList[];
  total_count: number;
};
export type FetchCompaniesAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** For incremental search by name */
  search?: string;
  /** For incremental search by prefecture */
  prefecture?: string;
  companyType?: CompanyTypeFilter;
  /** university_cooperation_featureのオプションが有効になっているCompanyを取得 */
  hasUniversityCooperationFeature?: boolean;
  /** has_university_cooperation_featureが必要。指定した company_id に紐づいている学校を除外する。 */
  companyId?: string;
};
export type PostAdminCompaniesApiResponse = /** status 201 Created */ {
  id: string;
};
export type PostAdminCompaniesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  companyRequestBodyAsAdmin: CompanyRequestBodyAsAdmin;
};
export type GetAdminCompaniesByCompanyIdApiResponse =
  /** status 200 OK */ AdminCompany;
export type GetAdminCompaniesByCompanyIdApiArg = {
  /** 企業ID */
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutAdminCompaniesByCompanyIdApiResponse =
  /** status 202 Accepted */ {
    id: string;
    options: Option;
    options_editable: Option;
  };
export type PutAdminCompaniesByCompanyIdApiArg = {
  /** 企業ID */
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  companyRequestBody: CompanyRequestBody;
};
export type DeleteAdminCompaniesByCompanyIdApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteAdminCompaniesByCompanyIdApiArg = {
  /** 企業ID */
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetAdminCompaniesByCompanyIdOwnersApiResponse =
  /** status 200 OK */ BasicUserInfo[];
export type GetAdminCompaniesByCompanyIdOwnersApiArg = {
  companyId: string;
};
export type PostAdminReviewerCompanyReviewingsApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostAdminReviewerCompanyReviewingsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  reviewerCompanyReviewingRequiredRequestBody: ReviewerCompanyReviewingRequiredRequestBody;
};
export type GetAdminGradeMastersApiResponse =
  /** status 200 OK */ GradeMasterBaseAsAdmin;
export type GetAdminGradeMastersApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  companyId?: string;
};
export type PutAdminGradeMastersByIdApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutAdminGradeMastersByIdApiArg = {
  id: string;
  graduationYearRequiredRequestBody: GraduationYearRequiredRequestBody;
};
export type GetAdminCompaniesByCompanyIdMaterialCapacityApiResponse =
  /** status 200 OK */ {
    material_capacity: string;
    material_limit_capacity: string | null;
  };
export type GetAdminCompaniesByCompanyIdMaterialCapacityApiArg = {
  companyId: string;
};
export type GetAdminCompaniesByCompanyIdTeachersApiResponse =
  /** status 200 OK */ BasicUserInfo[];
export type GetAdminCompaniesByCompanyIdTeachersApiArg = {
  companyId: string;
};
export type GetAdminCompaniesByCompanyIdStudentsApiResponse =
  /** status 200 OK */ BasicUserInfo[];
export type GetAdminCompaniesByCompanyIdStudentsApiArg = {
  companyId: string;
};
export type GetAdminCompaniesByCompanyIdSuperOwnersApiResponse =
  /** status 200 OK */ BasicUserInfo[];
export type GetAdminCompaniesByCompanyIdSuperOwnersApiArg = {
  companyId: string;
};
export type GetContentProvidersAsAdminApiResponse =
  /** status 200 OK */ BasicUserInfo[];
export type GetContentProvidersAsAdminApiArg = {
  companyId: string;
};
export type GetReviewerCompanyGroupControllersAsAdminApiResponse =
  /** status 200 OK */ {
    reviewer_company_groups: ReviewerCompanyGroupBase[];
    total_count: number;
  };
export type GetReviewerCompanyGroupControllersAsAdminApiArg = {
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostReviewerCompanyGroupAsAdminApiResponse = /** status 201 OK */ {
  reviewer_company_group: ReviewerCompanyGroupDetail;
};
export type PostReviewerCompanyGroupAsAdminApiArg = {
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  reviewerCompanyReviewingRequestBodyAsAdmin: ReviewerCompanyReviewingRequestBodyAsAdmin;
};
export type GetReviewerCompanyGroupAsAdminApiResponse = /** status 200 OK */ {
  reviewer_company_group: ReviewerCompanyGroupDetail;
};
export type GetReviewerCompanyGroupAsAdminApiArg = {
  /** CompanyID */
  companyId: string;
  /** ReviewerCompanyGroupID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutReviewerCompanyGroupAsAdminApiResponse =
  /** status 202 Accepted */ {
    reviewer_company_group: ReviewerCompanyGroupDetail;
  };
export type PutReviewerCompanyGroupAsAdminApiArg = {
  /** CompanyID */
  companyId: string;
  /** ReviewerCompanyGroupID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  reviewerCompanyReviewingRequestBodyAsAdmin: ReviewerCompanyReviewingRequestBodyAsAdmin;
};
export type DeleteControllerAsAdminApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteControllerAsAdminApiArg = {
  /** CompanyID */
  companyId: string;
  /** ReviewerCompanyGroupID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetCompaniesContentCompaniesAsAdminApiResponse =
  /** status 200 OK */ {
    companies: (ModelBase & {
      name: string;
      sponsor_info_id?: string | null;
      projects?: {
        id?: string;
        name?: string;
        lectures?: {
          id?: string;
          name?: string;
        }[];
      }[];
    })[];
    total_count: number;
  };
export type GetCompaniesContentCompaniesAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetIpWhitelistsAsAdminApiResponse = /** status 200 OK */ {
  ip_whitelists: IpWhitelistBase[];
  total_count: number;
};
export type GetIpWhitelistsAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  companyId: string;
};
export type PostIpWhitelistsAsAdminApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostIpWhitelistsAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postIpWhitelistsRequestBody: PostIpWhitelistsRequestBody;
};
export type PutIpWhitelistAsAdminApiResponse =
  /** status 202 Accepted */ IpWhitelistBase;
export type PutIpWhitelistAsAdminApiArg = {
  /** IpWhitelist ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putIpWhitelistsRequestBody: PutIpWhitelistsRequestBody;
};
export type DeleteIpWhitelistAsAdminApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteIpWhitelistAsAdminApiArg = {
  /** IpWhitelist ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetAdminUsersByUserIdApiResponse =
  /** status 200 OK */ BasicUserInfo & AffiliateListBase;
export type GetAdminUsersByUserIdApiArg = {
  userId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type DeleteAdminUsersByUserIdApiResponse =
  /** status 202 Accepted */ string;
export type DeleteAdminUsersByUserIdApiArg = {
  userId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    company_id: string;
    role: string;
  };
};
export type DeleteAdminUsersByIdHardApiResponse =
  /** status 204 No Content */ undefined;
export type DeleteAdminUsersByIdHardApiArg = {
  id: string;
};
export type PostAdminUsersAdminApiResponse = /** status 201 Created */ {
  id?: string;
};
export type PostAdminUsersAdminApiArg = {
  adminRegistrationRequestBody: AdminRegistrationRequestBody;
};
export type PostAdminUsersSuperOwnerApiResponse = /** status 201 Created */ {
  id?: string;
};
export type PostAdminUsersSuperOwnerApiArg = {
  userRegistrationRequestBodyAsAdmin: UserRegistrationRequestBodyAsAdmin;
};
export type PostAdminUsersReviewerApiResponse = /** status 201 Created */ {
  id?: string;
};
export type PostAdminUsersReviewerApiArg = {
  userRegistrationRequestBodyAsAdmin: UserRegistrationRequestBodyAsAdmin;
};
export type PostAdminUsersContentProviderApiResponse =
  /** status 201 Created */ {
    id?: string;
  };
export type PostAdminUsersContentProviderApiArg = {
  userRegistrationRequestBodyAsAdmin: UserRegistrationRequestBodyAsAdmin;
};
export type PostAdminUsersByIdAuthorizedSignInApiResponse =
  /** status 200 OK */ {
    data?: {
      id?: number;
      email?: string;
      provider?: string;
      avatar?: Avatar;
      uid?: string;
      last_name?: string;
      first_name?: string;
      allow_password_change?: boolean;
      discarded_at?: string | null;
      type?: string;
    };
  };
export type PostAdminUsersByIdAuthorizedSignInApiArg = {
  id: string;
};
export type GetSearchUserControllersAsAdminApiResponse = /** status 200 OK */ {
  users: BasicUserInfo[];
  is_admin_creator: boolean;
  total_count: number;
};
export type GetSearchUserControllersAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  email: string;
};
export type BulkDeleteControllerAsAdminApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type BulkDeleteControllerAsAdminApiArg = {
  /** UserID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type ResetPasswordAsAdminApiResponse =
  /** status 200 ok */ AccountUserInfo;
export type ResetPasswordAsAdminApiArg = {
  id: string;
  authorizationPasswordRequestBody: AuthorizationPasswordRequestBody;
};
export type PostAdminUsersApiResponse = /** status 201 Created */ {
  id: string;
};
export type PostAdminUsersApiArg = {
  accessToken: string;
  client: string;
  uid: string;
  /** EmailとCompany IDのみ指定 */
  basicUserInfo: BasicUserInfo;
};
export type GetAdminNotificationsApiResponse =
  /** status 200 OK */ Notifications;
export type GetAdminNotificationsApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
  sent?: string;
};
export type PostAdminNotificationsApiResponse = /** status 200 OK */ {
  message?: string;
};
export type PostAdminNotificationsApiArg = {
  notificationRequestBodyAsAdmin: NotificationRequestBodyAsAdmin;
};
export type GetAdminNotificationsByIdApiResponse =
  /** status 200 OK */ NotificationBase;
export type GetAdminNotificationsByIdApiArg = {
  id: string;
};
export type PutAdminNotificationsByIdApiResponse =
  /** status 200 OK */ NotificationBase;
export type PutAdminNotificationsByIdApiArg = {
  id: string;
  notificationRequestBody: NotificationRequestBody;
};
export type DeleteAdminNotificationsByIdApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteAdminNotificationsByIdApiArg = {
  id: string;
};
export type GetEblItemsAsAdminApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type GetEblItemsAsAdminApiArg = {
  page?: number;
  perPage?: number;
};
export type PostEblItemAsAdminApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostEblItemAsAdminApiArg = {
  body: Items & {
    images?: {
      id?: number;
      file?: Blob;
    }[];
  };
};
export type GetEblItemAsAdminApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type GetEblItemAsAdminApiArg = {
  id: number;
};
export type PutEblItemAsAdminApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutEblItemAsAdminApiArg = {
  id: number;
};
export type DeleteEblItemAsAdminApiResponse = /** status 202 Accepted */
  | {
      message?: string;
    }
  | /** status 204 No Content */ undefined;
export type DeleteEblItemAsAdminApiArg = {
  id: number;
};
export type GetAdminSteamContentsApiResponse = /** status 200 OK */ {
  steam_contents?: PblSteamContentBase[];
  total_count?: number;
};
export type GetAdminSteamContentsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostAdminSteamContentsApiResponse =
  /** status 201 Created */ PblSteamContentBase;
export type PostAdminSteamContentsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  pblSteamContentRequestBody: PblSteamContentRequestBody;
};
export type GetAdminSteamContentsIdApiResponse =
  /** status 200 OK */ PblSteamContentBase;
export type GetAdminSteamContentsIdApiArg = {
  /** Pbl::Steam::Content ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutAdminSteamContentsIdApiResponse =
  /** status 202 Accepted */ PblSteamContentBase;
export type PutAdminSteamContentsIdApiArg = {
  /** Pbl::Steam::Content ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  pblSteamContentRequestBody: PblSteamContentRequestBody;
};
export type DeleteAdminSteamContentsIdApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteAdminSteamContentsIdApiArg = {
  /** Pbl::Steam::Content ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchAdminSteamContentsApiResponse = /** status 200 OK */ {
  steam_library_content_id: number;
  steam_library_content_title: string;
};
export type FetchAdminSteamContentsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** Steam ライブラリーのコンテンツID */
  steamLibraryContentId: number;
};
export type CreateCategoryRenameMapAsAdminApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type CreateCategoryRenameMapAsAdminApiArg = {
  categoryRenameMapRequestBodyAsAdmin: CategoryRenameMapRequestBodyAsAdmin;
};
export type FetchCategoryRenameMapAsAdminApiResponse =
  /** status 200 OK */ CategoryRenameMap;
export type FetchCategoryRenameMapAsAdminApiArg = {
  id: string;
};
export type UpdateCategoryRenameMapAsAdminApiResponse =
  /** status 200 OK */ CategoryRenameMap;
export type UpdateCategoryRenameMapAsAdminApiArg = {
  id: string;
  categoryRenameMapRequestBody: CategoryRenameMapRequestBody;
};
export type FetchCategoryRenameMapByCompanyIdAsAdminApiResponse =
  /** status 200 OK */ CategoryRenameMap;
export type FetchCategoryRenameMapByCompanyIdAsAdminApiArg = {
  companyId: string;
};
export type GetAdminContentCompanyReviewingsApiResponse = /** status 200 OK */ {
  reviewee_companies: CompanyBase[];
  total_count: number;
};
export type GetAdminContentCompanyReviewingsApiArg = {
  /** コンテンツ事業者のCompany ID */
  contentCompanyId: string;
  "access-token"?: string;
  uid?: string;
  client?: string;
  sent?: string;
  perPage?: number;
  page?: number;
};
export type GetAdminContentCompanyReviewingsReviewFeatureCompaniesApiResponse =
  /** status 200 OK */ {
    review_feature_companies: CompanyBase[];
    total_count: number;
  };
export type GetAdminContentCompanyReviewingsReviewFeatureCompaniesApiArg = {
  /** コンテンツ事業者のCompany ID */
  contentCompanyId: string;
  "access-token"?: string;
  uid?: string;
  client?: string;
  sent?: string;
  perPage?: number;
  page?: number;
};
export type PatchAdminContentCompanyReviewingsBatchCreateApiResponse =
  /** status 200 OK */ {
    errors: string[];
    reviewee_companies: CompanyBase[];
  };
export type PatchAdminContentCompanyReviewingsBatchCreateApiArg = {
  /** コンテンツ事業者のCompany ID */
  contentCompanyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  companyIdsRequestBody: CompanyIdsRequestBody;
};
export type PatchAdminContentCompanyReviewingsBatchDeleteApiResponse =
  /** status 200 OK */ {
    removed_reviewee_ids: string[];
  };
export type PatchAdminContentCompanyReviewingsBatchDeleteApiArg = {
  /** コンテンツ事業者のCompany ID */
  contentCompanyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  companyIdsRequestBody: CompanyIdsRequestBody;
};
export type GetAdminSponsorInfosApiResponse = /** status 200 OK */ {
  sponsors: SponsorInfoListForAdmin[];
  total_count: number;
};
export type GetAdminSponsorInfosApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: SponsorInfosSearchType;
  searchWord?: string;
  isLinking?: boolean;
  /** - is_linking は必須
    - is_linking が true のとき、指定した company_id に関連する企業を返却
    - is_linking が false のとき、指定した company_id に関連しない企業を返却 */
  companyId?: string;
};
export type CreateSponsorInfoAsAdminApiResponse =
  /** status 201 OK */ SponsorInfoDetailsBase;
export type CreateSponsorInfoAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postSponsorInfoRequestBody: PostSponsorInfoRequestBody;
};
export type GetAdminSponsorInfosIdApiResponse =
  /** status 200 OK */ SponsorInfoDetailsBase;
export type GetAdminSponsorInfosIdApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type UpdateSponsorInfoAsAdminApiResponse =
  /** status 202 Accepted */ SponsorInfoDetailsBase;
export type UpdateSponsorInfoAsAdminApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putSponsorInfoRequestBody: PutSponsorInfoRequestBody;
};
export type DeleteSponsorInfoAsAdminApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteSponsorInfoAsAdminApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchPblSponsorLinkableCompaniesAsAdminApiResponse =
  /** status 200 OK */ {
    companies: {
      id: string;
      name: string;
      link_id: string | null;
    }[];
    total_count: number;
  };
export type FetchPblSponsorLinkableCompaniesAsAdminApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  infoId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  /** For incremental search by name */
  search?: string;
  /** For incremental search by prefecture */
  prefecture?: string;
  page?: number;
  perPage?: number;
};
export type CreateSponsorInfoCategoryAsAdminApiResponse =
  /** status 201 Accepted */ SponsorCategoryBase;
export type CreateSponsorInfoCategoryAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postSponsorCategoryRequestBody: PostSponsorCategoryRequestBody;
};
export type FetchSponsorInfoCategoryAsAdminApiResponse =
  /** status 200 OK */ SponsorCategoryBase;
export type FetchSponsorInfoCategoryAsAdminApiArg = {
  /** 企業カテゴリー ID（Sponsor::Category ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type UpdateSponsorInfoCategoryAsAdminApiResponse =
  /** status 202 Accepted */ SponsorCategoryBase;
export type UpdateSponsorInfoCategoryAsAdminApiArg = {
  /** 企業カテゴリー ID（Sponsor::Category ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putSponsorCategoryRequestBody: PutSponsorCategoryRequestBody;
};
export type DeleteSponsorInfoCategoryAsAdminApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteSponsorInfoCategoryAsAdminApiArg = {
  /** 企業カテゴリー ID（Sponsor::Category ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorProjectsAsAdminApiResponse = /** status 200 OK */ {
  projects: SponsorProjectAndTagsBase[];
  total_count: number;
};
export type FetchSponsorProjectsAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  companyId?: string;
  sponsorId?: string;
  searchType?: SponsorProjectsSearchType;
  searchWord?: string;
  /** 大学先生に紐づいているプロジェクトを呼び出す */
  professorAffiliateId?: string;
};
export type CreateSponsorProjectAsAdminApiResponse =
  /** status 201 OK */ SponsorProjectDetailsBase;
export type CreateSponsorProjectAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postSponsorProjectRequestBody: PostSponsorProjectRequestBody;
};
export type FetchSponsorProjectAsAdminApiResponse =
  /** status 200 OK */ SponsorProjectDetailsBase;
export type FetchSponsorProjectAsAdminApiArg = {
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type UpdateSponsorProjectAsAdminApiResponse =
  /** status 202 Accepted */ SponsorProjectDetailsBase;
export type UpdateSponsorProjectAsAdminApiArg = {
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putSponsorProjectRequestBody: PutSponsorProjectRequestBody;
};
export type DeleteSponsorProjectAsAdminApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteSponsorProjectAsAdminApiArg = {
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type SortSponsorProjectAsAdminApiResponse = /** status 200 200 OK */
  | {
      message?: string;
    }
  | /** status 202 202 Accepted */ {
      message?: string;
    };
export type SortSponsorProjectAsAdminApiArg = {
  sponsorProjectSequencesRequestBody: SponsorProjectSequencesRequestBody;
};
export type FetchSponsorProjectsForCarouselAsAdminApiResponse =
  /** status 200 OK */ {
    projects: SponsorProjectAndTagsBase[];
    total_count: number;
  };
export type FetchSponsorProjectsForCarouselAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  numberOfProjects?: number;
  sortType?: SponsorProjectsSortType;
};
export type FetchSponsorProjectListAsAdminApiResponse = /** status 200 OK */ {
  projects: HasIdAndName[];
  total_count: number;
};
export type FetchSponsorProjectListAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  professorAffiliateId: string;
};
export type ToggleDisplayOfProjectOnCarouselAsAdminApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type ToggleDisplayOfProjectOnCarouselAsAdminApiArg = {
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type CreateSponsorProjectCategoryAsAdminApiResponse =
  /** status 201 Accepted */ SponsorProjectCategoryBase;
export type CreateSponsorProjectCategoryAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postSponsorProjectCategoryRequestBody: PostSponsorProjectCategoryRequestBody;
};
export type GetAdminSponsorProjectCategoriesIdApiResponse =
  /** status 200 OK */ SponsorProjectCategoryBase;
export type GetAdminSponsorProjectCategoriesIdApiArg = {
  /** 企業プロジェクトカテゴリーID（Pbl::Sponsor::ProjectCategory ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type UpdateSponsorProjectCategoryAsAdminApiResponse =
  /** status 202 Accepted */ SponsorProjectCategoryBase;
export type UpdateSponsorProjectCategoryAsAdminApiArg = {
  /** 企業プロジェクトカテゴリーID（Pbl::Sponsor::ProjectCategory ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putSponsorCategoryRequestBody: PutSponsorCategoryRequestBody;
};
export type DeleteSponsorProjectCategoryAsAdminApiResponse =
  /** status 200 OK */ {
    message?: string;
  };
export type DeleteSponsorProjectCategoryAsAdminApiArg = {
  /** 企業プロジェクトカテゴリーID（Pbl::Sponsor::ProjectCategory ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorLecturesAsAdminApiResponse = /** status 200 OK */ {
  project_lectures: SponsorLectureDetailsBase[];
  total_count: number;
};
export type FetchSponsorLecturesAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  projectId: string;
};
export type CreateSponsorLectureAsAdminApiResponse =
  /** status 201 OK */ SponsorLectureDetailsBase;
export type CreateSponsorLectureAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  postSponsorLectureRequestBody: PostSponsorLectureRequestBody;
};
export type FetchSponsorLectureAsAdminApiResponse =
  /** status 200 OK */ SponsorLectureDetailsBase;
export type FetchSponsorLectureAsAdminApiArg = {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type UpdateSponsorLectureAsAdminApiResponse =
  /** status 202 OK */ SponsorLectureDetailsBase;
export type UpdateSponsorLectureAsAdminApiArg = {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putSponsorLectureRequestBody: PutSponsorLectureRequestBody;
};
export type DeleteSponsorLectureAsAdminApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteSponsorLectureAsAdminApiArg = {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutSponsorLectureSequenceAsAdminApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type PutSponsorLectureSequenceAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  projectId: string;
  sponsorLectureSequencesRequestBody: SponsorLectureSequencesRequestBody;
};
export type CreateSponsorLectureFileAsAdminApiResponse =
  /** status 201 Accepted */ SponsorLectureFileBase;
export type CreateSponsorLectureFileAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  sponsorLectureFileRequestBody: SponsorLectureFileRequestBody;
};
export type FetchSponsorLectureFileAsAdminApiResponse =
  /** status 200 OK */ SponsorLectureFileBase;
export type FetchSponsorLectureFileAsAdminApiArg = {
  /** 企業プロジェクトのレクチャーファイルID（Pbl::Sponsor::LectureFile ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type UpdateSponsorLectureFileAsAdminApiResponse =
  /** status 202 Accepted */ SponsorLectureFileBase;
export type UpdateSponsorLectureFileAsAdminApiArg = {
  /** 企業プロジェクトのレクチャーファイルID（Pbl::Sponsor::LectureFile ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteSponsorLectureFileAsAdminApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteSponsorLectureFileAsAdminApiArg = {
  /** 企業プロジェクトのレクチャーファイルID（Pbl::Sponsor::LectureFile ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostSponsorArticleContentTemplateAsAdminApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostSponsorArticleContentTemplateAsAdminApiArg = {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsorLectureId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  postSponsorArticleContentTemplateRequestBody: PostSponsorArticleContentTemplateRequestBody;
};
export type GetSponsorArticleContentTemplateAsAdminApiResponse =
  /** status 200 OK */ SponsorArticleContentTemplateBase;
export type GetSponsorArticleContentTemplateAsAdminApiArg = {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsorLectureId: string;
  /** Pbl::Sponsor::ArticleContentTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutSponsorArticleContentTemplateAsAdminApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutSponsorArticleContentTemplateAsAdminApiArg = {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsorLectureId: string;
  /** Pbl::Sponsor::ArticleContentTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putSponsorArticleContentTemplateFieldAsAdminRequestBody: PutSponsorArticleContentTemplateFieldAsAdminRequestBody;
};
export type DeleteSponsorArticleContentTemplateAsAdminApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteSponsorArticleContentTemplateAsAdminApiArg = {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsorLectureId: string;
  /** Pbl::Sponsor::ArticleContentTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostSponsorArticleContentTemplateFieldAsAdminApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostSponsorArticleContentTemplateFieldAsAdminApiArg = {
  /** ポスターテンプレートID(Pbl::Sponsor::ArticleContentTemplate ID) */
  articleContentTemplateId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putSponsorArticleContentTemplateFieldAsAdminRequestBody: PutSponsorArticleContentTemplateFieldAsAdminRequestBody;
};
export type PutSponsorArticleContentTemplateFieldAsAdminApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutSponsorArticleContentTemplateFieldAsAdminApiArg = {
  /** ポスターテンプレートID(Pbl::Sponsor::ArticleContentTemplate ID) */
  articleContentTemplateId: string;
  /** Pbl::Sponsor::ArticleContentTemplateFieldField ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  putSponsorArticleContentTemplateFieldAsAdminRequestBody: PutSponsorArticleContentTemplateFieldAsAdminRequestBody;
};
export type DeleteSponsorArticleContentTemplateFieldAsAdminApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteSponsorArticleContentTemplateFieldAsAdminApiArg = {
  /** ポスターテンプレートID(Pbl::Sponsor::ArticleContentTemplate ID) */
  articleContentTemplateId: string;
  /** Pbl::Sponsor::ArticleContentTemplateFieldField ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetFeedbackTemplatesAsAdminApiResponse = /** status 200 OK */ {
  feedback_templates: FeedbackTemplateBase[];
  total_count: number;
};
export type GetFeedbackTemplatesAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostFeedbackTemplateAsAdminApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostFeedbackTemplateAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  feedbackTemplateRequestBodyAsAdmin: CompanyRequestBodyAsAdmin2;
};
export type GetFeedbackTemplateAsAdminApiResponse =
  /** status 200 OK */ FeedbackTemplateBase;
export type GetFeedbackTemplateAsAdminApiArg = {
  /** FeedbackTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutFeedbackTemplateAsAdminApiResponse = /** status 202 Accepted */ {
  message?: string;
};
export type PutFeedbackTemplateAsAdminApiArg = {
  /** FeedbackTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  iconRequiredRequestBody: IconRequiredRequestBody;
};
export type DeleteFeedbackTemplateAsAdminApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type DeleteFeedbackTemplateAsAdminApiArg = {
  /** FeedbackTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostFeedbackTemplateCommentAsAdminApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostFeedbackTemplateCommentAsAdminApiArg = {
  /** FeedbackTemplate ID */
  feedbackTemplateId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  bodyRequiredRequestBody: BodyRequiredRequestBody;
};
export type PutFeedbackTemplateCommentAsAdminApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type PutFeedbackTemplateCommentAsAdminApiArg = {
  /** FeedbackTemplate ID */
  feedbackTemplateId: string;
  /** FeedbackTemplateComment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  bodyRequestBody: BodyRequestBody;
};
export type DeleteFeedbackTemplateCommentAsAdminApiResponse =
  /** status 202 Accepted */ {
    message?: string;
  };
export type DeleteFeedbackTemplateCommentAsAdminApiArg = {
  /** FeedbackTemplate ID */
  feedbackTemplateId: string;
  /** FeedbackTemplateComment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSponsorLinksAsAdminApiResponse = /** status 200 OK */ {
  sponsor_links: SponsorLinkBase[];
  total_count: number;
};
export type GetSponsorLinksAsAdminApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  infoId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostSponsorLinksAsAdminApiResponse =
  /** status 201 OK */ SponsorLinkBase;
export type PostSponsorLinksAsAdminApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  infoId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  sponsorInfoSchoolIdRequestBody: PutSponsorInfoRequestBody2;
};
export type DeleteSponsorLinkAsAdminApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteSponsorLinkAsAdminApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  infoId: string;
  /** - Sponsor Link ID （Pbl::Sponsor::Link ID）
    - getSponsorLinksAsAdminから取得できるID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type AdminSponsorInfosSponsorLinkingBulkInsertApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type AdminSponsorInfosSponsorLinkingBulkInsertApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  sponsorInfoLinkingBulkInsertParams: SponsorInfoLinkingBulkInsertParams;
};
export type PostAttachedImageAsAdminApiResponse =
  /** status 201 Created */ AttachedImageBase;
export type PostAttachedImageAsAdminApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetAttachedImageAsAdminApiResponse =
  /** status 200 OK */ AttachedImageBase;
export type GetAttachedImageAsAdminApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PutAttachedImageAsAdminApiResponse =
  /** status 202 Accepted */ AttachedImageBase;
export type PutAttachedImageAsAdminApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteAttachedImageAsAdminApiResponse = /** status 200 Accepted */ {
  message?: string;
};
export type DeleteAttachedImageAsAdminApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PostOptionsExpansionAsAdminApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostOptionsExpansionAsAdminApiArg = {
  companyId: string;
  body: {
    type: "material_expansion" | "ideal_expansion";
    capacity: OptionsExpansionCapacity;
  };
};
export type PutOptionsExpansionAsAdminApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutOptionsExpansionAsAdminApiArg = {
  companyId: string;
  /** Options::Expansion ID */
  id: string;
  body: {
    capacity: OptionsExpansionCapacity;
  };
};
export type GetUniversityCooperationsAsAdminApiResponse = /** status 200 OK */ {
  university_cooperations: UniversityCooperationBase[];
  total_count: number;
};
export type GetUniversityCooperationsAsAdminApiArg = {
  /** Company ID */
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostUniversityCooperationAsAdminApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostUniversityCooperationAsAdminApiArg = {
  /** Company ID */
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  targetCompanyIdsRequestBody: TargetCompanyIdsRequestBody;
};
export type DeleteUniversityCooperationAsAdminApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteUniversityCooperationAsAdminApiArg = {
  /** Company ID */
  companyId: string;
  /** UniversityCooperation ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTeacherUniversitiesAsAdminApiResponse = /** status 200 OK */ {
  university_teachers: TeacherListForUniversityTeacher[];
  total_count: number;
};
export type GetTeacherUniversitiesAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  searchType?: TeacherSearchType;
  /** 検索単語、search_typeとsearch_wordは同時に指定する必要有り */
  searchWord?: string;
  /** 企業プロジェクトでフィルタリングする */
  pblSponsorProjectId?: string;
};
export type GetTtCsvExportsAsAdminApiResponse = /** status 200 OK */ {
  tt_csv_exports: TtCsvExportBase[];
  total_count: number;
};
export type GetTtCsvExportsAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  types?: TtCsvExportType[];
};
export type PostTtCsvExportAsAdminApiResponse =
  /** status 200 OK */ TtCsvExportBase;
export type PostTtCsvExportAsAdminApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  sortedBy?: string;
  searchKey?: string;
  searchValue?: string;
  ttCsvExportRequestBody: TtCsvExportRequestBody;
};
export type GetGogoLinkingsAsAdminApiResponse = /** status 200 OK */ {
  gogo_linkings: GogoLinkingBase[];
  total_count: number;
};
export type GetGogoLinkingsAsAdminApiArg = {
  /** Company ID */
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostGogoLinkingAsAdminApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostGogoLinkingAsAdminApiArg = {
  /** Company ID */
  companyId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  gogoLinkingRequiredRequestBody: GogoLinkingRequiredRequestBody;
};
export type DeleteGogoLinkingAsAdminApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteGogoLinkingAsAdminApiArg = {
  /** Company ID */
  companyId: string;
  /** Gogo::Linking ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type ErrorsWithKeyMessage = {
  errors: {
    key?: string;
    message?: string;
  }[];
};
export type ModelBase = {
  id: string;
};
export type HasIdAndName = {
  id: string;
  name: string;
};
export type DeletionProgress = "running" | "failed" | "idle";
export type AdminCompanyList = ModelBase & {
  id: string;
  name: string;
  is_admin_company?: boolean;
  is_super_company?: boolean;
  is_child_company?: boolean;
  is_reviewer_company?: boolean;
  is_content_company?: boolean;
  is_reviewee_company?: boolean;
  parent_company?: HasIdAndName;
  deletion_progress?: DeletionProgress | null;
  has_university: boolean;
};
export type CompanyTypeFilter =
  | "any"
  | "admin"
  | "reviewer"
  | "content"
  | "school"
  | "pbl_board_feature";
export type Option = {
  use_video?: boolean;
  custom_student_ui?: boolean;
  homework_feature?: boolean;
  blog_feature?: boolean;
  text_seller?: boolean;
  use_image?: boolean;
  material?: boolean;
  teacher_material?: boolean;
  report?: boolean;
  payment?: boolean;
  calender?: boolean;
  review_feature?: boolean;
  reviewer_company?: boolean;
  tag?: boolean;
  google_classroom?: boolean;
  steam_library?: boolean;
  content_company?: boolean;
  content_company_review_feature?: boolean;
  dashboard_feature?: boolean;
  study_log_feature?: boolean;
  ideal_feature?: boolean;
  pbl_board_feature?: boolean;
  exam_feature?: boolean;
  rubric_feature?: boolean;
  word_cloud_feature?: boolean;
  rubric_evaluate_feature?: boolean;
  google_drive_feature?: boolean;
  one_drive_feature?: boolean;
  university_cooperation_feature?: boolean;
  article_student_comment_feature?: boolean;
  homework_automatic_scoring_feature?: boolean;
  class_nav_feature?: boolean;
  use_account_lock_mail?: boolean;
  gogo_feature?: boolean;
  pbl_projects_feature?: boolean;
  use_article_and_homework_mail?: boolean;
};
export type CompanyRequestBody = {
  name?: string;
  postcode?: string;
  prefecture?: string;
  adress1?: string;
  adress2?: string;
  phone?: string;
  logo?: string;
  options?: Option;
  email?: string;
};
export type CompanyRequestBodyAsAdmin = CompanyRequestBody & {
  is_reviewer?: boolean;
  parent_company_id?: string;
  university?: boolean;
};
export type CompanyBase = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
};
export type OptionsExpansionCapacity =
  | "unlimited"
  | "capacity_1"
  | "capacity_2"
  | "capacity_3"
  | "capacity_4"
  | "capacity_5";
export type MaterialExpansionBase = ModelBase & {
  capacity?: OptionsExpansionCapacity;
};
export type IdealExpansionBase = ModelBase & {
  capacity?: OptionsExpansionCapacity;
};
export type OptionExpansionBase = {
  material_expansion?: MaterialExpansionBase;
  ideal_expansion?: IdealExpansionBase;
};
export type SponsorSalesChannel = "direct_selling" | "selling_agency";
export type AdminCompany = CompanyBase & {
  created_at: string;
  updated_at: string;
  ui_color_code: string;
  ui_second_color_code: string;
  number_of_students: number;
  number_of_teachers: number;
  number_of_owners: number;
  number_of_super_owners: number;
  options: Option;
  options_editable: Option;
  options_expansions?: OptionExpansionBase;
  is_admin_company?: boolean;
  is_reviewer?: boolean;
  is_content_company?: boolean;
  is_reviewee_company?: boolean;
  is_super_company?: boolean;
  is_child_company?: boolean;
  child_companies?: {
    id?: string;
    name?: string;
  }[];
  parent_company?: {
    id?: string;
    name?: string;
  };
  reviewer_companies?: {
    id?: string;
    name?: string;
  }[];
  reviewee_companies?: {
    id?: string;
    name?: string;
  }[];
  has_super_owners?: boolean;
  parent_company_id?: string;
  is_pbl_board_published: boolean | null;
  deletion_progress?: DeletionProgress | null;
  has_university: boolean;
  sponsors?: (ModelBase & {
    company_id: string;
    name: string;
    description: string;
    is_public: boolean;
    sales_channel: SponsorSalesChannel | null;
    project_count: number;
  })[];
};
export type BasicUserInfo = ModelBase & {
  first_name: string;
  last_name: string;
  email: string;
  optional_email?: string | null;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  expired_date?: string | null;
  current_sign_in_at?: string | null;
  last_sign_in_at?: string | null;
  linked_project_count?: number | null;
  affiliate_id?: string | null;
  nickname: string | null;
};
export type ReviewerCompanyReviewingRequiredRequestBody = {
  reviewer_company_id: string;
  reviewee_company_id: string;
};
export type GradeMasterBaseAsAdmin = {
  grades?: {
    id?: string;
    grade?: string;
    grade_name?: string;
    graduation_year?: string;
    company_id?: string;
  }[];
};
export type GraduationYearRequiredRequestBody = {
  graduation_year: string;
};
export type ReviewerCompanyGroupBase = ModelBase & {
  name: string;
  is_main_reviewer_group: boolean;
  super_company: ModelBase & {
    name?: string;
  };
  is_public_profile?: boolean;
};
export type ReviewerCompanyGroupDetail = ReviewerCompanyGroupBase & {
  reviewer_companies: (ModelBase & {
    name?: string;
  })[];
  reviewee_companies: (ModelBase & {
    name?: string;
  })[];
};
export type ReviewerCompanyReviewingRequestBodyAsAdmin = {
  name: string;
  is_public_profile?: boolean;
};
export type Role =
  | "admin"
  | "super_owner"
  | "owner"
  | "teacher"
  | "student"
  | "reviewer"
  | "content_provider";
export type RoleJa =
  | "SV\u7BA1\u7406\u8005"
  | "\u89AA\u7BA1\u7406\u8005"
  | "\u7BA1\u7406\u8005"
  | "\u6559\u8077\u54E1"
  | "\u751F\u5F92"
  | "\u6DFB\u524A\u5229\u7528\u8005"
  | "\u30B3\u30F3\u30C6\u30F3\u30C4\u4E8B\u696D\u8005";
export type RoleBase = ModelBase & {
  name: Role;
  jp_name: RoleJa;
};
export type IpWhitelistBase = ModelBase & {
  ip_address: string;
  role: RoleBase;
  company: ModelBase & {
    name: string;
  };
};
export type PutIpWhitelistsRequestBody = {
  ip_address: string;
  role: string;
};
export type PostIpWhitelistsRequestBody = PutIpWhitelistsRequestBody & {
  company_id: string;
};
export type AffiliateItem = {
  company_id?: string;
  company_name?: string;
  roles?: {
    id?: string;
    role?: string;
    affiliate_id?: string;
    only_pbl_projects_feature?: string;
    student_suspension?: string;
    status?: string;
  }[];
};
export type AffiliateListBase = {
  affiliates: AffiliateItem[];
};
export type AdminRegistrationRequestBody = {
  email: string;
  first_name: string;
  last_name: string;
};
export type UserRegistrationRequestBodyAsAdmin = {
  email: string;
  company_id: string;
  first_name: string;
  last_name: string;
};
export type Avatar = {
  url: string | null;
};
export type AccountUserInfo = {
  user_id: string;
  full_name: string;
  email: string;
  new_password: string;
  group_affiliate_packages?:
    | {
        title?: string;
      }[]
    | null;
};
export type AuthorizationPasswordRequestBody = {
  password: string;
  affiliate_id?: string;
};
export type NotificationBase = ModelBase & {
  title: string;
  message: string;
  resource_info: string | null;
  sender?: {
    id?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
  };
  created_at: string;
  updated_at: string;
  notified_time: string;
};
export type UserTagBasic = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_linking_users?: number;
  kind?: string;
};
export type NotificationStatus =
  | "reserved"
  | "waiting"
  | "delivering"
  | "delivered";
export type Notifications = {
  notifications?: (NotificationBase & {
    user_tags?: UserTagBasic[];
    company_tags?: {
      id?: string;
      name?: string;
    }[];
    roles?: {
      id?: string;
      name?: string;
      jp_name?: string;
    }[];
    reservation_time?: string;
    number_of_reserve_deliveries?: number | null;
    status?: NotificationStatus | null;
    companies?: {
      id?: string;
      name?: string;
    }[];
  })[];
  total_count?: number;
};
export type NotificationRequestBody = {
  title?: string;
  message?: string;
};
export type NotificationRequestBodyAsAdmin = NotificationRequestBody & {
  target_company_ids: string[];
  target_roles: string[];
};
export type Items = ModelBase & {
  category?: string;
  name?: string;
  stock?: number;
  purchasing?: number;
  description?: string;
  content?: string;
  price?: number;
};
export type SteamLibraryContentDifficulty = "easy" | "normal" | "difficult";
export type SteamLibraryContentAspect = "familiar" | "japan" | "world";
export type PblSteamContentBase = ModelBase & {
  sl_content_id: number;
  title: string;
  difficulty: SteamLibraryContentDifficulty;
  aspect: SteamLibraryContentAspect;
  is_scientific: boolean;
  has_carousel: boolean;
  created_at: string;
  updated_at: string;
};
export type PblSteamContentRequestBody = {
  difficulty?: string;
  title: string;
  is_scientific?: boolean;
  sl_content_id: number;
  aspect?: string;
  has_carousel?: boolean;
};
export type CategoryRenameMapRequestBody = {
  for_text?: string;
  for_subject?: string;
  for_grade?: string;
  for_major?: string;
  for_middle?: string;
  for_minor?: string;
};
export type CategoryRenameMapRequestBodyAsAdmin =
  CategoryRenameMapRequestBody & {
    company_id: string;
  };
export type CategoryRenameMap = {
  id?: string | null;
  for_text: string | null;
  for_subject: string | null;
  for_grade: string | null;
  for_major: string | null;
  for_middle: string | null;
  for_minor: string | null;
} | null;
export type CompanyIdsRequestBody = {
  company_ids: string[];
};
export type SponsorImageBase = {
  id: string;
  company_id: string;
  url: string | null;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type VideoFileBase = {
  url: string | null;
};
export type CookieInfo = {
  cloudfront_policy: string;
  cloudfront_signature: string;
  cloudfront_key_pair_id: string;
  unsigned_url: string;
};
export type SponsorVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorInfoBase = ModelBase & {
  company_id: string;
  logo_url: string | null;
  name: string;
  description?: string;
  is_public: boolean;
  sales_channel?: SponsorSalesChannel | null;
  created_at: string;
  updated_at: string;
  project_count: number;
  image: SponsorImageBase | null;
  video_content?: SponsorVideoContentBase | null;
  tags: SponsorTagBase[] | null;
};
export type SponsorLinkMinBase = ModelBase & {
  sponsor_id: string;
  school_id: string;
};
export type SponsorInfoListForAdmin = SponsorInfoBase & {
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  affiliates_count?: number;
  linkings?: SponsorLinkMinBase[];
};
export type SponsorInfosSearchType = "name" | "description" | "tag";
export type SponsorCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: {
    url?: string | null;
  };
};
export type SponsorProjectTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorLectureVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorProjectImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorProjectWorkProgressJa =
  | "\u53D7\u8B1B\u4E2D"
  | "\u53D7\u8B1B\u6E08\u307F";
export type SponsorProjectAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description?: string;
  display_allowed: boolean;
  display_in_carousel: boolean;
  created_at: string;
  updated_at: string;
  tags: SponsorProjectTagBase[] | null;
  file?: {
    url?: string | null;
  };
  video_content?: SponsorLectureVideoContentBase | null;
  image?: SponsorProjectImageBase | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
  project_bookmark?: ModelBase | null;
  project_recommend?: ModelBase | null;
};
export type SponsorInfoDetailsBase = SponsorInfoBase & {
  sponsor_logo_url: string | null;
  categories: SponsorCategoryBase[] | null;
  projects: SponsorProjectAndTagsBase[] | null;
};
export type PutSponsorInfoRequestBody = {
  company_id: string | number;
  description?: string;
  is_public: string;
  sales_channel?: SponsorSalesChannel;
  tags?: object[];
  image_contents?: Blob;
  video_file?: Blob;
  external_link?: string;
  sponsor_logo?: Blob;
};
export type PostSponsorInfoRequestBody = PutSponsorInfoRequestBody & {
  categories?: {
    name: string;
    description?: string;
    file?: Blob;
  }[];
};
export type PutSponsorCategoryRequestBody = {
  name: string;
  description?: string;
  file?: Blob;
};
export type PostSponsorCategoryRequestBody = PutSponsorCategoryRequestBody & {
  sponsor_id: string;
};
export type SponsorProjectsSearchType = "name" | "description" | "tag";
export type SponsorProjectVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorProjectCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: {
    url?: string | null;
  };
};
export type SponsorLectureImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorLectureTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorLectureAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content?: SponsorLectureVideoContentBase | null;
  tags?: SponsorLectureTagBase[] | null;
};
export type SponsorProjectDetailsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description: string;
  display_allowed?: boolean;
  file?: {
    url?: string | null;
  };
  created_at: string;
  updated_at: string;
  image?: SponsorProjectImageBase | null;
  video_content?: SponsorProjectVideoContentBase | null;
  tags: SponsorProjectTagBase[] | null;
  categories: SponsorProjectCategoryBase[] | null;
  lectures: SponsorLectureAndTagsBase[] | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
  lecture_viewed_count: number;
  professor_affiliate_ids?: string[] | null;
  is_recommend: boolean;
  company_name?: string;
  sponsor_logo_url?: string | null;
};
export type SponsorProjectRequestBody = {
  company_id: string;
  name: string;
  description?: string;
  file?: Blob;
  tags?: string[];
  image_contents?: Blob;
  video_file?: Blob;
  external_link?: string;
  display_allowed: boolean;
};
export type PostSponsorProjectRequestBody = SponsorProjectRequestBody & {
  categories?: {
    name: string;
    description?: string;
    file?: Blob;
  }[];
};
export type PutSponsorProjectRequestBody = SponsorProjectRequestBody & {
  add_professor_affiliate_ids?: string[];
  remove_professor_affiliate_ids?: string[];
};
export type SponsorProjectSequencesRequestBody = {
  sequences: {
    pbl_sponsor_project_id: string;
    sequence: number;
  }[];
};
export type SponsorProjectsSortType = "sequence" | "recent" | "random";
export type PostSponsorProjectCategoryRequestBody =
  PutSponsorCategoryRequestBody & {
    project_id: string;
  };
export type SponsorLectureFileBase = ModelBase & {
  company_id: string;
  file: {
    title: string | null;
    url: string | null;
  };
};
export type Status = "\u65B0\u898F" | "\u78BA\u8A8D\u6E08\u307F";
export type SponsorLectureDetailsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content: SponsorLectureVideoContentBase | null;
  tags: SponsorLectureTagBase[] | null;
  files: SponsorLectureFileBase[] | null;
  learning_progress?: {
    id?: string;
    status?: Status;
  };
  article_content_template?: {
    id: string;
    title: string;
    description?: string | null;
  } | null;
  company_name?: string;
  sponsor_logo_url?: string | null;
  project_name?: string;
};
export type SponsorLectureRequestBody = {
  company_id: string;
  project_id: string;
  name: string;
  description?: string;
  tags?: string[];
  image_contents?: Blob;
  video_file?: Blob;
  external_link?: string;
};
export type PostSponsorLectureRequestBody = SponsorLectureRequestBody & {
  files?: Blob[];
};
export type PutSponsorLectureRequestBody = SponsorLectureRequestBody & object;
export type SponsorLectureSequencesRequestBody = {
  sequences: {
    pbl_sponsor_lecture_id: string;
    sequence: number;
  }[];
};
export type FileRequiredRequestBody = {
  file: Blob;
};
export type SponsorLectureFileRequestBody = FileRequiredRequestBody & {
  lecture_id: string;
};
export type PostSponsorArticleContentTemplateRequestBody = {
  title: string;
  description?: string | null;
  field_texts?: string[] | null;
};
export type SponsorArticleContentTemplateFieldBase = ModelBase & {
  title: string;
};
export type SponsorArticleContentTemplateBase = ModelBase & {
  title: string;
  description?: string | null;
  pbl_sponsor_lecture_id?: string;
  fields?: SponsorArticleContentTemplateFieldBase[];
};
export type PutSponsorArticleContentTemplateFieldAsAdminRequestBody = {
  title: string;
  description?: string | null;
};
export type FeedbackTemplateCommentBase = ModelBase & {
  body: string;
};
export type FeedbackTemplateBase = ModelBase & {
  icon: {
    title: string;
    url: string;
  };
  feedback_template_comments: FeedbackTemplateCommentBase[];
};
export type IconRequiredRequestBody = {
  icon: Blob;
};
export type CompanyRequestBodyAsAdmin2 = IconRequiredRequestBody & {
  comments: {
    body: string;
  }[];
};
export type BodyRequiredRequestBody = {
  body: string;
};
export type BodyRequestBody = {
  body?: string;
};
export type SponsorLinkBase = ModelBase & {
  company: {
    id: string;
    name: string;
    prefecture: string;
    address1: string;
    address2: string;
    phone: string;
    owner_count: number;
    teacher_count: number;
    student_count: number;
  };
};
export type PutSponsorInfoRequestBody2 = {
  school_id: string;
};
export type SponsorInfoLinkingBulkInsertParams = {
  sponsor_info_ids: string[];
  school_ids: string[];
};
export type AttachedImageCategorizableType =
  | "Material"
  | "Ideal::Board"
  | "Article"
  | "Pbl::Board::Content"
  | "Pbl::Sponsor::Lecture"
  | "Ideal::BoardTemplate"
  | "Interview::Message"
  | "HomeworkTemplateCustomFieldFeedback"
  | "Gogo::Message";
export type AttachedImageBase = ModelBase & {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
  file_name: string;
  file_url: string;
};
export type UniversityBase = ModelBase & {
  company: HasIdAndName;
};
export type CompanyUniversityCooperationBase = ModelBase & {
  name: string;
  postcode: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
  affiliates_count: number;
};
export type UniversityCooperationBase = ModelBase & {
  university: UniversityBase;
  company: CompanyUniversityCooperationBase;
};
export type TargetCompanyIdsRequestBody = {
  target_company_ids: string[];
};
export type AffiliateName = {
  affiliate_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  nickname: string | null;
};
export type ProfessorInfoBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    request_count?: number;
    position?: string | null;
    department?: string | null;
    description?: string | null;
    limit_request?: number | null;
    profile_picture?: string | null;
  };
};
export type TeacherListForUniversityTeacher = {
  professor_info: ProfessorInfoBase;
};
export type TeacherSearchType = "name" | "company";
export type TtCsvExportStatus = "running" | "completed" | "failed";
export type TtCsvExportTypeJa =
  | "\u4F01\u696D\u30D7\u30ED\u30B8\u30A7\u30AF\u30C8\u60C5\u5831"
  | "\u5B66\u6821\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u5B66\u6821\u30BF\u30B0\u306E\u5B66\u6821\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u6559\u6750\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u4E00\u89A7\u60C5\u5831"
  | "\u30D7\u30ED\u30B0\u30E9\u30E0\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u914D\u4FE1\u5C65\u6B74\u8A73\u7D30\u60C5\u5831"
  | "\u30EC\u30B3\u30FC\u30C9\u60C5\u5831"
  | "\u9650\u5B9A\u30BF\u30B0\u914D\u4FE1\u5C65\u6B74\u60C5\u5831"
  | "\u7BA1\u7406\u8005\u60C5\u5831"
  | "\u6559\u8077\u54E1\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u30BF\u30B0\u60C5\u5831"
  | "\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u9650\u5B9A\u30E6\u30FC\u30B6\u30BF\u30B0\u306E\u751F\u5F92\u60C5\u5831"
  | "\u5927\u5B66\u6559\u54E1\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u751F\u5F92\u60C5\u5831"
  | "\u6388\u696D\u8A08\u753B"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u56DE\u7B54\u60C5\u5831"
  | "\u63D0\u51FA\u7269\u30B3\u30E1\u30F3\u30C8\u60C5\u5831";
export type TtCsvExportBase = ModelBase & {
  status: TtCsvExportStatus;
  type_ja: TtCsvExportTypeJa;
  created_at: string;
  completed_at: string | null;
  file: {
    name?: string | null;
    url?: string | null;
  };
};
export type TtCsvExportType =
  | "admin_sponsor_info_list"
  | "super_owner_company_list"
  | "super_owner_owner_list"
  | "super_owner_teacher_list"
  | "super_owner_student_list"
  | "super_owner_tag_list"
  | "super_owner_company_tag_list"
  | "super_owner_nationwide_user_tag_and_student_list"
  | "super_owner_limited_user_tag_and_student_list"
  | "super_owner_material_distribution_history_list"
  | "super_owner_learning_package_distribution_history_list"
  | "super_owner_learning_package_distribution_history_detail"
  | "super_owner_homework_template_distribution_history_list"
  | "super_owner_homework_template_distribution_history_detail"
  | "super_owner_custom_list"
  | "super_owner_user_tag_distribution_history_list"
  | "owner_owner_list"
  | "owner_teacher_list"
  | "owner_student_list"
  | "owner_user_tag_list"
  | "owner_nationwide_user_tag_and_student_list"
  | "owner_limited_user_tag_and_student_list"
  | "owner_university_teacher_list"
  | "owner_homework_comment_list"
  | "owner_homework_template_custom_field_list"
  | "teacher_homework_template_custom_field_list"
  | "teacher_student_list"
  | "teacher_pbl_plan_schedule_list"
  | "teacher_homework_comment_list"
  | "reviewer_homework_template_custom_field_list"
  | "reviewer_homework_comment_list";
export type TtCsvExportRequestBody = {
  type: TtCsvExportType;
  target_company_id?: string;
  homework_template_id?: string;
  learning_template_package_distribution_setting_id?: string;
  common_custom_master_map_id?: string;
};
export type CompanyLinkingListBase = ModelBase & {
  name: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
  owner_count: number;
  teacher_count: number;
  student_count: number;
};
export type GogoLinkingBase = ModelBase & {
  company: CompanyLinkingListBase;
};
export type GogoLinkingRequiredRequestBody = {
  school_ids: string[];
};
export const {
  useAffiliateUpdateAsAdminMutation,
  useFetchCompaniesAsAdminQuery,
  usePostAdminCompaniesMutation,
  useGetAdminCompaniesByCompanyIdQuery,
  usePutAdminCompaniesByCompanyIdMutation,
  useDeleteAdminCompaniesByCompanyIdMutation,
  useGetAdminCompaniesByCompanyIdOwnersQuery,
  usePostAdminReviewerCompanyReviewingsMutation,
  useGetAdminGradeMastersQuery,
  usePutAdminGradeMastersByIdMutation,
  useGetAdminCompaniesByCompanyIdMaterialCapacityQuery,
  useGetAdminCompaniesByCompanyIdTeachersQuery,
  useGetAdminCompaniesByCompanyIdStudentsQuery,
  useGetAdminCompaniesByCompanyIdSuperOwnersQuery,
  useGetContentProvidersAsAdminQuery,
  useGetReviewerCompanyGroupControllersAsAdminQuery,
  usePostReviewerCompanyGroupAsAdminMutation,
  useGetReviewerCompanyGroupAsAdminQuery,
  usePutReviewerCompanyGroupAsAdminMutation,
  useDeleteControllerAsAdminMutation,
  useGetCompaniesContentCompaniesAsAdminQuery,
  useGetIpWhitelistsAsAdminQuery,
  usePostIpWhitelistsAsAdminMutation,
  usePutIpWhitelistAsAdminMutation,
  useDeleteIpWhitelistAsAdminMutation,
  useGetAdminUsersByUserIdQuery,
  useDeleteAdminUsersByUserIdMutation,
  useDeleteAdminUsersByIdHardMutation,
  usePostAdminUsersAdminMutation,
  usePostAdminUsersSuperOwnerMutation,
  usePostAdminUsersReviewerMutation,
  usePostAdminUsersContentProviderMutation,
  usePostAdminUsersByIdAuthorizedSignInMutation,
  useGetSearchUserControllersAsAdminQuery,
  useBulkDeleteControllerAsAdminMutation,
  useResetPasswordAsAdminMutation,
  usePostAdminUsersMutation,
  useGetAdminNotificationsQuery,
  usePostAdminNotificationsMutation,
  useGetAdminNotificationsByIdQuery,
  usePutAdminNotificationsByIdMutation,
  useDeleteAdminNotificationsByIdMutation,
  useGetEblItemsAsAdminQuery,
  usePostEblItemAsAdminMutation,
  useGetEblItemAsAdminQuery,
  usePutEblItemAsAdminMutation,
  useDeleteEblItemAsAdminMutation,
  useGetAdminSteamContentsQuery,
  usePostAdminSteamContentsMutation,
  useGetAdminSteamContentsIdQuery,
  usePutAdminSteamContentsIdMutation,
  useDeleteAdminSteamContentsIdMutation,
  useFetchAdminSteamContentsMutation,
  useCreateCategoryRenameMapAsAdminMutation,
  useFetchCategoryRenameMapAsAdminQuery,
  useUpdateCategoryRenameMapAsAdminMutation,
  useFetchCategoryRenameMapByCompanyIdAsAdminQuery,
  useGetAdminContentCompanyReviewingsQuery,
  useGetAdminContentCompanyReviewingsReviewFeatureCompaniesQuery,
  usePatchAdminContentCompanyReviewingsBatchCreateMutation,
  usePatchAdminContentCompanyReviewingsBatchDeleteMutation,
  useGetAdminSponsorInfosQuery,
  useCreateSponsorInfoAsAdminMutation,
  useGetAdminSponsorInfosIdQuery,
  useUpdateSponsorInfoAsAdminMutation,
  useDeleteSponsorInfoAsAdminMutation,
  useFetchPblSponsorLinkableCompaniesAsAdminQuery,
  useCreateSponsorInfoCategoryAsAdminMutation,
  useFetchSponsorInfoCategoryAsAdminQuery,
  useUpdateSponsorInfoCategoryAsAdminMutation,
  useDeleteSponsorInfoCategoryAsAdminMutation,
  useFetchSponsorProjectsAsAdminQuery,
  useCreateSponsorProjectAsAdminMutation,
  useFetchSponsorProjectAsAdminQuery,
  useUpdateSponsorProjectAsAdminMutation,
  useDeleteSponsorProjectAsAdminMutation,
  useSortSponsorProjectAsAdminMutation,
  useFetchSponsorProjectsForCarouselAsAdminQuery,
  useFetchSponsorProjectListAsAdminQuery,
  useToggleDisplayOfProjectOnCarouselAsAdminMutation,
  useCreateSponsorProjectCategoryAsAdminMutation,
  useGetAdminSponsorProjectCategoriesIdQuery,
  useUpdateSponsorProjectCategoryAsAdminMutation,
  useDeleteSponsorProjectCategoryAsAdminMutation,
  useFetchSponsorLecturesAsAdminQuery,
  useCreateSponsorLectureAsAdminMutation,
  useFetchSponsorLectureAsAdminQuery,
  useUpdateSponsorLectureAsAdminMutation,
  useDeleteSponsorLectureAsAdminMutation,
  usePutSponsorLectureSequenceAsAdminMutation,
  useCreateSponsorLectureFileAsAdminMutation,
  useFetchSponsorLectureFileAsAdminQuery,
  useUpdateSponsorLectureFileAsAdminMutation,
  useDeleteSponsorLectureFileAsAdminMutation,
  usePostSponsorArticleContentTemplateAsAdminMutation,
  useGetSponsorArticleContentTemplateAsAdminQuery,
  usePutSponsorArticleContentTemplateAsAdminMutation,
  useDeleteSponsorArticleContentTemplateAsAdminMutation,
  usePostSponsorArticleContentTemplateFieldAsAdminMutation,
  usePutSponsorArticleContentTemplateFieldAsAdminMutation,
  useDeleteSponsorArticleContentTemplateFieldAsAdminMutation,
  useGetFeedbackTemplatesAsAdminQuery,
  usePostFeedbackTemplateAsAdminMutation,
  useGetFeedbackTemplateAsAdminQuery,
  usePutFeedbackTemplateAsAdminMutation,
  useDeleteFeedbackTemplateAsAdminMutation,
  usePostFeedbackTemplateCommentAsAdminMutation,
  usePutFeedbackTemplateCommentAsAdminMutation,
  useDeleteFeedbackTemplateCommentAsAdminMutation,
  useGetSponsorLinksAsAdminQuery,
  usePostSponsorLinksAsAdminMutation,
  useDeleteSponsorLinkAsAdminMutation,
  useAdminSponsorInfosSponsorLinkingBulkInsertMutation,
  usePostAttachedImageAsAdminMutation,
  useGetAttachedImageAsAdminQuery,
  usePutAttachedImageAsAdminMutation,
  useDeleteAttachedImageAsAdminMutation,
  usePostOptionsExpansionAsAdminMutation,
  usePutOptionsExpansionAsAdminMutation,
  useGetUniversityCooperationsAsAdminQuery,
  usePostUniversityCooperationAsAdminMutation,
  useDeleteUniversityCooperationAsAdminMutation,
  useGetTeacherUniversitiesAsAdminQuery,
  useGetTtCsvExportsAsAdminQuery,
  usePostTtCsvExportAsAdminMutation,
  useGetGogoLinkingsAsAdminQuery,
  usePostGogoLinkingAsAdminMutation,
  useDeleteGogoLinkingAsAdminMutation,
} = injectedRtkApi;
