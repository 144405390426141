import { Report } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  fetchOneReportAsOwner,
  fetchReportsAsOwner,
} from "../../actions/ownerApp/report";
import { RootState } from "../../store";

export interface OwnerAppReportState {
  fetching: boolean;
  error: string;
  reports: Report[];
  // eslint-disable-next-line camelcase
  report: Report & { message_to_owner: string; message_to_student: string };
  totalCount: number;
}

export const defaultReport: Report & {
  // eslint-disable-next-line camelcase
  message_to_owner: string;
  // eslint-disable-next-line camelcase
  message_to_student: string;
} = {
  teacher_name: "",
  title: "",
  detail: undefined,
  reply: undefined,
  status: undefined,
  created_at: undefined,
  student_name: undefined,
  next_goal: undefined,
  monthly_teaching_times: undefined,
  monthly_student_schedule_change: undefined,
  monthly_teacher_schedule_change: undefined,
  homework_frequency: undefined,
  is_schedule_change_required: undefined,
  schedule_detail_reason: undefined,
  test_misc: undefined,
  id: "",
  message_to_owner: "",
  message_to_student: "",
};

export const initialState: OwnerAppReportState = {
  fetching: false,
  error: "",
  reports: [],
  report: defaultReport,
  totalCount: 0,
};

const ownerAppReportSlice = createSlice({
  name: "OwnerApp/Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportsAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          reports: [],
        };
      })
      .addCase(fetchReportsAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: action.error.message as string,
          reports: [],
        };
      })
      .addCase(fetchReportsAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          reports: payload.reports,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchOneReportAsOwner.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          report: defaultReport,
        };
      })
      .addCase(fetchOneReportAsOwner.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: action.error.message as string,
          report: defaultReport,
        };
      })
      .addCase(fetchOneReportAsOwner.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          report: payload,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const ownerAppReportState = (state: RootState): OwnerAppReportState =>
  state.ownerApp.report;

export default ownerAppReportSlice.reducer;
