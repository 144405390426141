/* eslint-disable camelcase */
import {
  Api,
  FetchHomeworkTemplatesAsSuperOwnerParams,
  HomeworkTemplate,
} from "@lib/Api";
import { http } from "@lib/http";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { HomeworkTemplateImageParams } from "@root/constants/types";
import { RootState } from "@store/store";

const api = new Api();

export const fetchHomeworkTemplatesAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplate/fetch",
  async (
    params: FetchHomeworkTemplatesAsSuperOwnerParams,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await api.superOwner.fetchHomeworkTemplatesAsSuperOwner(params);
      return response.data as any;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchHomeworkTemplateAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplate/fetchOne",
  async (templateId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.fetchHomeworkTemplateAsSuperOwner(templateId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface CreateHomeworkTemplateAsOwnerParams {
  title: string;
  content: string | undefined;
  homework_images: any;
  needsReview: boolean | undefined;
  needsScore: boolean | undefined;
  isExam: boolean | undefined;
  reviewerCompany: string;
}

export const createHomeworkTemplateAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "SuperOwner/homeworkTemplate/create",
  async (data: CreateHomeworkTemplateAsOwnerParams, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.set("title", data.title);
      if (typeof data.content !== "undefined") {
        fd.set("content", data.content);
      }
      if (typeof data.needsScore !== "undefined") {
        fd.append("needs_score", JSON.stringify(data.needsScore));
      }
      if (typeof data.needsReview !== "undefined") {
        fd.append("needs_review", JSON.stringify(data.needsReview));
      }
      if (typeof data.isExam !== "undefined") {
        fd.append("is_exam", JSON.stringify(data.isExam));
      }
      if (typeof data.homework_images !== "undefined") {
        data.homework_images.forEach((fileData: any, index: number) => {
          fd.append(`homework_images[]`, fileData.file, fileData.title);
        });
      }
      if (typeof data.reviewerCompany !== "undefined") {
        fd.set("reviewer_company_group_id", data.reviewerCompany);
      }
      const response = await http.post(
        `${"/super_owner/homework_templates"}`,
        fd,
      );
      const resData = response.data as HomeworkTemplate;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface UpdateHomeworkTemplateAsSuperOwnerParams {
  title?: string;
  content?: string;
  needs_score?: boolean;
  reviewer_company_group_id?: string;
  is_exam?: boolean;
}

export interface UpdateHomeworkTemplateAsSuperOwnerArg {
  homework_template_id: string;
  params: UpdateHomeworkTemplateAsSuperOwnerParams;
}

export const updateHomeworkTemplateAsSuperOwner = createAsyncThunk<
  any,
  UpdateHomeworkTemplateAsSuperOwnerArg,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/homeworkTemplate/update", async (data, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.updateHomeworkTemplateAsSuperOwner(
      data.homework_template_id,
      data.params,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createHomeworkTemplateImageAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateImage/create",
  async (data: HomeworkTemplateImageParams, { rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.set("homework_template_id", data.homework_template_id);
      fd.set("file", data?.file as File);
      const response = await http.post(
        `/super_owner/homework_templates/${data.homework_template_id}/homework_template_images`,
        fd,
      );
      const resData = response.data as HomeworkTemplate;
      return resData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteHomeworkTemplateImageAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplateImage/delete",
  async (data: HomeworkTemplateImageParams, { rejectWithValue }) => {
    try {
      await api.superOwner.deleteHomeworkTemplateImageAsSuperOwner(
        data.homework_template_id,
        data.id as string,
      );
      return {
        id: data.homework_template_id,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteHomeworkTemplateAsSuperOwner = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplate/delete",
  async (templateId: string, { rejectWithValue }) => {
    try {
      await api.superOwner.deleteHomeworkTemplateAsSuperOwner(templateId);
      return {
        id: templateId,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const duplicateHomeworkTemplateAsSuperOwner = createAsyncThunk<
  { message?: string | undefined },
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/homeworkTemplate/duplicate",
  async (templateId: string, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.postHomeworkTemplateDuplicateAsSuperOwner(
          templateId,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetModalState = createAction(
  "superOwner/homeworkTemplate/resetModalState",
);

export const resetTemplateRelatedData = createAction(
  "superOwner/homeworkTemplate/resetOne",
);

export const resetDeleteActionState = createAction(
  "superOwner/homeworkTemplate/resetDeleteActionState",
);

export const successUpdateTemplateAsSuperOwner = createAction(
  "superOwner/homeworkTemplate/successUpdateTemplate",
);
