import { getSteamLibraryContentImages } from "@actions/steamLibrary";
import { SteamLibraryContentImage } from "@actions/types/steamLibrary";
import { Api, GetSteamContentRecommendationsAsStudentParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getSteamRecommendationAsStudent = createAsyncThunk<
  any,
  GetSteamContentRecommendationsAsStudentParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "student/steamLibraryRecommendation/fetch",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response =
        await api.student.getSteamContentRecommendationsAsStudent(params);
      const contentIds = response.data.steam_content_recommendations?.map(
        (content) => content.content.sl_content_id,
      );
      await dispatch(getSteamLibraryContentImages(contentIds as number[]));
      const contentsWithImages =
        response.data?.steam_content_recommendations?.map((content) => ({
          ...content.content,
          top_image: getState().steamLibrary.contentImages.find(
            (image: SteamLibraryContentImage) =>
              image.id === content.content.sl_content_id,
          ),
        }));
      return {
        ...response.data,
        SteamContentRecommendations: contentsWithImages,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export default getSteamRecommendationAsStudent;
