import { SelectedButton } from "@components/UIv2/molecules/V2Tabs";
import { useAuth } from "@contexts/Auth";
import { UserType } from "@lib/Api";
import { usePatchNewNotificationCountAsCommonMutation } from "@root/endpoints/TimeTactApi/remainingApi";
import { useState } from "react";

const useNotification = () => {
  const [buttonState, setButtonState] = useState(SelectedButton.Primary);

  const { currentUser } = useAuth();

  const [postNotifications, postNotificationsResult] =
    usePatchNewNotificationCountAsCommonMutation();

  const allowedToDisplayLimitedTimeNotification = currentUser?.current_role
    ? [UserType.Teacher, UserType.Student, UserType.Owner].includes(
        currentUser?.current_role as UserType,
      )
    : false;
  return {
    setButtonState,
    buttonState,
    allowedToDisplayLimitedTimeNotification,
    postNotifications,
    postNotificationsResult,
  };
};

export default useNotification;
