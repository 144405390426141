import { getLearningTemplatePackageDistributionHistoriesAsTeacher } from "@actions/teacherApp/learningPackageDistributionAction";
import { LearningTemplatePackageDistributionHistoryBase } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface LearningTemplatePackageDistributionAsOwnerState {
  fetch: string;
  fetchError: string | null;
  learningTemplatePackage: LearningTemplatePackageDistributionHistoryBase[];
  totalCount: number;
}

const initialState: LearningTemplatePackageDistributionAsOwnerState = {
  fetch: ReduxStatus.idle,
  fetchError: "",
  learningTemplatePackage: [],
  totalCount: 0,
};

const learningTemplatePackageDistributionSlice = createSlice({
  name: "Teacher/LearningTemplatePackageDistribution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getLearningTemplatePackageDistributionHistoriesAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetch: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getLearningTemplatePackageDistributionHistoriesAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetch: ReduxStatus.fulfilled,
            learningTemplatePackage:
              payload.learning_template_package_distribution_histories,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        getLearningTemplatePackageDistributionHistoriesAsTeacher.rejected,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetch: ReduxStatus.rejected,
            fetchError: payload.errors.message as string,
          };
        },
      );
  },
});

export const teacherAppLearningTemplatePackageDistributionState = (
  state: RootState,
): LearningTemplatePackageDistributionAsOwnerState =>
  state.teacherApp.learningTemplatePackageDistributions;

export default learningTemplatePackageDistributionSlice.reducer;
