// Owner
export const OWNER_ROUTES = {
  USERS: "/owner/users",
  UNIVERSITY_MESSAGE: "/owner/university/message",
  USERS_STUDENT: "/owner/users?type=student",
  USERS_TEACHER: "/owner/users?type=teacher",
  USERS_CSV: "/owner/users/csv_import",
  ARTICLES: "/owner/articles",
  HOMEWORK: "/owner/homework",
  HOMEWORK_TEMPLATES: "/owner/homework_templates",
  REPORTS: "/owner/reports",
  FRAMEWORK_REPORTS: "/owner/framework_reports",
  PAYMENTS: "/owner/payments",
  TAGS: "/owner/tags",
  MATERIALS: "/owner/materials",
  MATERIALS_HISTORY_LIST: "/owner/materials/history",
  MATERIAL_DISTRIBUTIONS: "/owner/distributions",
  MATERIAL_DETAILS: "/owner/material",
  EMAILS: "/owner/emails",
  NOTIFICATIONS: "/owner/notifications",
  COMPANIES: "/owner/companies",
  HOMEWORK_TEMPLATES_HOMEWORKS_MATCHER: /homework_templates\/.*\/homeworks/,
  HOMEWORK_TEMPLATES_DISTRIBUTIONS_MATCHER:
    /homework_templates\/.*\/distributions/,
  HOMEWORK_DISTRIBUTIONS_MATCHER:
    /homework_templates\/.*\/homework_distributions/,
  LEARNING_PACKAGES: "/owner/learning_packages",
  PBL_BOARD: "/owner/pbl/board",
  PBL_BOARD_PREVIEW: "/owner/pbl/board/setting",
  PBL_BOARD_CAMPAIGNS: "/owner/pbl/board/campaigns",
  PBL_BOARD_CAMPAIGNS_MATCHER: /owner\/pbl\/board\/campaigns/,
  PBL_BOARD_PREVIEW_MATCHER: /owner\/pbl\/board\/preview/,
  PROFESSOR_DETAIL: "/owner/professor_detail",
};

// Student
export const STUDENT_ROUTES = {
  ARTICLES: "/student/articles",
  HOMEWORK: "/student/homework",
  REPORTS: "/student/reports",
  PBL_MENU: "/student/pbl",
  STUDY_CONTENTS_MENU: "/student/study_contents",
  PBL_REPORTS: "/student/report_answers",
  PBL_BOOKMARKS: "/student/pbl_bookmarks",
  PBL_PROJECTS: "/student/pbl_projects/companies",
  PBL_PROJECTS_LECTURE: "/student/pbl_projects/lectures",
  MATERIALS: "/student/materials",
  MATERIAL_WORKS: "/student/material_works",
  PAYMENTS: "/student/payments",
  CALENDAR: "/student/calendar",
  CLASSROOM: "/student/classroom",
  STEAM: "/student/steam_library",
  STEAM_LIBRARY_LECTURE: "/student/steam_library/lecture",
  STEAM_LIBRARY_CONTENT: "/student/steam_library/content",
  DASHBOARD: "/student/dashboard",
  STUDENT_LOGS: "/student/student_logs",
  PBL_PROJECT_PROJECT_DETAIL: "/student/pbl_projects/projects",
  LEARNING_PACKAGES: "/student/learning_packages",
  IDEAL: "/student/ideal",
  CLASS_NAV: "/student/class_nav",
};

// Teacher
export const TEACHER_ROUTES = {
  DASHBOARD: "/teacher/dashboard",
  STUDENTS: "/teacher/students",
  USERS: "/teacher/users",
  STUDENTS_LOGS: "/teacher/students_logs",
  ARTICLES: "/teacher/articles",
  PBL_REPORTS: "/teacher/report_templates",
  PBL_BOOKMARKS: "/teacher/pbl_bookmarks",
  PBL_PROJECTS: "/teacher/pbl_projects/companies",
  PBL_PROJECTS_LECTURE: "/teacher/pbl_projects/lectures",
  PBL_PROJECT_PROJECT_DETAIL: "/teacher/pbl_projects/projects",
  REPORTS: "/teacher/reports",
  PAYMENTS: "/teacher/payments",
  HOMEWORK_TEMPLATES: "/teacher/homework_templates",
  MATERIALS: "/teacher/materials",
  MATERIAL: "/teacher/materials/material",
  MATERIALS_DISTRIBUTIONS: "/teacher/materials/distributions",
  MATERIAL_WORKS: "/teacher/material_works",
  UNIVERSITY_MESSAGE: "/teacher/university/message",
  CORPORATE_COLLABORATION: "/teacher/corporate_collaboration",
  MATERIAL_DISTRIBUTIONS: "/teacher/distributions",
  CALENDAR: "/teacher/calendar",
  PBL_PLAN: "/teacher/pbl_plan",
  TAGS: "/teacher/tags",
  COMPANY: "/teacher/company",
  STEAM: "/teacher/steam_library",
  STEAM_LIBRARY_LECTURE: "/teacher/steam_library/lecture",
  STEAM_LIBRARY_CONTENT: "/teacher/steam_library/content",
  HOMEWORK_TEMPLATES_HOMEWORKS_MATCHER: /homework_templates\/.*\/homeworks/,
  HOMEWORK_DISTRIBUTIONS_MATCHER:
    /homework_templates\/.*\/homework_distributions/,
  LEARNING_PACKAGES: "/teacher/learning_packages",
  RUBRIC: "/teacher/rubric",
  IDEAL: "/teacher/ideal",
  IDEAL_BOARDS: "/teacher/ideal/boards",
  IDEAL_BOARD_TEMPLATES: "/teacher/ideal/board_templates",
  IDEAL_BOARDS_MATCHER: /teacher\/ideal\/boards/,
  CLASS_NAV: "/teacher/class_nav",
  NOTIFICATIONS: "/teacher/notifications",
};

// SuperOwner
export const SUPER_OWNER_ROUTES = {
  BASE_ROUTE: "/super_owner",
  USERS: "/super_owner/users",
  SCHOOLS: "/super_owner/schools",
  CUSTOM_LIST: "/super_owner/custom_list",
  HOMEWORK_TEMPLATES: "/super_owner/homework_templates",
  REVIEWER_COMPANIES: "/super_owner/reviewer_companies",
  SCHOOL_CSV: "/super_owner/schools/csv_import",
  TAGS: "/super_owner/tags",
  OWN_COMPANY: "/super_owner/company",
  MATERIALS: "/super_owner/materials",
  NOTIFICATIONS: "/super_owner/notifications",
  INQUIRIES: "/super_owner/inquiries",
  LEARNING_PACKAGES: "/super_owner/packages",
  IDEAL: "/super_owner/ideal",
  IDEAL_BOARDS: "/super_owner/ideal/boards",
  IDEAL_BOARD_TEMPLATES: "/super_owner/ideal/board_templates",
  IDEAL_BOARDS_MATCHER: /super_owner\/ideal\/boards/,
};

// Admin
export const ADMIN_ROUTES = {
  USERS: "/admin/users",
  COMPANIES: "/admin/companies",
  PBL_PROJECTS: "/admin/pbl_projects/companies",
  PBL_PROJECTS_LECTURE: "/admin/pbl_projects/lectures",
  All_PBL_PROJECTS: "/admin/all_projects",
  INVOICES: "/admin/invoices",
  REPORTS: "/admin/reports",
  NOTIFICATIONS: "/admin/notifications",
  STEAM: "/admin/steam_library",
};

// Reviewer
export const REVIEWER_ROUTES = {
  HOMEWORK_TEMPLATE_DISTRIBUTIONS: "/reviewer/homework_template_distributions",
  HOMEWORK_TEMPLATES: "/reviewer/homework_templates",
  HOMEWORK: "/reviewer/homeworks",
  ARTICLES: "/reviewer/articles",
  REVIEWERS: "/reviewer/reviewers",
};

// ContentProvider
export const CONTENT_PROVIDER_ROUTES = {
  ARTICLES: "/content_provider/articles",
  PBL_PROJECTS: "/content_provider/pbl_projects/companies",
  PBL_PROJECTS_LECTURE: "/content_provider/pbl_projects/lectures",
  PBL_PROJECT_PROJECT_DETAIL: "/content_provider/pbl_projects/projects",
  SCHOOLS: "/content_provider/schools",
  PBL_BOARD: "/content_provider/pbl/board",
  PBL_BOARD_PREVIEW: "/owner/pbl/board/setting",
  PBL_BOARD_CAMPAIGNS: "/content_provider/pbl/board/campaigns",
  CORPORATE_COLLABORATION: "/content_provider/corporate_collaboration",
  CORPORATE_COLLABORATION_SETTINGS:
    "/content_provider/corporate_collaboration/settings",
  PBL_BOARD_CAMPAIGNS_MATCHER: /content_provider\/pbl\/board\/campaigns/,
  PBL_BOARD_PREVIEW_MATCHER: /owner\/pbl\/board\/preview/,
};

// Common
export const COMMON_ROUTES = {
  INQUIRIES: "/inquiries",
};
