import { Alert } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export interface Props {
  message: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    margin: theme.spacing(2, 0),
  },
}));

const ErrorMessage: React.FC<Props> = ({ message }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrap}>
      <Alert severity="error" data-cy="error-message">
        {message}
      </Alert>
    </div>
  );
};

export default ErrorMessage;
