/* eslint-disable camelcase */
import {
  Api,
  CustomMasterMapBase,
  CustomMastersLinkingCompaniesListParams,
  DeleteControllerAsSuperOwnerParams,
  GetCustomMasterMapRelationshipsAsSuperOwnerParams,
  GetSuperOwnerCustomMasterMapsParams,
  PostCustomMasterMapRelationshipsAsSuperOwnerParams,
  PutCustomMasterMapRelationshipsAsSuperOwnerParams,
} from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RootState } from "@store/store";

const api = new Api();

export const fetchCustomMasterListAsSuperOwner = createAsyncThunk<
  any,
  GetSuperOwnerCustomMasterMapsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/customMasterList/fetch", async (params, { rejectWithValue }) => {
  try {
    const response: any =
      await api.superOwner.getSuperOwnerCustomMasterMaps(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchCustomMasterMapRelationListAsSuperOwner = createAsyncThunk<
  GetResponseType<
    Api["superOwner"]["getCustomMasterMapRelationshipsAsSuperOwner"]
  >,
  GetCustomMasterMapRelationshipsAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterRelationships/fetch",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.getCustomMasterMapRelationshipsAsSuperOwner(
          params,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const searchCustomMasterListRecordsAsSuperOwner = createAsyncThunk<
  any,
  CustomMastersLinkingCompaniesListParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/customMasterList/search", async (params, { rejectWithValue }) => {
  try {
    const response: any =
      await api.superOwner.customMastersLinkingCompaniesList(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const postCustomMasterListAsSuperOwner = createAsyncThunk<
  any,
  CustomMasterMapBase,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/customMasterList/post", async (data, { rejectWithValue }) => {
  try {
    const response = await api.superOwner.postSuperOwnerCustomMasterMaps(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export type CustomMasterMapRelationsParams = {
  query: PostCustomMasterMapRelationshipsAsSuperOwnerParams;
  data: {
    name: string;
    child_custom_master_map_id: string;
    parent_title_for_column_number: number;
    child_title_for_column_number: number;
  };
};
// カスタムリストのリレーション設定を登録
export const postCustomMasterMapRelationshipsAsSuperOwner = createAsyncThunk<
  any,
  CustomMasterMapRelationsParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterListRelationShips/post",
  async (params, { rejectWithValue }) => {
    const { query, data } = params;
    try {
      const response: any =
        await api.superOwner.postCustomMasterMapRelationshipsAsSuperOwner(
          query,
          data,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCustomMasterListAsSuperOwner = createAsyncThunk<
  any,
  {
    // TODO: This has to be fixed by みーちゃん
    listParams: any;
    listId: string;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("superOwner/customMasterList/update", async (data, { rejectWithValue }) => {
  try {
    const response =
      await api.superOwner.putSuperOwnerCustomMasterMapsCustomMasterMapId(
        data.listId,
        { id: data.listId, ...data.listParams },
      );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export interface UpdateCustomMasterListRelationParams {
  ids: PutCustomMasterMapRelationshipsAsSuperOwnerParams;
  data: {
    name: string;
  };
}
export const updateCustomMasterListRelationSuperOwner = createAsyncThunk<
  any,
  UpdateCustomMasterListRelationParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterListRelation/update",
  async (params, { rejectWithValue }) => {
    const { ids, data } = params;
    try {
      const response =
        await api.superOwner.putCustomMasterMapRelationshipsAsSuperOwner(
          ids,
          data,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteCustomMasterListAsSuperOwner = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterList/delete",
  async (customMasterId, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.deleteSuperOwnerCustomMasterMapsCustomMasterMapId(
          customMasterId,
        );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteCustomMasterRelationListAsSuperOwner = createAsyncThunk<
  any,
  DeleteControllerAsSuperOwnerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "superOwner/customMasterRelationships/delete",
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.deleteControllerAsSuperOwner(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export interface SaveCustomListSequenceParams {
  sequences: Array<{
    custom_master_map_id: string;
    sequence: number;
  }>;
}

export const saveCustomListSequence = createAsyncThunk<
  any,
  SaveCustomListSequenceParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "super_owner/customMasterList/saveSequence",
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await api.superOwner.customMasterMapsSequenceUpdate(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetState = createAction(
  "superOwner/customMasterList/resetState",
);

export const resetSearchedCompaniesState = createAction(
  "superOwner/customMasterList/resetSearchedCompaniesState",
);
