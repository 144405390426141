import {
  FormControl,
  OutlinedInput,
  OutlinedInputClassKey,
  PaperProps,
  Select,
  SelectChangeEvent,
  SelectClassKey,
  Theme,
} from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import CustomMenuItem from "./CustomMenuItem";

export type SelectItem = {
  value: string;
  label: string | JSX.Element;
  action?: () => void;
  disabled?: boolean;
};

export type SelectNumberItem = {
  value: number;
  label: string;
  action?: () => void;
  disabled?: boolean;
};

export type Props = {
  selectItems:
    | string[]
    | number[]
    | SelectItem[]
    | SelectNumberItem[]
    | Array<string | number>;
  selectorValue?: string | number | null;
  handleSelectorChange?: (event: SelectChangeEvent) => void;
  disabled?: boolean;
  isCustomLabelDisabled?: boolean;
  customLabel?: string;
  customStyles?: React.CSSProperties;
  customInputClasses?: Partial<ClassNameMap<OutlinedInputClassKey>>;
  customSelectClasses?: Partial<ClassNameMap<SelectClassKey>>;
  customPaperProps?: Partial<PaperProps>;
  fullWidth?: boolean;
  margin?: "none" | "dense" | "normal";
  size?: "small" | "medium";
  selectName?: string;
  error?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.common.white,
      "&:focus": {
        background: theme.palette.primary.contrastText,
        borderRadius: 8,
      },
    },
    select: {
      whiteSpace: "break-spaces",
    },
  }),
);

const useOutlinedInputStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover $notchedOutline": {
      border: `4px solid ${theme.palette.tertiary.mainDark}4D`,
    },
    "&$focused $notchedOutline": {
      border: `4px solid ${theme.palette.tertiary.mainDark}4D`,
    },
  },
  focused: {
    backgroundColor: "transparent",
  },
  notchedOutline: {
    borderRadius: 8,
  },
}));

const V2StyledSelect: React.FC<Props> = ({
  selectItems,
  selectorValue,
  handleSelectorChange,
  disabled,
  customLabel,
  customStyles,
  customInputClasses,
  customSelectClasses,
  customPaperProps,
  fullWidth,
  isCustomLabelDisabled,
  selectName,
  margin = "none",
  size,
  error,
}) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  return (
    <FormControl
      variant="outlined"
      margin={margin}
      fullWidth={fullWidth}
      data-cy="select-in-v2-styled-form-control"
      error={error}
    >
      <Select
        displayEmpty
        data-cy="select-in-v2-styled-select"
        name={selectName}
        disabled={disabled}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: customPaperProps ?? {
            style: {
              padding: "0px 10px 0px 10px",
              borderRadius: 8,
              marginTop: 8,
              backgroundColor: "white",
              maxWidth: 500,
            },
          },
        }}
        value={selectorValue ? String(selectorValue) : ""}
        classes={{
          root: classes.root,
          select: classes.select,
          ...customSelectClasses,
        }}
        style={customStyles}
        onChange={handleSelectorChange}
        input={
          <OutlinedInput
            size={size ?? "small"}
            notched={false}
            name="student-per-page"
            id="outlined-student-per-page"
            classes={customInputClasses || outlinedInputClasses}
          />
        }
      >
        {customLabel && (
          <CustomMenuItem
            data-cy="selector-item-default"
            key={uuidv4()}
            value=""
            disabled={isCustomLabelDisabled}
          >
            {customLabel}
          </CustomMenuItem>
        )}
        {selectItems.map((item) => {
          if (typeof item === "object" && "value" in item) {
            return (
              <CustomMenuItem
                data-cy="selector-item"
                key={uuidv4()}
                value={item.value}
                onClick={item.action}
                disabled={item.disabled}
              >
                {item.label}
              </CustomMenuItem>
            );
          }

          return (
            <CustomMenuItem data-cy="selector-item" key={item} value={item}>
              {item}
            </CustomMenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default V2StyledSelect;
