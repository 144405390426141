/* eslint-disable camelcase */
import { fetchMaterialsAllCategoriesAsOwner } from "@actions/ownerApp/material";
import { fetchMaterialsAllCategoriesAsSuperOwner } from "@actions/superOwnerApp/material";
import { ReduxStatus, ReduxStatusType } from "@constants/redux";
import { roles } from "@constants/roles";
import { useAuth } from "@contexts/Auth";
import {
  Api,
  AttachedImageBase,
  AttachedImageCategorizableType,
  HasIdAndTitle,
  MaterialsAllContainedCategoryListParams,
  Role,
} from "@lib/Api";
import { safeString } from "@lib/string-utils";
import { ownerAppMaterialState } from "@reducers/ownerApp/material";
import { superOwnerAppMaterialState } from "@reducers/superOwnerApp/material";
import { useAppDispatch, useAppSelector } from "@root/hooks";
import { getMarkAttributes } from "@tiptap/core";
import { Editor } from "@tiptap/react";
import { AxiosResponse } from "axios";

import { SVImageOptions } from "./Extension/Image/SVImage";
import {
  addDeleteImagesOnSaving,
  resetDeleteImages,
} from "./Extension/Image/SVImageSlice";
import SVImageStore from "./Extension/Image/SVImageStore";

const api = new Api();
type deleteAttachedImage =
  | typeof api.superOwner.deleteAttachedImageAsSuperOwner
  | typeof api.owner.deleteAttachedImageAsOwner
  | typeof api.teacher.deleteAttachedImageAsTeacher
  | typeof api.student.deleteAttachedImageAsStudent
  | typeof api.admin.deleteAttachedImageAsAdmin
  | typeof api.reviewer.deleteAttachedImageAsReviewer
  | typeof api.contentProvider.deleteAttachedImageAsContentProvider
  | typeof api.common.deleteAttachedImageAsCommon
  | typeof api.public.deleteAttachedImageAsPublic;

type getAttachedImage =
  | typeof api.superOwner.getAttachedImageAsSuperOwner
  | typeof api.owner.getAttachedImageAsOwner
  | typeof api.teacher.getAttachedImageAsTeacher
  | typeof api.student.getAttachedImageAsStudent
  | typeof api.admin.getAttachedImageAsAdmin
  | typeof api.reviewer.getAttachedImageAsReviewer
  | typeof api.contentProvider.getAttachedImageAsContentProvider
  | typeof api.common.getAttachedImageAsCommon
  | typeof api.public.getAttachedImageAsPublic;

export const deleteAttachedImage = (
  apiRole: SVImageOptions["apiRole"],
): deleteAttachedImage => {
  let deleteApi: deleteAttachedImage;
  switch (apiRole) {
    case roles.SUPER_OWNER:
      deleteApi = api.superOwner.deleteAttachedImageAsSuperOwner;
      break;
    case roles.OWNER:
      deleteApi = api.owner.deleteAttachedImageAsOwner;
      break;
    case roles.TEACHER:
      deleteApi = api.teacher.deleteAttachedImageAsTeacher;
      break;
    case roles.STUDENT:
      deleteApi = api.student.deleteAttachedImageAsStudent;
      break;
    case roles.REVIEWER:
      deleteApi = api.reviewer.deleteAttachedImageAsReviewer;
      break;
    case roles.ADMIN:
      deleteApi = api.admin.deleteAttachedImageAsAdmin;
      break;
    case roles.CONTENT_PROVIDER:
      deleteApi = api.contentProvider.deleteAttachedImageAsContentProvider;
      break;
    default:
      deleteApi = api.public.deleteAttachedImageAsPublic;
      break;
  }
  return deleteApi;
};

export const getAttachedImage = (
  apiRole: SVImageOptions["apiRole"],
): getAttachedImage => {
  let getApi: getAttachedImage;
  switch (apiRole) {
    case roles.SUPER_OWNER:
      getApi = api.superOwner.getAttachedImageAsSuperOwner;
      break;
    case roles.OWNER:
      getApi = api.owner.getAttachedImageAsOwner;
      break;
    case roles.TEACHER:
      getApi = api.teacher.getAttachedImageAsTeacher;
      break;
    case roles.STUDENT:
      getApi = api.student.getAttachedImageAsStudent;
      break;
    case roles.REVIEWER:
      getApi = api.reviewer.getAttachedImageAsReviewer;
      break;
    case roles.ADMIN:
      getApi = api.admin.getAttachedImageAsAdmin;
      break;
    case roles.CONTENT_PROVIDER:
      getApi = api.contentProvider.getAttachedImageAsContentProvider;
      break;
    default:
      getApi = api.public.getAttachedImageAsPublic;
      break;
  }
  return getApi;
};

export const resetStoredImage = (): void => {
  SVImageStore.dispatch(resetDeleteImages());
};

// キャンセルボタンを押下した際に本文に登録されなかった画像を削除する
// SVEditorを利用している箇所で明示的に関数を呼ぶ必要がある
export const deleteStoredImageOnCanceling = async (
  apiRole: SVImageOptions["apiRole"],
): Promise<void> => {
  const { SVImage } = SVImageStore.getState();

  await Promise.allSettled(
    SVImage.deleteImagesOnCanceling.map((img) => {
      return deleteAttachedImage(apiRole)(img);
    }),
  );
  resetStoredImage();
};

// 保存ボタンを押下した際に本文から削除された画像を削除する
// SVEditorを利用している箇所で明示的に関数を呼ぶ必要がある
export const deleteStoredImageOnSaving = async (
  apiRole: SVImageOptions["apiRole"],
): Promise<void> => {
  const { SVImage } = SVImageStore.getState();

  await Promise.allSettled(
    SVImage.deleteImagesOnSaving.map((img) => {
      return deleteAttachedImage(apiRole)(img);
    }),
  );
  resetStoredImage();
};

export const useDOMParse = (): {
  fetchMaterialsAllCategories: (
    query: MaterialsAllContainedCategoryListParams,
  ) => void;
  fetchMaterialsAllCategoriesStatus: () => ReduxStatusType;
  getMaterialsAllCategories: () => HasIdAndTitle[];
  refetchResizeImageSrc: (
    content: string,
    previewWidth: number,
  ) => Promise<string>;
  addDeleteImageOnClickDeleteButton: (content: string) => Promise<void>;
  getMaterialLink: (materialId: string | undefined) => string | undefined;
  canDisplayMaterialLink: (editor: Editor) => boolean;
  canDisplayLink: (editor: Editor) => boolean;
  hasLink: (editor: Editor) => boolean;
  hasMaterialLink: (editor: Editor) => boolean;
  setMaterialLinkHref: (content: string) => string;
  canInsertImage: (editor: Editor) => boolean;
  canInsertTable: (editor: Editor) => boolean;
  materialCount: number;
} => {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const {
    fetchingAllCategories: fetchStatusForOwner,
    materialAllCategories: materialsForOwner,
    materialAllCategoriesCount: materialAllCategoriesCountOwner,
  } = useAppSelector(ownerAppMaterialState);
  const {
    fetchingAllCategories: fetchStatusForSuperOwner,
    materialAllCategories: materialsForSuperOwner,
    materialAllCategoriesCount: materialAllCategoriesCountSuperOwner,
  } = useAppSelector(superOwnerAppMaterialState);

  // imgタグからcategorizableIdとcagetorizableTypeを取得する
  const getCategorizableValue = (image: HTMLImageElement) => {
    const { categorizableType } = image.dataset;
    let categorizableId;
    if (categorizableType === AttachedImageCategorizableType.Material) {
      categorizableId =
        image.dataset.materialId ?? image.dataset.categorizableId;
    } else {
      categorizableId = image.dataset.categorizableId;
    }

    return { categorizableId, categorizableType };
  };

  // 画像を再取得してsrcの中身を再取得後のデータに置き換える
  const refetchResizeImageSrc = async (
    content: string,
    previewWidth: number,
  ): Promise<string> => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const images = doc.querySelectorAll("img");
    if (images.length === 0) {
      return doc.body.innerHTML;
    }

    const responses = await Promise.allSettled(
      Array.from(images)
        .filter((image) => {
          return (
            image.dataset.id &&
            (image.dataset.materialId ?? image.dataset.categorizableId) &&
            image.dataset.categorizableType
          );
        })
        .filter((image) => {
          const { categorizableId, categorizableType } =
            getCategorizableValue(image);
          return Boolean(categorizableType) && Boolean(categorizableId);
        })
        .map((image) => {
          const { categorizableId, categorizableType } =
            getCategorizableValue(image);

          return getAttachedImage(currentUser?.current_role as Role)({
            id: image.dataset.id as string,
            categorizable_id: categorizableId as string,
            categorizable_type:
              categorizableType as AttachedImageCategorizableType,
          });
        }),
    );

    const successResponses = (
      responses.filter((response) => {
        return response.status === "fulfilled";
      }) as PromiseFulfilledResult<AxiosResponse<AttachedImageBase>>[]
    ).map((item) => {
      return item.value;
    });

    images.forEach((image, key) => {
      const item = successResponses.find((result) => {
        const { data } = result;
        return data?.id === image.dataset.id;
      });
      if (item) {
        images[key].src = `${
          (item?.data as AttachedImageBase)?.file_url || ""
        }`;
        if (images[key].width >= previewWidth) {
          images[key].style.width = "100%";
          images[key].style.height = "auto";
        }
      }
    });

    return doc.body.innerHTML;
  };

  // 画像を削除するために画像をリストに追加する
  const addDeleteImageOnClickDeleteButton = async (
    content: string,
  ): Promise<void> => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const images = doc.querySelectorAll("img");
    if (images.length === 0) {
      return;
    }

    Array.from(images)
      .filter((image) => {
        return (
          image.dataset.id &&
          (image.dataset.materialId ?? image.dataset.categorizableId) &&
          image.dataset.categorizableType
        );
      })
      .forEach((image) => {
        const { categorizableType } = image.dataset;
        let categorizableId;
        if (categorizableType === AttachedImageCategorizableType.Material) {
          categorizableId =
            image.dataset.materialId ?? image.dataset.categorizableId;
        } else {
          categorizableId = image.dataset.categorizableId;
        }
        if (!categorizableType || !categorizableId) {
          return;
        }

        SVImageStore.dispatch(
          addDeleteImagesOnSaving({
            id: image.dataset.id as string,
            categorizable_id: categorizableId,
            categorizable_type: categorizableType,
          }),
        );
      });
  };

  const fetchMaterialsAllCategories = async (
    query: MaterialsAllContainedCategoryListParams,
  ) => {
    if (safeString(currentUser?.current_role) === roles.SUPER_OWNER) {
      dispatch(fetchMaterialsAllCategoriesAsSuperOwner(query));
    }

    if (safeString(currentUser?.current_role) === roles.OWNER) {
      dispatch(fetchMaterialsAllCategoriesAsOwner(query));
    }
  };

  const fetchMaterialsAllCategoriesStatus = (): ReduxStatusType => {
    if (safeString(currentUser?.current_role) === roles.SUPER_OWNER) {
      return fetchStatusForOwner;
    }

    if (safeString(currentUser?.current_role) === roles.OWNER) {
      return fetchStatusForSuperOwner;
    }
    return ReduxStatus.idle;
  };

  const getMaterialsAllCategories = (): HasIdAndTitle[] => {
    if (safeString(currentUser?.current_role) === roles.SUPER_OWNER) {
      return materialsForSuperOwner;
    }

    if (safeString(currentUser?.current_role) === roles.OWNER) {
      return materialsForOwner;
    }
    return [];
  };

  // 教材用のリンクは役割(role)によりリンクが変化するので役割によりリンクを出し分ける
  // (生徒用のリンクをオーナーは開けない)
  const getMaterialLink = (
    materialId: string | undefined,
  ): string | undefined => {
    let materialLink;
    if (!materialId) {
      return materialLink;
    }
    if (
      [roles.SUPER_OWNER, roles.OWNER, roles.STUDENT].includes(
        safeString(currentUser?.current_role),
      )
    ) {
      materialLink = `/${currentUser?.current_role}/materials/${materialId}`;
    } else if (
      [roles.TEACHER].includes(safeString(currentUser?.current_role))
    ) {
      materialLink = `/${currentUser?.current_role}/materials/material/${materialId}`;
    } else {
      materialLink = `/student/materials/${materialId}`;
    }
    return materialLink;
  };

  const canDisplayMaterialLink = (editor: Editor): boolean => {
    const linkAttributes = getMarkAttributes(editor.state, "link");
    if (!linkAttributes) {
      return true;
    }

    return (
      (linkAttributes.href && linkAttributes["data-material-id"]) ||
      (!linkAttributes.href && !linkAttributes["data-material-id"])
    );
  };

  const hasMaterialLink = (editor: Editor): boolean => {
    const linkAttributes = getMarkAttributes(editor.state, "link");
    if (!linkAttributes) {
      return false;
    }
    return linkAttributes.href && linkAttributes["data-material-id"];
  };

  const canDisplayLink = (editor: Editor): boolean => {
    const linkAttributes = getMarkAttributes(editor.state, "link");
    if (!linkAttributes) {
      return true;
    }
    return !linkAttributes["data-material-id"];
  };

  const hasLink = (editor: Editor): boolean => {
    const linkAttributes = getMarkAttributes(editor.state, "link");
    if (!linkAttributes) {
      return false;
    }
    return linkAttributes.href && !linkAttributes["data-material-id"];
  };

  // getMaterialLinkで取得した教材用リンクでHTMLの中身を書き換える
  const setMaterialLinkHref = (content: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const links = doc.querySelectorAll("a");
    if (links.length === 0) {
      return doc.body.innerHTML;
    }
    links.forEach((link, key) => {
      const materialLink = getMaterialLink(link?.dataset?.materialId);
      if (materialLink) {
        links[key].href = materialLink;
      }
    });
    return doc.body.innerHTML;
  };

  // 画像が既にSVEditorにある場合は挿入できないようにする
  // SVEditor1つにつき画像は一つ
  // (画像が複数あると操作用の要素がうまく動かないため)
  const canInsertImage = (editor: Editor): boolean => {
    let isInsertedImage = false;
    editor.view.state.doc.descendants((node) => {
      if (node.type.name === "SVImage") {
        isInsertedImage = true;
      }
    });
    return !isInsertedImage;
  };

  // テーブルが既にSVEditorにある場合は挿入できないようにする
  // SVEditor1つにつきテーブルは一つ
  // (テーブルが複数あると操作用の要素がうまく動かないため)
  const canInsertTable = (editor: Editor): boolean => {
    let isInsertedTable = false;
    editor.view.state.doc.descendants((node) => {
      if (node.type.name === "SVTable") {
        isInsertedTable = true;
      }
    });
    return !isInsertedTable;
  };

  return {
    fetchMaterialsAllCategories,
    fetchMaterialsAllCategoriesStatus,
    getMaterialsAllCategories,
    refetchResizeImageSrc,
    addDeleteImageOnClickDeleteButton,
    getMaterialLink,
    canDisplayMaterialLink,
    canDisplayLink,
    hasLink,
    hasMaterialLink,
    setMaterialLinkHref,
    canInsertImage,
    canInsertTable,
    materialCount:
      safeString(currentUser?.current_role) === roles.SUPER_OWNER
        ? materialAllCategoriesCountSuperOwner
        : materialAllCategoriesCountOwner,
  };
};

export default useDOMParse;
