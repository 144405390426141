/* eslint-disable camelcase */
import {
  createPblReportAnswerAsTeacher,
  fetchPblReportAnswerAsTeacher,
  fetchPblReportTemplateAnswersAsTeacher,
  resetAnswers,
} from "@actions/teacherApp/pbl/report/answer";
import {
  createPblReportItemAsTeacher,
  deletePblReportItemAsTeacher,
  fetchPblReportItemsAsTeacher,
  updatePblReportItemAsTeacher,
} from "@actions/teacherApp/pbl/report/item";
import {
  createPblReportTemplateAsTeacher,
  fetchPblReportTemplateAsTeacher,
  fetchPblReportTemplatesAsTeacher,
  fetchPblReportTemplatesAsTeacherWithQuestionNumber,
  fetchPblReportTemplateStatsAsTeacher,
  getTeacherTemplateFrameworks,
  resetModalState,
  resetOne,
  resetPblReportAnswers,
  resetTemplateRelatedData,
  updatePblReportTemplateAsTeacher,
} from "@actions/teacherApp/pbl/report/template";
import {
  PblReportAnswerBase,
  PblReportAnswerDetailsForTeacher,
  PblReportTemplateBase,
  PblReportTemplateDetailsForTeacher,
  PblReportTemplateStats,
  PblReportTextBase,
} from "@lib/Api";
import { remove, update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";
import _ from "lodash";

export interface TeacherAppPblReportTemplateState {
  fetching: ReduxStatusType;
  fetchingWithQuestionNumber: ReduxStatusType;
  fetchingOne: boolean;
  fetchedOne: boolean;
  changeError: string | null;
  fetchError: string | null;
  fetchOneError: string | null;
  templates: PblReportTemplateBase[];
  templatesWithQuestionNumber: (PblReportTemplateBase & {
    question_number: number;
  })[];
  template: PblReportTemplateDetailsForTeacher | null;
  items: PblReportTextBase[];
  fetchingItems: boolean;
  fetchItemsError: any;
  answers: PblReportAnswerBase[];
  answer: PblReportAnswerDetailsForTeacher | null;
  fetchingAnswers: boolean;
  fetchAnswersError: any;
  fetchingAnswer: boolean;
  fetchAnswerError: any;
  totalCount: number;
  answersTotalCount: number;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  deleting: boolean;
  deleted: boolean;
  fetchingTemplateFrameWork: boolean;
  fetchedTemplateFrameWork: boolean;
  teacherTemplateFrameWorks: any[];
  templateFrameWorkError: any;
  fetchedTemplateStats: boolean;
  fetchingTemplateStats: boolean;
  fetchTemplateStatsError: string | null;
  templateStats: PblReportTemplateStats[];
  creatingAnswer: boolean;
  createdAnswer: boolean;
  createdAnswerError: any;
}

export const initialState: TeacherAppPblReportTemplateState = {
  fetching: ReduxStatus.idle,
  fetchingWithQuestionNumber: ReduxStatus.idle,
  fetchingOne: false,
  fetchedOne: false,
  fetchError: null,
  fetchOneError: null,
  changeError: null,
  templates: [],
  templatesWithQuestionNumber: [],
  template: null,
  items: [],
  fetchingItems: false,
  fetchItemsError: null,
  answers: [],
  answer: null,
  fetchingAnswers: false,
  fetchAnswersError: null,
  fetchingAnswer: false,
  fetchAnswerError: null,
  totalCount: 0,
  answersTotalCount: 0,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  fetchingTemplateFrameWork: false,
  fetchedTemplateFrameWork: false,
  templateFrameWorkError: null,
  teacherTemplateFrameWorks: [],
  fetchedTemplateStats: false,
  fetchingTemplateStats: false,
  fetchTemplateStatsError: null,
  templateStats: [],
  creatingAnswer: false,
  createdAnswer: false,
  createdAnswerError: null,
};

const teacherAppPblReportTemplateSlice = createSlice({
  name: "TeacherApp/Pbl/Report/Template",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeacherTemplateFrameworks.pending, (state, _action) => {
        return {
          ...state,
          fetchingTemplateFrameWork: true,
          fetchedTemplateFrameWork: false,
        };
      })
      .addCase(getTeacherTemplateFrameworks.rejected, (state, action) => {
        return {
          ...state,
          fetchingTemplateFrameWork: false,
          fetchedTemplateFrameWork: false,
          templateFrameWorkError: action.error.message as string,
        };
      })
      .addCase(getTeacherTemplateFrameworks.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingTemplateFrameWork: false,
          fetchedTemplateFrameWork: true,
          teacherTemplateFrameWorks: payload.report_template_frameworks,
        };
      })
      .addCase(fetchPblReportTemplatesAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: ReduxStatus.pending,
          fetchError: null,
        };
      })
      .addCase(fetchPblReportTemplatesAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: ReduxStatus.rejected,
          fetchError: action.error.message as string,
          templates: [],
        };
      })
      .addCase(fetchPblReportTemplatesAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: ReduxStatus.fulfilled,
          fetchError: null,
          templates: payload.templates,
          totalCount: payload.total_count,
        };
      })
      .addCase(
        fetchPblReportTemplatesAsTeacherWithQuestionNumber.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingWithQuestionNumber: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        fetchPblReportTemplatesAsTeacherWithQuestionNumber.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingWithQuestionNumber: ReduxStatus.rejected,
            templatesWithQuestionNumber: [],
          };
        },
      )
      .addCase(
        fetchPblReportTemplatesAsTeacherWithQuestionNumber.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingWithQuestionNumber: ReduxStatus.fulfilled,
            templatesWithQuestionNumber: payload.templates,
            totalCount: payload.total_count,
          };
        },
      )

      .addCase(
        fetchPblReportTemplateStatsAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetchingTemplateStats: true,
            fetchedTemplateStats: false,
            fetchTemplateStatsError: null,
          };
        },
      )
      .addCase(
        fetchPblReportTemplateStatsAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchingTemplateStats: false,
            fetchedTemplateStats: true,
            fetchTemplateStatsError: null,
            templateStats: payload.templates,
          };
        },
      )
      .addCase(
        fetchPblReportTemplateStatsAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetchingTemplateStats: false,
            fetchedTemplateStats: false,
            fetchTemplateStatsError: action.error.message as string,
          };
        },
      )
      .addCase(fetchPblReportTemplateAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingOne: true,
          fetchOneerror: null,
        };
      })
      .addCase(fetchPblReportTemplateAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: action.error.message as string,
          template: null,
        };
      })
      .addCase(fetchPblReportTemplateAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          fetchOneError: null,
          template: payload,
        };
      })
      .addCase(createPblReportTemplateAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          creating: true,
          created: false,
          changeError: null,
        };
      })
      .addCase(createPblReportTemplateAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          creating: false,
          created: true,
          templates: [payload, ...currentState.templates],
          template: payload,
          totalCount: currentState.totalCount + 1,
          changeError: null,
        };
      })
      .addCase(createPblReportTemplateAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          creating: false,
          created: false,
          changeError: action.error.message as string,
        };
      })
      .addCase(updatePblReportTemplateAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          updated: false,
          changeError: null,
        };
      })
      .addCase(updatePblReportTemplateAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          updated: true,
          templates: update(currentState.templates, payload),
          template: payload,
          changeError: null,
        };
      })
      .addCase(updatePblReportTemplateAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          updated: false,
          changeError: action.payload as string,
        };
      })
      // Items
      .addCase(fetchPblReportItemsAsTeacher.pending, (state, action) => {
        return {
          ...state,
          fetchcingItems: true,
          items: [],
        };
      })
      .addCase(fetchPblReportItemsAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetchcingItems: false,
          items: action.payload,
        };
      })
      .addCase(fetchPblReportItemsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchcingItems: false,
          fetchItemsError: action.payload as string,
        };
      })
      .addCase(createPblReportItemAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          items: _.sortBy(
            [...state.items, action.payload],
            [(item) => item.sequence],
          ),
        };
      })
      .addCase(updatePblReportItemAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          items: _.sortBy(update(state.items, action.payload), [
            (item) => item.sequence,
          ]),
        };
      })
      .addCase(deletePblReportItemAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          items: _.sortBy(remove(state.items, action.meta.arg), [
            (item) => item.sequence,
          ]),
        };
      })
      // Answers
      .addCase(
        fetchPblReportTemplateAnswersAsTeacher.pending,
        (state, action) => {
          return {
            ...state,
            fetchcingAnswers: true,
            answers: [],
          };
        },
      )
      .addCase(
        fetchPblReportTemplateAnswersAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetchcingAnswers: false,
            answers: action.payload.pbl_report_template_answers,
            answersTotalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        fetchPblReportTemplateAnswersAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetchcingAnswers: false,
            fetchAnswersError: action.payload as string,
          };
        },
      )
      .addCase(fetchPblReportAnswerAsTeacher.pending, (state, action) => {
        return {
          ...state,
          fetchingAnswer: true,
        };
      })
      .addCase(fetchPblReportAnswerAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingAnswer: false,
          fetchAnswerError: action.payload,
        };
      })
      .addCase(fetchPblReportAnswerAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingAnswer: false,
          answer: action.payload,
          fetchAnswerError: null,
        };
      })
      .addCase(createPblReportAnswerAsTeacher.pending, (state, action) => {
        return {
          ...state,
          creatingAnswer: true,
          createdAnswer: false,
          createdAnswerError: null,
        };
      })
      .addCase(createPblReportAnswerAsTeacher.fulfilled, (state, action) => {
        const currentState = current(state);
        return {
          ...state,
          creatingAnswer: false,
          createdAnswer: true,
          createdAnswerError: false,
          answers: [...currentState.answers, action.payload],
        };
      })
      .addCase(createPblReportAnswerAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          creatingAnswer: false,
          createdAnswer: false,
          createdAnswerError: action.payload,
        };
      })
      .addCase(resetAnswers, (state, _action) => {
        return {
          ...state,
          fetchingAnswers: false,
          fetchAnswersError: null,
          answers: [],
        };
      })
      .addCase(resetPblReportAnswers, (state, _action) => {
        return {
          ...state,
          creatingAnswer: false,
          createdAnswer: false,
          createdAnswerError: null,
        };
      })
      // Reseting state
      .addCase(resetTemplateRelatedData, (state, _action) => {
        return {
          ...state,
          changeError: null,
          fetchingOne: false,
          fetchedOne: false,
          fetchOneError: null,
          template: null,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          changeError: null,
          creating: false,
          created: false,
          updating: false,
          updated: false,
          deleting: false,
          deleted: false,
        };
      })
      .addCase(resetOne, (state, _action) => {
        return {
          ...state,
          template: null,
          items: [],
          answers: [],
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppPblReportTemplateState = (
  state: RootState,
): TeacherAppPblReportTemplateState => state.teacherApp.pblReportTemplate;

export default teacherAppPblReportTemplateSlice.reducer;
