/* eslint-disable camelcase */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, GetRubricsAsTeacherParams } from "@root/lib/Api";
import { RootState } from "@store/store";

import {
  BatchUpdateRubricAsTeacherParams,
  GetRubricAsTeacher,
} from "./types/rubric";

const api = new Api();

export const getRubricsAsTeacher = createAsyncThunk<
  any,
  GetRubricsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("/teacher/rubrics", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getRubricsAsTeacher(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getRubricAsTeacher = createAsyncThunk<
  any,
  GetRubricAsTeacher,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("/teacher/rubric", async (params, { rejectWithValue }) => {
  try {
    const response = await api.teacher.getRubricAsTeacher(params.id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const batchUpdateRubricAsTeacher = createAsyncThunk<
  any,
  BatchUpdateRubricAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("/teacher/rubricAll/post", async (params, { rejectWithValue }) => {
  try {
    const {
      postRubricOrRubricId,
      postItems,
      putRubric,
      putItems,
      deleteItems,
    } = params;
    const responses: any[] = [];
    let rubricId: string;
    if (typeof postRubricOrRubricId === "string") {
      rubricId = postRubricOrRubricId;
    } else {
      const result = await api.teacher.postRubricsAsTeacher({
        status: postRubricOrRubricId.status,
        title: postRubricOrRubricId.title,
      });
      responses.push(result);
      const { id } = result.data;
      rubricId = id;
    }

    if (postItems) {
      responses.push(
        await Promise.all(
          postItems.map((postItem) => {
            return api.teacher.postRubricItemsAsTeacher(
              rubricId,
              postItem.data,
            );
          }),
        ),
      );
    }

    if (deleteItems) {
      responses.push(
        await Promise.all(
          deleteItems.map((item) => {
            return api.teacher.deleteRubricItemAsTeacher(
              rubricId,
              item.itemIds,
            );
          }),
        ),
      );
    }

    if (putItems) {
      responses.push(
        await Promise.all(
          putItems.map((putItem) => {
            return api.teacher.putRubricItemAsTeacher(
              putItem.getIds.rubric_id,
              putItem.getIds.id,
              putItem.data,
            );
          }),
        ),
      );
    }

    if (putRubric) {
      const { id } = putRubric;
      const { data } = putRubric;
      const result = await api.teacher.putRubricAsTeacher(id, data);
      responses.push(result);
    }

    return responses;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resetState = createAction("rubric/resetState");
