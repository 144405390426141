import { MaterialDetail, MaterialList } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  fetchMaterialAsTeacher,
  fetchMaterialsByCategoryAsTeacher,
} from "../../actions/teacherApp/material";
import { RootState } from "../../store";

export interface TeacherAppMaterialState {
  fetching: boolean;
  totalCount: number;
  serverError: any;
  material: MaterialDetail | null;
  materials: MaterialList[];
}

export const initialState: TeacherAppMaterialState = {
  fetching: false,
  totalCount: 0,
  serverError: null,
  material: null,
  materials: [],
};

const teacherAppMaterialSlice = createSlice({
  name: "TeacherApp/Material",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterialsByCategoryAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          serverError: null,
        };
      })
      .addCase(fetchMaterialsByCategoryAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          serverError: action.error.message as string,
        };
      })
      .addCase(fetchMaterialsByCategoryAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          materials: payload.materials,
          totalCount: payload.total_count,
        };
      })

      .addCase(fetchMaterialAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          material: null,
          serverError: null,
        };
      })
      .addCase(fetchMaterialAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          serverError: action.error.message as string,
        };
      })
      .addCase(fetchMaterialAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          material: payload,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppMaterialState = (
  state: RootState,
): TeacherAppMaterialState => state.teacherApp.material;

export default teacherAppMaterialSlice.reducer;
