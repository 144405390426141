import { HasIdAndTitle,HomeworkTemplateBaseForSuperOwner } from "@lib/Api";

import { coPackageType } from "..";

const HandleChecked = (
  content: HomeworkTemplateBaseForSuperOwner | HasIdAndTitle,
  chosenPackage: coPackageType[] | string[],
  coPackage: boolean | undefined,
): boolean => {
  const chosenCoPackages = chosenPackage as coPackageType[];
  if (coPackage) {
    return chosenCoPackages.some((item) => item.id === content.id);
  }
  return (chosenPackage as string[]).includes(content.id);
};

export default HandleChecked;
