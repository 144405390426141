/* eslint-disable camelcase */
import {
  FormCategoryTitle,
  FormSectionWrap,
} from "@components/UI/atoms/Forms/FormComponents";
import { SelectedButton } from "@components/UIv2/molecules/V2Tabs";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import {
  BaseCategoryInfo,
  HasIdAndTitle,
  HomeworkTemplateBaseForSuperOwner,
} from "@lib/Api";
import { Grid, TextField,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { GradeCategoryState } from "@reducers/category/grade";
import { MajorCategoryState } from "@reducers/category/major";
import { MiddleCategoryState } from "@reducers/category/middle";
import { MinorCategoryState } from "@reducers/category/minor";
import { SubjectCategoryState } from "@reducers/category/subject";
import { TextCategoryState } from "@reducers/category/text";
import React from "react";
import { Controller } from "react-hook-form";

import { coPackageType } from "..";
import HomeWorkTemplateList from "../HomeWorkTemplateList";
import MaterialList from "../MaterialsList";
import Tabs from "./Tabs/Tabs";

interface Props {
  templates: HomeworkTemplateBaseForSuperOwner[];
  materials: HasIdAndTitle[];
  handleCoPackage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  chosenPackage: coPackageType[];
  status: ModalStatus;
  control: any;
  setButtonState: React.Dispatch<React.SetStateAction<SelectedButton>>;
  buttonState: SelectedButton;
  selectedCategoryId: string[];
  text: TextCategoryState;
  grade: GradeCategoryState;
  subject: SubjectCategoryState;
  major: MajorCategoryState;
  middle: MiddleCategoryState;
  minor: MinorCategoryState;
  fetchingMaterialsByCategories: boolean;
  currentMaterialsPage: number;
  totalMaterials: number;
  handleMaterialsPagination: (newPage: number) => void;
  handleSelectedIds: (item: BaseCategoryInfo) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      height: 400,
      width: "100%",
      borderTop: `2px solid ${theme.palette.learningTemplatePackage.lightGrey}`,
    },
    items: {
      paddingTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    title: {
      marginTop: 0,
    },
  }),
);

const ChildPackage: React.FC<Props> = ({
  templates,
  chosenPackage,
  status,
  materials,
  control,
  buttonState,
  setButtonState,
  handleCoPackage,
  selectedCategoryId,
  text,
  grade,
  subject,
  major,
  middle,
  minor,
  currentMaterialsPage,
  totalMaterials,
  handleMaterialsPagination,
  fetchingMaterialsByCategories,
  handleSelectedIds,
}) => {
  const classes = useStyles();
  const handleModalBody = () => {
    switch (buttonState) {
      case SelectedButton.Primary:
        return (
          <MaterialList
            materials={materials}
            handleChosePackage={handleCoPackage}
            chosenPackage={chosenPackage}
            status={status}
            selectedCategoryId={selectedCategoryId}
            text={text}
            grade={grade}
            subject={subject}
            major={major}
            middle={middle}
            minor={minor}
            fetchingMaterialsByCategories={fetchingMaterialsByCategories}
            handleSelectedIds={handleSelectedIds}
            currentMaterialsPage={currentMaterialsPage}
            handleMaterialsPagination={handleMaterialsPagination}
            totalMaterials={totalMaterials}
            coPackage
          />
        );
      default:
        return (
          <HomeWorkTemplateList
            chosenPackage={chosenPackage}
            handleChosePackage={handleCoPackage}
            templates={templates}
            status={status}
            coPackage
          />
        );
    }
  };
  const modalBody = handleModalBody();

  return (
    <Grid container className={classes.main}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.title}>
        <FormSectionWrap>
          <FormCategoryTitle>タイトル*</FormCategoryTitle>
          <Controller
            control={control}
            name="packageTitle"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                id="packageTitle"
                placeholder="タイトルを入力してください。"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{ required: "タイトルは必須です。" }}
          />
        </FormSectionWrap>
      </Grid>
      <Tabs
        primary="教材"
        secondary="提出物"
        setButtonState={setButtonState}
        buttonState={buttonState}
      />
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.items}>
        {modalBody}
      </Grid>
    </Grid>
  );
};

export default ChildPackage;
