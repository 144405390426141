import ContentMenuIcon from "@assets/images/svg/contentMenu_icon.svg?react";
import PblMenuIcon from "@assets/images/svg/pblMenu_icon.svg?react";
import { useAuth } from "@contexts/Auth";
import useOptionFeature from "@lib/hooks/useOptionFeature";
import useStudentHeader from "@lib/hooks/useStudentHeader";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Drawer,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom"; // Ensure correct import for react-router
import { v4 as uuid } from "uuid";

import {
  MobileTab,
  STUDENT_TAB_TYPES,
} from "../Categories/types/navigationTypes";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const MobileHeaderMenu: React.FC<Props> = ({ open, handleClose }) => {
  const { currentUser } = useAuth();
  const { getMobileMenu } = useStudentHeader();
  const { isPblProjectsFeature } = useOptionFeature();
  const { t } = useTranslation("header_menu");
  const history = useHistory();

  const handleRedirection = (url: string) => {
    history.push(url);
    handleClose();
  };

  const menuConfig = [
    { types: [STUDENT_TAB_TYPES.dashboard], condition: true },
    {
      types: [
        STUDENT_TAB_TYPES.student_logs,
        STUDENT_TAB_TYPES.steam_library,
        STUDENT_TAB_TYPES.calendar,
        STUDENT_TAB_TYPES.classroom,
        ...(isPblProjectsFeature ? [STUDENT_TAB_TYPES.pbl_projects] : []),
      ],
      condition: true,
    },
    {
      types: [
        STUDENT_TAB_TYPES.learning_packages,
        STUDENT_TAB_TYPES.materials,
        STUDENT_TAB_TYPES.material_works,
        STUDENT_TAB_TYPES.homework,
      ],
      condition: true,
      isContentMenu: true,
      Icon: ContentMenuIcon,
      title: t("study_contents_menu"),
    },
    {
      types: [
        STUDENT_TAB_TYPES.articles,
        STUDENT_TAB_TYPES.report_answers,
        STUDENT_TAB_TYPES.memo,
      ],
      condition: currentUser?.company_authorization?.blog_feature,
      Icon: PblMenuIcon,
      title: t("pbl_menu"),
    },
  ];

  return (
    <Drawer
      anchor="top"
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { height: "100%" } }}
      sx={{
        ".MuiDrawer-paper": {
          backgroundColor: "common.white",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleClose} data-cy="close-button" size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      {menuConfig.map((config) => {
        if (!config.condition) return null;
        const items = getMobileMenu(config.types);
        if (!items.length) return null;

        return (
          <Box
            key={uuid()}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              borderBottom: 1,
              borderColor: "rgba(0, 130, 103, 0.5)",
              gap: 1,
            }}
          >
            {config.title && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mt: 2,
                  padding: "8px",
                }}
              >
                <config.Icon />
                <Typography
                  sx={{
                    ml: 1,
                    color: "customColor.text",
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  {config.title}
                </Typography>
              </Box>
            )}
            <ImageList
              gap={8}
              cols={4}
              sx={{
                alignItems: "center",
                width: "100%",
              }}
            >
              {items.map((item: MobileTab) => (
                <ImageListItem
                  key={item.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    margin: 1,
                  }}
                  onClick={() => handleRedirection(item.url)}
                  data-cy="mobile-icon"
                >
                  {item.icon}
                  <Typography
                    sx={{ mt: 1, textAlign: "center", fontSize: "12px" }}
                  >
                    {t(item.value)}
                  </Typography>
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        );
      })}
    </Drawer>
  );
};

export default MobileHeaderMenu;
