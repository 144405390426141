/* eslint-disable react/require-default-props */
import PlusIcon from "@assets/images/svg/plus_icon.svg?react";
import {
  FormCategoryTitle,
  FormIconAnnotation,
} from "@components/UI/atoms/Forms/FormComponents";
import V2OutlinedButton from "@components/UIv2/atoms/buttons/V2OutlinedButton";
import { attachmentMIMEtypes } from "@constants/file";
import { simpleSort } from "@lib/file-utils";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { fileSize } from "@root/constants/fileSize";
import React, { useRef, useState } from "react";

import { MaterialImageData } from "../../types";

const useStyles = makeStyles((theme) => ({
  sectionWrap: {
    margin: "1rem 0 1.5rem",
  },
  categoryTitle: {
    margin: "1rem 0 0.5rem 0",
  },
  chipWrap: {
    margin: "14px 0",
  },
  chip: {
    marginRight: "7px",
  },
}));

interface Props {
  materialImages: MaterialImageData[];
  updateMaterialImages: React.Dispatch<
    React.SetStateAction<MaterialImageData[]>
  >;
  removedImages?: string[];
  setRemovedImages?: React.Dispatch<React.SetStateAction<string[]>>;
  isOverCapacity: boolean;
}

const MaterialImageFields: React.FC<Props> = (props: Props) => {
  const {
    materialImages,
    updateMaterialImages,
    removedImages,
    setRemovedImages,
    isOverCapacity,
  } = props;
  const classes = useStyles();
  const imageInput = useRef<HTMLInputElement>(null);
  const [fileSizeError, setFileSizeError] = useState(false);

  const onChangeFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileSizeError(false);
    const target = e.target as HTMLInputElement;
    const files = target.files ? target.files : null;
    const fileDataToAdd = [];
    if (files) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > fileSize.materialImage.size) {
          setFileSizeError(true);
        } else {
          fileDataToAdd.push({
            id: null,
            file,
            fileUrl: null,
            title: file.name,
            type: file.type,
          });
        }
      }
      const sortedImages = simpleSort([...materialImages, ...fileDataToAdd]);
      updateMaterialImages(sortedImages);
    }
  };

  const onRemoveFile = (index: number) => {
    const imageData = materialImages[index];
    if (
      imageData.id &&
      typeof removedImages !== "undefined" &&
      typeof setRemovedImages !== "undefined"
    ) {
      setRemovedImages([...removedImages, imageData.id]);
    }
    const copy = [...materialImages];
    copy.splice(index, 1);
    updateMaterialImages(copy);
  };

  return (
    <div className={classes.sectionWrap}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormCategoryTitle>
          添付ファイル(xlsx/pptx/docx/jpeg/jpg/png/pdf)
        </FormCategoryTitle>
        <FormIconAnnotation>
          <InfoIcon fontSize="small" htmlColor="#475149" />
          <div
            style={{
              paddingLeft: "8px",
            }}
          >
            1ファイル100MB以内
          </div>
        </FormIconAnnotation>
      </Box>
      <Box className={classes.chipWrap}>
        {materialImages.map((item: MaterialImageData, index: number) => {
          return (
            <span key={item.id}>
              <Chip
                className={classes.chip}
                variant="outlined"
                label={item.title ? `${item.title.slice(0, 20)}...` : ""}
                onDelete={() => onRemoveFile(index)}
                color="primary"
              />
            </span>
          );
        })}
      </Box>
      <V2OutlinedButton
        disabled={isOverCapacity}
        onClick={() => imageInput.current?.click()}
        startIcon={<PlusIcon />}
      >
        添付ファイルを追加
      </V2OutlinedButton>
      <input
        accept={attachmentMIMEtypes.join(",")}
        multiple
        onChange={onChangeFileInput}
        type="file"
        ref={imageInput}
        style={{ display: "none" }}
      />
      {fileSizeError && (
        <Box>
          <Typography variant="caption" color="error">
            {fileSize.materialImage.message}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default MaterialImageFields;
