import { Api, GetTeacherSteamLectureCommentsParams } from "@lib/Api";
import { createAction,createAsyncThunk } from "@reduxjs/toolkit";
import { PostLectureParams } from "@root/types/Steam/lecture";
import { RootState } from "@store/store";

import { postSteamLectureAsTeacher } from "./steamLecture";

const api = new Api();

export const getSteamLibraryCommentAsTeacher = createAsyncThunk<
  any,
  GetTeacherSteamLectureCommentsParams & { userId: string },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/lecture/getComment",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.teacher.getTeacherSteamLectureComments(params);
      return { ...response.data, userId: params.userId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const postSteamLibraryCommentAsTeacher = createAsyncThunk<
  any,
  {
    lectureId: string;
    body: string;
    isPublic: boolean;
    newLecture?: PostLectureParams;
  },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/lecture/postComment",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const { lectureId, body, newLecture, isPublic } = params;
      if (newLecture && !lectureId) {
        await dispatch(postSteamLectureAsTeacher(newLecture));
      }
      const response = await api.teacher.postTeacherSteamComments(
        {
          lecture_id:
            lectureId || getState().teacherApp.steamLecture.lecture.id,
        },
        { is_public: isPublic, body },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSteamLibraryCommentAsTeacher = createAsyncThunk<
  any,
  { commentId: string; body: string; isPublic: boolean },
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/lecture/updateComment",
  async (params, { rejectWithValue }) => {
    const { commentId, body, isPublic } = params;
    try {
      const response = await api.teacher.putTeacherSteamContentsId(commentId, {
        is_public: isPublic,
        body,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSteamLibraryCommentAsTeacher = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/steamLibrary/lecture/deleteComment",
  async (commentId, { rejectWithValue }) => {
    try {
      const response =
        await api.teacher.deleteTeacherSteamContentsId(commentId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetChangeCommentState = createAction(
  "teacher/steamLibrary/lecture/resetChangeCommentState",
);

export const resetSteamLibraryCommentState = createAction(
  "teacher/steamLibrary/lecture/resetCommentState",
);
