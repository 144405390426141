import { baseApi as api } from "../baseApi";
export const addTagTypes = [
  "Users",
  "Student/Users",
  "Materials",
  "Student/Material",
  "MaterialWorks",
  "Payment",
  "Homework",
  "Student/HomeworkTemplate",
  "Student/Homework",
  "HomeworkTemplate",
  "Companies",
  "Student/Company",
  "HomeworkImage",
  "Report",
  "Categories",
  "Student/Notification",
  "Student/Article",
  "articles",
  "Student/ArticleComments",
  "Student/ArticleAffiliates",
  "Student/ArticleImages",
  "Student/PblReportComment",
  "Student/PblReportAnswer",
  "Student/PblReportAnswerImages",
  "Student/PblReportAnswerItem",
  "Student/PblCardsInformationLiteracyBookmark",
  "Student/PblCardsInformationLiteracyBookmarkImage",
  "Student/PblCardsInformationLiteracyComment",
  "Teacher/PblCardsInformationLiteracyComment",
  "Student/Stamp",
  "Student/GoogleIntegrations",
  "Student/Steam/Contents",
  "Student/Steam/Lectures",
  "Student/Steam/Comments",
  "Student/Steam/ContentWorks",
  "Student/Steam/LectureHistories",
  "Student/Steam/ContentRecommendations",
  "Student/HomeworkTemplateCustomValues",
  "Student/Plan/Items",
  "Student/Plan/Classes",
  "Student/Inquiries",
  "Student/InquiryComments",
  "Student/Sponsor/Infos",
  "Student/Sponsor/Projects",
  "Student/Sponsor/Lectures",
  "Student/PblSponsorProjectWork",
  "Student/SponsorProjectWork",
  "Student/Sponsor/Contents",
  "Student/Controllers",
  "Student/PblSponsorComment",
  "Student/Sponsor/ArticleContentTemplate",
  "Student/Todos",
  "Student/DailyLog",
  "Student/StudyLog",
  "Student/LearningPackages",
  "Student/IdealBoards",
  "Student/IdealBoard",
  "Student/BoardAffiliates",
  "Student/GroupAffiliatePackages",
  "Student/AttachedImages",
  "Student/LimitedTimeNotifications",
  "AllowedGroupAffiliates",
  "Student/LearningProgress",
  "Student/Interview/Request",
  "Student/Interview/Message",
  "Student/Interview/Feedback",
  "Student/Help/Contents",
  "Student/CustomMaster",
  "Student/Chat/Room",
  "Student/Chat/RoomMessage",
  "Student/CommonTags",
  "Student/CommonTaggings",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getUserStudentsAsStudent: build.query<
        GetUserStudentsAsStudentApiResponse,
        GetUserStudentsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/users/students`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Users"],
      }),
      getUserStudentAffiliatesAsStudent: build.query<
        GetUserStudentAffiliatesAsStudentApiResponse,
        GetUserStudentAffiliatesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/users/teacher_affiliates`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Users"],
      }),
      getStudentUsersByUserId: build.query<
        GetStudentUsersByUserIdApiResponse,
        GetStudentUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/users/${queryArg.userId}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Users"],
      }),
      putStudentUsersByUserId: build.mutation<
        PutStudentUsersByUserIdApiResponse,
        PutStudentUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/users/${queryArg.userId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Student/Users"],
      }),
      deleteStudentUsersByUserId: build.mutation<
        DeleteStudentUsersByUserIdApiResponse,
        DeleteStudentUsersByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/users/${queryArg.userId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Student/Users"],
      }),
      getStudentMaterials: build.query<
        GetStudentMaterialsApiResponse,
        GetStudentMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/materials`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            category_ids: queryArg.categoryIds,
            tag_names: queryArg.tagNames,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            search_column: queryArg.searchColumn,
            search_order: queryArg.searchOrder,
          },
        }),
        providesTags: ["Materials"],
      }),
      getStudentMaterialsByMaterialId: build.query<
        GetStudentMaterialsByMaterialIdApiResponse,
        GetStudentMaterialsByMaterialIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/materials/${queryArg.materialId}`,
        }),
        providesTags: ["Student/Material"],
      }),
      getStudentMaterialWorks: build.query<
        GetStudentMaterialWorksApiResponse,
        GetStudentMaterialWorksApiArg
      >({
        query: (queryArg) => ({
          url: `/student/material_works`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            status: queryArg.status,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            category_id: queryArg.categoryId,
            tag_id: queryArg.tagId,
            order: queryArg.order,
          },
        }),
        providesTags: ["MaterialWorks"],
      }),
      fetchOneMaterialWorkAsStudent: build.query<
        FetchOneMaterialWorkAsStudentApiResponse,
        FetchOneMaterialWorkAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/material_works/${queryArg.materialWorkId}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["MaterialWorks"],
      }),
      putStudentMaterialWorksByMaterialWorkId: build.mutation<
        PutStudentMaterialWorksByMaterialWorkIdApiResponse,
        PutStudentMaterialWorksByMaterialWorkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/material_works/${queryArg.materialWorkId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["MaterialWorks"],
      }),
      postStudentMaterialWorksByMaterialWorkIdUploadImages: build.mutation<
        PostStudentMaterialWorksByMaterialWorkIdUploadImagesApiResponse,
        PostStudentMaterialWorksByMaterialWorkIdUploadImagesApiArg
      >({
        query: (queryArg) => ({
          url: `/student/material_works/${queryArg.materialWorkId}/upload_images`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["MaterialWorks"],
      }),
      postStudentMaterialWorksByMaterialWorkIdSaveImage: build.mutation<
        PostStudentMaterialWorksByMaterialWorkIdSaveImageApiResponse,
        PostStudentMaterialWorksByMaterialWorkIdSaveImageApiArg
      >({
        query: (queryArg) => ({
          url: `/student/material_works/${queryArg.materialWorkId}/save_image`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["MaterialWorks"],
      }),
      putStudentMaterialWorksByMaterialWorkIdUpdateImage: build.mutation<
        PutStudentMaterialWorksByMaterialWorkIdUpdateImageApiResponse,
        PutStudentMaterialWorksByMaterialWorkIdUpdateImageApiArg
      >({
        query: (queryArg) => ({
          url: `/student/material_works/${queryArg.materialWorkId}/update_image`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["MaterialWorks"],
      }),
      deleteMaterialWorkImageAsStudent: build.mutation<
        DeleteMaterialWorkImageAsStudentApiResponse,
        DeleteMaterialWorkImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/material_works/${queryArg.materialWorkId}/delete_image`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { image_id: queryArg.imageId },
        }),
        invalidatesTags: ["MaterialWorks"],
      }),
      getMaterialWorksCategoryListAsStudent: build.query<
        GetMaterialWorksCategoryListAsStudentApiResponse,
        GetMaterialWorksCategoryListAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/material_works/category_list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["MaterialWorks"],
      }),
      getMaterialWorksTagListAsStudent: build.query<
        GetMaterialWorksTagListAsStudentApiResponse,
        GetMaterialWorksTagListAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/material_works/tag_list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["MaterialWorks"],
      }),
      getStudentPayments: build.query<
        GetStudentPaymentsApiResponse,
        GetStudentPaymentsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/payments`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Payment"],
      }),
      getStudentPaymentsByPaymentId: build.query<
        GetStudentPaymentsByPaymentIdApiResponse,
        GetStudentPaymentsByPaymentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/payments/${queryArg.paymentId}`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Payment"],
      }),
      putStudentPaymentsByPaymentId: build.mutation<
        PutStudentPaymentsByPaymentIdApiResponse,
        PutStudentPaymentsByPaymentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/payments/${queryArg.paymentId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Payment"],
      }),
      getStudentHomeworks: build.query<
        GetStudentHomeworksApiResponse,
        GetStudentHomeworksApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homeworks`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            common_tag_name: queryArg.commonTagName,
          },
        }),
        providesTags: ["Homework"],
      }),
      getStudentHomeworksByHomeworkId: build.query<
        GetStudentHomeworksByHomeworkIdApiResponse,
        GetStudentHomeworksByHomeworkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homeworks/${queryArg.homeworkId}`,
          headers: {
            client: queryArg.client,
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Homework"],
      }),
      putStudentHomeworksByHomeworkId: build.mutation<
        PutStudentHomeworksByHomeworkIdApiResponse,
        PutStudentHomeworksByHomeworkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homeworks/${queryArg.homeworkId}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Homework"],
      }),
      uploadHomeworkImagesAsStudent: build.mutation<
        UploadHomeworkImagesAsStudentApiResponse,
        UploadHomeworkImagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homeworks/${queryArg.homeworkId}/upload_images`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Student/HomeworkTemplate",
          "Student/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      saveEditedHomeworkImageAsStudent: build.mutation<
        SaveEditedHomeworkImageAsStudentApiResponse,
        SaveEditedHomeworkImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homeworks/${queryArg.homeworkId}/save_image`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Student/HomeworkTemplate",
          "Student/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      updateHomeworkImageAsStudent: build.mutation<
        UpdateHomeworkImageAsStudentApiResponse,
        UpdateHomeworkImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homeworks/${queryArg.homeworkId}/update_image`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Student/HomeworkTemplate",
          "Student/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      deleteHomeworkImageAsStudent: build.mutation<
        DeleteHomeworkImageAsStudentApiResponse,
        DeleteHomeworkImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homeworks/${queryArg.homeworkId}/delete_image`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: [
          "Student/HomeworkTemplate",
          "Student/Homework",
          "Homework",
          "HomeworkTemplate",
        ],
      }),
      getHomeworkStatusesAsStudent: build.query<
        GetHomeworkStatusesAsStudentApiResponse,
        GetHomeworkStatusesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homeworks/status`,
          headers: {
            client: queryArg.client,
            uid: queryArg.uid,
            "access-token": queryArg["access-token"],
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            homework_ids: queryArg.homeworkIds,
          },
        }),
        providesTags: ["Homework"],
      }),
      getStudentCompaniesByCompanyId: build.query<
        GetStudentCompaniesByCompanyIdApiResponse,
        GetStudentCompaniesByCompanyIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/companies/${queryArg.companyId}`,
        }),
        providesTags: ["Companies"],
      }),
      getCompanyLimitOptionAsStudent: build.query<
        GetCompanyLimitOptionAsStudentApiResponse,
        GetCompanyLimitOptionAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/companies/${queryArg.id}/limit_option`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Company"],
      }),
      saveTempDataOfHomeworkImageAsStudent: build.mutation<
        SaveTempDataOfHomeworkImageAsStudentApiResponse,
        SaveTempDataOfHomeworkImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homework_images/save_temp_data`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["HomeworkImage"],
      }),
      deleteTempDataOfHomeworkImageAsStudent: build.mutation<
        DeleteTempDataOfHomeworkImageAsStudentApiResponse,
        DeleteTempDataOfHomeworkImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homework_images/${queryArg.id}/delete_temp_data`,
          method: "PATCH",
        }),
        invalidatesTags: ["HomeworkImage"],
      }),
      getStudentReports: build.query<
        GetStudentReportsApiResponse,
        GetStudentReportsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/reports`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Report"],
      }),
      getStudentReportsByReportId: build.query<
        GetStudentReportsByReportIdApiResponse,
        GetStudentReportsByReportIdApiArg
      >({
        query: (queryArg) => ({ url: `/student/reports/${queryArg.reportId}` }),
        providesTags: ["Report"],
      }),
      getStudentCategories: build.query<
        GetStudentCategoriesApiResponse,
        GetStudentCategoriesApiArg
      >({
        query: () => ({ url: `/student/categories` }),
        providesTags: ["Categories"],
      }),
      getStudentNotifications: build.query<
        GetStudentNotificationsApiResponse,
        GetStudentNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/notifications`,
          headers: {
            "access-token": queryArg["access-token"],
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            sent: queryArg.sent,
            per_page: queryArg.perPage,
            page: queryArg.page,
          },
        }),
        providesTags: ["Student/Notification"],
      }),
      getStudentArticles: build.query<
        GetStudentArticlesApiResponse,
        GetStudentArticlesApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            per_page: queryArg.perPage,
            page: queryArg.page,
            kind: queryArg.kind,
            status: queryArg.status,
            group_affiliate_package_id: queryArg.groupAffiliatePackageId,
            order_column: queryArg.orderColumn,
            order_type: queryArg.orderType,
            get_type: queryArg.getType,
            common_tag_name: queryArg.commonTagName,
          },
        }),
        providesTags: ["Student/Article"],
      }),
      postStudentArticles: build.mutation<
        PostStudentArticlesApiResponse,
        PostStudentArticlesApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        invalidatesTags: ["articles", "Student/Article"],
      }),
      getStudentArticlesByArticleId: build.query<
        GetStudentArticlesByArticleIdApiResponse,
        GetStudentArticlesByArticleIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
        }),
        providesTags: ["Student/Article", "articles"],
      }),
      putStudentArticlesByArticleId: build.mutation<
        PutStudentArticlesByArticleIdApiResponse,
        PutStudentArticlesByArticleIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["articles", "Student/Article"],
      }),
      deleteStudentArticlesByArticleId: build.mutation<
        DeleteStudentArticlesByArticleIdApiResponse,
        DeleteStudentArticlesByArticleIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["articles", "Student/Article"],
      }),
      postStudentArticlesByArticleIdSubmitToTeacher: build.mutation<
        PostStudentArticlesByArticleIdSubmitToTeacherApiResponse,
        PostStudentArticlesByArticleIdSubmitToTeacherApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}/submit_to_teacher`,
          method: "POST",
        }),
        invalidatesTags: ["articles", "Student/Article"],
      }),
      getStudentArticlesByArticleIdComments: build.query<
        GetStudentArticlesByArticleIdCommentsApiResponse,
        GetStudentArticlesByArticleIdCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}/comments`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Student/Article"],
      }),
      postArticleCommentsAsStudent: build.mutation<
        PostArticleCommentsAsStudentApiResponse,
        PostArticleCommentsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}/comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/ArticleComments"],
      }),
      putArticleCommentAsStudent: build.mutation<
        PutArticleCommentAsStudentApiResponse,
        PutArticleCommentAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/ArticleComments"],
      }),
      deleteArticleCommentAsStudent: build.mutation<
        DeleteArticleCommentAsStudentApiResponse,
        DeleteArticleCommentAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}/comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/ArticleComments"],
      }),
      getStudentArticlesByArticleIdFeedbacks: build.query<
        GetStudentArticlesByArticleIdFeedbacksApiResponse,
        GetStudentArticlesByArticleIdFeedbacksApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}/feedbacks`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Student/Article"],
      }),
      getStudentArticleReviewerCompanies: build.query<
        GetStudentArticleReviewerCompaniesApiResponse,
        GetStudentArticleReviewerCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/student/article/reviewer_companies`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Article"],
      }),
      postArticleAffiliatesAsStudent: build.mutation<
        PostArticleAffiliatesAsStudentApiResponse,
        PostArticleAffiliatesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/article_affiliates`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/ArticleAffiliates"],
      }),
      deleteArticleAffiliatesAsStudent: build.mutation<
        DeleteArticleAffiliatesAsStudentApiResponse,
        DeleteArticleAffiliatesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/article_affiliates`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/ArticleAffiliates"],
      }),
      fetchArticleStatsAsStudent: build.query<
        FetchArticleStatsAsStudentApiResponse,
        FetchArticleStatsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/stats`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Student/Article"],
      }),
      fetchArticleWordCloudAsStudent: build.query<
        FetchArticleWordCloudAsStudentApiResponse,
        FetchArticleWordCloudAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/word_cloud`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            list_len: queryArg.listLen,
            offset: queryArg.offset,
            min_num_of_char: queryArg.minNumOfChar,
          },
        }),
        providesTags: ["Student/Article"],
      }),
      postArticleImagesAsStudent: build.mutation<
        PostArticleImagesAsStudentApiResponse,
        PostArticleImagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}/article_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/ArticleImages"],
      }),
      putArticleImagesAsStudent: build.mutation<
        PutArticleImagesAsStudentApiResponse,
        PutArticleImagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}/article_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/ArticleImages"],
      }),
      deleteControllerAsStudent: build.mutation<
        DeleteControllerAsStudentApiResponse,
        DeleteControllerAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/articles/${queryArg.articleId}/article_images/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/ArticleImages"],
      }),
      putStudentPblReportCommentsById: build.mutation<
        PutStudentPblReportCommentsByIdApiResponse,
        PutStudentPblReportCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/comments/${queryArg.id}`,
          method: "PUT",
        }),
        invalidatesTags: ["Student/PblReportComment"],
      }),
      getStudentPblReportComments: build.query<
        GetStudentPblReportCommentsApiResponse,
        GetStudentPblReportCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/comments`,
          params: {
            output_id: queryArg.outputId,
            output_type: queryArg.outputType,
          },
        }),
        providesTags: ["Student/PblReportComment"],
      }),
      postStudentPblReportComments: build.mutation<
        PostStudentPblReportCommentsApiResponse,
        PostStudentPblReportCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/comments`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Student/PblReportComment"],
      }),
      fetchPblReportAnswersAsStudent: build.query<
        FetchPblReportAnswersAsStudentApiResponse,
        FetchPblReportAnswersAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/answers`,
          params: { per_page: queryArg.perPage, page: queryArg.page },
        }),
        providesTags: ["Student/PblReportAnswer"],
      }),
      updatePblReportAnswerAsStudent: build.mutation<
        UpdatePblReportAnswerAsStudentApiResponse,
        UpdatePblReportAnswerAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/answers/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Student/PblReportAnswer"],
      }),
      fetchPblReportAnswerAsStudent: build.query<
        FetchPblReportAnswerAsStudentApiResponse,
        FetchPblReportAnswerAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/answers/${queryArg.id}`,
        }),
        providesTags: ["Student/PblReportAnswer"],
      }),
      postPblReportAnswerImagesAsStudent: build.mutation<
        PostPblReportAnswerImagesAsStudentApiResponse,
        PostPblReportAnswerImagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/answer_images`,
          method: "POST",
          body: queryArg.pblReportAnswerImageRequest,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/PblReportAnswerImages"],
      }),
      putPblReportAnswerImagesAsStudent: build.mutation<
        PutPblReportAnswerImagesAsStudentApiResponse,
        PutPblReportAnswerImagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/answer_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.pblReportAnswerImageRequest,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/PblReportAnswerImages"],
      }),
      deletePblReportAnswerImagesAsStudent: build.mutation<
        DeletePblReportAnswerImagesAsStudentApiResponse,
        DeletePblReportAnswerImagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/answer_images/${queryArg.id}`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/PblReportAnswerImages"],
      }),
      updatePblReportAnswerItemAsStudent: build.mutation<
        UpdatePblReportAnswerItemAsStudentApiResponse,
        UpdatePblReportAnswerItemAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/answer_items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Student/PblReportAnswerItem"],
      }),
      createPblReportAnswerItemAsStudent: build.mutation<
        CreatePblReportAnswerItemAsStudentApiResponse,
        CreatePblReportAnswerItemAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/report/answer_items`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Student/PblReportAnswerItem"],
      }),
      fetchPblCardBookmarksAsStudent: build.query<
        FetchPblCardBookmarksAsStudentApiResponse,
        FetchPblCardBookmarksAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/bookmarks`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            group_affiliate_id: queryArg.groupAffiliateId,
            create_self: queryArg.createSelf,
            search_source_type: queryArg.searchSourceType,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            common_tag_name: queryArg.commonTagName,
          },
        }),
        providesTags: ["Student/PblCardsInformationLiteracyBookmark"],
      }),
      createPblCardBookmarkAsStudent: build.mutation<
        CreatePblCardBookmarkAsStudentApiResponse,
        CreatePblCardBookmarkAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/bookmarks`,
          method: "POST",
          body: queryArg.postPblCardBookmarkRequestBody,
        }),
        invalidatesTags: ["Student/PblCardsInformationLiteracyBookmark"],
      }),
      fetchPblCardBookmarkAsStudent: build.query<
        FetchPblCardBookmarkAsStudentApiResponse,
        FetchPblCardBookmarkAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/bookmarks/${queryArg.id}`,
        }),
        providesTags: ["Student/PblCardsInformationLiteracyBookmark"],
      }),
      updatePblCardBookmarkAsStudent: build.mutation<
        UpdatePblCardBookmarkAsStudentApiResponse,
        UpdatePblCardBookmarkAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/bookmarks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.putPblCardBookmarkRequestBody,
        }),
        invalidatesTags: ["Student/PblCardsInformationLiteracyBookmark"],
      }),
      deletePblCardBookmarkAsStudent: build.mutation<
        DeletePblCardBookmarkAsStudentApiResponse,
        DeletePblCardBookmarkAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/bookmarks/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Student/PblCardsInformationLiteracyBookmark"],
      }),
      updatePblCardBookmarkWysiwygBodyAsStudent: build.mutation<
        UpdatePblCardBookmarkWysiwygBodyAsStudentApiResponse,
        UpdatePblCardBookmarkWysiwygBodyAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/bookmarks/${queryArg.id}/update_wysiwyg_body`,
          method: "PUT",
          body: queryArg.schema,
        }),
        invalidatesTags: ["Student/PblCardsInformationLiteracyBookmark"],
      }),
      deletePblCardBookmarkImageAsStudent: build.mutation<
        DeletePblCardBookmarkImageAsStudentApiResponse,
        DeletePblCardBookmarkImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/bookmark_images/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Student/PblCardsInformationLiteracyBookmarkImage"],
      }),
      fetchPblCardBookmarkCommentsAsStudent: build.query<
        FetchPblCardBookmarkCommentsAsStudentApiResponse,
        FetchPblCardBookmarkCommentsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/comments`,
          params: {
            bookmark_id: queryArg.bookmarkId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Student/PblCardsInformationLiteracyComment"],
      }),
      createPblCardBookmarkCommentAsStudent: build.mutation<
        CreatePblCardBookmarkCommentAsStudentApiResponse,
        CreatePblCardBookmarkCommentAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/comments`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Student/PblCardsInformationLiteracyComment"],
      }),
      updatePblCardBookmarkCommentAsStudent: build.mutation<
        UpdatePblCardBookmarkCommentAsStudentApiResponse,
        UpdatePblCardBookmarkCommentAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Student/PblCardsInformationLiteracyComment"],
      }),
      deletePblCardBookmarkCommentAsStudent: build.mutation<
        DeletePblCardBookmarkCommentAsStudentApiResponse,
        DeletePblCardBookmarkCommentAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/cards/information_literacy/comments/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Teacher/PblCardsInformationLiteracyComment"],
      }),
      getPblCardBookmarkStampsAsStudent: build.query<
        GetPblCardBookmarkStampsAsStudentApiResponse,
        GetPblCardBookmarkStampsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/stamps`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            stampable_type: queryArg.stampableType,
            stampable_id: queryArg.stampableId,
            kind: queryArg.kind,
          },
        }),
        providesTags: ["Student/Stamp"],
      }),
      postPblCardBookmarkStampAsStudent: build.mutation<
        PostPblCardBookmarkStampAsStudentApiResponse,
        PostPblCardBookmarkStampAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/stamps`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Stamp"],
      }),
      putPblCardBookmarkStampAsStudent: build.mutation<
        PutPblCardBookmarkStampAsStudentApiResponse,
        PutPblCardBookmarkStampAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/stamps/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Student/Stamp"],
      }),
      deletePblCardBookmarkStampAsStudent: build.mutation<
        DeletePblCardBookmarkStampAsStudentApiResponse,
        DeletePblCardBookmarkStampAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/stamps/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Student/Stamp"],
      }),
      getStudentGoogleIntegrations: build.query<
        GetStudentGoogleIntegrationsApiResponse,
        GetStudentGoogleIntegrationsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/google_integrations`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/GoogleIntegrations"],
      }),
      getStudentGoogleIntegrationsByCourseId: build.query<
        GetStudentGoogleIntegrationsByCourseIdApiResponse,
        GetStudentGoogleIntegrationsByCourseIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/google_integrations/${queryArg.courseId}`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/GoogleIntegrations"],
      }),
      getStudentGoogleIntegrationsByCourseIdAndCourseWorkId: build.query<
        GetStudentGoogleIntegrationsByCourseIdAndCourseWorkIdApiResponse,
        GetStudentGoogleIntegrationsByCourseIdAndCourseWorkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/google_integrations/${queryArg.courseId}/${queryArg.courseWorkId}`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/GoogleIntegrations"],
      }),
      getSteamContentsAsStudent: build.query<
        GetSteamContentsAsStudentApiResponse,
        GetSteamContentsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/contents`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            is_bookmarked: queryArg.isBookmarked,
            is_recommended: queryArg.isRecommended,
          },
        }),
        providesTags: ["Student/Steam/Contents"],
      }),
      getStudentSteamContentsId: build.query<
        GetStudentSteamContentsIdApiResponse,
        GetStudentSteamContentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/contents/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Steam/Contents"],
      }),
      searchStudentSteamContents: build.query<
        SearchStudentSteamContentsApiResponse,
        SearchStudentSteamContentsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/contents/search`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            difficulty: queryArg.difficulty,
            aspect: queryArg.aspect,
            is_scientific: queryArg.isScientific,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Student/Steam/Contents"],
      }),
      postStudentSteamLectures: build.mutation<
        PostStudentSteamLecturesApiResponse,
        PostStudentSteamLecturesApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/lectures`,
          method: "POST",
          body: queryArg.createSteamLectureRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { sl_content_id: queryArg.slContentId },
        }),
        invalidatesTags: ["Student/Steam/Lectures"],
      }),
      getStudentSteamLecturesId: build.query<
        GetStudentSteamLecturesIdApiResponse,
        GetStudentSteamLecturesIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/lectures/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Steam/Lectures"],
      }),
      getStudentSteamLectureComments: build.query<
        GetStudentSteamLectureCommentsApiResponse,
        GetStudentSteamLectureCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/lectures/${queryArg.id}/comments`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/Steam/Lectures"],
      }),
      getStudentSteamComments: build.query<
        GetStudentSteamCommentsApiResponse,
        GetStudentSteamCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/comments`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            lecture_id: queryArg.lectureId,
          },
        }),
        providesTags: ["Student/Steam/Comments"],
      }),
      postStudentSteamComments: build.mutation<
        PostStudentSteamCommentsApiResponse,
        PostStudentSteamCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Steam/Comments"],
      }),
      putStudentSteamContentsId: build.mutation<
        PutStudentSteamContentsIdApiResponse,
        PutStudentSteamContentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Steam/Comments"],
      }),
      deleteStudentSteamContentsId: build.mutation<
        DeleteStudentSteamContentsIdApiResponse,
        DeleteStudentSteamContentsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Steam/Comments"],
      }),
      getStudentSteamContentWorks: build.query<
        GetStudentSteamContentWorksApiResponse,
        GetStudentSteamContentWorksApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/content_works`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            content_title: queryArg.contentTitle,
            lecture_title: queryArg.lectureTitle,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Student/Steam/ContentWorks"],
      }),
      postStudentSteamContentWorks: build.mutation<
        PostStudentSteamContentWorksApiResponse,
        PostStudentSteamContentWorksApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/content_works`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { content_id: queryArg.contentId },
        }),
        invalidatesTags: ["Student/Steam/ContentWorks"],
      }),
      getStudentSteamContentWorksId: build.query<
        GetStudentSteamContentWorksIdApiResponse,
        GetStudentSteamContentWorksIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/content_works/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Steam/ContentWorks"],
      }),
      putStudentSteamContentWorksId: build.mutation<
        PutStudentSteamContentWorksIdApiResponse,
        PutStudentSteamContentWorksIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/content_works/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Steam/ContentWorks"],
      }),
      getSteamLectureHistoriesAsStudent: build.query<
        GetSteamLectureHistoriesAsStudentApiResponse,
        GetSteamLectureHistoriesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/lecture_histories`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/Steam/LectureHistories"],
      }),
      postSteamLectureHistoriesAsStudent: build.mutation<
        PostSteamLectureHistoriesAsStudentApiResponse,
        PostSteamLectureHistoriesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/lecture_histories`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Steam/LectureHistories"],
      }),
      fetchSteamLectureHistoryStatsAsStudent: build.query<
        FetchSteamLectureHistoryStatsAsStudentApiResponse,
        FetchSteamLectureHistoryStatsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/lecture_histories/stats`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/Steam/LectureHistories"],
      }),
      fetchSteamLectureHistoryCountAsStudent: build.query<
        FetchSteamLectureHistoryCountAsStudentApiResponse,
        FetchSteamLectureHistoryCountAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/lecture_histories/count`,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { sl_content_ids: queryArg.slContentIds },
        }),
        providesTags: ["Student/Steam/LectureHistories"],
      }),
      deleteSteamLectureHistoryAsStudent: build.mutation<
        DeleteSteamLectureHistoryAsStudentApiResponse,
        DeleteSteamLectureHistoryAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/lecture_histories/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Steam/LectureHistories"],
      }),
      getSteamContentRecommendationsAsStudent: build.query<
        GetSteamContentRecommendationsAsStudentApiResponse,
        GetSteamContentRecommendationsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/steam/content_recommendations`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/Steam/ContentRecommendations"],
      }),
      postStudentHomeworkTemplateCustomValues: build.mutation<
        PostStudentHomeworkTemplateCustomValuesApiResponse,
        PostStudentHomeworkTemplateCustomValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homework_template_custom_values`,
          method: "POST",
          body: queryArg.customValueRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            homework_template_id: queryArg.homeworkTemplateId,
            homework_template_custom_field_id:
              queryArg.homeworkTemplateCustomFieldId,
          },
        }),
        invalidatesTags: ["Student/HomeworkTemplateCustomValues"],
      }),
      putStudentHomeworkTemplateCustomValuesById: build.mutation<
        PutStudentHomeworkTemplateCustomValuesByIdApiResponse,
        PutStudentHomeworkTemplateCustomValuesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homework_template_custom_values/${queryArg.id}`,
          method: "PUT",
          body: queryArg.customValueRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/HomeworkTemplateCustomValues"],
      }),
      deleteStudentHomeworkTemplateCustomValuesById: build.mutation<
        DeleteStudentHomeworkTemplateCustomValuesByIdApiResponse,
        DeleteStudentHomeworkTemplateCustomValuesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/homework_template_custom_values/${queryArg.id}`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/HomeworkTemplateCustomValues"],
      }),
      getPblPlanItemsAsStudent: build.query<
        GetPblPlanItemsAsStudentApiResponse,
        GetPblPlanItemsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/plan/items`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/Plan/Items"],
      }),
      getPblPlanClassesAsStudent: build.query<
        GetPblPlanClassesAsStudentApiResponse,
        GetPblPlanClassesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/plan/items/${queryArg.itemId}/classes`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ["Student/Plan/Classes"],
      }),
      getPblPlanContentsAsStudent: build.query<
        GetPblPlanContentsAsStudentApiResponse,
        GetPblPlanContentsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/pbl/plan/items/${queryArg.itemId}/classes/${queryArg.classId}/contents`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/Plan/Classes"],
      }),
      getStudentInquiries: build.query<
        GetStudentInquiriesApiResponse,
        GetStudentInquiriesApiArg
      >({
        query: (queryArg) => ({
          url: `/student/inquiries`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/Inquiries"],
      }),
      getStudentInquiriesById: build.query<
        GetStudentInquiriesByIdApiResponse,
        GetStudentInquiriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/inquiries/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Inquiries"],
      }),
      getTheNumberOfNewCommentInquiriesAsStudent: build.query<
        GetTheNumberOfNewCommentInquiriesAsStudentApiResponse,
        GetTheNumberOfNewCommentInquiriesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/inquiries/new_comment_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Inquiries"],
      }),
      postStudentInquiryComments: build.mutation<
        PostStudentInquiryCommentsApiResponse,
        PostStudentInquiryCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/student/inquiry_comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Student/InquiryComments"],
      }),
      putStudentInquiryCommentsById: build.mutation<
        PutStudentInquiryCommentsByIdApiResponse,
        PutStudentInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/inquiry_comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Student/InquiryComments"],
      }),
      deleteStudentInquiryCommentsById: build.mutation<
        DeleteStudentInquiryCommentsByIdApiResponse,
        DeleteStudentInquiryCommentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/inquiry_comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { inquiry_id: queryArg.inquiryId },
        }),
        invalidatesTags: ["Student/InquiryComments"],
      }),
      getStudentSponsorInfos: build.query<
        GetStudentSponsorInfosApiResponse,
        GetStudentSponsorInfosApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/infos`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Student/Sponsor/Infos"],
      }),
      getStudentSponsorInfosId: build.query<
        GetStudentSponsorInfosIdApiResponse,
        GetStudentSponsorInfosIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/infos/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Sponsor/Infos"],
      }),
      fetchSponsorProjectsAsStudent: build.query<
        FetchSponsorProjectsAsStudentApiResponse,
        FetchSponsorProjectsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/projects`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            bookmarked: queryArg.bookmarked,
            recommended: queryArg.recommended,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            viewing: queryArg.viewing,
          },
        }),
        providesTags: ["Student/Sponsor/Projects"],
      }),
      fetchOneSponsorProjectAsStudent: build.query<
        FetchOneSponsorProjectAsStudentApiResponse,
        FetchOneSponsorProjectAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/projects/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Sponsor/Projects"],
      }),
      fetchSponsorProjectsForCarouselAsStudent: build.query<
        FetchSponsorProjectsForCarouselAsStudentApiResponse,
        FetchSponsorProjectsForCarouselAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/projects/carousel`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            number_of_projects: queryArg.numberOfProjects,
            sort_type: queryArg.sortType,
          },
        }),
        providesTags: ["Student/Sponsor/Projects"],
      }),
      fetchSponsorProjectStatsAsStudent: build.query<
        FetchSponsorProjectStatsAsStudentApiResponse,
        FetchSponsorProjectStatsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/projects/${queryArg.id}/stats`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Sponsor/Projects"],
      }),
      fetchSponsorLecturesAsStudent: build.query<
        FetchSponsorLecturesAsStudentApiResponse,
        FetchSponsorLecturesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/projects/lectures`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            project_id: queryArg.projectId,
          },
        }),
        providesTags: ["Student/Sponsor/Lectures"],
      }),
      fetchSponsorLectureAsStudent: build.query<
        FetchSponsorLectureAsStudentApiResponse,
        FetchSponsorLectureAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/projects/lectures/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Sponsor/Lectures"],
      }),
      postPblSponsorProjectWorkAsStudent: build.mutation<
        PostPblSponsorProjectWorkAsStudentApiResponse,
        PostPblSponsorProjectWorkAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/project_works`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/PblSponsorProjectWork"],
      }),
      putSponsorProjectWorkAsStudent: build.mutation<
        PutSponsorProjectWorkAsStudentApiResponse,
        PutSponsorProjectWorkAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/project_works/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/SponsorProjectWork"],
      }),
      getSponsorContentsAsStudent: build.query<
        GetSponsorContentsAsStudentApiResponse,
        GetSponsorContentsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/comments`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            lecture_id: queryArg.lectureId,
          },
        }),
        providesTags: ["Student/Sponsor/Contents"],
      }),
      postPblSponsorCommentAsStudent: build.mutation<
        PostPblSponsorCommentAsStudentApiResponse,
        PostPblSponsorCommentAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/comments`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { lecture_id: queryArg.lectureId },
        }),
        invalidatesTags: ["Student/Controllers"],
      }),
      putPblSponsorCommentAsStudent: build.mutation<
        PutPblSponsorCommentAsStudentApiResponse,
        PutPblSponsorCommentAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/comments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/PblSponsorComment"],
      }),
      deletePblSponsorCommentsStudent: build.mutation<
        DeletePblSponsorCommentsStudentApiResponse,
        DeletePblSponsorCommentsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/comments/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/PblSponsorComment"],
      }),
      getSponsorArticleContentTemplateAsStudent: build.query<
        GetSponsorArticleContentTemplateAsStudentApiResponse,
        GetSponsorArticleContentTemplateAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/sponsor/article_content_templates/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { sponsor_lecture_id: queryArg.sponsorLectureId },
        }),
        providesTags: ["Student/Sponsor/ArticleContentTemplate"],
      }),
      getTodosAsStudent: build.query<
        GetTodosAsStudentApiResponse,
        GetTodosAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/todos/`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ["Student/Todos"],
      }),
      getDailyLogsAsStudent: build.query<
        GetDailyLogsAsStudentApiResponse,
        GetDailyLogsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/daily_logs/`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            all: queryArg.all,
            from: queryArg["from"],
            to: queryArg.to,
            category: queryArg.category,
          },
        }),
        providesTags: ["Student/DailyLog"],
      }),
      postDailyLogAsStudent: build.mutation<
        PostDailyLogAsStudentApiResponse,
        PostDailyLogAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/daily_logs/`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/DailyLog"],
      }),
      getDailyLogAsStudent: build.query<
        GetDailyLogAsStudentApiResponse,
        GetDailyLogAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/daily_logs/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/DailyLog"],
      }),
      putDailyLogAsStudent: build.mutation<
        PutDailyLogAsStudentApiResponse,
        PutDailyLogAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/daily_logs/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/DailyLog"],
      }),
      deleteDailyLogAsStudent: build.mutation<
        DeleteDailyLogAsStudentApiResponse,
        DeleteDailyLogAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/daily_logs/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/DailyLog"],
      }),
      getStudyLogsAsStudent: build.query<
        GetStudyLogsAsStudentApiResponse,
        GetStudyLogsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            from: queryArg["from"],
            to: queryArg.to,
          },
        }),
        providesTags: ["Student/StudyLog"],
      }),
      postStudyLogAsStudent: build.mutation<
        PostStudyLogAsStudentApiResponse,
        PostStudyLogAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/StudyLog"],
      }),
      getStudyLogAsStudent: build.query<
        GetStudyLogAsStudentApiResponse,
        GetStudyLogAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/StudyLog"],
      }),
      putStudentStudyLogsById: build.mutation<
        PutStudentStudyLogsByIdApiResponse,
        PutStudentStudyLogsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/${queryArg.id}`,
          method: "PUT",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/StudyLog"],
      }),
      getStudyLogsDeliverableListAsStudent: build.query<
        GetStudyLogsDeliverableListAsStudentApiResponse,
        GetStudyLogsDeliverableListAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/deliverable_list`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Student/StudyLog"],
      }),
      getStudyLogsMaterialWorksAsStudent: build.query<
        GetStudyLogsMaterialWorksAsStudentApiResponse,
        GetStudyLogsMaterialWorksAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/material_works`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Student/StudyLog"],
      }),
      getStudyLogsHomeworksAsStudent: build.query<
        GetStudyLogsHomeworksAsStudentApiResponse,
        GetStudyLogsHomeworksAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/homeworks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Student/StudyLog"],
      }),
      getStudyLogsLearningPackagesAsStudent: build.query<
        GetStudyLogsLearningPackagesAsStudentApiResponse,
        GetStudyLogsLearningPackagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/learning_packages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Student/StudyLog"],
      }),
      getStudyLogsArticlesAsStudent: build.query<
        GetStudyLogsArticlesAsStudentApiResponse,
        GetStudyLogsArticlesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/articles`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Student/StudyLog"],
      }),
      getStudyLogsIdealsAsStudent: build.query<
        GetStudyLogsIdealsAsStudentApiResponse,
        GetStudyLogsIdealsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/ideals`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Student/StudyLog"],
      }),
      getStudyLogsBookmarksAsStudent: build.query<
        GetStudyLogsBookmarksAsStudentApiResponse,
        GetStudyLogsBookmarksAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/study_logs/bookmarks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            tag_name: queryArg.tagName,
          },
        }),
        providesTags: ["Student/StudyLog"],
      }),
      getLearningPackagesAsStudent: build.query<
        GetLearningPackagesAsStudentApiResponse,
        GetLearningPackagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/learning_packages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Student/LearningPackages"],
      }),
      getLearningPackageAsStudent: build.query<
        GetLearningPackageAsStudentApiResponse,
        GetLearningPackageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/learning_packages/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/LearningPackages"],
      }),
      putLearningPackageAsStudent: build.mutation<
        PutLearningPackageAsStudentApiResponse,
        PutLearningPackageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/learning_packages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/LearningPackages"],
      }),
      getIdealBoardsAsStudent: build.query<
        GetIdealBoardsAsStudentApiResponse,
        GetIdealBoardsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/ideal/boards`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            group_affiliate_id: queryArg.groupAffiliateId,
          },
        }),
        providesTags: ["Student/IdealBoards"],
      }),
      postIdealBoardsAsStudent: build.mutation<
        PostIdealBoardsAsStudentApiResponse,
        PostIdealBoardsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/ideal/boards`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/IdealBoards"],
      }),
      getIdealBoardAsStudent: build.query<
        GetIdealBoardAsStudentApiResponse,
        GetIdealBoardAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/ideal/boards/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/IdealBoard"],
      }),
      putIdealBoardAsStudent: build.mutation<
        PutIdealBoardAsStudentApiResponse,
        PutIdealBoardAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/ideal/boards/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/IdealBoard"],
      }),
      deleteIdealBoardAsStudent: build.mutation<
        DeleteIdealBoardAsStudentApiResponse,
        DeleteIdealBoardAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/ideal/boards/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/IdealBoard"],
      }),
      postIdealBoardsAffiliatesAsStudent: build.mutation<
        PostIdealBoardsAffiliatesAsStudentApiResponse,
        PostIdealBoardsAffiliatesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/ideal/boards_affiliates`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/BoardAffiliates"],
      }),
      deleteIdealBoardsAffiliatesAsStudent: build.mutation<
        DeleteIdealBoardsAffiliatesAsStudentApiResponse,
        DeleteIdealBoardsAffiliatesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/ideal/boards_affiliates`,
          method: "DELETE",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/BoardAffiliates"],
      }),
      getGroupAffiliatePackagesAsStudent: build.query<
        GetGroupAffiliatePackagesAsStudentApiResponse,
        GetGroupAffiliatePackagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/group_affiliate_packages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/GroupAffiliatePackages"],
      }),
      postAttachedImageAsStudent: build.mutation<
        PostAttachedImageAsStudentApiResponse,
        PostAttachedImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/attached_images`,
          method: "POST",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Student/AttachedImages"],
      }),
      getAttachedImageAsStudent: build.query<
        GetAttachedImageAsStudentApiResponse,
        GetAttachedImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/attached_images/${queryArg.id}`,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        providesTags: ["Student/AttachedImages"],
      }),
      putAttachedImageAsStudent: build.mutation<
        PutAttachedImageAsStudentApiResponse,
        PutAttachedImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/attached_images/${queryArg.id}`,
          method: "PUT",
          body: queryArg.fileRequiredRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Student/AttachedImages"],
      }),
      deleteAttachedImageAsStudent: build.mutation<
        DeleteAttachedImageAsStudentApiResponse,
        DeleteAttachedImageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/attached_images/${queryArg.id}`,
          method: "DELETE",
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Student/AttachedImages"],
      }),
      getLimitedTimeNotificationNoticeAsStudent: build.query<
        GetLimitedTimeNotificationNoticeAsStudentApiResponse,
        GetLimitedTimeNotificationNoticeAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/limited_time_notifications/notice`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/LimitedTimeNotifications"],
      }),
      getLimitedTimeNotificationNoticeDetailsAsStudent: build.query<
        GetLimitedTimeNotificationNoticeDetailsAsStudentApiResponse,
        GetLimitedTimeNotificationNoticeDetailsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/limited_time_notifications/${queryArg.id}/notice_details`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/LimitedTimeNotifications"],
      }),
      postLimitedTimeNotificationNoticeCheckAsStudent: build.mutation<
        PostLimitedTimeNotificationNoticeCheckAsStudentApiResponse,
        PostLimitedTimeNotificationNoticeCheckAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/limited_time_notifications/${queryArg.id}/notice_check`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/LimitedTimeNotifications"],
      }),
      patchAllowedGroupAffiliatesBatchUpdateAsStudent: build.mutation<
        PatchAllowedGroupAffiliatesBatchUpdateAsStudentApiResponse,
        PatchAllowedGroupAffiliatesBatchUpdateAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/allowed_group_affiliates/batch_update`,
          method: "PATCH",
          body: queryArg.body,
          headers: {
            "access-token": queryArg["access-token"],
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["AllowedGroupAffiliates"],
      }),
      postLearningProgressAsStudent: build.mutation<
        PostLearningProgressAsStudentApiResponse,
        PostLearningProgressAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/learning_progress`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/LearningProgress"],
      }),
      putLearningProgressAsStudent: build.mutation<
        PutLearningProgressAsStudentApiResponse,
        PutLearningProgressAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/learning_progress/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/LearningProgress"],
      }),
      getInterviewRequestsAsStudent: build.query<
        GetInterviewRequestsAsStudentApiResponse,
        GetInterviewRequestsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            status: queryArg.status,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Student/Interview/Request"],
      }),
      getInterviewRequestAsStudent: build.query<
        GetInterviewRequestAsStudentApiResponse,
        GetInterviewRequestAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.id}`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Interview/Request"],
      }),
      putInterviewRequestAsStudent: build.mutation<
        PutInterviewRequestAsStudentApiResponse,
        PutInterviewRequestAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Interview/Request"],
      }),
      getInterviewRequestArticleAsStudent: build.query<
        GetInterviewRequestArticleAsStudentApiResponse,
        GetInterviewRequestArticleAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.id}/article`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        providesTags: ["Student/Interview/Request"],
      }),
      getInterviewRequestUnreadCountsAsStudent: build.query<
        GetInterviewRequestUnreadCountsAsStudentApiResponse,
        GetInterviewRequestUnreadCountsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/unread_count`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { request_ids: queryArg.requestIds },
        }),
        providesTags: ["Student/Interview/Request"],
      }),
      getInterviewMessagesAsStudent: build.query<
        GetInterviewMessagesAsStudentApiResponse,
        GetInterviewMessagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.requestId}/messages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/Interview/Message"],
      }),
      postInterviewMessageAsStudent: build.mutation<
        PostInterviewMessageAsStudentApiResponse,
        PostInterviewMessageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.requestId}/messages`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Interview/Message"],
      }),
      getInterviewMessageReadAsStudent: build.mutation<
        GetInterviewMessageReadAsStudentApiResponse,
        GetInterviewMessageReadAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.requestId}/messages/read`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Interview/Message"],
      }),
      putInterviewMessageAsStudent: build.mutation<
        PutInterviewMessageAsStudentApiResponse,
        PutInterviewMessageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.requestId}/messages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Interview/Message"],
      }),
      deleteInterviewMessageAsStudent: build.mutation<
        DeleteInterviewMessageAsStudentApiResponse,
        DeleteInterviewMessageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.requestId}/messages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Interview/Message"],
      }),
      getInterviewFeedbacksAsStudent: build.query<
        GetInterviewFeedbacksAsStudentApiResponse,
        GetInterviewFeedbacksAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.requestId}/feedbacks`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ["Student/Interview/Feedback"],
      }),
      postInterviewRequestAsStudent: build.mutation<
        PostInterviewRequestAsStudentApiResponse,
        PostInterviewRequestAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/interview/requests/${queryArg.requestId}/feedbacks`,
          method: "POST",
          body: queryArg.body,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
        }),
        invalidatesTags: ["Student/Interview/Feedback"],
      }),
      fetchHelpContentsAsStudent: build.query<
        FetchHelpContentsAsStudentApiResponse,
        FetchHelpContentsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/help/contents`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            kind: queryArg.kind,
          },
        }),
        providesTags: ["Student/Help/Contents"],
      }),
      getCustomMastersAsStudent: build.query<
        GetCustomMastersAsStudentApiResponse,
        GetCustomMastersAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/custom_masters`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            custom_master_map_id: queryArg.customMasterMapId,
            custom_field_id: queryArg.customFieldId,
            filter_infos: queryArg.filterInfos,
          },
        }),
        providesTags: ["Student/CustomMaster"],
      }),
      getCustomMastersSelectListAsStudent: build.query<
        GetCustomMastersSelectListAsStudentApiResponse,
        GetCustomMastersSelectListAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/custom_masters/select_list`,
          headers: {
            access_token: queryArg.accessToken,
            uid: queryArg.uid,
            client: queryArg.client,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            custom_master_map_id: queryArg.customMasterMapId,
            filter_column_number: queryArg.filterColumnNumber,
            filter_cmmr_id: queryArg.filterCmmrId,
            search_word: queryArg.searchWord,
          },
        }),
        providesTags: ["Student/CustomMaster"],
      }),
      getChatRoomAsStudent: build.query<
        GetChatRoomAsStudentApiResponse,
        GetChatRoomAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/chat/rooms`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        providesTags: ["Student/Chat/Room"],
      }),
      postChatRoomAsStudent: build.mutation<
        PostChatRoomAsStudentApiResponse,
        PostChatRoomAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/chat/rooms`,
          method: "POST",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        invalidatesTags: ["Student/Chat/Room"],
      }),
      getChatRoomMessagesAsStudent: build.query<
        GetChatRoomMessagesAsStudentApiResponse,
        GetChatRoomMessagesAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/chat/rooms/room_messages`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            bookmark_id: queryArg.bookmarkId,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["Student/Chat/RoomMessage"],
      }),
      postChatRoomMessageAsStudent: build.mutation<
        PostChatRoomMessageAsStudentApiResponse,
        PostChatRoomMessageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/chat/rooms/room_messages`,
          method: "POST",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        invalidatesTags: ["Student/Chat/RoomMessage"],
      }),
      putChatRoomMessageAsStudent: build.mutation<
        PutChatRoomMessageAsStudentApiResponse,
        PutChatRoomMessageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/chat/rooms/room_messages/${queryArg.id}`,
          method: "PUT",
          body: queryArg.messageRequiredRequestBody,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        invalidatesTags: ["Student/Chat/RoomMessage"],
      }),
      deleteChatRoomMessageAsStudent: build.mutation<
        DeleteChatRoomMessageAsStudentApiResponse,
        DeleteChatRoomMessageAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/chat/rooms/room_messages/${queryArg.id}`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: { bookmark_id: queryArg.bookmarkId },
        }),
        invalidatesTags: ["Student/Chat/RoomMessage"],
      }),
      getCommonTagsAsStudent: build.query<
        GetCommonTagsAsStudentApiResponse,
        GetCommonTagsAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/common/tags`,
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            search_type: queryArg.searchType,
            search_word: queryArg.searchWord,
            kind: queryArg.kind,
            categorizable_type: queryArg.categorizableType,
          },
        }),
        providesTags: ["Student/CommonTags"],
      }),
      postCommonTaggingAsStudent: build.mutation<
        PostCommonTaggingAsStudentApiResponse,
        PostCommonTaggingAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/common/taggings`,
          method: "POST",
          body: queryArg.commonTaggingRequestBody,
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
          },
        }),
        invalidatesTags: ["Student/CommonTaggings"],
      }),
      deleteCommonTaggingAsStudent: build.mutation<
        DeleteCommonTaggingAsStudentApiResponse,
        DeleteCommonTaggingAsStudentApiArg
      >({
        query: (queryArg) => ({
          url: `/student/common/taggings`,
          method: "DELETE",
          headers: {
            access_token: queryArg.accessToken,
            client: queryArg.client,
            uid: queryArg.uid,
          },
          params: {
            categorizable_type: queryArg.categorizableType,
            categorizable_id: queryArg.categorizableId,
            common_tag_id: queryArg.commonTagId,
          },
        }),
        invalidatesTags: ["Student/CommonTaggings"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as studentApi };
export type GetUserStudentsAsStudentApiResponse = /** status 200 OK */ {
  students: AffiliateName[];
  total_count: number;
};
export type GetUserStudentsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetUserStudentAffiliatesAsStudentApiResponse =
  /** status 200 OK */ {
    teacher_affiliates: AffiliateBase[];
    total_count: number;
  };
export type GetUserStudentAffiliatesAsStudentApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  perPage?: string;
  page?: string;
};
export type GetStudentUsersByUserIdApiResponse =
  /** status 200 OK */ StudentProfile;
export type GetStudentUsersByUserIdApiArg = {
  userId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutStudentUsersByUserIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type PutStudentUsersByUserIdApiArg = {
  userId: string;
  body: {
    nickname?: string;
    avatar?: Blob;
    student_info_attributes?: StudentInfoAttributes;
  };
};
export type DeleteStudentUsersByUserIdApiResponse = unknown;
export type DeleteStudentUsersByUserIdApiArg = {
  userId: string;
};
export type GetStudentMaterialsApiResponse = /** status 200 OK */ {
  materials: MaterialList[];
  total_count: number;
};
export type GetStudentMaterialsApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
  categoryIds?: string[];
  tagNames?: string[];
  /** - 検索タイプを指定する
    - search_typeとsearch_wordは両方していする必要あり
    - title: タイトルから検索
    - description: 説明文から検索
    - tag: タグから検索 */
  searchType?: string;
  /** - search_typeとsearch_wordは両方していする必要あり
    - 検索ワードの部分一致で検索する */
  searchWord?: string;
  searchColumn?: MaterialSortedByColumn;
  searchOrder?: SortedByOrder;
};
export type GetStudentMaterialsByMaterialIdApiResponse =
  /** status 200 OK */ MaterialDetail;
export type GetStudentMaterialsByMaterialIdApiArg = {
  /** Material ID to retrieve detail */
  materialId: string;
};
export type GetStudentMaterialWorksApiResponse = /** status 200 OK */ {
  total_count: number;
  material_works: StudentMaterialWork[];
};
export type GetStudentMaterialWorksApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  page?: number;
  perPage?: number;
  /** 教材配信のstatus */
  status?: string;
  /** - 検索タイプを指定する
    - search_typeとsearch_wordは両方指定する必要あり
    - title: タイトルから検索
    - description: 説明文から検索
    - tag: タグから検索 */
  searchType?: string;
  /** - search_typeとsearch_wordは両方指定する必要あり
    - 検索ワードの部分一致で検索する */
  searchWord?: string;
  /** - カテゴリーのIDを指定する
    - text カテゴリーに関連する（子孫）するものを取得する */
  categoryId?: string;
  /** - タグのIDを指定する
    - タグIDに一致するものを取得する */
  tagId?: string;
  order?: SortedByMaterialWork;
};
export type FetchOneMaterialWorkAsStudentApiResponse =
  /** status 200 OK */ StudentMaterialWork;
export type FetchOneMaterialWorkAsStudentApiArg = {
  materialWorkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type PutStudentMaterialWorksByMaterialWorkIdApiResponse =
  /** status 200 OK */ StudentMaterialWork;
export type PutStudentMaterialWorksByMaterialWorkIdApiArg = {
  materialWorkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    material_works_id: string;
    status: MaterialWorkStatus;
  };
};
export type PostStudentMaterialWorksByMaterialWorkIdUploadImagesApiResponse =
  /** status 200 OK */ StudentMaterialWork;
export type PostStudentMaterialWorksByMaterialWorkIdUploadImagesApiArg = {
  materialWorkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_content_id: string;
    file: string;
  };
};
export type PostStudentMaterialWorksByMaterialWorkIdSaveImageApiResponse =
  /** status 200 OK */ StudentMaterialWork;
export type PostStudentMaterialWorksByMaterialWorkIdSaveImageApiArg = {
  materialWorkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_content_id: string;
    file: string;
  };
};
export type PutStudentMaterialWorksByMaterialWorkIdUpdateImageApiResponse =
  /** status 200 OK */ StudentMaterialWork;
export type PutStudentMaterialWorksByMaterialWorkIdUpdateImageApiArg = {
  materialWorkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_id: string;
    file: string;
  };
};
export type DeleteMaterialWorkImageAsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteMaterialWorkImageAsStudentApiArg = {
  materialWorkId: string;
  /** 画像ID */
  imageId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetMaterialWorksCategoryListAsStudentApiResponse =
  /** status 200 OK */ {
    category_list: CategoryListBase[];
  };
export type GetMaterialWorksCategoryListAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetMaterialWorksTagListAsStudentApiResponse = /** status 200 OK */ {
  tag_list: TagListBase[];
};
export type GetMaterialWorksTagListAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudentPaymentsApiResponse = /** status 200 OK */ {
  payments: Payment[];
  total_count: number;
};
export type GetStudentPaymentsApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  perPage?: number;
  page?: number;
};
export type GetStudentPaymentsByPaymentIdApiResponse =
  /** status 200 OK */ Payment;
export type GetStudentPaymentsByPaymentIdApiArg = {
  paymentId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
};
export type PutStudentPaymentsByPaymentIdApiResponse =
  /** status 200 OK */ Payment;
export type PutStudentPaymentsByPaymentIdApiArg = {
  paymentId: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
  body: {
    status?: PaymentStatus;
  };
};
export type GetStudentHomeworksApiResponse = /** status 200 OK */ {
  homeworks: HomeworkBase[];
  total_count: number;
};
export type GetStudentHomeworksApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  perPage?: number;
  page?: number;
  /** common_tag_nameのフィルタリング */
  commonTagName?: string;
};
export type GetStudentHomeworksByHomeworkIdApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type GetStudentHomeworksByHomeworkIdApiArg = {
  homeworkId: string;
  client?: string;
  "access-token"?: string;
  uid?: string;
};
export type PutStudentHomeworksByHomeworkIdApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type PutStudentHomeworksByHomeworkIdApiArg = {
  homeworkId: string;
  "access-token"?: string;
  client?: string;
  uid?: string;
  body: {
    status?: HomeworkStatus;
    student_comment?: string;
    student_homework_images?: Blob[];
    ideal_thumbnail_file?: Blob | null;
  };
};
export type UploadHomeworkImagesAsStudentApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type UploadHomeworkImagesAsStudentApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    files: string[];
  };
};
export type SaveEditedHomeworkImageAsStudentApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type SaveEditedHomeworkImageAsStudentApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    file: string;
  };
};
export type UpdateHomeworkImageAsStudentApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type UpdateHomeworkImageAsStudentApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_id: string;
    file: string;
  };
};
export type DeleteHomeworkImageAsStudentApiResponse =
  /** status 200 OK */ HomeworkDetails;
export type DeleteHomeworkImageAsStudentApiArg = {
  homeworkId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    image_id: string;
  };
};
export type GetHomeworkStatusesAsStudentApiResponse = /** status 200 OK */ {
  homeworks: HomeworkStatusBase[];
};
export type GetHomeworkStatusesAsStudentApiArg = {
  client?: string;
  uid?: string;
  "access-token"?: string;
  perPage?: number;
  page?: number;
  homeworkIds?: string[];
};
export type GetStudentCompaniesByCompanyIdApiResponse =
  /** status 200 OK */ CompanyAsStudent;
export type GetStudentCompaniesByCompanyIdApiArg = {
  companyId: string;
};
export type GetCompanyLimitOptionAsStudentApiResponse = /** status 200 OK */ {
  limit_option: LimitOptionBase;
  total_count?: number;
};
export type GetCompanyLimitOptionAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  id: string;
};
export type SaveTempDataOfHomeworkImageAsStudentApiResponse =
  /** status 200 Saved the temp data */ HomeworkImage;
export type SaveTempDataOfHomeworkImageAsStudentApiArg = {
  body: {
    homework_template_image_id?: string | null;
    homework_image_id?: string | null;
    temp_data: string;
  };
};
export type DeleteTempDataOfHomeworkImageAsStudentApiResponse =
  /** status 200 Removed the temp data */ HomeworkImage;
export type DeleteTempDataOfHomeworkImageAsStudentApiArg = {
  id: string;
};
export type GetStudentReportsApiResponse = /** status 200 OK */ {
  reports: {
    id: string;
    teacher_name: string;
    created_at: string;
  }[];
  total_count: number;
};
export type GetStudentReportsApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
};
export type GetStudentReportsByReportIdApiResponse = /** status 200 OK */ {
  message_to_owner?: string;
  message_to_student?: string;
} & Report;
export type GetStudentReportsByReportIdApiArg = {
  reportId: string;
};
export type GetStudentCategoriesApiResponse = /** status 200 OK */ {
  grades: GradeCategoryInfo[];
  subjects: SubjectCategoryInfo[];
  major_categories: MajorCategoryInfo[];
  middle_categories: MiddleCategoryInfo[];
  minor_categories: MinorCategoryInfo[];
  texts?: TextCategoryInfo[];
};
export type GetStudentCategoriesApiArg = void;
export type GetStudentNotificationsApiResponse = /** status 200 OK */ {
  notifications?: (NotificationBase & {
    read?: boolean;
  })[];
  total_count?: number;
};
export type GetStudentNotificationsApiArg = {
  "access-token"?: string;
  uid?: string;
  client?: string;
  sent?: string;
  perPage?: number;
  page?: number;
};
export type GetStudentArticlesApiResponse = /** status 200 OK */ {
  articles: ArticleBase[];
  total_count: number;
};
export type GetStudentArticlesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  /** kindのフィルタリング */
  kind?: ArticleKind;
  /** statusのフィルタリング */
  status?: ArticleStatus;
  /** group_affiliate_package_idのフィルタリング */
  groupAffiliatePackageId?: string;
  /** order_columnとorder_typeは両方必須 */
  orderColumn?: ArticleOrderColumn;
  /** order_columnとorder_typeは両方必須 */
  orderType?: ArticleOrderType;
  getType?: ArticleGetType;
  /** common_tag_nameのフィルタリング */
  commonTagName?: string;
};
export type PostStudentArticlesApiResponse = /** status 201 created */ Article;
export type PostStudentArticlesApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  body: {
    title: string;
    body?: string;
    kind?: ArticleKind;
    cover?: string;
    sponsor_logo?: Blob;
    image_contents?: Blob[];
    add_group_affiliate_ids?: string[];
    add_or_update_categorizable_id?: string;
    add_or_update_categorizable_type?: AllowedArticleCategorizableType;
    is_article_sponsor_content?: boolean;
    pbl_sponsor_article_content_template_id?: string;
  };
};
export type GetStudentArticlesByArticleIdApiResponse =
  /** status 200 OK */ Article;
export type GetStudentArticlesByArticleIdApiArg = {
  articleId: string;
  accessToken?: string;
  uid?: string;
  client?: string;
};
export type PutStudentArticlesByArticleIdApiResponse =
  /** status 202 accepted */ Article;
export type PutStudentArticlesByArticleIdApiArg = {
  articleId: string;
  body: {
    title: string;
    body: string;
    updated_at_with_second: string;
    body_theme?: string;
    body_background?: string;
    body_hypothesis?: string;
    body_survey_method?: string;
    body_result?: string;
    body_summary?: string;
    body_future_issues?: string;
    cover?: string;
    delete_cover?: boolean;
    sponsor_logo?: Blob;
    add_group_affiliate_ids?: string[];
    remove_group_affiliate_ids?: string[];
    add_or_update_categorizable_id?: string;
    add_or_update_categorizable_type?: AllowedArticleCategorizableType;
    remove_categorizable_id?: string;
    remove_categorizable_type?: AllowedArticleCategorizableType;
    article_sponsor_contents?: {
      pbl_sponsor_article_content_template_field_id: string;
      body: string;
    }[];
  };
};
export type DeleteStudentArticlesByArticleIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteStudentArticlesByArticleIdApiArg = {
  articleId: string;
};
export type PostStudentArticlesByArticleIdSubmitToTeacherApiResponse =
  /** status 200 OK */ Article;
export type PostStudentArticlesByArticleIdSubmitToTeacherApiArg = {
  articleId: string;
};
export type GetStudentArticlesByArticleIdCommentsApiResponse =
  /** status 200 OK */ {
    article_comments: ArticleComment[];
    total_count: number;
  };
export type GetStudentArticlesByArticleIdCommentsApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  articleId: string;
};
export type PostArticleCommentsAsStudentApiResponse =
  /** status 201 Created */ ArticleComment;
export type PostArticleCommentsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  articleId: string;
  body: {
    body: string;
    comment_images?: Blob[];
  };
};
export type PutArticleCommentAsStudentApiResponse =
  /** status 202 Accepted */ ArticleComment;
export type PutArticleCommentAsStudentApiArg = {
  /** 探究成果物のID */
  articleId: string;
  /** 探究成果物のコメントID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
  };
};
export type DeleteArticleCommentAsStudentApiResponse = /** status 200 OK */ {
  message?: string;
};
export type DeleteArticleCommentAsStudentApiArg = {
  /** 探究成果物のID */
  articleId: string;
  /** 探究成果物のコメントID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudentArticlesByArticleIdFeedbacksApiResponse =
  /** status 200 OK */ {
    article_feedbacks: ArticleFeedback[];
    total_count: number;
  };
export type GetStudentArticlesByArticleIdFeedbacksApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
  articleId: string;
};
export type GetStudentArticleReviewerCompaniesApiResponse =
  /** status 200 OK */ {
    companies: ReviewerCompany[];
  };
export type GetStudentArticleReviewerCompaniesApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
};
export type PostArticleAffiliatesAsStudentApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostArticleAffiliatesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    article_id: string;
    affiliate_id: string;
  };
};
export type DeleteArticleAffiliatesAsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteArticleAffiliatesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    article_id: string;
    affiliate_id: string;
  };
};
export type FetchArticleStatsAsStudentApiResponse = /** status 200 OK */ {
  articles: ArticleBase[];
  total_count: number;
};
export type FetchArticleStatsAsStudentApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  perPage?: number;
  page?: number;
};
export type FetchArticleWordCloudAsStudentApiResponse =
  /** status 200 OK */ WordCloudBase;
export type FetchArticleWordCloudAsStudentApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  listLen?: number;
  offset?: number;
  minNumOfChar?: number;
};
export type PostArticleImagesAsStudentApiResponse =
  /** status 201 Created */ ArticleImageBase;
export type PostArticleImagesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** 探究成果のID */
  articleId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type PutArticleImagesAsStudentApiResponse =
  /** status 202 Accepted */ ArticleImageBase;
export type PutArticleImagesAsStudentApiArg = {
  /** 探究成果のID */
  articleId: string;
  /** 探究成果の画像 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    file: Blob;
  };
};
export type DeleteControllerAsStudentApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteControllerAsStudentApiArg = {
  /** 探究成果のID */
  articleId: string;
  /** 探究成果の画像 ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutStudentPblReportCommentsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PutStudentPblReportCommentsByIdApiArg = {
  /** 更新したいコメントのID */
  id: string;
};
export type GetStudentPblReportCommentsApiResponse =
  /** status 200 OK */ PblReportCommentBase[];
export type GetStudentPblReportCommentsApiArg = {
  outputId: string;
  outputType: "answer" | "answer_item";
};
export type PostStudentPblReportCommentsApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostStudentPblReportCommentsApiArg = {
  body: {
    message?: string;
    output_id?: string;
    output_type?: "answer" | "answer_item";
  };
};
export type FetchPblReportAnswersAsStudentApiResponse = /** status 200 OK */ {
  total_count: number;
  answers: PblReportAnswerForStudent[];
};
export type FetchPblReportAnswersAsStudentApiArg = {
  perPage?: number;
  page?: number;
};
export type UpdatePblReportAnswerAsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type UpdatePblReportAnswerAsStudentApiArg = {
  /** 更新したいコメントのID */
  id: string;
  body: {
    event?: PblReportAnswerStudentEventEnum;
  };
};
export type FetchPblReportAnswerAsStudentApiResponse =
  /** status 200 OK */ PblReportAnswerDetailsForStudent;
export type FetchPblReportAnswerAsStudentApiArg = {
  /** 更新したいコメントのID */
  id: string;
};
export type PostPblReportAnswerImagesAsStudentApiResponse =
  /** status 201 Created */ PblReportAnswerImageBase;
export type PostPblReportAnswerImagesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  pblReportAnswerImageRequest: PblReportAnswerImageRequest;
};
export type PutPblReportAnswerImagesAsStudentApiResponse =
  /** status 202 Accepted */ PblReportAnswerImageBase;
export type PutPblReportAnswerImagesAsStudentApiArg = {
  /** Pbl::Report::AnswerImageのID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  pblReportAnswerImageRequest: PblReportAnswerImageRequest;
};
export type DeletePblReportAnswerImagesAsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeletePblReportAnswerImagesAsStudentApiArg = {
  /** Pbl::Report::AnswerImageのID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    answer_id: string;
  };
};
export type UpdatePblReportAnswerItemAsStudentApiResponse =
  /** status 202 Accepted */ PblReportAnswerTextBaseWithComments;
export type UpdatePblReportAnswerItemAsStudentApiArg = {
  /** 更新したいAnswerItemのID */
  id: string;
  body: {
    title?: string;
    body?: string;
  };
};
export type CreatePblReportAnswerItemAsStudentApiResponse =
  /** status 201 OK */ PblReportAnswerTextBaseWithComments;
export type CreatePblReportAnswerItemAsStudentApiArg = {
  body: {
    answer_id: string;
    item_id: string;
    title?: string;
    body?: string;
  };
};
export type FetchPblCardBookmarksAsStudentApiResponse = /** status 200 OK */ {
  bookmarks?: PblCardBookmarkList[];
  total_count?: number;
};
export type FetchPblCardBookmarksAsStudentApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** GroupAffiliatePackageのIDを指定する */
  groupAffiliateId?: string;
  /** パラメータが存在すると自分自身のものを取得する */
  createSelf?: boolean;
  /** パラメータが存在するとsource_typeを指定し取得する */
  searchSourceType?: string;
  /** パラメータが存在するとcreated_atの範囲検索の開始日以降のものを取得する */
  startDate?: string;
  /** パラメータが存在するとcreated_atの範囲検索の終了日以前のものを取得する */
  endDate?: string;
  /** common_tag_nameのフィルタリング */
  commonTagName?: string;
};
export type CreatePblCardBookmarkAsStudentApiResponse =
  /** status 201 Created */ PblCardBookmarkBase;
export type CreatePblCardBookmarkAsStudentApiArg = {
  postPblCardBookmarkRequestBody: PostPblCardBookmarkRequestBody;
};
export type FetchPblCardBookmarkAsStudentApiResponse =
  /** status 200 OK */ PblCardBookmarkBase;
export type FetchPblCardBookmarkAsStudentApiArg = {
  id: string;
};
export type UpdatePblCardBookmarkAsStudentApiResponse =
  /** status 200 OK */ PblCardBookmarkBase;
export type UpdatePblCardBookmarkAsStudentApiArg = {
  id: string;
  putPblCardBookmarkRequestBody: PutPblCardBookmarkRequestBody;
};
export type DeletePblCardBookmarkAsStudentApiResponse =
  /** status 204 No content */ undefined;
export type DeletePblCardBookmarkAsStudentApiArg = {
  id: string;
};
export type UpdatePblCardBookmarkWysiwygBodyAsStudentApiResponse =
  /** status 200 OK */ PblCardBookmarkBase;
export type UpdatePblCardBookmarkWysiwygBodyAsStudentApiArg = {
  id: string;
  schema: PutPblCardBookmarkWysiwygBodyRequestBody;
};
export type DeletePblCardBookmarkImageAsStudentApiResponse = unknown;
export type DeletePblCardBookmarkImageAsStudentApiArg = {
  id: string;
};
export type FetchPblCardBookmarkCommentsAsStudentApiResponse =
  /** status 200 OK */ {
    pbl_card_comments?: PblCardCommentBase[];
    total_count?: number;
  };
export type FetchPblCardBookmarkCommentsAsStudentApiArg = {
  bookmarkId: string;
  page?: number;
  perPage?: number;
};
export type CreatePblCardBookmarkCommentAsStudentApiResponse =
  /** status 201 Created */ PblCardCommentBase;
export type CreatePblCardBookmarkCommentAsStudentApiArg = {
  body: {
    bookmark_id: string;
    body: string;
  };
};
export type UpdatePblCardBookmarkCommentAsStudentApiResponse =
  /** status 200 OK */ PblCardCommentBase;
export type UpdatePblCardBookmarkCommentAsStudentApiArg = {
  /** 更新したいコメントのID */
  id: string;
  body: {
    body?: string;
  };
};
export type DeletePblCardBookmarkCommentAsStudentApiResponse =
  /** status 204 No content */ undefined;
export type DeletePblCardBookmarkCommentAsStudentApiArg = {
  /** 削除したいコメントのID */
  id: string;
};
export type GetPblCardBookmarkStampsAsStudentApiResponse =
  /** status 200 OK */ {
    stamp_fields: StampBase[];
    total_count: number;
  };
export type GetPblCardBookmarkStampsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** スタンプを作成するモデル名 */
  stampableType: string;
  /** スタンプを作成するモデルのID */
  stampableId: string;
  kind?: StampType;
};
export type PostPblCardBookmarkStampAsStudentApiResponse =
  /** status 200 OK */ StampBase;
export type PostPblCardBookmarkStampAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    stampable_type: StampableType;
    stampable_id: string;
    actor_id: string;
    kind: StampType;
  };
};
export type PutPblCardBookmarkStampAsStudentApiResponse =
  /** status 200 OK */ StampBase;
export type PutPblCardBookmarkStampAsStudentApiArg = {
  /** スタンプのID */
  id: string;
  body: {
    kind: StampType;
  };
};
export type DeletePblCardBookmarkStampAsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeletePblCardBookmarkStampAsStudentApiArg = {
  /** スタンプのID */
  id: string;
};
export type GetStudentGoogleIntegrationsApiResponse = /** status 200 OK */ {
  gi_courses: (ModelBase & {
    course_id?: string;
    name?: string;
    section?: string;
    room?: string;
    courses?: {
      course_work_id?: string;
      title?: string;
    }[];
  })[];
  total_count: number;
};
export type GetStudentGoogleIntegrationsApiArg = {
  page?: number;
  perPage?: number;
};
export type GetStudentGoogleIntegrationsByCourseIdApiResponse =
  /** status 200 OK */ {
    gi_course_works: (ModelBase & {
      course_work_id?: string;
      title?: string;
    })[];
    total_count: number;
  };
export type GetStudentGoogleIntegrationsByCourseIdApiArg = {
  courseId: string;
  page?: number;
  perPage?: number;
};
export type GetStudentGoogleIntegrationsByCourseIdAndCourseWorkIdApiResponse =
  /** status 200 OK */ {
    gi_materials: (ModelBase & {
      material_id?: string;
      title?: string;
      alternate_link?: string;
      thumbnail_url?: string;
    })[];
    total_count: number;
  };
export type GetStudentGoogleIntegrationsByCourseIdAndCourseWorkIdApiArg = {
  courseId: string;
  courseWorkId: string;
  page?: number;
  perPage?: number;
};
export type GetSteamContentsAsStudentApiResponse = /** status 200 OK */ {
  steam_contents?: PblSteamContent[];
  total_count?: number;
};
export type GetSteamContentsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  isBookmarked?: boolean;
  isRecommended?: boolean;
};
export type GetStudentSteamContentsIdApiResponse =
  /** status 200 OK */ StudentPblSteamContentDetail;
export type GetStudentSteamContentsIdApiArg = {
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type SearchStudentSteamContentsApiResponse = /** status 200 OK */ {
  steam_contents: StudentPblSteamContentDetail[];
  total_count: number;
};
export type SearchStudentSteamContentsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  difficulty: number;
  aspect: number;
  isScientific: boolean;
  page?: number;
  perPage?: number;
};
export type PostStudentSteamLecturesApiResponse =
  /** status 201 Created */ PblSteamLecture;
export type PostStudentSteamLecturesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** SteamライブラリーのコンテンツIDを入力する。e.g. 1 */
  slContentId: number;
  createSteamLectureRequestBody: CreateSteamLectureRequestBody;
};
export type GetStudentSteamLecturesIdApiResponse =
  /** status 200 OK */ PblSteamLecture;
export type GetStudentSteamLecturesIdApiArg = {
  /** SteamライブラリーのレクチャーIDを指定する。 */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudentSteamLectureCommentsApiResponse = /** status 200 OK */ {
  comments?: PblSteamCommentBase[];
  total_count?: number;
};
export type GetStudentSteamLectureCommentsApiArg = {
  /** SteamライブラリーのレクチャーIDを指定する。 */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetStudentSteamCommentsApiResponse = /** status 200 OK */ {
  steam_content_comments?: PblSteamCommentBase[];
  total_count?: number;
};
export type GetStudentSteamCommentsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** Pbl::Steam::Lecture ID */
  lectureId: string;
};
export type PostStudentSteamCommentsApiResponse =
  /** status 201 Created */ PblSteamCommentBase;
export type PostStudentSteamCommentsApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    lecture_id: string;
    is_public: boolean;
    body: string;
  };
};
export type PutStudentSteamContentsIdApiResponse =
  /** status 202 Accepted */ PblSteamCommentBase;
export type PutStudentSteamContentsIdApiArg = {
  /** Pbl::Steam::Comment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    is_public?: boolean;
    body?: string;
  };
};
export type DeleteStudentSteamContentsIdApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteStudentSteamContentsIdApiArg = {
  /** Pbl::Steam::Comment ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudentSteamContentWorksApiResponse = /** status 200 OK */ {
  steam_contents?: StudentPblSteamContentDetail[];
  total_count?: number;
};
export type GetStudentSteamContentWorksApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  contentTitle?: string;
  lectureTitle?: string;
  page?: number;
  perPage?: number;
};
export type PostStudentSteamContentWorksApiResponse =
  /** status 201 Created */ StudentPblSteamContentWork;
export type PostStudentSteamContentWorksApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** Steam ライブラリーのコンテンツID */
  contentId: number;
  body: {
    progress?: string;
  };
};
export type GetStudentSteamContentWorksIdApiResponse =
  /** status 200 OK */ StudentPblSteamContentWork;
export type GetStudentSteamContentWorksIdApiArg = {
  /** Pbl::Steam::Content IDを指定してください */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutStudentSteamContentWorksIdApiResponse =
  /** status 202 Accepted */ StudentPblSteamContentWork;
export type PutStudentSteamContentWorksIdApiArg = {
  /** Pbl::Steam::Content IDを指定してください */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    progress?: string;
  };
};
export type GetSteamLectureHistoriesAsStudentApiResponse =
  /** status 200 OK */ {
    steam_histories?: StudentLectureHistory[];
    total_count?: number;
  };
export type GetSteamLectureHistoriesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostSteamLectureHistoriesAsStudentApiResponse =
  /** status 201 Created */ StudentLectureHistory;
export type PostSteamLectureHistoriesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    sl_lecture_id?: number;
  };
};
export type FetchSteamLectureHistoryStatsAsStudentApiResponse =
  /** status 200 OK */ StudentLectureStats;
export type FetchSteamLectureHistoryStatsAsStudentApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type FetchSteamLectureHistoryCountAsStudentApiResponse =
  /** status 200 OK */ {
    steam_contents: StudentLectureHistoryCount[];
  };
export type FetchSteamLectureHistoryCountAsStudentApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  /** sl_content_idを配列で渡す */
  slContentIds?: number[];
};
export type DeleteSteamLectureHistoryAsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteSteamLectureHistoryAsStudentApiArg = {
  /** Pbl::Steam::LectureHistory IDを指定する。 */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSteamContentRecommendationsAsStudentApiResponse =
  /** status 200 OK */ {
    steam_content_recommendations: ContentRecommendationBase[];
    total_count: number;
  };
export type GetSteamContentRecommendationsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostStudentHomeworkTemplateCustomValuesApiResponse =
  /** status 201 Created */ {
    homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  };
export type PostStudentHomeworkTemplateCustomValuesApiArg = {
  /** HomeworkTemplate ID */
  homeworkTemplateId: string;
  /** HomeworkTemplateCustomField ID */
  homeworkTemplateCustomFieldId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  customValueRequestBody: CustomValueRequestBody;
};
export type PutStudentHomeworkTemplateCustomValuesByIdApiResponse =
  /** status 202 Accepted */ {
    homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  };
export type PutStudentHomeworkTemplateCustomValuesByIdApiArg = {
  /** HomeworkTemplateCustomValue ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  customValueRequestBody: CustomValueRequestBody;
};
export type DeleteStudentHomeworkTemplateCustomValuesByIdApiResponse =
  /** status 200 Accepted */ {
    homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  };
export type DeleteStudentHomeworkTemplateCustomValuesByIdApiArg = {
  /** HomeworkTemplateCustomValue ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    homework_id: string;
    group_affiliate_package_id?: string;
  };
};
export type GetPblPlanItemsAsStudentApiResponse = /** status 200 OK */ {
  plan_items: TeacherPblPlanItemDetails[];
  total_count: number;
};
export type GetPblPlanItemsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetPblPlanClassesAsStudentApiResponse = /** status 200 OK */ {
  plan_classes: TeacherPblPlanClass[];
  total_count: number;
};
export type GetPblPlanClassesAsStudentApiArg = {
  /** Pbl::Plan::Item ID (or all in get) */
  itemId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
};
export type GetPblPlanContentsAsStudentApiResponse = /** status 200 OK */ {
  plan_contents: PblPlanContentNav[];
  total_count: number;
};
export type GetPblPlanContentsAsStudentApiArg = {
  /** Pbl::Plan::Item ID */
  itemId: string;
  /** Pbl::Plan::Class ID */
  classId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetStudentInquiriesApiResponse = /** status 200 OK */ {
  inquiries: InquirySimpleListBase[];
  total_count: number;
};
export type GetStudentInquiriesApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetStudentInquiriesByIdApiResponse =
  /** status 200 OK */ InquirySimpleBase;
export type GetStudentInquiriesByIdApiArg = {
  /** inquiries ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTheNumberOfNewCommentInquiriesAsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type GetTheNumberOfNewCommentInquiriesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostStudentInquiryCommentsApiResponse =
  /** status 201 Created */ InquiryComment;
export type PostStudentInquiryCommentsApiArg = {
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
  };
};
export type PutStudentInquiryCommentsByIdApiResponse =
  /** status 202 Accepted */ InquiryComment;
export type PutStudentInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
    status?: string;
  };
};
export type DeleteStudentInquiryCommentsByIdApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteStudentInquiryCommentsByIdApiArg = {
  /** inquiries ID */
  id: string;
  /** inquiries ID */
  inquiryId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudentSponsorInfosApiResponse = /** status 200 OK */ {
  sponsors: SponsorInfoListBase[];
  total_count: number;
  all_sponsor_names?: string[];
  all_sponsor_tags?: string[];
};
export type GetStudentSponsorInfosApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: SponsorInfosSearchType;
  searchWord?: string;
};
export type GetStudentSponsorInfosIdApiResponse =
  /** status 200 OK */ SponsorInfoDetailsBase;
export type GetStudentSponsorInfosIdApiArg = {
  /** 企業ID（Sponsor::Info ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorProjectsAsStudentApiResponse = /** status 200 OK */ {
  projects: SponsorProjectAndTagsBase[];
  total_count: number;
  all_project_names?: string[];
  all_project_tags?: string[];
};
export type FetchSponsorProjectsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  bookmarked?: boolean;
  recommended?: boolean;
  searchType?: SponsorProjectsSearchType;
  searchWord?: string;
  viewing?: boolean;
};
export type FetchOneSponsorProjectAsStudentApiResponse =
  /** status 200 OK */ SponsorProjectDetailsBase;
export type FetchOneSponsorProjectAsStudentApiArg = {
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorProjectsForCarouselAsStudentApiResponse =
  /** status 200 OK */ {
    projects: SponsorProjectBase[];
    total_count: number;
  };
export type FetchSponsorProjectsForCarouselAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  numberOfProjects?: number;
  sortType?: SponsorProjectsSortType;
};
export type FetchSponsorProjectStatsAsStudentApiResponse =
  /** status 200 OK */ {
    learning_progress?: {
      checked_lectures_count?: number;
      total_lectures_count?: number;
    };
  };
export type FetchSponsorProjectStatsAsStudentApiArg = {
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type FetchSponsorLecturesAsStudentApiResponse = /** status 200 OK */ {
  project_lectures: SponsorLectureDetailsBase[];
  total_count: number;
};
export type FetchSponsorLecturesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** 企業プロジェクトID（Pbl::Sponsor::Project ID） */
  projectId: string;
};
export type FetchSponsorLectureAsStudentApiResponse =
  /** status 200 OK */ SponsorLectureDetailsBase;
export type FetchSponsorLectureAsStudentApiArg = {
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostPblSponsorProjectWorkAsStudentApiResponse =
  /** status 201 Accepted */ ModelBase & {
    pbl_sponsor_project_id: string;
    student: AffiliateBase;
    progress: SponsorProjectWorkProgressJa;
    created_at: string;
    updated_at: string;
  };
export type PostPblSponsorProjectWorkAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    project_id: string;
  };
};
export type PutSponsorProjectWorkAsStudentApiResponse =
  /** status 202 Accepted */ Schema;
export type PutSponsorProjectWorkAsStudentApiArg = {
  /** Pbl::Sponsor::ProjectWorkID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    project_id: string;
    progress: SponsorProjectWorkProgress;
  };
};
export type GetSponsorContentsAsStudentApiResponse = /** status 200 OK */ {
  sponsor_lecture_comments: PblProjectCommentBase[];
  current_user_comment: PblProjectCommentBase;
  total_count: number;
};
export type GetSponsorContentsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  lectureId?: string;
};
export type PostPblSponsorCommentAsStudentApiResponse =
  /** status 201 OK */ PblProjectCommentBase;
export type PostPblSponsorCommentAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  /** 企業プロジェクトのレクチャーID（Pbl::Sponsor::Lecture ID） */
  lectureId: string;
  body: {
    body: string;
  };
};
export type PutPblSponsorCommentAsStudentApiResponse =
  /** status 202 Accepted */ PblProjectCommentBase;
export type PutPblSponsorCommentAsStudentApiArg = {
  /** レクチャーコメント（Pbl::Sponsor::Comment ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    body: string;
  };
};
export type DeletePblSponsorCommentsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeletePblSponsorCommentsStudentApiArg = {
  /** レクチャーコメント（Pbl::Sponsor::Comment ID） */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetSponsorArticleContentTemplateAsStudentApiResponse =
  /** status 200 OK */ SponsorArticleContentTemplateBase;
export type GetSponsorArticleContentTemplateAsStudentApiArg = {
  /** 企業プロジェクトレクチャーID(Pbl::Sponsor::Lecture ID) */
  sponsorLectureId: string;
  /** Pbl::Sponsor::ArticleContentTemplate ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetTodosAsStudentApiResponse = /** status 200 OK */ {
  material_works: HasTotalCount;
  homeworks: HasTotalCount;
  articles: HasTotalCount;
  answers: HasTotalCount;
  learning_templates: HasTotalCount;
};
export type GetTodosAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  startDate?: string;
  endDate?: string;
};
export type GetDailyLogsAsStudentApiResponse = /** status 200 OK */ {
  daily_logs: DailyLogBase[];
  total_count: number;
};
export type GetDailyLogsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** すべての情報を取得するフラグ */
  all?: boolean;
  /** - 開始日から範囲検索する
    - fromとtoは両方必須 */
  from?: string;
  /** - 開始日から範囲検索する
    - fromとtoは両方必須 */
  to?: string;
  /** - 学習カテゴリーから検索 */
  category?: DailyLogCategory;
};
export type PostDailyLogAsStudentApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostDailyLogAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    date: string;
    study_time: number;
    category: DailyLogCategory;
    memo?: string;
  };
};
export type GetDailyLogAsStudentApiResponse = /** status 200 OK */ DailyLogBase;
export type GetDailyLogAsStudentApiArg = {
  /** DailyLog ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutDailyLogAsStudentApiResponse =
  /** status 202 Accepted */ DailyLogBase;
export type PutDailyLogAsStudentApiArg = {
  /** DailyLog ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    date: string;
    study_time: number;
    category: DailyLogCategory;
    memo?: string;
  };
};
export type DeleteDailyLogAsStudentApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteDailyLogAsStudentApiArg = {
  /** DailyLog ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudyLogsAsStudentApiResponse = /** status 200 OK */ {
  study_logs: StudyLogBase[];
  total_count: number;
};
export type GetStudyLogsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** - 開始日から範囲検索する
    - fromとtoは両方必須 */
  from?: string;
  /** - 開始日から範囲検索する
    - fromとtoは両方必須 */
  to?: string;
};
export type PostStudyLogAsStudentApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostStudyLogAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    categorizable_type: StudyLogCategorizableType;
    categorizable_id: string;
    behavior: StudyLogBehavior;
    group_affiliate_package_id?: string;
  };
};
export type GetStudyLogAsStudentApiResponse = /** status 200 OK */ StudyLogBase;
export type GetStudyLogAsStudentApiArg = {
  /** StudyLog ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutStudentStudyLogsByIdApiResponse = unknown;
export type PutStudentStudyLogsByIdApiArg = {
  /** StudyLog ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetStudyLogsDeliverableListAsStudentApiResponse =
  /** status 200 OK */ DeliverableListBaseAsStudent;
export type GetStudyLogsDeliverableListAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudyLogsMaterialWorksAsStudentApiResponse =
  /** status 200 OK */ {
    material_works: StudentLogDeliverableMaterialWorkBase[];
    total_count: number;
  };
export type GetStudyLogsMaterialWorksAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudyLogsHomeworksAsStudentApiResponse = /** status 200 OK */ {
  homeworks: StudentLogDeliverableHomeWorkBase[];
  total_count: number;
};
export type GetStudyLogsHomeworksAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudyLogsLearningPackagesAsStudentApiResponse =
  /** status 200 OK */ {
    learning_packages: StudentLogDeliverableLearningPackageBase[];
    total_count: number;
  };
export type GetStudyLogsLearningPackagesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudyLogsArticlesAsStudentApiResponse = /** status 200 OK */ {
  articles: StudentLogDeliverableArticleBase[];
  total_count: number;
};
export type GetStudyLogsArticlesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudyLogsIdealsAsStudentApiResponse = /** status 200 OK */ {
  ideals: StudentLogDeliverableIdealBase[];
  total_count: number;
};
export type GetStudyLogsIdealsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetStudyLogsBookmarksAsStudentApiResponse = /** status 200 OK */ {
  bookmarks: StudentLogDeliverableListBase[];
  total_count: number;
};
export type GetStudyLogsBookmarksAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  startDate?: string;
  endDate?: string;
  tagName?: string;
};
export type GetLearningPackagesAsStudentApiResponse = /** status 200 OK */ {
  learning_packages: LearningPackageBaseDetails[];
  total_count: number;
};
export type GetLearningPackagesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  /** - 検索タイプを指定する
    - search_typeとsearch_wordは両方していする必要あり
    - title: タイトルから検索
    - description: 説明文から検索
    - tag: タグから検索 */
  searchType?: string;
  /** - search_typeとsearch_wordは両方していする必要あり
    - 検索ワードの部分一致で検索する */
  searchWord?: string;
};
export type GetLearningPackageAsStudentApiResponse =
  /** status 200 OK */ LearningPackageBaseDetails;
export type GetLearningPackageAsStudentApiArg = {
  /** LearningPackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutLearningPackageAsStudentApiResponse =
  /** status 202 Accepted */ LearningPackageBaseDetails;
export type PutLearningPackageAsStudentApiArg = {
  /** LearningPackage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    status: LearningPackageStatus;
  };
};
export type GetIdealBoardsAsStudentApiResponse = /** status 200 OK */ {
  boards: BoardList[];
  total_count: number;
};
export type GetIdealBoardsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  groupAffiliateId?: string;
};
export type PostIdealBoardsAsStudentApiResponse =
  /** status 201 Created */ Board;
export type PostIdealBoardsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    name: string;
    size: IdealBoardSize;
  };
};
export type GetIdealBoardAsStudentApiResponse = /** status 200 OK */ Board;
export type GetIdealBoardAsStudentApiArg = {
  /** Ideal::Board ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutIdealBoardAsStudentApiResponse = /** status 202 202 Accepted */ {
  message?: string;
};
export type PutIdealBoardAsStudentApiArg = {
  /** Ideal::Board ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    name?: string;
    data?: string;
    event?: IdealBoardEvent;
  };
};
export type DeleteIdealBoardAsStudentApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteIdealBoardAsStudentApiArg = {
  /** Ideal::Board ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostIdealBoardsAffiliatesAsStudentApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostIdealBoardsAffiliatesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    ideal_board_id: string;
    affiliate_id: string;
  };
};
export type DeleteIdealBoardsAffiliatesAsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type DeleteIdealBoardsAffiliatesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    ideal_board_id: string;
    affiliate_id: string;
  };
};
export type GetGroupAffiliatePackagesAsStudentApiResponse =
  /** status 200 OK */ {
    group_affiliate_packages: GroupAffiliatePackageList[];
    total_count: number;
  };
export type GetGroupAffiliatePackagesAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostAttachedImageAsStudentApiResponse =
  /** status 201 Created */ AttachedImageBase;
export type PostAttachedImageAsStudentApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type GetAttachedImageAsStudentApiResponse =
  /** status 200 OK */ AttachedImageBase;
export type GetAttachedImageAsStudentApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type PutAttachedImageAsStudentApiResponse =
  /** status 202 Accepted */ AttachedImageBase;
export type PutAttachedImageAsStudentApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
  fileRequiredRequestBody: FileRequiredRequestBody;
};
export type DeleteAttachedImageAsStudentApiResponse = /** status 200 200 OK */ {
  message?: string;
};
export type DeleteAttachedImageAsStudentApiArg = {
  categorizableType: AttachedImageCategorizableType;
  categorizableId: string;
  /** AttachedImage ID */
  id: string;
};
export type GetLimitedTimeNotificationNoticeAsStudentApiResponse =
  /** status 200 OK */ {
    limited_time_notifications: LimitedTimeNotificationNoticeBase[];
    total_count: number;
  };
export type GetLimitedTimeNotificationNoticeAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type GetLimitedTimeNotificationNoticeDetailsAsStudentApiResponse =
  /** status 200 OK */ LimitedTimeNotificationNoticeBase;
export type GetLimitedTimeNotificationNoticeDetailsAsStudentApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostLimitedTimeNotificationNoticeCheckAsStudentApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostLimitedTimeNotificationNoticeCheckAsStudentApiArg = {
  /** LimitedTimeNotification ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PatchAllowedGroupAffiliatesBatchUpdateAsStudentApiResponse =
  /** status 200 200 OK */ {
    message?: string;
  };
export type PatchAllowedGroupAffiliatesBatchUpdateAsStudentApiArg = {
  "access-token"?: string;
  client?: string;
  uid?: string;
  body: {
    categorizable_type: AllowedGroupAffiliateCategorizableType;
    categorizable_id: string;
    add_group_affiliate_ids?: string[];
    remove_group_affiliate_ids?: string[];
  };
};
export type PostLearningProgressAsStudentApiResponse =
  /** status 201 Created */ LearningProgressBase;
export type PostLearningProgressAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    categorizable_type: LearningProgressCategorizableType;
    categorizable_id: string;
    event?: LearningProgressEvent;
  };
};
export type PutLearningProgressAsStudentApiResponse =
  /** status 202 Accepted */ LearningProgressBase;
export type PutLearningProgressAsStudentApiArg = {
  /** LearningProgress ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    event: LearningProgressEvent;
  };
};
export type GetInterviewRequestsAsStudentApiResponse = /** status 200 OK */ {
  interview_requests: InterviewRequestListBase[];
  total_count: number;
};
export type GetInterviewRequestsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  status?: InterviewRequestStatus;
  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  searchType?: InterviewRequestSearchType;
  /** 検索種別、search_typeとsearch_wordは同時に指定する必要有り */
  searchWord?: string;
};
export type GetInterviewRequestAsStudentApiResponse =
  /** status 200 OK */ InterviewRequestDetailBase;
export type GetInterviewRequestAsStudentApiArg = {
  /** Interview::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PutInterviewRequestAsStudentApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type PutInterviewRequestAsStudentApiArg = {
  /** Interview::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    student_message?: string;
    is_online?: boolean;
    preferred_dates?: string[];
  };
};
export type GetInterviewRequestArticleAsStudentApiResponse =
  /** status 200 OK */ ArticleDetailBase;
export type GetInterviewRequestArticleAsStudentApiArg = {
  /** Interview::Request ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetInterviewRequestUnreadCountsAsStudentApiResponse =
  /** status 200 OK */ {
    unread_counts: InterviewRequestUnreadCountBase[];
  };
export type GetInterviewRequestUnreadCountsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  requestIds?: string[];
};
export type GetInterviewMessagesAsStudentApiResponse = /** status 200 OK */ {
  interview_messages: InterviewRequestListBase2[];
  total_count: number;
};
export type GetInterviewMessagesAsStudentApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostInterviewMessageAsStudentApiResponse =
  /** status 201 Created */ InterviewRequestListBase2;
export type PostInterviewMessageAsStudentApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    message: string;
  };
};
export type GetInterviewMessageReadAsStudentApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type GetInterviewMessageReadAsStudentApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    unread_message_ids: string[];
  };
};
export type PutInterviewMessageAsStudentApiResponse =
  /** status 202 Accepted */ InterviewRequestListBase2;
export type PutInterviewMessageAsStudentApiArg = {
  /** Interview::Request ID */
  requestId: string;
  /** Interview::Message ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    message: string;
  };
};
export type DeleteInterviewMessageAsStudentApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteInterviewMessageAsStudentApiArg = {
  /** Interview::Request ID */
  requestId: string;
  /** Interview::Message ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetInterviewFeedbacksAsStudentApiResponse = /** status 200 OK */ {
  interview_feedbacks: InterviewFeedbackBase[];
  total_count: number;
};
export type GetInterviewFeedbacksAsStudentApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostInterviewRequestAsStudentApiResponse =
  /** status 201 Created */ {
    message?: string;
  };
export type PostInterviewRequestAsStudentApiArg = {
  /** Interview::Request ID */
  requestId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  body: {
    description: string;
    degree_of_interest: number;
    degree_of_learning: number;
    is_request_send_material: boolean;
  };
};
export type FetchHelpContentsAsStudentApiResponse = /** status 200 OK */ {
  help_master?: HelpMasterBase;
  help_contents: HelpContentBase[];
  total_count: number;
};
export type FetchHelpContentsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  kind: HelpMasterKind;
};
export type GetCustomMastersAsStudentApiResponse = /** status 200 OK */ {
  custom_masters: CustomMasterPublics[];
  total_count: number;
};
export type GetCustomMastersAsStudentApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  page?: number;
  perPage?: number;
  /** custom_master_mapのhashid */
  customMasterMapId: string;
  /** homework_template_custom_fieldのhashid */
  customFieldId: string;
  /** - keyは以下のいずれか
    - 検索するカラムのnumber
    - custom_master_map_relationshipのhashid */
  filterInfos: {
    key: string;
    value: string[];
  }[];
};
export type GetCustomMastersSelectListAsStudentApiResponse =
  /** status 200 OK */ {
    custom_masters: HasName[];
    total_count: number;
  };
export type GetCustomMastersSelectListAsStudentApiArg = {
  accessToken?: string;
  uid?: string;
  client?: string;
  page?: number;
  perPage?: number;
  /** custom_master_mapのhashid */
  customMasterMapId: string;
  /** - 検索するカラムのnumberのみ "2"
    - filter_column_number と filter_cmmr_id はどちらか片方必須 */
  filterColumnNumber?: string;
  /** - フィルターするcustom_master_map_relationshipのhashid
    - filter_column_number と filter_cmmr_id はどちらか片方必須 */
  filterCmmrId?: string;
  /** 検索単語 */
  searchWord?: string;
};
export type GetChatRoomAsStudentApiResponse =
  /** status 200 OK */ ChatRoomDetailBase;
export type GetChatRoomAsStudentApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type PostChatRoomAsStudentApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostChatRoomAsStudentApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetChatRoomMessagesAsStudentApiResponse = /** status 200 OK */ {
  chat_room_messages: ChatRoomMessageListBase[];
  total_count: number;
};
export type GetChatRoomMessagesAsStudentApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
};
export type PostChatRoomMessageAsStudentApiResponse =
  /** status 201 Created */ ChatRoomMessageDetail;
export type PostChatRoomMessageAsStudentApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type PutChatRoomMessageAsStudentApiResponse =
  /** status 202 Accepted */ ChatRoomMessageDetail;
export type PutChatRoomMessageAsStudentApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  /** Chat::RoomMessage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  messageRequiredRequestBody: MessageRequiredRequestBody;
};
export type DeleteChatRoomMessageAsStudentApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteChatRoomMessageAsStudentApiArg = {
  /** 調査メモID */
  bookmarkId: string;
  /** Chat::RoomMessage ID */
  id: string;
  accessToken?: string;
  client?: string;
  uid?: string;
};
export type GetCommonTagsAsStudentApiResponse = /** status 200 OK */ {
  common_tags: CommonTagBase[];
  total_count: number;
};
export type GetCommonTagsAsStudentApiArg = {
  accessToken?: string;
  client?: string;
  uid?: string;
  page?: number;
  perPage?: number;
  searchType?: Schema2;
  searchWord?: string;
  kind?: CommonTagKind;
  categorizableType?: CommonTaggingCategorizableType;
};
export type PostCommonTaggingAsStudentApiResponse = /** status 201 Created */ {
  message?: string;
};
export type PostCommonTaggingAsStudentApiArg = {
  categorizableType: CommonTaggingCategorizableType;
  categorizableId: string;
  commonTaggingRequestBody: CommonTaggingRequestBody;
};
export type DeleteCommonTaggingAsStudentApiResponse =
  /** status 202 202 Accepted */ {
    message?: string;
  };
export type DeleteCommonTaggingAsStudentApiArg = {
  categorizableType: CommonTaggingCategorizableType;
  categorizableId: string;
  accessToken?: string;
  client?: string;
  uid?: string;
  commonTagId: string;
};
export type AffiliateName = {
  affiliate_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  nickname: string | null;
};
export type ErrorsWithKeyMessage = {
  errors: {
    key?: string;
    message?: string;
  }[];
};
export type ModelBase = {
  id: string;
};
export type BasicUserInfo = ModelBase & {
  first_name: string;
  last_name: string;
  email: string;
  optional_email?: string | null;
  invitation_accepted: boolean;
  invitation_expired: boolean;
  expired_date?: string | null;
  current_sign_in_at?: string | null;
  last_sign_in_at?: string | null;
  linked_project_count?: number | null;
  affiliate_id?: string | null;
  nickname: string | null;
};
export type CompanyBase = ModelBase & {
  name: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  logo?: {
    url?: string | null;
    thumb?: {
      url?: string | null;
    };
    icon?: {
      url?: string | null;
    };
  };
};
export type Role =
  | "admin"
  | "super_owner"
  | "owner"
  | "teacher"
  | "student"
  | "reviewer"
  | "content_provider";
export type RoleJa =
  | "SV\u7BA1\u7406\u8005"
  | "\u89AA\u7BA1\u7406\u8005"
  | "\u7BA1\u7406\u8005"
  | "\u6559\u8077\u54E1"
  | "\u751F\u5F92"
  | "\u6DFB\u524A\u5229\u7528\u8005"
  | "\u30B3\u30F3\u30C6\u30F3\u30C4\u4E8B\u696D\u8005";
export type RoleBase = ModelBase & {
  name: Role;
  jp_name: RoleJa;
};
export type AffiliateBase = {
  affiliate_id: string;
  user?: BasicUserInfo;
  company?: CompanyBase;
  role?: RoleBase;
};
export type Avatar = {
  url: string | null;
};
export type StudentProfile = {
  id: string;
  email: string;
  last_name: string;
  first_name: string;
  nickname: string | null;
  avatar: Avatar;
  student_info: {
    birth_date: string;
    grade: string;
    grade_value?: string;
    parent_last_name: string;
    parent_first_name: string;
    postcode: string;
    prefecture: string;
    address1: string;
    address2: string;
    phone: string;
    number?: number;
  };
};
export type StudentInfoAttributes = {
  birth_date?: string;
  grade?: string;
  parent_last_name?: string;
  parent_first_name?: string;
  postcode?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  number?: number;
};
export type CategoryPart = {
  id: string;
  name: string;
  depth: number;
  type: string;
  text_id?: string;
  grade_id?: string;
  subject_id?: string;
  major_category_id?: string;
  middle_category_id?: string;
};
export type CategoryBase = {
  category: CategoryPart;
};
export type HasIdAndName = {
  id: string;
  name: string;
};
export type MaterialList = ModelBase &
  CategoryBase & {
    title: string;
    thumbnail_file?: string | null;
    is_public: boolean;
    is_video_updating: boolean;
    tags: HasIdAndName[];
  };
export type MaterialSortedByColumn = "title" | "created_at";
export type SortedByOrder = "asc" | "desc";
export type ImageInfo = {
  id: string;
  title: string;
  material_id: string;
  file: {
    url: string;
    thumb?: {
      url: string | null;
    };
    icon?: {
      url: string | null;
    };
  };
  edited_image_url?: string | null;
};
export type CookieInfo = {
  cloudfront_policy: string;
  cloudfront_signature: string;
  cloudfront_key_pair_id: string;
  unsigned_url: string;
};
export type VideoInfo = {
  id: string;
  title: string;
  material_id: string;
  file_url: string | null;
  file: {
    url?: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string;
  thumbnail: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
};
export type MaterialDetail = MaterialList & {
  description: string;
  memo?: string | null;
  download_allowed: boolean;
  is_owned: boolean;
  images: ImageInfo[];
  videos: VideoInfo[];
  html_file: {
    title?: string | null;
    url?: string | null;
  };
};
export type MaterialWorkStatusJa =
  | "\u672A\u7740\u624B"
  | "\u53D7\u8B1B\u4E2D"
  | "\u5B8C\u4E86";
export type MaterialWorkStatus = "todo" | "in_progress" | "completed";
export type MaterialWorkList = ModelBase & {
  status: MaterialWorkStatusJa;
  status_code: MaterialWorkStatus;
  forbid_download: boolean;
  distributed_by_super_company: boolean;
  is_distributed: boolean;
  is_expired: boolean;
  is_available: boolean;
};
export type StudentMaterial = MaterialDetail & {
  thumbnail: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  edited_images: (ModelBase & {
    title?: string | null;
    url?: string | null;
    image_content_id?: string | null;
  })[];
};
export type StudentMaterialWork = MaterialWorkList & {
  images: (ModelBase & {
    title?: string | null;
    url?: string | null;
    image_content_id?: string | null;
  })[];
  material: StudentMaterial;
};
export type SortedByMaterialWork = "DateAsc" | "TitleAsc" | "TitleDesc";
export type CategoryListBase = ModelBase & {
  name: string;
  count: number;
};
export type TagListBase = ModelBase & {
  name: string;
  count: number;
};
export type PaymentStatus =
  | "initiated"
  | "customer_confirmed"
  | "teacher_confirmed"
  | "owner_confirmed";
export type Payment = ModelBase & {
  student_name: string | null;
  teacher_name: string | null;
  title: string | null;
  price?: number;
  status: string;
  status_code: PaymentStatus;
  created_at: string;
  due_date: string | null;
  year: number | null;
  month: number | null;
};
export type HomeworkStatusJa =
  | "\u65B0\u898F"
  | "\u63D0\u51FA\u6E08\u307F"
  | "\u6559\u8077\u54E1\u56DE\u7B54\u6E08\u307F"
  | "\u6DFB\u524A\u524D"
  | "\u6DFB\u524A\u4E2D"
  | "\u6DFB\u524A\u6E08\u307F"
  | "\u8FD4\u5374\u6E08\u307F"
  | "\u8981\u518D\u63D0\u51FA";
export type HomeworkStatus =
  | "todo"
  | "submitted"
  | "checked"
  | "submitted_reviewer"
  | "assigned_reviewer"
  | "checked_reviewer"
  | "returned_reviewer"
  | "change_requested";
export type GroupAffiliatePackageMinimalBase = ModelBase & {
  title: string;
};
export type StudentHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
};
export type CommonTagKind = "system" | "free" | "management";
export type CommonTagBase = ModelBase & {
  kind: CommonTagKind;
  name: string;
};
export type HomeworkBase = ModelBase & {
  title: string;
  status: HomeworkStatusJa;
  status_code: HomeworkStatus;
  submitted_at: string | null;
  student_affiliate_id: string | null;
  student_name: string;
  student_nickname?: string | null;
  teacher_name: string;
  start_at: string | null;
  end_at: string | null;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  student_number?: number | null;
  student_homework_images: StudentHomeworkImage[];
  has_attached_file: boolean;
  common_tags: CommonTagBase[];
  ideal_thumbnail_file: string | null;
};
export type HomeworkTemplateDistributionStatus =
  | "todo"
  | "distributed_students"
  | "submitted_reviewer"
  | "checked_reviewer"
  | "returned";
export type CategorizableCommentCategorizableType = "Homework";
export type AffiliateInfoBase = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  role_name: string;
  nickname: string | null;
};
export type CommentImageCategorizableType = "Homework" | "CategorizableComment";
export type CommentImageBase = ModelBase & {
  categorizable_id?: string;
  categorizable_type?: CommentImageCategorizableType;
  file_name?: string;
  file_url?: string;
};
export type CategorizableCommentBase = ModelBase & {
  categorizable_type: CategorizableCommentCategorizableType;
  categorizable_id: string;
  body: string;
  created_at: string;
  affiliate: AffiliateInfoBase;
  images: CommentImageBase[] | null;
};
export type TeacherHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
  from_template?: boolean;
  temp_data?: string | null;
};
export type ReviewerHomeworkImage = {
  id: string;
  title: string;
  thumb_url: string;
  file_url: string;
};
export type HomeworkTemplateCustomFieldsTypes =
  | "HomeworkTemplateCustomTextField"
  | "HomeworkTemplateCustomRadioField"
  | "HomeworkTemplateCustomSelectField"
  | "HomeworkTemplateCustomProfileField";
export type CustomFieldScoreBase = {
  possible_scores: number[] | null;
  possible_scores2: number[] | null;
  answer_flags?: boolean[] | null;
  is_exact_match: boolean | null;
};
export type HasNumberIdAndName = {
  id: number;
  name: string;
};
export type CustomFieldCustomMasterMap = ModelBase & {
  name: string;
  public_columns: HasNumberIdAndName[];
  filter_columns: HasNumberIdAndName[];
  filter_cmmrs: HasIdAndName[];
};
export type TtCategoryBaseType = "TtCategory::HomeworkTemplateCustomField";
export type TtCategoryBase = ModelBase & {
  name: string;
  type: TtCategoryBaseType;
};
export type HomeworkTemplateCustomField = ModelBase & {
  homework_templates_id: string;
  type: HomeworkTemplateCustomFieldsTypes;
  sequence: number;
  title: string;
  possible_values: string[];
  min_length: number | null;
  max_length: number | null;
  is_required: boolean;
  created_at: string;
  updated_at: string;
  score?: CustomFieldScoreBase;
  custom_master_map?: CustomFieldCustomMasterMap;
  custom_field_category?: TtCategoryBase;
};
export type AttachedImageCategorizableType =
  | "Material"
  | "Ideal::Board"
  | "Article"
  | "Pbl::Board::Content"
  | "Pbl::Sponsor::Lecture"
  | "Ideal::BoardTemplate"
  | "Interview::Message"
  | "HomeworkTemplateCustomFieldFeedback"
  | "Gogo::Message";
export type AttachedImageBase = ModelBase & {
  categorizable_type: AttachedImageCategorizableType;
  categorizable_id: string;
  file_name: string;
  file_url: string;
};
export type HomeworkTemplateCustomFieldFeedback = ModelBase & {
  body: string;
  commenter_affiliate: AffiliateInfoBase;
  created_at: string;
  feedback_image: AttachedImageBase[];
  wysiwyg_body: string | null;
};
export type StudentGrade =
  | "under"
  | "elementary1"
  | "elementary2"
  | "elementary3"
  | "elementary4"
  | "elementary5"
  | "elementary6"
  | "junior1"
  | "junior2"
  | "junior3"
  | "high1"
  | "high2"
  | "high3"
  | "university1"
  | "university2"
  | "university3"
  | "university4"
  | "others";
export type CustomMasterPublics = {
  id: string;
  public_columns: HasNumberIdAndName[];
};
export type StudentHomeworkTemplateCustomValue = ModelBase & {
  homework_template_custom_fields_id: string;
  student_id: string;
  profile_values?: {
    user_last_name?: string | null;
    user_first_name?: string | null;
    email?: string | null;
    number?: number | null;
    birth_date?: string | null;
    grade_value?: StudentGrade | null;
    grade?: string | null;
    phone?: string | null;
    parent_last_name?: string | null;
    parent_first_name?: string | null;
    postcode?: string | null;
    prefecture?: string | null;
    address1?: string | null;
    address2?: string | null;
  };
  value?: string | null;
  created_at: string;
  updated_at: string;
  group_affiliate_package?: GroupAffiliatePackageMinimalBase;
  custom_master?: CustomMasterPublics;
  wysiwyg_body?: string | null;
};
export type HomeworkTemplateCustomFieldAndValue =
  HomeworkTemplateCustomField & {
    custom_field_feedbacks: HomeworkTemplateCustomFieldFeedback[];
    homework_template_custom_values: StudentHomeworkTemplateCustomValue[];
    homework_template_custom_value_score?: {
      score?: number | null;
    } | null;
  };
export type IdealBoardStatusJa =
  | "\u65B0\u898F"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86";
export type IdealBoardStatus = "todo" | "in_progress" | "completed";
export type IdealBoardSize = "S" | "M" | "MV" | "L";
export type HomeworkBoardBase = ModelBase & {
  title?: string;
  template_id?: string | null;
};
export type BoardBase = ModelBase & {
  name: string;
  status: IdealBoardStatusJa;
  status_code: IdealBoardStatus;
  size: IdealBoardSize;
  created_at: string;
  template_id: string | null;
  homework?: HomeworkBoardBase | null;
  host_affiliate_id: string | null;
};
export type ApprovingStatus = "draft" | "approved";
export type ApprovingBase = ModelBase & {
  status: ApprovingStatus;
  affiliate: AffiliateName;
};
export type HomeworkDetails = HomeworkBase & {
  homework_status_changed_history: {
    status?: HomeworkStatusJa;
    updated_at?: string;
  }[];
  change_requested_at?: string | null;
  teacher_checked_at?: string | null;
  submitted_reviewer_at?: string | null;
  checked_reviewer_at?: string | null;
  returned_reviewer_at?: string | null;
  distribution_status_code?: HomeworkTemplateDistributionStatus;
  needs_review: boolean;
  needs_score: boolean;
  is_exam: boolean;
  created_at: string;
  updated_at: string;
  student_comment: string;
  teacher_comment?: string | null;
  reviewer_comments?: CategorizableCommentBase[] | null;
  teacher_comment_for_reviewer?: string | null;
  reviewer_comment_for_teacher?: string | null;
  score: number | null;
  assignees: AffiliateName[];
  teacher_homework_images: TeacherHomeworkImage[];
  reviewer_homework_images: ReviewerHomeworkImage[];
  content: string;
  company?: {
    id?: string;
    name?: string;
  };
  template?: {
    id?: string;
    title?: string;
  };
  reviewer_end_at?: string | null;
  homework_template_custom_fields: HomeworkTemplateCustomFieldAndValue[];
  ideal_board?: BoardBase;
  teacher_comment_images?: CommentImageBase[];
  approvings: ApprovingBase[];
  distribution?: {
    start_at?: string | null;
    end_at?: string | null;
  };
  ideal_thumbnail_file?: string | null;
};
export type HomeworkStatusBase = ModelBase & {
  status: HomeworkStatusJa;
  status_code: HomeworkStatus;
};
export type UserInputSetting = "required" | "optional" | "hidden";
export type StudentInputSetting = {
  avatar: UserInputSetting;
  postcode: UserInputSetting;
  prefecture: UserInputSetting;
  address1: UserInputSetting;
  address2: UserInputSetting;
  birth_date: UserInputSetting;
  grade: UserInputSetting;
  phone: UserInputSetting;
  parent_first_name: UserInputSetting;
  parent_last_name: UserInputSetting;
  number: UserInputSetting;
};
export type OptionsExpansionCapacity =
  | "unlimited"
  | "capacity_1"
  | "capacity_2"
  | "capacity_3"
  | "capacity_4"
  | "capacity_5";
export type MaterialExpansionBase = ModelBase & {
  capacity?: OptionsExpansionCapacity;
};
export type IdealExpansionBase = ModelBase & {
  capacity?: OptionsExpansionCapacity;
};
export type OptionExpansionBase = {
  material_expansion?: MaterialExpansionBase;
  ideal_expansion?: IdealExpansionBase;
};
export type CompanyAsStudent = ModelBase & {
  name: string;
  postcode: string;
  prefecture: string;
  address1: string;
  address2: string;
  phone: string;
  student_input_settings?: StudentInputSetting;
  options_expansions?: OptionExpansionBase;
};
export type LimitOptionBase = {
  over_capacity_material?: boolean | null;
  over_capacity_ideal?: boolean | null;
};
export type HomeworkImage = ModelBase & {
  title: string;
  temp_data: string | null;
  file: {
    thumb_url: string | null;
    file_url: string | null;
  } | null;
};
export type Report = ModelBase & {
  created_at?: string;
  teacher_name?: string;
  student_name?: string;
  detail?: string;
  reply?: string | null;
  status?: string;
  title?: string;
  next_goal?: string;
  monthly_teaching_times?: number;
  monthly_student_schedule_change?: number;
  monthly_teacher_schedule_change?: number;
  homework_frequency?: number;
  is_schedule_change_required?: boolean;
  schedule_detail_reason?: string;
  test_misc?: string;
  text_subjects?: string[];
  schedule_change_period?: string;
  schedule_change_notified?: string;
  is_schedule_change_notified?: boolean;
  substitute_lecture?: boolean;
  test_type?: string;
  no_homework_reason?: string;
  no_text_reason?: string;
  reports_test_results?: {
    subject: string;
    current_score: number;
    positive_negative: boolean;
    score_delta: number;
  }[];
};
export type CategoryType =
  | "text"
  | "grade"
  | "subject"
  | "major"
  | "middle"
  | "minor";
export type BaseCategoryInfo = {
  id: string;
  name: string;
  depth: number;
  type: CategoryType;
  student_visible?: boolean;
  teacher_visible?: boolean;
  owner_visible?: boolean;
  is_help?: boolean;
  student_help_page?: boolean;
  teacher_help_page?: boolean;
  owner_help_page?: boolean;
  reviewer_help_page?: boolean;
  image?: string | null;
};
export type GradeCategoryInfo = BaseCategoryInfo & {
  text_id: string;
};
export type SubjectCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  text_id: string;
};
export type MajorCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type MiddleCategoryInfo = BaseCategoryInfo & {
  grade_id: string;
  subject_id: string;
  major_category_id: string;
  text_id: string;
};
export type MinorCategoryInfo = BaseCategoryInfo & {
  major_category_id: string;
  middle_category_id: string;
  grade_id: string;
  subject_id: string;
  text_id: string;
};
export type UserTagBasic = ModelBase & {
  name: string;
  limit_number?: number | null;
  number_of_linking_users?: number;
  kind?: string;
};
export type TextCategoryInfo = BaseCategoryInfo & {
  linking_company_tags?: HasIdAndName[];
  linking_user_tags?: UserTagBasic[];
};
export type NotificationBase = ModelBase & {
  title: string;
  message: string;
  resource_info: string | null;
  sender?: {
    id?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
  };
  created_at: string;
  updated_at: string;
  notified_time: string;
};
export type ArticleKind = "default" | "poster";
export type ArticleStatusJa =
  | "\u4E0B\u66F8\u304D"
  | "\u518D\u63D0\u51FA/\u63D0\u51FA\u5F85\u3061"
  | "\u5B8C\u4E86"
  | "\u5B8C\u4E86"
  | "\u63D0\u51FA\u6E08\u307F/\u78BA\u8A8D\u4E2D";
export type ArticleStatus =
  | "draft"
  | "correction"
  | "reviewing"
  | "published"
  | "teacher_checking";
export type ArticleAffiliateLinking = {
  affiliate_id: string;
  user?: {
    user_id?: string;
    first_name?: string;
    last_name?: string;
    student_number?: number | null;
    nickname?: string | null;
  };
};
export type ArticleBaseCommon = ModelBase & {
  student_id: string;
  creator_student_affiliate_id?: string | null;
  student_number: number | null;
  student_name: string | null;
  student_nickname?: string | null;
  title: string;
  kind?: ArticleKind;
  status: ArticleStatusJa;
  status_code: ArticleStatus;
  created_at: string;
  created_at_with_second: string;
  updated_at: string;
  updated_at_with_second: string;
  liked?: boolean;
  num_likes: number;
  num_comments: number;
  cover: string | null;
  company: {
    id: string;
    name: string;
  };
  students_linking: ArticleAffiliateLinking[];
  common_tags: CommonTagBase[];
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type InterviewRequestStatusJa =
  | "\u627F\u8A8D\u5F85\u3061"
  | "\u975E\u627F\u8A8D"
  | "\u9032\u884C\u4E2D"
  | "\u5B8C\u4E86"
  | "\u7121\u52B9";
export type InterviewRequestStatus =
  | "idle"
  | "non_approved"
  | "approved"
  | "completed"
  | "invalided";
export type ProfessorInfoBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    request_count?: number;
    position?: string | null;
    department?: string | null;
    description?: string | null;
    limit_request?: number | null;
    profile_picture?: string | null;
  };
};
export type InterviewRequestArticleBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  professor_affiliate: ProfessorInfoBase;
};
export type ArticleBase = ArticleBaseCommon & {
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
  interview_requests?: InterviewRequestArticleBase[] | null;
};
export type ArticleOrderColumn = "status" | "updated_at" | "created_at";
export type ArticleOrderType = "asc" | "desc";
export type ArticleGetType = "all";
export type ArticleImageBase = ModelBase & {
  file?: {
    title?: string;
    thumb_url?: string;
    file_url?: string;
  };
};
export type AllowedArticleCategorizableType =
  | "Sponsor::Info"
  | "Pbl::Sponsor::Project";
export type ArticleCategorizable = ModelBase & {
  type: AllowedArticleCategorizableType;
  name: string;
  sponsor_logo: string | null;
  company_name?: string | null;
};
export type ArticleContentType =
  | "theme"
  | "background"
  | "hypothesis"
  | "survey_method"
  | "result"
  | "summary"
  | "future_issues";
export type ArticleContent = ModelBase & {
  content_type: ArticleContentType;
  body: string | null;
};
export type ArticleSponsorContent = ModelBase & {
  pbl_sponsor_article_content_template_field_id: string;
  body: string | null;
};
export type SponsorArticleContentTemplateFieldBase = ModelBase & {
  title: string;
};
export type SponsorArticleContentTemplateBase = ModelBase & {
  title: string;
  description?: string | null;
  pbl_sponsor_lecture_id?: string;
  fields?: SponsorArticleContentTemplateFieldBase[];
};
export type ArticlePoster = {
  sponsor_logo: string | null;
  categorizable?: ArticleCategorizable;
  article_contents: ArticleContent[];
  article_sponsor_contents?: ArticleSponsorContent[];
  pbl_sponsor_article_content_template?: SponsorArticleContentTemplateBase | null;
};
export type GroupAffiliatePackageBase = ModelBase & {
  title: string;
  description?: string;
  code: string | null;
  created_at: string;
  updated_at: string;
  affiliate: AffiliateBase;
  affiliates: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    grade?: string;
    nickname?: string;
  }[];
  assign_teacher_affiliates?: AffiliateName[];
};
export type TeacherSponsorProjectListBase = ModelBase & {
  sponsor_info_id: string;
  name: string;
  company_name: string;
};
export type ArticleDetailBase = ArticleBaseCommon & {
  body: string;
  display_student_names_for_content_provider?: boolean | null;
  display_comments_for_content_provider?: boolean | null;
  images: ArticleImageBase[];
  poster?: ArticlePoster;
  allowed_group_affiliates: GroupAffiliatePackageBase[];
  interview_requests: InterviewRequestArticleBase[];
  pbl_sponsor_project?: TeacherSponsorProjectListBase;
  template?: {
    id: string;
    description?: string | null;
  } | null;
};
export type ReviewerCompany = ModelBase & {
  name: string;
};
export type Article = ArticleDetailBase & {
  companies?: ReviewerCompany[] | null;
};
export type ArticleCommentImageBase = ModelBase & {
  title: string | null;
  url: string | null;
};
export type ArticleComment = ModelBase & {
  article_id: string;
  created_at: string;
  body: string;
  commentor_id: string | null;
  commentor_company_name?: string | null;
  commenter_name?: string | null;
  commenter_nickname?: string | null;
  commentor_affiliate_id?: string | null;
  role?: RoleBase;
  article_comment_images?: ArticleCommentImageBase[];
};
export type ArticleFeedback = ModelBase & {
  article_id: string;
  teacher_id: string;
  created_at: string;
  body: string;
  teacher?: {
    first_name: string;
    last_name: string;
  };
};
export type WordCloudWord = {
  text: string;
  value: number;
};
export type WordCloudBase = {
  word_cloud_list: WordCloudWord[];
  total_count: number;
};
export type FileRequiredRequestBody = {
  file: Blob;
};
export type PblReportCommentBase = {
  id?: string;
  message?: string;
  created_at?: string;
  commented_by?: string;
};
export type PblReportAnswerStatusEnum = "todo" | "drafting" | "submitted";
export type PblReportAnswerBase = ModelBase & {
  student: string;
  student_nickname?: string | null;
  status: string;
  status_code?: PblReportAnswerStatusEnum;
};
export type PblReportAnswerForStudent = PblReportAnswerBase & {
  template: ModelBase & {
    title?: string;
    due_date_time?: string;
  };
};
export type PblReportAnswerStudentEventEnum = "draft" | "submit";
export type PblReportTextBase = {
  id: string;
  sequence: number;
  title?: string | null;
  description?: string | null;
  example?: string | null;
  text_min_char_size?: number | null;
  has_title_required?: boolean | null;
};
export type PblReportAnswerTextBase = {
  id: string;
  title?: string;
  body?: string;
  item_id: string;
};
export type PblReportAnswerTextBaseWithComments = PblReportAnswerTextBase & {
  comments: PblReportCommentBase[];
};
export type PblReportAnswerImageBase = ModelBase & {
  file?: {
    title?: string;
    thumb_url?: string;
    file_url?: string;
  };
};
export type PblReportAnswerDetailsForStudent = PblReportAnswerForStudent & {
  items: PblReportTextBase[];
  answer_items: PblReportAnswerTextBaseWithComments[];
  comments: PblReportCommentBase[];
  answer_images: PblReportAnswerImageBase[];
};
export type PblReportAnswerImageRequest = {
  answer_id: string;
  file: Blob;
};
export type PblCardBookmarkSourceType =
  | "newspaper"
  | "internet"
  | "book"
  | "other";
export type StampableType = "Pbl::Cards::InformationLiteracy::Bookmark";
export type StampType = "good" | "nice" | "great" | "surprised";
export type StampBase = ModelBase & {
  stampable_type: StampableType;
  stampable_id: string;
  affiliate_id: string;
  user_name: string;
  kind: StampType;
  created_at: string;
};
export type StampableStampFields = {
  stamp_total_count: number;
  own_stamp_fields: StampBase[];
  kinds: {
    good_counts: number;
    nice_counts: number;
    great_counts: number;
    surprised_counts: number;
  };
};
export type PblCardBookmarkBase = ModelBase & {
  source_type: string;
  source_type_code: PblCardBookmarkSourceType;
  student_name: string;
  student_nickname?: string | null;
  title?: string;
  url: string | null;
  memo: string;
  wysiwyg_body?: string | null;
  created_at: string;
  updated_at: string;
  num_comments: number;
  images: {
    id: string;
    title?: string | null;
    url: string;
  }[];
  allowed_group_affiliates?: GroupAffiliatePackageMinimalBase[];
  stamp_fields: StampableStampFields;
  creator_affiliate_id?: string | null;
  student_number?: number | null;
  common_tags: CommonTagBase[];
  chat_room_id: string | null;
};
export type PblCardBookmarkList = PblCardBookmarkBase & {
  read_status: boolean;
};
export type PostPblCardBookmarkRequestBody = {
  source_type?: PblCardBookmarkSourceType;
  title?: string;
  url?: string;
  memo?: string;
  wysiwyg_body?: string;
  files?: Blob[];
  add_group_affiliate_ids?: string[];
  common_tag_names?: string[];
};
export type PutPblCardBookmarkRequestBody = PostPblCardBookmarkRequestBody & {
  remove_group_affiliate_ids?: string[];
};
export type PutPblCardBookmarkWysiwygBodyRequestBody = {
  wysiwyg_body?: string;
};
export type PblCardCommentBase = ModelBase & {
  commentor_id: string;
  commenter_name: string;
  commentor_role: Role | null;
  body: string;
  created_at: string;
};
export type SteamLibraryContentDifficulty = "easy" | "normal" | "difficult";
export type SteamLibraryContentAspect = "familiar" | "japan" | "world";
export type PblSteamContentBase = ModelBase & {
  sl_content_id: number;
  title: string;
  difficulty: SteamLibraryContentDifficulty;
  aspect: SteamLibraryContentAspect;
  is_scientific: boolean;
  has_carousel: boolean;
  created_at: string;
  updated_at: string;
};
export type PblSteamContent = PblSteamContentBase & {
  bookmark: {
    id: string | null;
  };
  recommendation: {
    id: string | null;
  };
};
export type SteamLibraryContentWorkProgress =
  | "not_viewed"
  | "viewing"
  | "viewed";
export type StudentPblSteamContentDetail = PblSteamContent & {
  progress?: SteamLibraryContentWorkProgress;
};
export type PblSteamLecture = ModelBase & {
  pbl_steam_content_id: string;
  sl_lecture_id: number;
  title?: string;
  sequence: number;
  created_at: string;
  updated_at: string;
};
export type CreateSteamLectureRequestBody = {
  title: string;
  sl_lecture_id: number;
  sequence: number;
};
export type PblSteamCommentBase = ModelBase & {
  pbl_steam_content_id: string;
  pbl_steam_lecture_id: string;
  affiliate: AffiliateBase;
  is_public?: boolean;
  body?: string;
  created_at: string;
  updated_at: string;
};
export type StudentPblSteamContentWork = ModelBase & {
  student: AffiliateBase;
  pbl_steam_content_id: string;
  progress?: string;
  created_at: string;
  updated_at: string;
};
export type StudentLectureHistory = ModelBase & {
  student_affiliate_id: string;
  pbl_steam_content_id: string;
  pbl_steam_lecture_id: string;
  sl_content_id: number;
  sl_lecture_id: number;
  title: string;
  created_at: string;
  updated_at: string;
};
export type StudentLectureStats = {
  viewing_contents_count: number;
  completed_contents_count: number;
  viewed_lectures_count: number;
  total_lectures_count: number;
  steam_contents?:
    | {
        id?: string;
        sl_content_id?: number;
        title?: string;
        steam_content_works?:
          | {
              id: string;
              progress: string;
            }[]
          | null;
        lectures?:
          | {
              id: string;
              sl_lecture_id: number;
              title: string;
            }[]
          | null;
        lecture_histories?:
          | {
              id: string;
              sl_lecture_id: number;
              title: string;
            }[]
          | null;
      }[]
    | null;
};
export type StudentLectureHistoryCount = ModelBase & {
  title: string;
  viewed_lectures_count: number;
};
export type ContentRecommendationBase = ModelBase & {
  company_id: string;
  company_name: string;
  content: PblSteamContentBase;
};
export type CustomProfileBody = {
  user_last_name?: string | null;
  user_first_name?: string | null;
  email?: string | null;
  number?: number | null;
  birth_date?: string | null;
  grade?: string | null;
  phone?: string | null;
  parent_last_name?: string | null;
  parent_first_name?: string | null;
  postcode?: string | null;
  prefecture?: string | null;
  address1?: string | null;
  address2?: string | null;
};
export type CustomValueRequestBody = CustomProfileBody & {
  value?: string;
  group_affiliate_package_id?: string;
  homework_id: string;
  custom_master_id?: string;
  wysiwyg_body?: string;
};
export type DeletionProgress = "running" | "failed" | "idle";
export type TeacherPblPlanItemBase = ModelBase & {
  class_minutes?: number;
  plan_years?: number;
  class_start_date?: string;
  class_end_date?: string;
  policy_summary?: string;
  why?: string;
  how?: string;
  what?: string;
  first_year_summary?: string;
  second_year_summary?: string;
  third_year_summary?: string;
  status: string;
  students_count: number;
  created_at: string;
  updated_at: string;
  deletion_progress: DeletionProgress;
};
export type TeacherPblPlanDay = ModelBase & {
  pbl_plan_item_id: string;
  class_date?: string;
  start_time?: string;
  created_at: string;
  updated_at: string;
};
export type TeacherPblPlanItemDetails = TeacherPblPlanItemBase & {
  days: TeacherPblPlanDay[];
};
export type PlanContentsCategorizableType =
  | "Article"
  | "HomeworkTemplateDistribution"
  | "Homework"
  | "Material"
  | "Pbl::Report::Template"
  | "Pbl::Sponsor::Lecture"
  | "Pbl::Steam::Content"
  | "Pbl::Steam::Lecture"
  | "CustomContent";
export type PblPlanContentKind = "learn" | "make";
export type TeacherPblPlanContent = ModelBase & {
  pbl_plan_class_id: string;
  categorizable_type: PlanContentsCategorizableType;
  categorizable_id: string;
  categorizable_ja_model_name: string;
  categorizable_title: string;
  categorizable_related_ids: string[];
  kind: PblPlanContentKind;
  sl_lecture_id?: number | null;
  is_project_list?: boolean;
};
export type TeacherPblPlanClass = ModelBase & {
  pbl_plan_item_id: string;
  year?: number;
  number?: number;
  date?: string;
  description?: string;
  is_skip?: boolean;
  article_creation_time?: boolean;
  created_at: string;
  updated_at: string;
  plan_contents?: TeacherPblPlanContent[];
  schedule_id?: string | null;
  class_minutes?: number | null;
  title?: string | null;
};
export type NumberOfEachStatus =
  | {
      status: HomeworkStatus;
      count: number;
    }[]
  | null;
export type PblPlanContentNav = ModelBase &
  TeacherPblPlanContent & {
    image_url?: string | null;
    number_of_each_homework_status?: NumberOfEachStatus;
  };
export type InquiryAffiliateBase = {
  affiliate_id?: string | null;
  has_deleted: boolean;
  company_id?: string | null;
  company_name?: string | null;
  user_id?: string | null;
  user_name?: string | null;
  email?: string | null;
  role?: RoleBase | null;
};
export type InquiryCommentCount = {
  count: number;
  is_new: boolean;
};
export type InquirySimpleListBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  created_at: string;
  updated_at: string;
  comments: InquiryCommentCount;
};
export type InquiryComment = ModelBase & {
  user: {
    affiliate_id?: string;
    company_id?: string;
    company_name?: string;
    user_id?: string;
    user_name?: string;
  };
  status: string;
  body: string;
  created_at: string;
  updated_at: string;
};
export type AffiliateCompanyAndUserBase = {
  affiliate_id: string;
  user_id: string;
  company_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: RoleBase;
  nickname: string | null;
};
export type InquirySimpleBase = ModelBase & {
  user_id: string;
  user: InquiryAffiliateBase;
  kind: string;
  title: string;
  body: string;
  category?: string | null;
  os_name: string;
  os_version: string;
  browser_name: string;
  browser_version: string;
  created_at: string;
  updated_at: string;
  comments: InquiryComment[];
  target_affiliates: AffiliateCompanyAndUserBase[];
};
export type SponsorSalesChannel = "direct_selling" | "selling_agency";
export type SponsorImageBase = {
  id: string;
  company_id: string;
  url: string | null;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type VideoFileBase = {
  url: string | null;
};
export type SponsorVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorInfoListBase = ModelBase & {
  company_id: string;
  logo_url: string | null;
  name: string;
  description?: string;
  is_public: boolean;
  sales_channel?: SponsorSalesChannel | null;
  created_at: string;
  updated_at: string;
  project_count: number;
  image: SponsorImageBase | null;
  video_content?: SponsorVideoContentBase | null;
  tags: SponsorTagBase[] | null;
};
export type SponsorInfosSearchType = "name" | "description" | "tag";
export type SponsorInfoBase = ModelBase & {
  company_id: string;
  logo_url: string | null;
  name: string;
  description?: string;
  is_public: boolean;
  sales_channel?: SponsorSalesChannel | null;
  created_at: string;
  updated_at: string;
  project_count: number;
  image: SponsorImageBase | null;
  video_content?: SponsorVideoContentBase | null;
  tags: SponsorTagBase[] | null;
};
export type SponsorCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: {
    url?: string | null;
  };
};
export type SponsorProjectTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorLectureVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorProjectImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorProjectWorkProgressJa =
  | "\u53D7\u8B1B\u4E2D"
  | "\u53D7\u8B1B\u6E08\u307F";
export type SponsorProjectAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description?: string;
  display_allowed: boolean;
  display_in_carousel: boolean;
  created_at: string;
  updated_at: string;
  tags: SponsorProjectTagBase[] | null;
  file?: {
    url?: string | null;
  };
  video_content?: SponsorLectureVideoContentBase | null;
  image?: SponsorProjectImageBase | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
  project_bookmark?: ModelBase | null;
  project_recommend?: ModelBase | null;
};
export type SponsorInfoDetailsBase = SponsorInfoBase & {
  sponsor_logo_url: string | null;
  categories: SponsorCategoryBase[] | null;
  projects: SponsorProjectAndTagsBase[] | null;
};
export type SponsorProjectsSearchType = "name" | "description" | "tag";
export type SponsorProjectVideoContentBase = {
  id: string;
  company_id: string;
  url: string | null;
  file: VideoFileBase;
  thumbnail?: {
    original: string | null;
    large: string | null;
    medium: string | null;
    small: string | null;
  };
  encoded_path?: string | null;
  cookie?: CookieInfo;
  external_link?: string | null;
} | null;
export type SponsorProjectCategoryBase = ModelBase & {
  company_id: string;
  name: string;
  description?: string;
  file?: {
    url?: string | null;
  };
};
export type SponsorLectureImageBase = {
  id: string;
  company_id: string;
  url?: string;
  thumb?: {
    url: string | null;
  };
  icon?: {
    url: string | null;
  };
} | null;
export type SponsorLectureTagBase = ModelBase & {
  company_id: string;
  name: string;
};
export type SponsorLectureAndTagsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content?: SponsorLectureVideoContentBase | null;
  tags?: SponsorLectureTagBase[] | null;
};
export type SponsorProjectDetailsBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description: string;
  display_allowed?: boolean;
  file?: {
    url?: string | null;
  };
  created_at: string;
  updated_at: string;
  image?: SponsorProjectImageBase | null;
  video_content?: SponsorProjectVideoContentBase | null;
  tags: SponsorProjectTagBase[] | null;
  categories: SponsorProjectCategoryBase[] | null;
  lectures: SponsorLectureAndTagsBase[] | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
  lecture_viewed_count: number;
  professor_affiliate_ids?: string[] | null;
  is_recommend: boolean;
  company_name?: string;
  sponsor_logo_url?: string | null;
};
export type SponsorProjectBase = ModelBase & {
  sponsor_info_id: string;
  lecture_count: number;
  company_id: string;
  name: string;
  description?: string;
  display_allowed: boolean;
  display_in_carousel: boolean;
  created_at: string;
  updated_at: string;
  file?: {
    url?: string | null;
  };
  image?: SponsorProjectImageBase | null;
  video_content?: SponsorProjectVideoContentBase | null;
  project_work?: {
    id?: string;
    progress?: SponsorProjectWorkProgressJa;
  };
};
export type SponsorProjectsSortType = "sequence" | "recent" | "random";
export type SponsorLectureFileBase = ModelBase & {
  company_id: string;
  file: {
    title: string | null;
    url: string | null;
  };
};
export type Status = "\u65B0\u898F" | "\u78BA\u8A8D\u6E08\u307F";
export type SponsorLectureDetailsBase = ModelBase & {
  sponsor_info_id: string;
  project_id: string;
  company_id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
  image?: SponsorLectureImageBase | null;
  video_content: SponsorLectureVideoContentBase | null;
  tags: SponsorLectureTagBase[] | null;
  files: SponsorLectureFileBase[] | null;
  learning_progress?: {
    id?: string;
    status?: Status;
  };
  article_content_template?: {
    id: string;
    title: string;
    description?: string | null;
  } | null;
  company_name?: string;
  sponsor_logo_url?: string | null;
  project_name?: string;
};
export type Schema = ModelBase & {
  pbl_sponsor_project_id: string;
  student: AffiliateBase;
  progress: SponsorProjectWorkProgressJa;
  created_at: string;
  updated_at: string;
};
export type SponsorProjectWorkProgress = "viewing" | "viewed";
export type PblProjectCommentBase = ModelBase & {
  pbl_sponsor_project_id: string;
  pbl_sponsor_lecture_id: string;
  affiliate: AffiliateBase;
  body?: string;
  created_at: string;
  updated_at: string;
};
export type HasTotalCount = {
  total_count: number;
};
export type DailyLogBase = ModelBase & {
  date?: string;
  study_time?: number;
  category?: string;
  category_status_code?: string;
  memo?: string;
  created_at?: string;
  updated_at?: string;
  student?: AffiliateName;
};
export type DailyLogCategory =
  | "theme_settings"
  | "research"
  | "analysis"
  | "summary";
export type StudyLogBasicBase = ModelBase & {
  log: string;
  categorizable_type: string;
  categorizable_id: string;
  behavior: string;
  created_at: string;
  categorizable?: {
    parent_category_id: string | null;
    is_discarded: boolean;
  };
};
export type StudyLogBase = StudyLogBasicBase & {
  student: AffiliateName;
};
export type StudyLogCategorizableType =
  | "Article"
  | "Homework"
  | "MaterialWork"
  | "Pbl::Cards::InformationLiteracy::Bookmark"
  | "Pbl::Report::Answer"
  | "Pbl::Steam::Comment"
  | "Pbl::Steam::ContentWork"
  | "Schedule";
export type StudyLogBehavior =
  | "saved"
  | "updated"
  | "registered"
  | "submitted"
  | "resubmitted"
  | "draft_saved"
  | "draft_updated"
  | "draft_created"
  | "attend_started"
  | "attend_finished"
  | "attend_completed"
  | "return_to_before_attend"
  | "return_to_during_attend"
  | "commented"
  | "confirmed_by_teacher";
export type DeliverableListBaseAsStudent = {
  material_works: {
    count?: number;
    todo_count?: number;
    in_progress_count?: number;
  };
  homeworks: {
    count?: number;
    todo_count?: number;
    change_requested_count?: number;
  };
  articles: {
    count?: number;
    draft_count?: number;
    correction_count?: number;
  };
  learning_packages: {
    count?: number;
  };
  bookmarks: {
    count?: number;
  };
  ideals: {
    count?: number;
    todo_count?: number;
    in_progress_count?: number;
  };
  answers: {
    count?: number;
  };
};
export type StudentLogCategorizableType =
  | "Article"
  | "Homework"
  | "MaterialWork"
  | "LearningPackage"
  | "Pbl::Report::Answer"
  | "Ideal::Board"
  | "Pbl::Cards::InformationLiteracy::Bookmark";
export type StudentLogDeliverableListBase = ModelBase & {
  title: string;
  updated_at: string;
  categorizable_type: StudentLogCategorizableType;
};
export type StudentLogDeliverableMaterialWorkBase =
  StudentLogDeliverableListBase & {
    status: MaterialWorkStatusJa;
    status_code: MaterialWorkStatus;
    parent_id: string;
  };
export type StudentLogDeliverableHomeWorkBase =
  StudentLogDeliverableListBase & {
    status: HomeworkStatusJa;
    status_code: HomeworkStatus;
    parent_id: string;
  };
export type LearningPackageStatusJa = "\u672A\u5B8C\u4E86" | "\u5B8C\u4E86";
export type LearningPackageStatus = "incompleted" | "completed";
export type StudentLogDeliverableLearningPackageBase =
  StudentLogDeliverableListBase & {
    status: LearningPackageStatusJa;
    status_code: LearningPackageStatus;
    parent_id: string;
  };
export type StudentLogDeliverableArticleBase = StudentLogDeliverableListBase & {
  status: ArticleStatusJa;
  status_code: ArticleStatus;
};
export type StudentLogDeliverableIdealBase = StudentLogDeliverableListBase & {
  status: IdealBoardStatusJa;
  status_code: IdealBoardStatus;
};
export type LearningPackageBase = ModelBase & {
  title: string;
  description?: string;
  status: LearningPackageStatusJa;
  start_at?: string;
  end_at?: string;
  created_at: string;
  updated_at: string;
  affiliate: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    company_id: string;
    company_name: string;
  };
};
export type LearningProgressCategorizableType =
  | "Pbl::Steam::Lecture"
  | "Pbl::Sponsor::Lecture";
export type LearningProgressBase = ModelBase & {
  categorizable_type?: LearningProgressCategorizableType;
  categorizable_id?: string;
  status?: "\u65B0\u898F" | "\u78BA\u8A8D\u6E08\u307F";
  sl_lecture_id?: number | null;
};
export type LearningPackageBaseDetails = LearningPackageBase & {
  packagings?: {
    id: string;
    packageable_type: string;
    packageable_id?: string;
    title: string | null;
    status?: string | null;
    packagings?:
      | {
          id?: string;
          packageable_type?: string;
          packageable_id?: string;
          title?: string;
          status?: string;
          start_at?: string | null;
          learning_progress?: LearningProgressBase;
        }[]
      | null;
  }[];
};
export type AffiliateIdName = {
  id: string;
  first_name: string;
  last_name: string;
};
export type AffiliateAvatar = AffiliateIdName & {
  avatar?: Avatar;
};
export type BoardList = BoardBase & {
  invited_affiliates: AffiliateAvatar[];
  allowed_group_affiliates: GroupAffiliatePackageMinimalBase[];
};
export type GroupAffiliatePackageBaseAvatar =
  GroupAffiliatePackageMinimalBase & {
    affiliates: AffiliateAvatar[];
  };
export type Board = BoardBase & {
  data: string | null;
  host_affiliate?: AffiliateName;
  host_role?: RoleBase;
  invited_affiliates: AffiliateAvatar[];
  allowed_group_affiliates: GroupAffiliatePackageBaseAvatar[];
};
export type IdealBoardEvent = "start" | "complete" | "restart";
export type GroupAffiliatePackageList = ModelBase & {
  title: string;
  created_at: string;
  updated_at: string;
  affiliates: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
  }[];
  assign_teacher_affiliates?: AffiliateName[];
};
export type LimitedTimeNotificationNoticeBase = ModelBase & {
  title: string;
  message: string;
  checked: boolean;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
};
export type AllowedGroupAffiliateCategorizableType = "Article" | "Ideal::Board";
export type LearningProgressEvent = "check" | "restart";
export type ProfessorAffiliateListBase = AffiliateName & {
  company?: HasIdAndName;
  professor_info: {
    position?: string | null;
    department?: string | null;
    description?: string | null;
  };
};
export type ArticleInterviewRequestBase = ModelBase & {
  title: string;
  assign_user_names: string | null;
  company: {
    id: string;
    name: string;
  };
};
export type InterviewRequestListBase = ModelBase & {
  status: InterviewRequestStatusJa;
  status_code: InterviewRequestStatus;
  confirm_date: string | null;
  has_preferred_dates: boolean;
  has_feedback_dates: boolean;
  professor_affiliate: ProfessorAffiliateListBase;
  article: ArticleInterviewRequestBase;
};
export type InterviewRequestSearchType =
  | "company_name"
  | "professor_name"
  | "article_title"
  | "student_name";
export type PreferredDateBase = ModelBase & {
  choice_number: number;
  date: string;
};
export type InterviewRequestDetailBase = InterviewRequestListBase & {
  student_message: string | null;
  professor_message: string | null;
  is_online: boolean;
  place: string | null;
  preferred_dates?: PreferredDateBase[];
  unread_message_ids?: string[];
};
export type InterviewRequestUnreadCountBase = ModelBase & {
  count: number;
};
export type InterviewMessageKind = "person" | "info";
export type InterviewRequestListBase2 = ModelBase & {
  message: string;
  kind: InterviewMessageKind;
  created_at: string;
  updated_at: string;
  sender_affiliate: AffiliateInfoBase;
  attached_images?: AttachedImageBase[];
};
export type InterviewFeedbackBase = ModelBase & {
  created_at: string;
  updated_at: string;
  student_affiliate: AffiliateInfoBase;
  is_request_send_material?: boolean;
  description?: string;
  degree_of_interest?: number;
  degree_of_learning?: number;
};
export type HelpMasterKind = "article_poster";
export type HelpMasterBase = ModelBase & {
  kind: HelpMasterKind;
};
export type HasIdAndTitle = {
  id: string;
  title: string;
};
export type HelpContentBase = ModelBase & {
  help_no: number;
  material?: HasIdAndTitle;
};
export type HasName = {
  name: string;
};
export type ChatRoomDetailBase = ModelBase & {
  bookmark: HasIdAndTitle;
};
export type AffiliateMessageBase = {
  affiliate_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  role_name: string;
  nickname: string | null;
  avatar: Avatar;
};
export type ChatRoomMessageListBase = ModelBase & {
  message: string;
  message_wysiwyg_body?: string | null;
  created_at: string;
  sender_affiliate: AffiliateMessageBase;
};
export type ChatRoomMessageDetail = ChatRoomMessageListBase & {
  bookmark_wysiwyg_body?: string | null;
};
export type MessageRequiredRequestBody = {
  message: string;
};
export type Schema2 = "name";
export type CommonTaggingCategorizableType =
  | "Article"
  | "Pbl::Cards::InformationLiteracy::Bookmark"
  | "Homework"
  | "HomeworkTemplate";
export type PostCommonTagKind = "free" | "management";
export type CommonTaggingRequestBody = {
  tag_name: string;
  kind: PostCommonTagKind;
};
export const {
  useGetUserStudentsAsStudentQuery,
  useGetUserStudentAffiliatesAsStudentQuery,
  useGetStudentUsersByUserIdQuery,
  usePutStudentUsersByUserIdMutation,
  useDeleteStudentUsersByUserIdMutation,
  useGetStudentMaterialsQuery,
  useGetStudentMaterialsByMaterialIdQuery,
  useGetStudentMaterialWorksQuery,
  useFetchOneMaterialWorkAsStudentQuery,
  usePutStudentMaterialWorksByMaterialWorkIdMutation,
  usePostStudentMaterialWorksByMaterialWorkIdUploadImagesMutation,
  usePostStudentMaterialWorksByMaterialWorkIdSaveImageMutation,
  usePutStudentMaterialWorksByMaterialWorkIdUpdateImageMutation,
  useDeleteMaterialWorkImageAsStudentMutation,
  useGetMaterialWorksCategoryListAsStudentQuery,
  useGetMaterialWorksTagListAsStudentQuery,
  useGetStudentPaymentsQuery,
  useGetStudentPaymentsByPaymentIdQuery,
  usePutStudentPaymentsByPaymentIdMutation,
  useGetStudentHomeworksQuery,
  useGetStudentHomeworksByHomeworkIdQuery,
  usePutStudentHomeworksByHomeworkIdMutation,
  useUploadHomeworkImagesAsStudentMutation,
  useSaveEditedHomeworkImageAsStudentMutation,
  useUpdateHomeworkImageAsStudentMutation,
  useDeleteHomeworkImageAsStudentMutation,
  useGetHomeworkStatusesAsStudentQuery,
  useGetStudentCompaniesByCompanyIdQuery,
  useGetCompanyLimitOptionAsStudentQuery,
  useSaveTempDataOfHomeworkImageAsStudentMutation,
  useDeleteTempDataOfHomeworkImageAsStudentMutation,
  useGetStudentReportsQuery,
  useGetStudentReportsByReportIdQuery,
  useGetStudentCategoriesQuery,
  useGetStudentNotificationsQuery,
  useGetStudentArticlesQuery,
  usePostStudentArticlesMutation,
  useGetStudentArticlesByArticleIdQuery,
  usePutStudentArticlesByArticleIdMutation,
  useDeleteStudentArticlesByArticleIdMutation,
  usePostStudentArticlesByArticleIdSubmitToTeacherMutation,
  useGetStudentArticlesByArticleIdCommentsQuery,
  usePostArticleCommentsAsStudentMutation,
  usePutArticleCommentAsStudentMutation,
  useDeleteArticleCommentAsStudentMutation,
  useGetStudentArticlesByArticleIdFeedbacksQuery,
  useGetStudentArticleReviewerCompaniesQuery,
  usePostArticleAffiliatesAsStudentMutation,
  useDeleteArticleAffiliatesAsStudentMutation,
  useFetchArticleStatsAsStudentQuery,
  useFetchArticleWordCloudAsStudentQuery,
  usePostArticleImagesAsStudentMutation,
  usePutArticleImagesAsStudentMutation,
  useDeleteControllerAsStudentMutation,
  usePutStudentPblReportCommentsByIdMutation,
  useGetStudentPblReportCommentsQuery,
  usePostStudentPblReportCommentsMutation,
  useFetchPblReportAnswersAsStudentQuery,
  useUpdatePblReportAnswerAsStudentMutation,
  useFetchPblReportAnswerAsStudentQuery,
  usePostPblReportAnswerImagesAsStudentMutation,
  usePutPblReportAnswerImagesAsStudentMutation,
  useDeletePblReportAnswerImagesAsStudentMutation,
  useUpdatePblReportAnswerItemAsStudentMutation,
  useCreatePblReportAnswerItemAsStudentMutation,
  useFetchPblCardBookmarksAsStudentQuery,
  useCreatePblCardBookmarkAsStudentMutation,
  useFetchPblCardBookmarkAsStudentQuery,
  useUpdatePblCardBookmarkAsStudentMutation,
  useDeletePblCardBookmarkAsStudentMutation,
  useUpdatePblCardBookmarkWysiwygBodyAsStudentMutation,
  useDeletePblCardBookmarkImageAsStudentMutation,
  useFetchPblCardBookmarkCommentsAsStudentQuery,
  useCreatePblCardBookmarkCommentAsStudentMutation,
  useUpdatePblCardBookmarkCommentAsStudentMutation,
  useDeletePblCardBookmarkCommentAsStudentMutation,
  useGetPblCardBookmarkStampsAsStudentQuery,
  usePostPblCardBookmarkStampAsStudentMutation,
  usePutPblCardBookmarkStampAsStudentMutation,
  useDeletePblCardBookmarkStampAsStudentMutation,
  useGetStudentGoogleIntegrationsQuery,
  useGetStudentGoogleIntegrationsByCourseIdQuery,
  useGetStudentGoogleIntegrationsByCourseIdAndCourseWorkIdQuery,
  useGetSteamContentsAsStudentQuery,
  useGetStudentSteamContentsIdQuery,
  useSearchStudentSteamContentsQuery,
  usePostStudentSteamLecturesMutation,
  useGetStudentSteamLecturesIdQuery,
  useGetStudentSteamLectureCommentsQuery,
  useGetStudentSteamCommentsQuery,
  usePostStudentSteamCommentsMutation,
  usePutStudentSteamContentsIdMutation,
  useDeleteStudentSteamContentsIdMutation,
  useGetStudentSteamContentWorksQuery,
  usePostStudentSteamContentWorksMutation,
  useGetStudentSteamContentWorksIdQuery,
  usePutStudentSteamContentWorksIdMutation,
  useGetSteamLectureHistoriesAsStudentQuery,
  usePostSteamLectureHistoriesAsStudentMutation,
  useFetchSteamLectureHistoryStatsAsStudentQuery,
  useFetchSteamLectureHistoryCountAsStudentQuery,
  useDeleteSteamLectureHistoryAsStudentMutation,
  useGetSteamContentRecommendationsAsStudentQuery,
  usePostStudentHomeworkTemplateCustomValuesMutation,
  usePutStudentHomeworkTemplateCustomValuesByIdMutation,
  useDeleteStudentHomeworkTemplateCustomValuesByIdMutation,
  useGetPblPlanItemsAsStudentQuery,
  useGetPblPlanClassesAsStudentQuery,
  useGetPblPlanContentsAsStudentQuery,
  useGetStudentInquiriesQuery,
  useGetStudentInquiriesByIdQuery,
  useGetTheNumberOfNewCommentInquiriesAsStudentQuery,
  usePostStudentInquiryCommentsMutation,
  usePutStudentInquiryCommentsByIdMutation,
  useDeleteStudentInquiryCommentsByIdMutation,
  useGetStudentSponsorInfosQuery,
  useGetStudentSponsorInfosIdQuery,
  useFetchSponsorProjectsAsStudentQuery,
  useFetchOneSponsorProjectAsStudentQuery,
  useFetchSponsorProjectsForCarouselAsStudentQuery,
  useFetchSponsorProjectStatsAsStudentQuery,
  useFetchSponsorLecturesAsStudentQuery,
  useFetchSponsorLectureAsStudentQuery,
  usePostPblSponsorProjectWorkAsStudentMutation,
  usePutSponsorProjectWorkAsStudentMutation,
  useGetSponsorContentsAsStudentQuery,
  usePostPblSponsorCommentAsStudentMutation,
  usePutPblSponsorCommentAsStudentMutation,
  useDeletePblSponsorCommentsStudentMutation,
  useGetSponsorArticleContentTemplateAsStudentQuery,
  useGetTodosAsStudentQuery,
  useGetDailyLogsAsStudentQuery,
  usePostDailyLogAsStudentMutation,
  useGetDailyLogAsStudentQuery,
  usePutDailyLogAsStudentMutation,
  useDeleteDailyLogAsStudentMutation,
  useGetStudyLogsAsStudentQuery,
  usePostStudyLogAsStudentMutation,
  useGetStudyLogAsStudentQuery,
  usePutStudentStudyLogsByIdMutation,
  useGetStudyLogsDeliverableListAsStudentQuery,
  useGetStudyLogsMaterialWorksAsStudentQuery,
  useGetStudyLogsHomeworksAsStudentQuery,
  useGetStudyLogsLearningPackagesAsStudentQuery,
  useGetStudyLogsArticlesAsStudentQuery,
  useGetStudyLogsIdealsAsStudentQuery,
  useGetStudyLogsBookmarksAsStudentQuery,
  useGetLearningPackagesAsStudentQuery,
  useGetLearningPackageAsStudentQuery,
  usePutLearningPackageAsStudentMutation,
  useGetIdealBoardsAsStudentQuery,
  usePostIdealBoardsAsStudentMutation,
  useGetIdealBoardAsStudentQuery,
  usePutIdealBoardAsStudentMutation,
  useDeleteIdealBoardAsStudentMutation,
  usePostIdealBoardsAffiliatesAsStudentMutation,
  useDeleteIdealBoardsAffiliatesAsStudentMutation,
  useGetGroupAffiliatePackagesAsStudentQuery,
  usePostAttachedImageAsStudentMutation,
  useGetAttachedImageAsStudentQuery,
  usePutAttachedImageAsStudentMutation,
  useDeleteAttachedImageAsStudentMutation,
  useGetLimitedTimeNotificationNoticeAsStudentQuery,
  useGetLimitedTimeNotificationNoticeDetailsAsStudentQuery,
  usePostLimitedTimeNotificationNoticeCheckAsStudentMutation,
  usePatchAllowedGroupAffiliatesBatchUpdateAsStudentMutation,
  usePostLearningProgressAsStudentMutation,
  usePutLearningProgressAsStudentMutation,
  useGetInterviewRequestsAsStudentQuery,
  useGetInterviewRequestAsStudentQuery,
  usePutInterviewRequestAsStudentMutation,
  useGetInterviewRequestArticleAsStudentQuery,
  useGetInterviewRequestUnreadCountsAsStudentQuery,
  useGetInterviewMessagesAsStudentQuery,
  usePostInterviewMessageAsStudentMutation,
  useGetInterviewMessageReadAsStudentMutation,
  usePutInterviewMessageAsStudentMutation,
  useDeleteInterviewMessageAsStudentMutation,
  useGetInterviewFeedbacksAsStudentQuery,
  usePostInterviewRequestAsStudentMutation,
  useFetchHelpContentsAsStudentQuery,
  useGetCustomMastersAsStudentQuery,
  useGetCustomMastersSelectListAsStudentQuery,
  useGetChatRoomAsStudentQuery,
  usePostChatRoomAsStudentMutation,
  useGetChatRoomMessagesAsStudentQuery,
  usePostChatRoomMessageAsStudentMutation,
  usePutChatRoomMessageAsStudentMutation,
  useDeleteChatRoomMessageAsStudentMutation,
  useGetCommonTagsAsStudentQuery,
  usePostCommonTaggingAsStudentMutation,
  useDeleteCommonTaggingAsStudentMutation,
} = injectedRtkApi;
