// TODO: immerの利用を検討する。 https://immerjs.github.io/
// import _ from "lodash";
import { ModelBase } from "./Api";

export function add<T extends ModelBase>(collection: T[], newItem: T): T[] {
  const duplicatedIndex = collection.findIndex(
    (item) => item.id === newItem.id,
  );
  if (duplicatedIndex === -1) {
    collection.push(newItem);
    return collection;
  }
  throw new Error("There's duplicated item");
}

export function get<T extends ModelBase>(
  collection: T[],
  id: string,
  key?: string,
): T | undefined {
  return collection.find((item) => {
    const newItem = item as T & { [key: string]: string };
    return key ? newItem[key] === id : newItem.id === id;
  });
}

export function update<T extends ModelBase>(
  collection: T[],
  changedItem: T,
): T[] {
  const index = collection.findIndex((item) => item.id === changedItem.id);
  const newCollection = [...collection];
  if (index >= 0) {
    newCollection.splice(index, 1, changedItem);
  } else {
    newCollection.push(changedItem);
  }
  return newCollection;
}

export function updateMany<T extends ModelBase>(
  collection: T[],
  changedItems: T[],
): T[] {
  const newCollection = [...collection];
  changedItems.forEach((changedItem) => {
    const index = collection.findIndex((item) => item.id === changedItem.id);
    newCollection.splice(index, 1, changedItem);
  });
  return newCollection;
}

export function remove<T extends ModelBase>(collection: T[], id: string): T[] {
  const index = collection.findIndex((item) => item.id === id);
  if (index !== -1) {
    // Remove the old object
    const filtered = collection.filter((item) => item.id !== id);
    return filtered;
  }
  return collection;
}

export function removeMany<T extends ModelBase>(
  collection: T[],
  ids: string[],
): T[] {
  const copy = [...collection];
  const updatedCollection = ids.reduce((accumulator, id) => {
    const removingItem = get(copy, id);
    if (removingItem) {
      const newCollection = remove(accumulator, id);
      return newCollection;
    }
    return accumulator;
  }, copy);
  return updatedCollection;
}

export function removeChildren<
  CategoryInfo,
  KeyType extends keyof CategoryInfo,
>(
  collection: CategoryInfo[],
  id: CategoryInfo[KeyType],
  key?: KeyType,
): CategoryInfo[] {
  if (key) {
    const filtered = collection.filter((item) => item[key] !== id);
    return filtered;
  }
  return collection;
}
