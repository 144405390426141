import { StudentMaterialWork } from "@lib/Api";
import { update } from "@lib/collection";
import { createSlice, current } from "@reduxjs/toolkit";

import {
  fetchMaterialWorksAsStudent,
  fetchOneMaterialWorkAsStudent,
  fileUploaded,
  resetActionState,
  resetModalState,
  saveMaterialWorkImageAsStudent,
  updatedMaterialWork,
  updateMaterialWorkAsStudent,
} from "../../actions/studentApp/materialWork";
import { RootState } from "../../store";

export interface StudentAppMaterialWorkState {
  fetching: boolean;
  fetchingOne: boolean;
  updating: boolean;
  updated: boolean;
  uploaded: boolean;
  error: any;
  materialWorks: StudentMaterialWork[];
  materialWork: StudentMaterialWork | null;
  totalCount: number;
}

export const initialState: StudentAppMaterialWorkState = {
  fetching: false,
  fetchingOne: false,
  updating: false,
  updated: false,
  uploaded: false,
  materialWorks: [],
  materialWork: null,
  error: null,
  totalCount: 0,
};

const studentAppMaterialWorkSlice = createSlice({
  name: "StudentApp/MaterialWork",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterialWorksAsStudent.pending, (state, _action) => {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      })
      .addCase(fetchMaterialWorksAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          error: action.error.message as string,
        };
      })
      .addCase(fetchMaterialWorksAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          materialWorks: payload.material_works,
          totalCount: payload.total_count,
        };
      })
      .addCase(fetchOneMaterialWorkAsStudent.pending, (state, _action) => {
        return {
          ...state,
          materialWork: null,
          fetchinOne: true,
          error: null,
        };
      })
      .addCase(fetchOneMaterialWorkAsStudent.rejected, (state, action) => {
        return {
          ...state,
          fetchingOne: false,
          error: action.error.message as string,
        };
      })
      .addCase(fetchOneMaterialWorkAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOne: false,
          materialWork: payload,
        };
      })
      .addCase(updateMaterialWorkAsStudent.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          error: null,
        };
      })
      .addCase(updateMaterialWorkAsStudent.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          error: action.error.message as string,
        };
      })
      .addCase(updateMaterialWorkAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          updating: false,
          materialWork: payload,
          materialWorks: update(state.materialWorks, payload as any),
        };
      })
      .addCase(saveMaterialWorkImageAsStudent.pending, (state, _action) => {
        return {
          ...state,
          updating: true,
          error: null,
        };
      })
      .addCase(saveMaterialWorkImageAsStudent.rejected, (state, action) => {
        return {
          ...state,
          updating: false,
          error: action.error.message as string,
        };
      })
      .addCase(saveMaterialWorkImageAsStudent.fulfilled, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updating: false,
          materialWork: payload,
          materialWorks: update(currentState.materialWorks, payload as any),
        };
      })
      .addCase(updatedMaterialWork, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          updated: true,
          updating: false,
          materialWork: payload,
          materialWorks: update(currentState.materialWorks, payload),
        };
      })
      .addCase(fileUploaded, (state, action) => {
        const { payload } = action;
        const currentState = current(state);
        return {
          ...state,
          uploaded: true,
          updating: false,
          materialWork: payload,
          materialWorks: update(currentState.materialWorks, payload),
        };
      })
      .addCase(resetActionState, (state, _action) => {
        return {
          ...state,
          updated: false,
          updating: false,
          uploaded: false,
        };
      })
      .addCase(resetModalState, (state, _action) => {
        return {
          ...state,
          updated: false,
          updating: false,
          uploaded: false,
          fetchingOne: false,
          error: false,
          materialWork: null,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const studentAppMaterialWorkState = (
  state: RootState,
): StudentAppMaterialWorkState => state.studentApp.materialWork;

export default studentAppMaterialWorkSlice.reducer;
