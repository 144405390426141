/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ITEMS_PER_PAGE } from "@constants/page";
import {
  useGetStudentLogsArticlesAsTeacherQuery,
  useGetStudentLogsHomeworksAsTeacherQuery,
  useGetStudentLogsLearningPackagesAsTeacherQuery,
  useGetStudentLogsMaterialWorksAsTeacherQuery,
  useGetStudentLogsPblAnswersAsTeacherQuery,
} from "@root/endpoints/TimeTactApi/teacherApi";
import { useState } from "react";

export enum LogsContent {
  ARTICLE = "article",
  HOMEWORKS = "homeworks",
  LEARNING_PACKAGE = "learningPackages",
  MATERIAL_WORKS = "materialWorks",
  REPORTS = "reports",
}

type StudyLogsByContentStatesType = {
  currentPage: number;
  selectedContent: LogsContent | null;
  openModule: boolean;
};

type QueryDataReturn =
  | ReturnType<typeof useGetStudentLogsMaterialWorksAsTeacherQuery>
  | ReturnType<typeof useGetStudentLogsHomeworksAsTeacherQuery>
  | ReturnType<typeof useGetStudentLogsPblAnswersAsTeacherQuery>
  | ReturnType<typeof useGetStudentLogsLearningPackagesAsTeacherQuery>
  | ReturnType<typeof useGetStudentLogsArticlesAsTeacherQuery>;

export const useHandleStudyLogsByContentType = (
  studentAffiliateId: string,
  start_date: string | null = null,
  end_date: string | null = null,
) => {
  const defaultStates = {
    currentPage: 1,
    selectedContent: null,
    openModule: false,
  };

  const [
    { selectedContent, openModule, currentPage },
    setStudyLogsByContentStates,
  ] = useState<StudyLogsByContentStatesType>(defaultStates);

  const handlePagination = (newPage: number) => {
    setStudyLogsByContentStates((prev) => ({ ...prev, currentPage: newPage }));
  };

  const handleSelectedContent = (type: LogsContent) => {
    if (type === selectedContent) {
      setStudyLogsByContentStates(defaultStates);
      return;
    }
    setStudyLogsByContentStates({
      currentPage: 1,
      selectedContent: type,
      openModule: true,
    });
  };

  const commonParams = {
    studentAffiliateId,
    page: currentPage,
    perPage: ITEMS_PER_PAGE,
    ...(start_date && end_date
      ? {
          startDate: start_date as string,
          endDate: end_date as string,
        }
      : {}),
  };

  const materialWorksQuery = useGetStudentLogsMaterialWorksAsTeacherQuery(
    commonParams,
    { skip: selectedContent !== LogsContent.MATERIAL_WORKS },
  );
  const homeWorksQuery = useGetStudentLogsHomeworksAsTeacherQuery(
    commonParams,
    { skip: selectedContent !== LogsContent.HOMEWORKS },
  );
  const reportsQuery = useGetStudentLogsPblAnswersAsTeacherQuery(commonParams, {
    skip: selectedContent !== LogsContent.REPORTS,
  });
  const learningPackageQuery = useGetStudentLogsLearningPackagesAsTeacherQuery(
    commonParams,
    { skip: selectedContent !== LogsContent.LEARNING_PACKAGE },
  );
  const articlesQuery = useGetStudentLogsArticlesAsTeacherQuery(commonParams, {
    skip: selectedContent !== LogsContent.ARTICLE,
  });

  const queryDataName: Record<LogsContent, string> = {
    [LogsContent.MATERIAL_WORKS]: "material_works",
    [LogsContent.HOMEWORKS]: "homeworks",
    [LogsContent.REPORTS]: "pbl_answers",
    [LogsContent.LEARNING_PACKAGE]: "learning_packages",
    [LogsContent.ARTICLE]: "articles",
  };

  const handleQuery = () => {
    if (!selectedContent) return {};
    const getQuery = (): QueryDataReturn => {
      if (selectedContent === LogsContent.MATERIAL_WORKS) {
        return materialWorksQuery;
      }
      if (selectedContent === LogsContent.HOMEWORKS) {
        return homeWorksQuery;
      }
      if (selectedContent === LogsContent.REPORTS) {
        return reportsQuery;
      }
      if (selectedContent === LogsContent.LEARNING_PACKAGE) {
        return learningPackageQuery;
      }
      if (selectedContent === LogsContent.ARTICLE) {
        return articlesQuery;
      }
      throw new Error("Invalid Query");
    };

    const { data, isFetching, error } = getQuery();

    const logsList = data?.[queryDataName[selectedContent]] || [];
    const totalCount = data?.total_count || 0;
    return {
      logsList,
      totalCount,
      isFetching,
      error,
    };
  };

  const dataQuery = handleQuery();
  return {
    ...dataQuery,
    currentPage,
    openModule,
    selectedContent,
    handlePagination,
    handleSelectedContent,
  };
};
