import { closeToast } from "@actions/toast";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { Alert,Grow, IconButton } from "@mui/material";
import { toastState } from "@reducers/toast";
import { SnackbarKey, useSnackbar } from "notistack";
import React from "react";

import { useAppDispatch, useAppSelector } from "./hooks";

let displayed: SnackbarKey[] = [];

const useToast = (): void => {
  const dispatch = useAppDispatch();
  const { toasts } = useAppSelector(toastState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  const handleExited = (event: any, id: SnackbarKey) => {
    // remove this snackbar from redux store
    dispatch(closeToast({ id, dismissAll: false }));
    removeDisplayed(id);
  };

  const handleCloseAlert = (event: any, id: SnackbarKey) => {
    closeSnackbar(id);
    handleExited(event, id);
  };

  React.useEffect(() => {
    toasts.forEach(({ id, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(id);
        handleExited(null, id);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(id)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key: id,
        ...options,
        preventDuplicate: true,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Grow,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: handleExited,
        content: (myKey, myMessage) => (
          <Alert
            key={myKey}
            data-cy="alert-toast"
            variant="standard"
            severity={options.variant}
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="inherit" />,
              error: <WarningOutlinedIcon fontSize="inherit" />,
            }}
            action={
              options.action ? (
                options.action(myKey)
              ) : (
                <IconButton
                  aria-label="閉じる"
                  color="inherit"
                  size="small"
                  onClick={(e) => handleCloseAlert(e, myKey)}
                >
                  <CloseIcon fontSize="inherit" color="inherit" />
                </IconButton>
              )
            }
            style={{ whiteSpace: "pre-line" }}
          >
            {myMessage}
          </Alert>
        ),
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(id);
    });
  }, [toasts, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useToast;
