import { combineReducers } from "redux";

import articleReducer from "./article";
import studentClassRoomReducer from "./classRoom";
import companyReducer from "./company";
import todosReducer from "./dashboard/studentTodos";
import homeworkReducer from "./homework";
import idealBoardReducer from "./ideal/board";
import idealBoardsAffiliateReducer from "./ideal/boardsAffiliate";
import learningPackagesReducer from "./learning_packages";
import materialReducer from "./material";
import materialWorkReducer from "./materialWork";
import paymentReducer from "./payment";
import pblCardBookmarkReducer from "./pbl/cards/information_literacy/bookmark";
import pblCardBookmarkCommentReducer from "./pbl/cards/information_literacy/comment";
import pblReportAnswerReducer from "./pbl/report/answer";
import pblSponsorInfoReducer from "./pbl/sponsors/info";
import pblSponsorLectureReducer from "./pbl/sponsors/lecture";
import pblSponsorProjectReducer from "./pbl/sponsors/project";
import reportReducer from "./report";
import steamCommentReducer from "./steam/steamComment";
import steamContentReducer from "./steam/steamContent";
import steamContentWorkReducer from "./steam/steamContentWorks";
import steamLectureReducer from "./steam/steamLecture";
import steamLectureHistoriesReducer from "./steam/steamLectureHistories";
import steamRecommendationsReducer from "./steam/steamRecommendations";
import studentLogsReducer from "./student_logs";
import usersReducer from "./user";

const studentAppReducer = combineReducers({
  payment: paymentReducer,
  material: materialReducer,
  materialWork: materialWorkReducer,
  report: reportReducer,
  homework: homeworkReducer,
  article: articleReducer,
  todos: todosReducer,
  users: usersReducer,
  pblReportAnswer: pblReportAnswerReducer,
  pblCardBookmark: pblCardBookmarkReducer,
  pblCardBookmarkComment: pblCardBookmarkCommentReducer,
  pblSponsorInfo: pblSponsorInfoReducer,
  pblSponsorProject: pblSponsorProjectReducer,
  pblSponsorLecture: pblSponsorLectureReducer,
  company: companyReducer,
  classRoom: studentClassRoomReducer,
  studentLogs: studentLogsReducer,
  learningPackage: learningPackagesReducer,
  steamContentWork: steamContentWorkReducer,
  steamComment: steamCommentReducer,
  steamLecture: steamLectureReducer,
  steamContent: steamContentReducer,
  steamRecommendations: steamRecommendationsReducer,
  steamLectureHistories: steamLectureHistoriesReducer,
  idealBoard: idealBoardReducer,
  idealBoardsAffiliate: idealBoardsAffiliateReducer,
});

export default studentAppReducer;
