/* eslint-disable react/no-array-index-key */
import { Box, Skeleton,Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    skeltonWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      padding: theme.spacing(0, 1),
      width: "100%",
    },
    skelton: {
      borderRadius: theme.spacing(1),
      width: 200,
      height: 30,
    },
  }),
);

const NavigationMenuSkelton: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.skeltonWrapper}>
      {new Array(5).fill("").map((_, index) => (
        <Skeleton
          key={index}
          className={classes.skelton}
          variant="rectangular"
        />
      ))}
    </Box>
  );
};

export default NavigationMenuSkelton;
