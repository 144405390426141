/* eslint-disable camelcase */
import { Api, FetchMaterialsAsTeacherParams } from "@lib/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetResponseType } from "@root/types/ApiResponse";
import { RoleApiFunctions } from "@root/types/materials";
import { RootState } from "@store/store";

const api = new Api();

export const fetchMaterialAsTeacher = createAsyncThunk<
  GetResponseType<Api["teacher"]["getTeacherMaterialsMaterialId"]>,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("teacher/material/fetchOne", async (materialId, { rejectWithValue }) => {
  try {
    const response =
      await api.teacher.getTeacherMaterialsMaterialId(materialId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchMaterialsByCategoryAsTeacher = createAsyncThunk<
  GetResponseType<Api["teacher"]["fetchMaterialsAsTeacher"]>,
  FetchMaterialsAsTeacherParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>(
  "teacher/material/fetchByCategory",
  async (params: FetchMaterialsAsTeacherParams, { rejectWithValue }) => {
    try {
      const response = await api.teacher.fetchMaterialsAsTeacher(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

type TeacherActionsType = {
  teacher: RoleApiFunctions;
};

export const teacherMaterialsActions: TeacherActionsType = {
  teacher: {
    getMaterials: fetchMaterialsByCategoryAsTeacher,
  },
};
