/* eslint-disable camelcase */
import {
  Api,
  GetHomeworkChunksAsReviewerParams,
  HomeworkChunkDetail,
  HomeworkChunkList,
  HomeworkChunkStatuses,
} from "@lib/Api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const api = new Api();

export const getHomeworkChunksAsReviewer = createAsyncThunk<
  {
    homework_chunks: HomeworkChunkList[];
    homework_chunk_statuses: HomeworkChunkStatuses;
    total_count: number;
  },
  GetHomeworkChunksAsReviewerParams,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("reviewer/homeworkChunk/fetch", async (params, { rejectWithValue }) => {
  try {
    const response = await api.reviewer.getHomeworkChunksAsReviewer(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getHomeworkChunkAsReviewer = createAsyncThunk<
  HomeworkChunkDetail,
  string,
  { state: RootState; rejectValue: any; rejectedMeta: void }
>("reviewer/homeworkChunk/fetchOne", async (params, { rejectWithValue }) => {
  try {
    const response = await api.reviewer.getHomeworkChunkAsReviewer(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateHomeworkChunkAsReviewer = createAction(
  "reviewer/homeworkChunk/update",
  (arg) => {
    return {
      payload: arg,
    };
  },
);

export const resetModalState = createAction(
  "reviewer/homeworkChunk/resetModalState",
);
