import CircularLoading from "@components/UI/organisms/CircularLoading/CircularLoading";
import V2Pagination from "@components/UIv2/molecules/V2Pagination";
import { ITEMS_PER_PAGE } from "@constants/page";
import { ModalStatus } from "@containers/SuperOwner/LearningTemplatePackages/LearningTemplateListContainer";
import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { superOwnerApi } from "@root/endpoints/TimeTactApi/superOwnerApi";
import React, { useState } from "react";

import PblProjectAccordion from "./PblProjectAccordion";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      borderTop: `2px solid ${theme.palette.learningTemplatePackage.lightGrey}`,
      padding: theme.spacing(2, 0),
    },
    descriptionBox: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderImage: theme.palette.dialogBorder.gradient,
      borderImageSlice: 1,
      fontWeight: 500,
      fontSize: 16,
      padding: theme.spacing(1, 3),
      marginBottom: theme.spacing(1),
    },
    accordionRoot: {
      boxShadow: "none",
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    accordion: {
      margin: theme.spacing(1, 0),
    },
    accordionSummary: {
      backgroundColor: "rgba(0, 130, 103, 0.05)",
    },
    contentTitle: {
      color: theme.palette.customColor.text,
      fontWeight: 700,
      fontSize: 16,
    },
  }),
);

type Props = {
  status: ModalStatus;
  chosenPackage: string[];
  handleChosePackage: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PblProjectList: React.FC<Props> = ({
  status,
  chosenPackage,
  handleChosePackage,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState<number>(1);

  const projectsResult =
    superOwnerApi.endpoints.fetchSponsorProjectsAsSuperOwner.useQuery({
      page,
      perPage: ITEMS_PER_PAGE,
    });
  const projects = projectsResult.data?.projects || [];
  const totalCount = projectsResult.data?.total_count ?? 0;

  return projectsResult.isFetching ? (
    <CircularLoading />
  ) : (
    <Box className={classes.main}>
      <Box className={classes.descriptionBox}>プロジェクト・レクチャー一覧</Box>
      {projects.length ? (
        <>
          {projects.map((project) => (
            <PblProjectAccordion
              key={project.id}
              status={status}
              project={project}
              chosenPackage={chosenPackage}
              handleChosePackage={handleChosePackage}
            />
          ))}
          <V2Pagination
            totalCount={totalCount}
            perPage={ITEMS_PER_PAGE}
            currentPage={page}
            handlePageTransition={setPage}
          />
        </>
      ) : (
        <Box m={2}>
          <Typography>データが見つかりませんでした。</Typography>
        </Box>
      )}
    </Box>
  );
};

export default PblProjectList;
