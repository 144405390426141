import {
  getPblBoardCampaignRequestAsContentProvider,
  pblBoardCampaignRequestSequenceRejected,
  pblBoardCampaignRequestSequenceSuccess,
  putPblBoardCampaignRequestAsContentProvider,
  putPblBoardCampaignRequestSequenceAsContentProvider,
  resetContentProviderAppPblBoardCampaignRequestModalState,
  resetContentProviderAppPblBoardCampaignRequestState,
} from "@actions/contentProviderApp/pblBoard/campaignRequest";
import { PblBoardCampaignRequestList } from "@lib/Api";
import { update } from "@lib/collection";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import { RootState } from "@store/store";

export interface ContentProviderAppPblBoardCampaignRequestState {
  fetchPblBoardCampaignRequests: ReduxStatusType;
  putPblBoardCampaignRequest: ReduxStatusType;
  campaignRequestSequence: ReduxStatusType;
  putPblBoardCampaignRequestError: string | null;
  pblBoardCampaignRequests: PblBoardCampaignRequestList[];
  totalCount: number;
  sortedSuccess: boolean;
  sortedRejected: boolean;
}

export const initialState: ContentProviderAppPblBoardCampaignRequestState = {
  fetchPblBoardCampaignRequests: ReduxStatus.idle,
  putPblBoardCampaignRequest: ReduxStatus.idle,
  campaignRequestSequence: ReduxStatus.idle,
  putPblBoardCampaignRequestError: null,
  pblBoardCampaignRequests: [],
  totalCount: 0,
  sortedSuccess: false,
  sortedRejected: false,
};

const contentProviderAppPblBoardCampaignRequestSlice = createSlice({
  name: "contentProviderApp/pblBoard/campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getPblBoardCampaignRequestAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            fetchPblBoardCampaignRequests: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        getPblBoardCampaignRequestAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            fetchPblBoardCampaignRequests: ReduxStatus.rejected,
            pblBoardCampaignRequests: [],
          };
        },
      )
      .addCase(
        getPblBoardCampaignRequestAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            fetchPblBoardCampaignRequests: ReduxStatus.fulfilled,
            pblBoardCampaignRequests: payload.board_campaign_requests,
            totalCount: payload.total_count,
          };
        },
      )
      .addCase(
        putPblBoardCampaignRequestSequenceAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            campaignRequestSequence: ReduxStatus.pending,
          };
        },
      )
      .addCase(
        putPblBoardCampaignRequestSequenceAsContentProvider.rejected,
        (state, _action) => {
          return {
            ...state,
            campaignRequestSequence: ReduxStatus.rejected,
          };
        },
      )
      .addCase(
        putPblBoardCampaignRequestSequenceAsContentProvider.fulfilled,
        (state, _action) => {
          return {
            ...state,
            campaignRequestSequence: ReduxStatus.fulfilled,
          };
        },
      )
      .addCase(
        putPblBoardCampaignRequestAsContentProvider.pending,
        (state, _action) => {
          return {
            ...state,
            putPblBoardCampaignRequest: ReduxStatus.pending,
            putPblBoardCampaignRequestError: null,
          };
        },
      )
      .addCase(
        putPblBoardCampaignRequestAsContentProvider.rejected,
        (state, action) => {
          return {
            ...state,
            putPblBoardCampaignRequest: ReduxStatus.rejected,
            putPblBoardCampaignRequestError: errorWithMessage(action.payload),
          };
        },
      )
      .addCase(
        putPblBoardCampaignRequestAsContentProvider.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            putPblBoardCampaignRequest: ReduxStatus.fulfilled,
            pblBoardCampaignRequests: update(
              state.pblBoardCampaignRequests,
              payload,
            ),
            putPblBoardCampaignRequestError: null,
          };
        },
      )
      .addCase(
        resetContentProviderAppPblBoardCampaignRequestState,
        (state, _action) => {
          return {
            ...state,
            fetchPblBoardCampaignRequests: ReduxStatus.idle,
            pblBoardCampaignRequests: [],
            pblBoardCampaignRequest: null,
          };
        },
      )
      .addCase(pblBoardCampaignRequestSequenceSuccess, (state, _action) => {
        return {
          ...state,
          sortedSuccess: true,
        };
      })
      .addCase(pblBoardCampaignRequestSequenceRejected, (state, _action) => {
        return {
          ...state,
          sortedRejected: true,
        };
      })
      .addCase(
        resetContentProviderAppPblBoardCampaignRequestModalState,
        (state, _action) => {
          return {
            ...state,
            putPblBoardCampaignRequest: ReduxStatus.idle,
            putPblBoardCampaignRequestError: null,
            sortedSuccess: false,
            sortedRejected: false,
          };
        },
      )
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const contentProviderAppPblBoardCampaignRequestBoardState = (
  state: RootState,
): ContentProviderAppPblBoardCampaignRequestState =>
  state.contentProviderApp.pblBoardCampaignRequest;

export default contentProviderAppPblBoardCampaignRequestSlice.reducer;
