import {
  createUserTagging,
  createUserTagType,
  deleteUserTagging,
  deleteUserTagType,
  fetchLimitedUserTagsAsTeacher,
  fetchStudentTaggingByUserTag,
  fetchUserTagsAsTeacher,
  fetchUserTagsAsTeacherForTagging,
  fetchUserTagTypesAsTeacher,
  isRequestsRunning,
  resetBatchCountState,
  resetState,
  updateTeacherUserTagsSequence,
  userTaggingsBatchCreatePartialUpdateAsTeacher,
  userTagRejected,
  userTagSuccess,
} from "@actions/teacherApp/userTag";
import { BasicUserInfo } from "@lib/Api";
import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";
import ApiResponseType from "@root/types/ApiResponse";
import { RootState } from "@store/store";

export interface TeacherAppUserTagState {
  fetched: boolean;
  fetching: boolean;
  fetchError: any;
  fetchingTagTypes: boolean;
  fetchingAll: boolean;
  fetchedAll: boolean;
  userTags: NonNullable<
    ApiResponseType["teacher"]["fetchUserTagsAsTeacher"]["user_tags"]
  >;
  limitedUserTags: NonNullable<
    ApiResponseType["teacher"]["fetchUserTagsAsTeacher"]["user_tags"]
  >;
  userTagTypes: NonNullable<
    ApiResponseType["teacher"]["userTagTypesList"]["user_tag_types"]
  >;
  limitedUserTagTypes: NonNullable<
    ApiResponseType["teacher"]["userTagTypesList"]["user_tag_types"]
  >;
  allTagTypes: NonNullable<
    ApiResponseType["teacher"]["userTagTypesList"]["user_tag_types"]
  >;
  totalCount: number;
  changing: boolean;
  changed: boolean;
  batchErrors: any[];
  batchCount: number;
  batchWarnings: string[];
  fetchingStudents: boolean;
  fetchedStudents: boolean;
  students: BasicUserInfo[];
  createUserTagType: ReduxStatusType;
  deleteUserTagType: ReduxStatusType;
  updateTagSequence: ReduxStatusType;
  isRequestsRunning: ReduxStatusType;
  success: boolean;
  rejected: boolean;
}

export const initialState: TeacherAppUserTagState = {
  fetched: false,
  fetching: false,
  fetchError: null,
  fetchingTagTypes: false,
  fetchingAll: false,
  fetchedAll: false,
  userTags: [],
  limitedUserTags: [],
  userTagTypes: [],
  limitedUserTagTypes: [],
  allTagTypes: [],
  totalCount: 0,
  changing: false,
  changed: false,
  batchErrors: [],
  batchCount: 0,
  fetchingStudents: false,
  fetchedStudents: false,
  students: [],
  batchWarnings: [],
  createUserTagType: ReduxStatus.idle,
  deleteUserTagType: ReduxStatus.idle,
  updateTagSequence: ReduxStatus.idle,
  isRequestsRunning: ReduxStatus.idle,
  success: false,
  rejected: false,
};

export const rejectedState = (
  state: TeacherAppUserTagState,
  action: PayloadAction<
    any,
    string,
    {
      arg: any;
      requestId: string;
      rejectedWithValue: boolean;
      requestStatus: "rejected";
      aborted: boolean;
      condition: boolean;
    },
    SerializedError
  >,
): TeacherAppUserTagState => {
  const tagName = action.meta.arg.tag_name;
  return {
    ...state,
    changing: false,
    changed: false,
    batchErrors: [
      ...state.batchErrors,
      { tagName, errors: action.payload.errors },
    ],
    batchCount: state.batchCount + 1,
  };
};

const fetchingState = (state: TeacherAppUserTagState) => {
  return { ...state, fetching: true, fetched: false };
};

const teacherAppUserTagSlice = createSlice({
  name: "TeacherApp/UserTag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserTagTypesAsTeacher.pending, (state, _action) => {
        return {
          ...state,
          fetchingTagTypes: true,
          fetched: false,
        };
      })
      .addCase(fetchUserTagTypesAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetchingTagTypes: false,
          fetched: false,
          fetchError: action.error.message,
        };
      })
      .addCase(fetchUserTagTypesAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingTagTypes: false,
          fetched: true,
          userTagTypes: action.payload.user_tag_types,
          totalCount: action.payload.total_count,
        };
      })
      .addCase(fetchUserTagsAsTeacher.pending, (state, _action) => {
        return fetchingState(state);
      })
      .addCase(fetchUserTagsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetchError: action.error.message,
          reports: [],
        };
      })
      .addCase(fetchUserTagsAsTeacher.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetching: false,
          userTags: payload.user_tags,
          fetched: true,
          fetchError: null,
        };
      })
      .addCase(fetchLimitedUserTagsAsTeacher.pending, (state, _action) => {
        return fetchingState(state);
      })
      .addCase(fetchLimitedUserTagsAsTeacher.rejected, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: action.error.message,
        };
      })
      .addCase(fetchLimitedUserTagsAsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          fetching: false,
          fetched: true,
          limitedUserTags: action.payload.user_tags,
        };
      })
      .addCase(fetchUserTagsAsTeacherForTagging.pending, (state, _action) => {
        return {
          ...state,
          fetchingAll: true,
          fetchedAll: false,
          fetchError: null,
        };
      })
      .addCase(fetchUserTagsAsTeacherForTagging.rejected, (state, action) => {
        return {
          ...state,
          fetchingAll: false,
          fetchedAll: false,
          fetchError: action.error.message,
        };
      })
      .addCase(fetchUserTagsAsTeacherForTagging.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingAll: false,
          fetchedAll: true,
          allTagTypes: action.payload.user_tag_types,
        };
      })
      .addCase(fetchStudentTaggingByUserTag.pending, (state, _action) => {
        return {
          ...state,
          fetchingStudents: true,
          fetchedStudents: false,
          students: [],
        };
      })
      .addCase(fetchStudentTaggingByUserTag.fulfilled, (state, action) => {
        return {
          ...state,
          fetchingStudents: false,
          fetchedStudents: true,
          students: action.payload.users,
        };
      })
      .addCase(fetchStudentTaggingByUserTag.rejected, (state, action) => {
        return {
          ...state,
          fetchingStudents: false,
          fetchError: action.payload.error,
        };
      })
      .addCase(createUserTagging.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(createUserTagging.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(createUserTagging.rejected, (state, action) => {
        return rejectedState(state, action);
      })
      .addCase(deleteUserTagging.pending, (state, _action) => {
        return {
          ...state,
          changing: true,
          changed: false,
        };
      })
      .addCase(deleteUserTagging.fulfilled, (state, _action) => {
        return {
          ...state,
          changing: false,
          changed: true,
          batchCount: state.batchCount + 1,
        };
      })
      .addCase(
        userTaggingsBatchCreatePartialUpdateAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            changing: true,
            changed: false,
          };
        },
      )
      .addCase(
        userTaggingsBatchCreatePartialUpdateAsTeacher.fulfilled,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            changing: false,
            changed: true,
            batchCount: state.batchCount + 1,
            batchWarnings: [...state.batchWarnings, ...payload.errors],
          };
        },
      )
      .addCase(
        userTaggingsBatchCreatePartialUpdateAsTeacher.rejected,
        (state, action) => {
          const { payload } = action;
          return {
            ...state,
            changing: false,
            changed: false,
            batchErrors: [payload.errors],
          };
        },
      )
      .addCase(deleteUserTagging.rejected, (state, action) => {
        return rejectedState(state, action);
      })
      .addCase(createUserTagType.pending, (state, _action) => {
        return {
          ...state,
          createUserTagType: ReduxStatus.pending,
        };
      })
      .addCase(createUserTagType.fulfilled, (state, _action) => {
        return {
          ...state,
          createUserTagType: ReduxStatus.fulfilled,
        };
      })
      .addCase(createUserTagType.rejected, (state, action) => {
        return {
          ...state,
          createUserTagType: ReduxStatus.rejected,
        };
      })
      .addCase(deleteUserTagType.pending, (state, _action) => {
        return {
          ...state,
          deleteUserTagType: ReduxStatus.pending,
        };
      })
      .addCase(deleteUserTagType.fulfilled, (state, _action) => {
        return {
          ...state,
          deleteUserTagType: ReduxStatus.fulfilled,
        };
      })
      .addCase(deleteUserTagType.rejected, (state, action) => {
        return {
          ...state,
          deleteUserTagType: ReduxStatus.rejected,
        };
      })
      .addCase(userTagSuccess, (state) => {
        return {
          ...state,
          success: true,
        };
      })
      .addCase(userTagRejected, (state) => {
        return {
          ...state,
          rejected: true,
        };
      })
      .addCase(resetBatchCountState, (state) => {
        return {
          ...state,
          batchCount: 0,
        };
      })
      .addCase(updateTeacherUserTagsSequence.pending, (state, _action) => {
        return {
          ...state,
          updateTagSequence: ReduxStatus.pending,
        };
      })
      .addCase(updateTeacherUserTagsSequence.fulfilled, (state, _action) => {
        return {
          ...state,
          updateTagSequence: ReduxStatus.fulfilled,
        };
      })
      .addCase(updateTeacherUserTagsSequence.rejected, (state, action) => {
        return {
          ...state,
          updateTagSequence: ReduxStatus.rejected,
        };
      })
      .addCase(isRequestsRunning, (state) => {
        return {
          ...state,
          isRequestsRunning: ReduxStatus.pending,
        };
      })
      .addCase(resetState, (state) => {
        return {
          ...state,
          fetching: false,
          fetched: false,
          fetchError: null,
          changing: false,
          changed: false,
          success: false,
          rejected: false,
          createUserTagType: ReduxStatus.idle,
          deleteUserTagType: ReduxStatus.idle,
          updateTagSequence: ReduxStatus.idle,
          isRequestsRunning: ReduxStatus.idle,
          batchErrors: [],
          batchWarnings: [],
          batchCount: 0,
        };
      })
      .addDefaultCase((state, _action) => {
        return state;
      });
  },
});

export const teacherAppUserTagState = (
  state: RootState,
): TeacherAppUserTagState => state.teacherApp.userTag;

export default teacherAppUserTagSlice.reducer;
