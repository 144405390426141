import SingleButton from "@components/UI/atoms/SingleButton/SingleButton";
import React from "react";

export type Props = Omit<React.ComponentProps<typeof SingleButton>, "variant">;

const V2OutlinedButton: React.FunctionComponent<Props> = (props: Props) => (
  <SingleButton {...props} />
);

export default V2OutlinedButton;
