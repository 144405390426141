import { putMaterialHomeworkTemplatePackageAsSuperOwner } from "@actions/superOwnerApp/materialHomeworkPackage/materialHomeworkPackage";
import {
  addPackagesSuccess,
  deletePackagingAsSuperOwner,
  postPackagingAsSuperOwner,
  updatePackagingAsSuperOwner,
} from "@actions/superOwnerApp/templatePackages/learningTemplatePackage";
import { SelectedButton } from "@components/UIv2/molecules/V2Tabs";
import { ReduxStatus } from "@constants/redux";
import {
  PackagingAsLearningTemplatePackageBase,
  PackagingPackageableType,
} from "@lib/Api";
import { useAppDispatch } from "@root/hooks";
import React from "react";

import { coPackageType } from ".";

type Props = {
  values: { packageTitle: string };
  templatePackage: PackagingAsLearningTemplatePackageBase | null;
  chosenPackage: coPackageType[];
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  packagedIds: string[] | undefined;
  dispatch: ReturnType<typeof useAppDispatch>;
};

const EditCoPackage = async ({
  values,
  templatePackage,
  chosenPackage,
  setErrorMessage,
  packagedIds,
  dispatch,
}: Props): Promise<void> => {
  const promiseAll: Promise<ReturnType<typeof useAppDispatch>>[] = [];

  const updateParams = {
    id: templatePackage?.material_homework_template_package?.id as string,
    params: {
      title: values.packageTitle,
      description: values.packageTitle,
    },
  };
  promiseAll.push(
    dispatch(putMaterialHomeworkTemplatePackageAsSuperOwner(updateParams)),
  );

  const getNewCheckedIds = () => {
    const packagings =
      templatePackage?.material_homework_template_package?.packagings;
    const currentPackageContents =
      (packagings &&
        packagings.length > 0 &&
        packagings.map((item) => item.packageable_id)) ||
      [];
    const chosenPackageId = (chosenPackage as coPackageType[]).map(
      (item) => item.id,
    );
    return chosenPackageId.filter((id) => !currentPackageContents.includes(id));
  };

  const newCheckedIds = getNewCheckedIds();

  if (!newCheckedIds.some((id) => packagedIds?.includes(id))) {
    chosenPackage.forEach(async (chosenItem: coPackageType) => {
      const currentPackage =
        templatePackage?.material_homework_template_package?.packagings?.find(
          (item) => item.packageable_id === chosenItem.id,
        );

      if (currentPackage) {
        const updatePackagingParams = {
          data: {
            package_id: templatePackage?.material_homework_template_package
              ?.id as string,
            packageable_type:
              chosenItem.type === SelectedButton.Primary
                ? PackagingPackageableType.Material
                : PackagingPackageableType.HomeworkTemplate,
            packageable_id: chosenItem.id,
          },
          currentPackage:
            currentPackage as PackagingAsLearningTemplatePackageBase,
        };
        promiseAll.push(
          dispatch(updatePackagingAsSuperOwner(updatePackagingParams)),
        );
      } else {
        const addPackagingParams = {
          package_id: templatePackage?.material_homework_template_package
            ?.id as string,
          packageable_type:
            chosenItem.type === SelectedButton.Primary
              ? PackagingPackageableType.Material
              : PackagingPackageableType.HomeworkTemplate,
          packageable_id: chosenItem.id,
        };
        promiseAll.push(
          dispatch(postPackagingAsSuperOwner(addPackagingParams)),
        );
      }
    });
    templatePackage?.material_homework_template_package?.packagings?.forEach(
      (currentItem) => {
        const chosenItem = chosenPackage.find(
          (item: coPackageType) => item.id === currentItem.packageable_id,
        );

        if (!chosenItem) {
          const deletePackagingParams = {
            package_id: templatePackage?.material_homework_template_package
              ?.id as string,
            packageable_type: currentItem.packageable_type,
            packageable_id: currentItem.packageable_id,
          };
          promiseAll.push(
            dispatch(deletePackagingAsSuperOwner(deletePackagingParams)),
          );
        }
      },
    );

    Promise.allSettled(promiseAll).then((resolves) => {
      const successResolve: PromiseSettledResult<any> | undefined =
        resolves.find(
          (resolve: any) =>
            resolve.value.meta.requestStatus === ReduxStatus.fulfilled,
        );
      if (successResolve) {
        dispatch(addPackagesSuccess());
      } else {
        const failedResolve: PromiseSettledResult<any> | undefined =
          resolves.find(
            (resolve: any) =>
              resolve.value.meta.requestStatus === ReduxStatus.rejected,
          );
        if (failedResolve) {
          setErrorMessage("コンテンツはすでに存在します。");
        }
      }
    });
  } else {
    setErrorMessage("コンテンツはすでに存在します。");
  }
};
export default EditCoPackage;
