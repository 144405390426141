import Tracker from "@openreplay/tracker";
import trackerAxios from "@openreplay/tracker-axios";
import trackerRedux from "@openreplay/tracker-redux";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { adminApi } from "@root/endpoints/TimeTactApi/adminApi";
import { contentProviderApi } from "@root/endpoints/TimeTactApi/contentProviderApi";
import { ownerApi } from "@root/endpoints/TimeTactApi/ownerApi";
import { remainingApi } from "@root/endpoints/TimeTactApi/remainingApi";
import { reviewerApi } from "@root/endpoints/TimeTactApi/reviewerApi";
import { studentApi } from "@root/endpoints/TimeTactApi/studentApi";
import { superOwnerApi } from "@root/endpoints/TimeTactApi/superOwnerApi";
import { teacherApi } from "@root/endpoints/TimeTactApi/teacherApi";
import { reviewerAppHomeworkApi } from "@store/services/api/reviewerApp/homework";
import { reviewerAppHomeworkChunkApi } from "@store/services/api/reviewerApp/homeworkChunk";
import { steamLibraryApi } from "@store/services/api/steamLibraryApp/steamLibraryApi";
import { studentAppHomeworkApi } from "@store/services/api/studentApp/homework";
import { studentAppMaterialWorkApi } from "@store/services/api/studentApp/materialWork";
import Cookies from "js-cookie";

import { config } from "../constants/env";
import { AUTH } from "../constants/localStorageKeys";
import rootReducer from "./reducers";
import { initialState as authInitialState } from "./reducers/auth";
import { initialState as companyInitialState } from "./reducers/company";
import { ownerAppHomeworkApi } from "./services/api/ownerApp/homework";
import { teacherAppHomeworkApi } from "./services/api/teacherApp/homework";

type TrackerReduxType = ({
  getState,
}: {
  getState: any;
}) => (next: any) => (action: any) => any;

const openReplayMiddleware: TrackerReduxType[] = [];

export const tracker =
  import.meta.env.VITE_SETTING === "test"
    ? undefined
    : new Tracker({
        projectKey: config.url.OR_KEY,
        ingestPoint: "https://openreplay.studyvalley.jp/ingest",
        __DISABLE_SECURE_MODE: true,
      });

if (
  (import.meta.env.VITE_SETTING === "production" ||
    import.meta.env.VITE_SETTING === "staging") &&
  tracker
) {
  tracker.use(trackerAxios({}));
  tracker.start();
  openReplayMiddleware.push(tracker.use(trackerRedux({})));
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(studentAppMaterialWorkApi.middleware)
      .concat(studentAppHomeworkApi.middleware)
      .concat(reviewerAppHomeworkApi.middleware)
      .concat(reviewerAppHomeworkChunkApi.middleware)
      .concat(ownerAppHomeworkApi.middleware)
      .concat(teacherAppHomeworkApi.middleware)
      .concat(adminApi.middleware)
      .concat(superOwnerApi.middleware)
      .concat(ownerApi.middleware)
      .concat(teacherApi.middleware)
      .concat(studentApi.middleware)
      .concat(reviewerApi.middleware)
      .concat(contentProviderApi.middleware)
      .concat(remainingApi.middleware)
      .concat(steamLibraryApi.middleware)
      .concat(...openReplayMiddleware),
  preloadedState: {
    auth: {
      ...authInitialState,
      accessToken: Cookies.get(AUTH.ACCESS_TOKEN),
      uid: Cookies.get(AUTH.UID),
      client: Cookies.get(AUTH.CLIENT),
    },
    company: {
      ...companyInitialState,
    },
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
