import { config } from "@root/constants/env";
import {
  AUTHENTICATION_FAILURES,
  FORBIDDEN,
  NOT_FOUND,
} from "@root/constants/httpStatus";
import { AUTH, AUTH_PROXY } from "@root/constants/localStorageKeys";
import IP_RESTRICTION from "@root/constants/Messages/responseMessage";
import { logout } from "@store/actions/auth";
import { store } from "@store/store";
import axios from "axios";
import { BrowserHistory } from "history";
import Cookies from "js-cookie";

import { UseToast } from "./hooks/useEnqueueToast";

const getCommonHeaders = () => {
  // If session storage access token exists, user is logged in as proxy
  const accessToken =
    sessionStorage.getItem(AUTH_PROXY.ACCESS_TOKEN) ||
    Cookies.get(AUTH.ACCESS_TOKEN);
  const client =
    sessionStorage.getItem(AUTH_PROXY.CLIENT) || Cookies.get(AUTH.CLIENT);
  const uid = sessionStorage.getItem(AUTH_PROXY.UID) || Cookies.get(AUTH.UID);

  return {
    "Content-Type": "application/json",
    Accept: "application/json; charset=utf-8",
    "access-token": accessToken,
    access_token: accessToken,
    client,
    uid,
  };
};

export const setupInterceptors = (
  history: BrowserHistory,
  toastActions: UseToast["actions"],
): void => {
  axios.interceptors.request.use((request) => {
    const commonHeaders = getCommonHeaders();
    request.baseURL = config.url.API_URL;
    request.headers = {
      ...request.headers,
      ...commonHeaders,
    };
    request.headers.common = {
      ...commonHeaders,
    };
    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error?.status || error?.response?.status;
      // If status is undefined, which means front-end couldn't connect to backend at all.
      switch (true) {
        case status === FORBIDDEN &&
          error?.response?.data?.message === IP_RESTRICTION:
          store.dispatch(logout());
          history.push({ pathname: "/forbidden" });
          break;
        case AUTHENTICATION_FAILURES.includes(status):
          if (status === FORBIDDEN) {
            toastActions.showError(
              "セッションが切れました。再度ログインしてください。",
              "session_error",
            );
          }
          store.dispatch(logout());
          break;
        case status >= 500:
          history.push({ pathname: "/error" });
          break;
        case status === NOT_FOUND:
          history.push({ pathname: "/not_found" });
          break;
        case status === undefined:
          toastActions.showError(
            "ネットワークエラーが発生しました。\nネットワークの状況を確認して、再度お試しください。",
            "network_error",
          );
          break;
        default:
      }
      return Promise.reject(error);
    },
  );
};
