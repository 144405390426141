/* eslint-disable camelcase */
import { MaterialDistributionHistory } from "@lib/Api";
import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAllDistributedMaterialWorksAsTeacher,
  resetState,
} from "../../actions/teacherApp/materialDistribution";
import { RootState } from "../../store";

export interface TeacherAppMaterialDistributionState {
  creating: boolean;
  created: boolean;
  error: any;
  fetching: boolean;
  fetched: boolean;
  distributedHistories: MaterialDistributionHistory[];
  totalCount: number;
}

const initialState: TeacherAppMaterialDistributionState = {
  creating: false,
  created: false,
  error: null,
  fetching: false,
  fetched: false,
  distributedHistories: [],
  totalCount: 0,
};

const teacherAppMaterialDistributionSlice = createSlice({
  name: "TeacherApp/MaterialDistribution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAllDistributedMaterialWorksAsTeacher.pending,
        (state, _action) => {
          return {
            ...state,
            fetching: true,
            fetched: false,
            error: null,
          };
        },
      )
      .addCase(
        fetchAllDistributedMaterialWorksAsTeacher.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: true,
            error: null,
            distributedHistories:
              action.payload.material_distribution_histories,
            totalCount: action.payload.total_count,
          };
        },
      )
      .addCase(
        fetchAllDistributedMaterialWorksAsTeacher.rejected,
        (state, action) => {
          return {
            ...state,
            fetching: false,
            fetched: false,
            error: action.payload,
            distributedHistories: [],
          };
        },
      )
      .addCase(resetState, (state, _action) => {
        return {
          ...state,
          created: false,
          creating: false,
          error: null,
        };
      });
  },
});

export const teacherAppMaterialDistributionState = (
  state: RootState,
): TeacherAppMaterialDistributionState => state.teacherApp.materialDistribution;

export default teacherAppMaterialDistributionSlice.reducer;
