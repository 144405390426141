/* eslint-disable @typescript-eslint/ban-types */
import { BodyItemsTypes } from "@components/UIv2/organisms/CommonTable/tableTypes";
import { InterviewRequestStatus } from "@lib/Api";
import {
  InterviewRequestListBase,
  InterviewRequestUnreadCountBase,
} from "@root/endpoints/TimeTactApi/teacherApi";
import { InterviewRequestStatusEnum } from "@root/types/interviewRequest";
import { Control, UseFormHandleSubmit, UseFormSetValue } from "react-hook-form";

export type UrlParams = {
  status: InterviewRequestStatus;
  page: number;
};
export type ModalState = {
  isOpen: boolean;
  modalInfos: BodyItemsTypes<InterviewRequestListBase>;
};

export enum SearchTypes {
  IDLE = "idle",
  COMPANY_NAME = "company_name",
  USER_NAME = "user_name",
  ARTICLE_TITLE = "article_title",
  STUDENT_NAME = "student_name",
  PROFESSOR_NAME = "professor_name",
}

export type SearchBarParamsTypes = {
  searchType: SearchTypes;
  inputValue: string;
};

export type UseHandleInterviewRequestListTypes = {
  states: {
    interviewRequests: InterviewRequestListBase[];
    totalCount: number;
    currentPage: number;
    isFetchingInterviewRequest: boolean;
    modalState: ModalState;
    currentTabValue: InterviewRequestStatusEnum;
    noDataMessage: string;
    searchType: SearchTypes;
    control: Control<SearchBarParamsTypes, object>;
    isUniversityTeacher: boolean;
    isTeacher: boolean;
    isOwner: boolean;
    unreadMessagesCounts: InterviewRequestUnreadCountBase[];
  };
  actions: {
    handlePageTransition: (newPage: number) => void;
    handleClose: () => void;
    refetchUnreadMessagesCount: () => void;
    handleTabClick: (
      event: React.SyntheticEvent,
      newValue: InterviewRequestStatusEnum,
    ) => void;
    handleModalState: (
      bodyItem: BodyItemsTypes<InterviewRequestListBase>,
    ) => void;
    onSubmitSearch: (searchValues: SearchBarParamsTypes) => void;
    handleSubmit: UseFormHandleSubmit<SearchBarParamsTypes>;
    setValue: UseFormSetValue<SearchBarParamsTypes>;
  };
};
