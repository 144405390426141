/* eslint-disable camelcase */
import {
  AllowedToDownload,
  isPublic,
  SetThumbnailData,
} from "@actions/types/material";
import { SelectState } from "@components/UI/molecules/CategorySelect/types";
import { SVImageOptions } from "@components/UI/molecules/SVEditor/Extension/Image/SVImage";
import {
  deleteStoredImageOnCanceling,
  deleteStoredImageOnSaving,
  resetStoredImage,
} from "@components/UI/molecules/SVEditor/useDOMParse";
import GenericModal from "@components/UI/organisms/Modal/GenericModal";
import { NETWORK_ERROR } from "@constants/Messages/responseMessage";
import { ReduxStatus } from "@constants/redux";
import { useAuth } from "@contexts/Auth";
import { CategoryInfo, ImageInfo, MaterialDetail } from "@lib/Api";
import { get } from "@lib/collection";
import useEnqueueToast from "@lib/hooks/useEnqueueToast";
import { safeString } from "@lib/string-utils";
import React, { createContext,useEffect, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { FormValues, MaterialImageData } from "../types";
import useMaterial from "../useMaterial";
import MaterialForm from "./Form";

type Tag = {
  id: string;
  name: string;
};

interface Props {
  material: MaterialDetail;
  handleClose: () => void;
  resetFormRelatedData: () => void;
  selectedCategoryIds: SelectState;
  setSelectedCategoryIds: React.Dispatch<React.SetStateAction<SelectState>>;
  serverError: any;
  categories: CategoryInfo[];
  isOverCapacity: boolean;
}

type EditMaterialModalMainContextType = {
  setIsUploadingVideo: React.Dispatch<React.SetStateAction<boolean>>;
};
export const EditMaterialModalMainContext =
  createContext<EditMaterialModalMainContextType>(undefined as never);

export const NO_CHANGE = "NO_CHANGE";
export const THUMBNAIL_DELETED = "THUMBNAIL_DELETED";

const EditMaterialModalMain: React.FC<Props> = (props: Props) => {
  const {
    material,
    resetFormRelatedData,
    serverError,
    categories,
    selectedCategoryIds,
    setSelectedCategoryIds,
    isOverCapacity,
  } = props;

  const { currentUser } = useAuth();
  const {
    fetchMaterial,
    updateMaterialStatus,
    updateVideoStatus,
    updateMaterial,
    updateVideoInMaterial,
  } = useMaterial();
  const { actions } = useEnqueueToast();
  const [isUploadingVideo, setIsUploadingVideo] = useState<boolean>(false);
  const [materialImages, updateMaterialImages] = useState<MaterialImageData[]>(
    material?.images
      ? material?.images.map((materialImage: ImageInfo): MaterialImageData => {
          return {
            id: materialImage.id,
            file: null,
            fileUrl: materialImage.file.url as string,
            title: materialImage.title,
            type: "application/pdf",
          };
        })
      : [],
  );

  const [thumbnailFile, setThumbnailFile] = useState<SetThumbnailData | null>(
    null,
  );

  const [deleteThumbnail, setDeleteThumbnail] = useState<boolean>(false);
  const [removedImages, setRemovedImages] = useState<string[]>([]);
  // Need removed videos array to match Super Owner API
  const [removedVideos, setRemovedVideos] = useState<string[]>([]);
  const [videoChanged, setVideoChanged] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const progressRef = useRef<HTMLDivElement | null>(null);
  const defaultTags =
    material?.tags?.length > 0
      ? material.tags.map((tag: Tag, index: number) => {
          if (material.tags.length === index + 1) {
            return tag.name;
          }
          return tag.name;
        })
      : [];
  const methods = useForm<FormValues>({
    defaultValues: {
      title: safeString(material?.title),
      description: safeString(material?.description),
      memo: safeString(material?.memo),
      tags: defaultTags,
      externalLink:
        material?.videos?.length > 0 && material?.videos[0].external_link
          ? material?.videos[0].external_link
          : "",
      video:
        material?.videos &&
        material?.videos?.length > 0 &&
        material?.videos[0].file_url
          ? {
              file: material?.videos[0].file,
              name: material?.videos[0].title,
              type: "video/mp4",
            }
          : undefined,
      videoThumb: undefined,
      downloadAllowed:
        (material?.download_allowed?.toString() as AllowedToDownload) ||
        AllowedToDownload.NotAllowed,
      isPublic:
        (material?.is_public?.toString() as isPublic) || isPublic.NotAllowed,
    },
  });

  const { register, handleSubmit, reset, watch } = methods;
  const materialVideoId = safeString(material?.videos?.[0]?.id);

  const handleClose = () => {
    setSelectedCategoryIds({
      ...selectedCategoryIds,
      selectedCategoryId: null,
    });
    updateMaterialImages([]);
    setRemovedImages([]);
    setSuccessMessage("");
    resetFormRelatedData();
    reset();
    setVideoChanged(false);
    props.handleClose();
    if (updateMaterialStatus === ReduxStatus.fulfilled) {
      fetchMaterial(material.id);
    }
    resetStoredImage();
    setDeleteThumbnail(false);
  };

  const handleCancel = () => {
    deleteStoredImageOnCanceling(
      currentUser?.current_role as SVImageOptions["apiRole"],
    );
    handleClose();
  };

  useEffect(() => {
    register("video");
    register("videoThumb");
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (videoChanged && watch("video")) {
      if (progress !== 100) return undefined;
      timeoutId = setTimeout(() => {
        setProgress(0);
        handleClose();
        actions.showSuccess("教材を更新しました。");
      }, 1000);
    } else if (updateMaterialStatus === ReduxStatus.fulfilled) {
      actions.showSuccess("教材を更新しました。");
      handleClose();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [progress, updateMaterialStatus]);

  useEffect(() => {
    if (serverError) {
      actions.showError(serverError);
      if (serverError === NETWORK_ERROR) {
        handleClose();
      }
    }
  }, [serverError]);

  useEffect(() => {
    if (successMessage.length > 0) {
      actions.showSuccess(successMessage);
    }
  }, [successMessage]);

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const pdfsData = materialImages.map((mi: MaterialImageData) => {
      return {
        id: mi.id,
        type: mi.type,
        file: mi.file,
        name: mi.title,
      };
    });
    let thumbnailParam;
    if (thumbnailFile) {
      thumbnailParam = {
        file: thumbnailFile.file,
        name: thumbnailFile.title,
        type: thumbnailFile.type,
      };
    } else if (deleteThumbnail) {
      thumbnailParam = THUMBNAIL_DELETED;
    } else {
      thumbnailParam = NO_CHANGE;
    }

    const videoFileData = values.video
      ? {
          name: values.video.name,
          file: values.video.file,
          type: values.video.type,
          thumbnail: values.videoThumb,
        }
      : null;

    const commonParams = {
      id: material.id,
      title: values.title,
      categoryId:
        selectedCategoryIds.selectedCategoryId || material.category.id,
      description: values.description,
      tags: values.tags,
      memo: values.memo,
      downloadAllowed: values.downloadAllowed,
      isPublic: values.isPublic,
      materialVideoId,
      videoFileData,
      videoChanged,
    };

    const params = {
      ...commonParams,
      removedImages,
      pdfFilesData: pdfsData,
      thumbnailData: thumbnailParam,
      removedVideos,
      external_link: values.externalLink,
    };

    await updateMaterial(params);

    if (videoChanged && videoFileData) {
      progressRef?.current?.scrollIntoView();
      updateVideoInMaterial(commonParams, setProgress);
    }
    deleteStoredImageOnSaving(
      currentUser?.current_role as SVImageOptions["apiRole"],
    );
  };

  const title = "教材の更新";
  const { selectedCategoryId } = selectedCategoryIds;
  const selectedCategory = get(
    categories,
    selectedCategoryId || material.category.id,
  );
  return (
    <GenericModal
      useActionButtons
      useSecondaryButton
      title={title}
      modalSize="xl"
      handleClose={handleCancel}
      primaryButtonAction={handleSubmit(onSubmit)}
      secondaryButtonAction={handleCancel}
      primaryButtonText="更新する"
      secondaryButtonText="キャンセル"
      open
      posting={
        updateMaterialStatus === ReduxStatus.pending ||
        updateVideoStatus === ReduxStatus.pending ||
        progress > 0 ||
        isUploadingVideo
      }
      secondaryButtonActionPosting={
        updateMaterialStatus === ReduxStatus.pending || progress > 0
      }
    >
      <EditMaterialModalMainContext.Provider value={{ setIsUploadingVideo }}>
        <FormProvider {...methods}>
          <MaterialForm
            material={material}
            categories={categories}
            selectedCategory={selectedCategory}
            videoChanged={videoChanged}
            setVideoChanged={setVideoChanged}
            removedImages={removedImages}
            setRemovedImages={setRemovedImages}
            materialImages={materialImages}
            updateMaterialImages={updateMaterialImages}
            thumbnailFile={thumbnailFile}
            setThumbnailFile={setThumbnailFile}
            setRemovedVideos={setRemovedVideos}
            isOverCapacity={isOverCapacity}
            progress={progress}
            progressRef={progressRef}
            setDeleteThumbnail={setDeleteThumbnail}
          />
        </FormProvider>
      </EditMaterialModalMainContext.Provider>
    </GenericModal>
  );
};

export default EditMaterialModalMain;
