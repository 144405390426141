import {
  activeStatus,
  addedStudentsToPlan,
  creatingStatus,
  deletedStudentsToPlan,
  deleteTeacherPlanClasse,
  deleteTeacherPlanDaysId,
  deleteTeacherPlanStudent,
  draftStatus,
  editedClasses,
  editedSubjects,
  getOneTeacherPlanItem,
  getOneTeacherPlanItemReload,
  getTeacherGetClassDateList,
  getTeacherPlanClasses,
  getTeacherPlanItems,
  getTeacherPlanStudents,
  handleLoading,
  postTeacherPlanClasses,
  postTeacherPlanDays,
  postTeacherPlanItems,
  postTeacherPlanStudents,
  putTeacherPlanClassesId,
  putTeacherPlanDaysId,
  putTeacherPlanItemsId,
  resetState,
  resetUploadingPercent,
} from "@actions/teacherApp/pblplan";
import {
  TeacherPblPlanClass,
  TeacherPblPlanItemBase,
  TeacherPblPlanItemDetails,
  TeacherPblPlanStudent,
} from "@lib/Api";
import errorWithMessage from "@lib/rtk/error-utils";
import { createSlice } from "@reduxjs/toolkit";
import { ReduxStatus, ReduxStatusType } from "@root/constants/redux";

import { RootState } from "../../store";

export interface TeacherPlan {
  creating: boolean;
  draft: boolean;
  activeStatus: boolean;
  fetchingPlanItems: boolean;
  fetchedPlanItems: boolean;
  fetchingClassDateList: boolean;
  fetchedClassDateList: boolean;
  fetchingOnePlanItems: boolean;
  fetchedOnePlanItems: boolean;
  postingPlanItem: boolean;
  postedPlanItem: boolean;
  postingPlanDays: boolean;
  postedPlanDays: boolean;
  postingPlanClasses: boolean;
  postedPlanClasses: boolean;
  postingPlanStudents: boolean;
  postedPlanStudent: boolean;
  editingPlanDays: boolean;
  editedPlanDays: boolean;
  editingPlanItems: boolean;
  editedPlanItems: boolean;
  editingPlanClasses: boolean;
  editingPlanSubjects: boolean;
  editedPlanSubjects: boolean;
  editedPlanClasses: boolean;
  deletingPlanDays: boolean;
  deletingPlanClasses: boolean;
  deletedPlanClasses: boolean;
  deletingPlanStudent: boolean;
  deletedPlanStudent: boolean;
  PlanClassesError: any;
  PlanItemsError: any;
  PlanItemError: any;
  planDaysError: any;
  PlanSubjectsError: any;
  onePlanItemsError: any;
  editePlanDaysError: any;
  editPlanClassesError: any;
  editePlanSubjectsError: any;
  planDays: any[];
  classDateList: any[];
  planItems: NonNullable<TeacherPblPlanItemBase[]> | [];
  onePlanItems: TeacherPblPlanItemDetails | null;
  totalCount: number;
  uploadingPercent: number;
  updatingPercent: number;
  fetchingPlanClasses: ReduxStatusType;
  getPlanClassesError: string | null;
  planClassesTotalCount: number;
  planClasses: TeacherPblPlanClass[] | [];
  fetchingPlanStudents: ReduxStatusType;
  getPlanStudentsError: string | null;
  planStudentsTotalCount: number;
  planStudents: TeacherPblPlanStudent[] | [];
  planStudentsError: string | null;
  loadingCount: number;
  loadingMsg: string;
}

export const initialState: TeacherPlan = {
  creating: false,
  draft: false,
  activeStatus: false,
  fetchingPlanItems: false,
  fetchedPlanItems: false,
  fetchingClassDateList: false,
  fetchedClassDateList: false,
  fetchingOnePlanItems: false,
  fetchedOnePlanItems: false,
  postingPlanItem: false,
  postedPlanItem: false,
  postingPlanDays: false,
  postedPlanDays: false,
  postingPlanClasses: false,
  postedPlanClasses: false,
  postingPlanStudents: false,
  postedPlanStudent: false,
  editingPlanDays: false,
  editedPlanDays: false,
  editingPlanItems: false,
  editedPlanItems: false,
  editingPlanClasses: false,
  editedPlanClasses: false,
  editingPlanSubjects: false,
  editedPlanSubjects: false,
  deletingPlanDays: false,
  deletingPlanClasses: false,
  deletedPlanClasses: false,
  deletingPlanStudent: false,
  deletedPlanStudent: false,
  PlanClassesError: null,
  PlanItemsError: null,
  PlanItemError: null,
  planDaysError: null,
  onePlanItemsError: null,
  PlanSubjectsError: null,
  editePlanDaysError: null,
  editPlanClassesError: null,
  editePlanSubjectsError: null,
  planItems: [],
  planDays: [],
  classDateList: [],
  onePlanItems: null,
  totalCount: 0,
  uploadingPercent: 0,
  updatingPercent: 10,
  fetchingPlanClasses: ReduxStatus.idle,
  getPlanClassesError: "",
  planClassesTotalCount: 0,
  planClasses: [],
  fetchingPlanStudents: ReduxStatus.idle,
  getPlanStudentsError: "",
  planStudentsTotalCount: 0,
  planStudents: [],
  planStudentsError: null,
  loadingCount: 0,
  loadingMsg: "",
};

const teacherAppPblPlanSlice = createSlice({
  name: "TeacherApp/StudentMaterialWork",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeacherPlanItems.pending, (state, _action) => {
        return {
          ...state,
          fetchingPlanItems: true,
          fetchedPlanItems: false,
          PlanItemsError: null,
        };
      })
      .addCase(getTeacherPlanItems.rejected, (state, action) => {
        return {
          ...state,
          fetchingPlanItems: false,
          fetchedPlanItems: false,
          PlanItemsError: errorWithMessage(action.payload),
        };
      })
      .addCase(getTeacherPlanItems.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingPlanItems: false,
          fetchedPlanItems: true,
          planItems: payload.plan_items,
          totalCount: payload.total_count,
        };
      })
      .addCase(getTeacherPlanClasses.pending, (state, _action) => {
        return {
          ...state,
          fetchingPlanClasses: ReduxStatus.pending,
        };
      })
      .addCase(getTeacherPlanClasses.rejected, (state, action) => {
        return {
          ...state,
          fetchingPlanClasses: ReduxStatus.rejected,
          getPlanClassesError: errorWithMessage(action.payload),
        };
      })
      .addCase(getTeacherPlanClasses.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingPlanClasses: ReduxStatus.fulfilled,
          planClasses: payload.plan_classes,
          planClassesTotalCount: payload.total_count,
        };
      })
      .addCase(getTeacherPlanStudents.pending, (state, _action) => {
        return {
          ...state,
          fetchingPlanStudents: ReduxStatus.pending,
        };
      })
      .addCase(getTeacherPlanStudents.rejected, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingPlanStudents: ReduxStatus.rejected,
          getPlanStudentsError: payload?.errors?.message || "",
        };
      })
      .addCase(getTeacherPlanStudents.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingPlanStudents: ReduxStatus.fulfilled,
          planStudents: payload.plan_students,
          planStudentsTotalCount: payload.total_count,
        };
      })
      .addCase(getOneTeacherPlanItem.pending, (state, _action) => {
        return {
          ...state,
          fetchingOnePlanItems: true,
          fetchedOnePlanItems: false,
          onePlanItemsError: null,
        };
      })
      .addCase(getOneTeacherPlanItem.rejected, (state, action) => {
        return {
          ...state,
          fetchingOnePlanItems: false,
          fetchedOnePlanItems: false,
          onePlanItemsError: errorWithMessage(action.payload),
        };
      })
      .addCase(getOneTeacherPlanItem.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingOnePlanItems: false,
          fetchedOnePlanItems: true,
          onePlanItems: payload,
        };
      })
      .addCase(getOneTeacherPlanItemReload.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          onePlanItems: payload,
        };
      })
      .addCase(getTeacherGetClassDateList.pending, (state, _action) => {
        return {
          ...state,
          fetchingClassDateList: true,
          fetchedClassDateList: false,
          classDateListError: null,
        };
      })
      .addCase(getTeacherGetClassDateList.rejected, (state, action) => {
        return {
          ...state,
          fetchingClassDateList: false,
          fetchedClassDateList: false,
          classDateListError: errorWithMessage(action.payload),
        };
      })
      .addCase(getTeacherGetClassDateList.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          fetchingClassDateList: false,
          fetchedClassDateList: true,
          classDateList: payload,
        };
      })
      .addCase(postTeacherPlanStudents.pending, (state, _action) => {
        return {
          ...state,
          postingPlanStudents: true,

          planStudentsError: null,
        };
      })
      .addCase(postTeacherPlanStudents.rejected, (state, action) => {
        return {
          ...state,
          postingPlanStudents: false,
          PlanStudentsError: errorWithMessage(action.payload),
        };
      })
      .addCase(postTeacherPlanStudents.fulfilled, (state, action) => {
        return {
          ...state,
          postingPlanStudents: false,
          planItems: action.payload,
          uploadingPercent: state.uploadingPercent + 25,
          updatingPercent: state.updatingPercent + 50,
        };
      })
      .addCase(postTeacherPlanItems.pending, (state, _action) => {
        return {
          ...state,
          postingPlanItem: true,
          postedPlanItem: false,
          PlanItemError: null,
        };
      })
      .addCase(postTeacherPlanItems.rejected, (state, action) => {
        return {
          ...state,
          postingPlanItem: false,
          postedPlanItem: false,
          PlanItemError: errorWithMessage(action.payload),
        };
      })
      .addCase(postTeacherPlanItems.fulfilled, (state, action) => {
        return {
          ...state,
          postingPlanItem: false,
          postedPlanItem: true,
        };
      })
      .addCase(postTeacherPlanDays.pending, (state, _action) => {
        return {
          ...state,
          postingPlanDays: true,
          postedPlanDays: false,
          planDaysError: null,
        };
      })
      .addCase(postTeacherPlanDays.rejected, (state, action) => {
        return {
          ...state,
          postingPlanDays: false,
          postedPlanDays: false,
          planDaysError: errorWithMessage(action.payload),
        };
      })
      .addCase(postTeacherPlanDays.fulfilled, (state, action) => {
        const { payload } = action;
        return {
          ...state,
          postingPlanDays: false,
          postedPlanDays: true,
          planDays: payload,
          uploadingPercent: state.uploadingPercent + 25,
          updatingPercent: 50,
        };
      })
      .addCase(postTeacherPlanClasses.pending, (state, _action) => {
        return {
          ...state,
          postingPlanClasses: true,
          postedPlanClasses: false,
          PlanClassesError: null,
        };
      })
      .addCase(postTeacherPlanClasses.rejected, (state, action) => {
        return {
          ...state,
          postingPlanClasses: false,
          postedPlanClasses: false,
          PlanClassesError: errorWithMessage(action.payload),
        };
      })
      .addCase(postTeacherPlanClasses.fulfilled, (state, action) => {
        return {
          ...state,
          postingPlanClasses: false,
          postedPlanClasses: true,
          uploadingPercent: state.uploadingPercent + 25,
          updatingPercent: 100,
        };
      })
      .addCase(putTeacherPlanClassesId.pending, (state, _action) => {
        return {
          ...state,
          editingPlanClasses: true,
          editPlanClassesError: null,
        };
      })
      .addCase(putTeacherPlanClassesId.rejected, (state, action) => {
        return {
          ...state,
          editingPlanClasses: false,
          editPlanClassesError: errorWithMessage(action.payload),
        };
      })
      .addCase(putTeacherPlanClassesId.fulfilled, (state, action) => {
        return {
          ...state,
          editingPlanClasses: false,
          updatingPercent: 100,
        };
      })
      .addCase(putTeacherPlanItemsId.pending, (state, _action) => {
        return {
          ...state,
          editingPlanItems: true,
          editedPlanItems: false,
          editePlanItemsError: null,
        };
      })
      .addCase(putTeacherPlanItemsId.rejected, (state, action) => {
        return {
          ...state,
          editingPlanItems: false,
          editedPlanItems: false,
          editePlanItemsError: errorWithMessage(action.payload),
        };
      })
      .addCase(putTeacherPlanItemsId.fulfilled, (state, action) => {
        return {
          ...state,
          editingPlanItems: false,
          editedPlanItems: true,
        };
      })
      .addCase(putTeacherPlanDaysId.pending, (state, _action) => {
        return {
          ...state,
          editingPlanDays: true,
          editedPlanDays: false,
          editePlanDaysError: null,
        };
      })
      .addCase(putTeacherPlanDaysId.rejected, (state, action) => {
        return {
          ...state,
          editingPlanDays: false,
          editedPlanDays: false,
          editePlanDaysError: errorWithMessage(action.payload),
        };
      })
      .addCase(putTeacherPlanDaysId.fulfilled, (state, action) => {
        return {
          ...state,
          editingPlanDays: false,
          editedPlanDays: true,
        };
      })
      .addCase(deleteTeacherPlanDaysId.pending, (state, _action) => {
        return {
          ...state,
          deletingPlanDays: true,
        };
      })
      .addCase(deleteTeacherPlanDaysId.rejected, (state, action) => {
        return {
          ...state,
          deletingPlanDays: false,
        };
      })
      .addCase(deleteTeacherPlanDaysId.fulfilled, (state, action) => {
        return {
          ...state,
          deletingPlanDays: false,
        };
      })
      .addCase(deleteTeacherPlanClasse.pending, (state, _action) => {
        return {
          ...state,
          deletingPlanClasses: true,
          deletedPlanClasses: false,
        };
      })
      .addCase(deleteTeacherPlanClasse.rejected, (state, action) => {
        return {
          ...state,
          deletingPlanClasses: false,
          deletedPlanClasses: false,
        };
      })
      .addCase(deleteTeacherPlanClasse.fulfilled, (state, action) => {
        return {
          ...state,
          deletingPlanClasses: false,
          deletedPlanClasses: true,
        };
      })
      .addCase(deleteTeacherPlanStudent.pending, (state, _action) => {
        return {
          ...state,
          deletingPlanStudent: true,
        };
      })
      .addCase(deleteTeacherPlanStudent.rejected, (state, action) => {
        return {
          ...state,
          deletingPlanStudent: false,
        };
      })
      .addCase(deleteTeacherPlanStudent.fulfilled, (state, action) => {
        return {
          ...state,
          deletingPlanStudent: false,
        };
      })
      .addCase(editedClasses, (state, action) => {
        return {
          ...state,
          editedPlanClasses: true,
        };
      })
      .addCase(editedSubjects, (state, action) => {
        return {
          ...state,
          editedPlanSubjects: true,
        };
      })
      .addCase(draftStatus, (state, action) => {
        return {
          ...state,
          draft: true,
        };
      })
      .addCase(activeStatus, (state, action) => {
        return {
          ...state,
          activeStatus: true,
        };
      })
      .addCase(creatingStatus, (state, action) => {
        return {
          ...state,
          creating: true,
        };
      })
      .addCase(handleLoading, (state, action) => {
        return {
          ...state,
          loadingCount: state.loadingCount + action.payload.count,
          loadingMsg: action.payload.message
            ? action.payload.message
            : state.loadingMsg,
        };
      })
      .addCase(resetUploadingPercent, (state, _action) => {
        return {
          ...state,
          uploadingPercent: 0,
          updatingPercent: 0,
        };
      })
      .addCase(addedStudentsToPlan, (state, _action) => {
        return {
          ...state,
          postedPlanStudent: true,
        };
      })
      .addCase(deletedStudentsToPlan, (state, _action) => {
        return {
          ...state,
          deletedPlanStudent: true,
        };
      })

      .addCase(resetState, (state, action) => {
        return {
          ...state,
          creating: false,
          draft: false,
          activeStatus: false,
          postingPlanDays: false,
          postedPlanDays: false,
          postingPlanItem: false,
          postedPlanItem: false,
          postingPlanClasses: false,
          postedPlanClasses: false,
          postingPlanStudents: false,
          postedPlanStudent: false,
          editingPlanItems: false,
          editedPlanItems: false,
          editingPlanClasses: false,
          editedPlanClasses: false,
          editingPlanSubjects: false,
          editedPlanSubjects: false,
          deletingPlanDays: false,
          deletedPlanClasses: false,
          deletingPlanStudent: false,
          deletedPlanStudent: false,
          uploadingPercent: 0,
          updatingPercent: 0,
          loadingCount: 0,
          loadingMsg: "",
          fetchingPlanClasses: ReduxStatus.idle,
          getPlanClassesError: "",
          fetchingPlanStudents: ReduxStatus.idle,
          getPlanStudentsError: "",
          planStudentsError: null,
          PlanClassesError: null,
        };
      });
  },
});

export const teacherAppPblPlan = (state: RootState): TeacherPlan =>
  state.teacherApp.pblPlan;

export default teacherAppPblPlanSlice.reducer;
